import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';

@Component({
  selector: 'app-partner-directory',
  templateUrl: './partner-directory.component.html',
  styleUrls: ['./partner-directory.component.scss']
})
export class PartnerDirectoryComponent implements OnInit {
  public selectedCategory:any;
  public partnerLogData:any;
  TravelData: any;
  edTechData:any;
  
  active = "tab-pane fade show active"
  nonActive = "tab-pane fade"
  navActive = "nav-link active"
  navNonActive = "nav-link"
  educationClass = this.nonActive;
  travelClass = this.active;
  navEducation = this.navNonActive;
  navTravel = this.navActive; 
  previousUrl: string = "";

   constructor(  private accountService: AccountService, private sharedDataService: SharedDataService) { }
 
   ngOnInit(): void {
     
     this.sharedDataService.educationTabDisplay.subscribe(value => {
      this.previousUrl = value;
      this.getPreviousUrl(this.previousUrl)
     });
     this.selectedCategory = this.previousUrl == "" ? "travel":this.previousUrl;
     this.getPartnersLogos();
   }
 
   tabChanged(category) {
     this.selectedCategory = category;
     console.log('Clicked: ' + category);
     this.getPartnersLogos();
     this.sharedDataService.setEducationDisplay(category);
     this.getPreviousUrl(category);
   }
 
   getPartnersLogos(){
    
    var category = {
     Category: this.selectedCategory
    }
     this.accountService.getPartnersLogos(category)
     .subscribe(response => {
      this.partnerLogData = response["data"].data;
       this.edTechData = this.partnerLogData.filter(
         category => category.category === 'edTech');
       this.TravelData = this.partnerLogData.filter(
         category => category.category === 'travel');
     });
   }

  getPreviousUrl(previousUrl){
    if (previousUrl == "edTech") {
        this.educationClass = this.active;
        this.travelClass = this.nonActive;
        this.navEducation = this.navActive;
        this.navTravel = this.navNonActive;
    }
    else {
      this.educationClass = this.nonActive;
      this.travelClass = this.active;
      this.navEducation = this.navNonActive;
      this.navTravel = this.navActive;
    }
  }

}
