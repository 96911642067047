import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assist-journey',
  templateUrl: './assist-journey.component.html',
  styleUrls: ['./assist-journey.component.scss']
})
export class AssistJourneyComponent implements OnInit {

  constructor(private router : Router) { }

  displayLeft:boolean=false;
  ngOnInit(): void {
    this.displayLeft = !window.location.href.includes("assist-journey/docupload");
  }

}
