import { Component, OnInit } from '@angular/core';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assist-success',
  templateUrl: './assist-success.component.html',
  styleUrls: ['./assist-success.component.scss'],
})
export class AssistSuccessComponent implements OnInit {
  constructor(private sessionTimerService :SessionTimerService,  private route: ActivatedRoute, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();

    this.route.queryParamMap.subscribe(params => {
      var redirect = params.get('redirect');
      if(redirect){
        window.location.replace(redirect);
      }
    });

  }
}
