import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AccountService } from '../../../core/services/account.service';
import { environment } from '../../../../environments/environment';
import { Plan } from '../../../core/models/personalInfo';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { NachPopComponent, UpdateStatusData } from '../nach-pop/nach-pop.component';
import { interval, Subscription } from 'rxjs';
import { data } from 'jquery';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';

export interface AutoPayBody {
  firstname:  string,
  surl: string,
  furl: string,
  udf5: string,
}

export interface DownpaymentBody {
  amount: string,
  txnid: string,
  firstname:  string,
  surl: string,
  furl: string,
  auto_debit_access_key: string,
  customer_authentication_id: string
}

@Component({
  selector: 'app-nach-mandate',
  templateUrl: './nach-mandate.component.html',
  styleUrls: ['./nach-mandate.component.scss'],
})
export class NachMandateComponent implements OnInit {
  @ViewChild('ifscCode') ifscCode: ElementRef;
  @ViewChild(NachPopComponent) nachpopup: NachPopComponent;

  private easebuzzCheckout: any;
  private MerchantKey: string = '';

  public nachForm: UntypedFormGroup;

  loanagreementurl!: string;
  redirectUrl!: string;
  plans!: Plan[];
  conditionAccept: boolean = true;
  baseUrl!: string;
  nachStatus: boolean = false;
  NachUniqueId: string;
  NachUrl: string;
  paymentStatusSubscription: Subscription;

  updateStatusData: UpdateStatusData = {
    mandateId: '',
    status: ''
  }

  isProd: Boolean = false;

  autoPayBody: AutoPayBody = {
    firstname: "",
    surl: "",
    furl: "",
    udf5: ""
  }

  autoDownpayment: DownpaymentBody = {
    amount: '',
    txnid: '',
    firstname:  '',
    surl: '',
    furl: '',
    auto_debit_access_key: '',
    customer_authentication_id: ''
  }

  orderId: string = '';
  isAutoPayLoading = false;
  downpay3tries = 0;
  emiAmount: any = '';
  collectionAmount: any = 0;
  
  nachTried: number = 0; 

  constructor(private route: ActivatedRoute, private accountService: AccountService, private router: Router, private formBuilder: UntypedFormBuilder, private http: HttpClient,
    private spinner: NgxSpinnerService, private sessionTimerService :SessionTimerService, private userService: UserService
  ) {
    this.baseUrl = environment.apiUrl + 'assistmodule/v1/createUserProfile';
    //this.redirectUrl = environment.frontEndUrl + 'assist-journey/payment-gateway';
    this.redirectUrl = environment.frontEndUrl + 'assist-journey/loan-agreement?source=digio&status=success';
    this.selectedPlan = JSON.parse(MemoryStorageService.getItem('finalplan')!);

    var user = JSON.parse(MemoryStorageService.getItem('user')!);
    console.log(user);

    this.emiAmount = [user['emiamount']];

    this.collectionAmount = this.selectedPlan['emi_amount'] * this.selectedPlan['tenor']
    this.nachForm = formBuilder.group({
      bankId: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$')],
      ],
      requestCode: ['5016'],//gen nach mandate
      accountNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{9,18}$')]
      ],
      accountType : [
        '',
        [Validators.required]
      ]
      ,
      //collectionAmount: [user['emiamount']],
      //collectionAmount: [user['collectionAmount']],
      collectionAmount: this.selectedPlan['emi_amount'] * this.selectedPlan['tenor'],
      customerName: [user['fullname']],
      firstCollectionDate: [user['firstCollectionDate']],
      redirectUrl: this.redirectUrl,
      chkAgree: [true, Validators.requiredTrue]
    });
  }

  get m() {
    return this.nachForm.controls;
  }

  openPopup(url: string, mandateId: string): void {
    this.startPaymentStatusCheck();
    document.getElementById('popup-nach')!.style.display = 'block';
    document.getElementById('overlay-nach')!.style.display = 'block';
    this.nachpopup.openWindow(url, mandateId);
  }


  startPaymentStatusCheck(): void {
    if (this.paymentStatusSubscription) {
      this.paymentStatusSubscription.unsubscribe();
    }

    this.paymentStatusSubscription = interval(10000).subscribe(async () => {
      const response = await this.accountService.GetNachStatus(this.NachUniqueId).toPromise();
      if (response['data']) {
        const data = response['data'];
        if (data.customer.accptd === 'accepted') {
          //Update Status to Accepted
          if (this.paymentStatusSubscription) {
            this.paymentStatusSubscription.unsubscribe();
          }
          this.updateStatusData.status = 'accepted';
          this.accountService.UpdateMandateStatus(this.updateStatusData).subscribe((response) => {
            this.accountService.updateNACHApplicationStatus(this.NachUniqueId, 2).subscribe((response) => {
              this.nachpopup.CloseWindow();
              this.router.navigateByUrl('/assist-journey/loan-agreement?status=success');
            });
          });
        }
        else if(data.customer.accptd === 'rejected'){
          //Update Status to Rejected
          if (this.paymentStatusSubscription) {
            this.paymentStatusSubscription.unsubscribe();
          }
          this.updateStatusData.status = 'rejected';
          this.accountService.UpdateMandateStatus(this.updateStatusData).subscribe((response) => {
            this.accountService.updateNACHApplicationStatus(this.NachUniqueId, 0).subscribe((response) => {
              document.getElementById('popup')!.style.display = 'none';
              document.getElementById('overlay')!.style.display = 'none';
              this.nachpopup.CloseWindow();

              /*** Redirect if Nach Failed for Sterling Holidays ***/
              if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
              {
                const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "NachFailed" };
                this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                return;
              }
              /*******************/

              swal.fire("NACH Mandate Failed");
              return this.router.navigateByUrl('/assist-journey/assist-underwrite');
            });
          });
          
        }
      }
    });
  }

  async onSubmit() {
    console.log(this.baseUrl);
    console.log(this.nachForm.value); 

    if(this.isProd)
    //if(true)
    {
      this.spinner.show();
      this.accountService.CheckMandate(MemoryStorageService.getItem("customerToken")).subscribe(async (response) => 
      {
        if (response['data'] != null) 
        {
            this.NachUniqueId = response['data'].mandateid;
            this.NachUrl = response['data'].url;
            this.updateStatusData.mandateId = this.NachUniqueId;
            this.openPopup(this.NachUrl, this.NachUniqueId);
        }
        else {
          try{
            if(response['error'] && response['error'].response_msg === 'Link Expired'){
              this.updateStatusData.mandateId = response['error'].mandateid;
              this.updateStatusData.status = 'rejected';
              this.accountService.UpdateMandateStatus(this.updateStatusData).subscribe((response) => {});
            }
            if(response['error'] && response['error'].response_msg === 'Request approved'){
              this.router.navigateByUrl('/assist-journey/loan-agreement');
            }
          }catch(error){
            // If exception occured, doesn't matter though, updating expired link status not mandatory
            console.log(error);
          }
          return await this.http.post(this.baseUrl, this.nachForm.value).subscribe({
            next: (response: any) => {
              console.log(response);
              if (response['error'] == null) {
                const data = response['data'];
                console.log(data['url']);
                this.NachUniqueId = data.customer.id;
                this.NachUrl = data['url'];
                this.updateStatusData.mandateId = this.NachUniqueId;
                this.openPopup(this.NachUrl, this.NachUniqueId);
                //window.open(JSON.parse(response['data'])['redirectUrl'], '_parent');
                this.spinner.hide();
                this.nachForm.reset();
              } else {
                console.log(response);
                console.log(response['error']);
                console.log(JSON.parse(response['error']));
                let e = JSON.parse(response['error']);
                this.spinner.hide();
                this.router.navigateByUrl('/assist-journey/pleasewait');
              }
            },
          });
        }
      });
    
    }

    // UAT/DEV use digio NACH API
    else {
      this.spinner.show();
      return await this.http.post(this.baseUrl, this.nachForm.value).subscribe({
        next: (response: any) => {
          console.log(response);
          if (response['error'] == null) {
            window.open(JSON.parse(response['data'])['redirectUrl'], '_parent');
            this.nachForm.reset();
            this.spinner.hide();
          } else {
            console.log(response);
            console.log(response['error']);
            console.log(JSON.parse(response['error']));
            let e = JSON.parse(response['error']);
            this.spinner.hide();
            this.router.navigateByUrl('/assist-journey/pleasewait');
          }
        },
      });
    }
    this.spinner.hide();
  }

  digio_doc_id!: string;
  message!: string;
  npci_txn_id!: string;
  status!: string;
  selectedPlan!: Plan;
  ngOnInit(): void {

    if(MemoryStorageService.getItem('OrderId'))
    {
      // start timer with 15min
    }
    else
    {
      //resume timer
      //this.sessionTimerService.resumeTimer();
    }

    this.isAutoPayLoading = false;

    this.isProd = environment.production;

    this.MerchantKey = environment.easeBuzzAutoPayKey;

    if (window['EasebuzzCheckout']) {
      this.easebuzzCheckout = new window['EasebuzzCheckout'](this.MerchantKey, environment.easeBuzzEnvironment);
    } else {
      console.error('EasebuzzCheckout script is not loaded.');
    }

    /* no longer required
    let finalplan: Plan = JSON.parse(MemoryStorageService.getItem('finalplan')!);
    let body = {
      requestCode: '5015',//esign
      emiAmount: finalplan.emi_amount,
      emiPercent: finalplan.roi,
      firstEmiDueDate: finalplan.emi_date,
      processingFee: finalplan.processing_fee,
      loanAmount: finalplan.loan_amount,
      totalNumberOfEmi: finalplan.tenor,
      ipAddress: '', //take it from request header in api request backend
    };
    
    this.http.post(this.baseUrl, body).subscribe((response: any) => {
      console.log('loan agreement');
      console.log(response);
      if (response['data'] != null) {
        this.loanagreementurl = response['data']['fileUrl'];
      }
    }); */

    // this.route.queryParams.subscribe((params) => {
    //   this.digio_doc_id = params['digio_doc_id'];
    //   this.message = params['message'];
    //   this.npci_txn_id =
    //     params['npci_txn_id'] == null ? '' : params['npci_txn_id'];
    //   this.status = params['status'];
    //   if (params['status'] == 'success') {
    //     this.nachStatus = true;
    //     //call esign api
    //     let finalplan: Plan = JSON.parse(MemoryStorageService.getItem('finalplan')!);
    //     let body = {
    //       requestCode: '5015',//esign
    //       emiAmount: finalplan.emi_amount,
    //       emiPercent: finalplan.roi,
    //       firstEmiDueDate: finalplan.emi_date,
    //       processingFee: finalplan.processing_fee,
    //       loanAmount: finalplan.loan_amount,
    //       totalNumberOfEmi: finalplan.tenor,
    //       ipAddress: '', //take it from request header in api request backend
    //     };
    //     this.http.post(this.baseUrl, body).subscribe((response: any) => {
    //       console.log('loan agreement');
    //       console.log(response);
    //       if (response['data'] != null) {
    //         this.loanagreementurl = response['data']['fileUrl'];
    //       }
    //     });

    //     let nachStatusReq = {
    //       requestCode: '5018',//nach update
    //       mandateId: this.digio_doc_id,
    //       mandateStatus: this.status=='success'?2:0,
    //       mandateStatusMessage: this.message,
    //       mandatePayload:''
    //     };
    //     this.http.post(this.baseUrl, nachStatusReq).subscribe((response: any) => {
    //       this.router.navigateByUrl('/assist-journey/payment-gateway');
    //     }, (error:any)=>{});

    //   }
    // });

    //Use Autopay if LoanAmount < 15000
    if(environment.showeasebuzz && this.collectionAmount < 15000)
    {
      //
      this.spinner.show();
      this.isAutoPayLoading = true;

      const orderid = MemoryStorageService.getItem('OrderId');

      if(orderid){
          this.onCheckout(orderid);
      }
      else
      {
        this.autoPayBody.firstname = this.nachForm.value.customerName;
        //this.autoPayBody.udf5 = this.nachForm.value.collectionAmount;
        this.autoPayBody.udf5 = this.selectedPlan['emi_amount'] * this.selectedPlan['tenor'] + '';
        this.autoPayBody.surl = environment.frontEndUrl + "assist-journey/payment-gateway";
        this.autoPayBody.furl = environment.frontEndUrl + "assist-journey/nach-mandate";

        this.http.post(environment.apiUrl + "Menedo/AutoPayInitiate", this.autoPayBody).subscribe((response) => 
        {
          if (response['data'] != null) 
          {
            const autoPayResponse = JSON.parse(response['data']);
            this.orderId = autoPayResponse.data;
            if(this.orderId){
              this.onCheckout(this.orderId);
            }
          }
        });
      }
        
    }
  }

  ngOnDestroy(): void {
    if (this.isProd && this.paymentStatusSubscription) {
      this.paymentStatusSubscription.unsubscribe();
    }
  }

  onCheckout(orderId: string): void {
    if (!this.easebuzzCheckout) {
      console.error('EasebuzzCheckout script is not loaded.');
      return;
    }

    if(orderId.toLowerCase().includes('error')){
      console.log("Something went wrong!");
    }

    const options = {
      access_key: orderId,
      onResponse: (response: any) => {
        console.log(response);
        this.onAutoPayResponse(response);
      },
      theme: '#123456' // color hex
    };
    this.easebuzzCheckout.initiatePayment(options);
  }

  private onAutoPayResponse(response: any){
    console.log(response);
    if(response.hasOwnProperty('status') && response.status.toLowerCase() === 'success'){
      // this.initiateDownpayment(response);  // removing as debit can be made after 48Hrs
      // save status
      this.updateStatusData.mandateId = this.orderId;
      this.updateStatusData.status = 'accepted';
      this.accountService.UpdateAutoPayMandateStatus(this.updateStatusData).subscribe((response) => {
        this.accountService.updateNACHApplicationStatus(this.orderId, 2).subscribe((response) => {
          const url = MemoryStorageService.getItem('partnercallback')
          const orderId = MemoryStorageService.getItem('OrderId');
          if(url != null && url !== 'undefined' && url != '' && orderId != null && orderId !== 'undefined' && orderId != '')
          {
            window.location.href = url;
          }
          else {
            
            this.router.navigateByUrl('/assist-journey/payment-gateway?source=upi');
            //window.location.href = this.redirectUrl + "?status=success";
          }
        });
      });
    }
    else {
      // Already Paid Check ------------------------------------------- 
      // if(error['message'] == "paid like something message - need to check"){
      //   // redirect to next page then or Back to redirectUrl ....
          // const url = MemoryStorageService.getItem('partnercallback')
          // const orderId = MemoryStorageService.getItem('OrderId');
          // if(url != null && url !== 'undefined' && url != '' && orderId != null && orderId !== 'undefined' && orderId != '')
          // {
          //    window.location.href = url;
          // }
          // else {            
          //    this.router.navigateByUrl('/assist-journey/payment-gateway?source=upi');
          // }
      // }
      this.nachTried = MemoryStorageService.getItem('nachTried') ? parseInt(MemoryStorageService.getItem('nachTried')) : 0;
      if (this.nachTried < 3) {
        swal.fire(response.message);
        this.spinner.hide();
        this.nachTried += 1;
    
        MemoryStorageService.setItem('nachTried', this.nachTried + '');
        location.reload();
      }
      else{
        this.updateStatusData.mandateId = this.orderId;
        this.updateStatusData.status = 'rejected';
        this.accountService.UpdateAutoPayMandateStatus(this.updateStatusData).subscribe((response) => {
          this.accountService.updateNACHApplicationStatus(this.orderId, 0).subscribe((response) => {

            /*** Redirect if Nach Failed for Sterling Holidays ***/
            if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
            {
              const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "NachFailed" };
              this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
              return;
            }
            /*******************/

            return this.router.navigateByUrl('/assist-journey/assist-underwrite');
          });
        });
        
       
      }
    }
  }

  private initiateDownpayment(response){
    this.spinner.show();
    if(response.hasOwnProperty("auto_debit_access_key") && response.auto_debit_access_key != ''){
      this.autoDownpayment.firstname = this.nachForm.value.customerName;
      //let amount: number = this.selectedPlan['emi_amount'];
      //this.autoDownpayment.amount = amount.toFixed(2);
      this.autoDownpayment.auto_debit_access_key = response.auto_debit_access_key;
      this.autoDownpayment.customer_authentication_id = response.customer_authentication_id;
      //this.autoDownpayment.txnid = response.txnid;
      this.autoDownpayment.surl = environment.frontEndUrl + "assist-journey/assist-success";
      this.autoDownpayment.furl = environment.frontEndUrl + "assist-journey/nach-mandate";

      this.http.post(environment.apiUrl + "Menedo/AutoPayDownPayment", this.autoDownpayment).subscribe((response) => 
        {
          if (response['data'] != null && (JSON.parse(response['data']).status === 1 || JSON.parse(response['data']).data === "Auto debit request accepted successfully")) 
          {
            console.log(response['data'])
            swal.fire("Downpayment Successful!");
            swal.fire({
              text: 'Downpayment Successful!',
              //icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed || result.isDenied || result.isDismissed) {
                this.router.navigateByUrl('/assist-journey/assist-success');
              }
            });
            this.spinner.hide();
          }
          else if (response['error'] != null) {
            console.log(response);
            if(this.downpay3tries > 3){
              this.downpay3tries += 1;
              setTimeout( () => { this.initiateDownpayment(response); }, 1000 );
            }
            else{
              swal.fire({
                text: 'Downpayment failed!',
                //icon: 'error',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed || result.isDenied || result.isDismissed) {
                  this.router.navigateByUrl('/assist-journey/sorry');
                }
              });
            }
          }
          else {
            swal.fire({
              text: 'Downpayment failed!',
              //icon: 'error',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed || result.isDenied || result.isDismissed) {
                this.router.navigateByUrl('/assist-journey/sorry');
              }
            });
          }
        });
    }
  }

  GenerateTitle(value: string) {
    if (value == 'PBYF' || value == 'PNFL') {
      return 'from';
    } else if (value == 'FNP') {
      return 'pay every 15 days';
    } else if (value == 'BP') {
      return 'pay once';
    } else {
      return value;
    }
  }

  GeneratePayIn(value: string) {
    if (value == 'PBYF') {
      return 'Pay before your travel';
    } else if (value == 'PNFL') {
      return 'pay even after your travel';
    } else if (value == 'FNP') {
      return 'Pay in 2 to 6 instalments';
    } else if (value == 'BP') {
      return 'Pay in one instalment';
    } else {
      return value;
    }
  }

  GeneratePayUpto(value: string) {
    if (value == 'PBYF' || value == 'PNFL') {
      return 'Spread the cost over 12 months';
    } else if (value == 'FNP') {
      return 'Spread the cost over 3 months';
    } else if (value == 'BP') {
      return 'Pay upto 45 days from booking';
    } else {
      return value;
    }
  }

  GenerateProcessingFee(plan: Plan) {
    return plan.processing_fee! + plan.gst!;
  }
  GeneratePayNow(plan: Plan) {
    return plan.processing_fee! + plan.gst! + plan.downpayment!;
  }
  GenerateInterest(plan: Plan) {
    return plan.emi_amount! * plan.tenor! - plan.loan_amount!;
  }
  GenerateTotalPayable(plan: Plan) {
    return plan.emi_amount! * plan.tenor!;
  }

  order_id:string;
  
  callPaymentGateway() {
    this.route.queryParams.subscribe((params: any) => {
      this.baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
      let obj = {
        'ResponseId': params['razorpay_payment_link_id'],
        'payment_id': params['razorpay_payment_id'],
        'payment_link_status': params['razorpay_payment_link_status'],
        'signature': params['razorpay_signature']
      }
      return this.http.post(this.baseUrl, obj).subscribe({
        next: (response: any) => {
          if (params["razorpay_payment_link_status"] == 'paid') {
            let queryParams = '?api=paymentgateway&payment_id=' + params['razorpay_payment_id'] + '&payment_reference_id=' + params['razorpay_payment_link_reference_id'] + '&payment_status=' + params['razorpay_payment_link_status'] + '&partnerTransactionId=' + response.data.partnerTransactionId;
            window.location.replace("https://" + response.data.partnerCallbackUrl + queryParams);
          }
          else {
            this.spinner.show();
          }
        },
      });
    });
  }
  checkValue(event: any) {
    this.conditionAccept = event['target']['checked'];
  }

  upperCase(event: any) {
    const input = event.target;
    input.value = input.value.toUpperCase();
  }

  bankName: string = '';
  getBankName(event: any) {
    this.accountService.getNachLiveDestinationBanks({ ifsc: this.nachForm.controls.bankId.value }).subscribe({
      next: (responseLiveBanks: any) => {
        console.log(responseLiveBanks);
        this.http.get('https://ifsc.razorpay.com/' + this.nachForm.controls.bankId.value).subscribe((response: any) => {
          if (responseLiveBanks['data'] != null) {
            console.log(response);
            this.accountService.getBankList(response['BANKCODE']).subscribe((res:any)=>{
              if(res['data'] !=null)
              {
                this.bankName = (response['BANK'] + ' ' + response['BRANCH']);
              }
              else
              {
                swal.fire(response['IFSC'] + '-' + response['BANK'] + ' ' + response['BRANCH'] + ' is not valid for E-Mandate. Please choose a valid Bank');
              }
            })
          } else {
            this.nachForm.get('bankId').reset();
            this.nachForm.get('accountNumber').reset();
            this.ifscCode.nativeElement.value = '';
            this.ifscCode.nativeElement.focus();
            swal.fire(response['IFSC'] + '-' + response['BANK'] + ' ' + response['BRANCH'] + ' is not valid for E-Mandate. Please choose a valid Bank');
          }
        });
      },
    });
  }
}
