import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UrlSerializer } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CountdownModule } from 'ngx-countdown';

import { SharedModule } from './shared/shared.module';
import { MenuComponent } from './layout/menu/menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { HomeComponent } from './modules/home/home.component';

import { LowerCaseUrlSerializer } from './shared/services/lowercaseurlserializer';
import { AuthInterceptorService } from './core/interceptor/auth-interceptor.service';
import { SplitFareComponent } from './modules/split-fare/split-fare.component';
import { TravelLoanComponent } from './modules/travel-loan/travel-loan.component';

import { TermsComponent } from './modules/privacy-policy/terms/terms.component';
import { PrivacyComponent } from './modules/privacy-policy/privacy/privacy.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { MainBannerComponent } from './modules/home/main-banner/main-banner.component';
import { MediaComponent } from './modules/home/media/media.component';
import { OurProductsComponent } from './modules/home/our-products/our-products.component';
import { StepsFreedomComponent } from './modules/home/steps-freedom/steps-freedom.component';
import { TravelAgentComponent } from './modules/travel-agent/travel-agent.component';
import { FaqsComponent } from './modules/faqs/faqs.component';
import { AgentBenefitsComponent } from './modules/travel-agent/agent-benefits/agent-benefits.component';
import { AgentWorkComponent } from './modules/travel-agent/agent-work/agent-work.component';
import { AgentMainBannerComponent } from './modules/travel-agent/agent-main-banner/agent-main-banner.component';
import { PleaseWaitComponent } from './modules/please-wait/please-wait.component';
import { EasyInstalmentComponent } from './modules/travel-loan/easy-instalment/easy-instalment.component';
import { HowItsWorkComponent } from './modules/travel-loan/how-its-work/how-its-work.component';
import { PartnerDirectoryComponent } from './modules/partner-directory/partner-directory.component';
import { PersonalizedDashboardComponent } from './modules/personalized-dashboard/personalized-dashboard.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { FloatingBarComponent } from './modules/dashboard/floating-bar/floating-bar.component';
import { CongratulationsComponent } from './modules/congratulations/congratulations.component';
import { AssistJourneyComponent } from './modules/assist-journey/assist-journey.component';
import { DocumentsUploadQRCodeComponent } from './modules/assist-journey/documents-upload-qr-code/documents-upload-qr-code.component';
import { DocumentUploadComponent } from './modules/assist-journey/document-upload/document-upload.component';
import { BookingDoneComponent } from './modules/assist-journey/booking-done/booking-done.component';
import { UPIPaymentComponent } from './modules/assist-journey/upi-payment/upi-payment.component';
import { AssistTermComponent } from './modules/assist-journey/assist-term/assist-term.component';
import { IdentityProofComponent } from './modules/assist-journey/identity-proof/identity-proof.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadAndPreviewComponent } from './modules/assist-journey/upload-and-preview/upload-and-preview.component';
import { AddressProofComponent } from './modules/assist-journey/address-proof/address-proof.component';
import { AadhaarVerificationComponent } from './modules/assist-journey/aadhaar-verification/aadhaar-verification.component';
import { BankStatementComponent } from './modules/assist-journey/bank-statement/bank-statement.component';
import { NachMandateComponent } from './modules/assist-journey/nach-mandate/nach-mandate.component';
import { AutocompleteOffDirective } from './shared/directives/autocomplete-off.directive';
import { AssistPaymentPlanComponent } from './modules/assist-journey/assist-payment-plan/assist-payment-plan.component';
import { EsignComponent } from './modules/assist-journey/esign/esign.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentGatewayComponent } from './modules/assist-journey/payment-gateway/payment-gateway.component';
import { ProfessionalDetailsComponent } from './modules/assist-journey/professional-details/professional-details.component';
import { ProcessOfflineComponent } from './modules/assist-journey/process-offline/process-offline.component';
import { SessiontimeoutComponent } from './modules/sessiontimeout/sessiontimeout.component';
import { TitleCasePipe } from './modules/assist-journey/documents-upload-qr-code/title-case.pipe';
import { AssistHeaderBannerComponent } from './modules/assist-journey/assist-header-banner/assist-header-banner.component';
import { AssistLeftComponent } from './modules/assist-journey/assist-left/assist-left.component';
import { AssistRightComponent } from './modules/assist-journey/assist-right/assist-right.component';
import { AssistEmiTableComponent } from './modules/assist-journey/assist-emi-table/assist-emi-table.component';
import { AssistPersonalDetailsComponent } from './modules/assist-journey/assist-personal-details/assist-personal-details.component';
import { SessionExpiredComponent } from './modules/assist-journey/session-expired/session-expired.component';
import { AssistFailComponent } from './modules/assist-journey/assist-fail/assist-fail.component';
import { AssistUnderwriteComponent } from './modules/assist-journey/assist-underwrite/assist-underwrite.component';
import { AssistSignUpComponent } from './modules/assist-journey/assist-sign-up/assist-sign-up.component';
import { AssistSubmitOtpComponent } from './modules/assist-journey/assist-submit-otp/assist-submit-otp.component';
import { AssistShowPaymentPlansComponent } from './modules/assist-journey/assist-show-payment-plans/assist-show-payment-plans.component';
import { AssistConfirmPersonalDetailsComponent } from './modules/assist-journey/assist-confirm-personal-details/assist-confirm-personal-details.component';
import { SalaryUploadComponent } from './modules/assist-journey/salary-upload/salary-upload.component';
import { AssistSuccessComponent } from './modules/assist-journey/assist-success/assist-success.component';
import { AssistTravelPlanDetailsComponent } from './modules/assist-journey/assist-travel-plan-details/assist-travel-plan-details.component';
import { AssistTeamCallbackComponent } from './modules/assist-journey/assist-team-callback/assist-team-callback.component';
import { NachPopComponent } from './modules/assist-journey/nach-pop/nach-pop.component';
import { PleaseWaitNachComponent } from './modules/assist-journey/please-wait-nach/please-wait-nach.component';
import { PaymentGatewayPopComponent } from './modules/assist-journey/payment-gateway-pop/payment-gateway-pop.component';
import { AssistLoanAgreementComponent } from './modules/assist-journey/assist-loan-agreement/assist-loan-agreement.component';
import { CdnImageDirective } from './core/directive/cdn-image.directive';
import { SorryComponent } from './modules/sorry/sorry.component';
import { AssistProcessingComponent } from './modules/assist-journey/assist-processing/assist-processing.component';

@NgModule({ declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        DashboardComponent,
        HomeComponent,
        SplitFareComponent,
        TravelLoanComponent,
        TermsComponent,
        PrivacyComponent,
        PrivacyPolicyComponent,
        MainBannerComponent,
        MediaComponent,
        OurProductsComponent,
        StepsFreedomComponent,
        TravelAgentComponent,
        FaqsComponent,
        AgentBenefitsComponent,
        AgentWorkComponent,
        AgentMainBannerComponent,
        PleaseWaitComponent,
        EasyInstalmentComponent,
        HowItsWorkComponent,
        PartnerDirectoryComponent,
        PersonalizedDashboardComponent,
        FloatingBarComponent,
        CongratulationsComponent,
        AssistJourneyComponent,
        DocumentsUploadQRCodeComponent,
        DocumentUploadComponent,
        BookingDoneComponent,
        UPIPaymentComponent,
        AssistTermComponent,
        IdentityProofComponent,
        UploadAndPreviewComponent,
        AddressProofComponent,
        AadhaarVerificationComponent,
        BankStatementComponent,
        NachMandateComponent,
        EsignComponent,
        AssistPaymentPlanComponent,
        PaymentGatewayComponent,
        ProfessionalDetailsComponent,
        ProcessOfflineComponent,
        SessiontimeoutComponent,
        TitleCasePipe,
        AssistHeaderBannerComponent,
        AssistLeftComponent,
        AssistRightComponent,
        AssistEmiTableComponent,
        AssistPersonalDetailsComponent,
        SessionExpiredComponent,
        AssistFailComponent,
        AssistUnderwriteComponent,
        AssistSignUpComponent,
        AssistSubmitOtpComponent,
        AssistShowPaymentPlansComponent,
        AssistConfirmPersonalDetailsComponent,
        SalaryUploadComponent,
        AssistSuccessComponent,
        AssistTravelPlanDetailsComponent,
        AssistTeamCallbackComponent,
        NachPopComponent,
        PleaseWaitNachComponent,
        PaymentGatewayPopComponent,
        AssistLoanAgreementComponent,
        CdnImageDirective,
        SorryComponent,
        AssistProcessingComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [RoundProgressModule,
        CommonModule,
        BrowserModule,
        RouterModule,
        NgbModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FileUploadModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        CountdownModule,
        SharedModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
        CollapseModule.forRoot()], providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AppModule { }
