import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(private accountService: AccountService, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
    let obj = { statusReason: 'Session timed out' };
    this.accountService
      .setApplStatusReasonHistories(obj)
      .subscribe((res) => {
        // handle response
      });
  }

}
