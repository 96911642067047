<!-- Floating Social Media bar Starts -->
<div class="float-sm">
    <div *ngIf="rates!=''" class="fl-fl float-tw">
        <i class="fas fa-rupee-sign"></i>
        <!-- <p>1$ = &#8377; 89</p> -->
        <p>{{rates}}</p>
    </div>
    <div *ngIf="now!=''" class="fl-fl float-gp">
        <i class="fas fa-clock"></i>
        <p>{{now}}</p>
    </div>
    <div class="fl-fl float-rs">
        <i class="fas fa-cloud-sun"></i>
        <p>{{tempMax}}&#8451; High, {{tempMin}}&#8451; Low  </p>
    </div>
</div>
<!-- Floating Social Media bar Ends -->