<div class="PDashboard">
  <div>
  <div class="center"><h2>Discover</h2></div>
  <!-- <div class="centered"><h1>{{destinationData?.country}}</h1></div> -->
  <div class="centered"><h1>{{cityName}}</h1></div>
  <div class="Emi"><h6>EMI starting from</h6></div>
  <div *ngIf="!showMsg">
    <div class="Rupee"><h5><span>{{location?.currency}}</span> {{emiValue}}</h5></div>
  </div>
  <div *ngIf="showMsg">
    <div class="Rupee">
       <h5><span>No Emi Available , Currently No flights available between your origin to destination.</span></h5>
    </div>
  </div>
  <div class="button">
    <button class="btn btn-warning btn-block" (click)="getCurrentApplication()">
      Complete Application
    </button>
  </div>
</div>
 <div class="uploadDocument" *ngIf="shwUploadedDocs"> <h5>
   <!-- document pending only screen enable -->
      <span>Upload your Documents to use your credit Line & book your flight,hotel or holiday packages.<br>
      </span></h5> 
      <!-- <h2> To view your application click here. <a>Click Here.</a></h2> -->
    </div>
<div class="Leftsidebar">

      <div><h2>{{userPersonalDetail?.userName}}</h2></div>
    
      <!-- <input (change)='getColor()' [(ngModel)]="creditLimit" /> -->
   <!-- <div class="centered"><h1>GREECE</h1></div> -->
   <img class="img" [src]="userPersonalDetail?.profilePic" style="border-radius:50%;height: 80px; width:80p;border: 1px solid black;"/>
     <div>
       <input type="file" id="upload" (change)="onFileChanged($event)" style="width: 0px" />
       <label for="upload" style="cursor:pointer">Click me to upload profile</label>
     </div>
      <!---<input type="file" (change)="onFileChanged($event)" class="img-circle">-->
      <div>EmailID</div><br>
      <div>{{userPersonalDetail?.email}}</div>
      <div *ngIf="verified">
      <input type="button"(click)="Popup()"value="click here to verify Email ID" /></div>
      <div>ContactNo</div>
      <span>{{userPersonalDetail?.phoneNo}}</span>  

      <div class="progress-wrapper" *ngIf="!principalLoanAmount||!availableCreditLimit">
        <!-- <div class="current" [ngStyle]="getOverlayStyle()">{{ creditLimit }}/{{ max }}</div> -->
          <round-progress
            [current]="current"
            [max]="100"
            [color]="color"
            [background]="'#eaeaea'"
            [radius]="125"
            [stroke]="15" 
            [semicircle]="true"
            [rounded]="false"
            [clockwise]="true"
            [responsive]="true"
            [duration]="800"
            [animation]="'easeOutCubic'"
            [animationDelay]="0" 
          ></round-progress>
          <div class='principalLoanAmountDivStyle' *ngIf="!principalLoanAmount">
            <div><p> In-Principle Loan Amount</p></div>
            <div><h5><span>{{location?.currency}}</span> {{creditLimit}}</h5></div>
            <!-- <div><p>(Upload & verify Document to avail credit)</p></div> -->
          </div>
          
          <div class='principalLoanAmountDivStyle' *ngIf="!availableCreditLimit">
           <div><p>Available credit Limit</p></div>
           <div><h6><span>{{location?.currency}}</span> {{availableCreditLimitAmount}}</h6></div>
          </div>
        </div>
</div>

      <div class="Rightsidebar">
        <div>Destination Fast Facts</div>
        <ul>
      <li> {{rates}}</li>
          <li>{{tempMax}}&#8451; High, {{tempMin}}&#8451; Low </li>
          <li>{{now}}</li>
        </ul> 
      </div>
      <div class="Rightsidebar2">

        <div  *ngIf="knowMoreData">
          <div innerHtml="{{staticContent}}"></div>
          <!-- <div [innerHTML]=staticContent> {{staticContent}}</div> -->
        </div>
        <br>

        <div (click)="knowMore()"><a>Know More</a></div>

      </div>
 
</div>
  <ng-template #Modal let-modal>
    <div class="row justify-content-center pt-3">
      <div class="card col-11">
        <div class="headline">
          <h3 class="title-border">Verify Email</h3>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-12">
              <form name="form" #vf="ngForm" autocomplete="off" class="EmailVerifyForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <span>
                        <input type="text" class="form-control" name="otp" maxlength="4"
                             [(ngModel)]="model.otp" (keydown)="onKeydown($event)" placeholder="OTP">
                        <label for="class">OTP</label>
                      </span>
                      <div *ngIf="invalid" class="text-danger">
                        Please enter correct otp
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row justify-content-center buttonDiv">
                <div class="col-6 col-sm-5">
                  <button class="btn btn-default btn-block" [disabled]="!resetButtonEnable"
                          (click)="onResentEmailOTP()" type="button">
                    Resend
                  </button>
                </div>
                <div class="col-6 col-sm-5">
                  <button class="btn btn-warning btn-block" [disabled]="(model.otp?.length < 4) ? true: false "
                          (click)="verifyEmailOTP()"
                          type="button">
                    Verify
                  </button>
                </div>
                
              </div>
              <div class="row mb-3 ">
                <div class="col-12 text-center">
                  <h3 class="viaWhat p-1 w-50 m-auto">
                    <countdown #Cdown2 [config]="{leftTime: 20}" (event)="onLeftTimeEvent($event)"></countdown>
  
                  </h3>
                </div>
  
              </div>
  
            </div>
          </div>
  
        </div>
      </div>
    </div>

  </ng-template>
