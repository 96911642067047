<div class="main-banner d-flex align-items-center">
    <div class="row justify-content-end m-0">
        <div class="col-sm-7 col-md-5">
            <h1 title="Travel on easy instalments">Travel on easy instalments</h1>
            <p>With Mihuru’s easy instalment plans, you can book your trip and travel when you choose to, and pay over
                time in convenient monthly instalments. We don’t sell financial products, but we help you create
                memories.</p>
            <div class="clear"></div>
            <div class="row mt-2 justify-center">
                <div class="col-6 col-sm-12 col-md-10 col-lg-4">
                    <a class="btn btn-warning btn-block" href="https://www.mihuru.com/Promotions/Book-Now-Pay-Later.html" target="_blank">Apply now</a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-easy-instalment></app-easy-instalment>
<div class="section4 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <h2 class="text-center">Can I Apply?</h2>
                <div class="row leftcontent">
                    <div class="col-sm-7 order-sm-first">
                        <p>If you can answer ‘Yes’ to these three questions, you can apply for our travel financing
                            solutions.
                        </p>
                        <h3>Q.1 Are you over 21? </h3>
                        <p>You must be 21 or over to apply.</p>
                        <h3>Q.2 Live in India?</h3>
                        <p>You must reside in India</p>
                        <h3>Q.3 Salaried or self-employed?</h3>
                        <p>You must be either salaried or self-employed and earning &#8377; 15,000 minimum in monthly
                            income or self-employed with minimum annual turnover of &#8377; 1,80,000. We will check
                            your credit history too to decide your eligibility.
                        </p>
                    </div>
                    <div class="col-sm-5 order-first"><img
                            alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru"
                            src="assets/images/product-travel-apply.png" alt="product-travel-Can I apply"
                            class="float-right"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section6">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <h2 class="text-center">What Does it Cost?</h2>
                <div class="row leftcontent">
                    <div class="col-sm-5"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru"
                            src="assets/images/product-travel-cost.png" alt="product-travel-What does it cost" /> </div>
                    <div class="col-sm-7">
                        <h3>What you can borrow?</h3>
                        <p>You can borrow up to &#8377; 2,00,000<br />
                            We offer travel loans ranging from&#8377; 3,000 to&#8377; 2,00,000 depending on your
                            credit profile and travel plan.</p>
                        <h3>What do you have to pay?</h3>
                        <p>You can repay your due amounts early with no extra charges. Please
                            refer to the FAQ section for details on late fees and penalty charges.</p>
                        <h3>What are the early repayment and late fees?</h3>
                        <p>You can repay your due amounts early with no extra charges. Please
                            refer to the FAQ section for details on late fees and penalty charges.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="sectionLast coloredBg">
    <div class="row justify-content-center">
        <div class="col-11 col-lg-5 col-sm-7">
            <h2 class="text-center">‘The world is a book and those who do not travel read only one page.’</h2>
            <img class="sectionLast-img" src="assets/images/splitfare-discoverLimit.png">
            <p>Stop waiting and start travelling!</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-6 col-sm-3 col-lg-2">
            <!-- <button class="lastbutton" (click)="GotoDiscLimit();">Apply Now</button> -->
            <!-- <a class="lastbutton btn-block" routerLink="/signup">Apply Now</a> -->
            <a class="lastbutton btn-block" (click)="moEngageTravelLoanApplyNow()">Apply Now</a>


        </div>
    </div>
</div>