<div class="section2" id="myContent">
    <div class="container-fluid leftcontent">
        <div class="row textDiv justify-content-center">
            <div class="col-12 col-lg-11">
                <h2>Freedom With
                    <br><span>Mihuru Is Easy</span>
                </h2>
                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs d-none" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a [class]="navTravel" (click)="tabChanged('travel')" id="Travel-tab" data-toggle="tab" href="#Travel" role="tab" aria-controls="Travel" aria-selected="true">Travel</a>
                            </li>
                            <li class="nav-item">
                                <a [class]="navEducation" (click)="tabChanged('edTech')" id="Education-tab" data-toggle="tab" href="#Education" role="tab" aria-controls="Education" aria-selected="false">Education</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div [class]="travelClass" id="Travel" role="tabpanel" aria-labelledby="Travel-tab">
                                <div class="row">
                                    <div class="col-12 imgDiv d-block d-md-none"><img alt=" Mobile screens | Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../../assets/images/mobiles-screens-travel.png">
                                    </div>
                                    <div class="col-sm-7 col-lg-7">
                                        <p><span class="font-weight-bold">Step 1 -</span> Get your credit line by filling a simple online application and uploading the required documents. It only takes 5 minutes! </p>
                                        <p><span class="font-weight-bold">Step 2 -</span> Finalize your travel booking with our partner. Meanwhile, we will assess your eligibility and confirm your travel credit limit</p>
                                        <p><span class="font-weight-bold">Step 3 -</span> Confirm your trip and pay for the booking with your Mihuru credit line and pay back in easy EMIs!</p>
                                        <div class="documents_required">
                                            <p class="font-weight-bold">Keep these documents handy for quick application</p>
                                            <p><span class="font-weight-bold">Identity Proof: </span> PAN card</p>
                                            <p><span class="font-weight-bold">Address proof: </span> Aadhaar card</p>
                                            <p><span class="font-weight-bold">Financial proof: </span> Last 6 months bank statement including current month (PDF only)</p>
                                            <p><span class="font-weight-bold">Employment / Business Proof:</span> (For Salaried) 1 Month Latest Salary Slip
                                                <br />
                                                (For self employed) GST number, Gumasta certificate, Udyog adhaar number, Shop & establishment certificate (any 1 for Self-Employed)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 col-lg-4 d-none d-md-block"><img alt=" Mobile screens | Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../../assets/images/mobiles-screens-travel.png">
                                    </div>
                                </div>
                            </div>
                            <div [class]="educationClass" id="Education" class="d-none" role="tabpanel" aria-labelledby="Education-tab">
                                <div class="row">
                                    <div class="col-12 imgDiv d-block d-md-none"><img alt=" Mobile screens | Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../../assets/images/mobiles-screens-education.png">
                                    </div>
                                    <div class="col-sm-7 col-lg-7">
                                        <p><span class="font-weight-bold">Step 1 -</span> Select a course with our education partners & apply for an EMI payment plan with Mihuru
                                        </p>
                                        <p><span class="font-weight-bold">Step 2 -</span> Provide us with a few personal details and required documentation to verify your eligibility and complete your course booking</p>
                                        <p><small><span class="font-weight-bold">Note - </span>Credit eligibility checks do not affect your credit score</small></p>
                                        <div class="row">
                                            <div class="col-lg-3 col-sm-5 col-md-4 col-6">
                                                <button class="btn btn-warning btn-block" routerLink="/educationloan" target="_blank">Know more</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 col-lg-4 d-none d-md-block"><img alt=" Mobile screens | Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../../assets/images/mobiles-screens-education.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>