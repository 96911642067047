import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-benefits',
  templateUrl: './agent-benefits.component.html',
  styleUrls: ['./agent-benefits.component.scss']
})
export class AgentBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
