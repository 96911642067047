import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MemoryStorageService } from './core/services/memory-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hideFooter: boolean = false;
  hideHeader: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.hideFooter = params['hidefooter'] === 'true';
      this.hideHeader = params['hideheader'] === 'true';
    });
    window.addEventListener('beforeunload', this.clearStorage);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.clearStorage);
  }

  private clearStorage() {
    if(environment.production){
      MemoryStorageService.clear();
    }
  }
}
