<div class="main-banner d-flex align-items-center">
    <div class="row justify-content-end m-0">
        <div class="col-8 col-sm-6 col-md-6">
            <h1 title="What is travel on easy instalment?">What is travel on easy instalment? </h1>
            <p>Mihuru offers a flexible payment facility that allows you to split your travel costs over months instead
                of paying it all upfront. You can book your flights, holiday packages and hotels by paying a small
                deposit upfront. Balance payments are spread over months as per your credit terms. Our customers save
                40% on average on their bookings by booking months in advance and paying in instalments.
            </p>
        </div>
    </div>
</div>
<div class="section1">
    <div class="row justify-content-center">
        <div class="col-sm-9 col-11">
            <h2 title="Four Simple Rules For Traveling The World">Four Simple Rules For Traveling The World</h2>
            <p>Paying for your travel in instalments makes perfect sense but the secret sauce is combining your travel
                planning with our four simple-but-life-changing rules that can help you fulfil all your travel dreams in
                a financially responsible way.</p>
        </div>
    </div>
</div>
<div class="section2">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12 leftcontent">
                <h2>Rule 1: Book When Prices Are Low</h2>
                <blockquote class="blockstyle">
                    <p><b> Fact:</b> Ticket prices are at their lowest 2 to 3 months before the travel date.</p>
                </blockquote>
                <h3>How It Works</h3>
                <p><a href="/flights-search" target="_blank" class="interLink">Book your travel</a>
                    months in advance with around 25%<sup>*</sup> deposit and pay the rest in instalments. By
                    paying per month you can book early when prices are low, even before you have enough money for the
                    whole booking in the bank. The average person pays at least 60% more for flights because of price
                    surges, be smarter! Save thousands, by locking in prices months in advance.</p>
            </div>
            <!-- <div class="col-md-6 p-5 text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/splitfare-rule1.jpg" alt="splitfare-rule 1"> </div> -->
        </div>
    </div>
</div>
<div class="section3 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row leftcontent">
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img
                            src="assets/images/spitfare-withoutrule1.png" alt="spitfare-without rule 1"> </div>
                    <div class="col-sm-7 col-md-8">
                        <h3>Without rule 1</h3>
                        <p>You have &#8377; 8,000 in your account. Not enough to buy a &#8377; 20,000 flight ticket for
                            your trip in 3 months. So you plan to save money for 2 months to <a
                                href="/flights-search" target="_blank" class="interLink">book your
                                flight</a>. <br />
                            By that time arrives flight prices have increased to &#8377; 28,000. Your savings of &#8377;
                            20,000 are not enough and you need to cough up an additional &#8377; 8,000. You are
                            officially
                            stressed.</p>
                    </div>
                </div>
                <div class="row leftcontent">
                    <div class="col-sm-7 col-md-8">
                        <h3>With rule 1</h3>
                        <p>You have &#8377; 8,000 in your account. Instead of waiting to book until you've saved enough,
                            you
                            book your flight today by paying &#8377; 5,000 today. You then pay &#8377; 5,000 per month
                            for the next
                            three months. By the time your travel date approaches, you've paid off for your flight in
                            full or paid off a large part and are ready to enjoy your vacation.</p>
                    </div>
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/spitfare-withrule1.png"
                            alt="spitfare-with rule 1" class="imgRgtrotate"> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section4">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-md-12 leftcontent">
                        <h2>Rule 2: Give Every Rupee A Job</h2>
                        <blockquote class="blockstyle">
                            <p> <b>Fact:</b> 70% of travellers overspend because they didn't make a vacation budget.</p>
                        </blockquote>
                        <h3>How it works </h3>
                        <p> Easy instalment plans are built to solve the problems of today's traveller. Booking with us
                            tells you exactly what you will pay ahead of time, per month. No surprises. This gives you
                            enough time to plan your itinerary and budget way ahead of time.
                        </p>
                    </div>
                    <!-- <div class="col-md-6 p-5 text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/splitfare-rule2.png" alt="splitfare-rule 2"> </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section5 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row leftcontent">
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img
                            src="assets/images/spitfare-withoutrule2.png" alt="spitfare-without rule 2"> </div>
                    <div class="col-sm-7 col-md-8">
                        <h3>Without rule 2</h3>
                        <p>You are busy and planning is boring. Now it is time for the trip and you are hit with
                            expenses you hadn't budgeted for in the form of local transport, activities, sightseeing
                            entry tickets, foreign exchange fluctuations etc.</p>
                    </div>
                </div>
                <div class="row leftcontent">
                    <div class="col-sm-7 col-md-8">
                        <h3>With rule 2 </h3>
                        <p> You planned three months ahead of time. You've been budgeting money for your trip every
                            month and now it’s time for the trip. You can enjoy the trip of your dreams without worrying
                            about cutting-corners and compromising on what you enjoy while on vacation. No need to
                            over-spend on your credit cards or scramble last minute for additional funds. Good-bye,
                            financial crisis roller coaster!</p>
                    </div>
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/spitfare-withrule2.png"
                            alt="spitfare-with rule 2" class="imgRgtrotate"> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section6">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-md-12 leftcontent">
                        <h2>Rule 3: Be Flexible</h2>
                        <blockquote class="blockstyle">
                            <p> <b>Fact:</b>  The chance to overspend on vacation is due to not accounting for smaller
                                costs that often accumulate to form bigger amounts.</p>
                        </blockquote>
                        <h3>How It Works</h3>
                        <p>When you overspend in a certain vacation aspect, just adjust. No guilt necessary. You have a
                            vacation budget planned after following Rule 2 and are better prepared to reduce your
                            spending in another category to cover the overspending.</p>
                    </div>
                    <!-- <div class="col-md-6 p-5 text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/splitfare-rule3.png" alt="splitfare-rule 3"> </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section7 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row leftcontent">
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img
                            src="assets/images/spitfare-withoutrule3.png" />
                    </div>
                    <div class="col-sm-7 col-md-8">
                        <h3>Without rule 3 </h3>
                        <p> Budgets for travel sometimes don't work. When you overspend, you feel sad. The whole idea of
                            trying to ‘guess’ what else you will now have to spend, is making you uneasy. You don't
                            ‘need a budget’, you now ‘need a raise’. If you only earned just a bit more money,
                            everything would be fine. If only it were that simple!</p>
                    </div>
                </div>
                <div class="row leftcontent">
                    <div class="col-sm-7 col-md-8">
                        <h3>With rule 3</h3>
                        <p>You overspend (it happens) and your vacation budget nudges you – ‘Oops! You overspent on
                            sightseeing. No worries. Just move over some surplus money from shopping.’ <br />
                            Your vacation won't have any unpleasant aftereffects specially when the credit card bill
                            arrives the next month. </p>
                    </div>
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/spitfare-withrule3.png"
                            class="imgRgtrotate" /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section8">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-md-12 leftcontent">
                        <h2>Rule 4: Take The Long Term View</h2>
                        <blockquote class="blockstyle">
                            <p> <b>Fact:</b> Controlling our money feels good. Spending without limits feels bad. </p>
                        </blockquote>
                        <h3>How It Works</h3>
                        <p>Remember, you control the process of 
                            <!-- <a href="/Discover" target="_blank" class="interLink">planning your vacations</a> -->
                            planning your vacations. You control where you go
                            and
                            you often have a control on exactly how much you spend. Plan a vacation that you can afford
                            and enjoy within your means. You'll feel much more at peace because you aren't worrying
                            about how you're going to pay for things that are too expensive. You can always travel again
                            and plan a bigger trip later. Well you can never have enough of travel, right?</p>

                    </div>
                    <!-- <div class="col-md-6 p-5 text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/splitfare-rule4.jpg" alt="splitfare-rule 4"> </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section9 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row leftcontent">
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img
                            src="assets/images/spitfare-withoutrule4.png" alt="spitfare-without rule 4"> </div>
                    <div class="col-sm-7 col-md-8">
                        <h3>Without rule 4</h3>
                        <p> You are ‘free’ from the routines of work/home and having easy access to credit cards/money.
                            But, you then overspend on vacations by visiting all the tourist destinations, dining out at
                            restaurants, buying new clothes and tons of souvenirs. Your already expensive vacation soon
                            becomes unaffordable.
                        </p>
                    </div>
                </div>
                <div class="row leftcontent">
                    <div class="col-sm-7 col-md-8">
                        <h3>With rule 4</h3>
                        <p>If you don't have the money in hand to pay for the vacation you want, plan for it and start
                            saving - don't buy into the 'I'll pay for it later' rationalization. Smart planning with
                            Mihuru not only ensures you are staying away from any debt traps but also saves you up to
                            40% on your overall travel costs. This helps you do so much more with the budget you are
                            comfortable with!
                        </p>
                    </div>
                    <div class="col-sm-5 col-md-4 mainCol text-center"> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/spitfare-withrule4.png"
                            alt="spitfare-with rule 4" class="imgRgtrotate"> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="sectionLast">
    <div class="row">
        <div class="col-12">
            <h1>You can do this! </h1>
            <h3>Right now, for free!</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12"> <img class="sectionLast-img" src="assets/images/splitfare-discoverLimit.png">
            <p>Planning isn't old school. It's smart and the right way to spend money on travel. Apply for Mihuru easy
                instalments now and travel the right way - happy, peaceful and ‘like a boss’! </p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-8 col-sm-4 col-lg-3">
            <!-- <button class="lastbutton" (click)="GotoDiscLimit();">Discover your limit</button> --> 
            <!-- <a class="lastbutton btn-block" routerLink="/signup">Discover your Credit line</a> -->
            <a class="lastbutton btn-block" (click) = "moEngageDiscoverCreditLine()">Discover your Credit line</a>
        </div>
    </div>
</div>