<div class="privacyText" [innerHTML]="privacyText">
    <!-- <h3>Privacy Policy <br><small>Date: 10 December 2020</small></h3>
    <p><b>iTraveleo Private Limited (“Mihuru”)</b> respects your privacy and is committed to protecting and safeguarding
        the
        confidentiality of any and all personal information you provide to us. This Privacy Policy, together with our
        Terms
        of Use, sets out the approach that we at Mihuru, take to the collection, usage, protection and handling of any
        personal information and applies to all our websites, mobile apps and other services operated by Mihuru
        including
        newsletters. By using our website and the apps you are deemed to agree to our use of your information in
        accordance
        with the terms described in this policy.</p>
    <p>The terms “we”, “us”, “our website” and “Mihuru” refer to www.mihuru.com and the terms “you” and “your” refer to
        you,
        as a user of www.mihuru.com. The term “Personal Information” is information that is personally identifiable like
        your name, email address, phone number, mailing address, etc. and any other data that is tied to such
        information.
    </p>
    <p>iTraveleo Private Limited, (hereinafter referred to as the "Company" or “We” or “Us” or “Mihuru”) collects
        information from the user accounts created by individuals on the Mihuru platform as well as other digital
        sources of
        information relating to such individuals (each a “User”) as set out herein. Mihuru uses the information to
        undertake, among other things, an assessment of each Users’ credit worthiness which in turn may enable such
        Users to
        make applications for one or more instalment facilities to Mihuru or credit to financial institutions affiliated
        with Mihuru (each a “Mihuru Partner”). Each of the credit worthiness assessment and the application for a
        facility
        form part of the services offered by Mihuru to Users and Mihuru Partners (individually and collectively the
        “Services”) The Company collects information from Users at the time of account creation as well as on an
        on-going
        basis from their emails, social media profiles and such other sources as authorized by the users and mingles it
        to
        create unique profiles for individuals that it uses in conjunction with proprietary algorithms to determine
        whether
        or not a particular individual should be offered the Service.</p>
    <p>Please note that not all individuals whose information is collected by the Company will be offered the Instalment
        Facility Application part of the Service because the Service provides a dynamic evaluation of risk. Each offer
        of
        Service made by the Company is based on the Company’s proprietary and discretionary decision-making
        methodologies
        and also relies on the credit risk requirements specified by a Mihuru Partner.</p>
    <p>Please read and understand this Privacy Policy carefully. Your use or access of our website, apps and/or Services
        shall constitute your agreement to this Privacy Policy. If you do not agree with our policies and our practices
        as
        described in this Privacy Policy, your choice is to not use the Services. You will always have the ability to
        opt-in
        and opt-out of the Service by sending us a request at support@mihuru.com. However, the Company will retain
        certain
        of your information to the extent required to maintain a “Do Not Contact/Do Not Offer” list.</p>
    <p>BY ACCEPTING THE PRIVACY POLICY, YOU EXPRESSLY CONSENT TO COMPANY’S COLLECTION, RETENTION, ANALYSIS, USE AND
        DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY.</p>
    <ol>
        <li>What kind of information do we collect? <p>This Privacy Policy shall apply to all information the Company
                collects either from you or the Mihuru Partner(s). The information the Company collects for the
                provision of
                Services are as follows:</p>
            <ol class="list-unstyled">
                <li>1.1 Contact or Identification Information <p>This includes but is not limited to your name, email,
                        phone
                        number, passport number or other similar heads of information that allow us to accurately
                        identify
                        you.</p>
                </li>
                <li>1.2 Personal Information <ol class="list-unstyled">
                        <li>1.2.1 In General. We may collect Personal Information, including Sensitive Data, and other
                            information. “Personal Information” means individually identifiable information that would
                            allow
                            us to determine the actual identity of, and contact, a specific living person. “Sensitive
                            Data”
                            includes name, address, mailing address, telephone number, email ID, information about your
                            mobile phone and any details that may have been voluntarily provide by you in connection
                            with
                            availing the Services. By providing Sensitive Data to us, you consent to the collection, use
                            and
                            disclosure of Sensitive Data, as permitted by applicable privacy laws. By using the Service,
                            you
                            are authorizing us to gather, parse and retain data related to the provision of the
                            Services.
                        </li>
                        <li>1.2.2 The Company is the sole owner of the information collected through the Website. We may
                            collect and process Personal Information from you, including but not limited to the
                            following:
                            <ol class="list-alpha">
                                <li>Information provided by you at the time registration such as your email ID, phone
                                    number, bank account details, contact information, financial information, unique
                                    identifiers and preferences information including favourites and history. Providing
                                    additional information beyond what is required at the time of registration can be
                                    altered by you at any time;</li>
                                <li>Information that you provide us with directly via email and / or electronic
                                    communication;</li>
                                <li> Information that you provide to us over telephone, SMS or Whatsapp. We may make and
                                    keep a record of such information shared by you; </li>
                                <li>Information that you provide us in physical form whether sent through post and / or
                                    courier and / or handed over to our representatives in person; and</li>
                                <li>Information from other source</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>1.3 Transaction Information <p>This includes information such as historical data of your
                        transactions as
                        well as your consumption habits, items purchased, tickets purchased, items you have selected for
                        purchase, method of payment used or other similar heads of information based on the information
                        in
                        your bank statements and other digital sources of Information that you provided the Company with
                        access to. This information is collected to allow the Company to understand your transactional
                        activity which in turn informs and allows the Company to refine the risk assessment associated
                        with
                        You.</p>
                    <ol class="list-unstyled">
                        <li>1.3.1 Usage and Preference Information <p>We may collect information on your use of our
                                Services, and any settings and preferences selected. We may also assign you with unique
                                identifiers to enable us to identify you, each time you access the Services. This
                                information shall not be deleted from our servers if you decide to discontinue using our
                                Services, as it shall be the basic data with which we identify you and will be needed to
                                ensure that your other information is not stored or analysed by Us when you transact
                                with
                                one or more Mihuru Partners.</p>
                        </li>
                    </ol>
                </li>
                <li>1.4 Device Information <p>We may collect information about your mobile or computing device,
                        communication device, browser information, IP address including, for example, the hardware
                        model,
                        operating system and version, software and file names and versions, preferred language, unique
                        device identifier, advertising identifiers, serial number, device motion information, and mobile
                        network information or other similar heads of information that allow us to accurately identify
                        you.
                        We may also collect specific types of information stored in the devices of the User that enable
                        it
                        to provide the Service including contact lists of the User, specific applications installed by
                        such
                        User on his/her device and call logs.</p>
                </li>
            </ol>
        </li>
        <li>How do we collect information? <p>We collect information that you voluntarily provide to us during your use
                of
                the Services. This can range from Non-Personal Information, such as the dates and desired destination
                you
                asked us to run a flight search against, education courses you undertake with our partners, to the
                Personal
                Information you are required to submit to avail the Services. Whether or not you provide us with this
                kind
                information is always entirely up to you.</p>
            <ol class="list-alpha">
                <li>
                    <p>You understand that the User Information collected by the Company, may be collected:</p>
                </li>
                <li>
                    <p>When you register with the Company by setting up a user-account;</p>
                </li>
                <li>
                    <p>Through your transactions with any of the Mihuru Partners;</p>
                </li>
                <li>
                    <p>Directly through user input;</p>
                </li>
                <li>
                    <p>Through your usage of the Services;</p>
                </li>
                <li>
                    <p>Through automatic tracking of your usage of the website or any web/mobile application of Mihuru
                        or
                        Mihuru Partner</p>
                </li>
                <li>
                    <p>Through access you provide the Company with to applications on your desktop or smart phone or
                        tablet;
                    </p>
                </li>
                <li>
                    <p>Through the use of cookies and other tracking technologies.</p>
                </li>
            </ol>
            <p>In addition to information that you voluntarily provide to us, we also collect certain Non-Personal
                Information directly from you as you use our Services, as well as indirectly where it is provided to us
                automatically by the third-party application like social networking sites (such as Facebook, Instagram
                or
                Twitter) or other website (such as a promotion partner) that you connect to Mihuru from.</p>
            <p>We use web analytics software to show which pages on this website are visited most frequently and how
                long
                visitors spend on this website.</p>
            <p>Only emails and calendar entries containing travel information are accessed by our proprietary algorithm
                in
                an encrypted format. We respect your privacy and confidentiality & do not access any other information
                from
                your inbox.</p>
        </li>
        <li>How do we use information? <ol class="list-alpha">
                <li>
                    <p>To verify your authorization to access Mihuru and deliver our Services</p>
                </li>
                <li>
                    <p>To customize and personalize our Services and content</p>
                </li>
                <li>
                    <p>To optimize your visits by remembering your location, language, currency and other configurations
                        along with previous searches</p>
                </li>
                <li>
                    <p>To deliver appropriate advertising </p>
                </li>
                <li>
                    <p>To notify you about any updates on our services </p>
                </li>
                <li>
                    <p>To better understand, improve and protect our Services</p>
                </li>
                <li>
                    <p>To generate aggregated datasets that inform our business and the travel industry</p>
                </li>
                <li>
                    <p>To contact you if you have subscribed to our newsletters</p>
                </li>
                <li>
                    <p>To provide services to you, including monitoring your bookings, receipts, claims, and returns,
                        and
                        responding or updating you on inquiries or to contact you about your accounts</p>
                </li>
                <li>
                    <p>To enable us to comply with our legal and regulatory obligations</p>
                </li>
                <li>
                    <p>For the purpose of sending administrative notices, Service-related alerts and other similar
                        communication with a view to optimizing the efficiency of the Website;</p>
                </li>
                <li>
                    <p>Doing market research, troubleshooting, protection against error, project planning, fraud and
                        other
                        criminal activity; and</p>
                </li>
                <li>
                    <p>To enforce our Terms of Use.</p>
                </li>
            </ol>
            <p>Usage of any information collected from your Google account (GMAIL APIs and/or Calendar API), i.e. travel
                information is limited to providing you features such as your personalized World travel map and to
                automate
                your travel itineraries.</p>
            <p>This information will not be used to deliver advertising or generate aggregated datasets that inform our
                business and the travel industry</p>
            <p>We will not allow humans to read this data unless we have your affirmative agreement for specific
                messages,
                doing so is necessary for security purposes such as investigating abuse, to comply with applicable law,
                or
                for Mihuru's internal operations and even then only when the data have been aggregated and anonymized.
            </p>
            <p>Our use of information received from Gmail APIs & calendar APIs will adhere to Google's Limited Use
                Requirements.</p>
        </li>
        <li>How we share your information? <p>When you make a booking with a third-party travel provider through Mihuru,
                any
                information you submit as part of the booking process may be collected by that third party in order to
                process the booking. Those third parties, in accordance with their own terms and conditions and privacy
                policy, will use your information. Additionally, third-party advertisement services may also collect
                Non-Personal Information from you as you use our Services via cookies and other similar technologies.
            </p>
            <ol class="list-unstyled">
                <li>
                    <p>4.1 Sharing. Other than as mentioned elsewhere in this Privacy Policy, we may share aggregated
                        demographic information with our partners or affiliates. This is not linked to any personal
                        information that can identify an individual person. We will not be liable for transfer of any
                        personal identification information resulting from loss or distribution of data, the delineation
                        or
                        corruption of media storage, power failures, natural phenomena, riots, act(s) of vandalism,
                        sabotage, terrorism or any other event beyond our control. Further, our Privacy Policy does not
                        cover the use of cookies by our partners and affiliates since we do not have access or control
                        over
                        such cookies.</p>
                </li>
                <li>
                    <p>4.2 Consulting. We may partner with other parties to provide specific portions of the Services,
                        if
                        required. When you sign-up for the Services, we will share names, or other Personal Information
                        that
                        is necessary for the third party to provide these Services. Our contractual arrangements with
                        such
                        third parties restrict these parties from using personally identifiable information except for
                        the
                        explicit purpose of assisting in the provision of these Services.</p>
                </li>
                <li>
                    <p>4.3 Promotional Offers. We may send you offers, which may be on behalf of other businesses. When
                        we
                        do this, we do not give that business your name, address and details in relation to the Services
                        availed. If you do not want to receive such offers, you may request us to adjust your
                        preferences.
                    </p>
                </li>
            </ol>
        </li>
        <li>When do we share your information? <p>The User Information you provide may be disclosed by the Company to
                its
                agents, employees, subsidiaries and affiliates, or to other third-party service providers of the Company
                who
                require the information for the purposes of operating and maintaining the Company’s platform for the
                provision of Services. Particularly, the Company will disclose the User Information to the Mihuru
                Partner to
                the extent necessary for such Mihuru Partner to process and approve a Credit Facility Application
                submitted
                by you.</p>
            <p>If you are purchasing travel on our platform, we may share information (including Personal Information)
                with
                the travel provider for their standard verification and fraud-detection purposes, or to a third party
                contracted to provide those services on the travel provider’s behalf.</p>
            <p>Non-Personal Information, such as fully anonymous datasets containing aggregated Services’ usage
                information
                or trend-level travel insights, may be shared by us with third parties. These datasets will not include
                any
                information collected from your Google account (GMAIL APIs and/or Calendar API) whose sole purpose is to
                provide you features in your usage of this website.</p>
            <p>The Company will comply with requests and directions of all governmental, law enforcement, or regulatory
                authorities, which it believes in good faith to be in accordance with any applicable law. Such
                compliance
                may include providing User Information whether it is personally identifiable information or not, to such
                agency or authority. By providing any information to the Company, you consent to the Company providing
                such
                information to any governmental, law enforcement or regulatory authorities who exercise jurisdiction
                over
                the Services provided and the Company.</p>
            <p>Our use of information received from Gmail APIs & calendar APIs will adhere to Google's Limited Use
                Requirements.</p>
        </li>
        <li>Change of Information <p>If your Personal Information or Sensitive Data changes, you may correct, delete
                inaccuracies, or amend information by making the change by contacting us through email address mentioned
                on
                our Website. We will make good faith efforts to make requested changes in our then active databases as
                soon
                as reasonably practicable.</p>
        </li>
        <li>For legal purposes <p>We may disclose your information where necessary to enforce our Terms of Use or other
                agreements, or to a prospective buyer or ultimate buyer if Mihuru itself (or part of our business) is
                sold.
                We may also disclose your information if necessary to prevent, detect or prosecute illegal or suspected
                illegal activities or to prevent other damage or in response to a legal action against us or to enforce
                our
                rights and claims.</p>
            <p>Information collected from you may be transferred to a third party(ies) as a result of a sale or
                acquisition,
                merger or bankruptcy involving the Company.</p>
        </li>
        <li>How do we safeguard your information? <p>We limit access to Personal Information about you to employees who
                we
                need to come into contact with that information to provide products or services to you or in order to
                fulfil
                their role. We also employ procedural and technical measures reasonably designed to protect your
                Personal
                Information from loss, misuse, unauthorized access or disclosure. Although we do our best to protect
                your
                Personal Information, the transmission of information via the Internet can never be completely secure
                and we
                are therefore unable to guarantee the security of the information, which you share with us at your own
                risk.
            </p>
        </li>
        <li>Other Links and Widgets <ol class="list-unstyled">
                <li>
                    <p>9.1 The Website may contain links to other sites. The linked sites are not necessarily under our
                        control. Please be aware that we are not responsible for the privacy practices of such other
                        sites.
                        We encourage you to read the privacy policies of each and every website that collects personally
                        identifiable information. If you decide to access any of the third-party sites linked to the
                        Website, you do this entirely at your own risk. Any links to any of our partners should be the
                        responsibility of the linking party, and we will not be responsible for notification of any
                        change
                        in name or location of any information on the Website.</p>
                </li>
                <li>
                    <p>9.2 The Website includes social media features, such as widgets or interactive mini-programs that
                        run
                        on the Website. These features may collect your IP address, the page which you are visiting on
                        the
                        Website, and may set a Cookie to enable the feature to function properly. Social media features
                        and
                        widgets are either hosted by a third party or hosted directly on the Website. Your interactions
                        with
                        these features are governed by the privacy policy of the entity providing it and not the
                        Company.
                    </p>
                </li>
            </ol>
        </li>
        <li>Privacy Policy <p>If we change our privacy policies and procedures, we will post the latest version on this
                page
                –please check this page regularly to remain up-to-date. We may update this Privacy Policy at any time,
                with
                or without advance notice. In the event there are significant changes in the way we treat your
                personally
                identifiable information, or in the Privacy Policy document itself, we will display a notice on the
                Website
                or send you an email informing you of such changes, so that you may review the changed terms prior to
                continuing to use the Website and our Services. Using the Company’s Services or accessing the Website
                after
                a notice of changes has been sent to you or published on the Website will constitute your consent to the
                changed terms.</p>
        </li>
        <li>Address for Privacy related questions <ol class="list-unstyled">
                <li>
                    <p>11.1 We respect and are sensitive to the rights as granted in the data protection laws. Should
                        you
                        have questions about the Privacy Policy or our information collection, use and disclosure
                        practices,
                        you may contact the Grievance Officer as per the details given hereinbelow. We will use
                        reasonable
                        efforts to respond promptly to requests, questions or concerns you may have regarding the use of
                        your personal information.</p>
                </li>
                <li>
                    <p>11.2 In accordance with the Information Technology Act, 2000 and the rules made thereunder,
                        contact
                        details of the Grievance Office at <a href="mailto: care@mihuru.com" target="_blank"
                            class="interLink">care@mihuru.com</a> You may contact the Grievance Office to address
                        any discrepancies and grievances you may have with respect to your information available with
                        us.
                        The Grievance Office will redress your grievances expeditiously.</p>
                </li>
            </ol>
        </li>
    </ol> -->

</div>