import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-salary-upload',
  templateUrl: './salary-upload.component.html',
  styleUrls: ['./salary-upload.component.scss']
})
export class SalaryUploadComponent implements OnInit {
  baseUrl!: string;
  constructor(private router: Router, private accountService: AccountService, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService ) {
    this.baseUrl = environment.apiUrl + 'assistmodule/uploadbusinessdetails';
  }

  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
  }

  ProceedToDocUpload(response: any) {
    let obj = {
      "occupationType": 'Salaried',
      "companyName": '',
      "companyType": '',
      "professionIdType": '',
      "professionId": '',
      "isProprietor": false,
      "isGST": false
    }
    this.accountService.uploadBusinessDetails(obj).subscribe(
      (res) => {
        //navigate to next page
        this.router.navigateByUrl('/assist-journey/assist-underwrite');
      }
    )
  }
}
