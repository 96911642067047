import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { User } from '../models/user';
import {
  UserPersonalDetails,
  UserProfessionalDetail,
} from '../models/applicationUser';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { CurrentApplication } from '../models/CurrentApplication';
import { CustomeResponse } from '../models/cusomeresponse';
import { TruecallerCallBackToken } from '../models/truecallerCallBackToken';
import { Configuration } from '../models/configuration';
import { environment } from '../../../environments/environment';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { PersonalInfo, Plan } from '../models/personalInfo';
import { CurrentApplicationDesc } from '../models/CurrentApplicationDesc';
import swal from 'sweetalert2';
import { MemoryStorageService } from './memory-storage.service';

declare var webengage: any;
declare var Moengage: any;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  baseUrl: string = '';

  baseAirtableHotelUrl: string = '';
  baseAirtableFlightUrl: string = '';
  baseAirtableKey: string = '';
  logoUrlApi :string ='';
  logoApiAuthKey :string ='';

  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();

  public defaultHeaders: Headers = new Headers();
  public configuration: Configuration = new Configuration();
  private brandLogoUrl =new BehaviorSubject<string>('');
  partnerLogo =this.brandLogoUrl.asObservable();

  //use HttpBackend to call http client without interceptor...
  constructor(private httpClient: HttpClient, private router: Router) {
    this.baseUrl = environment.apiUrl;
    this.baseAirtableKey = environment.apiAirtableKey;
    this.baseAirtableHotelUrl = environment.apiAirtableHotel;
    this.baseAirtableFlightUrl = environment.apiAirtableFlight;
    this.setCurrentUser(JSON.parse(MemoryStorageService.getItem('user')));
    this.logoUrlApi =environment.LogoApiUrl;
    this.logoApiAuthKey =environment.LogoApiAuthKey;
  }

  setPartnerLogo(url: string)
  {
    this.brandLogoUrl.next(url);
  }

  setMoengageUser(token: string){
    const header = { Authorization: 'Bearer ' + token };
    this.httpClient.get<CurrentApplicationDesc>(this.baseUrl + 'application/getcurrentapplicationstatusdesc', {
      headers: header,
    }).subscribe({
      next: (applicationdata) => {
        Moengage.add_unique_user_id(applicationdata['contact']);
      },
      error: (err) => {
        console.error('Error fetching application data', err);
      }
    });
  }
  
  DigioTest(mId: any, cId: any) {
    var options = {
      //default environment staging (sandbox)
      //for production include: “environment” : “production”; here
      callback: function (t) {
        if (t.error_code != undefined)
          swal.fire('failed to register with error :' + t.message);
        else swal.fire('register successful for ' + t.digio_doc_id);
      },
      //"logo":"www.your-website/logo-image", //Pass Logo URL here
      is_iframe: true, //open in iframe,
      dg_preferred_auth_type: 'debit', // debit or net_banking
    };
    var digio = new digio(options);
    digio.init();
    digio.submit(mId, cId);
  }

  addToAirtableHotel(obj: any) {
    var headersAirtbale = { Authorization: 'Bearer ' + this.baseAirtableKey };
    return this.httpClient.post(this.baseAirtableHotelUrl, obj, {
      headers: headersAirtbale,
    });
  }

  addToAirtableFlight(obj: any) {
    var headersAirtbale = { Authorization: 'Bearer ' + this.baseAirtableKey };
    return this.httpClient.post(this.baseAirtableFlightUrl, obj, {
      headers: headersAirtbale,
    });
  }

  CreateCoreFactorFlightLead(model) {
    return this.httpClient.post(
      this.baseUrl + 'CoreFactor/setflightsearch',
      model
    );
  }
 getLogoUrl(model:any)
{
  let logoHeader = new HttpHeaders().set('Authorization', 'Bearer '+this.logoApiAuthKey);
  return this.httpClient.post(this.logoUrlApi,model,{ headers: logoHeader });
 }
  CreateCoreFactorHotelLead(model) {
    return this.httpClient.post(
      this.baseUrl + 'CoreFactor/sethotelsearch',
      model
    );
  }

  setCurrentUser(user: User) {
    this.currentUserSource.next(user);
  }

  setCurrentApplicationStatus(model) {
    return this.httpClient.post(
      this.baseUrl + 'application/setApplStatus',
      model
    );
  }

  login(model: any) {
    return this.httpClient.post(this.baseUrl + 'user/login', model).pipe(
      map((response: User) => {
        const user = response;
        if (user) {
          //webengage.user.login(user.contact);

          MemoryStorageService.setItem('user', JSON.stringify(user));
        }
      })
    );
  }

  logout() {
    console.log('in logout method in service');
    MemoryStorageService.removeItem('user');
    this.currentUserSource.next(null);
    Moengage.destroy_session();
  }

  register(model: any) {
    console.log('In account service:', model);
    let user = {
      Email: model.email,
      PhoneNumber: model.mobileNumber,
      whatsappContactAllow: model.whatsappChecked,
      PartnerId: model.partnerId,
      UtmSource:
        Cookie.get('utm_source') == 'undefined' ? '' : Cookie.get('utm_source'),
      UtmMedium:
        Cookie.get('utm_medium') == 'undefined' ? '' : Cookie.get('utm_medium'),
      UtmCampaign:
        Cookie.get('utm_campaign') == 'undefined'
          ? ''
          : Cookie.get('utm_campaign'),
    };

    //this.httpClient = new HttpClient(this.httpBackend);
    return this.httpClient.post(this.baseUrl + 'user/register', user).pipe(
      map((response: User) => {
        const user = response;
        if (user) {
          // webengage.user.login(user.contact);/// change default value
          // Moengage.add_unique_user_id(user.contact); // COMMENTED ON 28-FEB-2022 AND MOVED TO VERIFYOTP

          // Moengage.add_first_name("Dominick");
          // Moengage.add_last_name("Cobb");
          // Moengage.add_email("dom@level5.com");
          // Moengage.add_mobile("+12399999999");
          // Moengage.add_user_name("Dominick (Dom) Cobb"); // Full name for user
          // Moengage.add_gender("M");
          // Moengage.add_birthday(new Date(1980, 2, 31));

          console.log(user.contact);
          MemoryStorageService.setItem('user', JSON.stringify(user));
          //this.currentUserSource.next(user);
        }
      })
    );
  }

  sendEmailOTP(email) {
    let user = {
      Email: email,
    };

    return this.httpClient.post(this.baseUrl + 'EmailOtp/Otp', user);
  }

  setHubspotUTM(obj) {
    return this.httpClient.post(this.baseUrl + 'HubspotUTM/setUTM', obj);
  }

  verifyEmailOTP(loginData) {
    return this.httpClient
      .post(this.baseUrl + 'EmailOtp/verifyotp', loginData)
      .pipe(
        map((response: User) => {
          const user = JSON.parse(MemoryStorageService.getItem('user'));
          if (user) {
            this.currentUserSource.next(user);
          }
          console.log(response, 'response-----');
          // const user = JSON.parse(MemoryStorageService.getItem("user"));
          // if (user) {
          // this.currentUserSource.next(user);
          // }
        })
      );
  }

  resendEmailOTP(email) {
    return this.httpClient
      .post(this.baseUrl + 'EmailOtp/resendOtp', email)
      .pipe(
        map((response: User) => {
          console.log(response, 'response-----');
          const user = response;
          // if (user) {
          // MemoryStorageService.setItem('user', JSON.stringify(user));
          // }
        })
      );
  }

  getPartnersLogos(category) {
    return this.httpClient.post(
      this.baseUrl + 'PartnerLogs/PartnerLogs',
      category
    );
  }

  notifyMe(applicationId) {
    return this.httpClient.post(
      this.baseUrl + 'NotifyMe/notifyMe',
      applicationId
    );
  }

  GetUserDetailsByEmail(email) {
    var UserEmail = {
      email: email,
    };
    return this.httpClient.post(
      this.baseUrl + 'application/GetUserByEmail',
      UserEmail
    );
  }

  verifyOTP(model) {
    let user = {
      Email: model.email,
      MobileNumber: model.mobileNumber,
      Otp: model.otp,
      registration: model.registration,
      platform: model.platform,
    };

    console.log('In account service: verifyOTP', user);

    const userObj = JSON.parse(MemoryStorageService.getItem('user'));
    Moengage.add_unique_user_id(userObj.contact);

    return this.httpClient.post(this.baseUrl + 'user/verifyotp', user).pipe(
      map((response: User) => {
        const user = JSON.parse(MemoryStorageService.getItem('user'));
        if (user) {
          this.currentUserSource.next(user);
          return response;
        }
      })
    );
  }

  resendOTP(model) {
    let user = {
      Email: model.email,
      mobileNumber: model.mobileNumber,
    };

    console.log('In account service: resendOTP', user);
    return this.httpClient.post(this.baseUrl + 'user/resendotp', user);
  }

  resendOTPMobile(mobileno, partnerToken) {
    let user = {
      mobileNo: mobileno,
    };

    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + partnerToken);
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/resendotp',
      user,
      { headers: headers1 }
    );
  }

  sendOTP(model) {
    let user = {
      Email: model.email,
      mobileNumber: model.mobileNumber,
    };

    console.log('In account service: resendOTP', user);
    return this.httpClient.post(this.baseUrl + 'user/sendotp', user);
  }

  userDetailsByPan(panId) {
    return this.httpClient.get(this.baseUrl + 'user/' + panId);
  }

  userPersonalDetails() {
    return this.httpClient.get(this.baseUrl + 'Userdetail/getPersonalDetails');
  }

  userProfessionalDetails() {
    return this.httpClient.get(
      this.baseUrl + 'Userdetail/getProfessionalDetails'
    );
  }

  updateUserPersonalDetail(userPersonalDetails: UserPersonalDetails) {
    console.log('In account service:', userPersonalDetails);

    //this.httpClient = new HttpClient(this.httpBackend);

    return this.httpClient
      .post(this.baseUrl + 'userdetail/updateuser', userPersonalDetails)
      .pipe(
        map(
          (response: UserPersonalDetails) => {
            const user = response;
            if (user) {
              console.log('after response in account service component');
              console.log(user);
            }
          },
          (error) => {
            swal.fire(error.errors);
          }
        )
      );
  }

  createUserProfessionalDetail(
    userProfessionalDetails: UserProfessionalDetail
  ) {
    console.log('In account service:', userProfessionalDetails);

    return this.httpClient
      .post<CustomeResponse>(
        this.baseUrl + 'userdetail/createuserprofdetail',
        userProfessionalDetails
      )
      .pipe(
        map(
          (response: CustomeResponse) => {
            const userProfDetail = response;
            if (userProfDetail) {
              console.log('after response in account service component');
              console.log(userProfDetail);
              return userProfDetail;
            }
          },
          (error) => {
            swal.fire(error.errors);
          }
        ),
        shareReplay()
      );
  }

  getCurrentApplicationStatus() {
    return this.httpClient.get<CurrentApplication>(
      this.baseUrl + 'application'
    );
  }

  getCurrentApplicationStatusDesc() {
    return this.httpClient.get<CurrentApplicationDesc>(
      this.baseUrl + 'application/getcurrentapplicationstatusdesc'
    );
  }

  getAgentDetails(agentid: string) {
    return this.httpClient.get(this.baseUrl + 'agent/' + agentid);
  }
  generatePartnerToken(req: any) {
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/login/apiuser',
      req
    );
  }
  signup(req: any, partnerToken: any) {
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + partnerToken);
    return this.httpClient.post(this.baseUrl + 'assistmodule/v1/signup', req, {
      headers: headers1,
    });
  }
  submitOtp(req: any, partnerToken: any) {
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + partnerToken);
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/v2/submitotp',
      req,
      { headers: headers1 }
    );
  }
  //resend
  resendOtp(req: any, partnerToken: any) {
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + partnerToken);
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/resendotp',
      req,
      { headers: headers1 }
    );
  }
  generateTravelPlans(req:any, customerToken:any){
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + customerToken);
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/v1/confirmpersonaldetails',
      req,
      { headers: headers1 }
    );    
  }
  generatePaymentOrder(req:any, customerToken:any){
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + customerToken);
    return this.httpClient.post(
      this.baseUrl + 'PaymentGateway/Razorpay/GenerateOrder',
      req,
      { headers: headers1 }
    );    
  }  
  getLatestDocument(value: any) {
    return this.httpClient.get<any>(
      encodeURI(this.baseUrl + 'assistmodule/GetLatestDocument/' + value)
    );
  }

  PollTrueCallerCheck(
    TCdata: TruecallerCallBackToken,
    extraHttpRequestParams?: any
  ): Observable<any> {

    const path = this.baseUrl + 'Truecaller/polltruecallercheck';
    let queryParameters = new URLSearchParams();
    let formParams = new URLSearchParams();

    var headers = { 'Content-Type': 'application/json' };
    var data = TCdata == null ? '' : JSON.stringify(TCdata);

    return this.httpClient.post(path, data, { headers: headers });
  }

  getDestinationDataById() {
    return this.httpClient.get(
      this.baseUrl + `UserDashboard/getDestinationData`
    );
  }

  getCurrentLocation() {
    return this.httpClient.get(`https://ipapi.co/json/`);
  }

  getpersonalDetails() {
    return this.httpClient.get(
      this.baseUrl + `UserDashboard/getUserpersonalDetails`
    );
  }

  getSaticContent() {
    return this.httpClient.get(this.baseUrl + `UserDashboard/getStaticContent`);
  }

  createApplication(obj: any) {
    return this.httpClient
      .post(this.baseUrl + `Application/createApplication`, obj)
      .pipe(
        map((response: User) => {
          const user = response;

          if (user) {
            if (user['Message'] == 'Active Application exists') {
            } else {
              MemoryStorageService.setItem('user', JSON.stringify(user));
              this.currentUserSource.next(user);
              this.router.navigateByUrl(
                '/application/appl/(application:personal)'
              );
            }
          }
        })
      );
  }

  getDestinationFacts(model: any) {
    return this.httpClient.post(
      this.baseUrl + `UserDashboard/getDestinationFactsData`,
      model
    );
  }

  getEmiValue(data) {
    return this.httpClient.post(this.baseUrl + `UserDashboard/getEmi`, data);
  }

  updateProfilePics(data) {
    return this.httpClient.post(
      this.baseUrl + `UserDashboard/updateProfilePics`,
      data
    );
  }

  getCheapestFlight(FlightRequest) {
    return this.httpClient.post(
      this.baseUrl + `UserDashboard/getCheapestFlight`,
      FlightRequest
    );
  }

  getCreditLimitAmount() {
    return this.httpClient.get(
      this.baseUrl + `UserDashboard/userApprovedCreditLimit`
    );
  }

  getUserBookingFlightDetails() {
    return this.httpClient.get(this.baseUrl + 'Flight/getBookingDetails');
  }

  GetTravelPlan(planID: string) {
    return this.httpClient.get<PersonalInfo>(
      this.baseUrl + 'travelplan/' + planID
    );
  }
  GetProposedPlans() {
    return this.httpClient.get<Plan[]>(this.baseUrl + 'ProposedEMIPlans');
  }

  ResendAadhaarOtp(obj: any) {
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/v1/createUserProfile',
      obj
    );
  }

  uploadBusinessDetails(obj: any) {
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/uploadbusinessdetails',
      obj
    );
  }

  getNachLiveDestinationBanks(obj: any) {
    return this.httpClient.post(
      this.baseUrl + 'enachapi/getnachlivedestinationbankslist',
      obj
    );
  }

  setApplStatusReasonHistories(ObjStatusReason: any) {
    return this.httpClient.post(
      this.baseUrl + 'application/setApplStatusReasonHistories',
      ObjStatusReason
    );
  }
  //MonedoBank list
  getBankList(bankCode) {
    return this.httpClient.get(this.baseUrl + 'Menedo/getbanklist/' + bankCode);
  }

  GetNachStatus(uniqueId: string){
    return this.httpClient.get(this.baseUrl + 'menedo/fetchmendatestatus/' + uniqueId);
  }

  UpdateMandateStatus(obj: any){
    return this.httpClient.post(
      this.baseUrl + 'menedo/updatemandatestatus',
      obj
    );
  }

  UpdateAutoPayMandateStatus(obj: any){
    return this.httpClient.post(
      this.baseUrl + 'menedo/updatemandatestatus',
      obj
    );
  }

  CheckMandate(token: string){
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient.get(
      this.baseUrl + 'menedo/checkmandate',
      { headers: headers1 }
    ); 
  }

  checkUpdateDownpayment(obj: any){
    return this.httpClient.post(
      this.baseUrl+ 'menedo/CheckUpdateDownpayment', obj
    );
  }

  GenerateDownpaymentLink(obj: any){
    return this.httpClient.post(
      this.baseUrl + 'menedo/GenerateDownPaymentLink',
      obj
    );
  }

  FetchDownPaymentStatus(merchantTxnId: string){
    return this.httpClient.post(
      this.baseUrl + 'menedo/DownpaymentTransactionStatus', { search_value:  merchantTxnId }
    );
  }

  updateNACHApplicationStatus(mandateId: string, status: number){
    return this.httpClient.post(
      this.baseUrl + 'ENachAPI/enachupdateformstatus', { ENachUpdateFormStatusRequest: { MandateId: mandateId, Status: status }  }
    );
  }

  generateLoanAgreementURL(status: string, docId: string, message: string){
    return this.httpClient.get(
      this.baseUrl + 'ENachAPI/enachredirecturl?status=' + status + '&digio_doc_id=' + docId + '&message=' + message
    );
  }

  fetchLoadAgreementStatus(AgreementId: string){
    return this.httpClient.get(
      this.baseUrl + 'LoanAgreement/fetchloanagreementstatus?agreementid=' + AgreementId
    );
  }

  updateLoanAgreementStatus(status: string, agreementId: string){
    return this.httpClient.post(
      this.baseUrl + 'LoanAgreement/updateLoanAgreement', { status:  status, LoanAgreementId: agreementId }
    );
  }

  GetTempRequestData(data: string) {
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/GetTempRequestData', { data: data}
    );
  }

  GetSignInDataIfExist(mobileNo: string){
    return this.httpClient.post(
      this.baseUrl + 'assistmodule/GetRequestTempByMobileNo', { mobileNo: mobileNo}
    );
  }

  GenerateChangedPlan(){
    return this.httpClient.get(
      this.baseUrl + 'assistmodule/PlanGenerate/1'
    ); 
  }

  UpdateAlternativeNumberForCredit(mobileNo: string, token: string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` 
    });
    return this.httpClient.get((this.baseUrl + 'assistmodule/UpdateAlternetNumber/' + mobileNo),  { headers } );
  }
}
