import { Pipe, PipeTransform } from '@angular/core';
//import { Airlines, IAirlines } from "app/shared/airlines";
//import { List } from "linqts/dist/linq";

@Pipe({ name: 'airlineCodeConvert' })
export class AirlineCodeConvertPipe implements PipeTransform {
    transform(value: any): string {
        try {
            if (value === undefined || value === null)
                return value;

            // let l = new List<IAirlines>(Airlines.List).FirstOrDefault(x => x.Code == value);
            // if (l === undefined || l === null)
            //     return value;

            //return l.Airline;
        }
        catch(e){
            return "Exp";
        }
  }
}