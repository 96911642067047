<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Please hold on</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row mt-4 justify-content-center">
                    <div class="col-10 text-center">
                        <h6>Your application for credit line is processing! </h6>
                       
                        <p>Our team will contact you the moment your application has been evaluated. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>

</div>