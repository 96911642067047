import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var Moengage ; 
@Component({
  selector: 'app-split-fare',
  templateUrl: './split-fare.component.html',
  styleUrls: ['./split-fare.component.scss']
})
export class SplitFareComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  moEngageDiscoverCreditLine(){
    //Moengage.track_event("App Content Refreshed", {"Split Fare": "Discover Your Credit Line", "moe_non_interactive": 1})
    Moengage.track_event("Split Fare - Discover Your Credit Line", {"Split Fare": "Discover Your Credit Line", "moe_non_interactive": 1})
    this.router.navigateByUrl('/signup');
  }

}
