import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
declare let $: any;

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  selectedTab : string = "";
  privacyText:string = "";
  constructor( private activatedRoute: ActivatedRoute) { 
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.selectedTab = params.get('tab');
      this.activaTab(this.selectedTab);
    })
  }

  activaTab(tab) {
    $('.nav-tabs a[href="#' + tab + '"]').tab('show');
  };

}
