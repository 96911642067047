import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MemoryStorageService {
  private static data: { [key: string]: any } = {};
  // Use localStorage in development, in-memory storage in production
  static setItem(key: string, value: any): void {
    if (!environment.production) {
      localStorage.setItem(key, value);
    } else {
      this.data[key] = value;
    }
  }
  static getItem(key: string): any {
    if (!environment.production) {
      const value = localStorage.getItem(key);
      return value ? value : null;
    } else {
      return this.data[key] !== undefined ? this.data[key] : null;
    }
  }
  static removeItem(key: string): void {
    if (!environment.production) {
      localStorage.removeItem(key);
    } else {
      delete this.data[key];
    }
  }
  static clear(): void {
    if (!environment.production) {
      localStorage.clear();
    } else {
      this.data = {};
    }
  }
}