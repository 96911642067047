import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/core/services/account.service';
import { GeolocationService } from '../geolocation.service';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Collapse } from 'bootstrap';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';

export interface iSignup {
  mobileNo: string;
  lastName: string;
  firstName: string;
  email: string;
  monthlyIncome?: number;
  travelType: string;
  travelDate: string;
  origin: string;
  destination: string;
  travelCost?: number;
  partnerTransactionId: string;
  travelAgentName: string;
  travelBrandName: string;
  travelAgentEmailId: string;
  travelAgentMobile: string;
  longitude: number;
  latitude: number;
  ipAddress: string;
  partnerId: string;
}

/**
 * Represents the component for signing up for assistance.
 */
@Component({
  selector: 'app-assist-sign-up',
  templateUrl: './assist-sign-up.component.html',
  styleUrls: ['./assist-sign-up.component.scss'],
})
export class AssistSignUpComponent implements OnInit {
  /**
   * Reference to the form element.
   */
  @ViewChild('form', { read: NgForm }) form!: NgForm;
  @ViewChild('collapseTwo') collapseTwo: ElementRef;
  @ViewChild('collapseThree') collapseThree: ElementRef;

  /**
   * Represents the sign up data.
*/


  signUp: iSignup = {
    mobileNo: '',
    email: '',
    firstName: '',
    lastName: '',
    monthlyIncome: 0,
    travelDate: '',
    origin: '',
    destination: '',
    travelCost: 0,
    partnerTransactionId: '1',
    travelAgentName: '',
    travelBrandName: '',
    travelAgentEmailId: '',
    travelAgentMobile: '',
    longitude: 0,
    latitude: 0,
    ipAddress: '',
    travelType: '',
    partnerId:''
  };
  /**
   * Indicates whether to show personal details or not.
   */
  showPersonalDetails: boolean = false;

  /**
   * Indicates whether to show travel details or not.
   */
  showTravelDetails: boolean = false;

  /**
   * The minimum date for selecting a travel date.
   */
  minDate: string;

  /**
   * The maximum date for selecting a travel date.
   */
  maxDate: string;

  /**
   * Represents the error message.
   */
  error: string;

  /**
   * The IP address.
   */
  ipAddress: string;

  /**
   * The travel agent ID.
   */
  travelAgentId: string = '';

  travelDateValidate = false;

  disableTravelDetail = true;
  disableIncomeDetail = true;
  disableAll =  false;


  constructor(
    private accountService: AccountService,
    private geolocationService: GeolocationService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionTimerService :SessionTimerService,
    private datePipe: DatePipe
  ) { }

  /**
   * Initializes the component.
   */
  mobileRegexPattern = environment.mobileRegexPattern;
  ngOnInit(): void {
    this.spinner.hide();
    //Clear MemoryStorageService
    MemoryStorageService.clear();

    const date = new Date();
    const dateNumber = date.getTime();
    this.signUp.partnerTransactionId = dateNumber.toString();

    this.geolocationService
      .getCurrentPosition()
      .then((position) => {
        this.signUp.latitude = position.latitude;
        this.signUp.longitude = position.longitude;
      })
      .catch((error) => {
        this.error = error.message;
      });


      if (this.route.snapshot.queryParamMap.get('hideheader')||this.route.snapshot.queryParamMap.get('hidefooter')) {
        
      }
      //utm_source means agent id which can be found in Airtable TA-India 
    if (this.route.snapshot.queryParamMap.get('utm_source') != null) {      
      this.travelAgentId = this.route.snapshot.queryParamMap
        .get('utm_source')
        ?.toString()
        .toUpperCase();
        this.signUp.partnerId = this.travelAgentId;
        //set partner logo based on Partner
       this.partnerLogo();
    }
    else{
      MemoryStorageService.removeItem('partnerLogourl');
    }

    // For Hiding Timer
    this.sessionTimerService.SetShowTimer(false);
    MemoryStorageService.removeItem('timerEndTime');

  }

  /**
   * Executes after the view has been initialized.
   */
  ngAfterViewInit(): void {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    this.minDate = this.formatDate(tomorrow);
    this.maxDate = this.formatDate(nextYear);
  }

  /**
   * Formats the given date to a string in the format 'YYYY-MM-DD'.
   * @param date - The date to format.
   * @returns The formatted date string.
   */
  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  /**
   * Determines whether to show validation for the given form control.
   * @param formControl - The form control to check.
   * @returns True if validation should be shown, false otherwise.
   */
  shouldShowValidation(formControl: NgModel) {
    return (
      formControl &&
      formControl.invalid &&
      formControl.touched &&
      (formControl.dirty ||
        this.form?.submitted ||
        formControl.errors.required ||
        formControl.errors.pattern ||
        formControl.errors.min ||
        formControl.errors.max)
    );
  }
//call partnerlogohere

validateTravelDate(){
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (this.signUp.travelDate == '' || (new Date(this.signUp.travelDate) < new Date(this.formatDate(tomorrow)))){
      this.travelDateValidate = true;
      return;
  }
  this.travelDateValidate = false;
}

 partnerLogo()
 {
  const body = { RegID: this.travelAgentId };
  this.accountService.getLogoUrl(body).subscribe(
    (res: any) => {
      if (res.status === 'success' && res.response.Error !='true') {
         this.accountService.setPartnerLogo(res.response.Logo)
      }});
 }
  /**
   * Submits the sign up form.
   */
 async submit() {
    this.spinner.show();
    let partnerAuthTokenRequest: any = {
      partnerId: this.travelAgentId,
      apiKey: 'c3a3e4b9-4564-45c0-9698-d644a19cb9bA',
    };
 await this.getAgentDetails();
    // this.signUp.travelAgentName = 'sejuti2010 Alyf agent';
    // this.signUp.travelBrandName = 'sejuti2010 Alyf agent';
    // this.signUp.travelAgentEmailId = 'sejuti2010+alyfagent@gmail.com';
    // this.signUp.travelAgentMobile = '9877656544';

    this.accountService.generatePartnerToken(partnerAuthTokenRequest).subscribe(
      (token) => {
        this.accountService.signup(this.signUp, token['data']).subscribe(
          (res) => {
            if (res['data'] != null) {
              console.log(token['data']);
              MemoryStorageService.setItem('partnerToken', token['data']);
              MemoryStorageService.setItem('mobile', this.signUp.mobileNo);
              MemoryStorageService.setItem('email', this.signUp.email);
              MemoryStorageService.setItem('TravelCost', ""+this.signUp.travelCost);
              //redirect to the submit otp page
              this.spinner.hide();

              //Reset OTP Timer 
              MemoryStorageService.setItem('otpTimer', (new Date().getTime() + (60 * 1000)).toString());

              this.router.navigateByUrl('/assist-journey/submit-otp');
            } else {
              this.spinner.hide();
              swal.fire('There was an error! Please try again!');
            }
          },
          (error) => {
            this.spinner.hide();
            swal.fire('There was an error! Please try again!');
            console.log(error);
          }
        );
      },
      (error) => {
        this.spinner.hide();
        swal.fire('Please check URL and try again');
      }
    );
  }
  //clear monthlyincome
  clearInitialValue(event: any): void {
    const fieldName = event.target.name;
    if (fieldName === 'monthlyIncome' && this.signUp.monthlyIncome === 0) {
      this.signUp.monthlyIncome = null;
    } else if (fieldName === 'travelCost' && this.signUp.travelCost === 0) {
      this.signUp.travelCost = null;
    }
  }
  //GetAgentDetails
  async getAgentDetails(): Promise<void> {
    if (this.travelAgentId != '') {
      try {
        const agentDetails: any = await this.accountService.getAgentDetails(this.travelAgentId.toUpperCase()).toPromise();
        console.log(agentDetails);
        if (agentDetails != null) {
          this.signUp.travelAgentName = agentDetails.ownerDirectorName;
          this.signUp.travelBrandName = agentDetails.brandName;
          this.signUp.travelAgentEmailId = agentDetails.email;
          this.signUp.travelAgentMobile = agentDetails.mobile.toString();
          MemoryStorageService.setItem("consolidatorId", agentDetails.consolidator[0] || null);
        } else {
          swal.fire('Please check Agent ID and try again!');
        }
      } catch (error) {
        console.error('Error fetching agent details:', error);
      }
    }
  }
  allowOnly(event: KeyboardEvent, type: string): void {
    const input = event.target as HTMLInputElement;
    let allowedPattern;
  
    if (type === 'numbers') {
      allowedPattern = /[^0-9]/g; // Match anything that's not a number
    } else if (type === 'alphabets') {
      allowedPattern = /[^a-zA-Z\s']/g; // Match anything that's not a letter
    }
  
    // Use input event to handle changes
    input.addEventListener('input', (e: Event) => {
      const currentValue = input.value;
      input.value = currentValue.replace(allowedPattern, '');
    });
  }
  toUpperCase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }
  //validation travel cost here
  errorMsg: string;
  isError: boolean;
  validateTravelCost() {
    const minCost = 3000;
    const maxCost = 1000000;
    const currentCost = this.signUp.travelCost;
    if(this.signUp.travelCost==null){
      this.isError = true;
      this.errorMsg ='Trip cost is required.';
      return;
    }
    if (currentCost < minCost) {
      this.errorMsg = `The minimum trip cost is ₹${minCost}.`;
      this.isError = true;
    } else if (currentCost > maxCost) {
      this.errorMsg = `The maximum trip cost is ₹${maxCost}.`;
      this.isError = true;
    }
    else {
      this.isError = false;
    }
  }
  //validate MonthlyIncome
  monthlyErroMsg :string;
  isMonthlyError :boolean;
  validateMonthlyincome()
  {
    const minIncome = 15000;
    const maxIncome = 10000000;
    const currentIncome = this.signUp.monthlyIncome;
    if(this.signUp.monthlyIncome==null){
      this.isMonthlyError = true;
      this.monthlyErroMsg ='Monthly Income is required.';
      return;
    }
    if (currentIncome < minIncome) {
      this.monthlyErroMsg = `The minimum Income is ₹${minIncome}.`;
      this.isMonthlyError = true;
    } else if (currentIncome > maxIncome) {
      this.monthlyErroMsg = `The maximum Income is ₹${maxIncome}.`;
      this.isMonthlyError = true;
    }
    else {
      this.isMonthlyError = false;
    }
  }

  onInputChange(value: string) {
    this.signUp.mobileNo = value.replace(/\D/g, '');
  }

  showTravelDetailsFun(val){
    this.showTravelDetails = val;
    setTimeout(() => {
      if (this.collapseThree) {
        const collapse = new Collapse(this.collapseThree.nativeElement, { toggle: false });
        collapse.show();
      }
    });
  }

  public async GetSignInData(val){
    try{
      this.showPersonalDetails = val;
      setTimeout(() => {
        if (this.collapseTwo) {
          const collapse = new Collapse(this.collapseTwo.nativeElement, { toggle: false });
          collapse.show();
        }
      });
      // this.spinner.show();
      // const response = await this.accountService.GetSignInDataIfExist(this.signUp.mobileNo).toPromise(); 
      // if(response['data'] != null)
      // {
      //   if(typeof response['data'] === 'string' && response['data'].toLowerCase().includes('denied, try after')){
      //     return this.router.navigateByUrl('/assist-journey/sorry');
      //   }

      //   this.signUp.mobileNo = response['data'].mobileNo;
      //   this.signUp.email = response['data'].email;
      //   this.signUp.firstName = response['data'].firstName.toUpperCase();
      //   this.signUp.lastName = response['data'].lastName.toUpperCase();
      //   this.signUp.monthlyIncome = response['data'].monthlyIncome;
      //   //const traveldate = new Date(response['data'].travelDate)
      //   //this.signUp.travelDate = this.datePipe.transform(traveldate, 'yyyy-MM-dd');
      //   this.signUp.origin = response['data'].orgin;
      //   this.signUp.destination = response['data'].destination;
      //   this.signUp.travelCost = response['data'].travelCost;
      //   this.signUp.travelType = response['data'].travelType;

      //   this.disableAll =  true;
      //   response['error'].forEach(element => {
      //     if(element == 'TRAVEL'){
      //       this.disableTravelDetail = false;
      //     }
      //     else if(element == 'INCOME'){
      //       this.disableIncomeDetail = false;
      //     }
      //   });

      // }
    }
    catch(err){
      //this.spinner.hide();
      console.log(err);
    }    
    //this.spinner.hide();
  }

}
