import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'decimalToHHMM'
})
export class decimalToHHMM implements PipeTransform {
  transform(decimalTimeString: string): string {
    
    if(decimalTimeString=="0")
    {
        return "00:00";
    }
    var decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor((decimalTime / (60 * 60))).toString();
    decimalTime = decimalTime - (parseFloat(hours) * 60 * 60);
    var minutes = Math.floor((decimalTime / 60)).toString();

    if (parseFloat(hours) < 10) {
        hours = "0" + hours;
    }
    if (parseFloat(minutes) < 10) {
        minutes = "0" + minutes;
    }
    return hours + ":" + minutes;


  }
}