import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var Moengage; 

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  moEngageKnowMore(){
    
    //Moengage.track_event("Education Know More");
    //Moengage.track_event("App Content Refreshed", {"HomePage": "Know More", "moe_non_interactive": 1}) 
    Moengage.track_event("HomePage - Know More", {"HomePage": "Know More", "moe_non_interactive": 1}) 
    //EventName : Homepage Know More
    
    this.router.navigateByUrl('/about-us');
  }
  

  moEngageSignUpInMinutes(){
    
    //Moengage.track_event("Education Know More");
    //Moengage.track_event("App Content Refreshed", {"HomePage": "Sign up in minutes", "moe_non_interactive": 1}) 
    Moengage.track_event("HomePage - Sign up in minutes", {"HomePage": "Sign up in minutes", "moe_non_interactive": 1}) 
    //EventName : Sign Up Initiated
    
    this.router.navigateByUrl('/signup');
  }
}
