import { Component, ElementRef, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { UserService } from 'src/app/core/services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-sorry',
  templateUrl: './sorry.component.html',
  styleUrls: ['./sorry.component.scss']
})
export class SorryComponent implements OnInit {
  applicationId: any;
  closeResult: string;
  showpopUp:boolean = false;

  constructor(
    private accountService: AccountService,private elRef: ElementRef, private renderer: Renderer2 //, private UserService: UserService
  ) { }

  async ngOnInit() {
    // const enkey = await this.UserService.encryptData('PaymentCompleted', '4a2c3e1f6d9b2c5d1f3e7a8b4c6d9a1b');
    // console.log(enkey);
    // console.log(await this.UserService.decryptData(enkey, '4a2c3e1f6d9b2c5d1f3e7a8b4c6d9a1b'));
    this.getCurrentApplication();
  }

  getCurrentApplication() {
    this.accountService.getCurrentApplicationStatus().subscribe(response => {

      this.applicationId = response.applicationCode;
      console.log(response , "app====");
    });
  }

  notifyMe(){
    var aplicationID = {
      ApplicationId: this.applicationId
    };
    this.accountService.notifyMe(aplicationID)
    .subscribe(res => {
      console.log(res, "respone---");
      if(res["data"].message == "Success" ){
        swal.fire("Your request submitted successfully. \n Thank you!!! ");
      }
    }, error => {
      console.log(error.error, "errrors-----");
      this.showpopUp = true;
      swal.fire(error.error.errors);
    });

  }
}
