import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easy-instalment',
  templateUrl: './easy-instalment.component.html',
  styleUrls: ['./easy-instalment.component.scss']
})
export class EasyInstalmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
