import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/core/services/shared-data.service';

declare var Moengage;

@Component({
  selector: 'app-steps-freedom',
  templateUrl: './steps-freedom.component.html',
  styleUrls: ['./steps-freedom.component.scss']
})
export class StepsFreedomComponent implements OnInit {

  previousUrl:string = "";
  active = "tab-pane fade show active"
  nonActive = "tab-pane fade"
  navActive = "nav-link active"
  navNonActive = "nav-link"
  educationClass = this.nonActive;
  travelClass = this.active;
  navEducation = this.navNonActive;
  navTravel = this.navActive; 
   
  
  constructor(private sharedDataService:SharedDataService, private router: Router) { }

  ngOnInit(): void {
    this.sharedDataService.educationTabDisplay.subscribe(value => {
      this.previousUrl = value;
      this.getPreviousUrl(this.previousUrl);
     });
  }

  moEngageEvent(){
    
    //Moengage.track_event("Education Know More");
    //Moengage.track_event("App Content Refreshed", {"HomePage": "Education Know More", "moe_non_interactive": 1})
    Moengage.track_event("HomePage - Education Know More", {"HomePage": "Education Know More", "moe_non_interactive": 1})
    
    this.router.navigateByUrl('/rules-for-traveling-the-world');
    // routerLink="/rules-for-traveling-the-world"
  }


  tabChanged(category) {
    this.getPreviousUrl(category);
  }


  getPreviousUrl(previousUrl){
    if (previousUrl == "edTech") {
        this.educationClass = this.active;
        this.travelClass = this.nonActive;
        this.navEducation = this.navActive;
        this.navTravel = this.navNonActive;
    }
    else {
      this.educationClass = this.nonActive;
      this.travelClass = this.active;
      this.navEducation = this.navNonActive;
      this.navTravel = this.navActive;
    }
  }

  ngOnDestroy() {
    this.sharedDataService.setEducationDisplay("travel");
  }
}
