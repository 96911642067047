import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';

@Component({
  selector: 'app-floating-bar',
  templateUrl: './floating-bar.component.html',
  styleUrls: ['./floating-bar.component.scss']
})
export class FloatingBarComponent implements OnInit {

  public rates: any;
  public ratesDestination: any;
  public temp: any;
  public time: any;
  public tempMin: any;
  public tempMax: any;
  public response: any;
  public now: string;

  constructor(private accountService: AccountService, private sharedDataService: SharedDataService) { }

  ngOnInit(): void {
    this.sharedDataService.locationList.subscribe(value => {
      this.getTempAndWeather(value);
    })
  }


  getTempAndWeather(location) {
    this.accountService.getDestinationFacts({
      CityName: location.city,
      CurrencyCode: location.currencyCode,
      CountryCode: location.countryCode,
      CurrencyCodeDestination: location.currencyCodeDestination,
      Latitude: location.latitude,
      Longitude: location.longitude

    }).subscribe(response => {
      this.response = response;
      var rate = this.response.data.data.rates;
      let symbol, symbolDestination;
      var rateDestination = this.response.data.data.ratesDestination;
      for (var key in rate) {
        this.rates = rate[key].toFixed(2);
        symbol = key
      }
      for (var key in rateDestination) {
        this.ratesDestination = rateDestination[key].toFixed(2);
        symbolDestination = key
      }
      if (symbol == symbolDestination) {
        this.rates = "";
        this.now = "";
      }
      else {
        if (this.rates > this.ratesDestination) {
          this.rates = (this.rates / this.ratesDestination).toFixed(2);
          this.rates = "1 " + symbol + " = " + this.rates + " " + symbolDestination;
        }
        else {
          this.rates = (this.rates / this.ratesDestination).toFixed(2);
          this.rates = "1 " + symbolDestination + " = " + this.rates + " " + symbol;
        }

        this.now = this.response.data.data.formatted.substring(10) + " GMT " + this.response.data.data.gmt;

      }

      this.temp = this.response.data.data.temp;
      this.convert(this.temp);

    });

  }

  convert(temp) {
    this.tempMin = Math.round((temp.temp_min));
    this.tempMax = Math.round((temp.temp_max));
  }


}
