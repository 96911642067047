import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private sharedDataService: SharedDataService) { }

  ngOnInit(): void {
    this.setDefaultHeaderVisibility();
  }

  ModalNewOpen() {
    
  }

  setDefaultHeaderVisibility() {
    this.sharedDataService.setDefaultMenuVisibility();
  }
}
