import { Component, OnInit } from '@angular/core';
import { TermsPrivacyService } from 'src/app/core/services/terms-privacy.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  privacyText:string = "";
  constructor(private termsPrivacyService:TermsPrivacyService) { }

  ngOnInit(): void {
    let obj = {
      type:"privacy"
    }
    this.termsPrivacyService.getTermsPrivacy(obj).subscribe(response=>{
      if (response["data"] != null) {
        this.privacyText = response["data"].data
      }
    })
  }

}
