<div class="section4 coloredBg">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12">
                <h2 class="text-center">How does it work</h2>
                <div class="row leftcontent">
                    <div class="col-sm-12 timeline-work">
                        <section class="ps-timeline-sec">
                            <div class="container-fluid">
                                <ol class="ps-timeline">
                                    <li>
                                        <div class="img-handler-top">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-01.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-bot">
                                            <p>Customer chooses his preferred flight, holiday package or hotel </p>
                                        </div>
                                        <span class="ps-sp-top">01</span>
                                    </li>
                                    <li>
                                        <div class="img-handler-bot">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-02.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-top">
                                            <p>You send the customer a Mihuru link using your unique login </p>
                                        </div>
                                        <span class="ps-sp-bot">02</span>
                                    </li>
                                    <li>
                                        <div class="img-handler-top">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-03.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-bot">
                                            <p>Customer completes the application form and provides required
                                                documents </p>
                                        </div>
                                        <span class="ps-sp-top">03</span>
                                    </li>
                                    <li>
                                        <div class="img-handler-bot">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-04.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-top">
                                            <p>We review this information and provide an instant decision </p>
                                        </div>
                                        <span class="ps-sp-bot">04</span>
                                    </li>
                                    <li>
                                        <div class="img-handler-top">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-05.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-bot">
                                            <p>Customer books his trip with you and you provide us booking
                                                confirmation </p>
                                        </div>
                                        <span class="ps-sp-top">05</span>
                                    </li>
                                    <li>
                                        <div class="img-handler-bot">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/travelAgent-06.png" alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" />
                                        </div>
                                        <div class="ps-top">
                                            <p>We will pay on behalf of Customer and Customer pays us in easy EMI </p>
                                        </div>
                                        <span class="ps-sp-bot">06</span>
                                    </li>
                                </ol>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>