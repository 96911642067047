<app-floating-bar></app-floating-bar>
<div class="blueGg align-items-center justify-content-center">
   <div class="page-start">
      <section class="page-wrap">
         <div class="container-fluid p-0">
            <div class="p-0 m-0 row signInBox justify-content-center"
               style=" background: url(https://mihuruendpoint.azureedge.net/websiteimages/assets/images/dashboardBg01.jpg)  center center no-repeat; background-size: cover;">
               <div class="col-12  justify-content-center p-0">
                  <div class="bsc-wrap">
                     <div class="row  justify-content-center">
                        <div class="col-12 mt-sm-4 mb-4">
                           <div class="row">
                              <div class="col-12 leftSection d-block d-sm-none">
                                 <div class="leftmobile">
                                    <div class="text-left">
                                       <button class="btn btn-warning" type="button" data-toggle="collapse"
                                          data-target="#collapseExample" aria-expanded="false"
                                          aria-controls="collapseExample"><i class="fas fa-bars"></i> My Profile
                                       </button>
                                    </div>
                                    <div class="collapse" id="collapseExample">
                                       <div class="contactDetails">
                                          <div class="row">
                                             <div class="col-4">
                                                <div class="img-circle">
                                                   <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" [src]="userPersonalDetail?.profilePic" />
                                                </div>
                                                
                                                <div>
                                                   <input type="file" id="upload" (change)="onFileChanged($event)" style="width: 0px" />
                                                   <label for="upload" style="cursor:pointer">Click me to upload profile</label>
                                                </div>
                                             </div>
                                             <div class="col-8 pl-0">
                                                <h2>{{userPersonalDetail?.userName}}</h2>
                                                <p> <span>Email ID:</span> {{userPersonalDetail?.email}}</p>
                                                <p *ngIf="verified">
                                                   <a (click)="Popup()" class="interLink">Click here</a> to verify mail ID
                                                </p>
                                                <p> <span>Contact No:</span> {{userPersonalDetail?.phoneNo}} </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwuploadDocs" class="uploadDoc">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <p class="note font-weight-bold mb-1">
                                                   In-principle credit line <br>
                                                   <span> {{location?.currency}} {{creditLimit}}</span>
                                                </p>
                                                <small class="text-white">(Upload & Verify documents
                                                   to avail credit)</small>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwwaitingForApproval" class="waitingForApproval">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <!-- <p class="note font-weight-bold mb-1">
                                                   In-principle credit line &#8377; 1,00,000
                                                </p> -->
                                                <p class="note font-weight-bold mb-1">
                                                   In-principle credit line <br>
                                                   <span> {{location?.currency}} {{creditLimit}}</span>
                                                </p>
                                                <small class="text-white">
                                                   (You can use this once we have verified your documents and
                                                   application)</small>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwapprovedNotBooked" class="approvedNotBooked">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                   [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                   [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                                <p class="note font-weight-bold mb-1">
                                                   Available Credit Line <br>
                                                   <span> {{location?.currency}} {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p> Expires on � {{validTill}} </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcompletedOneBooking && shwcreatedLineExpire == false" class="completedOneBooking">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                          
                                                <p class="note font-weight-bold mb-1">
                                                   Available Credit Line <br>
                                                   <span> {{location?.currency}} {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p class="text-center"> Payment Due On - {{emiDueDate}} </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcreatedLineExpire" class="createdLineExpire">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <p class="note text-center">
                                                   Credit line Status <br />
                                                   Expired on {{expiryDate}}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcreatedLineExhausted" class="createdLineExhausted">
                                          <div class="row justify-content-center">
                                             <div class="col-11">
                                                <p class="note font-weight-bold mb-1">
                                                   Credit line used <br>
                                                   <span> &#8377; {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p class="text-center"> Credit Exhaused - {{modifiedOn}}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-4 col-lg-3 leftSection  d-sm-block">
                                 <div class="d-flex align-items-end flex-column h-100">
                                    <div class="p-2 w-100">
                                       <div class="contactDetails">
                                          <h2>{{userPersonalDetail?.userName}}</h2>
                                          <div class="img-circle">
                                             <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" 
                                             [src]="userPersonalDetail?.profilePic" />
                                          </div>
                                          <div>
                                             <input type="file" id="upload" (change)="onFileChanged($event)" style="width: 0px" />
                                             <label for="upload" style="cursor:pointer">Click me to upload profile</label>
                                          </div>
                                          <p> <span>Email ID:</span> {{userPersonalDetail?.email}} </p>
                                          <p *ngIf="verified">
                                             <a (click)="Popup()" class="interLink">Click here</a> to verify mail ID
                                          </p>
                                          <p> <span>Contact No:</span> {{userPersonalDetail?.phoneNo}} </p>
                                       </div>
                                    </div>
                                    <div class="mt-auto w-100 p-2 pb-0">
                                       <div *ngIf = "shwuploadDocs" class="uploadDoc">
                                          <div class="row">
                                             <div class="col-12">
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                   [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                   [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                                <p class="note font-weight-bold mb-1">
                                                   In-principle credit line <br>
                                                   <span> {{location?.currency}} {{creditLimit}}</span>
                                                </p>
                                                <small class="text-white">(Upload & Verify documents
                                                   to avail credit)</small>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwwaitingForApproval" class="waitingForApproval">
                                          <div class="row">
                                             <div class="col-12">
                                                <!-- <p class="note font-weight-bold mb-1">
                                                   In-principle credit line &#8377; 1,00,000
                                                </p> -->
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                            
                                                <p class="note font-weight-bold mb-1">
                                                   In-principle credit line <br>
                                                   <span> {{location?.currency}} {{creditLimit}}</span>
                                                </p>
                                                <small class="text-white">
                                                   (You can use this once we have verified your documents and
                                                   application)</small>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwapprovedNotBooked" class="approvedNotBooked">
                                          <div class="row">
                                             <div class="col-12">
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                   [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                   [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                                <p class="note font-weight-bold mb-1">
                                                   Available Credit Line <br>
                                                   <span> {{location?.currency}} {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p> Expires on � {{validTill}} </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcompletedOneBooking && shwcreatedLineExpire == false" class="completedOneBooking">
                                          <div class="row">
                                             <div class="col-12">
                                                <round-progress [current]="current" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="125" [stroke]="15"
                                                [semicircle]="true" [rounded]="false" [clockwise]="true" [responsive]="true" [duration]="800"
                                                [animation]="'easeOutCubic'" [animationDelay]="0"></round-progress>
                                            
                                                <p class="note font-weight-bold mb-1">
                                                   Available Credit Line <br>
                                                   <span> {{location?.currency}} {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p class="text-center"> Payment Due On - {{emiDueDate}} </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcreatedLineExpire" class="createdLineExpire">
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p class="note text-center">
                                                   Credit line Status <br />
                                                   Expired on {{expiryDate}}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcreatedLineExhausted" class="createdLineExhausted">
                                          <div class="row">
                                             <div class="col-12">
                                                <p class="note font-weight-bold mb-1">
                                                   Credit line used <br>
                                                   <span> &#8377; {{availableCreditLimitAmount}}</span>
                                                </p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center mt-1">
                                             <div class="col-12">
                                                <p class="text-center"> Credit Exhaused - {{modifiedOn}}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-8 col-lg-9 border-left rightSection">
                                 <div class="d-flex align-items-end flex-column h-100">
                                    <div class="p-2 w-100">
                                       <div class="dashboardhome">
                                          <h5 *ngIf = "(shwcreatedLineExpire || shwcreatedLineExhausted) && shwcompletedOneBooking == false" class="createdLineExpire text-white text-center">Welcome back {{userPersonalDetail?.firstName}}!
                                             <br>
                                             Where are you
                                             going next?
                                          </h5>
                                          <h1 *ngIf = "!shwwaitingForApproval && !shwcompletedOneBooking" class="">Discover</h1>
                                          <h1 *ngIf = "shwwaitingForApproval" class="waitingForApproval">Soar the skies to</h1>
                                          <h1 *ngIf = "shwcompletedOneBooking" class="completedOneBooking">See you in </h1>
                                          <h2>{{cityName}}</h2>
                                          <p *ngIf = "!shwwaitingForApproval && !shwcompletedOneBooking" class="emiPrice">EMIs from <br /><span> &#8377; {{emiValue}}</span>
                                          </p>
                                          <p *ngIf = "shwwaitingForApproval" class="emiPrice waitingForApproval">
                                             EMIs on Flights from <span> <br>
                                                &#8377; {{emiValue}}</span>
                                          <p *ngIf = "shwcompletedOneBooking" class="emiPrice completedOneBooking">Next EMI <br>
                                             <span> &#8377; {{emiValue}}</span> on {{emiDueDate}}
                                          </p>
                                       </div>
                                    </div>
                                    <div class="mt-auto w-100 p-2 pb-0">
                                       <div *ngIf = "shwaccountCreated" class="accountCreated">
                                          <div class="row justify-content-center">
                                             <div class="col-sm-6 text-center">
                                                <button type="submit" (click)="getCurrentApplication()"  class="btn btn-warning">Complete
                                                   Application</button>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-sm-12">
                                                <p class="note">Complete your application form to book flights, hotels &
                                                   holidays on loan. <br> Save upto 40% on your total booking value </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwuploadDocs" class="uploadDocs">
                                          <div class="row justify-content-center">
                                             <div class="col-sm-5 text-center">
                                                <button type="submit" (click)="getCurrentApplication()"  class="btn btn-warning">Upload documents</button>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-sm-12">
                                                <p class="note">Upload your documents to use your credit line & book
                                                   your flight, hotel or holiday packages.</p>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center">
                                             <div class="col-sm-6">
                                                <div class="alert alert-danger text-center font-weight-bold">
                                                   To view your application <a href="#" class="interLink">Click Here</a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwwaitingForApproval" class="waitingForApproval">
                                          <div class="row">
                                             <div class="col-sm-12">
                                                <h3 class="text-center blinking">
                                                   Just a short wait! We are evaluating your documents & loan
                                                   eligibility
                                                </h3>
                                                <p class="note text-center mt-3">
                                                   In the mean time, why don�t you plan your trip?
                                                   <br />
                                                   Having this information ready will help us make the perfect booking
                                                   for you.
                                                </p>
                                                
                                             </div>
                                            
                                          </div>
                                          <div class="row justify-content-center buttonDiv">
                                             <div class="col-sm-3 text-center">
                                                <button type="submit" routerLink  = "/flights-search" class="btn btn-block btn-warning">Plan your trip</button> 
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwapprovedNotBooked" class="approvedNotBooked">
                                          <div class="row mt-5 justify-content-center">
                                             <div class="col-sm-10 mt-5">
                                                <h5 class="text-center text-white">Book your travel with Credit line
                                                </h5>
                                                <div class="row justify-content-center subbuttonDiv">
                                                   <div class="col-2">
                                                      <button type="submit" routerLink = "/flights-search" class="btn btn-default btn-block"><i
                                                            class="fas fa-plane"></i> Flights</button>
                                                   </div>
                                                   <div class="col-2">
                                                      <button type="submit" routerLink = "/hotels" class="btn btn-default btn-block"><i
                                                            class="fas fa-hotel"></i> Hotels</button>
                                                   </div>
                                                   <div class="col-2">
                                                      <button type="submit" routerLink = "https://holidays.mihuru.com/" class="btn btn-default btn-block"><i
                                                            class="fas fa-suitcase-rolling"></i>
                                                         Holidays</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center">
                                             <div class="col-sm-8">
                                                <p class="text-center blinking">
                                                   Your approved Credit line is expiring on <strong>{{validTill}}</strong>
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcompletedOneBooking" class="completedOneBooking">
                                          <!-- <div class="row justify-content-center buttonDiv">
                                             <div class="col-sm-3 text-center">
                                                <button type="submit" class="btn btn-block btn-warning">Make Payment
                                                </button>
                                             </div>
                                             <div class="col-sm-3">
                                                <button type="submit" class="btn btn-block btn-warning">Overdue! Pay
                                                   Now</button>
                                             </div>
                                          </div>
                                          <div class="row mt-5">
                                             <div class="col-sm-12">
                                                <h5 class="text-center text-white">Everything you need to know </h5>
                                                <div class="row justify-content-center subbuttonDiv mt-3">
                                                   <div class="col-sm-2">
                                                      <button type="submit" class="btn btn-default btn-block"><i
                                                            class="fas fa-route"></i> Sightseeing </button>
                                                   </div>
                                                   <div class="col-sm-2">
                                                      <button type="submit" class="btn btn-default btn-block"><i
                                                            class="fas fa-tasks"></i> Travel Checklist</button>
                                                   </div>
                                                   <div class="col-sm-2">
                                                      <button type="submit" class="btn btn-default btn-block"><i
                                                            class="fas fa-rupee-sign"></i> Payments </button>
                                                   </div>
                                                   <div class="col-sm-2">
                                                      <button type="submit" class="btn btn-default btn-block"><i
                                                            class="fas fa-suitcase-rolling"></i> New Booking
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div> -->
                                       </div>
                                       <div *ngIf = "shwcreatedLineExpire && shwcompletedOneBooking ==false" class="createdLineExpire">
                                          <div class="row justify-content-center">
                                             <div class="col-sm-6 text-center">
                                                <button type="submit" (click)="repeatUser()" class="btn btn-warning">Verify Details</button>
                                             </div>
                                          </div>
                                          <div class="row mt-3">
                                             <div class="col-sm-12">
                                                <p class="note">Your credit line seems to have expired!
                                                   <br>Kindly verify your details to reactivate your credit line or make
                                                   another booking
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div *ngIf = "shwcreatedLineExhausted" class="createdLineExhausted">
                                          <div class="row justify-content-center">
                                             <div class="col-sm-6 text-center">
                                                <!-- <button type="submit" (click)="creditLineExhaust()" class="btn btn-warning">Verify
                                                   Details</button> -->
                                             </div>
                                          </div>
                                          <div class="row mt-3">
                                             <div class="col-sm-12">
                                                <p class="note">Your credit line seems to have been used!<br>
                                                   <!-- Kindly verify your details to reactivate your creditline & make
                                                   another booking -->
                                                   You current credit line is fully utilized, please reach out to us for top-up at +91-7977191328
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</div>

<ng-template #Modal let-modal>
   <div class="row justify-content-center pt-3">
     <div class="card col-11">
       <div class="headline">
         <h3 class="title-border">Verify Email</h3>
       </div>
       <div class="card-body">
         <div class="row justify-content-center">
           <div class="col-12">
             <form name="form" #vf="ngForm" autocomplete="off" class="EmailVerifyForm">
               <div class="row">
                 <div class="col-12">
                   <div class="form-group">
                     <span>
                       <input type="text" class="form-control" name="otp" maxlength="4"
                            [(ngModel)]="model.otp" (keydown)="onKeydown($event)" placeholder="OTP">
                       <label for="class">OTP</label>
                     </span>
                     <div *ngIf="invalid" class="text-danger">
                       Please enter correct otp
                     </div>
                   </div>
                 </div>
               </div>
             </form>
             <div class="row justify-content-center buttonDiv">
               <div class="col-6 col-sm-5">
                 <button class="btn btn-default btn-block" [disabled]="!resetButtonEnable"
                         (click)="onResentEmailOTP()" type="button">
                   Resend
                 </button>
               </div>
               <div class="col-6 col-sm-5">
                 <button class="btn btn-warning btn-block" [disabled]="(model.otp?.length < 4) ? true: false "
                         (click)="verifyEmailOTP()"
                         type="button">
                   Verify
                 </button>
               </div>
               
             </div>
             <div class="row mb-3 ">
               <div class="col-12 text-center">
                 <h3 class="viaWhat p-1 w-50 m-auto">
                   <countdown #Cdown2 [config]="{leftTime: 20}" (event)="onLeftTimeEvent($event)"></countdown>
 
                 </h3>
               </div>
 
             </div>
 
           </div>
         </div>
 
       </div>
     </div>
   </div>

 </ng-template>