import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  mediaArr = []
  constructor(private user: UserService) { }

  
  ngOnInit(): void {
    this.getMedia();
  }

  getMedia() {
    let obj = {
      Type: "Media"
    }
    this.user.getTestimonialMedia(obj).subscribe(
      response => {
        if (response["data"] != null) {
          for (let i = 0; i < response["data"].length; i++) {
            let obj = {
              image: response["data"][i].logo,
              text:response["data"][i].text,
              url:response["data"][i].url
            }
            this.mediaArr.push(obj)
          }
        }
      })
  }

}
