import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountdownComponent } from 'ngx-countdown';
import { AccountService } from '../../core/services/account.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { ApplicationStatus } from 'src/app/core/Enums/ApplicationStatus';
import Swal from 'sweetalert2';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
declare function userlocation():any;
declare function geoFindMe():any;

@Component({
  selector: 'app-personalized-dashboard',
  templateUrl: './personalized-dashboard.component.html',
  styleUrls: ['./personalized-dashboard.component.scss']
})
export class PersonalizedDashboardComponent implements OnInit {

 /** Image Upload code */
 selectedFile: File
 tempflag: boolean;
 imgUrl = `assets/images/user.png`;

 /** End */
  public userPersonalDetail:any;
  public staticContent: any
  public location: any;
  public response: any;
  public loc = [];
  public rates: any;
  public ratesDestination: any;
  public temp: any;
  public time: any;
  public tempMin: any;
  public tempMax: any;
  public rat: any;
  public verified: boolean = false;
  public invalid: boolean = false;
  public knowMoreData:boolean = false;
  timerLeftValue: number = 0;
  public flightPrice: any;
  public emiValue:any = 0;
  public resetButtonEnable: boolean = false;
  public shwUploadedDocs:boolean = false;
  model: any = {}
  public now: string ;
  public destinationData:any = [];
  public creditLimit:any;
  public currentStatus: any;
  public principalLoanAmount: boolean = false;
  public availableCreditLimit:boolean = false;
  public availableCreditLimitAmount:number = 100000;
  cityName:string = "";
  public showMsg :boolean = false;
  public percentage : number = 0;
  @ViewChild('Modal') public Modal: ElementRef<any>;
  @ViewChild('Cdown2', { static: false }) public countdown: CountdownComponent;
  user: any = JSON.parse(MemoryStorageService.getItem("user"));
  http: any;
  /* for progress bar start */ 
  current: number = 10;
  max: number = 100;  
  radius: number = 125; 
  color: string = ''; 
  gradient:boolean= false;
  
  airportList = {
    "Continents": [
        {
            "Id": "A",
            "Name": "Asia",
            "Countries": [
                {
                    "Id": "MN",
                    "Name": "Mongolia",
                    "CurrencyId": "MNT",
                    "Cities": [
                        {
                            "Id": "HJTA",
                            "Name": "Khujirt",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "102.78259, 46.896566",
                            "IataCode": "HJT",
                            "Airports": [
                                {
                                    "Id": "HJT",
                                    "Name": "Khujirt",
                                    "CityId": "HJTA",
                                    "CountryId": "MN",
                                    "Location": "102.76667, 46.9"
                                }
                            ]
                        },
                        {
                            "Id": "TSZA",
                            "Name": "Tsetserleg",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "101.45417, 47.475",
                            "IataCode": "TSZ",
                            "Airports": [
                                {
                                    "Id": "TSZ",
                                    "Name": "Tsetserleg",
                                    "CityId": "TSZA",
                                    "CountryId": "MN",
                                    "Location": "101.76667, 47.733333"
                                }
                            ]
                        },
                        {
                            "Id": "COQA",
                            "Name": "Choibalsan",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "114.5, 48.066667",
                            "IataCode": "COQ",
                            "Airports": [
                                {
                                    "Id": "COQ",
                                    "Name": "Choibalsan",
                                    "CityId": "COQA",
                                    "CountryId": "MN",
                                    "Location": "114.9, 48.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DLZA",
                            "Name": "Dalanzadgad",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "104.425, 43.570833",
                            "IataCode": "DLZ",
                            "Airports": [
                                {
                                    "Id": "DLZ",
                                    "Name": "Dalanzadgad",
                                    "CityId": "DLZA",
                                    "CountryId": "MN",
                                    "Location": "104.43, 43.5917"
                                }
                            ]
                        },
                        {
                            "Id": "ERTA",
                            "Name": "Erdenet",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "104.08333, 49.033333",
                            "IataCode": "ERT",
                            "Airports": [
                                {
                                    "Id": "ERT",
                                    "Name": "Erdenet",
                                    "CityId": "ERTA",
                                    "CountryId": "MN",
                                    "Location": "104.15, 49.066667"
                                }
                            ]
                        },
                        {
                            "Id": "HVDA",
                            "Name": "Khovd",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "91.641944, 48.005556",
                            "IataCode": "HVD",
                            "Airports": [
                                {
                                    "Id": "HVD",
                                    "Name": "Khovd",
                                    "CityId": "HVDA",
                                    "CountryId": "MN",
                                    "Location": "91.624809, 47.962304"
                                }
                            ]
                        },
                        {
                            "Id": "KHRA",
                            "Name": "Kharkhorin",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "102.82379, 47.197528",
                            "IataCode": "KHR",
                            "Airports": [
                                {
                                    "Id": "KHR",
                                    "Name": "Kharkhorin",
                                    "CityId": "KHRA",
                                    "CountryId": "MN",
                                    "Location": "102.85, 46.783333"
                                }
                            ]
                        },
                        {
                            "Id": "LTIA",
                            "Name": "Altai",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "96.258333, 46.372222",
                            "IataCode": "LTI",
                            "Airports": [
                                {
                                    "Id": "LTI",
                                    "Name": "Altai",
                                    "CityId": "LTIA",
                                    "CountryId": "MN",
                                    "Location": "96.2211, 46.3764"
                                }
                            ]
                        },
                        {
                            "Id": "MXVA",
                            "Name": "Moron",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "100.1625, 49.634167",
                            "IataCode": "MXV",
                            "Airports": [
                                {
                                    "Id": "MXV",
                                    "Name": "Moron",
                                    "CityId": "MXVA",
                                    "CountryId": "MN",
                                    "Location": "100.16667, 49.633333"
                                }
                            ]
                        },
                        {
                            "Id": "UGAA",
                            "Name": "Bulgan",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "103.53472, 48.8125",
                            "IataCode": "UGA",
                            "Airports": [
                                {
                                    "Id": "UGA",
                                    "Name": "Bulgan",
                                    "CityId": "UGAA",
                                    "CountryId": "MN",
                                    "Location": "103.55, 48.8"
                                }
                            ]
                        },
                        {
                            "Id": "ULNA",
                            "Name": "Ulaanbaatar",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "106.91667, 47.916668",
                            "IataCode": "ULN",
                            "Airports": [
                                {
                                    "Id": "ULN",
                                    "Name": "Ulaanbaatar",
                                    "CityId": "ULNA",
                                    "CountryId": "MN",
                                    "Location": "106.76639, 47.843056"
                                }
                            ]
                        },
                        {
                            "Id": "ULOA",
                            "Name": "Ulaangom",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "92.066667, 49.981111",
                            "IataCode": "ULO",
                            "Airports": [
                                {
                                    "Id": "ULO",
                                    "Name": "Ulaangom",
                                    "CityId": "ULOA",
                                    "CountryId": "MN",
                                    "Location": "92.081215, 49.9711"
                                }
                            ]
                        },
                        {
                            "Id": "UNRA",
                            "Name": "Underkhaan",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "110.65556, 47.319444",
                            "IataCode": "UNR",
                            "Airports": [
                                {
                                    "Id": "UNR",
                                    "Name": "Underkhaan",
                                    "CityId": "UNRA",
                                    "CountryId": "MN",
                                    "Location": "110.66667, 47.316667"
                                }
                            ]
                        },
                        {
                            "Id": "UUNA",
                            "Name": "Baruun-Urt",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "113.27917, 46.680556",
                            "IataCode": "UUN",
                            "Airports": [
                                {
                                    "Id": "UUN",
                                    "Name": "Baruun-Urt",
                                    "CityId": "UUNA",
                                    "CountryId": "MN",
                                    "Location": "113.28333, 46.683333"
                                }
                            ]
                        },
                        {
                            "Id": "ULZA",
                            "Name": "Uliastai",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "96.844444, 47.741667",
                            "IataCode": "ULZ",
                            "Airports": [
                                {
                                    "Id": "ULZ",
                                    "Name": "Uliastai",
                                    "CityId": "ULZA",
                                    "CountryId": "MN",
                                    "Location": "96.85, 47.75"
                                }
                            ]
                        },
                        {
                            "Id": "TNZA",
                            "Name": "Tosontsengel",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "98.283889, 48.756667",
                            "IataCode": "TNZ",
                            "Airports": [
                                {
                                    "Id": "TNZ",
                                    "Name": "Tosontsengel",
                                    "CityId": "TNZA",
                                    "CountryId": "MN",
                                    "Location": "98.275278, 48.739722"
                                }
                            ]
                        },
                        {
                            "Id": "ULGA",
                            "Name": "Ulgit",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "89.9625, 48.968333",
                            "IataCode": "ULG",
                            "Airports": [
                                {
                                    "Id": "ULG",
                                    "Name": "Ulgit",
                                    "CityId": "ULGA",
                                    "CountryId": "MN",
                                    "Location": "89.920415, 48.992686"
                                }
                            ]
                        },
                        {
                            "Id": "MXWA",
                            "Name": "Mandalgobi",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "106.27083, 45.7625",
                            "IataCode": "MXW",
                            "Airports": [
                                {
                                    "Id": "MXW",
                                    "Name": "Mandalgobi",
                                    "CityId": "MXWA",
                                    "CountryId": "MN",
                                    "Location": "106.28333, 45.766667"
                                }
                            ]
                        },
                        {
                            "Id": "HTMA",
                            "Name": "Khatgal",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "100.16694, 50.451944",
                            "IataCode": "HTM",
                            "Airports": [
                                {
                                    "Id": "HTM",
                                    "Name": "Khatgal",
                                    "CityId": "HTMA",
                                    "CountryId": "MN",
                                    "Location": "100.16667, 50.45"
                                }
                            ]
                        },
                        {
                            "Id": "BYNA",
                            "Name": "Bayankhongor",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "100.71806, 46.194444",
                            "IataCode": "BYN",
                            "Airports": [
                                {
                                    "Id": "BYN",
                                    "Name": "Bayankhongor",
                                    "CityId": "BYNA",
                                    "CountryId": "MN",
                                    "Location": "100.68333, 46.1"
                                }
                            ]
                        },
                        {
                            "Id": "AVKA",
                            "Name": "Arvaikheer",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "102.775, 46.263889",
                            "IataCode": "AVK",
                            "Airports": [
                                {
                                    "Id": "AVK",
                                    "Name": "Arvaikheer",
                                    "CityId": "AVKA",
                                    "CountryId": "MN",
                                    "Location": "102.78333, 46.266667"
                                }
                            ]
                        },
                        {
                            "Id": "UGTA",
                            "Name": "Mani Hiid",
                            "SingleAirportCity": true,
                            "CountryId": "MN",
                            "Location": "104.2, 43.8",
                            "IataCode": "UGT",
                            "Airports": [
                                {
                                    "Id": "UGT",
                                    "Name": "Umnugobitour",
                                    "CityId": "UGTA",
                                    "CountryId": "MN",
                                    "Location": "104.12778, 43.752778"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MO",
                    "Name": "Macau",
                    "CurrencyId": "MOP",
                    "Cities": [
                        {
                            "Id": "MFMA",
                            "Name": "Macau",
                            "SingleAirportCity": true,
                            "CountryId": "MO",
                            "Location": "113.5588, 22.169979",
                            "IataCode": "MFM",
                            "Airports": [
                                {
                                    "Id": "MFM",
                                    "Name": "Macau",
                                    "CityId": "MFMA",
                                    "CountryId": "MO",
                                    "Location": "113.59167, 22.149444"
                                }
                            ]
                        },
                        {
                            "Id": "XZMA",
                            "Name": "Se",
                            "SingleAirportCity": true,
                            "CountryId": "MO",
                            "Location": "113.54824, 22.189942",
                            "IataCode": "XZM",
                            "Airports": [
                                {
                                    "Id": "XZM",
                                    "Name": "Macau",
                                    "CityId": "XZMA",
                                    "CountryId": "MO",
                                    "Location": "113, 22.25"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "HK",
                    "Name": "Hong Kong",
                    "CurrencyId": "HKD",
                    "Cities": [
                        {
                            "Id": "HKGA",
                            "Name": "Hong Kong",
                            "SingleAirportCity": false,
                            "CountryId": "HK",
                            "Location": "114.13499, 22.381087",
                            "IataCode": "HKG",
                            "Airports": [
                                {
                                    "Id": "HKG",
                                    "Name": "Hong Kong Intl",
                                    "CityId": "HKGA",
                                    "CountryId": "HK",
                                    "Location": "113.91846, 22.308117"
                                },
                                {
                                    "Id": "HHP",
                                    "Name": "Hong Kong H K Heliport",
                                    "CityId": "HKGA",
                                    "CountryId": "HK",
                                    "Location": "114.2, 22.318056"
                                }
                            ]
                        },
                        {
                            "Id": "QDMA",
                            "Name": "Shek Mum",
                            "SingleAirportCity": true,
                            "CountryId": "HK",
                            "Location": "113.2828, 22.289183",
                            "IataCode": "QDM",
                            "Airports": [
                                {
                                    "Id": "QDM",
                                    "Name": "Shek Mum",
                                    "CityId": "QDMA",
                                    "CountryId": "HK",
                                    "Location": "114.25056, 22.400556"
                                }
                            ]
                        },
                        {
                            "Id": "KTZA",
                            "Name": "Kwun Tong",
                            "SingleAirportCity": true,
                            "CountryId": "HK",
                            "Location": "114.22626, 22.315059",
                            "IataCode": "KTZ",
                            "Airports": [
                                {
                                    "Id": "KTZ",
                                    "Name": "Kwun Tong",
                                    "CityId": "KTZA",
                                    "CountryId": "HK",
                                    "Location": "114.21667, 22.316667"
                                }
                            ]
                        },
                        {
                            "Id": "XKTA",
                            "Name": "Wah Fu",
                            "SingleAirportCity": true,
                            "CountryId": "HK",
                            "Location": "114.13333, 22.25",
                            "IataCode": "XKT",
                            "Airports": [
                                {
                                    "Id": "XKT",
                                    "Name": "Kennedy Town",
                                    "CityId": "XKTA",
                                    "CountryId": "HK",
                                    "Location": "114.1, 22.26667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CN",
                    "Name": "China",
                    "CurrencyId": "CNY",
                    "LanguageId": "ZH",
                    "Cities": [
                        {
                            "Id": "CWEF",
                            "Name": "Weifang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.10194, 36.71",
                            "IataCode": "WEF",
                            "Airports": [
                                {
                                    "Id": "WEF",
                                    "Name": "Weifang",
                                    "CityId": "CWEF",
                                    "CountryId": "CN",
                                    "Location": "119.21667, 36.75"
                                }
                            ]
                        },
                        {
                            "Id": "CWEH",
                            "Name": "Weihai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.11361, 37.501667",
                            "IataCode": "WEH",
                            "Airports": [
                                {
                                    "Id": "WEH",
                                    "Name": "Weihai",
                                    "CityId": "CWEH",
                                    "CountryId": "CN",
                                    "Location": "122.229, 37.1871"
                                }
                            ]
                        },
                        {
                            "Id": "CWNZ",
                            "Name": "Wenzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.65444, 28.019167",
                            "IataCode": "WNZ",
                            "Airports": [
                                {
                                    "Id": "WNZ",
                                    "Name": "Wenzhou",
                                    "CityId": "CWNZ",
                                    "CountryId": "CN",
                                    "Location": "120.852, 27.9122"
                                }
                            ]
                        },
                        {
                            "Id": "CWUH",
                            "Name": "Wuhan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.26667, 30.583342",
                            "IataCode": "WUH",
                            "Airports": [
                                {
                                    "Id": "WUH",
                                    "Name": "Wuhan",
                                    "CityId": "CWUH",
                                    "CountryId": "CN",
                                    "Location": "114.20806, 30.783889"
                                }
                            ]
                        },
                        {
                            "Id": "CWUX",
                            "Name": "Wuxi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.29389, 31.577224",
                            "IataCode": "WUX",
                            "Airports": [
                                {
                                    "Id": "WUX",
                                    "Name": "Wuxi",
                                    "CityId": "CWUX",
                                    "CountryId": "CN",
                                    "Location": "120.31667, 31.583333"
                                }
                            ]
                        },
                        {
                            "Id": "CWUZ",
                            "Name": "Wuzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.31667, 23.483333",
                            "IataCode": "WUZ",
                            "Airports": [
                                {
                                    "Id": "WUZ",
                                    "Name": "Wuzhou",
                                    "CityId": "CWUZ",
                                    "CountryId": "CN",
                                    "Location": "111.33333, 23.5"
                                }
                            ]
                        },
                        {
                            "Id": "CWXN",
                            "Name": "Wanxian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "108.38972, 30.803889",
                            "IataCode": "WXN",
                            "Airports": [
                                {
                                    "Id": "WXN",
                                    "Name": "Wanxian",
                                    "CityId": "CWXN",
                                    "CountryId": "CN",
                                    "Location": "108.406, 30.8361"
                                }
                            ]
                        },
                        {
                            "Id": "CXFN",
                            "Name": "Xiangfan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.145, 32.041667",
                            "IataCode": "XFN",
                            "Airports": [
                                {
                                    "Id": "XFN",
                                    "Name": "Xiangfan",
                                    "CityId": "CXFN",
                                    "CountryId": "CN",
                                    "Location": "112.291, 32.1506"
                                }
                            ]
                        },
                        {
                            "Id": "CXIC",
                            "Name": "Xichang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.27056, 27.898333",
                            "IataCode": "XIC",
                            "Airports": [
                                {
                                    "Id": "XIC",
                                    "Name": "Xichang",
                                    "CityId": "CXIC",
                                    "CountryId": "CN",
                                    "Location": "102.21667, 27.966667"
                                }
                            ]
                        },
                        {
                            "Id": "XIAN",
                            "Name": "Xi An",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "108.92861, 34.258339",
                            "IataCode": "SIA",
                            "Airports": [
                                {
                                    "Id": "XIY",
                                    "Name": "Xi An Xianyang",
                                    "CityId": "XIAN",
                                    "CountryId": "CN",
                                    "Location": "108.75, 34.433333"
                                },
                                {
                                    "Id": "SIA",
                                    "Name": "Xi An Xiguan",
                                    "CityId": "XIAN",
                                    "CountryId": "CN",
                                    "Location": "108.86667, 34.25"
                                }
                            ]
                        },
                        {
                            "Id": "CXMN",
                            "Name": "Xiamen",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.09306, 24.467501",
                            "IataCode": "XMN",
                            "Airports": [
                                {
                                    "Id": "XMN",
                                    "Name": "Xiamen",
                                    "CityId": "CXMN",
                                    "CountryId": "CN",
                                    "Location": "118.12778, 24.544167"
                                }
                            ]
                        },
                        {
                            "Id": "CXNN",
                            "Name": "Xining",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "101.76667, 36.616667",
                            "IataCode": "XNN",
                            "Airports": [
                                {
                                    "Id": "XNN",
                                    "Name": "Xining",
                                    "CityId": "CXNN",
                                    "CountryId": "CN",
                                    "Location": "102.03747, 36.52556"
                                }
                            ]
                        },
                        {
                            "Id": "CXUZ",
                            "Name": "Xuzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.19167, 34.266946",
                            "IataCode": "XUZ",
                            "Airports": [
                                {
                                    "Id": "XUZ",
                                    "Name": "Xuzhou",
                                    "CityId": "CXUZ",
                                    "CountryId": "CN",
                                    "Location": "117.555, 34.056144"
                                }
                            ]
                        },
                        {
                            "Id": "CYBP",
                            "Name": "Yibin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "104.56667, 28.766667",
                            "IataCode": "YBP",
                            "Airports": [
                                {
                                    "Id": "YBP",
                                    "Name": "Yibin",
                                    "CityId": "CYBP",
                                    "CountryId": "CN",
                                    "Location": "104.53333, 28.816667"
                                }
                            ]
                        },
                        {
                            "Id": "CAQG",
                            "Name": "Anqing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.05056, 30.509167",
                            "IataCode": "AQG",
                            "Airports": [
                                {
                                    "Id": "AQG",
                                    "Name": "Anqing",
                                    "CityId": "CAQG",
                                    "CountryId": "CN",
                                    "Location": "117.03333, 30.516667"
                                }
                            ]
                        },
                        {
                            "Id": "CAKA",
                            "Name": "Ankang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.01722, 32.680001",
                            "IataCode": "AKA",
                            "Airports": [
                                {
                                    "Id": "AKA",
                                    "Name": "Ankang",
                                    "CityId": "CAKA",
                                    "CountryId": "CN",
                                    "Location": "108.93541, 32.708354"
                                }
                            ]
                        },
                        {
                            "Id": "CAKU",
                            "Name": "Aksu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "80.246944, 41.221111",
                            "IataCode": "AKU",
                            "Airports": [
                                {
                                    "Id": "AKU",
                                    "Name": "Aksu",
                                    "CityId": "CAKU",
                                    "CountryId": "CN",
                                    "Location": "80.333333, 41.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CAOG",
                            "Name": "Anshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.99, 41.123614",
                            "IataCode": "AOG",
                            "Airports": [
                                {
                                    "Id": "AOG",
                                    "Name": "Anshan",
                                    "CityId": "CAOG",
                                    "CountryId": "CN",
                                    "Location": "123.03333, 41.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CYIH",
                            "Name": "Yichang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.28472, 30.714444",
                            "IataCode": "YIH",
                            "Airports": [
                                {
                                    "Id": "YIH",
                                    "Name": "Yichang",
                                    "CityId": "CYIH",
                                    "CountryId": "CN",
                                    "Location": "111.48377, 30.551618"
                                }
                            ]
                        },
                        {
                            "Id": "CYIN",
                            "Name": "Yining",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "81.245833, 43.881111",
                            "IataCode": "YIN",
                            "Airports": [
                                {
                                    "Id": "YIN",
                                    "Name": "Yining",
                                    "CityId": "CYIN",
                                    "CountryId": "CN",
                                    "Location": "81.233333, 43.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CYIW",
                            "Name": "Yiwu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.06972, 29.308611",
                            "IataCode": "YIW",
                            "Airports": [
                                {
                                    "Id": "YIW",
                                    "Name": "Yiwu",
                                    "CityId": "CYIW",
                                    "CountryId": "CN",
                                    "Location": "120.05, 29.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CYLN",
                            "Name": "Yilan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "129.56667, 46.316667",
                            "IataCode": "YLN",
                            "Airports": [
                                {
                                    "Id": "YLN",
                                    "Name": "Yilan",
                                    "CityId": "CYLN",
                                    "CountryId": "CN",
                                    "Location": "129.5, 46.383333"
                                }
                            ]
                        },
                        {
                            "Id": "CYNJ",
                            "Name": "Yanji",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "129.50778, 42.9075",
                            "IataCode": "YNJ",
                            "Airports": [
                                {
                                    "Id": "YNJ",
                                    "Name": "Yanji",
                                    "CityId": "CYNJ",
                                    "CountryId": "CN",
                                    "Location": "129.451, 42.8828"
                                }
                            ]
                        },
                        {
                            "Id": "CYNZ",
                            "Name": "Yancheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.12528, 33.385555",
                            "IataCode": "YNZ",
                            "Airports": [
                                {
                                    "Id": "YNZ",
                                    "Name": "Yancheng",
                                    "CityId": "CYNZ",
                                    "CountryId": "CN",
                                    "Location": "120.12528, 33.385556"
                                }
                            ]
                        },
                        {
                            "Id": "CZAT",
                            "Name": "Zhaotong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "103.71667, 27.316667",
                            "IataCode": "ZAT",
                            "Airports": [
                                {
                                    "Id": "ZAT",
                                    "Name": "Zhaotong",
                                    "CityId": "CZAT",
                                    "CountryId": "CN",
                                    "Location": "103.8, 27.316667"
                                }
                            ]
                        },
                        {
                            "Id": "CLHW",
                            "Name": "Lanzhou",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "103.79222, 36.056399",
                            "IataCode": "LHW",
                            "Airports": [
                                {
                                    "Id": "LHW",
                                    "Name": "Lanzhou",
                                    "CityId": "CLHW",
                                    "CountryId": "CN",
                                    "Location": "103.86667, 36.033333"
                                },
                                {
                                    "Id": "LZD",
                                    "Name": "Lanzhoudong",
                                    "CityId": "CLHW",
                                    "CountryId": "CN",
                                    "Location": "103.68333, 36.1"
                                }
                            ]
                        },
                        {
                            "Id": "CZHA",
                            "Name": "Zhanjiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.38333, 21.200001",
                            "IataCode": "ZHA",
                            "Airports": [
                                {
                                    "Id": "ZHA",
                                    "Name": "Zhanjiang",
                                    "CityId": "CZHA",
                                    "CountryId": "CN",
                                    "Location": "110.46667, 21.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CZUH",
                            "Name": "Zhuhai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.56778, 22.276944",
                            "IataCode": "ZUH",
                            "Airports": [
                                {
                                    "Id": "ZUH",
                                    "Name": "Zhuhai",
                                    "CityId": "CZUH",
                                    "CountryId": "CN",
                                    "Location": "113.36667, 22"
                                }
                            ]
                        },
                        {
                            "Id": "CCHG",
                            "Name": "Chaoyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.45861, 41.570279",
                            "IataCode": "CHG",
                            "Airports": [
                                {
                                    "Id": "CHG",
                                    "Name": "Chaoyang",
                                    "CityId": "CCHG",
                                    "CountryId": "CN",
                                    "Location": "120.435, 41.5381"
                                }
                            ]
                        },
                        {
                            "Id": "CCHW",
                            "Name": "Jiuquan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "98.566667, 39.766667",
                            "IataCode": "CHW",
                            "Airports": [
                                {
                                    "Id": "CHW",
                                    "Name": "Jiuquan",
                                    "CityId": "CCHW",
                                    "CountryId": "CN",
                                    "Location": "98.416667, 39.85"
                                }
                            ]
                        },
                        {
                            "Id": "CDZU",
                            "Name": "Dazu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.71472, 29.701944",
                            "IataCode": "DZU",
                            "Airports": [
                                {
                                    "Id": "DZU",
                                    "Name": "Dazu",
                                    "CityId": "CDZU",
                                    "CountryId": "CN",
                                    "Location": "105.71667, 29.7"
                                }
                            ]
                        },
                        {
                            "Id": "CCSX",
                            "Name": "Changsha",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.96667, 28.200003",
                            "IataCode": "CSX",
                            "Airports": [
                                {
                                    "Id": "CSX",
                                    "Name": "Changsha",
                                    "CityId": "CCSX",
                                    "CountryId": "CN",
                                    "Location": "113.22167, 28.186667"
                                }
                            ]
                        },
                        {
                            "Id": "CHNY",
                            "Name": "Hengyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.615, 26.888056",
                            "IataCode": "HNY",
                            "Airports": [
                                {
                                    "Id": "HNY",
                                    "Name": "Hengyang",
                                    "CityId": "CHNY",
                                    "CountryId": "CN",
                                    "Location": "112.5, 26.85"
                                }
                            ]
                        },
                        {
                            "Id": "CZYI",
                            "Name": "Zunyi",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "106.90722, 27.686667",
                            "IataCode": "ZYI",
                            "Airports": [
                                {
                                    "Id": "ZYI",
                                    "Name": "Zunyi",
                                    "CityId": "CZYI",
                                    "CountryId": "CN",
                                    "Location": "106.83333, 27.666667"
                                },
                                {
                                    "Id": "WMT",
                                    "Name": "Maotai",
                                    "CityId": "CZYI",
                                    "CountryId": "CN",
                                    "Location": "107.24686, 27.804732"
                                }
                            ]
                        },
                        {
                            "Id": "CLHK",
                            "Name": "Guanghua",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.66778, 32.385834",
                            "IataCode": "LHK",
                            "Airports": [
                                {
                                    "Id": "LHK",
                                    "Name": "Guanghua",
                                    "CityId": "CLHK",
                                    "CountryId": "CN",
                                    "Location": "111.63333, 32.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CXEN",
                            "Name": "Xingcheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.71667, 40.616667",
                            "IataCode": "XEN",
                            "Airports": [
                                {
                                    "Id": "XEN",
                                    "Name": "Xingcheng",
                                    "CityId": "CXEN",
                                    "CountryId": "CN",
                                    "Location": "118.61667, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "CXIN",
                            "Name": "Xingning",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115.71275, 24.144096",
                            "IataCode": "XIN",
                            "Airports": [
                                {
                                    "Id": "XIN",
                                    "Name": "Xingning",
                                    "CityId": "CXIN",
                                    "CountryId": "CN",
                                    "Location": "116, 24"
                                }
                            ]
                        },
                        {
                            "Id": "CXNT",
                            "Name": "Xingtai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.49417, 37.063058",
                            "IataCode": "XNT",
                            "Airports": [
                                {
                                    "Id": "XNT",
                                    "Name": "Xingtai",
                                    "CityId": "CXNT",
                                    "CountryId": "CN",
                                    "Location": "114.5, 37.1"
                                }
                            ]
                        },
                        {
                            "Id": "CBFU",
                            "Name": "Bengbu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.36083, 32.940835",
                            "IataCode": "BFU",
                            "Airports": [
                                {
                                    "Id": "BFU",
                                    "Name": "Bengbu",
                                    "CityId": "CBFU",
                                    "CountryId": "CN",
                                    "Location": "117.33333, 32.95"
                                }
                            ]
                        },
                        {
                            "Id": "CBHY",
                            "Name": "Beihai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.1, 21.483334",
                            "IataCode": "BHY",
                            "Airports": [
                                {
                                    "Id": "BHY",
                                    "Name": "Beihai",
                                    "CityId": "CBHY",
                                    "CountryId": "CN",
                                    "Location": "109.294, 21.5394"
                                }
                            ]
                        },
                        {
                            "Id": "CBPX",
                            "Name": "Bangda",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "91.857376, 30.02407",
                            "IataCode": "BPX",
                            "Airports": [
                                {
                                    "Id": "BPX",
                                    "Name": "Bangda",
                                    "CityId": "CBPX",
                                    "CountryId": "CN",
                                    "Location": "97.106667, 30.555833"
                                }
                            ]
                        },
                        {
                            "Id": "CBSD",
                            "Name": "Baoshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "99.15, 25.116667",
                            "IataCode": "BSD",
                            "Airports": [
                                {
                                    "Id": "BSD",
                                    "Name": "Baoshan",
                                    "CityId": "CBSD",
                                    "CountryId": "CN",
                                    "Location": "99.15, 25.15"
                                }
                            ]
                        },
                        {
                            "Id": "CCAN",
                            "Name": "Guangzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.33992, 23.132725",
                            "IataCode": "CAN",
                            "Airports": [
                                {
                                    "Id": "CAN",
                                    "Name": "Guangzhou",
                                    "CityId": "CCAN",
                                    "CountryId": "CN",
                                    "Location": "113.29879, 23.39244"
                                }
                            ]
                        },
                        {
                            "Id": "CCGD",
                            "Name": "Changde",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.67806, 29.032223",
                            "IataCode": "CGD",
                            "Airports": [
                                {
                                    "Id": "CGD",
                                    "Name": "Changde",
                                    "CityId": "CCGD",
                                    "CountryId": "CN",
                                    "Location": "111.58333, 29.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CCGO",
                            "Name": "Zhengzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.64861, 34.757781",
                            "IataCode": "CGO",
                            "Airports": [
                                {
                                    "Id": "CGO",
                                    "Name": "Zhengzhou",
                                    "CityId": "CCGO",
                                    "CountryId": "CN",
                                    "Location": "113.84089, 34.51967"
                                }
                            ]
                        },
                        {
                            "Id": "CCGQ",
                            "Name": "Changchun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "125.32278, 43.880008",
                            "IataCode": "CGQ",
                            "Airports": [
                                {
                                    "Id": "CGQ",
                                    "Name": "Changchun",
                                    "CityId": "CCGQ",
                                    "CountryId": "CN",
                                    "Location": "125.68536, 43.99621"
                                }
                            ]
                        },
                        {
                            "Id": "CCIF",
                            "Name": "Chifeng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.96361, 42.268333",
                            "IataCode": "CIF",
                            "Airports": [
                                {
                                    "Id": "CIF",
                                    "Name": "Chifeng",
                                    "CityId": "CCIF",
                                    "CountryId": "CN",
                                    "Location": "118.90944, 42.23333"
                                }
                            ]
                        },
                        {
                            "Id": "CCIH",
                            "Name": "Changzhi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.10528, 36.183889",
                            "IataCode": "CIH",
                            "Airports": [
                                {
                                    "Id": "CIH",
                                    "Name": "Changzhi",
                                    "CityId": "CCIH",
                                    "CountryId": "CN",
                                    "Location": "113.13333, 36.183333"
                                }
                            ]
                        },
                        {
                            "Id": "CCKG",
                            "Name": "Chongqing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.55278, 29.562785",
                            "IataCode": "CKG",
                            "Airports": [
                                {
                                    "Id": "CKG",
                                    "Name": "Chongqing",
                                    "CityId": "CCKG",
                                    "CountryId": "CN",
                                    "Location": "106.64167, 29.719167"
                                }
                            ]
                        },
                        {
                            "Id": "CCTU",
                            "Name": "Chengdu",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "104.06667, 30.666675",
                            "IataCode": "CTU",
                            "Airports": [
                                {
                                    "Id": "CTU",
                                    "Name": "Chengdu",
                                    "CityId": "CCTU",
                                    "CountryId": "CN",
                                    "Location": "103.94722, 30.578611"
                                },
                                {
                                    "Id": "TFU",
                                    "Name": "Chengdu Tianfu International Airport",
                                    "CityId": "CCTU",
                                    "CountryId": "CN",
                                    "Location": "104.445, 30.569"
                                }
                            ]
                        },
                        {
                            "Id": "CAYN",
                            "Name": "Anyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.32889, 36.099444",
                            "IataCode": "AYN",
                            "Airports": [
                                {
                                    "Id": "AYN",
                                    "Name": "Anyang",
                                    "CityId": "CAYN",
                                    "CountryId": "CN",
                                    "Location": "114.35, 36.1"
                                }
                            ]
                        },
                        {
                            "Id": "CDAX",
                            "Name": "Daxian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.51667, 31.266667",
                            "IataCode": "DAX",
                            "Airports": [
                                {
                                    "Id": "DAX",
                                    "Name": "Daxian",
                                    "CityId": "CDAX",
                                    "CountryId": "CN",
                                    "Location": "107.42763, 31.131485"
                                }
                            ]
                        },
                        {
                            "Id": "CDDG",
                            "Name": "Dandong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "124.39472, 40.129167",
                            "IataCode": "DDG",
                            "Airports": [
                                {
                                    "Id": "DDG",
                                    "Name": "Dandong",
                                    "CityId": "CDDG",
                                    "CountryId": "CN",
                                    "Location": "124.28653, 40.033595"
                                }
                            ]
                        },
                        {
                            "Id": "CDLC",
                            "Name": "Dalian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "121.60222, 38.912227",
                            "IataCode": "DLC",
                            "Airports": [
                                {
                                    "Id": "DLC",
                                    "Name": "Dalian",
                                    "CityId": "CDLC",
                                    "CountryId": "CN",
                                    "Location": "121.55, 38.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CDNH",
                            "Name": "Dunhuang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "94.683333, 40.166667",
                            "IataCode": "DNH",
                            "Airports": [
                                {
                                    "Id": "DNH",
                                    "Name": "Dunhuang",
                                    "CityId": "CDNH",
                                    "CountryId": "CN",
                                    "Location": "94.683333, 40.2"
                                }
                            ]
                        },
                        {
                            "Id": "CDYG",
                            "Name": "Dayong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.47833, 29.129444",
                            "IataCode": "DYG",
                            "Airports": [
                                {
                                    "Id": "DYG",
                                    "Name": "Dayong",
                                    "CityId": "CDYG",
                                    "CountryId": "CN",
                                    "Location": "110.44411, 29.103883"
                                }
                            ]
                        },
                        {
                            "Id": "CDSN",
                            "Name": "Dongsheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.97694, 39.815833",
                            "IataCode": "DSN",
                            "Airports": [
                                {
                                    "Id": "DSN",
                                    "Name": "Dongsheng",
                                    "CityId": "CDSN",
                                    "CountryId": "CN",
                                    "Location": "109.86174, 39.49387"
                                }
                            ]
                        },
                        {
                            "Id": "CENH",
                            "Name": "Enshi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.48333, 30.3",
                            "IataCode": "ENH",
                            "Airports": [
                                {
                                    "Id": "ENH",
                                    "Name": "Enshi",
                                    "CityId": "CENH",
                                    "CountryId": "CN",
                                    "Location": "109.31667, 30.283333"
                                }
                            ]
                        },
                        {
                            "Id": "CENY",
                            "Name": "Yan'an",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.49167, 36.598889",
                            "IataCode": "ENY",
                            "Airports": [
                                {
                                    "Id": "ENY",
                                    "Name": "Yan'an",
                                    "CityId": "CENY",
                                    "CountryId": "CN",
                                    "Location": "109.55223, 36.63785"
                                }
                            ]
                        },
                        {
                            "Id": "CFOC",
                            "Name": "Fuzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.30611, 26.06139",
                            "IataCode": "FOC",
                            "Airports": [
                                {
                                    "Id": "FOC",
                                    "Name": "Fuzhou",
                                    "CityId": "CFOC",
                                    "CountryId": "CN",
                                    "Location": "119.66327, 25.93506"
                                }
                            ]
                        },
                        {
                            "Id": "CFUO",
                            "Name": "Fuoshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.11667, 23.033333",
                            "IataCode": "FUO",
                            "Airports": [
                                {
                                    "Id": "FUO",
                                    "Name": "Fuoshan",
                                    "CityId": "CFUO",
                                    "CountryId": "CN",
                                    "Location": "113.0694, 23.085733"
                                }
                            ]
                        },
                        {
                            "Id": "CHMI",
                            "Name": "Hami",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "93.45, 42.8",
                            "IataCode": "HMI",
                            "Airports": [
                                {
                                    "Id": "HMI",
                                    "Name": "Hami",
                                    "CityId": "CHMI",
                                    "CountryId": "CN",
                                    "Location": "93.416667, 42.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CGOQ",
                            "Name": "Golmud",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "94.916667, 36.366667",
                            "IataCode": "GOQ",
                            "Airports": [
                                {
                                    "Id": "GOQ",
                                    "Name": "Golmud",
                                    "CityId": "CGOQ",
                                    "CountryId": "CN",
                                    "Location": "94.7861, 36.4006"
                                }
                            ]
                        },
                        {
                            "Id": "CGYS",
                            "Name": "Guang Yuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.82139, 32.440278",
                            "IataCode": "GYS",
                            "Airports": [
                                {
                                    "Id": "GYS",
                                    "Name": "Guang Yuan",
                                    "CityId": "CGYS",
                                    "CountryId": "CN",
                                    "Location": "105.69972, 32.395"
                                }
                            ]
                        },
                        {
                            "Id": "CHAK",
                            "Name": "Haikou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.34167, 20.045833",
                            "IataCode": "HAK",
                            "Airports": [
                                {
                                    "Id": "HAK",
                                    "Name": "Haikou",
                                    "CityId": "CHAK",
                                    "CountryId": "CN",
                                    "Location": "110.45889, 19.934722"
                                }
                            ]
                        },
                        {
                            "Id": "CHEK",
                            "Name": "Heihe",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "127.46667, 50.266668",
                            "IataCode": "HEK",
                            "Airports": [
                                {
                                    "Id": "HEK",
                                    "Name": "Heihe",
                                    "CityId": "CHEK",
                                    "CountryId": "CN",
                                    "Location": "127.43333, 50.216667"
                                }
                            ]
                        },
                        {
                            "Id": "CHET",
                            "Name": "Hohhot",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.65222, 40.810556",
                            "IataCode": "HET",
                            "Airports": [
                                {
                                    "Id": "HET",
                                    "Name": "Hohhot",
                                    "CityId": "CHET",
                                    "CountryId": "CN",
                                    "Location": "111.82167, 40.853333"
                                }
                            ]
                        },
                        {
                            "Id": "CHFE",
                            "Name": "Hefei",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.28083, 31.863891",
                            "IataCode": "HFE",
                            "Airports": [
                                {
                                    "Id": "HFE",
                                    "Name": "Hefei",
                                    "CityId": "CHFE",
                                    "CountryId": "CN",
                                    "Location": "117.29833, 31.78"
                                }
                            ]
                        },
                        {
                            "Id": "CHGH",
                            "Name": "Hangzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.16889, 30.25528",
                            "IataCode": "HGH",
                            "Airports": [
                                {
                                    "Id": "HGH",
                                    "Name": "Hangzhou",
                                    "CityId": "CHGH",
                                    "CountryId": "CN",
                                    "Location": "120.43444, 30.229444"
                                }
                            ]
                        },
                        {
                            "Id": "CHLD",
                            "Name": "Hailar",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.7, 49.200002",
                            "IataCode": "HLD",
                            "Airports": [
                                {
                                    "Id": "HLD",
                                    "Name": "Hailar",
                                    "CityId": "CHLD",
                                    "CountryId": "CN",
                                    "Location": "119.7, 49.2"
                                }
                            ]
                        },
                        {
                            "Id": "CHRB",
                            "Name": "Harbin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "126.65, 45.750009",
                            "IataCode": "HRB",
                            "Airports": [
                                {
                                    "Id": "HRB",
                                    "Name": "Harbin",
                                    "CityId": "CHRB",
                                    "CountryId": "CN",
                                    "Location": "126.25028, 45.623333"
                                }
                            ]
                        },
                        {
                            "Id": "CHSC",
                            "Name": "Shaoguan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.58333, 24.800001",
                            "IataCode": "HSC",
                            "Airports": [
                                {
                                    "Id": "HSC",
                                    "Name": "Shaoguan",
                                    "CityId": "CHSC",
                                    "CountryId": "CN",
                                    "Location": "113.61667, 24.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CHSN",
                            "Name": "Zhoushan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.09806, 30.020278",
                            "IataCode": "HSN",
                            "Airports": [
                                {
                                    "Id": "HSN",
                                    "Name": "Zhoushan",
                                    "CityId": "CHSN",
                                    "CountryId": "CN",
                                    "Location": "122.362, 29.9342"
                                }
                            ]
                        },
                        {
                            "Id": "HOTA",
                            "Name": "Hotan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "79.926945, 37.099722",
                            "IataCode": "HTN",
                            "Airports": [
                                {
                                    "Id": "HTN",
                                    "Name": "Hotan",
                                    "CityId": "HOTA",
                                    "CountryId": "CN",
                                    "Location": "79.86493, 37.03852"
                                }
                            ]
                        },
                        {
                            "Id": "CHUZ",
                            "Name": "Huizhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.4, 23.083334",
                            "IataCode": "HUZ",
                            "Airports": [
                                {
                                    "Id": "HUZ",
                                    "Name": "Huizhou",
                                    "CityId": "CHUZ",
                                    "CountryId": "CN",
                                    "Location": "114.36667, 23.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CHYN",
                            "Name": "Huangyan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "121.25944, 28.647778",
                            "IataCode": "HYN",
                            "Airports": [
                                {
                                    "Id": "HYN",
                                    "Name": "Taizhou",
                                    "CityId": "CHYN",
                                    "CountryId": "CN",
                                    "Location": "121.42746, 28.564"
                                }
                            ]
                        },
                        {
                            "Id": "CHZG",
                            "Name": "Hanzhong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.03028, 33.072778",
                            "IataCode": "HZG",
                            "Airports": [
                                {
                                    "Id": "HZG",
                                    "Name": "Hanzhong",
                                    "CityId": "CHZG",
                                    "CountryId": "CN",
                                    "Location": "107.18333, 32.983333"
                                }
                            ]
                        },
                        {
                            "Id": "CINC",
                            "Name": "Yinchuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.27306, 38.468057",
                            "IataCode": "INC",
                            "Airports": [
                                {
                                    "Id": "INC",
                                    "Name": "Yinchuan",
                                    "CityId": "CINC",
                                    "CountryId": "CN",
                                    "Location": "106.35, 38.35"
                                }
                            ]
                        },
                        {
                            "Id": "CIQM",
                            "Name": "Qiemo",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "85.533333, 38.133333",
                            "IataCode": "IQM",
                            "Airports": [
                                {
                                    "Id": "IQM",
                                    "Name": "Qiemo",
                                    "CityId": "CIQM",
                                    "CountryId": "CN",
                                    "Location": "85.533333, 38.133333"
                                }
                            ]
                        },
                        {
                            "Id": "CIQN",
                            "Name": "Qingyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.87278, 36.000833",
                            "IataCode": "IQN",
                            "Airports": [
                                {
                                    "Id": "IQN",
                                    "Name": "Qingyang",
                                    "CityId": "CIQN",
                                    "CountryId": "CN",
                                    "Location": "107.78333, 36.1"
                                }
                            ]
                        },
                        {
                            "Id": "CJDZ",
                            "Name": "Jingdezhen",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.20222, 29.289722",
                            "IataCode": "JDZ",
                            "Airports": [
                                {
                                    "Id": "JDZ",
                                    "Name": "Jingdezhen",
                                    "CityId": "CJDZ",
                                    "CountryId": "CN",
                                    "Location": "117.18333, 29.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CJHG",
                            "Name": "Jinghong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.81667, 21.983333",
                            "IataCode": "JHG",
                            "Airports": [
                                {
                                    "Id": "JHG",
                                    "Name": "Xishuangbanna Gasa",
                                    "CityId": "CJHG",
                                    "CountryId": "CN",
                                    "Location": "100.7657, 21.972897"
                                }
                            ]
                        },
                        {
                            "Id": "CJIL",
                            "Name": "Jilin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "126.56028, 43.850838",
                            "IataCode": "JIL",
                            "Airports": [
                                {
                                    "Id": "JIL",
                                    "Name": "Jilin",
                                    "CityId": "CJIL",
                                    "CountryId": "CN",
                                    "Location": "126.65, 43.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CJMU",
                            "Name": "Jiamusi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "130.35, 46.833333",
                            "IataCode": "JMU",
                            "Airports": [
                                {
                                    "Id": "JMU",
                                    "Name": "Jiamusi",
                                    "CityId": "CJMU",
                                    "CountryId": "CN",
                                    "Location": "130.35, 46.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CJNG",
                            "Name": "Jining",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "116.58139, 35.405",
                            "IataCode": "JNG",
                            "Airports": [
                                {
                                    "Id": "JNG",
                                    "Name": "Jining",
                                    "CityId": "CJNG",
                                    "CountryId": "CN",
                                    "Location": "116.33444, 35.29276"
                                }
                            ]
                        },
                        {
                            "Id": "CJNZ",
                            "Name": "Jinzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "121.14167, 41.107778",
                            "IataCode": "JNZ",
                            "Airports": [
                                {
                                    "Id": "JNZ",
                                    "Name": "Jinzhou",
                                    "CityId": "CJNZ",
                                    "CountryId": "CN",
                                    "Location": "121.01667, 41.116667"
                                }
                            ]
                        },
                        {
                            "Id": "CJUZ",
                            "Name": "Juzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.86861, 28.959444",
                            "IataCode": "JUZ",
                            "Airports": [
                                {
                                    "Id": "JUZ",
                                    "Name": "Juzhou",
                                    "CityId": "CJUZ",
                                    "CountryId": "CN",
                                    "Location": "118.899, 28.9658"
                                }
                            ]
                        },
                        {
                            "Id": "CKCA",
                            "Name": "Kuqa",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "82.936389, 41.727778",
                            "IataCode": "KCA",
                            "Airports": [
                                {
                                    "Id": "KCA",
                                    "Name": "Kuqa",
                                    "CityId": "CKCA",
                                    "CountryId": "CN",
                                    "Location": "82.9869, 41.7181"
                                }
                            ]
                        },
                        {
                            "Id": "CKOW",
                            "Name": "Ganzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.93333, 25.85",
                            "IataCode": "KOW",
                            "Airports": [
                                {
                                    "Id": "KOW",
                                    "Name": "Ganzhou",
                                    "CityId": "CKOW",
                                    "CountryId": "CN",
                                    "Location": "114.91667, 25.9"
                                }
                            ]
                        },
                        {
                            "Id": "CKRL",
                            "Name": "Korla",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "86.146945, 41.759722",
                            "IataCode": "KRL",
                            "Airports": [
                                {
                                    "Id": "KRL",
                                    "Name": "Korla",
                                    "CityId": "CKRL",
                                    "CountryId": "CN",
                                    "Location": "86.15, 41.733333"
                                }
                            ]
                        },
                        {
                            "Id": "CKRY",
                            "Name": "Karamay",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "84.887238, 45.584731",
                            "IataCode": "KRY",
                            "Airports": [
                                {
                                    "Id": "KRY",
                                    "Name": "Karamay",
                                    "CityId": "CKRY",
                                    "CountryId": "CN",
                                    "Location": "84.883333, 45.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CKHG",
                            "Name": "Kashi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "75.979722, 39.454724",
                            "IataCode": "KHG",
                            "Airports": [
                                {
                                    "Id": "KHG",
                                    "Name": "Kashi",
                                    "CityId": "CKHG",
                                    "CountryId": "CN",
                                    "Location": "76.02, 39.542778"
                                }
                            ]
                        },
                        {
                            "Id": "CKMG",
                            "Name": "Kunming",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.71833, 25.03889",
                            "IataCode": "KMG",
                            "Airports": [
                                {
                                    "Id": "KMG",
                                    "Name": "Kunming",
                                    "CityId": "CKMG",
                                    "CountryId": "CN",
                                    "Location": "102.74333, 24.9925"
                                }
                            ]
                        },
                        {
                            "Id": "CKWE",
                            "Name": "Guiyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.71667, 26.583335",
                            "IataCode": "KWE",
                            "Airports": [
                                {
                                    "Id": "KWE",
                                    "Name": "Guiyang",
                                    "CityId": "CKWE",
                                    "CountryId": "CN",
                                    "Location": "106.80083, 26.538611"
                                }
                            ]
                        },
                        {
                            "Id": "CKWL",
                            "Name": "Guilin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.28639, 25.281944",
                            "IataCode": "KWL",
                            "Airports": [
                                {
                                    "Id": "KWL",
                                    "Name": "Guilin",
                                    "CityId": "CKWL",
                                    "CountryId": "CN",
                                    "Location": "110.03917, 25.218056"
                                }
                            ]
                        },
                        {
                            "Id": "CLCX",
                            "Name": "Longyan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.0225, 25.107222",
                            "IataCode": "LCX",
                            "Airports": [
                                {
                                    "Id": "LCX",
                                    "Name": "Longyan",
                                    "CityId": "CLCX",
                                    "CountryId": "CN",
                                    "Location": "116.74556, 25.675556"
                                }
                            ]
                        },
                        {
                            "Id": "CLJG",
                            "Name": "Lijiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.22072, 26.868795",
                            "IataCode": "LJG",
                            "Airports": [
                                {
                                    "Id": "LJG",
                                    "Name": "Lijiang City",
                                    "CityId": "CLJG",
                                    "CountryId": "CN",
                                    "Location": "100.25, 26.683333"
                                }
                            ]
                        },
                        {
                            "Id": "CLNJ",
                            "Name": "Lincang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.03333, 23.9",
                            "IataCode": "LNJ",
                            "Airports": [
                                {
                                    "Id": "LNJ",
                                    "Name": "Lincang",
                                    "CityId": "CLNJ",
                                    "CountryId": "CN",
                                    "Location": "100.02417, 23.738889"
                                }
                            ]
                        },
                        {
                            "Id": "CLUM",
                            "Name": "Luxi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "98.570368, 24.453293",
                            "IataCode": "LUM",
                            "Airports": [
                                {
                                    "Id": "LUM",
                                    "Name": "Dehong Mangshi",
                                    "CityId": "CLUM",
                                    "CountryId": "CN",
                                    "Location": "98.6, 24.45"
                                }
                            ]
                        },
                        {
                            "Id": "CLXA",
                            "Name": "Lhasa",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "91.1, 29.65",
                            "IataCode": "LXA",
                            "Airports": [
                                {
                                    "Id": "LXA",
                                    "Name": "Lhasa Gonggar",
                                    "CityId": "CLXA",
                                    "CountryId": "CN",
                                    "Location": "90.911944, 29.297778"
                                }
                            ]
                        },
                        {
                            "Id": "CLYA",
                            "Name": "Luoyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.45361, 34.683614",
                            "IataCode": "LYA",
                            "Airports": [
                                {
                                    "Id": "LYA",
                                    "Name": "Luoyang",
                                    "CityId": "CLYA",
                                    "CountryId": "CN",
                                    "Location": "112.46667, 34.683333"
                                }
                            ]
                        },
                        {
                            "Id": "CLYI",
                            "Name": "Linyi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.34278, 35.063057",
                            "IataCode": "LYI",
                            "Airports": [
                                {
                                    "Id": "LYI",
                                    "Name": "Linyi",
                                    "CityId": "CLYI",
                                    "CountryId": "CN",
                                    "Location": "118.36667, 35.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CLZH",
                            "Name": "Liuzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.38861, 24.3125",
                            "IataCode": "LZH",
                            "Airports": [
                                {
                                    "Id": "LZH",
                                    "Name": "Liuzhou",
                                    "CityId": "CLZH",
                                    "CountryId": "CN",
                                    "Location": "109.391, 24.2075"
                                }
                            ]
                        },
                        {
                            "Id": "CLZO",
                            "Name": "Luzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.45361, 28.882778",
                            "IataCode": "LZO",
                            "Airports": [
                                {
                                    "Id": "LZO",
                                    "Name": "Luzhou",
                                    "CityId": "CLZO",
                                    "CountryId": "CN",
                                    "Location": "105.35, 28.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CMDG",
                            "Name": "Mudanjiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "129.6, 44.583336",
                            "IataCode": "MDG",
                            "Airports": [
                                {
                                    "Id": "MDG",
                                    "Name": "Mudanjiang",
                                    "CityId": "CMDG",
                                    "CountryId": "CN",
                                    "Location": "129.6, 44.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MIGA",
                            "Name": "Mian Yang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "104.76667, 31.466668",
                            "IataCode": "MIG",
                            "Airports": [
                                {
                                    "Id": "MIG",
                                    "Name": "Mian Yang",
                                    "CityId": "MIGA",
                                    "CountryId": "CN",
                                    "Location": "104.73806, 31.431111"
                                }
                            ]
                        },
                        {
                            "Id": "CNAO",
                            "Name": "Nanchong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.06666, 30.800023",
                            "IataCode": "NAO",
                            "Airports": [
                                {
                                    "Id": "NAO",
                                    "Name": "Nanchong",
                                    "CityId": "CNAO",
                                    "CountryId": "CN",
                                    "Location": "106.06667, 30.8"
                                }
                            ]
                        },
                        {
                            "Id": "BJSA",
                            "Name": "Beijing",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "116.37199, 39.916916",
                            "IataCode": "BJS",
                            "Airports": [
                                {
                                    "Id": "NAY",
                                    "Name": "Beijing Nanyuan",
                                    "CityId": "BJSA",
                                    "CountryId": "CN",
                                    "Location": "116.60833, 40.073611"
                                },
                                {
                                    "Id": "PEK",
                                    "Name": "Beijing Capital",
                                    "CityId": "BJSA",
                                    "CountryId": "CN",
                                    "Location": "116.6, 40.066667"
                                },
                                {
                                    "Id": "PKX",
                                    "Name": "Beijing Daxing",
                                    "CityId": "BJSA",
                                    "CountryId": "CN",
                                    "Location": "116.41056, 39.509167"
                                }
                            ]
                        },
                        {
                            "Id": "CNGB",
                            "Name": "Ningbo",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "121.54194, 29.875",
                            "IataCode": "NGB",
                            "Airports": [
                                {
                                    "Id": "NGB",
                                    "Name": "Ningbo",
                                    "CityId": "CNGB",
                                    "CountryId": "CN",
                                    "Location": "121.465, 29.824722"
                                }
                            ]
                        },
                        {
                            "Id": "CNKG",
                            "Name": "Nanking / Nanjing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.77778, 32.061674",
                            "IataCode": "NKG",
                            "Airports": [
                                {
                                    "Id": "NKG",
                                    "Name": "Nanking / Nanjing",
                                    "CityId": "CNKG",
                                    "CountryId": "CN",
                                    "Location": "118.86202, 31.74204"
                                }
                            ]
                        },
                        {
                            "Id": "CNNG",
                            "Name": "Nanning",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "108.31667, 22.816667",
                            "IataCode": "NNG",
                            "Airports": [
                                {
                                    "Id": "NNG",
                                    "Name": "Nanning",
                                    "CityId": "CNNG",
                                    "CountryId": "CN",
                                    "Location": "108.1725, 22.608333"
                                }
                            ]
                        },
                        {
                            "Id": "CNNY",
                            "Name": "Nanyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.53278, 32.994722",
                            "IataCode": "NNY",
                            "Airports": [
                                {
                                    "Id": "NNY",
                                    "Name": "Nanyang",
                                    "CityId": "CNNY",
                                    "CountryId": "CN",
                                    "Location": "112.53333, 33"
                                }
                            ]
                        },
                        {
                            "Id": "CSHA",
                            "Name": "Shanghai",
                            "SingleAirportCity": false,
                            "CountryId": "CN",
                            "Location": "121.46708, 31.252305",
                            "IataCode": "SHA",
                            "Airports": [
                                {
                                    "Id": "PVG",
                                    "Name": "Shanghai Pu Dong",
                                    "CityId": "CSHA",
                                    "CountryId": "CN",
                                    "Location": "121.78, 31.15"
                                },
                                {
                                    "Id": "SHA",
                                    "Name": "Shanghai Hongqiao",
                                    "CityId": "CSHA",
                                    "CountryId": "CN",
                                    "Location": "121.33333, 31.2"
                                }
                            ]
                        },
                        {
                            "Id": "CSHE",
                            "Name": "Shenyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "123.43278, 41.792232",
                            "IataCode": "SHE",
                            "Airports": [
                                {
                                    "Id": "SHE",
                                    "Name": "Shenyang",
                                    "CityId": "CSHE",
                                    "CountryId": "CN",
                                    "Location": "123.48714, 41.636999"
                                }
                            ]
                        },
                        {
                            "Id": "CSWA",
                            "Name": "Shantou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "116.67833, 23.360002",
                            "IataCode": "SWA",
                            "Airports": [
                                {
                                    "Id": "SWA",
                                    "Name": "Shantou",
                                    "CityId": "CSWA",
                                    "CountryId": "CN",
                                    "Location": "116.75952, 23.42978"
                                }
                            ]
                        },
                        {
                            "Id": "CSYX",
                            "Name": "Sanya",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.505, 18.243056",
                            "IataCode": "SYX",
                            "Airports": [
                                {
                                    "Id": "SYX",
                                    "Name": "Sanya",
                                    "CityId": "CSYX",
                                    "CountryId": "CN",
                                    "Location": "109.4, 18.3"
                                }
                            ]
                        },
                        {
                            "Id": "CSZV",
                            "Name": "Suzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.61806, 31.311391",
                            "IataCode": "SZV",
                            "Airports": [
                                {
                                    "Id": "SZV",
                                    "Name": "Suzhou",
                                    "CityId": "CSZV",
                                    "CountryId": "CN",
                                    "Location": "120.63333, 31.3"
                                }
                            ]
                        },
                        {
                            "Id": "CTAO",
                            "Name": "Qingdao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.37195, 36.098615",
                            "IataCode": "TAO",
                            "Airports": [
                                {
                                    "Id": "TAO",
                                    "Name": "Qingdao",
                                    "CityId": "CTAO",
                                    "CountryId": "CN",
                                    "Location": "120.37444, 36.266111"
                                }
                            ]
                        },
                        {
                            "Id": "CTNH",
                            "Name": "Tonghua",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "125.92639, 41.719722",
                            "IataCode": "TNH",
                            "Airports": [
                                {
                                    "Id": "TNH",
                                    "Name": "Tonghua",
                                    "CityId": "CTNH",
                                    "CountryId": "CN",
                                    "Location": "125.95, 41.75"
                                }
                            ]
                        },
                        {
                            "Id": "CTSN",
                            "Name": "Tianjin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.17667, 39.142232",
                            "IataCode": "TSN",
                            "Airports": [
                                {
                                    "Id": "TSN",
                                    "Name": "Tianjin",
                                    "CityId": "CTSN",
                                    "CountryId": "CN",
                                    "Location": "117.34694, 39.118889"
                                }
                            ]
                        },
                        {
                            "Id": "CTYN",
                            "Name": "Taiyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.56028, 37.869452",
                            "IataCode": "TYN",
                            "Airports": [
                                {
                                    "Id": "TYN",
                                    "Name": "Taiyuan",
                                    "CityId": "CTYN",
                                    "CountryId": "CN",
                                    "Location": "112.61667, 37.75"
                                }
                            ]
                        },
                        {
                            "Id": "CURC",
                            "Name": "Urumqi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "87.583333, 43.800004",
                            "IataCode": "URC",
                            "Airports": [
                                {
                                    "Id": "URC",
                                    "Name": "Urumqi",
                                    "CityId": "CURC",
                                    "CountryId": "CN",
                                    "Location": "87.483333, 43.9"
                                }
                            ]
                        },
                        {
                            "Id": "CAAT",
                            "Name": "Altay",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "88.116666, 47.866667",
                            "IataCode": "AAT",
                            "Airports": [
                                {
                                    "Id": "AAT",
                                    "Name": "Altay",
                                    "CityId": "CAAT",
                                    "CountryId": "CN",
                                    "Location": "88.08738, 47.75223"
                                }
                            ]
                        },
                        {
                            "Id": "CBAV",
                            "Name": "Baotou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.82222, 40.652222",
                            "IataCode": "BAV",
                            "Airports": [
                                {
                                    "Id": "BAV",
                                    "Name": "Baotou",
                                    "CityId": "CBAV",
                                    "CountryId": "CN",
                                    "Location": "109.98333, 40.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BPEA",
                            "Name": "Qinhuangdao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.58833, 39.931668",
                            "IataCode": "BPE",
                            "Airports": [
                                {
                                    "Id": "BPE",
                                    "Name": "Beidaihe",
                                    "CityId": "BPEA",
                                    "CountryId": "CN",
                                    "Location": "119.05889, 39.666389"
                                }
                            ]
                        },
                        {
                            "Id": "CSJW",
                            "Name": "Shijiazhuang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.47861, 38.041393",
                            "IataCode": "SJW",
                            "Airports": [
                                {
                                    "Id": "SJW",
                                    "Name": "Shijiazhuang",
                                    "CityId": "CSJW",
                                    "CountryId": "CN",
                                    "Location": "114.69722, 38.280556"
                                }
                            ]
                        },
                        {
                            "Id": "CTXN",
                            "Name": "Tunxi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.3125, 29.711389",
                            "IataCode": "TXN",
                            "Airports": [
                                {
                                    "Id": "TXN",
                                    "Name": "Tunxi",
                                    "CityId": "CTXN",
                                    "CountryId": "CN",
                                    "Location": "118.3, 29.733333"
                                }
                            ]
                        },
                        {
                            "Id": "CWHU",
                            "Name": "Wuhu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.36667, 31.350001",
                            "IataCode": "WHU",
                            "Airports": [
                                {
                                    "Id": "WHU",
                                    "Name": "Wuhu",
                                    "CityId": "CWHU",
                                    "CountryId": "CN",
                                    "Location": "118.36667, 31.366667"
                                }
                            ]
                        },
                        {
                            "Id": "YCUA",
                            "Name": "Yun Cheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.99278, 35.023056",
                            "IataCode": "YCU",
                            "Airports": [
                                {
                                    "Id": "YCU",
                                    "Name": "Yun Cheng",
                                    "CityId": "YCUA",
                                    "CountryId": "CN",
                                    "Location": "111.0336, 35.1164"
                                }
                            ]
                        },
                        {
                            "Id": "CNDG",
                            "Name": "Qiqihar",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "123.96722, 47.340833",
                            "IataCode": "NDG",
                            "Airports": [
                                {
                                    "Id": "NDG",
                                    "Name": "Qiqihar",
                                    "CityId": "CNDG",
                                    "CountryId": "CN",
                                    "Location": "123.91667, 47.316667"
                                }
                            ]
                        },
                        {
                            "Id": "CNTG",
                            "Name": "Nantong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.87472, 32.030278",
                            "IataCode": "NTG",
                            "Airports": [
                                {
                                    "Id": "NTG",
                                    "Name": "Nantong",
                                    "CityId": "CNTG",
                                    "CountryId": "CN",
                                    "Location": "120.96667, 32.066667"
                                }
                            ]
                        },
                        {
                            "Id": "NZHA",
                            "Name": "Manzhouli",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.43333, 49.6",
                            "IataCode": "NZH",
                            "Airports": [
                                {
                                    "Id": "NZH",
                                    "Name": "Manzhouli",
                                    "CityId": "NZHA",
                                    "CountryId": "CN",
                                    "Location": "117.43333, 49.6"
                                }
                            ]
                        },
                        {
                            "Id": "CSXJ",
                            "Name": "Shanshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "90.166667, 42.866667",
                            "IataCode": "SXJ",
                            "Airports": [
                                {
                                    "Id": "SXJ",
                                    "Name": "Shanshan",
                                    "CityId": "CSXJ",
                                    "CountryId": "CN",
                                    "Location": "90.233333, 42.816667"
                                }
                            ]
                        },
                        {
                            "Id": "CSYM",
                            "Name": "Simao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.97579, 22.735939",
                            "IataCode": "SYM",
                            "Airports": [
                                {
                                    "Id": "SYM",
                                    "Name": "Simao",
                                    "CityId": "CSYM",
                                    "CountryId": "CN",
                                    "Location": "101, 22.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CTCG",
                            "Name": "Tacheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "82.95, 46.75",
                            "IataCode": "TCG",
                            "Airports": [
                                {
                                    "Id": "TCG",
                                    "Name": "Tacheng",
                                    "CityId": "CTCG",
                                    "CountryId": "CN",
                                    "Location": "83.333333, 46.666667"
                                }
                            ]
                        },
                        {
                            "Id": "CTGO",
                            "Name": "Tongliao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.26528, 43.6125",
                            "IataCode": "TGO",
                            "Airports": [
                                {
                                    "Id": "TGO",
                                    "Name": "Tongliao",
                                    "CityId": "CTGO",
                                    "CountryId": "CN",
                                    "Location": "122.25, 43.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CUYN",
                            "Name": "Yulin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.74944, 38.290556",
                            "IataCode": "UYN",
                            "Airports": [
                                {
                                    "Id": "UYN",
                                    "Name": "Yulin",
                                    "CityId": "CUYN",
                                    "CountryId": "CN",
                                    "Location": "109.731, 38.2692"
                                }
                            ]
                        },
                        {
                            "Id": "CACX",
                            "Name": "Xingyi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "104.98333, 25.05",
                            "IataCode": "ACX",
                            "Airports": [
                                {
                                    "Id": "ACX",
                                    "Name": "Xingyi",
                                    "CityId": "CACX",
                                    "CountryId": "CN",
                                    "Location": "104.95944, 25.085556"
                                }
                            ]
                        },
                        {
                            "Id": "CRUG",
                            "Name": "Rugao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.55528, 32.388333",
                            "IataCode": "RUG",
                            "Airports": [
                                {
                                    "Id": "RUG",
                                    "Name": "Rugao",
                                    "CityId": "CRUG",
                                    "CountryId": "CN",
                                    "Location": "120.55528, 32.388333"
                                }
                            ]
                        },
                        {
                            "Id": "CTEN",
                            "Name": "Tongren",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.18528, 27.717222",
                            "IataCode": "TEN",
                            "Airports": [
                                {
                                    "Id": "TEN",
                                    "Name": "Tongren",
                                    "CityId": "CTEN",
                                    "CountryId": "CN",
                                    "Location": "109.05, 27.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CAVA",
                            "Name": "An Shun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.93333, 26.25",
                            "IataCode": "AVA",
                            "Airports": [
                                {
                                    "Id": "AVA",
                                    "Name": "An Shun",
                                    "CityId": "CAVA",
                                    "CountryId": "CN",
                                    "Location": "105.87278, 26.257222"
                                }
                            ]
                        },
                        {
                            "Id": "CDOY",
                            "Name": "Dongying",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.48556, 37.456389",
                            "IataCode": "DOY",
                            "Airports": [
                                {
                                    "Id": "DOY",
                                    "Name": "Dongying",
                                    "CityId": "CDOY",
                                    "CountryId": "CN",
                                    "Location": "118.78778, 37.518333"
                                }
                            ]
                        },
                        {
                            "Id": "CSZX",
                            "Name": "Shenzhen",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.14255, 22.656087",
                            "IataCode": "SZX",
                            "Airports": [
                                {
                                    "Id": "SZX",
                                    "Name": "Shenzhen Bao'an International",
                                    "CityId": "CSZX",
                                    "CountryId": "CN",
                                    "Location": "113.80833, 22.64"
                                }
                            ]
                        },
                        {
                            "Id": "CYNT",
                            "Name": "Yantai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "121.4, 37.533333",
                            "IataCode": "YNT",
                            "Airports": [
                                {
                                    "Id": "YNT",
                                    "Name": "Yantai",
                                    "CityId": "CYNT",
                                    "CountryId": "CN",
                                    "Location": "121.33333, 37.55"
                                }
                            ]
                        },
                        {
                            "Id": "JINA",
                            "Name": "Jinan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "116.99722, 36.668337",
                            "IataCode": "TNA",
                            "Airports": [
                                {
                                    "Id": "TNA",
                                    "Name": "Jinan Yaoqiang",
                                    "CityId": "JINA",
                                    "CountryId": "CN",
                                    "Location": "117.21611, 36.857222"
                                }
                            ]
                        },
                        {
                            "Id": "CDGM",
                            "Name": "Dongguan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.74472, 23.048889",
                            "IataCode": "DGM",
                            "Airports": [
                                {
                                    "Id": "DGM",
                                    "Name": "Dongguan",
                                    "CityId": "CDGM",
                                    "CountryId": "CN",
                                    "Location": "113.74, 23.03"
                                }
                            ]
                        },
                        {
                            "Id": "CCZX",
                            "Name": "Changzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.96667, 31.783333",
                            "IataCode": "CZX",
                            "Airports": [
                                {
                                    "Id": "CZX",
                                    "Name": "Changzhou",
                                    "CityId": "CCZX",
                                    "CountryId": "CN",
                                    "Location": "119.779, 31.9197"
                                }
                            ]
                        },
                        {
                            "Id": "CDLU",
                            "Name": "Dali City",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.18333, 25.7",
                            "IataCode": "DLU",
                            "Airports": [
                                {
                                    "Id": "DLU",
                                    "Name": "Dali City",
                                    "CityId": "CDLU",
                                    "CountryId": "CN",
                                    "Location": "100.31667, 25.666667"
                                }
                            ]
                        },
                        {
                            "Id": "CFUD",
                            "Name": "Sui Fen He",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "131.16667, 44.4",
                            "IataCode": "FUD",
                            "Airports": [
                                {
                                    "Id": "FUD",
                                    "Name": "Sui Fen He",
                                    "CityId": "CFUD",
                                    "CountryId": "CN",
                                    "Location": "131.125, 44.380278"
                                }
                            ]
                        },
                        {
                            "Id": "YANG",
                            "Name": "Yangzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.43583, 32.397222",
                            "IataCode": "YTY",
                            "Airports": [
                                {
                                    "Id": "YTY",
                                    "Name": "Yangzhou Taizhou Airport",
                                    "CityId": "YANG",
                                    "CountryId": "CN",
                                    "Location": "119.715, 32.561667"
                                }
                            ]
                        },
                        {
                            "Id": "CKHN",
                            "Name": "Nanchang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115.88333, 28.683336",
                            "IataCode": "KHN",
                            "Airports": [
                                {
                                    "Id": "KHN",
                                    "Name": "Nanchang",
                                    "CityId": "CKHN",
                                    "CountryId": "CN",
                                    "Location": "115.9, 28.865"
                                }
                            ]
                        },
                        {
                            "Id": "CZAX",
                            "Name": "Pan Yu",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.36194, 22.937222",
                            "IataCode": "ZAX",
                            "Airports": [
                                {
                                    "Id": "ZAX",
                                    "Name": "Pan Yu",
                                    "CityId": "CZAX",
                                    "CountryId": "CN",
                                    "Location": "113.38333, 22.93333"
                                }
                            ]
                        },
                        {
                            "Id": "CWUS",
                            "Name": "Wuyishan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.03066, 27.759947",
                            "IataCode": "WUS",
                            "Airports": [
                                {
                                    "Id": "WUS",
                                    "Name": "Wuyishan",
                                    "CityId": "CWUS",
                                    "CountryId": "CN",
                                    "Location": "117.96667, 27.716667"
                                }
                            ]
                        },
                        {
                            "Id": "CJJN",
                            "Name": "Jinjiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.57639, 24.817778",
                            "IataCode": "JJN",
                            "Airports": [
                                {
                                    "Id": "JJN",
                                    "Name": "Jinjiang",
                                    "CityId": "CJJN",
                                    "CountryId": "CN",
                                    "Location": "118.6, 24.883333"
                                }
                            ]
                        },
                        {
                            "Id": "BURQ",
                            "Name": "Burqin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "86.883333, 47.716667",
                            "IataCode": "KJI",
                            "Airports": [
                                {
                                    "Id": "KJI",
                                    "Name": "Burqin",
                                    "CityId": "BURQ",
                                    "CountryId": "CN",
                                    "Location": "86.998055, 48.22111"
                                }
                            ]
                        },
                        {
                            "Id": "CDEB",
                            "Name": "Chengde",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.93611, 40.9725",
                            "IataCode": "CDE",
                            "Airports": [
                                {
                                    "Id": "CDE",
                                    "Name": "Puning",
                                    "CityId": "CDEB",
                                    "CountryId": "CN",
                                    "Location": "118.07389, 41.1225"
                                }
                            ]
                        },
                        {
                            "Id": "CZBD",
                            "Name": "Jiang Men",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.08333, 22.583333",
                            "IataCode": "ZBD",
                            "Airports": [
                                {
                                    "Id": "ZBD",
                                    "Name": "Jiang Men",
                                    "CityId": "CZBD",
                                    "CountryId": "CN",
                                    "Location": "113.1, 22.6"
                                }
                            ]
                        },
                        {
                            "Id": "CJIU",
                            "Name": "Jiujiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115.98333, 29.733334",
                            "IataCode": "JIU",
                            "Airports": [
                                {
                                    "Id": "JIU",
                                    "Name": "Jiujiang",
                                    "CityId": "CJIU",
                                    "CountryId": "CN",
                                    "Location": "115.80268, 29.483685"
                                }
                            ]
                        },
                        {
                            "Id": "CJGS",
                            "Name": "Ji An",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115, 27.133333",
                            "IataCode": "JGS",
                            "Airports": [
                                {
                                    "Id": "JGS",
                                    "Name": "Ji An",
                                    "CityId": "CJGS",
                                    "CountryId": "CN",
                                    "Location": "114.73265, 26.85534"
                                }
                            ]
                        },
                        {
                            "Id": "CJGN",
                            "Name": "Jiayuguan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "98.3, 39.816667",
                            "IataCode": "JGN",
                            "Airports": [
                                {
                                    "Id": "JGN",
                                    "Name": "Jiayuguan",
                                    "CityId": "CJGN",
                                    "CountryId": "CN",
                                    "Location": "98.34085, 39.86054"
                                }
                            ]
                        },
                        {
                            "Id": "CLYG",
                            "Name": "Lianyungang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.15944, 34.599722",
                            "IataCode": "LYG",
                            "Airports": [
                                {
                                    "Id": "LYG",
                                    "Name": "Lianyungang",
                                    "CityId": "CLYG",
                                    "CountryId": "CN",
                                    "Location": "119.18333, 34.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RIZB",
                            "Name": "Rizhao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.54583, 35.390278",
                            "IataCode": "RIZ",
                            "Airports": [
                                {
                                    "Id": "RIZ",
                                    "Name": "Shanzihe",
                                    "CityId": "RIZB",
                                    "CountryId": "CN",
                                    "Location": "119.32199, 35.400426"
                                }
                            ]
                        },
                        {
                            "Id": "CWUA",
                            "Name": "Wu Hai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.81222, 39.664723",
                            "IataCode": "WUA",
                            "Airports": [
                                {
                                    "Id": "WUA",
                                    "Name": "Wu Hai",
                                    "CityId": "CWUA",
                                    "CountryId": "CN",
                                    "Location": "106.79917, 39.794722"
                                }
                            ]
                        },
                        {
                            "Id": "CXIL",
                            "Name": "Xilinhot",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "116.03333, 43.966668",
                            "IataCode": "XIL",
                            "Airports": [
                                {
                                    "Id": "XIL",
                                    "Name": "Xilinhot",
                                    "CityId": "CXIL",
                                    "CountryId": "CN",
                                    "Location": "116.06667, 43.966667"
                                }
                            ]
                        },
                        {
                            "Id": "YIUN",
                            "Name": "Yichun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.4, 27.833333",
                            "IataCode": "YIC",
                            "Airports": [
                                {
                                    "Id": "YIC",
                                    "Name": "Yichun",
                                    "CityId": "YIUN",
                                    "CountryId": "CN",
                                    "Location": "114.1829, 27.4812"
                                }
                            ]
                        },
                        {
                            "Id": "CFUG",
                            "Name": "Fuyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115.81667, 32.9",
                            "IataCode": "FUG",
                            "Airports": [
                                {
                                    "Id": "FUG",
                                    "Name": "Fuyang",
                                    "CityId": "CFUG",
                                    "CountryId": "CN",
                                    "Location": "115.7, 32.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CMXZ",
                            "Name": "Meixian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "116.11667, 24.3",
                            "IataCode": "MXZ",
                            "Airports": [
                                {
                                    "Id": "MXZ",
                                    "Name": "Meixian",
                                    "CityId": "CMXZ",
                                    "CountryId": "CN",
                                    "Location": "116.13333, 24.35"
                                }
                            ]
                        },
                        {
                            "Id": "CZAS",
                            "Name": "Shunde",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.24528, 22.840833",
                            "IataCode": "ZAS",
                            "Airports": [
                                {
                                    "Id": "ZAS",
                                    "Name": "Shunde",
                                    "CityId": "CZAS",
                                    "CountryId": "CN",
                                    "Location": "113.26667, 22.83333"
                                }
                            ]
                        },
                        {
                            "Id": "DQAA",
                            "Name": "Daqing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "125, 46.583333",
                            "IataCode": "DQA",
                            "Airports": [
                                {
                                    "Id": "DQA",
                                    "Name": "Daqing",
                                    "CityId": "DQAA",
                                    "CountryId": "CN",
                                    "Location": "125.02, 46.59"
                                }
                            ]
                        },
                        {
                            "Id": "TNGS",
                            "Name": "Tangshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.18333, 39.633338",
                            "IataCode": "TVS",
                            "Airports": [
                                {
                                    "Id": "TVS",
                                    "Name": "Tangshan",
                                    "CityId": "TNGS",
                                    "CountryId": "CN",
                                    "Location": "117.99345, 39.724"
                                }
                            ]
                        },
                        {
                            "Id": "BARB",
                            "Name": "Qionghai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.46417, 19.2425",
                            "IataCode": "BAR",
                            "Airports": [
                                {
                                    "Id": "BAR",
                                    "Name": "Bo'ao",
                                    "CityId": "BARB",
                                    "CountryId": "CN",
                                    "Location": "110.45818, 19.141618"
                                }
                            ]
                        },
                        {
                            "Id": "KJHA",
                            "Name": "Kaili",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.97972, 26.585833",
                            "IataCode": "KJH",
                            "Airports": [
                                {
                                    "Id": "KJH",
                                    "Name": "Huangping",
                                    "CityId": "KJHA",
                                    "CountryId": "CN",
                                    "Location": "107.98398, 26.972395"
                                }
                            ]
                        },
                        {
                            "Id": "WDSA",
                            "Name": "Shiyan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.77806, 32.647507",
                            "IataCode": "WDS",
                            "Airports": [
                                {
                                    "Id": "WDS",
                                    "Name": "Wudangshan",
                                    "CityId": "WDSA",
                                    "CountryId": "CN",
                                    "Location": "110.90778, 32.591667"
                                }
                            ]
                        },
                        {
                            "Id": "HZAA",
                            "Name": "Heze",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "115.44111, 35.243056",
                            "IataCode": "HZA",
                            "Airports": [
                                {
                                    "Id": "HZA",
                                    "Name": "Heze Airport",
                                    "CityId": "HZAA",
                                    "CountryId": "CN",
                                    "Location": "115.73689, 35.211833"
                                }
                            ]
                        },
                        {
                            "Id": "LFQA",
                            "Name": "Linfen",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.51889, 36.088889",
                            "IataCode": "LFQ",
                            "Airports": [
                                {
                                    "Id": "LFQ",
                                    "Name": "Linfen Qiaoli",
                                    "CityId": "LFQA",
                                    "CountryId": "CN",
                                    "Location": "111.49222, 36.041944"
                                }
                            ]
                        },
                        {
                            "Id": "CDAT",
                            "Name": "Datong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.73333, 30.816667",
                            "IataCode": "DAT",
                            "Airports": [
                                {
                                    "Id": "DAT",
                                    "Name": "Datong",
                                    "CityId": "CDAT",
                                    "CountryId": "CN",
                                    "Location": "113.482, 40.0603"
                                }
                            ]
                        },
                        {
                            "Id": "GMQA",
                            "Name": "Golog",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "99.551807, 34.085949",
                            "IataCode": "GMQ",
                            "Airports": [
                                {
                                    "Id": "GMQ",
                                    "Name": "Maqin",
                                    "CityId": "GMQA",
                                    "CountryId": "CN",
                                    "Location": "100.31111, 34.415833"
                                }
                            ]
                        },
                        {
                            "Id": "LPFA",
                            "Name": "Liupanshui",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "104.75182, 26.240153",
                            "IataCode": "LPF",
                            "Airports": [
                                {
                                    "Id": "LPF",
                                    "Name": "Liupanshui Yue Zhao",
                                    "CityId": "LPFA",
                                    "CountryId": "CN",
                                    "Location": "104.979, 26.609417"
                                }
                            ]
                        },
                        {
                            "Id": "ADPA",
                            "Name": "Ampara",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "81.666667, 7.2833333",
                            "IataCode": "ADP",
                            "Airports": [
                                {
                                    "Id": "ADP",
                                    "Name": "Gal Oya",
                                    "CityId": "ADPA",
                                    "CountryId": "CN",
                                    "Location": "81.630278, 7.336944"
                                }
                            ]
                        },
                        {
                            "Id": "SHIG",
                            "Name": "Shigatse",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "88.883333, 29.25",
                            "IataCode": "RKZ",
                            "Airports": [
                                {
                                    "Id": "RKZ",
                                    "Name": "Shigatse Peace Airport",
                                    "CityId": "SHIG",
                                    "CountryId": "CN",
                                    "Location": "89.306944, 29.351667"
                                }
                            ]
                        },
                        {
                            "Id": "SHIQ",
                            "Name": "Shiquanhe",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "80.066667, 32.516667",
                            "IataCode": "NGQ",
                            "Airports": [
                                {
                                    "Id": "NGQ",
                                    "Name": "Shiquanhe",
                                    "CityId": "SHIQ",
                                    "CountryId": "CN",
                                    "Location": "80.054276, 32.103817"
                                }
                            ]
                        },
                        {
                            "Id": "RQAC",
                            "Name": "Ruoqiang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "88, 39.033333",
                            "IataCode": "RQA",
                            "Airports": [
                                {
                                    "Id": "RQA",
                                    "Name": "Loulan",
                                    "CityId": "RQAC",
                                    "CountryId": "CN",
                                    "Location": "88.008056, 38.974444"
                                }
                            ]
                        },
                        {
                            "Id": "HTTA",
                            "Name": "Huatugou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "90.86792, 38.255436",
                            "IataCode": "HTT",
                            "Airports": [
                                {
                                    "Id": "HTT",
                                    "Name": "Huatugou",
                                    "CityId": "HTTA",
                                    "CountryId": "CN",
                                    "Location": "90.888496, 38.251766"
                                }
                            ]
                        },
                        {
                            "Id": "HXDA",
                            "Name": "Delingha",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "97.383333, 37.383333",
                            "IataCode": "HXD",
                            "Airports": [
                                {
                                    "Id": "HXD",
                                    "Name": "Delingha",
                                    "CityId": "HXDA",
                                    "CountryId": "CN",
                                    "Location": "97.268611, 37.125"
                                }
                            ]
                        },
                        {
                            "Id": "NLTA",
                            "Name": "Xinyuan City",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "83.147778, 43.451111",
                            "IataCode": "NLT",
                            "Airports": [
                                {
                                    "Id": "NLT",
                                    "Name": "Xinyuan City",
                                    "CityId": "NLTA",
                                    "CountryId": "CN",
                                    "Location": "83.3822, 43.4322"
                                }
                            ]
                        },
                        {
                            "Id": "CFYN",
                            "Name": "Fuyun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "89.65, 47.216667",
                            "IataCode": "FYN",
                            "Airports": [
                                {
                                    "Id": "FYN",
                                    "Name": "Fuyun",
                                    "CityId": "CFYN",
                                    "CountryId": "CN",
                                    "Location": "89.616667, 47.25"
                                }
                            ]
                        },
                        {
                            "Id": "BOLE",
                            "Name": "Bole",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "81.95, 44.983333",
                            "IataCode": "BPL",
                            "Airports": [
                                {
                                    "Id": "BPL",
                                    "Name": "Bole Alashankou",
                                    "CityId": "BOLE",
                                    "CountryId": "CN",
                                    "Location": "82.024167, 44.904167"
                                }
                            ]
                        },
                        {
                            "Id": "ZHON",
                            "Name": "Zhongwei",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.17972, 37.519167",
                            "IataCode": "ZHY",
                            "Airports": [
                                {
                                    "Id": "ZHY",
                                    "Name": "Zhongwei",
                                    "CityId": "ZHON",
                                    "CountryId": "CN",
                                    "Location": "105.15253, 37.568935"
                                }
                            ]
                        },
                        {
                            "Id": "CYZY",
                            "Name": "Zhangye",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.45167, 38.934167",
                            "IataCode": "YZY",
                            "Airports": [
                                {
                                    "Id": "YZY",
                                    "Name": "Zhangye Ganzhou Airport",
                                    "CityId": "CYZY",
                                    "CountryId": "CN",
                                    "Location": "100.403, 38.4807"
                                }
                            ]
                        },
                        {
                            "Id": "CLLB",
                            "Name": "Libo County",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.88333, 25.416667",
                            "IataCode": "LLB",
                            "Airports": [
                                {
                                    "Id": "LLB",
                                    "Name": "Libo Airport",
                                    "CityId": "CLLB",
                                    "CountryId": "CN",
                                    "Location": "105.5742, 25.2709"
                                }
                            ]
                        },
                        {
                            "Id": "YLXA",
                            "Name": "Yulin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.15, 22.633333",
                            "IataCode": "YLX",
                            "Airports": [
                                {
                                    "Id": "YLX",
                                    "Name": "Yulin Fumian Airport",
                                    "CityId": "YLXA",
                                    "CountryId": "CN",
                                    "Location": "110.12083, 22.438056"
                                }
                            ]
                        },
                        {
                            "Id": "YYAC",
                            "Name": "Yueyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.11111, 29.140833",
                            "IataCode": "YYA",
                            "Airports": [
                                {
                                    "Id": "YYA",
                                    "Name": "Sanhe",
                                    "CityId": "YYAC",
                                    "CountryId": "CN",
                                    "Location": "113.278, 29.314"
                                }
                            ]
                        },
                        {
                            "Id": "CLLF",
                            "Name": "LingLing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.61306, 26.423889",
                            "IataCode": "LLF",
                            "Airports": [
                                {
                                    "Id": "LLF",
                                    "Name": "LingLing",
                                    "CityId": "CLLF",
                                    "CountryId": "CN",
                                    "Location": "111.9575, 26.700833"
                                }
                            ]
                        },
                        {
                            "Id": "SQDA",
                            "Name": "Shangrao",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.96667, 28.466667",
                            "IataCode": "SQD",
                            "Airports": [
                                {
                                    "Id": "SQD",
                                    "Name": "Sanqingshan",
                                    "CityId": "SQDA",
                                    "CountryId": "CN",
                                    "Location": "117.96139, 28.380278"
                                }
                            ]
                        },
                        {
                            "Id": "WUTA",
                            "Name": "Xinzhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.73333, 38.409167",
                            "IataCode": "WUT",
                            "Airports": [
                                {
                                    "Id": "WUT",
                                    "Name": "Xinzhou Wutaishan",
                                    "CityId": "WUTA",
                                    "CountryId": "CN",
                                    "Location": "112.96806, 38.597222"
                                }
                            ]
                        },
                        {
                            "Id": "XAIC",
                            "Name": "Xinyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.06556, 32.122781",
                            "IataCode": "XAI",
                            "Airports": [
                                {
                                    "Id": "XAI",
                                    "Name": "Minggang",
                                    "CityId": "XAIC",
                                    "CountryId": "CN",
                                    "Location": "114.07778, 32.541389"
                                }
                            ]
                        },
                        {
                            "Id": "WSKA",
                            "Name": "Wuxia",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.8925, 31.074167",
                            "IataCode": "WSK",
                            "Airports": [
                                {
                                    "Id": "WSK",
                                    "Name": "Chongqing Wushan Airport",
                                    "CityId": "WSKA",
                                    "CountryId": "CN",
                                    "Location": "109.706, 31.064"
                                }
                            ]
                        },
                        {
                            "Id": "THQA",
                            "Name": "Tianshui",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.72722, 34.580278",
                            "IataCode": "THQ",
                            "Airports": [
                                {
                                    "Id": "THQ",
                                    "Name": "Maijishan",
                                    "CityId": "THQA",
                                    "CountryId": "CN",
                                    "Location": "105.86111, 34.558333"
                                }
                            ]
                        },
                        {
                            "Id": "HPGA",
                            "Name": "Shennongjia",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "110.73333, 31.733333",
                            "IataCode": "HPG",
                            "Airports": [
                                {
                                    "Id": "HPG",
                                    "Name": "Hongping",
                                    "CityId": "HPGA",
                                    "CountryId": "CN",
                                    "Location": "110.33806, 31.633611"
                                }
                            ]
                        },
                        {
                            "Id": "CCNI",
                            "Name": "Changhai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.6, 39.275556",
                            "IataCode": "CNI",
                            "Airports": [
                                {
                                    "Id": "CNI",
                                    "Name": "Changhai",
                                    "CityId": "CCNI",
                                    "CountryId": "CN",
                                    "Location": "122.67001, 39.267893"
                                }
                            ]
                        },
                        {
                            "Id": "SQJB",
                            "Name": "Sanming",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.61861, 26.248611",
                            "IataCode": "SQJ",
                            "Airports": [
                                {
                                    "Id": "SQJ",
                                    "Name": "Shaxian",
                                    "CityId": "SQJB",
                                    "CountryId": "CN",
                                    "Location": "117.83906, 26.426596"
                                }
                            ]
                        },
                        {
                            "Id": "NLHA",
                            "Name": "Ninglang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.55, 28.033333",
                            "IataCode": "NLH",
                            "Airports": [
                                {
                                    "Id": "NLH",
                                    "Name": "Luguhu",
                                    "CityId": "NLHA",
                                    "CountryId": "CN",
                                    "Location": "100.75739, 27.543806"
                                }
                            ]
                        },
                        {
                            "Id": "KGTA",
                            "Name": "Kangding",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.03333, 30.05",
                            "IataCode": "KGT",
                            "Airports": [
                                {
                                    "Id": "KGT",
                                    "Name": "Kangding",
                                    "CityId": "KGTA",
                                    "CountryId": "CN",
                                    "Location": "101.7339, 38.1339"
                                }
                            ]
                        },
                        {
                            "Id": "LNLC",
                            "Name": "Longnan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.51667, 33.466667",
                            "IataCode": "LNL",
                            "Airports": [
                                {
                                    "Id": "LNL",
                                    "Name": "Cheng Xian",
                                    "CityId": "LNLC",
                                    "CountryId": "CN",
                                    "Location": "105.797, 33.788"
                                }
                            ]
                        },
                        {
                            "Id": "CLIA",
                            "Name": "Liangping",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.81667, 30.683333",
                            "IataCode": "LIA",
                            "Airports": [
                                {
                                    "Id": "LIA",
                                    "Name": "Liangping",
                                    "CityId": "CLIA",
                                    "CountryId": "CN",
                                    "Location": "107.7, 30.816667"
                                }
                            ]
                        },
                        {
                            "Id": "GXHE",
                            "Name": "Xiahe",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.51, 35.197222",
                            "IataCode": "GXH",
                            "Airports": [
                                {
                                    "Id": "GXH",
                                    "Name": "Gannan Xiahe",
                                    "CityId": "GXHE",
                                    "CountryId": "CN",
                                    "Location": "102.6447, 34.8106"
                                }
                            ]
                        },
                        {
                            "Id": "WNHA",
                            "Name": "Wenshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "104.24972, 23.371944",
                            "IataCode": "WNH",
                            "Airports": [
                                {
                                    "Id": "WNH",
                                    "Name": "Wenshan Puzhehei",
                                    "CityId": "WNHA",
                                    "CountryId": "CN",
                                    "Location": "104.325, 23.5583"
                                }
                            ]
                        },
                        {
                            "Id": "JICA",
                            "Name": "Jinchang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.17389, 38.495278",
                            "IataCode": "JIC",
                            "Airports": [
                                {
                                    "Id": "JIC",
                                    "Name": "Jinchuan",
                                    "CityId": "JICA",
                                    "CountryId": "CN",
                                    "Location": "102.34778, 38.541944"
                                }
                            ]
                        },
                        {
                            "Id": "CZBZ",
                            "Name": "Xin Hui",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.04028, 22.517778",
                            "IataCode": "ZBZ",
                            "Airports": [
                                {
                                    "Id": "ZBZ",
                                    "Name": "Xin Hui",
                                    "CityId": "CZBZ",
                                    "CountryId": "CN",
                                    "Location": "113.03333, 22.53333"
                                }
                            ]
                        },
                        {
                            "Id": "HJJA",
                            "Name": "Huai Hua",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.95917, 27.549444",
                            "IataCode": "HJJ",
                            "Airports": [
                                {
                                    "Id": "HJJ",
                                    "Name": "Huai Hua",
                                    "CityId": "HJJA",
                                    "CountryId": "CN",
                                    "Location": "109.7002, 27.43738"
                                }
                            ]
                        },
                        {
                            "Id": "HACN",
                            "Name": "Huaian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.14417, 33.503889",
                            "IataCode": "HIA",
                            "Airports": [
                                {
                                    "Id": "HIA",
                                    "Name": "Huai'an Lianshui Airport",
                                    "CityId": "HACN",
                                    "CountryId": "CN",
                                    "Location": "119.12778, 33.7875"
                                }
                            ]
                        },
                        {
                            "Id": "HCJA",
                            "Name": "Hechi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "108.03333, 24.7",
                            "IataCode": "HCJ",
                            "Airports": [
                                {
                                    "Id": "HCJ",
                                    "Name": "Jin Cheng Jiang",
                                    "CityId": "HCJA",
                                    "CountryId": "CN",
                                    "Location": "107.69972, 24.783889"
                                }
                            ]
                        },
                        {
                            "Id": "HDGA",
                            "Name": "Handan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.46778, 36.600558",
                            "IataCode": "HDG",
                            "Airports": [
                                {
                                    "Id": "HDG",
                                    "Name": "Hebei Handan",
                                    "CityId": "HDGA",
                                    "CountryId": "CN",
                                    "Location": "114.425, 36.5258"
                                }
                            ]
                        },
                        {
                            "Id": "GYUA",
                            "Name": "Guyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.28083, 36.006667",
                            "IataCode": "GYU",
                            "Airports": [
                                {
                                    "Id": "GYU",
                                    "Name": "Liupanshan",
                                    "CityId": "GYUA",
                                    "CountryId": "CN",
                                    "Location": "106.21806, 36.076111"
                                }
                            ]
                        },
                        {
                            "Id": "GZGA",
                            "Name": "Garzê",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.01667, 31.633333",
                            "IataCode": "GZG",
                            "Airports": [
                                {
                                    "Id": "GZG",
                                    "Name": "Garze Gesar Airport",
                                    "CityId": "GZGA",
                                    "CountryId": "CN",
                                    "Location": "99.754167, 31.7575"
                                }
                            ]
                        },
                        {
                            "Id": "RHTA",
                            "Name": "Alxa Right Banner",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "101.66667, 39.2",
                            "IataCode": "RHT",
                            "Airports": [
                                {
                                    "Id": "RHT",
                                    "Name": "Alxa Right Banner",
                                    "CityId": "RHTA",
                                    "CountryId": "CN",
                                    "Location": "101.3839, 39.12448"
                                }
                            ]
                        },
                        {
                            "Id": "CPNJ",
                            "Name": "Peng Lai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "120.75083, 37.808611",
                            "IataCode": "PNJ",
                            "Airports": [
                                {
                                    "Id": "PNJ",
                                    "Name": "Peng Lai",
                                    "CityId": "CPNJ",
                                    "CountryId": "CN",
                                    "Location": "120.81278, 37.808889"
                                }
                            ]
                        },
                        {
                            "Id": "HZHA",
                            "Name": "Liping City",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "109.13139, 26.231111",
                            "IataCode": "HZH",
                            "Airports": [
                                {
                                    "Id": "HZH",
                                    "Name": "Liping City",
                                    "CityId": "HZHA",
                                    "CountryId": "CN",
                                    "Location": "109.15267, 26.321274"
                                }
                            ]
                        },
                        {
                            "Id": "JUHI",
                            "Name": "Chizhou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.48306, 30.657779",
                            "IataCode": "JUH",
                            "Airports": [
                                {
                                    "Id": "JUH",
                                    "Name": "Chizhou Jiuhuashan",
                                    "CityId": "JUHI",
                                    "CountryId": "CN",
                                    "Location": "117.68667, 30.740278"
                                }
                            ]
                        },
                        {
                            "Id": "AEBA",
                            "Name": "Baise",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.61333, 23.899722",
                            "IataCode": "AEB",
                            "Airports": [
                                {
                                    "Id": "AEB",
                                    "Name": "Baise",
                                    "CityId": "AEBA",
                                    "CountryId": "CN",
                                    "Location": "106.9506, 23.7172"
                                }
                            ]
                        },
                        {
                            "Id": "BFJF",
                            "Name": "Bijie",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.29417, 27.3125",
                            "IataCode": "BFJ",
                            "Airports": [
                                {
                                    "Id": "BFJ",
                                    "Name": "Bijie Feixiong",
                                    "CityId": "BFJF",
                                    "CountryId": "CN",
                                    "Location": "105.30139, 27.300278"
                                }
                            ]
                        },
                        {
                            "Id": "CAXF",
                            "Name": "Alxa Left Banner",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "105.66861, 38.838611",
                            "IataCode": "AXF",
                            "Airports": [
                                {
                                    "Id": "AXF",
                                    "Name": "Alxa Left Banner Bayanhot Airport",
                                    "CityId": "CAXF",
                                    "CountryId": "CN",
                                    "Location": "105.3751, 38.4924"
                                }
                            ]
                        },
                        {
                            "Id": "WGNC",
                            "Name": "Shaoyang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.47389, 27.244167",
                            "IataCode": "WGN",
                            "Airports": [
                                {
                                    "Id": "WGN",
                                    "Name": "Wugang",
                                    "CityId": "WGNC",
                                    "CountryId": "CN",
                                    "Location": "111.66876, 27.223554"
                                }
                            ]
                        },
                        {
                            "Id": "HBQC",
                            "Name": "Qilian",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.24028, 38.180556",
                            "IataCode": "HBQ",
                            "Airports": [
                                {
                                    "Id": "HBQ",
                                    "Name": "Haibei",
                                    "CityId": "HBQC",
                                    "CountryId": "CN",
                                    "Location": "100.644, 38.012"
                                }
                            ]
                        },
                        {
                            "Id": "CZEF",
                            "Name": "Nanhai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.14278, 23.021925",
                            "IataCode": "ZEF",
                            "Airports": [
                                {
                                    "Id": "ZEF",
                                    "Name": "Nanhai",
                                    "CityId": "CZEF",
                                    "CountryId": "CN",
                                    "Location": "113.15, 23.03333"
                                }
                            ]
                        },
                        {
                            "Id": "ZHAN",
                            "Name": "Zhangjiakou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.87944, 40.810003",
                            "IataCode": "ZQZ",
                            "Airports": [
                                {
                                    "Id": "ZQZ",
                                    "Name": "Zhangjiakou",
                                    "CityId": "ZHAN",
                                    "CountryId": "CN",
                                    "Location": "114.93028, 40.738611"
                                }
                            ]
                        },
                        {
                            "Id": "NZLA",
                            "Name": "Zhalantun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.71667, 48.000002",
                            "IataCode": "NZL",
                            "Airports": [
                                {
                                    "Id": "NZL",
                                    "Name": "Chengjisihan",
                                    "CityId": "NZLA",
                                    "CountryId": "CN",
                                    "Location": "122.833, 47.746"
                                }
                            ]
                        },
                        {
                            "Id": "YKHA",
                            "Name": "Yingkou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.22472, 40.668058",
                            "IataCode": "YKH",
                            "Airports": [
                                {
                                    "Id": "YKH",
                                    "Name": "Yingkou",
                                    "CityId": "YKHA",
                                    "CountryId": "CN",
                                    "Location": "122.35833, 40.542222"
                                }
                            ]
                        },
                        {
                            "Id": "YICH",
                            "Name": "Yichun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "128.9, 47.7",
                            "IataCode": "LDS",
                            "Airports": [
                                {
                                    "Id": "LDS",
                                    "Name": "Yichun Lindu",
                                    "CityId": "YICH",
                                    "CountryId": "CN",
                                    "Location": "129.01799, 47.751827"
                                }
                            ]
                        },
                        {
                            "Id": "DTUC",
                            "Name": "Wudalianchi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "126.16667, 48.75",
                            "IataCode": "DTU",
                            "Airports": [
                                {
                                    "Id": "DTU",
                                    "Name": "Dedu",
                                    "CityId": "DTUC",
                                    "CountryId": "CN",
                                    "Location": "126.133, 48.445"
                                }
                            ]
                        },
                        {
                            "Id": "CHLH",
                            "Name": "Ulanhot",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.08333, 46.083333",
                            "IataCode": "HLH",
                            "Airports": [
                                {
                                    "Id": "HLH",
                                    "Name": "Ulanhot",
                                    "CityId": "CHLH",
                                    "CountryId": "CN",
                                    "Location": "122, 46.033333"
                                }
                            ]
                        },
                        {
                            "Id": "CSHF",
                            "Name": "Shanhaiguan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.74889, 40.0025",
                            "IataCode": "SHF",
                            "Airports": [
                                {
                                    "Id": "SHF",
                                    "Name": "Shanhaiguan",
                                    "CityId": "CSHF",
                                    "CountryId": "CN",
                                    "Location": "119.73333, 40.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MOHE",
                            "Name": "Mohe",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.28333, 53.466667",
                            "IataCode": "OHE",
                            "Airports": [
                                {
                                    "Id": "OHE",
                                    "Name": "Mohe",
                                    "CityId": "MOHE",
                                    "CountryId": "CN",
                                    "Location": "122.42059, 52.92113"
                                }
                            ]
                        },
                        {
                            "Id": "CLXI",
                            "Name": "Linxi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "118.03333, 43.516667",
                            "IataCode": "LXI",
                            "Airports": [
                                {
                                    "Id": "LXI",
                                    "Name": "Linxi",
                                    "CityId": "CLXI",
                                    "CountryId": "CN",
                                    "Location": "118.03333, 43.6"
                                }
                            ]
                        },
                        {
                            "Id": "JIXI",
                            "Name": "Jixi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "130.96667, 45.3",
                            "IataCode": "JXA",
                            "Airports": [
                                {
                                    "Id": "JXA",
                                    "Name": "Jixi Khanka Lake",
                                    "CityId": "JIXI",
                                    "CountryId": "CN",
                                    "Location": "130.99666, 45.306111"
                                }
                            ]
                        },
                        {
                            "Id": "CJGD",
                            "Name": "Jiagedaqi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "124.11667, 50.416669",
                            "IataCode": "JGD",
                            "Airports": [
                                {
                                    "Id": "JGD",
                                    "Name": "Jiagedaqi Airport",
                                    "CityId": "CJGD",
                                    "CountryId": "CN",
                                    "Location": "124.0412, 50.2512"
                                }
                            ]
                        },
                        {
                            "Id": "YSQC",
                            "Name": "Songyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "124.86667, 44.683333",
                            "IataCode": "YSQ",
                            "Airports": [
                                {
                                    "Id": "YSQ",
                                    "Name": "Chaganhu",
                                    "CityId": "YSQC",
                                    "CountryId": "CN",
                                    "Location": "124.55018, 44.938114"
                                }
                            ]
                        },
                        {
                            "Id": "CFYJ",
                            "Name": "Fuyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "134.28917, 48.363056",
                            "IataCode": "FYJ",
                            "Airports": [
                                {
                                    "Id": "FYJ",
                                    "Name": "Fuyuan Airport",
                                    "CityId": "CFYJ",
                                    "CountryId": "CN",
                                    "Location": "134.36645, 48.199494"
                                }
                            ]
                        },
                        {
                            "Id": "ERNH",
                            "Name": "Erenhot",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.97667, 43.6475",
                            "IataCode": "ERL",
                            "Airports": [
                                {
                                    "Id": "ERL",
                                    "Name": "Erenhot",
                                    "CityId": "ERNH",
                                    "CountryId": "CN",
                                    "Location": "112.09845, 43.426674"
                                }
                            ]
                        },
                        {
                            "Id": "EJNA",
                            "Name": "Ejina Banner",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "101.06389, 41.965278",
                            "IataCode": "EJN",
                            "Airports": [
                                {
                                    "Id": "EJN",
                                    "Name": "Taolai",
                                    "CityId": "EJNA",
                                    "CountryId": "CN",
                                    "Location": "101.00056, 42.015556"
                                }
                            ]
                        },
                        {
                            "Id": "DBCA",
                            "Name": "Baicheng",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "122.81667, 45.616667",
                            "IataCode": "DBC",
                            "Airports": [
                                {
                                    "Id": "DBC",
                                    "Name": "Chang'an",
                                    "CityId": "DBCA",
                                    "CountryId": "CN",
                                    "Location": "123.01972, 45.505278"
                                }
                            ]
                        },
                        {
                            "Id": "NBSA",
                            "Name": "Baishan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "126.42861, 41.943056",
                            "IataCode": "NBS",
                            "Airports": [
                                {
                                    "Id": "NBS",
                                    "Name": "Changbaishan",
                                    "CityId": "NBSA",
                                    "CountryId": "CN",
                                    "Location": "127.56214, 42.047916"
                                }
                            ]
                        },
                        {
                            "Id": "ARXN",
                            "Name": "Arxan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.96667, 47.183333",
                            "IataCode": "YIE",
                            "Airports": [
                                {
                                    "Id": "YIE",
                                    "Name": "Arxan Airport",
                                    "CityId": "ARXN",
                                    "CountryId": "CN",
                                    "Location": "119.944, 47.1777"
                                }
                            ]
                        },
                        {
                            "Id": "HUOC",
                            "Name": "Huolinguole",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.64917, 45.533611",
                            "IataCode": "HUO",
                            "Airports": [
                                {
                                    "Id": "HUO",
                                    "Name": "Huolinhe",
                                    "CityId": "HUOC",
                                    "CountryId": "CN",
                                    "Location": "119.40722, 45.487222"
                                }
                            ]
                        },
                        {
                            "Id": "CGHN",
                            "Name": "Guanghan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "106.56233, 30.47904",
                            "IataCode": "GHN",
                            "Airports": [
                                {
                                    "Id": "GHN",
                                    "Name": "Guanghan",
                                    "CityId": "CGHN",
                                    "CountryId": "CN",
                                    "Location": "106.65, 30.466667"
                                }
                            ]
                        },
                        {
                            "Id": "AHJA",
                            "Name": "Aba/Hongyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.42371, 32.746771",
                            "IataCode": "AHJ",
                            "Airports": [
                                {
                                    "Id": "AHJ",
                                    "Name": "Aba/Hongyuan",
                                    "CityId": "AHJA",
                                    "CountryId": "CN",
                                    "Location": "102.35694, 32.529167"
                                }
                            ]
                        },
                        {
                            "Id": "CJZH",
                            "Name": "Song Pan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "103.5247, 32.92294",
                            "IataCode": "JZH",
                            "Airports": [
                                {
                                    "Id": "JZH",
                                    "Name": "Song Pan",
                                    "CityId": "CJZH",
                                    "CountryId": "CN",
                                    "Location": "103.68306, 32.856667"
                                }
                            ]
                        },
                        {
                            "Id": "CDIG",
                            "Name": "Diqing",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "99.71, 27.8245",
                            "IataCode": "DIG",
                            "Airports": [
                                {
                                    "Id": "DIG",
                                    "Name": "Diqing",
                                    "CityId": "CDIG",
                                    "CountryId": "CN",
                                    "Location": "99.677778, 27.789722"
                                }
                            ]
                        },
                        {
                            "Id": "UCBA",
                            "Name": "Ulanqab",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.12646, 40.94671",
                            "IataCode": "UCB",
                            "Airports": [
                                {
                                    "Id": "UCB",
                                    "Name": "Ulanqab",
                                    "CityId": "UCBA",
                                    "CountryId": "CN",
                                    "Location": "113.10639, 41.13"
                                }
                            ]
                        },
                        {
                            "Id": "CLLV",
                            "Name": "Lvliang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "111.0666, 37.316601",
                            "IataCode": "LLV",
                            "Airports": [
                                {
                                    "Id": "LLV",
                                    "Name": "Lvliang Airport",
                                    "CityId": "CLLV",
                                    "CountryId": "CN",
                                    "Location": "111.0834, 37.41"
                                }
                            ]
                        },
                        {
                            "Id": "BZXC",
                            "Name": "Bazhong",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.2436, 31.91574",
                            "IataCode": "BZX",
                            "Airports": [
                                {
                                    "Id": "BZX",
                                    "Name": "Enyang",
                                    "CityId": "BZXC",
                                    "CountryId": "CN",
                                    "Location": "106.645, 31.738"
                                }
                            ]
                        },
                        {
                            "Id": "TCZA",
                            "Name": "Heshun",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "98.489167, 25.025",
                            "IataCode": "TCZ",
                            "Airports": [
                                {
                                    "Id": "TCZ",
                                    "Name": "Tengchong",
                                    "CityId": "TCZA",
                                    "CountryId": "CN",
                                    "Location": "98.489167, 25.025"
                                }
                            ]
                        },
                        {
                            "Id": "LZYA",
                            "Name": "Bayi Zhen",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "94.3353, 29.3033",
                            "IataCode": "LZY",
                            "Airports": [
                                {
                                    "Id": "LZY",
                                    "Name": "Lin Zhi",
                                    "CityId": "LZYA",
                                    "CountryId": "CN",
                                    "Location": "94.3353, 29.3033"
                                }
                            ]
                        },
                        {
                            "Id": "QSZA",
                            "Name": "Shache",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "77.240555, 38.416667",
                            "IataCode": "QSZ",
                            "Airports": [
                                {
                                    "Id": "QSZ",
                                    "Name": "Shache",
                                    "CityId": "QSZA",
                                    "CountryId": "CN",
                                    "Location": "77.24056, 38.41667"
                                }
                            ]
                        },
                        {
                            "Id": "JMJA",
                            "Name": "Mengbin",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "99.9, 22.516667",
                            "IataCode": "JMJ",
                            "Airports": [
                                {
                                    "Id": "JMJ",
                                    "Name": "Jingmai",
                                    "CityId": "JMJA",
                                    "CountryId": "CN",
                                    "Location": "99.786389, 22.415833"
                                }
                            ]
                        },
                        {
                            "Id": "BTON",
                            "Name": "Machang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "97.037025, 32.83641",
                            "IataCode": "YUS",
                            "Airports": [
                                {
                                    "Id": "YUS",
                                    "Name": "Ba Tong",
                                    "CityId": "BTON",
                                    "CountryId": "CN",
                                    "Location": "97.037025, 32.83641"
                                }
                            ]
                        },
                        {
                            "Id": "TWCC",
                            "Name": "Aktam",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "79.081667, 39.948056",
                            "IataCode": "TWC",
                            "Airports": [
                                {
                                    "Id": "TWC",
                                    "Name": "Tangwangcheng",
                                    "CityId": "TWCC",
                                    "CountryId": "CN",
                                    "Location": "78.99, 39.880556"
                                }
                            ]
                        },
                        {
                            "Id": "TLQA",
                            "Name": "Ewirgol",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "87.661111, 42.964444",
                            "IataCode": "TLQ",
                            "Airports": [
                                {
                                    "Id": "TLQ",
                                    "Name": "Jiaohe",
                                    "CityId": "TLQA",
                                    "CountryId": "CN",
                                    "Location": "89.100556, 43.030556"
                                }
                            ]
                        },
                        {
                            "Id": "BURE",
                            "Name": "Qindel",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "82.000556, 44.92",
                            "IataCode": "BVV",
                            "Airports": [
                                {
                                    "Id": "BVV",
                                    "Name": "Burevestnik",
                                    "CityId": "BURE",
                                    "CountryId": "CN",
                                    "Location": "147.62167, 44.92"
                                }
                            ]
                        },
                        {
                            "Id": "CYUA",
                            "Name": "Yindi",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "101.83333, 25.666667",
                            "IataCode": "YUA",
                            "Airports": [
                                {
                                    "Id": "YUA",
                                    "Name": "Yuanmou",
                                    "CityId": "CYUA",
                                    "CountryId": "CN",
                                    "Location": "101.91667, 25.65"
                                }
                            ]
                        },
                        {
                            "Id": "ZHOU",
                            "Name": "Lianhe",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "108.82918, 29.517557",
                            "IataCode": "JIQ",
                            "Airports": [
                                {
                                    "Id": "JIQ",
                                    "Name": "Zhoubai Airport",
                                    "CityId": "ZHOU",
                                    "CountryId": "CN",
                                    "Location": "108.82918, 29.517557"
                                }
                            ]
                        },
                        {
                            "Id": "CDCY",
                            "Name": "Kanggar",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "100.31667, 28.6",
                            "IataCode": "DCY",
                            "Airports": [
                                {
                                    "Id": "DCY",
                                    "Name": "Daocheng Yading",
                                    "CityId": "CDCY",
                                    "CountryId": "CN",
                                    "Location": "100.0312, 29.1923"
                                }
                            ]
                        },
                        {
                            "Id": "CZCA",
                            "Name": "Hongjialou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "117.05, 36.683333",
                            "IataCode": "ZCA",
                            "Airports": [
                                {
                                    "Id": "ZCA",
                                    "Name": "Tai Shan",
                                    "CityId": "CZCA",
                                    "CountryId": "CN",
                                    "Location": "117.06139, 36.828249"
                                }
                            ]
                        },
                        {
                            "Id": "CSHP",
                            "Name": "Guitizhai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "119.51667, 39.9",
                            "IataCode": "SHP",
                            "Airports": [
                                {
                                    "Id": "SHP",
                                    "Name": "Qinhuangdao",
                                    "CityId": "CSHP",
                                    "CountryId": "CN",
                                    "Location": "119.731, 39.9681"
                                }
                            ]
                        },
                        {
                            "Id": "CZCU",
                            "Name": "Pak Nai",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.95, 22.45",
                            "IataCode": "ZCU",
                            "Airports": [
                                {
                                    "Id": "ZCU",
                                    "Name": "She Kou",
                                    "CityId": "CZCU",
                                    "CountryId": "CN",
                                    "Location": "113.88333, 22.48333"
                                }
                            ]
                        },
                        {
                            "Id": "CSZO",
                            "Name": "Huanglübei",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "113.3866, 22.063347",
                            "IataCode": "SZO",
                            "Airports": [
                                {
                                    "Id": "SZO",
                                    "Name": "Shanzhou",
                                    "CityId": "CSZO",
                                    "CountryId": "CN",
                                    "Location": "113.38333, 22.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CKNC",
                            "Name": "Yangjiazhuang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "114.99944, 27.098333",
                            "IataCode": "KNC",
                            "Airports": [
                                {
                                    "Id": "KNC",
                                    "Name": "Ji'An",
                                    "CityId": "CKNC",
                                    "CountryId": "CN",
                                    "Location": "114.98333, 27.1"
                                }
                            ]
                        },
                        {
                            "Id": "JSJA",
                            "Name": "Erlongshan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "132.50694, 47.324722",
                            "IataCode": "JSJ",
                            "Airports": [
                                {
                                    "Id": "JSJ",
                                    "Name": "Jiansanjiang",
                                    "CityId": "JSJA",
                                    "CountryId": "CN",
                                    "Location": "132.6239, 47.2065"
                                }
                            ]
                        },
                        {
                            "Id": "CRLK",
                            "Name": "Dukou",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "107.1, 40.4",
                            "IataCode": "RLK",
                            "Airports": [
                                {
                                    "Id": "RLK",
                                    "Name": "Bayannur Tianjitai Airport",
                                    "CityId": "CRLK",
                                    "CountryId": "CN",
                                    "Location": "107.442, 40.5535"
                                }
                            ]
                        },
                        {
                            "Id": "CSHS",
                            "Name": "Fuliyuan",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "112.24289, 30.33072",
                            "IataCode": "SHS",
                            "Airports": [
                                {
                                    "Id": "SHS",
                                    "Name": "Shashi",
                                    "CityId": "CSHS",
                                    "CountryId": "CN",
                                    "Location": "112.23333, 30.316667"
                                }
                            ]
                        },
                        {
                            "Id": "CWJA",
                            "Name": "Long No",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "99.283333, 23.116667",
                            "IataCode": "CWJ",
                            "Airports": [
                                {
                                    "Id": "CWJ",
                                    "Name": "Washan",
                                    "CityId": "CWJA",
                                    "CountryId": "CN",
                                    "Location": "99.373611, 23.273889"
                                }
                            ]
                        },
                        {
                            "Id": "CPZI",
                            "Name": "Siêu Xin Thang",
                            "SingleAirportCity": true,
                            "CountryId": "CN",
                            "Location": "102.96667, 22.016667",
                            "IataCode": "PZI",
                            "Airports": [
                                {
                                    "Id": "PZI",
                                    "Name": "Pan Zhi Hua",
                                    "CityId": "CPZI",
                                    "CountryId": "CN",
                                    "Location": "101.7944, 26.53817"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KG",
                    "Name": "Kyrgyzstan",
                    "CurrencyId": "KGS",
                    "Cities": [
                        {
                            "Id": "FRUA",
                            "Name": "Bishkek",
                            "SingleAirportCity": true,
                            "CountryId": "KG",
                            "Location": "74.600278, 42.873056",
                            "IataCode": "FRU",
                            "Airports": [
                                {
                                    "Id": "FRU",
                                    "Name": "Bishkek",
                                    "CityId": "FRUA",
                                    "CountryId": "KG",
                                    "Location": "74.4775, 43.061389"
                                }
                            ]
                        },
                        {
                            "Id": "OSSA",
                            "Name": "Osh",
                            "SingleAirportCity": true,
                            "CountryId": "KG",
                            "Location": "72.79, 40.529444",
                            "IataCode": "OSS",
                            "Airports": [
                                {
                                    "Id": "OSS",
                                    "Name": "Osh",
                                    "CityId": "OSSA",
                                    "CountryId": "KG",
                                    "Location": "72.816667, 40.616667"
                                }
                            ]
                        },
                        {
                            "Id": "IKUA",
                            "Name": "Tamchy",
                            "SingleAirportCity": true,
                            "CountryId": "KG",
                            "Location": "76.656389, 42.561389",
                            "IataCode": "IKU",
                            "Airports": [
                                {
                                    "Id": "IKU",
                                    "Name": "Issyk-Kul",
                                    "CityId": "IKUA",
                                    "CountryId": "KG",
                                    "Location": "76.4248, 42.3518"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TJ",
                    "Name": "Tajikistan",
                    "CurrencyId": "TJS",
                    "Cities": [
                        {
                            "Id": "DYUA",
                            "Name": "Dushanbe",
                            "SingleAirportCity": true,
                            "CountryId": "TJ",
                            "Location": "68.773889, 38.560002",
                            "IataCode": "DYU",
                            "Airports": [
                                {
                                    "Id": "DYU",
                                    "Name": "Dushanbe",
                                    "CityId": "DYUA",
                                    "CountryId": "TJ",
                                    "Location": "68.823611, 38.55"
                                }
                            ]
                        },
                        {
                            "Id": "LBDA",
                            "Name": "Khudzhand",
                            "SingleAirportCity": true,
                            "CountryId": "TJ",
                            "Location": "69.631666, 40.28",
                            "IataCode": "LBD",
                            "Airports": [
                                {
                                    "Id": "LBD",
                                    "Name": "Khudzhand",
                                    "CityId": "LBDA",
                                    "CountryId": "TJ",
                                    "Location": "69.7, 40.216667"
                                }
                            ]
                        },
                        {
                            "Id": "KRGN",
                            "Name": "Kurgon-Tyube",
                            "SingleAirportCity": true,
                            "CountryId": "TJ",
                            "Location": "68.780278, 37.836389",
                            "IataCode": "KQT",
                            "Airports": [
                                {
                                    "Id": "KQT",
                                    "Name": "Kurgon-Tyube",
                                    "CityId": "KRGN",
                                    "CountryId": "TJ",
                                    "Location": "68.86117, 37.85961"
                                }
                            ]
                        },
                        {
                            "Id": "TJUA",
                            "Name": "Kulyab",
                            "SingleAirportCity": true,
                            "CountryId": "TJ",
                            "Location": "69.781944, 37.909167",
                            "IataCode": "TJU",
                            "Airports": [
                                {
                                    "Id": "TJU",
                                    "Name": "Kulyab",
                                    "CityId": "TJUA",
                                    "CountryId": "TJ",
                                    "Location": "69.7833, 37.9167"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BT",
                    "Name": "Bhutan",
                    "CurrencyId": "BTN",
                    "Cities": [
                        {
                            "Id": "PBHA",
                            "Name": "Paro",
                            "SingleAirportCity": true,
                            "CountryId": "BT",
                            "Location": "89.416667, 27.433333",
                            "IataCode": "PBH",
                            "Airports": [
                                {
                                    "Id": "PBH",
                                    "Name": "Paro",
                                    "CityId": "PBHA",
                                    "CountryId": "BT",
                                    "Location": "89.416667, 27.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YONA",
                            "Name": "Trashigang",
                            "SingleAirportCity": true,
                            "CountryId": "BT",
                            "Location": "91.566667, 27.316667",
                            "IataCode": "YON",
                            "Airports": [
                                {
                                    "Id": "YON",
                                    "Name": "Trashigang",
                                    "CityId": "YONA",
                                    "CountryId": "BT",
                                    "Location": "91.514444, 27.256389"
                                }
                            ]
                        },
                        {
                            "Id": "GLUA",
                            "Name": "Gelephu",
                            "SingleAirportCity": true,
                            "CountryId": "BT",
                            "Location": "90.5, 26.85",
                            "IataCode": "GLU",
                            "Airports": [
                                {
                                    "Id": "GLU",
                                    "Name": "Gelephu",
                                    "CityId": "GLUA",
                                    "CountryId": "BT",
                                    "Location": "90.463889, 26.884444"
                                }
                            ]
                        },
                        {
                            "Id": "BUTB",
                            "Name": "Jakar",
                            "SingleAirportCity": true,
                            "CountryId": "BT",
                            "Location": "90.733333, 27.55",
                            "IataCode": "BUT",
                            "Airports": [
                                {
                                    "Id": "BUT",
                                    "Name": "Bathpalathang",
                                    "CityId": "BUTB",
                                    "CountryId": "BT",
                                    "Location": "90.746389, 27.562778"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TM",
                    "Name": "Turkmenistan",
                    "CurrencyId": "TMT",
                    "Cities": [
                        {
                            "Id": "KRWA",
                            "Name": "Turkmenbashi",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "52.969722, 40.023056",
                            "IataCode": "KRW",
                            "Airports": [
                                {
                                    "Id": "KRW",
                                    "Name": "Turkmenbashi",
                                    "CityId": "KRWA",
                                    "CountryId": "TM",
                                    "Location": "53.083333, 40.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CRZA",
                            "Name": "Turkmenabad",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "63.575, 39.101389",
                            "IataCode": "CRZ",
                            "Airports": [
                                {
                                    "Id": "CRZ",
                                    "Name": "Turkmenabad",
                                    "CityId": "CRZA",
                                    "CountryId": "TM",
                                    "Location": "63.616667, 39.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MYPA",
                            "Name": "Mary",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "61.833334, 37.6",
                            "IataCode": "MYP",
                            "Airports": [
                                {
                                    "Id": "MYP",
                                    "Name": "Mary",
                                    "CityId": "MYPA",
                                    "CountryId": "TM",
                                    "Location": "61.833333, 37.666667"
                                }
                            ]
                        },
                        {
                            "Id": "TAZA",
                            "Name": "Dashoguz",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "59.966667, 41.833333",
                            "IataCode": "TAZ",
                            "Airports": [
                                {
                                    "Id": "TAZ",
                                    "Name": "Dashoguz",
                                    "CityId": "TAZA",
                                    "CountryId": "TM",
                                    "Location": "59.966667, 41.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ASBA",
                            "Name": "Ashgabat",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "58.383333, 37.95",
                            "IataCode": "ASB",
                            "Airports": [
                                {
                                    "Id": "ASB",
                                    "Name": "Ashgabat",
                                    "CityId": "ASBA",
                                    "CountryId": "TM",
                                    "Location": "58.360833, 37.981667"
                                }
                            ]
                        },
                        {
                            "Id": "BKNB",
                            "Name": "Balkanabat",
                            "SingleAirportCity": true,
                            "CountryId": "TM",
                            "Location": "54.365, 39.511944",
                            "IataCode": "BKN",
                            "Airports": [
                                {
                                    "Id": "BKN",
                                    "Name": "Balkanabat",
                                    "CityId": "BKNB",
                                    "CountryId": "TM",
                                    "Location": "54.362778, 39.481389"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IN",
                    "Name": "India",
                    "CurrencyId": "INR",
                    "Cities": [
                        {
                            "Id": "IAGR",
                            "Name": "Agra",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.016668, 27.183335",
                            "IataCode": "AGR",
                            "Airports": [
                                {
                                    "Id": "AGR",
                                    "Name": "Agra",
                                    "CityId": "IAGR",
                                    "CountryId": "IN",
                                    "Location": "77.962778, 27.158333"
                                }
                            ]
                        },
                        {
                            "Id": "IAJL",
                            "Name": "Aizawl",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.716667, 23.733333",
                            "IataCode": "AJL",
                            "Airports": [
                                {
                                    "Id": "AJL",
                                    "Name": "Aizawl",
                                    "CityId": "IAJL",
                                    "CountryId": "IN",
                                    "Location": "92.75, 23.75"
                                }
                            ]
                        },
                        {
                            "Id": "IAKD",
                            "Name": "Akola",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77, 20.733333",
                            "IataCode": "AKD",
                            "Airports": [
                                {
                                    "Id": "AKD",
                                    "Name": "Akola",
                                    "CityId": "IAKD",
                                    "CountryId": "IN",
                                    "Location": "77.083333, 20.666667"
                                }
                            ]
                        },
                        {
                            "Id": "IAMD",
                            "Name": "Ahmedabad",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.616669, 23.033338",
                            "IataCode": "AMD",
                            "Airports": [
                                {
                                    "Id": "AMD",
                                    "Name": "Ahmedabad",
                                    "CityId": "IAMD",
                                    "CountryId": "IN",
                                    "Location": "72.624167, 23.066389"
                                }
                            ]
                        },
                        {
                            "Id": "IZER",
                            "Name": "Zero",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "93.833333, 27.633333",
                            "IataCode": "ZER",
                            "Airports": [
                                {
                                    "Id": "ZER",
                                    "Name": "Zero",
                                    "CityId": "IZER",
                                    "CountryId": "IN",
                                    "Location": "93.833333, 27.633333"
                                }
                            ]
                        },
                        {
                            "Id": "IKTU",
                            "Name": "Kota",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.833333, 25.183333",
                            "IataCode": "KTU",
                            "Airports": [
                                {
                                    "Id": "KTU",
                                    "Name": "Kota",
                                    "CityId": "IKTU",
                                    "CountryId": "IN",
                                    "Location": "76.5, 25"
                                }
                            ]
                        },
                        {
                            "Id": "IBUP",
                            "Name": "Bhatinda",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.95, 30.200001",
                            "IataCode": "BUP",
                            "Airports": [
                                {
                                    "Id": "BUP",
                                    "Name": "Bhatinda",
                                    "CityId": "IBUP",
                                    "CountryId": "IN",
                                    "Location": "74.966667, 30.166667"
                                }
                            ]
                        },
                        {
                            "Id": "IGUX",
                            "Name": "Guna",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.316667, 24.65",
                            "IataCode": "GUX",
                            "Airports": [
                                {
                                    "Id": "GUX",
                                    "Name": "Guna",
                                    "CityId": "IGUX",
                                    "CountryId": "IN",
                                    "Location": "77.316667, 24.666667"
                                }
                            ]
                        },
                        {
                            "Id": "IHSS",
                            "Name": "Hissar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.716667, 29.166668",
                            "IataCode": "HSS",
                            "Airports": [
                                {
                                    "Id": "HSS",
                                    "Name": "Hissar",
                                    "CityId": "IHSS",
                                    "CountryId": "IN",
                                    "Location": "75.8, 29.166667"
                                }
                            ]
                        },
                        {
                            "Id": "IIXN",
                            "Name": "Khowai",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "91.633333, 24.1",
                            "IataCode": "IXN",
                            "Airports": [
                                {
                                    "Id": "IXN",
                                    "Name": "Khowai",
                                    "CityId": "IIXN",
                                    "CountryId": "IN",
                                    "Location": "91.603333, 24.063889"
                                }
                            ]
                        },
                        {
                            "Id": "IIXQ",
                            "Name": "Kamalpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "91.833333, 24.2",
                            "IataCode": "IXQ",
                            "Airports": [
                                {
                                    "Id": "IXQ",
                                    "Name": "Kamalpur",
                                    "CityId": "IIXQ",
                                    "CountryId": "IN",
                                    "Location": "91.815556, 24.1325"
                                }
                            ]
                        },
                        {
                            "Id": "IJGB",
                            "Name": "Jagdalpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "82.033333, 19.066667",
                            "IataCode": "JGB",
                            "Airports": [
                                {
                                    "Id": "JGB",
                                    "Name": "Jagdalpur",
                                    "CityId": "IJGB",
                                    "CountryId": "IN",
                                    "Location": "82.033333, 19.066667"
                                }
                            ]
                        },
                        {
                            "Id": "IBDQ",
                            "Name": "Vadodara",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.200001, 22.300002",
                            "IataCode": "BDQ",
                            "Airports": [
                                {
                                    "Id": "BDQ",
                                    "Name": "Vadodara",
                                    "CityId": "IBDQ",
                                    "CountryId": "IN",
                                    "Location": "73.220833, 22.329167"
                                }
                            ]
                        },
                        {
                            "Id": "IBEP",
                            "Name": "Bellary",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.933333, 15.15",
                            "IataCode": "BEP",
                            "Airports": [
                                {
                                    "Id": "BEP",
                                    "Name": "Bellary",
                                    "CityId": "IBEP",
                                    "CountryId": "IN",
                                    "Location": "76.9, 15.183333"
                                }
                            ]
                        },
                        {
                            "Id": "IBHJ",
                            "Name": "Bhuj",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "69.666667, 23.266667",
                            "IataCode": "BHJ",
                            "Airports": [
                                {
                                    "Id": "BHJ",
                                    "Name": "Bhuj",
                                    "CityId": "IBHJ",
                                    "CountryId": "IN",
                                    "Location": "69.670556, 23.2875"
                                }
                            ]
                        },
                        {
                            "Id": "IBHO",
                            "Name": "Bhopal",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.399999, 23.266669",
                            "IataCode": "BHO",
                            "Airports": [
                                {
                                    "Id": "BHO",
                                    "Name": "Bhopal",
                                    "CityId": "IBHO",
                                    "CountryId": "IN",
                                    "Location": "77.338611, 23.284722"
                                }
                            ]
                        },
                        {
                            "Id": "IBHU",
                            "Name": "Bhavnagar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.150001, 21.766667",
                            "IataCode": "BHU",
                            "Airports": [
                                {
                                    "Id": "BHU",
                                    "Name": "Bhavnagar",
                                    "CityId": "IBHU",
                                    "CountryId": "IN",
                                    "Location": "72.184444, 21.755"
                                }
                            ]
                        },
                        {
                            "Id": "IBKB",
                            "Name": "Bikaner",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.3, 28.016667",
                            "IataCode": "BKB",
                            "Airports": [
                                {
                                    "Id": "BKB",
                                    "Name": "Bikaner",
                                    "CityId": "IBKB",
                                    "CountryId": "IN",
                                    "Location": "73.3, 28.016667"
                                }
                            ]
                        },
                        {
                            "Id": "IBLR",
                            "Name": "Bengaluru",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.603287, 12.976232",
                            "IataCode": "BLR",
                            "Airports": [
                                {
                                    "Id": "BLR",
                                    "Name": "Bengaluru",
                                    "CityId": "IBLR",
                                    "CountryId": "IN",
                                    "Location": "77.705269, 13.199588"
                                }
                            ]
                        },
                        {
                            "Id": "IBOM",
                            "Name": "Mumbai",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.87939, 19.151421",
                            "IataCode": "BOM",
                            "Airports": [
                                {
                                    "Id": "BOM",
                                    "Name": "Mumbai",
                                    "CityId": "IBOM",
                                    "CountryId": "IN",
                                    "Location": "72.851667, 19.088056"
                                }
                            ]
                        },
                        {
                            "Id": "ICCJ",
                            "Name": "Kozhikode",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.766667, 11.25",
                            "IataCode": "CCJ",
                            "Airports": [
                                {
                                    "Id": "CCJ",
                                    "Name": "Kozhikode",
                                    "CityId": "ICCJ",
                                    "CountryId": "IN",
                                    "Location": "75.95, 11.133333"
                                }
                            ]
                        },
                        {
                            "Id": "ICCU",
                            "Name": "Kolkata",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.36972, 22.56973",
                            "IataCode": "CCU",
                            "Airports": [
                                {
                                    "Id": "CCU",
                                    "Name": "Kolkata",
                                    "CityId": "ICCU",
                                    "CountryId": "IN",
                                    "Location": "88.449167, 22.6525"
                                }
                            ]
                        },
                        {
                            "Id": "ICDP",
                            "Name": "Kadapa",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.816667, 14.466667",
                            "IataCode": "CDP",
                            "Airports": [
                                {
                                    "Id": "CDP",
                                    "Name": "Kadapa",
                                    "CityId": "ICDP",
                                    "CountryId": "IN",
                                    "Location": "78.783333, 14.516667"
                                }
                            ]
                        },
                        {
                            "Id": "ICJB",
                            "Name": "Coimbatore",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.966666, 11",
                            "IataCode": "CJB",
                            "Airports": [
                                {
                                    "Id": "CJB",
                                    "Name": "Coimbatore",
                                    "CityId": "ICJB",
                                    "CountryId": "IN",
                                    "Location": "77.043333, 11.028333"
                                }
                            ]
                        },
                        {
                            "Id": "ICOH",
                            "Name": "Cooch Behar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "89.433333, 26.316667",
                            "IataCode": "COH",
                            "Airports": [
                                {
                                    "Id": "COH",
                                    "Name": "Cooch Behar",
                                    "CityId": "ICOH",
                                    "CountryId": "IN",
                                    "Location": "89.466667, 26.333333"
                                }
                            ]
                        },
                        {
                            "Id": "ICOK",
                            "Name": "Kochi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.233333, 9.9666667",
                            "IataCode": "COK",
                            "Airports": [
                                {
                                    "Id": "COK",
                                    "Name": "Kochi",
                                    "CityId": "ICOK",
                                    "CountryId": "IN",
                                    "Location": "76.271944, 9.944444"
                                }
                            ]
                        },
                        {
                            "Id": "ITEI",
                            "Name": "Tezu",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "96.166667, 27.916667",
                            "IataCode": "TEI",
                            "Airports": [
                                {
                                    "Id": "TEI",
                                    "Name": "Tezu",
                                    "CityId": "ITEI",
                                    "CountryId": "IN",
                                    "Location": "96.133333, 27.95"
                                }
                            ]
                        },
                        {
                            "Id": "IDBD",
                            "Name": "Dhanbad",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "86.45, 23.8",
                            "IataCode": "DBD",
                            "Airports": [
                                {
                                    "Id": "DBD",
                                    "Name": "Dhanbad",
                                    "CityId": "IDBD",
                                    "CountryId": "IN",
                                    "Location": "86.45, 23.783333"
                                }
                            ]
                        },
                        {
                            "Id": "IDEL",
                            "Name": "New Delhi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.2, 28.6",
                            "IataCode": "DEL",
                            "Airports": [
                                {
                                    "Id": "DEL",
                                    "Name": "New Delhi",
                                    "CityId": "IDEL",
                                    "CountryId": "IN",
                                    "Location": "77.100833, 28.573611"
                                }
                            ]
                        },
                        {
                            "Id": "IDHM",
                            "Name": "Dharamsala",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.316667, 32.216667",
                            "IataCode": "DHM",
                            "Airports": [
                                {
                                    "Id": "DHM",
                                    "Name": "Dharamsala",
                                    "CityId": "IDHM",
                                    "CountryId": "IN",
                                    "Location": "76.266667, 32.3"
                                }
                            ]
                        },
                        {
                            "Id": "IDIB",
                            "Name": "Dibrugarh",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.9, 27.483333",
                            "IataCode": "DIB",
                            "Airports": [
                                {
                                    "Id": "DIB",
                                    "Name": "Dibrugarh",
                                    "CityId": "IDIB",
                                    "CountryId": "IN",
                                    "Location": "95.020833, 27.483889"
                                }
                            ]
                        },
                        {
                            "Id": "IDIU",
                            "Name": "Diu",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.982237, 20.714052",
                            "IataCode": "DIU",
                            "Airports": [
                                {
                                    "Id": "DIU",
                                    "Name": "Diu",
                                    "CityId": "IDIU",
                                    "CountryId": "IN",
                                    "Location": "70.916667, 20.716667"
                                }
                            ]
                        },
                        {
                            "Id": "IDMU",
                            "Name": "Dimapur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "93.733333, 25.9",
                            "IataCode": "DMU",
                            "Airports": [
                                {
                                    "Id": "DMU",
                                    "Name": "Dimapur",
                                    "CityId": "IDMU",
                                    "CountryId": "IN",
                                    "Location": "93.773611, 25.883889"
                                }
                            ]
                        },
                        {
                            "Id": "IGAU",
                            "Name": "Guwahati",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "91.750946, 26.186174",
                            "IataCode": "GAU",
                            "Airports": [
                                {
                                    "Id": "GAU",
                                    "Name": "Guwahati",
                                    "CityId": "IGAU",
                                    "CountryId": "IN",
                                    "Location": "91.5875, 26.104167"
                                }
                            ]
                        },
                        {
                            "Id": "IGAY",
                            "Name": "Gaya",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85, 24.783333",
                            "IataCode": "GAY",
                            "Airports": [
                                {
                                    "Id": "GAY",
                                    "Name": "Gaya",
                                    "CityId": "IGAY",
                                    "CountryId": "IN",
                                    "Location": "84.945833, 24.746667"
                                }
                            ]
                        },
                        {
                            "Id": "IGOI",
                            "Name": "Marmagao",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.8, 15.4",
                            "IataCode": "GOI",
                            "Airports": [
                                {
                                    "Id": "GOI",
                                    "Name": "Goa",
                                    "CityId": "IGOI",
                                    "CountryId": "IN",
                                    "Location": "73.833333, 15.383333"
                                }
                            ]
                        },
                        {
                            "Id": "IGOP",
                            "Name": "Gorakhpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "83.373888, 26.755002",
                            "IataCode": "GOP",
                            "Airports": [
                                {
                                    "Id": "GOP",
                                    "Name": "Gorakhpur",
                                    "CityId": "IGOP",
                                    "CountryId": "IN",
                                    "Location": "83.44971, 26.73971"
                                }
                            ]
                        },
                        {
                            "Id": "IGWL",
                            "Name": "Gwalior",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.179167, 26.223611",
                            "IataCode": "GWL",
                            "Airports": [
                                {
                                    "Id": "GWL",
                                    "Name": "Gwalior",
                                    "CityId": "IGWL",
                                    "CountryId": "IN",
                                    "Location": "78.23, 26.293889"
                                }
                            ]
                        },
                        {
                            "Id": "IHBX",
                            "Name": "Hubli",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.166667, 15.35",
                            "IataCode": "HBX",
                            "Airports": [
                                {
                                    "Id": "HBX",
                                    "Name": "Hubli",
                                    "CityId": "IHBX",
                                    "CountryId": "IN",
                                    "Location": "75.233333, 15.333333"
                                }
                            ]
                        },
                        {
                            "Id": "IHJR",
                            "Name": "Khajuraho",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.933333, 24.85",
                            "IataCode": "HJR",
                            "Airports": [
                                {
                                    "Id": "HJR",
                                    "Name": "Khajuraho",
                                    "CityId": "IHJR",
                                    "CountryId": "IN",
                                    "Location": "79.922778, 24.824167"
                                }
                            ]
                        },
                        {
                            "Id": "IHYD",
                            "Name": "Hyderabad",
                            "SingleAirportCity": false,
                            "CountryId": "IN",
                            "Location": "78.474446, 17.375281",
                            "IataCode": "HYD",
                            "Airports": [
                                {
                                    "Id": "HYD",
                                    "Name": "Hyderabad",
                                    "CityId": "IHYD",
                                    "CountryId": "IN",
                                    "Location": "78.430281, 17.230538"
                                },
                                {
                                    "Id": "BPM",
                                    "Name": "Begumpet Airport",
                                    "CityId": "IHYD",
                                    "CountryId": "IN",
                                    "Location": "78.4675, 17.453056"
                                }
                            ]
                        },
                        {
                            "Id": "IIDR",
                            "Name": "Indore",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.833301, 22.717925",
                            "IataCode": "IDR",
                            "Airports": [
                                {
                                    "Id": "IDR",
                                    "Name": "Indore",
                                    "CityId": "IIDR",
                                    "CountryId": "IN",
                                    "Location": "75.805556, 22.723056"
                                }
                            ]
                        },
                        {
                            "Id": "IIMF",
                            "Name": "Imphal",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "93.95, 24.816667",
                            "IataCode": "IMF",
                            "Airports": [
                                {
                                    "Id": "IMF",
                                    "Name": "Imphal",
                                    "CityId": "IIMF",
                                    "CountryId": "IN",
                                    "Location": "93.9, 24.766667"
                                }
                            ]
                        },
                        {
                            "Id": "IISK",
                            "Name": "Nasik",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.8, 19.983335",
                            "IataCode": "ISK",
                            "Airports": [
                                {
                                    "Id": "ISK",
                                    "Name": "Nasik",
                                    "CityId": "IISK",
                                    "CountryId": "IN",
                                    "Location": "73.866667, 20"
                                }
                            ]
                        },
                        {
                            "Id": "IIXB",
                            "Name": "Bagdogra",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.316667, 26.7",
                            "IataCode": "IXB",
                            "Airports": [
                                {
                                    "Id": "IXB",
                                    "Name": "Bagdogra",
                                    "CityId": "IIXB",
                                    "CountryId": "IN",
                                    "Location": "88.333333, 26.683333"
                                }
                            ]
                        },
                        {
                            "Id": "IIXC",
                            "Name": "Chandigarh",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.787222, 30.737223",
                            "IataCode": "IXC",
                            "Airports": [
                                {
                                    "Id": "IXC",
                                    "Name": "Chandigarh",
                                    "CityId": "IIXC",
                                    "CountryId": "IN",
                                    "Location": "76.790556, 30.671944"
                                }
                            ]
                        },
                        {
                            "Id": "IIXD",
                            "Name": "Allahabad",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "81.85, 25.450001",
                            "IataCode": "IXD",
                            "Airports": [
                                {
                                    "Id": "IXD",
                                    "Name": "Allahabad",
                                    "CityId": "IIXD",
                                    "CountryId": "IN",
                                    "Location": "81.736111, 25.439722"
                                }
                            ]
                        },
                        {
                            "Id": "IIXE",
                            "Name": "Mangalore",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.883333, 12.866667",
                            "IataCode": "IXE",
                            "Airports": [
                                {
                                    "Id": "IXE",
                                    "Name": "Mangalore",
                                    "CityId": "IIXE",
                                    "CountryId": "IN",
                                    "Location": "74.893889, 12.963889"
                                }
                            ]
                        },
                        {
                            "Id": "IIXG",
                            "Name": "Belgaum",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.5, 15.866667",
                            "IataCode": "IXG",
                            "Airports": [
                                {
                                    "Id": "IXG",
                                    "Name": "Belgaum",
                                    "CityId": "IIXG",
                                    "CountryId": "IN",
                                    "Location": "74.618333, 15.858611"
                                }
                            ]
                        },
                        {
                            "Id": "IIXH",
                            "Name": "Kailashahar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.016667, 24.333333",
                            "IataCode": "IXH",
                            "Airports": [
                                {
                                    "Id": "IXH",
                                    "Name": "Kailashahar",
                                    "CityId": "IIXH",
                                    "CountryId": "IN",
                                    "Location": "92.008889, 24.307222"
                                }
                            ]
                        },
                        {
                            "Id": "IIXJ",
                            "Name": "Jammu",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.866667, 32.733333",
                            "IataCode": "IXJ",
                            "Airports": [
                                {
                                    "Id": "IXJ",
                                    "Name": "Jammu",
                                    "CityId": "IIXJ",
                                    "CountryId": "IN",
                                    "Location": "74.838889, 32.690278"
                                }
                            ]
                        },
                        {
                            "Id": "IIXK",
                            "Name": "Keshod",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.25, 21.3",
                            "IataCode": "IXK",
                            "Airports": [
                                {
                                    "Id": "IXK",
                                    "Name": "Keshod",
                                    "CityId": "IIXK",
                                    "CountryId": "IN",
                                    "Location": "70.270833, 21.319444"
                                }
                            ]
                        },
                        {
                            "Id": "IIXL",
                            "Name": "Leh",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.583333, 34.166667",
                            "IataCode": "IXL",
                            "Airports": [
                                {
                                    "Id": "IXL",
                                    "Name": "Leh",
                                    "CityId": "IIXL",
                                    "CountryId": "IN",
                                    "Location": "77.583333, 34.166667"
                                }
                            ]
                        },
                        {
                            "Id": "IIXM",
                            "Name": "Madurai",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.116667, 9.9333333",
                            "IataCode": "IXM",
                            "Airports": [
                                {
                                    "Id": "IXM",
                                    "Name": "Madurai",
                                    "CityId": "IIXM",
                                    "CountryId": "IN",
                                    "Location": "78.088056, 9.8325"
                                }
                            ]
                        },
                        {
                            "Id": "IIXP",
                            "Name": "Pathankot",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.65, 32.283333",
                            "IataCode": "IXP",
                            "Airports": [
                                {
                                    "Id": "IXP",
                                    "Name": "Pathankot",
                                    "CityId": "IIXP",
                                    "CountryId": "IN",
                                    "Location": "75.633333, 32.216667"
                                }
                            ]
                        },
                        {
                            "Id": "IIXR",
                            "Name": "Ranchi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85.333334, 23.35",
                            "IataCode": "IXR",
                            "Airports": [
                                {
                                    "Id": "IXR",
                                    "Name": "Ranchi",
                                    "CityId": "IIXR",
                                    "CountryId": "IN",
                                    "Location": "85.323611, 23.314444"
                                }
                            ]
                        },
                        {
                            "Id": "IIXS",
                            "Name": "Silchar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.8, 24.816667",
                            "IataCode": "IXS",
                            "Airports": [
                                {
                                    "Id": "IXS",
                                    "Name": "Silchar",
                                    "CityId": "IIXS",
                                    "CountryId": "IN",
                                    "Location": "92.981389, 24.912222"
                                }
                            ]
                        },
                        {
                            "Id": "IIXT",
                            "Name": "Pasighat",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "95.333333, 28.066667",
                            "IataCode": "IXT",
                            "Airports": [
                                {
                                    "Id": "IXT",
                                    "Name": "Pasighat",
                                    "CityId": "IIXT",
                                    "CountryId": "IN",
                                    "Location": "95.333333, 28.016667"
                                }
                            ]
                        },
                        {
                            "Id": "IIXU",
                            "Name": "Aurangabad",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.333333, 19.883333",
                            "IataCode": "IXU",
                            "Airports": [
                                {
                                    "Id": "IXU",
                                    "Name": "Aurangabad",
                                    "CityId": "IIXU",
                                    "CountryId": "IN",
                                    "Location": "75.401111, 19.863611"
                                }
                            ]
                        },
                        {
                            "Id": "IIXV",
                            "Name": "Along",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.766667, 28.166667",
                            "IataCode": "IXV",
                            "Airports": [
                                {
                                    "Id": "IXV",
                                    "Name": "Along",
                                    "CityId": "IIXV",
                                    "CountryId": "IN",
                                    "Location": "94.816667, 28.2"
                                }
                            ]
                        },
                        {
                            "Id": "IIXW",
                            "Name": "Jamshedpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "86.183334, 22.8",
                            "IataCode": "IXW",
                            "Airports": [
                                {
                                    "Id": "IXW",
                                    "Name": "Jamshedpur",
                                    "CityId": "IIXW",
                                    "CountryId": "IN",
                                    "Location": "86.168889, 22.813056"
                                }
                            ]
                        },
                        {
                            "Id": "IIXY",
                            "Name": "Kandla",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.216667, 23.033333",
                            "IataCode": "IXY",
                            "Airports": [
                                {
                                    "Id": "IXY",
                                    "Name": "Kandla",
                                    "CityId": "IIXY",
                                    "CountryId": "IN",
                                    "Location": "70.104167, 23.111111"
                                }
                            ]
                        },
                        {
                            "Id": "IJAI",
                            "Name": "Jaipur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.816666, 26.916671",
                            "IataCode": "JAI",
                            "Airports": [
                                {
                                    "Id": "JAI",
                                    "Name": "Jaipur",
                                    "CityId": "IJAI",
                                    "CountryId": "IN",
                                    "Location": "75.806111, 26.821667"
                                }
                            ]
                        },
                        {
                            "Id": "IJDH",
                            "Name": "Jodhpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.03, 26.286667",
                            "IataCode": "JDH",
                            "Airports": [
                                {
                                    "Id": "JDH",
                                    "Name": "Jodhpur",
                                    "CityId": "IJDH",
                                    "CountryId": "IN",
                                    "Location": "73.047778, 26.260556"
                                }
                            ]
                        },
                        {
                            "Id": "IJGA",
                            "Name": "Jamnagar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.066667, 22.466667",
                            "IataCode": "JGA",
                            "Airports": [
                                {
                                    "Id": "JGA",
                                    "Name": "Jamnagar",
                                    "CityId": "IJGA",
                                    "CountryId": "IN",
                                    "Location": "70.011944, 22.463333"
                                }
                            ]
                        },
                        {
                            "Id": "IJLR",
                            "Name": "Jabalpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.950063, 23.166975",
                            "IataCode": "JLR",
                            "Airports": [
                                {
                                    "Id": "JLR",
                                    "Name": "Jabalpur",
                                    "CityId": "IJLR",
                                    "CountryId": "IN",
                                    "Location": "80.05205, 23.17782"
                                }
                            ]
                        },
                        {
                            "Id": "IJRH",
                            "Name": "Jorhat",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.216667, 26.75",
                            "IataCode": "JRH",
                            "Airports": [
                                {
                                    "Id": "JRH",
                                    "Name": "Jorhat",
                                    "CityId": "IJRH",
                                    "CountryId": "IN",
                                    "Location": "94.177778, 26.728611"
                                }
                            ]
                        },
                        {
                            "Id": "IJSA",
                            "Name": "Jaisalmer",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.918078, 26.914685",
                            "IataCode": "JSA",
                            "Airports": [
                                {
                                    "Id": "JSA",
                                    "Name": "Jaisalmer",
                                    "CityId": "IJSA",
                                    "CountryId": "IN",
                                    "Location": "70.9, 26.916667"
                                }
                            ]
                        },
                        {
                            "Id": "IKNU",
                            "Name": "Kanpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "80.35, 26.466672",
                            "IataCode": "KNU",
                            "Airports": [
                                {
                                    "Id": "KNU",
                                    "Name": "Kanpur",
                                    "CityId": "IKNU",
                                    "CountryId": "IN",
                                    "Location": "80.4125, 26.402778"
                                }
                            ]
                        },
                        {
                            "Id": "IKLH",
                            "Name": "Kolhapur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.216667, 16.7",
                            "IataCode": "KLH",
                            "Airports": [
                                {
                                    "Id": "KLH",
                                    "Name": "Kolhapur",
                                    "CityId": "IKLH",
                                    "CountryId": "IN",
                                    "Location": "74.333333, 16.666667"
                                }
                            ]
                        },
                        {
                            "Id": "IKUU",
                            "Name": "Kulu",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.1, 31.966667",
                            "IataCode": "KUU",
                            "Airports": [
                                {
                                    "Id": "KUU",
                                    "Name": "Kulu",
                                    "CityId": "IKUU",
                                    "CountryId": "IN",
                                    "Location": "77.1, 31.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ILDA",
                            "Name": "Malda",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.15, 25.033333",
                            "IataCode": "LDA",
                            "Airports": [
                                {
                                    "Id": "LDA",
                                    "Name": "Malda",
                                    "CityId": "ILDA",
                                    "CountryId": "IN",
                                    "Location": "88.15, 25.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ILKO",
                            "Name": "Lucknow",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "80.916667, 26.850004",
                            "IataCode": "LKO",
                            "Airports": [
                                {
                                    "Id": "LKO",
                                    "Name": "Lucknow",
                                    "CityId": "ILKO",
                                    "CountryId": "IN",
                                    "Location": "80.880278, 26.760278"
                                }
                            ]
                        },
                        {
                            "Id": "ILUH",
                            "Name": "Ludhiana",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.85, 30.900003",
                            "IataCode": "LUH",
                            "Airports": [
                                {
                                    "Id": "LUH",
                                    "Name": "Ludhiana",
                                    "CityId": "ILUH",
                                    "CountryId": "IN",
                                    "Location": "75.85, 30.9"
                                }
                            ]
                        },
                        {
                            "Id": "IMZU",
                            "Name": "Muzaffarpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85.4, 26.116667",
                            "IataCode": "MZU",
                            "Airports": [
                                {
                                    "Id": "MZU",
                                    "Name": "Muzaffarpur",
                                    "CityId": "IMZU",
                                    "CountryId": "IN",
                                    "Location": "85.383333, 26.116667"
                                }
                            ]
                        },
                        {
                            "Id": "INAG",
                            "Name": "Nagpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.100001, 21.150002",
                            "IataCode": "NAG",
                            "Airports": [
                                {
                                    "Id": "NAG",
                                    "Name": "Nagpur",
                                    "CityId": "INAG",
                                    "CountryId": "IN",
                                    "Location": "79.049722, 21.091111"
                                }
                            ]
                        },
                        {
                            "Id": "INDC",
                            "Name": "Nanded",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.333333, 19.15",
                            "IataCode": "NDC",
                            "Airports": [
                                {
                                    "Id": "NDC",
                                    "Name": "Nanded",
                                    "CityId": "INDC",
                                    "CountryId": "IN",
                                    "Location": "77.316667, 19.183333"
                                }
                            ]
                        },
                        {
                            "Id": "INMB",
                            "Name": "Daman",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.85, 20.416667",
                            "IataCode": "NMB",
                            "Airports": [
                                {
                                    "Id": "NMB",
                                    "Name": "Daman",
                                    "CityId": "INMB",
                                    "CountryId": "IN",
                                    "Location": "72.95, 20.416667"
                                }
                            ]
                        },
                        {
                            "Id": "INVY",
                            "Name": "Neyveli",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.483333, 11.533333",
                            "IataCode": "NVY",
                            "Airports": [
                                {
                                    "Id": "NVY",
                                    "Name": "Neyveli",
                                    "CityId": "INVY",
                                    "CountryId": "IN",
                                    "Location": "79.433333, 11.6"
                                }
                            ]
                        },
                        {
                            "Id": "IPAT",
                            "Name": "Patna",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85.116667, 25.600002",
                            "IataCode": "PAT",
                            "Airports": [
                                {
                                    "Id": "PAT",
                                    "Name": "Patna",
                                    "CityId": "IPAT",
                                    "CountryId": "IN",
                                    "Location": "85.09, 25.590556"
                                }
                            ]
                        },
                        {
                            "Id": "IPNY",
                            "Name": "Puducherry",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.83, 11.93",
                            "IataCode": "PNY",
                            "Airports": [
                                {
                                    "Id": "PNY",
                                    "Name": "Puducherry",
                                    "CityId": "IPNY",
                                    "CountryId": "IN",
                                    "Location": "79.8, 12.016667"
                                }
                            ]
                        },
                        {
                            "Id": "IRAJ",
                            "Name": "Rajkot",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "70.783333, 22.300001",
                            "IataCode": "RAJ",
                            "Airports": [
                                {
                                    "Id": "RAJ",
                                    "Name": "Rajkot",
                                    "CityId": "IRAJ",
                                    "CountryId": "IN",
                                    "Location": "70.780833, 22.305833"
                                }
                            ]
                        },
                        {
                            "Id": "IRPR",
                            "Name": "Raipur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "81.633333, 21.233334",
                            "IataCode": "RPR",
                            "Airports": [
                                {
                                    "Id": "RPR",
                                    "Name": "Raipur",
                                    "CityId": "IRPR",
                                    "CountryId": "IN",
                                    "Location": "81.7388, 21.1804"
                                }
                            ]
                        },
                        {
                            "Id": "IRRK",
                            "Name": "Rourkela",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "84.883333, 22.2",
                            "IataCode": "RRK",
                            "Airports": [
                                {
                                    "Id": "RRK",
                                    "Name": "Rourkela",
                                    "CityId": "IRRK",
                                    "CountryId": "IN",
                                    "Location": "84.814722, 22.256667"
                                }
                            ]
                        },
                        {
                            "Id": "IRTC",
                            "Name": "Ratnagiri",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.3, 16.983333",
                            "IataCode": "RTC",
                            "Airports": [
                                {
                                    "Id": "RTC",
                                    "Name": "Ratnagiri",
                                    "CityId": "IRTC",
                                    "CountryId": "IN",
                                    "Location": "73.316667, 17"
                                }
                            ]
                        },
                        {
                            "Id": "IRUP",
                            "Name": "Rupsi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "89.933333, 26.133333",
                            "IataCode": "RUP",
                            "Airports": [
                                {
                                    "Id": "RUP",
                                    "Name": "Rupsi",
                                    "CityId": "IRUP",
                                    "CountryId": "IN",
                                    "Location": "89.916667, 26.15"
                                }
                            ]
                        },
                        {
                            "Id": "ISTV",
                            "Name": "Surat",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.833335, 21.166671",
                            "IataCode": "STV",
                            "Airports": [
                                {
                                    "Id": "STV",
                                    "Name": "Surat",
                                    "CityId": "ISTV",
                                    "CountryId": "IN",
                                    "Location": "72.7418, 21.1141"
                                }
                            ]
                        },
                        {
                            "Id": "ISHL",
                            "Name": "Shillong",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "91.883125, 25.568924",
                            "IataCode": "SHL",
                            "Airports": [
                                {
                                    "Id": "SHL",
                                    "Name": "Shillong",
                                    "CityId": "ISHL",
                                    "CountryId": "IN",
                                    "Location": "91.883333, 25.566667"
                                }
                            ]
                        },
                        {
                            "Id": "ISLV",
                            "Name": "Shimla",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.166666, 31.1",
                            "IataCode": "SLV",
                            "Airports": [
                                {
                                    "Id": "SLV",
                                    "Name": "Shimla",
                                    "CityId": "ISLV",
                                    "CountryId": "IN",
                                    "Location": "77.15, 31.116667"
                                }
                            ]
                        },
                        {
                            "Id": "ISSE",
                            "Name": "Sholapur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.916667, 17.683333",
                            "IataCode": "SSE",
                            "Airports": [
                                {
                                    "Id": "SSE",
                                    "Name": "Sholapur",
                                    "CityId": "ISSE",
                                    "CountryId": "IN",
                                    "Location": "75.933333, 17.633333"
                                }
                            ]
                        },
                        {
                            "Id": "ISXR",
                            "Name": "Srinagar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.816667, 34.083333",
                            "IataCode": "SXR",
                            "Airports": [
                                {
                                    "Id": "SXR",
                                    "Name": "Srinagar",
                                    "CityId": "ISXR",
                                    "CountryId": "IN",
                                    "Location": "74.775278, 33.988056"
                                }
                            ]
                        },
                        {
                            "Id": "ISXV",
                            "Name": "Salem",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.166667, 11.65",
                            "IataCode": "SXV",
                            "Airports": [
                                {
                                    "Id": "SXV",
                                    "Name": "Salem",
                                    "CityId": "ISXV",
                                    "CountryId": "IN",
                                    "Location": "78.166667, 11.65"
                                }
                            ]
                        },
                        {
                            "Id": "ITEZ",
                            "Name": "Tezpur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.8, 26.633333",
                            "IataCode": "TEZ",
                            "Airports": [
                                {
                                    "Id": "TEZ",
                                    "Name": "Tezpur",
                                    "CityId": "ITEZ",
                                    "CountryId": "IN",
                                    "Location": "92.796111, 26.710833"
                                }
                            ]
                        },
                        {
                            "Id": "ITIR",
                            "Name": "Tirupati",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.416667, 13.65",
                            "IataCode": "TIR",
                            "Airports": [
                                {
                                    "Id": "TIR",
                                    "Name": "Tirupati",
                                    "CityId": "ITIR",
                                    "CountryId": "IN",
                                    "Location": "79.55, 13.633333"
                                }
                            ]
                        },
                        {
                            "Id": "ITNI",
                            "Name": "Satna",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "80.833333, 24.583333",
                            "IataCode": "TNI",
                            "Airports": [
                                {
                                    "Id": "TNI",
                                    "Name": "Satna",
                                    "CityId": "ITNI",
                                    "CountryId": "IN",
                                    "Location": "80.833333, 24.55"
                                }
                            ]
                        },
                        {
                            "Id": "ITRV",
                            "Name": "Thiruvananthapuram",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.916667, 8.4833333",
                            "IataCode": "TRV",
                            "Airports": [
                                {
                                    "Id": "TRV",
                                    "Name": "Thiruvananthapuram",
                                    "CityId": "ITRV",
                                    "CountryId": "IN",
                                    "Location": "76.919722, 8.479722"
                                }
                            ]
                        },
                        {
                            "Id": "IUDR",
                            "Name": "Udaipur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.683333, 24.583333",
                            "IataCode": "UDR",
                            "Airports": [
                                {
                                    "Id": "UDR",
                                    "Name": "Udaipur",
                                    "CityId": "IUDR",
                                    "CountryId": "IN",
                                    "Location": "73.896111, 24.615"
                                }
                            ]
                        },
                        {
                            "Id": "IMYQ",
                            "Name": "Mysore",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.649722, 12.307222",
                            "IataCode": "MYQ",
                            "Airports": [
                                {
                                    "Id": "MYQ",
                                    "Name": "Mysore",
                                    "CityId": "IMYQ",
                                    "CountryId": "IN",
                                    "Location": "76.65, 12.3"
                                }
                            ]
                        },
                        {
                            "Id": "IVNS",
                            "Name": "Varanasi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "83, 25.333335",
                            "IataCode": "VNS",
                            "Airports": [
                                {
                                    "Id": "VNS",
                                    "Name": "Varanasi",
                                    "CityId": "IVNS",
                                    "CountryId": "IN",
                                    "Location": "82.863056, 25.451944"
                                }
                            ]
                        },
                        {
                            "Id": "IVTZ",
                            "Name": "Visakhapatnam",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "83.3, 17.7",
                            "IataCode": "VTZ",
                            "Airports": [
                                {
                                    "Id": "VTZ",
                                    "Name": "Visakhapatnam",
                                    "CityId": "IVTZ",
                                    "CountryId": "IN",
                                    "Location": "83.224722, 17.721111"
                                }
                            ]
                        },
                        {
                            "Id": "IATQ",
                            "Name": "Amritsar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.865556, 31.633057",
                            "IataCode": "ATQ",
                            "Airports": [
                                {
                                    "Id": "ATQ",
                                    "Name": "Amritsar",
                                    "CityId": "IATQ",
                                    "CountryId": "IN",
                                    "Location": "74.797222, 31.706944"
                                }
                            ]
                        },
                        {
                            "Id": "IBBI",
                            "Name": "Bhubaneswar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85.833333, 20.233333",
                            "IataCode": "BBI",
                            "Airports": [
                                {
                                    "Id": "BBI",
                                    "Name": "Bhubaneswar",
                                    "CityId": "IBBI",
                                    "CountryId": "IN",
                                    "Location": "85.82, 20.243611"
                                }
                            ]
                        },
                        {
                            "Id": "IPUT",
                            "Name": "Puttaparthi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.8117, 14.1652",
                            "IataCode": "PUT",
                            "Airports": [
                                {
                                    "Id": "PUT",
                                    "Name": "Puttaparthi",
                                    "CityId": "IPUT",
                                    "CountryId": "IN",
                                    "Location": "77.783333, 14.133333"
                                }
                            ]
                        },
                        {
                            "Id": "IOMN",
                            "Name": "Osmanabad",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.05, 18.166667",
                            "IataCode": "OMN",
                            "Airports": [
                                {
                                    "Id": "OMN",
                                    "Name": "Osmanabad",
                                    "CityId": "IOMN",
                                    "CountryId": "IN",
                                    "Location": "76.1, 18.15"
                                }
                            ]
                        },
                        {
                            "Id": "IMZA",
                            "Name": "Muzaffarnagar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "77.683333, 29.466667",
                            "IataCode": "MZA",
                            "Airports": [
                                {
                                    "Id": "MZA",
                                    "Name": "Muzaffarnagar",
                                    "CityId": "IMZA",
                                    "CountryId": "IN",
                                    "Location": "77.700806, 29.482643"
                                }
                            ]
                        },
                        {
                            "Id": "IREW",
                            "Name": "Rewa",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "81.3, 24.533333",
                            "IataCode": "REW",
                            "Airports": [
                                {
                                    "Id": "REW",
                                    "Name": "Rewa",
                                    "CityId": "IREW",
                                    "CountryId": "IN",
                                    "Location": "81.3, 24.533333"
                                }
                            ]
                        },
                        {
                            "Id": "IPBD",
                            "Name": "Porbandar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "69.609289, 21.642191",
                            "IataCode": "PBD",
                            "Airports": [
                                {
                                    "Id": "PBD",
                                    "Name": "Porbandar",
                                    "CityId": "IPBD",
                                    "CountryId": "IN",
                                    "Location": "69.656944, 21.647222"
                                }
                            ]
                        },
                        {
                            "Id": "IPGH",
                            "Name": "Pantnagar",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.516667, 29.05",
                            "IataCode": "PGH",
                            "Airports": [
                                {
                                    "Id": "PGH",
                                    "Name": "Pantnagar",
                                    "CityId": "IPGH",
                                    "CountryId": "IN",
                                    "Location": "79.473611, 29.031944"
                                }
                            ]
                        },
                        {
                            "Id": "IPYB",
                            "Name": "Jeypore",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "82.583333, 18.85",
                            "IataCode": "PYB",
                            "Airports": [
                                {
                                    "Id": "PYB",
                                    "Name": "Jeypore",
                                    "CityId": "IPYB",
                                    "CountryId": "IN",
                                    "Location": "82.633333, 18.833333"
                                }
                            ]
                        },
                        {
                            "Id": "IRGH",
                            "Name": "Balurghat",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.766666, 25.216667",
                            "IataCode": "RGH",
                            "Airports": [
                                {
                                    "Id": "RGH",
                                    "Name": "Balurghat",
                                    "CityId": "IRGH",
                                    "CountryId": "IN",
                                    "Location": "88.733333, 25.25"
                                }
                            ]
                        },
                        {
                            "Id": "IRJA",
                            "Name": "Rajahmundry",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "81.783333, 16.983333",
                            "IataCode": "RJA",
                            "Airports": [
                                {
                                    "Id": "RJA",
                                    "Name": "Rajahmundry",
                                    "CityId": "IRJA",
                                    "CountryId": "IN",
                                    "Location": "81.783333, 16.983333"
                                }
                            ]
                        },
                        {
                            "Id": "IVGA",
                            "Name": "Vijayawada",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "80.616667, 16.516667",
                            "IataCode": "VGA",
                            "Airports": [
                                {
                                    "Id": "VGA",
                                    "Name": "Vijayawada",
                                    "CityId": "IVGA",
                                    "CountryId": "IN",
                                    "Location": "80.797222, 16.529444"
                                }
                            ]
                        },
                        {
                            "Id": "ITJV",
                            "Name": "Thanjavur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.15, 10.8",
                            "IataCode": "TJV",
                            "Airports": [
                                {
                                    "Id": "TJV",
                                    "Name": "Thanjavur",
                                    "CityId": "ITJV",
                                    "CountryId": "IN",
                                    "Location": "79.2, 10.8"
                                }
                            ]
                        },
                        {
                            "Id": "IIXZ",
                            "Name": "Port Blair",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.75, 11.666667",
                            "IataCode": "IXZ",
                            "Airports": [
                                {
                                    "Id": "IXZ",
                                    "Name": "Port blair",
                                    "CityId": "IIXZ",
                                    "CountryId": "IN",
                                    "Location": "92.743611, 11.648333"
                                }
                            ]
                        },
                        {
                            "Id": "IMAA",
                            "Name": "Chennai",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "80.278474, 13.087842",
                            "IataCode": "MAA",
                            "Airports": [
                                {
                                    "Id": "MAA",
                                    "Name": "Chennai",
                                    "CityId": "IMAA",
                                    "CountryId": "IN",
                                    "Location": "80.176944, 12.993333"
                                }
                            ]
                        },
                        {
                            "Id": "IDAI",
                            "Name": "Darjeeling",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.266667, 27.033333",
                            "IataCode": "DAI",
                            "Airports": [
                                {
                                    "Id": "DAI",
                                    "Name": "Darjeeling",
                                    "CityId": "IDAI",
                                    "CountryId": "IN",
                                    "Location": "88.266667, 27.033333"
                                }
                            ]
                        },
                        {
                            "Id": "RDPA",
                            "Name": "Durgapur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "87.316667, 23.483333",
                            "IataCode": "RDP",
                            "Airports": [
                                {
                                    "Id": "RDP",
                                    "Name": "Kazi Nazrul Islam",
                                    "CityId": "RDPA",
                                    "CountryId": "IN",
                                    "Location": "87.243333, 23.621389"
                                }
                            ]
                        },
                        {
                            "Id": "IIXA",
                            "Name": "Agartala",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "91.275, 23.836389",
                            "IataCode": "IXA",
                            "Airports": [
                                {
                                    "Id": "IXA",
                                    "Name": "Agartala",
                                    "CityId": "IIXA",
                                    "CountryId": "IN",
                                    "Location": "91.242222, 23.89"
                                }
                            ]
                        },
                        {
                            "Id": "PYGA",
                            "Name": "Gangtok",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "88.616667, 27.333333",
                            "IataCode": "PYG",
                            "Airports": [
                                {
                                    "Id": "PYG",
                                    "Name": "Pakyong",
                                    "CityId": "PYGA",
                                    "CountryId": "IN",
                                    "Location": "88.5892, 27.2335"
                                }
                            ]
                        },
                        {
                            "Id": "SAGI",
                            "Name": "Shirdi",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.483333, 19.766667",
                            "IataCode": "SAG",
                            "Airports": [
                                {
                                    "Id": "SAG",
                                    "Name": "Shirdi",
                                    "CityId": "SAGI",
                                    "CountryId": "IN",
                                    "Location": "74.390438, 19.691477"
                                }
                            ]
                        },
                        {
                            "Id": "ITRZ",
                            "Name": "Tiruchirapalli",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.69875, 10.80289",
                            "IataCode": "TRZ",
                            "Airports": [
                                {
                                    "Id": "TRZ",
                                    "Name": "Tiruchirapalli",
                                    "CityId": "ITRZ",
                                    "CountryId": "IN",
                                    "Location": "78.713333, 10.765"
                                }
                            ]
                        },
                        {
                            "Id": "ITCR",
                            "Name": "Tuticorin",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.133333, 8.7833333",
                            "IataCode": "TCR",
                            "Airports": [
                                {
                                    "Id": "TCR",
                                    "Name": "Tuticorin",
                                    "CityId": "ITCR",
                                    "CountryId": "IN",
                                    "Location": "78.033333, 8.716667"
                                }
                            ]
                        },
                        {
                            "Id": "IBEK",
                            "Name": "Bareli",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "81.233333, 26.216667",
                            "IataCode": "BEK",
                            "Airports": [
                                {
                                    "Id": "BEK",
                                    "Name": "Bareli",
                                    "CityId": "IBEK",
                                    "CountryId": "IN",
                                    "Location": "81.233333, 26.216667"
                                }
                            ]
                        },
                        {
                            "Id": "IWGC",
                            "Name": "Warangal",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.583334, 18",
                            "IataCode": "WGC",
                            "Airports": [
                                {
                                    "Id": "WGC",
                                    "Name": "Warangal",
                                    "CityId": "IWGC",
                                    "CountryId": "IN",
                                    "Location": "79.6, 17.916667"
                                }
                            ]
                        },
                        {
                            "Id": "IRJI",
                            "Name": "Rajouri",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.3, 33.383333",
                            "IataCode": "RJI",
                            "Airports": [
                                {
                                    "Id": "RJI",
                                    "Name": "Rajouri",
                                    "CityId": "IRJI",
                                    "CountryId": "IN",
                                    "Location": "74.3025, 33.368611"
                                }
                            ]
                        },
                        {
                            "Id": "ILTU",
                            "Name": "Latur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.583333, 18.4",
                            "IataCode": "LTU",
                            "Airports": [
                                {
                                    "Id": "LTU",
                                    "Name": "Latur",
                                    "CityId": "ILTU",
                                    "CountryId": "IN",
                                    "Location": "76.465, 18.411944"
                                }
                            ]
                        },
                        {
                            "Id": "KJBA",
                            "Name": "Kurnool",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.05, 15.833333",
                            "IataCode": "KJB",
                            "Airports": [
                                {
                                    "Id": "KJB",
                                    "Name": "Kurnool",
                                    "CityId": "KJBA",
                                    "CountryId": "IN",
                                    "Location": "78.160833, 15.706111"
                                }
                            ]
                        },
                        {
                            "Id": "KQHI",
                            "Name": "Kishangarh",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "74.866667, 26.566667",
                            "IataCode": "KQH",
                            "Airports": [
                                {
                                    "Id": "KQH",
                                    "Name": "Ajmer",
                                    "CityId": "KQHI",
                                    "CountryId": "IN",
                                    "Location": "74.816188, 26.590545"
                                }
                            ]
                        },
                        {
                            "Id": "JRGI",
                            "Name": "Veer Surendra Sai",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "84.033333, 21.85",
                            "IataCode": "JRG",
                            "Airports": [
                                {
                                    "Id": "JRG",
                                    "Name": "Jharsuguda",
                                    "CityId": "JRGI",
                                    "CountryId": "IN",
                                    "Location": "84.050278, 21.913333"
                                }
                            ]
                        },
                        {
                            "Id": "JLGI",
                            "Name": "Jalgaon",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.566667, 21.016667",
                            "IataCode": "JLG",
                            "Airports": [
                                {
                                    "Id": "JLG",
                                    "Name": "Jalgaon",
                                    "CityId": "JLGI",
                                    "CountryId": "IN",
                                    "Location": "75.626667, 20.961944"
                                }
                            ]
                        },
                        {
                            "Id": "GBIB",
                            "Name": "Gulbarga",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.833333, 17.333333",
                            "IataCode": "GBI",
                            "Airports": [
                                {
                                    "Id": "GBI",
                                    "Name": "Gulbarga",
                                    "CityId": "GBIB",
                                    "CountryId": "IN",
                                    "Location": "76.94494, 17.306128"
                                }
                            ]
                        },
                        {
                            "Id": "IDED",
                            "Name": "Dehradun",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "78.033334, 30.316668",
                            "IataCode": "DED",
                            "Airports": [
                                {
                                    "Id": "DED",
                                    "Name": "Dehradun",
                                    "CityId": "IDED",
                                    "CountryId": "IN",
                                    "Location": "78.18, 30.189444"
                                }
                            ]
                        },
                        {
                            "Id": "DBRA",
                            "Name": "Darbhanga",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "85.9, 26.166667",
                            "IataCode": "DBR",
                            "Airports": [
                                {
                                    "Id": "DBR",
                                    "Name": "Darbhanga Airport",
                                    "CityId": "DBRA",
                                    "CountryId": "IN",
                                    "Location": "85.9175, 26.194722"
                                }
                            ]
                        },
                        {
                            "Id": "CNNK",
                            "Name": "Kannur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.366667, 11.85",
                            "IataCode": "CNN",
                            "Airports": [
                                {
                                    "Id": "CNN",
                                    "Name": "Kannur International",
                                    "CityId": "CNNK",
                                    "CountryId": "IN",
                                    "Location": "75.55, 11.92"
                                }
                            ]
                        },
                        {
                            "Id": "IPAB",
                            "Name": "Bilaspur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.75, 31.333333",
                            "IataCode": "PAB",
                            "Airports": [
                                {
                                    "Id": "PAB",
                                    "Name": "Bilaspur",
                                    "CityId": "IPAB",
                                    "CountryId": "IN",
                                    "Location": "76.833333, 31.316667"
                                }
                            ]
                        },
                        {
                            "Id": "AIPI",
                            "Name": "Adampur",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "75.7175, 31.432778",
                            "IataCode": "AIP",
                            "Airports": [
                                {
                                    "Id": "AIP",
                                    "Name": "Adampur",
                                    "CityId": "AIPI",
                                    "CountryId": "IN",
                                    "Location": "75.760556, 31.433056"
                                }
                            ]
                        },
                        {
                            "Id": "IAGX",
                            "Name": "Agatti Island",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "72.2, 10.833",
                            "IataCode": "AGX",
                            "Airports": [
                                {
                                    "Id": "AGX",
                                    "Name": "Agatti island",
                                    "CityId": "IAGX",
                                    "CountryId": "IN",
                                    "Location": "72.176, 10.8237"
                                }
                            ]
                        },
                        {
                            "Id": "IPNQ",
                            "Name": "Pune",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "73.883486, 18.520673",
                            "IataCode": "PNQ",
                            "Airports": [
                                {
                                    "Id": "PNQ",
                                    "Name": "Pune",
                                    "CityId": "IPNQ",
                                    "CountryId": "IN",
                                    "Location": "73.920556, 18.581389"
                                }
                            ]
                        },
                        {
                            "Id": "IVDY",
                            "Name": "Sandūr",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "76.55, 15.1",
                            "IataCode": "VDY",
                            "Airports": [
                                {
                                    "Id": "VDY",
                                    "Name": "Vidyanagar",
                                    "CityId": "IVDY",
                                    "CountryId": "IN",
                                    "Location": "76.61376, 15.149849"
                                }
                            ]
                        },
                        {
                            "Id": "IRMD",
                            "Name": "Rāmgundam",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "79.45, 18.8",
                            "IataCode": "RMD",
                            "Airports": [
                                {
                                    "Id": "RMD",
                                    "Name": "Ramagundam",
                                    "CityId": "IRMD",
                                    "CountryId": "IN",
                                    "Location": "79.4, 18.766667"
                                }
                            ]
                        },
                        {
                            "Id": "ICBD",
                            "Name": "Malacca",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "92.816667, 9.1666667",
                            "IataCode": "CBD",
                            "Airports": [
                                {
                                    "Id": "CBD",
                                    "Name": "Car Nicobar",
                                    "CityId": "ICBD",
                                    "CountryId": "IN",
                                    "Location": "92.816667, 9.15"
                                }
                            ]
                        },
                        {
                            "Id": "IDEP",
                            "Name": "Jaihing",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.033333, 27.3",
                            "IataCode": "DEP",
                            "Airports": [
                                {
                                    "Id": "DEP",
                                    "Name": "Deparizo",
                                    "CityId": "IDEP",
                                    "CountryId": "IN",
                                    "Location": "94, 27.35"
                                }
                            ]
                        },
                        {
                            "Id": "IDAE",
                            "Name": "Dulangmukh",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.3, 27.5",
                            "IataCode": "DAE",
                            "Airports": [
                                {
                                    "Id": "DAE",
                                    "Name": "Daparizo",
                                    "CityId": "IDAE",
                                    "CountryId": "IN",
                                    "Location": "94.333333, 27.5"
                                }
                            ]
                        },
                        {
                            "Id": "IIXI",
                            "Name": "Diju",
                            "SingleAirportCity": true,
                            "CountryId": "IN",
                            "Location": "94.05, 27.283333",
                            "IataCode": "IXI",
                            "Airports": [
                                {
                                    "Id": "IXI",
                                    "Name": "Lilabari",
                                    "CityId": "IIXI",
                                    "CountryId": "IN",
                                    "Location": "94.088889, 27.290278"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MV",
                    "Name": "Maldives",
                    "CurrencyId": "MVR",
                    "Cities": [
                        {
                            "Id": "HAQA",
                            "Name": "Hanimaadhoo",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.17564, 6.7648521",
                            "IataCode": "HAQ",
                            "Airports": [
                                {
                                    "Id": "HAQ",
                                    "Name": "Hanimaadhoo",
                                    "CityId": "HAQA",
                                    "CountryId": "MV",
                                    "Location": "73.15, 6.733333"
                                }
                            ]
                        },
                        {
                            "Id": "MLEA",
                            "Name": "Malé",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.509865, 4.1771077",
                            "IataCode": "MLE",
                            "Airports": [
                                {
                                    "Id": "MLE",
                                    "Name": "Velana International",
                                    "CityId": "MLEA",
                                    "CountryId": "MV",
                                    "Location": "73.524444, 4.201389"
                                }
                            ]
                        },
                        {
                            "Id": "YLKA",
                            "Name": "Barrie",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "-79.666338, 44.400108",
                            "IataCode": "YLK",
                            "Airports": [
                                {
                                    "Id": "YLK",
                                    "Name": "Lake Simcoe Regional",
                                    "CityId": "YLKA",
                                    "CountryId": "MV",
                                    "Location": "-79.555556, 44.486111"
                                }
                            ]
                        },
                        {
                            "Id": "GANA",
                            "Name": "Gan Island",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.15, -0.7",
                            "IataCode": "GAN",
                            "Airports": [
                                {
                                    "Id": "GAN",
                                    "Name": "Gan Island",
                                    "CityId": "GANA",
                                    "CountryId": "MV",
                                    "Location": "73.166667, -0.683333"
                                }
                            ]
                        },
                        {
                            "Id": "DRVA",
                            "Name": "Dharavandhoo Island",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.13056, 5.15833",
                            "IataCode": "DRV",
                            "Airports": [
                                {
                                    "Id": "DRV",
                                    "Name": "Dharavandhoo Island",
                                    "CityId": "DRVA",
                                    "CountryId": "MV",
                                    "Location": "73.134195, 5.155074"
                                }
                            ]
                        },
                        {
                            "Id": "VAMA",
                            "Name": "Maamigili Island",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "72.8375, 3.475",
                            "IataCode": "VAM",
                            "Airports": [
                                {
                                    "Id": "VAM",
                                    "Name": "Maamigili Island",
                                    "CityId": "VAMA",
                                    "CountryId": "MV",
                                    "Location": "72.835833, 3.470556"
                                }
                            ]
                        },
                        {
                            "Id": "TMFA",
                            "Name": "Thimarafushi",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.14167, 2.19722",
                            "IataCode": "TMF",
                            "Airports": [
                                {
                                    "Id": "TMF",
                                    "Name": "Thimarafushi",
                                    "CityId": "TMFA",
                                    "CountryId": "MV",
                                    "Location": "73.153163, 2.212251"
                                }
                            ]
                        },
                        {
                            "Id": "KDMA",
                            "Name": "Kaadedhdhoo",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.167, 0.417",
                            "IataCode": "KDM",
                            "Airports": [
                                {
                                    "Id": "KDM",
                                    "Name": "Kaadedhdhoo",
                                    "CityId": "KDMA",
                                    "CountryId": "MV",
                                    "Location": "72.996944, 0.488056"
                                }
                            ]
                        },
                        {
                            "Id": "FVMA",
                            "Name": "Fuvahmulak Island",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.40833, -0.27917",
                            "IataCode": "FVM",
                            "Airports": [
                                {
                                    "Id": "FVM",
                                    "Name": "Fuvahmulak Island",
                                    "CityId": "FVMA",
                                    "CountryId": "MV",
                                    "Location": "73.433458, -0.309117"
                                }
                            ]
                        },
                        {
                            "Id": "DDDA",
                            "Name": "Eriyadu",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.414249, 4.590285",
                            "IataCode": "DDD",
                            "Airports": [
                                {
                                    "Id": "DDD",
                                    "Name": "Dhaalu Atoll",
                                    "CityId": "DDDA",
                                    "CountryId": "MV",
                                    "Location": "73.457948, 4.959203"
                                }
                            ]
                        },
                        {
                            "Id": "GKKA",
                            "Name": "Viligili",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.383333, 0.75",
                            "IataCode": "GKK",
                            "Airports": [
                                {
                                    "Id": "GKK",
                                    "Name": "Kooddoo Island",
                                    "CityId": "GKKA",
                                    "CountryId": "MV",
                                    "Location": "73.433056, 0.730833"
                                }
                            ]
                        },
                        {
                            "Id": "KDOA",
                            "Name": "Hithadhoo",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73.383333, 1.7833333",
                            "IataCode": "KDO",
                            "Airports": [
                                {
                                    "Id": "KDO",
                                    "Name": "Kadhdhoo",
                                    "CityId": "KDOA",
                                    "CountryId": "MV",
                                    "Location": "73.5219, 1.85917"
                                }
                            ]
                        },
                        {
                            "Id": "IFUA",
                            "Name": "Ugoofaaru",
                            "SingleAirportCity": true,
                            "CountryId": "MV",
                            "Location": "73, 5.6666667",
                            "IataCode": "IFU",
                            "Airports": [
                                {
                                    "Id": "IFU",
                                    "Name": "Ifuru",
                                    "CityId": "IFUA",
                                    "CountryId": "MV",
                                    "Location": "73.024756, 5.708529"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KP",
                    "Name": "North Korea",
                    "CurrencyId": "KPW",
                    "Cities": [
                        {
                            "Id": "FNJA",
                            "Name": "Pyongyang",
                            "SingleAirportCity": true,
                            "CountryId": "KP",
                            "Location": "125.75472, 39.019452",
                            "IataCode": "FNJ",
                            "Airports": [
                                {
                                    "Id": "FNJ",
                                    "Name": "Pyongyang",
                                    "CityId": "FNJA",
                                    "CountryId": "KP",
                                    "Location": "125.66667, 39.2"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KR",
                    "Name": "South Korea",
                    "CurrencyId": "KRW",
                    "Cities": [
                        {
                            "Id": "WJUA",
                            "Name": "WonJu",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "127.94528, 37.351389",
                            "IataCode": "WJU",
                            "Airports": [
                                {
                                    "Id": "WJU",
                                    "Name": "WonJu",
                                    "CityId": "WJUA",
                                    "CountryId": "KR",
                                    "Location": "127.95167, 37.434167"
                                }
                            ]
                        },
                        {
                            "Id": "CHFA",
                            "Name": "Jinhae",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.65972, 35.149446",
                            "IataCode": "CHF",
                            "Airports": [
                                {
                                    "Id": "CHF",
                                    "Name": "Jinhae",
                                    "CityId": "CHFA",
                                    "CountryId": "KR",
                                    "Location": "128.69778, 35.1375"
                                }
                            ]
                        },
                        {
                            "Id": "SELA",
                            "Name": "Seoul",
                            "SingleAirportCity": false,
                            "CountryId": "KR",
                            "Location": "126.99204, 37.542869",
                            "IataCode": "SEL",
                            "Airports": [
                                {
                                    "Id": "GMP",
                                    "Name": "Seoul Gimpo",
                                    "CityId": "SELA",
                                    "CountryId": "KR",
                                    "Location": "126.78528, 37.551111"
                                },
                                {
                                    "Id": "ICN",
                                    "Name": "Seoul Incheon Int'l",
                                    "CityId": "SELA",
                                    "CountryId": "KR",
                                    "Location": "126.45056, 37.469167"
                                },
                                {
                                    "Id": "SSN",
                                    "Name": "Seoul Ab",
                                    "CityId": "SELA",
                                    "CountryId": "KR",
                                    "Location": "127.11667, 37.45"
                                }
                            ]
                        },
                        {
                            "Id": "HINA",
                            "Name": "Jinju",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.08472, 35.192778",
                            "IataCode": "HIN",
                            "Airports": [
                                {
                                    "Id": "HIN",
                                    "Name": "Jinju",
                                    "CityId": "HINA",
                                    "CountryId": "KR",
                                    "Location": "128.07806, 35.088056"
                                }
                            ]
                        },
                        {
                            "Id": "KAGA",
                            "Name": "Gangneung",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.89611, 37.755556",
                            "IataCode": "KAG",
                            "Airports": [
                                {
                                    "Id": "KAG",
                                    "Name": "Gangneung",
                                    "CityId": "KAGA",
                                    "CountryId": "KR",
                                    "Location": "128.94972, 37.752222"
                                }
                            ]
                        },
                        {
                            "Id": "KPOA",
                            "Name": "P'ohang",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "129.365, 36.032222",
                            "IataCode": "KPO",
                            "Airports": [
                                {
                                    "Id": "KPO",
                                    "Name": "Pohang",
                                    "CityId": "KPOA",
                                    "CountryId": "KR",
                                    "Location": "129.42, 35.986111"
                                }
                            ]
                        },
                        {
                            "Id": "KUVA",
                            "Name": "Gunsan",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "126.71139, 35.978611",
                            "IataCode": "KUV",
                            "Airports": [
                                {
                                    "Id": "KUV",
                                    "Name": "Gunsan",
                                    "CityId": "KUVA",
                                    "CountryId": "KR",
                                    "Location": "126.75, 35.983333"
                                }
                            ]
                        },
                        {
                            "Id": "KWJA",
                            "Name": "Gwangju",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "126.91556, 35.154725",
                            "IataCode": "KWJ",
                            "Airports": [
                                {
                                    "Id": "KWJ",
                                    "Name": "Gwangju",
                                    "CityId": "KWJA",
                                    "CountryId": "KR",
                                    "Location": "126.81083, 35.123333"
                                }
                            ]
                        },
                        {
                            "Id": "PUSA",
                            "Name": "Busan",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "129.04028, 35.102786",
                            "IataCode": "PUS",
                            "Airports": [
                                {
                                    "Id": "PUS",
                                    "Name": "Gimhae International",
                                    "CityId": "PUSA",
                                    "CountryId": "KR",
                                    "Location": "128.93833, 35.179444"
                                }
                            ]
                        },
                        {
                            "Id": "TAEA",
                            "Name": "Daegu",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.59111, 35.870284",
                            "IataCode": "TAE",
                            "Airports": [
                                {
                                    "Id": "TAE",
                                    "Name": "Daegu",
                                    "CityId": "TAEA",
                                    "CountryId": "KR",
                                    "Location": "128.66667, 35.883333"
                                }
                            ]
                        },
                        {
                            "Id": "OSNA",
                            "Name": "Osan",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "127.06944, 37.145",
                            "IataCode": "OSN",
                            "Airports": [
                                {
                                    "Id": "OSN",
                                    "Name": "Osan",
                                    "CityId": "OSNA",
                                    "CountryId": "KR",
                                    "Location": "127.03333, 37.083333"
                                }
                            ]
                        },
                        {
                            "Id": "YNYA",
                            "Name": "Yangyang",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.62111, 38.073889",
                            "IataCode": "YNY",
                            "Airports": [
                                {
                                    "Id": "YNY",
                                    "Name": "Yangyang",
                                    "CityId": "YNYA",
                                    "CountryId": "KR",
                                    "Location": "128.66901, 38.0613"
                                }
                            ]
                        },
                        {
                            "Id": "MPKA",
                            "Name": "Mokpo",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "126.38861, 34.793611",
                            "IataCode": "MPK",
                            "Airports": [
                                {
                                    "Id": "MPK",
                                    "Name": "Mokpo",
                                    "CityId": "MPKA",
                                    "CountryId": "KR",
                                    "Location": "126.385, 34.756667"
                                }
                            ]
                        },
                        {
                            "Id": "USNA",
                            "Name": "Ulsan",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "129.31667, 35.537222",
                            "IataCode": "USN",
                            "Airports": [
                                {
                                    "Id": "USN",
                                    "Name": "Ulsan",
                                    "CityId": "USNA",
                                    "CountryId": "KR",
                                    "Location": "129.35172, 35.59349"
                                }
                            ]
                        },
                        {
                            "Id": "CJUA",
                            "Name": "Jeju",
                            "SingleAirportCity": false,
                            "CountryId": "KR",
                            "Location": "126.53576, 33.445354",
                            "IataCode": "CJU",
                            "Airports": [
                                {
                                    "Id": "CJU",
                                    "Name": "Jeju",
                                    "CityId": "CJUA",
                                    "CountryId": "KR",
                                    "Location": "126.495, 33.505556"
                                },
                                {
                                    "Id": "JSP",
                                    "Name": "Jeju Seogwipo Heliport",
                                    "CityId": "CJUA",
                                    "CountryId": "KR",
                                    "Location": "126.56667, 33.483333"
                                },
                                {
                                    "Id": "JCJ",
                                    "Name": "Jeju Chuja Heliport",
                                    "CityId": "CJUA",
                                    "CountryId": "KR",
                                    "Location": "126.32778, 33.944444"
                                }
                            ]
                        },
                        {
                            "Id": "SWUA",
                            "Name": "Suwon",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "127.01917, 37.284169",
                            "IataCode": "SWU",
                            "Airports": [
                                {
                                    "Id": "SWU",
                                    "Name": "Suwon",
                                    "CityId": "SWUA",
                                    "CountryId": "KR",
                                    "Location": "127.01667, 37.283333"
                                }
                            ]
                        },
                        {
                            "Id": "JCNA",
                            "Name": "Incheon",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "126.39282, 37.454614",
                            "IataCode": "JCN",
                            "Airports": [
                                {
                                    "Id": "JCN",
                                    "Name": "Incheon",
                                    "CityId": "JCNA",
                                    "CountryId": "KR",
                                    "Location": "126.6, 37.466667"
                                }
                            ]
                        },
                        {
                            "Id": "MWXK",
                            "Name": "Muan",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "126.47139, 34.989722",
                            "IataCode": "MWX",
                            "Airports": [
                                {
                                    "Id": "MWX",
                                    "Name": "Gwangju Muan International",
                                    "CityId": "MWXK",
                                    "CountryId": "KR",
                                    "Location": "126.38918, 34.993364"
                                }
                            ]
                        },
                        {
                            "Id": "JGEA",
                            "Name": "Geoje",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.58861, 34.850278",
                            "IataCode": "JGE",
                            "Airports": [
                                {
                                    "Id": "JGE",
                                    "Name": "Geoje",
                                    "CityId": "JGEA",
                                    "CountryId": "KR",
                                    "Location": "128.68333, 34.9"
                                }
                            ]
                        },
                        {
                            "Id": "CJJA",
                            "Name": "Cheongju",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "127.48972, 36.637224",
                            "IataCode": "CJJ",
                            "Airports": [
                                {
                                    "Id": "CJJ",
                                    "Name": "Cheongju",
                                    "CityId": "CJJA",
                                    "CountryId": "KR",
                                    "Location": "127.50667, 36.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YECA",
                            "Name": "Yecheon",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.21194, 37.136111",
                            "IataCode": "YEC",
                            "Airports": [
                                {
                                    "Id": "YEC",
                                    "Name": "Yecheon",
                                    "CityId": "YECA",
                                    "CountryId": "KR",
                                    "Location": "128.355, 36.625"
                                }
                            ]
                        },
                        {
                            "Id": "CHNA",
                            "Name": "Jeonju",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "128.30722, 36.739722",
                            "IataCode": "CHN",
                            "Airports": [
                                {
                                    "Id": "CHN",
                                    "Name": "Jeonju",
                                    "CityId": "CHNA",
                                    "CountryId": "KR",
                                    "Location": "127.93333, 37.016944"
                                }
                            ]
                        },
                        {
                            "Id": "RSUA",
                            "Name": "Yeosu",
                            "SingleAirportCity": true,
                            "CountryId": "KR",
                            "Location": "127.69479, 34.7833",
                            "IataCode": "RSU",
                            "Airports": [
                                {
                                    "Id": "RSU",
                                    "Name": "Yeosu",
                                    "CityId": "RSUA",
                                    "CountryId": "KR",
                                    "Location": "127.61685, 34.84233"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UZ",
                    "Name": "Uzbekistan",
                    "CurrencyId": "UZS",
                    "Cities": [
                        {
                            "Id": "BHKA",
                            "Name": "Bukhara",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "64.428611, 39.774724",
                            "IataCode": "BHK",
                            "Airports": [
                                {
                                    "Id": "BHK",
                                    "Name": "Bukhara",
                                    "CityId": "BHKA",
                                    "CountryId": "UZ",
                                    "Location": "64.483333, 39.766667"
                                }
                            ]
                        },
                        {
                            "Id": "FEGA",
                            "Name": "Fergana",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "71.779444, 40.393333",
                            "IataCode": "FEG",
                            "Airports": [
                                {
                                    "Id": "FEG",
                                    "Name": "Fergana",
                                    "CityId": "FEGA",
                                    "CountryId": "UZ",
                                    "Location": "71.733611, 40.350278"
                                }
                            ]
                        },
                        {
                            "Id": "NCUA",
                            "Name": "Nukus",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "59.610278, 42.453057",
                            "IataCode": "NCU",
                            "Airports": [
                                {
                                    "Id": "NCU",
                                    "Name": "Nukus",
                                    "CityId": "NCUA",
                                    "CountryId": "UZ",
                                    "Location": "59.633333, 42.483333"
                                }
                            ]
                        },
                        {
                            "Id": "TASA",
                            "Name": "Tashkent",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "69.25, 41.316671",
                            "IataCode": "TAS",
                            "Airports": [
                                {
                                    "Id": "TAS",
                                    "Name": "Tashkent",
                                    "CityId": "TASA",
                                    "CountryId": "UZ",
                                    "Location": "69.283333, 41.25"
                                }
                            ]
                        },
                        {
                            "Id": "TMJA",
                            "Name": "Termez",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "67.278334, 37.224168",
                            "IataCode": "TMJ",
                            "Airports": [
                                {
                                    "Id": "TMJ",
                                    "Name": "Termez",
                                    "CityId": "TMJA",
                                    "CountryId": "UZ",
                                    "Location": "67.285833, 37.268611"
                                }
                            ]
                        },
                        {
                            "Id": "AZNA",
                            "Name": "Andizhan",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "72.343889, 40.783056",
                            "IataCode": "AZN",
                            "Airports": [
                                {
                                    "Id": "AZN",
                                    "Name": "Andizhan",
                                    "CityId": "AZNA",
                                    "CountryId": "UZ",
                                    "Location": "72.3, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "AFSA",
                            "Name": "Zarafshan",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "64.2075, 41.579167",
                            "IataCode": "AFS",
                            "Airports": [
                                {
                                    "Id": "AFS",
                                    "Name": "Zarafshan",
                                    "CityId": "AFSA",
                                    "CountryId": "UZ",
                                    "Location": "64.2331, 41.6136"
                                }
                            ]
                        },
                        {
                            "Id": "NVIA",
                            "Name": "Navoiy",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "65.379166, 40.084444",
                            "IataCode": "NVI",
                            "Airports": [
                                {
                                    "Id": "NVI",
                                    "Name": "Navoiy",
                                    "CityId": "NVIA",
                                    "CountryId": "UZ",
                                    "Location": "65.176435, 40.12183"
                                }
                            ]
                        },
                        {
                            "Id": "SKDA",
                            "Name": "Samarkand",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "66.959722, 39.654167",
                            "IataCode": "SKD",
                            "Airports": [
                                {
                                    "Id": "SKD",
                                    "Name": "Samarkand",
                                    "CityId": "SKDA",
                                    "CountryId": "UZ",
                                    "Location": "67, 39.7"
                                }
                            ]
                        },
                        {
                            "Id": "NMAA",
                            "Name": "Namangan",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "71.672573, 40.998299",
                            "IataCode": "NMA",
                            "Airports": [
                                {
                                    "Id": "NMA",
                                    "Name": "Namangan",
                                    "CityId": "NMAA",
                                    "CountryId": "UZ",
                                    "Location": "71.6, 40.983333"
                                }
                            ]
                        },
                        {
                            "Id": "UGCA",
                            "Name": "Urgench",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "60.633334, 41.55",
                            "IataCode": "UGC",
                            "Airports": [
                                {
                                    "Id": "UGC",
                                    "Name": "Urgench",
                                    "CityId": "UGCA",
                                    "CountryId": "UZ",
                                    "Location": "60.533333, 41.65"
                                }
                            ]
                        },
                        {
                            "Id": "KSQA",
                            "Name": "Karshi",
                            "SingleAirportCity": true,
                            "CountryId": "UZ",
                            "Location": "65.797777, 38.863333",
                            "IataCode": "KSQ",
                            "Airports": [
                                {
                                    "Id": "KSQ",
                                    "Name": "Karshi",
                                    "CityId": "KSQA",
                                    "CountryId": "UZ",
                                    "Location": "65.766667, 38.8"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KZ",
                    "Name": "Kazakhstan",
                    "CurrencyId": "KZT",
                    "Cities": [
                        {
                            "Id": "ALAA",
                            "Name": "Almaty",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "76.949999, 43.250005",
                            "IataCode": "ALA",
                            "Airports": [
                                {
                                    "Id": "ALA",
                                    "Name": "Almaty",
                                    "CityId": "ALAA",
                                    "CountryId": "KZ",
                                    "Location": "77.033333, 43.35"
                                }
                            ]
                        },
                        {
                            "Id": "PPKA",
                            "Name": "Petropavlovsk",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "69.162778, 54.875279",
                            "IataCode": "PPK",
                            "Airports": [
                                {
                                    "Id": "PPK",
                                    "Name": "Petropavlovsk",
                                    "CityId": "PPKA",
                                    "CountryId": "KZ",
                                    "Location": "69.183333, 54.783333"
                                }
                            ]
                        },
                        {
                            "Id": "AKXA",
                            "Name": "Aktobe",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "57.207184, 50.279687",
                            "IataCode": "AKX",
                            "Airports": [
                                {
                                    "Id": "AKX",
                                    "Name": "Aktobe",
                                    "CityId": "AKXA",
                                    "CountryId": "KZ",
                                    "Location": "57.216667, 50.25"
                                }
                            ]
                        },
                        {
                            "Id": "DMBA",
                            "Name": "Zhambyl",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "71.366666, 42.9",
                            "IataCode": "DMB",
                            "Airports": [
                                {
                                    "Id": "DMB",
                                    "Name": "Zhambyl",
                                    "CityId": "DMBA",
                                    "CountryId": "KZ",
                                    "Location": "71.366667, 42.9"
                                }
                            ]
                        },
                        {
                            "Id": "EKBA",
                            "Name": "Ekibastuz",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "75.366667, 51.666667",
                            "IataCode": "EKB",
                            "Airports": [
                                {
                                    "Id": "EKB",
                                    "Name": "Ekibastuz",
                                    "CityId": "EKBA",
                                    "CountryId": "KZ",
                                    "Location": "75.216667, 51.583333"
                                }
                            ]
                        },
                        {
                            "Id": "GUWA",
                            "Name": "Atyrau",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "51.883333, 47.116669",
                            "IataCode": "GUW",
                            "Airports": [
                                {
                                    "Id": "GUW",
                                    "Name": "Atyrau",
                                    "CityId": "GUWA",
                                    "CountryId": "KZ",
                                    "Location": "51.821389, 47.121944"
                                }
                            ]
                        },
                        {
                            "Id": "KOVA",
                            "Name": "Kokshetau",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "69.388333, 53.277502",
                            "IataCode": "KOV",
                            "Airports": [
                                {
                                    "Id": "KOV",
                                    "Name": "Kokshetau",
                                    "CityId": "KOVA",
                                    "CountryId": "KZ",
                                    "Location": "69.4, 53.316667"
                                }
                            ]
                        },
                        {
                            "Id": "KGFA",
                            "Name": "Karaganda",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "73.099445, 49.798891",
                            "IataCode": "KGF",
                            "Airports": [
                                {
                                    "Id": "KGF",
                                    "Name": "Karaganda",
                                    "CityId": "KGFA",
                                    "CountryId": "KZ",
                                    "Location": "73.33444, 49.67083"
                                }
                            ]
                        },
                        {
                            "Id": "KSNA",
                            "Name": "Kostanay",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "63.583333, 53.166668",
                            "IataCode": "KSN",
                            "Airports": [
                                {
                                    "Id": "KSN",
                                    "Name": "Kostanay",
                                    "CityId": "KSNA",
                                    "CountryId": "KZ",
                                    "Location": "63.55, 53.2"
                                }
                            ]
                        },
                        {
                            "Id": "KZOA",
                            "Name": "Kzyl-Orda",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "65.509166, 44.85278",
                            "IataCode": "KZO",
                            "Airports": [
                                {
                                    "Id": "KZO",
                                    "Name": "Kzyl-Orda",
                                    "CityId": "KZOA",
                                    "CountryId": "KZ",
                                    "Location": "65.59267, 44.710909"
                                }
                            ]
                        },
                        {
                            "Id": "PLXA",
                            "Name": "Semipalatinsk",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "80.2275, 50.411114",
                            "IataCode": "PLX",
                            "Airports": [
                                {
                                    "Id": "PLX",
                                    "Name": "Semipalatinsk",
                                    "CityId": "PLXA",
                                    "CountryId": "KZ",
                                    "Location": "80.2344, 50.3513"
                                }
                            ]
                        },
                        {
                            "Id": "PWQA",
                            "Name": "Pavlodar",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "76.95, 52.3",
                            "IataCode": "PWQ",
                            "Airports": [
                                {
                                    "Id": "PWQ",
                                    "Name": "Pavlodar",
                                    "CityId": "PWQA",
                                    "CountryId": "KZ",
                                    "Location": "77.066667, 52.183333"
                                }
                            ]
                        },
                        {
                            "Id": "SCOA",
                            "Name": "Aktau",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "51.2, 43.65",
                            "IataCode": "SCO",
                            "Airports": [
                                {
                                    "Id": "SCO",
                                    "Name": "Aktau",
                                    "CityId": "SCOA",
                                    "CountryId": "KZ",
                                    "Location": "51.100001, 43.866667"
                                }
                            ]
                        },
                        {
                            "Id": "TDKA",
                            "Name": "Taldy-Kurgan",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "78.373889, 45.015556",
                            "IataCode": "TDK",
                            "Airports": [
                                {
                                    "Id": "TDK",
                                    "Name": "Taldy-Kurgan",
                                    "CityId": "TDKA",
                                    "CountryId": "KZ",
                                    "Location": "78.433333, 45.15"
                                }
                            ]
                        },
                        {
                            "Id": "TSEA",
                            "Name": "Nur-Sultan",
                            "SingleAirportCity": false,
                            "CountryId": "KZ",
                            "Location": "71.427777, 51.181111",
                            "IataCode": "NQZ",
                            "Airports": [
                                {
                                    "Id": "NQZ",
                                    "Name": "Nur-Sultan",
                                    "CityId": "TSEA",
                                    "CountryId": "KZ",
                                    "Location": "71.5, 51.166667"
                                },
                                {
                                    "Id": "TSE",
                                    "Name": "Nur-Sultan",
                                    "CityId": "TSEA",
                                    "CountryId": "KZ",
                                    "Location": "71.5, 51.166667"
                                }
                            ]
                        },
                        {
                            "Id": "URAA",
                            "Name": "Uralsk",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "51.366667, 51.233335",
                            "IataCode": "URA",
                            "Airports": [
                                {
                                    "Id": "URA",
                                    "Name": "Uralsk",
                                    "CityId": "URAA",
                                    "CountryId": "KZ",
                                    "Location": "51.550001, 51.15"
                                }
                            ]
                        },
                        {
                            "Id": "ATXA",
                            "Name": "Atbasar",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "68.333333, 51.8",
                            "IataCode": "ATX",
                            "Airports": [
                                {
                                    "Id": "ATX",
                                    "Name": "Atbasar",
                                    "CityId": "ATXA",
                                    "CountryId": "KZ",
                                    "Location": "68.4, 51.883333"
                                }
                            ]
                        },
                        {
                            "Id": "AYKA",
                            "Name": "Arkalyk",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "66.911389, 50.248611",
                            "IataCode": "AYK",
                            "Airports": [
                                {
                                    "Id": "AYK",
                                    "Name": "Arkalyk",
                                    "CityId": "AYKA",
                                    "CountryId": "KZ",
                                    "Location": "66.966667, 50.316667"
                                }
                            ]
                        },
                        {
                            "Id": "DZNA",
                            "Name": "Zhezkazgan",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "67.441944, 47.870833",
                            "IataCode": "DZN",
                            "Airports": [
                                {
                                    "Id": "DZN",
                                    "Name": "Zhezkazgan",
                                    "CityId": "DZNA",
                                    "CountryId": "KZ",
                                    "Location": "67.733333, 47.7"
                                }
                            ]
                        },
                        {
                            "Id": "SZIA",
                            "Name": "Zaisan",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "84.866667, 47.466667",
                            "IataCode": "SZI",
                            "Airports": [
                                {
                                    "Id": "SZI",
                                    "Name": "Zaisan",
                                    "CityId": "SZIA",
                                    "CountryId": "KZ",
                                    "Location": "84.866667, 47.7"
                                }
                            ]
                        },
                        {
                            "Id": "UZRA",
                            "Name": "Urdzhar",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "81.1, 47.166667",
                            "IataCode": "UZR",
                            "Airports": [
                                {
                                    "Id": "UZR",
                                    "Name": "Urdzhar",
                                    "CityId": "UZRA",
                                    "CountryId": "KZ",
                                    "Location": "81.66, 47.09"
                                }
                            ]
                        },
                        {
                            "Id": "USJK",
                            "Name": "Usharal",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "80.939444, 46.169722",
                            "IataCode": "USJ",
                            "Airports": [
                                {
                                    "Id": "USJ",
                                    "Name": "Usharal",
                                    "CityId": "USJK",
                                    "CountryId": "KZ",
                                    "Location": "80.831137, 46.189155"
                                }
                            ]
                        },
                        {
                            "Id": "HSAA",
                            "Name": "Turkestan",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "68.25, 43.333333",
                            "IataCode": "HSA",
                            "Airports": [
                                {
                                    "Id": "HSA",
                                    "Name": "Turkistan International Airport",
                                    "CityId": "HSAA",
                                    "CountryId": "KZ",
                                    "Location": "68.550278, 43.311111"
                                }
                            ]
                        },
                        {
                            "Id": "CITA",
                            "Name": "Shimkent",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "69.6, 42.3",
                            "IataCode": "CIT",
                            "Airports": [
                                {
                                    "Id": "CIT",
                                    "Name": "Shimkent",
                                    "CityId": "CITA",
                                    "CountryId": "KZ",
                                    "Location": "69.6, 42.3"
                                }
                            ]
                        },
                        {
                            "Id": "UKKA",
                            "Name": "Ust-Kamenogorsk",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "82.610277, 49.978889",
                            "IataCode": "UKK",
                            "Airports": [
                                {
                                    "Id": "UKK",
                                    "Name": "Ust-Kamenogorsk",
                                    "CityId": "UKKA",
                                    "CountryId": "KZ",
                                    "Location": "82.5, 50.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BXYA",
                            "Name": "Baykonur",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "63.316667, 45.616667",
                            "IataCode": "BXY",
                            "Airports": [
                                {
                                    "Id": "BXY",
                                    "Name": "Krayniy",
                                    "CityId": "BXYA",
                                    "CountryId": "KZ",
                                    "Location": "63.210386, 45.619909"
                                }
                            ]
                        },
                        {
                            "Id": "BXHA",
                            "Name": "Balhash",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "74.995, 46.848056",
                            "IataCode": "BXH",
                            "Airports": [
                                {
                                    "Id": "BXH",
                                    "Name": "Balhash",
                                    "CityId": "BXHA",
                                    "CountryId": "KZ",
                                    "Location": "75.016667, 46.883333"
                                }
                            ]
                        },
                        {
                            "Id": "HRCA",
                            "Name": "Mangadyr",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "70.616944, 48.549444",
                            "IataCode": "HRC",
                            "Airports": [
                                {
                                    "Id": "HRC",
                                    "Name": "Zhairem",
                                    "CityId": "HRCA",
                                    "CountryId": "KZ",
                                    "Location": "70.466667, 48.466667"
                                }
                            ]
                        },
                        {
                            "Id": "BXJA",
                            "Name": "Zhanatalap",
                            "SingleAirportCity": true,
                            "CountryId": "KZ",
                            "Location": "76.967222, 43.473056",
                            "IataCode": "BXJ",
                            "Airports": [
                                {
                                    "Id": "BXJ",
                                    "Name": "Burundai",
                                    "CityId": "BXJA",
                                    "CountryId": "KZ",
                                    "Location": "76.95, 43.5"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AZ",
                    "Name": "Azerbaijan",
                    "CurrencyId": "AZN",
                    "Cities": [
                        {
                            "Id": "BAKA",
                            "Name": "Baku",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "50.016982, 40.235197",
                            "IataCode": "BAK",
                            "Airports": [
                                {
                                    "Id": "GYD",
                                    "Name": "Baku Heydar Aliyev International",
                                    "CityId": "BAKA",
                                    "CountryId": "AZ",
                                    "Location": "50.052182, 40.465564"
                                }
                            ]
                        },
                        {
                            "Id": "NAJA",
                            "Name": "Nakhichevan",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "45.412222, 39.208889",
                            "IataCode": "NAJ",
                            "Airports": [
                                {
                                    "Id": "NAJ",
                                    "Name": "Nakhichevan",
                                    "CityId": "NAJA",
                                    "CountryId": "AZ",
                                    "Location": "45.458889, 39.190278"
                                }
                            ]
                        },
                        {
                            "Id": "LANK",
                            "Name": "Lankaran",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "48.851111, 38.753611",
                            "IataCode": "LLK",
                            "Airports": [
                                {
                                    "Id": "LLK",
                                    "Name": "Lankaran International",
                                    "CityId": "LANK",
                                    "CountryId": "AZ",
                                    "Location": "48.824093, 38.74203"
                                }
                            ]
                        },
                        {
                            "Id": "ZAQA",
                            "Name": "Zaqatala",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "46.643333, 41.633611",
                            "IataCode": "ZTU",
                            "Airports": [
                                {
                                    "Id": "ZTU",
                                    "Name": "Zaqatala",
                                    "CityId": "ZAQA",
                                    "CountryId": "AZ",
                                    "Location": "46.6648, 41.5617"
                                }
                            ]
                        },
                        {
                            "Id": "QABA",
                            "Name": "Qabala",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "47.84583, 40.98139",
                            "IataCode": "GBB",
                            "Airports": [
                                {
                                    "Id": "GBB",
                                    "Name": "Qabala",
                                    "CityId": "QABA",
                                    "CountryId": "AZ",
                                    "Location": "47.7125, 40.826667"
                                }
                            ]
                        },
                        {
                            "Id": "KVDA",
                            "Name": "Gyandzha",
                            "SingleAirportCity": true,
                            "CountryId": "AZ",
                            "Location": "46.36056, 40.68278",
                            "IataCode": "KVD",
                            "Airports": [
                                {
                                    "Id": "KVD",
                                    "Name": "Gyandzha",
                                    "CityId": "KVDA",
                                    "CountryId": "AZ",
                                    "Location": "46.316667, 40.733333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BD",
                    "Name": "Bangladesh",
                    "CurrencyId": "BDT",
                    "Cities": [
                        {
                            "Id": "ZHMA",
                            "Name": "Shamshernagar",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.916667, 24.383333",
                            "IataCode": "ZHM",
                            "Airports": [
                                {
                                    "Id": "ZHM",
                                    "Name": "Shamshernagar",
                                    "CityId": "ZHMA",
                                    "CountryId": "BD",
                                    "Location": "91.921111, 24.398611"
                                }
                            ]
                        },
                        {
                            "Id": "ZYLA",
                            "Name": "Sylhet",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.871667, 24.896667",
                            "IataCode": "ZYL",
                            "Airports": [
                                {
                                    "Id": "ZYL",
                                    "Name": "Sylhet",
                                    "CityId": "ZYLA",
                                    "CountryId": "BD",
                                    "Location": "91.874444, 24.958889"
                                }
                            ]
                        },
                        {
                            "Id": "BZLA",
                            "Name": "Barisal",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "90.371111, 22.701944",
                            "IataCode": "BZL",
                            "Airports": [
                                {
                                    "Id": "BZL",
                                    "Name": "Barisal",
                                    "CityId": "BZLA",
                                    "CountryId": "BD",
                                    "Location": "90.3, 22.8"
                                }
                            ]
                        },
                        {
                            "Id": "CGPA",
                            "Name": "Chittagong",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.83639, 22.333061",
                            "IataCode": "CGP",
                            "Airports": [
                                {
                                    "Id": "CGP",
                                    "Name": "Chittagong",
                                    "CityId": "CGPA",
                                    "CountryId": "BD",
                                    "Location": "91.825556, 22.2575"
                                }
                            ]
                        },
                        {
                            "Id": "CLAA",
                            "Name": "Comilla",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.204445, 23.457778",
                            "IataCode": "CLA",
                            "Airports": [
                                {
                                    "Id": "CLA",
                                    "Name": "Comilla",
                                    "CityId": "CLAA",
                                    "CountryId": "BD",
                                    "Location": "91.192222, 23.4375"
                                }
                            ]
                        },
                        {
                            "Id": "CXBA",
                            "Name": "Cox's Bazar",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.978056, 21.442222",
                            "IataCode": "CXB",
                            "Airports": [
                                {
                                    "Id": "CXB",
                                    "Name": "Cox's Bazar",
                                    "CityId": "CXBA",
                                    "CountryId": "BD",
                                    "Location": "91.966667, 21.45"
                                }
                            ]
                        },
                        {
                            "Id": "DACA",
                            "Name": "Dhaka",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "90.408627, 23.723091",
                            "IataCode": "DAC",
                            "Airports": [
                                {
                                    "Id": "DAC",
                                    "Name": "Dhaka",
                                    "CityId": "DACA",
                                    "CountryId": "BD",
                                    "Location": "90.39778, 23.84333"
                                }
                            ]
                        },
                        {
                            "Id": "KHLA",
                            "Name": "Khulna",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "89.549999, 22.800002",
                            "IataCode": "KHL",
                            "Airports": [
                                {
                                    "Id": "KHL",
                                    "Name": "Khulna",
                                    "CityId": "KHLA",
                                    "CountryId": "BD",
                                    "Location": "89.55, 22.8"
                                }
                            ]
                        },
                        {
                            "Id": "JSRA",
                            "Name": "Jessore",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "89.216666, 23.166667",
                            "IataCode": "JSR",
                            "Airports": [
                                {
                                    "Id": "JSR",
                                    "Name": "Jessore",
                                    "CityId": "JSRA",
                                    "CountryId": "BD",
                                    "Location": "89.163889, 23.181111"
                                }
                            ]
                        },
                        {
                            "Id": "RJHA",
                            "Name": "Rajshahi",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "88.6, 24.366667",
                            "IataCode": "RJH",
                            "Airports": [
                                {
                                    "Id": "RJH",
                                    "Name": "Rajshahi",
                                    "CityId": "RJHA",
                                    "CountryId": "BD",
                                    "Location": "88.616667, 24.433333"
                                }
                            ]
                        },
                        {
                            "Id": "RAUA",
                            "Name": "Rangpur",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "89.25, 25.750001",
                            "IataCode": "RAU",
                            "Airports": [
                                {
                                    "Id": "RAU",
                                    "Name": "Rangpur",
                                    "CityId": "RAUA",
                                    "CountryId": "BD",
                                    "Location": "89.366667, 25.7"
                                }
                            ]
                        },
                        {
                            "Id": "SDWA",
                            "Name": "Sandwip",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "91.456111, 22.467778",
                            "IataCode": "SDW",
                            "Airports": [
                                {
                                    "Id": "SDW",
                                    "Name": "Sandwip",
                                    "CityId": "SDWA",
                                    "CountryId": "BD",
                                    "Location": "91.416667, 22.483333"
                                }
                            ]
                        },
                        {
                            "Id": "TKRA",
                            "Name": "Thakurgaon",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "88.466667, 26.033333",
                            "IataCode": "TKR",
                            "Airports": [
                                {
                                    "Id": "TKR",
                                    "Name": "Thakurgaon",
                                    "CityId": "TKRA",
                                    "CountryId": "BD",
                                    "Location": "88.4, 26.016667"
                                }
                            ]
                        },
                        {
                            "Id": "SAJA",
                            "Name": "Sirajganj",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "89.716666, 24.45",
                            "IataCode": "SAJ",
                            "Airports": [
                                {
                                    "Id": "SAJ",
                                    "Name": "Sirajganj",
                                    "CityId": "SAJA",
                                    "CountryId": "BD",
                                    "Location": "89.783333, 24.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IRDA",
                            "Name": "Ishurdi",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "89.083333, 24.133333",
                            "IataCode": "IRD",
                            "Airports": [
                                {
                                    "Id": "IRD",
                                    "Name": "Ishurdi",
                                    "CityId": "IRDA",
                                    "CountryId": "BD",
                                    "Location": "89.052778, 24.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SPDA",
                            "Name": "Saidpur",
                            "SingleAirportCity": true,
                            "CountryId": "BD",
                            "Location": "88.89169, 25.777692",
                            "IataCode": "SPD",
                            "Airports": [
                                {
                                    "Id": "SPD",
                                    "Name": "Saidpur",
                                    "CityId": "SPDA",
                                    "CountryId": "BD",
                                    "Location": "88.9, 25.783333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "JP",
                    "Name": "Japan",
                    "CurrencyId": "JPY",
                    "LanguageId": "JA",
                    "Regions": [
                        {
                            "Id": "HOKKA",
                            "Name": "Hokkaido",
                            "CountryId": "JP"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "JSHI",
                            "Name": "Shimojishima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "125.14494, 24.826903",
                            "IataCode": "SHI",
                            "Airports": [
                                {
                                    "Id": "SHI",
                                    "Name": "Shimojishima",
                                    "CityId": "JSHI",
                                    "CountryId": "JP",
                                    "Location": "125.15, 24.816667"
                                }
                            ]
                        },
                        {
                            "Id": "JIZO",
                            "Name": "Izumo",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "132.76667, 35.366667",
                            "IataCode": "IZO",
                            "Airports": [
                                {
                                    "Id": "IZO",
                                    "Name": "Izumo",
                                    "CityId": "JIZO",
                                    "CountryId": "JP",
                                    "Location": "132.88944, 35.409444"
                                }
                            ]
                        },
                        {
                            "Id": "JWKJ",
                            "Name": "Wakkanai",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "141.67389, 45.409444",
                            "IataCode": "WKJ",
                            "Airports": [
                                {
                                    "Id": "WKJ",
                                    "Name": "Wakkanai",
                                    "CityId": "JWKJ",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "141.80083, 45.404167"
                                }
                            ]
                        },
                        {
                            "Id": "JYGJ",
                            "Name": "Yonago",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "133.33333, 35.433335",
                            "IataCode": "YGJ",
                            "Airports": [
                                {
                                    "Id": "YGJ",
                                    "Name": "Yonago",
                                    "CityId": "JYGJ",
                                    "CountryId": "JP",
                                    "Location": "133.24222, 35.491389"
                                }
                            ]
                        },
                        {
                            "Id": "JAKJ",
                            "Name": "Asahikawa",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "142.37028, 43.767778",
                            "IataCode": "AKJ",
                            "Airports": [
                                {
                                    "Id": "AKJ",
                                    "Name": "Asahikawa",
                                    "CityId": "JAKJ",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "142.45, 43.666667"
                                }
                            ]
                        },
                        {
                            "Id": "JAOJ",
                            "Name": "Aomori",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.75111, 40.821111",
                            "IataCode": "AOJ",
                            "Airports": [
                                {
                                    "Id": "AOJ",
                                    "Name": "Aomori",
                                    "CityId": "JAOJ",
                                    "CountryId": "JP",
                                    "Location": "140.7, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SPKA",
                            "Name": "Sapporo",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "141.35389, 43.054726",
                            "IataCode": "SPK",
                            "Airports": [
                                {
                                    "Id": "CTS",
                                    "Name": "Sapporo Chitose",
                                    "CityId": "SPKA",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "141.66917, 42.795833"
                                },
                                {
                                    "Id": "OKD",
                                    "Name": "Sapporo Okadama",
                                    "CityId": "SPKA",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "141.385, 43.115556"
                                }
                            ]
                        },
                        {
                            "Id": "JBPU",
                            "Name": "Beppu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.49194, 33.273611",
                            "IataCode": "BPU",
                            "Airports": [
                                {
                                    "Id": "BPU",
                                    "Name": "Beppu",
                                    "CityId": "JBPU",
                                    "CountryId": "JP",
                                    "Location": "131.5, 33.25"
                                }
                            ]
                        },
                        {
                            "Id": "JOKA",
                            "Name": "Okinawa",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "Location": "127.81755, 26.341228",
                            "IataCode": "OKA",
                            "Airports": [
                                {
                                    "Id": "OKA",
                                    "Name": "Okinawa Naha",
                                    "CityId": "JOKA",
                                    "CountryId": "JP",
                                    "Location": "127.65, 26.2"
                                },
                                {
                                    "Id": "AHA",
                                    "Name": "Okinawa Naha AFB",
                                    "CityId": "JOKA",
                                    "CountryId": "JP",
                                    "Location": "127.65, 26.2"
                                },
                                {
                                    "Id": "DNA",
                                    "Name": "Okinawa Kadena AFB",
                                    "CityId": "JOKA",
                                    "CountryId": "JP",
                                    "Location": "127.76667, 26.35"
                                }
                            ]
                        },
                        {
                            "Id": "JFKJ",
                            "Name": "Fukui",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "136.22257, 36.064435",
                            "IataCode": "FKJ",
                            "Airports": [
                                {
                                    "Id": "FKJ",
                                    "Name": "Fukui",
                                    "CityId": "JFKJ",
                                    "CountryId": "JP",
                                    "Location": "136.22667, 36.139722"
                                }
                            ]
                        },
                        {
                            "Id": "JFKS",
                            "Name": "Fukushima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.46667, 37.750002",
                            "IataCode": "FKS",
                            "Airports": [
                                {
                                    "Id": "FKS",
                                    "Name": "Fukushima",
                                    "CityId": "JFKS",
                                    "CountryId": "JP",
                                    "Location": "140.43278, 37.226667"
                                }
                            ]
                        },
                        {
                            "Id": "FSZA",
                            "Name": "Shizuoka",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "138.38333, 34.966667",
                            "IataCode": "FSZ",
                            "Airports": [
                                {
                                    "Id": "FSZ",
                                    "Name": "Shizuoka",
                                    "CityId": "FSZA",
                                    "CountryId": "JP",
                                    "Location": "138.1894, 34.7961"
                                }
                            ]
                        },
                        {
                            "Id": "JFUJ",
                            "Name": "Fukue",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "128.84194, 32.688056",
                            "IataCode": "FUJ",
                            "Airports": [
                                {
                                    "Id": "FUJ",
                                    "Name": "Fukue",
                                    "CityId": "JFUJ",
                                    "CountryId": "JP",
                                    "Location": "128.83333, 32.666667"
                                }
                            ]
                        },
                        {
                            "Id": "JFUK",
                            "Name": "Fukuoka",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.4, 33.583336",
                            "IataCode": "FUK",
                            "Airports": [
                                {
                                    "Id": "FUK",
                                    "Name": "Fukuoka",
                                    "CityId": "JFUK",
                                    "CountryId": "JP",
                                    "Location": "130.45333, 33.582222"
                                }
                            ]
                        },
                        {
                            "Id": "JGAJ",
                            "Name": "Yamagata",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.3375, 38.252778",
                            "IataCode": "GAJ",
                            "Airports": [
                                {
                                    "Id": "GAJ",
                                    "Name": "Yamagata",
                                    "CityId": "JGAJ",
                                    "CountryId": "JP",
                                    "Location": "140.38333, 38.4"
                                }
                            ]
                        },
                        {
                            "Id": "JHHE",
                            "Name": "Hachinohe",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "141.5, 40.500001",
                            "IataCode": "HHE",
                            "Airports": [
                                {
                                    "Id": "HHE",
                                    "Name": "Hachinohe",
                                    "CityId": "JHHE",
                                    "CountryId": "JP",
                                    "Location": "141.47083, 40.549167"
                                }
                            ]
                        },
                        {
                            "Id": "JHIJ",
                            "Name": "Hiroshima",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "Location": "132.45, 34.400002",
                            "IataCode": "HIJ",
                            "Airports": [
                                {
                                    "Id": "HIJ",
                                    "Name": "Hiroshima International",
                                    "CityId": "JHIJ",
                                    "CountryId": "JP",
                                    "Location": "132.92472, 34.432778"
                                },
                                {
                                    "Id": "HIW",
                                    "Name": "Hiroshima International Hiroshima West",
                                    "CityId": "JHIJ",
                                    "CountryId": "JP",
                                    "Location": "132.41639, 34.363611"
                                }
                            ]
                        },
                        {
                            "Id": "JHKD",
                            "Name": "Hakodate",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "140.73667, 41.775833",
                            "IataCode": "HKD",
                            "Airports": [
                                {
                                    "Id": "HKD",
                                    "Name": "Hakodate",
                                    "CityId": "JHKD",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "140.81972, 41.768889"
                                }
                            ]
                        },
                        {
                            "Id": "TYOA",
                            "Name": "Tokyo",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "Location": "139.73895, 35.68895",
                            "IataCode": "TYO",
                            "Airports": [
                                {
                                    "Id": "HND",
                                    "Name": "Tokyo Haneda",
                                    "CityId": "TYOA",
                                    "CountryId": "JP",
                                    "Location": "139.76583, 35.553611"
                                },
                                {
                                    "Id": "NRT",
                                    "Name": "Tokyo Narita",
                                    "CityId": "TYOA",
                                    "CountryId": "JP",
                                    "Location": "140.39111, 35.763889"
                                },
                                {
                                    "Id": "OKO",
                                    "Name": "Tokyo Yokota AFB",
                                    "CityId": "TYOA",
                                    "CountryId": "JP",
                                    "Location": "139.35, 35.75"
                                }
                            ]
                        },
                        {
                            "Id": "JHSG",
                            "Name": "Saga",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.3, 33.25",
                            "IataCode": "HSG",
                            "Airports": [
                                {
                                    "Id": "HSG",
                                    "Name": "Saga",
                                    "CityId": "JHSG",
                                    "CountryId": "JP",
                                    "Location": "130.30444, 33.146389"
                                }
                            ]
                        },
                        {
                            "Id": "JIIN",
                            "Name": "Nishinoomote",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131, 30.733333",
                            "IataCode": "IIN",
                            "Airports": [
                                {
                                    "Id": "IIN",
                                    "Name": "Nishinoomote",
                                    "CityId": "JIIN",
                                    "CountryId": "JP",
                                    "Location": "130.98333, 30.716667"
                                }
                            ]
                        },
                        {
                            "Id": "OSAA",
                            "Name": "Osaka",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "Location": "135.50218, 34.693746",
                            "IataCode": "OSA",
                            "Airports": [
                                {
                                    "Id": "ITM",
                                    "Name": "Osaka Itami",
                                    "CityId": "OSAA",
                                    "CountryId": "JP",
                                    "Location": "135.44167, 34.781667"
                                },
                                {
                                    "Id": "UKB",
                                    "Name": "Kobe",
                                    "CityId": "OSAA",
                                    "CountryId": "JP",
                                    "Location": "135.16667, 34.75"
                                },
                                {
                                    "Id": "KIX",
                                    "Name": "Osaka Kansai International",
                                    "CityId": "OSAA",
                                    "CountryId": "JP",
                                    "Location": "135.24694, 34.423889"
                                },
                                {
                                    "Id": "QKV",
                                    "Name": "Osaka Sakai - Off-line Point",
                                    "CityId": "OSAA",
                                    "CountryId": "JP",
                                    "Location": "135.50056, 34.500556"
                                }
                            ]
                        },
                        {
                            "Id": "JKMI",
                            "Name": "Miyazaki",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.43333, 31.9",
                            "IataCode": "KMI",
                            "Airports": [
                                {
                                    "Id": "KMI",
                                    "Name": "Miyazaki",
                                    "CityId": "JKMI",
                                    "CountryId": "JP",
                                    "Location": "131.44389, 31.874444"
                                }
                            ]
                        },
                        {
                            "Id": "JKMJ",
                            "Name": "Kumamoto",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.71667, 32.8",
                            "IataCode": "KMJ",
                            "Airports": [
                                {
                                    "Id": "KMJ",
                                    "Name": "Kumamoto",
                                    "CityId": "JKMJ",
                                    "CountryId": "JP",
                                    "Location": "130.8525, 32.831944"
                                }
                            ]
                        },
                        {
                            "Id": "JKMQ",
                            "Name": "Komatsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "136.45088, 36.402632",
                            "IataCode": "KMQ",
                            "Airports": [
                                {
                                    "Id": "KMQ",
                                    "Name": "Komatsu",
                                    "CityId": "JKMQ",
                                    "CountryId": "JP",
                                    "Location": "136.41694, 36.393889"
                                }
                            ]
                        },
                        {
                            "Id": "JKOJ",
                            "Name": "Kagoshima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.55, 31.600001",
                            "IataCode": "KOJ",
                            "Airports": [
                                {
                                    "Id": "KOJ",
                                    "Name": "Kagoshima",
                                    "CityId": "JKOJ",
                                    "CountryId": "JP",
                                    "Location": "130.72028, 31.801944"
                                }
                            ]
                        },
                        {
                            "Id": "JKCZ",
                            "Name": "Kochi",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "133.55, 33.55",
                            "IataCode": "KCZ",
                            "Airports": [
                                {
                                    "Id": "KCZ",
                                    "Name": "Kochi",
                                    "CityId": "JKCZ",
                                    "CountryId": "JP",
                                    "Location": "133.67694, 33.541111"
                                }
                            ]
                        },
                        {
                            "Id": "JKIJ",
                            "Name": "Niigata",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.05, 37.916668",
                            "IataCode": "KIJ",
                            "Airports": [
                                {
                                    "Id": "KIJ",
                                    "Name": "Niigata",
                                    "CityId": "JKIJ",
                                    "CountryId": "JP",
                                    "Location": "139.1225, 37.953333"
                                }
                            ]
                        },
                        {
                            "Id": "JKUH",
                            "Name": "Kushiro",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "144.37472, 42.975",
                            "IataCode": "KUH",
                            "Airports": [
                                {
                                    "Id": "KUH",
                                    "Name": "Kushiro",
                                    "CityId": "JKUH",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "144.20389, 43.039722"
                                }
                            ]
                        },
                        {
                            "Id": "JNGO",
                            "Name": "Nagoya",
                            "SingleAirportCity": false,
                            "CountryId": "JP",
                            "Location": "136.90641, 35.181477",
                            "IataCode": "NGO",
                            "Airports": [
                                {
                                    "Id": "NKM",
                                    "Name": "Nagoya Komaki Air Force Base",
                                    "CityId": "JNGO",
                                    "CountryId": "JP",
                                    "Location": "136.9275, 35.2517"
                                },
                                {
                                    "Id": "NGO",
                                    "Name": "Nagoya Chubu Centrair",
                                    "CityId": "JNGO",
                                    "CountryId": "JP",
                                    "Location": "136.80734, 34.859876"
                                }
                            ]
                        },
                        {
                            "Id": "JNGS",
                            "Name": "Nagasaki",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "129.86833, 32.755",
                            "IataCode": "NGS",
                            "Airports": [
                                {
                                    "Id": "NGS",
                                    "Name": "Nagasaki",
                                    "CityId": "JNGS",
                                    "CountryId": "JP",
                                    "Location": "129.9175, 32.911944"
                                }
                            ]
                        },
                        {
                            "Id": "JOBO",
                            "Name": "Obihiro",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "143.20444, 42.917222",
                            "IataCode": "OBO",
                            "Airports": [
                                {
                                    "Id": "OBO",
                                    "Name": "Obihiro",
                                    "CityId": "JOBO",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "143.21722, 42.733333"
                                }
                            ]
                        },
                        {
                            "Id": "JOIT",
                            "Name": "Oita",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.60444, 33.237222",
                            "IataCode": "OIT",
                            "Airports": [
                                {
                                    "Id": "OIT",
                                    "Name": "Oita",
                                    "CityId": "JOIT",
                                    "CountryId": "JP",
                                    "Location": "131.73333, 33.483333"
                                }
                            ]
                        },
                        {
                            "Id": "JOKJ",
                            "Name": "Okayama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "133.91667, 34.650002",
                            "IataCode": "OKJ",
                            "Airports": [
                                {
                                    "Id": "OKJ",
                                    "Name": "Okayama",
                                    "CityId": "JOKJ",
                                    "CountryId": "JP",
                                    "Location": "133.85528, 34.756944"
                                }
                            ]
                        },
                        {
                            "Id": "JSDJ",
                            "Name": "Sendai",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.88472, 38.254724",
                            "IataCode": "SDJ",
                            "Airports": [
                                {
                                    "Id": "SDJ",
                                    "Name": "Sendai",
                                    "CityId": "JSDJ",
                                    "CountryId": "JP",
                                    "Location": "140.92389, 38.135556"
                                }
                            ]
                        },
                        {
                            "Id": "JMMJ",
                            "Name": "Matsumoto",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "137.96667, 36.233334",
                            "IataCode": "MMJ",
                            "Airports": [
                                {
                                    "Id": "MMJ",
                                    "Name": "Matsumoto",
                                    "CityId": "JMMJ",
                                    "CountryId": "JP",
                                    "Location": "137.92278, 36.166667"
                                }
                            ]
                        },
                        {
                            "Id": "JTOY",
                            "Name": "Toyama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "137.21667, 36.683333",
                            "IataCode": "TOY",
                            "Airports": [
                                {
                                    "Id": "TOY",
                                    "Name": "Toyama",
                                    "CityId": "JTOY",
                                    "CountryId": "JP",
                                    "Location": "137.18889, 36.644167"
                                }
                            ]
                        },
                        {
                            "Id": "JUBJ",
                            "Name": "Ube",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.25111, 33.943056",
                            "IataCode": "UBJ",
                            "Airports": [
                                {
                                    "Id": "UBJ",
                                    "Name": "Yamaguchi Ube",
                                    "CityId": "JUBJ",
                                    "CountryId": "JP",
                                    "Location": "131.27444, 33.925833"
                                }
                            ]
                        },
                        {
                            "Id": "JMSJ",
                            "Name": "Misawa",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "141.35972, 40.683611",
                            "IataCode": "MSJ",
                            "Airports": [
                                {
                                    "Id": "MSJ",
                                    "Name": "Misawa",
                                    "CityId": "JMSJ",
                                    "CountryId": "JP",
                                    "Location": "141.37194, 40.700278"
                                }
                            ]
                        },
                        {
                            "Id": "JNJA",
                            "Name": "Atsugi",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.35972, 35.438889",
                            "IataCode": "NJA",
                            "Airports": [
                                {
                                    "Id": "NJA",
                                    "Name": "Atsugi",
                                    "CityId": "JNJA",
                                    "CountryId": "JP",
                                    "Location": "139.35, 35.416667"
                                }
                            ]
                        },
                        {
                            "Id": "JNTQ",
                            "Name": "Wajima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "136.9, 37.4",
                            "IataCode": "NTQ",
                            "Airports": [
                                {
                                    "Id": "NTQ",
                                    "Name": "Wajima",
                                    "CityId": "JNTQ",
                                    "CountryId": "JP",
                                    "Location": "136.96528, 37.290278"
                                }
                            ]
                        },
                        {
                            "Id": "JUKY",
                            "Name": "Kyoto",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "135.75385, 35.021073",
                            "IataCode": "UKY",
                            "Airports": [
                                {
                                    "Id": "UKY",
                                    "Name": "Kyoto",
                                    "CityId": "JUKY",
                                    "CountryId": "JP",
                                    "Location": "135.75, 35"
                                }
                            ]
                        },
                        {
                            "Id": "JTJH",
                            "Name": "Toyooka",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "134.83333, 35.533333",
                            "IataCode": "TJH",
                            "Airports": [
                                {
                                    "Id": "TJH",
                                    "Name": "Toyooka",
                                    "CityId": "JTJH",
                                    "CountryId": "JP",
                                    "Location": "134.78972, 35.509167"
                                }
                            ]
                        },
                        {
                            "Id": "JIHA",
                            "Name": "Niihama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "133.26667, 33.966667",
                            "IataCode": "IHA",
                            "Airports": [
                                {
                                    "Id": "IHA",
                                    "Name": "Niihama",
                                    "CityId": "JIHA",
                                    "CountryId": "JP",
                                    "Location": "133.26667, 33.916667"
                                }
                            ]
                        },
                        {
                            "Id": "JMBE",
                            "Name": "Monbetsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "143.3525, 44.3525",
                            "IataCode": "MBE",
                            "Airports": [
                                {
                                    "Id": "MBE",
                                    "Name": "Monbetsu",
                                    "CityId": "JMBE",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "143.38333, 44.266667"
                                }
                            ]
                        },
                        {
                            "Id": "JTAK",
                            "Name": "Takamatsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "134.05, 34.333333",
                            "IataCode": "TAK",
                            "Airports": [
                                {
                                    "Id": "TAK",
                                    "Name": "Takamatsu",
                                    "CityId": "JTAK",
                                    "CountryId": "JP",
                                    "Location": "134.01556, 34.214167"
                                }
                            ]
                        },
                        {
                            "Id": "JTKS",
                            "Name": "Tokushima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "134.56667, 34.066667",
                            "IataCode": "TKS",
                            "Airports": [
                                {
                                    "Id": "TKS",
                                    "Name": "Tokushima",
                                    "CityId": "JTKS",
                                    "CountryId": "JP",
                                    "Location": "134.60167, 34.129722"
                                }
                            ]
                        },
                        {
                            "Id": "JTTJ",
                            "Name": "Tottori",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "134.23333, 35.5",
                            "IataCode": "TTJ",
                            "Airports": [
                                {
                                    "Id": "TTJ",
                                    "Name": "Tottori",
                                    "CityId": "JTTJ",
                                    "CountryId": "JP",
                                    "Location": "134.17028, 35.526667"
                                }
                            ]
                        },
                        {
                            "Id": "JISG",
                            "Name": "Ishigaki",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "124.26592, 24.501602",
                            "IataCode": "ISG",
                            "Airports": [
                                {
                                    "Id": "ISG",
                                    "Name": "Ishigaki",
                                    "CityId": "JISG",
                                    "CountryId": "JP",
                                    "Location": "124.16889, 24.336944"
                                }
                            ]
                        },
                        {
                            "Id": "JKKJ",
                            "Name": "Kita Kyushu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.83333, 33.833333",
                            "IataCode": "KKJ",
                            "Airports": [
                                {
                                    "Id": "KKJ",
                                    "Name": "Kita Kyushu",
                                    "CityId": "JKKJ",
                                    "CountryId": "JP",
                                    "Location": "130.94944, 33.833056"
                                }
                            ]
                        },
                        {
                            "Id": "JXEI",
                            "Name": "Tsukuba",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.1, 36.2",
                            "IataCode": "XEI",
                            "Airports": [
                                {
                                    "Id": "XEI",
                                    "Name": "Tsukuba",
                                    "CityId": "JXEI",
                                    "CountryId": "JP",
                                    "Location": "140.1, 36.21667"
                                }
                            ]
                        },
                        {
                            "Id": "JOMJ",
                            "Name": "Omura",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "129.95389, 32.921389",
                            "IataCode": "OMJ",
                            "Airports": [
                                {
                                    "Id": "OMJ",
                                    "Name": "Omura",
                                    "CityId": "JOMJ",
                                    "CountryId": "JP",
                                    "Location": "129.93611, 32.924444"
                                }
                            ]
                        },
                        {
                            "Id": "JSHM",
                            "Name": "Shirahama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "135.35, 33.683333",
                            "IataCode": "SHM",
                            "Airports": [
                                {
                                    "Id": "SHM",
                                    "Name": "Shirahama",
                                    "CityId": "JSHM",
                                    "CountryId": "JP",
                                    "Location": "135.35806, 33.661389"
                                }
                            ]
                        },
                        {
                            "Id": "JKUJ",
                            "Name": "Kushimoto",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "135.78333, 33.466667",
                            "IataCode": "KUJ",
                            "Airports": [
                                {
                                    "Id": "KUJ",
                                    "Name": "Kushimoto",
                                    "CityId": "JKUJ",
                                    "CountryId": "JP",
                                    "Location": "135.78333, 33.433333"
                                }
                            ]
                        },
                        {
                            "Id": "IWKU",
                            "Name": "Iwakuni",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "132.18333, 34.15",
                            "IataCode": "IWK",
                            "Airports": [
                                {
                                    "Id": "IWK",
                                    "Name": "Iwakuni",
                                    "CityId": "IWKU",
                                    "CountryId": "JP",
                                    "Location": "132.23667, 34.158587"
                                }
                            ]
                        },
                        {
                            "Id": "JIWJ",
                            "Name": "Iwami",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.03333, 34.366667",
                            "IataCode": "IWJ",
                            "Airports": [
                                {
                                    "Id": "IWJ",
                                    "Name": "Iwami",
                                    "CityId": "JIWJ",
                                    "CountryId": "JP",
                                    "Location": "131.79278, 34.673056"
                                }
                            ]
                        },
                        {
                            "Id": "JAGJ",
                            "Name": "Aguni",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "127.22139, 26.576389",
                            "IataCode": "AGJ",
                            "Airports": [
                                {
                                    "Id": "AGJ",
                                    "Name": "Aguni",
                                    "CityId": "JAGJ",
                                    "CountryId": "JP",
                                    "Location": "127.23806, 26.589722"
                                }
                            ]
                        },
                        {
                            "Id": "JHTR",
                            "Name": "Hateruma",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "123.78333, 24.05",
                            "IataCode": "HTR",
                            "Airports": [
                                {
                                    "Id": "HTR",
                                    "Name": "Hateruma",
                                    "CityId": "JHTR",
                                    "CountryId": "JP",
                                    "Location": "123.8, 24.05"
                                }
                            ]
                        },
                        {
                            "Id": "JMMD",
                            "Name": "Minami Daito",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.25, 25.819444",
                            "IataCode": "MMD",
                            "Airports": [
                                {
                                    "Id": "MMD",
                                    "Name": "Minami Daito",
                                    "CityId": "JMMD",
                                    "CountryId": "JP",
                                    "Location": "131.24722, 25.841389"
                                }
                            ]
                        },
                        {
                            "Id": "JOIR",
                            "Name": "Okushiri",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "139.51833, 42.168333",
                            "IataCode": "OIR",
                            "Airports": [
                                {
                                    "Id": "OIR",
                                    "Name": "Okushiri",
                                    "CityId": "JOIR",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "139.43291, 42.07167"
                                }
                            ]
                        },
                        {
                            "Id": "JHNA",
                            "Name": "Hanamaki",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "141.11667, 39.383333",
                            "IataCode": "HNA",
                            "Airports": [
                                {
                                    "Id": "HNA",
                                    "Name": "Hanamaki",
                                    "CityId": "JHNA",
                                    "CountryId": "JP",
                                    "Location": "141.13528, 39.42861"
                                }
                            ]
                        },
                        {
                            "Id": "JONJ",
                            "Name": "Odate Noshiro",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.56833, 40.268611",
                            "IataCode": "ONJ",
                            "Airports": [
                                {
                                    "Id": "ONJ",
                                    "Name": "Odate Noshiro",
                                    "CityId": "JONJ",
                                    "CountryId": "JP",
                                    "Location": "140.37528, 40.189167"
                                }
                            ]
                        },
                        {
                            "Id": "JSHB",
                            "Name": "Nakashibetsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "144.98333, 43.55",
                            "IataCode": "SHB",
                            "Airports": [
                                {
                                    "Id": "SHB",
                                    "Name": "Nakashibetsu",
                                    "CityId": "JSHB",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "144.96667, 43.566667"
                                }
                            ]
                        },
                        {
                            "Id": "JMMB",
                            "Name": "Memanbetsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "144.17389, 43.907222",
                            "IataCode": "MMB",
                            "Airports": [
                                {
                                    "Id": "MMB",
                                    "Name": "Memanbetsu",
                                    "CityId": "JMMB",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "144.16722, 43.9"
                                }
                            ]
                        },
                        {
                            "Id": "IBRA",
                            "Name": "Omitama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.37962, 36.25449",
                            "IataCode": "IBR",
                            "Airports": [
                                {
                                    "Id": "IBR",
                                    "Name": "Omitama",
                                    "CityId": "IBRA",
                                    "CountryId": "JP",
                                    "Location": "140.41472, 36.181667"
                                }
                            ]
                        },
                        {
                            "Id": "JUEO",
                            "Name": "Kumejima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "126.67311, 26.367635",
                            "IataCode": "UEO",
                            "Airports": [
                                {
                                    "Id": "UEO",
                                    "Name": "Kumejima",
                                    "CityId": "JUEO",
                                    "CountryId": "JP",
                                    "Location": "126.71944, 26.365278"
                                }
                            ]
                        },
                        {
                            "Id": "JAXT",
                            "Name": "Akita",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "140.10333, 39.71806",
                            "IataCode": "AXT",
                            "Airports": [
                                {
                                    "Id": "AXT",
                                    "Name": "Akita",
                                    "CityId": "JAXT",
                                    "CountryId": "JP",
                                    "Location": "140.21861, 39.615556"
                                }
                            ]
                        },
                        {
                            "Id": "JRIS",
                            "Name": "Rishiri",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "141.19629, 45.15928",
                            "IataCode": "RIS",
                            "Airports": [
                                {
                                    "Id": "RIS",
                                    "Name": "Rishiri",
                                    "CityId": "JRIS",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "141.25, 45.183333"
                                }
                            ]
                        },
                        {
                            "Id": "JRBJ",
                            "Name": "Rebun",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "RegionId": "HOKKA",
                            "Location": "141.017, 45.35",
                            "IataCode": "RBJ",
                            "Airports": [
                                {
                                    "Id": "RBJ",
                                    "Name": "Rebun",
                                    "CityId": "JRBJ",
                                    "CountryId": "JP",
                                    "RegionId": "HOKKA",
                                    "Location": "141.03333, 45.383333"
                                }
                            ]
                        },
                        {
                            "Id": "JAMX",
                            "Name": "Amakusa",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.117, 32.4",
                            "IataCode": "AXJ",
                            "Airports": [
                                {
                                    "Id": "AXJ",
                                    "Name": "Amakusa Airport",
                                    "CityId": "JAMX",
                                    "CountryId": "JP",
                                    "Location": "130.15867, 32.48276"
                                }
                            ]
                        },
                        {
                            "Id": "JIKI",
                            "Name": "Iki",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "129.717, 33.783",
                            "IataCode": "IKI",
                            "Airports": [
                                {
                                    "Id": "IKI",
                                    "Name": "Iki",
                                    "CityId": "JIKI",
                                    "CountryId": "JP",
                                    "Location": "129.78833, 33.745556"
                                }
                            ]
                        },
                        {
                            "Id": "JTSJ",
                            "Name": "Tsushima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "129.333, 34.417",
                            "IataCode": "TSJ",
                            "Airports": [
                                {
                                    "Id": "TSJ",
                                    "Name": "Tsushima",
                                    "CityId": "JTSJ",
                                    "CountryId": "JP",
                                    "Location": "129.31667, 34.283333"
                                }
                            ]
                        },
                        {
                            "Id": "JTNE",
                            "Name": "Tanegashima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.98111, 30.57389",
                            "IataCode": "TNE",
                            "Airports": [
                                {
                                    "Id": "TNE",
                                    "Name": "Tanegashima",
                                    "CityId": "JTNE",
                                    "CountryId": "JP",
                                    "Location": "130.95417, 30.541111"
                                }
                            ]
                        },
                        {
                            "Id": "JKUM",
                            "Name": "Yakushima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130.52861, 30.35861",
                            "IataCode": "KUM",
                            "Airports": [
                                {
                                    "Id": "KUM",
                                    "Name": "Yakushima",
                                    "CityId": "JKUM",
                                    "CountryId": "JP",
                                    "Location": "130.63333, 30.333333"
                                }
                            ]
                        },
                        {
                            "Id": "JASJ",
                            "Name": "Amami O Shima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "129.367, 28.283",
                            "IataCode": "ASJ",
                            "Airports": [
                                {
                                    "Id": "ASJ",
                                    "Name": "Amami O Shima",
                                    "CityId": "JASJ",
                                    "CountryId": "JP",
                                    "Location": "129.6975, 28.416944"
                                }
                            ]
                        },
                        {
                            "Id": "JTKN",
                            "Name": "Tokunoshima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "128.95694, 27.77472",
                            "IataCode": "TKN",
                            "Airports": [
                                {
                                    "Id": "TKN",
                                    "Name": "Tokunoshima",
                                    "CityId": "JTKN",
                                    "CountryId": "JP",
                                    "Location": "128.88333, 27.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JRNJ",
                            "Name": "Yoronjima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "128.41722, 27.04444",
                            "IataCode": "RNJ",
                            "Airports": [
                                {
                                    "Id": "RNJ",
                                    "Name": "Yoronjima",
                                    "CityId": "JRNJ",
                                    "CountryId": "JP",
                                    "Location": "128.43333, 27.033333"
                                }
                            ]
                        },
                        {
                            "Id": "JIEJ",
                            "Name": "Iejima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "127.79028, 26.71611",
                            "IataCode": "IEJ",
                            "Airports": [
                                {
                                    "Id": "IEJ",
                                    "Name": "Iejima",
                                    "CityId": "JIEJ",
                                    "CountryId": "JP",
                                    "Location": "127.8, 26.733333"
                                }
                            ]
                        },
                        {
                            "Id": "JKTD",
                            "Name": "Kitadaito",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "131.3, 25.95",
                            "IataCode": "KTD",
                            "Airports": [
                                {
                                    "Id": "KTD",
                                    "Name": "Kitadaito",
                                    "CityId": "JKTD",
                                    "CountryId": "JP",
                                    "Location": "131.3, 25.95"
                                }
                            ]
                        },
                        {
                            "Id": "JMMY",
                            "Name": "Miyako Jima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "125.317, 24.767",
                            "IataCode": "MMY",
                            "Airports": [
                                {
                                    "Id": "MMY",
                                    "Name": "Miyako Jima",
                                    "CityId": "JMMY",
                                    "CountryId": "JP",
                                    "Location": "125.29722, 24.780833"
                                }
                            ]
                        },
                        {
                            "Id": "JTRA",
                            "Name": "Taramajima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "124.70167, 24.66944",
                            "IataCode": "TRA",
                            "Airports": [
                                {
                                    "Id": "TRA",
                                    "Name": "Taramajima",
                                    "CityId": "JTRA",
                                    "CountryId": "JP",
                                    "Location": "124.7, 24.65"
                                }
                            ]
                        },
                        {
                            "Id": "JOGN",
                            "Name": "Yonaguni Jima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "122.93361, 24.44944",
                            "IataCode": "OGN",
                            "Airports": [
                                {
                                    "Id": "OGN",
                                    "Name": "Yonaguni Jima",
                                    "CityId": "JOGN",
                                    "CountryId": "JP",
                                    "Location": "122.97639, 24.461111"
                                }
                            ]
                        },
                        {
                            "Id": "JOIM",
                            "Name": "Oshima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.367, 34.733",
                            "IataCode": "OIM",
                            "Airports": [
                                {
                                    "Id": "OIM",
                                    "Name": "Oshima",
                                    "CityId": "JOIM",
                                    "CountryId": "JP",
                                    "Location": "139.365, 34.779167"
                                }
                            ]
                        },
                        {
                            "Id": "JMYE",
                            "Name": "Miyake Jima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.529, 34.079",
                            "IataCode": "MYE",
                            "Airports": [
                                {
                                    "Id": "MYE",
                                    "Name": "Miyake Jima",
                                    "CityId": "JMYE",
                                    "CountryId": "JP",
                                    "Location": "139.5625, 34.069444"
                                }
                            ]
                        },
                        {
                            "Id": "JIWO",
                            "Name": "Iwo Jima Vol",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "141.317, 24.783",
                            "IataCode": "IWO",
                            "Airports": [
                                {
                                    "Id": "IWO",
                                    "Name": "Iwo Jima Vol",
                                    "CityId": "JIWO",
                                    "CountryId": "JP",
                                    "Location": "141.31667, 24.783333"
                                }
                            ]
                        },
                        {
                            "Id": "JMUS",
                            "Name": "Marcus Island",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "153.98639, 24.28306",
                            "IataCode": "MUS",
                            "Airports": [
                                {
                                    "Id": "MUS",
                                    "Name": "Marcus Island",
                                    "CityId": "JMUS",
                                    "CountryId": "JP",
                                    "Location": "153.97917, 24.289722"
                                }
                            ]
                        },
                        {
                            "Id": "JOKI",
                            "Name": "Oki Island",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "133.11477, 36.125481",
                            "IataCode": "OKI",
                            "Airports": [
                                {
                                    "Id": "OKI",
                                    "Name": "Oki Island",
                                    "CityId": "JOKI",
                                    "CountryId": "JP",
                                    "Location": "133.31667, 36.166667"
                                }
                            ]
                        },
                        {
                            "Id": "JMYJ",
                            "Name": "Matsuyama",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "132.78623, 33.85265",
                            "IataCode": "MYJ",
                            "Airports": [
                                {
                                    "Id": "MYJ",
                                    "Name": "Matsuyama",
                                    "CityId": "JMYJ",
                                    "CountryId": "JP",
                                    "Location": "132.70417, 33.822222"
                                }
                            ]
                        },
                        {
                            "Id": "JSDO",
                            "Name": "Ryōtsu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "138.43333, 38.083333",
                            "IataCode": "SDO",
                            "Airports": [
                                {
                                    "Id": "SDO",
                                    "Name": "Ryotsu Sado Is",
                                    "CityId": "JSDO",
                                    "CountryId": "JP",
                                    "Location": "138.41667, 38.133333"
                                }
                            ]
                        },
                        {
                            "Id": "JSYO",
                            "Name": "Sakata",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.855, 38.916667",
                            "IataCode": "SYO",
                            "Airports": [
                                {
                                    "Id": "SYO",
                                    "Name": "Shonai",
                                    "CityId": "JSYO",
                                    "CountryId": "JP",
                                    "Location": "139.79056, 38.809444"
                                }
                            ]
                        },
                        {
                            "Id": "JKKX",
                            "Name": "Onozu",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "130, 28.366667",
                            "IataCode": "KKX",
                            "Airports": [
                                {
                                    "Id": "KKX",
                                    "Name": "Kikaiga Shima",
                                    "CityId": "JKKX",
                                    "CountryId": "JP",
                                    "Location": "130.00833, 28.433333"
                                }
                            ]
                        },
                        {
                            "Id": "JOKE",
                            "Name": "Nishibaru",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "128.58333, 27.4",
                            "IataCode": "OKE",
                            "Airports": [
                                {
                                    "Id": "OKE",
                                    "Name": "Okino Erabu",
                                    "CityId": "JOKE",
                                    "CountryId": "JP",
                                    "Location": "128.61667, 27.433333"
                                }
                            ]
                        },
                        {
                            "Id": "JKJP",
                            "Name": "Awaren",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "127.34028, 26.170556",
                            "IataCode": "KJP",
                            "Airports": [
                                {
                                    "Id": "KJP",
                                    "Name": "Kerama",
                                    "CityId": "JKJP",
                                    "CountryId": "JP",
                                    "Location": "127.33333, 26.083333"
                                }
                            ]
                        },
                        {
                            "Id": "JSDS",
                            "Name": "Sado",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "138.35931, 38.024962",
                            "IataCode": "SDS",
                            "Airports": [
                                {
                                    "Id": "SDS",
                                    "Name": "Sado Shima",
                                    "CityId": "JSDS",
                                    "CountryId": "JP",
                                    "Location": "138.41667, 38"
                                }
                            ]
                        },
                        {
                            "Id": "JHAC",
                            "Name": "Hachijo Jima",
                            "SingleAirportCity": true,
                            "CountryId": "JP",
                            "Location": "139.79056, 33.113333",
                            "IataCode": "HAC",
                            "Airports": [
                                {
                                    "Id": "HAC",
                                    "Name": "Hachijo Jima",
                                    "CityId": "JHAC",
                                    "CountryId": "JP",
                                    "Location": "139.79056, 33.113333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NP",
                    "Name": "Nepal",
                    "CurrencyId": "NPR",
                    "Cities": [
                        {
                            "Id": "XMGA",
                            "Name": "Mahendranagar",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "80.333333, 28.916667",
                            "IataCode": "XMG",
                            "Airports": [
                                {
                                    "Id": "XMG",
                                    "Name": "Mahendranagar",
                                    "CityId": "XMGA",
                                    "CountryId": "NP",
                                    "Location": "80.2, 28.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BGLA",
                            "Name": "Baglung",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "83.6, 28.266667",
                            "IataCode": "BGL",
                            "Airports": [
                                {
                                    "Id": "BGL",
                                    "Name": "Baglung",
                                    "CityId": "BGLA",
                                    "CountryId": "NP",
                                    "Location": "83.683333, 28.215278"
                                }
                            ]
                        },
                        {
                            "Id": "BITA",
                            "Name": "Baitadi",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "80.433333, 29.533333",
                            "IataCode": "BIT",
                            "Airports": [
                                {
                                    "Id": "BIT",
                                    "Name": "Baitadi",
                                    "CityId": "BITA",
                                    "CountryId": "NP",
                                    "Location": "80.5492, 29.4653"
                                }
                            ]
                        },
                        {
                            "Id": "DHIA",
                            "Name": "Dhangarhi",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "80.6, 28.683333",
                            "IataCode": "DHI",
                            "Airports": [
                                {
                                    "Id": "DHI",
                                    "Name": "Dhangarhi",
                                    "CityId": "DHIA",
                                    "CountryId": "NP",
                                    "Location": "80.633333, 28.683333"
                                }
                            ]
                        },
                        {
                            "Id": "BHPA",
                            "Name": "Bhojpur",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "87.05, 27.166667",
                            "IataCode": "BHP",
                            "Airports": [
                                {
                                    "Id": "BHP",
                                    "Name": "Bhojpur",
                                    "CityId": "BHPA",
                                    "CountryId": "NP",
                                    "Location": "87.0508, 27.1474"
                                }
                            ]
                        },
                        {
                            "Id": "BHRA",
                            "Name": "Bharatpur",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "84.433334, 27.683333",
                            "IataCode": "BHR",
                            "Airports": [
                                {
                                    "Id": "BHR",
                                    "Name": "Bharatpur",
                                    "CityId": "BHRA",
                                    "CountryId": "NP",
                                    "Location": "84.4325, 27.676389"
                                }
                            ]
                        },
                        {
                            "Id": "BIRA",
                            "Name": "Biratnagar",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "87.283333, 26.483333",
                            "IataCode": "BIR",
                            "Airports": [
                                {
                                    "Id": "BIR",
                                    "Name": "Biratnagar",
                                    "CityId": "BIRA",
                                    "CountryId": "NP",
                                    "Location": "87.283333, 26.434722"
                                }
                            ]
                        },
                        {
                            "Id": "BJHA",
                            "Name": "Bajhang",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.083333, 29.533333",
                            "IataCode": "BJH",
                            "Airports": [
                                {
                                    "Id": "BJH",
                                    "Name": "Bajhang",
                                    "CityId": "BJHA",
                                    "CountryId": "NP",
                                    "Location": "81.25, 29.55"
                                }
                            ]
                        },
                        {
                            "Id": "BJUA",
                            "Name": "Bajura",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.316667, 29.383333",
                            "IataCode": "BJU",
                            "Airports": [
                                {
                                    "Id": "BJU",
                                    "Name": "Bajura",
                                    "CityId": "BJUA",
                                    "CountryId": "NP",
                                    "Location": "81.669, 29.502"
                                }
                            ]
                        },
                        {
                            "Id": "BWAA",
                            "Name": "Bhairawa",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "83.45, 27.5",
                            "IataCode": "BWA",
                            "Airports": [
                                {
                                    "Id": "BWA",
                                    "Name": "Bhairawa",
                                    "CityId": "BWAA",
                                    "CountryId": "NP",
                                    "Location": "83.42, 27.504167"
                                }
                            ]
                        },
                        {
                            "Id": "DAPA",
                            "Name": "Darchula",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "80.55, 29.85",
                            "IataCode": "DAP",
                            "Airports": [
                                {
                                    "Id": "DAP",
                                    "Name": "Darchula",
                                    "CityId": "DAPA",
                                    "CountryId": "NP",
                                    "Location": "80.5, 29.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DNPA",
                            "Name": "Dang",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "82.3, 28.116667",
                            "IataCode": "DNP",
                            "Airports": [
                                {
                                    "Id": "DNP",
                                    "Name": "Dang",
                                    "CityId": "DNPA",
                                    "CountryId": "NP",
                                    "Location": "82.2942, 28.1111"
                                }
                            ]
                        },
                        {
                            "Id": "GKHA",
                            "Name": "Gorkha",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "84.633333, 28",
                            "IataCode": "GKH",
                            "Airports": [
                                {
                                    "Id": "GKH",
                                    "Name": "Gorkha",
                                    "CityId": "GKHA",
                                    "CountryId": "NP",
                                    "Location": "84.675, 28"
                                }
                            ]
                        },
                        {
                            "Id": "IMKA",
                            "Name": "Simikot",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.833333, 29.966667",
                            "IataCode": "IMK",
                            "Airports": [
                                {
                                    "Id": "IMK",
                                    "Name": "Simikot",
                                    "CityId": "IMKA",
                                    "CountryId": "NP",
                                    "Location": "81.816667, 29.966667"
                                }
                            ]
                        },
                        {
                            "Id": "JIRA",
                            "Name": "Jiri",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.233333, 27.633333",
                            "IataCode": "JIR",
                            "Airports": [
                                {
                                    "Id": "JIR",
                                    "Name": "Jiri",
                                    "CityId": "JIRA",
                                    "CountryId": "NP",
                                    "Location": "86.233333, 27.633333"
                                }
                            ]
                        },
                        {
                            "Id": "JKRA",
                            "Name": "Janakpur",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "85.916667, 26.7",
                            "IataCode": "JKR",
                            "Airports": [
                                {
                                    "Id": "JKR",
                                    "Name": "Janakpur",
                                    "CityId": "JKRA",
                                    "CountryId": "NP",
                                    "Location": "85.923889, 26.708333"
                                }
                            ]
                        },
                        {
                            "Id": "JUMA",
                            "Name": "Jumla",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "82.166667, 29.283333",
                            "IataCode": "JUM",
                            "Airports": [
                                {
                                    "Id": "JUM",
                                    "Name": "Jumla",
                                    "CityId": "JUMA",
                                    "CountryId": "NP",
                                    "Location": "82.191944, 29.273611"
                                }
                            ]
                        },
                        {
                            "Id": "KTMA",
                            "Name": "Kathmandu",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "85.316667, 27.716669",
                            "IataCode": "KTM",
                            "Airports": [
                                {
                                    "Id": "KTM",
                                    "Name": "Kathmandu",
                                    "CityId": "KTMA",
                                    "CountryId": "NP",
                                    "Location": "85.363056, 27.700556"
                                }
                            ]
                        },
                        {
                            "Id": "LTGA",
                            "Name": "Langtang",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "85.583333, 28.216667",
                            "IataCode": "LTG",
                            "Airports": [
                                {
                                    "Id": "LTG",
                                    "Name": "Langtang",
                                    "CityId": "LTGA",
                                    "CountryId": "NP",
                                    "Location": "85.6, 28.233333"
                                }
                            ]
                        },
                        {
                            "Id": "MEYA",
                            "Name": "Meghauli",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "84.233333, 27.583333",
                            "IataCode": "MEY",
                            "Airports": [
                                {
                                    "Id": "MEY",
                                    "Name": "Meghauli",
                                    "CityId": "MEYA",
                                    "CountryId": "NP",
                                    "Location": "84.231389, 27.576389"
                                }
                            ]
                        },
                        {
                            "Id": "PKRA",
                            "Name": "Pokhara",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "83.983333, 28.233333",
                            "IataCode": "PKR",
                            "Airports": [
                                {
                                    "Id": "PKR",
                                    "Name": "Pokhara",
                                    "CityId": "PKRA",
                                    "CountryId": "NP",
                                    "Location": "83.986111, 28.197222"
                                }
                            ]
                        },
                        {
                            "Id": "PPLA",
                            "Name": "Phaplu",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.583333, 27.516667",
                            "IataCode": "PPL",
                            "Airports": [
                                {
                                    "Id": "PPL",
                                    "Name": "Phaplu",
                                    "CityId": "PPLA",
                                    "CountryId": "NP",
                                    "Location": "86.6, 27.516667"
                                }
                            ]
                        },
                        {
                            "Id": "RHPA",
                            "Name": "Ramechhap",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.083333, 27.333333",
                            "IataCode": "RHP",
                            "Airports": [
                                {
                                    "Id": "RHP",
                                    "Name": "Ramechhap",
                                    "CityId": "RHPA",
                                    "CountryId": "NP",
                                    "Location": "86.083333, 27.333333"
                                }
                            ]
                        },
                        {
                            "Id": "RUKA",
                            "Name": "Rukumkot",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "82.616667, 28.616667",
                            "IataCode": "RUK",
                            "Airports": [
                                {
                                    "Id": "RUK",
                                    "Name": "Rukumkot",
                                    "CityId": "RUKA",
                                    "CountryId": "NP",
                                    "Location": "82.195, 28.627"
                                }
                            ]
                        },
                        {
                            "Id": "RUMA",
                            "Name": "Rumjatar",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.55, 27.3",
                            "IataCode": "RUM",
                            "Airports": [
                                {
                                    "Id": "RUM",
                                    "Name": "Rumjatar",
                                    "CityId": "RUMA",
                                    "CountryId": "NP",
                                    "Location": "86.55128, 27.302384"
                                }
                            ]
                        },
                        {
                            "Id": "RJBA",
                            "Name": "Rajbiraj",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.75, 26.533333",
                            "IataCode": "RJB",
                            "Airports": [
                                {
                                    "Id": "RJB",
                                    "Name": "Rajbiraj",
                                    "CityId": "RJBA",
                                    "CountryId": "NP",
                                    "Location": "86.833333, 26.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SIFA",
                            "Name": "Simara",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "84.983333, 27.166667",
                            "IataCode": "SIF",
                            "Airports": [
                                {
                                    "Id": "SIF",
                                    "Name": "Simara",
                                    "CityId": "SIFA",
                                    "CountryId": "NP",
                                    "Location": "84.98012, 27.15946"
                                }
                            ]
                        },
                        {
                            "Id": "SKHA",
                            "Name": "Surkhet",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.6, 28.6",
                            "IataCode": "SKH",
                            "Airports": [
                                {
                                    "Id": "SKH",
                                    "Name": "Surkhet",
                                    "CityId": "SKHA",
                                    "CountryId": "NP",
                                    "Location": "81.61667, 28.6"
                                }
                            ]
                        },
                        {
                            "Id": "TPJA",
                            "Name": "Taplejung",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "87.666667, 27.35",
                            "IataCode": "TPJ",
                            "Airports": [
                                {
                                    "Id": "TPJ",
                                    "Name": "Taplejung",
                                    "CityId": "TPJA",
                                    "CountryId": "NP",
                                    "Location": "87.697391, 27.352057"
                                }
                            ]
                        },
                        {
                            "Id": "TPUA",
                            "Name": "Tikapur",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.133333, 28.5",
                            "IataCode": "TPU",
                            "Airports": [
                                {
                                    "Id": "TPU",
                                    "Name": "Tikapur",
                                    "CityId": "TPUA",
                                    "CountryId": "NP",
                                    "Location": "81.191667, 28.55"
                                }
                            ]
                        },
                        {
                            "Id": "NGXA",
                            "Name": "Manang",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "84.016667, 28.666667",
                            "IataCode": "NGX",
                            "Airports": [
                                {
                                    "Id": "NGX",
                                    "Name": "Manang",
                                    "CityId": "NGXA",
                                    "CountryId": "NP",
                                    "Location": "84.086375, 28.641696"
                                }
                            ]
                        },
                        {
                            "Id": "DOPA",
                            "Name": "Dolpa",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "83.083333, 29.166667",
                            "IataCode": "DOP",
                            "Airports": [
                                {
                                    "Id": "DOP",
                                    "Name": "Dolpa",
                                    "CityId": "DOPA",
                                    "CountryId": "NP",
                                    "Location": "82.816667, 29"
                                }
                            ]
                        },
                        {
                            "Id": "TMIA",
                            "Name": "Tumling Tar",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "87.2, 27.3",
                            "IataCode": "TMI",
                            "Airports": [
                                {
                                    "Id": "TMI",
                                    "Name": "Tumling Tar",
                                    "CityId": "TMIA",
                                    "CountryId": "NP",
                                    "Location": "87.2, 27.3"
                                }
                            ]
                        },
                        {
                            "Id": "KEPA",
                            "Name": "Nepalganj",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.616667, 28.05",
                            "IataCode": "KEP",
                            "Airports": [
                                {
                                    "Id": "KEP",
                                    "Name": "Nepalganj",
                                    "CityId": "KEPA",
                                    "CountryId": "NP",
                                    "Location": "81.576389, 28.1325"
                                }
                            ]
                        },
                        {
                            "Id": "LUAA",
                            "Name": "Lukla",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.716667, 27.7",
                            "IataCode": "LUA",
                            "Airports": [
                                {
                                    "Id": "LUA",
                                    "Name": "Lukla",
                                    "CityId": "LUAA",
                                    "CountryId": "NP",
                                    "Location": "86.733333, 27.683333"
                                }
                            ]
                        },
                        {
                            "Id": "JMOA",
                            "Name": "Jomsom",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "83.733333, 28.783333",
                            "IataCode": "JMO",
                            "Airports": [
                                {
                                    "Id": "JMO",
                                    "Name": "Jomsom",
                                    "CityId": "JMOA",
                                    "CountryId": "NP",
                                    "Location": "83.733333, 28.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BDPA",
                            "Name": "Bhadrapur",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "88.083333, 26.533333",
                            "IataCode": "BDP",
                            "Airports": [
                                {
                                    "Id": "BDP",
                                    "Name": "Bhadrapur",
                                    "CityId": "BDPA",
                                    "CountryId": "NP",
                                    "Location": "88.083333, 26.533333"
                                }
                            ]
                        },
                        {
                            "Id": "MWPA",
                            "Name": "Yārsa",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "85.316667, 28",
                            "IataCode": "MWP",
                            "Airports": [
                                {
                                    "Id": "MWP",
                                    "Name": "Mountain",
                                    "CityId": "MWPA",
                                    "CountryId": "NP",
                                    "Location": "85.333333, 28"
                                }
                            ]
                        },
                        {
                            "Id": "SIHA",
                            "Name": "Silgarhi-Dotī",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "80.983333, 29.266667",
                            "IataCode": "SIH",
                            "Airports": [
                                {
                                    "Id": "SIH",
                                    "Name": "Silgadi Doti",
                                    "CityId": "SIHA",
                                    "CountryId": "NP",
                                    "Location": "80.975, 29.266667"
                                }
                            ]
                        },
                        {
                            "Id": "FEBA",
                            "Name": "Ridīkot",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "81.216667, 29.216667",
                            "IataCode": "FEB",
                            "Airports": [
                                {
                                    "Id": "FEB",
                                    "Name": "Sanfebagar",
                                    "CityId": "FEBA",
                                    "CountryId": "NP",
                                    "Location": "81.216667, 29.233333"
                                }
                            ]
                        },
                        {
                            "Id": "HRJA",
                            "Name": "Reār",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "82.333333, 27.9",
                            "IataCode": "HRJ",
                            "Airports": [
                                {
                                    "Id": "HRJ",
                                    "Name": "Chaurjhari",
                                    "CityId": "HRJA",
                                    "CountryId": "NP",
                                    "Location": "82.333333, 28"
                                }
                            ]
                        },
                        {
                            "Id": "SYHA",
                            "Name": "Nāmche Bāzār",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.716667, 27.816667",
                            "IataCode": "SYH",
                            "Airports": [
                                {
                                    "Id": "SYH",
                                    "Name": "Syangboche",
                                    "CityId": "SYHA",
                                    "CountryId": "NP",
                                    "Location": "86.712278, 27.811222"
                                }
                            ]
                        },
                        {
                            "Id": "LDNA",
                            "Name": "Diktel",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "86.783333, 27.2",
                            "IataCode": "LDN",
                            "Airports": [
                                {
                                    "Id": "LDN",
                                    "Name": "Lamidanda",
                                    "CityId": "LDNA",
                                    "CountryId": "NP",
                                    "Location": "86.716667, 27.25"
                                }
                            ]
                        },
                        {
                            "Id": "RPAA",
                            "Name": "Dhankutā",
                            "SingleAirportCity": true,
                            "CountryId": "NP",
                            "Location": "87.333333, 26.983333",
                            "IataCode": "RPA",
                            "Airports": [
                                {
                                    "Id": "RPA",
                                    "Name": "Rolpa",
                                    "CityId": "RPAA",
                                    "CountryId": "NP",
                                    "Location": "87.333333, 26.983333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GE",
                    "Name": "Georgia",
                    "CurrencyId": "GEL",
                    "Cities": [
                        {
                            "Id": "SUIA",
                            "Name": "Sukhumi",
                            "SingleAirportCity": true,
                            "CountryId": "GE",
                            "Location": "41.015278, 43.003333",
                            "IataCode": "SUI",
                            "Airports": [
                                {
                                    "Id": "SUI",
                                    "Name": "Sukhumi",
                                    "CityId": "SUIA",
                                    "CountryId": "GE",
                                    "Location": "41.116667, 42.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BUSA",
                            "Name": "Batumi",
                            "SingleAirportCity": true,
                            "CountryId": "GE",
                            "Location": "41.633611, 41.641946",
                            "IataCode": "BUS",
                            "Airports": [
                                {
                                    "Id": "BUS",
                                    "Name": "Batumi",
                                    "CityId": "BUSA",
                                    "CountryId": "GE",
                                    "Location": "41.600556, 41.610833"
                                }
                            ]
                        },
                        {
                            "Id": "KUTA",
                            "Name": "Kutaisi",
                            "SingleAirportCity": true,
                            "CountryId": "GE",
                            "Location": "42.716389, 42.262222",
                            "IataCode": "KUT",
                            "Airports": [
                                {
                                    "Id": "KUT",
                                    "Name": "Kutaisi",
                                    "CityId": "KUTA",
                                    "CountryId": "GE",
                                    "Location": "42.479925, 42.176285"
                                }
                            ]
                        },
                        {
                            "Id": "TBLI",
                            "Name": "Tbilisi",
                            "SingleAirportCity": true,
                            "CountryId": "GE",
                            "Location": "44.790833, 41.725",
                            "IataCode": "TBS",
                            "Airports": [
                                {
                                    "Id": "TBS",
                                    "Name": "Tbilisi",
                                    "CityId": "TBLI",
                                    "CountryId": "GE",
                                    "Location": "44.95, 41.666667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AM",
                    "Name": "Armenia",
                    "CurrencyId": "AMD",
                    "Cities": [
                        {
                            "Id": "EVNA",
                            "Name": "Yerevan",
                            "SingleAirportCity": true,
                            "CountryId": "AM",
                            "Location": "44.513611, 40.181111",
                            "IataCode": "EVN",
                            "Airports": [
                                {
                                    "Id": "EVN",
                                    "Name": "Yerevan",
                                    "CityId": "EVNA",
                                    "CountryId": "AM",
                                    "Location": "44.4, 40.15"
                                }
                            ]
                        },
                        {
                            "Id": "LWNA",
                            "Name": "Gyoumri",
                            "SingleAirportCity": true,
                            "CountryId": "AM",
                            "Location": "43.8475, 40.789444",
                            "IataCode": "LWN",
                            "Airports": [
                                {
                                    "Id": "LWN",
                                    "Name": "Gyoumri",
                                    "CityId": "LWNA",
                                    "CountryId": "AM",
                                    "Location": "43.86, 40.751667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PK",
                    "Name": "Pakistan",
                    "CurrencyId": "PKR",
                    "Cities": [
                        {
                            "Id": "PZHA",
                            "Name": "Zhob",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "69.448056, 31.341111",
                            "IataCode": "PZH",
                            "Airports": [
                                {
                                    "Id": "PZH",
                                    "Name": "Zhob",
                                    "CityId": "PZHA",
                                    "CountryId": "PK",
                                    "Location": "69.5, 31.333333"
                                }
                            ]
                        },
                        {
                            "Id": "WGBA",
                            "Name": "Bahawalnagar",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.266667, 29.983333",
                            "IataCode": "WGB",
                            "Airports": [
                                {
                                    "Id": "WGB",
                                    "Name": "Bahawalnagar",
                                    "CityId": "WGBA",
                                    "CountryId": "PK",
                                    "Location": "73.25, 30"
                                }
                            ]
                        },
                        {
                            "Id": "BDNA",
                            "Name": "Badin",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.833333, 24.65",
                            "IataCode": "BDN",
                            "Airports": [
                                {
                                    "Id": "BDN",
                                    "Name": "Badin",
                                    "CityId": "BDNA",
                                    "CountryId": "PK",
                                    "Location": "68.9, 24.633333"
                                }
                            ]
                        },
                        {
                            "Id": "OHTA",
                            "Name": "Kohat",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.442222, 33.586944",
                            "IataCode": "OHT",
                            "Airports": [
                                {
                                    "Id": "OHT",
                                    "Name": "Kohat",
                                    "CityId": "OHTA",
                                    "CountryId": "PK",
                                    "Location": "71.433333, 33.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BHVA",
                            "Name": "Bahawalpur",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.683333, 29.4",
                            "IataCode": "BHV",
                            "Airports": [
                                {
                                    "Id": "BHV",
                                    "Name": "Bahawalpur",
                                    "CityId": "BHVA",
                                    "CountryId": "PK",
                                    "Location": "71.683333, 29.4"
                                }
                            ]
                        },
                        {
                            "Id": "BNPA",
                            "Name": "Bannu",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "70.605833, 32.989444",
                            "IataCode": "BNP",
                            "Airports": [
                                {
                                    "Id": "BNP",
                                    "Name": "Bannu",
                                    "CityId": "BNPA",
                                    "CountryId": "PK",
                                    "Location": "70.666667, 33"
                                }
                            ]
                        },
                        {
                            "Id": "CHBA",
                            "Name": "Chilas",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "74.097778, 35.426667",
                            "IataCode": "CHB",
                            "Airports": [
                                {
                                    "Id": "CHB",
                                    "Name": "Chilas",
                                    "CityId": "CHBA",
                                    "CountryId": "PK",
                                    "Location": "74.083333, 35.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CJLA",
                            "Name": "Chitral",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.781944, 35.842222",
                            "IataCode": "CJL",
                            "Airports": [
                                {
                                    "Id": "CJL",
                                    "Name": "Chitral",
                                    "CityId": "CJLA",
                                    "CountryId": "PK",
                                    "Location": "71.794444, 35.886111"
                                }
                            ]
                        },
                        {
                            "Id": "DBAA",
                            "Name": "Dalbandin",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "64.416667, 28.883333",
                            "IataCode": "DBA",
                            "Airports": [
                                {
                                    "Id": "DBA",
                                    "Name": "Dalbandin",
                                    "CityId": "DBAA",
                                    "CountryId": "PK",
                                    "Location": "64.416667, 28.883333"
                                }
                            ]
                        },
                        {
                            "Id": "DDUA",
                            "Name": "Dadu",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "67.783334, 26.733333",
                            "IataCode": "DDU",
                            "Airports": [
                                {
                                    "Id": "DDU",
                                    "Name": "Dadu",
                                    "CityId": "DDUA",
                                    "CountryId": "PK",
                                    "Location": "67.75, 26.75"
                                }
                            ]
                        },
                        {
                            "Id": "DEAA",
                            "Name": "Dera Ghazi Khan",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "70.634444, 30.056111",
                            "IataCode": "DEA",
                            "Airports": [
                                {
                                    "Id": "DEA",
                                    "Name": "Dera Ghazi Khan",
                                    "CityId": "DEAA",
                                    "CountryId": "PK",
                                    "Location": "70.485278, 29.960556"
                                }
                            ]
                        },
                        {
                            "Id": "DSKA",
                            "Name": "Dera Ismail Khan",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "70.9024, 31.83269",
                            "IataCode": "DSK",
                            "Airports": [
                                {
                                    "Id": "DSK",
                                    "Name": "Dera Ismail Khan",
                                    "CityId": "DSKA",
                                    "CountryId": "PK",
                                    "Location": "70.9, 31.916667"
                                }
                            ]
                        },
                        {
                            "Id": "GILA",
                            "Name": "Gilgit",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "74.3, 35.916667",
                            "IataCode": "GIL",
                            "Airports": [
                                {
                                    "Id": "GIL",
                                    "Name": "Gilgit",
                                    "CityId": "GILA",
                                    "CountryId": "PK",
                                    "Location": "74.335833, 35.919167"
                                }
                            ]
                        },
                        {
                            "Id": "GRTA",
                            "Name": "Gujrat",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "74.083333, 32.566667",
                            "IataCode": "GRT",
                            "Airports": [
                                {
                                    "Id": "GRT",
                                    "Name": "Gujrat",
                                    "CityId": "GRTA",
                                    "CountryId": "PK",
                                    "Location": "74.033333, 32.666667"
                                }
                            ]
                        },
                        {
                            "Id": "GWDA",
                            "Name": "Gwadar",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "62.328333, 25.115556",
                            "IataCode": "GWD",
                            "Airports": [
                                {
                                    "Id": "GWD",
                                    "Name": "Gwadar",
                                    "CityId": "GWDA",
                                    "CountryId": "PK",
                                    "Location": "62.338889, 25.230556"
                                }
                            ]
                        },
                        {
                            "Id": "HDDA",
                            "Name": "Hyderabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.366667, 25.366669",
                            "IataCode": "HDD",
                            "Airports": [
                                {
                                    "Id": "HDD",
                                    "Name": "Hyderabad",
                                    "CityId": "HDDA",
                                    "CountryId": "PK",
                                    "Location": "68.363889, 25.322222"
                                }
                            ]
                        },
                        {
                            "Id": "HRAA",
                            "Name": "Mansehra",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.2, 34.333333",
                            "IataCode": "HRA",
                            "Airports": [
                                {
                                    "Id": "HRA",
                                    "Name": "Mansehra",
                                    "CityId": "HRAA",
                                    "CountryId": "PK",
                                    "Location": "73.2, 34.333333"
                                }
                            ]
                        },
                        {
                            "Id": "ISBA",
                            "Name": "Islamabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.059083, 33.610044",
                            "IataCode": "ISB",
                            "Airports": [
                                {
                                    "Id": "ISB",
                                    "Name": "Islamabad",
                                    "CityId": "ISBA",
                                    "CountryId": "PK",
                                    "Location": "72.833562, 33.556232"
                                }
                            ]
                        },
                        {
                            "Id": "JAGA",
                            "Name": "Jacobabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.436111, 28.283889",
                            "IataCode": "JAG",
                            "Airports": [
                                {
                                    "Id": "JAG",
                                    "Name": "Jacobabad",
                                    "CityId": "JAGA",
                                    "CountryId": "PK",
                                    "Location": "68.45, 28.3"
                                }
                            ]
                        },
                        {
                            "Id": "JIWA",
                            "Name": "Jiwani",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "61.741667, 25.05",
                            "IataCode": "JIW",
                            "Airports": [
                                {
                                    "Id": "JIW",
                                    "Name": "Jiwani",
                                    "CityId": "JIWA",
                                    "CountryId": "PK",
                                    "Location": "61.816667, 25.083333"
                                }
                            ]
                        },
                        {
                            "Id": "KDDA",
                            "Name": "Khuzdar",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "66.616667, 27.8",
                            "IataCode": "KDD",
                            "Airports": [
                                {
                                    "Id": "KDD",
                                    "Name": "Khuzdar",
                                    "CityId": "KDDA",
                                    "CountryId": "PK",
                                    "Location": "66.630556, 27.780556"
                                }
                            ]
                        },
                        {
                            "Id": "KDUA",
                            "Name": "Skardu",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "75.616667, 35.3",
                            "IataCode": "KDU",
                            "Airports": [
                                {
                                    "Id": "KDU",
                                    "Name": "Skardu",
                                    "CityId": "KDUA",
                                    "CountryId": "PK",
                                    "Location": "75.733333, 35.3"
                                }
                            ]
                        },
                        {
                            "Id": "KHIA",
                            "Name": "Karachi",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "67.050015, 24.866714",
                            "IataCode": "KHI",
                            "Airports": [
                                {
                                    "Id": "KHI",
                                    "Name": "Karachi",
                                    "CityId": "KHIA",
                                    "CountryId": "PK",
                                    "Location": "67.156944, 24.905556"
                                }
                            ]
                        },
                        {
                            "Id": "LHEA",
                            "Name": "Lahore",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "74.343613, 31.549741",
                            "IataCode": "LHE",
                            "Airports": [
                                {
                                    "Id": "LHE",
                                    "Name": "Lahore",
                                    "CityId": "LHEA",
                                    "CountryId": "PK",
                                    "Location": "74.410518, 31.520761"
                                }
                            ]
                        },
                        {
                            "Id": "LYPA",
                            "Name": "Faisalabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.083334, 31.416672",
                            "IataCode": "LYP",
                            "Airports": [
                                {
                                    "Id": "LYP",
                                    "Name": "Faisalabad",
                                    "CityId": "LYPA",
                                    "CountryId": "PK",
                                    "Location": "72.998056, 31.369167"
                                }
                            ]
                        },
                        {
                            "Id": "MUXA",
                            "Name": "Multan",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.475277, 30.195558",
                            "IataCode": "MUX",
                            "Airports": [
                                {
                                    "Id": "MUX",
                                    "Name": "Multan",
                                    "CityId": "MUXA",
                                    "CountryId": "PK",
                                    "Location": "71.418056, 30.202778"
                                }
                            ]
                        },
                        {
                            "Id": "PEWA",
                            "Name": "Peshawar",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.573333, 34.00778",
                            "IataCode": "PEW",
                            "Airports": [
                                {
                                    "Id": "PEW",
                                    "Name": "Peshawar",
                                    "CityId": "PEWA",
                                    "CountryId": "PK",
                                    "Location": "71.5, 34"
                                }
                            ]
                        },
                        {
                            "Id": "PJGA",
                            "Name": "Panjgur",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "64.1, 26.966667",
                            "IataCode": "PJG",
                            "Airports": [
                                {
                                    "Id": "PJG",
                                    "Name": "Panjgur",
                                    "CityId": "PJGA",
                                    "CountryId": "PK",
                                    "Location": "64.1, 26.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SKTA",
                            "Name": "Sialkot",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "74.516667, 32.500001",
                            "IataCode": "SKT",
                            "Airports": [
                                {
                                    "Id": "SKT",
                                    "Name": "Sialkot",
                                    "CityId": "SKTA",
                                    "CountryId": "PK",
                                    "Location": "74.5, 32.533333"
                                }
                            ]
                        },
                        {
                            "Id": "SKZA",
                            "Name": "Sukkur",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.866667, 27.7",
                            "IataCode": "SKZ",
                            "Airports": [
                                {
                                    "Id": "SKZ",
                                    "Name": "Sukkur",
                                    "CityId": "SKZA",
                                    "CountryId": "PK",
                                    "Location": "68.793611, 27.72"
                                }
                            ]
                        },
                        {
                            "Id": "ATGA",
                            "Name": "Attock",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "72.241389, 33.893611",
                            "IataCode": "ATG",
                            "Airports": [
                                {
                                    "Id": "ATG",
                                    "Name": "Attock",
                                    "CityId": "ATGA",
                                    "CountryId": "PK",
                                    "Location": "72.25, 33.9"
                                }
                            ]
                        },
                        {
                            "Id": "UETA",
                            "Name": "Quetta",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "67.0125, 30.187222",
                            "IataCode": "UET",
                            "Airports": [
                                {
                                    "Id": "UET",
                                    "Name": "Quetta",
                                    "CityId": "UETA",
                                    "CountryId": "PK",
                                    "Location": "66.936111, 30.253611"
                                }
                            ]
                        },
                        {
                            "Id": "AAWA",
                            "Name": "Abbottabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.216667, 34.15",
                            "IataCode": "AAW",
                            "Airports": [
                                {
                                    "Id": "AAW",
                                    "Name": "Abbottabad",
                                    "CityId": "AAWA",
                                    "CountryId": "PK",
                                    "Location": "73.25, 34.2"
                                }
                            ]
                        },
                        {
                            "Id": "TUKA",
                            "Name": "Turbat",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "63.050556, 26.002778",
                            "IataCode": "TUK",
                            "Airports": [
                                {
                                    "Id": "TUK",
                                    "Name": "Turbat",
                                    "CityId": "TUKA",
                                    "CountryId": "PK",
                                    "Location": "63.066667, 25.983333"
                                }
                            ]
                        },
                        {
                            "Id": "CWPA",
                            "Name": "Campbellpore",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "72.368334, 33.772222",
                            "IataCode": "CWP",
                            "Airports": [
                                {
                                    "Id": "CWP",
                                    "Name": "Campbellpore",
                                    "CityId": "CWPA",
                                    "CountryId": "PK",
                                    "Location": "72.433333, 33.766667"
                                }
                            ]
                        },
                        {
                            "Id": "MFGA",
                            "Name": "Muzaffarabad",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.471111, 34.37",
                            "IataCode": "MFG",
                            "Airports": [
                                {
                                    "Id": "MFG",
                                    "Name": "Muzaffarabad",
                                    "CityId": "MFGA",
                                    "CountryId": "PK",
                                    "Location": "73.466667, 34.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MWDA",
                            "Name": "Mianwali",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "71.55, 32.583333",
                            "IataCode": "MWD",
                            "Airports": [
                                {
                                    "Id": "MWD",
                                    "Name": "Mianwali",
                                    "CityId": "MWDA",
                                    "CountryId": "PK",
                                    "Location": "71.55, 32.55"
                                }
                            ]
                        },
                        {
                            "Id": "NHSA",
                            "Name": "Nushki",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "66.016667, 29.55",
                            "IataCode": "NHS",
                            "Airports": [
                                {
                                    "Id": "NHS",
                                    "Name": "Nushki",
                                    "CityId": "NHSA",
                                    "CountryId": "PK",
                                    "Location": "66, 29.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ORWA",
                            "Name": "Ormara",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "64.6375, 25.210556",
                            "IataCode": "ORW",
                            "Airports": [
                                {
                                    "Id": "ORW",
                                    "Name": "Ormara",
                                    "CityId": "ORWA",
                                    "CountryId": "PK",
                                    "Location": "64.583333, 25.3"
                                }
                            ]
                        },
                        {
                            "Id": "PAJB",
                            "Name": "Para Chinar",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "70.1, 33.8975",
                            "IataCode": "PAJ",
                            "Airports": [
                                {
                                    "Id": "PAJ",
                                    "Name": "Para Chinar",
                                    "CityId": "PAJB",
                                    "CountryId": "PK",
                                    "Location": "70.07139, 33.9025"
                                }
                            ]
                        },
                        {
                            "Id": "PSIA",
                            "Name": "Pasni",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "63.474444, 25.263889",
                            "IataCode": "PSI",
                            "Airports": [
                                {
                                    "Id": "PSI",
                                    "Name": "Pasni",
                                    "CityId": "PSIA",
                                    "CountryId": "PK",
                                    "Location": "63.361111, 25.3"
                                }
                            ]
                        },
                        {
                            "Id": "RAZA",
                            "Name": "Rawala Kot",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.766667, 33.866667",
                            "IataCode": "RAZ",
                            "Airports": [
                                {
                                    "Id": "RAZ",
                                    "Name": "Rawala Kot",
                                    "CityId": "RAZA",
                                    "CountryId": "PK",
                                    "Location": "73.833333, 33.841667"
                                }
                            ]
                        },
                        {
                            "Id": "SBQA",
                            "Name": "Sibi",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "67.883333, 29.55",
                            "IataCode": "SBQ",
                            "Airports": [
                                {
                                    "Id": "SBQ",
                                    "Name": "Sibi",
                                    "CityId": "SBQA",
                                    "CountryId": "PK",
                                    "Location": "67.883333, 29.55"
                                }
                            ]
                        },
                        {
                            "Id": "SDTA",
                            "Name": "Saidu Sharif",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "72.355556, 34.745833",
                            "IataCode": "SDT",
                            "Airports": [
                                {
                                    "Id": "SDT",
                                    "Name": "Saidu Sharif",
                                    "CityId": "SDTA",
                                    "CountryId": "PK",
                                    "Location": "72.35, 34.75"
                                }
                            ]
                        },
                        {
                            "Id": "SGIA",
                            "Name": "Sargodha",
                            "SingleAirportCity": false,
                            "CountryId": "PK",
                            "Location": "72.671112, 32.083611",
                            "IataCode": "SGI",
                            "Airports": [
                                {
                                    "Id": "SGI",
                                    "Name": "Mushaf Air Base",
                                    "CityId": "SGIA",
                                    "CountryId": "PK",
                                    "Location": "72.666667, 32.166667"
                                },
                                {
                                    "Id": "BHW",
                                    "Name": "Sargodha Bhagatanwala Airport",
                                    "CityId": "SGIA",
                                    "CountryId": "PK",
                                    "Location": "72.666667, 32.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SULA",
                            "Name": "Sui",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "69.316667, 28.616667",
                            "IataCode": "SUL",
                            "Airports": [
                                {
                                    "Id": "SUL",
                                    "Name": "Sui",
                                    "CityId": "SULA",
                                    "CountryId": "PK",
                                    "Location": "69.1725, 28.648889"
                                }
                            ]
                        },
                        {
                            "Id": "WAFA",
                            "Name": "Wana",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "69.5725, 32.298889",
                            "IataCode": "WAF",
                            "Airports": [
                                {
                                    "Id": "WAF",
                                    "Name": "Wana",
                                    "CityId": "WAFA",
                                    "CountryId": "PK",
                                    "Location": "69.533333, 32.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TLBA",
                            "Name": "Tarbela",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "72.810278, 34.126389",
                            "IataCode": "TLB",
                            "Airports": [
                                {
                                    "Id": "TLB",
                                    "Name": "Tarbela",
                                    "CityId": "TLBA",
                                    "CountryId": "PK",
                                    "Location": "72.816667, 34.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SYWA",
                            "Name": "Sehwen Sharif",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "67.866667, 26.433333",
                            "IataCode": "SYW",
                            "Airports": [
                                {
                                    "Id": "SYW",
                                    "Name": "Sehwen Sharif",
                                    "CityId": "SYWA",
                                    "CountryId": "PK",
                                    "Location": "67.85, 26.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SWNA",
                            "Name": "Sahiwal",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.1, 30.666667",
                            "IataCode": "SWN",
                            "Airports": [
                                {
                                    "Id": "SWN",
                                    "Name": "Sahiwal",
                                    "CityId": "SWNA",
                                    "CountryId": "PK",
                                    "Location": "73.133333, 30.75"
                                }
                            ]
                        },
                        {
                            "Id": "RYKA",
                            "Name": "Rahim Yar Khan",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "70.3, 28.416667",
                            "IataCode": "RYK",
                            "Airports": [
                                {
                                    "Id": "RYK",
                                    "Name": "Rahim Yar Khan",
                                    "CityId": "RYKA",
                                    "CountryId": "PK",
                                    "Location": "70.279722, 28.383889"
                                }
                            ]
                        },
                        {
                            "Id": "WNSA",
                            "Name": "Nawabshah",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.416667, 26.25",
                            "IataCode": "WNS",
                            "Airports": [
                                {
                                    "Id": "WNS",
                                    "Name": "Nawabshah",
                                    "CityId": "WNSA",
                                    "CountryId": "PK",
                                    "Location": "68.392222, 26.219444"
                                }
                            ]
                        },
                        {
                            "Id": "MPDA",
                            "Name": "Mirpur Khas",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "69, 25.533333",
                            "IataCode": "MPD",
                            "Airports": [
                                {
                                    "Id": "MPD",
                                    "Name": "Mirpur Khas",
                                    "CityId": "MPDA",
                                    "CountryId": "PK",
                                    "Location": "69.033333, 25.65"
                                }
                            ]
                        },
                        {
                            "Id": "KCFA",
                            "Name": "Kadanwari",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "69.316667, 27.166667",
                            "IataCode": "KCF",
                            "Airports": [
                                {
                                    "Id": "KCF",
                                    "Name": "Kadanwari",
                                    "CityId": "KCFA",
                                    "CountryId": "PK",
                                    "Location": "69.15, 27.2"
                                }
                            ]
                        },
                        {
                            "Id": "RZSA",
                            "Name": "Sawan",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.905556, 26.625",
                            "IataCode": "RZS",
                            "Airports": [
                                {
                                    "Id": "RZS",
                                    "Name": "Sawan",
                                    "CityId": "RZSA",
                                    "CountryId": "PK",
                                    "Location": "68.866667, 26.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BHCA",
                            "Name": "Bhurban",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "73.459681, 33.955846",
                            "IataCode": "BHC",
                            "Airports": [
                                {
                                    "Id": "BHC",
                                    "Name": "Bhurban",
                                    "CityId": "BHCA",
                                    "CountryId": "PK",
                                    "Location": "73.433333, 33.925"
                                }
                            ]
                        },
                        {
                            "Id": "TFTA",
                            "Name": "Derūneh",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "61.366667, 28.566667",
                            "IataCode": "TFT",
                            "Airports": [
                                {
                                    "Id": "TFT",
                                    "Name": "Taftan",
                                    "CityId": "TFTA",
                                    "CountryId": "PK",
                                    "Location": "61.366667, 28.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MJDA",
                            "Name": "Hāji Mashūri",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "68.141667, 27.341667",
                            "IataCode": "MJD",
                            "Airports": [
                                {
                                    "Id": "MJD",
                                    "Name": "Mohenjodaro",
                                    "CityId": "MJDA",
                                    "CountryId": "PK",
                                    "Location": "68.141667, 27.336389"
                                }
                            ]
                        },
                        {
                            "Id": "LRGA",
                            "Name": "Shimshi",
                            "SingleAirportCity": true,
                            "CountryId": "PK",
                            "Location": "65.1878, 27.8779",
                            "IataCode": "LRG",
                            "Airports": [
                                {
                                    "Id": "LRG",
                                    "Name": "Lora Lai",
                                    "CityId": "LRGA",
                                    "CountryId": "PK",
                                    "Location": "65.083333, 27.85"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LK",
                    "Name": "Sri Lanka",
                    "CurrencyId": "LKR",
                    "Cities": [
                        {
                            "Id": "WRZL",
                            "Name": "Weerawila",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "81.216667, 6.2833333",
                            "IataCode": "WRZ",
                            "Airports": [
                                {
                                    "Id": "WRZ",
                                    "Name": "Weerawila",
                                    "CityId": "WRZL",
                                    "CountryId": "LK",
                                    "Location": "81.2352, 6.25449"
                                }
                            ]
                        },
                        {
                            "Id": "ACJA",
                            "Name": "Anuradhapura",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.417261, 8.3564746",
                            "IataCode": "ACJ",
                            "Airports": [
                                {
                                    "Id": "ACJ",
                                    "Name": "Anuradhapura",
                                    "CityId": "ACJA",
                                    "CountryId": "LK",
                                    "Location": "80.383333, 8.35"
                                }
                            ]
                        },
                        {
                            "Id": "CMBA",
                            "Name": "Colombo",
                            "SingleAirportCity": false,
                            "CountryId": "LK",
                            "Location": "79.847778, 6.9319444",
                            "IataCode": "CMB",
                            "Airports": [
                                {
                                    "Id": "RML",
                                    "Name": "Colombo Ratmalana",
                                    "CityId": "CMBA",
                                    "CountryId": "LK",
                                    "Location": "79.8875, 6.819444"
                                },
                                {
                                    "Id": "CMB",
                                    "Name": "Colombo Bandaranayake",
                                    "CityId": "CMBA",
                                    "CountryId": "LK",
                                    "Location": "79.884722, 7.175"
                                }
                            ]
                        },
                        {
                            "Id": "GOYA",
                            "Name": "Gal Oya",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "81.516667, 7.2333333",
                            "IataCode": "GOY",
                            "Airports": [
                                {
                                    "Id": "GOY",
                                    "Name": "Gal Oya",
                                    "CityId": "GOYA",
                                    "CountryId": "LK",
                                    "Location": "81.616667, 7.333333"
                                }
                            ]
                        },
                        {
                            "Id": "JAFA",
                            "Name": "Jaffna",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80, 9.6666667",
                            "IataCode": "JAF",
                            "Airports": [
                                {
                                    "Id": "JAF",
                                    "Name": "Jaffna",
                                    "CityId": "JAFA",
                                    "CountryId": "LK",
                                    "Location": "80.075, 9.791667"
                                }
                            ]
                        },
                        {
                            "Id": "TRRA",
                            "Name": "Trincomalee",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "81.233333, 8.5666667",
                            "IataCode": "TRR",
                            "Airports": [
                                {
                                    "Id": "TRR",
                                    "Name": "Trincomalee",
                                    "CityId": "TRRA",
                                    "CountryId": "LK",
                                    "Location": "81.181389, 8.539028"
                                }
                            ]
                        },
                        {
                            "Id": "NUAA",
                            "Name": "Nuwara Eliya",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.77, 6.9697222",
                            "IataCode": "NUA",
                            "Airports": [
                                {
                                    "Id": "NUA",
                                    "Name": "Nuwara Eliya",
                                    "CityId": "NUAA",
                                    "CountryId": "LK",
                                    "Location": "80.766667, 6.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BJTA",
                            "Name": "Bentota River",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "79.998889, 6.4211111",
                            "IataCode": "BJT",
                            "Airports": [
                                {
                                    "Id": "BJT",
                                    "Name": "Bentota River",
                                    "CityId": "BJTA",
                                    "CountryId": "LK",
                                    "Location": "79.983333, 6.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KDWA",
                            "Name": "Kandy",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.635, 7.2963889",
                            "IataCode": "KDW",
                            "Airports": [
                                {
                                    "Id": "KDW",
                                    "Name": "Victoria Resevour Kandy",
                                    "CityId": "KDWA",
                                    "CountryId": "LK",
                                    "Location": "80.6333, 7.3"
                                }
                            ]
                        },
                        {
                            "Id": "DBUA",
                            "Name": "Dambula",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.651667, 7.86",
                            "IataCode": "DBU",
                            "Airports": [
                                {
                                    "Id": "DBU",
                                    "Name": "Dambula",
                                    "CityId": "DBUA",
                                    "CountryId": "LK",
                                    "Location": "80.7285, 7.95667"
                                }
                            ]
                        },
                        {
                            "Id": "GIUA",
                            "Name": "Sigiriya",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.75, 7.95",
                            "IataCode": "GIU",
                            "Airports": [
                                {
                                    "Id": "GIU",
                                    "Name": "Sigiriya",
                                    "CityId": "GIUA",
                                    "CountryId": "LK",
                                    "Location": "80.728111, 7.955889"
                                }
                            ]
                        },
                        {
                            "Id": "HAMT",
                            "Name": "Hambantota",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "81.116667, 6.1166667",
                            "IataCode": "HRI",
                            "Airports": [
                                {
                                    "Id": "HRI",
                                    "Name": "Hambantota International Airport",
                                    "CityId": "HAMT",
                                    "CountryId": "LK",
                                    "Location": "81.1225, 6.124444"
                                }
                            ]
                        },
                        {
                            "Id": "MNHA",
                            "Name": "Minneriya",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.9, 8.0333333",
                            "IataCode": "MNH",
                            "Airports": [
                                {
                                    "Id": "MNH",
                                    "Name": "Minneriya",
                                    "CityId": "MNHA",
                                    "CountryId": "LK",
                                    "Location": "83, 7.75"
                                }
                            ]
                        },
                        {
                            "Id": "DWOA",
                            "Name": "Kotte",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "79.908333, 6.9027778",
                            "IataCode": "DWO",
                            "Airports": [
                                {
                                    "Id": "DWO",
                                    "Name": "Diyawanna Oya",
                                    "CityId": "DWOA",
                                    "CountryId": "LK",
                                    "Location": "79.91, 6.905"
                                }
                            ]
                        },
                        {
                            "Id": "KCTA",
                            "Name": "Koggala",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.333333, 5.9833333",
                            "IataCode": "KCT",
                            "Airports": [
                                {
                                    "Id": "KCT",
                                    "Name": "Koggala",
                                    "CityId": "KCTA",
                                    "CountryId": "LK",
                                    "Location": "80.333056, 5.983056"
                                }
                            ]
                        },
                        {
                            "Id": "KEZA",
                            "Name": "Kelaniya",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "79.897778, 6.96",
                            "IataCode": "KEZ",
                            "Airports": [
                                {
                                    "Id": "KEZ",
                                    "Name": "Kelaniya",
                                    "CityId": "KEZA",
                                    "CountryId": "LK",
                                    "Location": "79.916667, 6.983333"
                                }
                            ]
                        },
                        {
                            "Id": "KATU",
                            "Name": "Katugastota",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.621111, 7.3161111",
                            "IataCode": "KDZ",
                            "Airports": [
                                {
                                    "Id": "KDZ",
                                    "Name": "Katugastota",
                                    "CityId": "KATU",
                                    "CountryId": "LK",
                                    "Location": "80.433311, 7.200023"
                                }
                            ]
                        },
                        {
                            "Id": "HIMA",
                            "Name": "Hingurakgoda",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.95, 8.0333333",
                            "IataCode": "HIM",
                            "Airports": [
                                {
                                    "Id": "HIM",
                                    "Name": "Hingurakgoda",
                                    "CityId": "HIMA",
                                    "CountryId": "LK",
                                    "Location": "80.971111, 8.05"
                                }
                            ]
                        },
                        {
                            "Id": "NUFA",
                            "Name": "Hatton",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.595833, 6.8972222",
                            "IataCode": "NUF",
                            "Airports": [
                                {
                                    "Id": "NUF",
                                    "Name": "Castlereigh Resrvr SPB",
                                    "CityId": "NUFA",
                                    "CountryId": "LK",
                                    "Location": "80.586, 6.858"
                                }
                            ]
                        },
                        {
                            "Id": "DIKW",
                            "Name": "Dikwella",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "80.683333, 5.9666667",
                            "IataCode": "DIW",
                            "Airports": [
                                {
                                    "Id": "DIW",
                                    "Name": "Dikwella",
                                    "CityId": "DIKW",
                                    "CountryId": "LK",
                                    "Location": "80.73323, 5.990751"
                                }
                            ]
                        },
                        {
                            "Id": "BTCA",
                            "Name": "Batticaloa",
                            "SingleAirportCity": true,
                            "CountryId": "LK",
                            "Location": "81.7, 7.7166667",
                            "IataCode": "BTC",
                            "Airports": [
                                {
                                    "Id": "BTC",
                                    "Name": "Batticaloa",
                                    "CityId": "BTCA",
                                    "CountryId": "LK",
                                    "Location": "81.678611, 7.703333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AF",
                    "Name": "Afghanistan",
                    "CurrencyId": "AFN",
                    "Cities": [
                        {
                            "Id": "ZAJA",
                            "Name": "Zaranj",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "61.883333, 30.966667",
                            "IataCode": "ZAJ",
                            "Airports": [
                                {
                                    "Id": "ZAJ",
                                    "Name": "Zaranj",
                                    "CityId": "ZAJA",
                                    "CountryId": "AF",
                                    "Location": "61.983333, 31.1"
                                }
                            ]
                        },
                        {
                            "Id": "GRGA",
                            "Name": "Gardez",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "69.22, 33.59",
                            "IataCode": "GRG",
                            "Airports": [
                                {
                                    "Id": "GRG",
                                    "Name": "Gardez",
                                    "CityId": "GRGA",
                                    "CountryId": "AF",
                                    "Location": "69.116667, 33.616667"
                                }
                            ]
                        },
                        {
                            "Id": "GZIA",
                            "Name": "Ghazni",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "68.426886, 33.553555",
                            "IataCode": "GZI",
                            "Airports": [
                                {
                                    "Id": "GZI",
                                    "Name": "Ghazni",
                                    "CityId": "GZIA",
                                    "CountryId": "AF",
                                    "Location": "68.41333, 33.53194"
                                }
                            ]
                        },
                        {
                            "Id": "MMZA",
                            "Name": "Maimana",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "64.783611, 35.921389",
                            "IataCode": "MMZ",
                            "Airports": [
                                {
                                    "Id": "MMZ",
                                    "Name": "Maimana",
                                    "CityId": "MMZA",
                                    "CountryId": "AF",
                                    "Location": "64.733333, 35.916667"
                                }
                            ]
                        },
                        {
                            "Id": "BINA",
                            "Name": "Bamiyan",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "67.821667, 34.824167",
                            "IataCode": "BIN",
                            "Airports": [
                                {
                                    "Id": "BIN",
                                    "Name": "Bamiyan",
                                    "CityId": "BINA",
                                    "CountryId": "AF",
                                    "Location": "67.823611, 34.804167"
                                }
                            ]
                        },
                        {
                            "Id": "BSTA",
                            "Name": "Bost",
                            "SingleAirportCity": false,
                            "CountryId": "AF",
                            "Location": "64.36, 31.58",
                            "IataCode": "BST",
                            "Airports": [
                                {
                                    "Id": "BST",
                                    "Name": "Bost",
                                    "CityId": "BSTA",
                                    "CountryId": "AF",
                                    "Location": "64.366667, 31.55"
                                },
                                {
                                    "Id": "OAZ",
                                    "Name": "Camp Shorabak Airport",
                                    "CityId": "BSTA",
                                    "CountryId": "AF",
                                    "Location": "64.197778, 31.801667"
                                }
                            ]
                        },
                        {
                            "Id": "CCNA",
                            "Name": "Chakcharan",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "65.251667, 34.5225",
                            "IataCode": "CCN",
                            "Airports": [
                                {
                                    "Id": "CCN",
                                    "Name": "Chakcharan",
                                    "CityId": "CCNA",
                                    "CountryId": "AF",
                                    "Location": "65.266667, 34.533333"
                                }
                            ]
                        },
                        {
                            "Id": "FAHA",
                            "Name": "Farah",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "62.11, 32.37",
                            "IataCode": "FAH",
                            "Airports": [
                                {
                                    "Id": "FAH",
                                    "Name": "Farah",
                                    "CityId": "FAHA",
                                    "CountryId": "AF",
                                    "Location": "62.116667, 32.366667"
                                }
                            ]
                        },
                        {
                            "Id": "FBDA",
                            "Name": "Faizabad",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "70.579722, 37.1175",
                            "IataCode": "FBD",
                            "Airports": [
                                {
                                    "Id": "FBD",
                                    "Name": "Faizabad",
                                    "CityId": "FBDA",
                                    "CountryId": "AF",
                                    "Location": "70.566667, 37.1"
                                }
                            ]
                        },
                        {
                            "Id": "HEAA",
                            "Name": "Herat",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "62.19967, 34.34817",
                            "IataCode": "HEA",
                            "Airports": [
                                {
                                    "Id": "HEA",
                                    "Name": "Herat",
                                    "CityId": "HEAA",
                                    "CountryId": "AF",
                                    "Location": "62.226667, 34.206944"
                                }
                            ]
                        },
                        {
                            "Id": "JAAA",
                            "Name": "Jalalabad",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "70.45, 34.42",
                            "IataCode": "JAA",
                            "Airports": [
                                {
                                    "Id": "JAA",
                                    "Name": "Jalalabad",
                                    "CityId": "JAAA",
                                    "CountryId": "AF",
                                    "Location": "70.499444, 34.399167"
                                }
                            ]
                        },
                        {
                            "Id": "KBLA",
                            "Name": "Kabul",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "69.183333, 34.516675",
                            "IataCode": "KBL",
                            "Airports": [
                                {
                                    "Id": "KBL",
                                    "Name": "Kabul",
                                    "CityId": "KBLA",
                                    "CountryId": "AF",
                                    "Location": "69.213889, 34.563889"
                                }
                            ]
                        },
                        {
                            "Id": "KDHA",
                            "Name": "Kandahar",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "65.7, 31.61",
                            "IataCode": "KDH",
                            "Airports": [
                                {
                                    "Id": "KDH",
                                    "Name": "Kandahar",
                                    "CityId": "KDHA",
                                    "CountryId": "AF",
                                    "Location": "65.8475, 31.506944"
                                }
                            ]
                        },
                        {
                            "Id": "SBFA",
                            "Name": "Sardeh Band",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "68.631667, 33.297778",
                            "IataCode": "SBF",
                            "Airports": [
                                {
                                    "Id": "SBF",
                                    "Name": "Sardeh Band",
                                    "CityId": "SBFA",
                                    "CountryId": "AF",
                                    "Location": "68.533333, 33.616667"
                                }
                            ]
                        },
                        {
                            "Id": "SGAA",
                            "Name": "Sheghnan",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "71.483333, 37.516667",
                            "IataCode": "SGA",
                            "Airports": [
                                {
                                    "Id": "SGA",
                                    "Name": "Sheghnan",
                                    "CityId": "SGAA",
                                    "CountryId": "AF",
                                    "Location": "71.716667, 38.033333"
                                }
                            ]
                        },
                        {
                            "Id": "TQNA",
                            "Name": "Taluqan",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "69.53, 36.73",
                            "IataCode": "TQN",
                            "Airports": [
                                {
                                    "Id": "TQN",
                                    "Name": "Taluqan",
                                    "CityId": "TQNA",
                                    "CountryId": "AF",
                                    "Location": "69.533333, 36.766667"
                                }
                            ]
                        },
                        {
                            "Id": "UNDA",
                            "Name": "Kunduz",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "68.86, 36.72",
                            "IataCode": "UND",
                            "Airports": [
                                {
                                    "Id": "UND",
                                    "Name": "Kunduz",
                                    "CityId": "UNDA",
                                    "CountryId": "AF",
                                    "Location": "68.909167, 36.660556"
                                }
                            ]
                        },
                        {
                            "Id": "URZA",
                            "Name": "Uruzgan",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "66.63, 32.92",
                            "IataCode": "URZ",
                            "Airports": [
                                {
                                    "Id": "URZ",
                                    "Name": "Uruzgan",
                                    "CityId": "URZA",
                                    "CountryId": "AF",
                                    "Location": "66.616667, 32.9"
                                }
                            ]
                        },
                        {
                            "Id": "IMZB",
                            "Name": "Nimroz",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "62.313585, 30.805196",
                            "IataCode": "IMZ",
                            "Airports": [
                                {
                                    "Id": "IMZ",
                                    "Name": "Nimroz",
                                    "CityId": "IMZB",
                                    "CountryId": "AF",
                                    "Location": "62.933333, 31.1"
                                }
                            ]
                        },
                        {
                            "Id": "TIIA",
                            "Name": "Tirinkot",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "65.87, 32.62",
                            "IataCode": "TII",
                            "Airports": [
                                {
                                    "Id": "TII",
                                    "Name": "Tirinkot",
                                    "CityId": "TIIA",
                                    "CountryId": "AF",
                                    "Location": "65.864153, 32.615448"
                                }
                            ]
                        },
                        {
                            "Id": "SHAA",
                            "Name": "Sharana",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "68.730278, 33.175833",
                            "IataCode": "OAS",
                            "Airports": [
                                {
                                    "Id": "OAS",
                                    "Name": "Sharana Airstrip",
                                    "CityId": "SHAA",
                                    "CountryId": "AF",
                                    "Location": "68.838639, 33.126028"
                                }
                            ]
                        },
                        {
                            "Id": "LQNA",
                            "Name": "Qala Nau",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "67.141944, 35.443333",
                            "IataCode": "LQN",
                            "Airports": [
                                {
                                    "Id": "LQN",
                                    "Name": "Qala Nau",
                                    "CityId": "LQNA",
                                    "CountryId": "AF",
                                    "Location": "63.666667, 34.95"
                                }
                            ]
                        },
                        {
                            "Id": "MZRA",
                            "Name": "Mazar-I-Sharif",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "67.112222, 36.706944",
                            "IataCode": "MZR",
                            "Airports": [
                                {
                                    "Id": "MZR",
                                    "Name": "Mazar-I-Sharif",
                                    "CityId": "MZRA",
                                    "CountryId": "AF",
                                    "Location": "67.208333, 36.704167"
                                }
                            ]
                        },
                        {
                            "Id": "DAZA",
                            "Name": "Darwaz",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "70.783333, 38.466667",
                            "IataCode": "DAZ",
                            "Airports": [
                                {
                                    "Id": "DAZ",
                                    "Name": "Darwaz",
                                    "CityId": "DAZA",
                                    "CountryId": "AF",
                                    "Location": "70.883333, 38.466667"
                                }
                            ]
                        },
                        {
                            "Id": "KHTA",
                            "Name": "Ushara",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "72.007222, 35.005556",
                            "IataCode": "KHT",
                            "Airports": [
                                {
                                    "Id": "KHT",
                                    "Name": "Khost",
                                    "CityId": "KHTA",
                                    "CountryId": "AF",
                                    "Location": "72, 35"
                                }
                            ]
                        },
                        {
                            "Id": "URNA",
                            "Name": "Orgun",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "69.18, 32.941111",
                            "IataCode": "URN",
                            "Airports": [
                                {
                                    "Id": "URN",
                                    "Name": "Urgoon",
                                    "CityId": "URNA",
                                    "CountryId": "AF",
                                    "Location": "69.183333, 32.95"
                                }
                            ]
                        },
                        {
                            "Id": "KWHA",
                            "Name": "Sowshak",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "66.326111, 33.921111",
                            "IataCode": "KWH",
                            "Airports": [
                                {
                                    "Id": "KWH",
                                    "Name": "Khwahan",
                                    "CityId": "KWHA",
                                    "CountryId": "AF",
                                    "Location": "66.333333, 33.916667"
                                }
                            ]
                        },
                        {
                            "Id": "KURA",
                            "Name": "Ghūj",
                            "SingleAirportCity": true,
                            "CountryId": "AF",
                            "Location": "71.117222, 38.403056",
                            "IataCode": "KUR",
                            "Airports": [
                                {
                                    "Id": "KUR",
                                    "Name": "Kuran-O-Munjan",
                                    "CityId": "KURA",
                                    "CountryId": "AF",
                                    "Location": "71.116667, 38.4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IO",
                    "Name": "British Indian Ocean Territory",
                    "CurrencyId": "USD",
                    "LanguageId": "en",
                    "Cities": []
                }
            ]
        },
        {
            "Id": "C",
            "Name": "Central America and the Caribbean",
            "Countries": [
                {
                    "Id": "AI",
                    "Name": "Anguilla",
                    "CurrencyId": "XCD",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "AXAA",
                            "Name": "The Valley",
                            "SingleAirportCity": true,
                            "CountryId": "AI",
                            "Location": "-63.05, 18.216667",
                            "IataCode": "AXA",
                            "Airports": [
                                {
                                    "Id": "AXA",
                                    "Name": "The Valley",
                                    "CityId": "AXAA",
                                    "CountryId": "AI",
                                    "Location": "-63.05508, 18.20483"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AG",
                    "Name": "Antigua and Barbuda",
                    "CurrencyId": "XCD",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "ANUA",
                            "Name": "Saint John's",
                            "SingleAirportCity": true,
                            "CountryId": "AG",
                            "Location": "-61.85, 17.116667",
                            "IataCode": "ANU",
                            "Airports": [
                                {
                                    "Id": "ANU",
                                    "Name": "Saint John's",
                                    "CityId": "ANUA",
                                    "CountryId": "AG",
                                    "Location": "-61.785556, 17.141111"
                                }
                            ]
                        },
                        {
                            "Id": "BBQA",
                            "Name": "Barbuda",
                            "SingleAirportCity": true,
                            "CountryId": "AG",
                            "Location": "-61.8, 17.616667",
                            "IataCode": "BBQ",
                            "Airports": [
                                {
                                    "Id": "BBQ",
                                    "Name": "Barbuda",
                                    "CityId": "BBQA",
                                    "CountryId": "AG",
                                    "Location": "-61.829038, 17.636333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GD",
                    "Name": "Grenada",
                    "CurrencyId": "XCD",
                    "Cities": [
                        {
                            "Id": "GNDA",
                            "Name": "St. George",
                            "SingleAirportCity": true,
                            "CountryId": "GD",
                            "Location": "-61.744087, 12.045155",
                            "IataCode": "GND",
                            "Airports": [
                                {
                                    "Id": "GND",
                                    "Name": "St. George",
                                    "CityId": "GNDA",
                                    "CountryId": "GD",
                                    "Location": "-61.786111, 12.004167"
                                }
                            ]
                        },
                        {
                            "Id": "CRUA",
                            "Name": "Carriacou Is",
                            "SingleAirportCity": true,
                            "CountryId": "GD",
                            "Location": "-61.447177, 12.479763",
                            "IataCode": "CRU",
                            "Airports": [
                                {
                                    "Id": "CRU",
                                    "Name": "Carriacou Is",
                                    "CityId": "CRUA",
                                    "CountryId": "GD",
                                    "Location": "-61.472222, 12.475"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PR",
                    "Name": "Puerto Rico",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "BQNA",
                            "Name": "Aguadilla",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-67.15407, 18.427445",
                            "IataCode": "BQN",
                            "Airports": [
                                {
                                    "Id": "BQN",
                                    "Name": "Aguadilla",
                                    "CityId": "BQNA",
                                    "CountryId": "PR",
                                    "Location": "-67.13, 18.495833"
                                }
                            ]
                        },
                        {
                            "Id": "FAJA",
                            "Name": "Fajardo",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-65.652384, 18.325787",
                            "IataCode": "FAJ",
                            "Airports": [
                                {
                                    "Id": "FAJ",
                                    "Name": "Fajardo",
                                    "CityId": "FAJA",
                                    "CountryId": "PR",
                                    "Location": "-65.66127, 18.30579"
                                }
                            ]
                        },
                        {
                            "Id": "MAZA",
                            "Name": "Mayaguez",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-67.139624, 18.201066",
                            "IataCode": "MAZ",
                            "Airports": [
                                {
                                    "Id": "MAZ",
                                    "Name": "Mayaguez",
                                    "CityId": "MAZA",
                                    "CountryId": "PR",
                                    "Location": "-67.148889, 18.257778"
                                }
                            ]
                        },
                        {
                            "Id": "PSEA",
                            "Name": "Ponce",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-66.614062, 18.011077",
                            "IataCode": "PSE",
                            "Airports": [
                                {
                                    "Id": "PSE",
                                    "Name": "Ponce",
                                    "CityId": "PSEA",
                                    "CountryId": "PR",
                                    "Location": "-66.563611, 18.010833"
                                }
                            ]
                        },
                        {
                            "Id": "SJUA",
                            "Name": "San Juan",
                            "SingleAirportCity": false,
                            "CountryId": "PR",
                            "Location": "-66.105722, 18.466334",
                            "IataCode": "SJU",
                            "Airports": [
                                {
                                    "Id": "SIG",
                                    "Name": "San Juan Isla Grande",
                                    "CityId": "SJUA",
                                    "CountryId": "PR",
                                    "Location": "-66.102778, 18.458889"
                                },
                                {
                                    "Id": "SJU",
                                    "Name": "San Juan Luis Munoz Marin",
                                    "CityId": "SJUA",
                                    "CountryId": "PR",
                                    "Location": "-66.002222, 18.44"
                                }
                            ]
                        },
                        {
                            "Id": "AREA",
                            "Name": "Arecibo",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-66.715731, 18.472445",
                            "IataCode": "ARE",
                            "Airports": [
                                {
                                    "Id": "ARE",
                                    "Name": "Arecibo",
                                    "CityId": "AREA",
                                    "CountryId": "PR",
                                    "Location": "-66.675833, 18.45"
                                }
                            ]
                        },
                        {
                            "Id": "DDPA",
                            "Name": "Dorado",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-66.267668, 18.458835",
                            "IataCode": "DDP",
                            "Airports": [
                                {
                                    "Id": "DDP",
                                    "Name": "Dorado",
                                    "CityId": "DDPA",
                                    "CountryId": "PR",
                                    "Location": "-66.15, 18.483333"
                                }
                            ]
                        },
                        {
                            "Id": "HUCA",
                            "Name": "Humacao",
                            "SingleAirportCity": false,
                            "CountryId": "PR",
                            "Location": "-65.827385, 18.149683",
                            "IataCode": "HUC",
                            "Airports": [
                                {
                                    "Id": "PPD",
                                    "Name": "Humacao Palmas Del Mar",
                                    "CityId": "HUCA",
                                    "CountryId": "PR",
                                    "Location": "-65.833333, 18.15"
                                },
                                {
                                    "Id": "HUC",
                                    "Name": "Humacao Arpt",
                                    "CityId": "HUCA",
                                    "CountryId": "PR",
                                    "Location": "-65.801111, 18.14"
                                }
                            ]
                        },
                        {
                            "Id": "VQSA",
                            "Name": "Vieques",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-65.444881, 18.147462",
                            "IataCode": "VQS",
                            "Airports": [
                                {
                                    "Id": "VQS",
                                    "Name": "Vieques",
                                    "CityId": "VQSA",
                                    "CountryId": "PR",
                                    "Location": "-65.4225, 18.114167"
                                }
                            ]
                        },
                        {
                            "Id": "CPXA",
                            "Name": "Culebra",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-65.300991, 18.303012",
                            "IataCode": "CPX",
                            "Airports": [
                                {
                                    "Id": "CPX",
                                    "Name": "Culebra",
                                    "CityId": "CPXA",
                                    "CountryId": "PR",
                                    "Location": "-65.316667, 18.3"
                                }
                            ]
                        },
                        {
                            "Id": "CEIB",
                            "Name": "Ceiba",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-65.648495, 18.264123",
                            "IataCode": "TJR",
                            "Airports": [
                                {
                                    "Id": "TJR",
                                    "Name": "Ceiba",
                                    "CityId": "CEIB",
                                    "CountryId": "PR",
                                    "Location": "-65.643375, 18.245143"
                                }
                            ]
                        },
                        {
                            "Id": "NRRA",
                            "Name": "Roosevelt Roads",
                            "SingleAirportCity": true,
                            "CountryId": "PR",
                            "Location": "-65.6291, 18.23498",
                            "IataCode": "NRR",
                            "Airports": [
                                {
                                    "Id": "NRR",
                                    "Name": "Roosevelt Roads",
                                    "CityId": "NRRA",
                                    "CountryId": "PR",
                                    "Location": "-65.638889, 18.25"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "HN",
                    "Name": "Honduras",
                    "CurrencyId": "HNL",
                    "Cities": [
                        {
                            "Id": "CAAA",
                            "Name": "Catacamas",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-85.9, 14.8",
                            "IataCode": "CAA",
                            "Airports": [
                                {
                                    "Id": "CAA",
                                    "Name": "Catacamas",
                                    "CityId": "CAAA",
                                    "CountryId": "HN",
                                    "Location": "-85.894444, 14.830556"
                                }
                            ]
                        },
                        {
                            "Id": "XPLA",
                            "Name": "Comayagua",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.633333, 14.45",
                            "IataCode": "XPL",
                            "Airports": [
                                {
                                    "Id": "XPL",
                                    "Name": "Comayagua",
                                    "CityId": "XPLA",
                                    "CountryId": "HN",
                                    "Location": "-87.616667, 14.416667"
                                }
                            ]
                        },
                        {
                            "Id": "BHGA",
                            "Name": "Brus Laguna",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-84.483333, 15.75",
                            "IataCode": "BHG",
                            "Airports": [
                                {
                                    "Id": "BHG",
                                    "Name": "Brus Laguna",
                                    "CityId": "BHGA",
                                    "CountryId": "HN",
                                    "Location": "-84.543333, 15.734722"
                                }
                            ]
                        },
                        {
                            "Id": "CDDA",
                            "Name": "Cauquira",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-83.583333, 15.316667",
                            "IataCode": "CDD",
                            "Airports": [
                                {
                                    "Id": "CDD",
                                    "Name": "Cauquira",
                                    "CityId": "CDDA",
                                    "CountryId": "HN",
                                    "Location": "-83.602778, 15.322222"
                                }
                            ]
                        },
                        {
                            "Id": "IRNA",
                            "Name": "Iriona",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-85.183333, 15.95",
                            "IataCode": "IRN",
                            "Airports": [
                                {
                                    "Id": "IRN",
                                    "Name": "Iriona",
                                    "CityId": "IRNA",
                                    "CountryId": "HN",
                                    "Location": "-85.183333, 15.95"
                                }
                            ]
                        },
                        {
                            "Id": "JUTA",
                            "Name": "Juticalpa",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.2, 14.65",
                            "IataCode": "JUT",
                            "Airports": [
                                {
                                    "Id": "JUT",
                                    "Name": "Juticalpa",
                                    "CityId": "JUTA",
                                    "CountryId": "HN",
                                    "Location": "-86.293056, 14.673333"
                                }
                            ]
                        },
                        {
                            "Id": "LCEA",
                            "Name": "La Ceiba",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.8, 15.783333",
                            "IataCode": "LCE",
                            "Airports": [
                                {
                                    "Id": "LCE",
                                    "Name": "La Ceiba",
                                    "CityId": "LCEA",
                                    "CountryId": "HN",
                                    "Location": "-86.857222, 15.740556"
                                }
                            ]
                        },
                        {
                            "Id": "LEZA",
                            "Name": "La Esperanza",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.183333, 14.3",
                            "IataCode": "LEZ",
                            "Airports": [
                                {
                                    "Id": "LEZ",
                                    "Name": "La Esperanza",
                                    "CityId": "LEZA",
                                    "CountryId": "HN",
                                    "Location": "-88.166667, 14.25"
                                }
                            ]
                        },
                        {
                            "Id": "MRJA",
                            "Name": "Marcala",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.033333, 14.15",
                            "IataCode": "MRJ",
                            "Airports": [
                                {
                                    "Id": "MRJ",
                                    "Name": "Marcala",
                                    "CityId": "MRJA",
                                    "CountryId": "HN",
                                    "Location": "-88.033333, 14.163611"
                                }
                            ]
                        },
                        {
                            "Id": "SAPA",
                            "Name": "San Pedro Sula",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.033333, 15.5",
                            "IataCode": "SAP",
                            "Airports": [
                                {
                                    "Id": "SAP",
                                    "Name": "San Pedro Sula",
                                    "CityId": "SAPA",
                                    "CountryId": "HN",
                                    "Location": "-87.923611, 15.452778"
                                }
                            ]
                        },
                        {
                            "Id": "SCDA",
                            "Name": "Sulaco",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.266667, 14.916667",
                            "IataCode": "SCD",
                            "Airports": [
                                {
                                    "Id": "SCD",
                                    "Name": "Sulaco",
                                    "CityId": "SCDA",
                                    "CountryId": "HN",
                                    "Location": "-87.266667, 14.916111"
                                }
                            ]
                        },
                        {
                            "Id": "TGUA",
                            "Name": "Tegucigalpa",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.216667, 14.1",
                            "IataCode": "TGU",
                            "Airports": [
                                {
                                    "Id": "TGU",
                                    "Name": "Tegucigalpa",
                                    "CityId": "TGUA",
                                    "CountryId": "HN",
                                    "Location": "-87.2175, 14.059722"
                                }
                            ]
                        },
                        {
                            "Id": "AHSA",
                            "Name": "Ahuas",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-84.333333, 15.483333",
                            "IataCode": "AHS",
                            "Airports": [
                                {
                                    "Id": "AHS",
                                    "Name": "Ahuas",
                                    "CityId": "AHSA",
                                    "CountryId": "HN",
                                    "Location": "-84.425, 15.441667"
                                }
                            ]
                        },
                        {
                            "Id": "EDQA",
                            "Name": "Erandique",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.466667, 14.233333",
                            "IataCode": "EDQ",
                            "Airports": [
                                {
                                    "Id": "EDQ",
                                    "Name": "Erandique",
                                    "CityId": "EDQA",
                                    "CountryId": "HN",
                                    "Location": "-88.416667, 14.233333"
                                }
                            ]
                        },
                        {
                            "Id": "LMHA",
                            "Name": "Limon",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-85.5, 15.866667",
                            "IataCode": "LMH",
                            "Airports": [
                                {
                                    "Id": "LMH",
                                    "Name": "Limon",
                                    "CityId": "LMHA",
                                    "CountryId": "HN",
                                    "Location": "-85.55, 15.866667"
                                }
                            ]
                        },
                        {
                            "Id": "OANA",
                            "Name": "Olanchito",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.566667, 15.5",
                            "IataCode": "OAN",
                            "Airports": [
                                {
                                    "Id": "OAN",
                                    "Name": "Olanchito",
                                    "CityId": "OANA",
                                    "CountryId": "HN",
                                    "Location": "-86.574722, 15.504444"
                                }
                            ]
                        },
                        {
                            "Id": "OROA",
                            "Name": "Yoro",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.133333, 15.133333",
                            "IataCode": "ORO",
                            "Airports": [
                                {
                                    "Id": "ORO",
                                    "Name": "Yoro",
                                    "CityId": "OROA",
                                    "CountryId": "HN",
                                    "Location": "-87.133889, 15.125278"
                                }
                            ]
                        },
                        {
                            "Id": "SDHA",
                            "Name": "Santa Rosa Copan",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.783333, 14.766667",
                            "IataCode": "SDH",
                            "Airports": [
                                {
                                    "Id": "SDH",
                                    "Name": "Santa Rosa Copan",
                                    "CityId": "SDHA",
                                    "CountryId": "HN",
                                    "Location": "-88.776389, 14.777222"
                                }
                            ]
                        },
                        {
                            "Id": "TCFA",
                            "Name": "Tocoa",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86, 15.683333",
                            "IataCode": "TCF",
                            "Airports": [
                                {
                                    "Id": "TCF",
                                    "Name": "Tocoa",
                                    "CityId": "TCFA",
                                    "CountryId": "HN",
                                    "Location": "-85.994722, 15.658889"
                                }
                            ]
                        },
                        {
                            "Id": "TEAA",
                            "Name": "Tela",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.45, 15.783333",
                            "IataCode": "TEA",
                            "Airports": [
                                {
                                    "Id": "TEA",
                                    "Name": "Tela",
                                    "CityId": "TEAA",
                                    "CountryId": "HN",
                                    "Location": "-87.487778, 15.771667"
                                }
                            ]
                        },
                        {
                            "Id": "TJIA",
                            "Name": "Trujillo",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86, 15.916667",
                            "IataCode": "TJI",
                            "Airports": [
                                {
                                    "Id": "TJI",
                                    "Name": "Trujillo",
                                    "CityId": "TJIA",
                                    "CountryId": "HN",
                                    "Location": "-85.939444, 15.925833"
                                }
                            ]
                        },
                        {
                            "Id": "VTAA",
                            "Name": "Victoria",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.383333, 14.933333",
                            "IataCode": "VTA",
                            "Airports": [
                                {
                                    "Id": "VTA",
                                    "Name": "Victoria",
                                    "CityId": "VTAA",
                                    "CountryId": "HN",
                                    "Location": "-87.4, 14.95"
                                }
                            ]
                        },
                        {
                            "Id": "GACA",
                            "Name": "Gracias",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.583333, 14.583333",
                            "IataCode": "GAC",
                            "Airports": [
                                {
                                    "Id": "GAC",
                                    "Name": "Gracias",
                                    "CityId": "GACA",
                                    "CountryId": "HN",
                                    "Location": "-88.593889, 14.596667"
                                }
                            ]
                        },
                        {
                            "Id": "LUIA",
                            "Name": "La Union",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.843889, 13.336944",
                            "IataCode": "LUI",
                            "Airports": [
                                {
                                    "Id": "LUI",
                                    "Name": "La Union",
                                    "CityId": "LUIA",
                                    "CountryId": "HN",
                                    "Location": "-87.85, 13.333333"
                                }
                            ]
                        },
                        {
                            "Id": "RTBA",
                            "Name": "Roatan",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.55, 16.3",
                            "IataCode": "RTB",
                            "Airports": [
                                {
                                    "Id": "RTB",
                                    "Name": "Roatan",
                                    "CityId": "RTBA",
                                    "CountryId": "HN",
                                    "Location": "-86.516667, 16.316667"
                                }
                            ]
                        },
                        {
                            "Id": "UIIA",
                            "Name": "Utila",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.896191, 16.099423",
                            "IataCode": "UII",
                            "Airports": [
                                {
                                    "Id": "UII",
                                    "Name": "Utila",
                                    "CityId": "UIIA",
                                    "CountryId": "HN",
                                    "Location": "-86.8875, 16.091667"
                                }
                            ]
                        },
                        {
                            "Id": "RUYA",
                            "Name": "Copan",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.894069, 14.898846",
                            "IataCode": "RUY",
                            "Airports": [
                                {
                                    "Id": "RUY",
                                    "Name": "Copan",
                                    "CityId": "RUYA",
                                    "CountryId": "HN",
                                    "Location": "-88, 15"
                                }
                            ]
                        },
                        {
                            "Id": "PEUA",
                            "Name": "Puerto Lempira",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-83.766667, 15.266667",
                            "IataCode": "PEU",
                            "Airports": [
                                {
                                    "Id": "PEU",
                                    "Name": "Puerto Lempira",
                                    "CityId": "PEUA",
                                    "CountryId": "HN",
                                    "Location": "-83.766667, 15.216667"
                                }
                            ]
                        },
                        {
                            "Id": "LLHA",
                            "Name": "Las Limas",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.916667, 15.433333",
                            "IataCode": "LLH",
                            "Airports": [
                                {
                                    "Id": "LLH",
                                    "Name": "Las Limas",
                                    "CityId": "LLHA",
                                    "CountryId": "HN",
                                    "Location": "-87.898889, 15.443056"
                                }
                            ]
                        },
                        {
                            "Id": "GJAA",
                            "Name": "Guanaja",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-85.9, 16.4",
                            "IataCode": "GJA",
                            "Airports": [
                                {
                                    "Id": "GJA",
                                    "Name": "Guanaja",
                                    "CityId": "GJAA",
                                    "CountryId": "HN",
                                    "Location": "-85.9058, 16.445"
                                }
                            ]
                        },
                        {
                            "Id": "CYLA",
                            "Name": "Coyoles",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-86.583333, 14.933333",
                            "IataCode": "CYL",
                            "Airports": [
                                {
                                    "Id": "CYL",
                                    "Name": "Coyoles",
                                    "CityId": "CYLA",
                                    "CountryId": "HN",
                                    "Location": "-86.5, 15"
                                }
                            ]
                        },
                        {
                            "Id": "PCHA",
                            "Name": "San Marcos",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-88.983333, 15",
                            "IataCode": "PCH",
                            "Airports": [
                                {
                                    "Id": "PCH",
                                    "Name": "Palacios",
                                    "CityId": "PCHA",
                                    "CountryId": "HN",
                                    "Location": "-89, 15"
                                }
                            ]
                        },
                        {
                            "Id": "ENQA",
                            "Name": "El Coquito",
                            "SingleAirportCity": true,
                            "CountryId": "HN",
                            "Location": "-87.616667, 14.35",
                            "IataCode": "ENQ",
                            "Airports": [
                                {
                                    "Id": "ENQ",
                                    "Name": "Coronel E Soto Cano AB",
                                    "CityId": "ENQA",
                                    "CountryId": "HN",
                                    "Location": "-87.617222, 14.369167"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GT",
                    "Name": "Guatemala",
                    "CurrencyId": "GTQ",
                    "Cities": [
                        {
                            "Id": "AAZA",
                            "Name": "Quetzaltenango",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-91.516667, 14.833333",
                            "IataCode": "AAZ",
                            "Airports": [
                                {
                                    "Id": "AAZ",
                                    "Name": "Quetzaltenango",
                                    "CityId": "AAZA",
                                    "CountryId": "GT",
                                    "Location": "-91.501944, 14.865556"
                                }
                            ]
                        },
                        {
                            "Id": "HUGA",
                            "Name": "Huehuetenango",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-91.470833, 15.319722",
                            "IataCode": "HUG",
                            "Airports": [
                                {
                                    "Id": "HUG",
                                    "Name": "Huehuetenango",
                                    "CityId": "HUGA",
                                    "CountryId": "GT",
                                    "Location": "-91.466667, 15.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CMMA",
                            "Name": "Carmelita",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.053333, 17.463056",
                            "IataCode": "CMM",
                            "Airports": [
                                {
                                    "Id": "CMM",
                                    "Name": "Carmelita",
                                    "CityId": "CMMA",
                                    "CountryId": "GT",
                                    "Location": "-90.053333, 17.458333"
                                }
                            ]
                        },
                        {
                            "Id": "CBVA",
                            "Name": "Coban",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.366667, 15.483333",
                            "IataCode": "CBV",
                            "Airports": [
                                {
                                    "Id": "CBV",
                                    "Name": "Coban",
                                    "CityId": "CBVA",
                                    "CountryId": "GT",
                                    "Location": "-90.409167, 15.471667"
                                }
                            ]
                        },
                        {
                            "Id": "CIQA",
                            "Name": "Chiquimula",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.55, 14.8",
                            "IataCode": "CIQ",
                            "Airports": [
                                {
                                    "Id": "CIQ",
                                    "Name": "Chiquimula",
                                    "CityId": "CIQA",
                                    "CountryId": "GT",
                                    "Location": "-89.616667, 14.85"
                                }
                            ]
                        },
                        {
                            "Id": "CTFA",
                            "Name": "Coatepeque",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-91.866667, 14.7",
                            "IataCode": "CTF",
                            "Airports": [
                                {
                                    "Id": "CTF",
                                    "Name": "Coatepeque",
                                    "CityId": "CTFA",
                                    "CountryId": "GT",
                                    "Location": "-91.916667, 14.766667"
                                }
                            ]
                        },
                        {
                            "Id": "DONA",
                            "Name": "Dos Lagunas",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.531111, 17.693889",
                            "IataCode": "DON",
                            "Airports": [
                                {
                                    "Id": "DON",
                                    "Name": "Dos Lagunas",
                                    "CityId": "DONA",
                                    "CountryId": "GT",
                                    "Location": "-89.666667, 17.633333"
                                }
                            ]
                        },
                        {
                            "Id": "FRSA",
                            "Name": "Flores",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.883333, 16.933333",
                            "IataCode": "FRS",
                            "Airports": [
                                {
                                    "Id": "FRS",
                                    "Name": "Flores",
                                    "CityId": "FRSA",
                                    "CountryId": "GT",
                                    "Location": "-89.883333, 16.916667"
                                }
                            ]
                        },
                        {
                            "Id": "GUAA",
                            "Name": "Guatemala City",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.52694, 14.62111",
                            "IataCode": "GUA",
                            "Airports": [
                                {
                                    "Id": "GUA",
                                    "Name": "Guatemala City",
                                    "CityId": "GUAA",
                                    "CountryId": "GT",
                                    "Location": "-90.5275, 14.581944"
                                }
                            ]
                        },
                        {
                            "Id": "LOXA",
                            "Name": "Los Tablones",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.166667, 14.966667",
                            "IataCode": "LOX",
                            "Airports": [
                                {
                                    "Id": "LOX",
                                    "Name": "Los Tablones",
                                    "CityId": "LOXA",
                                    "CountryId": "GT",
                                    "Location": "-90.527463, 14.583263"
                                }
                            ]
                        },
                        {
                            "Id": "RERA",
                            "Name": "Retalhuleu",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-91.683333, 14.533333",
                            "IataCode": "RER",
                            "Airports": [
                                {
                                    "Id": "RER",
                                    "Name": "Retalhuleu",
                                    "CityId": "RERA",
                                    "CountryId": "GT",
                                    "Location": "-91.696944, 14.520278"
                                }
                            ]
                        },
                        {
                            "Id": "PKJA",
                            "Name": "Playa Grande",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.733333, 15.933333",
                            "IataCode": "PKJ",
                            "Airports": [
                                {
                                    "Id": "PKJ",
                                    "Name": "Playa Grande",
                                    "CityId": "PKJA",
                                    "CountryId": "GT",
                                    "Location": "-90.761944, 15.6425"
                                }
                            ]
                        },
                        {
                            "Id": "PBRA",
                            "Name": "Puerto Barrios",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-88.6, 15.716667",
                            "IataCode": "PBR",
                            "Airports": [
                                {
                                    "Id": "PBR",
                                    "Name": "Puerto Barrios",
                                    "CityId": "PBRA",
                                    "CountryId": "GT",
                                    "Location": "-88.606111, 15.723056"
                                }
                            ]
                        },
                        {
                            "Id": "PCGA",
                            "Name": "Paso Caballos",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.266667, 17.25",
                            "IataCode": "PCG",
                            "Airports": [
                                {
                                    "Id": "PCG",
                                    "Name": "Paso Caballos",
                                    "CityId": "PCGA",
                                    "CountryId": "GT",
                                    "Location": "-90.247222, 17.26"
                                }
                            ]
                        },
                        {
                            "Id": "PONA",
                            "Name": "Poptun",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.416944, 16.331111",
                            "IataCode": "PON",
                            "Airports": [
                                {
                                    "Id": "PON",
                                    "Name": "Poptun",
                                    "CityId": "PONA",
                                    "CountryId": "GT",
                                    "Location": "-89.433333, 16.35"
                                }
                            ]
                        },
                        {
                            "Id": "RUVA",
                            "Name": "Rubelsanto",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.45, 15.983333",
                            "IataCode": "RUV",
                            "Airports": [
                                {
                                    "Id": "RUV",
                                    "Name": "Rubelsanto",
                                    "CityId": "RUVA",
                                    "CountryId": "GT",
                                    "Location": "-90.333333, 15.833333"
                                }
                            ]
                        },
                        {
                            "Id": "UAXA",
                            "Name": "Uaxactun",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.633611, 17.393611",
                            "IataCode": "UAX",
                            "Airports": [
                                {
                                    "Id": "UAX",
                                    "Name": "Uaxactun",
                                    "CityId": "UAXA",
                                    "CountryId": "GT",
                                    "Location": "-89.632778, 17.393889"
                                }
                            ]
                        },
                        {
                            "Id": "TKMA",
                            "Name": "Tikal",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-89.613333, 17.225",
                            "IataCode": "TKM",
                            "Airports": [
                                {
                                    "Id": "TKM",
                                    "Name": "Tikal",
                                    "CityId": "TKMA",
                                    "CountryId": "GT",
                                    "Location": "-89.605, 17.226389"
                                }
                            ]
                        },
                        {
                            "Id": "AQBA",
                            "Name": "Quiche",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.936249, 15.432719",
                            "IataCode": "AQB",
                            "Airports": [
                                {
                                    "Id": "AQB",
                                    "Name": "Quiche",
                                    "CityId": "AQBA",
                                    "CountryId": "GT",
                                    "Location": "-91.151389, 15.0125"
                                }
                            ]
                        },
                        {
                            "Id": "ENJA",
                            "Name": "El Naranjo",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-90.833333, 17.233333",
                            "IataCode": "ENJ",
                            "Airports": [
                                {
                                    "Id": "ENJ",
                                    "Name": "El Naranjo",
                                    "CityId": "ENJA",
                                    "CountryId": "GT",
                                    "Location": "-90.166667, 17.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MCRA",
                            "Name": "Pojcol",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-91.991667, 17",
                            "IataCode": "MCR",
                            "Airports": [
                                {
                                    "Id": "MCR",
                                    "Name": "Melchor De Menco",
                                    "CityId": "MCRA",
                                    "CountryId": "GT",
                                    "Location": "-92.008333, 17"
                                }
                            ]
                        },
                        {
                            "Id": "LCFA",
                            "Name": "Camelias",
                            "SingleAirportCity": true,
                            "CountryId": "GT",
                            "Location": "-88.95, 15.666667",
                            "IataCode": "LCF",
                            "Airports": [
                                {
                                    "Id": "LCF",
                                    "Name": "Rio Dulce",
                                    "CityId": "LCFA",
                                    "CountryId": "GT",
                                    "Location": "-88.947778, 15.667778"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DM",
                    "Name": "Dominica",
                    "CurrencyId": "XCD",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "DOMA",
                            "Name": "Dominica",
                            "SingleAirportCity": false,
                            "CountryId": "DM",
                            "Location": "-61.383333, 15.3",
                            "IataCode": "DOM",
                            "Airports": [
                                {
                                    "Id": "DOM",
                                    "Name": "Dominica Melville Hall",
                                    "CityId": "DOMA",
                                    "CountryId": "DM",
                                    "Location": "-61.300449, 15.548216"
                                },
                                {
                                    "Id": "DCF",
                                    "Name": "Dominica Cane Field",
                                    "CityId": "DOMA",
                                    "CountryId": "DM",
                                    "Location": "-61.391944, 15.336667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "HT",
                    "Name": "Haiti",
                    "CurrencyId": "HTG",
                    "Cities": [
                        {
                            "Id": "CAPA",
                            "Name": "Cap Haitien",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-72.204167, 19.757778",
                            "IataCode": "CAP",
                            "Airports": [
                                {
                                    "Id": "CAP",
                                    "Name": "Cap Haitien",
                                    "CityId": "CAPA",
                                    "CountryId": "HT",
                                    "Location": "-72.195, 19.732778"
                                }
                            ]
                        },
                        {
                            "Id": "CYAA",
                            "Name": "Les Cayes",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-73.75, 18.2",
                            "IataCode": "CYA",
                            "Airports": [
                                {
                                    "Id": "CYA",
                                    "Name": "Les Cayes",
                                    "CityId": "CYAA",
                                    "CountryId": "HT",
                                    "Location": "-73.788333, 18.269722"
                                }
                            ]
                        },
                        {
                            "Id": "JAKA",
                            "Name": "Jacmel",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-72.534722, 18.234167",
                            "IataCode": "JAK",
                            "Airports": [
                                {
                                    "Id": "JAK",
                                    "Name": "Jacmel",
                                    "CityId": "JAKA",
                                    "CountryId": "HT",
                                    "Location": "-72.518611, 18.240556"
                                }
                            ]
                        },
                        {
                            "Id": "JEEA",
                            "Name": "Jeremie",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-74.116667, 18.65",
                            "IataCode": "JEE",
                            "Airports": [
                                {
                                    "Id": "JEE",
                                    "Name": "Jeremie",
                                    "CityId": "JEEA",
                                    "CountryId": "HT",
                                    "Location": "-74.169722, 18.662222"
                                }
                            ]
                        },
                        {
                            "Id": "PAPA",
                            "Name": "Port Au Prince",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-72.335001, 18.539168",
                            "IataCode": "PAP",
                            "Airports": [
                                {
                                    "Id": "PAP",
                                    "Name": "Port Au Prince",
                                    "CityId": "PAPA",
                                    "CountryId": "HT",
                                    "Location": "-72.2925, 18.58"
                                }
                            ]
                        },
                        {
                            "Id": "PAXA",
                            "Name": "Port De Paix",
                            "SingleAirportCity": true,
                            "CountryId": "HT",
                            "Location": "-72.833333, 19.95",
                            "IataCode": "PAX",
                            "Airports": [
                                {
                                    "Id": "PAX",
                                    "Name": "Port De Paix",
                                    "CityId": "PAXA",
                                    "CountryId": "HT",
                                    "Location": "-72.841667, 19.933333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DO",
                    "Name": "Dominican Republic",
                    "CurrencyId": "DOP",
                    "Cities": [
                        {
                            "Id": "PUJA",
                            "Name": "Punta Cana",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-68.398223, 18.559619",
                            "IataCode": "PUJ",
                            "Airports": [
                                {
                                    "Id": "PUJ",
                                    "Name": "Punta Cana",
                                    "CityId": "PUJA",
                                    "CountryId": "DO",
                                    "Location": "-68.383333, 18.566667"
                                }
                            ]
                        },
                        {
                            "Id": "BRXA",
                            "Name": "Barahona",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-71.1, 18.2",
                            "IataCode": "BRX",
                            "Airports": [
                                {
                                    "Id": "BRX",
                                    "Name": "Barahona",
                                    "CityId": "BRXA",
                                    "CountryId": "DO",
                                    "Location": "-71.1204, 18.25146"
                                }
                            ]
                        },
                        {
                            "Id": "CBJA",
                            "Name": "Cabo Rojo",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-71.6725, 17.904167",
                            "IataCode": "CBJ",
                            "Airports": [
                                {
                                    "Id": "CBJ",
                                    "Name": "Cabo Rojo",
                                    "CityId": "CBJA",
                                    "CountryId": "DO",
                                    "Location": "-71.65, 17.933333"
                                }
                            ]
                        },
                        {
                            "Id": "COZA",
                            "Name": "Constanza",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-70.75, 18.916667",
                            "IataCode": "COZ",
                            "Airports": [
                                {
                                    "Id": "COZ",
                                    "Name": "Constanza",
                                    "CityId": "COZA",
                                    "CountryId": "DO",
                                    "Location": "-70.716667, 18.9"
                                }
                            ]
                        },
                        {
                            "Id": "SDQA",
                            "Name": "Santo Domingo",
                            "SingleAirportCity": false,
                            "CountryId": "DO",
                            "Location": "-69.9, 18.466669",
                            "IataCode": "SDQ",
                            "Airports": [
                                {
                                    "Id": "HEX",
                                    "Name": "Santo Domingo Herrera",
                                    "CityId": "SDQA",
                                    "CountryId": "DO",
                                    "Location": "-69.975, 18.475"
                                },
                                {
                                    "Id": "JBQ",
                                    "Name": "La Isabela International",
                                    "CityId": "SDQA",
                                    "CountryId": "DO",
                                    "Location": "-69.98144, 18.575731"
                                },
                                {
                                    "Id": "SDQ",
                                    "Name": "Santo Domingo Las Americas",
                                    "CityId": "SDQA",
                                    "CountryId": "DO",
                                    "Location": "-69.669167, 18.429167"
                                }
                            ]
                        },
                        {
                            "Id": "LRMA",
                            "Name": "La Romana",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-68.966667, 18.416667",
                            "IataCode": "LRM",
                            "Airports": [
                                {
                                    "Id": "LRM",
                                    "Name": "La Romana",
                                    "CityId": "LRMA",
                                    "CountryId": "DO",
                                    "Location": "-68.909383, 18.45258"
                                }
                            ]
                        },
                        {
                            "Id": "POPA",
                            "Name": "Puerto Plata",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-70.683333, 19.8",
                            "IataCode": "POP",
                            "Airports": [
                                {
                                    "Id": "POP",
                                    "Name": "Puerto Plata",
                                    "CityId": "POPA",
                                    "CountryId": "DO",
                                    "Location": "-70.57, 19.757778"
                                }
                            ]
                        },
                        {
                            "Id": "STIA",
                            "Name": "Santiago",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-70.7, 19.45",
                            "IataCode": "STI",
                            "Airports": [
                                {
                                    "Id": "STI",
                                    "Name": "Santiago",
                                    "CityId": "STIA",
                                    "CountryId": "DO",
                                    "Location": "-70.700278, 19.469167"
                                }
                            ]
                        },
                        {
                            "Id": "SJMA",
                            "Name": "San Juan",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-71.233333, 18.8",
                            "IataCode": "SJM",
                            "Airports": [
                                {
                                    "Id": "SJM",
                                    "Name": "San Juan",
                                    "CityId": "SJMA",
                                    "CountryId": "DO",
                                    "Location": "-71.233333, 18.833333"
                                }
                            ]
                        },
                        {
                            "Id": "EPSA",
                            "Name": "El Portillo / Samana",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-69.333333, 19.233333",
                            "IataCode": "EPS",
                            "Airports": [
                                {
                                    "Id": "EPS",
                                    "Name": "El Portillo / Samana",
                                    "CityId": "EPSA",
                                    "CountryId": "DO",
                                    "Location": "-69.43286, 19.20107"
                                }
                            ]
                        },
                        {
                            "Id": "SAMB",
                            "Name": "El Catey",
                            "SingleAirportCity": true,
                            "CountryId": "DO",
                            "Location": "-69.7375, 19.27",
                            "IataCode": "AZS",
                            "Airports": [
                                {
                                    "Id": "AZS",
                                    "Name": "Samana",
                                    "CityId": "SAMB",
                                    "CountryId": "DO",
                                    "Location": "-69.7375, 19.27"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SX",
                    "Name": "St Maarten",
                    "CurrencyId": "ANG",
                    "Cities": [
                        {
                            "Id": "SXMA",
                            "Name": "Philipsburg",
                            "SingleAirportCity": true,
                            "CountryId": "SX",
                            "Location": "-63.033333, 18.016667",
                            "IataCode": "SXM",
                            "Airports": [
                                {
                                    "Id": "SXM",
                                    "Name": "St Maarten",
                                    "CityId": "SXMA",
                                    "CountryId": "SX",
                                    "Location": "-63.111944, 18.043611"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VI",
                    "Name": "US Virgin Islands",
                    "CurrencyId": "USD",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "STXA",
                            "Name": "St Croix Island",
                            "SingleAirportCity": false,
                            "CountryId": "VI",
                            "Location": "-64.75, 17.75",
                            "IataCode": "STX",
                            "Airports": [
                                {
                                    "Id": "STX",
                                    "Name": "Henry E. Rohlsen",
                                    "CityId": "STXA",
                                    "CountryId": "VI",
                                    "Location": "-64.798611, 17.701944"
                                },
                                {
                                    "Id": "SSB",
                                    "Name": "St Croix Island SPB",
                                    "CityId": "STXA",
                                    "CountryId": "VI",
                                    "Location": "-64.69986, 17.749402"
                                },
                                {
                                    "Id": "JCD",
                                    "Name": "St Croix Island Downtown Heliport",
                                    "CityId": "STXA",
                                    "CountryId": "VI",
                                    "Location": "-64.783333, 17.7"
                                }
                            ]
                        },
                        {
                            "Id": "SJFA",
                            "Name": "St John Island",
                            "SingleAirportCity": true,
                            "CountryId": "VI",
                            "Location": "-64.734893, 18.337956",
                            "IataCode": "SJF",
                            "Airports": [
                                {
                                    "Id": "SJF",
                                    "Name": "St John Island",
                                    "CityId": "SJFA",
                                    "CountryId": "VI",
                                    "Location": "-64.75, 18.333333"
                                }
                            ]
                        },
                        {
                            "Id": "STTA",
                            "Name": "Saint Thomas",
                            "SingleAirportCity": false,
                            "CountryId": "VI",
                            "Location": "-64.930549, 18.343921",
                            "IataCode": "STT",
                            "Airports": [
                                {
                                    "Id": "STT",
                                    "Name": "Saint Thomas Cyril E King",
                                    "CityId": "STTA",
                                    "CountryId": "VI",
                                    "Location": "-64.969444, 18.3375"
                                },
                                {
                                    "Id": "SPB",
                                    "Name": "Saint Thomas Sea Plane Base",
                                    "CityId": "STTA",
                                    "CountryId": "VI",
                                    "Location": "-64.940722, 18.338028"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NI",
                    "Name": "Nicaragua",
                    "CurrencyId": "NIO",
                    "Cities": [
                        {
                            "Id": "WSPA",
                            "Name": "Waspam",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-83.966667, 14.733333",
                            "IataCode": "WSP",
                            "Airports": [
                                {
                                    "Id": "WSP",
                                    "Name": "Waspam",
                                    "CityId": "WSPA",
                                    "CountryId": "NI",
                                    "Location": "-84.016667, 14.683333"
                                }
                            ]
                        },
                        {
                            "Id": "BEFA",
                            "Name": "Bluefields",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-83.75, 12",
                            "IataCode": "BEF",
                            "Airports": [
                                {
                                    "Id": "BEF",
                                    "Name": "Bluefields",
                                    "CityId": "BEFA",
                                    "CountryId": "NI",
                                    "Location": "-83.768611, 11.996944"
                                }
                            ]
                        },
                        {
                            "Id": "BZAA",
                            "Name": "Bonanza",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-84.583333, 14.016667",
                            "IataCode": "BZA",
                            "Airports": [
                                {
                                    "Id": "BZA",
                                    "Name": "Bonanza",
                                    "CityId": "BZAA",
                                    "CountryId": "NI",
                                    "Location": "-84.6, 13.95"
                                }
                            ]
                        },
                        {
                            "Id": "MGAA",
                            "Name": "Managua",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-86.268333, 12.150833",
                            "IataCode": "MGA",
                            "Airports": [
                                {
                                    "Id": "MGA",
                                    "Name": "Managua",
                                    "CityId": "MGAA",
                                    "CountryId": "NI",
                                    "Location": "-86.168611, 12.141111"
                                }
                            ]
                        },
                        {
                            "Id": "PUZA",
                            "Name": "Puerto Cabezas",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-83.383333, 14.033333",
                            "IataCode": "PUZ",
                            "Airports": [
                                {
                                    "Id": "PUZ",
                                    "Name": "Puerto Cabezas",
                                    "CityId": "PUZA",
                                    "CountryId": "NI",
                                    "Location": "-83.383611, 14.045278"
                                }
                            ]
                        },
                        {
                            "Id": "SIUA",
                            "Name": "Siuna",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-84.766667, 13.733333",
                            "IataCode": "SIU",
                            "Airports": [
                                {
                                    "Id": "SIU",
                                    "Name": "Siuna",
                                    "CityId": "SIUA",
                                    "CountryId": "NI",
                                    "Location": "-84.777778, 13.727222"
                                }
                            ]
                        },
                        {
                            "Id": "NVGA",
                            "Name": "Nueva Guinea",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-84.45, 11.683333",
                            "IataCode": "NVG",
                            "Airports": [
                                {
                                    "Id": "NVG",
                                    "Name": "Nueva Guinea",
                                    "CityId": "NVGA",
                                    "CountryId": "NI",
                                    "Location": "-84.45, 11.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RNIA",
                            "Name": "Corn Island",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-83.033333, 12.166667",
                            "IataCode": "RNI",
                            "Airports": [
                                {
                                    "Id": "RNI",
                                    "Name": "Corn Island",
                                    "CityId": "RNIA",
                                    "CountryId": "NI",
                                    "Location": "-83.066667, 12.15"
                                }
                            ]
                        },
                        {
                            "Id": "NCRA",
                            "Name": "San Carlos",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-84.783333, 11.116667",
                            "IataCode": "NCR",
                            "Airports": [
                                {
                                    "Id": "NCR",
                                    "Name": "San Carlos",
                                    "CityId": "NCRA",
                                    "CountryId": "NI",
                                    "Location": "-84.77, 11.1334"
                                }
                            ]
                        },
                        {
                            "Id": "ECIA",
                            "Name": "Rivas",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-85.833333, 11.433333",
                            "IataCode": "ECI",
                            "Airports": [
                                {
                                    "Id": "ECI",
                                    "Name": "Costa Esmeralda",
                                    "CityId": "ECIA",
                                    "CountryId": "NI",
                                    "Location": "-86.043562, 11.435385"
                                }
                            ]
                        },
                        {
                            "Id": "RFSA",
                            "Name": "Rosita",
                            "SingleAirportCity": true,
                            "CountryId": "NI",
                            "Location": "-84.4, 13.883333",
                            "IataCode": "RFS",
                            "Airports": [
                                {
                                    "Id": "RFS",
                                    "Name": "Rosita",
                                    "CityId": "RFSA",
                                    "CountryId": "NI",
                                    "Location": "-84.4, 13.883333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VG",
                    "Name": "British Virgin Islands",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "NSXA",
                            "Name": "N. Sound / Virgin Gorda",
                            "SingleAirportCity": true,
                            "CountryId": "VG",
                            "Location": "-64.562121, 18.444383",
                            "IataCode": "NSX",
                            "Airports": [
                                {
                                    "Id": "NSX",
                                    "Name": "N. Sound / Virgin Gorda",
                                    "CityId": "NSXA",
                                    "CountryId": "VG",
                                    "Location": "-64.366667, 18.5"
                                }
                            ]
                        },
                        {
                            "Id": "NGDA",
                            "Name": "Anegada",
                            "SingleAirportCity": true,
                            "CountryId": "VG",
                            "Location": "-64.333333, 18.733333",
                            "IataCode": "NGD",
                            "Airports": [
                                {
                                    "Id": "NGD",
                                    "Name": "Anegada",
                                    "CityId": "NGDA",
                                    "CountryId": "VG",
                                    "Location": "-64.3, 18.716667"
                                }
                            ]
                        },
                        {
                            "Id": "EISA",
                            "Name": "Beef Island",
                            "SingleAirportCity": true,
                            "CountryId": "VG",
                            "Location": "-64.533333, 18.45",
                            "IataCode": "EIS",
                            "Airports": [
                                {
                                    "Id": "EIS",
                                    "Name": "Beef Island",
                                    "CityId": "EISA",
                                    "CountryId": "VG",
                                    "Location": "-64.542778, 18.443889"
                                }
                            ]
                        },
                        {
                            "Id": "TOVA",
                            "Name": "Tortola",
                            "SingleAirportCity": false,
                            "CountryId": "VG",
                            "Location": "-64.6179, 18.425549",
                            "IataCode": "TOV",
                            "Airports": [
                                {
                                    "Id": "TOV",
                                    "Name": "Tortola West End SPB",
                                    "CityId": "TOVA",
                                    "CountryId": "VG",
                                    "Location": "-64.583333, 18.45"
                                },
                                {
                                    "Id": "RAD",
                                    "Name": "Tortola Road Town",
                                    "CityId": "TOVA",
                                    "CountryId": "VG",
                                    "Location": "-64.620833, 18.427778"
                                }
                            ]
                        },
                        {
                            "Id": "VIJA",
                            "Name": "Virgin Gorda",
                            "SingleAirportCity": true,
                            "CountryId": "VG",
                            "Location": "-64.398051, 18.474831",
                            "IataCode": "VIJ",
                            "Airports": [
                                {
                                    "Id": "VIJ",
                                    "Name": "Virgin Gorda",
                                    "CityId": "VIJA",
                                    "CountryId": "VG",
                                    "Location": "-64.428077, 18.445456"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LC",
                    "Name": "Saint Lucia",
                    "CurrencyId": "XCD",
                    "Cities": [
                        {
                            "Id": "SLUA",
                            "Name": "Castries",
                            "SingleAirportCity": false,
                            "CountryId": "LC",
                            "Location": "-61, 14",
                            "IataCode": "SLU",
                            "Airports": [
                                {
                                    "Id": "SLU",
                                    "Name": "St Lucia Vigie",
                                    "CityId": "SLUA",
                                    "CountryId": "LC",
                                    "Location": "-60.992778, 14.019167"
                                },
                                {
                                    "Id": "UVF",
                                    "Name": "St Lucia Hewanorra",
                                    "CityId": "SLUA",
                                    "CountryId": "LC",
                                    "Location": "-60.952222, 13.735556"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KN",
                    "Name": "Saint Kitts and Nevis",
                    "CurrencyId": "XCD",
                    "Cities": [
                        {
                            "Id": "SKBA",
                            "Name": "Basseterre",
                            "SingleAirportCity": true,
                            "CountryId": "KN",
                            "Location": "-62.716667, 17.3",
                            "IataCode": "SKB",
                            "Airports": [
                                {
                                    "Id": "SKB",
                                    "Name": "Basseterre",
                                    "CityId": "SKBA",
                                    "CountryId": "KN",
                                    "Location": "-62.721389, 17.308056"
                                }
                            ]
                        },
                        {
                            "Id": "NEVA",
                            "Name": "Nevis",
                            "SingleAirportCity": true,
                            "CountryId": "KN",
                            "Location": "-62.75, 17.333333",
                            "IataCode": "NEV",
                            "Airports": [
                                {
                                    "Id": "NEV",
                                    "Name": "Nevis",
                                    "CityId": "NEVA",
                                    "CountryId": "KN",
                                    "Location": "-62.5925, 17.205"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BZ",
                    "Name": "Belize",
                    "CurrencyId": "BZD",
                    "Cities": [
                        {
                            "Id": "BGKA",
                            "Name": "Big Creek",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.4, 16.516667",
                            "IataCode": "BGK",
                            "Airports": [
                                {
                                    "Id": "BGK",
                                    "Name": "Big Creek",
                                    "CityId": "BGKA",
                                    "CountryId": "BZ",
                                    "Location": "-88.4, 16.516667"
                                }
                            ]
                        },
                        {
                            "Id": "CZHB",
                            "Name": "Corozal",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.383333, 18.383333",
                            "IataCode": "CZH",
                            "Airports": [
                                {
                                    "Id": "CZH",
                                    "Name": "Corozal",
                                    "CityId": "CZHB",
                                    "CountryId": "BZ",
                                    "Location": "-88.411424, 18.383229"
                                }
                            ]
                        },
                        {
                            "Id": "BZEA",
                            "Name": "Belize City",
                            "SingleAirportCity": false,
                            "CountryId": "BZ",
                            "Location": "-88.197555, 17.499518",
                            "IataCode": "BZE",
                            "Airports": [
                                {
                                    "Id": "TZA",
                                    "Name": "Belize City Municipal",
                                    "CityId": "BZEA",
                                    "CountryId": "BZ",
                                    "Location": "-88.194702, 17.516063"
                                },
                                {
                                    "Id": "BZE",
                                    "Name": "Belize City Philip S.W.Goldson Int",
                                    "CityId": "BZEA",
                                    "CountryId": "BZ",
                                    "Location": "-88.31, 17.536111"
                                }
                            ]
                        },
                        {
                            "Id": "INBA",
                            "Name": "Independence",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.416667, 16.533333",
                            "IataCode": "INB",
                            "Airports": [
                                {
                                    "Id": "INB",
                                    "Name": "Independence",
                                    "CityId": "INBA",
                                    "CountryId": "BZ",
                                    "Location": "-88.44149, 16.5346"
                                }
                            ]
                        },
                        {
                            "Id": "DGAA",
                            "Name": "Dangriga",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.216667, 16.966667",
                            "IataCode": "DGA",
                            "Airports": [
                                {
                                    "Id": "DGA",
                                    "Name": "Dangriga",
                                    "CityId": "DGAA",
                                    "CountryId": "BZ",
                                    "Location": "-88.23099, 16.98251"
                                }
                            ]
                        },
                        {
                            "Id": "PLJA",
                            "Name": "Placencia",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.366667, 16.516667",
                            "IataCode": "PLJ",
                            "Airports": [
                                {
                                    "Id": "PLJ",
                                    "Name": "Placencia",
                                    "CityId": "PLJA",
                                    "CountryId": "BZ",
                                    "Location": "-88.35336, 16.533346"
                                }
                            ]
                        },
                        {
                            "Id": "PNDA",
                            "Name": "Punta Gorda",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.8, 16.1",
                            "IataCode": "PND",
                            "Airports": [
                                {
                                    "Id": "PND",
                                    "Name": "Punta Gorda",
                                    "CityId": "PNDA",
                                    "CountryId": "BZ",
                                    "Location": "-88.816667, 16.1"
                                }
                            ]
                        },
                        {
                            "Id": "STUA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.45, 18.25",
                            "IataCode": "STU",
                            "Airports": [
                                {
                                    "Id": "STU",
                                    "Name": "Santa Cruz",
                                    "CityId": "STUA",
                                    "CountryId": "BZ",
                                    "Location": "-88.45, 18.266667"
                                }
                            ]
                        },
                        {
                            "Id": "SVKA",
                            "Name": "Silver Creek",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.883333, 16.266667",
                            "IataCode": "SVK",
                            "Airports": [
                                {
                                    "Id": "SVK",
                                    "Name": "Silver Creek",
                                    "CityId": "SVKA",
                                    "CountryId": "BZ",
                                    "Location": "-88.2, 16.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SPRA",
                            "Name": "San Pedro",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-87.95, 17.916667",
                            "IataCode": "SPR",
                            "Airports": [
                                {
                                    "Id": "SPR",
                                    "Name": "San Pedro",
                                    "CityId": "SPRA",
                                    "CountryId": "BZ",
                                    "Location": "-87.968611, 17.911111"
                                }
                            ]
                        },
                        {
                            "Id": "SQSA",
                            "Name": "San Ignacio",
                            "SingleAirportCity": false,
                            "CountryId": "BZ",
                            "Location": "-89.071389, 17.156111",
                            "IataCode": "SQS",
                            "Airports": [
                                {
                                    "Id": "SQS",
                                    "Name": "San Ignacio",
                                    "CityId": "SQSA",
                                    "CountryId": "BZ",
                                    "Location": "-89.033333, 17.183333"
                                },
                                {
                                    "Id": "CYD",
                                    "Name": "San Ignacio Cayo",
                                    "CityId": "SQSA",
                                    "CountryId": "BZ",
                                    "Location": "-89.06, 17.160125"
                                }
                            ]
                        },
                        {
                            "Id": "BCVA",
                            "Name": "Belmopan",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.766667, 17.25",
                            "IataCode": "BCV",
                            "Airports": [
                                {
                                    "Id": "BCV",
                                    "Name": "Belmopan",
                                    "CityId": "BCVA",
                                    "CountryId": "BZ",
                                    "Location": "-88.783333, 17.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CUKA",
                            "Name": "Caye Caulker",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.024178, 17.747542",
                            "IataCode": "CUK",
                            "Airports": [
                                {
                                    "Id": "CUK",
                                    "Name": "Caye Caulker",
                                    "CityId": "CUKA",
                                    "CountryId": "BZ",
                                    "Location": "-88.0325, 17.7347"
                                }
                            ]
                        },
                        {
                            "Id": "SJXA",
                            "Name": "Sartaneja",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.133333, 18.35",
                            "IataCode": "SJX",
                            "Airports": [
                                {
                                    "Id": "SJX",
                                    "Name": "Sartaneja",
                                    "CityId": "SJXA",
                                    "CountryId": "BZ",
                                    "Location": "-88.333333, 18.3"
                                }
                            ]
                        },
                        {
                            "Id": "ORZA",
                            "Name": "Orange Walk",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.783333, 17.266667",
                            "IataCode": "ORZ",
                            "Airports": [
                                {
                                    "Id": "ORZ",
                                    "Name": "Orange Walk",
                                    "CityId": "ORZA",
                                    "CountryId": "BZ",
                                    "Location": "-88.75, 17.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MDBA",
                            "Name": "Quarry Hill",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.35, 16.983333",
                            "IataCode": "MDB",
                            "Airports": [
                                {
                                    "Id": "MDB",
                                    "Name": "Melinda",
                                    "CityId": "MDBA",
                                    "CountryId": "BZ",
                                    "Location": "-88.320556, 16.998611"
                                }
                            ]
                        },
                        {
                            "Id": "CYCA",
                            "Name": "Ladyville",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-88.116667, 17.55",
                            "IataCode": "CYC",
                            "Airports": [
                                {
                                    "Id": "CYC",
                                    "Name": "Caye Chapel",
                                    "CityId": "CYCA",
                                    "CountryId": "BZ",
                                    "Location": "-88.833333, 17.666667"
                                }
                            ]
                        },
                        {
                            "Id": "MZEA",
                            "Name": "Gato Salvaje",
                            "SingleAirportCity": true,
                            "CountryId": "BZ",
                            "Location": "-89.633333, 17.033333",
                            "IataCode": "MZE",
                            "Airports": [
                                {
                                    "Id": "MZE",
                                    "Name": "Manatee",
                                    "CityId": "MZEA",
                                    "CountryId": "BZ",
                                    "Location": "-89.6, 17.083333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BS",
                    "Name": "Bahamas",
                    "CurrencyId": "BSD",
                    "Cities": [
                        {
                            "Id": "WTDA",
                            "Name": "West End",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-78.966667, 26.683333",
                            "IataCode": "WTD",
                            "Airports": [
                                {
                                    "Id": "WTD",
                                    "Name": "West End",
                                    "CityId": "WTDA",
                                    "CountryId": "BS",
                                    "Location": "-78.975833, 26.686111"
                                }
                            ]
                        },
                        {
                            "Id": "COXA",
                            "Name": "Congo Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.583333, 24.166667",
                            "IataCode": "COX",
                            "Airports": [
                                {
                                    "Id": "COX",
                                    "Name": "Congo Town",
                                    "CityId": "COXA",
                                    "CountryId": "BS",
                                    "Location": "-77.583333, 24.15"
                                }
                            ]
                        },
                        {
                            "Id": "FPOA",
                            "Name": "Freeport",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-78.7, 26.533333",
                            "IataCode": "FPO",
                            "Airports": [
                                {
                                    "Id": "FPO",
                                    "Name": "Freeport",
                                    "CityId": "FPOA",
                                    "CountryId": "BS",
                                    "Location": "-78.7, 26.555278"
                                }
                            ]
                        },
                        {
                            "Id": "GHBA",
                            "Name": "Governors Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.233333, 25.166667",
                            "IataCode": "GHB",
                            "Airports": [
                                {
                                    "Id": "GHB",
                                    "Name": "Governors Harbour",
                                    "CityId": "GHBA",
                                    "CountryId": "BS",
                                    "Location": "-76.330833, 25.283889"
                                }
                            ]
                        },
                        {
                            "Id": "MHHA",
                            "Name": "Marsh Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.05, 26.55",
                            "IataCode": "MHH",
                            "Airports": [
                                {
                                    "Id": "MHH",
                                    "Name": "Marsh Harbour",
                                    "CityId": "MHHA",
                                    "CountryId": "BS",
                                    "Location": "-77.084722, 26.511111"
                                }
                            ]
                        },
                        {
                            "Id": "MAYA",
                            "Name": "Mangrove Cay",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.65, 24.25",
                            "IataCode": "MAY",
                            "Airports": [
                                {
                                    "Id": "MAY",
                                    "Name": "Mangrove Cay",
                                    "CityId": "MAYA",
                                    "CountryId": "BS",
                                    "Location": "-77.680278, 24.289722"
                                }
                            ]
                        },
                        {
                            "Id": "NASA",
                            "Name": "Nassau",
                            "SingleAirportCity": false,
                            "CountryId": "BS",
                            "Location": "-77.35, 25.083333",
                            "IataCode": "NAS",
                            "Airports": [
                                {
                                    "Id": "WZY",
                                    "Name": "Nassau Seaplane Base",
                                    "CityId": "NASA",
                                    "CountryId": "BS",
                                    "Location": "-77.327222, 25.0825"
                                },
                                {
                                    "Id": "PID",
                                    "Name": "Nassau Paradise Island",
                                    "CityId": "NASA",
                                    "CountryId": "BS",
                                    "Location": "-77.3, 25.083333"
                                },
                                {
                                    "Id": "NAS",
                                    "Name": "Nassau International",
                                    "CityId": "NASA",
                                    "CountryId": "BS",
                                    "Location": "-77.471944, 25.040556"
                                }
                            ]
                        },
                        {
                            "Id": "TBIA",
                            "Name": "The Bight",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-75.4, 24.316667",
                            "IataCode": "TBI",
                            "Airports": [
                                {
                                    "Id": "TBI",
                                    "Name": "The Bight",
                                    "CityId": "TBIA",
                                    "CountryId": "BS",
                                    "Location": "-75.453611, 24.315"
                                }
                            ]
                        },
                        {
                            "Id": "ASDA",
                            "Name": "Andros Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.766667, 24.7",
                            "IataCode": "ASD",
                            "Airports": [
                                {
                                    "Id": "ASD",
                                    "Name": "Andros Town",
                                    "CityId": "ASDA",
                                    "CountryId": "BS",
                                    "Location": "-77.796111, 24.697778"
                                }
                            ]
                        },
                        {
                            "Id": "ATCA",
                            "Name": "Arthur's Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-75.7, 24.633333",
                            "IataCode": "ATC",
                            "Airports": [
                                {
                                    "Id": "ATC",
                                    "Name": "Arthur's Town",
                                    "CityId": "ATCA",
                                    "CountryId": "BS",
                                    "Location": "-75.671389, 24.628611"
                                }
                            ]
                        },
                        {
                            "Id": "AXPA",
                            "Name": "Spring Point",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-74, 22.45",
                            "IataCode": "AXP",
                            "Airports": [
                                {
                                    "Id": "AXP",
                                    "Name": "Spring Point",
                                    "CityId": "AXPA",
                                    "CountryId": "BS",
                                    "Location": "-73.9709, 22.4418"
                                }
                            ]
                        },
                        {
                            "Id": "SMLA",
                            "Name": "Stella Maris",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-75.3, 23.6",
                            "IataCode": "SML",
                            "Airports": [
                                {
                                    "Id": "SML",
                                    "Name": "Stella Maris",
                                    "CityId": "SMLA",
                                    "CountryId": "BS",
                                    "Location": "-75.268056, 23.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PWNA",
                            "Name": "Pitts Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-74.35, 22.8",
                            "IataCode": "PWN",
                            "Airports": [
                                {
                                    "Id": "PWN",
                                    "Name": "Pitts Town",
                                    "CityId": "PWNA",
                                    "CountryId": "BS",
                                    "Location": "-74.35, 22.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RSDA",
                            "Name": "Rock Sound",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.2, 24.9",
                            "IataCode": "RSD",
                            "Airports": [
                                {
                                    "Id": "RSD",
                                    "Name": "Rock Sound",
                                    "CityId": "RSDA",
                                    "CountryId": "BS",
                                    "Location": "-76.17062, 24.89523"
                                }
                            ]
                        },
                        {
                            "Id": "HBIA",
                            "Name": "Harbour Island",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.65, 25.5",
                            "IataCode": "HBI",
                            "Airports": [
                                {
                                    "Id": "HBI",
                                    "Name": "Harbour Island",
                                    "CityId": "HBIA",
                                    "CountryId": "BS",
                                    "Location": "-76.6, 25.516667"
                                }
                            ]
                        },
                        {
                            "Id": "IGAA",
                            "Name": "Inagua",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-73.329632, 21.113979",
                            "IataCode": "IGA",
                            "Airports": [
                                {
                                    "Id": "IGA",
                                    "Name": "Inagua",
                                    "CityId": "IGAA",
                                    "CountryId": "BS",
                                    "Location": "-73.666667, 20.983333"
                                }
                            ]
                        },
                        {
                            "Id": "SAQA",
                            "Name": "San Andros",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-78.05, 25.066667",
                            "IataCode": "SAQ",
                            "Airports": [
                                {
                                    "Id": "SAQ",
                                    "Name": "San Andros",
                                    "CityId": "SAQA",
                                    "CountryId": "BS",
                                    "Location": "-78.05, 25.05"
                                }
                            ]
                        },
                        {
                            "Id": "MSKA",
                            "Name": "Mastic Point",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.95, 25.05",
                            "IataCode": "MSK",
                            "Airports": [
                                {
                                    "Id": "MSK",
                                    "Name": "Mastic Point",
                                    "CityId": "MSKA",
                                    "CountryId": "BS",
                                    "Location": "-78, 25.033333"
                                }
                            ]
                        },
                        {
                            "Id": "GGTA",
                            "Name": "George Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-75.766667, 23.5",
                            "IataCode": "GGT",
                            "Airports": [
                                {
                                    "Id": "GGT",
                                    "Name": "George Town",
                                    "CityId": "GGTA",
                                    "CountryId": "BS",
                                    "Location": "-75.7825, 23.468056"
                                }
                            ]
                        },
                        {
                            "Id": "DCTA",
                            "Name": "Duncan Town",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-75.725833, 22.190833",
                            "IataCode": "DCT",
                            "Airports": [
                                {
                                    "Id": "DCT",
                                    "Name": "Duncan Town",
                                    "CityId": "DCTA",
                                    "CountryId": "BS",
                                    "Location": "-75.75, 22.25"
                                }
                            ]
                        },
                        {
                            "Id": "CXYA",
                            "Name": "Cat Cays",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.816667, 25.416667",
                            "IataCode": "CXY",
                            "Airports": [
                                {
                                    "Id": "CXY",
                                    "Name": "Cat Cays",
                                    "CityId": "CXYA",
                                    "CountryId": "BS",
                                    "Location": "-77.816667, 25.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CRIA",
                            "Name": "Crooked Island",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-74.216667, 22.75",
                            "IataCode": "CRI",
                            "Airports": [
                                {
                                    "Id": "CRI",
                                    "Name": "Crooked Island",
                                    "CityId": "CRIA",
                                    "CountryId": "BS",
                                    "Location": "-74.15, 22.75"
                                }
                            ]
                        },
                        {
                            "Id": "GHCA",
                            "Name": "Great Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.833333, 25.75",
                            "IataCode": "GHC",
                            "Airports": [
                                {
                                    "Id": "GHC",
                                    "Name": "Great Harbour",
                                    "CityId": "GHCA",
                                    "CountryId": "BS",
                                    "Location": "-77.840278, 25.738056"
                                }
                            ]
                        },
                        {
                            "Id": "LGIA",
                            "Name": "Long Island",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.333333, 25.066667",
                            "IataCode": "LGI",
                            "Airports": [
                                {
                                    "Id": "LGI",
                                    "Name": "Long Island",
                                    "CityId": "LGIA",
                                    "CountryId": "BS",
                                    "Location": "-75.090556, 23.179444"
                                }
                            ]
                        },
                        {
                            "Id": "MYGA",
                            "Name": "Mayaguana",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-72.95, 22.383333",
                            "IataCode": "MYG",
                            "Airports": [
                                {
                                    "Id": "MYG",
                                    "Name": "Mayaguana",
                                    "CityId": "MYGA",
                                    "CountryId": "BS",
                                    "Location": "-73.018333, 22.376667"
                                }
                            ]
                        },
                        {
                            "Id": "NMCA",
                            "Name": "Norman's Cay",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.816667, 24.616667",
                            "IataCode": "NMC",
                            "Airports": [
                                {
                                    "Id": "NMC",
                                    "Name": "Norman's Cay",
                                    "CityId": "NMCA",
                                    "CountryId": "BS",
                                    "Location": "-76.82, 24.593611"
                                }
                            ]
                        },
                        {
                            "Id": "RCYA",
                            "Name": "Rum Cay",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-74.866667, 23.683333",
                            "IataCode": "RCY",
                            "Airports": [
                                {
                                    "Id": "RCY",
                                    "Name": "Rum Cay",
                                    "CityId": "RCYA",
                                    "CountryId": "BS",
                                    "Location": "-74.966667, 23.666667"
                                }
                            ]
                        },
                        {
                            "Id": "ZSAA",
                            "Name": "San Salvador",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-74.483333, 24.1",
                            "IataCode": "ZSA",
                            "Airports": [
                                {
                                    "Id": "ZSA",
                                    "Name": "San Salvador",
                                    "CityId": "ZSAA",
                                    "CountryId": "BS",
                                    "Location": "-74.533056, 24.06"
                                }
                            ]
                        },
                        {
                            "Id": "BIMA",
                            "Name": "Bimini",
                            "SingleAirportCity": false,
                            "CountryId": "BS",
                            "Location": "-79.25, 25.733333",
                            "IataCode": "BIM",
                            "Airports": [
                                {
                                    "Id": "NSB",
                                    "Name": "Bimini North SPB",
                                    "CityId": "BIMA",
                                    "CountryId": "BS",
                                    "Location": "-79.25, 25.775"
                                },
                                {
                                    "Id": "BIM",
                                    "Name": "Bimini International",
                                    "CityId": "BIMA",
                                    "CountryId": "BS",
                                    "Location": "-79.26466, 25.69988"
                                }
                            ]
                        },
                        {
                            "Id": "WKRA",
                            "Name": "Walker's Cay",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-78.394639, 27.257722",
                            "IataCode": "WKR",
                            "Airports": [
                                {
                                    "Id": "WKR",
                                    "Name": "Walker's Cay",
                                    "CityId": "WKRA",
                                    "CountryId": "BS",
                                    "Location": "-78.4, 27.266667"
                                }
                            ]
                        },
                        {
                            "Id": "TZNA",
                            "Name": "South Andros",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.668635, 23.880174",
                            "IataCode": "TZN",
                            "Airports": [
                                {
                                    "Id": "TZN",
                                    "Name": "South Andros",
                                    "CityId": "TZNA",
                                    "CountryId": "BS",
                                    "Location": "-77.585278, 24.165"
                                }
                            ]
                        },
                        {
                            "Id": "ELHA",
                            "Name": "North Eleuthera",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.606653, 25.406141",
                            "IataCode": "ELH",
                            "Airports": [
                                {
                                    "Id": "ELH",
                                    "Name": "North Eleuthera",
                                    "CityId": "ELHA",
                                    "CountryId": "BS",
                                    "Location": "-76.685, 25.474722"
                                }
                            ]
                        },
                        {
                            "Id": "GTCA",
                            "Name": "The Bight",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.55, 26.3",
                            "IataCode": "GTC",
                            "Airports": [
                                {
                                    "Id": "GTC",
                                    "Name": "Green Turtle",
                                    "CityId": "GTCA",
                                    "CountryId": "BS",
                                    "Location": "-77.366667, 26.25"
                                }
                            ]
                        },
                        {
                            "Id": "TCBA",
                            "Name": "Normans Castle",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-77.433333, 26.7",
                            "IataCode": "TCB",
                            "Airports": [
                                {
                                    "Id": "TCB",
                                    "Name": "Treasure Cay",
                                    "CityId": "TCBA",
                                    "CountryId": "BS",
                                    "Location": "-77.372222, 26.735"
                                }
                            ]
                        },
                        {
                            "Id": "CCZA",
                            "Name": "Morgan's Bluff",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-78.033333, 25.166667",
                            "IataCode": "CCZ",
                            "Airports": [
                                {
                                    "Id": "CCZ",
                                    "Name": "Chub Cay",
                                    "CityId": "CCZA",
                                    "CountryId": "BS",
                                    "Location": "-77.881389, 25.4175"
                                }
                            ]
                        },
                        {
                            "Id": "CELA",
                            "Name": "James Cistern",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.366667, 25.316667",
                            "IataCode": "CEL",
                            "Airports": [
                                {
                                    "Id": "CEL",
                                    "Name": "Cape Eleuthera",
                                    "CityId": "CELA",
                                    "CountryId": "BS",
                                    "Location": "-76.330833, 25.283889"
                                }
                            ]
                        },
                        {
                            "Id": "PPOA",
                            "Name": "Deep Creek",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.3, 24.783333",
                            "IataCode": "PPO",
                            "Airports": [
                                {
                                    "Id": "PPO",
                                    "Name": "Powell Point",
                                    "CityId": "PPOA",
                                    "CountryId": "BS",
                                    "Location": "-76.35, 24.9"
                                }
                            ]
                        },
                        {
                            "Id": "TYMA",
                            "Name": "Black Point",
                            "SingleAirportCity": true,
                            "CountryId": "BS",
                            "Location": "-76.383333, 24.1",
                            "IataCode": "TYM",
                            "Airports": [
                                {
                                    "Id": "TYM",
                                    "Name": "Staniel Cay",
                                    "CityId": "TYMA",
                                    "CountryId": "BS",
                                    "Location": "-76.433333, 24.15"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BQ",
                    "Name": "Caribbean Netherlands",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "BONA",
                            "Name": "Bonaire",
                            "SingleAirportCity": true,
                            "CountryId": "BQ",
                            "Location": "-68.466667, 12.25",
                            "IataCode": "BON",
                            "Airports": [
                                {
                                    "Id": "BON",
                                    "Name": "Bonaire",
                                    "CityId": "BONA",
                                    "CountryId": "BQ",
                                    "Location": "-68.273333, 12.134167"
                                }
                            ]
                        },
                        {
                            "Id": "SABA",
                            "Name": "Saba Island",
                            "SingleAirportCity": true,
                            "CountryId": "BQ",
                            "Location": "-63.233333, 17.633333",
                            "IataCode": "SAB",
                            "Airports": [
                                {
                                    "Id": "SAB",
                                    "Name": "Saba Island",
                                    "CityId": "SABA",
                                    "CountryId": "BQ",
                                    "Location": "-63.216667, 17.65"
                                }
                            ]
                        },
                        {
                            "Id": "EUXA",
                            "Name": "St Eustatius",
                            "SingleAirportCity": true,
                            "CountryId": "BQ",
                            "Location": "-62.983333, 17.483333",
                            "IataCode": "EUX",
                            "Airports": [
                                {
                                    "Id": "EUX",
                                    "Name": "St Eustatius",
                                    "CityId": "EUXA",
                                    "CountryId": "BQ",
                                    "Location": "-62.977778, 17.493056"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TT",
                    "Name": "Trinidad and Tobago",
                    "CurrencyId": "TTD",
                    "Cities": [
                        {
                            "Id": "POSA",
                            "Name": "Port Of Spain",
                            "SingleAirportCity": true,
                            "CountryId": "TT",
                            "Location": "-61.516667, 10.65",
                            "IataCode": "POS",
                            "Airports": [
                                {
                                    "Id": "POS",
                                    "Name": "Port Of Spain",
                                    "CityId": "POSA",
                                    "CountryId": "TT",
                                    "Location": "-61.35, 10.6"
                                }
                            ]
                        },
                        {
                            "Id": "TOBB",
                            "Name": "Tobago",
                            "SingleAirportCity": true,
                            "CountryId": "TT",
                            "Location": "-60.663673, 11.2409",
                            "IataCode": "TAB",
                            "Airports": [
                                {
                                    "Id": "TAB",
                                    "Name": "Tobago",
                                    "CityId": "TOBB",
                                    "CountryId": "TT",
                                    "Location": "-60.836667, 11.146389"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AW",
                    "Name": "Aruba",
                    "CurrencyId": "AWG",
                    "LanguageId": "NL",
                    "Cities": [
                        {
                            "Id": "AUAA",
                            "Name": "Oranjestad",
                            "SingleAirportCity": true,
                            "CountryId": "AW",
                            "Location": "-70.033333, 12.516667",
                            "IataCode": "AUA",
                            "Airports": [
                                {
                                    "Id": "AUA",
                                    "Name": "Oranjestad",
                                    "CityId": "AUAA",
                                    "CountryId": "AW",
                                    "Location": "-70.013889, 12.502222"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KY",
                    "Name": "Cayman Islands",
                    "CurrencyId": "KYD",
                    "Cities": [
                        {
                            "Id": "GCMA",
                            "Name": "Grand Cayman Island",
                            "SingleAirportCity": true,
                            "CountryId": "KY",
                            "Location": "-81.216667, 19.333333",
                            "IataCode": "GCM",
                            "Airports": [
                                {
                                    "Id": "GCM",
                                    "Name": "Grand Cayman Island",
                                    "CityId": "GCMA",
                                    "CountryId": "KY",
                                    "Location": "-81.358056, 19.2925"
                                }
                            ]
                        },
                        {
                            "Id": "CYBA",
                            "Name": "Cayman Brac Is",
                            "SingleAirportCity": true,
                            "CountryId": "KY",
                            "Location": "-79.808882, 19.716769",
                            "IataCode": "CYB",
                            "Airports": [
                                {
                                    "Id": "CYB",
                                    "Name": "Cayman Brac Is",
                                    "CityId": "CYBA",
                                    "CountryId": "KY",
                                    "Location": "-79.8875, 19.686667"
                                }
                            ]
                        },
                        {
                            "Id": "LYBA",
                            "Name": "Little Cayman",
                            "SingleAirportCity": true,
                            "CountryId": "KY",
                            "Location": "-80.038051, 19.681937",
                            "IataCode": "LYB",
                            "Airports": [
                                {
                                    "Id": "LYB",
                                    "Name": "Little Cayman",
                                    "CityId": "LYBA",
                                    "CountryId": "KY",
                                    "Location": "-80.083333, 19.666667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BB",
                    "Name": "Barbados",
                    "CurrencyId": "BBD",
                    "Cities": [
                        {
                            "Id": "BGIA",
                            "Name": "Bridgetown",
                            "SingleAirportCity": true,
                            "CountryId": "BB",
                            "Location": "-59.616667, 13.1",
                            "IataCode": "BGI",
                            "Airports": [
                                {
                                    "Id": "BGI",
                                    "Name": "Bridgetown",
                                    "CityId": "BGIA",
                                    "CountryId": "BB",
                                    "Location": "-59.493056, 13.072222"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TC",
                    "Name": "Turks and Caicos Islands",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "GDTA",
                            "Name": "Grand Turk Is",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-71.133333, 21.466667",
                            "IataCode": "GDT",
                            "Airports": [
                                {
                                    "Id": "GDT",
                                    "Name": "Grand Turk Is",
                                    "CityId": "GDTA",
                                    "CountryId": "TC",
                                    "Location": "-71.1423, 21.4445"
                                }
                            ]
                        },
                        {
                            "Id": "MDSA",
                            "Name": "Middle Caicos",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-71.734542, 21.788192",
                            "IataCode": "MDS",
                            "Airports": [
                                {
                                    "Id": "MDS",
                                    "Name": "Middle Caicos",
                                    "CityId": "MDSA",
                                    "CountryId": "TC",
                                    "Location": "-71.716667, 21.783333"
                                }
                            ]
                        },
                        {
                            "Id": "NCAA",
                            "Name": "North Caicos",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-71.946111, 21.891389",
                            "IataCode": "NCA",
                            "Airports": [
                                {
                                    "Id": "NCA",
                                    "Name": "North Caicos",
                                    "CityId": "NCAA",
                                    "CountryId": "TC",
                                    "Location": "-71.939444, 21.9175"
                                }
                            ]
                        },
                        {
                            "Id": "PICA",
                            "Name": "Pine Cay",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-72.093536, 21.871194",
                            "IataCode": "PIC",
                            "Airports": [
                                {
                                    "Id": "PIC",
                                    "Name": "Pine Cay",
                                    "CityId": "PICA",
                                    "CountryId": "TC",
                                    "Location": "-72.1, 21.883333"
                                }
                            ]
                        },
                        {
                            "Id": "PLSA",
                            "Name": "Providenciales",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-72.283333, 21.783333",
                            "IataCode": "PLS",
                            "Airports": [
                                {
                                    "Id": "PLS",
                                    "Name": "Providenciales",
                                    "CityId": "PLSA",
                                    "CountryId": "TC",
                                    "Location": "-72.268333, 21.769167"
                                }
                            ]
                        },
                        {
                            "Id": "XSCA",
                            "Name": "South Caicos",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-71.517778, 21.509444",
                            "IataCode": "XSC",
                            "Airports": [
                                {
                                    "Id": "XSC",
                                    "Name": "South Caicos",
                                    "CityId": "XSCA",
                                    "CountryId": "TC",
                                    "Location": "-71.529722, 21.515833"
                                }
                            ]
                        },
                        {
                            "Id": "SLXA",
                            "Name": "Salt Cay",
                            "SingleAirportCity": true,
                            "CountryId": "TC",
                            "Location": "-71.152986, 21.342476",
                            "IataCode": "SLX",
                            "Airports": [
                                {
                                    "Id": "SLX",
                                    "Name": "Salt Cay",
                                    "CityId": "SLXA",
                                    "CountryId": "TC",
                                    "Location": "-71.201667, 21.333333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VC",
                    "Name": "Saint Vincent and the Grenadines",
                    "CurrencyId": "XCD",
                    "Cities": [
                        {
                            "Id": "SVDA",
                            "Name": "Kingstown",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-61.216667, 13.133333",
                            "IataCode": "SVD",
                            "Airports": [
                                {
                                    "Id": "SVD",
                                    "Name": "Kingstown",
                                    "CityId": "SVDA",
                                    "CountryId": "VC",
                                    "Location": "-61.210885, 13.143845"
                                }
                            ]
                        },
                        {
                            "Id": "UNIA",
                            "Name": "Union Island",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-60.966667, 14.033333",
                            "IataCode": "UNI",
                            "Airports": [
                                {
                                    "Id": "UNI",
                                    "Name": "Union Island",
                                    "CityId": "UNIA",
                                    "CountryId": "VC",
                                    "Location": "-61, 13.5"
                                }
                            ]
                        },
                        {
                            "Id": "BQUA",
                            "Name": "Port Elizabeth",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-61.233333, 13.016667",
                            "IataCode": "BQU",
                            "Airports": [
                                {
                                    "Id": "BQU",
                                    "Name": "Port Elizabeth",
                                    "CityId": "BQUA",
                                    "CountryId": "VC",
                                    "Location": "-61.264167, 12.986667"
                                }
                            ]
                        },
                        {
                            "Id": "MQSA",
                            "Name": "Lovell Village",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-61.183333, 12.883333",
                            "IataCode": "MQS",
                            "Airports": [
                                {
                                    "Id": "MQS",
                                    "Name": "Mustique Island",
                                    "CityId": "MQSA",
                                    "CountryId": "VC",
                                    "Location": "-61.181944, 12.888333"
                                }
                            ]
                        },
                        {
                            "Id": "PLIA",
                            "Name": "Mount Pleasant",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-61.433333, 12.466667",
                            "IataCode": "PLI",
                            "Airports": [
                                {
                                    "Id": "PLI",
                                    "Name": "Palm Island",
                                    "CityId": "PLIA",
                                    "CountryId": "VC",
                                    "Location": "-61.233333, 12.35"
                                }
                            ]
                        },
                        {
                            "Id": "CIWA",
                            "Name": "Carenage",
                            "SingleAirportCity": true,
                            "CountryId": "VC",
                            "Location": "-61.324001, 12.716",
                            "IataCode": "CIW",
                            "Airports": [
                                {
                                    "Id": "CIW",
                                    "Name": "Canouan Island",
                                    "CityId": "CIWA",
                                    "CountryId": "VC",
                                    "Location": "-61.342206, 12.699417"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GP",
                    "Name": "Guadeloupe",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "LSSA",
                            "Name": "Terre-de-Haut",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.633333, 15.85",
                            "IataCode": "LSS",
                            "Airports": [
                                {
                                    "Id": "LSS",
                                    "Name": "Terre-de-Haut",
                                    "CityId": "LSSA",
                                    "CountryId": "GP",
                                    "Location": "-61.576667, 15.866944"
                                }
                            ]
                        },
                        {
                            "Id": "PTPA",
                            "Name": "Pointe-a-Pitre",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.516667, 16.233333",
                            "IataCode": "PTP",
                            "Airports": [
                                {
                                    "Id": "PTP",
                                    "Name": "Pointe-a-Pitre",
                                    "CityId": "PTPA",
                                    "CountryId": "GP",
                                    "Location": "-61.526389, 16.2675"
                                }
                            ]
                        },
                        {
                            "Id": "BBRA",
                            "Name": "Basse Terre",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.716667, 16",
                            "IataCode": "BBR",
                            "Airports": [
                                {
                                    "Id": "BBR",
                                    "Name": "Basse Terre",
                                    "CityId": "BBRA",
                                    "CountryId": "GP",
                                    "Location": "-61.739444, 16.016389"
                                }
                            ]
                        },
                        {
                            "Id": "SFCA",
                            "Name": "St Francois",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.266667, 16.25",
                            "IataCode": "SFC",
                            "Airports": [
                                {
                                    "Id": "SFC",
                                    "Name": "St Francois",
                                    "CityId": "SFCA",
                                    "CountryId": "GP",
                                    "Location": "-61.283333, 16.25"
                                }
                            ]
                        },
                        {
                            "Id": "DSDA",
                            "Name": "La Desirade",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.033333, 16.316667",
                            "IataCode": "DSD",
                            "Airports": [
                                {
                                    "Id": "DSD",
                                    "Name": "La Desirade",
                                    "CityId": "DSDA",
                                    "CountryId": "GP",
                                    "Location": "-61.016667, 16.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SFGA",
                            "Name": "Grand-Case",
                            "SingleAirportCity": false,
                            "CountryId": "GP",
                            "Location": "-63.05, 18.1",
                            "IataCode": "SFG",
                            "Airports": [
                                {
                                    "Id": "SFG",
                                    "Name": "St Martin Esperance",
                                    "CityId": "SFGA",
                                    "CountryId": "GP",
                                    "Location": "-63.047222, 18.099722"
                                },
                                {
                                    "Id": "CCE",
                                    "Name": "St Martin Grand Case",
                                    "CityId": "SFGA",
                                    "CountryId": "GP",
                                    "Location": "-63.116667, 18.05"
                                },
                                {
                                    "Id": "MSB",
                                    "Name": "St Martin Marigot SPB",
                                    "CityId": "SFGA",
                                    "CountryId": "GP",
                                    "Location": "-63.066667, 18.066667"
                                }
                            ]
                        },
                        {
                            "Id": "HTBA",
                            "Name": "Petites-Anses",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.633333, 15.85",
                            "IataCode": "HTB",
                            "Airports": [
                                {
                                    "Id": "HTB",
                                    "Name": "Terre-de-Bas",
                                    "CityId": "HTBA",
                                    "CountryId": "GP",
                                    "Location": "-61.633333, 15.716667"
                                }
                            ]
                        },
                        {
                            "Id": "GBJA",
                            "Name": "Murat",
                            "SingleAirportCity": true,
                            "CountryId": "GP",
                            "Location": "-61.3, 15.883333",
                            "IataCode": "GBJ",
                            "Airports": [
                                {
                                    "Id": "GBJ",
                                    "Name": "Marie Galante",
                                    "CityId": "GBJA",
                                    "CountryId": "GP",
                                    "Location": "-61.266667, 15.866667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CW",
                    "Name": "Curacao",
                    "CurrencyId": "ANG",
                    "Cities": [
                        {
                            "Id": "CURA",
                            "Name": "Curacao",
                            "SingleAirportCity": true,
                            "CountryId": "CW",
                            "Location": "-68.933333, 12.116667",
                            "IataCode": "CUR",
                            "Airports": [
                                {
                                    "Id": "CUR",
                                    "Name": "Curacao",
                                    "CityId": "CURA",
                                    "CountryId": "CW",
                                    "Location": "-68.960556, 12.194167"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CU",
                    "Name": "Cuba",
                    "CurrencyId": "CUP",
                    "Cities": [
                        {
                            "Id": "BYMA",
                            "Name": "Bayamo",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-76.643333, 20.379167",
                            "IataCode": "BYM",
                            "Airports": [
                                {
                                    "Id": "BYM",
                                    "Name": "Bayamo",
                                    "CityId": "BYMA",
                                    "CountryId": "CU",
                                    "Location": "-76.61667, 20.4"
                                }
                            ]
                        },
                        {
                            "Id": "CFGA",
                            "Name": "Cienfuegos",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-80.435556, 22.146111",
                            "IataCode": "CFG",
                            "Airports": [
                                {
                                    "Id": "CFG",
                                    "Name": "Cienfuegos",
                                    "CityId": "CFGA",
                                    "CountryId": "CU",
                                    "Location": "-80.414167, 22.15"
                                }
                            ]
                        },
                        {
                            "Id": "CMWA",
                            "Name": "Camaguey",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-77.916944, 21.380833",
                            "IataCode": "CMW",
                            "Airports": [
                                {
                                    "Id": "CMW",
                                    "Name": "Camaguey",
                                    "CityId": "CMWA",
                                    "CountryId": "CU",
                                    "Location": "-77.84743, 21.42043"
                                }
                            ]
                        },
                        {
                            "Id": "GAOA",
                            "Name": "Guantanamo",
                            "SingleAirportCity": false,
                            "CountryId": "CU",
                            "Location": "-75.209167, 20.144444",
                            "IataCode": "GAO",
                            "Airports": [
                                {
                                    "Id": "GAO",
                                    "Name": "Guantanamo Los Canos",
                                    "CityId": "GAOA",
                                    "CountryId": "CU",
                                    "Location": "-75.158611, 20.084722"
                                },
                                {
                                    "Id": "NBW",
                                    "Name": "Guantanamo NAS",
                                    "CityId": "GAOA",
                                    "CountryId": "CU",
                                    "Location": "-75.20694, 19.90639"
                                }
                            ]
                        },
                        {
                            "Id": "GERA",
                            "Name": "Nueva Gerona",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-82.8, 21.883333",
                            "IataCode": "GER",
                            "Airports": [
                                {
                                    "Id": "GER",
                                    "Name": "Nueva Gerona",
                                    "CityId": "GERA",
                                    "CountryId": "CU",
                                    "Location": "-82.78, 21.8375"
                                }
                            ]
                        },
                        {
                            "Id": "HOGA",
                            "Name": "Holguin",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-76.263056, 20.887222",
                            "IataCode": "HOG",
                            "Airports": [
                                {
                                    "Id": "HOG",
                                    "Name": "Holguin",
                                    "CityId": "HOGA",
                                    "CountryId": "CU",
                                    "Location": "-76.315, 20.785278"
                                }
                            ]
                        },
                        {
                            "Id": "ICRA",
                            "Name": "Nicaro",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-75.550556, 20.704722",
                            "IataCode": "ICR",
                            "Airports": [
                                {
                                    "Id": "ICR",
                                    "Name": "Nicaro",
                                    "CityId": "ICRA",
                                    "CountryId": "CU",
                                    "Location": "-75.975, 20.983333"
                                }
                            ]
                        },
                        {
                            "Id": "LCLA",
                            "Name": "La Coloma",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-83.576111, 22.241667",
                            "IataCode": "LCL",
                            "Airports": [
                                {
                                    "Id": "LCL",
                                    "Name": "La Coloma",
                                    "CityId": "LCLA",
                                    "CountryId": "CU",
                                    "Location": "-83.6419, 22.3361"
                                }
                            ]
                        },
                        {
                            "Id": "SCUA",
                            "Name": "Santiago",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-75.821944, 20.024722",
                            "IataCode": "SCU",
                            "Airports": [
                                {
                                    "Id": "SCU",
                                    "Name": "Santiago",
                                    "CityId": "SCUA",
                                    "CountryId": "CU",
                                    "Location": "-75.835833, 19.969167"
                                }
                            ]
                        },
                        {
                            "Id": "SNUA",
                            "Name": "Santa Clara",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-79.966667, 22.4",
                            "IataCode": "SNU",
                            "Airports": [
                                {
                                    "Id": "SNU",
                                    "Name": "Santa Clara",
                                    "CityId": "SNUA",
                                    "CountryId": "CU",
                                    "Location": "-79.941944, 22.491944"
                                }
                            ]
                        },
                        {
                            "Id": "VRAA",
                            "Name": "Varadero",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-81.251389, 23.153611",
                            "IataCode": "VRA",
                            "Airports": [
                                {
                                    "Id": "VRA",
                                    "Name": "Varadero",
                                    "CityId": "VRAA",
                                    "CountryId": "CU",
                                    "Location": "-81.435278, 23.034444"
                                }
                            ]
                        },
                        {
                            "Id": "AVIB",
                            "Name": "Ciego de Avila",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-78.761945, 21.84",
                            "IataCode": "AVI",
                            "Airports": [
                                {
                                    "Id": "AVI",
                                    "Name": "Ciego de Avila",
                                    "CityId": "AVIB",
                                    "CountryId": "CU",
                                    "Location": "-78.7914, 22.025"
                                }
                            ]
                        },
                        {
                            "Id": "BCAA",
                            "Name": "Baracoa",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-74.495833, 20.346667",
                            "IataCode": "BCA",
                            "Airports": [
                                {
                                    "Id": "BCA",
                                    "Name": "Baracoa",
                                    "CityId": "BCAA",
                                    "CountryId": "CU",
                                    "Location": "-74.50621, 20.36532"
                                }
                            ]
                        },
                        {
                            "Id": "MOAA",
                            "Name": "Moa",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-74.940278, 20.656944",
                            "IataCode": "MOA",
                            "Airports": [
                                {
                                    "Id": "MOA",
                                    "Name": "Moa",
                                    "CityId": "MOAA",
                                    "CountryId": "CU",
                                    "Location": "-74.92211, 20.65411"
                                }
                            ]
                        },
                        {
                            "Id": "MZOA",
                            "Name": "Manzanillo",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-77.116666, 20.343333",
                            "IataCode": "MZO",
                            "Airports": [
                                {
                                    "Id": "MZO",
                                    "Name": "Manzanillo",
                                    "CityId": "MZOA",
                                    "CountryId": "CU",
                                    "Location": "-77.093889, 20.286389"
                                }
                            ]
                        },
                        {
                            "Id": "TNDA",
                            "Name": "Trinidad",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-79.984167, 21.801944",
                            "IataCode": "TND",
                            "Airports": [
                                {
                                    "Id": "TND",
                                    "Name": "Trinidad",
                                    "CityId": "TNDA",
                                    "CountryId": "CU",
                                    "Location": "-80, 21.8"
                                }
                            ]
                        },
                        {
                            "Id": "MJGA",
                            "Name": "Mayajigua",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-79.068611, 22.241667",
                            "IataCode": "MJG",
                            "Airports": [
                                {
                                    "Id": "MJG",
                                    "Name": "Mayajigua",
                                    "CityId": "MJGA",
                                    "CountryId": "CU",
                                    "Location": "-79, 21.758333"
                                }
                            ]
                        },
                        {
                            "Id": "PSTA",
                            "Name": "Preston",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-75.653889, 20.760833",
                            "IataCode": "PST",
                            "Airports": [
                                {
                                    "Id": "PST",
                                    "Name": "Preston",
                                    "CityId": "PSTA",
                                    "CountryId": "CU",
                                    "Location": "-75.65, 20.73333"
                                }
                            ]
                        },
                        {
                            "Id": "UPAA",
                            "Name": "Punta Alegre",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-78.818889, 22.379722",
                            "IataCode": "UPA",
                            "Airports": [
                                {
                                    "Id": "UPA",
                                    "Name": "Punta Alegre",
                                    "CityId": "UPAA",
                                    "CountryId": "CU",
                                    "Location": "-78.816667, 22.383333"
                                }
                            ]
                        },
                        {
                            "Id": "USSA",
                            "Name": "Sancti Spiritus",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-79.4425, 21.929722",
                            "IataCode": "USS",
                            "Airports": [
                                {
                                    "Id": "USS",
                                    "Name": "Sancti Spiritus",
                                    "CityId": "USSA",
                                    "CountryId": "CU",
                                    "Location": "-79.55, 21.866667"
                                }
                            ]
                        },
                        {
                            "Id": "HAVA",
                            "Name": "Havana",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-82.325761, 23.178234",
                            "IataCode": "HAV",
                            "Airports": [
                                {
                                    "Id": "HAV",
                                    "Name": "Havana",
                                    "CityId": "HAVA",
                                    "CountryId": "CU",
                                    "Location": "-82.4075, 22.989444"
                                }
                            ]
                        },
                        {
                            "Id": "VROA",
                            "Name": "Matanzas",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-81.5775, 23.041111",
                            "IataCode": "VRO",
                            "Airports": [
                                {
                                    "Id": "VRO",
                                    "Name": "Matanzas",
                                    "CityId": "VROA",
                                    "CountryId": "CU",
                                    "Location": "-81.30157, 23.12367"
                                }
                            ]
                        },
                        {
                            "Id": "SZJA",
                            "Name": "Siguanea",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-82.959722, 21.631389",
                            "IataCode": "SZJ",
                            "Airports": [
                                {
                                    "Id": "SZJ",
                                    "Name": "Siguanea",
                                    "CityId": "SZJA",
                                    "CountryId": "CU",
                                    "Location": "-82.95, 21.633333"
                                }
                            ]
                        },
                        {
                            "Id": "SNJA",
                            "Name": "San Julian",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-84.15, 22.1",
                            "IataCode": "SNJ",
                            "Airports": [
                                {
                                    "Id": "SNJ",
                                    "Name": "San Julian",
                                    "CityId": "SNJA",
                                    "CountryId": "CU",
                                    "Location": "-84.15, 22.083333"
                                }
                            ]
                        },
                        {
                            "Id": "VTUA",
                            "Name": "Las Tunas",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-76.951111, 20.961667",
                            "IataCode": "VTU",
                            "Airports": [
                                {
                                    "Id": "VTU",
                                    "Name": "Las Tunas",
                                    "CityId": "VTUA",
                                    "CountryId": "CU",
                                    "Location": "-76.9358, 20.98764"
                                }
                            ]
                        },
                        {
                            "Id": "CYOA",
                            "Name": "Cayo Largo Del Sur",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-81.466667, 21.633333",
                            "IataCode": "CYO",
                            "Airports": [
                                {
                                    "Id": "CYO",
                                    "Name": "Cayo Largo Del Sur",
                                    "CityId": "CYOA",
                                    "CountryId": "CU",
                                    "Location": "-81.54599, 21.61645"
                                }
                            ]
                        },
                        {
                            "Id": "CCCA",
                            "Name": "Cayo Coco",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-78.511389, 22.514722",
                            "IataCode": "CCC",
                            "Airports": [
                                {
                                    "Id": "CCC",
                                    "Name": "Cayo Coco",
                                    "CityId": "CCCA",
                                    "CountryId": "CU",
                                    "Location": "-78.508333, 22.5125"
                                }
                            ]
                        },
                        {
                            "Id": "BANY",
                            "Name": "Cayo Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-78.97737, 22.64903",
                            "IataCode": "BWW",
                            "Airports": [
                                {
                                    "Id": "BWW",
                                    "Name": "Las Brujas",
                                    "CityId": "BANY",
                                    "CountryId": "CU",
                                    "Location": "-79.147222, 22.621111"
                                }
                            ]
                        },
                        {
                            "Id": "UPBA",
                            "Name": "Playa Baracoa",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-82.562222, 23.052222",
                            "IataCode": "UPB",
                            "Airports": [
                                {
                                    "Id": "UPB",
                                    "Name": "Havana",
                                    "CityId": "UPBA",
                                    "CountryId": "CU",
                                    "Location": "-82.579444, 23.032778"
                                }
                            ]
                        },
                        {
                            "Id": "UMAA",
                            "Name": "Maisí",
                            "SingleAirportCity": true,
                            "CountryId": "CU",
                            "Location": "-74.15, 20.249444",
                            "IataCode": "UMA",
                            "Airports": [
                                {
                                    "Id": "UMA",
                                    "Name": "Punta De Maisi",
                                    "CityId": "UMAA",
                                    "CountryId": "CU",
                                    "Location": "-74.147222, 20.243056"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CR",
                    "Name": "Costa Rica",
                    "CurrencyId": "CRC",
                    "Cities": [
                        {
                            "Id": "XQPA",
                            "Name": "Quepos",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-84.15, 9.45",
                            "IataCode": "XQP",
                            "Airports": [
                                {
                                    "Id": "XQP",
                                    "Name": "Quepos",
                                    "CityId": "XQPA",
                                    "CountryId": "CR",
                                    "Location": "-84.15, 9.45"
                                }
                            ]
                        },
                        {
                            "Id": "UPLA",
                            "Name": "Upala",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.033333, 10.9",
                            "IataCode": "UPL",
                            "Airports": [
                                {
                                    "Id": "UPL",
                                    "Name": "Upala",
                                    "CityId": "UPLA",
                                    "CountryId": "CR",
                                    "Location": "-85.033333, 10.783333"
                                }
                            ]
                        },
                        {
                            "Id": "GPLA",
                            "Name": "Guapiles",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.783333, 10.216667",
                            "IataCode": "GPL",
                            "Airports": [
                                {
                                    "Id": "GPL",
                                    "Name": "Guapiles",
                                    "CityId": "GPLA",
                                    "CountryId": "CR",
                                    "Location": "-83.8, 10.2"
                                }
                            ]
                        },
                        {
                            "Id": "FONA",
                            "Name": "Fortuna",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-84.65, 10.483333",
                            "IataCode": "FON",
                            "Airports": [
                                {
                                    "Id": "FON",
                                    "Name": "Fortuna",
                                    "CityId": "FONA",
                                    "CountryId": "CR",
                                    "Location": "-84.483333, 10.4"
                                }
                            ]
                        },
                        {
                            "Id": "GLFA",
                            "Name": "Golfito",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.15, 8.65",
                            "IataCode": "GLF",
                            "Airports": [
                                {
                                    "Id": "GLF",
                                    "Name": "Golfito",
                                    "CityId": "GLFA",
                                    "CountryId": "CR",
                                    "Location": "-83.180833, 8.651389"
                                }
                            ]
                        },
                        {
                            "Id": "LIOA",
                            "Name": "Limon",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.033333, 10",
                            "IataCode": "LIO",
                            "Airports": [
                                {
                                    "Id": "LIO",
                                    "Name": "Limon",
                                    "CityId": "LIOA",
                                    "CountryId": "CR",
                                    "Location": "-83.021111, 9.955833"
                                }
                            ]
                        },
                        {
                            "Id": "LIRA",
                            "Name": "Liberia",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.433333, 10.633333",
                            "IataCode": "LIR",
                            "Airports": [
                                {
                                    "Id": "LIR",
                                    "Name": "Liberia",
                                    "CityId": "LIRA",
                                    "CountryId": "CR",
                                    "Location": "-85.55, 10.589722"
                                }
                            ]
                        },
                        {
                            "Id": "PJMA",
                            "Name": "Puerto Jimenez",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.316667, 8.5333333",
                            "IataCode": "PJM",
                            "Airports": [
                                {
                                    "Id": "PJM",
                                    "Name": "Puerto Jimenez",
                                    "CityId": "PJMA",
                                    "CountryId": "CR",
                                    "Location": "-83.333333, 8.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PMZA",
                            "Name": "Palmar",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.45, 8.9666667",
                            "IataCode": "PMZ",
                            "Airports": [
                                {
                                    "Id": "PMZ",
                                    "Name": "Palmar",
                                    "CityId": "PMZA",
                                    "CountryId": "CR",
                                    "Location": "-83.470278, 8.953056"
                                }
                            ]
                        },
                        {
                            "Id": "SJOA",
                            "Name": "San Jose",
                            "SingleAirportCity": false,
                            "CountryId": "CR",
                            "Location": "-84.083333, 9.9333333",
                            "IataCode": "SJO",
                            "Airports": [
                                {
                                    "Id": "SYQ",
                                    "Name": "San Jose Tobias Bolanos",
                                    "CityId": "SJOA",
                                    "CountryId": "CR",
                                    "Location": "-84.1398, 9.95705"
                                },
                                {
                                    "Id": "SJO",
                                    "Name": "San Jose Juan Santamaria",
                                    "CityId": "SJOA",
                                    "CountryId": "CR",
                                    "Location": "-84.2, 10"
                                }
                            ]
                        },
                        {
                            "Id": "TMUA",
                            "Name": "Tambor",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.033333, 9.7166667",
                            "IataCode": "TMU",
                            "Airports": [
                                {
                                    "Id": "TMU",
                                    "Name": "Tambor",
                                    "CityId": "TMUA",
                                    "CountryId": "CR",
                                    "Location": "-85.016667, 9.733333"
                                }
                            ]
                        },
                        {
                            "Id": "TNOA",
                            "Name": "Tamarindo",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.82571, 10.304448",
                            "IataCode": "TNO",
                            "Airports": [
                                {
                                    "Id": "TNO",
                                    "Name": "Tamarindo",
                                    "CityId": "TNOA",
                                    "CountryId": "CR",
                                    "Location": "-85.81541, 10.31495"
                                }
                            ]
                        },
                        {
                            "Id": "BAIB",
                            "Name": "Buenos Aires",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.333333, 9.1666667",
                            "IataCode": "BAI",
                            "Airports": [
                                {
                                    "Id": "BAI",
                                    "Name": "Buenos Aires",
                                    "CityId": "BAIB",
                                    "CountryId": "CR",
                                    "Location": "-83.333333, 9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "NCTA",
                            "Name": "Nicoya",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.45, 10.15",
                            "IataCode": "NCT",
                            "Airports": [
                                {
                                    "Id": "NCT",
                                    "Name": "Nicoya",
                                    "CityId": "NCTA",
                                    "CountryId": "CR",
                                    "Location": "-85.445, 10.138889"
                                }
                            ]
                        },
                        {
                            "Id": "SZCA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.583333, 10.266667",
                            "IataCode": "SZC",
                            "Airports": [
                                {
                                    "Id": "SZC",
                                    "Name": "Santa Cruz",
                                    "CityId": "SZCA",
                                    "CountryId": "CR",
                                    "Location": "-85.584167, 10.254722"
                                }
                            ]
                        },
                        {
                            "Id": "LCSA",
                            "Name": "Las Canas",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.1, 10.433333",
                            "IataCode": "LCS",
                            "Airports": [
                                {
                                    "Id": "LCS",
                                    "Name": "Las Canas",
                                    "CityId": "LCSA",
                                    "CountryId": "CR",
                                    "Location": "-85.104167, 10.418889"
                                }
                            ]
                        },
                        {
                            "Id": "TOOA",
                            "Name": "San Vito",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-82.966667, 8.8333333",
                            "IataCode": "TOO",
                            "Airports": [
                                {
                                    "Id": "TOO",
                                    "Name": "San Vito",
                                    "CityId": "TOOA",
                                    "CountryId": "CR",
                                    "Location": "-82.966667, 8.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CSCA",
                            "Name": "Canas",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.483333, 10.733333",
                            "IataCode": "CSC",
                            "Airports": [
                                {
                                    "Id": "CSC",
                                    "Name": "Canas",
                                    "CityId": "CSCA",
                                    "CountryId": "CR",
                                    "Location": "-85.483333, 10.766667"
                                }
                            ]
                        },
                        {
                            "Id": "PBPA",
                            "Name": "Punta Islita",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.4, 9.85",
                            "IataCode": "PBP",
                            "Airports": [
                                {
                                    "Id": "PBP",
                                    "Name": "Punta Islita",
                                    "CityId": "PBPA",
                                    "CountryId": "CR",
                                    "Location": "-85.3708, 9.85611"
                                }
                            ]
                        },
                        {
                            "Id": "NOBA",
                            "Name": "Nosara Beach",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.65, 9.9833333",
                            "IataCode": "NOB",
                            "Airports": [
                                {
                                    "Id": "NOB",
                                    "Name": "Nosara Beach",
                                    "CityId": "NOBA",
                                    "CountryId": "CR",
                                    "Location": "-85.653, 9.97649"
                                }
                            ]
                        },
                        {
                            "Id": "TTQA",
                            "Name": "Tortuquero",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.516667, 10.583333",
                            "IataCode": "TTQ",
                            "Airports": [
                                {
                                    "Id": "TTQ",
                                    "Name": "Tortuquero",
                                    "CityId": "TTQA",
                                    "CountryId": "CR",
                                    "Location": "-83.516667, 10.566667"
                                }
                            ]
                        },
                        {
                            "Id": "RIKA",
                            "Name": "Carrillo",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.466667, 9.8666667",
                            "IataCode": "RIK",
                            "Airports": [
                                {
                                    "Id": "RIK",
                                    "Name": "Carrillo",
                                    "CityId": "RIKA",
                                    "CountryId": "CR",
                                    "Location": "-85.483333, 9.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LSLA",
                            "Name": "Los Chiles",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-84.716667, 11.033333",
                            "IataCode": "LSL",
                            "Airports": [
                                {
                                    "Id": "LSL",
                                    "Name": "Los Chiles",
                                    "CityId": "LSLA",
                                    "CountryId": "CR",
                                    "Location": "-84.7, 11.033333"
                                }
                            ]
                        },
                        {
                            "Id": "OTRA",
                            "Name": "Coto 47",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.746305, 9.1361906",
                            "IataCode": "OTR",
                            "Airports": [
                                {
                                    "Id": "OTR",
                                    "Name": "Coto 47",
                                    "CityId": "OTRA",
                                    "CountryId": "CR",
                                    "Location": "-82.9686, 8.60156"
                                }
                            ]
                        },
                        {
                            "Id": "IPZC",
                            "Name": "San Isidro de El General",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.7, 9.3833333",
                            "IataCode": "IPZ",
                            "Airports": [
                                {
                                    "Id": "IPZ",
                                    "Name": "San Isidro del General",
                                    "CityId": "IPZC",
                                    "CountryId": "CR",
                                    "Location": "-83.7125, 9.348611"
                                }
                            ]
                        },
                        {
                            "Id": "BCLA",
                            "Name": "Barra Colorado",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.6, 10.783333",
                            "IataCode": "BCL",
                            "Airports": [
                                {
                                    "Id": "BCL",
                                    "Name": "Barra Colorado",
                                    "CityId": "BCLA",
                                    "CountryId": "CR",
                                    "Location": "-82.966667, 10.833333"
                                }
                            ]
                        },
                        {
                            "Id": "DRAB",
                            "Name": "Drake Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.67193, 8.6918",
                            "IataCode": "DRK",
                            "Airports": [
                                {
                                    "Id": "DRK",
                                    "Name": "Drake Bay",
                                    "CityId": "DRAB",
                                    "CountryId": "CR",
                                    "Location": "-83.622437, 8.700499"
                                }
                            ]
                        },
                        {
                            "Id": "JAPA",
                            "Name": "Puntarenas",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-84.838357, 9.9762473",
                            "IataCode": "JAP",
                            "Airports": [
                                {
                                    "Id": "JAP",
                                    "Name": "Punta Renes",
                                    "CityId": "JAPA",
                                    "CountryId": "CR",
                                    "Location": "-84.833333, 9.966667"
                                }
                            ]
                        },
                        {
                            "Id": "FMGA",
                            "Name": "Brasilito",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.783333, 10.416667",
                            "IataCode": "FMG",
                            "Airports": [
                                {
                                    "Id": "FMG",
                                    "Name": "Flamingo",
                                    "CityId": "FMGA",
                                    "CountryId": "CR",
                                    "Location": "-85.783333, 10.416667"
                                }
                            ]
                        },
                        {
                            "Id": "PLDA",
                            "Name": "Sámara",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-85.533333, 9.8833333",
                            "IataCode": "PLD",
                            "Airports": [
                                {
                                    "Id": "PLD",
                                    "Name": "Playa Samara",
                                    "CityId": "PLDA",
                                    "CountryId": "CR",
                                    "Location": "-85.416667, 10.25"
                                }
                            ]
                        },
                        {
                            "Id": "RFRA",
                            "Name": "El Salvador",
                            "SingleAirportCity": true,
                            "CountryId": "CR",
                            "Location": "-83.9, 10.233333",
                            "IataCode": "RFR",
                            "Airports": [
                                {
                                    "Id": "RFR",
                                    "Name": "Rio Frio",
                                    "CityId": "RFRA",
                                    "CountryId": "CR",
                                    "Location": "-83.883333, 10.333333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "JM",
                    "Name": "Jamaica",
                    "CurrencyId": "JMD",
                    "Cities": [
                        {
                            "Id": "KINA",
                            "Name": "Kingston",
                            "SingleAirportCity": false,
                            "CountryId": "JM",
                            "Location": "-76.793575, 17.997019",
                            "IataCode": "KIN",
                            "Airports": [
                                {
                                    "Id": "KTP",
                                    "Name": "Kingston Tinson",
                                    "CityId": "KINA",
                                    "CountryId": "JM",
                                    "Location": "-76.823333, 17.9875"
                                },
                                {
                                    "Id": "KIN",
                                    "Name": "Kingston Norman Manley",
                                    "CityId": "KINA",
                                    "CountryId": "JM",
                                    "Location": "-76.7875, 17.935556"
                                }
                            ]
                        },
                        {
                            "Id": "MBJA",
                            "Name": "Montego Bay",
                            "SingleAirportCity": true,
                            "CountryId": "JM",
                            "Location": "-77.916667, 18.466667",
                            "IataCode": "MBJ",
                            "Airports": [
                                {
                                    "Id": "MBJ",
                                    "Name": "Montego Bay",
                                    "CityId": "MBJA",
                                    "CountryId": "JM",
                                    "Location": "-77.9125, 18.501944"
                                }
                            ]
                        },
                        {
                            "Id": "NEGA",
                            "Name": "Negril",
                            "SingleAirportCity": true,
                            "CountryId": "JM",
                            "Location": "-78.35, 18.266667",
                            "IataCode": "NEG",
                            "Airports": [
                                {
                                    "Id": "NEG",
                                    "Name": "Negril",
                                    "CityId": "NEGA",
                                    "CountryId": "JM",
                                    "Location": "-78.333333, 18.308333"
                                }
                            ]
                        },
                        {
                            "Id": "OCJA",
                            "Name": "Ocho Rios",
                            "SingleAirportCity": true,
                            "CountryId": "JM",
                            "Location": "-77.104797, 18.402501",
                            "IataCode": "OCJ",
                            "Airports": [
                                {
                                    "Id": "OCJ",
                                    "Name": "Ocho Rios",
                                    "CityId": "OCJA",
                                    "CountryId": "JM",
                                    "Location": "-76.969722, 18.400556"
                                }
                            ]
                        },
                        {
                            "Id": "POTA",
                            "Name": "Port Antonio",
                            "SingleAirportCity": true,
                            "CountryId": "JM",
                            "Location": "-76.466667, 18.183333",
                            "IataCode": "POT",
                            "Airports": [
                                {
                                    "Id": "POT",
                                    "Name": "Port Antonio",
                                    "CityId": "POTA",
                                    "CountryId": "JM",
                                    "Location": "-76.535556, 18.198333"
                                }
                            ]
                        },
                        {
                            "Id": "MVJA",
                            "Name": "Mandeville",
                            "SingleAirportCity": true,
                            "CountryId": "JM",
                            "Location": "-77.5, 18.033333",
                            "IataCode": "MVJ",
                            "Airports": [
                                {
                                    "Id": "MVJ",
                                    "Name": "Mandeville",
                                    "CityId": "MVJA",
                                    "CountryId": "JM",
                                    "Location": "-77.495278, 18.021667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SV",
                    "Name": "El Salvador",
                    "CurrencyId": "SVC",
                    "Cities": [
                        {
                            "Id": "SALB",
                            "Name": "San Salvador",
                            "SingleAirportCity": true,
                            "CountryId": "SV",
                            "Location": "-89.203055, 13.708611",
                            "IataCode": "SAL",
                            "Airports": [
                                {
                                    "Id": "SAL",
                                    "Name": "San Salvador",
                                    "CityId": "SALB",
                                    "CountryId": "SV",
                                    "Location": "-89.053974, 13.441883"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MS",
                    "Name": "Montserrat",
                    "CurrencyId": "XCD",
                    "Cities": [
                        {
                            "Id": "MNIA",
                            "Name": "Montserrat",
                            "SingleAirportCity": true,
                            "CountryId": "MS",
                            "Location": "-62.2, 16.75",
                            "IataCode": "MNI",
                            "Airports": [
                                {
                                    "Id": "MNI",
                                    "Name": "Montserrat",
                                    "CityId": "MNIA",
                                    "CountryId": "MS",
                                    "Location": "-62.233333, 16.75"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PA",
                    "Name": "Panama",
                    "CurrencyId": "PAB",
                    "Cities": [
                        {
                            "Id": "BOCA",
                            "Name": "Bocas Del Toro",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-82.25, 9.3333333",
                            "IataCode": "BOC",
                            "Airports": [
                                {
                                    "Id": "BOC",
                                    "Name": "Bocas Del Toro",
                                    "CityId": "BOCA",
                                    "CountryId": "PA",
                                    "Location": "-82.251944, 9.34"
                                }
                            ]
                        },
                        {
                            "Id": "CHXA",
                            "Name": "Changuinola",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-82.516667, 9.4333333",
                            "IataCode": "CHX",
                            "Airports": [
                                {
                                    "Id": "CHX",
                                    "Name": "Changuinola",
                                    "CityId": "CHXA",
                                    "CountryId": "PA",
                                    "Location": "-82.515714, 9.458311"
                                }
                            ]
                        },
                        {
                            "Id": "CTDA",
                            "Name": "Chitre",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.433333, 7.9666667",
                            "IataCode": "CTD",
                            "Airports": [
                                {
                                    "Id": "CTD",
                                    "Name": "Chitre",
                                    "CityId": "CTDA",
                                    "CountryId": "PA",
                                    "Location": "-80.433333, 7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CZJA",
                            "Name": "Corazon De Jesus",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.583333, 9.45",
                            "IataCode": "CZJ",
                            "Airports": [
                                {
                                    "Id": "CZJ",
                                    "Name": "Corazon De Jesus",
                                    "CityId": "CZJA",
                                    "CountryId": "PA",
                                    "Location": "-79.368611, 9.570556"
                                }
                            ]
                        },
                        {
                            "Id": "DAVA",
                            "Name": "David",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-82.433333, 8.4333333",
                            "IataCode": "DAV",
                            "Airports": [
                                {
                                    "Id": "DAV",
                                    "Name": "David",
                                    "CityId": "DAVA",
                                    "CountryId": "PA",
                                    "Location": "-82.433333, 8.383333"
                                }
                            ]
                        },
                        {
                            "Id": "ELEA",
                            "Name": "El Real",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.716667, 8.1333333",
                            "IataCode": "ELE",
                            "Airports": [
                                {
                                    "Id": "ELE",
                                    "Name": "El Real",
                                    "CityId": "ELEA",
                                    "CountryId": "PA",
                                    "Location": "-77.833333, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GHEA",
                            "Name": "Garachine",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.362778, 8.0647222",
                            "IataCode": "GHE",
                            "Airports": [
                                {
                                    "Id": "GHE",
                                    "Name": "Garachine",
                                    "CityId": "GHEA",
                                    "CountryId": "PA",
                                    "Location": "-78.366766, 8.066921"
                                }
                            ]
                        },
                        {
                            "Id": "JQEA",
                            "Name": "Jaque",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.170278, 7.5175",
                            "IataCode": "JQE",
                            "Airports": [
                                {
                                    "Id": "JQE",
                                    "Name": "Jaque",
                                    "CityId": "JQEA",
                                    "CountryId": "PA",
                                    "Location": "-78.1572, 7.51778"
                                }
                            ]
                        },
                        {
                            "Id": "ONXA",
                            "Name": "Colon",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-79.901389, 9.3591667",
                            "IataCode": "ONX",
                            "Airports": [
                                {
                                    "Id": "ONX",
                                    "Name": "Colon",
                                    "CityId": "ONXA",
                                    "CountryId": "PA",
                                    "Location": "-79.9, 9.35"
                                }
                            ]
                        },
                        {
                            "Id": "SYPA",
                            "Name": "Santiago",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.983333, 8.1",
                            "IataCode": "SYP",
                            "Airports": [
                                {
                                    "Id": "SYP",
                                    "Name": "Santiago",
                                    "CityId": "SYPA",
                                    "CountryId": "PA",
                                    "Location": "-80.94525, 8.0856"
                                }
                            ]
                        },
                        {
                            "Id": "AILA",
                            "Name": "Ailigandi",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.016667, 9.2333333",
                            "IataCode": "AIL",
                            "Airports": [
                                {
                                    "Id": "AIL",
                                    "Name": "Ailigandi",
                                    "CityId": "AILA",
                                    "CountryId": "PA",
                                    "Location": "-78.083333, 9.25"
                                }
                            ]
                        },
                        {
                            "Id": "PLPA",
                            "Name": "La Palma",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.145278, 8.4027778",
                            "IataCode": "PLP",
                            "Airports": [
                                {
                                    "Id": "PLP",
                                    "Name": "La Palma",
                                    "CityId": "PLPA",
                                    "CountryId": "PA",
                                    "Location": "-78.133333, 8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "ACUA",
                            "Name": "Achutupo",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.983333, 9.2",
                            "IataCode": "ACU",
                            "Airports": [
                                {
                                    "Id": "ACU",
                                    "Name": "Achutupo",
                                    "CityId": "ACUA",
                                    "CountryId": "PA",
                                    "Location": "-77.993778, 9.190904"
                                }
                            ]
                        },
                        {
                            "Id": "MPPA",
                            "Name": "Mulatupo",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.75, 8.95",
                            "IataCode": "MPP",
                            "Airports": [
                                {
                                    "Id": "MPP",
                                    "Name": "Mulatupo",
                                    "CityId": "MPPA",
                                    "CountryId": "PA",
                                    "Location": "-77.733454, 8.945568"
                                }
                            ]
                        },
                        {
                            "Id": "NGNA",
                            "Name": "Nargana",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.590556, 9.4427778",
                            "IataCode": "NGN",
                            "Airports": [
                                {
                                    "Id": "NGN",
                                    "Name": "Nargana",
                                    "CityId": "NGNA",
                                    "CountryId": "PA",
                                    "Location": "-79.25, 9.568611"
                                }
                            ]
                        },
                        {
                            "Id": "PUEA",
                            "Name": "Puerto Obaldia",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.416667, 8.6666667",
                            "IataCode": "PUE",
                            "Airports": [
                                {
                                    "Id": "PUE",
                                    "Name": "Puerto Obaldia",
                                    "CityId": "PUEA",
                                    "CountryId": "PA",
                                    "Location": "-77.418333, 8.667222"
                                }
                            ]
                        },
                        {
                            "Id": "PYVA",
                            "Name": "Yaviza",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.683333, 8.1833333",
                            "IataCode": "PYV",
                            "Airports": [
                                {
                                    "Id": "PYV",
                                    "Name": "Yaviza",
                                    "CityId": "PYVA",
                                    "CountryId": "PA",
                                    "Location": "-77.833333, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SFWA",
                            "Name": "Santa Fe",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-81.083333, 8.5166667",
                            "IataCode": "SFW",
                            "Airports": [
                                {
                                    "Id": "SFW",
                                    "Name": "Santa Fe",
                                    "CityId": "SFWA",
                                    "CountryId": "PA",
                                    "Location": "-81.166667, 8.833333"
                                }
                            ]
                        },
                        {
                            "Id": "TUEA",
                            "Name": "Tupile",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.566667, 9.45",
                            "IataCode": "TUE",
                            "Airports": [
                                {
                                    "Id": "TUE",
                                    "Name": "Tupile",
                                    "CityId": "TUEA",
                                    "CountryId": "PA",
                                    "Location": "-79.487222, 9.5725"
                                }
                            ]
                        },
                        {
                            "Id": "OGMA",
                            "Name": "Ustupo Island",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.933333, 9.1333333",
                            "IataCode": "OGM",
                            "Airports": [
                                {
                                    "Id": "OGM",
                                    "Name": "Ustupo Ogobsucum",
                                    "CityId": "OGMA",
                                    "CountryId": "PA",
                                    "Location": "-77.932618, 9.136465"
                                }
                            ]
                        },
                        {
                            "Id": "PTYA",
                            "Name": "Panama City",
                            "SingleAirportCity": false,
                            "CountryId": "PA",
                            "Location": "-79.533333, 8.9666667",
                            "IataCode": "PTY",
                            "Airports": [
                                {
                                    "Id": "BLB",
                                    "Name": "Balboa",
                                    "CityId": "PTYA",
                                    "CountryId": "PA",
                                    "Location": "-79.5575, 8.973056"
                                },
                                {
                                    "Id": "PAC",
                                    "Name": "Panama City Paitilla",
                                    "CityId": "PTYA",
                                    "CountryId": "PA",
                                    "Location": "-79.55558, 8.97334"
                                },
                                {
                                    "Id": "PTY",
                                    "Name": "Panama City Tocumen International",
                                    "CityId": "PTYA",
                                    "CountryId": "PA",
                                    "Location": "-79.383333, 9.083333"
                                }
                            ]
                        },
                        {
                            "Id": "RIHA",
                            "Name": "Río Hato",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.166667, 8.3833333",
                            "IataCode": "RIH",
                            "Airports": [
                                {
                                    "Id": "RIH",
                                    "Name": "Scarlett Martinez International",
                                    "CityId": "RIHA",
                                    "CountryId": "PA",
                                    "Location": "-80.074, 8.2233"
                                }
                            ]
                        },
                        {
                            "Id": "NBLA",
                            "Name": "San Blas",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.350126, 9.2127256",
                            "IataCode": "NBL",
                            "Airports": [
                                {
                                    "Id": "NBL",
                                    "Name": "San Blas",
                                    "CityId": "NBLA",
                                    "CountryId": "PA",
                                    "Location": "-79, 9.3"
                                }
                            ]
                        },
                        {
                            "Id": "NMGA",
                            "Name": "San Miguel",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.283333, 8.4666667",
                            "IataCode": "NMG",
                            "Airports": [
                                {
                                    "Id": "NMG",
                                    "Name": "San Miguel",
                                    "CityId": "NMGA",
                                    "CountryId": "PA",
                                    "Location": "-78.93333, 8.45"
                                }
                            ]
                        },
                        {
                            "Id": "UTUA",
                            "Name": "Ustupo",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.566667, 9.45",
                            "IataCode": "UTU",
                            "Airports": [
                                {
                                    "Id": "UTU",
                                    "Name": "Ustupo",
                                    "CityId": "UTUA",
                                    "CountryId": "PA",
                                    "Location": "-78.833333, 9.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SICA",
                            "Name": "San Jose Island",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-79.266667, 9.0666667",
                            "IataCode": "SIC",
                            "Airports": [
                                {
                                    "Id": "SIC",
                                    "Name": "San Jose Island",
                                    "CityId": "SICA",
                                    "CountryId": "PA",
                                    "Location": "-79.080076, 8.263402"
                                }
                            ]
                        },
                        {
                            "Id": "AMLA",
                            "Name": "Puerto Armuellas",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-82.866667, 8.2833333",
                            "IataCode": "AML",
                            "Airports": [
                                {
                                    "Id": "AML",
                                    "Name": "Puerto Armuellas",
                                    "CityId": "AMLA",
                                    "CountryId": "PA",
                                    "Location": "-82.866667, 8.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MPIA",
                            "Name": "Mamitupo",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.969722, 9.5502778",
                            "IataCode": "MPI",
                            "Airports": [
                                {
                                    "Id": "MPI",
                                    "Name": "Mamitupo",
                                    "CityId": "MPIA",
                                    "CountryId": "PA",
                                    "Location": "-79.843056, 9.578333"
                                }
                            ]
                        },
                        {
                            "Id": "HOWA",
                            "Name": "Fort Kobbe",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-79.589167, 8.9122917",
                            "IataCode": "HOW",
                            "Airports": [
                                {
                                    "Id": "HOW",
                                    "Name": "Fort Kobbe",
                                    "CityId": "HOWA",
                                    "CountryId": "PA",
                                    "Location": "-79.59972, 8.91667"
                                }
                            ]
                        },
                        {
                            "Id": "PVEA",
                            "Name": "El Porvenir",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.133611, 9.2038889",
                            "IataCode": "PVE",
                            "Airports": [
                                {
                                    "Id": "PVE",
                                    "Name": "El Porvenir",
                                    "CityId": "PVEA",
                                    "CountryId": "PA",
                                    "Location": "-81, 9"
                                }
                            ]
                        },
                        {
                            "Id": "OTDA",
                            "Name": "Contadora",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-79.033333, 8.6333333",
                            "IataCode": "OTD",
                            "Airports": [
                                {
                                    "Id": "OTD",
                                    "Name": "Contadora",
                                    "CityId": "OTDA",
                                    "CountryId": "PA",
                                    "Location": "-79.0347, 8.62876"
                                }
                            ]
                        },
                        {
                            "Id": "PYCA",
                            "Name": "Playon Chico",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-79.516667, 9.2666667",
                            "IataCode": "PYC",
                            "Airports": [
                                {
                                    "Id": "PYC",
                                    "Name": "Playon Chico",
                                    "CityId": "PYCA",
                                    "CountryId": "PA",
                                    "Location": "-79.605833, 9.574444"
                                }
                            ]
                        },
                        {
                            "Id": "SAXA",
                            "Name": "Sambu",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.205556, 8.0222222",
                            "IataCode": "SAX",
                            "Airports": [
                                {
                                    "Id": "SAX",
                                    "Name": "Sambu",
                                    "CityId": "SAXA",
                                    "CountryId": "PA",
                                    "Location": "-78.083333, 8.033333"
                                }
                            ]
                        },
                        {
                            "Id": "RSIA",
                            "Name": "Valle Escobal",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.333333, 8.9333333",
                            "IataCode": "RSI",
                            "Airports": [
                                {
                                    "Id": "RSI",
                                    "Name": "Rio Sidra",
                                    "CityId": "RSIA",
                                    "CountryId": "PA",
                                    "Location": "-80.333333, 8.966667"
                                }
                            ]
                        },
                        {
                            "Id": "TJCA",
                            "Name": "Ticantiquí",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.483333, 9.4166667",
                            "IataCode": "TJC",
                            "Airports": [
                                {
                                    "Id": "TJC",
                                    "Name": "Ticantiki",
                                    "CityId": "TJCA",
                                    "CountryId": "PA",
                                    "Location": "-78.488889, 9.408333"
                                }
                            ]
                        },
                        {
                            "Id": "TUWA",
                            "Name": "Sukunya",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-77.633333, 8.8333333",
                            "IataCode": "TUW",
                            "Airports": [
                                {
                                    "Id": "TUW",
                                    "Name": "Tubala",
                                    "CityId": "TUWA",
                                    "CountryId": "PA",
                                    "Location": "-77.666667, 8.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RITA",
                            "Name": "Río Parita",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.8, 7.8333333",
                            "IataCode": "RIT",
                            "Airports": [
                                {
                                    "Id": "RIT",
                                    "Name": "Rio Tigre",
                                    "CityId": "RITA",
                                    "CountryId": "PA",
                                    "Location": "-80.8, 7.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BFQA",
                            "Name": "Puerto Piña",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-78.166667, 7.583333",
                            "IataCode": "BFQ",
                            "Airports": [
                                {
                                    "Id": "BFQ",
                                    "Name": "Bahia Pinas",
                                    "CityId": "BFQA",
                                    "CountryId": "PA",
                                    "Location": "-78.166667, 7.583333"
                                }
                            ]
                        },
                        {
                            "Id": "CTEA",
                            "Name": "Belén",
                            "SingleAirportCity": true,
                            "CountryId": "PA",
                            "Location": "-80.866667, 8.8833333",
                            "IataCode": "CTE",
                            "Airports": [
                                {
                                    "Id": "CTE",
                                    "Name": "Carti",
                                    "CityId": "CTEA",
                                    "CountryId": "PA",
                                    "Location": "-80.833333, 8.833333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MQ",
                    "Name": "Martinique",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "FDFA",
                            "Name": "Fort De France",
                            "SingleAirportCity": true,
                            "CountryId": "MQ",
                            "Location": "-61.083333, 14.6",
                            "IataCode": "FDF",
                            "Airports": [
                                {
                                    "Id": "FDF",
                                    "Name": "Fort De France",
                                    "CityId": "FDFA",
                                    "CountryId": "MQ",
                                    "Location": "-61.006111, 14.589722"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "S",
            "Name": "South America",
            "Countries": [
                {
                    "Id": "PY",
                    "Name": "Paraguay",
                    "CurrencyId": "PYG",
                    "Cities": [
                        {
                            "Id": "AGTA",
                            "Name": "Ciudad del Este",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-54.616667, -25.516667",
                            "IataCode": "AGT",
                            "Airports": [
                                {
                                    "Id": "AGT",
                                    "Name": "Ciudad del Este",
                                    "CityId": "AGTA",
                                    "CountryId": "PY",
                                    "Location": "-54.843611, -25.461415"
                                }
                            ]
                        },
                        {
                            "Id": "FLMA",
                            "Name": "Filadelfia",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-60.033333, -22.35",
                            "IataCode": "FLM",
                            "Airports": [
                                {
                                    "Id": "FLM",
                                    "Name": "Filadelfia",
                                    "CityId": "FLMA",
                                    "CountryId": "PY",
                                    "Location": "-60.033333, -22.35"
                                }
                            ]
                        },
                        {
                            "Id": "PILA",
                            "Name": "Pilar",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-58.383333, -26.866667",
                            "IataCode": "PIL",
                            "Airports": [
                                {
                                    "Id": "PIL",
                                    "Name": "Pilar",
                                    "CityId": "PILA",
                                    "CountryId": "PY",
                                    "Location": "-58.333333, -26.866667"
                                }
                            ]
                        },
                        {
                            "Id": "ENOA",
                            "Name": "Encarnacion",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-55.9, -27.333333",
                            "IataCode": "ENO",
                            "Airports": [
                                {
                                    "Id": "ENO",
                                    "Name": "Encarnacion",
                                    "CityId": "ENOA",
                                    "CountryId": "PY",
                                    "Location": "-55.833333, -27.25"
                                }
                            ]
                        },
                        {
                            "Id": "ESGA",
                            "Name": "Mariscal Estigarribia",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-60.633333, -22.033333",
                            "IataCode": "ESG",
                            "Airports": [
                                {
                                    "Id": "ESG",
                                    "Name": "Mariscal Estigarribia",
                                    "CityId": "ESGA",
                                    "CountryId": "PY",
                                    "Location": "-60.616667, -22.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ASUA",
                            "Name": "Asuncion",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.666666, -25.266669",
                            "IataCode": "ASU",
                            "Airports": [
                                {
                                    "Id": "ASU",
                                    "Name": "Asuncion",
                                    "CityId": "ASUA",
                                    "CountryId": "PY",
                                    "Location": "-57.519167, -25.239167"
                                }
                            ]
                        },
                        {
                            "Id": "PJCA",
                            "Name": "Pedro Juan Caballero",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-55.616667, -22.566667",
                            "IataCode": "PJC",
                            "Airports": [
                                {
                                    "Id": "PJC",
                                    "Name": "Pedro Juan Caballero",
                                    "CityId": "PJCA",
                                    "CountryId": "PY",
                                    "Location": "-55.83, -22.64"
                                }
                            ]
                        },
                        {
                            "Id": "PBTA",
                            "Name": "Puerto Leda",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.983333, -20.716667",
                            "IataCode": "PBT",
                            "Airports": [
                                {
                                    "Id": "PBT",
                                    "Name": "Puerto Leda",
                                    "CityId": "PBTA",
                                    "CountryId": "PY",
                                    "Location": "-58.026944, -20.616667"
                                }
                            ]
                        },
                        {
                            "Id": "PCJA",
                            "Name": "Puerto La Victoria",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.916667, -22.333333",
                            "IataCode": "PCJ",
                            "Airports": [
                                {
                                    "Id": "PCJ",
                                    "Name": "Puerto La Victoria",
                                    "CityId": "PCJA",
                                    "CountryId": "PY",
                                    "Location": "-57.928611, -22.268889"
                                }
                            ]
                        },
                        {
                            "Id": "BFAA",
                            "Name": "Bahia Negra",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-58.168573, -20.229664",
                            "IataCode": "BFA",
                            "Airports": [
                                {
                                    "Id": "BFA",
                                    "Name": "Bahia Negra",
                                    "CityId": "BFAA",
                                    "CountryId": "PY",
                                    "Location": "-58.170278, -20.232778"
                                }
                            ]
                        },
                        {
                            "Id": "OLKA",
                            "Name": "Fuerte Olimpo",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.873766, -21.041529",
                            "IataCode": "OLK",
                            "Airports": [
                                {
                                    "Id": "OLK",
                                    "Name": "Fuerte Olimpo",
                                    "CityId": "OLKA",
                                    "CountryId": "PY",
                                    "Location": "-57.883056, -21.996944"
                                }
                            ]
                        },
                        {
                            "Id": "CIOA",
                            "Name": "Concepcion",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.434444, -23.406389",
                            "IataCode": "CIO",
                            "Airports": [
                                {
                                    "Id": "CIO",
                                    "Name": "Concepcion",
                                    "CityId": "CIOA",
                                    "CountryId": "PY",
                                    "Location": "-57.418333, -23.434444"
                                }
                            ]
                        },
                        {
                            "Id": "AYOA",
                            "Name": "Ayolas",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-56.9, -27.4",
                            "IataCode": "AYO",
                            "Airports": [
                                {
                                    "Id": "AYO",
                                    "Name": "Ayolas",
                                    "CityId": "AYOA",
                                    "CountryId": "PY",
                                    "Location": "-56.85, -27.366667"
                                }
                            ]
                        },
                        {
                            "Id": "VMIA",
                            "Name": "Puerto Valle Mi",
                            "SingleAirportCity": true,
                            "CountryId": "PY",
                            "Location": "-57.966667, -22.133333",
                            "IataCode": "VMI",
                            "Airports": [
                                {
                                    "Id": "VMI",
                                    "Name": "Vallemi",
                                    "CityId": "VMIA",
                                    "CountryId": "PY",
                                    "Location": "-57.94258, -22.15911"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GF",
                    "Name": "French Guiana",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "XAUA",
                            "Name": "Saul",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-53.2, 3.6166667",
                            "IataCode": "XAU",
                            "Airports": [
                                {
                                    "Id": "XAU",
                                    "Name": "Saul",
                                    "CityId": "XAUA",
                                    "CountryId": "GF",
                                    "Location": "-53.2, 3.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CAYA",
                            "Name": "Cayenne",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-52.333333, 4.9333333",
                            "IataCode": "CAY",
                            "Airports": [
                                {
                                    "Id": "CAY",
                                    "Name": "Cayenne",
                                    "CityId": "CAYA",
                                    "CountryId": "GF",
                                    "Location": "-52.366667, 4.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MPYA",
                            "Name": "Maripasoula",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-54.033333, 3.6333333",
                            "IataCode": "MPY",
                            "Airports": [
                                {
                                    "Id": "MPY",
                                    "Name": "Maripasoula",
                                    "CityId": "MPYA",
                                    "CountryId": "GF",
                                    "Location": "-54.033333, 3.666667"
                                }
                            ]
                        },
                        {
                            "Id": "REIA",
                            "Name": "Regina",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-52.133333, 4.3166667",
                            "IataCode": "REI",
                            "Airports": [
                                {
                                    "Id": "REI",
                                    "Name": "Regina",
                                    "CityId": "REIA",
                                    "CountryId": "GF",
                                    "Location": "-52.133333, 4.316667"
                                }
                            ]
                        },
                        {
                            "Id": "LDXA",
                            "Name": "St Laurent du Maroni",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-54.033333, 5.5",
                            "IataCode": "LDX",
                            "Airports": [
                                {
                                    "Id": "LDX",
                                    "Name": "St Laurent du Maroni",
                                    "CityId": "LDXA",
                                    "CountryId": "GF",
                                    "Location": "-54.05, 5.483333"
                                }
                            ]
                        },
                        {
                            "Id": "OYPA",
                            "Name": "Saint-Georges-de-l'Oyapock",
                            "SingleAirportCity": true,
                            "CountryId": "GF",
                            "Location": "-51.8, 3.9",
                            "IataCode": "OYP",
                            "Airports": [
                                {
                                    "Id": "OYP",
                                    "Name": "St Georges de lOyapock",
                                    "CityId": "OYPA",
                                    "CountryId": "GF",
                                    "Location": "-51.8, 3.9"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PE",
                    "Name": "Peru",
                    "CurrencyId": "PEN",
                    "Cities": [
                        {
                            "Id": "YMSA",
                            "Name": "Yurimaguas",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.083333, -5.9",
                            "IataCode": "YMS",
                            "Airports": [
                                {
                                    "Id": "YMS",
                                    "Name": "Yurimaguas",
                                    "CityId": "YMSA",
                                    "CountryId": "PE",
                                    "Location": "-76.1, -5.9"
                                }
                            ]
                        },
                        {
                            "Id": "CHHB",
                            "Name": "Chachapoyas",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-77.869034, -6.2316882",
                            "IataCode": "CHH",
                            "Airports": [
                                {
                                    "Id": "CHH",
                                    "Name": "Chachapoyas",
                                    "CityId": "CHHB",
                                    "CountryId": "PE",
                                    "Location": "-77.85, -6.216667"
                                }
                            ]
                        },
                        {
                            "Id": "CHMA",
                            "Name": "Chimbote",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-78.578333, -9.0852778",
                            "IataCode": "CHM",
                            "Airports": [
                                {
                                    "Id": "CHM",
                                    "Name": "Chimbote",
                                    "CityId": "CHMA",
                                    "CountryId": "PE",
                                    "Location": "-78.531111, -9.150556"
                                }
                            ]
                        },
                        {
                            "Id": "CIXA",
                            "Name": "Chiclayo",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-79.841667, -6.7736111",
                            "IataCode": "CIX",
                            "Airports": [
                                {
                                    "Id": "CIX",
                                    "Name": "Chiclayo",
                                    "CityId": "CIXA",
                                    "CountryId": "PE",
                                    "Location": "-79.832222, -6.789722"
                                }
                            ]
                        },
                        {
                            "Id": "CJAA",
                            "Name": "Cajamarca",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-78.500271, -7.1637775",
                            "IataCode": "CJA",
                            "Airports": [
                                {
                                    "Id": "CJA",
                                    "Name": "Cajamarca",
                                    "CityId": "CJAA",
                                    "CountryId": "PE",
                                    "Location": "-78.4894, -7.13918"
                                }
                            ]
                        },
                        {
                            "Id": "CUZA",
                            "Name": "Cuzco",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-71.978055, -13.518333",
                            "IataCode": "CUZ",
                            "Airports": [
                                {
                                    "Id": "CUZ",
                                    "Name": "Cuzco",
                                    "CityId": "CUZA",
                                    "CountryId": "PE",
                                    "Location": "-71.959722, -13.529444"
                                }
                            ]
                        },
                        {
                            "Id": "HUUA",
                            "Name": "Huanuco",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.233333, -9.9166667",
                            "IataCode": "HUU",
                            "Airports": [
                                {
                                    "Id": "HUU",
                                    "Name": "Huanuco",
                                    "CityId": "HUUA",
                                    "CountryId": "PE",
                                    "Location": "-76.216667, -9.866667"
                                }
                            ]
                        },
                        {
                            "Id": "IBPA",
                            "Name": "Iberia",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-69.583333, -11.35",
                            "IataCode": "IBP",
                            "Airports": [
                                {
                                    "Id": "IBP",
                                    "Name": "Iberia",
                                    "CityId": "IBPA",
                                    "CountryId": "PE",
                                    "Location": "-69.583333, -11.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ILQA",
                            "Name": "Ilo",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-71.3375, -17.639444",
                            "IataCode": "ILQ",
                            "Airports": [
                                {
                                    "Id": "ILQ",
                                    "Name": "Ilo",
                                    "CityId": "ILQA",
                                    "CountryId": "PE",
                                    "Location": "-71.333333, -17.666667"
                                }
                            ]
                        },
                        {
                            "Id": "IQTA",
                            "Name": "Iquitos",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-73.247222, -3.7480556",
                            "IataCode": "IQT",
                            "Airports": [
                                {
                                    "Id": "IQT",
                                    "Name": "Iquitos",
                                    "CityId": "IQTA",
                                    "CountryId": "PE",
                                    "Location": "-73.309193, -3.781698"
                                }
                            ]
                        },
                        {
                            "Id": "JAUA",
                            "Name": "Jauja",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-75.5, -11.8",
                            "IataCode": "JAU",
                            "Airports": [
                                {
                                    "Id": "JAU",
                                    "Name": "Jauja",
                                    "CityId": "JAUA",
                                    "CountryId": "PE",
                                    "Location": "-75.4734, -11.7831"
                                }
                            ]
                        },
                        {
                            "Id": "JJIA",
                            "Name": "Juanjui",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.75, -7.1833333",
                            "IataCode": "JJI",
                            "Airports": [
                                {
                                    "Id": "JJI",
                                    "Name": "Juanjui",
                                    "CityId": "JJIA",
                                    "CountryId": "PE",
                                    "Location": "-76.716667, -7.15"
                                }
                            ]
                        },
                        {
                            "Id": "JULA",
                            "Name": "Juliaca",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-70.133333, -15.5",
                            "IataCode": "JUL",
                            "Airports": [
                                {
                                    "Id": "JUL",
                                    "Name": "Juliaca",
                                    "CityId": "JULA",
                                    "CountryId": "PE",
                                    "Location": "-70.154444, -15.464167"
                                }
                            ]
                        },
                        {
                            "Id": "LIMA",
                            "Name": "Lima",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-77.049993, -12.05001",
                            "IataCode": "LIM",
                            "Airports": [
                                {
                                    "Id": "LIM",
                                    "Name": "Lima",
                                    "CityId": "LIMA",
                                    "CountryId": "PE",
                                    "Location": "-77.110634, -12.024882"
                                }
                            ]
                        },
                        {
                            "Id": "PCLA",
                            "Name": "Pucallpa",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-74.553866, -8.3791474",
                            "IataCode": "PCL",
                            "Airports": [
                                {
                                    "Id": "PCL",
                                    "Name": "Pucallpa",
                                    "CityId": "PCLA",
                                    "CountryId": "PE",
                                    "Location": "-74.633333, -8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PEMA",
                            "Name": "Puerto Maldonado",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-69.183333, -12.6",
                            "IataCode": "PEM",
                            "Airports": [
                                {
                                    "Id": "PEM",
                                    "Name": "Puerto Maldonado",
                                    "CityId": "PEMA",
                                    "CountryId": "PE",
                                    "Location": "-69.233333, -12.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PIUA",
                            "Name": "Piura",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-80.633333, -5.2",
                            "IataCode": "PIU",
                            "Airports": [
                                {
                                    "Id": "PIU",
                                    "Name": "Piura",
                                    "CityId": "PIUA",
                                    "CountryId": "PE",
                                    "Location": "-80.613333, -5.201667"
                                }
                            ]
                        },
                        {
                            "Id": "TBPA",
                            "Name": "Tumbes",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-80.441389, -3.5666667",
                            "IataCode": "TBP",
                            "Airports": [
                                {
                                    "Id": "TBP",
                                    "Name": "Tumbes",
                                    "CityId": "TBPA",
                                    "CountryId": "PE",
                                    "Location": "-80.416667, -3.566667"
                                }
                            ]
                        },
                        {
                            "Id": "TRUA",
                            "Name": "Trujillo",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-79.02998, -8.1159943",
                            "IataCode": "TRU",
                            "Airports": [
                                {
                                    "Id": "TRU",
                                    "Name": "Trujillo",
                                    "CityId": "TRUA",
                                    "CountryId": "PE",
                                    "Location": "-79.115, -8.09"
                                }
                            ]
                        },
                        {
                            "Id": "TYLA",
                            "Name": "Talara",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-81.271944, -4.5772222",
                            "IataCode": "TYL",
                            "Airports": [
                                {
                                    "Id": "TYL",
                                    "Name": "Talara",
                                    "CityId": "TYLA",
                                    "CountryId": "PE",
                                    "Location": "-81.252778, -4.564722"
                                }
                            ]
                        },
                        {
                            "Id": "ALDB",
                            "Name": "Alerta",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-69.233333, -11.666667",
                            "IataCode": "ALD",
                            "Airports": [
                                {
                                    "Id": "ALD",
                                    "Name": "Alerta",
                                    "CityId": "ALDB",
                                    "CountryId": "PE",
                                    "Location": "-69.22897, -11.65047"
                                }
                            ]
                        },
                        {
                            "Id": "ANSA",
                            "Name": "Andahuaylas",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-73.387222, -13.655556",
                            "IataCode": "ANS",
                            "Airports": [
                                {
                                    "Id": "ANS",
                                    "Name": "Andahuaylas",
                                    "CityId": "ANSA",
                                    "CountryId": "PE",
                                    "Location": "-73.355833, -13.716667"
                                }
                            ]
                        },
                        {
                            "Id": "AQPA",
                            "Name": "Arequipa",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-71.535, -16.398889",
                            "IataCode": "AQP",
                            "Airports": [
                                {
                                    "Id": "AQP",
                                    "Name": "Arequipa",
                                    "CityId": "AQPA",
                                    "CountryId": "PE",
                                    "Location": "-71.58308, -16.34107"
                                }
                            ]
                        },
                        {
                            "Id": "ATAA",
                            "Name": "Anta",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-77.597222, -9.3586111",
                            "IataCode": "ATA",
                            "Airports": [
                                {
                                    "Id": "ATA",
                                    "Name": "Anta",
                                    "CityId": "ATAA",
                                    "CountryId": "PE",
                                    "Location": "-77.6, -9.347222"
                                }
                            ]
                        },
                        {
                            "Id": "AYPA",
                            "Name": "Ayacucho",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-74.223889, -13.158333",
                            "IataCode": "AYP",
                            "Airports": [
                                {
                                    "Id": "AYP",
                                    "Name": "Ayacucho",
                                    "CityId": "AYPA",
                                    "CountryId": "PE",
                                    "Location": "-74.204149, -13.155212"
                                }
                            ]
                        },
                        {
                            "Id": "RIJA",
                            "Name": "Rioja",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-77.165, -6.0586111",
                            "IataCode": "RIJ",
                            "Airports": [
                                {
                                    "Id": "RIJ",
                                    "Name": "Rioja",
                                    "CityId": "RIJA",
                                    "CountryId": "PE",
                                    "Location": "-77.15, -6.05"
                                }
                            ]
                        },
                        {
                            "Id": "TCQA",
                            "Name": "Tacna",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-70.248333, -18.005556",
                            "IataCode": "TCQ",
                            "Airports": [
                                {
                                    "Id": "TCQ",
                                    "Name": "Tacna",
                                    "CityId": "TCQA",
                                    "CountryId": "PE",
                                    "Location": "-70.278889, -18.063333"
                                }
                            ]
                        },
                        {
                            "Id": "MBPA",
                            "Name": "Moyobamba",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.966667, -6.05",
                            "IataCode": "MBP",
                            "Airports": [
                                {
                                    "Id": "MBP",
                                    "Name": "Moyobamba",
                                    "CityId": "MBPA",
                                    "CountryId": "PE",
                                    "Location": "-77.166667, -6.2"
                                }
                            ]
                        },
                        {
                            "Id": "PIOA",
                            "Name": "Pisco",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.216667, -13.7",
                            "IataCode": "PIO",
                            "Airports": [
                                {
                                    "Id": "PIO",
                                    "Name": "Pisco",
                                    "CityId": "PIOA",
                                    "CountryId": "PE",
                                    "Location": "-76.216667, -13.75"
                                }
                            ]
                        },
                        {
                            "Id": "SJAA",
                            "Name": "San Juan",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-75.162222, -15.365278",
                            "IataCode": "SJA",
                            "Airports": [
                                {
                                    "Id": "SJA",
                                    "Name": "San Juan",
                                    "CityId": "SJAA",
                                    "CountryId": "PE",
                                    "Location": "-75.15, -15.35"
                                }
                            ]
                        },
                        {
                            "Id": "TPPA",
                            "Name": "Tarapoto",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.365556, -6.5013889",
                            "IataCode": "TPP",
                            "Airports": [
                                {
                                    "Id": "TPP",
                                    "Name": "Tarapoto",
                                    "CityId": "TPPA",
                                    "CountryId": "PE",
                                    "Location": "-76.398611, -6.511111"
                                }
                            ]
                        },
                        {
                            "Id": "UMIA",
                            "Name": "Quincemil",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-70.754013, -13.230922",
                            "IataCode": "UMI",
                            "Airports": [
                                {
                                    "Id": "UMI",
                                    "Name": "Quincemil",
                                    "CityId": "UMIA",
                                    "CountryId": "PE",
                                    "Location": "-70.666667, -13.25"
                                }
                            ]
                        },
                        {
                            "Id": "MFTA",
                            "Name": "Machu Picchu",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-72.531389, -13.158333",
                            "IataCode": "MFT",
                            "Airports": [
                                {
                                    "Id": "MFT",
                                    "Name": "Machu Picchu",
                                    "CityId": "MFTA",
                                    "CountryId": "PE",
                                    "Location": "-72.566667, -13.116667"
                                }
                            ]
                        },
                        {
                            "Id": "RIMA",
                            "Name": "Rodriguez De Men",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-77.416667, -6.3333333",
                            "IataCode": "RIM",
                            "Airports": [
                                {
                                    "Id": "RIM",
                                    "Name": "Rodriguez De Men",
                                    "CityId": "RIMA",
                                    "CountryId": "PE",
                                    "Location": "-78, -6"
                                }
                            ]
                        },
                        {
                            "Id": "APEA",
                            "Name": "San Juan Aposento",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-68.516667, -11.8",
                            "IataCode": "APE",
                            "Airports": [
                                {
                                    "Id": "APE",
                                    "Name": "San Juan Aposento",
                                    "CityId": "APEA",
                                    "CountryId": "PE",
                                    "Location": "-69.083333, -11.933333"
                                }
                            ]
                        },
                        {
                            "Id": "TGIA",
                            "Name": "Tingo Maria",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-75.983333, -9.3",
                            "IataCode": "TGI",
                            "Airports": [
                                {
                                    "Id": "TGI",
                                    "Name": "Tingo Maria",
                                    "CityId": "TGIA",
                                    "CountryId": "PE",
                                    "Location": "-76.016667, -9.3"
                                }
                            ]
                        },
                        {
                            "Id": "SQUA",
                            "Name": "Saposoa",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-76.8, -6.9333333",
                            "IataCode": "SQU",
                            "Airports": [
                                {
                                    "Id": "SQU",
                                    "Name": "Saposoa",
                                    "CityId": "SQUA",
                                    "CountryId": "PE",
                                    "Location": "-76.75, -6.95"
                                }
                            ]
                        },
                        {
                            "Id": "JAEA",
                            "Name": "Jaen",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-78.804722, -5.7080556",
                            "IataCode": "JAE",
                            "Airports": [
                                {
                                    "Id": "JAE",
                                    "Name": "Aeropuerto de Shumba",
                                    "CityId": "JAEA",
                                    "CountryId": "PE",
                                    "Location": "-78.773728, -5.596002"
                                }
                            ]
                        },
                        {
                            "Id": "SYCA",
                            "Name": "Shiringayoc",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-69.2, -11.883333",
                            "IataCode": "SYC",
                            "Airports": [
                                {
                                    "Id": "SYC",
                                    "Name": "Shiringayoc",
                                    "CityId": "SYCA",
                                    "CountryId": "PE",
                                    "Location": "-69.166667, -11.9"
                                }
                            ]
                        },
                        {
                            "Id": "BLPA",
                            "Name": "Bellavista",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-69.414722, -16.587778",
                            "IataCode": "BLP",
                            "Airports": [
                                {
                                    "Id": "BLP",
                                    "Name": "Bellavista",
                                    "CityId": "BLPA",
                                    "CountryId": "PE",
                                    "Location": "-69.416667, -16.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SMGA",
                            "Name": "Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "PE",
                            "Location": "-72.613056, -12.589167",
                            "IataCode": "SMG",
                            "Airports": [
                                {
                                    "Id": "SMG",
                                    "Name": "Santa Maria",
                                    "CityId": "SMGA",
                                    "CountryId": "PE",
                                    "Location": "-72, -12.5"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CO",
                    "Name": "Colombia",
                    "CurrencyId": "COP",
                    "Cities": [
                        {
                            "Id": "SROA",
                            "Name": "Santana Ramos",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-126.06694, 9.916944",
                            "IataCode": "SRO",
                            "Airports": [
                                {
                                    "Id": "SRO",
                                    "Name": "Santana Ramos",
                                    "CityId": "SROA",
                                    "CountryId": "CO",
                                    "Location": "-126.06694, 9.916944"
                                }
                            ]
                        },
                        {
                            "Id": "VVCA",
                            "Name": "Villavicencio",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.635, 4.1533333",
                            "IataCode": "VVC",
                            "Airports": [
                                {
                                    "Id": "VVC",
                                    "Name": "Villavicencio",
                                    "CityId": "VVCA",
                                    "CountryId": "CO",
                                    "Location": "-73.61376, 4.16788"
                                }
                            ]
                        },
                        {
                            "Id": "CUOA",
                            "Name": "Caruru",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.3, 1.0133333",
                            "IataCode": "CUO",
                            "Airports": [
                                {
                                    "Id": "CUO",
                                    "Name": "Caruru",
                                    "CityId": "CUOA",
                                    "CountryId": "CO",
                                    "Location": "-71.233333, 1.033333"
                                }
                            ]
                        },
                        {
                            "Id": "URIA",
                            "Name": "Uribe",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.3575, 3.2536111",
                            "IataCode": "URI",
                            "Airports": [
                                {
                                    "Id": "URI",
                                    "Name": "Uribe",
                                    "CityId": "URIA",
                                    "CountryId": "CO",
                                    "Location": "-74.4, 3.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BGAA",
                            "Name": "Bucaramanga",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.125834, 7.1297222",
                            "IataCode": "BGA",
                            "Airports": [
                                {
                                    "Id": "BGA",
                                    "Name": "Bucaramanga",
                                    "CityId": "BGAA",
                                    "CountryId": "CO",
                                    "Location": "-73.184722, 7.126389"
                                }
                            ]
                        },
                        {
                            "Id": "BOGA",
                            "Name": "Bogota",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.083336, 4.600003",
                            "IataCode": "BOG",
                            "Airports": [
                                {
                                    "Id": "BOG",
                                    "Name": "Bogota",
                                    "CityId": "BOGA",
                                    "CountryId": "CO",
                                    "Location": "-74.146119, 4.7010253"
                                }
                            ]
                        },
                        {
                            "Id": "BSCA",
                            "Name": "Bahia Solano",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.407778, 6.2191667",
                            "IataCode": "BSC",
                            "Airports": [
                                {
                                    "Id": "BSC",
                                    "Name": "Bahia Solano",
                                    "CityId": "BSCA",
                                    "CountryId": "CO",
                                    "Location": "-77.4, 6.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BUNA",
                            "Name": "Buenaventura",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.069722, 3.8933333",
                            "IataCode": "BUN",
                            "Airports": [
                                {
                                    "Id": "BUN",
                                    "Name": "Buenaventura",
                                    "CityId": "BUNA",
                                    "CountryId": "CO",
                                    "Location": "-76.995833, 3.825"
                                }
                            ]
                        },
                        {
                            "Id": "CAQA",
                            "Name": "Caucasia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.197222, 7.9869444",
                            "IataCode": "CAQ",
                            "Airports": [
                                {
                                    "Id": "CAQ",
                                    "Name": "Caucasia",
                                    "CityId": "CAQA",
                                    "CountryId": "CO",
                                    "Location": "-75.25, 7.6"
                                }
                            ]
                        },
                        {
                            "Id": "CLOA",
                            "Name": "Cali",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.522499, 3.4372222",
                            "IataCode": "CLO",
                            "Airports": [
                                {
                                    "Id": "CLO",
                                    "Name": "Cali",
                                    "CityId": "CLOA",
                                    "CountryId": "CO",
                                    "Location": "-76.385, 3.546111"
                                }
                            ]
                        },
                        {
                            "Id": "COGA",
                            "Name": "Condoto",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.650278, 5.0966667",
                            "IataCode": "COG",
                            "Airports": [
                                {
                                    "Id": "COG",
                                    "Name": "Condoto",
                                    "CityId": "COGA",
                                    "CountryId": "CO",
                                    "Location": "-76.663333, 5.079167"
                                }
                            ]
                        },
                        {
                            "Id": "CPBA",
                            "Name": "Capurgana",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.35, 8.6333333",
                            "IataCode": "CPB",
                            "Airports": [
                                {
                                    "Id": "CPB",
                                    "Name": "Capurgana",
                                    "CityId": "CPBA",
                                    "CountryId": "CO",
                                    "Location": "-77.35, 8.63333"
                                }
                            ]
                        },
                        {
                            "Id": "CPLA",
                            "Name": "Chaparral",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.487222, 3.7269444",
                            "IataCode": "CPL",
                            "Airports": [
                                {
                                    "Id": "CPL",
                                    "Name": "Chaparral",
                                    "CityId": "CPLA",
                                    "CountryId": "CO",
                                    "Location": "-75.466667, 3.716667"
                                }
                            ]
                        },
                        {
                            "Id": "CRCA",
                            "Name": "Cartago",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.911667, 4.7463889",
                            "IataCode": "CRC",
                            "Airports": [
                                {
                                    "Id": "CRC",
                                    "Name": "Cartago",
                                    "CityId": "CRCA",
                                    "CountryId": "CO",
                                    "Location": "-75.933333, 4.766667"
                                }
                            ]
                        },
                        {
                            "Id": "CSRA",
                            "Name": "Casuarito",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-67.640833, 5.6819444",
                            "IataCode": "CSR",
                            "Airports": [
                                {
                                    "Id": "CSR",
                                    "Name": "Casuarito",
                                    "CityId": "CSRA",
                                    "CountryId": "CO",
                                    "Location": "-68.133333, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CTGA",
                            "Name": "Cartagena",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.514444, 10.399722",
                            "IataCode": "CTG",
                            "Airports": [
                                {
                                    "Id": "CTG",
                                    "Name": "Cartagena",
                                    "CityId": "CTGA",
                                    "CountryId": "CO",
                                    "Location": "-75.510833, 10.446667"
                                }
                            ]
                        },
                        {
                            "Id": "CUCA",
                            "Name": "Cucuta",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.505278, 7.8833333",
                            "IataCode": "CUC",
                            "Airports": [
                                {
                                    "Id": "CUC",
                                    "Name": "Cucuta",
                                    "CityId": "CUCA",
                                    "CountryId": "CO",
                                    "Location": "-72.509444, 7.93"
                                }
                            ]
                        },
                        {
                            "Id": "ACRA",
                            "Name": "Araracuara",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.383333, -0.6230556",
                            "IataCode": "ACR",
                            "Airports": [
                                {
                                    "Id": "ACR",
                                    "Name": "Araracuara",
                                    "CityId": "ACRA",
                                    "CountryId": "CO",
                                    "Location": "-72.398014, -0.601104"
                                }
                            ]
                        },
                        {
                            "Id": "AFIA",
                            "Name": "Amalfi",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.08, 6.9105556",
                            "IataCode": "AFI",
                            "Airports": [
                                {
                                    "Id": "AFI",
                                    "Name": "Amalfi",
                                    "CityId": "AFIA",
                                    "CountryId": "CO",
                                    "Location": "-75.066667, 6.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CVEA",
                            "Name": "Covenas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.7, 9.4166667",
                            "IataCode": "CVE",
                            "Airports": [
                                {
                                    "Id": "CVE",
                                    "Name": "Covenas",
                                    "CityId": "CVEA",
                                    "CountryId": "CO",
                                    "Location": "-75.733333, 9.4"
                                }
                            ]
                        },
                        {
                            "Id": "CZUA",
                            "Name": "Corozal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.295833, 9.3177778",
                            "IataCode": "CZU",
                            "Airports": [
                                {
                                    "Id": "CZU",
                                    "Name": "Corozal",
                                    "CityId": "CZUA",
                                    "CountryId": "CO",
                                    "Location": "-75.282778, 9.3375"
                                }
                            ]
                        },
                        {
                            "Id": "ECOA",
                            "Name": "El Encanto",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.2, -1.7666667",
                            "IataCode": "ECO",
                            "Airports": [
                                {
                                    "Id": "ECO",
                                    "Name": "El Encanto",
                                    "CityId": "ECOA",
                                    "CountryId": "CO",
                                    "Location": "-73.233333, -1.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ECRA",
                            "Name": "El Charco",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-78.116944, 2.4761111",
                            "IataCode": "ECR",
                            "Airports": [
                                {
                                    "Id": "ECR",
                                    "Name": "El Charco",
                                    "CityId": "ECRA",
                                    "CountryId": "CO",
                                    "Location": "-78, 2.5"
                                }
                            ]
                        },
                        {
                            "Id": "EBGA",
                            "Name": "El Bagre",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.811944, 7.5941667",
                            "IataCode": "EBG",
                            "Airports": [
                                {
                                    "Id": "EBG",
                                    "Name": "El Bagre",
                                    "CityId": "EBGA",
                                    "CountryId": "CO",
                                    "Location": "-74.816667, 7.616667"
                                }
                            ]
                        },
                        {
                            "Id": "EJAA",
                            "Name": "Barrancabermeja",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.854722, 7.0652778",
                            "IataCode": "EJA",
                            "Airports": [
                                {
                                    "Id": "EJA",
                                    "Name": "Barrancabermeja",
                                    "CityId": "EJAA",
                                    "CountryId": "CO",
                                    "Location": "-73.799167, 7.015833"
                                }
                            ]
                        },
                        {
                            "Id": "ELBB",
                            "Name": "El Banco",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.978056, 9.0047222",
                            "IataCode": "ELB",
                            "Airports": [
                                {
                                    "Id": "ELB",
                                    "Name": "El Banco",
                                    "CityId": "ELBB",
                                    "CountryId": "CO",
                                    "Location": "-73.9742, 9.0489"
                                }
                            ]
                        },
                        {
                            "Id": "MDEA",
                            "Name": "Medellin",
                            "SingleAirportCity": false,
                            "CountryId": "CO",
                            "Location": "-75.536111, 6.2913889",
                            "IataCode": "MDE",
                            "Airports": [
                                {
                                    "Id": "EOH",
                                    "Name": "Medellin Enrique Olaya Herrera",
                                    "CityId": "MDEA",
                                    "CountryId": "CO",
                                    "Location": "-75.583333, 6.216667"
                                },
                                {
                                    "Id": "MDE",
                                    "Name": "Medellin Jose Maria Cordova",
                                    "CityId": "MDEA",
                                    "CountryId": "CO",
                                    "Location": "-75.423056, 6.164444"
                                }
                            ]
                        },
                        {
                            "Id": "EYPA",
                            "Name": "El Yopal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.394167, 5.3394444",
                            "IataCode": "EYP",
                            "Airports": [
                                {
                                    "Id": "EYP",
                                    "Name": "El Yopal",
                                    "CityId": "EYPA",
                                    "CountryId": "CO",
                                    "Location": "-72.38324, 5.31842"
                                }
                            ]
                        },
                        {
                            "Id": "FLAA",
                            "Name": "Florencia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.6175, 1.6175",
                            "IataCode": "FLA",
                            "Airports": [
                                {
                                    "Id": "FLA",
                                    "Name": "Florencia",
                                    "CityId": "FLAA",
                                    "CountryId": "CO",
                                    "Location": "-75.558889, 1.588889"
                                }
                            ]
                        },
                        {
                            "Id": "GIRA",
                            "Name": "Girardot",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.800833, 4.3030556",
                            "IataCode": "GIR",
                            "Airports": [
                                {
                                    "Id": "GIR",
                                    "Name": "Girardot",
                                    "CityId": "GIRA",
                                    "CountryId": "CO",
                                    "Location": "-74.8, 4.3"
                                }
                            ]
                        },
                        {
                            "Id": "GPIA",
                            "Name": "Guapi",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.863889, 2.5563889",
                            "IataCode": "GPI",
                            "Airports": [
                                {
                                    "Id": "GPI",
                                    "Name": "Guapi",
                                    "CityId": "GPIA",
                                    "CountryId": "CO",
                                    "Location": "-77.89762, 2.57103"
                                }
                            ]
                        },
                        {
                            "Id": "IBEA",
                            "Name": "Ibague",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.232222, 4.4388889",
                            "IataCode": "IBE",
                            "Airports": [
                                {
                                    "Id": "IBE",
                                    "Name": "Ibague",
                                    "CityId": "IBEA",
                                    "CountryId": "CO",
                                    "Location": "-75.14, 4.425833"
                                }
                            ]
                        },
                        {
                            "Id": "IGOA",
                            "Name": "Chigorodo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.686389, 7.6769444",
                            "IataCode": "IGO",
                            "Airports": [
                                {
                                    "Id": "IGO",
                                    "Name": "Chigorodo",
                                    "CityId": "IGOA",
                                    "CountryId": "CO",
                                    "Location": "-76.686667, 7.681667"
                                }
                            ]
                        },
                        {
                            "Id": "IPIA",
                            "Name": "Ipiales",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.644444, 0.8302778",
                            "IataCode": "IPI",
                            "Airports": [
                                {
                                    "Id": "IPI",
                                    "Name": "Ipiales",
                                    "CityId": "IPIA",
                                    "CountryId": "CO",
                                    "Location": "-77.67176, 0.86192"
                                }
                            ]
                        },
                        {
                            "Id": "IVOA",
                            "Name": "Chivolo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.643611, 10.028056",
                            "IataCode": "IVO",
                            "Airports": [
                                {
                                    "Id": "IVO",
                                    "Name": "Chivolo",
                                    "CityId": "IVOA",
                                    "CountryId": "CO",
                                    "Location": "-74.533333, 9.516667"
                                }
                            ]
                        },
                        {
                            "Id": "LCRA",
                            "Name": "La Chorrera",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.016667, -0.7333333",
                            "IataCode": "LCR",
                            "Airports": [
                                {
                                    "Id": "LCR",
                                    "Name": "La Chorrera",
                                    "CityId": "LCRA",
                                    "CountryId": "CO",
                                    "Location": "-73.016667, -0.733333"
                                }
                            ]
                        },
                        {
                            "Id": "LPDA",
                            "Name": "La Pedrera",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.563889, -1.3002778",
                            "IataCode": "LPD",
                            "Airports": [
                                {
                                    "Id": "LPD",
                                    "Name": "La Pedrera",
                                    "CityId": "LPDA",
                                    "CountryId": "CO",
                                    "Location": "-69.716667, -1.3"
                                }
                            ]
                        },
                        {
                            "Id": "LPEA",
                            "Name": "La Primavera",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.165556, 3.8580556",
                            "IataCode": "LPE",
                            "Airports": [
                                {
                                    "Id": "LPE",
                                    "Name": "La Primavera",
                                    "CityId": "LPEA",
                                    "CountryId": "CO",
                                    "Location": "-76.216667, 3.733333"
                                }
                            ]
                        },
                        {
                            "Id": "LRIA",
                            "Name": "Lorica",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.819722, 9.2316667",
                            "IataCode": "LRI",
                            "Airports": [
                                {
                                    "Id": "LRI",
                                    "Name": "Lorica",
                                    "CityId": "LRIA",
                                    "CountryId": "CO",
                                    "Location": "-75.7, 9.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MGNA",
                            "Name": "Magangue",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.753333, 9.2413889",
                            "IataCode": "MGN",
                            "Airports": [
                                {
                                    "Id": "MGN",
                                    "Name": "Magangue",
                                    "CityId": "MGNA",
                                    "CountryId": "CO",
                                    "Location": "-74.845, 9.288056"
                                }
                            ]
                        },
                        {
                            "Id": "MTRA",
                            "Name": "Monteria",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.89, 8.7575",
                            "IataCode": "MTR",
                            "Airports": [
                                {
                                    "Id": "MTR",
                                    "Name": "Monteria",
                                    "CityId": "MTRA",
                                    "CountryId": "CO",
                                    "Location": "-75.881389, 8.745833"
                                }
                            ]
                        },
                        {
                            "Id": "MVPA",
                            "Name": "Mitu",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.173333, 1.1983333",
                            "IataCode": "MVP",
                            "Airports": [
                                {
                                    "Id": "MVP",
                                    "Name": "Mitu",
                                    "CityId": "MVPA",
                                    "CountryId": "CO",
                                    "Location": "-70.23388, 1.25366"
                                }
                            ]
                        },
                        {
                            "Id": "NQUA",
                            "Name": "Nuqui",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.270833, 5.7125",
                            "IataCode": "NQU",
                            "Airports": [
                                {
                                    "Id": "NQU",
                                    "Name": "Nuqui",
                                    "CityId": "NQUA",
                                    "CountryId": "CO",
                                    "Location": "-77.283333, 5.7"
                                }
                            ]
                        },
                        {
                            "Id": "NVAA",
                            "Name": "Neiva",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.330278, 2.9305556",
                            "IataCode": "NVA",
                            "Airports": [
                                {
                                    "Id": "NVA",
                                    "Name": "Neiva",
                                    "CityId": "NVAA",
                                    "CountryId": "CO",
                                    "Location": "-75.298056, 2.952778"
                                }
                            ]
                        },
                        {
                            "Id": "ORCA",
                            "Name": "Orocue",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.34, 4.7941667",
                            "IataCode": "ORC",
                            "Airports": [
                                {
                                    "Id": "ORC",
                                    "Name": "Orocue",
                                    "CityId": "ORCA",
                                    "CountryId": "CO",
                                    "Location": "-71.333333, 4.9"
                                }
                            ]
                        },
                        {
                            "Id": "PDAA",
                            "Name": "Puerto Inirida",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-67.923889, 3.8652778",
                            "IataCode": "PDA",
                            "Airports": [
                                {
                                    "Id": "PDA",
                                    "Name": "Puerto Inirida",
                                    "CityId": "PDAA",
                                    "CountryId": "CO",
                                    "Location": "-67.883333, 3.883333"
                                }
                            ]
                        },
                        {
                            "Id": "PEIA",
                            "Name": "Pereira",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.696111, 4.8133333",
                            "IataCode": "PEI",
                            "Airports": [
                                {
                                    "Id": "PEI",
                                    "Name": "Pereira",
                                    "CityId": "PEIA",
                                    "CountryId": "CO",
                                    "Location": "-75.742222, 4.815278"
                                }
                            ]
                        },
                        {
                            "Id": "PSOA",
                            "Name": "Pasto",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.281111, 1.2136111",
                            "IataCode": "PSO",
                            "Airports": [
                                {
                                    "Id": "PSO",
                                    "Name": "Pasto",
                                    "CityId": "PSOA",
                                    "CountryId": "CO",
                                    "Location": "-77.25, 1.466667"
                                }
                            ]
                        },
                        {
                            "Id": "PUUA",
                            "Name": "Puerto Asis",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.495, 0.5158333",
                            "IataCode": "PUU",
                            "Airports": [
                                {
                                    "Id": "PUU",
                                    "Name": "Puerto Asis",
                                    "CityId": "PUUA",
                                    "CountryId": "CO",
                                    "Location": "-76.50084, 0.50523"
                                }
                            ]
                        },
                        {
                            "Id": "RVEA",
                            "Name": "Saravena",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.873611, 6.9563889",
                            "IataCode": "RVE",
                            "Airports": [
                                {
                                    "Id": "RVE",
                                    "Name": "Saravena",
                                    "CityId": "RVEA",
                                    "CountryId": "CO",
                                    "Location": "-71.85806, 6.95472"
                                }
                            ]
                        },
                        {
                            "Id": "SJEA",
                            "Name": "San Jose Del Gua",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.641667, 2.5683333",
                            "IataCode": "SJE",
                            "Airports": [
                                {
                                    "Id": "SJE",
                                    "Name": "San Jose Del Gua",
                                    "CityId": "SJEA",
                                    "CountryId": "CO",
                                    "Location": "-72.63936, 2.57969"
                                }
                            ]
                        },
                        {
                            "Id": "SMRA",
                            "Name": "Santa Marta",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.201667, 11.247222",
                            "IataCode": "SMR",
                            "Airports": [
                                {
                                    "Id": "SMR",
                                    "Name": "Santa Marta",
                                    "CityId": "SMRA",
                                    "CountryId": "CO",
                                    "Location": "-74.228611, 11.122222"
                                }
                            ]
                        },
                        {
                            "Id": "TMEA",
                            "Name": "Tame",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.728889, 6.4641667",
                            "IataCode": "TME",
                            "Airports": [
                                {
                                    "Id": "TME",
                                    "Name": "Tame",
                                    "CityId": "TMEA",
                                    "CountryId": "CO",
                                    "Location": "-71.75972, 6.45111"
                                }
                            ]
                        },
                        {
                            "Id": "UIBA",
                            "Name": "Quibdo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.661111, 5.6947222",
                            "IataCode": "UIB",
                            "Airports": [
                                {
                                    "Id": "UIB",
                                    "Name": "Quibdo",
                                    "CityId": "UIBA",
                                    "CountryId": "CO",
                                    "Location": "-76.642778, 5.680278"
                                }
                            ]
                        },
                        {
                            "Id": "VUPA",
                            "Name": "Valledupar",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.250556, 10.476944",
                            "IataCode": "VUP",
                            "Airports": [
                                {
                                    "Id": "VUP",
                                    "Name": "Valledupar",
                                    "CityId": "VUPA",
                                    "CountryId": "CO",
                                    "Location": "-73.252778, 10.436667"
                                }
                            ]
                        },
                        {
                            "Id": "ACDA",
                            "Name": "Acandi",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.233333, 8.5333333",
                            "IataCode": "ACD",
                            "Airports": [
                                {
                                    "Id": "ACD",
                                    "Name": "Acandi",
                                    "CityId": "ACDA",
                                    "CountryId": "CO",
                                    "Location": "-77.3, 8.516667"
                                }
                            ]
                        },
                        {
                            "Id": "ACLA",
                            "Name": "Aguaclara",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.993889, 4.7494444",
                            "IataCode": "ACL",
                            "Airports": [
                                {
                                    "Id": "ACL",
                                    "Name": "Aguaclara",
                                    "CityId": "ACLA",
                                    "CountryId": "CO",
                                    "Location": "-73, 4.75"
                                }
                            ]
                        },
                        {
                            "Id": "ADNA",
                            "Name": "Andes",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.880833, 5.6580556",
                            "IataCode": "ADN",
                            "Airports": [
                                {
                                    "Id": "ADN",
                                    "Name": "Andes",
                                    "CityId": "ADNA",
                                    "CountryId": "CO",
                                    "Location": "-75.933333, 5.666667"
                                }
                            ]
                        },
                        {
                            "Id": "APOA",
                            "Name": "Churidó",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.666667, 7.8666667",
                            "IataCode": "APO",
                            "Airports": [
                                {
                                    "Id": "APO",
                                    "Name": "Apartado",
                                    "CityId": "APOA",
                                    "CountryId": "CO",
                                    "Location": "-76.71643, 7.81196"
                                }
                            ]
                        },
                        {
                            "Id": "ARFA",
                            "Name": "Acaricuara",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.233333, 0.7",
                            "IataCode": "ARF",
                            "Airports": [
                                {
                                    "Id": "ARF",
                                    "Name": "Acaricuara",
                                    "CityId": "ARFA",
                                    "CountryId": "CO",
                                    "Location": "-70.133333, 0.533333"
                                }
                            ]
                        },
                        {
                            "Id": "AROA",
                            "Name": "Arboletas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.433611, 8.8655556",
                            "IataCode": "ARO",
                            "Airports": [
                                {
                                    "Id": "ARO",
                                    "Name": "Arboletas",
                                    "CityId": "AROA",
                                    "CountryId": "CO",
                                    "Location": "-76.433333, 8.85"
                                }
                            ]
                        },
                        {
                            "Id": "ARQA",
                            "Name": "Arauquita",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.428056, 7.0291667",
                            "IataCode": "ARQ",
                            "Airports": [
                                {
                                    "Id": "ARQ",
                                    "Name": "Arauquita",
                                    "CityId": "ARQA",
                                    "CountryId": "CO",
                                    "Location": "-71.431667, 7.033056"
                                }
                            ]
                        },
                        {
                            "Id": "AUCA",
                            "Name": "Arauca",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.761667, 7.0902778",
                            "IataCode": "AUC",
                            "Airports": [
                                {
                                    "Id": "AUC",
                                    "Name": "Arauca",
                                    "CityId": "AUCA",
                                    "CountryId": "CO",
                                    "Location": "-70.7425, 7.071667"
                                }
                            ]
                        },
                        {
                            "Id": "AXMA",
                            "Name": "Armenia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.681111, 4.5338889",
                            "IataCode": "AXM",
                            "Airports": [
                                {
                                    "Id": "AXM",
                                    "Name": "Armenia",
                                    "CityId": "AXMA",
                                    "CountryId": "CO",
                                    "Location": "-75.766667, 4.45"
                                }
                            ]
                        },
                        {
                            "Id": "AYCA",
                            "Name": "Ayacucho",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.583333, 8.6",
                            "IataCode": "AYC",
                            "Airports": [
                                {
                                    "Id": "AYC",
                                    "Name": "Ayacucho",
                                    "CityId": "AYCA",
                                    "CountryId": "CO",
                                    "Location": "-73.616667, 8.6"
                                }
                            ]
                        },
                        {
                            "Id": "SVIA",
                            "Name": "San Vicente",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.768889, 2.1138889",
                            "IataCode": "SVI",
                            "Airports": [
                                {
                                    "Id": "SVI",
                                    "Name": "San Vicente",
                                    "CityId": "SVIA",
                                    "CountryId": "CO",
                                    "Location": "-74.766667, 2.15"
                                }
                            ]
                        },
                        {
                            "Id": "AZTA",
                            "Name": "Zapatoca",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.271944, 6.8191667",
                            "IataCode": "AZT",
                            "Airports": [
                                {
                                    "Id": "AZT",
                                    "Name": "Zapatoca",
                                    "CityId": "AZTA",
                                    "CountryId": "CO",
                                    "Location": "-73.25, 6.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BACB",
                            "Name": "Barranca De Upia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.961111, 4.5755556",
                            "IataCode": "BAC",
                            "Airports": [
                                {
                                    "Id": "BAC",
                                    "Name": "Barranca De Upia",
                                    "CityId": "BACB",
                                    "CountryId": "CO",
                                    "Location": "-72.966667, 4.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BAQA",
                            "Name": "Barranquilla",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.796389, 10.963889",
                            "IataCode": "BAQ",
                            "Airports": [
                                {
                                    "Id": "BAQ",
                                    "Name": "Barranquilla",
                                    "CityId": "BAQA",
                                    "CountryId": "CO",
                                    "Location": "-74.778889, 10.896111"
                                }
                            ]
                        },
                        {
                            "Id": "MCJA",
                            "Name": "Maicao",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.244167, 11.384167",
                            "IataCode": "MCJ",
                            "Airports": [
                                {
                                    "Id": "MCJ",
                                    "Name": "Maicao",
                                    "CityId": "MCJA",
                                    "CountryId": "CO",
                                    "Location": "-72.241667, 11.391667"
                                }
                            ]
                        },
                        {
                            "Id": "MZLA",
                            "Name": "Manizales",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.520556, 5.07",
                            "IataCode": "MZL",
                            "Airports": [
                                {
                                    "Id": "MZL",
                                    "Name": "Manizales",
                                    "CityId": "MZLA",
                                    "CountryId": "CO",
                                    "Location": "-75.4647, 5.0296"
                                }
                            ]
                        },
                        {
                            "Id": "OCVA",
                            "Name": "Ocana",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.357778, 8.2363889",
                            "IataCode": "OCV",
                            "Airports": [
                                {
                                    "Id": "OCV",
                                    "Name": "Ocana",
                                    "CityId": "OCVA",
                                    "CountryId": "CO",
                                    "Location": "-73.333333, 8.25"
                                }
                            ]
                        },
                        {
                            "Id": "PALB",
                            "Name": "Palanquero",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.666667, 5.4833333",
                            "IataCode": "PAL",
                            "Airports": [
                                {
                                    "Id": "PAL",
                                    "Name": "Palanquero",
                                    "CityId": "PALB",
                                    "CountryId": "CO",
                                    "Location": "-74.6506, 5.4833"
                                }
                            ]
                        },
                        {
                            "Id": "PYAA",
                            "Name": "Puerto Boyaca",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.589722, 5.9780556",
                            "IataCode": "PYA",
                            "Airports": [
                                {
                                    "Id": "PYA",
                                    "Name": "Puerto Boyaca",
                                    "CityId": "PYAA",
                                    "CountryId": "CO",
                                    "Location": "-74.6, 5.966667"
                                }
                            ]
                        },
                        {
                            "Id": "PZAA",
                            "Name": "Paz De Ariporo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.891944, 5.8805556",
                            "IataCode": "PZA",
                            "Airports": [
                                {
                                    "Id": "PZA",
                                    "Name": "Paz De Ariporo",
                                    "CityId": "PZAA",
                                    "CountryId": "CO",
                                    "Location": "-71.885, 5.877778"
                                }
                            ]
                        },
                        {
                            "Id": "SNTA",
                            "Name": "Sabana De Torres",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.496944, 7.3980556",
                            "IataCode": "SNT",
                            "Airports": [
                                {
                                    "Id": "SNT",
                                    "Name": "Sabana De Torres",
                                    "CityId": "SNTA",
                                    "CountryId": "CO",
                                    "Location": "-73, 7.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SOXA",
                            "Name": "Sogamoso",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.929722, 5.7205556",
                            "IataCode": "SOX",
                            "Airports": [
                                {
                                    "Id": "SOX",
                                    "Name": "Sogamoso",
                                    "CityId": "SOXA",
                                    "CountryId": "CO",
                                    "Location": "-72.933333, 5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SSDA",
                            "Name": "San Felipe",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.733333, -32.75",
                            "IataCode": "SSD",
                            "Airports": [
                                {
                                    "Id": "SSD",
                                    "Name": "San Felipe",
                                    "CityId": "SSDA",
                                    "CountryId": "CO",
                                    "Location": "-70.741667, -32.85"
                                }
                            ]
                        },
                        {
                            "Id": "TAUA",
                            "Name": "Tauramena",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.755278, 5.0186111",
                            "IataCode": "TAU",
                            "Airports": [
                                {
                                    "Id": "TAU",
                                    "Name": "Tauramena",
                                    "CityId": "TAUA",
                                    "CountryId": "CO",
                                    "Location": "-72.733333, 5"
                                }
                            ]
                        },
                        {
                            "Id": "TCOA",
                            "Name": "Tumaco",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-78.815556, 1.7986111",
                            "IataCode": "TCO",
                            "Airports": [
                                {
                                    "Id": "TCO",
                                    "Name": "Tumaco",
                                    "CityId": "TCOA",
                                    "CountryId": "CO",
                                    "Location": "-78.766667, 1.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TRBA",
                            "Name": "Turbo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.731667, 8.0980556",
                            "IataCode": "TRB",
                            "Airports": [
                                {
                                    "Id": "TRB",
                                    "Name": "Turbo",
                                    "CityId": "TRBA",
                                    "CountryId": "CO",
                                    "Location": "-76.748056, 8.078333"
                                }
                            ]
                        },
                        {
                            "Id": "ULQA",
                            "Name": "Tulua",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.2, 4.0866667",
                            "IataCode": "ULQ",
                            "Airports": [
                                {
                                    "Id": "ULQ",
                                    "Name": "Tulua",
                                    "CityId": "ULQA",
                                    "CountryId": "CO",
                                    "Location": "-76.229167, 4.097222"
                                }
                            ]
                        },
                        {
                            "Id": "GRAA",
                            "Name": "Gamarra",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.744444, 8.3291667",
                            "IataCode": "GRA",
                            "Airports": [
                                {
                                    "Id": "GRA",
                                    "Name": "Gamarra",
                                    "CityId": "GRAA",
                                    "CountryId": "CO",
                                    "Location": "-73.7, 8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CJDA",
                            "Name": "Candilejas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.225992, 1.285936",
                            "IataCode": "CJD",
                            "Airports": [
                                {
                                    "Id": "CJD",
                                    "Name": "Candilejas",
                                    "CityId": "CJDA",
                                    "CountryId": "CO",
                                    "Location": "-74.266667, 1.333333"
                                }
                            ]
                        },
                        {
                            "Id": "GAAA",
                            "Name": "Guamal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.23, 9.1472222",
                            "IataCode": "GAA",
                            "Airports": [
                                {
                                    "Id": "GAA",
                                    "Name": "Guamal",
                                    "CityId": "GAAA",
                                    "CountryId": "CO",
                                    "Location": "-74.233333, 9.15"
                                }
                            ]
                        },
                        {
                            "Id": "GCAA",
                            "Name": "Guacamaya",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.927222, 2.3672222",
                            "IataCode": "GCA",
                            "Airports": [
                                {
                                    "Id": "GCA",
                                    "Name": "Guacamaya",
                                    "CityId": "GCAA",
                                    "CountryId": "CO",
                                    "Location": "-75.5, 2.316667"
                                }
                            ]
                        },
                        {
                            "Id": "ACMA",
                            "Name": "Arica",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.733333, -2.1333333",
                            "IataCode": "ACM",
                            "Airports": [
                                {
                                    "Id": "ACM",
                                    "Name": "Arica",
                                    "CityId": "ACMA",
                                    "CountryId": "CO",
                                    "Location": "-71.833333, -2"
                                }
                            ]
                        },
                        {
                            "Id": "ELJA",
                            "Name": "El Recreo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.14832, 1.81203",
                            "IataCode": "ELJ",
                            "Airports": [
                                {
                                    "Id": "ELJ",
                                    "Name": "El Recreo",
                                    "CityId": "ELJA",
                                    "CountryId": "CO",
                                    "Location": "-73.833333, 2"
                                }
                            ]
                        },
                        {
                            "Id": "HRRA",
                            "Name": "Herrera",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.817778, 3.2872222",
                            "IataCode": "HRR",
                            "Airports": [
                                {
                                    "Id": "HRR",
                                    "Name": "Herrera",
                                    "CityId": "HRRA",
                                    "CountryId": "CO",
                                    "Location": "-75.85, 3.216667"
                                }
                            ]
                        },
                        {
                            "Id": "ISDA",
                            "Name": "Iscuande",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.971667, 2.4444444",
                            "IataCode": "ISD",
                            "Airports": [
                                {
                                    "Id": "ISD",
                                    "Name": "Iscuande",
                                    "CityId": "ISDA",
                                    "CountryId": "CO",
                                    "Location": "-77.983333, 2.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LQMA",
                            "Name": "Puerto Leguizamo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.774167, -0.1802778",
                            "IataCode": "LQM",
                            "Airports": [
                                {
                                    "Id": "LQM",
                                    "Name": "Puerto Leguizamo",
                                    "CityId": "LQMA",
                                    "CountryId": "CO",
                                    "Location": "-74.7708, -0.18228"
                                }
                            ]
                        },
                        {
                            "Id": "UNCA",
                            "Name": "Unguia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.096111, 8.0430556",
                            "IataCode": "UNC",
                            "Airports": [
                                {
                                    "Id": "UNC",
                                    "Name": "Unguia",
                                    "CityId": "UNCA",
                                    "CountryId": "CO",
                                    "Location": "-77.083333, 8.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MHFA",
                            "Name": "Morichal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.566667, 2.1666667",
                            "IataCode": "MHF",
                            "Airports": [
                                {
                                    "Id": "MHF",
                                    "Name": "Morichal",
                                    "CityId": "MHFA",
                                    "CountryId": "CO",
                                    "Location": "-70.583333, 2.15"
                                }
                            ]
                        },
                        {
                            "Id": "MOYA",
                            "Name": "Monterrey",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.897222, 4.8783333",
                            "IataCode": "MOY",
                            "Airports": [
                                {
                                    "Id": "MOY",
                                    "Name": "Monterrey",
                                    "CityId": "MOYA",
                                    "CountryId": "CO",
                                    "Location": "-72.895278, 4.884167"
                                }
                            ]
                        },
                        {
                            "Id": "MQRA",
                            "Name": "Mosquera",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-78.495278, 2.4902778",
                            "IataCode": "MQR",
                            "Airports": [
                                {
                                    "Id": "MQR",
                                    "Name": "Mosquera",
                                    "CityId": "MQRA",
                                    "CountryId": "CO",
                                    "Location": "-78.416667, 2.5"
                                }
                            ]
                        },
                        {
                            "Id": "MQUA",
                            "Name": "Mariquita",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.912778, 5.2011111",
                            "IataCode": "MQU",
                            "Airports": [
                                {
                                    "Id": "MQU",
                                    "Name": "Mariquita",
                                    "CityId": "MQUA",
                                    "CountryId": "CO",
                                    "Location": "-74.883333, 5.216667"
                                }
                            ]
                        },
                        {
                            "Id": "NADA",
                            "Name": "Macanal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-67.612222, 2.8030556",
                            "IataCode": "NAD",
                            "Airports": [
                                {
                                    "Id": "NAD",
                                    "Name": "Macanal",
                                    "CityId": "NADA",
                                    "CountryId": "CO",
                                    "Location": "-67.583333, 2.566667"
                                }
                            ]
                        },
                        {
                            "Id": "NCIA",
                            "Name": "Necocli",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.791111, 8.4238889",
                            "IataCode": "NCI",
                            "Airports": [
                                {
                                    "Id": "NCI",
                                    "Name": "Necocli",
                                    "CityId": "NCIA",
                                    "CountryId": "CO",
                                    "Location": "-76.75, 8.483333"
                                }
                            ]
                        },
                        {
                            "Id": "NUHA",
                            "Name": "Nunchia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.198611, 5.6405556",
                            "IataCode": "NUH",
                            "Airports": [
                                {
                                    "Id": "NUH",
                                    "Name": "Nunchia",
                                    "CityId": "NUHA",
                                    "CountryId": "CO",
                                    "Location": "-72.216667, 5.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ULSA",
                            "Name": "Mulatos",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.733333, 8.65",
                            "IataCode": "ULS",
                            "Airports": [
                                {
                                    "Id": "ULS",
                                    "Name": "Mulatos",
                                    "CityId": "ULSA",
                                    "CountryId": "CO",
                                    "Location": "-76.75, 8.65"
                                }
                            ]
                        },
                        {
                            "Id": "PBEA",
                            "Name": "Puerto Berrio",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.406667, 6.4944444",
                            "IataCode": "PBE",
                            "Airports": [
                                {
                                    "Id": "PBE",
                                    "Name": "Puerto Berrio",
                                    "CityId": "PBEA",
                                    "CountryId": "CO",
                                    "Location": "-74.483333, 6.483333"
                                }
                            ]
                        },
                        {
                            "Id": "PLAA",
                            "Name": "Planadas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.648333, 3.1975",
                            "IataCode": "PLA",
                            "Airports": [
                                {
                                    "Id": "PLA",
                                    "Name": "Planadas",
                                    "CityId": "PLAA",
                                    "CountryId": "CO",
                                    "Location": "-75.7, 3.3"
                                }
                            ]
                        },
                        {
                            "Id": "PLCA",
                            "Name": "Planeta Rica",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.588333, 8.4147222",
                            "IataCode": "PLC",
                            "Airports": [
                                {
                                    "Id": "PLC",
                                    "Name": "Planeta Rica",
                                    "CityId": "PLCA",
                                    "CountryId": "CO",
                                    "Location": "-75.6, 8.416667"
                                }
                            ]
                        },
                        {
                            "Id": "PLTA",
                            "Name": "Plato",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.787222, 9.7919444",
                            "IataCode": "PLT",
                            "Airports": [
                                {
                                    "Id": "PLT",
                                    "Name": "Plato",
                                    "CityId": "PLTA",
                                    "CountryId": "CO",
                                    "Location": "-74.783333, 9.783333"
                                }
                            ]
                        },
                        {
                            "Id": "PREA",
                            "Name": "Pore",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.994722, 5.7272222",
                            "IataCode": "PRE",
                            "Airports": [
                                {
                                    "Id": "PRE",
                                    "Name": "Pore",
                                    "CityId": "PREA",
                                    "CountryId": "CO",
                                    "Location": "-71.983333, 5.7"
                                }
                            ]
                        },
                        {
                            "Id": "PTXA",
                            "Name": "Pitalito",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.056389, 1.8675",
                            "IataCode": "PTX",
                            "Airports": [
                                {
                                    "Id": "PTX",
                                    "Name": "Pitalito",
                                    "CityId": "PTXA",
                                    "CountryId": "CO",
                                    "Location": "-76.033333, 1.85"
                                }
                            ]
                        },
                        {
                            "Id": "PYNA",
                            "Name": "Payan",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-78.205, 1.935",
                            "IataCode": "PYN",
                            "Airports": [
                                {
                                    "Id": "PYN",
                                    "Name": "Payan",
                                    "CityId": "PYNA",
                                    "CountryId": "CO",
                                    "Location": "-78.133333, 1.816667"
                                }
                            ]
                        },
                        {
                            "Id": "RAVA",
                            "Name": "Cravo Norte",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-70.201667, 6.3030556",
                            "IataCode": "RAV",
                            "Airports": [
                                {
                                    "Id": "RAV",
                                    "Name": "Cravo Norte",
                                    "CityId": "RAVA",
                                    "CountryId": "CO",
                                    "Location": "-70.183333, 6.316667"
                                }
                            ]
                        },
                        {
                            "Id": "RONA",
                            "Name": "Rondon",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.1, 6.2780556",
                            "IataCode": "RON",
                            "Airports": [
                                {
                                    "Id": "RON",
                                    "Name": "Rondon",
                                    "CityId": "RONA",
                                    "CountryId": "CO",
                                    "Location": "-71.083333, 6.3"
                                }
                            ]
                        },
                        {
                            "Id": "SJHA",
                            "Name": "San Juan Del Cesar",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.008333, 10.773611",
                            "IataCode": "SJH",
                            "Airports": [
                                {
                                    "Id": "SJH",
                                    "Name": "San Juan Del Cesar",
                                    "CityId": "SJHA",
                                    "CountryId": "CO",
                                    "Location": "-73.016667, 10.766667"
                                }
                            ]
                        },
                        {
                            "Id": "SJRA",
                            "Name": "San Juan D Ur",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.53, 8.7630556",
                            "IataCode": "SJR",
                            "Airports": [
                                {
                                    "Id": "SJR",
                                    "Name": "San Juan D Ur",
                                    "CityId": "SJRA",
                                    "CountryId": "CO",
                                    "Location": "-76.533333, 8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "SMCA",
                            "Name": "Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.033333, 8.2166667",
                            "IataCode": "SMC",
                            "Airports": [
                                {
                                    "Id": "SMC",
                                    "Name": "Santa Maria",
                                    "CityId": "SMCA",
                                    "CountryId": "CO",
                                    "Location": "-77.05, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SRSA",
                            "Name": "San Marcos",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.134722, 8.6611111",
                            "IataCode": "SRS",
                            "Airports": [
                                {
                                    "Id": "SRS",
                                    "Name": "San Marcos",
                                    "CityId": "SRSA",
                                    "CountryId": "CO",
                                    "Location": "-75.133333, 8.65"
                                }
                            ]
                        },
                        {
                            "Id": "TBDA",
                            "Name": "Timbiqui",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.6275, 2.7638889",
                            "IataCode": "TBD",
                            "Airports": [
                                {
                                    "Id": "TBD",
                                    "Name": "Timbiqui",
                                    "CityId": "TBDA",
                                    "CountryId": "CO",
                                    "Location": "-77.7, 2.766667"
                                }
                            ]
                        },
                        {
                            "Id": "TCDA",
                            "Name": "Tarapaca",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.744167, -2.8786111",
                            "IataCode": "TCD",
                            "Airports": [
                                {
                                    "Id": "TCD",
                                    "Name": "Tarapaca",
                                    "CityId": "TCDA",
                                    "CountryId": "CO",
                                    "Location": "-69.766667, -2.933333"
                                }
                            ]
                        },
                        {
                            "Id": "TDAA",
                            "Name": "Trinidad",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.662222, 5.4088889",
                            "IataCode": "TDA",
                            "Airports": [
                                {
                                    "Id": "TDA",
                                    "Name": "Trinidad",
                                    "CityId": "TDAA",
                                    "CountryId": "CO",
                                    "Location": "-71.724444, 5.423611"
                                }
                            ]
                        },
                        {
                            "Id": "TIBA",
                            "Name": "Tibu",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.739444, 8.6480556",
                            "IataCode": "TIB",
                            "Airports": [
                                {
                                    "Id": "TIB",
                                    "Name": "Tibu",
                                    "CityId": "TIBA",
                                    "CountryId": "CO",
                                    "Location": "-72.733333, 8.633333"
                                }
                            ]
                        },
                        {
                            "Id": "TLUA",
                            "Name": "Tolu",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.581944, 9.5280556",
                            "IataCode": "TLU",
                            "Airports": [
                                {
                                    "Id": "TLU",
                                    "Name": "Tolu",
                                    "CityId": "TLUA",
                                    "CountryId": "CO",
                                    "Location": "-75.583333, 9.516667"
                                }
                            ]
                        },
                        {
                            "Id": "URRA",
                            "Name": "Urrao",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.138333, 6.3197222",
                            "IataCode": "URR",
                            "Airports": [
                                {
                                    "Id": "URR",
                                    "Name": "Urrao",
                                    "CityId": "URRA",
                                    "CountryId": "CO",
                                    "Location": "-76.183333, 6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "VABA",
                            "Name": "Yavarate",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.208056, 0.615",
                            "IataCode": "VAB",
                            "Airports": [
                                {
                                    "Id": "VAB",
                                    "Name": "Yavarate",
                                    "CityId": "VABA",
                                    "CountryId": "CO",
                                    "Location": "-70.75, 1.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MNDA",
                            "Name": "Medina",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.351389, 4.5119444",
                            "IataCode": "MND",
                            "Airports": [
                                {
                                    "Id": "MND",
                                    "Name": "Medina",
                                    "CityId": "MNDA",
                                    "CountryId": "CO",
                                    "Location": "-73.283333, 4.516667"
                                }
                            ]
                        },
                        {
                            "Id": "AYAA",
                            "Name": "Ayapel",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.145556, 8.3158333",
                            "IataCode": "AYA",
                            "Airports": [
                                {
                                    "Id": "AYA",
                                    "Name": "Ayapel",
                                    "CityId": "AYAA",
                                    "CountryId": "CO",
                                    "Location": "-75.15, 8.316667"
                                }
                            ]
                        },
                        {
                            "Id": "CIMA",
                            "Name": "Cimitarra",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.950556, 6.3161111",
                            "IataCode": "CIM",
                            "Airports": [
                                {
                                    "Id": "CIM",
                                    "Name": "Cimitarra",
                                    "CityId": "CIMA",
                                    "CountryId": "CO",
                                    "Location": "-74.116667, 6.483333"
                                }
                            ]
                        },
                        {
                            "Id": "ADZA",
                            "Name": "San Andres Island",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-81.700556, 12.584722",
                            "IataCode": "ADZ",
                            "Airports": [
                                {
                                    "Id": "ADZ",
                                    "Name": "San Andres Island",
                                    "CityId": "ADZA",
                                    "CountryId": "CO",
                                    "Location": "-81.7, 12.583333"
                                }
                            ]
                        },
                        {
                            "Id": "LETA",
                            "Name": "Leticia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.940556, -4.2152778",
                            "IataCode": "LET",
                            "Airports": [
                                {
                                    "Id": "LET",
                                    "Name": "Leticia",
                                    "CityId": "LETA",
                                    "CountryId": "CO",
                                    "Location": "-69.939444, -4.196389"
                                }
                            ]
                        },
                        {
                            "Id": "PVAA",
                            "Name": "Providencia",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-81.366667, 13.35",
                            "IataCode": "PVA",
                            "Airports": [
                                {
                                    "Id": "PVA",
                                    "Name": "Providencia",
                                    "CityId": "PVAA",
                                    "CountryId": "CO",
                                    "Location": "-81.35, 13.35"
                                }
                            ]
                        },
                        {
                            "Id": "MTBA",
                            "Name": "Monte Libano",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.71313, 7.8715756",
                            "IataCode": "MTB",
                            "Airports": [
                                {
                                    "Id": "MTB",
                                    "Name": "Monte Libano",
                                    "CityId": "MTBA",
                                    "CountryId": "CO",
                                    "Location": "-75.466667, 8.066667"
                                }
                            ]
                        },
                        {
                            "Id": "LMXA",
                            "Name": "Lopez De Micay",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.291071, 3.0173407",
                            "IataCode": "LMX",
                            "Airports": [
                                {
                                    "Id": "LMX",
                                    "Name": "Lopez De Micay",
                                    "CityId": "LMXA",
                                    "CountryId": "CO",
                                    "Location": "-77.55, 3.05"
                                }
                            ]
                        },
                        {
                            "Id": "HTZA",
                            "Name": "Hato Corozal",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.208798, 6.0779474",
                            "IataCode": "HTZ",
                            "Airports": [
                                {
                                    "Id": "HTZ",
                                    "Name": "Hato Corozal",
                                    "CityId": "HTZA",
                                    "CountryId": "CO",
                                    "Location": "-72.633333, 6.033333"
                                }
                            ]
                        },
                        {
                            "Id": "AYGA",
                            "Name": "Yaguara",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.518611, 2.6661111",
                            "IataCode": "AYG",
                            "Airports": [
                                {
                                    "Id": "AYG",
                                    "Name": "Yaguara",
                                    "CityId": "AYGA",
                                    "CountryId": "CO",
                                    "Location": "-73.933333, 1.533333"
                                }
                            ]
                        },
                        {
                            "Id": "VGZA",
                            "Name": "Villagarzon",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.626667, 1.0375",
                            "IataCode": "VGZ",
                            "Airports": [
                                {
                                    "Id": "VGZ",
                                    "Name": "Villagarzon",
                                    "CityId": "VGZA",
                                    "CountryId": "CO",
                                    "Location": "-76.716667, 1.066667"
                                }
                            ]
                        },
                        {
                            "Id": "TQSA",
                            "Name": "Tres Esquinas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.266667, 0.7166667",
                            "IataCode": "TQS",
                            "Airports": [
                                {
                                    "Id": "TQS",
                                    "Name": "Tres Esquinas",
                                    "CityId": "TQSA",
                                    "CountryId": "CO",
                                    "Location": "-75.233333, 0.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SCAA",
                            "Name": "Santa Catalina",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.295, 10.606667",
                            "IataCode": "SCA",
                            "Airports": [
                                {
                                    "Id": "SCA",
                                    "Name": "Santa Catalina",
                                    "CityId": "SCAA",
                                    "CountryId": "CO",
                                    "Location": "-75.283333, 10.6"
                                }
                            ]
                        },
                        {
                            "Id": "SQBA",
                            "Name": "Santa Ana",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.575833, 9.3236111",
                            "IataCode": "SQB",
                            "Airports": [
                                {
                                    "Id": "SQB",
                                    "Name": "Santa Ana",
                                    "CityId": "SQBA",
                                    "CountryId": "CO",
                                    "Location": "-74.583333, 9.316667"
                                }
                            ]
                        },
                        {
                            "Id": "RCHA",
                            "Name": "Riohacha",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.907222, 11.544444",
                            "IataCode": "RCH",
                            "Airports": [
                                {
                                    "Id": "RCH",
                                    "Name": "Riohacha",
                                    "CityId": "RCHA",
                                    "CountryId": "CO",
                                    "Location": "-72.924444, 11.529167"
                                }
                            ]
                        },
                        {
                            "Id": "PCCA",
                            "Name": "Puerto Rico",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.145, 1.9141667",
                            "IataCode": "PCC",
                            "Airports": [
                                {
                                    "Id": "PCC",
                                    "Name": "Puerto Rico",
                                    "CityId": "PCCA",
                                    "CountryId": "CO",
                                    "Location": "-75.15, 1.9"
                                }
                            ]
                        },
                        {
                            "Id": "PCRA",
                            "Name": "Puerto Carreno",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-67.473056, 6.1877778",
                            "IataCode": "PCR",
                            "Airports": [
                                {
                                    "Id": "PCR",
                                    "Name": "Puerto Carreno",
                                    "CityId": "PCRA",
                                    "CountryId": "CO",
                                    "Location": "-67.49316, 6.18472"
                                }
                            ]
                        },
                        {
                            "Id": "OTUA",
                            "Name": "Otu",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.733333, 7.0166667",
                            "IataCode": "OTU",
                            "Airports": [
                                {
                                    "Id": "OTU",
                                    "Name": "Otu",
                                    "CityId": "OTUA",
                                    "CountryId": "CO",
                                    "Location": "-74.716667, 7"
                                }
                            ]
                        },
                        {
                            "Id": "MFBA",
                            "Name": "Monfort",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.983333, 0.5833333",
                            "IataCode": "MFB",
                            "Airports": [
                                {
                                    "Id": "MFB",
                                    "Name": "Monfort",
                                    "CityId": "MFBA",
                                    "CountryId": "CO",
                                    "Location": "-69.75, 0.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MMPA",
                            "Name": "Mompos",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.426667, 9.2419444",
                            "IataCode": "MMP",
                            "Airports": [
                                {
                                    "Id": "MMP",
                                    "Name": "Mompos",
                                    "CityId": "MMPA",
                                    "CountryId": "CO",
                                    "Location": "-74.436111, 9.261667"
                                }
                            ]
                        },
                        {
                            "Id": "MFSA",
                            "Name": "Miraflores",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.148056, 5.1980556",
                            "IataCode": "MFS",
                            "Airports": [
                                {
                                    "Id": "MFS",
                                    "Name": "Miraflores",
                                    "CityId": "MFSA",
                                    "CountryId": "CO",
                                    "Location": "-73.25, 5.166667"
                                }
                            ]
                        },
                        {
                            "Id": "LATA",
                            "Name": "La Uribe",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.05, 4.75",
                            "IataCode": "LAT",
                            "Airports": [
                                {
                                    "Id": "LAT",
                                    "Name": "La Uribe",
                                    "CityId": "LATA",
                                    "CountryId": "CO",
                                    "Location": "0, 0"
                                }
                            ]
                        },
                        {
                            "Id": "NARA",
                            "Name": "Nare",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.586667, 6.1916667",
                            "IataCode": "NAR",
                            "Airports": [
                                {
                                    "Id": "NAR",
                                    "Name": "Nare",
                                    "CityId": "NARA",
                                    "CountryId": "CO",
                                    "Location": "-74.583333, 6.2"
                                }
                            ]
                        },
                        {
                            "Id": "JUOA",
                            "Name": "Jurado",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.771389, 7.1113889",
                            "IataCode": "JUO",
                            "Airports": [
                                {
                                    "Id": "JUO",
                                    "Name": "Jurado",
                                    "CityId": "JUOA",
                                    "CountryId": "CO",
                                    "Location": "-77.75, 7.116667"
                                }
                            ]
                        },
                        {
                            "Id": "BHFA",
                            "Name": "Bahia Cupica",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.5, 6.6833333",
                            "IataCode": "BHF",
                            "Airports": [
                                {
                                    "Id": "BHF",
                                    "Name": "Bahia Cupica",
                                    "CityId": "BHFA",
                                    "CountryId": "CO",
                                    "Location": "-77.516667, 6.716667"
                                }
                            ]
                        },
                        {
                            "Id": "CCOA",
                            "Name": "Carimagua",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.033333, 5.3833333",
                            "IataCode": "CCO",
                            "Airports": [
                                {
                                    "Id": "CCO",
                                    "Name": "Carimagua",
                                    "CityId": "CCOA",
                                    "CountryId": "CO",
                                    "Location": "-71.333333, 4.566667"
                                }
                            ]
                        },
                        {
                            "Id": "APIA",
                            "Name": "Apiay",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.566667, 4.0833333",
                            "IataCode": "API",
                            "Airports": [
                                {
                                    "Id": "API",
                                    "Name": "Apiay",
                                    "CityId": "APIA",
                                    "CountryId": "CO",
                                    "Location": "-73.56558, 4.07696"
                                }
                            ]
                        },
                        {
                            "Id": "EUOA",
                            "Name": "Paratebueno",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.221389, 4.3730556",
                            "IataCode": "EUO",
                            "Airports": [
                                {
                                    "Id": "EUO",
                                    "Name": "Paratebueno",
                                    "CityId": "EUOA",
                                    "CountryId": "CO",
                                    "Location": "-73.2, 4.383333"
                                }
                            ]
                        },
                        {
                            "Id": "DZIA",
                            "Name": "Codazzi",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.238889, 10.035833",
                            "IataCode": "DZI",
                            "Airports": [
                                {
                                    "Id": "DZI",
                                    "Name": "Codazzi",
                                    "CityId": "DZIA",
                                    "CountryId": "CO",
                                    "Location": "-73.25, 10.016667"
                                }
                            ]
                        },
                        {
                            "Id": "NBBA",
                            "Name": "Barrancominas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-69.810833, 3.4841667",
                            "IataCode": "NBB",
                            "Airports": [
                                {
                                    "Id": "NBB",
                                    "Name": "Barrancominas",
                                    "CityId": "NBBA",
                                    "CountryId": "CO",
                                    "Location": "-70.333333, 3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SQFA",
                            "Name": "Solano",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-66.958889, 1.9747222",
                            "IataCode": "SQF",
                            "Airports": [
                                {
                                    "Id": "SQF",
                                    "Name": "Solano",
                                    "CityId": "SQFA",
                                    "CountryId": "CO",
                                    "Location": "-66.966667, 2"
                                }
                            ]
                        },
                        {
                            "Id": "SOHA",
                            "Name": "Solita",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.761111, 9.1905556",
                            "IataCode": "SOH",
                            "Airports": [
                                {
                                    "Id": "SOH",
                                    "Name": "Solita",
                                    "CityId": "SOHA",
                                    "CountryId": "CO",
                                    "Location": "-71.766667, 9.2"
                                }
                            ]
                        },
                        {
                            "Id": "MIXA",
                            "Name": "Miriti",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-59.1, -6.6",
                            "IataCode": "MIX",
                            "Airports": [
                                {
                                    "Id": "MIX",
                                    "Name": "Miriti",
                                    "CityId": "MIXA",
                                    "CountryId": "CO",
                                    "Location": "-59, -6.25"
                                }
                            ]
                        },
                        {
                            "Id": "PPNA",
                            "Name": "Popayan",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-76.604198, 2.420736",
                            "IataCode": "PPN",
                            "Airports": [
                                {
                                    "Id": "PPN",
                                    "Name": "Popayan",
                                    "CityId": "PPNA",
                                    "CountryId": "CO",
                                    "Location": "-76.608889, 2.45"
                                }
                            ]
                        },
                        {
                            "Id": "GGLA",
                            "Name": "Titumate",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77.083333, 8.3166667",
                            "IataCode": "GGL",
                            "Airports": [
                                {
                                    "Id": "GGL",
                                    "Name": "Gilgal",
                                    "CityId": "GGLA",
                                    "CountryId": "CO",
                                    "Location": "-77.083333, 8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TTMA",
                            "Name": "Tablón Alto",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.1, 5.7166667",
                            "IataCode": "TTM",
                            "Airports": [
                                {
                                    "Id": "TTM",
                                    "Name": "Tablon De Tamara",
                                    "CityId": "TTMA",
                                    "CountryId": "CO",
                                    "Location": "-72.1, 5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SSLA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.25, 1.4666667",
                            "IataCode": "SSL",
                            "Airports": [
                                {
                                    "Id": "SSL",
                                    "Name": "Santa Rosalia",
                                    "CityId": "SSLA",
                                    "CountryId": "CO",
                                    "Location": "-72.233333, 1.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LMCA",
                            "Name": "Los Micos",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-73.906111, 3.3188889",
                            "IataCode": "LMC",
                            "Airports": [
                                {
                                    "Id": "LMC",
                                    "Name": "Lamacarena",
                                    "CityId": "LMCA",
                                    "CountryId": "CO",
                                    "Location": "-73.9, 3.316667"
                                }
                            ]
                        },
                        {
                            "Id": "SJGA",
                            "Name": "Las Trampas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-75.083333, 9.4166667",
                            "IataCode": "SJG",
                            "Airports": [
                                {
                                    "Id": "SJG",
                                    "Name": "San Pedro Jagua",
                                    "CityId": "SJGA",
                                    "CountryId": "CO",
                                    "Location": "-75.066667, 9.4"
                                }
                            ]
                        },
                        {
                            "Id": "LGTA",
                            "Name": "Cuatro Bocas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.466111, 9.1563889",
                            "IataCode": "LGT",
                            "Airports": [
                                {
                                    "Id": "LGT",
                                    "Name": "Las Gaviotas",
                                    "CityId": "LGTA",
                                    "CountryId": "CO",
                                    "Location": "-74.5, 9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "NPUA",
                            "Name": "Algarrobo",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-71.916667, 4.9833333",
                            "IataCode": "NPU",
                            "Airports": [
                                {
                                    "Id": "NPU",
                                    "Name": "San Pedro Uraba",
                                    "CityId": "NPUA",
                                    "CountryId": "CO",
                                    "Location": "-71.916667, 4.95"
                                }
                            ]
                        },
                        {
                            "Id": "CUIA",
                            "Name": "Las Brisas",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.023333, 4.6863889",
                            "IataCode": "CUI",
                            "Airports": [
                                {
                                    "Id": "CUI",
                                    "Name": "Currillo",
                                    "CityId": "CUIA",
                                    "CountryId": "CO",
                                    "Location": "-72, 4.666667"
                                }
                            ]
                        },
                        {
                            "Id": "AYIA",
                            "Name": "Puerto Benjamín García",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-72.282222, -0.3511111",
                            "IataCode": "AYI",
                            "Airports": [
                                {
                                    "Id": "AYI",
                                    "Name": "Yari",
                                    "CityId": "AYIA",
                                    "CountryId": "CO",
                                    "Location": "-72.333333, -0.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CQTA",
                            "Name": "San José de Caquetania",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-74.165833, 1.797745",
                            "IataCode": "CQT",
                            "Airports": [
                                {
                                    "Id": "CQT",
                                    "Name": "Caquetania",
                                    "CityId": "CQTA",
                                    "CountryId": "CO",
                                    "Location": "-74.166667, 1.616667"
                                }
                            ]
                        },
                        {
                            "Id": "GMCA",
                            "Name": "Hautnez",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-2.6, 49.433333",
                            "IataCode": "GMC",
                            "Airports": [
                                {
                                    "Id": "GMC",
                                    "Name": "Guerima",
                                    "CityId": "GMCA",
                                    "CountryId": "CO",
                                    "Location": "-2.6, 49.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SQEA",
                            "Name": "Valdivieso",
                            "SingleAirportCity": true,
                            "CountryId": "CO",
                            "Location": "-77, -12.066667",
                            "IataCode": "SQE",
                            "Airports": [
                                {
                                    "Id": "SQE",
                                    "Name": "San Luis De Pale",
                                    "CityId": "SQEA",
                                    "CountryId": "CO",
                                    "Location": "-77, -12.066667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AR",
                    "Name": "Argentina",
                    "CurrencyId": "ARS",
                    "LanguageId": "ES",
                    "Cities": [
                        {
                            "Id": "BUEA",
                            "Name": "Buenos Aires",
                            "SingleAirportCity": false,
                            "CountryId": "AR",
                            "Location": "-58.443658, -34.612538",
                            "IataCode": "BUE",
                            "Airports": [
                                {
                                    "Id": "AEP",
                                    "Name": "Buenos Aires Jorge Newbery",
                                    "CityId": "BUEA",
                                    "CountryId": "AR",
                                    "Location": "-58.415833, -34.558889"
                                },
                                {
                                    "Id": "EPA",
                                    "Name": "El Palomar",
                                    "CityId": "BUEA",
                                    "CountryId": "AR",
                                    "Location": "-58.616266, -34.610111"
                                },
                                {
                                    "Id": "EZE",
                                    "Name": "Buenos Aires Ministro Pistarini",
                                    "CityId": "BUEA",
                                    "CountryId": "AR",
                                    "Location": "-58.533889, -34.82"
                                }
                            ]
                        },
                        {
                            "Id": "AFAA",
                            "Name": "San Rafael",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.333333, -34.6",
                            "IataCode": "AFA",
                            "Airports": [
                                {
                                    "Id": "AFA",
                                    "Name": "San Rafael",
                                    "CityId": "AFAA",
                                    "CountryId": "AR",
                                    "Location": "-68.400556, -34.589167"
                                }
                            ]
                        },
                        {
                            "Id": "AOLA",
                            "Name": "Paso De Los Libres",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.083333, -29.716667",
                            "IataCode": "AOL",
                            "Airports": [
                                {
                                    "Id": "AOL",
                                    "Name": "Paso De Los Libres",
                                    "CityId": "AOLA",
                                    "CountryId": "AR",
                                    "Location": "-57.15, -29.683333"
                                }
                            ]
                        },
                        {
                            "Id": "CCTA",
                            "Name": "Colonia Catriel",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.833333, -37.85",
                            "IataCode": "CCT",
                            "Airports": [
                                {
                                    "Id": "CCT",
                                    "Name": "Colonia Catriel",
                                    "CityId": "CCTA",
                                    "CountryId": "AR",
                                    "Location": "-68.916667, -39.5"
                                }
                            ]
                        },
                        {
                            "Id": "CLXB",
                            "Name": "Clorinda",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.716667, -25.283333",
                            "IataCode": "CLX",
                            "Airports": [
                                {
                                    "Id": "CLX",
                                    "Name": "Clorinda",
                                    "CityId": "CLXB",
                                    "CountryId": "AR",
                                    "Location": "-57.666667, -25.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CPGA",
                            "Name": "Carmen De Patagones",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-62.983333, -40.8",
                            "IataCode": "CPG",
                            "Airports": [
                                {
                                    "Id": "CPG",
                                    "Name": "Carmen De Patagones",
                                    "CityId": "CPGA",
                                    "CountryId": "AR",
                                    "Location": "-63, -40.833333"
                                }
                            ]
                        },
                        {
                            "Id": "EHLA",
                            "Name": "El Bolson",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.516667, -41.966667",
                            "IataCode": "EHL",
                            "Airports": [
                                {
                                    "Id": "EHL",
                                    "Name": "El Bolson",
                                    "CityId": "EHLA",
                                    "CountryId": "AR",
                                    "Location": "-71.75, -42"
                                }
                            ]
                        },
                        {
                            "Id": "GHUA",
                            "Name": "Gualeguaychu",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.514167, -33.010278",
                            "IataCode": "GHU",
                            "Airports": [
                                {
                                    "Id": "GHU",
                                    "Name": "Gualeguaychu",
                                    "CityId": "GHUA",
                                    "CountryId": "AR",
                                    "Location": "-58.612222, -33.004167"
                                }
                            ]
                        },
                        {
                            "Id": "GPOA",
                            "Name": "General Pico",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-63.733333, -35.666667",
                            "IataCode": "GPO",
                            "Airports": [
                                {
                                    "Id": "GPO",
                                    "Name": "General Pico",
                                    "CityId": "GPOA",
                                    "CountryId": "AR",
                                    "Location": "-63.766667, -35.633333"
                                }
                            ]
                        },
                        {
                            "Id": "HOSA",
                            "Name": "Chos Malal",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.266667, -37.383333",
                            "IataCode": "HOS",
                            "Airports": [
                                {
                                    "Id": "HOS",
                                    "Name": "Chos Malal",
                                    "CityId": "HOSA",
                                    "CountryId": "AR",
                                    "Location": "-70.216667, -37.416667"
                                }
                            ]
                        },
                        {
                            "Id": "APZA",
                            "Name": "Zapala",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.066667, -38.9",
                            "IataCode": "APZ",
                            "Airports": [
                                {
                                    "Id": "APZ",
                                    "Name": "Zapala",
                                    "CityId": "APZA",
                                    "CountryId": "AR",
                                    "Location": "-70.083333, -38.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LPGA",
                            "Name": "La Plata",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.948889, -34.931391",
                            "IataCode": "LPG",
                            "Airports": [
                                {
                                    "Id": "LPG",
                                    "Name": "La Plata",
                                    "CityId": "LPGA",
                                    "CountryId": "AR",
                                    "Location": "-57.95, -34.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LLSA",
                            "Name": "Las Lomitas",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.6, -24.7",
                            "IataCode": "LLS",
                            "Airports": [
                                {
                                    "Id": "LLS",
                                    "Name": "Las Lomitas",
                                    "CityId": "LLSA",
                                    "CountryId": "AR",
                                    "Location": "-60.583333, -24.716667"
                                }
                            ]
                        },
                        {
                            "Id": "NECA",
                            "Name": "Necochea",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.75, -38.55",
                            "IataCode": "NEC",
                            "Airports": [
                                {
                                    "Id": "NEC",
                                    "Name": "Necochea",
                                    "CityId": "NECA",
                                    "CountryId": "AR",
                                    "Location": "-58.75, -38.566667"
                                }
                            ]
                        },
                        {
                            "Id": "MJRA",
                            "Name": "Miramar",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.85, -38.266667",
                            "IataCode": "MJR",
                            "Airports": [
                                {
                                    "Id": "MJR",
                                    "Name": "Miramar",
                                    "CityId": "MJRA",
                                    "CountryId": "AR",
                                    "Location": "-57.833333, -38.25"
                                }
                            ]
                        },
                        {
                            "Id": "PMQA",
                            "Name": "Perito Moreno",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.933333, -46.6",
                            "IataCode": "PMQ",
                            "Airports": [
                                {
                                    "Id": "PMQ",
                                    "Name": "Perito Moreno",
                                    "CityId": "PMQA",
                                    "CountryId": "AR",
                                    "Location": "-70.983611, -46.536389"
                                }
                            ]
                        },
                        {
                            "Id": "PUDA",
                            "Name": "Puerto Deseado",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.9, -47.75",
                            "IataCode": "PUD",
                            "Airports": [
                                {
                                    "Id": "PUD",
                                    "Name": "Puerto Deseado",
                                    "CityId": "PUDA",
                                    "CountryId": "AR",
                                    "Location": "-65.916667, -47.75"
                                }
                            ]
                        },
                        {
                            "Id": "BHIA",
                            "Name": "Bahia Blanca",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-62.283333, -38.716668",
                            "IataCode": "BHI",
                            "Airports": [
                                {
                                    "Id": "BHI",
                                    "Name": "Bahia Blanca",
                                    "CityId": "BHIA",
                                    "CountryId": "AR",
                                    "Location": "-62.150556, -38.730556"
                                }
                            ]
                        },
                        {
                            "Id": "CVIA",
                            "Name": "Caleta Olivia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.533333, -46.433333",
                            "IataCode": "CVI",
                            "Airports": [
                                {
                                    "Id": "CVI",
                                    "Name": "Caleta Olivia",
                                    "CityId": "CVIA",
                                    "CountryId": "AR",
                                    "Location": "-67.416667, -46.416667"
                                }
                            ]
                        },
                        {
                            "Id": "BRCA",
                            "Name": "San Carlos Bariloche",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.3, -41.15",
                            "IataCode": "BRC",
                            "Airports": [
                                {
                                    "Id": "BRC",
                                    "Name": "San Carlos DeBariloche",
                                    "CityId": "BRCA",
                                    "CountryId": "AR",
                                    "Location": "-71.158333, -41.149722"
                                }
                            ]
                        },
                        {
                            "Id": "CNQA",
                            "Name": "Corrientes",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.833333, -27.466667",
                            "IataCode": "CNQ",
                            "Airports": [
                                {
                                    "Id": "CNQ",
                                    "Name": "Corrientes",
                                    "CityId": "CNQA",
                                    "CountryId": "AR",
                                    "Location": "-58.762222, -27.449722"
                                }
                            ]
                        },
                        {
                            "Id": "CNTA",
                            "Name": "Charata",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.2, -27.216667",
                            "IataCode": "CNT",
                            "Airports": [
                                {
                                    "Id": "CNT",
                                    "Name": "Charata",
                                    "CityId": "CNTA",
                                    "CountryId": "AR",
                                    "Location": "-61.233333, -27.216667"
                                }
                            ]
                        },
                        {
                            "Id": "COCA",
                            "Name": "Concordia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.033333, -31.4",
                            "IataCode": "COC",
                            "Airports": [
                                {
                                    "Id": "COC",
                                    "Name": "Concordia",
                                    "CityId": "COCA",
                                    "CountryId": "AR",
                                    "Location": "-57.996389, -31.297222"
                                }
                            ]
                        },
                        {
                            "Id": "CORA",
                            "Name": "Cordoba",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.183333, -31.400003",
                            "IataCode": "COR",
                            "Airports": [
                                {
                                    "Id": "COR",
                                    "Name": "Cordoba",
                                    "CityId": "CORA",
                                    "CountryId": "AR",
                                    "Location": "-64.199444, -31.313056"
                                }
                            ]
                        },
                        {
                            "Id": "CPCA",
                            "Name": "San Martin DeLos Andes",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.35, -40.166667",
                            "IataCode": "CPC",
                            "Airports": [
                                {
                                    "Id": "CPC",
                                    "Name": "San Martin DeLos Andes",
                                    "CityId": "CPCA",
                                    "CountryId": "AR",
                                    "Location": "-71.133333, -40.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CRDA",
                            "Name": "Comodoro Rivadavia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.500001, -45.866667",
                            "IataCode": "CRD",
                            "Airports": [
                                {
                                    "Id": "CRD",
                                    "Name": "Comodoro Rivadavia",
                                    "CityId": "CRDA",
                                    "CountryId": "AR",
                                    "Location": "-67.462778, -45.787222"
                                }
                            ]
                        },
                        {
                            "Id": "CTCA",
                            "Name": "Catamarca",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.783333, -28.466667",
                            "IataCode": "CTC",
                            "Airports": [
                                {
                                    "Id": "CTC",
                                    "Name": "Catamarca",
                                    "CityId": "CTCA",
                                    "CountryId": "AR",
                                    "Location": "-65.779722, -28.448333"
                                }
                            ]
                        },
                        {
                            "Id": "EQSA",
                            "Name": "Esquel",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.316667, -42.9",
                            "IataCode": "EQS",
                            "Airports": [
                                {
                                    "Id": "EQS",
                                    "Name": "Esquel",
                                    "CityId": "EQSA",
                                    "CountryId": "AR",
                                    "Location": "-71.1425, -42.909722"
                                }
                            ]
                        },
                        {
                            "Id": "EMXA",
                            "Name": "El Maiten",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.166667, -42.05",
                            "IataCode": "EMX",
                            "Airports": [
                                {
                                    "Id": "EMX",
                                    "Name": "El Maiten",
                                    "CityId": "EMXA",
                                    "CountryId": "AR",
                                    "Location": "-71.15, -42.033333"
                                }
                            ]
                        },
                        {
                            "Id": "FMAA",
                            "Name": "Formosa",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.183333, -26.183333",
                            "IataCode": "FMA",
                            "Airports": [
                                {
                                    "Id": "FMA",
                                    "Name": "Formosa",
                                    "CityId": "FMAA",
                                    "CountryId": "AR",
                                    "Location": "-58.235, -26.212778"
                                }
                            ]
                        },
                        {
                            "Id": "FTEA",
                            "Name": "El Calafate",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-72.276821, -50.34075",
                            "IataCode": "FTE",
                            "Airports": [
                                {
                                    "Id": "FTE",
                                    "Name": "El Calafate",
                                    "CityId": "FTEA",
                                    "CountryId": "AR",
                                    "Location": "-72.053333, -50.280278"
                                }
                            ]
                        },
                        {
                            "Id": "GGSA",
                            "Name": "Gobernador Gregores",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.25, -48.766667",
                            "IataCode": "GGS",
                            "Airports": [
                                {
                                    "Id": "GGS",
                                    "Name": "Gobernador Gregores",
                                    "CityId": "GGSA",
                                    "CountryId": "AR",
                                    "Location": "-70.25, -48.766667"
                                }
                            ]
                        },
                        {
                            "Id": "GNRA",
                            "Name": "General Roca",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.583334, -39.033333",
                            "IataCode": "GNR",
                            "Airports": [
                                {
                                    "Id": "GNR",
                                    "Name": "General Roca",
                                    "CityId": "GNRA",
                                    "CountryId": "AR",
                                    "Location": "-67.6, -39.983333"
                                }
                            ]
                        },
                        {
                            "Id": "JNIA",
                            "Name": "Junin",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.958889, -34.585",
                            "IataCode": "JNI",
                            "Airports": [
                                {
                                    "Id": "JNI",
                                    "Name": "Junin",
                                    "CityId": "JNIA",
                                    "CountryId": "AR",
                                    "Location": "-60.966667, -34.583333"
                                }
                            ]
                        },
                        {
                            "Id": "IGBA",
                            "Name": "Ingeniero Jacobacci",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-69.583333, -41.3",
                            "IataCode": "IGB",
                            "Airports": [
                                {
                                    "Id": "IGB",
                                    "Name": "Ingeniero Jacobacci",
                                    "CityId": "IGBA",
                                    "CountryId": "AR",
                                    "Location": "-69.583333, -41.3"
                                }
                            ]
                        },
                        {
                            "Id": "IRJB",
                            "Name": "La Rioja",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-66.85, -29.433333",
                            "IataCode": "IRJ",
                            "Airports": [
                                {
                                    "Id": "IRJ",
                                    "Name": "La Rioja",
                                    "CityId": "IRJB",
                                    "CountryId": "AR",
                                    "Location": "-66.783333, -29.383333"
                                }
                            ]
                        },
                        {
                            "Id": "JSMA",
                            "Name": "Jose De San Martin",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.483333, -44.033333",
                            "IataCode": "JSM",
                            "Airports": [
                                {
                                    "Id": "JSM",
                                    "Name": "Jose De San Martin",
                                    "CityId": "JSMA",
                                    "CountryId": "AR",
                                    "Location": "-70.433333, -44.066667"
                                }
                            ]
                        },
                        {
                            "Id": "LCMA",
                            "Name": "La Cumbre",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.5, -30.966667",
                            "IataCode": "LCM",
                            "Airports": [
                                {
                                    "Id": "LCM",
                                    "Name": "La Cumbre",
                                    "CityId": "LCMA",
                                    "CountryId": "AR",
                                    "Location": "-64.5, -30.966667"
                                }
                            ]
                        },
                        {
                            "Id": "LGSA",
                            "Name": "Malargue",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-69.583333, -35.466667",
                            "IataCode": "LGS",
                            "Airports": [
                                {
                                    "Id": "LGS",
                                    "Name": "Malargue",
                                    "CityId": "LGSA",
                                    "CountryId": "AR",
                                    "Location": "-69.583333, -35.5"
                                }
                            ]
                        },
                        {
                            "Id": "LMDA",
                            "Name": "Los Menucos",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.133333, -40.833333",
                            "IataCode": "LMD",
                            "Airports": [
                                {
                                    "Id": "LMD",
                                    "Name": "Los Menucos",
                                    "CityId": "LMDA",
                                    "CountryId": "AR",
                                    "Location": "-68.133333, -40.833333"
                                }
                            ]
                        },
                        {
                            "Id": "SANB",
                            "Name": "San Luis",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-66.35, -33.3",
                            "IataCode": "LUQ",
                            "Airports": [
                                {
                                    "Id": "LUQ",
                                    "Name": "San Luis",
                                    "CityId": "SANB",
                                    "CountryId": "AR",
                                    "Location": "-66.358611, -33.274444"
                                }
                            ]
                        },
                        {
                            "Id": "MDQA",
                            "Name": "Mar Del Plata",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.55, -38.000002",
                            "IataCode": "MDQ",
                            "Airports": [
                                {
                                    "Id": "MDQ",
                                    "Name": "Mar Del Plata",
                                    "CityId": "MDQA",
                                    "CountryId": "AR",
                                    "Location": "-57.572222, -37.934722"
                                }
                            ]
                        },
                        {
                            "Id": "MDZA",
                            "Name": "Mendoza",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.816667, -32.883333",
                            "IataCode": "MDZ",
                            "Airports": [
                                {
                                    "Id": "MDZ",
                                    "Name": "Mendoza",
                                    "CityId": "MDZA",
                                    "CountryId": "AR",
                                    "Location": "-68.784722, -32.831944"
                                }
                            ]
                        },
                        {
                            "Id": "NQNA",
                            "Name": "Neuquen",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.066667, -38.950001",
                            "IataCode": "NQN",
                            "Airports": [
                                {
                                    "Id": "NQN",
                                    "Name": "Neuquen",
                                    "CityId": "NQNA",
                                    "CountryId": "AR",
                                    "Location": "-68.152222, -38.95"
                                }
                            ]
                        },
                        {
                            "Id": "OESA",
                            "Name": "San Antonio Oeste",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.933333, -40.733333",
                            "IataCode": "OES",
                            "Airports": [
                                {
                                    "Id": "OES",
                                    "Name": "San Antonio Oeste",
                                    "CityId": "OESA",
                                    "CountryId": "AR",
                                    "Location": "-64.95, -40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "PMYA",
                            "Name": "Puerto Madryn",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.05, -42.766667",
                            "IataCode": "PMY",
                            "Airports": [
                                {
                                    "Id": "PMY",
                                    "Name": "Puerto Madryn",
                                    "CityId": "PMYA",
                                    "CountryId": "AR",
                                    "Location": "-65.102778, -42.759167"
                                }
                            ]
                        },
                        {
                            "Id": "PRAA",
                            "Name": "Parana",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.533334, -31.733335",
                            "IataCode": "PRA",
                            "Airports": [
                                {
                                    "Id": "PRA",
                                    "Name": "Parana",
                                    "CityId": "PRAA",
                                    "CountryId": "AR",
                                    "Location": "-60.48, -31.795"
                                }
                            ]
                        },
                        {
                            "Id": "PRQA",
                            "Name": "Pres. Roque Saenz Pena",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.45, -26.783333",
                            "IataCode": "PRQ",
                            "Airports": [
                                {
                                    "Id": "PRQ",
                                    "Name": "Pres. Roque Saenz Pena",
                                    "CityId": "PRQA",
                                    "CountryId": "AR",
                                    "Location": "-60.666667, -26.833333"
                                }
                            ]
                        },
                        {
                            "Id": "PSSA",
                            "Name": "Posadas",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-55.883333, -27.383333",
                            "IataCode": "PSS",
                            "Airports": [
                                {
                                    "Id": "PSS",
                                    "Name": "Posadas",
                                    "CityId": "PSSA",
                                    "CountryId": "AR",
                                    "Location": "-55.97073, -27.38584"
                                }
                            ]
                        },
                        {
                            "Id": "RELA",
                            "Name": "Trelew",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.305051, -43.248954",
                            "IataCode": "REL",
                            "Airports": [
                                {
                                    "Id": "REL",
                                    "Name": "Trelew",
                                    "CityId": "RELA",
                                    "CountryId": "AR",
                                    "Location": "-65.323333, -43.233333"
                                }
                            ]
                        },
                        {
                            "Id": "RESA",
                            "Name": "Resistencia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.983333, -27.45",
                            "IataCode": "RES",
                            "Airports": [
                                {
                                    "Id": "RES",
                                    "Name": "Resistencia",
                                    "CityId": "RESA",
                                    "CountryId": "AR",
                                    "Location": "-59.050833, -27.451389"
                                }
                            ]
                        },
                        {
                            "Id": "RGAA",
                            "Name": "Rio Grande",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.7, -53.783333",
                            "IataCode": "RGA",
                            "Airports": [
                                {
                                    "Id": "RGA",
                                    "Name": "Rio Grande",
                                    "CityId": "RGAA",
                                    "CountryId": "AR",
                                    "Location": "-67.75, -53.779167"
                                }
                            ]
                        },
                        {
                            "Id": "RGLA",
                            "Name": "Rio Gallegos",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-69.216667, -51.633333",
                            "IataCode": "RGL",
                            "Airports": [
                                {
                                    "Id": "RGL",
                                    "Name": "Rio Gallegos",
                                    "CityId": "RGLA",
                                    "CountryId": "AR",
                                    "Location": "-69.283333, -51.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ROSA",
                            "Name": "Rosario",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.666389, -32.951113",
                            "IataCode": "ROS",
                            "Airports": [
                                {
                                    "Id": "ROS",
                                    "Name": "Rosario",
                                    "CityId": "ROSA",
                                    "CountryId": "AR",
                                    "Location": "-60.785, -32.90361"
                                }
                            ]
                        },
                        {
                            "Id": "RSAA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.283333, -36.616667",
                            "IataCode": "RSA",
                            "Airports": [
                                {
                                    "Id": "RSA",
                                    "Name": "Santa Rosa",
                                    "CityId": "RSAA",
                                    "CountryId": "AR",
                                    "Location": "-64.27569, -36.58832"
                                }
                            ]
                        },
                        {
                            "Id": "SDEA",
                            "Name": "Santiago Del Estero",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.266667, -27.783333",
                            "IataCode": "SDE",
                            "Airports": [
                                {
                                    "Id": "SDE",
                                    "Name": "Santiago Del Estero",
                                    "CityId": "SDEA",
                                    "CountryId": "AR",
                                    "Location": "-64.322222, -27.765278"
                                }
                            ]
                        },
                        {
                            "Id": "SLAA",
                            "Name": "Salta",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.416666, -24.783333",
                            "IataCode": "SLA",
                            "Airports": [
                                {
                                    "Id": "SLA",
                                    "Name": "Salta",
                                    "CityId": "SLAA",
                                    "CountryId": "AR",
                                    "Location": "-65.472222, -24.855"
                                }
                            ]
                        },
                        {
                            "Id": "TDLA",
                            "Name": "Tandil",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-59.15, -37.316667",
                            "IataCode": "TDL",
                            "Airports": [
                                {
                                    "Id": "TDL",
                                    "Name": "Tandil",
                                    "CityId": "TDLA",
                                    "CountryId": "AR",
                                    "Location": "-59.216888, -37.233267"
                                }
                            ]
                        },
                        {
                            "Id": "TTGA",
                            "Name": "Tartagal",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-63.816667, -22.533333",
                            "IataCode": "TTG",
                            "Airports": [
                                {
                                    "Id": "TTG",
                                    "Name": "Tartagal",
                                    "CityId": "TTGA",
                                    "CountryId": "AR",
                                    "Location": "-63.833333, -22.533333"
                                }
                            ]
                        },
                        {
                            "Id": "TUCA",
                            "Name": "Tucuman",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.216666, -26.816667",
                            "IataCode": "TUC",
                            "Airports": [
                                {
                                    "Id": "TUC",
                                    "Name": "Tucuman",
                                    "CityId": "TUCA",
                                    "CountryId": "AR",
                                    "Location": "-65.105, -26.840833"
                                }
                            ]
                        },
                        {
                            "Id": "UAQA",
                            "Name": "San Juan",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.536389, -31.5375",
                            "IataCode": "UAQ",
                            "Airports": [
                                {
                                    "Id": "UAQ",
                                    "Name": "San Juan",
                                    "CityId": "UAQA",
                                    "CountryId": "AR",
                                    "Location": "-68.418333, -31.571389"
                                }
                            ]
                        },
                        {
                            "Id": "USHA",
                            "Name": "Ushuaia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.3, -54.8",
                            "IataCode": "USH",
                            "Airports": [
                                {
                                    "Id": "USH",
                                    "Name": "Ushuaia",
                                    "CityId": "USHA",
                                    "CountryId": "AR",
                                    "Location": "-68.29575, -54.84328"
                                }
                            ]
                        },
                        {
                            "Id": "VDMA",
                            "Name": "Viedma",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-63, -40.8",
                            "IataCode": "VDM",
                            "Airports": [
                                {
                                    "Id": "VDM",
                                    "Name": "Viedma",
                                    "CityId": "VDMA",
                                    "CountryId": "AR",
                                    "Location": "-63.016667, -40.85"
                                }
                            ]
                        },
                        {
                            "Id": "VLGA",
                            "Name": "Villa Gesell",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-56.969261, -37.256839",
                            "IataCode": "VLG",
                            "Airports": [
                                {
                                    "Id": "VLG",
                                    "Name": "Villa Gesell",
                                    "CityId": "VLGA",
                                    "CountryId": "AR",
                                    "Location": "-57.029901, -37.234565"
                                }
                            ]
                        },
                        {
                            "Id": "PEHA",
                            "Name": "Pehuajo",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.905278, -35.814722",
                            "IataCode": "PEH",
                            "Airports": [
                                {
                                    "Id": "PEH",
                                    "Name": "Pehuajo",
                                    "CityId": "PEHA",
                                    "CountryId": "AR",
                                    "Location": "-61.866667, -35.85"
                                }
                            ]
                        },
                        {
                            "Id": "SFNA",
                            "Name": "Santa Fe",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.7, -31.633333",
                            "IataCode": "SFN",
                            "Airports": [
                                {
                                    "Id": "SFN",
                                    "Name": "Santa Fe",
                                    "CityId": "SFNA",
                                    "CountryId": "AR",
                                    "Location": "-60.81167, -31.71167"
                                }
                            ]
                        },
                        {
                            "Id": "ULAA",
                            "Name": "San Julian",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-67.716667, -49.3",
                            "IataCode": "ULA",
                            "Airports": [
                                {
                                    "Id": "ULA",
                                    "Name": "San Julian",
                                    "CityId": "ULAA",
                                    "CountryId": "AR",
                                    "Location": "-67.666667, -49.316667"
                                }
                            ]
                        },
                        {
                            "Id": "SZQA",
                            "Name": "Saenz Pena",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.533333, -34.6",
                            "IataCode": "SZQ",
                            "Airports": [
                                {
                                    "Id": "SZQ",
                                    "Name": "Saenz Pena",
                                    "CityId": "SZQA",
                                    "CountryId": "AR",
                                    "Location": "-58.533333, -34.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MDXA",
                            "Name": "Mercedes",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.083333, -29.2",
                            "IataCode": "MDX",
                            "Airports": [
                                {
                                    "Id": "MDX",
                                    "Name": "Mercedes",
                                    "CityId": "MDXA",
                                    "CountryId": "AR",
                                    "Location": "-58.075556, -29.167222"
                                }
                            ]
                        },
                        {
                            "Id": "MQDA",
                            "Name": "Maquinchao",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.733333, -41.25",
                            "IataCode": "MQD",
                            "Airports": [
                                {
                                    "Id": "MQD",
                                    "Name": "Maquinchao",
                                    "CityId": "MQDA",
                                    "CountryId": "AR",
                                    "Location": "-68.733333, -41.25"
                                }
                            ]
                        },
                        {
                            "Id": "ORAA",
                            "Name": "Oran",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.325981, -23.13223",
                            "IataCode": "ORA",
                            "Airports": [
                                {
                                    "Id": "ORA",
                                    "Name": "Oran",
                                    "CityId": "ORAA",
                                    "CountryId": "AR",
                                    "Location": "-64.166667, -23.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OYAA",
                            "Name": "Goya",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-59.266666, -29.133333",
                            "IataCode": "OYA",
                            "Airports": [
                                {
                                    "Id": "OYA",
                                    "Name": "Goya",
                                    "CityId": "OYAA",
                                    "CountryId": "AR",
                                    "Location": "-59.216667, -29.103333"
                                }
                            ]
                        },
                        {
                            "Id": "RCQA",
                            "Name": "Reconquista",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-59.65, -29.15",
                            "IataCode": "RCQ",
                            "Airports": [
                                {
                                    "Id": "RCQ",
                                    "Name": "Reconquista",
                                    "CityId": "RCQA",
                                    "CountryId": "AR",
                                    "Location": "-59.7, -29.183333"
                                }
                            ]
                        },
                        {
                            "Id": "RCUA",
                            "Name": "Rio Cuarto",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.35, -33.133333",
                            "IataCode": "RCU",
                            "Airports": [
                                {
                                    "Id": "RCU",
                                    "Name": "Rio Cuarto",
                                    "CityId": "RCUA",
                                    "CountryId": "AR",
                                    "Location": "-64.261389, -33.085"
                                }
                            ]
                        },
                        {
                            "Id": "ROYB",
                            "Name": "Rio Mayo",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.266667, -45.683333",
                            "IataCode": "ROY",
                            "Airports": [
                                {
                                    "Id": "ROY",
                                    "Name": "Rio Mayo",
                                    "CityId": "ROYB",
                                    "CountryId": "AR",
                                    "Location": "-70.25, -45.6667"
                                }
                            ]
                        },
                        {
                            "Id": "SGVA",
                            "Name": "Sierra Grande",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.366667, -41.6",
                            "IataCode": "SGV",
                            "Airports": [
                                {
                                    "Id": "SGV",
                                    "Name": "Sierra Grande",
                                    "CityId": "SGVA",
                                    "CountryId": "AR",
                                    "Location": "-65.35, -41.566667"
                                }
                            ]
                        },
                        {
                            "Id": "UZUA",
                            "Name": "Curuzu Cuatia",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-58.05, -29.783333",
                            "IataCode": "UZU",
                            "Airports": [
                                {
                                    "Id": "UZU",
                                    "Name": "Curuzu Cuatia",
                                    "CityId": "UZUA",
                                    "CountryId": "AR",
                                    "Location": "-58.095556, -29.778889"
                                }
                            ]
                        },
                        {
                            "Id": "VCFA",
                            "Name": "Valcheta",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-66.15, -40.7",
                            "IataCode": "VCF",
                            "Airports": [
                                {
                                    "Id": "VCF",
                                    "Name": "Valcheta",
                                    "CityId": "VCFA",
                                    "CountryId": "AR",
                                    "Location": "-66.15, -40.7"
                                }
                            ]
                        },
                        {
                            "Id": "VDRA",
                            "Name": "Villa Dolores",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.2, -31.933333",
                            "IataCode": "VDR",
                            "Airports": [
                                {
                                    "Id": "VDR",
                                    "Name": "Villa Dolores",
                                    "CityId": "VDRA",
                                    "CountryId": "AR",
                                    "Location": "-65.142222, -31.941111"
                                }
                            ]
                        },
                        {
                            "Id": "VGSA",
                            "Name": "General Villegas",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-63.016667, -35.033333",
                            "IataCode": "VGS",
                            "Airports": [
                                {
                                    "Id": "VGS",
                                    "Name": "General Villegas",
                                    "CityId": "VGSA",
                                    "CountryId": "AR",
                                    "Location": "-63, -35.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CRRA",
                            "Name": "Ceres",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.95, -29.883333",
                            "IataCode": "CRR",
                            "Airports": [
                                {
                                    "Id": "CRR",
                                    "Name": "Ceres",
                                    "CityId": "CRRA",
                                    "CountryId": "AR",
                                    "Location": "-61.833333, -29.916667"
                                }
                            ]
                        },
                        {
                            "Id": "JUJA",
                            "Name": "Jujuy",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.3, -24.183333",
                            "IataCode": "JUJ",
                            "Airports": [
                                {
                                    "Id": "JUJ",
                                    "Name": "Jujuy",
                                    "CityId": "JUJA",
                                    "CountryId": "AR",
                                    "Location": "-65.083333, -24.4"
                                }
                            ]
                        },
                        {
                            "Id": "VMEA",
                            "Name": "Villa Mercedes",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.45783, -33.67571",
                            "IataCode": "VME",
                            "Airports": [
                                {
                                    "Id": "VME",
                                    "Name": "Villa Mercedes",
                                    "CityId": "VMEA",
                                    "CountryId": "AR",
                                    "Location": "-65.416667, -33.633333"
                                }
                            ]
                        },
                        {
                            "Id": "RLOA",
                            "Name": "Merlo",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-65.033333, -32.35",
                            "IataCode": "RLO",
                            "Airports": [
                                {
                                    "Id": "RLO",
                                    "Name": "Merlo",
                                    "CityId": "RLOA",
                                    "CountryId": "AR",
                                    "Location": "-65.5525, -32.384444"
                                }
                            ]
                        },
                        {
                            "Id": "MCSA",
                            "Name": "Monte Caseros",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-57.65, -30.25",
                            "IataCode": "MCS",
                            "Airports": [
                                {
                                    "Id": "MCS",
                                    "Name": "Monte Caseros",
                                    "CityId": "MCSA",
                                    "CountryId": "AR",
                                    "Location": "-57.641111, -30.270833"
                                }
                            ]
                        },
                        {
                            "Id": "EPAA",
                            "Name": "El Palomar",
                            "SingleAirportCity": false,
                            "CountryId": "AR",
                            "Location": "-58.615278, -34.541667",
                            "Airports": []
                        },
                        {
                            "Id": "RYOA",
                            "Name": "Rio Turbio",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-72.3, -51.533333",
                            "IataCode": "RYO",
                            "Airports": [
                                {
                                    "Id": "RYO",
                                    "Name": "Rio Turbio",
                                    "CityId": "RYOA",
                                    "CountryId": "AR",
                                    "Location": "-72.216667, -51.583333"
                                }
                            ]
                        },
                        {
                            "Id": "RHDA",
                            "Name": "Rio Hondo",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-64.95, -27.566667",
                            "IataCode": "RHD",
                            "Airports": [
                                {
                                    "Id": "RHD",
                                    "Name": "Rio Hondo",
                                    "CityId": "RHDA",
                                    "CountryId": "AR",
                                    "Location": "-64.95, -27.533333"
                                }
                            ]
                        },
                        {
                            "Id": "OYOA",
                            "Name": "Tres Arroyos",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.283333, -38.383333",
                            "IataCode": "OYO",
                            "Airports": [
                                {
                                    "Id": "OYO",
                                    "Name": "Tres Arroyos",
                                    "CityId": "OYOA",
                                    "CountryId": "AR",
                                    "Location": "-60.25, -38.366667"
                                }
                            ]
                        },
                        {
                            "Id": "NCJA",
                            "Name": "Sunchales",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.566667, -30.933333",
                            "IataCode": "NCJ",
                            "Airports": [
                                {
                                    "Id": "NCJ",
                                    "Name": "Sunchales",
                                    "CityId": "NCJA",
                                    "CountryId": "AR",
                                    "Location": "-61.528889, -30.957222"
                                }
                            ]
                        },
                        {
                            "Id": "OLNA",
                            "Name": "Colonia Sarmiento",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-69.083333, -45.6",
                            "IataCode": "OLN",
                            "Airports": [
                                {
                                    "Id": "OLN",
                                    "Name": "Colonia Sarmiento",
                                    "CityId": "OLNA",
                                    "CountryId": "AR",
                                    "Location": "-68.9993, -45.5825"
                                }
                            ]
                        },
                        {
                            "Id": "SSTA",
                            "Name": "Santa Teresita",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.783333, -33.433333",
                            "IataCode": "SST",
                            "Airports": [
                                {
                                    "Id": "SST",
                                    "Name": "Santa Teresita",
                                    "CityId": "SSTA",
                                    "CountryId": "AR",
                                    "Location": "-60.783333, -33.416667"
                                }
                            ]
                        },
                        {
                            "Id": "RAFA",
                            "Name": "Rafaela",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.483333, -31.266667",
                            "IataCode": "RAF",
                            "Airports": [
                                {
                                    "Id": "RAF",
                                    "Name": "Rafaela",
                                    "CityId": "RAFA",
                                    "CountryId": "AR",
                                    "Location": "-61.499577, -31.284346"
                                }
                            ]
                        },
                        {
                            "Id": "RZAA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.516667, -50.016667",
                            "IataCode": "RZA",
                            "Airports": [
                                {
                                    "Id": "RZA",
                                    "Name": "Santa Cruz",
                                    "CityId": "RZAA",
                                    "CountryId": "AR",
                                    "Location": "-68.533333, -50"
                                }
                            ]
                        },
                        {
                            "Id": "OVRA",
                            "Name": "Olavarria",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-60.283334, -36.9",
                            "IataCode": "OVR",
                            "Airports": [
                                {
                                    "Id": "OVR",
                                    "Name": "Olavarria",
                                    "CityId": "OVRA",
                                    "CountryId": "AR",
                                    "Location": "-60.227778, -36.888889"
                                }
                            ]
                        },
                        {
                            "Id": "LCPA",
                            "Name": "Loncopue",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.616667, -38.066667",
                            "IataCode": "LCP",
                            "Airports": [
                                {
                                    "Id": "LCP",
                                    "Name": "Loncopue",
                                    "CityId": "LCPA",
                                    "CountryId": "AR",
                                    "Location": "-70.666667, -38.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LHSA",
                            "Name": "Las Heras",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.816667, -32.85",
                            "IataCode": "LHS",
                            "Airports": [
                                {
                                    "Id": "LHS",
                                    "Name": "Las Heras",
                                    "CityId": "LHSA",
                                    "CountryId": "AR",
                                    "Location": "-68.816667, -32.85"
                                }
                            ]
                        },
                        {
                            "Id": "CUTA",
                            "Name": "Cutral",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-69.233333, -38.933333",
                            "IataCode": "CUT",
                            "Airports": [
                                {
                                    "Id": "CUT",
                                    "Name": "Cutral",
                                    "CityId": "CUTA",
                                    "CountryId": "AR",
                                    "Location": "-69, -38.9"
                                }
                            ]
                        },
                        {
                            "Id": "CSZA",
                            "Name": "Coronel Suarez",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-61.916667, -37.466667",
                            "IataCode": "CSZ",
                            "Airports": [
                                {
                                    "Id": "CSZ",
                                    "Name": "Coronel Suarez",
                                    "CityId": "CSZA",
                                    "CountryId": "AR",
                                    "Location": "-67.883333, -37.433333"
                                }
                            ]
                        },
                        {
                            "Id": "ARRA",
                            "Name": "Alto Rio Senguerr",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-70.833333, -45.033333",
                            "IataCode": "ARR",
                            "Airports": [
                                {
                                    "Id": "ARR",
                                    "Name": "Alto Rio Senguerr",
                                    "CityId": "ARRA",
                                    "CountryId": "AR",
                                    "Location": "-70.833333, -45.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ELOA",
                            "Name": "Puerto Eldorado",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-54.66667, -26.38333",
                            "IataCode": "ELO",
                            "Airports": [
                                {
                                    "Id": "ELO",
                                    "Name": "Eldorado",
                                    "CityId": "ELOA",
                                    "CountryId": "AR",
                                    "Location": "-54.733333, -26.5"
                                }
                            ]
                        },
                        {
                            "Id": "IGRA",
                            "Name": "Puerto Iguazú",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-54.482574, -25.73504",
                            "IataCode": "IGR",
                            "Airports": [
                                {
                                    "Id": "IGR",
                                    "Name": "Iguazu",
                                    "CityId": "IGRA",
                                    "CountryId": "AR",
                                    "Location": "-54.482574, -25.73504"
                                }
                            ]
                        },
                        {
                            "Id": "RDSA",
                            "Name": "Luanco",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-68.4, -37.2",
                            "IataCode": "RDS",
                            "Airports": [
                                {
                                    "Id": "RDS",
                                    "Name": "Rincon de los Sauces",
                                    "CityId": "RDSA",
                                    "CountryId": "AR",
                                    "Location": "-68.9, -37.383333"
                                }
                            ]
                        },
                        {
                            "Id": "INGA",
                            "Name": "Colonia Francisco Perito Moreno",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-72.666667, -50.45",
                            "IataCode": "ING",
                            "Airports": [
                                {
                                    "Id": "ING",
                                    "Name": "Lago Argentino",
                                    "CityId": "INGA",
                                    "CountryId": "AR",
                                    "Location": "-72.666667, -50.583333"
                                }
                            ]
                        },
                        {
                            "Id": "CVHA",
                            "Name": "Baños de Copahue",
                            "SingleAirportCity": true,
                            "CountryId": "AR",
                            "Location": "-71.116667, -37.8",
                            "IataCode": "CVH",
                            "Airports": [
                                {
                                    "Id": "CVH",
                                    "Name": "Caviahue",
                                    "CityId": "CVHA",
                                    "CountryId": "AR",
                                    "Location": "-71, -37.916667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GY",
                    "Name": "Guyana",
                    "CurrencyId": "GYD",
                    "Cities": [
                        {
                            "Id": "PMTA",
                            "Name": "Paramakotoi",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-57.183333, 7.566667",
                            "IataCode": "PMT",
                            "Airports": [
                                {
                                    "Id": "PMT",
                                    "Name": "Paramakotoi",
                                    "CityId": "PMTA",
                                    "CountryId": "GY",
                                    "Location": "-57.183333, 7.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KTOA",
                            "Name": "Kato",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.816667, 4.6666667",
                            "IataCode": "KTO",
                            "Airports": [
                                {
                                    "Id": "KTO",
                                    "Name": "Kato",
                                    "CityId": "KTOA",
                                    "CountryId": "GY",
                                    "Location": "-59.816667, 4.65"
                                }
                            ]
                        },
                        {
                            "Id": "AHLA",
                            "Name": "Aishalton",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.25, 2.5166667",
                            "IataCode": "AHL",
                            "Airports": [
                                {
                                    "Id": "AHL",
                                    "Name": "Aishalton",
                                    "CityId": "AHLA",
                                    "CountryId": "GY",
                                    "Location": "-59.316667, 2.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KRMA",
                            "Name": "Karanambo",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.3, 3.75",
                            "IataCode": "KRM",
                            "Airports": [
                                {
                                    "Id": "KRM",
                                    "Name": "Karanambo",
                                    "CityId": "KRMA",
                                    "CountryId": "GY",
                                    "Location": "-59.316667, 3.75"
                                }
                            ]
                        },
                        {
                            "Id": "MHAA",
                            "Name": "Mahdia",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.15, 5.2666667",
                            "IataCode": "MHA",
                            "Airports": [
                                {
                                    "Id": "MHA",
                                    "Name": "Mahdia",
                                    "CityId": "MHAA",
                                    "CountryId": "GY",
                                    "Location": "-59.15, 5.25"
                                }
                            ]
                        },
                        {
                            "Id": "NAIA",
                            "Name": "Annai",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.133333, 3.95",
                            "IataCode": "NAI",
                            "Airports": [
                                {
                                    "Id": "NAI",
                                    "Name": "Annai",
                                    "CityId": "NAIA",
                                    "CountryId": "GY",
                                    "Location": "-59, 3.75"
                                }
                            ]
                        },
                        {
                            "Id": "GEOA",
                            "Name": "Georgetown",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.166667, 6.8",
                            "IataCode": "GEO",
                            "Airports": [
                                {
                                    "Id": "GEO",
                                    "Name": "Georgetown",
                                    "CityId": "GEOA",
                                    "CountryId": "GY",
                                    "Location": "-58.25, 6.5"
                                }
                            ]
                        },
                        {
                            "Id": "GFOA",
                            "Name": "Bartica",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.616667, 6.4",
                            "IataCode": "GFO",
                            "Airports": [
                                {
                                    "Id": "GFO",
                                    "Name": "Bartica",
                                    "CityId": "GFOA",
                                    "CountryId": "GY",
                                    "Location": "-58.583333, 6.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IMBA",
                            "Name": "Imbaimadai",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.283333, 5.7",
                            "IataCode": "IMB",
                            "Airports": [
                                {
                                    "Id": "IMB",
                                    "Name": "Imbaimadai",
                                    "CityId": "IMBA",
                                    "CountryId": "GY",
                                    "Location": "-60.283333, 5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "KARA",
                            "Name": "Kamarang",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.616667, 5.8666667",
                            "IataCode": "KAR",
                            "Airports": [
                                {
                                    "Id": "KAR",
                                    "Name": "Kamarang",
                                    "CityId": "KARA",
                                    "CountryId": "GY",
                                    "Location": "-60.616667, 5.883333"
                                }
                            ]
                        },
                        {
                            "Id": "KRGA",
                            "Name": "Karasabai",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.533333, 4.0333333",
                            "IataCode": "KRG",
                            "Airports": [
                                {
                                    "Id": "KRG",
                                    "Name": "Karasabai",
                                    "CityId": "KRGA",
                                    "CountryId": "GY",
                                    "Location": "-59.516667, 4.016667"
                                }
                            ]
                        },
                        {
                            "Id": "LTMA",
                            "Name": "Lethem",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.8, 3.3833333",
                            "IataCode": "LTM",
                            "Airports": [
                                {
                                    "Id": "LTM",
                                    "Name": "Lethem",
                                    "CityId": "LTMA",
                                    "CountryId": "GY",
                                    "Location": "-59.79, 3.371667"
                                }
                            ]
                        },
                        {
                            "Id": "MYMA",
                            "Name": "Monkey Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.683333, 4.4833333",
                            "IataCode": "MYM",
                            "Airports": [
                                {
                                    "Id": "MYM",
                                    "Name": "Monkey Mountain",
                                    "CityId": "MYMA",
                                    "CountryId": "GY",
                                    "Location": "-59.633333, 4.6"
                                }
                            ]
                        },
                        {
                            "Id": "ORJA",
                            "Name": "Orinduik",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.016667, 4.7",
                            "IataCode": "ORJ",
                            "Airports": [
                                {
                                    "Id": "ORJ",
                                    "Name": "Orinduik",
                                    "CityId": "ORJA",
                                    "CountryId": "GY",
                                    "Location": "-60.033333, 4.716667"
                                }
                            ]
                        },
                        {
                            "Id": "PRRA",
                            "Name": "Paruima",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-61.05, 5.8",
                            "IataCode": "PRR",
                            "Airports": [
                                {
                                    "Id": "PRR",
                                    "Name": "Paruima",
                                    "CityId": "PRRA",
                                    "CountryId": "GY",
                                    "Location": "-61.05, 5.8"
                                }
                            ]
                        },
                        {
                            "Id": "USIA",
                            "Name": "Mabaruma",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.783333, 8.2",
                            "IataCode": "USI",
                            "Airports": [
                                {
                                    "Id": "USI",
                                    "Name": "Mabaruma",
                                    "CityId": "USIA",
                                    "CountryId": "GY",
                                    "Location": "-59.833333, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BMJA",
                            "Name": "Baramita",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.483333, 7.35",
                            "IataCode": "BMJ",
                            "Airports": [
                                {
                                    "Id": "BMJ",
                                    "Name": "Baramita",
                                    "CityId": "BMJA",
                                    "CountryId": "GY",
                                    "Location": "-60, 6.75"
                                }
                            ]
                        },
                        {
                            "Id": "MWJA",
                            "Name": "Matthews Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.166667, 7.5",
                            "IataCode": "MWJ",
                            "Airports": [
                                {
                                    "Id": "MWJ",
                                    "Name": "Matthews Ridge",
                                    "CityId": "MWJA",
                                    "CountryId": "GY",
                                    "Location": "-59.9, 6.55"
                                }
                            ]
                        },
                        {
                            "Id": "PKMA",
                            "Name": "Port Kaituma",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.883333, 7.7333333",
                            "IataCode": "PKM",
                            "Airports": [
                                {
                                    "Id": "PKM",
                                    "Name": "Port Kaituma",
                                    "CityId": "PKMA",
                                    "CountryId": "GY",
                                    "Location": "-59.883333, 7.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SKMA",
                            "Name": "Skeldon",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-57.133333, 5.8833333",
                            "IataCode": "SKM",
                            "Airports": [
                                {
                                    "Id": "SKM",
                                    "Name": "Skeldon",
                                    "CityId": "SKMA",
                                    "CountryId": "GY",
                                    "Location": "-57.175, 5.877778"
                                }
                            ]
                        },
                        {
                            "Id": "LUBA",
                            "Name": "Lumid Pau",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.483333, 2.4",
                            "IataCode": "LUB",
                            "Airports": [
                                {
                                    "Id": "LUB",
                                    "Name": "Lumid Pau",
                                    "CityId": "LUBA",
                                    "CountryId": "GY",
                                    "Location": "-59.433333, 2.4"
                                }
                            ]
                        },
                        {
                            "Id": "KPGA",
                            "Name": "Upper Kamaria",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.8, 6.4333333",
                            "IataCode": "KPG",
                            "Airports": [
                                {
                                    "Id": "KPG",
                                    "Name": "Kurupung",
                                    "CityId": "KPGA",
                                    "CountryId": "GY",
                                    "Location": "-59.166667, 6.466667"
                                }
                            ]
                        },
                        {
                            "Id": "BCGA",
                            "Name": "Simirie",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.583333, 6.5166667",
                            "IataCode": "BCG",
                            "Airports": [
                                {
                                    "Id": "BCG",
                                    "Name": "Bemichi",
                                    "CityId": "BCGA",
                                    "CountryId": "GY",
                                    "Location": "-58.55, 6.55"
                                }
                            ]
                        },
                        {
                            "Id": "PIQA",
                            "Name": "Saint Mary's",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.016667, 5.2",
                            "IataCode": "PIQ",
                            "Airports": [
                                {
                                    "Id": "PIQ",
                                    "Name": "Pipillipai",
                                    "CityId": "PIQA",
                                    "CountryId": "GY",
                                    "Location": "-59, 5.5"
                                }
                            ]
                        },
                        {
                            "Id": "SDCA",
                            "Name": "Pukasanta Village",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.9, 3.0666667",
                            "IataCode": "SDC",
                            "Airports": [
                                {
                                    "Id": "SDC",
                                    "Name": "Sandcreek",
                                    "CityId": "SDCA",
                                    "CountryId": "GY",
                                    "Location": "-58.166667, 3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OGLA",
                            "Name": "Plaisance",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-58.1, 6.8",
                            "IataCode": "OGL",
                            "Airports": [
                                {
                                    "Id": "OGL",
                                    "Name": "Ogle",
                                    "CityId": "OGLA",
                                    "CountryId": "GY",
                                    "Location": "-58.105556, 6.8"
                                }
                            ]
                        },
                        {
                            "Id": "EKEA",
                            "Name": "Oranapai Landing",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.916667, 6.0666667",
                            "IataCode": "EKE",
                            "Airports": [
                                {
                                    "Id": "EKE",
                                    "Name": "Ekereku",
                                    "CityId": "EKEA",
                                    "CountryId": "GY",
                                    "Location": "-59.883333, 5.916667"
                                }
                            ]
                        },
                        {
                            "Id": "KKGA",
                            "Name": "Kopinang Mission",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.8, 4.9333333",
                            "IataCode": "KKG",
                            "Airports": [
                                {
                                    "Id": "KKG",
                                    "Name": "Konawaruk",
                                    "CityId": "KKGA",
                                    "CountryId": "GY",
                                    "Location": "-59.816667, 5.266667"
                                }
                            ]
                        },
                        {
                            "Id": "VEGA",
                            "Name": "Apanachi",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-59.216667, 5.65",
                            "IataCode": "VEG",
                            "Airports": [
                                {
                                    "Id": "VEG",
                                    "Name": "Maikwak",
                                    "CityId": "VEGA",
                                    "CountryId": "GY",
                                    "Location": "-59.283333, 5.55"
                                }
                            ]
                        },
                        {
                            "Id": "KAIA",
                            "Name": "Keweigek",
                            "SingleAirportCity": true,
                            "CountryId": "GY",
                            "Location": "-60.633333, 5.9333333",
                            "IataCode": "KAI",
                            "Airports": [
                                {
                                    "Id": "KAI",
                                    "Name": "Kaieteur",
                                    "CityId": "KAIA",
                                    "CountryId": "GY",
                                    "Location": "-59.49148, 5.17275"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VE",
                    "Name": "Venezuela",
                    "CurrencyId": "VEF",
                    "Cities": [
                        {
                            "Id": "KTVA",
                            "Name": "Kamarata",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.416667, 5.65",
                            "IataCode": "KTV",
                            "Airports": [
                                {
                                    "Id": "KTV",
                                    "Name": "Kamarata",
                                    "CityId": "KTVA",
                                    "CountryId": "VE",
                                    "Location": "-62.416667, 5.75"
                                }
                            ]
                        },
                        {
                            "Id": "AAOA",
                            "Name": "Anaco",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-64.472778, 9.4388889",
                            "IataCode": "AAO",
                            "Airports": [
                                {
                                    "Id": "AAO",
                                    "Name": "Anaco",
                                    "CityId": "AAOA",
                                    "CountryId": "VE",
                                    "Location": "-64.463889, 9.431944"
                                }
                            ]
                        },
                        {
                            "Id": "AGVA",
                            "Name": "Acarigua",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-69.201944, 9.5597222",
                            "IataCode": "AGV",
                            "Airports": [
                                {
                                    "Id": "AGV",
                                    "Name": "Acarigua",
                                    "CityId": "AGVA",
                                    "CountryId": "VE",
                                    "Location": "-69.233333, 9.552222"
                                }
                            ]
                        },
                        {
                            "Id": "GUQA",
                            "Name": "Guanare",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-69.75, 9.05",
                            "IataCode": "GUQ",
                            "Airports": [
                                {
                                    "Id": "GUQ",
                                    "Name": "Guanare",
                                    "CityId": "GUQA",
                                    "CountryId": "VE",
                                    "Location": "-69.75, 9.025278"
                                }
                            ]
                        },
                        {
                            "Id": "BLAA",
                            "Name": "Barcelona",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-64.7, 10.133333",
                            "IataCode": "BLA",
                            "Airports": [
                                {
                                    "Id": "BLA",
                                    "Name": "Barcelona",
                                    "CityId": "BLAA",
                                    "CountryId": "VE",
                                    "Location": "-64.6875, 10.109722"
                                }
                            ]
                        },
                        {
                            "Id": "BNSA",
                            "Name": "Barinas",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.207222, 8.6291667",
                            "IataCode": "BNS",
                            "Airports": [
                                {
                                    "Id": "BNS",
                                    "Name": "Barinas",
                                    "CityId": "BNSA",
                                    "CountryId": "VE",
                                    "Location": "-70.221111, 8.618056"
                                }
                            ]
                        },
                        {
                            "Id": "BRMA",
                            "Name": "Barquisimeto",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-69.322778, 10.073889",
                            "IataCode": "BRM",
                            "Airports": [
                                {
                                    "Id": "BRM",
                                    "Name": "Barquisimeto",
                                    "CityId": "BRMA",
                                    "CountryId": "VE",
                                    "Location": "-69.356111, 10.045278"
                                }
                            ]
                        },
                        {
                            "Id": "CBLA",
                            "Name": "Ciudad Bolivar",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-63.549722, 8.1222222",
                            "IataCode": "CBL",
                            "Airports": [
                                {
                                    "Id": "CBL",
                                    "Name": "Ciudad Bolivar",
                                    "CityId": "CBLA",
                                    "CountryId": "VE",
                                    "Location": "-63.5375, 8.128056"
                                }
                            ]
                        },
                        {
                            "Id": "CBSA",
                            "Name": "Cabimas",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.446111, 10.401944",
                            "IataCode": "CBS",
                            "Airports": [
                                {
                                    "Id": "CBS",
                                    "Name": "Cabimas",
                                    "CityId": "CBSA",
                                    "CountryId": "VE",
                                    "Location": "-71.416667, 10.383333"
                                }
                            ]
                        },
                        {
                            "Id": "CCSA",
                            "Name": "Caracas",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66.916667, 10.5",
                            "IataCode": "CCS",
                            "Airports": [
                                {
                                    "Id": "CCS",
                                    "Name": "Caracas",
                                    "CityId": "CCSA",
                                    "CountryId": "VE",
                                    "Location": "-66.989167, 10.606389"
                                }
                            ]
                        },
                        {
                            "Id": "CLZA",
                            "Name": "Calabozo",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-67.426667, 8.9344444",
                            "IataCode": "CLZ",
                            "Airports": [
                                {
                                    "Id": "CLZ",
                                    "Name": "Calabozo",
                                    "CityId": "CLZA",
                                    "CountryId": "VE",
                                    "Location": "-67.41709, 8.92466"
                                }
                            ]
                        },
                        {
                            "Id": "CUMA",
                            "Name": "Cumana",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-64.166667, 10.466667",
                            "IataCode": "CUM",
                            "Airports": [
                                {
                                    "Id": "CUM",
                                    "Name": "Cumana",
                                    "CityId": "CUMA",
                                    "CountryId": "VE",
                                    "Location": "-64.134192, 10.451983"
                                }
                            ]
                        },
                        {
                            "Id": "CUPA",
                            "Name": "Carupano",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-63.249167, 10.669722",
                            "IataCode": "CUP",
                            "Airports": [
                                {
                                    "Id": "CUP",
                                    "Name": "Carupano",
                                    "CityId": "CUPA",
                                    "CountryId": "VE",
                                    "Location": "-63.2625, 10.658889"
                                }
                            ]
                        },
                        {
                            "Id": "CXAA",
                            "Name": "Caicara De Oro",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66.172222, 7.6463889",
                            "IataCode": "CXA",
                            "Airports": [
                                {
                                    "Id": "CXA",
                                    "Name": "Caicara De Oro",
                                    "CityId": "CXAA",
                                    "CountryId": "VE",
                                    "Location": "-66.15, 7.533333"
                                }
                            ]
                        },
                        {
                            "Id": "CZEA",
                            "Name": "Coro",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-69.667222, 11.409167",
                            "IataCode": "CZE",
                            "Airports": [
                                {
                                    "Id": "CZE",
                                    "Name": "Coro",
                                    "CityId": "CZEA",
                                    "CountryId": "VE",
                                    "Location": "-69.681944, 11.415833"
                                }
                            ]
                        },
                        {
                            "Id": "EORA",
                            "Name": "El Dorado",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.633333, 6.7333333",
                            "IataCode": "EOR",
                            "Airports": [
                                {
                                    "Id": "EOR",
                                    "Name": "El Dorado",
                                    "CityId": "EORA",
                                    "CountryId": "VE",
                                    "Location": "-61.883333, 6.733333"
                                }
                            ]
                        },
                        {
                            "Id": "EOZA",
                            "Name": "Elorza",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-69.496111, 7.0663889",
                            "IataCode": "EOZ",
                            "Airports": [
                                {
                                    "Id": "EOZ",
                                    "Name": "Elorza",
                                    "CityId": "EOZA",
                                    "CountryId": "VE",
                                    "Location": "-69.533333, 7.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GDOA",
                            "Name": "Guasdualito",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.729444, 7.2472222",
                            "IataCode": "GDO",
                            "Airports": [
                                {
                                    "Id": "GDO",
                                    "Name": "Guasdualito",
                                    "CityId": "GDOA",
                                    "CountryId": "VE",
                                    "Location": "-70.75645, 7.21108"
                                }
                            ]
                        },
                        {
                            "Id": "GUIA",
                            "Name": "Guiria",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.294444, 10.571111",
                            "IataCode": "GUI",
                            "Airports": [
                                {
                                    "Id": "GUI",
                                    "Name": "Guiria",
                                    "CityId": "GUIA",
                                    "CountryId": "VE",
                                    "Location": "-62.3, 10.566667"
                                }
                            ]
                        },
                        {
                            "Id": "ICAA",
                            "Name": "Icabaru",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.75, 4.3333333",
                            "IataCode": "ICA",
                            "Airports": [
                                {
                                    "Id": "ICA",
                                    "Name": "Icabaru",
                                    "CityId": "ICAA",
                                    "CountryId": "VE",
                                    "Location": "-61.733333, 4.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LAGA",
                            "Name": "La Guaira",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66.934601, 10.599011",
                            "IataCode": "LAG",
                            "Airports": [
                                {
                                    "Id": "LAG",
                                    "Name": "La Guaira",
                                    "CityId": "LAGA",
                                    "CountryId": "VE",
                                    "Location": "-66.933333, 10.6"
                                }
                            ]
                        },
                        {
                            "Id": "KAVB",
                            "Name": "Kavanayen",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.8, 5.6333333",
                            "IataCode": "KAV",
                            "Airports": [
                                {
                                    "Id": "KAV",
                                    "Name": "Kavanayen",
                                    "CityId": "KAVB",
                                    "CountryId": "VE",
                                    "Location": "-61.766667, 5.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LFRA",
                            "Name": "La Fria",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-72.248333, 8.2188889",
                            "IataCode": "LFR",
                            "Airports": [
                                {
                                    "Id": "LFR",
                                    "Name": "La Fria",
                                    "CityId": "LFRA",
                                    "CountryId": "VE",
                                    "Location": "-72.272222, 8.240556"
                                }
                            ]
                        },
                        {
                            "Id": "LSPA",
                            "Name": "Las Piedras",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.219444, 11.716667",
                            "IataCode": "LSP",
                            "Airports": [
                                {
                                    "Id": "LSP",
                                    "Name": "Las Piedras",
                                    "CityId": "LSPA",
                                    "CountryId": "VE",
                                    "Location": "-70.144615, 11.781661"
                                }
                            ]
                        },
                        {
                            "Id": "MARB",
                            "Name": "Maracaibo",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.640554, 10.631667",
                            "IataCode": "MAR",
                            "Airports": [
                                {
                                    "Id": "MAR",
                                    "Name": "Maracaibo",
                                    "CityId": "MARB",
                                    "CountryId": "VE",
                                    "Location": "-71.733333, 10.566667"
                                }
                            ]
                        },
                        {
                            "Id": "MRDA",
                            "Name": "Merida",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.145, 8.5983333",
                            "IataCode": "MRD",
                            "Airports": [
                                {
                                    "Id": "MRD",
                                    "Name": "Merida",
                                    "CityId": "MRDA",
                                    "CountryId": "VE",
                                    "Location": "-71.157778, 8.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MUNA",
                            "Name": "Maturin",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-63.176667, 9.75",
                            "IataCode": "MUN",
                            "Airports": [
                                {
                                    "Id": "MUN",
                                    "Name": "Maturin",
                                    "CityId": "MUNA",
                                    "CountryId": "VE",
                                    "Location": "-63.154167, 9.745833"
                                }
                            ]
                        },
                        {
                            "Id": "PMVA",
                            "Name": "Porlamar",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-63.85, 10.95",
                            "IataCode": "PMV",
                            "Airports": [
                                {
                                    "Id": "PMV",
                                    "Name": "Porlamar",
                                    "CityId": "PMVA",
                                    "CountryId": "VE",
                                    "Location": "-63.966179, 10.911696"
                                }
                            ]
                        },
                        {
                            "Id": "PYHA",
                            "Name": "Puerto Ayacucho",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-67.623611, 5.6638889",
                            "IataCode": "PYH",
                            "Airports": [
                                {
                                    "Id": "PYH",
                                    "Name": "Puerto Ayacucho",
                                    "CityId": "PYHA",
                                    "CountryId": "VE",
                                    "Location": "-67.5, 5.6"
                                }
                            ]
                        },
                        {
                            "Id": "STDA",
                            "Name": "Santo Domingo",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-72.045833, 7.5708333",
                            "IataCode": "STD",
                            "Airports": [
                                {
                                    "Id": "STD",
                                    "Name": "Santo Domingo",
                                    "CityId": "STDA",
                                    "CountryId": "VE",
                                    "Location": "-72.066667, 7.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SOMA",
                            "Name": "San Tome",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-64.130833, 8.9408333",
                            "IataCode": "SOM",
                            "Airports": [
                                {
                                    "Id": "SOM",
                                    "Name": "San Tome",
                                    "CityId": "SOMA",
                                    "CountryId": "VE",
                                    "Location": "-64.145833, 8.949167"
                                }
                            ]
                        },
                        {
                            "Id": "VDPA",
                            "Name": "Valle De Pascua",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66, 9.2166667",
                            "IataCode": "VDP",
                            "Airports": [
                                {
                                    "Id": "VDP",
                                    "Name": "Valle De Pascua",
                                    "CityId": "VDPA",
                                    "CountryId": "VE",
                                    "Location": "-65.991111, 9.221389"
                                }
                            ]
                        },
                        {
                            "Id": "VIGA",
                            "Name": "El Vigia",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.650555, 8.6219444",
                            "IataCode": "VIG",
                            "Airports": [
                                {
                                    "Id": "VIG",
                                    "Name": "El Vigia",
                                    "CityId": "VIGA",
                                    "CountryId": "VE",
                                    "Location": "-71.668196, 8.62269"
                                }
                            ]
                        },
                        {
                            "Id": "VLNA",
                            "Name": "Valencia",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-68.003889, 10.180556",
                            "IataCode": "VLN",
                            "Airports": [
                                {
                                    "Id": "VLN",
                                    "Name": "Valencia",
                                    "CityId": "VLNA",
                                    "CountryId": "VE",
                                    "Location": "-67.926235, 10.150296"
                                }
                            ]
                        },
                        {
                            "Id": "VLVA",
                            "Name": "Valera",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.603611, 9.3177778",
                            "IataCode": "VLV",
                            "Airports": [
                                {
                                    "Id": "VLV",
                                    "Name": "Valera",
                                    "CityId": "VLVA",
                                    "CountryId": "VE",
                                    "Location": "-70.585833, 9.339167"
                                }
                            ]
                        },
                        {
                            "Id": "MYCA",
                            "Name": "Maracay",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-67.595833, 10.246944",
                            "IataCode": "MYC",
                            "Airports": [
                                {
                                    "Id": "MYC",
                                    "Name": "Maracay",
                                    "CityId": "MYCA",
                                    "CountryId": "VE",
                                    "Location": "-68.166667, 10.5"
                                }
                            ]
                        },
                        {
                            "Id": "PBLA",
                            "Name": "Puerto Cabello",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-68.0125, 10.473056",
                            "IataCode": "PBL",
                            "Airports": [
                                {
                                    "Id": "PBL",
                                    "Name": "Puerto Cabello",
                                    "CityId": "PBLA",
                                    "CountryId": "VE",
                                    "Location": "-68.073611, 10.479167"
                                }
                            ]
                        },
                        {
                            "Id": "SCIA",
                            "Name": "San Cristobal",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-72.225, 7.7669444",
                            "IataCode": "SCI",
                            "Airports": [
                                {
                                    "Id": "SCI",
                                    "Name": "San Cristobal",
                                    "CityId": "SCIA",
                                    "CountryId": "VE",
                                    "Location": "-72.233333, 7.766667"
                                }
                            ]
                        },
                        {
                            "Id": "SNFA",
                            "Name": "San Felipe",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-68.737222, 10.340556",
                            "IataCode": "SNF",
                            "Airports": [
                                {
                                    "Id": "SNF",
                                    "Name": "San Felipe",
                                    "CityId": "SNFA",
                                    "CountryId": "VE",
                                    "Location": "-68.753889, 10.280278"
                                }
                            ]
                        },
                        {
                            "Id": "CGUA",
                            "Name": "Ciudad Guayana",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.652778, 8.3533333",
                            "IataCode": "CGU",
                            "Airports": [
                                {
                                    "Id": "CGU",
                                    "Name": "Ciudad Guayana",
                                    "CityId": "CGUA",
                                    "CountryId": "VE",
                                    "Location": "-62.734444, 8.305"
                                }
                            ]
                        },
                        {
                            "Id": "PDZA",
                            "Name": "Pedernales",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.264167, 9.9591667",
                            "IataCode": "PDZ",
                            "Airports": [
                                {
                                    "Id": "PDZ",
                                    "Name": "Pedernales",
                                    "CityId": "PDZA",
                                    "CountryId": "VE",
                                    "Location": "-62.233333, 9.966667"
                                }
                            ]
                        },
                        {
                            "Id": "PPZA",
                            "Name": "Puerto Paez",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-67.450278, 6.2108333",
                            "IataCode": "PPZ",
                            "Airports": [
                                {
                                    "Id": "PPZ",
                                    "Name": "Puerto Paez",
                                    "CityId": "PPZA",
                                    "CountryId": "VE",
                                    "Location": "-67.446944, 6.226111"
                                }
                            ]
                        },
                        {
                            "Id": "PTMA",
                            "Name": "Palmarito",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.16, 7.6255556",
                            "IataCode": "PTM",
                            "Airports": [
                                {
                                    "Id": "PTM",
                                    "Name": "Palmarito",
                                    "CityId": "PTMA",
                                    "CountryId": "VE",
                                    "Location": "-70.183333, 7.566667"
                                }
                            ]
                        },
                        {
                            "Id": "SNVA",
                            "Name": "Santa Elena",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.133333, 4.6166667",
                            "IataCode": "SNV",
                            "Airports": [
                                {
                                    "Id": "SNV",
                                    "Name": "Santa Elena",
                                    "CityId": "SNVA",
                                    "CountryId": "VE",
                                    "Location": "-61.116667, 4.55"
                                }
                            ]
                        },
                        {
                            "Id": "TMOA",
                            "Name": "Tumeremo",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.5, 7.3",
                            "IataCode": "TMO",
                            "Airports": [
                                {
                                    "Id": "TMO",
                                    "Name": "Tumeremo",
                                    "CityId": "TMOA",
                                    "CountryId": "VE",
                                    "Location": "-61.5, 7.3"
                                }
                            ]
                        },
                        {
                            "Id": "TUVA",
                            "Name": "Tucupita",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.049167, 9.0686111",
                            "IataCode": "TUV",
                            "Airports": [
                                {
                                    "Id": "TUV",
                                    "Name": "Tucupita",
                                    "CityId": "TUVA",
                                    "CountryId": "VE",
                                    "Location": "-62.05, 9.091667"
                                }
                            ]
                        },
                        {
                            "Id": "URMA",
                            "Name": "Uriman",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.65, 5.35",
                            "IataCode": "URM",
                            "Airports": [
                                {
                                    "Id": "URM",
                                    "Name": "Uriman",
                                    "CityId": "URMA",
                                    "CountryId": "VE",
                                    "Location": "-62.758333, 5.341667"
                                }
                            ]
                        },
                        {
                            "Id": "VCRA",
                            "Name": "Carora",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-70.080556, 10.177778",
                            "IataCode": "VCR",
                            "Airports": [
                                {
                                    "Id": "VCR",
                                    "Name": "Carora",
                                    "CityId": "VCRA",
                                    "CountryId": "VE",
                                    "Location": "-70.083333, 10.183333"
                                }
                            ]
                        },
                        {
                            "Id": "SFDA",
                            "Name": "San Fernando De Apure",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-67.467222, 7.8966667",
                            "IataCode": "SFD",
                            "Airports": [
                                {
                                    "Id": "SFD",
                                    "Name": "San Fernando De Apure",
                                    "CityId": "SFDA",
                                    "CountryId": "VE",
                                    "Location": "-67.439167, 7.885278"
                                }
                            ]
                        },
                        {
                            "Id": "ELXA",
                            "Name": "El Tigre",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-64.252778, 8.8925",
                            "IataCode": "ELX",
                            "Airports": [
                                {
                                    "Id": "ELX",
                                    "Name": "El Tigre",
                                    "CityId": "ELXA",
                                    "CountryId": "VE",
                                    "Location": "-64.266667, 8.883333"
                                }
                            ]
                        },
                        {
                            "Id": "SBBA",
                            "Name": "Santa Barbara Ba",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.173611, 7.8180556",
                            "IataCode": "SBB",
                            "Airports": [
                                {
                                    "Id": "SBB",
                                    "Name": "Santa Barbara Ba",
                                    "CityId": "SBBA",
                                    "CountryId": "VE",
                                    "Location": "-71.166667, 7.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SFXA",
                            "Name": "San Felix",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.7, 7.55",
                            "IataCode": "SFX",
                            "Airports": [
                                {
                                    "Id": "SFX",
                                    "Name": "San Felix",
                                    "CityId": "SFXA",
                                    "CountryId": "VE",
                                    "Location": "-62.383333, 8.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SVZA",
                            "Name": "San Antonio",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-72.4425, 7.8177778",
                            "IataCode": "SVZ",
                            "Airports": [
                                {
                                    "Id": "SVZ",
                                    "Name": "San Antonio",
                                    "CityId": "SVZA",
                                    "CountryId": "VE",
                                    "Location": "-72.438056, 7.843056"
                                }
                            ]
                        },
                        {
                            "Id": "LPJA",
                            "Name": "Pijiguaos",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66.747222, 6.5886111",
                            "IataCode": "LPJ",
                            "Airports": [
                                {
                                    "Id": "LPJ",
                                    "Name": "Pijiguaos",
                                    "CityId": "LPJA",
                                    "CountryId": "VE",
                                    "Location": "-67.666667, 7"
                                }
                            ]
                        },
                        {
                            "Id": "LGYA",
                            "Name": "Lagunillas",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.386944, 8.5083333",
                            "IataCode": "LGY",
                            "Airports": [
                                {
                                    "Id": "LGY",
                                    "Name": "Lagunillas",
                                    "CityId": "LGYA",
                                    "CountryId": "VE",
                                    "Location": "-71.4, 8.516667"
                                }
                            ]
                        },
                        {
                            "Id": "CUVA",
                            "Name": "Casigua",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.009167, 11.021111",
                            "IataCode": "CUV",
                            "Airports": [
                                {
                                    "Id": "CUV",
                                    "Name": "Casigua",
                                    "CityId": "CUVA",
                                    "CountryId": "VE",
                                    "Location": "-70.95, 11.066667"
                                }
                            ]
                        },
                        {
                            "Id": "STBB",
                            "Name": "Santa Barbara Ed",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-71.905, 9.0083333",
                            "IataCode": "STB",
                            "Airports": [
                                {
                                    "Id": "STB",
                                    "Name": "Santa Barbara Ed",
                                    "CityId": "STBB",
                                    "CountryId": "VE",
                                    "Location": "-71.95, 9.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PPHA",
                            "Name": "Peraitepuy",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.495556, 4.5966667",
                            "IataCode": "PPH",
                            "Airports": [
                                {
                                    "Id": "PPH",
                                    "Name": "Peraitepuy",
                                    "CityId": "PPHA",
                                    "CountryId": "VE",
                                    "Location": "-61.516667, 4.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SVVA",
                            "Name": "San Salvador De",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.398889, 7.21",
                            "IataCode": "SVV",
                            "Airports": [
                                {
                                    "Id": "SVV",
                                    "Name": "San Salvador De",
                                    "CityId": "SVVA",
                                    "CountryId": "VE",
                                    "Location": "-62, 7"
                                }
                            ]
                        },
                        {
                            "Id": "LRVA",
                            "Name": "Los Roques",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-66.7575, 11.8575",
                            "IataCode": "LRV",
                            "Airports": [
                                {
                                    "Id": "LRV",
                                    "Name": "Los Roques",
                                    "CityId": "LRVA",
                                    "CountryId": "VE",
                                    "Location": "-66.75, 11.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CAJA",
                            "Name": "Canaima",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.85258, 6.241644",
                            "IataCode": "CAJ",
                            "Airports": [
                                {
                                    "Id": "CAJ",
                                    "Name": "Canaima",
                                    "CityId": "CAJA",
                                    "CountryId": "VE",
                                    "Location": "-62.833333, 6.25"
                                }
                            ]
                        },
                        {
                            "Id": "PZOA",
                            "Name": "Morocure",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-62.7075, 8.2602778",
                            "IataCode": "PZO",
                            "Airports": [
                                {
                                    "Id": "PZO",
                                    "Name": "Puerto Ordaz",
                                    "CityId": "PZOA",
                                    "CountryId": "VE",
                                    "Location": "-62.763348, 8.287429"
                                }
                            ]
                        },
                        {
                            "Id": "WOKA",
                            "Name": "Amurug",
                            "SingleAirportCity": true,
                            "CountryId": "VE",
                            "Location": "-61.683333, 5.2333333",
                            "IataCode": "WOK",
                            "Airports": [
                                {
                                    "Id": "WOK",
                                    "Name": "Wonken",
                                    "CityId": "WOKA",
                                    "CountryId": "VE",
                                    "Location": "-61.733333, 5.25"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SR",
                    "Name": "Suriname",
                    "CurrencyId": "SRD",
                    "Cities": [
                        {
                            "Id": "DRJA",
                            "Name": "Drietabbetje",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-54.666667, 4.1166667",
                            "IataCode": "DRJ",
                            "Airports": [
                                {
                                    "Id": "DRJ",
                                    "Name": "Drietabbetje",
                                    "CityId": "DRJA",
                                    "CountryId": "SR",
                                    "Location": "-54.666667, 4.116667"
                                }
                            ]
                        },
                        {
                            "Id": "ICKA",
                            "Name": "Nieuw Nickerie",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-56.983333, 5.95",
                            "IataCode": "ICK",
                            "Airports": [
                                {
                                    "Id": "ICK",
                                    "Name": "Nieuw Nickerie",
                                    "CityId": "ICKA",
                                    "CountryId": "SR",
                                    "Location": "-56.983333, 5.933333"
                                }
                            ]
                        },
                        {
                            "Id": "PBMA",
                            "Name": "Paramaribo",
                            "SingleAirportCity": false,
                            "CountryId": "SR",
                            "Location": "-55.166667, 5.8333333",
                            "IataCode": "PBM",
                            "Airports": [
                                {
                                    "Id": "PBM",
                                    "Name": "Paramaribo Zanderij",
                                    "CityId": "PBMA",
                                    "CountryId": "SR",
                                    "Location": "-55.191111, 5.451389"
                                },
                                {
                                    "Id": "ORG",
                                    "Name": "Paramaribo Zorg En Hoop",
                                    "CityId": "PBMA",
                                    "CountryId": "SR",
                                    "Location": "-55.191111, 5.809444"
                                }
                            ]
                        },
                        {
                            "Id": "TOTA",
                            "Name": "Totness",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-56.316667, 5.8833333",
                            "IataCode": "TOT",
                            "Airports": [
                                {
                                    "Id": "TOT",
                                    "Name": "Totness",
                                    "CityId": "TOTA",
                                    "CountryId": "SR",
                                    "Location": "-56.316667, 5.883333"
                                }
                            ]
                        },
                        {
                            "Id": "AGIA",
                            "Name": "Wageningen",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-56.683333, 5.7666667",
                            "IataCode": "AGI",
                            "Airports": [
                                {
                                    "Id": "AGI",
                                    "Name": "Wageningen",
                                    "CityId": "AGIA",
                                    "CountryId": "SR",
                                    "Location": "-56.833333, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WSOA",
                            "Name": "Washabo",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-57.183333, 5.2166667",
                            "IataCode": "WSO",
                            "Airports": [
                                {
                                    "Id": "WSO",
                                    "Name": "Washabo",
                                    "CityId": "WSOA",
                                    "CountryId": "SR",
                                    "Location": "-57.183333, 5.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BTOA",
                            "Name": "Botopasie",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.45, 4.2333333",
                            "IataCode": "BTO",
                            "Airports": [
                                {
                                    "Id": "BTO",
                                    "Name": "Botopasie",
                                    "CityId": "BTOA",
                                    "CountryId": "SR",
                                    "Location": "-55.45, 4.25"
                                }
                            ]
                        },
                        {
                            "Id": "MOJA",
                            "Name": "Moengo",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-54.4, 5.6166667",
                            "IataCode": "MOJ",
                            "Airports": [
                                {
                                    "Id": "MOJ",
                                    "Name": "Moengo",
                                    "CityId": "MOJA",
                                    "CountryId": "SR",
                                    "Location": "-54.333333, 5.75"
                                }
                            ]
                        },
                        {
                            "Id": "LDOA",
                            "Name": "Ladouanie",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.4, 4.3833333",
                            "IataCode": "LDO",
                            "Airports": [
                                {
                                    "Id": "LDO",
                                    "Name": "Ladouanie",
                                    "CityId": "LDOA",
                                    "CountryId": "SR",
                                    "Location": "-55.233333, 4.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SMZA",
                            "Name": "Stoelmans Eiland",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-54.416667, 4.35",
                            "IataCode": "SMZ",
                            "Airports": [
                                {
                                    "Id": "SMZ",
                                    "Name": "Stoelmans Eiland",
                                    "CityId": "SMZA",
                                    "CountryId": "SR",
                                    "Location": "-54.4, 4.343056"
                                }
                            ]
                        },
                        {
                            "Id": "DOEA",
                            "Name": "Djoemoe",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.483333, 4.0166667",
                            "IataCode": "DOE",
                            "Airports": [
                                {
                                    "Id": "DOE",
                                    "Name": "Djoemoe",
                                    "CityId": "DOEA",
                                    "CountryId": "SR",
                                    "Location": "-55.483333, 4.016667"
                                }
                            ]
                        },
                        {
                            "Id": "AAJA",
                            "Name": "Awaradam",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.65, 3.8333333",
                            "IataCode": "AAJ",
                            "Airports": [
                                {
                                    "Id": "AAJ",
                                    "Name": "Awaradam",
                                    "CityId": "AAJA",
                                    "CountryId": "SR",
                                    "Location": "-55.366667, 3.9"
                                }
                            ]
                        },
                        {
                            "Id": "ABNA",
                            "Name": "Albina",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-54.05, 5.5",
                            "IataCode": "ABN",
                            "Airports": [
                                {
                                    "Id": "ABN",
                                    "Name": "Albina",
                                    "CityId": "ABNA",
                                    "CountryId": "SR",
                                    "Location": "-54.083333, 5.5"
                                }
                            ]
                        },
                        {
                            "Id": "OEMA",
                            "Name": "Pepejoe",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.45, 3.35",
                            "IataCode": "OEM",
                            "Airports": [
                                {
                                    "Id": "OEM",
                                    "Name": "Paloemeu",
                                    "CityId": "OEMA",
                                    "CountryId": "SR",
                                    "Location": "-55.45, 3.35"
                                }
                            ]
                        },
                        {
                            "Id": "KCBA",
                            "Name": "Alopi",
                            "SingleAirportCity": true,
                            "CountryId": "SR",
                            "Location": "-55.533333, 3.25",
                            "IataCode": "KCB",
                            "Airports": [
                                {
                                    "Id": "KCB",
                                    "Name": "Kasikasima",
                                    "CityId": "KCBA",
                                    "CountryId": "SR",
                                    "Location": "-55.716667, 3.15"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "EC",
                    "Name": "Ecuador",
                    "CurrencyId": "USD",
                    "LanguageId": "ES",
                    "Cities": [
                        {
                            "Id": "XMSA",
                            "Name": "Macas",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.116667, -2.3166667",
                            "IataCode": "XMS",
                            "Airports": [
                                {
                                    "Id": "XMS",
                                    "Name": "Macas",
                                    "CityId": "XMSA",
                                    "CountryId": "EC",
                                    "Location": "-78.133333, -2.316667"
                                }
                            ]
                        },
                        {
                            "Id": "BHAA",
                            "Name": "Bahia De Caraquez",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-80.416667, -0.6",
                            "IataCode": "BHA",
                            "Airports": [
                                {
                                    "Id": "BHA",
                                    "Name": "Bahia De Caraquez",
                                    "CityId": "BHAA",
                                    "CountryId": "EC",
                                    "Location": "-80.416667, -0.6"
                                }
                            ]
                        },
                        {
                            "Id": "CUEA",
                            "Name": "Cuenca",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.983334, -2.8833333",
                            "IataCode": "CUE",
                            "Airports": [
                                {
                                    "Id": "CUE",
                                    "Name": "Cuenca",
                                    "CityId": "CUEA",
                                    "CountryId": "EC",
                                    "Location": "-78.983333, -2.866667"
                                }
                            ]
                        },
                        {
                            "Id": "GYEA",
                            "Name": "Guayaquil",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.9, -2.1666667",
                            "IataCode": "GYE",
                            "Airports": [
                                {
                                    "Id": "GYE",
                                    "Name": "Guayaquil",
                                    "CityId": "GYEA",
                                    "CountryId": "EC",
                                    "Location": "-79.875556, -2.154444"
                                }
                            ]
                        },
                        {
                            "Id": "LGQA",
                            "Name": "Lago Agrio",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-76.882778, 0.0847222",
                            "IataCode": "LGQ",
                            "Airports": [
                                {
                                    "Id": "LGQ",
                                    "Name": "Lago Agrio",
                                    "CityId": "LGQA",
                                    "CountryId": "EC",
                                    "Location": "-76.883333, 0.1"
                                }
                            ]
                        },
                        {
                            "Id": "LOHA",
                            "Name": "Loja",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.204216, -3.9931262",
                            "IataCode": "LOH",
                            "Airports": [
                                {
                                    "Id": "LOH",
                                    "Name": "Loja",
                                    "CityId": "LOHA",
                                    "CountryId": "EC",
                                    "Location": "-79.373611, -3.993611"
                                }
                            ]
                        },
                        {
                            "Id": "MECA",
                            "Name": "Manta",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-80.733333, -0.95",
                            "IataCode": "MEC",
                            "Airports": [
                                {
                                    "Id": "MEC",
                                    "Name": "Manta",
                                    "CityId": "MECA",
                                    "CountryId": "EC",
                                    "Location": "-80.683333, -0.95"
                                }
                            ]
                        },
                        {
                            "Id": "MZDA",
                            "Name": "Mendez",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.316667, -2.6833333",
                            "IataCode": "MZD",
                            "Airports": [
                                {
                                    "Id": "MZD",
                                    "Name": "Mendez",
                                    "CityId": "MZDA",
                                    "CountryId": "EC",
                                    "Location": "-78.316667, -2.733333"
                                }
                            ]
                        },
                        {
                            "Id": "OCCA",
                            "Name": "Coca",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-76.966667, -0.4666667",
                            "IataCode": "OCC",
                            "Airports": [
                                {
                                    "Id": "OCC",
                                    "Name": "Coca",
                                    "CityId": "OCCA",
                                    "CountryId": "EC",
                                    "Location": "-76.986667, -0.462778"
                                }
                            ]
                        },
                        {
                            "Id": "SNCA",
                            "Name": "Salinas",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-80.966667, -2.2166667",
                            "IataCode": "SNC",
                            "Airports": [
                                {
                                    "Id": "SNC",
                                    "Name": "Salinas",
                                    "CityId": "SNCA",
                                    "CountryId": "EC",
                                    "Location": "-80.983333, -2.2"
                                }
                            ]
                        },
                        {
                            "Id": "UIOA",
                            "Name": "Quito",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.500001, -0.2166667",
                            "IataCode": "UIO",
                            "Airports": [
                                {
                                    "Id": "UIO",
                                    "Name": "Quito",
                                    "CityId": "UIOA",
                                    "CountryId": "EC",
                                    "Location": "-78.486111, -0.137778"
                                }
                            ]
                        },
                        {
                            "Id": "ATFA",
                            "Name": "Ambato",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.616748, -1.2490809",
                            "IataCode": "ATF",
                            "Airports": [
                                {
                                    "Id": "ATF",
                                    "Name": "Ambato",
                                    "CityId": "ATFA",
                                    "CountryId": "EC",
                                    "Location": "-78.7, -1.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MCHA",
                            "Name": "Machala",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.966667, -3.2666667",
                            "IataCode": "MCH",
                            "Airports": [
                                {
                                    "Id": "MCH",
                                    "Name": "Machala",
                                    "CityId": "MCHA",
                                    "CountryId": "EC",
                                    "Location": "-79.983333, -3.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MRRA",
                            "Name": "Macara",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.95, -4.3833333",
                            "IataCode": "MRR",
                            "Airports": [
                                {
                                    "Id": "MRR",
                                    "Name": "Macara",
                                    "CityId": "MRRA",
                                    "CountryId": "EC",
                                    "Location": "-79.933333, -4.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PVOA",
                            "Name": "Portoviejo",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-80.45, -1.05",
                            "IataCode": "PVO",
                            "Airports": [
                                {
                                    "Id": "PVO",
                                    "Name": "Portoviejo",
                                    "CityId": "PVOA",
                                    "CountryId": "EC",
                                    "Location": "-80.466667, -1.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PYOA",
                            "Name": "Putumayo",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-75.866667, 0.1166667",
                            "IataCode": "PYO",
                            "Airports": [
                                {
                                    "Id": "PYO",
                                    "Name": "Putumayo",
                                    "CityId": "PYOA",
                                    "CountryId": "EC",
                                    "Location": "-75.9, 0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SUQA",
                            "Name": "Sucua",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.166667, -2.4666667",
                            "IataCode": "SUQ",
                            "Airports": [
                                {
                                    "Id": "SUQ",
                                    "Name": "Sucua",
                                    "CityId": "SUQA",
                                    "CountryId": "EC",
                                    "Location": "-78.166667, -2.466667"
                                }
                            ]
                        },
                        {
                            "Id": "TPNA",
                            "Name": "Tiputini",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-75.533333, -0.75",
                            "IataCode": "TPN",
                            "Airports": [
                                {
                                    "Id": "TPN",
                                    "Name": "Tiputini",
                                    "CityId": "TPNA",
                                    "CountryId": "EC",
                                    "Location": "-75.533333, -0.766667"
                                }
                            ]
                        },
                        {
                            "Id": "SCYA",
                            "Name": "San Cristobal",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-89.566667, -0.9166667",
                            "IataCode": "SCY",
                            "Airports": [
                                {
                                    "Id": "SCY",
                                    "Name": "San Cristobal",
                                    "CityId": "SCYA",
                                    "CountryId": "EC",
                                    "Location": "-89.61745, -0.91021"
                                }
                            ]
                        },
                        {
                            "Id": "PTZA",
                            "Name": "Pastaza",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-76.873234, -1.7074613",
                            "IataCode": "PTZ",
                            "Airports": [
                                {
                                    "Id": "PTZ",
                                    "Name": "Pastaza",
                                    "CityId": "PTZA",
                                    "CountryId": "EC",
                                    "Location": "-77, -2"
                                }
                            ]
                        },
                        {
                            "Id": "TUAA",
                            "Name": "Tulcan",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-77.716667, 0.8",
                            "IataCode": "TUA",
                            "Airports": [
                                {
                                    "Id": "TUA",
                                    "Name": "Tulcan",
                                    "CityId": "TUAA",
                                    "CountryId": "EC",
                                    "Location": "-77.716667, 0.8"
                                }
                            ]
                        },
                        {
                            "Id": "TPCA",
                            "Name": "Tarapoa",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-76.783333, -1.4166667",
                            "IataCode": "TPC",
                            "Airports": [
                                {
                                    "Id": "TPC",
                                    "Name": "Tarapoa",
                                    "CityId": "TPCA",
                                    "CountryId": "EC",
                                    "Location": "-76.783333, -1.416667"
                                }
                            ]
                        },
                        {
                            "Id": "TSCA",
                            "Name": "Taisha",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-77.5, -2.3833333",
                            "IataCode": "TSC",
                            "Airports": [
                                {
                                    "Id": "TSC",
                                    "Name": "Taisha",
                                    "CityId": "TSCA",
                                    "CountryId": "EC",
                                    "Location": "-77.5, -2.383333"
                                }
                            ]
                        },
                        {
                            "Id": "ETRA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.966667, -3.45",
                            "IataCode": "ETR",
                            "Airports": [
                                {
                                    "Id": "ETR",
                                    "Name": "Santa Rosa",
                                    "CityId": "ETRA",
                                    "CountryId": "EC",
                                    "Location": "-79.96167, -3.45222"
                                }
                            ]
                        },
                        {
                            "Id": "LTXA",
                            "Name": "Latacunga",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-78.616667, -0.9333333",
                            "IataCode": "LTX",
                            "Airports": [
                                {
                                    "Id": "LTX",
                                    "Name": "Latacunga",
                                    "CityId": "LTXA",
                                    "CountryId": "EC",
                                    "Location": "-78.6025, -0.901111"
                                }
                            ]
                        },
                        {
                            "Id": "ESMA",
                            "Name": "Esmeraldas",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-79.7, 0.9833333",
                            "IataCode": "ESM",
                            "Airports": [
                                {
                                    "Id": "ESM",
                                    "Name": "Esmeraldas",
                                    "CityId": "ESMA",
                                    "CountryId": "EC",
                                    "Location": "-79.625, 0.966667"
                                }
                            ]
                        },
                        {
                            "Id": "GPSA",
                            "Name": "Galapagos Is",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-90.562649, -0.29350782",
                            "IataCode": "GPS",
                            "Airports": [
                                {
                                    "Id": "GPS",
                                    "Name": "Galapagos Is",
                                    "CityId": "GPSA",
                                    "CountryId": "EC",
                                    "Location": "-90.282778, -0.434722"
                                }
                            ]
                        },
                        {
                            "Id": "IBBA",
                            "Name": "Albemarle Island",
                            "SingleAirportCity": true,
                            "CountryId": "EC",
                            "Location": "-90.953003, -0.942628",
                            "IataCode": "IBB",
                            "Airports": [
                                {
                                    "Id": "IBB",
                                    "Name": "General Villamil",
                                    "CityId": "IBBA",
                                    "CountryId": "EC",
                                    "Location": "-90.953003, -0.942628"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CL",
                    "Name": "Chile",
                    "CurrencyId": "CLP",
                    "LanguageId": "ES",
                    "Cities": [
                        {
                            "Id": "WPAA",
                            "Name": "Puerto Aisen",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.7, -45.4",
                            "IataCode": "WPA",
                            "Airports": [
                                {
                                    "Id": "WPA",
                                    "Name": "Puerto Aisen",
                                    "CityId": "WPAA",
                                    "CountryId": "CL",
                                    "Location": "-72.7, -45.4"
                                }
                            ]
                        },
                        {
                            "Id": "YAIA",
                            "Name": "Chillan",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.116667, -36.6",
                            "IataCode": "YAI",
                            "Airports": [
                                {
                                    "Id": "YAI",
                                    "Name": "Chillan",
                                    "CityId": "YAIA",
                                    "CountryId": "CL",
                                    "Location": "-72.116667, -36.6"
                                }
                            ]
                        },
                        {
                            "Id": "ANFA",
                            "Name": "Antofagasta",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.4, -23.65",
                            "IataCode": "ANF",
                            "Airports": [
                                {
                                    "Id": "ANF",
                                    "Name": "Antofagasta",
                                    "CityId": "ANFA",
                                    "CountryId": "CL",
                                    "Location": "-70.442778, -23.439444"
                                }
                            ]
                        },
                        {
                            "Id": "ZALA",
                            "Name": "Valdivia",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-73.245893, -39.814223",
                            "IataCode": "ZAL",
                            "Airports": [
                                {
                                    "Id": "ZAL",
                                    "Name": "Valdivia",
                                    "CityId": "ZALA",
                                    "CountryId": "CL",
                                    "Location": "-73.086111, -39.649444"
                                }
                            ]
                        },
                        {
                            "Id": "ZCOA",
                            "Name": "Temuco",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.6, -38.733335",
                            "IataCode": "ZCO",
                            "Airports": [
                                {
                                    "Id": "ZCO",
                                    "Name": "Temuco",
                                    "CityId": "ZCOA",
                                    "CountryId": "CL",
                                    "Location": "-72.6372, -38.7669"
                                }
                            ]
                        },
                        {
                            "Id": "ZOSA",
                            "Name": "Osorno",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-73.150001, -40.566667",
                            "IataCode": "ZOS",
                            "Airports": [
                                {
                                    "Id": "ZOS",
                                    "Name": "Osorno",
                                    "CityId": "ZOSA",
                                    "CountryId": "CL",
                                    "Location": "-73.055556, -40.607778"
                                }
                            ]
                        },
                        {
                            "Id": "ZPCA",
                            "Name": "Pucon",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.974869, -39.274923",
                            "IataCode": "ZPC",
                            "Airports": [
                                {
                                    "Id": "ZPC",
                                    "Name": "Pucon",
                                    "CityId": "ZPCA",
                                    "CountryId": "CL",
                                    "Location": "-71.974444, -39.276667"
                                }
                            ]
                        },
                        {
                            "Id": "CNRA",
                            "Name": "Chanaral",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.616667, -26.35",
                            "IataCode": "CNR",
                            "Airports": [
                                {
                                    "Id": "CNR",
                                    "Name": "Chanaral",
                                    "CityId": "CNRA",
                                    "CountryId": "CL",
                                    "Location": "-70.604167, -26.329167"
                                }
                            ]
                        },
                        {
                            "Id": "LGRA",
                            "Name": "Cochrane",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.569504, -47.255699",
                            "IataCode": "LGR",
                            "Airports": [
                                {
                                    "Id": "LGR",
                                    "Name": "Cochrane",
                                    "CityId": "LGRA",
                                    "CountryId": "CL",
                                    "Location": "-72, -47.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CCPA",
                            "Name": "Concepcion",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-73.04977, -36.826996",
                            "IataCode": "CCP",
                            "Airports": [
                                {
                                    "Id": "CCP",
                                    "Name": "Concepcion",
                                    "CityId": "CCPA",
                                    "CountryId": "CL",
                                    "Location": "-73.06, -36.768333"
                                }
                            ]
                        },
                        {
                            "Id": "CJCA",
                            "Name": "Calama",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-68.933333, -22.466667",
                            "IataCode": "CJC",
                            "Airports": [
                                {
                                    "Id": "CJC",
                                    "Name": "Calama",
                                    "CityId": "CJCA",
                                    "CountryId": "CL",
                                    "Location": "-68.916667, -22.487222"
                                }
                            ]
                        },
                        {
                            "Id": "CPOA",
                            "Name": "Copiapo",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.333333, -27.366667",
                            "IataCode": "CPO",
                            "Airports": [
                                {
                                    "Id": "CPO",
                                    "Name": "Copiapo",
                                    "CityId": "CPOA",
                                    "CountryId": "CL",
                                    "Location": "-70.536111, -27.416667"
                                }
                            ]
                        },
                        {
                            "Id": "FRTA",
                            "Name": "Frutillar",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-73.043718, -41.126759",
                            "IataCode": "FRT",
                            "Airports": [
                                {
                                    "Id": "FRT",
                                    "Name": "Frutillar",
                                    "CityId": "FRTA",
                                    "CountryId": "CL",
                                    "Location": "-73.065278, -41.125"
                                }
                            ]
                        },
                        {
                            "Id": "GXQA",
                            "Name": "Coyhaique",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.066193, -45.57524",
                            "IataCode": "GXQ",
                            "Airports": [
                                {
                                    "Id": "GXQ",
                                    "Name": "Coyhaique",
                                    "CityId": "GXQA",
                                    "CountryId": "CL",
                                    "Location": "-72.098611, -45.588889"
                                }
                            ]
                        },
                        {
                            "Id": "IQQA",
                            "Name": "Iquique",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.166667, -20.216667",
                            "IataCode": "IQQ",
                            "Airports": [
                                {
                                    "Id": "IQQ",
                                    "Name": "Iquique",
                                    "CityId": "IQQA",
                                    "CountryId": "CL",
                                    "Location": "-70.181389, -20.535278"
                                }
                            ]
                        },
                        {
                            "Id": "KNAA",
                            "Name": "Vina del Mar",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.519722, -33.008057",
                            "IataCode": "KNA",
                            "Airports": [
                                {
                                    "Id": "KNA",
                                    "Name": "Vina del Mar",
                                    "CityId": "KNAA",
                                    "CountryId": "CL",
                                    "Location": "-71.566667, -33.033333"
                                }
                            ]
                        },
                        {
                            "Id": "LSCA",
                            "Name": "La Serena",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.254167, -29.907778",
                            "IataCode": "LSC",
                            "Airports": [
                                {
                                    "Id": "LSC",
                                    "Name": "La Serena",
                                    "CityId": "LSCA",
                                    "CountryId": "CL",
                                    "Location": "-71.197778, -29.913333"
                                }
                            ]
                        },
                        {
                            "Id": "LSQA",
                            "Name": "Los Angeles",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.35, -37.466668",
                            "IataCode": "LSQ",
                            "Airports": [
                                {
                                    "Id": "LSQ",
                                    "Name": "Los Angeles",
                                    "CityId": "LSQA",
                                    "CountryId": "CL",
                                    "Location": "-72.422778, -37.3975"
                                }
                            ]
                        },
                        {
                            "Id": "OVLA",
                            "Name": "Ovalle",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.200278, -30.598333",
                            "IataCode": "OVL",
                            "Airports": [
                                {
                                    "Id": "OVL",
                                    "Name": "Ovalle",
                                    "CityId": "OVLA",
                                    "CountryId": "CL",
                                    "Location": "-71, -30.566667"
                                }
                            ]
                        },
                        {
                            "Id": "PMCA",
                            "Name": "Puerto Montt",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.936945, -41.471667",
                            "IataCode": "PMC",
                            "Airports": [
                                {
                                    "Id": "PMC",
                                    "Name": "Puerto Montt",
                                    "CityId": "PMCA",
                                    "CountryId": "CL",
                                    "Location": "-73.1, -41.433333"
                                }
                            ]
                        },
                        {
                            "Id": "PNTA",
                            "Name": "Puerto Natales",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.48745, -51.723626",
                            "IataCode": "PNT",
                            "Airports": [
                                {
                                    "Id": "PNT",
                                    "Name": "Puerto Natales",
                                    "CityId": "PNTA",
                                    "CountryId": "CL",
                                    "Location": "-72.516667, -51.683333"
                                }
                            ]
                        },
                        {
                            "Id": "PUQA",
                            "Name": "Punta Arenas",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.916667, -53.150002",
                            "IataCode": "PUQ",
                            "Airports": [
                                {
                                    "Id": "PUQ",
                                    "Name": "Punta Arenas",
                                    "CityId": "PUQA",
                                    "CountryId": "CL",
                                    "Location": "-70.854722, -53.002778"
                                }
                            ]
                        },
                        {
                            "Id": "SCLA",
                            "Name": "Santiago",
                            "SingleAirportCity": false,
                            "CountryId": "CL",
                            "Location": "-70.666664, -33.450013",
                            "IataCode": "SCL",
                            "Airports": [
                                {
                                    "Id": "SCL",
                                    "Name": "Santiago Arturo Merino Benitez",
                                    "CityId": "SCLA",
                                    "CountryId": "CL",
                                    "Location": "-70.7858, -33.39298"
                                },
                                {
                                    "Id": "ULC",
                                    "Name": "Santiago Los Cerrillos",
                                    "CityId": "SCLA",
                                    "CountryId": "CL",
                                    "Location": "-70.694722, -33.489722"
                                }
                            ]
                        },
                        {
                            "Id": "WCAA",
                            "Name": "Castro",
                            "SingleAirportCity": false,
                            "CountryId": "CL",
                            "Location": "-73.773193, -42.472097",
                            "IataCode": "WCA",
                            "Airports": [
                                {
                                    "Id": "WCA",
                                    "Name": "Castro",
                                    "CityId": "WCAA",
                                    "CountryId": "CL",
                                    "Location": "-73.833333, -42.5"
                                },
                                {
                                    "Id": "MHC",
                                    "Name": "Mocopulli",
                                    "CityId": "WCAA",
                                    "CountryId": "CL",
                                    "Location": "-73.715667, -42.340389"
                                }
                            ]
                        },
                        {
                            "Id": "WCHA",
                            "Name": "Chaiten",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.706318, -42.915955",
                            "IataCode": "WCH",
                            "Airports": [
                                {
                                    "Id": "WCH",
                                    "Name": "Chaiten",
                                    "CityId": "WCHA",
                                    "CountryId": "CL",
                                    "Location": "-72.716667, -42.916667"
                                }
                            ]
                        },
                        {
                            "Id": "ARIA",
                            "Name": "Arica",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.333333, -18.483333",
                            "IataCode": "ARI",
                            "Airports": [
                                {
                                    "Id": "ARI",
                                    "Name": "Arica",
                                    "CityId": "ARIA",
                                    "CountryId": "CL",
                                    "Location": "-70.33874, -18.34853"
                                }
                            ]
                        },
                        {
                            "Id": "PUXA",
                            "Name": "Puerto Varas",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-72.983333, -41.316667",
                            "IataCode": "PUX",
                            "Airports": [
                                {
                                    "Id": "PUX",
                                    "Name": "Puerto Varas",
                                    "CityId": "PUXA",
                                    "CountryId": "CL",
                                    "Location": "-72.944444, -40.340278"
                                }
                            ]
                        },
                        {
                            "Id": "TLXA",
                            "Name": "Talca",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.666666, -35.433333",
                            "IataCode": "TLX",
                            "Airports": [
                                {
                                    "Id": "TLX",
                                    "Name": "Talca",
                                    "CityId": "TLXA",
                                    "CountryId": "CL",
                                    "Location": "-71.666667, -35.466667"
                                }
                            ]
                        },
                        {
                            "Id": "TOQA",
                            "Name": "Tocopilla",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.2, -22.083333",
                            "IataCode": "TOQ",
                            "Airports": [
                                {
                                    "Id": "TOQ",
                                    "Name": "Tocopilla",
                                    "CityId": "TOQA",
                                    "CountryId": "CL",
                                    "Location": "-70.061389, -22.136111"
                                }
                            ]
                        },
                        {
                            "Id": "TTCA",
                            "Name": "Taltal",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.483333, -25.4",
                            "IataCode": "TTC",
                            "Airports": [
                                {
                                    "Id": "TTC",
                                    "Name": "Taltal",
                                    "CityId": "TTCA",
                                    "CountryId": "CL",
                                    "Location": "-70.422222, -25.517778"
                                }
                            ]
                        },
                        {
                            "Id": "WPRA",
                            "Name": "Porvenir",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.366667, -53.3",
                            "IataCode": "WPR",
                            "Airports": [
                                {
                                    "Id": "WPR",
                                    "Name": "Porvenir",
                                    "CityId": "WPRA",
                                    "CountryId": "CL",
                                    "Location": "-70.333333, -53.25"
                                }
                            ]
                        },
                        {
                            "Id": "WPUA",
                            "Name": "Puerto Williams",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-67.616667, -54.933333",
                            "IataCode": "WPU",
                            "Airports": [
                                {
                                    "Id": "WPU",
                                    "Name": "Puerto Williams",
                                    "CityId": "WPUA",
                                    "CountryId": "CL",
                                    "Location": "-67.626389, -54.931111"
                                }
                            ]
                        },
                        {
                            "Id": "VLRA",
                            "Name": "Vallenar",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.758056, -28.570833",
                            "IataCode": "VLR",
                            "Airports": [
                                {
                                    "Id": "VLR",
                                    "Name": "Vallenar",
                                    "CityId": "VLRA",
                                    "CountryId": "CL",
                                    "Location": "-70.761667, -28.591667"
                                }
                            ]
                        },
                        {
                            "Id": "WAPA",
                            "Name": "Alto Palena",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.803889, -43.623056",
                            "IataCode": "WAP",
                            "Airports": [
                                {
                                    "Id": "WAP",
                                    "Name": "Alto Palena",
                                    "CityId": "WAPA",
                                    "CountryId": "CL",
                                    "Location": "-71.8, -43.616667"
                                }
                            ]
                        },
                        {
                            "Id": "VAPA",
                            "Name": "Valparaiso",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.601111, -33.047779",
                            "IataCode": "VAP",
                            "Airports": [
                                {
                                    "Id": "VAP",
                                    "Name": "Valparaiso",
                                    "CityId": "VAPA",
                                    "CountryId": "CL",
                                    "Location": "-71.666667, -33.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ESRA",
                            "Name": "El Salvador",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-69.616667, -26.25",
                            "IataCode": "ESR",
                            "Airports": [
                                {
                                    "Id": "ESR",
                                    "Name": "El Salvador",
                                    "CityId": "ESRA",
                                    "CountryId": "CL",
                                    "Location": "-69.765278, -26.315278"
                                }
                            ]
                        },
                        {
                            "Id": "FFUA",
                            "Name": "Futaleufu",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.848333, -43.1675",
                            "IataCode": "FFU",
                            "Airports": [
                                {
                                    "Id": "FFU",
                                    "Name": "Futaleufu",
                                    "CityId": "FFUA",
                                    "CountryId": "CL",
                                    "Location": "-71.816667, -43.2"
                                }
                            ]
                        },
                        {
                            "Id": "COWA",
                            "Name": "Coquimbo",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-70.882854, -30.601627",
                            "IataCode": "COW",
                            "Airports": [
                                {
                                    "Id": "COW",
                                    "Name": "Coquimbo",
                                    "CityId": "COWA",
                                    "CountryId": "CL",
                                    "Location": "-70.633333, -32.8"
                                }
                            ]
                        },
                        {
                            "Id": "CCHA",
                            "Name": "Chile Chico",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.733333, -46.55",
                            "IataCode": "CCH",
                            "Airports": [
                                {
                                    "Id": "CCH",
                                    "Name": "Chile Chico",
                                    "CityId": "CCHA",
                                    "CountryId": "CL",
                                    "Location": "-71.7, -46.55"
                                }
                            ]
                        },
                        {
                            "Id": "BBAA",
                            "Name": "Balmaceda",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.683333, -45.916667",
                            "IataCode": "BBA",
                            "Airports": [
                                {
                                    "Id": "BBA",
                                    "Name": "Balmaceda",
                                    "CityId": "BBAA",
                                    "CountryId": "CL",
                                    "Location": "-71.695, -45.916667"
                                }
                            ]
                        },
                        {
                            "Id": "IPCA",
                            "Name": "Easter Island",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-109.36642, -27.12119",
                            "IataCode": "IPC",
                            "Airports": [
                                {
                                    "Id": "IPC",
                                    "Name": "Easter Island",
                                    "CityId": "IPCA",
                                    "CountryId": "CL",
                                    "Location": "-109.42962, -27.160025"
                                }
                            ]
                        },
                        {
                            "Id": "DPBC",
                            "Name": "San Justo",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-68.533333, -54.033333",
                            "IataCode": "DPB",
                            "Airports": [
                                {
                                    "Id": "DPB",
                                    "Name": "Pampa Guanaco",
                                    "CityId": "DPBC",
                                    "CountryId": "CL",
                                    "Location": "-68.808806, -54.049976"
                                }
                            ]
                        },
                        {
                            "Id": "SMBA",
                            "Name": "Reserva Fiscal",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-69.35, -52.65",
                            "IataCode": "SMB",
                            "Airports": [
                                {
                                    "Id": "SMB",
                                    "Name": "Cerro Sombrero",
                                    "CityId": "SMBA",
                                    "CountryId": "CL",
                                    "Location": "-69.342778, -52.738333"
                                }
                            ]
                        },
                        {
                            "Id": "LOBA",
                            "Name": "Panul",
                            "SingleAirportCity": true,
                            "CountryId": "CL",
                            "Location": "-71.4, -30.016667",
                            "IataCode": "LOB",
                            "Airports": [
                                {
                                    "Id": "LOB",
                                    "Name": "Los Andes",
                                    "CityId": "LOBA",
                                    "CountryId": "CL",
                                    "Location": "-71.533333, -29.966667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BO",
                    "Name": "Bolivia",
                    "CurrencyId": "BOB",
                    "Cities": [
                        {
                            "Id": "CAMA",
                            "Name": "Camiri",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.516667, -20.05",
                            "IataCode": "CAM",
                            "Airports": [
                                {
                                    "Id": "CAM",
                                    "Name": "Camiri",
                                    "CityId": "CAMA",
                                    "CountryId": "BO",
                                    "Location": "-63.561111, -20.011111"
                                }
                            ]
                        },
                        {
                            "Id": "BVKA",
                            "Name": "Huacaraje",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.75, -13.55",
                            "IataCode": "BVK",
                            "Airports": [
                                {
                                    "Id": "BVK",
                                    "Name": "Huacaraje",
                                    "CityId": "BVKA",
                                    "CountryId": "BO",
                                    "Location": "-63.75, -13.55"
                                }
                            ]
                        },
                        {
                            "Id": "BVLA",
                            "Name": "Baures",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.583333, -13.583333",
                            "IataCode": "BVL",
                            "Airports": [
                                {
                                    "Id": "BVL",
                                    "Name": "Baures",
                                    "CityId": "BVLA",
                                    "CountryId": "BO",
                                    "Location": "-63.583333, -13.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BYCA",
                            "Name": "Yacuiba",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.683333, -22.033333",
                            "IataCode": "BYC",
                            "Airports": [
                                {
                                    "Id": "BYC",
                                    "Name": "Yacuiba",
                                    "CityId": "BYCA",
                                    "CountryId": "BO",
                                    "Location": "-63.65, -21.95"
                                }
                            ]
                        },
                        {
                            "Id": "CBBA",
                            "Name": "Cochabamba",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-66.15, -17.383333",
                            "IataCode": "CBB",
                            "Airports": [
                                {
                                    "Id": "CBB",
                                    "Name": "Cochabamba",
                                    "CityId": "CBBA",
                                    "CountryId": "BO",
                                    "Location": "-66.171667, -17.428333"
                                }
                            ]
                        },
                        {
                            "Id": "CEPA",
                            "Name": "Concepcion",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-62.033333, -16.133333",
                            "IataCode": "CEP",
                            "Airports": [
                                {
                                    "Id": "CEP",
                                    "Name": "Concepcion",
                                    "CityId": "CEPA",
                                    "CountryId": "BO",
                                    "Location": "-62.083333, -16.233333"
                                }
                            ]
                        },
                        {
                            "Id": "LPBA",
                            "Name": "La Paz",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-68.15, -16.5",
                            "IataCode": "LPB",
                            "Airports": [
                                {
                                    "Id": "LPB",
                                    "Name": "La Paz",
                                    "CityId": "LPBA",
                                    "CountryId": "BO",
                                    "Location": "-68.19226, -16.51334"
                                }
                            ]
                        },
                        {
                            "Id": "ORUA",
                            "Name": "Oruro",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-67.15, -17.983333",
                            "IataCode": "ORU",
                            "Airports": [
                                {
                                    "Id": "ORU",
                                    "Name": "Oruro",
                                    "CityId": "ORUA",
                                    "CountryId": "BO",
                                    "Location": "-67.080833, -17.964167"
                                }
                            ]
                        },
                        {
                            "Id": "PSZA",
                            "Name": "Puerto Suarez",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-57.8, -18.95",
                            "IataCode": "PSZ",
                            "Airports": [
                                {
                                    "Id": "PSZ",
                                    "Name": "Puerto Suarez",
                                    "CityId": "PSZA",
                                    "CountryId": "BO",
                                    "Location": "-57.82059, -18.97528"
                                }
                            ]
                        },
                        {
                            "Id": "RBQA",
                            "Name": "Rurrenabaque",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-67.527809, -14.441247",
                            "IataCode": "RBQ",
                            "Airports": [
                                {
                                    "Id": "RBQ",
                                    "Name": "Rurrenabaque",
                                    "CityId": "RBQA",
                                    "CountryId": "BO",
                                    "Location": "-67.55, -14.45"
                                }
                            ]
                        },
                        {
                            "Id": "RIBA",
                            "Name": "Riberalta",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-66.1, -10.983333",
                            "IataCode": "RIB",
                            "Airports": [
                                {
                                    "Id": "RIB",
                                    "Name": "Riberalta",
                                    "CityId": "RIBA",
                                    "CountryId": "BO",
                                    "Location": "-66.093056, -11.006944"
                                }
                            ]
                        },
                        {
                            "Id": "SBLA",
                            "Name": "Santa Ana",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.433333, -13.75",
                            "IataCode": "SBL",
                            "Airports": [
                                {
                                    "Id": "SBL",
                                    "Name": "Santa Ana",
                                    "CityId": "SBLA",
                                    "CountryId": "BO",
                                    "Location": "-65.45, -13.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SREA",
                            "Name": "Sucre",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.259166, -19.043056",
                            "IataCode": "SRE",
                            "Airports": [
                                {
                                    "Id": "SRE",
                                    "Name": "Sucre",
                                    "CityId": "SREA",
                                    "CountryId": "BO",
                                    "Location": "-65.28875, -19.00708"
                                }
                            ]
                        },
                        {
                            "Id": "SRJA",
                            "Name": "San Borja",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-66.85, -14.816667",
                            "IataCode": "SRJ",
                            "Airports": [
                                {
                                    "Id": "SRJ",
                                    "Name": "San Borja",
                                    "CityId": "SRJA",
                                    "CountryId": "BO",
                                    "Location": "-66.833333, -14.816667"
                                }
                            ]
                        },
                        {
                            "Id": "SRZA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": false,
                            "CountryId": "BO",
                            "Location": "-63.166667, -17.800001",
                            "IataCode": "SRZ",
                            "Airports": [
                                {
                                    "Id": "VVI",
                                    "Name": "Santa Cruz Viru Viru",
                                    "CityId": "SRZA",
                                    "CountryId": "BO",
                                    "Location": "-63.13536, -17.64476"
                                },
                                {
                                    "Id": "SRZ",
                                    "Name": "Santa Cruz El Trompillo",
                                    "CityId": "SRZA",
                                    "CountryId": "BO",
                                    "Location": "-63.17148, -17.81158"
                                }
                            ]
                        },
                        {
                            "Id": "TDDA",
                            "Name": "Trinidad",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.9, -14.833333",
                            "IataCode": "TDD",
                            "Airports": [
                                {
                                    "Id": "TDD",
                                    "Name": "Trinidad",
                                    "CityId": "TDDA",
                                    "CountryId": "BO",
                                    "Location": "-64.833333, -14.683333"
                                }
                            ]
                        },
                        {
                            "Id": "TJAA",
                            "Name": "Tarija",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.729557, -21.535486",
                            "IataCode": "TJA",
                            "Airports": [
                                {
                                    "Id": "TJA",
                                    "Name": "Tarija",
                                    "CityId": "TJAA",
                                    "CountryId": "BO",
                                    "Location": "-64.701389, -21.555833"
                                }
                            ]
                        },
                        {
                            "Id": "APBA",
                            "Name": "Apolo",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-68.416667, -14.716667",
                            "IataCode": "APB",
                            "Airports": [
                                {
                                    "Id": "APB",
                                    "Name": "Apolo",
                                    "CityId": "APBA",
                                    "CountryId": "BO",
                                    "Location": "-68.516667, -14.716667"
                                }
                            ]
                        },
                        {
                            "Id": "POIA",
                            "Name": "Potosi",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.753055, -19.583611",
                            "IataCode": "POI",
                            "Airports": [
                                {
                                    "Id": "POI",
                                    "Name": "Potosi",
                                    "CityId": "POIA",
                                    "CountryId": "BO",
                                    "Location": "-65.716667, -19.533333"
                                }
                            ]
                        },
                        {
                            "Id": "SNGA",
                            "Name": "San Ignacio De Velasco",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-60.95, -16.366667",
                            "IataCode": "SNG",
                            "Airports": [
                                {
                                    "Id": "SNG",
                                    "Name": "San Ignacio De Velasco",
                                    "CityId": "SNGA",
                                    "CountryId": "BO",
                                    "Location": "-60.976389, -16.383056"
                                }
                            ]
                        },
                        {
                            "Id": "VAHA",
                            "Name": "Vallegrande",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.106944, -18.489722",
                            "IataCode": "VAH",
                            "Airports": [
                                {
                                    "Id": "VAH",
                                    "Name": "Vallegrande",
                                    "CityId": "VAHA",
                                    "CountryId": "BO",
                                    "Location": "-64.1, -18.483333"
                                }
                            ]
                        },
                        {
                            "Id": "VLMA",
                            "Name": "Villamontes",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.5, -21.25",
                            "IataCode": "VLM",
                            "Airports": [
                                {
                                    "Id": "VLM",
                                    "Name": "Villamontes",
                                    "CityId": "VLMA",
                                    "CountryId": "BO",
                                    "Location": "-63.5, -21.25"
                                }
                            ]
                        },
                        {
                            "Id": "MGDA",
                            "Name": "Magdalena",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.2, -13.233333",
                            "IataCode": "MGD",
                            "Airports": [
                                {
                                    "Id": "MGD",
                                    "Name": "Magdalena",
                                    "CityId": "MGDA",
                                    "CountryId": "BO",
                                    "Location": "-64.124722, -13.325278"
                                }
                            ]
                        },
                        {
                            "Id": "PURA",
                            "Name": "Puerto Rico",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-67.533333, -11.05",
                            "IataCode": "PUR",
                            "Airports": [
                                {
                                    "Id": "PUR",
                                    "Name": "Puerto Rico",
                                    "CityId": "PURA",
                                    "CountryId": "BO",
                                    "Location": "-67.52, -11.1125"
                                }
                            ]
                        },
                        {
                            "Id": "RBOA",
                            "Name": "Robore",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-59.75, -18.333333",
                            "IataCode": "RBO",
                            "Airports": [
                                {
                                    "Id": "RBO",
                                    "Name": "Robore",
                                    "CityId": "RBOA",
                                    "CountryId": "BO",
                                    "Location": "-59.755278, -18.344444"
                                }
                            ]
                        },
                        {
                            "Id": "REYA",
                            "Name": "Reyes",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-67.383333, -14.316667",
                            "IataCode": "REY",
                            "Airports": [
                                {
                                    "Id": "REY",
                                    "Name": "Reyes",
                                    "CityId": "REYA",
                                    "CountryId": "BO",
                                    "Location": "-67.266667, -14.316667"
                                }
                            ]
                        },
                        {
                            "Id": "SJBA",
                            "Name": "San Joaquin",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.816667, -13.066667",
                            "IataCode": "SJB",
                            "Airports": [
                                {
                                    "Id": "SJB",
                                    "Name": "San Joaquin",
                                    "CityId": "SJBA",
                                    "CountryId": "BO",
                                    "Location": "-64.8, -13.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SJSA",
                            "Name": "San Jose",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-60.75, -17.85",
                            "IataCode": "SJS",
                            "Airports": [
                                {
                                    "Id": "SJS",
                                    "Name": "San Jose de Chiquitos",
                                    "CityId": "SJSA",
                                    "CountryId": "BO",
                                    "Location": "-60.738333, -17.827222"
                                }
                            ]
                        },
                        {
                            "Id": "SJVA",
                            "Name": "San Javier",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-62.633333, -16.333333",
                            "IataCode": "SJV",
                            "Airports": [
                                {
                                    "Id": "SJV",
                                    "Name": "San Javier",
                                    "CityId": "SJVA",
                                    "CountryId": "BO",
                                    "Location": "-62.416667, -16.233333"
                                }
                            ]
                        },
                        {
                            "Id": "SRDA",
                            "Name": "San Ramon",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.716667, -13.283333",
                            "IataCode": "SRD",
                            "Airports": [
                                {
                                    "Id": "SRD",
                                    "Name": "San Ramon",
                                    "CityId": "SRDA",
                                    "CountryId": "BO",
                                    "Location": "-64.716667, -13.283333"
                                }
                            ]
                        },
                        {
                            "Id": "UYUA",
                            "Name": "Uyuni",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-66.833333, -20.466667",
                            "IataCode": "UYU",
                            "Airports": [
                                {
                                    "Id": "UYU",
                                    "Name": "Uyuni",
                                    "CityId": "UYUA",
                                    "CountryId": "BO",
                                    "Location": "-66.8333, -20.4667"
                                }
                            ]
                        },
                        {
                            "Id": "MQKA",
                            "Name": "San Matias",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-58.4, -16.366667",
                            "IataCode": "MQK",
                            "Airports": [
                                {
                                    "Id": "MQK",
                                    "Name": "San Matias",
                                    "CityId": "MQKA",
                                    "CountryId": "BO",
                                    "Location": "-58.385556, -16.334444"
                                }
                            ]
                        },
                        {
                            "Id": "SRBA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-66.75, -10.733333",
                            "IataCode": "SRB",
                            "Airports": [
                                {
                                    "Id": "SRB",
                                    "Name": "Santa Rosa",
                                    "CityId": "SRBA",
                                    "CountryId": "BO",
                                    "Location": "-67.333333, -10.6"
                                }
                            ]
                        },
                        {
                            "Id": "SNMA",
                            "Name": "San Ignacio De M",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.65, -15",
                            "IataCode": "SNM",
                            "Airports": [
                                {
                                    "Id": "SNM",
                                    "Name": "San Ignacio De M",
                                    "CityId": "SNMA",
                                    "CountryId": "BO",
                                    "Location": "-65.635556, -14.966389"
                                }
                            ]
                        },
                        {
                            "Id": "MHWA",
                            "Name": "Monteagudo",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.983333, -19.816667",
                            "IataCode": "MHW",
                            "Airports": [
                                {
                                    "Id": "MHW",
                                    "Name": "Monteagudo",
                                    "CityId": "MHWA",
                                    "CountryId": "BO",
                                    "Location": "-63.966667, -19.8325"
                                }
                            ]
                        },
                        {
                            "Id": "GYAA",
                            "Name": "Guayaramerin",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.383333, -10.8",
                            "IataCode": "GYA",
                            "Airports": [
                                {
                                    "Id": "GYA",
                                    "Name": "Guayaramerin",
                                    "CityId": "GYAA",
                                    "CountryId": "BO",
                                    "Location": "-65.35, -10.81667"
                                }
                            ]
                        },
                        {
                            "Id": "CIJA",
                            "Name": "Cobija",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-68.733333, -11.033333",
                            "IataCode": "CIJ",
                            "Airports": [
                                {
                                    "Id": "CIJ",
                                    "Name": "Cobija",
                                    "CityId": "CIJA",
                                    "CountryId": "BO",
                                    "Location": "-68.782778, -11.040278"
                                }
                            ]
                        },
                        {
                            "Id": "CCAB",
                            "Name": "Chimore",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-65.133333, -16.983333",
                            "IataCode": "CCA",
                            "Airports": [
                                {
                                    "Id": "CCA",
                                    "Name": "Chimore",
                                    "CityId": "CCAB",
                                    "CountryId": "BO",
                                    "Location": "-65.1415, -16.98975"
                                }
                            ]
                        },
                        {
                            "Id": "BJOA",
                            "Name": "Bermejo",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-64.366667, -22.65",
                            "IataCode": "BJO",
                            "Airports": [
                                {
                                    "Id": "BJO",
                                    "Name": "Bermejo",
                                    "CityId": "BJOA",
                                    "CountryId": "BO",
                                    "Location": "-64.333333, -22.866667"
                                }
                            ]
                        },
                        {
                            "Id": "ASCA",
                            "Name": "Ascension",
                            "SingleAirportCity": true,
                            "CountryId": "BO",
                            "Location": "-63.083333, -15.7",
                            "IataCode": "ASC",
                            "Airports": [
                                {
                                    "Id": "ASC",
                                    "Name": "Ascension",
                                    "CityId": "ASCA",
                                    "CountryId": "BO",
                                    "Location": "-63.15, -15.916667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UY",
                    "Name": "Uruguay",
                    "CurrencyId": "UYU",
                    "Cities": [
                        {
                            "Id": "ATIA",
                            "Name": "Artigas",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-56.466667, -30.4",
                            "IataCode": "ATI",
                            "Airports": [
                                {
                                    "Id": "ATI",
                                    "Name": "Artigas",
                                    "CityId": "ATIA",
                                    "CountryId": "UY",
                                    "Location": "-56.508333, -30.4"
                                }
                            ]
                        },
                        {
                            "Id": "BUVA",
                            "Name": "Bella Union",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-57.583333, -30.25",
                            "IataCode": "BUV",
                            "Airports": [
                                {
                                    "Id": "BUV",
                                    "Name": "Bella Union",
                                    "CityId": "BUVA",
                                    "CountryId": "UY",
                                    "Location": "-57.083333, -30.333333"
                                }
                            ]
                        },
                        {
                            "Id": "DZOA",
                            "Name": "Durazno",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-56.500556, -33.413056",
                            "IataCode": "DZO",
                            "Airports": [
                                {
                                    "Id": "DZO",
                                    "Name": "Durazno",
                                    "CityId": "DZOA",
                                    "CountryId": "UY",
                                    "Location": "-56.516667, -33.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MVDA",
                            "Name": "Montevideo",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-56.170834, -34.858058",
                            "IataCode": "MVD",
                            "Airports": [
                                {
                                    "Id": "MVD",
                                    "Name": "Montevideo",
                                    "CityId": "MVDA",
                                    "CountryId": "UY",
                                    "Location": "-56.033333, -34.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MLZA",
                            "Name": "Melo",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-54.183333, -32.366667",
                            "IataCode": "MLZ",
                            "Airports": [
                                {
                                    "Id": "MLZ",
                                    "Name": "Melo",
                                    "CityId": "MLZA",
                                    "CountryId": "UY",
                                    "Location": "-54.2, -32.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PDUA",
                            "Name": "Paysandu",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-58.075555, -32.321389",
                            "IataCode": "PDU",
                            "Airports": [
                                {
                                    "Id": "PDU",
                                    "Name": "Paysandu",
                                    "CityId": "PDUA",
                                    "CountryId": "UY",
                                    "Location": "-58.064167, -32.365833"
                                }
                            ]
                        },
                        {
                            "Id": "RVYA",
                            "Name": "Rivera",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-55.516667, -30.9",
                            "IataCode": "RVY",
                            "Airports": [
                                {
                                    "Id": "RVY",
                                    "Name": "Rivera",
                                    "CityId": "RVYA",
                                    "CountryId": "UY",
                                    "Location": "-55.479167, -30.970833"
                                }
                            ]
                        },
                        {
                            "Id": "STYA",
                            "Name": "Salto",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-57.966667, -31.383333",
                            "IataCode": "STY",
                            "Airports": [
                                {
                                    "Id": "STY",
                                    "Name": "Salto",
                                    "CityId": "STYA",
                                    "CountryId": "UY",
                                    "Location": "-57.988333, -31.436667"
                                }
                            ]
                        },
                        {
                            "Id": "TAWA",
                            "Name": "Tacuarembo",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-55.983333, -31.733333",
                            "IataCode": "TAW",
                            "Airports": [
                                {
                                    "Id": "TAW",
                                    "Name": "Tacuarembo",
                                    "CityId": "TAWA",
                                    "CountryId": "UY",
                                    "Location": "-55.916667, -31.75"
                                }
                            ]
                        },
                        {
                            "Id": "TYTA",
                            "Name": "Treinta-y-Tres",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-54.383333, -33.233333",
                            "IataCode": "TYT",
                            "Airports": [
                                {
                                    "Id": "TYT",
                                    "Name": "Treinta-y-Tres",
                                    "CityId": "TYTA",
                                    "CountryId": "UY",
                                    "Location": "-54.283333, -33.266667"
                                }
                            ]
                        },
                        {
                            "Id": "VCHA",
                            "Name": "Vichadero",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-54.716667, -31.8",
                            "IataCode": "VCH",
                            "Airports": [
                                {
                                    "Id": "VCH",
                                    "Name": "Vichadero",
                                    "CityId": "VCHA",
                                    "CountryId": "UY",
                                    "Location": "-54.617, -31.767"
                                }
                            ]
                        },
                        {
                            "Id": "PDPA",
                            "Name": "Punta Del Este",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-54.95, -34.966667",
                            "IataCode": "PDP",
                            "Airports": [
                                {
                                    "Id": "PDP",
                                    "Name": "Punta Del Este",
                                    "CityId": "PDPA",
                                    "CountryId": "UY",
                                    "Location": "-55.093517, -34.853115"
                                }
                            ]
                        },
                        {
                            "Id": "CYRA",
                            "Name": "Colonia",
                            "SingleAirportCity": true,
                            "CountryId": "UY",
                            "Location": "-57.68537, -34.118989",
                            "IataCode": "CYR",
                            "Airports": [
                                {
                                    "Id": "CYR",
                                    "Name": "Colonia",
                                    "CityId": "CYRA",
                                    "CountryId": "UY",
                                    "Location": "-57.5, -34.166667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BR",
                    "Name": "Brazil",
                    "CurrencyId": "BRL",
                    "Cities": [
                        {
                            "Id": "ALQA",
                            "Name": "Alegrete",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.791945, -29.783056",
                            "IataCode": "ALQ",
                            "Airports": [
                                {
                                    "Id": "ALQ",
                                    "Name": "Alegrete",
                                    "CityId": "ALQA",
                                    "CountryId": "BR",
                                    "Location": "-55.763333, -29.799722"
                                }
                            ]
                        },
                        {
                            "Id": "CCQA",
                            "Name": "Cachoeira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.955833, -12.618333",
                            "IataCode": "CCQ",
                            "Airports": [
                                {
                                    "Id": "CCQ",
                                    "Name": "Cachoeira",
                                    "CityId": "CCQA",
                                    "CountryId": "BR",
                                    "Location": "-38.966667, -12.6"
                                }
                            ]
                        },
                        {
                            "Id": "CZBA",
                            "Name": "Cruz Alta",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.606389, -28.638611",
                            "IataCode": "CZB",
                            "Airports": [
                                {
                                    "Id": "CZB",
                                    "Name": "Cruz Alta",
                                    "CityId": "CZBA",
                                    "CountryId": "BR",
                                    "Location": "-53.558333, -28.641667"
                                }
                            ]
                        },
                        {
                            "Id": "XAPA",
                            "Name": "Chapeco",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.618333, -27.096389",
                            "IataCode": "XAP",
                            "Airports": [
                                {
                                    "Id": "XAP",
                                    "Name": "Chapeco",
                                    "CityId": "XAPA",
                                    "CountryId": "BR",
                                    "Location": "-52.6566, -27.1342"
                                }
                            ]
                        },
                        {
                            "Id": "APUA",
                            "Name": "Apucarana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.460833, -23.550833",
                            "IataCode": "APU",
                            "Airports": [
                                {
                                    "Id": "APU",
                                    "Name": "Apucarana",
                                    "CityId": "APUA",
                                    "CountryId": "BR",
                                    "Location": "-51.483333, -23.55"
                                }
                            ]
                        },
                        {
                            "Id": "APXA",
                            "Name": "Arapongas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.424444, -23.419444",
                            "IataCode": "APX",
                            "Airports": [
                                {
                                    "Id": "APX",
                                    "Name": "Arapongas",
                                    "CityId": "APXA",
                                    "CountryId": "BR",
                                    "Location": "-51.466667, -23.483333"
                                }
                            ]
                        },
                        {
                            "Id": "AQAA",
                            "Name": "Araraquara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.175556, -21.794444",
                            "IataCode": "AQA",
                            "Airports": [
                                {
                                    "Id": "AQA",
                                    "Name": "Araraquara",
                                    "CityId": "AQAA",
                                    "CountryId": "BR",
                                    "Location": "-48.133333, -21.816667"
                                }
                            ]
                        },
                        {
                            "Id": "AQMA",
                            "Name": "Ariquemes",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-63.040833, -9.9133333",
                            "IataCode": "AQM",
                            "Airports": [
                                {
                                    "Id": "AQM",
                                    "Name": "Ariquemes",
                                    "CityId": "AQMA",
                                    "CountryId": "BR",
                                    "Location": "-63.066667, -9.933333"
                                }
                            ]
                        },
                        {
                            "Id": "AAGA",
                            "Name": "Arapoti",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.826667, -24.157778",
                            "IataCode": "AAG",
                            "Airports": [
                                {
                                    "Id": "AAG",
                                    "Name": "Arapoti",
                                    "CityId": "AAGA",
                                    "CountryId": "BR",
                                    "Location": "-49.79, -24.103611"
                                }
                            ]
                        },
                        {
                            "Id": "AAXA",
                            "Name": "Araxa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.940555, -19.593333",
                            "IataCode": "AAX",
                            "Airports": [
                                {
                                    "Id": "AAX",
                                    "Name": "Araxa",
                                    "CityId": "AAXA",
                                    "CountryId": "BR",
                                    "Location": "-46.929167, -19.568056"
                                }
                            ]
                        },
                        {
                            "Id": "AJUA",
                            "Name": "Aracaju",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-37.071667, -10.911111",
                            "IataCode": "AJU",
                            "Airports": [
                                {
                                    "Id": "AJU",
                                    "Name": "Aracaju",
                                    "CityId": "AJUA",
                                    "CountryId": "BR",
                                    "Location": "-37.05, -10.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ITIA",
                            "Name": "Itambacuri",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.685, -18.031111",
                            "IataCode": "ITI",
                            "Airports": [
                                {
                                    "Id": "ITI",
                                    "Name": "Itambacuri",
                                    "CityId": "ITIA",
                                    "CountryId": "BR",
                                    "Location": "-41.5, -18"
                                }
                            ]
                        },
                        {
                            "Id": "JIAA",
                            "Name": "Juina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-58.741111, -11.378333",
                            "IataCode": "JIA",
                            "Airports": [
                                {
                                    "Id": "JIA",
                                    "Name": "Juina",
                                    "CityId": "JIAA",
                                    "CountryId": "BR",
                                    "Location": "-58.883333, -11.5"
                                }
                            ]
                        },
                        {
                            "Id": "AIFA",
                            "Name": "Assis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.412222, -22.661667",
                            "IataCode": "AIF",
                            "Airports": [
                                {
                                    "Id": "AIF",
                                    "Name": "Assis",
                                    "CityId": "AIFA",
                                    "CountryId": "BR",
                                    "Location": "-50.416667, -22.666667"
                                }
                            ]
                        },
                        {
                            "Id": "APQA",
                            "Name": "Arapiraca",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-36.661111, -9.7525",
                            "IataCode": "APQ",
                            "Airports": [
                                {
                                    "Id": "APQ",
                                    "Name": "Arapiraca",
                                    "CityId": "APQA",
                                    "CountryId": "BR",
                                    "Location": "-36.65, -9.75"
                                }
                            ]
                        },
                        {
                            "Id": "APSA",
                            "Name": "Anapolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.952778, -16.326667",
                            "IataCode": "APS",
                            "Airports": [
                                {
                                    "Id": "APS",
                                    "Name": "Anapolis",
                                    "CityId": "APSA",
                                    "CountryId": "BR",
                                    "Location": "-48.966667, -16.333333"
                                }
                            ]
                        },
                        {
                            "Id": "APYA",
                            "Name": "Alto Parnaiba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.929722, -9.1108333",
                            "IataCode": "APY",
                            "Airports": [
                                {
                                    "Id": "APY",
                                    "Name": "Alto Parnaiba",
                                    "CityId": "APYA",
                                    "CountryId": "BR",
                                    "Location": "-45.933333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "BAZA",
                            "Name": "Barbelos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-62.924167, -0.97472222",
                            "IataCode": "BAZ",
                            "Airports": [
                                {
                                    "Id": "BAZ",
                                    "Name": "Barbelos",
                                    "CityId": "BAZA",
                                    "CountryId": "BR",
                                    "Location": "-62.933611, -0.966944"
                                }
                            ]
                        },
                        {
                            "Id": "BNUA",
                            "Name": "Blumenau",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.066111, -26.919446",
                            "IataCode": "BNU",
                            "Airports": [
                                {
                                    "Id": "BNU",
                                    "Name": "Blumenau",
                                    "CityId": "BNUA",
                                    "CountryId": "BR",
                                    "Location": "-49, -27"
                                }
                            ]
                        },
                        {
                            "Id": "BRAA",
                            "Name": "Barreiras",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.99, -12.152778",
                            "IataCode": "BRA",
                            "Airports": [
                                {
                                    "Id": "BRA",
                                    "Name": "Barreiras",
                                    "CityId": "BRAA",
                                    "CountryId": "BR",
                                    "Location": "-45.008333, -12.073056"
                                }
                            ]
                        },
                        {
                            "Id": "BVSA",
                            "Name": "Breves",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.480278, -1.6822222",
                            "IataCode": "BVS",
                            "Airports": [
                                {
                                    "Id": "BVS",
                                    "Name": "Breves",
                                    "CityId": "BVSA",
                                    "CountryId": "BR",
                                    "Location": "-50.466667, -1.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BVHA",
                            "Name": "Vilhena",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.145834, -12.740556",
                            "IataCode": "BVH",
                            "Airports": [
                                {
                                    "Id": "BVH",
                                    "Name": "Vilhena",
                                    "CityId": "BVHA",
                                    "CountryId": "BR",
                                    "Location": "-60.097919, -12.693389"
                                }
                            ]
                        },
                        {
                            "Id": "BVMA",
                            "Name": "Belmonte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.882778, -15.863056",
                            "IataCode": "BVM",
                            "Airports": [
                                {
                                    "Id": "BVM",
                                    "Name": "Belmonte",
                                    "CityId": "BVMA",
                                    "CountryId": "BR",
                                    "Location": "-38.916667, -15.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CAUA",
                            "Name": "Caruaru",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-35.976111, -8.2833333",
                            "IataCode": "CAU",
                            "Airports": [
                                {
                                    "Id": "CAU",
                                    "Name": "Caruaru",
                                    "CityId": "CAUA",
                                    "CountryId": "BR",
                                    "Location": "-35.916667, -8.25"
                                }
                            ]
                        },
                        {
                            "Id": "CAFA",
                            "Name": "Carauari",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-66.895833, -4.8827778",
                            "IataCode": "CAF",
                            "Airports": [
                                {
                                    "Id": "CAF",
                                    "Name": "Carauari",
                                    "CityId": "CAFA",
                                    "CountryId": "BR",
                                    "Location": "-66.916667, -4.9"
                                }
                            ]
                        },
                        {
                            "Id": "CDJA",
                            "Name": "Conceicao Do Araguaia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.264722, -8.2577778",
                            "IataCode": "CDJ",
                            "Airports": [
                                {
                                    "Id": "CDJ",
                                    "Name": "Conceicao Do Araguaia",
                                    "CityId": "CDJA",
                                    "CountryId": "BR",
                                    "Location": "-49.283333, -8.233333"
                                }
                            ]
                        },
                        {
                            "Id": "CLNA",
                            "Name": "Carolina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.469444, -7.3327778",
                            "IataCode": "CLN",
                            "Airports": [
                                {
                                    "Id": "CLN",
                                    "Name": "Carolina",
                                    "CityId": "CLNA",
                                    "CountryId": "BR",
                                    "Location": "-47.45, -7.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CIZA",
                            "Name": "Coari",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-63.141389, -4.085",
                            "IataCode": "CIZ",
                            "Airports": [
                                {
                                    "Id": "CIZ",
                                    "Name": "Coari",
                                    "CityId": "CIZA",
                                    "CountryId": "BR",
                                    "Location": "-63.133333, -4.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CKOA",
                            "Name": "Cornelio Procopio",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.646667, -23.181111",
                            "IataCode": "CKO",
                            "Airports": [
                                {
                                    "Id": "CKO",
                                    "Name": "Cornelio Procopio",
                                    "CityId": "CKOA",
                                    "CountryId": "BR",
                                    "Location": "-50.666667, -23.116667"
                                }
                            ]
                        },
                        {
                            "Id": "CPUA",
                            "Name": "Cururupu",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.868333, -1.8283333",
                            "IataCode": "CPU",
                            "Airports": [
                                {
                                    "Id": "CPU",
                                    "Name": "Cururupu",
                                    "CityId": "CPUA",
                                    "CountryId": "BR",
                                    "Location": "-44.866667, -1.8"
                                }
                            ]
                        },
                        {
                            "Id": "CQSA",
                            "Name": "Costa Marques",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-64.227222, -12.445",
                            "IataCode": "CQS",
                            "Airports": [
                                {
                                    "Id": "CQS",
                                    "Name": "Costa Marques",
                                    "CityId": "CQSA",
                                    "CountryId": "BR",
                                    "Location": "-64.25, -12.416667"
                                }
                            ]
                        },
                        {
                            "Id": "DMTA",
                            "Name": "Diamantino",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.446111, -14.408611",
                            "IataCode": "DMT",
                            "Airports": [
                                {
                                    "Id": "DMT",
                                    "Name": "Diamantino",
                                    "CityId": "DMTA",
                                    "CountryId": "BR",
                                    "Location": "-56.5, -14.5"
                                }
                            ]
                        },
                        {
                            "Id": "DNOA",
                            "Name": "Dianopolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.820556, -11.627778",
                            "IataCode": "DNO",
                            "Airports": [
                                {
                                    "Id": "DNO",
                                    "Name": "Dianopolis",
                                    "CityId": "DNOA",
                                    "CountryId": "BR",
                                    "Location": "-46.85, -11.616667"
                                }
                            ]
                        },
                        {
                            "Id": "FBEA",
                            "Name": "Francisco Beltrao",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.055, -26.081111",
                            "IataCode": "FBE",
                            "Airports": [
                                {
                                    "Id": "FBE",
                                    "Name": "Francisco Beltrao",
                                    "CityId": "FBEA",
                                    "CountryId": "BR",
                                    "Location": "-53.066667, -26.083333"
                                }
                            ]
                        },
                        {
                            "Id": "FRCA",
                            "Name": "Franca",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.400833, -20.538611",
                            "IataCode": "FRC",
                            "Airports": [
                                {
                                    "Id": "FRC",
                                    "Name": "Franca",
                                    "CityId": "FRCA",
                                    "CountryId": "BR",
                                    "Location": "-47.4375, -20.551944"
                                }
                            ]
                        },
                        {
                            "Id": "GNMA",
                            "Name": "Guanambi",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.781389, -14.223333",
                            "IataCode": "GNM",
                            "Airports": [
                                {
                                    "Id": "GNM",
                                    "Name": "Guanambi",
                                    "CityId": "GNMA",
                                    "CountryId": "BR",
                                    "Location": "-42.783333, -14.3"
                                }
                            ]
                        },
                        {
                            "Id": "GRPA",
                            "Name": "Gurupi",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.068611, -11.729167",
                            "IataCode": "GRP",
                            "Airports": [
                                {
                                    "Id": "GRP",
                                    "Name": "Gurupi",
                                    "CityId": "GRPA",
                                    "CountryId": "BR",
                                    "Location": "-49.216667, -11.666667"
                                }
                            ]
                        },
                        {
                            "Id": "GUJA",
                            "Name": "Guaratingueta",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.1925, -22.816389",
                            "IataCode": "GUJ",
                            "Airports": [
                                {
                                    "Id": "GUJ",
                                    "Name": "Guaratingueta",
                                    "CityId": "GUJA",
                                    "CountryId": "BR",
                                    "Location": "-45.216667, -22.816667"
                                }
                            ]
                        },
                        {
                            "Id": "GVRA",
                            "Name": "Governador Valadares",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.949444, -18.851111",
                            "IataCode": "GVR",
                            "Airports": [
                                {
                                    "Id": "GVR",
                                    "Name": "Governador Valadares",
                                    "CityId": "GVRA",
                                    "CountryId": "BR",
                                    "Location": "-41.933333, -18.850278"
                                }
                            ]
                        },
                        {
                            "Id": "ITBA",
                            "Name": "Itaituba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.983611, -4.2761111",
                            "IataCode": "ITB",
                            "Airports": [
                                {
                                    "Id": "ITB",
                                    "Name": "Itaituba",
                                    "CityId": "ITBA",
                                    "CountryId": "BR",
                                    "Location": "-55.983334, -4.283333"
                                }
                            ]
                        },
                        {
                            "Id": "IPUA",
                            "Name": "Ipiau",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.733889, -14.137222",
                            "IataCode": "IPU",
                            "Airports": [
                                {
                                    "Id": "IPU",
                                    "Name": "Ipiau",
                                    "CityId": "IPUA",
                                    "CountryId": "BR",
                                    "Location": "-39.733333, -14.133333"
                                }
                            ]
                        },
                        {
                            "Id": "IREA",
                            "Name": "Irece",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.855833, -11.304167",
                            "IataCode": "IRE",
                            "Airports": [
                                {
                                    "Id": "IRE",
                                    "Name": "Irece",
                                    "CityId": "IREA",
                                    "CountryId": "BR",
                                    "Location": "-41.866667, -11.3"
                                }
                            ]
                        },
                        {
                            "Id": "ITNA",
                            "Name": "Itabuna",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.280278, -14.785556",
                            "IataCode": "ITN",
                            "Airports": [
                                {
                                    "Id": "ITN",
                                    "Name": "Itabuna",
                                    "CityId": "ITNA",
                                    "CountryId": "BR",
                                    "Location": "-39.3, -14.8"
                                }
                            ]
                        },
                        {
                            "Id": "CRQA",
                            "Name": "Caravelas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.265833, -17.731944",
                            "IataCode": "CRQ",
                            "Airports": [
                                {
                                    "Id": "CRQ",
                                    "Name": "Caravelas",
                                    "CityId": "CRQA",
                                    "CountryId": "BR",
                                    "Location": "-39.25, -17.75"
                                }
                            ]
                        },
                        {
                            "Id": "JEQA",
                            "Name": "Jequie",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.083611, -13.8575",
                            "IataCode": "JEQ",
                            "Airports": [
                                {
                                    "Id": "JEQ",
                                    "Name": "Jequie",
                                    "CityId": "JEQA",
                                    "CountryId": "BR",
                                    "Location": "-40.083333, -13.85"
                                }
                            ]
                        },
                        {
                            "Id": "OYKA",
                            "Name": "Oiapoque",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.835, 3.8430556",
                            "IataCode": "OYK",
                            "Airports": [
                                {
                                    "Id": "OYK",
                                    "Name": "Oiapoque",
                                    "CityId": "OYKA",
                                    "CountryId": "BR",
                                    "Location": "-51.783333, 3.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LAJA",
                            "Name": "Lages",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.326111, -27.816111",
                            "IataCode": "LAJ",
                            "Airports": [
                                {
                                    "Id": "LAJ",
                                    "Name": "Lages",
                                    "CityId": "LAJA",
                                    "CountryId": "BR",
                                    "Location": "-50.316667, -27.8"
                                }
                            ]
                        },
                        {
                            "Id": "LAZA",
                            "Name": "Bom Jesus Da Lapa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.418056, -13.255",
                            "IataCode": "LAZ",
                            "Airports": [
                                {
                                    "Id": "LAZ",
                                    "Name": "Bom Jesus Da Lapa",
                                    "CityId": "LAZA",
                                    "CountryId": "BR",
                                    "Location": "-43.4125, -13.256944"
                                }
                            ]
                        },
                        {
                            "Id": "PINA",
                            "Name": "Parintins",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.735833, -2.6283333",
                            "IataCode": "PIN",
                            "Airports": [
                                {
                                    "Id": "PIN",
                                    "Name": "Parintins",
                                    "CityId": "PINA",
                                    "CountryId": "BR",
                                    "Location": "-56.733333, -2.633333"
                                }
                            ]
                        },
                        {
                            "Id": "RBBA",
                            "Name": "Borba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-59.593889, -4.3877778",
                            "IataCode": "RBB",
                            "Airports": [
                                {
                                    "Id": "RBB",
                                    "Name": "Borba",
                                    "CityId": "RBBA",
                                    "CountryId": "BR",
                                    "Location": "-59.583333, -4.4"
                                }
                            ]
                        },
                        {
                            "Id": "LIPA",
                            "Name": "Lins",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.7425, -21.678611",
                            "IataCode": "LIP",
                            "Airports": [
                                {
                                    "Id": "LIP",
                                    "Name": "Lins",
                                    "CityId": "LIPA",
                                    "CountryId": "BR",
                                    "Location": "-49.75, -21.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BELA",
                            "Name": "Belem",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.504445, -1.4558333",
                            "IataCode": "BEL",
                            "Airports": [
                                {
                                    "Id": "BEL",
                                    "Name": "Belem",
                                    "CityId": "BELA",
                                    "CountryId": "BR",
                                    "Location": "-48.476111, -1.379167"
                                }
                            ]
                        },
                        {
                            "Id": "BGVA",
                            "Name": "Bento Goncalves",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.519167, -29.171389",
                            "IataCode": "BGV",
                            "Airports": [
                                {
                                    "Id": "BGV",
                                    "Name": "Bento Goncalves",
                                    "CityId": "BGVA",
                                    "CountryId": "BR",
                                    "Location": "-51.516667, -29.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BGXA",
                            "Name": "Bage",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.106945, -31.331389",
                            "IataCode": "BGX",
                            "Airports": [
                                {
                                    "Id": "BGX",
                                    "Name": "Bage",
                                    "CityId": "BGXA",
                                    "CountryId": "BR",
                                    "Location": "-54.1125, -31.398611"
                                }
                            ]
                        },
                        {
                            "Id": "BMSA",
                            "Name": "Brumado",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.665278, -14.203611",
                            "IataCode": "BMS",
                            "Airports": [
                                {
                                    "Id": "BMS",
                                    "Name": "Brumado",
                                    "CityId": "BMSA",
                                    "CountryId": "BR",
                                    "Location": "-41.633333, -14.233333"
                                }
                            ]
                        },
                        {
                            "Id": "BPGA",
                            "Name": "Barra Do Garcas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.256667, -15.89",
                            "IataCode": "BPG",
                            "Airports": [
                                {
                                    "Id": "BPG",
                                    "Name": "Barra Do Garcas",
                                    "CityId": "BPGA",
                                    "CountryId": "BR",
                                    "Location": "-52.390169, -15.859594"
                                }
                            ]
                        },
                        {
                            "Id": "BPSA",
                            "Name": "Porto Seguro",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.064722, -16.449722",
                            "IataCode": "BPS",
                            "Airports": [
                                {
                                    "Id": "BPS",
                                    "Name": "Porto Seguro",
                                    "CityId": "BPSA",
                                    "CountryId": "BR",
                                    "Location": "-39.083333, -16.533333"
                                }
                            ]
                        },
                        {
                            "Id": "BQQA",
                            "Name": "Barra",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.141667, -11.089444",
                            "IataCode": "BQQ",
                            "Airports": [
                                {
                                    "Id": "BQQ",
                                    "Name": "Barra",
                                    "CityId": "BQQA",
                                    "CountryId": "BR",
                                    "Location": "-43.133333, -11.083333"
                                }
                            ]
                        },
                        {
                            "Id": "BSBA",
                            "Name": "Brasilia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.929719, -15.779722",
                            "IataCode": "BSB",
                            "Airports": [
                                {
                                    "Id": "BSB",
                                    "Name": "Brasilia",
                                    "CityId": "BSBA",
                                    "CountryId": "BR",
                                    "Location": "-47.915278, -15.860556"
                                }
                            ]
                        },
                        {
                            "Id": "BVBA",
                            "Name": "Boa Vista",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.673334, 2.8197222",
                            "IataCode": "BVB",
                            "Airports": [
                                {
                                    "Id": "BVB",
                                    "Name": "Boa Vista",
                                    "CityId": "BVBA",
                                    "CountryId": "BR",
                                    "Location": "-60.666667, 2.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BZCA",
                            "Name": "Buzios",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.913357, -22.775232",
                            "IataCode": "BZC",
                            "Airports": [
                                {
                                    "Id": "BZC",
                                    "Name": "Buzios",
                                    "CityId": "BZCA",
                                    "CountryId": "BR",
                                    "Location": "-41.964444, -22.769444"
                                }
                            ]
                        },
                        {
                            "Id": "CACA",
                            "Name": "Cascavel",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.455277, -24.955833",
                            "IataCode": "CAC",
                            "Airports": [
                                {
                                    "Id": "CAC",
                                    "Name": "Cascavel",
                                    "CityId": "CACA",
                                    "CountryId": "BR",
                                    "Location": "-53.5008, -25.0003"
                                }
                            ]
                        },
                        {
                            "Id": "CAWA",
                            "Name": "Campos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.3, -21.75",
                            "IataCode": "CAW",
                            "Airports": [
                                {
                                    "Id": "CAW",
                                    "Name": "Campos",
                                    "CityId": "CAWA",
                                    "CountryId": "BR",
                                    "Location": "-41.3, -21.75"
                                }
                            ]
                        },
                        {
                            "Id": "CBWA",
                            "Name": "Campo Mourao",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.383055, -24.045556",
                            "IataCode": "CBW",
                            "Airports": [
                                {
                                    "Id": "CBW",
                                    "Name": "Campo Mourao",
                                    "CityId": "CBWA",
                                    "CountryId": "BR",
                                    "Location": "-52.356389, -24.001944"
                                }
                            ]
                        },
                        {
                            "Id": "CCIA",
                            "Name": "Concordia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.027778, -27.234167",
                            "IataCode": "CCI",
                            "Airports": [
                                {
                                    "Id": "CCI",
                                    "Name": "Concordia",
                                    "CityId": "CCIA",
                                    "CountryId": "BR",
                                    "Location": "-52.050556, -27.181389"
                                }
                            ]
                        },
                        {
                            "Id": "CCMA",
                            "Name": "Criciuma",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.369722, -28.6775",
                            "IataCode": "CCM",
                            "Airports": [
                                {
                                    "Id": "CCM",
                                    "Name": "Criciuma",
                                    "CityId": "CCMA",
                                    "CountryId": "BR",
                                    "Location": "-49.42139, -28.72444"
                                }
                            ]
                        },
                        {
                            "Id": "CCXA",
                            "Name": "Caceres",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.678889, -16.070556",
                            "IataCode": "CCX",
                            "Airports": [
                                {
                                    "Id": "CCX",
                                    "Name": "Caceres",
                                    "CityId": "CCXA",
                                    "CountryId": "BR",
                                    "Location": "-57.7, -16.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CFBA",
                            "Name": "Cabo Frio",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.018611, -22.879444",
                            "IataCode": "CFB",
                            "Airports": [
                                {
                                    "Id": "CFB",
                                    "Name": "Cabo Frio",
                                    "CityId": "CFBA",
                                    "CountryId": "BR",
                                    "Location": "-42.078611, -22.925"
                                }
                            ]
                        },
                        {
                            "Id": "CGBA",
                            "Name": "Cuiaba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.096667, -15.596111",
                            "IataCode": "CGB",
                            "Airports": [
                                {
                                    "Id": "CGB",
                                    "Name": "Cuiaba",
                                    "CityId": "CGBA",
                                    "CountryId": "BR",
                                    "Location": "-56.116667, -15.65"
                                }
                            ]
                        },
                        {
                            "Id": "SAOA",
                            "Name": "Sao Paulo",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-46.648221, -23.650481",
                            "IataCode": "SAO",
                            "Airports": [
                                {
                                    "Id": "CGH",
                                    "Name": "Sao Paulo Congonhas",
                                    "CityId": "SAOA",
                                    "CountryId": "BR",
                                    "Location": "-46.66, -23.625"
                                },
                                {
                                    "Id": "GRU",
                                    "Name": "Sao Paulo Guarulhos",
                                    "CityId": "SAOA",
                                    "CountryId": "BR",
                                    "Location": "-46.483333, -23.433333"
                                },
                                {
                                    "Id": "VCP",
                                    "Name": "Sao Paulo Viracopos",
                                    "CityId": "SAOA",
                                    "CountryId": "BR",
                                    "Location": "-47.13452, -23.00738"
                                }
                            ]
                        },
                        {
                            "Id": "CGRA",
                            "Name": "Campo Grande",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.646389, -20.442778",
                            "IataCode": "CGR",
                            "Airports": [
                                {
                                    "Id": "CGR",
                                    "Name": "Campo Grande",
                                    "CityId": "CGRA",
                                    "CountryId": "BR",
                                    "Location": "-54.6725, -20.463333"
                                }
                            ]
                        },
                        {
                            "Id": "CKSA",
                            "Name": "Carajas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.866667, -2.95",
                            "IataCode": "CKS",
                            "Airports": [
                                {
                                    "Id": "CKS",
                                    "Name": "Carajas",
                                    "CityId": "CKSA",
                                    "CountryId": "BR",
                                    "Location": "-50.00347, -6.11781"
                                }
                            ]
                        },
                        {
                            "Id": "CLVA",
                            "Name": "Caldas Novas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.625, -17.741667",
                            "IataCode": "CLV",
                            "Airports": [
                                {
                                    "Id": "CLV",
                                    "Name": "Caldas Novas",
                                    "CityId": "CLVA",
                                    "CountryId": "BR",
                                    "Location": "-48.633333, -17.75"
                                }
                            ]
                        },
                        {
                            "Id": "CMGA",
                            "Name": "Corumba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.653333, -19.009167",
                            "IataCode": "CMG",
                            "Airports": [
                                {
                                    "Id": "CMG",
                                    "Name": "Corumba",
                                    "CityId": "CMGA",
                                    "CountryId": "BR",
                                    "Location": "-57.672222, -19.011111"
                                }
                            ]
                        },
                        {
                            "Id": "CMTA",
                            "Name": "Cameta",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.495833, -2.2444444",
                            "IataCode": "CMT",
                            "Airports": [
                                {
                                    "Id": "CMT",
                                    "Name": "Cameta",
                                    "CityId": "CMTA",
                                    "CountryId": "BR",
                                    "Location": "-49.5, -2.2"
                                }
                            ]
                        },
                        {
                            "Id": "BHZA",
                            "Name": "Belo Horizonte",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-43.937778, -19.920836",
                            "IataCode": "BHZ",
                            "Airports": [
                                {
                                    "Id": "PLU",
                                    "Name": "Belo Horizonte Pampulha",
                                    "CityId": "BHZA",
                                    "CountryId": "BR",
                                    "Location": "-43.950556, -19.851111"
                                },
                                {
                                    "Id": "CNF",
                                    "Name": "Belo Horizonte Tancredo Neves Int'l.",
                                    "CityId": "BHZA",
                                    "CountryId": "BR",
                                    "Location": "-43.968611, -19.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CPQA",
                            "Name": "Campinas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.060833, -22.905555",
                            "IataCode": "CPQ",
                            "Airports": [
                                {
                                    "Id": "CPQ",
                                    "Name": "Campinas",
                                    "CityId": "CPQA",
                                    "CountryId": "BR",
                                    "Location": "-47.1082, -22.8592"
                                }
                            ]
                        },
                        {
                            "Id": "CPVA",
                            "Name": "Campina Grande",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-35.881111, -7.2305555",
                            "IataCode": "CPV",
                            "Airports": [
                                {
                                    "Id": "CPV",
                                    "Name": "Campina Grande",
                                    "CityId": "CPVA",
                                    "CountryId": "BR",
                                    "Location": "-35.9, -7.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CSSA",
                            "Name": "Cassilandia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.734167, -19.113333",
                            "IataCode": "CSS",
                            "Airports": [
                                {
                                    "Id": "CSS",
                                    "Name": "Cassilandia",
                                    "CityId": "CSSA",
                                    "CountryId": "BR",
                                    "Location": "-51.983333, -18.983333"
                                }
                            ]
                        },
                        {
                            "Id": "CSWB",
                            "Name": "Colorado do Oeste",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.541667, -13.116667",
                            "IataCode": "CSW",
                            "Airports": [
                                {
                                    "Id": "CSW",
                                    "Name": "Colorado do Oeste",
                                    "CityId": "CSWB",
                                    "CountryId": "BR",
                                    "Location": "-61.168056, -13.019444"
                                }
                            ]
                        },
                        {
                            "Id": "AFLA",
                            "Name": "Alta Floresta",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.086111, -9.8755556",
                            "IataCode": "AFL",
                            "Airports": [
                                {
                                    "Id": "AFL",
                                    "Name": "Alta Floresta",
                                    "CityId": "AFLA",
                                    "CountryId": "BR",
                                    "Location": "-56, -10"
                                }
                            ]
                        },
                        {
                            "Id": "ALTB",
                            "Name": "Alenquer",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.738333, -1.9416667",
                            "IataCode": "ALT",
                            "Airports": [
                                {
                                    "Id": "ALT",
                                    "Name": "Alenquer",
                                    "CityId": "ALTB",
                                    "CountryId": "BR",
                                    "Location": "-54.766667, -1.933333"
                                }
                            ]
                        },
                        {
                            "Id": "AMJA",
                            "Name": "Almenara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.694444, -16.183611",
                            "IataCode": "AMJ",
                            "Airports": [
                                {
                                    "Id": "AMJ",
                                    "Name": "Almenara",
                                    "CityId": "AMJA",
                                    "CountryId": "BR",
                                    "Location": "-40.683333, -16.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ARSA",
                            "Name": "Aragarcas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.250833, -15.8975",
                            "IataCode": "ARS",
                            "Airports": [
                                {
                                    "Id": "ARS",
                                    "Name": "Aragarcas",
                                    "CityId": "ARSA",
                                    "CountryId": "BR",
                                    "Location": "-52.233333, -15.9"
                                }
                            ]
                        },
                        {
                            "Id": "CWBA",
                            "Name": "Curitiba",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-49.273056, -25.42778",
                            "IataCode": "CWB",
                            "Airports": [
                                {
                                    "Id": "BFH",
                                    "Name": "Bacacheri",
                                    "CityId": "CWBA",
                                    "CountryId": "BR",
                                    "Location": "-49.233889, -25.4025"
                                },
                                {
                                    "Id": "CWB",
                                    "Name": "Curitiba",
                                    "CityId": "CWBA",
                                    "CountryId": "BR",
                                    "Location": "-49.177778, -25.526667"
                                }
                            ]
                        },
                        {
                            "Id": "CXJA",
                            "Name": "Caxias Do Sul",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.179444, -29.168056",
                            "IataCode": "CXJ",
                            "Airports": [
                                {
                                    "Id": "CXJ",
                                    "Name": "Caxias Do Sul",
                                    "CityId": "CXJA",
                                    "CountryId": "BR",
                                    "Location": "-51.186848, -29.196677"
                                }
                            ]
                        },
                        {
                            "Id": "CZSA",
                            "Name": "Cruzeiro Do Sul",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-72.67, -7.6311111",
                            "IataCode": "CZS",
                            "Airports": [
                                {
                                    "Id": "CZS",
                                    "Name": "Cruzeiro Do Sul",
                                    "CityId": "CZSA",
                                    "CountryId": "BR",
                                    "Location": "-72.783333, -7.583333"
                                }
                            ]
                        },
                        {
                            "Id": "CMCA",
                            "Name": "Camocim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.841111, -2.9022222",
                            "IataCode": "CMC",
                            "Airports": [
                                {
                                    "Id": "CMC",
                                    "Name": "Camocim",
                                    "CityId": "CMCA",
                                    "CountryId": "BR",
                                    "Location": "-40.833333, -2.916667"
                                }
                            ]
                        },
                        {
                            "Id": "DIQA",
                            "Name": "Divinopolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.883889, -20.138889",
                            "IataCode": "DIQ",
                            "Airports": [
                                {
                                    "Id": "DIQ",
                                    "Name": "Divinopolis",
                                    "CityId": "DIQA",
                                    "CountryId": "BR",
                                    "Location": "-44.869444, -20.181389"
                                }
                            ]
                        },
                        {
                            "Id": "DOUA",
                            "Name": "Dourados",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.805556, -22.221111",
                            "IataCode": "DOU",
                            "Airports": [
                                {
                                    "Id": "DOU",
                                    "Name": "Dourados",
                                    "CityId": "DOUA",
                                    "CountryId": "BR",
                                    "Location": "-54.925556, -22.202778"
                                }
                            ]
                        },
                        {
                            "Id": "ERMA",
                            "Name": "Erechim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.273889, -27.634167",
                            "IataCode": "ERM",
                            "Airports": [
                                {
                                    "Id": "ERM",
                                    "Name": "Erechim",
                                    "CityId": "ERMA",
                                    "CountryId": "BR",
                                    "Location": "-52.275556, -27.641667"
                                }
                            ]
                        },
                        {
                            "Id": "ERNA",
                            "Name": "Eirunepe",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-69.873611, -6.6602778",
                            "IataCode": "ERN",
                            "Airports": [
                                {
                                    "Id": "ERN",
                                    "Name": "Eirunepe",
                                    "CityId": "ERNA",
                                    "CountryId": "BR",
                                    "Location": "-69.883333, -6.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ESIA",
                            "Name": "Espinosa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.819167, -14.926111",
                            "IataCode": "ESI",
                            "Airports": [
                                {
                                    "Id": "ESI",
                                    "Name": "Espinosa",
                                    "CityId": "ESIA",
                                    "CountryId": "BR",
                                    "Location": "-44, -15"
                                }
                            ]
                        },
                        {
                            "Id": "FLBA",
                            "Name": "Floriano",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.0225, -6.7669444",
                            "IataCode": "FLB",
                            "Airports": [
                                {
                                    "Id": "FLB",
                                    "Name": "Floriano",
                                    "CityId": "FLBA",
                                    "CountryId": "BR",
                                    "Location": "-43.033333, -6.8"
                                }
                            ]
                        },
                        {
                            "Id": "FLNA",
                            "Name": "Florianopolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.549167, -27.596667",
                            "IataCode": "FLN",
                            "Airports": [
                                {
                                    "Id": "FLN",
                                    "Name": "Florianopolis",
                                    "CityId": "FLNA",
                                    "CountryId": "BR",
                                    "Location": "-48.550556, -27.669722"
                                }
                            ]
                        },
                        {
                            "Id": "FORA",
                            "Name": "Fortaleza",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.543056, -3.7172222",
                            "IataCode": "FOR",
                            "Airports": [
                                {
                                    "Id": "FOR",
                                    "Name": "Fortaleza",
                                    "CityId": "FORA",
                                    "CountryId": "BR",
                                    "Location": "-38.534444, -3.776667"
                                }
                            ]
                        },
                        {
                            "Id": "GELA",
                            "Name": "Santo Angelo",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.263056, -28.299167",
                            "IataCode": "GEL",
                            "Airports": [
                                {
                                    "Id": "GEL",
                                    "Name": "Santo Angelo",
                                    "CityId": "GELA",
                                    "CountryId": "BR",
                                    "Location": "-54.168056, -28.281111"
                                }
                            ]
                        },
                        {
                            "Id": "RIOA",
                            "Name": "Rio De Janeiro",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-43.296306, -22.932491",
                            "IataCode": "RIO",
                            "Airports": [
                                {
                                    "Id": "SDU",
                                    "Name": "Rio De Janeiro Santos Dumont",
                                    "CityId": "RIOA",
                                    "CountryId": "BR",
                                    "Location": "-43.16313, -22.91046"
                                },
                                {
                                    "Id": "GIG",
                                    "Name": "Rio De Janeiro Internacional",
                                    "CityId": "RIOA",
                                    "CountryId": "BR",
                                    "Location": "-43.243611, -22.808889"
                                }
                            ]
                        },
                        {
                            "Id": "GMSA",
                            "Name": "Guimaraes",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.601111, -2.1330556",
                            "IataCode": "GMS",
                            "Airports": [
                                {
                                    "Id": "GMS",
                                    "Name": "Guimaraes",
                                    "CityId": "GMSA",
                                    "CountryId": "BR",
                                    "Location": "-44.7, -2.15"
                                }
                            ]
                        },
                        {
                            "Id": "JCMA",
                            "Name": "Jacobina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.518333, -11.180556",
                            "IataCode": "JCM",
                            "Airports": [
                                {
                                    "Id": "JCM",
                                    "Name": "Jacobina",
                                    "CityId": "JCMA",
                                    "CountryId": "BR",
                                    "Location": "-40.516667, -11.183333"
                                }
                            ]
                        },
                        {
                            "Id": "GPBA",
                            "Name": "Guarapuava",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.458056, -25.395278",
                            "IataCode": "GPB",
                            "Airports": [
                                {
                                    "Id": "GPB",
                                    "Name": "Guarapuava",
                                    "CityId": "GPBA",
                                    "CountryId": "BR",
                                    "Location": "-51.5, -25.333333"
                                }
                            ]
                        },
                        {
                            "Id": "GYNA",
                            "Name": "Goiania",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.253889, -16.678611",
                            "IataCode": "GYN",
                            "Airports": [
                                {
                                    "Id": "GYN",
                                    "Name": "Goiania",
                                    "CityId": "GYNA",
                                    "CountryId": "BR",
                                    "Location": "-49.210833, -16.638333"
                                }
                            ]
                        },
                        {
                            "Id": "BSSA",
                            "Name": "Balsas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.035555, -7.5325",
                            "IataCode": "BSS",
                            "Airports": [
                                {
                                    "Id": "BSS",
                                    "Name": "Balsas",
                                    "CityId": "BSSA",
                                    "CountryId": "BR",
                                    "Location": "-46.05, -7.516667"
                                }
                            ]
                        },
                        {
                            "Id": "IDOA",
                            "Name": "Santa Isabel do Morro",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.666667, -11.566667",
                            "IataCode": "IDO",
                            "Airports": [
                                {
                                    "Id": "IDO",
                                    "Name": "Santa Isabel do Morro",
                                    "CityId": "IDOA",
                                    "CountryId": "BR",
                                    "Location": "-50.666667, -11.566667"
                                }
                            ]
                        },
                        {
                            "Id": "IJUA",
                            "Name": "Ijui",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.914722, -28.387778",
                            "IataCode": "IJU",
                            "Airports": [
                                {
                                    "Id": "IJU",
                                    "Name": "Ijui",
                                    "CityId": "IJUA",
                                    "CountryId": "BR",
                                    "Location": "-53.916667, -28.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IMPA",
                            "Name": "Imperatriz",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.491666, -5.5263889",
                            "IataCode": "IMP",
                            "Airports": [
                                {
                                    "Id": "IMP",
                                    "Name": "Imperatriz",
                                    "CityId": "IMPA",
                                    "CountryId": "BR",
                                    "Location": "-47.480556, -5.531944"
                                }
                            ]
                        },
                        {
                            "Id": "IOSA",
                            "Name": "Ilheus",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.049444, -14.788889",
                            "IataCode": "IOS",
                            "Airports": [
                                {
                                    "Id": "IOS",
                                    "Name": "Ilheus",
                                    "CityId": "IOSA",
                                    "CountryId": "BR",
                                    "Location": "-39.03, -14.813889"
                                }
                            ]
                        },
                        {
                            "Id": "ITEA",
                            "Name": "Itubera",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.149167, -13.732222",
                            "IataCode": "ITE",
                            "Airports": [
                                {
                                    "Id": "ITE",
                                    "Name": "Itubera",
                                    "CityId": "ITEA",
                                    "CountryId": "BR",
                                    "Location": "-39.2, -13.7"
                                }
                            ]
                        },
                        {
                            "Id": "JCBA",
                            "Name": "Joacaba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.504722, -27.178056",
                            "IataCode": "JCB",
                            "Airports": [
                                {
                                    "Id": "JCB",
                                    "Name": "Joacaba",
                                    "CityId": "JCBA",
                                    "CountryId": "BR",
                                    "Location": "-51.5533, -27.1714"
                                }
                            ]
                        },
                        {
                            "Id": "JDFA",
                            "Name": "Juiz De Fora",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-43.350278, -21.764167",
                            "IataCode": "JDF",
                            "Airports": [
                                {
                                    "Id": "IZA",
                                    "Name": "Zona da Mata Regional Airport",
                                    "CityId": "JDFA",
                                    "CountryId": "BR",
                                    "Location": "-43.173056, -21.513056"
                                },
                                {
                                    "Id": "JDF",
                                    "Name": "Juiz De Fora",
                                    "CityId": "JDFA",
                                    "CountryId": "BR",
                                    "Location": "-43.333333, -21.75"
                                }
                            ]
                        },
                        {
                            "Id": "JDOA",
                            "Name": "Juazeiro Do Norte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.315278, -7.2130555",
                            "IataCode": "JDO",
                            "Airports": [
                                {
                                    "Id": "JDO",
                                    "Name": "Juazeiro Do Norte",
                                    "CityId": "JDOA",
                                    "CountryId": "BR",
                                    "Location": "-39.316667, -7.2"
                                }
                            ]
                        },
                        {
                            "Id": "JNAA",
                            "Name": "Januaria",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.366667, -15.483333",
                            "IataCode": "JNA",
                            "Airports": [
                                {
                                    "Id": "JNA",
                                    "Name": "Januaria",
                                    "CityId": "JNAA",
                                    "CountryId": "BR",
                                    "Location": "-44.385833, -15.474167"
                                }
                            ]
                        },
                        {
                            "Id": "JOIA",
                            "Name": "Joinville",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.845556, -26.304444",
                            "IataCode": "JOI",
                            "Airports": [
                                {
                                    "Id": "JOI",
                                    "Name": "Joinville",
                                    "CityId": "JOIA",
                                    "CountryId": "BR",
                                    "Location": "-48.783333, -26.233333"
                                }
                            ]
                        },
                        {
                            "Id": "JPAA",
                            "Name": "Joao Pessoa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-34.863055, -7.115",
                            "IataCode": "JPA",
                            "Airports": [
                                {
                                    "Id": "JPA",
                                    "Name": "Joao Pessoa",
                                    "CityId": "JPAA",
                                    "CountryId": "BR",
                                    "Location": "-34.951944, -7.146389"
                                }
                            ]
                        },
                        {
                            "Id": "JUAA",
                            "Name": "Juara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.519722, -11.255",
                            "IataCode": "JUA",
                            "Airports": [
                                {
                                    "Id": "JUA",
                                    "Name": "Juara",
                                    "CityId": "JUAA",
                                    "CountryId": "BR",
                                    "Location": "-57.466667, -11.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LEPA",
                            "Name": "Leopoldina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.643056, -21.531944",
                            "IataCode": "LEP",
                            "Airports": [
                                {
                                    "Id": "LEP",
                                    "Name": "Leopoldina",
                                    "CityId": "LEPA",
                                    "CountryId": "BR",
                                    "Location": "-42.666667, -21.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LBRA",
                            "Name": "Labrea",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-64.798056, -7.2586111",
                            "IataCode": "LBR",
                            "Airports": [
                                {
                                    "Id": "LBR",
                                    "Name": "Labrea",
                                    "CityId": "LBRA",
                                    "CountryId": "BR",
                                    "Location": "-64.85, -7.25"
                                }
                            ]
                        },
                        {
                            "Id": "LDBA",
                            "Name": "Londrina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.162778, -23.310278",
                            "IataCode": "LDB",
                            "Airports": [
                                {
                                    "Id": "LDB",
                                    "Name": "Londrina",
                                    "CityId": "LDBA",
                                    "CountryId": "BR",
                                    "Location": "-51.131944, -23.331944"
                                }
                            ]
                        },
                        {
                            "Id": "MAOA",
                            "Name": "Manaus",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.025001, -3.1019444",
                            "IataCode": "MAO",
                            "Airports": [
                                {
                                    "Id": "MAO",
                                    "Name": "Manaus",
                                    "CityId": "MAOA",
                                    "CountryId": "BR",
                                    "Location": "-60.05, -3.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MCPA",
                            "Name": "Macapa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.066389, 0.038888889",
                            "IataCode": "MCP",
                            "Airports": [
                                {
                                    "Id": "MCP",
                                    "Name": "Macapa",
                                    "CityId": "MCPA",
                                    "CountryId": "BR",
                                    "Location": "-51.066667, 0.05"
                                }
                            ]
                        },
                        {
                            "Id": "MCZA",
                            "Name": "Maceio",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-35.735277, -9.6658333",
                            "IataCode": "MCZ",
                            "Airports": [
                                {
                                    "Id": "MCZ",
                                    "Name": "Maceio",
                                    "CityId": "MCZA",
                                    "CountryId": "BR",
                                    "Location": "-35.795833, -9.515278"
                                }
                            ]
                        },
                        {
                            "Id": "MEAA",
                            "Name": "Macae",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.786944, -22.370833",
                            "IataCode": "MEA",
                            "Airports": [
                                {
                                    "Id": "MEA",
                                    "Name": "Macae",
                                    "CityId": "MEAA",
                                    "CountryId": "BR",
                                    "Location": "-41.8, -22.35"
                                }
                            ]
                        },
                        {
                            "Id": "MGFA",
                            "Name": "Maringa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.938611, -23.425278",
                            "IataCode": "MGF",
                            "Airports": [
                                {
                                    "Id": "MGF",
                                    "Name": "Regional De Maringa",
                                    "CityId": "MGFA",
                                    "CountryId": "BR",
                                    "Location": "-51.933333, -23.383611"
                                }
                            ]
                        },
                        {
                            "Id": "MVFA",
                            "Name": "Mossoro",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-37.344167, -5.1875",
                            "IataCode": "MVF",
                            "Airports": [
                                {
                                    "Id": "MVF",
                                    "Name": "Mossoro",
                                    "CityId": "MVFA",
                                    "CountryId": "BR",
                                    "Location": "-37.36435, -5.20192"
                                }
                            ]
                        },
                        {
                            "Id": "NATA",
                            "Name": "Natal",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-35.209444, -5.795",
                            "IataCode": "NAT",
                            "Airports": [
                                {
                                    "Id": "NAT",
                                    "Name": "Natal",
                                    "CityId": "NATA",
                                    "CountryId": "BR",
                                    "Location": "-35.243611, -5.910556"
                                }
                            ]
                        },
                        {
                            "Id": "NVTA",
                            "Name": "Navegantes",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.654167, -26.898889",
                            "IataCode": "NVT",
                            "Airports": [
                                {
                                    "Id": "NVT",
                                    "Name": "Navegantes",
                                    "CityId": "NVTA",
                                    "CountryId": "BR",
                                    "Location": "-48.633333, -26.866667"
                                }
                            ]
                        },
                        {
                            "Id": "PETA",
                            "Name": "Pelotas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.3425, -31.771944",
                            "IataCode": "PET",
                            "Airports": [
                                {
                                    "Id": "PET",
                                    "Name": "Pelotas",
                                    "CityId": "PETA",
                                    "CountryId": "BR",
                                    "Location": "-52.324444, -31.718056"
                                }
                            ]
                        },
                        {
                            "Id": "PFBA",
                            "Name": "Passo Fundo",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.406667, -28.262778",
                            "IataCode": "PFB",
                            "Airports": [
                                {
                                    "Id": "PFB",
                                    "Name": "Passo Fundo",
                                    "CityId": "PFBA",
                                    "CountryId": "BR",
                                    "Location": "-52.333333, -28.25"
                                }
                            ]
                        },
                        {
                            "Id": "PHBA",
                            "Name": "Parnaiba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.776666, -2.9047222",
                            "IataCode": "PHB",
                            "Airports": [
                                {
                                    "Id": "PHB",
                                    "Name": "Parnaiba",
                                    "CityId": "PHBA",
                                    "CountryId": "BR",
                                    "Location": "-41.738333, -2.891667"
                                }
                            ]
                        },
                        {
                            "Id": "PNZA",
                            "Name": "Petrolina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.500833, -9.3986111",
                            "IataCode": "PNZ",
                            "Airports": [
                                {
                                    "Id": "PNZ",
                                    "Name": "Petrolina",
                                    "CityId": "PNZA",
                                    "CountryId": "BR",
                                    "Location": "-40.490556, -9.393333"
                                }
                            ]
                        },
                        {
                            "Id": "POAA",
                            "Name": "Porto Alegre",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.23, -30.033058",
                            "IataCode": "POA",
                            "Airports": [
                                {
                                    "Id": "POA",
                                    "Name": "Porto Alegre",
                                    "CityId": "POAA",
                                    "CountryId": "BR",
                                    "Location": "-51.170833, -29.993333"
                                }
                            ]
                        },
                        {
                            "Id": "PPBA",
                            "Name": "Presidente Prudente",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.388889, -22.125556",
                            "IataCode": "PPB",
                            "Airports": [
                                {
                                    "Id": "PPB",
                                    "Name": "Presidente Prudente",
                                    "CityId": "PPBA",
                                    "CountryId": "BR",
                                    "Location": "-51.45, -22.1"
                                }
                            ]
                        },
                        {
                            "Id": "PVHA",
                            "Name": "Porto Velho",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-63.903889, -8.7619444",
                            "IataCode": "PVH",
                            "Airports": [
                                {
                                    "Id": "PVH",
                                    "Name": "Porto Velho",
                                    "CityId": "PVHA",
                                    "CountryId": "BR",
                                    "Location": "-63.9, -8.733333"
                                }
                            ]
                        },
                        {
                            "Id": "QSCQ",
                            "Name": "Sao Carlos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.890833, -22.0175",
                            "IataCode": "QSC",
                            "Airports": [
                                {
                                    "Id": "QSC",
                                    "Name": "Sao Carlos Airport",
                                    "CityId": "QSCQ",
                                    "CountryId": "BR",
                                    "Location": "47.903333, 21.876389"
                                }
                            ]
                        },
                        {
                            "Id": "RBRA",
                            "Name": "Rio Branco",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-67.81, -9.9747222",
                            "IataCode": "RBR",
                            "Airports": [
                                {
                                    "Id": "RBR",
                                    "Name": "Rio Branco",
                                    "CityId": "RBRA",
                                    "CountryId": "BR",
                                    "Location": "-67.894167, -9.869167"
                                }
                            ]
                        },
                        {
                            "Id": "RECA",
                            "Name": "Recife",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-34.88111, -8.0538888",
                            "IataCode": "REC",
                            "Airports": [
                                {
                                    "Id": "REC",
                                    "Name": "Recife",
                                    "CityId": "RECA",
                                    "CountryId": "BR",
                                    "Location": "-34.923889, -8.125833"
                                }
                            ]
                        },
                        {
                            "Id": "RIAA",
                            "Name": "Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.806944, -29.684167",
                            "IataCode": "RIA",
                            "Airports": [
                                {
                                    "Id": "RIA",
                                    "Name": "Santa Maria",
                                    "CityId": "RIAA",
                                    "CountryId": "BR",
                                    "Location": "-53.6875, -29.710556"
                                }
                            ]
                        },
                        {
                            "Id": "RIGB",
                            "Name": "Rio Grande",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.098611, -32.035",
                            "IataCode": "RIG",
                            "Airports": [
                                {
                                    "Id": "RIG",
                                    "Name": "Rio Grande",
                                    "CityId": "RIGB",
                                    "CountryId": "BR",
                                    "Location": "-52.166668, -32.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SSZA",
                            "Name": "Santos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.333611, -23.960833",
                            "IataCode": "SSZ",
                            "Airports": [
                                {
                                    "Id": "SSZ",
                                    "Name": "Santos",
                                    "CityId": "SSZA",
                                    "CountryId": "BR",
                                    "Location": "-46.366667, -23.933333"
                                }
                            ]
                        },
                        {
                            "Id": "STMB",
                            "Name": "Santarem",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.708334, -2.4430556",
                            "IataCode": "STM",
                            "Airports": [
                                {
                                    "Id": "STM",
                                    "Name": "Santarem",
                                    "CityId": "STMB",
                                    "CountryId": "BR",
                                    "Location": "-54.7, -2.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SJKA",
                            "Name": "Sao Jose Dos Campos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.886944, -23.179446",
                            "IataCode": "SJK",
                            "Airports": [
                                {
                                    "Id": "SJK",
                                    "Name": "Sao Jose Dos Campos",
                                    "CityId": "SJKA",
                                    "CountryId": "BR",
                                    "Location": "-45.86274, -23.22817"
                                }
                            ]
                        },
                        {
                            "Id": "SJPA",
                            "Name": "Sao Jose Do Rio Preto",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.379445, -20.819722",
                            "IataCode": "SJP",
                            "Airports": [
                                {
                                    "Id": "SJP",
                                    "Name": "Sao Jose Do Rio Preto",
                                    "CityId": "SJPA",
                                    "CountryId": "BR",
                                    "Location": "-49.416667, -20.816667"
                                }
                            ]
                        },
                        {
                            "Id": "SLZA",
                            "Name": "Sao Luiz",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.302778, -2.5297222",
                            "IataCode": "SLZ",
                            "Airports": [
                                {
                                    "Id": "SLZ",
                                    "Name": "Sao Luiz",
                                    "CityId": "SLZA",
                                    "CountryId": "BR",
                                    "Location": "-44.233333, -2.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SODB",
                            "Name": "Sorocaba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.458055, -23.501667",
                            "IataCode": "SOD",
                            "Airports": [
                                {
                                    "Id": "SOD",
                                    "Name": "Sorocaba",
                                    "CityId": "SODB",
                                    "CountryId": "BR",
                                    "Location": "-47.450001, -23.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SSAA",
                            "Name": "Salvador",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.510834, -12.971113",
                            "IataCode": "SSA",
                            "Airports": [
                                {
                                    "Id": "SSA",
                                    "Name": "Salvador",
                                    "CityId": "SSAA",
                                    "CountryId": "BR",
                                    "Location": "-38.333333, -12.916667"
                                }
                            ]
                        },
                        {
                            "Id": "TBTA",
                            "Name": "Tabatinga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-69.938056, -4.2525",
                            "IataCode": "TBT",
                            "Airports": [
                                {
                                    "Id": "TBT",
                                    "Name": "Tabatinga",
                                    "CityId": "TBTA",
                                    "CountryId": "BR",
                                    "Location": "-69.93583, -4.25567"
                                }
                            ]
                        },
                        {
                            "Id": "THEA",
                            "Name": "Teresina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.801945, -5.0891667",
                            "IataCode": "THE",
                            "Airports": [
                                {
                                    "Id": "THE",
                                    "Name": "Teresina",
                                    "CityId": "THEA",
                                    "CountryId": "BR",
                                    "Location": "-42.817778, -5.065278"
                                }
                            ]
                        },
                        {
                            "Id": "UBAA",
                            "Name": "Uberaba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.931944, -19.748333",
                            "IataCode": "UBA",
                            "Airports": [
                                {
                                    "Id": "UBA",
                                    "Name": "Uberaba",
                                    "CityId": "UBAA",
                                    "CountryId": "BR",
                                    "Location": "-47.958333, -19.776389"
                                }
                            ]
                        },
                        {
                            "Id": "UDIA",
                            "Name": "Uberlandia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.277223, -18.918611",
                            "IataCode": "UDI",
                            "Airports": [
                                {
                                    "Id": "UDI",
                                    "Name": "Uberlandia",
                                    "CityId": "UDIA",
                                    "CountryId": "BR",
                                    "Location": "-48.233333, -18.9"
                                }
                            ]
                        },
                        {
                            "Id": "URGA",
                            "Name": "Uruguaiana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.088333, -29.754722",
                            "IataCode": "URG",
                            "Airports": [
                                {
                                    "Id": "URG",
                                    "Name": "Uruguaiana",
                                    "CityId": "URGA",
                                    "CountryId": "BR",
                                    "Location": "-57.038333, -29.781667"
                                }
                            ]
                        },
                        {
                            "Id": "VIAA",
                            "Name": "Videira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.151667, -27.008333",
                            "IataCode": "VIA",
                            "Airports": [
                                {
                                    "Id": "VIA",
                                    "Name": "Videira",
                                    "CityId": "VIAA",
                                    "CountryId": "BR",
                                    "Location": "-51.136111, -27.008333"
                                }
                            ]
                        },
                        {
                            "Id": "AAIA",
                            "Name": "Arraias",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.938333, -12.931389",
                            "IataCode": "AAI",
                            "Airports": [
                                {
                                    "Id": "AAI",
                                    "Name": "Arraias",
                                    "CityId": "AAIA",
                                    "CountryId": "BR",
                                    "Location": "-46.933333, -12.916667"
                                }
                            ]
                        },
                        {
                            "Id": "AIRA",
                            "Name": "Aripuana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-59.459444, -10.166667",
                            "IataCode": "AIR",
                            "Airports": [
                                {
                                    "Id": "AIR",
                                    "Name": "Aripuana",
                                    "CityId": "AIRA",
                                    "CountryId": "BR",
                                    "Location": "-59.383333, -10.25"
                                }
                            ]
                        },
                        {
                            "Id": "ARUA",
                            "Name": "Aracatuba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.432778, -21.208889",
                            "IataCode": "ARU",
                            "Airports": [
                                {
                                    "Id": "ARU",
                                    "Name": "Aracatuba",
                                    "CityId": "ARUA",
                                    "CountryId": "BR",
                                    "Location": "-50.426111, -21.143611"
                                }
                            ]
                        },
                        {
                            "Id": "ATMA",
                            "Name": "Altamira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.206389, -3.2033333",
                            "IataCode": "ATM",
                            "Airports": [
                                {
                                    "Id": "ATM",
                                    "Name": "Altamira",
                                    "CityId": "ATMA",
                                    "CountryId": "BR",
                                    "Location": "-52.216667, -3.2"
                                }
                            ]
                        },
                        {
                            "Id": "AUXA",
                            "Name": "Araguaina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.207222, -7.1911111",
                            "IataCode": "AUX",
                            "Airports": [
                                {
                                    "Id": "AUX",
                                    "Name": "Araguaina",
                                    "CityId": "AUXA",
                                    "CountryId": "BR",
                                    "Location": "-48.2, -7.2"
                                }
                            ]
                        },
                        {
                            "Id": "BATA",
                            "Name": "Barretos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.567778, -20.557222",
                            "IataCode": "BAT",
                            "Airports": [
                                {
                                    "Id": "BAT",
                                    "Name": "Barretos",
                                    "CityId": "BATA",
                                    "CountryId": "BR",
                                    "Location": "-48.55, -20.55"
                                }
                            ]
                        },
                        {
                            "Id": "BAUA",
                            "Name": "Bauru",
                            "SingleAirportCity": false,
                            "CountryId": "BR",
                            "Location": "-49.060556, -22.314722",
                            "IataCode": "BAU",
                            "Airports": [
                                {
                                    "Id": "BAU",
                                    "Name": "Bauru",
                                    "CityId": "BAUA",
                                    "CountryId": "BR",
                                    "Location": "-49.0525, -22.343056"
                                },
                                {
                                    "Id": "JTC",
                                    "Name": "Bauru-Arealva",
                                    "CityId": "BAUA",
                                    "CountryId": "BR",
                                    "Location": "-49.068333, -22.157778"
                                }
                            ]
                        },
                        {
                            "Id": "BCRA",
                            "Name": "Boca Do Acre",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-67.397778, -8.7522222",
                            "IataCode": "BCR",
                            "Airports": [
                                {
                                    "Id": "BCR",
                                    "Name": "Boca Do Acre",
                                    "CityId": "BCRA",
                                    "CountryId": "BR",
                                    "Location": "-67.383333, -8.75"
                                }
                            ]
                        },
                        {
                            "Id": "PMWA",
                            "Name": "Palmas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.360278, -10.212778",
                            "IataCode": "PMW",
                            "Airports": [
                                {
                                    "Id": "PMW",
                                    "Name": "Palmas",
                                    "CityId": "PMWA",
                                    "CountryId": "BR",
                                    "Location": "-48.354934, -10.294072"
                                }
                            ]
                        },
                        {
                            "Id": "POOA",
                            "Name": "Pocos De Caldas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.561389, -21.787778",
                            "IataCode": "POO",
                            "Airports": [
                                {
                                    "Id": "POO",
                                    "Name": "Pocos De Caldas",
                                    "CityId": "POOA",
                                    "CountryId": "BR",
                                    "Location": "-46.5675, -21.841389"
                                }
                            ]
                        },
                        {
                            "Id": "STZA",
                            "Name": "Santa Terezinha",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.503056, -10.469722",
                            "IataCode": "STZ",
                            "Airports": [
                                {
                                    "Id": "STZ",
                                    "Name": "Santa Terezinha",
                                    "CityId": "STZA",
                                    "CountryId": "BR",
                                    "Location": "-50.51861, -10.46472"
                                }
                            ]
                        },
                        {
                            "Id": "TFLA",
                            "Name": "Teofilo Otoni",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.505278, -17.8575",
                            "IataCode": "TFL",
                            "Airports": [
                                {
                                    "Id": "TFL",
                                    "Name": "Teofilo Otoni",
                                    "CityId": "TFLA",
                                    "CountryId": "BR",
                                    "Location": "-41.5, -17.85"
                                }
                            ]
                        },
                        {
                            "Id": "GGFA",
                            "Name": "Almeirim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.581667, -1.5233333",
                            "IataCode": "GGF",
                            "Airports": [
                                {
                                    "Id": "GGF",
                                    "Name": "Almeirim Airport",
                                    "CityId": "GGFA",
                                    "CountryId": "BR",
                                    "Location": "-52.578056, -1.479167"
                                }
                            ]
                        },
                        {
                            "Id": "RVDA",
                            "Name": "Rio Verde",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.928056, -17.798056",
                            "IataCode": "RVD",
                            "Airports": [
                                {
                                    "Id": "RVD",
                                    "Name": "Rio Verde",
                                    "CityId": "RVDA",
                                    "CountryId": "BR",
                                    "Location": "-50.955488, -17.83326"
                                }
                            ]
                        },
                        {
                            "Id": "VAGA",
                            "Name": "Varginha",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.430278, -21.551389",
                            "IataCode": "VAG",
                            "Airports": [
                                {
                                    "Id": "VAG",
                                    "Name": "Varginha",
                                    "CityId": "VAGA",
                                    "CountryId": "BR",
                                    "Location": "-45.433333, -21.55"
                                }
                            ]
                        },
                        {
                            "Id": "ITQA",
                            "Name": "Itaqui",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.553056, -29.125278",
                            "IataCode": "ITQ",
                            "Airports": [
                                {
                                    "Id": "ITQ",
                                    "Name": "Itaqui",
                                    "CityId": "ITQA",
                                    "CountryId": "BR",
                                    "Location": "-56.55, -29.133333"
                                }
                            ]
                        },
                        {
                            "Id": "ILBA",
                            "Name": "Ilha Solteira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.3425, -20.432778",
                            "IataCode": "ILB",
                            "Airports": [
                                {
                                    "Id": "ILB",
                                    "Name": "Ilha Solteira",
                                    "CityId": "ILBA",
                                    "CountryId": "BR",
                                    "Location": "-51.333333, -20.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MABA",
                            "Name": "Maraba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.117778, -5.3686111",
                            "IataCode": "MAB",
                            "Airports": [
                                {
                                    "Id": "MAB",
                                    "Name": "Maraba",
                                    "CityId": "MABA",
                                    "CountryId": "BR",
                                    "Location": "-49.166667, -5.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MBKA",
                            "Name": "Matupa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.932778, -10.0575",
                            "IataCode": "MBK",
                            "Airports": [
                                {
                                    "Id": "MBK",
                                    "Name": "Matupa",
                                    "CityId": "MBKA",
                                    "CountryId": "BR",
                                    "Location": "-54.932778, -10.0575"
                                }
                            ]
                        },
                        {
                            "Id": "MBZA",
                            "Name": "Maues",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.718611, -3.3836111",
                            "IataCode": "MBZ",
                            "Airports": [
                                {
                                    "Id": "MBZ",
                                    "Name": "Maues",
                                    "CityId": "MBZA",
                                    "CountryId": "BR",
                                    "Location": "-57.7, -3.4"
                                }
                            ]
                        },
                        {
                            "Id": "MIIA",
                            "Name": "Marilia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.945833, -22.213889",
                            "IataCode": "MII",
                            "Airports": [
                                {
                                    "Id": "MII",
                                    "Name": "Marilia",
                                    "CityId": "MIIA",
                                    "CountryId": "BR",
                                    "Location": "-49.933333, -22.2"
                                }
                            ]
                        },
                        {
                            "Id": "MNXA",
                            "Name": "Manicore",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-61.300278, -5.8091667",
                            "IataCode": "MNX",
                            "Airports": [
                                {
                                    "Id": "MNX",
                                    "Name": "Manicore",
                                    "CityId": "MNXA",
                                    "CountryId": "BR",
                                    "Location": "-61.283333, -5.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MOCA",
                            "Name": "Montes Claros",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.861667, -16.735",
                            "IataCode": "MOC",
                            "Airports": [
                                {
                                    "Id": "MOC",
                                    "Name": "Montes Claros",
                                    "CityId": "MOCA",
                                    "CountryId": "BR",
                                    "Location": "-43.817222, -16.707778"
                                }
                            ]
                        },
                        {
                            "Id": "MTEA",
                            "Name": "Monte Alegre",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.069167, -2.0077778",
                            "IataCode": "MTE",
                            "Airports": [
                                {
                                    "Id": "MTE",
                                    "Name": "Monte Alegre",
                                    "CityId": "MTEA",
                                    "CountryId": "BR",
                                    "Location": "-54.066668, -1.983333"
                                }
                            ]
                        },
                        {
                            "Id": "MVSA",
                            "Name": "Mucuri",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.550833, -18.086389",
                            "IataCode": "MVS",
                            "Airports": [
                                {
                                    "Id": "MVS",
                                    "Name": "Mucuri",
                                    "CityId": "MVSA",
                                    "CountryId": "BR",
                                    "Location": "-39.566667, -18.083333"
                                }
                            ]
                        },
                        {
                            "Id": "NNUA",
                            "Name": "Nanuque",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.353889, -17.839167",
                            "IataCode": "NNU",
                            "Airports": [
                                {
                                    "Id": "NNU",
                                    "Name": "Nanuque",
                                    "CityId": "NNUA",
                                    "CountryId": "BR",
                                    "Location": "-40.333333, -17.816667"
                                }
                            ]
                        },
                        {
                            "Id": "NOKA",
                            "Name": "Nova Xavantina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.353056, -14.673333",
                            "IataCode": "NOK",
                            "Airports": [
                                {
                                    "Id": "NOK",
                                    "Name": "Nova Xavantina",
                                    "CityId": "NOKA",
                                    "CountryId": "BR",
                                    "Location": "-52.348611, -14.690278"
                                }
                            ]
                        },
                        {
                            "Id": "NQLA",
                            "Name": "Niquelandia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.459722, -14.473889",
                            "IataCode": "NQL",
                            "Airports": [
                                {
                                    "Id": "NQL",
                                    "Name": "Niquelandia",
                                    "CityId": "NQLA",
                                    "CountryId": "BR",
                                    "Location": "-48.983333, -14"
                                }
                            ]
                        },
                        {
                            "Id": "NTMA",
                            "Name": "Miracema Do Norte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.391667, -9.5672222",
                            "IataCode": "NTM",
                            "Airports": [
                                {
                                    "Id": "NTM",
                                    "Name": "Miracema Do Norte",
                                    "CityId": "NTMA",
                                    "CountryId": "BR",
                                    "Location": "-48.5, -9.183333"
                                }
                            ]
                        },
                        {
                            "Id": "NVPA",
                            "Name": "Novo Aripuana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.379722, -5.1205556",
                            "IataCode": "NVP",
                            "Airports": [
                                {
                                    "Id": "NVP",
                                    "Name": "Novo Aripuana",
                                    "CityId": "NVPA",
                                    "CountryId": "BR",
                                    "Location": "-60.366667, -5.133333"
                                }
                            ]
                        },
                        {
                            "Id": "OPSA",
                            "Name": "Sinop",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.5025, -11.864167",
                            "IataCode": "OPS",
                            "Airports": [
                                {
                                    "Id": "OPS",
                                    "Name": "Sinop",
                                    "CityId": "OPSA",
                                    "CountryId": "BR",
                                    "Location": "-55.58611, -11.885"
                                }
                            ]
                        },
                        {
                            "Id": "ORXA",
                            "Name": "Oriximina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.866111, -1.7655556",
                            "IataCode": "ORX",
                            "Airports": [
                                {
                                    "Id": "ORX",
                                    "Name": "Oriximina",
                                    "CityId": "ORXA",
                                    "CountryId": "BR",
                                    "Location": "-55.866668, -1.75"
                                }
                            ]
                        },
                        {
                            "Id": "OUSA",
                            "Name": "Ourinhos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.870556, -22.978889",
                            "IataCode": "OUS",
                            "Airports": [
                                {
                                    "Id": "OUS",
                                    "Name": "Ourinhos",
                                    "CityId": "OUSA",
                                    "CountryId": "BR",
                                    "Location": "-49.916667, -22.966667"
                                }
                            ]
                        },
                        {
                            "Id": "PAVA",
                            "Name": "Paulo Afonso",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.214722, -9.4061111",
                            "IataCode": "PAV",
                            "Airports": [
                                {
                                    "Id": "PAV",
                                    "Name": "Paulo Afonso",
                                    "CityId": "PAVA",
                                    "CountryId": "BR",
                                    "Location": "-38.222222, -9.397778"
                                }
                            ]
                        },
                        {
                            "Id": "PBQA",
                            "Name": "Pimenta Bueno",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-61.193611, -11.6725",
                            "IataCode": "PBQ",
                            "Airports": [
                                {
                                    "Id": "PBQ",
                                    "Name": "Pimenta Bueno",
                                    "CityId": "PBQA",
                                    "CountryId": "BR",
                                    "Location": "-61.2, -11.7"
                                }
                            ]
                        },
                        {
                            "Id": "PCSA",
                            "Name": "Picos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.466944, -7.0769444",
                            "IataCode": "PCS",
                            "Airports": [
                                {
                                    "Id": "PCS",
                                    "Name": "Picos",
                                    "CityId": "PCSA",
                                    "CountryId": "BR",
                                    "Location": "-41.5237, -7.06206"
                                }
                            ]
                        },
                        {
                            "Id": "PDFA",
                            "Name": "Prado",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.220833, -17.341111",
                            "IataCode": "PDF",
                            "Airports": [
                                {
                                    "Id": "PDF",
                                    "Name": "Prado",
                                    "CityId": "PDFA",
                                    "CountryId": "BR",
                                    "Location": "-39.216667, -17.35"
                                }
                            ]
                        },
                        {
                            "Id": "PHIA",
                            "Name": "Pinheiro",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.0825, -2.5213889",
                            "IataCode": "PHI",
                            "Airports": [
                                {
                                    "Id": "PHI",
                                    "Name": "Pinheiro",
                                    "CityId": "PHIA",
                                    "CountryId": "BR",
                                    "Location": "-45.083333, -2.516667"
                                }
                            ]
                        },
                        {
                            "Id": "PIVA",
                            "Name": "Pirapora",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.941944, -17.345",
                            "IataCode": "PIV",
                            "Airports": [
                                {
                                    "Id": "PIV",
                                    "Name": "Pirapora",
                                    "CityId": "PIVA",
                                    "CountryId": "BR",
                                    "Location": "-44.932222, -17.338611"
                                }
                            ]
                        },
                        {
                            "Id": "PNBA",
                            "Name": "Porto Nacional",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.417222, -10.708056",
                            "IataCode": "PNB",
                            "Airports": [
                                {
                                    "Id": "PNB",
                                    "Name": "Porto Nacional",
                                    "CityId": "PNBA",
                                    "CountryId": "BR",
                                    "Location": "-48.416667, -10.7"
                                }
                            ]
                        },
                        {
                            "Id": "PNGA",
                            "Name": "Paranagua",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.509167, -25.52",
                            "IataCode": "PNG",
                            "Airports": [
                                {
                                    "Id": "PNG",
                                    "Name": "Paranagua",
                                    "CityId": "PNGA",
                                    "CountryId": "BR",
                                    "Location": "-48.5, -25.516667"
                                }
                            ]
                        },
                        {
                            "Id": "PPYA",
                            "Name": "Pouso Alegre",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.936389, -22.23",
                            "IataCode": "PPY",
                            "Airports": [
                                {
                                    "Id": "PPY",
                                    "Name": "Pouso Alegre",
                                    "CityId": "PPYA",
                                    "CountryId": "BR",
                                    "Location": "-45.918889, -22.288333"
                                }
                            ]
                        },
                        {
                            "Id": "PSWA",
                            "Name": "Passos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.609722, -20.718889",
                            "IataCode": "PSW",
                            "Airports": [
                                {
                                    "Id": "PSW",
                                    "Name": "Passos",
                                    "CityId": "PSWA",
                                    "CountryId": "BR",
                                    "Location": "-46.616667, -20.716667"
                                }
                            ]
                        },
                        {
                            "Id": "PTOA",
                            "Name": "Pato Branco",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.670555, -26.228611",
                            "IataCode": "PTO",
                            "Airports": [
                                {
                                    "Id": "PTO",
                                    "Name": "Pato Branco",
                                    "CityId": "PTOA",
                                    "CountryId": "BR",
                                    "Location": "-52.666667, -26.216667"
                                }
                            ]
                        },
                        {
                            "Id": "PVIA",
                            "Name": "Paranavai",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.465278, -23.073056",
                            "IataCode": "PVI",
                            "Airports": [
                                {
                                    "Id": "PVI",
                                    "Name": "Paranavai",
                                    "CityId": "PVIA",
                                    "CountryId": "BR",
                                    "Location": "-52.466667, -23.066667"
                                }
                            ]
                        },
                        {
                            "Id": "RDCA",
                            "Name": "Redencao",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.031389, -8.0286111",
                            "IataCode": "RDC",
                            "Airports": [
                                {
                                    "Id": "RDC",
                                    "Name": "Redencao",
                                    "CityId": "RDCA",
                                    "CountryId": "BR",
                                    "Location": "-49.98, -8.030278"
                                }
                            ]
                        },
                        {
                            "Id": "ROOA",
                            "Name": "Rondonopolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.635556, -16.470833",
                            "IataCode": "ROO",
                            "Airports": [
                                {
                                    "Id": "ROO",
                                    "Name": "Rondonopolis",
                                    "CityId": "ROOA",
                                    "CountryId": "BR",
                                    "Location": "-54.716667, -16.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SBJA",
                            "Name": "Sao Mateus",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.858889, -18.716111",
                            "IataCode": "SBJ",
                            "Airports": [
                                {
                                    "Id": "SBJ",
                                    "Name": "Sao Mateus",
                                    "CityId": "SBJA",
                                    "CountryId": "BR",
                                    "Location": "-39.733333, -18.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SEIA",
                            "Name": "Senhor Do Bonfim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.189444, -10.461389",
                            "IataCode": "SEI",
                            "Airports": [
                                {
                                    "Id": "SEI",
                                    "Name": "Senhor Do Bonfim",
                                    "CityId": "SEIA",
                                    "CountryId": "BR",
                                    "Location": "-40.183333, -10.45"
                                }
                            ]
                        },
                        {
                            "Id": "SFVA",
                            "Name": "Santa Fe Do Sul",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.925833, -20.211111",
                            "IataCode": "SFV",
                            "Airports": [
                                {
                                    "Id": "SFV",
                                    "Name": "Santa Fe Do Sul",
                                    "CityId": "SFVA",
                                    "CountryId": "BR",
                                    "Location": "-50.166667, -19.833333"
                                }
                            ]
                        },
                        {
                            "Id": "SJLA",
                            "Name": "Sao Gabriel",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-67.089167, -0.13027778",
                            "IataCode": "SJL",
                            "Airports": [
                                {
                                    "Id": "SJL",
                                    "Name": "Sao Gabriel",
                                    "CityId": "SJLA",
                                    "CountryId": "BR",
                                    "Location": "-67.082176, -0.119133"
                                }
                            ]
                        },
                        {
                            "Id": "SRAA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.481389, -27.870833",
                            "IataCode": "SRA",
                            "Airports": [
                                {
                                    "Id": "SRA",
                                    "Name": "Santa Rosa",
                                    "CityId": "SRAA",
                                    "CountryId": "BR",
                                    "Location": "-54.5204, -27.9067"
                                }
                            ]
                        },
                        {
                            "Id": "SSOA",
                            "Name": "Sao Lourenco",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.054444, -22.116389",
                            "IataCode": "SSO",
                            "Airports": [
                                {
                                    "Id": "SSO",
                                    "Name": "Sao Lourenco",
                                    "CityId": "SSOA",
                                    "CountryId": "BR",
                                    "Location": "-45.05, -22.116667"
                                }
                            ]
                        },
                        {
                            "Id": "TECA",
                            "Name": "Telemaco Borba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.615556, -24.323889",
                            "IataCode": "TEC",
                            "Airports": [
                                {
                                    "Id": "TEC",
                                    "Name": "Telemaco Borba",
                                    "CityId": "TECA",
                                    "CountryId": "BR",
                                    "Location": "-50.651667, -24.315833"
                                }
                            ]
                        },
                        {
                            "Id": "TLZA",
                            "Name": "Catalao",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.946389, -18.165833",
                            "IataCode": "TLZ",
                            "Airports": [
                                {
                                    "Id": "TLZ",
                                    "Name": "Catalao",
                                    "CityId": "TLZA",
                                    "CountryId": "BR",
                                    "Location": "-47.95, -18.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TOWA",
                            "Name": "Toledo",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.743055, -24.713611",
                            "IataCode": "TOW",
                            "Airports": [
                                {
                                    "Id": "TOW",
                                    "Name": "Toledo",
                                    "CityId": "TOWA",
                                    "CountryId": "BR",
                                    "Location": "-53.696111, -24.698611"
                                }
                            ]
                        },
                        {
                            "Id": "TRQA",
                            "Name": "Tarauaca",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-70.765556, -8.1613889",
                            "IataCode": "TRQ",
                            "Airports": [
                                {
                                    "Id": "TRQ",
                                    "Name": "Tarauaca",
                                    "CityId": "TRQA",
                                    "CountryId": "BR",
                                    "Location": "-70.75, -8.1"
                                }
                            ]
                        },
                        {
                            "Id": "TURA",
                            "Name": "Tucurui",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.6725, -3.7661111",
                            "IataCode": "TUR",
                            "Airports": [
                                {
                                    "Id": "TUR",
                                    "Name": "Tucurui",
                                    "CityId": "TURA",
                                    "CountryId": "BR",
                                    "Location": "-49.733333, -3.7"
                                }
                            ]
                        },
                        {
                            "Id": "UMUA",
                            "Name": "Umuarama",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.325, -23.766389",
                            "IataCode": "UMU",
                            "Airports": [
                                {
                                    "Id": "UMU",
                                    "Name": "Umuarama",
                                    "CityId": "UMUA",
                                    "CountryId": "BR",
                                    "Location": "-53.313333, -23.798333"
                                }
                            ]
                        },
                        {
                            "Id": "UNAA",
                            "Name": "Una",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.075278, -15.293333",
                            "IataCode": "UNA",
                            "Airports": [
                                {
                                    "Id": "UNA",
                                    "Name": "Una",
                                    "CityId": "UNAA",
                                    "CountryId": "BR",
                                    "Location": "-38.997222, -15.353333"
                                }
                            ]
                        },
                        {
                            "Id": "VALA",
                            "Name": "Valenca",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.073056, -13.370278",
                            "IataCode": "VAL",
                            "Airports": [
                                {
                                    "Id": "VAL",
                                    "Name": "Valenca",
                                    "CityId": "VALA",
                                    "CountryId": "BR",
                                    "Location": "-39.083333, -13.333333"
                                }
                            ]
                        },
                        {
                            "Id": "VDCA",
                            "Name": "Vitoria Da Conquista",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.839444, -14.866111",
                            "IataCode": "VDC",
                            "Airports": [
                                {
                                    "Id": "VDC",
                                    "Name": "Vitoria Da Conquista",
                                    "CityId": "VDCA",
                                    "CountryId": "BR",
                                    "Location": "-40.866667, -14.883333"
                                }
                            ]
                        },
                        {
                            "Id": "VOTA",
                            "Name": "Votuporanga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.972778, -20.422778",
                            "IataCode": "VOT",
                            "Airports": [
                                {
                                    "Id": "VOT",
                                    "Name": "Votuporanga",
                                    "CityId": "VOTA",
                                    "CountryId": "BR",
                                    "Location": "-49.883333, -20.433333"
                                }
                            ]
                        },
                        {
                            "Id": "UBTA",
                            "Name": "Ubatuba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.071111, -23.433889",
                            "IataCode": "UBT",
                            "Airports": [
                                {
                                    "Id": "UBT",
                                    "Name": "Ubatuba",
                                    "CityId": "UBTA",
                                    "CountryId": "BR",
                                    "Location": "-45.083333, -23.433333"
                                }
                            ]
                        },
                        {
                            "Id": "CTPA",
                            "Name": "Carutapera",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.02, -1.195",
                            "IataCode": "CTP",
                            "Airports": [
                                {
                                    "Id": "CTP",
                                    "Name": "Carutapera",
                                    "CityId": "CTPA",
                                    "CountryId": "BR",
                                    "Location": "-46.016667, -1.25"
                                }
                            ]
                        },
                        {
                            "Id": "GDRA",
                            "Name": "Angra dos Reis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.318056, -23.006667",
                            "IataCode": "GDR",
                            "Airports": [
                                {
                                    "Id": "GDR",
                                    "Name": "Angra dos Reis Airport",
                                    "CityId": "GDRA",
                                    "CountryId": "BR",
                                    "Location": "-44.307222, -22.975278"
                                }
                            ]
                        },
                        {
                            "Id": "RAOA",
                            "Name": "Ribeirao Preto",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.810277, -21.1775",
                            "IataCode": "RAO",
                            "Airports": [
                                {
                                    "Id": "RAO",
                                    "Name": "Ribeirao Preto",
                                    "CityId": "RAOA",
                                    "CountryId": "BR",
                                    "Location": "-47.773333, -21.135833"
                                }
                            ]
                        },
                        {
                            "Id": "CNVA",
                            "Name": "Canavieiras",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.947222, -15.675",
                            "IataCode": "CNV",
                            "Airports": [
                                {
                                    "Id": "CNV",
                                    "Name": "Canavieiras",
                                    "CityId": "CNVA",
                                    "CountryId": "BR",
                                    "Location": "-38.983333, -15.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LECB",
                            "Name": "Lencois",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.39, -12.563056",
                            "IataCode": "LEC",
                            "Airports": [
                                {
                                    "Id": "LEC",
                                    "Name": "Lencois Chapada Diamantina",
                                    "CityId": "LECB",
                                    "CountryId": "BR",
                                    "Location": "-41.281111, -12.48"
                                }
                            ]
                        },
                        {
                            "Id": "PGZA",
                            "Name": "Ponta Grossa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.161945, -25.095",
                            "IataCode": "PGZ",
                            "Airports": [
                                {
                                    "Id": "PGZ",
                                    "Name": "Ponta Grossa",
                                    "CityId": "PGZA",
                                    "CountryId": "BR",
                                    "Location": "-50.15, -25.083333"
                                }
                            ]
                        },
                        {
                            "Id": "VIXA",
                            "Name": "Vitoria",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.337778, -20.319444",
                            "IataCode": "VIX",
                            "Airports": [
                                {
                                    "Id": "VIX",
                                    "Name": "Vitoria",
                                    "CityId": "VIXA",
                                    "CountryId": "BR",
                                    "Location": "-40.2875, -20.264444"
                                }
                            ]
                        },
                        {
                            "Id": "QDLA",
                            "Name": "Guarapari",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.4975, -20.666667",
                            "IataCode": "QDL",
                            "Airports": [
                                {
                                    "Id": "GUZ",
                                    "Name": "Guarapari",
                                    "CityId": "QDLA",
                                    "CountryId": "BR",
                                    "Location": "-40.491667, -20.65"
                                }
                            ]
                        },
                        {
                            "Id": "IPNA",
                            "Name": "Ipatinga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-42.536667, -19.468333",
                            "IataCode": "IPN",
                            "Airports": [
                                {
                                    "Id": "IPN",
                                    "Name": "Ipatinga",
                                    "CityId": "IPNA",
                                    "CountryId": "BR",
                                    "Location": "-42.533333, -19.5"
                                }
                            ]
                        },
                        {
                            "Id": "BYOA",
                            "Name": "Bonito",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.481944, -21.121111",
                            "IataCode": "BYO",
                            "Airports": [
                                {
                                    "Id": "BYO",
                                    "Name": "Bonito",
                                    "CityId": "BYOA",
                                    "CountryId": "BR",
                                    "Location": "-56.456111, -21.229444"
                                }
                            ]
                        },
                        {
                            "Id": "QDVA",
                            "Name": "Jundiaí",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.884167, -23.186389",
                            "IataCode": "QDV",
                            "Airports": [
                                {
                                    "Id": "QDV",
                                    "Name": "Jundiaí Airport",
                                    "CityId": "QDVA",
                                    "CountryId": "BR",
                                    "Location": "-46.943611, -23.181667"
                                }
                            ]
                        },
                        {
                            "Id": "GCVA",
                            "Name": "Gravatai",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.991944, -29.944445",
                            "IataCode": "GCV",
                            "Airports": [
                                {
                                    "Id": "GCV",
                                    "Name": "Gravatai",
                                    "CityId": "GCVA",
                                    "CountryId": "BR",
                                    "Location": "-50.994167, -29.941667"
                                }
                            ]
                        },
                        {
                            "Id": "CDIA",
                            "Name": "Cachoeiro Itapemirim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.112778, -20.848889",
                            "IataCode": "CDI",
                            "Airports": [
                                {
                                    "Id": "CDI",
                                    "Name": "Cachoeiro Itapemirim",
                                    "CityId": "CDIA",
                                    "CountryId": "BR",
                                    "Location": "-41.186944, -20.836111"
                                }
                            ]
                        },
                        {
                            "Id": "JPRA",
                            "Name": "Ji-Parana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-61.951667, -10.885278",
                            "IataCode": "JPR",
                            "Airports": [
                                {
                                    "Id": "JPR",
                                    "Name": "Ji-Parana",
                                    "CityId": "JPRA",
                                    "CountryId": "BR",
                                    "Location": "-61.860452, -10.866561"
                                }
                            ]
                        },
                        {
                            "Id": "TSQA",
                            "Name": "Torres",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.726944, -29.335278",
                            "IataCode": "TSQ",
                            "Airports": [
                                {
                                    "Id": "TSQ",
                                    "Name": "Torres",
                                    "CityId": "TSQA",
                                    "CountryId": "BR",
                                    "Location": "-49.746111, -29.3325"
                                }
                            ]
                        },
                        {
                            "Id": "ARXB",
                            "Name": "Aracati",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-37.769722, -4.5616667",
                            "IataCode": "ARX",
                            "Airports": [
                                {
                                    "Id": "ARX",
                                    "Name": "Dragao do Mar",
                                    "CityId": "ARXB",
                                    "CountryId": "BR",
                                    "Location": "-37.8082, -4.568951"
                                }
                            ]
                        },
                        {
                            "Id": "REZA",
                            "Name": "Resende",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.446667, -22.468889",
                            "IataCode": "REZ",
                            "Airports": [
                                {
                                    "Id": "REZ",
                                    "Name": "Resende",
                                    "CityId": "REZA",
                                    "CountryId": "BR",
                                    "Location": "-44.481111, -22.478056"
                                }
                            ]
                        },
                        {
                            "Id": "SFKA",
                            "Name": "Soure",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.523333, -0.71666667",
                            "IataCode": "SFK",
                            "Airports": [
                                {
                                    "Id": "SFK",
                                    "Name": "Soure",
                                    "CityId": "SFKA",
                                    "CountryId": "BR",
                                    "Location": "-48.522222, -0.7"
                                }
                            ]
                        },
                        {
                            "Id": "BJPA",
                            "Name": "Braganca Paulista",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.541944, -22.951944",
                            "IataCode": "BJP",
                            "Airports": [
                                {
                                    "Id": "BJP",
                                    "Name": "Braganca Paulista",
                                    "CityId": "BJPA",
                                    "CountryId": "BR",
                                    "Location": "-46.566667, -22.95"
                                }
                            ]
                        },
                        {
                            "Id": "LVRA",
                            "Name": "Lucas do Rio Verde",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.911111, -13.050278",
                            "IataCode": "LVR",
                            "Airports": [
                                {
                                    "Id": "LVR",
                                    "Name": "Lucas do Rio Verde Airport",
                                    "CityId": "LVRA",
                                    "CountryId": "BR",
                                    "Location": "-55.942222, -13.037222"
                                }
                            ]
                        },
                        {
                            "Id": "TGQA",
                            "Name": "Tangara da Serra",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.485833, -14.619444",
                            "IataCode": "TGQ",
                            "Airports": [
                                {
                                    "Id": "TGQ",
                                    "Name": "Tangara da Serra",
                                    "CityId": "TGQA",
                                    "CountryId": "BR",
                                    "Location": "-57.448611, -14.656389"
                                }
                            ]
                        },
                        {
                            "Id": "MTGA",
                            "Name": "Mato Grosso",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.919266, -12.949598",
                            "IataCode": "MTG",
                            "Airports": [
                                {
                                    "Id": "MTG",
                                    "Name": "Mato Grosso",
                                    "CityId": "MTGA",
                                    "CountryId": "BR",
                                    "Location": "-55, -14"
                                }
                            ]
                        },
                        {
                            "Id": "JCRA",
                            "Name": "Jacareacanga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.302165, -7.4483866",
                            "IataCode": "JCR",
                            "Airports": [
                                {
                                    "Id": "JCR",
                                    "Name": "Jacareacanga",
                                    "CityId": "JCRA",
                                    "CountryId": "BR",
                                    "Location": "-57.533334, -5.983333"
                                }
                            ]
                        },
                        {
                            "Id": "NPRA",
                            "Name": "Novo Progresso",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.612073, -8.0621463",
                            "IataCode": "NPR",
                            "Airports": [
                                {
                                    "Id": "NPR",
                                    "Name": "Novo Progresso",
                                    "CityId": "NPRA",
                                    "CountryId": "BR",
                                    "Location": "-55.400833, -7.125833"
                                }
                            ]
                        },
                        {
                            "Id": "OBIA",
                            "Name": "Obidos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.673534, -0.14958564",
                            "IataCode": "OBI",
                            "Airports": [
                                {
                                    "Id": "OBI",
                                    "Name": "Obidos",
                                    "CityId": "OBIA",
                                    "CountryId": "BR",
                                    "Location": "-55.516668, -1.916667"
                                }
                            ]
                        },
                        {
                            "Id": "JDRA",
                            "Name": "Sao Joao Del Rei",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.282153, -21.260244",
                            "IataCode": "JDR",
                            "Airports": [
                                {
                                    "Id": "JDR",
                                    "Name": "Sao Joao Del Rei",
                                    "CityId": "JDRA",
                                    "CountryId": "BR",
                                    "Location": "-44.228, -21.08718"
                                }
                            ]
                        },
                        {
                            "Id": "JRNA",
                            "Name": "Juruena",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-58.598486, -10.327251",
                            "IataCode": "JRN",
                            "Airports": [
                                {
                                    "Id": "JRN",
                                    "Name": "Juruena",
                                    "CityId": "JRNA",
                                    "CountryId": "BR",
                                    "Location": "-58.488889, -10.305556"
                                }
                            ]
                        },
                        {
                            "Id": "SETB",
                            "Name": "Serra Talhada",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.298333, -7.9919444",
                            "IataCode": "SET",
                            "Airports": [
                                {
                                    "Id": "SET",
                                    "Name": "Serra Talhada",
                                    "CityId": "SETB",
                                    "CountryId": "BR",
                                    "Location": "-38.328611, -8.061389"
                                }
                            ]
                        },
                        {
                            "Id": "XOEA",
                            "Name": "Sao Jose",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.9, -3.0333333",
                            "IataCode": "XOE",
                            "Airports": [
                                {
                                    "Id": "XOE",
                                    "Name": "Sao Jose",
                                    "CityId": "XOEA",
                                    "CountryId": "BR",
                                    "Location": "-44.066671, -2.63333"
                                }
                            ]
                        },
                        {
                            "Id": "SXXA",
                            "Name": "Sao Felix Do Xingu",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.995, -6.6447222",
                            "IataCode": "SXX",
                            "Airports": [
                                {
                                    "Id": "SXX",
                                    "Name": "Sao Felix Do Xingu",
                                    "CityId": "SXXA",
                                    "CountryId": "BR",
                                    "Location": "-51.983333, -6.633333"
                                }
                            ]
                        },
                        {
                            "Id": "PDRA",
                            "Name": "Presidente Dutra",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-44.49, -5.29",
                            "IataCode": "PDR",
                            "Airports": [
                                {
                                    "Id": "PDR",
                                    "Name": "Presidente Dutra",
                                    "CityId": "PDRA",
                                    "CountryId": "BR",
                                    "Location": "-44.480278, -5.311111"
                                }
                            ]
                        },
                        {
                            "Id": "PTQA",
                            "Name": "Porto de Moz",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.238333, -1.7483333",
                            "IataCode": "PTQ",
                            "Airports": [
                                {
                                    "Id": "PTQ",
                                    "Name": "Porto de Moz",
                                    "CityId": "PTQA",
                                    "CountryId": "BR",
                                    "Location": "-52.244444, -1.738889"
                                }
                            ]
                        },
                        {
                            "Id": "JRTA",
                            "Name": "Juruti",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-56.092222, -2.1522222",
                            "IataCode": "JRT",
                            "Airports": [
                                {
                                    "Id": "JRT",
                                    "Name": "Juruti Airport",
                                    "CityId": "JRTA",
                                    "CountryId": "BR",
                                    "Location": "-56.090278, -2.186667"
                                }
                            ]
                        },
                        {
                            "Id": "ITAA",
                            "Name": "Itacoatiara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-58.444167, -3.1430556",
                            "IataCode": "ITA",
                            "Airports": [
                                {
                                    "Id": "ITA",
                                    "Name": "Itacoatiara",
                                    "CityId": "ITAA",
                                    "CountryId": "BR",
                                    "Location": "-58.416667, -3.133333"
                                }
                            ]
                        },
                        {
                            "Id": "JJDA",
                            "Name": "Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-40.171667, -2.9177778",
                            "IataCode": "JJD",
                            "Airports": [
                                {
                                    "Id": "JJD",
                                    "Name": "Jericoacoara",
                                    "CityId": "JJDA",
                                    "CountryId": "BR",
                                    "Location": "-40.358055, -2.906666"
                                }
                            ]
                        },
                        {
                            "Id": "BRBA",
                            "Name": "Barreirinhas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.07, -2.7933333",
                            "IataCode": "BRB",
                            "Airports": [
                                {
                                    "Id": "BRB",
                                    "Name": "Barreirinhas",
                                    "CityId": "BRBA",
                                    "CountryId": "BR",
                                    "Location": "-57.111111, -2.910626"
                                }
                            ]
                        },
                        {
                            "Id": "BDCA",
                            "Name": "Barra Do Corda",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.243333, -5.5055556",
                            "IataCode": "BDC",
                            "Airports": [
                                {
                                    "Id": "BDC",
                                    "Name": "Barra Do Corda",
                                    "CityId": "BDCA",
                                    "CountryId": "BR",
                                    "Location": "-45.266667, -5.466667"
                                }
                            ]
                        },
                        {
                            "Id": "AXEA",
                            "Name": "Xanxere",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.404167, -26.876944",
                            "IataCode": "AXE",
                            "Airports": [
                                {
                                    "Id": "AXE",
                                    "Name": "Xanxere",
                                    "CityId": "AXEA",
                                    "CountryId": "BR",
                                    "Location": "-52.383333, -26.883333"
                                }
                            ]
                        },
                        {
                            "Id": "TJLA",
                            "Name": "Três Lagoas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.678333, -20.751111",
                            "IataCode": "TJL",
                            "Airports": [
                                {
                                    "Id": "TJL",
                                    "Name": "Três Lagoas",
                                    "CityId": "TJLA",
                                    "CountryId": "BR",
                                    "Location": "-51.683889, -20.754444"
                                }
                            ]
                        },
                        {
                            "Id": "XHRA",
                            "Name": "Timbo",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.271667, -26.823333",
                            "IataCode": "XHR",
                            "Airports": [
                                {
                                    "Id": "XHR",
                                    "Name": "Timbo",
                                    "CityId": "XHRA",
                                    "CountryId": "BR",
                                    "Location": "-49.271671, -26.82333"
                                }
                            ]
                        },
                        {
                            "Id": "RWSA",
                            "Name": "Sumare",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-47.266944, -22.821944",
                            "IataCode": "RWS",
                            "Airports": [
                                {
                                    "Id": "RWS",
                                    "Name": "Sumare",
                                    "CityId": "RWSA",
                                    "CountryId": "BR",
                                    "Location": "-47.192222, -22.800556"
                                }
                            ]
                        },
                        {
                            "Id": "SQXA",
                            "Name": "Sao Miguel Do Oeste",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.518056, -26.725278",
                            "IataCode": "SQX",
                            "Airports": [
                                {
                                    "Id": "SQX",
                                    "Name": "Sao Miguel Do Oeste",
                                    "CityId": "SQXA",
                                    "CountryId": "BR",
                                    "Location": "-53.502778, -26.780556"
                                }
                            ]
                        },
                        {
                            "Id": "SQMA",
                            "Name": "Sao Miguel Araguaia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.162778, -13.275",
                            "IataCode": "SQM",
                            "Airports": [
                                {
                                    "Id": "SQM",
                                    "Name": "Sao Miguel Araguaia",
                                    "CityId": "SQMA",
                                    "CountryId": "BR",
                                    "Location": "-50.216667, -13.316667"
                                }
                            ]
                        },
                        {
                            "Id": "SQYA",
                            "Name": "Sao Lourenco Do Sul",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.978333, -31.365278",
                            "IataCode": "SQY",
                            "Airports": [
                                {
                                    "Id": "SQY",
                                    "Name": "Sao Lourenco Do Sul",
                                    "CityId": "SQYA",
                                    "CountryId": "BR",
                                    "Location": "-52.032222, -31.382778"
                                }
                            ]
                        },
                        {
                            "Id": "XGOA",
                            "Name": "Santiago",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.867222, -29.191667",
                            "IataCode": "XGO",
                            "Airports": [
                                {
                                    "Id": "XGO",
                                    "Name": "Santiago",
                                    "CityId": "XGOA",
                                    "CountryId": "BR",
                                    "Location": "-54.866671, -29.18333"
                                }
                            ]
                        },
                        {
                            "Id": "CTQA",
                            "Name": "Santa Vitoria",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.121389, -18.838611",
                            "IataCode": "CTQ",
                            "Airports": [
                                {
                                    "Id": "CTQ",
                                    "Name": "Santa Vitoria",
                                    "CityId": "CTQA",
                                    "CountryId": "BR",
                                    "Location": "-50.1, -18.85"
                                }
                            ]
                        },
                        {
                            "Id": "CSUA",
                            "Name": "Santa Cruz Do Sul",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.425833, -29.7175",
                            "IataCode": "CSU",
                            "Airports": [
                                {
                                    "Id": "CSU",
                                    "Name": "Santa Cruz Do Sul",
                                    "CityId": "CSUA",
                                    "CountryId": "BR",
                                    "Location": "-52.416667, -29.683333"
                                }
                            ]
                        },
                        {
                            "Id": "PBVA",
                            "Name": "Porto Dos Gauchos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-57.414444, -11.535278",
                            "IataCode": "PBV",
                            "Airports": [
                                {
                                    "Id": "PBV",
                                    "Name": "Porto Dos Gauchos",
                                    "CityId": "PBVA",
                                    "CountryId": "BR",
                                    "Location": "-57.333333, -11.516667"
                                }
                            ]
                        },
                        {
                            "Id": "PMGA",
                            "Name": "Ponta Pora",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.725556, -22.536111",
                            "IataCode": "PMG",
                            "Airports": [
                                {
                                    "Id": "PMG",
                                    "Name": "Ponta Pora",
                                    "CityId": "PMGA",
                                    "CountryId": "BR",
                                    "Location": "-55.7, -22.55"
                                }
                            ]
                        },
                        {
                            "Id": "LOIA",
                            "Name": "Lontras",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.541944, -27.166111",
                            "IataCode": "LOI",
                            "Airports": [
                                {
                                    "Id": "LOI",
                                    "Name": "Lontras",
                                    "CityId": "LOIA",
                                    "CountryId": "BR",
                                    "Location": "-49.542778, -27.159722"
                                }
                            ]
                        },
                        {
                            "Id": "LVBA",
                            "Name": "Livramento",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.840556, -13.643056",
                            "IataCode": "LVB",
                            "Airports": [
                                {
                                    "Id": "LVB",
                                    "Name": "Livramento",
                                    "CityId": "LVBA",
                                    "CountryId": "BR",
                                    "Location": "-41.838889, -13.655556"
                                }
                            ]
                        },
                        {
                            "Id": "JTIA",
                            "Name": "Jatai",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.714444, -17.881389",
                            "IataCode": "JTI",
                            "Airports": [
                                {
                                    "Id": "JTI",
                                    "Name": "Jatai",
                                    "CityId": "JTIA",
                                    "CountryId": "BR",
                                    "Location": "-51.774722, -17.829444"
                                }
                            ]
                        },
                        {
                            "Id": "JLSA",
                            "Name": "Jales",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.545833, -20.268889",
                            "IataCode": "JLS",
                            "Airports": [
                                {
                                    "Id": "JLS",
                                    "Name": "Jales",
                                    "CityId": "JLSA",
                                    "CountryId": "BR",
                                    "Location": "-50.55, -20.166667"
                                }
                            ]
                        },
                        {
                            "Id": "JJGA",
                            "Name": "Jaguaruna",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.025556, -28.615",
                            "IataCode": "JJG",
                            "Airports": [
                                {
                                    "Id": "JJG",
                                    "Name": "Humberto Bortoluzzi",
                                    "CityId": "JJGA",
                                    "CountryId": "BR",
                                    "Location": "-49.060278, -28.675278"
                                }
                            ]
                        },
                        {
                            "Id": "ITRA",
                            "Name": "Itumbiara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.215278, -18.419167",
                            "IataCode": "ITR",
                            "Airports": [
                                {
                                    "Id": "ITR",
                                    "Name": "Itumbiara",
                                    "CityId": "ITRA",
                                    "CountryId": "BR",
                                    "Location": "-49.214167, -18.445"
                                }
                            ]
                        },
                        {
                            "Id": "ITPA",
                            "Name": "Itaperuna",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.887778, -21.205",
                            "IataCode": "ITP",
                            "Airports": [
                                {
                                    "Id": "ITP",
                                    "Name": "Itaperuna",
                                    "CityId": "ITPA",
                                    "CountryId": "BR",
                                    "Location": "-41.875, -21.218889"
                                }
                            ]
                        },
                        {
                            "Id": "HRZA",
                            "Name": "Horizontina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.307778, -27.625833",
                            "IataCode": "HRZ",
                            "Airports": [
                                {
                                    "Id": "HRZ",
                                    "Name": "Horizontina",
                                    "CityId": "HRZA",
                                    "CountryId": "BR",
                                    "Location": "-54.340556, -27.638333"
                                }
                            ]
                        },
                        {
                            "Id": "FECA",
                            "Name": "Feira De Santana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-38.966667, -12.266667",
                            "IataCode": "FEC",
                            "Airports": [
                                {
                                    "Id": "FEC",
                                    "Name": "Feira De Santana",
                                    "CityId": "FECA",
                                    "CountryId": "BR",
                                    "Location": "-38.906111, -12.2025"
                                }
                            ]
                        },
                        {
                            "Id": "XISA",
                            "Name": "Dionisio Cerqueira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.639722, -26.255",
                            "IataCode": "XIS",
                            "Airports": [
                                {
                                    "Id": "XIS",
                                    "Name": "Dionisio Cerqueira",
                                    "CityId": "XISA",
                                    "CountryId": "BR",
                                    "Location": "-53.600001, -26.25"
                                }
                            ]
                        },
                        {
                            "Id": "DTIA",
                            "Name": "Diamantina",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.600278, -18.249444",
                            "IataCode": "DTI",
                            "Airports": [
                                {
                                    "Id": "DTI",
                                    "Name": "Diamantina",
                                    "CityId": "DTIA",
                                    "CountryId": "BR",
                                    "Location": "-43.650278, -18.231944"
                                }
                            ]
                        },
                        {
                            "Id": "CFCA",
                            "Name": "Cacador",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.015, -26.775278",
                            "IataCode": "CFC",
                            "Airports": [
                                {
                                    "Id": "CFC",
                                    "Name": "Cacador",
                                    "CityId": "CFCA",
                                    "CountryId": "BR",
                                    "Location": "-50.941389, -26.790556"
                                }
                            ]
                        },
                        {
                            "Id": "XRBA",
                            "Name": "Ararangua",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.485833, -28.934722",
                            "IataCode": "XRB",
                            "Airports": [
                                {
                                    "Id": "XRB",
                                    "Name": "Ararangua",
                                    "CityId": "XRBA",
                                    "CountryId": "BR",
                                    "Location": "-49.500001, -29"
                                }
                            ]
                        },
                        {
                            "Id": "SNZA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.2, -22.816667",
                            "IataCode": "SNZ",
                            "Airports": [
                                {
                                    "Id": "SNZ",
                                    "Name": "Santa Cruz",
                                    "CityId": "SNZA",
                                    "CountryId": "BR",
                                    "Location": "-43.920593, -22.925512"
                                }
                            ]
                        },
                        {
                            "Id": "TFFA",
                            "Name": "Tefe",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-64.711389, -3.3541667",
                            "IataCode": "TFF",
                            "Airports": [
                                {
                                    "Id": "TFF",
                                    "Name": "Tefe",
                                    "CityId": "TFFA",
                                    "CountryId": "BR",
                                    "Location": "-64.7, -3.366667"
                                }
                            ]
                        },
                        {
                            "Id": "SPDO",
                            "Name": "São Paulo de Olivença",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-68.8725, -3.3783333",
                            "IataCode": "OLC",
                            "Airports": [
                                {
                                    "Id": "OLC",
                                    "Name": "São Paulo de Olivença",
                                    "CityId": "SPDO",
                                    "CountryId": "BR",
                                    "Location": "-68.918889, -3.465556"
                                }
                            ]
                        },
                        {
                            "Id": "IRZN",
                            "Name": "Santa Isabel do Rio Negro",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-65.019167, -0.41388889",
                            "IataCode": "IRZ",
                            "Airports": [
                                {
                                    "Id": "IRZ",
                                    "Name": "Santa Isabel do Rio Negro",
                                    "CityId": "IRZN",
                                    "CountryId": "BR",
                                    "Location": "-65.033889, -0.416944"
                                }
                            ]
                        },
                        {
                            "Id": "IPGA",
                            "Name": "Ipiranga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-66.016667, -3.2",
                            "IataCode": "IPG",
                            "Airports": [
                                {
                                    "Id": "IPG",
                                    "Name": "Ipiranga",
                                    "CityId": "IPGA",
                                    "CountryId": "BR",
                                    "Location": "-65.95, -3.216667"
                                }
                            ]
                        },
                        {
                            "Id": "HMIT",
                            "Name": "Humaitá",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-63.020833, -7.5061111",
                            "IataCode": "HUW",
                            "Airports": [
                                {
                                    "Id": "HUW",
                                    "Name": "Humaitá",
                                    "CityId": "HMIT",
                                    "CountryId": "BR",
                                    "Location": "-63.072222, -7.53222"
                                }
                            ]
                        },
                        {
                            "Id": "FBAA",
                            "Name": "Fonte Boa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-66.091667, -2.5138889",
                            "IataCode": "FBA",
                            "Airports": [
                                {
                                    "Id": "FBA",
                                    "Name": "Fonte Boa",
                                    "CityId": "FBAA",
                                    "CountryId": "BR",
                                    "Location": "-66.083333, -2.5325"
                                }
                            ]
                        },
                        {
                            "Id": "FEJA",
                            "Name": "Feijo",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-70.353611, -8.1641667",
                            "IataCode": "FEJ",
                            "Airports": [
                                {
                                    "Id": "FEJ",
                                    "Name": "Feijo",
                                    "CityId": "FEJA",
                                    "CountryId": "BR",
                                    "Location": "-70.35, -8.15"
                                }
                            ]
                        },
                        {
                            "Id": "GJMA",
                            "Name": "Guajará Mirim",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-65.339444, -10.782778",
                            "IataCode": "GJM",
                            "Airports": [
                                {
                                    "Id": "GJM",
                                    "Name": "Guajara-Mirim",
                                    "CityId": "GJMA",
                                    "CountryId": "BR",
                                    "Location": "-65.2848, -10.7864"
                                }
                            ]
                        },
                        {
                            "Id": "OALA",
                            "Name": "Cacoal",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-61.447222, -11.438611",
                            "IataCode": "OAL",
                            "Airports": [
                                {
                                    "Id": "OAL",
                                    "Name": "Cacoal",
                                    "CityId": "OALA",
                                    "CountryId": "BR",
                                    "Location": "-61.450608, -11.491223"
                                }
                            ]
                        },
                        {
                            "Id": "GGBA",
                            "Name": "Água Boa",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.158611, -14.05",
                            "IataCode": "GGB",
                            "Airports": [
                                {
                                    "Id": "GGB",
                                    "Name": "Água Boa Airport",
                                    "CityId": "GGBA",
                                    "CountryId": "BR",
                                    "Location": "-52.152222, -14.019444"
                                }
                            ]
                        },
                        {
                            "Id": "CQAA",
                            "Name": "Canarana",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.268333, -13.552222",
                            "IataCode": "CQA",
                            "Airports": [
                                {
                                    "Id": "CQA",
                                    "Name": "Canarana",
                                    "CityId": "CQAA",
                                    "CountryId": "BR",
                                    "Location": "-52.270278, -13.574167"
                                }
                            ]
                        },
                        {
                            "Id": "CFOA",
                            "Name": "Confreza",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.568889, -10.643889",
                            "IataCode": "CFO",
                            "Airports": [
                                {
                                    "Id": "CFO",
                                    "Name": "Confreza",
                                    "CityId": "CFOA",
                                    "CountryId": "BR",
                                    "Location": "-51.566667, -10.633333"
                                }
                            ]
                        },
                        {
                            "Id": "FENA",
                            "Name": "Fernando De Noronha",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-32.410833, -3.8402778",
                            "IataCode": "FEN",
                            "Airports": [
                                {
                                    "Id": "FEN",
                                    "Name": "Fernando De Noronha",
                                    "CityId": "FENA",
                                    "CountryId": "BR",
                                    "Location": "-32.416667, -3.85"
                                }
                            ]
                        },
                        {
                            "Id": "GDPA",
                            "Name": "Guadalupe",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-43.569167, -6.7869444",
                            "IataCode": "GDP",
                            "Airports": [
                                {
                                    "Id": "GDP",
                                    "Name": "Guadalupe",
                                    "CityId": "GDPA",
                                    "CountryId": "BR",
                                    "Location": "-43.581667, -6.781944"
                                }
                            ]
                        },
                        {
                            "Id": "MQHA",
                            "Name": "Minacu",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-48.22, -13.533056",
                            "IataCode": "MQH",
                            "Airports": [
                                {
                                    "Id": "MQH",
                                    "Name": "Minacu",
                                    "CityId": "MQHA",
                                    "CountryId": "BR",
                                    "Location": "-48.233333, -13.533333"
                                }
                            ]
                        },
                        {
                            "Id": "PBXA",
                            "Name": "Porto Alegre Do Norte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.6325, -10.876944",
                            "IataCode": "PBX",
                            "Airports": [
                                {
                                    "Id": "PBX",
                                    "Name": "Porto Alegre Do Norte",
                                    "CityId": "PBXA",
                                    "CountryId": "BR",
                                    "Location": "-51.684444, -10.860833"
                                }
                            ]
                        },
                        {
                            "Id": "SXOA",
                            "Name": "Sao Felix Do Araguaia",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.669444, -11.617222",
                            "IataCode": "SXO",
                            "Airports": [
                                {
                                    "Id": "SXO",
                                    "Name": "Sao Felix Do Araguaia",
                                    "CityId": "SXOA",
                                    "CountryId": "BR",
                                    "Location": "-50.65, -11.6"
                                }
                            ]
                        },
                        {
                            "Id": "SMTB",
                            "Name": "Sorriso",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.711389, -12.545278",
                            "IataCode": "SMT",
                            "Airports": [
                                {
                                    "Id": "SMT",
                                    "Name": "Sorriso",
                                    "CityId": "SMTB",
                                    "CountryId": "BR",
                                    "Location": "-55.673523, -12.480559"
                                }
                            ]
                        },
                        {
                            "Id": "TXFA",
                            "Name": "Teixeira de Freitas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-39.741944, -17.535",
                            "IataCode": "TXF",
                            "Airports": [
                                {
                                    "Id": "TXF",
                                    "Name": "Teixeira de Freitas",
                                    "CityId": "TXFA",
                                    "CountryId": "BR",
                                    "Location": "-37.183333, -7.133333"
                                }
                            ]
                        },
                        {
                            "Id": "VLPA",
                            "Name": "Vila Rica",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.116389, -10.011667",
                            "IataCode": "VLP",
                            "Airports": [
                                {
                                    "Id": "VLP",
                                    "Name": "Vila Rica",
                                    "CityId": "VLPA",
                                    "CountryId": "BR",
                                    "Location": "-51.122222, -10.015556"
                                }
                            ]
                        },
                        {
                            "Id": "XIGA",
                            "Name": "Xinguara",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.945833, -7.095",
                            "IataCode": "XIG",
                            "Airports": [
                                {
                                    "Id": "XIG",
                                    "Name": "Xinguara",
                                    "CityId": "XIGA",
                                    "CountryId": "BR",
                                    "Location": "-48.8, -6.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MEUA",
                            "Name": "Monte Dourado",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-52.52833, -0.86667",
                            "IataCode": "MEU",
                            "Airports": [
                                {
                                    "Id": "MEU",
                                    "Name": "Monte Dourado",
                                    "CityId": "MEUA",
                                    "CountryId": "BR",
                                    "Location": "-52.583333, -0.883333"
                                }
                            ]
                        },
                        {
                            "Id": "QPTA",
                            "Name": "Patos De Minas",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-46.51806, -18.57889",
                            "IataCode": "QPT",
                            "Airports": [
                                {
                                    "Id": "POJ",
                                    "Name": "Patos De Minas",
                                    "CityId": "QPTA",
                                    "CountryId": "BR",
                                    "Location": "-46.490833, -18.671667"
                                }
                            ]
                        },
                        {
                            "Id": "IGUA",
                            "Name": "Foz do Iguaçu",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-54.483333, -25.6",
                            "IataCode": "IGU",
                            "Airports": [
                                {
                                    "Id": "IGU",
                                    "Name": "Iguassu Falls",
                                    "CityId": "IGUA",
                                    "CountryId": "BR",
                                    "Location": "-54.483333, -25.6"
                                }
                            ]
                        },
                        {
                            "Id": "PBBA",
                            "Name": "Santa Isabel",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-41.8, -2.8833333",
                            "IataCode": "PBB",
                            "Airports": [
                                {
                                    "Id": "PBB",
                                    "Name": "Paranaiba",
                                    "CityId": "PBBA",
                                    "CountryId": "BR",
                                    "Location": "-41.875, -2.99084"
                                }
                            ]
                        },
                        {
                            "Id": "TMTA",
                            "Name": "Ábidos",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.518056, -1.9175",
                            "IataCode": "TMT",
                            "Airports": [
                                {
                                    "Id": "TMT",
                                    "Name": "Trombetas",
                                    "CityId": "TMTA",
                                    "CountryId": "BR",
                                    "Location": "-56.3968, -1.4896"
                                }
                            ]
                        },
                        {
                            "Id": "PLLA",
                            "Name": "Nova Vida",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-59.916667, -3.2",
                            "IataCode": "PLL",
                            "Airports": [
                                {
                                    "Id": "PLL",
                                    "Name": "Ponta Pelada",
                                    "CityId": "PLLA",
                                    "CountryId": "BR",
                                    "Location": "-59.984444, -3.145556"
                                }
                            ]
                        },
                        {
                            "Id": "CMPA",
                            "Name": "Belo Horizonte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-36.333333, -9.8",
                            "IataCode": "CMP",
                            "Airports": [
                                {
                                    "Id": "CMP",
                                    "Name": "Santana Do Araguaia",
                                    "CityId": "CMPA",
                                    "CountryId": "BR",
                                    "Location": "-36.35, -9.8"
                                }
                            ]
                        },
                        {
                            "Id": "TUZA",
                            "Name": "Santa Estela",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-50.466667, -21.866667",
                            "IataCode": "TUZ",
                            "Airports": [
                                {
                                    "Id": "TUZ",
                                    "Name": "Tucuma",
                                    "CityId": "TUZA",
                                    "CountryId": "BR",
                                    "Location": "-50.5, -21.883333"
                                }
                            ]
                        },
                        {
                            "Id": "PIGA",
                            "Name": "Pitanga",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.761389, -24.757222",
                            "IataCode": "PIG",
                            "Airports": [
                                {
                                    "Id": "PIG",
                                    "Name": "Pitinga",
                                    "CityId": "PIGA",
                                    "CountryId": "BR",
                                    "Location": "-51.716667, -24.75"
                                }
                            ]
                        },
                        {
                            "Id": "URBA",
                            "Name": "Junqueira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.55, -20.833333",
                            "IataCode": "URB",
                            "Airports": [
                                {
                                    "Id": "URB",
                                    "Name": "Urubupunga",
                                    "CityId": "URBA",
                                    "CountryId": "BR",
                                    "Location": "-51.566667, -20.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SWMA",
                            "Name": "Campo de Diauarum",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-53.233333, -11.2",
                            "IataCode": "SWM",
                            "Airports": [
                                {
                                    "Id": "SWM",
                                    "Name": "Suia-Missu",
                                    "CityId": "SWMA",
                                    "CountryId": "BR",
                                    "Location": "-53.25, -11.216667"
                                }
                            ]
                        },
                        {
                            "Id": "AUBB",
                            "Name": "Porto de Itatuba",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-63.410556, -5.6833333",
                            "IataCode": "AUB",
                            "Airports": [
                                {
                                    "Id": "AUB",
                                    "Name": "Itauba",
                                    "CityId": "AUBB",
                                    "CountryId": "BR",
                                    "Location": "-63.35, -5.76"
                                }
                            ]
                        },
                        {
                            "Id": "LCBA",
                            "Name": "São Luis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-60.033333, -13.083333",
                            "IataCode": "LCB",
                            "Airports": [
                                {
                                    "Id": "LCB",
                                    "Name": "Pontes e Lacerda",
                                    "CityId": "LCBA",
                                    "CountryId": "BR",
                                    "Location": "-59.998611, -13.013333"
                                }
                            ]
                        },
                        {
                            "Id": "RRNA",
                            "Name": "Castanheira",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-58.6025, -11.1325",
                            "IataCode": "RRN",
                            "Airports": [
                                {
                                    "Id": "RRN",
                                    "Name": "Serra Norte",
                                    "CityId": "RRNA",
                                    "CountryId": "BR",
                                    "Location": "-59, -11"
                                }
                            ]
                        },
                        {
                            "Id": "RSGA",
                            "Name": "Curionópolis",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-49.598056, -6.1016667",
                            "IataCode": "RSG",
                            "Airports": [
                                {
                                    "Id": "RSG",
                                    "Name": "Serra Pelada",
                                    "CityId": "RSGA",
                                    "CountryId": "BR",
                                    "Location": "-49.65, -5.95"
                                }
                            ]
                        },
                        {
                            "Id": "PGGA",
                            "Name": "Novo Progresso",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-55.381111, -7.1477778",
                            "IataCode": "PGG",
                            "Airports": [
                                {
                                    "Id": "PGG",
                                    "Name": "Progresso",
                                    "CityId": "PGGA",
                                    "CountryId": "BR",
                                    "Location": "-55.461111, -6.954167"
                                }
                            ]
                        },
                        {
                            "Id": "OIAA",
                            "Name": "Ourilândia do Norte",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-51.083889, -6.7547222",
                            "IataCode": "OIA",
                            "Airports": [
                                {
                                    "Id": "OIA",
                                    "Name": "Ourilandia",
                                    "CityId": "OIAA",
                                    "CountryId": "BR",
                                    "Location": "-51.06, -6.775833"
                                }
                            ]
                        },
                        {
                            "Id": "NBVA",
                            "Name": "Canabrava",
                            "SingleAirportCity": true,
                            "CountryId": "BR",
                            "Location": "-45.83139, -17.39056",
                            "IataCode": "NBV",
                            "Airports": [
                                {
                                    "Id": "NBV",
                                    "Name": "Cana Brava",
                                    "CityId": "NBVA",
                                    "CountryId": "BR",
                                    "Location": "-45.866667, -17.383333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "FK",
                    "Name": "Falkland Islands",
                    "CurrencyId": "FKP",
                    "Cities": [
                        {
                            "Id": "MPNA",
                            "Name": "Stanley",
                            "SingleAirportCity": true,
                            "CountryId": "FK",
                            "Location": "-57.85, -51.7",
                            "IataCode": "MPN",
                            "Airports": [
                                {
                                    "Id": "MPN",
                                    "Name": "Stanley",
                                    "CityId": "MPNA",
                                    "CountryId": "FK",
                                    "Location": "-58.45, -51.816667"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "E",
            "Name": "Europe",
            "Countries": [
                {
                    "Id": "CY",
                    "Name": "Cyprus",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "AKTA",
                            "Name": "Akrotiri",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "32.958333, 34.604167",
                            "IataCode": "AKT",
                            "Airports": [
                                {
                                    "Id": "AKT",
                                    "Name": "Akrotiri",
                                    "CityId": "AKTA",
                                    "CountryId": "CY",
                                    "Location": "32.983333, 34.583333"
                                }
                            ]
                        },
                        {
                            "Id": "GECI",
                            "Name": "Gecitkale",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "33.733333, 35.258333",
                            "IataCode": "GEC",
                            "Airports": [
                                {
                                    "Id": "GEC",
                                    "Name": "Gecitkale",
                                    "CityId": "GECI",
                                    "CountryId": "CY",
                                    "Location": "33.716667, 35.233333"
                                }
                            ]
                        },
                        {
                            "Id": "EPIS",
                            "Name": "Episkopi",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "32.9, 34.666667",
                            "IataCode": "EPK",
                            "Airports": [
                                {
                                    "Id": "EPK",
                                    "Name": "Episkopi",
                                    "CityId": "EPIS",
                                    "CountryId": "CY",
                                    "Location": "32.9, 34.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LARN",
                            "Name": "Larnaca",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "33.619822, 34.900881",
                            "IataCode": "LCA",
                            "Airports": [
                                {
                                    "Id": "LCA",
                                    "Name": "Larnaca",
                                    "CityId": "LARN",
                                    "CountryId": "CY",
                                    "Location": "33.62033, 34.872507"
                                }
                            ]
                        },
                        {
                            "Id": "PAPH",
                            "Name": "Paphos",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "32.428116, 34.775178",
                            "IataCode": "PFO",
                            "Airports": [
                                {
                                    "Id": "PFO",
                                    "Name": "Paphos",
                                    "CityId": "PAPH",
                                    "CountryId": "CY",
                                    "Location": "32.483939, 34.717517"
                                }
                            ]
                        },
                        {
                            "Id": "NICA",
                            "Name": "Nicosia",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "33.366667, 35.166667",
                            "IataCode": "NIC",
                            "Airports": [
                                {
                                    "Id": "NIC",
                                    "Name": "Nicosia",
                                    "CityId": "NICA",
                                    "CountryId": "CY",
                                    "Location": "33.272222, 35.151111"
                                }
                            ]
                        },
                        {
                            "Id": "ERCA",
                            "Name": "Tymbou",
                            "SingleAirportCity": true,
                            "CountryId": "CY",
                            "Location": "33.4898, 35.1589",
                            "IataCode": "ECN",
                            "Airports": [
                                {
                                    "Id": "ECN",
                                    "Name": "Ercan",
                                    "CityId": "ERCA",
                                    "CountryId": "CY",
                                    "Location": "33.4898, 35.1589"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ME",
                    "Name": "Montenegro",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "IVAP",
                            "Name": "Ivangrad",
                            "SingleAirportCity": true,
                            "CountryId": "ME",
                            "Location": "19.873333, 42.8425",
                            "IataCode": "IVG",
                            "Airports": [
                                {
                                    "Id": "IVG",
                                    "Name": "Ivangrad",
                                    "CityId": "IVAP",
                                    "CountryId": "ME",
                                    "Location": "19.866667, 42.85"
                                }
                            ]
                        },
                        {
                            "Id": "PODG",
                            "Name": "Podgorica",
                            "SingleAirportCity": true,
                            "CountryId": "ME",
                            "Location": "19.263611, 42.441113",
                            "IataCode": "TGD",
                            "Airports": [
                                {
                                    "Id": "TGD",
                                    "Name": "Podgorica",
                                    "CityId": "PODG",
                                    "CountryId": "ME",
                                    "Location": "19.251944, 42.358333"
                                }
                            ]
                        },
                        {
                            "Id": "TIVA",
                            "Name": "Tivat",
                            "SingleAirportCity": true,
                            "CountryId": "ME",
                            "Location": "18.696111, 42.436389",
                            "IataCode": "TIV",
                            "Airports": [
                                {
                                    "Id": "TIV",
                                    "Name": "Tivat",
                                    "CityId": "TIVA",
                                    "CountryId": "ME",
                                    "Location": "18.725556, 42.403611"
                                }
                            ]
                        },
                        {
                            "Id": "HNOA",
                            "Name": "Herceg Novi",
                            "SingleAirportCity": true,
                            "CountryId": "ME",
                            "Location": "18.5375, 42.453056",
                            "IataCode": "HNO",
                            "Airports": [
                                {
                                    "Id": "HNO",
                                    "Name": "Herceg Novi",
                                    "CityId": "HNOA",
                                    "CountryId": "ME",
                                    "Location": "18.55, 42.5"
                                }
                            ]
                        },
                        {
                            "Id": "ZABL",
                            "Name": "Zabljak",
                            "SingleAirportCity": true,
                            "CountryId": "ME",
                            "Location": "19.195101, 43.101108",
                            "IataCode": "ZBK",
                            "Airports": [
                                {
                                    "Id": "ZBK",
                                    "Name": "Zabljak",
                                    "CityId": "ZABL",
                                    "CountryId": "ME",
                                    "Location": "19.116667, 43.15"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MK",
                    "Name": "North Macedonia",
                    "CurrencyId": "MKD",
                    "Cities": [
                        {
                            "Id": "OHRI",
                            "Name": "Ohrid",
                            "SingleAirportCity": true,
                            "CountryId": "MK",
                            "Location": "20.801944, 41.117222",
                            "IataCode": "OHD",
                            "Airports": [
                                {
                                    "Id": "OHD",
                                    "Name": "Ohrid",
                                    "CityId": "OHRI",
                                    "CountryId": "MK",
                                    "Location": "20.743056, 41.185"
                                }
                            ]
                        },
                        {
                            "Id": "SKOP",
                            "Name": "Skopje",
                            "SingleAirportCity": true,
                            "CountryId": "MK",
                            "Location": "21.430268, 42.002795",
                            "IataCode": "SKP",
                            "Airports": [
                                {
                                    "Id": "SKP",
                                    "Name": "Skopje",
                                    "CityId": "SKOP",
                                    "CountryId": "MK",
                                    "Location": "21.633333, 41.966667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GI",
                    "Name": "Gibraltar",
                    "CurrencyId": "GIP",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "GIBR",
                            "Name": "Gibraltar",
                            "SingleAirportCity": true,
                            "CountryId": "GI",
                            "Location": "-5.3499999, 36.133333",
                            "IataCode": "GIB",
                            "Airports": [
                                {
                                    "Id": "GIB",
                                    "Name": "Gibraltar",
                                    "CityId": "GIBR",
                                    "CountryId": "GI",
                                    "Location": "-5.347222, 36.15"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "RU",
                    "Name": "Russia",
                    "CurrencyId": "RUB",
                    "LanguageId": "RU",
                    "Cities": [
                        {
                            "Id": "ANAP",
                            "Name": "Anapa",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "37.32, 44.89",
                            "IataCode": "AAQ",
                            "Airports": [
                                {
                                    "Id": "AAQ",
                                    "Name": "Anapa",
                                    "CityId": "ANAP",
                                    "CountryId": "RU",
                                    "Location": "37.347272, 45.002097"
                                }
                            ]
                        },
                        {
                            "Id": "ACHI",
                            "Name": "Achinsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "90.4993, 56.269402",
                            "IataCode": "ACS",
                            "Airports": [
                                {
                                    "Id": "ACS",
                                    "Name": "Achinsk",
                                    "CityId": "ACHI",
                                    "CountryId": "RU",
                                    "Location": "90.566667, 56.266667"
                                }
                            ]
                        },
                        {
                            "Id": "ALDA",
                            "Name": "Aldan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "125.38, 58.6",
                            "IataCode": "ADH",
                            "Airports": [
                                {
                                    "Id": "ADH",
                                    "Name": "Aldan",
                                    "CityId": "ALDA",
                                    "CountryId": "RU",
                                    "Location": "125.4, 58.6"
                                }
                            ]
                        },
                        {
                            "Id": "ADLE",
                            "Name": "Sochi",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.730278, 43.6",
                            "IataCode": "AER",
                            "Airports": [
                                {
                                    "Id": "AER",
                                    "Name": "Sochi",
                                    "CityId": "ADLE",
                                    "CountryId": "RU",
                                    "Location": "39.9333, 43.45"
                                }
                            ]
                        },
                        {
                            "Id": "AMDE",
                            "Name": "Amderma",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "61.6655, 69.7576",
                            "IataCode": "AMV",
                            "Airports": [
                                {
                                    "Id": "AMV",
                                    "Name": "Amderma",
                                    "CityId": "AMDE",
                                    "CountryId": "RU",
                                    "Location": "61.55, 69.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YAKU",
                            "Name": "Yakutsk",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "129.73306, 62.033892",
                            "IataCode": "YKS",
                            "Airports": [
                                {
                                    "Id": "YKS",
                                    "Name": "Yakutsk",
                                    "CityId": "YAKU",
                                    "CountryId": "RU",
                                    "Location": "129.77067, 62.09325"
                                },
                                {
                                    "Id": "GYG",
                                    "Name": "Magan",
                                    "CityId": "YAKU",
                                    "CountryId": "RU",
                                    "Location": "129.55944, 62.107222"
                                }
                            ]
                        },
                        {
                            "Id": "BELO",
                            "Name": "Beloreck",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "58.39, 53.96",
                            "IataCode": "BCX",
                            "Airports": [
                                {
                                    "Id": "BCX",
                                    "Name": "Beloreck",
                                    "CityId": "BELO",
                                    "CountryId": "RU",
                                    "Location": "58.333333, 53.933333"
                                }
                            ]
                        },
                        {
                            "Id": "CERV",
                            "Name": "Cherskiy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "161.3, 68.75",
                            "IataCode": "CYX",
                            "Airports": [
                                {
                                    "Id": "CYX",
                                    "Name": "Cherskiy",
                                    "CityId": "CERV",
                                    "CountryId": "RU",
                                    "Location": "161.35, 68.75"
                                }
                            ]
                        },
                        {
                            "Id": "BRIA",
                            "Name": "Briansk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "34.380555, 53.2875",
                            "IataCode": "BZK",
                            "Airports": [
                                {
                                    "Id": "BZK",
                                    "Name": "Briansk",
                                    "CityId": "BRIA",
                                    "CountryId": "RU",
                                    "Location": "34.333333, 53.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MOSC",
                            "Name": "Moscow",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "37.363523, 55.559192",
                            "IataCode": "MOW",
                            "Airports": [
                                {
                                    "Id": "VKO",
                                    "Name": "Moscow Vnukovo",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "37.266667, 55.583333"
                                },
                                {
                                    "Id": "SVO",
                                    "Name": "Moscow Sheremetyevo",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "37.414722, 55.972778"
                                },
                                {
                                    "Id": "DME",
                                    "Name": "Moscow Domodedovo",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "37.8833, 55.4"
                                },
                                {
                                    "Id": "OSF",
                                    "Name": "Moscow Ostafyevo",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "37.507222, 55.511667"
                                },
                                {
                                    "Id": "ZIA",
                                    "Name": "Moscow Zhukovsky",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "38.151667, 55.553333"
                                },
                                {
                                    "Id": "BKA",
                                    "Name": "Moscow Bykovo",
                                    "CityId": "MOSC",
                                    "CountryId": "RU",
                                    "Location": "38.056389, 55.620556"
                                }
                            ]
                        },
                        {
                            "Id": "BRAT",
                            "Name": "Bratsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "101.61417, 56.132503",
                            "IataCode": "BTK",
                            "Airports": [
                                {
                                    "Id": "BTK",
                                    "Name": "Bratsk",
                                    "CityId": "BRAT",
                                    "CountryId": "RU",
                                    "Location": "101.69833, 56.370556"
                                }
                            ]
                        },
                        {
                            "Id": "CHER",
                            "Name": "Cherepovets",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "37.9, 59.133333",
                            "IataCode": "CEE",
                            "Airports": [
                                {
                                    "Id": "CEE",
                                    "Name": "Cherepovets",
                                    "CityId": "CHER",
                                    "CountryId": "RU",
                                    "Location": "38.017502, 59.274828"
                                }
                            ]
                        },
                        {
                            "Id": "CHEL",
                            "Name": "Chelyabinsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "61.429722, 55.154448",
                            "IataCode": "CEK",
                            "Airports": [
                                {
                                    "Id": "CEK",
                                    "Name": "Chelyabinsk",
                                    "CityId": "CHEL",
                                    "CountryId": "RU",
                                    "Location": "61.5, 55.3"
                                }
                            ]
                        },
                        {
                            "Id": "CHEB",
                            "Name": "Cheboksary",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "47.251943, 56.132222",
                            "IataCode": "CSY",
                            "Airports": [
                                {
                                    "Id": "CSY",
                                    "Name": "Cheboksary",
                                    "CityId": "CHEB",
                                    "CountryId": "RU",
                                    "Location": "47.3473, 56.0903"
                                }
                            ]
                        },
                        {
                            "Id": "ABAK",
                            "Name": "Abakan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "91.42, 53.71",
                            "IataCode": "ABA",
                            "Airports": [
                                {
                                    "Id": "ABA",
                                    "Name": "Abakan",
                                    "CityId": "ABAK",
                                    "CountryId": "RU",
                                    "Location": "91.5, 53.716667"
                                }
                            ]
                        },
                        {
                            "Id": "DIKS",
                            "Name": "Dikson",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "80.546389, 73.506944",
                            "IataCode": "DKS",
                            "Airports": [
                                {
                                    "Id": "DKS",
                                    "Name": "Dikson",
                                    "CityId": "DIKS",
                                    "CountryId": "RU",
                                    "Location": "80.366668, 73.516667"
                                }
                            ]
                        },
                        {
                            "Id": "ANAD",
                            "Name": "Anadyr",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "177.48, 64.75",
                            "IataCode": "DYR",
                            "Airports": [
                                {
                                    "Id": "DYR",
                                    "Name": "Anadyr",
                                    "CityId": "ANAD",
                                    "CountryId": "RU",
                                    "Location": "177.75, 64.733333"
                                }
                            ]
                        },
                        {
                            "Id": "BELH",
                            "Name": "Belgorod",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "36.58, 50.610001",
                            "IataCode": "EGO",
                            "Airports": [
                                {
                                    "Id": "EGO",
                                    "Name": "Belgorod",
                                    "CityId": "BELH",
                                    "CountryId": "RU",
                                    "Location": "36.5901, 50.6438"
                                }
                            ]
                        },
                        {
                            "Id": "ENIS",
                            "Name": "Eniseysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "92.170278, 58.449722",
                            "IataCode": "EIE",
                            "Airports": [
                                {
                                    "Id": "EIE",
                                    "Name": "Eniseysk",
                                    "CityId": "ENIS",
                                    "CountryId": "RU",
                                    "Location": "92.116667, 58.466667"
                                }
                            ]
                        },
                        {
                            "Id": "ELIS",
                            "Name": "Elista",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "44.255833, 46.307778",
                            "IataCode": "ESL",
                            "Airports": [
                                {
                                    "Id": "ESL",
                                    "Name": "Elista",
                                    "CityId": "ELIS",
                                    "CountryId": "RU",
                                    "Location": "44.333333, 46.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MAGD",
                            "Name": "Magdagachi",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "125.8, 53.45",
                            "IataCode": "GDG",
                            "Airports": [
                                {
                                    "Id": "GDG",
                                    "Name": "Magdagachi",
                                    "CityId": "MAGD",
                                    "CountryId": "RU",
                                    "Location": "125.8, 53.466667"
                                }
                            ]
                        },
                        {
                            "Id": "MAGA",
                            "Name": "Magadan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "150.8, 59.566667",
                            "IataCode": "GDX",
                            "Airports": [
                                {
                                    "Id": "GDX",
                                    "Name": "Magadan",
                                    "CityId": "MAGA",
                                    "CountryId": "RU",
                                    "Location": "150.71667, 59.916667"
                                }
                            ]
                        },
                        {
                            "Id": "GELE",
                            "Name": "Gelendzik",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.074722, 44.573056",
                            "IataCode": "GDZ",
                            "Airports": [
                                {
                                    "Id": "GDZ",
                                    "Name": "Gelendzik",
                                    "CityId": "GELE",
                                    "CountryId": "RU",
                                    "Location": "38.016667, 44.566667"
                                }
                            ]
                        },
                        {
                            "Id": "NIZH",
                            "Name": "Nizhniy Novgorod",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "44.002047, 56.328678",
                            "IataCode": "GOJ",
                            "Airports": [
                                {
                                    "Id": "GOJ",
                                    "Name": "Nizhniy Novgorod",
                                    "CityId": "NIZH",
                                    "CountryId": "RU",
                                    "Location": "43.784042, 56.230119"
                                }
                            ]
                        },
                        {
                            "Id": "GROZ",
                            "Name": "Grozny",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "45.700278, 43.308335",
                            "IataCode": "GRV",
                            "Airports": [
                                {
                                    "Id": "GRV",
                                    "Name": "Grozny",
                                    "CityId": "GROZ",
                                    "CountryId": "RU",
                                    "Location": "45.788784, 43.298385"
                                }
                            ]
                        },
                        {
                            "Id": "CHIT",
                            "Name": "Chita",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "113.55, 52.033333",
                            "IataCode": "HTA",
                            "Airports": [
                                {
                                    "Id": "HTA",
                                    "Name": "Chita",
                                    "CityId": "CHIT",
                                    "CountryId": "RU",
                                    "Location": "113.3, 52.033333"
                                }
                            ]
                        },
                        {
                            "Id": "IGAR",
                            "Name": "Igarka",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "86.583333, 67.466667",
                            "IataCode": "IAA",
                            "Airports": [
                                {
                                    "Id": "IAA",
                                    "Name": "Igarka",
                                    "CityId": "IGAR",
                                    "CountryId": "RU",
                                    "Location": "86.633333, 67.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YARO",
                            "Name": "Yaroslavl",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.873676, 57.629876",
                            "IataCode": "IAR",
                            "Airports": [
                                {
                                    "Id": "IAR",
                                    "Name": "Yaroslavl",
                                    "CityId": "YARO",
                                    "CountryId": "RU",
                                    "Location": "40.1574, 57.5607"
                                }
                            ]
                        },
                        {
                            "Id": "IZHE",
                            "Name": "Izhevsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "53.233333, 56.850004",
                            "IataCode": "IJK",
                            "Airports": [
                                {
                                    "Id": "IJK",
                                    "Name": "Izhevsk",
                                    "CityId": "IZHE",
                                    "CountryId": "RU",
                                    "Location": "53.466667, 56.833333"
                                }
                            ]
                        },
                        {
                            "Id": "TIKS",
                            "Name": "Tiksi",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "128.86944, 71.687222",
                            "IataCode": "IKS",
                            "Airports": [
                                {
                                    "Id": "IKS",
                                    "Name": "Tiksi",
                                    "CityId": "TIKS",
                                    "CountryId": "RU",
                                    "Location": "128.9, 71.7"
                                }
                            ]
                        },
                        {
                            "Id": "IRKU",
                            "Name": "Irkutsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "104.29639, 52.297781",
                            "IataCode": "IKT",
                            "Airports": [
                                {
                                    "Id": "IKT",
                                    "Name": "Irkutsk",
                                    "CityId": "IRKU",
                                    "CountryId": "RU",
                                    "Location": "104.41667, 52.266667"
                                }
                            ]
                        },
                        {
                            "Id": "INTA",
                            "Name": "Inta",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "60.133334, 66.083333",
                            "IataCode": "INA",
                            "Airports": [
                                {
                                    "Id": "INA",
                                    "Name": "Inta",
                                    "CityId": "INTA",
                                    "CountryId": "RU",
                                    "Location": "60.110664, 66.054901"
                                }
                            ]
                        },
                        {
                            "Id": "IVAO",
                            "Name": "Ivanova",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "40.985833, 56.994169",
                            "IataCode": "IWA",
                            "Airports": [
                                {
                                    "Id": "IWA",
                                    "Name": "Ivanovo",
                                    "CityId": "IVAO",
                                    "CountryId": "RU",
                                    "Location": "40.933333, 56.941667"
                                }
                            ]
                        },
                        {
                            "Id": "JOSH",
                            "Name": "Joshkar-Ola",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "47.890777, 56.63877",
                            "IataCode": "JOK",
                            "Airports": [
                                {
                                    "Id": "JOK",
                                    "Name": "Joshkar-Ola",
                                    "CityId": "JOSH",
                                    "CountryId": "RU",
                                    "Location": "47.895158, 56.705069"
                                }
                            ]
                        },
                        {
                            "Id": "KOST",
                            "Name": "Kostroma",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "40.934444, 57.770837",
                            "IataCode": "KMW",
                            "Airports": [
                                {
                                    "Id": "KMW",
                                    "Name": "Kostroma",
                                    "CityId": "KOST",
                                    "CountryId": "RU",
                                    "Location": "40.933333, 57.75"
                                }
                            ]
                        },
                        {
                            "Id": "KEME",
                            "Name": "Kemerovo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "86.083334, 55.333333",
                            "IataCode": "KEJ",
                            "Airports": [
                                {
                                    "Id": "KEJ",
                                    "Name": "Kemerovo",
                                    "CityId": "KEME",
                                    "CountryId": "RU",
                                    "Location": "86.107208, 55.270094"
                                }
                            ]
                        },
                        {
                            "Id": "KURG",
                            "Name": "Kurgan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "65.333333, 55.450001",
                            "IataCode": "KRO",
                            "Airports": [
                                {
                                    "Id": "KRO",
                                    "Name": "Kurgan",
                                    "CityId": "KURG",
                                    "CountryId": "RU",
                                    "Location": "65.3, 55.433333"
                                }
                            ]
                        },
                        {
                            "Id": "KRAT",
                            "Name": "Krasnodar",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.976944, 45.032781",
                            "IataCode": "KRR",
                            "Airports": [
                                {
                                    "Id": "KRR",
                                    "Name": "Krasnodar",
                                    "CityId": "KRAT",
                                    "CountryId": "RU",
                                    "Location": "38.916667, 45.066667"
                                }
                            ]
                        },
                        {
                            "Id": "KGPA",
                            "Name": "Kogalym",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "74.479065, 62.265366",
                            "IataCode": "KGP",
                            "Airports": [
                                {
                                    "Id": "KGP",
                                    "Name": "Kogalym",
                                    "CityId": "KGPA",
                                    "CountryId": "RU",
                                    "Location": "74.533611, 62.195833"
                                }
                            ]
                        },
                        {
                            "Id": "KOTL",
                            "Name": "Kotlas",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "46.655556, 61.261112",
                            "IataCode": "KSZ",
                            "Airports": [
                                {
                                    "Id": "KSZ",
                                    "Name": "Kotlas",
                                    "CityId": "KOTL",
                                    "CountryId": "RU",
                                    "Location": "46.7, 61.233333"
                                }
                            ]
                        },
                        {
                            "Id": "KHAB",
                            "Name": "Khabarovsk Novy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "135.09278, 48.480836",
                            "IataCode": "KHV",
                            "Airports": [
                                {
                                    "Id": "KHV",
                                    "Name": "Khabarovsk Novy",
                                    "CityId": "KHAB",
                                    "CountryId": "RU",
                                    "Location": "135.18333, 48.516667"
                                }
                            ]
                        },
                        {
                            "Id": "KRAS",
                            "Name": "Krasnoyarsk",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "92.791667, 56.009725",
                            "IataCode": "KJA",
                            "Airports": [
                                {
                                    "Id": "KJA",
                                    "Name": "Krasnoyarsk",
                                    "CityId": "KRAS",
                                    "CountryId": "RU",
                                    "Location": "92.493278, 56.172947"
                                },
                                {
                                    "Id": "KFC",
                                    "Name": "Krasnoyarsk Cheremshanka Airport",
                                    "CityId": "KRAS",
                                    "CountryId": "RU",
                                    "Location": "92.542778, 56.175833"
                                }
                            ]
                        },
                        {
                            "Id": "KLDA",
                            "Name": "Kalinin",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "35.893055, 56.861946",
                            "IataCode": "KLD",
                            "Airports": [
                                {
                                    "Id": "KLD",
                                    "Name": "Kalinin",
                                    "CityId": "KLDA",
                                    "CountryId": "RU",
                                    "Location": "35.916667, 56.916667"
                                }
                            ]
                        },
                        {
                            "Id": "KALU",
                            "Name": "Kaluga",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "36.270556, 54.535833",
                            "IataCode": "KLF",
                            "Airports": [
                                {
                                    "Id": "KLF",
                                    "Name": "Kaluga",
                                    "CityId": "KALU",
                                    "CountryId": "RU",
                                    "Location": "36.266667, 54.5"
                                }
                            ]
                        },
                        {
                            "Id": "SAMA",
                            "Name": "Samara",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "50.15, 53.200004",
                            "IataCode": "KUF",
                            "Airports": [
                                {
                                    "Id": "KUF",
                                    "Name": "Samara",
                                    "CityId": "SAMA",
                                    "CountryId": "RU",
                                    "Location": "50.15, 53.5"
                                }
                            ]
                        },
                        {
                            "Id": "KIRQ",
                            "Name": "Kirov",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "49.658333, 58.596947",
                            "IataCode": "KVX",
                            "Airports": [
                                {
                                    "Id": "KVX",
                                    "Name": "Kirov",
                                    "CityId": "KIRQ",
                                    "CountryId": "RU",
                                    "Location": "49.35, 58.5"
                                }
                            ]
                        },
                        {
                            "Id": "KYZY",
                            "Name": "Kyzyl",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "94.45, 51.700001",
                            "IataCode": "KYZ",
                            "Airports": [
                                {
                                    "Id": "KYZ",
                                    "Name": "Kyzyl",
                                    "CityId": "KYZY",
                                    "CountryId": "RU",
                                    "Location": "94.466667, 51.7"
                                }
                            ]
                        },
                        {
                            "Id": "KAZA",
                            "Name": "Kazan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "49.133334, 55.750004",
                            "IataCode": "KZN",
                            "Airports": [
                                {
                                    "Id": "KZN",
                                    "Name": "Kazan",
                                    "CityId": "KAZA",
                                    "CountryId": "RU",
                                    "Location": "49.278728, 55.606186"
                                }
                            ]
                        },
                        {
                            "Id": "LDGA",
                            "Name": "Leshukonskoye",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "45.766667, 64.9",
                            "IataCode": "LDG",
                            "Airports": [
                                {
                                    "Id": "LDG",
                                    "Name": "Leshukonskoye",
                                    "CityId": "LDGA",
                                    "CountryId": "RU",
                                    "Location": "45.75, 64.9"
                                }
                            ]
                        },
                        {
                            "Id": "PETE",
                            "Name": "St Petersburg",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "30.348702, 59.9396",
                            "IataCode": "LED",
                            "Airports": [
                                {
                                    "Id": "LED",
                                    "Name": "St Petersburg Pulkovo",
                                    "CityId": "PETE",
                                    "CountryId": "RU",
                                    "Location": "30.3, 59.8"
                                },
                                {
                                    "Id": "RVH",
                                    "Name": "St Petersburg Rzhevka",
                                    "CityId": "PETE",
                                    "CountryId": "RU",
                                    "Location": "30.6, 59.983333"
                                }
                            ]
                        },
                        {
                            "Id": "SMOL",
                            "Name": "Smolensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "32.04, 54.781667",
                            "IataCode": "LNX",
                            "Airports": [
                                {
                                    "Id": "LNX",
                                    "Name": "Smolensk",
                                    "CityId": "SMOL",
                                    "CountryId": "RU",
                                    "Location": "32.05, 54.8"
                                }
                            ]
                        },
                        {
                            "Id": "LIPE",
                            "Name": "Lipetsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.568889, 52.618611",
                            "IataCode": "LPK",
                            "Airports": [
                                {
                                    "Id": "LPK",
                                    "Name": "Lipetsk",
                                    "CityId": "LIPE",
                                    "CountryId": "RU",
                                    "Location": "39.537907, 52.703227"
                                }
                            ]
                        },
                        {
                            "Id": "MAKH",
                            "Name": "Makhachkala",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "47.502221, 42.975278",
                            "IataCode": "MCX",
                            "Airports": [
                                {
                                    "Id": "MCX",
                                    "Name": "Makhachkala",
                                    "CityId": "MAKH",
                                    "CountryId": "RU",
                                    "Location": "47.652294, 42.816822"
                                }
                            ]
                        },
                        {
                            "Id": "MURM",
                            "Name": "Murmansk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "33.081944, 68.971667",
                            "IataCode": "MMK",
                            "Airports": [
                                {
                                    "Id": "MMK",
                                    "Name": "Murmansk",
                                    "CityId": "MURM",
                                    "CountryId": "RU",
                                    "Location": "32.753034, 68.781208"
                                }
                            ]
                        },
                        {
                            "Id": "MAGN",
                            "Name": "Magnitogorsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "59.047221, 53.418611",
                            "IataCode": "MQF",
                            "Airports": [
                                {
                                    "Id": "MQF",
                                    "Name": "Magnitogorsk",
                                    "CityId": "MAGN",
                                    "CountryId": "RU",
                                    "Location": "58.755661, 53.393131"
                                }
                            ]
                        },
                        {
                            "Id": "MINE",
                            "Name": "Mineralnye Vody",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "43.135278, 44.210278",
                            "IataCode": "MRV",
                            "Airports": [
                                {
                                    "Id": "MRV",
                                    "Name": "Mineralnye Vody",
                                    "CityId": "MINE",
                                    "CountryId": "RU",
                                    "Location": "43.1, 44.216667"
                                }
                            ]
                        },
                        {
                            "Id": "NIZI",
                            "Name": "Nizhnevartovsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "76.566666, 60.933336",
                            "IataCode": "NJC",
                            "Airports": [
                                {
                                    "Id": "NJC",
                                    "Name": "Nizhnevartovsk",
                                    "CityId": "NIZI",
                                    "CountryId": "RU",
                                    "Location": "76.483333, 60.95"
                                }
                            ]
                        },
                        {
                            "Id": "NOVP",
                            "Name": "Novokuzinetsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "87.1, 53.750003",
                            "IataCode": "NOZ",
                            "Airports": [
                                {
                                    "Id": "NOZ",
                                    "Name": "Novokuzinetsk",
                                    "CityId": "NOVP",
                                    "CountryId": "RU",
                                    "Location": "86.87748, 53.809977"
                                }
                            ]
                        },
                        {
                            "Id": "NORI",
                            "Name": "Norilsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "88.220553, 69.340556",
                            "IataCode": "NSK",
                            "Airports": [
                                {
                                    "Id": "NSK",
                                    "Name": "Norilsk",
                                    "CityId": "NORI",
                                    "CountryId": "RU",
                                    "Location": "87.316667, 69.316667"
                                }
                            ]
                        },
                        {
                            "Id": "OREL",
                            "Name": "Orel",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "36.080279, 52.965833",
                            "IataCode": "OEL",
                            "Airports": [
                                {
                                    "Id": "OEL",
                                    "Name": "Orel",
                                    "CityId": "OREL",
                                    "CountryId": "RU",
                                    "Location": "36.1, 52.983333"
                                }
                            ]
                        },
                        {
                            "Id": "OMSK",
                            "Name": "Omsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "73.4, 55.000004",
                            "IataCode": "OMS",
                            "Airports": [
                                {
                                    "Id": "OMS",
                                    "Name": "Omsk",
                                    "CityId": "OMSK",
                                    "CountryId": "RU",
                                    "Location": "73.3, 54.966667"
                                }
                            ]
                        },
                        {
                            "Id": "OVBA",
                            "Name": "Novosibirsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "82.934444, 55.041117",
                            "IataCode": "OVB",
                            "Airports": [
                                {
                                    "Id": "OVB",
                                    "Name": "Novosibirsk",
                                    "CityId": "OVBA",
                                    "CountryId": "RU",
                                    "Location": "82.6167, 55.0167"
                                }
                            ]
                        },
                        {
                            "Id": "PERM",
                            "Name": "Perm",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "56.25, 58.000003",
                            "IataCode": "PEE",
                            "Airports": [
                                {
                                    "Id": "PEE",
                                    "Name": "Perm",
                                    "CityId": "PERM",
                                    "CountryId": "RU",
                                    "Location": "56.021214, 57.914517"
                                }
                            ]
                        },
                        {
                            "Id": "PETR",
                            "Name": "Petrozavodsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "34.346909, 61.784917",
                            "IataCode": "PES",
                            "Airports": [
                                {
                                    "Id": "PES",
                                    "Name": "Petrozavodsk",
                                    "CityId": "PETR",
                                    "CountryId": "RU",
                                    "Location": "34.154744, 61.885161"
                                }
                            ]
                        },
                        {
                            "Id": "PENZ",
                            "Name": "Penza",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "45, 53.195836",
                            "IataCode": "PEZ",
                            "Airports": [
                                {
                                    "Id": "PEZ",
                                    "Name": "Penza",
                                    "CityId": "PENZ",
                                    "CountryId": "RU",
                                    "Location": "45.023333, 53.11"
                                }
                            ]
                        },
                        {
                            "Id": "PETS",
                            "Name": "Petropavlovsk-Kamchats",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "158.65, 53.016667",
                            "IataCode": "PKC",
                            "Airports": [
                                {
                                    "Id": "PKC",
                                    "Name": "Petropavlovsk-Kamchatsky",
                                    "CityId": "PETS",
                                    "CountryId": "RU",
                                    "Location": "158.45367, 53.16789"
                                }
                            ]
                        },
                        {
                            "Id": "PEVE",
                            "Name": "Pevek",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "170.31333, 69.700833",
                            "IataCode": "PWE",
                            "Airports": [
                                {
                                    "Id": "PWE",
                                    "Name": "Pevek",
                                    "CityId": "PEVE",
                                    "CountryId": "RU",
                                    "Location": "170.6, 69.783333"
                                }
                            ]
                        },
                        {
                            "Id": "OREN",
                            "Name": "Orenburg",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "55.098612, 51.784722",
                            "IataCode": "REN",
                            "Airports": [
                                {
                                    "Id": "REN",
                                    "Name": "Orenburg",
                                    "CityId": "OREN",
                                    "CountryId": "RU",
                                    "Location": "55.45674, 51.79579"
                                }
                            ]
                        },
                        {
                            "Id": "ROSV",
                            "Name": "Rostov-on-Don",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.713889, 47.236391",
                            "IataCode": "ROV",
                            "Airports": [
                                {
                                    "Id": "ROV",
                                    "Name": "Platov International, Rostov on Don",
                                    "CityId": "ROSV",
                                    "CountryId": "RU",
                                    "Location": "39.925774, 47.489935"
                                }
                            ]
                        },
                        {
                            "Id": "SARB",
                            "Name": "Saratov",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "46.033333, 51.566669",
                            "IataCode": "RTW",
                            "Airports": [
                                {
                                    "Id": "RTW",
                                    "Name": "Saratov",
                                    "CityId": "SARB",
                                    "CountryId": "RU",
                                    "Location": "46.033329, 51.562115"
                                },
                                {
                                    "Id": "GSV",
                                    "Name": "Gagarin",
                                    "CityId": "SARB",
                                    "CountryId": "RU",
                                    "Location": "46.04628, 51.57808"
                                }
                            ]
                        },
                        {
                            "Id": "STAW",
                            "Name": "Stavropol",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "41.973333, 45.042779",
                            "IataCode": "STW",
                            "Airports": [
                                {
                                    "Id": "STW",
                                    "Name": "Stavropol",
                                    "CityId": "STAW",
                                    "CountryId": "RU",
                                    "Location": "42.112778, 45.109165"
                                }
                            ]
                        },
                        {
                            "Id": "SURG",
                            "Name": "Surgut",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "73.416667, 61.250001",
                            "IataCode": "SGC",
                            "Airports": [
                                {
                                    "Id": "SGC",
                                    "Name": "Surgut",
                                    "CityId": "SURG",
                                    "CountryId": "RU",
                                    "Location": "73.401842, 61.343694"
                                }
                            ]
                        },
                        {
                            "Id": "SARC",
                            "Name": "Saransk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "45.183333, 54.183333",
                            "IataCode": "SKX",
                            "Airports": [
                                {
                                    "Id": "SKX",
                                    "Name": "Saransk",
                                    "CityId": "SARC",
                                    "CountryId": "RU",
                                    "Location": "45.166667, 54.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SALE",
                            "Name": "Salekhard",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "66.601945, 66.53",
                            "IataCode": "SLY",
                            "Airports": [
                                {
                                    "Id": "SLY",
                                    "Name": "Salekhard",
                                    "CityId": "SALE",
                                    "CountryId": "RU",
                                    "Location": "66.6, 66.583333"
                                }
                            ]
                        },
                        {
                            "Id": "EKAT",
                            "Name": "Ekaterinburg",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "60.612501, 56.857505",
                            "IataCode": "SVX",
                            "Airports": [
                                {
                                    "Id": "SVX",
                                    "Name": "Ekaterinburg",
                                    "CityId": "EKAT",
                                    "CountryId": "RU",
                                    "Location": "60.802728, 56.743108"
                                }
                            ]
                        },
                        {
                            "Id": "TYUM",
                            "Name": "Tyumen",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "65.527222, 57.152225",
                            "IataCode": "TJM",
                            "Airports": [
                                {
                                    "Id": "TJM",
                                    "Name": "Tyumen",
                                    "CityId": "TYUM",
                                    "CountryId": "RU",
                                    "Location": "65.3243, 57.189567"
                                }
                            ]
                        },
                        {
                            "Id": "TOMS",
                            "Name": "Tomsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "84.966667, 56.500002",
                            "IataCode": "TOF",
                            "Airports": [
                                {
                                    "Id": "TOF",
                                    "Name": "Tomsk",
                                    "CityId": "TOMS",
                                    "CountryId": "RU",
                                    "Location": "85.211666, 56.383333"
                                }
                            ]
                        },
                        {
                            "Id": "TULA",
                            "Name": "Tula",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "37.611111, 54.204446",
                            "IataCode": "TYA",
                            "Airports": [
                                {
                                    "Id": "TYA",
                                    "Name": "Tula",
                                    "CityId": "TULA",
                                    "CountryId": "RU",
                                    "Location": "37.616667, 54.2"
                                }
                            ]
                        },
                        {
                            "Id": "UFAI",
                            "Name": "Ufa",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "56.0375, 54.775003",
                            "IataCode": "UFA",
                            "Airports": [
                                {
                                    "Id": "UFA",
                                    "Name": "Ufa",
                                    "CityId": "UFAI",
                                    "CountryId": "RU",
                                    "Location": "55.86462, 54.558128"
                                }
                            ]
                        },
                        {
                            "Id": "ULYA",
                            "Name": "Ulyanovsk",
                            "SingleAirportCity": false,
                            "CountryId": "RU",
                            "Location": "48.400001, 54.333337",
                            "IataCode": "ULY",
                            "Airports": [
                                {
                                    "Id": "ULV",
                                    "Name": "Ulyanovsk Baratayevka",
                                    "CityId": "ULYA",
                                    "CountryId": "RU",
                                    "Location": "48.22666, 54.268333"
                                },
                                {
                                    "Id": "ULY",
                                    "Name": "Ulyanovsk",
                                    "CityId": "ULYA",
                                    "CountryId": "RU",
                                    "Location": "48.802656, 54.401017"
                                }
                            ]
                        },
                        {
                            "Id": "YUZH",
                            "Name": "Yuzhno-Sakhalinsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "142.74056, 46.952222",
                            "IataCode": "UUS",
                            "Airports": [
                                {
                                    "Id": "UUS",
                                    "Name": "Yuzhno-Sakhalinsk",
                                    "CityId": "YUZH",
                                    "CountryId": "RU",
                                    "Location": "142.71753, 46.888672"
                                }
                            ]
                        },
                        {
                            "Id": "VOLH",
                            "Name": "Volgograd",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "44.585834, 48.804722",
                            "IataCode": "VOG",
                            "Airports": [
                                {
                                    "Id": "VOG",
                                    "Name": "Volgograd",
                                    "CityId": "VOLH",
                                    "CountryId": "RU",
                                    "Location": "44.345544, 48.782528"
                                }
                            ]
                        },
                        {
                            "Id": "VLAD",
                            "Name": "Vladivostok",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "132.03092, 43.188367",
                            "IataCode": "VVO",
                            "Airports": [
                                {
                                    "Id": "VVO",
                                    "Name": "Vladivostok",
                                    "CityId": "VLAD",
                                    "CountryId": "RU",
                                    "Location": "132.14802, 43.39895"
                                }
                            ]
                        },
                        {
                            "Id": "USTY",
                            "Name": "Velikij Ustyug",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "46.310555, 60.764444",
                            "IataCode": "VUS",
                            "Airports": [
                                {
                                    "Id": "VUS",
                                    "Name": "Velikij Ustyug",
                                    "CityId": "USTY",
                                    "CountryId": "RU",
                                    "Location": "46.316667, 60.766667"
                                }
                            ]
                        },
                        {
                            "Id": "ARKH",
                            "Name": "Arkhangelsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "40.54, 64.540002",
                            "IataCode": "ARH",
                            "Airports": [
                                {
                                    "Id": "ARH",
                                    "Name": "Arkhangelsk",
                                    "CityId": "ARKH",
                                    "CountryId": "RU",
                                    "Location": "40.716667, 64.600281"
                                }
                            ]
                        },
                        {
                            "Id": "ASTR",
                            "Name": "Astrakhan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "48.04, 46.340002",
                            "IataCode": "ASF",
                            "Airports": [
                                {
                                    "Id": "ASF",
                                    "Name": "Astrakhan",
                                    "CityId": "ASTR",
                                    "CountryId": "RU",
                                    "Location": "48.006278, 46.283333"
                                }
                            ]
                        },
                        {
                            "Id": "BARN",
                            "Name": "Barnaul",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "83.76, 53.360003",
                            "IataCode": "BAX",
                            "Airports": [
                                {
                                    "Id": "BAX",
                                    "Name": "Barnaul",
                                    "CityId": "BARN",
                                    "CountryId": "RU",
                                    "Location": "83.543472, 53.364075"
                                }
                            ]
                        },
                        {
                            "Id": "VLAE",
                            "Name": "Vladikavkaz",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "44.667778, 43.036667",
                            "IataCode": "OGZ",
                            "Airports": [
                                {
                                    "Id": "OGZ",
                                    "Name": "Vladikavkaz",
                                    "CityId": "VLAE",
                                    "CountryId": "RU",
                                    "Location": "44.6, 43.2"
                                }
                            ]
                        },
                        {
                            "Id": "RYBI",
                            "Name": "Rybinsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.833333, 58.05",
                            "IataCode": "RYB",
                            "Airports": [
                                {
                                    "Id": "RYB",
                                    "Name": "Rybinsk",
                                    "CityId": "RYBI",
                                    "CountryId": "RU",
                                    "Location": "38.866667, 58.133333"
                                }
                            ]
                        },
                        {
                            "Id": "TAMB",
                            "Name": "Tambov",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "41.433888, 52.73167",
                            "IataCode": "TBW",
                            "Airports": [
                                {
                                    "Id": "TBW",
                                    "Name": "Tambov",
                                    "CityId": "TAMB",
                                    "CountryId": "RU",
                                    "Location": "41.48333, 52.805"
                                }
                            ]
                        },
                        {
                            "Id": "ULAN",
                            "Name": "Ulan-Ude",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "107.61667, 51.833333",
                            "IataCode": "UUD",
                            "Airports": [
                                {
                                    "Id": "UUD",
                                    "Name": "Baikal International",
                                    "CityId": "ULAN",
                                    "CountryId": "RU",
                                    "Location": "107.43764, 51.807764"
                                }
                            ]
                        },
                        {
                            "Id": "VORO",
                            "Name": "Voronezh",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.17, 51.666391",
                            "IataCode": "VOZ",
                            "Airports": [
                                {
                                    "Id": "VOZ",
                                    "Name": "Voronezh",
                                    "CityId": "VORO",
                                    "CountryId": "RU",
                                    "Location": "39.229589, 51.814211"
                                }
                            ]
                        },
                        {
                            "Id": "NALC",
                            "Name": "Nalchik",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "43.614722, 43.501669",
                            "IataCode": "NAL",
                            "Airports": [
                                {
                                    "Id": "NAL",
                                    "Name": "Nalchik",
                                    "CityId": "NALC",
                                    "CountryId": "RU",
                                    "Location": "43.636583, 43.512856"
                                }
                            ]
                        },
                        {
                            "Id": "NEFT",
                            "Name": "Neftekamsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "54.266668, 56.083333",
                            "IataCode": "NEF",
                            "Airports": [
                                {
                                    "Id": "NEF",
                                    "Name": "Neftekamsk",
                                    "CityId": "NEFT",
                                    "CountryId": "RU",
                                    "Location": "54.348679, 56.106513"
                                }
                            ]
                        },
                        {
                            "Id": "NERY",
                            "Name": "Neryungri",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "124.71417, 56.673333",
                            "IataCode": "NER",
                            "Airports": [
                                {
                                    "Id": "NER",
                                    "Name": "Neryungri",
                                    "CityId": "NERY",
                                    "CountryId": "RU",
                                    "Location": "124.6, 56.65"
                                }
                            ]
                        },
                        {
                            "Id": "NEFU",
                            "Name": "Nefteyugansk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "72.700001, 61.083333",
                            "IataCode": "NFG",
                            "Airports": [
                                {
                                    "Id": "NFG",
                                    "Name": "Nefteyugansk",
                                    "CityId": "NEFU",
                                    "CountryId": "RU",
                                    "Location": "73.05, 61.133333"
                                }
                            ]
                        },
                        {
                            "Id": "NARY",
                            "Name": "Naryan-Mar",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "53.047222, 67.648056",
                            "IataCode": "NNM",
                            "Airports": [
                                {
                                    "Id": "NNM",
                                    "Name": "Naryan-Mar",
                                    "CityId": "NARY",
                                    "CountryId": "RU",
                                    "Location": "53.15, 67.616667"
                                }
                            ]
                        },
                        {
                            "Id": "NOVG",
                            "Name": "Novgorod",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "31.283334, 58.516667",
                            "IataCode": "NVR",
                            "Airports": [
                                {
                                    "Id": "NVR",
                                    "Name": "Novgorod",
                                    "CityId": "NOVG",
                                    "CountryId": "RU",
                                    "Location": "31.241667, 58.493333"
                                }
                            ]
                        },
                        {
                            "Id": "NADY",
                            "Name": "Nadym",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "72.516667, 65.533333",
                            "IataCode": "NYM",
                            "Airports": [
                                {
                                    "Id": "NYM",
                                    "Name": "Nadym",
                                    "CityId": "NADY",
                                    "CountryId": "RU",
                                    "Location": "72.716667, 65.483333"
                                }
                            ]
                        },
                        {
                            "Id": "OHOT",
                            "Name": "Ohotsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "143.3, 59.383333",
                            "IataCode": "OHO",
                            "Airports": [
                                {
                                    "Id": "OHO",
                                    "Name": "Ohotsk",
                                    "CityId": "OHOT",
                                    "CountryId": "RU",
                                    "Location": "143.05, 59.416667"
                                }
                            ]
                        },
                        {
                            "Id": "PECH",
                            "Name": "Pechora",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "57.216389, 65.145",
                            "IataCode": "PEX",
                            "Airports": [
                                {
                                    "Id": "PEX",
                                    "Name": "Pechora",
                                    "CityId": "PECH",
                                    "CountryId": "RU",
                                    "Location": "57.133333, 65.116667"
                                }
                            ]
                        },
                        {
                            "Id": "PSKO",
                            "Name": "Pskov",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "28.333333, 57.833333",
                            "IataCode": "PKV",
                            "Airports": [
                                {
                                    "Id": "PKV",
                                    "Name": "Pskov",
                                    "CityId": "PSKO",
                                    "CountryId": "RU",
                                    "Location": "28.395498, 57.798807"
                                }
                            ]
                        },
                        {
                            "Id": "SYKT",
                            "Name": "Syktyvkar",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "50.812222, 61.66667",
                            "IataCode": "SCW",
                            "Airports": [
                                {
                                    "Id": "SCW",
                                    "Name": "Syktyvkar",
                                    "CityId": "SYKT",
                                    "CountryId": "RU",
                                    "Location": "50.84505, 61.64705"
                                }
                            ]
                        },
                        {
                            "Id": "ORSK",
                            "Name": "Orsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "58.57, 51.22528",
                            "IataCode": "OSW",
                            "Airports": [
                                {
                                    "Id": "OSW",
                                    "Name": "Orsk",
                                    "CityId": "ORSK",
                                    "CountryId": "RU",
                                    "Location": "58.595586, 51.072506"
                                }
                            ]
                        },
                        {
                            "Id": "TOBO",
                            "Name": "Tobolsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "68.266667, 58.200002",
                            "IataCode": "TOX",
                            "Airports": [
                                {
                                    "Id": "TOX",
                                    "Name": "Tobolsk",
                                    "CityId": "TOBO",
                                    "CountryId": "RU",
                                    "Location": "68.233333, 58.133333"
                                }
                            ]
                        },
                        {
                            "Id": "TYND",
                            "Name": "Tynda",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "124.71667, 55.166667",
                            "IataCode": "TYD",
                            "Airports": [
                                {
                                    "Id": "TYD",
                                    "Name": "Tynda",
                                    "CityId": "TYND",
                                    "CountryId": "RU",
                                    "Location": "124.73333, 55.283333"
                                }
                            ]
                        },
                        {
                            "Id": "UKHT",
                            "Name": "Ukhta",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "53.688889, 63.558058",
                            "IataCode": "UCT",
                            "Airports": [
                                {
                                    "Id": "UCT",
                                    "Name": "Ukhta",
                                    "CityId": "UKHT",
                                    "CountryId": "RU",
                                    "Location": "53.8, 63.566667"
                                }
                            ]
                        },
                        {
                            "Id": "USTA",
                            "Name": "Ust'-Ilimsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "102.58333, 58",
                            "IataCode": "UIK",
                            "Airports": [
                                {
                                    "Id": "UIK",
                                    "Name": "Ust-Ilimsk",
                                    "CityId": "USTA",
                                    "CountryId": "RU",
                                    "Location": "102.55, 58.133333"
                                }
                            ]
                        },
                        {
                            "Id": "USTK",
                            "Name": "Ust-Kut",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "105.64833, 56.765833",
                            "IataCode": "UKX",
                            "Airports": [
                                {
                                    "Id": "UKX",
                                    "Name": "Ust-Kut",
                                    "CityId": "USTK",
                                    "CountryId": "RU",
                                    "Location": "105.73333, 56.85"
                                }
                            ]
                        },
                        {
                            "Id": "KURS",
                            "Name": "Kursk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "36.193889, 51.730279",
                            "IataCode": "URS",
                            "Airports": [
                                {
                                    "Id": "URS",
                                    "Name": "Kursk",
                                    "CityId": "KURS",
                                    "CountryId": "RU",
                                    "Location": "36.266667, 51.75"
                                }
                            ]
                        },
                        {
                            "Id": "BUGU",
                            "Name": "Bugulma",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "52.799999, 54.55",
                            "IataCode": "UUA",
                            "Airports": [
                                {
                                    "Id": "UUA",
                                    "Name": "Bugulma",
                                    "CityId": "BUGU",
                                    "CountryId": "RU",
                                    "Location": "52.816667, 54.616667"
                                }
                            ]
                        },
                        {
                            "Id": "VOLO",
                            "Name": "Vologda",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "39.9, 59.216671",
                            "IataCode": "VGD",
                            "Airports": [
                                {
                                    "Id": "VGD",
                                    "Name": "Vologda",
                                    "CityId": "VOLO",
                                    "CountryId": "RU",
                                    "Location": "39.944744, 59.282194"
                                }
                            ]
                        },
                        {
                            "Id": "VORK",
                            "Name": "Vorkuta",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "64, 67.500001",
                            "IataCode": "VKT",
                            "Airports": [
                                {
                                    "Id": "VKT",
                                    "Name": "Vorkuta",
                                    "CityId": "VORK",
                                    "CountryId": "RU",
                                    "Location": "63.983333, 67.483333"
                                }
                            ]
                        },
                        {
                            "Id": "VOLG",
                            "Name": "Volgodonsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "42.151389, 47.513611",
                            "IataCode": "VLK",
                            "Airports": [
                                {
                                    "Id": "VLK",
                                    "Name": "Volgodonsk",
                                    "CityId": "VOLG",
                                    "CountryId": "RU",
                                    "Location": "42.083333, 47.683333"
                                }
                            ]
                        },
                        {
                            "Id": "VELI",
                            "Name": "Velikiye Luki",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "30.534722, 56.340001",
                            "IataCode": "VLU",
                            "Airports": [
                                {
                                    "Id": "VLU",
                                    "Name": "Velikiye Luki",
                                    "CityId": "VELI",
                                    "CountryId": "RU",
                                    "Location": "30.616667, 56.383333"
                                }
                            ]
                        },
                        {
                            "Id": "KALI",
                            "Name": "Kaliningrad",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "20.5, 54.71",
                            "IataCode": "KGD",
                            "Airports": [
                                {
                                    "Id": "KGD",
                                    "Name": "Kaliningrad",
                                    "CityId": "KALI",
                                    "CountryId": "RU",
                                    "Location": "20.592633, 54.89005"
                                }
                            ]
                        },
                        {
                            "Id": "HMAA",
                            "Name": "Khanty-Mansiysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "69.001944, 61.004167",
                            "IataCode": "HMA",
                            "Airports": [
                                {
                                    "Id": "HMA",
                                    "Name": "Khanty-Mansiysk",
                                    "CityId": "HMAA",
                                    "CountryId": "RU",
                                    "Location": "69.086667, 61.028333"
                                }
                            ]
                        },
                        {
                            "Id": "NABE",
                            "Name": "Naberezhnye Chelny",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "52.428889, 55.756114",
                            "IataCode": "NBC",
                            "Airports": [
                                {
                                    "Id": "NBC",
                                    "Name": "Naberezhnye Chelny",
                                    "CityId": "NABE",
                                    "CountryId": "RU",
                                    "Location": "52.1, 55.566667"
                                }
                            ]
                        },
                        {
                            "Id": "NOVO",
                            "Name": "Novorossijsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "37.770834, 44.723889",
                            "IataCode": "NOI",
                            "Airports": [
                                {
                                    "Id": "NOI",
                                    "Name": "Novorossijsk",
                                    "CityId": "NOVO",
                                    "CountryId": "RU",
                                    "Location": "37.7667, 44.7167"
                                }
                            ]
                        },
                        {
                            "Id": "TAGA",
                            "Name": "Taganrog",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.909444, 47.221391",
                            "IataCode": "TGK",
                            "Airports": [
                                {
                                    "Id": "TGK",
                                    "Name": "Taganrog Yuzhny",
                                    "CityId": "TAGA",
                                    "CountryId": "RU",
                                    "Location": "38.864017, 47.243378"
                                }
                            ]
                        },
                        {
                            "Id": "RYAZ",
                            "Name": "Ryazan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "40.654414, 54.306884",
                            "IataCode": "RZN",
                            "Airports": [
                                {
                                    "Id": "RZN",
                                    "Name": "Ryazan",
                                    "CityId": "RYAZ",
                                    "CountryId": "RU",
                                    "Location": "39.583333, 54.633333"
                                }
                            ]
                        },
                        {
                            "Id": "ZZOA",
                            "Name": "Sakhalin Oblast",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "143.75588, 49.8799",
                            "IataCode": "ZZO",
                            "Airports": [
                                {
                                    "Id": "ZZO",
                                    "Name": "Zonalnoye Airport",
                                    "CityId": "ZZOA",
                                    "CountryId": "RU",
                                    "Location": "142.76, 50.668333"
                                }
                            ]
                        },
                        {
                            "Id": "EIKA",
                            "Name": "Yeysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.279167, 46.698889",
                            "IataCode": "EIK",
                            "Airports": [
                                {
                                    "Id": "EIK",
                                    "Name": "Eisk",
                                    "CityId": "EIKA",
                                    "CountryId": "RU",
                                    "Location": "38.194313, 46.671114"
                                }
                            ]
                        },
                        {
                            "Id": "UUYX",
                            "Name": "Ust-Tsilma",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "52.15, 65.441111",
                            "IataCode": "UTS",
                            "Airports": [
                                {
                                    "Id": "UTS",
                                    "Name": "Ust-Tsilma",
                                    "CityId": "UUYX",
                                    "CountryId": "RU",
                                    "Location": "52.198889, 65.433333"
                                }
                            ]
                        },
                        {
                            "Id": "NAZR",
                            "Name": "Nazran",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "44.765, 43.226111",
                            "IataCode": "IGT",
                            "Airports": [
                                {
                                    "Id": "IGT",
                                    "Name": "Magas",
                                    "CityId": "NAZR",
                                    "CountryId": "RU",
                                    "Location": "45.013, 43.3193"
                                }
                            ]
                        },
                        {
                            "Id": "CSHB",
                            "Name": "Solovetsky",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "35.716666, 65.033333",
                            "IataCode": "CSH",
                            "Airports": [
                                {
                                    "Id": "CSH",
                                    "Name": "Solovetsky",
                                    "CityId": "CSHB",
                                    "CountryId": "RU",
                                    "Location": "35.7333, 65.03"
                                }
                            ]
                        },
                        {
                            "Id": "KIRP",
                            "Name": "Kirovsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "33.671944, 67.614444",
                            "IataCode": "KVK",
                            "Airports": [
                                {
                                    "Id": "KVK",
                                    "Name": "Kirovsk-Apatity",
                                    "CityId": "KIRP",
                                    "CountryId": "RU",
                                    "Location": "33.583333, 67.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BALA",
                            "Name": "Balakovo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "47.794106, 52.024663",
                            "IataCode": "BWO",
                            "Airports": [
                                {
                                    "Id": "BWO",
                                    "Name": "Balakovo",
                                    "CityId": "BALA",
                                    "CountryId": "RU",
                                    "Location": "47.75, 51.866667"
                                }
                            ]
                        },
                        {
                            "Id": "USIN",
                            "Name": "Usinsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "57.528055, 65.993889",
                            "IataCode": "USK",
                            "Airports": [
                                {
                                    "Id": "USK",
                                    "Name": "Usinsk",
                                    "CityId": "USIN",
                                    "CountryId": "RU",
                                    "Location": "57.366829, 66.005177"
                                }
                            ]
                        },
                        {
                            "Id": "URAJ",
                            "Name": "Uraj",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "64.775833, 60.124722",
                            "IataCode": "URJ",
                            "Airports": [
                                {
                                    "Id": "URJ",
                                    "Name": "Uraj",
                                    "CityId": "URAJ",
                                    "CountryId": "RU",
                                    "Location": "64.833333, 60.116667"
                                }
                            ]
                        },
                        {
                            "Id": "THXA",
                            "Name": "Turukhansk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "87.983333, 65.816667",
                            "IataCode": "THX",
                            "Airports": [
                                {
                                    "Id": "THX",
                                    "Name": "Turukhansk",
                                    "CityId": "THXA",
                                    "CountryId": "RU",
                                    "Location": "87.93, 65.8"
                                }
                            ]
                        },
                        {
                            "Id": "TRKO",
                            "Name": "Tarko-Sale",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "77.785, 64.9225",
                            "IataCode": "TQL",
                            "Airports": [
                                {
                                    "Id": "TQL",
                                    "Name": "Tarko-Sale",
                                    "CityId": "TRKO",
                                    "CountryId": "RU",
                                    "Location": "77.797356, 64.92134"
                                }
                            ]
                        },
                        {
                            "Id": "SWTA",
                            "Name": "Strzhewoi",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "77.592777, 60.728333",
                            "IataCode": "SWT",
                            "Airports": [
                                {
                                    "Id": "SWT",
                                    "Name": "Strzhewoi",
                                    "CityId": "SWTA",
                                    "CountryId": "RU",
                                    "Location": "77.651575, 60.715994"
                                }
                            ]
                        },
                        {
                            "Id": "OVSB",
                            "Name": "Sovetsky",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "63.584167, 61.361389",
                            "IataCode": "OVS",
                            "Airports": [
                                {
                                    "Id": "OVS",
                                    "Name": "Sovetsky",
                                    "CityId": "OVSB",
                                    "CountryId": "RU",
                                    "Location": "63.6007, 61.3172"
                                }
                            ]
                        },
                        {
                            "Id": "TGPA",
                            "Name": "Podkamennaya Tunguska",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "90.123611, 61.599167",
                            "IataCode": "TGP",
                            "Airports": [
                                {
                                    "Id": "TGP",
                                    "Name": "Podkamennaya Tunguska",
                                    "CityId": "TGPA",
                                    "CountryId": "RU",
                                    "Location": "89.973333, 61.59"
                                }
                            ]
                        },
                        {
                            "Id": "NYAA",
                            "Name": "Nyagan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "65.393611, 62.140557",
                            "IataCode": "NYA",
                            "Airports": [
                                {
                                    "Id": "NYA",
                                    "Name": "Nyagan",
                                    "CityId": "NYAA",
                                    "CountryId": "RU",
                                    "Location": "65.6025, 62.1017"
                                }
                            ]
                        },
                        {
                            "Id": "NOJA",
                            "Name": "Noyabrsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "75.616667, 63.166669",
                            "IataCode": "NOJ",
                            "Airports": [
                                {
                                    "Id": "NOJ",
                                    "Name": "Nojabrxsk",
                                    "CityId": "NOJA",
                                    "CountryId": "RU",
                                    "Location": "75.27, 63.183333"
                                }
                            ]
                        },
                        {
                            "Id": "NOVY",
                            "Name": "Novy Urengoy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "76.633332, 66.083333",
                            "IataCode": "NUX",
                            "Airports": [
                                {
                                    "Id": "NUX",
                                    "Name": "Novy Urengoy",
                                    "CityId": "NOVY",
                                    "CountryId": "RU",
                                    "Location": "76.516667, 66.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MYSK",
                            "Name": "Mys Kamennyy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "73.4, 68.533333",
                            "IataCode": "YMK",
                            "Airports": [
                                {
                                    "Id": "YMK",
                                    "Name": "Mys Kamennyy",
                                    "CityId": "MYSK",
                                    "CountryId": "RU",
                                    "Location": "73.59573, 68.46843"
                                }
                            ]
                        },
                        {
                            "Id": "KRSN",
                            "Name": "Krasnosel'Kup",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "82.466667, 65.7",
                            "IataCode": "KKQ",
                            "Airports": [
                                {
                                    "Id": "KKQ",
                                    "Name": "Krasnosel'Kup",
                                    "CityId": "KRSN",
                                    "CountryId": "RU",
                                    "Location": "82.456055, 65.7"
                                }
                            ]
                        },
                        {
                            "Id": "IRMA",
                            "Name": "Igrim",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "64.419444, 63.193333",
                            "IataCode": "IRM",
                            "Airports": [
                                {
                                    "Id": "IRM",
                                    "Name": "Igrim",
                                    "CityId": "IRMA",
                                    "CountryId": "RU",
                                    "Location": "64.45, 63.2"
                                }
                            ]
                        },
                        {
                            "Id": "RGKA",
                            "Name": "Gorno-Altaysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "85.955, 51.956111",
                            "IataCode": "RGK",
                            "Airports": [
                                {
                                    "Id": "RGK",
                                    "Name": "Gorno-Altaysk",
                                    "CityId": "RGKA",
                                    "CountryId": "RU",
                                    "Location": "85.8333, 51.9667"
                                }
                            ]
                        },
                        {
                            "Id": "EZVA",
                            "Name": "Berezovo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "65.04, 63.93",
                            "IataCode": "EZV",
                            "Airports": [
                                {
                                    "Id": "EZV",
                                    "Name": "Berezovo",
                                    "CityId": "EZVA",
                                    "CountryId": "RU",
                                    "Location": "65.0167, 63.9167"
                                }
                            ]
                        },
                        {
                            "Id": "EYKA",
                            "Name": "Beloyarsky",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "66.67, 63.71",
                            "IataCode": "EYK",
                            "Airports": [
                                {
                                    "Id": "EYK",
                                    "Name": "Beloyarsky",
                                    "CityId": "EYKA",
                                    "CountryId": "RU",
                                    "Location": "66.6833, 63.6833"
                                }
                            ]
                        },
                        {
                            "Id": "SBTR",
                            "Name": "Sabetta",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "72.096112, 71.256944",
                            "IataCode": "SBT",
                            "Airports": [
                                {
                                    "Id": "SBT",
                                    "Name": "Sabetta International",
                                    "CityId": "SBTR",
                                    "CountryId": "RU",
                                    "Location": "72.052222, 71.219167"
                                }
                            ]
                        },
                        {
                            "Id": "RATA",
                            "Name": "Raduzhnyi",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "77.475, 62.096111",
                            "IataCode": "RAT",
                            "Airports": [
                                {
                                    "Id": "RAT",
                                    "Name": "Raduzhnyi",
                                    "CityId": "RATA",
                                    "CountryId": "RU",
                                    "Location": "77.3289, 62.1586"
                                }
                            ]
                        },
                        {
                            "Id": "ZIXC",
                            "Name": "Zhigansk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "123.37111, 66.769722",
                            "IataCode": "ZIX",
                            "Airports": [
                                {
                                    "Id": "ZIX",
                                    "Name": "Zhigansk",
                                    "CityId": "ZIXC",
                                    "CountryId": "RU",
                                    "Location": "123.36139, 66.796667"
                                }
                            ]
                        },
                        {
                            "Id": "YERB",
                            "Name": "Yerbogachen",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "108.01083, 61.276667",
                            "IataCode": "ERG",
                            "Airports": [
                                {
                                    "Id": "ERG",
                                    "Name": "Yerbogachen Airport",
                                    "CityId": "YERB",
                                    "CountryId": "RU",
                                    "Location": "108.03, 61.275"
                                }
                            ]
                        },
                        {
                            "Id": "EDNA",
                            "Name": "Yedinka",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "138.71667, 47.1575",
                            "IataCode": "EDN",
                            "Airports": [
                                {
                                    "Id": "EDN",
                                    "Name": "Yedinka",
                                    "CityId": "EDNA",
                                    "CountryId": "RU",
                                    "Location": "138.64083, 47.18083"
                                }
                            ]
                        },
                        {
                            "Id": "VYIA",
                            "Name": "Vilyuysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "121.62472, 63.755278",
                            "IataCode": "VYI",
                            "Airports": [
                                {
                                    "Id": "VYI",
                                    "Name": "Vilyuysk",
                                    "CityId": "VYIA",
                                    "CountryId": "RU",
                                    "Location": "121.69221, 63.754825"
                                }
                            ]
                        },
                        {
                            "Id": "VHVA",
                            "Name": "Verkhnevilyuysk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "120.31667, 63.445278",
                            "IataCode": "VHV",
                            "Airports": [
                                {
                                    "Id": "VHV",
                                    "Name": "Verkhnevilyuysk",
                                    "CityId": "VHVA",
                                    "CountryId": "RU",
                                    "Location": "120.25189, 63.457634"
                                }
                            ]
                        },
                        {
                            "Id": "UMSA",
                            "Name": "Ust-Maya",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "134.53333, 60.416667",
                            "IataCode": "UMS",
                            "Airports": [
                                {
                                    "Id": "UMS",
                                    "Name": "Ust-Maya",
                                    "CityId": "UMSA",
                                    "CountryId": "RU",
                                    "Location": "134.43778, 60.358056"
                                }
                            ]
                        },
                        {
                            "Id": "UKGA",
                            "Name": "Ust-Kuyga",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "135.6, 70.016667",
                            "IataCode": "UKG",
                            "Airports": [
                                {
                                    "Id": "UKG",
                                    "Name": "Ust-Kuyga",
                                    "CityId": "UKGA",
                                    "CountryId": "RU",
                                    "Location": "135.64667, 70.011111"
                                }
                            ]
                        },
                        {
                            "Id": "NEIA",
                            "Name": "Terney",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "136.60917, 45.050278",
                            "IataCode": "NEI",
                            "Airports": [
                                {
                                    "Id": "NEI",
                                    "Name": "Terney",
                                    "CityId": "NEIA",
                                    "CountryId": "RU",
                                    "Location": "136.59039, 45.08132"
                                }
                            ]
                        },
                        {
                            "Id": "ETLA",
                            "Name": "Svetlaya",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "138.32972, 46.538889",
                            "IataCode": "ETL",
                            "Airports": [
                                {
                                    "Id": "ETL",
                                    "Name": "Svetlaya",
                                    "CityId": "ETLA",
                                    "CountryId": "RU",
                                    "Location": "138.31833, 46.541111"
                                }
                            ]
                        },
                        {
                            "Id": "SUYA",
                            "Name": "Suntar",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "117.63194, 62.144444",
                            "IataCode": "SUY",
                            "Airports": [
                                {
                                    "Id": "SUY",
                                    "Name": "Suntar",
                                    "CityId": "SUYA",
                                    "CountryId": "RU",
                                    "Location": "117.63611, 62.184722"
                                }
                            ]
                        },
                        {
                            "Id": "SYSB",
                            "Name": "Saskylakh",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "114.08333, 71.916667",
                            "IataCode": "SYS",
                            "Airports": [
                                {
                                    "Id": "SYS",
                                    "Name": "Saskylakh",
                                    "CityId": "SYSB",
                                    "CountryId": "RU",
                                    "Location": "114.08222, 71.928889"
                                }
                            ]
                        },
                        {
                            "Id": "RZHA",
                            "Name": "Preobrazheniye",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "133.90639, 42.901944",
                            "IataCode": "RZH",
                            "Airports": [
                                {
                                    "Id": "RZH",
                                    "Name": "Preobrazheniye",
                                    "CityId": "RZHA",
                                    "CountryId": "RU",
                                    "Location": "133.90389, 42.92094"
                                }
                            ]
                        },
                        {
                            "Id": "TLYA",
                            "Name": "Plastun",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "136.29389, 44.756111",
                            "IataCode": "TLY",
                            "Airports": [
                                {
                                    "Id": "TLY",
                                    "Name": "Plastun",
                                    "CityId": "TLYA",
                                    "CountryId": "RU",
                                    "Location": "136.2917, 44.8147"
                                }
                            ]
                        },
                        {
                            "Id": "ONKC",
                            "Name": "Olenyok",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "112.45, 68.5",
                            "IataCode": "ONK",
                            "Airports": [
                                {
                                    "Id": "ONK",
                                    "Name": "Olenyok",
                                    "CityId": "ONKC",
                                    "CountryId": "RU",
                                    "Location": "112.47917, 68.515556"
                                }
                            ]
                        },
                        {
                            "Id": "UEMO",
                            "Name": "Olyokminsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "120.42639, 60.373611",
                            "IataCode": "OLZ",
                            "Airports": [
                                {
                                    "Id": "OLZ",
                                    "Name": "Olyokminsk Airport",
                                    "CityId": "UEMO",
                                    "CountryId": "RU",
                                    "Location": "120.46861, 60.398889"
                                }
                            ]
                        },
                        {
                            "Id": "NYRC",
                            "Name": "Nyurba",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "118.33194, 63.284167",
                            "IataCode": "NYR",
                            "Airports": [
                                {
                                    "Id": "NYR",
                                    "Name": "Nyurba Airport",
                                    "CityId": "NYRC",
                                    "CountryId": "RU",
                                    "Location": "118.34694, 63.297222"
                                }
                            ]
                        },
                        {
                            "Id": "MIRN",
                            "Name": "Mirny",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "113.96111, 62.535278",
                            "IataCode": "MJZ",
                            "Airports": [
                                {
                                    "Id": "MJZ",
                                    "Name": "Mirny",
                                    "CityId": "MIRN",
                                    "CountryId": "RU",
                                    "Location": "114.03333, 62.533333"
                                }
                            ]
                        },
                        {
                            "Id": "ULKC",
                            "Name": "Lensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "114.92778, 60.725278",
                            "IataCode": "ULK",
                            "Airports": [
                                {
                                    "Id": "ULK",
                                    "Name": "Lensk Airport",
                                    "CityId": "ULKC",
                                    "CountryId": "RU",
                                    "Location": "114.82583, 60.720556"
                                }
                            ]
                        },
                        {
                            "Id": "KOMS",
                            "Name": "Komsomolsk Na Amure",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "137.01524, 50.551988",
                            "IataCode": "KXK",
                            "Airports": [
                                {
                                    "Id": "KXK",
                                    "Name": "Komsomolsk Na Amure",
                                    "CityId": "KOMS",
                                    "CountryId": "RU",
                                    "Location": "136.95, 50.4"
                                }
                            ]
                        },
                        {
                            "Id": "KIRE",
                            "Name": "Kirensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "108.11194, 57.785278",
                            "IataCode": "KCK",
                            "Airports": [
                                {
                                    "Id": "KCK",
                                    "Name": "Kirensk",
                                    "CityId": "KIRE",
                                    "CountryId": "RU",
                                    "Location": "108.0608, 57.772779"
                                }
                            ]
                        },
                        {
                            "Id": "HATA",
                            "Name": "Hatanga",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "102.5, 71.966667",
                            "IataCode": "HTG",
                            "Airports": [
                                {
                                    "Id": "HTG",
                                    "Name": "Hatanga",
                                    "CityId": "HATA",
                                    "CountryId": "RU",
                                    "Location": "102.5, 71.966667"
                                }
                            ]
                        },
                        {
                            "Id": "KDYR",
                            "Name": "Khandyga",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "135.6, 62.666667",
                            "IataCode": "KDY",
                            "Airports": [
                                {
                                    "Id": "KDY",
                                    "Name": "Teply Klyuch",
                                    "CityId": "KDYR",
                                    "CountryId": "RU",
                                    "Location": "136.84222, 62.786111"
                                }
                            ]
                        },
                        {
                            "Id": "KVRA",
                            "Name": "Kavalerovo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "135.05472, 44.270556",
                            "IataCode": "KVR",
                            "Airports": [
                                {
                                    "Id": "KVR",
                                    "Name": "Kavalerovo",
                                    "CityId": "KVRA",
                                    "CountryId": "RU",
                                    "Location": "135.0289, 44.2725"
                                }
                            ]
                        },
                        {
                            "Id": "DLRA",
                            "Name": "Dalnerechensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "133.7225, 45.933889",
                            "IataCode": "DLR",
                            "Airports": [
                                {
                                    "Id": "DLR",
                                    "Name": "Dalnerechensk",
                                    "CityId": "DLRA",
                                    "CountryId": "RU",
                                    "Location": "133.73137, 45.877972"
                                }
                            ]
                        },
                        {
                            "Id": "DHGA",
                            "Name": "Dalnegorsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "135.57167, 44.551111",
                            "IataCode": "DHG",
                            "Airports": [
                                {
                                    "Id": "DHG",
                                    "Name": "Dalnegorsk",
                                    "CityId": "DHGA",
                                    "CountryId": "RU",
                                    "Location": "135.49119, 44.559059"
                                }
                            ]
                        },
                        {
                            "Id": "BODA",
                            "Name": "Bodaybo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "114.19333, 57.850556",
                            "IataCode": "ODO",
                            "Airports": [
                                {
                                    "Id": "ODO",
                                    "Name": "Bodaybo",
                                    "CityId": "BODA",
                                    "CountryId": "RU",
                                    "Location": "114.23524, 57.862014"
                                }
                            ]
                        },
                        {
                            "Id": "BLAG",
                            "Name": "Blagoveschensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "127.53333, 50.266667",
                            "IataCode": "BQS",
                            "Airports": [
                                {
                                    "Id": "BQS",
                                    "Name": "Blagoveschensk",
                                    "CityId": "BLAG",
                                    "CountryId": "RU",
                                    "Location": "127.4, 50.416667"
                                }
                            ]
                        },
                        {
                            "Id": "BGJY",
                            "Name": "Batagay",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "134.63, 67.63",
                            "IataCode": "BQJ",
                            "Airports": [
                                {
                                    "Id": "BQJ",
                                    "Name": "Batagay Airport",
                                    "CityId": "BGJY",
                                    "CountryId": "RU",
                                    "Location": "134.695, 67.648889"
                                }
                            ]
                        },
                        {
                            "Id": "AEMA",
                            "Name": "Amgu",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "137.68, 45.84",
                            "IataCode": "AEM",
                            "Airports": [
                                {
                                    "Id": "AEM",
                                    "Name": "Amgu",
                                    "CityId": "AEMA",
                                    "CountryId": "RU",
                                    "Location": "137.67833, 45.841667"
                                }
                            ]
                        },
                        {
                            "Id": "DPTA",
                            "Name": "Deputatsky",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "139.9, 69.3",
                            "IataCode": "DPT",
                            "Airports": [
                                {
                                    "Id": "DPT",
                                    "Name": "Deputatsky",
                                    "CityId": "DPTA",
                                    "CountryId": "RU",
                                    "Location": "139.90194, 69.3925"
                                }
                            ]
                        },
                        {
                            "Id": "ZKPC",
                            "Name": "Zyryanka",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "150.85, 65.833333",
                            "IataCode": "ZKP",
                            "Airports": [
                                {
                                    "Id": "ZKP",
                                    "Name": "Zyryanka Airport",
                                    "CityId": "ZKPC",
                                    "CountryId": "RU",
                                    "Location": "150.70778, 65.738333"
                                }
                            ]
                        },
                        {
                            "Id": "DEEA",
                            "Name": "Yuzhno-Kurilsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "145.85556, 44.030556",
                            "IataCode": "DEE",
                            "Airports": [
                                {
                                    "Id": "DEE",
                                    "Name": "Mendeleyevo",
                                    "CityId": "DEEA",
                                    "CountryId": "RU",
                                    "Location": "145.683, 43.958401"
                                }
                            ]
                        },
                        {
                            "Id": "USRC",
                            "Name": "Ust-Nera",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "143.2, 64.566667",
                            "IataCode": "USR",
                            "Airports": [
                                {
                                    "Id": "USR",
                                    "Name": "Ust-Nera Airport",
                                    "CityId": "USRC",
                                    "CountryId": "RU",
                                    "Location": "143.11083, 64.549444"
                                }
                            ]
                        },
                        {
                            "Id": "SEKA",
                            "Name": "Srednekolymsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "153.68333, 67.45",
                            "IataCode": "SEK",
                            "Airports": [
                                {
                                    "Id": "SEK",
                                    "Name": "Srednekolymsk",
                                    "CityId": "SEKA",
                                    "CountryId": "RU",
                                    "Location": "153.735, 67.479722"
                                }
                            ]
                        },
                        {
                            "Id": "GVNA",
                            "Name": "Sovetskaya Gavan",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "140.3, 48.966667",
                            "IataCode": "GVN",
                            "Airports": [
                                {
                                    "Id": "GVN",
                                    "Name": "May-Gatka",
                                    "CityId": "GVNA",
                                    "CountryId": "RU",
                                    "Location": "140.034, 48.926998"
                                }
                            ]
                        },
                        {
                            "Id": "SHAK",
                            "Name": "Shakhtyorsk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "142.13333, 49.166667",
                            "IataCode": "EKS",
                            "Airports": [
                                {
                                    "Id": "EKS",
                                    "Name": "Shakhtyorsk",
                                    "CityId": "SHAK",
                                    "CountryId": "RU",
                                    "Location": "142.08346, 49.190486"
                                }
                            ]
                        },
                        {
                            "Id": "OHHA",
                            "Name": "Okha",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "142.94778, 53.573889",
                            "IataCode": "OHH",
                            "Airports": [
                                {
                                    "Id": "OHH",
                                    "Name": "Okha",
                                    "CityId": "OHHA",
                                    "CountryId": "RU",
                                    "Location": "142.8839, 53.5172"
                                }
                            ]
                        },
                        {
                            "Id": "NOGL",
                            "Name": "Nogliki",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "143.16667, 51.833333",
                            "IataCode": "NGK",
                            "Airports": [
                                {
                                    "Id": "NGK",
                                    "Name": "Nogliki",
                                    "CityId": "NOGL",
                                    "CountryId": "RU",
                                    "Location": "143.14343, 51.789874"
                                }
                            ]
                        },
                        {
                            "Id": "NLIA",
                            "Name": "Nikolaevsk-na-Amure",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "140.73, 53.140556",
                            "IataCode": "NLI",
                            "Airports": [
                                {
                                    "Id": "NLI",
                                    "Name": "Nikolaevsk-na-Amure",
                                    "CityId": "NLIA",
                                    "CountryId": "RU",
                                    "Location": "140.65028, 53.152778"
                                }
                            ]
                        },
                        {
                            "Id": "KVMA",
                            "Name": "Markovo",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "170.41667, 64.666667",
                            "IataCode": "KVM",
                            "Airports": [
                                {
                                    "Id": "KVM",
                                    "Name": "Markovo",
                                    "CityId": "KVMA",
                                    "CountryId": "RU",
                                    "Location": "170.41417, 64.665278"
                                }
                            ]
                        },
                        {
                            "Id": "MQJC",
                            "Name": "Khonnu",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "143.1, 66.45",
                            "IataCode": "MQJ",
                            "Airports": [
                                {
                                    "Id": "MQJ",
                                    "Name": "Moma Airport",
                                    "CityId": "MQJC",
                                    "CountryId": "RU",
                                    "Location": "143.25306, 66.455833"
                                }
                            ]
                        },
                        {
                            "Id": "KPWA",
                            "Name": "Keperveyem",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "166.1, 67.816667",
                            "IataCode": "KPW",
                            "Airports": [
                                {
                                    "Id": "KPW",
                                    "Name": "Keperveyem",
                                    "CityId": "KPWA",
                                    "CountryId": "RU",
                                    "Location": "166.13889, 67.846944"
                                }
                            ]
                        },
                        {
                            "Id": "SWVB",
                            "Name": "Evensk",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "159.23333, 61.95",
                            "IataCode": "SWV",
                            "Airports": [
                                {
                                    "Id": "SWV",
                                    "Name": "Severo-Evensk",
                                    "CityId": "SWVB",
                                    "CountryId": "RU",
                                    "Location": "159.23111, 61.924444"
                                }
                            ]
                        },
                        {
                            "Id": "CHOK",
                            "Name": "Chokurdah",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "147.91667, 70.633333",
                            "IataCode": "CKH",
                            "Airports": [
                                {
                                    "Id": "CKH",
                                    "Name": "Chokurdah",
                                    "CityId": "CHOK",
                                    "CountryId": "RU",
                                    "Location": "147.88333, 70.633333"
                                }
                            ]
                        },
                        {
                            "Id": "BQGA",
                            "Name": "Bogorodskoye",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "140.44556, 52.365833",
                            "IataCode": "BQG",
                            "Airports": [
                                {
                                    "Id": "BQG",
                                    "Name": "Bogorodskoye",
                                    "CityId": "BQGA",
                                    "CountryId": "RU",
                                    "Location": "140.4604, 52.3753"
                                }
                            ]
                        },
                        {
                            "Id": "BGNC",
                            "Name": "Belaya Gora",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "146.41, 68.53",
                            "IataCode": "BGN",
                            "Airports": [
                                {
                                    "Id": "BGN",
                                    "Name": "Belaya Gora Airport",
                                    "CityId": "BGNC",
                                    "CountryId": "RU",
                                    "Location": "146.18583, 68.538056"
                                }
                            ]
                        },
                        {
                            "Id": "PROV",
                            "Name": "Provideniya",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "173.3, 64.28",
                            "IataCode": "PVS",
                            "Airports": [
                                {
                                    "Id": "PVS",
                                    "Name": "Provideniya",
                                    "CityId": "PROV",
                                    "CountryId": "RU",
                                    "Location": "173.3, 64.383333"
                                }
                            ]
                        },
                        {
                            "Id": "ITUA",
                            "Name": "Iturup Island",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "147.617, 45.033",
                            "IataCode": "ITU",
                            "Airports": [
                                {
                                    "Id": "ITU",
                                    "Name": "Iturup",
                                    "CityId": "ITUA",
                                    "CountryId": "RU",
                                    "Location": "147.95583, 45.256389"
                                }
                            ]
                        },
                        {
                            "Id": "OKTI",
                            "Name": "Klyuchëvka",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "54.416667, 53.366667",
                            "IataCode": "OKT",
                            "Airports": [
                                {
                                    "Id": "OKT",
                                    "Name": "Oktiabrskij",
                                    "CityId": "OKTI",
                                    "CountryId": "RU",
                                    "Location": "53.383333, 54.433333"
                                }
                            ]
                        },
                        {
                            "Id": "CKLA",
                            "Name": "Bol'shiye Zherebtsy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "38.044167, 55.859722",
                            "IataCode": "CKL",
                            "Airports": [
                                {
                                    "Id": "CKL",
                                    "Name": "Chkalovsky",
                                    "CityId": "CKLA",
                                    "CountryId": "RU",
                                    "Location": "38.051944, 55.866667"
                                }
                            ]
                        },
                        {
                            "Id": "POLY",
                            "Name": "Udachnyy",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "112.05, 66.416667",
                            "IataCode": "PYJ",
                            "Airports": [
                                {
                                    "Id": "PYJ",
                                    "Name": "Polyarnyj",
                                    "CityId": "POLY",
                                    "CountryId": "RU",
                                    "Location": "112.05, 66.416667"
                                }
                            ]
                        },
                        {
                            "Id": "TLKA",
                            "Name": "Bogatyr'",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "110.88333, 59.716667",
                            "IataCode": "TLK",
                            "Airports": [
                                {
                                    "Id": "TLK",
                                    "Name": "Talakan",
                                    "CityId": "TLKA",
                                    "CountryId": "RU",
                                    "Location": "111.04012, 59.867482"
                                }
                            ]
                        },
                        {
                            "Id": "SUKB",
                            "Name": "Batagay-Alyta",
                            "SingleAirportCity": true,
                            "CountryId": "RU",
                            "Location": "130.41, 67.8",
                            "IataCode": "SUK",
                            "Airports": [
                                {
                                    "Id": "SUK",
                                    "Name": "Sakkyryr",
                                    "CityId": "SUKB",
                                    "CountryId": "RU",
                                    "Location": "130.39083, 67.791944"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PL",
                    "Name": "Poland",
                    "CurrencyId": "PLN",
                    "LanguageId": "PL",
                    "Cities": [
                        {
                            "Id": "WROC",
                            "Name": "Wroclaw",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "17.033333, 51.100003",
                            "IataCode": "WRO",
                            "Airports": [
                                {
                                    "Id": "WRO",
                                    "Name": "Wroclaw",
                                    "CityId": "WROC",
                                    "CountryId": "PL",
                                    "Location": "16.883333, 51.1"
                                }
                            ]
                        },
                        {
                            "Id": "BYDG",
                            "Name": "Bydgoszcz",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "18.000001, 53.15",
                            "IataCode": "BZG",
                            "Airports": [
                                {
                                    "Id": "BZG",
                                    "Name": "Bydgoszcz",
                                    "CityId": "BYDG",
                                    "CountryId": "PL",
                                    "Location": "17.978611, 53.096667"
                                }
                            ]
                        },
                        {
                            "Id": "CZES",
                            "Name": "Czestochowa",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "19.116668, 50.8",
                            "IataCode": "CZW",
                            "Airports": [
                                {
                                    "Id": "CZW",
                                    "Name": "Czestochowa",
                                    "CityId": "CZES",
                                    "CountryId": "PL",
                                    "Location": "19.1, 50.816667"
                                }
                            ]
                        },
                        {
                            "Id": "GADA",
                            "Name": "Gdansk",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "18.637258, 54.359752",
                            "IataCode": "GDN",
                            "Airports": [
                                {
                                    "Id": "GDN",
                                    "Name": "Gdansk",
                                    "CityId": "GADA",
                                    "CountryId": "PL",
                                    "Location": "18.470078, 54.376558"
                                }
                            ]
                        },
                        {
                            "Id": "ZIEL",
                            "Name": "Zielona Gora Babimost",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "15.5, 51.933335",
                            "IataCode": "IEG",
                            "Airports": [
                                {
                                    "Id": "IEG",
                                    "Name": "Zielona Gora Babimost",
                                    "CityId": "ZIEL",
                                    "CountryId": "PL",
                                    "Location": "15.798556, 52.138517"
                                }
                            ]
                        },
                        {
                            "Id": "KRAK",
                            "Name": "Krakow",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "19.916667, 50.083333",
                            "IataCode": "KRK",
                            "Airports": [
                                {
                                    "Id": "KRK",
                                    "Name": "Krakow",
                                    "CityId": "KRAK",
                                    "CountryId": "PL",
                                    "Location": "19.784836, 50.077731"
                                }
                            ]
                        },
                        {
                            "Id": "KATO",
                            "Name": "Katowice",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "19.016667, 50.266667",
                            "IataCode": "KTW",
                            "Airports": [
                                {
                                    "Id": "KTW",
                                    "Name": "Katowice",
                                    "CityId": "KATO",
                                    "CountryId": "PL",
                                    "Location": "19.080591, 50.473621"
                                }
                            ]
                        },
                        {
                            "Id": "LODZ",
                            "Name": "Lodz",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "19.466667, 51.750002",
                            "IataCode": "LCJ",
                            "Airports": [
                                {
                                    "Id": "LCJ",
                                    "Name": "Lodz",
                                    "CityId": "LODZ",
                                    "CountryId": "PL",
                                    "Location": "19.398333, 51.721944"
                                }
                            ]
                        },
                        {
                            "Id": "POZN",
                            "Name": "Poznan",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "16.966666, 52.41667",
                            "IataCode": "POZ",
                            "Airports": [
                                {
                                    "Id": "POZ",
                                    "Name": "Poznan",
                                    "CityId": "POZN",
                                    "CountryId": "PL",
                                    "Location": "16.826325, 52.421031"
                                }
                            ]
                        },
                        {
                            "Id": "RZES",
                            "Name": "Rzeszow",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "22, 50.05",
                            "IataCode": "RZE",
                            "Airports": [
                                {
                                    "Id": "RZE",
                                    "Name": "Rzeszow",
                                    "CityId": "RZES",
                                    "CountryId": "PL",
                                    "Location": "22.026901, 50.113533"
                                }
                            ]
                        },
                        {
                            "Id": "SZYA",
                            "Name": "Szczytno",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "21, 53.566667",
                            "IataCode": "SZY",
                            "Airports": [
                                {
                                    "Id": "SZY",
                                    "Name": "Olsztyn-Mazury",
                                    "CityId": "SZYA",
                                    "CountryId": "PL",
                                    "Location": "20.937778, 53.481944"
                                }
                            ]
                        },
                        {
                            "Id": "SZCZ",
                            "Name": "Szczecin",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "14.552937, 53.42938",
                            "IataCode": "SZZ",
                            "Airports": [
                                {
                                    "Id": "SZZ",
                                    "Name": "Szczecin Goleniow",
                                    "CityId": "SZCZ",
                                    "CountryId": "PL",
                                    "Location": "14.9, 53.583333"
                                }
                            ]
                        },
                        {
                            "Id": "WARS",
                            "Name": "Warsaw",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "21, 52.250005",
                            "IataCode": "WAW",
                            "Airports": [
                                {
                                    "Id": "WAW",
                                    "Name": "Warsaw Chopin",
                                    "CityId": "WARS",
                                    "CountryId": "PL",
                                    "Location": "20.966667, 52.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SLUP",
                            "Name": "Slupsk Redzikowo",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "17.033333, 54.450001",
                            "IataCode": "OSP",
                            "Airports": [
                                {
                                    "Id": "OSP",
                                    "Name": "Slupsk Redzikowo",
                                    "CityId": "SLUP",
                                    "CountryId": "PL",
                                    "Location": "17.016667, 54.466667"
                                }
                            ]
                        },
                        {
                            "Id": "KOSZ",
                            "Name": "Koszalin",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "16.183333, 54.200001",
                            "IataCode": "OSZ",
                            "Airports": [
                                {
                                    "Id": "OSZ",
                                    "Name": "Koszalin",
                                    "CityId": "KOSZ",
                                    "CountryId": "PL",
                                    "Location": "16.264444, 54.042778"
                                }
                            ]
                        },
                        {
                            "Id": "CLUZ",
                            "Name": "Lublin",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "22.566667, 51.250001",
                            "IataCode": "LUZ",
                            "Airports": [
                                {
                                    "Id": "LUZ",
                                    "Name": "Lublin",
                                    "CityId": "CLUZ",
                                    "CountryId": "PL",
                                    "Location": "22.690278, 51.231944"
                                }
                            ]
                        },
                        {
                            "Id": "RDOA",
                            "Name": "Radom",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "21.15, 51.416669",
                            "IataCode": "RDO",
                            "Airports": [
                                {
                                    "Id": "RDO",
                                    "Name": "Radom",
                                    "CityId": "RDOA",
                                    "CountryId": "PL",
                                    "Location": "21.213611, 51.389167"
                                }
                            ]
                        },
                        {
                            "Id": "WMIP",
                            "Name": "Nowy Dwor Mazowiecki",
                            "SingleAirportCity": true,
                            "CountryId": "PL",
                            "Location": "20.716667, 52.433333",
                            "IataCode": "WMI",
                            "Airports": [
                                {
                                    "Id": "WMI",
                                    "Name": "Warsaw Modlin",
                                    "CityId": "WMIP",
                                    "CountryId": "PL",
                                    "Location": "20.654275, 52.447176"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LI",
                    "Name": "Liechtenstein",
                    "CurrencyId": "CHF",
                    "Cities": []
                },
                {
                    "Id": "FI",
                    "Name": "Finland",
                    "CurrencyId": "EUR",
                    "Regions": [
                        {
                            "Id": "LAPLA",
                            "Name": "Lapland",
                            "CountryId": "FI"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "YLIV",
                            "Name": "Ylivieska",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "24.55, 64.083333",
                            "IataCode": "YLI",
                            "Airports": [
                                {
                                    "Id": "YLI",
                                    "Name": "Ylivieska",
                                    "CityId": "YLIV",
                                    "CountryId": "FI",
                                    "Location": "24.728889, 64.054444"
                                }
                            ]
                        },
                        {
                            "Id": "ENON",
                            "Name": "Enontekio",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "23.633333, 68.383333",
                            "IataCode": "ENF",
                            "Airports": [
                                {
                                    "Id": "ENF",
                                    "Name": "Enontekio",
                                    "CityId": "ENON",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "23.416667, 68.35"
                                }
                            ]
                        },
                        {
                            "Id": "HELS",
                            "Name": "Helsinki",
                            "SingleAirportCity": false,
                            "CountryId": "FI",
                            "Location": "25.017531, 60.113476",
                            "IataCode": "HEL",
                            "Airports": [
                                {
                                    "Id": "HEN",
                                    "Name": "Hendon",
                                    "CityId": "HELS",
                                    "CountryId": "FI",
                                    "Location": "24.924057, 60.147714"
                                },
                                {
                                    "Id": "HEL",
                                    "Name": "Helsinki Vantaa",
                                    "CityId": "HELS",
                                    "CountryId": "FI",
                                    "Location": "24.952978, 60.321031"
                                },
                                {
                                    "Id": "HEM",
                                    "Name": "Helsinki Malmi",
                                    "CityId": "HELS",
                                    "CountryId": "FI",
                                    "Location": "25.05, 60.25"
                                }
                            ]
                        },
                        {
                            "Id": "HYVI",
                            "Name": "Hyvinkaa",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "24.866667, 60.633333",
                            "IataCode": "HYV",
                            "Airports": [
                                {
                                    "Id": "HYV",
                                    "Name": "Hyvinkaa",
                                    "CityId": "HYVI",
                                    "CountryId": "FI",
                                    "Location": "24.884444, 60.654167"
                                }
                            ]
                        },
                        {
                            "Id": "IVAL",
                            "Name": "Ivalo",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "27.6, 68.65",
                            "IataCode": "IVL",
                            "Airports": [
                                {
                                    "Id": "IVL",
                                    "Name": "Ivalo",
                                    "CityId": "IVAL",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "27.415556, 68.611111"
                                }
                            ]
                        },
                        {
                            "Id": "JOEN",
                            "Name": "Joensuu",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "29.766667, 62.600001",
                            "IataCode": "JOE",
                            "Airports": [
                                {
                                    "Id": "JOE",
                                    "Name": "Joensuu",
                                    "CityId": "JOEN",
                                    "CountryId": "FI",
                                    "Location": "29.608889, 62.6625"
                                }
                            ]
                        },
                        {
                            "Id": "JYVA",
                            "Name": "Jyvaskyla",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "25.733333, 62.233335",
                            "IataCode": "JYV",
                            "Airports": [
                                {
                                    "Id": "JYV",
                                    "Name": "Jyvaskyla",
                                    "CityId": "JYVA",
                                    "CountryId": "FI",
                                    "Location": "25.681944, 62.399167"
                                }
                            ]
                        },
                        {
                            "Id": "KAJA",
                            "Name": "Kajaani",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "27.726574, 64.22818",
                            "IataCode": "KAJ",
                            "Airports": [
                                {
                                    "Id": "KAJ",
                                    "Name": "Kajaani",
                                    "CityId": "KAJA",
                                    "CountryId": "FI",
                                    "Location": "27.688889, 64.277778"
                                }
                            ]
                        },
                        {
                            "Id": "KUUS",
                            "Name": "Kuusamo",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "29.183333, 65.966667",
                            "IataCode": "KAO",
                            "Airports": [
                                {
                                    "Id": "KAO",
                                    "Name": "Kuusamo",
                                    "CityId": "KUUS",
                                    "CountryId": "FI",
                                    "Location": "29.233889, 65.990278"
                                }
                            ]
                        },
                        {
                            "Id": "KAUH",
                            "Name": "Kauhava",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "23.083333, 63.1",
                            "IataCode": "KAU",
                            "Airports": [
                                {
                                    "Id": "KAU",
                                    "Name": "Kauhava",
                                    "CityId": "KAUH",
                                    "CountryId": "FI",
                                    "Location": "23.083333, 63.1"
                                }
                            ]
                        },
                        {
                            "Id": "KAUI",
                            "Name": "Kauhajoki",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "22.183333, 62.433333",
                            "IataCode": "KHJ",
                            "Airports": [
                                {
                                    "Id": "KHJ",
                                    "Name": "Kauhajoki",
                                    "CityId": "KAUI",
                                    "CountryId": "FI",
                                    "Location": "22.394722, 62.463056"
                                }
                            ]
                        },
                        {
                            "Id": "KITE",
                            "Name": "Kitee",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "30.15, 62.1",
                            "IataCode": "KTQ",
                            "Airports": [
                                {
                                    "Id": "KTQ",
                                    "Name": "Kitee",
                                    "CityId": "KITE",
                                    "CountryId": "FI",
                                    "Location": "30.076667, 62.165833"
                                }
                            ]
                        },
                        {
                            "Id": "KITT",
                            "Name": "Kittilä",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "24.9, 67.666667",
                            "IataCode": "KTT",
                            "Airports": [
                                {
                                    "Id": "KTT",
                                    "Name": "Kittilä",
                                    "CityId": "KITT",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "24.8469, 67.701"
                                }
                            ]
                        },
                        {
                            "Id": "KUOP",
                            "Name": "Kuopio",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "27.683333, 62.9",
                            "IataCode": "KUO",
                            "Airports": [
                                {
                                    "Id": "KUO",
                                    "Name": "Kuopio",
                                    "CityId": "KUOP",
                                    "CountryId": "FI",
                                    "Location": "27.798333, 63.005556"
                                }
                            ]
                        },
                        {
                            "Id": "LAPP",
                            "Name": "Lappeenranta",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "28.183333, 61.066667",
                            "IataCode": "LPP",
                            "Airports": [
                                {
                                    "Id": "LPP",
                                    "Name": "Lappeenranta",
                                    "CityId": "LAPP",
                                    "CountryId": "FI",
                                    "Location": "28.156667, 61.046111"
                                }
                            ]
                        },
                        {
                            "Id": "MARJ",
                            "Name": "Mariehamn",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "19.95, 60.1",
                            "IataCode": "MHQ",
                            "Airports": [
                                {
                                    "Id": "MHQ",
                                    "Name": "Mariehamn",
                                    "CityId": "MARJ",
                                    "CountryId": "FI",
                                    "Location": "19.896667, 60.123333"
                                }
                            ]
                        },
                        {
                            "Id": "MIKK",
                            "Name": "Mikkeli",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "27.25, 61.683333",
                            "IataCode": "MIK",
                            "Airports": [
                                {
                                    "Id": "MIK",
                                    "Name": "Mikkeli",
                                    "CityId": "MIKK",
                                    "CountryId": "FI",
                                    "Location": "27.213056, 61.683889"
                                }
                            ]
                        },
                        {
                            "Id": "OULU",
                            "Name": "Oulu",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "25.466667, 65.01667",
                            "IataCode": "OUL",
                            "Airports": [
                                {
                                    "Id": "OUL",
                                    "Name": "Oulu",
                                    "CityId": "OULU",
                                    "CountryId": "FI",
                                    "Location": "25.358333, 64.929167"
                                }
                            ]
                        },
                        {
                            "Id": "PORI",
                            "Name": "Pori",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "21.783333, 61.483334",
                            "IataCode": "POR",
                            "Airports": [
                                {
                                    "Id": "POR",
                                    "Name": "Pori",
                                    "CityId": "PORI",
                                    "CountryId": "FI",
                                    "Location": "21.797222, 61.463056"
                                }
                            ]
                        },
                        {
                            "Id": "ROVA",
                            "Name": "Rovaniemi",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "25.716667, 66.5",
                            "IataCode": "RVN",
                            "Airports": [
                                {
                                    "Id": "RVN",
                                    "Name": "Rovaniemi",
                                    "CityId": "ROVA",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "25.826667, 66.563056"
                                }
                            ]
                        },
                        {
                            "Id": "SEIN",
                            "Name": "Seinajoki Ilmajoki",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "22.833333, 62.8",
                            "IataCode": "SJY",
                            "Airports": [
                                {
                                    "Id": "SJY",
                                    "Name": "Seinajoki Ilmajoki",
                                    "CityId": "SEIN",
                                    "CountryId": "FI",
                                    "Location": "22.831667, 62.693333"
                                }
                            ]
                        },
                        {
                            "Id": "TURK",
                            "Name": "Turku",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "22.283333, 60.450002",
                            "IataCode": "TKU",
                            "Airports": [
                                {
                                    "Id": "TKU",
                                    "Name": "Turku",
                                    "CityId": "TURK",
                                    "CountryId": "FI",
                                    "Location": "22.270556, 60.514167"
                                }
                            ]
                        },
                        {
                            "Id": "TAMP",
                            "Name": "Tampere",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "23.787117, 61.499115",
                            "IataCode": "TMP",
                            "Airports": [
                                {
                                    "Id": "TMP",
                                    "Name": "Tampere",
                                    "CityId": "TAMP",
                                    "CountryId": "FI",
                                    "Location": "23.738889, 61.463889"
                                }
                            ]
                        },
                        {
                            "Id": "VAAS",
                            "Name": "Vaasa",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "21.615772, 63.096001",
                            "IataCode": "VAA",
                            "Airports": [
                                {
                                    "Id": "VAA",
                                    "Name": "Vaasa",
                                    "CityId": "VAAS",
                                    "CountryId": "FI",
                                    "Location": "21.761389, 63.045"
                                }
                            ]
                        },
                        {
                            "Id": "VARK",
                            "Name": "Varkaus",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "27.916667, 62.316667",
                            "IataCode": "VRK",
                            "Airports": [
                                {
                                    "Id": "VRK",
                                    "Name": "Varkaus",
                                    "CityId": "VARK",
                                    "CountryId": "FI",
                                    "Location": "27.868611, 62.171111"
                                }
                            ]
                        },
                        {
                            "Id": "SODA",
                            "Name": "Sodankyla",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "26.6, 67.416667",
                            "IataCode": "SOT",
                            "Airports": [
                                {
                                    "Id": "SOT",
                                    "Name": "Sodankyla",
                                    "CityId": "SODA",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "26.616667, 67.383333"
                                }
                            ]
                        },
                        {
                            "Id": "SAVO",
                            "Name": "Savonlinna",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "28.883333, 61.866667",
                            "IataCode": "SVL",
                            "Airports": [
                                {
                                    "Id": "SVL",
                                    "Name": "Savonlinna",
                                    "CityId": "SAVO",
                                    "CountryId": "FI",
                                    "Location": "28.95, 61.95"
                                }
                            ]
                        },
                        {
                            "Id": "KEMI",
                            "Name": "Kemi / Tornio",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "RegionId": "LAPLA",
                            "Location": "24.566667, 65.733333",
                            "IataCode": "KEM",
                            "Airports": [
                                {
                                    "Id": "KEM",
                                    "Name": "Kemi / Tornio",
                                    "CityId": "KEMI",
                                    "CountryId": "FI",
                                    "RegionId": "LAPLA",
                                    "Location": "24.585833, 65.78"
                                }
                            ]
                        },
                        {
                            "Id": "KOKK",
                            "Name": "Kokkola / Pietarsaari Kruunupyy",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "23.116666, 63.833333",
                            "IataCode": "KOK",
                            "Airports": [
                                {
                                    "Id": "KOK",
                                    "Name": "Kokkola / Pietarsaari Kruunupyy",
                                    "CityId": "KOKK",
                                    "CountryId": "FI",
                                    "Location": "23.15, 63.716667"
                                }
                            ]
                        },
                        {
                            "Id": "KUOR",
                            "Name": "Kuorevesi",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "24.783334, 61.85",
                            "IataCode": "KEV",
                            "Airports": [
                                {
                                    "Id": "KEV",
                                    "Name": "Kuorevesi Halli",
                                    "CityId": "KUOR",
                                    "CountryId": "FI",
                                    "Location": "24.786667, 61.856111"
                                }
                            ]
                        },
                        {
                            "Id": "KOUV",
                            "Name": "Utti",
                            "SingleAirportCity": true,
                            "CountryId": "FI",
                            "Location": "26.933333, 60.883333",
                            "IataCode": "QVY",
                            "Airports": [
                                {
                                    "Id": "UTI",
                                    "Name": "Kouvola Utti",
                                    "CityId": "KOUV",
                                    "CountryId": "FI",
                                    "Location": "26.933333, 60.9"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AT",
                    "Name": "Austria",
                    "CurrencyId": "EUR",
                    "LanguageId": "DE",
                    "Cities": [
                        {
                            "Id": "GRAZ",
                            "Name": "Graz",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "15.45, 47.066667",
                            "IataCode": "GRZ",
                            "Airports": [
                                {
                                    "Id": "GRZ",
                                    "Name": "Graz",
                                    "CityId": "GRAZ",
                                    "CountryId": "AT",
                                    "Location": "15.44, 46.993333"
                                }
                            ]
                        },
                        {
                            "Id": "HOHE",
                            "Name": "Hohenems",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "9.6830556, 47.366667",
                            "IataCode": "HOH",
                            "Airports": [
                                {
                                    "Id": "HOH",
                                    "Name": "Hohenems",
                                    "CityId": "HOHE",
                                    "CountryId": "AT",
                                    "Location": "9.7, 47.383333"
                                }
                            ]
                        },
                        {
                            "Id": "INNS",
                            "Name": "Innsbruck",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "11.399999, 47.266667",
                            "IataCode": "INN",
                            "Airports": [
                                {
                                    "Id": "INN",
                                    "Name": "Innsbruck",
                                    "CityId": "INNS",
                                    "CountryId": "AT",
                                    "Location": "11.347222, 47.260833"
                                }
                            ]
                        },
                        {
                            "Id": "KLAG",
                            "Name": "Klagenfurt",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "14.305278, 46.624722",
                            "IataCode": "KLU",
                            "Airports": [
                                {
                                    "Id": "KLU",
                                    "Name": "Klagenfurt",
                                    "CityId": "KLAG",
                                    "CountryId": "AT",
                                    "Location": "14.341667, 46.643056"
                                }
                            ]
                        },
                        {
                            "Id": "LINZ",
                            "Name": "Linz",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "14.286111, 48.30639",
                            "IataCode": "LNZ",
                            "Airports": [
                                {
                                    "Id": "LNZ",
                                    "Name": "Linz",
                                    "CityId": "LINZ",
                                    "CountryId": "AT",
                                    "Location": "14.193611, 48.234444"
                                }
                            ]
                        },
                        {
                            "Id": "SALZ",
                            "Name": "Salzburg",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "13.033334, 47.8",
                            "IataCode": "SZG",
                            "Airports": [
                                {
                                    "Id": "SZG",
                                    "Name": "Salzburg",
                                    "CityId": "SALZ",
                                    "CountryId": "AT",
                                    "Location": "13.003332, 47.794244"
                                }
                            ]
                        },
                        {
                            "Id": "VIEN",
                            "Name": "Vienna",
                            "SingleAirportCity": false,
                            "CountryId": "AT",
                            "Location": "16.377888, 48.208451",
                            "IataCode": "VIE",
                            "Airports": [
                                {
                                    "Id": "VIE",
                                    "Name": "Vienna",
                                    "CityId": "VIEN",
                                    "CountryId": "AT",
                                    "Location": "16.55751, 48.1221"
                                },
                                {
                                    "Id": "VDD",
                                    "Name": "Vienna Vienna Danubepier Hov",
                                    "CityId": "VIEN",
                                    "CountryId": "AT",
                                    "Location": "16.366667, 48.2"
                                }
                            ]
                        },
                        {
                            "Id": "QLXA",
                            "Name": "Lauterach",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "9.7330556, 47.483056",
                            "IataCode": "QLX",
                            "Airports": [
                                {
                                    "Id": "QLX",
                                    "Name": "Lauterach",
                                    "CityId": "QLXA",
                                    "CountryId": "AT",
                                    "Location": "9.733333, 47.483333"
                                }
                            ]
                        },
                        {
                            "Id": "ANTB",
                            "Name": "St Anton",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "10.26372, 47.127498",
                            "IataCode": "ANT",
                            "Airports": [
                                {
                                    "Id": "ANT",
                                    "Name": "St Anton",
                                    "CityId": "ANTB",
                                    "CountryId": "AT",
                                    "Location": "10.283333, 47.15"
                                }
                            ]
                        },
                        {
                            "Id": "ZRSA",
                            "Name": "Zürs",
                            "SingleAirportCity": true,
                            "CountryId": "AT",
                            "Location": "10.166667, 47.166667",
                            "IataCode": "ZRS",
                            "Airports": [
                                {
                                    "Id": "ZRS",
                                    "Name": "Zurs / Lech",
                                    "CityId": "ZRSA",
                                    "CountryId": "AT",
                                    "Location": "0, 0"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ES",
                    "Name": "Spain",
                    "CurrencyId": "EUR",
                    "LanguageId": "ES",
                    "Regions": [
                        {
                            "Id": "ILLES",
                            "Name": "Balearic Islands",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "LA_RI",
                            "Name": "La Rioja",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "MADRI",
                            "Name": "Community of Madrid",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "MURCI",
                            "Name": "Region of Murcia",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "NAVAR",
                            "Name": "Navarre",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "ASTUR",
                            "Name": "Asturias",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "CANTA",
                            "Name": "Cantabria",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "ARAGO",
                            "Name": "Aragon",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "CANAR",
                            "Name": "Canary Islands",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "CAS_M",
                            "Name": "Castile-La Mancha",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "CAS_L",
                            "Name": "Castile and León",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "CATAL",
                            "Name": "Catalonia",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "EXTRE",
                            "Name": "Extremadura",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "GALIC",
                            "Name": "Galicia",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "PAIS_",
                            "Name": "Basque Country",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "VALEN",
                            "Name": "Valencian Community",
                            "CountryId": "ES"
                        },
                        {
                            "Id": "MELIL",
                            "Name": "Autonomous City of Melilla",
                            "CountryId": "ES"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "JERE",
                            "Name": "Jerez",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-6.1333338, 36.683333",
                            "IataCode": "XRY",
                            "Airports": [
                                {
                                    "Id": "XRY",
                                    "Name": "Jerez",
                                    "CityId": "JERE",
                                    "CountryId": "ES",
                                    "Location": "-6.058889, 36.745278"
                                }
                            ]
                        },
                        {
                            "Id": "ALIC",
                            "Name": "Alicante",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "VALEN",
                            "Location": "-0.53744057, 38.382225",
                            "IataCode": "ALC",
                            "Airports": [
                                {
                                    "Id": "ALC",
                                    "Name": "Alicante",
                                    "CityId": "ALIC",
                                    "CountryId": "ES",
                                    "RegionId": "VALEN",
                                    "Location": "-0.5600024, 38.285466"
                                }
                            ]
                        },
                        {
                            "Id": "ZARA",
                            "Name": "Zaragoza",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ARAGO",
                            "Location": "-0.87734101, 41.656064",
                            "IataCode": "ZAZ",
                            "Airports": [
                                {
                                    "Id": "ZAZ",
                                    "Name": "Zaragoza",
                                    "CityId": "ZARA",
                                    "CountryId": "ES",
                                    "RegionId": "ARAGO",
                                    "Location": "-1.011086, 41.663423"
                                }
                            ]
                        },
                        {
                            "Id": "BILB",
                            "Name": "Bilbao",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "PAIS_",
                            "Location": "-2.9252815, 43.262706",
                            "IataCode": "BIO",
                            "Airports": [
                                {
                                    "Id": "BIO",
                                    "Name": "Bilbao",
                                    "CityId": "BILB",
                                    "CountryId": "ES",
                                    "RegionId": "PAIS_",
                                    "Location": "-2.9112662, 43.302377"
                                }
                            ]
                        },
                        {
                            "Id": "BADA",
                            "Name": "Badajoz",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "EXTRE",
                            "Location": "-6.9666673, 38.883333",
                            "IataCode": "BJZ",
                            "Airports": [
                                {
                                    "Id": "BJZ",
                                    "Name": "Badajoz",
                                    "CityId": "BADA",
                                    "CountryId": "ES",
                                    "RegionId": "EXTRE",
                                    "Location": "-6.82, 38.891667"
                                }
                            ]
                        },
                        {
                            "Id": "SANS",
                            "Name": "San Sebastian",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "PAIS_",
                            "Location": "-1.9749936, 43.31283",
                            "IataCode": "EAS",
                            "Airports": [
                                {
                                    "Id": "EAS",
                                    "Name": "San Sebastian",
                                    "CityId": "SANS",
                                    "CountryId": "ES",
                                    "RegionId": "PAIS_",
                                    "Location": "-1.79, 43.357778"
                                }
                            ]
                        },
                        {
                            "Id": "FUER",
                            "Name": "Fuerteventura",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-14.02473, 28.35696",
                            "IataCode": "FUE",
                            "Airports": [
                                {
                                    "Id": "FUE",
                                    "Name": "Fuerteventura",
                                    "CityId": "FUER",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-13.863761, 28.452717"
                                }
                            ]
                        },
                        {
                            "Id": "GERO",
                            "Name": "Girona",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CATAL",
                            "Location": "2.8249347, 41.983113",
                            "IataCode": "GRO",
                            "Airports": [
                                {
                                    "Id": "GRO",
                                    "Name": "Girona",
                                    "CityId": "GERO",
                                    "CountryId": "ES",
                                    "RegionId": "CATAL",
                                    "Location": "2.763333, 41.909167"
                                }
                            ]
                        },
                        {
                            "Id": "GRAO",
                            "Name": "Granada",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-3.5914723, 37.18562",
                            "IataCode": "GRX",
                            "Airports": [
                                {
                                    "Id": "GRX",
                                    "Name": "Granada",
                                    "CityId": "GRAO",
                                    "CountryId": "ES",
                                    "Location": "-3.7778719, 37.187454"
                                }
                            ]
                        },
                        {
                            "Id": "HSKA",
                            "Name": "Huesca",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ARAGO",
                            "Location": "-0.4087045, 42.136152",
                            "IataCode": "HSK",
                            "Airports": [
                                {
                                    "Id": "HSK",
                                    "Name": "Huesca",
                                    "CityId": "HSKA",
                                    "CountryId": "ES",
                                    "RegionId": "ARAGO",
                                    "Location": "-0.316111, 42.07756"
                                }
                            ]
                        },
                        {
                            "Id": "IBIZ",
                            "Name": "Ibiza",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ILLES",
                            "Location": "1.4194715, 38.913623",
                            "IataCode": "IBZ",
                            "Airports": [
                                {
                                    "Id": "IBZ",
                                    "Name": "Ibiza",
                                    "CityId": "IBIZ",
                                    "CountryId": "ES",
                                    "RegionId": "ILLES",
                                    "Location": "1.372778, 38.873611"
                                }
                            ]
                        },
                        {
                            "Id": "LACO",
                            "Name": "A Coruña",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "GALIC",
                            "Location": "-8.3960011, 43.37135",
                            "IataCode": "LCG",
                            "Airports": [
                                {
                                    "Id": "LCG",
                                    "Name": "A Coruña",
                                    "CityId": "LACO",
                                    "CountryId": "ES",
                                    "RegionId": "GALIC",
                                    "Location": "-8.379167, 43.299444"
                                }
                            ]
                        },
                        {
                            "Id": "ALME",
                            "Name": "Almeria",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-2.4500001, 36.833333",
                            "IataCode": "LEI",
                            "Airports": [
                                {
                                    "Id": "LEI",
                                    "Name": "Almeria",
                                    "CityId": "ALME",
                                    "CountryId": "ES",
                                    "Location": "-2.372222, 36.844444"
                                }
                            ]
                        },
                        {
                            "Id": "LEON",
                            "Name": "Leon",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_L",
                            "Location": "-5.5666658, 42.6",
                            "IataCode": "LEN",
                            "Airports": [
                                {
                                    "Id": "LEN",
                                    "Name": "Leon",
                                    "CityId": "LEON",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_L",
                                    "Location": "-5.650833, 42.584444"
                                }
                            ]
                        },
                        {
                            "Id": "SEOD",
                            "Name": "Seo De Urgel De La Seu",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CATAL",
                            "Location": "1.4614392, 42.358766",
                            "IataCode": "LEU",
                            "Airports": [
                                {
                                    "Id": "LEU",
                                    "Name": "Seo De Urgel De La Seu",
                                    "CityId": "SEOD",
                                    "CountryId": "ES",
                                    "RegionId": "CATAL",
                                    "Location": "1.466667, 42.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MADR",
                            "Name": "Madrid",
                            "SingleAirportCity": false,
                            "CountryId": "ES",
                            "RegionId": "MADRI",
                            "Location": "-3.6964031, 40.465456",
                            "IataCode": "MAD",
                            "Airports": [
                                {
                                    "Id": "MAD",
                                    "Name": "Madrid",
                                    "CityId": "MADR",
                                    "CountryId": "ES",
                                    "RegionId": "MADRI",
                                    "Location": "-3.5921839, 40.490384"
                                },
                                {
                                    "Id": "TOJ",
                                    "Name": "Madrid Torrejon Afb",
                                    "CityId": "MADR",
                                    "CountryId": "ES",
                                    "RegionId": "MADRI",
                                    "Location": "-3.44587, 40.49675"
                                }
                            ]
                        },
                        {
                            "Id": "MURC",
                            "Name": "Murcia",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "MURCI",
                            "Location": "-1.1166671, 37.983333",
                            "IataCode": "MJV",
                            "Airports": [
                                {
                                    "Id": "MJV",
                                    "Name": "Murcia",
                                    "CityId": "MURC",
                                    "CountryId": "ES",
                                    "RegionId": "MURCI",
                                    "Location": "-0.811111, 37.774722"
                                }
                            ]
                        },
                        {
                            "Id": "CORD",
                            "Name": "Cordoba",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-4.7666665, 37.883333",
                            "IataCode": "ODB",
                            "Airports": [
                                {
                                    "Id": "ODB",
                                    "Name": "Cordoba",
                                    "CityId": "CORD",
                                    "CountryId": "ES",
                                    "Location": "-4.847222, 37.841111"
                                }
                            ]
                        },
                        {
                            "Id": "PALM",
                            "Name": "Palma",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ILLES",
                            "Location": "2.715276, 39.556347",
                            "IataCode": "PMI",
                            "Airports": [
                                {
                                    "Id": "PMI",
                                    "Name": "Palma - Majorca",
                                    "CityId": "PALM",
                                    "CountryId": "ES",
                                    "RegionId": "ILLES",
                                    "Location": "2.7361971, 39.551716"
                                }
                            ]
                        },
                        {
                            "Id": "PAMP",
                            "Name": "Pamplona",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "NAVAR",
                            "Location": "-1.6432288, 42.816873",
                            "IataCode": "PNA",
                            "Airports": [
                                {
                                    "Id": "PNA",
                                    "Name": "Pamplona",
                                    "CityId": "PAMP",
                                    "CountryId": "ES",
                                    "RegionId": "NAVAR",
                                    "Location": "-1.644444, 42.769444"
                                }
                            ]
                        },
                        {
                            "Id": "REUS",
                            "Name": "Reus",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CATAL",
                            "Location": "1.1068729, 41.15612",
                            "IataCode": "REU",
                            "Airports": [
                                {
                                    "Id": "REU",
                                    "Name": "Reus",
                                    "CityId": "REUS",
                                    "CountryId": "ES",
                                    "RegionId": "CATAL",
                                    "Location": "1.1682891, 41.148259"
                                }
                            ]
                        },
                        {
                            "Id": "RGSA",
                            "Name": "Burgos",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_L",
                            "Location": "-3.6999999, 42.35",
                            "IataCode": "RGS",
                            "Airports": [
                                {
                                    "Id": "RGS",
                                    "Name": "Burgos",
                                    "CityId": "RGSA",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_L",
                                    "Location": "-3.621025, 42.357275"
                                }
                            ]
                        },
                        {
                            "Id": "SANC",
                            "Name": "Santiago de Compostela",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "GALIC",
                            "Location": "-8.5456897, 42.880524",
                            "IataCode": "SCQ",
                            "Airports": [
                                {
                                    "Id": "SCQ",
                                    "Name": "Santiago de Compostela",
                                    "CityId": "SANC",
                                    "CountryId": "ES",
                                    "RegionId": "GALIC",
                                    "Location": "-8.4168, 42.9"
                                }
                            ]
                        },
                        {
                            "Id": "SANT",
                            "Name": "Santander",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANTA",
                            "Location": "-3.8044442, 43.464722",
                            "IataCode": "SDR",
                            "Airports": [
                                {
                                    "Id": "SDR",
                                    "Name": "Santander",
                                    "CityId": "SANT",
                                    "CountryId": "ES",
                                    "RegionId": "CANTA",
                                    "Location": "-3.826389, 43.429167"
                                }
                            ]
                        },
                        {
                            "Id": "SALA",
                            "Name": "Salamanca",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_L",
                            "Location": "-5.6499996, 40.966667",
                            "IataCode": "SLM",
                            "Airports": [
                                {
                                    "Id": "SLM",
                                    "Name": "Salamanca Matacan",
                                    "CityId": "SALA",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_L",
                                    "Location": "-5.501986, 40.952117"
                                }
                            ]
                        },
                        {
                            "Id": "SEVI",
                            "Name": "Seville",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.9567933, 37.392225",
                            "IataCode": "SVQ",
                            "Airports": [
                                {
                                    "Id": "SVQ",
                                    "Name": "Seville",
                                    "CityId": "SEVI",
                                    "CountryId": "ES",
                                    "Location": "-5.8934041, 37.420125"
                                }
                            ]
                        },
                        {
                            "Id": "TENE",
                            "Name": "Tenerife",
                            "SingleAirportCity": false,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-16.225348, 28.517947",
                            "IataCode": "TCI",
                            "Airports": [
                                {
                                    "Id": "TFN",
                                    "Name": "Tenerife North",
                                    "CityId": "TENE",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-16.332778, 28.473056"
                                },
                                {
                                    "Id": "TFS",
                                    "Name": "Tenerife South",
                                    "CityId": "TENE",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-16.572691, 28.046672"
                                }
                            ]
                        },
                        {
                            "Id": "VIGO",
                            "Name": "Vigo",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "GALIC",
                            "Location": "-8.7166668, 42.233336",
                            "IataCode": "VGO",
                            "Airports": [
                                {
                                    "Id": "VGO",
                                    "Name": "Vigo",
                                    "CityId": "VIGO",
                                    "CountryId": "ES",
                                    "RegionId": "GALIC",
                                    "Location": "-8.627778, 42.226389"
                                }
                            ]
                        },
                        {
                            "Id": "VITO",
                            "Name": "Vitoria-Gasteiz",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "PAIS_",
                            "Location": "-2.6666668, 42.85",
                            "IataCode": "VIT",
                            "Airports": [
                                {
                                    "Id": "VIT",
                                    "Name": "Vitoria",
                                    "CityId": "VITO",
                                    "CountryId": "ES",
                                    "RegionId": "PAIS_",
                                    "Location": "-2.724469, 42.882836"
                                }
                            ]
                        },
                        {
                            "Id": "VALE",
                            "Name": "Valencia",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "VALEN",
                            "Location": "-0.3581812, 39.420226",
                            "IataCode": "VLC",
                            "Airports": [
                                {
                                    "Id": "VLC",
                                    "Name": "Valencia",
                                    "CityId": "VALE",
                                    "CountryId": "ES",
                                    "RegionId": "VALEN",
                                    "Location": "-0.4778432, 39.4891"
                                }
                            ]
                        },
                        {
                            "Id": "VALL",
                            "Name": "Valladolid",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_L",
                            "Location": "-4.7166665, 41.65",
                            "IataCode": "VLL",
                            "Airports": [
                                {
                                    "Id": "VLL",
                                    "Name": "Valladolid",
                                    "CityId": "VALL",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_L",
                                    "Location": "-4.85, 41.7"
                                }
                            ]
                        },
                        {
                            "Id": "MALA",
                            "Name": "Málaga",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-4.4241091, 36.767497",
                            "IataCode": "AGP",
                            "Airports": [
                                {
                                    "Id": "AGP",
                                    "Name": "Malaga",
                                    "CityId": "MALA",
                                    "CountryId": "ES",
                                    "Location": "-4.4940091, 36.676571"
                                }
                            ]
                        },
                        {
                            "Id": "BARC",
                            "Name": "Barcelona",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CATAL",
                            "Location": "2.1509793, 41.399855",
                            "IataCode": "BCN",
                            "Airports": [
                                {
                                    "Id": "BCN",
                                    "Name": "Barcelona",
                                    "CityId": "BARC",
                                    "CountryId": "ES",
                                    "RegionId": "CATAL",
                                    "Location": "2.0833156, 41.297324"
                                }
                            ]
                        },
                        {
                            "Id": "OZPA",
                            "Name": "Moron",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.4499999, 37.133333",
                            "IataCode": "OZP",
                            "Airports": [
                                {
                                    "Id": "OZP",
                                    "Name": "Moron",
                                    "CityId": "OZPA",
                                    "CountryId": "ES",
                                    "Location": "-5.759444, 37.249167"
                                }
                            ]
                        },
                        {
                            "Id": "LOGR",
                            "Name": "Logrono",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "LA_RI",
                            "Location": "-2.4500001, 42.466668",
                            "IataCode": "RJL",
                            "Airports": [
                                {
                                    "Id": "RJL",
                                    "Name": "Logrono",
                                    "CityId": "LOGR",
                                    "CountryId": "ES",
                                    "RegionId": "LA_RI",
                                    "Location": "-2.323611, 42.456944"
                                }
                            ]
                        },
                        {
                            "Id": "VALV",
                            "Name": "Valverde Hierro",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-17.916667, 27.8",
                            "IataCode": "VDE",
                            "Airports": [
                                {
                                    "Id": "VDE",
                                    "Name": "Valverde Hierro",
                                    "CityId": "VALV",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-17.885278, 27.812778"
                                }
                            ]
                        },
                        {
                            "Id": "ABCA",
                            "Name": "Albacete",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_M",
                            "Location": "-1.8500001, 38.983333",
                            "IataCode": "ABC",
                            "Airports": [
                                {
                                    "Id": "ABC",
                                    "Name": "Albacete",
                                    "CityId": "ABCA",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_M",
                                    "Location": "-1.863333, 38.948333"
                                }
                            ]
                        },
                        {
                            "Id": "CQMA",
                            "Name": "Ciudad Real",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_M",
                            "Location": "-3.9333332, 38.983333",
                            "IataCode": "CQM",
                            "Airports": [
                                {
                                    "Id": "CQM",
                                    "Name": "Ciudad Real",
                                    "CityId": "CQMA",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_M",
                                    "Location": "-3.97, 38.856389"
                                }
                            ]
                        },
                        {
                            "Id": "TORR",
                            "Name": "Torremolinos",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-4.4999999, 36.616667",
                            "IataCode": "UTL",
                            "Airports": [
                                {
                                    "Id": "UTL",
                                    "Name": "Torremolinos",
                                    "CityId": "TORR",
                                    "CountryId": "ES",
                                    "Location": "-4.5, 36.633333"
                                }
                            ]
                        },
                        {
                            "Id": "PXSA",
                            "Name": "Puerto De Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-6.2329819, 36.593892",
                            "IataCode": "PXS",
                            "Airports": [
                                {
                                    "Id": "PXS",
                                    "Name": "Puerto De Santa Maria",
                                    "CityId": "PXSA",
                                    "CountryId": "ES",
                                    "Location": "-6.216667, 36.6"
                                }
                            ]
                        },
                        {
                            "Id": "FGRA",
                            "Name": "Fuengirola",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-4.6245573, 36.541915",
                            "IataCode": "FGR",
                            "Airports": [
                                {
                                    "Id": "FGR",
                                    "Name": "Fuengirola",
                                    "CityId": "FGRA",
                                    "CountryId": "ES",
                                    "Location": "-4.633333, 36.533333"
                                }
                            ]
                        },
                        {
                            "Id": "CDTA",
                            "Name": "Castellon de la Plana",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-0.033333761, 39.983333",
                            "IataCode": "CDT",
                            "Airports": [
                                {
                                    "Id": "CDT",
                                    "Name": "Castellon de la Plana",
                                    "CityId": "CDTA",
                                    "CountryId": "ES",
                                    "Location": "0.073333, 40.213889"
                                }
                            ]
                        },
                        {
                            "Id": "RRAA",
                            "Name": "Ronda",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.1670932, 36.742306",
                            "IataCode": "RRA",
                            "Airports": [
                                {
                                    "Id": "RRA",
                                    "Name": "Ronda",
                                    "CityId": "RRAA",
                                    "CountryId": "ES",
                                    "Location": "-5.166667, 36.75"
                                }
                            ]
                        },
                        {
                            "Id": "LLEI",
                            "Name": "Lleida",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CATAL",
                            "Location": "0.61666734, 41.616667",
                            "IataCode": "ILD",
                            "Airports": [
                                {
                                    "Id": "ILD",
                                    "Name": "Lleida",
                                    "CityId": "LLEI",
                                    "CountryId": "ES",
                                    "RegionId": "CATAL",
                                    "Location": "0.5358, 41.7278"
                                }
                            ]
                        },
                        {
                            "Id": "HEVA",
                            "Name": "Huelva",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-6.9508339, 37.258333",
                            "IataCode": "HEV",
                            "Airports": [
                                {
                                    "Id": "HEV",
                                    "Name": "Huelva",
                                    "CityId": "HEVA",
                                    "CountryId": "ES",
                                    "Location": "-6.95, 37.266667"
                                }
                            ]
                        },
                        {
                            "Id": "FESA",
                            "Name": "San Fernando",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-6.2000003, 36.466667",
                            "IataCode": "FES",
                            "Airports": [
                                {
                                    "Id": "FES",
                                    "Name": "San Fernando",
                                    "CityId": "FESA",
                                    "CountryId": "ES",
                                    "Location": "-6.183333, 36.466667"
                                }
                            ]
                        },
                        {
                            "Id": "OZUA",
                            "Name": "Montilla",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-4.6333332, 37.583333",
                            "IataCode": "OZU",
                            "Airports": [
                                {
                                    "Id": "OZU",
                                    "Name": "Montilla",
                                    "CityId": "OZUA",
                                    "CountryId": "ES",
                                    "Location": "-4.633333, 37.6"
                                }
                            ]
                        },
                        {
                            "Id": "AEIA",
                            "Name": "Algeciras",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.4538885, 36.1275",
                            "IataCode": "AEI",
                            "Airports": [
                                {
                                    "Id": "AEI",
                                    "Name": "Algeciras",
                                    "CityId": "AEIA",
                                    "CountryId": "ES",
                                    "Location": "-5.45, 36.183333"
                                }
                            ]
                        },
                        {
                            "Id": "NDOA",
                            "Name": "La Palma Del Condado",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-6.5500001, 37.383333",
                            "IataCode": "NDO",
                            "Airports": [
                                {
                                    "Id": "NDO",
                                    "Name": "La Palma Del Condado",
                                    "CityId": "NDOA",
                                    "CountryId": "ES",
                                    "Location": "-6.583333, 37.383333"
                                }
                            ]
                        },
                        {
                            "Id": "UERA",
                            "Name": "Puertollano",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CAS_M",
                            "Location": "-4.1073417, 38.687118",
                            "IataCode": "UER",
                            "Airports": [
                                {
                                    "Id": "UER",
                                    "Name": "Puertollano",
                                    "CityId": "UERA",
                                    "CountryId": "ES",
                                    "RegionId": "CAS_M",
                                    "Location": "-4.116667, 38.7"
                                }
                            ]
                        },
                        {
                            "Id": "CEUT",
                            "Name": "Ceuta",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.3197859, 35.889328",
                            "IataCode": "JCU",
                            "Airports": [
                                {
                                    "Id": "JCU",
                                    "Name": "Ceuta",
                                    "CityId": "CEUT",
                                    "CountryId": "ES",
                                    "Location": "-5.30639, 35.8928"
                                }
                            ]
                        },
                        {
                            "Id": "RMUA",
                            "Name": "Corvera",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-1.1500001, 37.833333",
                            "IataCode": "RMU",
                            "Airports": [
                                {
                                    "Id": "RMU",
                                    "Name": "Región de Murcia",
                                    "CityId": "RMUA",
                                    "CountryId": "ES",
                                    "Location": "-1.141831, 37.790325"
                                }
                            ]
                        },
                        {
                            "Id": "MELI",
                            "Name": "Melilla",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "MELIL",
                            "Location": "-2.9383278, 35.293691",
                            "IataCode": "MLN",
                            "Airports": [
                                {
                                    "Id": "MLN",
                                    "Name": "Melilla",
                                    "CityId": "MELI",
                                    "CountryId": "ES",
                                    "RegionId": "MELIL",
                                    "Location": "-2.955556, 35.28"
                                }
                            ]
                        },
                        {
                            "Id": "SPOA",
                            "Name": "San Pablo",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-5.6825453, 37.435689",
                            "IataCode": "SPO",
                            "Airports": [
                                {
                                    "Id": "SPO",
                                    "Name": "San Pablo",
                                    "CityId": "SPOA",
                                    "CountryId": "ES",
                                    "Location": "-5.75, 37.383333"
                                }
                            ]
                        },
                        {
                            "Id": "AVIL",
                            "Name": "Asturias",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ASTUR",
                            "Location": "-5.9890797, 43.299181",
                            "IataCode": "OVD",
                            "Airports": [
                                {
                                    "Id": "OVD",
                                    "Name": "Asturias",
                                    "CityId": "AVIL",
                                    "CountryId": "ES",
                                    "RegionId": "ASTUR",
                                    "Location": "-6.033333, 43.566667"
                                }
                            ]
                        },
                        {
                            "Id": "GRAN",
                            "Name": "Gran Canaria",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-15.585312, 27.963463",
                            "IataCode": "LPA",
                            "Airports": [
                                {
                                    "Id": "LPA",
                                    "Name": "Gran Canaria Las Palmas",
                                    "CityId": "GRAN",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-15.387696, 27.933175"
                                }
                            ]
                        },
                        {
                            "Id": "LAPA",
                            "Name": "La Palma",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-17.855281, 28.683856",
                            "IataCode": "SPC",
                            "Airports": [
                                {
                                    "Id": "SPC",
                                    "Name": "La Palma",
                                    "CityId": "LAPA",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-17.754167, 28.620833"
                                }
                            ]
                        },
                        {
                            "Id": "LAGO",
                            "Name": "La Gomera Tenerife",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-17.229471, 28.118276",
                            "IataCode": "GMZ",
                            "Airports": [
                                {
                                    "Id": "GMZ",
                                    "Name": "La Gomera Tenerife",
                                    "CityId": "LAGO",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-17.2, 28.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ARRE",
                            "Name": "Lanzarote",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "CANAR",
                            "Location": "-13.641342, 29.029617",
                            "IataCode": "ACE",
                            "Airports": [
                                {
                                    "Id": "ACE",
                                    "Name": "Lanzarote",
                                    "CityId": "ARRE",
                                    "CountryId": "ES",
                                    "RegionId": "CANAR",
                                    "Location": "-13.605745, 28.95025"
                                }
                            ]
                        },
                        {
                            "Id": "MENO",
                            "Name": "Menorca",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "RegionId": "ILLES",
                            "Location": "4.0764611, 39.958638",
                            "IataCode": "MAH",
                            "Airports": [
                                {
                                    "Id": "MAH",
                                    "Name": "Menorca",
                                    "CityId": "MENO",
                                    "CountryId": "ES",
                                    "RegionId": "ILLES",
                                    "Location": "4.215278, 39.861389"
                                }
                            ]
                        },
                        {
                            "Id": "OZIA",
                            "Name": "Terra Chã",
                            "SingleAirportCity": true,
                            "CountryId": "ES",
                            "Location": "-25.15, 37.75",
                            "IataCode": "OZI",
                            "Airports": [
                                {
                                    "Id": "OZI",
                                    "Name": "Bobadilla",
                                    "CityId": "OZIA",
                                    "CountryId": "ES",
                                    "Location": "-25.224609, 37.76203"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LU",
                    "Name": "Luxembourg",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "LUXE",
                            "Name": "Luxembourg",
                            "SingleAirportCity": true,
                            "CountryId": "LU",
                            "Location": "6.1300004, 49.611667",
                            "IataCode": "LUX",
                            "Airports": [
                                {
                                    "Id": "LUX",
                                    "Name": "Luxembourg",
                                    "CityId": "LUXE",
                                    "CountryId": "LU",
                                    "Location": "6.2147231, 49.628879"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PT",
                    "Name": "Portugal",
                    "CurrencyId": "EUR",
                    "LanguageId": "PT",
                    "Cities": [
                        {
                            "Id": "FUNC",
                            "Name": "Madeira",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-16.9, 32.633333",
                            "IataCode": "FNC",
                            "Airports": [
                                {
                                    "Id": "FNC",
                                    "Name": "Madeira",
                                    "CityId": "FUNC",
                                    "CountryId": "PT",
                                    "Location": "-16.778056, 32.694167"
                                }
                            ]
                        },
                        {
                            "Id": "BRAH",
                            "Name": "Braga",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-8.4333334, 41.55",
                            "IataCode": "BGZ",
                            "Airports": [
                                {
                                    "Id": "BGZ",
                                    "Name": "Braga",
                                    "CityId": "BRAH",
                                    "CountryId": "PT",
                                    "Location": "-8.44501, 41.58672"
                                }
                            ]
                        },
                        {
                            "Id": "CHAV",
                            "Name": "Chaves",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.4666668, 41.733333",
                            "IataCode": "CHV",
                            "Airports": [
                                {
                                    "Id": "CHV",
                                    "Name": "Chaves",
                                    "CityId": "CHAV",
                                    "CountryId": "PT",
                                    "Location": "-7.466667, 41.733333"
                                }
                            ]
                        },
                        {
                            "Id": "BRAG",
                            "Name": "Braganca",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-6.7500001, 41.816667",
                            "IataCode": "BGC",
                            "Airports": [
                                {
                                    "Id": "BGC",
                                    "Name": "Braganca",
                                    "CityId": "BRAG",
                                    "CountryId": "PT",
                                    "Location": "-6.706477, 41.857799"
                                }
                            ]
                        },
                        {
                            "Id": "COIM",
                            "Name": "Coimbra",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-8.4166668, 40.2",
                            "IataCode": "CBP",
                            "Airports": [
                                {
                                    "Id": "CBP",
                                    "Name": "Coimbra",
                                    "CityId": "COIM",
                                    "CountryId": "PT",
                                    "Location": "-8.416667, 40.2"
                                }
                            ]
                        },
                        {
                            "Id": "COVI",
                            "Name": "Covilha",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.5000001, 40.283333",
                            "IataCode": "COV",
                            "Airports": [
                                {
                                    "Id": "COV",
                                    "Name": "Covilha",
                                    "CityId": "COVI",
                                    "CountryId": "PT",
                                    "Location": "-7.5, 40.283333"
                                }
                            ]
                        },
                        {
                            "Id": "FARO",
                            "Name": "Faro",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.9333334, 37.016667",
                            "IataCode": "FAO",
                            "Airports": [
                                {
                                    "Id": "FAO",
                                    "Name": "Faro",
                                    "CityId": "FARO",
                                    "CountryId": "PT",
                                    "Location": "-7.9697522, 37.017613"
                                }
                            ]
                        },
                        {
                            "Id": "LISB",
                            "Name": "Lisbon",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-9.156132, 38.742684",
                            "IataCode": "LIS",
                            "Airports": [
                                {
                                    "Id": "LIS",
                                    "Name": "Lisbon",
                                    "CityId": "LISB",
                                    "CountryId": "PT",
                                    "Location": "-9.1353882, 38.775577"
                                }
                            ]
                        },
                        {
                            "Id": "PORT",
                            "Name": "Porto",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-8.6208904, 41.161676",
                            "IataCode": "OPO",
                            "Airports": [
                                {
                                    "Id": "OPO",
                                    "Name": "Porto",
                                    "CityId": "PORT",
                                    "CountryId": "PT",
                                    "Location": "-8.6786158, 41.242086"
                                }
                            ]
                        },
                        {
                            "Id": "PONT",
                            "Name": "Ponta Delgada",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-25.666667, 37.733333",
                            "IataCode": "PDL",
                            "Airports": [
                                {
                                    "Id": "PDL",
                                    "Name": "Ponta Delgada",
                                    "CityId": "PONT",
                                    "CountryId": "PT",
                                    "Location": "-25.681944, 37.747222"
                                }
                            ]
                        },
                        {
                            "Id": "PORW",
                            "Name": "Portimao",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-8.5377503, 37.138561",
                            "IataCode": "PRM",
                            "Airports": [
                                {
                                    "Id": "PRM",
                                    "Name": "Portimao",
                                    "CityId": "PORW",
                                    "CountryId": "PT",
                                    "Location": "-8.533333, 37.133333"
                                }
                            ]
                        },
                        {
                            "Id": "VISE",
                            "Name": "Viseu G. Lobato",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.9166668, 40.65",
                            "IataCode": "VSE",
                            "Airports": [
                                {
                                    "Id": "VSE",
                                    "Name": "Viseu G. Lobato",
                                    "CityId": "VISE",
                                    "CountryId": "PT",
                                    "Location": "-7.916667, 40.65"
                                }
                            ]
                        },
                        {
                            "Id": "SINE",
                            "Name": "Sines",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-8.8666667, 37.95",
                            "IataCode": "SIE",
                            "Airports": [
                                {
                                    "Id": "SIE",
                                    "Name": "Sines",
                                    "CityId": "SINE",
                                    "CountryId": "PT",
                                    "Location": "-8.85, 37.933333"
                                }
                            ]
                        },
                        {
                            "Id": "VILA",
                            "Name": "Vila Real",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.7500001, 41.3",
                            "IataCode": "VRL",
                            "Airports": [
                                {
                                    "Id": "VRL",
                                    "Name": "Vila Real",
                                    "CityId": "VILA",
                                    "CountryId": "PT",
                                    "Location": "-7.720835, 41.274629"
                                }
                            ]
                        },
                        {
                            "Id": "CATC",
                            "Name": "Cascais",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-9.421463, 38.697903",
                            "IataCode": "CAT",
                            "Airports": [
                                {
                                    "Id": "CAT",
                                    "Name": "Tires",
                                    "CityId": "CATC",
                                    "CountryId": "PT",
                                    "Location": "-9.353991, 38.722727"
                                }
                            ]
                        },
                        {
                            "Id": "BYJA",
                            "Name": "Beja",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-7.8666667, 38.016667",
                            "IataCode": "BYJ",
                            "Airports": [
                                {
                                    "Id": "BYJ",
                                    "Name": "International",
                                    "CityId": "BYJA",
                                    "CountryId": "PT",
                                    "Location": "-7.958667, 38.022335"
                                }
                            ]
                        },
                        {
                            "Id": "HORT",
                            "Name": "Horta",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-28.633333, 38.533333",
                            "IataCode": "HOR",
                            "Airports": [
                                {
                                    "Id": "HOR",
                                    "Name": "Horta",
                                    "CityId": "HORT",
                                    "CountryId": "PT",
                                    "Location": "-28.717222, 38.52"
                                }
                            ]
                        },
                        {
                            "Id": "PORY",
                            "Name": "Porto Santo",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-16.333333, 33.05",
                            "IataCode": "PXO",
                            "Airports": [
                                {
                                    "Id": "PXO",
                                    "Name": "Porto Santo",
                                    "CityId": "PORY",
                                    "CountryId": "PT",
                                    "Location": "-16.349373, 33.067521"
                                }
                            ]
                        },
                        {
                            "Id": "TERC",
                            "Name": "Terceira Lajes",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-27.1, 38.766667",
                            "IataCode": "TER",
                            "Airports": [
                                {
                                    "Id": "TER",
                                    "Name": "Terceira Lajes",
                                    "CityId": "TERC",
                                    "CountryId": "PT",
                                    "Location": "-27.094167, 38.756667"
                                }
                            ]
                        },
                        {
                            "Id": "SANV",
                            "Name": "Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-25.090833, 36.982778",
                            "IataCode": "SMA",
                            "Airports": [
                                {
                                    "Id": "SMA",
                                    "Name": "Santa Maria",
                                    "CityId": "SANV",
                                    "CountryId": "PT",
                                    "Location": "-25.170639, 36.97139"
                                }
                            ]
                        },
                        {
                            "Id": "PICO",
                            "Name": "Pico Island",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-28.863889, 38.471944",
                            "IataCode": "PIX",
                            "Airports": [
                                {
                                    "Id": "PIX",
                                    "Name": "Pico Island",
                                    "CityId": "PICO",
                                    "CountryId": "PT",
                                    "Location": "-28.441333, 38.554333"
                                }
                            ]
                        },
                        {
                            "Id": "GRAC",
                            "Name": "Graciosa Island",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-28.014167, 39.051389",
                            "IataCode": "GRW",
                            "Airports": [
                                {
                                    "Id": "GRW",
                                    "Name": "Graciosa Island",
                                    "CityId": "GRAC",
                                    "CountryId": "PT",
                                    "Location": "-28.029847, 39.092169"
                                }
                            ]
                        },
                        {
                            "Id": "SANJ",
                            "Name": "San Jorge Island",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-28.121944, 38.669167",
                            "IataCode": "SJZ",
                            "Airports": [
                                {
                                    "Id": "SJZ",
                                    "Name": "San Jorge Island",
                                    "CityId": "SANJ",
                                    "CountryId": "PT",
                                    "Location": "-28.175817, 38.6655"
                                }
                            ]
                        },
                        {
                            "Id": "SANU",
                            "Name": "Flores Island",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-31.227222, 39.466667",
                            "IataCode": "FLW",
                            "Airports": [
                                {
                                    "Id": "FLW",
                                    "Name": "Flores Island",
                                    "CityId": "SANU",
                                    "CountryId": "PT",
                                    "Location": "-31.15, 39.466667"
                                }
                            ]
                        },
                        {
                            "Id": "CORV",
                            "Name": "Corvo Island",
                            "SingleAirportCity": true,
                            "CountryId": "PT",
                            "Location": "-31.101667, 39.701875",
                            "IataCode": "CVU",
                            "Airports": [
                                {
                                    "Id": "CVU",
                                    "Name": "Corvo Island",
                                    "CityId": "CORV",
                                    "CountryId": "PT",
                                    "Location": "-31.1, 39.7"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "EE",
                    "Name": "Estonia",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "EPUA",
                            "Name": "Parnu",
                            "SingleAirportCity": true,
                            "CountryId": "EE",
                            "Location": "24.513612, 58.374722",
                            "IataCode": "EPU",
                            "Airports": [
                                {
                                    "Id": "EPU",
                                    "Name": "Parnu",
                                    "CityId": "EPUA",
                                    "CountryId": "EE",
                                    "Location": "24.472778, 58.418889"
                                }
                            ]
                        },
                        {
                            "Id": "KDLA",
                            "Name": "Kardla",
                            "SingleAirportCity": true,
                            "CountryId": "EE",
                            "Location": "22.749167, 58.997778",
                            "IataCode": "KDL",
                            "Airports": [
                                {
                                    "Id": "KDL",
                                    "Name": "Kardla",
                                    "CityId": "KDLA",
                                    "CountryId": "EE",
                                    "Location": "22.8, 58.983333"
                                }
                            ]
                        },
                        {
                            "Id": "TAYA",
                            "Name": "Tartu",
                            "SingleAirportCity": true,
                            "CountryId": "EE",
                            "Location": "26.725091, 58.380625",
                            "IataCode": "TAY",
                            "Airports": [
                                {
                                    "Id": "TAY",
                                    "Name": "Tartu",
                                    "CityId": "TAYA",
                                    "CountryId": "EE",
                                    "Location": "26.690556, 58.3075"
                                }
                            ]
                        },
                        {
                            "Id": "TALL",
                            "Name": "Tallinn",
                            "SingleAirportCity": true,
                            "CountryId": "EE",
                            "Location": "24.753528, 59.43696",
                            "IataCode": "TLL",
                            "Airports": [
                                {
                                    "Id": "TLL",
                                    "Name": "Tallinn",
                                    "CityId": "TALL",
                                    "CountryId": "EE",
                                    "Location": "24.832844, 59.413317"
                                }
                            ]
                        },
                        {
                            "Id": "KURE",
                            "Name": "Kuressaare",
                            "SingleAirportCity": true,
                            "CountryId": "EE",
                            "Location": "22.503889, 58.248056",
                            "IataCode": "URE",
                            "Airports": [
                                {
                                    "Id": "URE",
                                    "Name": "Kuressaare",
                                    "CityId": "KURE",
                                    "CountryId": "EE",
                                    "Location": "22.5, 58.216667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "RO",
                    "Name": "Romania",
                    "CurrencyId": "RON",
                    "LanguageId": "RO",
                    "Cities": [
                        {
                            "Id": "CLUJ",
                            "Name": "Cluj-Napoca",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "23.6, 46.766667",
                            "IataCode": "CLJ",
                            "Airports": [
                                {
                                    "Id": "CLJ",
                                    "Name": "Cluj-Napoca",
                                    "CityId": "CLUJ",
                                    "CountryId": "RO",
                                    "Location": "23.683333, 46.783333"
                                }
                            ]
                        },
                        {
                            "Id": "CONS",
                            "Name": "Constanta Kogalniceanu",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "28.65, 44.183333",
                            "IataCode": "CND",
                            "Airports": [
                                {
                                    "Id": "CND",
                                    "Name": "Constanta Kogalniceanu",
                                    "CityId": "CONS",
                                    "CountryId": "RO",
                                    "Location": "28.483333, 44.35"
                                }
                            ]
                        },
                        {
                            "Id": "CRAI",
                            "Name": "Craiova",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "23.8, 44.316667",
                            "IataCode": "CRA",
                            "Airports": [
                                {
                                    "Id": "CRA",
                                    "Name": "Craiova",
                                    "CityId": "CRAI",
                                    "CountryId": "RO",
                                    "Location": "23.886389, 44.318889"
                                }
                            ]
                        },
                        {
                            "Id": "CARA",
                            "Name": "Caransebes",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "22.216667, 45.416667",
                            "IataCode": "CSB",
                            "Airports": [
                                {
                                    "Id": "CSB",
                                    "Name": "Caransebes",
                                    "CityId": "CARA",
                                    "CountryId": "RO",
                                    "Location": "22.216667, 45.416667"
                                }
                            ]
                        },
                        {
                            "Id": "DEVA",
                            "Name": "Deva",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "22.9, 45.883333",
                            "IataCode": "DVA",
                            "Airports": [
                                {
                                    "Id": "DVA",
                                    "Name": "Deva",
                                    "CityId": "DEVA",
                                    "CountryId": "RO",
                                    "Location": "22.916667, 45.883333"
                                }
                            ]
                        },
                        {
                            "Id": "IASI",
                            "Name": "Iasi",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "27.6, 47.166667",
                            "IataCode": "IAS",
                            "Airports": [
                                {
                                    "Id": "IAS",
                                    "Name": "Iasi",
                                    "CityId": "IASI",
                                    "CountryId": "RO",
                                    "Location": "27.6, 47.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ORAD",
                            "Name": "Oradea",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "21.933333, 47.066667",
                            "IataCode": "OMR",
                            "Airports": [
                                {
                                    "Id": "OMR",
                                    "Name": "Oradea",
                                    "CityId": "ORAD",
                                    "CountryId": "RO",
                                    "Location": "21.903056, 47.0275"
                                }
                            ]
                        },
                        {
                            "Id": "BUCH",
                            "Name": "Bucharest",
                            "SingleAirportCity": false,
                            "CountryId": "RO",
                            "Location": "26.084844, 44.430386",
                            "IataCode": "BUH",
                            "Airports": [
                                {
                                    "Id": "OTP",
                                    "Name": "Bucharest Otopeni",
                                    "CityId": "BUCH",
                                    "CountryId": "RO",
                                    "Location": "26.084391, 44.570616"
                                },
                                {
                                    "Id": "BBU",
                                    "Name": "Bucharest Baneasa",
                                    "CityId": "BUCH",
                                    "CountryId": "RO",
                                    "Location": "26.1, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "SIBI",
                            "Name": "Sibiu",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "24.15, 45.8",
                            "IataCode": "SBZ",
                            "Airports": [
                                {
                                    "Id": "SBZ",
                                    "Name": "Sibiu",
                                    "CityId": "SIBI",
                                    "CountryId": "RO",
                                    "Location": "24.083333, 45.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SUCE",
                            "Name": "Suceava Salcea",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "26.25, 47.633333",
                            "IataCode": "SCV",
                            "Airports": [
                                {
                                    "Id": "SCV",
                                    "Name": "Suceava Salcea",
                                    "CityId": "SUCE",
                                    "CountryId": "RO",
                                    "Location": "26.356111, 47.689444"
                                }
                            ]
                        },
                        {
                            "Id": "SATU",
                            "Name": "Satu Mare",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "22.883333, 47.8",
                            "IataCode": "SUJ",
                            "Airports": [
                                {
                                    "Id": "SUJ",
                                    "Name": "Satu Mare",
                                    "CityId": "SATU",
                                    "CountryId": "RO",
                                    "Location": "22.883333, 47.7"
                                }
                            ]
                        },
                        {
                            "Id": "TIRG",
                            "Name": "Tirgu Mures",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "24.566667, 46.550002",
                            "IataCode": "TGM",
                            "Airports": [
                                {
                                    "Id": "TGM",
                                    "Name": "Tirgu Mures",
                                    "CityId": "TIRG",
                                    "CountryId": "RO",
                                    "Location": "24.412525, 46.467714"
                                }
                            ]
                        },
                        {
                            "Id": "TIMI",
                            "Name": "Timisoara",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "21.227222, 45.749444",
                            "IataCode": "TSR",
                            "Airports": [
                                {
                                    "Id": "TSR",
                                    "Name": "Timisoara",
                                    "CityId": "TIMI",
                                    "CountryId": "RO",
                                    "Location": "21.333333, 45.816667"
                                }
                            ]
                        },
                        {
                            "Id": "ARAD",
                            "Name": "Arad",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "21.316667, 46.183333",
                            "IataCode": "ARW",
                            "Airports": [
                                {
                                    "Id": "ARW",
                                    "Name": "Arad",
                                    "CityId": "ARAD",
                                    "CountryId": "RO",
                                    "Location": "21.266667, 46.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BAIA",
                            "Name": "Baia Mare",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "23.568077, 47.657296",
                            "IataCode": "BAY",
                            "Airports": [
                                {
                                    "Id": "BAY",
                                    "Name": "Baia Mare",
                                    "CityId": "BAIA",
                                    "CountryId": "RO",
                                    "Location": "23.466667, 47.65"
                                }
                            ]
                        },
                        {
                            "Id": "BACA",
                            "Name": "Bacau",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "26.9, 46.566667",
                            "IataCode": "BCM",
                            "Airports": [
                                {
                                    "Id": "BCM",
                                    "Name": "Bacau International",
                                    "CityId": "BACA",
                                    "CountryId": "RO",
                                    "Location": "26.910278, 46.521946"
                                }
                            ]
                        },
                        {
                            "Id": "TULC",
                            "Name": "Tulcea",
                            "SingleAirportCity": true,
                            "CountryId": "RO",
                            "Location": "28.8, 45.166667",
                            "IataCode": "TCE",
                            "Airports": [
                                {
                                    "Id": "TCE",
                                    "Name": "Tulcea",
                                    "CityId": "TULC",
                                    "CountryId": "RO",
                                    "Location": "28.714444, 45.0625"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SE",
                    "Name": "Sweden",
                    "CurrencyId": "SEK",
                    "LanguageId": "SV",
                    "Cities": [
                        {
                            "Id": "MALM",
                            "Name": "Malmo",
                            "SingleAirportCity": false,
                            "CountryId": "SE",
                            "Location": "13.000732, 55.605872",
                            "IataCode": "MMA",
                            "Airports": [
                                {
                                    "Id": "JMM",
                                    "Name": "Malmo Harbour Heliport",
                                    "CityId": "MALM",
                                    "CountryId": "SE",
                                    "Location": "13, 55.6"
                                },
                                {
                                    "Id": "MMX",
                                    "Name": "Malmo Sturup",
                                    "CityId": "MALM",
                                    "CountryId": "SE",
                                    "Location": "13.373108, 55.538734"
                                }
                            ]
                        },
                        {
                            "Id": "ARVI",
                            "Name": "Arvidsjaur",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "19.166667, 65.583333",
                            "IataCode": "AJR",
                            "Airports": [
                                {
                                    "Id": "AJR",
                                    "Name": "Arvidsjaur",
                                    "CityId": "ARVI",
                                    "CountryId": "SE",
                                    "Location": "19.285556, 65.591389"
                                }
                            ]
                        },
                        {
                            "Id": "ESKJ",
                            "Name": "Eskilstuna",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.5, 59.366667",
                            "IataCode": "EKT",
                            "Airports": [
                                {
                                    "Id": "EKT",
                                    "Name": "Eskilstuna",
                                    "CityId": "ESKJ",
                                    "CountryId": "SE",
                                    "Location": "16.7, 59.35"
                                }
                            ]
                        },
                        {
                            "Id": "KARM",
                            "Name": "Karlskoga",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.523861, 59.326666",
                            "IataCode": "KSK",
                            "Airports": [
                                {
                                    "Id": "KSK",
                                    "Name": "Karlskoga",
                                    "CityId": "KARM",
                                    "CountryId": "SE",
                                    "Location": "14.55, 59.316667"
                                }
                            ]
                        },
                        {
                            "Id": "BORL",
                            "Name": "Borlange Dala",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.416667, 60.483333",
                            "IataCode": "BLE",
                            "Airports": [
                                {
                                    "Id": "BLE",
                                    "Name": "Borlange Dala",
                                    "CityId": "BORL",
                                    "CountryId": "SE",
                                    "Location": "15.518056, 60.423333"
                                }
                            ]
                        },
                        {
                            "Id": "STOC",
                            "Name": "Stockholm",
                            "SingleAirportCity": false,
                            "CountryId": "SE",
                            "Location": "18.06264, 59.329474",
                            "IataCode": "STO",
                            "Airports": [
                                {
                                    "Id": "BMA",
                                    "Name": "Stockholm Bromma",
                                    "CityId": "STOC",
                                    "CountryId": "SE",
                                    "Location": "17.946389, 59.352778"
                                },
                                {
                                    "Id": "VST",
                                    "Name": "Stockholm Vasteras",
                                    "CityId": "STOC",
                                    "CountryId": "SE",
                                    "Location": "16.630556, 59.589167"
                                },
                                {
                                    "Id": "ARN",
                                    "Name": "Stockholm Arlanda",
                                    "CityId": "STOC",
                                    "CountryId": "SE",
                                    "Location": "17.923759, 59.649741"
                                },
                                {
                                    "Id": "NYO",
                                    "Name": "Stockholm Skavsta",
                                    "CityId": "STOC",
                                    "CountryId": "SE",
                                    "Location": "16.912189, 58.788636"
                                }
                            ]
                        },
                        {
                            "Id": "SVEG",
                            "Name": "Sveg",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.35, 62.033333",
                            "IataCode": "EVG",
                            "Airports": [
                                {
                                    "Id": "EVG",
                                    "Name": "Sveg",
                                    "CityId": "SVEG",
                                    "CountryId": "SE",
                                    "Location": "14.42295, 62.047811"
                                }
                            ]
                        },
                        {
                            "Id": "GALL",
                            "Name": "Gallivare",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "20.7, 67.133333",
                            "IataCode": "GEV",
                            "Airports": [
                                {
                                    "Id": "GEV",
                                    "Name": "Gallivare",
                                    "CityId": "GALL",
                                    "CountryId": "SE",
                                    "Location": "20.816667, 67.134444"
                                }
                            ]
                        },
                        {
                            "Id": "GOTH",
                            "Name": "Gothenburg",
                            "SingleAirportCity": false,
                            "CountryId": "SE",
                            "Location": "11.966793, 57.707165",
                            "IataCode": "GOT",
                            "Airports": [
                                {
                                    "Id": "GSE",
                                    "Name": "Gothenburg Save",
                                    "CityId": "GOTH",
                                    "CountryId": "SE",
                                    "Location": "11.867778, 57.768333"
                                },
                                {
                                    "Id": "GOT",
                                    "Name": "Gothenburg Landvetter",
                                    "CityId": "GOTH",
                                    "CountryId": "SE",
                                    "Location": "12.283333, 57.666667"
                                }
                            ]
                        },
                        {
                            "Id": "HALM",
                            "Name": "Halmstad",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "12.855556, 56.671389",
                            "IataCode": "HAD",
                            "Airports": [
                                {
                                    "Id": "HAD",
                                    "Name": "Halmstad",
                                    "CityId": "HALM",
                                    "CountryId": "SE",
                                    "Location": "12.8194, 56.6833"
                                }
                            ]
                        },
                        {
                            "Id": "HAGF",
                            "Name": "Hagfors",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.65, 60.033333",
                            "IataCode": "HFS",
                            "Airports": [
                                {
                                    "Id": "HFS",
                                    "Name": "Hagfors",
                                    "CityId": "HAGF",
                                    "CountryId": "SE",
                                    "Location": "13.569167, 60.0175"
                                }
                            ]
                        },
                        {
                            "Id": "HEMA",
                            "Name": "Hemavan",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.086632, 65.819868",
                            "IataCode": "HMV",
                            "Airports": [
                                {
                                    "Id": "HMV",
                                    "Name": "Hemavan Tarnaby",
                                    "CityId": "HEMA",
                                    "CountryId": "SE",
                                    "Location": "15.082778, 65.806111"
                                }
                            ]
                        },
                        {
                            "Id": "HUDI",
                            "Name": "Hudiksvall",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.1125, 61.733333",
                            "IataCode": "HUV",
                            "Airports": [
                                {
                                    "Id": "HUV",
                                    "Name": "Hudiksvall",
                                    "CityId": "HUDI",
                                    "CountryId": "SE",
                                    "Location": "17.116667, 61.733333"
                                }
                            ]
                        },
                        {
                            "Id": "IDBA",
                            "Name": "Idre",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "12.714014, 61.858213",
                            "IataCode": "IDB",
                            "Airports": [
                                {
                                    "Id": "IDB",
                                    "Name": "Idre",
                                    "CityId": "IDBA",
                                    "CountryId": "SE",
                                    "Location": "12.683333, 61.866667"
                                }
                            ]
                        },
                        {
                            "Id": "HELT",
                            "Name": "Helsingborg",
                            "SingleAirportCity": false,
                            "CountryId": "SE",
                            "Location": "12.700001, 56.05",
                            "IataCode": "AGH",
                            "Airports": [
                                {
                                    "Id": "JHE",
                                    "Name": "Helsingborg Hlpt",
                                    "CityId": "HELT",
                                    "CountryId": "SE",
                                    "Location": "12.7, 56.033333"
                                },
                                {
                                    "Id": "AGH",
                                    "Name": "Angelholm Helsingborg",
                                    "CityId": "HELT",
                                    "CountryId": "SE",
                                    "Location": "12.8625, 56.293056"
                                }
                            ]
                        },
                        {
                            "Id": "JONK",
                            "Name": "Jonkoping Axamo",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.183334, 57.783333",
                            "IataCode": "JKG",
                            "Airports": [
                                {
                                    "Id": "JKG",
                                    "Name": "Jonkoping Axamo",
                                    "CityId": "JONK",
                                    "CountryId": "SE",
                                    "Location": "14.071944, 57.758611"
                                }
                            ]
                        },
                        {
                            "Id": "JLDB",
                            "Name": "Landskrona",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "12.833333, 55.866667",
                            "IataCode": "JLD",
                            "Airports": [
                                {
                                    "Id": "JLD",
                                    "Name": "Landskrona Heliport",
                                    "CityId": "JLDB",
                                    "CountryId": "SE",
                                    "Location": "12.848511, 55.867606"
                                }
                            ]
                        },
                        {
                            "Id": "KIRU",
                            "Name": "Kiruna",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "20.216667, 67.85",
                            "IataCode": "KRN",
                            "Airports": [
                                {
                                    "Id": "KRN",
                                    "Name": "Kiruna",
                                    "CityId": "KIRU",
                                    "CountryId": "SE",
                                    "Location": "20.345833, 67.822222"
                                }
                            ]
                        },
                        {
                            "Id": "KARL",
                            "Name": "Karlstad",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.503571, 59.3793",
                            "IataCode": "KSD",
                            "Airports": [
                                {
                                    "Id": "KSD",
                                    "Name": "Karlstad",
                                    "CityId": "KARL",
                                    "CountryId": "SE",
                                    "Location": "13.469444, 59.361667"
                                }
                            ]
                        },
                        {
                            "Id": "KRIS",
                            "Name": "Kristianstad",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.133333, 56.033333",
                            "IataCode": "KID",
                            "Airports": [
                                {
                                    "Id": "KID",
                                    "Name": "Kristianstad",
                                    "CityId": "KRIS",
                                    "CountryId": "SE",
                                    "Location": "14.088889, 55.919444"
                                }
                            ]
                        },
                        {
                            "Id": "KALM",
                            "Name": "Kalmar",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.366667, 56.666667",
                            "IataCode": "KLR",
                            "Airports": [
                                {
                                    "Id": "KLR",
                                    "Name": "Kalmar",
                                    "CityId": "KALM",
                                    "CountryId": "SE",
                                    "Location": "16.287222, 56.685"
                                }
                            ]
                        },
                        {
                            "Id": "LULE",
                            "Name": "Lulea Kallax",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "22.15, 65.583333",
                            "IataCode": "LLA",
                            "Airports": [
                                {
                                    "Id": "LLA",
                                    "Name": "Lulea Kallax",
                                    "CityId": "LULE",
                                    "CountryId": "SE",
                                    "Location": "22.1278, 65.5447"
                                }
                            ]
                        },
                        {
                            "Id": "LINK",
                            "Name": "Linkoping",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.616667, 58.416668",
                            "IataCode": "LPI",
                            "Airports": [
                                {
                                    "Id": "LPI",
                                    "Name": "Linkoping City",
                                    "CityId": "LINK",
                                    "CountryId": "SE",
                                    "Location": "15.6805, 58.4062"
                                }
                            ]
                        },
                        {
                            "Id": "LYCK",
                            "Name": "Lycksele Hedlunda",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "18.666667, 64.6",
                            "IataCode": "LYC",
                            "Airports": [
                                {
                                    "Id": "LYC",
                                    "Name": "Lycksele Hedlunda",
                                    "CityId": "LYCK",
                                    "CountryId": "SE",
                                    "Location": "18.716667, 64.533333"
                                }
                            ]
                        },
                        {
                            "Id": "MORA",
                            "Name": "Mora",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.55, 61",
                            "IataCode": "MXX",
                            "Airports": [
                                {
                                    "Id": "MXX",
                                    "Name": "Mora-Siljan",
                                    "CityId": "MORA",
                                    "CountryId": "SE",
                                    "Location": "14.511383, 60.957908"
                                }
                            ]
                        },
                        {
                            "Id": "NORR",
                            "Name": "Norrkoping Kungsangen",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.183333, 58.6",
                            "IataCode": "NRK",
                            "Airports": [
                                {
                                    "Id": "NRK",
                                    "Name": "Norrkoping Kungsangen",
                                    "CityId": "NORR",
                                    "CountryId": "SE",
                                    "Location": "16.25, 58.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ORNS",
                            "Name": "Ornskoldsvik",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "18.716667, 63.3",
                            "IataCode": "OER",
                            "Airports": [
                                {
                                    "Id": "OER",
                                    "Name": "Ornskoldsvik",
                                    "CityId": "ORNS",
                                    "CountryId": "SE",
                                    "Location": "18.991667, 63.408333"
                                }
                            ]
                        },
                        {
                            "Id": "OREB",
                            "Name": "Orebro",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.216667, 59.283333",
                            "IataCode": "ORB",
                            "Airports": [
                                {
                                    "Id": "ORB",
                                    "Name": "Orebro",
                                    "CityId": "OREB",
                                    "CountryId": "SE",
                                    "Location": "15.037956, 59.223733"
                                }
                            ]
                        },
                        {
                            "Id": "OSTE",
                            "Name": "Ostersund",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.65, 63.183333",
                            "IataCode": "OSD",
                            "Airports": [
                                {
                                    "Id": "OSD",
                                    "Name": "Ostersund Froesoe",
                                    "CityId": "OSTE",
                                    "CountryId": "SE",
                                    "Location": "14.489937, 63.196496"
                                }
                            ]
                        },
                        {
                            "Id": "RONN",
                            "Name": "Ronneby",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.276017, 56.209986",
                            "IataCode": "RNB",
                            "Airports": [
                                {
                                    "Id": "RNB",
                                    "Name": "Ronneby",
                                    "CityId": "RONN",
                                    "CountryId": "SE",
                                    "Location": "15.261111, 56.258333"
                                }
                            ]
                        },
                        {
                            "Id": "UMEA",
                            "Name": "Umea",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "20.250001, 63.833333",
                            "IataCode": "UME",
                            "Airports": [
                                {
                                    "Id": "UME",
                                    "Name": "Umea",
                                    "CityId": "UMEA",
                                    "CountryId": "SE",
                                    "Location": "20.284444, 63.793611"
                                }
                            ]
                        },
                        {
                            "Id": "VISB",
                            "Name": "Visby",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "18.3, 57.633333",
                            "IataCode": "VBY",
                            "Airports": [
                                {
                                    "Id": "VBY",
                                    "Name": "Visby",
                                    "CityId": "VISB",
                                    "CountryId": "SE",
                                    "Location": "18.349444, 57.663056"
                                }
                            ]
                        },
                        {
                            "Id": "VILH",
                            "Name": "Vilhelmina Sagadal",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.65, 64.616667",
                            "IataCode": "VHM",
                            "Airports": [
                                {
                                    "Id": "VHM",
                                    "Name": "Vilhelmina Sagadal",
                                    "CityId": "VILH",
                                    "CountryId": "SE",
                                    "Location": "16.65, 64.616667"
                                }
                            ]
                        },
                        {
                            "Id": "VAXJ",
                            "Name": "Vaxjo",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.816667, 56.883333",
                            "IataCode": "VXO",
                            "Airports": [
                                {
                                    "Id": "VXO",
                                    "Name": "Vaxjo",
                                    "CityId": "VAXJ",
                                    "CountryId": "SE",
                                    "Location": "14.733333, 56.933333"
                                }
                            ]
                        },
                        {
                            "Id": "TORS",
                            "Name": "Torsby Fryklanda",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13, 60.133333",
                            "IataCode": "TYF",
                            "Airports": [
                                {
                                    "Id": "TYF",
                                    "Name": "Torsby Fryklanda",
                                    "CityId": "TORS",
                                    "CountryId": "SE",
                                    "Location": "13, 60.133333"
                                }
                            ]
                        },
                        {
                            "Id": "XWPA",
                            "Name": "Hassleholm",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.766667, 56.15",
                            "IataCode": "XWP",
                            "Airports": [
                                {
                                    "Id": "XWP",
                                    "Name": "Hassleholm",
                                    "CityId": "XWPA",
                                    "CountryId": "SE",
                                    "Location": "13.76361, 56.15778"
                                }
                            ]
                        },
                        {
                            "Id": "OSKA",
                            "Name": "Oskarshamn",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.433333, 57.266667",
                            "IataCode": "OSK",
                            "Airports": [
                                {
                                    "Id": "OSK",
                                    "Name": "Oskarshamn",
                                    "CityId": "OSKA",
                                    "CountryId": "SE",
                                    "Location": "16.497972, 57.350453"
                                }
                            ]
                        },
                        {
                            "Id": "PAJA",
                            "Name": "Pajala",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "23.366074, 67.212843",
                            "IataCode": "PJA",
                            "Airports": [
                                {
                                    "Id": "PJA",
                                    "Name": "Pajala",
                                    "CityId": "PAJA",
                                    "CountryId": "SE",
                                    "Location": "23.075, 67.246667"
                                }
                            ]
                        },
                        {
                            "Id": "SKEL",
                            "Name": "Skelleftea",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "20.95, 64.766667",
                            "IataCode": "SFT",
                            "Airports": [
                                {
                                    "Id": "SFT",
                                    "Name": "Skelleftea",
                                    "CityId": "SKEL",
                                    "CountryId": "SE",
                                    "Location": "21.078333, 64.625"
                                }
                            ]
                        },
                        {
                            "Id": "SODF",
                            "Name": "Soderhamn",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.05, 61.3",
                            "IataCode": "SOO",
                            "Airports": [
                                {
                                    "Id": "SOO",
                                    "Name": "Soderhamn",
                                    "CityId": "SODF",
                                    "CountryId": "SE",
                                    "Location": "17.09917, 61.26139"
                                }
                            ]
                        },
                        {
                            "Id": "STOT",
                            "Name": "Storurnan",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.1, 65.1",
                            "IataCode": "SQO",
                            "Airports": [
                                {
                                    "Id": "SQO",
                                    "Name": "Storurnan",
                                    "CityId": "STOT",
                                    "CountryId": "SE",
                                    "Location": "17.698631, 64.956552"
                                }
                            ]
                        },
                        {
                            "Id": "VAST",
                            "Name": "Vastervik",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.633333, 57.75",
                            "IataCode": "VVK",
                            "Airports": [
                                {
                                    "Id": "VVK",
                                    "Name": "Vastervik",
                                    "CityId": "VAST",
                                    "CountryId": "SE",
                                    "Location": "16.633333, 57.75"
                                }
                            ]
                        },
                        {
                            "Id": "SODE",
                            "Name": "Sodertalje",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.616667, 59.200001",
                            "IataCode": "JSO",
                            "Airports": [
                                {
                                    "Id": "JSO",
                                    "Name": "Sodertalje",
                                    "CityId": "SODE",
                                    "CountryId": "SE",
                                    "Location": "17.65, 59.2"
                                }
                            ]
                        },
                        {
                            "Id": "XWZA",
                            "Name": "Nykoping",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17, 58.75",
                            "IataCode": "XWZ",
                            "Airports": [
                                {
                                    "Id": "XWZ",
                                    "Name": "Nykoping",
                                    "CityId": "XWZA",
                                    "CountryId": "SE",
                                    "Location": "16.99472, 58.75556"
                                }
                            ]
                        },
                        {
                            "Id": "TROL",
                            "Name": "Trollhattan",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "12.3, 58.266667",
                            "IataCode": "THN",
                            "Airports": [
                                {
                                    "Id": "THN",
                                    "Name": "Trollhattan Vanersborg",
                                    "CityId": "TROL",
                                    "CountryId": "SE",
                                    "Location": "12.342453, 58.319587"
                                }
                            ]
                        },
                        {
                            "Id": "SUND",
                            "Name": "Sundsvall",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.299999, 62.383333",
                            "IataCode": "SDL",
                            "Airports": [
                                {
                                    "Id": "SDL",
                                    "Name": "Sundsvall Timra",
                                    "CityId": "SUND",
                                    "CountryId": "SE",
                                    "Location": "17.45, 62.533333"
                                }
                            ]
                        },
                        {
                            "Id": "XXAA",
                            "Name": "Alvesta",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.55, 56.9",
                            "IataCode": "XXA",
                            "Airports": [
                                {
                                    "Id": "XXA",
                                    "Name": "Alvesta",
                                    "CityId": "XXAA",
                                    "CountryId": "SE",
                                    "Location": "14.55778, 56.89889"
                                }
                            ]
                        },
                        {
                            "Id": "GAVL",
                            "Name": "Gavle Sandviken",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.166667, 60.666668",
                            "IataCode": "GVX",
                            "Airports": [
                                {
                                    "Id": "GVX",
                                    "Name": "Gavle Sandviken",
                                    "CityId": "GAVL",
                                    "CountryId": "SE",
                                    "Location": "16.954722, 60.593889"
                                }
                            ]
                        },
                        {
                            "Id": "XYOA",
                            "Name": "Karlshamn",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.85, 56.166667",
                            "IataCode": "XYO",
                            "Airports": [
                                {
                                    "Id": "XYO",
                                    "Name": "Karlshamn",
                                    "CityId": "XYOA",
                                    "CountryId": "SE",
                                    "Location": "14.86806, 56.17694"
                                }
                            ]
                        },
                        {
                            "Id": "XXKA",
                            "Name": "Katrineholm",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.2, 59",
                            "IataCode": "XXK",
                            "Airports": [
                                {
                                    "Id": "XXK",
                                    "Name": "Katrineholm",
                                    "CityId": "XXKA",
                                    "CountryId": "SE",
                                    "Location": "16.20833, 58.99694"
                                }
                            ]
                        },
                        {
                            "Id": "KRAM",
                            "Name": "Kramfors",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.7925, 62.933333",
                            "IataCode": "KRF",
                            "Airports": [
                                {
                                    "Id": "KRF",
                                    "Name": "Kramfors Solleftea",
                                    "CityId": "KRAM",
                                    "CountryId": "SE",
                                    "Location": "17.772778, 63.049444"
                                }
                            ]
                        },
                        {
                            "Id": "XYXA",
                            "Name": "Sala",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.6, 59.916667",
                            "IataCode": "XYX",
                            "Airports": [
                                {
                                    "Id": "XYX",
                                    "Name": "Sala",
                                    "CityId": "XYXA",
                                    "CountryId": "SE",
                                    "Location": "16.6075, 59.92639"
                                }
                            ]
                        },
                        {
                            "Id": "XYUA",
                            "Name": "Solvesborg",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "14.575253, 56.052053",
                            "IataCode": "XYU",
                            "Airports": [
                                {
                                    "Id": "XYU",
                                    "Name": "Solvesborg",
                                    "CityId": "XYUA",
                                    "CountryId": "SE",
                                    "Location": "14.58278, 56.05"
                                }
                            ]
                        },
                        {
                            "Id": "XWQA",
                            "Name": "Enkoping",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "17.066666, 59.633333",
                            "IataCode": "XWQ",
                            "Airports": [
                                {
                                    "Id": "XWQ",
                                    "Name": "Enkoping",
                                    "CityId": "XWQA",
                                    "CountryId": "SE",
                                    "Location": "17.08833, 59.64444"
                                }
                            ]
                        },
                        {
                            "Id": "HULT",
                            "Name": "Hultsfred",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.843573, 57.488154",
                            "IataCode": "HLF",
                            "Airports": [
                                {
                                    "Id": "HLF",
                                    "Name": "Hultsfred",
                                    "CityId": "HULT",
                                    "CountryId": "SE",
                                    "Location": "15.827778, 57.526389"
                                }
                            ]
                        },
                        {
                            "Id": "SKOV",
                            "Name": "Skovde",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.916667, 58.416667",
                            "IataCode": "KVB",
                            "Airports": [
                                {
                                    "Id": "KVB",
                                    "Name": "Skovde",
                                    "CityId": "SKOV",
                                    "CountryId": "SE",
                                    "Location": "13.966667, 58.45"
                                }
                            ]
                        },
                        {
                            "Id": "XYCA",
                            "Name": "Herrljunga",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.033333, 58.083333",
                            "IataCode": "XYC",
                            "Airports": [
                                {
                                    "Id": "XYC",
                                    "Name": "Herrljunga",
                                    "CityId": "XYCA",
                                    "CountryId": "SE",
                                    "Location": "13.03333, 58.08333"
                                }
                            ]
                        },
                        {
                            "Id": "XXUA",
                            "Name": "Hedemora",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "15.983333, 60.283333",
                            "IataCode": "XXU",
                            "Airports": [
                                {
                                    "Id": "XXU",
                                    "Name": "Hedemora",
                                    "CityId": "XXUA",
                                    "CountryId": "SE",
                                    "Location": "15.97556, 60.27639"
                                }
                            ]
                        },
                        {
                            "Id": "LIDK",
                            "Name": "Mariedal",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "13.183333, 58.466667",
                            "IataCode": "LDK",
                            "Airports": [
                                {
                                    "Id": "LDK",
                                    "Name": "Lidkoping Hovby",
                                    "CityId": "LIDK",
                                    "CountryId": "SE",
                                    "Location": "13.166667, 58.5"
                                }
                            ]
                        },
                        {
                            "Id": "XYPA",
                            "Name": "Krylbo",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "16.216667, 60.133333",
                            "IataCode": "XYP",
                            "Airports": [
                                {
                                    "Id": "XYP",
                                    "Name": "Avesta Krylbo",
                                    "CityId": "XYPA",
                                    "CountryId": "SE",
                                    "Location": "16.21444, 60.12917"
                                }
                            ]
                        },
                        {
                            "Id": "SCRA",
                            "Name": "Brunntjärnåsen",
                            "SingleAirportCity": true,
                            "CountryId": "SE",
                            "Location": "12.8, 61.166667",
                            "IataCode": "SCR",
                            "Airports": [
                                {
                                    "Id": "SCR",
                                    "Name": "Scandinavian Mountains",
                                    "CityId": "SCRA",
                                    "CountryId": "SE",
                                    "Location": "12.84, 61.159"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DK",
                    "Name": "Denmark",
                    "CurrencyId": "DKK",
                    "LanguageId": "DA",
                    "Cities": [
                        {
                            "Id": "AALB",
                            "Name": "Aalborg",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.9333328, 57.05",
                            "IataCode": "AAL",
                            "Airports": [
                                {
                                    "Id": "AAL",
                                    "Name": "Aalborg",
                                    "CityId": "AALB",
                                    "CountryId": "DK",
                                    "Location": "9.85, 57.093056"
                                }
                            ]
                        },
                        {
                            "Id": "SIND",
                            "Name": "Sindal",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "10.216667, 57.466667",
                            "IataCode": "CNL",
                            "Airports": [
                                {
                                    "Id": "CNL",
                                    "Name": "Sindal",
                                    "CityId": "SIND",
                                    "CountryId": "DK",
                                    "Location": "10.216667, 57.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SQWA",
                            "Name": "Skive",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.0333333, 56.566667",
                            "IataCode": "SQW",
                            "Airports": [
                                {
                                    "Id": "SQW",
                                    "Name": "Skive",
                                    "CityId": "SQWA",
                                    "CountryId": "DK",
                                    "Location": "9.033333, 56.566667"
                                }
                            ]
                        },
                        {
                            "Id": "BILL",
                            "Name": "Billund",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.1166667, 55.733333",
                            "IataCode": "BLL",
                            "Airports": [
                                {
                                    "Id": "BLL",
                                    "Name": "Billund",
                                    "CityId": "BILL",
                                    "CountryId": "DK",
                                    "Location": "9.15, 55.738333"
                                }
                            ]
                        },
                        {
                            "Id": "COPE",
                            "Name": "Copenhagen",
                            "SingleAirportCity": false,
                            "CountryId": "DK",
                            "Location": "12.570935, 55.677685",
                            "IataCode": "CPH",
                            "Airports": [
                                {
                                    "Id": "RKE",
                                    "Name": "Copenhagen Roskilde",
                                    "CityId": "COPE",
                                    "CountryId": "DK",
                                    "Location": "12.133333, 55.583333"
                                },
                                {
                                    "Id": "CPH",
                                    "Name": "Copenhagen",
                                    "CityId": "COPE",
                                    "CountryId": "DK",
                                    "Location": "12.633333, 55.608056"
                                }
                            ]
                        },
                        {
                            "Id": "ESBJ",
                            "Name": "Esbjerg",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "8.45, 55.466668",
                            "IataCode": "EBJ",
                            "Airports": [
                                {
                                    "Id": "EBJ",
                                    "Name": "Esbjerg",
                                    "CityId": "ESBJ",
                                    "CountryId": "DK",
                                    "Location": "8.5525, 55.526389"
                                }
                            ]
                        },
                        {
                            "Id": "KARU",
                            "Name": "Karup",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.1683483, 56.306729",
                            "IataCode": "KRP",
                            "Airports": [
                                {
                                    "Id": "KRP",
                                    "Name": "Karup",
                                    "CityId": "KARU",
                                    "CountryId": "DK",
                                    "Location": "9.116667, 56.3"
                                }
                            ]
                        },
                        {
                            "Id": "ODEN",
                            "Name": "Odense",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "10.383333, 55.400003",
                            "IataCode": "ODE",
                            "Airports": [
                                {
                                    "Id": "ODE",
                                    "Name": "Odense",
                                    "CityId": "ODEN",
                                    "CountryId": "DK",
                                    "Location": "10.328611, 55.476389"
                                }
                            ]
                        },
                        {
                            "Id": "SOND",
                            "Name": "Sonderborg",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.7833333, 54.916667",
                            "IataCode": "SGD",
                            "Airports": [
                                {
                                    "Id": "SGD",
                                    "Name": "Sonderborg",
                                    "CityId": "SOND",
                                    "CountryId": "DK",
                                    "Location": "9.791731, 54.964367"
                                }
                            ]
                        },
                        {
                            "Id": "VOJE",
                            "Name": "Vojens",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.3166667, 55.25",
                            "IataCode": "SKS",
                            "Airports": [
                                {
                                    "Id": "SKS",
                                    "Name": "Vojens",
                                    "CityId": "VOJE",
                                    "CountryId": "DK",
                                    "Location": "9.264722, 55.221944"
                                }
                            ]
                        },
                        {
                            "Id": "THIS",
                            "Name": "Thisted",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "8.6949063, 56.955225",
                            "IataCode": "TED",
                            "Airports": [
                                {
                                    "Id": "TED",
                                    "Name": "Thisted",
                                    "CityId": "THIS",
                                    "CountryId": "DK",
                                    "Location": "8.703333, 57.072222"
                                }
                            ]
                        },
                        {
                            "Id": "AARH",
                            "Name": "Aarhus",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "10.210762, 56.156739",
                            "IataCode": "AAR",
                            "Airports": [
                                {
                                    "Id": "AAR",
                                    "Name": "Aarhus",
                                    "CityId": "AARH",
                                    "CountryId": "DK",
                                    "Location": "10.618056, 56.310278"
                                }
                            ]
                        },
                        {
                            "Id": "VEJL",
                            "Name": "Vejle",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.5357036, 55.709271",
                            "IataCode": "VEJ",
                            "Airports": [
                                {
                                    "Id": "VEJ",
                                    "Name": "Vejle",
                                    "CityId": "VEJL",
                                    "CountryId": "DK",
                                    "Location": "9.533333, 55.7"
                                }
                            ]
                        },
                        {
                            "Id": "RONO",
                            "Name": "Ronne Bornholm",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "14.7, 55.1",
                            "IataCode": "RNN",
                            "Airports": [
                                {
                                    "Id": "RNN",
                                    "Name": "Ronne Bornholm",
                                    "CityId": "RONO",
                                    "CountryId": "DK",
                                    "Location": "14.757778, 55.065556"
                                }
                            ]
                        },
                        {
                            "Id": "QJVA",
                            "Name": "Skagen Z7",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "10.6, 57.733333",
                            "IataCode": "QJV",
                            "Airports": [
                                {
                                    "Id": "QJV",
                                    "Name": "Skagen Z7",
                                    "CityId": "QJVA",
                                    "CountryId": "DK",
                                    "Location": "10.6, 57.73333"
                                }
                            ]
                        },
                        {
                            "Id": "MARK",
                            "Name": "Maribo",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "11.516667, 54.766667",
                            "IataCode": "MRW",
                            "Airports": [
                                {
                                    "Id": "MRW",
                                    "Name": "Maribo",
                                    "CityId": "MARK",
                                    "CountryId": "DK",
                                    "Location": "11.43333, 54.7"
                                }
                            ]
                        },
                        {
                            "Id": "LAES",
                            "Name": "Laeso Island",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "11.126855, 57.319695",
                            "IataCode": "BYR",
                            "Airports": [
                                {
                                    "Id": "BYR",
                                    "Name": "Laeso Island",
                                    "CityId": "LAES",
                                    "CountryId": "DK",
                                    "Location": "11, 57.116667"
                                }
                            ]
                        },
                        {
                            "Id": "ZBUA",
                            "Name": "Aarhus Limo",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "9.4067384, 56.25712",
                            "IataCode": "ZBU",
                            "Airports": [
                                {
                                    "Id": "ZBU",
                                    "Name": "Aarhus Limo",
                                    "CityId": "ZBUA",
                                    "CountryId": "DK",
                                    "Location": "10.500556, 56.000556"
                                }
                            ]
                        },
                        {
                            "Id": "STAU",
                            "Name": "Østerby",
                            "SingleAirportCity": true,
                            "CountryId": "DK",
                            "Location": "8.35, 55.983333",
                            "IataCode": "STA",
                            "Airports": [
                                {
                                    "Id": "STA",
                                    "Name": "Stauning",
                                    "CityId": "STAU",
                                    "CountryId": "DK",
                                    "Location": "8.355, 55.990278"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AL",
                    "Name": "Albania",
                    "CurrencyId": "ALL",
                    "Cities": [
                        {
                            "Id": "TIRA",
                            "Name": "Tirana",
                            "SingleAirportCity": true,
                            "CountryId": "AL",
                            "Location": "19.818889, 41.3275",
                            "IataCode": "TIA",
                            "Airports": [
                                {
                                    "Id": "TIA",
                                    "Name": "Tirana",
                                    "CityId": "TIRA",
                                    "CountryId": "AL",
                                    "Location": "19.716667, 41.416944"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "RS",
                    "Name": "Serbia",
                    "CurrencyId": "RSD",
                    "Cities": [
                        {
                            "Id": "BELI",
                            "Name": "Belgrade",
                            "SingleAirportCity": false,
                            "CountryId": "RS",
                            "Location": "20.468056, 44.818614",
                            "IataCode": "BEG",
                            "Airports": [
                                {
                                    "Id": "BJY",
                                    "Name": "Belgrade Batajnica",
                                    "CityId": "BELI",
                                    "CountryId": "RS",
                                    "Location": "20.2575, 44.9353"
                                },
                                {
                                    "Id": "BEG",
                                    "Name": "Belgrade Nikola Tesla",
                                    "CityId": "BELI",
                                    "CountryId": "RS",
                                    "Location": "20.312222, 44.819167"
                                }
                            ]
                        },
                        {
                            "Id": "NISI",
                            "Name": "Nis",
                            "SingleAirportCity": true,
                            "CountryId": "RS",
                            "Location": "21.903333, 43.324724",
                            "IataCode": "INI",
                            "Airports": [
                                {
                                    "Id": "INI",
                                    "Name": "Nis",
                                    "CityId": "NISI",
                                    "CountryId": "RS",
                                    "Location": "21.851667, 43.333889"
                                }
                            ]
                        },
                        {
                            "Id": "NOVI",
                            "Name": "Novi Sad",
                            "SingleAirportCity": true,
                            "CountryId": "RS",
                            "Location": "19.836945, 45.251668",
                            "IataCode": "QND",
                            "Airports": [
                                {
                                    "Id": "QND",
                                    "Name": "Novi Sad",
                                    "CityId": "NOVI",
                                    "CountryId": "RS",
                                    "Location": "19.9833, 45.25"
                                }
                            ]
                        },
                        {
                            "Id": "UZCA",
                            "Name": "Uzice",
                            "SingleAirportCity": true,
                            "CountryId": "RS",
                            "Location": "19.848776, 43.858606",
                            "IataCode": "UZC",
                            "Airports": [
                                {
                                    "Id": "UZC",
                                    "Name": "Uzice",
                                    "CityId": "UZCA",
                                    "CountryId": "RS",
                                    "Location": "19.703056, 43.898889"
                                }
                            ]
                        },
                        {
                            "Id": "KVOA",
                            "Name": "Kraljevo",
                            "SingleAirportCity": true,
                            "CountryId": "RS",
                            "Location": "20.689444, 43.725833",
                            "IataCode": "KVO",
                            "Airports": [
                                {
                                    "Id": "KVO",
                                    "Name": "Morava",
                                    "CityId": "KVOA",
                                    "CountryId": "RS",
                                    "Location": "20.585833, 43.818056"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BA",
                    "Name": "Bosnia and Herzegovina",
                    "CurrencyId": "BAM",
                    "Cities": [
                        {
                            "Id": "BANJ",
                            "Name": "Banja Luka",
                            "SingleAirportCity": true,
                            "CountryId": "BA",
                            "Location": "17.185555, 44.775833",
                            "IataCode": "BNX",
                            "Airports": [
                                {
                                    "Id": "BNX",
                                    "Name": "Banja Luka",
                                    "CityId": "BANJ",
                                    "CountryId": "BA",
                                    "Location": "17.3, 44.933333"
                                }
                            ]
                        },
                        {
                            "Id": "MOST",
                            "Name": "Mostar",
                            "SingleAirportCity": true,
                            "CountryId": "BA",
                            "Location": "17.808055, 43.343334",
                            "IataCode": "OMO",
                            "Airports": [
                                {
                                    "Id": "OMO",
                                    "Name": "Mostar",
                                    "CityId": "MOST",
                                    "CountryId": "BA",
                                    "Location": "17.846667, 43.285556"
                                }
                            ]
                        },
                        {
                            "Id": "SARA",
                            "Name": "Sarajevo",
                            "SingleAirportCity": true,
                            "CountryId": "BA",
                            "Location": "18.383334, 43.850003",
                            "IataCode": "SJJ",
                            "Airports": [
                                {
                                    "Id": "SJJ",
                                    "Name": "Sarajevo International",
                                    "CityId": "SARA",
                                    "CountryId": "BA",
                                    "Location": "18.338056, 43.818889"
                                }
                            ]
                        },
                        {
                            "Id": "TZLA",
                            "Name": "Tuzla",
                            "SingleAirportCity": true,
                            "CountryId": "BA",
                            "Location": "18.669445, 44.54278",
                            "IataCode": "TZL",
                            "Airports": [
                                {
                                    "Id": "TZL",
                                    "Name": "Tuzla",
                                    "CityId": "TZLA",
                                    "CountryId": "BA",
                                    "Location": "18.709167, 44.409722"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VA",
                    "Name": "Vatican City State (Holy See)",
                    "CurrencyId": "EUR",
                    "Cities": []
                },
                {
                    "Id": "IT",
                    "Name": "Italy",
                    "CurrencyId": "EUR",
                    "LanguageId": "IT",
                    "Regions": [
                        {
                            "Id": "ABRUZ",
                            "Name": "Abruzzo",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "CALAB",
                            "Name": "Calabria",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "CAMPA",
                            "Name": "Campania",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "EMILI",
                            "Name": "Emilia-Romagna",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "FRIUL",
                            "Name": "Friuli-Venezia Giulia",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "LAZIO",
                            "Name": "Lazio",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "LIGUR",
                            "Name": "Liguria",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "LOMBA",
                            "Name": "Lombardy",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "MARCH",
                            "Name": "Marche",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "PIEMO",
                            "Name": "Piedmont",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "PUGLI",
                            "Name": "Apulia",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "SARDE",
                            "Name": "Sardinia",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "SICIL",
                            "Name": "Sicily",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "TOSCA",
                            "Name": "Tuscany",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "TRENT",
                            "Name": "Trentino-Alto Adige/Südtirol",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "UMBRI",
                            "Name": "Umbria",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "VALLE",
                            "Name": "Aosta Valley",
                            "CountryId": "IT"
                        },
                        {
                            "Id": "VENET",
                            "Name": "Veneto",
                            "CountryId": "IT"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "ALGS",
                            "Name": "Alghero Sardinia",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SARDE",
                            "Location": "8.3180556, 40.558889",
                            "IataCode": "AHO",
                            "Airports": [
                                {
                                    "Id": "AHO",
                                    "Name": "Alghero Sardinia",
                                    "CityId": "ALGS",
                                    "CountryId": "IT",
                                    "RegionId": "SARDE",
                                    "Location": "8.288889, 40.630556"
                                }
                            ]
                        },
                        {
                            "Id": "ANCO",
                            "Name": "Ancona",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "MARCH",
                            "Location": "13.510075, 43.598164",
                            "IataCode": "AOI",
                            "Airports": [
                                {
                                    "Id": "AOI",
                                    "Name": "Ancona",
                                    "CityId": "ANCO",
                                    "CountryId": "IT",
                                    "RegionId": "MARCH",
                                    "Location": "13.362319, 43.616342"
                                }
                            ]
                        },
                        {
                            "Id": "AOST",
                            "Name": "Aosta",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "VALLE",
                            "Location": "7.3166011, 45.73736",
                            "IataCode": "AOT",
                            "Airports": [
                                {
                                    "Id": "AOT",
                                    "Name": "Aosta",
                                    "CityId": "AOST",
                                    "CountryId": "IT",
                                    "RegionId": "VALLE",
                                    "Location": "7.3625, 45.738611"
                                }
                            ]
                        },
                        {
                            "Id": "BRIN",
                            "Name": "Brindisi",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "17.936815, 40.627731",
                            "IataCode": "BDS",
                            "Airports": [
                                {
                                    "Id": "BDS",
                                    "Name": "Brindisi",
                                    "CityId": "BRIN",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "17.946667, 40.658056"
                                }
                            ]
                        },
                        {
                            "Id": "BOLO",
                            "Name": "Bologna",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "EMILI",
                            "Location": "11.333417, 44.497937",
                            "IataCode": "BLQ",
                            "Airports": [
                                {
                                    "Id": "BLQ",
                                    "Name": "Bologna International",
                                    "CityId": "BOLO",
                                    "CountryId": "IT",
                                    "RegionId": "EMILI",
                                    "Location": "11.2925, 44.530556"
                                }
                            ]
                        },
                        {
                            "Id": "BARI",
                            "Name": "Bari",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "16.851185, 41.117734",
                            "IataCode": "BRI",
                            "Airports": [
                                {
                                    "Id": "BRI",
                                    "Name": "Bari",
                                    "CityId": "BARI",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "16.788333, 41.141111"
                                }
                            ]
                        },
                        {
                            "Id": "BOLZ",
                            "Name": "Bolzano",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TRENT",
                            "Location": "11.333576, 46.492716",
                            "IataCode": "BZO",
                            "Airports": [
                                {
                                    "Id": "BZO",
                                    "Name": "Bolzano",
                                    "CityId": "BOLZ",
                                    "CountryId": "IT",
                                    "RegionId": "TRENT",
                                    "Location": "11.326383, 46.460194"
                                }
                            ]
                        },
                        {
                            "Id": "CAGL",
                            "Name": "Cagliari",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SARDE",
                            "Location": "9.1346168, 39.207384",
                            "IataCode": "CAG",
                            "Airports": [
                                {
                                    "Id": "CAG",
                                    "Name": "Cagliari",
                                    "CityId": "CAGL",
                                    "CountryId": "IT",
                                    "RegionId": "SARDE",
                                    "Location": "9.061111, 39.247222"
                                }
                            ]
                        },
                        {
                            "Id": "ROME",
                            "Name": "Rome",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "LAZIO",
                            "Location": "12.460806, 41.885695",
                            "IataCode": "ROM",
                            "Airports": [
                                {
                                    "Id": "CIA",
                                    "Name": "Rome Ciampino",
                                    "CityId": "ROME",
                                    "CountryId": "IT",
                                    "RegionId": "LAZIO",
                                    "Location": "12.593333, 41.799444"
                                },
                                {
                                    "Id": "FCO",
                                    "Name": "Rome Fiumicino",
                                    "CityId": "ROME",
                                    "CountryId": "IT",
                                    "RegionId": "LAZIO",
                                    "Location": "12.246228, 41.799847"
                                }
                            ]
                        },
                        {
                            "Id": "COMI",
                            "Name": "Comiso",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "14.610184, 36.95134",
                            "IataCode": "CIY",
                            "Airports": [
                                {
                                    "Id": "CIY",
                                    "Name": "Comiso",
                                    "CityId": "COMI",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "14.606945, 36.991667"
                                }
                            ]
                        },
                        {
                            "Id": "CORT",
                            "Name": "Crotone",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CALAB",
                            "Location": "17.117814, 39.085095",
                            "IataCode": "CRV",
                            "Airports": [
                                {
                                    "Id": "CRV",
                                    "Name": "Crotone",
                                    "CityId": "CORT",
                                    "CountryId": "IT",
                                    "RegionId": "CALAB",
                                    "Location": "17.083333, 39"
                                }
                            ]
                        },
                        {
                            "Id": "CATA",
                            "Name": "Catania",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "15.08719, 37.502134",
                            "IataCode": "CTA",
                            "Airports": [
                                {
                                    "Id": "CTA",
                                    "Name": "Catania Fontanarossa",
                                    "CityId": "CATA",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "15.06575, 37.467254"
                                }
                            ]
                        },
                        {
                            "Id": "CUNE",
                            "Name": "Cuneo",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PIEMO",
                            "Location": "7.5445312, 44.39733",
                            "IataCode": "CUF",
                            "Airports": [
                                {
                                    "Id": "CUF",
                                    "Name": "Cuneo Levaldigi",
                                    "CityId": "CUNE",
                                    "CountryId": "IT",
                                    "RegionId": "PIEMO",
                                    "Location": "7.62125, 44.545585"
                                }
                            ]
                        },
                        {
                            "Id": "ALBE",
                            "Name": "Albenga",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "LIGUR",
                            "Location": "8.2182885, 44.049966",
                            "IataCode": "ALL",
                            "Airports": [
                                {
                                    "Id": "ALL",
                                    "Name": "Albenga",
                                    "CityId": "ALBE",
                                    "CountryId": "IT",
                                    "RegionId": "LIGUR",
                                    "Location": "8.216667, 44.05"
                                }
                            ]
                        },
                        {
                            "Id": "DECI",
                            "Name": "Decimomannu",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SARDE",
                            "Location": "8.9694444, 39.3125",
                            "IataCode": "DCI",
                            "Airports": [
                                {
                                    "Id": "DCI",
                                    "Name": "Decimomannu",
                                    "CityId": "DECI",
                                    "CountryId": "IT",
                                    "RegionId": "SARDE",
                                    "Location": "8.966667, 39.35"
                                }
                            ]
                        },
                        {
                            "Id": "FLOR",
                            "Name": "Florence",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "11.244979, 43.779909",
                            "IataCode": "FLR",
                            "Airports": [
                                {
                                    "Id": "FLR",
                                    "Name": "Florence",
                                    "CityId": "FLOR",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "11.201182, 43.808491"
                                },
                                {
                                    "Id": "ZMS",
                                    "Name": "Florence",
                                    "CityId": "FLOR",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "11.2028, 43.8"
                                }
                            ]
                        },
                        {
                            "Id": "FOGG",
                            "Name": "Foggia Gino Lisa",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "15.549251, 41.460932",
                            "IataCode": "FOG",
                            "Airports": [
                                {
                                    "Id": "FOG",
                                    "Name": "Foggia Gino Lisa",
                                    "CityId": "FOGG",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "15.539444, 41.426667"
                                }
                            ]
                        },
                        {
                            "Id": "FORL",
                            "Name": "Forlì",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "EMILI",
                            "Location": "12.052778, 44.223611",
                            "IataCode": "FRL",
                            "Airports": [
                                {
                                    "Id": "FRL",
                                    "Name": "Forlì",
                                    "CityId": "FORL",
                                    "CountryId": "IT",
                                    "RegionId": "EMILI",
                                    "Location": "12.068611, 44.195278"
                                }
                            ]
                        },
                        {
                            "Id": "GENO",
                            "Name": "Genoa",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "LIGUR",
                            "Location": "8.9338589, 44.406316",
                            "IataCode": "GOA",
                            "Airports": [
                                {
                                    "Id": "GOA",
                                    "Name": "Genoa",
                                    "CityId": "GENO",
                                    "CountryId": "IT",
                                    "RegionId": "LIGUR",
                                    "Location": "8.837778, 44.413056"
                                }
                            ]
                        },
                        {
                            "Id": "GROS",
                            "Name": "Grosseto Baccarini",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "11.107935, 42.771425",
                            "IataCode": "GRS",
                            "Airports": [
                                {
                                    "Id": "GRS",
                                    "Name": "Grosseto Baccarini",
                                    "CityId": "GROS",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "11.070556, 42.761944"
                                }
                            ]
                        },
                        {
                            "Id": "JESO",
                            "Name": "Jesolo",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "VENET",
                            "Location": "12.644749, 45.533108",
                            "IataCode": "JLO",
                            "Airports": [
                                {
                                    "Id": "JLO",
                                    "Name": "Jesolo",
                                    "CityId": "JESO",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "12.716667, 45.533333"
                                }
                            ]
                        },
                        {
                            "Id": "LUCC",
                            "Name": "Lucca",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "10.505848, 43.843565",
                            "IataCode": "LCV",
                            "Airports": [
                                {
                                    "Id": "LCV",
                                    "Name": "Lucca",
                                    "CityId": "LUCC",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "10.583333, 43.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MILA",
                            "Name": "Milan",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "LOMBA",
                            "Location": "9.1730166, 45.468394",
                            "IataCode": "MIL",
                            "Airports": [
                                {
                                    "Id": "SWK",
                                    "Name": "Milan Segrate",
                                    "CityId": "MILA",
                                    "CountryId": "IT",
                                    "RegionId": "LOMBA",
                                    "Location": "9.316667, 45.483333"
                                },
                                {
                                    "Id": "LIN",
                                    "Name": "Milan Linate",
                                    "CityId": "MILA",
                                    "CountryId": "IT",
                                    "RegionId": "LOMBA",
                                    "Location": "9.277778, 45.441667"
                                },
                                {
                                    "Id": "MXP",
                                    "Name": "Milan Malpensa",
                                    "CityId": "MILA",
                                    "CountryId": "IT",
                                    "RegionId": "LOMBA",
                                    "Location": "8.7243946, 45.629799"
                                },
                                {
                                    "Id": "BGY",
                                    "Name": "Milan Bergamo",
                                    "CityId": "MILA",
                                    "CountryId": "IT",
                                    "RegionId": "LOMBA",
                                    "Location": "9.700278, 45.668333"
                                }
                            ]
                        },
                        {
                            "Id": "NAPL",
                            "Name": "Naples",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "CAMPA",
                            "Location": "14.25, 40.833333",
                            "IataCode": "NAP",
                            "Airports": [
                                {
                                    "Id": "NAP",
                                    "Name": "Naples International",
                                    "CityId": "NAPL",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "14.289232, 40.884636"
                                },
                                {
                                    "Id": "ZMI",
                                    "Name": "Naples Merglna Rr",
                                    "CityId": "NAPL",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "14.2861, 40.8833"
                                },
                                {
                                    "Id": "ZMF",
                                    "Name": "Naples Beverello Harbor",
                                    "CityId": "NAPL",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "13.933889, 40.667222"
                                }
                            ]
                        },
                        {
                            "Id": "OLBI",
                            "Name": "Olbia",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SARDE",
                            "Location": "9.485629, 40.921373",
                            "IataCode": "OLB",
                            "Airports": [
                                {
                                    "Id": "OLB",
                                    "Name": "Olbia",
                                    "CityId": "OLBI",
                                    "CountryId": "IT",
                                    "RegionId": "SARDE",
                                    "Location": "9.51725, 40.898593"
                                }
                            ]
                        },
                        {
                            "Id": "PERU",
                            "Name": "Perugia",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "UMBRI",
                            "Location": "12.382858, 43.096745",
                            "IataCode": "PEG",
                            "Airports": [
                                {
                                    "Id": "PEG",
                                    "Name": "Perugia Santegidio",
                                    "CityId": "PERU",
                                    "CountryId": "IT",
                                    "RegionId": "UMBRI",
                                    "Location": "12.513428, 43.096721"
                                }
                            ]
                        },
                        {
                            "Id": "PARM",
                            "Name": "Parma",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "EMILI",
                            "Location": "10.328984, 44.802665",
                            "IataCode": "PMF",
                            "Airports": [
                                {
                                    "Id": "PMF",
                                    "Name": "Parma",
                                    "CityId": "PARM",
                                    "CountryId": "IT",
                                    "RegionId": "EMILI",
                                    "Location": "10.295906, 44.823491"
                                }
                            ]
                        },
                        {
                            "Id": "PALE",
                            "Name": "Palermo",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "13.359762, 38.115822",
                            "IataCode": "PMO",
                            "Airports": [
                                {
                                    "Id": "PMO",
                                    "Name": "Palermo",
                                    "CityId": "PALE",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "13.1, 38.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PISA",
                            "Name": "Pisa",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "10.396499, 43.71752",
                            "IataCode": "PSA",
                            "Airports": [
                                {
                                    "Id": "PSA",
                                    "Name": "Pisa",
                                    "CityId": "PISA",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "10.397842, 43.688976"
                                }
                            ]
                        },
                        {
                            "Id": "PESC",
                            "Name": "Pescara",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "ABRUZ",
                            "Location": "14.210207, 42.460244",
                            "IataCode": "PSR",
                            "Airports": [
                                {
                                    "Id": "PSR",
                                    "Name": "Pescara",
                                    "CityId": "PESC",
                                    "CountryId": "IT",
                                    "RegionId": "ABRUZ",
                                    "Location": "14.184444, 42.433889"
                                }
                            ]
                        },
                        {
                            "Id": "SLER",
                            "Name": "Salerno",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CAMPA",
                            "Location": "14.803745, 40.66321",
                            "IataCode": "QSR",
                            "Airports": [
                                {
                                    "Id": "QSR",
                                    "Name": "Salerno Costa d'Amalfi",
                                    "CityId": "SLER",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "14.772491, 40.676472"
                                }
                            ]
                        },
                        {
                            "Id": "REGG",
                            "Name": "Reggio Calabria",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CALAB",
                            "Location": "15.661294, 38.110467",
                            "IataCode": "REG",
                            "Airports": [
                                {
                                    "Id": "REG",
                                    "Name": "Reggio Calabria",
                                    "CityId": "REGG",
                                    "CountryId": "IT",
                                    "RegionId": "CALAB",
                                    "Location": "15.650833, 38.075278"
                                }
                            ]
                        },
                        {
                            "Id": "RIMI",
                            "Name": "Rimini",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "EMILI",
                            "Location": "12.580834, 44.063333",
                            "IataCode": "RMI",
                            "Airports": [
                                {
                                    "Id": "RMI",
                                    "Name": "Rimini",
                                    "CityId": "RIMI",
                                    "CountryId": "IT",
                                    "RegionId": "EMILI",
                                    "Location": "12.612222, 44.020833"
                                }
                            ]
                        },
                        {
                            "Id": "SIEN",
                            "Name": "Siena",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "11.332831, 43.320039",
                            "IataCode": "SAY",
                            "Airports": [
                                {
                                    "Id": "SAY",
                                    "Name": "Siena",
                                    "CityId": "SIEN",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "11.25, 43.25"
                                }
                            ]
                        },
                        {
                            "Id": "MARA",
                            "Name": "Lamezia Terme",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CALAB",
                            "Location": "16.309198, 38.965887",
                            "IataCode": "SUF",
                            "Airports": [
                                {
                                    "Id": "SUF",
                                    "Name": "Lamezia Terme",
                                    "CityId": "MARA",
                                    "CountryId": "IT",
                                    "RegionId": "CALAB",
                                    "Location": "16.240883, 38.907064"
                                }
                            ]
                        },
                        {
                            "Id": "TARA",
                            "Name": "Taranto Arlota Gro",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "17.229722, 40.476111",
                            "IataCode": "TAR",
                            "Airports": [
                                {
                                    "Id": "TAR",
                                    "Name": "Taranto Arlota Gro",
                                    "CityId": "TARA",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "17.401944, 40.517778"
                                }
                            ]
                        },
                        {
                            "Id": "TRAP",
                            "Name": "Trapani Birgi",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "12.510767, 38.015843",
                            "IataCode": "TPS",
                            "Airports": [
                                {
                                    "Id": "TPS",
                                    "Name": "Trapani Birgi",
                                    "CityId": "TRAP",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "12.486389, 37.911667"
                                }
                            ]
                        },
                        {
                            "Id": "TURI",
                            "Name": "Turin",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PIEMO",
                            "Location": "7.6868253, 45.07049",
                            "IataCode": "TRN",
                            "Airports": [
                                {
                                    "Id": "TRN",
                                    "Name": "Turin",
                                    "CityId": "TURI",
                                    "CountryId": "IT",
                                    "RegionId": "PIEMO",
                                    "Location": "7.6480601, 45.196521"
                                }
                            ]
                        },
                        {
                            "Id": "TRIE",
                            "Name": "Trieste",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "FRIUL",
                            "Location": "13.78, 45.648611",
                            "IataCode": "TRS",
                            "Airports": [
                                {
                                    "Id": "TRS",
                                    "Name": "Trieste",
                                    "CityId": "TRIE",
                                    "CountryId": "IT",
                                    "RegionId": "FRIUL",
                                    "Location": "13.466667, 45.833333"
                                }
                            ]
                        },
                        {
                            "Id": "VENI",
                            "Name": "Venice",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "VENET",
                            "Location": "12.346384, 45.416892",
                            "IataCode": "VCE",
                            "Airports": [
                                {
                                    "Id": "VCE",
                                    "Name": "Venice Marco Polo",
                                    "CityId": "VENI",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "12.35, 45.5"
                                },
                                {
                                    "Id": "TSF",
                                    "Name": "Venice Treviso",
                                    "CityId": "VENI",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "12.194422, 45.6484"
                                }
                            ]
                        },
                        {
                            "Id": "VICE",
                            "Name": "Vicenza",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "VENET",
                            "Location": "11.540897, 45.557294",
                            "IataCode": "VIC",
                            "Airports": [
                                {
                                    "Id": "VIC",
                                    "Name": "Vicenza",
                                    "CityId": "VICE",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "11.55, 45.55"
                                }
                            ]
                        },
                        {
                            "Id": "VERO",
                            "Name": "Verona",
                            "SingleAirportCity": false,
                            "CountryId": "IT",
                            "RegionId": "VENET",
                            "Location": "10.997787, 45.434186",
                            "IataCode": "VRN",
                            "Airports": [
                                {
                                    "Id": "VBS",
                                    "Name": "Verona Brescia",
                                    "CityId": "VERO",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "10.331388, 45.428611"
                                },
                                {
                                    "Id": "VRN",
                                    "Name": "Verona International",
                                    "CityId": "VERO",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "10.888533, 45.395706"
                                }
                            ]
                        },
                        {
                            "Id": "AVIA",
                            "Name": "Aviano",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "FRIUL",
                            "Location": "12.594722, 46.070556",
                            "IataCode": "AVB",
                            "Airports": [
                                {
                                    "Id": "AVB",
                                    "Name": "Aviano Air Base",
                                    "CityId": "AVIA",
                                    "CountryId": "IT",
                                    "RegionId": "FRIUL",
                                    "Location": "12.6, 46.033333"
                                }
                            ]
                        },
                        {
                            "Id": "RAVE",
                            "Name": "Ravenna",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "EMILI",
                            "Location": "12.201112, 44.4175",
                            "IataCode": "RAN",
                            "Airports": [
                                {
                                    "Id": "RAN",
                                    "Name": "Ravenna",
                                    "CityId": "RAVE",
                                    "CountryId": "IT",
                                    "RegionId": "EMILI",
                                    "Location": "12.223333, 44.366667"
                                }
                            ]
                        },
                        {
                            "Id": "UDIN",
                            "Name": "Udine",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "FRIUL",
                            "Location": "13.242223, 46.061944",
                            "IataCode": "UDN",
                            "Airports": [
                                {
                                    "Id": "UDN",
                                    "Name": "Udine",
                                    "CityId": "UDIN",
                                    "CountryId": "IT",
                                    "RegionId": "FRIUL",
                                    "Location": "13.183333, 46.033333"
                                }
                            ]
                        },
                        {
                            "Id": "CAPR",
                            "Name": "Capri",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CAMPA",
                            "Location": "14.243534, 40.549303",
                            "IataCode": "PRJ",
                            "Airports": [
                                {
                                    "Id": "PRJ",
                                    "Name": "Capri",
                                    "CityId": "CAPR",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "14.233333, 40.55"
                                }
                            ]
                        },
                        {
                            "Id": "SORR",
                            "Name": "Sorrento",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CAMPA",
                            "Location": "14.374409, 40.626005",
                            "IataCode": "RRO",
                            "Airports": [
                                {
                                    "Id": "RRO",
                                    "Name": "Sorrento",
                                    "CityId": "SORR",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "14.366667, 40.616667"
                                }
                            ]
                        },
                        {
                            "Id": "TFCA",
                            "Name": "Taormina",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "15.288506, 37.853577",
                            "IataCode": "TFC",
                            "Airports": [
                                {
                                    "Id": "TFC",
                                    "Name": "Taormina",
                                    "CityId": "TFCA",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "15.28333, 37.86667"
                                }
                            ]
                        },
                        {
                            "Id": "PEJA",
                            "Name": "Peschiei",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "16.015855, 41.946046",
                            "IataCode": "PEJ",
                            "Airports": [
                                {
                                    "Id": "PEJ",
                                    "Name": "Peschiei",
                                    "CityId": "PEJA",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "15.730556, 41.701667"
                                }
                            ]
                        },
                        {
                            "Id": "GBNA",
                            "Name": "San Giovanni Rotondo",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "15.719052, 41.704938",
                            "IataCode": "GBN",
                            "Airports": [
                                {
                                    "Id": "GBN",
                                    "Name": "San Giovanni Rotondo",
                                    "CityId": "GBNA",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "15.730556, 41.701667"
                                }
                            ]
                        },
                        {
                            "Id": "VIFA",
                            "Name": "Vieste",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "16.17496, 41.882446",
                            "IataCode": "VIF",
                            "Airports": [
                                {
                                    "Id": "VIF",
                                    "Name": "Vieste",
                                    "CityId": "VIFA",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "16.176944, 41.885278"
                                }
                            ]
                        },
                        {
                            "Id": "PANT",
                            "Name": "Pantelleria",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "11.95, 36.833333",
                            "IataCode": "PNL",
                            "Airports": [
                                {
                                    "Id": "PNL",
                                    "Name": "Pantelleria",
                                    "CityId": "PANT",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "11.966111, 36.813611"
                                }
                            ]
                        },
                        {
                            "Id": "TORT",
                            "Name": "Tortoli Arbatax",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SARDE",
                            "Location": "9.7055556, 39.934444",
                            "IataCode": "TTB",
                            "Airports": [
                                {
                                    "Id": "TTB",
                                    "Name": "Tortoli Arbatax",
                                    "CityId": "TORT",
                                    "CountryId": "IT",
                                    "RegionId": "SARDE",
                                    "Location": "9.683333, 39.916667"
                                }
                            ]
                        },
                        {
                            "Id": "ELBA",
                            "Name": "Marina di Campo",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "10.23428, 42.7426",
                            "IataCode": "EBA",
                            "Airports": [
                                {
                                    "Id": "EBA",
                                    "Name": "Elba Island",
                                    "CityId": "ELBA",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "10.2394, 42.7615"
                                }
                            ]
                        },
                        {
                            "Id": "LAMP",
                            "Name": "Lampedusa",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "12.6, 35.5",
                            "IataCode": "LMP",
                            "Airports": [
                                {
                                    "Id": "LMP",
                                    "Name": "Lampedusa",
                                    "CityId": "LAMP",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "12.616667, 35.5"
                                }
                            ]
                        },
                        {
                            "Id": "SORA",
                            "Name": "Sora",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "LAZIO",
                            "Location": "13.614105, 41.715723",
                            "IataCode": "QXE",
                            "Airports": [
                                {
                                    "Id": "QXE",
                                    "Name": "Sora",
                                    "CityId": "SORA",
                                    "CountryId": "IT",
                                    "RegionId": "LAZIO",
                                    "Location": "13.6167, 41.7167"
                                }
                            ]
                        },
                        {
                            "Id": "BELL",
                            "Name": "Belluno",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "VENET",
                            "Location": "12.170077, 46.315264",
                            "IataCode": "BLX",
                            "Airports": [
                                {
                                    "Id": "BLX",
                                    "Name": "Belluno",
                                    "CityId": "BELL",
                                    "CountryId": "IT",
                                    "RegionId": "VENET",
                                    "Location": "12.216667, 46.15"
                                }
                            ]
                        },
                        {
                            "Id": "LIUA",
                            "Name": "Linosa",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "12.85, 35.85",
                            "IataCode": "LIU",
                            "Airports": [
                                {
                                    "Id": "LIU",
                                    "Name": "Linosa",
                                    "CityId": "LIUA",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "12.083333, 35.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ISCH",
                            "Name": "Ischia",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "CAMPA",
                            "Location": "13.895833, 40.731389",
                            "IataCode": "ISH",
                            "Airports": [
                                {
                                    "Id": "ISH",
                                    "Name": "Ischia",
                                    "CityId": "ISCH",
                                    "CountryId": "IT",
                                    "RegionId": "CAMPA",
                                    "Location": "13.95, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "TQRA",
                            "Name": "Isole Tremiti",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "15.483333, 42.116667",
                            "IataCode": "TQR",
                            "Airports": [
                                {
                                    "Id": "TQR",
                                    "Name": "San Domino Island",
                                    "CityId": "TQRA",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "15.483333, 42.116667"
                                }
                            ]
                        },
                        {
                            "Id": "NSYA",
                            "Name": "Cuccumella",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "SICIL",
                            "Location": "14.933333, 37.366667",
                            "IataCode": "NSY",
                            "Airports": [
                                {
                                    "Id": "NSY",
                                    "Name": "Sigonella",
                                    "CityId": "NSYA",
                                    "CountryId": "IT",
                                    "RegionId": "SICIL",
                                    "Location": "14.933333, 37.4"
                                }
                            ]
                        },
                        {
                            "Id": "LECC",
                            "Name": "San Donato di Lecce",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "PUGLI",
                            "Location": "18.184225, 40.268126",
                            "IataCode": "LCC",
                            "Airports": [
                                {
                                    "Id": "LCC",
                                    "Name": "Lecce Galatina",
                                    "CityId": "LECC",
                                    "CountryId": "IT",
                                    "RegionId": "PUGLI",
                                    "Location": "18.133333, 40.242778"
                                }
                            ]
                        },
                        {
                            "Id": "MARM",
                            "Name": "Pila",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TOSCA",
                            "Location": "10.234795, 42.763398",
                            "IataCode": "QMM",
                            "Airports": [
                                {
                                    "Id": "QMM",
                                    "Name": "Marina Di Massa",
                                    "CityId": "MARM",
                                    "CountryId": "IT",
                                    "RegionId": "TOSCA",
                                    "Location": "10.233333, 42.766667"
                                }
                            ]
                        },
                        {
                            "Id": "CORU",
                            "Name": "Monteversciaco",
                            "SingleAirportCity": true,
                            "CountryId": "IT",
                            "RegionId": "TRENT",
                            "Location": "12.3475, 46.740556",
                            "IataCode": "CDF",
                            "Airports": [
                                {
                                    "Id": "CDF",
                                    "Name": "Cortina D'Ampezzo Fiames",
                                    "CityId": "CORU",
                                    "CountryId": "IT",
                                    "RegionId": "TRENT",
                                    "Location": "12.133333, 46.533333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KO",
                    "Name": "Kosovo",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "PRIT",
                            "Name": "Pristina",
                            "SingleAirportCity": true,
                            "CountryId": "KO",
                            "Location": "21.166667, 42.666669",
                            "IataCode": "PRN",
                            "Airports": [
                                {
                                    "Id": "PRN",
                                    "Name": "Pristina",
                                    "CityId": "PRIT",
                                    "CountryId": "KO",
                                    "Location": "21.035556, 42.573611"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "HU",
                    "Name": "Hungary",
                    "CurrencyId": "HUF",
                    "Cities": [
                        {
                            "Id": "BUDA",
                            "Name": "Budapest",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "19.096296, 47.485413",
                            "IataCode": "BUD",
                            "Airports": [
                                {
                                    "Id": "BUD",
                                    "Name": "Budapest",
                                    "CityId": "BUDA",
                                    "CountryId": "HU",
                                    "Location": "19.252317, 47.438415"
                                }
                            ]
                        },
                        {
                            "Id": "DEBR",
                            "Name": "Debrecen",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "21.633333, 47.533335",
                            "IataCode": "DEB",
                            "Airports": [
                                {
                                    "Id": "DEB",
                                    "Name": "Debrecen",
                                    "CityId": "DEBR",
                                    "CountryId": "HU",
                                    "Location": "21.7, 47.55"
                                }
                            ]
                        },
                        {
                            "Id": "MISK",
                            "Name": "Miskolc",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "20.783333, 48.1",
                            "IataCode": "MCQ",
                            "Airports": [
                                {
                                    "Id": "MCQ",
                                    "Name": "Miskolc",
                                    "CityId": "MISK",
                                    "CountryId": "HU",
                                    "Location": "20.833333, 48.116667"
                                }
                            ]
                        },
                        {
                            "Id": "SZEG",
                            "Name": "Szeged",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "20.148239, 46.252998",
                            "IataCode": "QZD",
                            "Airports": [
                                {
                                    "Id": "QZD",
                                    "Name": "Szeged",
                                    "CityId": "SZEG",
                                    "CountryId": "HU",
                                    "Location": "20.08917, 46.25083"
                                }
                            ]
                        },
                        {
                            "Id": "PECS",
                            "Name": "Pecs",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "18.233334, 46.083333",
                            "IataCode": "PEV",
                            "Airports": [
                                {
                                    "Id": "PEV",
                                    "Name": "Pecs",
                                    "CityId": "PECS",
                                    "CountryId": "HU",
                                    "Location": "18.24083, 45.99083"
                                }
                            ]
                        },
                        {
                            "Id": "SAAS",
                            "Name": "Balaton",
                            "SingleAirportCity": true,
                            "CountryId": "HU",
                            "Location": "20.316667, 48.1",
                            "IataCode": "SOB",
                            "Airports": [
                                {
                                    "Id": "SOB",
                                    "Name": "Balaton",
                                    "CityId": "SAAS",
                                    "CountryId": "HU",
                                    "Location": "17.159, 46.686"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MD",
                    "Name": "Moldova",
                    "CurrencyId": "MDL",
                    "Cities": [
                        {
                            "Id": "KIVA",
                            "Name": "Chisinau",
                            "SingleAirportCity": true,
                            "CountryId": "MD",
                            "Location": "28.8575, 47.005559",
                            "IataCode": "KIV",
                            "Airports": [
                                {
                                    "Id": "KIV",
                                    "Name": "Chisinau",
                                    "CityId": "KIVA",
                                    "CountryId": "MD",
                                    "Location": "28.933333, 46.916667"
                                }
                            ]
                        },
                        {
                            "Id": "BZYA",
                            "Name": "Beltsy",
                            "SingleAirportCity": true,
                            "CountryId": "MD",
                            "Location": "27.928889, 47.761667",
                            "IataCode": "BZY",
                            "Airports": [
                                {
                                    "Id": "BZY",
                                    "Name": "Beltsy",
                                    "CityId": "BZYA",
                                    "CountryId": "MD",
                                    "Location": "27.933333, 47.75"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "HR",
                    "Name": "Croatia",
                    "CurrencyId": "HRK",
                    "Cities": [
                        {
                            "Id": "ZADA",
                            "Name": "Zadar",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "15.242222, 44.119722",
                            "IataCode": "ZAD",
                            "Airports": [
                                {
                                    "Id": "ZAD",
                                    "Name": "Zadar",
                                    "CityId": "ZADA",
                                    "CountryId": "HR",
                                    "Location": "15.356667, 44.097778"
                                }
                            ]
                        },
                        {
                            "Id": "ZAGR",
                            "Name": "Zagreb",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "16, 45.800003",
                            "IataCode": "ZAG",
                            "Airports": [
                                {
                                    "Id": "ZAG",
                                    "Name": "Zagreb",
                                    "CityId": "ZAGR",
                                    "CountryId": "HR",
                                    "Location": "16.067501, 45.740863"
                                }
                            ]
                        },
                        {
                            "Id": "BOLL",
                            "Name": "Bol",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "16.655, 43.261944",
                            "IataCode": "BWK",
                            "Airports": [
                                {
                                    "Id": "BWK",
                                    "Name": "Bol",
                                    "CityId": "BOLL",
                                    "CountryId": "HR",
                                    "Location": "16.683333, 43.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MALI",
                            "Name": "Mali Losinj Losinj",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "14.468611, 44.530556",
                            "IataCode": "LSZ",
                            "Airports": [
                                {
                                    "Id": "LSZ",
                                    "Name": "Mali Losinj Losinj",
                                    "CityId": "MALI",
                                    "CountryId": "HR",
                                    "Location": "14.385833, 44.566944"
                                }
                            ]
                        },
                        {
                            "Id": "OSIJ",
                            "Name": "Osijek",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "18.693889, 45.551111",
                            "IataCode": "OSI",
                            "Airports": [
                                {
                                    "Id": "OSI",
                                    "Name": "Osijek",
                                    "CityId": "OSIJ",
                                    "CountryId": "HR",
                                    "Location": "18.810156, 45.462667"
                                }
                            ]
                        },
                        {
                            "Id": "PULA",
                            "Name": "Pula",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "13.848056, 44.868333",
                            "IataCode": "PUY",
                            "Airports": [
                                {
                                    "Id": "PUY",
                                    "Name": "Pula",
                                    "CityId": "PULA",
                                    "CountryId": "HR",
                                    "Location": "13.923611, 44.891667"
                                }
                            ]
                        },
                        {
                            "Id": "RUJE",
                            "Name": "Rijeka",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "14.409167, 45.343057",
                            "IataCode": "RJK",
                            "Airports": [
                                {
                                    "Id": "RJK",
                                    "Name": "Rijeka",
                                    "CityId": "RUJE",
                                    "CountryId": "HR",
                                    "Location": "14.568333, 45.215833"
                                }
                            ]
                        },
                        {
                            "Id": "SPLI",
                            "Name": "Split",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "16.439151, 43.508908",
                            "IataCode": "SPU",
                            "Airports": [
                                {
                                    "Id": "SPU",
                                    "Name": "Split",
                                    "CityId": "SPLI",
                                    "CountryId": "HR",
                                    "Location": "16.299132, 43.536448"
                                }
                            ]
                        },
                        {
                            "Id": "DUBR",
                            "Name": "Dubrovnik",
                            "SingleAirportCity": true,
                            "CountryId": "HR",
                            "Location": "18.093652, 42.649965",
                            "IataCode": "DBV",
                            "Airports": [
                                {
                                    "Id": "DBV",
                                    "Name": "Dubrovnik",
                                    "CityId": "DUBR",
                                    "CountryId": "HR",
                                    "Location": "18.262132, 42.560874"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MC",
                    "Name": "Monaco",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "MCMA",
                            "Name": "Monte Carlo",
                            "SingleAirportCity": true,
                            "CountryId": "MC",
                            "Location": "7.4166668, 43.733333",
                            "IataCode": "MCM",
                            "Airports": [
                                {
                                    "Id": "MCM",
                                    "Name": "Monte Carlo",
                                    "CityId": "MCMA",
                                    "CountryId": "MC",
                                    "Location": "7.416667, 43.733333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MT",
                    "Name": "Malta",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "MLAA",
                            "Name": "Valletta",
                            "SingleAirportCity": true,
                            "CountryId": "MT",
                            "Location": "14.514722, 35.899722",
                            "IataCode": "MLA",
                            "Airports": [
                                {
                                    "Id": "MLA",
                                    "Name": "Luqa Malta International",
                                    "CityId": "MLAA",
                                    "CountryId": "MT",
                                    "Location": "14.486829, 35.852827"
                                }
                            ]
                        },
                        {
                            "Id": "GOZO",
                            "Name": "Gozo",
                            "SingleAirportCity": true,
                            "CountryId": "MT",
                            "Location": "14.25308, 36.047724",
                            "IataCode": "GZM",
                            "Airports": [
                                {
                                    "Id": "GZM",
                                    "Name": "Gozo",
                                    "CityId": "GOZO",
                                    "CountryId": "MT",
                                    "Location": "14.2728, 36.0272"
                                }
                            ]
                        },
                        {
                            "Id": "JCOA",
                            "Name": "Comino",
                            "SingleAirportCity": true,
                            "CountryId": "MT",
                            "Location": "14.336329, 36.012033",
                            "IataCode": "JCO",
                            "Airports": [
                                {
                                    "Id": "JCO",
                                    "Name": "Comino Hlpt",
                                    "CityId": "JCOA",
                                    "CountryId": "MT",
                                    "Location": "14.3333, 36.0167"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GR",
                    "Name": "Greece",
                    "CurrencyId": "EUR",
                    "LanguageId": "GR",
                    "Cities": [
                        {
                            "Id": "AGRI",
                            "Name": "Agrinion",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "21.407778, 38.621389",
                            "IataCode": "AGQ",
                            "Airports": [
                                {
                                    "Id": "AGQ",
                                    "Name": "Agrinion",
                                    "CityId": "AGRI",
                                    "CountryId": "GR",
                                    "Location": "21.351944, 38.604167"
                                }
                            ]
                        },
                        {
                            "Id": "KARP",
                            "Name": "Karpathos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "27.233333, 35.5",
                            "IataCode": "AOK",
                            "Airports": [
                                {
                                    "Id": "AOK",
                                    "Name": "Karpathos",
                                    "CityId": "KARP",
                                    "CountryId": "GR",
                                    "Location": "27.15, 35.416667"
                                }
                            ]
                        },
                        {
                            "Id": "ZAKI",
                            "Name": "Zante (Zakynthos)",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "20.895278, 37.791389",
                            "IataCode": "ZTH",
                            "Airports": [
                                {
                                    "Id": "ZTH",
                                    "Name": "Zante (Zakynthos)",
                                    "CityId": "ZAKI",
                                    "CountryId": "GR",
                                    "Location": "20.8883, 37.7558"
                                }
                            ]
                        },
                        {
                            "Id": "LARI",
                            "Name": "Larisa",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.420278, 39.637223",
                            "IataCode": "LRA",
                            "Airports": [
                                {
                                    "Id": "LRA",
                                    "Name": "Larisa",
                                    "CityId": "LARI",
                                    "CountryId": "GR",
                                    "Location": "22.463889, 39.651111"
                                }
                            ]
                        },
                        {
                            "Id": "CORF",
                            "Name": "Corfu",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "19.919722, 39.62",
                            "IataCode": "CFU",
                            "Airports": [
                                {
                                    "Id": "CFU",
                                    "Name": "Corfu",
                                    "CityId": "CORF",
                                    "CountryId": "GR",
                                    "Location": "19.910278, 39.601389"
                                }
                            ]
                        },
                        {
                            "Id": "IOAN",
                            "Name": "Ioannina",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "20.850833, 39.6675",
                            "IataCode": "IOA",
                            "Airports": [
                                {
                                    "Id": "IOA",
                                    "Name": "Ioannina",
                                    "CityId": "IOAN",
                                    "CountryId": "GR",
                                    "Location": "20.825833, 39.696667"
                                }
                            ]
                        },
                        {
                            "Id": "CHIO",
                            "Name": "Chios",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.135833, 38.367778",
                            "IataCode": "JKH",
                            "Airports": [
                                {
                                    "Id": "JKH",
                                    "Name": "Chios",
                                    "CityId": "CHIO",
                                    "CountryId": "GR",
                                    "Location": "26.141667, 38.345"
                                }
                            ]
                        },
                        {
                            "Id": "NAXO",
                            "Name": "Naxos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.376389, 37.105556",
                            "IataCode": "JNX",
                            "Airports": [
                                {
                                    "Id": "JNX",
                                    "Name": "Naxos",
                                    "CityId": "NAXO",
                                    "CountryId": "GR",
                                    "Location": "25.383333, 37.1"
                                }
                            ]
                        },
                        {
                            "Id": "SITI",
                            "Name": "Sitia",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.098611, 35.2",
                            "IataCode": "JSH",
                            "Airports": [
                                {
                                    "Id": "JSH",
                                    "Name": "Sitia",
                                    "CityId": "SITI",
                                    "CountryId": "GR",
                                    "Location": "26.116667, 35.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SKIA",
                            "Name": "Skiathos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "23.483333, 39.166667",
                            "IataCode": "JSI",
                            "Airports": [
                                {
                                    "Id": "JSI",
                                    "Name": "Skiathos Island National",
                                    "CityId": "SKIA",
                                    "CountryId": "GR",
                                    "Location": "23.505556, 39.180556"
                                }
                            ]
                        },
                        {
                            "Id": "SPET",
                            "Name": "Spetsai",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "23.15, 37.266667",
                            "IataCode": "JSS",
                            "Airports": [
                                {
                                    "Id": "JSS",
                                    "Name": "Spetsai",
                                    "CityId": "SPET",
                                    "CountryId": "GR",
                                    "Location": "23.166667, 37.25"
                                }
                            ]
                        },
                        {
                            "Id": "KAST",
                            "Name": "Kastoria Aristotlis",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "21.266667, 40.516667",
                            "IataCode": "KSO",
                            "Airports": [
                                {
                                    "Id": "KSO",
                                    "Name": "Kastoria Aristotlis",
                                    "CityId": "KAST",
                                    "CountryId": "GR",
                                    "Location": "21.273333, 40.450833"
                                }
                            ]
                        },
                        {
                            "Id": "KITH",
                            "Name": "Kithira",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.9925, 36.15",
                            "IataCode": "KIT",
                            "Airports": [
                                {
                                    "Id": "KIT",
                                    "Name": "Kithira",
                                    "CityId": "KITH",
                                    "CountryId": "GR",
                                    "Location": "23.025, 36.291667"
                                }
                            ]
                        },
                        {
                            "Id": "KALA",
                            "Name": "Kalamata",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.114167, 37.038889",
                            "IataCode": "KLX",
                            "Airports": [
                                {
                                    "Id": "KLX",
                                    "Name": "Kalamata International",
                                    "CityId": "KALA",
                                    "CountryId": "GR",
                                    "Location": "22.028704, 37.056136"
                                }
                            ]
                        },
                        {
                            "Id": "KAVA",
                            "Name": "Kavala",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "24.401945, 40.939722",
                            "IataCode": "KVA",
                            "Airports": [
                                {
                                    "Id": "KVA",
                                    "Name": "Kavala Alexandros",
                                    "CityId": "KAVA",
                                    "CountryId": "GR",
                                    "Location": "24.61922, 40.91331"
                                }
                            ]
                        },
                        {
                            "Id": "KOZA",
                            "Name": "Kozani",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "21.786389, 40.301111",
                            "IataCode": "KZI",
                            "Airports": [
                                {
                                    "Id": "KZI",
                                    "Name": "Kozani Philippos",
                                    "CityId": "KOZA",
                                    "CountryId": "GR",
                                    "Location": "21.841944, 40.288611"
                                }
                            ]
                        },
                        {
                            "Id": "MYTI",
                            "Name": "Mytilene",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.554722, 39.11",
                            "IataCode": "MJT",
                            "Airports": [
                                {
                                    "Id": "MJT",
                                    "Name": "Mytilene",
                                    "CityId": "MYTI",
                                    "CountryId": "GR",
                                    "Location": "26.599444, 39.056111"
                                }
                            ]
                        },
                        {
                            "Id": "MILO",
                            "Name": "Milos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "24.433333, 36.75",
                            "IataCode": "MLO",
                            "Airports": [
                                {
                                    "Id": "MLO",
                                    "Name": "Milos",
                                    "CityId": "MILO",
                                    "CountryId": "GR",
                                    "Location": "24.476531, 36.696744"
                                }
                            ]
                        },
                        {
                            "Id": "PARO",
                            "Name": "Paros",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.15, 37.083333",
                            "IataCode": "PAS",
                            "Airports": [
                                {
                                    "Id": "PAS",
                                    "Name": "Paros",
                                    "CityId": "PARO",
                                    "CountryId": "GR",
                                    "Location": "25.128137, 37.010161"
                                }
                            ]
                        },
                        {
                            "Id": "RHOD",
                            "Name": "Rhodes",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "28.2225, 36.440833",
                            "IataCode": "RHO",
                            "Airports": [
                                {
                                    "Id": "RHO",
                                    "Name": "Rhodes",
                                    "CityId": "RHOD",
                                    "CountryId": "GR",
                                    "Location": "28.08629, 36.406501"
                                }
                            ]
                        },
                        {
                            "Id": "THES",
                            "Name": "Thessaloniki",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.935272, 40.640317",
                            "IataCode": "SKG",
                            "Airports": [
                                {
                                    "Id": "SKG",
                                    "Name": "Thessaloniki",
                                    "CityId": "THES",
                                    "CountryId": "GR",
                                    "Location": "22.971719, 40.520578"
                                }
                            ]
                        },
                        {
                            "Id": "SAMO",
                            "Name": "Samos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.976944, 37.757222",
                            "IataCode": "SMI",
                            "Airports": [
                                {
                                    "Id": "SMI",
                                    "Name": "Samos",
                                    "CityId": "SAMO",
                                    "CountryId": "GR",
                                    "Location": "26.913889, 37.688889"
                                }
                            ]
                        },
                        {
                            "Id": "SPAR",
                            "Name": "Sparti",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.429722, 37.073333",
                            "IataCode": "SPJ",
                            "Airports": [
                                {
                                    "Id": "SPJ",
                                    "Name": "Sparta",
                                    "CityId": "SPAR",
                                    "CountryId": "GR",
                                    "Location": "22.533333, 36.983333"
                                }
                            ]
                        },
                        {
                            "Id": "VOLP",
                            "Name": "Volos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "22.945833, 39.366667",
                            "IataCode": "VOL",
                            "Airports": [
                                {
                                    "Id": "VOL",
                                    "Name": "Volos",
                                    "CityId": "VOLP",
                                    "CountryId": "GR",
                                    "Location": "22.794339, 39.219619"
                                }
                            ]
                        },
                        {
                            "Id": "ATHE",
                            "Name": "Athens",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "23.734876, 37.988864",
                            "IataCode": "ATH",
                            "Airports": [
                                {
                                    "Id": "ATH",
                                    "Name": "Athens International",
                                    "CityId": "ATHE",
                                    "CountryId": "GR",
                                    "Location": "23.948298, 37.935477"
                                }
                            ]
                        },
                        {
                            "Id": "ALEY",
                            "Name": "Alexandroupolis Dimokritos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.874445, 40.8475",
                            "IataCode": "AXD",
                            "Airports": [
                                {
                                    "Id": "AXD",
                                    "Name": "Alexandroupolis Dimokritos",
                                    "CityId": "ALEY",
                                    "CountryId": "GR",
                                    "Location": "25.95, 40.8556"
                                }
                            ]
                        },
                        {
                            "Id": "PYRG",
                            "Name": "Pyrgos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "21.441021, 37.675125",
                            "IataCode": "PYR",
                            "Airports": [
                                {
                                    "Id": "PYR",
                                    "Name": "Pyrgos",
                                    "CityId": "PYRG",
                                    "CountryId": "GR",
                                    "Location": "21.466667, 37.683333"
                                }
                            ]
                        },
                        {
                            "Id": "DRAM",
                            "Name": "Drama",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "24.139445, 41.154444",
                            "IataCode": "DRM",
                            "Airports": [
                                {
                                    "Id": "DRM",
                                    "Name": "Drama",
                                    "CityId": "DRAM",
                                    "CountryId": "GR",
                                    "Location": "24.166667, 41.15"
                                }
                            ]
                        },
                        {
                            "Id": "SKIR",
                            "Name": "Skiros",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "24.563056, 38.904167",
                            "IataCode": "SKU",
                            "Airports": [
                                {
                                    "Id": "SKU",
                                    "Name": "Skiros",
                                    "CityId": "SKIR",
                                    "CountryId": "GR",
                                    "Location": "24.566667, 38.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MIKO",
                            "Name": "Mykonos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.328722, 37.445289",
                            "IataCode": "JMK",
                            "Airports": [
                                {
                                    "Id": "JMK",
                                    "Name": "Mykonos",
                                    "CityId": "MIKO",
                                    "CountryId": "GR",
                                    "Location": "25.344444, 37.436111"
                                }
                            ]
                        },
                        {
                            "Id": "THIR",
                            "Name": "Santorini (Thira)",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.433333, 36.416667",
                            "IataCode": "JTR",
                            "Airports": [
                                {
                                    "Id": "JTR",
                                    "Name": "Santorini (Thira)",
                                    "CityId": "THIR",
                                    "CountryId": "GR",
                                    "Location": "25.473093, 36.402959"
                                }
                            ]
                        },
                        {
                            "Id": "ARGO",
                            "Name": "Argostoli",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "20.481944, 38.173056",
                            "IataCode": "EFL",
                            "Airports": [
                                {
                                    "Id": "EFL",
                                    "Name": "Argostoli Kefalonia",
                                    "CityId": "ARGO",
                                    "CountryId": "GR",
                                    "Location": "20.505556, 38.118056"
                                }
                            ]
                        },
                        {
                            "Id": "JKLA",
                            "Name": "Kalymnos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.983333, 36.95",
                            "IataCode": "JKL",
                            "Airports": [
                                {
                                    "Id": "JKL",
                                    "Name": "Kalymnos",
                                    "CityId": "JKLA",
                                    "CountryId": "GR",
                                    "Location": "26.940556, 36.963333"
                                }
                            ]
                        },
                        {
                            "Id": "PREV",
                            "Name": "Preveza",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "20.75, 38.95",
                            "IataCode": "PVK",
                            "Airports": [
                                {
                                    "Id": "PVK",
                                    "Name": "Preveza",
                                    "CityId": "PREV",
                                    "CountryId": "GR",
                                    "Location": "20.765833, 38.926389"
                                }
                            ]
                        },
                        {
                            "Id": "ASTY",
                            "Name": "Astypalaia",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.35, 36.55",
                            "IataCode": "JTY",
                            "Airports": [
                                {
                                    "Id": "JTY",
                                    "Name": "Astypalaia",
                                    "CityId": "ASTY",
                                    "CountryId": "GR",
                                    "Location": "26.366667, 36.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KASU",
                            "Name": "Kastelorizo",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "29.593611, 36.148889",
                            "IataCode": "KZS",
                            "Airports": [
                                {
                                    "Id": "KZS",
                                    "Name": "Kastelorizo",
                                    "CityId": "KASU",
                                    "CountryId": "GR",
                                    "Location": "29.576483, 36.141514"
                                }
                            ]
                        },
                        {
                            "Id": "LERO",
                            "Name": "Leros",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.866667, 37.15",
                            "IataCode": "LRS",
                            "Airports": [
                                {
                                    "Id": "LRS",
                                    "Name": "Leros",
                                    "CityId": "LERO",
                                    "CountryId": "GR",
                                    "Location": "26.800289, 37.184903"
                                }
                            ]
                        },
                        {
                            "Id": "ARAX",
                            "Name": "Araxos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "21.416667, 38.166667",
                            "IataCode": "GPA",
                            "Airports": [
                                {
                                    "Id": "GPA",
                                    "Name": "Araxos",
                                    "CityId": "ARAX",
                                    "CountryId": "GR",
                                    "Location": "21.425556, 38.151111"
                                }
                            ]
                        },
                        {
                            "Id": "CRET",
                            "Name": "Crete",
                            "SingleAirportCity": false,
                            "CountryId": "GR",
                            "Location": "24.8677, 35.241276",
                            "IataCode": "HER",
                            "Airports": [
                                {
                                    "Id": "HER",
                                    "Name": "Crete Heraklion",
                                    "CityId": "CRET",
                                    "CountryId": "GR",
                                    "Location": "25.183333, 35.340278"
                                },
                                {
                                    "Id": "CHQ",
                                    "Name": "Crete Chania",
                                    "CityId": "CRET",
                                    "CountryId": "GR",
                                    "Location": "24.148333, 35.5325"
                                }
                            ]
                        },
                        {
                            "Id": "KASO",
                            "Name": "Kasos Island",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.916667, 35.4",
                            "IataCode": "KSJ",
                            "Airports": [
                                {
                                    "Id": "KSJ",
                                    "Name": "Kasos Island",
                                    "CityId": "KASO",
                                    "CountryId": "GR",
                                    "Location": "26.916667, 35.421389"
                                }
                            ]
                        },
                        {
                            "Id": "KOSS",
                            "Name": "Kos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "27.123452, 36.814682",
                            "IataCode": "KGS",
                            "Airports": [
                                {
                                    "Id": "KGS",
                                    "Name": "Kos",
                                    "CityId": "KOSS",
                                    "CountryId": "GR",
                                    "Location": "27.095278, 36.793056"
                                }
                            ]
                        },
                        {
                            "Id": "SYRO",
                            "Name": "Syros Island",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "24.915478, 37.434142",
                            "IataCode": "JSY",
                            "Airports": [
                                {
                                    "Id": "JSY",
                                    "Name": "Syros Island",
                                    "CityId": "SYRO",
                                    "CountryId": "GR",
                                    "Location": "24.95, 37.423611"
                                }
                            ]
                        },
                        {
                            "Id": "IKAR",
                            "Name": "Ikaria",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "26.157145, 37.597432",
                            "IataCode": "JIK",
                            "Airports": [
                                {
                                    "Id": "JIK",
                                    "Name": "Ikaria",
                                    "CityId": "IKAR",
                                    "CountryId": "GR",
                                    "Location": "26.333333, 37.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LEMN",
                            "Name": "Lemnos",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "25.25, 39.916667",
                            "IataCode": "LXS",
                            "Airports": [
                                {
                                    "Id": "LXS",
                                    "Name": "Lemnos",
                                    "CityId": "LEMN",
                                    "CountryId": "GR",
                                    "Location": "25.25, 39.916667"
                                }
                            ]
                        },
                        {
                            "Id": "PORU",
                            "Name": "Portochélion",
                            "SingleAirportCity": true,
                            "CountryId": "GR",
                            "Location": "23.15, 37.316667",
                            "IataCode": "PKH",
                            "Airports": [
                                {
                                    "Id": "PKH",
                                    "Name": "Porto Kheli Alexion",
                                    "CityId": "PORU",
                                    "CountryId": "GR",
                                    "Location": "23.148193, 37.299388"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IE",
                    "Name": "Ireland",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "CLBA",
                            "Name": "Castlebar",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.3, 53.85",
                            "IataCode": "CLB",
                            "Airports": [
                                {
                                    "Id": "CLB",
                                    "Name": "Castlebar",
                                    "CityId": "CLBA",
                                    "CountryId": "IE",
                                    "Location": "-9.3, 53.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LETT",
                            "Name": "Letterkenny",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-7.7333334, 54.95",
                            "IataCode": "LTR",
                            "Airports": [
                                {
                                    "Id": "LTR",
                                    "Name": "Letterkenny",
                                    "CityId": "LETT",
                                    "CountryId": "IE",
                                    "Location": "-7.733333, 54.95"
                                }
                            ]
                        },
                        {
                            "Id": "BELM",
                            "Name": "Belmullet",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.9908332, 54.225",
                            "IataCode": "BLY",
                            "Airports": [
                                {
                                    "Id": "BLY",
                                    "Name": "Belmullet",
                                    "CityId": "BELM",
                                    "CountryId": "IE",
                                    "Location": "-10, 54.233333"
                                }
                            ]
                        },
                        {
                            "Id": "BANT",
                            "Name": "Bantry",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.45, 51.683333",
                            "IataCode": "BYT",
                            "Airports": [
                                {
                                    "Id": "BYT",
                                    "Name": "Bantry",
                                    "CityId": "BANT",
                                    "CountryId": "IE",
                                    "Location": "-9.45, 51.683333"
                                }
                            ]
                        },
                        {
                            "Id": "DUBL",
                            "Name": "Dublin",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-6.2560177, 53.355504",
                            "IataCode": "DUB",
                            "Airports": [
                                {
                                    "Id": "DUB",
                                    "Name": "Dublin",
                                    "CityId": "DUBL",
                                    "CountryId": "IE",
                                    "Location": "-6.252222, 53.4325"
                                }
                            ]
                        },
                        {
                            "Id": "GALW",
                            "Name": "Galway",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.0488889, 53.271944",
                            "IataCode": "GWY",
                            "Airports": [
                                {
                                    "Id": "GWY",
                                    "Name": "Galway",
                                    "CityId": "GALW",
                                    "CountryId": "IE",
                                    "Location": "-8.936691, 53.293746"
                                }
                            ]
                        },
                        {
                            "Id": "INIT",
                            "Name": "Kilronan",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.6683333, 53.124722",
                            "IataCode": "IOR",
                            "Airports": [
                                {
                                    "Id": "IOR",
                                    "Name": "Kilronan",
                                    "CityId": "INIT",
                                    "CountryId": "IE",
                                    "Location": "-9.653893, 53.107217"
                                }
                            ]
                        },
                        {
                            "Id": "KILK",
                            "Name": "Kilkenny",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-7.2522223, 52.654167",
                            "IataCode": "KKY",
                            "Airports": [
                                {
                                    "Id": "KKY",
                                    "Name": "Kilkenny",
                                    "CityId": "KILK",
                                    "CountryId": "IE",
                                    "Location": "-7.25, 52.65"
                                }
                            ]
                        },
                        {
                            "Id": "KNOC",
                            "Name": "Knock",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-8.9166667, 53.783333",
                            "IataCode": "NOC",
                            "Airports": [
                                {
                                    "Id": "NOC",
                                    "Name": "Ireland West Airport Knock",
                                    "CityId": "KNOC",
                                    "CountryId": "IE",
                                    "Location": "-8.811254, 53.913203"
                                }
                            ]
                        },
                        {
                            "Id": "CORK",
                            "Name": "Cork",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-8.4958335, 51.898611",
                            "IataCode": "ORK",
                            "Airports": [
                                {
                                    "Id": "ORK",
                                    "Name": "Cork",
                                    "CityId": "CORK",
                                    "CountryId": "IE",
                                    "Location": "-8.490278, 51.843333"
                                }
                            ]
                        },
                        {
                            "Id": "SHAN",
                            "Name": "Shannon",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-8.8641667, 52.703889",
                            "IataCode": "SNN",
                            "Airports": [
                                {
                                    "Id": "SNN",
                                    "Name": "Shannon",
                                    "CityId": "SHAN",
                                    "CountryId": "IE",
                                    "Location": "-8.918611, 52.700556"
                                }
                            ]
                        },
                        {
                            "Id": "SLIG",
                            "Name": "Sligo",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-8.4694291, 54.269691",
                            "IataCode": "SXL",
                            "Airports": [
                                {
                                    "Id": "SXL",
                                    "Name": "Sligo",
                                    "CityId": "SLIG",
                                    "CountryId": "IE",
                                    "Location": "-8.599206, 54.280214"
                                }
                            ]
                        },
                        {
                            "Id": "WATE",
                            "Name": "Waterford",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-7.1119447, 52.258333",
                            "IataCode": "WAT",
                            "Airports": [
                                {
                                    "Id": "WAT",
                                    "Name": "Waterford",
                                    "CityId": "WATE",
                                    "CountryId": "IE",
                                    "Location": "-7.088889, 52.197222"
                                }
                            ]
                        },
                        {
                            "Id": "WEXF",
                            "Name": "Wexford Castlebrdg",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-6.4575002, 52.334167",
                            "IataCode": "WEX",
                            "Airports": [
                                {
                                    "Id": "WEX",
                                    "Name": "Wexford Castlebrdg",
                                    "CityId": "WEXF",
                                    "CountryId": "IE",
                                    "Location": "-6.466667, 52.333333"
                                }
                            ]
                        },
                        {
                            "Id": "DONE",
                            "Name": "Donegal",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-8.1166668, 54.65",
                            "IataCode": "CFN",
                            "Airports": [
                                {
                                    "Id": "CFN",
                                    "Name": "Donegal",
                                    "CityId": "DONE",
                                    "CountryId": "IE",
                                    "Location": "-8.340278, 55.041667"
                                }
                            ]
                        },
                        {
                            "Id": "KERR",
                            "Name": "Kerry",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.8167168, 52.122821",
                            "IataCode": "KIR",
                            "Airports": [
                                {
                                    "Id": "KIR",
                                    "Name": "Kerry",
                                    "CityId": "KERR",
                                    "CountryId": "IE",
                                    "Location": "-9.523783, 52.180878"
                                }
                            ]
                        },
                        {
                            "Id": "SPID",
                            "Name": "Spiddal Connemara",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.3027778, 53.246667",
                            "IataCode": "NNR",
                            "Airports": [
                                {
                                    "Id": "NNR",
                                    "Name": "Spiddal Connemara",
                                    "CityId": "SPID",
                                    "CountryId": "IE",
                                    "Location": "-9.466667, 53.216667"
                                }
                            ]
                        },
                        {
                            "Id": "INIU",
                            "Name": "Inisheer",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.5275, 53.058056",
                            "IataCode": "INQ",
                            "Airports": [
                                {
                                    "Id": "INQ",
                                    "Name": "Inisheer",
                                    "CityId": "INIU",
                                    "CountryId": "IE",
                                    "Location": "-9.433333, 53.033333"
                                }
                            ]
                        },
                        {
                            "Id": "INIS",
                            "Name": "Inishmaan",
                            "SingleAirportCity": true,
                            "CountryId": "IE",
                            "Location": "-9.583333, 53.083333",
                            "IataCode": "IIA",
                            "Airports": [
                                {
                                    "Id": "IIA",
                                    "Name": "Inishmaan",
                                    "CityId": "INIS",
                                    "CountryId": "IE",
                                    "Location": "-9.567268, 53.092497"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NL",
                    "Name": "Netherlands",
                    "CurrencyId": "EUR",
                    "LanguageId": "NL",
                    "Cities": [
                        {
                            "Id": "AMST",
                            "Name": "Amsterdam",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.8813243, 52.380429",
                            "IataCode": "AMS",
                            "Airports": [
                                {
                                    "Id": "AMS",
                                    "Name": "Amsterdam",
                                    "CityId": "AMST",
                                    "CountryId": "NL",
                                    "Location": "4.768056, 52.308333"
                                }
                            ]
                        },
                        {
                            "Id": "DENH",
                            "Name": "Den Helder De Kooy",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.7597216, 52.953333",
                            "IataCode": "DHR",
                            "Airports": [
                                {
                                    "Id": "DHR",
                                    "Name": "Den Helder De Kooy",
                                    "CityId": "DENH",
                                    "CountryId": "NL",
                                    "Location": "4.75, 52.95"
                                }
                            ]
                        },
                        {
                            "Id": "EIND",
                            "Name": "Eindhoven",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.4777778, 51.440835",
                            "IataCode": "EIN",
                            "Airports": [
                                {
                                    "Id": "EIN",
                                    "Name": "Eindhoven",
                                    "CityId": "EIND",
                                    "CountryId": "NL",
                                    "Location": "5.3783917, 51.45045"
                                }
                            ]
                        },
                        {
                            "Id": "ENSC",
                            "Name": "Enschede Twente",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "6.8958335, 52.218333",
                            "IataCode": "ENS",
                            "Airports": [
                                {
                                    "Id": "ENS",
                                    "Name": "Enschede Twente",
                                    "CityId": "ENSC",
                                    "CountryId": "NL",
                                    "Location": "6.878333, 52.271667"
                                }
                            ]
                        },
                        {
                            "Id": "BRED",
                            "Name": "Breda Gilze Rijn",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.7759627, 51.586564",
                            "IataCode": "GLZ",
                            "Airports": [
                                {
                                    "Id": "GLZ",
                                    "Name": "Breda Gilze Rijn",
                                    "CityId": "BRED",
                                    "CountryId": "NL",
                                    "Location": "4.75, 51.583333"
                                }
                            ]
                        },
                        {
                            "Id": "GRON",
                            "Name": "Groningen",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "6.5666678, 53.219167",
                            "IataCode": "GRQ",
                            "Airports": [
                                {
                                    "Id": "GRQ",
                                    "Name": "Groningen",
                                    "CityId": "GRON",
                                    "CountryId": "NL",
                                    "Location": "6.575556, 53.120278"
                                }
                            ]
                        },
                        {
                            "Id": "LEYA",
                            "Name": "Lelystad",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.4749993, 52.508333",
                            "IataCode": "LEY",
                            "Airports": [
                                {
                                    "Id": "LEY",
                                    "Name": "Lelystad",
                                    "CityId": "LEYA",
                                    "CountryId": "NL",
                                    "Location": "5.533333, 52.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LEID",
                            "Name": "Leiden Valkenburg",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.4930554, 52.158333",
                            "IataCode": "LID",
                            "Airports": [
                                {
                                    "Id": "LID",
                                    "Name": "Leiden Valkenburg",
                                    "CityId": "LEID",
                                    "CountryId": "NL",
                                    "Location": "4.41794, 52.16614"
                                }
                            ]
                        },
                        {
                            "Id": "LEEU",
                            "Name": "Leeuwarden",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.7833331, 53.200001",
                            "IataCode": "LWR",
                            "Airports": [
                                {
                                    "Id": "LWR",
                                    "Name": "Leeuwarden",
                                    "CityId": "LEEU",
                                    "CountryId": "NL",
                                    "Location": "5.8, 53.25"
                                }
                            ]
                        },
                        {
                            "Id": "MAAS",
                            "Name": "Maastricht",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.6888879, 50.848333",
                            "IataCode": "MST",
                            "Airports": [
                                {
                                    "Id": "MST",
                                    "Name": "Maastricht",
                                    "CityId": "MAAS",
                                    "CountryId": "NL",
                                    "Location": "5.773611, 50.911111"
                                }
                            ]
                        },
                        {
                            "Id": "ROTT",
                            "Name": "Rotterdam",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.4791667, 51.922503",
                            "IataCode": "RTM",
                            "Airports": [
                                {
                                    "Id": "RTM",
                                    "Name": "Rotterdam",
                                    "CityId": "ROTT",
                                    "CountryId": "NL",
                                    "Location": "4.444444, 51.958333"
                                }
                            ]
                        },
                        {
                            "Id": "BERH",
                            "Name": "Bergen Op Zoom Wonsdrecht",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.2916667, 51.495",
                            "IataCode": "BZM",
                            "Airports": [
                                {
                                    "Id": "BZM",
                                    "Name": "Bergen Op Zoom Wonsdrecht",
                                    "CityId": "BERH",
                                    "CountryId": "NL",
                                    "Location": "4.34203, 51.44909"
                                }
                            ]
                        },
                        {
                            "Id": "THEH",
                            "Name": "The Hague",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.2986099, 52.07667",
                            "IataCode": "HAG",
                            "Airports": [
                                {
                                    "Id": "HAG",
                                    "Name": "The Hague",
                                    "CityId": "THEH",
                                    "CountryId": "NL",
                                    "Location": "4.2917, 52.15"
                                }
                            ]
                        },
                        {
                            "Id": "WOEN",
                            "Name": "Woensdrecht Ab",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "4.3027779, 51.43",
                            "IataCode": "WOE",
                            "Airports": [
                                {
                                    "Id": "WOE",
                                    "Name": "Woensdrecht Ab",
                                    "CityId": "WOEN",
                                    "CountryId": "NL",
                                    "Location": "4.335, 51.435833"
                                }
                            ]
                        },
                        {
                            "Id": "UDEN",
                            "Name": "Oosterheide",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.6999999, 51.633333",
                            "IataCode": "UDE",
                            "Airports": [
                                {
                                    "Id": "UDE",
                                    "Name": "Uden Volkel",
                                    "CityId": "UDEN",
                                    "CountryId": "NL",
                                    "Location": "5.616667, 51.666667"
                                }
                            ]
                        },
                        {
                            "Id": "UTRE",
                            "Name": "Sterrenberg",
                            "SingleAirportCity": true,
                            "CountryId": "NL",
                            "Location": "5.2819439, 52.114167",
                            "IataCode": "UTC",
                            "Airports": [
                                {
                                    "Id": "UTC",
                                    "Name": "Utrecht Soesterbrg",
                                    "CityId": "UTRE",
                                    "CountryId": "NL",
                                    "Location": "5.27619, 52.12731"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NO",
                    "Name": "Norway",
                    "CurrencyId": "NOK",
                    "LanguageId": "NO",
                    "Cities": [
                        {
                            "Id": "SVOL",
                            "Name": "Svolvaer",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "14.566667, 68.233333",
                            "IataCode": "SVJ",
                            "Airports": [
                                {
                                    "Id": "SVJ",
                                    "Name": "Svolvaer",
                                    "CityId": "SVOL",
                                    "CountryId": "NO",
                                    "Location": "14.672222, 68.241667"
                                }
                            ]
                        },
                        {
                            "Id": "ALTA",
                            "Name": "Alta",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "23.241666, 69.966667",
                            "IataCode": "ALF",
                            "Airports": [
                                {
                                    "Id": "ALF",
                                    "Name": "Alta",
                                    "CityId": "ALTA",
                                    "CountryId": "NO",
                                    "Location": "23.354187, 69.979019"
                                }
                            ]
                        },
                        {
                            "Id": "OSLO",
                            "Name": "Oslo",
                            "SingleAirportCity": false,
                            "CountryId": "NO",
                            "Location": "10.738921, 59.913248",
                            "IataCode": "OSL",
                            "Airports": [
                                {
                                    "Id": "TRF",
                                    "Name": "Oslo Torp",
                                    "CityId": "OSLO",
                                    "CountryId": "NO",
                                    "Location": "10.266667, 59.183333"
                                },
                                {
                                    "Id": "OSL",
                                    "Name": "Oslo Gardermoen",
                                    "CityId": "OSLO",
                                    "CountryId": "NO",
                                    "Location": "11.083333, 60.2"
                                },
                                {
                                    "Id": "RYG",
                                    "Name": "Oslo Rygge",
                                    "CityId": "OSLO",
                                    "CountryId": "NO",
                                    "Location": "10.7856, 59.3789"
                                }
                            ]
                        },
                        {
                            "Id": "BARD",
                            "Name": "Bardufoss",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "18.591614, 69.039598",
                            "IataCode": "BDU",
                            "Airports": [
                                {
                                    "Id": "BDU",
                                    "Name": "Bardufoss",
                                    "CityId": "BARD",
                                    "CountryId": "NO",
                                    "Location": "18.54, 69.056111"
                                }
                            ]
                        },
                        {
                            "Id": "BERI",
                            "Name": "Bergen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "5.3242812, 60.393163",
                            "IataCode": "BGO",
                            "Airports": [
                                {
                                    "Id": "BGO",
                                    "Name": "Bergen",
                                    "CityId": "BERI",
                                    "CountryId": "NO",
                                    "Location": "5.219444, 60.293611"
                                }
                            ]
                        },
                        {
                            "Id": "BATS",
                            "Name": "Batsfjord",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "29.699722, 70.632222",
                            "IataCode": "BJF",
                            "Airports": [
                                {
                                    "Id": "BJF",
                                    "Name": "Batsfjord",
                                    "CityId": "BATS",
                                    "CountryId": "NO",
                                    "Location": "29.666667, 70.6"
                                }
                            ]
                        },
                        {
                            "Id": "BRON",
                            "Name": "Bronnoysund Bronnoy",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "12.216667, 65.466667",
                            "IataCode": "BNN",
                            "Airports": [
                                {
                                    "Id": "BNN",
                                    "Name": "Bronnoysund Bronnoy",
                                    "CityId": "BRON",
                                    "CountryId": "NO",
                                    "Location": "12.206944, 65.460278"
                                }
                            ]
                        },
                        {
                            "Id": "BODO",
                            "Name": "Bodo",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "14.383333, 67.283333",
                            "IataCode": "BOO",
                            "Airports": [
                                {
                                    "Id": "BOO",
                                    "Name": "Bodo",
                                    "CityId": "BODO",
                                    "CountryId": "NO",
                                    "Location": "14.372222, 67.269167"
                                }
                            ]
                        },
                        {
                            "Id": "BERM",
                            "Name": "Berlevag",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "29.1, 70.85",
                            "IataCode": "BVG",
                            "Airports": [
                                {
                                    "Id": "BVG",
                                    "Name": "Berlevag",
                                    "CityId": "BERM",
                                    "CountryId": "NO",
                                    "Location": "29, 70.866667"
                                }
                            ]
                        },
                        {
                            "Id": "EVEN",
                            "Name": "Evenes",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "16.995163, 68.526852",
                            "IataCode": "EVE",
                            "Airports": [
                                {
                                    "Id": "EVE",
                                    "Name": "Evenes",
                                    "CityId": "EVEN",
                                    "CountryId": "NO",
                                    "Location": "16.679722, 68.496667"
                                }
                            ]
                        },
                        {
                            "Id": "FARS",
                            "Name": "Farsund Lista",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "6.8018247, 58.094755",
                            "IataCode": "FAN",
                            "Airports": [
                                {
                                    "Id": "FAN",
                                    "Name": "Farsund Lista",
                                    "CityId": "FARS",
                                    "CountryId": "NO",
                                    "Location": "6.625556, 58.101667"
                                }
                            ]
                        },
                        {
                            "Id": "FLOT",
                            "Name": "Floro",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "4.9999998, 61.6",
                            "IataCode": "FRO",
                            "Airports": [
                                {
                                    "Id": "FRO",
                                    "Name": "Floro",
                                    "CityId": "FLOT",
                                    "CountryId": "NO",
                                    "Location": "5.014444, 61.581944"
                                }
                            ]
                        },
                        {
                            "Id": "HASV",
                            "Name": "Hasvik",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "22.15, 70.483333",
                            "IataCode": "HAA",
                            "Airports": [
                                {
                                    "Id": "HAA",
                                    "Name": "Hasvik",
                                    "CityId": "HASV",
                                    "CountryId": "NO",
                                    "Location": "22.15, 70.466667"
                                }
                            ]
                        },
                        {
                            "Id": "HAUG",
                            "Name": "Haugesund",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "5.2774997, 59.411944",
                            "IataCode": "HAU",
                            "Airports": [
                                {
                                    "Id": "HAU",
                                    "Name": "Haugesund",
                                    "CityId": "HAUG",
                                    "CountryId": "NO",
                                    "Location": "5.279167, 59.405556"
                                }
                            ]
                        },
                        {
                            "Id": "HAMM",
                            "Name": "Hammerfest",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "23.681931, 70.663394",
                            "IataCode": "HFT",
                            "Airports": [
                                {
                                    "Id": "HFT",
                                    "Name": "Hammerfest",
                                    "CityId": "HAMM",
                                    "CountryId": "NO",
                                    "Location": "23.668333, 70.679444"
                                }
                            ]
                        },
                        {
                            "Id": "HAMA",
                            "Name": "Hamar Stavsberg",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "11.1, 60.8",
                            "IataCode": "HMR",
                            "Airports": [
                                {
                                    "Id": "HMR",
                                    "Name": "Hamar Stavsberg",
                                    "CityId": "HAMA",
                                    "CountryId": "NO",
                                    "Location": "11.116667, 60.8"
                                }
                            ]
                        },
                        {
                            "Id": "HONN",
                            "Name": "Honningsvag Valan",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "25.98258, 70.97663",
                            "IataCode": "HVG",
                            "Airports": [
                                {
                                    "Id": "HVG",
                                    "Name": "Honningsvag Valan",
                                    "CityId": "HONN",
                                    "CountryId": "NO",
                                    "Location": "25.983611, 71.009722"
                                }
                            ]
                        },
                        {
                            "Id": "KRSA",
                            "Name": "Kristiansand",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "7.9995063, 58.147383",
                            "IataCode": "KRS",
                            "Airports": [
                                {
                                    "Id": "KRS",
                                    "Name": "Kristiansand Kjevik",
                                    "CityId": "KRSA",
                                    "CountryId": "NO",
                                    "Location": "8.079071, 58.199661"
                                }
                            ]
                        },
                        {
                            "Id": "KRIT",
                            "Name": "Kristiansund",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "7.7128317, 63.120498",
                            "IataCode": "KSU",
                            "Airports": [
                                {
                                    "Id": "KSU",
                                    "Name": "Kristiansund",
                                    "CityId": "KRIT",
                                    "CountryId": "NO",
                                    "Location": "7.844444, 63.114722"
                                }
                            ]
                        },
                        {
                            "Id": "KIRK",
                            "Name": "Kirkenes Høybuktmoen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "30.051667, 69.725",
                            "IataCode": "KKN",
                            "Airports": [
                                {
                                    "Id": "KKN",
                                    "Name": "Kirkenes Høybuktmoen",
                                    "CityId": "KIRK",
                                    "CountryId": "NO",
                                    "Location": "29.893333, 69.722778"
                                }
                            ]
                        },
                        {
                            "Id": "LAKE",
                            "Name": "Lakselv",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "24.933334, 70.05",
                            "IataCode": "LKL",
                            "Airports": [
                                {
                                    "Id": "LKL",
                                    "Name": "Lakselv",
                                    "CityId": "LAKE",
                                    "CountryId": "NO",
                                    "Location": "24.973611, 70.067778"
                                }
                            ]
                        },
                        {
                            "Id": "MEHA",
                            "Name": "Mehamn",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "27.85, 71.033333",
                            "IataCode": "MEH",
                            "Airports": [
                                {
                                    "Id": "MEH",
                                    "Name": "Mehamn",
                                    "CityId": "MEHA",
                                    "CountryId": "NO",
                                    "Location": "27.833333, 71.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MOSJ",
                            "Name": "Mosjoen Kjaerstad",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "13.2, 65.833333",
                            "IataCode": "MJF",
                            "Airports": [
                                {
                                    "Id": "MJF",
                                    "Name": "Mosjoen Kjaerstad",
                                    "CityId": "MOSJ",
                                    "CountryId": "NO",
                                    "Location": "13.214914, 65.783997"
                                }
                            ]
                        },
                        {
                            "Id": "MOLD",
                            "Name": "Molde Aro",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "7.1833336, 62.733333",
                            "IataCode": "MOL",
                            "Airports": [
                                {
                                    "Id": "MOL",
                                    "Name": "Molde Aro",
                                    "CityId": "MOLD",
                                    "CountryId": "NO",
                                    "Location": "7.271111, 62.748611"
                                }
                            ]
                        },
                        {
                            "Id": "NOTO",
                            "Name": "Notodden",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "9.2833333, 59.566667",
                            "IataCode": "NTB",
                            "Airports": [
                                {
                                    "Id": "NTB",
                                    "Name": "Notodden",
                                    "CityId": "NOTO",
                                    "CountryId": "NO",
                                    "Location": "9.283333, 59.583333"
                                }
                            ]
                        },
                        {
                            "Id": "NARV",
                            "Name": "Narvik Framnes",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "17.437222, 68.435556",
                            "IataCode": "NVK",
                            "Airports": [
                                {
                                    "Id": "NVK",
                                    "Name": "Narvik Framnes",
                                    "CityId": "NARV",
                                    "CountryId": "NO",
                                    "Location": "17.425, 68.425"
                                }
                            ]
                        },
                        {
                            "Id": "NAMS",
                            "Name": "Namsos",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "11.5, 64.483333",
                            "IataCode": "OSY",
                            "Airports": [
                                {
                                    "Id": "OSY",
                                    "Name": "Namsos",
                                    "CityId": "NAMS",
                                    "CountryId": "NO",
                                    "Location": "11.6, 64.466667"
                                }
                            ]
                        },
                        {
                            "Id": "RORO",
                            "Name": "Roros",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "11.4, 62.583333",
                            "IataCode": "RRS",
                            "Airports": [
                                {
                                    "Id": "RRS",
                                    "Name": "Roros",
                                    "CityId": "RORO",
                                    "CountryId": "NO",
                                    "Location": "11.345556, 62.579167"
                                }
                            ]
                        },
                        {
                            "Id": "STOK",
                            "Name": "Stokmarknes",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "14.908611, 68.561944",
                            "IataCode": "SKN",
                            "Airports": [
                                {
                                    "Id": "SKN",
                                    "Name": "Stokmarknes",
                                    "CityId": "STOK",
                                    "CountryId": "NO",
                                    "Location": "15.027222, 68.581111"
                                }
                            ]
                        },
                        {
                            "Id": "STAV",
                            "Name": "Stavanger",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "5.7499998, 58.966668",
                            "IataCode": "SVG",
                            "Airports": [
                                {
                                    "Id": "SVG",
                                    "Name": "Stavanger",
                                    "CityId": "STAV",
                                    "CountryId": "NO",
                                    "Location": "5.634722, 58.879722"
                                }
                            ]
                        },
                        {
                            "Id": "SORK",
                            "Name": "Sorkjosen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "20.933333, 69.8",
                            "IataCode": "SOJ",
                            "Airports": [
                                {
                                    "Id": "SOJ",
                                    "Name": "Sorkjosen",
                                    "CityId": "SORK",
                                    "CountryId": "NO",
                                    "Location": "20.933333, 69.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SANE",
                            "Name": "Sandnessjoen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "12.633334, 66.016667",
                            "IataCode": "SSJ",
                            "Airports": [
                                {
                                    "Id": "SSJ",
                                    "Name": "Sandnessjoen",
                                    "CityId": "SANE",
                                    "CountryId": "NO",
                                    "Location": "12.471389, 65.958333"
                                }
                            ]
                        },
                        {
                            "Id": "TROM",
                            "Name": "Tromso",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "18.966667, 69.666668",
                            "IataCode": "TOS",
                            "Airports": [
                                {
                                    "Id": "TOS",
                                    "Name": "Tromso",
                                    "CityId": "TROM",
                                    "CountryId": "NO",
                                    "Location": "18.921667, 69.681389"
                                }
                            ]
                        },
                        {
                            "Id": "TRON",
                            "Name": "Trondheim",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "10.394976, 63.430528",
                            "IataCode": "TRD",
                            "Airports": [
                                {
                                    "Id": "TRD",
                                    "Name": "Trondheim",
                                    "CityId": "TRON",
                                    "CountryId": "NO",
                                    "Location": "10.933333, 63.45"
                                }
                            ]
                        },
                        {
                            "Id": "VARD",
                            "Name": "Vardoe Lufthaun",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "31.1, 70.366667",
                            "IataCode": "VAW",
                            "Airports": [
                                {
                                    "Id": "VAW",
                                    "Name": "Vardoe Lufthaun",
                                    "CityId": "VARD",
                                    "CountryId": "NO",
                                    "Location": "31.045556, 70.354722"
                                }
                            ]
                        },
                        {
                            "Id": "FAGE",
                            "Name": "Fagernes Valdres",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "9.25, 60.983333",
                            "IataCode": "VDB",
                            "Airports": [
                                {
                                    "Id": "VDB",
                                    "Name": "Fagernes Valdres",
                                    "CityId": "FAGE",
                                    "CountryId": "NO",
                                    "Location": "9.288056, 61.015556"
                                }
                            ]
                        },
                        {
                            "Id": "VADS",
                            "Name": "Vadso",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "29.769722, 70.073056",
                            "IataCode": "VDS",
                            "Airports": [
                                {
                                    "Id": "VDS",
                                    "Name": "Vadso",
                                    "CityId": "VADS",
                                    "CountryId": "NO",
                                    "Location": "29.845278, 70.065"
                                }
                            ]
                        },
                        {
                            "Id": "GEIM",
                            "Name": "Geilo",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "8.2000001, 60.516667",
                            "IataCode": "DLD",
                            "Airports": [
                                {
                                    "Id": "DLD",
                                    "Name": "Geilo",
                                    "CityId": "GEIM",
                                    "CountryId": "NO",
                                    "Location": "8.483333, 60.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LYRL",
                            "Name": "Longyearbyen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "15.633333, 78.216667",
                            "IataCode": "LYR",
                            "Airports": [
                                {
                                    "Id": "LYR",
                                    "Name": "Longyearbyen",
                                    "CityId": "LYRL",
                                    "CountryId": "NO",
                                    "Location": "15.465556, 78.246111"
                                }
                            ]
                        },
                        {
                            "Id": "ANDE",
                            "Name": "Andenes",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "16.127929, 69.318805",
                            "IataCode": "ANX",
                            "Airports": [
                                {
                                    "Id": "ANX",
                                    "Name": "Andenes",
                                    "CityId": "ANDE",
                                    "CountryId": "NO",
                                    "Location": "16.141667, 69.293056"
                                }
                            ]
                        },
                        {
                            "Id": "FORD",
                            "Name": "Forde",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "5.843353, 61.451732",
                            "IataCode": "FDE",
                            "Airports": [
                                {
                                    "Id": "FDE",
                                    "Name": "Forde Bringeland",
                                    "CityId": "FORD",
                                    "CountryId": "NO",
                                    "Location": "5.759411, 61.392363"
                                }
                            ]
                        },
                        {
                            "Id": "ROER",
                            "Name": "Roervik Ryumsjoen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "11.233333, 64.85",
                            "IataCode": "ZXF",
                            "Airports": [
                                {
                                    "Id": "RVK",
                                    "Name": "Roervik Ryumsjoen",
                                    "CityId": "ROER",
                                    "CountryId": "NO",
                                    "Location": "11.145629, 64.837262"
                                }
                            ]
                        },
                        {
                            "Id": "SAND",
                            "Name": "Sandane",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "6.216667, 61.766667",
                            "IataCode": "SDN",
                            "Airports": [
                                {
                                    "Id": "SDN",
                                    "Name": "Sandane",
                                    "CityId": "SAND",
                                    "CountryId": "NO",
                                    "Location": "6.108913, 61.830146"
                                }
                            ]
                        },
                        {
                            "Id": "ROSU",
                            "Name": "Rost",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "12.116667, 67.516667",
                            "IataCode": "RET",
                            "Airports": [
                                {
                                    "Id": "RET",
                                    "Name": "Rost",
                                    "CityId": "ROSU",
                                    "CountryId": "NO",
                                    "Location": "12.103333, 67.527778"
                                }
                            ]
                        },
                        {
                            "Id": "ORLA",
                            "Name": "Orland",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "9.5674802, 63.689465",
                            "IataCode": "OLA",
                            "Airports": [
                                {
                                    "Id": "OLA",
                                    "Name": "Orland",
                                    "CityId": "ORLA",
                                    "CountryId": "NO",
                                    "Location": "9.616667, 63.7"
                                }
                            ]
                        },
                        {
                            "Id": "MOIR",
                            "Name": "Mo I Rana Rossvoll",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "14.512697, 66.410647",
                            "IataCode": "MQN",
                            "Airports": [
                                {
                                    "Id": "MQN",
                                    "Name": "Mo I Rana Rossvoll",
                                    "CityId": "MOIR",
                                    "CountryId": "NO",
                                    "Location": "14.3, 66.366667"
                                }
                            ]
                        },
                        {
                            "Id": "SKIE",
                            "Name": "Skien Geiterygen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "9.4797727, 59.2537",
                            "IataCode": "SKE",
                            "Airports": [
                                {
                                    "Id": "SKE",
                                    "Name": "Skien Geiterygen",
                                    "CityId": "SKIE",
                                    "CountryId": "NO",
                                    "Location": "9.5625, 59.182778"
                                }
                            ]
                        },
                        {
                            "Id": "VAER",
                            "Name": "Vaeroy Stolport",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "12.65, 67.666667",
                            "IataCode": "VRY",
                            "Airports": [
                                {
                                    "Id": "VRY",
                                    "Name": "Vaeroy Stolport",
                                    "CityId": "VAER",
                                    "CountryId": "NO",
                                    "Location": "12.683333, 67.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SOGN",
                            "Name": "Sogndal Haukasen",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "7.1000002, 61.233333",
                            "IataCode": "SOG",
                            "Airports": [
                                {
                                    "Id": "SOG",
                                    "Name": "Sogndal Haukasen",
                                    "CityId": "SOGN",
                                    "CountryId": "NO",
                                    "Location": "7.136944, 61.157778"
                                }
                            ]
                        },
                        {
                            "Id": "ORST",
                            "Name": "Orsta / Volda Hovden",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "6.1296847, 62.20339",
                            "IataCode": "HOV",
                            "Airports": [
                                {
                                    "Id": "HOV",
                                    "Name": "Orsta / Volda Hovden",
                                    "CityId": "ORST",
                                    "CountryId": "NO",
                                    "Location": "6.078333, 62.181111"
                                }
                            ]
                        },
                        {
                            "Id": "LEKN",
                            "Name": "Leknes",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "13.613547, 68.145814",
                            "IataCode": "LKN",
                            "Airports": [
                                {
                                    "Id": "LKN",
                                    "Name": "Leknes",
                                    "CityId": "LEKN",
                                    "CountryId": "NO",
                                    "Location": "13.608889, 68.153611"
                                }
                            ]
                        },
                        {
                            "Id": "STOS",
                            "Name": "Stord",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "5.4992312, 59.780115",
                            "IataCode": "SRP",
                            "Airports": [
                                {
                                    "Id": "SRP",
                                    "Name": "Stord",
                                    "CityId": "STOS",
                                    "CountryId": "NO",
                                    "Location": "5.34085, 59.791925"
                                }
                            ]
                        },
                        {
                            "Id": "AALE",
                            "Name": "Aalesund Vigra",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "6.0265589, 62.536434",
                            "IataCode": "AES",
                            "Airports": [
                                {
                                    "Id": "AES",
                                    "Name": "Aalesund Vigra",
                                    "CityId": "AALE",
                                    "CountryId": "NO",
                                    "Location": "6.105278, 62.5625"
                                }
                            ]
                        },
                        {
                            "Id": "SYGA",
                            "Name": "Svalbard",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "18.272891, 78.877323",
                            "IataCode": "SYG",
                            "Airports": [
                                {
                                    "Id": "SYG",
                                    "Name": "Svalbard",
                                    "CityId": "SYGA",
                                    "CountryId": "NO",
                                    "Location": "15.469444, 78.236944"
                                }
                            ]
                        },
                        {
                            "Id": "GOLK",
                            "Name": "Gol",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "8.95, 60.7",
                            "IataCode": "GLL",
                            "Airports": [
                                {
                                    "Id": "GLL",
                                    "Name": "Gol Klanten",
                                    "CityId": "GOLK",
                                    "CountryId": "NO",
                                    "Location": "8.95, 60.7"
                                }
                            ]
                        },
                        {
                            "Id": "HARS",
                            "Name": "Tofta",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "16.5, 68.8",
                            "IataCode": "HRD",
                            "Airports": [
                                {
                                    "Id": "HRD",
                                    "Name": "Harstad Evenes",
                                    "CityId": "HARS",
                                    "CountryId": "NO",
                                    "Location": "16.5, 68.8"
                                }
                            ]
                        },
                        {
                            "Id": "OSLP",
                            "Name": "Pompierre",
                            "SingleAirportCity": true,
                            "CountryId": "NO",
                            "Location": "2.9166667, 48.65",
                            "IataCode": "FBU",
                            "Airports": [
                                {
                                    "Id": "FBU",
                                    "Name": "Oslo Fornebu Sea Plane Base",
                                    "CityId": "OSLP",
                                    "CountryId": "NO",
                                    "Location": "10.631332, 59.899786"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AD",
                    "Name": "Andorra",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "ALVA",
                            "Name": "Andorra La Vella",
                            "SingleAirportCity": true,
                            "CountryId": "AD",
                            "Location": "1.5150619, 42.505151",
                            "IataCode": "ALV",
                            "Airports": [
                                {
                                    "Id": "ALV",
                                    "Name": "Andorra La Vella",
                                    "CityId": "ALVA",
                                    "CountryId": "AD",
                                    "Location": "1.516667, 42.5"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LV",
                    "Name": "Latvia",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "LIEP",
                            "Name": "Liepaya",
                            "SingleAirportCity": true,
                            "CountryId": "LV",
                            "Location": "21.016667, 56.516667",
                            "IataCode": "LPX",
                            "Airports": [
                                {
                                    "Id": "LPX",
                                    "Name": "Liepaya",
                                    "CityId": "LIEP",
                                    "CountryId": "LV",
                                    "Location": "21.1, 56.516667"
                                }
                            ]
                        },
                        {
                            "Id": "DAUG",
                            "Name": "Daugavpils",
                            "SingleAirportCity": true,
                            "CountryId": "LV",
                            "Location": "26.533334, 55.883333",
                            "IataCode": "DGP",
                            "Airports": [
                                {
                                    "Id": "DGP",
                                    "Name": "Daugavpils",
                                    "CityId": "DAUG",
                                    "CountryId": "LV",
                                    "Location": "26.533333, 55.883333"
                                }
                            ]
                        },
                        {
                            "Id": "RIGA",
                            "Name": "Riga",
                            "SingleAirportCity": true,
                            "CountryId": "LV",
                            "Location": "24.122233, 56.978072",
                            "IataCode": "RIX",
                            "Airports": [
                                {
                                    "Id": "RIX",
                                    "Name": "Riga International",
                                    "CityId": "RIGA",
                                    "CountryId": "LV",
                                    "Location": "23.972222, 56.925"
                                }
                            ]
                        },
                        {
                            "Id": "VNTA",
                            "Name": "Ventspils",
                            "SingleAirportCity": true,
                            "CountryId": "LV",
                            "Location": "21.560556, 57.389444",
                            "IataCode": "VNT",
                            "Airports": [
                                {
                                    "Id": "VNT",
                                    "Name": "Ventspils",
                                    "CityId": "VNTA",
                                    "CountryId": "LV",
                                    "Location": "21.5442, 57.3578"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "FO",
                    "Name": "Faroe Islands",
                    "CurrencyId": "DKK",
                    "Cities": [
                        {
                            "Id": "SORV",
                            "Name": "Sorvagur",
                            "SingleAirportCity": true,
                            "CountryId": "FO",
                            "Location": "-7.3000002, 62.066667",
                            "IataCode": "FAE",
                            "Airports": [
                                {
                                    "Id": "FAE",
                                    "Name": "Sorvagur",
                                    "CityId": "SORV",
                                    "CountryId": "FO",
                                    "Location": "-7.266667, 62.066667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BE",
                    "Name": "Belgium",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "ANTW",
                            "Name": "Antwerp",
                            "SingleAirportCity": true,
                            "CountryId": "BE",
                            "Location": "4.4166666, 51.216668",
                            "IataCode": "ANR",
                            "Airports": [
                                {
                                    "Id": "ANR",
                                    "Name": "Antwerp Deurne",
                                    "CityId": "ANTW",
                                    "CountryId": "BE",
                                    "Location": "4.46, 51.19"
                                }
                            ]
                        },
                        {
                            "Id": "BRUS",
                            "Name": "Brussels",
                            "SingleAirportCity": false,
                            "CountryId": "BE",
                            "Location": "4.3592416, 50.838425",
                            "IataCode": "BRU",
                            "Airports": [
                                {
                                    "Id": "BRU",
                                    "Name": "Brussels International",
                                    "CityId": "BRUS",
                                    "CountryId": "BE",
                                    "Location": "4.4855529, 50.900985"
                                },
                                {
                                    "Id": "CRL",
                                    "Name": "Brussels S. Charleroi",
                                    "CityId": "BRUS",
                                    "CountryId": "BE",
                                    "Location": "4.453817, 50.459197"
                                }
                            ]
                        },
                        {
                            "Id": "GHEN",
                            "Name": "Ghent",
                            "SingleAirportCity": true,
                            "CountryId": "BE",
                            "Location": "3.7166664, 51.05",
                            "IataCode": "GNE",
                            "Airports": [
                                {
                                    "Id": "GNE",
                                    "Name": "Ghent",
                                    "CityId": "GHEN",
                                    "CountryId": "BE",
                                    "Location": "3.716667, 51.05"
                                }
                            ]
                        },
                        {
                            "Id": "KJKA",
                            "Name": "Kortrijk",
                            "SingleAirportCity": true,
                            "CountryId": "BE",
                            "Location": "3.2648706, 50.828034",
                            "IataCode": "KJK",
                            "Airports": [
                                {
                                    "Id": "KJK",
                                    "Name": "Kortrijk",
                                    "CityId": "KJKA",
                                    "CountryId": "BE",
                                    "Location": "3.201667, 50.817222"
                                }
                            ]
                        },
                        {
                            "Id": "LIEG",
                            "Name": "Liege",
                            "SingleAirportCity": false,
                            "CountryId": "BE",
                            "Location": "5.5717842, 50.641188",
                            "IataCode": "LGG",
                            "Airports": [
                                {
                                    "Id": "LGG",
                                    "Name": "Liege",
                                    "CityId": "LIEG",
                                    "CountryId": "BE",
                                    "Location": "5.441667, 50.636111"
                                },
                                {
                                    "Id": "XHN",
                                    "Name": "Liege Guillemins Railway Station",
                                    "CityId": "LIEG",
                                    "CountryId": "BE",
                                    "Location": "5.573158, 50.640317"
                                }
                            ]
                        },
                        {
                            "Id": "OBLA",
                            "Name": "Zoersel",
                            "SingleAirportCity": true,
                            "CountryId": "BE",
                            "Location": "4.6999999, 51.266667",
                            "IataCode": "OBL",
                            "Airports": [
                                {
                                    "Id": "OBL",
                                    "Name": "Zoersel",
                                    "CityId": "OBLA",
                                    "CountryId": "BE",
                                    "Location": "4.75, 51.266667"
                                }
                            ]
                        },
                        {
                            "Id": "OSTA",
                            "Name": "Ostend",
                            "SingleAirportCity": true,
                            "CountryId": "BE",
                            "Location": "2.9166667, 51.216668",
                            "IataCode": "OST",
                            "Airports": [
                                {
                                    "Id": "OST",
                                    "Name": "Ostend - Bruges",
                                    "CityId": "OSTA",
                                    "CountryId": "BE",
                                    "Location": "2.8636, 51.1997"
                                }
                            ]
                        },
                        {
                            "Id": "MWWA",
                            "Name": "Mouscron",
                            "SingleAirportCity": false,
                            "CountryId": "BE",
                            "Location": "3.2166667, 50.733333",
                            "Airports": []
                        }
                    ]
                },
                {
                    "Id": "BG",
                    "Name": "Bulgaria",
                    "CurrencyId": "BGN",
                    "Cities": [
                        {
                            "Id": "HASK",
                            "Name": "Haskovo",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "25.569445, 41.940278",
                            "IataCode": "HKV",
                            "Airports": [
                                {
                                    "Id": "HKV",
                                    "Name": "Haskovo",
                                    "CityId": "HASK",
                                    "CountryId": "BG",
                                    "Location": "25.55, 41.933333"
                                }
                            ]
                        },
                        {
                            "Id": "JAMB",
                            "Name": "Jambol",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "26.5, 42.483333",
                            "IataCode": "JAM",
                            "Airports": [
                                {
                                    "Id": "JAM",
                                    "Name": "Jambol",
                                    "CityId": "JAMB",
                                    "CountryId": "BG",
                                    "Location": "26.483333, 42.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BURG",
                            "Name": "Bourgas",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "27.466667, 42.500001",
                            "IataCode": "BOJ",
                            "Airports": [
                                {
                                    "Id": "BOJ",
                                    "Name": "Bourgas",
                                    "CityId": "BURG",
                                    "CountryId": "BG",
                                    "Location": "27.5152, 42.5696"
                                }
                            ]
                        },
                        {
                            "Id": "KARD",
                            "Name": "Kardjali",
                            "SingleAirportCity": false,
                            "CountryId": "BG",
                            "Location": "25.366667, 41.65",
                            "IataCode": "KDR",
                            "Airports": [
                                {
                                    "Id": "KDR",
                                    "Name": "Kardjali",
                                    "CityId": "KARD",
                                    "CountryId": "BG",
                                    "Location": "25.3339, 41.6172"
                                },
                                {
                                    "Id": "KDG",
                                    "Name": "Kardjali",
                                    "CityId": "KARD",
                                    "CountryId": "BG",
                                    "Location": "25.3333, 41.6167"
                                }
                            ]
                        },
                        {
                            "Id": "PLOV",
                            "Name": "Plovdiv",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "24.75, 42.15",
                            "IataCode": "PDV",
                            "Airports": [
                                {
                                    "Id": "PDV",
                                    "Name": "Plovdiv",
                                    "CityId": "PLOV",
                                    "CountryId": "BG",
                                    "Location": "24.850833, 42.067778"
                                }
                            ]
                        },
                        {
                            "Id": "ROUS",
                            "Name": "Rousse",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "25.970833, 43.856389",
                            "IataCode": "ROU",
                            "Airports": [
                                {
                                    "Id": "ROU",
                                    "Name": "Rousse",
                                    "CityId": "ROUS",
                                    "CountryId": "BG",
                                    "Location": "26.05, 43.683333"
                                }
                            ]
                        },
                        {
                            "Id": "SILI",
                            "Name": "Silistra",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "27.266667, 44.116667",
                            "IataCode": "SLS",
                            "Airports": [
                                {
                                    "Id": "SLS",
                                    "Name": "Silistra",
                                    "CityId": "SILI",
                                    "CountryId": "BG",
                                    "Location": "27.180556, 44.056389"
                                }
                            ]
                        },
                        {
                            "Id": "SOFI",
                            "Name": "Sofia",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "23.320307, 42.695543",
                            "IataCode": "SOF",
                            "Airports": [
                                {
                                    "Id": "SOF",
                                    "Name": "Sofia",
                                    "CityId": "SOFI",
                                    "CountryId": "BG",
                                    "Location": "23.406932, 42.693412"
                                }
                            ]
                        },
                        {
                            "Id": "STAR",
                            "Name": "Stara Zagora",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "25.641945, 42.432779",
                            "IataCode": "SZR",
                            "Airports": [
                                {
                                    "Id": "SZR",
                                    "Name": "Stara Zagora",
                                    "CityId": "STAR",
                                    "CountryId": "BG",
                                    "Location": "25.616667, 42.433333"
                                }
                            ]
                        },
                        {
                            "Id": "VARN",
                            "Name": "Varna",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "27.916667, 43.216667",
                            "IataCode": "VAR",
                            "Airports": [
                                {
                                    "Id": "VAR",
                                    "Name": "Varna",
                                    "CityId": "VARN",
                                    "CountryId": "BG",
                                    "Location": "27.825556, 43.2325"
                                }
                            ]
                        },
                        {
                            "Id": "VIDI",
                            "Name": "Vidin",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "22.8725, 43.99",
                            "IataCode": "VID",
                            "Airports": [
                                {
                                    "Id": "VID",
                                    "Name": "Vidin",
                                    "CityId": "VIDI",
                                    "CountryId": "BG",
                                    "Location": "22.816667, 43.983333"
                                }
                            ]
                        },
                        {
                            "Id": "PLEV",
                            "Name": "Pleven",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "24.016667, 41.2",
                            "IataCode": "PVN",
                            "Airports": [
                                {
                                    "Id": "PVN",
                                    "Name": "Pleven",
                                    "CityId": "PLEV",
                                    "CountryId": "BG",
                                    "Location": "24.616667, 43.416667"
                                }
                            ]
                        },
                        {
                            "Id": "TARG",
                            "Name": "Targovishte",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "26.589167, 43.259167",
                            "IataCode": "TGV",
                            "Airports": [
                                {
                                    "Id": "TGV",
                                    "Name": "Targovishte",
                                    "CityId": "TARG",
                                    "CountryId": "BG",
                                    "Location": "24.5, 42.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GORN",
                            "Name": "Gorna Orechovitsa",
                            "SingleAirportCity": true,
                            "CountryId": "BG",
                            "Location": "25.701667, 43.127778",
                            "IataCode": "GOZ",
                            "Airports": [
                                {
                                    "Id": "GOZ",
                                    "Name": "Gorna Orechovitsa",
                                    "CityId": "GORN",
                                    "CountryId": "BG",
                                    "Location": "25.666667, 43.116667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CH",
                    "Name": "Switzerland",
                    "CurrencyId": "CHF",
                    "Cities": [
                        {
                            "Id": "ZURI",
                            "Name": "Zurich",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "8.5500001, 47.366667",
                            "IataCode": "ZRH",
                            "Airports": [
                                {
                                    "Id": "ZRH",
                                    "Name": "Zurich",
                                    "CityId": "ZURI",
                                    "CountryId": "CH",
                                    "Location": "8.5534547, 47.461213"
                                }
                            ]
                        },
                        {
                            "Id": "BERN",
                            "Name": "Bern",
                            "SingleAirportCity": false,
                            "CountryId": "CH",
                            "Location": "7.4474435, 46.948094",
                            "IataCode": "BRN",
                            "Airports": [
                                {
                                    "Id": "BRN",
                                    "Name": "Bern",
                                    "CityId": "BERN",
                                    "CountryId": "CH",
                                    "Location": "7.499167, 46.915278"
                                },
                                {
                                    "Id": "ZDJ",
                                    "Name": "Berne Rr Station",
                                    "CityId": "BERN",
                                    "CountryId": "CH",
                                    "Location": "7.5, 46.9139"
                                }
                            ]
                        },
                        {
                            "Id": "LUGA",
                            "Name": "Lugano",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "8.9600372, 46.010078",
                            "IataCode": "LUG",
                            "Airports": [
                                {
                                    "Id": "LUG",
                                    "Name": "Lugano",
                                    "CityId": "LUGA",
                                    "CountryId": "CH",
                                    "Location": "8.908882, 46.002685"
                                }
                            ]
                        },
                        {
                            "Id": "SION",
                            "Name": "Sion",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "7.3500001, 46.233333",
                            "IataCode": "SIR",
                            "Airports": [
                                {
                                    "Id": "SIR",
                                    "Name": "Sion",
                                    "CityId": "SION",
                                    "CountryId": "CH",
                                    "Location": "7.333333, 46.25"
                                }
                            ]
                        },
                        {
                            "Id": "STMA",
                            "Name": "St. Moritz Samedan",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "9.8333333, 46.5",
                            "IataCode": "SMV",
                            "Airports": [
                                {
                                    "Id": "SMV",
                                    "Name": "St. Moritz Samedan",
                                    "CityId": "STMA",
                                    "CountryId": "CH",
                                    "Location": "9.885556, 46.534722"
                                }
                            ]
                        },
                        {
                            "Id": "BASE",
                            "Name": "Basel",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "7.6000003, 47.566667",
                            "IataCode": "EAP",
                            "Airports": [
                                {
                                    "Id": "BSL",
                                    "Name": "Basel Mulhouse Freiburg",
                                    "CityId": "BASE",
                                    "CountryId": "CH",
                                    "Location": "7.5253568, 47.598157"
                                }
                            ]
                        },
                        {
                            "Id": "GENE",
                            "Name": "Geneva",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "6.1456925, 46.202216",
                            "IataCode": "GVA",
                            "Airports": [
                                {
                                    "Id": "GVA",
                                    "Name": "Geneva",
                                    "CityId": "GENE",
                                    "CountryId": "CH",
                                    "Location": "6.1089386, 46.236957"
                                }
                            ]
                        },
                        {
                            "Id": "BXOA",
                            "Name": "Buochs",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "8.4166667, 46.966667",
                            "IataCode": "BXO",
                            "Airports": [
                                {
                                    "Id": "BXO",
                                    "Name": "Buochs",
                                    "CityId": "BXOA",
                                    "CountryId": "CH",
                                    "Location": "8.379993, 46.971585"
                                }
                            ]
                        },
                        {
                            "Id": "ASCO",
                            "Name": "Ascona",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "8.7732697, 46.154508",
                            "IataCode": "ACO",
                            "Airports": [
                                {
                                    "Id": "ACO",
                                    "Name": "Ascona",
                                    "CityId": "ASCO",
                                    "CountryId": "CH",
                                    "Location": "8.783056, 46.159444"
                                }
                            ]
                        },
                        {
                            "Id": "EMLA",
                            "Name": "Emmen",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "8.3000001, 47.083333",
                            "IataCode": "EML",
                            "Airports": [
                                {
                                    "Id": "EML",
                                    "Name": "Emmen",
                                    "CityId": "EMLA",
                                    "CountryId": "CH",
                                    "Location": "-8.304722, 47.092222"
                                }
                            ]
                        },
                        {
                            "Id": "ALTF",
                            "Name": "Altenrhein",
                            "SingleAirportCity": true,
                            "CountryId": "CH",
                            "Location": "9.55, 47.483333",
                            "IataCode": "ACH",
                            "Airports": [
                                {
                                    "Id": "ACH",
                                    "Name": "St Gallen Altenrhein",
                                    "CityId": "ALTF",
                                    "CountryId": "CH",
                                    "Location": "9.5667, 47.4833"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UA",
                    "Name": "Ukraine",
                    "CurrencyId": "UAH",
                    "Cities": [
                        {
                            "Id": "CERU",
                            "Name": "Cherkassy",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "32.066667, 49.433336",
                            "IataCode": "CKC",
                            "Airports": [
                                {
                                    "Id": "CKC",
                                    "Name": "Cherkassy",
                                    "CityId": "CERU",
                                    "CountryId": "UA",
                                    "Location": "32, 49.416667"
                                }
                            ]
                        },
                        {
                            "Id": "ZHIT",
                            "Name": "Zhitomir",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "28.666666, 50.250003",
                            "IataCode": "ZTR",
                            "Airports": [
                                {
                                    "Id": "ZTR",
                                    "Name": "Zhitomir",
                                    "CityId": "ZHIT",
                                    "CountryId": "UA",
                                    "Location": "28.65, 50.25"
                                }
                            ]
                        },
                        {
                            "Id": "KRAN",
                            "Name": "Kramatorsk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "37.533333, 48.716668",
                            "IataCode": "KRQ",
                            "Airports": [
                                {
                                    "Id": "KRQ",
                                    "Name": "Kramatorsk",
                                    "CityId": "KRAN",
                                    "CountryId": "UA",
                                    "Location": "37.616667, 48.716667"
                                }
                            ]
                        },
                        {
                            "Id": "ROVN",
                            "Name": "Rovno",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "26.25, 50.616669",
                            "IataCode": "RWN",
                            "Airports": [
                                {
                                    "Id": "RWN",
                                    "Name": "Rovno",
                                    "CityId": "ROVN",
                                    "CountryId": "UA",
                                    "Location": "26.15, 50.6"
                                }
                            ]
                        },
                        {
                            "Id": "CERW",
                            "Name": "Chernivtsi",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "25.933334, 48.3",
                            "IataCode": "CWC",
                            "Airports": [
                                {
                                    "Id": "CWC",
                                    "Name": "Chernivtsi",
                                    "CityId": "CERW",
                                    "CountryId": "UA",
                                    "Location": "25.966667, 48.266667"
                                }
                            ]
                        },
                        {
                            "Id": "DNEP",
                            "Name": "Dnipro",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "34.983333, 48.450002",
                            "IataCode": "DNK",
                            "Airports": [
                                {
                                    "Id": "DNK",
                                    "Name": "Dnipro",
                                    "CityId": "DNEP",
                                    "CountryId": "UA",
                                    "Location": "35.100556, 48.357222"
                                }
                            ]
                        },
                        {
                            "Id": "DONF",
                            "Name": "Donetsk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "37.8, 48.000002",
                            "IataCode": "DOK",
                            "Airports": [
                                {
                                    "Id": "DOK",
                                    "Name": "Donetsk",
                                    "CityId": "DONF",
                                    "CountryId": "UA",
                                    "Location": "37.75, 48.083333"
                                }
                            ]
                        },
                        {
                            "Id": "BERD",
                            "Name": "Berdiansk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "36.794445, 46.750279",
                            "IataCode": "ERD",
                            "Airports": [
                                {
                                    "Id": "ERD",
                                    "Name": "Berdiansk",
                                    "CityId": "BERD",
                                    "CountryId": "UA",
                                    "Location": "36.783333, 46.733333"
                                }
                            ]
                        },
                        {
                            "Id": "KHAR",
                            "Name": "Kharkiv",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "36.250001, 50.000005",
                            "IataCode": "HRK",
                            "Airports": [
                                {
                                    "Id": "HRK",
                                    "Name": "Kharkiv",
                                    "CityId": "KHAR",
                                    "CountryId": "UA",
                                    "Location": "36.283333, 49.933333"
                                }
                            ]
                        },
                        {
                            "Id": "KIEV",
                            "Name": "Kyiv",
                            "SingleAirportCity": false,
                            "CountryId": "UA",
                            "Location": "30.538892, 50.424645",
                            "IataCode": "IEV",
                            "Airports": [
                                {
                                    "Id": "KBP",
                                    "Name": "Kiev Borispol",
                                    "CityId": "KIEV",
                                    "CountryId": "UA",
                                    "Location": "30.9167, 50.35"
                                },
                                {
                                    "Id": "IEV",
                                    "Name": "Kiev Zhulhany",
                                    "CityId": "KIEV",
                                    "CountryId": "UA",
                                    "Location": "30.4508, 50.4019"
                                }
                            ]
                        },
                        {
                            "Id": "IVAN",
                            "Name": "Ivano-Frankivsk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "24.707223, 48.917222",
                            "IataCode": "IFO",
                            "Airports": [
                                {
                                    "Id": "IFO",
                                    "Name": "Ivano-Frankivsk",
                                    "CityId": "IVAN",
                                    "CountryId": "UA",
                                    "Location": "24.683333, 48.883333"
                                }
                            ]
                        },
                        {
                            "Id": "KIRO",
                            "Name": "Kirovograd",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "32.263056, 48.504167",
                            "IataCode": "KGO",
                            "Airports": [
                                {
                                    "Id": "KGO",
                                    "Name": "Kirovograd",
                                    "CityId": "KIRO",
                                    "CountryId": "UA",
                                    "Location": "32.3, 48.55"
                                }
                            ]
                        },
                        {
                            "Id": "KHER",
                            "Name": "Kherson",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "32.6, 46.633333",
                            "IataCode": "KHE",
                            "Airports": [
                                {
                                    "Id": "KHE",
                                    "Name": "Kherson",
                                    "CityId": "KHER",
                                    "CountryId": "UA",
                                    "Location": "32.627778, 46.675278"
                                }
                            ]
                        },
                        {
                            "Id": "KRIV",
                            "Name": "Krivoy Rog",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "33.35, 47.91667",
                            "IataCode": "KWG",
                            "Airports": [
                                {
                                    "Id": "KWG",
                                    "Name": "Krivoy Rog",
                                    "CityId": "KRIV",
                                    "CountryId": "UA",
                                    "Location": "33.216667, 48.05"
                                }
                            ]
                        },
                        {
                            "Id": "LVOV",
                            "Name": "Lviv",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "24.023238, 49.838259",
                            "IataCode": "LWO",
                            "Airports": [
                                {
                                    "Id": "LWO",
                                    "Name": "Lviv",
                                    "CityId": "LVOV",
                                    "CountryId": "UA",
                                    "Location": "23.956111, 49.8125"
                                }
                            ]
                        },
                        {
                            "Id": "MARL",
                            "Name": "Mariupol",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "37.500001, 47.066667",
                            "IataCode": "MPW",
                            "Airports": [
                                {
                                    "Id": "MPW",
                                    "Name": "Mariupol",
                                    "CityId": "MARL",
                                    "CountryId": "UA",
                                    "Location": "37.55, 47.1"
                                }
                            ]
                        },
                        {
                            "Id": "NIKO",
                            "Name": "Mykolaiv",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "32, 46.966669",
                            "IataCode": "NLV",
                            "Airports": [
                                {
                                    "Id": "NLV",
                                    "Name": "Mykolaiv",
                                    "CityId": "NIKO",
                                    "CountryId": "UA",
                                    "Location": "31.916667, 47.05"
                                }
                            ]
                        },
                        {
                            "Id": "ODES",
                            "Name": "Odesa",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "30.732622, 46.477475",
                            "IataCode": "ODS",
                            "Airports": [
                                {
                                    "Id": "ODS",
                                    "Name": "Odesa Central",
                                    "CityId": "ODES",
                                    "CountryId": "UA",
                                    "Location": "30.683333, 46.433333"
                                }
                            ]
                        },
                        {
                            "Id": "LUGB",
                            "Name": "Luhansk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "39.333333, 48.566667",
                            "IataCode": "VSG",
                            "Airports": [
                                {
                                    "Id": "VSG",
                                    "Name": "Luhansk",
                                    "CityId": "LUGB",
                                    "CountryId": "UA",
                                    "Location": "39.374125, 48.417381"
                                }
                            ]
                        },
                        {
                            "Id": "UKSA",
                            "Name": "Sevastopol",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "33.533333, 44.6",
                            "IataCode": "UKS",
                            "Airports": [
                                {
                                    "Id": "UKS",
                                    "Name": "Sevastopol",
                                    "CityId": "UKSA",
                                    "CountryId": "UA",
                                    "Location": "33.576667, 44.691667"
                                }
                            ]
                        },
                        {
                            "Id": "VINN",
                            "Name": "Vinnytsia",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "28.483333, 49.233334",
                            "IataCode": "VIN",
                            "Airports": [
                                {
                                    "Id": "VIN",
                                    "Name": "Vinnytsia",
                                    "CityId": "VINN",
                                    "CountryId": "UA",
                                    "Location": "28.483333, 49.233333"
                                }
                            ]
                        },
                        {
                            "Id": "CERS",
                            "Name": "Chernihiv",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "31.3, 51.5",
                            "IataCode": "CEJ",
                            "Airports": [
                                {
                                    "Id": "CEJ",
                                    "Name": "Chernihiv",
                                    "CityId": "CERS",
                                    "CountryId": "UA",
                                    "Location": "31.3, 51.5"
                                }
                            ]
                        },
                        {
                            "Id": "KREM",
                            "Name": "Kremenchug",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "33.416667, 49.066667",
                            "IataCode": "KHU",
                            "Airports": [
                                {
                                    "Id": "KHU",
                                    "Name": "Kremenchug",
                                    "CityId": "KREM",
                                    "CountryId": "UA",
                                    "Location": "33.45, 49.1"
                                }
                            ]
                        },
                        {
                            "Id": "MIRG",
                            "Name": "Mirgorod",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "33.6, 49.966667",
                            "IataCode": "MXR",
                            "Airports": [
                                {
                                    "Id": "MXR",
                                    "Name": "Mirgorod",
                                    "CityId": "MIRG",
                                    "CountryId": "UA",
                                    "Location": "33.6, 49.966667"
                                }
                            ]
                        },
                        {
                            "Id": "POLT",
                            "Name": "Poltava",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "34.566666, 49.583333",
                            "IataCode": "PLV",
                            "Airports": [
                                {
                                    "Id": "PLV",
                                    "Name": "Poltava",
                                    "CityId": "POLT",
                                    "CountryId": "UA",
                                    "Location": "34.533333, 49.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SEVE",
                            "Name": "Severodoneck",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "38.502222, 48.945557",
                            "IataCode": "SEV",
                            "Airports": [
                                {
                                    "Id": "SEV",
                                    "Name": "Severodoneck",
                                    "CityId": "SEVE",
                                    "CountryId": "UA",
                                    "Location": "38.55, 48.9"
                                }
                            ]
                        },
                        {
                            "Id": "LUTS",
                            "Name": "Lutsk",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "25.333333, 50.750002",
                            "IataCode": "UCK",
                            "Airports": [
                                {
                                    "Id": "UCK",
                                    "Name": "Lutsk",
                                    "CityId": "LUTS",
                                    "CountryId": "UA",
                                    "Location": "25.333333, 50.75"
                                }
                            ]
                        },
                        {
                            "Id": "UZHG",
                            "Name": "Uzhgorod",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "22.3, 48.616667",
                            "IataCode": "UDJ",
                            "Airports": [
                                {
                                    "Id": "UDJ",
                                    "Name": "Uzhgorod",
                                    "CityId": "UZHG",
                                    "CountryId": "UA",
                                    "Location": "22.283333, 48.616667"
                                }
                            ]
                        },
                        {
                            "Id": "SUMY",
                            "Name": "Sumy",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "34.781944, 50.919725",
                            "IataCode": "UMY",
                            "Airports": [
                                {
                                    "Id": "UMY",
                                    "Name": "Sumy",
                                    "CityId": "SUMY",
                                    "CountryId": "UA",
                                    "Location": "34.783333, 50.933333"
                                }
                            ]
                        },
                        {
                            "Id": "KAME",
                            "Name": "Kamenets-Podolskiy",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "26.566667, 48.666667",
                            "IataCode": "KCP",
                            "Airports": [
                                {
                                    "Id": "KCP",
                                    "Name": "Kamenets-Podolskiy",
                                    "CityId": "KAME",
                                    "CountryId": "UA",
                                    "Location": "26.616667, 48.683333"
                                }
                            ]
                        },
                        {
                            "Id": "ZAPO",
                            "Name": "Zaporizhia",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "35.183333, 47.816667",
                            "IataCode": "OZH",
                            "Airports": [
                                {
                                    "Id": "OZH",
                                    "Name": "Zaporizhia",
                                    "CityId": "ZAPO",
                                    "CountryId": "UA",
                                    "Location": "35.315833, 47.8675"
                                }
                            ]
                        },
                        {
                            "Id": "TERN",
                            "Name": "Ternopol",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "25.583334, 49.55",
                            "IataCode": "TNL",
                            "Airports": [
                                {
                                    "Id": "TNL",
                                    "Name": "Ternopol",
                                    "CityId": "TERN",
                                    "CountryId": "UA",
                                    "Location": "25.6, 49.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KHME",
                            "Name": "Khmelnitskiy",
                            "SingleAirportCity": true,
                            "CountryId": "UA",
                            "Location": "27, 49.416668",
                            "IataCode": "HMJ",
                            "Airports": [
                                {
                                    "Id": "HMJ",
                                    "Name": "Khmelnitskiy",
                                    "CityId": "KHME",
                                    "CountryId": "UA",
                                    "Location": "27, 49.416667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "FR",
                    "Name": "France",
                    "CurrencyId": "EUR",
                    "LanguageId": "FR",
                    "Regions": [
                        {
                            "Id": "AQUIT",
                            "Name": "Aquitaine",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "AUVER",
                            "Name": "Auvergne",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "BASSE",
                            "Name": "Lower Normandy",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "CENTR",
                            "Name": "Centre",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "CORSE",
                            "Name": "Corsica",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "HAUTE",
                            "Name": "Upper Normandy",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "ILE_D",
                            "Name": "Île-de-France",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "LANGU",
                            "Name": "Languedoc-Roussillon",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "LIMOU",
                            "Name": "Limousin",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "LORRA",
                            "Name": "Lorraine",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "MIDI_",
                            "Name": "Midi-Pyrénées",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "NORD_",
                            "Name": "Nord-Pas-de-Calais",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "PAYS_",
                            "Name": "Pays de la Loire",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "POITO",
                            "Name": "Poitou-Charentes",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "PROVE",
                            "Name": "Provence-Alpes-Côte d'Azur",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "RHONE",
                            "Name": "Rhône-Alpes",
                            "CountryId": "FR"
                        },
                        {
                            "Id": "ALSAC",
                            "Name": "Alsace",
                            "CountryId": "FR"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "XCRA",
                            "Name": "Châlons-en-Champagne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "4.3666665, 48.95",
                            "IataCode": "XCR",
                            "Airports": [
                                {
                                    "Id": "XCR",
                                    "Name": "Châlons-en-Champagne",
                                    "CityId": "XCRA",
                                    "CountryId": "FR",
                                    "Location": "4.2011, 48.7678"
                                }
                            ]
                        },
                        {
                            "Id": "AGEN",
                            "Name": "Agen",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "0.63333341, 44.2",
                            "IataCode": "AGF",
                            "Airports": [
                                {
                                    "Id": "AGF",
                                    "Name": "Agen",
                                    "CityId": "AGEN",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "0.598611, 44.173611"
                                }
                            ]
                        },
                        {
                            "Id": "AJAC",
                            "Name": "Ajaccio",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "8.7333333, 41.916667",
                            "IataCode": "AJA",
                            "Airports": [
                                {
                                    "Id": "AJA",
                                    "Name": "Ajaccio",
                                    "CityId": "AJAC",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "8.803056, 41.924167"
                                }
                            ]
                        },
                        {
                            "Id": "ANGE",
                            "Name": "Angers",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-0.55000015, 47.466668",
                            "IataCode": "ANE",
                            "Airports": [
                                {
                                    "Id": "ANE",
                                    "Name": "Angers",
                                    "CityId": "ANGE",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-0.31268, 47.56254"
                                }
                            ]
                        },
                        {
                            "Id": "CAHO",
                            "Name": "Cahors Lalbenque",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "1.4366342, 44.4491",
                            "IataCode": "ZAO",
                            "Airports": [
                                {
                                    "Id": "ZAO",
                                    "Name": "Cahors Lalbenque",
                                    "CityId": "CAHO",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "1.45, 44.45"
                                }
                            ]
                        },
                        {
                            "Id": "AIXE",
                            "Name": "Aix en Provence",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "5.4497327, 43.528295",
                            "IataCode": "QXB",
                            "Airports": [
                                {
                                    "Id": "QXB",
                                    "Name": "Aix en Provence",
                                    "CityId": "AIXE",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "5.36778, 43.50555"
                                }
                            ]
                        },
                        {
                            "Id": "ALPE",
                            "Name": "Alpe D'Huez",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.0666668, 45.1",
                            "IataCode": "AHZ",
                            "Airports": [
                                {
                                    "Id": "AHZ",
                                    "Name": "Alpe D'Huez",
                                    "CityId": "ALPE",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.083333, 45.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CREI",
                            "Name": "Creil",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "2.4833333, 49.266667",
                            "IataCode": "CSF",
                            "Airports": [
                                {
                                    "Id": "CSF",
                                    "Name": "Creil",
                                    "CityId": "CREI",
                                    "CountryId": "FR",
                                    "Location": "2.51914, 49.25355"
                                }
                            ]
                        },
                        {
                            "Id": "LECA",
                            "Name": "Le Castellet",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "5.7833332, 43.2",
                            "IataCode": "CTT",
                            "Airports": [
                                {
                                    "Id": "CTT",
                                    "Name": "Le Castellet",
                                    "CityId": "LECA",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "5.783333, 43.25"
                                }
                            ]
                        },
                        {
                            "Id": "PROP",
                            "Name": "Propriano",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "8.9, 41.666667",
                            "IataCode": "PRP",
                            "Airports": [
                                {
                                    "Id": "PRP",
                                    "Name": "Propriano",
                                    "CityId": "PROP",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "8.9, 41.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BRES",
                            "Name": "Brest",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-4.4833331, 48.400002",
                            "IataCode": "BES",
                            "Airports": [
                                {
                                    "Id": "BES",
                                    "Name": "Brest",
                                    "CityId": "BRES",
                                    "CountryId": "FR",
                                    "Location": "-4.420556, 48.447222"
                                }
                            ]
                        },
                        {
                            "Id": "BAST",
                            "Name": "Bastia - Corsica",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "9.45, 42.702778",
                            "IataCode": "BIA",
                            "Airports": [
                                {
                                    "Id": "BIA",
                                    "Name": "Bastia - Corsica",
                                    "CityId": "BAST",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "9.486111, 42.549167"
                                }
                            ]
                        },
                        {
                            "Id": "BIAR",
                            "Name": "Biarritz",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "-1.5666668, 43.483333",
                            "IataCode": "BIQ",
                            "Airports": [
                                {
                                    "Id": "BIQ",
                                    "Name": "Biarritz",
                                    "CityId": "BIAR",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "-1.522778, 43.469722"
                                }
                            ]
                        },
                        {
                            "Id": "BORD",
                            "Name": "Bordeaux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "-0.56666755, 44.833333",
                            "IataCode": "BOD",
                            "Airports": [
                                {
                                    "Id": "BOD",
                                    "Name": "Bordeaux",
                                    "CityId": "BORD",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "-0.7129053, 44.829461"
                                }
                            ]
                        },
                        {
                            "Id": "BELG",
                            "Name": "Belfort",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "6.866667, 47.633333",
                            "IataCode": "BOR",
                            "Airports": [
                                {
                                    "Id": "BOR",
                                    "Name": "Belfort",
                                    "CityId": "BELG",
                                    "CountryId": "FR",
                                    "Location": "7.011667, 47.655556"
                                }
                            ]
                        },
                        {
                            "Id": "BOUS",
                            "Name": "Bourgas",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CENTR",
                            "Location": "2.4000001, 47.083333",
                            "IataCode": "BOU",
                            "Airports": [
                                {
                                    "Id": "BOU",
                                    "Name": "Bourgas",
                                    "CityId": "BOUS",
                                    "CountryId": "FR",
                                    "RegionId": "CENTR",
                                    "Location": "2.370278, 47.058056"
                                }
                            ]
                        },
                        {
                            "Id": "BEXI",
                            "Name": "Beziers",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "3.2499999, 43.35",
                            "IataCode": "BZR",
                            "Airports": [
                                {
                                    "Id": "BZR",
                                    "Name": "Beziers",
                                    "CityId": "BEXI",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "3.354444, 43.324167"
                                }
                            ]
                        },
                        {
                            "Id": "CARC",
                            "Name": "Carcassonne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "2.3500001, 43.216667",
                            "IataCode": "CCF",
                            "Airports": [
                                {
                                    "Id": "CCF",
                                    "Name": "Carcassonne",
                                    "CityId": "CARC",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "2.3167, 43.2167"
                                }
                            ]
                        },
                        {
                            "Id": "PARI",
                            "Name": "Paris",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "ILE_D",
                            "Location": "2.342876, 48.856622",
                            "IataCode": "PAR",
                            "Airports": [
                                {
                                    "Id": "LBG",
                                    "Name": "Paris Le Bourget",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.4425, 48.971944"
                                },
                                {
                                    "Id": "ORY",
                                    "Name": "Paris Orly",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.357222, 48.728889"
                                },
                                {
                                    "Id": "CDG",
                                    "Name": "Paris Charles de Gaulle",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.5481698, 49.009618"
                                },
                                {
                                    "Id": "BVA",
                                    "Name": "Paris Beauvais",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.1125, 49.455833"
                                },
                                {
                                    "Id": "VIY",
                                    "Name": "Villacoublay-Velizy",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.1917, 48.7742"
                                },
                                {
                                    "Id": "JPU",
                                    "Name": "Paris La Defense",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.333333, 48.866667"
                                },
                                {
                                    "Id": "XPG",
                                    "Name": "Paris Gare du Nord Rail Station",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.355323, 48.880931"
                                },
                                {
                                    "Id": "POX",
                                    "Name": "Paris Cergy Pontoise",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.040833, 49.096647"
                                },
                                {
                                    "Id": "JDP",
                                    "Name": "Paris Heliport De Paris",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.283333, 48.816667"
                                },
                                {
                                    "Id": "XTT",
                                    "Name": "Paris Etoile",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.350556, 48.850556"
                                },
                                {
                                    "Id": "XED",
                                    "Name": "Paris Disneyland Resort Rail Station",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.7818, 48.868719"
                                },
                                {
                                    "Id": "XHP",
                                    "Name": "Paris Gare de l'Est",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.350556, 48.883889"
                                },
                                {
                                    "Id": "XEX",
                                    "Name": "Paris Aerogare des Invalides",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.65, 48.833333"
                                },
                                {
                                    "Id": "XGB",
                                    "Name": "Paris Gare Montparnasse",
                                    "CityId": "PARI",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.565278, 49.016667"
                                }
                            ]
                        },
                        {
                            "Id": "CANN",
                            "Name": "Cannes",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "7.0166671, 43.55",
                            "IataCode": "CEQ",
                            "Airports": [
                                {
                                    "Id": "CEQ",
                                    "Name": "Cannes Mandelieu",
                                    "CityId": "CANN",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.953611, 43.541944"
                                },
                                {
                                    "Id": "JCA",
                                    "Name": "Cannes Croisette",
                                    "CityId": "CANN",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "7.016988, 43.545102"
                                }
                            ]
                        },
                        {
                            "Id": "CERT",
                            "Name": "Cherbourg",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "BASSE",
                            "Location": "-1.6163636, 49.639844",
                            "IataCode": "CER",
                            "Airports": [
                                {
                                    "Id": "CER",
                                    "Name": "Cherbourg",
                                    "CityId": "CERT",
                                    "CountryId": "FR",
                                    "RegionId": "BASSE",
                                    "Location": "-1.465278, 49.651111"
                                }
                            ]
                        },
                        {
                            "Id": "CAEN",
                            "Name": "Caen",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "BASSE",
                            "Location": "-0.37121794, 49.181088",
                            "IataCode": "CFR",
                            "Airports": [
                                {
                                    "Id": "CFR",
                                    "Name": "Caen",
                                    "CityId": "CAEN",
                                    "CountryId": "FR",
                                    "RegionId": "BASSE",
                                    "Location": "-0.448056, 49.173333"
                                }
                            ]
                        },
                        {
                            "Id": "CALV",
                            "Name": "Calvi",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "8.75, 42.566667",
                            "IataCode": "CLY",
                            "Airports": [
                                {
                                    "Id": "CLY",
                                    "Name": "Calvi",
                                    "CityId": "CALV",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "8.791667, 42.527778"
                                }
                            ]
                        },
                        {
                            "Id": "CHAM",
                            "Name": "Chambéry-le-Vieux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "5.8999999, 45.6",
                            "IataCode": "CMF",
                            "Airports": [
                                {
                                    "Id": "CMF",
                                    "Name": "Chambery",
                                    "CityId": "CHAM",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "5.879745, 45.639968"
                                }
                            ]
                        },
                        {
                            "Id": "COGN",
                            "Name": "Cognac",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "-0.33333345, 45.7",
                            "IataCode": "CNG",
                            "Airports": [
                                {
                                    "Id": "CNG",
                                    "Name": "Cognac",
                                    "CityId": "COGN",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "-0.333333, 45.683333"
                                }
                            ]
                        },
                        {
                            "Id": "COUR",
                            "Name": "Courchevel",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.6500001, 45.416667",
                            "IataCode": "CVF",
                            "Airports": [
                                {
                                    "Id": "CVF",
                                    "Name": "Courchevel",
                                    "CityId": "COUR",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.634722, 45.396667"
                                }
                            ]
                        },
                        {
                            "Id": "CASU",
                            "Name": "Castres Mazamet",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "2.2500001, 43.6",
                            "IataCode": "DCM",
                            "Airports": [
                                {
                                    "Id": "DCM",
                                    "Name": "Castres Mazamet",
                                    "CityId": "CASU",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "2.284167, 43.555833"
                                }
                            ]
                        },
                        {
                            "Id": "DEAU",
                            "Name": "Deauville St. Gatien",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "BASSE",
                            "Location": "0.069952148, 49.356998",
                            "IataCode": "DOL",
                            "Airports": [
                                {
                                    "Id": "DOL",
                                    "Name": "Deauville St. Gatien",
                                    "CityId": "DEAU",
                                    "CountryId": "FR",
                                    "RegionId": "BASSE",
                                    "Location": "0.164167, 49.362778"
                                }
                            ]
                        },
                        {
                            "Id": "DIEP",
                            "Name": "Dieppe",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "HAUTE",
                            "Location": "1.0833334, 49.933333",
                            "IataCode": "DPE",
                            "Airports": [
                                {
                                    "Id": "DPE",
                                    "Name": "Dieppe",
                                    "CityId": "DIEP",
                                    "CountryId": "FR",
                                    "RegionId": "HAUTE",
                                    "Location": "1.241111, 49.961389"
                                }
                            ]
                        },
                        {
                            "Id": "BERG",
                            "Name": "Bergerac",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "0.48333338, 44.85",
                            "IataCode": "EGC",
                            "Airports": [
                                {
                                    "Id": "EGC",
                                    "Name": "Bergerac",
                                    "CityId": "BERG",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "0.518611, 44.825279"
                                }
                            ]
                        },
                        {
                            "Id": "ENCA",
                            "Name": "Nancy",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LORRA",
                            "Location": "6.2000002, 48.683334",
                            "IataCode": "ENC",
                            "Airports": [
                                {
                                    "Id": "ENC",
                                    "Name": "Nancy",
                                    "CityId": "ENCA",
                                    "CountryId": "FR",
                                    "RegionId": "LORRA",
                                    "Location": "6.231667, 48.691667"
                                }
                            ]
                        },
                        {
                            "Id": "EVRE",
                            "Name": "Evreux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "HAUTE",
                            "Location": "1.1500003, 49.016667",
                            "IataCode": "EVX",
                            "Airports": [
                                {
                                    "Id": "EVX",
                                    "Name": "Evreux",
                                    "CityId": "EVRE",
                                    "CountryId": "FR",
                                    "RegionId": "HAUTE",
                                    "Location": "1.21986, 49.02867"
                                }
                            ]
                        },
                        {
                            "Id": "NIME",
                            "Name": "Nimes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "4.3499994, 43.833333",
                            "IataCode": "FNI",
                            "Airports": [
                                {
                                    "Id": "FNI",
                                    "Name": "Nimes",
                                    "CityId": "NIME",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "4.416667, 43.75"
                                }
                            ]
                        },
                        {
                            "Id": "FIGA",
                            "Name": "Figari",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "9.1295, 41.4882",
                            "IataCode": "FSC",
                            "Airports": [
                                {
                                    "Id": "FSC",
                                    "Name": "Figari Sud Corse",
                                    "CityId": "FIGA",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "9.098396, 41.503178"
                                }
                            ]
                        },
                        {
                            "Id": "GRAP",
                            "Name": "Granville",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "BASSE",
                            "Location": "-1.5971375, 48.837916",
                            "IataCode": "GFR",
                            "Airports": [
                                {
                                    "Id": "GFR",
                                    "Name": "Granville",
                                    "CityId": "GRAP",
                                    "CountryId": "FR",
                                    "RegionId": "BASSE",
                                    "Location": "-1.566667, 48.883333"
                                }
                            ]
                        },
                        {
                            "Id": "GREN",
                            "Name": "Grenoble",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "5.7166663, 45.166667",
                            "IataCode": "GNB",
                            "Airports": [
                                {
                                    "Id": "GNB",
                                    "Name": "Grenoble",
                                    "CityId": "GREN",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "5.329722, 45.362778"
                                }
                            ]
                        },
                        {
                            "Id": "AUBA",
                            "Name": "Aubagne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "5.5666665, 43.283333",
                            "IataCode": "JAH",
                            "Airports": [
                                {
                                    "Id": "JAH",
                                    "Name": "Aubagne Agora Hlpd",
                                    "CityId": "AUBA",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "5.6, 43.283333"
                                }
                            ]
                        },
                        {
                            "Id": "JUAN",
                            "Name": "Juan Les Pins",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "7.1000001, 43.566667",
                            "IataCode": "JLP",
                            "Airports": [
                                {
                                    "Id": "JLP",
                                    "Name": "Juan Les Pins",
                                    "CityId": "JUAN",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "7.1, 43.65"
                                }
                            ]
                        },
                        {
                            "Id": "LANN",
                            "Name": "Lannion",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-3.4666667, 48.733333",
                            "IataCode": "LAI",
                            "Airports": [
                                {
                                    "Id": "LAI",
                                    "Name": "Lannion",
                                    "CityId": "LANN",
                                    "CountryId": "FR",
                                    "Location": "-3.475285, 48.755114"
                                }
                            ]
                        },
                        {
                            "Id": "LANE",
                            "Name": "Landivisiau",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-4.0666666, 48.516667",
                            "IataCode": "LDV",
                            "Airports": [
                                {
                                    "Id": "LDV",
                                    "Name": "Landivisiau",
                                    "CityId": "LANE",
                                    "CountryId": "FR",
                                    "Location": "-4.151389, 48.53"
                                }
                            ]
                        },
                        {
                            "Id": "LEHA",
                            "Name": "Le Havre Octeville",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "HAUTE",
                            "Location": "0.10767541, 49.493803",
                            "IataCode": "LEH",
                            "Airports": [
                                {
                                    "Id": "LEH",
                                    "Name": "Le Havre Octeville",
                                    "CityId": "LEHA",
                                    "CountryId": "FR",
                                    "RegionId": "HAUTE",
                                    "Location": "0.088611, 49.538889"
                                }
                            ]
                        },
                        {
                            "Id": "LIMO",
                            "Name": "Limoges",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LIMOU",
                            "Location": "1.2500007, 45.85",
                            "IataCode": "LIG",
                            "Airports": [
                                {
                                    "Id": "LIG",
                                    "Name": "Limoges",
                                    "CityId": "LIMO",
                                    "CountryId": "FR",
                                    "RegionId": "LIMOU",
                                    "Location": "1.182222, 45.861944"
                                }
                            ]
                        },
                        {
                            "Id": "LILL",
                            "Name": "Lille",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "NORD_",
                            "Location": "3.0666667, 50.633335",
                            "IataCode": "LIL",
                            "Airports": [
                                {
                                    "Id": "LIL",
                                    "Name": "Lille",
                                    "CityId": "LILL",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "3.088056, 50.564444"
                                },
                                {
                                    "Id": "XDB",
                                    "Name": "Lille International Rail Station",
                                    "CityId": "LILL",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "3.05833, 50.6325"
                                }
                            ]
                        },
                        {
                            "Id": "LEMA",
                            "Name": "Le Mans",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "0.19957395, 47.988079",
                            "IataCode": "LME",
                            "Airports": [
                                {
                                    "Id": "LME",
                                    "Name": "Le Mans Arnage",
                                    "CityId": "LEMA",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "0.201667, 47.948611"
                                }
                            ]
                        },
                        {
                            "Id": "LARO",
                            "Name": "La Rochelle",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "-1.1500002, 46.166667",
                            "IataCode": "LRH",
                            "Airports": [
                                {
                                    "Id": "LRH",
                                    "Name": "La Rochelle",
                                    "CityId": "LARO",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "-1.185833, 46.180556"
                                }
                            ]
                        },
                        {
                            "Id": "LORI",
                            "Name": "Lorient",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-3.3666666, 47.75",
                            "IataCode": "LRT",
                            "Airports": [
                                {
                                    "Id": "LRT",
                                    "Name": "Lorient",
                                    "CityId": "LORI",
                                    "CountryId": "FR",
                                    "Location": "-3.439167, 47.761111"
                                }
                            ]
                        },
                        {
                            "Id": "LETO",
                            "Name": "Le Touquet Paris Plag",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "NORD_",
                            "Location": "1.5833334, 50.516667",
                            "IataCode": "LTQ",
                            "Airports": [
                                {
                                    "Id": "LTQ",
                                    "Name": "Le Touquet Paris Plag",
                                    "CityId": "LETO",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "1.628056, 50.515"
                                }
                            ]
                        },
                        {
                            "Id": "LAVA",
                            "Name": "Laval",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-0.76666697, 48.066667",
                            "IataCode": "LVA",
                            "Airports": [
                                {
                                    "Id": "LVA",
                                    "Name": "Laval",
                                    "CityId": "LAVA",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-0.766667, 48.066667"
                                }
                            ]
                        },
                        {
                            "Id": "LYON",
                            "Name": "Lyon",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "4.8499998, 45.750002",
                            "IataCode": "LYS",
                            "Airports": [
                                {
                                    "Id": "LYN",
                                    "Name": "Lyon Bron",
                                    "CityId": "LYON",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "4.943333, 45.730833"
                                },
                                {
                                    "Id": "LYS",
                                    "Name": "Lyon",
                                    "CityId": "LYON",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "5.081667, 45.725556"
                                }
                            ]
                        },
                        {
                            "Id": "NICE",
                            "Name": "Nice",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "7.2379042, 43.712236",
                            "IataCode": "NCE",
                            "Airports": [
                                {
                                    "Id": "NCE",
                                    "Name": "Nice",
                                    "CityId": "NICE",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "7.215359, 43.659654"
                                }
                            ]
                        },
                        {
                            "Id": "ANNE",
                            "Name": "Annecy",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.116667, 45.9",
                            "IataCode": "NCY",
                            "Airports": [
                                {
                                    "Id": "NCY",
                                    "Name": "Annecy",
                                    "CityId": "ANNE",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.100833, 45.930556"
                                }
                            ]
                        },
                        {
                            "Id": "NANT",
                            "Name": "Nantes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-1.553364, 47.217253",
                            "IataCode": "NTE",
                            "Airports": [
                                {
                                    "Id": "NTE",
                                    "Name": "Nantes",
                                    "CityId": "NANT",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-1.609722, 47.158333"
                                }
                            ]
                        },
                        {
                            "Id": "ORLE",
                            "Name": "Orleans",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CENTR",
                            "Location": "1.9000001, 47.916668",
                            "IataCode": "ORE",
                            "Airports": [
                                {
                                    "Id": "ORE",
                                    "Name": "Orleans",
                                    "CityId": "ORLE",
                                    "CountryId": "FR",
                                    "RegionId": "CENTR",
                                    "Location": "1.9, 47.916667"
                                }
                            ]
                        },
                        {
                            "Id": "PERI",
                            "Name": "Perigueux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "0.71666681, 45.183333",
                            "IataCode": "PGX",
                            "Airports": [
                                {
                                    "Id": "PGX",
                                    "Name": "Perigueux",
                                    "CityId": "PERI",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "0.815392, 45.197462"
                                }
                            ]
                        },
                        {
                            "Id": "POIT",
                            "Name": "Poitiers",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "0.33333373, 46.583333",
                            "IataCode": "PIS",
                            "Airports": [
                                {
                                    "Id": "PIS",
                                    "Name": "Poitiers",
                                    "CityId": "POIT",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "0.306389, 46.586111"
                                }
                            ]
                        },
                        {
                            "Id": "TROY",
                            "Name": "Troyes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "4.0833331, 48.3",
                            "Airports": [
                                {
                                    "Id": "QYR",
                                    "Name": "Troyes – Barberey Airport",
                                    "CityId": "TROY",
                                    "CountryId": "FR",
                                    "Location": "4.016667, 48.321667"
                                }
                            ]
                        },
                        {
                            "Id": "RODE",
                            "Name": "Rodez",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "2.5776672, 44.354235",
                            "IataCode": "RDZ",
                            "Airports": [
                                {
                                    "Id": "RDZ",
                                    "Name": "Rodez",
                                    "CityId": "RODE",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "2.482672, 44.407869"
                                }
                            ]
                        },
                        {
                            "Id": "RENN",
                            "Name": "Rennes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-1.6833337, 48.083333",
                            "IataCode": "RNS",
                            "Airports": [
                                {
                                    "Id": "RNS",
                                    "Name": "Rennes",
                                    "CityId": "RENN",
                                    "CountryId": "FR",
                                    "Location": "-1.731389, 48.068611"
                                }
                            ]
                        },
                        {
                            "Id": "ROYA",
                            "Name": "Royan",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "-1.0333334, 45.633333",
                            "IataCode": "RYN",
                            "Airports": [
                                {
                                    "Id": "RYN",
                                    "Name": "Royan Medis",
                                    "CityId": "ROYA",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "-0.976, 45.631"
                                }
                            ]
                        },
                        {
                            "Id": "STRA",
                            "Name": "Strasbourg",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ALSAC",
                            "Location": "7.7500007, 48.583333",
                            "IataCode": "SXB",
                            "Airports": [
                                {
                                    "Id": "SXB",
                                    "Name": "Strasbourg",
                                    "CityId": "STRA",
                                    "CountryId": "FR",
                                    "RegionId": "ALSAC",
                                    "Location": "7.633333, 48.533333"
                                }
                            ]
                        },
                        {
                            "Id": "TOUS",
                            "Name": "Toulouse",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "1.4436726, 43.604262",
                            "IataCode": "TLS",
                            "Airports": [
                                {
                                    "Id": "XYT",
                                    "Name": "Toulouse Montaudran",
                                    "CityId": "TOUS",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "1.483333, 43.566667"
                                },
                                {
                                    "Id": "TLS",
                                    "Name": "Toulouse",
                                    "CityId": "TOUS",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "1.3678937, 43.629333"
                                }
                            ]
                        },
                        {
                            "Id": "TOUT",
                            "Name": "Toussus-Le-Noble",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ILE_D",
                            "Location": "2.1166667, 48.75",
                            "IataCode": "TNF",
                            "Airports": [
                                {
                                    "Id": "TNF",
                                    "Name": "Toussus-Le-Noble",
                                    "CityId": "TOUT",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.113056, 48.750833"
                                }
                            ]
                        },
                        {
                            "Id": "TOUR",
                            "Name": "Tours",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CENTR",
                            "Location": "0.68333361, 47.383335",
                            "IataCode": "TUF",
                            "Airports": [
                                {
                                    "Id": "TUF",
                                    "Name": "Tours",
                                    "CityId": "TOUR",
                                    "CountryId": "FR",
                                    "RegionId": "CENTR",
                                    "Location": "0.7275, 47.428333"
                                }
                            ]
                        },
                        {
                            "Id": "QUIM",
                            "Name": "Quimper",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-4.0999998, 48",
                            "IataCode": "UIP",
                            "Airports": [
                                {
                                    "Id": "UIP",
                                    "Name": "Quimper",
                                    "CityId": "QUIM",
                                    "CountryId": "FR",
                                    "Location": "-4.170833, 47.974444"
                                }
                            ]
                        },
                        {
                            "Id": "ROUE",
                            "Name": "Rouen",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "HAUTE",
                            "Location": "1.0993201, 49.443129",
                            "IataCode": "URO",
                            "Airports": [
                                {
                                    "Id": "URO",
                                    "Name": "Rouen",
                                    "CityId": "ROUE",
                                    "CountryId": "FR",
                                    "RegionId": "HAUTE",
                                    "Location": "1.1875, 49.388611"
                                }
                            ]
                        },
                        {
                            "Id": "VANN",
                            "Name": "Vannes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-2.75, 47.666667",
                            "IataCode": "VNE",
                            "Airports": [
                                {
                                    "Id": "VNE",
                                    "Name": "Vannes",
                                    "CityId": "VANN",
                                    "CountryId": "FR",
                                    "Location": "-2.766667, 47.65"
                                }
                            ]
                        },
                        {
                            "Id": "ANGO",
                            "Name": "Angouleme",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "0.15000029, 45.65",
                            "IataCode": "ANG",
                            "Airports": [
                                {
                                    "Id": "ANG",
                                    "Name": "Angouleme",
                                    "CityId": "ANGO",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "0.216, 45.7299"
                                }
                            ]
                        },
                        {
                            "Id": "AUFA",
                            "Name": "Auxerre",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "3.5666667, 47.8",
                            "IataCode": "AUF",
                            "Airports": [
                                {
                                    "Id": "AUF",
                                    "Name": "Auxerre",
                                    "CityId": "AUFA",
                                    "CountryId": "FR",
                                    "Location": "3.5, 47.85"
                                }
                            ]
                        },
                        {
                            "Id": "AURI",
                            "Name": "Aurillac Tronquiere",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AUVER",
                            "Location": "2.45, 44.916667",
                            "IataCode": "AUR",
                            "Airports": [
                                {
                                    "Id": "AUR",
                                    "Name": "Aurillac Tronquiere",
                                    "CityId": "AURI",
                                    "CountryId": "FR",
                                    "RegionId": "AUVER",
                                    "Location": "2.418056, 44.8975"
                                }
                            ]
                        },
                        {
                            "Id": "AVIG",
                            "Name": "Avignon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "4.8166664, 43.95",
                            "IataCode": "AVN",
                            "Airports": [
                                {
                                    "Id": "AVN",
                                    "Name": "Avignon",
                                    "CityId": "AVIG",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "4.9, 43.9"
                                }
                            ]
                        },
                        {
                            "Id": "BARE",
                            "Name": "Barcelonnette",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "6.6500001, 44.383333",
                            "IataCode": "BAE",
                            "Airports": [
                                {
                                    "Id": "BAE",
                                    "Name": "Barcelonnette",
                                    "CityId": "BARE",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.666667, 44.383333"
                                }
                            ]
                        },
                        {
                            "Id": "MEND",
                            "Name": "Mende",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "3.5, 44.516667",
                            "IataCode": "MEN",
                            "Airports": [
                                {
                                    "Id": "MEN",
                                    "Name": "Mende",
                                    "CityId": "MEND",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "3.533333, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "MZMA",
                            "Name": "Metz",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LORRA",
                            "Location": "6.1726867, 49.119107",
                            "IataCode": "MZM",
                            "Airports": [
                                {
                                    "Id": "MZM",
                                    "Name": "Metz",
                                    "CityId": "MZMA",
                                    "CountryId": "FR",
                                    "RegionId": "LORRA",
                                    "Location": "6.134444, 49.0725"
                                }
                            ]
                        },
                        {
                            "Id": "NIOR",
                            "Name": "Niort",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "POITO",
                            "Location": "-0.46666703, 46.316667",
                            "IataCode": "NIT",
                            "Airports": [
                                {
                                    "Id": "NIT",
                                    "Name": "Niort",
                                    "CityId": "NIOR",
                                    "CountryId": "FR",
                                    "RegionId": "POITO",
                                    "Location": "-0.45, 46.316667"
                                }
                            ]
                        },
                        {
                            "Id": "VITT",
                            "Name": "Vittel",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LORRA",
                            "Location": "5.9499999, 48.2",
                            "IataCode": "VTL",
                            "Airports": [
                                {
                                    "Id": "VTL",
                                    "Name": "Vittel",
                                    "CityId": "VITT",
                                    "CountryId": "FR",
                                    "RegionId": "LORRA",
                                    "Location": "5.95, 48.2"
                                }
                            ]
                        },
                        {
                            "Id": "EVRY",
                            "Name": "Evry Hlpt",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ILE_D",
                            "Location": "2.45, 48.633333",
                            "IataCode": "JEV",
                            "Airports": [
                                {
                                    "Id": "JEV",
                                    "Name": "Evry Hlpt",
                                    "CityId": "EVRY",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.416667, 48.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MEGE",
                            "Name": "Megeve",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.6166668, 45.866667",
                            "IataCode": "MVV",
                            "Airports": [
                                {
                                    "Id": "MVV",
                                    "Name": "Megeve",
                                    "CityId": "MEGE",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.65, 45.816667"
                                }
                            ]
                        },
                        {
                            "Id": "SERR",
                            "Name": "Serre Chevalier",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "6.5176393, 44.944389",
                            "IataCode": "SEC",
                            "Airports": [
                                {
                                    "Id": "SEC",
                                    "Name": "Serre Chevalier",
                                    "CityId": "SERR",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.5, 44.916667"
                                }
                            ]
                        },
                        {
                            "Id": "SOLE",
                            "Name": "Solenzara",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CORSE",
                            "Location": "9.4, 41.85",
                            "IataCode": "SOZ",
                            "Airports": [
                                {
                                    "Id": "SOZ",
                                    "Name": "Solenzara",
                                    "CityId": "SOLE",
                                    "CountryId": "FR",
                                    "RegionId": "CORSE",
                                    "Location": "9.383333, 41.883333"
                                }
                            ]
                        },
                        {
                            "Id": "SOPH",
                            "Name": "Sophia Antipolis Hlpt",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "7.0418931, 43.624023",
                            "IataCode": "SXD",
                            "Airports": [
                                {
                                    "Id": "SXD",
                                    "Name": "Sophia Antipolis Hlpt",
                                    "CityId": "SOPH",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "7.05, 43.616667"
                                }
                            ]
                        },
                        {
                            "Id": "TIGN",
                            "Name": "Tignes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.9166668, 45.5",
                            "IataCode": "TGF",
                            "Airports": [
                                {
                                    "Id": "TGF",
                                    "Name": "Tignes",
                                    "CityId": "TIGN",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.9, 45.483333"
                                }
                            ]
                        },
                        {
                            "Id": "REIM",
                            "Name": "Reims",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "4.0333332, 49.250001",
                            "IataCode": "RHE",
                            "Airports": [
                                {
                                    "Id": "RHE",
                                    "Name": "Reims",
                                    "CityId": "REIM",
                                    "CountryId": "FR",
                                    "Location": "4.050556, 49.310833"
                                }
                            ]
                        },
                        {
                            "Id": "DIJO",
                            "Name": "Dijon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "5.0166657, 47.316667",
                            "IataCode": "DIJ",
                            "Airports": [
                                {
                                    "Id": "DIJ",
                                    "Name": "Dijon",
                                    "CityId": "DIJO",
                                    "CountryId": "FR",
                                    "Location": "5.0889, 47.2708"
                                }
                            ]
                        },
                        {
                            "Id": "LOUR",
                            "Name": "Lourdes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "-0.050000127, 43.1",
                            "IataCode": "LDE",
                            "Airports": [
                                {
                                    "Id": "LDE",
                                    "Name": "Lourdes",
                                    "CityId": "LOUR",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "-0.003611, 43.181944"
                                }
                            ]
                        },
                        {
                            "Id": "MULH",
                            "Name": "Mulhouse",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ALSAC",
                            "Location": "7.3333334, 47.750001",
                            "IataCode": "MLH",
                            "Airports": [
                                {
                                    "Id": "MLH",
                                    "Name": "Mulhouse",
                                    "CityId": "MULH",
                                    "CountryId": "FR",
                                    "RegionId": "ALSAC",
                                    "Location": "7.529914, 47.589583"
                                }
                            ]
                        },
                        {
                            "Id": "CALA",
                            "Name": "Calais",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "NORD_",
                            "Location": "1.8520547, 50.958104",
                            "IataCode": "CQF",
                            "Airports": [
                                {
                                    "Id": "CQF",
                                    "Name": "Calais Marck",
                                    "CityId": "CALA",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "1.933333, 50.95"
                                },
                                {
                                    "Id": "XFF",
                                    "Name": "Calais International Rail Station",
                                    "CityId": "CALA",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "1.816521, 50.93063"
                                }
                            ]
                        },
                        {
                            "Id": "MONT",
                            "Name": "Montpellier",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "3.883333, 43.6",
                            "IataCode": "MPL",
                            "Airports": [
                                {
                                    "Id": "MPL",
                                    "Name": "Montpellier",
                                    "CityId": "MONT",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "3.9675, 43.580833"
                                }
                            ]
                        },
                        {
                            "Id": "MARS",
                            "Name": "Marseille",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "5.3999994, 43.3",
                            "IataCode": "MRS",
                            "Airports": [
                                {
                                    "Id": "MRS",
                                    "Name": "Marseille",
                                    "CityId": "MARS",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "5.2143389, 43.438462"
                                }
                            ]
                        },
                        {
                            "Id": "PERP",
                            "Name": "Perpignan",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LANGU",
                            "Location": "2.8954125, 42.69764",
                            "IataCode": "PGF",
                            "Airports": [
                                {
                                    "Id": "PGF",
                                    "Name": "Perpignan",
                                    "CityId": "PERP",
                                    "CountryId": "FR",
                                    "RegionId": "LANGU",
                                    "Location": "2.871667, 42.740833"
                                }
                            ]
                        },
                        {
                            "Id": "PAUU",
                            "Name": "Pau",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "-0.36666703, 43.3",
                            "IataCode": "PUF",
                            "Airports": [
                                {
                                    "Id": "PUF",
                                    "Name": "Pau",
                                    "CityId": "PAUU",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "-0.417778, 43.38"
                                }
                            ]
                        },
                        {
                            "Id": "CLER",
                            "Name": "Clermont-Ferrand",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AUVER",
                            "Location": "3.0833333, 45.783333",
                            "IataCode": "CFE",
                            "Airports": [
                                {
                                    "Id": "CFE",
                                    "Name": "Clermont-Ferrand",
                                    "CityId": "CLER",
                                    "CountryId": "FR",
                                    "RegionId": "AUVER",
                                    "Location": "3.1675, 45.785833"
                                }
                            ]
                        },
                        {
                            "Id": "DINA",
                            "Name": "Dinard",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-2.0666667, 48.633333",
                            "IataCode": "DNR",
                            "Airports": [
                                {
                                    "Id": "DNR",
                                    "Name": "Dinard",
                                    "CityId": "DINA",
                                    "CountryId": "FR",
                                    "Location": "-2.0836, 48.5878"
                                }
                            ]
                        },
                        {
                            "Id": "TOUL",
                            "Name": "Toulon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "5.9333328, 43.116667",
                            "IataCode": "TLN",
                            "Airports": [
                                {
                                    "Id": "TLN",
                                    "Name": "Toulon-Hyères",
                                    "CityId": "TOUL",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.15, 43.1"
                                }
                            ]
                        },
                        {
                            "Id": "STNA",
                            "Name": "St. Nazaire Montoir",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-2.2000002, 47.283333",
                            "IataCode": "SNR",
                            "Airports": [
                                {
                                    "Id": "SNR",
                                    "Name": "St. Nazaire Montoir",
                                    "CityId": "STNA",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-2.147827, 47.310664"
                                }
                            ]
                        },
                        {
                            "Id": "FREJ",
                            "Name": "Frejus",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "6.7333335, 43.433333",
                            "IataCode": "FRJ",
                            "Airports": [
                                {
                                    "Id": "FRJ",
                                    "Name": "Frejus",
                                    "CityId": "FREJ",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.733333, 43.433333"
                                }
                            ]
                        },
                        {
                            "Id": "ALBT",
                            "Name": "Albert",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "2.65, 50",
                            "IataCode": "BYF",
                            "Airports": [
                                {
                                    "Id": "BYF",
                                    "Name": "Albert (Picardie Somme)",
                                    "CityId": "ALBT",
                                    "CountryId": "FR",
                                    "Location": "2.69, 49.97"
                                }
                            ]
                        },
                        {
                            "Id": "SAIN",
                            "Name": "Saint Tropez",
                            "SingleAirportCity": false,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "6.6333334, 43.266667",
                            "IataCode": "LTT",
                            "Airports": [
                                {
                                    "Id": "XPZ",
                                    "Name": "Saint Tropez Harbour",
                                    "CityId": "SAIN",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.633333, 43.283333"
                                },
                                {
                                    "Id": "LTT",
                                    "Name": "Saint Tropez La Mole",
                                    "CityId": "SAIN",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.482019, 43.205927"
                                }
                            ]
                        },
                        {
                            "Id": "CHAT",
                            "Name": "Chateauroux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CENTR",
                            "Location": "1.7000002, 46.816667",
                            "IataCode": "CHR",
                            "Airports": [
                                {
                                    "Id": "CHR",
                                    "Name": "Chateauroux",
                                    "CityId": "CHAT",
                                    "CountryId": "FR",
                                    "RegionId": "CENTR",
                                    "Location": "1.730556, 46.862222"
                                }
                            ]
                        },
                        {
                            "Id": "SBKA",
                            "Name": "St Brieuc",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-2.7833333, 48.516667",
                            "IataCode": "SBK",
                            "Airports": [
                                {
                                    "Id": "SBK",
                                    "Name": "St Brieuc Armor",
                                    "CityId": "SBKA",
                                    "CountryId": "FR",
                                    "Location": "-2.86211, 48.535249"
                                }
                            ]
                        },
                        {
                            "Id": "MERI",
                            "Name": "Meribel",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.5666668, 45.416667",
                            "IataCode": "MFX",
                            "Airports": [
                                {
                                    "Id": "MFX",
                                    "Name": "Meribel",
                                    "CityId": "MERI",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.566667, 45.416667"
                                }
                            ]
                        },
                        {
                            "Id": "EPIN",
                            "Name": "Epinal Mirecourt",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LORRA",
                            "Location": "6.1333334, 48.3",
                            "IataCode": "EPL",
                            "Airports": [
                                {
                                    "Id": "EPL",
                                    "Name": "Epinal Mirecourt",
                                    "CityId": "EPIN",
                                    "CountryId": "FR",
                                    "RegionId": "LORRA",
                                    "Location": "6.069444, 48.325833"
                                }
                            ]
                        },
                        {
                            "Id": "STET",
                            "Name": "St Etienne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "4.3999997, 45.433333",
                            "IataCode": "EBU",
                            "Airports": [
                                {
                                    "Id": "EBU",
                                    "Name": "St Etienne",
                                    "CityId": "STET",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "4.296944, 45.541389"
                                }
                            ]
                        },
                        {
                            "Id": "AVFA",
                            "Name": "Avoriaz",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.7734147, 46.192428",
                            "IataCode": "AVF",
                            "Airports": [
                                {
                                    "Id": "AVF",
                                    "Name": "Avoriaz",
                                    "CityId": "AVFA",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.766667, 46.2"
                                }
                            ]
                        },
                        {
                            "Id": "HAZE",
                            "Name": "Hazebrouck Merv / Calon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "NORD_",
                            "Location": "2.5333333, 50.716667",
                            "IataCode": "HZB",
                            "Airports": [
                                {
                                    "Id": "HZB",
                                    "Name": "Hazebrouck Merv / Calon",
                                    "CityId": "HAZE",
                                    "CountryId": "FR",
                                    "RegionId": "NORD_",
                                    "Location": "2.65, 50.616667"
                                }
                            ]
                        },
                        {
                            "Id": "BRIV",
                            "Name": "Brive Vallée de la Dordogne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LIMOU",
                            "Location": "0.74115608, 45.104824",
                            "IataCode": "BVE",
                            "Airports": [
                                {
                                    "Id": "BVE",
                                    "Name": "Brive Vallée de la Dordogne",
                                    "CityId": "BRIV",
                                    "CountryId": "FR",
                                    "RegionId": "LIMOU",
                                    "Location": "1.483498, 45.040355"
                                }
                            ]
                        },
                        {
                            "Id": "DOLE",
                            "Name": "Dole Tavaux",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "5.3999999, 47.033333",
                            "IataCode": "DLE",
                            "Airports": [
                                {
                                    "Id": "DLE",
                                    "Name": "Dole Tavaux",
                                    "CityId": "DOLE",
                                    "CountryId": "FR",
                                    "Location": "5.433333, 47.05"
                                }
                            ]
                        },
                        {
                            "Id": "SAIO",
                            "Name": "Saint-Yan Bourgogne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "4.0333333, 46.416667",
                            "IataCode": "SYT",
                            "Airports": [
                                {
                                    "Id": "SYT",
                                    "Name": "Saint-Yan Bourgogne",
                                    "CityId": "SAIO",
                                    "CountryId": "FR",
                                    "Location": "4.033333, 46.4"
                                }
                            ]
                        },
                        {
                            "Id": "STCA",
                            "Name": "Saint Crepin",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "0.60000009, 45.416667",
                            "IataCode": "SCP",
                            "Airports": [
                                {
                                    "Id": "SCP",
                                    "Name": "Saint Crepin",
                                    "CityId": "STCA",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "0.6167, 45.4167"
                                }
                            ]
                        },
                        {
                            "Id": "LEPU",
                            "Name": "Le Puy Loudes",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AUVER",
                            "Location": "3.75, 45.083333",
                            "IataCode": "LPY",
                            "Airports": [
                                {
                                    "Id": "LPY",
                                    "Name": "Le Puy Loudes",
                                    "CityId": "LEPU",
                                    "CountryId": "FR",
                                    "RegionId": "AUVER",
                                    "Location": "3.766667, 45.083333"
                                }
                            ]
                        },
                        {
                            "Id": "ILED",
                            "Name": "Ile D'Yeu",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-2.3493576, 46.725153",
                            "IataCode": "IDY",
                            "Airports": [
                                {
                                    "Id": "IDY",
                                    "Name": "Ile D'Yeu",
                                    "CityId": "ILED",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-2.391973, 46.717122"
                                }
                            ]
                        },
                        {
                            "Id": "DLPA",
                            "Name": "Disneyland Paris",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ILE_D",
                            "Location": "2.7795625, 48.870403",
                            "IataCode": "DLP",
                            "Airports": [
                                {
                                    "Id": "DLP",
                                    "Name": "Disneyland Paris",
                                    "CityId": "DLPA",
                                    "CountryId": "FR",
                                    "RegionId": "ILE_D",
                                    "Location": "2.8, 48.866667"
                                }
                            ]
                        },
                        {
                            "Id": "COLM",
                            "Name": "Colmar",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "ALSAC",
                            "Location": "7.366667, 48.083333",
                            "IataCode": "CMR",
                            "Airports": [
                                {
                                    "Id": "CMR",
                                    "Name": "Colmar Houssen",
                                    "CityId": "COLM",
                                    "CountryId": "FR",
                                    "RegionId": "ALSAC",
                                    "Location": "7.366667, 48.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LARP",
                            "Name": "La Roche-sur-Yon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-1.4333335, 46.666667",
                            "IataCode": "EDM",
                            "Airports": [
                                {
                                    "Id": "EDM",
                                    "Name": "La Roche Les Ajoncs",
                                    "CityId": "LARP",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-1.383333, 46.7"
                                }
                            ]
                        },
                        {
                            "Id": "ALBI",
                            "Name": "Albi",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "MIDI_",
                            "Location": "2.1500001, 43.933333",
                            "IataCode": "LBI",
                            "Airports": [
                                {
                                    "Id": "LBI",
                                    "Name": "Albi Sequestre",
                                    "CityId": "ALBI",
                                    "CountryId": "FR",
                                    "RegionId": "MIDI_",
                                    "Location": "2.15, 43.933333"
                                }
                            ]
                        },
                        {
                            "Id": "LABA",
                            "Name": "La Baule-Escoublac",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-2.3639489, 47.292213",
                            "IataCode": "LBY",
                            "Airports": [
                                {
                                    "Id": "LBY",
                                    "Name": "La Baule Montoir",
                                    "CityId": "LABA",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-2.345833, 47.288889"
                                }
                            ]
                        },
                        {
                            "Id": "MONU",
                            "Name": "Montluçon",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AUVER",
                            "Location": "2.6, 46.333333",
                            "IataCode": "MCU",
                            "Airports": [
                                {
                                    "Id": "MCU",
                                    "Name": "Montlucon Gueret",
                                    "CityId": "MONU",
                                    "CountryId": "FR",
                                    "RegionId": "AUVER",
                                    "Location": "2.36396, 46.22264"
                                }
                            ]
                        },
                        {
                            "Id": "MORL",
                            "Name": "Morlaix",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-3.8333333, 48.583333",
                            "IataCode": "MXN",
                            "Airports": [
                                {
                                    "Id": "MXN",
                                    "Name": "Morlaix Ploujean",
                                    "CityId": "MORL",
                                    "CountryId": "FR",
                                    "Location": "-3.816667, 48.6"
                                }
                            ]
                        },
                        {
                            "Id": "AUBE",
                            "Name": "Aubenas",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "4.3833332, 44.616667",
                            "IataCode": "OBS",
                            "Airports": [
                                {
                                    "Id": "OBS",
                                    "Name": "Aubenas Vals Lanas",
                                    "CityId": "AUBE",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "4.372, 44.539"
                                }
                            ]
                        },
                        {
                            "Id": "LESS",
                            "Name": "Les Sables-d'Olonne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PAYS_",
                            "Location": "-1.7833333, 46.5",
                            "IataCode": "LSO",
                            "Airports": [
                                {
                                    "Id": "LSO",
                                    "Name": "Les Sables Talmout",
                                    "CityId": "LESS",
                                    "CountryId": "FR",
                                    "RegionId": "PAYS_",
                                    "Location": "-1.783333, 46.5"
                                }
                            ]
                        },
                        {
                            "Id": "NEVE",
                            "Name": "Nevers",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "3.1589985, 46.989562",
                            "IataCode": "NVS",
                            "Airports": [
                                {
                                    "Id": "NVS",
                                    "Name": "Nevers Fourchambt",
                                    "CityId": "NEVE",
                                    "CountryId": "FR",
                                    "Location": "3.15, 47"
                                }
                            ]
                        },
                        {
                            "Id": "ZFQA",
                            "Name": "Talence",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AQUIT",
                            "Location": "-0.60000018, 44.816667",
                            "IataCode": "ZFQ",
                            "Airports": [
                                {
                                    "Id": "ZFQ",
                                    "Name": "Gare de Bordeaux",
                                    "CityId": "ZFQA",
                                    "CountryId": "FR",
                                    "RegionId": "AQUIT",
                                    "Location": "-0.700556, 44.833889"
                                }
                            ]
                        },
                        {
                            "Id": "VALD",
                            "Name": "Borgo San Dalmazzo",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "7.4858311, 44.333028",
                            "IataCode": "VAZ",
                            "Airports": [
                                {
                                    "Id": "VAZ",
                                    "Name": "Val D'Isere",
                                    "CityId": "VALD",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.983333, 45.45"
                                }
                            ]
                        },
                        {
                            "Id": "GAPT",
                            "Name": "Saint-Pierre",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "PROVE",
                            "Location": "6.0333334, 44.433333",
                            "IataCode": "GAT",
                            "Airports": [
                                {
                                    "Id": "GAT",
                                    "Name": "Gap Tallard",
                                    "CityId": "GAPT",
                                    "CountryId": "FR",
                                    "RegionId": "PROVE",
                                    "Location": "6.083333, 44.55"
                                }
                            ]
                        },
                        {
                            "Id": "ZFJA",
                            "Name": "Saint-Laurent",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "Location": "-1.6500001, 48.133333",
                            "IataCode": "ZFJ",
                            "Airports": [
                                {
                                    "Id": "ZFJ",
                                    "Name": "Gare de Rennes",
                                    "CityId": "ZFJA",
                                    "CountryId": "FR",
                                    "Location": "-1.73172, 48.071771"
                                }
                            ]
                        },
                        {
                            "Id": "ROCH",
                            "Name": "Saint-Brice-sur-Vienne",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LIMOU",
                            "Location": "0.95000008, 45.883333",
                            "IataCode": "RCO",
                            "Airports": [
                                {
                                    "Id": "RCO",
                                    "Name": "Rochefort",
                                    "CityId": "ROCH",
                                    "CountryId": "FR",
                                    "RegionId": "LIMOU",
                                    "Location": "-0.980556, 45.890556"
                                }
                            ]
                        },
                        {
                            "Id": "VICH",
                            "Name": "Les Bouchauds",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "AUVER",
                            "Location": "3.4, 46.166667",
                            "IataCode": "VHY",
                            "Airports": [
                                {
                                    "Id": "VHY",
                                    "Name": "Vichy Charmeil",
                                    "CityId": "VICH",
                                    "CountryId": "FR",
                                    "RegionId": "AUVER",
                                    "Location": "3.409722, 46.165556"
                                }
                            ]
                        },
                        {
                            "Id": "VALF",
                            "Name": "Les Bérards",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "4.9833332, 44.916667",
                            "IataCode": "VAF",
                            "Airports": [
                                {
                                    "Id": "VAF",
                                    "Name": "Valence Chabeuil",
                                    "CityId": "VALF",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "4.970278, 44.920278"
                                }
                            ]
                        },
                        {
                            "Id": "ROAN",
                            "Name": "La Roche",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "4, 46.016667",
                            "IataCode": "RNE",
                            "Airports": [
                                {
                                    "Id": "RNE",
                                    "Name": "Roanne Renaison",
                                    "CityId": "ROAN",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "4.000833, 46.053333"
                                }
                            ]
                        },
                        {
                            "Id": "DXAA",
                            "Name": "Lanchatra",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "RHONE",
                            "Location": "6.1500001, 44.966667",
                            "IataCode": "DXA",
                            "Airports": [
                                {
                                    "Id": "DXA",
                                    "Name": "Deux Alpes",
                                    "CityId": "DXAA",
                                    "CountryId": "FR",
                                    "RegionId": "RHONE",
                                    "Location": "6.15, 44.983333"
                                }
                            ]
                        },
                        {
                            "Id": "METZ",
                            "Name": "Goin",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "LORRA",
                            "Location": "6.2166668, 48.983333",
                            "IataCode": "ETZ",
                            "Airports": [
                                {
                                    "Id": "ETZ",
                                    "Name": "Metz / Nancy Lorraine",
                                    "CityId": "METZ",
                                    "CountryId": "FR",
                                    "RegionId": "LORRA",
                                    "Location": "6.247778, 48.979167"
                                }
                            ]
                        },
                        {
                            "Id": "CHOL",
                            "Name": "Ciran",
                            "SingleAirportCity": true,
                            "CountryId": "FR",
                            "RegionId": "CENTR",
                            "Location": "0.86666679, 47.066667",
                            "IataCode": "CET",
                            "Airports": [
                                {
                                    "Id": "CET",
                                    "Name": "Cholet Le Pontrea",
                                    "CityId": "CHOL",
                                    "CountryId": "FR",
                                    "RegionId": "CENTR",
                                    "Location": "-0.866667, 47.066667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SM",
                    "Name": "San Marino",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "SAIA",
                            "Name": "San Marino",
                            "SingleAirportCity": true,
                            "CountryId": "SM",
                            "Location": "12.45, 43.933333",
                            "IataCode": "SAI",
                            "Airports": [
                                {
                                    "Id": "SAI",
                                    "Name": "San Marino",
                                    "CityId": "SAIA",
                                    "CountryId": "SM",
                                    "Location": "12.466667, 43.916667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LT",
                    "Name": "Lithuania",
                    "CurrencyId": "LTL",
                    "Cities": [
                        {
                            "Id": "KAUN",
                            "Name": "Kaunas",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "23.9, 54.900001",
                            "IataCode": "KUN",
                            "Airports": [
                                {
                                    "Id": "KUN",
                                    "Name": "Kaunas",
                                    "CityId": "KAUN",
                                    "CountryId": "LT",
                                    "Location": "24.084778, 54.963919"
                                }
                            ]
                        },
                        {
                            "Id": "PALA",
                            "Name": "Palanga",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "21.068611, 55.9175",
                            "IataCode": "PLQ",
                            "Airports": [
                                {
                                    "Id": "PLQ",
                                    "Name": "Palanga International",
                                    "CityId": "PALA",
                                    "CountryId": "LT",
                                    "Location": "21.083333, 55.95"
                                }
                            ]
                        },
                        {
                            "Id": "SQQA",
                            "Name": "Siauliai",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "23.316666, 55.933336",
                            "IataCode": "SQQ",
                            "Airports": [
                                {
                                    "Id": "SQQ",
                                    "Name": "Siauliai",
                                    "CityId": "SQQA",
                                    "CountryId": "LT",
                                    "Location": "23.316667, 55.933333"
                                }
                            ]
                        },
                        {
                            "Id": "VILN",
                            "Name": "Vilnius",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "25.316667, 54.683336",
                            "IataCode": "VNO",
                            "Airports": [
                                {
                                    "Id": "VNO",
                                    "Name": "Vilnius",
                                    "CityId": "VILN",
                                    "CountryId": "LT",
                                    "Location": "25.286709, 54.637981"
                                }
                            ]
                        },
                        {
                            "Id": "PNVA",
                            "Name": "Panevezys",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "24.35, 55.733335",
                            "IataCode": "PNV",
                            "Airports": [
                                {
                                    "Id": "PNV",
                                    "Name": "Panevezys",
                                    "CityId": "PNVA",
                                    "CountryId": "LT",
                                    "Location": "24.46667, 55.73333"
                                }
                            ]
                        },
                        {
                            "Id": "KLAI",
                            "Name": "Haylat La'mia",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "56.116667, 20.883333",
                            "IataCode": "KLJ",
                            "Airports": [
                                {
                                    "Id": "KLJ",
                                    "Name": "Klaipeda",
                                    "CityId": "KLAI",
                                    "CountryId": "LT",
                                    "Location": "21.25, 55.71667"
                                }
                            ]
                        },
                        {
                            "Id": "SHAU",
                            "Name": "Ar Rāyḩānī",
                            "SingleAirportCity": true,
                            "CountryId": "LT",
                            "Location": "55.969722, 23.6125",
                            "IataCode": "HLJ",
                            "Airports": [
                                {
                                    "Id": "HLJ",
                                    "Name": "Shauliaj",
                                    "CityId": "SHAU",
                                    "CountryId": "LT",
                                    "Location": "23.5167, 56.0667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UK",
                    "Name": "United Kingdom",
                    "CurrencyId": "GBP",
                    "LanguageId": "EN",
                    "Regions": [
                        {
                            "Id": "ENGLA",
                            "Name": "England",
                            "CountryId": "UK"
                        },
                        {
                            "Id": "N_IRE",
                            "Name": "Northern Ireland",
                            "CountryId": "UK"
                        },
                        {
                            "Id": "SCOTL",
                            "Name": "Scotland",
                            "CountryId": "UK"
                        },
                        {
                            "Id": "WALES",
                            "Name": "Wales",
                            "CountryId": "UK"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "WICK",
                            "Name": "Wick",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.0833333, 58.433333",
                            "IataCode": "WIC",
                            "Airports": [
                                {
                                    "Id": "WIC",
                                    "Name": "Wick",
                                    "CityId": "WICK",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.086667, 58.456944"
                                }
                            ]
                        },
                        {
                            "Id": "WXFA",
                            "Name": "Braintree",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.56666701, 51.883333",
                            "IataCode": "WXF",
                            "Airports": [
                                {
                                    "Id": "WXF",
                                    "Name": "Braintree",
                                    "CityId": "WXFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.566667, 51.883333"
                                }
                            ]
                        },
                        {
                            "Id": "YEOA",
                            "Name": "Yeovilton",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.6502778, 51.004167",
                            "IataCode": "YEO",
                            "Airports": [
                                {
                                    "Id": "YEO",
                                    "Name": "Yeovilton",
                                    "CityId": "YEOA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.55, 51.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ABER",
                            "Name": "Aberdeen",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.1000004, 57.133333",
                            "IataCode": "ABZ",
                            "Airports": [
                                {
                                    "Id": "ABZ",
                                    "Name": "Aberdeen",
                                    "CityId": "ABER",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.198056, 57.204167"
                                }
                            ]
                        },
                        {
                            "Id": "ADXA",
                            "Name": "St Andrews",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.7833333, 56.333333",
                            "IataCode": "ADX",
                            "Airports": [
                                {
                                    "Id": "ADX",
                                    "Name": "St Andrews",
                                    "CityId": "ADXA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.866667, 56.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ESHA",
                            "Name": "Shoreham By Sea",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.27431501, 50.834131",
                            "IataCode": "ESH",
                            "Airports": [
                                {
                                    "Id": "ESH",
                                    "Name": "Shoreham By Sea",
                                    "CityId": "ESHA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.3, 50.833333"
                                }
                            ]
                        },
                        {
                            "Id": "OKHA",
                            "Name": "Oakham",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.73333344, 52.666667",
                            "IataCode": "OKH",
                            "Airports": [
                                {
                                    "Id": "OKH",
                                    "Name": "Oakham",
                                    "CityId": "OKHA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.716667, 52.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BELF",
                            "Name": "Belfast",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "N_IRE",
                            "Location": "-5.9333328, 54.583336",
                            "IataCode": "BFS",
                            "Airports": [
                                {
                                    "Id": "BHD",
                                    "Name": "Belfast City",
                                    "CityId": "BELF",
                                    "CountryId": "UK",
                                    "RegionId": "N_IRE",
                                    "Location": "-5.883333, 54.616667"
                                },
                                {
                                    "Id": "BFS",
                                    "Name": "Belfast International",
                                    "CityId": "BELF",
                                    "CountryId": "UK",
                                    "RegionId": "N_IRE",
                                    "Location": "-6.2172632, 54.656371"
                                }
                            ]
                        },
                        {
                            "Id": "BIRM",
                            "Name": "Birmingham",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.9166669, 52.466669",
                            "IataCode": "BHX",
                            "Airports": [
                                {
                                    "Id": "BHX",
                                    "Name": "Birmingham",
                                    "CityId": "BIRM",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.7435393, 52.452402"
                                }
                            ]
                        },
                        {
                            "Id": "BLAC",
                            "Name": "Blackpool",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-3.05, 53.816667",
                            "IataCode": "BLK",
                            "Airports": [
                                {
                                    "Id": "BLK",
                                    "Name": "Blackpool",
                                    "CityId": "BLAC",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-3.036111, 53.771944"
                                }
                            ]
                        },
                        {
                            "Id": "BOUR",
                            "Name": "Bournemouth",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.8833334, 50.716668",
                            "IataCode": "BOH",
                            "Airports": [
                                {
                                    "Id": "BOH",
                                    "Name": "Bournemouth",
                                    "CityId": "BOUR",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.8325419, 50.777605"
                                }
                            ]
                        },
                        {
                            "Id": "BRIS",
                            "Name": "Bristol",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.5833333, 51.450002",
                            "IataCode": "BRS",
                            "Airports": [
                                {
                                    "Id": "BRS",
                                    "Name": "Bristol",
                                    "CityId": "BRIS",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.713563, 51.383688"
                                }
                            ]
                        },
                        {
                            "Id": "BWFA",
                            "Name": "Barrow-In-Furness",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-3.2333333, 54.116667",
                            "IataCode": "BWF",
                            "Airports": [
                                {
                                    "Id": "BWF",
                                    "Name": "Barrow-In-Furness",
                                    "CityId": "BWFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-3.233333, 54.116667"
                                }
                            ]
                        },
                        {
                            "Id": "OXFA",
                            "Name": "Oxford",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.2559608, 51.752221",
                            "IataCode": "OXF",
                            "Airports": [
                                {
                                    "Id": "OXF",
                                    "Name": "Oxford",
                                    "CityId": "OXFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.25, 51.766667"
                                }
                            ]
                        },
                        {
                            "Id": "CAMP",
                            "Name": "Campbeltown",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-5.6333331, 55.433333",
                            "IataCode": "CAL",
                            "Airports": [
                                {
                                    "Id": "CAL",
                                    "Name": "Campbeltown",
                                    "CityId": "CAMP",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-5.686667, 55.436389"
                                }
                            ]
                        },
                        {
                            "Id": "CAXA",
                            "Name": "Carlisle",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.9333333, 54.883333",
                            "IataCode": "CAX",
                            "Airports": [
                                {
                                    "Id": "CAX",
                                    "Name": "Carlisle",
                                    "CityId": "CAXA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.809444, 54.936667"
                                }
                            ]
                        },
                        {
                            "Id": "CBGA",
                            "Name": "Cambridge",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.11666701, 52.200002",
                            "IataCode": "CBG",
                            "Airports": [
                                {
                                    "Id": "CBG",
                                    "Name": "Cambridge",
                                    "CityId": "CBGA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.183333, 52.2"
                                }
                            ]
                        },
                        {
                            "Id": "CEGA",
                            "Name": "Chester",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.9166667, 53.2",
                            "IataCode": "CEG",
                            "Airports": [
                                {
                                    "Id": "CEG",
                                    "Name": "Chester",
                                    "CityId": "CEGA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.977778, 53.178056"
                                }
                            ]
                        },
                        {
                            "Id": "COVE",
                            "Name": "Coventry",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.5121654, 52.406556",
                            "IataCode": "CVT",
                            "Airports": [
                                {
                                    "Id": "CVT",
                                    "Name": "Coventry",
                                    "CityId": "COVE",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.478611, 52.369167"
                                }
                            ]
                        },
                        {
                            "Id": "CARD",
                            "Name": "Cardiff",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "WALES",
                            "Location": "-3.1795927, 51.48078",
                            "IataCode": "CWL",
                            "Airports": [
                                {
                                    "Id": "CWL",
                                    "Name": "Cardiff",
                                    "CityId": "CARD",
                                    "CountryId": "UK",
                                    "RegionId": "WALES",
                                    "Location": "-3.3462304, 51.397707"
                                }
                            ]
                        },
                        {
                            "Id": "DUND",
                            "Name": "Dundee",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.9666667, 56.500001",
                            "IataCode": "DND",
                            "Airports": [
                                {
                                    "Id": "DND",
                                    "Name": "Dundee",
                                    "CityId": "DUND",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.008333, 56.452778"
                                }
                            ]
                        },
                        {
                            "Id": "DOCA",
                            "Name": "Dornoch",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-4.0333332, 57.866667",
                            "IataCode": "DOC",
                            "Airports": [
                                {
                                    "Id": "DOC",
                                    "Name": "Dornoch",
                                    "CityId": "DOCA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-4.033333, 57.866667"
                                }
                            ]
                        },
                        {
                            "Id": "EDIN",
                            "Name": "Edinburgh",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.2883468, 55.926518",
                            "IataCode": "EDI",
                            "Airports": [
                                {
                                    "Id": "EDI",
                                    "Name": "Edinburgh",
                                    "CityId": "EDIN",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.3635, 55.9497"
                                }
                            ]
                        },
                        {
                            "Id": "ENKA",
                            "Name": "Enniskillen",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "N_IRE",
                            "Location": "-7.6333334, 54.366667",
                            "IataCode": "ENK",
                            "Airports": [
                                {
                                    "Id": "ENK",
                                    "Name": "Enniskillen",
                                    "CityId": "ENKA",
                                    "CountryId": "UK",
                                    "RegionId": "N_IRE",
                                    "Location": "-7.633333, 54.35"
                                }
                            ]
                        },
                        {
                            "Id": "EXET",
                            "Name": "Exeter",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-3.5275071, 50.723599",
                            "IataCode": "EXT",
                            "Airports": [
                                {
                                    "Id": "EXT",
                                    "Name": "Exeter",
                                    "CityId": "EXET",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-3.421667, 50.734167"
                                }
                            ]
                        },
                        {
                            "Id": "FABA",
                            "Name": "Farnborough",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.73333375, 51.266667",
                            "IataCode": "FAB",
                            "Airports": [
                                {
                                    "Id": "FAB",
                                    "Name": "Farnborough",
                                    "CityId": "FABA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.766667, 51.283333"
                                }
                            ]
                        },
                        {
                            "Id": "FSSA",
                            "Name": "Forres",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.6166667, 57.6",
                            "IataCode": "FSS",
                            "Airports": [
                                {
                                    "Id": "FSS",
                                    "Name": "Forres",
                                    "CityId": "FSSA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.566566, 57.64416"
                                }
                            ]
                        },
                        {
                            "Id": "FWMA",
                            "Name": "Fort William",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-5.1120756, 56.816478",
                            "IataCode": "FWM",
                            "Airports": [
                                {
                                    "Id": "FWM",
                                    "Name": "Fort William",
                                    "CityId": "FWMA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-5.116667, 56.816667"
                                }
                            ]
                        },
                        {
                            "Id": "GLAS",
                            "Name": "Glasgow",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-4.2488787, 55.860982",
                            "IataCode": "GLA",
                            "Airports": [
                                {
                                    "Id": "GLA",
                                    "Name": "Glasgow Intl",
                                    "CityId": "GLAS",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-4.433333, 55.866667"
                                },
                                {
                                    "Id": "PIK",
                                    "Name": "Glasgow Prestwick",
                                    "CityId": "GLAS",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-4.592294, 55.509827"
                                }
                            ]
                        },
                        {
                            "Id": "GSYA",
                            "Name": "Grimsby",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.050000168, 53.533333",
                            "IataCode": "GSY",
                            "Airports": [
                                {
                                    "Id": "GSY",
                                    "Name": "Grimsby",
                                    "CityId": "GSYA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.083333, 53.583333"
                                }
                            ]
                        },
                        {
                            "Id": "HAWA",
                            "Name": "Haverfordwest",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "WALES",
                            "Location": "-4.9708332, 51.801667",
                            "IataCode": "HAW",
                            "Airports": [
                                {
                                    "Id": "HAW",
                                    "Name": "Haverfordwest",
                                    "CityId": "HAWA",
                                    "CountryId": "UK",
                                    "RegionId": "WALES",
                                    "Location": "-4.966667, 51.8"
                                }
                            ]
                        },
                        {
                            "Id": "HRTA",
                            "Name": "Harrogate",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.5333334, 53.983334",
                            "IataCode": "HRT",
                            "Airports": [
                                {
                                    "Id": "HRT",
                                    "Name": "Harrogate",
                                    "CityId": "HRTA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.55, 54"
                                }
                            ]
                        },
                        {
                            "Id": "INVE",
                            "Name": "Inverness",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-4.2333331, 57.466667",
                            "IataCode": "INV",
                            "Airports": [
                                {
                                    "Id": "INV",
                                    "Name": "Inverness",
                                    "CityId": "INVE",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-4.048333, 57.5425"
                                }
                            ]
                        },
                        {
                            "Id": "LOND",
                            "Name": "London",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.094346534, 51.504117",
                            "IataCode": "LON",
                            "Airports": [
                                {
                                    "Id": "LCY",
                                    "Name": "London City",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.048512, 51.503753"
                                },
                                {
                                    "Id": "LHR",
                                    "Name": "London Heathrow",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.452778, 51.471389"
                                },
                                {
                                    "Id": "LGW",
                                    "Name": "London Gatwick",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.196667, 51.148056"
                                },
                                {
                                    "Id": "STN",
                                    "Name": "London Stansted",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.233333, 51.883333"
                                },
                                {
                                    "Id": "LTN",
                                    "Name": "London Luton",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.375705, 51.878704"
                                },
                                {
                                    "Id": "SEN",
                                    "Name": "London Southend",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.6922722, 51.57017"
                                },
                                {
                                    "Id": "QQS",
                                    "Name": "London St. Pancras International",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.117222, 51.517222"
                                },
                                {
                                    "Id": "XQE",
                                    "Name": "Ebbsfleet International Rail Station",
                                    "CityId": "LOND",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.320883, 51.443039"
                                }
                            ]
                        },
                        {
                            "Id": "DERR",
                            "Name": "Derry",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "N_IRE",
                            "Location": "-7.3093415, 54.9981",
                            "IataCode": "LDY",
                            "Airports": [
                                {
                                    "Id": "LDY",
                                    "Name": "Derry",
                                    "CityId": "DERR",
                                    "CountryId": "UK",
                                    "RegionId": "N_IRE",
                                    "Location": "-7.153333, 55.043333"
                                }
                            ]
                        },
                        {
                            "Id": "LIVE",
                            "Name": "Liverpool",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-3, 53.416669",
                            "IataCode": "LPL",
                            "Airports": [
                                {
                                    "Id": "LPL",
                                    "Name": "Liverpool",
                                    "CityId": "LIVE",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.8551011, 53.335831"
                                }
                            ]
                        },
                        {
                            "Id": "SUMB",
                            "Name": "Sumburgh",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-1.2833334, 59.866667",
                            "IataCode": "SDZ",
                            "Airports": [
                                {
                                    "Id": "LWK",
                                    "Name": "Sumburgh Lerwick / Tingwall",
                                    "CityId": "SUMB",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-1.243611, 60.191944"
                                },
                                {
                                    "Id": "LSI",
                                    "Name": "Sumburgh Shetland",
                                    "CityId": "SUMB",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-1.293056, 59.881111"
                                },
                                {
                                    "Id": "SCS",
                                    "Name": "Sumburgh Scatsta",
                                    "CityId": "SUMB",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-1.133333, 60.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LYXA",
                            "Name": "Lydd",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.9166668, 50.95",
                            "IataCode": "LYX",
                            "Airports": [
                                {
                                    "Id": "LYX",
                                    "Name": "Lydd",
                                    "CityId": "LYXA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.933333, 50.95"
                                }
                            ]
                        },
                        {
                            "Id": "MANC",
                            "Name": "Manchester",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.2374347, 53.480946",
                            "IataCode": "MAN",
                            "Airports": [
                                {
                                    "Id": "MAN",
                                    "Name": "Manchester",
                                    "CityId": "MANC",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.272687, 53.358812"
                                }
                            ]
                        },
                        {
                            "Id": "MHZA",
                            "Name": "Mildenhall",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.51666683, 52.35",
                            "IataCode": "MHZ",
                            "Airports": [
                                {
                                    "Id": "MHZ",
                                    "Name": "Mildenhall Arpt",
                                    "CityId": "MHZA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.483333, 52.366667"
                                }
                            ]
                        },
                        {
                            "Id": "NEWC",
                            "Name": "Newcastle",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.6139608, 54.973279",
                            "IataCode": "NCL",
                            "Airports": [
                                {
                                    "Id": "NCL",
                                    "Name": "Newcastle",
                                    "CityId": "NEWC",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.686667, 55.037778"
                                }
                            ]
                        },
                        {
                            "Id": "NHTA",
                            "Name": "Northolt",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.36778463, 51.54855",
                            "IataCode": "NHT",
                            "Airports": [
                                {
                                    "Id": "NHT",
                                    "Name": "Northolt",
                                    "CityId": "NHTA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.41817, 51.553"
                                }
                            ]
                        },
                        {
                            "Id": "NEWQ",
                            "Name": "Newquay",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-5.0818633, 50.413167",
                            "IataCode": "NQY",
                            "Airports": [
                                {
                                    "Id": "NQY",
                                    "Name": "Newquay",
                                    "CityId": "NEWQ",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-5.008674, 50.439964"
                                }
                            ]
                        },
                        {
                            "Id": "NORW",
                            "Name": "Norwich",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "1.2983399, 52.627827",
                            "IataCode": "NWI",
                            "Airports": [
                                {
                                    "Id": "NWI",
                                    "Name": "Norwich",
                                    "CityId": "NORW",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "1.2836748, 52.675584"
                                }
                            ]
                        },
                        {
                            "Id": "ORMA",
                            "Name": "Northampton",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.88333348, 52.250001",
                            "IataCode": "ORM",
                            "Airports": [
                                {
                                    "Id": "ORM",
                                    "Name": "Northampton",
                                    "CityId": "ORMA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.9, 52.233333"
                                }
                            ]
                        },
                        {
                            "Id": "PLYM",
                            "Name": "Plymouth",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-4.1430467, 50.371525",
                            "IataCode": "PLH",
                            "Airports": [
                                {
                                    "Id": "PLH",
                                    "Name": "Plymouth",
                                    "CityId": "PLYM",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-4.11026, 50.423394"
                                }
                            ]
                        },
                        {
                            "Id": "RCSA",
                            "Name": "Rochester",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.50545704, 51.387638",
                            "IataCode": "RCS",
                            "Airports": [
                                {
                                    "Id": "RCS",
                                    "Name": "Rochester",
                                    "CityId": "RCSA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.5, 51.35"
                                }
                            ]
                        },
                        {
                            "Id": "SEND",
                            "Name": "Southend",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.71432646, 51.537821",
                            "Airports": []
                        },
                        {
                            "Id": "SWAN",
                            "Name": "Swansea",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "WALES",
                            "Location": "-3.9432334, 51.620789",
                            "IataCode": "SWS",
                            "Airports": [
                                {
                                    "Id": "SWS",
                                    "Name": "Swansea",
                                    "CityId": "SWAN",
                                    "CountryId": "UK",
                                    "RegionId": "WALES",
                                    "Location": "-3.95, 51.633333"
                                }
                            ]
                        },
                        {
                            "Id": "SOUT",
                            "Name": "Southampton",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.4000002, 50.900002",
                            "IataCode": "SOU",
                            "Airports": [
                                {
                                    "Id": "SOU",
                                    "Name": "Southampton",
                                    "CityId": "SOUT",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.351944, 50.951667"
                                }
                            ]
                        },
                        {
                            "Id": "SOYA",
                            "Name": "Stronsay",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.60954, 59.11851",
                            "IataCode": "SOY",
                            "Airports": [
                                {
                                    "Id": "SOY",
                                    "Name": "Stronsay",
                                    "CityId": "SOYA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.641869, 59.156484"
                                }
                            ]
                        },
                        {
                            "Id": "STOR",
                            "Name": "Stornoway",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.3666669, 58.216667",
                            "IataCode": "SYY",
                            "Airports": [
                                {
                                    "Id": "SYY",
                                    "Name": "Stornoway",
                                    "CityId": "STOR",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.329167, 58.216111"
                                }
                            ]
                        },
                        {
                            "Id": "SZDA",
                            "Name": "Sheffield",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.465903, 53.38297",
                            "Airports": []
                        },
                        {
                            "Id": "ADVA",
                            "Name": "Andover",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.4833334, 51.2",
                            "IataCode": "ADV",
                            "Airports": [
                                {
                                    "Id": "ADV",
                                    "Name": "Andover",
                                    "CityId": "ADVA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.466667, 51.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BBPA",
                            "Name": "Bembridge",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.0833334, 50.683333",
                            "IataCode": "BBP",
                            "Airports": [
                                {
                                    "Id": "BBP",
                                    "Name": "Bembridge",
                                    "CityId": "BBPA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.083333, 50.683333"
                                }
                            ]
                        },
                        {
                            "Id": "NOTT",
                            "Name": "Nottingham",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.1504757, 52.953602",
                            "IataCode": "NQT",
                            "Airports": [
                                {
                                    "Id": "EMA",
                                    "Name": "East Midlands",
                                    "CityId": "NOTT",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.3321769, 52.829374"
                                },
                                {
                                    "Id": "NQT",
                                    "Name": "Nottingham",
                                    "CityId": "NOTT",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.079063, 52.921737"
                                }
                            ]
                        },
                        {
                            "Id": "OBNA",
                            "Name": "Oban",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-5.4666665, 56.416667",
                            "IataCode": "OBN",
                            "Airports": [
                                {
                                    "Id": "OBN",
                                    "Name": "Oban Connel",
                                    "CityId": "OBNA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-5.399667, 56.4635"
                                },
                                {
                                    "Id": "OHP",
                                    "Name": "Oban Heliport",
                                    "CityId": "OBNA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-5.483333, 56.416667"
                                }
                            ]
                        },
                        {
                            "Id": "PSLA",
                            "Name": "Perth",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.4333332, 56.4",
                            "IataCode": "PSL",
                            "Airports": [
                                {
                                    "Id": "PSL",
                                    "Name": "Perth",
                                    "CityId": "PSLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.36667, 56.45"
                                }
                            ]
                        },
                        {
                            "Id": "PENA",
                            "Name": "Penzance",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-5.5371521, 50.118611",
                            "IataCode": "PZE",
                            "Airports": [
                                {
                                    "Id": "PZE",
                                    "Name": "Penzance",
                                    "CityId": "PENA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-5.516111, 50.111944"
                                }
                            ]
                        },
                        {
                            "Id": "SWIA",
                            "Name": "Swindon",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.7833334, 51.516667",
                            "IataCode": "SWI",
                            "Airports": [
                                {
                                    "Id": "SWI",
                                    "Name": "Swindon",
                                    "CityId": "SWIA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.783333, 51.566667"
                                }
                            ]
                        },
                        {
                            "Id": "LPHA",
                            "Name": "Lochgilphead",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-5.4166665, 56.033333",
                            "IataCode": "LPH",
                            "Airports": [
                                {
                                    "Id": "LPH",
                                    "Name": "Lochgilphead",
                                    "CityId": "LPHA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-5.433333, 56.05"
                                }
                            ]
                        },
                        {
                            "Id": "PMEA",
                            "Name": "Portsmouth",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.0714251, 50.809081",
                            "IataCode": "PME",
                            "Airports": [
                                {
                                    "Id": "PME",
                                    "Name": "Portsmouth",
                                    "CityId": "PMEA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.083333, 50.8"
                                }
                            ]
                        },
                        {
                            "Id": "FEAA",
                            "Name": "Fetlar",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-0.86170019, 60.59425",
                            "IataCode": "FEA",
                            "Airports": [
                                {
                                    "Id": "FEA",
                                    "Name": "Fetlar",
                                    "CityId": "FEAA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-0.966667, 60.583333"
                                }
                            ]
                        },
                        {
                            "Id": "JERS",
                            "Name": "Jersey",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "Location": "-2.1362674, 49.211629",
                            "IataCode": "JER",
                            "Airports": [
                                {
                                    "Id": "JER",
                                    "Name": "Jersey",
                                    "CityId": "JERS",
                                    "CountryId": "UK",
                                    "Location": "-2.216667, 49.211111"
                                }
                            ]
                        },
                        {
                            "Id": "HLYA",
                            "Name": "Holyhead",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "WALES",
                            "Location": "-4.6321105, 53.306211",
                            "IataCode": "HLY",
                            "Airports": [
                                {
                                    "Id": "HLY",
                                    "Name": "Holyhead",
                                    "CityId": "HLYA",
                                    "CountryId": "UK",
                                    "RegionId": "WALES",
                                    "Location": "-4.633333, 53.3"
                                }
                            ]
                        },
                        {
                            "Id": "IPWA",
                            "Name": "Ipswich",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "1.1569988, 52.054654",
                            "IataCode": "IPW",
                            "Airports": [
                                {
                                    "Id": "IPW",
                                    "Name": "Ipswich",
                                    "CityId": "IPWA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "1.166667, 52.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BRIG",
                            "Name": "Brighton",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.13947523, 50.828387",
                            "Airports": []
                        },
                        {
                            "Id": "RAMT",
                            "Name": "Ramsgate",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "1.4333334, 51.333333",
                            "Airports": []
                        },
                        {
                            "Id": "BRFA",
                            "Name": "Bradford",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.7500002, 53.783337",
                            "IataCode": "BRF",
                            "Airports": [
                                {
                                    "Id": "BRF",
                                    "Name": "Bradford",
                                    "CityId": "BRFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.75, 53.8"
                                }
                            ]
                        },
                        {
                            "Id": "MMEA",
                            "Name": "Durham",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.5666669, 54.766667",
                            "IataCode": "MME",
                            "Airports": [
                                {
                                    "Id": "MME",
                                    "Name": "Teesside International",
                                    "CityId": "MMEA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.416667, 54.516667"
                                }
                            ]
                        },
                        {
                            "Id": "LEED",
                            "Name": "Leeds",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.5478493, 53.796483",
                            "IataCode": "LBA",
                            "Airports": [
                                {
                                    "Id": "LBA",
                                    "Name": "Leeds Bradford",
                                    "CityId": "LEED",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.6547, 53.8653"
                                }
                            ]
                        },
                        {
                            "Id": "GLOA",
                            "Name": "Gloucester",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.2430993, 51.865682",
                            "IataCode": "GLO",
                            "Airports": [
                                {
                                    "Id": "GLO",
                                    "Name": "Gloucestershire",
                                    "CityId": "GLOA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.167222, 51.894167"
                                }
                            ]
                        },
                        {
                            "Id": "KYNA",
                            "Name": "Milton Keynes",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.75582576, 52.041722",
                            "IataCode": "KYN",
                            "Airports": [
                                {
                                    "Id": "KYN",
                                    "Name": "Milton Keynes",
                                    "CityId": "KYNA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.7, 52.033333"
                                }
                            ]
                        },
                        {
                            "Id": "EWYA",
                            "Name": "Newbury",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.3166668, 51.4",
                            "IataCode": "EWY",
                            "Airports": [
                                {
                                    "Id": "EWY",
                                    "Name": "Newbury",
                                    "CityId": "EWYA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.333333, 51.4"
                                }
                            ]
                        },
                        {
                            "Id": "KNFA",
                            "Name": "Kings Lynn",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.39640918, 52.752425",
                            "IataCode": "KNF",
                            "Airports": [
                                {
                                    "Id": "KNF",
                                    "Name": "Kings Lynn",
                                    "CityId": "KNFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.4, 52.75"
                                }
                            ]
                        },
                        {
                            "Id": "DONC",
                            "Name": "Doncaster",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.1166672, 53.533333",
                            "IataCode": "DSA",
                            "Airports": [
                                {
                                    "Id": "DSA",
                                    "Name": "Doncaster Sheffield",
                                    "CityId": "DONC",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.003889, 53.475278"
                                }
                            ]
                        },
                        {
                            "Id": "HYCA",
                            "Name": "High Wycombe",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.7666671, 51.633333",
                            "IataCode": "HYC",
                            "Airports": [
                                {
                                    "Id": "HYC",
                                    "Name": "High Wycombe",
                                    "CityId": "HYCA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.75, 51.616667"
                                }
                            ]
                        },
                        {
                            "Id": "QDHA",
                            "Name": "Ashford",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.88333376, 51.133333",
                            "IataCode": "QDH",
                            "Airports": [
                                {
                                    "Id": "QDH",
                                    "Name": "Ashford International Rail Station",
                                    "CityId": "QDHA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.876, 51.1436"
                                }
                            ]
                        },
                        {
                            "Id": "BWYA",
                            "Name": "Woodbridge",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "1.3204194, 52.093324",
                            "IataCode": "BWY",
                            "Airports": [
                                {
                                    "Id": "BWY",
                                    "Name": "Woodbridge",
                                    "CityId": "BWYA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "1.316667, 52.1"
                                }
                            ]
                        },
                        {
                            "Id": "LKZA",
                            "Name": "Brandon",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.61666683, 52.45",
                            "IataCode": "LKZ",
                            "Airports": [
                                {
                                    "Id": "LKZ",
                                    "Name": "Brandon",
                                    "CityId": "LKZA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.561, 52.40933"
                                }
                            ]
                        },
                        {
                            "Id": "HTFA",
                            "Name": "Hatfield",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.2241899, 51.763378",
                            "IataCode": "HTF",
                            "Airports": [
                                {
                                    "Id": "HTF",
                                    "Name": "Hatfield",
                                    "CityId": "HTFA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.248333, 51.765"
                                }
                            ]
                        },
                        {
                            "Id": "WFDA",
                            "Name": "Woodford",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.1666667, 53.333333",
                            "IataCode": "WFD",
                            "Airports": [
                                {
                                    "Id": "WFD",
                                    "Name": "Woodford",
                                    "CityId": "WFDA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.148889, 53.338056"
                                }
                            ]
                        },
                        {
                            "Id": "ALDE",
                            "Name": "Alderney",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "Location": "-2.19958, 49.7136",
                            "IataCode": "ACI",
                            "Airports": [
                                {
                                    "Id": "ACI",
                                    "Name": "Alderney",
                                    "CityId": "ALDE",
                                    "CountryId": "UK",
                                    "Location": "-2.215278, 49.706944"
                                }
                            ]
                        },
                        {
                            "Id": "WEMA",
                            "Name": "West Malling",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.41666683, 51.283333",
                            "Airports": []
                        },
                        {
                            "Id": "FKHA",
                            "Name": "Fakenham",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.83333343, 52.833333",
                            "IataCode": "FKH",
                            "Airports": [
                                {
                                    "Id": "FKH",
                                    "Name": "Fakenham",
                                    "CityId": "FKHA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.85, 52.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BEQA",
                            "Name": "Bury St Edmunds",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.71110746, 52.246301",
                            "IataCode": "BEQ",
                            "Airports": [
                                {
                                    "Id": "BEQ",
                                    "Name": "Bury St Edmunds",
                                    "CityId": "BEQA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "0.766667, 52.35"
                                }
                            ]
                        },
                        {
                            "Id": "OUKA",
                            "Name": "Outer Skerries",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-1.2711485, 60.360755",
                            "IataCode": "OUK",
                            "Airports": [
                                {
                                    "Id": "OUK",
                                    "Name": "Outer Skerries",
                                    "CityId": "OUKA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-0.75, 60.416667"
                                }
                            ]
                        },
                        {
                            "Id": "UPVA",
                            "Name": "Upavon",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.8166668, 51.283333",
                            "IataCode": "UPV",
                            "Airports": [
                                {
                                    "Id": "UPV",
                                    "Name": "Upavon",
                                    "CityId": "UPVA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.816667, 51.3"
                                }
                            ]
                        },
                        {
                            "Id": "MSEA",
                            "Name": "Manston",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "1.3666668, 51.35",
                            "IataCode": "MSE",
                            "Airports": [
                                {
                                    "Id": "MSE",
                                    "Name": "Kent International Airport (Manston)",
                                    "CityId": "MSEA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "1.35, 51.35"
                                }
                            ]
                        },
                        {
                            "Id": "HENA",
                            "Name": "Hendon",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.21666684, 51.6",
                            "Airports": []
                        },
                        {
                            "Id": "BZZA",
                            "Name": "Brize Norton",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-1.5666668, 51.766667",
                            "IataCode": "BZZ",
                            "Airports": [
                                {
                                    "Id": "BZZ",
                                    "Name": "RAF Brize Norton",
                                    "CityId": "BZZA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-1.566582, 51.76614"
                                }
                            ]
                        },
                        {
                            "Id": "BOLA",
                            "Name": "Bally Kelly",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "N_IRE",
                            "Location": "-7.0166668, 55.05",
                            "IataCode": "BOL",
                            "Airports": [
                                {
                                    "Id": "BOL",
                                    "Name": "Bally Kelly",
                                    "CityId": "BOLA",
                                    "CountryId": "UK",
                                    "RegionId": "N_IRE",
                                    "Location": "-7, 55"
                                }
                            ]
                        },
                        {
                            "Id": "CAST",
                            "Name": "Castletown",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "Location": "-4.6499999, 54.083333",
                            "IataCode": "IOM",
                            "Airports": [
                                {
                                    "Id": "IOM",
                                    "Name": "Ronaldsway",
                                    "CityId": "CAST",
                                    "CountryId": "UK",
                                    "Location": "-4.627778, 54.0825"
                                }
                            ]
                        },
                        {
                            "Id": "ORKN",
                            "Name": "Orkney",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3, 59",
                            "IataCode": "KOI",
                            "Airports": [
                                {
                                    "Id": "KOI",
                                    "Name": "Orkney Kirkwall",
                                    "CityId": "ORKN",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.966667, 58.958611"
                                }
                            ]
                        },
                        {
                            "Id": "ANGA",
                            "Name": "Anglesey",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "WALES",
                            "Location": "-4.333333, 53.283333",
                            "IataCode": "VLY",
                            "Airports": [
                                {
                                    "Id": "VLY",
                                    "Name": "Anglesey",
                                    "CityId": "ANGA",
                                    "CountryId": "UK",
                                    "RegionId": "WALES",
                                    "Location": "-4.54, 53.25"
                                }
                            ]
                        },
                        {
                            "Id": "SCIL",
                            "Name": "Isles Of Scilly",
                            "SingleAirportCity": false,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-6.31688, 49.91542",
                            "IataCode": "ISC",
                            "Airports": [
                                {
                                    "Id": "ISC",
                                    "Name": "Isles of Scilly St. Mary's",
                                    "CityId": "SCIL",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-6.294539, 49.914936"
                                },
                                {
                                    "Id": "TSO",
                                    "Name": "Isles Of Scilly Tresco",
                                    "CityId": "SCIL",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-6.3314, 49.9456"
                                }
                            ]
                        },
                        {
                            "Id": "FZOA",
                            "Name": "Filton",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-2.5717449, 51.509704",
                            "IataCode": "FZO",
                            "Airports": [
                                {
                                    "Id": "FZO",
                                    "Name": "Filton",
                                    "CityId": "FZOA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-2.583333, 51.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BBSA",
                            "Name": "Blackbushes",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.83333341, 51.316667",
                            "IataCode": "BBS",
                            "Airports": [
                                {
                                    "Id": "BBS",
                                    "Name": "Blackbushe",
                                    "CityId": "BBSA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.8475, 51.3239"
                                }
                            ]
                        },
                        {
                            "Id": "HUMB",
                            "Name": "Sutton on Sea",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.28333345, 53.316667",
                            "IataCode": "HUY",
                            "Airports": [
                                {
                                    "Id": "HUY",
                                    "Name": "Humberside",
                                    "CityId": "HUMB",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.35, 53.583333"
                                }
                            ]
                        },
                        {
                            "Id": "EOIA",
                            "Name": "Whitehall",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.6166667, 59.15",
                            "IataCode": "EOI",
                            "Airports": [
                                {
                                    "Id": "EOI",
                                    "Name": "Eday",
                                    "CityId": "EOIA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.769842, 59.19123"
                                }
                            ]
                        },
                        {
                            "Id": "CSAA",
                            "Name": "Upper Kilchattan",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.2166669, 56.066667",
                            "IataCode": "CSA",
                            "Airports": [
                                {
                                    "Id": "CSA",
                                    "Name": "Colonsay Is",
                                    "CityId": "CSAA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.2, 56.083333"
                                }
                            ]
                        },
                        {
                            "Id": "FIEA",
                            "Name": "Toab",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-1.3166668, 59.883333",
                            "IataCode": "FIE",
                            "Airports": [
                                {
                                    "Id": "FIE",
                                    "Name": "Fair Isle",
                                    "CityId": "FIEA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-1.666667, 59.5"
                                }
                            ]
                        },
                        {
                            "Id": "FLHA",
                            "Name": "Staxigoe",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.0666667, 58.466667",
                            "IataCode": "FLH",
                            "Airports": [
                                {
                                    "Id": "FLH",
                                    "Name": "Flotta",
                                    "CityId": "FLHA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3, 58.5"
                                }
                            ]
                        },
                        {
                            "Id": "TTKA",
                            "Name": "Southgate",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-0.10000015, 51.616667",
                            "IataCode": "TTK",
                            "Airports": [
                                {
                                    "Id": "TTK",
                                    "Name": "Tottenham Hale Station",
                                    "CityId": "TTKA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-0.083333, 51.583333"
                                }
                            ]
                        },
                        {
                            "Id": "LEQA",
                            "Name": "Sennen",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "-5.670556, 50.102778",
                            "IataCode": "LEQ",
                            "Airports": [
                                {
                                    "Id": "LEQ",
                                    "Name": "Lands End",
                                    "CityId": "LEQA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "-5.670556, 50.102778"
                                }
                            ]
                        },
                        {
                            "Id": "PSVA",
                            "Name": "Sandness",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-1.6333334, 60.283333",
                            "IataCode": "PSV",
                            "Airports": [
                                {
                                    "Id": "PSV",
                                    "Name": "Papa Stour",
                                    "CityId": "PSVA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-1.7, 60.316667"
                                }
                            ]
                        },
                        {
                            "Id": "WOBA",
                            "Name": "Raydon",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "ENGLA",
                            "Location": "0.98333341, 52.016667",
                            "IataCode": "WOB",
                            "Airports": [
                                {
                                    "Id": "WOB",
                                    "Name": "Suttonheath",
                                    "CityId": "WOBA",
                                    "CountryId": "UK",
                                    "RegionId": "ENGLA",
                                    "Location": "1, 52"
                                }
                            ]
                        },
                        {
                            "Id": "PPWA",
                            "Name": "Pierowall",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.95, 59.35",
                            "IataCode": "PPW",
                            "Airports": [
                                {
                                    "Id": "PPW",
                                    "Name": "Papa Westray",
                                    "CityId": "PPWA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.900278, 59.351667"
                                }
                            ]
                        },
                        {
                            "Id": "SKLA",
                            "Name": "Mugeary",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.2666669, 57.383333",
                            "IataCode": "SKL",
                            "Airports": [
                                {
                                    "Id": "SKL",
                                    "Name": "Isle Of Skye",
                                    "CityId": "SKLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.25, 57.333333"
                                }
                            ]
                        },
                        {
                            "Id": "HOYA",
                            "Name": "Melsetter",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.2666667, 58.783333",
                            "IataCode": "HOY",
                            "Airports": [
                                {
                                    "Id": "HOY",
                                    "Name": "Hoy Island",
                                    "CityId": "HOYA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.3, 58.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ULLA",
                            "Name": "Knock",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-5.9833331, 56.466667",
                            "IataCode": "ULL",
                            "Airports": [
                                {
                                    "Id": "ULL",
                                    "Name": "Mull",
                                    "CityId": "ULLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6, 56.45"
                                }
                            ]
                        },
                        {
                            "Id": "NRLA",
                            "Name": "Kettletoft",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.583333, 59.25",
                            "IataCode": "NRL",
                            "Airports": [
                                {
                                    "Id": "NRL",
                                    "Name": "North Ronaldsay",
                                    "CityId": "NRLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.433333, 59.370278"
                                }
                            ]
                        },
                        {
                            "Id": "FOAA",
                            "Name": "Ham",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.083333, 60.166667",
                            "IataCode": "FOA",
                            "Airports": [
                                {
                                    "Id": "FOA",
                                    "Name": "Foula",
                                    "CityId": "FOAA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.083333, 60.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TIRE",
                            "Name": "Fidden",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.871389, 56.500556",
                            "IataCode": "TRE",
                            "Airports": [
                                {
                                    "Id": "TRE",
                                    "Name": "Tiree",
                                    "CityId": "TIRE",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.871389, 56.500556"
                                }
                            ]
                        },
                        {
                            "Id": "BARR",
                            "Name": "Ersary",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-7.440278, 57.023333",
                            "IataCode": "BRR",
                            "Airports": [
                                {
                                    "Id": "BRR",
                                    "Name": "Barra",
                                    "CityId": "BARR",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-7.440278, 57.023333"
                                }
                            ]
                        },
                        {
                            "Id": "BENB",
                            "Name": "Creagorry",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-7.364722, 57.482222",
                            "IataCode": "BEB",
                            "Airports": [
                                {
                                    "Id": "BEB",
                                    "Name": "Benbecula",
                                    "CityId": "BENB",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-7.364722, 57.482222"
                                }
                            ]
                        },
                        {
                            "Id": "COLA",
                            "Name": "Clabhach",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.616667, 56.616667",
                            "IataCode": "COL",
                            "Airports": [
                                {
                                    "Id": "COL",
                                    "Name": "Coll Island",
                                    "CityId": "COLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.616667, 56.616667"
                                }
                            ]
                        },
                        {
                            "Id": "WHSA",
                            "Name": "Burravoe",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-1.0500002, 60.5",
                            "IataCode": "WHS",
                            "Airports": [
                                {
                                    "Id": "WHS",
                                    "Name": "Whalsay",
                                    "CityId": "WHSA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-0.92632, 60.37856"
                                }
                            ]
                        },
                        {
                            "Id": "ISLA",
                            "Name": "Barr",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-6.2542, 55.6819",
                            "IataCode": "ILY",
                            "Airports": [
                                {
                                    "Id": "ILY",
                                    "Name": "Islay Glenegedale",
                                    "CityId": "ISLA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-6.2542, 55.6819"
                                }
                            ]
                        },
                        {
                            "Id": "UNTA",
                            "Name": "Baltasound",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-0.86666689, 60.75",
                            "IataCode": "UNT",
                            "Airports": [
                                {
                                    "Id": "UNT",
                                    "Name": "Unst Shetland Is",
                                    "CityId": "UNTA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-0.85, 60.766667"
                                }
                            ]
                        },
                        {
                            "Id": "RAYA",
                            "Name": "Airdrie",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.9833332, 55.85",
                            "IataCode": "RAY",
                            "Airports": [
                                {
                                    "Id": "RAY",
                                    "Name": "Rothesay",
                                    "CityId": "RAYA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-3.966667, 55.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WRYA",
                            "Name": "Redland",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-3.0666667, 59.1",
                            "IataCode": "WRY",
                            "Airports": [
                                {
                                    "Id": "WRY",
                                    "Name": "Westray",
                                    "CityId": "WRYA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.95, 59.35"
                                }
                            ]
                        },
                        {
                            "Id": "NDYA",
                            "Name": "Templehall",
                            "SingleAirportCity": true,
                            "CountryId": "UK",
                            "RegionId": "SCOTL",
                            "Location": "-2.56061, 59.25477",
                            "IataCode": "NDY",
                            "Airports": [
                                {
                                    "Id": "NDY",
                                    "Name": "Sanday",
                                    "CityId": "NDYA",
                                    "CountryId": "UK",
                                    "RegionId": "SCOTL",
                                    "Location": "-2.583333, 59.25"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BY",
                    "Name": "Belarus",
                    "CurrencyId": "BYR",
                    "Cities": [
                        {
                            "Id": "BQTA",
                            "Name": "Brest",
                            "SingleAirportCity": true,
                            "CountryId": "BY",
                            "Location": "23.699999, 52.1",
                            "IataCode": "BQT",
                            "Airports": [
                                {
                                    "Id": "BQT",
                                    "Name": "Brest",
                                    "CityId": "BQTA",
                                    "CountryId": "BY",
                                    "Location": "23.883333, 52.116667"
                                }
                            ]
                        },
                        {
                            "Id": "GMEA",
                            "Name": "Gomel",
                            "SingleAirportCity": true,
                            "CountryId": "BY",
                            "Location": "30.983334, 52.441669",
                            "IataCode": "GME",
                            "Airports": [
                                {
                                    "Id": "GME",
                                    "Name": "Gomel",
                                    "CityId": "GMEA",
                                    "CountryId": "BY",
                                    "Location": "31.016667, 52.526944"
                                }
                            ]
                        },
                        {
                            "Id": "GNAA",
                            "Name": "Grodna",
                            "SingleAirportCity": true,
                            "CountryId": "BY",
                            "Location": "23.814722, 53.681389",
                            "IataCode": "GNA",
                            "Airports": [
                                {
                                    "Id": "GNA",
                                    "Name": "Grodna",
                                    "CityId": "GNAA",
                                    "CountryId": "BY",
                                    "Location": "24.055, 53.601667"
                                }
                            ]
                        },
                        {
                            "Id": "MSQA",
                            "Name": "Minsk",
                            "SingleAirportCity": false,
                            "CountryId": "BY",
                            "Location": "27.566667, 53.900005",
                            "IataCode": "MSQ",
                            "Airports": [
                                {
                                    "Id": "MSQ",
                                    "Name": "Minsk International 2",
                                    "CityId": "MSQA",
                                    "CountryId": "BY",
                                    "Location": "28.03073, 53.88247"
                                },
                                {
                                    "Id": "MHP",
                                    "Name": "Minsk International 1",
                                    "CityId": "MSQA",
                                    "CountryId": "BY",
                                    "Location": "27.55, 53.85"
                                }
                            ]
                        },
                        {
                            "Id": "MVQA",
                            "Name": "Mogilev",
                            "SingleAirportCity": true,
                            "CountryId": "BY",
                            "Location": "30.336389, 53.91389",
                            "IataCode": "MVQ",
                            "Airports": [
                                {
                                    "Id": "MVQ",
                                    "Name": "Mogilev",
                                    "CityId": "MVQA",
                                    "CountryId": "BY",
                                    "Location": "30.133333, 53.95"
                                }
                            ]
                        },
                        {
                            "Id": "VTBA",
                            "Name": "Vitebsk",
                            "SingleAirportCity": true,
                            "CountryId": "BY",
                            "Location": "30.194445, 55.192501",
                            "IataCode": "VTB",
                            "Airports": [
                                {
                                    "Id": "VTB",
                                    "Name": "Vitebsk",
                                    "CityId": "VTBA",
                                    "CountryId": "BY",
                                    "Location": "30.366667, 55.133333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SJ",
                    "Name": "Svalbard and Jan Mayen Islands",
                    "CurrencyId": "NOK",
                    "Cities": []
                },
                {
                    "Id": "SI",
                    "Name": "Slovenia",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "LJUB",
                            "Name": "Ljubljana",
                            "SingleAirportCity": true,
                            "CountryId": "SI",
                            "Location": "14.514444, 46.05528",
                            "IataCode": "LJU",
                            "Airports": [
                                {
                                    "Id": "LJU",
                                    "Name": "Ljubljana",
                                    "CityId": "LJUB",
                                    "CountryId": "SI",
                                    "Location": "14.460833, 46.224722"
                                }
                            ]
                        },
                        {
                            "Id": "MARI",
                            "Name": "Maribor",
                            "SingleAirportCity": true,
                            "CountryId": "SI",
                            "Location": "15.646667, 46.554722",
                            "IataCode": "MBX",
                            "Airports": [
                                {
                                    "Id": "MBX",
                                    "Name": "Maribor",
                                    "CityId": "MARI",
                                    "CountryId": "SI",
                                    "Location": "15.686, 46.479"
                                }
                            ]
                        },
                        {
                            "Id": "PORV",
                            "Name": "Portoroz",
                            "SingleAirportCity": true,
                            "CountryId": "SI",
                            "Location": "13.588333, 45.516111",
                            "IataCode": "POW",
                            "Airports": [
                                {
                                    "Id": "POW",
                                    "Name": "Portoroz",
                                    "CityId": "PORV",
                                    "CountryId": "SI",
                                    "Location": "13.615, 45.473333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CZ",
                    "Name": "Czechia",
                    "CurrencyId": "CZK",
                    "LanguageId": "CZ",
                    "Cities": [
                        {
                            "Id": "BRNO",
                            "Name": "Brno",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "16.607959, 49.195223",
                            "IataCode": "BRQ",
                            "Airports": [
                                {
                                    "Id": "BRQ",
                                    "Name": "Brno-Turany",
                                    "CityId": "BRNO",
                                    "CountryId": "CZ",
                                    "Location": "16.687202, 49.152745"
                                }
                            ]
                        },
                        {
                            "Id": "KARN",
                            "Name": "Karlovy Vary",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "12.87117, 50.232713",
                            "IataCode": "KLV",
                            "Airports": [
                                {
                                    "Id": "KLV",
                                    "Name": "Karlovy Vary",
                                    "CityId": "KARN",
                                    "CountryId": "CZ",
                                    "Location": "12.916667, 50.2"
                                }
                            ]
                        },
                        {
                            "Id": "MKAA",
                            "Name": "Marianske Lazne",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "12.701183, 49.964587",
                            "IataCode": "MKA",
                            "Airports": [
                                {
                                    "Id": "MKA",
                                    "Name": "Marianske Lazne",
                                    "CityId": "MKAA",
                                    "CountryId": "CZ",
                                    "Location": "12.716667, 49.983333"
                                }
                            ]
                        },
                        {
                            "Id": "OSTR",
                            "Name": "Ostrava",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "18.282045, 49.834645",
                            "IataCode": "OSR",
                            "Airports": [
                                {
                                    "Id": "OSR",
                                    "Name": "Ostrava",
                                    "CityId": "OSTR",
                                    "CountryId": "CZ",
                                    "Location": "18.133333, 49.7"
                                }
                            ]
                        },
                        {
                            "Id": "PEDA",
                            "Name": "Pardubice",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "15.77659, 50.040751",
                            "IataCode": "PED",
                            "Airports": [
                                {
                                    "Id": "PED",
                                    "Name": "Pardubice",
                                    "CityId": "PEDA",
                                    "CountryId": "CZ",
                                    "Location": "15.738611, 50.013333"
                                }
                            ]
                        },
                        {
                            "Id": "PRAG",
                            "Name": "Prague",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "14.451394, 50.062378",
                            "IataCode": "PRG",
                            "Airports": [
                                {
                                    "Id": "PRG",
                                    "Name": "Prague",
                                    "CityId": "PRAG",
                                    "CountryId": "CZ",
                                    "Location": "14.263127, 50.101743"
                                }
                            ]
                        },
                        {
                            "Id": "UHEA",
                            "Name": "Uherske Hradiste",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "17.459685, 49.06975",
                            "IataCode": "UHE",
                            "Airports": [
                                {
                                    "Id": "UHE",
                                    "Name": "Uherske Hradiste",
                                    "CityId": "UHEA",
                                    "CountryId": "CZ",
                                    "Location": "17.45, 49.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OLOA",
                            "Name": "Olomouc",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "17.251753, 49.595524",
                            "IataCode": "OLO",
                            "Airports": [
                                {
                                    "Id": "OLO",
                                    "Name": "Olomouc",
                                    "CityId": "OLOA",
                                    "CountryId": "CZ",
                                    "Location": "17.2, 49.633333"
                                }
                            ]
                        },
                        {
                            "Id": "PRVA",
                            "Name": "Prerov",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "17.450902, 49.455109",
                            "IataCode": "PRV",
                            "Airports": [
                                {
                                    "Id": "PRV",
                                    "Name": "Prerov",
                                    "CityId": "PRVA",
                                    "CountryId": "CZ",
                                    "Location": "17.208333, 49.590278"
                                }
                            ]
                        },
                        {
                            "Id": "GTWA",
                            "Name": "Zlin",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "17.666666, 49.216667",
                            "IataCode": "GTW",
                            "Airports": [
                                {
                                    "Id": "GTW",
                                    "Name": "Zlin",
                                    "CityId": "GTWA",
                                    "CountryId": "CZ",
                                    "Location": "17.516667, 49.2"
                                }
                            ]
                        },
                        {
                            "Id": "ZBEA",
                            "Name": "Zabreh",
                            "SingleAirportCity": true,
                            "CountryId": "CZ",
                            "Location": "16.872227, 49.882602",
                            "IataCode": "ZBE",
                            "Airports": [
                                {
                                    "Id": "ZBE",
                                    "Name": "Zabreh",
                                    "CityId": "ZBEA",
                                    "CountryId": "CZ",
                                    "Location": "16.866667, 49.883333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DE",
                    "Name": "Germany",
                    "CurrencyId": "EUR",
                    "LanguageId": "DE",
                    "Regions": [
                        {
                            "Id": "BADEN",
                            "Name": "Baden-Württemberg",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "BAYER",
                            "Name": "Bavaria",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "BREME",
                            "Name": "Bremen",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "HAMBU",
                            "Name": "Hamburg",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "HESSE",
                            "Name": "Hesse",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "NIEDE",
                            "Name": "Lower Saxony",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "NORDR",
                            "Name": "North Rhine-Westphalia",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "SAARL",
                            "Name": "Saarland",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "SCHLE",
                            "Name": "Schleswig-Holstein",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "SACHS",
                            "Name": "Saxony",
                            "CountryId": "DE"
                        },
                        {
                            "Id": "BERLI",
                            "Name": "Berlin",
                            "CountryId": "DE"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "UWEA",
                            "Name": "Wiesbaden",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "HESSE",
                            "Location": "8.2500001, 50.083336",
                            "IataCode": "UWE",
                            "Airports": [
                                {
                                    "Id": "WIE",
                                    "Name": "Wiesbaden",
                                    "CityId": "UWEA",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "8.3254, 50.04982"
                                }
                            ]
                        },
                        {
                            "Id": "WILH",
                            "Name": "Wilhelmshaven",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "8.1333335, 53.516667",
                            "IataCode": "WVN",
                            "Airports": [
                                {
                                    "Id": "WVN",
                                    "Name": "Wilhelmshaven",
                                    "CityId": "WILH",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "8.05, 53.5"
                                }
                            ]
                        },
                        {
                            "Id": "HAMB",
                            "Name": "Hamburg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "HAMBU",
                            "Location": "10.018562, 53.545526",
                            "IataCode": "HAM",
                            "Airports": [
                                {
                                    "Id": "HAM",
                                    "Name": "Hamburg International",
                                    "CityId": "HAMB",
                                    "CountryId": "DE",
                                    "RegionId": "HAMBU",
                                    "Location": "9.9973162, 53.633635"
                                },
                                {
                                    "Id": "LBC",
                                    "Name": "Hamburg Lubeck",
                                    "CityId": "HAMB",
                                    "CountryId": "DE",
                                    "RegionId": "HAMBU",
                                    "Location": "10.720556, 53.806111"
                                }
                            ]
                        },
                        {
                            "Id": "AUGS",
                            "Name": "Augsburg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "10.883333, 48.366669",
                            "IataCode": "AGB",
                            "Airports": [
                                {
                                    "Id": "AGB",
                                    "Name": "Augsburg",
                                    "CityId": "AUGS",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "10.931667, 48.425278"
                                }
                            ]
                        },
                        {
                            "Id": "ZQWA",
                            "Name": "Zweibrücken",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "7.3666668, 49.25",
                            "Airports": []
                        },
                        {
                            "Id": "BART",
                            "Name": "Barth",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "12.724915, 54.363458",
                            "IataCode": "BBH",
                            "Airports": [
                                {
                                    "Id": "BBH",
                                    "Name": "Barth",
                                    "CityId": "BART",
                                    "CountryId": "DE",
                                    "Location": "12.711667, 54.339722"
                                }
                            ]
                        },
                        {
                            "Id": "BRAU",
                            "Name": "Braunschweig",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "10.533332, 52.266667",
                            "IataCode": "BWE",
                            "Airports": [
                                {
                                    "Id": "BWE",
                                    "Name": "Braunschweig",
                                    "CityId": "BRAU",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "10.55611, 52.31917"
                                }
                            ]
                        },
                        {
                            "Id": "LANG",
                            "Name": "Langeoog",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.4833334, 53.75",
                            "IataCode": "LGO",
                            "Airports": [
                                {
                                    "Id": "LGO",
                                    "Name": "Langeoog",
                                    "CityId": "LANG",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.48333, 53.73333"
                                }
                            ]
                        },
                        {
                            "Id": "NORG",
                            "Name": "Norderney",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.1500001, 53.7",
                            "IataCode": "NRD",
                            "Airports": [
                                {
                                    "Id": "NRD",
                                    "Name": "Norderney",
                                    "CityId": "NORG",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.166667, 53.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WURZ",
                            "Name": "Würzburg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "9.9361107, 49.787778",
                            "Airports": []
                        },
                        {
                            "Id": "LEMW",
                            "Name": "Lemwerder",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "8.6166667, 53.166667",
                            "IataCode": "XLW",
                            "Airports": [
                                {
                                    "Id": "XLW",
                                    "Name": "Lemwerder",
                                    "CityId": "LEMW",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "8.6, 53.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BREM",
                            "Name": "Bremen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BREME",
                            "Location": "8.8077736, 53.075158",
                            "IataCode": "BRE",
                            "Airports": [
                                {
                                    "Id": "BRE",
                                    "Name": "Bremen",
                                    "CityId": "BREM",
                                    "CountryId": "DE",
                                    "RegionId": "BREME",
                                    "Location": "8.79, 53.046667"
                                }
                            ]
                        },
                        {
                            "Id": "BREN",
                            "Name": "Bremerhaven",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BREME",
                            "Location": "8.5833335, 53.55",
                            "IataCode": "BRV",
                            "Airports": [
                                {
                                    "Id": "BRV",
                                    "Name": "Bremerhaven",
                                    "CityId": "BREN",
                                    "CountryId": "DE",
                                    "RegionId": "BREME",
                                    "Location": "8.572878, 53.507081"
                                }
                            ]
                        },
                        {
                            "Id": "BAYR",
                            "Name": "Bayreuth Bindlacher",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.578333, 49.948056",
                            "IataCode": "BYU",
                            "Airports": [
                                {
                                    "Id": "BYU",
                                    "Name": "Bayreuth Bindlacher",
                                    "CityId": "BAYR",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.64, 49.985556"
                                }
                            ]
                        },
                        {
                            "Id": "COTT",
                            "Name": "Cottbus",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "14.333333, 51.766667",
                            "IataCode": "CBU",
                            "Airports": [
                                {
                                    "Id": "CBU",
                                    "Name": "Cottbus",
                                    "CityId": "COTT",
                                    "CountryId": "DE",
                                    "Location": "14.53333, 51.88333"
                                }
                            ]
                        },
                        {
                            "Id": "CSOA",
                            "Name": "Cochstedt",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "11.416667, 51.883333",
                            "IataCode": "CSO",
                            "Airports": [
                                {
                                    "Id": "CSO",
                                    "Name": "Magdeburg-Cochstedt",
                                    "CityId": "CSOA",
                                    "CountryId": "DE",
                                    "Location": "11.423206, 51.859319"
                                }
                            ]
                        },
                        {
                            "Id": "WANG",
                            "Name": "Wangerooge",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.9000001, 53.8",
                            "IataCode": "AGE",
                            "Airports": [
                                {
                                    "Id": "AGE",
                                    "Name": "Wangerooge",
                                    "CityId": "WANG",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.916667, 53.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BFEA",
                            "Name": "Bielefeld",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "8.5333334, 52.033333",
                            "IataCode": "BFE",
                            "Airports": [
                                {
                                    "Id": "BFE",
                                    "Name": "Bielefeld",
                                    "CityId": "BFEA",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "8.549108, 51.965055"
                                }
                            ]
                        },
                        {
                            "Id": "BALT",
                            "Name": "Baltrum",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.3666668, 53.733333",
                            "IataCode": "BMR",
                            "Airports": [
                                {
                                    "Id": "BMR",
                                    "Name": "Baltrum",
                                    "CityId": "BALT",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.4, 53.716667"
                                }
                            ]
                        },
                        {
                            "Id": "DRES",
                            "Name": "Dresden",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SACHS",
                            "Location": "13.738318, 51.050893",
                            "IataCode": "DRS",
                            "Airports": [
                                {
                                    "Id": "DRS",
                                    "Name": "Dresden",
                                    "CityId": "DRES",
                                    "CountryId": "DE",
                                    "RegionId": "SACHS",
                                    "Location": "13.766667, 51.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DORT",
                            "Name": "Dortmund",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "7.4500011, 51.516667",
                            "IataCode": "DTM",
                            "Airports": [
                                {
                                    "Id": "DTM",
                                    "Name": "Dortmund",
                                    "CityId": "DORT",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "7.6, 51.516667"
                                }
                            ]
                        },
                        {
                            "Id": "DUSS",
                            "Name": "Dusseldorf",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.7761616, 51.221725",
                            "IataCode": "DUS",
                            "Airports": [
                                {
                                    "Id": "MGL",
                                    "Name": "Dusseldorf Monchengladbach",
                                    "CityId": "DUSS",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.504444, 51.230278"
                                },
                                {
                                    "Id": "DUS",
                                    "Name": "Dusseldorf International",
                                    "CityId": "DUSS",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.752778, 51.281111"
                                },
                                {
                                    "Id": "NRN",
                                    "Name": "Weeze",
                                    "CityId": "DUSS",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.15, 51.6056"
                                }
                            ]
                        },
                        {
                            "Id": "EMDE",
                            "Name": "Emden",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.216667, 53.366667",
                            "IataCode": "EME",
                            "Airports": [
                                {
                                    "Id": "EME",
                                    "Name": "Emden",
                                    "CityId": "EMDE",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.2, 53.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ERFU",
                            "Name": "Erfurt",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "11.033333, 50.983335",
                            "IataCode": "ERF",
                            "Airports": [
                                {
                                    "Id": "ERF",
                                    "Name": "Erfurt",
                                    "CityId": "ERFU",
                                    "CountryId": "DE",
                                    "Location": "10.966667, 50.983333"
                                }
                            ]
                        },
                        {
                            "Id": "NEUM",
                            "Name": "Neumuenster",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "9.9833331, 54.066667",
                            "IataCode": "EUM",
                            "Airports": [
                                {
                                    "Id": "EUM",
                                    "Name": "Neumuenster",
                                    "CityId": "NEUM",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "9.966667, 54.066667"
                                }
                            ]
                        },
                        {
                            "Id": "FRIE",
                            "Name": "Friedrichshafen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "9.4833332, 47.65",
                            "IataCode": "FDH",
                            "Airports": [
                                {
                                    "Id": "FDH",
                                    "Name": "Friedrichshafen",
                                    "CityId": "FRIE",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "9.5125, 47.667222"
                                }
                            ]
                        },
                        {
                            "Id": "FURS",
                            "Name": "Furstenfeldbruck",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.259613, 48.175244",
                            "IataCode": "FEL",
                            "Airports": [
                                {
                                    "Id": "FEL",
                                    "Name": "Furstenfeldbruck",
                                    "CityId": "FURS",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.266667, 48.2"
                                }
                            ]
                        },
                        {
                            "Id": "MEMB",
                            "Name": "Memmingen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "10.166667, 47.983333",
                            "IataCode": "FMM",
                            "Airports": [
                                {
                                    "Id": "FMM",
                                    "Name": "Memmingen Allgäu",
                                    "CityId": "MEMB",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "10.243611, 47.9925"
                                }
                            ]
                        },
                        {
                            "Id": "FRAN",
                            "Name": "Frankfurt",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "HESSE",
                            "Location": "8.6833334, 50.11667",
                            "IataCode": "FRA",
                            "Airports": [
                                {
                                    "Id": "FRF",
                                    "Name": "Frankfurt Rhein-Main Afb",
                                    "CityId": "FRAN",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "8.683333, 50.1"
                                },
                                {
                                    "Id": "HHN",
                                    "Name": "Frankfurt Hahn",
                                    "CityId": "FRAN",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "7.266667, 49.95"
                                },
                                {
                                    "Id": "FRA",
                                    "Name": "Frankfurt am Main",
                                    "CityId": "FRAN",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "8.5621411, 50.037905"
                                },
                                {
                                    "Id": "QGV",
                                    "Name": "Frankfurt Neu Isenburg",
                                    "CityId": "FRAN",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "8.683333, 50.05"
                                }
                            ]
                        },
                        {
                            "Id": "FRZA",
                            "Name": "Fritzlar",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "HESSE",
                            "Location": "9.2833333, 51.133333",
                            "IataCode": "FRZ",
                            "Airports": [
                                {
                                    "Id": "FRZ",
                                    "Name": "Fritzlar",
                                    "CityId": "FRZA",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "9.266667, 51.116667"
                                }
                            ]
                        },
                        {
                            "Id": "HEID",
                            "Name": "Heidleberg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "8.6907865, 49.407678",
                            "IataCode": "HDB",
                            "Airports": [
                                {
                                    "Id": "HDB",
                                    "Name": "Heidleberg",
                                    "CityId": "HEID",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "8.7, 49.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GEIL",
                            "Name": "Geilenkirchen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.1166668, 50.966667",
                            "IataCode": "GKE",
                            "Airports": [
                                {
                                    "Id": "GKE",
                                    "Name": "Geilenkirchen",
                                    "CityId": "GEIL",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.133333, 50.966667"
                                }
                            ]
                        },
                        {
                            "Id": "GUET",
                            "Name": "Guettin",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "13.316667, 54.383333",
                            "IataCode": "GTI",
                            "Airports": [
                                {
                                    "Id": "GTI",
                                    "Name": "Guettin",
                                    "CityId": "GUET",
                                    "CountryId": "DE",
                                    "Location": "12.316667, 54.383333"
                                }
                            ]
                        },
                        {
                            "Id": "GUTE",
                            "Name": "Gutersloh",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "8.3833335, 51.9",
                            "IataCode": "ZEX",
                            "Airports": [
                                {
                                    "Id": "GUT",
                                    "Name": "Gutersloh",
                                    "CityId": "GUTE",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "8.3, 51.91667"
                                }
                            ]
                        },
                        {
                            "Id": "HANN",
                            "Name": "Hannover",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "9.7332214, 52.370519",
                            "IataCode": "HAJ",
                            "Airports": [
                                {
                                    "Id": "HAJ",
                                    "Name": "Hannover",
                                    "CityId": "HANN",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "9.684722, 52.461667"
                                }
                            ]
                        },
                        {
                            "Id": "HERI",
                            "Name": "Heringsdorf",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "14.166667, 53.95",
                            "IataCode": "HDF",
                            "Airports": [
                                {
                                    "Id": "HDF",
                                    "Name": "Heringsdorf",
                                    "CityId": "HERI",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "14.15235, 53.87871"
                                }
                            ]
                        },
                        {
                            "Id": "HOFF",
                            "Name": "Hof",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.916666, 50.316667",
                            "IataCode": "HOQ",
                            "Airports": [
                                {
                                    "Id": "HOQ",
                                    "Name": "Hof",
                                    "CityId": "HOFF",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.862222, 50.289167"
                                }
                            ]
                        },
                        {
                            "Id": "JUIS",
                            "Name": "Juist",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.0000001, 53.683333",
                            "IataCode": "JUI",
                            "Airports": [
                                {
                                    "Id": "JUI",
                                    "Name": "Juist",
                                    "CityId": "JUIS",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.066667, 53.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KIEL",
                            "Name": "Kiel",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "10.134888, 54.321331",
                            "IataCode": "KEL",
                            "Airports": [
                                {
                                    "Id": "KEL",
                                    "Name": "Kiel",
                                    "CityId": "KIEL",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "10.143333, 54.380833"
                                }
                            ]
                        },
                        {
                            "Id": "KASS",
                            "Name": "Kassel",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "HESSE",
                            "Location": "9.4999998, 51.316667",
                            "IataCode": "KSF",
                            "Airports": [
                                {
                                    "Id": "KSF",
                                    "Name": "Kassel",
                                    "CityId": "KASS",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "9.375278, 51.408056"
                                }
                            ]
                        },
                        {
                            "Id": "LEIP",
                            "Name": "Leipzig",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SACHS",
                            "Location": "12.371293, 51.33962",
                            "IataCode": "LEJ",
                            "Airports": [
                                {
                                    "Id": "LEJ",
                                    "Name": "Leipzig",
                                    "CityId": "LEIP",
                                    "CountryId": "DE",
                                    "RegionId": "SACHS",
                                    "Location": "12.233333, 51.416667"
                                }
                            ]
                        },
                        {
                            "Id": "LAHR",
                            "Name": "Lahr",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "7.8666668, 48.35",
                            "IataCode": "LHA",
                            "Airports": [
                                {
                                    "Id": "LHA",
                                    "Name": "Lahr",
                                    "CityId": "LAHR",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "7.81667, 48.36667"
                                }
                            ]
                        },
                        {
                            "Id": "MANN",
                            "Name": "Mannheim",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "8.4647223, 49.488333",
                            "IataCode": "MHG",
                            "Airports": [
                                {
                                    "Id": "MHG",
                                    "Name": "Mannheim",
                                    "CityId": "MANN",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "8.514264, 49.472706"
                                }
                            ]
                        },
                        {
                            "Id": "MUNI",
                            "Name": "Munich",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.55237, 48.148606",
                            "IataCode": "MUC",
                            "Airports": [
                                {
                                    "Id": "MUC",
                                    "Name": "Munich",
                                    "CityId": "MUNI",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.772581, 48.35165"
                                }
                            ]
                        },
                        {
                            "Id": "NORJ",
                            "Name": "Norden",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.2000002, 53.6",
                            "IataCode": "NOD",
                            "Airports": [
                                {
                                    "Id": "NOD",
                                    "Name": "Norden",
                                    "CityId": "NORJ",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.191096, 53.631841"
                                }
                            ]
                        },
                        {
                            "Id": "NURE",
                            "Name": "Nuremberg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.068332, 49.447778",
                            "IataCode": "NUE",
                            "Airports": [
                                {
                                    "Id": "NUE",
                                    "Name": "Nuremberg",
                                    "CityId": "NURE",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.083333, 49.5"
                                }
                            ]
                        },
                        {
                            "Id": "OBER",
                            "Name": "Oberpfaffenhofen Munich",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.263647, 48.074065",
                            "IataCode": "OBF",
                            "Airports": [
                                {
                                    "Id": "OBF",
                                    "Name": "Oberpfaffenhofen Munich",
                                    "CityId": "OBER",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.283333, 48.083333"
                                }
                            ]
                        },
                        {
                            "Id": "RBMA",
                            "Name": "Straubing",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "12.566667, 48.883333",
                            "IataCode": "RBM",
                            "Airports": [
                                {
                                    "Id": "RBM",
                                    "Name": "Straubing",
                                    "CityId": "RBMA",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "12.519722, 48.901944"
                                }
                            ]
                        },
                        {
                            "Id": "RAMS",
                            "Name": "Ramstein",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "7.5500001, 49.45",
                            "IataCode": "RMS",
                            "Airports": [
                                {
                                    "Id": "RMS",
                                    "Name": "Ramstein",
                                    "CityId": "RAMS",
                                    "CountryId": "DE",
                                    "Location": "7.60028, 49.43691"
                                }
                            ]
                        },
                        {
                            "Id": "SAAR",
                            "Name": "Saarbruecken",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "SAARL",
                            "Location": "7.0000001, 49.233334",
                            "IataCode": "SCN",
                            "Airports": [
                                {
                                    "Id": "ZQW",
                                    "Name": "Zweibrücken",
                                    "CityId": "SAAR",
                                    "CountryId": "DE",
                                    "RegionId": "SAARL",
                                    "Location": "7.400647, 49.209525"
                                },
                                {
                                    "Id": "SCN",
                                    "Name": "Saarbruecken",
                                    "CityId": "SAAR",
                                    "CountryId": "DE",
                                    "RegionId": "SAARL",
                                    "Location": "7.110214, 49.215579"
                                }
                            ]
                        },
                        {
                            "Id": "STUT",
                            "Name": "Stuttgart",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "9.1770172, 48.782327",
                            "IataCode": "STR",
                            "Airports": [
                                {
                                    "Id": "STR",
                                    "Name": "Stuttgart",
                                    "CityId": "STUT",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "9.2056189, 48.687644"
                                }
                            ]
                        },
                        {
                            "Id": "SEMB",
                            "Name": "Sembach",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "7.8500001, 49.516667",
                            "IataCode": "SEX",
                            "Airports": [
                                {
                                    "Id": "SEX",
                                    "Name": "Sembach",
                                    "CityId": "SEMB",
                                    "CountryId": "DE",
                                    "Location": "7.866667, 49.5"
                                }
                            ]
                        },
                        {
                            "Id": "SGEA",
                            "Name": "Siegen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "8.0333336, 50.866667",
                            "IataCode": "SGE",
                            "Airports": [
                                {
                                    "Id": "SGE",
                                    "Name": "Siegen",
                                    "CityId": "SGEA",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "8, 50.85"
                                }
                            ]
                        },
                        {
                            "Id": "BERL",
                            "Name": "Berlin",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "BERLI",
                            "Location": "13.424519, 52.486562",
                            "IataCode": "BER",
                            "Airports": [
                                {
                                    "Id": "BER",
                                    "Name": "Berlin Brandenburg",
                                    "CityId": "BERL",
                                    "CountryId": "DE",
                                    "RegionId": "BERLI",
                                    "Location": "13.50333, 52.366667"
                                },
                                {
                                    "Id": "QWC",
                                    "Name": "Berlin Zoo Railway",
                                    "CityId": "BERL",
                                    "CountryId": "DE",
                                    "RegionId": "BERLI",
                                    "Location": "13.3006, 52.5839"
                                },
                                {
                                    "Id": "GWW",
                                    "Name": "Berlin Gatow",
                                    "CityId": "BERL",
                                    "CountryId": "DE",
                                    "RegionId": "BERLI",
                                    "Location": "13, 52"
                                },
                                {
                                    "Id": "TXL",
                                    "Name": "Berlin Tegel",
                                    "CityId": "BERL",
                                    "CountryId": "DE",
                                    "RegionId": "BERLI",
                                    "Location": "13.2956, 52.5606"
                                },
                                {
                                    "Id": "SXF",
                                    "Name": "Berlin Schoenefeld",
                                    "CityId": "BERL",
                                    "CountryId": "DE",
                                    "RegionId": "BERLI",
                                    "Location": "13.5, 52.366667"
                                }
                            ]
                        },
                        {
                            "Id": "PARC",
                            "Name": "Schwerin",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "11.383333, 53.633333",
                            "IataCode": "SZW",
                            "Airports": [
                                {
                                    "Id": "SZW",
                                    "Name": "Schwerin-Parchim",
                                    "CityId": "PARC",
                                    "CountryId": "DE",
                                    "Location": "11.783333, 53.433333"
                                }
                            ]
                        },
                        {
                            "Id": "AAHA",
                            "Name": "Aachen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.0834221, 50.776638",
                            "IataCode": "AAH",
                            "Airports": [
                                {
                                    "Id": "AAH",
                                    "Name": "Aachen / Merzbruck",
                                    "CityId": "AAHA",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.133333, 50.75"
                                }
                            ]
                        },
                        {
                            "Id": "BBJA",
                            "Name": "Bitburg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "6.5333334, 49.966667",
                            "IataCode": "BBJ",
                            "Airports": [
                                {
                                    "Id": "BBJ",
                                    "Name": "Bitburg",
                                    "CityId": "BBJA",
                                    "CountryId": "DE",
                                    "Location": "6.55, 49.95"
                                }
                            ]
                        },
                        {
                            "Id": "REBA",
                            "Name": "Rechlin",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "12.733333, 53.35",
                            "IataCode": "REB",
                            "Airports": [
                                {
                                    "Id": "REB",
                                    "Name": "Rechlin",
                                    "CityId": "REBA",
                                    "CountryId": "DE",
                                    "Location": "12.716667, 53.35"
                                }
                            ]
                        },
                        {
                            "Id": "NORF",
                            "Name": "Norddeich",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.1500001, 53.616667",
                            "IataCode": "NOE",
                            "Airports": [
                                {
                                    "Id": "NOE",
                                    "Name": "Norddeich",
                                    "CityId": "NORF",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "7.19205, 53.6323"
                                }
                            ]
                        },
                        {
                            "Id": "WYKA",
                            "Name": "Wyk Auf Foehr",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "8.5670185, 54.691396",
                            "IataCode": "OHR",
                            "Airports": [
                                {
                                    "Id": "OHR",
                                    "Name": "Wyk Auf Foehr",
                                    "CityId": "WYKA",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "8.529167, 54.685833"
                                }
                            ]
                        },
                        {
                            "Id": "PHMA",
                            "Name": "Boeblingen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "9.0166667, 48.683333",
                            "IataCode": "PHM",
                            "Airports": [
                                {
                                    "Id": "PHM",
                                    "Name": "Boeblingen",
                                    "CityId": "PHMA",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "9, 48.683333"
                                }
                            ]
                        },
                        {
                            "Id": "PFOR",
                            "Name": "Pforheim",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "8.7000001, 48.883333",
                            "IataCode": "UPF",
                            "Airports": [
                                {
                                    "Id": "UPF",
                                    "Name": "Pforheim",
                                    "CityId": "PFOR",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "8.683333, 48.866667"
                                }
                            ]
                        },
                        {
                            "Id": "VACA",
                            "Name": "Varrelbusch",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "8.0166668, 52.883333",
                            "IataCode": "VAC",
                            "Airports": [
                                {
                                    "Id": "VAC",
                                    "Name": "Varrelbusch",
                                    "CityId": "VACA",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "8.05, 52.916667"
                                }
                            ]
                        },
                        {
                            "Id": "WILD",
                            "Name": "Wildenrath",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.1833334, 51.116667",
                            "IataCode": "WID",
                            "Airports": [
                                {
                                    "Id": "WID",
                                    "Name": "Wildenrath",
                                    "CityId": "WILD",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.195602, 51.124859"
                                }
                            ]
                        },
                        {
                            "Id": "FLEN",
                            "Name": "Flensburg Schaferhas",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "9.4333332, 54.783333",
                            "IataCode": "FLF",
                            "Airports": [
                                {
                                    "Id": "FLF",
                                    "Name": "Flensburg Schaferhas",
                                    "CityId": "FLEN",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "9.383333, 54.772222"
                                }
                            ]
                        },
                        {
                            "Id": "ESSE",
                            "Name": "Essen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "7.016667, 51.450003",
                            "IataCode": "ESS",
                            "Airports": [
                                {
                                    "Id": "ESS",
                                    "Name": "Essen",
                                    "CityId": "ESSE",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.941667, 51.403889"
                                }
                            ]
                        },
                        {
                            "Id": "COLO",
                            "Name": "Cologne",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.973699, 50.945626",
                            "IataCode": "CGN",
                            "Airports": [
                                {
                                    "Id": "CGN",
                                    "Name": "Cologne",
                                    "CityId": "COLO",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "7.1411809, 50.866943"
                                },
                                {
                                    "Id": "QKU",
                                    "Name": "Cologne-Deutz R.R. Svc",
                                    "CityId": "COLO",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.85, 51.3167"
                                }
                            ]
                        },
                        {
                            "Id": "EIBA",
                            "Name": "Eisenach",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "10.316667, 50.983333",
                            "IataCode": "EIB",
                            "Airports": [
                                {
                                    "Id": "EIB",
                                    "Name": "Eisenach",
                                    "CityId": "EIBA",
                                    "CountryId": "DE",
                                    "Location": "10.483333, 50.983333"
                                }
                            ]
                        },
                        {
                            "Id": "BADE",
                            "Name": "Baden-Baden",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BADEN",
                            "Location": "8.2500001, 48.75",
                            "IataCode": "FKB",
                            "Airports": [
                                {
                                    "Id": "FKB",
                                    "Name": "Karlsruhe / Baden-Baden",
                                    "CityId": "BADE",
                                    "CountryId": "DE",
                                    "RegionId": "BADEN",
                                    "Location": "8.0803, 48.7792"
                                }
                            ]
                        },
                        {
                            "Id": "BONN",
                            "Name": "Bonn Train Main",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "7.1000004, 50.733333",
                            "IataCode": "BNJ",
                            "Airports": [
                                {
                                    "Id": "BNJ",
                                    "Name": "Bonn Train Main",
                                    "CityId": "BONN",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "7.1667, 50.7667"
                                }
                            ]
                        },
                        {
                            "Id": "DARM",
                            "Name": "Darmstadt",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "8.6494444, 49.870558",
                            "Airports": []
                        },
                        {
                            "Id": "LUFT",
                            "Name": "Lutherstadt Wittenberg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "12.606034, 51.874367",
                            "Airports": []
                        },
                        {
                            "Id": "WOLF",
                            "Name": "Wolfsburg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "10.8, 52.433335",
                            "Airports": []
                        },
                        {
                            "Id": "DUIS",
                            "Name": "Duisburg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "6.7500003, 51.433335",
                            "Airports": []
                        },
                        {
                            "Id": "WUPP",
                            "Name": "Wuppertal",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "7.1833338, 51.266667",
                            "Airports": []
                        },
                        {
                            "Id": "IESA",
                            "Name": "Riesa",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SACHS",
                            "Location": "13.3, 51.3",
                            "IataCode": "IES",
                            "Airports": [
                                {
                                    "Id": "IES",
                                    "Name": "Riesa",
                                    "CityId": "IESA",
                                    "CountryId": "DE",
                                    "RegionId": "SACHS",
                                    "Location": "13.283333, 51.3"
                                }
                            ]
                        },
                        {
                            "Id": "HALL",
                            "Name": "Halle (Saale)",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "11.982762, 51.492847",
                            "Airports": []
                        },
                        {
                            "Id": "MUNS",
                            "Name": "Munster",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "7.6257136, 51.962359",
                            "IataCode": "FMO",
                            "Airports": [
                                {
                                    "Id": "FMO",
                                    "Name": "Munster Osnabruck",
                                    "CityId": "MUNS",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "7.683333, 52.133333"
                                }
                            ]
                        },
                        {
                            "Id": "ROST",
                            "Name": "Rostock",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "12.140495, 54.088697",
                            "IataCode": "RLG",
                            "Airports": [
                                {
                                    "Id": "RLG",
                                    "Name": "Rostock",
                                    "CityId": "ROST",
                                    "CountryId": "DE",
                                    "Location": "12.266667, 53.92"
                                }
                            ]
                        },
                        {
                            "Id": "BITT",
                            "Name": "Bitterfeld",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "12.316667, 51.616667",
                            "Airports": []
                        },
                        {
                            "Id": "FCNA",
                            "Name": "Cuxhaven",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "8.6908722, 53.871761",
                            "IataCode": "FCN",
                            "Airports": [
                                {
                                    "Id": "FCN",
                                    "Name": "Cuxhaven / Nordholz",
                                    "CityId": "FCNA",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "8.666667, 53.783056"
                                }
                            ]
                        },
                        {
                            "Id": "FULD",
                            "Name": "Fulda",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "9.6751784, 50.551617",
                            "Airports": []
                        },
                        {
                            "Id": "HELG",
                            "Name": "Helgoland",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "7.8862954, 54.181425",
                            "IataCode": "HGL",
                            "Airports": [
                                {
                                    "Id": "HGL",
                                    "Name": "Helgoland",
                                    "CityId": "HELG",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "7.916667, 54.186944"
                                }
                            ]
                        },
                        {
                            "Id": "FNBA",
                            "Name": "Neubrandenburg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "13.266667, 53.566667",
                            "IataCode": "FNB",
                            "Airports": [
                                {
                                    "Id": "FNB",
                                    "Name": "Neubrandenburg",
                                    "CityId": "FNBA",
                                    "CountryId": "DE",
                                    "Location": "13.307222, 53.602778"
                                }
                            ]
                        },
                        {
                            "Id": "GTTG",
                            "Name": "Göttingen",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "9.9333329, 51.533333",
                            "Airports": []
                        },
                        {
                            "Id": "BORK",
                            "Name": "Borkum",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "6.6666668, 53.583333",
                            "IataCode": "BMK",
                            "Airports": [
                                {
                                    "Id": "BMK",
                                    "Name": "Borkum",
                                    "CityId": "BORK",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "6.7, 53.6"
                                }
                            ]
                        },
                        {
                            "Id": "QHUA",
                            "Name": "Husum",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "9.052391, 54.485796",
                            "IataCode": "QHU",
                            "Airports": [
                                {
                                    "Id": "QHU",
                                    "Name": "Husum",
                                    "CityId": "QHUA",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "9.06667, 54.46667"
                                }
                            ]
                        },
                        {
                            "Id": "KELS",
                            "Name": "Kelsterbach",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "HESSE",
                            "Location": "8.5333333, 50.066667",
                            "IataCode": "QLH",
                            "Airports": [
                                {
                                    "Id": "QLH",
                                    "Name": "Kelsterbach",
                                    "CityId": "KELS",
                                    "CountryId": "DE",
                                    "RegionId": "HESSE",
                                    "Location": "8.51667, 50.05"
                                }
                            ]
                        },
                        {
                            "Id": "STPA",
                            "Name": "St. Peter / Ording",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "8.6333333, 54.3",
                            "IataCode": "PSH",
                            "Airports": [
                                {
                                    "Id": "PSH",
                                    "Name": "St. Peter / Ording",
                                    "CityId": "STPA",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "8.116667, 54.5"
                                }
                            ]
                        },
                        {
                            "Id": "GHFA",
                            "Name": "Giebelstadt",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "9.9477778, 49.654167",
                            "IataCode": "GHF",
                            "Airports": [
                                {
                                    "Id": "GHF",
                                    "Name": "Giebelstadt",
                                    "CityId": "GHFA",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "9.966667, 49.65"
                                }
                            ]
                        },
                        {
                            "Id": "HAMW",
                            "Name": "Hamm",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "7.8208927, 51.680326",
                            "Airports": []
                        },
                        {
                            "Id": "PADE",
                            "Name": "Paderborn",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "8.754387, 51.719053",
                            "IataCode": "PAD",
                            "Airports": [
                                {
                                    "Id": "PAD",
                                    "Name": "Paderborn",
                                    "CityId": "PADE",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "8.621111, 51.615"
                                }
                            ]
                        },
                        {
                            "Id": "STEN",
                            "Name": "Stendal",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "11.85, 52.6",
                            "Airports": []
                        },
                        {
                            "Id": "WEST",
                            "Name": "Westerland",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "8.3032609, 54.907903",
                            "IataCode": "GWT",
                            "Airports": [
                                {
                                    "Id": "GWT",
                                    "Name": "Westerland",
                                    "CityId": "WEST",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "8.3403, 54.9132"
                                }
                            ]
                        },
                        {
                            "Id": "GOTA",
                            "Name": "Gotha",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "10.716666, 50.95",
                            "Airports": []
                        },
                        {
                            "Id": "KZGA",
                            "Name": "Kitzingen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "10.166667, 49.733333",
                            "IataCode": "KZG",
                            "Airports": [
                                {
                                    "Id": "KZG",
                                    "Name": "Kitzingen",
                                    "CityId": "KZGA",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "10.15, 49.733333"
                                }
                            ]
                        },
                        {
                            "Id": "NAUM",
                            "Name": "Naumburg",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "11.816667, 51.15",
                            "Airports": []
                        },
                        {
                            "Id": "WERD",
                            "Name": "Werder (Havel)",
                            "SingleAirportCity": false,
                            "CountryId": "DE",
                            "Location": "12.933998, 52.378743",
                            "Airports": []
                        },
                        {
                            "Id": "KOQA",
                            "Name": "Koethen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "11.966667, 51.75",
                            "IataCode": "KOQ",
                            "Airports": [
                                {
                                    "Id": "KOQ",
                                    "Name": "Koethen",
                                    "CityId": "KOQA",
                                    "CountryId": "DE",
                                    "Location": "11.95, 51.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SPMA",
                            "Name": "Spangdahlem",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "6.6833334, 49.983333",
                            "IataCode": "SPM",
                            "Airports": [
                                {
                                    "Id": "SPM",
                                    "Name": "Spangdahlem",
                                    "CityId": "SPMA",
                                    "CountryId": "DE",
                                    "Location": "6.6925, 49.97267"
                                }
                            ]
                        },
                        {
                            "Id": "PEFA",
                            "Name": "Peenemuende",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "13.783333, 54.133333",
                            "IataCode": "PEF",
                            "Airports": [
                                {
                                    "Id": "PEF",
                                    "Name": "Peenemuende",
                                    "CityId": "PEFA",
                                    "CountryId": "DE",
                                    "Location": "13.766667, 54.166667"
                                }
                            ]
                        },
                        {
                            "Id": "NORD",
                            "Name": "Westerloog",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NIEDE",
                            "Location": "7.2000001, 53.633333",
                            "IataCode": "NDZ",
                            "Airports": [
                                {
                                    "Id": "NDZ",
                                    "Name": "Nordholz-Spieka",
                                    "CityId": "NORD",
                                    "CountryId": "DE",
                                    "RegionId": "NIEDE",
                                    "Location": "8.644722, 53.768611"
                                }
                            ]
                        },
                        {
                            "Id": "FINK",
                            "Name": "Teufelsbrück",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "HAMBU",
                            "Location": "9.8644351, 53.54704",
                            "IataCode": "XFW",
                            "Airports": [
                                {
                                    "Id": "XFW",
                                    "Name": "Finkenwerder",
                                    "CityId": "FINK",
                                    "CountryId": "DE",
                                    "RegionId": "HAMBU",
                                    "Location": "9.834652, 53.534288"
                                }
                            ]
                        },
                        {
                            "Id": "HEIE",
                            "Name": "Oesterdeichstrich",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "8.8833333, 54.15",
                            "IataCode": "HEI",
                            "Airports": [
                                {
                                    "Id": "HEI",
                                    "Name": "Heide / Buesum Heide",
                                    "CityId": "HEIE",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "8.900557, 54.153538"
                                }
                            ]
                        },
                        {
                            "Id": "WBGA",
                            "Name": "Mielberg",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SCHLE",
                            "Location": "9.5166667, 54.45",
                            "IataCode": "WBG",
                            "Airports": [
                                {
                                    "Id": "WBG",
                                    "Name": "Schleswig-Jagel",
                                    "CityId": "WBGA",
                                    "CountryId": "DE",
                                    "RegionId": "SCHLE",
                                    "Location": "9.516667, 54.45"
                                }
                            ]
                        },
                        {
                            "Id": "GMYA",
                            "Name": "Kothausen",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.3720704, 51.163198",
                            "IataCode": "GMY",
                            "Airports": [
                                {
                                    "Id": "GMY",
                                    "Name": "Rheindahlen",
                                    "CityId": "GMYA",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.366667, 51.166667"
                                }
                            ]
                        },
                        {
                            "Id": "ILHA",
                            "Name": "Illesheim",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "Location": "10.383333, 49.466667",
                            "IataCode": "ILH",
                            "Airports": [
                                {
                                    "Id": "ILH",
                                    "Name": "Illisheim",
                                    "CityId": "ILHA",
                                    "CountryId": "DE",
                                    "Location": "10.3881, 49.4739"
                                }
                            ]
                        },
                        {
                            "Id": "URDA",
                            "Name": "Drügendorf",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "BAYER",
                            "Location": "11.116667, 49.8",
                            "IataCode": "URD",
                            "Airports": [
                                {
                                    "Id": "URD",
                                    "Name": "Burg Feuerstein",
                                    "CityId": "URDA",
                                    "CountryId": "DE",
                                    "RegionId": "BAYER",
                                    "Location": "11.133333, 49.8"
                                }
                            ]
                        },
                        {
                            "Id": "LRCA",
                            "Name": "Baal",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "NORDR",
                            "Location": "6.1333334, 51.616667",
                            "IataCode": "LRC",
                            "Airports": [
                                {
                                    "Id": "LRC",
                                    "Name": "Laarbruch",
                                    "CityId": "LRCA",
                                    "CountryId": "DE",
                                    "RegionId": "NORDR",
                                    "Location": "6.133333, 51.6"
                                }
                            ]
                        },
                        {
                            "Id": "ALTE",
                            "Name": "Michanice",
                            "SingleAirportCity": true,
                            "CountryId": "DE",
                            "RegionId": "SACHS",
                            "Location": "13.45, 50.45",
                            "IataCode": "AOC",
                            "Airports": [
                                {
                                    "Id": "AOC",
                                    "Name": "Altenburg",
                                    "CityId": "ALTE",
                                    "CountryId": "DE",
                                    "RegionId": "SACHS",
                                    "Location": "12.506361, 50.981817"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SK",
                    "Name": "Slovakia",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "LUEA",
                            "Name": "Lucenec",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "19.666667, 48.333333",
                            "IataCode": "LUE",
                            "Airports": [
                                {
                                    "Id": "LUE",
                                    "Name": "Lucenec",
                                    "CityId": "LUEA",
                                    "CountryId": "SK",
                                    "Location": "19.666667, 48.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BRAS",
                            "Name": "Bratislava",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "17.116666, 48.15",
                            "IataCode": "BTS",
                            "Airports": [
                                {
                                    "Id": "BTS",
                                    "Name": "Bratislava",
                                    "CityId": "BRAS",
                                    "CountryId": "SK",
                                    "Location": "17.21047, 48.170213"
                                }
                            ]
                        },
                        {
                            "Id": "ZILI",
                            "Name": "Zilina D. Hricov",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "18.739415, 49.223147",
                            "IataCode": "ILZ",
                            "Airports": [
                                {
                                    "Id": "ILZ",
                                    "Name": "Zilina D. Hricov",
                                    "CityId": "ZILI",
                                    "CountryId": "SK",
                                    "Location": "18.614316, 49.233601"
                                }
                            ]
                        },
                        {
                            "Id": "KOSI",
                            "Name": "Kosice",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "21.25, 48.716669",
                            "IataCode": "KSC",
                            "Airports": [
                                {
                                    "Id": "KSC",
                                    "Name": "Kosice",
                                    "CityId": "KOSI",
                                    "CountryId": "SK",
                                    "Location": "21.25, 48.666667"
                                }
                            ]
                        },
                        {
                            "Id": "PZYA",
                            "Name": "Piestany",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "17.833333, 48.6",
                            "IataCode": "PZY",
                            "Airports": [
                                {
                                    "Id": "PZY",
                                    "Name": "Piestany",
                                    "CityId": "PZYA",
                                    "CountryId": "SK",
                                    "Location": "17.833333, 48.633333"
                                }
                            ]
                        },
                        {
                            "Id": "SLIA",
                            "Name": "Sliac",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "19.183333, 48.616667",
                            "IataCode": "SLD",
                            "Airports": [
                                {
                                    "Id": "SLD",
                                    "Name": "Sliac",
                                    "CityId": "SLIA",
                                    "CountryId": "SK",
                                    "Location": "19.133333, 48.633333"
                                }
                            ]
                        },
                        {
                            "Id": "POPR",
                            "Name": "Poprad",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "20.3, 49.05",
                            "IataCode": "TAT",
                            "Airports": [
                                {
                                    "Id": "TAT",
                                    "Name": "Poprad-Tatry",
                                    "CityId": "POPR",
                                    "CountryId": "SK",
                                    "Location": "20.240278, 49.071944"
                                }
                            ]
                        },
                        {
                            "Id": "POVA",
                            "Name": "Presov",
                            "SingleAirportCity": true,
                            "CountryId": "SK",
                            "Location": "21.25, 49",
                            "IataCode": "POV",
                            "Airports": [
                                {
                                    "Id": "POV",
                                    "Name": "Presov",
                                    "CityId": "POVA",
                                    "CountryId": "SK",
                                    "Location": "21.25, 49"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GG",
                    "Name": "Guernsey",
                    "CurrencyId": "GGP",
                    "Cities": [
                        {
                            "Id": "GUER",
                            "Name": "Guernsey",
                            "SingleAirportCity": true,
                            "CountryId": "GG",
                            "Location": "-2.4125494, 49.545815",
                            "IataCode": "GCI",
                            "Airports": [
                                {
                                    "Id": "GCI",
                                    "Name": "Guernsey",
                                    "CityId": "GUER",
                                    "CountryId": "GG",
                                    "Location": "-2.602778, 49.436111"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CE",
                    "Name": "Crimea",
                    "CurrencyId": "RUB",
                    "Cities": [
                        {
                            "Id": "KERC",
                            "Name": "Kerch",
                            "SingleAirportCity": true,
                            "CountryId": "CE",
                            "Location": "36.475834, 45.358333",
                            "IataCode": "KHC",
                            "Airports": [
                                {
                                    "Id": "KHC",
                                    "Name": "Kerch",
                                    "CityId": "KERC",
                                    "CountryId": "CE",
                                    "Location": "36.413959, 45.36661"
                                }
                            ]
                        },
                        {
                            "Id": "SIMF",
                            "Name": "Simferopol",
                            "SingleAirportCity": true,
                            "CountryId": "CE",
                            "Location": "34.1, 44.95",
                            "IataCode": "SIP",
                            "Airports": [
                                {
                                    "Id": "SIP",
                                    "Name": "Simferopol",
                                    "CityId": "SIMF",
                                    "CountryId": "CE",
                                    "Location": "33.975139, 45.052222"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "F",
            "Name": "Africa",
            "Countries": [
                {
                    "Id": "BJ",
                    "Name": "Benin",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "COOA",
                            "Name": "Cotonou",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "2.4333334, 6.35",
                            "IataCode": "COO",
                            "Airports": [
                                {
                                    "Id": "COO",
                                    "Name": "Cotonou",
                                    "CityId": "COOA",
                                    "CountryId": "BJ",
                                    "Location": "2.385556, 6.354167"
                                }
                            ]
                        },
                        {
                            "Id": "DJAA",
                            "Name": "Djougou",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "1.6669445, 9.705",
                            "IataCode": "DJA",
                            "Airports": [
                                {
                                    "Id": "DJA",
                                    "Name": "Djougou",
                                    "CityId": "DJAA",
                                    "CountryId": "BJ",
                                    "Location": "1.666667, 9.7"
                                }
                            ]
                        },
                        {
                            "Id": "KDCA",
                            "Name": "Kandi",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "2.9386111, 11.134167",
                            "IataCode": "KDC",
                            "Airports": [
                                {
                                    "Id": "KDC",
                                    "Name": "Kandi",
                                    "CityId": "KDCA",
                                    "CountryId": "BJ",
                                    "Location": "2.916667, 11.116667"
                                }
                            ]
                        },
                        {
                            "Id": "NAEA",
                            "Name": "Natitingou",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "1.3750001, 10.306667",
                            "IataCode": "NAE",
                            "Airports": [
                                {
                                    "Id": "NAE",
                                    "Name": "Natitingou",
                                    "CityId": "NAEA",
                                    "CountryId": "BJ",
                                    "Location": "1.366667, 10.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PKOA",
                            "Name": "Parakou",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "2.6166667, 9.35",
                            "IataCode": "PKO",
                            "Airports": [
                                {
                                    "Id": "PKO",
                                    "Name": "Parakou",
                                    "CityId": "PKOA",
                                    "CountryId": "BJ",
                                    "Location": "2.616667, 9.35"
                                }
                            ]
                        },
                        {
                            "Id": "SVFA",
                            "Name": "Save",
                            "SingleAirportCity": true,
                            "CountryId": "BJ",
                            "Location": "2.4833333, 8.0333333",
                            "IataCode": "SVF",
                            "Airports": [
                                {
                                    "Id": "SVF",
                                    "Name": "Save",
                                    "CityId": "SVFA",
                                    "CountryId": "BJ",
                                    "Location": "2.483333, 8.033333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CF",
                    "Name": "Central African Republic",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "BGFA",
                            "Name": "Bangui",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "18.583334, 4.3666667",
                            "IataCode": "BGF",
                            "Airports": [
                                {
                                    "Id": "BGF",
                                    "Name": "Bangui",
                                    "CityId": "BGFA",
                                    "CountryId": "CF",
                                    "Location": "18.520278, 4.396111"
                                }
                            ]
                        },
                        {
                            "Id": "BGUA",
                            "Name": "Bangassou",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "22.816667, 4.7333333",
                            "IataCode": "BGU",
                            "Airports": [
                                {
                                    "Id": "BGU",
                                    "Name": "Bangassou",
                                    "CityId": "BGUA",
                                    "CountryId": "CF",
                                    "Location": "22.784444, 4.786667"
                                }
                            ]
                        },
                        {
                            "Id": "BIVA",
                            "Name": "Bria",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "21.983333, 6.5333333",
                            "IataCode": "BIV",
                            "Airports": [
                                {
                                    "Id": "BIV",
                                    "Name": "Bria",
                                    "CityId": "BIVA",
                                    "CountryId": "CF",
                                    "Location": "21.983333, 6.533333"
                                }
                            ]
                        },
                        {
                            "Id": "BMFA",
                            "Name": "Bakouma",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "22.783333, 5.7",
                            "IataCode": "BMF",
                            "Airports": [
                                {
                                    "Id": "BMF",
                                    "Name": "Bakouma",
                                    "CityId": "BMFA",
                                    "CountryId": "CF",
                                    "Location": "22.8, 5.733333"
                                }
                            ]
                        },
                        {
                            "Id": "BOPA",
                            "Name": "Bouar",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "15.6, 5.95",
                            "IataCode": "BOP",
                            "Airports": [
                                {
                                    "Id": "BOP",
                                    "Name": "Bouar",
                                    "CityId": "BOPA",
                                    "CountryId": "CF",
                                    "Location": "15.666667, 6"
                                }
                            ]
                        },
                        {
                            "Id": "BOZA",
                            "Name": "Bozoum",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "16.383333, 6.3166667",
                            "IataCode": "BOZ",
                            "Airports": [
                                {
                                    "Id": "BOZ",
                                    "Name": "Bozoum",
                                    "CityId": "BOZA",
                                    "CountryId": "CF",
                                    "Location": "16.583333, 6.416667"
                                }
                            ]
                        },
                        {
                            "Id": "BSNA",
                            "Name": "Bossangoa",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "17.45, 6.4833333",
                            "IataCode": "BSN",
                            "Airports": [
                                {
                                    "Id": "BSN",
                                    "Name": "Bossangoa",
                                    "CityId": "BSNA",
                                    "CountryId": "CF",
                                    "Location": "17.5, 6.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BTGA",
                            "Name": "Batangafo",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "18.3, 7.3",
                            "IataCode": "BTG",
                            "Airports": [
                                {
                                    "Id": "BTG",
                                    "Name": "Batangafo",
                                    "CityId": "BTGA",
                                    "CountryId": "CF",
                                    "Location": "18.333333, 7.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CRFA",
                            "Name": "Carnot",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "15.866667, 4.9333333",
                            "IataCode": "CRF",
                            "Airports": [
                                {
                                    "Id": "CRF",
                                    "Name": "Carnot",
                                    "CityId": "CRFA",
                                    "CountryId": "CF",
                                    "Location": "15.933333, 4.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GDIA",
                            "Name": "Gordil",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "21.583333, 9.7333333",
                            "IataCode": "GDI",
                            "Airports": [
                                {
                                    "Id": "GDI",
                                    "Name": "Gordil",
                                    "CityId": "GDIA",
                                    "CountryId": "CF",
                                    "Location": "21.583333, 9.733333"
                                }
                            ]
                        },
                        {
                            "Id": "GDAA",
                            "Name": "Gounda",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "21.2, 9.05",
                            "IataCode": "GDA",
                            "Airports": [
                                {
                                    "Id": "GDA",
                                    "Name": "Gounda",
                                    "CityId": "GDAA",
                                    "CountryId": "CF",
                                    "Location": "21.166667, 9.333333"
                                }
                            ]
                        },
                        {
                            "Id": "IMOA",
                            "Name": "Zemio",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "25.133333, 5.0333333",
                            "IataCode": "IMO",
                            "Airports": [
                                {
                                    "Id": "IMO",
                                    "Name": "Zemio",
                                    "CityId": "IMOA",
                                    "CountryId": "CF",
                                    "Location": "25.083333, 5.033333"
                                }
                            ]
                        },
                        {
                            "Id": "IROA",
                            "Name": "Birao",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "22.783333, 10.283333",
                            "IataCode": "IRO",
                            "Airports": [
                                {
                                    "Id": "IRO",
                                    "Name": "Birao",
                                    "CityId": "IROA",
                                    "CountryId": "CF",
                                    "Location": "22.713889, 10.186389"
                                }
                            ]
                        },
                        {
                            "Id": "AIGA",
                            "Name": "Yalinga",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "23.25, 6.5166667",
                            "IataCode": "AIG",
                            "Airports": [
                                {
                                    "Id": "AIG",
                                    "Name": "Yalinga",
                                    "CityId": "AIGA",
                                    "CountryId": "CF",
                                    "Location": "23.25, 6.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BBTA",
                            "Name": "Berberati",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "15.783333, 4.2666667",
                            "IataCode": "BBT",
                            "Airports": [
                                {
                                    "Id": "BBT",
                                    "Name": "Berberati",
                                    "CityId": "BBTA",
                                    "CountryId": "CF",
                                    "Location": "15.788056, 4.219167"
                                }
                            ]
                        },
                        {
                            "Id": "BBYA",
                            "Name": "Bambari",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "20.667222, 5.7619444",
                            "IataCode": "BBY",
                            "Airports": [
                                {
                                    "Id": "BBY",
                                    "Name": "Bambari",
                                    "CityId": "BBYA",
                                    "CountryId": "CF",
                                    "Location": "20.649722, 5.845833"
                                }
                            ]
                        },
                        {
                            "Id": "BCFA",
                            "Name": "Bouca",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "18.283333, 6.5",
                            "IataCode": "BCF",
                            "Airports": [
                                {
                                    "Id": "BCF",
                                    "Name": "Bouca",
                                    "CityId": "BCFA",
                                    "CountryId": "CF",
                                    "Location": "18.416667, 6.75"
                                }
                            ]
                        },
                        {
                            "Id": "ODAA",
                            "Name": "Ouadda",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "22.4, 8.0666667",
                            "IataCode": "ODA",
                            "Airports": [
                                {
                                    "Id": "ODA",
                                    "Name": "Ouadda",
                                    "CityId": "ODAA",
                                    "CountryId": "CF",
                                    "Location": "22.401667, 8.003889"
                                }
                            ]
                        },
                        {
                            "Id": "ODJA",
                            "Name": "Ouanda Djalle",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "22.8, 8.9",
                            "IataCode": "ODJ",
                            "Airports": [
                                {
                                    "Id": "ODJ",
                                    "Name": "Ouanda Djalle",
                                    "CityId": "ODJA",
                                    "CountryId": "CF",
                                    "Location": "22.883333, 8.916667"
                                }
                            ]
                        },
                        {
                            "Id": "RFAA",
                            "Name": "Rafai",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "23.933333, 4.9666667",
                            "IataCode": "RFA",
                            "Airports": [
                                {
                                    "Id": "RFA",
                                    "Name": "Rafai",
                                    "CityId": "RFAA",
                                    "CountryId": "CF",
                                    "Location": "23.966667, 4.983333"
                                }
                            ]
                        },
                        {
                            "Id": "NDLA",
                            "Name": "Ndele",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "20.653056, 8.4091667",
                            "IataCode": "NDL",
                            "Airports": [
                                {
                                    "Id": "NDL",
                                    "Name": "Ndele",
                                    "CityId": "NDLA",
                                    "CountryId": "CF",
                                    "Location": "20.6, 8.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MKIA",
                            "Name": "M'Boki",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "25.966667, 5.3166667",
                            "IataCode": "MKI",
                            "Airports": [
                                {
                                    "Id": "MKI",
                                    "Name": "M'Boki",
                                    "CityId": "MKIA",
                                    "CountryId": "CF",
                                    "Location": "25.95, 5.316667"
                                }
                            ]
                        },
                        {
                            "Id": "KOLA",
                            "Name": "Kouyara",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "21.3, 8.1",
                            "IataCode": "KOL",
                            "Airports": [
                                {
                                    "Id": "KOL",
                                    "Name": "Koumala",
                                    "CityId": "KOLA",
                                    "CountryId": "CF",
                                    "Location": "21.25, 8.5"
                                }
                            ]
                        },
                        {
                            "Id": "KWDA",
                            "Name": "Kaouadja",
                            "SingleAirportCity": true,
                            "CountryId": "CF",
                            "Location": "23.516667, 7.8833333",
                            "IataCode": "KWD",
                            "Airports": [
                                {
                                    "Id": "KWD",
                                    "Name": "Kawadjia",
                                    "CityId": "KWDA",
                                    "CountryId": "CF",
                                    "Location": "23.5262, 7.96483"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KE",
                    "Name": "Kenya",
                    "CurrencyId": "KES",
                    "Cities": [
                        {
                            "Id": "NBOA",
                            "Name": "Nairobi",
                            "SingleAirportCity": false,
                            "CountryId": "KE",
                            "Location": "36.816668, -1.2833333",
                            "IataCode": "NBO",
                            "Airports": [
                                {
                                    "Id": "WIL",
                                    "Name": "Nairobi Wilson",
                                    "CityId": "NBOA",
                                    "CountryId": "KE",
                                    "Location": "36.816667, -1.316667"
                                },
                                {
                                    "Id": "NBO",
                                    "Name": "Nairobi Jomo Kenyatta",
                                    "CityId": "NBOA",
                                    "CountryId": "KE",
                                    "Location": "36.930833, -1.316667"
                                }
                            ]
                        },
                        {
                            "Id": "KTLA",
                            "Name": "Kitale",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35, 1.0166667",
                            "IataCode": "KTL",
                            "Airports": [
                                {
                                    "Id": "KTL",
                                    "Name": "Kitale",
                                    "CityId": "KTLA",
                                    "CountryId": "KE",
                                    "Location": "35, 1"
                                }
                            ]
                        },
                        {
                            "Id": "GASA",
                            "Name": "Garissa",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.658333, -0.4569444",
                            "IataCode": "GAS",
                            "Airports": [
                                {
                                    "Id": "GAS",
                                    "Name": "Garissa",
                                    "CityId": "GASA",
                                    "CountryId": "KE",
                                    "Location": "39.65, -0.45"
                                }
                            ]
                        },
                        {
                            "Id": "LOKA",
                            "Name": "Lodwar",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.6, 3.1166667",
                            "IataCode": "LOK",
                            "Airports": [
                                {
                                    "Id": "LOK",
                                    "Name": "Lodwar",
                                    "CityId": "LOKA",
                                    "CountryId": "KE",
                                    "Location": "35.6, 3.15"
                                }
                            ]
                        },
                        {
                            "Id": "BMQA",
                            "Name": "Bamburi",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.716667, -4",
                            "IataCode": "BMQ",
                            "Airports": [
                                {
                                    "Id": "BMQ",
                                    "Name": "Bamburi",
                                    "CityId": "BMQA",
                                    "CountryId": "KE",
                                    "Location": "39.833333, -4.5"
                                }
                            ]
                        },
                        {
                            "Id": "EDLA",
                            "Name": "Eldoret",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.283333, 0.5166667",
                            "IataCode": "EDL",
                            "Airports": [
                                {
                                    "Id": "EDL",
                                    "Name": "Eldoret",
                                    "CityId": "EDLA",
                                    "CountryId": "KE",
                                    "Location": "35.238889, 0.404444"
                                }
                            ]
                        },
                        {
                            "Id": "HOAA",
                            "Name": "Hola",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "40.028889, -1.5036111",
                            "IataCode": "HOA",
                            "Airports": [
                                {
                                    "Id": "HOA",
                                    "Name": "Hola",
                                    "CityId": "HOAA",
                                    "CountryId": "KE",
                                    "Location": "40, -1"
                                }
                            ]
                        },
                        {
                            "Id": "KEYA",
                            "Name": "Kericho",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.283333, -0.3666667",
                            "IataCode": "KEY",
                            "Airports": [
                                {
                                    "Id": "KEY",
                                    "Name": "Kericho",
                                    "CityId": "KEYA",
                                    "CountryId": "KE",
                                    "Location": "35.283333, -0.366667"
                                }
                            ]
                        },
                        {
                            "Id": "KISA",
                            "Name": "Kisumu",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "34.75, -0.1",
                            "IataCode": "KIS",
                            "Airports": [
                                {
                                    "Id": "KIS",
                                    "Name": "Kisumu",
                                    "CityId": "KISA",
                                    "CountryId": "KE",
                                    "Location": "34.728611, -0.085556"
                                }
                            ]
                        },
                        {
                            "Id": "LAUA",
                            "Name": "Lamu",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "40.903333, -2.2783333",
                            "IataCode": "LAU",
                            "Airports": [
                                {
                                    "Id": "LAU",
                                    "Name": "Lamu",
                                    "CityId": "LAUA",
                                    "CountryId": "KE",
                                    "Location": "40.911667, -2.25"
                                }
                            ]
                        },
                        {
                            "Id": "MBAA",
                            "Name": "Mombasa",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.666667, -4.05",
                            "IataCode": "MBA",
                            "Airports": [
                                {
                                    "Id": "MBA",
                                    "Name": "Mombasa",
                                    "CityId": "MBAA",
                                    "CountryId": "KE",
                                    "Location": "39.593056, -4.031389"
                                }
                            ]
                        },
                        {
                            "Id": "NYKA",
                            "Name": "Nanyuki",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "37.066667, 0.0166667",
                            "IataCode": "NYK",
                            "Airports": [
                                {
                                    "Id": "NYK",
                                    "Name": "Nanyuki",
                                    "CityId": "NYKA",
                                    "CountryId": "KE",
                                    "Location": "37.066667, 0.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ASVA",
                            "Name": "Amboseli",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "37.283333, -2.6666667",
                            "IataCode": "ASV",
                            "Airports": [
                                {
                                    "Id": "ASV",
                                    "Name": "Amboseli",
                                    "CityId": "ASVA",
                                    "CountryId": "KE",
                                    "Location": "37.249276, -2.645286"
                                }
                            ]
                        },
                        {
                            "Id": "KIUA",
                            "Name": "Kiunga",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "41.498611, -1.7447222",
                            "IataCode": "KIU",
                            "Airports": [
                                {
                                    "Id": "KIU",
                                    "Name": "Kiunga",
                                    "CityId": "KIUA",
                                    "CountryId": "KE",
                                    "Location": "41.516667, -1.733333"
                                }
                            ]
                        },
                        {
                            "Id": "MUMA",
                            "Name": "Mumias",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "34.489167, 0.3363889",
                            "IataCode": "MUM",
                            "Airports": [
                                {
                                    "Id": "MUM",
                                    "Name": "Mumias",
                                    "CityId": "MUMA",
                                    "CountryId": "KE",
                                    "Location": "34.483333, 0.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MYDA",
                            "Name": "Malindi",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "40.116667, -3.2166667",
                            "IataCode": "MYD",
                            "Airports": [
                                {
                                    "Id": "MYD",
                                    "Name": "Malindi",
                                    "CityId": "MYDA",
                                    "CountryId": "KE",
                                    "Location": "40.100556, -3.226111"
                                }
                            ]
                        },
                        {
                            "Id": "NUUA",
                            "Name": "Nakuru",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "36.066667, -0.2833333",
                            "IataCode": "NUU",
                            "Airports": [
                                {
                                    "Id": "NUU",
                                    "Name": "Nakuru",
                                    "CityId": "NUUA",
                                    "CountryId": "KE",
                                    "Location": "36.15, -0.3"
                                }
                            ]
                        },
                        {
                            "Id": "NYEA",
                            "Name": "Nyeri",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "36.95, -0.4166667",
                            "IataCode": "NYE",
                            "Airports": [
                                {
                                    "Id": "NYE",
                                    "Name": "Nyeri",
                                    "CityId": "NYEA",
                                    "CountryId": "KE",
                                    "Location": "36.95, -0.416667"
                                }
                            ]
                        },
                        {
                            "Id": "OYLA",
                            "Name": "Moyale",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.069167, 3.5163889",
                            "IataCode": "OYL",
                            "Airports": [
                                {
                                    "Id": "OYL",
                                    "Name": "Moyale",
                                    "CityId": "OYLA",
                                    "CountryId": "KE",
                                    "Location": "39.05, 3.533333"
                                }
                            ]
                        },
                        {
                            "Id": "RBTA",
                            "Name": "Marsabit",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "37.983333, 2.3333333",
                            "IataCode": "RBT",
                            "Airports": [
                                {
                                    "Id": "RBT",
                                    "Name": "Marsabit",
                                    "CityId": "RBTA",
                                    "CountryId": "KE",
                                    "Location": "37.983333, 2.333333"
                                }
                            ]
                        },
                        {
                            "Id": "UKAA",
                            "Name": "Ukunda",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.566667, -4.2833333",
                            "IataCode": "UKA",
                            "Airports": [
                                {
                                    "Id": "UKA",
                                    "Name": "Ukunda",
                                    "CityId": "UKAA",
                                    "CountryId": "KE",
                                    "Location": "39.566667, -4.3"
                                }
                            ]
                        },
                        {
                            "Id": "WJRA",
                            "Name": "Wajir",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "40.068889, 1.7472222",
                            "IataCode": "WJR",
                            "Airports": [
                                {
                                    "Id": "WJR",
                                    "Name": "Wajir",
                                    "CityId": "WJRA",
                                    "CountryId": "KE",
                                    "Location": "40.09161, 1.73324"
                                }
                            ]
                        },
                        {
                            "Id": "MREA",
                            "Name": "Maasai Mara",
                            "SingleAirportCity": false,
                            "CountryId": "KE",
                            "Location": "34.55, -1.5",
                            "IataCode": "MRE",
                            "Airports": [
                                {
                                    "Id": "MRE",
                                    "Name": "Mara Lodges",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.00974, -1.40534"
                                },
                                {
                                    "Id": "ANA",
                                    "Name": "Angama Mara",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "34.953816, -1.271505"
                                },
                                {
                                    "Id": "KEU",
                                    "Name": "Keekorok",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.251667, -1.585832"
                                },
                                {
                                    "Id": "OLX",
                                    "Name": "Olkiombo",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.11, -1.40861"
                                },
                                {
                                    "Id": "KTJ",
                                    "Name": "Kichwa Tembo",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.022648, -1.263771"
                                },
                                {
                                    "Id": "HKR",
                                    "Name": "Mara North",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.125807, -1.145191"
                                },
                                {
                                    "Id": "MDR",
                                    "Name": "Musiara",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.059631, -1.29928"
                                },
                                {
                                    "Id": "OLG",
                                    "Name": "Olare",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.244095, -1.323842"
                                },
                                {
                                    "Id": "OSJ",
                                    "Name": "Ol Seki",
                                    "CityId": "MREA",
                                    "CountryId": "KE",
                                    "Location": "35.37737, -1.378392"
                                }
                            ]
                        },
                        {
                            "Id": "VPGO",
                            "Name": "Vipingo",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "39.8, -3.8166667",
                            "IataCode": "VPG",
                            "Airports": [
                                {
                                    "Id": "VPG",
                                    "Name": "Vipingo Airstrip",
                                    "CityId": "VPGO",
                                    "CountryId": "KE",
                                    "Location": "39.805205, -3.842631"
                                }
                            ]
                        },
                        {
                            "Id": "NDEA",
                            "Name": "Mandera",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "41.846111, 3.9261111",
                            "IataCode": "NDE",
                            "Airports": [
                                {
                                    "Id": "NDE",
                                    "Name": "Mandera",
                                    "CityId": "NDEA",
                                    "CountryId": "KE",
                                    "Location": "41.866667, 3.933333"
                                }
                            ]
                        },
                        {
                            "Id": "LKGA",
                            "Name": "Lokichoggio",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "34.35, 4.2",
                            "IataCode": "LKG",
                            "Airports": [
                                {
                                    "Id": "LKG",
                                    "Name": "Lokichoggio",
                                    "CityId": "LKGA",
                                    "CountryId": "KE",
                                    "Location": "34.35, 4.233333"
                                }
                            ]
                        },
                        {
                            "Id": "KINN",
                            "Name": "Kinna",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "38.2, 0.3166667",
                            "IataCode": "JJM",
                            "Airports": [
                                {
                                    "Id": "JJM",
                                    "Name": "Kinna Meru",
                                    "CityId": "KINN",
                                    "CountryId": "KE",
                                    "Location": "38.1951, 0.1651"
                                }
                            ]
                        },
                        {
                            "Id": "KAKA",
                            "Name": "Kakamega",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "34.75, 0.2833333",
                            "IataCode": "GGM",
                            "Airports": [
                                {
                                    "Id": "GGM",
                                    "Name": "Kakamega",
                                    "CityId": "KAKA",
                                    "CountryId": "KE",
                                    "Location": "34.766965, 0.288068"
                                }
                            ]
                        },
                        {
                            "Id": "LBNA",
                            "Name": "Lake Baringo",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.966667, 0.4666667",
                            "IataCode": "LBN",
                            "Airports": [
                                {
                                    "Id": "LBN",
                                    "Name": "Lake Baringo",
                                    "CityId": "LBNA",
                                    "CountryId": "KE",
                                    "Location": "36.083333, 0.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KWYA",
                            "Name": "Kiwayu",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "41.276097, -2.005781",
                            "IataCode": "KWY",
                            "Airports": [
                                {
                                    "Id": "KWY",
                                    "Name": "Kiwayu",
                                    "CityId": "KWYA",
                                    "CountryId": "KE",
                                    "Location": "41.266667, -2.016667"
                                }
                            ]
                        },
                        {
                            "Id": "UASA",
                            "Name": "Archers Post",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "37.55, 0.466667",
                            "IataCode": "UAS",
                            "Airports": [
                                {
                                    "Id": "UAS",
                                    "Name": "Samburu",
                                    "CityId": "UASA",
                                    "CountryId": "KE",
                                    "Location": "37.55, 0.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LBKA",
                            "Name": "Dicu",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "41.25, 0.5333333",
                            "IataCode": "LBK",
                            "Airports": [
                                {
                                    "Id": "LBK",
                                    "Name": "Liboi",
                                    "CityId": "LBKA",
                                    "CountryId": "KE",
                                    "Location": "40.966667, 0.383333"
                                }
                            ]
                        },
                        {
                            "Id": "NZOA",
                            "Name": "Siamufua",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "34.066667, 0.1166667",
                            "IataCode": "NZO",
                            "Airports": [
                                {
                                    "Id": "NZO",
                                    "Name": "Nzoia",
                                    "CityId": "NZOA",
                                    "CountryId": "KE",
                                    "Location": "34.083333, 0.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LOYA",
                            "Name": "North Horr",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "37.066667, 3.3166667",
                            "IataCode": "LOY",
                            "Airports": [
                                {
                                    "Id": "LOY",
                                    "Name": "Loyangalani",
                                    "CityId": "LOYA",
                                    "CountryId": "KE",
                                    "Location": "36.716667, 2.75"
                                }
                            ]
                        },
                        {
                            "Id": "KRVA",
                            "Name": "Muriling",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.933333, 2.45",
                            "IataCode": "KRV",
                            "Airports": [
                                {
                                    "Id": "KRV",
                                    "Name": "Kerio Valley",
                                    "CityId": "KRVA",
                                    "CountryId": "KE",
                                    "Location": "36.116667, 2.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ILUA",
                            "Name": "Mangelete",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "38.133333, -2.7",
                            "IataCode": "ILU",
                            "Airports": [
                                {
                                    "Id": "ILU",
                                    "Name": "Kilaguni",
                                    "CityId": "ILUA",
                                    "CountryId": "KE",
                                    "Location": "38.05, -2.9"
                                }
                            ]
                        },
                        {
                            "Id": "LKUA",
                            "Name": "Lorugumu",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.25, 2.8833333",
                            "IataCode": "LKU",
                            "Airports": [
                                {
                                    "Id": "LKU",
                                    "Name": "Lake Rudolf",
                                    "CityId": "LKUA",
                                    "CountryId": "KE",
                                    "Location": "35.883333, 3.416667"
                                }
                            ]
                        },
                        {
                            "Id": "FERA",
                            "Name": "Lokwakangole",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.9, 3.5333333",
                            "IataCode": "FER",
                            "Airports": [
                                {
                                    "Id": "FER",
                                    "Name": "Fergusons Gulf",
                                    "CityId": "FERA",
                                    "CountryId": "KE",
                                    "Location": "35.833333, 3.483583"
                                }
                            ]
                        },
                        {
                            "Id": "EYSA",
                            "Name": "Loichangamatak",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "35.65, 2.6333333",
                            "IataCode": "EYS",
                            "Airports": [
                                {
                                    "Id": "EYS",
                                    "Name": "Eliye Springs",
                                    "CityId": "EYSA",
                                    "CountryId": "KE",
                                    "Location": "35.966667, 3.25"
                                }
                            ]
                        },
                        {
                            "Id": "KLKA",
                            "Name": "Kaloko",
                            "SingleAirportCity": true,
                            "CountryId": "KE",
                            "Location": "25.8, -6.7833333",
                            "IataCode": "KLK",
                            "Airports": [
                                {
                                    "Id": "KLK",
                                    "Name": "Kalokol",
                                    "CityId": "KLKA",
                                    "CountryId": "KE",
                                    "Location": "25.783333, -6.783333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BI",
                    "Name": "Burundi",
                    "CurrencyId": "BIF",
                    "Cities": [
                        {
                            "Id": "KREA",
                            "Name": "Kirundo",
                            "SingleAirportCity": true,
                            "CountryId": "BI",
                            "Location": "30.097222, -2.5847222",
                            "IataCode": "KRE",
                            "Airports": [
                                {
                                    "Id": "KRE",
                                    "Name": "Kirundo",
                                    "CityId": "KREA",
                                    "CountryId": "BI",
                                    "Location": "29, -3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BJMA",
                            "Name": "Bujumbura",
                            "SingleAirportCity": true,
                            "CountryId": "BI",
                            "Location": "29.36, -3.3761111",
                            "IataCode": "BJM",
                            "Airports": [
                                {
                                    "Id": "BJM",
                                    "Name": "Bujumbura",
                                    "CityId": "BJMA",
                                    "CountryId": "BI",
                                    "Location": "29.318333, -3.323889"
                                }
                            ]
                        },
                        {
                            "Id": "GIDA",
                            "Name": "Gitega",
                            "SingleAirportCity": true,
                            "CountryId": "BI",
                            "Location": "29.8444, -3.4269",
                            "IataCode": "GID",
                            "Airports": [
                                {
                                    "Id": "GID",
                                    "Name": "Gitega",
                                    "CityId": "GIDA",
                                    "CountryId": "BI",
                                    "Location": "29.916667, -3.416667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BF",
                    "Name": "Burkina Faso",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "XARA",
                            "Name": "Aribinda",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.87180003, 14.2265",
                            "IataCode": "XAR",
                            "Airports": [
                                {
                                    "Id": "XAR",
                                    "Name": "Aribinda",
                                    "CityId": "XARA",
                                    "CountryId": "BF",
                                    "Location": "-0.866667, 14.233333"
                                }
                            ]
                        },
                        {
                            "Id": "XBGA",
                            "Name": "Bogande",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.14361114, 12.971389",
                            "IataCode": "XBG",
                            "Airports": [
                                {
                                    "Id": "XBG",
                                    "Name": "Bogande",
                                    "CityId": "XBGA",
                                    "CountryId": "BF",
                                    "Location": "-0.133333, 12.983333"
                                }
                            ]
                        },
                        {
                            "Id": "XBOA",
                            "Name": "Boulsa",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.57583334, 12.6675",
                            "IataCode": "XBO",
                            "Airports": [
                                {
                                    "Id": "XBO",
                                    "Name": "Boulsa",
                                    "CityId": "XBOA",
                                    "CountryId": "BF",
                                    "Location": "-0.566667, 12.65"
                                }
                            ]
                        },
                        {
                            "Id": "XDEA",
                            "Name": "Diebougou",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-3.25, 10.966667",
                            "IataCode": "XDE",
                            "Airports": [
                                {
                                    "Id": "XDE",
                                    "Name": "Diebougou",
                                    "CityId": "XDEA",
                                    "CountryId": "BF",
                                    "Location": "-3.25, 10.966667"
                                }
                            ]
                        },
                        {
                            "Id": "XDJA",
                            "Name": "Djibo",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-1.6305556, 14.102222",
                            "IataCode": "XDJ",
                            "Airports": [
                                {
                                    "Id": "XDJ",
                                    "Name": "Djibo",
                                    "CityId": "XDJA",
                                    "CountryId": "BF",
                                    "Location": "-1.633333, 14.1"
                                }
                            ]
                        },
                        {
                            "Id": "XGGA",
                            "Name": "Gorom-Gorom",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.23611114, 14.443889",
                            "IataCode": "XGG",
                            "Airports": [
                                {
                                    "Id": "XGG",
                                    "Name": "Gorom-Gorom",
                                    "CityId": "XGGA",
                                    "CountryId": "BF",
                                    "Location": "-0.233333, 14.433333"
                                }
                            ]
                        },
                        {
                            "Id": "XKAA",
                            "Name": "Kantchari",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "1.5122222, 12.478611",
                            "IataCode": "XKA",
                            "Airports": [
                                {
                                    "Id": "XKA",
                                    "Name": "Kantchari",
                                    "CityId": "XKAA",
                                    "CountryId": "BF",
                                    "Location": "1.516667, 12.483333"
                                }
                            ]
                        },
                        {
                            "Id": "XKYA",
                            "Name": "Kaya",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-1.0844444, 13.091667",
                            "IataCode": "XKY",
                            "Airports": [
                                {
                                    "Id": "XKY",
                                    "Name": "Kaya",
                                    "CityId": "XKYA",
                                    "CountryId": "BF",
                                    "Location": "-1.083333, 13.083333"
                                }
                            ]
                        },
                        {
                            "Id": "XLUA",
                            "Name": "Leo",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-2.1, 11.1",
                            "IataCode": "XLU",
                            "Airports": [
                                {
                                    "Id": "XLU",
                                    "Name": "Leo",
                                    "CityId": "XLUA",
                                    "CountryId": "BF",
                                    "Location": "-2.1, 11.1"
                                }
                            ]
                        },
                        {
                            "Id": "XNUA",
                            "Name": "Nouna",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-3.8666667, 12.733333",
                            "IataCode": "XNU",
                            "Airports": [
                                {
                                    "Id": "XNU",
                                    "Name": "Nouna",
                                    "CityId": "XNUA",
                                    "CountryId": "BF",
                                    "Location": "-3.866667, 12.733333"
                                }
                            ]
                        },
                        {
                            "Id": "XPAA",
                            "Name": "Pama",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "0.70750003, 11.249722",
                            "IataCode": "XPA",
                            "Airports": [
                                {
                                    "Id": "XPA",
                                    "Name": "Pama",
                                    "CityId": "XPAA",
                                    "CountryId": "BF",
                                    "Location": "0.7, 11.25"
                                }
                            ]
                        },
                        {
                            "Id": "XSEA",
                            "Name": "Sebba",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "0.53044303, 13.436412",
                            "IataCode": "XSE",
                            "Airports": [
                                {
                                    "Id": "XSE",
                                    "Name": "Sebba",
                                    "CityId": "XSEA",
                                    "CountryId": "BF",
                                    "Location": "0.533333, 13.433333"
                                }
                            ]
                        },
                        {
                            "Id": "XZAA",
                            "Name": "Zabre",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.63222223, 11.1825",
                            "IataCode": "XZA",
                            "Airports": [
                                {
                                    "Id": "XZA",
                                    "Name": "Zabre",
                                    "CityId": "XZAA",
                                    "CountryId": "BF",
                                    "Location": "-1, 11.133333"
                                }
                            ]
                        },
                        {
                            "Id": "XGAA",
                            "Name": "Gaoua",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-3.2508333, 10.299167",
                            "IataCode": "XGA",
                            "Airports": [
                                {
                                    "Id": "XGA",
                                    "Name": "Gaoua",
                                    "CityId": "XGAA",
                                    "CountryId": "BF",
                                    "Location": "-3.183333, 10.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BNRA",
                            "Name": "Banfora",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-4.7666666, 10.633333",
                            "IataCode": "BNR",
                            "Airports": [
                                {
                                    "Id": "BNR",
                                    "Name": "Banfora",
                                    "CityId": "BNRA",
                                    "CountryId": "BF",
                                    "Location": "-4.666667, 10.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DGUA",
                            "Name": "Dedougou",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-3.4666667, 12.466667",
                            "IataCode": "DGU",
                            "Airports": [
                                {
                                    "Id": "DGU",
                                    "Name": "Dedougou",
                                    "CityId": "DGUA",
                                    "CountryId": "BF",
                                    "Location": "-3.483333, 12.466667"
                                }
                            ]
                        },
                        {
                            "Id": "DIPA",
                            "Name": "Diapaga",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "1.7888889, 12.070833",
                            "IataCode": "DIP",
                            "Airports": [
                                {
                                    "Id": "DIP",
                                    "Name": "Diapaga",
                                    "CityId": "DIPA",
                                    "CountryId": "BF",
                                    "Location": "2.033333, 12.033333"
                                }
                            ]
                        },
                        {
                            "Id": "DORA",
                            "Name": "Dori",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.033333366, 14.033333",
                            "IataCode": "DOR",
                            "Airports": [
                                {
                                    "Id": "DOR",
                                    "Name": "Dori",
                                    "CityId": "DORA",
                                    "CountryId": "BF",
                                    "Location": "-0.033333, 14.033333"
                                }
                            ]
                        },
                        {
                            "Id": "FNGA",
                            "Name": "Fada Ngourma",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "0.35777785, 12.062222",
                            "IataCode": "FNG",
                            "Airports": [
                                {
                                    "Id": "FNG",
                                    "Name": "Fada Ngourma",
                                    "CityId": "FNGA",
                                    "CountryId": "BF",
                                    "Location": "0.35, 12.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OUAA",
                            "Name": "Ouagadougou",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-1.5247225, 12.370278",
                            "IataCode": "OUA",
                            "Airports": [
                                {
                                    "Id": "OUA",
                                    "Name": "Ouagadougou",
                                    "CityId": "OUAA",
                                    "CountryId": "BF",
                                    "Location": "-1.512778, 12.352778"
                                }
                            ]
                        },
                        {
                            "Id": "OUGA",
                            "Name": "Ouahigouya",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-2.4166667, 13.583333",
                            "IataCode": "OUG",
                            "Airports": [
                                {
                                    "Id": "OUG",
                                    "Name": "Ouahigouya",
                                    "CityId": "OUGA",
                                    "CountryId": "BF",
                                    "Location": "-2.333333, 13.516667"
                                }
                            ]
                        },
                        {
                            "Id": "TEGA",
                            "Name": "Tenkodogo",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-0.36972226, 11.78",
                            "IataCode": "TEG",
                            "Airports": [
                                {
                                    "Id": "TEG",
                                    "Name": "Tenkodogo",
                                    "CityId": "TEGA",
                                    "CountryId": "BF",
                                    "Location": "-0.316667, 11.9"
                                }
                            ]
                        },
                        {
                            "Id": "TUQA",
                            "Name": "Tougan",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-3.0666667, 13.066667",
                            "IataCode": "TUQ",
                            "Airports": [
                                {
                                    "Id": "TUQ",
                                    "Name": "Tougan",
                                    "CityId": "TUQA",
                                    "CountryId": "BF",
                                    "Location": "-3, 13"
                                }
                            ]
                        },
                        {
                            "Id": "ARLA",
                            "Name": "Arly",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "1.4666667, 11.583333",
                            "IataCode": "ARL",
                            "Airports": [
                                {
                                    "Id": "ARL",
                                    "Name": "Arly",
                                    "CityId": "ARLA",
                                    "CountryId": "BF",
                                    "Location": "1.483333, 11.6"
                                }
                            ]
                        },
                        {
                            "Id": "PUPA",
                            "Name": "Po",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-1.145, 11.169722",
                            "IataCode": "PUP",
                            "Airports": [
                                {
                                    "Id": "PUP",
                                    "Name": "Po",
                                    "CityId": "PUPA",
                                    "CountryId": "BF",
                                    "Location": "-1.166667, 11.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BOYA",
                            "Name": "Bobo Dioulasso",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "-4.2999999, 11.2",
                            "IataCode": "BOY",
                            "Airports": [
                                {
                                    "Id": "BOY",
                                    "Name": "Bobo Dioulasso",
                                    "CityId": "BOYA",
                                    "CountryId": "BF",
                                    "Location": "-4.324444, 11.163611"
                                }
                            ]
                        },
                        {
                            "Id": "TMQA",
                            "Name": "Markoy",
                            "SingleAirportCity": true,
                            "CountryId": "BF",
                            "Location": "0.036111142, 14.643333",
                            "IataCode": "TMQ",
                            "Airports": [
                                {
                                    "Id": "TMQ",
                                    "Name": "Tambao",
                                    "CityId": "TMQA",
                                    "CountryId": "BF",
                                    "Location": "0.083333, 14.783333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KM",
                    "Name": "Comoros",
                    "CurrencyId": "KMF",
                    "Cities": [
                        {
                            "Id": "YVAA",
                            "Name": "Moroni",
                            "SingleAirportCity": false,
                            "CountryId": "KM",
                            "Location": "43.240278, -11.704167",
                            "IataCode": "YVA",
                            "Airports": [
                                {
                                    "Id": "HAH",
                                    "Name": "Moroni Prince Said Ibrahim In",
                                    "CityId": "YVAA",
                                    "CountryId": "KM",
                                    "Location": "43.266667, -11.533333"
                                },
                                {
                                    "Id": "YVA",
                                    "Name": "Moroni Iconi",
                                    "CityId": "YVAA",
                                    "CountryId": "KM",
                                    "Location": "43.243089, -11.711452"
                                }
                            ]
                        },
                        {
                            "Id": "AJNA",
                            "Name": "Anjouan",
                            "SingleAirportCity": true,
                            "CountryId": "KM",
                            "Location": "44.416667, -12.25",
                            "IataCode": "AJN",
                            "Airports": [
                                {
                                    "Id": "AJN",
                                    "Name": "Anjouan",
                                    "CityId": "AJNA",
                                    "CountryId": "KM",
                                    "Location": "44.429722, -12.126389"
                                }
                            ]
                        },
                        {
                            "Id": "NWAA",
                            "Name": "Moheli",
                            "SingleAirportCity": true,
                            "CountryId": "KM",
                            "Location": "43.75, -12.25",
                            "IataCode": "NWA",
                            "Airports": [
                                {
                                    "Id": "NWA",
                                    "Name": "Moheli",
                                    "CityId": "NWAA",
                                    "CountryId": "KM",
                                    "Location": "43.766667, -12.283333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ZM",
                    "Name": "Zambia",
                    "CurrencyId": "ZMK",
                    "Cities": [
                        {
                            "Id": "ZGMA",
                            "Name": "Ngoma",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "25.95, -15.933333",
                            "IataCode": "ZGM",
                            "Airports": [
                                {
                                    "Id": "ZGM",
                                    "Name": "Ngoma",
                                    "CityId": "ZGMA",
                                    "CountryId": "ZM",
                                    "Location": "25.934444, -15.958333"
                                }
                            ]
                        },
                        {
                            "Id": "CGJA",
                            "Name": "Chingola",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "27.85, -12.533333",
                            "IataCode": "CGJ",
                            "Airports": [
                                {
                                    "Id": "CGJ",
                                    "Name": "Chingola",
                                    "CityId": "CGJA",
                                    "CountryId": "ZM",
                                    "Location": "27.883333, -12.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BBZA",
                            "Name": "Zambezi",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "23.116667, -13.55",
                            "IataCode": "BBZ",
                            "Airports": [
                                {
                                    "Id": "BBZ",
                                    "Name": "Zambezi",
                                    "CityId": "BBZA",
                                    "CountryId": "ZM",
                                    "Location": "23.11, -13.537222"
                                }
                            ]
                        },
                        {
                            "Id": "LXUA",
                            "Name": "Lukulu",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "23.25, -14.383333",
                            "IataCode": "LXU",
                            "Airports": [
                                {
                                    "Id": "LXU",
                                    "Name": "Lukulu",
                                    "CityId": "LXUA",
                                    "CountryId": "ZM",
                                    "Location": "23.2, -14.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CIPA",
                            "Name": "Chipata",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "32.65, -13.633333",
                            "IataCode": "CIP",
                            "Airports": [
                                {
                                    "Id": "CIP",
                                    "Name": "Chipata",
                                    "CityId": "CIPA",
                                    "CountryId": "ZM",
                                    "Location": "32.586136, -13.557224"
                                }
                            ]
                        },
                        {
                            "Id": "KAAA",
                            "Name": "Kasama",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "31.2, -10.2",
                            "IataCode": "KAA",
                            "Airports": [
                                {
                                    "Id": "KAA",
                                    "Name": "Kasama",
                                    "CityId": "KAAA",
                                    "CountryId": "ZM",
                                    "Location": "31.13, -10.215833"
                                }
                            ]
                        },
                        {
                            "Id": "KMZA",
                            "Name": "Kaoma",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "24.8, -14.783333",
                            "IataCode": "KMZ",
                            "Airports": [
                                {
                                    "Id": "KMZ",
                                    "Name": "Kaoma",
                                    "CityId": "KMZA",
                                    "CountryId": "ZM",
                                    "Location": "24.803333, -14.798889"
                                }
                            ]
                        },
                        {
                            "Id": "KIWA",
                            "Name": "Kitwe",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "28.2, -12.816667",
                            "IataCode": "KIW",
                            "Airports": [
                                {
                                    "Id": "KIW",
                                    "Name": "Kitwe",
                                    "CityId": "KIWA",
                                    "CountryId": "ZM",
                                    "Location": "28.15075, -12.90042"
                                }
                            ]
                        },
                        {
                            "Id": "KLBA",
                            "Name": "Kalabo",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "22.683333, -14.966667",
                            "IataCode": "KLB",
                            "Airports": [
                                {
                                    "Id": "KLB",
                                    "Name": "Kalabo",
                                    "CityId": "KLBA",
                                    "CountryId": "ZM",
                                    "Location": "22.644444, -14.975833"
                                }
                            ]
                        },
                        {
                            "Id": "LUNA",
                            "Name": "Lusaka",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "28.283333, -15.416667",
                            "IataCode": "LUN",
                            "Airports": [
                                {
                                    "Id": "LUN",
                                    "Name": "Lusaka",
                                    "CityId": "LUNA",
                                    "CountryId": "ZM",
                                    "Location": "28.449783, -15.329221"
                                }
                            ]
                        },
                        {
                            "Id": "LVIA",
                            "Name": "Livingstone",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "25.866667, -17.85",
                            "IataCode": "LVI",
                            "Airports": [
                                {
                                    "Id": "LVI",
                                    "Name": "Livingstone",
                                    "CityId": "LVIA",
                                    "CountryId": "ZM",
                                    "Location": "25.822678, -17.821262"
                                }
                            ]
                        },
                        {
                            "Id": "MMQA",
                            "Name": "Mbala",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "31.383333, -8.8333333",
                            "IataCode": "MMQ",
                            "Airports": [
                                {
                                    "Id": "MMQ",
                                    "Name": "Mbala",
                                    "CityId": "MMQA",
                                    "CountryId": "ZM",
                                    "Location": "31.336111, -8.857222"
                                }
                            ]
                        },
                        {
                            "Id": "MNSA",
                            "Name": "Mansa",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "28.883333, -11.2",
                            "IataCode": "MNS",
                            "Airports": [
                                {
                                    "Id": "MNS",
                                    "Name": "Mansa",
                                    "CityId": "MNSA",
                                    "CountryId": "ZM",
                                    "Location": "28.866667, -11.125"
                                }
                            ]
                        },
                        {
                            "Id": "SLIB",
                            "Name": "Solwezi",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "26.4, -12.183333",
                            "IataCode": "SLI",
                            "Airports": [
                                {
                                    "Id": "SLI",
                                    "Name": "Solwezi",
                                    "CityId": "SLIB",
                                    "CountryId": "ZM",
                                    "Location": "26.366667, -12.172222"
                                }
                            ]
                        },
                        {
                            "Id": "MFUA",
                            "Name": "Mfuwe",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "31.92275, -12.894979",
                            "IataCode": "MFU",
                            "Airports": [
                                {
                                    "Id": "MFU",
                                    "Name": "Mfuwe",
                                    "CityId": "MFUA",
                                    "CountryId": "ZM",
                                    "Location": "31.933334, -13.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MNRA",
                            "Name": "Mongu",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "23.133333, -15.25",
                            "IataCode": "MNR",
                            "Airports": [
                                {
                                    "Id": "MNR",
                                    "Name": "Mongu",
                                    "CityId": "MNRA",
                                    "CountryId": "ZM",
                                    "Location": "23.157778, -15.225556"
                                }
                            ]
                        },
                        {
                            "Id": "SJQA",
                            "Name": "Sesheke",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "24.3, -17.466667",
                            "IataCode": "SJQ",
                            "Airports": [
                                {
                                    "Id": "SJQ",
                                    "Name": "Sesheke",
                                    "CityId": "SJQA",
                                    "CountryId": "ZM",
                                    "Location": "24.3, -17.46667"
                                }
                            ]
                        },
                        {
                            "Id": "SXGA",
                            "Name": "Senanga",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "23.266667, -16.116667",
                            "IataCode": "SXG",
                            "Airports": [
                                {
                                    "Id": "SXG",
                                    "Name": "Senanga",
                                    "CityId": "SXGA",
                                    "CountryId": "ZM",
                                    "Location": "23.281944, -16.119444"
                                }
                            ]
                        },
                        {
                            "Id": "NLAA",
                            "Name": "Ndola",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "28.633333, -12.966667",
                            "IataCode": "NLA",
                            "Airports": [
                                {
                                    "Id": "NLA",
                                    "Name": "Ndola",
                                    "CityId": "NLAA",
                                    "CountryId": "ZM",
                                    "Location": "28.661957, -12.99586"
                                }
                            ]
                        },
                        {
                            "Id": "ZKBA",
                            "Name": "Wanyima",
                            "SingleAirportCity": true,
                            "CountryId": "ZM",
                            "Location": "30.75, -8.6166667",
                            "IataCode": "ZKB",
                            "Airports": [
                                {
                                    "Id": "ZKB",
                                    "Name": "Kasaba Bay",
                                    "CityId": "ZKBA",
                                    "CountryId": "ZM",
                                    "Location": "30.659722, -8.522222"
                                }
                            ]
                        },
                        {
                            "Id": "ZAMB",
                            "Name": "Kasala Tiki",
                            "SingleAirportCity": false,
                            "CountryId": "ZM",
                            "Location": "29.566667, -15.233333",
                            "IataCode": "RYL",
                            "Airports": [
                                {
                                    "Id": "RYL",
                                    "Name": "Royal",
                                    "CityId": "ZAMB",
                                    "CountryId": "ZM",
                                    "Location": "29.296679, -15.726728"
                                },
                                {
                                    "Id": "JEK",
                                    "Name": "Jeki",
                                    "CityId": "ZAMB",
                                    "CountryId": "ZM",
                                    "Location": "29.603556, -15.633121"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BW",
                    "Name": "Botswana",
                    "CurrencyId": "BWP",
                    "Cities": [
                        {
                            "Id": "PKWA",
                            "Name": "Selebi-Phikwe",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "27.917222, -21.966111",
                            "IataCode": "PKW",
                            "Airports": [
                                {
                                    "Id": "PKW",
                                    "Name": "Selebi-Phikwe",
                                    "CityId": "PKWA",
                                    "CountryId": "BW",
                                    "Location": "27.816667, -22.05"
                                }
                            ]
                        },
                        {
                            "Id": "FRWA",
                            "Name": "Francistown",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "27.516667, -21.166667",
                            "IataCode": "FRW",
                            "Airports": [
                                {
                                    "Id": "FRW",
                                    "Name": "Francistown",
                                    "CityId": "FRWA",
                                    "CountryId": "BW",
                                    "Location": "27.4825, -21.16"
                                }
                            ]
                        },
                        {
                            "Id": "GBEA",
                            "Name": "Gaborone",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "25.911945, -24.646389",
                            "IataCode": "GBE",
                            "Airports": [
                                {
                                    "Id": "GBE",
                                    "Name": "Gaborone",
                                    "CityId": "GBEA",
                                    "CountryId": "BW",
                                    "Location": "25.91821, -24.55522"
                                }
                            ]
                        },
                        {
                            "Id": "HUKA",
                            "Name": "Hukuntsi",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "21.75, -24",
                            "IataCode": "HUK",
                            "Airports": [
                                {
                                    "Id": "HUK",
                                    "Name": "Hukuntsi",
                                    "CityId": "HUKA",
                                    "CountryId": "BW",
                                    "Location": "21.75, -23.983333"
                                }
                            ]
                        },
                        {
                            "Id": "MUBA",
                            "Name": "Maun",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "23.416667, -19.983333",
                            "IataCode": "MUB",
                            "Airports": [
                                {
                                    "Id": "MUB",
                                    "Name": "Maun",
                                    "CityId": "MUBA",
                                    "CountryId": "BW",
                                    "Location": "23.430556, -19.970833"
                                }
                            ]
                        },
                        {
                            "Id": "BBKA",
                            "Name": "Kasane",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "25.15, -17.816667",
                            "IataCode": "BBK",
                            "Airports": [
                                {
                                    "Id": "BBK",
                                    "Name": "Kasane",
                                    "CityId": "BBKA",
                                    "CountryId": "BW",
                                    "Location": "25.1624, -17.83288"
                                }
                            ]
                        },
                        {
                            "Id": "LOQA",
                            "Name": "Lobatse",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "25.666667, -25.216667",
                            "IataCode": "LOQ",
                            "Airports": [
                                {
                                    "Id": "LOQ",
                                    "Name": "Lobatse",
                                    "CityId": "LOQA",
                                    "CountryId": "BW",
                                    "Location": "25.681944, -25.190278"
                                }
                            ]
                        },
                        {
                            "Id": "ORPA",
                            "Name": "Orapa",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "25.366667, -21.283333",
                            "IataCode": "ORP",
                            "Airports": [
                                {
                                    "Id": "ORP",
                                    "Name": "Orapa",
                                    "CityId": "ORPA",
                                    "CountryId": "BW",
                                    "Location": "24.5, -21"
                                }
                            ]
                        },
                        {
                            "Id": "SWXA",
                            "Name": "Shakawe",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "21.85, -18.366667",
                            "IataCode": "SWX",
                            "Airports": [
                                {
                                    "Id": "SWX",
                                    "Name": "Shakawe",
                                    "CityId": "SWXA",
                                    "CountryId": "BW",
                                    "Location": "21.833333, -18.383333"
                                }
                            ]
                        },
                        {
                            "Id": "TBYA",
                            "Name": "Tsabong",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "22.45, -26.05",
                            "IataCode": "TBY",
                            "Airports": [
                                {
                                    "Id": "TBY",
                                    "Name": "Tsabong",
                                    "CityId": "TBYA",
                                    "CountryId": "BW",
                                    "Location": "22.466667, -26.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GNZA",
                            "Name": "Ghanzi",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "22.599599, -22.294158",
                            "IataCode": "GNZ",
                            "Airports": [
                                {
                                    "Id": "GNZ",
                                    "Name": "Ghanzi",
                                    "CityId": "GNZA",
                                    "CountryId": "BW",
                                    "Location": "21.666667, -21.7"
                                }
                            ]
                        },
                        {
                            "Id": "TLDA",
                            "Name": "Tuli",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "29.2, -21.916667",
                            "IataCode": "TLD",
                            "Airports": [
                                {
                                    "Id": "TLD",
                                    "Name": "Tuli Block",
                                    "CityId": "TLDA",
                                    "CountryId": "BW",
                                    "Location": "29.116667, -22.183333"
                                }
                            ]
                        },
                        {
                            "Id": "KHWA",
                            "Name": "Tlogoeankwes",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "23.416667, -19.116667",
                            "IataCode": "KHW",
                            "Airports": [
                                {
                                    "Id": "KHW",
                                    "Name": "Khwai River Lodge",
                                    "CityId": "KHWA",
                                    "CountryId": "BW",
                                    "Location": "23.5, -19"
                                }
                            ]
                        },
                        {
                            "Id": "JWAA",
                            "Name": "Lokerane",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "24.666667, -24.883333",
                            "IataCode": "JWA",
                            "Airports": [
                                {
                                    "Id": "JWA",
                                    "Name": "Jwaneng",
                                    "CityId": "JWAA",
                                    "CountryId": "BW",
                                    "Location": "24.666667, -24.6"
                                }
                            ]
                        },
                        {
                            "Id": "SVTA",
                            "Name": "Kakoaka",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "24.366667, -18.666667",
                            "IataCode": "SVT",
                            "Airports": [
                                {
                                    "Id": "SVT",
                                    "Name": "Savuti",
                                    "CityId": "SVTA",
                                    "CountryId": "BW",
                                    "Location": "24.083333, -18.633333"
                                }
                            ]
                        },
                        {
                            "Id": "SXNA",
                            "Name": "Dukwe",
                            "SingleAirportCity": true,
                            "CountryId": "BW",
                            "Location": "26.416667, -20.583333",
                            "IataCode": "SXN",
                            "Airports": [
                                {
                                    "Id": "SXN",
                                    "Name": "Suapan",
                                    "CityId": "SXNA",
                                    "CountryId": "BW",
                                    "Location": "26.116667, -20.55"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CD",
                    "Name": "DR Congo",
                    "CurrencyId": "CDF",
                    "Cities": [
                        {
                            "Id": "YANA",
                            "Name": "Yangambi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "24.466667, 0.7833333",
                            "IataCode": "YAN",
                            "Airports": [
                                {
                                    "Id": "YAN",
                                    "Name": "Yangambi",
                                    "CityId": "YANA",
                                    "CountryId": "CD",
                                    "Location": "24.4, 0.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BDVA",
                            "Name": "Moba",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "29.783333, -7.05",
                            "IataCode": "BDV",
                            "Airports": [
                                {
                                    "Id": "BDV",
                                    "Name": "Moba",
                                    "CityId": "BDVA",
                                    "CountryId": "CD",
                                    "Location": "29.8, -7"
                                }
                            ]
                        },
                        {
                            "Id": "BNCA",
                            "Name": "Beni",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "29.466667, 0.5",
                            "IataCode": "BNC",
                            "Airports": [
                                {
                                    "Id": "BNC",
                                    "Name": "Beni",
                                    "CityId": "BNCA",
                                    "CountryId": "CD",
                                    "Location": "29.466667, 0.566667"
                                }
                            ]
                        },
                        {
                            "Id": "LBOA",
                            "Name": "Lusambo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "23.45, -4.9666667",
                            "IataCode": "LBO",
                            "Airports": [
                                {
                                    "Id": "LBO",
                                    "Name": "Lusambo",
                                    "CityId": "LBOA",
                                    "CountryId": "CD",
                                    "Location": "23.45, -4.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BKYA",
                            "Name": "Bukavu",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "28.860833, -2.5083333",
                            "IataCode": "BKY",
                            "Airports": [
                                {
                                    "Id": "BKY",
                                    "Name": "Bukavu",
                                    "CityId": "BKYA",
                                    "CountryId": "CD",
                                    "Location": "28.816667, -2.3"
                                }
                            ]
                        },
                        {
                            "Id": "BNBA",
                            "Name": "Boende",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "20.866667, -0.2166667",
                            "IataCode": "BNB",
                            "Airports": [
                                {
                                    "Id": "BNB",
                                    "Name": "Boende",
                                    "CityId": "BNBA",
                                    "CountryId": "CD",
                                    "Location": "20.87, -0.233056"
                                }
                            ]
                        },
                        {
                            "Id": "BOAA",
                            "Name": "Boma",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "13.05, -5.85",
                            "IataCode": "BOA",
                            "Airports": [
                                {
                                    "Id": "BOA",
                                    "Name": "Boma",
                                    "CityId": "BOAA",
                                    "CountryId": "CD",
                                    "Location": "13.066667, -5.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BSUA",
                            "Name": "Basankusu",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "19.8, 1.2333333",
                            "IataCode": "BSU",
                            "Airports": [
                                {
                                    "Id": "BSU",
                                    "Name": "Basankusu",
                                    "CityId": "BSUA",
                                    "CountryId": "CD",
                                    "Location": "19.788889, 1.221667"
                                }
                            ]
                        },
                        {
                            "Id": "BUXA",
                            "Name": "Bunia",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "30.25, 1.5666667",
                            "IataCode": "BUX",
                            "Airports": [
                                {
                                    "Id": "BUX",
                                    "Name": "Bunia",
                                    "CityId": "BUXA",
                                    "CountryId": "CD",
                                    "Location": "30.212778, 1.571667"
                                }
                            ]
                        },
                        {
                            "Id": "FBMA",
                            "Name": "Lubumbashi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "27.466667, -11.666667",
                            "IataCode": "FBM",
                            "Airports": [
                                {
                                    "Id": "FBM",
                                    "Name": "Lubumbashi",
                                    "CityId": "FBMA",
                                    "CountryId": "CD",
                                    "Location": "27.529678, -11.591369"
                                }
                            ]
                        },
                        {
                            "Id": "FDUA",
                            "Name": "Bandundu",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "17.366666, -3.3166667",
                            "IataCode": "FDU",
                            "Airports": [
                                {
                                    "Id": "FDU",
                                    "Name": "Bandundu",
                                    "CityId": "FDUA",
                                    "CountryId": "CD",
                                    "Location": "17.383333, -3.305556"
                                }
                            ]
                        },
                        {
                            "Id": "FIHA",
                            "Name": "Kinshasa",
                            "SingleAirportCity": false,
                            "CountryId": "CD",
                            "Location": "15.314999, -4.3297256",
                            "IataCode": "FIH",
                            "Airports": [
                                {
                                    "Id": "FIH",
                                    "Name": "Kinshasa N'Djili",
                                    "CityId": "FIHA",
                                    "CountryId": "CD",
                                    "Location": "15.433334, -4.383333"
                                },
                                {
                                    "Id": "NLO",
                                    "Name": "Kinshasa N'Dolo",
                                    "CityId": "FIHA",
                                    "CountryId": "CD",
                                    "Location": "15.326389, -4.325"
                                }
                            ]
                        },
                        {
                            "Id": "FKIA",
                            "Name": "Kisangani",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "25.2, 0.5166667",
                            "IataCode": "FKI",
                            "Airports": [
                                {
                                    "Id": "FKI",
                                    "Name": "Kisangani",
                                    "CityId": "FKIA",
                                    "CountryId": "CD",
                                    "Location": "25.338, 0.48164"
                                }
                            ]
                        },
                        {
                            "Id": "FMIA",
                            "Name": "Kalemie",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "29.194708, -5.9474851",
                            "IataCode": "FMI",
                            "Airports": [
                                {
                                    "Id": "FMI",
                                    "Name": "Kalemie",
                                    "CityId": "FMIA",
                                    "CountryId": "CD",
                                    "Location": "29.245278, -5.878333"
                                }
                            ]
                        },
                        {
                            "Id": "GDJA",
                            "Name": "Gandajika",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "23.95, -6.75",
                            "IataCode": "GDJ",
                            "Airports": [
                                {
                                    "Id": "GDJ",
                                    "Name": "Gandajika",
                                    "CityId": "GDJA",
                                    "CountryId": "CD",
                                    "Location": "23.966667, -6.766667"
                                }
                            ]
                        },
                        {
                            "Id": "GOMA",
                            "Name": "Goma",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "29.222777, -1.6791667",
                            "IataCode": "GOM",
                            "Airports": [
                                {
                                    "Id": "GOM",
                                    "Name": "Goma",
                                    "CityId": "GOMA",
                                    "CountryId": "CD",
                                    "Location": "29.235, -1.67"
                                }
                            ]
                        },
                        {
                            "Id": "KOOA",
                            "Name": "Kongolo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "27, -5.3833333",
                            "IataCode": "KOO",
                            "Airports": [
                                {
                                    "Id": "KOO",
                                    "Name": "Kongolo",
                                    "CityId": "KOOA",
                                    "CountryId": "CD",
                                    "Location": "27, -5.383333"
                                }
                            ]
                        },
                        {
                            "Id": "IDFA",
                            "Name": "Idiofa",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "19.6, -5.0333333",
                            "IataCode": "IDF",
                            "Airports": [
                                {
                                    "Id": "IDF",
                                    "Name": "Idiofa",
                                    "CityId": "IDFA",
                                    "CountryId": "CD",
                                    "Location": "19.6, -5.033333"
                                }
                            ]
                        },
                        {
                            "Id": "IKLA",
                            "Name": "Ikela",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "23.266667, -1.1833333",
                            "IataCode": "IKL",
                            "Airports": [
                                {
                                    "Id": "IKL",
                                    "Name": "Ikela",
                                    "CityId": "IKLA",
                                    "CountryId": "CD",
                                    "Location": "23.666667, -1.666667"
                                }
                            ]
                        },
                        {
                            "Id": "INOA",
                            "Name": "Inongo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "18.266667, -1.95",
                            "IataCode": "INO",
                            "Airports": [
                                {
                                    "Id": "INO",
                                    "Name": "Inongo",
                                    "CityId": "INOA",
                                    "CountryId": "CD",
                                    "Location": "18.279167, -1.95"
                                }
                            ]
                        },
                        {
                            "Id": "IRPA",
                            "Name": "Isiro",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "27.616667, 2.7666667",
                            "IataCode": "IRP",
                            "Airports": [
                                {
                                    "Id": "IRP",
                                    "Name": "Isiro",
                                    "CityId": "IRPA",
                                    "CountryId": "CD",
                                    "Location": "27.630833, 2.781944"
                                }
                            ]
                        },
                        {
                            "Id": "KMNA",
                            "Name": "Kamina",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "24.990556, -8.7386111",
                            "IataCode": "KMN",
                            "Airports": [
                                {
                                    "Id": "KMN",
                                    "Name": "Kamina",
                                    "CityId": "KMNA",
                                    "CountryId": "CD",
                                    "Location": "25.25, -8.641111"
                                }
                            ]
                        },
                        {
                            "Id": "KNDA",
                            "Name": "Kindu",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "25.95, -2.95",
                            "IataCode": "KND",
                            "Airports": [
                                {
                                    "Id": "KND",
                                    "Name": "Kindu",
                                    "CityId": "KNDA",
                                    "CountryId": "CD",
                                    "Location": "25.913889, -2.9225"
                                }
                            ]
                        },
                        {
                            "Id": "KAPA",
                            "Name": "Kapanga",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "22.583333, -8.35",
                            "IataCode": "KAP",
                            "Airports": [
                                {
                                    "Id": "KAP",
                                    "Name": "Kapanga",
                                    "CityId": "KAPA",
                                    "CountryId": "CD",
                                    "Location": "22.666667, -8.5"
                                }
                            ]
                        },
                        {
                            "Id": "KNMA",
                            "Name": "Kaniama",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "24.183333, -7.5666667",
                            "IataCode": "KNM",
                            "Airports": [
                                {
                                    "Id": "KNM",
                                    "Name": "Kaniama",
                                    "CityId": "KNMA",
                                    "CountryId": "CD",
                                    "Location": "24.183333, -7.516667"
                                }
                            ]
                        },
                        {
                            "Id": "KBNA",
                            "Name": "Kabinda",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "24.483333, -6.1333333",
                            "IataCode": "KBN",
                            "Airports": [
                                {
                                    "Id": "KBN",
                                    "Name": "Kabinda",
                                    "CityId": "KBNA",
                                    "CountryId": "CD",
                                    "Location": "24.533333, -6.116667"
                                }
                            ]
                        },
                        {
                            "Id": "KBOA",
                            "Name": "Kabalo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26.916667, -6.05",
                            "IataCode": "KBO",
                            "Airports": [
                                {
                                    "Id": "KBO",
                                    "Name": "Kabalo",
                                    "CityId": "KBOA",
                                    "CountryId": "CD",
                                    "Location": "26.916667, -6.088056"
                                }
                            ]
                        },
                        {
                            "Id": "KRZA",
                            "Name": "Kiri",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "19, -1.45",
                            "IataCode": "KRZ",
                            "Airports": [
                                {
                                    "Id": "KRZ",
                                    "Name": "Kiri",
                                    "CityId": "KRZA",
                                    "CountryId": "CD",
                                    "Location": "19, -1.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KGAA",
                            "Name": "Kananga",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "22.417778, -5.8958333",
                            "IataCode": "KGA",
                            "Airports": [
                                {
                                    "Id": "KGA",
                                    "Name": "Kananga",
                                    "CityId": "KGAA",
                                    "CountryId": "CD",
                                    "Location": "22.469167, -5.9"
                                }
                            ]
                        },
                        {
                            "Id": "KKWA",
                            "Name": "Kikwit",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "18.818056, -5.0386111",
                            "IataCode": "KKW",
                            "Airports": [
                                {
                                    "Id": "KKW",
                                    "Name": "Kikwit",
                                    "CityId": "KKWA",
                                    "CountryId": "CD",
                                    "Location": "18.780278, -5.036111"
                                }
                            ]
                        },
                        {
                            "Id": "KLYA",
                            "Name": "Kalima",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26.616667, -2.5666667",
                            "IataCode": "KLY",
                            "Airports": [
                                {
                                    "Id": "KLY",
                                    "Name": "Kalima",
                                    "CityId": "KLYA",
                                    "CountryId": "CD",
                                    "Location": "26.533333, -2.55"
                                }
                            ]
                        },
                        {
                            "Id": "KWZA",
                            "Name": "Kolwezi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "25.4725, -10.716667",
                            "IataCode": "KWZ",
                            "Airports": [
                                {
                                    "Id": "KWZ",
                                    "Name": "Kolwezi",
                                    "CityId": "KWZA",
                                    "CountryId": "CD",
                                    "Location": "25.506946, -10.766667"
                                }
                            ]
                        },
                        {
                            "Id": "LIEA",
                            "Name": "Libenge",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "18.633333, 3.65",
                            "IataCode": "LIE",
                            "Airports": [
                                {
                                    "Id": "LIE",
                                    "Name": "Libenge",
                                    "CityId": "LIEA",
                                    "CountryId": "CD",
                                    "Location": "18.638889, 3.623611"
                                }
                            ]
                        },
                        {
                            "Id": "LIQA",
                            "Name": "Lisala",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "21.516667, 2.15",
                            "IataCode": "LIQ",
                            "Airports": [
                                {
                                    "Id": "LIQ",
                                    "Name": "Lisala",
                                    "CityId": "LIQA",
                                    "CountryId": "CD",
                                    "Location": "21.495833, 2.172222"
                                }
                            ]
                        },
                        {
                            "Id": "LJAA",
                            "Name": "Lodja",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "23.433333, -3.4833333",
                            "IataCode": "LJA",
                            "Airports": [
                                {
                                    "Id": "LJA",
                                    "Name": "Lodja",
                                    "CityId": "LJAA",
                                    "CountryId": "CD",
                                    "Location": "23.6181, -3.463657"
                                }
                            ]
                        },
                        {
                            "Id": "LZAA",
                            "Name": "Luiza",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "22.416667, -7.2",
                            "IataCode": "LZA",
                            "Airports": [
                                {
                                    "Id": "LZA",
                                    "Name": "Luiza",
                                    "CityId": "LZAA",
                                    "CountryId": "CD",
                                    "Location": "22.5, -7.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LZIA",
                            "Name": "Luozi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "14.133333, -4.95",
                            "IataCode": "LZI",
                            "Airports": [
                                {
                                    "Id": "LZI",
                                    "Name": "Luozi",
                                    "CityId": "LZIA",
                                    "CountryId": "CD",
                                    "Location": "14, -4.9"
                                }
                            ]
                        },
                        {
                            "Id": "NIOA",
                            "Name": "Nioki",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "17.683333, -2.7166667",
                            "IataCode": "NIO",
                            "Airports": [
                                {
                                    "Id": "NIO",
                                    "Name": "Nioki",
                                    "CityId": "NIOA",
                                    "CountryId": "CD",
                                    "Location": "17.7, -2.75"
                                }
                            ]
                        },
                        {
                            "Id": "BANA",
                            "Name": "Basongo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "20.4, -4.3333333",
                            "IataCode": "BAN",
                            "Airports": [
                                {
                                    "Id": "BAN",
                                    "Name": "Basongo",
                                    "CityId": "BANA",
                                    "CountryId": "CD",
                                    "Location": "20.428056, -4.313333"
                                }
                            ]
                        },
                        {
                            "Id": "MATA",
                            "Name": "Matadi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "13.45, -5.8166667",
                            "IataCode": "MAT",
                            "Airports": [
                                {
                                    "Id": "MAT",
                                    "Name": "Matadi",
                                    "CityId": "MATA",
                                    "CountryId": "CD",
                                    "Location": "13.441667, -5.798333"
                                }
                            ]
                        },
                        {
                            "Id": "MDKA",
                            "Name": "Mbandaka",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "18.266667, 0.0666667",
                            "IataCode": "MDK",
                            "Airports": [
                                {
                                    "Id": "MDK",
                                    "Name": "Mbandaka",
                                    "CityId": "MDKA",
                                    "CountryId": "CD",
                                    "Location": "18.292222, 0.023333"
                                }
                            ]
                        },
                        {
                            "Id": "MEWA",
                            "Name": "Mweka",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "21.566667, -4.85",
                            "IataCode": "MEW",
                            "Airports": [
                                {
                                    "Id": "MEW",
                                    "Name": "Mweka",
                                    "CityId": "MEWA",
                                    "CountryId": "CD",
                                    "Location": "21.566667, -4.85"
                                }
                            ]
                        },
                        {
                            "Id": "MJMA",
                            "Name": "Mbuji Mayi",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "23.6, -6.15",
                            "IataCode": "MJM",
                            "Airports": [
                                {
                                    "Id": "MJM",
                                    "Name": "Mbuji Mayi",
                                    "CityId": "MJMA",
                                    "CountryId": "CD",
                                    "Location": "23.633334, -6.15"
                                }
                            ]
                        },
                        {
                            "Id": "MNBA",
                            "Name": "Moanda",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "12.35, -5.9333333",
                            "IataCode": "MNB",
                            "Airports": [
                                {
                                    "Id": "MNB",
                                    "Name": "Moanda",
                                    "CityId": "MNBA",
                                    "CountryId": "CD",
                                    "Location": "12.352778, -5.926944"
                                }
                            ]
                        },
                        {
                            "Id": "MNOA",
                            "Name": "Manono",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "27.416667, -7.3",
                            "IataCode": "MNO",
                            "Airports": [
                                {
                                    "Id": "MNO",
                                    "Name": "Manono",
                                    "CityId": "MNOA",
                                    "CountryId": "CD",
                                    "Location": "27.393889, -7.288056"
                                }
                            ]
                        },
                        {
                            "Id": "MSMA",
                            "Name": "Masi Manimba",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "17.916667, -4.7666667",
                            "IataCode": "MSM",
                            "Airports": [
                                {
                                    "Id": "MSM",
                                    "Name": "Masi Manimba",
                                    "CityId": "MSMA",
                                    "CountryId": "CD",
                                    "Location": "17.916667, -4.766667"
                                }
                            ]
                        },
                        {
                            "Id": "PFRA",
                            "Name": "Ilebo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "20.583333, -4.3166667",
                            "IataCode": "PFR",
                            "Airports": [
                                {
                                    "Id": "PFR",
                                    "Name": "Ilebo",
                                    "CityId": "PFRA",
                                    "CountryId": "CD",
                                    "Location": "20.583333, -4.316667"
                                }
                            ]
                        },
                        {
                            "Id": "PUNA",
                            "Name": "Punia",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26.45, -1.4666667",
                            "IataCode": "PUN",
                            "Airports": [
                                {
                                    "Id": "PUN",
                                    "Name": "Punia",
                                    "CityId": "PUNA",
                                    "CountryId": "CD",
                                    "Location": "26.333333, -1.366667"
                                }
                            ]
                        },
                        {
                            "Id": "PWOA",
                            "Name": "Pweto",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "28.9, -8.4666667",
                            "IataCode": "PWO",
                            "Airports": [
                                {
                                    "Id": "PWO",
                                    "Name": "Pweto",
                                    "CityId": "PWOA",
                                    "CountryId": "CD",
                                    "Location": "28.9, -8.466667"
                                }
                            ]
                        },
                        {
                            "Id": "TSHA",
                            "Name": "Tshikapa",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "20.8, -6.4166667",
                            "IataCode": "TSH",
                            "Airports": [
                                {
                                    "Id": "TSH",
                                    "Name": "Tshikapa",
                                    "CityId": "TSHA",
                                    "CountryId": "CD",
                                    "Location": "20.797222, -6.441111"
                                }
                            ]
                        },
                        {
                            "Id": "KILA",
                            "Name": "Kilwa",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "28.05, -8.4",
                            "IataCode": "KIL",
                            "Airports": [
                                {
                                    "Id": "KIL",
                                    "Name": "Kilwa",
                                    "CityId": "KILA",
                                    "CountryId": "CD",
                                    "Location": "28.05, -8.4"
                                }
                            ]
                        },
                        {
                            "Id": "KGNA",
                            "Name": "Kasongo Lunda",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26.666667, -4.45",
                            "IataCode": "KGN",
                            "Airports": [
                                {
                                    "Id": "KGN",
                                    "Name": "Kasongo Lunda",
                                    "CityId": "KGNA",
                                    "CountryId": "CD",
                                    "Location": "26.616667, -4.532096"
                                }
                            ]
                        },
                        {
                            "Id": "DICA",
                            "Name": "Dili",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26.783333, 3.4166667",
                            "IataCode": "DIC",
                            "Airports": [
                                {
                                    "Id": "DIC",
                                    "Name": "Dili",
                                    "CityId": "DICA",
                                    "CountryId": "CD",
                                    "Location": "26.783333, 3.4"
                                }
                            ]
                        },
                        {
                            "Id": "BZUA",
                            "Name": "Buta",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "24.733333, 2.8",
                            "IataCode": "BZU",
                            "Airports": [
                                {
                                    "Id": "BZU",
                                    "Name": "Buta",
                                    "CityId": "BZUA",
                                    "CountryId": "CD",
                                    "Location": "24.733333, 2.8"
                                }
                            ]
                        },
                        {
                            "Id": "BMBA",
                            "Name": "Bumba",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "22.466667, 2.1833333",
                            "IataCode": "BMB",
                            "Airports": [
                                {
                                    "Id": "BMB",
                                    "Name": "Bumba",
                                    "CityId": "BMBA",
                                    "CountryId": "CD",
                                    "Location": "22.473333, 2.19"
                                }
                            ]
                        },
                        {
                            "Id": "KECA",
                            "Name": "Kasenga",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "28.633333, -10.366667",
                            "IataCode": "KEC",
                            "Airports": [
                                {
                                    "Id": "KEC",
                                    "Name": "Kasenga",
                                    "CityId": "KECA",
                                    "CountryId": "CD",
                                    "Location": "28.75, -10.333333"
                                }
                            ]
                        },
                        {
                            "Id": "NKLA",
                            "Name": "Nkolo",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "16.466667, -1.9833333",
                            "IataCode": "NKL",
                            "Airports": [
                                {
                                    "Id": "NKL",
                                    "Name": "Nkolo",
                                    "CityId": "NKLA",
                                    "CountryId": "CD",
                                    "Location": "16.466667, -1.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GMAA",
                            "Name": "Gemena",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "19.766667, 3.25",
                            "IataCode": "GMA",
                            "Airports": [
                                {
                                    "Id": "GMA",
                                    "Name": "Gemena",
                                    "CityId": "GMAA",
                                    "CountryId": "CD",
                                    "Location": "19.768611, 3.235"
                                }
                            ]
                        },
                        {
                            "Id": "KLIA",
                            "Name": "Yenge",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "21.583333, -3.75",
                            "IataCode": "KLI",
                            "Airports": [
                                {
                                    "Id": "KLI",
                                    "Name": "Kota Koli",
                                    "CityId": "KLIA",
                                    "CountryId": "CD",
                                    "Location": "21.75, 4.165403"
                                }
                            ]
                        },
                        {
                            "Id": "BDTA",
                            "Name": "Yakoma",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "22.45, 4.0833333",
                            "IataCode": "BDT",
                            "Airports": [
                                {
                                    "Id": "BDT",
                                    "Name": "Gbadolite",
                                    "CityId": "BDTA",
                                    "CountryId": "CD",
                                    "Location": "22.45, 4.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LUSA",
                            "Name": "Lusangaye",
                            "SingleAirportCity": true,
                            "CountryId": "CD",
                            "Location": "26, -4.9",
                            "IataCode": "LUS",
                            "Airports": [
                                {
                                    "Id": "LUS",
                                    "Name": "Lusanga",
                                    "CityId": "LUSA",
                                    "CountryId": "CD",
                                    "Location": "26, -4.9"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CV",
                    "Name": "Cape Verde",
                    "CurrencyId": "CVE",
                    "Cities": [
                        {
                            "Id": "RAIA",
                            "Name": "Praia",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-23.516666, 14.916667",
                            "IataCode": "RAI",
                            "Airports": [
                                {
                                    "Id": "RAI",
                                    "Name": "Praia",
                                    "CityId": "RAIA",
                                    "CountryId": "CV",
                                    "Location": "-23.503056, 14.925278"
                                }
                            ]
                        },
                        {
                            "Id": "SFLA",
                            "Name": "Sao Filipe",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-24.516667, 14.9",
                            "IataCode": "SFL",
                            "Airports": [
                                {
                                    "Id": "SFL",
                                    "Name": "Sao Filipe",
                                    "CityId": "SFLA",
                                    "CountryId": "CV",
                                    "Location": "-24.48, 14.885"
                                }
                            ]
                        },
                        {
                            "Id": "MTIA",
                            "Name": "Mosteiros",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-24.338336, 15.002947",
                            "IataCode": "MTI",
                            "Airports": [
                                {
                                    "Id": "MTI",
                                    "Name": "Mosteiros",
                                    "CityId": "MTIA",
                                    "CountryId": "CV",
                                    "Location": "-24.433333, 15"
                                }
                            ]
                        },
                        {
                            "Id": "MMOA",
                            "Name": "Maio",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-23.216667, 15.133333",
                            "IataCode": "MMO",
                            "Airports": [
                                {
                                    "Id": "MMO",
                                    "Name": "Vila Do Maio",
                                    "CityId": "MMOA",
                                    "CountryId": "CV",
                                    "Location": "-23.166667, 15.25"
                                }
                            ]
                        },
                        {
                            "Id": "NTOA",
                            "Name": "Santo Antao",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-25.171111, 17.07",
                            "IataCode": "NTO",
                            "Airports": [
                                {
                                    "Id": "NTO",
                                    "Name": "Santo Antao",
                                    "CityId": "NTOA",
                                    "CountryId": "CV",
                                    "Location": "-25.083333, 17.066667"
                                }
                            ]
                        },
                        {
                            "Id": "VXEA",
                            "Name": "Sao Vicente",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-24.966667, 16.85",
                            "IataCode": "VXE",
                            "Airports": [
                                {
                                    "Id": "VXE",
                                    "Name": "Sao Vicente",
                                    "CityId": "VXEA",
                                    "CountryId": "CV",
                                    "Location": "-24.966667, 16.85"
                                }
                            ]
                        },
                        {
                            "Id": "SNEA",
                            "Name": "Sao Nicolau",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-24.271111, 16.615",
                            "IataCode": "SNE",
                            "Airports": [
                                {
                                    "Id": "SNE",
                                    "Name": "Sao Nicolau",
                                    "CityId": "SNEA",
                                    "CountryId": "CV",
                                    "Location": "-24.288611, 16.586944"
                                }
                            ]
                        },
                        {
                            "Id": "BVCA",
                            "Name": "Boa Vista",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-22.803497, 16.103315",
                            "IataCode": "BVC",
                            "Airports": [
                                {
                                    "Id": "BVC",
                                    "Name": "Boa Vista",
                                    "CityId": "BVCA",
                                    "CountryId": "CV",
                                    "Location": "-22.890508, 16.13489"
                                }
                            ]
                        },
                        {
                            "Id": "SIDA",
                            "Name": "Sal",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-22.933333, 16.716667",
                            "IataCode": "SID",
                            "Airports": [
                                {
                                    "Id": "SID",
                                    "Name": "Sal",
                                    "CityId": "SIDA",
                                    "CountryId": "CV",
                                    "Location": "-22.951667, 16.745"
                                }
                            ]
                        },
                        {
                            "Id": "BVRA",
                            "Name": "Brava",
                            "SingleAirportCity": true,
                            "CountryId": "CV",
                            "Location": "-24.7, 14.866667",
                            "IataCode": "BVR",
                            "Airports": [
                                {
                                    "Id": "BVR",
                                    "Name": "Brava",
                                    "CityId": "BVRA",
                                    "CountryId": "CV",
                                    "Location": "-24.733333, 14.866667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CM",
                    "Name": "Cameroon",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "YAOA",
                            "Name": "Yaounde",
                            "SingleAirportCity": false,
                            "CountryId": "CM",
                            "Location": "11.516666, 3.8666667",
                            "IataCode": "YAO",
                            "Airports": [
                                {
                                    "Id": "YAO",
                                    "Name": "Yaounde",
                                    "CityId": "YAOA",
                                    "CountryId": "CM",
                                    "Location": "11.523611, 3.833611"
                                },
                                {
                                    "Id": "NSI",
                                    "Name": "Yaounde Nsimalen",
                                    "CityId": "YAOA",
                                    "CountryId": "CM",
                                    "Location": "11.55327, 3.72256"
                                }
                            ]
                        },
                        {
                            "Id": "BLCA",
                            "Name": "Bali",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.016667, 5.8833333",
                            "IataCode": "BLC",
                            "Airports": [
                                {
                                    "Id": "BLC",
                                    "Name": "Bali",
                                    "CityId": "BLCA",
                                    "CountryId": "CM",
                                    "Location": "10.031667, 5.897222"
                                }
                            ]
                        },
                        {
                            "Id": "BPCA",
                            "Name": "Bamenda",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.166667, 5.9333333",
                            "IataCode": "BPC",
                            "Airports": [
                                {
                                    "Id": "BPC",
                                    "Name": "Bamenda",
                                    "CityId": "BPCA",
                                    "CountryId": "CM",
                                    "Location": "10.15, 5.916667"
                                }
                            ]
                        },
                        {
                            "Id": "BTAA",
                            "Name": "Bertoua",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "13.683333, 4.5833333",
                            "IataCode": "BTA",
                            "Airports": [
                                {
                                    "Id": "BTA",
                                    "Name": "Bertoua",
                                    "CityId": "BTAA",
                                    "CountryId": "CM",
                                    "Location": "13.7275, 4.540833"
                                }
                            ]
                        },
                        {
                            "Id": "DLAA",
                            "Name": "Douala",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.6999998, 4.0502778",
                            "IataCode": "DLA",
                            "Airports": [
                                {
                                    "Id": "DLA",
                                    "Name": "Douala",
                                    "CityId": "DLAA",
                                    "CountryId": "CM",
                                    "Location": "9.709167, 4.013333"
                                }
                            ]
                        },
                        {
                            "Id": "DSCA",
                            "Name": "Dschang",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.066667, 5.45",
                            "IataCode": "DSC",
                            "Airports": [
                                {
                                    "Id": "DSC",
                                    "Name": "Dschang",
                                    "CityId": "DSCA",
                                    "CountryId": "CM",
                                    "Location": "10.091667, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "EBWA",
                            "Name": "Ebolowa",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "11.15, 2.9",
                            "IataCode": "EBW",
                            "Airports": [
                                {
                                    "Id": "EBW",
                                    "Name": "Ebolowa",
                                    "CityId": "EBWA",
                                    "CountryId": "CM",
                                    "Location": "11.166667, 2.916667"
                                }
                            ]
                        },
                        {
                            "Id": "FOMA",
                            "Name": "Foumban",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.916667, 5.7166667",
                            "IataCode": "FOM",
                            "Airports": [
                                {
                                    "Id": "FOM",
                                    "Name": "Foumban",
                                    "CityId": "FOMA",
                                    "CountryId": "CM",
                                    "Location": "10.833333, 5.75"
                                }
                            ]
                        },
                        {
                            "Id": "GOUA",
                            "Name": "Garoua",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "13.4, 9.3",
                            "IataCode": "GOU",
                            "Airports": [
                                {
                                    "Id": "GOU",
                                    "Name": "Garoua",
                                    "CityId": "GOUA",
                                    "CountryId": "CM",
                                    "Location": "13.375556, 9.336111"
                                }
                            ]
                        },
                        {
                            "Id": "GXXA",
                            "Name": "Yagoua",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "15.240556, 10.342778",
                            "IataCode": "GXX",
                            "Airports": [
                                {
                                    "Id": "GXX",
                                    "Name": "Yagoua",
                                    "CityId": "GXXA",
                                    "CountryId": "CM",
                                    "Location": "15.2375, 10.358333"
                                }
                            ]
                        },
                        {
                            "Id": "KBIA",
                            "Name": "Kribi",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.9166667, 2.95",
                            "IataCode": "KBI",
                            "Airports": [
                                {
                                    "Id": "KBI",
                                    "Name": "Kribi",
                                    "CityId": "KBIA",
                                    "CountryId": "CM",
                                    "Location": "9.916667, 2.95"
                                }
                            ]
                        },
                        {
                            "Id": "KLEA",
                            "Name": "Kaele",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "14.450833, 10.109167",
                            "IataCode": "KLE",
                            "Airports": [
                                {
                                    "Id": "KLE",
                                    "Name": "Kaele",
                                    "CityId": "KLEA",
                                    "CountryId": "CM",
                                    "Location": "14.45, 10.116667"
                                }
                            ]
                        },
                        {
                            "Id": "OURA",
                            "Name": "Batouri",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "14.366667, 4.4333333",
                            "IataCode": "OUR",
                            "Airports": [
                                {
                                    "Id": "OUR",
                                    "Name": "Batouri",
                                    "CityId": "OURA",
                                    "CountryId": "CM",
                                    "Location": "14.363611, 4.473056"
                                }
                            ]
                        },
                        {
                            "Id": "MVRA",
                            "Name": "Maroua",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "14.324722, 10.595556",
                            "IataCode": "MVR",
                            "Airports": [
                                {
                                    "Id": "MVR",
                                    "Name": "Maroua",
                                    "CityId": "MVRA",
                                    "CountryId": "CM",
                                    "Location": "14.256389, 10.452222"
                                }
                            ]
                        },
                        {
                            "Id": "VCCA",
                            "Name": "Limbe",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.2202778, 4.0127778",
                            "IataCode": "VCC",
                            "Airports": [
                                {
                                    "Id": "VCC",
                                    "Name": "Limbe",
                                    "CityId": "VCCA",
                                    "CountryId": "CM",
                                    "Location": "9.2, 4.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MMFA",
                            "Name": "Mamfe",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.2833333, 5.7666667",
                            "IataCode": "MMF",
                            "Airports": [
                                {
                                    "Id": "MMF",
                                    "Name": "Mamfe",
                                    "CityId": "MMFA",
                                    "CountryId": "CM",
                                    "Location": "9.3, 5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "NKSA",
                            "Name": "Nkongsamba",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.9324999, 4.9533333",
                            "IataCode": "NKS",
                            "Airports": [
                                {
                                    "Id": "NKS",
                                    "Name": "Nkongsamba",
                                    "CityId": "NKSA",
                                    "CountryId": "CM",
                                    "Location": "9.933333, 4.95"
                                }
                            ]
                        },
                        {
                            "Id": "TKCA",
                            "Name": "Tiko",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "9.3680556, 4.0786111",
                            "IataCode": "TKC",
                            "Airports": [
                                {
                                    "Id": "TKC",
                                    "Name": "Tiko",
                                    "CityId": "TKCA",
                                    "CountryId": "CM",
                                    "Location": "9.333333, 4.116667"
                                }
                            ]
                        },
                        {
                            "Id": "NGEA",
                            "Name": "Ngaoundere",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "13.583333, 7.3166667",
                            "IataCode": "NGE",
                            "Airports": [
                                {
                                    "Id": "NGE",
                                    "Name": "Ngaoundere",
                                    "CityId": "NGEA",
                                    "CountryId": "CM",
                                    "Location": "13.561389, 7.356389"
                                }
                            ]
                        },
                        {
                            "Id": "KOBA",
                            "Name": "Koutaba",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.816667, 5.7",
                            "IataCode": "KOB",
                            "Airports": [
                                {
                                    "Id": "KOB",
                                    "Name": "Koutaba",
                                    "CityId": "KOBA",
                                    "CountryId": "CM",
                                    "Location": "10.75, 5.65"
                                }
                            ]
                        },
                        {
                            "Id": "BFXA",
                            "Name": "Bafoussam",
                            "SingleAirportCity": true,
                            "CountryId": "CM",
                            "Location": "10.416666, 5.4666667",
                            "IataCode": "BFX",
                            "Airports": [
                                {
                                    "Id": "BFX",
                                    "Name": "Bafoussam",
                                    "CityId": "BFXA",
                                    "CountryId": "CM",
                                    "Location": "10.4, 5.483333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LR",
                    "Name": "Liberia",
                    "CurrencyId": "LRD",
                    "Cities": [
                        {
                            "Id": "CPAA",
                            "Name": "Cape Palmas",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-7.7169444, 4.375",
                            "IataCode": "CPA",
                            "Airports": [
                                {
                                    "Id": "CPA",
                                    "Name": "Cape Palmas",
                                    "CityId": "CPAA",
                                    "CountryId": "LR",
                                    "Location": "-7.695556, 4.376667"
                                }
                            ]
                        },
                        {
                            "Id": "FOYA",
                            "Name": "Foya",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-10.280556, 8.2786111",
                            "IataCode": "FOY",
                            "Airports": [
                                {
                                    "Id": "FOY",
                                    "Name": "Foya",
                                    "CityId": "FOYA",
                                    "CountryId": "LR",
                                    "Location": "-10.216667, 8.366667"
                                }
                            ]
                        },
                        {
                            "Id": "GRCA",
                            "Name": "Grand Cess",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-8.2230556, 4.5713889",
                            "IataCode": "GRC",
                            "Airports": [
                                {
                                    "Id": "GRC",
                                    "Name": "Grand Cess",
                                    "CityId": "GRCA",
                                    "CountryId": "LR",
                                    "Location": "-8.166667, 4.6"
                                }
                            ]
                        },
                        {
                            "Id": "MLWA",
                            "Name": "Monrovia",
                            "SingleAirportCity": false,
                            "CountryId": "LR",
                            "Location": "-10.804722, 6.3105556",
                            "IataCode": "MLW",
                            "Airports": [
                                {
                                    "Id": "MLW",
                                    "Name": "Monrovia Sprigg Payne",
                                    "CityId": "MLWA",
                                    "CountryId": "LR",
                                    "Location": "-10.758333, 6.289444"
                                },
                                {
                                    "Id": "ROB",
                                    "Name": "Monrovia Roberts",
                                    "CityId": "MLWA",
                                    "CountryId": "LR",
                                    "Location": "-10.358889, 6.239722"
                                }
                            ]
                        },
                        {
                            "Id": "NIAA",
                            "Name": "Nimba",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-8.5708333, 7.4888889",
                            "IataCode": "NIA",
                            "Airports": [
                                {
                                    "Id": "NIA",
                                    "Name": "Nimba",
                                    "CityId": "NIAA",
                                    "CountryId": "LR",
                                    "Location": "-8.591667, 7.491111"
                                }
                            ]
                        },
                        {
                            "Id": "SNIA",
                            "Name": "Sinoe",
                            "SingleAirportCity": false,
                            "CountryId": "LR",
                            "Location": "-9.0388889, 5.0111111",
                            "IataCode": "SNI",
                            "Airports": [
                                {
                                    "Id": "XSA",
                                    "Name": "Sinoe AFC",
                                    "CityId": "SNIA",
                                    "CountryId": "LR",
                                    "Location": "-9.05, 5.016667"
                                },
                                {
                                    "Id": "SNI",
                                    "Name": "Sinoe R.E. Murray",
                                    "CityId": "SNIA",
                                    "CountryId": "LR",
                                    "Location": "-9.063611, 5.033056"
                                }
                            ]
                        },
                        {
                            "Id": "THCA",
                            "Name": "Tchien",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-8.1280556, 6.0666667",
                            "IataCode": "THC",
                            "Airports": [
                                {
                                    "Id": "THC",
                                    "Name": "Tchien",
                                    "CityId": "THCA",
                                    "CountryId": "LR",
                                    "Location": "-8.133333, 6.066667"
                                }
                            ]
                        },
                        {
                            "Id": "UCNA",
                            "Name": "Buchanan",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-10.046667, 5.8808333",
                            "IataCode": "UCN",
                            "Airports": [
                                {
                                    "Id": "UCN",
                                    "Name": "Buchanan",
                                    "CityId": "UCNA",
                                    "CountryId": "LR",
                                    "Location": "-10.033333, 5.95"
                                }
                            ]
                        },
                        {
                            "Id": "VOIA",
                            "Name": "Voinjama",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-9.7477778, 8.4219444",
                            "IataCode": "VOI",
                            "Airports": [
                                {
                                    "Id": "VOI",
                                    "Name": "Voinjama",
                                    "CityId": "VOIA",
                                    "CountryId": "LR",
                                    "Location": "-9.75, 8.416667"
                                }
                            ]
                        },
                        {
                            "Id": "RVCA",
                            "Name": "Rivercess",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-9.5827778, 5.46",
                            "IataCode": "RVC",
                            "Airports": [
                                {
                                    "Id": "RVC",
                                    "Name": "Rivercess",
                                    "CityId": "RVCA",
                                    "CountryId": "LR",
                                    "Location": "-9.583333, 5.466667"
                                }
                            ]
                        },
                        {
                            "Id": "WESA",
                            "Name": "Weasua",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-10.508056, 7.3163889",
                            "IataCode": "WES",
                            "Airports": [
                                {
                                    "Id": "WES",
                                    "Name": "Weasua",
                                    "CityId": "WESA",
                                    "CountryId": "LR",
                                    "Location": "-10.483333, 7.283333"
                                }
                            ]
                        },
                        {
                            "Id": "TPTA",
                            "Name": "Tapeta",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-8.8602778, 6.4922222",
                            "IataCode": "TPT",
                            "Airports": [
                                {
                                    "Id": "TPT",
                                    "Name": "Tapeta",
                                    "CityId": "TPTA",
                                    "CountryId": "LR",
                                    "Location": "-8.866667, 6.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SAZA",
                            "Name": "Sasstown",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-8.4283333, 4.665",
                            "IataCode": "SAZ",
                            "Airports": [
                                {
                                    "Id": "SAZ",
                                    "Name": "Sasstown",
                                    "CityId": "SAZA",
                                    "CountryId": "LR",
                                    "Location": "-8.433333, 4.666667"
                                }
                            ]
                        },
                        {
                            "Id": "WOIA",
                            "Name": "Selma",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-9.75, 8.2666667",
                            "IataCode": "WOI",
                            "Airports": [
                                {
                                    "Id": "WOI",
                                    "Name": "Wologissi",
                                    "CityId": "WOIA",
                                    "CountryId": "LR",
                                    "Location": "-9.75, 8.266667"
                                }
                            ]
                        },
                        {
                            "Id": "BYLA",
                            "Name": "Belle Yella",
                            "SingleAirportCity": true,
                            "CountryId": "LR",
                            "Location": "-9.9983333, 7.3763889",
                            "IataCode": "BYL",
                            "Airports": [
                                {
                                    "Id": "BYL",
                                    "Name": "Bella Yella",
                                    "CityId": "BYLA",
                                    "CountryId": "LR",
                                    "Location": "-10, 7.366667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ZW",
                    "Name": "Zimbabwe",
                    "CurrencyId": "ZWD",
                    "Cities": [
                        {
                            "Id": "CHJA",
                            "Name": "Chipinge",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "32.616667, -20.2",
                            "IataCode": "CHJ",
                            "Airports": [
                                {
                                    "Id": "CHJ",
                                    "Name": "Chipinge",
                                    "CityId": "CHJA",
                                    "CountryId": "ZW",
                                    "Location": "32.65, -20.2"
                                }
                            ]
                        },
                        {
                            "Id": "UTAA",
                            "Name": "Mutare",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "32.666667, -18.966667",
                            "IataCode": "UTA",
                            "Airports": [
                                {
                                    "Id": "UTA",
                                    "Name": "Mutare",
                                    "CityId": "UTAA",
                                    "CountryId": "ZW",
                                    "Location": "32.666667, -18.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BUQA",
                            "Name": "Bulawayo",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "28.583333, -20.15",
                            "IataCode": "BUQ",
                            "Airports": [
                                {
                                    "Id": "BUQ",
                                    "Name": "Bulawayo",
                                    "CityId": "BUQA",
                                    "CountryId": "ZW",
                                    "Location": "28.618611, -20.016389"
                                }
                            ]
                        },
                        {
                            "Id": "GWEA",
                            "Name": "Gweru",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "29.816666, -19.45",
                            "IataCode": "GWE",
                            "Airports": [
                                {
                                    "Id": "GWE",
                                    "Name": "Gweru",
                                    "CityId": "GWEA",
                                    "CountryId": "ZW",
                                    "Location": "29.861111, -19.435833"
                                }
                            ]
                        },
                        {
                            "Id": "HREA",
                            "Name": "Harare",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "31.044723, -17.817778",
                            "IataCode": "HRE",
                            "Airports": [
                                {
                                    "Id": "HRE",
                                    "Name": "Harare",
                                    "CityId": "HREA",
                                    "CountryId": "ZW",
                                    "Location": "31.101389, -17.922778"
                                }
                            ]
                        },
                        {
                            "Id": "KABA",
                            "Name": "Kariba",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "28.8, -16.516667",
                            "IataCode": "KAB",
                            "Airports": [
                                {
                                    "Id": "KAB",
                                    "Name": "Kariba",
                                    "CityId": "KABA",
                                    "CountryId": "ZW",
                                    "Location": "28.885278, -16.518333"
                                }
                            ]
                        },
                        {
                            "Id": "VFAA",
                            "Name": "Victoria Falls",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "25.833333, -17.933333",
                            "IataCode": "VFA",
                            "Airports": [
                                {
                                    "Id": "VFA",
                                    "Name": "Victoria Falls",
                                    "CityId": "VFAA",
                                    "CountryId": "ZW",
                                    "Location": "25.840278, -18.093056"
                                }
                            ]
                        },
                        {
                            "Id": "MVZA",
                            "Name": "Masvingo",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "30.833333, -20.083333",
                            "IataCode": "MVZ",
                            "Airports": [
                                {
                                    "Id": "MVZ",
                                    "Name": "Masvingo",
                                    "CityId": "MVZA",
                                    "CountryId": "ZW",
                                    "Location": "30.860556, -20.054167"
                                }
                            ]
                        },
                        {
                            "Id": "WKIA",
                            "Name": "Hwange",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "26.483333, -18.366667",
                            "IataCode": "WKI",
                            "Airports": [
                                {
                                    "Id": "WKI",
                                    "Name": "Hwange",
                                    "CityId": "WKIA",
                                    "CountryId": "ZW",
                                    "Location": "26.518333, -18.3625"
                                }
                            ]
                        },
                        {
                            "Id": "BZHA",
                            "Name": "Bumi Hills",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "28.35, -16.816667",
                            "IataCode": "BZH",
                            "Airports": [
                                {
                                    "Id": "BZH",
                                    "Name": "Bumi Hills",
                                    "CityId": "BZHA",
                                    "CountryId": "ZW",
                                    "Location": "28.35, -16.816667"
                                }
                            ]
                        },
                        {
                            "Id": "BFOA",
                            "Name": "Buffalo Range",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "31.533333, -21.016667",
                            "IataCode": "BFO",
                            "Airports": [
                                {
                                    "Id": "BFO",
                                    "Name": "Buffalo Range",
                                    "CityId": "BFOA",
                                    "CountryId": "ZW",
                                    "Location": "31.579167, -21.009167"
                                }
                            ]
                        },
                        {
                            "Id": "HWNA",
                            "Name": "Hwange District",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "26.5, -18.75",
                            "IataCode": "HWN",
                            "Airports": [
                                {
                                    "Id": "HWN",
                                    "Name": "Hwange Nat Park",
                                    "CityId": "HWNA",
                                    "CountryId": "ZW",
                                    "Location": "27.02104, -18.62987"
                                }
                            ]
                        },
                        {
                            "Id": "MJWA",
                            "Name": "Mavue",
                            "SingleAirportCity": true,
                            "CountryId": "ZW",
                            "Location": "32.418056, -21.318889",
                            "IataCode": "MJW",
                            "Airports": [
                                {
                                    "Id": "MJW",
                                    "Name": "Mahenye",
                                    "CityId": "MJWA",
                                    "CountryId": "ZW",
                                    "Location": "32.333333, -21.233333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "RW",
                    "Name": "Rwanda",
                    "CurrencyId": "RWF",
                    "Cities": [
                        {
                            "Id": "GYIA",
                            "Name": "Gisenyi",
                            "SingleAirportCity": true,
                            "CountryId": "RW",
                            "Location": "29.256389, -1.7027778",
                            "IataCode": "GYI",
                            "Airports": [
                                {
                                    "Id": "GYI",
                                    "Name": "Gisenyi",
                                    "CityId": "GYIA",
                                    "CountryId": "RW",
                                    "Location": "29.25921, -1.677804"
                                }
                            ]
                        },
                        {
                            "Id": "KGLA",
                            "Name": "Kigali",
                            "SingleAirportCity": true,
                            "CountryId": "RW",
                            "Location": "30.060556, -1.9536111",
                            "IataCode": "KGL",
                            "Airports": [
                                {
                                    "Id": "KGL",
                                    "Name": "Kigali",
                                    "CityId": "KGLA",
                                    "CountryId": "RW",
                                    "Location": "30.14, -1.967778"
                                }
                            ]
                        },
                        {
                            "Id": "KMEA",
                            "Name": "Kamembe",
                            "SingleAirportCity": true,
                            "CountryId": "RW",
                            "Location": "28.922222, -2.4377778",
                            "IataCode": "KME",
                            "Airports": [
                                {
                                    "Id": "KME",
                                    "Name": "Kamembe",
                                    "CityId": "KMEA",
                                    "CountryId": "RW",
                                    "Location": "28.916667, -2.466667"
                                }
                            ]
                        },
                        {
                            "Id": "RHGA",
                            "Name": "Ruhengeri",
                            "SingleAirportCity": true,
                            "CountryId": "RW",
                            "Location": "29.635833, -1.5041667",
                            "IataCode": "RHG",
                            "Airports": [
                                {
                                    "Id": "RHG",
                                    "Name": "Ruhengeri",
                                    "CityId": "RHGA",
                                    "CountryId": "RW",
                                    "Location": "29.6, -1.5"
                                }
                            ]
                        },
                        {
                            "Id": "BTQA",
                            "Name": "Butare",
                            "SingleAirportCity": true,
                            "CountryId": "RW",
                            "Location": "29.739444, -2.5966667",
                            "IataCode": "BTQ",
                            "Airports": [
                                {
                                    "Id": "BTQ",
                                    "Name": "Butare",
                                    "CityId": "BTQA",
                                    "CountryId": "RW",
                                    "Location": "29.733333, -2.6"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ML",
                    "Name": "Mali",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "BKOA",
                            "Name": "Bamako",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-8.0000003, 12.65",
                            "IataCode": "BKO",
                            "Airports": [
                                {
                                    "Id": "BKO",
                                    "Name": "Bamako",
                                    "CityId": "BKOA",
                                    "CountryId": "ML",
                                    "Location": "-7.933333, 12.533611"
                                }
                            ]
                        },
                        {
                            "Id": "EYLA",
                            "Name": "Yelimane",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-10.577291, 15.118684",
                            "IataCode": "EYL",
                            "Airports": [
                                {
                                    "Id": "EYL",
                                    "Name": "Yelimane",
                                    "CityId": "EYLA",
                                    "CountryId": "ML",
                                    "Location": "-10.566667, 15.133333"
                                }
                            ]
                        },
                        {
                            "Id": "GAQA",
                            "Name": "Gao",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-0.041542213, 16.274006",
                            "IataCode": "GAQ",
                            "Airports": [
                                {
                                    "Id": "GAQ",
                                    "Name": "Gao",
                                    "CityId": "GAQA",
                                    "CountryId": "ML",
                                    "Location": "-0.001389, 16.248611"
                                }
                            ]
                        },
                        {
                            "Id": "KNZA",
                            "Name": "Kenieba",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-11.240563, 12.839615",
                            "IataCode": "KNZ",
                            "Airports": [
                                {
                                    "Id": "KNZ",
                                    "Name": "Kenieba",
                                    "CityId": "KNZA",
                                    "CountryId": "ML",
                                    "Location": "-11.2525, 12.843333"
                                }
                            ]
                        },
                        {
                            "Id": "KSSA",
                            "Name": "Sikasso",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-5.6666663, 11.316667",
                            "IataCode": "KSS",
                            "Airports": [
                                {
                                    "Id": "KSS",
                                    "Name": "Sikasso",
                                    "CityId": "KSSA",
                                    "CountryId": "ML",
                                    "Location": "-5.583333, 11.3"
                                }
                            ]
                        },
                        {
                            "Id": "KTXA",
                            "Name": "Koutiala",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-5.4666665, 12.383333",
                            "IataCode": "KTX",
                            "Airports": [
                                {
                                    "Id": "KTX",
                                    "Name": "Koutiala",
                                    "CityId": "KTXA",
                                    "CountryId": "ML",
                                    "Location": "-5.383333, 12.416667"
                                }
                            ]
                        },
                        {
                            "Id": "KYSA",
                            "Name": "Kayes",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-11.4341, 14.4417",
                            "IataCode": "KYS",
                            "Airports": [
                                {
                                    "Id": "KYS",
                                    "Name": "Kayes",
                                    "CityId": "KYSA",
                                    "CountryId": "ML",
                                    "Location": "-11.439444, 14.431944"
                                }
                            ]
                        },
                        {
                            "Id": "NIXA",
                            "Name": "Nioro",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-9.5927711, 15.229317",
                            "IataCode": "NIX",
                            "Airports": [
                                {
                                    "Id": "NIX",
                                    "Name": "Nioro",
                                    "CityId": "NIXA",
                                    "CountryId": "ML",
                                    "Location": "-9.577222, 15.239167"
                                }
                            ]
                        },
                        {
                            "Id": "NRMA",
                            "Name": "Nara",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-7.2846595, 15.168751",
                            "IataCode": "NRM",
                            "Airports": [
                                {
                                    "Id": "NRM",
                                    "Name": "Nara",
                                    "CityId": "NRMA",
                                    "CountryId": "ML",
                                    "Location": "-7.283333, 15.25"
                                }
                            ]
                        },
                        {
                            "Id": "TOMA",
                            "Name": "Tombouctou",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-3.0074215, 16.77348",
                            "IataCode": "TOM",
                            "Airports": [
                                {
                                    "Id": "TOM",
                                    "Name": "Tombouctou",
                                    "CityId": "TOMA",
                                    "CountryId": "ML",
                                    "Location": "-3.005278, 16.732222"
                                }
                            ]
                        },
                        {
                            "Id": "SZUA",
                            "Name": "Segou",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-6.2666669, 13.45",
                            "IataCode": "SZU",
                            "Airports": [
                                {
                                    "Id": "SZU",
                                    "Name": "Segou",
                                    "CityId": "SZUA",
                                    "CountryId": "ML",
                                    "Location": "-6.283333, 13.433333"
                                }
                            ]
                        },
                        {
                            "Id": "GUDA",
                            "Name": "Goundam",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-3.6666667, 16.416667",
                            "IataCode": "GUD",
                            "Airports": [
                                {
                                    "Id": "GUD",
                                    "Name": "Goundam",
                                    "CityId": "GUDA",
                                    "CountryId": "ML",
                                    "Location": "-3.609722, 16.3575"
                                }
                            ]
                        },
                        {
                            "Id": "MZIA",
                            "Name": "Mopti",
                            "SingleAirportCity": true,
                            "CountryId": "ML",
                            "Location": "-4.1942023, 14.493024",
                            "IataCode": "MZI",
                            "Airports": [
                                {
                                    "Id": "MZI",
                                    "Name": "Mopti",
                                    "CityId": "MZIA",
                                    "CountryId": "ML",
                                    "Location": "-4.085, 14.508333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SZ",
                    "Name": "Eswatini",
                    "CurrencyId": "SZL",
                    "Cities": [
                        {
                            "Id": "MTSA",
                            "Name": "Manzini",
                            "SingleAirportCity": false,
                            "CountryId": "SZ",
                            "Location": "31.366667, -26.483333",
                            "IataCode": "MTS",
                            "Airports": [
                                {
                                    "Id": "MTS",
                                    "Name": "Manzini",
                                    "CityId": "MTSA",
                                    "CountryId": "SZ",
                                    "Location": "31.311389, -26.524167"
                                },
                                {
                                    "Id": "SHO",
                                    "Name": "King Mswati III",
                                    "CityId": "MTSA",
                                    "CountryId": "SZ",
                                    "Location": "31.720805, -26.356895"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ST",
                    "Name": "Sao Tome and Principe",
                    "CurrencyId": "STD",
                    "Cities": [
                        {
                            "Id": "PGPA",
                            "Name": "Porto Alegre",
                            "SingleAirportCity": true,
                            "CountryId": "ST",
                            "Location": "6.5333333, 0.0333333",
                            "IataCode": "PGP",
                            "Airports": [
                                {
                                    "Id": "PGP",
                                    "Name": "Porto Alegre",
                                    "CityId": "PGPA",
                                    "CountryId": "ST",
                                    "Location": "6.533333, 0.033333"
                                }
                            ]
                        },
                        {
                            "Id": "TMSA",
                            "Name": "Sao Tome Is",
                            "SingleAirportCity": true,
                            "CountryId": "ST",
                            "Location": "6.7333334, 0.3333333",
                            "IataCode": "TMS",
                            "Airports": [
                                {
                                    "Id": "TMS",
                                    "Name": "Sao Tome Is",
                                    "CityId": "TMSA",
                                    "CountryId": "ST",
                                    "Location": "6.725, 0.3783"
                                }
                            ]
                        },
                        {
                            "Id": "PCPA",
                            "Name": "Principe",
                            "SingleAirportCity": true,
                            "CountryId": "ST",
                            "Location": "7.396261, 1.6127094",
                            "IataCode": "PCP",
                            "Airports": [
                                {
                                    "Id": "PCP",
                                    "Name": "Principe",
                                    "CityId": "PCPA",
                                    "CountryId": "ST",
                                    "Location": "7.416667, 1.666667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SO",
                    "Name": "Somalia",
                    "CurrencyId": "SOS",
                    "Cities": [
                        {
                            "Id": "BIBA",
                            "Name": "Baidoa",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "43.65, 3.1166667",
                            "IataCode": "BIB",
                            "Airports": [
                                {
                                    "Id": "BIB",
                                    "Name": "Baidoa",
                                    "CityId": "BIBA",
                                    "CountryId": "SO",
                                    "Location": "43.633333, 3.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GSRA",
                            "Name": "Gardo",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "49.086944, 9.5052778",
                            "IataCode": "GSR",
                            "Airports": [
                                {
                                    "Id": "GSR",
                                    "Name": "Gardo",
                                    "CityId": "GSRA",
                                    "CountryId": "SO",
                                    "Location": "49.05, 9.5"
                                }
                            ]
                        },
                        {
                            "Id": "KMUA",
                            "Name": "Kismayu",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "42.533334, -0.3666667",
                            "IataCode": "KMU",
                            "Airports": [
                                {
                                    "Id": "KMU",
                                    "Name": "Kismayu",
                                    "CityId": "KMUA",
                                    "CountryId": "SO",
                                    "Location": "42.459167, -0.377222"
                                }
                            ]
                        },
                        {
                            "Id": "LGXA",
                            "Name": "Lugh Ganane",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "42.55, 3.8",
                            "IataCode": "LGX",
                            "Airports": [
                                {
                                    "Id": "LGX",
                                    "Name": "Lugh Ganane",
                                    "CityId": "LGXA",
                                    "CountryId": "SO",
                                    "Location": "42.05, 3.8"
                                }
                            ]
                        },
                        {
                            "Id": "BSAA",
                            "Name": "Bossaso",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "49.1825, 11.284722",
                            "IataCode": "BSA",
                            "Airports": [
                                {
                                    "Id": "BSA",
                                    "Name": "Bossaso",
                                    "CityId": "BSAA",
                                    "CountryId": "SO",
                                    "Location": "49.15, 11.283333"
                                }
                            ]
                        },
                        {
                            "Id": "BSYA",
                            "Name": "Bardera",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "42.285637, 2.3385643",
                            "IataCode": "BSY",
                            "Airports": [
                                {
                                    "Id": "BSY",
                                    "Name": "Bardera",
                                    "CityId": "BSYA",
                                    "CountryId": "SO",
                                    "Location": "42.333333, 2.35"
                                }
                            ]
                        },
                        {
                            "Id": "BUOA",
                            "Name": "Burao",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "45.533333, 9.5166667",
                            "IataCode": "BUO",
                            "Airports": [
                                {
                                    "Id": "BUO",
                                    "Name": "Burao",
                                    "CityId": "BUOA",
                                    "CountryId": "SO",
                                    "Location": "45.554167, 9.5225"
                                }
                            ]
                        },
                        {
                            "Id": "ERAA",
                            "Name": "Erigavo",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "47.37, 10.618056",
                            "IataCode": "ERA",
                            "Airports": [
                                {
                                    "Id": "ERA",
                                    "Name": "Erigavo",
                                    "CityId": "ERAA",
                                    "CountryId": "SO",
                                    "Location": "47.4, 10.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CMOA",
                            "Name": "Obbia",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "48.525556, 5.3513889",
                            "IataCode": "CMO",
                            "Airports": [
                                {
                                    "Id": "CMO",
                                    "Name": "Obbia",
                                    "CityId": "CMOA",
                                    "CountryId": "SO",
                                    "Location": "48.516667, 5.366667"
                                }
                            ]
                        },
                        {
                            "Id": "CMSA",
                            "Name": "Scusciuban",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "50.233333, 10.283333",
                            "IataCode": "CMS",
                            "Airports": [
                                {
                                    "Id": "CMS",
                                    "Name": "Scusciuban",
                                    "CityId": "CMSA",
                                    "CountryId": "SO",
                                    "Location": "50.233333, 10.3"
                                }
                            ]
                        },
                        {
                            "Id": "CXNA",
                            "Name": "Candala",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "49.873889, 11.471111",
                            "IataCode": "CXN",
                            "Airports": [
                                {
                                    "Id": "CXN",
                                    "Name": "Candala",
                                    "CityId": "CXNA",
                                    "CountryId": "SO",
                                    "Location": "49.916667, 11.5"
                                }
                            ]
                        },
                        {
                            "Id": "GGRA",
                            "Name": "Garoe",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "48.483056, 8.4063889",
                            "IataCode": "GGR",
                            "Airports": [
                                {
                                    "Id": "GGR",
                                    "Name": "Garoe",
                                    "CityId": "GGRA",
                                    "CountryId": "SO",
                                    "Location": "48.333333, 8.283333"
                                }
                            ]
                        },
                        {
                            "Id": "GBMA",
                            "Name": "Garbaharey",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "42.216667, 3.3166667",
                            "IataCode": "GBM",
                            "Airports": [
                                {
                                    "Id": "GBM",
                                    "Name": "Garbaharey",
                                    "CityId": "GBMA",
                                    "CountryId": "SO",
                                    "Location": "42.183333, 3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "GLKA",
                            "Name": "Galcaio",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "47.430833, 6.7697222",
                            "IataCode": "GLK",
                            "Airports": [
                                {
                                    "Id": "GLK",
                                    "Name": "Galcaio",
                                    "CityId": "GLKA",
                                    "CountryId": "SO",
                                    "Location": "47.433333, 6.766667"
                                }
                            ]
                        },
                        {
                            "Id": "HCMA",
                            "Name": "Eil",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "49.816667, 7.9833333",
                            "IataCode": "HCM",
                            "Airports": [
                                {
                                    "Id": "HCM",
                                    "Name": "Eil",
                                    "CityId": "HCMA",
                                    "CountryId": "SO",
                                    "Location": "49.8, 7.916667"
                                }
                            ]
                        },
                        {
                            "Id": "HGAA",
                            "Name": "Hargeisa",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "44.065, 9.56",
                            "IataCode": "HGA",
                            "Airports": [
                                {
                                    "Id": "HGA",
                                    "Name": "Hargeisa",
                                    "CityId": "HGAA",
                                    "CountryId": "SO",
                                    "Location": "44.091667, 9.515833"
                                }
                            ]
                        },
                        {
                            "Id": "LKRA",
                            "Name": "Las Khoreh",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "48.216667, 11.166667",
                            "IataCode": "LKR",
                            "Airports": [
                                {
                                    "Id": "LKR",
                                    "Name": "Las Khoreh",
                                    "CityId": "LKRA",
                                    "CountryId": "SO",
                                    "Location": "48.2, 11.15"
                                }
                            ]
                        },
                        {
                            "Id": "MGQA",
                            "Name": "Mogadishu",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "45.366666, 2.0666667",
                            "IataCode": "MGQ",
                            "Airports": [
                                {
                                    "Id": "MGQ",
                                    "Name": "Mogadishu",
                                    "CityId": "MGQA",
                                    "CountryId": "SO",
                                    "Location": "45.313333, 2.013333"
                                }
                            ]
                        },
                        {
                            "Id": "ALUA",
                            "Name": "Alula",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "50.75, 11.966667",
                            "IataCode": "ALU",
                            "Airports": [
                                {
                                    "Id": "ALU",
                                    "Name": "Alula",
                                    "CityId": "ALUA",
                                    "CountryId": "SO",
                                    "Location": "50.8, 11.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BBOA",
                            "Name": "Berbera",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "45.016389, 10.435556",
                            "IataCode": "BBO",
                            "Airports": [
                                {
                                    "Id": "BBO",
                                    "Name": "Berbera",
                                    "CityId": "BBOA",
                                    "CountryId": "SO",
                                    "Location": "44.941111, 10.389167"
                                }
                            ]
                        },
                        {
                            "Id": "BXXA",
                            "Name": "Borama",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "43.182778, 9.9361111",
                            "IataCode": "BXX",
                            "Airports": [
                                {
                                    "Id": "BXX",
                                    "Name": "Borama",
                                    "CityId": "BXXA",
                                    "CountryId": "SO",
                                    "Location": "43.116667, 9.966667"
                                }
                            ]
                        },
                        {
                            "Id": "AADB",
                            "Name": "Adado",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "46.625556, 6.1402778",
                            "IataCode": "AAD",
                            "Airports": [
                                {
                                    "Id": "AAD",
                                    "Name": "Adado",
                                    "CityId": "AADB",
                                    "CountryId": "SO",
                                    "Location": "46.6375, 6.095833"
                                }
                            ]
                        },
                        {
                            "Id": "GUOS",
                            "Name": "Guri Ceel",
                            "SingleAirportCity": true,
                            "CountryId": "SO",
                            "Location": "45.8825, 5.3075",
                            "IataCode": "GUO",
                            "Airports": [
                                {
                                    "Id": "GUO",
                                    "Name": "Guriel",
                                    "CityId": "GUOS",
                                    "CountryId": "SO",
                                    "Location": "45.9856, 5.3176"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MR",
                    "Name": "Mauritania",
                    "CurrencyId": "MRO",
                    "Cities": [
                        {
                            "Id": "AEOA",
                            "Name": "Aioun El Atrouss",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-9.6149, 16.6614",
                            "IataCode": "AEO",
                            "Airports": [
                                {
                                    "Id": "AEO",
                                    "Name": "Aioun El Atrouss",
                                    "CityId": "AEOA",
                                    "CountryId": "MR",
                                    "Location": "-9.635556, 16.709167"
                                }
                            ]
                        },
                        {
                            "Id": "AJJA",
                            "Name": "Akjoujt",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-14.416389, 19.696944",
                            "IataCode": "AJJ",
                            "Airports": [
                                {
                                    "Id": "AJJ",
                                    "Name": "Akjoujt",
                                    "CityId": "AJJA",
                                    "CountryId": "MR",
                                    "Location": "-14.374444, 19.730556"
                                }
                            ]
                        },
                        {
                            "Id": "BGHA",
                            "Name": "Boghe",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-14.270581, 16.580324",
                            "IataCode": "BGH",
                            "Airports": [
                                {
                                    "Id": "BGH",
                                    "Name": "Boghe",
                                    "CityId": "BGHA",
                                    "CountryId": "MR",
                                    "Location": "-14.2, 16.633333"
                                }
                            ]
                        },
                        {
                            "Id": "EMNA",
                            "Name": "Nema",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-7.2535, 16.6162",
                            "IataCode": "EMN",
                            "Airports": [
                                {
                                    "Id": "EMN",
                                    "Name": "Nema",
                                    "CityId": "EMNA",
                                    "CountryId": "MR",
                                    "Location": "-7.283333, 16.6"
                                }
                            ]
                        },
                        {
                            "Id": "FGDA",
                            "Name": "Fderik",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.733611, 22.611111",
                            "IataCode": "FGD",
                            "Airports": [
                                {
                                    "Id": "FGD",
                                    "Name": "Fderik",
                                    "CityId": "FGDA",
                                    "CountryId": "MR",
                                    "Location": "-12.731944, 22.675"
                                }
                            ]
                        },
                        {
                            "Id": "KEDA",
                            "Name": "Kaedi",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-13.504739, 16.14653",
                            "IataCode": "KED",
                            "Airports": [
                                {
                                    "Id": "KED",
                                    "Name": "Kaedi",
                                    "CityId": "KEDA",
                                    "CountryId": "MR",
                                    "Location": "-13.507778, 16.161389"
                                }
                            ]
                        },
                        {
                            "Id": "KFAA",
                            "Name": "Kiffa",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-11.40453, 16.616586",
                            "IataCode": "KFA",
                            "Airports": [
                                {
                                    "Id": "KFA",
                                    "Name": "Kiffa",
                                    "CityId": "KFAA",
                                    "CountryId": "MR",
                                    "Location": "-11.405278, 16.588889"
                                }
                            ]
                        },
                        {
                            "Id": "MOMA",
                            "Name": "Moudjeria",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.331847, 17.873645",
                            "IataCode": "MOM",
                            "Airports": [
                                {
                                    "Id": "MOM",
                                    "Name": "Moudjeria",
                                    "CityId": "MOMA",
                                    "CountryId": "MR",
                                    "Location": "-12.5, 17.75"
                                }
                            ]
                        },
                        {
                            "Id": "NDBA",
                            "Name": "Nouadhibou",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-17.042222, 20.9025",
                            "IataCode": "NDB",
                            "Airports": [
                                {
                                    "Id": "NDB",
                                    "Name": "Nouadhibou",
                                    "CityId": "NDBA",
                                    "CountryId": "MR",
                                    "Location": "-17.028333, 20.934167"
                                }
                            ]
                        },
                        {
                            "Id": "NKCA",
                            "Name": "Nouakchott",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-16.040555, 18.119444",
                            "IataCode": "NKC",
                            "Airports": [
                                {
                                    "Id": "NKC",
                                    "Name": "Nouakchott",
                                    "CityId": "NKCA",
                                    "CountryId": "MR",
                                    "Location": "-15.951944, 18.091944"
                                }
                            ]
                        },
                        {
                            "Id": "OTLA",
                            "Name": "Boutilimit",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-14.6944, 17.5467",
                            "IataCode": "OTL",
                            "Airports": [
                                {
                                    "Id": "OTL",
                                    "Name": "Boutilimit",
                                    "CityId": "OTLA",
                                    "CountryId": "MR",
                                    "Location": "-14.666667, 17.75"
                                }
                            ]
                        },
                        {
                            "Id": "OUZA",
                            "Name": "Zouerate",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.459167, 22.688333",
                            "IataCode": "OUZ",
                            "Airports": [
                                {
                                    "Id": "OUZ",
                                    "Name": "Zouerate",
                                    "CityId": "OUZA",
                                    "CountryId": "MR",
                                    "Location": "-12.35, 22.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SEYA",
                            "Name": "Selibaby",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.182897, 15.158946",
                            "IataCode": "SEY",
                            "Airports": [
                                {
                                    "Id": "SEY",
                                    "Name": "Selibaby",
                                    "CityId": "SEYA",
                                    "CountryId": "MR",
                                    "Location": "-12.206111, 15.182222"
                                }
                            ]
                        },
                        {
                            "Id": "THIA",
                            "Name": "Tichitt",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-9.4939435, 18.441345",
                            "IataCode": "THI",
                            "Airports": [
                                {
                                    "Id": "THI",
                                    "Name": "Tichitt",
                                    "CityId": "THIA",
                                    "CountryId": "MR",
                                    "Location": "-9.5, 18.45"
                                }
                            ]
                        },
                        {
                            "Id": "THTA",
                            "Name": "Tamchakett",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-10.6706, 17.2422",
                            "IataCode": "THT",
                            "Airports": [
                                {
                                    "Id": "THT",
                                    "Name": "Tamchakett",
                                    "CityId": "THTA",
                                    "CountryId": "MR",
                                    "Location": "-10.816667, 17.233333"
                                }
                            ]
                        },
                        {
                            "Id": "TIYA",
                            "Name": "Tidjikja",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-11.4256, 18.555",
                            "IataCode": "TIY",
                            "Airports": [
                                {
                                    "Id": "TIY",
                                    "Name": "Tidjikja",
                                    "CityId": "TIYA",
                                    "CountryId": "MR",
                                    "Location": "-11.416667, 18.566667"
                                }
                            ]
                        },
                        {
                            "Id": "TMDA",
                            "Name": "Timbedra",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-8.1643, 16.2447",
                            "IataCode": "TMD",
                            "Airports": [
                                {
                                    "Id": "TMD",
                                    "Name": "Timbedra",
                                    "CityId": "TMDA",
                                    "CountryId": "MR",
                                    "Location": "-8.154167, 16.236667"
                                }
                            ]
                        },
                        {
                            "Id": "ATRA",
                            "Name": "Atar",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-13.065, 20.427222",
                            "IataCode": "ATR",
                            "Airports": [
                                {
                                    "Id": "ATR",
                                    "Name": "Atar",
                                    "CityId": "ATRA",
                                    "CountryId": "MR",
                                    "Location": "-13.046389, 20.499444"
                                }
                            ]
                        },
                        {
                            "Id": "LEGA",
                            "Name": "Aleg",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-13.9209, 17.0481",
                            "IataCode": "LEG",
                            "Airports": [
                                {
                                    "Id": "LEG",
                                    "Name": "Aleg",
                                    "CityId": "LEGA",
                                    "CountryId": "MR",
                                    "Location": "-13.95, 17.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MBRA",
                            "Name": "Mbout",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.583789, 16.021568",
                            "IataCode": "MBR",
                            "Airports": [
                                {
                                    "Id": "MBR",
                                    "Name": "Mbout",
                                    "CityId": "MBRA",
                                    "CountryId": "MR",
                                    "Location": "-12.583333, 16.033333"
                                }
                            ]
                        },
                        {
                            "Id": "CGTA",
                            "Name": "Chinguitti",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-12.3618, 20.452",
                            "IataCode": "CGT",
                            "Airports": [
                                {
                                    "Id": "CGT",
                                    "Name": "Chinguitti",
                                    "CityId": "CGTA",
                                    "CountryId": "MR",
                                    "Location": "-12.366667, 20.45"
                                }
                            ]
                        },
                        {
                            "Id": "ZLGA",
                            "Name": "Uad Guenifa",
                            "SingleAirportCity": true,
                            "CountryId": "MR",
                            "Location": "-17.1, 20.833333",
                            "IataCode": "ZLG",
                            "Airports": [
                                {
                                    "Id": "ZLG",
                                    "Name": "El Gouera",
                                    "CityId": "ZLGA",
                                    "CountryId": "MR",
                                    "Location": "-17.078333, 20.842222"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SL",
                    "Name": "Sierra Leone",
                    "CurrencyId": "SLL",
                    "Cities": [
                        {
                            "Id": "FNAA",
                            "Name": "Freetown",
                            "SingleAirportCity": false,
                            "CountryId": "SL",
                            "Location": "-13.234167, 8.49",
                            "IataCode": "FNA",
                            "Airports": [
                                {
                                    "Id": "HGS",
                                    "Name": "Freetown Hastings",
                                    "CityId": "FNAA",
                                    "CountryId": "SL",
                                    "Location": "-13.13, 8.393056"
                                },
                                {
                                    "Id": "JMY",
                                    "Name": "Freetown Mammy Yoko Heliport",
                                    "CityId": "FNAA",
                                    "CountryId": "SL",
                                    "Location": "-13.266667, 8.483333"
                                },
                                {
                                    "Id": "FNA",
                                    "Name": "Freetown Lungi International",
                                    "CityId": "FNAA",
                                    "CountryId": "SL",
                                    "Location": "-13.196944, 8.6175"
                                }
                            ]
                        },
                        {
                            "Id": "WYEA",
                            "Name": "Yengema",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-11.05, 8.6166667",
                            "IataCode": "WYE",
                            "Airports": [
                                {
                                    "Id": "WYE",
                                    "Name": "Yengema",
                                    "CityId": "WYEA",
                                    "CountryId": "SL",
                                    "Location": "-11.058889, 8.616667"
                                }
                            ]
                        },
                        {
                            "Id": "BTEA",
                            "Name": "Bonthe",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-12.505, 7.5263889",
                            "IataCode": "BTE",
                            "Airports": [
                                {
                                    "Id": "BTE",
                                    "Name": "Bonthe",
                                    "CityId": "BTEA",
                                    "CountryId": "SL",
                                    "Location": "-12.008333, 7"
                                }
                            ]
                        },
                        {
                            "Id": "DSLA",
                            "Name": "Daru",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-10.840556, 7.9919444",
                            "IataCode": "DSL",
                            "Airports": [
                                {
                                    "Id": "DSL",
                                    "Name": "Daru",
                                    "CityId": "DSLA",
                                    "CountryId": "SL",
                                    "Location": "-10.833333, 7.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GBKA",
                            "Name": "Gbangbatok",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-12.384167, 7.8066667",
                            "IataCode": "GBK",
                            "Airports": [
                                {
                                    "Id": "GBK",
                                    "Name": "Gbangbatok",
                                    "CityId": "GBKA",
                                    "CountryId": "SL",
                                    "Location": "-12.366667, 7.8"
                                }
                            ]
                        },
                        {
                            "Id": "KBAA",
                            "Name": "Kabala",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-11.55, 9.5833333",
                            "IataCode": "KBA",
                            "Airports": [
                                {
                                    "Id": "KBA",
                                    "Name": "Kabala",
                                    "CityId": "KBAA",
                                    "CountryId": "SL",
                                    "Location": "-11.51556, 9.63832"
                                }
                            ]
                        },
                        {
                            "Id": "KBSA",
                            "Name": "Bo",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-11.738333, 7.9647222",
                            "IataCode": "KBS",
                            "Airports": [
                                {
                                    "Id": "KBS",
                                    "Name": "Bo",
                                    "CityId": "KBSA",
                                    "CountryId": "SL",
                                    "Location": "-11.761944, 7.943889"
                                }
                            ]
                        },
                        {
                            "Id": "KENA",
                            "Name": "Kenema",
                            "SingleAirportCity": true,
                            "CountryId": "SL",
                            "Location": "-11.1875, 7.8766667",
                            "IataCode": "KEN",
                            "Airports": [
                                {
                                    "Id": "KEN",
                                    "Name": "Kenema",
                                    "CityId": "KENA",
                                    "CountryId": "SL",
                                    "Location": "-11.183333, 7.883333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DJ",
                    "Name": "Djibouti",
                    "CurrencyId": "DJF",
                    "Cities": [
                        {
                            "Id": "JIBA",
                            "Name": "Djibouti",
                            "SingleAirportCity": true,
                            "CountryId": "DJ",
                            "Location": "43.148056, 11.595",
                            "IataCode": "JIB",
                            "Airports": [
                                {
                                    "Id": "JIB",
                                    "Name": "Djibouti",
                                    "CityId": "JIBA",
                                    "CountryId": "DJ",
                                    "Location": "43.158889, 11.546667"
                                }
                            ]
                        },
                        {
                            "Id": "OBCA",
                            "Name": "Obock",
                            "SingleAirportCity": true,
                            "CountryId": "DJ",
                            "Location": "43.290556, 11.963056",
                            "IataCode": "OBC",
                            "Airports": [
                                {
                                    "Id": "OBC",
                                    "Name": "Obock",
                                    "CityId": "OBCA",
                                    "CountryId": "DJ",
                                    "Location": "43.266667, 11.983333"
                                }
                            ]
                        },
                        {
                            "Id": "TDJA",
                            "Name": "Tadjoura",
                            "SingleAirportCity": true,
                            "CountryId": "DJ",
                            "Location": "42.884444, 11.785278",
                            "IataCode": "TDJ",
                            "Airports": [
                                {
                                    "Id": "TDJ",
                                    "Name": "Tadjoura",
                                    "CityId": "TDJA",
                                    "CountryId": "DJ",
                                    "Location": "42.9, 11.783333"
                                }
                            ]
                        },
                        {
                            "Id": "AIIA",
                            "Name": "Alisabieh",
                            "SingleAirportCity": true,
                            "CountryId": "DJ",
                            "Location": "42.7125, 11.155833",
                            "IataCode": "AII",
                            "Airports": [
                                {
                                    "Id": "AII",
                                    "Name": "Alisabieh",
                                    "CityId": "AIIA",
                                    "CountryId": "DJ",
                                    "Location": "42.716667, 11.15"
                                }
                            ]
                        },
                        {
                            "Id": "MHIA",
                            "Name": "Dudub Asa",
                            "SingleAirportCity": true,
                            "CountryId": "DJ",
                            "Location": "43.066667, 10.883333",
                            "IataCode": "MHI",
                            "Airports": [
                                {
                                    "Id": "MHI",
                                    "Name": "Musha",
                                    "CityId": "MHIA",
                                    "CountryId": "DJ",
                                    "Location": "43, 11"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AO",
                    "Name": "Angola",
                    "CurrencyId": "AOA",
                    "LanguageId": "PT",
                    "Cities": [
                        {
                            "Id": "XGNA",
                            "Name": "Xangongo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "14.983333, -16.75",
                            "IataCode": "XGN",
                            "Airports": [
                                {
                                    "Id": "XGN",
                                    "Name": "Xangongo",
                                    "CityId": "XGNA",
                                    "CountryId": "AO",
                                    "Location": "14.972222, -16.75"
                                }
                            ]
                        },
                        {
                            "Id": "ARZA",
                            "Name": "N'Zeto",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "12.866667, -7.2333333",
                            "IataCode": "ARZ",
                            "Airports": [
                                {
                                    "Id": "ARZ",
                                    "Name": "N'Zeto",
                                    "CityId": "ARZA",
                                    "CountryId": "AO",
                                    "Location": "13.5, -7.5"
                                }
                            ]
                        },
                        {
                            "Id": "AZZA",
                            "Name": "Ambriz",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.116667, -7.85",
                            "IataCode": "AZZ",
                            "Airports": [
                                {
                                    "Id": "AZZ",
                                    "Name": "Ambriz",
                                    "CityId": "AZZA",
                                    "CountryId": "AO",
                                    "Location": "13.15, -7.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CNZA",
                            "Name": "Cangamba",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "19.866667, -13.733333",
                            "IataCode": "CNZ",
                            "Airports": [
                                {
                                    "Id": "CNZ",
                                    "Name": "Cangamba",
                                    "CityId": "CNZA",
                                    "CountryId": "AO",
                                    "Location": "19, -11.5"
                                }
                            ]
                        },
                        {
                            "Id": "CTIA",
                            "Name": "Cuito Cuanavale",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "19.183333, -15.166667",
                            "IataCode": "CTI",
                            "Airports": [
                                {
                                    "Id": "CTI",
                                    "Name": "Cuito Cuanavale",
                                    "CityId": "CTIA",
                                    "CountryId": "AO",
                                    "Location": "19.166667, -15.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BUGA",
                            "Name": "Benguela",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.407222, -12.578333",
                            "IataCode": "BUG",
                            "Airports": [
                                {
                                    "Id": "BUG",
                                    "Name": "Benguela",
                                    "CityId": "BUGA",
                                    "CountryId": "AO",
                                    "Location": "13.405556, -12.606389"
                                }
                            ]
                        },
                        {
                            "Id": "CABA",
                            "Name": "Cabinda",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "12.2, -5.55",
                            "IataCode": "CAB",
                            "Airports": [
                                {
                                    "Id": "CAB",
                                    "Name": "Cabinda",
                                    "CityId": "CABA",
                                    "CountryId": "AO",
                                    "Location": "12.2, -5.583333"
                                }
                            ]
                        },
                        {
                            "Id": "CAVB",
                            "Name": "Cazombo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "22.916667, -11.9",
                            "IataCode": "CAV",
                            "Airports": [
                                {
                                    "Id": "CAV",
                                    "Name": "Cazombo",
                                    "CityId": "CAVB",
                                    "CountryId": "AO",
                                    "Location": "22.866667, -11.9"
                                }
                            ]
                        },
                        {
                            "Id": "CBTA",
                            "Name": "Catumbela",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.547222, -12.431389",
                            "IataCode": "CBT",
                            "Airports": [
                                {
                                    "Id": "CBT",
                                    "Name": "Catumbela",
                                    "CityId": "CBTA",
                                    "CountryId": "AO",
                                    "Location": "13.483333, -12.483333"
                                }
                            ]
                        },
                        {
                            "Id": "ANLA",
                            "Name": "Andulo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "16.7, -11.483333",
                            "IataCode": "ANL",
                            "Airports": [
                                {
                                    "Id": "ANL",
                                    "Name": "Andulo",
                                    "CityId": "ANLA",
                                    "CountryId": "AO",
                                    "Location": "17.25, -11.25"
                                }
                            ]
                        },
                        {
                            "Id": "DUEA",
                            "Name": "Dundo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "20.833333, -7.3833333",
                            "IataCode": "DUE",
                            "Airports": [
                                {
                                    "Id": "DUE",
                                    "Name": "Dundo",
                                    "CityId": "DUEA",
                                    "CountryId": "AO",
                                    "Location": "20.816667, -7.4"
                                }
                            ]
                        },
                        {
                            "Id": "DRCA",
                            "Name": "Dirico",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "20.783333, -17.983333",
                            "IataCode": "DRC",
                            "Airports": [
                                {
                                    "Id": "DRC",
                                    "Name": "Dirico",
                                    "CityId": "DRCA",
                                    "CountryId": "AO",
                                    "Location": "20.7, -17.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JMBA",
                            "Name": "Jamba",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "16.033333, -14.683333",
                            "IataCode": "JMB",
                            "Airports": [
                                {
                                    "Id": "JMB",
                                    "Name": "Jamba",
                                    "CityId": "JMBA",
                                    "CountryId": "AO",
                                    "Location": "16.083333, -14.7"
                                }
                            ]
                        },
                        {
                            "Id": "LADA",
                            "Name": "Luanda",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.234446, -8.8383332",
                            "IataCode": "LAD",
                            "Airports": [
                                {
                                    "Id": "LAD",
                                    "Name": "Luanda",
                                    "CityId": "LADA",
                                    "CountryId": "AO",
                                    "Location": "13.236111, -8.853056"
                                }
                            ]
                        },
                        {
                            "Id": "LBZA",
                            "Name": "Lukapa",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "20.75, -8.4166667",
                            "IataCode": "LBZ",
                            "Airports": [
                                {
                                    "Id": "LBZ",
                                    "Name": "Lukapa",
                                    "CityId": "LBZA",
                                    "CountryId": "AO",
                                    "Location": "20.666667, -8.383333"
                                }
                            ]
                        },
                        {
                            "Id": "LUOA",
                            "Name": "Luena",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "19.916667, -11.783333",
                            "IataCode": "LUO",
                            "Airports": [
                                {
                                    "Id": "LUO",
                                    "Name": "Luena",
                                    "CityId": "LUOA",
                                    "CountryId": "AO",
                                    "Location": "19.89521, -11.768554"
                                }
                            ]
                        },
                        {
                            "Id": "SDDA",
                            "Name": "Lubango",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.5, -14.916667",
                            "IataCode": "SDD",
                            "Airports": [
                                {
                                    "Id": "SDD",
                                    "Name": "Lubango",
                                    "CityId": "SDDA",
                                    "CountryId": "AO",
                                    "Location": "13.576667, -14.924444"
                                }
                            ]
                        },
                        {
                            "Id": "UALA",
                            "Name": "Luau",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "22.233333, -10.7",
                            "IataCode": "UAL",
                            "Airports": [
                                {
                                    "Id": "UAL",
                                    "Name": "Luau",
                                    "CityId": "UALA",
                                    "CountryId": "AO",
                                    "Location": "22.5, -11"
                                }
                            ]
                        },
                        {
                            "Id": "KNPA",
                            "Name": "Capanda",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.433333, -9.7333333",
                            "IataCode": "KNP",
                            "Airports": [
                                {
                                    "Id": "KNP",
                                    "Name": "Capanda",
                                    "CityId": "KNPA",
                                    "CountryId": "AO",
                                    "Location": "15.450278, -9.766944"
                                }
                            ]
                        },
                        {
                            "Id": "NOVA",
                            "Name": "Huambo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.739167, -12.776111",
                            "IataCode": "NOV",
                            "Airports": [
                                {
                                    "Id": "NOV",
                                    "Name": "Huambo",
                                    "CityId": "NOVA",
                                    "CountryId": "AO",
                                    "Location": "15.757222, -12.805556"
                                }
                            ]
                        },
                        {
                            "Id": "SPPA",
                            "Name": "Menongue",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "17.7, -14.666667",
                            "IataCode": "SPP",
                            "Airports": [
                                {
                                    "Id": "SPP",
                                    "Name": "Menongue",
                                    "CityId": "SPPA",
                                    "CountryId": "AO",
                                    "Location": "17.725556, -14.6375"
                                }
                            ]
                        },
                        {
                            "Id": "SVPA",
                            "Name": "Kuito",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "16.933333, -12.383333",
                            "IataCode": "SVP",
                            "Airports": [
                                {
                                    "Id": "SVP",
                                    "Name": "Kuito",
                                    "CityId": "SVPA",
                                    "CountryId": "AO",
                                    "Location": "16.956667, -12.404167"
                                }
                            ]
                        },
                        {
                            "Id": "MEGA",
                            "Name": "Malange",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "16.345278, -9.5447222",
                            "IataCode": "MEG",
                            "Airports": [
                                {
                                    "Id": "MEG",
                                    "Name": "Malange",
                                    "CityId": "MEGA",
                                    "CountryId": "AO",
                                    "Location": "16.333333, -9.533333"
                                }
                            ]
                        },
                        {
                            "Id": "MSZA",
                            "Name": "Namibe",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "12.152222, -15.196111",
                            "IataCode": "MSZ",
                            "Airports": [
                                {
                                    "Id": "MSZ",
                                    "Name": "Namibe",
                                    "CityId": "MSZA",
                                    "CountryId": "AO",
                                    "Location": "12.160833, -15.208611"
                                }
                            ]
                        },
                        {
                            "Id": "NDDA",
                            "Name": "Sumbe",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.833333, -11.2",
                            "IataCode": "NDD",
                            "Airports": [
                                {
                                    "Id": "NDD",
                                    "Name": "Sumbe",
                                    "CityId": "NDDA",
                                    "CountryId": "AO",
                                    "Location": "13.866667, -11.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PBNA",
                            "Name": "Porto Amboim",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "13.75, -10.733333",
                            "IataCode": "PBN",
                            "Airports": [
                                {
                                    "Id": "PBN",
                                    "Name": "Porto Amboim",
                                    "CityId": "PBNA",
                                    "CountryId": "AO",
                                    "Location": "13.75, -10.7"
                                }
                            ]
                        },
                        {
                            "Id": "PGIA",
                            "Name": "Chitato",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "20.816667, -7.35",
                            "IataCode": "PGI",
                            "Airports": [
                                {
                                    "Id": "PGI",
                                    "Name": "Chitato",
                                    "CityId": "PGIA",
                                    "CountryId": "AO",
                                    "Location": "20.8, -7.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SZAA",
                            "Name": "Soyo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "12.366667, -6.1333333",
                            "IataCode": "SZA",
                            "Airports": [
                                {
                                    "Id": "SZA",
                                    "Name": "Soyo",
                                    "CityId": "SZAA",
                                    "CountryId": "AO",
                                    "Location": "12.3718, -6.14109"
                                }
                            ]
                        },
                        {
                            "Id": "UGOA",
                            "Name": "Uige",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.05, -7.6166667",
                            "IataCode": "UGO",
                            "Airports": [
                                {
                                    "Id": "UGO",
                                    "Name": "Uige",
                                    "CityId": "UGOA",
                                    "CountryId": "AO",
                                    "Location": "15.15, -7.816667"
                                }
                            ]
                        },
                        {
                            "Id": "VHCA",
                            "Name": "Saurimo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "20.4, -9.65",
                            "IataCode": "VHC",
                            "Airports": [
                                {
                                    "Id": "VHC",
                                    "Name": "Saurimo",
                                    "CityId": "VHCA",
                                    "CountryId": "AO",
                                    "Location": "20.55, -9.75"
                                }
                            ]
                        },
                        {
                            "Id": "VPEA",
                            "Name": "Ongiva",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.733333, -17.066667",
                            "IataCode": "VPE",
                            "Airports": [
                                {
                                    "Id": "VPE",
                                    "Name": "Ongiva",
                                    "CityId": "VPEA",
                                    "CountryId": "AO",
                                    "Location": "15.7, -17.05"
                                }
                            ]
                        },
                        {
                            "Id": "GGCA",
                            "Name": "Lumbala",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "22.583333, -12.65",
                            "IataCode": "GGC",
                            "Airports": [
                                {
                                    "Id": "GGC",
                                    "Name": "Lumbala",
                                    "CityId": "GGCA",
                                    "CountryId": "AO",
                                    "Location": "22.566667, -12.65"
                                }
                            ]
                        },
                        {
                            "Id": "GXGA",
                            "Name": "Negage",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.266667, -7.7833333",
                            "IataCode": "GXG",
                            "Airports": [
                                {
                                    "Id": "GXG",
                                    "Name": "Negage",
                                    "CityId": "GXGA",
                                    "CountryId": "AO",
                                    "Location": "15.45, -7.783333"
                                }
                            ]
                        },
                        {
                            "Id": "NDFA",
                            "Name": "Ndalatandos",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "14.916667, -9.3",
                            "IataCode": "NDF",
                            "Airports": [
                                {
                                    "Id": "NDF",
                                    "Name": "Ndalatandos",
                                    "CityId": "NDFA",
                                    "CountryId": "AO",
                                    "Location": "14.833333, -9.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SSYA",
                            "Name": "M'Banza Congo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "14.25, -6.2666667",
                            "IataCode": "SSY",
                            "Airports": [
                                {
                                    "Id": "SSY",
                                    "Name": "M'Banza Congo",
                                    "CityId": "SSYA",
                                    "CountryId": "AO",
                                    "Location": "14.244167, -6.273889"
                                }
                            ]
                        },
                        {
                            "Id": "CEOA",
                            "Name": "Waco Kungo",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.11667, -11.35",
                            "IataCode": "CEO",
                            "Airports": [
                                {
                                    "Id": "CEO",
                                    "Name": "Waco Kungo",
                                    "CityId": "CEOA",
                                    "CountryId": "AO",
                                    "Location": "124.73056, 9.308333"
                                }
                            ]
                        },
                        {
                            "Id": "CFFA",
                            "Name": "Monachinogue",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "18, -8.6",
                            "IataCode": "CFF",
                            "Airports": [
                                {
                                    "Id": "CFF",
                                    "Name": "Cafunfo",
                                    "CityId": "CFFA",
                                    "CountryId": "AO",
                                    "Location": "18, -8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "LZMA",
                            "Name": "Cuango",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "18.05, -9.1333333",
                            "IataCode": "LZM",
                            "Airports": [
                                {
                                    "Id": "LZM",
                                    "Name": "Luzamba",
                                    "CityId": "LZMA",
                                    "CountryId": "AO",
                                    "Location": "18.083333, -9.05"
                                }
                            ]
                        },
                        {
                            "Id": "NGVA",
                            "Name": "Omupanda",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "15.766667, -17.133333",
                            "IataCode": "NGV",
                            "Airports": [
                                {
                                    "Id": "NGV",
                                    "Name": "Ngiva",
                                    "CityId": "NGVA",
                                    "CountryId": "AO",
                                    "Location": "15.783333, -17.05"
                                }
                            ]
                        },
                        {
                            "Id": "NZAA",
                            "Name": "Camatai",
                            "SingleAirportCity": true,
                            "CountryId": "AO",
                            "Location": "21.37868, -7.69244",
                            "IataCode": "NZA",
                            "Airports": [
                                {
                                    "Id": "NZA",
                                    "Name": "Nzagi",
                                    "CityId": "NZAA",
                                    "CountryId": "AO",
                                    "Location": "21.358066, -7.716081"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GW",
                    "Name": "Guinea-Bissau",
                    "CurrencyId": "GNF",
                    "Cities": [
                        {
                            "Id": "BQEA",
                            "Name": "Bubaque",
                            "SingleAirportCity": true,
                            "CountryId": "GW",
                            "Location": "-15.833333, 11.283333",
                            "IataCode": "BQE",
                            "Airports": [
                                {
                                    "Id": "BQE",
                                    "Name": "Bubaque",
                                    "CityId": "BQEA",
                                    "CountryId": "GW",
                                    "Location": "-15.85, 11.3"
                                }
                            ]
                        },
                        {
                            "Id": "OXBA",
                            "Name": "Bissau",
                            "SingleAirportCity": true,
                            "CountryId": "GW",
                            "Location": "-15.583333, 11.85",
                            "IataCode": "OXB",
                            "Airports": [
                                {
                                    "Id": "OXB",
                                    "Name": "Bissau",
                                    "CityId": "OXBA",
                                    "CountryId": "GW",
                                    "Location": "-15.657222, 11.888889"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "EG",
                    "Name": "Egypt",
                    "CurrencyId": "EGP",
                    "Cities": [
                        {
                            "Id": "GSQA",
                            "Name": "Shark Elowainat",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "28.716667, 22.583333",
                            "IataCode": "GSQ",
                            "Airports": [
                                {
                                    "Id": "GSQ",
                                    "Name": "Shark Elowainat",
                                    "CityId": "GSQA",
                                    "CountryId": "EG",
                                    "Location": "28.716667, 22.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ABUS",
                            "Name": "Abu Simbel",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.614052, 22.359421",
                            "IataCode": "ABS",
                            "Airports": [
                                {
                                    "Id": "ABS",
                                    "Name": "Abu Simbel",
                                    "CityId": "ABUS",
                                    "CountryId": "EG",
                                    "Location": "31.615278, 22.372222"
                                }
                            ]
                        },
                        {
                            "Id": "ALEX",
                            "Name": "Alexandria",
                            "SingleAirportCity": false,
                            "CountryId": "EG",
                            "Location": "29.976203, 31.234373",
                            "IataCode": "ALY",
                            "Airports": [
                                {
                                    "Id": "HBE",
                                    "Name": "Alexandria Borg El Arab",
                                    "CityId": "ALEX",
                                    "CountryId": "EG",
                                    "Location": "29.683333, 30.9"
                                },
                                {
                                    "Id": "ALY",
                                    "Name": "Alexandria El Nohza",
                                    "CityId": "ALEX",
                                    "CountryId": "EG",
                                    "Location": "29.948889, 31.183903"
                                }
                            ]
                        },
                        {
                            "Id": "ABUR",
                            "Name": "Abu Ruedis",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "33.1875, 28.903611",
                            "IataCode": "AUE",
                            "Airports": [
                                {
                                    "Id": "AUE",
                                    "Name": "Abu Ruedis",
                                    "CityId": "ABUR",
                                    "CountryId": "EG",
                                    "Location": "33.183333, 28.9"
                                }
                            ]
                        },
                        {
                            "Id": "CAIR",
                            "Name": "Cairo",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.288286, 30.064068",
                            "IataCode": "CAI",
                            "Airports": [
                                {
                                    "Id": "CAI",
                                    "Name": "Cairo",
                                    "CityId": "CAIR",
                                    "CountryId": "EG",
                                    "Location": "31.399705, 30.112836"
                                }
                            ]
                        },
                        {
                            "Id": "HURG",
                            "Name": "Hurghada",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "33.81609, 27.199339",
                            "IataCode": "HRG",
                            "Airports": [
                                {
                                    "Id": "HRG",
                                    "Name": "Hurghada",
                                    "CityId": "HURG",
                                    "CountryId": "EG",
                                    "Location": "33.801069, 27.178759"
                                }
                            ]
                        },
                        {
                            "Id": "LUXO",
                            "Name": "Luxor",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "32.657643, 25.700974",
                            "IataCode": "LXR",
                            "Airports": [
                                {
                                    "Id": "LXR",
                                    "Name": "Luxor",
                                    "CityId": "LUXO",
                                    "CountryId": "EG",
                                    "Location": "32.7075, 25.673056"
                                }
                            ]
                        },
                        {
                            "Id": "MART",
                            "Name": "Marsa Allam",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "34.84318, 25.147957",
                            "IataCode": "RMF",
                            "Airports": [
                                {
                                    "Id": "RMF",
                                    "Name": "Marsa Allam",
                                    "CityId": "MART",
                                    "CountryId": "EG",
                                    "Location": "34.583711, 25.557111"
                                }
                            ]
                        },
                        {
                            "Id": "TABB",
                            "Name": "Taba",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "34.884297, 29.491939",
                            "IataCode": "TCP",
                            "Airports": [
                                {
                                    "Id": "TCP",
                                    "Name": "Taba",
                                    "CityId": "TABB",
                                    "CountryId": "EG",
                                    "Location": "34.778056, 29.587778"
                                }
                            ]
                        },
                        {
                            "Id": "ASWA",
                            "Name": "Aswan Daraw",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "32.885178, 24.069708",
                            "IataCode": "ASW",
                            "Airports": [
                                {
                                    "Id": "ASW",
                                    "Name": "Aswan Daraw",
                                    "CityId": "ASWA",
                                    "CountryId": "EG",
                                    "Location": "32.821667, 23.965"
                                }
                            ]
                        },
                        {
                            "Id": "ASSI",
                            "Name": "Assiut",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.161634, 27.18907",
                            "IataCode": "ATZ",
                            "Airports": [
                                {
                                    "Id": "ATZ",
                                    "Name": "Assiut",
                                    "CityId": "ASSI",
                                    "CountryId": "EG",
                                    "Location": "31, 27.033333"
                                }
                            ]
                        },
                        {
                            "Id": "SIWA",
                            "Name": "Siwa",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "25.516667, 29.2",
                            "IataCode": "SEW",
                            "Airports": [
                                {
                                    "Id": "SEW",
                                    "Name": "Siwa",
                                    "CityId": "SIWA",
                                    "CountryId": "EG",
                                    "Location": "25.516667, 29.183333"
                                }
                            ]
                        },
                        {
                            "Id": "MERS",
                            "Name": "Mersa Matruh",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "27.248486, 31.351927",
                            "IataCode": "MUH",
                            "Airports": [
                                {
                                    "Id": "MUH",
                                    "Name": "Mersa Matruh",
                                    "CityId": "MERS",
                                    "CountryId": "EG",
                                    "Location": "27.221689, 31.325356"
                                }
                            ]
                        },
                        {
                            "Id": "PORZ",
                            "Name": "Port Said",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "32.290256, 31.263717",
                            "IataCode": "PSD",
                            "Airports": [
                                {
                                    "Id": "PSD",
                                    "Name": "Port Said",
                                    "CityId": "PORZ",
                                    "CountryId": "EG",
                                    "Location": "32.24, 31.279444"
                                }
                            ]
                        },
                        {
                            "Id": "SHAR",
                            "Name": "Sharm El Sheikh",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "34.344355, 27.931329",
                            "IataCode": "SSH",
                            "Airports": [
                                {
                                    "Id": "SSH",
                                    "Name": "Sharm El Sheikh",
                                    "CityId": "SHAR",
                                    "CountryId": "EG",
                                    "Location": "34.395, 27.977222"
                                }
                            ]
                        },
                        {
                            "Id": "SPXE",
                            "Name": "Giza",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.184053, 30.001058",
                            "IataCode": "SPX",
                            "Airports": [
                                {
                                    "Id": "SPX",
                                    "Name": "Sphinx International",
                                    "CityId": "SPXE",
                                    "CountryId": "EG",
                                    "Location": "30.894444, 30.109722"
                                }
                            ]
                        },
                        {
                            "Id": "SOHG",
                            "Name": "Sohag",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.695515, 26.547113",
                            "IataCode": "HMB",
                            "Airports": [
                                {
                                    "Id": "HMB",
                                    "Name": "Sohag",
                                    "CityId": "SOHG",
                                    "CountryId": "EG",
                                    "Location": "31.737167, 26.338877"
                                }
                            ]
                        },
                        {
                            "Id": "ELMI",
                            "Name": "El Minya",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "30.744445, 28.119445",
                            "IataCode": "EMY",
                            "Airports": [
                                {
                                    "Id": "EMY",
                                    "Name": "El Minya",
                                    "CityId": "ELMI",
                                    "CountryId": "EG",
                                    "Location": "30.75, 28.1"
                                }
                            ]
                        },
                        {
                            "Id": "ALAR",
                            "Name": "Al Arish",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "33.801944, 31.126111",
                            "IataCode": "AAC",
                            "Airports": [
                                {
                                    "Id": "AAC",
                                    "Name": "Al Arish",
                                    "CityId": "ALAR",
                                    "CountryId": "EG",
                                    "Location": "33.835833, 31.073333"
                                }
                            ]
                        },
                        {
                            "Id": "NEWV",
                            "Name": "El-Kharaga",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "30.546014, 25.447345",
                            "IataCode": "UVL",
                            "Airports": [
                                {
                                    "Id": "UVL",
                                    "Name": "New Valley Kharga",
                                    "CityId": "NEWV",
                                    "CountryId": "EG",
                                    "Location": "30.590833, 25.475"
                                }
                            ]
                        },
                        {
                            "Id": "SQKA",
                            "Name": "Sid Barani",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "25.916667, 31.6",
                            "IataCode": "SQK",
                            "Airports": [
                                {
                                    "Id": "SQK",
                                    "Name": "Sidi Barani",
                                    "CityId": "SQKA",
                                    "CountryId": "EG",
                                    "Location": "25.933333, 31.6"
                                }
                            ]
                        },
                        {
                            "Id": "DAKA",
                            "Name": "Mut",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "28.983333, 25.483333",
                            "IataCode": "DAK",
                            "Airports": [
                                {
                                    "Id": "DAK",
                                    "Name": "Dakhla Oasis",
                                    "CityId": "DAKA",
                                    "CountryId": "EG",
                                    "Location": "28.999167, 25.414722"
                                }
                            ]
                        },
                        {
                            "Id": "SINA",
                            "Name": "Al Jubayl",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "33.6275, 28.197778",
                            "IataCode": "ELT",
                            "Airports": [
                                {
                                    "Id": "ELT",
                                    "Name": "Tour Sinai City",
                                    "CityId": "SINA",
                                    "CountryId": "EG",
                                    "Location": "33.633333, 28.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SKVA",
                            "Name": "El-Tur",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "33.623611, 28.241667",
                            "IataCode": "SKV",
                            "Airports": [
                                {
                                    "Id": "SKV",
                                    "Name": "Santa Katarina",
                                    "CityId": "SKVA",
                                    "CountryId": "EG",
                                    "Location": "33.95, 28.5"
                                }
                            ]
                        },
                        {
                            "Id": "DBBA",
                            "Name": "Aḑ Ḑab`ah",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "28.433333, 31.033333",
                            "IataCode": "DBB",
                            "Airports": [
                                {
                                    "Id": "DBB",
                                    "Name": "Dabaa",
                                    "CityId": "DBBA",
                                    "CountryId": "EG",
                                    "Location": "28.461436, 30.924458"
                                }
                            ]
                        },
                        {
                            "Id": "TFRA",
                            "Name": "Qalyub",
                            "SingleAirportCity": true,
                            "CountryId": "EG",
                            "Location": "31.214553, 30.180924",
                            "IataCode": "TFR",
                            "Airports": [
                                {
                                    "Id": "TFR",
                                    "Name": "Ramadan",
                                    "CityId": "TFRA",
                                    "CountryId": "EG",
                                    "Location": "31.400833, 30.1175"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CI",
                    "Name": "Ivory Coast",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "ZSSA",
                            "Name": "Sassandra",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.0833334, 4.95",
                            "IataCode": "ZSS",
                            "Airports": [
                                {
                                    "Id": "ZSS",
                                    "Name": "Sassandra",
                                    "CityId": "ZSSA",
                                    "CountryId": "CI",
                                    "Location": "-6.135556, 4.924444"
                                }
                            ]
                        },
                        {
                            "Id": "BDKA",
                            "Name": "Bondoukou",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-2.8, 8.0333333",
                            "IataCode": "BDK",
                            "Airports": [
                                {
                                    "Id": "BDK",
                                    "Name": "Bondoukou",
                                    "CityId": "BDKA",
                                    "CountryId": "CI",
                                    "Location": "-2.8, 8.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BQOA",
                            "Name": "Bouna",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-3, 9.2666667",
                            "IataCode": "BQO",
                            "Airports": [
                                {
                                    "Id": "BQO",
                                    "Name": "Bouna",
                                    "CityId": "BQOA",
                                    "CountryId": "CI",
                                    "Location": "-3, 9.266667"
                                }
                            ]
                        },
                        {
                            "Id": "BRZA",
                            "Name": "Borotou",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.4930556, 8.7411111",
                            "IataCode": "BRZ",
                            "Airports": [
                                {
                                    "Id": "BRZ",
                                    "Name": "Borotou",
                                    "CityId": "BRZA",
                                    "CountryId": "CI",
                                    "Location": "-7.5, 8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "BXIA",
                            "Name": "Boundiali",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.4869445, 9.5216667",
                            "IataCode": "BXI",
                            "Airports": [
                                {
                                    "Id": "BXI",
                                    "Name": "Boundiali",
                                    "CityId": "BXIA",
                                    "CountryId": "CI",
                                    "Location": "-6.5, 8.75"
                                }
                            ]
                        },
                        {
                            "Id": "BYKA",
                            "Name": "Bouake",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.0330553, 7.6833333",
                            "IataCode": "BYK",
                            "Airports": [
                                {
                                    "Id": "BYK",
                                    "Name": "Bouake",
                                    "CityId": "BYKA",
                                    "CountryId": "CI",
                                    "Location": "-5.069167, 7.739444"
                                }
                            ]
                        },
                        {
                            "Id": "DIMA",
                            "Name": "Dimbokro",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-4.6999999, 6.65",
                            "IataCode": "DIM",
                            "Airports": [
                                {
                                    "Id": "DIM",
                                    "Name": "Dimbokro",
                                    "CityId": "DIMA",
                                    "CountryId": "CI",
                                    "Location": "-4.766667, 6.75"
                                }
                            ]
                        },
                        {
                            "Id": "DIVA",
                            "Name": "Divo",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.3599997, 5.8397222",
                            "IataCode": "DIV",
                            "Airports": [
                                {
                                    "Id": "DIV",
                                    "Name": "Divo",
                                    "CityId": "DIVA",
                                    "CountryId": "CI",
                                    "Location": "-5.25, 5.8"
                                }
                            ]
                        },
                        {
                            "Id": "DJOA",
                            "Name": "Daloa",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.4519447, 6.8747222",
                            "IataCode": "DJO",
                            "Airports": [
                                {
                                    "Id": "DJO",
                                    "Name": "Daloa",
                                    "CityId": "DJOA",
                                    "CountryId": "CI",
                                    "Location": "-6.466667, 6.866944"
                                }
                            ]
                        },
                        {
                            "Id": "FEKA",
                            "Name": "Ferkessedougou",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.1999999, 9.6",
                            "IataCode": "FEK",
                            "Airports": [
                                {
                                    "Id": "FEK",
                                    "Name": "Ferkessedougou",
                                    "CityId": "FEKA",
                                    "CountryId": "CI",
                                    "Location": "-5.2, 9.6"
                                }
                            ]
                        },
                        {
                            "Id": "GGNA",
                            "Name": "Gagnoa",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.933333, 6.1333333",
                            "IataCode": "GGN",
                            "Airports": [
                                {
                                    "Id": "GGN",
                                    "Name": "Gagnoa",
                                    "CityId": "GGNA",
                                    "CountryId": "CI",
                                    "Location": "-5.933333, 6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "GGOA",
                            "Name": "Guiglo",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.4858333, 6.5402778",
                            "IataCode": "GGO",
                            "Airports": [
                                {
                                    "Id": "GGO",
                                    "Name": "Guiglo",
                                    "CityId": "GGOA",
                                    "CountryId": "CI",
                                    "Location": "-7.75, 6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "HGOA",
                            "Name": "Korhogo",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.6333331, 9.45",
                            "IataCode": "HGO",
                            "Airports": [
                                {
                                    "Id": "HGO",
                                    "Name": "Korhogo",
                                    "CityId": "HGOA",
                                    "CountryId": "CI",
                                    "Location": "-5.616667, 9.413889"
                                }
                            ]
                        },
                        {
                            "Id": "KEOA",
                            "Name": "Odienne",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.5691668, 9.51",
                            "IataCode": "KEO",
                            "Airports": [
                                {
                                    "Id": "KEO",
                                    "Name": "Odienne",
                                    "CityId": "KEOA",
                                    "CountryId": "CI",
                                    "Location": "-7.570833, 9.49"
                                }
                            ]
                        },
                        {
                            "Id": "LKTA",
                            "Name": "Lakota",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.6883332, 5.8527778",
                            "IataCode": "LKT",
                            "Airports": [
                                {
                                    "Id": "LKT",
                                    "Name": "Lakota",
                                    "CityId": "LKTA",
                                    "CountryId": "CI",
                                    "Location": "-5.5, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ABJA",
                            "Name": "Abidjan",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-4.0280547, 5.3411122",
                            "IataCode": "ABJ",
                            "Airports": [
                                {
                                    "Id": "ABJ",
                                    "Name": "Abidjan",
                                    "CityId": "ABJA",
                                    "CountryId": "CI",
                                    "Location": "-3.926944, 5.256389"
                                }
                            ]
                        },
                        {
                            "Id": "ABOA",
                            "Name": "Aboisso",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-3.2144444, 5.4675",
                            "IataCode": "ABO",
                            "Airports": [
                                {
                                    "Id": "ABO",
                                    "Name": "Aboisso",
                                    "CityId": "ABOA",
                                    "CountryId": "CI",
                                    "Location": "-3.216667, 5.433333"
                                }
                            ]
                        },
                        {
                            "Id": "ASKA",
                            "Name": "Yamoussoukro",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.2833331, 6.8166667",
                            "IataCode": "ASK",
                            "Airports": [
                                {
                                    "Id": "ASK",
                                    "Name": "Yamoussoukro",
                                    "CityId": "ASKA",
                                    "CountryId": "CI",
                                    "Location": "-5.283333, 6.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TOZA",
                            "Name": "Touba",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.6833333, 8.2833333",
                            "IataCode": "TOZ",
                            "Airports": [
                                {
                                    "Id": "TOZ",
                                    "Name": "Touba",
                                    "CityId": "TOZA",
                                    "CountryId": "CI",
                                    "Location": "-7.683333, 8.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ZUEA",
                            "Name": "Zuenoula",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.0430556, 7.4291667",
                            "IataCode": "ZUE",
                            "Airports": [
                                {
                                    "Id": "ZUE",
                                    "Name": "Zuenoula",
                                    "CityId": "ZUEA",
                                    "CountryId": "CI",
                                    "Location": "-6.05, 7.433333"
                                }
                            ]
                        },
                        {
                            "Id": "DNCA",
                            "Name": "Danane",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-8.1597222, 7.2627778",
                            "IataCode": "DNC",
                            "Airports": [
                                {
                                    "Id": "DNC",
                                    "Name": "Danane",
                                    "CityId": "DNCA",
                                    "CountryId": "CI",
                                    "Location": "-8.166667, 7.35"
                                }
                            ]
                        },
                        {
                            "Id": "MJCA",
                            "Name": "Man",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.5475002, 7.4052778",
                            "IataCode": "MJC",
                            "Airports": [
                                {
                                    "Id": "MJC",
                                    "Name": "Man",
                                    "CityId": "MJCA",
                                    "CountryId": "CI",
                                    "Location": "-7.58736, 7.27207"
                                }
                            ]
                        },
                        {
                            "Id": "MOKA",
                            "Name": "Mankono",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.1897223, 8.0586111",
                            "IataCode": "MOK",
                            "Airports": [
                                {
                                    "Id": "MOK",
                                    "Name": "Mankono",
                                    "CityId": "MOKA",
                                    "CountryId": "CI",
                                    "Location": "-6.15, 8.016667"
                                }
                            ]
                        },
                        {
                            "Id": "OGOA",
                            "Name": "Abengourou",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-3.4963889, 6.7297222",
                            "IataCode": "OGO",
                            "Airports": [
                                {
                                    "Id": "OGO",
                                    "Name": "Abengourou",
                                    "CityId": "OGOA",
                                    "CountryId": "CI",
                                    "Location": "-3.45, 6.7"
                                }
                            ]
                        },
                        {
                            "Id": "SEOA",
                            "Name": "Seguela",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.6730557, 7.9611111",
                            "IataCode": "SEO",
                            "Airports": [
                                {
                                    "Id": "SEO",
                                    "Name": "Seguela",
                                    "CityId": "SEOA",
                                    "CountryId": "CI",
                                    "Location": "-6.666667, 7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SPYA",
                            "Name": "San Pedro",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.6166669, 4.7333333",
                            "IataCode": "SPY",
                            "Airports": [
                                {
                                    "Id": "SPY",
                                    "Name": "San Pedro",
                                    "CityId": "SPYA",
                                    "CountryId": "CI",
                                    "Location": "-6.626944, 4.730278"
                                }
                            ]
                        },
                        {
                            "Id": "TGXA",
                            "Name": "Tingrela",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-6.4097223, 10.486944",
                            "IataCode": "TGX",
                            "Airports": [
                                {
                                    "Id": "TGX",
                                    "Name": "Tingrela",
                                    "CityId": "TGXA",
                                    "CountryId": "CI",
                                    "Location": "-6.333333, 10.433333"
                                }
                            ]
                        },
                        {
                            "Id": "TXUA",
                            "Name": "Tabou",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.35, 4.4166667",
                            "IataCode": "TXU",
                            "Airports": [
                                {
                                    "Id": "TXU",
                                    "Name": "Tabou",
                                    "CityId": "TXUA",
                                    "CountryId": "CI",
                                    "Location": "-7.366667, 4.433333"
                                }
                            ]
                        },
                        {
                            "Id": "KTCA",
                            "Name": "Katiola",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.0999999, 8.1333333",
                            "IataCode": "KTC",
                            "Airports": [
                                {
                                    "Id": "KTC",
                                    "Name": "Katiola",
                                    "CityId": "KTCA",
                                    "CountryId": "CI",
                                    "Location": "-5.166667, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TBXA",
                            "Name": "Taabo",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-5.1333333, 6.2333333",
                            "IataCode": "TBX",
                            "Airports": [
                                {
                                    "Id": "TBX",
                                    "Name": "Taabo",
                                    "CityId": "TBXA",
                                    "CountryId": "CI",
                                    "Location": "0, 0"
                                }
                            ]
                        },
                        {
                            "Id": "BBVA",
                            "Name": "Bereby",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-7.0166667, 4.5666667",
                            "IataCode": "BBV",
                            "Airports": [
                                {
                                    "Id": "BBV",
                                    "Name": "Bereby",
                                    "CityId": "BBVA",
                                    "CountryId": "CI",
                                    "Location": "-6.95, 4.666667"
                                }
                            ]
                        },
                        {
                            "Id": "OFIA",
                            "Name": "Ouangofétini",
                            "SingleAirportCity": true,
                            "CountryId": "CI",
                            "Location": "-4.05, 9.5666667",
                            "IataCode": "OFI",
                            "Airports": [
                                {
                                    "Id": "OFI",
                                    "Name": "Ouango Fitini",
                                    "CityId": "OFIA",
                                    "CountryId": "CI",
                                    "Location": "-4.033333, 9.6"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CG",
                    "Name": "Republic of the Congo",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "IONA",
                            "Name": "Impfondo",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "18.066667, 1.6380556",
                            "IataCode": "ION",
                            "Airports": [
                                {
                                    "Id": "ION",
                                    "Name": "Impfondo",
                                    "CityId": "IONA",
                                    "CountryId": "CG",
                                    "Location": "18.061944, 1.613333"
                                }
                            ]
                        },
                        {
                            "Id": "BOEA",
                            "Name": "Boundji",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.359167, -1.0275",
                            "IataCode": "BOE",
                            "Airports": [
                                {
                                    "Id": "BOE",
                                    "Name": "Boundji",
                                    "CityId": "BOEA",
                                    "CountryId": "CG",
                                    "Location": "15.383333, -1.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BTBA",
                            "Name": "Betou",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "18.517222, 3.055",
                            "IataCode": "BTB",
                            "Airports": [
                                {
                                    "Id": "BTB",
                                    "Name": "Betou",
                                    "CityId": "BTBA",
                                    "CountryId": "CG",
                                    "Location": "18.5, 3.05"
                                }
                            ]
                        },
                        {
                            "Id": "BZVA",
                            "Name": "Brazzaville",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.284722, -4.2591667",
                            "IataCode": "BZV",
                            "Airports": [
                                {
                                    "Id": "BZV",
                                    "Name": "Brazzaville",
                                    "CityId": "BZVA",
                                    "CountryId": "CG",
                                    "Location": "15.254722, -4.251944"
                                }
                            ]
                        },
                        {
                            "Id": "DJMA",
                            "Name": "Djambala",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.753333, -2.5447222",
                            "IataCode": "DJM",
                            "Airports": [
                                {
                                    "Id": "DJM",
                                    "Name": "Djambala",
                                    "CityId": "DJMA",
                                    "CountryId": "CG",
                                    "Location": "14.75, -2.533333"
                                }
                            ]
                        },
                        {
                            "Id": "DISA",
                            "Name": "Loubomo",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "12.673889, -4.1997222",
                            "IataCode": "DIS",
                            "Airports": [
                                {
                                    "Id": "DIS",
                                    "Name": "Loubomo",
                                    "CityId": "DISA",
                                    "CountryId": "CG",
                                    "Location": "12.661389, -4.205556"
                                }
                            ]
                        },
                        {
                            "Id": "EPNA",
                            "Name": "Epena",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "17.4525, 1.3525",
                            "IataCode": "EPN",
                            "Airports": [
                                {
                                    "Id": "EPN",
                                    "Name": "Epena",
                                    "CityId": "EPNA",
                                    "CountryId": "CG",
                                    "Location": "17.533333, 1.366667"
                                }
                            ]
                        },
                        {
                            "Id": "EWOA",
                            "Name": "Ewo",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.820556, -0.8725",
                            "IataCode": "EWO",
                            "Airports": [
                                {
                                    "Id": "EWO",
                                    "Name": "Ewo",
                                    "CityId": "EWOA",
                                    "CountryId": "CG",
                                    "Location": "14.8, -0.883333"
                                }
                            ]
                        },
                        {
                            "Id": "FTXA",
                            "Name": "Owando",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.908056, -0.4869444",
                            "IataCode": "FTX",
                            "Airports": [
                                {
                                    "Id": "FTX",
                                    "Name": "Owando",
                                    "CityId": "FTXA",
                                    "CountryId": "CG",
                                    "Location": "16.008333, -0.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GMMA",
                            "Name": "Gamboma",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.864444, -1.8763889",
                            "IataCode": "GMM",
                            "Airports": [
                                {
                                    "Id": "GMM",
                                    "Name": "Gamboma",
                                    "CityId": "GMMA",
                                    "CountryId": "CG",
                                    "Location": "15.866667, -1.933333"
                                }
                            ]
                        },
                        {
                            "Id": "KMKA",
                            "Name": "Makabana",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "12.605556, -3.4633333",
                            "IataCode": "KMK",
                            "Airports": [
                                {
                                    "Id": "KMK",
                                    "Name": "Makabana",
                                    "CityId": "KMKA",
                                    "CountryId": "CG",
                                    "Location": "12.616667, -3.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KNJA",
                            "Name": "Kindamba",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.521111, -3.7275",
                            "IataCode": "KNJ",
                            "Airports": [
                                {
                                    "Id": "KNJ",
                                    "Name": "Kindamba",
                                    "CityId": "KNJA",
                                    "CountryId": "CG",
                                    "Location": "14.516667, -3.95"
                                }
                            ]
                        },
                        {
                            "Id": "KEEA",
                            "Name": "Kelle",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.496667, -0.0613889",
                            "IataCode": "KEE",
                            "Airports": [
                                {
                                    "Id": "KEE",
                                    "Name": "Kelle",
                                    "CityId": "KEEA",
                                    "CountryId": "CG",
                                    "Location": "14.533333, -0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LKCA",
                            "Name": "Lekana",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.6, -2.3252778",
                            "IataCode": "LKC",
                            "Airports": [
                                {
                                    "Id": "LKC",
                                    "Name": "Lekana",
                                    "CityId": "LKCA",
                                    "CountryId": "CG",
                                    "Location": "14.6, -2.316667"
                                }
                            ]
                        },
                        {
                            "Id": "MKJA",
                            "Name": "Makoua",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.633333, 0.0069444",
                            "IataCode": "MKJ",
                            "Airports": [
                                {
                                    "Id": "MKJ",
                                    "Name": "Makoua",
                                    "CityId": "MKJA",
                                    "CountryId": "CG",
                                    "Location": "15.576667, -0.020278"
                                }
                            ]
                        },
                        {
                            "Id": "PNRA",
                            "Name": "Pointe Noire",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "11.84611, -4.7947222",
                            "IataCode": "PNR",
                            "Airports": [
                                {
                                    "Id": "PNR",
                                    "Name": "Pointe Noire",
                                    "CityId": "PNRA",
                                    "CountryId": "CG",
                                    "Location": "11.885833, -4.810556"
                                }
                            ]
                        },
                        {
                            "Id": "ANJA",
                            "Name": "Zanaga",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "13.826111, -2.8502778",
                            "IataCode": "ANJ",
                            "Airports": [
                                {
                                    "Id": "ANJ",
                                    "Name": "Zanaga",
                                    "CityId": "ANJA",
                                    "CountryId": "CG",
                                    "Location": "13.833333, -2.85"
                                }
                            ]
                        },
                        {
                            "Id": "NKYA",
                            "Name": "Nkayi",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "13.292778, -4.1655556",
                            "IataCode": "NKY",
                            "Airports": [
                                {
                                    "Id": "NKY",
                                    "Name": "Nkayi",
                                    "CityId": "NKYA",
                                    "CountryId": "CG",
                                    "Location": "13.3, -4.216667"
                                }
                            ]
                        },
                        {
                            "Id": "MSXA",
                            "Name": "Mossendjo",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "12.726111, -2.9505556",
                            "IataCode": "MSX",
                            "Airports": [
                                {
                                    "Id": "MSX",
                                    "Name": "Mossendjo",
                                    "CityId": "MSXA",
                                    "CountryId": "CG",
                                    "Location": "12.733333, -2.95"
                                }
                            ]
                        },
                        {
                            "Id": "MUYA",
                            "Name": "Mouyondzi",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "13.914444, -3.9905556",
                            "IataCode": "MUY",
                            "Airports": [
                                {
                                    "Id": "MUY",
                                    "Name": "Mouyondzi",
                                    "CityId": "MUYA",
                                    "CountryId": "CG",
                                    "Location": "13.919444, -3.983333"
                                }
                            ]
                        },
                        {
                            "Id": "OKGA",
                            "Name": "Okoyo",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "15.084444, -1.4647222",
                            "IataCode": "OKG",
                            "Airports": [
                                {
                                    "Id": "OKG",
                                    "Name": "Okoyo",
                                    "CityId": "OKGA",
                                    "CountryId": "CG",
                                    "Location": "15, -1.466667"
                                }
                            ]
                        },
                        {
                            "Id": "OUEA",
                            "Name": "Ouesso",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "16.051667, 1.6136111",
                            "IataCode": "OUE",
                            "Airports": [
                                {
                                    "Id": "OUE",
                                    "Name": "Ouesso",
                                    "CityId": "OUEA",
                                    "CountryId": "CG",
                                    "Location": "16.048611, 1.616667"
                                }
                            ]
                        },
                        {
                            "Id": "SIBA",
                            "Name": "Sibiti",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "13.345833, -3.6858333",
                            "IataCode": "SIB",
                            "Airports": [
                                {
                                    "Id": "SIB",
                                    "Name": "Sibiti",
                                    "CityId": "SIBA",
                                    "CountryId": "CG",
                                    "Location": "13.4, -3.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SOEA",
                            "Name": "Souanke",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.133333, 2.0833333",
                            "IataCode": "SOE",
                            "Airports": [
                                {
                                    "Id": "SOE",
                                    "Name": "Souanke",
                                    "CityId": "SOEA",
                                    "CountryId": "CG",
                                    "Location": "14.166667, 2"
                                }
                            ]
                        },
                        {
                            "Id": "LCOA",
                            "Name": "Akana",
                            "SingleAirportCity": true,
                            "CountryId": "CG",
                            "Location": "14.532222, -2.4519444",
                            "IataCode": "LCO",
                            "Airports": [
                                {
                                    "Id": "LCO",
                                    "Name": "Lague",
                                    "CityId": "LCOA",
                                    "CountryId": "CG",
                                    "Location": "14.533333, -2.45"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GQ",
                    "Name": "Equatorial Guinea",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "BSGA",
                            "Name": "Bata",
                            "SingleAirportCity": true,
                            "CountryId": "GQ",
                            "Location": "9.75, 1.85",
                            "IataCode": "BSG",
                            "Airports": [
                                {
                                    "Id": "BSG",
                                    "Name": "Bata",
                                    "CityId": "BSGA",
                                    "CountryId": "GQ",
                                    "Location": "9.802222, 1.906667"
                                }
                            ]
                        },
                        {
                            "Id": "SSGA",
                            "Name": "Malabo",
                            "SingleAirportCity": true,
                            "CountryId": "GQ",
                            "Location": "8.7833333, 3.75",
                            "IataCode": "SSG",
                            "Airports": [
                                {
                                    "Id": "SSG",
                                    "Name": "Malabo",
                                    "CityId": "SSGA",
                                    "CountryId": "GQ",
                                    "Location": "8.705556, 3.752778"
                                }
                            ]
                        },
                        {
                            "Id": "NBNA",
                            "Name": "Annobon",
                            "SingleAirportCity": true,
                            "CountryId": "GQ",
                            "Location": "5.6325, -1.4013889",
                            "IataCode": "NBN",
                            "Airports": [
                                {
                                    "Id": "NBN",
                                    "Name": "Annobon",
                                    "CityId": "NBNA",
                                    "CountryId": "GQ",
                                    "Location": "5.621944, -1.410277"
                                }
                            ]
                        },
                        {
                            "Id": "GEMA",
                            "Name": "Mongomo",
                            "SingleAirportCity": true,
                            "CountryId": "GQ",
                            "Location": "11.316667, 1.6333333",
                            "IataCode": "GEM",
                            "Airports": [
                                {
                                    "Id": "GEM",
                                    "Name": "Mongomeyen",
                                    "CityId": "GEMA",
                                    "CountryId": "GQ",
                                    "Location": "11.023889, 1.69"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GA",
                    "Name": "Gabon",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "BAWA",
                            "Name": "Biawonque",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "146.35, -0.666667",
                            "IataCode": "BAW",
                            "Airports": [
                                {
                                    "Id": "BAW",
                                    "Name": "Biawonque",
                                    "CityId": "BAWA",
                                    "CountryId": "GA",
                                    "Location": "146.35, -0.666667"
                                }
                            ]
                        },
                        {
                            "Id": "ZKMA",
                            "Name": "Sette Cama",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.75, -2.5333333",
                            "IataCode": "ZKM",
                            "Airports": [
                                {
                                    "Id": "ZKM",
                                    "Name": "Sette Cama",
                                    "CityId": "ZKMA",
                                    "CountryId": "GA",
                                    "Location": "9.75, -2.533333"
                                }
                            ]
                        },
                        {
                            "Id": "AKEA",
                            "Name": "Akieni",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.883333, -1.1833333",
                            "IataCode": "AKE",
                            "Airports": [
                                {
                                    "Id": "AKE",
                                    "Name": "Akieni",
                                    "CityId": "AKEA",
                                    "CountryId": "GA",
                                    "Location": "13.916667, -1.172222"
                                }
                            ]
                        },
                        {
                            "Id": "LTLA",
                            "Name": "Lastourville",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "12.7, -0.8166667",
                            "IataCode": "LTL",
                            "Airports": [
                                {
                                    "Id": "LTL",
                                    "Name": "Lastourville",
                                    "CityId": "LTLA",
                                    "CountryId": "GA",
                                    "Location": "12.716667, -0.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LBQA",
                            "Name": "Lambarene",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.216667, -0.7",
                            "IataCode": "LBQ",
                            "Airports": [
                                {
                                    "Id": "LBQ",
                                    "Name": "Lambarene",
                                    "CityId": "LBQA",
                                    "CountryId": "GA",
                                    "Location": "10.239444, -0.714722"
                                }
                            ]
                        },
                        {
                            "Id": "BGBA",
                            "Name": "Booue",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.933333, -0.1",
                            "IataCode": "BGB",
                            "Airports": [
                                {
                                    "Id": "BGB",
                                    "Name": "Booue",
                                    "CityId": "BGBA",
                                    "CountryId": "GA",
                                    "Location": "11.938889, -0.106667"
                                }
                            ]
                        },
                        {
                            "Id": "BMMA",
                            "Name": "Bitam",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.483333, 2.0833333",
                            "IataCode": "BMM",
                            "Airports": [
                                {
                                    "Id": "BMM",
                                    "Name": "Bitam",
                                    "CityId": "BMMA",
                                    "CountryId": "GA",
                                    "Location": "11.490833, 2.075833"
                                }
                            ]
                        },
                        {
                            "Id": "FOUA",
                            "Name": "Fougamou",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.6, -1.2166667",
                            "IataCode": "FOU",
                            "Airports": [
                                {
                                    "Id": "FOU",
                                    "Name": "Fougamou",
                                    "CityId": "FOUA",
                                    "CountryId": "GA",
                                    "Location": "10.783333, -1.2"
                                }
                            ]
                        },
                        {
                            "Id": "GAXA",
                            "Name": "Gamba",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10, -2.65",
                            "IataCode": "GAX",
                            "Airports": [
                                {
                                    "Id": "GAX",
                                    "Name": "Gamba",
                                    "CityId": "GAXA",
                                    "CountryId": "GA",
                                    "Location": "9.983333, -2.766667"
                                }
                            ]
                        },
                        {
                            "Id": "IGEA",
                            "Name": "Iguela",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.3166667, -1.9166667",
                            "IataCode": "IGE",
                            "Airports": [
                                {
                                    "Id": "IGE",
                                    "Name": "Iguela",
                                    "CityId": "IGEA",
                                    "CountryId": "GA",
                                    "Location": "9.316667, -1.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LEOA",
                            "Name": "Leconi",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "14.233333, -1.5833333",
                            "IataCode": "LEO",
                            "Airports": [
                                {
                                    "Id": "LEO",
                                    "Name": "Leconi",
                                    "CityId": "LEOA",
                                    "CountryId": "GA",
                                    "Location": "14.216667, -1.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KOUA",
                            "Name": "Koulamoutou",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "12.483333, -1.1333333",
                            "IataCode": "KOU",
                            "Airports": [
                                {
                                    "Id": "KOU",
                                    "Name": "Koulamoutou",
                                    "CityId": "KOUA",
                                    "CountryId": "GA",
                                    "Location": "12.508333, -1.116667"
                                }
                            ]
                        },
                        {
                            "Id": "KDNA",
                            "Name": "Ndende",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.383333, -2.3833333",
                            "IataCode": "KDN",
                            "Airports": [
                                {
                                    "Id": "KDN",
                                    "Name": "Ndende",
                                    "CityId": "KDNA",
                                    "CountryId": "GA",
                                    "Location": "11.166667, -2.5"
                                }
                            ]
                        },
                        {
                            "Id": "KMDA",
                            "Name": "Mandji",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.4, -1.7",
                            "IataCode": "KMD",
                            "Airports": [
                                {
                                    "Id": "KMD",
                                    "Name": "Mandji",
                                    "CityId": "KMDA",
                                    "CountryId": "GA",
                                    "Location": "10.383333, -1.7"
                                }
                            ]
                        },
                        {
                            "Id": "LBVA",
                            "Name": "Libreville",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.4499999, 0.3833333",
                            "IataCode": "LBV",
                            "Airports": [
                                {
                                    "Id": "LBV",
                                    "Name": "Libreville",
                                    "CityId": "LBVA",
                                    "CountryId": "GA",
                                    "Location": "9.415278, 0.459167"
                                }
                            ]
                        },
                        {
                            "Id": "POGA",
                            "Name": "Port Gentil",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "8.7833333, -0.7166667",
                            "IataCode": "POG",
                            "Airports": [
                                {
                                    "Id": "POG",
                                    "Name": "Port Gentil",
                                    "CityId": "POGA",
                                    "CountryId": "GA",
                                    "Location": "8.755833, -0.7125"
                                }
                            ]
                        },
                        {
                            "Id": "OWEA",
                            "Name": "Owendo",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.5, 0.2833333",
                            "IataCode": "OWE",
                            "Airports": [
                                {
                                    "Id": "OWE",
                                    "Name": "Owendo",
                                    "CityId": "OWEA",
                                    "CountryId": "GA",
                                    "Location": "9.483333, 0.35"
                                }
                            ]
                        },
                        {
                            "Id": "MBCA",
                            "Name": "Mbigou",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.933333, -1.8833333",
                            "IataCode": "MBC",
                            "Airports": [
                                {
                                    "Id": "MBC",
                                    "Name": "Mbigou",
                                    "CityId": "MBCA",
                                    "CountryId": "GA",
                                    "Location": "12, -2"
                                }
                            ]
                        },
                        {
                            "Id": "MDVA",
                            "Name": "Medouneu",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.783333, 0.95",
                            "IataCode": "MDV",
                            "Airports": [
                                {
                                    "Id": "MDV",
                                    "Name": "Medouneu",
                                    "CityId": "MDVA",
                                    "CountryId": "GA",
                                    "Location": "10.783333, 0.95"
                                }
                            ]
                        },
                        {
                            "Id": "MFFA",
                            "Name": "Moanda",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.2, -1.5666667",
                            "IataCode": "MFF",
                            "Airports": [
                                {
                                    "Id": "MFF",
                                    "Name": "Moanda",
                                    "CityId": "MFFA",
                                    "CountryId": "GA",
                                    "Location": "13.270833, -1.536944"
                                }
                            ]
                        },
                        {
                            "Id": "MGXA",
                            "Name": "Moabi",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.983333, -2.4",
                            "IataCode": "MGX",
                            "Airports": [
                                {
                                    "Id": "MGX",
                                    "Name": "Moabi",
                                    "CityId": "MGXA",
                                    "CountryId": "GA",
                                    "Location": "11, -2.25"
                                }
                            ]
                        },
                        {
                            "Id": "MJLA",
                            "Name": "Mouila",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.016667, -1.8666667",
                            "IataCode": "MJL",
                            "Airports": [
                                {
                                    "Id": "MJL",
                                    "Name": "Mouila",
                                    "CityId": "MJLA",
                                    "CountryId": "GA",
                                    "Location": "10.924167, -1.817778"
                                }
                            ]
                        },
                        {
                            "Id": "MKBA",
                            "Name": "Mekambo",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.933333, 1.0166667",
                            "IataCode": "MKB",
                            "Airports": [
                                {
                                    "Id": "MKB",
                                    "Name": "Mekambo",
                                    "CityId": "MKBA",
                                    "CountryId": "GA",
                                    "Location": "13.833333, 1.05"
                                }
                            ]
                        },
                        {
                            "Id": "MKUA",
                            "Name": "Makokou",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "12.866667, 0.5666667",
                            "IataCode": "MKU",
                            "Airports": [
                                {
                                    "Id": "MKU",
                                    "Name": "Makokou",
                                    "CityId": "MKUA",
                                    "CountryId": "GA",
                                    "Location": "12.861111, 0.559167"
                                }
                            ]
                        },
                        {
                            "Id": "MVGA",
                            "Name": "Mevang",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.066667, 0.0666667",
                            "IataCode": "MVG",
                            "Airports": [
                                {
                                    "Id": "MVG",
                                    "Name": "Mevang",
                                    "CityId": "MVGA",
                                    "CountryId": "GA",
                                    "Location": "11.083333, 0.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MYBA",
                            "Name": "Mayoumba",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.65, -3.4166667",
                            "IataCode": "MYB",
                            "Airports": [
                                {
                                    "Id": "MYB",
                                    "Name": "Mayoumba",
                                    "CityId": "MYBA",
                                    "CountryId": "GA",
                                    "Location": "10.683333, -3.45"
                                }
                            ]
                        },
                        {
                            "Id": "MZCA",
                            "Name": "Mitzic",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.566667, 0.7833333",
                            "IataCode": "MZC",
                            "Airports": [
                                {
                                    "Id": "MZC",
                                    "Name": "Mitzic",
                                    "CityId": "MZCA",
                                    "CountryId": "GA",
                                    "Location": "11.566667, 0.783333"
                                }
                            ]
                        },
                        {
                            "Id": "NKAA",
                            "Name": "Nkan",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.6666667, 0.7166667",
                            "IataCode": "NKA",
                            "Airports": [
                                {
                                    "Id": "NKA",
                                    "Name": "Nkan",
                                    "CityId": "NKAA",
                                    "CountryId": "GA",
                                    "Location": "9.983333, 0.7"
                                }
                            ]
                        },
                        {
                            "Id": "OKNA",
                            "Name": "Okondja",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.783333, -0.6833333",
                            "IataCode": "OKN",
                            "Airports": [
                                {
                                    "Id": "OKN",
                                    "Name": "Okondja",
                                    "CityId": "OKNA",
                                    "CountryId": "GA",
                                    "Location": "14.008333, -1"
                                }
                            ]
                        },
                        {
                            "Id": "OMBA",
                            "Name": "Omboue",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.25, -1.5666667",
                            "IataCode": "OMB",
                            "Airports": [
                                {
                                    "Id": "OMB",
                                    "Name": "Omboue",
                                    "CityId": "OMBA",
                                    "CountryId": "GA",
                                    "Location": "9.266667, -1.6"
                                }
                            ]
                        },
                        {
                            "Id": "OYEA",
                            "Name": "Oyem",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.583333, 1.6166667",
                            "IataCode": "OYE",
                            "Airports": [
                                {
                                    "Id": "OYE",
                                    "Name": "Oyem",
                                    "CityId": "OYEA",
                                    "CountryId": "GA",
                                    "Location": "11.583333, 1.566667"
                                }
                            ]
                        },
                        {
                            "Id": "TCHA",
                            "Name": "Tchibanga",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.033333, -2.85",
                            "IataCode": "TCH",
                            "Airports": [
                                {
                                    "Id": "TCH",
                                    "Name": "Tchibanga",
                                    "CityId": "TCHA",
                                    "CountryId": "GA",
                                    "Location": "11, -2.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MGOA",
                            "Name": "Manega",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.116667, -2.0833333",
                            "IataCode": "MGO",
                            "Airports": [
                                {
                                    "Id": "MGO",
                                    "Name": "Manega",
                                    "CityId": "MGOA",
                                    "CountryId": "GA",
                                    "Location": "10.216667, -1.733333"
                                }
                            ]
                        },
                        {
                            "Id": "MVXA",
                            "Name": "Minvoul",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "12.133333, 2.15",
                            "IataCode": "MVX",
                            "Airports": [
                                {
                                    "Id": "MVX",
                                    "Name": "Minvoul",
                                    "CityId": "MVXA",
                                    "CountryId": "GA",
                                    "Location": "12.133333, 2.15"
                                }
                            ]
                        },
                        {
                            "Id": "KDJA",
                            "Name": "N'Djole",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.75, -0.1833333",
                            "IataCode": "KDJ",
                            "Airports": [
                                {
                                    "Id": "KDJ",
                                    "Name": "N'Djole",
                                    "CityId": "KDJA",
                                    "CountryId": "GA",
                                    "Location": "10.766667, -0.15"
                                }
                            ]
                        },
                        {
                            "Id": "GKOA",
                            "Name": "Kongoboumba",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.466667, -0.0833333",
                            "IataCode": "GKO",
                            "Airports": [
                                {
                                    "Id": "GKO",
                                    "Name": "Kongoboumba",
                                    "CityId": "GKOA",
                                    "CountryId": "GA",
                                    "Location": "11.466667, -0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MVBA",
                            "Name": "Franceville",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.583333, -1.6333333",
                            "IataCode": "MVB",
                            "Airports": [
                                {
                                    "Id": "MVB",
                                    "Name": "Franceville / Mvengue",
                                    "CityId": "MVBA",
                                    "CountryId": "GA",
                                    "Location": "13.433333, -1.65"
                                }
                            ]
                        },
                        {
                            "Id": "BGPA",
                            "Name": "Bongo",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "13.433333, -1.65",
                            "IataCode": "BGP",
                            "Airports": [
                                {
                                    "Id": "BGP",
                                    "Name": "Bongo",
                                    "CityId": "BGPA",
                                    "CountryId": "GA",
                                    "Location": "13.433333, -1.65"
                                }
                            ]
                        },
                        {
                            "Id": "WNEA",
                            "Name": "Yéno",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.3333333, -1.35",
                            "IataCode": "WNE",
                            "Airports": [
                                {
                                    "Id": "WNE",
                                    "Name": "Wora Na Ye",
                                    "CityId": "WNEA",
                                    "CountryId": "GA",
                                    "Location": "9.333334, -1.35"
                                }
                            ]
                        },
                        {
                            "Id": "OUUA",
                            "Name": "Nyanga",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.283333, -2.9833333",
                            "IataCode": "OUU",
                            "Airports": [
                                {
                                    "Id": "OUU",
                                    "Name": "Ouanga",
                                    "CityId": "OUUA",
                                    "CountryId": "GA",
                                    "Location": "10.3, -2.983333"
                                }
                            ]
                        },
                        {
                            "Id": "WGYA",
                            "Name": "Ngoulemve",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "10.45, 0.5666667",
                            "IataCode": "WGY",
                            "Airports": [
                                {
                                    "Id": "WGY",
                                    "Name": "Wagny",
                                    "CityId": "WGYA",
                                    "CountryId": "GA",
                                    "Location": "10.433333, 0.516667"
                                }
                            ]
                        },
                        {
                            "Id": "GIMA",
                            "Name": "Ayem",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "11.366667, -0.0833333",
                            "IataCode": "GIM",
                            "Airports": [
                                {
                                    "Id": "GIM",
                                    "Name": "Miele Mimbale",
                                    "CityId": "GIMA",
                                    "CountryId": "GA",
                                    "Location": "11.4, -0.016667"
                                }
                            ]
                        },
                        {
                            "Id": "AWEA",
                            "Name": "Abelago",
                            "SingleAirportCity": true,
                            "CountryId": "GA",
                            "Location": "9.4666667, -0.9166667",
                            "IataCode": "AWE",
                            "Airports": [
                                {
                                    "Id": "AWE",
                                    "Name": "Alowe",
                                    "CityId": "AWEA",
                                    "CountryId": "GA",
                                    "Location": "9.45, -0.75"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ET",
                    "Name": "Ethiopia",
                    "CurrencyId": "ETB",
                    "Cities": [
                        {
                            "Id": "ADDA",
                            "Name": "Addis Ababa",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.7, 9.0333348",
                            "IataCode": "ADD",
                            "Airports": [
                                {
                                    "Id": "ADD",
                                    "Name": "Addis Ababa",
                                    "CityId": "ADDA",
                                    "CountryId": "ET",
                                    "Location": "38.798056, 8.977778"
                                }
                            ]
                        },
                        {
                            "Id": "AMHA",
                            "Name": "Arba Mintch",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.55, 6.0333333",
                            "IataCode": "AMH",
                            "Airports": [
                                {
                                    "Id": "AMH",
                                    "Name": "Arba Mintch",
                                    "CityId": "AMHA",
                                    "CountryId": "ET",
                                    "Location": "37.576944, 6.036111"
                                }
                            ]
                        },
                        {
                            "Id": "BJRA",
                            "Name": "Bahar Dar",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.383333, 11.6",
                            "IataCode": "BJR",
                            "Airports": [
                                {
                                    "Id": "BJR",
                                    "Name": "Bahar Dar",
                                    "CityId": "BJRA",
                                    "CountryId": "ET",
                                    "Location": "37.384722, 11.590278"
                                }
                            ]
                        },
                        {
                            "Id": "GLCA",
                            "Name": "Geladi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "46.411111, 6.975556",
                            "IataCode": "GLC",
                            "Airports": [
                                {
                                    "Id": "GLC",
                                    "Name": "Geladi",
                                    "CityId": "GLCA",
                                    "CountryId": "ET",
                                    "Location": "46.41917, 6.98278"
                                }
                            ]
                        },
                        {
                            "Id": "HILA",
                            "Name": "Shillavo",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "44.763611, 6.091667",
                            "IataCode": "HIL",
                            "Airports": [
                                {
                                    "Id": "HIL",
                                    "Name": "Shillavo",
                                    "CityId": "HILA",
                                    "CountryId": "ET",
                                    "Location": "44.766667, 6.083333"
                                }
                            ]
                        },
                        {
                            "Id": "BCYA",
                            "Name": "Bulchi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.616667, 6.166667",
                            "IataCode": "BCY",
                            "Airports": [
                                {
                                    "Id": "BCY",
                                    "Name": "Bulchi",
                                    "CityId": "BCYA",
                                    "CountryId": "ET",
                                    "Location": "36.666667, 6.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BEIA",
                            "Name": "Beica",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "34.483333, 9.333333",
                            "IataCode": "BEI",
                            "Airports": [
                                {
                                    "Id": "BEI",
                                    "Name": "Beica",
                                    "CityId": "BEIA",
                                    "CountryId": "ET",
                                    "Location": "34.533333, 9.391667"
                                }
                            ]
                        },
                        {
                            "Id": "DBMA",
                            "Name": "Debra Marcos",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.733333, 10.35",
                            "IataCode": "DBM",
                            "Airports": [
                                {
                                    "Id": "DBM",
                                    "Name": "Debra Marcos",
                                    "CityId": "DBMA",
                                    "CountryId": "ET",
                                    "Location": "37.743056, 10.319444"
                                }
                            ]
                        },
                        {
                            "Id": "DBTA",
                            "Name": "Debra Tabor",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.016667, 11.85",
                            "IataCode": "DBT",
                            "Airports": [
                                {
                                    "Id": "DBT",
                                    "Name": "Debra Tabor",
                                    "CityId": "DBTA",
                                    "CountryId": "ET",
                                    "Location": "38.025278, 11.968056"
                                }
                            ]
                        },
                        {
                            "Id": "DEMA",
                            "Name": "Dembidollo",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "34.8, 8.5333333",
                            "IataCode": "DEM",
                            "Airports": [
                                {
                                    "Id": "DEM",
                                    "Name": "Dembidollo",
                                    "CityId": "DEMA",
                                    "CountryId": "ET",
                                    "Location": "34.883333, 8.571111"
                                }
                            ]
                        },
                        {
                            "Id": "DGCA",
                            "Name": "Degahbur",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "43.557222, 8.220556",
                            "IataCode": "DGC",
                            "Airports": [
                                {
                                    "Id": "DGC",
                                    "Name": "Degahbur",
                                    "CityId": "DGCA",
                                    "CountryId": "ET",
                                    "Location": "43.583333, 8.233333"
                                }
                            ]
                        },
                        {
                            "Id": "DIRA",
                            "Name": "Dire Dawa",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "41.866111, 9.5930556",
                            "IataCode": "DIR",
                            "Airports": [
                                {
                                    "Id": "DIR",
                                    "Name": "Dire Dawa",
                                    "CityId": "DIRA",
                                    "CountryId": "ET",
                                    "Location": "41.850833, 9.622222"
                                }
                            ]
                        },
                        {
                            "Id": "DSEA",
                            "Name": "Dessie",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.633333, 11.133333",
                            "IataCode": "DSE",
                            "Airports": [
                                {
                                    "Id": "DSE",
                                    "Name": "Dessie",
                                    "CityId": "DSEA",
                                    "CountryId": "ET",
                                    "Location": "39.715278, 11.075"
                                }
                            ]
                        },
                        {
                            "Id": "FNHA",
                            "Name": "Fincha",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.45, 9.9",
                            "IataCode": "FNH",
                            "Airports": [
                                {
                                    "Id": "FNH",
                                    "Name": "Fincha",
                                    "CityId": "FNHA",
                                    "CountryId": "ET",
                                    "Location": "37.433333, 9.583333"
                                }
                            ]
                        },
                        {
                            "Id": "GDEA",
                            "Name": "Gode / Iddidole",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "43.597778, 5.887222",
                            "IataCode": "GDE",
                            "Airports": [
                                {
                                    "Id": "GDE",
                                    "Name": "Gode / Iddidole",
                                    "CityId": "GDEA",
                                    "CountryId": "ET",
                                    "Location": "43.628333, 5.902222"
                                }
                            ]
                        },
                        {
                            "Id": "GDQA",
                            "Name": "Gondar",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.466667, 12.6",
                            "IataCode": "GDQ",
                            "Airports": [
                                {
                                    "Id": "GDQ",
                                    "Name": "Gondar",
                                    "CityId": "GDQA",
                                    "CountryId": "ET",
                                    "Location": "37.445556, 12.514444"
                                }
                            ]
                        },
                        {
                            "Id": "GHDA",
                            "Name": "Ghimbi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.833333, 9.1666667",
                            "IataCode": "GHD",
                            "Airports": [
                                {
                                    "Id": "GHD",
                                    "Name": "Ghimbi",
                                    "CityId": "GHDA",
                                    "CountryId": "ET",
                                    "Location": "35.816667, 9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GMBA",
                            "Name": "Gambela",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "34.583333, 8.25",
                            "IataCode": "GMB",
                            "Airports": [
                                {
                                    "Id": "GMB",
                                    "Name": "Gambela",
                                    "CityId": "GMBA",
                                    "CountryId": "ET",
                                    "Location": "34.583333, 8.283333"
                                }
                            ]
                        },
                        {
                            "Id": "GNNA",
                            "Name": "Ghinnir",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "40.7, 7.1333333",
                            "IataCode": "GNN",
                            "Airports": [
                                {
                                    "Id": "GNN",
                                    "Name": "Ghinnir",
                                    "CityId": "GNNA",
                                    "CountryId": "ET",
                                    "Location": "40.716667, 7.15"
                                }
                            ]
                        },
                        {
                            "Id": "GOBA",
                            "Name": "Goba",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.983333, 7.0166667",
                            "IataCode": "GOB",
                            "Airports": [
                                {
                                    "Id": "GOB",
                                    "Name": "Goba",
                                    "CityId": "GOBA",
                                    "CountryId": "ET",
                                    "Location": "39.980556, 7.013889"
                                }
                            ]
                        },
                        {
                            "Id": "GORA",
                            "Name": "Gore",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.533333, 8.15",
                            "IataCode": "GOR",
                            "Airports": [
                                {
                                    "Id": "GOR",
                                    "Name": "Gore",
                                    "CityId": "GORA",
                                    "CountryId": "ET",
                                    "Location": "35.538056, 8.155556"
                                }
                            ]
                        },
                        {
                            "Id": "JIJA",
                            "Name": "Jijiga",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "42.8, 9.35",
                            "IataCode": "JIJ",
                            "Airports": [
                                {
                                    "Id": "JIJ",
                                    "Name": "Jijiga",
                                    "CityId": "JIJA",
                                    "CountryId": "ET",
                                    "Location": "42.766667, 9.366667"
                                }
                            ]
                        },
                        {
                            "Id": "JIMA",
                            "Name": "Jimma",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.833334, 7.6666667",
                            "IataCode": "JIM",
                            "Airports": [
                                {
                                    "Id": "JIM",
                                    "Name": "Jimma",
                                    "CityId": "JIMA",
                                    "CountryId": "ET",
                                    "Location": "36.821111, 7.654444"
                                }
                            ]
                        },
                        {
                            "Id": "LLIA",
                            "Name": "Lalibela",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.033333, 12.033333",
                            "IataCode": "LLI",
                            "Airports": [
                                {
                                    "Id": "LLI",
                                    "Name": "Lalibela",
                                    "CityId": "LLIA",
                                    "CountryId": "ET",
                                    "Location": "39.066667, 12.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MQXA",
                            "Name": "Makale",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.475278, 13.496667",
                            "IataCode": "MQX",
                            "Airports": [
                                {
                                    "Id": "MQX",
                                    "Name": "Makale",
                                    "CityId": "MQXA",
                                    "CountryId": "ET",
                                    "Location": "39.526389, 13.468333"
                                }
                            ]
                        },
                        {
                            "Id": "NDMA",
                            "Name": "Mendi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.1, 9.8",
                            "IataCode": "NDM",
                            "Airports": [
                                {
                                    "Id": "NDM",
                                    "Name": "Mendi",
                                    "CityId": "NDMA",
                                    "CountryId": "ET",
                                    "Location": "35.083333, 9.816667"
                                }
                            ]
                        },
                        {
                            "Id": "ALKA",
                            "Name": "Asela",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.133333, 7.95",
                            "IataCode": "ALK",
                            "Airports": [
                                {
                                    "Id": "ALK",
                                    "Name": "Asela",
                                    "CityId": "ALKA",
                                    "CountryId": "ET",
                                    "Location": "39.116667, 7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "ASOA",
                            "Name": "Asosa",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "34.533333, 10.066667",
                            "IataCode": "ASO",
                            "Airports": [
                                {
                                    "Id": "ASO",
                                    "Name": "Asosa",
                                    "CityId": "ASOA",
                                    "CountryId": "ET",
                                    "Location": "34.536667, 10.047222"
                                }
                            ]
                        },
                        {
                            "Id": "AWAA",
                            "Name": "Awassa",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.466667, 7.05",
                            "IataCode": "AWA",
                            "Airports": [
                                {
                                    "Id": "AWA",
                                    "Name": "Awassa",
                                    "CityId": "AWAA",
                                    "CountryId": "ET",
                                    "Location": "38.4875, 7.061111"
                                }
                            ]
                        },
                        {
                            "Id": "AWHA",
                            "Name": "Awareh",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "44.156667, 8.256111",
                            "IataCode": "AWH",
                            "Airports": [
                                {
                                    "Id": "AWH",
                                    "Name": "Awareh",
                                    "CityId": "AWHA",
                                    "CountryId": "ET",
                                    "Location": "44.183333, 8.266667"
                                }
                            ]
                        },
                        {
                            "Id": "AXUA",
                            "Name": "Axum",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.715833, 14.129722",
                            "IataCode": "AXU",
                            "Airports": [
                                {
                                    "Id": "AXU",
                                    "Name": "Axum",
                                    "CityId": "AXUA",
                                    "CountryId": "ET",
                                    "Location": "38.716667, 14.120833"
                                }
                            ]
                        },
                        {
                            "Id": "BCOA",
                            "Name": "Jinka",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.65, 5.65",
                            "IataCode": "BCO",
                            "Airports": [
                                {
                                    "Id": "BCO",
                                    "Name": "Jinka",
                                    "CityId": "BCOA",
                                    "CountryId": "ET",
                                    "Location": "36.55, 5.783333"
                                }
                            ]
                        },
                        {
                            "Id": "MTFA",
                            "Name": "Mizan Teferi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.583333, 6.9833333",
                            "IataCode": "MTF",
                            "Airports": [
                                {
                                    "Id": "MTF",
                                    "Name": "Mizan Teferi",
                                    "CityId": "MTFA",
                                    "CountryId": "ET",
                                    "Location": "35.533333, 6.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MUJA",
                            "Name": "Mui",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.55, 6.05",
                            "IataCode": "MUJ",
                            "Airports": [
                                {
                                    "Id": "MUJ",
                                    "Name": "Mui",
                                    "CityId": "MUJA",
                                    "CountryId": "ET",
                                    "Location": "35, 6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MYSA",
                            "Name": "Moyale",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.083333, 3.569167",
                            "IataCode": "MYS",
                            "Airports": [
                                {
                                    "Id": "MYS",
                                    "Name": "Moyale",
                                    "CityId": "MYSA",
                                    "CountryId": "ET",
                                    "Location": "39.1, 3.466667"
                                }
                            ]
                        },
                        {
                            "Id": "MZXA",
                            "Name": "Mena",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.85, 6.416667",
                            "IataCode": "MZX",
                            "Airports": [
                                {
                                    "Id": "MZX",
                                    "Name": "Mena",
                                    "CityId": "MZXA",
                                    "CountryId": "ET",
                                    "Location": "39.716667, 6.35"
                                }
                            ]
                        },
                        {
                            "Id": "SKRA",
                            "Name": "Shakiso",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.916667, 5.75",
                            "IataCode": "SKR",
                            "Airports": [
                                {
                                    "Id": "SKR",
                                    "Name": "Shakiso",
                                    "CityId": "SKRA",
                                    "CountryId": "ET",
                                    "Location": "38.966667, 5.883333"
                                }
                            ]
                        },
                        {
                            "Id": "SXUA",
                            "Name": "Soddu",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.75, 6.9",
                            "IataCode": "SXU",
                            "Airports": [
                                {
                                    "Id": "SXU",
                                    "Name": "Soddu",
                                    "CityId": "SXUA",
                                    "CountryId": "ET",
                                    "Location": "37.775, 6.836111"
                                }
                            ]
                        },
                        {
                            "Id": "TIEA",
                            "Name": "Tippi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.45, 7.2",
                            "IataCode": "TIE",
                            "Airports": [
                                {
                                    "Id": "TIE",
                                    "Name": "Tippi",
                                    "CityId": "TIEA",
                                    "CountryId": "ET",
                                    "Location": "35.415278, 7.201111"
                                }
                            ]
                        },
                        {
                            "Id": "WRAA",
                            "Name": "Warder",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "45.340833, 6.974444",
                            "IataCode": "WRA",
                            "Airports": [
                                {
                                    "Id": "WRA",
                                    "Name": "Warder",
                                    "CityId": "WRAA",
                                    "CountryId": "ET",
                                    "Location": "45.333333, 6.966667"
                                }
                            ]
                        },
                        {
                            "Id": "NEKA",
                            "Name": "Nekemt",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.55, 9.083333",
                            "IataCode": "NEK",
                            "Airports": [
                                {
                                    "Id": "NEK",
                                    "Name": "Nekemt",
                                    "CityId": "NEKA",
                                    "CountryId": "ET",
                                    "Location": "36.5, 9.066667"
                                }
                            ]
                        },
                        {
                            "Id": "EGLA",
                            "Name": "Neghelli",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.583333, 5.316667",
                            "IataCode": "EGL",
                            "Airports": [
                                {
                                    "Id": "EGL",
                                    "Name": "Neghelli",
                                    "CityId": "EGLA",
                                    "CountryId": "ET",
                                    "Location": "39.716667, 5.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ABKA",
                            "Name": "Kabri Dar",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "44.278056, 6.74",
                            "IataCode": "ABK",
                            "Airports": [
                                {
                                    "Id": "ABK",
                                    "Name": "Kabri Dar",
                                    "CityId": "ABKA",
                                    "CountryId": "ET",
                                    "Location": "44.266111, 6.733889"
                                }
                            ]
                        },
                        {
                            "Id": "SZEA",
                            "Name": "Semera",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "41.200983, 11.500993",
                            "IataCode": "SZE",
                            "Airports": [
                                {
                                    "Id": "SZE",
                                    "Name": "Semera",
                                    "CityId": "SZEA",
                                    "CountryId": "ET",
                                    "Location": "41.083333, 11.5"
                                }
                            ]
                        },
                        {
                            "Id": "HUEA",
                            "Name": "Humera",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.606014, 14.304275",
                            "IataCode": "HUE",
                            "Airports": [
                                {
                                    "Id": "HUE",
                                    "Name": "Humera",
                                    "CityId": "HUEA",
                                    "CountryId": "ET",
                                    "Location": "36.583333, 14.25"
                                }
                            ]
                        },
                        {
                            "Id": "XBLA",
                            "Name": "Bedelē",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.35, 8.45",
                            "IataCode": "XBL",
                            "Airports": [
                                {
                                    "Id": "XBL",
                                    "Name": "Buno Bedelle",
                                    "CityId": "XBLA",
                                    "CountryId": "ET",
                                    "Location": "36.333333, 8.45"
                                }
                            ]
                        },
                        {
                            "Id": "TUJA",
                            "Name": "Uesca Ueca",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.6, 5.816667",
                            "IataCode": "TUJ",
                            "Airports": [
                                {
                                    "Id": "TUJ",
                                    "Name": "Tum",
                                    "CityId": "TUJA",
                                    "CountryId": "ET",
                                    "Location": "35.566667, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WACA",
                            "Name": "Tarch’a Sodo",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.15, 7.15",
                            "IataCode": "WAC",
                            "Airports": [
                                {
                                    "Id": "WAC",
                                    "Name": "Waca",
                                    "CityId": "WACA",
                                    "CountryId": "ET",
                                    "Location": "37.166667, 7.166667"
                                }
                            ]
                        },
                        {
                            "Id": "OTAA",
                            "Name": "Mot’a",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "37.866667, 11.083333",
                            "IataCode": "OTA",
                            "Airports": [
                                {
                                    "Id": "OTA",
                                    "Name": "Mota",
                                    "CityId": "OTAA",
                                    "CountryId": "ET",
                                    "Location": "37.883333, 11.066667"
                                }
                            ]
                        },
                        {
                            "Id": "NEJA",
                            "Name": "Jarso",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "35.45, 9.516667",
                            "IataCode": "NEJ",
                            "Airports": [
                                {
                                    "Id": "NEJ",
                                    "Name": "Nejjo",
                                    "CityId": "NEJA",
                                    "CountryId": "ET",
                                    "Location": "35.466667, 9.5"
                                }
                            ]
                        },
                        {
                            "Id": "ETEA",
                            "Name": "Ghenga",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.2, 12.95",
                            "IataCode": "ETE",
                            "Airports": [
                                {
                                    "Id": "ETE",
                                    "Name": "Genda Wuha",
                                    "CityId": "ETEA",
                                    "CountryId": "ET",
                                    "Location": "36.166667, 12.933333"
                                }
                            ]
                        },
                        {
                            "Id": "MKSA",
                            "Name": "Forat",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "38.166667, 11.716667",
                            "IataCode": "MKS",
                            "Airports": [
                                {
                                    "Id": "MKS",
                                    "Name": "Mekane Selam",
                                    "CityId": "MKSA",
                                    "CountryId": "ET",
                                    "Location": "38.15, 11.683333"
                                }
                            ]
                        },
                        {
                            "Id": "MKDA",
                            "Name": "Chagne",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.5, 10.95",
                            "IataCode": "MKD",
                            "Airports": [
                                {
                                    "Id": "MKD",
                                    "Name": "Chagni",
                                    "CityId": "MKDA",
                                    "CountryId": "ET",
                                    "Location": "36.466667, 11.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PWIA",
                            "Name": "Bagusta",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "36.4, 11.35",
                            "IataCode": "PWI",
                            "Airports": [
                                {
                                    "Id": "PWI",
                                    "Name": "Pawi",
                                    "CityId": "PWIA",
                                    "CountryId": "ET",
                                    "Location": "36.416667, 11.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LFOA",
                            "Name": "Ārka Ādon",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "44.116667, 5.609444",
                            "IataCode": "LFO",
                            "Airports": [
                                {
                                    "Id": "LFO",
                                    "Name": "Kelafo / Callaf",
                                    "CityId": "LFOA",
                                    "CountryId": "ET",
                                    "Location": "44.333333, 5.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SHCA",
                            "Name": "Abba Kudi",
                            "SingleAirportCity": true,
                            "CountryId": "ET",
                            "Location": "39.093223, 13.020917",
                            "IataCode": "SHC",
                            "Airports": [
                                {
                                    "Id": "SHC",
                                    "Name": "Indaselassie",
                                    "CityId": "SHCA",
                                    "CountryId": "ET",
                                    "Location": "38.2725, 14.0781"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ER",
                    "Name": "Eritrea",
                    "CurrencyId": "ERN",
                    "Cities": [
                        {
                            "Id": "ASMA",
                            "Name": "Asmara",
                            "SingleAirportCity": true,
                            "CountryId": "ER",
                            "Location": "38.933333, 15.333333",
                            "IataCode": "ASM",
                            "Airports": [
                                {
                                    "Id": "ASM",
                                    "Name": "Asmara",
                                    "CityId": "ASMA",
                                    "CountryId": "ER",
                                    "Location": "38.910278, 15.291111"
                                }
                            ]
                        },
                        {
                            "Id": "MSWA",
                            "Name": "Massawa",
                            "SingleAirportCity": true,
                            "CountryId": "ER",
                            "Location": "39.45, 15.609722",
                            "IataCode": "MSW",
                            "Airports": [
                                {
                                    "Id": "MSW",
                                    "Name": "Massawa",
                                    "CityId": "MSWA",
                                    "CountryId": "ER",
                                    "Location": "39.441111, 15.603333"
                                }
                            ]
                        },
                        {
                            "Id": "ASAA",
                            "Name": "Assab",
                            "SingleAirportCity": true,
                            "CountryId": "ER",
                            "Location": "42.739444, 13.009167",
                            "IataCode": "ASA",
                            "Airports": [
                                {
                                    "Id": "ASA",
                                    "Name": "Assab",
                                    "CityId": "ASAA",
                                    "CountryId": "ER",
                                    "Location": "42.638333, 13.07"
                                }
                            ]
                        },
                        {
                            "Id": "TESA",
                            "Name": "Aligider",
                            "SingleAirportCity": true,
                            "CountryId": "ER",
                            "Location": "36.483333, 15.116667",
                            "IataCode": "TES",
                            "Airports": [
                                {
                                    "Id": "TES",
                                    "Name": "Tessenei",
                                    "CityId": "TESA",
                                    "CountryId": "ER",
                                    "Location": "36.683333, 15.116667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "EH",
                    "Name": "Western Sahara",
                    "CurrencyId": "MAD",
                    "Cities": []
                },
                {
                    "Id": "GM",
                    "Name": "Gambia",
                    "CurrencyId": "GMD",
                    "Cities": [
                        {
                            "Id": "BJLA",
                            "Name": "Banjul",
                            "SingleAirportCity": true,
                            "CountryId": "GM",
                            "Location": "-16.581287, 13.456408",
                            "IataCode": "BJL",
                            "Airports": [
                                {
                                    "Id": "BJL",
                                    "Name": "Banjul",
                                    "CityId": "BJLA",
                                    "CountryId": "GM",
                                    "Location": "-16.666667, 13.35"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GN",
                    "Name": "Guinea",
                    "CurrencyId": "GNF",
                    "Cities": [
                        {
                            "Id": "MCAA",
                            "Name": "Macenta",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-9.4694444, 8.5461111",
                            "IataCode": "MCA",
                            "Airports": [
                                {
                                    "Id": "MCA",
                                    "Name": "Macenta",
                                    "CityId": "MCAA",
                                    "CountryId": "GN",
                                    "Location": "-9.46667, 8.53333"
                                }
                            ]
                        },
                        {
                            "Id": "BKJA",
                            "Name": "Boke",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-14.3, 10.933333",
                            "IataCode": "BKJ",
                            "Airports": [
                                {
                                    "Id": "BKJ",
                                    "Name": "Boke",
                                    "CityId": "BKJA",
                                    "CountryId": "GN",
                                    "Location": "-14.283333, 10.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CKYA",
                            "Name": "Conakry",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-13.712223, 9.5091666",
                            "IataCode": "CKY",
                            "Airports": [
                                {
                                    "Id": "CKY",
                                    "Name": "Conakry",
                                    "CityId": "CKYA",
                                    "CountryId": "GN",
                                    "Location": "-13.610833, 9.576389"
                                }
                            ]
                        },
                        {
                            "Id": "FAAA",
                            "Name": "Faranah",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-10.733333, 10.033333",
                            "IataCode": "FAA",
                            "Airports": [
                                {
                                    "Id": "FAA",
                                    "Name": "Faranah",
                                    "CityId": "FAAA",
                                    "CountryId": "GN",
                                    "Location": "-10.75, 10.033333"
                                }
                            ]
                        },
                        {
                            "Id": "FIGB",
                            "Name": "Fria",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-13.533333, 10.45",
                            "IataCode": "FIG",
                            "Airports": [
                                {
                                    "Id": "FIG",
                                    "Name": "Fria",
                                    "CityId": "FIGB",
                                    "CountryId": "GN",
                                    "Location": "-13.566667, 10.35"
                                }
                            ]
                        },
                        {
                            "Id": "GIIA",
                            "Name": "Siguiri",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-9.1666667, 11.416667",
                            "IataCode": "GII",
                            "Airports": [
                                {
                                    "Id": "GII",
                                    "Name": "Siguiri",
                                    "CityId": "GIIA",
                                    "CountryId": "GN",
                                    "Location": "-9.166667, 11.516667"
                                }
                            ]
                        },
                        {
                            "Id": "KNNA",
                            "Name": "Kankan",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-9.3, 10.383333",
                            "IataCode": "KNN",
                            "Airports": [
                                {
                                    "Id": "KNN",
                                    "Name": "Kankan",
                                    "CityId": "KNNA",
                                    "CountryId": "GN",
                                    "Location": "-9.305556, 10.3975"
                                }
                            ]
                        },
                        {
                            "Id": "KSIA",
                            "Name": "Kissidougou",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-10.1, 9.1833333",
                            "IataCode": "KSI",
                            "Airports": [
                                {
                                    "Id": "KSI",
                                    "Name": "Kissidougou",
                                    "CityId": "KSIA",
                                    "CountryId": "GN",
                                    "Location": "-10.1, 9.183333"
                                }
                            ]
                        },
                        {
                            "Id": "LEKA",
                            "Name": "Labe",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-12.283333, 11.316667",
                            "IataCode": "LEK",
                            "Airports": [
                                {
                                    "Id": "LEK",
                                    "Name": "Labe",
                                    "CityId": "LEKA",
                                    "CountryId": "GN",
                                    "Location": "-12.297222, 11.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SBIA",
                            "Name": "Koundara",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-13.3, 12.483333",
                            "IataCode": "SBI",
                            "Airports": [
                                {
                                    "Id": "SBI",
                                    "Name": "Koundara",
                                    "CityId": "SBIA",
                                    "CountryId": "GN",
                                    "Location": "-13.3, 12.483333"
                                }
                            ]
                        },
                        {
                            "Id": "NZEA",
                            "Name": "Nzerekore",
                            "SingleAirportCity": true,
                            "CountryId": "GN",
                            "Location": "-8.8238889, 7.7472222",
                            "IataCode": "NZE",
                            "Airports": [
                                {
                                    "Id": "NZE",
                                    "Name": "Nzerekore",
                                    "CityId": "NZEA",
                                    "CountryId": "GN",
                                    "Location": "-8.702778, 7.808333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LS",
                    "Name": "Lesotho",
                    "CurrencyId": "LSL",
                    "Cities": [
                        {
                            "Id": "LRBA",
                            "Name": "Leribe",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.05, -28.883333",
                            "IataCode": "LRB",
                            "Airports": [
                                {
                                    "Id": "LRB",
                                    "Name": "Leribe",
                                    "CityId": "LRBA",
                                    "CountryId": "LS",
                                    "Location": "28, -28.966667"
                                }
                            ]
                        },
                        {
                            "Id": "LESA",
                            "Name": "Lesobeng",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.3589, -29.75778",
                            "IataCode": "LES",
                            "Airports": [
                                {
                                    "Id": "LES",
                                    "Name": "Lesobeng",
                                    "CityId": "LESA",
                                    "CountryId": "LS",
                                    "Location": "27.666667, -29.666667"
                                }
                            ]
                        },
                        {
                            "Id": "MSUA",
                            "Name": "Maseru",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.483333, -29.316667",
                            "IataCode": "MSU",
                            "Airports": [
                                {
                                    "Id": "MSU",
                                    "Name": "Maseru",
                                    "CityId": "MSUA",
                                    "CountryId": "LS",
                                    "Location": "27.558732, -29.455854"
                                }
                            ]
                        },
                        {
                            "Id": "MFCA",
                            "Name": "Mafeteng",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.25, -29.816667",
                            "IataCode": "MFC",
                            "Airports": [
                                {
                                    "Id": "MFC",
                                    "Name": "Mafeteng",
                                    "CityId": "MFCA",
                                    "CountryId": "LS",
                                    "Location": "27.3, -29.75"
                                }
                            ]
                        },
                        {
                            "Id": "MKHA",
                            "Name": "Mokhotlong",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "29.083333, -29.283333",
                            "IataCode": "MKH",
                            "Airports": [
                                {
                                    "Id": "MKH",
                                    "Name": "Mokhotlong",
                                    "CityId": "MKHA",
                                    "CountryId": "LS",
                                    "Location": "29.166667, -29.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MSGA",
                            "Name": "Matsaile",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.783333, -29.85",
                            "IataCode": "MSG",
                            "Airports": [
                                {
                                    "Id": "MSG",
                                    "Name": "Matsaile",
                                    "CityId": "MSGA",
                                    "CountryId": "LS",
                                    "Location": "28.75, -29.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SKQA",
                            "Name": "Sekakes",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.35, -30",
                            "IataCode": "SKQ",
                            "Airports": [
                                {
                                    "Id": "SKQ",
                                    "Name": "Sekakes",
                                    "CityId": "SKQA",
                                    "CountryId": "LS",
                                    "Location": "28.166667, -29.65"
                                }
                            ]
                        },
                        {
                            "Id": "UTGA",
                            "Name": "Quthing",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.716667, -30.4",
                            "IataCode": "UTG",
                            "Airports": [
                                {
                                    "Id": "UTG",
                                    "Name": "Quthing",
                                    "CityId": "UTGA",
                                    "CountryId": "LS",
                                    "Location": "27.6, -30.5"
                                }
                            ]
                        },
                        {
                            "Id": "TKOA",
                            "Name": "Tlokoeng",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.966667, -29.233333",
                            "IataCode": "TKO",
                            "Airports": [
                                {
                                    "Id": "TKO",
                                    "Name": "Tlokoeng",
                                    "CityId": "TKOA",
                                    "CountryId": "LS",
                                    "Location": "28.883333, -29.233333"
                                }
                            ]
                        },
                        {
                            "Id": "THBA",
                            "Name": "Thaba-Tseka",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.595366, -29.570226",
                            "IataCode": "THB",
                            "Airports": [
                                {
                                    "Id": "THB",
                                    "Name": "Thaba-Tseka",
                                    "CityId": "THBA",
                                    "CountryId": "LS",
                                    "Location": "29.5, -28.833333"
                                }
                            ]
                        },
                        {
                            "Id": "NKUA",
                            "Name": "Nkaus",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.533333, -30.233333",
                            "IataCode": "NKU",
                            "Airports": [
                                {
                                    "Id": "NKU",
                                    "Name": "Nkaus",
                                    "CityId": "NKUA",
                                    "CountryId": "LS",
                                    "Location": "28, -29.5"
                                }
                            ]
                        },
                        {
                            "Id": "SOKA",
                            "Name": "White Hill",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.483333, -30.05",
                            "IataCode": "SOK",
                            "Airports": [
                                {
                                    "Id": "SOK",
                                    "Name": "Semongkong",
                                    "CityId": "SOKA",
                                    "CountryId": "LS",
                                    "Location": "28.5, -29.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LEFA",
                            "Name": "Mohlanapeng",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.65, -29.6",
                            "IataCode": "LEF",
                            "Airports": [
                                {
                                    "Id": "LEF",
                                    "Name": "Lebakeng",
                                    "CityId": "LEFA",
                                    "CountryId": "LS",
                                    "Location": "28.583333, -29.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SHKA",
                            "Name": "Jaele",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.866667, -29.466667",
                            "IataCode": "SHK",
                            "Airports": [
                                {
                                    "Id": "SHK",
                                    "Name": "Sehonghong",
                                    "CityId": "SHKA",
                                    "CountryId": "LS",
                                    "Location": "27.833333, -29.475"
                                }
                            ]
                        },
                        {
                            "Id": "SHZA",
                            "Name": "ha Makhoroana",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "28.03563, -29.15881",
                            "IataCode": "SHZ",
                            "Airports": [
                                {
                                    "Id": "SHZ",
                                    "Name": "Seshutes",
                                    "CityId": "SHZA",
                                    "CountryId": "LS",
                                    "Location": "28, -29.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PELA",
                            "Name": "Telle Falls",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.833333, -30.633333",
                            "IataCode": "PEL",
                            "Airports": [
                                {
                                    "Id": "PEL",
                                    "Name": "Pelaneng",
                                    "CityId": "PELA",
                                    "CountryId": "LS",
                                    "Location": "27.916667, -30.55"
                                }
                            ]
                        },
                        {
                            "Id": "UNEA",
                            "Name": "Lincelles",
                            "SingleAirportCity": true,
                            "CountryId": "LS",
                            "Location": "27.116667, -30.033333",
                            "IataCode": "UNE",
                            "Airports": [
                                {
                                    "Id": "UNE",
                                    "Name": "Qachas Nek",
                                    "CityId": "UNEA",
                                    "CountryId": "LS",
                                    "Location": "28.6719, -30.1117"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "GH",
                    "Name": "Ghana",
                    "CurrencyId": "GHC",
                    "Cities": [
                        {
                            "Id": "ACCA",
                            "Name": "Accra",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-0.21666777, 5.55",
                            "IataCode": "ACC",
                            "Airports": [
                                {
                                    "Id": "ACC",
                                    "Name": "Accra",
                                    "CityId": "ACCA",
                                    "CountryId": "GH",
                                    "Location": "-0.170833, 5.595278"
                                }
                            ]
                        },
                        {
                            "Id": "KMSA",
                            "Name": "Kumasi",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-1.6166672, 6.6833333",
                            "IataCode": "KMS",
                            "Airports": [
                                {
                                    "Id": "KMS",
                                    "Name": "Kumasi",
                                    "CityId": "KMSA",
                                    "CountryId": "GH",
                                    "Location": "-1.591111, 6.7125"
                                }
                            ]
                        },
                        {
                            "Id": "NYIA",
                            "Name": "Sunyani",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-2.3333333, 7.3333333",
                            "IataCode": "NYI",
                            "Airports": [
                                {
                                    "Id": "NYI",
                                    "Name": "Sunyani",
                                    "CityId": "NYIA",
                                    "CountryId": "GH",
                                    "Location": "-2.333333, 7.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TKDA",
                            "Name": "Takoradi",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-1.7500002, 4.8833333",
                            "IataCode": "TKD",
                            "Airports": [
                                {
                                    "Id": "TKD",
                                    "Name": "Takoradi",
                                    "CityId": "TKDA",
                                    "CountryId": "GH",
                                    "Location": "-1.775, 4.891667"
                                }
                            ]
                        },
                        {
                            "Id": "WZAA",
                            "Name": "Wa",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-2.4833333, 10.05",
                            "IataCode": "WZA",
                            "Airports": [
                                {
                                    "Id": "WZA",
                                    "Name": "Wa",
                                    "CityId": "WZAA",
                                    "CountryId": "GH",
                                    "Location": "-2.507693, 10.082664"
                                }
                            ]
                        },
                        {
                            "Id": "TMLA",
                            "Name": "Tamale",
                            "SingleAirportCity": true,
                            "CountryId": "GH",
                            "Location": "-0.83333345, 9.4",
                            "IataCode": "TML",
                            "Airports": [
                                {
                                    "Id": "TML",
                                    "Name": "Tamale",
                                    "CityId": "TMLA",
                                    "CountryId": "GH",
                                    "Location": "-0.863333, 9.557222"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "DZ",
                    "Name": "Algeria",
                    "CurrencyId": "DZD",
                    "Cities": [
                        {
                            "Id": "AAEA",
                            "Name": "Annaba",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "7.7666669, 36.9",
                            "IataCode": "AAE",
                            "Airports": [
                                {
                                    "Id": "AAE",
                                    "Name": "Annaba",
                                    "CityId": "AAEA",
                                    "CountryId": "DZ",
                                    "Location": "7.81, 36.821667"
                                }
                            ]
                        },
                        {
                            "Id": "ALGA",
                            "Name": "Algiers",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "3.0505556, 36.763059",
                            "IataCode": "ALG",
                            "Airports": [
                                {
                                    "Id": "ALG",
                                    "Name": "Algiers",
                                    "CityId": "ALGA",
                                    "CountryId": "DZ",
                                    "Location": "3.217222, 36.693333"
                                }
                            ]
                        },
                        {
                            "Id": "LOOA",
                            "Name": "Laghouat",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "2.8833333, 33.8",
                            "IataCode": "LOO",
                            "Airports": [
                                {
                                    "Id": "LOO",
                                    "Name": "Laghouat",
                                    "CityId": "LOOA",
                                    "CountryId": "DZ",
                                    "Location": "2.983333, 33.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BJAA",
                            "Name": "Bejaia",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.083333, 36.75",
                            "IataCode": "BJA",
                            "Airports": [
                                {
                                    "Id": "BJA",
                                    "Name": "Bejaia",
                                    "CityId": "BJAA",
                                    "CountryId": "DZ",
                                    "Location": "5.083333, 36.75"
                                }
                            ]
                        },
                        {
                            "Id": "BLJA",
                            "Name": "Batna",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.1741457, 35.555971",
                            "IataCode": "BLJ",
                            "Airports": [
                                {
                                    "Id": "BLJ",
                                    "Name": "Batna",
                                    "CityId": "BLJA",
                                    "CountryId": "DZ",
                                    "Location": "6.30859, 35.75211"
                                }
                            ]
                        },
                        {
                            "Id": "BSKA",
                            "Name": "Biskra",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.7333332, 34.85",
                            "IataCode": "BSK",
                            "Airports": [
                                {
                                    "Id": "BSK",
                                    "Name": "Biskra",
                                    "CityId": "BSKA",
                                    "CountryId": "DZ",
                                    "Location": "5.738056, 34.793333"
                                }
                            ]
                        },
                        {
                            "Id": "CBHA",
                            "Name": "Bechar",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-2.2166668, 31.616668",
                            "IataCode": "CBH",
                            "Airports": [
                                {
                                    "Id": "CBH",
                                    "Name": "Bechar",
                                    "CityId": "CBHA",
                                    "CountryId": "DZ",
                                    "Location": "-2.259722, 31.647778"
                                }
                            ]
                        },
                        {
                            "Id": "CZLA",
                            "Name": "Constantine",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.6147227, 36.365",
                            "IataCode": "CZL",
                            "Airports": [
                                {
                                    "Id": "CZL",
                                    "Name": "Constantine",
                                    "CityId": "CZLA",
                                    "CountryId": "DZ",
                                    "Location": "6.63, 36.276944"
                                }
                            ]
                        },
                        {
                            "Id": "DJGA",
                            "Name": "Djanet",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "9.4822222, 24.5525",
                            "IataCode": "DJG",
                            "Airports": [
                                {
                                    "Id": "DJG",
                                    "Name": "Djanet",
                                    "CityId": "DJGA",
                                    "CountryId": "DZ",
                                    "Location": "9.488889, 24.465278"
                                }
                            ]
                        },
                        {
                            "Id": "ELGA",
                            "Name": "El Golea",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "2.8833333, 30.566667",
                            "IataCode": "ELG",
                            "Airports": [
                                {
                                    "Id": "ELG",
                                    "Name": "El Golea",
                                    "CityId": "ELGA",
                                    "CountryId": "DZ",
                                    "Location": "2.864722, 30.5675"
                                }
                            ]
                        },
                        {
                            "Id": "ELUA",
                            "Name": "El Oued",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.8833333, 33.333333",
                            "IataCode": "ELU",
                            "Airports": [
                                {
                                    "Id": "ELU",
                                    "Name": "El Oued",
                                    "CityId": "ELUA",
                                    "CountryId": "DZ",
                                    "Location": "6.783333, 33.516667"
                                }
                            ]
                        },
                        {
                            "Id": "GHAA",
                            "Name": "Ghardaia",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "3.6734676, 32.490941",
                            "IataCode": "GHA",
                            "Airports": [
                                {
                                    "Id": "GHA",
                                    "Name": "Ghardaia",
                                    "CityId": "GHAA",
                                    "CountryId": "DZ",
                                    "Location": "3.800556, 32.382222"
                                }
                            ]
                        },
                        {
                            "Id": "GJLA",
                            "Name": "Jijel",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.7667064, 36.820554",
                            "IataCode": "GJL",
                            "Airports": [
                                {
                                    "Id": "GJL",
                                    "Name": "Jijel",
                                    "CityId": "GJLA",
                                    "CountryId": "DZ",
                                    "Location": "5.883333, 36.8"
                                }
                            ]
                        },
                        {
                            "Id": "HMEA",
                            "Name": "Hassi Messaoud",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.0544445, 31.701944",
                            "IataCode": "HME",
                            "Airports": [
                                {
                                    "Id": "HME",
                                    "Name": "Hassi Messaoud",
                                    "CityId": "HMEA",
                                    "CountryId": "DZ",
                                    "Location": "6.142778, 31.673889"
                                }
                            ]
                        },
                        {
                            "Id": "INZA",
                            "Name": "In Salah",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "2.47913, 27.19678",
                            "IataCode": "INZ",
                            "Airports": [
                                {
                                    "Id": "INZ",
                                    "Name": "In Salah",
                                    "CityId": "INZA",
                                    "CountryId": "DZ",
                                    "Location": "2.516667, 27.25"
                                }
                            ]
                        },
                        {
                            "Id": "ORNA",
                            "Name": "Oran",
                            "SingleAirportCity": false,
                            "CountryId": "DZ",
                            "Location": "-0.64166719, 35.691113",
                            "IataCode": "ORN",
                            "Airports": [
                                {
                                    "Id": "ORN",
                                    "Name": "Ben Bella Airport",
                                    "CityId": "ORNA",
                                    "CountryId": "DZ",
                                    "Location": "-0.610556, 35.627778"
                                },
                                {
                                    "Id": "TAF",
                                    "Name": "Oran Tafaraoui",
                                    "CityId": "ORNA",
                                    "CountryId": "DZ",
                                    "Location": "-0.53222, 35.5425"
                                }
                            ]
                        },
                        {
                            "Id": "QSFA",
                            "Name": "Setif",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.4094443, 36.191389",
                            "IataCode": "QSF",
                            "Airports": [
                                {
                                    "Id": "QSF",
                                    "Name": "Setif",
                                    "CityId": "QSFA",
                                    "CountryId": "DZ",
                                    "Location": "5.3311, 36.181"
                                }
                            ]
                        },
                        {
                            "Id": "SKIB",
                            "Name": "Skikda",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.906667, 36.879167",
                            "IataCode": "SKI",
                            "Airports": [
                                {
                                    "Id": "SKI",
                                    "Name": "Skikda",
                                    "CityId": "SKIB",
                                    "CountryId": "DZ",
                                    "Location": "6.966667, 36.833333"
                                }
                            ]
                        },
                        {
                            "Id": "TMRA",
                            "Name": "Tamanrasset",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.5227776, 22.785",
                            "IataCode": "TMR",
                            "Airports": [
                                {
                                    "Id": "TMR",
                                    "Name": "Tamanrasset",
                                    "CityId": "TMRA",
                                    "CountryId": "DZ",
                                    "Location": "5.459167, 22.814722"
                                }
                            ]
                        },
                        {
                            "Id": "AZRA",
                            "Name": "Adrar",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-0.29388433, 27.874287",
                            "IataCode": "AZR",
                            "Airports": [
                                {
                                    "Id": "AZR",
                                    "Name": "Adrar",
                                    "CityId": "AZRA",
                                    "CountryId": "DZ",
                                    "Location": "-0.283333, 27.883333"
                                }
                            ]
                        },
                        {
                            "Id": "OGXA",
                            "Name": "Ouargla",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.3250217, 31.949322",
                            "IataCode": "OGX",
                            "Airports": [
                                {
                                    "Id": "OGX",
                                    "Name": "Ouargla",
                                    "CityId": "OGXA",
                                    "CountryId": "DZ",
                                    "Location": "5.4, 31.916667"
                                }
                            ]
                        },
                        {
                            "Id": "TGRA",
                            "Name": "Touggourt",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "6.0666669, 33.1",
                            "IataCode": "TGR",
                            "Airports": [
                                {
                                    "Id": "TGR",
                                    "Name": "Touggourt",
                                    "CityId": "TGRA",
                                    "CountryId": "DZ",
                                    "Location": "6.084722, 33.058333"
                                }
                            ]
                        },
                        {
                            "Id": "TIDA",
                            "Name": "Tiaret",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "1.3130556, 35.375833",
                            "IataCode": "TID",
                            "Airports": [
                                {
                                    "Id": "TID",
                                    "Name": "Tiaret",
                                    "CityId": "TIDA",
                                    "CountryId": "DZ",
                                    "Location": "1.46315, 35.34114"
                                }
                            ]
                        },
                        {
                            "Id": "TLMA",
                            "Name": "Tlemcen",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-1.3150002, 34.878334",
                            "IataCode": "TLM",
                            "Airports": [
                                {
                                    "Id": "TLM",
                                    "Name": "Tlemcen",
                                    "CityId": "TLMA",
                                    "CountryId": "DZ",
                                    "Location": "-1.45, 35.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MQVA",
                            "Name": "Mostaganem",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "0.090278125, 35.933334",
                            "IataCode": "MQV",
                            "Airports": [
                                {
                                    "Id": "MQV",
                                    "Name": "Mostaganem",
                                    "CityId": "MQVA",
                                    "CountryId": "DZ",
                                    "Location": "0.083333, 35.9"
                                }
                            ]
                        },
                        {
                            "Id": "TINA",
                            "Name": "Tindouf",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-8.2144444, 27.651944",
                            "IataCode": "TIN",
                            "Airports": [
                                {
                                    "Id": "TIN",
                                    "Name": "Tindouf",
                                    "CityId": "TINA",
                                    "CountryId": "DZ",
                                    "Location": "-8.166667, 27.716667"
                                }
                            ]
                        },
                        {
                            "Id": "TMXA",
                            "Name": "Timimoun",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "0.25000015, 29.25",
                            "IataCode": "TMX",
                            "Airports": [
                                {
                                    "Id": "TMX",
                                    "Name": "Timimoun",
                                    "CityId": "TMXA",
                                    "CountryId": "DZ",
                                    "Location": "0.283333, 29.233333"
                                }
                            ]
                        },
                        {
                            "Id": "VVZA",
                            "Name": "Illizi",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "8.4666667, 26.483333",
                            "IataCode": "VVZ",
                            "Airports": [
                                {
                                    "Id": "VVZ",
                                    "Name": "Illizi",
                                    "CityId": "VVZA",
                                    "CountryId": "DZ",
                                    "Location": "8.62265, 26.72354"
                                }
                            ]
                        },
                        {
                            "Id": "TEEA",
                            "Name": "Tbessa",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "8.1241667, 35.404167",
                            "IataCode": "TEE",
                            "Airports": [
                                {
                                    "Id": "TEE",
                                    "Name": "Tbessa",
                                    "CityId": "TEEA",
                                    "CountryId": "DZ",
                                    "Location": "8.15, 35.466667"
                                }
                            ]
                        },
                        {
                            "Id": "BFWA",
                            "Name": "Sidi Belabbes",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-0.64138928, 35.193889",
                            "IataCode": "BFW",
                            "Airports": [
                                {
                                    "Id": "BFW",
                                    "Name": "Sidi Belabbes",
                                    "CityId": "BFWA",
                                    "CountryId": "DZ",
                                    "Location": "-0.589444, 35.171667"
                                }
                            ]
                        },
                        {
                            "Id": "MZWA",
                            "Name": "Mechria",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "-0.28333336, 33.55",
                            "IataCode": "MZW",
                            "Airports": [
                                {
                                    "Id": "MZW",
                                    "Name": "Mechria",
                                    "CityId": "MZWA",
                                    "CountryId": "DZ",
                                    "Location": "-0.283056, 33.521944"
                                }
                            ]
                        },
                        {
                            "Id": "MUWA",
                            "Name": "Mascara",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "0.13972227, 35.394444",
                            "IataCode": "MUW",
                            "Airports": [
                                {
                                    "Id": "MUW",
                                    "Name": "Mascara",
                                    "CityId": "MUWA",
                                    "CountryId": "DZ",
                                    "Location": "0.14714, 35.20772"
                                }
                            ]
                        },
                        {
                            "Id": "IAMA",
                            "Name": "In Amenas",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "9.55, 28.05",
                            "IataCode": "IAM",
                            "Airports": [
                                {
                                    "Id": "IAM",
                                    "Name": "In Amenas",
                                    "CityId": "IAMA",
                                    "CountryId": "DZ",
                                    "Location": "9.638056, 28.051111"
                                }
                            ]
                        },
                        {
                            "Id": "EBHA",
                            "Name": "El Bayadh",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "1.0138889, 33.686111",
                            "IataCode": "EBH",
                            "Airports": [
                                {
                                    "Id": "EBH",
                                    "Name": "El Bayadh",
                                    "CityId": "EBHA",
                                    "CountryId": "DZ",
                                    "Location": "1.091389, 33.720833"
                                }
                            ]
                        },
                        {
                            "Id": "CFKA",
                            "Name": "Chlef",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "1.3347222, 36.164722",
                            "IataCode": "CFK",
                            "Airports": [
                                {
                                    "Id": "CFK",
                                    "Name": "Chlef",
                                    "CityId": "CFKA",
                                    "CountryId": "DZ",
                                    "Location": "1.331667, 36.212222"
                                }
                            ]
                        },
                        {
                            "Id": "BMWA",
                            "Name": "Bordj Badji Mokhtar",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "0.94671253, 21.331594",
                            "IataCode": "BMW",
                            "Airports": [
                                {
                                    "Id": "BMW",
                                    "Name": "Bordj Badji Mokhtar",
                                    "CityId": "BMWA",
                                    "CountryId": "DZ",
                                    "Location": "1.033333, 21.316667"
                                }
                            ]
                        },
                        {
                            "Id": "BUJA",
                            "Name": "Bou Saâda",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "4.1825, 35.214167",
                            "IataCode": "BUJ",
                            "Airports": [
                                {
                                    "Id": "BUJ",
                                    "Name": "Boussaada",
                                    "CityId": "BUJA",
                                    "CountryId": "DZ",
                                    "Location": "4.15, 35.166667"
                                }
                            ]
                        },
                        {
                            "Id": "HRMA",
                            "Name": "Bou Trekfine",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "2.95, 33.583333",
                            "IataCode": "HRM",
                            "Airports": [
                                {
                                    "Id": "HRM",
                                    "Name": "Hassi R'Mel",
                                    "CityId": "HRMA",
                                    "CountryId": "DZ",
                                    "Location": "3.2, 33.166667"
                                }
                            ]
                        },
                        {
                            "Id": "INFA",
                            "Name": "Assamakka",
                            "SingleAirportCity": true,
                            "CountryId": "DZ",
                            "Location": "5.8, 19.366667",
                            "IataCode": "INF",
                            "Airports": [
                                {
                                    "Id": "INF",
                                    "Name": "In Guezzam",
                                    "CityId": "INFA",
                                    "CountryId": "DZ",
                                    "Location": "5.866667, 19.616667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MU",
                    "Name": "Mauritius",
                    "CurrencyId": "MUR",
                    "Cities": [
                        {
                            "Id": "MRUA",
                            "Name": "Mauritius",
                            "SingleAirportCity": true,
                            "CountryId": "MU",
                            "Location": "57.652183, -20.165109",
                            "IataCode": "MRU",
                            "Airports": [
                                {
                                    "Id": "MRU",
                                    "Name": "Mauritius",
                                    "CityId": "MRUA",
                                    "CountryId": "MU",
                                    "Location": "57.676389, -20.4275"
                                }
                            ]
                        },
                        {
                            "Id": "RRGA",
                            "Name": "Rodrigues Is",
                            "SingleAirportCity": true,
                            "CountryId": "MU",
                            "Location": "63.417, -19.717",
                            "IataCode": "RRG",
                            "Airports": [
                                {
                                    "Id": "RRG",
                                    "Name": "Rodrigues Is",
                                    "CityId": "RRGA",
                                    "CountryId": "MU",
                                    "Location": "63.35, -19.75"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SH",
                    "Name": "St. Helena",
                    "CurrencyId": "SHP",
                    "Cities": [
                        {
                            "Id": "ASIA",
                            "Name": "Georgetown",
                            "SingleAirportCity": true,
                            "CountryId": "SH",
                            "Location": "-14.416667, -7.9333333",
                            "IataCode": "ASI",
                            "Airports": [
                                {
                                    "Id": "ASI",
                                    "Name": "Georgetown",
                                    "CityId": "ASIA",
                                    "CountryId": "SH",
                                    "Location": "-14.4, -7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "HLEA",
                            "Name": "St Helena",
                            "SingleAirportCity": true,
                            "CountryId": "SH",
                            "Location": "-5.716667, -15.933333",
                            "IataCode": "HLE",
                            "Airports": [
                                {
                                    "Id": "HLE",
                                    "Name": "St Helena",
                                    "CityId": "HLEA",
                                    "CountryId": "SH",
                                    "Location": "-5.645, -15.958056"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MW",
                    "Name": "Malawi",
                    "CurrencyId": "MWK",
                    "Cities": [
                        {
                            "Id": "KBQA",
                            "Name": "Kasungu",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "13.016667, -33.466667",
                            "IataCode": "KBQ",
                            "Airports": [
                                {
                                    "Id": "KBQ",
                                    "Name": "Kasungu",
                                    "CityId": "KBQA",
                                    "CountryId": "MW",
                                    "Location": "33.4686, -13.0146"
                                }
                            ]
                        },
                        {
                            "Id": "KGJA",
                            "Name": "Karonga",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "33.933333, -9.9333333",
                            "IataCode": "KGJ",
                            "Airports": [
                                {
                                    "Id": "KGJ",
                                    "Name": "Karonga",
                                    "CityId": "KGJA",
                                    "CountryId": "MW",
                                    "Location": "33.892222, -9.957778"
                                }
                            ]
                        },
                        {
                            "Id": "ZZUA",
                            "Name": "Mzuzu",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "33.916667, -11.45",
                            "IataCode": "ZZU",
                            "Airports": [
                                {
                                    "Id": "ZZU",
                                    "Name": "Mzuzu",
                                    "CityId": "ZZUA",
                                    "CountryId": "MW",
                                    "Location": "34.012778, -11.442778"
                                }
                            ]
                        },
                        {
                            "Id": "DWAA",
                            "Name": "Dwangwa",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "34.15, -12.566667",
                            "IataCode": "DWA",
                            "Airports": [
                                {
                                    "Id": "DWA",
                                    "Name": "Dwangwa",
                                    "CityId": "DWAA",
                                    "CountryId": "MW",
                                    "Location": "34.1319, -12.5183"
                                }
                            ]
                        },
                        {
                            "Id": "BLZA",
                            "Name": "Blantyre",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "35, -15.783333",
                            "IataCode": "BLZ",
                            "Airports": [
                                {
                                    "Id": "BLZ",
                                    "Name": "Blantyre",
                                    "CityId": "BLZA",
                                    "CountryId": "MW",
                                    "Location": "34.970833, -15.674722"
                                }
                            ]
                        },
                        {
                            "Id": "LLWA",
                            "Name": "Lilongwe",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "33.783333, -13.983333",
                            "IataCode": "LLW",
                            "Airports": [
                                {
                                    "Id": "LLW",
                                    "Name": "Lilongwe",
                                    "CityId": "LLWA",
                                    "CountryId": "MW",
                                    "Location": "33.783333, -13.783333"
                                }
                            ]
                        },
                        {
                            "Id": "LMBA",
                            "Name": "Salima",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "34.433333, -13.783333",
                            "IataCode": "LMB",
                            "Airports": [
                                {
                                    "Id": "LMB",
                                    "Name": "Salima",
                                    "CityId": "LMBA",
                                    "CountryId": "MW",
                                    "Location": "34.583333, -13.75"
                                }
                            ]
                        },
                        {
                            "Id": "MAIA",
                            "Name": "Mangochi",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "35.266667, -14.466667",
                            "IataCode": "MAI",
                            "Airports": [
                                {
                                    "Id": "MAI",
                                    "Name": "Mangochi",
                                    "CityId": "MAIA",
                                    "CountryId": "MW",
                                    "Location": "35.25, -14.5"
                                }
                            ]
                        },
                        {
                            "Id": "MYZA",
                            "Name": "Monkey Bay",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "34.916667, -14.083333",
                            "IataCode": "MYZ",
                            "Airports": [
                                {
                                    "Id": "MYZ",
                                    "Name": "Monkey Bay",
                                    "CityId": "MYZA",
                                    "CountryId": "MW",
                                    "Location": "34.91667, -14.08333"
                                }
                            ]
                        },
                        {
                            "Id": "CEHA",
                            "Name": "Chelinda",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "34.166667, -10.45",
                            "IataCode": "CEH",
                            "Airports": [
                                {
                                    "Id": "CEH",
                                    "Name": "Chelinda",
                                    "CityId": "CEHA",
                                    "CountryId": "MW",
                                    "Location": "33.8, -10.55"
                                }
                            ]
                        },
                        {
                            "Id": "LIXA",
                            "Name": "Likoma Island",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "34.7182, -12.09137",
                            "IataCode": "LIX",
                            "Airports": [
                                {
                                    "Id": "LIX",
                                    "Name": "Likoma Island",
                                    "CityId": "LIXA",
                                    "CountryId": "MW",
                                    "Location": "34.733333, -12.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CMKA",
                            "Name": "Mdala Chikowa",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "35.083333, -14.233333",
                            "IataCode": "CMK",
                            "Airports": [
                                {
                                    "Id": "CMK",
                                    "Name": "Club Makokola",
                                    "CityId": "CMKA",
                                    "CountryId": "MW",
                                    "Location": "35.1325, -14.3069"
                                }
                            ]
                        },
                        {
                            "Id": "VUUA",
                            "Name": "Likulu",
                            "SingleAirportCity": true,
                            "CountryId": "MW",
                            "Location": "35.05, -15.65",
                            "IataCode": "VUU",
                            "Airports": [
                                {
                                    "Id": "VUU",
                                    "Name": "Mvuu Camp",
                                    "CityId": "VUUA",
                                    "CountryId": "MW",
                                    "Location": "35, -15.683333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MZ",
                    "Name": "Mozambique",
                    "CurrencyId": "MZN",
                    "Cities": [
                        {
                            "Id": "IBOA",
                            "Name": "Ibo",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.591111, -12.341667",
                            "IataCode": "IBO",
                            "Airports": [
                                {
                                    "Id": "IBO",
                                    "Name": "Ibo",
                                    "CityId": "IBOA",
                                    "CountryId": "MZ",
                                    "Location": "40.583333, -12.333333"
                                }
                            ]
                        },
                        {
                            "Id": "APLA",
                            "Name": "Nampula",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "39.264722, -15.119722",
                            "IataCode": "APL",
                            "Airports": [
                                {
                                    "Id": "APL",
                                    "Name": "Nampula",
                                    "CityId": "APLA",
                                    "CountryId": "MZ",
                                    "Location": "39.287222, -15.101667"
                                }
                            ]
                        },
                        {
                            "Id": "ANOA",
                            "Name": "Angoche",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "39.908611, -16.2325",
                            "IataCode": "ANO",
                            "Airports": [
                                {
                                    "Id": "ANO",
                                    "Name": "Angoche",
                                    "CityId": "ANOA",
                                    "CountryId": "MZ",
                                    "Location": "39.936944, -16.177778"
                                }
                            ]
                        },
                        {
                            "Id": "MUDA",
                            "Name": "Mueda",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "39.550556, -11.662778",
                            "IataCode": "MUD",
                            "Airports": [
                                {
                                    "Id": "MUD",
                                    "Name": "Mueda",
                                    "CityId": "MUDA",
                                    "CountryId": "MZ",
                                    "Location": "39.516667, -11.666667"
                                }
                            ]
                        },
                        {
                            "Id": "VJBA",
                            "Name": "Xai Xai",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "33.644167, -25.051944",
                            "IataCode": "VJB",
                            "Airports": [
                                {
                                    "Id": "VJB",
                                    "Name": "Xai Xai",
                                    "CityId": "VJBA",
                                    "CountryId": "MZ",
                                    "Location": "33.616667, -25.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BEWA",
                            "Name": "Beira",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "34.838889, -19.843611",
                            "IataCode": "BEW",
                            "Airports": [
                                {
                                    "Id": "BEW",
                                    "Name": "Beira",
                                    "CityId": "BEWA",
                                    "CountryId": "MZ",
                                    "Location": "34.911944, -19.794444"
                                }
                            ]
                        },
                        {
                            "Id": "FXOA",
                            "Name": "Cuamba",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "36.537222, -14.803056",
                            "IataCode": "FXO",
                            "Airports": [
                                {
                                    "Id": "FXO",
                                    "Name": "Cuamba",
                                    "CityId": "FXOA",
                                    "CountryId": "MZ",
                                    "Location": "36.526944, -14.805278"
                                }
                            ]
                        },
                        {
                            "Id": "INEA",
                            "Name": "Chinde",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "36.458611, -18.581111",
                            "IataCode": "INE",
                            "Airports": [
                                {
                                    "Id": "INE",
                                    "Name": "Chinde",
                                    "CityId": "INEA",
                                    "CountryId": "MZ",
                                    "Location": "36.4, -18.616667"
                                }
                            ]
                        },
                        {
                            "Id": "INHA",
                            "Name": "Inhambane",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.383333, -23.865",
                            "IataCode": "INH",
                            "Airports": [
                                {
                                    "Id": "INH",
                                    "Name": "Inhambane",
                                    "CityId": "INHA",
                                    "CountryId": "MZ",
                                    "Location": "35.408333, -23.874444"
                                }
                            ]
                        },
                        {
                            "Id": "IMGA",
                            "Name": "Inhaminga",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "34.995833, -18.403611",
                            "IataCode": "IMG",
                            "Airports": [
                                {
                                    "Id": "IMG",
                                    "Name": "Inhaminga",
                                    "CityId": "IMGA",
                                    "CountryId": "MZ",
                                    "Location": "35, -18.433333"
                                }
                            ]
                        },
                        {
                            "Id": "MNCA",
                            "Name": "Nacala",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.672778, -14.542778",
                            "IataCode": "MNC",
                            "Airports": [
                                {
                                    "Id": "MNC",
                                    "Name": "Nacala",
                                    "CityId": "MNCA",
                                    "CountryId": "MZ",
                                    "Location": "40.708611, -14.48"
                                }
                            ]
                        },
                        {
                            "Id": "MPMA",
                            "Name": "Maputo",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "32.589167, -25.965279",
                            "IataCode": "MPM",
                            "Airports": [
                                {
                                    "Id": "MPM",
                                    "Name": "Maputo",
                                    "CityId": "MPMA",
                                    "CountryId": "MZ",
                                    "Location": "32.566667, -25.916667"
                                }
                            ]
                        },
                        {
                            "Id": "POLA",
                            "Name": "Pemba",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.507778, -12.960833",
                            "IataCode": "POL",
                            "Airports": [
                                {
                                    "Id": "POL",
                                    "Name": "Pemba",
                                    "CityId": "POLA",
                                    "CountryId": "MZ",
                                    "Location": "40.5225, -12.988333"
                                }
                            ]
                        },
                        {
                            "Id": "TETA",
                            "Name": "Tete",
                            "SingleAirportCity": false,
                            "CountryId": "MZ",
                            "Location": "33.586667, -16.156389",
                            "IataCode": "TET",
                            "Airports": [
                                {
                                    "Id": "TET",
                                    "Name": "Tete Matunda",
                                    "CityId": "TETA",
                                    "CountryId": "MZ",
                                    "Location": "33.638889, -16.103333"
                                },
                                {
                                    "Id": "TCV",
                                    "Name": "Tete",
                                    "CityId": "TETA",
                                    "CountryId": "MZ",
                                    "Location": "33.583333, -16.216667"
                                }
                            ]
                        },
                        {
                            "Id": "UELA",
                            "Name": "Quelimane",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "36.888333, -17.878611",
                            "IataCode": "UEL",
                            "Airports": [
                                {
                                    "Id": "UEL",
                                    "Name": "Quelimane",
                                    "CityId": "UELA",
                                    "CountryId": "MZ",
                                    "Location": "36.865556, -17.847222"
                                }
                            ]
                        },
                        {
                            "Id": "VNXA",
                            "Name": "Vilanculos",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.316667, -22",
                            "IataCode": "VNX",
                            "Airports": [
                                {
                                    "Id": "VNX",
                                    "Name": "Vilanculos",
                                    "CityId": "VNXA",
                                    "CountryId": "MZ",
                                    "Location": "35.283333, -22.016667"
                                }
                            ]
                        },
                        {
                            "Id": "VPYA",
                            "Name": "Chimoio",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "33.483333, -19.116389",
                            "IataCode": "VPY",
                            "Airports": [
                                {
                                    "Id": "VPY",
                                    "Name": "Chimoio",
                                    "CityId": "VPYA",
                                    "CountryId": "MZ",
                                    "Location": "33.42865, -19.150195"
                                }
                            ]
                        },
                        {
                            "Id": "VXCA",
                            "Name": "Lichinga",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.240555, -13.312778",
                            "IataCode": "VXC",
                            "Airports": [
                                {
                                    "Id": "VXC",
                                    "Name": "Lichinga",
                                    "CityId": "VXCA",
                                    "CountryId": "MZ",
                                    "Location": "35.25, -13.283333"
                                }
                            ]
                        },
                        {
                            "Id": "AMEA",
                            "Name": "Alto Molocue",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "37.688333, -15.635556",
                            "IataCode": "AME",
                            "Airports": [
                                {
                                    "Id": "AME",
                                    "Name": "Alto Molocue",
                                    "CityId": "AMEA",
                                    "CountryId": "MZ",
                                    "Location": "37.583333, -15.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LBMA",
                            "Name": "Luabo",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "36.105278, -18.392778",
                            "IataCode": "LBM",
                            "Airports": [
                                {
                                    "Id": "LBM",
                                    "Name": "Luabo",
                                    "CityId": "LBMA",
                                    "CountryId": "MZ",
                                    "Location": "36.166667, -18.5"
                                }
                            ]
                        },
                        {
                            "Id": "BJNA",
                            "Name": "Bajone",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "38.065833, -17.205278",
                            "IataCode": "BJN",
                            "Airports": [
                                {
                                    "Id": "BJN",
                                    "Name": "Bajone",
                                    "CityId": "BJNA",
                                    "CountryId": "MZ",
                                    "Location": "38.083333, -17.183333"
                                }
                            ]
                        },
                        {
                            "Id": "LMZA",
                            "Name": "Palma",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.481111, -10.782222",
                            "IataCode": "LMZ",
                            "Airports": [
                                {
                                    "Id": "LMZ",
                                    "Name": "Palma",
                                    "CityId": "LMZA",
                                    "CountryId": "MZ",
                                    "Location": "40.483333, -10.766667"
                                }
                            ]
                        },
                        {
                            "Id": "MMWA",
                            "Name": "Moma",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "39.222222, -16.759444",
                            "IataCode": "MMW",
                            "Airports": [
                                {
                                    "Id": "MMW",
                                    "Name": "Moma",
                                    "CityId": "MMWA",
                                    "CountryId": "MZ",
                                    "Location": "39.066667, -16.783333"
                                }
                            ]
                        },
                        {
                            "Id": "MTUA",
                            "Name": "Montepuez",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "38.999722, -13.125556",
                            "IataCode": "MTU",
                            "Airports": [
                                {
                                    "Id": "MTU",
                                    "Name": "Montepuez",
                                    "CityId": "MTUA",
                                    "CountryId": "MZ",
                                    "Location": "38.983333, -13.133333"
                                }
                            ]
                        },
                        {
                            "Id": "NNDA",
                            "Name": "Nangade",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "39.674444, -11.077222",
                            "IataCode": "NND",
                            "Airports": [
                                {
                                    "Id": "NND",
                                    "Name": "Nangade",
                                    "CityId": "NNDA",
                                    "CountryId": "MZ",
                                    "Location": "39.1, -11.083333"
                                }
                            ]
                        },
                        {
                            "Id": "PEBA",
                            "Name": "Pebane",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "38.135, -17.264167",
                            "IataCode": "PEB",
                            "Airports": [
                                {
                                    "Id": "PEB",
                                    "Name": "Pebane",
                                    "CityId": "PEBA",
                                    "CountryId": "MZ",
                                    "Location": "38.133333, -17.166667"
                                }
                            ]
                        },
                        {
                            "Id": "RRMA",
                            "Name": "Marromeu",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.945278, -18.290833",
                            "IataCode": "RRM",
                            "Airports": [
                                {
                                    "Id": "RRM",
                                    "Name": "Marromeu",
                                    "CityId": "RRMA",
                                    "CountryId": "MZ",
                                    "Location": "35.933333, -18.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TGSA",
                            "Name": "Chokwe",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "32.983333, -24.533333",
                            "IataCode": "TGS",
                            "Airports": [
                                {
                                    "Id": "TGS",
                                    "Name": "Chokwe",
                                    "CityId": "TGSA",
                                    "CountryId": "MZ",
                                    "Location": "32.416667, -24.616667"
                                }
                            ]
                        },
                        {
                            "Id": "VJQA",
                            "Name": "Gurue",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "36.977778, -15.466944",
                            "IataCode": "VJQ",
                            "Airports": [
                                {
                                    "Id": "VJQ",
                                    "Name": "Gurue",
                                    "CityId": "VJQA",
                                    "CountryId": "MZ",
                                    "Location": "36.966667, -15.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CMZA",
                            "Name": "Caia",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.344167, -17.820278",
                            "IataCode": "CMZ",
                            "Airports": [
                                {
                                    "Id": "CMZ",
                                    "Name": "Caia",
                                    "CityId": "CMZA",
                                    "CountryId": "MZ",
                                    "Location": "35.4, -17.85"
                                }
                            ]
                        },
                        {
                            "Id": "MZBA",
                            "Name": "Mocimboa Praia",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.352222, -11.345833",
                            "IataCode": "MZB",
                            "Airports": [
                                {
                                    "Id": "MZB",
                                    "Name": "Mocimboa Praia",
                                    "CityId": "MZBA",
                                    "CountryId": "MZ",
                                    "Location": "40.354167, -11.356111"
                                }
                            ]
                        },
                        {
                            "Id": "LFBA",
                            "Name": "Lumbo",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "40.666389, -15.010556",
                            "IataCode": "LFB",
                            "Airports": [
                                {
                                    "Id": "LFB",
                                    "Name": "Lumbo",
                                    "CityId": "LFBA",
                                    "CountryId": "MZ",
                                    "Location": "40.666667, -15.033333"
                                }
                            ]
                        },
                        {
                            "Id": "IHCA",
                            "Name": "Inhaca",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "32.916389, -26.003611",
                            "IataCode": "IHC",
                            "Airports": [
                                {
                                    "Id": "IHC",
                                    "Name": "Inhaca",
                                    "CityId": "IHCA",
                                    "CountryId": "MZ",
                                    "Location": "32.933333, -26"
                                }
                            ]
                        },
                        {
                            "Id": "NTCA",
                            "Name": "Santa Carolina",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.341667, -21.618333",
                            "IataCode": "NTC",
                            "Airports": [
                                {
                                    "Id": "NTC",
                                    "Name": "Santa Carolina",
                                    "CityId": "NTCA",
                                    "CountryId": "MZ",
                                    "Location": "35.336332, -21.613135"
                                }
                            ]
                        },
                        {
                            "Id": "BZBA",
                            "Name": "Bazaruto Island",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.5, -21.633333",
                            "IataCode": "BZB",
                            "Airports": [
                                {
                                    "Id": "BZB",
                                    "Name": "Bazaruto Island",
                                    "CityId": "BZBA",
                                    "CountryId": "MZ",
                                    "Location": "35.491667, -21.534722"
                                }
                            ]
                        },
                        {
                            "Id": "BCWA",
                            "Name": "Benguera Island",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.44, -21.866389",
                            "IataCode": "BCW",
                            "Airports": [
                                {
                                    "Id": "BCW",
                                    "Name": "Benguera Island",
                                    "CityId": "BCWA",
                                    "CountryId": "MZ",
                                    "Location": "35.425, -21.884722"
                                }
                            ]
                        },
                        {
                            "Id": "MFWA",
                            "Name": "Magaruque",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.430278, -21.966389",
                            "IataCode": "MFW",
                            "Airports": [
                                {
                                    "Id": "MFW",
                                    "Name": "Magaruque",
                                    "CityId": "MFWA",
                                    "CountryId": "MZ",
                                    "Location": "35.591667, -21.972222"
                                }
                            ]
                        },
                        {
                            "Id": "PDDA",
                            "Name": "Moolomo",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "32.869722, -26.786389",
                            "IataCode": "PDD",
                            "Airports": [
                                {
                                    "Id": "PDD",
                                    "Name": "Ponta de Ouro",
                                    "CityId": "PDDA",
                                    "CountryId": "MZ",
                                    "Location": "32.880556, -26.783056"
                                }
                            ]
                        },
                        {
                            "Id": "IBLA",
                            "Name": "Mondego",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "35.443056, -21.714167",
                            "IataCode": "IBL",
                            "Airports": [
                                {
                                    "Id": "IBL",
                                    "Name": "Indigo Bay Lodge",
                                    "CityId": "IBLA",
                                    "CountryId": "MZ",
                                    "Location": "35.451389, -21.705833"
                                }
                            ]
                        },
                        {
                            "Id": "MJSA",
                            "Name": "Maganame",
                            "SingleAirportCity": true,
                            "CountryId": "MZ",
                            "Location": "32.216667, -25.35",
                            "IataCode": "MJS",
                            "Airports": [
                                {
                                    "Id": "MJS",
                                    "Name": "Maganja Da Costa",
                                    "CityId": "MJSA",
                                    "CountryId": "MZ",
                                    "Location": "32.216667, -25.35"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MG",
                    "Name": "Madagascar",
                    "CurrencyId": "MGA",
                    "Cities": [
                        {
                            "Id": "SMSA",
                            "Name": "Sainte Marie",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.822097, -17.071882",
                            "IataCode": "SMS",
                            "Airports": [
                                {
                                    "Id": "SMS",
                                    "Name": "Sainte Marie",
                                    "CityId": "SMSA",
                                    "CountryId": "MG",
                                    "Location": "49.816667, -17.083333"
                                }
                            ]
                        },
                        {
                            "Id": "WFIA",
                            "Name": "Fianarantsoa",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.083333, -21.433333",
                            "IataCode": "WFI",
                            "Airports": [
                                {
                                    "Id": "WFI",
                                    "Name": "Fianarantsoa",
                                    "CityId": "WFIA",
                                    "CountryId": "MG",
                                    "Location": "47.083333, -21.466667"
                                }
                            ]
                        },
                        {
                            "Id": "WMAA",
                            "Name": "Mandritsara",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.816667, -15.833333",
                            "IataCode": "WMA",
                            "Airports": [
                                {
                                    "Id": "WMA",
                                    "Name": "Mandritsara",
                                    "CityId": "WMAA",
                                    "CountryId": "MG",
                                    "Location": "48.833333, -15.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WMLA",
                            "Name": "Malaimbandy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.6, -20.333333",
                            "IataCode": "WML",
                            "Airports": [
                                {
                                    "Id": "WML",
                                    "Name": "Malaimbandy",
                                    "CityId": "WMLA",
                                    "CountryId": "MG",
                                    "Location": "45.55, -20.35"
                                }
                            ]
                        },
                        {
                            "Id": "WTAA",
                            "Name": "Tambohorano",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "43.966667, -17.5",
                            "IataCode": "WTA",
                            "Airports": [
                                {
                                    "Id": "WTA",
                                    "Name": "Tambohorano",
                                    "CityId": "WTAA",
                                    "CountryId": "MG",
                                    "Location": "43.966667, -17.483333"
                                }
                            ]
                        },
                        {
                            "Id": "WTSA",
                            "Name": "Tsiroanomandidy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "46.033333, -18.766667",
                            "IataCode": "WTS",
                            "Airports": [
                                {
                                    "Id": "WTS",
                                    "Name": "Tsiroanomandidy",
                                    "CityId": "WTSA",
                                    "CountryId": "MG",
                                    "Location": "46.054444, -18.757222"
                                }
                            ]
                        },
                        {
                            "Id": "AMBA",
                            "Name": "Ambilobe",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.05, -13.2",
                            "IataCode": "AMB",
                            "Airports": [
                                {
                                    "Id": "AMB",
                                    "Name": "Ambilobe",
                                    "CityId": "AMBA",
                                    "CountryId": "MG",
                                    "Location": "48.983333, -13.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ANMA",
                            "Name": "Antalaha",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "50.283333, -14.883333",
                            "IataCode": "ANM",
                            "Airports": [
                                {
                                    "Id": "ANM",
                                    "Name": "Antalaha",
                                    "CityId": "ANMA",
                                    "CountryId": "MG",
                                    "Location": "50.316667, -15"
                                }
                            ]
                        },
                        {
                            "Id": "ZVAA",
                            "Name": "Miandrivazo",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.466667, -19.516667",
                            "IataCode": "ZVA",
                            "Airports": [
                                {
                                    "Id": "ZVA",
                                    "Name": "Miandrivazo",
                                    "CityId": "ZVAA",
                                    "CountryId": "MG",
                                    "Location": "45.45, -19.55"
                                }
                            ]
                        },
                        {
                            "Id": "AMPA",
                            "Name": "Ampanihy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.75, -24.7",
                            "IataCode": "AMP",
                            "Airports": [
                                {
                                    "Id": "AMP",
                                    "Name": "Ampanihy",
                                    "CityId": "AMPA",
                                    "CountryId": "MG",
                                    "Location": "44.733333, -24.7"
                                }
                            ]
                        },
                        {
                            "Id": "ATJA",
                            "Name": "Antsirabe",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.033333, -19.85",
                            "IataCode": "ATJ",
                            "Airports": [
                                {
                                    "Id": "ATJ",
                                    "Name": "Antsirabe",
                                    "CityId": "ATJA",
                                    "CountryId": "MG",
                                    "Location": "47.06667, -19.83333"
                                }
                            ]
                        },
                        {
                            "Id": "DOAA",
                            "Name": "Doany",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.516667, -14.366667",
                            "IataCode": "DOA",
                            "Airports": [
                                {
                                    "Id": "DOA",
                                    "Name": "Doany",
                                    "CityId": "DOAA",
                                    "CountryId": "MG",
                                    "Location": "49.5, -14.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ZWAA",
                            "Name": "Andapa",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.65, -14.65",
                            "IataCode": "ZWA",
                            "Airports": [
                                {
                                    "Id": "ZWA",
                                    "Name": "Andapa",
                                    "CityId": "ZWAA",
                                    "CountryId": "MG",
                                    "Location": "49.616667, -14.65"
                                }
                            ]
                        },
                        {
                            "Id": "BKUA",
                            "Name": "Betioky",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.383333, -23.716667",
                            "IataCode": "BKU",
                            "Airports": [
                                {
                                    "Id": "BKU",
                                    "Name": "Betioky",
                                    "CityId": "BKUA",
                                    "CountryId": "MG",
                                    "Location": "44.391111, -23.732222"
                                }
                            ]
                        },
                        {
                            "Id": "BPYA",
                            "Name": "Besalampy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.483333, -16.75",
                            "IataCode": "BPY",
                            "Airports": [
                                {
                                    "Id": "BPY",
                                    "Name": "Besalampy",
                                    "CityId": "BPYA",
                                    "CountryId": "MG",
                                    "Location": "44.483333, -16.75"
                                }
                            ]
                        },
                        {
                            "Id": "DIEA",
                            "Name": "Antsiranana",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.283333, -12.266667",
                            "IataCode": "DIE",
                            "Airports": [
                                {
                                    "Id": "DIE",
                                    "Name": "Antsiranana / Arrachart",
                                    "CityId": "DIEA",
                                    "CountryId": "MG",
                                    "Location": "49.2925, -12.346111"
                                }
                            ]
                        },
                        {
                            "Id": "DWBA",
                            "Name": "Soalala",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.316667, -16.1",
                            "IataCode": "DWB",
                            "Airports": [
                                {
                                    "Id": "DWB",
                                    "Name": "Soalala",
                                    "CityId": "DWBA",
                                    "CountryId": "MG",
                                    "Location": "45.366667, -16.1"
                                }
                            ]
                        },
                        {
                            "Id": "FTUA",
                            "Name": "Fort Dauphin",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "46.999812, -25.031834",
                            "IataCode": "FTU",
                            "Airports": [
                                {
                                    "Id": "FTU",
                                    "Name": "Fort Dauphin",
                                    "CityId": "FTUA",
                                    "CountryId": "MG",
                                    "Location": "46.955556, -25.038056"
                                }
                            ]
                        },
                        {
                            "Id": "HVAA",
                            "Name": "Analalava",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.75, -14.633333",
                            "IataCode": "HVA",
                            "Airports": [
                                {
                                    "Id": "HVA",
                                    "Name": "Analalava",
                                    "CityId": "HVAA",
                                    "CountryId": "MG",
                                    "Location": "47.766667, -14.633333"
                                }
                            ]
                        },
                        {
                            "Id": "IHOA",
                            "Name": "Ihosy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "46.116667, -22.4",
                            "IataCode": "IHO",
                            "Airports": [
                                {
                                    "Id": "IHO",
                                    "Name": "Ihosy",
                                    "CityId": "IHOA",
                                    "CountryId": "MG",
                                    "Location": "46.116667, -22.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IVAA",
                            "Name": "Ambanja",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.45, -13.683333",
                            "IataCode": "IVA",
                            "Airports": [
                                {
                                    "Id": "IVA",
                                    "Name": "Ambanja",
                                    "CityId": "IVAA",
                                    "CountryId": "MG",
                                    "Location": "48.457778, -13.642222"
                                }
                            ]
                        },
                        {
                            "Id": "JVAA",
                            "Name": "Ankavandra",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.3, -18.766667",
                            "IataCode": "JVA",
                            "Airports": [
                                {
                                    "Id": "JVA",
                                    "Name": "Ankavandra",
                                    "CityId": "JVAA",
                                    "CountryId": "MG",
                                    "Location": "45.283333, -18.8"
                                }
                            ]
                        },
                        {
                            "Id": "TMMA",
                            "Name": "Tamatave",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.383333, -18.166667",
                            "IataCode": "TMM",
                            "Airports": [
                                {
                                    "Id": "TMM",
                                    "Name": "Tamatave",
                                    "CityId": "TMMA",
                                    "CountryId": "MG",
                                    "Location": "49.392222, -18.11"
                                }
                            ]
                        },
                        {
                            "Id": "TNRA",
                            "Name": "Antananarivo",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.516666, -18.916668",
                            "IataCode": "TNR",
                            "Airports": [
                                {
                                    "Id": "TNR",
                                    "Name": "Antananarivo",
                                    "CityId": "TNRA",
                                    "CountryId": "MG",
                                    "Location": "47.483333, -18.8"
                                }
                            ]
                        },
                        {
                            "Id": "TTSA",
                            "Name": "Tsaratanana",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.65, -16.783333",
                            "IataCode": "TTS",
                            "Airports": [
                                {
                                    "Id": "TTS",
                                    "Name": "Tsaratanana",
                                    "CityId": "TTSA",
                                    "CountryId": "MG",
                                    "Location": "47.6, -16.733333"
                                }
                            ]
                        },
                        {
                            "Id": "WADA",
                            "Name": "Andriamena",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.5, -17.433333",
                            "IataCode": "WAD",
                            "Airports": [
                                {
                                    "Id": "WAD",
                                    "Name": "Andriamena",
                                    "CityId": "WADA",
                                    "CountryId": "MG",
                                    "Location": "47.516667, -17.45"
                                }
                            ]
                        },
                        {
                            "Id": "AHYA",
                            "Name": "Ambatolahy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.533333, -20",
                            "IataCode": "AHY",
                            "Airports": [
                                {
                                    "Id": "AHY",
                                    "Name": "Ambatolahy",
                                    "CityId": "AHYA",
                                    "CountryId": "MG",
                                    "Location": "45.533333, -20.016667"
                                }
                            ]
                        },
                        {
                            "Id": "AMYA",
                            "Name": "Ambatomainty",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.666667, -17.683333",
                            "IataCode": "AMY",
                            "Airports": [
                                {
                                    "Id": "AMY",
                                    "Name": "Ambatomainty",
                                    "CityId": "AMYA",
                                    "CountryId": "MG",
                                    "Location": "45.666667, -17.683333"
                                }
                            ]
                        },
                        {
                            "Id": "MJNA",
                            "Name": "Majunga",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "46.316667, -15.716667",
                            "IataCode": "MJN",
                            "Airports": [
                                {
                                    "Id": "MJN",
                                    "Name": "Majunga",
                                    "CityId": "MJNA",
                                    "CountryId": "MG",
                                    "Location": "46.353056, -15.665833"
                                }
                            ]
                        },
                        {
                            "Id": "TDVA",
                            "Name": "Tanandava",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "43.75, -21.716667",
                            "IataCode": "TDV",
                            "Airports": [
                                {
                                    "Id": "TDV",
                                    "Name": "Tanandava",
                                    "CityId": "TDVA",
                                    "CountryId": "MG",
                                    "Location": "43.733333, -21.7"
                                }
                            ]
                        },
                        {
                            "Id": "WAIA",
                            "Name": "Antsohihy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.983333, -14.866667",
                            "IataCode": "WAI",
                            "Airports": [
                                {
                                    "Id": "WAI",
                                    "Name": "Antsohihy",
                                    "CityId": "WAIA",
                                    "CountryId": "MG",
                                    "Location": "47.95, -14.916667"
                                }
                            ]
                        },
                        {
                            "Id": "WBDA",
                            "Name": "Befandriana",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.533333, -15.266667",
                            "IataCode": "WBD",
                            "Airports": [
                                {
                                    "Id": "WBD",
                                    "Name": "Befandriana",
                                    "CityId": "WBDA",
                                    "CountryId": "MG",
                                    "Location": "48.483333, -15.2"
                                }
                            ]
                        },
                        {
                            "Id": "WBOA",
                            "Name": "Beroroha",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.166667, -21.666667",
                            "IataCode": "WBO",
                            "Airports": [
                                {
                                    "Id": "WBO",
                                    "Name": "Beroroha",
                                    "CityId": "WBOA",
                                    "CountryId": "MG",
                                    "Location": "45.133333, -21.6"
                                }
                            ]
                        },
                        {
                            "Id": "WMRA",
                            "Name": "Mananara",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.766667, -16.166667",
                            "IataCode": "WMR",
                            "Airports": [
                                {
                                    "Id": "WMR",
                                    "Name": "Mananara",
                                    "CityId": "WMRA",
                                    "CountryId": "MG",
                                    "Location": "49.770556, -16.161944"
                                }
                            ]
                        },
                        {
                            "Id": "MJAA",
                            "Name": "Manja",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.333333, -21.433333",
                            "IataCode": "MJA",
                            "Airports": [
                                {
                                    "Id": "MJA",
                                    "Name": "Manja",
                                    "CityId": "MJAA",
                                    "CountryId": "MG",
                                    "Location": "44.316667, -21.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MNJA",
                            "Name": "Mananjary",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.333333, -21.216667",
                            "IataCode": "MNJ",
                            "Airports": [
                                {
                                    "Id": "MNJ",
                                    "Name": "Mananjary",
                                    "CityId": "MNJA",
                                    "CountryId": "MG",
                                    "Location": "48.366667, -21.2"
                                }
                            ]
                        },
                        {
                            "Id": "MOQA",
                            "Name": "Morondava",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.283333, -20.283333",
                            "IataCode": "MOQ",
                            "Airports": [
                                {
                                    "Id": "MOQ",
                                    "Name": "Morondava",
                                    "CityId": "MOQA",
                                    "CountryId": "MG",
                                    "Location": "44.318333, -20.283611"
                                }
                            ]
                        },
                        {
                            "Id": "MXMA",
                            "Name": "Morombe",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "43.35, -21.733333",
                            "IataCode": "MXM",
                            "Airports": [
                                {
                                    "Id": "MXM",
                                    "Name": "Morombe",
                                    "CityId": "MXMA",
                                    "CountryId": "MG",
                                    "Location": "43.366667, -21.75"
                                }
                            ]
                        },
                        {
                            "Id": "MXTA",
                            "Name": "Maintirano",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.016667, -18.066667",
                            "IataCode": "MXT",
                            "Airports": [
                                {
                                    "Id": "MXT",
                                    "Name": "Maintirano",
                                    "CityId": "MXTA",
                                    "CountryId": "MG",
                                    "Location": "44.032778, -18.047778"
                                }
                            ]
                        },
                        {
                            "Id": "OVAA",
                            "Name": "Bekily",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "45.316667, -24.216667",
                            "IataCode": "OVA",
                            "Airports": [
                                {
                                    "Id": "OVA",
                                    "Name": "Bekily",
                                    "CityId": "OVAA",
                                    "CountryId": "MG",
                                    "Location": "45.305, -24.2325"
                                }
                            ]
                        },
                        {
                            "Id": "RVAA",
                            "Name": "Farafangana",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.833333, -22.816667",
                            "IataCode": "RVA",
                            "Airports": [
                                {
                                    "Id": "RVA",
                                    "Name": "Farafangana",
                                    "CityId": "RVAA",
                                    "CountryId": "MG",
                                    "Location": "47.819444, -22.801944"
                                }
                            ]
                        },
                        {
                            "Id": "SVBA",
                            "Name": "Sambava",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "50.166667, -14.266667",
                            "IataCode": "SVB",
                            "Airports": [
                                {
                                    "Id": "SVB",
                                    "Name": "Sambava",
                                    "CityId": "SVBA",
                                    "CountryId": "MG",
                                    "Location": "50.175, -14.276944"
                                }
                            ]
                        },
                        {
                            "Id": "TLEA",
                            "Name": "Tulear",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "43.666667, -23.35",
                            "IataCode": "TLE",
                            "Airports": [
                                {
                                    "Id": "TLE",
                                    "Name": "Tulear",
                                    "CityId": "TLEA",
                                    "CountryId": "MG",
                                    "Location": "43.724167, -23.389722"
                                }
                            ]
                        },
                        {
                            "Id": "TVAA",
                            "Name": "Morafenobe",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.916667, -17.816667",
                            "IataCode": "TVA",
                            "Airports": [
                                {
                                    "Id": "TVA",
                                    "Name": "Morafenobe",
                                    "CityId": "TVAA",
                                    "CountryId": "MG",
                                    "Location": "44.919167, -17.849444"
                                }
                            ]
                        },
                        {
                            "Id": "VATA",
                            "Name": "Vatomandry",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.983333, -19.333333",
                            "IataCode": "VAT",
                            "Airports": [
                                {
                                    "Id": "VAT",
                                    "Name": "Vatomandry",
                                    "CityId": "VATA",
                                    "CountryId": "MG",
                                    "Location": "48.833333, -19.283333"
                                }
                            ]
                        },
                        {
                            "Id": "VVBA",
                            "Name": "Mahanoro",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.8, -19.9",
                            "IataCode": "VVB",
                            "Airports": [
                                {
                                    "Id": "VVB",
                                    "Name": "Mahanoro",
                                    "CityId": "VVBA",
                                    "CountryId": "MG",
                                    "Location": "48.8, -19.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WAKA",
                            "Name": "Ankazoabo",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.516667, -22.283333",
                            "IataCode": "WAK",
                            "Airports": [
                                {
                                    "Id": "WAK",
                                    "Name": "Ankazoabo",
                                    "CityId": "WAKA",
                                    "CountryId": "MG",
                                    "Location": "44.533333, -22.3"
                                }
                            ]
                        },
                        {
                            "Id": "WAMA",
                            "Name": "Ambatondrazaka",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.416667, -17.833333",
                            "IataCode": "WAM",
                            "Airports": [
                                {
                                    "Id": "WAM",
                                    "Name": "Ambatondrazaka",
                                    "CityId": "WAMA",
                                    "CountryId": "MG",
                                    "Location": "48.433333, -17.8"
                                }
                            ]
                        },
                        {
                            "Id": "WAQA",
                            "Name": "Antsalova",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.616667, -18.666667",
                            "IataCode": "WAQ",
                            "Airports": [
                                {
                                    "Id": "WAQ",
                                    "Name": "Antsalova",
                                    "CityId": "WAQA",
                                    "CountryId": "MG",
                                    "Location": "44.616667, -18.7"
                                }
                            ]
                        },
                        {
                            "Id": "WBEA",
                            "Name": "Bealanana",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.733333, -14.55",
                            "IataCode": "WBE",
                            "Airports": [
                                {
                                    "Id": "WBE",
                                    "Name": "Bealanana",
                                    "CityId": "WBEA",
                                    "CountryId": "MG",
                                    "Location": "48.7, -14.533333"
                                }
                            ]
                        },
                        {
                            "Id": "WMDA",
                            "Name": "Mandabe",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44.933333, -21.05",
                            "IataCode": "WMD",
                            "Airports": [
                                {
                                    "Id": "WMD",
                                    "Name": "Mandabe",
                                    "CityId": "WMDA",
                                    "CountryId": "MG",
                                    "Location": "44.95, -21.033333"
                                }
                            ]
                        },
                        {
                            "Id": "WMNA",
                            "Name": "Maroantsetra",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "49.733333, -15.433333",
                            "IataCode": "WMN",
                            "Airports": [
                                {
                                    "Id": "WMN",
                                    "Name": "Maroantsetra",
                                    "CityId": "WMNA",
                                    "CountryId": "MG",
                                    "Location": "49.683333, -15.433333"
                                }
                            ]
                        },
                        {
                            "Id": "WMPA",
                            "Name": "Mampikony",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.633333, -16.1",
                            "IataCode": "WMP",
                            "Airports": [
                                {
                                    "Id": "WMP",
                                    "Name": "Mampikony",
                                    "CityId": "WMPA",
                                    "CountryId": "MG",
                                    "Location": "47.597222, -16.075"
                                }
                            ]
                        },
                        {
                            "Id": "WMVA",
                            "Name": "Madirovalo",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "46.533333, -16.433333",
                            "IataCode": "WMV",
                            "Airports": [
                                {
                                    "Id": "WMV",
                                    "Name": "Madirovalo",
                                    "CityId": "WMVA",
                                    "CountryId": "MG",
                                    "Location": "46.566667, -16.45"
                                }
                            ]
                        },
                        {
                            "Id": "WVKA",
                            "Name": "Manakara",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.016667, -22.133333",
                            "IataCode": "WVK",
                            "Airports": [
                                {
                                    "Id": "WVK",
                                    "Name": "Manakara",
                                    "CityId": "WVKA",
                                    "CountryId": "MG",
                                    "Location": "48.016667, -22.116667"
                                }
                            ]
                        },
                        {
                            "Id": "VANG",
                            "Name": "Vangaindrano",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.6, -23.35",
                            "IataCode": "VND",
                            "Airports": [
                                {
                                    "Id": "VND",
                                    "Name": "Vangaindrano",
                                    "CityId": "VANG",
                                    "CountryId": "MG",
                                    "Location": "47.566667, -23.35"
                                }
                            ]
                        },
                        {
                            "Id": "WPBA",
                            "Name": "Port Berge",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.666667, -15.55",
                            "IataCode": "WPB",
                            "Airports": [
                                {
                                    "Id": "WPB",
                                    "Name": "Port Berge",
                                    "CityId": "WPBA",
                                    "CountryId": "MG",
                                    "Location": "47.666667, -15.55"
                                }
                            ]
                        },
                        {
                            "Id": "ILKA",
                            "Name": "Ilaka",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.15, -20.333333",
                            "IataCode": "ILK",
                            "Airports": [
                                {
                                    "Id": "ILK",
                                    "Name": "Ilaka",
                                    "CityId": "ILKA",
                                    "CountryId": "MG",
                                    "Location": "47.166667, -20.333333"
                                }
                            ]
                        },
                        {
                            "Id": "VOHA",
                            "Name": "Vohemar",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "50, -13.35",
                            "IataCode": "VOH",
                            "Airports": [
                                {
                                    "Id": "VOH",
                                    "Name": "Vohemar",
                                    "CityId": "VOHA",
                                    "CountryId": "MG",
                                    "Location": "50, -13.366667"
                                }
                            ]
                        },
                        {
                            "Id": "BSVA",
                            "Name": "Besakoa",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.083333, -15.7",
                            "IataCode": "BSV",
                            "Airports": [
                                {
                                    "Id": "BSV",
                                    "Name": "Besakoa",
                                    "CityId": "BSVA",
                                    "CountryId": "MG",
                                    "Location": "47.066667, -15.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BMDA",
                            "Name": "Belo",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "44, -20.733333",
                            "IataCode": "BMD",
                            "Airports": [
                                {
                                    "Id": "BMD",
                                    "Name": "Belo",
                                    "CityId": "BMDA",
                                    "CountryId": "MG",
                                    "Location": "44.541836, -19.685102"
                                }
                            ]
                        },
                        {
                            "Id": "WORA",
                            "Name": "Marovoha",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "47.316667, -14.883333",
                            "IataCode": "WOR",
                            "Airports": [
                                {
                                    "Id": "WOR",
                                    "Name": "Ankorefo",
                                    "CityId": "WORA",
                                    "CountryId": "MG",
                                    "Location": "47.283333, -14.883333"
                                }
                            ]
                        },
                        {
                            "Id": "NOSA",
                            "Name": "Fasenina-Ampasy",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "48.313889, -13.311111",
                            "IataCode": "NOS",
                            "Airports": [
                                {
                                    "Id": "NOS",
                                    "Name": "Nossi-be",
                                    "CityId": "NOSA",
                                    "CountryId": "MG",
                                    "Location": "48.313889, -13.311111"
                                }
                            ]
                        },
                        {
                            "Id": "NKOA",
                            "Name": "Santa da Pedra",
                            "SingleAirportCity": true,
                            "CountryId": "MG",
                            "Location": "-44.49194, -16.62833",
                            "IataCode": "NKO",
                            "Airports": [
                                {
                                    "Id": "NKO",
                                    "Name": "Ankokoambo",
                                    "CityId": "NKOA",
                                    "CountryId": "MG",
                                    "Location": "-44.45, -16.583333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TN",
                    "Name": "Tunisia",
                    "CurrencyId": "TND",
                    "Cities": [
                        {
                            "Id": "ELBO",
                            "Name": "El Borma",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "9.1333333, 31.666667",
                            "IataCode": "EBM",
                            "Airports": [
                                {
                                    "Id": "EBM",
                                    "Name": "El Borma",
                                    "CityId": "ELBO",
                                    "CountryId": "TN",
                                    "Location": "9.266667, 31.716667"
                                }
                            ]
                        },
                        {
                            "Id": "DJER",
                            "Name": "Djerba",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.859167, 33.874722",
                            "IataCode": "DJE",
                            "Airports": [
                                {
                                    "Id": "DJE",
                                    "Name": "Djerba",
                                    "CityId": "DJER",
                                    "CountryId": "TN",
                                    "Location": "10.783333, 33.874167"
                                }
                            ]
                        },
                        {
                            "Id": "GABE",
                            "Name": "Gabes",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.116667, 33.883333",
                            "IataCode": "GAE",
                            "Airports": [
                                {
                                    "Id": "GAE",
                                    "Name": "Gabes",
                                    "CityId": "GABE",
                                    "CountryId": "TN",
                                    "Location": "10.103333, 33.876919"
                                }
                            ]
                        },
                        {
                            "Id": "GAFS",
                            "Name": "Gafsa",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "8.7841667, 34.425",
                            "IataCode": "GAF",
                            "Airports": [
                                {
                                    "Id": "GAF",
                                    "Name": "Gafsa",
                                    "CityId": "GAFS",
                                    "CountryId": "TN",
                                    "Location": "8.816667, 34.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MONA",
                            "Name": "Monastir",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.833333, 35.783333",
                            "IataCode": "MIR",
                            "Airports": [
                                {
                                    "Id": "MIR",
                                    "Name": "Monastir",
                                    "CityId": "MONA",
                                    "CountryId": "TN",
                                    "Location": "10.752778, 35.756944"
                                }
                            ]
                        },
                        {
                            "Id": "SFAX",
                            "Name": "Sfax El Maou",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.760277, 34.740556",
                            "IataCode": "SFA",
                            "Airports": [
                                {
                                    "Id": "SFA",
                                    "Name": "Sfax El Maou",
                                    "CityId": "SFAX",
                                    "CountryId": "TN",
                                    "Location": "10.683333, 34.716667"
                                }
                            ]
                        },
                        {
                            "Id": "TABA",
                            "Name": "Tabarka",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "8.7580556, 36.954444",
                            "IataCode": "TBJ",
                            "Airports": [
                                {
                                    "Id": "TBJ",
                                    "Name": "Tabarka",
                                    "CityId": "TABA",
                                    "CountryId": "TN",
                                    "Location": "8.876389, 36.978333"
                                }
                            ]
                        },
                        {
                            "Id": "TUNI",
                            "Name": "Tunis Carthage",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.179722, 36.80278",
                            "IataCode": "TUN",
                            "Airports": [
                                {
                                    "Id": "TUN",
                                    "Name": "Tunis Carthage",
                                    "CityId": "TUNI",
                                    "CountryId": "TN",
                                    "Location": "10.227222, 36.851111"
                                }
                            ]
                        },
                        {
                            "Id": "TOZE",
                            "Name": "Tozeur",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "8.1333333, 33.920556",
                            "IataCode": "TOE",
                            "Airports": [
                                {
                                    "Id": "TOE",
                                    "Name": "Tozeur",
                                    "CityId": "TOZE",
                                    "CountryId": "TN",
                                    "Location": "8.133333, 33.916667"
                                }
                            ]
                        },
                        {
                            "Id": "NBEA",
                            "Name": "Enfidha",
                            "SingleAirportCity": true,
                            "CountryId": "TN",
                            "Location": "10.380833, 36.135278",
                            "IataCode": "NBE",
                            "Airports": [
                                {
                                    "Id": "NBE",
                                    "Name": "Enfidha",
                                    "CityId": "NBEA",
                                    "CountryId": "TN",
                                    "Location": "10.413889, 36.091944"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TZ",
                    "Name": "Tanzania",
                    "CurrencyId": "TZS",
                    "Cities": [
                        {
                            "Id": "XMIA",
                            "Name": "Masasi",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "38.8, -10.716667",
                            "IataCode": "XMI",
                            "Airports": [
                                {
                                    "Id": "XMI",
                                    "Name": "Masasi",
                                    "CityId": "XMIA",
                                    "CountryId": "TZ",
                                    "Location": "38.8, -10.716667"
                                }
                            ]
                        },
                        {
                            "Id": "IRIA",
                            "Name": "Iringa",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "35.7, -7.7666667",
                            "IataCode": "IRI",
                            "Airports": [
                                {
                                    "Id": "IRI",
                                    "Name": "Iringa",
                                    "CityId": "IRIA",
                                    "CountryId": "TZ",
                                    "Location": "35.750278, -7.670556"
                                }
                            ]
                        },
                        {
                            "Id": "QSIA",
                            "Name": "Moshi",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "37.333333, -3.35",
                            "IataCode": "QSI",
                            "Airports": [
                                {
                                    "Id": "QSI",
                                    "Name": "Moshi",
                                    "CityId": "QSIA",
                                    "CountryId": "TZ",
                                    "Location": "37.323375, -3.362823"
                                }
                            ]
                        },
                        {
                            "Id": "DARA",
                            "Name": "Dar Es Salaam",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.283333, -6.7999999",
                            "IataCode": "DAR",
                            "Airports": [
                                {
                                    "Id": "DAR",
                                    "Name": "Dar Es Salaam",
                                    "CityId": "DARA",
                                    "CountryId": "TZ",
                                    "Location": "39.201944, -6.875278"
                                }
                            ]
                        },
                        {
                            "Id": "DODA",
                            "Name": "Dodoma",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "35.75, -6.1833333",
                            "IataCode": "DOD",
                            "Airports": [
                                {
                                    "Id": "DOD",
                                    "Name": "Dodoma",
                                    "CityId": "DODA",
                                    "CountryId": "TZ",
                                    "Location": "35.750278, -6.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GITA",
                            "Name": "Geita",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "32.166667, -2.8666667",
                            "IataCode": "GIT",
                            "Airports": [
                                {
                                    "Id": "GIT",
                                    "Name": "Geita",
                                    "CityId": "GITA",
                                    "CountryId": "TZ",
                                    "Location": "32.2, -2.8"
                                }
                            ]
                        },
                        {
                            "Id": "LDIA",
                            "Name": "Lindi",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.716667, -10",
                            "IataCode": "LDI",
                            "Airports": [
                                {
                                    "Id": "LDI",
                                    "Name": "Lindi",
                                    "CityId": "LDIA",
                                    "CountryId": "TZ",
                                    "Location": "39.759444, -9.845833"
                                }
                            ]
                        },
                        {
                            "Id": "MWZA",
                            "Name": "Mwanza",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "32.9, -2.5166667",
                            "IataCode": "MWZ",
                            "Airports": [
                                {
                                    "Id": "MWZ",
                                    "Name": "Mwanza",
                                    "CityId": "MWZA",
                                    "CountryId": "TZ",
                                    "Location": "32.928056, -2.444722"
                                }
                            ]
                        },
                        {
                            "Id": "SUTA",
                            "Name": "Sumbawanga",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "31.616667, -7.9666667",
                            "IataCode": "SUT",
                            "Airports": [
                                {
                                    "Id": "SUT",
                                    "Name": "Sumbawanga",
                                    "CityId": "SUTA",
                                    "CountryId": "TZ",
                                    "Location": "31.616667, -7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "ARKA",
                            "Name": "Arusha",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "36.683333, -3.3666667",
                            "IataCode": "ARK",
                            "Airports": [
                                {
                                    "Id": "ARK",
                                    "Name": "Arusha",
                                    "CityId": "ARKA",
                                    "CountryId": "TZ",
                                    "Location": "36.683333, -3.366667"
                                }
                            ]
                        },
                        {
                            "Id": "BKZA",
                            "Name": "Bukoba",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "31.812222, -1.3316667",
                            "IataCode": "BKZ",
                            "Airports": [
                                {
                                    "Id": "BKZ",
                                    "Name": "Bukoba",
                                    "CityId": "BKZA",
                                    "CountryId": "TZ",
                                    "Location": "31.8, -1.3"
                                }
                            ]
                        },
                        {
                            "Id": "JOMA",
                            "Name": "Njombe",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "34.766667, -9.3333333",
                            "IataCode": "JOM",
                            "Airports": [
                                {
                                    "Id": "JOM",
                                    "Name": "Njombe",
                                    "CityId": "JOMA",
                                    "CountryId": "TZ",
                                    "Location": "34.771389, -9.354444"
                                }
                            ]
                        },
                        {
                            "Id": "LUYA",
                            "Name": "Lushoto",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "38.283333, -4.7833333",
                            "IataCode": "LUY",
                            "Airports": [
                                {
                                    "Id": "LUY",
                                    "Name": "Lushoto",
                                    "CityId": "LUYA",
                                    "CountryId": "TZ",
                                    "Location": "38.333333, -4.8"
                                }
                            ]
                        },
                        {
                            "Id": "MBIA",
                            "Name": "Mbeya",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "33.45, -8.9",
                            "IataCode": "MBI",
                            "Airports": [
                                {
                                    "Id": "MBI",
                                    "Name": "Mbeya",
                                    "CityId": "MBIA",
                                    "CountryId": "TZ",
                                    "Location": "33.461944, -8.914722"
                                }
                            ]
                        },
                        {
                            "Id": "MUZA",
                            "Name": "Musoma",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "33.8, -1.5",
                            "IataCode": "MUZ",
                            "Airports": [
                                {
                                    "Id": "MUZ",
                                    "Name": "Musoma",
                                    "CityId": "MUZA",
                                    "CountryId": "TZ",
                                    "Location": "33.8, -1.497222"
                                }
                            ]
                        },
                        {
                            "Id": "MWNA",
                            "Name": "Mwadui",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "33.6, -3.55",
                            "IataCode": "MWN",
                            "Airports": [
                                {
                                    "Id": "MWN",
                                    "Name": "Mwadui",
                                    "CityId": "MWNA",
                                    "CountryId": "TZ",
                                    "Location": "33.6, -3.55"
                                }
                            ]
                        },
                        {
                            "Id": "MYWA",
                            "Name": "Mtwara",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "40.183333, -10.266667",
                            "IataCode": "MYW",
                            "Airports": [
                                {
                                    "Id": "MYW",
                                    "Name": "Mtwara",
                                    "CityId": "MYWA",
                                    "CountryId": "TZ",
                                    "Location": "40.193611, -10.338889"
                                }
                            ]
                        },
                        {
                            "Id": "NCHA",
                            "Name": "Nachingwea",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "38.766667, -10.383333",
                            "IataCode": "NCH",
                            "Airports": [
                                {
                                    "Id": "NCH",
                                    "Name": "Nachingwea",
                                    "CityId": "NCHA",
                                    "CountryId": "TZ",
                                    "Location": "38.775, -10.363889"
                                }
                            ]
                        },
                        {
                            "Id": "SGXA",
                            "Name": "Songea",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "35.65, -10.683333",
                            "IataCode": "SGX",
                            "Airports": [
                                {
                                    "Id": "SGX",
                                    "Name": "Songea",
                                    "CityId": "SGXA",
                                    "CountryId": "TZ",
                                    "Location": "35.5775, -10.686667"
                                }
                            ]
                        },
                        {
                            "Id": "SHYA",
                            "Name": "Shinyanga",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "33.433333, -3.6666667",
                            "IataCode": "SHY",
                            "Airports": [
                                {
                                    "Id": "SHY",
                                    "Name": "Shinyanga",
                                    "CityId": "SHYA",
                                    "CountryId": "TZ",
                                    "Location": "33, -3.5"
                                }
                            ]
                        },
                        {
                            "Id": "TBOA",
                            "Name": "Tabora",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "32.8, -5.0166667",
                            "IataCode": "TBO",
                            "Airports": [
                                {
                                    "Id": "TBO",
                                    "Name": "Tabora",
                                    "CityId": "TBOA",
                                    "CountryId": "TZ",
                                    "Location": "32.8375, -5.0725"
                                }
                            ]
                        },
                        {
                            "Id": "TGTA",
                            "Name": "Tanga",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.1, -5.0666667",
                            "IataCode": "TGT",
                            "Airports": [
                                {
                                    "Id": "TGT",
                                    "Name": "Tanga",
                                    "CityId": "TGTA",
                                    "CountryId": "TZ",
                                    "Location": "39.070278, -5.089444"
                                }
                            ]
                        },
                        {
                            "Id": "TKQA",
                            "Name": "Kigoma",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "29.626667, -4.8769444",
                            "IataCode": "TKQ",
                            "Airports": [
                                {
                                    "Id": "TKQ",
                                    "Name": "Kigoma",
                                    "CityId": "TKQA",
                                    "CountryId": "TZ",
                                    "Location": "29.67, -4.885"
                                }
                            ]
                        },
                        {
                            "Id": "ZNZA",
                            "Name": "Zanzibar",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.197931, -6.1639369",
                            "IataCode": "ZNZ",
                            "Airports": [
                                {
                                    "Id": "ZNZ",
                                    "Name": "Zanzibar",
                                    "CityId": "ZNZA",
                                    "CountryId": "TZ",
                                    "Location": "39.221944, -6.219167"
                                }
                            ]
                        },
                        {
                            "Id": "JROA",
                            "Name": "Kilimanjaro",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "37.640996, -3.7600528",
                            "IataCode": "JRO",
                            "Airports": [
                                {
                                    "Id": "JRO",
                                    "Name": "Kilimanjaro",
                                    "CityId": "JROA",
                                    "CountryId": "TZ",
                                    "Location": "37.066667, -3.433333"
                                }
                            ]
                        },
                        {
                            "Id": "NPYA",
                            "Name": "Mpanda",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "31.072987, -6.3429524",
                            "IataCode": "NPY",
                            "Airports": [
                                {
                                    "Id": "NPY",
                                    "Name": "Mpanda Airport",
                                    "CityId": "NPYA",
                                    "CountryId": "TZ",
                                    "Location": "31.080556, -6.355556"
                                }
                            ]
                        },
                        {
                            "Id": "LKYA",
                            "Name": "Lake Manyara",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "35.933333, -3.6",
                            "IataCode": "LKY",
                            "Airports": [
                                {
                                    "Id": "LKY",
                                    "Name": "Lake Manyara",
                                    "CityId": "LKYA",
                                    "CountryId": "TZ",
                                    "Location": "35.81852, -3.37625"
                                }
                            ]
                        },
                        {
                            "Id": "KIYA",
                            "Name": "Kilwa",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.4, -8.75",
                            "IataCode": "KIY",
                            "Airports": [
                                {
                                    "Id": "KIY",
                                    "Name": "Kilwa",
                                    "CityId": "KIYA",
                                    "CountryId": "TZ",
                                    "Location": "39.511667, -8.911944"
                                }
                            ]
                        },
                        {
                            "Id": "KBHT",
                            "Name": "Kahama",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "32.6, -3.8333333",
                            "IataCode": "KBH",
                            "Airports": [
                                {
                                    "Id": "KBH",
                                    "Name": "Buzwagi",
                                    "CityId": "KBHT",
                                    "CountryId": "TZ",
                                    "Location": "32.686204, -3.846828"
                                }
                            ]
                        },
                        {
                            "Id": "PMAA",
                            "Name": "Pemba",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.733333, -5.216667",
                            "IataCode": "PMA",
                            "Airports": [
                                {
                                    "Id": "PMA",
                                    "Name": "Pemba",
                                    "CityId": "PMAA",
                                    "CountryId": "TZ",
                                    "Location": "39.801944, -5.242222"
                                }
                            ]
                        },
                        {
                            "Id": "MFAA",
                            "Name": "Mafia",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "39.783333, -7.85",
                            "IataCode": "MFA",
                            "Airports": [
                                {
                                    "Id": "MFA",
                                    "Name": "Mafia",
                                    "CityId": "MFAA",
                                    "CountryId": "TZ",
                                    "Location": "39.665, -7.913889"
                                }
                            ]
                        },
                        {
                            "Id": "SEUA",
                            "Name": "Ruhogo",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "34.883333, -2.25",
                            "IataCode": "SEU",
                            "Airports": [
                                {
                                    "Id": "SEU",
                                    "Name": "Seronera",
                                    "CityId": "SEUA",
                                    "CountryId": "TZ",
                                    "Location": "34.8225, -2.45806"
                                }
                            ]
                        },
                        {
                            "Id": "GRUM",
                            "Name": "Banagi",
                            "SingleAirportCity": true,
                            "CountryId": "TZ",
                            "Location": "34.8225, -2.45806",
                            "IataCode": "GTZ",
                            "Airports": [
                                {
                                    "Id": "GTZ",
                                    "Name": "Grumeti Airstrip",
                                    "CityId": "GRUM",
                                    "CountryId": "TZ",
                                    "Location": "34.224343, -2.16079"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "YT",
                    "Name": "Mayotte",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "DZAA",
                            "Name": "Dzaoudzi",
                            "SingleAirportCity": true,
                            "CountryId": "YT",
                            "Location": "45.253611, -12.779444",
                            "IataCode": "DZA",
                            "Airports": [
                                {
                                    "Id": "DZA",
                                    "Name": "Dzaoudzi",
                                    "CityId": "DZAA",
                                    "CountryId": "YT",
                                    "Location": "45.283333, -12.783333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LY",
                    "Name": "Libya",
                    "CurrencyId": "LYD",
                    "Cities": [
                        {
                            "Id": "BENA",
                            "Name": "Benghazi",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "20.066667, 32.116667",
                            "IataCode": "BEN",
                            "Airports": [
                                {
                                    "Id": "BEN",
                                    "Name": "Benghazi",
                                    "CityId": "BENA",
                                    "CountryId": "LY",
                                    "Location": "20.266667, 32.1"
                                }
                            ]
                        },
                        {
                            "Id": "GHTA",
                            "Name": "Ghat",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "10.172778, 24.964722",
                            "IataCode": "GHT",
                            "Airports": [
                                {
                                    "Id": "GHT",
                                    "Name": "Ghat",
                                    "CityId": "GHTA",
                                    "CountryId": "LY",
                                    "Location": "10.166667, 24.966667"
                                }
                            ]
                        },
                        {
                            "Id": "LAQA",
                            "Name": "Beida",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "21.741667, 32.766389",
                            "IataCode": "LAQ",
                            "Airports": [
                                {
                                    "Id": "LAQ",
                                    "Name": "Beida",
                                    "CityId": "LAQA",
                                    "CountryId": "LY",
                                    "Location": "21.75, 32.816667"
                                }
                            ]
                        },
                        {
                            "Id": "LMQA",
                            "Name": "Marsa Brega",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "19.578611, 30.416667",
                            "IataCode": "LMQ",
                            "Airports": [
                                {
                                    "Id": "LMQ",
                                    "Name": "Marsa Brega",
                                    "CityId": "LMQA",
                                    "CountryId": "LY",
                                    "Location": "19.579444, 30.379444"
                                }
                            ]
                        },
                        {
                            "Id": "LTDA",
                            "Name": "Ghadames",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "9.5, 30.133333",
                            "IataCode": "LTD",
                            "Airports": [
                                {
                                    "Id": "LTD",
                                    "Name": "Ghadames",
                                    "CityId": "LTDA",
                                    "CountryId": "LY",
                                    "Location": "9.509722, 30.129167"
                                }
                            ]
                        },
                        {
                            "Id": "TIPA",
                            "Name": "Tripoli",
                            "SingleAirportCity": false,
                            "CountryId": "LY",
                            "Location": "13.180001, 32.8925",
                            "IataCode": "TIP",
                            "Airports": [
                                {
                                    "Id": "TIP",
                                    "Name": "Tripoli",
                                    "CityId": "TIPA",
                                    "CountryId": "LY",
                                    "Location": "13.158889, 32.663611"
                                },
                                {
                                    "Id": "MJI",
                                    "Name": "Mitiga",
                                    "CityId": "TIPA",
                                    "CountryId": "LY",
                                    "Location": "13.277778, 32.897222"
                                }
                            ]
                        },
                        {
                            "Id": "SRXA",
                            "Name": "Sert",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "16.594722, 31.206111",
                            "IataCode": "SRX",
                            "Airports": [
                                {
                                    "Id": "SRX",
                                    "Name": "Sert",
                                    "CityId": "SRXA",
                                    "CountryId": "LY",
                                    "Location": "16, 30.833333"
                                }
                            ]
                        },
                        {
                            "Id": "TOBA",
                            "Name": "Tobruk",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "23.976388, 32.083611",
                            "IataCode": "TOB",
                            "Airports": [
                                {
                                    "Id": "TOB",
                                    "Name": "Tobruk",
                                    "CityId": "TOBA",
                                    "CountryId": "LY",
                                    "Location": "23.917222, 31.854167"
                                }
                            ]
                        },
                        {
                            "Id": "MRAA",
                            "Name": "Misurata",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "15.090556, 32.378333",
                            "IataCode": "MRA",
                            "Airports": [
                                {
                                    "Id": "MRA",
                                    "Name": "Misurata",
                                    "CityId": "MRAA",
                                    "CountryId": "LY",
                                    "Location": "15.066667, 32.4"
                                }
                            ]
                        },
                        {
                            "Id": "QUBA",
                            "Name": "Ubari",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "12.766667, 26.583333",
                            "IataCode": "QUB",
                            "Airports": [
                                {
                                    "Id": "QUB",
                                    "Name": "Ubari",
                                    "CityId": "QUBA",
                                    "CountryId": "LY",
                                    "Location": "12.766667, 26.6"
                                }
                            ]
                        },
                        {
                            "Id": "SEBA",
                            "Name": "Sebha",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "14.433333, 27.033333",
                            "IataCode": "SEB",
                            "Airports": [
                                {
                                    "Id": "SEB",
                                    "Name": "Sebha",
                                    "CityId": "SEBA",
                                    "CountryId": "LY",
                                    "Location": "14.4625, 27.016667"
                                }
                            ]
                        },
                        {
                            "Id": "AKFA",
                            "Name": "Kufrah",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "23.25, 24.166667",
                            "IataCode": "AKF",
                            "Airports": [
                                {
                                    "Id": "AKF",
                                    "Name": "Kufrah",
                                    "CityId": "AKFA",
                                    "CountryId": "LY",
                                    "Location": "23.333333, 24.2"
                                }
                            ]
                        },
                        {
                            "Id": "QMQA",
                            "Name": "Murzuq",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "13.897222, 25.904444",
                            "IataCode": "QMQ",
                            "Airports": [
                                {
                                    "Id": "QMQ",
                                    "Name": "Murzuq",
                                    "CityId": "QMQA",
                                    "CountryId": "LY",
                                    "Location": "13.916667, 25.916667"
                                }
                            ]
                        },
                        {
                            "Id": "HUQA",
                            "Name": "Houn",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "15.933333, 29.116667",
                            "IataCode": "HUQ",
                            "Airports": [
                                {
                                    "Id": "HUQ",
                                    "Name": "Houn",
                                    "CityId": "HUQA",
                                    "CountryId": "LY",
                                    "Location": "15.5, 29.5"
                                }
                            ]
                        },
                        {
                            "Id": "ZISA",
                            "Name": "Zintan",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "12.233333, 31.933333",
                            "IataCode": "ZIS",
                            "Airports": [
                                {
                                    "Id": "ZIS",
                                    "Name": "Zintan",
                                    "CityId": "ZISA",
                                    "CountryId": "LY",
                                    "Location": "12.248611, 31.771667"
                                }
                            ]
                        },
                        {
                            "Id": "DNFA",
                            "Name": "Derna",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "22.537619, 31.996992",
                            "IataCode": "DNF",
                            "Airports": [
                                {
                                    "Id": "DNF",
                                    "Name": "Derna",
                                    "CityId": "DNFA",
                                    "CountryId": "LY",
                                    "Location": "22.766667, 32.55"
                                }
                            ]
                        },
                        {
                            "Id": "NFRA",
                            "Name": "Jakharrah",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "21.566667, 29.216667",
                            "IataCode": "NFR",
                            "Airports": [
                                {
                                    "Id": "NFR",
                                    "Name": "Nafoora",
                                    "CityId": "NFRA",
                                    "CountryId": "LY",
                                    "Location": "21.583333, 29.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BCQA",
                            "Name": "Tamzāwah",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "14.216667, 27.566667",
                            "IataCode": "BCQ",
                            "Airports": [
                                {
                                    "Id": "BCQ",
                                    "Name": "Brack",
                                    "CityId": "BCQA",
                                    "CountryId": "LY",
                                    "Location": "14.216667, 27.683333"
                                }
                            ]
                        },
                        {
                            "Id": "WAXA",
                            "Name": "Goose Cove East",
                            "SingleAirportCity": true,
                            "CountryId": "LY",
                            "Location": "-55.631287, 51.300077",
                            "IataCode": "WAX",
                            "Airports": [
                                {
                                    "Id": "WAX",
                                    "Name": "Zwara",
                                    "CityId": "WAXA",
                                    "CountryId": "LY",
                                    "Location": "-55.383333, 51.216667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NA",
                    "Name": "Namibia",
                    "CurrencyId": "NAD",
                    "Cities": [
                        {
                            "Id": "WDHA",
                            "Name": "Windhoek",
                            "SingleAirportCity": false,
                            "CountryId": "NA",
                            "Location": "17.083611, -22.57",
                            "IataCode": "WDH",
                            "Airports": [
                                {
                                    "Id": "ERS",
                                    "Name": "Windhoek Eros",
                                    "CityId": "WDHA",
                                    "CountryId": "NA",
                                    "Location": "17.083333, -22.616667"
                                },
                                {
                                    "Id": "WDH",
                                    "Name": "Windhoek Hosea Kutako",
                                    "CityId": "WDHA",
                                    "CountryId": "NA",
                                    "Location": "17.466667, -22.483333"
                                }
                            ]
                        },
                        {
                            "Id": "WVBA",
                            "Name": "Walvis Bay",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "14.505278, -22.9575",
                            "IataCode": "WVB",
                            "Airports": [
                                {
                                    "Id": "WVB",
                                    "Name": "Walvis Bay",
                                    "CityId": "WVBA",
                                    "CountryId": "NA",
                                    "Location": "14.517222, -22.978611"
                                }
                            ]
                        },
                        {
                            "Id": "ADIA",
                            "Name": "Arandis",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "14.966667, -22.416667",
                            "IataCode": "ADI",
                            "Airports": [
                                {
                                    "Id": "ADI",
                                    "Name": "Arandis",
                                    "CityId": "ADIA",
                                    "CountryId": "NA",
                                    "Location": "15, -22.4"
                                }
                            ]
                        },
                        {
                            "Id": "GOGA",
                            "Name": "Gobabis",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "18.966667, -22.45",
                            "IataCode": "GOG",
                            "Airports": [
                                {
                                    "Id": "GOG",
                                    "Name": "Gobabis",
                                    "CityId": "GOGA",
                                    "CountryId": "NA",
                                    "Location": "18.974722, -22.504722"
                                }
                            ]
                        },
                        {
                            "Id": "GFYA",
                            "Name": "Grootfontein",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "18.116667, -19.566667",
                            "IataCode": "GFY",
                            "Airports": [
                                {
                                    "Id": "GFY",
                                    "Name": "Grootfontein",
                                    "CityId": "GFYA",
                                    "CountryId": "NA",
                                    "Location": "18.133333, -19.6"
                                }
                            ]
                        },
                        {
                            "Id": "KMPA",
                            "Name": "Keetmanshoop",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "18.133333, -26.583333",
                            "IataCode": "KMP",
                            "Airports": [
                                {
                                    "Id": "KMP",
                                    "Name": "Keetmanshoop",
                                    "CityId": "KMPA",
                                    "CountryId": "NA",
                                    "Location": "18.1, -26.533611"
                                }
                            ]
                        },
                        {
                            "Id": "KASA",
                            "Name": "Karasburg",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "18.75, -28.016667",
                            "IataCode": "KAS",
                            "Airports": [
                                {
                                    "Id": "KAS",
                                    "Name": "Karasburg",
                                    "CityId": "KASA",
                                    "CountryId": "NA",
                                    "Location": "18.733333, -28"
                                }
                            ]
                        },
                        {
                            "Id": "LUDA",
                            "Name": "Luderitz",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "15.159444, -26.648056",
                            "IataCode": "LUD",
                            "Airports": [
                                {
                                    "Id": "LUD",
                                    "Name": "Luderitz",
                                    "CityId": "LUDA",
                                    "CountryId": "NA",
                                    "Location": "15.243889, -26.686389"
                                }
                            ]
                        },
                        {
                            "Id": "NDUA",
                            "Name": "Rundu",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "19.766667, -17.933333",
                            "IataCode": "NDU",
                            "Airports": [
                                {
                                    "Id": "NDU",
                                    "Name": "Rundu",
                                    "CityId": "NDUA",
                                    "CountryId": "NA",
                                    "Location": "19.716667, -17.95"
                                }
                            ]
                        },
                        {
                            "Id": "NNIA",
                            "Name": "Namutoni",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "16.983333, -18.8",
                            "IataCode": "NNI",
                            "Airports": [
                                {
                                    "Id": "NNI",
                                    "Name": "Namutoni",
                                    "CityId": "NNIA",
                                    "CountryId": "NA",
                                    "Location": "16.916667, -18.816667"
                                }
                            ]
                        },
                        {
                            "Id": "OHIA",
                            "Name": "Oshakati",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "15.683333, -17.783333",
                            "IataCode": "OHI",
                            "Airports": [
                                {
                                    "Id": "OHI",
                                    "Name": "Oshakati",
                                    "CityId": "OHIA",
                                    "CountryId": "NA",
                                    "Location": "15.8, -17.783333"
                                }
                            ]
                        },
                        {
                            "Id": "OKFA",
                            "Name": "Okaukuejo",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "15.9161, -19.176301",
                            "IataCode": "OKF",
                            "Airports": [
                                {
                                    "Id": "OKF",
                                    "Name": "Okaukuejo",
                                    "CityId": "OKFA",
                                    "CountryId": "NA",
                                    "Location": "16, -19.166667"
                                }
                            ]
                        },
                        {
                            "Id": "OMDA",
                            "Name": "Oranjemund",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "16.433333, -28.55",
                            "IataCode": "OMD",
                            "Airports": [
                                {
                                    "Id": "OMD",
                                    "Name": "Oranjemund",
                                    "CityId": "OMDA",
                                    "CountryId": "NA",
                                    "Location": "16.45, -28.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ONDA",
                            "Name": "Ondangwa",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "15.95, -17.916667",
                            "IataCode": "OND",
                            "Airports": [
                                {
                                    "Id": "OND",
                                    "Name": "Ondangwa",
                                    "CityId": "ONDA",
                                    "CountryId": "NA",
                                    "Location": "15.9525, -17.87833"
                                }
                            ]
                        },
                        {
                            "Id": "OPWA",
                            "Name": "Opuwa",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "13.839984, -18.06068",
                            "IataCode": "OPW",
                            "Airports": [
                                {
                                    "Id": "OPW",
                                    "Name": "Opuwa",
                                    "CityId": "OPWA",
                                    "CountryId": "NA",
                                    "Location": "13.833333, -18.066667"
                                }
                            ]
                        },
                        {
                            "Id": "SWPA",
                            "Name": "Swakopmund",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "14.533333, -22.683333",
                            "IataCode": "SWP",
                            "Airports": [
                                {
                                    "Id": "SWP",
                                    "Name": "Swakopmund",
                                    "CityId": "SWPA",
                                    "CountryId": "NA",
                                    "Location": "14.566667, -22.683333"
                                }
                            ]
                        },
                        {
                            "Id": "TSBA",
                            "Name": "Tsumeb",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "17.716667, -19.233333",
                            "IataCode": "TSB",
                            "Airports": [
                                {
                                    "Id": "TSB",
                                    "Name": "Tsumeb",
                                    "CityId": "TSBA",
                                    "CountryId": "NA",
                                    "Location": "17.733333, -19.266667"
                                }
                            ]
                        },
                        {
                            "Id": "BQIA",
                            "Name": "Bagani",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "21.633333, -18.116667",
                            "IataCode": "BQI",
                            "Airports": [
                                {
                                    "Id": "BQI",
                                    "Name": "Bagani",
                                    "CityId": "BQIA",
                                    "CountryId": "NA",
                                    "Location": "21.629722, -18.118056"
                                }
                            ]
                        },
                        {
                            "Id": "LHUA",
                            "Name": "Caprivi",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "23.846744, -17.862748",
                            "IataCode": "LHU",
                            "Airports": [
                                {
                                    "Id": "LHU",
                                    "Name": "Caprivi",
                                    "CityId": "LHUA",
                                    "CountryId": "NA",
                                    "Location": "23.391667, -18.116389"
                                }
                            ]
                        },
                        {
                            "Id": "OTJA",
                            "Name": "Otjiwarongo",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "16.64772, -20.463686",
                            "IataCode": "OTJ",
                            "Airports": [
                                {
                                    "Id": "OTJ",
                                    "Name": "Otjiwarongo",
                                    "CityId": "OTJA",
                                    "CountryId": "NA",
                                    "Location": "16.6625, -20.430556"
                                }
                            ]
                        },
                        {
                            "Id": "AIWA",
                            "Name": "Ai-Ais",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "17.483333, -27.916667",
                            "IataCode": "AIW",
                            "Airports": [
                                {
                                    "Id": "AIW",
                                    "Name": "Ai-Ais",
                                    "CityId": "AIWA",
                                    "CountryId": "NA",
                                    "Location": "17.583333, -27.983333"
                                }
                            ]
                        },
                        {
                            "Id": "OKUA",
                            "Name": "Mokuti Lodge",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "17.049923, -18.813652",
                            "IataCode": "OKU",
                            "Airports": [
                                {
                                    "Id": "OKU",
                                    "Name": "Mokuti Lodge",
                                    "CityId": "OKUA",
                                    "CountryId": "NA",
                                    "Location": "17.05, -18.808333"
                                }
                            ]
                        },
                        {
                            "Id": "SZMA",
                            "Name": "Sesriem",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "15.799178, -24.486173",
                            "IataCode": "SZM",
                            "Airports": [
                                {
                                    "Id": "SZM",
                                    "Name": "Sesriem",
                                    "CityId": "SZMA",
                                    "CountryId": "NA",
                                    "Location": "15.833333, -24.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MPAA",
                            "Name": "Katima Mulilo",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "24.177303, -17.633716",
                            "IataCode": "MPA",
                            "Airports": [
                                {
                                    "Id": "MPA",
                                    "Name": "Mpacha",
                                    "CityId": "MPAA",
                                    "CountryId": "NA",
                                    "Location": "24.177303, -17.633716"
                                }
                            ]
                        },
                        {
                            "Id": "OMGA",
                            "Name": "Khance",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "21.783333, -18.2",
                            "IataCode": "OMG",
                            "Airports": [
                                {
                                    "Id": "OMG",
                                    "Name": "Omega",
                                    "CityId": "OMGA",
                                    "CountryId": "NA",
                                    "Location": "22.066667, -18"
                                }
                            ]
                        },
                        {
                            "Id": "OGVA",
                            "Name": "Witvlei",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "18.483333, -22.4",
                            "IataCode": "OGV",
                            "Airports": [
                                {
                                    "Id": "OGV",
                                    "Name": "Ongava Game Reserve",
                                    "CityId": "OGVA",
                                    "CountryId": "NA",
                                    "Location": "18.53, -22.183"
                                }
                            ]
                        },
                        {
                            "Id": "HALA",
                            "Name": "Torrabaai",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "13.233333, -20.316667",
                            "IataCode": "HAL",
                            "Airports": [
                                {
                                    "Id": "HAL",
                                    "Name": "Halali",
                                    "CityId": "HALA",
                                    "CountryId": "NA",
                                    "Location": "13.066667, -19.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MQGA",
                            "Name": "Stanley",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "17.466667, -22.1",
                            "IataCode": "MQG",
                            "Airports": [
                                {
                                    "Id": "MQG",
                                    "Name": "Midgard",
                                    "CityId": "MQGA",
                                    "CountryId": "NA",
                                    "Location": "17.366667, -22.083333"
                                }
                            ]
                        },
                        {
                            "Id": "RHNA",
                            "Name": "Sendelingsdrif",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "16.883333, -28.166667",
                            "IataCode": "RHN",
                            "Airports": [
                                {
                                    "Id": "RHN",
                                    "Name": "Rosh Pina",
                                    "CityId": "RHNA",
                                    "CountryId": "NA",
                                    "Location": "16.7, -27.966667"
                                }
                            ]
                        },
                        {
                            "Id": "TCYA",
                            "Name": "Möwebaai",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "12.7, -19.366667",
                            "IataCode": "TCY",
                            "Airports": [
                                {
                                    "Id": "TCY",
                                    "Name": "Terrace Bay",
                                    "CityId": "TCYA",
                                    "CountryId": "NA",
                                    "Location": "13, -19.983333"
                                }
                            ]
                        },
                        {
                            "Id": "MJOA",
                            "Name": "Kalkfeld",
                            "SingleAirportCity": true,
                            "CountryId": "NA",
                            "Location": "16.183333, -20.883333",
                            "IataCode": "MJO",
                            "Airports": [
                                {
                                    "Id": "MJO",
                                    "Name": "Mount Etjo Lodge",
                                    "CityId": "MJOA",
                                    "CountryId": "NA",
                                    "Location": "16.469167, -21.018333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NE",
                    "Name": "Niger",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "AJYA",
                            "Name": "Agades",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "7.991111, 16.973333",
                            "IataCode": "AJY",
                            "Airports": [
                                {
                                    "Id": "AJY",
                                    "Name": "Agades",
                                    "CityId": "AJYA",
                                    "CountryId": "NE",
                                    "Location": "7.993056, 16.964167"
                                }
                            ]
                        },
                        {
                            "Id": "ZNDA",
                            "Name": "Zinder",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "8.9833333, 13.8",
                            "IataCode": "ZND",
                            "Airports": [
                                {
                                    "Id": "ZND",
                                    "Name": "Zinder",
                                    "CityId": "ZNDA",
                                    "CountryId": "NE",
                                    "Location": "8.983056, 13.779444"
                                }
                            ]
                        },
                        {
                            "Id": "MFQA",
                            "Name": "Maradi",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "7.096389, 13.491667",
                            "IataCode": "MFQ",
                            "Airports": [
                                {
                                    "Id": "MFQ",
                                    "Name": "Maradi",
                                    "CityId": "MFQA",
                                    "CountryId": "NE",
                                    "Location": "7.125278, 13.5025"
                                }
                            ]
                        },
                        {
                            "Id": "NIMA",
                            "Name": "Niamey",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "2.1166668, 13.516667",
                            "IataCode": "NIM",
                            "Airports": [
                                {
                                    "Id": "NIM",
                                    "Name": "Niamey",
                                    "CityId": "NIMA",
                                    "CountryId": "NE",
                                    "Location": "2.170833, 13.483333"
                                }
                            ]
                        },
                        {
                            "Id": "RLTA",
                            "Name": "Arlit",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "7.3852778, 18.736944",
                            "IataCode": "RLT",
                            "Airports": [
                                {
                                    "Id": "RLT",
                                    "Name": "Arlit",
                                    "CityId": "RLTA",
                                    "CountryId": "NE",
                                    "Location": "7.366667, 18.783333"
                                }
                            ]
                        },
                        {
                            "Id": "THZA",
                            "Name": "Tahoua",
                            "SingleAirportCity": true,
                            "CountryId": "NE",
                            "Location": "5.2677777, 14.890278",
                            "IataCode": "THZ",
                            "Airports": [
                                {
                                    "Id": "THZ",
                                    "Name": "Tahoua",
                                    "CityId": "THZA",
                                    "CountryId": "NE",
                                    "Location": "5.263889, 14.872778"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NG",
                    "Name": "Nigeria",
                    "CurrencyId": "NGN",
                    "Cities": [
                        {
                            "Id": "AKRA",
                            "Name": "Akure",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "5.1999998, 7.25",
                            "IataCode": "AKR",
                            "Airports": [
                                {
                                    "Id": "AKR",
                                    "Name": "Akure",
                                    "CityId": "AKRA",
                                    "CountryId": "NG",
                                    "Location": "5.30101, 7.24674"
                                }
                            ]
                        },
                        {
                            "Id": "YOLA",
                            "Name": "Yola",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "12.483333, 9.2",
                            "IataCode": "YOL",
                            "Airports": [
                                {
                                    "Id": "YOL",
                                    "Name": "Yola",
                                    "CityId": "YOLA",
                                    "CountryId": "NG",
                                    "Location": "12.432222, 9.266111"
                                }
                            ]
                        },
                        {
                            "Id": "ZARB",
                            "Name": "Zaria",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.7000002, 11.066667",
                            "IataCode": "ZAR",
                            "Airports": [
                                {
                                    "Id": "ZAR",
                                    "Name": "Zaria",
                                    "CityId": "ZARB",
                                    "CountryId": "NG",
                                    "Location": "7.666667, 11"
                                }
                            ]
                        },
                        {
                            "Id": "BNIA",
                            "Name": "Benin City",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "5.6333328, 6.3333333",
                            "IataCode": "BNI",
                            "Airports": [
                                {
                                    "Id": "BNI",
                                    "Name": "Benin City",
                                    "CityId": "BNIA",
                                    "CountryId": "NG",
                                    "Location": "5.601667, 6.323889"
                                }
                            ]
                        },
                        {
                            "Id": "CBQA",
                            "Name": "Calabar",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "8.3166668, 4.95",
                            "IataCode": "CBQ",
                            "Airports": [
                                {
                                    "Id": "CBQ",
                                    "Name": "Calabar",
                                    "CityId": "CBQA",
                                    "CountryId": "NG",
                                    "Location": "8.342778, 4.963611"
                                }
                            ]
                        },
                        {
                            "Id": "ENUA",
                            "Name": "Enugu",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.4833337, 6.4333333",
                            "IataCode": "ENU",
                            "Airports": [
                                {
                                    "Id": "ENU",
                                    "Name": "Enugu",
                                    "CityId": "ENUA",
                                    "CountryId": "NG",
                                    "Location": "7.566111, 6.475556"
                                }
                            ]
                        },
                        {
                            "Id": "IBAA",
                            "Name": "Ibadan",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "3.8963882, 7.3877792",
                            "IataCode": "IBA",
                            "Airports": [
                                {
                                    "Id": "IBA",
                                    "Name": "Ibadan",
                                    "CityId": "IBAA",
                                    "CountryId": "NG",
                                    "Location": "3.911111, 7.431944"
                                }
                            ]
                        },
                        {
                            "Id": "ILRA",
                            "Name": "Ilorin",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "4.5499998, 8.5",
                            "IataCode": "ILR",
                            "Airports": [
                                {
                                    "Id": "ILR",
                                    "Name": "Ilorin",
                                    "CityId": "ILRA",
                                    "CountryId": "NG",
                                    "Location": "4.533333, 8.5"
                                }
                            ]
                        },
                        {
                            "Id": "JOSA",
                            "Name": "Jos",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "8.9, 9.9166667",
                            "IataCode": "JOS",
                            "Airports": [
                                {
                                    "Id": "JOS",
                                    "Name": "Jos",
                                    "CityId": "JOSA",
                                    "CountryId": "NG",
                                    "Location": "8.869167, 9.639722"
                                }
                            ]
                        },
                        {
                            "Id": "KADA",
                            "Name": "Kaduna",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.4402784, 10.523056",
                            "IataCode": "KAD",
                            "Airports": [
                                {
                                    "Id": "KAD",
                                    "Name": "Kaduna",
                                    "CityId": "KADA",
                                    "CountryId": "NG",
                                    "Location": "7.440278, 10.595833"
                                }
                            ]
                        },
                        {
                            "Id": "KANA",
                            "Name": "Kano",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "8.5166671, 11.996391",
                            "IataCode": "KAN",
                            "Airports": [
                                {
                                    "Id": "KAN",
                                    "Name": "Aminu Kano Intl",
                                    "CityId": "KANA",
                                    "CountryId": "NG",
                                    "Location": "8.5239, 12.0464"
                                }
                            ]
                        },
                        {
                            "Id": "LOSA",
                            "Name": "Lagos",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "3.3958315, 6.4530624",
                            "IataCode": "LOS",
                            "Airports": [
                                {
                                    "Id": "LOS",
                                    "Name": "Lagos",
                                    "CityId": "LOSA",
                                    "CountryId": "NG",
                                    "Location": "3.326389, 6.588889"
                                }
                            ]
                        },
                        {
                            "Id": "MIUA",
                            "Name": "Maiduguri",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "13.16, 11.845",
                            "IataCode": "MIU",
                            "Airports": [
                                {
                                    "Id": "MIU",
                                    "Name": "Maiduguri",
                                    "CityId": "MIUA",
                                    "CountryId": "NG",
                                    "Location": "13.068056, 11.844167"
                                }
                            ]
                        },
                        {
                            "Id": "PHCA",
                            "Name": "Port Harcourt",
                            "SingleAirportCity": false,
                            "CountryId": "NG",
                            "Location": "6.9986115, 4.7891667",
                            "IataCode": "PHC",
                            "Airports": [
                                {
                                    "Id": "PHG",
                                    "Name": "Port Harcourt City",
                                    "CityId": "PHCA",
                                    "CountryId": "NG",
                                    "Location": "7.02, 4.8411"
                                },
                                {
                                    "Id": "PHC",
                                    "Name": "Port Harcourt",
                                    "CityId": "PHCA",
                                    "CountryId": "NG",
                                    "Location": "6.95, 5.016667"
                                }
                            ]
                        },
                        {
                            "Id": "QRWA",
                            "Name": "Warri",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "5.7499996, 5.5166667",
                            "IataCode": "QRW",
                            "Airports": [
                                {
                                    "Id": "QRW",
                                    "Name": "Warri",
                                    "CityId": "QRWA",
                                    "CountryId": "NG",
                                    "Location": "5.68333, 5.5"
                                }
                            ]
                        },
                        {
                            "Id": "SKOA",
                            "Name": "Sokoto",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "5.2313885, 13.051389",
                            "IataCode": "SKO",
                            "Airports": [
                                {
                                    "Id": "SKO",
                                    "Name": "Sokoto",
                                    "CityId": "SKOA",
                                    "CountryId": "NG",
                                    "Location": "5.207222, 12.916389"
                                }
                            ]
                        },
                        {
                            "Id": "MDIA",
                            "Name": "Makurdi",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "8.5333334, 7.7333333",
                            "IataCode": "MDI",
                            "Airports": [
                                {
                                    "Id": "MDI",
                                    "Name": "Makurdi",
                                    "CityId": "MDIA",
                                    "CountryId": "NG",
                                    "Location": "8.533333, 7.75"
                                }
                            ]
                        },
                        {
                            "Id": "MXJA",
                            "Name": "Minna",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "6.5569449, 9.6138889",
                            "IataCode": "MXJ",
                            "Airports": [
                                {
                                    "Id": "MXJ",
                                    "Name": "Minna",
                                    "CityId": "MXJA",
                                    "CountryId": "NG",
                                    "Location": "6.533333, 9.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ABVA",
                            "Name": "Abuja",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.5333336, 9.0833333",
                            "IataCode": "ABV",
                            "Airports": [
                                {
                                    "Id": "ABV",
                                    "Name": "Abuja",
                                    "CityId": "ABVA",
                                    "CountryId": "NG",
                                    "Location": "7.266667, 9"
                                }
                            ]
                        },
                        {
                            "Id": "UYOA",
                            "Name": "Uyo",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.9333333, 5.05",
                            "IataCode": "QUO",
                            "Airports": [
                                {
                                    "Id": "QUO",
                                    "Name": "Uyo",
                                    "CityId": "UYOA",
                                    "CountryId": "NG",
                                    "Location": "8.098889, 4.875833"
                                }
                            ]
                        },
                        {
                            "Id": "OWEB",
                            "Name": "Owerri",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.0333335, 5.4833333",
                            "IataCode": "QOW",
                            "Airports": [
                                {
                                    "Id": "QOW",
                                    "Name": "Owerri",
                                    "CityId": "OWEB",
                                    "CountryId": "NG",
                                    "Location": "7.21, 5.39"
                                }
                            ]
                        },
                        {
                            "Id": "KATS",
                            "Name": "Katsina",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.5994445, 12.997778",
                            "IataCode": "DKA",
                            "Airports": [
                                {
                                    "Id": "DKA",
                                    "Name": "Katsina Airport",
                                    "CityId": "KATS",
                                    "CountryId": "NG",
                                    "Location": "7.660803, 13.003712"
                                }
                            ]
                        },
                        {
                            "Id": "GOMB",
                            "Name": "Gombe",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "11.171666, 10.289444",
                            "IataCode": "GMO",
                            "Airports": [
                                {
                                    "Id": "GMO",
                                    "Name": "Gombe Lawanti",
                                    "CityId": "GOMB",
                                    "CountryId": "NG",
                                    "Location": "11.166667, 10.283333"
                                }
                            ]
                        },
                        {
                            "Id": "BCUA",
                            "Name": "Bauchi",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "9.8441667, 10.315833",
                            "IataCode": "BCU",
                            "Airports": [
                                {
                                    "Id": "BCU",
                                    "Name": "Bauchi",
                                    "CityId": "BCUA",
                                    "CountryId": "NG",
                                    "Location": "9.8, 10.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ASAB",
                            "Name": "Asaba",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "6.7500002, 6.1833333",
                            "IataCode": "ABB",
                            "Airports": [
                                {
                                    "Id": "ABB",
                                    "Name": "Asaba International Airport",
                                    "CityId": "ASAB",
                                    "CountryId": "NG",
                                    "Location": "6.729126, 6.195168"
                                }
                            ]
                        },
                        {
                            "Id": "QVLA",
                            "Name": "Abaja-Ngwo",
                            "SingleAirportCity": true,
                            "CountryId": "NG",
                            "Location": "7.4081745, 6.424155",
                            "IataCode": "QVL",
                            "Airports": [
                                {
                                    "Id": "QVL",
                                    "Name": "Victoria Island",
                                    "CityId": "QVLA",
                                    "CountryId": "NG",
                                    "Location": "7.41667, 6.41667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SD",
                    "Name": "Sudan",
                    "CurrencyId": "SDG",
                    "Cities": [
                        {
                            "Id": "WHFA",
                            "Name": "Wadi Halfa",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "31.35, 21.8",
                            "IataCode": "WHF",
                            "Airports": [
                                {
                                    "Id": "WHF",
                                    "Name": "Wadi Halfa",
                                    "CityId": "WHFA",
                                    "CountryId": "SD",
                                    "Location": "31.5, 21.75"
                                }
                            ]
                        },
                        {
                            "Id": "DNIA",
                            "Name": "Wad Medani",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "33.533333, 14.4",
                            "IataCode": "DNI",
                            "Airports": [
                                {
                                    "Id": "DNI",
                                    "Name": "Wad Medani",
                                    "CityId": "DNIA",
                                    "CountryId": "SD",
                                    "Location": "33.543889, 14.393889"
                                }
                            ]
                        },
                        {
                            "Id": "DOGA",
                            "Name": "Dongola",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "30.483333, 19.166667",
                            "IataCode": "DOG",
                            "Airports": [
                                {
                                    "Id": "DOG",
                                    "Name": "Dongola",
                                    "CityId": "DOGA",
                                    "CountryId": "SD",
                                    "Location": "30.45, 19.183333"
                                }
                            ]
                        },
                        {
                            "Id": "EBDA",
                            "Name": "El Obeid",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "30.216667, 13.183333",
                            "IataCode": "EBD",
                            "Airports": [
                                {
                                    "Id": "EBD",
                                    "Name": "El Obeid",
                                    "CityId": "EBDA",
                                    "CountryId": "SD",
                                    "Location": "30.234167, 13.159722"
                                }
                            ]
                        },
                        {
                            "Id": "EGNA",
                            "Name": "Geneina",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "22.45, 13.45",
                            "IataCode": "EGN",
                            "Airports": [
                                {
                                    "Id": "EGN",
                                    "Name": "Geneina",
                                    "CityId": "EGNA",
                                    "CountryId": "SD",
                                    "Location": "22.469444, 13.4875"
                                }
                            ]
                        },
                        {
                            "Id": "ELFA",
                            "Name": "El Fasher",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "25.35, 13.633333",
                            "IataCode": "ELF",
                            "Airports": [
                                {
                                    "Id": "ELF",
                                    "Name": "El Fasher",
                                    "CityId": "ELFA",
                                    "CountryId": "SD",
                                    "Location": "25.316667, 13.616667"
                                }
                            ]
                        },
                        {
                            "Id": "GBUA",
                            "Name": "Khashm El Girba",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "35.916667, 14.966667",
                            "IataCode": "GBU",
                            "Airports": [
                                {
                                    "Id": "GBU",
                                    "Name": "Khashm El Girba",
                                    "CityId": "GBUA",
                                    "CountryId": "SD",
                                    "Location": "35.888889, 14.926389"
                                }
                            ]
                        },
                        {
                            "Id": "GSUA",
                            "Name": "Gedaref",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "35.4, 14.033333",
                            "IataCode": "GSU",
                            "Airports": [
                                {
                                    "Id": "GSU",
                                    "Name": "Gedaref",
                                    "CityId": "GSUA",
                                    "CountryId": "SD",
                                    "Location": "35.466667, 14.033333"
                                }
                            ]
                        },
                        {
                            "Id": "KRTA",
                            "Name": "Khartoum",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "32.534167, 15.588057",
                            "IataCode": "KRT",
                            "Airports": [
                                {
                                    "Id": "KRT",
                                    "Name": "Khartoum",
                                    "CityId": "KRTA",
                                    "CountryId": "SD",
                                    "Location": "32.556111, 15.599444"
                                }
                            ]
                        },
                        {
                            "Id": "KSTA",
                            "Name": "Kosti",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "32.666667, 13.166667",
                            "IataCode": "KST",
                            "Airports": [
                                {
                                    "Id": "KST",
                                    "Name": "Kosti",
                                    "CityId": "KSTA",
                                    "CountryId": "SD",
                                    "Location": "32.716667, 13.133333"
                                }
                            ]
                        },
                        {
                            "Id": "PZUA",
                            "Name": "Port Sudan",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "37.216389, 19.615833",
                            "IataCode": "PZU",
                            "Airports": [
                                {
                                    "Id": "PZU",
                                    "Name": "Port Sudan",
                                    "CityId": "PZUA",
                                    "CountryId": "SD",
                                    "Location": "37.213889, 19.577778"
                                }
                            ]
                        },
                        {
                            "Id": "UYLA",
                            "Name": "Nyala",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "24.883333, 12.05",
                            "IataCode": "UYL",
                            "Airports": [
                                {
                                    "Id": "UYL",
                                    "Name": "Nyala",
                                    "CityId": "UYLA",
                                    "CountryId": "SD",
                                    "Location": "24.894722, 12.071667"
                                }
                            ]
                        },
                        {
                            "Id": "ATBA",
                            "Name": "Atbara",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "33.978333, 17.697222",
                            "IataCode": "ATB",
                            "Airports": [
                                {
                                    "Id": "ATB",
                                    "Name": "Atbara",
                                    "CityId": "ATBA",
                                    "CountryId": "SD",
                                    "Location": "34.016667, 17.716667"
                                }
                            ]
                        },
                        {
                            "Id": "MWEA",
                            "Name": "Merowe",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "31.816667, 18.483333",
                            "IataCode": "MWE",
                            "Airports": [
                                {
                                    "Id": "MWE",
                                    "Name": "Merowe",
                                    "CityId": "MWEA",
                                    "CountryId": "SD",
                                    "Location": "31.833333, 18.45"
                                }
                            ]
                        },
                        {
                            "Id": "NHFA",
                            "Name": "New Halfa",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "35.583333, 15.333333",
                            "IataCode": "NHF",
                            "Airports": [
                                {
                                    "Id": "NHF",
                                    "Name": "New Halfa",
                                    "CityId": "NHFA",
                                    "CountryId": "SD",
                                    "Location": "35.733333, 15.35"
                                }
                            ]
                        },
                        {
                            "Id": "NUDA",
                            "Name": "En Nahud",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "28.433333, 12.7",
                            "IataCode": "NUD",
                            "Airports": [
                                {
                                    "Id": "NUD",
                                    "Name": "En Nahud",
                                    "CityId": "NUDA",
                                    "CountryId": "SD",
                                    "Location": "28.416667, 12.75"
                                }
                            ]
                        },
                        {
                            "Id": "KSLA",
                            "Name": "Kassala",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "36.398889, 15.455833",
                            "IataCode": "KSL",
                            "Airports": [
                                {
                                    "Id": "KSL",
                                    "Name": "Kassala",
                                    "CityId": "KSLA",
                                    "CountryId": "SD",
                                    "Location": "36.342222, 15.390278"
                                }
                            ]
                        },
                        {
                            "Id": "RSSA",
                            "Name": "Roseires",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "34.383333, 11.85",
                            "IataCode": "RSS",
                            "Airports": [
                                {
                                    "Id": "RSS",
                                    "Name": "Roseires",
                                    "CityId": "RSSA",
                                    "CountryId": "SD",
                                    "Location": "34.4, 11.783333"
                                }
                            ]
                        },
                        {
                            "Id": "EDBA",
                            "Name": "Eldebba",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "30.95, 18.05",
                            "IataCode": "EDB",
                            "Airports": [
                                {
                                    "Id": "EDB",
                                    "Name": "Eldebba",
                                    "CityId": "EDBA",
                                    "CountryId": "SD",
                                    "Location": "30.9583, 18.025"
                                }
                            ]
                        },
                        {
                            "Id": "DNXA",
                            "Name": "Al Bashīr Ibrāhīm",
                            "SingleAirportCity": true,
                            "CountryId": "SD",
                            "Location": "33.033333, 14.083333",
                            "IataCode": "DNX",
                            "Airports": [
                                {
                                    "Id": "DNX",
                                    "Name": "Dinder",
                                    "CityId": "DNXA",
                                    "CountryId": "SD",
                                    "Location": "35.067, 12.533"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SC",
                    "Name": "Seychelles",
                    "CurrencyId": "SCR",
                    "Cities": [
                        {
                            "Id": "SEZA",
                            "Name": "Mahe Island",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "55.469696, -4.6699256",
                            "IataCode": "SEZ",
                            "Airports": [
                                {
                                    "Id": "SEZ",
                                    "Name": "Mahe Island",
                                    "CityId": "SEZA",
                                    "CountryId": "SC",
                                    "Location": "55.5225, -4.672222"
                                }
                            ]
                        },
                        {
                            "Id": "PRIA",
                            "Name": "Praslin Island",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "55.73476, -4.3247145",
                            "IataCode": "PRI",
                            "Airports": [
                                {
                                    "Id": "PRI",
                                    "Name": "Praslin Island",
                                    "CityId": "PRIA",
                                    "CountryId": "SC",
                                    "Location": "55.692778, -4.319167"
                                }
                            ]
                        },
                        {
                            "Id": "FRKA",
                            "Name": "Fregate Is",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "55.939182, -4.5794082",
                            "IataCode": "FRK",
                            "Airports": [
                                {
                                    "Id": "FRK",
                                    "Name": "Fregate Is",
                                    "CityId": "FRKA",
                                    "CountryId": "SC",
                                    "Location": "55.966667, -4.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BDIA",
                            "Name": "Bird Island",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "55.206905, -3.716381",
                            "IataCode": "BDI",
                            "Airports": [
                                {
                                    "Id": "BDI",
                                    "Name": "Bird Island",
                                    "CityId": "BDIA",
                                    "CountryId": "SC",
                                    "Location": "55.216667, -3.716667"
                                }
                            ]
                        },
                        {
                            "Id": "DESA",
                            "Name": "Desroches",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "53.683, -5.683",
                            "IataCode": "DES",
                            "Airports": [
                                {
                                    "Id": "DES",
                                    "Name": "Desroches",
                                    "CityId": "DESA",
                                    "CountryId": "SC",
                                    "Location": "53.683333, -5.683333"
                                }
                            ]
                        },
                        {
                            "Id": "DEIA",
                            "Name": "Denis Island",
                            "SingleAirportCity": true,
                            "CountryId": "SC",
                            "Location": "55.67049, -3.80165",
                            "IataCode": "DEI",
                            "Airports": [
                                {
                                    "Id": "DEI",
                                    "Name": "Denis Island",
                                    "CityId": "DEIA",
                                    "CountryId": "SC",
                                    "Location": "55.666667, -3.8"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "RE",
                    "Name": "Reunion",
                    "CurrencyId": "EUR",
                    "Cities": [
                        {
                            "Id": "RUNA",
                            "Name": "Saint-Denis",
                            "SingleAirportCity": true,
                            "CountryId": "RE",
                            "Location": "55.466667, -20.866667",
                            "IataCode": "RUN",
                            "Airports": [
                                {
                                    "Id": "RUN",
                                    "Name": "Saint-Denis",
                                    "CityId": "RUNA",
                                    "CountryId": "RE",
                                    "Location": "55.521389, -20.877222"
                                }
                            ]
                        },
                        {
                            "Id": "ZSEB",
                            "Name": "Saint-Pierre",
                            "SingleAirportCity": true,
                            "CountryId": "RE",
                            "Location": "55.483333, -21.316667",
                            "IataCode": "ZSE",
                            "Airports": [
                                {
                                    "Id": "ZSE",
                                    "Name": "Saint-Pierre",
                                    "CityId": "ZSEB",
                                    "CountryId": "RE",
                                    "Location": "55.423611, -21.32"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TD",
                    "Name": "Chad",
                    "CurrencyId": "XAF",
                    "Cities": [
                        {
                            "Id": "AMOA",
                            "Name": "Mao",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "15.313333, 14.119444",
                            "IataCode": "AMO",
                            "Airports": [
                                {
                                    "Id": "AMO",
                                    "Name": "Mao",
                                    "CityId": "AMOA",
                                    "CountryId": "TD",
                                    "Location": "15.316667, 14.116667"
                                }
                            ]
                        },
                        {
                            "Id": "BKRA",
                            "Name": "Bokoro",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "17.058056, 12.376667",
                            "IataCode": "BKR",
                            "Airports": [
                                {
                                    "Id": "BKR",
                                    "Name": "Bokoro",
                                    "CityId": "BKRA",
                                    "CountryId": "TD",
                                    "Location": "17.233333, 12.416667"
                                }
                            ]
                        },
                        {
                            "Id": "FYTA",
                            "Name": "Faya",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "19.116667, 17.916667",
                            "IataCode": "FYT",
                            "Airports": [
                                {
                                    "Id": "FYT",
                                    "Name": "Faya",
                                    "CityId": "FYTA",
                                    "CountryId": "TD",
                                    "Location": "19.116667, 17.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LTCA",
                            "Name": "Lai",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "16.3, 9.4",
                            "IataCode": "LTC",
                            "Airports": [
                                {
                                    "Id": "LTC",
                                    "Name": "Lai",
                                    "CityId": "LTCA",
                                    "CountryId": "TD",
                                    "Location": "16.3, 9.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MEFA",
                            "Name": "Melfi",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "17.933056, 11.059722",
                            "IataCode": "MEF",
                            "Airports": [
                                {
                                    "Id": "MEF",
                                    "Name": "Melfi",
                                    "CityId": "MEFA",
                                    "CountryId": "TD",
                                    "Location": "17.95, 11.05"
                                }
                            ]
                        },
                        {
                            "Id": "MQQA",
                            "Name": "Moundou",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "16.083333, 8.5666667",
                            "IataCode": "MQQ",
                            "Airports": [
                                {
                                    "Id": "MQQ",
                                    "Name": "Moundou",
                                    "CityId": "MQQA",
                                    "CountryId": "TD",
                                    "Location": "16.073333, 8.625"
                                }
                            ]
                        },
                        {
                            "Id": "MVOA",
                            "Name": "Mongo",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "18.7, 12.183333",
                            "IataCode": "MVO",
                            "Airports": [
                                {
                                    "Id": "MVO",
                                    "Name": "Mongo",
                                    "CityId": "MVOA",
                                    "CountryId": "TD",
                                    "Location": "18.783333, 12.2"
                                }
                            ]
                        },
                        {
                            "Id": "NDJA",
                            "Name": "N'Djamena",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "15.049167, 12.113056",
                            "IataCode": "NDJ",
                            "Airports": [
                                {
                                    "Id": "NDJ",
                                    "Name": "N'Djamena",
                                    "CityId": "NDJA",
                                    "CountryId": "TD",
                                    "Location": "15.036111, 12.135"
                                }
                            ]
                        },
                        {
                            "Id": "OGRA",
                            "Name": "Bongor",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "15.372222, 10.280556",
                            "IataCode": "OGR",
                            "Airports": [
                                {
                                    "Id": "OGR",
                                    "Name": "Bongor",
                                    "CityId": "OGRA",
                                    "CountryId": "TD",
                                    "Location": "15.383333, 10.289444"
                                }
                            ]
                        },
                        {
                            "Id": "OTCA",
                            "Name": "Bol",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "14.714722, 13.458611",
                            "IataCode": "OTC",
                            "Airports": [
                                {
                                    "Id": "OTC",
                                    "Name": "Bol",
                                    "CityId": "OTCA",
                                    "CountryId": "TD",
                                    "Location": "14.733333, 13.433333"
                                }
                            ]
                        },
                        {
                            "Id": "OUTA",
                            "Name": "Bousso",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "16.716111, 10.4825",
                            "IataCode": "OUT",
                            "Airports": [
                                {
                                    "Id": "OUT",
                                    "Name": "Bousso",
                                    "CityId": "OUTA",
                                    "CountryId": "TD",
                                    "Location": "16.716667, 10.483333"
                                }
                            ]
                        },
                        {
                            "Id": "AEHA",
                            "Name": "Abecher",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "20.832396, 13.829162",
                            "IataCode": "AEH",
                            "Airports": [
                                {
                                    "Id": "AEH",
                                    "Name": "Abecher",
                                    "CityId": "AEHA",
                                    "CountryId": "TD",
                                    "Location": "20.850833, 13.851389"
                                }
                            ]
                        },
                        {
                            "Id": "AKMA",
                            "Name": "Zakouma",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "19.8199, 10.891",
                            "IataCode": "AKM",
                            "Airports": [
                                {
                                    "Id": "AKM",
                                    "Name": "Zakouma",
                                    "CityId": "AKMA",
                                    "CountryId": "TD",
                                    "Location": "19.816667, 10.883333"
                                }
                            ]
                        },
                        {
                            "Id": "AMCA",
                            "Name": "Am Timan",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "20.283333, 11.033333",
                            "IataCode": "AMC",
                            "Airports": [
                                {
                                    "Id": "AMC",
                                    "Name": "Am Timan",
                                    "CityId": "AMCA",
                                    "CountryId": "TD",
                                    "Location": "20.283333, 11.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ATVA",
                            "Name": "Ati",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "18.333333, 13.216667",
                            "IataCode": "ATV",
                            "Airports": [
                                {
                                    "Id": "ATV",
                                    "Name": "Ati",
                                    "CityId": "ATVA",
                                    "CountryId": "TD",
                                    "Location": "18.3075, 13.242222"
                                }
                            ]
                        },
                        {
                            "Id": "PLFA",
                            "Name": "Pala",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "14.9, 9.3666667",
                            "IataCode": "PLF",
                            "Airports": [
                                {
                                    "Id": "PLF",
                                    "Name": "Pala",
                                    "CityId": "PLFA",
                                    "CountryId": "TD",
                                    "Location": "14.933333, 9.383333"
                                }
                            ]
                        },
                        {
                            "Id": "OUMA",
                            "Name": "Oum Hadjer",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "19.683333, 13.3",
                            "IataCode": "OUM",
                            "Airports": [
                                {
                                    "Id": "OUM",
                                    "Name": "Oum Hadjer",
                                    "CityId": "OUMA",
                                    "CountryId": "TD",
                                    "Location": "19.616667, 13.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SRHA",
                            "Name": "Sarh",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "18.383333, 9.15",
                            "IataCode": "SRH",
                            "Airports": [
                                {
                                    "Id": "SRH",
                                    "Name": "Sarh",
                                    "CityId": "SRHA",
                                    "CountryId": "TD",
                                    "Location": "18.38333, 9.15"
                                }
                            ]
                        },
                        {
                            "Id": "AODA",
                            "Name": "Abou Deia",
                            "SingleAirportCity": true,
                            "CountryId": "TD",
                            "Location": "19.2789, 11.448",
                            "IataCode": "AOD",
                            "Airports": [
                                {
                                    "Id": "AOD",
                                    "Name": "Abou Deia",
                                    "CityId": "AODA",
                                    "CountryId": "TD",
                                    "Location": "19.288889, 11.474722"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SN",
                    "Name": "Senegal",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "ZIGA",
                            "Name": "Ziguinchor",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-16.271944, 12.583333",
                            "IataCode": "ZIG",
                            "Airports": [
                                {
                                    "Id": "ZIG",
                                    "Name": "Ziguinchor",
                                    "CityId": "ZIGA",
                                    "CountryId": "SN",
                                    "Location": "-16.275833, 12.556111"
                                }
                            ]
                        },
                        {
                            "Id": "BXEA",
                            "Name": "Bakel",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-12.459351, 14.902377",
                            "IataCode": "BXE",
                            "Airports": [
                                {
                                    "Id": "BXE",
                                    "Name": "Bakel",
                                    "CityId": "BXEA",
                                    "CountryId": "SN",
                                    "Location": "-12.467778, 14.841667"
                                }
                            ]
                        },
                        {
                            "Id": "CSKA",
                            "Name": "Cap Skirring",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-16.71707, 12.35011",
                            "IataCode": "CSK",
                            "Airports": [
                                {
                                    "Id": "CSK",
                                    "Name": "Cap Skirring",
                                    "CityId": "CSKA",
                                    "CountryId": "SN",
                                    "Location": "-16.75, 12.4"
                                }
                            ]
                        },
                        {
                            "Id": "DKRA",
                            "Name": "Dakar",
                            "SingleAirportCity": false,
                            "CountryId": "SN",
                            "Location": "-17.443884, 14.695114",
                            "IataCode": "DKR",
                            "Airports": [
                                {
                                    "Id": "DKR",
                                    "Name": "Leopold Sedar Senghor",
                                    "CityId": "DKRA",
                                    "CountryId": "SN",
                                    "Location": "-17.490556, 14.740833"
                                },
                                {
                                    "Id": "DSS",
                                    "Name": "Blaise Diagne International",
                                    "CityId": "DKRA",
                                    "CountryId": "SN",
                                    "Location": "-17.072777, 14.670833"
                                }
                            ]
                        },
                        {
                            "Id": "KDAA",
                            "Name": "Kolda",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-14.95, 12.883333",
                            "IataCode": "KDA",
                            "Airports": [
                                {
                                    "Id": "KDA",
                                    "Name": "Kolda",
                                    "CityId": "KDAA",
                                    "CountryId": "SN",
                                    "Location": "-14.966667, 12.883333"
                                }
                            ]
                        },
                        {
                            "Id": "KGGA",
                            "Name": "Kedougou",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-12.183333, 12.55",
                            "IataCode": "KGG",
                            "Airports": [
                                {
                                    "Id": "KGG",
                                    "Name": "Kedougou",
                                    "CityId": "KGGA",
                                    "CountryId": "SN",
                                    "Location": "-12.217222, 12.570833"
                                }
                            ]
                        },
                        {
                            "Id": "MAXA",
                            "Name": "Matam",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-13.330278, 15.6175",
                            "IataCode": "MAX",
                            "Airports": [
                                {
                                    "Id": "MAX",
                                    "Name": "Matam",
                                    "CityId": "MAXA",
                                    "CountryId": "SN",
                                    "Location": "-13.323611, 15.593056"
                                }
                            ]
                        },
                        {
                            "Id": "RDTA",
                            "Name": "Richard Toll",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-15.683333, 16.466667",
                            "IataCode": "RDT",
                            "Airports": [
                                {
                                    "Id": "RDT",
                                    "Name": "Richard Toll",
                                    "CityId": "RDTA",
                                    "CountryId": "SN",
                                    "Location": "-15.657222, 16.437222"
                                }
                            ]
                        },
                        {
                            "Id": "SMYA",
                            "Name": "Simenti",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-13.3, 13.033333",
                            "IataCode": "SMY",
                            "Airports": [
                                {
                                    "Id": "SMY",
                                    "Name": "Simenti",
                                    "CityId": "SMYA",
                                    "CountryId": "SN",
                                    "Location": "-13.166667, 13.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TUDA",
                            "Name": "Tambacounda",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-13.758611, 13.753889",
                            "IataCode": "TUD",
                            "Airports": [
                                {
                                    "Id": "TUD",
                                    "Name": "Tambacounda",
                                    "CityId": "TUDA",
                                    "CountryId": "SN",
                                    "Location": "-13.658333, 13.736111"
                                }
                            ]
                        },
                        {
                            "Id": "NIKA",
                            "Name": "Niokolo Koba",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-12.727117, 13.077115",
                            "IataCode": "NIK",
                            "Airports": [
                                {
                                    "Id": "NIK",
                                    "Name": "Niokolo Koba",
                                    "CityId": "NIKA",
                                    "CountryId": "SN",
                                    "Location": "-12.716667, 13.066667"
                                }
                            ]
                        },
                        {
                            "Id": "KLCA",
                            "Name": "Kaolack",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-16.05724, 14.063365",
                            "IataCode": "KLC",
                            "Airports": [
                                {
                                    "Id": "KLC",
                                    "Name": "Kaolack",
                                    "CityId": "KLCA",
                                    "CountryId": "SN",
                                    "Location": "-16.05, 14.13333"
                                }
                            ]
                        },
                        {
                            "Id": "PODA",
                            "Name": "Podor",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-15.041389, 16.617778",
                            "IataCode": "POD",
                            "Airports": [
                                {
                                    "Id": "POD",
                                    "Name": "Podor",
                                    "CityId": "PODA",
                                    "CountryId": "SN",
                                    "Location": "-14.966667, 16.666667"
                                }
                            ]
                        },
                        {
                            "Id": "XLSA",
                            "Name": "St Louis",
                            "SingleAirportCity": true,
                            "CountryId": "SN",
                            "Location": "-16.50419, 16.02764",
                            "IataCode": "XLS",
                            "Airports": [
                                {
                                    "Id": "XLS",
                                    "Name": "St Louis",
                                    "CityId": "XLSA",
                                    "CountryId": "SN",
                                    "Location": "-16.460278, 16.050278"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TG",
                    "Name": "Togo",
                    "CurrencyId": "XOF",
                    "Cities": [
                        {
                            "Id": "LFWA",
                            "Name": "Lome",
                            "SingleAirportCity": true,
                            "CountryId": "TG",
                            "Location": "1.222778, 6.1319444",
                            "IataCode": "LFW",
                            "Airports": [
                                {
                                    "Id": "LFW",
                                    "Name": "Lome",
                                    "CityId": "LFWA",
                                    "CountryId": "TG",
                                    "Location": "1.254167, 6.1625"
                                }
                            ]
                        },
                        {
                            "Id": "LRLA",
                            "Name": "Niamtougou",
                            "SingleAirportCity": true,
                            "CountryId": "TG",
                            "Location": "1.1052778, 9.7680556",
                            "IataCode": "LRL",
                            "Airports": [
                                {
                                    "Id": "LRL",
                                    "Name": "Niamtougou",
                                    "CityId": "LRLA",
                                    "CountryId": "TG",
                                    "Location": "1.133333, 9.833333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ZA",
                    "Name": "South Africa",
                    "CurrencyId": "ZAR",
                    "Cities": [
                        {
                            "Id": "ADYA",
                            "Name": "Alldays",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.1, -22.666667",
                            "IataCode": "ADY",
                            "Airports": [
                                {
                                    "Id": "ADY",
                                    "Name": "Alldays",
                                    "CityId": "ADYA",
                                    "CountryId": "ZA",
                                    "Location": "29.05, -22.666667"
                                }
                            ]
                        },
                        {
                            "Id": "AFDA",
                            "Name": "Port Alfred",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.9, -33.6",
                            "IataCode": "AFD",
                            "Airports": [
                                {
                                    "Id": "AFD",
                                    "Name": "Port Alfred",
                                    "CityId": "AFDA",
                                    "CountryId": "ZA",
                                    "Location": "26.883333, -33.583333"
                                }
                            ]
                        },
                        {
                            "Id": "AGZA",
                            "Name": "Aggeneys",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "18.85, -29.2",
                            "IataCode": "AGZ",
                            "Airports": [
                                {
                                    "Id": "AGZ",
                                    "Name": "Aggeneys",
                                    "CityId": "AGZA",
                                    "CountryId": "ZA",
                                    "Location": "18.81366, -29.28191"
                                }
                            ]
                        },
                        {
                            "Id": "ALJA",
                            "Name": "Alexander Bay",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "16.483333, -28.583333",
                            "IataCode": "ALJ",
                            "Airports": [
                                {
                                    "Id": "ALJ",
                                    "Name": "Alexander Bay",
                                    "CityId": "ALJA",
                                    "CountryId": "ZA",
                                    "Location": "16.53333, -28.575"
                                }
                            ]
                        },
                        {
                            "Id": "BIYA",
                            "Name": "Bisho",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.433333, -32.866667",
                            "IataCode": "BIY",
                            "Airports": [
                                {
                                    "Id": "BIY",
                                    "Name": "Bisho",
                                    "CityId": "BIYA",
                                    "CountryId": "ZA",
                                    "Location": "27.283333, -35.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CDOA",
                            "Name": "Cradock",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "25.616667, -32.183333",
                            "IataCode": "CDO",
                            "Airports": [
                                {
                                    "Id": "CDO",
                                    "Name": "Cradock",
                                    "CityId": "CDOA",
                                    "CountryId": "ZA",
                                    "Location": "25.6, -32.133333"
                                }
                            ]
                        },
                        {
                            "Id": "GIYA",
                            "Name": "Giyani",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.716667, -23.316667",
                            "IataCode": "GIY",
                            "Airports": [
                                {
                                    "Id": "GIY",
                                    "Name": "Giyani",
                                    "CityId": "GIYA",
                                    "CountryId": "ZA",
                                    "Location": "30.666667, -23.283333"
                                }
                            ]
                        },
                        {
                            "Id": "LLEA",
                            "Name": "Malelane",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.516667, -25.483333",
                            "IataCode": "LLE",
                            "Airports": [
                                {
                                    "Id": "LLE",
                                    "Name": "Malelane",
                                    "CityId": "LLEA",
                                    "CountryId": "ZA",
                                    "Location": "31.566667, -25.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LTAA",
                            "Name": "Tzaneen",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.166667, -23.833333",
                            "IataCode": "LTA",
                            "Airports": [
                                {
                                    "Id": "LTA",
                                    "Name": "Tzaneen",
                                    "CityId": "LTAA",
                                    "CountryId": "ZA",
                                    "Location": "30.15, -23.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ELLA",
                            "Name": "Ellisras",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.733333, -23.666667",
                            "IataCode": "ELL",
                            "Airports": [
                                {
                                    "Id": "ELL",
                                    "Name": "Ellisras",
                                    "CityId": "ELLA",
                                    "CountryId": "ZA",
                                    "Location": "27.683333, -23.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SBUA",
                            "Name": "Springbok",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "17.883333, -29.666667",
                            "IataCode": "SBU",
                            "Airports": [
                                {
                                    "Id": "SBU",
                                    "Name": "Springbok",
                                    "CityId": "SBUA",
                                    "CountryId": "ZA",
                                    "Location": "17.939444, -29.688611"
                                }
                            ]
                        },
                        {
                            "Id": "BFNA",
                            "Name": "Bloemfontein",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.2, -29.133333",
                            "IataCode": "BFN",
                            "Airports": [
                                {
                                    "Id": "BFN",
                                    "Name": "Bloemfontein",
                                    "CityId": "BFNA",
                                    "CountryId": "ZA",
                                    "Location": "26.3, -29.1"
                                }
                            ]
                        },
                        {
                            "Id": "CPTA",
                            "Name": "Cape Town",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "18.417396, -33.928992",
                            "IataCode": "CPT",
                            "Airports": [
                                {
                                    "Id": "CPT",
                                    "Name": "Cape Town",
                                    "CityId": "CPTA",
                                    "CountryId": "ZA",
                                    "Location": "18.602074, -33.971499"
                                }
                            ]
                        },
                        {
                            "Id": "DURA",
                            "Name": "Durban",
                            "SingleAirportCity": false,
                            "CountryId": "ZA",
                            "Location": "31.016669, -29.850007",
                            "IataCode": "DUR",
                            "Airports": [
                                {
                                    "Id": "VIR",
                                    "Name": "Durban Virginia",
                                    "CityId": "DURA",
                                    "CountryId": "ZA",
                                    "Location": "31.05841, -29.77061"
                                },
                                {
                                    "Id": "DUR",
                                    "Name": "Durban King Shaka International",
                                    "CityId": "DURA",
                                    "CountryId": "ZA",
                                    "Location": "31.114062, -29.611081"
                                }
                            ]
                        },
                        {
                            "Id": "ELSA",
                            "Name": "East London",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.911625, -33.015285",
                            "IataCode": "ELS",
                            "Airports": [
                                {
                                    "Id": "ELS",
                                    "Name": "East London",
                                    "CityId": "ELSA",
                                    "CountryId": "ZA",
                                    "Location": "27.823889, -33.036111"
                                }
                            ]
                        },
                        {
                            "Id": "EMGA",
                            "Name": "Empangeni",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.9, -28.75",
                            "IataCode": "EMG",
                            "Airports": [
                                {
                                    "Id": "EMG",
                                    "Name": "Empangeni",
                                    "CityId": "EMGA",
                                    "CountryId": "ZA",
                                    "Location": "31.9, -28.75"
                                }
                            ]
                        },
                        {
                            "Id": "FCBA",
                            "Name": "Ficksburg",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.883333, -28.883333",
                            "IataCode": "FCB",
                            "Airports": [
                                {
                                    "Id": "FCB",
                                    "Name": "Ficksburg",
                                    "CityId": "FCBA",
                                    "CountryId": "ZA",
                                    "Location": "27.908333, -28.825"
                                }
                            ]
                        },
                        {
                            "Id": "HDSA",
                            "Name": "Hoedspruit",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.966667, -24.35",
                            "IataCode": "HDS",
                            "Airports": [
                                {
                                    "Id": "HDS",
                                    "Name": "Hoedspruit",
                                    "CityId": "HDSA",
                                    "CountryId": "ZA",
                                    "Location": "31.049767, -24.368677"
                                }
                            ]
                        },
                        {
                            "Id": "JNBA",
                            "Name": "Johannesburg",
                            "SingleAirportCity": false,
                            "CountryId": "ZA",
                            "Location": "28.083333, -26.200003",
                            "IataCode": "JNB",
                            "Airports": [
                                {
                                    "Id": "HCS",
                                    "Name": "Johannesburg Randburg Heliport",
                                    "CityId": "JNBA",
                                    "CountryId": "ZA",
                                    "Location": "28.242778, -26.140278"
                                },
                                {
                                    "Id": "GCJ",
                                    "Name": "Johannesburg Grand Central",
                                    "CityId": "JNBA",
                                    "CountryId": "ZA",
                                    "Location": "28.14079, -25.98941"
                                },
                                {
                                    "Id": "QRA",
                                    "Name": "Johannesburg Randgermiston",
                                    "CityId": "JNBA",
                                    "CountryId": "ZA",
                                    "Location": "28.1512, -26.24243"
                                },
                                {
                                    "Id": "JNB",
                                    "Name": "Johannesburg O.R. Tambo",
                                    "CityId": "JNBA",
                                    "CountryId": "ZA",
                                    "Location": "28.25, -26.133333"
                                },
                                {
                                    "Id": "HLA",
                                    "Name": "Lanseria",
                                    "CityId": "JNBA",
                                    "CountryId": "ZA",
                                    "Location": "27.909444, -25.938611"
                                }
                            ]
                        },
                        {
                            "Id": "GRJA",
                            "Name": "George",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "22.45, -33.966667",
                            "IataCode": "GRJ",
                            "Airports": [
                                {
                                    "Id": "GRJ",
                                    "Name": "George",
                                    "CityId": "GRJA",
                                    "CountryId": "ZA",
                                    "Location": "22.378889, -34.005556"
                                }
                            ]
                        },
                        {
                            "Id": "JOHA",
                            "Name": "Port Saint Johns",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.533333, -31.633333",
                            "IataCode": "JOH",
                            "Airports": [
                                {
                                    "Id": "JOH",
                                    "Name": "Port Saint Johns",
                                    "CityId": "JOHA",
                                    "CountryId": "ZA",
                                    "Location": "29.533333, -31.616667"
                                }
                            ]
                        },
                        {
                            "Id": "KOFA",
                            "Name": "Komatipoort",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.933333, -25.433333",
                            "IataCode": "KOF",
                            "Airports": [
                                {
                                    "Id": "KOF",
                                    "Name": "Komatipoort",
                                    "CityId": "KOFA",
                                    "CountryId": "ZA",
                                    "Location": "31.933333, -25.433333"
                                }
                            ]
                        },
                        {
                            "Id": "HLWA",
                            "Name": "Hluhluwe",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "32.283333, -28.033333",
                            "IataCode": "HLW",
                            "Airports": [
                                {
                                    "Id": "HLW",
                                    "Name": "Hluhluwe",
                                    "CityId": "HLWA",
                                    "CountryId": "ZA",
                                    "Location": "32.25, -28.016667"
                                }
                            ]
                        },
                        {
                            "Id": "HZVA",
                            "Name": "Hazyview",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.116667, -25.033333",
                            "IataCode": "HZV",
                            "Airports": [
                                {
                                    "Id": "HZV",
                                    "Name": "Hazyview",
                                    "CityId": "HZVA",
                                    "CountryId": "ZA",
                                    "Location": "31.130556, -25.066667"
                                }
                            ]
                        },
                        {
                            "Id": "KMHA",
                            "Name": "Kuruman",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "23.433333, -27.45",
                            "IataCode": "KMH",
                            "Airports": [
                                {
                                    "Id": "KMH",
                                    "Name": "Kuruman",
                                    "CityId": "KMHA",
                                    "CountryId": "ZA",
                                    "Location": "23.466667, -27.466667"
                                }
                            ]
                        },
                        {
                            "Id": "KIMA",
                            "Name": "Kimberley",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "24.766667, -28.733334",
                            "IataCode": "KIM",
                            "Airports": [
                                {
                                    "Id": "KIM",
                                    "Name": "Kimberley",
                                    "CityId": "KIMA",
                                    "CountryId": "ZA",
                                    "Location": "24.766389, -28.805833"
                                }
                            ]
                        },
                        {
                            "Id": "KLZA",
                            "Name": "Kleinzee",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "17.083333, -29.666667",
                            "IataCode": "KLZ",
                            "Airports": [
                                {
                                    "Id": "KLZ",
                                    "Name": "Kleinzee",
                                    "CityId": "KLZA",
                                    "CountryId": "ZA",
                                    "Location": "17.066667, -29.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KXEA",
                            "Name": "Klerksdorp",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.666667, -26.866667",
                            "IataCode": "KXE",
                            "Airports": [
                                {
                                    "Id": "KXE",
                                    "Name": "Klerksdorp",
                                    "CityId": "KXEA",
                                    "CountryId": "ZA",
                                    "Location": "26.716667, -26.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LAYA",
                            "Name": "Ladysmith",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.783333, -28.55",
                            "IataCode": "LAY",
                            "Airports": [
                                {
                                    "Id": "LAY",
                                    "Name": "Ladysmith",
                                    "CityId": "LAYA",
                                    "CountryId": "ZA",
                                    "Location": "29.75, -28.566667"
                                }
                            ]
                        },
                        {
                            "Id": "LCDA",
                            "Name": "Louis Trichardt",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.9, -23.05",
                            "IataCode": "LCD",
                            "Airports": [
                                {
                                    "Id": "LCD",
                                    "Name": "Louis Trichardt",
                                    "CityId": "LCDA",
                                    "CountryId": "ZA",
                                    "Location": "29.716667, -23.016667"
                                }
                            ]
                        },
                        {
                            "Id": "LMRA",
                            "Name": "Lime Acres",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "23.466667, -28.35",
                            "IataCode": "LMR",
                            "Airports": [
                                {
                                    "Id": "LMR",
                                    "Name": "Lime Acres",
                                    "CityId": "LMRA",
                                    "CountryId": "ZA",
                                    "Location": "23.45, -28.366667"
                                }
                            ]
                        },
                        {
                            "Id": "LUJA",
                            "Name": "Lusikisiki",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.583333, -31.35",
                            "IataCode": "LUJ",
                            "Airports": [
                                {
                                    "Id": "LUJ",
                                    "Name": "Lusikisiki",
                                    "CityId": "LUJA",
                                    "CountryId": "ZA",
                                    "Location": "29.583333, -31.366667"
                                }
                            ]
                        },
                        {
                            "Id": "NLPA",
                            "Name": "Nelspruit",
                            "SingleAirportCity": false,
                            "CountryId": "ZA",
                            "Location": "30.966667, -25.466667",
                            "IataCode": "NLP",
                            "Airports": [
                                {
                                    "Id": "MQP",
                                    "Name": "Nelspruit",
                                    "CityId": "NLPA",
                                    "CountryId": "ZA",
                                    "Location": "31.099444, -25.390833"
                                },
                                {
                                    "Id": "NLP",
                                    "Name": "Nelspruit",
                                    "CityId": "NLPA",
                                    "CountryId": "ZA",
                                    "Location": "30.913889, -25.500833"
                                }
                            ]
                        },
                        {
                            "Id": "NTYA",
                            "Name": "Sun City",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.1, -25.333333",
                            "IataCode": "NTY",
                            "Airports": [
                                {
                                    "Id": "NTY",
                                    "Name": "Sun City",
                                    "CityId": "NTYA",
                                    "CountryId": "ZA",
                                    "Location": "27.173333, -25.333889"
                                }
                            ]
                        },
                        {
                            "Id": "PLZA",
                            "Name": "Port Elizabeth",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "25.583334, -33.966668",
                            "IataCode": "PLZ",
                            "Airports": [
                                {
                                    "Id": "PLZ",
                                    "Name": "Port Elizabeth",
                                    "CityId": "PLZA",
                                    "CountryId": "ZA",
                                    "Location": "25.613889, -33.986111"
                                }
                            ]
                        },
                        {
                            "Id": "PRYA",
                            "Name": "Pretoria",
                            "SingleAirportCity": false,
                            "CountryId": "ZA",
                            "Location": "28.229444, -25.706947",
                            "IataCode": "PRY",
                            "Airports": [
                                {
                                    "Id": "HIC",
                                    "Name": "Pretoria Iscor Heliport",
                                    "CityId": "PRYA",
                                    "CountryId": "ZA",
                                    "Location": "28.220833, -25.654444"
                                },
                                {
                                    "Id": "HPR",
                                    "Name": "Pretoria Central Hpr",
                                    "CityId": "PRYA",
                                    "CountryId": "ZA",
                                    "Location": "28.220833, -25.654444"
                                },
                                {
                                    "Id": "PRY",
                                    "Name": "Pretoria Wonderboom Apt.",
                                    "CityId": "PRYA",
                                    "CountryId": "ZA",
                                    "Location": "28.22423, -25.65386"
                                }
                            ]
                        },
                        {
                            "Id": "PZBA",
                            "Name": "Pietermaritzburg",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.383334, -29.616667",
                            "IataCode": "PZB",
                            "Airports": [
                                {
                                    "Id": "PZB",
                                    "Name": "Pietermaritzburg",
                                    "CityId": "PZBA",
                                    "CountryId": "ZA",
                                    "Location": "30.399444, -29.646111"
                                }
                            ]
                        },
                        {
                            "Id": "UTNA",
                            "Name": "Upington",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "21.25, -28.45",
                            "IataCode": "UTN",
                            "Airports": [
                                {
                                    "Id": "UTN",
                                    "Name": "Upington",
                                    "CityId": "UTNA",
                                    "CountryId": "ZA",
                                    "Location": "21.253333, -28.400556"
                                }
                            ]
                        },
                        {
                            "Id": "VYDA",
                            "Name": "Vryheid",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.8, -27.766667",
                            "IataCode": "VYD",
                            "Airports": [
                                {
                                    "Id": "VYD",
                                    "Name": "Vryheid",
                                    "CityId": "VYDA",
                                    "CountryId": "ZA",
                                    "Location": "30.8, -27.783333"
                                }
                            ]
                        },
                        {
                            "Id": "MBDA",
                            "Name": "Mmabatho",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "25.633333, -25.85",
                            "IataCode": "MBD",
                            "Airports": [
                                {
                                    "Id": "MBD",
                                    "Name": "Mmabatho",
                                    "CityId": "MBDA",
                                    "CountryId": "ZA",
                                    "Location": "25.548056, -25.798333"
                                }
                            ]
                        },
                        {
                            "Id": "PTGA",
                            "Name": "Polokwane",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.45, -23.9",
                            "IataCode": "PTG",
                            "Airports": [
                                {
                                    "Id": "PTG",
                                    "Name": "Polokwane",
                                    "CityId": "PTGA",
                                    "CountryId": "ZA",
                                    "Location": "29.459152, -23.848947"
                                }
                            ]
                        },
                        {
                            "Id": "VRUA",
                            "Name": "Vryburg",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "24.733333, -26.95",
                            "IataCode": "VRU",
                            "Airports": [
                                {
                                    "Id": "VRU",
                                    "Name": "Vryburg",
                                    "CityId": "VRUA",
                                    "CountryId": "ZA",
                                    "Location": "24.75, -26.916667"
                                }
                            ]
                        },
                        {
                            "Id": "ULDA",
                            "Name": "Ulundi",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.416667, -28.333333",
                            "IataCode": "ULD",
                            "Airports": [
                                {
                                    "Id": "ULD",
                                    "Name": "Ulundi",
                                    "CityId": "ULDA",
                                    "CountryId": "ZA",
                                    "Location": "31.433333, -28.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MEZA",
                            "Name": "Messina",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.05, -22.35",
                            "IataCode": "MEZ",
                            "Airports": [
                                {
                                    "Id": "MEZ",
                                    "Name": "Messina",
                                    "CityId": "MEZA",
                                    "CountryId": "ZA",
                                    "Location": "29.833333, -22.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MGHA",
                            "Name": "Margate",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.366667, -30.85",
                            "IataCode": "MGH",
                            "Airports": [
                                {
                                    "Id": "MGH",
                                    "Name": "Margate",
                                    "CityId": "MGHA",
                                    "CountryId": "ZA",
                                    "Location": "30.34302, -30.85741"
                                }
                            ]
                        },
                        {
                            "Id": "OUHA",
                            "Name": "Oudtshoorn",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "22.201395, -33.590671",
                            "IataCode": "OUH",
                            "Airports": [
                                {
                                    "Id": "OUH",
                                    "Name": "Oudtshoorn",
                                    "CityId": "OUHA",
                                    "CountryId": "ZA",
                                    "Location": "22.188333, -33.603333"
                                }
                            ]
                        },
                        {
                            "Id": "RCBA",
                            "Name": "Richards Bay",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "32.1, -28.8",
                            "IataCode": "RCB",
                            "Airports": [
                                {
                                    "Id": "RCB",
                                    "Name": "Richards Bay",
                                    "CityId": "RCBA",
                                    "CountryId": "ZA",
                                    "Location": "32.093056, -28.740556"
                                }
                            ]
                        },
                        {
                            "Id": "TCUA",
                            "Name": "Thaba Nchu",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.833333, -29.216667",
                            "IataCode": "TCU",
                            "Airports": [
                                {
                                    "Id": "TCU",
                                    "Name": "Thaba Nchu",
                                    "CityId": "TCUA",
                                    "CountryId": "ZA",
                                    "Location": "26.866667, -29.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MZQA",
                            "Name": "Mkuze",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "32.033333, -27.616667",
                            "IataCode": "MZQ",
                            "Airports": [
                                {
                                    "Id": "MZQ",
                                    "Name": "Mkuze",
                                    "CityId": "MZQA",
                                    "CountryId": "ZA",
                                    "Location": "32.05, -27.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MZYA",
                            "Name": "Mossel Bay",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "22.133333, -34.183333",
                            "IataCode": "MZY",
                            "Airports": [
                                {
                                    "Id": "MZY",
                                    "Name": "Mossel Bay",
                                    "CityId": "MZYA",
                                    "CountryId": "ZA",
                                    "Location": "22.083333, -34.183333"
                                }
                            ]
                        },
                        {
                            "Id": "NCSA",
                            "Name": "Newcastle",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.933333, -27.75",
                            "IataCode": "NCS",
                            "Airports": [
                                {
                                    "Id": "NCS",
                                    "Name": "Newcastle",
                                    "CityId": "NCSA",
                                    "CountryId": "ZA",
                                    "Location": "29.99, -27.774167"
                                }
                            ]
                        },
                        {
                            "Id": "PBZA",
                            "Name": "Plettenberg Bay",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "23.366667, -34.05",
                            "IataCode": "PBZ",
                            "Airports": [
                                {
                                    "Id": "PBZ",
                                    "Name": "Plettenberg Bay",
                                    "CityId": "PBZA",
                                    "CountryId": "ZA",
                                    "Location": "23.329167, -34.0875"
                                }
                            ]
                        },
                        {
                            "Id": "PHWA",
                            "Name": "Phalaborwa",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.116667, -23.95",
                            "IataCode": "PHW",
                            "Airports": [
                                {
                                    "Id": "PHW",
                                    "Name": "Phalaborwa",
                                    "CityId": "PHWA",
                                    "CountryId": "ZA",
                                    "Location": "31.156111, -23.933611"
                                }
                            ]
                        },
                        {
                            "Id": "PRKA",
                            "Name": "Prieska",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "22.75, -29.666667",
                            "IataCode": "PRK",
                            "Airports": [
                                {
                                    "Id": "PRK",
                                    "Name": "Prieska",
                                    "CityId": "PRKA",
                                    "CountryId": "ZA",
                                    "Location": "22.7, -29.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RODA",
                            "Name": "Robertson",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "19.883333, -33.8",
                            "IataCode": "ROD",
                            "Airports": [
                                {
                                    "Id": "ROD",
                                    "Name": "Robertson",
                                    "CityId": "RODA",
                                    "CountryId": "ZA",
                                    "Location": "19.9, -33.816667"
                                }
                            ]
                        },
                        {
                            "Id": "RVOA",
                            "Name": "Reivilo",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "24.2, -27.566667",
                            "IataCode": "RVO",
                            "Airports": [
                                {
                                    "Id": "RVO",
                                    "Name": "Reivilo",
                                    "CityId": "RVOA",
                                    "CountryId": "ZA",
                                    "Location": "24.133333, -27.6"
                                }
                            ]
                        },
                        {
                            "Id": "SISA",
                            "Name": "Sishen",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "22.983333, -27.783333",
                            "IataCode": "SIS",
                            "Airports": [
                                {
                                    "Id": "SIS",
                                    "Name": "Sishen",
                                    "CityId": "SISA",
                                    "CountryId": "ZA",
                                    "Location": "22.99928, -27.64861"
                                }
                            ]
                        },
                        {
                            "Id": "SZKA",
                            "Name": "Skukuza",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.6, -24.983333",
                            "IataCode": "SZK",
                            "Airports": [
                                {
                                    "Id": "SZK",
                                    "Name": "Skukuza",
                                    "CityId": "SZKA",
                                    "CountryId": "ZA",
                                    "Location": "31.586111, -24.9625"
                                }
                            ]
                        },
                        {
                            "Id": "UTTA",
                            "Name": "Umtata",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "28.783333, -31.583333",
                            "IataCode": "UTT",
                            "Airports": [
                                {
                                    "Id": "UTT",
                                    "Name": "Umtata",
                                    "CityId": "UTTA",
                                    "CountryId": "ZA",
                                    "Location": "28.67569, -31.547527"
                                }
                            ]
                        },
                        {
                            "Id": "THYA",
                            "Name": "Thohoyandou",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.483333, -22.95",
                            "IataCode": "THY",
                            "Airports": [
                                {
                                    "Id": "THY",
                                    "Name": "Thohoyandou",
                                    "CityId": "THYA",
                                    "CountryId": "ZA",
                                    "Location": "30.55, -22.883333"
                                }
                            ]
                        },
                        {
                            "Id": "VREA",
                            "Name": "Vredendal",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "18.5, -31.666667",
                            "IataCode": "VRE",
                            "Airports": [
                                {
                                    "Id": "VRE",
                                    "Name": "Vredendal",
                                    "CityId": "VREA",
                                    "CountryId": "ZA",
                                    "Location": "18.5, -31.666667"
                                }
                            ]
                        },
                        {
                            "Id": "WELA",
                            "Name": "Welkom",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.733333, -27.983333",
                            "IataCode": "WEL",
                            "Airports": [
                                {
                                    "Id": "WEL",
                                    "Name": "Welkom",
                                    "CityId": "WELA",
                                    "CountryId": "ZA",
                                    "Location": "26.75, -28"
                                }
                            ]
                        },
                        {
                            "Id": "TSDA",
                            "Name": "Tshipise",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.166667, -22.6",
                            "IataCode": "TSD",
                            "Airports": [
                                {
                                    "Id": "TSD",
                                    "Name": "Tshipise",
                                    "CityId": "TSDA",
                                    "CountryId": "ZA",
                                    "Location": "30.166667, -22.616667"
                                }
                            ]
                        },
                        {
                            "Id": "UTEA",
                            "Name": "Butterworth",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "28.15, -32.333333",
                            "IataCode": "UTE",
                            "Airports": [
                                {
                                    "Id": "UTE",
                                    "Name": "Butterworth",
                                    "CityId": "UTEA",
                                    "CountryId": "ZA",
                                    "Location": "28.066667, -32.383333"
                                }
                            ]
                        },
                        {
                            "Id": "UTWA",
                            "Name": "Queenstown",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "26.883333, -31.9",
                            "IataCode": "UTW",
                            "Airports": [
                                {
                                    "Id": "UTW",
                                    "Name": "Queenstown",
                                    "CityId": "UTWA",
                                    "CountryId": "ZA",
                                    "Location": "26.866667, -31.866667"
                                }
                            ]
                        },
                        {
                            "Id": "GSSA",
                            "Name": "Sabi Sabi",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "30.783333, -25.1",
                            "IataCode": "GSS",
                            "Airports": [
                                {
                                    "Id": "GSS",
                                    "Name": "Sabi Sabi",
                                    "CityId": "GSSA",
                                    "CountryId": "ZA",
                                    "Location": "30.8, -25.116667"
                                }
                            ]
                        },
                        {
                            "Id": "NGLA",
                            "Name": "Ngala",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "21.483333, 2.45",
                            "IataCode": "NGL",
                            "Airports": [
                                {
                                    "Id": "NGL",
                                    "Name": "Ngala",
                                    "CityId": "NGLA",
                                    "CountryId": "ZA",
                                    "Location": "21.55, 2.45"
                                }
                            ]
                        },
                        {
                            "Id": "WKFA",
                            "Name": "Waterkloof",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "25.283333, -30.3",
                            "IataCode": "WKF",
                            "Airports": [
                                {
                                    "Id": "WKF",
                                    "Name": "Waterkloof",
                                    "CityId": "WKFA",
                                    "CountryId": "ZA",
                                    "Location": "25.3, -30.316667"
                                }
                            ]
                        },
                        {
                            "Id": "MBMA",
                            "Name": "Mkambati",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.95, -31.3",
                            "IataCode": "MBM",
                            "Airports": [
                                {
                                    "Id": "MBM",
                                    "Name": "Mkambati",
                                    "CityId": "MBMA",
                                    "CountryId": "ZA",
                                    "Location": "29.966667, -31.283333"
                                }
                            ]
                        },
                        {
                            "Id": "KHOA",
                            "Name": "Gottenburg",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.433333, -24.6",
                            "IataCode": "KHO",
                            "Airports": [
                                {
                                    "Id": "KHO",
                                    "Name": "Khoka Moya",
                                    "CityId": "KHOA",
                                    "CountryId": "ZA",
                                    "Location": "31.416667, -24.583333"
                                }
                            ]
                        },
                        {
                            "Id": "DUKA",
                            "Name": "Mtubatuba",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "32.183333, -28.416667",
                            "IataCode": "DUK",
                            "Airports": [
                                {
                                    "Id": "DUK",
                                    "Name": "Dukuduk",
                                    "CityId": "DUKA",
                                    "CountryId": "ZA",
                                    "Location": "32.234167, -28.366667"
                                }
                            ]
                        },
                        {
                            "Id": "TDTA",
                            "Name": "Welverdiend",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.316667, -24.566667",
                            "IataCode": "TDT",
                            "Airports": [
                                {
                                    "Id": "TDT",
                                    "Name": "Tanda Tula",
                                    "CityId": "TDTA",
                                    "CountryId": "ZA",
                                    "Location": "31.3, -24.533333"
                                }
                            ]
                        },
                        {
                            "Id": "OVGA",
                            "Name": "Waenhuiskrans",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "20.233333, -34.666667",
                            "IataCode": "OVG",
                            "Airports": [
                                {
                                    "Id": "OVG",
                                    "Name": "Overberg",
                                    "CityId": "OVGA",
                                    "CountryId": "ZA",
                                    "Location": "20.235833, -34.551667"
                                }
                            ]
                        },
                        {
                            "Id": "LDZA",
                            "Name": "Varty",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.5, -24.783333",
                            "IataCode": "LDZ",
                            "Airports": [
                                {
                                    "Id": "LDZ",
                                    "Name": "Londolozi",
                                    "CityId": "LDZA",
                                    "CountryId": "ZA",
                                    "Location": "31.5, -24.8"
                                }
                            ]
                        },
                        {
                            "Id": "MZFA",
                            "Name": "Trennerys",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "28.433333, -32.65",
                            "IataCode": "MZF",
                            "Airports": [
                                {
                                    "Id": "MZF",
                                    "Name": "Mzamba (Wild Coast Sun)",
                                    "CityId": "MZFA",
                                    "CountryId": "ZA",
                                    "Location": "28.5, -32.75"
                                }
                            ]
                        },
                        {
                            "Id": "AAMA",
                            "Name": "Sparta",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.5, -24.8",
                            "IataCode": "AAM",
                            "Airports": [
                                {
                                    "Id": "AAM",
                                    "Name": "Mala Mala",
                                    "CityId": "AAMA",
                                    "CountryId": "ZA",
                                    "Location": "31.533333, -24.8"
                                }
                            ]
                        },
                        {
                            "Id": "ASSA",
                            "Name": "Phungwe",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.466667, -24.7",
                            "IataCode": "ASS",
                            "Airports": [
                                {
                                    "Id": "ASS",
                                    "Name": "Arathusa Safari Lodge",
                                    "CityId": "ASSA",
                                    "CountryId": "ZA",
                                    "Location": "31.522499, -24.744167"
                                }
                            ]
                        },
                        {
                            "Id": "ULXA",
                            "Name": "Meetse",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.316667, -24.833333",
                            "IataCode": "ULX",
                            "Airports": [
                                {
                                    "Id": "ULX",
                                    "Name": "Ulusaba",
                                    "CityId": "ULXA",
                                    "CountryId": "ZA",
                                    "Location": "31.35, -24.783333"
                                }
                            ]
                        },
                        {
                            "Id": "HRSA",
                            "Name": "Matla",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "29.133333, -26.283333",
                            "IataCode": "HRS",
                            "Airports": [
                                {
                                    "Id": "HRS",
                                    "Name": "Harrismith",
                                    "CityId": "HRSA",
                                    "CountryId": "ZA",
                                    "Location": "29.1, -26.233333"
                                }
                            ]
                        },
                        {
                            "Id": "SSXZ",
                            "Name": "Justicia",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.4, -24.866667",
                            "IataCode": "SSX",
                            "Airports": [
                                {
                                    "Id": "SSX",
                                    "Name": "Singita Safari Lodge",
                                    "CityId": "SSXZ",
                                    "CountryId": "ZA",
                                    "Location": "31.422337, -24.803064"
                                }
                            ]
                        },
                        {
                            "Id": "PZLA",
                            "Name": "Golweni",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "32.3, -27.833333",
                            "IataCode": "PZL",
                            "Airports": [
                                {
                                    "Id": "PZL",
                                    "Name": "Phinda",
                                    "CityId": "PZLA",
                                    "CountryId": "ZA",
                                    "Location": "32.3, -27.866667"
                                }
                            ]
                        },
                        {
                            "Id": "INYA",
                            "Name": "Exeter",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.35, -24.783333",
                            "IataCode": "INY",
                            "Airports": [
                                {
                                    "Id": "INY",
                                    "Name": "Inyati",
                                    "CityId": "INYA",
                                    "CountryId": "ZA",
                                    "Location": "31.383333, -24.783333"
                                }
                            ]
                        },
                        {
                            "Id": "ZECA",
                            "Name": "Clydesdale",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "27.916667, -26.933333",
                            "IataCode": "ZEC",
                            "Airports": [
                                {
                                    "Id": "ZEC",
                                    "Name": "Secunda",
                                    "CityId": "ZECA",
                                    "CountryId": "ZA",
                                    "Location": "29.1701, -26.5241"
                                }
                            ]
                        },
                        {
                            "Id": "KIGA",
                            "Name": "Koiingnaas",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "17.283333, -30.2",
                            "IataCode": "KIG",
                            "Airports": [
                                {
                                    "Id": "KIG",
                                    "Name": "Koinghaas",
                                    "CityId": "KIGA",
                                    "CountryId": "ZA",
                                    "Location": "17.266667, -30.2"
                                }
                            ]
                        },
                        {
                            "Id": "SDBA",
                            "Name": "Donkergat",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "18, -33.066667",
                            "IataCode": "SDB",
                            "Airports": [
                                {
                                    "Id": "SDB",
                                    "Name": "Saldanha Bay",
                                    "CityId": "SDBA",
                                    "CountryId": "ZA",
                                    "Location": "18, -33.066667"
                                }
                            ]
                        },
                        {
                            "Id": "MWRA",
                            "Name": "Kruger Park",
                            "SingleAirportCity": true,
                            "CountryId": "ZA",
                            "Location": "31.3094, -24.3319",
                            "IataCode": "MWR",
                            "Airports": [
                                {
                                    "Id": "MWR",
                                    "Name": "Motswari",
                                    "CityId": "MWRA",
                                    "CountryId": "ZA",
                                    "Location": "31.3864, -24.1903"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MA",
                    "Name": "Morocco",
                    "CurrencyId": "MAD",
                    "Cities": [
                        {
                            "Id": "AGAD",
                            "Name": "Agadir",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-9.5999999, 30.400002",
                            "IataCode": "AGA",
                            "Airports": [
                                {
                                    "Id": "AGA",
                                    "Name": "Agadir",
                                    "CityId": "AGAD",
                                    "CountryId": "MA",
                                    "Location": "-9.412708, 30.325619"
                                }
                            ]
                        },
                        {
                            "Id": "ALHO",
                            "Name": "Al Hoceima",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-3.93, 35.24",
                            "IataCode": "AHU",
                            "Airports": [
                                {
                                    "Id": "AHU",
                                    "Name": "Al Hoceima Charif Idr",
                                    "CityId": "ALHO",
                                    "CountryId": "MA",
                                    "Location": "-3.836944, 35.179722"
                                }
                            ]
                        },
                        {
                            "Id": "CASA",
                            "Name": "Casablanca",
                            "SingleAirportCity": false,
                            "CountryId": "MA",
                            "Location": "-7.6100013, 33.590008",
                            "IataCode": "CAS",
                            "Airports": [
                                {
                                    "Id": "CMN",
                                    "Name": "Casablanca Mohamed V.",
                                    "CityId": "CASA",
                                    "CountryId": "MA",
                                    "Location": "-7.586667, 33.366667"
                                },
                                {
                                    "Id": "CAS",
                                    "Name": "Casablanca Anfa",
                                    "CityId": "CASA",
                                    "CountryId": "MA",
                                    "Location": "-7.663056, 33.559722"
                                }
                            ]
                        },
                        {
                            "Id": "LAAY",
                            "Name": "Laayoune Hassan I",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-13.1625, 27.135",
                            "IataCode": "EUN",
                            "Airports": [
                                {
                                    "Id": "EUN",
                                    "Name": "Laayoune Hassan I",
                                    "CityId": "LAAY",
                                    "CountryId": "MA",
                                    "Location": "-13.216667, 27.166667"
                                }
                            ]
                        },
                        {
                            "Id": "ESSA",
                            "Name": "Essaouira",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-9.7599999, 31.51",
                            "IataCode": "ESU",
                            "Airports": [
                                {
                                    "Id": "ESU",
                                    "Name": "Essaouira",
                                    "CityId": "ESSA",
                                    "CountryId": "MA",
                                    "Location": "-9.681667, 31.3975"
                                }
                            ]
                        },
                        {
                            "Id": "FEZS",
                            "Name": "Fes",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-4.9799995, 34.05",
                            "IataCode": "FEZ",
                            "Airports": [
                                {
                                    "Id": "FEZ",
                                    "Name": "Fes-Saïss",
                                    "CityId": "FEZS",
                                    "CountryId": "MA",
                                    "Location": "-4.966667, 33.933333"
                                }
                            ]
                        },
                        {
                            "Id": "NADO",
                            "Name": "Nador",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-2.93, 35.17",
                            "IataCode": "NDR",
                            "Airports": [
                                {
                                    "Id": "NDR",
                                    "Name": "Nador",
                                    "CityId": "NADO",
                                    "CountryId": "MA",
                                    "Location": "-3.028333, 34.988889"
                                }
                            ]
                        },
                        {
                            "Id": "NNAA",
                            "Name": "Kenitra",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-6.5700003, 34.26",
                            "IataCode": "NNA",
                            "Airports": [
                                {
                                    "Id": "NNA",
                                    "Name": "Kenitra",
                                    "CityId": "NNAA",
                                    "CountryId": "MA",
                                    "Location": "-6.59588, 34.29886"
                                }
                            ]
                        },
                        {
                            "Id": "OUJD",
                            "Name": "Oujda",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-1.9100002, 34.68",
                            "IataCode": "OUD",
                            "Airports": [
                                {
                                    "Id": "OUD",
                                    "Name": "Oujda L. Angades",
                                    "CityId": "OUJD",
                                    "CountryId": "MA",
                                    "Location": "-1.933333, 34.783333"
                                }
                            ]
                        },
                        {
                            "Id": "OUAR",
                            "Name": "Ouarzazate",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-6.9100002, 30.92",
                            "IataCode": "OZZ",
                            "Airports": [
                                {
                                    "Id": "OZZ",
                                    "Name": "Ouarzazate",
                                    "CityId": "OUAR",
                                    "CountryId": "MA",
                                    "Location": "-6.916667, 30.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MARR",
                            "Name": "Marrakech Menara",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-7.9920999, 31.632002",
                            "IataCode": "RAK",
                            "Airports": [
                                {
                                    "Id": "RAK",
                                    "Name": "Marrakech Menara",
                                    "CityId": "MARR",
                                    "CountryId": "MA",
                                    "Location": "-8.038333, 31.608333"
                                }
                            ]
                        },
                        {
                            "Id": "RABA",
                            "Name": "Rabat",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-6.8300009, 34.020004",
                            "IataCode": "RBA",
                            "Airports": [
                                {
                                    "Id": "RBA",
                                    "Name": "Rabat",
                                    "CityId": "RABA",
                                    "CountryId": "MA",
                                    "Location": "-6.756944, 34.05"
                                }
                            ]
                        },
                        {
                            "Id": "SIDI",
                            "Name": "Sidi Ifni",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-10.16, 29.38",
                            "IataCode": "SII",
                            "Airports": [
                                {
                                    "Id": "SII",
                                    "Name": "Sidi Ifni",
                                    "CityId": "SIDI",
                                    "CountryId": "MA",
                                    "Location": "-10.1833, 29.3667"
                                }
                            ]
                        },
                        {
                            "Id": "TANG",
                            "Name": "Tangier",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-5.8099986, 35.78",
                            "IataCode": "TNG",
                            "Airports": [
                                {
                                    "Id": "TNG",
                                    "Name": "Tangier Boukhalef",
                                    "CityId": "TANG",
                                    "CountryId": "MA",
                                    "Location": "-5.916667, 35.716667"
                                }
                            ]
                        },
                        {
                            "Id": "TANT",
                            "Name": "Tan Tan",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-11.1, 28.43",
                            "IataCode": "TTA",
                            "Airports": [
                                {
                                    "Id": "TTA",
                                    "Name": "Tan Tan",
                                    "CityId": "TANT",
                                    "CountryId": "MA",
                                    "Location": "-11.16135, 28.44819"
                                }
                            ]
                        },
                        {
                            "Id": "TETU",
                            "Name": "Tetuan S. Ramel",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-5.3699997, 35.57",
                            "IataCode": "TTU",
                            "Airports": [
                                {
                                    "Id": "TTU",
                                    "Name": "Tetuan S. Ramel",
                                    "CityId": "TETU",
                                    "CountryId": "MA",
                                    "Location": "-5.316667, 35.583333"
                                }
                            ]
                        },
                        {
                            "Id": "DAKH",
                            "Name": "Dakhla",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-15.94186, 23.718621",
                            "IataCode": "VIL",
                            "Airports": [
                                {
                                    "Id": "VIL",
                                    "Name": "Dakhla",
                                    "CityId": "DAKH",
                                    "CountryId": "MA",
                                    "Location": "-15.935, 23.713333"
                                }
                            ]
                        },
                        {
                            "Id": "ERRA",
                            "Name": "Errachidia",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-4.42, 31.93",
                            "IataCode": "ERH",
                            "Airports": [
                                {
                                    "Id": "ERH",
                                    "Name": "Errachidia",
                                    "CityId": "ERRA",
                                    "CountryId": "MA",
                                    "Location": "-4.4, 31.95"
                                }
                            ]
                        },
                        {
                            "Id": "SAFI",
                            "Name": "Safi",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-9.2299999, 32.3",
                            "IataCode": "SFI",
                            "Airports": [
                                {
                                    "Id": "SFI",
                                    "Name": "Safi",
                                    "CityId": "SAFI",
                                    "CountryId": "MA",
                                    "Location": "-9.268084, 32.330803"
                                }
                            ]
                        },
                        {
                            "Id": "SMAR",
                            "Name": "Smara",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-11.678056, 26.679167",
                            "IataCode": "SMW",
                            "Airports": [
                                {
                                    "Id": "SMW",
                                    "Name": "Smara",
                                    "CityId": "SMAR",
                                    "CountryId": "MA",
                                    "Location": "-11.683333, 26.733333"
                                }
                            ]
                        },
                        {
                            "Id": "TARF",
                            "Name": "Tarfaya",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-12.843333, 27.907222",
                            "IataCode": "TFY",
                            "Airports": [
                                {
                                    "Id": "TFY",
                                    "Name": "Tarfaya",
                                    "CityId": "TARF",
                                    "CountryId": "MA",
                                    "Location": "-12.916667, 27.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MEKN",
                            "Name": "Meknes Mezergue",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-5.5499993, 33.9",
                            "IataCode": "MEK",
                            "Airports": [
                                {
                                    "Id": "MEK",
                                    "Name": "Meknes Mezergue",
                                    "CityId": "MEKN",
                                    "CountryId": "MA",
                                    "Location": "-5.518398, 33.872767"
                                }
                            ]
                        },
                        {
                            "Id": "ZAGO",
                            "Name": "Zagora",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-5.8299999, 30.31",
                            "IataCode": "OZG",
                            "Airports": [
                                {
                                    "Id": "OZG",
                                    "Name": "Zagora",
                                    "CityId": "ZAGO",
                                    "CountryId": "MA",
                                    "Location": "-5.852456, 30.267143"
                                }
                            ]
                        },
                        {
                            "Id": "BENI",
                            "Name": "Beni Mellal",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-6.3500003, 32.33",
                            "IataCode": "BEM",
                            "Airports": [
                                {
                                    "Id": "BEM",
                                    "Name": "Beni Mellal Airport",
                                    "CityId": "BENI",
                                    "CountryId": "MA",
                                    "Location": "-6.323936, 32.397247"
                                }
                            ]
                        },
                        {
                            "Id": "GOUL",
                            "Name": "Goulimime",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-10.06, 28.98",
                            "IataCode": "GLN",
                            "Airports": [
                                {
                                    "Id": "GLN",
                                    "Name": "Goulimime",
                                    "CityId": "GOUL",
                                    "CountryId": "MA",
                                    "Location": "-10.066667, 29.016667"
                                }
                            ]
                        },
                        {
                            "Id": "UARM",
                            "Name": "Bouarfa",
                            "SingleAirportCity": true,
                            "CountryId": "MA",
                            "Location": "-1.95, 32.53",
                            "IataCode": "UAR",
                            "Airports": [
                                {
                                    "Id": "UAR",
                                    "Name": "Bouarfa",
                                    "CityId": "UARM",
                                    "CountryId": "MA",
                                    "Location": "-1.9775, 32.518889"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UG",
                    "Name": "Uganda",
                    "CurrencyId": "UGX",
                    "Cities": [
                        {
                            "Id": "KCUA",
                            "Name": "Masindi",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "31.715, 1.6744444",
                            "IataCode": "KCU",
                            "Airports": [
                                {
                                    "Id": "KCU",
                                    "Name": "Masindi",
                                    "CityId": "KCUA",
                                    "CountryId": "UG",
                                    "Location": "31.716667, 1.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KSEA",
                            "Name": "Kasese",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "29.988333, 0.23",
                            "IataCode": "KSE",
                            "Airports": [
                                {
                                    "Id": "KSE",
                                    "Name": "Kasese",
                                    "CityId": "KSEA",
                                    "CountryId": "UG",
                                    "Location": "30.100833, 0.185556"
                                }
                            ]
                        },
                        {
                            "Id": "RUAA",
                            "Name": "Arua",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "30.930833, 3.0191667",
                            "IataCode": "RUA",
                            "Airports": [
                                {
                                    "Id": "RUA",
                                    "Name": "Arua",
                                    "CityId": "RUAA",
                                    "CountryId": "UG",
                                    "Location": "30.933333, 3.033333"
                                }
                            ]
                        },
                        {
                            "Id": "SRTA",
                            "Name": "Soroti",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "33.616389, 1.6855556",
                            "IataCode": "SRT",
                            "Airports": [
                                {
                                    "Id": "SRT",
                                    "Name": "Soroti",
                                    "CityId": "SRTA",
                                    "CountryId": "UG",
                                    "Location": "33.616667, 1.7"
                                }
                            ]
                        },
                        {
                            "Id": "MBQA",
                            "Name": "Mbarara",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "30.675833, -0.6583333",
                            "IataCode": "MBQ",
                            "Airports": [
                                {
                                    "Id": "MBQ",
                                    "Name": "Mbarara",
                                    "CityId": "MBQA",
                                    "CountryId": "UG",
                                    "Location": "30.65, -0.616667"
                                }
                            ]
                        },
                        {
                            "Id": "OYGA",
                            "Name": "Moyo",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "31.753611, 3.6280556",
                            "IataCode": "OYG",
                            "Airports": [
                                {
                                    "Id": "OYG",
                                    "Name": "Moyo",
                                    "CityId": "OYGA",
                                    "CountryId": "UG",
                                    "Location": "31.716667, 3.65"
                                }
                            ]
                        },
                        {
                            "Id": "PAFA",
                            "Name": "Pakuba",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "31.481944, 2.3180556",
                            "IataCode": "PAF",
                            "Airports": [
                                {
                                    "Id": "PAF",
                                    "Name": "Pakuba",
                                    "CityId": "PAFA",
                                    "CountryId": "UG",
                                    "Location": "31.616667, 2.2"
                                }
                            ]
                        },
                        {
                            "Id": "TRYA",
                            "Name": "Tororo",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "34.181111, 0.6847222",
                            "IataCode": "TRY",
                            "Airports": [
                                {
                                    "Id": "TRY",
                                    "Name": "Tororo",
                                    "CityId": "TRYA",
                                    "CountryId": "UG",
                                    "Location": "34.183333, 0.7"
                                }
                            ]
                        },
                        {
                            "Id": "ULUA",
                            "Name": "Gulu",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "32.305556, 2.7666667",
                            "IataCode": "ULU",
                            "Airports": [
                                {
                                    "Id": "ULU",
                                    "Name": "Gulu",
                                    "CityId": "ULUA",
                                    "CountryId": "UG",
                                    "Location": "32.3, 2.783333"
                                }
                            ]
                        },
                        {
                            "Id": "EBBA",
                            "Name": "Entebbe",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "32.446944, 0.0644444",
                            "IataCode": "EBB",
                            "Airports": [
                                {
                                    "Id": "EBB",
                                    "Name": "Entebbe",
                                    "CityId": "EBBA",
                                    "CountryId": "UG",
                                    "Location": "32.438056, 0.0425"
                                }
                            ]
                        },
                        {
                            "Id": "KHXA",
                            "Name": "Kihihi",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "29.7, -0.75",
                            "IataCode": "KHX",
                            "Airports": [
                                {
                                    "Id": "KHX",
                                    "Name": "Kihihi",
                                    "CityId": "KHXA",
                                    "CountryId": "UG",
                                    "Location": "29.699444, -0.716388"
                                }
                            ]
                        },
                        {
                            "Id": "JINB",
                            "Name": "Jinja",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "33.204167, 0.4244444",
                            "IataCode": "JIN",
                            "Airports": [
                                {
                                    "Id": "JIN",
                                    "Name": "Jinja",
                                    "CityId": "JINB",
                                    "CountryId": "UG",
                                    "Location": "33.192222, 0.456389"
                                }
                            ]
                        },
                        {
                            "Id": "KBGA",
                            "Name": "Paroketto",
                            "SingleAirportCity": true,
                            "CountryId": "UG",
                            "Location": "31.45, 2.4166667",
                            "IataCode": "KBG",
                            "Airports": [
                                {
                                    "Id": "KBG",
                                    "Name": "Kabalega Falls",
                                    "CityId": "KBGA",
                                    "CountryId": "UG",
                                    "Location": "31.496389, 2.329444"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SS",
                    "Name": "South Sudan",
                    "CurrencyId": "SDG",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "WUUA",
                            "Name": "Wau",
                            "SingleAirportCity": true,
                            "CountryId": "SS",
                            "Location": "28, 7.7",
                            "IataCode": "WUU",
                            "Airports": [
                                {
                                    "Id": "WUU",
                                    "Name": "Wau",
                                    "CityId": "WUUA",
                                    "CountryId": "SS",
                                    "Location": "27.985278, 7.746389"
                                }
                            ]
                        },
                        {
                            "Id": "JUBA",
                            "Name": "Juba",
                            "SingleAirportCity": true,
                            "CountryId": "SS",
                            "Location": "31.616667, 4.85",
                            "IataCode": "JUB",
                            "Airports": [
                                {
                                    "Id": "JUB",
                                    "Name": "Juba",
                                    "CityId": "JUBA",
                                    "CountryId": "SS",
                                    "Location": "31.603889, 4.870556"
                                }
                            ]
                        },
                        {
                            "Id": "MAKA",
                            "Name": "Malakal",
                            "SingleAirportCity": true,
                            "CountryId": "SS",
                            "Location": "31.656111, 9.5369444",
                            "IataCode": "MAK",
                            "Airports": [
                                {
                                    "Id": "MAK",
                                    "Name": "Malakal",
                                    "CityId": "MAKA",
                                    "CountryId": "SS",
                                    "Location": "31.644444, 9.555556"
                                }
                            ]
                        },
                        {
                            "Id": "RBXA",
                            "Name": "Rumbek",
                            "SingleAirportCity": true,
                            "CountryId": "SS",
                            "Location": "29.683333, 6.8",
                            "IataCode": "RBX",
                            "Airports": [
                                {
                                    "Id": "RBX",
                                    "Name": "Rumbek",
                                    "CityId": "RBXA",
                                    "CountryId": "SS",
                                    "Location": "29.6506, 6.8339"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "X",
            "Name": "Arctic Region (North America)",
            "Countries": [
                {
                    "Id": "GL",
                    "Name": "Greenland",
                    "CurrencyId": "DKK",
                    "Cities": [
                        {
                            "Id": "AGMA",
                            "Name": "Tasiilaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-37.633333, 65.6",
                            "IataCode": "AGM",
                            "Airports": [
                                {
                                    "Id": "AGM",
                                    "Name": "Tasiilaq",
                                    "CityId": "AGMA",
                                    "CountryId": "GL",
                                    "Location": "-37.683333, 65.6"
                                }
                            ]
                        },
                        {
                            "Id": "JRKA",
                            "Name": "Arsuk",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-48.45, 61.166667",
                            "IataCode": "JRK",
                            "Airports": [
                                {
                                    "Id": "JRK",
                                    "Name": "Arsuk",
                                    "CityId": "JRKA",
                                    "CountryId": "GL",
                                    "Location": "-48.416667, 61.166667"
                                }
                            ]
                        },
                        {
                            "Id": "DUNA",
                            "Name": "Dundas",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-68.775556, 76.577222",
                            "IataCode": "DUN",
                            "Airports": [
                                {
                                    "Id": "DUN",
                                    "Name": "Dundas",
                                    "CityId": "DUNA",
                                    "CountryId": "GL",
                                    "Location": "-68.775556, 76.577222"
                                }
                            ]
                        },
                        {
                            "Id": "GOHA",
                            "Name": "Nuuk",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.738396, 64.173772",
                            "IataCode": "GOH",
                            "Airports": [
                                {
                                    "Id": "GOH",
                                    "Name": "Nuuk",
                                    "CityId": "GOHA",
                                    "CountryId": "GL",
                                    "Location": "-51.675508, 64.191189"
                                }
                            ]
                        },
                        {
                            "Id": "JAVA",
                            "Name": "Ilulissat",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.1, 69.216667",
                            "IataCode": "JAV",
                            "Airports": [
                                {
                                    "Id": "JAV",
                                    "Name": "Ilulissat",
                                    "CityId": "JAVA",
                                    "CountryId": "GL",
                                    "Location": "-51.061014, 69.24132"
                                }
                            ]
                        },
                        {
                            "Id": "JCHA",
                            "Name": "Qasigiannguit",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.183333, 68.816667",
                            "IataCode": "JCH",
                            "Airports": [
                                {
                                    "Id": "JCH",
                                    "Name": "Qasigiannguit",
                                    "CityId": "JCHA",
                                    "CountryId": "GL",
                                    "Location": "-51, 68.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JEGA",
                            "Name": "Aasiaat",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.866666, 68.716667",
                            "IataCode": "JEG",
                            "Airports": [
                                {
                                    "Id": "JEG",
                                    "Name": "Aasiaat",
                                    "CityId": "JEGA",
                                    "CountryId": "GL",
                                    "Location": "-52.78553, 68.722434"
                                }
                            ]
                        },
                        {
                            "Id": "JFRA",
                            "Name": "Paamiut",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-49.75, 62",
                            "IataCode": "JFR",
                            "Airports": [
                                {
                                    "Id": "JFR",
                                    "Name": "Paamiut",
                                    "CityId": "JFRA",
                                    "CountryId": "GL",
                                    "Location": "-49.700278, 62.000278"
                                }
                            ]
                        },
                        {
                            "Id": "JGOA",
                            "Name": "Qeqertarsuaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.55, 69.25",
                            "IataCode": "JGO",
                            "Airports": [
                                {
                                    "Id": "JGO",
                                    "Name": "Qeqertarsuaq",
                                    "CityId": "JGOA",
                                    "CountryId": "GL",
                                    "Location": "-53.566667, 69.25"
                                }
                            ]
                        },
                        {
                            "Id": "JHSA",
                            "Name": "Sisimiut",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.683333, 66.933333",
                            "IataCode": "JHS",
                            "Airports": [
                                {
                                    "Id": "JHS",
                                    "Name": "Sisimiut",
                                    "CityId": "JHSA",
                                    "CountryId": "GL",
                                    "Location": "-53.704167, 66.936944"
                                }
                            ]
                        },
                        {
                            "Id": "JJUA",
                            "Name": "Qaqortoq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-46.033333, 60.716667",
                            "IataCode": "JJU",
                            "Airports": [
                                {
                                    "Id": "JJU",
                                    "Name": "Qaqortoq",
                                    "CityId": "JJUA",
                                    "CountryId": "GL",
                                    "Location": "-46.033333, 60.716667"
                                }
                            ]
                        },
                        {
                            "Id": "JNNA",
                            "Name": "Nanortalik",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-45.216667, 60.116667",
                            "IataCode": "JNN",
                            "Airports": [
                                {
                                    "Id": "JNN",
                                    "Name": "Nanortalik",
                                    "CityId": "JNNA",
                                    "CountryId": "GL",
                                    "Location": "-45.233611, 60.134167"
                                }
                            ]
                        },
                        {
                            "Id": "JNSA",
                            "Name": "Narsaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-46.05, 60.916667",
                            "IataCode": "JNS",
                            "Airports": [
                                {
                                    "Id": "JNS",
                                    "Name": "Narsaq",
                                    "CityId": "JNSA",
                                    "CountryId": "GL",
                                    "Location": "-46.05, 60.916667"
                                }
                            ]
                        },
                        {
                            "Id": "JSUA",
                            "Name": "Maniitsoq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.9, 65.416667",
                            "IataCode": "JSU",
                            "Airports": [
                                {
                                    "Id": "JSU",
                                    "Name": "Maniitsoq",
                                    "CityId": "JSUA",
                                    "CountryId": "GL",
                                    "Location": "-52.9, 65.416667"
                                }
                            ]
                        },
                        {
                            "Id": "JUVA",
                            "Name": "Upernavik",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-56.166667, 72.783333",
                            "IataCode": "JUV",
                            "Airports": [
                                {
                                    "Id": "JUV",
                                    "Name": "Upernavik",
                                    "CityId": "JUVA",
                                    "CountryId": "GL",
                                    "Location": "-56.130556, 72.787869"
                                }
                            ]
                        },
                        {
                            "Id": "KULU",
                            "Name": "Kulusuk Island",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-37.183334, 65.566667",
                            "IataCode": "KUS",
                            "Airports": [
                                {
                                    "Id": "KUS",
                                    "Name": "Kulusuk Island",
                                    "CityId": "KULU",
                                    "CountryId": "GL",
                                    "Location": "-37.116667, 65.566667"
                                }
                            ]
                        },
                        {
                            "Id": "LLUA",
                            "Name": "Alluitsup Paa",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-45.55, 60.45",
                            "IataCode": "LLU",
                            "Airports": [
                                {
                                    "Id": "LLU",
                                    "Name": "Alluitsup Paa",
                                    "CityId": "LLUA",
                                    "CountryId": "GL",
                                    "Location": "-45.568889, 60.464167"
                                }
                            ]
                        },
                        {
                            "Id": "OBYA",
                            "Name": "Ittoqqortoormiit",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-21.966666, 70.483333",
                            "IataCode": "OBY",
                            "Airports": [
                                {
                                    "Id": "OBY",
                                    "Name": "Ittoqqortoormiit",
                                    "CityId": "OBYA",
                                    "CountryId": "GL",
                                    "Location": "-21.666667, 70.583333"
                                }
                            ]
                        },
                        {
                            "Id": "THUA",
                            "Name": "Pituffik",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-68.842778, 76.547778",
                            "IataCode": "THU",
                            "Airports": [
                                {
                                    "Id": "THU",
                                    "Name": "Pituffik",
                                    "CityId": "THUA",
                                    "CountryId": "GL",
                                    "Location": "-68.7, 76.533333"
                                }
                            ]
                        },
                        {
                            "Id": "NARS",
                            "Name": "Narsarsuaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-45.416667, 61.166667",
                            "IataCode": "UAK",
                            "Airports": [
                                {
                                    "Id": "UAK",
                                    "Name": "Narsarsuaq",
                                    "CityId": "NARS",
                                    "CountryId": "GL",
                                    "Location": "-45.426111, 61.160556"
                                }
                            ]
                        },
                        {
                            "Id": "NAQA",
                            "Name": "Qaanaaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-69.332222, 77.489444",
                            "IataCode": "NAQ",
                            "Airports": [
                                {
                                    "Id": "NAQ",
                                    "Name": "Qaanaaq",
                                    "CityId": "NAQA",
                                    "CountryId": "GL",
                                    "Location": "-69.3887, 77.4886"
                                }
                            ]
                        },
                        {
                            "Id": "XEQA",
                            "Name": "Tasiuasaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-44.783333, 60.183333",
                            "IataCode": "XEQ",
                            "Airports": [
                                {
                                    "Id": "XEQ",
                                    "Name": "Tasiuasaq",
                                    "CityId": "XEQA",
                                    "CountryId": "GL",
                                    "Location": "-44.633333, 60.266667"
                                }
                            ]
                        },
                        {
                            "Id": "SFJA",
                            "Name": "Kangerlussuaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-50.7, 67",
                            "IataCode": "SFJ",
                            "Airports": [
                                {
                                    "Id": "SFJ",
                                    "Name": "Kangerlussuaq",
                                    "CityId": "SFJA",
                                    "CountryId": "GL",
                                    "Location": "-50.719723, 67.012222"
                                }
                            ]
                        },
                        {
                            "Id": "QUPA",
                            "Name": "Saqqaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.95, 70.016667",
                            "IataCode": "QUP",
                            "Airports": [
                                {
                                    "Id": "QUP",
                                    "Name": "Saqqaq",
                                    "CityId": "QUPA",
                                    "CountryId": "GL",
                                    "Location": "-52, 70.05"
                                }
                            ]
                        },
                        {
                            "Id": "XEOA",
                            "Name": "Oqatsut",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.016667, 69.35",
                            "IataCode": "XEO",
                            "Airports": [
                                {
                                    "Id": "XEO",
                                    "Name": "Oqatsut",
                                    "CityId": "XEOA",
                                    "CountryId": "GL",
                                    "Location": "-51, 69.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PQTA",
                            "Name": "Qeqertaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.316667, 70",
                            "IataCode": "PQT",
                            "Airports": [
                                {
                                    "Id": "PQT",
                                    "Name": "Qeqertaq",
                                    "CityId": "PQTA",
                                    "CountryId": "GL",
                                    "Location": "-51.185303, 69.988655"
                                }
                            ]
                        },
                        {
                            "Id": "JQAA",
                            "Name": "Qaarsut",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.65, 70.733333",
                            "IataCode": "JQA",
                            "Airports": [
                                {
                                    "Id": "JQA",
                                    "Name": "Qaarsut",
                                    "CityId": "JQAA",
                                    "CountryId": "GL",
                                    "Location": "-52.702778, 70.731944"
                                }
                            ]
                        },
                        {
                            "Id": "QMKA",
                            "Name": "Niaqornaarsuk",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.866666, 68.233333",
                            "IataCode": "QMK",
                            "Airports": [
                                {
                                    "Id": "QMK",
                                    "Name": "Niaqornaarsuk",
                                    "CityId": "QMKA",
                                    "CountryId": "GL",
                                    "Location": "-52.833333, 68.25"
                                }
                            ]
                        },
                        {
                            "Id": "QPWA",
                            "Name": "Kangaatsiaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.466666, 68.3",
                            "IataCode": "QPW",
                            "Airports": [
                                {
                                    "Id": "QPW",
                                    "Name": "Kangaatsiaq",
                                    "CityId": "QPWA",
                                    "CountryId": "GL",
                                    "Location": "-53.5, 68.25"
                                }
                            ]
                        },
                        {
                            "Id": "QRYA",
                            "Name": "Ikerasaarsuk",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.533333, 68.133333",
                            "IataCode": "QRY",
                            "Airports": [
                                {
                                    "Id": "QRY",
                                    "Name": "Ikerasaarsuk",
                                    "CityId": "QRYA",
                                    "CountryId": "GL",
                                    "Location": "-53.866667, 68.216667"
                                }
                            ]
                        },
                        {
                            "Id": "QJIA",
                            "Name": "Ikamiut",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.833333, 68.633333",
                            "IataCode": "QJI",
                            "Airports": [
                                {
                                    "Id": "QJI",
                                    "Name": "Ikamiut",
                                    "CityId": "QJIA",
                                    "CountryId": "GL",
                                    "Location": "-52, 68.833333"
                                }
                            ]
                        },
                        {
                            "Id": "QFIA",
                            "Name": "Iginniarfik",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.166666, 68.15",
                            "IataCode": "QFI",
                            "Airports": [
                                {
                                    "Id": "QFI",
                                    "Name": "Iginniarfik",
                                    "CityId": "QFIA",
                                    "CountryId": "GL",
                                    "Location": "-53.25, 68.166667"
                                }
                            ]
                        },
                        {
                            "Id": "QJEA",
                            "Name": "Kitsissuarsuit",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.116666, 68.85",
                            "IataCode": "QJE",
                            "Airports": [
                                {
                                    "Id": "QJE",
                                    "Name": "Kitsissuarsuit",
                                    "CityId": "QJEA",
                                    "CountryId": "GL",
                                    "Location": "-53.166667, 68.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JGRA",
                            "Name": "Groennedal",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-48.1, 61.233333",
                            "IataCode": "JGR",
                            "Airports": [
                                {
                                    "Id": "JGR",
                                    "Name": "Groennedal",
                                    "CityId": "JGRA",
                                    "CountryId": "GL",
                                    "Location": "-48.1, 61.233333"
                                }
                            ]
                        },
                        {
                            "Id": "XIQA",
                            "Name": "Ilimanaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-51.116667, 69.083333",
                            "IataCode": "XIQ",
                            "Airports": [
                                {
                                    "Id": "XIQ",
                                    "Name": "Ilimanaq",
                                    "CityId": "XIQA",
                                    "CountryId": "GL",
                                    "Location": "-51, 69"
                                }
                            ]
                        },
                        {
                            "Id": "QGQA",
                            "Name": "Attu",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-53.633333, 67.95",
                            "IataCode": "QGQ",
                            "Airports": [
                                {
                                    "Id": "QGQ",
                                    "Name": "Attu",
                                    "CityId": "QGQA",
                                    "CountryId": "GL",
                                    "Location": "-53.583333, 68"
                                }
                            ]
                        },
                        {
                            "Id": "QCUA",
                            "Name": "Akunnaaq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.333333, 68.75",
                            "IataCode": "QCU",
                            "Airports": [
                                {
                                    "Id": "QCU",
                                    "Name": "Akunnaaq",
                                    "CityId": "QCUA",
                                    "CountryId": "GL",
                                    "Location": "-52.333333, 68.75"
                                }
                            ]
                        },
                        {
                            "Id": "UMDA",
                            "Name": "Sermiarsuit",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-52.117778, 70.668889",
                            "IataCode": "UMD",
                            "Airports": [
                                {
                                    "Id": "UMD",
                                    "Name": "Uummannaq",
                                    "CityId": "UMDA",
                                    "CountryId": "GL",
                                    "Location": "-52.117778, 70.668889"
                                }
                            ]
                        },
                        {
                            "Id": "NEER",
                            "Name": "Ittorisseq",
                            "SingleAirportCity": true,
                            "CountryId": "GL",
                            "Location": "-22.45, 70.45",
                            "IataCode": "CNP",
                            "Airports": [
                                {
                                    "Id": "CNP",
                                    "Name": "Neerlerit Inaat",
                                    "CityId": "NEER",
                                    "CountryId": "GL",
                                    "Location": "-22.658333, 70.743056"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "I",
            "Name": "Southeast Asia",
            "Countries": [
                {
                    "Id": "TH",
                    "Name": "Thailand",
                    "CurrencyId": "THB",
                    "Cities": [
                        {
                            "Id": "BKKT",
                            "Name": "Bangkok",
                            "SingleAirportCity": false,
                            "CountryId": "TH",
                            "Location": "100.62368, 13.771926",
                            "IataCode": "BKK",
                            "Airports": [
                                {
                                    "Id": "BKK",
                                    "Name": "Bangkok Suvarnabhumi",
                                    "CityId": "BKKT",
                                    "CountryId": "TH",
                                    "Location": "100.75016, 13.690083"
                                },
                                {
                                    "Id": "DMK",
                                    "Name": "Don Mueang",
                                    "CityId": "BKKT",
                                    "CountryId": "TH",
                                    "Location": "100.61, 13.91"
                                }
                            ]
                        },
                        {
                            "Id": "CJMT",
                            "Name": "Chumphon",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.166667, 10.5",
                            "IataCode": "CJM",
                            "Airports": [
                                {
                                    "Id": "CJM",
                                    "Name": "Chumphon",
                                    "CityId": "CJMT",
                                    "CountryId": "TH",
                                    "Location": "99.363889, 10.709722"
                                }
                            ]
                        },
                        {
                            "Id": "CEIT",
                            "Name": "Chiang Rai",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.833333, 19.9",
                            "IataCode": "CEI",
                            "Airports": [
                                {
                                    "Id": "CEI",
                                    "Name": "Chiang Rai",
                                    "CityId": "CEIT",
                                    "CountryId": "TH",
                                    "Location": "99.883056, 19.9525"
                                }
                            ]
                        },
                        {
                            "Id": "CNXT",
                            "Name": "Chiang Mai",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.008056, 18.795",
                            "IataCode": "CNX",
                            "Airports": [
                                {
                                    "Id": "CNX",
                                    "Name": "Chiang Mai",
                                    "CityId": "CNXT",
                                    "CountryId": "TH",
                                    "Location": "98.963998, 18.767891"
                                }
                            ]
                        },
                        {
                            "Id": "HDYT",
                            "Name": "Hat Yai (Songkhla)",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.46667, 7.0166667",
                            "IataCode": "HDY",
                            "Airports": [
                                {
                                    "Id": "HDY",
                                    "Name": "Hat Yai",
                                    "CityId": "HDYT",
                                    "CountryId": "TH",
                                    "Location": "100.41667, 6.933333"
                                }
                            ]
                        },
                        {
                            "Id": "HGNT",
                            "Name": "Mae Hong Son",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "97.968521, 19.300289",
                            "IataCode": "HGN",
                            "Airports": [
                                {
                                    "Id": "HGN",
                                    "Name": "Mae Hong Son",
                                    "CityId": "HGNT",
                                    "CountryId": "TH",
                                    "Location": "97.976389, 19.298889"
                                }
                            ]
                        },
                        {
                            "Id": "HHQT",
                            "Name": "Hua Hin (Prachuap Khiri Khan)",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.957537, 12.567849",
                            "IataCode": "HHQ",
                            "Airports": [
                                {
                                    "Id": "HHQ",
                                    "Name": "Hua Hin (Prachuap Khiri Khan)",
                                    "CityId": "HHQT",
                                    "CountryId": "TH",
                                    "Location": "99.954444, 12.630556"
                                }
                            ]
                        },
                        {
                            "Id": "KBVT",
                            "Name": "Krabi",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.916667, 8.0666667",
                            "IataCode": "KBV",
                            "Airports": [
                                {
                                    "Id": "KBV",
                                    "Name": "Krabi",
                                    "CityId": "KBVT",
                                    "CountryId": "TH",
                                    "Location": "98.98527, 8.09985"
                                }
                            ]
                        },
                        {
                            "Id": "KOPT",
                            "Name": "Nakhon Phanom",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "104.78333, 17.4",
                            "IataCode": "KOP",
                            "Airports": [
                                {
                                    "Id": "KOP",
                                    "Name": "Nakhon Phanom",
                                    "CityId": "KOPT",
                                    "CountryId": "TH",
                                    "Location": "104.64615, 17.388648"
                                }
                            ]
                        },
                        {
                            "Id": "KKCT",
                            "Name": "Khon Kaen",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.83333, 16.433333",
                            "IataCode": "KKC",
                            "Airports": [
                                {
                                    "Id": "KKC",
                                    "Name": "Khon Kaen",
                                    "CityId": "KKCT",
                                    "CountryId": "TH",
                                    "Location": "102.78667, 16.465"
                                }
                            ]
                        },
                        {
                            "Id": "KKMT",
                            "Name": "Lop Buri",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.61667, 14.8",
                            "IataCode": "KKM",
                            "Airports": [
                                {
                                    "Id": "KKM",
                                    "Name": "Lop Buri",
                                    "CityId": "KKMT",
                                    "CountryId": "TH",
                                    "Location": "100.61667, 14.8"
                                }
                            ]
                        },
                        {
                            "Id": "LOET",
                            "Name": "Loei",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.71667, 17.483333",
                            "IataCode": "LOE",
                            "Airports": [
                                {
                                    "Id": "LOE",
                                    "Name": "Loei",
                                    "CityId": "LOET",
                                    "CountryId": "TH",
                                    "Location": "101.73333, 17.45"
                                }
                            ]
                        },
                        {
                            "Id": "LPTT",
                            "Name": "Lampang",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.507222, 18.298333",
                            "IataCode": "LPT",
                            "Airports": [
                                {
                                    "Id": "LPT",
                                    "Name": "Lampang",
                                    "CityId": "LPTT",
                                    "CountryId": "TH",
                                    "Location": "99.507222, 18.274167"
                                }
                            ]
                        },
                        {
                            "Id": "PRHT",
                            "Name": "Phrae",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.13333, 18.15",
                            "IataCode": "PRH",
                            "Airports": [
                                {
                                    "Id": "PRH",
                                    "Name": "Phrae",
                                    "CityId": "PRHT",
                                    "CountryId": "TH",
                                    "Location": "100.13333, 18.15"
                                }
                            ]
                        },
                        {
                            "Id": "NAKT",
                            "Name": "Nakhon Ratchasima",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.10196, 14.970657",
                            "IataCode": "NAK",
                            "Airports": [
                                {
                                    "Id": "NAK",
                                    "Name": "Nakhon Ratchasima",
                                    "CityId": "NAKT",
                                    "CountryId": "TH",
                                    "Location": "102.313, 14.9495"
                                }
                            ]
                        },
                        {
                            "Id": "PATT",
                            "Name": "Pattani",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.27417, 6.8886111",
                            "IataCode": "PAN",
                            "Airports": [
                                {
                                    "Id": "PAN",
                                    "Name": "Pattani",
                                    "CityId": "PATT",
                                    "CountryId": "TH",
                                    "Location": "101.15833, 6.777222"
                                }
                            ]
                        },
                        {
                            "Id": "TKTT",
                            "Name": "Tak",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.133333, 16.866667",
                            "IataCode": "TKT",
                            "Airports": [
                                {
                                    "Id": "TKT",
                                    "Name": "Tak",
                                    "CityId": "TKTT",
                                    "CountryId": "TH",
                                    "Location": "99.25335, 16.89599"
                                }
                            ]
                        },
                        {
                            "Id": "TSTT",
                            "Name": "Trang",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.611406, 7.5563344",
                            "IataCode": "TST",
                            "Airports": [
                                {
                                    "Id": "TST",
                                    "Name": "Trang",
                                    "CityId": "TSTT",
                                    "CountryId": "TH",
                                    "Location": "99.616667, 7.5"
                                }
                            ]
                        },
                        {
                            "Id": "UBPT",
                            "Name": "Ubon Ratchathani",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "104.86306, 15.233056",
                            "IataCode": "UBP",
                            "Airports": [
                                {
                                    "Id": "UBP",
                                    "Name": "Ubon Ratchathani",
                                    "CityId": "UBPT",
                                    "CountryId": "TH",
                                    "Location": "104.87361, 15.248889"
                                }
                            ]
                        },
                        {
                            "Id": "UNNT",
                            "Name": "Ranong",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.633333, 9.9666667",
                            "IataCode": "UNN",
                            "Airports": [
                                {
                                    "Id": "UNN",
                                    "Name": "Ranong",
                                    "CityId": "UNNT",
                                    "CountryId": "TH",
                                    "Location": "98.587532, 9.776562"
                                }
                            ]
                        },
                        {
                            "Id": "UTHT",
                            "Name": "Udon Thani",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.79306, 17.4075",
                            "IataCode": "UTH",
                            "Airports": [
                                {
                                    "Id": "UTH",
                                    "Name": "Udon Thani",
                                    "CityId": "UTHT",
                                    "CountryId": "TH",
                                    "Location": "102.79278, 17.385"
                                }
                            ]
                        },
                        {
                            "Id": "MAQT",
                            "Name": "Mae Sot (Tak)",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.566667, 16.716667",
                            "IataCode": "MAQ",
                            "Airports": [
                                {
                                    "Id": "MAQ",
                                    "Name": "Mae Sot (Tak)",
                                    "CityId": "MAQT",
                                    "CountryId": "TH",
                                    "Location": "98.583333, 16.666667"
                                }
                            ]
                        },
                        {
                            "Id": "NAWT",
                            "Name": "Narathiwat",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.82361, 6.4244444",
                            "IataCode": "NAW",
                            "Airports": [
                                {
                                    "Id": "NAW",
                                    "Name": "Narathiwat",
                                    "CityId": "NAWT",
                                    "CountryId": "TH",
                                    "Location": "101.75, 6.516667"
                                }
                            ]
                        },
                        {
                            "Id": "NNTT",
                            "Name": "Nan",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.78333, 18.783333",
                            "IataCode": "NNT",
                            "Airports": [
                                {
                                    "Id": "NNT",
                                    "Name": "Nan",
                                    "CityId": "NNTT",
                                    "CountryId": "TH",
                                    "Location": "100.78333, 18.8"
                                }
                            ]
                        },
                        {
                            "Id": "NSTT",
                            "Name": "Nakhon Si Thammarat",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.966667, 8.4333333",
                            "IataCode": "NST",
                            "Airports": [
                                {
                                    "Id": "NST",
                                    "Name": "Nakhon Si Thammarat",
                                    "CityId": "NSTT",
                                    "CountryId": "TH",
                                    "Location": "99.95, 8.466667"
                                }
                            ]
                        },
                        {
                            "Id": "PBST",
                            "Name": "Patong Beach",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.29661, 7.89607",
                            "IataCode": "PBS",
                            "Airports": [
                                {
                                    "Id": "PBS",
                                    "Name": "Patong Beach",
                                    "CityId": "PBST",
                                    "CountryId": "TH",
                                    "Location": "98.291667, 7.895278"
                                }
                            ]
                        },
                        {
                            "Id": "PHST",
                            "Name": "Phitsanulok",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.25, 16.833333",
                            "IataCode": "PHS",
                            "Airports": [
                                {
                                    "Id": "PHS",
                                    "Name": "Phitsanulok",
                                    "CityId": "PHST",
                                    "CountryId": "TH",
                                    "Location": "100.27944, 16.788333"
                                }
                            ]
                        },
                        {
                            "Id": "PMMT",
                            "Name": "Phanom Sarakham",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.35, 13.75",
                            "IataCode": "PMM",
                            "Airports": [
                                {
                                    "Id": "PMM",
                                    "Name": "Phanom Sarakham",
                                    "CityId": "PMMT",
                                    "CountryId": "TH",
                                    "Location": "101.35, 13.75"
                                }
                            ]
                        },
                        {
                            "Id": "PXRT",
                            "Name": "Surin",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "103.48333, 14.883333",
                            "IataCode": "PXR",
                            "Airports": [
                                {
                                    "Id": "PXR",
                                    "Name": "Surin",
                                    "CityId": "PXRT",
                                    "CountryId": "TH",
                                    "Location": "103.5, 14.866667"
                                }
                            ]
                        },
                        {
                            "Id": "PYXT",
                            "Name": "Pattaya",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.90095, 12.931941",
                            "IataCode": "PYX",
                            "Airports": [
                                {
                                    "Id": "PYX",
                                    "Name": "Pattaya",
                                    "CityId": "PYXT",
                                    "CountryId": "TH",
                                    "Location": "100.88333, 12.95"
                                }
                            ]
                        },
                        {
                            "Id": "ROIT",
                            "Name": "Roi Et",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "103.65309, 16.056701",
                            "IataCode": "ROI",
                            "Airports": [
                                {
                                    "Id": "ROI",
                                    "Name": "Roi Et",
                                    "CityId": "ROIT",
                                    "CountryId": "TH",
                                    "Location": "103.77694, 16.115278"
                                }
                            ]
                        },
                        {
                            "Id": "SGZT",
                            "Name": "Songkhla",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.59659, 7.2075236",
                            "IataCode": "SGZ",
                            "Airports": [
                                {
                                    "Id": "SGZ",
                                    "Name": "Songkhla",
                                    "CityId": "SGZT",
                                    "CountryId": "TH",
                                    "Location": "100.60803, 7.18656"
                                }
                            ]
                        },
                        {
                            "Id": "SNOT",
                            "Name": "Sakon Nakhon",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "104.15, 17.166667",
                            "IataCode": "SNO",
                            "Airports": [
                                {
                                    "Id": "SNO",
                                    "Name": "Sakon Nakhon",
                                    "CityId": "SNOT",
                                    "CountryId": "TH",
                                    "Location": "104.1, 17.283333"
                                }
                            ]
                        },
                        {
                            "Id": "UTRT",
                            "Name": "Uttaradit",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.10083, 17.631111",
                            "IataCode": "UTR",
                            "Airports": [
                                {
                                    "Id": "UTR",
                                    "Name": "Uttaradit",
                                    "CityId": "UTRT",
                                    "CountryId": "TH",
                                    "Location": "100.24389, 17.669444"
                                }
                            ]
                        },
                        {
                            "Id": "PYYA",
                            "Name": "Pai (Mae Hong Son)",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.439732, 19.361681",
                            "IataCode": "PYY",
                            "Airports": [
                                {
                                    "Id": "PYY",
                                    "Name": "Pai (Mae Hong Son)",
                                    "CityId": "PYYA",
                                    "CountryId": "TH",
                                    "Location": "98.436667, 19.358889"
                                }
                            ]
                        },
                        {
                            "Id": "KTAO",
                            "Name": "Koh Tao",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.838085, 10.098079",
                            "Airports": [
                                {
                                    "Id": "KXM",
                                    "Name": "Koh Tao Island",
                                    "CityId": "KTAO",
                                    "CountryId": "TH",
                                    "Location": "99.86667, 10.08333"
                                }
                            ]
                        },
                        {
                            "Id": "URTT",
                            "Name": "Surat Thani",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.316667, 9.1333333",
                            "IataCode": "URT",
                            "Airports": [
                                {
                                    "Id": "URT",
                                    "Name": "Surat Thani",
                                    "CityId": "URTT",
                                    "CountryId": "TH",
                                    "Location": "99.136162, 9.1343"
                                }
                            ]
                        },
                        {
                            "Id": "MUKD",
                            "Name": "Mukdahan",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "104.71667, 16.533333",
                            "Airports": [
                                {
                                    "Id": "KXN",
                                    "Name": "Mukdahan",
                                    "CityId": "MUKD",
                                    "CountryId": "TH",
                                    "Location": "104.72611, 16.546111"
                                }
                            ]
                        },
                        {
                            "Id": "THST",
                            "Name": "Sukhothai",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.711252, 17.258778",
                            "IataCode": "THS",
                            "Airports": [
                                {
                                    "Id": "THS",
                                    "Name": "Sukhothai",
                                    "CityId": "THST",
                                    "CountryId": "TH",
                                    "Location": "99.822222, 17.223056"
                                }
                            ]
                        },
                        {
                            "Id": "TDXT",
                            "Name": "Trat",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.51973, 12.247124",
                            "IataCode": "TDX",
                            "Airports": [
                                {
                                    "Id": "TDX",
                                    "Name": "Trat",
                                    "CityId": "TDXT",
                                    "CountryId": "TH",
                                    "Location": "102.31694, 12.268056"
                                }
                            ]
                        },
                        {
                            "Id": "PHYT",
                            "Name": "Phetchabun",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.14701, 16.262214",
                            "IataCode": "PHY",
                            "Airports": [
                                {
                                    "Id": "PHY",
                                    "Name": "Phetchabun",
                                    "CityId": "PHYT",
                                    "CountryId": "TH",
                                    "Location": "101.19833, 16.681944"
                                }
                            ]
                        },
                        {
                            "Id": "BFVT",
                            "Name": "Buri Ram",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.9589, 14.819775",
                            "IataCode": "BFV",
                            "Airports": [
                                {
                                    "Id": "BFV",
                                    "Name": "Buri Ram",
                                    "CityId": "BFVT",
                                    "CountryId": "TH",
                                    "Location": "103.25528, 15.226944"
                                }
                            ]
                        },
                        {
                            "Id": "TKHT",
                            "Name": "Takhli",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.35, 15.25",
                            "IataCode": "TKH",
                            "Airports": [
                                {
                                    "Id": "TKH",
                                    "Name": "Takhli",
                                    "CityId": "TKHT",
                                    "CountryId": "TH",
                                    "Location": "100.35, 15.25"
                                }
                            ]
                        },
                        {
                            "Id": "KPGN",
                            "Name": "Ko Pha Ngan",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "100.02914, 9.7577839",
                            "Airports": [
                                {
                                    "Id": "KXJ",
                                    "Name": "Koh Phangan",
                                    "CityId": "KPGN",
                                    "CountryId": "TH",
                                    "Location": "100.08333, 9.766"
                                }
                            ]
                        },
                        {
                            "Id": "HKTT",
                            "Name": "Phuket Island",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.34498, 7.9737561",
                            "IataCode": "HKT",
                            "Airports": [
                                {
                                    "Id": "HKT",
                                    "Name": "Phuket",
                                    "CityId": "HKTT",
                                    "CountryId": "TH",
                                    "Location": "98.306529, 8.1111907"
                                }
                            ]
                        },
                        {
                            "Id": "PHZT",
                            "Name": "Phi Phi Island",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.769233, 7.7381957",
                            "IataCode": "PHZ",
                            "Airports": [
                                {
                                    "Id": "PHZ",
                                    "Name": "Phi Phi Island",
                                    "CityId": "PHZT",
                                    "CountryId": "TH",
                                    "Location": "98.283333, 8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "KLTA",
                            "Name": "Koh Lanta",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.077955, 7.5981499",
                            "Airports": [
                                {
                                    "Id": "KXC",
                                    "Name": "Koh Lanta (Saladan pier)",
                                    "CityId": "KLTA",
                                    "CountryId": "TH",
                                    "Location": "99.064944, 7.559217"
                                }
                            ]
                        },
                        {
                            "Id": "USMT",
                            "Name": "Koh Samui / Samui Island",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.998638, 9.5095389",
                            "IataCode": "USM",
                            "Airports": [
                                {
                                    "Id": "USM",
                                    "Name": "Koh Samui / Samui Island",
                                    "CityId": "USMT",
                                    "CountryId": "TH",
                                    "Location": "100.06667, 9.55"
                                }
                            ]
                        },
                        {
                            "Id": "UTPT",
                            "Name": "Rayong",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "101.01667, 12.683333",
                            "IataCode": "UTP",
                            "Airports": [
                                {
                                    "Id": "UTP",
                                    "Name": "Pattaya (Chonburi)",
                                    "CityId": "UTPT",
                                    "CountryId": "TH",
                                    "Location": "101.01667, 12.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KKDN",
                            "Name": "Ban Ko Muk",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.266667, 7.3166667",
                            "Airports": [
                                {
                                    "Id": "KXB",
                                    "Name": "Koh Kradan Island",
                                    "CityId": "KKDN",
                                    "CountryId": "TH",
                                    "Location": "99.25, 7.316667"
                                }
                            ]
                        },
                        {
                            "Id": "YNOI",
                            "Name": "Ko Yao",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "98.6, 8.1166667",
                            "Airports": [
                                {
                                    "Id": "KXY",
                                    "Name": "Koh Yao Noi",
                                    "CityId": "YNOI",
                                    "CountryId": "TH",
                                    "Location": "98.600809, 8.133829"
                                }
                            ]
                        },
                        {
                            "Id": "KMOK",
                            "Name": "Ban Ko Muk",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.316667, 7.3666667",
                            "Airports": [
                                {
                                    "Id": "KXG",
                                    "Name": "Koh Mook Island",
                                    "CityId": "KMOK",
                                    "CountryId": "TH",
                                    "Location": "99.297974, 7.372522"
                                }
                            ]
                        },
                        {
                            "Id": "KNAG",
                            "Name": "Ban Ko Tao",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.833333, 10.083333",
                            "Airports": [
                                {
                                    "Id": "KXI",
                                    "Name": "Koh Nang Yuan",
                                    "CityId": "KNAG",
                                    "CountryId": "TH",
                                    "Location": "99.813066, 10.11971"
                                }
                            ]
                        },
                        {
                            "Id": "KDTT",
                            "Name": "Ban Rai Chek",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.895556, 14.105278",
                            "IataCode": "KDT",
                            "Airports": [
                                {
                                    "Id": "KDT",
                                    "Name": "Kamphangsaen",
                                    "CityId": "KDTT",
                                    "CountryId": "TH",
                                    "Location": "99.916667, 14.1"
                                }
                            ]
                        },
                        {
                            "Id": "KNAI",
                            "Name": "Ban Phru Chut",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.266667, 7.5",
                            "Airports": [
                                {
                                    "Id": "KXO",
                                    "Name": "Koh Ngai Island",
                                    "CityId": "KNAI",
                                    "CountryId": "TH",
                                    "Location": "99.20661, 7.416921"
                                }
                            ]
                        },
                        {
                            "Id": "KPHI",
                            "Name": "Ban Ba Raket",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.9, 6.6833333",
                            "Airports": [
                                {
                                    "Id": "KXL",
                                    "Name": "Koh Phiphi",
                                    "CityId": "KPHI",
                                    "CountryId": "TH",
                                    "Location": "99.907222, 6.685"
                                }
                            ]
                        },
                        {
                            "Id": "KLIP",
                            "Name": "Ban Ao Makham",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "99.625278, 6.5677778",
                            "Airports": [
                                {
                                    "Id": "KXD",
                                    "Name": "Koh Lipe Island",
                                    "CityId": "KLIP",
                                    "CountryId": "TH",
                                    "Location": "99.30833, 6.498611"
                                }
                            ]
                        },
                        {
                            "Id": "BAOT",
                            "Name": "Ban Chang",
                            "SingleAirportCity": true,
                            "CountryId": "TH",
                            "Location": "102.77361, 17.378056",
                            "IataCode": "BAO",
                            "Airports": [
                                {
                                    "Id": "BAO",
                                    "Name": "Ban Mak Khaen",
                                    "CityId": "BAOT",
                                    "CountryId": "TH",
                                    "Location": "102.79278, 17.385"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SG",
                    "Name": "Singapore",
                    "CurrencyId": "SGD",
                    "Cities": [
                        {
                            "Id": "SINS",
                            "Name": "Singapore",
                            "SingleAirportCity": false,
                            "CountryId": "SG",
                            "Location": "103.81917, 1.3366141",
                            "IataCode": "SIN",
                            "Airports": [
                                {
                                    "Id": "XSP",
                                    "Name": "Seletar",
                                    "CityId": "SINS",
                                    "CountryId": "SG",
                                    "Location": "103.86765, 1.41695"
                                },
                                {
                                    "Id": "SIN",
                                    "Name": "Singapore Changi",
                                    "CityId": "SINS",
                                    "CountryId": "SG",
                                    "Location": "103.99151, 1.3643773"
                                },
                                {
                                    "Id": "QPG",
                                    "Name": "Singapore Paya Lebar",
                                    "CityId": "SINS",
                                    "CountryId": "SG",
                                    "Location": "103.9, 1.35"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TW",
                    "Name": "Taiwan",
                    "CurrencyId": "TWD",
                    "Cities": [
                        {
                            "Id": "CYIT",
                            "Name": "Chiayi",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.44889, 23.479167",
                            "IataCode": "CYI",
                            "Airports": [
                                {
                                    "Id": "CYI",
                                    "Name": "Chiayi",
                                    "CityId": "CYIT",
                                    "CountryId": "TW",
                                    "Location": "120.38333, 23.466667"
                                }
                            ]
                        },
                        {
                            "Id": "HCNT",
                            "Name": "Hengchun",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.74389, 22.004167",
                            "IataCode": "HCN",
                            "Airports": [
                                {
                                    "Id": "HCN",
                                    "Name": "Hengchun",
                                    "CityId": "HCNT",
                                    "CountryId": "TW",
                                    "Location": "120.83333, 21.933333"
                                }
                            ]
                        },
                        {
                            "Id": "HUNT",
                            "Name": "Hualien",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "121.60444, 23.976944",
                            "IataCode": "HUN",
                            "Airports": [
                                {
                                    "Id": "HUN",
                                    "Name": "Hualien",
                                    "CityId": "HUNT",
                                    "CountryId": "TW",
                                    "Location": "121.6125, 24.022222"
                                }
                            ]
                        },
                        {
                            "Id": "KHHT",
                            "Name": "Kaohsiung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.31574, 22.630492",
                            "IataCode": "KHH",
                            "Airports": [
                                {
                                    "Id": "KHH",
                                    "Name": "Kaohsiung",
                                    "CityId": "KHHT",
                                    "CountryId": "TW",
                                    "Location": "120.34528, 22.5725"
                                }
                            ]
                        },
                        {
                            "Id": "LHNT",
                            "Name": "Lishan",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "121.25444, 24.254444",
                            "IataCode": "LHN",
                            "Airports": [
                                {
                                    "Id": "LHN",
                                    "Name": "Lishan",
                                    "CityId": "LHNT",
                                    "CountryId": "TW",
                                    "Location": "121.3, 23.433333"
                                }
                            ]
                        },
                        {
                            "Id": "RMQT",
                            "Name": "T'ai-chung-shih",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.68667, 24.148333",
                            "IataCode": "RMQ",
                            "Airports": [
                                {
                                    "Id": "RMQ",
                                    "Name": "Taichung",
                                    "CityId": "RMQT",
                                    "CountryId": "TW",
                                    "Location": "120.62083, 24.265"
                                }
                            ]
                        },
                        {
                            "Id": "TPET",
                            "Name": "Taipei",
                            "SingleAirportCity": false,
                            "CountryId": "TW",
                            "Location": "121.56725, 25.083396",
                            "IataCode": "TPE",
                            "Airports": [
                                {
                                    "Id": "TSA",
                                    "Name": "Taipei Sung Shan",
                                    "CityId": "TPET",
                                    "CountryId": "TW",
                                    "Location": "121.51667, 25.066667"
                                },
                                {
                                    "Id": "TPE",
                                    "Name": "Taipei Taiwan Taoyuan",
                                    "CityId": "TPET",
                                    "CountryId": "TW",
                                    "Location": "121.23278, 25.077778"
                                }
                            ]
                        },
                        {
                            "Id": "TTTT",
                            "Name": "Taitung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "121.14457, 22.759912",
                            "IataCode": "TTT",
                            "Airports": [
                                {
                                    "Id": "TTT",
                                    "Name": "Taitung",
                                    "CityId": "TTTT",
                                    "CountryId": "TW",
                                    "Location": "121.1, 22.75"
                                }
                            ]
                        },
                        {
                            "Id": "PIFT",
                            "Name": "P'ing-tung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.48833, 22.6725",
                            "IataCode": "PIF",
                            "Airports": [
                                {
                                    "Id": "PIF",
                                    "Name": "Pingtung",
                                    "CityId": "PIFT",
                                    "CountryId": "TW",
                                    "Location": "120.48333, 22.666667"
                                }
                            ]
                        },
                        {
                            "Id": "HSZT",
                            "Name": "Hsinchu",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.96861, 24.803611",
                            "IataCode": "HSZ",
                            "Airports": [
                                {
                                    "Id": "HSZ",
                                    "Name": "Hsinchu",
                                    "CityId": "HSZT",
                                    "CountryId": "TW",
                                    "Location": "120.93333, 24.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TNNT",
                            "Name": "Tainan",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.16811, 23.024764",
                            "IataCode": "TNN",
                            "Airports": [
                                {
                                    "Id": "TNN",
                                    "Name": "Tainan",
                                    "CityId": "TNNT",
                                    "CountryId": "TW",
                                    "Location": "120.2, 22.951944"
                                }
                            ]
                        },
                        {
                            "Id": "LZNA",
                            "Name": "Nangan",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "119.13861, 25.572222",
                            "IataCode": "LZN",
                            "Airports": [
                                {
                                    "Id": "LZN",
                                    "Name": "Nangan",
                                    "CityId": "LZNA",
                                    "CountryId": "TW",
                                    "Location": "119.95833, 26.159444"
                                }
                            ]
                        },
                        {
                            "Id": "WOTT",
                            "Name": "Wonan",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "119.49917, 23.3575",
                            "IataCode": "WOT",
                            "Airports": [
                                {
                                    "Id": "WOT",
                                    "Name": "Wonan",
                                    "CityId": "WOTT",
                                    "CountryId": "TW",
                                    "Location": "119.50389, 23.36944"
                                }
                            ]
                        },
                        {
                            "Id": "MFKT",
                            "Name": "Matsu",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "119.92194, 26.158889",
                            "IataCode": "MFK",
                            "Airports": [
                                {
                                    "Id": "MFK",
                                    "Name": "Matsu",
                                    "CityId": "MFKT",
                                    "CountryId": "TW",
                                    "Location": "120.003, 26.2242"
                                }
                            ]
                        },
                        {
                            "Id": "MZGT",
                            "Name": "Makung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "119.57004, 23.569958",
                            "IataCode": "MZG",
                            "Airports": [
                                {
                                    "Id": "MZG",
                                    "Name": "Makung",
                                    "CityId": "MZGT",
                                    "CountryId": "TW",
                                    "Location": "119.61861, 23.569167"
                                }
                            ]
                        },
                        {
                            "Id": "CMJT",
                            "Name": "Chi Mei",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.28627, 23.152208",
                            "IataCode": "CMJ",
                            "Airports": [
                                {
                                    "Id": "CMJ",
                                    "Name": "Chi Mei",
                                    "CityId": "CMJT",
                                    "CountryId": "TW",
                                    "Location": "119.4183, 23.212005"
                                }
                            ]
                        },
                        {
                            "Id": "KNHT",
                            "Name": "Kinmen",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "118.35766, 24.444627",
                            "IataCode": "KNH",
                            "Airports": [
                                {
                                    "Id": "KNH",
                                    "Name": "Kinmen",
                                    "CityId": "KNHT",
                                    "CountryId": "TW",
                                    "Location": "118.34444, 24.420556"
                                }
                            ]
                        },
                        {
                            "Id": "GNIT",
                            "Name": "Green Island",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "121.49703, 22.666262",
                            "IataCode": "GNI",
                            "Airports": [
                                {
                                    "Id": "GNI",
                                    "Name": "Green Island",
                                    "CityId": "GNIT",
                                    "CountryId": "TW",
                                    "Location": "121.46639, 22.67278"
                                }
                            ]
                        },
                        {
                            "Id": "TXGT",
                            "Name": "Taichung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "120.62083, 24.265",
                            "IataCode": "TXG",
                            "Airports": [
                                {
                                    "Id": "TXG",
                                    "Name": "Taichung",
                                    "CityId": "TXGT",
                                    "CountryId": "TW",
                                    "Location": "120.64611, 24.15"
                                }
                            ]
                        },
                        {
                            "Id": "KYDT",
                            "Name": "Wu-k'ung-tung",
                            "SingleAirportCity": true,
                            "CountryId": "TW",
                            "Location": "121.51278, 22.078611",
                            "IataCode": "KYD",
                            "Airports": [
                                {
                                    "Id": "KYD",
                                    "Name": "Orchid Island",
                                    "CityId": "KYDT",
                                    "CountryId": "TW",
                                    "Location": "121.5, 22.083333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "VN",
                    "Name": "Vietnam",
                    "CurrencyId": "VND",
                    "Cities": [
                        {
                            "Id": "XLOA",
                            "Name": "Long Xuyen",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.41667, 10.383333",
                            "IataCode": "XLO",
                            "Airports": [
                                {
                                    "Id": "XLO",
                                    "Name": "Long Xuyen",
                                    "CityId": "XLOA",
                                    "CountryId": "VN",
                                    "Location": "105.46667, 10.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PHHV",
                            "Name": "Phan Thiet",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.1, 10.933333",
                            "IataCode": "PHH",
                            "Airports": [
                                {
                                    "Id": "PHH",
                                    "Name": "Phan Thiet",
                                    "CityId": "PHHV",
                                    "CountryId": "VN",
                                    "Location": "108.06667, 10.9"
                                }
                            ]
                        },
                        {
                            "Id": "BMVV",
                            "Name": "Banmethuot",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.05, 12.666667",
                            "IataCode": "BMV",
                            "Airports": [
                                {
                                    "Id": "BMV",
                                    "Name": "Banmethuot",
                                    "CityId": "BMVV",
                                    "CountryId": "VN",
                                    "Location": "108.12028, 12.668333"
                                }
                            ]
                        },
                        {
                            "Id": "CAHV",
                            "Name": "Ca Mau",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.15, 9.1769444",
                            "IataCode": "CAH",
                            "Airports": [
                                {
                                    "Id": "CAH",
                                    "Name": "Ca Mau",
                                    "CityId": "CAHV",
                                    "CountryId": "VN",
                                    "Location": "105.17944, 9.175556"
                                }
                            ]
                        },
                        {
                            "Id": "NHAV",
                            "Name": "Nha Trang",
                            "SingleAirportCity": false,
                            "CountryId": "VN",
                            "Location": "109.18333, 12.25",
                            "IataCode": "NHA",
                            "Airports": [
                                {
                                    "Id": "CXR",
                                    "Name": "Nha Trang Cam Ranh",
                                    "CityId": "NHAV",
                                    "CountryId": "VN",
                                    "Location": "109.225, 11.993611"
                                },
                                {
                                    "Id": "NHA",
                                    "Name": "Nha Trang Air Base",
                                    "CityId": "NHAV",
                                    "CountryId": "VN",
                                    "Location": "109.19389, 12.227778"
                                }
                            ]
                        },
                        {
                            "Id": "DADV",
                            "Name": "Da Nang",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.22083, 16.067778",
                            "IataCode": "DAD",
                            "Airports": [
                                {
                                    "Id": "DAD",
                                    "Name": "Da Nang",
                                    "CityId": "DADV",
                                    "CountryId": "VN",
                                    "Location": "108.20361, 16.042222"
                                }
                            ]
                        },
                        {
                            "Id": "DLIV",
                            "Name": "Dalat",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.41667, 11.933333",
                            "IataCode": "DLI",
                            "Airports": [
                                {
                                    "Id": "DLI",
                                    "Name": "Dalat",
                                    "CityId": "DLIV",
                                    "CountryId": "VN",
                                    "Location": "108.37778, 11.749722"
                                }
                            ]
                        },
                        {
                            "Id": "HANV",
                            "Name": "Hanoi",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.85, 21.033335",
                            "IataCode": "HAN",
                            "Airports": [
                                {
                                    "Id": "HAN",
                                    "Name": "Hanoi",
                                    "CityId": "HANV",
                                    "CountryId": "VN",
                                    "Location": "105.80304, 21.219176"
                                }
                            ]
                        },
                        {
                            "Id": "HPHV",
                            "Name": "Haiphong",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "106.68222, 20.856112",
                            "IataCode": "HPH",
                            "Airports": [
                                {
                                    "Id": "HPH",
                                    "Name": "Haiphong",
                                    "CityId": "HPHV",
                                    "CountryId": "VN",
                                    "Location": "106.725, 20.819444"
                                }
                            ]
                        },
                        {
                            "Id": "SGNV",
                            "Name": "Ho Chi Minh City",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "106.66667, 10.750002",
                            "IataCode": "SGN",
                            "Airports": [
                                {
                                    "Id": "SGN",
                                    "Name": "Ho Chi Minh City",
                                    "CityId": "SGNV",
                                    "CountryId": "VN",
                                    "Location": "106.65847, 10.818712"
                                }
                            ]
                        },
                        {
                            "Id": "UIHV",
                            "Name": "Qui Nhon",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "109.23333, 13.766667",
                            "IataCode": "UIH",
                            "Airports": [
                                {
                                    "Id": "UIH",
                                    "Name": "Qui Nhon",
                                    "CityId": "UIHV",
                                    "CountryId": "VN",
                                    "Location": "109.042, 13.955"
                                }
                            ]
                        },
                        {
                            "Id": "VKGV",
                            "Name": "Rach Gia",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.08333, 10.016667",
                            "IataCode": "VKG",
                            "Airports": [
                                {
                                    "Id": "VKG",
                                    "Name": "Rach Gia",
                                    "CityId": "VKGV",
                                    "CountryId": "VN",
                                    "Location": "105.08333, 10.083333"
                                }
                            ]
                        },
                        {
                            "Id": "VCSV",
                            "Name": "Con Dao",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.06667, 10.35",
                            "IataCode": "VCS",
                            "Airports": [
                                {
                                    "Id": "VCS",
                                    "Name": "Con Dao",
                                    "CityId": "VCSV",
                                    "CountryId": "VN",
                                    "Location": "106.633, 8.73183"
                                }
                            ]
                        },
                        {
                            "Id": "XVLA",
                            "Name": "Vinh Long",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.96667, 10.25",
                            "IataCode": "XVL",
                            "Airports": [
                                {
                                    "Id": "XVL",
                                    "Name": "Vinh Long",
                                    "CityId": "XVLA",
                                    "CountryId": "VN",
                                    "Location": "105.95, 10.25"
                                }
                            ]
                        },
                        {
                            "Id": "PHUV",
                            "Name": "Phu Vinh",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "106.34528, 9.9347222",
                            "IataCode": "PHU",
                            "Airports": [
                                {
                                    "Id": "PHU",
                                    "Name": "Phu Vinh",
                                    "CityId": "PHUV",
                                    "CountryId": "VN",
                                    "Location": "106.33333, 9.95"
                                }
                            ]
                        },
                        {
                            "Id": "PXUV",
                            "Name": "Pleiku",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108, 13.983333",
                            "IataCode": "PXU",
                            "Airports": [
                                {
                                    "Id": "PXU",
                                    "Name": "Pleiku",
                                    "CityId": "PXUV",
                                    "CountryId": "VN",
                                    "Location": "108.02139, 14.003056"
                                }
                            ]
                        },
                        {
                            "Id": "TBBV",
                            "Name": "Tuy Hoa",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "109.3, 13.083333",
                            "IataCode": "TBB",
                            "Airports": [
                                {
                                    "Id": "TBB",
                                    "Name": "Tuy Hoa",
                                    "CityId": "TBBV",
                                    "CountryId": "VN",
                                    "Location": "109.33972, 13.044167"
                                }
                            ]
                        },
                        {
                            "Id": "PHAV",
                            "Name": "Phan Rang",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.98333, 11.566667",
                            "IataCode": "PHA",
                            "Airports": [
                                {
                                    "Id": "PHA",
                                    "Name": "Phan Rang",
                                    "CityId": "PHAV",
                                    "CountryId": "VN",
                                    "Location": "108.95, 11.633333"
                                }
                            ]
                        },
                        {
                            "Id": "XNGA",
                            "Name": "Quang Ngai",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.8, 15.116667",
                            "IataCode": "XNG",
                            "Airports": [
                                {
                                    "Id": "XNG",
                                    "Name": "Quang Ngai",
                                    "CityId": "XNGA",
                                    "CountryId": "VN",
                                    "Location": "108.77583, 15.113611"
                                }
                            ]
                        },
                        {
                            "Id": "HUIV",
                            "Name": "Hue",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.6, 16.466667",
                            "IataCode": "HUI",
                            "Airports": [
                                {
                                    "Id": "HUI",
                                    "Name": "Hue",
                                    "CityId": "HUIV",
                                    "CountryId": "VN",
                                    "Location": "107.70333, 16.399167"
                                }
                            ]
                        },
                        {
                            "Id": "PQCV",
                            "Name": "Phu Quoc",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "103.96667, 10.216667",
                            "IataCode": "PQC",
                            "Airports": [
                                {
                                    "Id": "PQC",
                                    "Name": "Phu Quoc",
                                    "CityId": "PQCV",
                                    "CountryId": "VN",
                                    "Location": "103.96667, 10.233333"
                                }
                            ]
                        },
                        {
                            "Id": "VCAV",
                            "Name": "Can Tho",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.78333, 10.033333",
                            "IataCode": "VCA",
                            "Airports": [
                                {
                                    "Id": "VCA",
                                    "Name": "Can Tho",
                                    "CityId": "VCAV",
                                    "CountryId": "VN",
                                    "Location": "105.712, 10.0851"
                                }
                            ]
                        },
                        {
                            "Id": "DONG",
                            "Name": "Dong Hoi",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "106.6, 17.483333",
                            "IataCode": "VDH",
                            "Airports": [
                                {
                                    "Id": "VDH",
                                    "Name": "Dong Hoi",
                                    "CityId": "DONG",
                                    "CountryId": "VN",
                                    "Location": "106.59055, 17.515"
                                }
                            ]
                        },
                        {
                            "Id": "VIIV",
                            "Name": "Vinh City",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.66667, 18.666667",
                            "IataCode": "VII",
                            "Airports": [
                                {
                                    "Id": "VII",
                                    "Name": "Vinh City",
                                    "CityId": "VIIV",
                                    "CountryId": "VN",
                                    "Location": "105.63333, 18.7"
                                }
                            ]
                        },
                        {
                            "Id": "THDV",
                            "Name": "Thanh Hoa",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.76667, 19.8",
                            "IataCode": "THD",
                            "Airports": [
                                {
                                    "Id": "THD",
                                    "Name": "Thanh Hoa Tho Xuan",
                                    "CityId": "THDV",
                                    "CountryId": "VN",
                                    "Location": "105.46778, 19.901667"
                                }
                            ]
                        },
                        {
                            "Id": "VCLV",
                            "Name": "Tamky",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.48333, 15.566667",
                            "IataCode": "VCL",
                            "Airports": [
                                {
                                    "Id": "VCL",
                                    "Name": "Tamky",
                                    "CityId": "VCLV",
                                    "CountryId": "VN",
                                    "Location": "108.70389, 15.422222"
                                }
                            ]
                        },
                        {
                            "Id": "SQHV",
                            "Name": "Son-La",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "103.9, 21.316667",
                            "IataCode": "SQH",
                            "Airports": [
                                {
                                    "Id": "SQH",
                                    "Name": "Son-La",
                                    "CityId": "SQHV",
                                    "CountryId": "VN",
                                    "Location": "104.025, 21.218056"
                                }
                            ]
                        },
                        {
                            "Id": "SOAV",
                            "Name": "Soc Trang",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.98, 9.6033333",
                            "IataCode": "SOA",
                            "Airports": [
                                {
                                    "Id": "SOA",
                                    "Name": "Soc Trang",
                                    "CityId": "SOAV",
                                    "CountryId": "VN",
                                    "Location": "105.83333, 9.616667"
                                }
                            ]
                        },
                        {
                            "Id": "VSOV",
                            "Name": "Phuoclong",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.42389, 9.4516667",
                            "IataCode": "VSO",
                            "Airports": [
                                {
                                    "Id": "VSO",
                                    "Name": "Phuoclong",
                                    "CityId": "VSOV",
                                    "CountryId": "VN",
                                    "Location": "105.46667, 9.433333"
                                }
                            ]
                        },
                        {
                            "Id": "KONV",
                            "Name": "Mang La",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.95, 14.383333",
                            "IataCode": "KON",
                            "Airports": [
                                {
                                    "Id": "KON",
                                    "Name": "Kontum",
                                    "CityId": "KONV",
                                    "CountryId": "VN",
                                    "Location": "108.02139, 14.354167"
                                }
                            ]
                        },
                        {
                            "Id": "DINV",
                            "Name": "Dien Bien Phu",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "103.01667, 21.383333",
                            "IataCode": "DIN",
                            "Airports": [
                                {
                                    "Id": "DIN",
                                    "Name": "Dien Bien Phu",
                                    "CityId": "DINV",
                                    "CountryId": "VN",
                                    "Location": "103, 21.383333"
                                }
                            ]
                        },
                        {
                            "Id": "VDOA",
                            "Name": "Quang Ninh",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.33662, 21.198185",
                            "IataCode": "VDO",
                            "Airports": [
                                {
                                    "Id": "VDO",
                                    "Name": "Van Don International",
                                    "CityId": "VDOA",
                                    "CountryId": "VN",
                                    "Location": "107.41417, 21.117778"
                                }
                            ]
                        },
                        {
                            "Id": "VTGV",
                            "Name": "Xã Thắng Nhứt",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.1, 10.383333",
                            "IataCode": "VTG",
                            "Airports": [
                                {
                                    "Id": "VTG",
                                    "Name": "Vung Tau",
                                    "CityId": "VTGV",
                                    "CountryId": "VN",
                                    "Location": "107.09111, 10.374444"
                                }
                            ]
                        },
                        {
                            "Id": "HOOV",
                            "Name": "Nghĩa Tín",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "107.7, 12.016667",
                            "IataCode": "HOO",
                            "Airports": [
                                {
                                    "Id": "HOO",
                                    "Name": "Quanduc",
                                    "CityId": "HOOV",
                                    "CountryId": "VN",
                                    "Location": "107.68806, 12.009722"
                                }
                            ]
                        },
                        {
                            "Id": "TMKV",
                            "Name": "Mỹ Thach (1)",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "108.48333, 15.583333",
                            "IataCode": "TMK",
                            "Airports": [
                                {
                                    "Id": "TMK",
                                    "Name": "Tamky",
                                    "CityId": "TMKV",
                                    "CountryId": "VN",
                                    "Location": "108.48333, 15.566667"
                                }
                            ]
                        },
                        {
                            "Id": "HBNV",
                            "Name": "Phumĭ Prâlaôh Tbong",
                            "SingleAirportCity": true,
                            "CountryId": "VN",
                            "Location": "105.96667, 12.016667",
                            "IataCode": "HBN",
                            "Airports": [
                                {
                                    "Id": "HBN",
                                    "Name": "Phu-bon",
                                    "CityId": "HBNV",
                                    "CountryId": "VN",
                                    "Location": "106, 12"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CX",
                    "Name": "Christmas Island",
                    "CurrencyId": "AUD",
                    "Cities": [
                        {
                            "Id": "XCHA",
                            "Name": "Christmas Island",
                            "SingleAirportCity": true,
                            "CountryId": "CX",
                            "Location": "105.63333, -10.483333",
                            "IataCode": "XCH",
                            "Airports": [
                                {
                                    "Id": "XCH",
                                    "Name": "Christmas Island",
                                    "CityId": "XCHA",
                                    "CountryId": "CX",
                                    "Location": "105.69028, -10.450556"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CC",
                    "Name": "Cocos (Keeling) Islands",
                    "CurrencyId": "AUD",
                    "Cities": [
                        {
                            "Id": "CCKA",
                            "Name": "Cocos Islands",
                            "SingleAirportCity": true,
                            "CountryId": "CC",
                            "Location": "96.8716, -12.098569",
                            "IataCode": "CCK",
                            "Airports": [
                                {
                                    "Id": "CCK",
                                    "Name": "Cocos Islands",
                                    "CityId": "CCKA",
                                    "CountryId": "CC",
                                    "Location": "96.833889, -12.188333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PH",
                    "Name": "Philippines",
                    "CurrencyId": "PHP",
                    "Cities": [
                        {
                            "Id": "PXSO",
                            "Name": "Siocon",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.13528, 7.7061111",
                            "IataCode": "XSO",
                            "Airports": [
                                {
                                    "Id": "XSO",
                                    "Name": "Siocon",
                                    "CityId": "PXSO",
                                    "CountryId": "PH",
                                    "Location": "122.15, 7.7"
                                }
                            ]
                        },
                        {
                            "Id": "WNPP",
                            "Name": "Naga",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.18139, 13.619167",
                            "IataCode": "WNP",
                            "Airports": [
                                {
                                    "Id": "WNP",
                                    "Name": "Naga",
                                    "CityId": "WNPP",
                                    "CountryId": "PH",
                                    "Location": "123.27, 13.587222"
                                }
                            ]
                        },
                        {
                            "Id": "PZAM",
                            "Name": "Zamboanga",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.07389, 6.9102778",
                            "IataCode": "ZAM",
                            "Airports": [
                                {
                                    "Id": "ZAM",
                                    "Name": "Zamboanga International",
                                    "CityId": "PZAM",
                                    "CountryId": "PH",
                                    "Location": "122.05889, 6.923056"
                                }
                            ]
                        },
                        {
                            "Id": "MBOP",
                            "Name": "Mamburao",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.59361, 13.228056",
                            "IataCode": "MBO",
                            "Airports": [
                                {
                                    "Id": "MBO",
                                    "Name": "Mamburao",
                                    "CityId": "MBOP",
                                    "CountryId": "PH",
                                    "Location": "120.60333, 13.211389"
                                }
                            ]
                        },
                        {
                            "Id": "MLPP",
                            "Name": "Malabang",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.07028, 7.5902778",
                            "IataCode": "MLP",
                            "Airports": [
                                {
                                    "Id": "MLP",
                                    "Name": "Malabang",
                                    "CityId": "MLPP",
                                    "CountryId": "PH",
                                    "Location": "124.0575, 7.618333"
                                }
                            ]
                        },
                        {
                            "Id": "BPHP",
                            "Name": "Bislig",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "126.31639, 8.2152778",
                            "IataCode": "BPH",
                            "Airports": [
                                {
                                    "Id": "BPH",
                                    "Name": "Bislig",
                                    "CityId": "BPHP",
                                    "CountryId": "PH",
                                    "Location": "126.32111, 8.196667"
                                }
                            ]
                        },
                        {
                            "Id": "BQAP",
                            "Name": "Baler",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.56083, 15.759444",
                            "IataCode": "BQA",
                            "Airports": [
                                {
                                    "Id": "BQA",
                                    "Name": "Baler",
                                    "CityId": "BQAP",
                                    "CountryId": "PH",
                                    "Location": "121.5022, 15.7312"
                                }
                            ]
                        },
                        {
                            "Id": "BSOP",
                            "Name": "Basco",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.96972, 20.45",
                            "IataCode": "BSO",
                            "Airports": [
                                {
                                    "Id": "BSO",
                                    "Name": "Basco (Batanes)",
                                    "CityId": "BSOP",
                                    "CountryId": "PH",
                                    "Location": "121.97778, 20.453056"
                                }
                            ]
                        },
                        {
                            "Id": "BXUP",
                            "Name": "Butuan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.54361, 8.9491667",
                            "IataCode": "BXU",
                            "Airports": [
                                {
                                    "Id": "BXU",
                                    "Name": "Bancasi (Butuan)",
                                    "CityId": "BXUP",
                                    "CountryId": "PH",
                                    "Location": "125.48389, 8.951389"
                                }
                            ]
                        },
                        {
                            "Id": "CBOP",
                            "Name": "Cotabato",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.24639, 7.2236111",
                            "IataCode": "CBO",
                            "Airports": [
                                {
                                    "Id": "CBO",
                                    "Name": "Cotabato",
                                    "CityId": "CBOP",
                                    "CountryId": "PH",
                                    "Location": "124.20997, 7.164512"
                                }
                            ]
                        },
                        {
                            "Id": "CGYP",
                            "Name": "Cagayan De Oro",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.64722, 8.4822222",
                            "IataCode": "CGY",
                            "Airports": [
                                {
                                    "Id": "CGY",
                                    "Name": "Cagayan De Oro",
                                    "CityId": "CGYP",
                                    "CountryId": "PH",
                                    "Location": "124.61056, 8.416111"
                                }
                            ]
                        },
                        {
                            "Id": "CRMP",
                            "Name": "Catarman",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.63861, 12.496111",
                            "IataCode": "CRM",
                            "Airports": [
                                {
                                    "Id": "CRM",
                                    "Name": "Catarman",
                                    "CityId": "CRMP",
                                    "CountryId": "PH",
                                    "Location": "124.58333, 12.483333"
                                }
                            ]
                        },
                        {
                            "Id": "CUJP",
                            "Name": "Culion",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.02028, 11.888889",
                            "IataCode": "CUJ",
                            "Airports": [
                                {
                                    "Id": "CUJ",
                                    "Name": "Culion",
                                    "CityId": "CUJP",
                                    "CountryId": "PH",
                                    "Location": "120, 11.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CYPP",
                            "Name": "Calbayog",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.60417, 12.067222",
                            "IataCode": "CYP",
                            "Airports": [
                                {
                                    "Id": "CYP",
                                    "Name": "Calbayog",
                                    "CityId": "CYPP",
                                    "CountryId": "PH",
                                    "Location": "124.54306, 12.075833"
                                }
                            ]
                        },
                        {
                            "Id": "CYUP",
                            "Name": "Cuyo",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.00861, 10.8525",
                            "IataCode": "CYU",
                            "Airports": [
                                {
                                    "Id": "CYU",
                                    "Name": "Cuyo",
                                    "CityId": "CYUP",
                                    "CountryId": "PH",
                                    "Location": "121, 10.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CYZP",
                            "Name": "Cauayan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.7725, 16.934722",
                            "IataCode": "CYZ",
                            "Airports": [
                                {
                                    "Id": "CYZ",
                                    "Name": "Cauayan",
                                    "CityId": "CYZP",
                                    "CountryId": "PH",
                                    "Location": "121.75583, 16.93"
                                }
                            ]
                        },
                        {
                            "Id": "DGTP",
                            "Name": "Dumaguete",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.30806, 9.3102778",
                            "IataCode": "DGT",
                            "Airports": [
                                {
                                    "Id": "DGT",
                                    "Name": "Dumaguete",
                                    "CityId": "DGTP",
                                    "CountryId": "PH",
                                    "Location": "123.30162, 9.334151"
                                }
                            ]
                        },
                        {
                            "Id": "DPLP",
                            "Name": "Dipolog",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.34139, 8.5894444",
                            "IataCode": "DPL",
                            "Airports": [
                                {
                                    "Id": "DPL",
                                    "Name": "Dipolog",
                                    "CityId": "DPLP",
                                    "CountryId": "PH",
                                    "Location": "123.35, 8.6"
                                }
                            ]
                        },
                        {
                            "Id": "DTEP",
                            "Name": "Daet",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.95583, 14.112778",
                            "IataCode": "DTE",
                            "Airports": [
                                {
                                    "Id": "DTE",
                                    "Name": "Daet",
                                    "CityId": "DTEP",
                                    "CountryId": "PH",
                                    "Location": "122.98333, 14.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DVOP",
                            "Name": "Davao",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.61278, 7.0730556",
                            "IataCode": "DVO",
                            "Airports": [
                                {
                                    "Id": "DVO",
                                    "Name": "Davao International",
                                    "CityId": "DVOP",
                                    "CountryId": "PH",
                                    "Location": "125.64944, 7.131667"
                                }
                            ]
                        },
                        {
                            "Id": "EUQA",
                            "Name": "San Jose de Buenavista",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.94139, 10.752222",
                            "IataCode": "EUQ",
                            "Airports": [
                                {
                                    "Id": "EUQ",
                                    "Name": "Evelio Javier",
                                    "CityId": "EUQA",
                                    "CountryId": "PH",
                                    "Location": "121.93344, 10.766044"
                                }
                            ]
                        },
                        {
                            "Id": "GESP",
                            "Name": "General Santos",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.17167, 6.1127778",
                            "IataCode": "GES",
                            "Airports": [
                                {
                                    "Id": "GES",
                                    "Name": "General Santos International",
                                    "CityId": "GESP",
                                    "CountryId": "PH",
                                    "Location": "125.23472, 6.109444"
                                }
                            ]
                        },
                        {
                            "Id": "IGNP",
                            "Name": "Iligan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.23611, 8.2308333",
                            "IataCode": "IGN",
                            "Airports": [
                                {
                                    "Id": "IGN",
                                    "Name": "Iligan",
                                    "CityId": "IGNP",
                                    "CountryId": "PH",
                                    "Location": "124.21417, 8.131111"
                                }
                            ]
                        },
                        {
                            "Id": "ILOP",
                            "Name": "Iloilo",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.56444, 10.696944",
                            "IataCode": "ILO",
                            "Airports": [
                                {
                                    "Id": "ILO",
                                    "Name": "Iloilo International",
                                    "CityId": "ILOP",
                                    "CountryId": "PH",
                                    "Location": "122.54361, 10.714444"
                                }
                            ]
                        },
                        {
                            "Id": "IPEP",
                            "Name": "Ipil",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.58611, 7.7844444",
                            "IataCode": "IPE",
                            "Airports": [
                                {
                                    "Id": "IPE",
                                    "Name": "Ipil",
                                    "CityId": "IPEP",
                                    "CountryId": "PH",
                                    "Location": "122.58333, 7.783333"
                                }
                            ]
                        },
                        {
                            "Id": "JOLP",
                            "Name": "Jolo",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.00222, 6.0522222",
                            "IataCode": "JOL",
                            "Airports": [
                                {
                                    "Id": "JOL",
                                    "Name": "Jolo",
                                    "CityId": "JOLP",
                                    "CountryId": "PH",
                                    "Location": "121.00889, 6.055278"
                                }
                            ]
                        },
                        {
                            "Id": "LAOP",
                            "Name": "Laoag",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.59361, 18.198889",
                            "IataCode": "LAO",
                            "Airports": [
                                {
                                    "Id": "LAO",
                                    "Name": "Laoag International",
                                    "CityId": "LAOP",
                                    "CountryId": "PH",
                                    "Location": "120.53028, 18.183611"
                                }
                            ]
                        },
                        {
                            "Id": "LBXP",
                            "Name": "Lubang",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.12333, 13.857778",
                            "IataCode": "LBX",
                            "Airports": [
                                {
                                    "Id": "LBX",
                                    "Name": "Lubang",
                                    "CityId": "LBXP",
                                    "CountryId": "PH",
                                    "Location": "120.105, 13.865556"
                                }
                            ]
                        },
                        {
                            "Id": "LGPP",
                            "Name": "Legaspi Port",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.75583, 13.146667",
                            "IataCode": "LGP",
                            "Airports": [
                                {
                                    "Id": "LGP",
                                    "Name": "Legaspi",
                                    "CityId": "LGPP",
                                    "CountryId": "PH",
                                    "Location": "123.73028, 13.156944"
                                }
                            ]
                        },
                        {
                            "Id": "MBTP",
                            "Name": "Masbate",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.61667, 12.366667",
                            "IataCode": "MBT",
                            "Airports": [
                                {
                                    "Id": "MBT",
                                    "Name": "Masbate",
                                    "CityId": "MBTP",
                                    "CountryId": "PH",
                                    "Location": "123.62924, 12.36937"
                                }
                            ]
                        },
                        {
                            "Id": "MNLP",
                            "Name": "Manila",
                            "SingleAirportCity": false,
                            "CountryId": "PH",
                            "Location": "120.98223, 14.604188",
                            "IataCode": "MNL",
                            "Airports": [
                                {
                                    "Id": "SGL",
                                    "Name": "Manila Sangley Point NAS",
                                    "CityId": "MNLP",
                                    "CountryId": "PH",
                                    "Location": "120.95, 14.6"
                                },
                                {
                                    "Id": "MNL",
                                    "Name": "Manila Ninoy Aquino",
                                    "CityId": "MNLP",
                                    "CountryId": "PH",
                                    "Location": "121.0166, 14.512066"
                                }
                            ]
                        },
                        {
                            "Id": "MPHP",
                            "Name": "Caticlan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.95889, 11.9325",
                            "IataCode": "MPH",
                            "Airports": [
                                {
                                    "Id": "MPH",
                                    "Name": "Caticlan (Boracay)",
                                    "CityId": "MPHP",
                                    "CountryId": "PH",
                                    "Location": "121.95222, 11.925833"
                                }
                            ]
                        },
                        {
                            "Id": "MXIP",
                            "Name": "Mati",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "126.21583, 6.9527778",
                            "IataCode": "MXI",
                            "Airports": [
                                {
                                    "Id": "MXI",
                                    "Name": "Mati",
                                    "CityId": "MXIP",
                                    "CountryId": "PH",
                                    "Location": "126.25, 6.916667"
                                }
                            ]
                        },
                        {
                            "Id": "OMCP",
                            "Name": "Ormoc",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.6075, 11.006389",
                            "IataCode": "OMC",
                            "Airports": [
                                {
                                    "Id": "OMC",
                                    "Name": "Ormoc",
                                    "CityId": "OMCP",
                                    "CountryId": "PH",
                                    "Location": "124.56667, 11.05"
                                }
                            ]
                        },
                        {
                            "Id": "OZCP",
                            "Name": "Ozamis City",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.84444, 8.1458333",
                            "IataCode": "OZC",
                            "Airports": [
                                {
                                    "Id": "OZC",
                                    "Name": "Ozamis City",
                                    "CityId": "OZCP",
                                    "CountryId": "PH",
                                    "Location": "123.84139, 8.180556"
                                }
                            ]
                        },
                        {
                            "Id": "PAGP",
                            "Name": "Pagadian",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.437, 7.8257",
                            "IataCode": "PAG",
                            "Airports": [
                                {
                                    "Id": "PAG",
                                    "Name": "Pagadian",
                                    "CityId": "PAGP",
                                    "CountryId": "PH",
                                    "Location": "123.4575, 7.826667"
                                }
                            ]
                        },
                        {
                            "Id": "PPSP",
                            "Name": "Puerto Princesa",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "118.73528, 9.7391667",
                            "IataCode": "PPS",
                            "Airports": [
                                {
                                    "Id": "PPS",
                                    "Name": "Puerto Princesa (Palawan)",
                                    "CityId": "PPSP",
                                    "CountryId": "PH",
                                    "Location": "118.75389, 9.742222"
                                }
                            ]
                        },
                        {
                            "Id": "RXSP",
                            "Name": "Roxas City",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.75111, 11.585278",
                            "IataCode": "RXS",
                            "Airports": [
                                {
                                    "Id": "RXS",
                                    "Name": "Roxas City",
                                    "CityId": "RXSP",
                                    "CountryId": "PH",
                                    "Location": "122.75111, 11.6"
                                }
                            ]
                        },
                        {
                            "Id": "SUGP",
                            "Name": "Surigao",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.48889, 9.7838889",
                            "IataCode": "SUG",
                            "Airports": [
                                {
                                    "Id": "SUG",
                                    "Name": "Surigao",
                                    "CityId": "SUGP",
                                    "CountryId": "PH",
                                    "Location": "125.47917, 9.758889"
                                }
                            ]
                        },
                        {
                            "Id": "SFEP",
                            "Name": "San Fernando",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.31944, 16.618611",
                            "IataCode": "SFE",
                            "Airports": [
                                {
                                    "Id": "SFE",
                                    "Name": "San Fernando",
                                    "CityId": "SFEP",
                                    "CountryId": "PH",
                                    "Location": "120.3, 16.6"
                                }
                            ]
                        },
                        {
                            "Id": "TACP",
                            "Name": "Tacloban",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.00472, 11.243333",
                            "IataCode": "TAC",
                            "Airports": [
                                {
                                    "Id": "TAC",
                                    "Name": "Tacloban",
                                    "CityId": "TACP",
                                    "CountryId": "PH",
                                    "Location": "125.01944, 11.234444"
                                }
                            ]
                        },
                        {
                            "Id": "TAGP",
                            "Name": "Panglao",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.85556, 9.6475",
                            "IataCode": "TAG",
                            "Airports": [
                                {
                                    "Id": "TAG",
                                    "Name": "Bohol-Panglao International",
                                    "CityId": "TAGP",
                                    "CountryId": "PH",
                                    "Location": "123.77396, 9.575875"
                                }
                            ]
                        },
                        {
                            "Id": "TDGP",
                            "Name": "Tandag",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "126.19861, 9.0783333",
                            "IataCode": "TDG",
                            "Airports": [
                                {
                                    "Id": "TDG",
                                    "Name": "Tandag",
                                    "CityId": "TDGP",
                                    "CountryId": "PH",
                                    "Location": "126.16667, 9.06667"
                                }
                            ]
                        },
                        {
                            "Id": "TUGP",
                            "Name": "Tuguegarao",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.72694, 17.613056",
                            "IataCode": "TUG",
                            "Airports": [
                                {
                                    "Id": "TUG",
                                    "Name": "Tuguegarao",
                                    "CityId": "TUGP",
                                    "CountryId": "PH",
                                    "Location": "121.73167, 17.641111"
                                }
                            ]
                        },
                        {
                            "Id": "USUP",
                            "Name": "Busuanga",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "119.91889, 12.159444",
                            "IataCode": "USU",
                            "Airports": [
                                {
                                    "Id": "USU",
                                    "Name": "Busuanga",
                                    "CityId": "USUP",
                                    "CountryId": "PH",
                                    "Location": "120.16667, 12.1"
                                }
                            ]
                        },
                        {
                            "Id": "VRCP",
                            "Name": "Virac",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.23056, 13.578611",
                            "IataCode": "VRC",
                            "Airports": [
                                {
                                    "Id": "VRC",
                                    "Name": "Virac",
                                    "CityId": "VRCP",
                                    "CountryId": "PH",
                                    "Location": "124.2, 13.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BAGP",
                            "Name": "Baguio",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.59306, 16.416389",
                            "IataCode": "BAG",
                            "Airports": [
                                {
                                    "Id": "BAG",
                                    "Name": "Baguio",
                                    "CityId": "BAGP",
                                    "CountryId": "PH",
                                    "Location": "120.61778, 16.376667"
                                }
                            ]
                        },
                        {
                            "Id": "BCDP",
                            "Name": "Bacolod",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.95, 10.666111",
                            "IataCode": "BCD",
                            "Airports": [
                                {
                                    "Id": "BCD",
                                    "Name": "Bacolod-Silay",
                                    "CityId": "BCDP",
                                    "CountryId": "PH",
                                    "Location": "122.92917, 10.644444"
                                }
                            ]
                        },
                        {
                            "Id": "PXCN",
                            "Name": "Coron",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.20583, 11.998611",
                            "IataCode": "XCN",
                            "Airports": [
                                {
                                    "Id": "XCN",
                                    "Name": "Coron",
                                    "CityId": "PXCN",
                                    "CountryId": "PH",
                                    "Location": "120.2, 12.016667"
                                }
                            ]
                        },
                        {
                            "Id": "PXMA",
                            "Name": "Maramag",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.00528, 7.7633333",
                            "IataCode": "XMA",
                            "Airports": [
                                {
                                    "Id": "XMA",
                                    "Name": "Maramag",
                                    "CityId": "PXMA",
                                    "CountryId": "PH",
                                    "Location": "125, 7.766667"
                                }
                            ]
                        },
                        {
                            "Id": "ENIP",
                            "Name": "El Nido",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "119.39556, 11.185833",
                            "IataCode": "ENI",
                            "Airports": [
                                {
                                    "Id": "ENI",
                                    "Name": "El Nido",
                                    "CityId": "ENIP",
                                    "CountryId": "PH",
                                    "Location": "119.41278, 11.205556"
                                }
                            ]
                        },
                        {
                            "Id": "SSVP",
                            "Name": "Siasi",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.81611, 5.5444444",
                            "IataCode": "SSV",
                            "Airports": [
                                {
                                    "Id": "SSV",
                                    "Name": "Siasi",
                                    "CityId": "SSVP",
                                    "CountryId": "PH",
                                    "Location": "120.81667, 5.5"
                                }
                            ]
                        },
                        {
                            "Id": "CEBP",
                            "Name": "Cebu",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.89311, 10.309555",
                            "IataCode": "CEB",
                            "Airports": [
                                {
                                    "Id": "CEB",
                                    "Name": "Mactan-Cebu International",
                                    "CityId": "CEBP",
                                    "CountryId": "PH",
                                    "Location": "123.97889, 10.307222"
                                }
                            ]
                        },
                        {
                            "Id": "CRKP",
                            "Name": "Angeles",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.59111, 15.135833",
                            "IataCode": "CRK",
                            "Airports": [
                                {
                                    "Id": "CRK",
                                    "Name": "Luzon Clark International",
                                    "CityId": "CRKP",
                                    "CountryId": "PH",
                                    "Location": "120.55, 15.1833"
                                }
                            ]
                        },
                        {
                            "Id": "TWTP",
                            "Name": "Tawitawi",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "119.95235, 5.2332904",
                            "IataCode": "TWT",
                            "Airports": [
                                {
                                    "Id": "TWT",
                                    "Name": "Tawitawi",
                                    "CityId": "TWTP",
                                    "CountryId": "PH",
                                    "Location": "120, 5.166667"
                                }
                            ]
                        },
                        {
                            "Id": "VGNP",
                            "Name": "Vigan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.38694, 17.574722",
                            "IataCode": "VGN",
                            "Airports": [
                                {
                                    "Id": "VGN",
                                    "Name": "Vigan",
                                    "CityId": "VGNP",
                                    "CountryId": "PH",
                                    "Location": "120.35612, 17.55474"
                                }
                            ]
                        },
                        {
                            "Id": "TAYP",
                            "Name": "Taytay",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.1325, 14.569167",
                            "IataCode": "RZP",
                            "Airports": [
                                {
                                    "Id": "RZP",
                                    "Name": "Taytay",
                                    "CityId": "TAYP",
                                    "CountryId": "PH",
                                    "Location": "119.51833, 11.05167"
                                }
                            ]
                        },
                        {
                            "Id": "SFSP",
                            "Name": "Subic Bay",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.23333, 14.879722",
                            "IataCode": "SFS",
                            "Airports": [
                                {
                                    "Id": "SFS",
                                    "Name": "Subic Bay",
                                    "CityId": "SFSP",
                                    "CountryId": "PH",
                                    "Location": "120.26722, 14.785556"
                                }
                            ]
                        },
                        {
                            "Id": "SWLP",
                            "Name": "San Vicente",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "119.26667, 10.55",
                            "IataCode": "SWL",
                            "Airports": [
                                {
                                    "Id": "SWL",
                                    "Name": "San Vicente",
                                    "CityId": "SWLP",
                                    "CountryId": "PH",
                                    "Location": "119.27362, 10.524262"
                                }
                            ]
                        },
                        {
                            "Id": "SJIP",
                            "Name": "San Jose",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.06667, 12.353611",
                            "IataCode": "SJI",
                            "Airports": [
                                {
                                    "Id": "SJI",
                                    "Name": "San Jose (Mindoro)",
                                    "CityId": "SJIP",
                                    "CountryId": "PH",
                                    "Location": "121.0468, 12.361581"
                                }
                            ]
                        },
                        {
                            "Id": "MACO",
                            "Name": "Maconacon",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.23333, 17.433333",
                            "IataCode": "KCV",
                            "Airports": [
                                {
                                    "Id": "KCV",
                                    "Name": "Maconacon",
                                    "CityId": "MACO",
                                    "CountryId": "PH",
                                    "Location": "-83.633333, 32.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KLOP",
                            "Name": "Kalibo",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.36444, 11.706111",
                            "IataCode": "KLO",
                            "Airports": [
                                {
                                    "Id": "KLO",
                                    "Name": "Kalibo International (Boracay)",
                                    "CityId": "KLOP",
                                    "CountryId": "PH",
                                    "Location": "122.37722, 11.682778"
                                }
                            ]
                        },
                        {
                            "Id": "CGGP",
                            "Name": "Casiguran",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.94222, 16.133611",
                            "IataCode": "CGG",
                            "Airports": [
                                {
                                    "Id": "CGG",
                                    "Name": "Casiguran",
                                    "CityId": "CGGP",
                                    "CountryId": "PH",
                                    "Location": "122.01667, 16.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CDYP",
                            "Name": "Cagayan De Sulu",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "118.51361, 6.9766667",
                            "IataCode": "CDY",
                            "Airports": [
                                {
                                    "Id": "CDY",
                                    "Name": "Cagayan De Sulu",
                                    "CityId": "CDYP",
                                    "CountryId": "PH",
                                    "Location": "118.46667, 7"
                                }
                            ]
                        },
                        {
                            "Id": "BPRP",
                            "Name": "Borongan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "125.43194, 11.608056",
                            "Airports": [
                                {
                                    "Id": "BPR",
                                    "Name": "Borongan Airport",
                                    "CityId": "BPRP",
                                    "CountryId": "PH",
                                    "Location": "125.47861, 11.674167"
                                }
                            ]
                        },
                        {
                            "Id": "AAVP",
                            "Name": "Alah",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "124.77222, 6.1958333",
                            "IataCode": "AAV",
                            "Airports": [
                                {
                                    "Id": "AAV",
                                    "Name": "Alah",
                                    "CityId": "AAVP",
                                    "CountryId": "PH",
                                    "Location": "124.76667, 6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SGSP",
                            "Name": "Sanga Sanga",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "119.78528, 5.0725",
                            "IataCode": "SGS",
                            "Airports": [
                                {
                                    "Id": "SGS",
                                    "Name": "Sanga Sanga",
                                    "CityId": "SGSP",
                                    "CountryId": "PH",
                                    "Location": "119.743, 5.04699"
                                }
                            ]
                        },
                        {
                            "Id": "MRQP",
                            "Name": "Marinduque",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.96667, 13.4",
                            "IataCode": "MRQ",
                            "Airports": [
                                {
                                    "Id": "MRQ",
                                    "Name": "Marinduque",
                                    "CityId": "MRQP",
                                    "CountryId": "PH",
                                    "Location": "121.82361, 13.363056"
                                }
                            ]
                        },
                        {
                            "Id": "TBHP",
                            "Name": "Tablas",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "122.03333, 12.383333",
                            "IataCode": "TBH",
                            "Airports": [
                                {
                                    "Id": "TBH",
                                    "Name": "Tablas",
                                    "CityId": "TBHP",
                                    "CountryId": "PH",
                                    "Location": "122.07935, 12.309521"
                                }
                            ]
                        },
                        {
                            "Id": "SIAR",
                            "Name": "Siargao Island",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "126.05, 9.866667",
                            "IataCode": "IAO",
                            "Airports": [
                                {
                                    "Id": "IAO",
                                    "Name": "Siargao Island",
                                    "CityId": "SIAR",
                                    "CountryId": "PH",
                                    "Location": "126.01537, 9.855132"
                                }
                            ]
                        },
                        {
                            "Id": "TGBP",
                            "Name": "Pasirputih",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "126.35, 1.3166667",
                            "IataCode": "TGB",
                            "Airports": [
                                {
                                    "Id": "TGB",
                                    "Name": "Tagbita",
                                    "CityId": "TGBP",
                                    "CountryId": "PH",
                                    "Location": "126.78333, 1.416667"
                                }
                            ]
                        },
                        {
                            "Id": "CGMP",
                            "Name": "Mambit",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.86667, 18.9",
                            "IataCode": "CGM",
                            "Airports": [
                                {
                                    "Id": "CGM",
                                    "Name": "Camiguin",
                                    "CityId": "CGMP",
                                    "CountryId": "PH",
                                    "Location": "121.91667, 18.9"
                                }
                            ]
                        },
                        {
                            "Id": "LWAP",
                            "Name": "Lubuagan",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.17667, 17.356667",
                            "IataCode": "LWA",
                            "Airports": [
                                {
                                    "Id": "LWA",
                                    "Name": "Lwbak",
                                    "CityId": "LWAP",
                                    "CountryId": "PH",
                                    "Location": "121.18333, 17.35"
                                }
                            ]
                        },
                        {
                            "Id": "DSGP",
                            "Name": "Kalsada",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "120.01667, 13.883333",
                            "IataCode": "DSG",
                            "Airports": [
                                {
                                    "Id": "DSG",
                                    "Name": "Dilasag",
                                    "CityId": "DSGP",
                                    "CountryId": "PH",
                                    "Location": "120, 14"
                                }
                            ]
                        },
                        {
                            "Id": "BNQP",
                            "Name": "Camp Good Hope",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "121.05, 13.25",
                            "IataCode": "BNQ",
                            "Airports": [
                                {
                                    "Id": "BNQ",
                                    "Name": "Baganga",
                                    "CityId": "BNQP",
                                    "CountryId": "PH",
                                    "Location": "121.05, 13.25"
                                }
                            ]
                        },
                        {
                            "Id": "ICOP",
                            "Name": "Alipato",
                            "SingleAirportCity": true,
                            "CountryId": "PH",
                            "Location": "123.25472, 11.438889",
                            "IataCode": "ICO",
                            "Airports": [
                                {
                                    "Id": "ICO",
                                    "Name": "Sicogon Island",
                                    "CityId": "ICOP",
                                    "CountryId": "PH",
                                    "Location": "123.26667, 11.433333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "ID",
                    "Name": "Indonesia",
                    "CurrencyId": "IDR",
                    "Cities": [
                        {
                            "Id": "MSII",
                            "Name": "Masalembo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.43333, -5.583333",
                            "IataCode": "MSI",
                            "Airports": [
                                {
                                    "Id": "MSI",
                                    "Name": "Masalembo",
                                    "CityId": "MSII",
                                    "CountryId": "ID",
                                    "Location": "114.43333, -5.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MESI",
                            "Name": "Medan",
                            "SingleAirportCity": false,
                            "CountryId": "ID",
                            "Location": "98.666667, 3.5833333",
                            "IataCode": "MES",
                            "Airports": [
                                {
                                    "Id": "KNO",
                                    "Name": "Medan Kuala Namu",
                                    "CityId": "MESI",
                                    "CountryId": "ID",
                                    "Location": "98.876556, 3.650824"
                                },
                                {
                                    "Id": "MES",
                                    "Name": "Medan",
                                    "CityId": "MESI",
                                    "CountryId": "ID",
                                    "Location": "98.672778, 3.561111"
                                }
                            ]
                        },
                        {
                            "Id": "WGPI",
                            "Name": "Waingapu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.26667, -9.65",
                            "IataCode": "WGP",
                            "Airports": [
                                {
                                    "Id": "WGP",
                                    "Name": "Waingapu",
                                    "CityId": "WGPI",
                                    "CountryId": "ID",
                                    "Location": "120.3, -9.666667"
                                }
                            ]
                        },
                        {
                            "Id": "ABUI",
                            "Name": "Atambua",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "124.8925, -9.1061111",
                            "IataCode": "ABU",
                            "Airports": [
                                {
                                    "Id": "ABU",
                                    "Name": "Atambua",
                                    "CityId": "ABUI",
                                    "CountryId": "ID",
                                    "Location": "124.88333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "AMQI",
                            "Name": "Ambon",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "128.2, -3.7166667",
                            "IataCode": "AMQ",
                            "Airports": [
                                {
                                    "Id": "AMQ",
                                    "Name": "Ambon",
                                    "CityId": "AMQI",
                                    "CountryId": "ID",
                                    "Location": "128.08914, -3.71026"
                                }
                            ]
                        },
                        {
                            "Id": "NTII",
                            "Name": "Bintuni",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.53333, -2.1166667",
                            "IataCode": "NTI",
                            "Airports": [
                                {
                                    "Id": "NTI",
                                    "Name": "Bintuni",
                                    "CityId": "NTII",
                                    "CountryId": "ID",
                                    "Location": "133.25, -2.333333"
                                }
                            ]
                        },
                        {
                            "Id": "ZRII",
                            "Name": "Serui",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.23333, -1.8833333",
                            "IataCode": "ZRI",
                            "Airports": [
                                {
                                    "Id": "ZRI",
                                    "Name": "Sudjarwo Tjondronegoro",
                                    "CityId": "ZRII",
                                    "CountryId": "ID",
                                    "Location": "136.23951, -1.874397"
                                }
                            ]
                        },
                        {
                            "Id": "BXBI",
                            "Name": "Babo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.41667, -2.55",
                            "IataCode": "BXB",
                            "Airports": [
                                {
                                    "Id": "BXB",
                                    "Name": "Babo",
                                    "CityId": "BXBI",
                                    "CountryId": "ID",
                                    "Location": "133.41667, -2.55"
                                }
                            ]
                        },
                        {
                            "Id": "EWII",
                            "Name": "Enarotali",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.35, -3.9166667",
                            "IataCode": "EWI",
                            "Airports": [
                                {
                                    "Id": "EWI",
                                    "Name": "Enarotali",
                                    "CityId": "EWII",
                                    "CountryId": "ID",
                                    "Location": "136.33333, -3.966667"
                                }
                            ]
                        },
                        {
                            "Id": "LSXI",
                            "Name": "Lhok Sukon",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "97.3203, 5.0404",
                            "IataCode": "LSX",
                            "Airports": [
                                {
                                    "Id": "LSX",
                                    "Name": "Lhok Sukon",
                                    "CityId": "LSXI",
                                    "CountryId": "ID",
                                    "Location": "97.316667, 5.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BDOI",
                            "Name": "Bandung",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "107.62055, -6.9127777",
                            "IataCode": "BDO",
                            "Airports": [
                                {
                                    "Id": "BDO",
                                    "Name": "Bandung",
                                    "CityId": "BDOI",
                                    "CountryId": "ID",
                                    "Location": "107.57583, -6.901667"
                                }
                            ]
                        },
                        {
                            "Id": "BIKI",
                            "Name": "Biak",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.1, -1.1666667",
                            "IataCode": "BIK",
                            "Airports": [
                                {
                                    "Id": "BIK",
                                    "Name": "Biak",
                                    "CityId": "BIKI",
                                    "CountryId": "ID",
                                    "Location": "136.08556, -1.186667"
                                }
                            ]
                        },
                        {
                            "Id": "BJKI",
                            "Name": "Benjina",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.26667, -6.0666667",
                            "IataCode": "BJK",
                            "Airports": [
                                {
                                    "Id": "BJK",
                                    "Name": "Benjina",
                                    "CityId": "BJKI",
                                    "CountryId": "ID",
                                    "Location": "134.25, -6.083333"
                                }
                            ]
                        },
                        {
                            "Id": "BJWI",
                            "Name": "Bajawa",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.98333, -8.7833333",
                            "IataCode": "BJW",
                            "Airports": [
                                {
                                    "Id": "BJW",
                                    "Name": "Bajawa",
                                    "CityId": "BJWI",
                                    "CountryId": "ID",
                                    "Location": "120.98333, -8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "BKSI",
                            "Name": "Bengkulu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "102.26554, -3.800443",
                            "IataCode": "BKS",
                            "Airports": [
                                {
                                    "Id": "BKS",
                                    "Name": "Bengkulu",
                                    "CityId": "BKSI",
                                    "CountryId": "ID",
                                    "Location": "102.33887, -3.861238"
                                }
                            ]
                        },
                        {
                            "Id": "BMUI",
                            "Name": "Bima",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "118.71667, -8.4666667",
                            "IataCode": "BMU",
                            "Airports": [
                                {
                                    "Id": "BMU",
                                    "Name": "Bima",
                                    "CityId": "BMUI",
                                    "CountryId": "ID",
                                    "Location": "118.7, -8.55"
                                }
                            ]
                        },
                        {
                            "Id": "BPNI",
                            "Name": "Balikpapan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.82887, -1.26753",
                            "IataCode": "BPN",
                            "Airports": [
                                {
                                    "Id": "BPN",
                                    "Name": "Balikpapan",
                                    "CityId": "BPNI",
                                    "CountryId": "ID",
                                    "Location": "116.89444, -1.268333"
                                }
                            ]
                        },
                        {
                            "Id": "BTJI",
                            "Name": "Banda Aceh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "95.325833, 5.5616667",
                            "IataCode": "BTJ",
                            "Airports": [
                                {
                                    "Id": "BTJ",
                                    "Name": "Banda Aceh",
                                    "CityId": "BTJI",
                                    "CountryId": "ID",
                                    "Location": "95.419444, 5.52"
                                }
                            ]
                        },
                        {
                            "Id": "BXDI",
                            "Name": "Bade",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "139.58333, -7.1666667",
                            "IataCode": "BXD",
                            "Airports": [
                                {
                                    "Id": "BXD",
                                    "Name": "Bade",
                                    "CityId": "BXDI",
                                    "CountryId": "ID",
                                    "Location": "139.5, -7.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BXTI",
                            "Name": "Bontang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.5, 0.1333333",
                            "IataCode": "BXT",
                            "Airports": [
                                {
                                    "Id": "BXT",
                                    "Name": "Bontang",
                                    "CityId": "BXTI",
                                    "CountryId": "ID",
                                    "Location": "117.5, 0.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CBNI",
                            "Name": "Cirebon",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "108.56667, -6.7333333",
                            "IataCode": "CBN",
                            "Airports": [
                                {
                                    "Id": "CBN",
                                    "Name": "Cirebon",
                                    "CityId": "CBNI",
                                    "CountryId": "ID",
                                    "Location": "108.53333, -6.75"
                                }
                            ]
                        },
                        {
                            "Id": "CGKI",
                            "Name": "Jakarta",
                            "SingleAirportCity": false,
                            "CountryId": "ID",
                            "Location": "106.68212, -5.6283286",
                            "IataCode": "JKT",
                            "Airports": [
                                {
                                    "Id": "HLP",
                                    "Name": "Halim Perdana Kusuma",
                                    "CityId": "CGKI",
                                    "CountryId": "ID",
                                    "Location": "106.89028, -6.268056"
                                },
                                {
                                    "Id": "CGK",
                                    "Name": "Soekarno-Hatta",
                                    "CityId": "CGKI",
                                    "CountryId": "ID",
                                    "Location": "106.65, -6.116667"
                                }
                            ]
                        },
                        {
                            "Id": "CPFI",
                            "Name": "Cepu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "111.58333, -7.15",
                            "IataCode": "CPF",
                            "Airports": [
                                {
                                    "Id": "CPF",
                                    "Name": "Cepu",
                                    "CityId": "CPFI",
                                    "CountryId": "ID",
                                    "Location": "111.58333, -7.15"
                                }
                            ]
                        },
                        {
                            "Id": "CXPI",
                            "Name": "Cilacap",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "109.00944, -7.7263889",
                            "IataCode": "CXP",
                            "Airports": [
                                {
                                    "Id": "CXP",
                                    "Name": "Cilacap",
                                    "CityId": "CXPI",
                                    "CountryId": "ID",
                                    "Location": "109, -7.733333"
                                }
                            ]
                        },
                        {
                            "Id": "DJBI",
                            "Name": "Jambi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.61667, -1.6",
                            "IataCode": "DJB",
                            "Airports": [
                                {
                                    "Id": "DJB",
                                    "Name": "Jambi",
                                    "CityId": "DJBI",
                                    "CountryId": "ID",
                                    "Location": "103.64167, -1.634722"
                                }
                            ]
                        },
                        {
                            "Id": "DUMI",
                            "Name": "Dumai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "101.45, 1.6833333",
                            "IataCode": "DUM",
                            "Airports": [
                                {
                                    "Id": "DUM",
                                    "Name": "Dumai",
                                    "CityId": "DUMI",
                                    "CountryId": "ID",
                                    "Location": "101.46667, 1.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ENEI",
                            "Name": "Ende",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.65, -8.8333333",
                            "IataCode": "ENE",
                            "Airports": [
                                {
                                    "Id": "ENE",
                                    "Name": "Ende",
                                    "CityId": "ENEI",
                                    "CountryId": "ID",
                                    "Location": "121.65, -8.833333"
                                }
                            ]
                        },
                        {
                            "Id": "GNSI",
                            "Name": "Gunungsitoli",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "97.6143, 1.2888",
                            "IataCode": "GNS",
                            "Airports": [
                                {
                                    "Id": "GNS",
                                    "Name": "Gunungsitoli",
                                    "CityId": "GNSI",
                                    "CountryId": "ID",
                                    "Location": "97.70468, 1.16638"
                                }
                            ]
                        },
                        {
                            "Id": "GTOI",
                            "Name": "Gorontalo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "123.06167, 0.5344444",
                            "IataCode": "GTO",
                            "Airports": [
                                {
                                    "Id": "GTO",
                                    "Name": "Gorontalo",
                                    "CityId": "GTOI",
                                    "CountryId": "ID",
                                    "Location": "122.84994, 0.638438"
                                }
                            ]
                        },
                        {
                            "Id": "INXI",
                            "Name": "Inanwatan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.16667, -2.1333333",
                            "IataCode": "INX",
                            "Airports": [
                                {
                                    "Id": "INX",
                                    "Name": "Inanwatan",
                                    "CityId": "INXI",
                                    "CountryId": "ID",
                                    "Location": "132.23333, -2.166667"
                                }
                            ]
                        },
                        {
                            "Id": "JOGI",
                            "Name": "Yogyakarta",
                            "SingleAirportCity": false,
                            "CountryId": "ID",
                            "Location": "110.36083, -7.7827778",
                            "IataCode": "JOG",
                            "Airports": [
                                {
                                    "Id": "YIA",
                                    "Name": "Yogyakarta International",
                                    "CityId": "JOGI",
                                    "CountryId": "ID",
                                    "Location": "110.05444, -7.907499"
                                },
                                {
                                    "Id": "JOG",
                                    "Name": "Yogyakarta",
                                    "CityId": "JOGI",
                                    "CountryId": "ID",
                                    "Location": "110.43056, -7.789722"
                                }
                            ]
                        },
                        {
                            "Id": "KNGI",
                            "Name": "Kaimana",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.75, -3.65",
                            "IataCode": "KNG",
                            "Airports": [
                                {
                                    "Id": "KNG",
                                    "Name": "Utarom / Kaimana",
                                    "CityId": "KNGI",
                                    "CountryId": "ID",
                                    "Location": "133.69555, -3.64452"
                                }
                            ]
                        },
                        {
                            "Id": "KOEA",
                            "Name": "Kupang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "123.58333, -10.166667",
                            "IataCode": "KOE",
                            "Airports": [
                                {
                                    "Id": "KOE",
                                    "Name": "Kupang",
                                    "CityId": "KOEA",
                                    "CountryId": "ID",
                                    "Location": "123.66776, -10.168741"
                                }
                            ]
                        },
                        {
                            "Id": "KBUI",
                            "Name": "Kotabaru",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.21667, -3.2333333",
                            "IataCode": "KBU",
                            "Airports": [
                                {
                                    "Id": "KBU",
                                    "Name": "Kotabaru",
                                    "CityId": "KBUI",
                                    "CountryId": "ID",
                                    "Location": "116.33333, -3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "KDII",
                            "Name": "Kendari",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.49889, -3.945",
                            "IataCode": "KDI",
                            "Airports": [
                                {
                                    "Id": "KDI",
                                    "Name": "Kendari",
                                    "CityId": "KDII",
                                    "CountryId": "ID",
                                    "Location": "122.41729, -4.077237"
                                }
                            ]
                        },
                        {
                            "Id": "KEII",
                            "Name": "Kepi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "139.31667, -6.5333333",
                            "IataCode": "KEI",
                            "Airports": [
                                {
                                    "Id": "KEI",
                                    "Name": "Kepi",
                                    "CityId": "KEII",
                                    "CountryId": "ID",
                                    "Location": "139.33333, -6.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KTGI",
                            "Name": "Ketapang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "109.98333, -1.8666667",
                            "IataCode": "KTG",
                            "Airports": [
                                {
                                    "Id": "KTG",
                                    "Name": "Ketapang",
                                    "CityId": "KTGI",
                                    "CountryId": "ID",
                                    "Location": "109.98333, -1.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LHII",
                            "Name": "Lereh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "139.9, -3.1333333",
                            "IataCode": "LHI",
                            "Airports": [
                                {
                                    "Id": "LHI",
                                    "Name": "Lereh",
                                    "CityId": "LHII",
                                    "CountryId": "ID",
                                    "Location": "139.9, -3.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LIII",
                            "Name": "Mulia",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "137.96667, -3.7333333",
                            "IataCode": "LII",
                            "Airports": [
                                {
                                    "Id": "LII",
                                    "Name": "Mulia",
                                    "CityId": "LIII",
                                    "CountryId": "ID",
                                    "Location": "137.96667, -3.733333"
                                }
                            ]
                        },
                        {
                            "Id": "LKAI",
                            "Name": "Larantuka",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.98333, -8.35",
                            "IataCode": "LKA",
                            "Airports": [
                                {
                                    "Id": "LKA",
                                    "Name": "Larantuka",
                                    "CityId": "LKAI",
                                    "CountryId": "ID",
                                    "Location": "122.98333, -8.35"
                                }
                            ]
                        },
                        {
                            "Id": "LSWI",
                            "Name": "Lhoksumawe",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "97.133333, 5.1666667",
                            "IataCode": "LSW",
                            "Airports": [
                                {
                                    "Id": "LSW",
                                    "Name": "Lhoksumawe",
                                    "CityId": "LSWI",
                                    "CountryId": "ID",
                                    "Location": "96.95034, 5.22668"
                                }
                            ]
                        },
                        {
                            "Id": "LUVI",
                            "Name": "Langgur",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.71667, -5.6666667",
                            "IataCode": "LUV",
                            "Airports": [
                                {
                                    "Id": "LUV",
                                    "Name": "Dumatubin - Langgur",
                                    "CityId": "LUVI",
                                    "CountryId": "ID",
                                    "Location": "132.71667, -5.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LUWI",
                            "Name": "Banggai (Luwuk)",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.78861, -0.9558333",
                            "IataCode": "LUW",
                            "Airports": [
                                {
                                    "Id": "LUW",
                                    "Name": "Banggai (Luwuk)",
                                    "CityId": "LUWI",
                                    "CountryId": "ID",
                                    "Location": "122.78333, -0.933333"
                                }
                            ]
                        },
                        {
                            "Id": "LWEI",
                            "Name": "Lewoleba",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "123.4, -8.3833333",
                            "IataCode": "LWE",
                            "Airports": [
                                {
                                    "Id": "LWE",
                                    "Name": "Lewoleba",
                                    "CityId": "LWEI",
                                    "CountryId": "ID",
                                    "Location": "123.36667, -8.5"
                                }
                            ]
                        },
                        {
                            "Id": "LYKI",
                            "Name": "Lunyuk",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.23333, -8.95",
                            "IataCode": "LYK",
                            "Airports": [
                                {
                                    "Id": "LYK",
                                    "Name": "Lunyuk",
                                    "CityId": "LYKI",
                                    "CountryId": "ID",
                                    "Location": "117.26667, -9"
                                }
                            ]
                        },
                        {
                            "Id": "MDCI",
                            "Name": "Manado",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "124.84417, 1.5016667",
                            "IataCode": "MDC",
                            "Airports": [
                                {
                                    "Id": "MDC",
                                    "Name": "Manado",
                                    "CityId": "MDCI",
                                    "CountryId": "ID",
                                    "Location": "124.92583, 1.549444"
                                }
                            ]
                        },
                        {
                            "Id": "MLGI",
                            "Name": "Malang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.62806, -7.9777778",
                            "IataCode": "MLG",
                            "Airports": [
                                {
                                    "Id": "MLG",
                                    "Name": "Malang",
                                    "CityId": "MLGI",
                                    "CountryId": "ID",
                                    "Location": "112.71667, -7.933333"
                                }
                            ]
                        },
                        {
                            "Id": "PDGI",
                            "Name": "Padang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "100.35427, -0.94924398",
                            "IataCode": "PDG",
                            "Airports": [
                                {
                                    "Id": "PDG",
                                    "Name": "Padang",
                                    "CityId": "PDGI",
                                    "CountryId": "ID",
                                    "Location": "100.35, -0.875556"
                                }
                            ]
                        },
                        {
                            "Id": "PKUI",
                            "Name": "Pekanbaru",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "101.45, 0.5333333",
                            "IataCode": "PKU",
                            "Airports": [
                                {
                                    "Id": "PKU",
                                    "Name": "Pekanbaru",
                                    "CityId": "PKUI",
                                    "CountryId": "ID",
                                    "Location": "101.45, 0.466667"
                                }
                            ]
                        },
                        {
                            "Id": "PLMI",
                            "Name": "Palembang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "104.7458, -2.9167253",
                            "IataCode": "PLM",
                            "Airports": [
                                {
                                    "Id": "PLM",
                                    "Name": "Palembang",
                                    "CityId": "PLMI",
                                    "CountryId": "ID",
                                    "Location": "104.69944, -2.9"
                                }
                            ]
                        },
                        {
                            "Id": "PNKI",
                            "Name": "Pontianak",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "109.33333, -0.0333333",
                            "IataCode": "PNK",
                            "Airports": [
                                {
                                    "Id": "PNK",
                                    "Name": "Pontianak",
                                    "CityId": "PNKI",
                                    "CountryId": "ID",
                                    "Location": "109.4, -0.15"
                                }
                            ]
                        },
                        {
                            "Id": "SUBI",
                            "Name": "Surabaya",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.75083, -7.2491666",
                            "IataCode": "SUB",
                            "Airports": [
                                {
                                    "Id": "SUB",
                                    "Name": "Surabaya",
                                    "CityId": "SUBI",
                                    "CountryId": "ID",
                                    "Location": "112.78694, -7.379722"
                                }
                            ]
                        },
                        {
                            "Id": "SMQI",
                            "Name": "Sampit",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.95, -2.5333333",
                            "IataCode": "SMQ",
                            "Airports": [
                                {
                                    "Id": "SMQ",
                                    "Name": "Sampit",
                                    "CityId": "SMQI",
                                    "CountryId": "ID",
                                    "Location": "112.97507, -2.499798"
                                }
                            ]
                        },
                        {
                            "Id": "TKGI",
                            "Name": "Bandar Lampung",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "105.26667, -5.45",
                            "IataCode": "TKG",
                            "Airports": [
                                {
                                    "Id": "TKG",
                                    "Name": "Bandar Lampung",
                                    "CityId": "TKGI",
                                    "CountryId": "ID",
                                    "Location": "105.17675, -5.24088"
                                }
                            ]
                        },
                        {
                            "Id": "TRKI",
                            "Name": "Tarakan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.63333, 3.3",
                            "IataCode": "TRK",
                            "Airports": [
                                {
                                    "Id": "TRK",
                                    "Name": "Tarakan",
                                    "CityId": "TRKI",
                                    "CountryId": "ID",
                                    "Location": "117.56528, 3.327222"
                                }
                            ]
                        },
                        {
                            "Id": "TSYI",
                            "Name": "Tasikmalaya",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "108.2, -7.3333333",
                            "IataCode": "TSY",
                            "Airports": [
                                {
                                    "Id": "TSY",
                                    "Name": "Tasikmalaya",
                                    "CityId": "TSYI",
                                    "CountryId": "ID",
                                    "Location": "108.2, -7.3"
                                }
                            ]
                        },
                        {
                            "Id": "UOLI",
                            "Name": "Buol",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.43333, 1.1666667",
                            "IataCode": "UOL",
                            "Airports": [
                                {
                                    "Id": "UOL",
                                    "Name": "Buol",
                                    "CityId": "UOLI",
                                    "CountryId": "ID",
                                    "Location": "121.43333, 1.166667"
                                }
                            ]
                        },
                        {
                            "Id": "ARJI",
                            "Name": "Arso",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.78333, -2.9333333",
                            "IataCode": "ARJ",
                            "Airports": [
                                {
                                    "Id": "ARJ",
                                    "Name": "Arso",
                                    "CityId": "ARJI",
                                    "CountryId": "ID",
                                    "Location": "140.78333, -2.933333"
                                }
                            ]
                        },
                        {
                            "Id": "MEQI",
                            "Name": "Meulaboh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "96.129444, 4.1366667",
                            "IataCode": "MEQ",
                            "Airports": [
                                {
                                    "Id": "MEQ",
                                    "Name": "Meulaboh",
                                    "CityId": "MEQI",
                                    "CountryId": "ID",
                                    "Location": "96.116667, 4.15"
                                }
                            ]
                        },
                        {
                            "Id": "MKQI",
                            "Name": "Merauke",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.33333, -8.4666667",
                            "IataCode": "MKQ",
                            "Airports": [
                                {
                                    "Id": "MKQ",
                                    "Name": "Merauke",
                                    "CityId": "MKQI",
                                    "CountryId": "ID",
                                    "Location": "140.5, -8.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MKWI",
                            "Name": "Manokwari",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.08333, -0.8666667",
                            "IataCode": "MKW",
                            "Airports": [
                                {
                                    "Id": "MKW",
                                    "Name": "Manokwari",
                                    "CityId": "MKWI",
                                    "CountryId": "ID",
                                    "Location": "134.05278, -0.893056"
                                }
                            ]
                        },
                        {
                            "Id": "MXBI",
                            "Name": "Masamba",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.3275, -2.5530556",
                            "IataCode": "MXB",
                            "Airports": [
                                {
                                    "Id": "MXB",
                                    "Name": "Masamba",
                                    "CityId": "MXBI",
                                    "CountryId": "ID",
                                    "Location": "120.33333, -2.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PGKI",
                            "Name": "Pangkalpinang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "106.13333, -2.1333333",
                            "IataCode": "PGK",
                            "Airports": [
                                {
                                    "Id": "PGK",
                                    "Name": "Pangkalpinang",
                                    "CityId": "PGKI",
                                    "CountryId": "ID",
                                    "Location": "106.14111, -2.160556"
                                }
                            ]
                        },
                        {
                            "Id": "PKNI",
                            "Name": "Pangkalanbuun",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "111.61667, -2.6833333",
                            "IataCode": "PKN",
                            "Airports": [
                                {
                                    "Id": "PKN",
                                    "Name": "Pangkalanbuun",
                                    "CityId": "PKNI",
                                    "CountryId": "ID",
                                    "Location": "111.66667, -2.7"
                                }
                            ]
                        },
                        {
                            "Id": "PKYI",
                            "Name": "Palangkaraya",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "113.83333, -2.2",
                            "IataCode": "PKY",
                            "Airports": [
                                {
                                    "Id": "PKY",
                                    "Name": "Palangkaraya",
                                    "CityId": "PKYI",
                                    "CountryId": "ID",
                                    "Location": "113.93333, -2.216667"
                                }
                            ]
                        },
                        {
                            "Id": "PLWI",
                            "Name": "Palu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "119.85972, -0.9016667",
                            "IataCode": "PLW",
                            "Airports": [
                                {
                                    "Id": "PLW",
                                    "Name": "Palu",
                                    "CityId": "PLWI",
                                    "CountryId": "ID",
                                    "Location": "119.9, -0.916667"
                                }
                            ]
                        },
                        {
                            "Id": "PSUI",
                            "Name": "Putussibau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.93333, 0.8333333",
                            "IataCode": "PSU",
                            "Airports": [
                                {
                                    "Id": "PSU",
                                    "Name": "Putussibau",
                                    "CityId": "PSUI",
                                    "CountryId": "ID",
                                    "Location": "112.93333, 0.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RAQI",
                            "Name": "Raha",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.72389, -4.8311111",
                            "IataCode": "RAQ",
                            "Airports": [
                                {
                                    "Id": "RAQ",
                                    "Name": "Raha",
                                    "CityId": "RAQI",
                                    "CountryId": "ID",
                                    "Location": "122.58333, -4.916667"
                                }
                            ]
                        },
                        {
                            "Id": "RGTI",
                            "Name": "Rengat",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "102.55, -0.4",
                            "IataCode": "RGT",
                            "Airports": [
                                {
                                    "Id": "RGT",
                                    "Name": "Rengat",
                                    "CityId": "RGTI",
                                    "CountryId": "ID",
                                    "Location": "102.55, -0.4"
                                }
                            ]
                        },
                        {
                            "Id": "RTGI",
                            "Name": "Ruteng",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.45, -8.6",
                            "IataCode": "RTG",
                            "Airports": [
                                {
                                    "Id": "RTG",
                                    "Name": "Ruteng",
                                    "CityId": "RTGI",
                                    "CountryId": "ID",
                                    "Location": "120.45, -8.6"
                                }
                            ]
                        },
                        {
                            "Id": "SOQI",
                            "Name": "Sorong",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "131.25, -0.8833333",
                            "IataCode": "SOQ",
                            "Airports": [
                                {
                                    "Id": "SOQ",
                                    "Name": "Sorong",
                                    "CityId": "SOQI",
                                    "CountryId": "ID",
                                    "Location": "131.11667, -0.933333"
                                }
                            ]
                        },
                        {
                            "Id": "SQGI",
                            "Name": "Sintang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "111.5, 0.0666667",
                            "IataCode": "SQG",
                            "Airports": [
                                {
                                    "Id": "SQG",
                                    "Name": "Sintang",
                                    "CityId": "SQGI",
                                    "CountryId": "ID",
                                    "Location": "111.58333, 0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SRII",
                            "Name": "Samarinda",
                            "SingleAirportCity": false,
                            "CountryId": "ID",
                            "Location": "117.15, -0.5",
                            "IataCode": "SRI",
                            "Airports": [
                                {
                                    "Id": "AAP",
                                    "Name": "AP Tumenggung Pranoto",
                                    "CityId": "SRII",
                                    "CountryId": "ID",
                                    "Location": "117.25556, -0.373611"
                                },
                                {
                                    "Id": "SRI",
                                    "Name": "Samarinda",
                                    "CityId": "SRII",
                                    "CountryId": "ID",
                                    "Location": "117.15, -0.5"
                                }
                            ]
                        },
                        {
                            "Id": "SWQI",
                            "Name": "Sumbawa",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.43333, -8.5",
                            "IataCode": "SWQ",
                            "Airports": [
                                {
                                    "Id": "SWQ",
                                    "Name": "Sumbawa",
                                    "CityId": "SWQI",
                                    "CountryId": "ID",
                                    "Location": "117.41667, -8.5"
                                }
                            ]
                        },
                        {
                            "Id": "TTEI",
                            "Name": "Ternate",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.4, 0.8",
                            "IataCode": "TTE",
                            "Airports": [
                                {
                                    "Id": "TTE",
                                    "Name": "Ternate",
                                    "CityId": "TTEI",
                                    "CountryId": "ID",
                                    "Location": "127.4, 0.8"
                                }
                            ]
                        },
                        {
                            "Id": "DRHI",
                            "Name": "Dabra",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.31667, -2.8",
                            "IataCode": "DRH",
                            "Airports": [
                                {
                                    "Id": "DRH",
                                    "Name": "Dabra",
                                    "CityId": "DRHI",
                                    "CountryId": "ID",
                                    "Location": "138.56667, -3.25"
                                }
                            ]
                        },
                        {
                            "Id": "LAHI",
                            "Name": "Labuha",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.48333, -0.616667",
                            "IataCode": "LAH",
                            "Airports": [
                                {
                                    "Id": "LAH",
                                    "Name": "Labuha",
                                    "CityId": "LAHI",
                                    "CountryId": "ID",
                                    "Location": "127.48333, -0.616667"
                                }
                            ]
                        },
                        {
                            "Id": "LLNI",
                            "Name": "Kelila",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.75, -3.5833333",
                            "IataCode": "LLN",
                            "Airports": [
                                {
                                    "Id": "LLN",
                                    "Name": "Kelila",
                                    "CityId": "LLNI",
                                    "CountryId": "ID",
                                    "Location": "138.66667, -3.75"
                                }
                            ]
                        },
                        {
                            "Id": "OBDI",
                            "Name": "Obano",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.21667, -3.9",
                            "IataCode": "OBD",
                            "Airports": [
                                {
                                    "Id": "OBD",
                                    "Name": "Obano",
                                    "CityId": "OBDI",
                                    "CountryId": "ID",
                                    "Location": "136.2, -3.9"
                                }
                            ]
                        },
                        {
                            "Id": "MDPI",
                            "Name": "Mindiptana",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.36667, -5.75",
                            "IataCode": "MDP",
                            "Airports": [
                                {
                                    "Id": "MDP",
                                    "Name": "Mindiptana",
                                    "CityId": "MDPI",
                                    "CountryId": "ID",
                                    "Location": "140.83333, -5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MJUI",
                            "Name": "Mamuju",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "118.89333, -2.6786111",
                            "IataCode": "MJU",
                            "Airports": [
                                {
                                    "Id": "MJU",
                                    "Name": "Mamuju",
                                    "CityId": "MJUI",
                                    "CountryId": "ID",
                                    "Location": "118.895, -2.67877"
                                }
                            ]
                        },
                        {
                            "Id": "MOFI",
                            "Name": "Maumere",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.23333, -8.6166667",
                            "IataCode": "MOF",
                            "Airports": [
                                {
                                    "Id": "MOF",
                                    "Name": "Maumere",
                                    "CityId": "MOFI",
                                    "CountryId": "ID",
                                    "Location": "122.25, -8.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MUFI",
                            "Name": "Muting",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.33333, -7.3833333",
                            "IataCode": "MUF",
                            "Airports": [
                                {
                                    "Id": "MUF",
                                    "Name": "Muting",
                                    "CityId": "MUFI",
                                    "CountryId": "ID",
                                    "Location": "140.33333, -7.5"
                                }
                            ]
                        },
                        {
                            "Id": "NAMI",
                            "Name": "Namlea",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.1, -3.3",
                            "IataCode": "NAM",
                            "Airports": [
                                {
                                    "Id": "NAM",
                                    "Name": "Namlea",
                                    "CityId": "NAMI",
                                    "CountryId": "ID",
                                    "Location": "127.1, -3.3"
                                }
                            ]
                        },
                        {
                            "Id": "NBXI",
                            "Name": "Nabire",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "135.48333, -3.3666667",
                            "IataCode": "NBX",
                            "Airports": [
                                {
                                    "Id": "NBX",
                                    "Name": "Nabire",
                                    "CityId": "NBXI",
                                    "CountryId": "ID",
                                    "Location": "135.43333, -3.366667"
                                }
                            ]
                        },
                        {
                            "Id": "NPOI",
                            "Name": "Nangapinoh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "111.73333, -0.3333333",
                            "IataCode": "NPO",
                            "Airports": [
                                {
                                    "Id": "NPO",
                                    "Name": "Nangapinoh",
                                    "CityId": "NPOI",
                                    "CountryId": "ID",
                                    "Location": "111.73333, -0.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OKQI",
                            "Name": "Okaba",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "139.7, -8.1",
                            "IataCode": "OKQ",
                            "Airports": [
                                {
                                    "Id": "OKQ",
                                    "Name": "Okaba",
                                    "CityId": "OKQI",
                                    "CountryId": "ID",
                                    "Location": "139.7, -8.1"
                                }
                            ]
                        },
                        {
                            "Id": "PDOI",
                            "Name": "Pendopo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.86667, -3.2833333",
                            "IataCode": "PDO",
                            "Airports": [
                                {
                                    "Id": "PDO",
                                    "Name": "Pendopo",
                                    "CityId": "PDOI",
                                    "CountryId": "ID",
                                    "Location": "103.86667, -3.283333"
                                }
                            ]
                        },
                        {
                            "Id": "PWLI",
                            "Name": "Purwokerto",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "109.23444, -7.4213889",
                            "IataCode": "PWL",
                            "Airports": [
                                {
                                    "Id": "PWL",
                                    "Name": "Purwokerto",
                                    "CityId": "PWLI",
                                    "CountryId": "ID",
                                    "Location": "109.15, -7.466667"
                                }
                            ]
                        },
                        {
                            "Id": "RSKI",
                            "Name": "Ransiki",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.16667, -1.5",
                            "IataCode": "RSK",
                            "Airports": [
                                {
                                    "Id": "RSK",
                                    "Name": "Ransiki",
                                    "CityId": "RSKI",
                                    "CountryId": "ID",
                                    "Location": "134.18333, -1.5"
                                }
                            ]
                        },
                        {
                            "Id": "UBRI",
                            "Name": "Ubrub",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.8, -3.7333333",
                            "IataCode": "UBR",
                            "Airports": [
                                {
                                    "Id": "UBR",
                                    "Name": "Ubrub",
                                    "CityId": "UBRI",
                                    "CountryId": "ID",
                                    "Location": "140.85, -3.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SXKI",
                            "Name": "Saumlaki",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "131.31667, -7.95",
                            "IataCode": "SXK",
                            "Airports": [
                                {
                                    "Id": "SXK",
                                    "Name": "Saumlaki",
                                    "CityId": "SXKI",
                                    "CountryId": "ID",
                                    "Location": "131.31667, -7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SZHI",
                            "Name": "Senipah",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.16667, -0.95",
                            "IataCode": "SZH",
                            "Airports": [
                                {
                                    "Id": "SZH",
                                    "Name": "Senipah",
                                    "CityId": "SZHI",
                                    "CountryId": "ID",
                                    "Location": "117.13333, -0.966667"
                                }
                            ]
                        },
                        {
                            "Id": "TLII",
                            "Name": "Tolitoli",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.81361, 1.0425",
                            "IataCode": "TLI",
                            "Airports": [
                                {
                                    "Id": "TLI",
                                    "Name": "Tolitoli",
                                    "CityId": "TLII",
                                    "CountryId": "ID",
                                    "Location": "120.81667, 1.033333"
                                }
                            ]
                        },
                        {
                            "Id": "TMCI",
                            "Name": "Tambolaka",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "119.238, -9.4283",
                            "IataCode": "TMC",
                            "Airports": [
                                {
                                    "Id": "TMC",
                                    "Name": "Tambolaka",
                                    "CityId": "TMCI",
                                    "CountryId": "ID",
                                    "Location": "119.18306, -9.340278"
                                }
                            ]
                        },
                        {
                            "Id": "TMHI",
                            "Name": "Tanahmerah",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.28333, -6.0833333",
                            "IataCode": "TMH",
                            "Airports": [
                                {
                                    "Id": "TMH",
                                    "Name": "Tanahmerah",
                                    "CityId": "TMHI",
                                    "CountryId": "ID",
                                    "Location": "140.26667, -6.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TMYI",
                            "Name": "Tiom",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.36667, -3.8666667",
                            "IataCode": "TMY",
                            "Airports": [
                                {
                                    "Id": "TMY",
                                    "Name": "Tiom",
                                    "CityId": "TMYI",
                                    "CountryId": "ID",
                                    "Location": "138.41667, -3.95"
                                }
                            ]
                        },
                        {
                            "Id": "TXMI",
                            "Name": "Teminabuan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.01667, -1.4333333",
                            "IataCode": "TXM",
                            "Airports": [
                                {
                                    "Id": "TXM",
                                    "Name": "Teminabuan",
                                    "CityId": "TXMI",
                                    "CountryId": "ID",
                                    "Location": "132.01667, -1.433333"
                                }
                            ]
                        },
                        {
                            "Id": "WARI",
                            "Name": "Waris",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.88333, -3.1166667",
                            "IataCode": "WAR",
                            "Airports": [
                                {
                                    "Id": "WAR",
                                    "Name": "Waris",
                                    "CityId": "WARI",
                                    "CountryId": "ID",
                                    "Location": "140.88333, -3.116667"
                                }
                            ]
                        },
                        {
                            "Id": "WMXI",
                            "Name": "Wamena",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.73333, -3.9166667",
                            "IataCode": "WMX",
                            "Airports": [
                                {
                                    "Id": "WMX",
                                    "Name": "Wamena",
                                    "CityId": "WMXI",
                                    "CountryId": "ID",
                                    "Location": "138.93333, -4.116667"
                                }
                            ]
                        },
                        {
                            "Id": "WSRI",
                            "Name": "Wasior",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.5, -2.7166667",
                            "IataCode": "WSR",
                            "Airports": [
                                {
                                    "Id": "WSR",
                                    "Name": "Wasior",
                                    "CityId": "WSRI",
                                    "CountryId": "ID",
                                    "Location": "134.5, -2.716667"
                                }
                            ]
                        },
                        {
                            "Id": "KODI",
                            "Name": "Kotabangun",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.58333, -0.2666667",
                            "IataCode": "KOD",
                            "Airports": [
                                {
                                    "Id": "KOD",
                                    "Name": "Kotabangun",
                                    "CityId": "KODI",
                                    "CountryId": "ID",
                                    "Location": "116.58333, -0.266667"
                                }
                            ]
                        },
                        {
                            "Id": "SRGI",
                            "Name": "Semarang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "110.41667, -6.9666667",
                            "IataCode": "SRG",
                            "Airports": [
                                {
                                    "Id": "SRG",
                                    "Name": "Semarang",
                                    "CityId": "SRGI",
                                    "CountryId": "ID",
                                    "Location": "110.38, -6.975833"
                                }
                            ]
                        },
                        {
                            "Id": "UPGI",
                            "Name": "Makassar",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "119.43861, -5.1463889",
                            "IataCode": "UPG",
                            "Airports": [
                                {
                                    "Id": "UPG",
                                    "Name": "Makassar",
                                    "CityId": "UPGI",
                                    "CountryId": "ID",
                                    "Location": "119.55, -5.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BDJI",
                            "Name": "Banjarmasin",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.58333, -3.3333333",
                            "IataCode": "BDJ",
                            "Airports": [
                                {
                                    "Id": "BDJ",
                                    "Name": "Banjarmasin",
                                    "CityId": "BDJI",
                                    "CountryId": "ID",
                                    "Location": "114.75572, -3.440015"
                                }
                            ]
                        },
                        {
                            "Id": "SOCI",
                            "Name": "Solo / Surakarta",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "110.83167, -7.5561111",
                            "IataCode": "SOC",
                            "Airports": [
                                {
                                    "Id": "SOC",
                                    "Name": "Solo / Surakarta",
                                    "CityId": "SOCI",
                                    "CountryId": "ID",
                                    "Location": "110.75694, -7.516111"
                                }
                            ]
                        },
                        {
                            "Id": "DJJI",
                            "Name": "Jayapura",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.7, -2.5333333",
                            "IataCode": "DJJ",
                            "Airports": [
                                {
                                    "Id": "DJJ",
                                    "Name": "Jayapura",
                                    "CityId": "DJJI",
                                    "CountryId": "ID",
                                    "Location": "140.51389, -2.575"
                                }
                            ]
                        },
                        {
                            "Id": "BWXA",
                            "Name": "Banyuwangi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.35, -8.2",
                            "IataCode": "BWX",
                            "Airports": [
                                {
                                    "Id": "BWX",
                                    "Name": "Blimbingsari",
                                    "CityId": "BWXA",
                                    "CountryId": "ID",
                                    "Location": "114.34028, -8.31"
                                }
                            ]
                        },
                        {
                            "Id": "TJQI",
                            "Name": "Belitung (Tanjung Pandan)",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "107.65, -2.75",
                            "IataCode": "TJQ",
                            "Airports": [
                                {
                                    "Id": "TJQ",
                                    "Name": "Belitung (Tanjung Pandan)",
                                    "CityId": "TJQI",
                                    "CountryId": "ID",
                                    "Location": "107.75381, -2.754014"
                                }
                            ]
                        },
                        {
                            "Id": "KRCI",
                            "Name": "Kerinci",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "101.38333, -2.0833333",
                            "IataCode": "KRC",
                            "Airports": [
                                {
                                    "Id": "KRC",
                                    "Name": "Kerinci",
                                    "CityId": "KRCI",
                                    "CountryId": "ID",
                                    "Location": "101.25, -1.716667"
                                }
                            ]
                        },
                        {
                            "Id": "TPKI",
                            "Name": "Tapaktuan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "97.18, 3.2582",
                            "IataCode": "TPK",
                            "Airports": [
                                {
                                    "Id": "TPK",
                                    "Name": "Tapaktuan",
                                    "CityId": "TPKI",
                                    "CountryId": "ID",
                                    "Location": "97.183333, 3.266667"
                                }
                            ]
                        },
                        {
                            "Id": "TXEI",
                            "Name": "Takengon",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "96.8467, 4.6212",
                            "IataCode": "TXE",
                            "Airports": [
                                {
                                    "Id": "TXE",
                                    "Name": "Rembele",
                                    "CityId": "TXEI",
                                    "CountryId": "ID",
                                    "Location": "96.849444, 4.720833"
                                }
                            ]
                        },
                        {
                            "Id": "SINB",
                            "Name": "Sinabang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "96.3801, 2.4803",
                            "IataCode": "LSG",
                            "Airports": [
                                {
                                    "Id": "LSG",
                                    "Name": "Sinabang",
                                    "CityId": "SINB",
                                    "CountryId": "ID",
                                    "Location": "96.329167, 2.416667"
                                }
                            ]
                        },
                        {
                            "Id": "DTBA",
                            "Name": "Siborong-borong",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "98.983333, 2.2166667",
                            "IataCode": "DTB",
                            "Airports": [
                                {
                                    "Id": "DTB",
                                    "Name": "Siborong-borong",
                                    "CityId": "DTBA",
                                    "CountryId": "ID",
                                    "Location": "98.993838, 2.258886"
                                }
                            ]
                        },
                        {
                            "Id": "FLZA",
                            "Name": "Sibolga",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "98.8, 1.75",
                            "IataCode": "FLZ",
                            "Airports": [
                                {
                                    "Id": "FLZ",
                                    "Name": "Ferdinand Lumban Tobing",
                                    "CityId": "FLZA",
                                    "CountryId": "ID",
                                    "Location": "98.887854, 1.55637"
                                }
                            ]
                        },
                        {
                            "Id": "SBGI",
                            "Name": "Sabang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "95.321389, 5.8933333",
                            "IataCode": "SBG",
                            "Airports": [
                                {
                                    "Id": "SBG",
                                    "Name": "Sabang",
                                    "CityId": "SBGI",
                                    "CountryId": "ID",
                                    "Location": "95.333333, 5.55"
                                }
                            ]
                        },
                        {
                            "Id": "KUTC",
                            "Name": "Kutacane",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "97.8175, 3.5052",
                            "Airports": [
                                {
                                    "Id": "KUB",
                                    "Name": "Kutacane",
                                    "CityId": "KUTC",
                                    "CountryId": "ID",
                                    "Location": "97.841492, 3.426034"
                                }
                            ]
                        },
                        {
                            "Id": "RJMI",
                            "Name": "Waisai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "130.51667, -0.2333333",
                            "IataCode": "RJM",
                            "Airports": [
                                {
                                    "Id": "RJM",
                                    "Name": "Marinda",
                                    "CityId": "RJMI",
                                    "CountryId": "ID",
                                    "Location": "130.77362, -0.430536"
                                }
                            ]
                        },
                        {
                            "Id": "WBAI",
                            "Name": "Wahai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "129.5, -2.8",
                            "IataCode": "WBA",
                            "Airports": [
                                {
                                    "Id": "WBA",
                                    "Name": "Wahai",
                                    "CityId": "WBAI",
                                    "CountryId": "ID",
                                    "Location": "129.48333, -2.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TIII",
                            "Name": "Tembagapura",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "137, -4.3333333",
                            "IataCode": "TIM",
                            "Airports": [
                                {
                                    "Id": "TIM",
                                    "Name": "Tembagapura",
                                    "CityId": "TIII",
                                    "CountryId": "ID",
                                    "Location": "136.91667, -4.533333"
                                }
                            ]
                        },
                        {
                            "Id": "TJSI",
                            "Name": "Tanjung Selor",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.36667, 2.85",
                            "IataCode": "TJS",
                            "Airports": [
                                {
                                    "Id": "TJS",
                                    "Name": "Tanjung Selor",
                                    "CityId": "TJSI",
                                    "CountryId": "ID",
                                    "Location": "117.36667, 2.85"
                                }
                            ]
                        },
                        {
                            "Id": "TNJI",
                            "Name": "Tanjung Pinang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "104.45, 0.9166667",
                            "IataCode": "TNJ",
                            "Airports": [
                                {
                                    "Id": "TNJ",
                                    "Name": "Tanjung Pinang",
                                    "CityId": "TNJI",
                                    "CountryId": "ID",
                                    "Location": "104.528, 0.92014"
                                }
                            ]
                        },
                        {
                            "Id": "TJGI",
                            "Name": "Tanjung Warukin",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.96667, -1.3833333",
                            "IataCode": "TJG",
                            "Airports": [
                                {
                                    "Id": "TJG",
                                    "Name": "Tanjung Warukin",
                                    "CityId": "TJGI",
                                    "CountryId": "ID",
                                    "Location": "103.96667, -1.4"
                                }
                            ]
                        },
                        {
                            "Id": "TNBI",
                            "Name": "Tanah Grogot",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.2, -1.9166667",
                            "IataCode": "TNB",
                            "Airports": [
                                {
                                    "Id": "TNB",
                                    "Name": "Tanah Grogot",
                                    "CityId": "TNBI",
                                    "CountryId": "ID",
                                    "Location": "116.2, -1.916667"
                                }
                            ]
                        },
                        {
                            "Id": "SUPI",
                            "Name": "Sumenep",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "113.86667, -7.0166667",
                            "IataCode": "SUP",
                            "Airports": [
                                {
                                    "Id": "SUP",
                                    "Name": "Sumenep",
                                    "CityId": "SUPI",
                                    "CountryId": "ID",
                                    "Location": "113.93333, -7.066667"
                                }
                            ]
                        },
                        {
                            "Id": "SGQI",
                            "Name": "Sanggata",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.55, 0.4666667",
                            "IataCode": "SGQ",
                            "Airports": [
                                {
                                    "Id": "SGQ",
                                    "Name": "Sanggata",
                                    "CityId": "SGQI",
                                    "CountryId": "ID",
                                    "Location": "117.33333, 0.5"
                                }
                            ]
                        },
                        {
                            "Id": "NTXI",
                            "Name": "Natuna Ranai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "108.38333, 3.916667",
                            "IataCode": "NTX",
                            "Airports": [
                                {
                                    "Id": "NTX",
                                    "Name": "Natuna Ranai",
                                    "CityId": "NTXI",
                                    "CountryId": "ID",
                                    "Location": "108.38333, 3.95"
                                }
                            ]
                        },
                        {
                            "Id": "LOPA",
                            "Name": "Praya",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.28333, -8.7",
                            "IataCode": "LOP",
                            "Airports": [
                                {
                                    "Id": "LOP",
                                    "Name": "Lombok International",
                                    "CityId": "LOPA",
                                    "CountryId": "ID",
                                    "Location": "116.27667, -8.757322"
                                }
                            ]
                        },
                        {
                            "Id": "PSJI",
                            "Name": "Poso",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.75, -1.3983333",
                            "IataCode": "PSJ",
                            "Airports": [
                                {
                                    "Id": "PSJ",
                                    "Name": "Poso",
                                    "CityId": "PSJI",
                                    "CountryId": "ID",
                                    "Location": "120.73333, -1.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PUMI",
                            "Name": "Pomala",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.6, -4.05",
                            "IataCode": "PUM",
                            "Airports": [
                                {
                                    "Id": "PUM",
                                    "Name": "Pomala",
                                    "CityId": "PUMI",
                                    "CountryId": "ID",
                                    "Location": "121.61667, -4.183333"
                                }
                            ]
                        },
                        {
                            "Id": "LLOI",
                            "Name": "Palopo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.19694, -2.9925",
                            "IataCode": "LLO",
                            "Airports": [
                                {
                                    "Id": "LLO",
                                    "Name": "Lagaligo",
                                    "CityId": "LLOI",
                                    "CountryId": "ID",
                                    "Location": "120.24528, -3.084722"
                                }
                            ]
                        },
                        {
                            "Id": "NNXI",
                            "Name": "Nunukan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.6507, 4.1384143",
                            "IataCode": "NNX",
                            "Airports": [
                                {
                                    "Id": "NNX",
                                    "Name": "Nunukan",
                                    "CityId": "NNXI",
                                    "CountryId": "ID",
                                    "Location": "117.65, 4.15"
                                }
                            ]
                        },
                        {
                            "Id": "NREI",
                            "Name": "Namrole",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "126.7, -3.85",
                            "IataCode": "NRE",
                            "Airports": [
                                {
                                    "Id": "NRE",
                                    "Name": "Namrole",
                                    "CityId": "NREI",
                                    "CountryId": "ID",
                                    "Location": "126.71667, -3.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MPCI",
                            "Name": "Muko-Muko",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "101.11667, -2.5833333",
                            "IataCode": "MPC",
                            "Airports": [
                                {
                                    "Id": "MPC",
                                    "Name": "Muko-Muko",
                                    "CityId": "MPCI",
                                    "CountryId": "ID",
                                    "Location": "101.1, -2.55"
                                }
                            ]
                        },
                        {
                            "Id": "BUUI",
                            "Name": "Muara Bungo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "102.11667, -1.4666667",
                            "IataCode": "BUU",
                            "Airports": [
                                {
                                    "Id": "BUU",
                                    "Name": "Muara Bungo",
                                    "CityId": "BUUI",
                                    "CountryId": "ID",
                                    "Location": "102.18278, -1.5425"
                                }
                            ]
                        },
                        {
                            "Id": "MOHI",
                            "Name": "Morowali",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.5, -1.8666667",
                            "IataCode": "MOH",
                            "Airports": [
                                {
                                    "Id": "MOH",
                                    "Name": "Maleo",
                                    "CityId": "MOHI",
                                    "CountryId": "ID",
                                    "Location": "121.66028, -2.203333"
                                }
                            ]
                        },
                        {
                            "Id": "MAII",
                            "Name": "Mangole",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "125.91667, -1.9",
                            "IataCode": "MAL",
                            "Airports": [
                                {
                                    "Id": "MAL",
                                    "Name": "Mangole",
                                    "CityId": "MAII",
                                    "CountryId": "ID",
                                    "Location": "125.91667, -1.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MILU",
                            "Name": "Malinau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.63333, 3.5833333",
                            "IataCode": "LNU",
                            "Airports": [
                                {
                                    "Id": "LNU",
                                    "Name": "Kolonel RA Bessing",
                                    "CityId": "MILU",
                                    "CountryId": "ID",
                                    "Location": "116.61972, 3.575278"
                                }
                            ]
                        },
                        {
                            "Id": "KJTA",
                            "Name": "Majalengka",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "108.22778, -6.8361111",
                            "IataCode": "KJT",
                            "Airports": [
                                {
                                    "Id": "KJT",
                                    "Name": "Kertajati International",
                                    "CityId": "KJTA",
                                    "CountryId": "ID",
                                    "Location": "108.15759, -6.65097"
                                }
                            ]
                        },
                        {
                            "Id": "LLJI",
                            "Name": "Lubuk Linggau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "102.86667, -3.3",
                            "IataCode": "LLJ",
                            "Airports": [
                                {
                                    "Id": "LLJ",
                                    "Name": "Silampari",
                                    "CityId": "LLJI",
                                    "CountryId": "ID",
                                    "Location": "102.91455, -3.283912"
                                }
                            ]
                        },
                        {
                            "Id": "PXAI",
                            "Name": "Lahat",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.53333, -3.8",
                            "IataCode": "PXA",
                            "Airports": [
                                {
                                    "Id": "PXA",
                                    "Name": "Pagar Alam",
                                    "CityId": "PXAI",
                                    "CountryId": "ID",
                                    "Location": "103.37977, -4.022201"
                                }
                            ]
                        },
                        {
                            "Id": "LBJI",
                            "Name": "Labuan Bajo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "119.9, -8.4833333",
                            "IataCode": "LBJ",
                            "Airports": [
                                {
                                    "Id": "LBJ",
                                    "Name": "Komodo",
                                    "CityId": "LBJI",
                                    "CountryId": "ID",
                                    "Location": "119.88807, -8.488771"
                                }
                            ]
                        },
                        {
                            "Id": "KLQI",
                            "Name": "Keluang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.9, -2.6833333",
                            "IataCode": "KLQ",
                            "Airports": [
                                {
                                    "Id": "KLQ",
                                    "Name": "Keluang",
                                    "CityId": "KLQI",
                                    "CountryId": "ID",
                                    "Location": "103.9, -2.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KAZI",
                            "Name": "North Halmahera (Kau)",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.9, 1.183333",
                            "IataCode": "KAZ",
                            "Airports": [
                                {
                                    "Id": "KAZ",
                                    "Name": "North Halmahera (Kau)",
                                    "CityId": "KAZI",
                                    "CountryId": "ID",
                                    "Location": "127.86667, 1.166667"
                                }
                            ]
                        },
                        {
                            "Id": "KWBI",
                            "Name": "Karimunjawa",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "110.43333, -5.8666667",
                            "IataCode": "KWB",
                            "Airports": [
                                {
                                    "Id": "KWB",
                                    "Name": "Karimunjawa",
                                    "CityId": "KWBI",
                                    "CountryId": "ID",
                                    "Location": "110.5, -5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JBBA",
                            "Name": "Jember",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "113.70317, -8.1660391",
                            "IataCode": "JBB",
                            "Airports": [
                                {
                                    "Id": "JBB",
                                    "Name": "Noto Hadinegoro",
                                    "CityId": "JBBA",
                                    "CountryId": "ID",
                                    "Location": "113.69389, -8.241111"
                                }
                            ]
                        },
                        {
                            "Id": "GLXI",
                            "Name": "Galela",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.83333, 1.8333333",
                            "IataCode": "GLX",
                            "Airports": [
                                {
                                    "Id": "GLX",
                                    "Name": "Galela",
                                    "CityId": "GLXI",
                                    "CountryId": "ID",
                                    "Location": "127.83333, 1.833333"
                                }
                            ]
                        },
                        {
                            "Id": "FKQI",
                            "Name": "Fak Fak",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.3, -2.9166667",
                            "IataCode": "FKQ",
                            "Airports": [
                                {
                                    "Id": "FKQ",
                                    "Name": "Fak Fak",
                                    "CityId": "FKQI",
                                    "CountryId": "ID",
                                    "Location": "132.3, -2.916667"
                                }
                            ]
                        },
                        {
                            "Id": "DOBI",
                            "Name": "Dobo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.21667, -5.7666667",
                            "IataCode": "DOB",
                            "Airports": [
                                {
                                    "Id": "DOB",
                                    "Name": "Dobo",
                                    "CityId": "DOBI",
                                    "CountryId": "ID",
                                    "Location": "134, -5.75"
                                }
                            ]
                        },
                        {
                            "Id": "BYQI",
                            "Name": "Bunyu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.8, 3.5",
                            "IataCode": "BYQ",
                            "Airports": [
                                {
                                    "Id": "BYQ",
                                    "Name": "Bunyu",
                                    "CityId": "BYQI",
                                    "CountryId": "ID",
                                    "Location": "117.83333, 3.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PGQA",
                            "Name": "Buli",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "128.3, 0.8833333",
                            "IataCode": "PGQ",
                            "Airports": [
                                {
                                    "Id": "PGQ",
                                    "Name": "Buli Airport",
                                    "CityId": "PGQA",
                                    "CountryId": "ID",
                                    "Location": "128.38306, 0.9175"
                                }
                            ]
                        },
                        {
                            "Id": "BJGI",
                            "Name": "Bolaang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.11194, -0.9605556",
                            "IataCode": "BJG",
                            "Airports": [
                                {
                                    "Id": "BJG",
                                    "Name": "Bolaang",
                                    "CityId": "BJGI",
                                    "CountryId": "ID",
                                    "Location": "122.1, -0.95"
                                }
                            ]
                        },
                        {
                            "Id": "BUWI",
                            "Name": "Baubau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.63333, -5.4666667",
                            "IataCode": "BUW",
                            "Airports": [
                                {
                                    "Id": "BUW",
                                    "Name": "Baubau",
                                    "CityId": "BUWI",
                                    "CountryId": "ID",
                                    "Location": "122.63333, -5.466667"
                                }
                            ]
                        },
                        {
                            "Id": "BTWI",
                            "Name": "Batu Licin",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116, -3.45",
                            "IataCode": "BTW",
                            "Airports": [
                                {
                                    "Id": "BTW",
                                    "Name": "Batu Licin",
                                    "CityId": "BTWI",
                                    "CountryId": "ID",
                                    "Location": "116, -3"
                                }
                            ]
                        },
                        {
                            "Id": "NDAI",
                            "Name": "Bandanaira",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "129.9, -4.5333333",
                            "IataCode": "NDA",
                            "Airports": [
                                {
                                    "Id": "NDA",
                                    "Name": "Bandanaira",
                                    "CityId": "NDAI",
                                    "CountryId": "ID",
                                    "Location": "129.9, -4.533333"
                                }
                            ]
                        },
                        {
                            "Id": "VPMA",
                            "Name": "Ampana",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.57111, -0.8713889",
                            "IataCode": "VPM",
                            "Airports": [
                                {
                                    "Id": "VPM",
                                    "Name": "Tanjung Api",
                                    "CityId": "VPMA",
                                    "CountryId": "ID",
                                    "Location": "121.62283, -0.865735"
                                }
                            ]
                        },
                        {
                            "Id": "AHII",
                            "Name": "Amahai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "128.91667, -3.3333333",
                            "IataCode": "AHI",
                            "Airports": [
                                {
                                    "Id": "AHI",
                                    "Name": "Amahai",
                                    "CityId": "AHII",
                                    "CountryId": "ID",
                                    "Location": "128.91667, -3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "EWEI",
                            "Name": "Ewer",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.13333, -5.55",
                            "IataCode": "EWE",
                            "Airports": [
                                {
                                    "Id": "EWE",
                                    "Name": "Ewer",
                                    "CityId": "EWEI",
                                    "CountryId": "ID",
                                    "Location": "138.08333, -5.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SAUI",
                            "Name": "Sawu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "121.96667, -10.533333",
                            "IataCode": "SAU",
                            "Airports": [
                                {
                                    "Id": "SAU",
                                    "Name": "Sawu",
                                    "CityId": "SAUI",
                                    "CountryId": "ID",
                                    "Location": "121.9, -10.483333"
                                }
                            ]
                        },
                        {
                            "Id": "RTII",
                            "Name": "Roti",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "123.38333, -10.466667",
                            "IataCode": "RTI",
                            "Airports": [
                                {
                                    "Id": "RTI",
                                    "Name": "Roti",
                                    "CityId": "RTII",
                                    "CountryId": "ID",
                                    "Location": "122.96667, -10.883333"
                                }
                            ]
                        },
                        {
                            "Id": "AEGI",
                            "Name": "Aek Godang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "98.8976, 1.9335",
                            "IataCode": "AEG",
                            "Airports": [
                                {
                                    "Id": "AEG",
                                    "Name": "Aek Godang",
                                    "CityId": "AEGI",
                                    "CountryId": "ID",
                                    "Location": "99.430276, 1.400235"
                                }
                            ]
                        },
                        {
                            "Id": "NAHI",
                            "Name": "Naha",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "125.5326, 3.6922",
                            "IataCode": "NAH",
                            "Airports": [
                                {
                                    "Id": "NAH",
                                    "Name": "Naha",
                                    "CityId": "NAHI",
                                    "CountryId": "ID",
                                    "Location": "125.52802, 3.68321"
                                }
                            ]
                        },
                        {
                            "Id": "TTRI",
                            "Name": "Tana Toraja",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "119.8188, -2.8836",
                            "IataCode": "TTR",
                            "Airports": [
                                {
                                    "Id": "TTR",
                                    "Name": "Tana Toraja",
                                    "CityId": "TTRI",
                                    "CountryId": "ID",
                                    "Location": "119.91667, -3.416667"
                                }
                            ]
                        },
                        {
                            "Id": "KSRI",
                            "Name": "Selayar Islands",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "120.5, -6",
                            "IataCode": "KSR",
                            "Airports": [
                                {
                                    "Id": "KSR",
                                    "Name": "Haji Aroeppala",
                                    "CityId": "KSRI",
                                    "CountryId": "ID",
                                    "Location": "120.43786, -6.179272"
                                }
                            ]
                        },
                        {
                            "Id": "SIQI",
                            "Name": "Singkep",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "104.41667, -0.5",
                            "IataCode": "SIQ",
                            "Airports": [
                                {
                                    "Id": "SIQ",
                                    "Name": "Singkep",
                                    "CityId": "SIQI",
                                    "CountryId": "ID",
                                    "Location": "104.41667, -0.5"
                                }
                            ]
                        },
                        {
                            "Id": "ARDI",
                            "Name": "Alor Island",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "125.34172, -8.515",
                            "IataCode": "ARD",
                            "Airports": [
                                {
                                    "Id": "ARD",
                                    "Name": "Alor Island",
                                    "CityId": "ARDI",
                                    "CountryId": "ID",
                                    "Location": "124.597, -8.13234"
                                }
                            ]
                        },
                        {
                            "Id": "OTII",
                            "Name": "Morotai Island",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "128.53333, 2.316667",
                            "IataCode": "OTI",
                            "Airports": [
                                {
                                    "Id": "OTI",
                                    "Name": "Morotai Island",
                                    "CityId": "OTII",
                                    "CountryId": "ID",
                                    "Location": "128.5, 2.833333"
                                }
                            ]
                        },
                        {
                            "Id": "SQNI",
                            "Name": "Sanana",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "125.91667, -2.2",
                            "IataCode": "SQN",
                            "Airports": [
                                {
                                    "Id": "SQN",
                                    "Name": "Sanana",
                                    "CityId": "SQNI",
                                    "CountryId": "ID",
                                    "Location": "125.91667, -2.2"
                                }
                            ]
                        },
                        {
                            "Id": "TAXI",
                            "Name": "Taliabu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "124.8, -1.8",
                            "IataCode": "TAX",
                            "Airports": [
                                {
                                    "Id": "TAX",
                                    "Name": "Taliabu",
                                    "CityId": "TAXI",
                                    "CountryId": "ID",
                                    "Location": "124.8, -1.766667"
                                }
                            ]
                        },
                        {
                            "Id": "FOOI",
                            "Name": "Numfoor",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.86667, -1",
                            "IataCode": "FOO",
                            "Airports": [
                                {
                                    "Id": "FOO",
                                    "Name": "Numfoor",
                                    "CityId": "FOOI",
                                    "CountryId": "ID",
                                    "Location": "134.9, -1.05"
                                }
                            ]
                        },
                        {
                            "Id": "BTHI",
                            "Name": "Batam",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "104.01306, 1.1387266",
                            "IataCode": "BTH",
                            "Airports": [
                                {
                                    "Id": "BTH",
                                    "Name": "Batam",
                                    "CityId": "BTHI",
                                    "CountryId": "ID",
                                    "Location": "104.11889, 1.121111"
                                }
                            ]
                        },
                        {
                            "Id": "GAVI",
                            "Name": "Amata",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "131.15, -26.166667",
                            "IataCode": "GAV",
                            "Airports": [
                                {
                                    "Id": "GAV",
                                    "Name": "Gag Island",
                                    "CityId": "GAVI",
                                    "CountryId": "ID",
                                    "Location": "129.87831, -0.456614"
                                }
                            ]
                        },
                        {
                            "Id": "DPSI",
                            "Name": "Denpasar",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "115.16669, -8.7467293",
                            "IataCode": "DPS",
                            "Airports": [
                                {
                                    "Id": "DPS",
                                    "Name": "Bali (Denpasar)",
                                    "CityId": "DPSI",
                                    "CountryId": "ID",
                                    "Location": "115.16669, -8.7467293"
                                }
                            ]
                        },
                        {
                            "Id": "UGUI",
                            "Name": "Zoatalipa",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.95, -3.7333333",
                            "IataCode": "UGU",
                            "Airports": [
                                {
                                    "Id": "UGU",
                                    "Name": "Zugapa",
                                    "CityId": "UGUI",
                                    "CountryId": "ID",
                                    "Location": "137.03333, -3.733333"
                                }
                            ]
                        },
                        {
                            "Id": "RDEI",
                            "Name": "Wauje",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.3, -1.7333333",
                            "IataCode": "RDE",
                            "Airports": [
                                {
                                    "Id": "RDE",
                                    "Name": "Merdey",
                                    "CityId": "RDEI",
                                    "CountryId": "ID",
                                    "Location": "133.33333, -1.583333"
                                }
                            ]
                        },
                        {
                            "Id": "KEQI",
                            "Name": "Watulai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "134.78333, -5.8166667",
                            "IataCode": "KEQ",
                            "Airports": [
                                {
                                    "Id": "KEQ",
                                    "Name": "Kebar",
                                    "CityId": "KEQI",
                                    "CountryId": "ID",
                                    "Location": "134.83333, -5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KMMI",
                            "Name": "Waipa",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.25, -3.8",
                            "IataCode": "KMM",
                            "Airports": [
                                {
                                    "Id": "KMM",
                                    "Name": "Kimam",
                                    "CityId": "KMMI",
                                    "CountryId": "ID",
                                    "Location": "136.16667, -3.666667"
                                }
                            ]
                        },
                        {
                            "Id": "AKQI",
                            "Name": "Umbulan Miring",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "105.23333, -4.5833333",
                            "IataCode": "AKQ",
                            "Airports": [
                                {
                                    "Id": "AKQ",
                                    "Name": "Astraksetra",
                                    "CityId": "AKQI",
                                    "CountryId": "ID",
                                    "Location": "105.23333, -4.616667"
                                }
                            ]
                        },
                        {
                            "Id": "BEJI",
                            "Name": "Ulak",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.432, 2.1555",
                            "IataCode": "BEJ",
                            "Airports": [
                                {
                                    "Id": "BEJ",
                                    "Name": "Berau",
                                    "CityId": "BEJI",
                                    "CountryId": "ID",
                                    "Location": "117.432, 2.1555"
                                }
                            ]
                        },
                        {
                            "Id": "MNAI",
                            "Name": "Tarun",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "126.7, 4.0666667",
                            "IataCode": "MNA",
                            "Airports": [
                                {
                                    "Id": "MNA",
                                    "Name": "Sangir Talaud (Melonguane)",
                                    "CityId": "MNAI",
                                    "CountryId": "ID",
                                    "Location": "126.7, 4.05"
                                }
                            ]
                        },
                        {
                            "Id": "MWKI",
                            "Name": "Tarempah",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "106.23333, 3.2333333",
                            "IataCode": "MWK",
                            "Airports": [
                                {
                                    "Id": "MWK",
                                    "Name": "Matak",
                                    "CityId": "MWKI",
                                    "CountryId": "ID",
                                    "Location": "106.26667, 3.3"
                                }
                            ]
                        },
                        {
                            "Id": "TSXI",
                            "Name": "Tanjungwira",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.43333, -0.05",
                            "IataCode": "TSX",
                            "Airports": [
                                {
                                    "Id": "TSX",
                                    "Name": "Tanjung Santan",
                                    "CityId": "TSXI",
                                    "CountryId": "ID",
                                    "Location": "117.43333, -0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TBMI",
                            "Name": "Tanjunglingga",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.55, -3.3333333",
                            "IataCode": "TBM",
                            "Airports": [
                                {
                                    "Id": "TBM",
                                    "Name": "Tumbang Samba",
                                    "CityId": "TBMI",
                                    "CountryId": "ID",
                                    "Location": "112.58333, -3.25"
                                }
                            ]
                        },
                        {
                            "Id": "SEQI",
                            "Name": "Sungaipinang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.06667, -0.8",
                            "IataCode": "SEQ",
                            "Airports": [
                                {
                                    "Id": "SEQ",
                                    "Name": "Sungai Pakning",
                                    "CityId": "SEQI",
                                    "CountryId": "ID",
                                    "Location": "114.06667, -0.8"
                                }
                            ]
                        },
                        {
                            "Id": "MJYI",
                            "Name": "Sugihwaras",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.55, -2.7666667",
                            "IataCode": "MJY",
                            "Airports": [
                                {
                                    "Id": "MJY",
                                    "Name": "Mangunjaya",
                                    "CityId": "MJYI",
                                    "CountryId": "ID",
                                    "Location": "103.56667, -2.733333"
                                }
                            ]
                        },
                        {
                            "Id": "JIOI",
                            "Name": "Siota",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "127.93333, -8.15",
                            "IataCode": "JIO",
                            "Airports": [
                                {
                                    "Id": "JIO",
                                    "Name": "Jos Orno Imsula",
                                    "CityId": "JIOI",
                                    "CountryId": "ID",
                                    "Location": "127.90722, -8.140556"
                                }
                            ]
                        },
                        {
                            "Id": "AASI",
                            "Name": "Sigi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "139, -3.55",
                            "IataCode": "AAS",
                            "Airports": [
                                {
                                    "Id": "AAS",
                                    "Name": "Apalapsili",
                                    "CityId": "AASI",
                                    "CountryId": "ID",
                                    "Location": "139.25, -3.916667"
                                }
                            ]
                        },
                        {
                            "Id": "RKOI",
                            "Name": "Sangbewai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.06667, -2.1",
                            "IataCode": "RKO",
                            "Airports": [
                                {
                                    "Id": "RKO",
                                    "Name": "Sipora",
                                    "CityId": "RKOI",
                                    "CountryId": "ID",
                                    "Location": "116, -2.083333"
                                }
                            ]
                        },
                        {
                            "Id": "KBXI",
                            "Name": "Sagumatoh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.25, -1.0333333",
                            "IataCode": "KBX",
                            "Airports": [
                                {
                                    "Id": "KBX",
                                    "Name": "Kambuaya",
                                    "CityId": "KBXI",
                                    "CountryId": "ID",
                                    "Location": "132.25, -1"
                                }
                            ]
                        },
                        {
                            "Id": "DEXA",
                            "Name": "Sabang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.78333, -4.7666667",
                            "IataCode": "DEX",
                            "Airports": [
                                {
                                    "Id": "DEX",
                                    "Name": "Nop Goliath",
                                    "CityId": "DEXA",
                                    "CountryId": "ID",
                                    "Location": "139.4854, -4.854012"
                                }
                            ]
                        },
                        {
                            "Id": "PPJI",
                            "Name": "Pulaukijang",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "103.2, -0.7",
                            "IataCode": "PPJ",
                            "Airports": [
                                {
                                    "Id": "PPJ",
                                    "Name": "Pulau Panjang",
                                    "CityId": "PPJI",
                                    "CountryId": "ID",
                                    "Location": "103.3, -0.633333"
                                }
                            ]
                        },
                        {
                            "Id": "GEBI",
                            "Name": "Omnial",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "129.6, -0.0666667",
                            "IataCode": "GEB",
                            "Airports": [
                                {
                                    "Id": "GEB",
                                    "Name": "Gebe",
                                    "CityId": "GEBI",
                                    "CountryId": "ID",
                                    "Location": "129.41667, 0.083333"
                                }
                            ]
                        },
                        {
                            "Id": "KBFI",
                            "Name": "Nalouk",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.43333, -3.6666667",
                            "IataCode": "KBF",
                            "Airports": [
                                {
                                    "Id": "KBF",
                                    "Name": "Karubaga",
                                    "CityId": "KBFI",
                                    "CountryId": "ID",
                                    "Location": "138.45, -3.716667"
                                }
                            ]
                        },
                        {
                            "Id": "MUAR",
                            "Name": "Muaratewe",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.88333, -0.95",
                            "IataCode": "KXH",
                            "Airports": [
                                {
                                    "Id": "KXH",
                                    "Name": "Muara Teweh",
                                    "CityId": "MUAR",
                                    "CountryId": "ID",
                                    "Location": "114.89444, -0.942778"
                                }
                            ]
                        },
                        {
                            "Id": "LPUI",
                            "Name": "Muara Bem-lelakidau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "114.96667, 1.6833333",
                            "IataCode": "LPU",
                            "Airports": [
                                {
                                    "Id": "LPU",
                                    "Name": "Long Apung",
                                    "CityId": "LPUI",
                                    "CountryId": "ID",
                                    "Location": "114.97278, 1.709167"
                                }
                            ]
                        },
                        {
                            "Id": "DTDI",
                            "Name": "Muaraatan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "116.51667, 0.8666667",
                            "IataCode": "DTD",
                            "Airports": [
                                {
                                    "Id": "DTD",
                                    "Name": "Datadawai",
                                    "CityId": "DTDI",
                                    "CountryId": "ID",
                                    "Location": "116.48333, 0.716667"
                                }
                            ]
                        },
                        {
                            "Id": "WETI",
                            "Name": "Mapia",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "135.86667, -4",
                            "IataCode": "WET",
                            "Airports": [
                                {
                                    "Id": "WET",
                                    "Name": "Wagethe",
                                    "CityId": "WETI",
                                    "CountryId": "ID",
                                    "Location": "135.83333, -4.166667"
                                }
                            ]
                        },
                        {
                            "Id": "LMUI",
                            "Name": "Mampo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "105.71667, 2.9833333",
                            "IataCode": "LMU",
                            "Airports": [
                                {
                                    "Id": "LMU",
                                    "Name": "Letung",
                                    "CityId": "LMUI",
                                    "CountryId": "ID",
                                    "Location": "105.75444, 2.964167"
                                }
                            ]
                        },
                        {
                            "Id": "LBWI",
                            "Name": "Longbangun",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "115.18333, 0.6",
                            "IataCode": "LBW",
                            "Airports": [
                                {
                                    "Id": "LBW",
                                    "Name": "Long Bawan",
                                    "CityId": "LBWI",
                                    "CountryId": "ID",
                                    "Location": "115.16667, 0.583333"
                                }
                            ]
                        },
                        {
                            "Id": "NAFI",
                            "Name": "Lembu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "117.08333, 2.7666667",
                            "IataCode": "NAF",
                            "Airports": [
                                {
                                    "Id": "NAF",
                                    "Name": "Banaina",
                                    "CityId": "NAFI",
                                    "CountryId": "ID",
                                    "Location": "117.05, 2.75"
                                }
                            ]
                        },
                        {
                            "Id": "AGDI",
                            "Name": "Lefikbrie",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.88333, -1.3833333",
                            "IataCode": "AGD",
                            "Airports": [
                                {
                                    "Id": "AGD",
                                    "Name": "Anggi",
                                    "CityId": "AGDI",
                                    "CountryId": "ID",
                                    "Location": "133.86667, -1.383333"
                                }
                            ]
                        },
                        {
                            "Id": "WNGI",
                            "Name": "Langga",
                            "SingleAirportCity": false,
                            "CountryId": "ID",
                            "Location": "123.61667, -5.3",
                            "IataCode": "WNI",
                            "Airports": [
                                {
                                    "Id": "WGI",
                                    "Name": "Wangi Wangi (Wakatobi)",
                                    "CityId": "WNGI",
                                    "CountryId": "ID",
                                    "Location": "123.58333, -5.316667"
                                },
                                {
                                    "Id": "WNI",
                                    "Name": "Matahora",
                                    "CityId": "WNGI",
                                    "CountryId": "ID",
                                    "Location": "123.63529, -5.291152"
                                }
                            ]
                        },
                        {
                            "Id": "AYWI",
                            "Name": "Kumurkek",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "132.51667, -1.2666667",
                            "IataCode": "AYW",
                            "Airports": [
                                {
                                    "Id": "AYW",
                                    "Name": "Ayawasi",
                                    "CityId": "AYWI",
                                    "CountryId": "ID",
                                    "Location": "132.5, -1.2"
                                }
                            ]
                        },
                        {
                            "Id": "RKII",
                            "Name": "Kubangnandua",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "100.73333, -0.9",
                            "IataCode": "RKI",
                            "Airports": [
                                {
                                    "Id": "RKI",
                                    "Name": "Rokot",
                                    "CityId": "RKII",
                                    "CountryId": "ID",
                                    "Location": "100.75, -0.95"
                                }
                            ]
                        },
                        {
                            "Id": "BUII",
                            "Name": "Koranu",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.7, -2.05",
                            "IataCode": "BUI",
                            "Airports": [
                                {
                                    "Id": "BUI",
                                    "Name": "Bokondini",
                                    "CityId": "BUII",
                                    "CountryId": "ID",
                                    "Location": "133.58333, -2"
                                }
                            ]
                        },
                        {
                            "Id": "KOXI",
                            "Name": "Kokenau",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "136.43333, -4.7166667",
                            "IataCode": "KOX",
                            "Airports": [
                                {
                                    "Id": "KOX",
                                    "Name": "Kokonao",
                                    "CityId": "KOXI",
                                    "CountryId": "ID",
                                    "Location": "136.41667, -4.716667"
                                }
                            ]
                        },
                        {
                            "Id": "ONII",
                            "Name": "Kimi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "135.56667, -3.2666667",
                            "IataCode": "ONI",
                            "Airports": [
                                {
                                    "Id": "ONI",
                                    "Name": "Moanamani",
                                    "CityId": "ONII",
                                    "CountryId": "ID",
                                    "Location": "135.5, -3.333333"
                                }
                            ]
                        },
                        {
                            "Id": "IULI",
                            "Name": "Kemboro",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.4, -3.5666667",
                            "IataCode": "IUL",
                            "Airports": [
                                {
                                    "Id": "IUL",
                                    "Name": "Ilu",
                                    "CityId": "IULI",
                                    "CountryId": "ID",
                                    "Location": "138.16667, -3.733333"
                                }
                            ]
                        },
                        {
                            "Id": "KCDI",
                            "Name": "Jahat",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "138.73333, -6.0666667",
                            "IataCode": "KCD",
                            "Airports": [
                                {
                                    "Id": "KCD",
                                    "Name": "Kamur",
                                    "CityId": "KCDI",
                                    "CountryId": "ID",
                                    "Location": "138.71667, -6.2"
                                }
                            ]
                        },
                        {
                            "Id": "ILAI",
                            "Name": "Airtiba",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "133.7, -3.65",
                            "IataCode": "ILA",
                            "Airports": [
                                {
                                    "Id": "ILA",
                                    "Name": "Illaga",
                                    "CityId": "ILAI",
                                    "CountryId": "ID",
                                    "Location": "133.73333, -3.65"
                                }
                            ]
                        },
                        {
                            "Id": "SQRI",
                            "Name": "Umbele",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "122.50167, -3.0616667",
                            "IataCode": "SQR",
                            "Airports": [
                                {
                                    "Id": "SQR",
                                    "Name": "Soroako",
                                    "CityId": "SQRI",
                                    "CountryId": "ID",
                                    "Location": "122.66667, -3.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PCBI",
                            "Name": "Cipayung Tiga",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "106.75833, -6.3297222",
                            "IataCode": "PCB",
                            "Airports": [
                                {
                                    "Id": "PCB",
                                    "Name": "Pondok Cabe",
                                    "CityId": "PCBI",
                                    "CountryId": "ID",
                                    "Location": "106.76667, -6.35"
                                }
                            ]
                        },
                        {
                            "Id": "SEHI",
                            "Name": "Pulau-kalong",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.78333, -3.4166667",
                            "IataCode": "SEH",
                            "Airports": [
                                {
                                    "Id": "SEH",
                                    "Name": "Senggeh",
                                    "CityId": "SEHI",
                                    "CountryId": "ID",
                                    "Location": "140.81667, -3.433333"
                                }
                            ]
                        },
                        {
                            "Id": "KEAI",
                            "Name": "Nakake",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.1, -7.0833333",
                            "IataCode": "KEA",
                            "Airports": [
                                {
                                    "Id": "KEA",
                                    "Name": "Keisah",
                                    "CityId": "KEAI",
                                    "CountryId": "ID",
                                    "Location": "140.03333, -7.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ELRI",
                            "Name": "Kiveh",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.38333, -3.45",
                            "IataCode": "ELR",
                            "Airports": [
                                {
                                    "Id": "ELR",
                                    "Name": "Elelim",
                                    "CityId": "ELRI",
                                    "CountryId": "ID",
                                    "Location": "140.06667, -3.816667"
                                }
                            ]
                        },
                        {
                            "Id": "OKLI",
                            "Name": "Awom",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "140.66667, -5.1",
                            "IataCode": "OKL",
                            "Airports": [
                                {
                                    "Id": "OKL",
                                    "Name": "Oksibil",
                                    "CityId": "OKLI",
                                    "CountryId": "ID",
                                    "Location": "140.66667, -5.1"
                                }
                            ]
                        },
                        {
                            "Id": "SIWI",
                            "Name": "Pondo Mayo",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "99.5226, 3.1764",
                            "IataCode": "SIW",
                            "Airports": [
                                {
                                    "Id": "SIW",
                                    "Name": "Sibisa",
                                    "CityId": "SIWI",
                                    "CountryId": "ID",
                                    "Location": "99.53064, 3.16794"
                                }
                            ]
                        },
                        {
                            "Id": "TJBI",
                            "Name": "Simpang Empat",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "99.7277, 2.9207",
                            "IataCode": "TJB",
                            "Airports": [
                                {
                                    "Id": "TJB",
                                    "Name": "Tanjung Balai",
                                    "CityId": "TJBI",
                                    "CountryId": "ID",
                                    "Location": "99.733333, 2.916667"
                                }
                            ]
                        },
                        {
                            "Id": "SIBO",
                            "Name": "Parsuratan",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "99.0023, 2.1945",
                            "Airports": [
                                {
                                    "Id": "WWO",
                                    "Name": "Siborong Borong",
                                    "CityId": "SIBO",
                                    "CountryId": "ID",
                                    "Location": "98.993811, 2.25902"
                                }
                            ]
                        },
                        {
                            "Id": "NKDI",
                            "Name": "Bayuran",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "110.7595, -6.4413",
                            "IataCode": "NKD",
                            "Airports": [
                                {
                                    "Id": "NKD",
                                    "Name": "Sinak",
                                    "CityId": "NKDI",
                                    "CountryId": "ID",
                                    "Location": "110.46667, -5.85"
                                }
                            ]
                        },
                        {
                            "Id": "RUFI",
                            "Name": "Nindebai",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "141.01667, -3.65",
                            "IataCode": "RUF",
                            "Airports": [
                                {
                                    "Id": "RUF",
                                    "Name": "Yuruf",
                                    "CityId": "RUFI",
                                    "CountryId": "ID",
                                    "Location": "140.93333, -3.633333"
                                }
                            ]
                        },
                        {
                            "Id": "BXMI",
                            "Name": "Masianum Hufi",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "141, -4.15",
                            "IataCode": "BXM",
                            "Airports": [
                                {
                                    "Id": "BXM",
                                    "Name": "Batom",
                                    "CityId": "BXMI",
                                    "CountryId": "ID",
                                    "Location": "140.85, -4.116667"
                                }
                            ]
                        },
                        {
                            "Id": "BXWA",
                            "Name": "Bawean",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "112.67917, -5.723333",
                            "IataCode": "BXW",
                            "Airports": [
                                {
                                    "Id": "BXW",
                                    "Name": "Bawean",
                                    "CityId": "BXWA",
                                    "CountryId": "ID",
                                    "Location": "112.67917, -5.723333"
                                }
                            ]
                        },
                        {
                            "Id": "PPRI",
                            "Name": "Rambah Hilir",
                            "SingleAirportCity": true,
                            "CountryId": "ID",
                            "Location": "100.37109, 0.834931",
                            "IataCode": "PPR",
                            "Airports": [
                                {
                                    "Id": "PPR",
                                    "Name": "Pasir Pangarayan",
                                    "CityId": "PPRI",
                                    "CountryId": "ID",
                                    "Location": "100.36667, 0.833333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MY",
                    "Name": "Malaysia",
                    "CurrencyId": "MYR",
                    "Cities": [
                        {
                            "Id": "LACM",
                            "Name": "Pulau Layang-Layang Is",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "113.85, 7.4",
                            "IataCode": "LAC",
                            "Airports": [
                                {
                                    "Id": "LAC",
                                    "Name": "Pulau Layang-Layang Is",
                                    "CityId": "LACM",
                                    "CountryId": "MY",
                                    "Location": "113.85, 7.4"
                                }
                            ]
                        },
                        {
                            "Id": "AORM",
                            "Name": "Alor Setar",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.36667, 6.1166667",
                            "IataCode": "AOR",
                            "Airports": [
                                {
                                    "Id": "AOR",
                                    "Name": "Alor Setar",
                                    "CityId": "AORM",
                                    "CountryId": "MY",
                                    "Location": "100.40389, 6.191111"
                                }
                            ]
                        },
                        {
                            "Id": "LSUM",
                            "Name": "Long Sukang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.48333, 4.55",
                            "IataCode": "LSU",
                            "Airports": [
                                {
                                    "Id": "LSU",
                                    "Name": "Long Sukang",
                                    "CityId": "LSUM",
                                    "CountryId": "MY",
                                    "Location": "115.5, 4.55"
                                }
                            ]
                        },
                        {
                            "Id": "BKIM",
                            "Name": "Kota Kinabalu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.0729, 5.9780066",
                            "IataCode": "BKI",
                            "Airports": [
                                {
                                    "Id": "BKI",
                                    "Name": "Kota Kinabalu",
                                    "CityId": "BKIM",
                                    "CountryId": "MY",
                                    "Location": "116.05, 5.940833"
                                }
                            ]
                        },
                        {
                            "Id": "BLGM",
                            "Name": "Belaga",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "113.78333, 2.7",
                            "IataCode": "BLG",
                            "Airports": [
                                {
                                    "Id": "BLG",
                                    "Name": "Belaga",
                                    "CityId": "BLGM",
                                    "CountryId": "MY",
                                    "Location": "113.78333, 2.7"
                                }
                            ]
                        },
                        {
                            "Id": "BSEM",
                            "Name": "Sematan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "109.76667, 1.8",
                            "IataCode": "BSE",
                            "Airports": [
                                {
                                    "Id": "BSE",
                                    "Name": "Sematan",
                                    "CityId": "BSEM",
                                    "CountryId": "MY",
                                    "Location": "109.76667, 1.8"
                                }
                            ]
                        },
                        {
                            "Id": "BTUM",
                            "Name": "Bintulu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "113.03333, 3.1666667",
                            "IataCode": "BTU",
                            "Airports": [
                                {
                                    "Id": "BTU",
                                    "Name": "Bintulu",
                                    "CityId": "BTUM",
                                    "CountryId": "MY",
                                    "Location": "113.04306, 3.175"
                                }
                            ]
                        },
                        {
                            "Id": "BWHM",
                            "Name": "Butterworth",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.4, 5.4166667",
                            "IataCode": "BWH",
                            "Airports": [
                                {
                                    "Id": "BWH",
                                    "Name": "Butterworth",
                                    "CityId": "BWHM",
                                    "CountryId": "MY",
                                    "Location": "100.3925, 5.466667"
                                }
                            ]
                        },
                        {
                            "Id": "IPHM",
                            "Name": "Ipoh",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "101.08333, 4.5833333",
                            "IataCode": "IPH",
                            "Airports": [
                                {
                                    "Id": "IPH",
                                    "Name": "Ipoh",
                                    "CityId": "IPHM",
                                    "CountryId": "MY",
                                    "Location": "101.09583, 4.566944"
                                }
                            ]
                        },
                        {
                            "Id": "JHBM",
                            "Name": "Johor Bahru",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.75, 1.4666667",
                            "IataCode": "JHB",
                            "Airports": [
                                {
                                    "Id": "JHB",
                                    "Name": "Johor Bahru",
                                    "CityId": "JHBM",
                                    "CountryId": "MY",
                                    "Location": "103.67083, 1.638889"
                                }
                            ]
                        },
                        {
                            "Id": "KBRM",
                            "Name": "Kota Bharu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "102.25, 6.1333333",
                            "IataCode": "KBR",
                            "Airports": [
                                {
                                    "Id": "KBR",
                                    "Name": "Kota Bharu",
                                    "CityId": "KBRM",
                                    "CountryId": "MY",
                                    "Location": "102.29361, 6.165278"
                                }
                            ]
                        },
                        {
                            "Id": "KCHM",
                            "Name": "Kuching",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "110.33333, 1.55",
                            "IataCode": "KCH",
                            "Airports": [
                                {
                                    "Id": "KCH",
                                    "Name": "Kuching",
                                    "CityId": "KCHM",
                                    "CountryId": "MY",
                                    "Location": "110.34083, 1.484167"
                                }
                            ]
                        },
                        {
                            "Id": "KPIM",
                            "Name": "Kapit",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "112.93333, 2.0166667",
                            "IataCode": "KPI",
                            "Airports": [
                                {
                                    "Id": "KPI",
                                    "Name": "Kapit",
                                    "CityId": "KPIM",
                                    "CountryId": "MY",
                                    "Location": "112.92944, 2.010556"
                                }
                            ]
                        },
                        {
                            "Id": "KGUM",
                            "Name": "Keningau",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.16667, 5.3333333",
                            "IataCode": "KGU",
                            "Airports": [
                                {
                                    "Id": "KGU",
                                    "Name": "Keningau",
                                    "CityId": "KGUM",
                                    "CountryId": "MY",
                                    "Location": "116.16194, 5.356667"
                                }
                            ]
                        },
                        {
                            "Id": "KTEM",
                            "Name": "Kerteh",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.45, 4.5166667",
                            "IataCode": "KTE",
                            "Airports": [
                                {
                                    "Id": "KTE",
                                    "Name": "Kerteh",
                                    "CityId": "KTEM",
                                    "CountryId": "MY",
                                    "Location": "103.42833, 4.538333"
                                }
                            ]
                        },
                        {
                            "Id": "KUAM",
                            "Name": "Kuantan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.33333, 3.8",
                            "IataCode": "KUA",
                            "Airports": [
                                {
                                    "Id": "KUA",
                                    "Name": "Kuantan",
                                    "CityId": "KUAM",
                                    "CountryId": "MY",
                                    "Location": "103.21028, 3.772222"
                                }
                            ]
                        },
                        {
                            "Id": "KUDM",
                            "Name": "Kudat",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.83333, 6.8833333",
                            "IataCode": "KUD",
                            "Airports": [
                                {
                                    "Id": "KUD",
                                    "Name": "Kudat",
                                    "CityId": "KUDM",
                                    "CountryId": "MY",
                                    "Location": "116.83417, 6.925"
                                }
                            ]
                        },
                        {
                            "Id": "KULM",
                            "Name": "Kuala Lumpur",
                            "SingleAirportCity": false,
                            "CountryId": "MY",
                            "Location": "101.7, 3.1666667",
                            "IataCode": "KUL",
                            "Airports": [
                                {
                                    "Id": "SZB",
                                    "Name": "Kuala Lumpur Sultan Abdul Azziz Shah",
                                    "CityId": "KULM",
                                    "CountryId": "MY",
                                    "Location": "101.55, 3.133333"
                                },
                                {
                                    "Id": "KUL",
                                    "Name": "Kuala Lumpur International",
                                    "CityId": "KULM",
                                    "CountryId": "MY",
                                    "Location": "101.70507, 2.7347148"
                                }
                            ]
                        },
                        {
                            "Id": "LBPM",
                            "Name": "Long Banga",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.38333, 3.2",
                            "IataCode": "LBP",
                            "Airports": [
                                {
                                    "Id": "LBP",
                                    "Name": "Long Banga",
                                    "CityId": "LBPM",
                                    "CountryId": "MY",
                                    "Location": "115.4, 3.2"
                                }
                            ]
                        },
                        {
                            "Id": "LBUM",
                            "Name": "Labuan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.24167, 5.2766667",
                            "IataCode": "LBU",
                            "Airports": [
                                {
                                    "Id": "LBU",
                                    "Name": "Labuan",
                                    "CityId": "LBUM",
                                    "CountryId": "MY",
                                    "Location": "115.24833, 5.300556"
                                }
                            ]
                        },
                        {
                            "Id": "LDUM",
                            "Name": "Lahad Datu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "118.31667, 5.0333333",
                            "IataCode": "LDU",
                            "Airports": [
                                {
                                    "Id": "LDU",
                                    "Name": "Lahad Datu",
                                    "CityId": "LDUM",
                                    "CountryId": "MY",
                                    "Location": "118.32611, 5.035278"
                                }
                            ]
                        },
                        {
                            "Id": "LGKM",
                            "Name": "Langkawi",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "99.85, 6.3166667",
                            "IataCode": "LGK",
                            "Airports": [
                                {
                                    "Id": "LGK",
                                    "Name": "Langkawi",
                                    "CityId": "LGKM",
                                    "CountryId": "MY",
                                    "Location": "99.733333, 6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LGLM",
                            "Name": "Long Lellang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.13333, 3.4166667",
                            "IataCode": "LGL",
                            "Airports": [
                                {
                                    "Id": "LGL",
                                    "Name": "Long Lellang",
                                    "CityId": "LGLM",
                                    "CountryId": "MY",
                                    "Location": "115.15406, 3.421847"
                                }
                            ]
                        },
                        {
                            "Id": "LSMM",
                            "Name": "Long Semado",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.58333, 4.25",
                            "IataCode": "LSM",
                            "Airports": [
                                {
                                    "Id": "LSM",
                                    "Name": "Long Semado",
                                    "CityId": "LSMM",
                                    "CountryId": "MY",
                                    "Location": "115.6, 4.217"
                                }
                            ]
                        },
                        {
                            "Id": "LWYM",
                            "Name": "Lawas",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.4, 4.85",
                            "IataCode": "LWY",
                            "Airports": [
                                {
                                    "Id": "LWY",
                                    "Name": "Lawas",
                                    "CityId": "LWYM",
                                    "CountryId": "MY",
                                    "Location": "115.41667, 4.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MEPM",
                            "Name": "Mersing",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.83333, 2.4333333",
                            "IataCode": "MEP",
                            "Airports": [
                                {
                                    "Id": "MEP",
                                    "Name": "Mersing",
                                    "CityId": "MEPM",
                                    "CountryId": "MY",
                                    "Location": "103.83333, 2.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MYYM",
                            "Name": "Miri",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "113.98333, 4.3833333",
                            "IataCode": "MYY",
                            "Airports": [
                                {
                                    "Id": "MYY",
                                    "Name": "Miri",
                                    "CityId": "MYYM",
                                    "CountryId": "MY",
                                    "Location": "113.98694, 4.321944"
                                }
                            ]
                        },
                        {
                            "Id": "SBWM",
                            "Name": "Sibu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "111.81667, 2.3",
                            "IataCode": "SBW",
                            "Airports": [
                                {
                                    "Id": "SBW",
                                    "Name": "Sibu",
                                    "CityId": "SBWM",
                                    "CountryId": "MY",
                                    "Location": "111.83833, 2.341667"
                                }
                            ]
                        },
                        {
                            "Id": "SDKM",
                            "Name": "Sandakan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "118.11667, 5.8333333",
                            "IataCode": "SDK",
                            "Airports": [
                                {
                                    "Id": "SDK",
                                    "Name": "Sandakan",
                                    "CityId": "SDKM",
                                    "CountryId": "MY",
                                    "Location": "118.05972, 5.9"
                                }
                            ]
                        },
                        {
                            "Id": "SMMM",
                            "Name": "Semporna",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "118.61583, 4.4811111",
                            "IataCode": "SMM",
                            "Airports": [
                                {
                                    "Id": "SMM",
                                    "Name": "Semporna",
                                    "CityId": "SMMM",
                                    "CountryId": "MY",
                                    "Location": "118.83333, 4.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SWYM",
                            "Name": "Sitiawan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.7, 4.2166667",
                            "IataCode": "SWY",
                            "Airports": [
                                {
                                    "Id": "SWY",
                                    "Name": "Sitiawan",
                                    "CityId": "SWYM",
                                    "CountryId": "MY",
                                    "Location": "100.7, 4.216667"
                                }
                            ]
                        },
                        {
                            "Id": "TWUM",
                            "Name": "Tawau",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "117.9, 4.25",
                            "IataCode": "TWU",
                            "Airports": [
                                {
                                    "Id": "TWU",
                                    "Name": "Tawau",
                                    "CityId": "TWUM",
                                    "CountryId": "MY",
                                    "Location": "118.12195, 4.31337"
                                }
                            ]
                        },
                        {
                            "Id": "BBNM",
                            "Name": "Bario",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.45, 3.75",
                            "IataCode": "BBN",
                            "Airports": [
                                {
                                    "Id": "BBN",
                                    "Name": "Bario",
                                    "CityId": "BBNM",
                                    "CountryId": "MY",
                                    "Location": "115.46667, 3.683333"
                                }
                            ]
                        },
                        {
                            "Id": "TELM",
                            "Name": "Telupid",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "117.11667, 5.65",
                            "IataCode": "TEL",
                            "Airports": [
                                {
                                    "Id": "TEL",
                                    "Name": "Telupid",
                                    "CityId": "TELM",
                                    "CountryId": "MY",
                                    "Location": "117.11667, 5.583333"
                                }
                            ]
                        },
                        {
                            "Id": "TGGM",
                            "Name": "Kuala Terengganu",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.13333, 5.3333333",
                            "IataCode": "TGG",
                            "Airports": [
                                {
                                    "Id": "TGG",
                                    "Name": "Kuala Terengganu",
                                    "CityId": "TGGM",
                                    "CountryId": "MY",
                                    "Location": "103.10972, 5.381111"
                                }
                            ]
                        },
                        {
                            "Id": "LLMM",
                            "Name": "Long Lama",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "114.4, 3.7666667",
                            "IataCode": "LLM",
                            "Airports": [
                                {
                                    "Id": "LLM",
                                    "Name": "Long Lama",
                                    "CityId": "LLMM",
                                    "CountryId": "MY",
                                    "Location": "114.46667, 3.766667"
                                }
                            ]
                        },
                        {
                            "Id": "LKHM",
                            "Name": "Long Akah",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "114.78333, 3.3166667",
                            "IataCode": "LKH",
                            "Airports": [
                                {
                                    "Id": "LKH",
                                    "Name": "Long Akah",
                                    "CityId": "LKHM",
                                    "CountryId": "MY",
                                    "Location": "114.783, 3.3"
                                }
                            ]
                        },
                        {
                            "Id": "MKMM",
                            "Name": "Mukah",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "112.1, 2.9",
                            "IataCode": "MKM",
                            "Airports": [
                                {
                                    "Id": "MKM",
                                    "Name": "Mukah",
                                    "CityId": "MKMM",
                                    "CountryId": "MY",
                                    "Location": "112.07972, 2.906944"
                                }
                            ]
                        },
                        {
                            "Id": "MUMM",
                            "Name": "Marudi",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "114.31667, 4.1833333",
                            "IataCode": "MUR",
                            "Airports": [
                                {
                                    "Id": "MUR",
                                    "Name": "Marudi",
                                    "CityId": "MUMM",
                                    "CountryId": "MY",
                                    "Location": "114.31667, 4.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ODNM",
                            "Name": "Long Seridan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.06667, 3.9833333",
                            "IataCode": "ODN",
                            "Airports": [
                                {
                                    "Id": "ODN",
                                    "Name": "Long Seridan",
                                    "CityId": "ODNM",
                                    "CountryId": "MY",
                                    "Location": "115.06667, 4.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PAYM",
                            "Name": "Pamol",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "117.4, 5.9666667",
                            "IataCode": "PAY",
                            "Airports": [
                                {
                                    "Id": "PAY",
                                    "Name": "Pamol",
                                    "CityId": "PAYM",
                                    "CountryId": "MY",
                                    "Location": "117.39444, 5.993056"
                                }
                            ]
                        },
                        {
                            "Id": "SGGA",
                            "Name": "Simanggang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "111.45278, 1.2472222",
                            "IataCode": "SGG",
                            "Airports": [
                                {
                                    "Id": "SGG",
                                    "Name": "Simanggang Airport",
                                    "CityId": "SGGA",
                                    "CountryId": "MY",
                                    "Location": "111.45, 1.217"
                                }
                            ]
                        },
                        {
                            "Id": "SPTM",
                            "Name": "Sipitang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.55, 5.0833333",
                            "IataCode": "SPT",
                            "Airports": [
                                {
                                    "Id": "SPT",
                                    "Name": "Sipitang",
                                    "CityId": "SPTM",
                                    "CountryId": "MY",
                                    "Location": "115.55, 5.083333"
                                }
                            ]
                        },
                        {
                            "Id": "RNUM",
                            "Name": "Ranau",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.68333, 5.9666667",
                            "IataCode": "RNU",
                            "Airports": [
                                {
                                    "Id": "RNU",
                                    "Name": "Ranau",
                                    "CityId": "RNUM",
                                    "CountryId": "MY",
                                    "Location": "116.66667, 5.95"
                                }
                            ]
                        },
                        {
                            "Id": "TPGM",
                            "Name": "Taiping",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.73333, 4.85",
                            "IataCode": "TPG",
                            "Airports": [
                                {
                                    "Id": "TPG",
                                    "Name": "Taiping",
                                    "CityId": "TPGM",
                                    "CountryId": "MY",
                                    "Location": "100.73333, 4.85"
                                }
                            ]
                        },
                        {
                            "Id": "MKZM",
                            "Name": "Malacca",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "102.24806, 2.1969444",
                            "IataCode": "MKZ",
                            "Airports": [
                                {
                                    "Id": "MKZ",
                                    "Name": "Malacca",
                                    "CityId": "MKZM",
                                    "CountryId": "MY",
                                    "Location": "102.25333, 2.264167"
                                }
                            ]
                        },
                        {
                            "Id": "PKGM",
                            "Name": "Pangkor",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.56667, 4.2166667",
                            "IataCode": "PKG",
                            "Airports": [
                                {
                                    "Id": "PKG",
                                    "Name": "Pangkor",
                                    "CityId": "PKGM",
                                    "CountryId": "MY",
                                    "Location": "100.553, 4.24472"
                                }
                            ]
                        },
                        {
                            "Id": "ZLWA",
                            "Name": "Pasir Gudang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.91041, 1.4826455",
                            "IataCode": "ZLW",
                            "Airports": [
                                {
                                    "Id": "ZLW",
                                    "Name": "Pasir Gudang",
                                    "CityId": "ZLWA",
                                    "CountryId": "MY",
                                    "Location": "103.93333, 1.43333"
                                }
                            ]
                        },
                        {
                            "Id": "XPQA",
                            "Name": "Port Klang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "101.45, 3.0333333",
                            "IataCode": "XPQ",
                            "Airports": [
                                {
                                    "Id": "XPQ",
                                    "Name": "Port Klang",
                                    "CityId": "XPQA",
                                    "CountryId": "MY",
                                    "Location": "101.4, 3"
                                }
                            ]
                        },
                        {
                            "Id": "ZJTA",
                            "Name": "Tanjung Pelepas",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.55, 1.3666667",
                            "IataCode": "ZJT",
                            "Airports": [
                                {
                                    "Id": "ZJT",
                                    "Name": "Tanjung Pelepas",
                                    "CityId": "ZJTA",
                                    "CountryId": "MY",
                                    "Location": "103.55, 1.35"
                                }
                            ]
                        },
                        {
                            "Id": "MZSM",
                            "Name": "Mostyn",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "118.18333, 4.6666667",
                            "IataCode": "MZS",
                            "Airports": [
                                {
                                    "Id": "MZS",
                                    "Name": "Mostyn",
                                    "CityId": "MZSM",
                                    "CountryId": "MY",
                                    "Location": "118.15, 4.616667"
                                }
                            ]
                        },
                        {
                            "Id": "TMGM",
                            "Name": "Tomanggong",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "118.65833, 5.4027778",
                            "IataCode": "TMG",
                            "Airports": [
                                {
                                    "Id": "TMG",
                                    "Name": "Tomanggong",
                                    "CityId": "TMGM",
                                    "CountryId": "MY",
                                    "Location": "118.65, 5.4"
                                }
                            ]
                        },
                        {
                            "Id": "TGCA",
                            "Name": "Tanjung Manis",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "111.36667, 2.15",
                            "IataCode": "TGC",
                            "Airports": [
                                {
                                    "Id": "TGC",
                                    "Name": "Tanjung Manis",
                                    "CityId": "TGCA",
                                    "CountryId": "MY",
                                    "Location": "111.20275, 2.17773"
                                }
                            ]
                        },
                        {
                            "Id": "GTBM",
                            "Name": "Genting",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "111.7, 2.1166667",
                            "IataCode": "GTB",
                            "Airports": [
                                {
                                    "Id": "GTB",
                                    "Name": "Genting",
                                    "CityId": "GTBM",
                                    "CountryId": "MY",
                                    "Location": "111.7, 2.116667"
                                }
                            ]
                        },
                        {
                            "Id": "BKMM",
                            "Name": "Bakalalan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.61667, 3.9833333",
                            "IataCode": "BKM",
                            "Airports": [
                                {
                                    "Id": "BKM",
                                    "Name": "Bakalalan",
                                    "CityId": "BKMM",
                                    "CountryId": "MY",
                                    "Location": "115.61667, 3.966667"
                                }
                            ]
                        },
                        {
                            "Id": "LMNM",
                            "Name": "Limbang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115, 4.75",
                            "IataCode": "LMN",
                            "Airports": [
                                {
                                    "Id": "LMN",
                                    "Name": "Limbang",
                                    "CityId": "LMNM",
                                    "CountryId": "MY",
                                    "Location": "115.01, 4.8083"
                                }
                            ]
                        },
                        {
                            "Id": "PENM",
                            "Name": "George Town",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.2725, 5.289722",
                            "IataCode": "PEN",
                            "Airports": [
                                {
                                    "Id": "PEN",
                                    "Name": "Penang",
                                    "CityId": "PENM",
                                    "CountryId": "MY",
                                    "Location": "100.2725, 5.289722"
                                }
                            ]
                        },
                        {
                            "Id": "ZGYA",
                            "Name": "Tanjungmedan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.56667, 1.4333333",
                            "IataCode": "ZGY",
                            "Airports": [
                                {
                                    "Id": "ZGY",
                                    "Name": "Kuching",
                                    "CityId": "ZGYA",
                                    "CountryId": "MY",
                                    "Location": "100.38333, 1.55"
                                }
                            ]
                        },
                        {
                            "Id": "GSAM",
                            "Name": "Long Miau",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "115.75, 4.4333333",
                            "IataCode": "GSA",
                            "Airports": [
                                {
                                    "Id": "GSA",
                                    "Name": "Long Pasia",
                                    "CityId": "GSAM",
                                    "CountryId": "MY",
                                    "Location": "115.76667, 4.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SXSM",
                            "Name": "Sabahat",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "119.04861, 5.0716667",
                            "IataCode": "SXS",
                            "Airports": [
                                {
                                    "Id": "SXS",
                                    "Name": "Sahabat 16",
                                    "CityId": "SXSM",
                                    "CountryId": "MY",
                                    "Location": "119.09167, 5.089722"
                                }
                            ]
                        },
                        {
                            "Id": "TODM",
                            "Name": "Kampung Tekek",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "104.16, 2.818056",
                            "IataCode": "TOD",
                            "Airports": [
                                {
                                    "Id": "TOD",
                                    "Name": "Tioman",
                                    "CityId": "TODM",
                                    "CountryId": "MY",
                                    "Location": "104.16, 2.818056"
                                }
                            ]
                        },
                        {
                            "Id": "MZVM",
                            "Name": "Long Terawan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "114.63333, 3.9666667",
                            "IataCode": "MZV",
                            "Airports": [
                                {
                                    "Id": "MZV",
                                    "Name": "Mulu",
                                    "CityId": "MZVM",
                                    "CountryId": "MY",
                                    "Location": "114.8, 4.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ZMKA",
                            "Name": "Rumah Dana",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "113.01667, 3.1166667",
                            "IataCode": "ZMK",
                            "Airports": [
                                {
                                    "Id": "ZMK",
                                    "Name": "Bintulu",
                                    "CityId": "ZMKA",
                                    "CountryId": "MY",
                                    "Location": "113.06667, 3.26667"
                                }
                            ]
                        },
                        {
                            "Id": "ZWRA",
                            "Name": "Kampong Sembulan",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.06667, 5.9666667",
                            "IataCode": "ZWR",
                            "Airports": [
                                {
                                    "Id": "ZWR",
                                    "Name": "Kota Kinabalu",
                                    "CityId": "ZWRA",
                                    "CountryId": "MY",
                                    "Location": "116.06667, 5.98333"
                                }
                            ]
                        },
                        {
                            "Id": "GTKM",
                            "Name": "Kampong Sekijang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "102.93333, 2.55",
                            "IataCode": "GTK",
                            "Airports": [
                                {
                                    "Id": "GTK",
                                    "Name": "Sungei Tekai",
                                    "CityId": "GTKM",
                                    "CountryId": "MY",
                                    "Location": "102.91667, 2.6"
                                }
                            ]
                        },
                        {
                            "Id": "ZJBA",
                            "Name": "Kampong Riti",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.18333, 3.5166667",
                            "IataCode": "ZJB",
                            "Airports": [
                                {
                                    "Id": "ZJB",
                                    "Name": "Kuantan",
                                    "CityId": "ZJBA",
                                    "CountryId": "MY",
                                    "Location": "103.43444, 3.96667"
                                }
                            ]
                        },
                        {
                            "Id": "SXTM",
                            "Name": "Kampong Padang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "102.4, 4.3333333",
                            "IataCode": "SXT",
                            "Airports": [
                                {
                                    "Id": "SXT",
                                    "Name": "Taman Negara",
                                    "CityId": "SXTM",
                                    "CountryId": "MY",
                                    "Location": "102.39667, 4.330556"
                                }
                            ]
                        },
                        {
                            "Id": "SPEM",
                            "Name": "Beluran",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "116.43333, 4.7166667",
                            "IataCode": "SPE",
                            "Airports": [
                                {
                                    "Id": "SPE",
                                    "Name": "Sepulot",
                                    "CityId": "SPEM",
                                    "CountryId": "MY",
                                    "Location": "116.45694, 4.712222"
                                }
                            ]
                        },
                        {
                            "Id": "RDNM",
                            "Name": "Kampung Hulu Redang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "103.00375, 5.7658475",
                            "IataCode": "RDN",
                            "Airports": [
                                {
                                    "Id": "RDN",
                                    "Name": "Redang",
                                    "CityId": "RDNM",
                                    "CountryId": "MY",
                                    "Location": "103.00694, 5.765278"
                                }
                            ]
                        },
                        {
                            "Id": "ZJRA",
                            "Name": "Penang",
                            "SingleAirportCity": true,
                            "CountryId": "MY",
                            "Location": "100.23333, 5.4",
                            "IataCode": "ZJR",
                            "Airports": [
                                {
                                    "Id": "ZJR",
                                    "Name": "Penang",
                                    "CityId": "ZJRA",
                                    "CountryId": "MY",
                                    "Location": "100.33333, 5.41667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KH",
                    "Name": "Cambodia",
                    "CurrencyId": "KHR",
                    "Cities": [
                        {
                            "Id": "KKZK",
                            "Name": "Koh Kong",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "102.98056, 11.6175",
                            "IataCode": "KKZ",
                            "Airports": [
                                {
                                    "Id": "KKZ",
                                    "Name": "Koh Kong",
                                    "CityId": "KKZK",
                                    "CountryId": "KH",
                                    "Location": "102.96667, 11.633333"
                                }
                            ]
                        },
                        {
                            "Id": "KOSK",
                            "Name": "Sihanoukville",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "103.5, 10.633333",
                            "IataCode": "KOS",
                            "Airports": [
                                {
                                    "Id": "KOS",
                                    "Name": "Sihanoukville",
                                    "CityId": "KOSK",
                                    "CountryId": "KH",
                                    "Location": "103.5, 10.633333"
                                }
                            ]
                        },
                        {
                            "Id": "KTIK",
                            "Name": "Kratie",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "106.01667, 12.483333",
                            "IataCode": "KTI",
                            "Airports": [
                                {
                                    "Id": "KTI",
                                    "Name": "Kratie",
                                    "CityId": "KTIK",
                                    "CountryId": "KH",
                                    "Location": "106.05, 12.5"
                                }
                            ]
                        },
                        {
                            "Id": "PNHK",
                            "Name": "Phnom Penh",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.91667, 11.55",
                            "IataCode": "PNH",
                            "Airports": [
                                {
                                    "Id": "PNH",
                                    "Name": "Phnom Penh",
                                    "CityId": "PNHK",
                                    "CountryId": "KH",
                                    "Location": "104.84778, 11.546111"
                                }
                            ]
                        },
                        {
                            "Id": "REPK",
                            "Name": "Siem Reap",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "103.85903, 13.361756",
                            "IataCode": "REP",
                            "Airports": [
                                {
                                    "Id": "REP",
                                    "Name": "Siem Reap",
                                    "CityId": "REPK",
                                    "CountryId": "KH",
                                    "Location": "103.81667, 13.4"
                                }
                            ]
                        },
                        {
                            "Id": "BBMK",
                            "Name": "Battambang",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "103.2, 13.1",
                            "IataCode": "BBM",
                            "Airports": [
                                {
                                    "Id": "BBM",
                                    "Name": "Battambang",
                                    "CityId": "BBMK",
                                    "CountryId": "KH",
                                    "Location": "103.2, 13.116667"
                                }
                            ]
                        },
                        {
                            "Id": "KMTK",
                            "Name": "Kampot",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.18333, 10.616667",
                            "IataCode": "KMT",
                            "Airports": [
                                {
                                    "Id": "KMT",
                                    "Name": "Kampot",
                                    "CityId": "KMTK",
                                    "CountryId": "KH",
                                    "Location": "104.16667, 10.6"
                                }
                            ]
                        },
                        {
                            "Id": "KZDK",
                            "Name": "Krakor",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.2, 12.533333",
                            "IataCode": "KZD",
                            "Airports": [
                                {
                                    "Id": "KZD",
                                    "Name": "Krakor",
                                    "CityId": "KZDK",
                                    "CountryId": "KH",
                                    "Location": "104.2, 12.533333"
                                }
                            ]
                        },
                        {
                            "Id": "KZKK",
                            "Name": "Kompong Thom",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.9, 12.7",
                            "IataCode": "KZK",
                            "Airports": [
                                {
                                    "Id": "KZK",
                                    "Name": "Kompong Thom",
                                    "CityId": "KZKK",
                                    "CountryId": "KH",
                                    "Location": "104.85, 12.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PAIK",
                            "Name": "Pailin",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "102.60972, 12.850556",
                            "IataCode": "PAI",
                            "Airports": [
                                {
                                    "Id": "PAI",
                                    "Name": "Pailin",
                                    "CityId": "PAIK",
                                    "CountryId": "KH",
                                    "Location": "102.6, 12.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TNXK",
                            "Name": "Stung Treng",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "105.96667, 13.516667",
                            "IataCode": "TNX",
                            "Airports": [
                                {
                                    "Id": "TNX",
                                    "Name": "Stung Treng",
                                    "CityId": "TNXK",
                                    "CountryId": "KH",
                                    "Location": "106.03333, 13.533333"
                                }
                            ]
                        },
                        {
                            "Id": "RBEK",
                            "Name": "Ratanakiri",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "107.08719, 13.793627",
                            "IataCode": "RBE",
                            "Airports": [
                                {
                                    "Id": "RBE",
                                    "Name": "Ratanakiri",
                                    "CityId": "RBEK",
                                    "CountryId": "KH",
                                    "Location": "106.98333, 13.666667"
                                }
                            ]
                        },
                        {
                            "Id": "MWVK",
                            "Name": "Mundulkiri",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "107.28333, 12.416667",
                            "IataCode": "MWV",
                            "Airports": [
                                {
                                    "Id": "MWV",
                                    "Name": "Mundulkiri",
                                    "CityId": "MWVK",
                                    "CountryId": "KH",
                                    "Location": "107.19083, 12.460556"
                                }
                            ]
                        },
                        {
                            "Id": "KZCK",
                            "Name": "Kompong-Chhnang",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.66667, 12.25",
                            "IataCode": "KZC",
                            "Airports": [
                                {
                                    "Id": "KZC",
                                    "Name": "Kompong-Chhnang",
                                    "CityId": "KZCK",
                                    "CountryId": "KH",
                                    "Location": "104.58333, 12.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OMYK",
                            "Name": "Phnum Tbêng Méanchey",
                            "SingleAirportCity": true,
                            "CountryId": "KH",
                            "Location": "104.96667, 13.816667",
                            "IataCode": "OMY",
                            "Airports": [
                                {
                                    "Id": "OMY",
                                    "Name": "Oddor Meanche",
                                    "CityId": "OMYK",
                                    "CountryId": "KH",
                                    "Location": "104.96667, 13.816667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MM",
                    "Name": "Myanmar",
                    "CurrencyId": "MMK",
                    "Cities": [
                        {
                            "Id": "XYEA",
                            "Name": "Ye",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.85, 15.25",
                            "IataCode": "XYE",
                            "Airports": [
                                {
                                    "Id": "XYE",
                                    "Name": "Ye",
                                    "CityId": "XYEA",
                                    "CountryId": "MM",
                                    "Location": "97.85, 15.25"
                                }
                            ]
                        },
                        {
                            "Id": "AKYM",
                            "Name": "Sittwe",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "92.9, 20.15",
                            "IataCode": "AKY",
                            "Airports": [
                                {
                                    "Id": "AKY",
                                    "Name": "Sittwe",
                                    "CityId": "AKYM",
                                    "CountryId": "MM",
                                    "Location": "92.880278, 20.130278"
                                }
                            ]
                        },
                        {
                            "Id": "GAWM",
                            "Name": "Gangaw",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.133333, 22.166667",
                            "IataCode": "GAW",
                            "Airports": [
                                {
                                    "Id": "GAW",
                                    "Name": "Gangaw",
                                    "CityId": "GAWM",
                                    "CountryId": "MM",
                                    "Location": "94.133333, 22.166667"
                                }
                            ]
                        },
                        {
                            "Id": "HOXM",
                            "Name": "Homalin",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.916667, 24.866667",
                            "IataCode": "HOX",
                            "Airports": [
                                {
                                    "Id": "HOX",
                                    "Name": "Homalin",
                                    "CityId": "HOXM",
                                    "CountryId": "MM",
                                    "Location": "94.916667, 24.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BMOM",
                            "Name": "Bhamo",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.233333, 24.266667",
                            "IataCode": "BMO",
                            "Airports": [
                                {
                                    "Id": "BMO",
                                    "Name": "Bhamo",
                                    "CityId": "BMOM",
                                    "CountryId": "MM",
                                    "Location": "97.251667, 24.273889"
                                }
                            ]
                        },
                        {
                            "Id": "BSXM",
                            "Name": "Bassein",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.733333, 16.783333",
                            "IataCode": "BSX",
                            "Airports": [
                                {
                                    "Id": "BSX",
                                    "Name": "Bassein",
                                    "CityId": "BSXM",
                                    "CountryId": "MM",
                                    "Location": "94.783333, 16.8"
                                }
                            ]
                        },
                        {
                            "Id": "HEBM",
                            "Name": "Henzada",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "95.466666, 17.633333",
                            "IataCode": "HEB",
                            "Airports": [
                                {
                                    "Id": "HEB",
                                    "Name": "Henzada",
                                    "CityId": "HEBM",
                                    "CountryId": "MM",
                                    "Location": "95.466667, 17.633333"
                                }
                            ]
                        },
                        {
                            "Id": "HEHM",
                            "Name": "Heho",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96.816667, 20.716667",
                            "IataCode": "HEH",
                            "Airports": [
                                {
                                    "Id": "HEH",
                                    "Name": "Heho",
                                    "CityId": "HEHM",
                                    "CountryId": "MM",
                                    "Location": "96.794444, 20.743889"
                                }
                            ]
                        },
                        {
                            "Id": "KMVM",
                            "Name": "Kalemyo",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.066667, 23.183333",
                            "IataCode": "KMV",
                            "Airports": [
                                {
                                    "Id": "KMV",
                                    "Name": "Kalemyo",
                                    "CityId": "KMVM",
                                    "CountryId": "MM",
                                    "Location": "94.05, 23.183333"
                                }
                            ]
                        },
                        {
                            "Id": "KETM",
                            "Name": "Keng Tung",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "99.6, 21.283333",
                            "IataCode": "KET",
                            "Airports": [
                                {
                                    "Id": "KET",
                                    "Name": "Keng Tung",
                                    "CityId": "KETM",
                                    "CountryId": "MM",
                                    "Location": "99.616667, 21.3"
                                }
                            ]
                        },
                        {
                            "Id": "KYPM",
                            "Name": "Kyaukpyu",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "93.55, 19.433333",
                            "IataCode": "KYP",
                            "Airports": [
                                {
                                    "Id": "KYP",
                                    "Name": "Kyaukpyu",
                                    "CityId": "KYPM",
                                    "CountryId": "MM",
                                    "Location": "93.533333, 19.433333"
                                }
                            ]
                        },
                        {
                            "Id": "LIWM",
                            "Name": "Loikaw",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.209444, 19.674167",
                            "IataCode": "LIW",
                            "Airports": [
                                {
                                    "Id": "LIW",
                                    "Name": "Loikaw",
                                    "CityId": "LIWM",
                                    "CountryId": "MM",
                                    "Location": "97.216944, 19.688611"
                                }
                            ]
                        },
                        {
                            "Id": "LSHM",
                            "Name": "Lashio",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.75, 22.933333",
                            "IataCode": "LSH",
                            "Airports": [
                                {
                                    "Id": "LSH",
                                    "Name": "Lashio",
                                    "CityId": "LSHM",
                                    "CountryId": "MM",
                                    "Location": "97.75, 22.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MDLM",
                            "Name": "Mandalay",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96.083334, 22.000001",
                            "IataCode": "MDL",
                            "Airports": [
                                {
                                    "Id": "MDL",
                                    "Name": "Mandalay",
                                    "CityId": "MDLM",
                                    "CountryId": "MM",
                                    "Location": "95.978056, 21.702222"
                                }
                            ]
                        },
                        {
                            "Id": "RGNM",
                            "Name": "Yangon",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96.156115, 16.805283",
                            "IataCode": "RGN",
                            "Airports": [
                                {
                                    "Id": "RGN",
                                    "Name": "Yangon",
                                    "CityId": "RGNM",
                                    "CountryId": "MM",
                                    "Location": "96.138889, 16.906389"
                                }
                            ]
                        },
                        {
                            "Id": "THLM",
                            "Name": "Tachilek",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "99.880833, 20.4475",
                            "IataCode": "THL",
                            "Airports": [
                                {
                                    "Id": "THL",
                                    "Name": "Tachilek",
                                    "CityId": "THLM",
                                    "CountryId": "MM",
                                    "Location": "99.95, 20.45"
                                }
                            ]
                        },
                        {
                            "Id": "MYTM",
                            "Name": "Myitkyina",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.4, 25.383333",
                            "IataCode": "MYT",
                            "Airports": [
                                {
                                    "Id": "MYT",
                                    "Name": "Myitkyina",
                                    "CityId": "MYTM",
                                    "CountryId": "MM",
                                    "Location": "97.298889, 25.354167"
                                }
                            ]
                        },
                        {
                            "Id": "SNWM",
                            "Name": "Thandwe",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.366667, 18.466667",
                            "IataCode": "SNW",
                            "Airports": [
                                {
                                    "Id": "SNW",
                                    "Name": "Thandwe",
                                    "CityId": "SNWM",
                                    "CountryId": "MM",
                                    "Location": "94.300203, 18.46292"
                                }
                            ]
                        },
                        {
                            "Id": "MGZM",
                            "Name": "Myeik",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "98.6, 12.433333",
                            "IataCode": "MGZ",
                            "Airports": [
                                {
                                    "Id": "MGZ",
                                    "Name": "Myeik",
                                    "CityId": "MGZM",
                                    "CountryId": "MM",
                                    "Location": "98.616667, 12.45"
                                }
                            ]
                        },
                        {
                            "Id": "MOEM",
                            "Name": "Momeik",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96.683333, 23.116667",
                            "IataCode": "MOE",
                            "Airports": [
                                {
                                    "Id": "MOE",
                                    "Name": "Momeik",
                                    "CityId": "MOEM",
                                    "CountryId": "MM",
                                    "Location": "96.65, 23.1"
                                }
                            ]
                        },
                        {
                            "Id": "MOGM",
                            "Name": "Mong Hsat",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "99.255833, 20.536944",
                            "IataCode": "MOG",
                            "Airports": [
                                {
                                    "Id": "MOG",
                                    "Name": "Mong Hsat",
                                    "CityId": "MOGM",
                                    "CountryId": "MM",
                                    "Location": "99.266667, 20.533333"
                                }
                            ]
                        },
                        {
                            "Id": "MWQM",
                            "Name": "Magwe",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.916667, 20.15",
                            "IataCode": "MWQ",
                            "Airports": [
                                {
                                    "Id": "MWQ",
                                    "Name": "Magwe",
                                    "CityId": "MWQM",
                                    "CountryId": "MM",
                                    "Location": "94.941111, 20.166667"
                                }
                            ]
                        },
                        {
                            "Id": "NMSM",
                            "Name": "Namsang",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.716667, 20.883333",
                            "IataCode": "NMS",
                            "Airports": [
                                {
                                    "Id": "NMS",
                                    "Name": "Namsang",
                                    "CityId": "NMSM",
                                    "CountryId": "MM",
                                    "Location": "97.75, 20.883333"
                                }
                            ]
                        },
                        {
                            "Id": "NMTM",
                            "Name": "Namtu",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.4, 23.083333",
                            "IataCode": "NMT",
                            "Airports": [
                                {
                                    "Id": "NMT",
                                    "Name": "Namtu",
                                    "CityId": "NMTM",
                                    "CountryId": "MM",
                                    "Location": "97.4, 23.083333"
                                }
                            ]
                        },
                        {
                            "Id": "PAAM",
                            "Name": "Pa-an",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.633333, 16.890556",
                            "IataCode": "PAA",
                            "Airports": [
                                {
                                    "Id": "PAA",
                                    "Name": "Pa-an",
                                    "CityId": "PAAM",
                                    "CountryId": "MM",
                                    "Location": "97.678333, 16.892778"
                                }
                            ]
                        },
                        {
                            "Id": "PAUM",
                            "Name": "Pauk",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.466667, 21.45",
                            "IataCode": "PAU",
                            "Airports": [
                                {
                                    "Id": "PAU",
                                    "Name": "Pauk",
                                    "CityId": "PAUM",
                                    "CountryId": "MM",
                                    "Location": "94.516667, 21.45"
                                }
                            ]
                        },
                        {
                            "Id": "PKKM",
                            "Name": "Pakokku",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "95.1, 21.333333",
                            "IataCode": "PKK",
                            "Airports": [
                                {
                                    "Id": "PKK",
                                    "Name": "Pakokku",
                                    "CityId": "PKKM",
                                    "CountryId": "MM",
                                    "Location": "95.106389, 21.343333"
                                }
                            ]
                        },
                        {
                            "Id": "PPUM",
                            "Name": "Papun",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.45, 18.066667",
                            "IataCode": "PPU",
                            "Airports": [
                                {
                                    "Id": "PPU",
                                    "Name": "Papun",
                                    "CityId": "PPUM",
                                    "CountryId": "MM",
                                    "Location": "97.45, 18.066667"
                                }
                            ]
                        },
                        {
                            "Id": "PRUM",
                            "Name": "Prome",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "95.216666, 18.816667",
                            "IataCode": "PRU",
                            "Airports": [
                                {
                                    "Id": "PRU",
                                    "Name": "Prome",
                                    "CityId": "PRUM",
                                    "CountryId": "MM",
                                    "Location": "95.268611, 18.825"
                                }
                            ]
                        },
                        {
                            "Id": "NYUM",
                            "Name": "Nyaung-u",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.866667, 21.166667",
                            "IataCode": "NYU",
                            "Airports": [
                                {
                                    "Id": "NYU",
                                    "Name": "Nyaung-u",
                                    "CityId": "NYUM",
                                    "CountryId": "MM",
                                    "Location": "94.934722, 21.181389"
                                }
                            ]
                        },
                        {
                            "Id": "TVYM",
                            "Name": "Dawe",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "98.2, 14.083333",
                            "IataCode": "TVY",
                            "Airports": [
                                {
                                    "Id": "TVY",
                                    "Name": "Dawe",
                                    "CityId": "TVYM",
                                    "CountryId": "MM",
                                    "Location": "98.2, 14.1"
                                }
                            ]
                        },
                        {
                            "Id": "NYTM",
                            "Name": "Naypyidaw",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96.216667, 19.733333",
                            "IataCode": "NYT",
                            "Airports": [
                                {
                                    "Id": "NYT",
                                    "Name": "Naypyidaw Airport",
                                    "CityId": "NYTM",
                                    "CountryId": "MM",
                                    "Location": "96.1829, 19.77117"
                                }
                            ]
                        },
                        {
                            "Id": "PBUM",
                            "Name": "Putao",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.4, 27.366667",
                            "IataCode": "PBU",
                            "Airports": [
                                {
                                    "Id": "PBU",
                                    "Name": "Putao",
                                    "CityId": "PBUM",
                                    "CountryId": "MM",
                                    "Location": "97.416667, 27.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MNUM",
                            "Name": "Maulmyine",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.625556, 16.491389",
                            "IataCode": "MNU",
                            "Airports": [
                                {
                                    "Id": "MNU",
                                    "Name": "Maulmyine",
                                    "CityId": "MNUM",
                                    "CountryId": "MM",
                                    "Location": "97.669444, 16.441667"
                                }
                            ]
                        },
                        {
                            "Id": "NYWA",
                            "Name": "Monywa",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "95.133333, 22.116667",
                            "IataCode": "NYW",
                            "Airports": [
                                {
                                    "Id": "NYW",
                                    "Name": "Monywa",
                                    "CityId": "NYWA",
                                    "CountryId": "MM",
                                    "Location": "95.0947, 22.2215"
                                }
                            ]
                        },
                        {
                            "Id": "MGKM",
                            "Name": "Mong Ton",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "98.934444, 20.294722",
                            "IataCode": "MGK",
                            "Airports": [
                                {
                                    "Id": "MGK",
                                    "Name": "Mong Ton",
                                    "CityId": "MGKM",
                                    "CountryId": "MM",
                                    "Location": "98.9, 20.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MGUM",
                            "Name": "Manaung",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "93.733333, 18.85",
                            "IataCode": "MGU",
                            "Airports": [
                                {
                                    "Id": "MGU",
                                    "Name": "Manaung",
                                    "CityId": "MGUM",
                                    "CountryId": "MM",
                                    "Location": "93.738889, 18.958333"
                                }
                            ]
                        },
                        {
                            "Id": "KAWM",
                            "Name": "Kawthaung",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "98.55, 9.9833333",
                            "IataCode": "KAW",
                            "Airports": [
                                {
                                    "Id": "KAW",
                                    "Name": "Kawthaung",
                                    "CityId": "KAWM",
                                    "CountryId": "MM",
                                    "Location": "98.516667, 10.05"
                                }
                            ]
                        },
                        {
                            "Id": "VBPA",
                            "Name": "Bokpyin",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "98.766667, 11.266667",
                            "IataCode": "VBP",
                            "Airports": [
                                {
                                    "Id": "VBP",
                                    "Name": "Bokpyin",
                                    "CityId": "VBPA",
                                    "CountryId": "MM",
                                    "Location": "98.767, 11.267"
                                }
                            ]
                        },
                        {
                            "Id": "TIOM",
                            "Name": "Thayettaw",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "96, 20",
                            "IataCode": "TIO",
                            "Airports": [
                                {
                                    "Id": "TIO",
                                    "Name": "Tilin",
                                    "CityId": "TIOM",
                                    "CountryId": "MM",
                                    "Location": "96, 20"
                                }
                            ]
                        },
                        {
                            "Id": "KYTM",
                            "Name": "Minkyi",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.133333, 21.4",
                            "IataCode": "KYT",
                            "Airports": [
                                {
                                    "Id": "KYT",
                                    "Name": "Kyauktaw",
                                    "CityId": "KYTM",
                                    "CountryId": "MM",
                                    "Location": "94.133333, 21.4"
                                }
                            ]
                        },
                        {
                            "Id": "KHMM",
                            "Name": "Mān Hpat",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "97.016667, 23",
                            "IataCode": "KHM",
                            "Airports": [
                                {
                                    "Id": "KHM",
                                    "Name": "Khamti",
                                    "CityId": "KHMM",
                                    "CountryId": "MM",
                                    "Location": "95.6744, 25.9883"
                                }
                            ]
                        },
                        {
                            "Id": "GWAM",
                            "Name": "Hkok",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "93.966667, 21.016667",
                            "IataCode": "GWA",
                            "Airports": [
                                {
                                    "Id": "GWA",
                                    "Name": "Gwa",
                                    "CityId": "GWAM",
                                    "CountryId": "MM",
                                    "Location": "94, 21"
                                }
                            ]
                        },
                        {
                            "Id": "VBAA",
                            "Name": "Aukywa",
                            "SingleAirportCity": true,
                            "CountryId": "MM",
                            "Location": "94.033333, 19.766667",
                            "IataCode": "VBA",
                            "Airports": [
                                {
                                    "Id": "VBA",
                                    "Name": "Ann",
                                    "CityId": "VBAA",
                                    "CountryId": "MM",
                                    "Location": "94.026133, 19.769156"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LA",
                    "Name": "Laos",
                    "CurrencyId": "LAK",
                    "Cities": [
                        {
                            "Id": "XKHA",
                            "Name": "Xieng Khouang",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "103.36667, 19.333333",
                            "IataCode": "XKH",
                            "Airports": [
                                {
                                    "Id": "XKH",
                                    "Name": "Xieng Khouang",
                                    "CityId": "XKHA",
                                    "CountryId": "LA",
                                    "Location": "103.158, 19.45"
                                }
                            ]
                        },
                        {
                            "Id": "ZBYA",
                            "Name": "Sayaboury",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "101.75, 19.25",
                            "IataCode": "ZBY",
                            "Airports": [
                                {
                                    "Id": "ZBY",
                                    "Name": "Sayaboury",
                                    "CityId": "ZBYA",
                                    "CountryId": "LA",
                                    "Location": "101.7, 19.25"
                                }
                            ]
                        },
                        {
                            "Id": "LPQL",
                            "Name": "Luang Prabang",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "102.13472, 19.885556",
                            "IataCode": "LPQ",
                            "Airports": [
                                {
                                    "Id": "LPQ",
                                    "Name": "Luang Prabang",
                                    "CityId": "LPQL",
                                    "CountryId": "LA",
                                    "Location": "102.16389, 19.895833"
                                }
                            ]
                        },
                        {
                            "Id": "VTEL",
                            "Name": "Vientiane",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "102.6, 17.966667",
                            "IataCode": "VTE",
                            "Airports": [
                                {
                                    "Id": "VTE",
                                    "Name": "Vientiane",
                                    "CityId": "VTEL",
                                    "CountryId": "LA",
                                    "Location": "102.56667, 17.984722"
                                }
                            ]
                        },
                        {
                            "Id": "AOUL",
                            "Name": "Attopeu",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "106.83333, 14.8",
                            "IataCode": "AOU",
                            "Airports": [
                                {
                                    "Id": "AOU",
                                    "Name": "Attopeu",
                                    "CityId": "AOUL",
                                    "CountryId": "LA",
                                    "Location": "106.83333, 14.8"
                                }
                            ]
                        },
                        {
                            "Id": "UONL",
                            "Name": "Muong Sai",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "101.98333, 20.7",
                            "IataCode": "UON",
                            "Airports": [
                                {
                                    "Id": "UON",
                                    "Name": "Muong Sai",
                                    "CityId": "UONL",
                                    "CountryId": "LA",
                                    "Location": "101.98333, 20.7"
                                }
                            ]
                        },
                        {
                            "Id": "NEUL",
                            "Name": "Sam Neua",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "104.68333, 19.966667",
                            "IataCode": "NEU",
                            "Airports": [
                                {
                                    "Id": "NEU",
                                    "Name": "Sam Neua",
                                    "CityId": "NEUL",
                                    "CountryId": "LA",
                                    "Location": "104.06667, 20.416667"
                                }
                            ]
                        },
                        {
                            "Id": "PKSL",
                            "Name": "Paksane",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "103.65, 18.366667",
                            "IataCode": "PKS",
                            "Airports": [
                                {
                                    "Id": "PKS",
                                    "Name": "Paksane",
                                    "CityId": "PKSL",
                                    "CountryId": "LA",
                                    "Location": "103.68333, 18.366667"
                                }
                            ]
                        },
                        {
                            "Id": "PKZL",
                            "Name": "Pakse",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "105.78333, 15.116667",
                            "IataCode": "PKZ",
                            "Airports": [
                                {
                                    "Id": "PKZ",
                                    "Name": "Pakse",
                                    "CityId": "PKZL",
                                    "CountryId": "LA",
                                    "Location": "105.78389, 15.131667"
                                }
                            ]
                        },
                        {
                            "Id": "SNDL",
                            "Name": "Seno",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "105.01667, 16.683333",
                            "IataCode": "SND",
                            "Airports": [
                                {
                                    "Id": "SND",
                                    "Name": "Seno",
                                    "CityId": "SNDL",
                                    "CountryId": "LA",
                                    "Location": "105.01667, 16.666667"
                                }
                            ]
                        },
                        {
                            "Id": "THKL",
                            "Name": "Thakhek",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "104.8, 17.4",
                            "IataCode": "THK",
                            "Airports": [
                                {
                                    "Id": "THK",
                                    "Name": "Thakhek",
                                    "CityId": "THKL",
                                    "CountryId": "LA",
                                    "Location": "104.81667, 17.4"
                                }
                            ]
                        },
                        {
                            "Id": "VNAL",
                            "Name": "Saravane",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "106.41667, 15.716667",
                            "IataCode": "VNA",
                            "Airports": [
                                {
                                    "Id": "VNA",
                                    "Name": "Saravane",
                                    "CityId": "VNAL",
                                    "CountryId": "LA",
                                    "Location": "106.42611, 15.692778"
                                }
                            ]
                        },
                        {
                            "Id": "ZVKA",
                            "Name": "Savannakhet",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "104.75, 16.55",
                            "IataCode": "ZVK",
                            "Airports": [
                                {
                                    "Id": "ZVK",
                                    "Name": "Savannakhet",
                                    "CityId": "ZVKA",
                                    "CountryId": "LA",
                                    "Location": "104.7625, 16.553611"
                                }
                            ]
                        },
                        {
                            "Id": "PCQL",
                            "Name": "Phongsaly",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "102.10583, 21.683333",
                            "IataCode": "PCQ",
                            "Airports": [
                                {
                                    "Id": "PCQ",
                                    "Name": "Phongsaly",
                                    "CityId": "PCQL",
                                    "CountryId": "LA",
                                    "Location": "101.90583, 21.645833"
                                }
                            ]
                        },
                        {
                            "Id": "LXGL",
                            "Name": "Luang Namtha",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "101.40111, 20.949722",
                            "IataCode": "LXG",
                            "Airports": [
                                {
                                    "Id": "LXG",
                                    "Name": "Luang Namtha",
                                    "CityId": "LXGL",
                                    "CountryId": "LA",
                                    "Location": "101.46667, 21.05"
                                }
                            ]
                        },
                        {
                            "Id": "HOEL",
                            "Name": "Houeisay",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "100.41278, 20.2775",
                            "IataCode": "HOE",
                            "Airports": [
                                {
                                    "Id": "HOE",
                                    "Name": "Houeisay",
                                    "CityId": "HOEL",
                                    "CountryId": "LA",
                                    "Location": "103.75, 20.5"
                                }
                            ]
                        },
                        {
                            "Id": "ODYL",
                            "Name": "Ban Taén",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "104.1625, 20.589167",
                            "IataCode": "ODY",
                            "Airports": [
                                {
                                    "Id": "ODY",
                                    "Name": "Oudomxay",
                                    "CityId": "ODYL",
                                    "CountryId": "LA",
                                    "Location": "101.994, 20.6827"
                                }
                            ]
                        },
                        {
                            "Id": "KOGL",
                            "Name": "Muang Khôngxédôn",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "105.81667, 15.566667",
                            "IataCode": "KOG",
                            "Airports": [
                                {
                                    "Id": "KOG",
                                    "Name": "Khong",
                                    "CityId": "KOGL",
                                    "CountryId": "LA",
                                    "Location": "105.81667, 15.566667"
                                }
                            ]
                        },
                        {
                            "Id": "XIEA",
                            "Name": "Ban Kèn",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "100.82167, 19.652778",
                            "IataCode": "XIE",
                            "Airports": [
                                {
                                    "Id": "XIE",
                                    "Name": "Xienglom",
                                    "CityId": "XIEA",
                                    "CountryId": "LA",
                                    "Location": "100.83333, 19.65"
                                }
                            ]
                        },
                        {
                            "Id": "UDOL",
                            "Name": "Ban Nam Pung",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "103.85, 19.133333",
                            "IataCode": "UDO",
                            "Airports": [
                                {
                                    "Id": "UDO",
                                    "Name": "Udomxay",
                                    "CityId": "UDOL",
                                    "CountryId": "LA",
                                    "Location": "103.81667, 19.133333"
                                }
                            ]
                        },
                        {
                            "Id": "XAYA",
                            "Name": "Ban Nampong",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "104.75, 18.666667",
                            "IataCode": "XAY",
                            "Airports": [
                                {
                                    "Id": "XAY",
                                    "Name": "Xayabury",
                                    "CityId": "XAYA",
                                    "CountryId": "LA",
                                    "Location": "104.71667, 18.666667"
                                }
                            ]
                        },
                        {
                            "Id": "OUIL",
                            "Name": "Ban Houayxai",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "102.67361, 18.926944",
                            "IataCode": "OUI",
                            "Airports": [
                                {
                                    "Id": "OUI",
                                    "Name": "Ban Houei",
                                    "CityId": "OUIL",
                                    "CountryId": "LA",
                                    "Location": "100.437, 20.2573"
                                }
                            ]
                        },
                        {
                            "Id": "VNGL",
                            "Name": "Ban Houaymouang",
                            "SingleAirportCity": true,
                            "CountryId": "LA",
                            "Location": "102.51556, 18.933056",
                            "IataCode": "VNG",
                            "Airports": [
                                {
                                    "Id": "VNG",
                                    "Name": "Viengxay",
                                    "CityId": "VNGL",
                                    "CountryId": "LA",
                                    "Location": "102.5, 18.933333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BN",
                    "Name": "Brunei",
                    "CurrencyId": "BND",
                    "Cities": [
                        {
                            "Id": "BWNA",
                            "Name": "Bandar Seri Begawan",
                            "SingleAirportCity": true,
                            "CountryId": "BN",
                            "Location": "114.93333, 4.8833333",
                            "IataCode": "BWN",
                            "Airports": [
                                {
                                    "Id": "BWN",
                                    "Name": "Bandar Seri Begawan",
                                    "CityId": "BWNA",
                                    "CountryId": "BN",
                                    "Location": "114.92835, 4.9442"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "Y",
            "Name": "Arctic Region (Europe)",
            "Countries": [
                {
                    "Id": "IS",
                    "Name": "Iceland",
                    "CurrencyId": "ISK",
                    "Cities": [
                        {
                            "Id": "AKUR",
                            "Name": "Akureyri",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-18.1, 65.666667",
                            "IataCode": "AEY",
                            "Airports": [
                                {
                                    "Id": "AEY",
                                    "Name": "Akureyri",
                                    "CityId": "AKUR",
                                    "CountryId": "IS",
                                    "Location": "-18.166667, 65.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BILD",
                            "Name": "Bildudalur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.6, 65.683333",
                            "IataCode": "BIU",
                            "Airports": [
                                {
                                    "Id": "BIU",
                                    "Name": "Bildudalur",
                                    "CityId": "BILD",
                                    "CountryId": "IS",
                                    "Location": "-23.983333, 65.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BAKK",
                            "Name": "Bakkafjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14.8, 66.033333",
                            "IataCode": "BJD",
                            "Airports": [
                                {
                                    "Id": "BJD",
                                    "Name": "Bakkafjordur",
                                    "CityId": "BAKK",
                                    "CountryId": "IS",
                                    "Location": "-14.75, 66.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BLON",
                            "Name": "Blonduos",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-20.3, 65.666667",
                            "IataCode": "BLO",
                            "Airports": [
                                {
                                    "Id": "BLO",
                                    "Name": "Blonduos",
                                    "CityId": "BLON",
                                    "CountryId": "IS",
                                    "Location": "-20.3, 65.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BREI",
                            "Name": "Breiddalsvik",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14, 64.8",
                            "IataCode": "BXV",
                            "Airports": [
                                {
                                    "Id": "BXV",
                                    "Name": "Breiddalsvik",
                                    "CityId": "BREI",
                                    "CountryId": "IS",
                                    "Location": "-14, 64.766667"
                                }
                            ]
                        },
                        {
                            "Id": "DJUP",
                            "Name": "Djupivogur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14.283333, 64.65",
                            "IataCode": "DJU",
                            "Airports": [
                                {
                                    "Id": "DJU",
                                    "Name": "Djupivogur",
                                    "CityId": "DJUP",
                                    "CountryId": "IS",
                                    "Location": "-14.268333, 64.650278"
                                }
                            ]
                        },
                        {
                            "Id": "EGIL",
                            "Name": "Egilsstadir",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14.394837, 65.265327",
                            "IataCode": "EGS",
                            "Airports": [
                                {
                                    "Id": "EGS",
                                    "Name": "Egilsstadir",
                                    "CityId": "EGIL",
                                    "CountryId": "IS",
                                    "Location": "-14.402778, 65.277778"
                                }
                            ]
                        },
                        {
                            "Id": "FASK",
                            "Name": "Faskrudsfjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14.016667, 64.933333",
                            "IataCode": "FAS",
                            "Airports": [
                                {
                                    "Id": "FAS",
                                    "Name": "Faskrudsfjordur",
                                    "CityId": "FASK",
                                    "CountryId": "IS",
                                    "Location": "-14.016667, 64.95"
                                }
                            ]
                        },
                        {
                            "Id": "FLAT",
                            "Name": "Flateyri",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.516666, 66.05",
                            "IataCode": "FLI",
                            "Airports": [
                                {
                                    "Id": "FLI",
                                    "Name": "Flateyri",
                                    "CityId": "FLAT",
                                    "CountryId": "IS",
                                    "Location": "-23.527222, 66.062076"
                                }
                            ]
                        },
                        {
                            "Id": "GJOG",
                            "Name": "Gjogur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-21.35, 65.983333",
                            "IataCode": "GJR",
                            "Airports": [
                                {
                                    "Id": "GJR",
                                    "Name": "Gjogur",
                                    "CityId": "GJOG",
                                    "CountryId": "IS",
                                    "Location": "-21.35, 65.983333"
                                }
                            ]
                        },
                        {
                            "Id": "HOFN",
                            "Name": "Hofn Horafjorur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-15.216667, 64.25",
                            "IataCode": "HFN",
                            "Airports": [
                                {
                                    "Id": "HFN",
                                    "Name": "Hofn Horafjorur",
                                    "CityId": "HOFN",
                                    "CountryId": "IS",
                                    "Location": "-15.266667, 64.283333"
                                }
                            ]
                        },
                        {
                            "Id": "HOLM",
                            "Name": "Holmavik",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-21.683333, 65.716667",
                            "IataCode": "HVK",
                            "Airports": [
                                {
                                    "Id": "HVK",
                                    "Name": "Holmavik",
                                    "CityId": "HOLM",
                                    "CountryId": "IS",
                                    "Location": "-22.466667, 65.7"
                                }
                            ]
                        },
                        {
                            "Id": "HVAM",
                            "Name": "Hvammstangi",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-20.95, 65.4",
                            "IataCode": "HVM",
                            "Airports": [
                                {
                                    "Id": "HVM",
                                    "Name": "Hvammstangi",
                                    "CityId": "HVAM",
                                    "CountryId": "IS",
                                    "Location": "-21, 65.416667"
                                }
                            ]
                        },
                        {
                            "Id": "HUSA",
                            "Name": "Husavik",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-17.35, 66.05",
                            "IataCode": "HZK",
                            "Airports": [
                                {
                                    "Id": "HZK",
                                    "Name": "Husavik",
                                    "CityId": "HUSA",
                                    "CountryId": "IS",
                                    "Location": "-17.427778, 65.955278"
                                }
                            ]
                        },
                        {
                            "Id": "ISAF",
                            "Name": "Isafjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.15, 66.083333",
                            "IataCode": "IFJ",
                            "Airports": [
                                {
                                    "Id": "IFJ",
                                    "Name": "Isafjordur",
                                    "CityId": "ISAF",
                                    "CountryId": "IS",
                                    "Location": "-23.127079, 66.058036"
                                }
                            ]
                        },
                        {
                            "Id": "REYK",
                            "Name": "Reykjavik",
                            "SingleAirportCity": false,
                            "CountryId": "IS",
                            "Location": "-21.95, 64.150002",
                            "IataCode": "REK",
                            "Airports": [
                                {
                                    "Id": "KEF",
                                    "Name": "Reykjavik Keflavik",
                                    "CityId": "REYK",
                                    "CountryId": "IS",
                                    "Location": "-22.606111, 63.985"
                                },
                                {
                                    "Id": "RKV",
                                    "Name": "Reykjavik Domestic",
                                    "CityId": "REYK",
                                    "CountryId": "IS",
                                    "Location": "-21.95, 64.133333"
                                }
                            ]
                        },
                        {
                            "Id": "PATS",
                            "Name": "Patreksfjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-24, 65.583333",
                            "IataCode": "PFJ",
                            "Airports": [
                                {
                                    "Id": "PFJ",
                                    "Name": "Patreksfjordur",
                                    "CityId": "PATS",
                                    "CountryId": "IS",
                                    "Location": "-23.971996, 65.556258"
                                }
                            ]
                        },
                        {
                            "Id": "SAUD",
                            "Name": "Saudarkrokur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-19.65, 65.75",
                            "IataCode": "SAK",
                            "Airports": [
                                {
                                    "Id": "SAK",
                                    "Name": "Saudarkrokur",
                                    "CityId": "SAUD",
                                    "CountryId": "IS",
                                    "Location": "-19.65, 65.75"
                                }
                            ]
                        },
                        {
                            "Id": "SIGL",
                            "Name": "Siglufjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-18.916667, 66.15",
                            "IataCode": "SIJ",
                            "Airports": [
                                {
                                    "Id": "SIJ",
                                    "Name": "Siglufjordur",
                                    "CityId": "SIGL",
                                    "CountryId": "IS",
                                    "Location": "-18.9167, 66.1333"
                                }
                            ]
                        },
                        {
                            "Id": "THOR",
                            "Name": "Thorshofn",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-15.333333, 66.2",
                            "IataCode": "THO",
                            "Airports": [
                                {
                                    "Id": "THO",
                                    "Name": "Thorshofn",
                                    "CityId": "THOR",
                                    "CountryId": "IS",
                                    "Location": "-15.333333, 66.2"
                                }
                            ]
                        },
                        {
                            "Id": "VEST",
                            "Name": "Vestmannaeyjar",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-20.2075, 63.365",
                            "IataCode": "VEY",
                            "Airports": [
                                {
                                    "Id": "VEY",
                                    "Name": "Vestmannaeyjar",
                                    "CityId": "VEST",
                                    "CountryId": "IS",
                                    "Location": "-20.277778, 63.426389"
                                }
                            ]
                        },
                        {
                            "Id": "VOPN",
                            "Name": "Vopnafjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14.833333, 65.75",
                            "IataCode": "VPN",
                            "Airports": [
                                {
                                    "Id": "VPN",
                                    "Name": "Vopnafjordur",
                                    "CityId": "VOPN",
                                    "CountryId": "IS",
                                    "Location": "-14.829444, 65.756944"
                                }
                            ]
                        },
                        {
                            "Id": "OLAF",
                            "Name": "Olafsfjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-18.65, 66.066667",
                            "IataCode": "OFJ",
                            "Airports": [
                                {
                                    "Id": "OFJ",
                                    "Name": "Olafsfjordur",
                                    "CityId": "OLAF",
                                    "CountryId": "IS",
                                    "Location": "-18.61084, 66.060404"
                                }
                            ]
                        },
                        {
                            "Id": "KOPA",
                            "Name": "Kopasker",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-16.45, 66.3",
                            "IataCode": "OPA",
                            "Airports": [
                                {
                                    "Id": "OPA",
                                    "Name": "Kopasker",
                                    "CityId": "KOPA",
                                    "CountryId": "IS",
                                    "Location": "-16.483333, 66.416667"
                                }
                            ]
                        },
                        {
                            "Id": "RAUF",
                            "Name": "Raufarhofn",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-15.95, 66.45",
                            "IataCode": "RFN",
                            "Airports": [
                                {
                                    "Id": "RFN",
                                    "Name": "Raufarhofn",
                                    "CityId": "RAUF",
                                    "CountryId": "IS",
                                    "Location": "-15.993347, 66.470534"
                                }
                            ]
                        },
                        {
                            "Id": "SEYD",
                            "Name": "Seydisfjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-14, 65.266667",
                            "IataCode": "SEJ",
                            "Airports": [
                                {
                                    "Id": "SEJ",
                                    "Name": "Seydisfjordur",
                                    "CityId": "SEYD",
                                    "CountryId": "IS",
                                    "Location": "-13.95, 65.266667"
                                }
                            ]
                        },
                        {
                            "Id": "STYK",
                            "Name": "Stykkisholmur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-22.733333, 65.066667",
                            "IataCode": "SYK",
                            "Airports": [
                                {
                                    "Id": "SYK",
                                    "Name": "Stykkisholmur",
                                    "CityId": "STYK",
                                    "CountryId": "IS",
                                    "Location": "-22.745819, 65.069815"
                                }
                            ]
                        },
                        {
                            "Id": "THIN",
                            "Name": "Thingeyri",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.5, 65.866667",
                            "IataCode": "TEY",
                            "Airports": [
                                {
                                    "Id": "TEY",
                                    "Name": "Thingeyri",
                                    "CityId": "THIN",
                                    "CountryId": "IS",
                                    "Location": "-23.486023, 65.872479"
                                }
                            ]
                        },
                        {
                            "Id": "OLAG",
                            "Name": "Olafsvik",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.716666, 64.883333",
                            "IataCode": "OLI",
                            "Airports": [
                                {
                                    "Id": "OLI",
                                    "Name": "Olafsvik",
                                    "CityId": "OLAG",
                                    "CountryId": "IS",
                                    "Location": "-23.812866, 64.911898"
                                }
                            ]
                        },
                        {
                            "Id": "GRUN",
                            "Name": "Grundarfjordur",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-23.266666, 64.916667",
                            "IataCode": "GUU",
                            "Airports": [
                                {
                                    "Id": "GUU",
                                    "Name": "Grundarfjordur",
                                    "CityId": "GRUN",
                                    "CountryId": "IS",
                                    "Location": "-23.166667, 64.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BORG",
                            "Name": "Borgarnes",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-21.916667, 64.533333",
                            "IataCode": "BGJ",
                            "Airports": [
                                {
                                    "Id": "BGJ",
                                    "Name": "Borgarfjordur Eystri",
                                    "CityId": "BORG",
                                    "CountryId": "IS",
                                    "Location": "-21.972656, 64.465099"
                                }
                            ]
                        },
                        {
                            "Id": "NORH",
                            "Name": "Neskaupstaður",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-13.7, 65.15",
                            "IataCode": "NOR",
                            "Airports": [
                                {
                                    "Id": "NOR",
                                    "Name": "Nordfjordur",
                                    "CityId": "NORH",
                                    "CountryId": "IS",
                                    "Location": "-13.7, 65.15"
                                }
                            ]
                        },
                        {
                            "Id": "MYVA",
                            "Name": "Reykjahlíð",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-16.916666, 65.65",
                            "IataCode": "MVA",
                            "Airports": [
                                {
                                    "Id": "MVA",
                                    "Name": "Myvatn",
                                    "CityId": "MYVA",
                                    "CountryId": "IS",
                                    "Location": "-16.966667, 65.616667"
                                }
                            ]
                        },
                        {
                            "Id": "GRIM",
                            "Name": "Miðgarðar",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-18, 66.533333",
                            "IataCode": "GRY",
                            "Airports": [
                                {
                                    "Id": "GRY",
                                    "Name": "Grimsey",
                                    "CityId": "GRIM",
                                    "CountryId": "IS",
                                    "Location": "-18.009338, 66.542797"
                                }
                            ]
                        },
                        {
                            "Id": "FAGU",
                            "Name": "Knappavellir",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-16.6, 63.9",
                            "IataCode": "FAG",
                            "Airports": [
                                {
                                    "Id": "FAG",
                                    "Name": "Fagurholsmyri",
                                    "CityId": "FAGU",
                                    "CountryId": "IS",
                                    "Location": "-16.65, 63.883333"
                                }
                            ]
                        },
                        {
                            "Id": "REYL",
                            "Name": "Víðidalstunga",
                            "SingleAirportCity": true,
                            "CountryId": "IS",
                            "Location": "-20.6, 65.35",
                            "IataCode": "RHA",
                            "Airports": [
                                {
                                    "Id": "RHA",
                                    "Name": "Reykholar",
                                    "CityId": "REYL",
                                    "CountryId": "IS",
                                    "Location": "-22.20612, 65.45263"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "Z",
            "Name": "Antarctic Region",
            "Countries": [
                {
                    "Id": "AQ",
                    "Name": "Antarctica",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "TNMA",
                            "Name": "King George Island",
                            "SingleAirportCity": true,
                            "CountryId": "AQ",
                            "Location": "-58.986389, -62.190556",
                            "IataCode": "TNM",
                            "Airports": [
                                {
                                    "Id": "TNM",
                                    "Name": "Teniente R. Marsh",
                                    "CityId": "TNMA",
                                    "CountryId": "AQ",
                                    "Location": "-58.986389, -62.190556"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TF",
                    "Name": "French Southern Territories",
                    "CurrencyId": "EUR",
                    "Cities": []
                },
                {
                    "Id": "HM",
                    "Name": "Heard and McDonald Islands",
                    "CurrencyId": "USD",
                    "Cities": []
                },
                {
                    "Id": "GS",
                    "Name": "S. Georgia and S. Sandwich Isls.",
                    "CurrencyId": "GBP",
                    "Cities": []
                },
                {
                    "Id": "BV",
                    "Name": "Bouvet Island",
                    "CurrencyId": "USD",
                    "Cities": []
                }
            ]
        },
        {
            "Id": "M",
            "Name": "Middle East",
            "Countries": [
                {
                    "Id": "TR",
                    "Name": "Turkey",
                    "CurrencyId": "TRY",
                    "LanguageId": "TR",
                    "Cities": [
                        {
                            "Id": "YEIA",
                            "Name": "Bursa",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "29.06111, 40.19167",
                            "IataCode": "YEI",
                            "Airports": [
                                {
                                    "Id": "YEI",
                                    "Name": "Bursa",
                                    "CityId": "YEIA",
                                    "CountryId": "TR",
                                    "Location": "29.55, 40.233333"
                                },
                                {
                                    "Id": "BTZ",
                                    "Name": "Bursa",
                                    "CityId": "YEIA",
                                    "CountryId": "TR",
                                    "Location": "29.009722, 40.234444"
                                }
                            ]
                        },
                        {
                            "Id": "ADAN",
                            "Name": "Adana",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "35.328888, 37.001669",
                            "IataCode": "ADA",
                            "Airports": [
                                {
                                    "Id": "ADA",
                                    "Name": "Adana",
                                    "CityId": "ADAN",
                                    "CountryId": "TR",
                                    "Location": "35.280833, 36.983611"
                                }
                            ]
                        },
                        {
                            "Id": "IZMI",
                            "Name": "Izmir",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "27.143412, 38.414733",
                            "IataCode": "IZM",
                            "Airports": [
                                {
                                    "Id": "IGL",
                                    "Name": "Izmir Cigli",
                                    "CityId": "IZMI",
                                    "CountryId": "TR",
                                    "Location": "27.159444, 38.318889"
                                },
                                {
                                    "Id": "ADB",
                                    "Name": "Izmir",
                                    "CityId": "IZMI",
                                    "CountryId": "TR",
                                    "Location": "27.15, 38.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ADFA",
                            "Name": "Adiyaman",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "38.278333, 37.759168",
                            "IataCode": "ADF",
                            "Airports": [
                                {
                                    "Id": "ADF",
                                    "Name": "Adiyaman",
                                    "CityId": "ADFA",
                                    "CountryId": "TR",
                                    "Location": "38.468936, 37.731369"
                                }
                            ]
                        },
                        {
                            "Id": "AFYO",
                            "Name": "Afyon",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "30.543334, 38.756668",
                            "IataCode": "AFY",
                            "Airports": [
                                {
                                    "Id": "AFY",
                                    "Name": "Afyon",
                                    "CityId": "AFYO",
                                    "CountryId": "TR",
                                    "Location": "30.6, 38.733333"
                                }
                            ]
                        },
                        {
                            "Id": "AGRO",
                            "Name": "Agri",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "43.051389, 39.719444",
                            "IataCode": "AJI",
                            "Airports": [
                                {
                                    "Id": "AJI",
                                    "Name": "Agri",
                                    "CityId": "AGRO",
                                    "CountryId": "TR",
                                    "Location": "43.025, 39.65"
                                }
                            ]
                        },
                        {
                            "Id": "ANKA",
                            "Name": "Ankara",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "32.864445, 39.927231",
                            "IataCode": "ANK",
                            "Airports": [
                                {
                                    "Id": "ESB",
                                    "Name": "Ankara Esenboga",
                                    "CityId": "ANKA",
                                    "CountryId": "TR",
                                    "Location": "33, 40.133333"
                                },
                                {
                                    "Id": "ANK",
                                    "Name": "Ankara Etimesgut",
                                    "CityId": "ANKA",
                                    "CountryId": "TR",
                                    "Location": "32.688622, 39.949831"
                                }
                            ]
                        },
                        {
                            "Id": "ESKI",
                            "Name": "Eskisehir",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "30.520556, 39.776667",
                            "IataCode": "ESK",
                            "Airports": [
                                {
                                    "Id": "ESK",
                                    "Name": "Eskisehir",
                                    "CityId": "ESKI",
                                    "CountryId": "TR",
                                    "Location": "30.58211, 39.78414"
                                },
                                {
                                    "Id": "AOE",
                                    "Name": "Eskisehir",
                                    "CityId": "ESKI",
                                    "CountryId": "TR",
                                    "Location": "30.519167, 39.809722"
                                }
                            ]
                        },
                        {
                            "Id": "KCMA",
                            "Name": "Kahramanmaras",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "36.931667, 37.5875",
                            "IataCode": "KCM",
                            "Airports": [
                                {
                                    "Id": "KCM",
                                    "Name": "Kahramanmaras",
                                    "CityId": "KCMA",
                                    "CountryId": "TR",
                                    "Location": "36.950556, 37.534444"
                                }
                            ]
                        },
                        {
                            "Id": "BAND",
                            "Name": "Bandirma",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.976667, 40.352222",
                            "IataCode": "BDM",
                            "Airports": [
                                {
                                    "Id": "BDM",
                                    "Name": "Bandirma",
                                    "CityId": "BAND",
                                    "CountryId": "TR",
                                    "Location": "27.977222, 40.318333"
                                }
                            ]
                        },
                        {
                            "Id": "BODR",
                            "Name": "Bodrum",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.429167, 37.038333",
                            "IataCode": "BJV",
                            "Airports": [
                                {
                                    "Id": "BJV",
                                    "Name": "Bodrum",
                                    "CityId": "BODR",
                                    "CountryId": "TR",
                                    "Location": "27.683333, 37.25"
                                }
                            ]
                        },
                        {
                            "Id": "BALI",
                            "Name": "Balikesir",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.886111, 39.649167",
                            "IataCode": "BZI",
                            "Airports": [
                                {
                                    "Id": "BZI",
                                    "Name": "Balikesir",
                                    "CityId": "BALI",
                                    "CountryId": "TR",
                                    "Location": "27.927778, 39.617222"
                                }
                            ]
                        },
                        {
                            "Id": "CANA",
                            "Name": "Canakkale",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "26.406389, 40.145556",
                            "IataCode": "CKZ",
                            "Airports": [
                                {
                                    "Id": "CKZ",
                                    "Name": "Canakkale",
                                    "CityId": "CANA",
                                    "CountryId": "TR",
                                    "Location": "26.4, 40.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DIYA",
                            "Name": "Diyarbakir",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "40.210555, 37.918889",
                            "IataCode": "DIY",
                            "Airports": [
                                {
                                    "Id": "DIY",
                                    "Name": "Diyarbakir",
                                    "CityId": "DIYA",
                                    "CountryId": "TR",
                                    "Location": "40.203056, 37.895278"
                                }
                            ]
                        },
                        {
                            "Id": "DALA",
                            "Name": "Dalaman",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "28.804167, 36.765",
                            "IataCode": "DLM",
                            "Airports": [
                                {
                                    "Id": "DLM",
                                    "Name": "Dalaman",
                                    "CityId": "DALA",
                                    "CountryId": "TR",
                                    "Location": "28.783333, 36.716667"
                                }
                            ]
                        },
                        {
                            "Id": "DENI",
                            "Name": "Denizli Cardak",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "29.0875, 37.774167",
                            "IataCode": "DNZ",
                            "Airports": [
                                {
                                    "Id": "DNZ",
                                    "Name": "Denizli Cardak",
                                    "CityId": "DENI",
                                    "CountryId": "TR",
                                    "Location": "29.703333, 37.787222"
                                }
                            ]
                        },
                        {
                            "Id": "ERZI",
                            "Name": "Erzincan",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "39.492778, 39.752222",
                            "IataCode": "ERC",
                            "Airports": [
                                {
                                    "Id": "ERC",
                                    "Name": "Erzincan",
                                    "CityId": "ERZI",
                                    "CountryId": "TR",
                                    "Location": "39.516944, 39.711667"
                                }
                            ]
                        },
                        {
                            "Id": "ERZU",
                            "Name": "Erzurum",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "41.276944, 39.908612",
                            "IataCode": "ERZ",
                            "Airports": [
                                {
                                    "Id": "ERZ",
                                    "Name": "Erzurum",
                                    "CityId": "ERZU",
                                    "CountryId": "TR",
                                    "Location": "41.173611, 39.955556"
                                }
                            ]
                        },
                        {
                            "Id": "ELAZ",
                            "Name": "Elazig",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "39.220556, 38.67528",
                            "IataCode": "EZS",
                            "Airports": [
                                {
                                    "Id": "EZS",
                                    "Name": "Elazig",
                                    "CityId": "ELAZ",
                                    "CountryId": "TR",
                                    "Location": "39.291667, 38.608333"
                                }
                            ]
                        },
                        {
                            "Id": "GAZI",
                            "Name": "Gaziantep",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "37.3825, 37.059446",
                            "IataCode": "GZT",
                            "Airports": [
                                {
                                    "Id": "GZT",
                                    "Name": "Gaziantep",
                                    "CityId": "GAZI",
                                    "CountryId": "TR",
                                    "Location": "37.478683, 36.947183"
                                }
                            ]
                        },
                        {
                            "Id": "KCOA",
                            "Name": "Kocaeli",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "29.916944, 40.766945",
                            "IataCode": "KCO",
                            "Airports": [
                                {
                                    "Id": "KCO",
                                    "Name": "Kocaeli",
                                    "CityId": "KCOA",
                                    "CountryId": "TR",
                                    "Location": "30.08334, 40.73503"
                                }
                            ]
                        },
                        {
                            "Id": "HTYA",
                            "Name": "Antakya",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "36.160556, 36.198889",
                            "IataCode": "HTY",
                            "Airports": [
                                {
                                    "Id": "HTY",
                                    "Name": "Antakya",
                                    "CityId": "HTYA",
                                    "CountryId": "TR",
                                    "Location": "36.282349, 36.369604"
                                }
                            ]
                        },
                        {
                            "Id": "ISEA",
                            "Name": "Isparta",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "30.552223, 37.764444",
                            "IataCode": "ISE",
                            "Airports": [
                                {
                                    "Id": "ISE",
                                    "Name": "Isparta",
                                    "CityId": "ISEA",
                                    "CountryId": "TR",
                                    "Location": "30.382222, 37.866111"
                                }
                            ]
                        },
                        {
                            "Id": "ISTA",
                            "Name": "Istanbul",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "28.955461, 41.01656",
                            "IataCode": "IST",
                            "Airports": [
                                {
                                    "Id": "IST",
                                    "Name": "Istanbul",
                                    "CityId": "ISTA",
                                    "CountryId": "TR",
                                    "Location": "28.742772, 41.259908"
                                },
                                {
                                    "Id": "SAW",
                                    "Name": "Istanbul Sabiha",
                                    "CityId": "ISTA",
                                    "CountryId": "TR",
                                    "Location": "29.309167, 40.899444"
                                },
                                {
                                    "Id": "ISL",
                                    "Name": "Istanbul Ataturk",
                                    "CityId": "ISTA",
                                    "CountryId": "TR",
                                    "Location": "28.815278, 40.976667"
                                }
                            ]
                        },
                        {
                            "Id": "KARS",
                            "Name": "Kars",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "43.0975, 40.608056",
                            "IataCode": "KSY",
                            "Airports": [
                                {
                                    "Id": "KSY",
                                    "Name": "Kars",
                                    "CityId": "KARS",
                                    "CountryId": "TR",
                                    "Location": "43.085833, 40.551667"
                                }
                            ]
                        },
                        {
                            "Id": "KONY",
                            "Name": "Konya",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "32.4825, 37.865556",
                            "IataCode": "KYA",
                            "Airports": [
                                {
                                    "Id": "KYA",
                                    "Name": "Konya",
                                    "CityId": "KONY",
                                    "CountryId": "TR",
                                    "Location": "32.562222, 37.979167"
                                }
                            ]
                        },
                        {
                            "Id": "MALB",
                            "Name": "Malatya",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "38.311945, 38.353333",
                            "IataCode": "MLX",
                            "Airports": [
                                {
                                    "Id": "MLX",
                                    "Name": "Malatya",
                                    "CityId": "MALB",
                                    "CountryId": "TR",
                                    "Location": "38.091006, 38.435347"
                                }
                            ]
                        },
                        {
                            "Id": "MARD",
                            "Name": "Mardin",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "40.738611, 37.312778",
                            "IataCode": "MQM",
                            "Airports": [
                                {
                                    "Id": "MQM",
                                    "Name": "Mardin",
                                    "CityId": "MARD",
                                    "CountryId": "TR",
                                    "Location": "40.631667, 37.223336"
                                }
                            ]
                        },
                        {
                            "Id": "MUSI",
                            "Name": "Mus",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "41.496944, 38.748611",
                            "IataCode": "MSR",
                            "Airports": [
                                {
                                    "Id": "MSR",
                                    "Name": "Mus",
                                    "CityId": "MUSI",
                                    "CountryId": "TR",
                                    "Location": "41.661236, 38.747769"
                                }
                            ]
                        },
                        {
                            "Id": "MERZ",
                            "Name": "Merzifon",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "35.463055, 40.873333",
                            "IataCode": "MZH",
                            "Airports": [
                                {
                                    "Id": "MZH",
                                    "Name": "Merzifon",
                                    "CityId": "MERZ",
                                    "CountryId": "TR",
                                    "Location": "35.521992, 40.829375"
                                }
                            ]
                        },
                        {
                            "Id": "ONQA",
                            "Name": "Zonguldak",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "31.789722, 41.455556",
                            "IataCode": "ONQ",
                            "Airports": [
                                {
                                    "Id": "ONQ",
                                    "Name": "Zonguldak",
                                    "CityId": "ONQA",
                                    "CountryId": "TR",
                                    "Location": "32.1, 41.516667"
                                }
                            ]
                        },
                        {
                            "Id": "SANL",
                            "Name": "Sanliurfa",
                            "SingleAirportCity": false,
                            "CountryId": "TR",
                            "Location": "38.792778, 37.151112",
                            "IataCode": "SFQ",
                            "Airports": [
                                {
                                    "Id": "GNY",
                                    "Name": "Sanliurfa Guney Anadoulu Projesi",
                                    "CityId": "SANL",
                                    "CountryId": "TR",
                                    "Location": "38.90216, 37.44556"
                                },
                                {
                                    "Id": "SFQ",
                                    "Name": "Sanliurfa",
                                    "CityId": "SANL",
                                    "CountryId": "TR",
                                    "Location": "38.85, 37.085278"
                                }
                            ]
                        },
                        {
                            "Id": "SINO",
                            "Name": "Sinop",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "35.1575, 42.026944",
                            "IataCode": "NOP",
                            "Airports": [
                                {
                                    "Id": "NOP",
                                    "Name": "Sinop Airport",
                                    "CityId": "SINO",
                                    "CountryId": "TR",
                                    "Location": "35.066389, 42.015833"
                                }
                            ]
                        },
                        {
                            "Id": "SXZA",
                            "Name": "Siirt",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "41.945277, 37.927222",
                            "IataCode": "SXZ",
                            "Airports": [
                                {
                                    "Id": "SXZ",
                                    "Name": "Siirt",
                                    "CityId": "SXZA",
                                    "CountryId": "TR",
                                    "Location": "41.84, 37.98"
                                }
                            ]
                        },
                        {
                            "Id": "SAMS",
                            "Name": "Samsun",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "36.33, 41.286668",
                            "IataCode": "SZF",
                            "Airports": [
                                {
                                    "Id": "SZF",
                                    "Name": "Samsun Carsamba",
                                    "CityId": "SAMS",
                                    "CountryId": "TR",
                                    "Location": "36.55, 41.25"
                                }
                            ]
                        },
                        {
                            "Id": "TEQA",
                            "Name": "Tekirdag",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.515, 40.980557",
                            "IataCode": "TEQ",
                            "Airports": [
                                {
                                    "Id": "TEQ",
                                    "Name": "Tekirdag",
                                    "CityId": "TEQA",
                                    "CountryId": "TR",
                                    "Location": "27.91909, 41.13825"
                                }
                            ]
                        },
                        {
                            "Id": "TJKA",
                            "Name": "Tokat",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "36.554445, 40.313889",
                            "IataCode": "TJK",
                            "Airports": [
                                {
                                    "Id": "TJK",
                                    "Name": "Tokat",
                                    "CityId": "TJKA",
                                    "CountryId": "TR",
                                    "Location": "36.367778, 40.302222"
                                }
                            ]
                        },
                        {
                            "Id": "TRAB",
                            "Name": "Trabzon",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "39.726944, 41.005002",
                            "IataCode": "TZX",
                            "Airports": [
                                {
                                    "Id": "TZX",
                                    "Name": "Trabzon",
                                    "CityId": "TRAB",
                                    "CountryId": "TR",
                                    "Location": "39.776389, 40.998611"
                                }
                            ]
                        },
                        {
                            "Id": "USQA",
                            "Name": "Usak",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "29.408055, 38.68",
                            "IataCode": "USQ",
                            "Airports": [
                                {
                                    "Id": "USQ",
                                    "Name": "Usak",
                                    "CityId": "USQA",
                                    "CountryId": "TR",
                                    "Location": "29.471944, 38.682222"
                                }
                            ]
                        },
                        {
                            "Id": "VANI",
                            "Name": "Van",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "43.38, 38.494167",
                            "IataCode": "VAN",
                            "Airports": [
                                {
                                    "Id": "VAN",
                                    "Name": "Van",
                                    "CityId": "VANI",
                                    "CountryId": "TR",
                                    "Location": "43.332222, 38.458611"
                                }
                            ]
                        },
                        {
                            "Id": "SIVA",
                            "Name": "Sivas",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "37.016112, 39.748333",
                            "IataCode": "VAS",
                            "Airports": [
                                {
                                    "Id": "VAS",
                                    "Name": "Sivas",
                                    "CityId": "SIVA",
                                    "CountryId": "TR",
                                    "Location": "36.904167, 39.813889"
                                }
                            ]
                        },
                        {
                            "Id": "KAYS",
                            "Name": "Kayseri",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "35.485277, 38.732224",
                            "IataCode": "ASR",
                            "Airports": [
                                {
                                    "Id": "ASR",
                                    "Name": "Kayseri",
                                    "CityId": "KAYS",
                                    "CountryId": "TR",
                                    "Location": "35.495428, 38.770386"
                                }
                            ]
                        },
                        {
                            "Id": "ANTA",
                            "Name": "Antalya",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "30.720324, 36.905148",
                            "IataCode": "AYT",
                            "Airports": [
                                {
                                    "Id": "AYT",
                                    "Name": "Antalya",
                                    "CityId": "ANTA",
                                    "CountryId": "TR",
                                    "Location": "30.790556, 36.905556"
                                }
                            ]
                        },
                        {
                            "Id": "BATM",
                            "Name": "Batman",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "41.120555, 37.882778",
                            "IataCode": "BAL",
                            "Airports": [
                                {
                                    "Id": "BAL",
                                    "Name": "Batman",
                                    "CityId": "BATM",
                                    "CountryId": "TR",
                                    "Location": "41.083333, 37.916667"
                                }
                            ]
                        },
                        {
                            "Id": "NAVA",
                            "Name": "Nevsehir",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "34.712222, 38.625",
                            "IataCode": "NAV",
                            "Airports": [
                                {
                                    "Id": "NAV",
                                    "Name": "Nevsehir",
                                    "CityId": "NAVA",
                                    "CountryId": "TR",
                                    "Location": "34.53455, 38.771867"
                                }
                            ]
                        },
                        {
                            "Id": "XKUA",
                            "Name": "Kusadasi",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.261111, 37.8575",
                            "IataCode": "XKU",
                            "Airports": [
                                {
                                    "Id": "XKU",
                                    "Name": "Kusadasi",
                                    "CityId": "XKUA",
                                    "CountryId": "TR",
                                    "Location": "27.25, 37.86667"
                                }
                            ]
                        },
                        {
                            "Id": "OGUA",
                            "Name": "Ordu-Giresun",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "37.878889, 40.984722",
                            "IataCode": "OGU",
                            "Airports": [
                                {
                                    "Id": "OGU",
                                    "Name": "Ordu/Giresun",
                                    "CityId": "OGUA",
                                    "CountryId": "TR",
                                    "Location": "38.066667, 40.966667"
                                }
                            ]
                        },
                        {
                            "Id": "KUTH",
                            "Name": "Kütahya",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "29.983333, 39.424167",
                            "IataCode": "KZR",
                            "Airports": [
                                {
                                    "Id": "KZR",
                                    "Name": "Kütahya",
                                    "CityId": "KUTH",
                                    "CountryId": "TR",
                                    "Location": "30.13, 39.111389"
                                }
                            ]
                        },
                        {
                            "Id": "EDOA",
                            "Name": "Edremit / Korfez",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.024444, 39.596111",
                            "IataCode": "EDO",
                            "Airports": [
                                {
                                    "Id": "EDO",
                                    "Name": "Edremit / Korfez",
                                    "CityId": "EDOA",
                                    "CountryId": "TR",
                                    "Location": "27.033333, 39.583333"
                                }
                            ]
                        },
                        {
                            "Id": "KFSA",
                            "Name": "Kastamonu",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "33.771111, 41.372222",
                            "IataCode": "KFS",
                            "Airports": [
                                {
                                    "Id": "KFS",
                                    "Name": "Kastamonu",
                                    "CityId": "KFSA",
                                    "CountryId": "TR",
                                    "Location": "33.791111, 41.305556"
                                }
                            ]
                        },
                        {
                            "Id": "SIRN",
                            "Name": "Sirnak",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "42.459444, 37.522778",
                            "IataCode": "NKT",
                            "Airports": [
                                {
                                    "Id": "NKT",
                                    "Name": "Sirnak",
                                    "CityId": "SIRN",
                                    "CountryId": "TR",
                                    "Location": "42.057563, 37.362678"
                                }
                            ]
                        },
                        {
                            "Id": "IGDR",
                            "Name": "Igdir",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "44.046389, 39.918611",
                            "IataCode": "IGD",
                            "Airports": [
                                {
                                    "Id": "IGD",
                                    "Name": "Igdir Airport",
                                    "CityId": "IGDR",
                                    "CountryId": "TR",
                                    "Location": "43.866389, 39.983056"
                                }
                            ]
                        },
                        {
                            "Id": "GZPA",
                            "Name": "Gazipasa",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "32.318611, 36.271389",
                            "IataCode": "GZP",
                            "Airports": [
                                {
                                    "Id": "GZP",
                                    "Name": "Gazipasa Alanya",
                                    "CityId": "GZPA",
                                    "CountryId": "TR",
                                    "Location": "32.3014, 36.2993"
                                }
                            ]
                        },
                        {
                            "Id": "YKOA",
                            "Name": "Hakkari",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "43.740833, 37.574444",
                            "IataCode": "YKO",
                            "Airports": [
                                {
                                    "Id": "YKO",
                                    "Name": "Yuksekova",
                                    "CityId": "YKOA",
                                    "CountryId": "TR",
                                    "Location": "44.265939, 37.555561"
                                }
                            ]
                        },
                        {
                            "Id": "BING",
                            "Name": "Bingöl",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "40.496389, 38.884722",
                            "IataCode": "BGG",
                            "Airports": [
                                {
                                    "Id": "BGG",
                                    "Name": "Bingöl",
                                    "CityId": "BING",
                                    "CountryId": "TR",
                                    "Location": "40.5925, 38.861111"
                                }
                            ]
                        },
                        {
                            "Id": "GKDA",
                            "Name": "Gökçeada",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "25.916667, 40.183333",
                            "IataCode": "GKD",
                            "Airports": [
                                {
                                    "Id": "GKD",
                                    "Name": "Gökçeada",
                                    "CityId": "GKDA",
                                    "CountryId": "TR",
                                    "Location": "25.8, 40.2"
                                }
                            ]
                        },
                        {
                            "Id": "BODS",
                            "Name": "Çömlekçi",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "27.683333, 37.15",
                            "IataCode": "BXN",
                            "Airports": [
                                {
                                    "Id": "BXN",
                                    "Name": "Bodrum Imsik",
                                    "CityId": "BODS",
                                    "CountryId": "TR",
                                    "Location": "27.667222, 37.134722"
                                }
                            ]
                        },
                        {
                            "Id": "UABA",
                            "Name": "Camili",
                            "SingleAirportCity": true,
                            "CountryId": "TR",
                            "Location": "35.433333, 36.983333",
                            "IataCode": "UAB",
                            "Airports": [
                                {
                                    "Id": "UAB",
                                    "Name": "Adana-Incirlik Airbase",
                                    "CityId": "UABA",
                                    "CountryId": "TR",
                                    "Location": "35.418333, 37.000278"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SA",
                    "Name": "Saudi Arabia",
                    "CurrencyId": "SAR",
                    "Cities": [
                        {
                            "Id": "AQIA",
                            "Name": "Qaisumah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "46.1275, 28.309722",
                            "IataCode": "AQI",
                            "Airports": [
                                {
                                    "Id": "AQI",
                                    "Name": "Qaisumah",
                                    "CityId": "AQIA",
                                    "CountryId": "SA",
                                    "Location": "46.130556, 28.328056"
                                }
                            ]
                        },
                        {
                            "Id": "ZULA",
                            "Name": "Zilfi",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "44.803611, 26.296667",
                            "IataCode": "ZUL",
                            "Airports": [
                                {
                                    "Id": "ZUL",
                                    "Name": "Zilfi",
                                    "CityId": "ZULA",
                                    "CountryId": "SA",
                                    "Location": "44.833333, 26.25"
                                }
                            ]
                        },
                        {
                            "Id": "EJHA",
                            "Name": "Wedjh",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "36.466667, 26.233333",
                            "IataCode": "EJH",
                            "Airports": [
                                {
                                    "Id": "EJH",
                                    "Name": "Wedjh",
                                    "CityId": "EJHA",
                                    "CountryId": "SA",
                                    "Location": "36.483333, 26.2"
                                }
                            ]
                        },
                        {
                            "Id": "BHHA",
                            "Name": "Bisha",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "42.6, 20",
                            "IataCode": "BHH",
                            "Airports": [
                                {
                                    "Id": "BHH",
                                    "Name": "Bisha",
                                    "CityId": "BHHA",
                                    "CountryId": "SA",
                                    "Location": "42.625, 19.986111"
                                }
                            ]
                        },
                        {
                            "Id": "DHAA",
                            "Name": "Dhahran",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "50.1325, 26.304167",
                            "IataCode": "DHA",
                            "Airports": [
                                {
                                    "Id": "DHA",
                                    "Name": "Dhahran",
                                    "CityId": "DHAA",
                                    "CountryId": "SA",
                                    "Location": "50.151944, 26.266667"
                                }
                            ]
                        },
                        {
                            "Id": "DMMA",
                            "Name": "Dammam",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "50.114167, 26.425833",
                            "IataCode": "DMM",
                            "Airports": [
                                {
                                    "Id": "DMM",
                                    "Name": "Dammam",
                                    "CityId": "DMMA",
                                    "CountryId": "SA",
                                    "Location": "49.797161, 26.469283"
                                }
                            ]
                        },
                        {
                            "Id": "GIZA",
                            "Name": "Jazan",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "42.551111, 16.889167",
                            "IataCode": "GIZ",
                            "Airports": [
                                {
                                    "Id": "GIZ",
                                    "Name": "Jazan",
                                    "CityId": "GIZA",
                                    "CountryId": "SA",
                                    "Location": "42.585833, 16.901111"
                                }
                            ]
                        },
                        {
                            "Id": "HASA",
                            "Name": "Hail",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "41.696944, 27.516389",
                            "IataCode": "HAS",
                            "Airports": [
                                {
                                    "Id": "HAS",
                                    "Name": "Hail",
                                    "CityId": "HASA",
                                    "CountryId": "SA",
                                    "Location": "41.68629, 27.43792"
                                }
                            ]
                        },
                        {
                            "Id": "JEDA",
                            "Name": "Jeddah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "39.219167, 21.516949",
                            "IataCode": "JED",
                            "Airports": [
                                {
                                    "Id": "JED",
                                    "Name": "Jeddah",
                                    "CityId": "JEDA",
                                    "CountryId": "SA",
                                    "Location": "39.173329, 21.662197"
                                }
                            ]
                        },
                        {
                            "Id": "KMXA",
                            "Name": "Khamis Mushait",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "42.729167, 18.306389",
                            "IataCode": "KMX",
                            "Airports": [
                                {
                                    "Id": "KMX",
                                    "Name": "Khamis Mushait",
                                    "CityId": "KMXA",
                                    "CountryId": "SA",
                                    "Location": "42.80354, 18.29729"
                                }
                            ]
                        },
                        {
                            "Id": "MEDA",
                            "Name": "Madinah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "39.611122, 24.471153",
                            "IataCode": "MED",
                            "Airports": [
                                {
                                    "Id": "MED",
                                    "Name": "Madinah",
                                    "CityId": "MEDA",
                                    "CountryId": "SA",
                                    "Location": "39.721111, 24.545"
                                }
                            ]
                        },
                        {
                            "Id": "RUHA",
                            "Name": "Riyadh",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "46.772776, 24.64084",
                            "IataCode": "RUH",
                            "Airports": [
                                {
                                    "Id": "RUH",
                                    "Name": "Riyadh",
                                    "CityId": "RUHA",
                                    "CountryId": "SA",
                                    "Location": "46.702109, 24.977799"
                                }
                            ]
                        },
                        {
                            "Id": "TIFA",
                            "Name": "Taif",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "40.415833, 21.270278",
                            "IataCode": "TIF",
                            "Airports": [
                                {
                                    "Id": "TIF",
                                    "Name": "Taif",
                                    "CityId": "TIFA",
                                    "CountryId": "SA",
                                    "Location": "40.543333, 21.488333"
                                }
                            ]
                        },
                        {
                            "Id": "TUUA",
                            "Name": "Tabuk",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "36.583334, 28.383333",
                            "IataCode": "TUU",
                            "Airports": [
                                {
                                    "Id": "TUU",
                                    "Name": "Tabuk",
                                    "CityId": "TUUA",
                                    "CountryId": "SA",
                                    "Location": "36.625278, 28.372222"
                                }
                            ]
                        },
                        {
                            "Id": "RAEA",
                            "Name": "Arar",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "41.020555, 30.985001",
                            "IataCode": "RAE",
                            "Airports": [
                                {
                                    "Id": "RAE",
                                    "Name": "Arar",
                                    "CityId": "RAEA",
                                    "CountryId": "SA",
                                    "Location": "41.133333, 30.9"
                                }
                            ]
                        },
                        {
                            "Id": "RAHA",
                            "Name": "Rafha",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "43.501389, 29.638611",
                            "IataCode": "RAH",
                            "Airports": [
                                {
                                    "Id": "RAH",
                                    "Name": "Rafha",
                                    "CityId": "RAHA",
                                    "CountryId": "SA",
                                    "Location": "43.481111, 29.630278"
                                }
                            ]
                        },
                        {
                            "Id": "TUIA",
                            "Name": "Turaif",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "38.653056, 31.6775",
                            "IataCode": "TUI",
                            "Airports": [
                                {
                                    "Id": "TUI",
                                    "Name": "Turaif",
                                    "CityId": "TUIA",
                                    "CountryId": "SA",
                                    "Location": "38.631667, 31.695"
                                }
                            ]
                        },
                        {
                            "Id": "AKHA",
                            "Name": "Al Kharj",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "47.305, 24.148333",
                            "IataCode": "AKH",
                            "Airports": [
                                {
                                    "Id": "AKH",
                                    "Name": "Al Kharj",
                                    "CityId": "AKHA",
                                    "CountryId": "SA",
                                    "Location": "47.580556, 24.063333"
                                }
                            ]
                        },
                        {
                            "Id": "AHBA",
                            "Name": "Abha",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "42.505278, 18.216389",
                            "IataCode": "AHB",
                            "Airports": [
                                {
                                    "Id": "AHB",
                                    "Name": "Abha",
                                    "CityId": "AHBA",
                                    "CountryId": "SA",
                                    "Location": "42.65, 18.233333"
                                }
                            ]
                        },
                        {
                            "Id": "ELQA",
                            "Name": "Gassim",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "43.971667, 26.331667",
                            "IataCode": "ELQ",
                            "Airports": [
                                {
                                    "Id": "ELQ",
                                    "Name": "Gassim",
                                    "CityId": "ELQA",
                                    "CountryId": "SA",
                                    "Location": "43.770833, 26.303333"
                                }
                            ]
                        },
                        {
                            "Id": "HBTA",
                            "Name": "Hafr Albatin",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "45.963611, 28.434167",
                            "IataCode": "HBT",
                            "Airports": [
                                {
                                    "Id": "HBT",
                                    "Name": "Hafr Albatin",
                                    "CityId": "HBTA",
                                    "CountryId": "SA",
                                    "Location": "45.526389, 27.894444"
                                }
                            ]
                        },
                        {
                            "Id": "EAMA",
                            "Name": "Nejran",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "44.184167, 17.505556",
                            "IataCode": "EAM",
                            "Airports": [
                                {
                                    "Id": "EAM",
                                    "Name": "Nejran",
                                    "CityId": "EAMA",
                                    "CountryId": "SA",
                                    "Location": "44.429722, 17.611667"
                                }
                            ]
                        },
                        {
                            "Id": "YNBA",
                            "Name": "Yanbu",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "38.048611, 24.085278",
                            "IataCode": "YNB",
                            "Airports": [
                                {
                                    "Id": "YNB",
                                    "Name": "Yanbu",
                                    "CityId": "YNBA",
                                    "CountryId": "SA",
                                    "Location": "38.066667, 24.116667"
                                }
                            ]
                        },
                        {
                            "Id": "UZHA",
                            "Name": "Unayzah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "43.979722, 26.095556",
                            "IataCode": "UZH",
                            "Airports": [
                                {
                                    "Id": "UZH",
                                    "Name": "Unayzah",
                                    "CityId": "UZHA",
                                    "CountryId": "SA",
                                    "Location": "43.933333, 26.1"
                                }
                            ]
                        },
                        {
                            "Id": "ALUL",
                            "Name": "Al `Ula",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "37.916667, 26.633333",
                            "IataCode": "ULH",
                            "Airports": [
                                {
                                    "Id": "ULH",
                                    "Name": "Al `Ula",
                                    "CityId": "ALUL",
                                    "CountryId": "SA",
                                    "Location": "37.916667, 26.63333"
                                }
                            ]
                        },
                        {
                            "Id": "MJHA",
                            "Name": "Majma",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "45.345556, 25.903889",
                            "IataCode": "MJH",
                            "Airports": [
                                {
                                    "Id": "MJH",
                                    "Name": "Majma",
                                    "CityId": "MJHA",
                                    "CountryId": "SA",
                                    "Location": "45.366667, 25.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ABTA",
                            "Name": "Al-Baha",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "41.466667, 20.016667",
                            "IataCode": "ABT",
                            "Airports": [
                                {
                                    "Id": "ABT",
                                    "Name": "Al-Baha",
                                    "CityId": "ABTA",
                                    "CountryId": "SA",
                                    "Location": "41.633333, 20.285556"
                                }
                            ]
                        },
                        {
                            "Id": "DWDA",
                            "Name": "Dawadmi",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "44.383333, 24.494167",
                            "IataCode": "DWD",
                            "Airports": [
                                {
                                    "Id": "DWD",
                                    "Name": "Dawadmi",
                                    "CityId": "DWDA",
                                    "CountryId": "SA",
                                    "Location": "44.121111, 24.449722"
                                }
                            ]
                        },
                        {
                            "Id": "KMCA",
                            "Name": "Masjid Ibn Rashīd",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "45.4, 27.916667",
                            "IataCode": "KMC",
                            "Airports": [
                                {
                                    "Id": "KMC",
                                    "Name": "King Khalid Mil. City",
                                    "CityId": "KMCA",
                                    "CountryId": "SA",
                                    "Location": "45.518611, 27.900278"
                                }
                            ]
                        },
                        {
                            "Id": "HOFA",
                            "Name": "Banī `Awaḑ",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "49.716667, 25.433333",
                            "IataCode": "HOF",
                            "Airports": [
                                {
                                    "Id": "HOF",
                                    "Name": "Alahsa",
                                    "CityId": "HOFA",
                                    "CountryId": "SA",
                                    "Location": "49.48519, 25.28531"
                                }
                            ]
                        },
                        {
                            "Id": "WAEA",
                            "Name": "Ash Sharāfá",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "44.933333, 20.45",
                            "IataCode": "WAE",
                            "Airports": [
                                {
                                    "Id": "WAE",
                                    "Name": "Wadi Ad Dawasir",
                                    "CityId": "WAEA",
                                    "CountryId": "SA",
                                    "Location": "45.166667, 20.5"
                                }
                            ]
                        },
                        {
                            "Id": "URYA",
                            "Name": "Al Qurayyāt",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "37.333333, 31.333333",
                            "IataCode": "URY",
                            "Airports": [
                                {
                                    "Id": "URY",
                                    "Name": "Gurayat",
                                    "CityId": "URYA",
                                    "CountryId": "SA",
                                    "Location": "37.333333, 31.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SHWA",
                            "Name": "Al Wuday`ah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "47.1, 17.416667",
                            "IataCode": "SHW",
                            "Airports": [
                                {
                                    "Id": "SHW",
                                    "Name": "Sharurah",
                                    "CityId": "SHWA",
                                    "CountryId": "SA",
                                    "Location": "47.1, 17.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SLFA",
                            "Name": "Āl Muḩammad",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "45.6, 20.483333",
                            "IataCode": "SLF",
                            "Airports": [
                                {
                                    "Id": "SLF",
                                    "Name": "Sulayel",
                                    "CityId": "SLFA",
                                    "CountryId": "SA",
                                    "Location": "45.616667, 20.466667"
                                }
                            ]
                        },
                        {
                            "Id": "AJFA",
                            "Name": "Al Qunayţirah",
                            "SingleAirportCity": true,
                            "CountryId": "SA",
                            "Location": "40.093056, 29.977778",
                            "IataCode": "AJF",
                            "Airports": [
                                {
                                    "Id": "AJF",
                                    "Name": "Jouf",
                                    "CityId": "AJFA",
                                    "CountryId": "SA",
                                    "Location": "40.10001, 29.78513"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BH",
                    "Name": "Bahrain",
                    "CurrencyId": "BHD",
                    "Cities": [
                        {
                            "Id": "GBQA",
                            "Name": "Muharraq",
                            "SingleAirportCity": true,
                            "CountryId": "BH",
                            "Location": "50.611944, 26.257222",
                            "IataCode": "GBQ",
                            "Airports": [
                                {
                                    "Id": "GBQ",
                                    "Name": "Muharraq",
                                    "CityId": "GBQA",
                                    "CountryId": "BH",
                                    "Location": "50.988889, 26.433056"
                                }
                            ]
                        },
                        {
                            "Id": "BAHA",
                            "Name": "Manama",
                            "SingleAirportCity": true,
                            "CountryId": "BH",
                            "Location": "50.6375, 26.270833",
                            "IataCode": "BAH",
                            "Airports": [
                                {
                                    "Id": "BAH",
                                    "Name": "Bahrain",
                                    "CityId": "BAHA",
                                    "CountryId": "BH",
                                    "Location": "50.6375, 26.270833"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "JO",
                    "Name": "Jordan",
                    "CurrencyId": "JOD",
                    "Cities": [
                        {
                            "Id": "AQJA",
                            "Name": "Aqaba",
                            "SingleAirportCity": true,
                            "CountryId": "JO",
                            "Location": "35.012318, 29.529368",
                            "IataCode": "AQJ",
                            "Airports": [
                                {
                                    "Id": "AQJ",
                                    "Name": "Aqaba",
                                    "CityId": "AQJA",
                                    "CountryId": "JO",
                                    "Location": "35.018067, 29.611619"
                                }
                            ]
                        },
                        {
                            "Id": "AMMA",
                            "Name": "Amman",
                            "SingleAirportCity": false,
                            "CountryId": "JO",
                            "Location": "35.933333, 31.950002",
                            "IataCode": "AMM",
                            "Airports": [
                                {
                                    "Id": "ADJ",
                                    "Name": "Amman Civil - Marka",
                                    "CityId": "AMMA",
                                    "CountryId": "JO",
                                    "Location": "35.983333, 31.95"
                                },
                                {
                                    "Id": "AMM",
                                    "Name": "Amman Queen Alia",
                                    "CityId": "AMMA",
                                    "CountryId": "JO",
                                    "Location": "35.98642, 31.722274"
                                }
                            ]
                        },
                        {
                            "Id": "OMFA",
                            "Name": "Mafraq",
                            "SingleAirportCity": true,
                            "CountryId": "JO",
                            "Location": "36.2, 32.35",
                            "IataCode": "OMF",
                            "Airports": [
                                {
                                    "Id": "OMF",
                                    "Name": "Mafraq",
                                    "CityId": "OMFA",
                                    "CountryId": "JO",
                                    "Location": "36.25, 32.35"
                                }
                            ]
                        },
                        {
                            "Id": "MPQA",
                            "Name": "Maan",
                            "SingleAirportCity": true,
                            "CountryId": "JO",
                            "Location": "35.732689, 30.197292",
                            "IataCode": "MPQ",
                            "Airports": [
                                {
                                    "Id": "MPQ",
                                    "Name": "Maan",
                                    "CityId": "MPQA",
                                    "CountryId": "JO",
                                    "Location": "35.766667, 30.166667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "QA",
                    "Name": "Qatar",
                    "CurrencyId": "QAR",
                    "Cities": [
                        {
                            "Id": "DOHA",
                            "Name": "Doha",
                            "SingleAirportCity": false,
                            "CountryId": "QA",
                            "Location": "51.533333, 25.286667",
                            "IataCode": "DOH",
                            "Airports": [
                                {
                                    "Id": "XOZ",
                                    "Name": "Doha Free Zone",
                                    "CityId": "DOHA",
                                    "CountryId": "QA",
                                    "Location": "50.9167, 24.8333"
                                },
                                {
                                    "Id": "XJD",
                                    "Name": "Air Base",
                                    "CityId": "DOHA",
                                    "CountryId": "QA",
                                    "Location": "51.314989, 25.117317"
                                },
                                {
                                    "Id": "DOH",
                                    "Name": "Hamad International",
                                    "CityId": "DOHA",
                                    "CountryId": "QA",
                                    "Location": "51.613799, 25.260924"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KW",
                    "Name": "Kuwait",
                    "CurrencyId": "KWD",
                    "Cities": [
                        {
                            "Id": "KWIA",
                            "Name": "Kuwait",
                            "SingleAirportCity": true,
                            "CountryId": "KW",
                            "Location": "47.978333, 29.369722",
                            "IataCode": "KWI",
                            "Airports": [
                                {
                                    "Id": "KWI",
                                    "Name": "Kuwait",
                                    "CityId": "KWIA",
                                    "CountryId": "KW",
                                    "Location": "47.971127, 29.240948"
                                }
                            ]
                        },
                        {
                            "Id": "AEJA",
                            "Name": "Johnson Village",
                            "SingleAirportCity": true,
                            "CountryId": "KW",
                            "Location": "-106.10668, 38.810829",
                            "IataCode": "AEJ",
                            "Airports": [
                                {
                                    "Id": "AEJ",
                                    "Name": "Ahmed Al Jaber",
                                    "CityId": "AEJA",
                                    "CountryId": "KW",
                                    "Location": "-106.12599, 38.823848"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IL",
                    "Name": "Israel",
                    "CurrencyId": "ILS",
                    "Cities": [
                        {
                            "Id": "YOTA",
                            "Name": "Yotvata",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.05, 29.883333",
                            "IataCode": "YOT",
                            "Airports": [
                                {
                                    "Id": "YOT",
                                    "Name": "Yotvata",
                                    "CityId": "YOTA",
                                    "CountryId": "IL",
                                    "Location": "35.066667, 29.9"
                                }
                            ]
                        },
                        {
                            "Id": "BEER",
                            "Name": "Beersheba",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "34.783333, 31.233333",
                            "IataCode": "BEV",
                            "Airports": [
                                {
                                    "Id": "BEV",
                                    "Name": "Beersheba",
                                    "CityId": "BEER",
                                    "CountryId": "IL",
                                    "Location": "34.8, 31.25"
                                }
                            ]
                        },
                        {
                            "Id": "ELAT",
                            "Name": "Eilat",
                            "SingleAirportCity": false,
                            "CountryId": "IL",
                            "Location": "34.948633, 29.554248",
                            "IataCode": "ETH",
                            "Airports": [
                                {
                                    "Id": "ETM",
                                    "Name": "Ramon International",
                                    "CityId": "ELAT",
                                    "CountryId": "IL",
                                    "Location": "35.006307, 29.723284"
                                },
                                {
                                    "Id": "ETH",
                                    "Name": "Eilat (J. Hozman)",
                                    "CityId": "ELAT",
                                    "CountryId": "IL",
                                    "Location": "34.961389, 29.559722"
                                }
                            ]
                        },
                        {
                            "Id": "HAIF",
                            "Name": "Haifa",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "34.989166, 32.815557",
                            "IataCode": "HFA",
                            "Airports": [
                                {
                                    "Id": "HFA",
                                    "Name": "Haifa",
                                    "CityId": "HAIF",
                                    "CountryId": "IL",
                                    "Location": "35.041667, 32.811111"
                                }
                            ]
                        },
                        {
                            "Id": "TELA",
                            "Name": "Tel Aviv",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "34.764722, 32.067778",
                            "IataCode": "TLV",
                            "Airports": [
                                {
                                    "Id": "TLV",
                                    "Name": "Ben Gurion Intl",
                                    "CityId": "TELA",
                                    "CountryId": "IL",
                                    "Location": "34.885315, 32.005477"
                                }
                            ]
                        },
                        {
                            "Id": "JERU",
                            "Name": "Jerusalem",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.215758, 31.774225",
                            "IataCode": "JRS",
                            "Airports": [
                                {
                                    "Id": "JRS",
                                    "Name": "Jerusalem",
                                    "CityId": "JERU",
                                    "CountryId": "IL",
                                    "Location": "35.214444, 31.8675"
                                }
                            ]
                        },
                        {
                            "Id": "ROSH",
                            "Name": "Rosh-Pina",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.533333, 32.966667",
                            "IataCode": "RPN",
                            "Airports": [
                                {
                                    "Id": "RPN",
                                    "Name": "Rosh-Pina",
                                    "CityId": "ROSH",
                                    "CountryId": "IL",
                                    "Location": "35.571111, 32.981667"
                                }
                            ]
                        },
                        {
                            "Id": "MITS",
                            "Name": "Mitspeh Ramon",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "34.801111, 30.609444",
                            "IataCode": "MIP",
                            "Airports": [
                                {
                                    "Id": "MIP",
                                    "Name": "Mitspeh Ramon",
                                    "CityId": "MITS",
                                    "CountryId": "IL",
                                    "Location": "34.8, 30.6"
                                }
                            ]
                        },
                        {
                            "Id": "KSWA",
                            "Name": "Kiryat Shmona",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.568611, 33.208056",
                            "IataCode": "KSW",
                            "Airports": [
                                {
                                    "Id": "KSW",
                                    "Name": "Kiryat Shmona",
                                    "CityId": "KSWA",
                                    "CountryId": "IL",
                                    "Location": "35.566667, 33.216667"
                                }
                            ]
                        },
                        {
                            "Id": "EINY",
                            "Name": "Ein Yahav",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.238611, 30.653333",
                            "IataCode": "EIY",
                            "Airports": [
                                {
                                    "Id": "EIY",
                                    "Name": "Ein Yahav",
                                    "CityId": "EINY",
                                    "CountryId": "IL",
                                    "Location": "35.183333, 30.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MASA",
                            "Name": "`En Gedi",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.383333, 31.45",
                            "IataCode": "MTZ",
                            "Airports": [
                                {
                                    "Id": "MTZ",
                                    "Name": "Masada Bar Yehuda",
                                    "CityId": "MASA",
                                    "CountryId": "IL",
                                    "Location": "35.388401, 31.327301"
                                }
                            ]
                        },
                        {
                            "Id": "GUSH",
                            "Name": "Newe Zohar",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.366667, 31.152778",
                            "IataCode": "GHK",
                            "Airports": [
                                {
                                    "Id": "GHK",
                                    "Name": "Gush Katif",
                                    "CityId": "GUSH",
                                    "CountryId": "IL",
                                    "Location": "35.3, 31.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SEDO",
                            "Name": "`En Boqeq",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "35.363889, 31.201389",
                            "IataCode": "SED",
                            "Airports": [
                                {
                                    "Id": "SED",
                                    "Name": "Sedom Hashnayim",
                                    "CityId": "SEDO",
                                    "CountryId": "IL",
                                    "Location": "35.383333, 31.15"
                                }
                            ]
                        },
                        {
                            "Id": "OVDA",
                            "Name": "Giv'at Raẖel",
                            "SingleAirportCity": true,
                            "CountryId": "IL",
                            "Location": "34.439167, 30.89",
                            "IataCode": "VDA",
                            "Airports": [
                                {
                                    "Id": "VDA",
                                    "Name": "Ovda (Eilat)",
                                    "CityId": "OVDA",
                                    "CountryId": "IL",
                                    "Location": "34.947222, 29.933333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "OM",
                    "Name": "Oman",
                    "CurrencyId": "OMR",
                    "Cities": [
                        {
                            "Id": "MCTA",
                            "Name": "Muscat",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "58.593333, 23.613333",
                            "IataCode": "MCT",
                            "Airports": [
                                {
                                    "Id": "MCT",
                                    "Name": "Muscat",
                                    "CityId": "MCTA",
                                    "CountryId": "OM",
                                    "Location": "58.279167, 23.591667"
                                }
                            ]
                        },
                        {
                            "Id": "SLLA",
                            "Name": "Salalah",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "54.082778, 17.0175",
                            "IataCode": "SLL",
                            "Airports": [
                                {
                                    "Id": "SLL",
                                    "Name": "Salalah",
                                    "CityId": "SLLA",
                                    "CountryId": "OM",
                                    "Location": "54.101667, 17.036389"
                                }
                            ]
                        },
                        {
                            "Id": "SUHA",
                            "Name": "Sur",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "59.528889, 22.566667",
                            "IataCode": "SUH",
                            "Airports": [
                                {
                                    "Id": "SUH",
                                    "Name": "Sur",
                                    "CityId": "SUHA",
                                    "CountryId": "OM",
                                    "Location": "59.483333, 22.533333"
                                }
                            ]
                        },
                        {
                            "Id": "OMMA",
                            "Name": "Marmul",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "55.266667, 18.133333",
                            "IataCode": "OMM",
                            "Airports": [
                                {
                                    "Id": "OMM",
                                    "Name": "Marmul",
                                    "CityId": "OMMA",
                                    "CountryId": "OM",
                                    "Location": "55.266667, 18.133333"
                                }
                            ]
                        },
                        {
                            "Id": "KHSA",
                            "Name": "Khasab",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "56.243611, 26.191667",
                            "IataCode": "KHS",
                            "Airports": [
                                {
                                    "Id": "KHS",
                                    "Name": "Khasab",
                                    "CityId": "KHSA",
                                    "CountryId": "OM",
                                    "Location": "56.233333, 26.183333"
                                }
                            ]
                        },
                        {
                            "Id": "MSHA",
                            "Name": "Masirah",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "58.733333, 20.416667",
                            "IataCode": "MSH",
                            "Airports": [
                                {
                                    "Id": "MSH",
                                    "Name": "Masirah",
                                    "CityId": "MSHA",
                                    "CountryId": "OM",
                                    "Location": "58.891667, 20.680556"
                                }
                            ]
                        },
                        {
                            "Id": "OHSA",
                            "Name": "Sohar",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "56.743889, 24.368889",
                            "IataCode": "OHS",
                            "Airports": [
                                {
                                    "Id": "OHS",
                                    "Name": "Sohar",
                                    "CityId": "OHSA",
                                    "CountryId": "OM",
                                    "Location": "56.610366, 24.394909"
                                }
                            ]
                        },
                        {
                            "Id": "DQMA",
                            "Name": "Duqm",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "57.7, 19.65",
                            "IataCode": "DQM",
                            "Airports": [
                                {
                                    "Id": "DQM",
                                    "Name": "Duqm International",
                                    "CityId": "DQMA",
                                    "CountryId": "OM",
                                    "Location": "57.642778, 19.5"
                                }
                            ]
                        },
                        {
                            "Id": "RMBA",
                            "Name": "Buraimi",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "55.75, 24.25",
                            "IataCode": "RMB",
                            "Airports": [
                                {
                                    "Id": "RMB",
                                    "Name": "Buraimi",
                                    "CityId": "RMBA",
                                    "CountryId": "OM",
                                    "Location": "55.783333, 24.25"
                                }
                            ]
                        },
                        {
                            "Id": "TTHA",
                            "Name": "Thamarīt",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "54.033333, 17.67",
                            "IataCode": "TTH",
                            "Airports": [
                                {
                                    "Id": "TTH",
                                    "Name": "Thumrait",
                                    "CityId": "TTHA",
                                    "CountryId": "OM",
                                    "Location": "54, 17.633333"
                                }
                            ]
                        },
                        {
                            "Id": "WWGA",
                            "Name": "Rusayl",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "58.188611, 23.539167",
                            "IataCode": "WWG",
                            "Airports": [
                                {
                                    "Id": "WWG",
                                    "Name": "Al Koude",
                                    "CityId": "WWGA",
                                    "CountryId": "OM",
                                    "Location": "58.188611, 23.539167"
                                }
                            ]
                        },
                        {
                            "Id": "UKHA",
                            "Name": "Haymā'",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "56.316667, 19.933333",
                            "IataCode": "UKH",
                            "Airports": [
                                {
                                    "Id": "UKH",
                                    "Name": "Mukhaizna",
                                    "CityId": "UKHA",
                                    "CountryId": "OM",
                                    "Location": "56.399167, 19.388333"
                                }
                            ]
                        },
                        {
                            "Id": "FAUO",
                            "Name": "Falaj",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "56.833333, 22.7",
                            "IataCode": "FAU",
                            "Airports": [
                                {
                                    "Id": "FAU",
                                    "Name": "Fahud",
                                    "CityId": "FAUO",
                                    "CountryId": "OM",
                                    "Location": "56.483333, 22.355556"
                                }
                            ]
                        },
                        {
                            "Id": "RNMO",
                            "Name": "Al Ghābah",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "57.333333, 21.5",
                            "IataCode": "RNM",
                            "Airports": [
                                {
                                    "Id": "RNM",
                                    "Name": "Qarn Alam",
                                    "CityId": "RNMO",
                                    "CountryId": "OM",
                                    "Location": "57.055556, 21.377778"
                                }
                            ]
                        },
                        {
                            "Id": "BYBA",
                            "Name": "Wāsiţ",
                            "SingleAirportCity": true,
                            "CountryId": "OM",
                            "Location": "56.256667, 25.598056",
                            "IataCode": "BYB",
                            "Airports": [
                                {
                                    "Id": "BYB",
                                    "Name": "Dibaa",
                                    "CityId": "BYBA",
                                    "CountryId": "OM",
                                    "Location": "56.25, 25.583333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IR",
                    "Name": "Iran",
                    "CurrencyId": "IRR",
                    "Cities": [
                        {
                            "Id": "XBJA",
                            "Name": "Birjand",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "59.214444, 32.873889",
                            "IataCode": "XBJ",
                            "Airports": [
                                {
                                    "Id": "XBJ",
                                    "Name": "Birjand",
                                    "CityId": "XBJA",
                                    "CountryId": "IR",
                                    "Location": "59.281368, 32.894723"
                                }
                            ]
                        },
                        {
                            "Id": "ABDA",
                            "Name": "Abadan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.295834, 30.3525",
                            "IataCode": "ABD",
                            "Airports": [
                                {
                                    "Id": "ABD",
                                    "Name": "Abadan",
                                    "CityId": "ABDA",
                                    "CountryId": "IR",
                                    "Location": "48.233333, 30.366667"
                                }
                            ]
                        },
                        {
                            "Id": "ACZA",
                            "Name": "Zabol",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "61.497778, 31.029722",
                            "IataCode": "ACZ",
                            "Airports": [
                                {
                                    "Id": "ACZ",
                                    "Name": "Zabol",
                                    "CityId": "ACZA",
                                    "CountryId": "IR",
                                    "Location": "61.535, 31.085833"
                                }
                            ]
                        },
                        {
                            "Id": "ADUA",
                            "Name": "Ardabil",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.301389, 38.249444",
                            "IataCode": "ADU",
                            "Airports": [
                                {
                                    "Id": "ADU",
                                    "Name": "Ardabil",
                                    "CityId": "ADUA",
                                    "CountryId": "IR",
                                    "Location": "48.3, 38.25"
                                }
                            ]
                        },
                        {
                            "Id": "ZAHA",
                            "Name": "Zahedan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "60.872778, 29.497779",
                            "IataCode": "ZAH",
                            "Airports": [
                                {
                                    "Id": "ZAH",
                                    "Name": "Zahedan",
                                    "CityId": "ZAHA",
                                    "CountryId": "IR",
                                    "Location": "60.906944, 29.461944"
                                }
                            ]
                        },
                        {
                            "Id": "CKTA",
                            "Name": "Sarakhs",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "61.161111, 36.534722",
                            "IataCode": "CKT",
                            "Airports": [
                                {
                                    "Id": "CKT",
                                    "Name": "Sarakhs",
                                    "CityId": "CKTA",
                                    "CountryId": "IR",
                                    "Location": "61.07, 36.488056"
                                }
                            ]
                        },
                        {
                            "Id": "OMHA",
                            "Name": "Urmieh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "45.076046, 37.552743",
                            "IataCode": "OMH",
                            "Airports": [
                                {
                                    "Id": "OMH",
                                    "Name": "Urmieh",
                                    "CityId": "OMHA",
                                    "CountryId": "IR",
                                    "Location": "45.067978, 37.671321"
                                }
                            ]
                        },
                        {
                            "Id": "BNDA",
                            "Name": "Bandar Abbas",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "56.2808, 27.1865",
                            "IataCode": "BND",
                            "Airports": [
                                {
                                    "Id": "BND",
                                    "Name": "Bandar Abbas",
                                    "CityId": "BNDA",
                                    "CountryId": "IR",
                                    "Location": "56.366667, 27.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BUZA",
                            "Name": "Bushehr",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.8385, 28.9684",
                            "IataCode": "BUZ",
                            "Airports": [
                                {
                                    "Id": "BUZ",
                                    "Name": "Bushehr",
                                    "CityId": "BUZA",
                                    "CountryId": "IR",
                                    "Location": "50.8325, 28.949167"
                                }
                            ]
                        },
                        {
                            "Id": "BXRA",
                            "Name": "Bam",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "58.361944, 29.107778",
                            "IataCode": "BXR",
                            "Airports": [
                                {
                                    "Id": "BXR",
                                    "Name": "Bam",
                                    "CityId": "BXRA",
                                    "CountryId": "IR",
                                    "Location": "58.451557, 29.086927"
                                }
                            ]
                        },
                        {
                            "Id": "DEFA",
                            "Name": "Dezful",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.402, 32.3825",
                            "IataCode": "DEF",
                            "Airports": [
                                {
                                    "Id": "DEF",
                                    "Name": "Dezful",
                                    "CityId": "DEFA",
                                    "CountryId": "IR",
                                    "Location": "48.383333, 32.433333"
                                }
                            ]
                        },
                        {
                            "Id": "HDMA",
                            "Name": "Hamadan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.515833, 34.796111",
                            "IataCode": "HDM",
                            "Airports": [
                                {
                                    "Id": "HDM",
                                    "Name": "Hamadan",
                                    "CityId": "HDMA",
                                    "CountryId": "IR",
                                    "Location": "48.548056, 34.864722"
                                }
                            ]
                        },
                        {
                            "Id": "IFNA",
                            "Name": "Isfahan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "51.671389, 32.659725",
                            "IataCode": "IFN",
                            "Airports": [
                                {
                                    "Id": "IFN",
                                    "Name": "Isfahan",
                                    "CityId": "IFNA",
                                    "CountryId": "IR",
                                    "Location": "51.85, 32.75"
                                }
                            ]
                        },
                        {
                            "Id": "THRA",
                            "Name": "Tehran",
                            "SingleAirportCity": false,
                            "CountryId": "IR",
                            "Location": "51.424443, 35.671972",
                            "IataCode": "THR",
                            "Airports": [
                                {
                                    "Id": "THR",
                                    "Name": "Mehrabad International Airport",
                                    "CityId": "THRA",
                                    "CountryId": "IR",
                                    "Location": "51.315833, 35.689722"
                                },
                                {
                                    "Id": "IKA",
                                    "Name": "Imam Khomeini International",
                                    "CityId": "THRA",
                                    "CountryId": "IR",
                                    "Location": "51.1508, 35.4097"
                                }
                            ]
                        },
                        {
                            "Id": "KNRA",
                            "Name": "Kangan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "52.0628, 27.834",
                            "IataCode": "KNR",
                            "Airports": [
                                {
                                    "Id": "KNR",
                                    "Name": "Kangan",
                                    "CityId": "KNRA",
                                    "CountryId": "IR",
                                    "Location": "52.350833, 27.818056"
                                }
                            ]
                        },
                        {
                            "Id": "KERA",
                            "Name": "Kerman",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "57.084167, 30.29389",
                            "IataCode": "KER",
                            "Airports": [
                                {
                                    "Id": "KER",
                                    "Name": "Kerman",
                                    "CityId": "KERA",
                                    "CountryId": "IR",
                                    "Location": "56.9575, 30.263333"
                                }
                            ]
                        },
                        {
                            "Id": "KSHA",
                            "Name": "Kermanshah",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "47.065, 34.314167",
                            "IataCode": "KSH",
                            "Airports": [
                                {
                                    "Id": "KSH",
                                    "Name": "Kermanshah",
                                    "CityId": "KSHA",
                                    "CountryId": "IR",
                                    "Location": "47.15, 34.35"
                                }
                            ]
                        },
                        {
                            "Id": "KHAA",
                            "Name": "Khaneh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "45.1413, 36.701",
                            "IataCode": "KHA",
                            "Airports": [
                                {
                                    "Id": "KHA",
                                    "Name": "Khaneh",
                                    "CityId": "KHAA",
                                    "CountryId": "IR",
                                    "Location": "45.15, 36.733333"
                                }
                            ]
                        },
                        {
                            "Id": "KHDA",
                            "Name": "Khorramabad",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.355834, 33.487778",
                            "IataCode": "KHD",
                            "Airports": [
                                {
                                    "Id": "KHD",
                                    "Name": "Khorramabad",
                                    "CityId": "KHDA",
                                    "CountryId": "IR",
                                    "Location": "48.280833, 33.438611"
                                }
                            ]
                        },
                        {
                            "Id": "LVPA",
                            "Name": "Lavan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.37, 26.809722",
                            "IataCode": "LVP",
                            "Airports": [
                                {
                                    "Id": "LVP",
                                    "Name": "Lavan",
                                    "CityId": "LVPA",
                                    "CountryId": "IR",
                                    "Location": "53.356111, 26.810278"
                                }
                            ]
                        },
                        {
                            "Id": "SRYA",
                            "Name": "Sary",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.05861, 36.567778",
                            "IataCode": "SRY",
                            "Airports": [
                                {
                                    "Id": "SRY",
                                    "Name": "Sary",
                                    "CityId": "SRYA",
                                    "CountryId": "IR",
                                    "Location": "53.183333, 36.616667"
                                }
                            ]
                        },
                        {
                            "Id": "SYZA",
                            "Name": "Shiraz",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "52.538333, 29.615002",
                            "IataCode": "SYZ",
                            "Airports": [
                                {
                                    "Id": "SYZ",
                                    "Name": "Shiraz",
                                    "CityId": "SYZA",
                                    "CountryId": "IR",
                                    "Location": "52.590833, 29.540278"
                                }
                            ]
                        },
                        {
                            "Id": "TBZA",
                            "Name": "Tabriz",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "46.291944, 38.080003",
                            "IataCode": "TBZ",
                            "Airports": [
                                {
                                    "Id": "TBZ",
                                    "Name": "Tabriz",
                                    "CityId": "TBZA",
                                    "CountryId": "IR",
                                    "Location": "46.243333, 38.131667"
                                }
                            ]
                        },
                        {
                            "Id": "AWZA",
                            "Name": "Ahvāz",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.7505, 31.3553",
                            "IataCode": "AWZ",
                            "Airports": [
                                {
                                    "Id": "AWZ",
                                    "Name": "Ahwaz",
                                    "CityId": "AWZA",
                                    "CountryId": "IR",
                                    "Location": "48.759722, 31.337778"
                                }
                            ]
                        },
                        {
                            "Id": "AZDA",
                            "Name": "Yazd",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.3675, 31.897223",
                            "IataCode": "AZD",
                            "Airports": [
                                {
                                    "Id": "AZD",
                                    "Name": "Yazd",
                                    "CityId": "AZDA",
                                    "CountryId": "IR",
                                    "Location": "54.281667, 31.897778"
                                }
                            ]
                        },
                        {
                            "Id": "MHDA",
                            "Name": "Mashhad",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "59.611943, 36.295838",
                            "IataCode": "MHD",
                            "Airports": [
                                {
                                    "Id": "MHD",
                                    "Name": "Mashhad",
                                    "CityId": "MHDA",
                                    "CountryId": "IR",
                                    "Location": "59.641667, 36.236667"
                                }
                            ]
                        },
                        {
                            "Id": "JYRA",
                            "Name": "Jiroft",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "57.7406, 28.678",
                            "IataCode": "JYR",
                            "Airports": [
                                {
                                    "Id": "JYR",
                                    "Name": "Jiroft",
                                    "CityId": "JYRA",
                                    "CountryId": "IR",
                                    "Location": "57.666667, 28.716667"
                                }
                            ]
                        },
                        {
                            "Id": "JARA",
                            "Name": "Jahrom",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.5605, 28.5",
                            "IataCode": "JAR",
                            "Airports": [
                                {
                                    "Id": "JAR",
                                    "Name": "Jahrom",
                                    "CityId": "JARA",
                                    "CountryId": "IR",
                                    "Location": "53.55, 28.516667"
                                }
                            ]
                        },
                        {
                            "Id": "JWNA",
                            "Name": "Zanjan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.4787, 36.6736",
                            "IataCode": "JWN",
                            "Airports": [
                                {
                                    "Id": "JWN",
                                    "Name": "Zanjan",
                                    "CityId": "JWNA",
                                    "CountryId": "IR",
                                    "Location": "48.359167, 36.774722"
                                }
                            ]
                        },
                        {
                            "Id": "KHYA",
                            "Name": "Khoy",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "44.947778, 38.5525",
                            "IataCode": "KHY",
                            "Airports": [
                                {
                                    "Id": "KHY",
                                    "Name": "Khoy",
                                    "CityId": "KHYA",
                                    "CountryId": "IR",
                                    "Location": "44.973611, 38.4275"
                                }
                            ]
                        },
                        {
                            "Id": "KLMA",
                            "Name": "Kalaleh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "55.499444, 37.370556",
                            "IataCode": "KLM",
                            "Airports": [
                                {
                                    "Id": "KLM",
                                    "Name": "Kalaleh",
                                    "CityId": "KLMA",
                                    "CountryId": "IR",
                                    "Location": "55.45, 37.366667"
                                }
                            ]
                        },
                        {
                            "Id": "LFMA",
                            "Name": "Lamerd",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.179, 27.334",
                            "IataCode": "LFM",
                            "Airports": [
                                {
                                    "Id": "LFM",
                                    "Name": "Lamerd",
                                    "CityId": "LFMA",
                                    "CountryId": "IR",
                                    "Location": "53.203056, 27.3675"
                                }
                            ]
                        },
                        {
                            "Id": "NSHA",
                            "Name": "Now Shahr",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "51.495833, 36.645278",
                            "IataCode": "NSH",
                            "Airports": [
                                {
                                    "Id": "NSH",
                                    "Name": "Now Shahr",
                                    "CityId": "NSHA",
                                    "CountryId": "IR",
                                    "Location": "51.5, 36.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RASB",
                            "Name": "Rasht",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "49.585834, 37.279446",
                            "IataCode": "RAS",
                            "Airports": [
                                {
                                    "Id": "RAS",
                                    "Name": "Rasht",
                                    "CityId": "RASB",
                                    "CountryId": "IR",
                                    "Location": "49.600001, 37.266667"
                                }
                            ]
                        },
                        {
                            "Id": "RJNA",
                            "Name": "Rafsanjan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "55.993889, 30.406668",
                            "IataCode": "RJN",
                            "Airports": [
                                {
                                    "Id": "RJN",
                                    "Name": "Rafsanjan",
                                    "CityId": "RJNA",
                                    "CountryId": "IR",
                                    "Location": "56.056667, 30.296944"
                                }
                            ]
                        },
                        {
                            "Id": "RZRA",
                            "Name": "Ramsar",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.6825, 36.900278",
                            "IataCode": "RZR",
                            "Airports": [
                                {
                                    "Id": "RZR",
                                    "Name": "Ramsar",
                                    "CityId": "RZRA",
                                    "CountryId": "IR",
                                    "Location": "50.680556, 36.910833"
                                }
                            ]
                        },
                        {
                            "Id": "SDGA",
                            "Name": "Sanandaj",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "46.998889, 35.317222",
                            "IataCode": "SDG",
                            "Airports": [
                                {
                                    "Id": "SDG",
                                    "Name": "Sanandaj",
                                    "CityId": "SDGA",
                                    "CountryId": "IR",
                                    "Location": "46.962778, 35.350278"
                                }
                            ]
                        },
                        {
                            "Id": "SYJA",
                            "Name": "Sirjan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "55.671667, 29.451389",
                            "IataCode": "SYJ",
                            "Airports": [
                                {
                                    "Id": "SYJ",
                                    "Name": "Sirjan",
                                    "CityId": "SYJA",
                                    "CountryId": "IR",
                                    "Location": "55.66, 29.556389"
                                }
                            ]
                        },
                        {
                            "Id": "AFZA",
                            "Name": "Sabzevar",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "57.679722, 36.214444",
                            "IataCode": "AFZ",
                            "Airports": [
                                {
                                    "Id": "AFZ",
                                    "Name": "Sabzevar",
                                    "CityId": "AFZA",
                                    "CountryId": "IR",
                                    "Location": "57.595278, 36.168056"
                                }
                            ]
                        },
                        {
                            "Id": "GZWA",
                            "Name": "Ghazvin",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.016945, 36.262222",
                            "IataCode": "GZW",
                            "Airports": [
                                {
                                    "Id": "GZW",
                                    "Name": "Ghazvin",
                                    "CityId": "GZWA",
                                    "CountryId": "IR",
                                    "Location": "50.033333, 36.233333"
                                }
                            ]
                        },
                        {
                            "Id": "IHRA",
                            "Name": "Iran Shahr",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "60.691945, 27.203889",
                            "IataCode": "IHR",
                            "Airports": [
                                {
                                    "Id": "IHR",
                                    "Name": "Iran Shahr",
                                    "CityId": "IHRA",
                                    "CountryId": "IR",
                                    "Location": "60.724167, 27.236944"
                                }
                            ]
                        },
                        {
                            "Id": "IILA",
                            "Name": "Ilaam",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "46.4227, 33.6374",
                            "IataCode": "IIL",
                            "Airports": [
                                {
                                    "Id": "IIL",
                                    "Name": "Ilaam",
                                    "CityId": "IILA",
                                    "CountryId": "IR",
                                    "Location": "46.433333, 33.616667"
                                }
                            ]
                        },
                        {
                            "Id": "AJKA",
                            "Name": "Araak",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "49.683889, 34.085557",
                            "IataCode": "AJK",
                            "Airports": [
                                {
                                    "Id": "AJK",
                                    "Name": "Araak",
                                    "CityId": "AJKA",
                                    "CountryId": "IR",
                                    "Location": "49.833333, 34.133333"
                                }
                            ]
                        },
                        {
                            "Id": "FAZA",
                            "Name": "Fasa",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.65, 28.939444",
                            "IataCode": "FAZ",
                            "Airports": [
                                {
                                    "Id": "FAZ",
                                    "Name": "Fasa",
                                    "CityId": "FAZA",
                                    "CountryId": "IR",
                                    "Location": "53.7, 28.933333"
                                }
                            ]
                        },
                        {
                            "Id": "GBTA",
                            "Name": "Gorgan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.436111, 36.839446",
                            "IataCode": "GBT",
                            "Airports": [
                                {
                                    "Id": "GBT",
                                    "Name": "Gorgan",
                                    "CityId": "GBTA",
                                    "CountryId": "IR",
                                    "Location": "54.412778, 36.900556"
                                }
                            ]
                        },
                        {
                            "Id": "KIHA",
                            "Name": "Kish Island",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.019444, 26.557778",
                            "IataCode": "KIH",
                            "Airports": [
                                {
                                    "Id": "KIH",
                                    "Name": "Kish Island",
                                    "CityId": "KIHA",
                                    "CountryId": "IR",
                                    "Location": "53.972054, 26.53233"
                                }
                            ]
                        },
                        {
                            "Id": "YESA",
                            "Name": "Yasouj",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "51.566667, 30.716667",
                            "IataCode": "YES",
                            "Airports": [
                                {
                                    "Id": "YES",
                                    "Name": "Yasouj",
                                    "CityId": "YESA",
                                    "CountryId": "IR",
                                    "Location": "51.5451, 30.7005"
                                }
                            ]
                        },
                        {
                            "Id": "SHAH",
                            "Name": "Shāhrūd",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "55.016667, 36.416667",
                            "IataCode": "RUD",
                            "Airports": [
                                {
                                    "Id": "RUD",
                                    "Name": "Shahroud",
                                    "CityId": "SHAH",
                                    "CountryId": "IR",
                                    "Location": "55.0945, 36.426792"
                                }
                            ]
                        },
                        {
                            "Id": "TCXA",
                            "Name": "Tabas",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "56.9225, 33.588333",
                            "IataCode": "TCX",
                            "Airports": [
                                {
                                    "Id": "TCX",
                                    "Name": "Tabas",
                                    "CityId": "TCXA",
                                    "CountryId": "IR",
                                    "Location": "56.901389, 33.68"
                                }
                            ]
                        },
                        {
                            "Id": "CQDA",
                            "Name": "Shahre-Kord",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.864444, 32.325556",
                            "IataCode": "CQD",
                            "Airports": [
                                {
                                    "Id": "CQD",
                                    "Name": "Shahre-Kord",
                                    "CityId": "CQDA",
                                    "CountryId": "IR",
                                    "Location": "50.842222, 32.297222"
                                }
                            ]
                        },
                        {
                            "Id": "SNXI",
                            "Name": "Semnan",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "53.394166, 35.575278",
                            "IataCode": "SNX",
                            "Airports": [
                                {
                                    "Id": "SNX",
                                    "Name": "Semnan",
                                    "CityId": "SNXI",
                                    "CountryId": "IR",
                                    "Location": "53.495998, 35.594983"
                                }
                            ]
                        },
                        {
                            "Id": "GSMA",
                            "Name": "Gheshm",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "56.2719, 26.9581",
                            "IataCode": "GSM",
                            "Airports": [
                                {
                                    "Id": "GSM",
                                    "Name": "Gheshm",
                                    "CityId": "GSMA",
                                    "CountryId": "IR",
                                    "Location": "56.2775, 26.948333"
                                }
                            ]
                        },
                        {
                            "Id": "PFQA",
                            "Name": "Parsabad",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "47.9174, 39.6482",
                            "IataCode": "PFQ",
                            "Airports": [
                                {
                                    "Id": "PFQ",
                                    "Name": "Parsabad",
                                    "CityId": "PFQA",
                                    "CountryId": "IR",
                                    "Location": "47.883053, 39.603374"
                                }
                            ]
                        },
                        {
                            "Id": "IMQI",
                            "Name": "Maku",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "44.5167, 39.2952",
                            "IataCode": "IMQ",
                            "Airports": [
                                {
                                    "Id": "IMQ",
                                    "Name": "Maku",
                                    "CityId": "IMQI",
                                    "CountryId": "IR",
                                    "Location": "44.925278, 39.1925"
                                }
                            ]
                        },
                        {
                            "Id": "LRRA",
                            "Name": "Lar",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.343611, 27.684167",
                            "IataCode": "LRR",
                            "Airports": [
                                {
                                    "Id": "LRR",
                                    "Name": "Lar",
                                    "CityId": "LRRA",
                                    "CountryId": "IR",
                                    "Location": "54.387222, 27.673056"
                                }
                            ]
                        },
                        {
                            "Id": "KKSI",
                            "Name": "Kāshān",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "51.4773, 33.9888",
                            "IataCode": "KKS",
                            "Airports": [
                                {
                                    "Id": "KKS",
                                    "Name": "Kashan",
                                    "CityId": "KKSI",
                                    "CountryId": "IR",
                                    "Location": "51.570521, 33.89436"
                                }
                            ]
                        },
                        {
                            "Id": "KHKA",
                            "Name": "Khark Island",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.330556, 29.261389",
                            "IataCode": "KHK",
                            "Airports": [
                                {
                                    "Id": "KHK",
                                    "Name": "Khark Island",
                                    "CityId": "KHKA",
                                    "CountryId": "IR",
                                    "Location": "50.323611, 29.261667"
                                }
                            ]
                        },
                        {
                            "Id": "GCHA",
                            "Name": "Gachsaran",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.7981, 30.3586",
                            "IataCode": "GCH",
                            "Airports": [
                                {
                                    "Id": "GCH",
                                    "Name": "Gachsaran",
                                    "CityId": "GCHA",
                                    "CountryId": "IR",
                                    "Location": "50.816667, 30.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BJBA",
                            "Name": "Bojnord",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "57.324444, 37.4775",
                            "IataCode": "BJB",
                            "Airports": [
                                {
                                    "Id": "BJB",
                                    "Name": "Bojnord",
                                    "CityId": "BJBA",
                                    "CountryId": "IR",
                                    "Location": "57.305556, 37.488333"
                                }
                            ]
                        },
                        {
                            "Id": "MRXA",
                            "Name": "Bandar Mahshahr",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "49.1981, 30.5588",
                            "IataCode": "MRX",
                            "Airports": [
                                {
                                    "Id": "MRX",
                                    "Name": "Bandar Mahshahr",
                                    "CityId": "MRXA",
                                    "CountryId": "IR",
                                    "Location": "49.167222, 30.535556"
                                }
                            ]
                        },
                        {
                            "Id": "BDHA",
                            "Name": "Bandar Lengeh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.880833, 26.556111",
                            "IataCode": "BDH",
                            "Airports": [
                                {
                                    "Id": "BDH",
                                    "Name": "Bandar Lengeh",
                                    "CityId": "BDHA",
                                    "CountryId": "IR",
                                    "Location": "54.825468, 26.532791"
                                }
                            ]
                        },
                        {
                            "Id": "YEHA",
                            "Name": "Asaloyeh",
                            "SingleAirportCity": false,
                            "CountryId": "IR",
                            "Location": "52.6074, 27.4761",
                            "IataCode": "YEH",
                            "Airports": [
                                {
                                    "Id": "PGU",
                                    "Name": "Asaloyeh Persian Gulf International",
                                    "CityId": "YEHA",
                                    "CountryId": "IR",
                                    "Location": "52.7339, 27.3672"
                                },
                                {
                                    "Id": "YEH",
                                    "Name": "Asaloyeh",
                                    "CityId": "YEHA",
                                    "CountryId": "IR",
                                    "Location": "52.61667, 27.46667"
                                }
                            ]
                        },
                        {
                            "Id": "AEUA",
                            "Name": "Abu Musa",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.995278, 25.914444",
                            "IataCode": "AEU",
                            "Airports": [
                                {
                                    "Id": "AEU",
                                    "Name": "Abu Musa",
                                    "CityId": "AEUA",
                                    "CountryId": "IR",
                                    "Location": "55.041667, 25.8875"
                                }
                            ]
                        },
                        {
                            "Id": "ZBRA",
                            "Name": "Chah-Bahar",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "60.633889, 25.295556",
                            "IataCode": "ZBR",
                            "Airports": [
                                {
                                    "Id": "ZBR",
                                    "Name": "Chah-Bahar",
                                    "CityId": "ZBRA",
                                    "CountryId": "IR",
                                    "Location": "60.38211, 25.44335"
                                }
                            ]
                        },
                        {
                            "Id": "BSMA",
                            "Name": "Rūdposht",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "52.35, 36.65",
                            "IataCode": "BSM",
                            "Airports": [
                                {
                                    "Id": "BSM",
                                    "Name": "Bishe-Kola",
                                    "CityId": "BSMA",
                                    "CountryId": "IR",
                                    "Location": "52.35, 36.65"
                                }
                            ]
                        },
                        {
                            "Id": "ACPA",
                            "Name": "Serej",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "46.1668, 37.3364",
                            "IataCode": "ACP",
                            "Airports": [
                                {
                                    "Id": "ACP",
                                    "Name": "Sahand",
                                    "CityId": "ACPA",
                                    "CountryId": "IR",
                                    "Location": "46.149722, 37.354444"
                                }
                            ]
                        },
                        {
                            "Id": "HDRA",
                            "Name": "Ţūlā",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "56.2112, 26.9789",
                            "IataCode": "HDR",
                            "Airports": [
                                {
                                    "Id": "HDR",
                                    "Name": "Havadarya",
                                    "CityId": "HDRA",
                                    "CountryId": "IR",
                                    "Location": "56.1725, 27.1583"
                                }
                            ]
                        },
                        {
                            "Id": "OMIA",
                            "Name": "Ţabreh-ye ‘Olyā",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "49.4972, 30.8264",
                            "IataCode": "OMI",
                            "Airports": [
                                {
                                    "Id": "OMI",
                                    "Name": "Omidieh",
                                    "CityId": "OMIA",
                                    "CountryId": "IR",
                                    "Location": "49.533333, 30.833333"
                                }
                            ]
                        },
                        {
                            "Id": "AKWA",
                            "Name": "Omīdīyeh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "49.705, 30.759722",
                            "IataCode": "AKW",
                            "Airports": [
                                {
                                    "Id": "AKW",
                                    "Name": "Aghajari",
                                    "CityId": "AKWA",
                                    "CountryId": "IR",
                                    "Location": "49.683333, 30.75"
                                }
                            ]
                        },
                        {
                            "Id": "SXIA",
                            "Name": "Bandar-e Bostāneh",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "54.653611, 26.509722",
                            "IataCode": "SXI",
                            "Airports": [
                                {
                                    "Id": "SXI",
                                    "Name": "Sirri Island",
                                    "CityId": "SXIA",
                                    "CountryId": "IR",
                                    "Location": "54.533333, 25.916667"
                                }
                            ]
                        },
                        {
                            "Id": "IAQA",
                            "Name": "Bāgh-e Mollā",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "50.820833, 28.940833",
                            "IataCode": "IAQ",
                            "Airports": [
                                {
                                    "Id": "IAQ",
                                    "Name": "Bahregan",
                                    "CityId": "IAQA",
                                    "CountryId": "IR",
                                    "Location": "50.833333, 28.933333"
                                }
                            ]
                        },
                        {
                            "Id": "NUJA",
                            "Name": "Amīrābād",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "48.6883, 35.2201",
                            "IataCode": "NUJ",
                            "Airports": [
                                {
                                    "Id": "NUJ",
                                    "Name": "Nojeh",
                                    "CityId": "NUJA",
                                    "CountryId": "IR",
                                    "Location": "48.666667, 35.2"
                                }
                            ]
                        },
                        {
                            "Id": "BBLA",
                            "Name": "Shūrek",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "52.658333, 36.665556",
                            "IataCode": "BBL",
                            "Airports": [
                                {
                                    "Id": "BBL",
                                    "Name": "Babolsar",
                                    "CityId": "BBLA",
                                    "CountryId": "IR",
                                    "Location": "52.666667, 36.65"
                                }
                            ]
                        },
                        {
                            "Id": "TEWA",
                            "Name": "Chāh Gachī",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "52.349, 27.8242",
                            "IataCode": "TEW",
                            "Airports": [
                                {
                                    "Id": "TEW",
                                    "Name": "Tohid",
                                    "CityId": "TEWA",
                                    "CountryId": "IR",
                                    "Location": "52.353611, 27.825556"
                                }
                            ]
                        },
                        {
                            "Id": "IFHA",
                            "Name": "Āqānūr",
                            "SingleAirportCity": true,
                            "CountryId": "IR",
                            "Location": "51.52816, 32.91971",
                            "IataCode": "IFH",
                            "Airports": [
                                {
                                    "Id": "IFH",
                                    "Name": "Hesa",
                                    "CityId": "IFHA",
                                    "CountryId": "IR",
                                    "Location": "51.55, 32.916667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AE",
                    "Name": "United Arab Emirates",
                    "CurrencyId": "AED",
                    "Cities": [
                        {
                            "Id": "DXBA",
                            "Name": "Dubai",
                            "SingleAirportCity": false,
                            "CountryId": "AE",
                            "Location": "55.385782, 24.941633",
                            "IataCode": "DXB",
                            "Airports": [
                                {
                                    "Id": "DWC",
                                    "Name": "Dubai Al Maktoum International",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.172222, 24.886111"
                                },
                                {
                                    "Id": "XNB",
                                    "Name": "Dubai Bus Station",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.336761, 25.254012"
                                },
                                {
                                    "Id": "DJH",
                                    "Name": "Jebel Ali SPB",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.023611, 24.988889"
                                },
                                {
                                    "Id": "DXB",
                                    "Name": "Dubai",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.365748, 25.253145"
                                },
                                {
                                    "Id": "XMB",
                                    "Name": "M'Bahiakro",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.147033, 25.075629"
                                },
                                {
                                    "Id": "DCG",
                                    "Name": "Dubai Creek SPB",
                                    "CityId": "DXBA",
                                    "CountryId": "AE",
                                    "Location": "55.331858, 25.242353"
                                }
                            ]
                        },
                        {
                            "Id": "RKTA",
                            "Name": "Ras al Khaimah",
                            "SingleAirportCity": false,
                            "CountryId": "AE",
                            "Location": "55.942778, 25.791111",
                            "IataCode": "RKT",
                            "Airports": [
                                {
                                    "Id": "RKT",
                                    "Name": "Ras Al Khaimah",
                                    "CityId": "RKTA",
                                    "CountryId": "AE",
                                    "Location": "55.938889, 25.613333"
                                },
                                {
                                    "Id": "RHR",
                                    "Name": "Al Hamra SPB",
                                    "CityId": "RKTA",
                                    "CountryId": "AE",
                                    "Location": "55.778, 25.691"
                                }
                            ]
                        },
                        {
                            "Id": "AUHA",
                            "Name": "Abu Dhabi",
                            "SingleAirportCity": false,
                            "CountryId": "AE",
                            "Location": "54.366667, 24.466668",
                            "IataCode": "AUH",
                            "Airports": [
                                {
                                    "Id": "AZI",
                                    "Name": "Abu Dhabi Bateen",
                                    "CityId": "AUHA",
                                    "CountryId": "AE",
                                    "Location": "54.451667, 24.419167"
                                },
                                {
                                    "Id": "DHF",
                                    "Name": "Abu Dhabi Al Dhafra Military Apt",
                                    "CityId": "AUHA",
                                    "CountryId": "AE",
                                    "Location": "54.548333, 24.24"
                                },
                                {
                                    "Id": "AYM",
                                    "Name": "Yas Island SPB",
                                    "CityId": "AUHA",
                                    "CountryId": "AE",
                                    "Location": "54.610497, 24.464503"
                                },
                                {
                                    "Id": "AUH",
                                    "Name": "Abu Dhabi International",
                                    "CityId": "AUHA",
                                    "CountryId": "AE",
                                    "Location": "54.65, 24.433333"
                                }
                            ]
                        },
                        {
                            "Id": "FJRA",
                            "Name": "Al-Fujairah",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "56.3375, 25.123056",
                            "IataCode": "FJR",
                            "Airports": [
                                {
                                    "Id": "FJR",
                                    "Name": "Al-Fujairah",
                                    "CityId": "FJRA",
                                    "CountryId": "AE",
                                    "Location": "56.330556, 25.109444"
                                }
                            ]
                        },
                        {
                            "Id": "SHJA",
                            "Name": "Sharjah",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "55.779677, 25.144244",
                            "IataCode": "SHJ",
                            "Airports": [
                                {
                                    "Id": "SHJ",
                                    "Name": "Sharjah",
                                    "CityId": "SHJA",
                                    "CountryId": "AE",
                                    "Location": "55.500556, 25.328611"
                                }
                            ]
                        },
                        {
                            "Id": "AANA",
                            "Name": "Al Ain",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "55.766667, 24.216667",
                            "IataCode": "AAN",
                            "Airports": [
                                {
                                    "Id": "AAN",
                                    "Name": "Al Ain",
                                    "CityId": "AANA",
                                    "CountryId": "AE",
                                    "Location": "55.75, 24.25"
                                }
                            ]
                        },
                        {
                            "Id": "NHDA",
                            "Name": "Hunaywah",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "55.6, 25.183333",
                            "IataCode": "NHD",
                            "Airports": [
                                {
                                    "Id": "NHD",
                                    "Name": "Minhad Ab",
                                    "CityId": "NHDA",
                                    "CountryId": "AE",
                                    "Location": "55.733333, 25.066667"
                                }
                            ]
                        },
                        {
                            "Id": "ZDYA",
                            "Name": "Da`sah",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "52.623889, 24.280278",
                            "IataCode": "ZDY",
                            "Airports": [
                                {
                                    "Id": "ZDY",
                                    "Name": "Delma",
                                    "CityId": "ZDYA",
                                    "CountryId": "AE",
                                    "Location": "52.3358, 24.5031"
                                }
                            ]
                        },
                        {
                            "Id": "XSBA",
                            "Name": "`Awāfī",
                            "SingleAirportCity": true,
                            "CountryId": "AE",
                            "Location": "52.583874, 24.283974",
                            "IataCode": "XSB",
                            "Airports": [
                                {
                                    "Id": "XSB",
                                    "Name": "Sir Bani Yas Island",
                                    "CityId": "XSBA",
                                    "CountryId": "AE",
                                    "Location": "52.583874, 24.283974"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "LB",
                    "Name": "Lebanon",
                    "CurrencyId": "LBP",
                    "Cities": [
                        {
                            "Id": "BEIR",
                            "Name": "Beirut",
                            "SingleAirportCity": true,
                            "CountryId": "LB",
                            "Location": "35.509721, 33.871948",
                            "IataCode": "BEY",
                            "Airports": [
                                {
                                    "Id": "BEY",
                                    "Name": "Beirut",
                                    "CityId": "BEIR",
                                    "CountryId": "LB",
                                    "Location": "35.491226, 33.819465"
                                }
                            ]
                        },
                        {
                            "Id": "KYEA",
                            "Name": "Tripoli",
                            "SingleAirportCity": true,
                            "CountryId": "LB",
                            "Location": "35.849722, 34.436667",
                            "IataCode": "KYE",
                            "Airports": [
                                {
                                    "Id": "KYE",
                                    "Name": "Tripoli",
                                    "CityId": "KYEA",
                                    "CountryId": "LB",
                                    "Location": "36.002778, 34.586111"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "YE",
                    "Name": "Yemen",
                    "CurrencyId": "YER",
                    "Cities": [
                        {
                            "Id": "ADEA",
                            "Name": "Aden",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.036667, 12.779444",
                            "IataCode": "ADE",
                            "Airports": [
                                {
                                    "Id": "ADE",
                                    "Name": "Aden",
                                    "CityId": "ADEA",
                                    "CountryId": "YE",
                                    "Location": "45.030556, 12.827778"
                                }
                            ]
                        },
                        {
                            "Id": "BHNA",
                            "Name": "Beihan",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.733333, 14.8",
                            "IataCode": "BHN",
                            "Airports": [
                                {
                                    "Id": "BHN",
                                    "Name": "Beihan",
                                    "CityId": "BHNA",
                                    "CountryId": "YE",
                                    "Location": "45.733333, 14.783333"
                                }
                            ]
                        },
                        {
                            "Id": "DHLA",
                            "Name": "Dhala",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "44.730833, 13.696667",
                            "IataCode": "DHL",
                            "Airports": [
                                {
                                    "Id": "DHL",
                                    "Name": "Dhala",
                                    "CityId": "DHLA",
                                    "CountryId": "YE",
                                    "Location": "44.729167, 13.7375"
                                }
                            ]
                        },
                        {
                            "Id": "DMRA",
                            "Name": "Dhamar",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "44.401667, 14.55",
                            "IataCode": "DMR",
                            "Airports": [
                                {
                                    "Id": "DMR",
                                    "Name": "Dhamar",
                                    "CityId": "DMRA",
                                    "CountryId": "YE",
                                    "Location": "44.433333, 14.533333"
                                }
                            ]
                        },
                        {
                            "Id": "GXFA",
                            "Name": "Seiyun",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "48.793333, 15.943333",
                            "IataCode": "GXF",
                            "Airports": [
                                {
                                    "Id": "GXF",
                                    "Name": "Seiyun",
                                    "CityId": "GXFA",
                                    "CountryId": "YE",
                                    "Location": "48.78685, 15.962732"
                                }
                            ]
                        },
                        {
                            "Id": "HODA",
                            "Name": "Hodeidah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "42.952223, 14.797778",
                            "IataCode": "HOD",
                            "Airports": [
                                {
                                    "Id": "HOD",
                                    "Name": "Hodeidah",
                                    "CityId": "HODA",
                                    "CountryId": "YE",
                                    "Location": "42.982778, 14.747222"
                                }
                            ]
                        },
                        {
                            "Id": "KAMA",
                            "Name": "Kamaran Is",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "42.6, 15.35",
                            "IataCode": "KAM",
                            "Airports": [
                                {
                                    "Id": "KAM",
                                    "Name": "Kamaran Is",
                                    "CityId": "KAMA",
                                    "CountryId": "YE",
                                    "Location": "41.666667, 15.35"
                                }
                            ]
                        },
                        {
                            "Id": "LDRA",
                            "Name": "Lodar",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.864722, 13.886389",
                            "IataCode": "LDR",
                            "Airports": [
                                {
                                    "Id": "LDR",
                                    "Name": "Lodar",
                                    "CityId": "LDRA",
                                    "CountryId": "YE",
                                    "Location": "45.866667, 13.9"
                                }
                            ]
                        },
                        {
                            "Id": "SAHA",
                            "Name": "Sana'a",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "44.206667, 15.354722",
                            "IataCode": "SAH",
                            "Airports": [
                                {
                                    "Id": "SAH",
                                    "Name": "Sana'a",
                                    "CityId": "SAHA",
                                    "CountryId": "YE",
                                    "Location": "44.219167, 15.478611"
                                }
                            ]
                        },
                        {
                            "Id": "TAIA",
                            "Name": "Taiz",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "44.033334, 13.566667",
                            "IataCode": "TAI",
                            "Airports": [
                                {
                                    "Id": "TAI",
                                    "Name": "Taiz",
                                    "CityId": "TAIA",
                                    "CountryId": "YE",
                                    "Location": "44.1375, 13.688889"
                                }
                            ]
                        },
                        {
                            "Id": "MKXA",
                            "Name": "Mukalla",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "49.131389, 14.53",
                            "IataCode": "MKX",
                            "Airports": [
                                {
                                    "Id": "MKX",
                                    "Name": "Mukalla",
                                    "CityId": "MKXA",
                                    "CountryId": "YE",
                                    "Location": "49.133333, 14.533333"
                                }
                            ]
                        },
                        {
                            "Id": "RXAA",
                            "Name": "Raudha",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "44.223611, 15.431111",
                            "IataCode": "RXA",
                            "Airports": [
                                {
                                    "Id": "RXA",
                                    "Name": "Raudha",
                                    "CityId": "RXAA",
                                    "CountryId": "YE",
                                    "Location": "44.216667, 15.433333"
                                }
                            ]
                        },
                        {
                            "Id": "UKRA",
                            "Name": "Mukeiras",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.671111, 13.943611",
                            "IataCode": "UKR",
                            "Airports": [
                                {
                                    "Id": "UKR",
                                    "Name": "Mukeiras",
                                    "CityId": "UKRA",
                                    "CountryId": "YE",
                                    "Location": "45.683333, 13.933333"
                                }
                            ]
                        },
                        {
                            "Id": "AJOA",
                            "Name": "Aljouf",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.59952, 16.621527",
                            "IataCode": "AJO",
                            "Airports": [
                                {
                                    "Id": "AJO",
                                    "Name": "Aljouf",
                                    "CityId": "AJOA",
                                    "CountryId": "YE",
                                    "Location": "44.25, 15.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SYEA",
                            "Name": "Sadah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "43.764444, 16.935833",
                            "IataCode": "SYE",
                            "Airports": [
                                {
                                    "Id": "SYE",
                                    "Name": "Sadah",
                                    "CityId": "SYEA",
                                    "CountryId": "YE",
                                    "Location": "43.616667, 16.866667"
                                }
                            ]
                        },
                        {
                            "Id": "MFYA",
                            "Name": "Mayfa'ah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "46, 14.333333",
                            "IataCode": "MFY",
                            "Airports": [
                                {
                                    "Id": "MFY",
                                    "Name": "Mayfa'ah",
                                    "CityId": "MFYA",
                                    "CountryId": "YE",
                                    "Location": "46.008333, 13.666667"
                                }
                            ]
                        },
                        {
                            "Id": "MYNA",
                            "Name": "Mareb",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.325813, 15.462532",
                            "IataCode": "MYN",
                            "Airports": [
                                {
                                    "Id": "MYN",
                                    "Name": "Mareb",
                                    "CityId": "MYNA",
                                    "CountryId": "YE",
                                    "Location": "45.333333, 15.483333"
                                }
                            ]
                        },
                        {
                            "Id": "AXKA",
                            "Name": "Ataq",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "46.8, 14.55",
                            "IataCode": "AXK",
                            "Airports": [
                                {
                                    "Id": "AXK",
                                    "Name": "Ataq",
                                    "CityId": "AXKA",
                                    "CountryId": "YE",
                                    "Location": "46.82618, 14.55132"
                                }
                            ]
                        },
                        {
                            "Id": "AAYA",
                            "Name": "Al Ghaydah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "52.183333, 16.216667",
                            "IataCode": "AAY",
                            "Airports": [
                                {
                                    "Id": "AAY",
                                    "Name": "Al Ghaydah",
                                    "CityId": "AAYA",
                                    "CountryId": "YE",
                                    "Location": "52.183333, 16.2"
                                }
                            ]
                        },
                        {
                            "Id": "SCTA",
                            "Name": "Socotra",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "53.298466, 12.319161",
                            "IataCode": "SCT",
                            "Airports": [
                                {
                                    "Id": "SCT",
                                    "Name": "Socotra",
                                    "CityId": "SCTA",
                                    "CountryId": "YE",
                                    "Location": "54, 12.5"
                                }
                            ]
                        },
                        {
                            "Id": "RIYA",
                            "Name": "Ar Riyān",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "49.37503, 14.66264",
                            "IataCode": "RIY",
                            "Airports": [
                                {
                                    "Id": "RIY",
                                    "Name": "Riyan Mukalla",
                                    "CityId": "RIYA",
                                    "CountryId": "YE",
                                    "Location": "49.37503, 14.66264"
                                }
                            ]
                        },
                        {
                            "Id": "IHNA",
                            "Name": "Thawbān",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "50, 14.95",
                            "IataCode": "IHN",
                            "Airports": [
                                {
                                    "Id": "IHN",
                                    "Name": "Qishn",
                                    "CityId": "IHNA",
                                    "CountryId": "YE",
                                    "Location": "50.05, 15.05"
                                }
                            ]
                        },
                        {
                            "Id": "DAHA",
                            "Name": "Far`ān",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "46.125278, 13.874722",
                            "IataCode": "DAH",
                            "Airports": [
                                {
                                    "Id": "DAH",
                                    "Name": "Dathina",
                                    "CityId": "DAHA",
                                    "CountryId": "YE",
                                    "Location": "46.133333, 13.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BYDA",
                            "Name": "Al Makdash",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "46.65, 14.183333",
                            "IataCode": "BYD",
                            "Airports": [
                                {
                                    "Id": "BYD",
                                    "Name": "Beidah",
                                    "CityId": "BYDA",
                                    "CountryId": "YE",
                                    "Location": "46.55, 14.133333"
                                }
                            ]
                        },
                        {
                            "Id": "WDAA",
                            "Name": "Ad Daymah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "45.533333, 14.866667",
                            "IataCode": "WDA",
                            "Airports": [
                                {
                                    "Id": "WDA",
                                    "Name": "Wadi Ain",
                                    "CityId": "WDAA",
                                    "CountryId": "YE",
                                    "Location": "45.55, 14.866667"
                                }
                            ]
                        },
                        {
                            "Id": "EABA",
                            "Name": "Al `Asaqah",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "43.505556, 14.584444",
                            "IataCode": "EAB",
                            "Airports": [
                                {
                                    "Id": "EAB",
                                    "Name": "Abbse",
                                    "CityId": "EABA",
                                    "CountryId": "YE",
                                    "Location": "43.5, 14.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BUKA",
                            "Name": "Al ‘Ulkī",
                            "SingleAirportCity": true,
                            "CountryId": "YE",
                            "Location": "43.766, 15.83175",
                            "IataCode": "BUK",
                            "Airports": [
                                {
                                    "Id": "BUK",
                                    "Name": "Albuq",
                                    "CityId": "BUKA",
                                    "CountryId": "YE",
                                    "Location": "43.766667, 15.833333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "IQ",
                    "Name": "Iraq",
                    "CurrencyId": "IQD",
                    "Cities": [
                        {
                            "Id": "KIKA",
                            "Name": "Kirkuk",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "44.392222, 35.468057",
                            "IataCode": "KIK",
                            "Airports": [
                                {
                                    "Id": "KIK",
                                    "Name": "Kirkuk",
                                    "CityId": "KIKA",
                                    "CountryId": "IQ",
                                    "Location": "44.283333, 35.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BGWA",
                            "Name": "Baghdad",
                            "SingleAirportCity": false,
                            "CountryId": "IQ",
                            "Location": "44.39389, 33.338627",
                            "IataCode": "BGW",
                            "Airports": [
                                {
                                    "Id": "BGW",
                                    "Name": "Baghdad International",
                                    "CityId": "BGWA",
                                    "CountryId": "IQ",
                                    "Location": "44.225, 33.2556"
                                },
                                {
                                    "Id": "SDA",
                                    "Name": "Baghdad International",
                                    "CityId": "BGWA",
                                    "CountryId": "IQ",
                                    "Location": "44.234444, 33.2625"
                                }
                            ]
                        },
                        {
                            "Id": "BSRA",
                            "Name": "Basra",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "47.819165, 30.494172",
                            "IataCode": "BSR",
                            "Airports": [
                                {
                                    "Id": "BSR",
                                    "Name": "Basra",
                                    "CityId": "BSRA",
                                    "CountryId": "IQ",
                                    "Location": "47.662222, 30.549167"
                                }
                            ]
                        },
                        {
                            "Id": "EBLA",
                            "Name": "Erbil",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "44.008889, 36.19",
                            "IataCode": "EBL",
                            "Airports": [
                                {
                                    "Id": "EBL",
                                    "Name": "Erbil",
                                    "CityId": "EBLA",
                                    "CountryId": "IQ",
                                    "Location": "43.963056, 36.2375"
                                }
                            ]
                        },
                        {
                            "Id": "OSMA",
                            "Name": "Mosul",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "43.11889, 36.335",
                            "IataCode": "OSM",
                            "Airports": [
                                {
                                    "Id": "OSM",
                                    "Name": "Mosul",
                                    "CityId": "OSMA",
                                    "CountryId": "IQ",
                                    "Location": "43.149444, 36.3075"
                                }
                            ]
                        },
                        {
                            "Id": "ISUA",
                            "Name": "Sulaymaniyah",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "45.440833, 35.561667",
                            "IataCode": "ISU",
                            "Airports": [
                                {
                                    "Id": "ISU",
                                    "Name": "Sulaymaniyah International",
                                    "CityId": "ISUA",
                                    "CountryId": "IQ",
                                    "Location": "45.314701, 35.5608"
                                }
                            ]
                        },
                        {
                            "Id": "XNHI",
                            "Name": "Nasiriyah",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "46.259444, 31.054167",
                            "IataCode": "XNH",
                            "Airports": [
                                {
                                    "Id": "XNH",
                                    "Name": "Imam Ali Air Base",
                                    "CityId": "XNHI",
                                    "CountryId": "IQ",
                                    "Location": "46.0925, 30.939722"
                                }
                            ]
                        },
                        {
                            "Id": "NJFA",
                            "Name": "Najaf",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "44.314722, 31.996111",
                            "IataCode": "NJF",
                            "Airports": [
                                {
                                    "Id": "NJF",
                                    "Name": "Najaf",
                                    "CityId": "NJFA",
                                    "CountryId": "IQ",
                                    "Location": "44.404167, 31.991667"
                                }
                            ]
                        },
                        {
                            "Id": "BMNA",
                            "Name": "Tānī",
                            "SingleAirportCity": true,
                            "CountryId": "IQ",
                            "Location": "43.245556, 37.098333",
                            "IataCode": "BMN",
                            "Airports": [
                                {
                                    "Id": "BMN",
                                    "Name": "Bamerny",
                                    "CityId": "BMNA",
                                    "CountryId": "IQ",
                                    "Location": "43.266667, 37.1"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SY",
                    "Name": "Syria",
                    "CurrencyId": "SYP",
                    "Cities": [
                        {
                            "Id": "ALPA",
                            "Name": "Aleppo",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "37.158612, 36.202782",
                            "IataCode": "ALP",
                            "Airports": [
                                {
                                    "Id": "ALP",
                                    "Name": "Aleppo",
                                    "CityId": "ALPA",
                                    "CountryId": "SY",
                                    "Location": "37.226667, 36.180833"
                                }
                            ]
                        },
                        {
                            "Id": "DAMA",
                            "Name": "Damascus",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "36.300001, 33.500003",
                            "IataCode": "DAM",
                            "Airports": [
                                {
                                    "Id": "DAM",
                                    "Name": "Damascus",
                                    "CityId": "DAMA",
                                    "CountryId": "SY",
                                    "Location": "36.517778, 33.413889"
                                }
                            ]
                        },
                        {
                            "Id": "LTKA",
                            "Name": "Latakia",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "35.783333, 35.516667",
                            "IataCode": "LTK",
                            "Airports": [
                                {
                                    "Id": "LTK",
                                    "Name": "Latakia",
                                    "CityId": "LTKA",
                                    "CountryId": "SY",
                                    "Location": "35.94512, 35.407626"
                                }
                            ]
                        },
                        {
                            "Id": "PMSA",
                            "Name": "Palmyra",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "38.283333, 34.55",
                            "IataCode": "PMS",
                            "Airports": [
                                {
                                    "Id": "PMS",
                                    "Name": "Palmyra",
                                    "CityId": "PMSA",
                                    "CountryId": "SY",
                                    "Location": "38.25, 34.6"
                                }
                            ]
                        },
                        {
                            "Id": "DEZA",
                            "Name": "Deirezzor",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "40.15, 35.333333",
                            "IataCode": "DEZ",
                            "Airports": [
                                {
                                    "Id": "DEZ",
                                    "Name": "Deirezzor",
                                    "CityId": "DEZA",
                                    "CountryId": "SY",
                                    "Location": "40.175972, 35.287106"
                                }
                            ]
                        },
                        {
                            "Id": "KACA",
                            "Name": "Kameshli",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "41.226389, 37.049722",
                            "IataCode": "KAC",
                            "Airports": [
                                {
                                    "Id": "KAC",
                                    "Name": "Kameshli",
                                    "CityId": "KACA",
                                    "CountryId": "SY",
                                    "Location": "41.198611, 37.027778"
                                }
                            ]
                        },
                        {
                            "Id": "SORB",
                            "Name": "Baktāl",
                            "SingleAirportCity": true,
                            "CountryId": "SY",
                            "Location": "40.184722, 34.391667",
                            "IataCode": "SOR",
                            "Airports": [
                                {
                                    "Id": "SOR",
                                    "Name": "Al Thaurah",
                                    "CityId": "SORB",
                                    "CountryId": "SY",
                                    "Location": "40.166667, 34.4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PS",
                    "Name": "Palestinian Territory",
                    "CurrencyId": "EGP",
                    "Cities": []
                }
            ]
        },
        {
            "Id": "N",
            "Name": "North America",
            "Countries": [
                {
                    "Id": "BM",
                    "Name": "Bermuda",
                    "CurrencyId": "BMD",
                    "LanguageId": "EN",
                    "Cities": [
                        {
                            "Id": "BDAA",
                            "Name": "Hamilton",
                            "SingleAirportCity": false,
                            "CountryId": "BM",
                            "Location": "-64.783889, 32.294167",
                            "IataCode": "BDA",
                            "Airports": [
                                {
                                    "Id": "BDA",
                                    "Name": "L.F. Wade International Airport",
                                    "CityId": "BDAA",
                                    "CountryId": "BM",
                                    "Location": "-64.6787, 32.36404"
                                },
                                {
                                    "Id": "NWU",
                                    "Name": "Bermuda NAS",
                                    "CityId": "BDAA",
                                    "CountryId": "BM",
                                    "Location": "-65, 32.75"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CA",
                    "Name": "Canada",
                    "CurrencyId": "CAD",
                    "Cities": [
                        {
                            "Id": "MSAA",
                            "Name": "Muskrat Dam",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-91.983333, 53.816667",
                            "IataCode": "MSA",
                            "Airports": [
                                {
                                    "Id": "MSA",
                                    "Name": "Muskrat Dam",
                                    "CityId": "MSAA",
                                    "CountryId": "CA",
                                    "Location": "-91.7628, 53.4414"
                                }
                            ]
                        },
                        {
                            "Id": "SURA",
                            "Name": "Summer Beaver",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-88.5475, 52.715278",
                            "IataCode": "SUR",
                            "Airports": [
                                {
                                    "Id": "SUR",
                                    "Name": "Summer Beaver",
                                    "CityId": "SURA",
                                    "CountryId": "CA",
                                    "Location": "-88.5475, 52.715278"
                                }
                            ]
                        },
                        {
                            "Id": "WNNA",
                            "Name": "Wunnummin Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.166667, 52.916667",
                            "IataCode": "WNN",
                            "Airports": [
                                {
                                    "Id": "WNN",
                                    "Name": "Wunnummin Lake",
                                    "CityId": "WNNA",
                                    "CountryId": "CA",
                                    "Location": "-89.166667, 52.916667"
                                }
                            ]
                        },
                        {
                            "Id": "XTLA",
                            "Name": "Tadoule Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-98.483333, 58.716667",
                            "IataCode": "XTL",
                            "Airports": [
                                {
                                    "Id": "XTL",
                                    "Name": "Tadoule Lake",
                                    "CityId": "XTLA",
                                    "CountryId": "CA",
                                    "Location": "-98.483333, 58.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YFHA",
                            "Name": "Fort Hope",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-88, 51.5",
                            "IataCode": "YFH",
                            "Airports": [
                                {
                                    "Id": "YFH",
                                    "Name": "Fort Hope",
                                    "CityId": "YFHA",
                                    "CountryId": "CA",
                                    "Location": "-88, 51.5"
                                }
                            ]
                        },
                        {
                            "Id": "YGLA",
                            "Name": "La Grande",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.706389, 53.629722",
                            "IataCode": "YGL",
                            "Airports": [
                                {
                                    "Id": "YGL",
                                    "Name": "La Grande",
                                    "CityId": "YGLA",
                                    "CountryId": "CA",
                                    "Location": "-77.706389, 53.629722"
                                }
                            ]
                        },
                        {
                            "Id": "YLHA",
                            "Name": "Lansdowne House",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-87.93, 52.183333",
                            "IataCode": "YLH",
                            "Airports": [
                                {
                                    "Id": "YLH",
                                    "Name": "Lansdowne House",
                                    "CityId": "YLHA",
                                    "CountryId": "CA",
                                    "Location": "-87.93, 52.183333"
                                }
                            ]
                        },
                        {
                            "Id": "YHPA",
                            "Name": "Poplar Hill",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.3, 52.083333",
                            "IataCode": "YHP",
                            "Airports": [
                                {
                                    "Id": "YHP",
                                    "Name": "Poplar Hill",
                                    "CityId": "YHPA",
                                    "CountryId": "CA",
                                    "Location": "-94.3, 52.083333"
                                }
                            ]
                        },
                        {
                            "Id": "YNLA",
                            "Name": "Points North Landing",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-104.08111, 58.275",
                            "IataCode": "YNL",
                            "Airports": [
                                {
                                    "Id": "YNL",
                                    "Name": "Points North Landing",
                                    "CityId": "YNLA",
                                    "CountryId": "CA",
                                    "Location": "-104.08111, 58.275"
                                }
                            ]
                        },
                        {
                            "Id": "YNPA",
                            "Name": "Natuashish",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.184444, 55.913889",
                            "IataCode": "YNP",
                            "Airports": [
                                {
                                    "Id": "YNP",
                                    "Name": "Natuashish",
                                    "CityId": "YNPA",
                                    "CountryId": "CA",
                                    "Location": "-61.184444, 55.913889"
                                }
                            ]
                        },
                        {
                            "Id": "YPOA",
                            "Name": "Peawanuck",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.433333, 54.983333",
                            "IataCode": "YPO",
                            "Airports": [
                                {
                                    "Id": "YPO",
                                    "Name": "Peawanuck",
                                    "CityId": "YPOA",
                                    "CountryId": "CA",
                                    "Location": "-85.433333, 54.983333"
                                }
                            ]
                        },
                        {
                            "Id": "YRAA",
                            "Name": "Gameti",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.41089, 64.127387",
                            "IataCode": "YRA",
                            "Airports": [
                                {
                                    "Id": "YRA",
                                    "Name": "Gameti (Rae Lakes)",
                                    "CityId": "YRAA",
                                    "CountryId": "CA",
                                    "Location": "-117.41604, 64.129184"
                                }
                            ]
                        },
                        {
                            "Id": "YWPA",
                            "Name": "Webequie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-87.368889, 52.959722",
                            "IataCode": "YWP",
                            "Airports": [
                                {
                                    "Id": "YWP",
                                    "Name": "Webequie",
                                    "CityId": "YWPA",
                                    "CountryId": "CA",
                                    "Location": "-87.368889, 52.959722"
                                }
                            ]
                        },
                        {
                            "Id": "YVZA",
                            "Name": "Deer Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.5, 52.666667",
                            "IataCode": "YVZ",
                            "Airports": [
                                {
                                    "Id": "YVZ",
                                    "Name": "Deer Lake",
                                    "CityId": "YVZA",
                                    "CountryId": "CA",
                                    "Location": "-94.0614, 52.6558"
                                }
                            ]
                        },
                        {
                            "Id": "ZGSA",
                            "Name": "Gethsemani",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-60.6672, 50.3339",
                            "IataCode": "ZGS",
                            "Airports": [
                                {
                                    "Id": "ZGS",
                                    "Name": "Gethsemani",
                                    "CityId": "ZGSA",
                                    "CountryId": "CA",
                                    "Location": "-60.673889, 50.259722"
                                }
                            ]
                        },
                        {
                            "Id": "ZELA",
                            "Name": "Bella Bella",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.11398, 52.157506",
                            "IataCode": "ZEL",
                            "Airports": [
                                {
                                    "Id": "ZEL",
                                    "Name": "Bella Bella",
                                    "CityId": "ZELA",
                                    "CountryId": "CA",
                                    "Location": "-128.15552, 52.18156"
                                }
                            ]
                        },
                        {
                            "Id": "ZTMA",
                            "Name": "Shamattawa",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.086716, 55.852963",
                            "IataCode": "ZTM",
                            "Airports": [
                                {
                                    "Id": "ZTM",
                                    "Name": "Shamattawa",
                                    "CityId": "ZTMA",
                                    "CountryId": "CA",
                                    "Location": "-92.081389, 55.865556"
                                }
                            ]
                        },
                        {
                            "Id": "TNSA",
                            "Name": "Tungsten",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.23712, 61.956529",
                            "IataCode": "TNS",
                            "Airports": [
                                {
                                    "Id": "TNS",
                                    "Name": "Cantung",
                                    "CityId": "TNSA",
                                    "CountryId": "CA",
                                    "Location": "-128.2042, 61.956491"
                                }
                            ]
                        },
                        {
                            "Id": "YOGA",
                            "Name": "Ogoki",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.916667, 51.666667",
                            "IataCode": "YOG",
                            "Airports": [
                                {
                                    "Id": "YOG",
                                    "Name": "Ogoki",
                                    "CityId": "YOGA",
                                    "CountryId": "CA",
                                    "Location": "-85.916667, 51.666667"
                                }
                            ]
                        },
                        {
                            "Id": "YRFA",
                            "Name": "Cartwright",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.03306, 53.68306",
                            "IataCode": "YRF",
                            "Airports": [
                                {
                                    "Id": "YRF",
                                    "Name": "Cartwright",
                                    "CityId": "YRFA",
                                    "CountryId": "CA",
                                    "Location": "-57, 53.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YKTA",
                            "Name": "Klemtu",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.51667, 52.6",
                            "IataCode": "YKT",
                            "Airports": [
                                {
                                    "Id": "YKT",
                                    "Name": "Klemtu",
                                    "CityId": "YKTA",
                                    "CountryId": "CA",
                                    "Location": "-128.51667, 52.6"
                                }
                            ]
                        },
                        {
                            "Id": "XCLA",
                            "Name": "Cluff Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-107, 59",
                            "IataCode": "XCL",
                            "Airports": [
                                {
                                    "Id": "XCL",
                                    "Name": "Cluff Lake",
                                    "CityId": "XCLA",
                                    "CountryId": "CA",
                                    "Location": "-107, 59"
                                }
                            ]
                        },
                        {
                            "Id": "YBSA",
                            "Name": "Opapamiska Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-90.376944, 52.606667",
                            "IataCode": "YBS",
                            "Airports": [
                                {
                                    "Id": "YBS",
                                    "Name": "Opapamiska Lake",
                                    "CityId": "YBSA",
                                    "CountryId": "CA",
                                    "Location": "-90.376944, 52.606667"
                                }
                            ]
                        },
                        {
                            "Id": "YDEA",
                            "Name": "Paradise River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.233333, 53.433333",
                            "IataCode": "YDE",
                            "Airports": [
                                {
                                    "Id": "YDE",
                                    "Name": "Paradise River",
                                    "CityId": "YDEA",
                                    "CountryId": "CA",
                                    "Location": "-57.233333, 53.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YDJA",
                            "Name": "Hatchet Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-102.53944, 58.660556",
                            "IataCode": "YDJ",
                            "Airports": [
                                {
                                    "Id": "YDJ",
                                    "Name": "Hatchet Lake",
                                    "CityId": "YDJA",
                                    "CountryId": "CA",
                                    "Location": "-102.53944, 58.660556"
                                }
                            ]
                        },
                        {
                            "Id": "YAHA",
                            "Name": "Lagrande 4",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-73.65, 53.75",
                            "IataCode": "YAH",
                            "Airports": [
                                {
                                    "Id": "YAH",
                                    "Name": "Lagrande 4",
                                    "CityId": "YAHA",
                                    "CountryId": "CA",
                                    "Location": "-73.65, 53.75"
                                }
                            ]
                        },
                        {
                            "Id": "YARA",
                            "Name": "Lagrande 3",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.2, 53.55",
                            "IataCode": "YAR",
                            "Airports": [
                                {
                                    "Id": "YAR",
                                    "Name": "Lagrande 3",
                                    "CityId": "YARA",
                                    "CountryId": "CA",
                                    "Location": "-76.2, 53.55"
                                }
                            ]
                        },
                        {
                            "Id": "YHCA",
                            "Name": "Hakai Pass",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.11667, 51.733333",
                            "IataCode": "YHC",
                            "Airports": [
                                {
                                    "Id": "YHC",
                                    "Name": "Hakai Pass",
                                    "CityId": "YHCA",
                                    "CountryId": "CA",
                                    "Location": "-128.11667, 51.733333"
                                }
                            ]
                        },
                        {
                            "Id": "YKEA",
                            "Name": "Knee Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.666667, 53.05",
                            "IataCode": "YKE",
                            "Airports": [
                                {
                                    "Id": "YKE",
                                    "Name": "Knee Lake",
                                    "CityId": "YKEA",
                                    "CountryId": "CA",
                                    "Location": "-94.666667, 53.05"
                                }
                            ]
                        },
                        {
                            "Id": "YKJA",
                            "Name": "Key Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.6, 57.25",
                            "IataCode": "YKJ",
                            "Airports": [
                                {
                                    "Id": "YKJ",
                                    "Name": "Key Lake",
                                    "CityId": "YKJA",
                                    "CountryId": "CA",
                                    "Location": "-105.6, 57.25"
                                }
                            ]
                        },
                        {
                            "Id": "YMDA",
                            "Name": "Mould Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.5, 76.25",
                            "IataCode": "YMD",
                            "Airports": [
                                {
                                    "Id": "YMD",
                                    "Name": "Mould Bay",
                                    "CityId": "YMDA",
                                    "CountryId": "CA",
                                    "Location": "-119.5, 76.25"
                                }
                            ]
                        },
                        {
                            "Id": "YFGA",
                            "Name": "Fontanges",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.183333, 54.55",
                            "IataCode": "YFG",
                            "Airports": [
                                {
                                    "Id": "YFG",
                                    "Name": "Fontanges",
                                    "CityId": "YFGA",
                                    "CountryId": "CA",
                                    "Location": "-71.183333, 54.55"
                                }
                            ]
                        },
                        {
                            "Id": "YEUA",
                            "Name": "Eureka",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.81639, 80",
                            "IataCode": "YEU",
                            "Airports": [
                                {
                                    "Id": "YEU",
                                    "Name": "Eureka",
                                    "CityId": "YEUA",
                                    "CountryId": "CA",
                                    "Location": "-85.933333, 80"
                                }
                            ]
                        },
                        {
                            "Id": "YLTA",
                            "Name": "Alert",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-62.283333, 82.516667",
                            "IataCode": "YLT",
                            "Airports": [
                                {
                                    "Id": "YLT",
                                    "Name": "Alert",
                                    "CityId": "YLTA",
                                    "CountryId": "CA",
                                    "Location": "-62.283333, 82.516667"
                                }
                            ]
                        },
                        {
                            "Id": "YSAA",
                            "Name": "Sable Island",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-60, 44",
                            "IataCode": "YSA",
                            "Airports": [
                                {
                                    "Id": "YSA",
                                    "Name": "Sable Island",
                                    "CityId": "YSAA",
                                    "CountryId": "CA",
                                    "Location": "-60, 44"
                                }
                            ]
                        },
                        {
                            "Id": "YWQA",
                            "Name": "Chute-Des-Passes",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.253889, 49.887222",
                            "IataCode": "YWQ",
                            "Airports": [
                                {
                                    "Id": "YWQ",
                                    "Name": "Chute-Des-Passes",
                                    "CityId": "YWQA",
                                    "CountryId": "CA",
                                    "Location": "-71.253889, 49.887222"
                                }
                            ]
                        },
                        {
                            "Id": "KEWA",
                            "Name": "Keewaywin",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.838889, 52.992222",
                            "IataCode": "KEW",
                            "Airports": [
                                {
                                    "Id": "KEW",
                                    "Name": "Keewaywin",
                                    "CityId": "KEWA",
                                    "CountryId": "CA",
                                    "Location": "-92.838889, 52.992222"
                                }
                            ]
                        },
                        {
                            "Id": "YCYA",
                            "Name": "Clyde River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.5, 70.416667",
                            "IataCode": "YCY",
                            "Airports": [
                                {
                                    "Id": "YCY",
                                    "Name": "Clyde River",
                                    "CityId": "YCYA",
                                    "CountryId": "CA",
                                    "Location": "-68.5, 70.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YFBA",
                            "Name": "Iqaluit",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.51449, 63.750593",
                            "IataCode": "YFB",
                            "Airports": [
                                {
                                    "Id": "YFB",
                                    "Name": "Iqaluit",
                                    "CityId": "YFBA",
                                    "CountryId": "CA",
                                    "Location": "-68.563889, 63.752222"
                                }
                            ]
                        },
                        {
                            "Id": "YPFA",
                            "Name": "Esquimalt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.4193, 48.449634",
                            "IataCode": "YPF",
                            "Airports": [
                                {
                                    "Id": "YPF",
                                    "Name": "Esquimalt",
                                    "CityId": "YPFA",
                                    "CountryId": "CA",
                                    "Location": "-123.4, 48.433333"
                                }
                            ]
                        },
                        {
                            "Id": "WPCA",
                            "Name": "Pincher Creek",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.95195, 49.48328",
                            "IataCode": "WPC",
                            "Airports": [
                                {
                                    "Id": "WPC",
                                    "Name": "Pincher Creek",
                                    "CityId": "WPCA",
                                    "CountryId": "CA",
                                    "Location": "-113.95, 49.483333"
                                }
                            ]
                        },
                        {
                            "Id": "XBEA",
                            "Name": "Bearskin Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-90.96685, 53.91684",
                            "IataCode": "XBE",
                            "Airports": [
                                {
                                    "Id": "XBE",
                                    "Name": "Bearskin Lake",
                                    "CityId": "XBEA",
                                    "CountryId": "CA",
                                    "Location": "-91.031237, 53.962291"
                                }
                            ]
                        },
                        {
                            "Id": "XGRA",
                            "Name": "Kangiqsualujjuaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.952468, 58.691432",
                            "IataCode": "XGR",
                            "Airports": [
                                {
                                    "Id": "XGR",
                                    "Name": "Kangiqsualujjuaq",
                                    "CityId": "XGRA",
                                    "CountryId": "CA",
                                    "Location": "-65.99267, 58.70973"
                                }
                            ]
                        },
                        {
                            "Id": "XKOA",
                            "Name": "Kemano",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.95337, 53.56639",
                            "IataCode": "XKO",
                            "Airports": [
                                {
                                    "Id": "XKO",
                                    "Name": "Kemano",
                                    "CityId": "XKOA",
                                    "CountryId": "CA",
                                    "Location": "-127.93333, 53.566667"
                                }
                            ]
                        },
                        {
                            "Id": "XKSA",
                            "Name": "Kasabonika",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-88.60777, 53.53094",
                            "IataCode": "XKS",
                            "Airports": [
                                {
                                    "Id": "XKS",
                                    "Name": "Kasabonika",
                                    "CityId": "XKSA",
                                    "CountryId": "CA",
                                    "Location": "-88.646667, 53.52"
                                }
                            ]
                        },
                        {
                            "Id": "XQUA",
                            "Name": "Qualicum",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.43606, 49.346831",
                            "IataCode": "XQU",
                            "Airports": [
                                {
                                    "Id": "XQU",
                                    "Name": "Qualicum",
                                    "CityId": "XQUA",
                                    "CountryId": "CA",
                                    "Location": "-124.45, 49.35"
                                }
                            ]
                        },
                        {
                            "Id": "XRRA",
                            "Name": "Ross River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-132.41203, 61.98962",
                            "IataCode": "XRR",
                            "Airports": [
                                {
                                    "Id": "XRR",
                                    "Name": "Ross River",
                                    "CityId": "XRRA",
                                    "CountryId": "CA",
                                    "Location": "-132.45, 61.983333"
                                }
                            ]
                        },
                        {
                            "Id": "XSIA",
                            "Name": "South Indian Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-98.92811, 56.77949",
                            "IataCode": "XSI",
                            "Airports": [
                                {
                                    "Id": "XSI",
                                    "Name": "South Indian Lake",
                                    "CityId": "XSIA",
                                    "CountryId": "CA",
                                    "Location": "-98.916667, 56.8"
                                }
                            ]
                        },
                        {
                            "Id": "YACA",
                            "Name": "Cat Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-91.81698, 51.72039",
                            "IataCode": "YAC",
                            "Airports": [
                                {
                                    "Id": "YAC",
                                    "Name": "Cat Lake",
                                    "CityId": "YACA",
                                    "CountryId": "CA",
                                    "Location": "-91.822707, 51.726936"
                                }
                            ]
                        },
                        {
                            "Id": "YAGA",
                            "Name": "Fort Frances",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.400298, 48.599876",
                            "IataCode": "YAG",
                            "Airports": [
                                {
                                    "Id": "YAG",
                                    "Name": "Fort Frances",
                                    "CityId": "YAGA",
                                    "CountryId": "CA",
                                    "Location": "-93.447222, 48.652778"
                                }
                            ]
                        },
                        {
                            "Id": "YALA",
                            "Name": "Alert Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.91979, 50.582925",
                            "IataCode": "YAL",
                            "Airports": [
                                {
                                    "Id": "YAL",
                                    "Name": "Alert Bay",
                                    "CityId": "YALA",
                                    "CountryId": "CA",
                                    "Location": "-126.91667, 50.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YATA",
                            "Name": "Attawapiskat",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.41669, 52.92774",
                            "IataCode": "YAT",
                            "Airports": [
                                {
                                    "Id": "YAT",
                                    "Name": "Attawapiskat",
                                    "CityId": "YATA",
                                    "CountryId": "CA",
                                    "Location": "-82.4, 52.941667"
                                }
                            ]
                        },
                        {
                            "Id": "YHZA",
                            "Name": "Halifax",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-63.596849, 44.651986",
                            "IataCode": "YHZ",
                            "Airports": [
                                {
                                    "Id": "YHZ",
                                    "Name": "Halifax International",
                                    "CityId": "YHZA",
                                    "CountryId": "CA",
                                    "Location": "-63.516667, 44.883333"
                                },
                                {
                                    "Id": "YWF",
                                    "Name": "Halifax Dwtown Waterfront H / P",
                                    "CityId": "YHZA",
                                    "CountryId": "CA",
                                    "Location": "-63.583333, 44.633333"
                                },
                                {
                                    "Id": "YAW",
                                    "Name": "Halifax Shearwater",
                                    "CityId": "YHZA",
                                    "CountryId": "CA",
                                    "Location": "-63.616667, 44.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YAYA",
                            "Name": "St Anthony",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-55.581272, 51.36678",
                            "IataCode": "YAY",
                            "Airports": [
                                {
                                    "Id": "YAY",
                                    "Name": "St Anthony",
                                    "CityId": "YAYA",
                                    "CountryId": "CA",
                                    "Location": "-56.08306, 51.39194"
                                }
                            ]
                        },
                        {
                            "Id": "YAZA",
                            "Name": "Tofino",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-125.90315, 49.152071",
                            "IataCode": "YAZ",
                            "Airports": [
                                {
                                    "Id": "YTP",
                                    "Name": "Tofino Seaplane Base",
                                    "CityId": "YAZA",
                                    "CountryId": "CA",
                                    "Location": "-125.40806, 49.154444"
                                },
                                {
                                    "Id": "YAZ",
                                    "Name": "Tofino",
                                    "CityId": "YAZA",
                                    "CountryId": "CA",
                                    "Location": "-125.77639, 49.076944"
                                }
                            ]
                        },
                        {
                            "Id": "YBAA",
                            "Name": "Banff",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.56879, 51.166743",
                            "IataCode": "YBA",
                            "Airports": [
                                {
                                    "Id": "YBA",
                                    "Name": "Banff",
                                    "CityId": "YBAA",
                                    "CountryId": "CA",
                                    "Location": "-115.56667, 51.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YBBA",
                            "Name": "Kugaaruk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.82471, 68.53577",
                            "IataCode": "YBB",
                            "Airports": [
                                {
                                    "Id": "YBB",
                                    "Name": "Kugaaruk",
                                    "CityId": "YBBA",
                                    "CountryId": "CA",
                                    "Location": "-89.808056, 68.544444"
                                }
                            ]
                        },
                        {
                            "Id": "YBCA",
                            "Name": "Baie Comeau",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.14894, 49.216792",
                            "IataCode": "YBC",
                            "Airports": [
                                {
                                    "Id": "YBC",
                                    "Name": "Baie Comeau",
                                    "CityId": "YBCA",
                                    "CountryId": "CA",
                                    "Location": "-68.207222, 49.133056"
                                }
                            ]
                        },
                        {
                            "Id": "YBEA",
                            "Name": "Uranium City",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-108.6183, 59.56707",
                            "IataCode": "YBE",
                            "Airports": [
                                {
                                    "Id": "YBE",
                                    "Name": "Uranium City",
                                    "CityId": "YBEA",
                                    "CountryId": "CA",
                                    "Location": "-108.48333, 59.566667"
                                }
                            ]
                        },
                        {
                            "Id": "YBFA",
                            "Name": "Bamfield",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.13609, 48.83291",
                            "IataCode": "YBF",
                            "Airports": [
                                {
                                    "Id": "YBF",
                                    "Name": "Bamfield",
                                    "CityId": "YBFA",
                                    "CountryId": "CA",
                                    "Location": "-125.13333, 48.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YBKA",
                            "Name": "Baker Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.02123, 64.32009",
                            "IataCode": "YBK",
                            "Airports": [
                                {
                                    "Id": "YBK",
                                    "Name": "Baker Lake",
                                    "CityId": "YBKA",
                                    "CountryId": "CA",
                                    "Location": "-96.083333, 64.3"
                                }
                            ]
                        },
                        {
                            "Id": "YBLA",
                            "Name": "Campbell River",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-125.24459, 50.016339",
                            "IataCode": "YBL",
                            "Airports": [
                                {
                                    "Id": "YBL",
                                    "Name": "Campbell River",
                                    "CityId": "YBLA",
                                    "CountryId": "CA",
                                    "Location": "-125.26556, 49.946389"
                                },
                                {
                                    "Id": "YHH",
                                    "Name": "Campbell River Harbor SPB",
                                    "CityId": "YBLA",
                                    "CountryId": "CA",
                                    "Location": "-125.26556, 49.946389"
                                }
                            ]
                        },
                        {
                            "Id": "YBRA",
                            "Name": "Brandon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-99.953061, 49.846921",
                            "IataCode": "YBR",
                            "Airports": [
                                {
                                    "Id": "YBR",
                                    "Name": "Brandon",
                                    "CityId": "YBRA",
                                    "CountryId": "CA",
                                    "Location": "-99.95, 49.9"
                                }
                            ]
                        },
                        {
                            "Id": "YBYA",
                            "Name": "Bonnyville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.73505, 54.266845",
                            "IataCode": "YBY",
                            "Airports": [
                                {
                                    "Id": "YBY",
                                    "Name": "Bonnyville",
                                    "CityId": "YBYA",
                                    "CountryId": "CA",
                                    "Location": "-110.73333, 54.266667"
                                }
                            ]
                        },
                        {
                            "Id": "YCBA",
                            "Name": "Cambridge Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.05484, 69.11489",
                            "IataCode": "YCB",
                            "Airports": [
                                {
                                    "Id": "YCB",
                                    "Name": "Cambridge Bay",
                                    "CityId": "YCBA",
                                    "CountryId": "CA",
                                    "Location": "-105.1347, 69.09847"
                                }
                            ]
                        },
                        {
                            "Id": "YCEA",
                            "Name": "Centralia",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.46646, 43.28339",
                            "IataCode": "YCE",
                            "Airports": [
                                {
                                    "Id": "YCE",
                                    "Name": "Centralia",
                                    "CityId": "YCEA",
                                    "CountryId": "CA",
                                    "Location": "-81.483333, 43.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YCGA",
                            "Name": "Castlegar",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.66894, 49.299838",
                            "IataCode": "YCG",
                            "Airports": [
                                {
                                    "Id": "YCG",
                                    "Name": "Castlegar",
                                    "CityId": "YCGA",
                                    "CountryId": "CA",
                                    "Location": "-117.63222, 49.295556"
                                }
                            ]
                        },
                        {
                            "Id": "YCHA",
                            "Name": "Miramichi",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.46544, 47.004308",
                            "IataCode": "YCH",
                            "Airports": [
                                {
                                    "Id": "YCH",
                                    "Name": "Miramichi",
                                    "CityId": "YCHA",
                                    "CountryId": "CA",
                                    "Location": "-65, 47.25"
                                }
                            ]
                        },
                        {
                            "Id": "YCMA",
                            "Name": "St Catharines",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.249584, 43.166815",
                            "IataCode": "YCM",
                            "Airports": [
                                {
                                    "Id": "YCM",
                                    "Name": "St Catharines",
                                    "CityId": "YCMA",
                                    "CountryId": "CA",
                                    "Location": "-79.166667, 43.2"
                                }
                            ]
                        },
                        {
                            "Id": "YCNA",
                            "Name": "Cochrane",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.033071, 49.150185",
                            "IataCode": "YCN",
                            "Airports": [
                                {
                                    "Id": "YCN",
                                    "Name": "Cochrane",
                                    "CityId": "YCNA",
                                    "CountryId": "CA",
                                    "Location": "-81.016667, 49.066667"
                                }
                            ]
                        },
                        {
                            "Id": "YCOA",
                            "Name": "Kugluktuk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.09649, 67.82743",
                            "IataCode": "YCO",
                            "Airports": [
                                {
                                    "Id": "YCO",
                                    "Name": "Kugluktuk",
                                    "CityId": "YCOA",
                                    "CountryId": "CA",
                                    "Location": "-115.08333, 67.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YCQA",
                            "Name": "Chetwynd",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.63627, 55.699881",
                            "IataCode": "YCQ",
                            "Airports": [
                                {
                                    "Id": "YCQ",
                                    "Name": "Chetwynd",
                                    "CityId": "YCQA",
                                    "CountryId": "CA",
                                    "Location": "-121.63333, 55.683333"
                                }
                            ]
                        },
                        {
                            "Id": "YCSA",
                            "Name": "Chesterfield Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-90.70119, 63.33955",
                            "IataCode": "YCS",
                            "Airports": [
                                {
                                    "Id": "YCS",
                                    "Name": "Chesterfield Inlet",
                                    "CityId": "YCSA",
                                    "CountryId": "CA",
                                    "Location": "-90.716667, 63.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YCTA",
                            "Name": "Coronation",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.45174, 52.083431",
                            "IataCode": "YCT",
                            "Airports": [
                                {
                                    "Id": "YCT",
                                    "Name": "Coronation",
                                    "CityId": "YCTA",
                                    "CountryId": "CA",
                                    "Location": "-111.41667, 52.1"
                                }
                            ]
                        },
                        {
                            "Id": "YCWA",
                            "Name": "Chilliwack",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.94427, 49.174682",
                            "IataCode": "YCW",
                            "Airports": [
                                {
                                    "Id": "YCW",
                                    "Name": "Chilliwack",
                                    "CityId": "YCWA",
                                    "CountryId": "CA",
                                    "Location": "-121.9, 49.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YCXA",
                            "Name": "Gagetown",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.161662, 45.770142",
                            "IataCode": "YCX",
                            "Airports": [
                                {
                                    "Id": "YCX",
                                    "Name": "Gagetown",
                                    "CityId": "YCXA",
                                    "CountryId": "CA",
                                    "Location": "-66.166667, 45.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YDAA",
                            "Name": "Dawson City",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-139.42629, 64.06252",
                            "IataCode": "YDA",
                            "Airports": [
                                {
                                    "Id": "YDA",
                                    "Name": "Dawson City",
                                    "CityId": "YDAA",
                                    "CountryId": "CA",
                                    "Location": "-139.12083, 64.041667"
                                }
                            ]
                        },
                        {
                            "Id": "YDFA",
                            "Name": "Deer Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.431632, 49.166706",
                            "IataCode": "YDF",
                            "Airports": [
                                {
                                    "Id": "YDF",
                                    "Name": "Deer Lake",
                                    "CityId": "YDFA",
                                    "CountryId": "CA",
                                    "Location": "-57.4, 49.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YDGA",
                            "Name": "Digby",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.765549, 44.61685",
                            "IataCode": "YDG",
                            "Airports": [
                                {
                                    "Id": "YDG",
                                    "Name": "Digby",
                                    "CityId": "YDGA",
                                    "CountryId": "CA",
                                    "Location": "-65.766667, 44.616667"
                                }
                            ]
                        },
                        {
                            "Id": "YDHA",
                            "Name": "Daniels Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.581561, 50.233335",
                            "IataCode": "YDH",
                            "Airports": [
                                {
                                    "Id": "YDH",
                                    "Name": "Daniels Harbour",
                                    "CityId": "YDHA",
                                    "CountryId": "CA",
                                    "Location": "-57.583333, 50.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YCDA",
                            "Name": "Nanaimo",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-123.93601, 49.166337",
                            "IataCode": "YCD",
                            "Airports": [
                                {
                                    "Id": "YCD",
                                    "Name": "Nanaimo",
                                    "CityId": "YCDA",
                                    "CountryId": "CA",
                                    "Location": "-123.86667, 49.05"
                                },
                                {
                                    "Id": "ZNA",
                                    "Name": "Nanaimo Harbour",
                                    "CityId": "YCDA",
                                    "CountryId": "CA",
                                    "Location": "-123.93565, 49.169022"
                                }
                            ]
                        },
                        {
                            "Id": "YDLA",
                            "Name": "Dease Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-129.98749, 58.43569",
                            "IataCode": "YDL",
                            "Airports": [
                                {
                                    "Id": "YDL",
                                    "Name": "Dease Lake",
                                    "CityId": "YDLA",
                                    "CountryId": "CA",
                                    "Location": "-130.03333, 58.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YDNA",
                            "Name": "Dauphin",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-100.05023, 51.149409",
                            "IataCode": "YDN",
                            "Airports": [
                                {
                                    "Id": "YDN",
                                    "Name": "Dauphin",
                                    "CityId": "YDNA",
                                    "CountryId": "CA",
                                    "Location": "-100.05, 51.1"
                                }
                            ]
                        },
                        {
                            "Id": "YDPA",
                            "Name": "Nain",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.67253, 56.54581",
                            "IataCode": "YDP",
                            "Airports": [
                                {
                                    "Id": "YDP",
                                    "Name": "Nain",
                                    "CityId": "YDPA",
                                    "CountryId": "CA",
                                    "Location": "-61.666667, 56.533333"
                                }
                            ]
                        },
                        {
                            "Id": "YDQA",
                            "Name": "Dawson Creek",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-120.23616, 55.766603",
                            "IataCode": "YDQ",
                            "Airports": [
                                {
                                    "Id": "YDQ",
                                    "Name": "Dawson Creek",
                                    "CityId": "YDQA",
                                    "CountryId": "CA",
                                    "Location": "-120.18333, 55.733333"
                                }
                            ]
                        },
                        {
                            "Id": "YDRA",
                            "Name": "Broadview",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-102.58431, 50.366729",
                            "IataCode": "YDR",
                            "Airports": [
                                {
                                    "Id": "YDR",
                                    "Name": "Broadview",
                                    "CityId": "YDRA",
                                    "CountryId": "CA",
                                    "Location": "-102.58333, 50.366667"
                                }
                            ]
                        },
                        {
                            "Id": "YEAA",
                            "Name": "Edmonton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.46871, 53.550137",
                            "IataCode": "YEA",
                            "Airports": [
                                {
                                    "Id": "YEG",
                                    "Name": "Edmonton International",
                                    "CityId": "YEAA",
                                    "CountryId": "CA",
                                    "Location": "-113.57972, 53.309722"
                                }
                            ]
                        },
                        {
                            "Id": "YEKA",
                            "Name": "Arviat",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.05848, 61.10883",
                            "IataCode": "YEK",
                            "Airports": [
                                {
                                    "Id": "YEK",
                                    "Name": "Arviat",
                                    "CityId": "YEKA",
                                    "CountryId": "CA",
                                    "Location": "-94.05, 61.116667"
                                }
                            ]
                        },
                        {
                            "Id": "YELA",
                            "Name": "Elliot Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.633152, 46.383363",
                            "IataCode": "YEL",
                            "Airports": [
                                {
                                    "Id": "YEL",
                                    "Name": "Elliot Lake",
                                    "CityId": "YELA",
                                    "CountryId": "CA",
                                    "Location": "-82.563056, 46.352222"
                                }
                            ]
                        },
                        {
                            "Id": "YENA",
                            "Name": "Estevan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-102.98422, 49.133369",
                            "IataCode": "YEN",
                            "Airports": [
                                {
                                    "Id": "YEN",
                                    "Name": "Estevan",
                                    "CityId": "YENA",
                                    "CountryId": "CA",
                                    "Location": "-102.98333, 49.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YEPA",
                            "Name": "Estevan Point",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.55296, 49.38289",
                            "IataCode": "YEP",
                            "Airports": [
                                {
                                    "Id": "YEP",
                                    "Name": "Estevan Point",
                                    "CityId": "YEPA",
                                    "CountryId": "CA",
                                    "Location": "-126.53333, 49.383333"
                                }
                            ]
                        },
                        {
                            "Id": "YERA",
                            "Name": "Fort Severn",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-87.63317, 55.98352",
                            "IataCode": "YER",
                            "Airports": [
                                {
                                    "Id": "YER",
                                    "Name": "Fort Severn",
                                    "CityId": "YERA",
                                    "CountryId": "CA",
                                    "Location": "-87.833333, 56.033333"
                                }
                            ]
                        },
                        {
                            "Id": "YETA",
                            "Name": "Edson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-116.43559, 53.583445",
                            "IataCode": "YET",
                            "Airports": [
                                {
                                    "Id": "YET",
                                    "Name": "Edson",
                                    "CityId": "YETA",
                                    "CountryId": "CA",
                                    "Location": "-116.46667, 53.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YEVA",
                            "Name": "Inuvik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-133.72181, 68.349864",
                            "IataCode": "YEV",
                            "Airports": [
                                {
                                    "Id": "YEV",
                                    "Name": "Inuvik / Mike Zubko",
                                    "CityId": "YEVA",
                                    "CountryId": "CA",
                                    "Location": "-133.48278, 68.304167"
                                }
                            ]
                        },
                        {
                            "Id": "YFAA",
                            "Name": "Fort Albany",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.66664, 52.19998",
                            "IataCode": "YFA",
                            "Airports": [
                                {
                                    "Id": "YFA",
                                    "Name": "Fort Albany",
                                    "CityId": "YFAA",
                                    "CountryId": "CA",
                                    "Location": "-81.679873, 52.204161"
                                }
                            ]
                        },
                        {
                            "Id": "YFCA",
                            "Name": "Fredericton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.665582, 45.945415",
                            "IataCode": "YFC",
                            "Airports": [
                                {
                                    "Id": "YFC",
                                    "Name": "Fredericton",
                                    "CityId": "YFCA",
                                    "CountryId": "CA",
                                    "Location": "-66.533333, 45.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YFEA",
                            "Name": "Forestville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.08478, 48.73808",
                            "IataCode": "YFE",
                            "Airports": [
                                {
                                    "Id": "YFE",
                                    "Name": "Forestville",
                                    "CityId": "YFEA",
                                    "CountryId": "CA",
                                    "Location": "-69.066667, 48.75"
                                }
                            ]
                        },
                        {
                            "Id": "YFOA",
                            "Name": "Flin Flon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.86497, 54.768237",
                            "IataCode": "YFO",
                            "Airports": [
                                {
                                    "Id": "YFO",
                                    "Name": "Flin Flon",
                                    "CityId": "YFOA",
                                    "CountryId": "CA",
                                    "Location": "-101.68333, 54.683333"
                                }
                            ]
                        },
                        {
                            "Id": "YFSA",
                            "Name": "Fort Simpson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.35159, 61.862693",
                            "IataCode": "YFS",
                            "Airports": [
                                {
                                    "Id": "YFS",
                                    "Name": "Fort Simpson",
                                    "CityId": "YFSA",
                                    "CountryId": "CA",
                                    "Location": "-121.23333, 61.75"
                                }
                            ]
                        },
                        {
                            "Id": "YGHA",
                            "Name": "Fort Good Hope",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.6374, 66.25698",
                            "IataCode": "YGH",
                            "Airports": [
                                {
                                    "Id": "YGH",
                                    "Name": "Fort Good Hope",
                                    "CityId": "YGHA",
                                    "CountryId": "CA",
                                    "Location": "-128.65083, 66.240833"
                                }
                            ]
                        },
                        {
                            "Id": "AKVA",
                            "Name": "Akulivik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.199351, 60.800564",
                            "IataCode": "AKV",
                            "Airports": [
                                {
                                    "Id": "AKV",
                                    "Name": "Akulivik",
                                    "CityId": "AKVA",
                                    "CountryId": "CA",
                                    "Location": "-78.14861, 60.81861"
                                }
                            ]
                        },
                        {
                            "Id": "YGKA",
                            "Name": "Kingston",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.48098, 44.229764",
                            "IataCode": "YGK",
                            "Airports": [
                                {
                                    "Id": "YGK",
                                    "Name": "Kingston",
                                    "CityId": "YGKA",
                                    "CountryId": "CA",
                                    "Location": "-76.598396, 44.223306"
                                }
                            ]
                        },
                        {
                            "Id": "YGMA",
                            "Name": "Gimli",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.990656, 50.633617",
                            "IataCode": "YGM",
                            "Airports": [
                                {
                                    "Id": "YGM",
                                    "Name": "Gimli",
                                    "CityId": "YGMA",
                                    "CountryId": "CA",
                                    "Location": "-97, 50.666667"
                                }
                            ]
                        },
                        {
                            "Id": "YGPA",
                            "Name": "Gaspe",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.481945, 48.833414",
                            "IataCode": "YGP",
                            "Airports": [
                                {
                                    "Id": "YGP",
                                    "Name": "Gaspe",
                                    "CityId": "YGPA",
                                    "CountryId": "CA",
                                    "Location": "-64.478611, 48.775278"
                                }
                            ]
                        },
                        {
                            "Id": "YGQA",
                            "Name": "Geraldton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-86.94842, 49.72667",
                            "IataCode": "YGQ",
                            "Airports": [
                                {
                                    "Id": "YGQ",
                                    "Name": "Geraldton",
                                    "CityId": "YGQA",
                                    "CountryId": "CA",
                                    "Location": "-86.95, 49.733333"
                                }
                            ]
                        },
                        {
                            "Id": "YGTA",
                            "Name": "Igloolik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.79888, 69.38437",
                            "IataCode": "YGT",
                            "Airports": [
                                {
                                    "Id": "YGT",
                                    "Name": "Igloolik",
                                    "CityId": "YGTA",
                                    "CountryId": "CA",
                                    "Location": "-81.816667, 69.4"
                                }
                            ]
                        },
                        {
                            "Id": "YGWA",
                            "Name": "Kuujjuarapik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.749504, 55.283601",
                            "IataCode": "YGW",
                            "Airports": [
                                {
                                    "Id": "YGW",
                                    "Name": "Kuujjuarapik",
                                    "CityId": "YGWA",
                                    "CountryId": "CA",
                                    "Location": "-77.766667, 55.279167"
                                }
                            ]
                        },
                        {
                            "Id": "YGXA",
                            "Name": "Gillam",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.208541, 56.458559",
                            "IataCode": "YGX",
                            "Airports": [
                                {
                                    "Id": "YGX",
                                    "Name": "Gillam",
                                    "CityId": "YGXA",
                                    "CountryId": "CA",
                                    "Location": "-94.7, 56.35"
                                }
                            ]
                        },
                        {
                            "Id": "YGZA",
                            "Name": "Grise Fiord",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.894679, 76.4184",
                            "IataCode": "YGZ",
                            "Airports": [
                                {
                                    "Id": "YGZ",
                                    "Name": "Grise Fiord",
                                    "CityId": "YGZA",
                                    "CountryId": "CA",
                                    "Location": "-82.957222, 76.417778"
                                }
                            ]
                        },
                        {
                            "Id": "YHBA",
                            "Name": "Hudson Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-102.38425, 52.850034",
                            "IataCode": "YHB",
                            "Airports": [
                                {
                                    "Id": "YHB",
                                    "Name": "Hudson Bay",
                                    "CityId": "YHBA",
                                    "CountryId": "CA",
                                    "Location": "-102.41667, 52.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YHDA",
                            "Name": "Dryden",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.75032, 49.783344",
                            "IataCode": "YHD",
                            "Airports": [
                                {
                                    "Id": "YHD",
                                    "Name": "Dryden",
                                    "CityId": "YHDA",
                                    "CountryId": "CA",
                                    "Location": "-92.744167, 49.831667"
                                }
                            ]
                        },
                        {
                            "Id": "YHEA",
                            "Name": "Hope",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.44144, 49.382993",
                            "IataCode": "YHE",
                            "Airports": [
                                {
                                    "Id": "YHE",
                                    "Name": "Hope",
                                    "CityId": "YHEA",
                                    "CountryId": "CA",
                                    "Location": "-121.41667, 49.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YHFA",
                            "Name": "Hearst",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-83.666536, 49.683513",
                            "IataCode": "YHF",
                            "Airports": [
                                {
                                    "Id": "YHF",
                                    "Name": "Hearst",
                                    "CityId": "YHFA",
                                    "CountryId": "CA",
                                    "Location": "-83.683333, 49.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YHKA",
                            "Name": "Gjoa Haven",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-95.87836, 68.62602",
                            "IataCode": "YHK",
                            "Airports": [
                                {
                                    "Id": "YHK",
                                    "Name": "Gjoa Haven",
                                    "CityId": "YHKA",
                                    "CountryId": "CA",
                                    "Location": "-95.878372, 68.637054"
                                }
                            ]
                        },
                        {
                            "Id": "YHNA",
                            "Name": "Hornepayne",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-84.77051, 49.21378",
                            "IataCode": "YHN",
                            "Airports": [
                                {
                                    "Id": "YHN",
                                    "Name": "Hornepayne",
                                    "CityId": "YHNA",
                                    "CountryId": "CA",
                                    "Location": "-84.783333, 49.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YHOA",
                            "Name": "Hopedale",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-60.203189, 55.467372",
                            "IataCode": "YHO",
                            "Airports": [
                                {
                                    "Id": "YHO",
                                    "Name": "Hopedale",
                                    "CityId": "YHOA",
                                    "CountryId": "CA",
                                    "Location": "-60.229444, 55.448333"
                                }
                            ]
                        },
                        {
                            "Id": "YHSA",
                            "Name": "Sechelt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.76943, 49.466355",
                            "IataCode": "YHS",
                            "Airports": [
                                {
                                    "Id": "YHS",
                                    "Name": "Sechelt",
                                    "CityId": "YHSA",
                                    "CountryId": "CA",
                                    "Location": "-123.75, 49.466667"
                                }
                            ]
                        },
                        {
                            "Id": "YHTA",
                            "Name": "Haines Junction",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-137.51082, 60.752157",
                            "IataCode": "YHT",
                            "Airports": [
                                {
                                    "Id": "YHT",
                                    "Name": "Haines Junction",
                                    "CityId": "YHTA",
                                    "CountryId": "CA",
                                    "Location": "-137.5, 60.75"
                                }
                            ]
                        },
                        {
                            "Id": "YMQA",
                            "Name": "Montreal",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-73.604321, 45.522492",
                            "IataCode": "YMQ",
                            "Airports": [
                                {
                                    "Id": "YHU",
                                    "Name": "Montreal St Hubert",
                                    "CityId": "YMQA",
                                    "CountryId": "CA",
                                    "Location": "-73.41667, 45.51667"
                                },
                                {
                                    "Id": "YMX",
                                    "Name": "Montreal Mirabel",
                                    "CityId": "YMQA",
                                    "CountryId": "CA",
                                    "Location": "-74.03, 45.666667"
                                },
                                {
                                    "Id": "YUL",
                                    "Name": "Montreal Pierre Elliott Trudeau",
                                    "CityId": "YMQA",
                                    "CountryId": "CA",
                                    "Location": "-73.75, 45.463889"
                                }
                            ]
                        },
                        {
                            "Id": "CYHY",
                            "Name": "Hay River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.79993, 60.815553",
                            "IataCode": "YHY",
                            "Airports": [
                                {
                                    "Id": "YHY",
                                    "Name": "Hay River",
                                    "CityId": "CYHY",
                                    "CountryId": "CA",
                                    "Location": "-115.78182, 60.841063"
                                }
                            ]
                        },
                        {
                            "Id": "YIBA",
                            "Name": "Atikokan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-91.6216, 48.75551",
                            "IataCode": "YIB",
                            "Airports": [
                                {
                                    "Id": "YIB",
                                    "Name": "Atikokan",
                                    "CityId": "YIBA",
                                    "CountryId": "CA",
                                    "Location": "-91.616667, 48.75"
                                }
                            ]
                        },
                        {
                            "Id": "YIFA",
                            "Name": "Pakuashipi",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-58.67558, 51.22312",
                            "IataCode": "YIF",
                            "Airports": [
                                {
                                    "Id": "YIF",
                                    "Name": "Pakuashipi",
                                    "CityId": "YIFA",
                                    "CountryId": "CA",
                                    "Location": "-58.658964, 51.212352"
                                }
                            ]
                        },
                        {
                            "Id": "YIKA",
                            "Name": "Ivujivik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.915916, 62.417373",
                            "IataCode": "YIK",
                            "Airports": [
                                {
                                    "Id": "YIK",
                                    "Name": "Ivujivik",
                                    "CityId": "YIKA",
                                    "CountryId": "CA",
                                    "Location": "-77.92528, 62.41733"
                                }
                            ]
                        },
                        {
                            "Id": "YIOA",
                            "Name": "Pond Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.959329, 72.70137",
                            "IataCode": "YIO",
                            "Airports": [
                                {
                                    "Id": "YIO",
                                    "Name": "Pond Inlet",
                                    "CityId": "YIOA",
                                    "CountryId": "CA",
                                    "Location": "-78, 72.683333"
                                }
                            ]
                        },
                        {
                            "Id": "YJTA",
                            "Name": "Stephenville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-58.581802, 48.550012",
                            "IataCode": "YJT",
                            "Airports": [
                                {
                                    "Id": "YJT",
                                    "Name": "Stephenville",
                                    "CityId": "YJTA",
                                    "CountryId": "CA",
                                    "Location": "-58.55, 48.533333"
                                }
                            ]
                        },
                        {
                            "Id": "YKAA",
                            "Name": "Kamloops",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-120.3192, 50.666478",
                            "IataCode": "YKA",
                            "Airports": [
                                {
                                    "Id": "YKA",
                                    "Name": "Kamloops",
                                    "CityId": "YKAA",
                                    "CountryId": "CA",
                                    "Location": "-120.43833, 50.699167"
                                }
                            ]
                        },
                        {
                            "Id": "YKDA",
                            "Name": "Kincardine",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.60095, 44.16644",
                            "IataCode": "YKD",
                            "Airports": [
                                {
                                    "Id": "YKD",
                                    "Name": "Kincardine",
                                    "CityId": "YKDA",
                                    "CountryId": "CA",
                                    "Location": "-81.606667, 44.201389"
                                }
                            ]
                        },
                        {
                            "Id": "YKGA",
                            "Name": "Kangirsuk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-70.026263, 60.018527",
                            "IataCode": "YKG",
                            "Airports": [
                                {
                                    "Id": "YKG",
                                    "Name": "Kangirsuk",
                                    "CityId": "YKGA",
                                    "CountryId": "CA",
                                    "Location": "-70, 60"
                                }
                            ]
                        },
                        {
                            "Id": "YKLA",
                            "Name": "Schefferville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.83183, 54.80022",
                            "IataCode": "YKL",
                            "Airports": [
                                {
                                    "Id": "YKL",
                                    "Name": "Schefferville",
                                    "CityId": "YKLA",
                                    "CountryId": "CA",
                                    "Location": "-66.80528, 54.80528"
                                }
                            ]
                        },
                        {
                            "Id": "YKQA",
                            "Name": "Waskaganish",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.766104, 51.200182",
                            "IataCode": "YKQ",
                            "Airports": [
                                {
                                    "Id": "YKQ",
                                    "Name": "Waskaganish",
                                    "CityId": "YKQA",
                                    "CountryId": "CA",
                                    "Location": "-78.75, 51.4875"
                                }
                            ]
                        },
                        {
                            "Id": "YKUA",
                            "Name": "Chisasibi",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.332895, 53.666947",
                            "IataCode": "YKU",
                            "Airports": [
                                {
                                    "Id": "YKU",
                                    "Name": "Chisasibi",
                                    "CityId": "YKUA",
                                    "CountryId": "CA",
                                    "Location": "-79, 53.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YKXA",
                            "Name": "Kirkland Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.033009, 48.150168",
                            "IataCode": "YKX",
                            "Airports": [
                                {
                                    "Id": "YKX",
                                    "Name": "Kirkland Lake",
                                    "CityId": "YKXA",
                                    "CountryId": "CA",
                                    "Location": "-80.033333, 48.15"
                                }
                            ]
                        },
                        {
                            "Id": "YKYA",
                            "Name": "Kindersley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-109.16818, 51.466811",
                            "IataCode": "YKY",
                            "Airports": [
                                {
                                    "Id": "YKY",
                                    "Name": "Kindersley",
                                    "CityId": "YKYA",
                                    "CountryId": "CA",
                                    "Location": "-109.16667, 51.45"
                                }
                            ]
                        },
                        {
                            "Id": "YTOA",
                            "Name": "Toronto",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-79.388494, 43.723899",
                            "IataCode": "YTO",
                            "Airports": [
                                {
                                    "Id": "YKF",
                                    "Name": "Kitchener / Waterloo",
                                    "CityId": "YTOA",
                                    "CountryId": "CA",
                                    "Location": "-80.378611, 43.460833"
                                },
                                {
                                    "Id": "YKZ",
                                    "Name": "Toronto Buttonville",
                                    "CityId": "YTOA",
                                    "CountryId": "CA",
                                    "Location": "-79.366667, 43.866667"
                                },
                                {
                                    "Id": "YTZ",
                                    "Name": "Toronto Island",
                                    "CityId": "YTOA",
                                    "CountryId": "CA",
                                    "Location": "-79.39617, 43.6275"
                                },
                                {
                                    "Id": "YYZ",
                                    "Name": "Toronto Pearson International",
                                    "CityId": "YTOA",
                                    "CountryId": "CA",
                                    "Location": "-79.633333, 43.683333"
                                },
                                {
                                    "Id": "YHM",
                                    "Name": "Hamilton",
                                    "CityId": "YTOA",
                                    "CountryId": "CA",
                                    "Location": "-79.930556, 43.166944"
                                }
                            ]
                        },
                        {
                            "Id": "YLBA",
                            "Name": "Lac Biche",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.96861, 54.766898",
                            "IataCode": "YLB",
                            "Airports": [
                                {
                                    "Id": "YLB",
                                    "Name": "Lac Biche",
                                    "CityId": "YLBA",
                                    "CountryId": "CA",
                                    "Location": "-112.01667, 54.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YLCA",
                            "Name": "Kimmirut / Lake Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.87009, 62.84726",
                            "IataCode": "YLC",
                            "Airports": [
                                {
                                    "Id": "YLC",
                                    "Name": "Kimmirut / Lake Harbour",
                                    "CityId": "YLCA",
                                    "CountryId": "CA",
                                    "Location": "-69.883333, 62.85"
                                }
                            ]
                        },
                        {
                            "Id": "YLDA",
                            "Name": "Chapleau",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-83.4034, 47.84053",
                            "IataCode": "YLD",
                            "Airports": [
                                {
                                    "Id": "YLD",
                                    "Name": "Chapleau",
                                    "CityId": "YLDA",
                                    "CountryId": "CA",
                                    "Location": "-83.352778, 47.819444"
                                }
                            ]
                        },
                        {
                            "Id": "YLJA",
                            "Name": "Meadow Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-108.43471, 54.133479",
                            "IataCode": "YLJ",
                            "Airports": [
                                {
                                    "Id": "YLJ",
                                    "Name": "Meadow Lake",
                                    "CityId": "YLJA",
                                    "CountryId": "CA",
                                    "Location": "-108.66667, 54.5"
                                }
                            ]
                        },
                        {
                            "Id": "YLQA",
                            "Name": "La Tuque",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-72.782405, 47.433371",
                            "IataCode": "YLQ",
                            "Airports": [
                                {
                                    "Id": "YLQ",
                                    "Name": "La Tuque",
                                    "CityId": "YLQA",
                                    "CountryId": "CA",
                                    "Location": "-72.833333, 47.5"
                                }
                            ]
                        },
                        {
                            "Id": "YLRA",
                            "Name": "Leaf Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-99.750579, 56.466879",
                            "IataCode": "YLR",
                            "Airports": [
                                {
                                    "Id": "YLR",
                                    "Name": "Leaf Rapids",
                                    "CityId": "YLRA",
                                    "CountryId": "CA",
                                    "Location": "-100.03333, 56.5"
                                }
                            ]
                        },
                        {
                            "Id": "YLWA",
                            "Name": "Kelowna",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.48567, 49.883074",
                            "IataCode": "YLW",
                            "Airports": [
                                {
                                    "Id": "YLW",
                                    "Name": "Kelowna",
                                    "CityId": "YLWA",
                                    "CountryId": "CA",
                                    "Location": "-119.37778, 49.956111"
                                }
                            ]
                        },
                        {
                            "Id": "YMAA",
                            "Name": "Mayo",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-135.89984, 63.59274",
                            "IataCode": "YMA",
                            "Airports": [
                                {
                                    "Id": "YMA",
                                    "Name": "Mayo",
                                    "CityId": "YMAA",
                                    "CountryId": "CA",
                                    "Location": "-135.87361, 63.616389"
                                }
                            ]
                        },
                        {
                            "Id": "YMEA",
                            "Name": "Matane",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-67.521973, 48.828566",
                            "IataCode": "YME",
                            "Airports": [
                                {
                                    "Id": "YME",
                                    "Name": "Matane",
                                    "CityId": "YMEA",
                                    "CountryId": "CA",
                                    "Location": "-67.516667, 48.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YMGA",
                            "Name": "Manitouwadge",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.8403, 49.12152",
                            "IataCode": "YMG",
                            "Airports": [
                                {
                                    "Id": "YMG",
                                    "Name": "Manitouwadge",
                                    "CityId": "YMGA",
                                    "CountryId": "CA",
                                    "Location": "-85.866667, 49.083333"
                                }
                            ]
                        },
                        {
                            "Id": "YMHA",
                            "Name": "Mary's Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-55.831295, 52.316778",
                            "IataCode": "YMH",
                            "Airports": [
                                {
                                    "Id": "YMH",
                                    "Name": "Mary's Harbour",
                                    "CityId": "YMHA",
                                    "CountryId": "CA",
                                    "Location": "-55.848827, 52.302758"
                                }
                            ]
                        },
                        {
                            "Id": "YMJA",
                            "Name": "Moose Jaw",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.53445, 50.400053",
                            "IataCode": "YMJ",
                            "Airports": [
                                {
                                    "Id": "YMJ",
                                    "Name": "Moose Jaw",
                                    "CityId": "YMJA",
                                    "CountryId": "CA",
                                    "Location": "-105.53333, 50.383333"
                                }
                            ]
                        },
                        {
                            "Id": "YMMA",
                            "Name": "Fort Mcmurray",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.38519, 56.7335",
                            "IataCode": "YMM",
                            "Airports": [
                                {
                                    "Id": "YMM",
                                    "Name": "Fort Mcmurray",
                                    "CityId": "YMMA",
                                    "CountryId": "CA",
                                    "Location": "-111.26667, 56.677778"
                                }
                            ]
                        },
                        {
                            "Id": "YMOA",
                            "Name": "Moosonee",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.716251, 51.316892",
                            "IataCode": "YMO",
                            "Airports": [
                                {
                                    "Id": "YMO",
                                    "Name": "Moosonee",
                                    "CityId": "YMOA",
                                    "CountryId": "CA",
                                    "Location": "-80.604167, 51.291111"
                                }
                            ]
                        },
                        {
                            "Id": "YMTA",
                            "Name": "Chibougamau",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-74.365865, 49.916838",
                            "IataCode": "YMT",
                            "Airports": [
                                {
                                    "Id": "YMT",
                                    "Name": "Chibougamau",
                                    "CityId": "YMTA",
                                    "CountryId": "CA",
                                    "Location": "-74.527795, 49.778424"
                                }
                            ]
                        },
                        {
                            "Id": "YMWA",
                            "Name": "Maniwaki",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-75.966047, 46.38341",
                            "IataCode": "YMW",
                            "Airports": [
                                {
                                    "Id": "YMW",
                                    "Name": "Maniwaki",
                                    "CityId": "YMWA",
                                    "CountryId": "CA",
                                    "Location": "-75.966667, 46.383333"
                                }
                            ]
                        },
                        {
                            "Id": "YNAA",
                            "Name": "Natashquan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.748513, 50.166842",
                            "IataCode": "YNA",
                            "Airports": [
                                {
                                    "Id": "YNA",
                                    "Name": "Natashquan",
                                    "CityId": "YNAA",
                                    "CountryId": "CA",
                                    "Location": "-61.788346, 50.188545"
                                }
                            ]
                        },
                        {
                            "Id": "YNCA",
                            "Name": "Wemindji",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.782937, 52.916933",
                            "IataCode": "YNC",
                            "Airports": [
                                {
                                    "Id": "YNC",
                                    "Name": "Wemindji",
                                    "CityId": "YNCA",
                                    "CountryId": "CA",
                                    "Location": "-78.816667, 53"
                                }
                            ]
                        },
                        {
                            "Id": "YOWA",
                            "Name": "Ottawa",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-75.690286, 45.42094",
                            "IataCode": "YOW",
                            "Airports": [
                                {
                                    "Id": "YRO",
                                    "Name": "Ottawa Rockcliffe St",
                                    "CityId": "YOWA",
                                    "CountryId": "CA",
                                    "Location": "-75.6461, 45.4603"
                                },
                                {
                                    "Id": "YND",
                                    "Name": "Ottawa Gatineau",
                                    "CityId": "YOWA",
                                    "CountryId": "CA",
                                    "Location": "-75.56359, 45.52169"
                                },
                                {
                                    "Id": "YOW",
                                    "Name": "Ottawa International",
                                    "CityId": "YOWA",
                                    "CountryId": "CA",
                                    "Location": "-75.672778, 45.320833"
                                },
                                {
                                    "Id": "XDS",
                                    "Name": "Ottawa Train Station",
                                    "CityId": "YOWA",
                                    "CountryId": "CA",
                                    "Location": "-75.650675, 45.416317"
                                }
                            ]
                        },
                        {
                            "Id": "YNEA",
                            "Name": "Norway House",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.82776, 53.98759",
                            "IataCode": "YNE",
                            "Airports": [
                                {
                                    "Id": "YNE",
                                    "Name": "Norway House",
                                    "CityId": "YNEA",
                                    "CountryId": "CA",
                                    "Location": "-97.845833, 53.954167"
                                }
                            ]
                        },
                        {
                            "Id": "YNHA",
                            "Name": "Hudson's  Hope",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.00305, 55.999894",
                            "IataCode": "YNH",
                            "Airports": [
                                {
                                    "Id": "YNH",
                                    "Name": "Hudson's  Hope",
                                    "CityId": "YNHA",
                                    "CountryId": "CA",
                                    "Location": "-121.9, 56"
                                }
                            ]
                        },
                        {
                            "Id": "YLOA",
                            "Name": "Shilo",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-99.64193, 49.80563",
                            "IataCode": "YLO",
                            "Airports": [
                                {
                                    "Id": "YLO",
                                    "Name": "Shilo",
                                    "CityId": "YLOA",
                                    "CountryId": "CA",
                                    "Location": "-99.638333, 49.781667"
                                }
                            ]
                        },
                        {
                            "Id": "YNMA",
                            "Name": "Matagami",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.632766, 49.750182",
                            "IataCode": "YNM",
                            "Airports": [
                                {
                                    "Id": "YNM",
                                    "Name": "Matagami",
                                    "CityId": "YNMA",
                                    "CountryId": "CA",
                                    "Location": "-77.8, 49.75"
                                }
                            ]
                        },
                        {
                            "Id": "YOCA",
                            "Name": "Old Crow",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-139.83416, 67.57117",
                            "IataCode": "YOC",
                            "Airports": [
                                {
                                    "Id": "YOC",
                                    "Name": "Old Crow",
                                    "CityId": "YOCA",
                                    "CountryId": "CA",
                                    "Location": "-139.83333, 67.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YODA",
                            "Name": "Cold Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.2017, 54.450175",
                            "IataCode": "YOD",
                            "Airports": [
                                {
                                    "Id": "YOD",
                                    "Name": "Cold Lake",
                                    "CityId": "YODA",
                                    "CountryId": "CA",
                                    "Location": "-110.25, 54.466667"
                                }
                            ]
                        },
                        {
                            "Id": "YOJA",
                            "Name": "High Level",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.13605, 58.516875",
                            "IataCode": "YOJ",
                            "Airports": [
                                {
                                    "Id": "YOJ",
                                    "Name": "High Level",
                                    "CityId": "YOJA",
                                    "CountryId": "CA",
                                    "Location": "-117.16833, 58.618333"
                                }
                            ]
                        },
                        {
                            "Id": "QWAA",
                            "Name": "Oshawa",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.84957, 43.90012",
                            "IataCode": "QWA",
                            "Airports": [
                                {
                                    "Id": "YOO",
                                    "Name": "Oshawa",
                                    "CityId": "QWAA",
                                    "CountryId": "CA",
                                    "Location": "-78.9, 43.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YOPA",
                            "Name": "Rainbow Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.38624, 58.500113",
                            "IataCode": "YOP",
                            "Airports": [
                                {
                                    "Id": "YOP",
                                    "Name": "Rainbow Lake",
                                    "CityId": "YOPA",
                                    "CountryId": "CA",
                                    "Location": "-119.4, 58.5"
                                }
                            ]
                        },
                        {
                            "Id": "YOSA",
                            "Name": "Owen Sound",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.933024, 44.56679",
                            "IataCode": "YOS",
                            "Airports": [
                                {
                                    "Id": "YOS",
                                    "Name": "Owen Sound",
                                    "CityId": "YOSA",
                                    "CountryId": "CA",
                                    "Location": "-80.8375, 44.590278"
                                }
                            ]
                        },
                        {
                            "Id": "YPAA",
                            "Name": "Prince Albert",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.76772, 53.200082",
                            "IataCode": "YPA",
                            "Airports": [
                                {
                                    "Id": "YPA",
                                    "Name": "Prince Albert",
                                    "CityId": "YPAA",
                                    "CountryId": "CA",
                                    "Location": "-105.68333, 53.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YPBA",
                            "Name": "Port Alberni",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.8028, 49.241325",
                            "IataCode": "YPB",
                            "Airports": [
                                {
                                    "Id": "YPB",
                                    "Name": "Port Alberni",
                                    "CityId": "YPBA",
                                    "CountryId": "CA",
                                    "Location": "-124.8, 49.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YPCA",
                            "Name": "Paulatuk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.07137, 69.3505",
                            "IataCode": "YPC",
                            "Airports": [
                                {
                                    "Id": "YPC",
                                    "Name": "Paulatuk",
                                    "CityId": "YPCA",
                                    "CountryId": "CA",
                                    "Location": "-124.05861, 69.36111"
                                }
                            ]
                        },
                        {
                            "Id": "YPDA",
                            "Name": "Parry Sound",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.032964, 45.350098",
                            "IataCode": "YPD",
                            "Airports": [
                                {
                                    "Id": "YPD",
                                    "Name": "Parry Sound",
                                    "CityId": "YPDA",
                                    "CountryId": "CA",
                                    "Location": "-79.916667, 45.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YPEA",
                            "Name": "Peace River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.28599, 56.250097",
                            "IataCode": "YPE",
                            "Airports": [
                                {
                                    "Id": "YPE",
                                    "Name": "Peace River",
                                    "CityId": "YPEA",
                                    "CountryId": "CA",
                                    "Location": "-117.44028, 56.227778"
                                }
                            ]
                        },
                        {
                            "Id": "YPHA",
                            "Name": "Inukjuak",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.101394, 58.454679",
                            "IataCode": "YPH",
                            "Airports": [
                                {
                                    "Id": "YPH",
                                    "Name": "Inukjuak",
                                    "CityId": "YPHA",
                                    "CountryId": "CA",
                                    "Location": "-78.166667, 58.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YPJA",
                            "Name": "Aupaluk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.598527, 59.30041",
                            "IataCode": "YPJ",
                            "Airports": [
                                {
                                    "Id": "YPJ",
                                    "Name": "Aupaluk",
                                    "CityId": "YPJA",
                                    "CountryId": "CA",
                                    "Location": "-69.666667, 59.3"
                                }
                            ]
                        },
                        {
                            "Id": "YPLA",
                            "Name": "Pickle Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-90.16248, 51.47903",
                            "IataCode": "YPL",
                            "Airports": [
                                {
                                    "Id": "YPL",
                                    "Name": "Pickle Lake",
                                    "CityId": "YPLA",
                                    "CountryId": "CA",
                                    "Location": "-90.21417, 51.44639"
                                }
                            ]
                        },
                        {
                            "Id": "YPMA",
                            "Name": "Pikangikum",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.99764, 51.81677",
                            "IataCode": "YPM",
                            "Airports": [
                                {
                                    "Id": "YPM",
                                    "Name": "Pikangikum",
                                    "CityId": "YPMA",
                                    "CountryId": "CA",
                                    "Location": "-93.974637, 51.819797"
                                }
                            ]
                        },
                        {
                            "Id": "YPNA",
                            "Name": "Port Menier",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.35199, 49.81844",
                            "IataCode": "YPN",
                            "Airports": [
                                {
                                    "Id": "YPN",
                                    "Name": "Port Menier",
                                    "CityId": "YPNA",
                                    "CountryId": "CA",
                                    "Location": "-64.283333, 49.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YPQA",
                            "Name": "Peterborough",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.316233, 44.300121",
                            "IataCode": "YPQ",
                            "Airports": [
                                {
                                    "Id": "YPQ",
                                    "Name": "Peterborough",
                                    "CityId": "YPQA",
                                    "CountryId": "CA",
                                    "Location": "-78.316667, 44.3"
                                }
                            ]
                        },
                        {
                            "Id": "YPRA",
                            "Name": "Prince Rupert",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-130.32012, 54.31614",
                            "IataCode": "YPR",
                            "Airports": [
                                {
                                    "Id": "YPR",
                                    "Name": "Prince Rupert Digby Island",
                                    "CityId": "YPRA",
                                    "CountryId": "CA",
                                    "Location": "-130.44083, 54.287222"
                                },
                                {
                                    "Id": "ZSW",
                                    "Name": "Prince Rupert Seal Cove",
                                    "CityId": "YPRA",
                                    "CountryId": "CA",
                                    "Location": "-130.28333, 54.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YPSA",
                            "Name": "Port Hawkesbury",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.348534, 45.616845",
                            "IataCode": "YPS",
                            "Airports": [
                                {
                                    "Id": "YPS",
                                    "Name": "Port Hawkesbury",
                                    "CityId": "YPSA",
                                    "CountryId": "CA",
                                    "Location": "-61.35, 45.616667"
                                }
                            ]
                        },
                        {
                            "Id": "YPWA",
                            "Name": "Powell River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.53612, 49.849615",
                            "IataCode": "YPW",
                            "Airports": [
                                {
                                    "Id": "YPW",
                                    "Name": "Powell River",
                                    "CityId": "YPWA",
                                    "CountryId": "CA",
                                    "Location": "-124.50059, 49.834135"
                                }
                            ]
                        },
                        {
                            "Id": "YPYA",
                            "Name": "Fort Chipewyan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.12053, 58.72224",
                            "IataCode": "YPY",
                            "Airports": [
                                {
                                    "Id": "YPY",
                                    "Name": "Fort Chipewyan",
                                    "CityId": "YPYA",
                                    "CountryId": "CA",
                                    "Location": "-111.12083, 58.7625"
                                }
                            ]
                        },
                        {
                            "Id": "YPZA",
                            "Name": "Burns Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.7533, 54.233104",
                            "IataCode": "YPZ",
                            "Airports": [
                                {
                                    "Id": "YPZ",
                                    "Name": "Burns Lake",
                                    "CityId": "YPZA",
                                    "CountryId": "CA",
                                    "Location": "-125.76667, 54.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YQBA",
                            "Name": "Quebec",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-71.214537, 46.812279",
                            "IataCode": "YQB",
                            "Airports": [
                                {
                                    "Id": "XFZ",
                                    "Name": "Quebec Charny",
                                    "CityId": "YQBA",
                                    "CountryId": "CA",
                                    "Location": "-71.25, 46.716667"
                                },
                                {
                                    "Id": "YQB",
                                    "Name": "Quebec",
                                    "CityId": "YQBA",
                                    "CountryId": "CA",
                                    "Location": "-71.38431, 46.79227"
                                }
                            ]
                        },
                        {
                            "Id": "YQDA",
                            "Name": "The Pas",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.25413, 53.825089",
                            "IataCode": "YQD",
                            "Airports": [
                                {
                                    "Id": "YQD",
                                    "Name": "The Pas",
                                    "CityId": "YQDA",
                                    "CountryId": "CA",
                                    "Location": "-101.09111, 53.97139"
                                }
                            ]
                        },
                        {
                            "Id": "YQFA",
                            "Name": "Red Deer",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.802, 52.266817",
                            "IataCode": "YQF",
                            "Airports": [
                                {
                                    "Id": "YQF",
                                    "Name": "Red Deer",
                                    "CityId": "YQFA",
                                    "CountryId": "CA",
                                    "Location": "-113.89333, 52.176944"
                                }
                            ]
                        },
                        {
                            "Id": "YQGA",
                            "Name": "Windsor",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-83.016543, 42.300084",
                            "IataCode": "YQG",
                            "Airports": [
                                {
                                    "Id": "YQG",
                                    "Name": "Windsor",
                                    "CityId": "YQGA",
                                    "CountryId": "CA",
                                    "Location": "-82.960596, 42.266702"
                                }
                            ]
                        },
                        {
                            "Id": "YQHA",
                            "Name": "Watson Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.70893, 60.063488",
                            "IataCode": "YQH",
                            "Airports": [
                                {
                                    "Id": "YQH",
                                    "Name": "Watson Lake",
                                    "CityId": "YQHA",
                                    "CountryId": "CA",
                                    "Location": "-128.825, 60.123611"
                                }
                            ]
                        },
                        {
                            "Id": "YQIA",
                            "Name": "Yarmouth",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.115569, 43.833452",
                            "IataCode": "YQI",
                            "Airports": [
                                {
                                    "Id": "YQI",
                                    "Name": "Yarmouth",
                                    "CityId": "YQIA",
                                    "CountryId": "CA",
                                    "Location": "-66.083333, 43.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YQKA",
                            "Name": "Kenora",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.433733, 49.81671",
                            "IataCode": "YQK",
                            "Airports": [
                                {
                                    "Id": "YQK",
                                    "Name": "Kenora",
                                    "CityId": "YQKA",
                                    "CountryId": "CA",
                                    "Location": "-94.358333, 49.7875"
                                }
                            ]
                        },
                        {
                            "Id": "YQLA",
                            "Name": "Lethbridge",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-112.81856, 49.699993",
                            "IataCode": "YQL",
                            "Airports": [
                                {
                                    "Id": "YQL",
                                    "Name": "Lethbridge",
                                    "CityId": "YQLA",
                                    "CountryId": "CA",
                                    "Location": "-112.79167, 49.631944"
                                }
                            ]
                        },
                        {
                            "Id": "MONB",
                            "Name": "Moncton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.801862, 46.115943",
                            "IataCode": "YQM",
                            "Airports": [
                                {
                                    "Id": "YQM",
                                    "Name": "Moncton",
                                    "CityId": "MONB",
                                    "CountryId": "CA",
                                    "Location": "-64.670001, 46.11"
                                }
                            ]
                        },
                        {
                            "Id": "YQNA",
                            "Name": "Nakina",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-86.71071, 50.17893",
                            "IataCode": "YQN",
                            "Airports": [
                                {
                                    "Id": "YQN",
                                    "Name": "Nakina",
                                    "CityId": "YQNA",
                                    "CountryId": "CA",
                                    "Location": "-86.7, 50.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YQQA",
                            "Name": "Comox",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.93613, 49.682941",
                            "IataCode": "YQQ",
                            "Airports": [
                                {
                                    "Id": "YQQ",
                                    "Name": "Comox",
                                    "CityId": "YQQA",
                                    "CountryId": "CA",
                                    "Location": "-124.89333, 49.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YQRA",
                            "Name": "Regina",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-104.6178, 50.450081",
                            "IataCode": "YQR",
                            "Airports": [
                                {
                                    "Id": "YQR",
                                    "Name": "Regina",
                                    "CityId": "YQRA",
                                    "CountryId": "CA",
                                    "Location": "-104.65333, 50.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YQSA",
                            "Name": "St Thomas",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.199736, 42.783391",
                            "IataCode": "YQS",
                            "Airports": [
                                {
                                    "Id": "YQS",
                                    "Name": "St Thomas",
                                    "CityId": "YQSA",
                                    "CountryId": "CA",
                                    "Location": "-81.2, 42.783333"
                                }
                            ]
                        },
                        {
                            "Id": "YOEA",
                            "Name": "Falher",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.20262, 55.733385",
                            "IataCode": "YOE",
                            "Airports": [
                                {
                                    "Id": "YOE",
                                    "Name": "Falher",
                                    "CityId": "YOEA",
                                    "CountryId": "CA",
                                    "Location": "-117.25, 55.733333"
                                }
                            ]
                        },
                        {
                            "Id": "YQTA",
                            "Name": "Thunder Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.316825, 48.400096",
                            "IataCode": "YQT",
                            "Airports": [
                                {
                                    "Id": "YQT",
                                    "Name": "Thunder Bay",
                                    "CityId": "YQTA",
                                    "CountryId": "CA",
                                    "Location": "-89.323889, 48.371944"
                                }
                            ]
                        },
                        {
                            "Id": "YQUA",
                            "Name": "Grande Prairie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-118.80271, 55.166673",
                            "IataCode": "YQU",
                            "Airports": [
                                {
                                    "Id": "YQU",
                                    "Name": "Grande Prairie",
                                    "CityId": "YQUA",
                                    "CountryId": "CA",
                                    "Location": "-118.89445, 55.184167"
                                }
                            ]
                        },
                        {
                            "Id": "YQVA",
                            "Name": "Yorkton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-102.46766, 51.216703",
                            "IataCode": "YQV",
                            "Airports": [
                                {
                                    "Id": "YQV",
                                    "Name": "Yorkton",
                                    "CityId": "YQVA",
                                    "CountryId": "CA",
                                    "Location": "-102.46667, 51.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YQWA",
                            "Name": "North Battleford",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-108.28465, 52.783437",
                            "IataCode": "YQW",
                            "Airports": [
                                {
                                    "Id": "YQW",
                                    "Name": "North Battleford",
                                    "CityId": "YQWA",
                                    "CountryId": "CA",
                                    "Location": "-108.25, 52.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YQXA",
                            "Name": "Gander",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-54.590641, 48.968217",
                            "IataCode": "YQX",
                            "Airports": [
                                {
                                    "Id": "YQX",
                                    "Name": "Gander",
                                    "CityId": "YQXA",
                                    "CountryId": "CA",
                                    "Location": "-54.563333, 48.946667"
                                }
                            ]
                        },
                        {
                            "Id": "YQYA",
                            "Name": "Sydney",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-60.1831, 46.1351",
                            "IataCode": "YQY",
                            "Airports": [
                                {
                                    "Id": "YQY",
                                    "Name": "J.A. Douglas McCurdy Sydney",
                                    "CityId": "YQYA",
                                    "CountryId": "CA",
                                    "Location": "-60.05, 46.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YQZA",
                            "Name": "Quesnel",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.50289, 52.999882",
                            "IataCode": "YQZ",
                            "Airports": [
                                {
                                    "Id": "YQZ",
                                    "Name": "Quesnel",
                                    "CityId": "YQZA",
                                    "CountryId": "CA",
                                    "Location": "-122.50972, 53.026667"
                                }
                            ]
                        },
                        {
                            "Id": "YRGA",
                            "Name": "Rigolet",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-58.43439, 54.17012",
                            "IataCode": "YRG",
                            "Airports": [
                                {
                                    "Id": "YRG",
                                    "Name": "Rigolet",
                                    "CityId": "YRGA",
                                    "CountryId": "CA",
                                    "Location": "-58.416667, 54.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YRIA",
                            "Name": "Riviere Du Loup",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.534187, 47.830444",
                            "IataCode": "YRI",
                            "Airports": [
                                {
                                    "Id": "YRI",
                                    "Name": "Riviere Du Loup",
                                    "CityId": "YRIA",
                                    "CountryId": "CA",
                                    "Location": "-69.533333, 47.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YRJA",
                            "Name": "Roberval",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-72.232444, 48.516795",
                            "IataCode": "YRJ",
                            "Airports": [
                                {
                                    "Id": "YRJ",
                                    "Name": "Roberval",
                                    "CityId": "YRJA",
                                    "CountryId": "CA",
                                    "Location": "-72.216667, 48.516667"
                                }
                            ]
                        },
                        {
                            "Id": "YRLA",
                            "Name": "Red Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.733732, 51.066758",
                            "IataCode": "YRL",
                            "Airports": [
                                {
                                    "Id": "YRL",
                                    "Name": "Red Lake",
                                    "CityId": "YRLA",
                                    "CountryId": "CA",
                                    "Location": "-93.816667, 51.05"
                                }
                            ]
                        },
                        {
                            "Id": "YRMA",
                            "Name": "Rocky Mountain House",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.9188, 52.366826",
                            "IataCode": "YRM",
                            "Airports": [
                                {
                                    "Id": "YRM",
                                    "Name": "Rocky Mountain House",
                                    "CityId": "YRMA",
                                    "CountryId": "CA",
                                    "Location": "-114.91667, 52.366667"
                                }
                            ]
                        },
                        {
                            "Id": "YRQA",
                            "Name": "Trois-Rivieres",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-72.54912, 46.350061",
                            "IataCode": "YRQ",
                            "Airports": [
                                {
                                    "Id": "YRQ",
                                    "Name": "Trois-Rivieres",
                                    "CityId": "YRQA",
                                    "CountryId": "CA",
                                    "Location": "-72.683333, 46.35"
                                }
                            ]
                        },
                        {
                            "Id": "YRTA",
                            "Name": "Rankin Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.08324, 62.81732",
                            "IataCode": "YRT",
                            "Airports": [
                                {
                                    "Id": "YRT",
                                    "Name": "Rankin Inlet",
                                    "CityId": "YRTA",
                                    "CountryId": "CA",
                                    "Location": "-92.108774, 62.808519"
                                }
                            ]
                        },
                        {
                            "Id": "YRVA",
                            "Name": "Revelstoke",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-118.2023, 50.983167",
                            "IataCode": "YRV",
                            "Airports": [
                                {
                                    "Id": "YRV",
                                    "Name": "Revelstoke",
                                    "CityId": "YRVA",
                                    "CountryId": "CA",
                                    "Location": "-118.16667, 51"
                                }
                            ]
                        },
                        {
                            "Id": "YSBA",
                            "Name": "Sudbury",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.990009, 46.490002",
                            "IataCode": "YSB",
                            "Airports": [
                                {
                                    "Id": "YSB",
                                    "Name": "Sudbury",
                                    "CityId": "YSBA",
                                    "CountryId": "CA",
                                    "Location": "-80.8, 46.616667"
                                }
                            ]
                        },
                        {
                            "Id": "YSCA",
                            "Name": "Sherbrooke",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.899077, 45.400083",
                            "IataCode": "YSC",
                            "Airports": [
                                {
                                    "Id": "YSC",
                                    "Name": "Sherbrooke",
                                    "CityId": "YSCA",
                                    "CountryId": "CA",
                                    "Location": "-71.9, 45.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YSFA",
                            "Name": "Stony Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.83509, 59.26035",
                            "IataCode": "YSF",
                            "Airports": [
                                {
                                    "Id": "YSF",
                                    "Name": "Stony Rapids",
                                    "CityId": "YSFA",
                                    "CountryId": "CA",
                                    "Location": "-105.83333, 59.25"
                                }
                            ]
                        },
                        {
                            "Id": "YSJA",
                            "Name": "Saint John",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.037745, 45.25935",
                            "IataCode": "YSJ",
                            "Airports": [
                                {
                                    "Id": "YSJ",
                                    "Name": "Saint John",
                                    "CityId": "YSJA",
                                    "CountryId": "CA",
                                    "Location": "-65.883333, 45.316667"
                                }
                            ]
                        },
                        {
                            "Id": "YSKA",
                            "Name": "Sanikiluaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.23306, 56.53366",
                            "IataCode": "YSK",
                            "Airports": [
                                {
                                    "Id": "YSK",
                                    "Name": "Sanikiluaq",
                                    "CityId": "YSKA",
                                    "CountryId": "CA",
                                    "Location": "-79.216667, 56.55"
                                }
                            ]
                        },
                        {
                            "Id": "YSMA",
                            "Name": "Fort Smith",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.88543, 60.000291",
                            "IataCode": "YSM",
                            "Airports": [
                                {
                                    "Id": "YSM",
                                    "Name": "Fort Smith",
                                    "CityId": "YSMA",
                                    "CountryId": "CA",
                                    "Location": "-111.96667, 60.016667"
                                }
                            ]
                        },
                        {
                            "Id": "YSNA",
                            "Name": "Salmon Arm",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.30237, 50.699805",
                            "IataCode": "YSN",
                            "Airports": [
                                {
                                    "Id": "YSN",
                                    "Name": "Salmon Arm",
                                    "CityId": "YSNA",
                                    "CountryId": "CA",
                                    "Location": "-119.23333, 50.683333"
                                }
                            ]
                        },
                        {
                            "Id": "YSOA",
                            "Name": "Postville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-59.79446, 54.89793",
                            "IataCode": "YSO",
                            "Airports": [
                                {
                                    "Id": "YSO",
                                    "Name": "Postville",
                                    "CityId": "YSOA",
                                    "CountryId": "CA",
                                    "Location": "-59.966667, 54.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YSPA",
                            "Name": "Marathon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-86.433218, 48.750099",
                            "IataCode": "YSP",
                            "Airports": [
                                {
                                    "Id": "YSP",
                                    "Name": "Marathon",
                                    "CityId": "YSPA",
                                    "CountryId": "CA",
                                    "Location": "-86.35, 48.75"
                                }
                            ]
                        },
                        {
                            "Id": "YSYA",
                            "Name": "Sachs Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.2463, 71.98409",
                            "IataCode": "YSY",
                            "Airports": [
                                {
                                    "Id": "YSY",
                                    "Name": "Sachs Harbour",
                                    "CityId": "YSYA",
                                    "CountryId": "CA",
                                    "Location": "-125.24689, 71.99088"
                                }
                            ]
                        },
                        {
                            "Id": "YTAA",
                            "Name": "Pembroke",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.116155, 45.816814",
                            "IataCode": "YTA",
                            "Airports": [
                                {
                                    "Id": "YTA",
                                    "Name": "Pembroke",
                                    "CityId": "YTAA",
                                    "CountryId": "CA",
                                    "Location": "-77.249444, 45.861667"
                                }
                            ]
                        },
                        {
                            "Id": "YTEA",
                            "Name": "Cape Dorset",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.54372, 64.23378",
                            "IataCode": "YTE",
                            "Airports": [
                                {
                                    "Id": "YTE",
                                    "Name": "Cape Dorset",
                                    "CityId": "YTEA",
                                    "CountryId": "CA",
                                    "Location": "-76.533333, 64.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YTFA",
                            "Name": "Alma",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.6491, 48.550093",
                            "IataCode": "YTF",
                            "Airports": [
                                {
                                    "Id": "YTF",
                                    "Name": "Alma",
                                    "CityId": "YTFA",
                                    "CountryId": "CA",
                                    "Location": "-71.666667, 48.533333"
                                }
                            ]
                        },
                        {
                            "Id": "YTGA",
                            "Name": "Sullivan Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.81979, 50.88293",
                            "IataCode": "YTG",
                            "Airports": [
                                {
                                    "Id": "YTG",
                                    "Name": "Sullivan Bay",
                                    "CityId": "YTGA",
                                    "CountryId": "CA",
                                    "Location": "-126.8, 50.883333"
                                }
                            ]
                        },
                        {
                            "Id": "YTHA",
                            "Name": "Thompson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.85579, 55.743504",
                            "IataCode": "YTH",
                            "Airports": [
                                {
                                    "Id": "YTH",
                                    "Name": "Thompson",
                                    "CityId": "YTHA",
                                    "CountryId": "CA",
                                    "Location": "-97.866667, 55.8"
                                }
                            ]
                        },
                        {
                            "Id": "YTJA",
                            "Name": "Terrace Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-87.09996, 48.78341",
                            "IataCode": "YTJ",
                            "Airports": [
                                {
                                    "Id": "YTJ",
                                    "Name": "Terrace Bay",
                                    "CityId": "YTJA",
                                    "CountryId": "CA",
                                    "Location": "-87.166667, 48.75"
                                }
                            ]
                        },
                        {
                            "Id": "YTMA",
                            "Name": "Mont Tremblant",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-74.626436, 46.194923",
                            "IataCode": "YTM",
                            "Airports": [
                                {
                                    "Id": "YTM",
                                    "Name": "Mont Tremblant",
                                    "CityId": "YTMA",
                                    "CountryId": "CA",
                                    "Location": "-74.755278, 46.515556"
                                }
                            ]
                        },
                        {
                            "Id": "YTNA",
                            "Name": "Riviere Au Tonnerre",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.782041, 50.266816",
                            "IataCode": "YTN",
                            "Airports": [
                                {
                                    "Id": "YTN",
                                    "Name": "Riviere Au Tonnerre",
                                    "CityId": "YTNA",
                                    "CountryId": "CA",
                                    "Location": "-64.75, 50.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YTSA",
                            "Name": "Timmins",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.333124, 48.466864",
                            "IataCode": "YTS",
                            "Airports": [
                                {
                                    "Id": "YTS",
                                    "Name": "Timmins",
                                    "CityId": "YTSA",
                                    "CountryId": "CA",
                                    "Location": "-81.366667, 48.566667"
                                }
                            ]
                        },
                        {
                            "Id": "YTTA",
                            "Name": "Tisdale",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-104.05096, 52.850022",
                            "IataCode": "YTT",
                            "Airports": [
                                {
                                    "Id": "YTT",
                                    "Name": "Tisdale",
                                    "CityId": "YTTA",
                                    "CountryId": "CA",
                                    "Location": "-104.06667, 52.85"
                                }
                            ]
                        },
                        {
                            "Id": "YUBA",
                            "Name": "Tuktoyaktuk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-133.03741, 69.45414",
                            "IataCode": "YUB",
                            "Airports": [
                                {
                                    "Id": "YUB",
                                    "Name": "Tuktoyaktuk",
                                    "CityId": "YUBA",
                                    "CountryId": "CA",
                                    "Location": "-133.03448, 69.429103"
                                }
                            ]
                        },
                        {
                            "Id": "YUDA",
                            "Name": "Umiujaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.552201, 56.555354",
                            "IataCode": "YUD",
                            "Airports": [
                                {
                                    "Id": "YUD",
                                    "Name": "Umiujaq",
                                    "CityId": "YUDA",
                                    "CountryId": "CA",
                                    "Location": "-76.55, 56.55"
                                }
                            ]
                        },
                        {
                            "Id": "YUTA",
                            "Name": "Repulse Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-86.24919, 66.53417",
                            "IataCode": "YUT",
                            "Airports": [
                                {
                                    "Id": "YUT",
                                    "Name": "Repulse Bay",
                                    "CityId": "YUTA",
                                    "CountryId": "CA",
                                    "Location": "-86.229873, 66.524748"
                                }
                            ]
                        },
                        {
                            "Id": "YUXA",
                            "Name": "Hall Beach",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.23598, 68.79134",
                            "IataCode": "YUX",
                            "Airports": [
                                {
                                    "Id": "YUX",
                                    "Name": "Hall Beach",
                                    "CityId": "YUXA",
                                    "CountryId": "CA",
                                    "Location": "-81.25, 68.783333"
                                }
                            ]
                        },
                        {
                            "Id": "YUYA",
                            "Name": "Rouyn",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.028778, 48.239852",
                            "IataCode": "YUY",
                            "Airports": [
                                {
                                    "Id": "YUY",
                                    "Name": "Rouyn",
                                    "CityId": "YUYA",
                                    "CountryId": "CA",
                                    "Location": "-78.830556, 48.205556"
                                }
                            ]
                        },
                        {
                            "Id": "YVBA",
                            "Name": "Bonaventure",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.49259, 48.04573",
                            "IataCode": "YVB",
                            "Airports": [
                                {
                                    "Id": "YVB",
                                    "Name": "Bonaventure",
                                    "CityId": "YVBA",
                                    "CountryId": "CA",
                                    "Location": "-65.483333, 48.05"
                                }
                            ]
                        },
                        {
                            "Id": "YVCA",
                            "Name": "La Ronge",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.28422, 55.100129",
                            "IataCode": "YVC",
                            "Airports": [
                                {
                                    "Id": "YVC",
                                    "Name": "La Ronge",
                                    "CityId": "YVCA",
                                    "CountryId": "CA",
                                    "Location": "-105.26194, 55.151389"
                                }
                            ]
                        },
                        {
                            "Id": "YVEA",
                            "Name": "Vernon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.26905, 50.258089",
                            "IataCode": "YVE",
                            "Airports": [
                                {
                                    "Id": "YVE",
                                    "Name": "Vernon",
                                    "CityId": "YVEA",
                                    "CountryId": "CA",
                                    "Location": "-119.33333, 50.25"
                                }
                            ]
                        },
                        {
                            "Id": "YVGA",
                            "Name": "Vermilion",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.85166, 53.366856",
                            "IataCode": "YVG",
                            "Airports": [
                                {
                                    "Id": "YVG",
                                    "Name": "Vermilion",
                                    "CityId": "YVGA",
                                    "CountryId": "CA",
                                    "Location": "-110.81667, 53.35"
                                }
                            ]
                        },
                        {
                            "Id": "YVMA",
                            "Name": "Qikiqtarjuaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.02493, 67.55546",
                            "IataCode": "YVM",
                            "Airports": [
                                {
                                    "Id": "YVM",
                                    "Name": "Qikiqtarjuaq",
                                    "CityId": "YVMA",
                                    "CountryId": "CA",
                                    "Location": "-63.833333, 67.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YVPA",
                            "Name": "Kuujjuaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.398488, 58.100307",
                            "IataCode": "YVP",
                            "Airports": [
                                {
                                    "Id": "YVP",
                                    "Name": "Kuujjuaq",
                                    "CityId": "YVPA",
                                    "CountryId": "CA",
                                    "Location": "-68.406667, 58.101944"
                                }
                            ]
                        },
                        {
                            "Id": "YVQA",
                            "Name": "Norman Wells",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.8329, 65.28201",
                            "IataCode": "YVQ",
                            "Airports": [
                                {
                                    "Id": "YVQ",
                                    "Name": "Norman Wells",
                                    "CityId": "YVQA",
                                    "CountryId": "CA",
                                    "Location": "-126.8, 65.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YVRA",
                            "Name": "Vancouver",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-123.151, 49.241508",
                            "IataCode": "YVR",
                            "Airports": [
                                {
                                    "Id": "CXH",
                                    "Name": "Vancouver Coal Harbour",
                                    "CityId": "YVRA",
                                    "CountryId": "CA",
                                    "Location": "-123.10404, 49.29851"
                                },
                                {
                                    "Id": "YDT",
                                    "Name": "Vancouver Boundary Bay",
                                    "CityId": "YVRA",
                                    "CountryId": "CA",
                                    "Location": "-123.18333, 49.183333"
                                },
                                {
                                    "Id": "YVR",
                                    "Name": "Vancouver International",
                                    "CityId": "YVRA",
                                    "CountryId": "CA",
                                    "Location": "-123.18528, 49.187222"
                                },
                                {
                                    "Id": "YXX",
                                    "Name": "Abbotsford",
                                    "CityId": "YVRA",
                                    "CountryId": "CA",
                                    "Location": "-122.36194, 49.025556"
                                }
                            ]
                        },
                        {
                            "Id": "YVTA",
                            "Name": "Buffalo Narrows",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-108.48475, 55.850174",
                            "IataCode": "YVT",
                            "Airports": [
                                {
                                    "Id": "YVT",
                                    "Name": "Buffalo Narrows",
                                    "CityId": "YVTA",
                                    "CountryId": "CA",
                                    "Location": "-108.46667, 55.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YWAA",
                            "Name": "Petawawa",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.332773, 45.900117",
                            "IataCode": "YWA",
                            "Airports": [
                                {
                                    "Id": "YWA",
                                    "Name": "Petawawa",
                                    "CityId": "YWAA",
                                    "CountryId": "CA",
                                    "Location": "-77.283333, 45.9"
                                }
                            ]
                        },
                        {
                            "Id": "YWBA",
                            "Name": "Kangiqsujuaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.96026, 61.598517",
                            "IataCode": "YWB",
                            "Airports": [
                                {
                                    "Id": "YWB",
                                    "Name": "Kangiqsujuaq",
                                    "CityId": "YWBA",
                                    "CountryId": "CA",
                                    "Location": "-71.95, 61.6"
                                }
                            ]
                        },
                        {
                            "Id": "WINB",
                            "Name": "Winnipeg",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.147045, 49.884402",
                            "IataCode": "YWG",
                            "Airports": [
                                {
                                    "Id": "YWG",
                                    "Name": "Winnipeg",
                                    "CityId": "WINB",
                                    "CountryId": "CA",
                                    "Location": "-97.238961, 49.908124"
                                }
                            ]
                        },
                        {
                            "Id": "YYJA",
                            "Name": "Victoria",
                            "SingleAirportCity": false,
                            "CountryId": "CA",
                            "Location": "-123.3693, 48.432939",
                            "IataCode": "YYJ",
                            "Airports": [
                                {
                                    "Id": "YWH",
                                    "Name": "Victoria Inner Harbor",
                                    "CityId": "YYJA",
                                    "CountryId": "CA",
                                    "Location": "-123.37063, 48.423989"
                                },
                                {
                                    "Id": "YYJ",
                                    "Name": "Victoria International",
                                    "CityId": "YYJA",
                                    "CountryId": "CA",
                                    "Location": "-123.42361, 48.648611"
                                }
                            ]
                        },
                        {
                            "Id": "YWJA",
                            "Name": "Deline",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.40565, 65.19844",
                            "IataCode": "YWJ",
                            "Airports": [
                                {
                                    "Id": "YWJ",
                                    "Name": "Deline",
                                    "CityId": "YWJA",
                                    "CountryId": "CA",
                                    "Location": "-123.41667, 65.183333"
                                }
                            ]
                        },
                        {
                            "Id": "YWKA",
                            "Name": "Wabush",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.865407, 52.900151",
                            "IataCode": "YWK",
                            "Airports": [
                                {
                                    "Id": "YWK",
                                    "Name": "Wabush",
                                    "CityId": "YWKA",
                                    "CountryId": "CA",
                                    "Location": "-66.9, 52.95"
                                }
                            ]
                        },
                        {
                            "Id": "YWLA",
                            "Name": "Williams Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.14451, 52.141528",
                            "IataCode": "YWL",
                            "Airports": [
                                {
                                    "Id": "YWL",
                                    "Name": "Williams Lake",
                                    "CityId": "YWLA",
                                    "CountryId": "CA",
                                    "Location": "-122.04444, 52.184722"
                                }
                            ]
                        },
                        {
                            "Id": "YWRA",
                            "Name": "White River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.27201, 48.592241",
                            "IataCode": "YWR",
                            "Airports": [
                                {
                                    "Id": "YWR",
                                    "Name": "White River",
                                    "CityId": "YWRA",
                                    "CountryId": "CA",
                                    "Location": "-85.333333, 48.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YWSA",
                            "Name": "Whistler",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.96946, 50.116396",
                            "IataCode": "YWS",
                            "Airports": [
                                {
                                    "Id": "YWS",
                                    "Name": "Whistler",
                                    "CityId": "YWSA",
                                    "CountryId": "CA",
                                    "Location": "-122.96667, 50.15"
                                }
                            ]
                        },
                        {
                            "Id": "YWYA",
                            "Name": "Wrigley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.498, 63.25001",
                            "IataCode": "YWY",
                            "Airports": [
                                {
                                    "Id": "YWY",
                                    "Name": "Wrigley",
                                    "CityId": "YWYA",
                                    "CountryId": "CA",
                                    "Location": "-123.43667, 63.209722"
                                }
                            ]
                        },
                        {
                            "Id": "YXCA",
                            "Name": "Cranbrook",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.76879, 49.499912",
                            "IataCode": "YXC",
                            "Airports": [
                                {
                                    "Id": "YXC",
                                    "Name": "Cranbrook",
                                    "CityId": "YXCA",
                                    "CountryId": "CA",
                                    "Location": "-115.78194, 49.61222"
                                }
                            ]
                        },
                        {
                            "Id": "YWNA",
                            "Name": "Winisk",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.19967, 55.26688",
                            "IataCode": "YWN",
                            "Airports": [
                                {
                                    "Id": "YWN",
                                    "Name": "Winisk",
                                    "CityId": "YWNA",
                                    "CountryId": "CA",
                                    "Location": "-85.116667, 55.25"
                                }
                            ]
                        },
                        {
                            "Id": "YXEA",
                            "Name": "Saskatoon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-106.63452, 52.116788",
                            "IataCode": "YXE",
                            "Airports": [
                                {
                                    "Id": "YXE",
                                    "Name": "Saskatoon",
                                    "CityId": "YXEA",
                                    "CountryId": "CA",
                                    "Location": "-106.68333, 52.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YXHA",
                            "Name": "Medicine Hat",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.66834, 50.05006",
                            "IataCode": "YXH",
                            "Airports": [
                                {
                                    "Id": "YXH",
                                    "Name": "Medicine Hat",
                                    "CityId": "YXHA",
                                    "CountryId": "CA",
                                    "Location": "-110.72083, 50.015833"
                                }
                            ]
                        },
                        {
                            "Id": "YXJA",
                            "Name": "Fort St John",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-120.85292, 56.249884",
                            "IataCode": "YXJ",
                            "Airports": [
                                {
                                    "Id": "YXJ",
                                    "Name": "Fort St John",
                                    "CityId": "YXJA",
                                    "CountryId": "CA",
                                    "Location": "-120.73333, 56.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YXKA",
                            "Name": "Rimouski",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.52396, 48.448788",
                            "IataCode": "YXK",
                            "Airports": [
                                {
                                    "Id": "YXK",
                                    "Name": "Rimouski",
                                    "CityId": "YXKA",
                                    "CountryId": "CA",
                                    "Location": "-68.55, 48.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YXLA",
                            "Name": "Sioux Lookout",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-91.983577, 50.066764",
                            "IataCode": "YXL",
                            "Airports": [
                                {
                                    "Id": "YXL",
                                    "Name": "Sioux Lookout",
                                    "CityId": "YXLA",
                                    "CountryId": "CA",
                                    "Location": "-91.902778, 50.116667"
                                }
                            ]
                        },
                        {
                            "Id": "YXNA",
                            "Name": "Whale Cove",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.57782, 62.17138",
                            "IataCode": "YXN",
                            "Airports": [
                                {
                                    "Id": "YXN",
                                    "Name": "Whale Cove",
                                    "CityId": "YXNA",
                                    "CountryId": "CA",
                                    "Location": "-92.6, 62.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YXPA",
                            "Name": "Pangnirtung",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.71252, 66.14511",
                            "IataCode": "YXP",
                            "Airports": [
                                {
                                    "Id": "YXP",
                                    "Name": "Pangnirtung",
                                    "CityId": "YXPA",
                                    "CountryId": "CA",
                                    "Location": "-65.733333, 66.133333"
                                }
                            ]
                        },
                        {
                            "Id": "YXQA",
                            "Name": "Beaver Creek",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-140.87925, 62.38266",
                            "IataCode": "YXQ",
                            "Airports": [
                                {
                                    "Id": "YXQ",
                                    "Name": "Beaver Creek",
                                    "CityId": "YXQA",
                                    "CountryId": "CA",
                                    "Location": "-141, 62"
                                }
                            ]
                        },
                        {
                            "Id": "YXSA",
                            "Name": "Prince George",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.75301, 53.916603",
                            "IataCode": "YXS",
                            "Airports": [
                                {
                                    "Id": "YXS",
                                    "Name": "Prince George",
                                    "CityId": "YXSA",
                                    "CountryId": "CA",
                                    "Location": "-122.67849, 53.88694"
                                }
                            ]
                        },
                        {
                            "Id": "YXTA",
                            "Name": "Terrace",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.60345, 54.51634",
                            "IataCode": "YXT",
                            "Airports": [
                                {
                                    "Id": "YXT",
                                    "Name": "Terrace",
                                    "CityId": "YXTA",
                                    "CountryId": "CA",
                                    "Location": "-128.57444, 54.465556"
                                }
                            ]
                        },
                        {
                            "Id": "YXUS",
                            "Name": "London",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.233042, 42.983389",
                            "IataCode": "YXU",
                            "Airports": [
                                {
                                    "Id": "YXU",
                                    "Name": "London",
                                    "CityId": "YXUS",
                                    "CountryId": "CA",
                                    "Location": "-81.15, 43.04"
                                }
                            ]
                        },
                        {
                            "Id": "YXYA",
                            "Name": "Whitehorse",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-135.05375, 60.716115",
                            "IataCode": "YXY",
                            "Airports": [
                                {
                                    "Id": "YXY",
                                    "Name": "Whitehorse",
                                    "CityId": "YXYA",
                                    "CountryId": "CA",
                                    "Location": "-135.06667, 60.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YXZA",
                            "Name": "Wawa",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-84.783149, 47.983384",
                            "IataCode": "YXZ",
                            "Airports": [
                                {
                                    "Id": "YXZ",
                                    "Name": "Wawa",
                                    "CityId": "YXZA",
                                    "CountryId": "CA",
                                    "Location": "-84.785833, 47.9625"
                                }
                            ]
                        },
                        {
                            "Id": "YYBA",
                            "Name": "North Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.466328, 46.316802",
                            "IataCode": "YYB",
                            "Airports": [
                                {
                                    "Id": "YYB",
                                    "Name": "North Bay",
                                    "CityId": "YYBA",
                                    "CountryId": "CA",
                                    "Location": "-79.421389, 46.365833"
                                }
                            ]
                        },
                        {
                            "Id": "YYCA",
                            "Name": "Calgary",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.08529, 51.050115",
                            "IataCode": "YYC",
                            "Airports": [
                                {
                                    "Id": "YYC",
                                    "Name": "Calgary",
                                    "CityId": "YYCA",
                                    "CountryId": "CA",
                                    "Location": "-114.02126, 51.114084"
                                }
                            ]
                        },
                        {
                            "Id": "YYDA",
                            "Name": "Smithers",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.17429, 54.780362",
                            "IataCode": "YYD",
                            "Airports": [
                                {
                                    "Id": "YYD",
                                    "Name": "Smithers",
                                    "CityId": "YYDA",
                                    "CountryId": "CA",
                                    "Location": "-127.18028, 54.825556"
                                }
                            ]
                        },
                        {
                            "Id": "YYEA",
                            "Name": "Fort Nelson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.7002, 58.805333",
                            "IataCode": "YYE",
                            "Airports": [
                                {
                                    "Id": "YYE",
                                    "Name": "Fort Nelson",
                                    "CityId": "YYEA",
                                    "CountryId": "CA",
                                    "Location": "-122.58333, 58.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YYFA",
                            "Name": "Penticton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.58569, 49.499765",
                            "IataCode": "YYF",
                            "Airports": [
                                {
                                    "Id": "YYF",
                                    "Name": "Penticton",
                                    "CityId": "YYFA",
                                    "CountryId": "CA",
                                    "Location": "-119.6, 49.464722"
                                }
                            ]
                        },
                        {
                            "Id": "YYGA",
                            "Name": "Charlottetown",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-63.126712, 46.23525",
                            "IataCode": "YYG",
                            "Airports": [
                                {
                                    "Id": "YYG",
                                    "Name": "Charlottetown",
                                    "CityId": "YYGA",
                                    "CountryId": "CA",
                                    "Location": "-63.130556, 46.286111"
                                }
                            ]
                        },
                        {
                            "Id": "YYHA",
                            "Name": "Taloyoak",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.52112, 69.53722",
                            "IataCode": "YYH",
                            "Airports": [
                                {
                                    "Id": "YYH",
                                    "Name": "Taloyoak",
                                    "CityId": "YYHA",
                                    "CountryId": "CA",
                                    "Location": "-93.521118, 69.536198"
                                }
                            ]
                        },
                        {
                            "Id": "YYLA",
                            "Name": "Lynn Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.07564, 56.825236",
                            "IataCode": "YYL",
                            "Airports": [
                                {
                                    "Id": "YYL",
                                    "Name": "Lynn Lake",
                                    "CityId": "YYLA",
                                    "CountryId": "CA",
                                    "Location": "-101.06667, 56.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YYMA",
                            "Name": "Cowley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.08525, 49.566697",
                            "IataCode": "YYM",
                            "Airports": [
                                {
                                    "Id": "YYM",
                                    "Name": "Cowley",
                                    "CityId": "YYMA",
                                    "CountryId": "CA",
                                    "Location": "-114.16667, 49.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YYNA",
                            "Name": "Swift Current",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-107.80135, 50.283366",
                            "IataCode": "YYN",
                            "Airports": [
                                {
                                    "Id": "YYN",
                                    "Name": "Swift Current",
                                    "CityId": "YYNA",
                                    "CountryId": "CA",
                                    "Location": "-107.83333, 50.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YYQA",
                            "Name": "Churchill",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.116824, 58.725407",
                            "IataCode": "YYQ",
                            "Airports": [
                                {
                                    "Id": "YYQ",
                                    "Name": "Churchill",
                                    "CityId": "YYQA",
                                    "CountryId": "CA",
                                    "Location": "-94.065, 58.739167"
                                }
                            ]
                        },
                        {
                            "Id": "YYUA",
                            "Name": "Kapuskasing",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.433078, 49.416936",
                            "IataCode": "YYU",
                            "Airports": [
                                {
                                    "Id": "YYU",
                                    "Name": "Kapuskasing",
                                    "CityId": "YYUA",
                                    "CountryId": "CA",
                                    "Location": "-82.470833, 49.4125"
                                }
                            ]
                        },
                        {
                            "Id": "YYYA",
                            "Name": "Mont Joli",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-68.19214, 48.58388",
                            "IataCode": "YYY",
                            "Airports": [
                                {
                                    "Id": "YYY",
                                    "Name": "Mont Joli",
                                    "CityId": "YYYA",
                                    "CountryId": "CA",
                                    "Location": "-68.2, 48.6"
                                }
                            ]
                        },
                        {
                            "Id": "YZEA",
                            "Name": "Gore Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.466543, 45.916767",
                            "IataCode": "YZE",
                            "Airports": [
                                {
                                    "Id": "YZE",
                                    "Name": "Gore Bay",
                                    "CityId": "YZEA",
                                    "CountryId": "CA",
                                    "Location": "-82.567778, 45.885278"
                                }
                            ]
                        },
                        {
                            "Id": "YELB",
                            "Name": "Yellowknife",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.35255, 62.455995",
                            "IataCode": "YZF",
                            "Airports": [
                                {
                                    "Id": "YZF",
                                    "Name": "Yellowknife",
                                    "CityId": "YELB",
                                    "CountryId": "CA",
                                    "Location": "-114.45, 62.47"
                                }
                            ]
                        },
                        {
                            "Id": "YSWA",
                            "Name": "Salluit",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-75.632313, 62.200607",
                            "IataCode": "YSW",
                            "Airports": [
                                {
                                    "Id": "YZG",
                                    "Name": "Salluit",
                                    "CityId": "YSWA",
                                    "CountryId": "CA",
                                    "Location": "-75.633333, 62.2"
                                }
                            ]
                        },
                        {
                            "Id": "YZHA",
                            "Name": "Slave Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.76896, 55.283437",
                            "IataCode": "YZH",
                            "Airports": [
                                {
                                    "Id": "YZH",
                                    "Name": "Slave Lake",
                                    "CityId": "YZHA",
                                    "CountryId": "CA",
                                    "Location": "-114.78333, 55.3"
                                }
                            ]
                        },
                        {
                            "Id": "YZPA",
                            "Name": "Sandspit",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-131.82117, 53.24262",
                            "IataCode": "YZP",
                            "Airports": [
                                {
                                    "Id": "YZP",
                                    "Name": "Sandspit",
                                    "CityId": "YZPA",
                                    "CountryId": "CA",
                                    "Location": "-131.81278, 53.254444"
                                }
                            ]
                        },
                        {
                            "Id": "SARN",
                            "Name": "Sarnia",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.383127, 42.966776",
                            "IataCode": "YZR",
                            "Airports": [
                                {
                                    "Id": "YZR",
                                    "Name": "Sarnia",
                                    "CityId": "SARN",
                                    "CountryId": "CA",
                                    "Location": "-82.30957, 42.999877"
                                }
                            ]
                        },
                        {
                            "Id": "YZSA",
                            "Name": "Coral Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-83.16615, 64.13406",
                            "IataCode": "YZS",
                            "Airports": [
                                {
                                    "Id": "YZS",
                                    "Name": "Coral Harbour",
                                    "CityId": "YZSA",
                                    "CountryId": "CA",
                                    "Location": "-83.366667, 64.2"
                                }
                            ]
                        },
                        {
                            "Id": "YZTA",
                            "Name": "Port Hardy",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.41985, 50.699617",
                            "IataCode": "YZT",
                            "Airports": [
                                {
                                    "Id": "YZT",
                                    "Name": "Port Hardy",
                                    "CityId": "YZTA",
                                    "CountryId": "CA",
                                    "Location": "-127.36389, 50.680556"
                                }
                            ]
                        },
                        {
                            "Id": "YZUA",
                            "Name": "Whitecourt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.68548, 54.150147",
                            "IataCode": "YZU",
                            "Airports": [
                                {
                                    "Id": "YZU",
                                    "Name": "Whitecourt",
                                    "CityId": "YZUA",
                                    "CountryId": "CA",
                                    "Location": "-115.78333, 54.15"
                                }
                            ]
                        },
                        {
                            "Id": "YZVA",
                            "Name": "Sept-Iles",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.382083, 50.200112",
                            "IataCode": "YZV",
                            "Airports": [
                                {
                                    "Id": "YZV",
                                    "Name": "Sept-Iles",
                                    "CityId": "YZVA",
                                    "CountryId": "CA",
                                    "Location": "-66.265556, 50.223333"
                                }
                            ]
                        },
                        {
                            "Id": "YZWA",
                            "Name": "Teslin",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-132.72433, 60.166166",
                            "IataCode": "YZW",
                            "Airports": [
                                {
                                    "Id": "YZW",
                                    "Name": "Teslin",
                                    "CityId": "YZWA",
                                    "CountryId": "CA",
                                    "Location": "-132.71667, 60.166667"
                                }
                            ]
                        },
                        {
                            "Id": "ZBFA",
                            "Name": "Bathurst",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65.65112, 47.61814",
                            "IataCode": "ZBF",
                            "Airports": [
                                {
                                    "Id": "ZBF",
                                    "Name": "Bathurst",
                                    "CityId": "ZBFA",
                                    "CountryId": "CA",
                                    "Location": "-65.738889, 47.629772"
                                }
                            ]
                        },
                        {
                            "Id": "ZEMA",
                            "Name": "Eastmain",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.166163, 52.183503",
                            "IataCode": "ZEM",
                            "Airports": [
                                {
                                    "Id": "ZEM",
                                    "Name": "Eastmain River",
                                    "CityId": "ZEMA",
                                    "CountryId": "CA",
                                    "Location": "-78.5225, 52.226389"
                                }
                            ]
                        },
                        {
                            "Id": "ZFMA",
                            "Name": "Fort McPherson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-134.88543, 67.43863",
                            "IataCode": "ZFM",
                            "Airports": [
                                {
                                    "Id": "ZFM",
                                    "Name": "Fort McPherson",
                                    "CityId": "ZFMA",
                                    "CountryId": "CA",
                                    "Location": "-134.86028, 67.407778"
                                }
                            ]
                        },
                        {
                            "Id": "ZKEA",
                            "Name": "Kashechewan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.61662, 52.29996",
                            "IataCode": "ZKE",
                            "Airports": [
                                {
                                    "Id": "ZKE",
                                    "Name": "Kashechewan",
                                    "CityId": "ZKEA",
                                    "CountryId": "CA",
                                    "Location": "-81.677778, 52.2825"
                                }
                            ]
                        },
                        {
                            "Id": "ZKGA",
                            "Name": "Kegashka",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.27098, 50.18374",
                            "IataCode": "ZKG",
                            "Airports": [
                                {
                                    "Id": "ZKG",
                                    "Name": "Kegashka",
                                    "CityId": "ZKGA",
                                    "CountryId": "CA",
                                    "Location": "-61.265833, 50.195833"
                                }
                            ]
                        },
                        {
                            "Id": "ZPBA",
                            "Name": "Sachigo Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-92.16697, 53.86687",
                            "IataCode": "ZPB",
                            "Airports": [
                                {
                                    "Id": "ZPB",
                                    "Name": "Sachigo Lake",
                                    "CityId": "ZPBA",
                                    "CountryId": "CA",
                                    "Location": "-92.196389, 53.891111"
                                }
                            ]
                        },
                        {
                            "Id": "ZUMA",
                            "Name": "Churchill Falls",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.00849, 53.53153",
                            "IataCode": "ZUM",
                            "Airports": [
                                {
                                    "Id": "ZUM",
                                    "Name": "Churchill Falls",
                                    "CityId": "ZUMA",
                                    "CountryId": "CA",
                                    "Location": "-64.106389, 53.561944"
                                }
                            ]
                        },
                        {
                            "Id": "ZWLA",
                            "Name": "Wollaston Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-103.15646, 58.11137",
                            "IataCode": "ZWL",
                            "Airports": [
                                {
                                    "Id": "ZWL",
                                    "Name": "Wollaston Lake",
                                    "CityId": "ZWLA",
                                    "CountryId": "CA",
                                    "Location": "-103.17329, 58.106729"
                                }
                            ]
                        },
                        {
                            "Id": "YMBA",
                            "Name": "Merritt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-120.7942, 50.112249",
                            "IataCode": "YMB",
                            "Airports": [
                                {
                                    "Id": "YMB",
                                    "Name": "Merritt",
                                    "CityId": "YMBA",
                                    "CountryId": "CA",
                                    "Location": "-120.75, 50.116667"
                                }
                            ]
                        },
                        {
                            "Id": "YCAA",
                            "Name": "Courtenay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.98613, 49.682941",
                            "IataCode": "YCA",
                            "Airports": [
                                {
                                    "Id": "YCA",
                                    "Name": "Courtenay",
                                    "CityId": "YCAA",
                                    "CountryId": "CA",
                                    "Location": "-124.98333, 49.683333"
                                }
                            ]
                        },
                        {
                            "Id": "XBRA",
                            "Name": "Brockville",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-75.682639, 44.583412",
                            "IataCode": "XBR",
                            "Airports": [
                                {
                                    "Id": "XBR",
                                    "Name": "Brockville",
                                    "CityId": "XBRA",
                                    "CountryId": "CA",
                                    "Location": "-75.683333, 44.583333"
                                }
                            ]
                        },
                        {
                            "Id": "YDCA",
                            "Name": "Drayton Valley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-114.98544, 53.216818",
                            "IataCode": "YDC",
                            "Airports": [
                                {
                                    "Id": "YDC",
                                    "Name": "Drayton Valley",
                                    "CityId": "YDCA",
                                    "CountryId": "CA",
                                    "Location": "-114.96667, 53.2"
                                }
                            ]
                        },
                        {
                            "Id": "LAKA",
                            "Name": "Aklavik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-135.01071, 68.21913",
                            "IataCode": "LAK",
                            "Airports": [
                                {
                                    "Id": "LAK",
                                    "Name": "Aklavik",
                                    "CityId": "LAKA",
                                    "CountryId": "CA",
                                    "Location": "-135.00583, 68.223333"
                                }
                            ]
                        },
                        {
                            "Id": "SSQA",
                            "Name": "La Sarre",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.199639, 48.800186",
                            "IataCode": "SSQ",
                            "Airports": [
                                {
                                    "Id": "SSQ",
                                    "Name": "La Sarre",
                                    "CityId": "SSQA",
                                    "CountryId": "CA",
                                    "Location": "-79.166667, 48.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YYIA",
                            "Name": "Rivers",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-100.24028, 50.030811",
                            "IataCode": "YYI",
                            "Airports": [
                                {
                                    "Id": "YYI",
                                    "Name": "Rivers",
                                    "CityId": "YYIA",
                                    "CountryId": "CA",
                                    "Location": "-100.23333, 50.033333"
                                }
                            ]
                        },
                        {
                            "Id": "YQCA",
                            "Name": "Quaqtaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.614721, 61.033748",
                            "IataCode": "YQC",
                            "Airports": [
                                {
                                    "Id": "YQC",
                                    "Name": "Quaqtaq",
                                    "CityId": "YQCA",
                                    "CountryId": "CA",
                                    "Location": "-69.63333, 61.05"
                                }
                            ]
                        },
                        {
                            "Id": "YAQA",
                            "Name": "Maple Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.61936, 48.81633",
                            "IataCode": "YAQ",
                            "Airports": [
                                {
                                    "Id": "YAQ",
                                    "Name": "Maple Bay",
                                    "CityId": "YAQA",
                                    "CountryId": "CA",
                                    "Location": "-123.61667, 48.816667"
                                }
                            ]
                        },
                        {
                            "Id": "YBDA",
                            "Name": "New Westminster",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.91092, 49.206776",
                            "IataCode": "YBD",
                            "Airports": [
                                {
                                    "Id": "YBD",
                                    "Name": "New Westminster",
                                    "CityId": "YBDA",
                                    "CountryId": "CA",
                                    "Location": "-122.9, 49.216667"
                                }
                            ]
                        },
                        {
                            "Id": "YBJA",
                            "Name": "Baie Johan Beetz",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-62.798567, 50.283446",
                            "IataCode": "YBJ",
                            "Airports": [
                                {
                                    "Id": "YBJ",
                                    "Name": "Baie Johan Beetz",
                                    "CityId": "YBJA",
                                    "CountryId": "CA",
                                    "Location": "-62.916667, 50.5"
                                }
                            ]
                        },
                        {
                            "Id": "YCFA",
                            "Name": "Cortes Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.93617, 50.06634",
                            "IataCode": "YCF",
                            "Airports": [
                                {
                                    "Id": "YCF",
                                    "Name": "Cortes Bay",
                                    "CityId": "YCFA",
                                    "CountryId": "CA",
                                    "Location": "-124.93333, 50.066667"
                                }
                            ]
                        },
                        {
                            "Id": "YHAA",
                            "Name": "Port Hope Simpson",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-56.298025, 52.550095",
                            "IataCode": "YHA",
                            "Airports": [
                                {
                                    "Id": "YHA",
                                    "Name": "Port Hope Simpson",
                                    "CityId": "YHAA",
                                    "CountryId": "CA",
                                    "Location": "-56.2861, 52.5281"
                                }
                            ]
                        },
                        {
                            "Id": "YLSA",
                            "Name": "Lebel-Sur-Quevillon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.982733, 49.050179",
                            "IataCode": "YLS",
                            "Airports": [
                                {
                                    "Id": "YLS",
                                    "Name": "Lebel-Sur-Quevillon",
                                    "CityId": "YLSA",
                                    "CountryId": "CA",
                                    "Location": "-77.016667, 49.033333"
                                }
                            ]
                        },
                        {
                            "Id": "YNOA",
                            "Name": "North Spirit Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.01705, 52.51681",
                            "IataCode": "YNO",
                            "Airports": [
                                {
                                    "Id": "YNO",
                                    "Name": "North Spirit Lake",
                                    "CityId": "YNOA",
                                    "CountryId": "CA",
                                    "Location": "-92.9711, 52.49"
                                }
                            ]
                        },
                        {
                            "Id": "YQEA",
                            "Name": "Kimberley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.9855, 49.683247",
                            "IataCode": "YQE",
                            "Airports": [
                                {
                                    "Id": "YQE",
                                    "Name": "Kimberley",
                                    "CityId": "YQEA",
                                    "CountryId": "CA",
                                    "Location": "-115.98333, 49.666667"
                                }
                            ]
                        },
                        {
                            "Id": "YRRA",
                            "Name": "Stuart Island",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.13621, 50.36634",
                            "IataCode": "YRR",
                            "Airports": [
                                {
                                    "Id": "YRR",
                                    "Name": "Stuart Island",
                                    "CityId": "YRRA",
                                    "CountryId": "CA",
                                    "Location": "-125.16667, 50.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YSZA",
                            "Name": "Squirrel Cove",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.91958, 50.11634",
                            "IataCode": "YSZ",
                            "Airports": [
                                {
                                    "Id": "YSZ",
                                    "Name": "Squirrel Cove",
                                    "CityId": "YSZA",
                                    "CountryId": "CA",
                                    "Location": "-124.91667, 50.05"
                                }
                            ]
                        },
                        {
                            "Id": "YXFA",
                            "Name": "Snake River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.45297, 59.03306",
                            "IataCode": "YXF",
                            "Airports": [
                                {
                                    "Id": "YXF",
                                    "Name": "Snake River",
                                    "CityId": "YXFA",
                                    "CountryId": "CA",
                                    "Location": "-122.43333, 59.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ZLTA",
                            "Name": "La Tabatiere",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-58.96495, 50.83341",
                            "IataCode": "ZLT",
                            "Airports": [
                                {
                                    "Id": "ZLT",
                                    "Name": "La Tabatiere",
                                    "CityId": "ZLTA",
                                    "CountryId": "CA",
                                    "Location": "-58.975556, 50.830833"
                                }
                            ]
                        },
                        {
                            "Id": "YGCA",
                            "Name": "Grande Cache",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.13585, 53.88335",
                            "IataCode": "YGC",
                            "Airports": [
                                {
                                    "Id": "YGC",
                                    "Name": "Grande Cache",
                                    "CityId": "YGCA",
                                    "CountryId": "CA",
                                    "Location": "-118.86667, 53.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YHGA",
                            "Name": "Charlottetown",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-56.114684, 52.766786",
                            "IataCode": "YHG",
                            "Airports": [
                                {
                                    "Id": "YHG",
                                    "Name": "Charlottetown",
                                    "CityId": "YHGA",
                                    "CountryId": "CA",
                                    "Location": "-56.1, 52.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YABA",
                            "Name": "Arctic Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-85.166301, 73.03471",
                            "IataCode": "YAB",
                            "Airports": [
                                {
                                    "Id": "YAB",
                                    "Name": "Arctic Bay",
                                    "CityId": "YABA",
                                    "CountryId": "CA",
                                    "Location": "-84.61361, 72.98222"
                                }
                            ]
                        },
                        {
                            "Id": "SYFA",
                            "Name": "Silva Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.70269, 49.14964",
                            "IataCode": "SYF",
                            "Airports": [
                                {
                                    "Id": "SYF",
                                    "Name": "Silva Bay",
                                    "CityId": "SYFA",
                                    "CountryId": "CA",
                                    "Location": "-123.69722, 49.15"
                                }
                            ]
                        },
                        {
                            "Id": "YAXA",
                            "Name": "Angling Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.53003, 53.84484",
                            "IataCode": "YAX",
                            "Airports": [
                                {
                                    "Id": "YAX",
                                    "Name": "Angling Lake",
                                    "CityId": "YAXA",
                                    "CountryId": "CA",
                                    "Location": "-89.576039, 53.842172"
                                }
                            ]
                        },
                        {
                            "Id": "TUXA",
                            "Name": "Tumbler Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-120.99415, 55.130268",
                            "IataCode": "TUX",
                            "Airports": [
                                {
                                    "Id": "TUX",
                                    "Name": "Tumbler Ridge",
                                    "CityId": "TUXA",
                                    "CountryId": "CA",
                                    "Location": "-121.08333, 55.1"
                                }
                            ]
                        },
                        {
                            "Id": "YCCA",
                            "Name": "Cornwall",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-74.73258, 45.033393",
                            "IataCode": "YCC",
                            "Airports": [
                                {
                                    "Id": "YCC",
                                    "Name": "Cornwall",
                                    "CityId": "YCCA",
                                    "CountryId": "CA",
                                    "Location": "-74.566667, 45.1"
                                }
                            ]
                        },
                        {
                            "Id": "YEYA",
                            "Name": "Amos",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-78.116237, 48.566882",
                            "IataCode": "YEY",
                            "Airports": [
                                {
                                    "Id": "YEY",
                                    "Name": "Amos",
                                    "CityId": "YEYA",
                                    "CountryId": "CA",
                                    "Location": "-78.25, 48.552222"
                                }
                            ]
                        },
                        {
                            "Id": "YMNA",
                            "Name": "Makkovik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-59.16842, 55.08672",
                            "IataCode": "YMN",
                            "Airports": [
                                {
                                    "Id": "YMN",
                                    "Name": "Makkovik",
                                    "CityId": "YMNA",
                                    "CountryId": "CA",
                                    "Location": "-59.166667, 55.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YZAA",
                            "Name": "Ashcroft",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.2859, 50.716458",
                            "IataCode": "YZA",
                            "Airports": [
                                {
                                    "Id": "YZA",
                                    "Name": "Ashcroft",
                                    "CityId": "YZAA",
                                    "CountryId": "CA",
                                    "Location": "-121.33333, 50.666667"
                                }
                            ]
                        },
                        {
                            "Id": "YAMA",
                            "Name": "Sault Ste Marie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-84.333252, 46.516766",
                            "IataCode": "YAM",
                            "Airports": [
                                {
                                    "Id": "YAM",
                                    "Name": "Sault Ste Marie",
                                    "CityId": "YAMA",
                                    "CountryId": "CA",
                                    "Location": "-84.507778, 46.483611"
                                }
                            ]
                        },
                        {
                            "Id": "YLYA",
                            "Name": "Langley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.6526, 49.099668",
                            "IataCode": "YLY",
                            "Airports": [
                                {
                                    "Id": "YLY",
                                    "Name": "Langley",
                                    "CityId": "YLYA",
                                    "CountryId": "CA",
                                    "Location": "-122.63083, 49.100833"
                                }
                            ]
                        },
                        {
                            "Id": "YYTA",
                            "Name": "St John's",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-52.731345, 47.566588",
                            "IataCode": "YYT",
                            "Airports": [
                                {
                                    "Id": "YYT",
                                    "Name": "St John's",
                                    "CityId": "YYTA",
                                    "CountryId": "CA",
                                    "Location": "-52.75, 47.616944"
                                }
                            ]
                        },
                        {
                            "Id": "YNFA",
                            "Name": "Corner Brook",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.948402, 48.966707",
                            "IataCode": "YNF",
                            "Airports": [
                                {
                                    "Id": "YNF",
                                    "Name": "Corner Brook",
                                    "CityId": "YNFA",
                                    "CountryId": "CA",
                                    "Location": "-57.943889, 48.946944"
                                }
                            ]
                        },
                        {
                            "Id": "YLLA",
                            "Name": "Lloydminster",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.00157, 53.283462",
                            "IataCode": "YLL",
                            "Airports": [
                                {
                                    "Id": "YLL",
                                    "Name": "Lloydminster",
                                    "CityId": "YLLA",
                                    "CountryId": "CA",
                                    "Location": "-110, 53.3"
                                }
                            ]
                        },
                        {
                            "Id": "YPKA",
                            "Name": "Pitt Meadows",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.70261, 49.249669",
                            "IataCode": "YPK",
                            "Airports": [
                                {
                                    "Id": "YPK",
                                    "Name": "Pitt Meadows",
                                    "CityId": "YPKA",
                                    "CountryId": "CA",
                                    "Location": "-122.70546, 49.210932"
                                }
                            ]
                        },
                        {
                            "Id": "YPGA",
                            "Name": "Portage La Prairie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-98.29263, 49.972818",
                            "IataCode": "YPG",
                            "Airports": [
                                {
                                    "Id": "YPG",
                                    "Name": "Portage La Prairie",
                                    "CityId": "YPGA",
                                    "CountryId": "CA",
                                    "Location": "-98.3, 49.966667"
                                }
                            ]
                        },
                        {
                            "Id": "YSHA",
                            "Name": "Smith Falls",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.01607, 44.900108",
                            "IataCode": "YSH",
                            "Airports": [
                                {
                                    "Id": "YSH",
                                    "Name": "Smith Falls",
                                    "CityId": "YSHA",
                                    "CountryId": "CA",
                                    "Location": "-76, 44.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YZZA",
                            "Name": "Trail",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.70223, 49.099834",
                            "IataCode": "YZZ",
                            "Airports": [
                                {
                                    "Id": "YZZ",
                                    "Name": "Trail",
                                    "CityId": "YZZA",
                                    "CountryId": "CA",
                                    "Location": "-117.60917, 49.055556"
                                }
                            ]
                        },
                        {
                            "Id": "YVOA",
                            "Name": "Val D'Or",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.782796, 48.100183",
                            "IataCode": "YVO",
                            "Airports": [
                                {
                                    "Id": "YVO",
                                    "Name": "Val D'Or",
                                    "CityId": "YVOA",
                                    "CountryId": "CA",
                                    "Location": "-77.782778, 48.052778"
                                }
                            ]
                        },
                        {
                            "Id": "YDOA",
                            "Name": "Dolbeau",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-72.22627, 48.87646",
                            "IataCode": "YDO",
                            "Airports": [
                                {
                                    "Id": "YDO",
                                    "Name": "Dolbeau",
                                    "CityId": "YDOA",
                                    "CountryId": "CA",
                                    "Location": "-72.233333, 48.866667"
                                }
                            ]
                        },
                        {
                            "Id": "XPKA",
                            "Name": "Pukatawagan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.425527, 54.932986",
                            "IataCode": "XPK",
                            "Airports": [
                                {
                                    "Id": "XPK",
                                    "Name": "Pukatawagan",
                                    "CityId": "XPKA",
                                    "CountryId": "CA",
                                    "Location": "-101.23333, 55.766667"
                                }
                            ]
                        },
                        {
                            "Id": "YBXA",
                            "Name": "Blanc Sablon",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.13142, 51.416803",
                            "IataCode": "YBX",
                            "Airports": [
                                {
                                    "Id": "YBX",
                                    "Name": "Blanc Sablon",
                                    "CityId": "YBXA",
                                    "CountryId": "CA",
                                    "Location": "-57.18624, 51.44196"
                                }
                            ]
                        },
                        {
                            "Id": "YZMA",
                            "Name": "Buchans",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-56.84381, 48.82503",
                            "IataCode": "YZM",
                            "Airports": [
                                {
                                    "Id": "YZM",
                                    "Name": "Buchans",
                                    "CityId": "YZMA",
                                    "CountryId": "CA",
                                    "Location": "-56.866667, 48.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CFQA",
                            "Name": "Creston",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-116.50211, 49.099871",
                            "IataCode": "CFQ",
                            "Airports": [
                                {
                                    "Id": "CFQ",
                                    "Name": "Creston",
                                    "CityId": "CFQA",
                                    "CountryId": "CA",
                                    "Location": "-116.49833, 49.036944"
                                }
                            ]
                        },
                        {
                            "Id": "YZXA",
                            "Name": "Greenwood",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-118.68561, 49.099789",
                            "IataCode": "YZX",
                            "Airports": [
                                {
                                    "Id": "YZX",
                                    "Name": "Greenwood",
                                    "CityId": "YZXA",
                                    "CountryId": "CA",
                                    "Location": "-118.66667, 49.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YGVA",
                            "Name": "Havre St Pierre",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-63.598624, 50.233444",
                            "IataCode": "YGV",
                            "Airports": [
                                {
                                    "Id": "YGV",
                                    "Name": "Havre St Pierre",
                                    "CityId": "YGVA",
                                    "CountryId": "CA",
                                    "Location": "-63.621826, 50.295481"
                                }
                            ]
                        },
                        {
                            "Id": "ZMTA",
                            "Name": "Masset",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-132.10332, 54.016111",
                            "IataCode": "ZMT",
                            "Airports": [
                                {
                                    "Id": "ZMT",
                                    "Name": "Masset",
                                    "CityId": "ZMTA",
                                    "CountryId": "CA",
                                    "Location": "-132.12528, 54.0275"
                                }
                            ]
                        },
                        {
                            "Id": "YNSA",
                            "Name": "Nemiscau",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.332803, 51.716946",
                            "IataCode": "YNS",
                            "Airports": [
                                {
                                    "Id": "YNS",
                                    "Name": "Nemiscau",
                                    "CityId": "YNSA",
                                    "CountryId": "CA",
                                    "Location": "-76.13621, 51.69105"
                                }
                            ]
                        },
                        {
                            "Id": "YTQA",
                            "Name": "Tasiujuaq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-69.9319, 58.700379",
                            "IataCode": "YTQ",
                            "Airports": [
                                {
                                    "Id": "YTQ",
                                    "Name": "Tasiujuaq",
                                    "CityId": "YTQA",
                                    "CountryId": "CA",
                                    "Location": "-69.95729, 58.66712"
                                }
                            ]
                        },
                        {
                            "Id": "YAEA",
                            "Name": "Alta Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.98606, 50.0997",
                            "IataCode": "YAE",
                            "Airports": [
                                {
                                    "Id": "YAE",
                                    "Name": "Alta Lake",
                                    "CityId": "YAEA",
                                    "CountryId": "CA",
                                    "Location": "-123, 50.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YDBA",
                            "Name": "Burwash Landings",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-139.00967, 61.35844",
                            "IataCode": "YDB",
                            "Airports": [
                                {
                                    "Id": "YDB",
                                    "Name": "Burwash Landings",
                                    "CityId": "YDBA",
                                    "CountryId": "CA",
                                    "Location": "-139.01667, 61.35"
                                }
                            ]
                        },
                        {
                            "Id": "YLMA",
                            "Name": "Clinton Creek",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-140.60313, 64.40084",
                            "IataCode": "YLM",
                            "Airports": [
                                {
                                    "Id": "YLM",
                                    "Name": "Clinton Creek",
                                    "CityId": "YLMA",
                                    "CountryId": "CA",
                                    "Location": "-140.55, 64.4"
                                }
                            ]
                        },
                        {
                            "Id": "ZFDA",
                            "Name": "Fond-du-Lac",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-107.20219, 59.31753",
                            "IataCode": "ZFD",
                            "Airports": [
                                {
                                    "Id": "ZFD",
                                    "Name": "Fond-du-Lac",
                                    "CityId": "ZFDA",
                                    "CountryId": "CA",
                                    "Location": "-107.18194, 59.334444"
                                }
                            ]
                        },
                        {
                            "Id": "YGGA",
                            "Name": "Ganges Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.50265, 48.84964",
                            "IataCode": "YGG",
                            "Airports": [
                                {
                                    "Id": "YGG",
                                    "Name": "Ganges Harbor",
                                    "CityId": "YGGA",
                                    "CountryId": "CA",
                                    "Location": "-123.5, 48.85"
                                }
                            ]
                        },
                        {
                            "Id": "YHIA",
                            "Name": "Ulukhaktok",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.75457, 70.73418",
                            "IataCode": "YHI",
                            "Airports": [
                                {
                                    "Id": "YHI",
                                    "Name": "Ulukhaktok / Holman",
                                    "CityId": "YHIA",
                                    "CountryId": "CA",
                                    "Location": "-117.80611, 70.762778"
                                }
                            ]
                        },
                        {
                            "Id": "TILA",
                            "Name": "Inverlake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.63525, 51.05011",
                            "IataCode": "TIL",
                            "Airports": [
                                {
                                    "Id": "TIL",
                                    "Name": "Inverlake",
                                    "CityId": "TILA",
                                    "CountryId": "CA",
                                    "Location": "-113.62361, 51.0575"
                                }
                            ]
                        },
                        {
                            "Id": "YXIA",
                            "Name": "Killaloe",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.41618, 45.55012",
                            "IataCode": "YXI",
                            "Airports": [
                                {
                                    "Id": "YXI",
                                    "Name": "Killaloe",
                                    "CityId": "YXIA",
                                    "CountryId": "CA",
                                    "Location": "-77.416667, 45.55"
                                }
                            ]
                        },
                        {
                            "Id": "YMUA",
                            "Name": "Mansons Landing",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.98617, 50.06634",
                            "IataCode": "YMU",
                            "Airports": [
                                {
                                    "Id": "YMU",
                                    "Name": "Mansons Landing Water Aerodrome",
                                    "CityId": "YMUA",
                                    "CountryId": "CA",
                                    "Location": "-124.98333, 50.066667"
                                }
                            ]
                        },
                        {
                            "Id": "YRCA",
                            "Name": "Refuge Cove",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.83617, 50.11634",
                            "IataCode": "YRC",
                            "Airports": [
                                {
                                    "Id": "YRC",
                                    "Name": "Refuge Cove Seaplane Base",
                                    "CityId": "YRCA",
                                    "CountryId": "CA",
                                    "Location": "-124.84066, 50.124183"
                                }
                            ]
                        },
                        {
                            "Id": "YRBA",
                            "Name": "Resolute",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.830619, 74.69736",
                            "IataCode": "YRB",
                            "Airports": [
                                {
                                    "Id": "YRB",
                                    "Name": "Resolute",
                                    "CityId": "YRBA",
                                    "CountryId": "CA",
                                    "Location": "-94.966667, 74.716667"
                                }
                            ]
                        },
                        {
                            "Id": "YSXA",
                            "Name": "Shearwater",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.09361, 52.14684",
                            "IataCode": "YSX",
                            "Airports": [
                                {
                                    "Id": "YSX",
                                    "Name": "Shearwater",
                                    "CityId": "YSXA",
                                    "CountryId": "CA",
                                    "Location": "-128.06667, 52.133333"
                                }
                            ]
                        },
                        {
                            "Id": "YSTA",
                            "Name": "Ste Therese Point",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.84906, 53.83189",
                            "IataCode": "YST",
                            "Airports": [
                                {
                                    "Id": "YST",
                                    "Name": "Ste Therese Point",
                                    "CityId": "YSTA",
                                    "CountryId": "CA",
                                    "Location": "-94.8519, 53.8456"
                                }
                            ]
                        },
                        {
                            "Id": "ZFNA",
                            "Name": "Tulita / Fort Norman",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.50026, 64.93082",
                            "IataCode": "ZFN",
                            "Airports": [
                                {
                                    "Id": "ZFN",
                                    "Name": "Tulita / Fort Norman",
                                    "CityId": "ZFNA",
                                    "CountryId": "CA",
                                    "Location": "-125.56667, 64.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YLEA",
                            "Name": "Wha Ti / Lac La Martre",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.27567, 63.14429",
                            "IataCode": "YLE",
                            "Airports": [
                                {
                                    "Id": "YLE",
                                    "Name": "Wha Ti / Lac La Martre",
                                    "CityId": "YLEA",
                                    "CountryId": "CA",
                                    "Location": "-117.26667, 63.15"
                                }
                            ]
                        },
                        {
                            "Id": "YSGA",
                            "Name": "Lutselke / Snowdrift",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.736, 62.4056",
                            "IataCode": "YSG",
                            "Airports": [
                                {
                                    "Id": "YSG",
                                    "Name": "Lutselke / Snowdrift",
                                    "CityId": "YSGA",
                                    "CountryId": "CA",
                                    "Location": "-110.07556, 62.418611"
                                }
                            ]
                        },
                        {
                            "Id": "YTLA",
                            "Name": "Big Trout",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.66114, 53.81727",
                            "IataCode": "YTL",
                            "Airports": [
                                {
                                    "Id": "YTL",
                                    "Name": "Big Trout",
                                    "CityId": "YTLA",
                                    "CountryId": "CA",
                                    "Location": "-90, 53.75"
                                }
                            ]
                        },
                        {
                            "Id": "YSLA",
                            "Name": "St Leonard",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-67.92469, 47.17303",
                            "IataCode": "YSL",
                            "Airports": [
                                {
                                    "Id": "YSL",
                                    "Name": "St Leonard",
                                    "CityId": "YSLA",
                                    "CountryId": "CA",
                                    "Location": "-67.83472, 47.1575"
                                }
                            ]
                        },
                        {
                            "Id": "YREA",
                            "Name": "Resolution Island",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-65, 61.583333",
                            "IataCode": "YRE",
                            "Airports": [
                                {
                                    "Id": "YRE",
                                    "Name": "Resolution Island",
                                    "CityId": "YREA",
                                    "CountryId": "CA",
                                    "Location": "-65, 61.5"
                                }
                            ]
                        },
                        {
                            "Id": "YQJA",
                            "Name": "Quadra Island",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.233, 50.167",
                            "IataCode": "YQJ",
                            "Airports": [
                                {
                                    "Id": "YQJ",
                                    "Name": "April Point Seaplane Base",
                                    "CityId": "YQJA",
                                    "CountryId": "CA",
                                    "Location": "-125.22923, 50.06466"
                                }
                            ]
                        },
                        {
                            "Id": "YLAA",
                            "Name": "Langara",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-133.02028, 54.23417",
                            "IataCode": "YLA",
                            "Airports": [
                                {
                                    "Id": "YLA",
                                    "Name": "Langara",
                                    "CityId": "YLAA",
                                    "CountryId": "CA",
                                    "Location": "-133.01667, 54.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YGRA",
                            "Name": "Iles De La Madeleine",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-61.764259, 47.366759",
                            "IataCode": "YGR",
                            "Airports": [
                                {
                                    "Id": "YGR",
                                    "Name": "Iles De La Madeleine",
                                    "CityId": "YGRA",
                                    "CountryId": "CA",
                                    "Location": "-61.900002, 47.366667"
                                }
                            ]
                        },
                        {
                            "Id": "YTRA",
                            "Name": "Trenton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.512894, 44.116299",
                            "IataCode": "YTR",
                            "Airports": [
                                {
                                    "Id": "YTR",
                                    "Name": "Trenton",
                                    "CityId": "YTRA",
                                    "CountryId": "CA",
                                    "Location": "-77.583333, 44.1"
                                }
                            ]
                        },
                        {
                            "Id": "XCMA",
                            "Name": "Chatham",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-82.17285, 42.41935",
                            "IataCode": "XCM",
                            "Airports": [
                                {
                                    "Id": "XCM",
                                    "Name": "Chatham",
                                    "CityId": "XCMA",
                                    "CountryId": "CA",
                                    "Location": "-82.183333, 42.4"
                                }
                            ]
                        },
                        {
                            "Id": "DGFA",
                            "Name": "Douglas Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-119.51117, 50.1184",
                            "IataCode": "DGF",
                            "Airports": [
                                {
                                    "Id": "DGF",
                                    "Name": "Douglas Lake",
                                    "CityId": "DGFA",
                                    "CountryId": "CA",
                                    "Location": "-120.18333, 50.166667"
                                }
                            ]
                        },
                        {
                            "Id": "YBWA",
                            "Name": "Bedwell Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.22616, 48.74766",
                            "IataCode": "YBW",
                            "Airports": [
                                {
                                    "Id": "YBW",
                                    "Name": "Bedwell Harbor",
                                    "CityId": "YBWA",
                                    "CountryId": "CA",
                                    "Location": "-123.23333, 48.75"
                                }
                            ]
                        },
                        {
                            "Id": "YBOA",
                            "Name": "Bob Quinn Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-130.25121, 56.97183",
                            "IataCode": "YBO",
                            "Airports": [
                                {
                                    "Id": "YBO",
                                    "Name": "Bobquinn Lake",
                                    "CityId": "YBOA",
                                    "CountryId": "CA",
                                    "Location": "-130.25, 56.966667"
                                }
                            ]
                        },
                        {
                            "Id": "YHRA",
                            "Name": "Harrington Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-59.636406, 50.469133",
                            "IataCode": "YHR",
                            "Airports": [
                                {
                                    "Id": "YHR",
                                    "Name": "Chevery",
                                    "CityId": "YHRA",
                                    "CountryId": "CA",
                                    "Location": "-59.636406, 50.469133"
                                }
                            ]
                        },
                        {
                            "Id": "YMLA",
                            "Name": "La Malbaie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-70.15594, 47.65753",
                            "IataCode": "YML",
                            "Airports": [
                                {
                                    "Id": "YML",
                                    "Name": "Murray Bay",
                                    "CityId": "YMLA",
                                    "CountryId": "CA",
                                    "Location": "-70.166667, 47.65"
                                }
                            ]
                        },
                        {
                            "Id": "YMPA",
                            "Name": "Port McNeill",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.10311, 50.582944",
                            "IataCode": "YMP",
                            "Airports": [
                                {
                                    "Id": "YMP",
                                    "Name": "Port McNeil",
                                    "CityId": "YMPA",
                                    "CountryId": "CA",
                                    "Location": "-127.09722, 50.611111"
                                }
                            ]
                        },
                        {
                            "Id": "YIGA",
                            "Name": "Big Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.13621, 50.39964",
                            "IataCode": "YIG",
                            "Airports": [
                                {
                                    "Id": "YIG",
                                    "Name": "Big Bay Marina",
                                    "CityId": "YIGA",
                                    "CountryId": "CA",
                                    "Location": "-125.13333, 50.383333"
                                }
                            ]
                        },
                        {
                            "Id": "YSEA",
                            "Name": "Swan River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.26759, 52.105801",
                            "IataCode": "YSE",
                            "Airports": [
                                {
                                    "Id": "YSE",
                                    "Name": "Squamish",
                                    "CityId": "YSEA",
                                    "CountryId": "CA",
                                    "Location": "-101.23333, 52.116667"
                                }
                            ]
                        },
                        {
                            "Id": "YDXA",
                            "Name": "Namu",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.86998, 51.86635",
                            "IataCode": "YDX",
                            "Airports": [
                                {
                                    "Id": "YDX",
                                    "Name": "Doc Creek",
                                    "CityId": "YDXA",
                                    "CountryId": "CA",
                                    "Location": "-128.08333, 52.05"
                                }
                            ]
                        },
                        {
                            "Id": "YFXA",
                            "Name": "St. Lewis",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-55.681292, 52.36679",
                            "IataCode": "YFX",
                            "Airports": [
                                {
                                    "Id": "YFX",
                                    "Name": "Fox Harbour (St Lewis)",
                                    "CityId": "YFXA",
                                    "CountryId": "CA",
                                    "Location": "-55.683333, 52.366667"
                                }
                            ]
                        },
                        {
                            "Id": "YMFA",
                            "Name": "Salt Spring Island",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.51002, 48.81726",
                            "IataCode": "YMF",
                            "Airports": [
                                {
                                    "Id": "YMF",
                                    "Name": "Montagne Harbor",
                                    "CityId": "YMFA",
                                    "CountryId": "CA",
                                    "Location": "-123.2, 48.816667"
                                }
                            ]
                        },
                        {
                            "Id": "YBQA",
                            "Name": "Chemainus",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.74451, 48.92882",
                            "IataCode": "YBQ",
                            "Airports": [
                                {
                                    "Id": "YBQ",
                                    "Name": "Telegraph Harbour",
                                    "CityId": "YBQA",
                                    "CountryId": "CA",
                                    "Location": "-123.65, 48.983333"
                                }
                            ]
                        },
                        {
                            "Id": "YJNA",
                            "Name": "Saint-Jean-sur-Richelieu",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-73.265857, 45.316781",
                            "IataCode": "YJN",
                            "Airports": [
                                {
                                    "Id": "YJN",
                                    "Name": "St Jean",
                                    "CityId": "YJNA",
                                    "CountryId": "CA",
                                    "Location": "-73.333333, 45.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YBGA",
                            "Name": "La Baie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-70.988611, 48.322222",
                            "IataCode": "YBG",
                            "Airports": [
                                {
                                    "Id": "YBG",
                                    "Name": "Bagotville",
                                    "CityId": "YBGA",
                                    "CountryId": "CA",
                                    "Location": "-70.988611, 48.322222"
                                }
                            ]
                        },
                        {
                            "Id": "COLV",
                            "Name": "Northwest Territories",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-110.07556, 62.418611",
                            "IataCode": "YCK",
                            "Airports": [
                                {
                                    "Id": "YCK",
                                    "Name": "Colville Lake",
                                    "CityId": "COLV",
                                    "CountryId": "CA",
                                    "Location": "-125.67, 67.31"
                                }
                            ]
                        },
                        {
                            "Id": "YOYA",
                            "Name": "Holland",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-72.002319, 44.966991",
                            "IataCode": "YOY",
                            "Airports": [
                                {
                                    "Id": "YOY",
                                    "Name": "Valcartier",
                                    "CityId": "YOYA",
                                    "CountryId": "CA",
                                    "Location": "-72, 45"
                                }
                            ]
                        },
                        {
                            "Id": "YAFA",
                            "Name": "Asbestos",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-71.932399, 45.766775",
                            "IataCode": "YAF",
                            "Airports": [
                                {
                                    "Id": "YAF",
                                    "Name": "Asbestos Hill",
                                    "CityId": "YAFA",
                                    "CountryId": "CA",
                                    "Location": "-71.95, 45.766667"
                                }
                            ]
                        },
                        {
                            "Id": "ZTBA",
                            "Name": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-59.598324, 50.466817",
                            "IataCode": "ZTB",
                            "Airports": [
                                {
                                    "Id": "ZTB",
                                    "Name": "Tete-a-La-Baleine",
                                    "CityId": "ZTBA",
                                    "CountryId": "CA",
                                    "Location": "-59.383611, 50.674444"
                                }
                            ]
                        },
                        {
                            "Id": "YYRA",
                            "Name": "Happy Valley-Goose Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-60.4, 53.316667",
                            "IataCode": "YYR",
                            "Airports": [
                                {
                                    "Id": "YYR",
                                    "Name": "Goose Bay",
                                    "CityId": "YYRA",
                                    "CountryId": "CA",
                                    "Location": "-60.4, 53.316667"
                                }
                            ]
                        },
                        {
                            "Id": "YSUA",
                            "Name": "Irishtown-Summerside",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-57.981703, 48.983308",
                            "IataCode": "YSU",
                            "Airports": [
                                {
                                    "Id": "YSU",
                                    "Name": "Summerside",
                                    "CityId": "YSUA",
                                    "CountryId": "CA",
                                    "Location": "-57.983333, 49"
                                }
                            ]
                        },
                        {
                            "Id": "YLPA",
                            "Name": "Longue-Pointe-de-Mingan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-64.14867, 50.266841",
                            "IataCode": "YLP",
                            "Airports": [
                                {
                                    "Id": "YLP",
                                    "Name": "Mingan",
                                    "CityId": "YLPA",
                                    "CountryId": "CA",
                                    "Location": "-64.15, 50.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YPXA",
                            "Name": "Puvirnituq",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.166667, 60.033333",
                            "IataCode": "YPX",
                            "Airports": [
                                {
                                    "Id": "YPX",
                                    "Name": "Povungnituk",
                                    "CityId": "YPXA",
                                    "CountryId": "CA",
                                    "Location": "-77.166667, 60.033333"
                                }
                            ]
                        },
                        {
                            "Id": "YVVA",
                            "Name": "South Bruce Peninsula",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.216441, 44.683388",
                            "IataCode": "YVV",
                            "Airports": [
                                {
                                    "Id": "YVV",
                                    "Name": "Wiarton",
                                    "CityId": "YVVA",
                                    "CountryId": "CA",
                                    "Location": "-81.166667, 44.666667"
                                }
                            ]
                        },
                        {
                            "Id": "YMRA",
                            "Name": "Whapmagoostui",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-77.749505, 55.2503",
                            "IataCode": "YMR",
                            "Airports": [
                                {
                                    "Id": "YMR",
                                    "Name": "Merry Island",
                                    "CityId": "YMRA",
                                    "CountryId": "CA",
                                    "Location": "-77.516667, 55.483333"
                                }
                            ]
                        },
                        {
                            "Id": "YGSA",
                            "Name": "12 Mile",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.96998, 55.81654",
                            "IataCode": "YGS",
                            "Airports": [
                                {
                                    "Id": "YGS",
                                    "Name": "Germansen",
                                    "CityId": "YGSA",
                                    "CountryId": "CA",
                                    "Location": "-124.71667, 55.783333"
                                }
                            ]
                        },
                        {
                            "Id": "YBVA",
                            "Name": "Asinkaanumevatt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.85252, 52.25811",
                            "IataCode": "YBV",
                            "Airports": [
                                {
                                    "Id": "YBV",
                                    "Name": "Berens River",
                                    "CityId": "YBVA",
                                    "CountryId": "CA",
                                    "Location": "-97.016667, 52.355556"
                                }
                            ]
                        },
                        {
                            "Id": "KIFA",
                            "Name": "Big Beaver House",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.88339, 52.95012",
                            "IataCode": "KIF",
                            "Airports": [
                                {
                                    "Id": "KIF",
                                    "Name": "Kingfisher Lake",
                                    "CityId": "KIFA",
                                    "CountryId": "CA",
                                    "Location": "-89.85, 53.013889"
                                }
                            ]
                        },
                        {
                            "Id": "YADA",
                            "Name": "Bushell Park",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-105.55115, 50.33335",
                            "IataCode": "YAD",
                            "Airports": [
                                {
                                    "Id": "YAD",
                                    "Name": "Moose Lake",
                                    "CityId": "YADA",
                                    "CountryId": "CA",
                                    "Location": "-105.53333, 50.383333"
                                }
                            ]
                        },
                        {
                            "Id": "YCLA",
                            "Name": "Carleton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-66.333333, 47.983333",
                            "IataCode": "YCL",
                            "Airports": [
                                {
                                    "Id": "YCL",
                                    "Name": "Charlo",
                                    "CityId": "YCLA",
                                    "CountryId": "CA",
                                    "Location": "-66.333333, 47.983333"
                                }
                            ]
                        },
                        {
                            "Id": "YAAA",
                            "Name": "Charlotte Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.30308, 52.22476",
                            "IataCode": "YAA",
                            "Airports": [
                                {
                                    "Id": "YAA",
                                    "Name": "Anahim Lake",
                                    "CityId": "YAAA",
                                    "CountryId": "CA",
                                    "Location": "-125.3, 52.45"
                                }
                            ]
                        },
                        {
                            "Id": "YBNA",
                            "Name": "Chelton",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-63.74872, 46.30013",
                            "IataCode": "YBN",
                            "Airports": [
                                {
                                    "Id": "YBN",
                                    "Name": "Borden",
                                    "CityId": "YBNA",
                                    "CountryId": "CA",
                                    "Location": "-63.783333, 46.3"
                                }
                            ]
                        },
                        {
                            "Id": "YDSA",
                            "Name": "Church House",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.0696, 50.33295",
                            "IataCode": "YDS",
                            "Airports": [
                                {
                                    "Id": "YDS",
                                    "Name": "Desolation Sound",
                                    "CityId": "YDSA",
                                    "CountryId": "CA",
                                    "Location": "-124.72971, 49.965949"
                                }
                            ]
                        },
                        {
                            "Id": "YRNA",
                            "Name": "Dawsons Landing",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.58653, 51.58295",
                            "IataCode": "YRN",
                            "Airports": [
                                {
                                    "Id": "YRN",
                                    "Name": "Rivers Inlet",
                                    "CityId": "YRNA",
                                    "CountryId": "CA",
                                    "Location": "-127.58333, 51.466667"
                                }
                            ]
                        },
                        {
                            "Id": "YSDA",
                            "Name": "Dennis",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-111.05171, 50.1834",
                            "IataCode": "YSD",
                            "Airports": [
                                {
                                    "Id": "YSD",
                                    "Name": "Suffield",
                                    "CityId": "YSDA",
                                    "CountryId": "CA",
                                    "Location": "-111.16667, 50.2"
                                }
                            ]
                        },
                        {
                            "Id": "YCVA",
                            "Name": "Dollard-Des Ormeaux",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-73.82419, 45.49452",
                            "IataCode": "YCV",
                            "Airports": [
                                {
                                    "Id": "YCV",
                                    "Name": "Cartierville",
                                    "CityId": "YCVA",
                                    "CountryId": "CA",
                                    "Location": "-73.75, 45.516667"
                                }
                            ]
                        },
                        {
                            "Id": "YCPA",
                            "Name": "Drybrough",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.20175, 56.52162",
                            "IataCode": "YCP",
                            "Airports": [
                                {
                                    "Id": "YCP",
                                    "Name": "Co-op Point",
                                    "CityId": "YCPA",
                                    "CountryId": "CA",
                                    "Location": "-102.03333, 57.066667"
                                }
                            ]
                        },
                        {
                            "Id": "YNRA",
                            "Name": "Finns",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.99506, 50.86111",
                            "IataCode": "YNR",
                            "Airports": [
                                {
                                    "Id": "YNR",
                                    "Name": "Arnes",
                                    "CityId": "YNRA",
                                    "CountryId": "CA",
                                    "Location": "-97, 50.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YFJA",
                            "Name": "Fort Enterprise",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.15255, 64.47274",
                            "IataCode": "YFJ",
                            "Airports": [
                                {
                                    "Id": "YFJ",
                                    "Name": "Snare Lake",
                                    "CityId": "YFJA",
                                    "CountryId": "CA",
                                    "Location": "-114.0672, 64.1839"
                                }
                            ]
                        },
                        {
                            "Id": "YJOA",
                            "Name": "Fowler",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-131.77057, 56.83285",
                            "IataCode": "YJO",
                            "Airports": [
                                {
                                    "Id": "YJO",
                                    "Name": "Johnny Mountain",
                                    "CityId": "YJOA",
                                    "CountryId": "CA",
                                    "Location": "-131.11667, 56.666667"
                                }
                            ]
                        },
                        {
                            "Id": "NWPA",
                            "Name": "Gallardin Point",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-53.97313, 47.2599",
                            "IataCode": "NWP",
                            "Airports": [
                                {
                                    "Id": "NWP",
                                    "Name": "Argentia",
                                    "CityId": "NWPA",
                                    "CountryId": "CA",
                                    "Location": "-53.966667, 47.3"
                                }
                            ]
                        },
                        {
                            "Id": "YPIA",
                            "Name": "Georgetown Mills",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-130.40344, 54.46613",
                            "IataCode": "YPI",
                            "Airports": [
                                {
                                    "Id": "YPI",
                                    "Name": "Port Simpson",
                                    "CityId": "YPIA",
                                    "CountryId": "CA",
                                    "Location": "-130.33333, 54.5"
                                }
                            ]
                        },
                        {
                            "Id": "IUMA",
                            "Name": "Giscome",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-122.3697, 54.06662",
                            "IataCode": "IUM",
                            "Airports": [
                                {
                                    "Id": "IUM",
                                    "Name": "Summit Lake",
                                    "CityId": "IUMA",
                                    "CountryId": "CA",
                                    "Location": "-122.66667, 54.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YYWA",
                            "Name": "Gull Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-89.10568, 49.80541",
                            "IataCode": "YYW",
                            "Airports": [
                                {
                                    "Id": "YYW",
                                    "Name": "Armstrong",
                                    "CityId": "YYWA",
                                    "CountryId": "CA",
                                    "Location": "-89.033333, 50.283333"
                                }
                            ]
                        },
                        {
                            "Id": "YNKA",
                            "Name": "Hkusam",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.91968, 50.38293",
                            "IataCode": "YNK",
                            "Airports": [
                                {
                                    "Id": "YNK",
                                    "Name": "Nootka Sound",
                                    "CityId": "YNKA",
                                    "CountryId": "CA",
                                    "Location": "-125.91667, 50.083333"
                                }
                            ]
                        },
                        {
                            "Id": "WPLA",
                            "Name": "Homfray Creek",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.63618, 50.29965",
                            "IataCode": "WPL",
                            "Airports": [
                                {
                                    "Id": "WPL",
                                    "Name": "Powell Lake",
                                    "CityId": "WPLA",
                                    "CountryId": "CA",
                                    "Location": "-124.4, 50.183333"
                                }
                            ]
                        },
                        {
                            "Id": "XGLA",
                            "Name": "Hughes Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-100.56122, 56.80299",
                            "IataCode": "XGL",
                            "Airports": [
                                {
                                    "Id": "XGL",
                                    "Name": "Granville Lake",
                                    "CityId": "XGLA",
                                    "CountryId": "CA",
                                    "Location": "-100.55, 56.233333"
                                }
                            ]
                        },
                        {
                            "Id": "YIVA",
                            "Name": "Kapaneewekamik Place",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.78825, 53.9766",
                            "IataCode": "YIV",
                            "Airports": [
                                {
                                    "Id": "YIV",
                                    "Name": "Island Lake",
                                    "CityId": "YIVA",
                                    "CountryId": "CA",
                                    "Location": "-94.791667, 54.208333"
                                }
                            ]
                        },
                        {
                            "Id": "YXRA",
                            "Name": "Kerns",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.80228, 47.60917",
                            "IataCode": "YXR",
                            "Airports": [
                                {
                                    "Id": "YXR",
                                    "Name": "Earlton",
                                    "CityId": "YXRA",
                                    "CountryId": "CA",
                                    "Location": "-79.851944, 47.697222"
                                }
                            ]
                        },
                        {
                            "Id": "YTUA",
                            "Name": "Kildonan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-125.00279, 48.99962",
                            "IataCode": "YTU",
                            "Airports": [
                                {
                                    "Id": "YTU",
                                    "Name": "Tasu",
                                    "CityId": "YTUA",
                                    "CountryId": "CA",
                                    "Location": "-124.93333, 49.1"
                                }
                            ]
                        },
                        {
                            "Id": "YRDA",
                            "Name": "Kimsquit",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.95329, 52.83298",
                            "IataCode": "YRD",
                            "Airports": [
                                {
                                    "Id": "YRD",
                                    "Name": "Dean River",
                                    "CityId": "YRDA",
                                    "CountryId": "CA",
                                    "Location": "-127, 52.833333"
                                }
                            ]
                        },
                        {
                            "Id": "YGOA",
                            "Name": "Kitchiokonim Place",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.33434, 53.91269",
                            "IataCode": "YGO",
                            "Airports": [
                                {
                                    "Id": "YGO",
                                    "Name": "Gods Narrows",
                                    "CityId": "YGOA",
                                    "CountryId": "CA",
                                    "Location": "-94.483333, 54.55"
                                }
                            ]
                        },
                        {
                            "Id": "YPTA",
                            "Name": "Kleindale",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.96946, 49.63296",
                            "IataCode": "YPT",
                            "Airports": [
                                {
                                    "Id": "YPT",
                                    "Name": "Pender Harbor",
                                    "CityId": "YPTA",
                                    "CountryId": "CA",
                                    "Location": "-124, 49.65"
                                }
                            ]
                        },
                        {
                            "Id": "YZCA",
                            "Name": "Kobes",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-121.65293, 56.63874",
                            "IataCode": "YZC",
                            "Airports": [
                                {
                                    "Id": "YZC",
                                    "Name": "Beatton River",
                                    "CityId": "YZCA",
                                    "CountryId": "CA",
                                    "Location": "-121.33333, 57.433333"
                                }
                            ]
                        },
                        {
                            "Id": "LRQA",
                            "Name": "Kosapachekaywinasinne",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-100.82284, 56.33021",
                            "IataCode": "LRQ",
                            "Airports": [
                                {
                                    "Id": "LRQ",
                                    "Name": "Laurie River",
                                    "CityId": "LRQA",
                                    "CountryId": "CA",
                                    "Location": "-101, 56.216667"
                                }
                            ]
                        },
                        {
                            "Id": "KNYA",
                            "Name": "Kosapechekanesik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.73507, 56.40082",
                            "IataCode": "KNY",
                            "Airports": [
                                {
                                    "Id": "KNY",
                                    "Name": "Kinoosao",
                                    "CityId": "KNYA",
                                    "CountryId": "CA",
                                    "Location": "-102.03333, 57.066667"
                                }
                            ]
                        },
                        {
                            "Id": "YMIA",
                            "Name": "Laclu",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-94.70045, 49.78331",
                            "IataCode": "YMI",
                            "Airports": [
                                {
                                    "Id": "YMI",
                                    "Name": "Minaki",
                                    "CityId": "YMIA",
                                    "CountryId": "CA",
                                    "Location": "-94.666667, 50"
                                }
                            ]
                        },
                        {
                            "Id": "XLBA",
                            "Name": "Le Pensie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.16324, 58.45701",
                            "IataCode": "XLB",
                            "Airports": [
                                {
                                    "Id": "XLB",
                                    "Name": "Lac Brochet",
                                    "CityId": "XLBA",
                                    "CountryId": "CA",
                                    "Location": "-101.46667, 58.6"
                                }
                            ]
                        },
                        {
                            "Id": "YTDA",
                            "Name": "Leven",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.54268, 55.38098",
                            "IataCode": "YTD",
                            "Airports": [
                                {
                                    "Id": "YTD",
                                    "Name": "Thicket Portage",
                                    "CityId": "YTDA",
                                    "CountryId": "CA",
                                    "Location": "-97.7, 55.316667"
                                }
                            ]
                        },
                        {
                            "Id": "YQAA",
                            "Name": "Lewisham",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-79.11631, 44.86681",
                            "IataCode": "YQA",
                            "Airports": [
                                {
                                    "Id": "YQA",
                                    "Name": "Muskoka",
                                    "CityId": "YQAA",
                                    "CountryId": "CA",
                                    "Location": "-79.3, 44.966667"
                                }
                            ]
                        },
                        {
                            "Id": "YDVA",
                            "Name": "Little Bullhead",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.83704, 51.67391",
                            "IataCode": "YDV",
                            "Airports": [
                                {
                                    "Id": "YDV",
                                    "Name": "Bloodvein",
                                    "CityId": "YDVA",
                                    "CountryId": "CA",
                                    "Location": "-96.733333, 51.75"
                                }
                            ]
                        },
                        {
                            "Id": "YGBA",
                            "Name": "Marshall School Junction",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.51141, 49.71071",
                            "IataCode": "YGB",
                            "Airports": [
                                {
                                    "Id": "YGB",
                                    "Name": "Gillies Bay",
                                    "CityId": "YGBA",
                                    "CountryId": "CA",
                                    "Location": "-124.53333, 49.7"
                                }
                            ]
                        },
                        {
                            "Id": "ZGIA",
                            "Name": "Matawak",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.40096, 54.58332",
                            "IataCode": "ZGI",
                            "Airports": [
                                {
                                    "Id": "ZGI",
                                    "Name": "Gods River",
                                    "CityId": "ZGIA",
                                    "CountryId": "CA",
                                    "Location": "-94.078611, 54.839722"
                                }
                            ]
                        },
                        {
                            "Id": "ZGRC",
                            "Name": "Matawa Place",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-95.72863, 52.59566",
                            "IataCode": "ZGR",
                            "Airports": [
                                {
                                    "Id": "ZGR",
                                    "Name": "Little Grand Rapids",
                                    "CityId": "ZGRC",
                                    "CountryId": "CA",
                                    "Location": "-95.466111, 52.045"
                                }
                            ]
                        },
                        {
                            "Id": "ZACA",
                            "Name": "Mistuhekasookun",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.08365, 56.66694",
                            "IataCode": "ZAC",
                            "Airports": [
                                {
                                    "Id": "ZAC",
                                    "Name": "York Landing",
                                    "CityId": "ZACA",
                                    "CountryId": "CA",
                                    "Location": "-96.089167, 56.089444"
                                }
                            ]
                        },
                        {
                            "Id": "YGNA",
                            "Name": "Mitchell Bay",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.85308, 50.63292",
                            "IataCode": "YGN",
                            "Airports": [
                                {
                                    "Id": "YGN",
                                    "Name": "Greenway Sound",
                                    "CityId": "YGNA",
                                    "CountryId": "CA",
                                    "Location": "-126.85, 50.85"
                                }
                            ]
                        },
                        {
                            "Id": "CJHA",
                            "Name": "Nemaiah Valley",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.88629, 51.48305",
                            "IataCode": "CJH",
                            "Airports": [
                                {
                                    "Id": "CJH",
                                    "Name": "Chilko Lake",
                                    "CityId": "CJHA",
                                    "CountryId": "CA",
                                    "Location": "-124.08333, 51.333333"
                                }
                            ]
                        },
                        {
                            "Id": "YJPA",
                            "Name": "Old Entrance",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-117.71908, 53.36678",
                            "IataCode": "YJP",
                            "Airports": [
                                {
                                    "Id": "YJP",
                                    "Name": "Jasper-Hinton",
                                    "CityId": "YJPA",
                                    "CountryId": "CA",
                                    "Location": "-117.75306, 53.319167"
                                }
                            ]
                        },
                        {
                            "Id": "YRSA",
                            "Name": "Omineeseenowenik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.50071, 54.14991",
                            "IataCode": "YRS",
                            "Airports": [
                                {
                                    "Id": "YRS",
                                    "Name": "Red Sucker Lake",
                                    "CityId": "YRSA",
                                    "CountryId": "CA",
                                    "Location": "-93.560534, 54.16732"
                                }
                            ]
                        },
                        {
                            "Id": "YKKA",
                            "Name": "Oona River",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-130.25336, 53.94947",
                            "IataCode": "YKK",
                            "Airports": [
                                {
                                    "Id": "YKK",
                                    "Name": "Kitkatla",
                                    "CityId": "YKKA",
                                    "CountryId": "CA",
                                    "Location": "-130, 53"
                                }
                            ]
                        },
                        {
                            "Id": "XPPA",
                            "Name": "Opekamank",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.71, 52.74753",
                            "IataCode": "XPP",
                            "Airports": [
                                {
                                    "Id": "XPP",
                                    "Name": "Poplar River",
                                    "CityId": "XPPA",
                                    "CountryId": "CA",
                                    "Location": "-97.3, 53"
                                }
                            ]
                        },
                        {
                            "Id": "YOHA",
                            "Name": "Pakitahokansik",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-95.4648, 54.80802",
                            "IataCode": "YOH",
                            "Airports": [
                                {
                                    "Id": "YOH",
                                    "Name": "Oxford House",
                                    "CityId": "YOHA",
                                    "CountryId": "CA",
                                    "Location": "-95.283333, 54.95"
                                }
                            ]
                        },
                        {
                            "Id": "YDKA",
                            "Name": "Point Alexandria",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.34939, 44.13341",
                            "IataCode": "YDK",
                            "Airports": [
                                {
                                    "Id": "YDK",
                                    "Name": "Main Duck Island",
                                    "CityId": "YDKA",
                                    "CountryId": "CA",
                                    "Location": "-76.616667, 43.933333"
                                }
                            ]
                        },
                        {
                            "Id": "YCJA",
                            "Name": "Rose Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-131.08483, 52.14745",
                            "IataCode": "YCJ",
                            "Airports": [
                                {
                                    "Id": "YCJ",
                                    "Name": "Cape St James",
                                    "CityId": "YCJA",
                                    "CountryId": "CA",
                                    "Location": "-131.01667, 51.933333"
                                }
                            ]
                        },
                        {
                            "Id": "PIWA",
                            "Name": "Sakitaw",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.20984, 55.6544",
                            "IataCode": "PIW",
                            "Airports": [
                                {
                                    "Id": "PIW",
                                    "Name": "Pikwitonei",
                                    "CityId": "PIWA",
                                    "CountryId": "CA",
                                    "Location": "-97.333333, 55.7"
                                }
                            ]
                        },
                        {
                            "Id": "YBIA",
                            "Name": "Salmon Bight",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-55.78124, 53.46678",
                            "IataCode": "YBI",
                            "Airports": [
                                {
                                    "Id": "YBI",
                                    "Name": "Black Tickle",
                                    "CityId": "YBIA",
                                    "CountryId": "CA",
                                    "Location": "-55.733333, 53.45"
                                }
                            ]
                        },
                        {
                            "Id": "YWMA",
                            "Name": "Sandy Hook",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-55.79798, 52.53339",
                            "IataCode": "YWM",
                            "Airports": [
                                {
                                    "Id": "YWM",
                                    "Name": "Williams Harbour",
                                    "CityId": "YWMA",
                                    "CountryId": "CA",
                                    "Location": "-55.7847, 52.5669"
                                }
                            ]
                        },
                        {
                            "Id": "YAJA",
                            "Name": "Saturna",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.20262, 48.79965",
                            "IataCode": "YAJ",
                            "Airports": [
                                {
                                    "Id": "YAJ",
                                    "Name": "Lyall Harbour",
                                    "CityId": "YAJA",
                                    "CountryId": "CA",
                                    "Location": "-123.2, 48.8"
                                }
                            ]
                        },
                        {
                            "Id": "YBTA",
                            "Name": "Sawbill",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-101.7246, 57.63697",
                            "IataCode": "YBT",
                            "Airports": [
                                {
                                    "Id": "YBT",
                                    "Name": "Brochet",
                                    "CityId": "YBTA",
                                    "CountryId": "CA",
                                    "Location": "-101.66667, 57.883333"
                                }
                            ]
                        },
                        {
                            "Id": "YGEA",
                            "Name": "Seaford",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-124.90287, 50.08294",
                            "IataCode": "YGE",
                            "Airports": [
                                {
                                    "Id": "YGE",
                                    "Name": "Gorge Harbor",
                                    "CityId": "YGEA",
                                    "CountryId": "CA",
                                    "Location": "-124.95, 50.083333"
                                }
                            ]
                        },
                        {
                            "Id": "YBHA",
                            "Name": "Shushartie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-127.85742, 50.85382",
                            "IataCode": "YBH",
                            "Airports": [
                                {
                                    "Id": "YBH",
                                    "Name": "Bull Harbour",
                                    "CityId": "YBHA",
                                    "CountryId": "CA",
                                    "Location": "-127.91667, 50.9"
                                }
                            ]
                        },
                        {
                            "Id": "ILFA",
                            "Name": "Split Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-95.8271, 56.01695",
                            "IataCode": "ILF",
                            "Airports": [
                                {
                                    "Id": "ILF",
                                    "Name": "Ilford",
                                    "CityId": "ILFA",
                                    "CountryId": "CA",
                                    "Location": "-95.616667, 56.066667"
                                }
                            ]
                        },
                        {
                            "Id": "XBBA",
                            "Name": "Sproatt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.03607, 50.08299",
                            "IataCode": "XBB",
                            "Airports": [
                                {
                                    "Id": "XBB",
                                    "Name": "Blubber Bay",
                                    "CityId": "XBBA",
                                    "CountryId": "CA",
                                    "Location": "-123, 50"
                                }
                            ]
                        },
                        {
                            "Id": "YKCA",
                            "Name": "Stella",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-76.69942, 44.16682",
                            "IataCode": "YKC",
                            "Airports": [
                                {
                                    "Id": "YKC",
                                    "Name": "Collins Bay",
                                    "CityId": "YKCA",
                                    "CountryId": "CA",
                                    "Location": "-76.600001, 44.25"
                                }
                            ]
                        },
                        {
                            "Id": "YBMA",
                            "Name": "Stikine",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-131.80348, 56.69941",
                            "IataCode": "YBM",
                            "Airports": [
                                {
                                    "Id": "YBM",
                                    "Name": "Bronson Creek",
                                    "CityId": "YBMA",
                                    "CountryId": "CA",
                                    "Location": "-131, 56.7"
                                }
                            ]
                        },
                        {
                            "Id": "KESA",
                            "Name": "Stitt",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-96.57685, 55.83964",
                            "IataCode": "KES",
                            "Airports": [
                                {
                                    "Id": "KES",
                                    "Name": "Kelsey",
                                    "CityId": "KESA",
                                    "CountryId": "CA",
                                    "Location": "-96.5, 56.016667"
                                }
                            ]
                        },
                        {
                            "Id": "XMPA",
                            "Name": "Summit Roadhouse",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-136.43733, 63.08286",
                            "IataCode": "XMP",
                            "Airports": [
                                {
                                    "Id": "XMP",
                                    "Name": "Macmillan Pass",
                                    "CityId": "XMPA",
                                    "CountryId": "CA",
                                    "Location": "-135.91667, 62.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YTXA",
                            "Name": "Tahltan",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-131.00387, 58.01636",
                            "IataCode": "YTX",
                            "Airports": [
                                {
                                    "Id": "YTX",
                                    "Name": "Telegraph Creek",
                                    "CityId": "YTXA",
                                    "CountryId": "CA",
                                    "Location": "-131.15, 57.9"
                                }
                            ]
                        },
                        {
                            "Id": "QBCA",
                            "Name": "Tallheo",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.83651, 52.383",
                            "IataCode": "QBC",
                            "Airports": [
                                {
                                    "Id": "QBC",
                                    "Name": "Bella Coola",
                                    "CityId": "QBCA",
                                    "CountryId": "CA",
                                    "Location": "-126.59583, 52.3875"
                                }
                            ]
                        },
                        {
                            "Id": "YSIA",
                            "Name": "The Depot",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-80.09967, 45.98338",
                            "IataCode": "YSI",
                            "Airports": [
                                {
                                    "Id": "YSI",
                                    "Name": "Sans Souci",
                                    "CityId": "YSIA",
                                    "CountryId": "CA",
                                    "Location": "-80, 46"
                                }
                            ]
                        },
                        {
                            "Id": "YCRA",
                            "Name": "The Elbow",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-97.68165, 54.41092",
                            "IataCode": "YCR",
                            "Airports": [
                                {
                                    "Id": "YCR",
                                    "Name": "Cross Lake",
                                    "CityId": "YCRA",
                                    "CountryId": "CA",
                                    "Location": "-97.8, 54.633333"
                                }
                            ]
                        },
                        {
                            "Id": "KNVA",
                            "Name": "Thompson Sound",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-126.01971, 50.79964",
                            "IataCode": "KNV",
                            "Airports": [
                                {
                                    "Id": "KNV",
                                    "Name": "Knights Inlet",
                                    "CityId": "KNVA",
                                    "CountryId": "CA",
                                    "Location": "-125.83333, 50.683333"
                                }
                            ]
                        },
                        {
                            "Id": "YEMA",
                            "Name": "Vanzant's Landing",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-81.8812, 45.75647",
                            "IataCode": "YEM",
                            "Airports": [
                                {
                                    "Id": "YEM",
                                    "Name": "Manitowaning",
                                    "CityId": "YEMA",
                                    "CountryId": "CA",
                                    "Location": "-81.858056, 45.842778"
                                }
                            ]
                        },
                        {
                            "Id": "ZRJA",
                            "Name": "Weagamow Lake",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-91.312778, 52.943611",
                            "IataCode": "ZRJ",
                            "Airports": [
                                {
                                    "Id": "ZRJ",
                                    "Name": "Round Lake",
                                    "CityId": "ZRJA",
                                    "CountryId": "CA",
                                    "Location": "-91.312778, 52.943611"
                                }
                            ]
                        },
                        {
                            "Id": "ZSJA",
                            "Name": "Weesakachak",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-93.79262, 53.74409",
                            "IataCode": "ZSJ",
                            "Airports": [
                                {
                                    "Id": "ZSJ",
                                    "Name": "Sandy Lake",
                                    "CityId": "ZSJA",
                                    "CountryId": "CA",
                                    "Location": "-93.34444, 53.064167"
                                }
                            ]
                        },
                        {
                            "Id": "YTBA",
                            "Name": "Weewanie",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-128.78663, 53.68283",
                            "IataCode": "YTB",
                            "Airports": [
                                {
                                    "Id": "YTB",
                                    "Name": "Hartley Bay",
                                    "CityId": "YTBA",
                                    "CountryId": "CA",
                                    "Location": "-129.25, 53.416667"
                                }
                            ]
                        },
                        {
                            "Id": "YCIA",
                            "Name": "Inner Whaleback Rocks",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-113.7625, 61.91944",
                            "IataCode": "YCI",
                            "Airports": [
                                {
                                    "Id": "YCI",
                                    "Name": "Caribou Island",
                                    "CityId": "YCIA",
                                    "CountryId": "CA",
                                    "Location": "-113.25, 61.916667"
                                }
                            ]
                        },
                        {
                            "Id": "YAVA",
                            "Name": "Montague Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-123.39166, 48.89452",
                            "IataCode": "YAV",
                            "Airports": [
                                {
                                    "Id": "YAV",
                                    "Name": "Miners Bay",
                                    "CityId": "YAVA",
                                    "CountryId": "CA",
                                    "Location": "-123.3, 48.866667"
                                }
                            ]
                        },
                        {
                            "Id": "YCZA",
                            "Name": "Fairmont Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "CA",
                            "Location": "-115.85596, 50.34052",
                            "IataCode": "YCZ",
                            "Airports": [
                                {
                                    "Id": "YCZ",
                                    "Name": "Fairmount Springs",
                                    "CityId": "YCZA",
                                    "CountryId": "CA",
                                    "Location": "-115.87361, 50.331944"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MX",
                    "Name": "Mexico",
                    "CurrencyId": "MXN",
                    "Cities": [
                        {
                            "Id": "CZMA",
                            "Name": "Cozumel",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-86.894263, 20.452687",
                            "IataCode": "CZM",
                            "Airports": [
                                {
                                    "Id": "CZM",
                                    "Name": "Cozumel",
                                    "CityId": "CZMA",
                                    "CountryId": "MX",
                                    "Location": "-86.933333, 20.516667"
                                }
                            ]
                        },
                        {
                            "Id": "SCXA",
                            "Name": "Salina Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-95.2, 16.166667",
                            "IataCode": "SCX",
                            "Airports": [
                                {
                                    "Id": "SCX",
                                    "Name": "Salina Cruz",
                                    "CityId": "SCXA",
                                    "CountryId": "MX",
                                    "Location": "-95.2, 16.166667"
                                }
                            ]
                        },
                        {
                            "Id": "XALA",
                            "Name": "Alamos",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-108.93333, 27.016667",
                            "IataCode": "XAL",
                            "Airports": [
                                {
                                    "Id": "XAL",
                                    "Name": "Alamos",
                                    "CityId": "XALA",
                                    "CountryId": "MX",
                                    "Location": "-108.93333, 27.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ZCLA",
                            "Name": "Zacatecas",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-102.57218, 22.775588",
                            "IataCode": "ZCL",
                            "Airports": [
                                {
                                    "Id": "ZCL",
                                    "Name": "Zacatecas",
                                    "CityId": "ZCLA",
                                    "CountryId": "MX",
                                    "Location": "-102.68694, 22.897222"
                                }
                            ]
                        },
                        {
                            "Id": "ZLOA",
                            "Name": "Manzanillo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-104.31879, 19.050111",
                            "IataCode": "ZLO",
                            "Airports": [
                                {
                                    "Id": "ZLO",
                                    "Name": "Manzanillo",
                                    "CityId": "ZLOA",
                                    "CountryId": "MX",
                                    "Location": "-104.55863, 19.144778"
                                }
                            ]
                        },
                        {
                            "Id": "CENA",
                            "Name": "Ciudad Obregon",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-109.93333, 27.483334",
                            "IataCode": "CEN",
                            "Airports": [
                                {
                                    "Id": "CEN",
                                    "Name": "Ciudad Obregon",
                                    "CityId": "CENA",
                                    "CountryId": "MX",
                                    "Location": "-109.83917, 27.391944"
                                }
                            ]
                        },
                        {
                            "Id": "CJSA",
                            "Name": "Ciudad Juarez",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-106.48333, 31.733336",
                            "IataCode": "CJS",
                            "Airports": [
                                {
                                    "Id": "CJS",
                                    "Name": "Ciudad Juarez",
                                    "CityId": "CJSA",
                                    "CountryId": "MX",
                                    "Location": "-106.42902, 31.63599"
                                }
                            ]
                        },
                        {
                            "Id": "CJTA",
                            "Name": "Comitan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-92.133333, 16.25",
                            "IataCode": "CJT",
                            "Airports": [
                                {
                                    "Id": "CJT",
                                    "Name": "Comitan",
                                    "CityId": "CJTA",
                                    "CountryId": "MX",
                                    "Location": "-92.05, 16.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CLQA",
                            "Name": "Colima",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-103.71667, 19.233333",
                            "IataCode": "CLQ",
                            "Airports": [
                                {
                                    "Id": "CLQ",
                                    "Name": "Colima",
                                    "CityId": "CLQA",
                                    "CountryId": "MX",
                                    "Location": "-103.5775, 19.276944"
                                }
                            ]
                        },
                        {
                            "Id": "CMEA",
                            "Name": "Ciudad Del Carmen",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-91.833333, 18.633333",
                            "IataCode": "CME",
                            "Airports": [
                                {
                                    "Id": "CME",
                                    "Name": "Ciudad Del Carmen",
                                    "CityId": "CMEA",
                                    "CountryId": "MX",
                                    "Location": "-91.8, 18.65"
                                }
                            ]
                        },
                        {
                            "Id": "CNAA",
                            "Name": "Cananea",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-110.3, 30.95",
                            "IataCode": "CNA",
                            "Airports": [
                                {
                                    "Id": "CNA",
                                    "Name": "Cananea",
                                    "CityId": "CNAA",
                                    "CountryId": "MX",
                                    "Location": "-110.33333, 31"
                                }
                            ]
                        },
                        {
                            "Id": "CPEA",
                            "Name": "Campeche",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-90.533334, 19.85",
                            "IataCode": "CPE",
                            "Airports": [
                                {
                                    "Id": "CPE",
                                    "Name": "Campeche",
                                    "CityId": "CPEA",
                                    "CountryId": "MX",
                                    "Location": "-90.509444, 19.835"
                                }
                            ]
                        },
                        {
                            "Id": "CTMA",
                            "Name": "Chetumal",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-88.3, 18.5",
                            "IataCode": "CTM",
                            "Airports": [
                                {
                                    "Id": "CTM",
                                    "Name": "Chetumal",
                                    "CityId": "CTMA",
                                    "CountryId": "MX",
                                    "Location": "-88.326847, 18.504667"
                                }
                            ]
                        },
                        {
                            "Id": "CULA",
                            "Name": "Culiacan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-107.38972, 24.799444",
                            "IataCode": "CUL",
                            "Airports": [
                                {
                                    "Id": "CUL",
                                    "Name": "Culiacan",
                                    "CityId": "CULA",
                                    "CountryId": "MX",
                                    "Location": "-107.47417, 24.764444"
                                }
                            ]
                        },
                        {
                            "Id": "CUNA",
                            "Name": "Cancun",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-86.869606, 21.14023",
                            "IataCode": "CUN",
                            "Airports": [
                                {
                                    "Id": "CUN",
                                    "Name": "Cancun",
                                    "CityId": "CUNA",
                                    "CountryId": "MX",
                                    "Location": "-86.866667, 21.033333"
                                }
                            ]
                        },
                        {
                            "Id": "CUUA",
                            "Name": "Chihuahua",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-106.08333, 28.633333",
                            "IataCode": "CUU",
                            "Airports": [
                                {
                                    "Id": "CUU",
                                    "Name": "Chihuahua",
                                    "CityId": "CUUA",
                                    "CountryId": "MX",
                                    "Location": "-105.96444, 28.702778"
                                }
                            ]
                        },
                        {
                            "Id": "CVJA",
                            "Name": "Cuernavaca",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-99.25, 18.916667",
                            "IataCode": "CVJ",
                            "Airports": [
                                {
                                    "Id": "CVJ",
                                    "Name": "Cuernavaca",
                                    "CityId": "CVJA",
                                    "CountryId": "MX",
                                    "Location": "-99.262222, 18.835"
                                }
                            ]
                        },
                        {
                            "Id": "CVMA",
                            "Name": "Ciudad Victoria",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-99.133333, 23.733333",
                            "IataCode": "CVM",
                            "Airports": [
                                {
                                    "Id": "CVM",
                                    "Name": "Ciudad Victoria",
                                    "CityId": "CVMA",
                                    "CountryId": "MX",
                                    "Location": "-98.965278, 23.713889"
                                }
                            ]
                        },
                        {
                            "Id": "CYWB",
                            "Name": "Ciudad de Celaya",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-100.81667, 20.516667",
                            "IataCode": "CYW",
                            "Airports": [
                                {
                                    "Id": "CYW",
                                    "Name": "Ciudad de Celaya",
                                    "CityId": "CYWB",
                                    "CountryId": "MX",
                                    "Location": "-100.9006, 20.5506"
                                }
                            ]
                        },
                        {
                            "Id": "CZAA",
                            "Name": "Chichen Itza",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-88.566667, 20.666667",
                            "IataCode": "CZA",
                            "Airports": [
                                {
                                    "Id": "CZA",
                                    "Name": "Chichen Itza",
                                    "CityId": "CZAA",
                                    "CountryId": "MX",
                                    "Location": "-88.566667, 20.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DGOA",
                            "Name": "Durango",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-104.66667, 24.033333",
                            "IataCode": "DGO",
                            "Airports": [
                                {
                                    "Id": "DGO",
                                    "Name": "Durango",
                                    "CityId": "DGOA",
                                    "CountryId": "MX",
                                    "Location": "-104.525, 24.125"
                                }
                            ]
                        },
                        {
                            "Id": "ESEA",
                            "Name": "Ensenada",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-116.61667, 31.866668",
                            "IataCode": "ESE",
                            "Airports": [
                                {
                                    "Id": "ESE",
                                    "Name": "Ensenada",
                                    "CityId": "ESEA",
                                    "CountryId": "MX",
                                    "Location": "-116.61667, 31.866667"
                                }
                            ]
                        },
                        {
                            "Id": "GDLA",
                            "Name": "Guadalajara",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-103.33333, 20.666669",
                            "IataCode": "GDL",
                            "Airports": [
                                {
                                    "Id": "GDL",
                                    "Name": "Guadalajara",
                                    "CityId": "GDLA",
                                    "CountryId": "MX",
                                    "Location": "-103.3125, 20.523889"
                                }
                            ]
                        },
                        {
                            "Id": "GUBA",
                            "Name": "Guerrero Negro",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-114.06111, 27.976944",
                            "IataCode": "GUB",
                            "Airports": [
                                {
                                    "Id": "GUB",
                                    "Name": "Guerrero Negro",
                                    "CityId": "GUBA",
                                    "CountryId": "MX",
                                    "Location": "-114.02349, 28.025482"
                                }
                            ]
                        },
                        {
                            "Id": "GYMA",
                            "Name": "Guaymas",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-110.9, 27.933333",
                            "IataCode": "GYM",
                            "Airports": [
                                {
                                    "Id": "GYM",
                                    "Name": "Guaymas",
                                    "CityId": "GYMA",
                                    "CountryId": "MX",
                                    "Location": "-110.9225, 27.968719"
                                }
                            ]
                        },
                        {
                            "Id": "HMOA",
                            "Name": "Hermosillo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-110.96667, 29.066668",
                            "IataCode": "HMO",
                            "Airports": [
                                {
                                    "Id": "HMO",
                                    "Name": "Hermosillo",
                                    "CityId": "HMOA",
                                    "CountryId": "MX",
                                    "Location": "-111.04611, 29.096389"
                                }
                            ]
                        },
                        {
                            "Id": "HUXA",
                            "Name": "Huatulco",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-96.326667, 15.843611",
                            "IataCode": "HUX",
                            "Airports": [
                                {
                                    "Id": "HUX",
                                    "Name": "Huatulco",
                                    "CityId": "HUXA",
                                    "CountryId": "MX",
                                    "Location": "-96.235556, 15.768333"
                                }
                            ]
                        },
                        {
                            "Id": "IZTA",
                            "Name": "Ixtepec",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-95.102227, 16.578555",
                            "IataCode": "IZT",
                            "Airports": [
                                {
                                    "Id": "IZT",
                                    "Name": "Ixtepec",
                                    "CityId": "IZTA",
                                    "CountryId": "MX",
                                    "Location": "-95.108333, 16.569167"
                                }
                            ]
                        },
                        {
                            "Id": "JALA",
                            "Name": "Jalapa",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-96.916667, 19.533333",
                            "IataCode": "JAL",
                            "Airports": [
                                {
                                    "Id": "JAL",
                                    "Name": "Jalapa",
                                    "CityId": "JALA",
                                    "CountryId": "MX",
                                    "Location": "-96.781944, 19.472222"
                                }
                            ]
                        },
                        {
                            "Id": "LAPB",
                            "Name": "La Paz",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-110.3, 24.166667",
                            "IataCode": "LAP",
                            "Airports": [
                                {
                                    "Id": "LAP",
                                    "Name": "La Paz",
                                    "CityId": "LAPB",
                                    "CountryId": "MX",
                                    "Location": "-110.29833, 24.125556"
                                }
                            ]
                        },
                        {
                            "Id": "LMMA",
                            "Name": "Los Mochis",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-108.96667, 25.766667",
                            "IataCode": "LMM",
                            "Airports": [
                                {
                                    "Id": "LMM",
                                    "Name": "Los Mochis",
                                    "CityId": "LMMA",
                                    "CountryId": "MX",
                                    "Location": "-109.08083, 25.685278"
                                }
                            ]
                        },
                        {
                            "Id": "LOMA",
                            "Name": "Lagos de Moreno",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101.91667, 21.35",
                            "IataCode": "LOM",
                            "Airports": [
                                {
                                    "Id": "LOM",
                                    "Name": "Lagos de Moreno",
                                    "CityId": "LOMA",
                                    "CountryId": "MX",
                                    "Location": "-101.93333, 21.266667"
                                }
                            ]
                        },
                        {
                            "Id": "LOVA",
                            "Name": "Monclova",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101.41667, 26.9",
                            "IataCode": "LOV",
                            "Airports": [
                                {
                                    "Id": "LOV",
                                    "Name": "Monclova",
                                    "CityId": "LOVA",
                                    "CountryId": "MX",
                                    "Location": "-101.47014, 26.95573"
                                }
                            ]
                        },
                        {
                            "Id": "LTOA",
                            "Name": "Loreto",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-111.35, 26.016667",
                            "IataCode": "LTO",
                            "Airports": [
                                {
                                    "Id": "LTO",
                                    "Name": "Loreto",
                                    "CityId": "LTOA",
                                    "CountryId": "MX",
                                    "Location": "-111.35, 25.983333"
                                }
                            ]
                        },
                        {
                            "Id": "LZCA",
                            "Name": "Lazaro Cardenas",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-102.2, 17.958333",
                            "IataCode": "LZC",
                            "Airports": [
                                {
                                    "Id": "LZC",
                                    "Name": "Lazaro Cardenas",
                                    "CityId": "LZCA",
                                    "CountryId": "MX",
                                    "Location": "-102.21667, 18"
                                }
                            ]
                        },
                        {
                            "Id": "MAMA",
                            "Name": "Matamoros",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-97.5, 25.883333",
                            "IataCode": "MAM",
                            "Airports": [
                                {
                                    "Id": "MAM",
                                    "Name": "Matamoros",
                                    "CityId": "MAMA",
                                    "CountryId": "MX",
                                    "Location": "-97.523611, 25.770278"
                                }
                            ]
                        },
                        {
                            "Id": "MIDA",
                            "Name": "Merida",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-89.616666, 20.966667",
                            "IataCode": "MID",
                            "Airports": [
                                {
                                    "Id": "MID",
                                    "Name": "Merida",
                                    "CityId": "MIDA",
                                    "CountryId": "MX",
                                    "Location": "-89.65, 20.933333"
                                }
                            ]
                        },
                        {
                            "Id": "MLMA",
                            "Name": "Morelia",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101.18442, 19.700779",
                            "IataCode": "MLM",
                            "Airports": [
                                {
                                    "Id": "MLM",
                                    "Name": "Morelia",
                                    "CityId": "MLMA",
                                    "CountryId": "MX",
                                    "Location": "-101.01667, 19.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MTTA",
                            "Name": "Minatitlan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-94.516667, 17.983333",
                            "IataCode": "MTT",
                            "Airports": [
                                {
                                    "Id": "MTT",
                                    "Name": "Minatitlan",
                                    "CityId": "MTTA",
                                    "CountryId": "MX",
                                    "Location": "-94.580556, 18.103333"
                                }
                            ]
                        },
                        {
                            "Id": "MTYA",
                            "Name": "Monterrey",
                            "SingleAirportCity": false,
                            "CountryId": "MX",
                            "Location": "-100.31667, 25.666667",
                            "IataCode": "MTY",
                            "Airports": [
                                {
                                    "Id": "NTR",
                                    "Name": "Monterrey Aeropuerto Del Norte",
                                    "CityId": "MTYA",
                                    "CountryId": "MX",
                                    "Location": "-100.23724, 25.86557"
                                },
                                {
                                    "Id": "MTY",
                                    "Name": "Monterrey Gen Mariano Escobedo",
                                    "CityId": "MTYA",
                                    "CountryId": "MX",
                                    "Location": "-100.1, 25.766667"
                                }
                            ]
                        },
                        {
                            "Id": "MXLA",
                            "Name": "Mexicali",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-115.46833, 32.651944",
                            "IataCode": "MXL",
                            "Airports": [
                                {
                                    "Id": "MXL",
                                    "Name": "Mexicali",
                                    "CityId": "MXLA",
                                    "CountryId": "MX",
                                    "Location": "-115.23333, 32.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MZTA",
                            "Name": "Mazatlan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-106.41667, 23.216667",
                            "IataCode": "MZT",
                            "Airports": [
                                {
                                    "Id": "MZT",
                                    "Name": "Mazatlan",
                                    "CityId": "MZTA",
                                    "CountryId": "MX",
                                    "Location": "-106.27028, 23.166837"
                                }
                            ]
                        },
                        {
                            "Id": "NLDA",
                            "Name": "Nuevo Laredo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-99.516667, 27.5",
                            "IataCode": "NLD",
                            "Airports": [
                                {
                                    "Id": "NLD",
                                    "Name": "Nuevo Laredo",
                                    "CityId": "NLDA",
                                    "CountryId": "MX",
                                    "Location": "-99.566667, 27.434722"
                                }
                            ]
                        },
                        {
                            "Id": "NOGA",
                            "Name": "Nogales",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-110.93333, 31.333333",
                            "IataCode": "NOG",
                            "Airports": [
                                {
                                    "Id": "NOG",
                                    "Name": "Nogales",
                                    "CityId": "NOGA",
                                    "CountryId": "MX",
                                    "Location": "-110.97583, 31.226111"
                                }
                            ]
                        },
                        {
                            "Id": "OAXA",
                            "Name": "Oaxaca",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-96.716667, 17.05",
                            "IataCode": "OAX",
                            "Airports": [
                                {
                                    "Id": "OAX",
                                    "Name": "Oaxaca",
                                    "CityId": "OAXA",
                                    "CountryId": "MX",
                                    "Location": "-96.733333, 17"
                                }
                            ]
                        },
                        {
                            "Id": "PAZA",
                            "Name": "Poza Rica",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-97.459459, 20.533148",
                            "IataCode": "PAZ",
                            "Airports": [
                                {
                                    "Id": "PAZ",
                                    "Name": "Poza Rica",
                                    "CityId": "PAZA",
                                    "CountryId": "MX",
                                    "Location": "-97.460833, 20.602778"
                                }
                            ]
                        },
                        {
                            "Id": "PBCA",
                            "Name": "Puebla",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-98.2, 19.050001",
                            "IataCode": "PBC",
                            "Airports": [
                                {
                                    "Id": "PBC",
                                    "Name": "Puebla",
                                    "CityId": "PBCA",
                                    "CountryId": "MX",
                                    "Location": "-98.3675, 19.135"
                                }
                            ]
                        },
                        {
                            "Id": "PDSA",
                            "Name": "Piedras Negras",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-100.51667, 28.700001",
                            "IataCode": "PDS",
                            "Airports": [
                                {
                                    "Id": "PDS",
                                    "Name": "Piedras Negras",
                                    "CityId": "PDSA",
                                    "CountryId": "MX",
                                    "Location": "-100.53409, 28.628377"
                                }
                            ]
                        },
                        {
                            "Id": "PVRA",
                            "Name": "Puerto Vallarta",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-105.23066, 20.620414",
                            "IataCode": "PVR",
                            "Airports": [
                                {
                                    "Id": "PVR",
                                    "Name": "Puerto Vallarta",
                                    "CityId": "PVRA",
                                    "CountryId": "MX",
                                    "Location": "-105.265, 20.686667"
                                }
                            ]
                        },
                        {
                            "Id": "PXMB",
                            "Name": "Puerto Escondido",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-97.066667, 15.85",
                            "IataCode": "PXM",
                            "Airports": [
                                {
                                    "Id": "PXM",
                                    "Name": "Puerto Escondido",
                                    "CityId": "PXMB",
                                    "CountryId": "MX",
                                    "Location": "-97.069359, 15.865581"
                                }
                            ]
                        },
                        {
                            "Id": "QUEA",
                            "Name": "Queretaro",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-100.38333, 20.600001",
                            "IataCode": "QRO",
                            "Airports": [
                                {
                                    "Id": "QRO",
                                    "Name": "Queretaro",
                                    "CityId": "QUEA",
                                    "CountryId": "MX",
                                    "Location": "-100.19, 20.62"
                                }
                            ]
                        },
                        {
                            "Id": "REXA",
                            "Name": "Reynosa",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-98.283333, 26.083333",
                            "IataCode": "REX",
                            "Airports": [
                                {
                                    "Id": "REX",
                                    "Name": "Reynosa",
                                    "CityId": "REXA",
                                    "CountryId": "MX",
                                    "Location": "-98.23, 26.0125"
                                }
                            ]
                        },
                        {
                            "Id": "SLPA",
                            "Name": "San Luis Potosi",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-100.98333, 22.15",
                            "IataCode": "SLP",
                            "Airports": [
                                {
                                    "Id": "SLP",
                                    "Name": "San Luis Potosi",
                                    "CityId": "SLPA",
                                    "CountryId": "MX",
                                    "Location": "-100.98833, 22.177222"
                                }
                            ]
                        },
                        {
                            "Id": "SLWA",
                            "Name": "Saltillo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101, 25.416667",
                            "IataCode": "SLW",
                            "Airports": [
                                {
                                    "Id": "SLW",
                                    "Name": "Saltillo",
                                    "CityId": "SLWA",
                                    "CountryId": "MX",
                                    "Location": "-100.91667, 25.55"
                                }
                            ]
                        },
                        {
                            "Id": "SZTA",
                            "Name": "S.Cristobal deL Casas",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-92.633333, 16.75",
                            "IataCode": "SZT",
                            "Airports": [
                                {
                                    "Id": "SZT",
                                    "Name": "S.Cristobal deL Casas",
                                    "CityId": "SZTA",
                                    "CountryId": "MX",
                                    "Location": "-92.544167, 16.690556"
                                }
                            ]
                        },
                        {
                            "Id": "TAMA",
                            "Name": "Tampico",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-97.85, 22.3",
                            "IataCode": "TAM",
                            "Airports": [
                                {
                                    "Id": "TAM",
                                    "Name": "Tampico",
                                    "CityId": "TAMA",
                                    "CountryId": "MX",
                                    "Location": "-97.866383, 22.297514"
                                }
                            ]
                        },
                        {
                            "Id": "TAPA",
                            "Name": "Tapachula",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-92.283333, 14.9",
                            "IataCode": "TAP",
                            "Airports": [
                                {
                                    "Id": "TAP",
                                    "Name": "Tapachula",
                                    "CityId": "TAPA",
                                    "CountryId": "MX",
                                    "Location": "-92.37, 14.794444"
                                }
                            ]
                        },
                        {
                            "Id": "TCNA",
                            "Name": "Tehuacan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-97.383334, 18.45",
                            "IataCode": "TCN",
                            "Airports": [
                                {
                                    "Id": "TCN",
                                    "Name": "Tehuacan",
                                    "CityId": "TCNA",
                                    "CountryId": "MX",
                                    "Location": "-97.417778, 18.499722"
                                }
                            ]
                        },
                        {
                            "Id": "TGZA",
                            "Name": "Tuxtla Gutierrez",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-93.116667, 16.75",
                            "IataCode": "TGZ",
                            "Airports": [
                                {
                                    "Id": "TGZ",
                                    "Name": "Tuxtla Gutierrez",
                                    "CityId": "TGZA",
                                    "CountryId": "MX",
                                    "Location": "-93.026111, 16.561944"
                                }
                            ]
                        },
                        {
                            "Id": "TIJA",
                            "Name": "Tijuana",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-117.01667, 32.533333",
                            "IataCode": "TIJ",
                            "Airports": [
                                {
                                    "Id": "TIJ",
                                    "Name": "Tijuana",
                                    "CityId": "TIJA",
                                    "CountryId": "MX",
                                    "Location": "-116.97028, 32.541389"
                                }
                            ]
                        },
                        {
                            "Id": "TLCA",
                            "Name": "Toluca",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-99.667222, 19.288333",
                            "IataCode": "TLC",
                            "Airports": [
                                {
                                    "Id": "TLC",
                                    "Name": "Toluca",
                                    "CityId": "TLCA",
                                    "CountryId": "MX",
                                    "Location": "-99.566111, 19.336944"
                                }
                            ]
                        },
                        {
                            "Id": "TPQA",
                            "Name": "Tepic",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-104.9, 21.5",
                            "IataCode": "TPQ",
                            "Airports": [
                                {
                                    "Id": "TPQ",
                                    "Name": "Tepic",
                                    "CityId": "TPQA",
                                    "CountryId": "MX",
                                    "Location": "-104.8425, 21.419444"
                                }
                            ]
                        },
                        {
                            "Id": "TRCA",
                            "Name": "Torreon",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-103.43333, 25.55",
                            "IataCode": "TRC",
                            "Airports": [
                                {
                                    "Id": "TRC",
                                    "Name": "Torreon",
                                    "CityId": "TRCA",
                                    "CountryId": "MX",
                                    "Location": "-103.41083, 25.568333"
                                }
                            ]
                        },
                        {
                            "Id": "TSLA",
                            "Name": "Tamuin",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-98.75, 21.983333",
                            "IataCode": "TSL",
                            "Airports": [
                                {
                                    "Id": "TSL",
                                    "Name": "Tamuin",
                                    "CityId": "TSLA",
                                    "CountryId": "MX",
                                    "Location": "-98.75, 21.983333"
                                }
                            ]
                        },
                        {
                            "Id": "TUYA",
                            "Name": "Tulum",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-87.466667, 20.216667",
                            "IataCode": "TUY",
                            "Airports": [
                                {
                                    "Id": "TUY",
                                    "Name": "Tulum",
                                    "CityId": "TUYA",
                                    "CountryId": "MX",
                                    "Location": "-87.466667, 20.216667"
                                }
                            ]
                        },
                        {
                            "Id": "UPNA",
                            "Name": "Uruapan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-102.06667, 19.416667",
                            "IataCode": "UPN",
                            "Airports": [
                                {
                                    "Id": "UPN",
                                    "Name": "Uruapan",
                                    "CityId": "UPNA",
                                    "CountryId": "MX",
                                    "Location": "-102.04722, 19.411111"
                                }
                            ]
                        },
                        {
                            "Id": "VERA",
                            "Name": "Veracruz",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-96.133334, 19.2",
                            "IataCode": "VER",
                            "Airports": [
                                {
                                    "Id": "VER",
                                    "Name": "Veracruz",
                                    "CityId": "VERA",
                                    "CountryId": "MX",
                                    "Location": "-96.183333, 19.15"
                                }
                            ]
                        },
                        {
                            "Id": "VSAA",
                            "Name": "Villahermosa",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-92.916667, 17.983333",
                            "IataCode": "VSA",
                            "Airports": [
                                {
                                    "Id": "VSA",
                                    "Name": "Villahermosa",
                                    "CityId": "VSAA",
                                    "CountryId": "MX",
                                    "Location": "-92.8175, 17.996944"
                                }
                            ]
                        },
                        {
                            "Id": "ACAA",
                            "Name": "Acapulco",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-99.890098, 16.863362",
                            "IataCode": "ACA",
                            "Airports": [
                                {
                                    "Id": "ACA",
                                    "Name": "Acapulco",
                                    "CityId": "ACAA",
                                    "CountryId": "MX",
                                    "Location": "-99.766944, 16.755556"
                                }
                            ]
                        },
                        {
                            "Id": "ACNA",
                            "Name": "Ciudad Acuna",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-100.91667, 29.3",
                            "IataCode": "ACN",
                            "Airports": [
                                {
                                    "Id": "ACN",
                                    "Name": "Ciudad Acuna",
                                    "CityId": "ACNA",
                                    "CountryId": "MX",
                                    "Location": "-101.099, 29.3329"
                                }
                            ]
                        },
                        {
                            "Id": "AGUA",
                            "Name": "Aguascalientes",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-102.3, 21.883334",
                            "IataCode": "AGU",
                            "Airports": [
                                {
                                    "Id": "AGU",
                                    "Name": "Aguascalientes",
                                    "CityId": "AGUA",
                                    "CountryId": "MX",
                                    "Location": "-102.31667, 21.7"
                                }
                            ]
                        },
                        {
                            "Id": "PPEA",
                            "Name": "Puerto Penasco",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-113.53333, 31.316667",
                            "IataCode": "PPE",
                            "Airports": [
                                {
                                    "Id": "PPE",
                                    "Name": "Puerto Penasco",
                                    "CityId": "PPEA",
                                    "CountryId": "MX",
                                    "Location": "-113.52583, 31.351944"
                                }
                            ]
                        },
                        {
                            "Id": "PQMA",
                            "Name": "Palenque",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-91.966667, 17.516667",
                            "IataCode": "PQM",
                            "Airports": [
                                {
                                    "Id": "PQM",
                                    "Name": "Palenque",
                                    "CityId": "PQMA",
                                    "CountryId": "MX",
                                    "Location": "-91.996422, 17.531767"
                                }
                            ]
                        },
                        {
                            "Id": "SFHA",
                            "Name": "San Felipe",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-114.86667, 31",
                            "IataCode": "SFH",
                            "Airports": [
                                {
                                    "Id": "SFH",
                                    "Name": "San Felipe",
                                    "CityId": "SFHA",
                                    "CountryId": "MX",
                                    "Location": "-114.809, 30.9302"
                                }
                            ]
                        },
                        {
                            "Id": "BHLA",
                            "Name": "Bahia Angeles",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-113.56667, 28.933333",
                            "IataCode": "BHL",
                            "Airports": [
                                {
                                    "Id": "BHL",
                                    "Name": "Bahia Angeles",
                                    "CityId": "BHLA",
                                    "CountryId": "MX",
                                    "Location": "-113.55, 28.95"
                                }
                            ]
                        },
                        {
                            "Id": "CUAA",
                            "Name": "Ciudad Constitucion",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-111.66444, 25.036944",
                            "IataCode": "CUA",
                            "Airports": [
                                {
                                    "Id": "CUA",
                                    "Name": "Ciudad Constitucion",
                                    "CityId": "CUAA",
                                    "CountryId": "MX",
                                    "Location": "-111.61164, 25.050419"
                                }
                            ]
                        },
                        {
                            "Id": "PCMA",
                            "Name": "Playa del Carmen",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-87.072365, 20.653086",
                            "IataCode": "PCM",
                            "Airports": [
                                {
                                    "Id": "PCM",
                                    "Name": "Playa del Carmen",
                                    "CityId": "PCMA",
                                    "CountryId": "MX",
                                    "Location": "-87.082199, 20.6225"
                                }
                            ]
                        },
                        {
                            "Id": "MMCA",
                            "Name": "Ciudad Mante",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-98.95, 22.733333",
                            "IataCode": "MMC",
                            "Airports": [
                                {
                                    "Id": "MMC",
                                    "Name": "Ciudad Mante",
                                    "CityId": "MMCA",
                                    "CountryId": "MX",
                                    "Location": "-99, 22.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MUGA",
                            "Name": "Mulege",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-111.98333, 26.883333",
                            "IataCode": "MUG",
                            "Airports": [
                                {
                                    "Id": "MUG",
                                    "Name": "Mulege",
                                    "CityId": "MUGA",
                                    "CountryId": "MX",
                                    "Location": "-111.98333, 26.916667"
                                }
                            ]
                        },
                        {
                            "Id": "NCGA",
                            "Name": "Nueva Casas Grandes",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-107.91667, 30.416667",
                            "IataCode": "NCG",
                            "Airports": [
                                {
                                    "Id": "NCG",
                                    "Name": "Nueva Casas Grandes",
                                    "CityId": "NCGA",
                                    "CountryId": "MX",
                                    "Location": "-107.86667, 30.38333"
                                }
                            ]
                        },
                        {
                            "Id": "PCVA",
                            "Name": "Punta Chivato",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-111.96139, 27.075278",
                            "IataCode": "PCV",
                            "Airports": [
                                {
                                    "Id": "PCV",
                                    "Name": "Punta Chivato",
                                    "CityId": "PCVA",
                                    "CountryId": "MX",
                                    "Location": "-111.962, 27.0692"
                                }
                            ]
                        },
                        {
                            "Id": "PUHA",
                            "Name": "Pochutla",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-96.466667, 15.733333",
                            "IataCode": "PUH",
                            "Airports": [
                                {
                                    "Id": "PUH",
                                    "Name": "Pochutla",
                                    "CityId": "PUHA",
                                    "CountryId": "MX",
                                    "Location": "-96.466667, 15.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SGMA",
                            "Name": "San Ignacio",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-112.9, 27.3",
                            "IataCode": "SGM",
                            "Airports": [
                                {
                                    "Id": "SGM",
                                    "Name": "San Ignacio",
                                    "CityId": "SGMA",
                                    "CountryId": "MX",
                                    "Location": "-112.938, 27.2966"
                                }
                            ]
                        },
                        {
                            "Id": "SNQA",
                            "Name": "San Quintin",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-115.95, 30.483333",
                            "IataCode": "SNQ",
                            "Airports": [
                                {
                                    "Id": "SNQ",
                                    "Name": "San Quintin",
                                    "CityId": "SNQA",
                                    "CountryId": "MX",
                                    "Location": "-116, 30.5"
                                }
                            ]
                        },
                        {
                            "Id": "SRLA",
                            "Name": "Santa Rosalia",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-112.28333, 27.316667",
                            "IataCode": "SRL",
                            "Airports": [
                                {
                                    "Id": "SRL",
                                    "Name": "Santa Rosalia",
                                    "CityId": "SRLA",
                                    "CountryId": "MX",
                                    "Location": "-112.25, 27.2"
                                }
                            ]
                        },
                        {
                            "Id": "TZMA",
                            "Name": "Tizimin",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-88.15, 21.15",
                            "IataCode": "TZM",
                            "Airports": [
                                {
                                    "Id": "TZM",
                                    "Name": "Tizimin",
                                    "CityId": "TZMA",
                                    "CountryId": "MX",
                                    "Location": "-88.15, 21.166667"
                                }
                            ]
                        },
                        {
                            "Id": "UACA",
                            "Name": "San Luis Rio Colorado",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-114.77667, 32.463056",
                            "IataCode": "UAC",
                            "Airports": [
                                {
                                    "Id": "UAC",
                                    "Name": "San Luis Rio Colorado",
                                    "CityId": "UACA",
                                    "CountryId": "MX",
                                    "Location": "-114.8, 32.45"
                                }
                            ]
                        },
                        {
                            "Id": "PJZA",
                            "Name": "Puerto Juarez",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-86.816667, 21.183333",
                            "IataCode": "PJZ",
                            "Airports": [
                                {
                                    "Id": "PJZ",
                                    "Name": "Puerto Juarez",
                                    "CityId": "PJZA",
                                    "CountryId": "MX",
                                    "Location": "-86.833333, 21.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MEXA",
                            "Name": "Mexico City",
                            "SingleAirportCity": false,
                            "CountryId": "MX",
                            "Location": "-99.117976, 19.428482",
                            "IataCode": "MEX",
                            "Airports": [
                                {
                                    "Id": "AZP",
                                    "Name": "Mexico City Atizapan",
                                    "CityId": "MEXA",
                                    "CountryId": "MX",
                                    "Location": "-99.1, 19.416667"
                                },
                                {
                                    "Id": "NLU",
                                    "Name": "Mexico City Santa Lucia",
                                    "CityId": "MEXA",
                                    "CountryId": "MX",
                                    "Location": "-99.15, 19.4"
                                },
                                {
                                    "Id": "MEX",
                                    "Name": "Mexico City Juarez International",
                                    "CityId": "MEXA",
                                    "CountryId": "MX",
                                    "Location": "-99.071898, 19.436066"
                                }
                            ]
                        },
                        {
                            "Id": "CSLM",
                            "Name": "Cabo San Lucas",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-109.91238, 22.890883",
                            "IataCode": "CSL",
                            "Airports": [
                                {
                                    "Id": "CSL",
                                    "Name": "Cabo San Lucas",
                                    "CityId": "CSLM",
                                    "CountryId": "MX",
                                    "Location": "-109.93788, 22.948047"
                                }
                            ]
                        },
                        {
                            "Id": "SJDA",
                            "Name": "San Jose Cabo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-109.68333, 23.05",
                            "IataCode": "SJD",
                            "Airports": [
                                {
                                    "Id": "SJD",
                                    "Name": "San Jose Cabo",
                                    "CityId": "SJDA",
                                    "CountryId": "MX",
                                    "Location": "-109.7, 23.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BJXS",
                            "Name": "Silao",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101.43333, 20.933333",
                            "IataCode": "BJX",
                            "Airports": [
                                {
                                    "Id": "BJX",
                                    "Name": "Silao",
                                    "CityId": "BJXS",
                                    "CountryId": "MX",
                                    "Location": "-101.47831, 20.987847"
                                }
                            ]
                        },
                        {
                            "Id": "ZIHA",
                            "Name": "Ixtapa / Zihuatanejo",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-101.55, 17.633333",
                            "IataCode": "ZIH",
                            "Airports": [
                                {
                                    "Id": "ZIH",
                                    "Name": "Ixtapa / Zihuatanejo",
                                    "CityId": "ZIHA",
                                    "CountryId": "MX",
                                    "Location": "-101.55528, 17.643333"
                                }
                            ]
                        },
                        {
                            "Id": "PNOA",
                            "Name": "Pinotepa Nacional",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-98.016667, 16.316667",
                            "IataCode": "PNO",
                            "Airports": [
                                {
                                    "Id": "PNO",
                                    "Name": "Pinotepa Nacional",
                                    "CityId": "PNOA",
                                    "CountryId": "MX",
                                    "Location": "-98.069444, 16.3425"
                                }
                            ]
                        },
                        {
                            "Id": "AZGA",
                            "Name": "Apatzingan",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-102.35, 19.083333",
                            "IataCode": "AZG",
                            "Airports": [
                                {
                                    "Id": "AZG",
                                    "Name": "Apatzingan",
                                    "CityId": "AZGA",
                                    "CountryId": "MX",
                                    "Location": "-102.38333, 19.08333"
                                }
                            ]
                        },
                        {
                            "Id": "ISJA",
                            "Name": "Chacmuchuch",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-86.866667, 21.283333",
                            "IataCode": "ISJ",
                            "Airports": [
                                {
                                    "Id": "ISJ",
                                    "Name": "Isla Mujeres",
                                    "CityId": "ISJA",
                                    "CountryId": "MX",
                                    "Location": "-86.75, 21.266667"
                                }
                            ]
                        },
                        {
                            "Id": "VIBA",
                            "Name": "La Laguna",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-111.66667, 25.066667",
                            "IataCode": "VIB",
                            "Airports": [
                                {
                                    "Id": "VIB",
                                    "Name": "Villa Constitucion",
                                    "CityId": "VIBA",
                                    "CountryId": "MX",
                                    "Location": "-111.7, 25.058333"
                                }
                            ]
                        },
                        {
                            "Id": "PCOA",
                            "Name": "La Laguna",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-109.49611, 23.537222",
                            "IataCode": "PCO",
                            "Airports": [
                                {
                                    "Id": "PCO",
                                    "Name": "Punta Colorada",
                                    "CityId": "PCOA",
                                    "CountryId": "MX",
                                    "Location": "-109.5, 23.583333"
                                }
                            ]
                        },
                        {
                            "Id": "AJSA",
                            "Name": "Estero La Bocana",
                            "SingleAirportCity": true,
                            "CountryId": "MX",
                            "Location": "-113.71583, 26.796667",
                            "IataCode": "AJS",
                            "Airports": [
                                {
                                    "Id": "AJS",
                                    "Name": "Abreojos",
                                    "CityId": "AJSA",
                                    "CountryId": "MX",
                                    "Location": "-113.66667, 26.833333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "US",
                    "Name": "United States",
                    "CurrencyId": "USD",
                    "Regions": [
                        {
                            "Id": "ALASK",
                            "Name": "Alaska",
                            "CountryId": "US"
                        },
                        {
                            "Id": "ALABA",
                            "Name": "Alabama",
                            "CountryId": "US"
                        },
                        {
                            "Id": "ARKAN",
                            "Name": "Arkansas",
                            "CountryId": "US"
                        },
                        {
                            "Id": "ARIZO",
                            "Name": "Arizona",
                            "CountryId": "US"
                        },
                        {
                            "Id": "CALIF",
                            "Name": "California",
                            "CountryId": "US"
                        },
                        {
                            "Id": "COLOR",
                            "Name": "Colorado",
                            "CountryId": "US"
                        },
                        {
                            "Id": "CONNE",
                            "Name": "Connecticut",
                            "CountryId": "US"
                        },
                        {
                            "Id": "DISTR",
                            "Name": "District of Columbia",
                            "CountryId": "US"
                        },
                        {
                            "Id": "DELAW",
                            "Name": "Delaware",
                            "CountryId": "US"
                        },
                        {
                            "Id": "FLORI",
                            "Name": "Florida",
                            "CountryId": "US"
                        },
                        {
                            "Id": "GEORG",
                            "Name": "Georgia",
                            "CountryId": "US"
                        },
                        {
                            "Id": "HAWAI",
                            "Name": "Hawaii",
                            "CountryId": "US"
                        },
                        {
                            "Id": "IOWA_",
                            "Name": "Iowa",
                            "CountryId": "US"
                        },
                        {
                            "Id": "IDAHO",
                            "Name": "Idaho",
                            "CountryId": "US"
                        },
                        {
                            "Id": "ILLIN",
                            "Name": "Illinois",
                            "CountryId": "US"
                        },
                        {
                            "Id": "INDIA",
                            "Name": "Indiana",
                            "CountryId": "US"
                        },
                        {
                            "Id": "KANSA",
                            "Name": "Kansas",
                            "CountryId": "US"
                        },
                        {
                            "Id": "KENTU",
                            "Name": "Kentucky",
                            "CountryId": "US"
                        },
                        {
                            "Id": "LOUIS",
                            "Name": "Louisiana",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MASSA",
                            "Name": "Massachusetts",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MARYL",
                            "Name": "Maryland",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MAINE",
                            "Name": "Maine",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MICHI",
                            "Name": "Michigan",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MINNE",
                            "Name": "Minnesota",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MISSO",
                            "Name": "Missouri",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MISSI",
                            "Name": "Mississippi",
                            "CountryId": "US"
                        },
                        {
                            "Id": "MONTA",
                            "Name": "Montana",
                            "CountryId": "US"
                        },
                        {
                            "Id": "N_CAR",
                            "Name": "North Carolina",
                            "CountryId": "US"
                        },
                        {
                            "Id": "N_DAK",
                            "Name": "North Dakota",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEBRA",
                            "Name": "Nebraska",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEW_H",
                            "Name": "New Hampshire",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEW_J",
                            "Name": "New Jersey",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEW_M",
                            "Name": "New Mexico",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEVAD",
                            "Name": "Nevada",
                            "CountryId": "US"
                        },
                        {
                            "Id": "NEW_Y",
                            "Name": "New York",
                            "CountryId": "US"
                        },
                        {
                            "Id": "OHIO_",
                            "Name": "Ohio",
                            "CountryId": "US"
                        },
                        {
                            "Id": "OKLAH",
                            "Name": "Oklahoma",
                            "CountryId": "US"
                        },
                        {
                            "Id": "OREGO",
                            "Name": "Oregon",
                            "CountryId": "US"
                        },
                        {
                            "Id": "PENNS",
                            "Name": "Pennsylvania",
                            "CountryId": "US"
                        },
                        {
                            "Id": "RHODE",
                            "Name": "Rhode Island",
                            "CountryId": "US"
                        },
                        {
                            "Id": "S_CAR",
                            "Name": "South Carolina",
                            "CountryId": "US"
                        },
                        {
                            "Id": "S_DAK",
                            "Name": "South Dakota",
                            "CountryId": "US"
                        },
                        {
                            "Id": "TENNE",
                            "Name": "Tennessee",
                            "CountryId": "US"
                        },
                        {
                            "Id": "TEXAS",
                            "Name": "Texas",
                            "CountryId": "US"
                        },
                        {
                            "Id": "UTAH_",
                            "Name": "Utah",
                            "CountryId": "US"
                        },
                        {
                            "Id": "VIRGI",
                            "Name": "Virginia",
                            "CountryId": "US"
                        },
                        {
                            "Id": "VERMO",
                            "Name": "Vermont",
                            "CountryId": "US"
                        },
                        {
                            "Id": "WASHI",
                            "Name": "Washington",
                            "CountryId": "US"
                        },
                        {
                            "Id": "WISCO",
                            "Name": "Wisconsin",
                            "CountryId": "US"
                        },
                        {
                            "Id": "WEST_",
                            "Name": "West Virginia",
                            "CountryId": "US"
                        },
                        {
                            "Id": "WYOMI",
                            "Name": "Wyoming",
                            "CountryId": "US"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "MKCA",
                            "Name": "Kansas City",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-94.55586, 39.126027",
                            "IataCode": "MKC",
                            "Airports": [
                                {
                                    "Id": "JCI",
                                    "Name": "Kansas City Johnson Industrial",
                                    "CityId": "MKCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.9, 38.833333"
                                },
                                {
                                    "Id": "OJC",
                                    "Name": "Kansas City Johnson Executive",
                                    "CityId": "MKCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.733333, 38.85"
                                },
                                {
                                    "Id": "MKC",
                                    "Name": "Kansas City Downtown",
                                    "CityId": "MKCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.591944, 39.124167"
                                },
                                {
                                    "Id": "MCI",
                                    "Name": "Kansas City International",
                                    "CityId": "MKCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.726667, 39.292778"
                                }
                            ]
                        },
                        {
                            "Id": "KOYA",
                            "Name": "Olga Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.2275, 57.162222",
                            "IataCode": "KOY",
                            "Airports": [
                                {
                                    "Id": "KOY",
                                    "Name": "Olga Bay",
                                    "CityId": "KOYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.2275, 57.162222"
                                }
                            ]
                        },
                        {
                            "Id": "RDBA",
                            "Name": "Red Dog",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.66667, 67.75",
                            "IataCode": "RDB",
                            "Airports": [
                                {
                                    "Id": "RDB",
                                    "Name": "Red Dog",
                                    "CityId": "RDBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.90278, 68.03111"
                                }
                            ]
                        },
                        {
                            "Id": "EWDA",
                            "Name": "Wildman Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.76222, 56.456389",
                            "IataCode": "EWD",
                            "Airports": [
                                {
                                    "Id": "EWD",
                                    "Name": "Wildman Lake",
                                    "CityId": "EWDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.76222, 56.456389"
                                }
                            ]
                        },
                        {
                            "Id": "FMCA",
                            "Name": "Five Mile",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.84167, 66.916667",
                            "IataCode": "FMC",
                            "Airports": [
                                {
                                    "Id": "FMC",
                                    "Name": "Five Mile",
                                    "CityId": "FMCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.84167, 66.916667"
                                }
                            ]
                        },
                        {
                            "Id": "GBHA",
                            "Name": "Galbraith Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.48333, 68.466667",
                            "IataCode": "GBH",
                            "Airports": [
                                {
                                    "Id": "GBH",
                                    "Name": "Galbraith Lake",
                                    "CityId": "GBHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.48333, 68.466667"
                                }
                            ]
                        },
                        {
                            "Id": "ICYA",
                            "Name": "Icy Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-141.25, 60",
                            "IataCode": "ICY",
                            "Airports": [
                                {
                                    "Id": "ICY",
                                    "Name": "Icy Bay",
                                    "CityId": "ICYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-141.25, 60"
                                }
                            ]
                        },
                        {
                            "Id": "KKLA",
                            "Name": "Karluk Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.53333, 57.55",
                            "IataCode": "KKL",
                            "Airports": [
                                {
                                    "Id": "KKL",
                                    "Name": "Karluk Lake",
                                    "CityId": "KKLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.53333, 57.55"
                                }
                            ]
                        },
                        {
                            "Id": "UUKA",
                            "Name": "Kuparuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.91667, 70.416667",
                            "IataCode": "UUK",
                            "Airports": [
                                {
                                    "Id": "UUK",
                                    "Name": "Kuparuk",
                                    "CityId": "UUKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.91667, 70.416667"
                                }
                            ]
                        },
                        {
                            "Id": "WSFA",
                            "Name": "Sarichef",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.91667, 54.583333",
                            "IataCode": "WSF",
                            "Airports": [
                                {
                                    "Id": "WSF",
                                    "Name": "Sarichef",
                                    "CityId": "WSFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.91667, 54.583333"
                                }
                            ]
                        },
                        {
                            "Id": "KWPA",
                            "Name": "West Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.55, 57.766944",
                            "IataCode": "KWP",
                            "Airports": [
                                {
                                    "Id": "KWP",
                                    "Name": "West Point",
                                    "CityId": "KWPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.55, 57.766944"
                                }
                            ]
                        },
                        {
                            "Id": "KPVA",
                            "Name": "Perryville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.145, 55.906667",
                            "IataCode": "KPV",
                            "Airports": [
                                {
                                    "Id": "KPV",
                                    "Name": "Perryville",
                                    "CityId": "KPVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.15713, 55.908761"
                                }
                            ]
                        },
                        {
                            "Id": "YKMA",
                            "Name": "Yakima",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-120.5059, 46.602071",
                            "IataCode": "YKM",
                            "Airports": [
                                {
                                    "Id": "YKM",
                                    "Name": "Yakima Air Terminal",
                                    "CityId": "YKMA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-120.53778, 46.566944"
                                },
                                {
                                    "Id": "FCT",
                                    "Name": "Yakima Firing Center AAF",
                                    "CityId": "YKMA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-120.51667, 46.6"
                                }
                            ]
                        },
                        {
                            "Id": "HAYA",
                            "Name": "Haycock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.16556, 65.209722",
                            "IataCode": "HAY",
                            "Airports": [
                                {
                                    "Id": "HAY",
                                    "Name": "Haycock",
                                    "CityId": "HAYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.16667, 65.216667"
                                }
                            ]
                        },
                        {
                            "Id": "INKA",
                            "Name": "Wink",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-103.15989, 31.75124",
                            "IataCode": "INK",
                            "Airports": [
                                {
                                    "Id": "INK",
                                    "Name": "Wink",
                                    "CityId": "INKA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-103.20156, 31.77957"
                                }
                            ]
                        },
                        {
                            "Id": "WDGA",
                            "Name": "Enid",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.878391, 36.395589",
                            "IataCode": "WDG",
                            "Airports": [
                                {
                                    "Id": "END",
                                    "Name": "Enid Vance AFB",
                                    "CityId": "WDGA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.908096, 36.344443"
                                },
                                {
                                    "Id": "WDG",
                                    "Name": "Enid Woodring Mun.",
                                    "CityId": "WDGA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.790833, 36.383611"
                                }
                            ]
                        },
                        {
                            "Id": "WDRA",
                            "Name": "Winder",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.720171, 33.99261",
                            "IataCode": "WDR",
                            "Airports": [
                                {
                                    "Id": "WDR",
                                    "Name": "Winder",
                                    "CityId": "WDRA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.716667, 33.983333"
                                }
                            ]
                        },
                        {
                            "Id": "KTNA",
                            "Name": "Ketchikan",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.64611, 55.342222",
                            "IataCode": "KTN",
                            "Airports": [
                                {
                                    "Id": "KTN",
                                    "Name": "Ketchikan International",
                                    "CityId": "KTNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.71208, 55.355843"
                                },
                                {
                                    "Id": "WFB",
                                    "Name": "Ketchikan Waterfront SPB",
                                    "CityId": "KTNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.66833, 55.358056"
                                }
                            ]
                        },
                        {
                            "Id": "WFKA",
                            "Name": "Frenchville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.379765, 47.280873",
                            "IataCode": "WFK",
                            "Airports": [
                                {
                                    "Id": "WFK",
                                    "Name": "Frenchville",
                                    "CityId": "WFKA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.333333, 47.35"
                                }
                            ]
                        },
                        {
                            "Id": "WGOA",
                            "Name": "Winchester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.163334, 39.18566",
                            "IataCode": "WGO",
                            "Airports": [
                                {
                                    "Id": "WGO",
                                    "Name": "Winchester",
                                    "CityId": "WGOA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-78.142778, 39.141111"
                                }
                            ]
                        },
                        {
                            "Id": "LAXA",
                            "Name": "Los Angeles",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.40777, 34.099782",
                            "IataCode": "LAX",
                            "Airports": [
                                {
                                    "Id": "JSN",
                                    "Name": "Los Angeles Sherman Oaks H / P",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.25, 34.066667"
                                },
                                {
                                    "Id": "CCD",
                                    "Name": "Los Angeles Century City",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.26667, 34.066667"
                                },
                                {
                                    "Id": "VNY",
                                    "Name": "Los Angeles Van Nuys",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.49064, 34.20973"
                                },
                                {
                                    "Id": "JID",
                                    "Name": "Los Angeles City Of Industry H / P",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118, 34"
                                },
                                {
                                    "Id": "WHP",
                                    "Name": "Los Angeles Whiteman",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.41425, 34.25972"
                                },
                                {
                                    "Id": "JWC",
                                    "Name": "Los Angeles Warner Cntr Bus. Plaza",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.25, 34.066667"
                                },
                                {
                                    "Id": "JUC",
                                    "Name": "Los Angeles Universal City H / P",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.26667, 34.083333"
                                },
                                {
                                    "Id": "JCX",
                                    "Name": "Los Angeles Citicorp Plaza H / P",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.25, 34.066667"
                                },
                                {
                                    "Id": "LAX",
                                    "Name": "Los Angeles International",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.4088, 33.941536"
                                },
                                {
                                    "Id": "JBP",
                                    "Name": "Los Angeles Commerce Bus. Plaza",
                                    "CityId": "LAXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.15972, 33.986667"
                                }
                            ]
                        },
                        {
                            "Id": "WHTA",
                            "Name": "Wharton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.102737, 29.311637",
                            "IataCode": "WHT",
                            "Airports": [
                                {
                                    "Id": "WHT",
                                    "Name": "Wharton",
                                    "CityId": "WHTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.1, 29.316667"
                                }
                            ]
                        },
                        {
                            "Id": "WJFA",
                            "Name": "Lancaster",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.13674, 34.698041",
                            "IataCode": "WJF",
                            "Airports": [
                                {
                                    "Id": "WJF",
                                    "Name": "Lancaster William J Fox",
                                    "CityId": "WJFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.13333, 34.7"
                                }
                            ]
                        },
                        {
                            "Id": "WKKA",
                            "Name": "Aleknagik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.61778, 59.273056",
                            "IataCode": "WKK",
                            "Airports": [
                                {
                                    "Id": "WKK",
                                    "Name": "Aleknagik",
                                    "CityId": "WKKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.61111, 59.277778"
                                }
                            ]
                        },
                        {
                            "Id": "WLKA",
                            "Name": "Selawik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.00694, 66.603889",
                            "IataCode": "WLK",
                            "Airports": [
                                {
                                    "Id": "WLK",
                                    "Name": "Selawik",
                                    "CityId": "WLKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.98333, 66.602778"
                                }
                            ]
                        },
                        {
                            "Id": "WLMA",
                            "Name": "Waltham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.235611, 42.376485",
                            "IataCode": "WLM",
                            "Airports": [
                                {
                                    "Id": "WLM",
                                    "Name": "Waltham",
                                    "CityId": "WLMA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.251389, 42.385278"
                                }
                            ]
                        },
                        {
                            "Id": "WLRA",
                            "Name": "Loring",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.63611, 55.6025",
                            "IataCode": "WLR",
                            "Airports": [
                                {
                                    "Id": "WLR",
                                    "Name": "Loring",
                                    "CityId": "WLRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.66667, 55.666667"
                                }
                            ]
                        },
                        {
                            "Id": "WLWA",
                            "Name": "Willows",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.19359, 39.524326",
                            "IataCode": "WLW",
                            "Airports": [
                                {
                                    "Id": "WLW",
                                    "Name": "Willows",
                                    "CityId": "WLWA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.35, 39.883333"
                                }
                            ]
                        },
                        {
                            "Id": "WMCA",
                            "Name": "Winnemucca",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-117.73568, 40.972958",
                            "IataCode": "WMC",
                            "Airports": [
                                {
                                    "Id": "WMC",
                                    "Name": "Winnemucca",
                                    "CityId": "WMCA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-117.8064, 40.901966"
                                }
                            ]
                        },
                        {
                            "Id": "WMHA",
                            "Name": "Mountain Home",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.385159, 36.335345",
                            "IataCode": "WMH",
                            "Airports": [
                                {
                                    "Id": "WMH",
                                    "Name": "Mountain Home",
                                    "CityId": "WMHA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.383333, 36.333333"
                                }
                            ]
                        },
                        {
                            "Id": "WMKA",
                            "Name": "Meyers Chuck",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.25639, 55.740833",
                            "IataCode": "WMK",
                            "Airports": [
                                {
                                    "Id": "WMK",
                                    "Name": "Meyers Chuck",
                                    "CityId": "WMKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.18333, 55.733333"
                                }
                            ]
                        },
                        {
                            "Id": "WMOA",
                            "Name": "White Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.40556, 64.681389",
                            "IataCode": "WMO",
                            "Airports": [
                                {
                                    "Id": "WMO",
                                    "Name": "White Mountain",
                                    "CityId": "WMOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.40667, 64.686944"
                                }
                            ]
                        },
                        {
                            "Id": "WNAA",
                            "Name": "Napakiak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.95194, 60.696667",
                            "IataCode": "WNA",
                            "Airports": [
                                {
                                    "Id": "WNA",
                                    "Name": "Napakiak",
                                    "CityId": "WNAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.97865, 60.690536"
                                }
                            ]
                        },
                        {
                            "Id": "WOWA",
                            "Name": "Willow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.0375, 61.747222",
                            "IataCode": "WOW",
                            "Airports": [
                                {
                                    "Id": "WOW",
                                    "Name": "Willow",
                                    "CityId": "WOWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.16667, 61.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WPOA",
                            "Name": "Paonia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.592, 38.86832",
                            "IataCode": "WPO",
                            "Airports": [
                                {
                                    "Id": "WPO",
                                    "Name": "Paonia",
                                    "CityId": "WPOA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.6, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "WRGA",
                            "Name": "Wrangell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.37667, 56.470833",
                            "IataCode": "WRG",
                            "Airports": [
                                {
                                    "Id": "WRG",
                                    "Name": "Wrangell",
                                    "CityId": "WRGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.36667, 56.484444"
                                }
                            ]
                        },
                        {
                            "Id": "WRLA",
                            "Name": "Worland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-107.95537, 44.016901",
                            "IataCode": "WRL",
                            "Airports": [
                                {
                                    "Id": "WRL",
                                    "Name": "Worland",
                                    "CityId": "WRLA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-107.95338, 43.967616"
                                }
                            ]
                        },
                        {
                            "Id": "WSDA",
                            "Name": "White Sands",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.47944, 32.380925",
                            "IataCode": "WSD",
                            "Airports": [
                                {
                                    "Id": "WSD",
                                    "Name": "White Sands",
                                    "CityId": "WSDA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.48333, 32.383333"
                                }
                            ]
                        },
                        {
                            "Id": "WSGA",
                            "Name": "Washington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-80.246171, 40.17396",
                            "IataCode": "WSG",
                            "Airports": [
                                {
                                    "Id": "WSG",
                                    "Name": "Washington",
                                    "CityId": "WSGA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.25, 40.166667"
                                }
                            ]
                        },
                        {
                            "Id": "WSNA",
                            "Name": "South Naknek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.99806, 58.715556",
                            "IataCode": "WSN",
                            "Airports": [
                                {
                                    "Id": "WSN",
                                    "Name": "South Naknek",
                                    "CityId": "WSNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.94056, 58.7273"
                                }
                            ]
                        },
                        {
                            "Id": "WSTA",
                            "Name": "Westerly",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "RHODE",
                            "Location": "-71.827291, 41.3776",
                            "IataCode": "WST",
                            "Airports": [
                                {
                                    "Id": "WST",
                                    "Name": "Westerly",
                                    "CityId": "WSTA",
                                    "CountryId": "US",
                                    "RegionId": "RHODE",
                                    "Location": "-71.803889, 41.349444"
                                }
                            ]
                        },
                        {
                            "Id": "WTKA",
                            "Name": "Noatak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.96528, 67.571111",
                            "IataCode": "WTK",
                            "Airports": [
                                {
                                    "Id": "WTK",
                                    "Name": "Noatak",
                                    "CityId": "WTKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.98417, 67.563056"
                                }
                            ]
                        },
                        {
                            "Id": "WTLA",
                            "Name": "Tuntutuliak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.66306, 60.343056",
                            "IataCode": "WTL",
                            "Airports": [
                                {
                                    "Id": "WTL",
                                    "Name": "Tuntutuliak",
                                    "CityId": "WTLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.63333, 60.35"
                                }
                            ]
                        },
                        {
                            "Id": "WVIA",
                            "Name": "Watsonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.75689, 36.910231",
                            "IataCode": "WVI",
                            "Airports": [
                                {
                                    "Id": "WVI",
                                    "Name": "Watsonville",
                                    "CityId": "WVIA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.79057, 36.936035"
                                }
                            ]
                        },
                        {
                            "Id": "WVLA",
                            "Name": "Waterville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.631712, 44.55201",
                            "IataCode": "WVL",
                            "Airports": [
                                {
                                    "Id": "WVL",
                                    "Name": "Waterville",
                                    "CityId": "WVLA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.675833, 44.533889"
                                }
                            ]
                        },
                        {
                            "Id": "WWAA",
                            "Name": "Wasilla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.43944, 61.581389",
                            "IataCode": "WWA",
                            "Airports": [
                                {
                                    "Id": "WWA",
                                    "Name": "Wasilla",
                                    "CityId": "WWAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.43333, 61.583333"
                                }
                            ]
                        },
                        {
                            "Id": "WWDA",
                            "Name": "Wildwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.814889, 38.99178",
                            "IataCode": "WWD",
                            "Airports": [
                                {
                                    "Id": "WWD",
                                    "Name": "Wildwood",
                                    "CityId": "WWDA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.908889, 39.006389"
                                }
                            ]
                        },
                        {
                            "Id": "WWRA",
                            "Name": "Woodward",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.390386, 36.433648",
                            "IataCode": "WWR",
                            "Airports": [
                                {
                                    "Id": "WWR",
                                    "Name": "West Woodward",
                                    "CityId": "WWRA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.526389, 36.436111"
                                }
                            ]
                        },
                        {
                            "Id": "WWTA",
                            "Name": "Newtok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.62944, 60.942778",
                            "IataCode": "WWT",
                            "Airports": [
                                {
                                    "Id": "WWT",
                                    "Name": "Newtok",
                                    "CityId": "WWTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.64861, 60.922778"
                                }
                            ]
                        },
                        {
                            "Id": "WYSA",
                            "Name": "West Yellowstone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-111.10411, 44.662149",
                            "IataCode": "WYS",
                            "Airports": [
                                {
                                    "Id": "WYS",
                                    "Name": "West Yellowstone",
                                    "CityId": "WYSA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.10472, 44.686667"
                                }
                            ]
                        },
                        {
                            "Id": "XMDA",
                            "Name": "Madison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-97.11395, 44.006085",
                            "IataCode": "XMD",
                            "Airports": [
                                {
                                    "Id": "XMD",
                                    "Name": "Madison",
                                    "CityId": "XMDA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-97.08367, 44.01664"
                                }
                            ]
                        },
                        {
                            "Id": "FYVA",
                            "Name": "Fayetteville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.157426, 36.062579",
                            "IataCode": "FYV",
                            "Airports": [
                                {
                                    "Id": "FYV",
                                    "Name": "Fayetteville Municipal (Drake Fld)",
                                    "CityId": "FYVA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.17, 36.003611"
                                },
                                {
                                    "Id": "XNA",
                                    "Name": "Fayetteville Northwest Arkansas Rgn",
                                    "CityId": "FYVA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.306667, 36.281667"
                                }
                            ]
                        },
                        {
                            "Id": "XPRA",
                            "Name": "Pine Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-102.55627, 43.025541",
                            "IataCode": "XPR",
                            "Airports": [
                                {
                                    "Id": "XPR",
                                    "Name": "Pine Ridge",
                                    "CityId": "XPRA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-102.51667, 43.016667"
                                }
                            ]
                        },
                        {
                            "Id": "TPHA",
                            "Name": "Tonopah",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-117.23008, 38.067155",
                            "IataCode": "TPH",
                            "Airports": [
                                {
                                    "Id": "XSD",
                                    "Name": "Tonopah Test Range",
                                    "CityId": "TPHA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-117.23333, 38.033333"
                                },
                                {
                                    "Id": "TPH",
                                    "Name": "Tonopah Arpt",
                                    "CityId": "TPHA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-117.08705, 38.060256"
                                }
                            ]
                        },
                        {
                            "Id": "YAKA",
                            "Name": "Yakutat",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-139.72722, 59.546944",
                            "IataCode": "YAK",
                            "Airports": [
                                {
                                    "Id": "YAK",
                                    "Name": "Yakutat",
                                    "CityId": "YAKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-139.65972, 59.509167"
                                }
                            ]
                        },
                        {
                            "Id": "SSMA",
                            "Name": "Sault Ste Marie",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.345317, 46.4953",
                            "IataCode": "SSM",
                            "Airports": [
                                {
                                    "Id": "CIU",
                                    "Name": "Sault Ste Marie Chippewa County",
                                    "CityId": "SSMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.470787, 46.250149"
                                },
                                {
                                    "Id": "INR",
                                    "Name": "Sault Ste Marie Kincheloe AFB",
                                    "CityId": "SSMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.35, 46.5"
                                }
                            ]
                        },
                        {
                            "Id": "ARAA",
                            "Name": "New Iberia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-91.818729, 30.003536",
                            "IataCode": "ARA",
                            "Airports": [
                                {
                                    "Id": "ARA",
                                    "Name": "New Iberia",
                                    "CityId": "ARAA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-91.883889, 30.037778"
                                }
                            ]
                        },
                        {
                            "Id": "ARBA",
                            "Name": "Ann Arbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.740882, 42.277563",
                            "IataCode": "ARB",
                            "Airports": [
                                {
                                    "Id": "ARB",
                                    "Name": "Ann Arbor",
                                    "CityId": "ARBA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.7455, 42.22282"
                                }
                            ]
                        },
                        {
                            "Id": "ARCA",
                            "Name": "Arctic Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.53778, 68.126944",
                            "IataCode": "ARC",
                            "Airports": [
                                {
                                    "Id": "ARC",
                                    "Name": "Arctic Village",
                                    "CityId": "ARCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.52444, 68.1375"
                                }
                            ]
                        },
                        {
                            "Id": "APNA",
                            "Name": "Alpena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.432753, 45.061679",
                            "IataCode": "APN",
                            "Airports": [
                                {
                                    "Id": "APN",
                                    "Name": "Alpena",
                                    "CityId": "APNA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.555833, 45.081667"
                                }
                            ]
                        },
                        {
                            "Id": "APTA",
                            "Name": "Jasper",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-85.626079, 35.074245",
                            "IataCode": "APT",
                            "Airports": [
                                {
                                    "Id": "APT",
                                    "Name": "Jasper",
                                    "CityId": "APTA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-85.5, 35.066667"
                                }
                            ]
                        },
                        {
                            "Id": "APVA",
                            "Name": "Apple Valley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.18588, 34.500831",
                            "IataCode": "APV",
                            "Airports": [
                                {
                                    "Id": "APV",
                                    "Name": "Apple Valley",
                                    "CityId": "APVA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.21278, 34.526389"
                                }
                            ]
                        },
                        {
                            "Id": "AAFA",
                            "Name": "Apalachicola",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-84.983244, 29.725767",
                            "IataCode": "AAF",
                            "Airports": [
                                {
                                    "Id": "AAF",
                                    "Name": "Apalachicola",
                                    "CityId": "AAFA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-85.0288, 29.72938"
                                }
                            ]
                        },
                        {
                            "Id": "ABEA",
                            "Name": "Allentown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.490183, 40.60843",
                            "IataCode": "ABE",
                            "Airports": [
                                {
                                    "Id": "ABE",
                                    "Name": "Allentown",
                                    "CityId": "ABEA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.438333, 40.654722"
                                }
                            ]
                        },
                        {
                            "Id": "ABIA",
                            "Name": "Abilene",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.733144, 32.448736",
                            "IataCode": "ABI",
                            "Airports": [
                                {
                                    "Id": "DYS",
                                    "Name": "Abilene Dyess AFB",
                                    "CityId": "ABIA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.85731, 32.42013"
                                },
                                {
                                    "Id": "ABI",
                                    "Name": "Abilene Municipal",
                                    "CityId": "ABIA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.679722, 32.409444"
                                }
                            ]
                        },
                        {
                            "Id": "ABLA",
                            "Name": "Ambler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.85139, 67.086111",
                            "IataCode": "ABL",
                            "Airports": [
                                {
                                    "Id": "ABL",
                                    "Name": "Ambler",
                                    "CityId": "ABLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.84583, 67.0875"
                                }
                            ]
                        },
                        {
                            "Id": "ABQA",
                            "Name": "Albuquerque",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.65114, 35.084492",
                            "IataCode": "ABQ",
                            "Airports": [
                                {
                                    "Id": "ABQ",
                                    "Name": "Albuquerque",
                                    "CityId": "ABQA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.60927, 35.042084"
                                }
                            ]
                        },
                        {
                            "Id": "ABRA",
                            "Name": "Aberdeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-98.486483, 45.464698",
                            "IataCode": "ABR",
                            "Airports": [
                                {
                                    "Id": "ABR",
                                    "Name": "Aberdeen",
                                    "CityId": "ABRA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-98.426111, 45.4525"
                                }
                            ]
                        },
                        {
                            "Id": "ABYA",
                            "Name": "Albany",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.155741, 31.578507",
                            "IataCode": "ABY",
                            "Airports": [
                                {
                                    "Id": "NAB",
                                    "Name": "Albany NAS",
                                    "CityId": "ABYA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.166667, 31.583333"
                                },
                                {
                                    "Id": "ABY",
                                    "Name": "Albany Dougherty County",
                                    "CityId": "ABYA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.196111, 31.532222"
                                }
                            ]
                        },
                        {
                            "Id": "ACBA",
                            "Name": "Bellaire",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.211173, 44.980282",
                            "IataCode": "ACB",
                            "Airports": [
                                {
                                    "Id": "ACB",
                                    "Name": "Bellaire",
                                    "CityId": "ACBA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.216667, 44.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ACTA",
                            "Name": "Waco",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.14667, 31.549333",
                            "IataCode": "ACT",
                            "Airports": [
                                {
                                    "Id": "CNW",
                                    "Name": "Waco James Connall",
                                    "CityId": "ACTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.133333, 31.916667"
                                },
                                {
                                    "Id": "ACT",
                                    "Name": "Waco Municipal",
                                    "CityId": "ACTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.230278, 31.607778"
                                }
                            ]
                        },
                        {
                            "Id": "AIYA",
                            "Name": "Atlantic City",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.422927, 39.364283",
                            "IataCode": "AIY",
                            "Airports": [
                                {
                                    "Id": "ACY",
                                    "Name": "Atlantic City International",
                                    "CityId": "AIYA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.583333, 39.45"
                                },
                                {
                                    "Id": "JSL",
                                    "Name": "Atlantic City Steel Pier Hlpt",
                                    "CityId": "AIYA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.45, 39.333333"
                                },
                                {
                                    "Id": "AIY",
                                    "Name": "Atlantic City Bader Field",
                                    "CityId": "AIYA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.457222, 39.36"
                                }
                            ]
                        },
                        {
                            "Id": "ADGA",
                            "Name": "Adrian",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.037166, 41.897547",
                            "IataCode": "ADG",
                            "Airports": [
                                {
                                    "Id": "ADG",
                                    "Name": "Adrian",
                                    "CityId": "ADGA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.07467, 41.86921"
                                }
                            ]
                        },
                        {
                            "Id": "ADMA",
                            "Name": "Ardmore",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.143625, 34.174261",
                            "IataCode": "ADM",
                            "Airports": [
                                {
                                    "Id": "AHD",
                                    "Name": "Ardmore Downtown",
                                    "CityId": "ADMA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.133333, 34.166667"
                                },
                                {
                                    "Id": "ADM",
                                    "Name": "Ardmore Municipal Arpt",
                                    "CityId": "ADMA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.019444, 34.303056"
                                }
                            ]
                        },
                        {
                            "Id": "ADQA",
                            "Name": "Kodiak",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.40722, 57.79",
                            "IataCode": "ADQ",
                            "Airports": [
                                {
                                    "Id": "ADQ",
                                    "Name": "Kodiak",
                                    "CityId": "ADQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.48333, 57.754167"
                                },
                                {
                                    "Id": "WDY",
                                    "Name": "Kodiak Fss",
                                    "CityId": "ADQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.4, 57.783333"
                                },
                                {
                                    "Id": "KDK",
                                    "Name": "Kodiak Municipal",
                                    "CityId": "ADQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.37, 57.807222"
                                }
                            ]
                        },
                        {
                            "Id": "ADRA",
                            "Name": "Andrews",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.560897, 33.451278",
                            "IataCode": "ADR",
                            "Airports": [
                                {
                                    "Id": "ADR",
                                    "Name": "Andrews",
                                    "CityId": "ADRA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.566667, 33.45"
                                }
                            ]
                        },
                        {
                            "Id": "DFWA",
                            "Name": "Dallas",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.806668, 32.783055",
                            "IataCode": "DFW",
                            "Airports": [
                                {
                                    "Id": "DAL",
                                    "Name": "Dallas Love Field",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.853333, 32.846944"
                                },
                                {
                                    "Id": "JDB",
                                    "Name": "Dallas Downtown Heliport",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.316667, 32.766667"
                                },
                                {
                                    "Id": "ADS",
                                    "Name": "Dallas Addison",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.3, 32.783333"
                                },
                                {
                                    "Id": "RBD",
                                    "Name": "Dallas Redbird",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.816667, 32.783333"
                                },
                                {
                                    "Id": "FWH",
                                    "Name": "Dallas Carswell AFB",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.3, 32.763889"
                                },
                                {
                                    "Id": "DNE",
                                    "Name": "Dallas North",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.383333, 32.75"
                                },
                                {
                                    "Id": "JNH",
                                    "Name": "Dallas North Park Inn H / P",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.35, 32.816667"
                                },
                                {
                                    "Id": "AFW",
                                    "Name": "Dallas Fort Worth Alliance",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.316667, 32.986667"
                                },
                                {
                                    "Id": "JMD",
                                    "Name": "Dallas Market Centre H / P",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.333333, 32.8"
                                },
                                {
                                    "Id": "DFW",
                                    "Name": "Dallas Fort Worth International",
                                    "CityId": "DFWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.029723, 32.894444"
                                }
                            ]
                        },
                        {
                            "Id": "ADTA",
                            "Name": "Ada",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-96.678345, 34.774531",
                            "IataCode": "ADT",
                            "Airports": [
                                {
                                    "Id": "ADT",
                                    "Name": "Ada",
                                    "CityId": "ADTA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.666667, 34.8"
                                }
                            ]
                        },
                        {
                            "Id": "ADWA",
                            "Name": "Camp Springs",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.90664, 38.804003",
                            "IataCode": "ADW",
                            "Airports": [
                                {
                                    "Id": "ADW",
                                    "Name": "Camp Springs Andrews AFB",
                                    "CityId": "ADWA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.866667, 38.816667"
                                },
                                {
                                    "Id": "NSF",
                                    "Name": "Camp Springs Andrews NAF",
                                    "CityId": "ADWA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.833333, 38.816667"
                                }
                            ]
                        },
                        {
                            "Id": "AELA",
                            "Name": "Albert Lea",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.368266, 43.648013",
                            "IataCode": "AEL",
                            "Airports": [
                                {
                                    "Id": "AEL",
                                    "Name": "Albert Lea",
                                    "CityId": "AELA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.36743, 43.68135"
                                }
                            ]
                        },
                        {
                            "Id": "AETA",
                            "Name": "Allakaket",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.64556, 66.565556",
                            "IataCode": "AET",
                            "Airports": [
                                {
                                    "Id": "AET",
                                    "Name": "Allakaket",
                                    "CityId": "AETA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.65833, 66.561667"
                                }
                            ]
                        },
                        {
                            "Id": "AEXA",
                            "Name": "Alexandria",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.445137, 31.311294",
                            "IataCode": "AEX",
                            "Airports": [
                                {
                                    "Id": "ESF",
                                    "Name": "Alexandria Esler Field",
                                    "CityId": "AEXA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.296944, 31.395"
                                },
                                {
                                    "Id": "AEX",
                                    "Name": "Alexandria Internation",
                                    "CityId": "AEXA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.55, 31.333333"
                                }
                            ]
                        },
                        {
                            "Id": "COSA",
                            "Name": "Colorado Springs",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-104.82136, 38.833882",
                            "IataCode": "COS",
                            "Airports": [
                                {
                                    "Id": "AFF",
                                    "Name": "Colorado Springs USAF Academy",
                                    "CityId": "COSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.7, 38.833333"
                                },
                                {
                                    "Id": "FCS",
                                    "Name": "Colorado Springs Butts AAF",
                                    "CityId": "COSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.81667, 38.833333"
                                },
                                {
                                    "Id": "COS",
                                    "Name": "Colorado Springs Peterson Field",
                                    "CityId": "COSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.70108, 38.803865"
                                }
                            ]
                        },
                        {
                            "Id": "AFNA",
                            "Name": "Jaffrey",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-72.023136, 42.813973",
                            "IataCode": "AFN",
                            "Airports": [
                                {
                                    "Id": "AFN",
                                    "Name": "Jaffrey",
                                    "CityId": "AFNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-72.066667, 42.833333"
                                }
                            ]
                        },
                        {
                            "Id": "AFOA",
                            "Name": "Afton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.93187, 42.724928",
                            "IataCode": "AFO",
                            "Airports": [
                                {
                                    "Id": "AFO",
                                    "Name": "Afton",
                                    "CityId": "AFOA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.94303, 42.7001"
                                }
                            ]
                        },
                        {
                            "Id": "FTWA",
                            "Name": "Fort Worth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.32085, 32.725411",
                            "IataCode": "FTW",
                            "Airports": [
                                {
                                    "Id": "FTW",
                                    "Name": "Fort Worth",
                                    "CityId": "FTWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.36196, 32.8193"
                                }
                            ]
                        },
                        {
                            "Id": "PITA",
                            "Name": "Pittsburgh",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.995887, 40.440625",
                            "IataCode": "PIT",
                            "Airports": [
                                {
                                    "Id": "CVA",
                                    "Name": "Pittsburgh Civic Ar Heli",
                                    "CityId": "PITA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.235833, 40.496667"
                                },
                                {
                                    "Id": "AGC",
                                    "Name": "Pittsburgh Allegheny County",
                                    "CityId": "PITA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.93, 40.354722"
                                },
                                {
                                    "Id": "PIT",
                                    "Name": "Pittsburgh International",
                                    "CityId": "PITA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.235833, 40.496667"
                                }
                            ]
                        },
                        {
                            "Id": "AGNA",
                            "Name": "Angoon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.58389, 57.503333",
                            "IataCode": "AGN",
                            "Airports": [
                                {
                                    "Id": "AGN",
                                    "Name": "Angoon",
                                    "CityId": "AGNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.58333, 57.503889"
                                }
                            ]
                        },
                        {
                            "Id": "AGOA",
                            "Name": "Magnolia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-93.239334, 33.267072",
                            "IataCode": "AGO",
                            "Airports": [
                                {
                                    "Id": "AGO",
                                    "Name": "Magnolia",
                                    "CityId": "AGOA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-93.21683, 33.22791"
                                }
                            ]
                        },
                        {
                            "Id": "AGSA",
                            "Name": "Augusta",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.974838, 33.47097",
                            "IataCode": "AGS",
                            "Airports": [
                                {
                                    "Id": "DNL",
                                    "Name": "Augusta Daniel Field",
                                    "CityId": "AGSA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-82.03928, 33.46653"
                                },
                                {
                                    "Id": "AGS",
                                    "Name": "Augusta Bush Field",
                                    "CityId": "AGSA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.966944, 33.366389"
                                }
                            ]
                        },
                        {
                            "Id": "AHCA",
                            "Name": "Herlong",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.13465, 40.143514",
                            "IataCode": "AHC",
                            "Airports": [
                                {
                                    "Id": "AHC",
                                    "Name": "Herlong",
                                    "CityId": "AHCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.13333, 40.15"
                                }
                            ]
                        },
                        {
                            "Id": "AHHA",
                            "Name": "Amery",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-92.362137, 45.306906",
                            "IataCode": "AHH",
                            "Airports": [
                                {
                                    "Id": "AHH",
                                    "Name": "Amery",
                                    "CityId": "AHHA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-92.37519, 45.28107"
                                }
                            ]
                        },
                        {
                            "Id": "AHNA",
                            "Name": "Athens",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.377936, 33.960948",
                            "IataCode": "AHN",
                            "Airports": [
                                {
                                    "Id": "AHN",
                                    "Name": "Athens Ben Epps",
                                    "CityId": "AHNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.3269, 33.9483"
                                }
                            ]
                        },
                        {
                            "Id": "AIAA",
                            "Name": "Alliance",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-102.87215, 42.101634",
                            "IataCode": "AIA",
                            "Airports": [
                                {
                                    "Id": "AIA",
                                    "Name": "Alliance",
                                    "CityId": "AIAA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-102.80662, 42.050567"
                                }
                            ]
                        },
                        {
                            "Id": "AIDA",
                            "Name": "Anderson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.680254, 40.10532",
                            "IataCode": "AID",
                            "Airports": [
                                {
                                    "Id": "AID",
                                    "Name": "Anderson",
                                    "CityId": "AIDA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.683333, 40.166667"
                                }
                            ]
                        },
                        {
                            "Id": "AIKA",
                            "Name": "Aiken",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-81.719553, 33.560417",
                            "IataCode": "AIK",
                            "Airports": [
                                {
                                    "Id": "AIK",
                                    "Name": "Aiken",
                                    "CityId": "AIKA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.68427, 33.64931"
                                }
                            ]
                        },
                        {
                            "Id": "AINA",
                            "Name": "Wainwright",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.03833, 70.636944",
                            "IataCode": "AIN",
                            "Airports": [
                                {
                                    "Id": "AIN",
                                    "Name": "Wainwright",
                                    "CityId": "AINA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.03333, 70.633333"
                                }
                            ]
                        },
                        {
                            "Id": "AIOA",
                            "Name": "Atlantic",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.013878, 41.403601",
                            "IataCode": "AIO",
                            "Airports": [
                                {
                                    "Id": "AIO",
                                    "Name": "Atlantic",
                                    "CityId": "AIOA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.016667, 41.4"
                                }
                            ]
                        },
                        {
                            "Id": "AIVA",
                            "Name": "Aliceville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-88.151417, 33.129568",
                            "IataCode": "AIV",
                            "Airports": [
                                {
                                    "Id": "AIV",
                                    "Name": "Aliceville",
                                    "CityId": "AIVA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-88.15, 33.133333"
                                }
                            ]
                        },
                        {
                            "Id": "AKBA",
                            "Name": "Atka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-174.20056, 52.196111",
                            "IataCode": "AKB",
                            "Airports": [
                                {
                                    "Id": "AKB",
                                    "Name": "Atka",
                                    "CityId": "AKBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-174.20083, 52.217222"
                                }
                            ]
                        },
                        {
                            "Id": "AKRB",
                            "Name": "Akron",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.519005, 41.081445",
                            "IataCode": "CAK",
                            "Airports": [
                                {
                                    "Id": "AKC",
                                    "Name": "Akron International",
                                    "CityId": "AKRB",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.467778, 41.04"
                                },
                                {
                                    "Id": "CAK",
                                    "Name": "Akron / Canton Regional",
                                    "CityId": "AKRB",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.441667, 40.913889"
                                }
                            ]
                        },
                        {
                            "Id": "AKIA",
                            "Name": "Akiak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.21389, 60.912222",
                            "IataCode": "AKI",
                            "Airports": [
                                {
                                    "Id": "AKI",
                                    "Name": "Akiak",
                                    "CityId": "AKIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.22333, 60.905556"
                                }
                            ]
                        },
                        {
                            "Id": "AKKA",
                            "Name": "Akhiok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.17028, 56.945556",
                            "IataCode": "AKK",
                            "Airports": [
                                {
                                    "Id": "AKK",
                                    "Name": "Akhiok",
                                    "CityId": "AKKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.16667, 56.944444"
                                }
                            ]
                        },
                        {
                            "Id": "AKNA",
                            "Name": "King Salmon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.66139, 58.688333",
                            "IataCode": "AKN",
                            "Airports": [
                                {
                                    "Id": "AKN",
                                    "Name": "King Salmon",
                                    "CityId": "AKNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.63556, 58.668611"
                                }
                            ]
                        },
                        {
                            "Id": "AKOA",
                            "Name": "Akron",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-103.21438, 40.160537",
                            "IataCode": "AKO",
                            "Airports": [
                                {
                                    "Id": "AKO",
                                    "Name": "Akron",
                                    "CityId": "AKOA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-103.21667, 40.166667"
                                }
                            ]
                        },
                        {
                            "Id": "AKPA",
                            "Name": "Anaktuvuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.73583, 68.143333",
                            "IataCode": "AKP",
                            "Airports": [
                                {
                                    "Id": "AKP",
                                    "Name": "Anaktuvuk",
                                    "CityId": "AKPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.74, 68.1375"
                                }
                            ]
                        },
                        {
                            "Id": "ALBA",
                            "Name": "Albany",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.756232, 42.652579",
                            "IataCode": "ALB",
                            "Airports": [
                                {
                                    "Id": "ALB",
                                    "Name": "Albany",
                                    "CityId": "ALBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.805278, 42.745833"
                                }
                            ]
                        },
                        {
                            "Id": "ALIA",
                            "Name": "Alice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.069725, 27.752249",
                            "IataCode": "ALI",
                            "Airports": [
                                {
                                    "Id": "ALI",
                                    "Name": "Alice",
                                    "CityId": "ALIA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.02722, 27.74114"
                                }
                            ]
                        },
                        {
                            "Id": "ALMA",
                            "Name": "Alamogordo",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.96026, 32.899532",
                            "IataCode": "ALM",
                            "Airports": [
                                {
                                    "Id": "ALM",
                                    "Name": "Alamogordo Municipal",
                                    "CityId": "ALMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.98528, 32.845833"
                                },
                                {
                                    "Id": "HMN",
                                    "Name": "Alamogordo Holloman AFB",
                                    "CityId": "ALMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.10652, 32.85252"
                                }
                            ]
                        },
                        {
                            "Id": "ALOA",
                            "Name": "Waterloo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-92.342963, 42.492764",
                            "IataCode": "ALO",
                            "Airports": [
                                {
                                    "Id": "ALO",
                                    "Name": "Waterloo",
                                    "CityId": "ALOA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-92.399722, 42.556111"
                                }
                            ]
                        },
                        {
                            "Id": "ALSA",
                            "Name": "Alamosa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.87002, 37.469449",
                            "IataCode": "ALS",
                            "Airports": [
                                {
                                    "Id": "ALS",
                                    "Name": "Alamosa",
                                    "CityId": "ALSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.86529, 37.436975"
                                }
                            ]
                        },
                        {
                            "Id": "ALXA",
                            "Name": "Alexander City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.953853, 32.944012",
                            "IataCode": "ALX",
                            "Airports": [
                                {
                                    "Id": "ALX",
                                    "Name": "Alexander City",
                                    "CityId": "ALXA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.95, 32.933333"
                                }
                            ]
                        },
                        {
                            "Id": "AMAA",
                            "Name": "Amarillo",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.8313, 35.221997",
                            "IataCode": "AMA",
                            "Airports": [
                                {
                                    "Id": "TDW",
                                    "Name": "Amarillo Tradewind",
                                    "CityId": "AMAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.83333, 35.216667"
                                },
                                {
                                    "Id": "AMA",
                                    "Name": "Amarillo International",
                                    "CityId": "AMAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.70559, 35.221082"
                                }
                            ]
                        },
                        {
                            "Id": "DROA",
                            "Name": "Durango",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.88007, 37.27528",
                            "IataCode": "DRO",
                            "Airports": [
                                {
                                    "Id": "AMK",
                                    "Name": "Durango Animas Airpark",
                                    "CityId": "DROA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.75, 37.15"
                                },
                                {
                                    "Id": "DRO",
                                    "Name": "Durango La Plata",
                                    "CityId": "DROA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.75322, 37.153202"
                                }
                            ]
                        },
                        {
                            "Id": "AMNA",
                            "Name": "Alma",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.659727, 43.37892",
                            "IataCode": "AMN",
                            "Airports": [
                                {
                                    "Id": "AMN",
                                    "Name": "Alma",
                                    "CityId": "AMNA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.68778, 43.32198"
                                }
                            ]
                        },
                        {
                            "Id": "AMWA",
                            "Name": "Ames",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.61994, 42.034708",
                            "IataCode": "AMW",
                            "Airports": [
                                {
                                    "Id": "AMW",
                                    "Name": "Ames",
                                    "CityId": "AMWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.618333, 41.994167"
                                }
                            ]
                        },
                        {
                            "Id": "ANBA",
                            "Name": "Anniston",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.831632, 33.659826",
                            "IataCode": "ANB",
                            "Airports": [
                                {
                                    "Id": "RLI",
                                    "Name": "Anniston Reilly AHP",
                                    "CityId": "ANBA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.85, 33.666667"
                                },
                                {
                                    "Id": "ANB",
                                    "Name": "Anniston County Arpt",
                                    "CityId": "ANBA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.855833, 33.590278"
                                }
                            ]
                        },
                        {
                            "Id": "ANCA",
                            "Name": "Anchorage",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.90028, 61.218059",
                            "IataCode": "ANC",
                            "Airports": [
                                {
                                    "Id": "MRI",
                                    "Name": "Anchorage Merrill Field",
                                    "CityId": "ANCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.84583, 61.21444"
                                },
                                {
                                    "Id": "ANC",
                                    "Name": "Anchorage International",
                                    "CityId": "ANCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.98528, 61.169167"
                                },
                                {
                                    "Id": "EDF",
                                    "Name": "Anchorage Elmendorf AFB",
                                    "CityId": "ANCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.78472, 61.250556"
                                }
                            ]
                        },
                        {
                            "Id": "ANDA",
                            "Name": "Anderson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-82.650133, 34.503439",
                            "IataCode": "AND",
                            "Airports": [
                                {
                                    "Id": "AND",
                                    "Name": "Anderson",
                                    "CityId": "ANDA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.71, 34.493611"
                                }
                            ]
                        },
                        {
                            "Id": "ANIA",
                            "Name": "Aniak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.52222, 61.578333",
                            "IataCode": "ANI",
                            "Airports": [
                                {
                                    "Id": "ANI",
                                    "Name": "Aniak",
                                    "CityId": "ANIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.53333, 61.566667"
                                }
                            ]
                        },
                        {
                            "Id": "ANQA",
                            "Name": "Angola",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-84.999409, 41.634773",
                            "IataCode": "ANQ",
                            "Airports": [
                                {
                                    "Id": "ANQ",
                                    "Name": "Angola",
                                    "CityId": "ANQA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.08663, 41.63949"
                                }
                            ]
                        },
                        {
                            "Id": "ANVA",
                            "Name": "Anvik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.20667, 62.656111",
                            "IataCode": "ANV",
                            "Airports": [
                                {
                                    "Id": "ANV",
                                    "Name": "Anvik",
                                    "CityId": "ANVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.18889, 62.647778"
                                }
                            ]
                        },
                        {
                            "Id": "ANWA",
                            "Name": "Ainsworth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.862624, 42.549999",
                            "IataCode": "ANW",
                            "Airports": [
                                {
                                    "Id": "ANW",
                                    "Name": "Ainsworth",
                                    "CityId": "ANWA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.99318, 42.57917"
                                }
                            ]
                        },
                        {
                            "Id": "ANYA",
                            "Name": "Anthony",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-98.031173, 37.153355",
                            "IataCode": "ANY",
                            "Airports": [
                                {
                                    "Id": "ANY",
                                    "Name": "Anthony",
                                    "CityId": "ANYA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-98.077869, 37.159769"
                                }
                            ]
                        },
                        {
                            "Id": "LIAA",
                            "Name": "Lima",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.105226, 40.742551",
                            "IataCode": "AOH",
                            "Airports": [
                                {
                                    "Id": "AOH",
                                    "Name": "Lima",
                                    "CityId": "LIAA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.026012, 40.708296"
                                }
                            ]
                        },
                        {
                            "Id": "DENA",
                            "Name": "Denver",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-104.9847, 39.739155",
                            "IataCode": "DEN",
                            "Airports": [
                                {
                                    "Id": "BFK",
                                    "Name": "Denver Buckley ANGB",
                                    "CityId": "DENA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.86667, 39.733333"
                                },
                                {
                                    "Id": "APA",
                                    "Name": "Denver Arapahoe Co",
                                    "CityId": "DENA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.98333, 39.733333"
                                },
                                {
                                    "Id": "DEN",
                                    "Name": "Denver International",
                                    "CityId": "DENA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.67533, 39.862317"
                                }
                            ]
                        },
                        {
                            "Id": "APCA",
                            "Name": "Napa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.28553, 38.297137",
                            "IataCode": "APC",
                            "Airports": [
                                {
                                    "Id": "APC",
                                    "Name": "Napa",
                                    "CityId": "APCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.28, 38.212222"
                                }
                            ]
                        },
                        {
                            "Id": "APFA",
                            "Name": "Naples",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.79481, 26.142036",
                            "IataCode": "APF",
                            "Airports": [
                                {
                                    "Id": "APF",
                                    "Name": "Naples",
                                    "CityId": "APFA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.775278, 26.152778"
                                }
                            ]
                        },
                        {
                            "Id": "MDJA",
                            "Name": "Madras",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.12949, 44.633454",
                            "IataCode": "MDJ",
                            "Airports": [
                                {
                                    "Id": "MDJ",
                                    "Name": "Madras",
                                    "CityId": "MDJA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.281, 44.812306"
                                }
                            ]
                        },
                        {
                            "Id": "MLLA",
                            "Name": "Marshall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.08111, 61.877778",
                            "IataCode": "MLL",
                            "Airports": [
                                {
                                    "Id": "MLL",
                                    "Name": "Marshall",
                                    "CityId": "MLLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.06889, 61.865833"
                                }
                            ]
                        },
                        {
                            "Id": "DTTA",
                            "Name": "Detroit",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.045754, 42.331429",
                            "IataCode": "DTT",
                            "Airports": [
                                {
                                    "Id": "DET",
                                    "Name": "Detroit City",
                                    "CityId": "DTTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.016667, 42.416667"
                                },
                                {
                                    "Id": "YIP",
                                    "Name": "Detroit Willow Run",
                                    "CityId": "DTTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.533333, 42.233333"
                                },
                                {
                                    "Id": "DTW",
                                    "Name": "Detroit Wayne County",
                                    "CityId": "DTTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.348611, 42.218611"
                                }
                            ]
                        },
                        {
                            "Id": "YKNA",
                            "Name": "Yankton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-97.397281, 42.871109",
                            "IataCode": "YKN",
                            "Airports": [
                                {
                                    "Id": "YKN",
                                    "Name": "Yankton",
                                    "CityId": "YKNA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-97.386389, 42.916944"
                                }
                            ]
                        },
                        {
                            "Id": "MIAA",
                            "Name": "Miami",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.218101, 25.775156",
                            "IataCode": "MIA",
                            "Airports": [
                                {
                                    "Id": "MIA",
                                    "Name": "Miami International",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.287019, 25.795872"
                                },
                                {
                                    "Id": "MPB",
                                    "Name": "Miami SPB",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.166667, 25.75"
                                },
                                {
                                    "Id": "TNT",
                                    "Name": "Miami Dade Collier",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.89673, 25.86204"
                                },
                                {
                                    "Id": "OPF",
                                    "Name": "Miami Opa Locka",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.283333, 25.9"
                                },
                                {
                                    "Id": "JDM",
                                    "Name": "Miami Downtown Heliport",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.25, 25.75"
                                },
                                {
                                    "Id": "TMB",
                                    "Name": "Miami Tamiami",
                                    "CityId": "MIAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.433333, 25.65"
                                }
                            ]
                        },
                        {
                            "Id": "MYKA",
                            "Name": "May Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.69333, 61.348611",
                            "IataCode": "MYK",
                            "Airports": [
                                {
                                    "Id": "MYK",
                                    "Name": "May Creek",
                                    "CityId": "MYKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.68667, 61.33556"
                                }
                            ]
                        },
                        {
                            "Id": "PQSA",
                            "Name": "Pilot Station",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.875, 61.938889",
                            "IataCode": "PQS",
                            "Airports": [
                                {
                                    "Id": "PQS",
                                    "Name": "Pilot Station",
                                    "CityId": "PQSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.89056, 61.934722"
                                }
                            ]
                        },
                        {
                            "Id": "NWHA",
                            "Name": "Newport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-72.173421, 43.365352",
                            "IataCode": "NWH",
                            "Airports": [
                                {
                                    "Id": "NWH",
                                    "Name": "Newport",
                                    "CityId": "NWHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-72.18813, 43.38742"
                                }
                            ]
                        },
                        {
                            "Id": "NUPA",
                            "Name": "Nunapitchuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.45944, 60.896944",
                            "IataCode": "NUP",
                            "Airports": [
                                {
                                    "Id": "NUP",
                                    "Name": "Nunapitchuk",
                                    "CityId": "NUPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.46667, 60.894444"
                                }
                            ]
                        },
                        {
                            "Id": "ONHA",
                            "Name": "Oneonta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.063775, 42.452857",
                            "IataCode": "ONH",
                            "Airports": [
                                {
                                    "Id": "ONH",
                                    "Name": "Oneonta",
                                    "CityId": "ONHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.06488, 42.52397"
                                }
                            ]
                        },
                        {
                            "Id": "ORIA",
                            "Name": "Port Lions",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.88222, 57.8675",
                            "IataCode": "ORI",
                            "Airports": [
                                {
                                    "Id": "ORI",
                                    "Name": "Port Lions",
                                    "CityId": "ORIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.84389, 57.886111"
                                }
                            ]
                        },
                        {
                            "Id": "OLHA",
                            "Name": "Old Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.30389, 57.202778",
                            "IataCode": "OLH",
                            "Airports": [
                                {
                                    "Id": "OLH",
                                    "Name": "Old Harbor",
                                    "CityId": "OLHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.3, 57.204167"
                                }
                            ]
                        },
                        {
                            "Id": "ORVA",
                            "Name": "Noorvik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.03278, 66.838333",
                            "IataCode": "ORV",
                            "Airports": [
                                {
                                    "Id": "ORV",
                                    "Name": "Noorvik",
                                    "CityId": "ORVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.02361, 66.829167"
                                }
                            ]
                        },
                        {
                            "Id": "YUMA",
                            "Name": "Yuma",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-114.6244, 32.725325",
                            "IataCode": "YUM",
                            "Airports": [
                                {
                                    "Id": "LGF",
                                    "Name": "Yuma Laguna AAF",
                                    "CityId": "YUMA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-114.61667, 32.716667"
                                },
                                {
                                    "Id": "YUM",
                                    "Name": "Yuma International",
                                    "CityId": "YUMA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-114.60697, 32.655852"
                                }
                            ]
                        },
                        {
                            "Id": "PCTA",
                            "Name": "Princeton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.659047, 40.348718",
                            "IataCode": "PCT",
                            "Airports": [
                                {
                                    "Id": "PCT",
                                    "Name": "Princeton",
                                    "CityId": "PCTA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.65, 40.35"
                                }
                            ]
                        },
                        {
                            "Id": "PFNA",
                            "Name": "Panama City",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-85.660206, 30.158813",
                            "IataCode": "ECP",
                            "Airports": [
                                {
                                    "Id": "PAM",
                                    "Name": "Panama City Tyndall AFB",
                                    "CityId": "PFNA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-85.576977, 30.07095"
                                },
                                {
                                    "Id": "ECP",
                                    "Name": "Northwest Florida Beaches International",
                                    "CityId": "PFNA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-85.79892, 30.357803"
                                }
                            ]
                        },
                        {
                            "Id": "PGMA",
                            "Name": "Port Graham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.82972, 59.351389",
                            "IataCode": "PGM",
                            "Airports": [
                                {
                                    "Id": "PGM",
                                    "Name": "Port Graham",
                                    "CityId": "PGMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.83333, 59.35"
                                }
                            ]
                        },
                        {
                            "Id": "PGSA",
                            "Name": "Peach Springs",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-113.42549, 35.529159",
                            "IataCode": "PGS",
                            "Airports": [
                                {
                                    "Id": "GCW",
                                    "Name": "Peach Springs Grand Canyon West",
                                    "CityId": "PGSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-113.81639, 35.990278"
                                },
                                {
                                    "Id": "PGS",
                                    "Name": "Peach Springs",
                                    "CityId": "PGSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-113.41667, 35.533333"
                                }
                            ]
                        },
                        {
                            "Id": "PPVA",
                            "Name": "Port Protection",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.59806, 56.317222",
                            "IataCode": "PPV",
                            "Airports": [
                                {
                                    "Id": "PPV",
                                    "Name": "Port Protection",
                                    "CityId": "PPVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.61019, 56.328745"
                                }
                            ]
                        },
                        {
                            "Id": "PRZA",
                            "Name": "Prineville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-120.83447, 44.299848",
                            "IataCode": "PRZ",
                            "Airports": [
                                {
                                    "Id": "PRZ",
                                    "Name": "Prineville",
                                    "CityId": "PRZA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-120.90406, 44.28782"
                                }
                            ]
                        },
                        {
                            "Id": "NYCA",
                            "Name": "New York",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.928267, 40.694096",
                            "IataCode": "NYC",
                            "Airports": [
                                {
                                    "Id": "LGA",
                                    "Name": "New York LaGuardia",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.875833, 40.779444"
                                },
                                {
                                    "Id": "JFK",
                                    "Name": "New York John F. Kennedy",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.778118, 40.641222"
                                },
                                {
                                    "Id": "EWR",
                                    "Name": "New York Newark",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.171667, 40.695833"
                                },
                                {
                                    "Id": "SWF",
                                    "Name": "Stewart International",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.097222, 41.505833"
                                },
                                {
                                    "Id": "NES",
                                    "Name": "New York East 34th St Landing",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.975, 40.743333"
                                },
                                {
                                    "Id": "FLU",
                                    "Name": "New York Flushing",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.834444, 40.775556"
                                },
                                {
                                    "Id": "JRB",
                                    "Name": "New York Downtown Manhattan H / P",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.009233, 40.701516"
                                },
                                {
                                    "Id": "JRA",
                                    "Name": "New York West 30th St Heliport",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.0075, 40.75"
                                },
                                {
                                    "Id": "WTC",
                                    "Name": "New York World Trade Center",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.016667, 40.7"
                                },
                                {
                                    "Id": "NWS",
                                    "Name": "New York Pier 11 / Wall St. SPB",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.010556, 40.703056"
                                },
                                {
                                    "Id": "QNY",
                                    "Name": "New York Marine Air Terminal",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74, 40.75"
                                },
                                {
                                    "Id": "TSS",
                                    "Name": "New York East 34th St Heliport",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.97153, 40.7426"
                                },
                                {
                                    "Id": "NBP",
                                    "Name": "New York Battery Pk City / N. Cov",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.021667, 40.706111"
                                },
                                {
                                    "Id": "ZYP",
                                    "Name": "New York Penn STN",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.7172, 40.7006"
                                },
                                {
                                    "Id": "JRE",
                                    "Name": "New York East 60th Street H / P",
                                    "CityId": "NYCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.970833, 40.754167"
                                }
                            ]
                        },
                        {
                            "Id": "CLTA",
                            "Name": "Charlotte",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-80.843127, 35.227087",
                            "IataCode": "CLT",
                            "Airports": [
                                {
                                    "Id": "QWG",
                                    "Name": "Charlotte Wilgrove Air Park",
                                    "CityId": "CLTA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-80.67007, 35.21376"
                                },
                                {
                                    "Id": "CLT",
                                    "Name": "Charlotte Douglas",
                                    "CityId": "CLTA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-80.935833, 35.219167"
                                }
                            ]
                        },
                        {
                            "Id": "RDVA",
                            "Name": "Red Devil",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.3125, 61.761111",
                            "IataCode": "RDV",
                            "Airports": [
                                {
                                    "Id": "RDV",
                                    "Name": "Red Devil",
                                    "CityId": "RDVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.34694, 61.787222"
                                }
                            ]
                        },
                        {
                            "Id": "DCRA",
                            "Name": "Decatur",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-84.929133, 40.830603",
                            "IataCode": "DCR",
                            "Airports": [
                                {
                                    "Id": "DCR",
                                    "Name": "Decatur",
                                    "CityId": "DCRA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-84.862722, 40.837678"
                                }
                            ]
                        },
                        {
                            "Id": "RMPA",
                            "Name": "Rampart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.17, 65.505",
                            "IataCode": "RMP",
                            "Airports": [
                                {
                                    "Id": "RMP",
                                    "Name": "Rampart",
                                    "CityId": "RMPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.14028, 65.508333"
                                }
                            ]
                        },
                        {
                            "Id": "TLTA",
                            "Name": "Tuluksak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.96167, 61.1025",
                            "IataCode": "TLT",
                            "Airports": [
                                {
                                    "Id": "TLT",
                                    "Name": "Tuluksak",
                                    "CityId": "TLTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.96472, 61.098889"
                                }
                            ]
                        },
                        {
                            "Id": "TNKA",
                            "Name": "Tununak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.25583, 60.585556",
                            "IataCode": "TNK",
                            "Airports": [
                                {
                                    "Id": "TNK",
                                    "Name": "Tununak",
                                    "CityId": "TNKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.26667, 60.570833"
                                }
                            ]
                        },
                        {
                            "Id": "UCAA",
                            "Name": "Utica",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.232664, 43.100903",
                            "IataCode": "UCA",
                            "Airports": [
                                {
                                    "Id": "UCA",
                                    "Name": "Utica",
                                    "CityId": "UCAA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.380278, 43.141389"
                                }
                            ]
                        },
                        {
                            "Id": "KWKA",
                            "Name": "Kwigillingok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.13417, 59.864444",
                            "IataCode": "KWK",
                            "Airports": [
                                {
                                    "Id": "KWK",
                                    "Name": "Kwigillingok",
                                    "CityId": "KWKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.13333, 59.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KXAA",
                            "Name": "Kasaan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.40083, 55.538889",
                            "IataCode": "KXA",
                            "Airports": [
                                {
                                    "Id": "KXA",
                                    "Name": "Kasaan",
                                    "CityId": "KXAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.39583, 55.537778"
                                }
                            ]
                        },
                        {
                            "Id": "ELWA",
                            "Name": "Ellamar",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.6975, 60.895556",
                            "IataCode": "ELW",
                            "Airports": [
                                {
                                    "Id": "ELW",
                                    "Name": "Ellamar",
                                    "CityId": "ELWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.23333, 60.9"
                                }
                            ]
                        },
                        {
                            "Id": "ASYA",
                            "Name": "Ashley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-99.371503, 46.034141",
                            "IataCode": "ASY",
                            "Airports": [
                                {
                                    "Id": "ASY",
                                    "Name": "Ashley",
                                    "CityId": "ASYA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-99.366667, 46.033333"
                                }
                            ]
                        },
                        {
                            "Id": "AXVA",
                            "Name": "Wapakoneta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.193559, 40.567826",
                            "IataCode": "AXV",
                            "Airports": [
                                {
                                    "Id": "AXV",
                                    "Name": "Wapakoneta",
                                    "CityId": "AXVA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.2, 40.566667"
                                }
                            ]
                        },
                        {
                            "Id": "AXXA",
                            "Name": "Angel Fire",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.28501, 36.393088",
                            "IataCode": "AXX",
                            "Airports": [
                                {
                                    "Id": "AXX",
                                    "Name": "Angel Fire",
                                    "CityId": "AXXA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.28333, 36.416667"
                                }
                            ]
                        },
                        {
                            "Id": "BDFA",
                            "Name": "Bradford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.657598, 41.177259",
                            "IataCode": "BDF",
                            "Airports": [
                                {
                                    "Id": "BDF",
                                    "Name": "Bradford",
                                    "CityId": "BDFA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.65, 41.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BIEA",
                            "Name": "Beatrice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-96.74697, 40.268055",
                            "IataCode": "BIE",
                            "Airports": [
                                {
                                    "Id": "BIE",
                                    "Name": "Beatrice",
                                    "CityId": "BIEA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.75, 40.3"
                                }
                            ]
                        },
                        {
                            "Id": "BKDA",
                            "Name": "Breckenridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.90229, 32.755677",
                            "IataCode": "BKD",
                            "Airports": [
                                {
                                    "Id": "BKD",
                                    "Name": "Breckenridge",
                                    "CityId": "BKDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.9, 32.75"
                                }
                            ]
                        },
                        {
                            "Id": "BNWA",
                            "Name": "Boone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.880227, 42.059705",
                            "IataCode": "BNW",
                            "Airports": [
                                {
                                    "Id": "BNW",
                                    "Name": "Boone",
                                    "CityId": "BNWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.883333, 42.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BUBA",
                            "Name": "Burwell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.133154, 41.781673",
                            "IataCode": "BUB",
                            "Airports": [
                                {
                                    "Id": "BUB",
                                    "Name": "Burwell",
                                    "CityId": "BUBA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.133333, 41.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BUMA",
                            "Name": "Butler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.330506, 38.258635",
                            "IataCode": "BUM",
                            "Airports": [
                                {
                                    "Id": "BUM",
                                    "Name": "Butler",
                                    "CityId": "BUMA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.340587, 38.289431"
                                }
                            ]
                        },
                        {
                            "Id": "CCGA",
                            "Name": "Crane",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-102.35014, 31.397362",
                            "IataCode": "CCG",
                            "Airports": [
                                {
                                    "Id": "CCG",
                                    "Name": "Crane",
                                    "CityId": "CCGA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.35, 31.4"
                                }
                            ]
                        },
                        {
                            "Id": "CDLA",
                            "Name": "Candle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.92444, 65.913333",
                            "IataCode": "CDL",
                            "Airports": [
                                {
                                    "Id": "CDL",
                                    "Name": "Candle",
                                    "CityId": "CDLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.93833, 65.908056"
                                }
                            ]
                        },
                        {
                            "Id": "CEVA",
                            "Name": "Connersville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.141075, 39.641159",
                            "IataCode": "CEV",
                            "Airports": [
                                {
                                    "Id": "CEV",
                                    "Name": "Connersville",
                                    "CityId": "CEVA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.133333, 39.65"
                                }
                            ]
                        },
                        {
                            "Id": "CHKA",
                            "Name": "Chickasha",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.936433, 35.052565",
                            "IataCode": "CHK",
                            "Airports": [
                                {
                                    "Id": "CHK",
                                    "Name": "Chickasha",
                                    "CityId": "CHKA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.96643, 35.09618"
                                }
                            ]
                        },
                        {
                            "Id": "CKXA",
                            "Name": "Chicken",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-141.93611, 64.073333",
                            "IataCode": "CKX",
                            "Airports": [
                                {
                                    "Id": "CKX",
                                    "Name": "Chicken",
                                    "CityId": "CKXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-141.94917, 64.067778"
                                }
                            ]
                        },
                        {
                            "Id": "CLSA",
                            "Name": "Chehalis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.96402, 46.662048",
                            "IataCode": "CLS",
                            "Airports": [
                                {
                                    "Id": "CLS",
                                    "Name": "Chehalis",
                                    "CityId": "CLSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.98111, 46.673889"
                                }
                            ]
                        },
                        {
                            "Id": "CNEA",
                            "Name": "Canon City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.21721, 38.399999",
                            "IataCode": "CNE",
                            "Airports": [
                                {
                                    "Id": "CNE",
                                    "Name": "Canon City",
                                    "CityId": "CNEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.23333, 38.45"
                                }
                            ]
                        },
                        {
                            "Id": "CSEA",
                            "Name": "Crested Butte",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.98782, 38.869715",
                            "IataCode": "CSE",
                            "Airports": [
                                {
                                    "Id": "CSE",
                                    "Name": "Crested Butte",
                                    "CityId": "CSEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.98333, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CTKA",
                            "Name": "Canton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-96.59282, 43.300815",
                            "IataCode": "CTK",
                            "Airports": [
                                {
                                    "Id": "CTK",
                                    "Name": "Canton",
                                    "CityId": "CTKA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-96.583333, 43.3"
                                }
                            ]
                        },
                        {
                            "Id": "CSQA",
                            "Name": "Creston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.361347, 41.0586",
                            "IataCode": "CSQ",
                            "Airports": [
                                {
                                    "Id": "CSQ",
                                    "Name": "Creston",
                                    "CityId": "CSQA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.361358, 41.020384"
                                }
                            ]
                        },
                        {
                            "Id": "CSVA",
                            "Name": "Crossville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-85.026902, 35.948957",
                            "IataCode": "CSV",
                            "Airports": [
                                {
                                    "Id": "CSV",
                                    "Name": "Crossville",
                                    "CityId": "CSVA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-85.084722, 35.951111"
                                }
                            ]
                        },
                        {
                            "Id": "CTWA",
                            "Name": "Cottonwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.00988, 34.739188",
                            "IataCode": "CTW",
                            "Airports": [
                                {
                                    "Id": "CTW",
                                    "Name": "Cottonwood",
                                    "CityId": "CTWA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.03683, 34.731625"
                                }
                            ]
                        },
                        {
                            "Id": "CUHA",
                            "Name": "Cushing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-96.76697, 35.985064",
                            "IataCode": "CUH",
                            "Airports": [
                                {
                                    "Id": "CUH",
                                    "Name": "Cushing",
                                    "CityId": "CUHA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.77308, 35.94979"
                                }
                            ]
                        },
                        {
                            "Id": "CZOA",
                            "Name": "Chistochina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-144.66472, 62.565",
                            "IataCode": "CZO",
                            "Airports": [
                                {
                                    "Id": "CZO",
                                    "Name": "Chistochina",
                                    "CityId": "CZOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-144.66667, 62.566667"
                                }
                            ]
                        },
                        {
                            "Id": "DRUA",
                            "Name": "Drummond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-113.14729, 46.66743",
                            "IataCode": "DRU",
                            "Airports": [
                                {
                                    "Id": "DRU",
                                    "Name": "Drummond",
                                    "CityId": "DRUA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-113.15, 46.666667"
                                }
                            ]
                        },
                        {
                            "Id": "EBSA",
                            "Name": "Webster City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.816054, 42.469418",
                            "IataCode": "EBS",
                            "Airports": [
                                {
                                    "Id": "EBS",
                                    "Name": "Webster City",
                                    "CityId": "EBSA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.86883, 42.43581"
                                }
                            ]
                        },
                        {
                            "Id": "ECAA",
                            "Name": "East Tawas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.490252, 44.279461",
                            "IataCode": "ECA",
                            "Airports": [
                                {
                                    "Id": "ECA",
                                    "Name": "East Tawas",
                                    "CityId": "ECAA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.483333, 44.266667"
                                }
                            ]
                        },
                        {
                            "Id": "ECSA",
                            "Name": "Newcastle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.20494, 43.8547",
                            "IataCode": "ECS",
                            "Airports": [
                                {
                                    "Id": "ECS",
                                    "Name": "Newcastle",
                                    "CityId": "ECSA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.18333, 43.833333"
                                }
                            ]
                        },
                        {
                            "Id": "AUSA",
                            "Name": "Austin",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.74596, 30.261705",
                            "IataCode": "AUS",
                            "Airports": [
                                {
                                    "Id": "MMR",
                                    "Name": "Austin Camp Maybry AHP",
                                    "CityId": "AUSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.75, 30.283333"
                                },
                                {
                                    "Id": "AUS",
                                    "Name": "Austin-Bergstrom",
                                    "CityId": "AUSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.666667, 30.2"
                                }
                            ]
                        },
                        {
                            "Id": "ELAA",
                            "Name": "Eagle Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.333577, 29.589684",
                            "IataCode": "ELA",
                            "Airports": [
                                {
                                    "Id": "ELA",
                                    "Name": "Eagle Lake",
                                    "CityId": "ELAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.333333, 29.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ERRB",
                            "Name": "Errol",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.137856, 44.781439",
                            "IataCode": "ERR",
                            "Airports": [
                                {
                                    "Id": "ERR",
                                    "Name": "Errol",
                                    "CityId": "ERRB",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.133333, 44.783333"
                                }
                            ]
                        },
                        {
                            "Id": "ESPA",
                            "Name": "East Stroudsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.181291, 40.999539",
                            "IataCode": "ESP",
                            "Airports": [
                                {
                                    "Id": "ESP",
                                    "Name": "East Stroudsburg",
                                    "CityId": "ESPA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.2, 40.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ETNA",
                            "Name": "Eastland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.817562, 32.40152",
                            "IataCode": "ETN",
                            "Airports": [
                                {
                                    "Id": "ETN",
                                    "Name": "Eastland",
                                    "CityId": "ETNA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.833333, 32.383333"
                                }
                            ]
                        },
                        {
                            "Id": "EVAA",
                            "Name": "Evadale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.072681, 30.35493",
                            "IataCode": "EVA",
                            "Airports": [
                                {
                                    "Id": "EVA",
                                    "Name": "Evadale",
                                    "CityId": "EVAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.066667, 30.35"
                                }
                            ]
                        },
                        {
                            "Id": "EVMA",
                            "Name": "Eveleth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-92.539906, 47.462428",
                            "IataCode": "EVM",
                            "Airports": [
                                {
                                    "Id": "EVM",
                                    "Name": "Eveleth",
                                    "CityId": "EVMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.495833, 47.426389"
                                }
                            ]
                        },
                        {
                            "Id": "EVWA",
                            "Name": "Evanston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.96324, 41.268279",
                            "IataCode": "EVW",
                            "Airports": [
                                {
                                    "Id": "EVW",
                                    "Name": "Evanston",
                                    "CityId": "EVWA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.96667, 41.266667"
                                }
                            ]
                        },
                        {
                            "Id": "FBLA",
                            "Name": "Faribault",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.268827, 44.294964",
                            "IataCode": "FBL",
                            "Airports": [
                                {
                                    "Id": "FBL",
                                    "Name": "Faribault",
                                    "CityId": "FBLA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.31077, 44.32441"
                                }
                            ]
                        },
                        {
                            "Id": "FBRA",
                            "Name": "Fort Bridger",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.38433, 41.316617",
                            "IataCode": "FBR",
                            "Airports": [
                                {
                                    "Id": "FBR",
                                    "Name": "Fort Bridger",
                                    "CityId": "FBRA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.38333, 41.316667"
                                }
                            ]
                        },
                        {
                            "Id": "FBYA",
                            "Name": "Fairbury",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-97.180591, 40.137225",
                            "IataCode": "FBY",
                            "Airports": [
                                {
                                    "Id": "FBY",
                                    "Name": "Fairbury",
                                    "CityId": "FBYA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-97.183333, 40.133333"
                                }
                            ]
                        },
                        {
                            "Id": "FCYA",
                            "Name": "Forrest City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.789834, 35.008147",
                            "IataCode": "FCY",
                            "Airports": [
                                {
                                    "Id": "FCY",
                                    "Name": "Forrest City",
                                    "CityId": "FCYA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.766667, 34.95"
                                }
                            ]
                        },
                        {
                            "Id": "FEPA",
                            "Name": "Freeport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.621227, 42.296686",
                            "IataCode": "FEP",
                            "Airports": [
                                {
                                    "Id": "FEP",
                                    "Name": "Freeport",
                                    "CityId": "FEPA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.58178, 42.24641"
                                }
                            ]
                        },
                        {
                            "Id": "FRRA",
                            "Name": "Front Royal",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.194445, 38.918167",
                            "IataCode": "FRR",
                            "Airports": [
                                {
                                    "Id": "FRR",
                                    "Name": "Front Royal",
                                    "CityId": "FRRA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-78.2, 38.916667"
                                }
                            ]
                        },
                        {
                            "Id": "GAGA",
                            "Name": "Gage",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.757618, 36.315594",
                            "IataCode": "GAG",
                            "Airports": [
                                {
                                    "Id": "GAG",
                                    "Name": "Gage",
                                    "CityId": "GAGA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.883333, 36.35"
                                }
                            ]
                        },
                        {
                            "Id": "GNGA",
                            "Name": "Gooding",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-114.71311, 42.938792",
                            "IataCode": "GNG",
                            "Airports": [
                                {
                                    "Id": "GNG",
                                    "Name": "Gooding",
                                    "CityId": "GNGA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-114.71667, 42.933333"
                                }
                            ]
                        },
                        {
                            "Id": "GNTA",
                            "Name": "Grants",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-107.85145, 35.14726",
                            "IataCode": "GNT",
                            "Airports": [
                                {
                                    "Id": "GNT",
                                    "Name": "Grants",
                                    "CityId": "GNTA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-107.86667, 35.15"
                                }
                            ]
                        },
                        {
                            "Id": "HGZA",
                            "Name": "Hogatza",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.71722, 66.189722",
                            "IataCode": "HGZ",
                            "Airports": [
                                {
                                    "Id": "HGZ",
                                    "Name": "Hogatza",
                                    "CityId": "HGZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.66889, 66.191667"
                                }
                            ]
                        },
                        {
                            "Id": "GRNA",
                            "Name": "Gordon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-102.20322, 42.80472",
                            "IataCode": "GRN",
                            "Airports": [
                                {
                                    "Id": "GRN",
                                    "Name": "Gordon",
                                    "CityId": "GRNA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-102.2, 42.8"
                                }
                            ]
                        },
                        {
                            "Id": "GVEA",
                            "Name": "Gordonsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.187781, 38.137358",
                            "IataCode": "GVE",
                            "Airports": [
                                {
                                    "Id": "GVE",
                                    "Name": "Gordonsville",
                                    "CityId": "GVEA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-78.165879, 38.156343"
                                }
                            ]
                        },
                        {
                            "Id": "BYHA",
                            "Name": "Blytheville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-89.918975, 35.927295",
                            "IataCode": "BYH",
                            "Airports": [
                                {
                                    "Id": "BYH",
                                    "Name": "Blytheville AFB",
                                    "CityId": "BYHA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-89.916667, 35.933333"
                                },
                                {
                                    "Id": "HKA",
                                    "Name": "Blytheville Municipal",
                                    "CityId": "BYHA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-89.83064, 35.94035"
                                }
                            ]
                        },
                        {
                            "Id": "HLBA",
                            "Name": "Batesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.222184, 39.300051",
                            "IataCode": "HLB",
                            "Airports": [
                                {
                                    "Id": "HLB",
                                    "Name": "Batesville",
                                    "CityId": "HLBA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.216667, 39.3"
                                }
                            ]
                        },
                        {
                            "Id": "HSPA",
                            "Name": "Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-79.831719, 37.99957",
                            "IataCode": "HSP",
                            "Airports": [
                                {
                                    "Id": "HSP",
                                    "Name": "Hot Springs",
                                    "CityId": "HSPA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-79.833889, 37.950556"
                                }
                            ]
                        },
                        {
                            "Id": "HPTA",
                            "Name": "Hampton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.202423, 42.741918",
                            "IataCode": "HPT",
                            "Airports": [
                                {
                                    "Id": "HPT",
                                    "Name": "Hampton",
                                    "CityId": "HPTA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.2, 42.75"
                                }
                            ]
                        },
                        {
                            "Id": "HSBA",
                            "Name": "Harrisburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.540607, 37.738381",
                            "IataCode": "HSB",
                            "Airports": [
                                {
                                    "Id": "HSB",
                                    "Name": "Harrisburg",
                                    "CityId": "HSBA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.533333, 37.733333"
                                }
                            ]
                        },
                        {
                            "Id": "HSIA",
                            "Name": "Hastings",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-98.388393, 40.586125",
                            "IataCode": "HSI",
                            "Airports": [
                                {
                                    "Id": "HSI",
                                    "Name": "Hastings",
                                    "CityId": "HSIA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-98.426944, 40.605556"
                                }
                            ]
                        },
                        {
                            "Id": "IRBA",
                            "Name": "Iraan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.89791, 30.914048",
                            "IataCode": "IRB",
                            "Airports": [
                                {
                                    "Id": "IRB",
                                    "Name": "Iraan",
                                    "CityId": "IRBA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.93333, 30.7"
                                }
                            ]
                        },
                        {
                            "Id": "IRSA",
                            "Name": "Sturgis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.419148, 41.799217",
                            "IataCode": "IRS",
                            "Airports": [
                                {
                                    "Id": "IRS",
                                    "Name": "Sturgis",
                                    "CityId": "IRSA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.435278, 41.816667"
                                }
                            ]
                        },
                        {
                            "Id": "ISQA",
                            "Name": "Manistique",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.246252, 45.957751",
                            "IataCode": "ISQ",
                            "Airports": [
                                {
                                    "Id": "ISQ",
                                    "Name": "Manistique",
                                    "CityId": "ISQA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.25, 45.95"
                                }
                            ]
                        },
                        {
                            "Id": "JDNA",
                            "Name": "Jordan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-106.91007, 47.320834",
                            "IataCode": "JDN",
                            "Airports": [
                                {
                                    "Id": "JDN",
                                    "Name": "Jordan",
                                    "CityId": "JDNA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-106.93451, 47.33333"
                                }
                            ]
                        },
                        {
                            "Id": "MGEA",
                            "Name": "Marietta",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.549933, 33.952602",
                            "IataCode": "MGE",
                            "Airports": [
                                {
                                    "Id": "NCQ",
                                    "Name": "Marietta Atlanta NAS",
                                    "CityId": "MGEA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.516667, 33.916667"
                                },
                                {
                                    "Id": "MGE",
                                    "Name": "Marietta Dobbins AFB",
                                    "CityId": "MGEA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.516667, 33.916667"
                                }
                            ]
                        },
                        {
                            "Id": "HOUA",
                            "Name": "Houston",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.363271, 29.763287",
                            "IataCode": "HOU",
                            "Airports": [
                                {
                                    "Id": "JMA",
                                    "Name": "Houston Marriot Astrodome",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.34, 29.983333"
                                },
                                {
                                    "Id": "JDX",
                                    "Name": "Houston Central Bus. District",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.333333, 29.766667"
                                },
                                {
                                    "Id": "JWL",
                                    "Name": "Houston Woodlawns",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.471389, 30.150556"
                                },
                                {
                                    "Id": "DWH",
                                    "Name": "Houston David Wayne Hooks",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.416667, 29.75"
                                },
                                {
                                    "Id": "JPT",
                                    "Name": "Houston Park Ten Heliport",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.811667, 29.786389"
                                },
                                {
                                    "Id": "JGP",
                                    "Name": "Houston Greenway Plaza H / P",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.25, 29.666667"
                                },
                                {
                                    "Id": "JWH",
                                    "Name": "Houston Westchase Hilton H / P",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.417778, 29.736111"
                                },
                                {
                                    "Id": "IWS",
                                    "Name": "West Houston",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.666667, 29.816667"
                                },
                                {
                                    "Id": "SGR",
                                    "Name": "Houston Sugar Land Municipal",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.407778, 29.698889"
                                },
                                {
                                    "Id": "JGQ",
                                    "Name": "Houston Transco Twr Galleria",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.466667, 29.766667"
                                },
                                {
                                    "Id": "EFD",
                                    "Name": "Houston Ellington Field",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.15, 29.6"
                                },
                                {
                                    "Id": "HOU",
                                    "Name": "Houston Hobby",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.278611, 29.645"
                                },
                                {
                                    "Id": "IAH",
                                    "Name": "Houston George Bush Intercntl.",
                                    "CityId": "HOUA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.34, 29.983333"
                                }
                            ]
                        },
                        {
                            "Id": "RALA",
                            "Name": "Riverside",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.39616, 33.95335",
                            "IataCode": "RAL",
                            "Airports": [
                                {
                                    "Id": "RIV",
                                    "Name": "Riverside March AFB",
                                    "CityId": "RALA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.36667, 33.983333"
                                },
                                {
                                    "Id": "RAL",
                                    "Name": "Riverside Municipal",
                                    "CityId": "RALA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.44222, 33.951667"
                                },
                                {
                                    "Id": "JRD",
                                    "Name": "Riverside Heliport",
                                    "CityId": "RALA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.36667, 33.983333"
                                },
                                {
                                    "Id": "RIR",
                                    "Name": "Riverside Fla-Bob",
                                    "CityId": "RALA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.41144, 33.98974"
                                }
                            ]
                        },
                        {
                            "Id": "WASA",
                            "Name": "Washington",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "DISTR",
                            "Location": "-77.010464, 38.90915",
                            "IataCode": "WAS",
                            "Airports": [
                                {
                                    "Id": "BOF",
                                    "Name": "Washington Bolling AFB",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.033333, 38.9"
                                },
                                {
                                    "Id": "BZS",
                                    "Name": "Washington Buzzards Pt S",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.013333, 38.863333"
                                },
                                {
                                    "Id": "JPN",
                                    "Name": "Washington Pentagon Army",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.066667, 38.766667"
                                },
                                {
                                    "Id": "BWI",
                                    "Name": "Baltimore Washington International",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-76.671389, 39.175556"
                                },
                                {
                                    "Id": "DCA",
                                    "Name": "Washington Ronald Reagan",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.0369, 38.8517"
                                },
                                {
                                    "Id": "IAD",
                                    "Name": "Washington Dulles",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.45, 38.95"
                                },
                                {
                                    "Id": "ZRZ",
                                    "Name": "Washington New Carrolton RR",
                                    "CityId": "WASA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77.1673, 38.8839"
                                }
                            ]
                        },
                        {
                            "Id": "JSDA",
                            "Name": "Stratford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-73.133165, 41.184541",
                            "IataCode": "JSD",
                            "Airports": [
                                {
                                    "Id": "JSD",
                                    "Name": "Stratford",
                                    "CityId": "JSDA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-73.133333, 41.2"
                                }
                            ]
                        },
                        {
                            "Id": "KKUA",
                            "Name": "Ekuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.55339, 58.80351",
                            "IataCode": "KKU",
                            "Airports": [
                                {
                                    "Id": "KKU",
                                    "Name": "Ekuk",
                                    "CityId": "KKUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.56056, 58.8125"
                                }
                            ]
                        },
                        {
                            "Id": "KKTA",
                            "Name": "Kentland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.445303, 40.770315",
                            "IataCode": "KKT",
                            "Airports": [
                                {
                                    "Id": "KKT",
                                    "Name": "Kentland",
                                    "CityId": "KKTA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.429714, 40.760261"
                                }
                            ]
                        },
                        {
                            "Id": "BIHA",
                            "Name": "Bishop",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.39511, 37.36354",
                            "IataCode": "BIH",
                            "Airports": [
                                {
                                    "Id": "BIH",
                                    "Name": "Bishop",
                                    "CityId": "BIHA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.365, 37.373333"
                                }
                            ]
                        },
                        {
                            "Id": "SEAA",
                            "Name": "Seattle",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.33305, 47.617935",
                            "IataCode": "SEA",
                            "Airports": [
                                {
                                    "Id": "LKE",
                                    "Name": "Seattle Lake Union SPB",
                                    "CityId": "SEAA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.33167, 47.6275"
                                },
                                {
                                    "Id": "SEA",
                                    "Name": "Seattle / Tacoma International",
                                    "CityId": "SEAA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.30556, 47.447222"
                                },
                                {
                                    "Id": "BFI",
                                    "Name": "Seattle Boeing Fld",
                                    "CityId": "SEAA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.29944, 47.528889"
                                }
                            ]
                        },
                        {
                            "Id": "BVOA",
                            "Name": "Bartlesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.980818, 36.747311",
                            "IataCode": "BVO",
                            "Airports": [
                                {
                                    "Id": "BVO",
                                    "Name": "Bartlesville",
                                    "CityId": "BVOA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.010833, 36.7625"
                                }
                            ]
                        },
                        {
                            "Id": "CUSA",
                            "Name": "Columbus",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-107.64002, 31.8276",
                            "IataCode": "CUS",
                            "Airports": [
                                {
                                    "Id": "CUS",
                                    "Name": "Columbus",
                                    "CityId": "CUSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-107.63333, 31.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CZTA",
                            "Name": "Carrizo Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.860611, 28.521929",
                            "IataCode": "CZT",
                            "Airports": [
                                {
                                    "Id": "CZT",
                                    "Name": "Carrizo Springs",
                                    "CityId": "CZTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.866667, 28.516667"
                                }
                            ]
                        },
                        {
                            "Id": "DNSA",
                            "Name": "Denison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.355276, 42.017766",
                            "IataCode": "DNS",
                            "Airports": [
                                {
                                    "Id": "DNS",
                                    "Name": "Denison",
                                    "CityId": "DNSA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.35, 42.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ESTA",
                            "Name": "Estherville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.832764, 43.401625",
                            "IataCode": "EST",
                            "Airports": [
                                {
                                    "Id": "EST",
                                    "Name": "Estherville",
                                    "CityId": "ESTA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.833333, 43.4"
                                }
                            ]
                        },
                        {
                            "Id": "FYMA",
                            "Name": "Fayetteville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-86.570551, 35.152027",
                            "IataCode": "FYM",
                            "Airports": [
                                {
                                    "Id": "FYM",
                                    "Name": "Fayetteville",
                                    "CityId": "FYMA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.566667, 35.15"
                                }
                            ]
                        },
                        {
                            "Id": "GEYA",
                            "Name": "Greybull",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-108.05621, 44.489124",
                            "IataCode": "GEY",
                            "Airports": [
                                {
                                    "Id": "GEY",
                                    "Name": "Greybull",
                                    "CityId": "GEYA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-108.05, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "GWSA",
                            "Name": "Glenwood Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.32478, 39.550538",
                            "IataCode": "GWS",
                            "Airports": [
                                {
                                    "Id": "GWS",
                                    "Name": "Glenwood Springs",
                                    "CityId": "GWSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.31033, 39.504968"
                                }
                            ]
                        },
                        {
                            "Id": "CHDA",
                            "Name": "Chandler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.84125, 33.30616",
                            "IataCode": "CHD",
                            "Airports": [
                                {
                                    "Id": "CHD",
                                    "Name": "Chandler Williams AFB",
                                    "CityId": "CHDA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.91667, 33.3"
                                }
                            ]
                        },
                        {
                            "Id": "BSQA",
                            "Name": "Bisbee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.92841, 31.448155",
                            "IataCode": "BSQ",
                            "Airports": [
                                {
                                    "Id": "BSQ",
                                    "Name": "Bisbee",
                                    "CityId": "BSQA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.88306, 31.363889"
                                }
                            ]
                        },
                        {
                            "Id": "CHZA",
                            "Name": "Chiloquin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.86613, 42.577636",
                            "IataCode": "CHZ",
                            "Airports": [
                                {
                                    "Id": "CHZ",
                                    "Name": "Chiloquin",
                                    "CityId": "CHZA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.86667, 42.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BWLA",
                            "Name": "Blackwell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.282821, 36.804477",
                            "IataCode": "BWL",
                            "Airports": [
                                {
                                    "Id": "BWL",
                                    "Name": "Blackwell",
                                    "CityId": "BWLA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.317452, 36.79633"
                                }
                            ]
                        },
                        {
                            "Id": "BSWA",
                            "Name": "Boswell Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.13333, 60.4",
                            "IataCode": "BSW",
                            "Airports": [
                                {
                                    "Id": "BSW",
                                    "Name": "Boswell Bay",
                                    "CityId": "BSWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.13333, 60.4"
                                }
                            ]
                        },
                        {
                            "Id": "CHPA",
                            "Name": "Circle Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-144.63417, 65.483333",
                            "IataCode": "CHP",
                            "Airports": [
                                {
                                    "Id": "CHP",
                                    "Name": "Circle Hot Springs",
                                    "CityId": "CHPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-144.58333, 65.466667"
                                }
                            ]
                        },
                        {
                            "Id": "CLGA",
                            "Name": "Coalinga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.36015, 36.139678",
                            "IataCode": "CLG",
                            "Airports": [
                                {
                                    "Id": "CLG",
                                    "Name": "Coalinga",
                                    "CityId": "CLGA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.3601, 36.158026"
                                }
                            ]
                        },
                        {
                            "Id": "CGAA",
                            "Name": "Craig",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.14833, 55.476389",
                            "IataCode": "CGA",
                            "Airports": [
                                {
                                    "Id": "CGA",
                                    "Name": "Craig",
                                    "CityId": "CGAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.14611, 55.479167"
                                }
                            ]
                        },
                        {
                            "Id": "CRPA",
                            "Name": "Corpus Christi",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.396381, 27.800584",
                            "IataCode": "CRP",
                            "Airports": [
                                {
                                    "Id": "NGP",
                                    "Name": "Corpus Christi NAS",
                                    "CityId": "CRPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.283333, 27.7"
                                },
                                {
                                    "Id": "CUX",
                                    "Name": "Corpus Christi Cuddihy Field",
                                    "CityId": "CRPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.336111, 27.75"
                                },
                                {
                                    "Id": "NGW",
                                    "Name": "Corpus Christi Cabaniss Field",
                                    "CityId": "CRPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.4, 27.783333"
                                },
                                {
                                    "Id": "CRP",
                                    "Name": "Corpus Christi International",
                                    "CityId": "CRPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.501389, 27.772222"
                                }
                            ]
                        },
                        {
                            "Id": "DBSA",
                            "Name": "Dubois",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-112.23082, 44.176298",
                            "IataCode": "DBS",
                            "Airports": [
                                {
                                    "Id": "DBS",
                                    "Name": "Dubois",
                                    "CityId": "DBSA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-112.23333, 44.166667"
                                }
                            ]
                        },
                        {
                            "Id": "EPGA",
                            "Name": "Weeping Water",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-96.140566, 40.870001",
                            "IataCode": "EPG",
                            "Airports": [
                                {
                                    "Id": "EPG",
                                    "Name": "Weeping Water",
                                    "CityId": "EPGA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.133333, 40.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LMAA",
                            "Name": "Lake Minchumina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.31222, 63.882778",
                            "IataCode": "LMA",
                            "Airports": [
                                {
                                    "Id": "LMA",
                                    "Name": "Lake Minchumina",
                                    "CityId": "LMAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.31667, 63.883333"
                                }
                            ]
                        },
                        {
                            "Id": "HOBA",
                            "Name": "Hobbs",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-103.13604, 32.702612",
                            "IataCode": "HOB",
                            "Airports": [
                                {
                                    "Id": "HOB",
                                    "Name": "Hobbs Lea County",
                                    "CityId": "HOBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.21694, 32.688611"
                                },
                                {
                                    "Id": "HBB",
                                    "Name": "Hobbs Industrial Airpark",
                                    "CityId": "HOBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.13333, 32.7"
                                }
                            ]
                        },
                        {
                            "Id": "BETA",
                            "Name": "Bethel",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.75583, 60.792222",
                            "IataCode": "BET",
                            "Airports": [
                                {
                                    "Id": "BET",
                                    "Name": "Bethel",
                                    "CityId": "BETA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.83139, 60.784444"
                                },
                                {
                                    "Id": "JBT",
                                    "Name": "Bethel City Landing",
                                    "CityId": "BETA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.75, 60.8"
                                }
                            ]
                        },
                        {
                            "Id": "OAKA",
                            "Name": "Oakland",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.2708, 37.804372",
                            "IataCode": "OAK",
                            "Airports": [
                                {
                                    "Id": "JCE",
                                    "Name": "Oakland Convention Center H / P",
                                    "CityId": "OAKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.26667, 37.816667"
                                },
                                {
                                    "Id": "OAK",
                                    "Name": "Oakland Metropolitan Oak",
                                    "CityId": "OAKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.21556, 37.723611"
                                }
                            ]
                        },
                        {
                            "Id": "JORA",
                            "Name": "Orange",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.85311, 33.787794",
                            "IataCode": "JOR",
                            "Airports": [
                                {
                                    "Id": "JOR",
                                    "Name": "Orange",
                                    "CityId": "JORA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.85, 33.783333"
                                }
                            ]
                        },
                        {
                            "Id": "KWFA",
                            "Name": "Waterfall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.24056, 55.297222",
                            "IataCode": "KWF",
                            "Airports": [
                                {
                                    "Id": "KWF",
                                    "Name": "Waterfall",
                                    "CityId": "KWFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.24167, 55.297222"
                                }
                            ]
                        },
                        {
                            "Id": "KTBA",
                            "Name": "Thorne Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.52222, 55.687778",
                            "IataCode": "KTB",
                            "Airports": [
                                {
                                    "Id": "KTB",
                                    "Name": "Thorne Bay",
                                    "CityId": "KTBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.535, 55.688333"
                                }
                            ]
                        },
                        {
                            "Id": "FAIA",
                            "Name": "Fairbanks",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.71639, 64.837778",
                            "IataCode": "FAI",
                            "Airports": [
                                {
                                    "Id": "FAI",
                                    "Name": "Fairbanks International",
                                    "CityId": "FAIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.86278, 64.814444"
                                },
                                {
                                    "Id": "FBK",
                                    "Name": "Fairbanks Ft Wainwright",
                                    "CityId": "FAIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.7, 64.833333"
                                },
                                {
                                    "Id": "EIL",
                                    "Name": "Fairbanks Eielson AFB",
                                    "CityId": "FAIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.71667, 64.85"
                                },
                                {
                                    "Id": "PII",
                                    "Name": "Fairbanks Phillips Field",
                                    "CityId": "FAIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.71389, 64.863889"
                                },
                                {
                                    "Id": "MTX",
                                    "Name": "Fairbanks Metro Field",
                                    "CityId": "FAIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.86667, 64.816667"
                                }
                            ]
                        },
                        {
                            "Id": "NPHA",
                            "Name": "Nephi",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.83632, 39.710234",
                            "IataCode": "NPH",
                            "Airports": [
                                {
                                    "Id": "NPH",
                                    "Name": "Nephi",
                                    "CityId": "NPHA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.87083, 39.738889"
                                }
                            ]
                        },
                        {
                            "Id": "NTJA",
                            "Name": "Manti",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.63686, 39.268295",
                            "IataCode": "NTJ",
                            "Airports": [
                                {
                                    "Id": "NTJ",
                                    "Name": "Manti-Ephraim",
                                    "CityId": "NTJA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.63333, 39.266667"
                                }
                            ]
                        },
                        {
                            "Id": "KYLA",
                            "Name": "Key Largo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.44728, 25.086515",
                            "IataCode": "KYL",
                            "Airports": [
                                {
                                    "Id": "KYL",
                                    "Name": "Key Largo",
                                    "CityId": "KYLA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.466667, 25.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OTNA",
                            "Name": "Oaktown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.441408, 38.871156",
                            "IataCode": "OTN",
                            "Airports": [
                                {
                                    "Id": "OTN",
                                    "Name": "Oaktown",
                                    "CityId": "OTNA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.45, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "PGCA",
                            "Name": "Petersburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-79.123924, 38.99261",
                            "IataCode": "PGC",
                            "Airports": [
                                {
                                    "Id": "PGC",
                                    "Name": "Petersburg",
                                    "CityId": "PGCA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-79.116667, 39"
                                }
                            ]
                        },
                        {
                            "Id": "PNUA",
                            "Name": "Panguitch",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.43576, 37.822755",
                            "IataCode": "PNU",
                            "Airports": [
                                {
                                    "Id": "PNU",
                                    "Name": "Panguitch",
                                    "CityId": "PNUA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.3928, 37.846664"
                                }
                            ]
                        },
                        {
                            "Id": "PLYA",
                            "Name": "Plymouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.309728, 41.343655",
                            "IataCode": "PLY",
                            "Airports": [
                                {
                                    "Id": "PLY",
                                    "Name": "Plymouth",
                                    "CityId": "PLYA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.301667, 41.365278"
                                }
                            ]
                        },
                        {
                            "Id": "PTCA",
                            "Name": "Port Alice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.60278, 55.826389",
                            "IataCode": "PTC",
                            "Airports": [
                                {
                                    "Id": "PTC",
                                    "Name": "Port Alice",
                                    "CityId": "PTCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.585, 55.783611"
                                }
                            ]
                        },
                        {
                            "Id": "PULB",
                            "Name": "Poulsbo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.64654, 47.735928",
                            "IataCode": "PUL",
                            "Airports": [
                                {
                                    "Id": "PUL",
                                    "Name": "Poulsbo",
                                    "CityId": "PULB",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.63832, 47.733089"
                                }
                            ]
                        },
                        {
                            "Id": "SXYA",
                            "Name": "Sidney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.391566, 42.314802",
                            "IataCode": "SXY",
                            "Airports": [
                                {
                                    "Id": "SXY",
                                    "Name": "Sidney",
                                    "CityId": "SXYA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.4, 42.316667"
                                }
                            ]
                        },
                        {
                            "Id": "TGEA",
                            "Name": "Tuskegee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.69162, 32.424029",
                            "IataCode": "TGE",
                            "Airports": [
                                {
                                    "Id": "TGE",
                                    "Name": "Tuskegee",
                                    "CityId": "TGEA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.683333, 32.466667"
                                }
                            ]
                        },
                        {
                            "Id": "SRVA",
                            "Name": "Stony River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.58806, 61.783056",
                            "IataCode": "SRV",
                            "Airports": [
                                {
                                    "Id": "SRV",
                                    "Name": "Stony River",
                                    "CityId": "SRVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.58611, 61.791111"
                                }
                            ]
                        },
                        {
                            "Id": "SQVA",
                            "Name": "Sequim",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.10184, 48.079536",
                            "IataCode": "SQV",
                            "Airports": [
                                {
                                    "Id": "SQV",
                                    "Name": "Sequim",
                                    "CityId": "SQVA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.18528, 48.097778"
                                }
                            ]
                        },
                        {
                            "Id": "TKIA",
                            "Name": "Tokeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.325, 55.9375",
                            "IataCode": "TKI",
                            "Airports": [
                                {
                                    "Id": "TKI",
                                    "Name": "Tokeen",
                                    "CityId": "TKIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.325, 55.9375"
                                }
                            ]
                        },
                        {
                            "Id": "TWDA",
                            "Name": "Port Townsend",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.76045, 48.117039",
                            "IataCode": "TWD",
                            "Airports": [
                                {
                                    "Id": "TWD",
                                    "Name": "Port Townsend",
                                    "CityId": "TWDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.80128, 48.04981"
                                }
                            ]
                        },
                        {
                            "Id": "UKNA",
                            "Name": "Waukon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.475695, 43.269421",
                            "IataCode": "UKN",
                            "Airports": [
                                {
                                    "Id": "UKN",
                                    "Name": "Waukon",
                                    "CityId": "UKNA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.483333, 43.266667"
                                }
                            ]
                        },
                        {
                            "Id": "GCNA",
                            "Name": "Grand Canyon",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.13934, 36.054427",
                            "IataCode": "GCN",
                            "Airports": [
                                {
                                    "Id": "JGC",
                                    "Name": "Grand Canyon Heliport",
                                    "CityId": "GCNA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.1475, 35.951944"
                                },
                                {
                                    "Id": "NGC",
                                    "Name": "Grand Canyon North Rim",
                                    "CityId": "GCNA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.12917, 36.389722"
                                },
                                {
                                    "Id": "GCN",
                                    "Name": "Grand Canyon National Park",
                                    "CityId": "GCNA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.145, 35.955221"
                                },
                                {
                                    "Id": "FLG",
                                    "Name": "Flagstaff Pulliam",
                                    "CityId": "GCNA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.66917, 35.140278"
                                }
                            ]
                        },
                        {
                            "Id": "VRSA",
                            "Name": "Versailles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.841027, 38.431414",
                            "IataCode": "VRS",
                            "Airports": [
                                {
                                    "Id": "VRS",
                                    "Name": "Versailles",
                                    "CityId": "VRSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-85.246053, 39.059833"
                                }
                            ]
                        },
                        {
                            "Id": "XESA",
                            "Name": "Lake Geneva",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.43343, 42.591684",
                            "IataCode": "XES",
                            "Airports": [
                                {
                                    "Id": "XES",
                                    "Name": "Lake Geneva",
                                    "CityId": "XESA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.428056, 42.627778"
                                }
                            ]
                        },
                        {
                            "Id": "LIVA",
                            "Name": "Livengood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.54472, 65.524444",
                            "IataCode": "LIV",
                            "Airports": [
                                {
                                    "Id": "LIV",
                                    "Name": "Livengood",
                                    "CityId": "LIVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.66667, 65.516667"
                                }
                            ]
                        },
                        {
                            "Id": "LMSA",
                            "Name": "Louisville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.055062, 33.123738",
                            "IataCode": "LMS",
                            "Airports": [
                                {
                                    "Id": "LMS",
                                    "Name": "Louisville",
                                    "CityId": "LMSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.05, 33.116667"
                                }
                            ]
                        },
                        {
                            "Id": "LNDA",
                            "Name": "Lander",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-108.73067, 42.833014",
                            "IataCode": "LND",
                            "Airports": [
                                {
                                    "Id": "LND",
                                    "Name": "Lander",
                                    "CityId": "LNDA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-108.73333, 42.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BDYA",
                            "Name": "Bandon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-124.40845, 43.118998",
                            "IataCode": "BDY",
                            "Airports": [
                                {
                                    "Id": "BDY",
                                    "Name": "Bandon",
                                    "CityId": "BDYA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-124.41667, 43.116667"
                                }
                            ]
                        },
                        {
                            "Id": "LSBA",
                            "Name": "Lordsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-108.70866, 32.35036",
                            "IataCode": "LSB",
                            "Airports": [
                                {
                                    "Id": "LSB",
                                    "Name": "Lordsburg",
                                    "CityId": "LSBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-108.66667, 32.416667"
                                }
                            ]
                        },
                        {
                            "Id": "LSKA",
                            "Name": "Lusk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.45217, 42.762467",
                            "IataCode": "LSK",
                            "Airports": [
                                {
                                    "Id": "LSK",
                                    "Name": "Lusk",
                                    "CityId": "LSKA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.44553, 42.788083"
                                }
                            ]
                        },
                        {
                            "Id": "LVDA",
                            "Name": "Lime Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.43556, 61.356389",
                            "IataCode": "LVD",
                            "Airports": [
                                {
                                    "Id": "LVD",
                                    "Name": "Lime Village",
                                    "CityId": "LVDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.46667, 61.35"
                                }
                            ]
                        },
                        {
                            "Id": "LVLA",
                            "Name": "Lawrenceville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.846937, 36.757647",
                            "IataCode": "LVL",
                            "Airports": [
                                {
                                    "Id": "LVL",
                                    "Name": "Lawrenceville",
                                    "CityId": "LVLA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.833333, 36.75"
                                }
                            ]
                        },
                        {
                            "Id": "LVMA",
                            "Name": "Livingston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-110.56104, 45.662435",
                            "IataCode": "LVM",
                            "Airports": [
                                {
                                    "Id": "LVM",
                                    "Name": "Livingston",
                                    "CityId": "LVMA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-110.44881, 45.6991"
                                }
                            ]
                        },
                        {
                            "Id": "LYOA",
                            "Name": "Lyon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-98.201727, 38.34501",
                            "IataCode": "LYO",
                            "Airports": [
                                {
                                    "Id": "LYO",
                                    "Name": "Lyon",
                                    "CityId": "LYOA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-98.227215, 38.345022"
                                }
                            ]
                        },
                        {
                            "Id": "MBGA",
                            "Name": "Mobridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-100.42791, 45.537216",
                            "IataCode": "MBG",
                            "Airports": [
                                {
                                    "Id": "MBG",
                                    "Name": "Mobridge",
                                    "CityId": "MBGA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-100.40041, 45.54999"
                                }
                            ]
                        },
                        {
                            "Id": "MHLA",
                            "Name": "Marshall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.19687, 39.123078",
                            "IataCode": "MHL",
                            "Airports": [
                                {
                                    "Id": "MHL",
                                    "Name": "Marshall",
                                    "CityId": "MHLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.2, 39.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MHNA",
                            "Name": "Mullen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-101.04266, 42.042775",
                            "IataCode": "MHN",
                            "Airports": [
                                {
                                    "Id": "MHN",
                                    "Name": "Mullen",
                                    "CityId": "MHNA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-101.01667, 42.05"
                                }
                            ]
                        },
                        {
                            "Id": "MIWA",
                            "Name": "Marshalltown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-92.907977, 42.049432",
                            "IataCode": "MIW",
                            "Airports": [
                                {
                                    "Id": "MIW",
                                    "Name": "Marshalltown",
                                    "CityId": "MIWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-92.916667, 42.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MLDA",
                            "Name": "Malad City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-112.2508, 42.191587",
                            "IataCode": "MLD",
                            "Airports": [
                                {
                                    "Id": "MLD",
                                    "Name": "Malad City",
                                    "CityId": "MLDA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-112.45, 42.2"
                                }
                            ]
                        },
                        {
                            "Id": "MLJA",
                            "Name": "Milledgeville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.232099, 33.080143",
                            "IataCode": "MLJ",
                            "Airports": [
                                {
                                    "Id": "MLJ",
                                    "Name": "Milledgeville",
                                    "CityId": "MLJA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.233333, 33.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MPJA",
                            "Name": "Morrilton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.744054, 35.150917",
                            "IataCode": "MPJ",
                            "Airports": [
                                {
                                    "Id": "MPJ",
                                    "Name": "Morrilton",
                                    "CityId": "MPJA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.733333, 35.15"
                                }
                            ]
                        },
                        {
                            "Id": "MPOA",
                            "Name": "Mt Pocono",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.364628, 41.122034",
                            "IataCode": "MPO",
                            "Airports": [
                                {
                                    "Id": "MPO",
                                    "Name": "Mt Pocono",
                                    "CityId": "MPOA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.366667, 41.133333"
                                }
                            ]
                        },
                        {
                            "Id": "CILA",
                            "Name": "Council",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.67639, 64.895",
                            "IataCode": "CIL",
                            "Airports": [
                                {
                                    "Id": "CIL",
                                    "Name": "Council",
                                    "CityId": "CILA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.73333, 64.908333"
                                }
                            ]
                        },
                        {
                            "Id": "MVEA",
                            "Name": "Montevideo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.723637, 44.942457",
                            "IataCode": "MVE",
                            "Airports": [
                                {
                                    "Id": "MVE",
                                    "Name": "Montevideo",
                                    "CityId": "MVEA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.716667, 44.95"
                                }
                            ]
                        },
                        {
                            "Id": "MVMA",
                            "Name": "Kayenta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.25458, 36.727777",
                            "IataCode": "MVM",
                            "Airports": [
                                {
                                    "Id": "MVM",
                                    "Name": "Kayenta",
                                    "CityId": "MVMA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.23896, 36.712261"
                                }
                            ]
                        },
                        {
                            "Id": "MWMA",
                            "Name": "Windom",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.116937, 43.866346",
                            "IataCode": "MWM",
                            "Airports": [
                                {
                                    "Id": "MWM",
                                    "Name": "Windom",
                                    "CityId": "MWMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.1, 43.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MZZA",
                            "Name": "Marion",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.659144, 40.558374",
                            "IataCode": "MZZ",
                            "Airports": [
                                {
                                    "Id": "MZZ",
                                    "Name": "Marion",
                                    "CityId": "MZZA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.679444, 40.490833"
                                }
                            ]
                        },
                        {
                            "Id": "NINA",
                            "Name": "Ninilchik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.66889, 60.051389",
                            "IataCode": "NIN",
                            "Airports": [
                                {
                                    "Id": "NIN",
                                    "Name": "Ninilchik",
                                    "CityId": "NINA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.73333, 60.066667"
                                }
                            ]
                        },
                        {
                            "Id": "NLEA",
                            "Name": "Niles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.254177, 41.829769",
                            "IataCode": "NLE",
                            "Airports": [
                                {
                                    "Id": "NLE",
                                    "Name": "Niles",
                                    "CityId": "NLEA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.225, 41.841667"
                                }
                            ]
                        },
                        {
                            "Id": "OCWA",
                            "Name": "Washington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.052174, 35.546552",
                            "IataCode": "OCW",
                            "Airports": [
                                {
                                    "Id": "OCW",
                                    "Name": "Washington",
                                    "CityId": "OCWA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.05, 35.55"
                                }
                            ]
                        },
                        {
                            "Id": "OGAA",
                            "Name": "Ogallala",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-101.71962, 41.128048",
                            "IataCode": "OGA",
                            "Airports": [
                                {
                                    "Id": "OGA",
                                    "Name": "Ogallala",
                                    "CityId": "OGAA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-101.76447, 41.120034"
                                }
                            ]
                        },
                        {
                            "Id": "OKSA",
                            "Name": "Oshkosh",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-102.34436, 41.404983",
                            "IataCode": "OKS",
                            "Airports": [
                                {
                                    "Id": "OKS",
                                    "Name": "Oshkosh",
                                    "CityId": "OKSA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-102.35, 41.4"
                                }
                            ]
                        },
                        {
                            "Id": "OLEA",
                            "Name": "Olean",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-78.429742, 42.077565",
                            "IataCode": "OLE",
                            "Airports": [
                                {
                                    "Id": "OLE",
                                    "Name": "Olean",
                                    "CityId": "OLEA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-78.376667, 42.240833"
                                }
                            ]
                        },
                        {
                            "Id": "ONMA",
                            "Name": "Socorro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.89142, 34.058399",
                            "IataCode": "ONM",
                            "Airports": [
                                {
                                    "Id": "ONM",
                                    "Name": "Socorro",
                                    "CityId": "ONMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.9, 34.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OZAA",
                            "Name": "Ozona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.20067, 30.710173",
                            "IataCode": "OZA",
                            "Airports": [
                                {
                                    "Id": "OZA",
                                    "Name": "Ozona",
                                    "CityId": "OZAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.21667, 30.716667"
                                }
                            ]
                        },
                        {
                            "Id": "ONYA",
                            "Name": "Olney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.75284, 33.370663",
                            "IataCode": "ONY",
                            "Airports": [
                                {
                                    "Id": "ONY",
                                    "Name": "Olney",
                                    "CityId": "ONYA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.75, 33.366667"
                                }
                            ]
                        },
                        {
                            "Id": "OOAA",
                            "Name": "Oskaloosa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-92.644359, 41.296395",
                            "IataCode": "OOA",
                            "Airports": [
                                {
                                    "Id": "OOA",
                                    "Name": "Oskaloosa",
                                    "CityId": "OOAA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-92.65, 41.3"
                                }
                            ]
                        },
                        {
                            "Id": "OSXA",
                            "Name": "Kosciusko",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.587576, 33.057629",
                            "IataCode": "OSX",
                            "Airports": [
                                {
                                    "Id": "OSX",
                                    "Name": "Kosciusko",
                                    "CityId": "OSXA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.583333, 33.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OTGA",
                            "Name": "Worthington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.596398, 43.619964",
                            "IataCode": "OTG",
                            "Airports": [
                                {
                                    "Id": "OTG",
                                    "Name": "Worthington",
                                    "CityId": "OTGA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.583611, 43.652222"
                                }
                            ]
                        },
                        {
                            "Id": "DJNA",
                            "Name": "Delta Junction",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.73222, 64.037778",
                            "IataCode": "DJN",
                            "Airports": [
                                {
                                    "Id": "DJN",
                                    "Name": "Delta Junction",
                                    "CityId": "DJNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.71722, 64.05028"
                                }
                            ]
                        },
                        {
                            "Id": "PCAA",
                            "Name": "Portage Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.7175, 58.906111",
                            "IataCode": "PCA",
                            "Airports": [
                                {
                                    "Id": "PCA",
                                    "Name": "Portage Creek",
                                    "CityId": "PCAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.70194, 58.901389"
                                }
                            ]
                        },
                        {
                            "Id": "PGRA",
                            "Name": "Paragould",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.497329, 36.058402",
                            "IataCode": "PGR",
                            "Airports": [
                                {
                                    "Id": "PGR",
                                    "Name": "Paragould",
                                    "CityId": "PGRA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.5, 36.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PKFA",
                            "Name": "Park Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.441553, 45.934398",
                            "IataCode": "PKF",
                            "Airports": [
                                {
                                    "Id": "PKF",
                                    "Name": "Park Falls",
                                    "CityId": "PKFA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.533333, 45.933333"
                                }
                            ]
                        },
                        {
                            "Id": "PMBA",
                            "Name": "Pembina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-97.243676, 48.966377",
                            "IataCode": "PMB",
                            "Airports": [
                                {
                                    "Id": "PMB",
                                    "Name": "Pembina",
                                    "CityId": "PMBA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-98.083333, 49"
                                }
                            ]
                        },
                        {
                            "Id": "POYA",
                            "Name": "Powell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-108.75735, 44.753841",
                            "IataCode": "POY",
                            "Airports": [
                                {
                                    "Id": "POY",
                                    "Name": "Powell",
                                    "CityId": "POYA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-108.76667, 44.75"
                                }
                            ]
                        },
                        {
                            "Id": "PPAA",
                            "Name": "Pampa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.95987, 35.536156",
                            "IataCode": "PPA",
                            "Airports": [
                                {
                                    "Id": "PPA",
                                    "Name": "Pampa",
                                    "CityId": "PPAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.96667, 35.533333"
                                }
                            ]
                        },
                        {
                            "Id": "PTBA",
                            "Name": "Petersburg",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.401927, 37.227928",
                            "IataCode": "PTB",
                            "Airports": [
                                {
                                    "Id": "PTB",
                                    "Name": "Petersburg Municipal",
                                    "CityId": "PTBA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.4, 37.216667"
                                },
                                {
                                    "Id": "FLE",
                                    "Name": "Petersburg Fort Lee AAF",
                                    "CityId": "PTBA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.4, 37.216667"
                                }
                            ]
                        },
                        {
                            "Id": "PWDA",
                            "Name": "Plentywood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-104.56246, 48.77475",
                            "IataCode": "PWD",
                            "Airports": [
                                {
                                    "Id": "PWD",
                                    "Name": "Plentywood",
                                    "CityId": "PWDA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-104.56667, 48.783333"
                                }
                            ]
                        },
                        {
                            "Id": "PXLA",
                            "Name": "Polacca",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.38152, 35.836672",
                            "IataCode": "PXL",
                            "Airports": [
                                {
                                    "Id": "PXL",
                                    "Name": "Polacca",
                                    "CityId": "PXLA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.33333, 35.8"
                                }
                            ]
                        },
                        {
                            "Id": "RFGA",
                            "Name": "Refugio",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.27527, 28.305284",
                            "IataCode": "RFG",
                            "Airports": [
                                {
                                    "Id": "RFG",
                                    "Name": "Refugio Rooke Field",
                                    "CityId": "RFGA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.35, 28.333333"
                                },
                                {
                                    "Id": "VDU",
                                    "Name": "Refugio Tom O'Connor Oilfield",
                                    "CityId": "RFGA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.283333, 28.3"
                                }
                            ]
                        },
                        {
                            "Id": "RIDA",
                            "Name": "Richmond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-84.890238, 39.828937",
                            "IataCode": "RID",
                            "Airports": [
                                {
                                    "Id": "RID",
                                    "Name": "Richmond",
                                    "CityId": "RIDA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-84.9, 39.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RIFA",
                            "Name": "Richfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.08409, 38.772467",
                            "IataCode": "RIF",
                            "Airports": [
                                {
                                    "Id": "RIF",
                                    "Name": "Richfield",
                                    "CityId": "RIFA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.09444, 38.741111"
                                }
                            ]
                        },
                        {
                            "Id": "RKSA",
                            "Name": "Rock Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-109.2029, 41.587464",
                            "IataCode": "RKS",
                            "Airports": [
                                {
                                    "Id": "RKS",
                                    "Name": "Rock Springs",
                                    "CityId": "RKSA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-109.06574, 41.594648"
                                }
                            ]
                        },
                        {
                            "Id": "RLAA",
                            "Name": "Rolla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-91.771269, 37.95143",
                            "IataCode": "RLA",
                            "Airports": [
                                {
                                    "Id": "RLA",
                                    "Name": "Rolla",
                                    "CityId": "RLAA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-91.81683, 37.93615"
                                }
                            ]
                        },
                        {
                            "Id": "FIDA",
                            "Name": "Fishers Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-72.023963, 41.257043",
                            "IataCode": "FID",
                            "Airports": [
                                {
                                    "Id": "FID",
                                    "Name": "Fishers Island",
                                    "CityId": "FIDA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-72.0298, 41.253355"
                                }
                            ]
                        },
                        {
                            "Id": "RRLA",
                            "Name": "Merrill",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.683459, 45.180522",
                            "IataCode": "RRL",
                            "Airports": [
                                {
                                    "Id": "RRL",
                                    "Name": "Merrill",
                                    "CityId": "RRLA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.683333, 45.183333"
                                }
                            ]
                        },
                        {
                            "Id": "RSLA",
                            "Name": "Russell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-98.859806, 38.895289",
                            "IataCode": "RSL",
                            "Airports": [
                                {
                                    "Id": "RSL",
                                    "Name": "Russell",
                                    "CityId": "RSLA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-98.85, 38.883333"
                                }
                            ]
                        },
                        {
                            "Id": "RWFA",
                            "Name": "Redwood Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.116942, 44.539404",
                            "IataCode": "RWF",
                            "Airports": [
                                {
                                    "Id": "RWF",
                                    "Name": "Redwood Falls",
                                    "CityId": "RWFA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.1, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "RZZA",
                            "Name": "Roanoke Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.654146, 36.461539",
                            "IataCode": "RZZ",
                            "Airports": [
                                {
                                    "Id": "RZZ",
                                    "Name": "Roanoke Rapids",
                                    "CityId": "RZZA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.666667, 36.466667"
                                }
                            ]
                        },
                        {
                            "Id": "SBXA",
                            "Name": "Shelby",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-111.85697, 48.505263",
                            "IataCode": "SBX",
                            "Airports": [
                                {
                                    "Id": "SBX",
                                    "Name": "Shelby",
                                    "CityId": "SBXA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.85, 48.5"
                                }
                            ]
                        },
                        {
                            "Id": "SEGA",
                            "Name": "Selinsgrove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-76.862193, 40.798974",
                            "IataCode": "SEG",
                            "Airports": [
                                {
                                    "Id": "SEG",
                                    "Name": "Selinsgrove",
                                    "CityId": "SEGA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.860695, 40.818324"
                                }
                            ]
                        },
                        {
                            "Id": "SEPA",
                            "Name": "Stephenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.202263, 32.220696",
                            "IataCode": "SEP",
                            "Airports": [
                                {
                                    "Id": "SEP",
                                    "Name": "Stephenville",
                                    "CityId": "SEPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.2, 32.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SLBA",
                            "Name": "Storm Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.209718, 42.641091",
                            "IataCode": "SLB",
                            "Airports": [
                                {
                                    "Id": "SLB",
                                    "Name": "Storm Lake",
                                    "CityId": "SLBA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.2, 42.633333"
                                }
                            ]
                        },
                        {
                            "Id": "SLOA",
                            "Name": "Salem",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.945616, 38.626993",
                            "IataCode": "SLO",
                            "Airports": [
                                {
                                    "Id": "SLO",
                                    "Name": "Salem",
                                    "CityId": "SLOA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.964109, 38.643355"
                                }
                            ]
                        },
                        {
                            "Id": "SRCA",
                            "Name": "Searcy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-91.736249, 35.250641",
                            "IataCode": "SRC",
                            "Airports": [
                                {
                                    "Id": "SRC",
                                    "Name": "Searcy",
                                    "CityId": "SRCA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.73708, 35.21175"
                                }
                            ]
                        },
                        {
                            "Id": "STKA",
                            "Name": "Sterling",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-103.20771, 40.625541",
                            "IataCode": "STK",
                            "Airports": [
                                {
                                    "Id": "STK",
                                    "Name": "Sterling",
                                    "CityId": "STKA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-103.26722, 40.616111"
                                }
                            ]
                        },
                        {
                            "Id": "SUDA",
                            "Name": "Stroud",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-96.658073, 35.74868",
                            "IataCode": "SUD",
                            "Airports": [
                                {
                                    "Id": "SUD",
                                    "Name": "Stroud",
                                    "CityId": "SUDA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.683333, 35.75"
                                }
                            ]
                        },
                        {
                            "Id": "SYVA",
                            "Name": "Sylvester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.835454, 31.530735",
                            "IataCode": "SYV",
                            "Airports": [
                                {
                                    "Id": "SYV",
                                    "Name": "Sylvester",
                                    "CityId": "SYVA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.833333, 31.516667"
                                }
                            ]
                        },
                        {
                            "Id": "TCTA",
                            "Name": "Takotna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.06417, 62.988611",
                            "IataCode": "TCT",
                            "Airports": [
                                {
                                    "Id": "TCT",
                                    "Name": "Takotna",
                                    "CityId": "TCTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.06556, 62.992778"
                                }
                            ]
                        },
                        {
                            "Id": "TLFA",
                            "Name": "Telida",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.28222, 63.383889",
                            "IataCode": "TLF",
                            "Airports": [
                                {
                                    "Id": "TLF",
                                    "Name": "Telida",
                                    "CityId": "TLFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.5, 63.666667"
                                }
                            ]
                        },
                        {
                            "Id": "TSGA",
                            "Name": "Tanacross",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-143.34639, 63.385278",
                            "IataCode": "TSG",
                            "Airports": [
                                {
                                    "Id": "TSG",
                                    "Name": "Tanacross",
                                    "CityId": "TSGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-143.5, 63.35"
                                }
                            ]
                        },
                        {
                            "Id": "TWEA",
                            "Name": "Taylor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.84861, 65.727222",
                            "IataCode": "TWE",
                            "Airports": [
                                {
                                    "Id": "TWE",
                                    "Name": "Taylor",
                                    "CityId": "TWEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.85, 65.733333"
                                }
                            ]
                        },
                        {
                            "Id": "UCEA",
                            "Name": "Eunice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.417632, 30.494367",
                            "IataCode": "UCE",
                            "Airports": [
                                {
                                    "Id": "UCE",
                                    "Name": "Eunice",
                                    "CityId": "UCEA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.416667, 30.5"
                                }
                            ]
                        },
                        {
                            "Id": "UMTA",
                            "Name": "Umiat",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.14417, 69.366944",
                            "IataCode": "UMT",
                            "Airports": [
                                {
                                    "Id": "UMT",
                                    "Name": "Umiat",
                                    "CityId": "UMTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.33333, 69.416667"
                                }
                            ]
                        },
                        {
                            "Id": "UWAA",
                            "Name": "Ware",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.239802, 42.259815",
                            "IataCode": "UWA",
                            "Airports": [
                                {
                                    "Id": "UWA",
                                    "Name": "Ware",
                                    "CityId": "UWAA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.233333, 42.266667"
                                }
                            ]
                        },
                        {
                            "Id": "VKSA",
                            "Name": "Vicksburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.877882, 32.352646",
                            "IataCode": "VKS",
                            "Airports": [
                                {
                                    "Id": "VKS",
                                    "Name": "Vicksburg",
                                    "CityId": "VKSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.92844, 32.23932"
                                }
                            ]
                        },
                        {
                            "Id": "VLAA",
                            "Name": "Vandalia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.093678, 38.960601",
                            "IataCode": "VLA",
                            "Airports": [
                                {
                                    "Id": "VLA",
                                    "Name": "Vandalia",
                                    "CityId": "VLAA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.1, 38.966667"
                                }
                            ]
                        },
                        {
                            "Id": "WEAA",
                            "Name": "Weatherford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.797255, 32.759295",
                            "IataCode": "WEA",
                            "Airports": [
                                {
                                    "Id": "WEA",
                                    "Name": "Weatherford",
                                    "CityId": "WEAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.8, 32.766667"
                                }
                            ]
                        },
                        {
                            "Id": "LICA",
                            "Name": "Limon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-103.69217, 39.263876",
                            "IataCode": "LIC",
                            "Airports": [
                                {
                                    "Id": "LIC",
                                    "Name": "Limon",
                                    "CityId": "LICA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-103.68333, 39.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MJQA",
                            "Name": "Jackson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.988602, 43.620792",
                            "IataCode": "MJQ",
                            "Airports": [
                                {
                                    "Id": "MJQ",
                                    "Name": "Jackson",
                                    "CityId": "MJQA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.016667, 43.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MMSA",
                            "Name": "Marks",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.272038, 34.2565",
                            "IataCode": "MMS",
                            "Airports": [
                                {
                                    "Id": "MMS",
                                    "Name": "Marks",
                                    "CityId": "MMSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.266667, 34.266667"
                                }
                            ]
                        },
                        {
                            "Id": "MNMA",
                            "Name": "Menominee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-87.614274, 45.107763",
                            "IataCode": "MNM",
                            "Airports": [
                                {
                                    "Id": "MNM",
                                    "Name": "Menominee",
                                    "CityId": "MNMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-87.636111, 45.121667"
                                }
                            ]
                        },
                        {
                            "Id": "MNNA",
                            "Name": "Marion",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-83.128524, 40.58867",
                            "IataCode": "MNN",
                            "Airports": [
                                {
                                    "Id": "MNN",
                                    "Name": "Marion",
                                    "CityId": "MNNA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.133333, 40.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MXAA",
                            "Name": "Manila",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.167039, 35.880073",
                            "IataCode": "MXA",
                            "Airports": [
                                {
                                    "Id": "MXA",
                                    "Name": "Manila Municipal",
                                    "CityId": "MXAA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.183333, 35.883333"
                                }
                            ]
                        },
                        {
                            "Id": "PHPA",
                            "Name": "Philip",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-101.66514, 44.039433",
                            "IataCode": "PHP",
                            "Airports": [
                                {
                                    "Id": "PHP",
                                    "Name": "Philip",
                                    "CityId": "PHPA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-101.59903, 44.04804"
                                }
                            ]
                        },
                        {
                            "Id": "PRWA",
                            "Name": "Prentice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.286539, 45.546075",
                            "IataCode": "PRW",
                            "Airports": [
                                {
                                    "Id": "PRW",
                                    "Name": "Prentice",
                                    "CityId": "PRWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.283333, 45.55"
                                }
                            ]
                        },
                        {
                            "Id": "PSNA",
                            "Name": "Palestine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.630789, 31.762115",
                            "IataCode": "PSN",
                            "Airports": [
                                {
                                    "Id": "PSN",
                                    "Name": "Palestine",
                                    "CityId": "PSNA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.716667, 31.783333"
                                }
                            ]
                        },
                        {
                            "Id": "TORA",
                            "Name": "Torrington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.18439, 42.062465",
                            "IataCode": "TOR",
                            "Airports": [
                                {
                                    "Id": "TOR",
                                    "Name": "Torrington",
                                    "CityId": "TORA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.18333, 42.066667"
                                }
                            ]
                        },
                        {
                            "Id": "WSMA",
                            "Name": "Wiseman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.1075, 67.41",
                            "IataCode": "WSM",
                            "Airports": [
                                {
                                    "Id": "WSM",
                                    "Name": "Wiseman",
                                    "CityId": "WSMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.11667, 67.401667"
                                }
                            ]
                        },
                        {
                            "Id": "PIPA",
                            "Name": "Pilot Point",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.57917, 57.564167",
                            "IataCode": "PIP",
                            "Airports": [
                                {
                                    "Id": "PIP",
                                    "Name": "Pilot Point Arpt",
                                    "CityId": "PIPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.56083, 57.563611"
                                },
                                {
                                    "Id": "UGB",
                                    "Name": "Pilot Point Ugashik Bay",
                                    "CityId": "PIPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.74264, 57.424992"
                                }
                            ]
                        },
                        {
                            "Id": "UGSA",
                            "Name": "Ugashik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.3975, 57.513056",
                            "IataCode": "UGS",
                            "Airports": [
                                {
                                    "Id": "UGS",
                                    "Name": "Ugashik",
                                    "CityId": "UGSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.41667, 57.533333"
                                }
                            ]
                        },
                        {
                            "Id": "BCTA",
                            "Name": "Boca Raton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.083098, 26.358688",
                            "IataCode": "BCT",
                            "Airports": [
                                {
                                    "Id": "BCT",
                                    "Name": "Boca Raton",
                                    "CityId": "BCTA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.10699, 26.37897"
                                }
                            ]
                        },
                        {
                            "Id": "BDEA",
                            "Name": "Baudette",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.59993, 48.712474",
                            "IataCode": "BDE",
                            "Airports": [
                                {
                                    "Id": "BDE",
                                    "Name": "Baudette",
                                    "CityId": "BDEA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.606944, 48.722778"
                                }
                            ]
                        },
                        {
                            "Id": "BDGA",
                            "Name": "Blanding",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-109.47818, 37.624165",
                            "IataCode": "BDG",
                            "Airports": [
                                {
                                    "Id": "BDG",
                                    "Name": "Blanding",
                                    "CityId": "BDGA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-109.48329, 37.58303"
                                }
                            ]
                        },
                        {
                            "Id": "BDRA",
                            "Name": "Bridgeport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-73.204835, 41.167043",
                            "IataCode": "BDR",
                            "Airports": [
                                {
                                    "Id": "BDR",
                                    "Name": "Bridgeport",
                                    "CityId": "BDRA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-73.124444, 41.164167"
                                }
                            ]
                        },
                        {
                            "Id": "ICTA",
                            "Name": "Wichita",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.337545, 37.692236",
                            "IataCode": "ICT",
                            "Airports": [
                                {
                                    "Id": "CEA",
                                    "Name": "Wichita Cessna Aircraft Field",
                                    "CityId": "ICTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.333333, 37.7"
                                },
                                {
                                    "Id": "IAB",
                                    "Name": "Wichita Mcconnell AFB",
                                    "CityId": "ICTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.276167, 37.681139"
                                },
                                {
                                    "Id": "BEC",
                                    "Name": "Wichita Beech",
                                    "CityId": "ICTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.22115, 37.6864"
                                },
                                {
                                    "Id": "ICT",
                                    "Name": "Wichita Mid-Continent",
                                    "CityId": "ICTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.428333, 37.6525"
                                }
                            ]
                        },
                        {
                            "Id": "BEDA",
                            "Name": "Bedford / Hanscom",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.276169, 42.49065",
                            "IataCode": "BED",
                            "Airports": [
                                {
                                    "Id": "BED",
                                    "Name": "Bedford / Hanscom",
                                    "CityId": "BEDA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.29, 42.470833"
                                }
                            ]
                        },
                        {
                            "Id": "BEHA",
                            "Name": "Benton Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.454189, 42.116706",
                            "IataCode": "BEH",
                            "Airports": [
                                {
                                    "Id": "BEH",
                                    "Name": "Benton Harbor",
                                    "CityId": "BEHA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.428333, 42.127778"
                                }
                            ]
                        },
                        {
                            "Id": "BFDA",
                            "Name": "Bradford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.643916, 41.955896",
                            "IataCode": "BFD",
                            "Airports": [
                                {
                                    "Id": "BFD",
                                    "Name": "Bradford",
                                    "CityId": "BFDA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.639444, 41.802222"
                                }
                            ]
                        },
                        {
                            "Id": "BFFA",
                            "Name": "Scottsbluff",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-103.66717, 41.866634",
                            "IataCode": "BFF",
                            "Airports": [
                                {
                                    "Id": "BFF",
                                    "Name": "Scottsbluff",
                                    "CityId": "BFFA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-103.59592, 41.873907"
                                }
                            ]
                        },
                        {
                            "Id": "BFLA",
                            "Name": "Bakersfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.01871, 35.373292",
                            "IataCode": "BFL",
                            "Airports": [
                                {
                                    "Id": "BFL",
                                    "Name": "Bakersfield",
                                    "CityId": "BFLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.05222, 35.43"
                                }
                            ]
                        },
                        {
                            "Id": "MOBA",
                            "Name": "Mobile",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-88.043054, 30.694357",
                            "IataCode": "MOB",
                            "Airports": [
                                {
                                    "Id": "MOB",
                                    "Name": "Mobile Municipal Arpt",
                                    "CityId": "MOBA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-88.240833, 30.688333"
                                },
                                {
                                    "Id": "BFM",
                                    "Name": "Mobile Mob Aerospace",
                                    "CityId": "MOBA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-88.233333, 30.683333"
                                }
                            ]
                        },
                        {
                            "Id": "BFPA",
                            "Name": "Beaver Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-80.31923, 40.75201",
                            "IataCode": "BFP",
                            "Airports": [
                                {
                                    "Id": "BFP",
                                    "Name": "Beaver Falls",
                                    "CityId": "BFPA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.392284, 40.773782"
                                }
                            ]
                        },
                        {
                            "Id": "BFRA",
                            "Name": "Bedford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.487215, 38.861162",
                            "IataCode": "BFR",
                            "Airports": [
                                {
                                    "Id": "BFR",
                                    "Name": "Bedford",
                                    "CityId": "BFRA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.483333, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "BFTA",
                            "Name": "Beaufort",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.669829, 32.431581",
                            "IataCode": "BFT",
                            "Airports": [
                                {
                                    "Id": "BFT",
                                    "Name": "Beaufort",
                                    "CityId": "BFTA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.635, 32.410833"
                                }
                            ]
                        },
                        {
                            "Id": "BGDA",
                            "Name": "Borger",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.39739, 35.66782",
                            "IataCode": "BGD",
                            "Airports": [
                                {
                                    "Id": "BGD",
                                    "Name": "Borger",
                                    "CityId": "BGDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.4, 35.65"
                                }
                            ]
                        },
                        {
                            "Id": "BGEA",
                            "Name": "Bainbridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.57547, 30.903799",
                            "IataCode": "BGE",
                            "Airports": [
                                {
                                    "Id": "BGE",
                                    "Name": "Bainbridge",
                                    "CityId": "BGEA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.63742, 30.9713"
                                }
                            ]
                        },
                        {
                            "Id": "BGMA",
                            "Name": "Binghamton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.917974, 42.098687",
                            "IataCode": "BGM",
                            "Airports": [
                                {
                                    "Id": "BGM",
                                    "Name": "Binghamton",
                                    "CityId": "BGMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.969167, 42.201389"
                                }
                            ]
                        },
                        {
                            "Id": "BGRA",
                            "Name": "Bangor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.777814, 44.801182",
                            "IataCode": "BGR",
                            "Airports": [
                                {
                                    "Id": "BGR",
                                    "Name": "Bangor",
                                    "CityId": "BGRA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.827778, 44.807778"
                                }
                            ]
                        },
                        {
                            "Id": "BGTA",
                            "Name": "Bagdad",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-113.20464, 34.58113",
                            "IataCode": "BGT",
                            "Airports": [
                                {
                                    "Id": "BGT",
                                    "Name": "Bagdad",
                                    "CityId": "BGTA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-113.17028, 34.595833"
                                }
                            ]
                        },
                        {
                            "Id": "BHBA",
                            "Name": "Bar Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.203902, 44.387579",
                            "IataCode": "BHB",
                            "Airports": [
                                {
                                    "Id": "BHB",
                                    "Name": "Bar Harbor",
                                    "CityId": "BHBA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.361667, 44.448889"
                                }
                            ]
                        },
                        {
                            "Id": "BHMA",
                            "Name": "Birmingham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.80249, 33.520661",
                            "IataCode": "BHM",
                            "Airports": [
                                {
                                    "Id": "BHM",
                                    "Name": "Birmingham",
                                    "CityId": "BHMA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.755556, 33.563056"
                                }
                            ]
                        },
                        {
                            "Id": "BIDA",
                            "Name": "Block Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "RHODE",
                            "Location": "-71.58022, 41.17374",
                            "IataCode": "BID",
                            "Airports": [
                                {
                                    "Id": "BID",
                                    "Name": "Block Island",
                                    "CityId": "BIDA",
                                    "CountryId": "US",
                                    "RegionId": "RHODE",
                                    "Location": "-71.579167, 41.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BILA",
                            "Name": "Billings",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-108.50069, 45.783286",
                            "IataCode": "BIL",
                            "Airports": [
                                {
                                    "Id": "BIL",
                                    "Name": "Billings",
                                    "CityId": "BILA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-108.54801, 45.810376"
                                }
                            ]
                        },
                        {
                            "Id": "BISA",
                            "Name": "Bismarck",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-100.78374, 46.808327",
                            "IataCode": "BIS",
                            "Airports": [
                                {
                                    "Id": "BIS",
                                    "Name": "Bismarck",
                                    "CityId": "BISA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-100.74583, 46.772778"
                                }
                            ]
                        },
                        {
                            "Id": "BIXA",
                            "Name": "Biloxi",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.885308, 30.396032",
                            "IataCode": "BIX",
                            "Airports": [
                                {
                                    "Id": "BIX",
                                    "Name": "Biloxi",
                                    "CityId": "BIXA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.883333, 30.4"
                                }
                            ]
                        },
                        {
                            "Id": "BJIA",
                            "Name": "Bemidji",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.880277, 47.473563",
                            "IataCode": "BJI",
                            "Airports": [
                                {
                                    "Id": "BJI",
                                    "Name": "Bemidji",
                                    "CityId": "BJIA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.934722, 47.509722"
                                }
                            ]
                        },
                        {
                            "Id": "BJJA",
                            "Name": "Wooster",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.935143, 40.805056",
                            "IataCode": "BJJ",
                            "Airports": [
                                {
                                    "Id": "BJJ",
                                    "Name": "Wooster",
                                    "CityId": "BJJA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.889, 40.87718"
                                }
                            ]
                        },
                        {
                            "Id": "BKCA",
                            "Name": "Buckland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.12306, 65.979722",
                            "IataCode": "BKC",
                            "Airports": [
                                {
                                    "Id": "BKC",
                                    "Name": "Buckland",
                                    "CityId": "BKCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.12667, 65.978889"
                                }
                            ]
                        },
                        {
                            "Id": "PLKA",
                            "Name": "Branson / Point Lookout",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.263199, 36.649225",
                            "IataCode": "BKG",
                            "Airports": [
                                {
                                    "Id": "BKG",
                                    "Name": "Branson",
                                    "CityId": "PLKA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.20055, 36.53208"
                                },
                                {
                                    "Id": "PLK",
                                    "Name": "Branson / Point Lookout",
                                    "CityId": "PLKA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.216667, 36.65"
                                }
                            ]
                        },
                        {
                            "Id": "BKHA",
                            "Name": "Kekaha",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-159.715, 21.970833",
                            "IataCode": "BKH",
                            "Airports": [
                                {
                                    "Id": "BKH",
                                    "Name": "Kekaha",
                                    "CityId": "BKHA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-159.785, 22.02283"
                                }
                            ]
                        },
                        {
                            "Id": "CLEA",
                            "Name": "Cleveland",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.695409, 41.499497",
                            "IataCode": "CLE",
                            "Airports": [
                                {
                                    "Id": "CGF",
                                    "Name": "Cleveland Cuyahoga County",
                                    "CityId": "CLEA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.483889, 41.5525"
                                },
                                {
                                    "Id": "BKL",
                                    "Name": "Cleveland Burke Lakefront",
                                    "CityId": "CLEA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.683333, 41.516944"
                                },
                                {
                                    "Id": "CLE",
                                    "Name": "Cleveland Hopkins International",
                                    "CityId": "CLEA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.848056, 41.411944"
                                }
                            ]
                        },
                        {
                            "Id": "BKTA",
                            "Name": "Blackstone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.997226, 37.080428",
                            "IataCode": "BKT",
                            "Airports": [
                                {
                                    "Id": "BKT",
                                    "Name": "Blackstone",
                                    "CityId": "BKTA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.96278, 37.07459"
                                }
                            ]
                        },
                        {
                            "Id": "BKWA",
                            "Name": "Beckley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-81.188156, 37.77817",
                            "IataCode": "BKW",
                            "Airports": [
                                {
                                    "Id": "BKW",
                                    "Name": "Beckley",
                                    "CityId": "BKWA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-81.124167, 37.781944"
                                }
                            ]
                        },
                        {
                            "Id": "BKXA",
                            "Name": "Brookings",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-96.798388, 44.311357",
                            "IataCode": "BKX",
                            "Airports": [
                                {
                                    "Id": "BKX",
                                    "Name": "Brookings",
                                    "CityId": "BKXA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-96.811111, 44.303333"
                                }
                            ]
                        },
                        {
                            "Id": "BLDA",
                            "Name": "Boulder City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-114.83249, 35.978591",
                            "IataCode": "BLD",
                            "Airports": [
                                {
                                    "Id": "BLD",
                                    "Name": "Boulder City",
                                    "CityId": "BLDA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-114.85935, 35.946668"
                                }
                            ]
                        },
                        {
                            "Id": "BLFA",
                            "Name": "Bluefield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-81.222319, 37.269839",
                            "IataCode": "BLF",
                            "Airports": [
                                {
                                    "Id": "BLF",
                                    "Name": "Bluefield",
                                    "CityId": "BLFA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-81.208056, 37.295833"
                                }
                            ]
                        },
                        {
                            "Id": "BLHA",
                            "Name": "Blythe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-114.59635, 33.610302",
                            "IataCode": "BLH",
                            "Airports": [
                                {
                                    "Id": "BLH",
                                    "Name": "Blythe",
                                    "CityId": "BLHA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-114.71889, 33.620833"
                                }
                            ]
                        },
                        {
                            "Id": "BLIA",
                            "Name": "Bellingham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.48823, 48.759553",
                            "IataCode": "BLI",
                            "Airports": [
                                {
                                    "Id": "BLI",
                                    "Name": "Bellingham",
                                    "CityId": "BLIA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.53472, 48.794444"
                                }
                            ]
                        },
                        {
                            "Id": "BLVA",
                            "Name": "Belleville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.983993, 38.52005",
                            "IataCode": "BLV",
                            "Airports": [
                                {
                                    "Id": "BLV",
                                    "Name": "Belleville",
                                    "CityId": "BLVA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.853058, 38.542594"
                                }
                            ]
                        },
                        {
                            "Id": "BMCA",
                            "Name": "Brigham City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.0155, 41.510213",
                            "IataCode": "BMC",
                            "Airports": [
                                {
                                    "Id": "BMC",
                                    "Name": "Brigham City",
                                    "CityId": "BMCA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.06222, 41.549167"
                                }
                            ]
                        },
                        {
                            "Id": "BMGA",
                            "Name": "Bloomington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.526386, 39.165325",
                            "IataCode": "BMG",
                            "Airports": [
                                {
                                    "Id": "BMG",
                                    "Name": "Bloomington",
                                    "CityId": "BMGA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.614722, 39.14"
                                }
                            ]
                        },
                        {
                            "Id": "BMLA",
                            "Name": "Berlin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.185077, 44.46867",
                            "IataCode": "BML",
                            "Airports": [
                                {
                                    "Id": "BML",
                                    "Name": "Berlin",
                                    "CityId": "BMLA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.176389, 44.574722"
                                }
                            ]
                        },
                        {
                            "Id": "BPTA",
                            "Name": "Beaumont",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.101846, 30.086046",
                            "IataCode": "BPT",
                            "Airports": [
                                {
                                    "Id": "BPT",
                                    "Name": "Beaumont Jefferson County",
                                    "CityId": "BPTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.02, 29.950833"
                                }
                            ]
                        },
                        {
                            "Id": "BNAA",
                            "Name": "Nashville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-86.784443, 36.16589",
                            "IataCode": "BNA",
                            "Airports": [
                                {
                                    "Id": "BNA",
                                    "Name": "Nashville",
                                    "CityId": "BNAA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.681944, 36.126667"
                                }
                            ]
                        },
                        {
                            "Id": "BNFA",
                            "Name": "Baranof",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.83306, 57.089444",
                            "IataCode": "BNF",
                            "Airports": [
                                {
                                    "Id": "BNF",
                                    "Name": "Baranof",
                                    "CityId": "BNFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135, 57"
                                }
                            ]
                        },
                        {
                            "Id": "BNGA",
                            "Name": "Banning",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.87641, 33.925571",
                            "IataCode": "BNG",
                            "Airports": [
                                {
                                    "Id": "BNG",
                                    "Name": "Banning",
                                    "CityId": "BNGA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.86667, 33.933333"
                                }
                            ]
                        },
                        {
                            "Id": "BNLA",
                            "Name": "Barnwell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-81.358718, 33.244873",
                            "IataCode": "BNL",
                            "Airports": [
                                {
                                    "Id": "BNL",
                                    "Name": "Barnwell",
                                    "CityId": "BNLA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.383333, 33.25"
                                }
                            ]
                        },
                        {
                            "Id": "OTSA",
                            "Name": "Anacortes",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.61267, 48.512604",
                            "IataCode": "OTS",
                            "Airports": [
                                {
                                    "Id": "OTS",
                                    "Name": "Anacortes",
                                    "CityId": "OTSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.66111, 48.499167"
                                }
                            ]
                        },
                        {
                            "Id": "BNOA",
                            "Name": "Burns",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-119.0541, 43.586261",
                            "IataCode": "BNO",
                            "Airports": [
                                {
                                    "Id": "BNO",
                                    "Name": "Burns",
                                    "CityId": "BNOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-118.95, 43.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BOIA",
                            "Name": "Boise",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-116.20345, 43.6135",
                            "IataCode": "BOI",
                            "Airports": [
                                {
                                    "Id": "BOI",
                                    "Name": "Boise",
                                    "CityId": "BOIA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-116.22643, 43.562481"
                                }
                            ]
                        },
                        {
                            "Id": "BOKA",
                            "Name": "Brookings",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-124.28398, 42.052611",
                            "IataCode": "BOK",
                            "Airports": [
                                {
                                    "Id": "BOK",
                                    "Name": "Brookings",
                                    "CityId": "BOKA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-124.29009, 42.07456"
                                }
                            ]
                        },
                        {
                            "Id": "BOSA",
                            "Name": "Boston",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.078925, 42.331123",
                            "IataCode": "BOS",
                            "Airports": [
                                {
                                    "Id": "JBC",
                                    "Name": "Boston City Heliport",
                                    "CityId": "BOSA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.049444, 42.343889"
                                },
                                {
                                    "Id": "BOS",
                                    "Name": "Boston Logan International",
                                    "CityId": "BOSA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.005278, 42.365"
                                }
                            ]
                        },
                        {
                            "Id": "BOWA",
                            "Name": "Bartow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.843137, 27.896415",
                            "IataCode": "BOW",
                            "Airports": [
                                {
                                    "Id": "BOW",
                                    "Name": "Bartow",
                                    "CityId": "BOWA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.78314, 27.94364"
                                }
                            ]
                        },
                        {
                            "Id": "BPIA",
                            "Name": "Big Piney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.11433, 42.538275",
                            "IataCode": "BPI",
                            "Airports": [
                                {
                                    "Id": "BPI",
                                    "Name": "Big Piney-marbleton",
                                    "CityId": "BPIA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.11183, 42.58494"
                                }
                            ]
                        },
                        {
                            "Id": "SSIA",
                            "Name": "Brunswick",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.491489, 31.149953",
                            "IataCode": "SSI",
                            "Airports": [
                                {
                                    "Id": "SSI",
                                    "Name": "Brunswick Mckinnon",
                                    "CityId": "SSIA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.38769, 31.15646"
                                },
                                {
                                    "Id": "BQK",
                                    "Name": "Brunswick Glynco Jetport",
                                    "CityId": "SSIA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.467056, 31.253607"
                                }
                            ]
                        },
                        {
                            "Id": "BRDA",
                            "Name": "Brainerd",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.200829, 46.358022",
                            "IataCode": "BRD",
                            "Airports": [
                                {
                                    "Id": "BRD",
                                    "Name": "Brainerd",
                                    "CityId": "BRDA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.1375, 46.397222"
                                }
                            ]
                        },
                        {
                            "Id": "BRLA",
                            "Name": "Burlington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.112923, 40.807539",
                            "IataCode": "BRL",
                            "Airports": [
                                {
                                    "Id": "BRL",
                                    "Name": "Burlington",
                                    "CityId": "BRLA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.123333, 40.786111"
                                }
                            ]
                        },
                        {
                            "Id": "BRWA",
                            "Name": "Barrow",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.78861, 71.290556",
                            "IataCode": "BRW",
                            "Airports": [
                                {
                                    "Id": "BRW",
                                    "Name": "Barrow Wiley Post / W.Rogers M",
                                    "CityId": "BRWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.76944, 71.283333"
                                },
                                {
                                    "Id": "PBA",
                                    "Name": "Point Barrow",
                                    "CityId": "BRWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.76667, 71.283333"
                                }
                            ]
                        },
                        {
                            "Id": "BRYA",
                            "Name": "Bardstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-85.466903, 37.809228",
                            "IataCode": "BRY",
                            "Airports": [
                                {
                                    "Id": "BRY",
                                    "Name": "Bardstown",
                                    "CityId": "BRYA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.49885, 37.81562"
                                }
                            ]
                        },
                        {
                            "Id": "BTFA",
                            "Name": "Bountiful",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.88077, 40.889389",
                            "IataCode": "BTF",
                            "Airports": [
                                {
                                    "Id": "BTF",
                                    "Name": "Bountiful",
                                    "CityId": "BTFA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.88333, 40.883333"
                                }
                            ]
                        },
                        {
                            "Id": "BTLA",
                            "Name": "Battle Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.179714, 42.321152",
                            "IataCode": "BTL",
                            "Airports": [
                                {
                                    "Id": "BTL",
                                    "Name": "Battle Creek",
                                    "CityId": "BTLA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.25083, 42.30726"
                                }
                            ]
                        },
                        {
                            "Id": "BTMA",
                            "Name": "Butte",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-112.53474, 46.003815",
                            "IataCode": "BTM",
                            "Airports": [
                                {
                                    "Id": "BTM",
                                    "Name": "Butte",
                                    "CityId": "BTMA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-112.49389, 45.951111"
                                }
                            ]
                        },
                        {
                            "Id": "BTNA",
                            "Name": "Bennettsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.684781, 34.61738",
                            "IataCode": "BTN",
                            "Airports": [
                                {
                                    "Id": "BTN",
                                    "Name": "Bennettsville",
                                    "CityId": "BTNA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.683333, 34.616667"
                                }
                            ]
                        },
                        {
                            "Id": "BTPA",
                            "Name": "Butler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.895333, 40.861175",
                            "IataCode": "BTP",
                            "Airports": [
                                {
                                    "Id": "BTP",
                                    "Name": "Butler",
                                    "CityId": "BTPA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.916667, 40.85"
                                }
                            ]
                        },
                        {
                            "Id": "BTRA",
                            "Name": "Baton Rouge",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-91.154551, 30.450746",
                            "IataCode": "BTR",
                            "Airports": [
                                {
                                    "Id": "EBR",
                                    "Name": "Baton Rouge Downtown",
                                    "CityId": "BTRA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-91.183333, 30.45"
                                },
                                {
                                    "Id": "BTR",
                                    "Name": "Baton Rouge Ryan",
                                    "CityId": "BTRA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-91.150556, 30.531667"
                                }
                            ]
                        },
                        {
                            "Id": "BTTA",
                            "Name": "Bettles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.51611, 66.918889",
                            "IataCode": "BTT",
                            "Airports": [
                                {
                                    "Id": "BTT",
                                    "Name": "Bettles",
                                    "CityId": "BTTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.52639, 66.915278"
                                }
                            ]
                        },
                        {
                            "Id": "BTVA",
                            "Name": "Burlington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-73.212072, 44.475882",
                            "IataCode": "BTV",
                            "Airports": [
                                {
                                    "Id": "BTV",
                                    "Name": "Burlington",
                                    "CityId": "BTVA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-73.153056, 44.472778"
                                }
                            ]
                        },
                        {
                            "Id": "BTYA",
                            "Name": "Beatty",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-116.75923, 36.908557",
                            "IataCode": "BTY",
                            "Airports": [
                                {
                                    "Id": "BTY",
                                    "Name": "Beatty",
                                    "CityId": "BTYA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-116.78604, 36.867708"
                                }
                            ]
                        },
                        {
                            "Id": "BUFA",
                            "Name": "Buffalo",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-78.878369, 42.886449",
                            "IataCode": "BUF",
                            "Airports": [
                                {
                                    "Id": "ZFZ",
                                    "Name": "Buffalo Depew RR",
                                    "CityId": "BUFA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-78.7339, 42.9339"
                                },
                                {
                                    "Id": "BUF",
                                    "Name": "Buffalo Niagara",
                                    "CityId": "BUFA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-78.736389, 42.941111"
                                }
                            ]
                        },
                        {
                            "Id": "BURA",
                            "Name": "Burbank",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.30897, 34.180839",
                            "IataCode": "BUR",
                            "Airports": [
                                {
                                    "Id": "BUR",
                                    "Name": "Burbank",
                                    "CityId": "BURA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.35778, 34.2"
                                }
                            ]
                        },
                        {
                            "Id": "BVUA",
                            "Name": "Beluga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.02361, 61.179167",
                            "IataCode": "BVU",
                            "Airports": [
                                {
                                    "Id": "BVU",
                                    "Name": "Beluga",
                                    "CityId": "BVUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.03611, 61.169444"
                                }
                            ]
                        },
                        {
                            "Id": "BVXA",
                            "Name": "Batesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-91.640972, 35.769799",
                            "IataCode": "BVX",
                            "Airports": [
                                {
                                    "Id": "BVX",
                                    "Name": "Batesville",
                                    "CityId": "BVXA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.64736, 35.72591"
                                }
                            ]
                        },
                        {
                            "Id": "BVYA",
                            "Name": "Beverly",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.880049, 42.558428",
                            "IataCode": "BVY",
                            "Airports": [
                                {
                                    "Id": "BVY",
                                    "Name": "Beverly",
                                    "CityId": "BVYA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.91588, 42.58426"
                                }
                            ]
                        },
                        {
                            "Id": "BWCA",
                            "Name": "Brawley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.53027, 32.978657",
                            "IataCode": "BWC",
                            "Airports": [
                                {
                                    "Id": "BWC",
                                    "Name": "Brawley",
                                    "CityId": "BWCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.51749, 32.9931"
                                }
                            ]
                        },
                        {
                            "Id": "BWDA",
                            "Name": "Brownwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.991161, 31.70932",
                            "IataCode": "BWD",
                            "Airports": [
                                {
                                    "Id": "BWD",
                                    "Name": "Brownwood",
                                    "CityId": "BWDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.956667, 31.794722"
                                }
                            ]
                        },
                        {
                            "Id": "BWGA",
                            "Name": "Bowling Green",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-86.443602, 36.99032",
                            "IataCode": "BWG",
                            "Airports": [
                                {
                                    "Id": "BWG",
                                    "Name": "Bowling Green",
                                    "CityId": "BWGA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-86.422222, 36.962222"
                                }
                            ]
                        },
                        {
                            "Id": "BWIA",
                            "Name": "Baltimore",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.612189, 39.290387",
                            "IataCode": "BWI",
                            "Airports": [
                                {
                                    "Id": "GBO",
                                    "Name": "Baltimore Greenbelt T",
                                    "CityId": "BWIA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.75, 39.266667"
                                },
                                {
                                    "Id": "MTN",
                                    "Name": "Baltimore Glenn L Martin",
                                    "CityId": "BWIA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.411667, 39.321667"
                                }
                            ]
                        },
                        {
                            "Id": "BWMA",
                            "Name": "Bowman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-103.39491, 46.183062",
                            "IataCode": "BWM",
                            "Airports": [
                                {
                                    "Id": "BWM",
                                    "Name": "Bowman",
                                    "CityId": "BWMA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-103.4, 46.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BXAA",
                            "Name": "Bogalusa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-89.848686, 30.79102",
                            "IataCode": "BXA",
                            "Airports": [
                                {
                                    "Id": "BXA",
                                    "Name": "Bogalusa",
                                    "CityId": "BXAA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-89.883333, 30.933333"
                                }
                            ]
                        },
                        {
                            "Id": "BXKA",
                            "Name": "Buckeye",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.58378, 33.37032",
                            "IataCode": "BXK",
                            "Airports": [
                                {
                                    "Id": "BXK",
                                    "Name": "Buckeye",
                                    "CityId": "BXKA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.68611, 33.420556"
                                }
                            ]
                        },
                        {
                            "Id": "BXSA",
                            "Name": "Borrego Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.37501, 33.255872",
                            "IataCode": "BXS",
                            "Airports": [
                                {
                                    "Id": "BXS",
                                    "Name": "Borrego Springs",
                                    "CityId": "BXSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.3239, 33.25976"
                                }
                            ]
                        },
                        {
                            "Id": "BYIA",
                            "Name": "Burley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-113.79279, 42.535743",
                            "IataCode": "BYI",
                            "Airports": [
                                {
                                    "Id": "BYI",
                                    "Name": "Burley",
                                    "CityId": "BYIA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-113.77417, 42.541389"
                                }
                            ]
                        },
                        {
                            "Id": "BZNA",
                            "Name": "Bozeman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-111.03856, 45.679653",
                            "IataCode": "BZN",
                            "Airports": [
                                {
                                    "Id": "BZN",
                                    "Name": "Bozeman",
                                    "CityId": "BZNA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.15177, 45.77782"
                                }
                            ]
                        },
                        {
                            "Id": "CADA",
                            "Name": "Cadillac",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.401162, 44.251953",
                            "IataCode": "CAD",
                            "Airports": [
                                {
                                    "Id": "CAD",
                                    "Name": "Cadillac",
                                    "CityId": "CADA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.422222, 44.275833"
                                }
                            ]
                        },
                        {
                            "Id": "CAEA",
                            "Name": "Columbia",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-81.034814, 34.00071",
                            "IataCode": "CAE",
                            "Airports": [
                                {
                                    "Id": "MMT",
                                    "Name": "Columbia Mc Entire ANG Base",
                                    "CityId": "CAEA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.115556, 33.936667"
                                },
                                {
                                    "Id": "CUB",
                                    "Name": "Columbia Owens Field",
                                    "CityId": "CAEA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.99565, 33.97071"
                                },
                                {
                                    "Id": "CAE",
                                    "Name": "Columbia Metropolitan",
                                    "CityId": "CAEA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.115833, 33.936389"
                                }
                            ]
                        },
                        {
                            "Id": "CAOA",
                            "Name": "Clayton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-103.1841, 36.451693",
                            "IataCode": "CAO",
                            "Airports": [
                                {
                                    "Id": "CAO",
                                    "Name": "Clayton",
                                    "CityId": "CAOA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.15466, 36.44586"
                                }
                            ]
                        },
                        {
                            "Id": "CARB",
                            "Name": "Caribou",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.011971, 46.860598",
                            "IataCode": "CAR",
                            "Airports": [
                                {
                                    "Id": "CAR",
                                    "Name": "Caribou",
                                    "CityId": "CARB",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.022194, 46.874744"
                                }
                            ]
                        },
                        {
                            "Id": "CBEA",
                            "Name": "Cumberland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-78.762519, 39.652865",
                            "IataCode": "CBE",
                            "Airports": [
                                {
                                    "Id": "CBE",
                                    "Name": "Cumberland",
                                    "CityId": "CBEA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-78.766111, 39.614444"
                                }
                            ]
                        },
                        {
                            "Id": "CBFA",
                            "Name": "Council Bluffs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.861123, 41.261943",
                            "IataCode": "CBF",
                            "Airports": [
                                {
                                    "Id": "CBF",
                                    "Name": "Council Bluffs",
                                    "CityId": "CBFA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.76117, 41.259598"
                                }
                            ]
                        },
                        {
                            "Id": "CBKA",
                            "Name": "Colby",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-101.05238, 39.395837",
                            "IataCode": "CBK",
                            "Airports": [
                                {
                                    "Id": "CBK",
                                    "Name": "Colby",
                                    "CityId": "CBKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-101.05, 39.4"
                                }
                            ]
                        },
                        {
                            "Id": "UBSA",
                            "Name": "Columbus",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.427263, 33.495674",
                            "IataCode": "UBS",
                            "Airports": [
                                {
                                    "Id": "UBS",
                                    "Name": "Columbus Lowndes County",
                                    "CityId": "UBSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.383333, 33.466667"
                                },
                                {
                                    "Id": "CBM",
                                    "Name": "Columbus AFB",
                                    "CityId": "UBSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.44383, 33.64383"
                                },
                                {
                                    "Id": "GTR",
                                    "Name": "Columbus Golden Triangle Reg.",
                                    "CityId": "UBSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.6, 33.45"
                                }
                            ]
                        },
                        {
                            "Id": "CCBA",
                            "Name": "Upland",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.64839, 34.09751",
                            "IataCode": "CCB",
                            "Airports": [
                                {
                                    "Id": "JUP",
                                    "Name": "Upland Cable Heliport",
                                    "CityId": "CCBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.63333, 34.1"
                                },
                                {
                                    "Id": "CCB",
                                    "Name": "Upland Cable",
                                    "CityId": "CCBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.68889, 34.113889"
                                }
                            ]
                        },
                        {
                            "Id": "CCRA",
                            "Name": "Concord",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.03107, 37.977979",
                            "IataCode": "CCR",
                            "Airports": [
                                {
                                    "Id": "CCR",
                                    "Name": "Concord",
                                    "CityId": "CCRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.05556, 37.988889"
                                }
                            ]
                        },
                        {
                            "Id": "CCYA",
                            "Name": "Charles City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-92.672411, 43.066361",
                            "IataCode": "CCY",
                            "Airports": [
                                {
                                    "Id": "CCY",
                                    "Name": "Charles City",
                                    "CityId": "CCYA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-92.61074, 43.07247"
                                }
                            ]
                        },
                        {
                            "Id": "CDBA",
                            "Name": "Cold Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.72111, 55.185833",
                            "IataCode": "CDB",
                            "Airports": [
                                {
                                    "Id": "CDB",
                                    "Name": "Cold Bay",
                                    "CityId": "CDBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.71972, 55.199722"
                                }
                            ]
                        },
                        {
                            "Id": "CDCA",
                            "Name": "Cedar City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-113.06189, 37.677477",
                            "IataCode": "CDC",
                            "Airports": [
                                {
                                    "Id": "CDC",
                                    "Name": "Cedar City",
                                    "CityId": "CDCA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-113.09738, 37.701479"
                                }
                            ]
                        },
                        {
                            "Id": "CDHA",
                            "Name": "Camden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.834329, 33.584558",
                            "IataCode": "CDH",
                            "Airports": [
                                {
                                    "Id": "CDH",
                                    "Name": "Camden",
                                    "CityId": "CDHA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.76322, 33.62261"
                                }
                            ]
                        },
                        {
                            "Id": "CDKA",
                            "Name": "Cedar Key",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-83.035121, 29.138578",
                            "IataCode": "CDK",
                            "Airports": [
                                {
                                    "Id": "CDK",
                                    "Name": "Cedar Key",
                                    "CityId": "CDKA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-83.04957, 29.13775"
                                }
                            ]
                        },
                        {
                            "Id": "CDNA",
                            "Name": "Camden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.607024, 34.246539",
                            "IataCode": "CDN",
                            "Airports": [
                                {
                                    "Id": "CDN",
                                    "Name": "Camden",
                                    "CityId": "CDNA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.6, 34.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CDRA",
                            "Name": "Chadron",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-102.99991, 42.829419",
                            "IataCode": "CDR",
                            "Airports": [
                                {
                                    "Id": "CDR",
                                    "Name": "Chadron",
                                    "CityId": "CDRA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-103.09708, 42.835192"
                                }
                            ]
                        },
                        {
                            "Id": "CDVA",
                            "Name": "Cordova",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.7575, 60.542778",
                            "IataCode": "CDV",
                            "Airports": [
                                {
                                    "Id": "CDV",
                                    "Name": "Cordova Mudhole Smith",
                                    "CityId": "CDVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.47, 60.493056"
                                },
                                {
                                    "Id": "CKU",
                                    "Name": "Cordova City",
                                    "CityId": "CDVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.75, 60.55"
                                }
                            ]
                        },
                        {
                            "Id": "CDWA",
                            "Name": "Caldwell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.276537, 40.839822",
                            "IataCode": "CDW",
                            "Airports": [
                                {
                                    "Id": "CDW",
                                    "Name": "Caldwell",
                                    "CityId": "CDWA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.266667, 40.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CECA",
                            "Name": "Crescent City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-124.20175, 41.755948",
                            "IataCode": "CEC",
                            "Airports": [
                                {
                                    "Id": "CEC",
                                    "Name": "Crescent City",
                                    "CityId": "CECA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-124.23583, 41.78"
                                }
                            ]
                        },
                        {
                            "Id": "CEMA",
                            "Name": "Central",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-144.80306, 65.5725",
                            "IataCode": "CEM",
                            "Airports": [
                                {
                                    "Id": "CEM",
                                    "Name": "Central",
                                    "CityId": "CEMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-144.78194, 65.574722"
                                }
                            ]
                        },
                        {
                            "Id": "CEUA",
                            "Name": "Clemson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-82.837365, 34.683438",
                            "IataCode": "CEU",
                            "Airports": [
                                {
                                    "Id": "CEU",
                                    "Name": "Clemson",
                                    "CityId": "CEUA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.88626, 34.67177"
                                }
                            ]
                        },
                        {
                            "Id": "CEWA",
                            "Name": "Crestview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-86.570508, 30.762133",
                            "IataCode": "CEW",
                            "Airports": [
                                {
                                    "Id": "CEW",
                                    "Name": "Crestview",
                                    "CityId": "CEWA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-86.5219, 30.7788"
                                }
                            ]
                        },
                        {
                            "Id": "CEYA",
                            "Name": "Murray",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-88.314761, 36.610333",
                            "IataCode": "CEY",
                            "Airports": [
                                {
                                    "Id": "CEY",
                                    "Name": "Murray",
                                    "CityId": "CEYA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-88.316667, 36.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CEZA",
                            "Name": "Cortez",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-108.58593, 37.348883",
                            "IataCode": "CEZ",
                            "Airports": [
                                {
                                    "Id": "CEZ",
                                    "Name": "Cortez",
                                    "CityId": "CEZA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-108.62801, 37.302733"
                                }
                            ]
                        },
                        {
                            "Id": "CFDA",
                            "Name": "Bryan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.369963, 30.674364",
                            "IataCode": "CFD",
                            "Airports": [
                                {
                                    "Id": "CFD",
                                    "Name": "Bryan",
                                    "CityId": "CFDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.33246, 30.71658"
                                }
                            ]
                        },
                        {
                            "Id": "CFVA",
                            "Name": "Coffeyville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.616366, 37.0373",
                            "IataCode": "CFV",
                            "Airports": [
                                {
                                    "Id": "CFV",
                                    "Name": "Coffeyville",
                                    "CityId": "CFVA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.57414, 37.09535"
                                }
                            ]
                        },
                        {
                            "Id": "CGEA",
                            "Name": "Cambridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.078829, 38.563174",
                            "IataCode": "CGE",
                            "Airports": [
                                {
                                    "Id": "CGE",
                                    "Name": "Cambridge",
                                    "CityId": "CGEA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.030347, 38.539522"
                                }
                            ]
                        },
                        {
                            "Id": "CGIA",
                            "Name": "Cape Girardeau",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-89.518148, 37.305884",
                            "IataCode": "CGI",
                            "Airports": [
                                {
                                    "Id": "CGI",
                                    "Name": "Cape Girardeau",
                                    "CityId": "CGIA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-89.571667, 37.223611"
                                }
                            ]
                        },
                        {
                            "Id": "CGZA",
                            "Name": "Casa Grande",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.75735, 32.879502",
                            "IataCode": "CGZ",
                            "Airports": [
                                {
                                    "Id": "CGZ",
                                    "Name": "Casa Grande",
                                    "CityId": "CGZA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.76694, 32.955"
                                }
                            ]
                        },
                        {
                            "Id": "CHAA",
                            "Name": "Chattanooga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-85.30968, 35.04563",
                            "IataCode": "CHA",
                            "Airports": [
                                {
                                    "Id": "CHA",
                                    "Name": "Chattanooga",
                                    "CityId": "CHAA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-85.203611, 35.036389"
                                }
                            ]
                        },
                        {
                            "Id": "CHLA",
                            "Name": "Challis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-114.23173, 44.504644",
                            "IataCode": "CHL",
                            "Airports": [
                                {
                                    "Id": "CHL",
                                    "Name": "Challis",
                                    "CityId": "CHLA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-114.25, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "CHOA",
                            "Name": "Charlottesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.476678, 38.029306",
                            "IataCode": "CHO",
                            "Airports": [
                                {
                                    "Id": "CHO",
                                    "Name": "Charlottesville",
                                    "CityId": "CHOA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-78.453056, 38.141389"
                                }
                            ]
                        },
                        {
                            "Id": "CHSA",
                            "Name": "Charleston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.930922, 32.776566",
                            "IataCode": "CHS",
                            "Airports": [
                                {
                                    "Id": "CHS",
                                    "Name": "Charleston",
                                    "CityId": "CHSA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.038889, 32.899167"
                                }
                            ]
                        },
                        {
                            "Id": "CHUA",
                            "Name": "Chuathbaluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.245, 61.571944",
                            "IataCode": "CHU",
                            "Airports": [
                                {
                                    "Id": "CHU",
                                    "Name": "Chuathbaluk",
                                    "CityId": "CHUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159, 61.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CICA",
                            "Name": "Chico",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.83748, 39.728494",
                            "IataCode": "CIC",
                            "Airports": [
                                {
                                    "Id": "CIC",
                                    "Name": "Chico",
                                    "CityId": "CICA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.85667, 39.795833"
                                }
                            ]
                        },
                        {
                            "Id": "CIDA",
                            "Name": "Cedar Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.644069, 42.008333",
                            "IataCode": "CID",
                            "Airports": [
                                {
                                    "Id": "CID",
                                    "Name": "Cedar Rapids",
                                    "CityId": "CIDA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.706111, 41.884444"
                                }
                            ]
                        },
                        {
                            "Id": "CIGA",
                            "Name": "Craig",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.54645, 40.515249",
                            "IataCode": "CIG",
                            "Airports": [
                                {
                                    "Id": "CIG",
                                    "Name": "Craig-Moffat",
                                    "CityId": "CIGA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.525, 40.495556"
                                }
                            ]
                        },
                        {
                            "Id": "CIKA",
                            "Name": "Chalkyitsik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-143.72222, 66.654444",
                            "IataCode": "CIK",
                            "Airports": [
                                {
                                    "Id": "CIK",
                                    "Name": "Chalkyitsik",
                                    "CityId": "CIKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-143.72917, 66.648056"
                                }
                            ]
                        },
                        {
                            "Id": "CINA",
                            "Name": "Carroll",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.86693, 42.065818",
                            "IataCode": "CIN",
                            "Airports": [
                                {
                                    "Id": "CIN",
                                    "Name": "Carroll",
                                    "CityId": "CINA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.78998, 42.04693"
                                }
                            ]
                        },
                        {
                            "Id": "CIRA",
                            "Name": "Cairo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.176461, 37.005329",
                            "IataCode": "CIR",
                            "Airports": [
                                {
                                    "Id": "CIR",
                                    "Name": "Cairo",
                                    "CityId": "CIRA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.21924, 37.06422"
                                }
                            ]
                        },
                        {
                            "Id": "CKAA",
                            "Name": "Cherokee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-98.35674, 36.754475",
                            "IataCode": "CKA",
                            "Airports": [
                                {
                                    "Id": "CKA",
                                    "Name": "Cherokee",
                                    "CityId": "CKAA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-98.35, 36.75"
                                }
                            ]
                        },
                        {
                            "Id": "CKBA",
                            "Name": "Clarksburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-80.344534, 39.280645",
                            "IataCode": "CKB",
                            "Airports": [
                                {
                                    "Id": "CKB",
                                    "Name": "Clarksburg",
                                    "CityId": "CKBA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-80.229444, 39.295556"
                                }
                            ]
                        },
                        {
                            "Id": "CKDA",
                            "Name": "Crooked Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.11083, 61.87",
                            "IataCode": "CKD",
                            "Airports": [
                                {
                                    "Id": "CKD",
                                    "Name": "Crooked Creek",
                                    "CityId": "CKDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.10083, 61.870833"
                                }
                            ]
                        },
                        {
                            "Id": "CKMA",
                            "Name": "Clarksdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.570932, 34.200109",
                            "IataCode": "CKM",
                            "Airports": [
                                {
                                    "Id": "CKM",
                                    "Name": "Clarksdale",
                                    "CityId": "CKMA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.51677, 34.30011"
                                }
                            ]
                        },
                        {
                            "Id": "CKNA",
                            "Name": "Crookston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-96.608121, 47.774138",
                            "IataCode": "CKN",
                            "Airports": [
                                {
                                    "Id": "CKN",
                                    "Name": "Crookston",
                                    "CityId": "CKNA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-96.62173, 47.84164"
                                }
                            ]
                        },
                        {
                            "Id": "CKVA",
                            "Name": "Clarksville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-87.359453, 36.529771",
                            "IataCode": "CKV",
                            "Airports": [
                                {
                                    "Id": "CKV",
                                    "Name": "Clarksville",
                                    "CityId": "CKVA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-87.414444, 36.620833"
                                }
                            ]
                        },
                        {
                            "Id": "CLIB",
                            "Name": "Clintonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.762323, 44.620535",
                            "IataCode": "CLI",
                            "Airports": [
                                {
                                    "Id": "CLI",
                                    "Name": "Clintonville",
                                    "CityId": "CLIB",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.731422, 44.612468"
                                }
                            ]
                        },
                        {
                            "Id": "CSMA",
                            "Name": "Clinton",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-98.967307, 35.515606",
                            "IataCode": "CSM",
                            "Airports": [
                                {
                                    "Id": "CLK",
                                    "Name": "Clinton Municipal",
                                    "CityId": "CSMA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-98.9325, 35.538056"
                                },
                                {
                                    "Id": "CSM",
                                    "Name": "Clinton Sherman",
                                    "CityId": "CSMA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.933333, 35.539167"
                                }
                            ]
                        },
                        {
                            "Id": "CLLA",
                            "Name": "College Station",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.334407, 30.627977",
                            "IataCode": "CLL",
                            "Airports": [
                                {
                                    "Id": "CLL",
                                    "Name": "College Station",
                                    "CityId": "CLLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.364444, 30.589444"
                                }
                            ]
                        },
                        {
                            "Id": "CLMA",
                            "Name": "Port Angeles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.43074, 48.118146",
                            "IataCode": "CLM",
                            "Airports": [
                                {
                                    "Id": "CLM",
                                    "Name": "Port Angeles",
                                    "CityId": "CLMA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.49361, 48.118889"
                                }
                            ]
                        },
                        {
                            "Id": "CLPA",
                            "Name": "Clarks Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.55083, 58.844167",
                            "IataCode": "CLP",
                            "Airports": [
                                {
                                    "Id": "CLP",
                                    "Name": "Clarks Point",
                                    "CityId": "CLPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.54306, 58.843056"
                                }
                            ]
                        },
                        {
                            "Id": "CLRA",
                            "Name": "Calipatria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.51415, 33.125596",
                            "IataCode": "CLR",
                            "Airports": [
                                {
                                    "Id": "CLR",
                                    "Name": "Calipatria",
                                    "CityId": "CLRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.51667, 33.133333"
                                }
                            ]
                        },
                        {
                            "Id": "CLUA",
                            "Name": "Columbus",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.92138, 39.20144",
                            "IataCode": "CLU",
                            "Airports": [
                                {
                                    "Id": "CLU",
                                    "Name": "Columbus",
                                    "CityId": "CLUA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.916667, 39.216667"
                                }
                            ]
                        },
                        {
                            "Id": "CMHA",
                            "Name": "Columbus",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.998794, 39.961175",
                            "IataCode": "CMH",
                            "Airports": [
                                {
                                    "Id": "OSU",
                                    "Name": "Columbus Ohio State University",
                                    "CityId": "CMHA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.073333, 40.078056"
                                },
                                {
                                    "Id": "CMH",
                                    "Name": "Port Columbus",
                                    "CityId": "CMHA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.878333, 39.991111"
                                },
                                {
                                    "Id": "LCK",
                                    "Name": "Columbus Rickenbacker",
                                    "CityId": "CMHA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.933333, 39.816667"
                                }
                            ]
                        },
                        {
                            "Id": "CMXA",
                            "Name": "Hancock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-88.580956, 47.126871",
                            "IataCode": "CMX",
                            "Airports": [
                                {
                                    "Id": "CMX",
                                    "Name": "Hancock",
                                    "CityId": "CMXA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-88.486389, 47.167778"
                                }
                            ]
                        },
                        {
                            "Id": "CMYA",
                            "Name": "Sparta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.812912, 43.944133",
                            "IataCode": "CMY",
                            "Airports": [
                                {
                                    "Id": "CMY",
                                    "Name": "Sparta",
                                    "CityId": "CMYA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.73791, 43.9583"
                                }
                            ]
                        },
                        {
                            "Id": "CNHA",
                            "Name": "Claremont",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-72.346756, 43.37674",
                            "IataCode": "CNH",
                            "Airports": [
                                {
                                    "Id": "CNH",
                                    "Name": "Claremont",
                                    "CityId": "CNHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-72.333333, 43.383333"
                                }
                            ]
                        },
                        {
                            "Id": "CNKA",
                            "Name": "Concordia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.66254, 39.570835",
                            "IataCode": "CNK",
                            "Airports": [
                                {
                                    "Id": "CNK",
                                    "Name": "Concordia",
                                    "CityId": "CNKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.666667, 39.566667"
                                }
                            ]
                        },
                        {
                            "Id": "CNMA",
                            "Name": "Carlsbad",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-104.22884, 32.420674",
                            "IataCode": "CNM",
                            "Airports": [
                                {
                                    "Id": "CNM",
                                    "Name": "Carlsbad",
                                    "CityId": "CNMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-104.26278, 32.337222"
                                }
                            ]
                        },
                        {
                            "Id": "CNOA",
                            "Name": "Chino",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.68894, 34.012235",
                            "IataCode": "CNO",
                            "Airports": [
                                {
                                    "Id": "CNO",
                                    "Name": "Chino",
                                    "CityId": "CNOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.63917, 33.980833"
                                }
                            ]
                        },
                        {
                            "Id": "CNUA",
                            "Name": "Chanute",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.457203, 37.679213",
                            "IataCode": "CNU",
                            "Airports": [
                                {
                                    "Id": "CNU",
                                    "Name": "Chanute",
                                    "CityId": "CNUA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.48707, 37.668501"
                                }
                            ]
                        },
                        {
                            "Id": "CNYA",
                            "Name": "Moab",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-109.54984, 38.573315",
                            "IataCode": "CNY",
                            "Airports": [
                                {
                                    "Id": "CNY",
                                    "Name": "Moab",
                                    "CityId": "CNYA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-109.74611, 38.759444"
                                }
                            ]
                        },
                        {
                            "Id": "COAA",
                            "Name": "Columbia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.40131, 38.036312",
                            "IataCode": "COA",
                            "Airports": [
                                {
                                    "Id": "COA",
                                    "Name": "Columbia",
                                    "CityId": "COAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.41278, 38.028889"
                                }
                            ]
                        },
                        {
                            "Id": "COEA",
                            "Name": "Coeur D'Alene",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-116.78047, 47.677683",
                            "IataCode": "COE",
                            "Airports": [
                                {
                                    "Id": "COE",
                                    "Name": "Coeur D'Alene",
                                    "CityId": "COEA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-116.81936, 47.77407"
                                }
                            ]
                        },
                        {
                            "Id": "COMA",
                            "Name": "Coleman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.426453, 31.827368",
                            "IataCode": "COM",
                            "Airports": [
                                {
                                    "Id": "COM",
                                    "Name": "Coleman",
                                    "CityId": "COMA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.40367, 31.84126"
                                }
                            ]
                        },
                        {
                            "Id": "CONA",
                            "Name": "Concord",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.537572, 43.208137",
                            "IataCode": "CON",
                            "Airports": [
                                {
                                    "Id": "CON",
                                    "Name": "Concord",
                                    "CityId": "CONA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.50146, 43.20341"
                                }
                            ]
                        },
                        {
                            "Id": "COPA",
                            "Name": "Cooperstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-74.924321, 42.70063",
                            "IataCode": "COP",
                            "Airports": [
                                {
                                    "Id": "COP",
                                    "Name": "Cooperstown",
                                    "CityId": "COPA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-98.11168, 47.4251"
                                }
                            ]
                        },
                        {
                            "Id": "COTA",
                            "Name": "Cotulla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.235032, 28.436934",
                            "IataCode": "COT",
                            "Airports": [
                                {
                                    "Id": "COT",
                                    "Name": "Cotulla",
                                    "CityId": "COTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.233333, 28.433333"
                                }
                            ]
                        },
                        {
                            "Id": "COUA",
                            "Name": "Columbia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.334072, 38.951705",
                            "IataCode": "COU",
                            "Airports": [
                                {
                                    "Id": "COU",
                                    "Name": "Columbia",
                                    "CityId": "COUA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.219167, 38.813611"
                                }
                            ]
                        },
                        {
                            "Id": "CPMA",
                            "Name": "Compton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.22007, 33.895849",
                            "IataCode": "CPM",
                            "Airports": [
                                {
                                    "Id": "CPM",
                                    "Name": "Compton",
                                    "CityId": "CPMA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.24778, 33.894444"
                                }
                            ]
                        },
                        {
                            "Id": "CPRA",
                            "Name": "Casper",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-106.31308, 42.866632",
                            "IataCode": "CPR",
                            "Airports": [
                                {
                                    "Id": "CPR",
                                    "Name": "Casper",
                                    "CityId": "CPRA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-106.46267, 42.903382"
                                }
                            ]
                        },
                        {
                            "Id": "JAXA",
                            "Name": "Jacksonville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.655651, 30.332184",
                            "IataCode": "JAX",
                            "Airports": [
                                {
                                    "Id": "NIP",
                                    "Name": "Jacksonville NAS",
                                    "CityId": "JAXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.680556, 30.25"
                                },
                                {
                                    "Id": "NZC",
                                    "Name": "Jacksonville Cecil Field NAS",
                                    "CityId": "JAXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.65, 30.333333"
                                },
                                {
                                    "Id": "CRG",
                                    "Name": "Jacksonville Craig Municipal",
                                    "CityId": "JAXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.51426, 30.33635"
                                },
                                {
                                    "Id": "VQQ",
                                    "Name": "Cecil Airport",
                                    "CityId": "JAXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.876667, 30.218611"
                                },
                                {
                                    "Id": "JAX",
                                    "Name": "Jacksonville International",
                                    "CityId": "JAXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.689722, 30.4925"
                                }
                            ]
                        },
                        {
                            "Id": "CROA",
                            "Name": "Corcoran",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.5604, 36.098007",
                            "IataCode": "CRO",
                            "Airports": [
                                {
                                    "Id": "CRO",
                                    "Name": "Corcoran",
                                    "CityId": "CROA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.59568, 36.10217"
                                }
                            ]
                        },
                        {
                            "Id": "CRSA",
                            "Name": "Corsicana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.468873, 32.09543",
                            "IataCode": "CRS",
                            "Airports": [
                                {
                                    "Id": "CRS",
                                    "Name": "Corsicana",
                                    "CityId": "CRSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.39804, 32.02738"
                                }
                            ]
                        },
                        {
                            "Id": "CRTA",
                            "Name": "Crossett",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-91.961243, 33.128178",
                            "IataCode": "CRT",
                            "Airports": [
                                {
                                    "Id": "CRT",
                                    "Name": "Crossett",
                                    "CityId": "CRTA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.966667, 33.133333"
                                }
                            ]
                        },
                        {
                            "Id": "CRWA",
                            "Name": "Charleston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-81.632623, 38.349819",
                            "IataCode": "CRW",
                            "Airports": [
                                {
                                    "Id": "CRW",
                                    "Name": "Charleston",
                                    "CityId": "CRWA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-81.593056, 38.372778"
                                }
                            ]
                        },
                        {
                            "Id": "CRXA",
                            "Name": "Corinth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.52227, 34.934255",
                            "IataCode": "CRX",
                            "Airports": [
                                {
                                    "Id": "CRX",
                                    "Name": "Corinth",
                                    "CityId": "CRXA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.6, 34.9"
                                }
                            ]
                        },
                        {
                            "Id": "CSGA",
                            "Name": "Columbus",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.98771, 32.460976",
                            "IataCode": "CSG",
                            "Airports": [
                                {
                                    "Id": "MKF",
                                    "Name": "Columbus Mckenna AAF",
                                    "CityId": "CSGA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.983333, 32.466667"
                                },
                                {
                                    "Id": "LSF",
                                    "Name": "Columbus Lawson AAF",
                                    "CityId": "CSGA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-85, 32.5"
                                },
                                {
                                    "Id": "CSG",
                                    "Name": "Columbus Metropolitain",
                                    "CityId": "CSGA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.941483, 32.513815"
                                }
                            ]
                        },
                        {
                            "Id": "CSNA",
                            "Name": "Carson City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-119.7674, 39.163798",
                            "IataCode": "CSN",
                            "Airports": [
                                {
                                    "Id": "CSN",
                                    "Name": "Carson City",
                                    "CityId": "CSNA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-119.73472, 39.191667"
                                }
                            ]
                        },
                        {
                            "Id": "CTBA",
                            "Name": "Cut Bank",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-112.32616, 48.633039",
                            "IataCode": "CTB",
                            "Airports": [
                                {
                                    "Id": "CTB",
                                    "Name": "Cut Bank",
                                    "CityId": "CTBA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-112.33333, 48.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CTHA",
                            "Name": "Coatesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.823835, 39.983162",
                            "IataCode": "CTH",
                            "Airports": [
                                {
                                    "Id": "CTH",
                                    "Name": "Coatesville",
                                    "CityId": "CTHA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.833333, 39.983333"
                                }
                            ]
                        },
                        {
                            "Id": "CTXA",
                            "Name": "Cortland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-76.180484, 42.601181",
                            "IataCode": "CTX",
                            "Airports": [
                                {
                                    "Id": "CTX",
                                    "Name": "Cortland",
                                    "CityId": "CTXA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-76.183333, 42.6"
                                }
                            ]
                        },
                        {
                            "Id": "CTYA",
                            "Name": "Cross City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-83.125131, 29.634398",
                            "IataCode": "CTY",
                            "Airports": [
                                {
                                    "Id": "CTY",
                                    "Name": "Cross City",
                                    "CityId": "CTYA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-83.116667, 29.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CTZA",
                            "Name": "Clinton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-78.323333, 34.997945",
                            "IataCode": "CTZ",
                            "Airports": [
                                {
                                    "Id": "CTZ",
                                    "Name": "Clinton",
                                    "CityId": "CTZA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-78.362217, 34.976845"
                                }
                            ]
                        },
                        {
                            "Id": "CVNA",
                            "Name": "Clovis",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-103.20523, 34.404799",
                            "IataCode": "CVN",
                            "Airports": [
                                {
                                    "Id": "CVN",
                                    "Name": "Clovis Municipal",
                                    "CityId": "CVNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.07922, 34.427302"
                                },
                                {
                                    "Id": "CVS",
                                    "Name": "Clovis Cannon AFB",
                                    "CityId": "CVNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.31667, 34.383889"
                                }
                            ]
                        },
                        {
                            "Id": "CVOA",
                            "Name": "Corvallis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.26204, 44.564566",
                            "IataCode": "CVO",
                            "Airports": [
                                {
                                    "Id": "CVO",
                                    "Name": "Corvallis",
                                    "CityId": "CVOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.28798, 44.49798"
                                }
                            ]
                        },
                        {
                            "Id": "LCHA",
                            "Name": "Lake Charles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-93.204403, 30.213092",
                            "IataCode": "LCH",
                            "Airports": [
                                {
                                    "Id": "LCH",
                                    "Name": "Lake Charles",
                                    "CityId": "LCHA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.225556, 30.131111"
                                }
                            ]
                        },
                        {
                            "Id": "CXFA",
                            "Name": "Coldfoot",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.18417, 67.256389",
                            "IataCode": "CXF",
                            "Airports": [
                                {
                                    "Id": "CXF",
                                    "Name": "Coldfoot",
                                    "CityId": "CXFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.16667, 67.266667"
                                }
                            ]
                        },
                        {
                            "Id": "CXLA",
                            "Name": "Calexico",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.49888, 32.678948",
                            "IataCode": "CXL",
                            "Airports": [
                                {
                                    "Id": "CXL",
                                    "Name": "Calexico",
                                    "CityId": "CXLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.51667, 32.666667"
                                }
                            ]
                        },
                        {
                            "Id": "CYFA",
                            "Name": "Chefornak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.26583, 60.16",
                            "IataCode": "CYF",
                            "Airports": [
                                {
                                    "Id": "CYF",
                                    "Name": "Chefornak",
                                    "CityId": "CYFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.2, 60.216667"
                                }
                            ]
                        },
                        {
                            "Id": "CYMA",
                            "Name": "Chatham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.94361, 57.515278",
                            "IataCode": "CYM",
                            "Airports": [
                                {
                                    "Id": "CYM",
                                    "Name": "Chatham",
                                    "CityId": "CYMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135, 57.5"
                                }
                            ]
                        },
                        {
                            "Id": "CYSA",
                            "Name": "Cheyenne",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.82025, 41.139981",
                            "IataCode": "CYS",
                            "Airports": [
                                {
                                    "Id": "FEW",
                                    "Name": "Cheyenne Warren AFB",
                                    "CityId": "CYSA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.81667, 41.133333"
                                },
                                {
                                    "Id": "CYS",
                                    "Name": "Cheyenne",
                                    "CityId": "CYSA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.81815, 41.156686"
                                }
                            ]
                        },
                        {
                            "Id": "CZKA",
                            "Name": "Cascade Locks",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.89064, 45.669839",
                            "IataCode": "CZK",
                            "Airports": [
                                {
                                    "Id": "CZK",
                                    "Name": "Cascade Locks / Stevens",
                                    "CityId": "CZKA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.9, 45.666667"
                                }
                            ]
                        },
                        {
                            "Id": "CZNA",
                            "Name": "Chisana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.04083, 62.066111",
                            "IataCode": "CZN",
                            "Airports": [
                                {
                                    "Id": "CZN",
                                    "Name": "Chisana",
                                    "CityId": "CZNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.05, 62.070833"
                                }
                            ]
                        },
                        {
                            "Id": "CZPA",
                            "Name": "Cape Pole",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.79333, 55.965",
                            "IataCode": "CZP",
                            "Airports": [
                                {
                                    "Id": "CZP",
                                    "Name": "Cape Pole",
                                    "CityId": "CZPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.78528, 55.966667"
                                }
                            ]
                        },
                        {
                            "Id": "DABA",
                            "Name": "Daytona Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.022833, 29.210815",
                            "IataCode": "DAB",
                            "Airports": [
                                {
                                    "Id": "DAB",
                                    "Name": "Daytona Beach",
                                    "CityId": "DABA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.052778, 29.1825"
                                }
                            ]
                        },
                        {
                            "Id": "BYAA",
                            "Name": "Boundary",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-141.01667, 64.08333",
                            "IataCode": "BYA",
                            "Airports": [
                                {
                                    "Id": "BYA",
                                    "Name": "Boundary",
                                    "CityId": "BYAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-141.11444, 64.076389"
                                }
                            ]
                        },
                        {
                            "Id": "BYGA",
                            "Name": "Buffalo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-106.69894, 44.348307",
                            "IataCode": "BYG",
                            "Airports": [
                                {
                                    "Id": "BYG",
                                    "Name": "Buffalo",
                                    "CityId": "BYGA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-106.72227, 44.38081"
                                }
                            ]
                        },
                        {
                            "Id": "DAGA",
                            "Name": "Daggett",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.88809, 34.863319",
                            "IataCode": "DAG",
                            "Airports": [
                                {
                                    "Id": "DAG",
                                    "Name": "Barstow-Daggett",
                                    "CityId": "DAGA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.78753, 34.8536"
                                }
                            ]
                        },
                        {
                            "Id": "DANA",
                            "Name": "Danville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-79.395023, 36.585972",
                            "IataCode": "DAN",
                            "Airports": [
                                {
                                    "Id": "DAN",
                                    "Name": "Danville",
                                    "CityId": "DANA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-79.335, 36.573056"
                                }
                            ]
                        },
                        {
                            "Id": "DAYA",
                            "Name": "Dayton",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.191607, 39.758948",
                            "IataCode": "DAY",
                            "Airports": [
                                {
                                    "Id": "DWF",
                                    "Name": "Dayton Wright AFB",
                                    "CityId": "DAYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.183333, 39.666667"
                                },
                                {
                                    "Id": "MGY",
                                    "Name": "Dayton Montgomery County",
                                    "CityId": "DAYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.254444, 39.933333"
                                },
                                {
                                    "Id": "FFO",
                                    "Name": "Dayton Patterson AFB",
                                    "CityId": "DAYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.2, 39.75"
                                },
                                {
                                    "Id": "DAY",
                                    "Name": "Dayton International",
                                    "CityId": "DAYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.221111, 39.9"
                                }
                            ]
                        },
                        {
                            "Id": "DBNA",
                            "Name": "Dublin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-82.903754, 32.540445",
                            "IataCode": "DBN",
                            "Airports": [
                                {
                                    "Id": "DBN",
                                    "Name": "Dublin",
                                    "CityId": "DBNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-82.986111, 32.561944"
                                }
                            ]
                        },
                        {
                            "Id": "DBQA",
                            "Name": "Dubuque",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-90.664572, 42.500558",
                            "IataCode": "DBQ",
                            "Airports": [
                                {
                                    "Id": "DBQ",
                                    "Name": "Dubuque",
                                    "CityId": "DBQA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-90.710833, 42.41"
                                }
                            ]
                        },
                        {
                            "Id": "CEXA",
                            "Name": "Chena Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.05556, 65.053056",
                            "IataCode": "CEX",
                            "Airports": [
                                {
                                    "Id": "CEX",
                                    "Name": "Chena Hot Springs",
                                    "CityId": "CEXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.16667, 65.05"
                                }
                            ]
                        },
                        {
                            "Id": "DCUA",
                            "Name": "Decatur",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.983342, 34.605925",
                            "IataCode": "DCU",
                            "Airports": [
                                {
                                    "Id": "DCU",
                                    "Name": "Decatur",
                                    "CityId": "DCUA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.94528, 34.65259"
                                }
                            ]
                        },
                        {
                            "Id": "DDCA",
                            "Name": "Dodge City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-100.01708, 37.752798",
                            "IataCode": "DDC",
                            "Airports": [
                                {
                                    "Id": "DDC",
                                    "Name": "Dodge City",
                                    "CityId": "DDCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-99.965, 37.761667"
                                }
                            ]
                        },
                        {
                            "Id": "DECA",
                            "Name": "Decatur",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.9548, 39.840315",
                            "IataCode": "DEC",
                            "Airports": [
                                {
                                    "Id": "DEC",
                                    "Name": "Decatur",
                                    "CityId": "DECA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.868611, 39.834167"
                                }
                            ]
                        },
                        {
                            "Id": "DEHA",
                            "Name": "Decorah",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.785709, 43.303306",
                            "IataCode": "DEH",
                            "Airports": [
                                {
                                    "Id": "DEH",
                                    "Name": "Decorah",
                                    "CityId": "DEHA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.73932, 43.27525"
                                }
                            ]
                        },
                        {
                            "Id": "DFIA",
                            "Name": "Defiance",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.35578, 41.284493",
                            "IataCode": "DFI",
                            "Airports": [
                                {
                                    "Id": "DFI",
                                    "Name": "Defiance",
                                    "CityId": "DFIA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.431412, 41.338605"
                                }
                            ]
                        },
                        {
                            "Id": "DUGA",
                            "Name": "Douglas",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.54534, 31.344547",
                            "IataCode": "DUG",
                            "Airports": [
                                {
                                    "Id": "DGL",
                                    "Name": "Douglas Municipal",
                                    "CityId": "DUGA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.50639, 31.3425"
                                },
                                {
                                    "Id": "DUG",
                                    "Name": "Bisbee-Douglas",
                                    "CityId": "DUGA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.60361, 31.468889"
                                }
                            ]
                        },
                        {
                            "Id": "DGWA",
                            "Name": "Douglas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-105.38221, 42.75969",
                            "IataCode": "DGW",
                            "Airports": [
                                {
                                    "Id": "DGW",
                                    "Name": "Douglas",
                                    "CityId": "DGWA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-105.38637, 42.79719"
                                }
                            ]
                        },
                        {
                            "Id": "DHNA",
                            "Name": "Dothan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.390489, 31.223231",
                            "IataCode": "DHN",
                            "Airports": [
                                {
                                    "Id": "DHN",
                                    "Name": "Dothan",
                                    "CityId": "DHNA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.448333, 31.32"
                                }
                            ]
                        },
                        {
                            "Id": "DHTA",
                            "Name": "Dalhart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-102.51325, 36.059477",
                            "IataCode": "DHT",
                            "Airports": [
                                {
                                    "Id": "DHT",
                                    "Name": "Dalhart",
                                    "CityId": "DHTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.5477, 36.02226"
                                }
                            ]
                        },
                        {
                            "Id": "DIKA",
                            "Name": "Dickinson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-102.78962, 46.879176",
                            "IataCode": "DIK",
                            "Airports": [
                                {
                                    "Id": "DIK",
                                    "Name": "Dickinson",
                                    "CityId": "DIKA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-102.9, 46.833333"
                                }
                            ]
                        },
                        {
                            "Id": "DKKA",
                            "Name": "Dunkirk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-79.333932, 42.479502",
                            "IataCode": "DKK",
                            "Airports": [
                                {
                                    "Id": "DKK",
                                    "Name": "Dunkirk",
                                    "CityId": "DKKA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-79.27171, 42.49339"
                                }
                            ]
                        },
                        {
                            "Id": "DRTA",
                            "Name": "Del Rio",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.89676, 29.36273",
                            "IataCode": "DRT",
                            "Airports": [
                                {
                                    "Id": "DLF",
                                    "Name": "Del Rio Laughlin AFB",
                                    "CityId": "DRTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.9, 29.366667"
                                },
                                {
                                    "Id": "DRT",
                                    "Name": "Del Rio International",
                                    "CityId": "DRTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.92041, 29.374397"
                                }
                            ]
                        },
                        {
                            "Id": "DLGA",
                            "Name": "Dillingham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.4575, 59.039722",
                            "IataCode": "DLG",
                            "Airports": [
                                {
                                    "Id": "DLG",
                                    "Name": "Dillingham",
                                    "CityId": "DLGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.5, 59.05"
                                }
                            ]
                        },
                        {
                            "Id": "DLHA",
                            "Name": "Duluth",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-92.106579, 46.783273",
                            "IataCode": "DLH",
                            "Airports": [
                                {
                                    "Id": "DLH",
                                    "Name": "Duluth International",
                                    "CityId": "DLHA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.190278, 46.8425"
                                },
                                {
                                    "Id": "LKI",
                                    "Name": "Duluth Lakeside USAF",
                                    "CityId": "DLHA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.1, 46.783333"
                                }
                            ]
                        },
                        {
                            "Id": "DLLA",
                            "Name": "Dillon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.371158, 34.41655",
                            "IataCode": "DLL",
                            "Airports": [
                                {
                                    "Id": "DLL",
                                    "Name": "Dillon",
                                    "CityId": "DLLA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.3686, 34.4491"
                                }
                            ]
                        },
                        {
                            "Id": "DLNA",
                            "Name": "Dillon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-112.63752, 45.216311",
                            "IataCode": "DLN",
                            "Airports": [
                                {
                                    "Id": "DLN",
                                    "Name": "Dillon",
                                    "CityId": "DLNA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-112.63333, 45.216667"
                                }
                            ]
                        },
                        {
                            "Id": "DLSA",
                            "Name": "The Dalles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.17868, 45.594564",
                            "IataCode": "DLS",
                            "Airports": [
                                {
                                    "Id": "DLS",
                                    "Name": "The Dalles",
                                    "CityId": "DLSA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.17257, 45.61818"
                                }
                            ]
                        },
                        {
                            "Id": "TUSA",
                            "Name": "Tucson",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.92648, 32.221743",
                            "IataCode": "TUS",
                            "Airports": [
                                {
                                    "Id": "AVW",
                                    "Name": "Tucson Avra Valley",
                                    "CityId": "TUSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.21839, 32.409556"
                                },
                                {
                                    "Id": "DMA",
                                    "Name": "Tucson Davis Monthan AFB",
                                    "CityId": "TUSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.88306, 32.166389"
                                },
                                {
                                    "Id": "TUS",
                                    "Name": "Tucson International",
                                    "CityId": "TUSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.92896, 32.107297"
                                }
                            ]
                        },
                        {
                            "Id": "DMNA",
                            "Name": "Deming",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-107.75864, 32.268698",
                            "IataCode": "DMN",
                            "Airports": [
                                {
                                    "Id": "DMN",
                                    "Name": "Deming",
                                    "CityId": "DMNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-107.75, 32.266667"
                                }
                            ]
                        },
                        {
                            "Id": "DMOA",
                            "Name": "Sedalia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.228261, 38.704461",
                            "IataCode": "DMO",
                            "Airports": [
                                {
                                    "Id": "DMO",
                                    "Name": "Sedalia",
                                    "CityId": "DMOA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.180556, 38.704167"
                                }
                            ]
                        },
                        {
                            "Id": "DNNA",
                            "Name": "Dalton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.970223, 34.769802",
                            "IataCode": "DNN",
                            "Airports": [
                                {
                                    "Id": "DNN",
                                    "Name": "Dalton",
                                    "CityId": "DNNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.86911, 34.72175"
                                }
                            ]
                        },
                        {
                            "Id": "DNVA",
                            "Name": "Danville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.630021, 40.124481",
                            "IataCode": "DNV",
                            "Airports": [
                                {
                                    "Id": "DNV",
                                    "Name": "Danville",
                                    "CityId": "DNVA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.596944, 40.197222"
                                }
                            ]
                        },
                        {
                            "Id": "DOVA",
                            "Name": "Dover-Cheswold",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "DELAW",
                            "Location": "-75.524368, 39.158168",
                            "IataCode": "DOV",
                            "Airports": [
                                {
                                    "Id": "DOV",
                                    "Name": "Dover-Cheswold",
                                    "CityId": "DOVA",
                                    "CountryId": "US",
                                    "RegionId": "DELAW",
                                    "Location": "-75.464167, 39.128611"
                                }
                            ]
                        },
                        {
                            "Id": "DRAA",
                            "Name": "Mercury",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.99447, 36.66051",
                            "IataCode": "DRA",
                            "Airports": [
                                {
                                    "Id": "DRA",
                                    "Name": "Mercury",
                                    "CityId": "DRAA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.96667, 36.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DRGA",
                            "Name": "Deering",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.71722, 66.075556",
                            "IataCode": "DRG",
                            "Airports": [
                                {
                                    "Id": "DRG",
                                    "Name": "Deering",
                                    "CityId": "DRGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.73333, 66.080833"
                                }
                            ]
                        },
                        {
                            "Id": "CXCA",
                            "Name": "Chitina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-144.43694, 61.515833",
                            "IataCode": "CXC",
                            "Airports": [
                                {
                                    "Id": "CXC",
                                    "Name": "Chitina",
                                    "CityId": "CXCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-144.42778, 61.583333"
                                }
                            ]
                        },
                        {
                            "Id": "DYLA",
                            "Name": "Doylestown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.129894, 40.310106",
                            "IataCode": "DYL",
                            "Airports": [
                                {
                                    "Id": "DYL",
                                    "Name": "Doylestown",
                                    "CityId": "DYLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.133333, 40.316667"
                                }
                            ]
                        },
                        {
                            "Id": "DSIA",
                            "Name": "Destin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-86.495783, 30.393534",
                            "IataCode": "DSI",
                            "Airports": [
                                {
                                    "Id": "DSI",
                                    "Name": "Destin",
                                    "CityId": "DSIA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-86.5, 30.383333"
                                }
                            ]
                        },
                        {
                            "Id": "DSMA",
                            "Name": "Des Moines",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.609106, 41.600545",
                            "IataCode": "DSM",
                            "Airports": [
                                {
                                    "Id": "DSM",
                                    "Name": "Des Moines",
                                    "CityId": "DSMA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.656667, 41.533889"
                                }
                            ]
                        },
                        {
                            "Id": "DSVA",
                            "Name": "Dansville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-77.696106, 42.5609",
                            "IataCode": "DSV",
                            "Airports": [
                                {
                                    "Id": "DSV",
                                    "Name": "Dansville",
                                    "CityId": "DSVA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-77.7, 42.566667"
                                }
                            ]
                        },
                        {
                            "Id": "DTAA",
                            "Name": "Delta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.57717, 39.352178",
                            "IataCode": "DTA",
                            "Airports": [
                                {
                                    "Id": "DTA",
                                    "Name": "Delta",
                                    "CityId": "DTAA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.5, 39.383333"
                                }
                            ]
                        },
                        {
                            "Id": "DTLA",
                            "Name": "Detroit Lakes",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.845325, 46.817181",
                            "IataCode": "DTL",
                            "Airports": [
                                {
                                    "Id": "DTL",
                                    "Name": "Detroit Lakes",
                                    "CityId": "DTLA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.886111, 46.826389"
                                }
                            ]
                        },
                        {
                            "Id": "SHVA",
                            "Name": "Shreveport",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-93.750179, 32.525152",
                            "IataCode": "SHV",
                            "Airports": [
                                {
                                    "Id": "BAD",
                                    "Name": "Shreveport Barksdale AFB",
                                    "CityId": "SHVA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.666667, 32.5"
                                },
                                {
                                    "Id": "DTN",
                                    "Name": "Shreveport Downtown",
                                    "CityId": "SHVA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.744444, 32.539722"
                                },
                                {
                                    "Id": "SHV",
                                    "Name": "Shreveport Regional",
                                    "CityId": "SHVA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.825556, 32.446667"
                                }
                            ]
                        },
                        {
                            "Id": "DUAA",
                            "Name": "Durant",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-96.370824, 33.993986",
                            "IataCode": "DUA",
                            "Airports": [
                                {
                                    "Id": "DUA",
                                    "Name": "Durant",
                                    "CityId": "DUAA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.39471, 33.94232"
                                }
                            ]
                        },
                        {
                            "Id": "DUCA",
                            "Name": "Duncan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.957813, 34.502303",
                            "IataCode": "DUC",
                            "Airports": [
                                {
                                    "Id": "DUC",
                                    "Name": "Duncan",
                                    "CityId": "DUCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.959722, 34.472222"
                                }
                            ]
                        },
                        {
                            "Id": "DVLA",
                            "Name": "Devils Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-98.86512, 48.112779",
                            "IataCode": "DVL",
                            "Airports": [
                                {
                                    "Id": "DVL",
                                    "Name": "Devils Lake",
                                    "CityId": "DVLA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-98.9075, 48.113056"
                                }
                            ]
                        },
                        {
                            "Id": "DVNA",
                            "Name": "Davenport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-90.577637, 41.523644",
                            "IataCode": "DVN",
                            "Airports": [
                                {
                                    "Id": "DVN",
                                    "Name": "Davenport",
                                    "CityId": "DVNA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-90.588611, 41.610556"
                                }
                            ]
                        },
                        {
                            "Id": "PHXA",
                            "Name": "Phoenix",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.07404, 33.448377",
                            "IataCode": "PHX",
                            "Airports": [
                                {
                                    "Id": "AZA",
                                    "Name": "Phoenix-Mesa Gateway",
                                    "CityId": "PHXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.65556, 33.307778"
                                },
                                {
                                    "Id": "SCF",
                                    "Name": "Phoenix Scottsdale Municipal",
                                    "CityId": "PHXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.91667, 33.616667"
                                },
                                {
                                    "Id": "DVT",
                                    "Name": "Phoenix-Deer Valley",
                                    "CityId": "PHXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.0825, 33.688333"
                                },
                                {
                                    "Id": "LUF",
                                    "Name": "Phoenix Luke AFB",
                                    "CityId": "PHXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.91667, 33.616667"
                                },
                                {
                                    "Id": "PHX",
                                    "Name": "Phoenix Sky Harbor",
                                    "CityId": "PHXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.0118, 33.43316"
                                }
                            ]
                        },
                        {
                            "Id": "DXRA",
                            "Name": "Danbury",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-73.454011, 41.394817",
                            "IataCode": "DXR",
                            "Airports": [
                                {
                                    "Id": "DXR",
                                    "Name": "Danbury",
                                    "CityId": "DXRA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-73.47888, 41.371463"
                                }
                            ]
                        },
                        {
                            "Id": "EDAA",
                            "Name": "Edna Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.66222, 55.948889",
                            "IataCode": "EDA",
                            "Airports": [
                                {
                                    "Id": "EDA",
                                    "Name": "Edna Bay",
                                    "CityId": "EDAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.66667, 55.95"
                                }
                            ]
                        },
                        {
                            "Id": "EDEA",
                            "Name": "Edenton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-76.607721, 36.057938",
                            "IataCode": "EDE",
                            "Airports": [
                                {
                                    "Id": "EDE",
                                    "Name": "Edenton",
                                    "CityId": "EDEA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-76.65, 36.066667"
                                }
                            ]
                        },
                        {
                            "Id": "EDKA",
                            "Name": "El Dorado",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-96.862252, 37.81724",
                            "IataCode": "EDK",
                            "Airports": [
                                {
                                    "Id": "EDK",
                                    "Name": "El Dorado",
                                    "CityId": "EDKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-96.814528, 37.777626"
                                }
                            ]
                        },
                        {
                            "Id": "EDWA",
                            "Name": "Edwards AFB",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.93507, 34.926088",
                            "IataCode": "EDW",
                            "Airports": [
                                {
                                    "Id": "EDW",
                                    "Name": "Edwards AFB",
                                    "CityId": "EDWA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.93205, 34.912118"
                                }
                            ]
                        },
                        {
                            "Id": "EEDA",
                            "Name": "Needles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-114.61413, 34.84806",
                            "IataCode": "EED",
                            "Airports": [
                                {
                                    "Id": "EED",
                                    "Name": "Needles",
                                    "CityId": "EEDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-114.61667, 34.85"
                                }
                            ]
                        },
                        {
                            "Id": "EEKA",
                            "Name": "Eek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.02444, 60.218889",
                            "IataCode": "EEK",
                            "Airports": [
                                {
                                    "Id": "EEK",
                                    "Name": "Eek",
                                    "CityId": "EEKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.01667, 60.216667"
                                }
                            ]
                        },
                        {
                            "Id": "EAAA",
                            "Name": "Eagle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-141.2, 64.788056",
                            "IataCode": "EAA",
                            "Airports": [
                                {
                                    "Id": "EAA",
                                    "Name": "Eagle",
                                    "CityId": "EAAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-141.14861, 64.776667"
                                }
                            ]
                        },
                        {
                            "Id": "EARA",
                            "Name": "Kearney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.081477, 40.699457",
                            "IataCode": "EAR",
                            "Airports": [
                                {
                                    "Id": "EAR",
                                    "Name": "Kearney",
                                    "CityId": "EARA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-98.998889, 40.728333"
                                }
                            ]
                        },
                        {
                            "Id": "EATA",
                            "Name": "Wenatchee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-120.31035, 47.42346",
                            "IataCode": "EAT",
                            "Airports": [
                                {
                                    "Id": "EAT",
                                    "Name": "Wenatchee",
                                    "CityId": "EATA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-120.20778, 47.399722"
                                }
                            ]
                        },
                        {
                            "Id": "EAUA",
                            "Name": "Eau Claire",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-91.498494, 44.811349",
                            "IataCode": "EAU",
                            "Airports": [
                                {
                                    "Id": "EAU",
                                    "Name": "Eau Claire",
                                    "CityId": "EAUA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-91.487222, 44.864444"
                                }
                            ]
                        },
                        {
                            "Id": "EFKA",
                            "Name": "Newport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.205102, 44.936436",
                            "IataCode": "EFK",
                            "Airports": [
                                {
                                    "Id": "EFK",
                                    "Name": "Newport",
                                    "CityId": "EFKA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.23, 44.889444"
                                }
                            ]
                        },
                        {
                            "Id": "ECGA",
                            "Name": "Elizabeth City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-76.251046, 36.294601",
                            "IataCode": "ECG",
                            "Airports": [
                                {
                                    "Id": "ECG",
                                    "Name": "Elizabeth City",
                                    "CityId": "ECGA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-76.173056, 36.259167"
                                }
                            ]
                        },
                        {
                            "Id": "ELKA",
                            "Name": "Elk City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.404259, 35.411994",
                            "IataCode": "ELK",
                            "Airports": [
                                {
                                    "Id": "ELK",
                                    "Name": "Elk City",
                                    "CityId": "ELKA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.416667, 35.416667"
                                }
                            ]
                        },
                        {
                            "Id": "ELNA",
                            "Name": "Ellensburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-120.54785, 46.996514",
                            "IataCode": "ELN",
                            "Airports": [
                                {
                                    "Id": "ELN",
                                    "Name": "Ellensburg",
                                    "CityId": "ELNA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-120.53146, 47.03374"
                                }
                            ]
                        },
                        {
                            "Id": "EGPA",
                            "Name": "Eagle Pass",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.49952, 28.709143",
                            "IataCode": "EGP",
                            "Airports": [
                                {
                                    "Id": "EGP",
                                    "Name": "Eagle Pass",
                                    "CityId": "EGPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.52276, 28.85956"
                                }
                            ]
                        },
                        {
                            "Id": "EGVA",
                            "Name": "Eagle River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.244299, 45.917176",
                            "IataCode": "EGV",
                            "Airports": [
                                {
                                    "Id": "EGV",
                                    "Name": "Eagle River",
                                    "CityId": "EGVA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.265833, 45.929167"
                                }
                            ]
                        },
                        {
                            "Id": "EGXA",
                            "Name": "Egegik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.37583, 58.215556",
                            "IataCode": "EGX",
                            "Airports": [
                                {
                                    "Id": "EGX",
                                    "Name": "Egegik",
                                    "CityId": "EGXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.3752, 58.187719"
                                }
                            ]
                        },
                        {
                            "Id": "ELPA",
                            "Name": "El Paso",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-106.48693, 31.758721",
                            "IataCode": "ELP",
                            "Airports": [
                                {
                                    "Id": "BIF",
                                    "Name": "El Paso Biggs AAF",
                                    "CityId": "ELPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-106.38026, 31.84955"
                                },
                                {
                                    "Id": "ELP",
                                    "Name": "El Paso International",
                                    "CityId": "ELPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-106.39861, 31.801111"
                                }
                            ]
                        },
                        {
                            "Id": "EHTA",
                            "Name": "East Hartford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-72.612035, 41.782322",
                            "IataCode": "EHT",
                            "Airports": [
                                {
                                    "Id": "EHT",
                                    "Name": "East Hartford",
                                    "CityId": "EHTA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.65, 41.766667"
                                }
                            ]
                        },
                        {
                            "Id": "EKAA",
                            "Name": "Eureka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-124.16367, 40.802071",
                            "IataCode": "EKA",
                            "Airports": [
                                {
                                    "Id": "EKA",
                                    "Name": "Eureka",
                                    "CityId": "EKAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-124.115, 40.805"
                                }
                            ]
                        },
                        {
                            "Id": "EKIA",
                            "Name": "Elkhart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.976667, 41.681993",
                            "IataCode": "EKI",
                            "Airports": [
                                {
                                    "Id": "EKI",
                                    "Name": "Elkhart",
                                    "CityId": "EKIA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.992222, 41.722222"
                                }
                            ]
                        },
                        {
                            "Id": "EKNA",
                            "Name": "Elkins",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-79.846735, 38.92594",
                            "IataCode": "EKN",
                            "Airports": [
                                {
                                    "Id": "EKN",
                                    "Name": "Elkins",
                                    "CityId": "EKNA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-79.857778, 38.889444"
                                }
                            ]
                        },
                        {
                            "Id": "EKOA",
                            "Name": "Elko",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.76312, 40.832421",
                            "IataCode": "EKO",
                            "Airports": [
                                {
                                    "Id": "EKO",
                                    "Name": "Elko",
                                    "CityId": "EKOA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.78972, 40.823889"
                                }
                            ]
                        },
                        {
                            "Id": "EKXA",
                            "Name": "Elizabethtown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-85.859129, 37.693952",
                            "IataCode": "EKX",
                            "Airports": [
                                {
                                    "Id": "EKX",
                                    "Name": "Elizabethtown",
                                    "CityId": "EKXA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.927778, 37.688889"
                                }
                            ]
                        },
                        {
                            "Id": "ELDA",
                            "Name": "El Dorado",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.666267, 33.20763",
                            "IataCode": "ELD",
                            "Airports": [
                                {
                                    "Id": "ELD",
                                    "Name": "El Dorado",
                                    "CityId": "ELDA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.814722, 33.220556"
                                }
                            ]
                        },
                        {
                            "Id": "ELIA",
                            "Name": "Elim",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.26056, 64.6175",
                            "IataCode": "ELI",
                            "Airports": [
                                {
                                    "Id": "ELI",
                                    "Name": "Elim",
                                    "CityId": "ELIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.27028, 64.613611"
                                }
                            ]
                        },
                        {
                            "Id": "ELVA",
                            "Name": "Elfin Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-136.34333, 58.194444",
                            "IataCode": "ELV",
                            "Airports": [
                                {
                                    "Id": "ELV",
                                    "Name": "Elfin Cove",
                                    "CityId": "ELVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-136.31667, 58.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ELYA",
                            "Name": "Ely",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-114.88863, 39.247439",
                            "IataCode": "ELY",
                            "Airports": [
                                {
                                    "Id": "ELY",
                                    "Name": "Ely",
                                    "CityId": "ELYA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-114.84194, 39.301944"
                                }
                            ]
                        },
                        {
                            "Id": "ELZA",
                            "Name": "Wellsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-77.948057, 42.122012",
                            "IataCode": "ELZ",
                            "Airports": [
                                {
                                    "Id": "ELZ",
                                    "Name": "Wellsville",
                                    "CityId": "ELZA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-77.99167, 42.10951"
                                }
                            ]
                        },
                        {
                            "Id": "EFWA",
                            "Name": "Jefferson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.377468, 42.015261",
                            "IataCode": "EFW",
                            "Airports": [
                                {
                                    "Id": "EFW",
                                    "Name": "Jefferson",
                                    "CityId": "EFWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.383333, 42.016667"
                                }
                            ]
                        },
                        {
                            "Id": "EMKA",
                            "Name": "Emmonak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.52306, 62.777778",
                            "IataCode": "EMK",
                            "Airports": [
                                {
                                    "Id": "EMK",
                                    "Name": "Emmonak",
                                    "CityId": "EMKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.52083, 62.776944"
                                }
                            ]
                        },
                        {
                            "Id": "EMPA",
                            "Name": "Emporia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-96.181663, 38.403903",
                            "IataCode": "EMP",
                            "Airports": [
                                {
                                    "Id": "EMP",
                                    "Name": "Emporia",
                                    "CityId": "EMPA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-96.190833, 38.3325"
                                }
                            ]
                        },
                        {
                            "Id": "EMTA",
                            "Name": "El Monte",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.02757, 34.068621",
                            "IataCode": "EMT",
                            "Airports": [
                                {
                                    "Id": "EMT",
                                    "Name": "El Monte",
                                    "CityId": "EMTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.03333, 34.083333"
                                }
                            ]
                        },
                        {
                            "Id": "ENAA",
                            "Name": "Kenai",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.25833, 60.554444",
                            "IataCode": "ENA",
                            "Airports": [
                                {
                                    "Id": "ENA",
                                    "Name": "Kenai",
                                    "CityId": "ENAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.25111, 60.5675"
                                }
                            ]
                        },
                        {
                            "Id": "ENNA",
                            "Name": "Nenana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.09306, 64.563889",
                            "IataCode": "ENN",
                            "Airports": [
                                {
                                    "Id": "ENN",
                                    "Name": "Nenana",
                                    "CityId": "ENNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.07333, 64.54889"
                                }
                            ]
                        },
                        {
                            "Id": "ENVA",
                            "Name": "Wendover",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-114.03751, 40.737152",
                            "IataCode": "ENV",
                            "Airports": [
                                {
                                    "Id": "ENV",
                                    "Name": "Wendover",
                                    "CityId": "ENVA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-114.03333, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "ENWA",
                            "Name": "Kenosha",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.821185, 42.584742",
                            "IataCode": "ENW",
                            "Airports": [
                                {
                                    "Id": "ENW",
                                    "Name": "Kenosha",
                                    "CityId": "ENWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.816667, 42.583333"
                                }
                            ]
                        },
                        {
                            "Id": "EOKA",
                            "Name": "Keokuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.384874, 40.397266",
                            "IataCode": "EOK",
                            "Airports": [
                                {
                                    "Id": "EOK",
                                    "Name": "Keokuk",
                                    "CityId": "EOKA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.43, 40.458333"
                                }
                            ]
                        },
                        {
                            "Id": "EOSA",
                            "Name": "Neosho",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.367996, 36.868955",
                            "IataCode": "EOS",
                            "Airports": [
                                {
                                    "Id": "EOS",
                                    "Name": "Neosho",
                                    "CityId": "EOSA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.386077, 36.811518"
                                }
                            ]
                        },
                        {
                            "Id": "EPHA",
                            "Name": "Ephrata",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-119.55365, 47.317638",
                            "IataCode": "EPH",
                            "Airports": [
                                {
                                    "Id": "EPH",
                                    "Name": "Ephrata",
                                    "CityId": "EPHA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.5125, 47.304167"
                                }
                            ]
                        },
                        {
                            "Id": "ERIA",
                            "Name": "Erie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-80.085059, 42.129224",
                            "IataCode": "ERI",
                            "Airports": [
                                {
                                    "Id": "ERI",
                                    "Name": "Erie",
                                    "CityId": "ERIA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.178611, 42.08"
                                }
                            ]
                        },
                        {
                            "Id": "EUEA",
                            "Name": "Eureka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.96061, 39.512707",
                            "IataCode": "EUE",
                            "Airports": [
                                {
                                    "Id": "EUE",
                                    "Name": "Eureka",
                                    "CityId": "EUEA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-116.00506, 39.59993"
                                }
                            ]
                        },
                        {
                            "Id": "EUFA",
                            "Name": "Eufaula",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.145488, 31.891272",
                            "IataCode": "EUF",
                            "Airports": [
                                {
                                    "Id": "EUF",
                                    "Name": "Eufaula",
                                    "CityId": "EUFA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.13743, 31.94599"
                                }
                            ]
                        },
                        {
                            "Id": "EUGA",
                            "Name": "Eugene",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.08675, 44.052071",
                            "IataCode": "EUG",
                            "Airports": [
                                {
                                    "Id": "EUG",
                                    "Name": "Eugene",
                                    "CityId": "EUGA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.2175, 44.120833"
                                }
                            ]
                        },
                        {
                            "Id": "ENLA",
                            "Name": "Centralia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.133404, 38.525049",
                            "IataCode": "ENL",
                            "Airports": [
                                {
                                    "Id": "ENL",
                                    "Name": "Centralia",
                                    "CityId": "ENLA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.09201, 38.51477"
                                }
                            ]
                        },
                        {
                            "Id": "EVVA",
                            "Name": "Evansville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.555848, 37.974764",
                            "IataCode": "EVV",
                            "Airports": [
                                {
                                    "Id": "EVV",
                                    "Name": "Evansville",
                                    "CityId": "EVVA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.5325, 38.036667"
                                }
                            ]
                        },
                        {
                            "Id": "EWKA",
                            "Name": "Newton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.345037, 38.046678",
                            "IataCode": "EWK",
                            "Airports": [
                                {
                                    "Id": "EWK",
                                    "Name": "Newton",
                                    "CityId": "EWKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.278099, 38.056202"
                                }
                            ]
                        },
                        {
                            "Id": "EWNA",
                            "Name": "New Bern",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.044114, 35.108493",
                            "IataCode": "EWN",
                            "Airports": [
                                {
                                    "Id": "EWN",
                                    "Name": "New Bern",
                                    "CityId": "EWNA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.0425, 35.073611"
                                }
                            ]
                        },
                        {
                            "Id": "ERVA",
                            "Name": "Kerrville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.140319, 30.047433",
                            "IataCode": "ERV",
                            "Airports": [
                                {
                                    "Id": "ERV",
                                    "Name": "Kerrville",
                                    "CityId": "ERVA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.085007, 29.984379"
                                }
                            ]
                        },
                        {
                            "Id": "ESCA",
                            "Name": "Escanaba",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-87.06458, 45.745247",
                            "IataCode": "ESC",
                            "Airports": [
                                {
                                    "Id": "ESC",
                                    "Name": "Escanaba",
                                    "CityId": "ESCA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-87.086667, 45.720556"
                                }
                            ]
                        },
                        {
                            "Id": "ESNA",
                            "Name": "Easton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.07633, 38.774283",
                            "IataCode": "ESN",
                            "Airports": [
                                {
                                    "Id": "ESN",
                                    "Name": "Easton",
                                    "CityId": "ESNA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.067778, 38.802778"
                                }
                            ]
                        },
                        {
                            "Id": "ESWA",
                            "Name": "Easton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-121.17898, 47.23706",
                            "IataCode": "ESW",
                            "Airports": [
                                {
                                    "Id": "ESW",
                                    "Name": "Easton",
                                    "CityId": "ESWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-121.18333, 47.233333"
                                }
                            ]
                        },
                        {
                            "Id": "ETBA",
                            "Name": "West Bend",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.183428, 43.425278",
                            "IataCode": "ETB",
                            "Airports": [
                                {
                                    "Id": "ETB",
                                    "Name": "West Bend",
                                    "CityId": "ETBA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.12787, 43.42194"
                                }
                            ]
                        },
                        {
                            "Id": "ETSA",
                            "Name": "Enterprise",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.855216, 31.315171",
                            "IataCode": "ETS",
                            "Airports": [
                                {
                                    "Id": "ETS",
                                    "Name": "Enterprise",
                                    "CityId": "ETSA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.90188, 31.2985"
                                }
                            ]
                        },
                        {
                            "Id": "FCAA",
                            "Name": "Kalispell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-114.31291, 48.195792",
                            "IataCode": "FCA",
                            "Airports": [
                                {
                                    "Id": "FCA",
                                    "Name": "Kalispell",
                                    "CityId": "FCAA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-114.25266, 48.312885"
                                }
                            ]
                        },
                        {
                            "Id": "FATA",
                            "Name": "Fresno",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.77237, 36.747727",
                            "IataCode": "FAT",
                            "Airports": [
                                {
                                    "Id": "FCH",
                                    "Name": "Fresno Chandler Executive",
                                    "CityId": "FATA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.82007, 36.732143"
                                },
                                {
                                    "Id": "FAT",
                                    "Name": "Fresno Yosemite",
                                    "CityId": "FATA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.71361, 36.771111"
                                }
                            ]
                        },
                        {
                            "Id": "MSPA",
                            "Name": "Minneapolis",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.263836, 44.979965",
                            "IataCode": "MSP",
                            "Airports": [
                                {
                                    "Id": "STP",
                                    "Name": "Minneapolis Downtown",
                                    "CityId": "MSPA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.066667, 44.933333"
                                },
                                {
                                    "Id": "FCM",
                                    "Name": "Minneapolis Flying Cloud",
                                    "CityId": "MSPA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.45717, 44.82719"
                                },
                                {
                                    "Id": "MIC",
                                    "Name": "Minneapolis Crystal",
                                    "CityId": "MSPA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.353889, 45.061944"
                                },
                                {
                                    "Id": "JDT",
                                    "Name": "Minneapolis Downtown Heliport",
                                    "CityId": "MSPA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.266667, 44.983333"
                                },
                                {
                                    "Id": "MSP",
                                    "Name": "Minneapolis St Paul",
                                    "CityId": "MSPA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.217778, 44.886111"
                                }
                            ]
                        },
                        {
                            "Id": "FDKA",
                            "Name": "Frederick",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-77.410541, 39.414269",
                            "IataCode": "FDK",
                            "Airports": [
                                {
                                    "Id": "FDK",
                                    "Name": "Frederick",
                                    "CityId": "FDKA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-77.416667, 39.416667"
                                }
                            ]
                        },
                        {
                            "Id": "FDRA",
                            "Name": "Frederick",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.018409, 34.392026",
                            "IataCode": "FDR",
                            "Airports": [
                                {
                                    "Id": "FDR",
                                    "Name": "Frederick",
                                    "CityId": "FDRA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-98.9848, 34.35203"
                                }
                            ]
                        },
                        {
                            "Id": "FDYA",
                            "Name": "Findlay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-83.649932, 41.04422",
                            "IataCode": "FDY",
                            "Airports": [
                                {
                                    "Id": "FDY",
                                    "Name": "Findlay",
                                    "CityId": "FDYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.666639, 41.016045"
                                }
                            ]
                        },
                        {
                            "Id": "FAMA",
                            "Name": "Farmington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-90.42179, 37.780885",
                            "IataCode": "FAM",
                            "Airports": [
                                {
                                    "Id": "FAM",
                                    "Name": "Farmington",
                                    "CityId": "FAMA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.428681, 37.761809"
                                }
                            ]
                        },
                        {
                            "Id": "FARA",
                            "Name": "Fargo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-96.789804, 46.877186",
                            "IataCode": "FAR",
                            "Airports": [
                                {
                                    "Id": "FAR",
                                    "Name": "Fargo",
                                    "CityId": "FARA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-96.814722, 46.915556"
                                }
                            ]
                        },
                        {
                            "Id": "FAYA",
                            "Name": "Fayetteville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-78.878359, 35.052664",
                            "IataCode": "FAY",
                            "Airports": [
                                {
                                    "Id": "POB",
                                    "Name": "Fayetteville Pope AFB",
                                    "CityId": "FAYA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.01447, 35.17088"
                                },
                                {
                                    "Id": "FAY",
                                    "Name": "Fayetteville Municipal",
                                    "CityId": "FAYA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-78.881111, 34.989444"
                                }
                            ]
                        },
                        {
                            "Id": "FOBA",
                            "Name": "Fort Bragg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-123.80529, 39.445723",
                            "IataCode": "FOB",
                            "Airports": [
                                {
                                    "Id": "FOB",
                                    "Name": "Fort Bragg",
                                    "CityId": "FOBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-123.79444, 39.474444"
                                }
                            ]
                        },
                        {
                            "Id": "FETA",
                            "Name": "Fremont",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-96.498081, 41.433333",
                            "IataCode": "FET",
                            "Airports": [
                                {
                                    "Id": "FET",
                                    "Name": "Fremont",
                                    "CityId": "FETA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.516953, 41.446072"
                                }
                            ]
                        },
                        {
                            "Id": "FMSA",
                            "Name": "Fort Madison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.315151, 40.629763",
                            "IataCode": "FMS",
                            "Airports": [
                                {
                                    "Id": "FMS",
                                    "Name": "Fort Madison",
                                    "CityId": "FMSA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.3275, 40.658889"
                                }
                            ]
                        },
                        {
                            "Id": "FFAA",
                            "Name": "Kill Devil Hills",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-75.67601, 36.030723",
                            "IataCode": "FFA",
                            "Airports": [
                                {
                                    "Id": "FFA",
                                    "Name": "Kill Devil Hills",
                                    "CityId": "FFAA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-75.666667, 36.016667"
                                }
                            ]
                        },
                        {
                            "Id": "FFLA",
                            "Name": "Fairfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.962673, 41.008631",
                            "IataCode": "FFL",
                            "Airports": [
                                {
                                    "Id": "FFL",
                                    "Name": "Fairfield",
                                    "CityId": "FFLA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.980801, 41.051395"
                                }
                            ]
                        },
                        {
                            "Id": "FFMA",
                            "Name": "Fergus Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-96.077558, 46.283015",
                            "IataCode": "FFM",
                            "Airports": [
                                {
                                    "Id": "FFM",
                                    "Name": "Fergus Falls",
                                    "CityId": "FFMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-96.066667, 46.283333"
                                }
                            ]
                        },
                        {
                            "Id": "FFTA",
                            "Name": "Frankfort",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-84.873284, 38.200905",
                            "IataCode": "FFT",
                            "Airports": [
                                {
                                    "Id": "FFT",
                                    "Name": "Frankfort",
                                    "CityId": "FFTA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-84.905, 38.182222"
                                }
                            ]
                        },
                        {
                            "Id": "FILA",
                            "Name": "Fillmore",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.32355, 38.968852",
                            "IataCode": "FIL",
                            "Airports": [
                                {
                                    "Id": "FIL",
                                    "Name": "Fillmore",
                                    "CityId": "FILA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.3625, 38.958333"
                                }
                            ]
                        },
                        {
                            "Id": "FKLA",
                            "Name": "Franklin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.831444, 41.397836",
                            "IataCode": "FKL",
                            "Airports": [
                                {
                                    "Id": "FKL",
                                    "Name": "Franklin",
                                    "CityId": "FKLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.859722, 41.379167"
                                }
                            ]
                        },
                        {
                            "Id": "FKNA",
                            "Name": "Franklin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-76.922461, 36.677651",
                            "IataCode": "FKN",
                            "Airports": [
                                {
                                    "Id": "FKN",
                                    "Name": "Franklin",
                                    "CityId": "FKNA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.90302, 36.69848"
                                }
                            ]
                        },
                        {
                            "Id": "FLDA",
                            "Name": "Fond Du Lac",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.438826, 43.774998",
                            "IataCode": "FLD",
                            "Airports": [
                                {
                                    "Id": "FLD",
                                    "Name": "Fond Du Lac",
                                    "CityId": "FLDA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.484444, 43.771667"
                                }
                            ]
                        },
                        {
                            "Id": "FLLA",
                            "Name": "Fort Lauderdale",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.142827, 26.141341",
                            "IataCode": "FLL",
                            "Airports": [
                                {
                                    "Id": "FLL",
                                    "Name": "Fort Lauderdale International",
                                    "CityId": "FLLA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.151667, 26.074167"
                                },
                                {
                                    "Id": "FXE",
                                    "Name": "Fort Lauderdale Executive",
                                    "CityId": "FLLA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.17032, 26.19758"
                                }
                            ]
                        },
                        {
                            "Id": "FLOA",
                            "Name": "Florence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.762563, 34.195433",
                            "IataCode": "FLO",
                            "Airports": [
                                {
                                    "Id": "FLO",
                                    "Name": "Florence",
                                    "CityId": "FLOA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.724444, 34.188889"
                                }
                            ]
                        },
                        {
                            "Id": "FLPA",
                            "Name": "Flippin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.597108, 36.278958",
                            "IataCode": "FLP",
                            "Airports": [
                                {
                                    "Id": "FLP",
                                    "Name": "Flippin",
                                    "CityId": "FLPA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.586765, 36.292368"
                                }
                            ]
                        },
                        {
                            "Id": "FLTA",
                            "Name": "Flat",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.0075, 62.453611",
                            "IataCode": "FLT",
                            "Airports": [
                                {
                                    "Id": "FLT",
                                    "Name": "Flat",
                                    "CityId": "FLTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.98667, 62.454167"
                                }
                            ]
                        },
                        {
                            "Id": "NFLA",
                            "Name": "Fallon",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-118.77737, 39.473529",
                            "IataCode": "NFL",
                            "Airports": [
                                {
                                    "Id": "NFL",
                                    "Name": "Fallon NAS",
                                    "CityId": "NFLA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-118.75098, 39.49992"
                                },
                                {
                                    "Id": "FLX",
                                    "Name": "Fallon Municipal",
                                    "CityId": "NFLA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-118.78333, 39.466667"
                                }
                            ]
                        },
                        {
                            "Id": "FMHA",
                            "Name": "Falmouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.614752, 41.551498",
                            "IataCode": "FMH",
                            "Airports": [
                                {
                                    "Id": "FMH",
                                    "Name": "Falmouth",
                                    "CityId": "FMHA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.5642, 41.6715"
                                }
                            ]
                        },
                        {
                            "Id": "FMNA",
                            "Name": "Farmington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-108.21869, 36.728058",
                            "IataCode": "FMN",
                            "Airports": [
                                {
                                    "Id": "FMN",
                                    "Name": "Farmington",
                                    "CityId": "FMNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-108.2301, 36.741085"
                                }
                            ]
                        },
                        {
                            "Id": "FMYA",
                            "Name": "Fort Myers",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.840591, 26.62168",
                            "IataCode": "FMY",
                            "Airports": [
                                {
                                    "Id": "FMY",
                                    "Name": "Fort Myers Page Field",
                                    "CityId": "FMYA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.866667, 26.583333"
                                },
                                {
                                    "Id": "RSW",
                                    "Name": "Fort Myers Southwest Florida Reg",
                                    "CityId": "FMYA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.755167, 26.536167"
                                }
                            ]
                        },
                        {
                            "Id": "FNTA",
                            "Name": "Flint",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.687456, 43.012529",
                            "IataCode": "FNT",
                            "Airports": [
                                {
                                    "Id": "FNT",
                                    "Name": "Flint",
                                    "CityId": "FNTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.741667, 42.966667"
                                }
                            ]
                        },
                        {
                            "Id": "FODA",
                            "Name": "Fort Dodge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.168016, 42.497469",
                            "IataCode": "FOD",
                            "Airports": [
                                {
                                    "Id": "FOD",
                                    "Name": "Fort Dodge",
                                    "CityId": "FODA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.189722, 42.5525"
                                }
                            ]
                        },
                        {
                            "Id": "TOPA",
                            "Name": "Topeka",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.678036, 39.048334",
                            "IataCode": "TOP",
                            "Airports": [
                                {
                                    "Id": "FOE",
                                    "Name": "Topeka Forbes AFB",
                                    "CityId": "TOPA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.6625, 38.9525"
                                },
                                {
                                    "Id": "TOP",
                                    "Name": "Topeka Philip Billard",
                                    "CityId": "TOPA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.6225, 39.069722"
                                }
                            ]
                        },
                        {
                            "Id": "FPRA",
                            "Name": "Fort Pierce",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.325606, 27.446706",
                            "IataCode": "FPR",
                            "Airports": [
                                {
                                    "Id": "FPR",
                                    "Name": "Fort Pierce",
                                    "CityId": "FPRA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.36783, 27.49532"
                                }
                            ]
                        },
                        {
                            "Id": "FPYA",
                            "Name": "Perry",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-83.581815, 30.117435",
                            "IataCode": "FPY",
                            "Airports": [
                                {
                                    "Id": "FPY",
                                    "Name": "Perry-Foley",
                                    "CityId": "FPYA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-83.57361, 30.07194"
                                }
                            ]
                        },
                        {
                            "Id": "FRGA",
                            "Name": "Farmingdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.445401, 40.7326",
                            "IataCode": "FRG",
                            "Airports": [
                                {
                                    "Id": "FRG",
                                    "Name": "Farmingdale",
                                    "CityId": "FRGA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.416667, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "FRHA",
                            "Name": "French Lick",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.619991, 38.548943",
                            "IataCode": "FRH",
                            "Airports": [
                                {
                                    "Id": "FRH",
                                    "Name": "French Lick",
                                    "CityId": "FRHA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.63527, 38.50644"
                                }
                            ]
                        },
                        {
                            "Id": "FRMA",
                            "Name": "Fairmont",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.461083, 43.652177",
                            "IataCode": "FRM",
                            "Airports": [
                                {
                                    "Id": "FRM",
                                    "Name": "Fairmont",
                                    "CityId": "FRMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.423611, 43.647222"
                                }
                            ]
                        },
                        {
                            "Id": "FRNA",
                            "Name": "Fort Richardson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.68833, 61.254444",
                            "IataCode": "FRN",
                            "Airports": [
                                {
                                    "Id": "FRN",
                                    "Name": "Fort Richardson",
                                    "CityId": "FRNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.65444, 61.2625"
                                }
                            ]
                        },
                        {
                            "Id": "FRYA",
                            "Name": "Fryeburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-70.980624, 44.016459",
                            "IataCode": "FRY",
                            "Airports": [
                                {
                                    "Id": "FRY",
                                    "Name": "Fryeburg",
                                    "CityId": "FRYA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-70.983333, 44.016667"
                                }
                            ]
                        },
                        {
                            "Id": "FSDA",
                            "Name": "Sioux Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-96.700327, 43.549977",
                            "IataCode": "FSD",
                            "Airports": [
                                {
                                    "Id": "FSD",
                                    "Name": "Sioux Falls",
                                    "CityId": "FSDA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-96.740833, 43.578889"
                                }
                            ]
                        },
                        {
                            "Id": "FSKA",
                            "Name": "Fort Scott",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-94.708295, 37.839763",
                            "IataCode": "FSK",
                            "Airports": [
                                {
                                    "Id": "FSK",
                                    "Name": "Fort Scott",
                                    "CityId": "FSKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.766607, 37.79266"
                                }
                            ]
                        },
                        {
                            "Id": "FSMA",
                            "Name": "Fort Smith",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.398547, 35.385924",
                            "IataCode": "FSM",
                            "Airports": [
                                {
                                    "Id": "FSM",
                                    "Name": "Fort Smith",
                                    "CityId": "FSMA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.37, 35.336111"
                                }
                            ]
                        },
                        {
                            "Id": "FSTA",
                            "Name": "Fort Stockton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-102.87932, 30.894043",
                            "IataCode": "FST",
                            "Airports": [
                                {
                                    "Id": "FST",
                                    "Name": "Fort Stockton",
                                    "CityId": "FSTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.87889, 30.893889"
                                }
                            ]
                        },
                        {
                            "Id": "FSUA",
                            "Name": "Fort Sumner",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-104.24553, 34.471733",
                            "IataCode": "FSU",
                            "Airports": [
                                {
                                    "Id": "FSU",
                                    "Name": "Fort Sumner",
                                    "CityId": "FSUA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-104.25, 34.466667"
                                }
                            ]
                        },
                        {
                            "Id": "FTKA",
                            "Name": "Fort Knox",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-85.96363, 37.89113",
                            "IataCode": "FTK",
                            "Airports": [
                                {
                                    "Id": "FTK",
                                    "Name": "Fort Knox",
                                    "CityId": "FTKA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.967245, 37.906215"
                                }
                            ]
                        },
                        {
                            "Id": "GSHA",
                            "Name": "Goshen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.834438, 41.582272",
                            "IataCode": "GSH",
                            "Airports": [
                                {
                                    "Id": "GSH",
                                    "Name": "Goshen",
                                    "CityId": "GSHA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.833333, 41.583333"
                                }
                            ]
                        },
                        {
                            "Id": "ATLA",
                            "Name": "Atlanta",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.387983, 33.748995",
                            "IataCode": "ATL",
                            "Airports": [
                                {
                                    "Id": "JGL",
                                    "Name": "Atlanta Galleria",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.466944, 33.886111"
                                },
                                {
                                    "Id": "PDK",
                                    "Name": "Atlanta De Kalb / Peachtree",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.30456, 33.87737"
                                },
                                {
                                    "Id": "JAJ",
                                    "Name": "Atlanta Perimeter Mall",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.421667, 33.635278"
                                },
                                {
                                    "Id": "FTY",
                                    "Name": "Atlanta Fulton County",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.516667, 33.783333"
                                },
                                {
                                    "Id": "JAO",
                                    "Name": "Atlanta Beaver Ruin",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.432778, 33.646389"
                                },
                                {
                                    "Id": "ATL",
                                    "Name": "Atlanta Hartsfield-Jackson",
                                    "CityId": "ATLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.427222, 33.640833"
                                }
                            ]
                        },
                        {
                            "Id": "FULA",
                            "Name": "Fullerton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.92534, 33.870293",
                            "IataCode": "FUL",
                            "Airports": [
                                {
                                    "Id": "FUL",
                                    "Name": "Fullerton",
                                    "CityId": "FULA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.975, 33.866667"
                                }
                            ]
                        },
                        {
                            "Id": "FWAA",
                            "Name": "Fort Wayne",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.12886, 41.130604",
                            "IataCode": "FWA",
                            "Airports": [
                                {
                                    "Id": "SMD",
                                    "Name": "Fort Wayne Smith Field",
                                    "CityId": "FWAA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.15, 41.066667"
                                },
                                {
                                    "Id": "FWA",
                                    "Name": "Fort Wayne",
                                    "CityId": "FWAA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.1881, 40.9783"
                                }
                            ]
                        },
                        {
                            "Id": "FXYA",
                            "Name": "Forest City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.637159, 43.262456",
                            "IataCode": "FXY",
                            "Airports": [
                                {
                                    "Id": "FXY",
                                    "Name": "Forest City",
                                    "CityId": "FXYA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.6241, 43.23468"
                                }
                            ]
                        },
                        {
                            "Id": "FYUA",
                            "Name": "Fort Yukon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.27389, 66.564722",
                            "IataCode": "FYU",
                            "Airports": [
                                {
                                    "Id": "FYU",
                                    "Name": "Fort Yukon",
                                    "CityId": "FYUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.20833, 66.581111"
                                }
                            ]
                        },
                        {
                            "Id": "GABA",
                            "Name": "Gabbs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-117.92206, 38.868813",
                            "IataCode": "GAB",
                            "Airports": [
                                {
                                    "Id": "GAB",
                                    "Name": "Gabbs",
                                    "CityId": "GABA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-117.91667, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "GADB",
                            "Name": "Gadsden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.006639, 34.014264",
                            "IataCode": "GAD",
                            "Airports": [
                                {
                                    "Id": "GAD",
                                    "Name": "Gadsden",
                                    "CityId": "GADB",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.087223, 33.973611"
                                }
                            ]
                        },
                        {
                            "Id": "GAIA",
                            "Name": "Gaithersburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-77.20137, 39.143441",
                            "IataCode": "GAI",
                            "Airports": [
                                {
                                    "Id": "GAI",
                                    "Name": "Gaithersburg",
                                    "CityId": "GAIA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-77.2, 39.15"
                                }
                            ]
                        },
                        {
                            "Id": "GALA",
                            "Name": "Galena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.9275, 64.733333",
                            "IataCode": "GAL",
                            "Airports": [
                                {
                                    "Id": "GAL",
                                    "Name": "Galena",
                                    "CityId": "GALA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.93167, 64.736389"
                                }
                            ]
                        },
                        {
                            "Id": "GAMA",
                            "Name": "Gambell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-171.74111, 63.779722",
                            "IataCode": "GAM",
                            "Airports": [
                                {
                                    "Id": "GAM",
                                    "Name": "Gambell",
                                    "CityId": "GAMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-171.7375, 63.766667"
                                }
                            ]
                        },
                        {
                            "Id": "HDEA",
                            "Name": "Holdrege",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.369822, 40.440288",
                            "IataCode": "HDE",
                            "Airports": [
                                {
                                    "Id": "HDE",
                                    "Name": "Holdrege",
                                    "CityId": "HDEA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.383333, 40.433333"
                                }
                            ]
                        },
                        {
                            "Id": "GBDA",
                            "Name": "Great Bend",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-98.764807, 38.364457",
                            "IataCode": "GBD",
                            "Airports": [
                                {
                                    "Id": "GBD",
                                    "Name": "Great Bend",
                                    "CityId": "GBDA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-98.864167, 38.347222"
                                }
                            ]
                        },
                        {
                            "Id": "GBGA",
                            "Name": "Galesburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.37124, 40.947816",
                            "IataCode": "GBG",
                            "Airports": [
                                {
                                    "Id": "GBG",
                                    "Name": "Galesburg",
                                    "CityId": "GBGA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.428889, 40.938611"
                                }
                            ]
                        },
                        {
                            "Id": "GBRA",
                            "Name": "Great Barrington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-73.362056, 42.195922",
                            "IataCode": "GBR",
                            "Airports": [
                                {
                                    "Id": "GBR",
                                    "Name": "Great Barrington",
                                    "CityId": "GBRA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-73.366667, 42.2"
                                }
                            ]
                        },
                        {
                            "Id": "GCCA",
                            "Name": "Gillette",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-105.50222, 44.291091",
                            "IataCode": "GCC",
                            "Airports": [
                                {
                                    "Id": "GCC",
                                    "Name": "Gillette",
                                    "CityId": "GCCA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-105.54222, 44.348159"
                                }
                            ]
                        },
                        {
                            "Id": "GCKA",
                            "Name": "Garden City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-100.87266, 37.97169",
                            "IataCode": "GCK",
                            "Airports": [
                                {
                                    "Id": "GCK",
                                    "Name": "Garden City",
                                    "CityId": "GCKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-100.72972, 37.928611"
                                }
                            ]
                        },
                        {
                            "Id": "GCYA",
                            "Name": "Greenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-82.830986, 36.163157",
                            "IataCode": "GCY",
                            "Airports": [
                                {
                                    "Id": "GCY",
                                    "Name": "Greenville",
                                    "CityId": "GCYA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-82.812023, 36.192339"
                                }
                            ]
                        },
                        {
                            "Id": "GSPA",
                            "Name": "Greenville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-82.39401, 34.852618",
                            "IataCode": "GSP",
                            "Airports": [
                                {
                                    "Id": "GMU",
                                    "Name": "Greenville Downtown",
                                    "CityId": "GSPA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.349997, 34.848114"
                                },
                                {
                                    "Id": "GDC",
                                    "Name": "Greenville Donaldson Center",
                                    "CityId": "GSPA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.37651, 34.75845"
                                },
                                {
                                    "Id": "GSP",
                                    "Name": "Greenville-Spartanburg",
                                    "CityId": "GSPA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.21859, 34.896069"
                                }
                            ]
                        },
                        {
                            "Id": "GDMA",
                            "Name": "Gardner",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.998133, 42.575088",
                            "IataCode": "GDM",
                            "Airports": [
                                {
                                    "Id": "GDM",
                                    "Name": "Gardner",
                                    "CityId": "GDMA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.01447, 42.5497"
                                }
                            ]
                        },
                        {
                            "Id": "GDVA",
                            "Name": "Glendive",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-104.71246, 47.10529",
                            "IataCode": "GDV",
                            "Airports": [
                                {
                                    "Id": "GDV",
                                    "Name": "Glendive",
                                    "CityId": "GDVA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-104.80614, 47.138826"
                                }
                            ]
                        },
                        {
                            "Id": "GDWA",
                            "Name": "Gladwin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.486401, 43.980855",
                            "IataCode": "GDW",
                            "Airports": [
                                {
                                    "Id": "GDW",
                                    "Name": "Gladwin",
                                    "CityId": "GDWA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.483333, 43.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GEDA",
                            "Name": "Georgetown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "DELAW",
                            "Location": "-75.385473, 38.690113",
                            "IataCode": "GED",
                            "Airports": [
                                {
                                    "Id": "GED",
                                    "Name": "Georgetown",
                                    "CityId": "GEDA",
                                    "CountryId": "US",
                                    "RegionId": "DELAW",
                                    "Location": "-75.3575, 38.688333"
                                }
                            ]
                        },
                        {
                            "Id": "HNBA",
                            "Name": "Huntingburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.954997, 38.298942",
                            "IataCode": "HNB",
                            "Airports": [
                                {
                                    "Id": "HNB",
                                    "Name": "Huntingburg",
                                    "CityId": "HNBA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.95, 38.3"
                                }
                            ]
                        },
                        {
                            "Id": "GEGA",
                            "Name": "Spokane",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-117.42605, 47.65878",
                            "IataCode": "GEG",
                            "Airports": [
                                {
                                    "Id": "SKA",
                                    "Name": "Spokane Fairchild AFB",
                                    "CityId": "GEGA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-117.65577, 47.61506"
                                },
                                {
                                    "Id": "SFF",
                                    "Name": "Spokane Felts Field",
                                    "CityId": "GEGA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-117.32243, 47.68267"
                                },
                                {
                                    "Id": "GEG",
                                    "Name": "Spokane International",
                                    "CityId": "GEGA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-117.53722, 47.616944"
                                }
                            ]
                        },
                        {
                            "Id": "GTFA",
                            "Name": "Great Falls",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-111.30081, 47.500235",
                            "IataCode": "GTF",
                            "Airports": [
                                {
                                    "Id": "GFA",
                                    "Name": "Great Falls Malmstrom AFB",
                                    "CityId": "GTFA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.18859, 47.50468"
                                },
                                {
                                    "Id": "GTF",
                                    "Name": "Great Falls International",
                                    "CityId": "GTFA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.36189, 47.484265"
                                }
                            ]
                        },
                        {
                            "Id": "GFDA",
                            "Name": "Greenfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.769423, 39.785043",
                            "IataCode": "GFD",
                            "Airports": [
                                {
                                    "Id": "GFD",
                                    "Name": "Greenfield",
                                    "CityId": "GFDA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.766667, 39.783333"
                                }
                            ]
                        },
                        {
                            "Id": "GFKA",
                            "Name": "Grand Forks",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-97.032855, 47.925257",
                            "IataCode": "GFK",
                            "Airports": [
                                {
                                    "Id": "GFK",
                                    "Name": "Grand Forks",
                                    "CityId": "GFKA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-97.178611, 47.951944"
                                }
                            ]
                        },
                        {
                            "Id": "GFLA",
                            "Name": "Glens Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.644006, 43.309516",
                            "IataCode": "GFL",
                            "Airports": [
                                {
                                    "Id": "GFL",
                                    "Name": "Glens Falls",
                                    "CityId": "GFLA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.609167, 43.344444"
                                }
                            ]
                        },
                        {
                            "Id": "GGEA",
                            "Name": "Georgetown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.294497, 33.376834",
                            "IataCode": "GGE",
                            "Airports": [
                                {
                                    "Id": "GGE",
                                    "Name": "Georgetown",
                                    "CityId": "GGEA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.283333, 33.383333"
                                }
                            ]
                        },
                        {
                            "Id": "GGGA",
                            "Name": "Longview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.740489, 32.500704",
                            "IataCode": "GGG",
                            "Airports": [
                                {
                                    "Id": "GGG",
                                    "Name": "East Texas Regional",
                                    "CityId": "GGGA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.715278, 32.386667"
                                }
                            ]
                        },
                        {
                            "Id": "GGWA",
                            "Name": "Glasgow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-106.63671, 48.196964",
                            "IataCode": "GGW",
                            "Airports": [
                                {
                                    "Id": "GGW",
                                    "Name": "Glasgow",
                                    "CityId": "GGWA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-106.61613, 48.212091"
                                }
                            ]
                        },
                        {
                            "Id": "GHMA",
                            "Name": "Centerville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-87.466959, 35.778956",
                            "IataCode": "GHM",
                            "Airports": [
                                {
                                    "Id": "GHM",
                                    "Name": "Centerville",
                                    "CityId": "GHMA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-87.466667, 35.783333"
                                }
                            ]
                        },
                        {
                            "Id": "GIFA",
                            "Name": "Winter Haven",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.732857, 28.022243",
                            "IataCode": "GIF",
                            "Airports": [
                                {
                                    "Id": "GIF",
                                    "Name": "Winter Haven",
                                    "CityId": "GIFA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.75, 28.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GJTA",
                            "Name": "Grand Junction",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-108.55065, 39.06387",
                            "IataCode": "GJT",
                            "Airports": [
                                {
                                    "Id": "GJT",
                                    "Name": "Grand Junction",
                                    "CityId": "GJTA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-108.51505, 39.118608"
                                }
                            ]
                        },
                        {
                            "Id": "GKNA",
                            "Name": "Gulkana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.38222, 62.271389",
                            "IataCode": "GKN",
                            "Airports": [
                                {
                                    "Id": "GKN",
                                    "Name": "Gulkana",
                                    "CityId": "GKNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.45222, 62.155278"
                                }
                            ]
                        },
                        {
                            "Id": "GLDA",
                            "Name": "Goodland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-101.71017, 39.350833",
                            "IataCode": "GLD",
                            "Airports": [
                                {
                                    "Id": "GLD",
                                    "Name": "Goodland",
                                    "CityId": "GLDA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-101.69944, 39.370278"
                                }
                            ]
                        },
                        {
                            "Id": "GLEA",
                            "Name": "Gainesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.133345, 33.625941",
                            "IataCode": "GLE",
                            "Airports": [
                                {
                                    "Id": "GLE",
                                    "Name": "Gainesville",
                                    "CityId": "GLEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.19529, 33.64927"
                                }
                            ]
                        },
                        {
                            "Id": "GLHA",
                            "Name": "Greenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-91.061774, 33.410116",
                            "IataCode": "GLH",
                            "Airports": [
                                {
                                    "Id": "GLH",
                                    "Name": "Greenville",
                                    "CityId": "GLHA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.985, 33.484444"
                                }
                            ]
                        },
                        {
                            "Id": "GLRA",
                            "Name": "Gaylord",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.674752, 45.027513",
                            "IataCode": "GLR",
                            "Airports": [
                                {
                                    "Id": "GLR",
                                    "Name": "Gaylord",
                                    "CityId": "GLRA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.666667, 45.033333"
                                }
                            ]
                        },
                        {
                            "Id": "GLSA",
                            "Name": "Galveston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.797696, 29.301348",
                            "IataCode": "GLS",
                            "Airports": [
                                {
                                    "Id": "GLS",
                                    "Name": "Galveston",
                                    "CityId": "GLSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.860278, 29.2675"
                                }
                            ]
                        },
                        {
                            "Id": "GLVA",
                            "Name": "Golovin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.02917, 64.543333",
                            "IataCode": "GLV",
                            "Airports": [
                                {
                                    "Id": "GLV",
                                    "Name": "Golovin",
                                    "CityId": "GLVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.0375, 64.544167"
                                }
                            ]
                        },
                        {
                            "Id": "GLWA",
                            "Name": "Glasgow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-85.911922, 36.995884",
                            "IataCode": "GLW",
                            "Airports": [
                                {
                                    "Id": "GLW",
                                    "Name": "Glasgow",
                                    "CityId": "GLWA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.95248, 37.03199"
                                }
                            ]
                        },
                        {
                            "Id": "HWIA",
                            "Name": "Hawk Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.75306, 58.125833",
                            "IataCode": "HWI",
                            "Airports": [
                                {
                                    "Id": "HWI",
                                    "Name": "Hawk Inlet",
                                    "CityId": "HWIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.75417, 58.117778"
                                }
                            ]
                        },
                        {
                            "Id": "GNVA",
                            "Name": "Gainesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.324826, 29.651634",
                            "IataCode": "GNV",
                            "Airports": [
                                {
                                    "Id": "GNV",
                                    "Name": "Gainesville",
                                    "CityId": "GNVA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.275833, 29.689722"
                                }
                            ]
                        },
                        {
                            "Id": "GOKA",
                            "Name": "Guthrie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.425319, 35.878937",
                            "IataCode": "GOK",
                            "Airports": [
                                {
                                    "Id": "GOK",
                                    "Name": "Guthrie",
                                    "CityId": "GOKA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.416667, 35.883333"
                                }
                            ]
                        },
                        {
                            "Id": "IMLA",
                            "Name": "Imperial",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-101.64323, 40.51694",
                            "IataCode": "IML",
                            "Airports": [
                                {
                                    "Id": "IML",
                                    "Name": "Imperial",
                                    "CityId": "IMLA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-101.65, 40.516667"
                                }
                            ]
                        },
                        {
                            "Id": "GOLA",
                            "Name": "Gold Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-124.42177, 42.407333",
                            "IataCode": "GOL",
                            "Airports": [
                                {
                                    "Id": "GOL",
                                    "Name": "Gold Beach",
                                    "CityId": "GOLA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-124.41667, 42.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GONA",
                            "Name": "Groton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-72.078409, 41.350098",
                            "IataCode": "GON",
                            "Airports": [
                                {
                                    "Id": "GON",
                                    "Name": "Groton-New London",
                                    "CityId": "GONA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.046389, 41.328889"
                                }
                            ]
                        },
                        {
                            "Id": "JCTA",
                            "Name": "Junction",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.772011, 30.489355",
                            "IataCode": "JCT",
                            "Airports": [
                                {
                                    "Id": "JCT",
                                    "Name": "Junction",
                                    "CityId": "JCTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.766667, 30.483333"
                                }
                            ]
                        },
                        {
                            "Id": "GPTA",
                            "Name": "Gulfport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.092815, 30.36742",
                            "IataCode": "GPT",
                            "Airports": [
                                {
                                    "Id": "GPT",
                                    "Name": "Gulfport",
                                    "CityId": "GPTA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.070278, 30.406111"
                                }
                            ]
                        },
                        {
                            "Id": "GPZA",
                            "Name": "Grand Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.530214, 47.237166",
                            "IataCode": "GPZ",
                            "Airports": [
                                {
                                    "Id": "GPZ",
                                    "Name": "Grand Rapids",
                                    "CityId": "GPZA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.511111, 47.214444"
                                }
                            ]
                        },
                        {
                            "Id": "GQQA",
                            "Name": "Galion",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.789903, 40.733669",
                            "IataCode": "GQQ",
                            "Airports": [
                                {
                                    "Id": "GQQ",
                                    "Name": "Galion",
                                    "CityId": "GQQA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.757222, 40.736667"
                                }
                            ]
                        },
                        {
                            "Id": "GRBA",
                            "Name": "Green Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.019826, 44.519159",
                            "IataCode": "GRB",
                            "Airports": [
                                {
                                    "Id": "GRB",
                                    "Name": "Green Bay",
                                    "CityId": "GRBA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.130556, 44.488056"
                                }
                            ]
                        },
                        {
                            "Id": "GRDA",
                            "Name": "Greenwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-82.161788, 34.1954",
                            "IataCode": "GRD",
                            "Airports": [
                                {
                                    "Id": "GRD",
                                    "Name": "Greenwood",
                                    "CityId": "GRDA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.158333, 34.251389"
                                }
                            ]
                        },
                        {
                            "Id": "GREA",
                            "Name": "Greenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.413136, 38.892269",
                            "IataCode": "GRE",
                            "Airports": [
                                {
                                    "Id": "GRE",
                                    "Name": "Greenville",
                                    "CityId": "GREA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.416667, 38.883333"
                                }
                            ]
                        },
                        {
                            "Id": "GRIA",
                            "Name": "Grand Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-98.342007, 40.925012",
                            "IataCode": "GRI",
                            "Airports": [
                                {
                                    "Id": "GRI",
                                    "Name": "Grand Island",
                                    "CityId": "GRIA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-98.306667, 40.967222"
                                }
                            ]
                        },
                        {
                            "Id": "GRRA",
                            "Name": "Grand Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.668087, 42.96336",
                            "IataCode": "GRR",
                            "Airports": [
                                {
                                    "Id": "GRR",
                                    "Name": "Grand Rapids",
                                    "CityId": "GRRA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.524167, 42.881111"
                                }
                            ]
                        },
                        {
                            "Id": "GSBA",
                            "Name": "Goldsboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.992765, 35.384884",
                            "IataCode": "GSB",
                            "Airports": [
                                {
                                    "Id": "GSB",
                                    "Name": "Goldsboro",
                                    "CityId": "GSBA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.96059, 35.33938"
                                }
                            ]
                        },
                        {
                            "Id": "GSOA",
                            "Name": "Greensboro / High Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.791976, 36.072637",
                            "IataCode": "GSO",
                            "Airports": [
                                {
                                    "Id": "GSO",
                                    "Name": "Piedmont Triad International",
                                    "CityId": "GSOA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.942778, 36.091944"
                                }
                            ]
                        },
                        {
                            "Id": "GSTA",
                            "Name": "Gustavus",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.73694, 58.413333",
                            "IataCode": "GST",
                            "Airports": [
                                {
                                    "Id": "GST",
                                    "Name": "Gustavus Arpt",
                                    "CityId": "GSTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.705, 58.4275"
                                },
                                {
                                    "Id": "BQV",
                                    "Name": "Gustavus Bartlett SPB",
                                    "CityId": "GSTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.73333, 58.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GTGA",
                            "Name": "Grantsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-92.682704, 45.776342",
                            "IataCode": "GTG",
                            "Airports": [
                                {
                                    "Id": "GTG",
                                    "Name": "Grantsburg",
                                    "CityId": "GTGA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-92.66437, 45.79856"
                                }
                            ]
                        },
                        {
                            "Id": "GTYA",
                            "Name": "Gettysburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.231095, 39.830929",
                            "IataCode": "GTY",
                            "Airports": [
                                {
                                    "Id": "GTY",
                                    "Name": "Gettysburg",
                                    "CityId": "GTYA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.233333, 39.833333"
                                }
                            ]
                        },
                        {
                            "Id": "GUCA",
                            "Name": "Gunnison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.92532, 38.545825",
                            "IataCode": "GUC",
                            "Airports": [
                                {
                                    "Id": "GUC",
                                    "Name": "Gunnison",
                                    "CityId": "GUCA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.93989, 38.532859"
                                }
                            ]
                        },
                        {
                            "Id": "GUFA",
                            "Name": "Gulf Shores",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.700819, 30.246036",
                            "IataCode": "GUF",
                            "Airports": [
                                {
                                    "Id": "GUF",
                                    "Name": "Gulf Shores",
                                    "CityId": "GUFA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.583333, 30.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GUPA",
                            "Name": "Gallup",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-108.74258, 35.528078",
                            "IataCode": "GUP",
                            "Airports": [
                                {
                                    "Id": "GUP",
                                    "Name": "Gallup",
                                    "CityId": "GUPA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-108.78799, 35.51099"
                                }
                            ]
                        },
                        {
                            "Id": "GUSA",
                            "Name": "Peru",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.068881, 40.753653",
                            "IataCode": "GUS",
                            "Airports": [
                                {
                                    "Id": "GUS",
                                    "Name": "Peru",
                                    "CityId": "GUSA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.152222, 40.648056"
                                }
                            ]
                        },
                        {
                            "Id": "GUYA",
                            "Name": "Guymon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-101.48155, 36.682804",
                            "IataCode": "GUY",
                            "Airports": [
                                {
                                    "Id": "GUY",
                                    "Name": "Guymon",
                                    "CityId": "GUYA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-101.50778, 36.682778"
                                }
                            ]
                        },
                        {
                            "Id": "GVLA",
                            "Name": "Gainesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.824066, 34.297879",
                            "IataCode": "GVL",
                            "Airports": [
                                {
                                    "Id": "GVL",
                                    "Name": "Gainesville",
                                    "CityId": "GVLA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.83205, 34.27574"
                                }
                            ]
                        },
                        {
                            "Id": "GVTA",
                            "Name": "Greenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.110807, 33.138449",
                            "IataCode": "GVT",
                            "Airports": [
                                {
                                    "Id": "GVT",
                                    "Name": "Greenville",
                                    "CityId": "GVTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.061667, 33.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GWOA",
                            "Name": "Greenwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.179528, 33.516231",
                            "IataCode": "GWO",
                            "Airports": [
                                {
                                    "Id": "GWO",
                                    "Name": "Greenwood",
                                    "CityId": "GWOA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.088889, 33.495"
                                }
                            ]
                        },
                        {
                            "Id": "GXYA",
                            "Name": "Greeley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-104.70913, 40.423314",
                            "IataCode": "GXY",
                            "Airports": [
                                {
                                    "Id": "GXY",
                                    "Name": "Greeley",
                                    "CityId": "GXYA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.7, 40.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GYRA",
                            "Name": "Goodyear",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.35821, 33.43532",
                            "IataCode": "GYR",
                            "Airports": [
                                {
                                    "Id": "GYR",
                                    "Name": "Goodyear",
                                    "CityId": "GYRA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.37444, 33.428056"
                                }
                            ]
                        },
                        {
                            "Id": "HABA",
                            "Name": "Hamilton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.988644, 34.142323",
                            "IataCode": "HAB",
                            "Airports": [
                                {
                                    "Id": "HAB",
                                    "Name": "Hamilton",
                                    "CityId": "HABA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-88.1, 34.15"
                                }
                            ]
                        },
                        {
                            "Id": "HAIA",
                            "Name": "Three Rivers",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.632493, 41.943937",
                            "IataCode": "HAI",
                            "Airports": [
                                {
                                    "Id": "HAI",
                                    "Name": "Three Rivers",
                                    "CityId": "HAIA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.633333, 41.95"
                                }
                            ]
                        },
                        {
                            "Id": "HAOA",
                            "Name": "Hamilton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.561336, 39.399501",
                            "IataCode": "HAO",
                            "Airports": [
                                {
                                    "Id": "HAO",
                                    "Name": "Hamilton",
                                    "CityId": "HAOA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.566667, 39.4"
                                }
                            ]
                        },
                        {
                            "Id": "HARA",
                            "Name": "Harrisburg",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-76.884418, 40.2737",
                            "IataCode": "HAR",
                            "Airports": [
                                {
                                    "Id": "HAR",
                                    "Name": "Harrisburg Skyport",
                                    "CityId": "HARA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.852222, 40.216111"
                                },
                                {
                                    "Id": "MDT",
                                    "Name": "Harrisburg International",
                                    "CityId": "HARA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.762222, 40.193056"
                                }
                            ]
                        },
                        {
                            "Id": "MKOA",
                            "Name": "Muskogee",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.369691, 35.747877",
                            "IataCode": "MKO",
                            "Airports": [
                                {
                                    "Id": "MKO",
                                    "Name": "Muskogee Davis Field",
                                    "CityId": "MKOA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.366667, 35.660556"
                                },
                                {
                                    "Id": "HAX",
                                    "Name": "Muskogee Hatbox Field",
                                    "CityId": "MKOA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.416667, 35.75"
                                }
                            ]
                        },
                        {
                            "Id": "HBGA",
                            "Name": "Hattiesburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.290339, 31.327119",
                            "IataCode": "HBG",
                            "Airports": [
                                {
                                    "Id": "HBG",
                                    "Name": "Hattiesburg",
                                    "CityId": "HBGA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.266667, 31.316667"
                                }
                            ]
                        },
                        {
                            "Id": "HBRA",
                            "Name": "Hobart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.093132, 35.029501",
                            "IataCode": "HBR",
                            "Airports": [
                                {
                                    "Id": "HBR",
                                    "Name": "Hobart",
                                    "CityId": "HBRA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.05146, 34.99117"
                                }
                            ]
                        },
                        {
                            "Id": "HCCA",
                            "Name": "Hudson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.790959, 42.252865",
                            "IataCode": "HCC",
                            "Airports": [
                                {
                                    "Id": "HCC",
                                    "Name": "Hudson",
                                    "CityId": "HCCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.710556, 42.293056"
                                }
                            ]
                        },
                        {
                            "Id": "HCRA",
                            "Name": "Holy Cross",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.77139, 62.199444",
                            "IataCode": "HCR",
                            "Airports": [
                                {
                                    "Id": "HCR",
                                    "Name": "Holy Cross",
                                    "CityId": "HCRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.77167, 62.188889"
                                }
                            ]
                        },
                        {
                            "Id": "HCWA",
                            "Name": "Cheraw",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-79.883397, 34.697656",
                            "IataCode": "HCW",
                            "Airports": [
                                {
                                    "Id": "HCW",
                                    "Name": "Cheraw",
                                    "CityId": "HCWA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.883333, 34.7"
                                },
                                {
                                    "Id": "CQW",
                                    "Name": "Cheraw Municipal Airport",
                                    "CityId": "HCWA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-79.956944, 34.712778"
                                }
                            ]
                        },
                        {
                            "Id": "HDNA",
                            "Name": "Hayden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.25756, 40.491918",
                            "IataCode": "HDN",
                            "Airports": [
                                {
                                    "Id": "HDN",
                                    "Name": "Hayden",
                                    "CityId": "HDNA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.22176, 40.482471"
                                }
                            ]
                        },
                        {
                            "Id": "HESA",
                            "Name": "Hermiston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-119.28946, 45.84041",
                            "IataCode": "HES",
                            "Airports": [
                                {
                                    "Id": "HES",
                                    "Name": "Hermiston",
                                    "CityId": "HESA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-119.28333, 45.85"
                                }
                            ]
                        },
                        {
                            "Id": "HEZA",
                            "Name": "Natchez",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-91.403171, 31.560444",
                            "IataCode": "HEZ",
                            "Airports": [
                                {
                                    "Id": "HEZ",
                                    "Name": "Natchez",
                                    "CityId": "HEZA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-91.296389, 31.614722"
                                }
                            ]
                        },
                        {
                            "Id": "HFDA",
                            "Name": "Hartford",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-72.685094, 41.763711",
                            "IataCode": "HFD",
                            "Airports": [
                                {
                                    "Id": "HFD",
                                    "Name": "Hartford Brainard",
                                    "CityId": "HFDA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.65, 41.733056"
                                },
                                {
                                    "Id": "BNH",
                                    "Name": "Hartford Barnes",
                                    "CityId": "HFDA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.683333, 41.766667"
                                },
                                {
                                    "Id": "BDL",
                                    "Name": "Hartford Bradley International",
                                    "CityId": "HFDA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.683333, 41.938611"
                                }
                            ]
                        },
                        {
                            "Id": "HGRA",
                            "Name": "Hagerstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-77.719993, 39.641763",
                            "IataCode": "HGR",
                            "Airports": [
                                {
                                    "Id": "HGR",
                                    "Name": "Hagerstown",
                                    "CityId": "HGRA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-77.73, 39.707778"
                                }
                            ]
                        },
                        {
                            "Id": "HHIA",
                            "Name": "Wahiawa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-158.02361, 21.502778",
                            "IataCode": "HHI",
                            "Airports": [
                                {
                                    "Id": "HHI",
                                    "Name": "Wahiawa",
                                    "CityId": "HHIA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-158.03972, 21.48472"
                                }
                            ]
                        },
                        {
                            "Id": "HHRA",
                            "Name": "Hawthorne",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.35257, 33.916403",
                            "IataCode": "HHR",
                            "Airports": [
                                {
                                    "Id": "HHR",
                                    "Name": "Hawthorne",
                                    "CityId": "HHRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.33639, 33.926944"
                                }
                            ]
                        },
                        {
                            "Id": "HIBA",
                            "Name": "Hibbing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-92.937689, 47.427155",
                            "IataCode": "HIB",
                            "Airports": [
                                {
                                    "Id": "HIB",
                                    "Name": "Hibbing",
                                    "CityId": "HIBA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.838611, 47.388611"
                                }
                            ]
                        },
                        {
                            "Id": "HIEA",
                            "Name": "Whitefield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.610084, 44.373116",
                            "IataCode": "HIE",
                            "Airports": [
                                {
                                    "Id": "HIE",
                                    "Name": "Whitefield",
                                    "CityId": "HIEA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.548611, 44.365833"
                                }
                            ]
                        },
                        {
                            "Id": "OGDA",
                            "Name": "Ogden",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.97383, 41.223",
                            "IataCode": "OGD",
                            "Airports": [
                                {
                                    "Id": "OGD",
                                    "Name": "Ogden Municipal",
                                    "CityId": "OGDA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.01083, 41.196389"
                                },
                                {
                                    "Id": "HIF",
                                    "Name": "Ogden Hill AFB",
                                    "CityId": "OGDA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.96667, 41.216667"
                                }
                            ]
                        },
                        {
                            "Id": "HIIA",
                            "Name": "Lake Havasu City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-114.32246, 34.483901",
                            "IataCode": "HII",
                            "Airports": [
                                {
                                    "Id": "HII",
                                    "Name": "Lake Havasu City",
                                    "CityId": "HIIA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-114.35833, 34.571111"
                                }
                            ]
                        },
                        {
                            "Id": "HNLA",
                            "Name": "Honolulu",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-157.81208, 21.322371",
                            "IataCode": "HNL",
                            "Airports": [
                                {
                                    "Id": "HNL",
                                    "Name": "Honolulu International",
                                    "CityId": "HNLA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-157.92167, 21.325833"
                                }
                            ]
                        },
                        {
                            "Id": "PDXA",
                            "Name": "Portland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-122.67621, 45.523454",
                            "IataCode": "PDX",
                            "Airports": [
                                {
                                    "Id": "PDX",
                                    "Name": "Portland",
                                    "CityId": "PDXA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-122.595, 45.589167"
                                }
                            ]
                        },
                        {
                            "Id": "HKBA",
                            "Name": "Healy Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.96611, 63.856944",
                            "IataCode": "HKB",
                            "Airports": [
                                {
                                    "Id": "HKB",
                                    "Name": "Healy Lake",
                                    "CityId": "HKBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.96667, 63.866667"
                                }
                            ]
                        },
                        {
                            "Id": "JANA",
                            "Name": "Jackson",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.184811, 32.298757",
                            "IataCode": "JAN",
                            "Airports": [
                                {
                                    "Id": "HKS",
                                    "Name": "Jackson Hawkins Field",
                                    "CityId": "JANA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.22203, 32.33431"
                                },
                                {
                                    "Id": "JAN",
                                    "Name": "Jackson-Evers International",
                                    "CityId": "JANA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.075188, 32.314121"
                                }
                            ]
                        },
                        {
                            "Id": "HKYA",
                            "Name": "Hickory",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-81.341197, 35.733188",
                            "IataCode": "HKY",
                            "Airports": [
                                {
                                    "Id": "HKY",
                                    "Name": "Hickory",
                                    "CityId": "HKYA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-81.391667, 35.738611"
                                }
                            ]
                        },
                        {
                            "Id": "HLCA",
                            "Name": "Hill City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-99.842065, 39.364728",
                            "IataCode": "HLC",
                            "Airports": [
                                {
                                    "Id": "HLC",
                                    "Name": "Hill City",
                                    "CityId": "HLCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-99.85, 39.366667"
                                }
                            ]
                        },
                        {
                            "Id": "HLGA",
                            "Name": "Wheeling",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-80.720915, 40.063962",
                            "IataCode": "HLG",
                            "Airports": [
                                {
                                    "Id": "HLG",
                                    "Name": "Wheeling",
                                    "CityId": "HLGA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-80.65, 40.183333"
                                }
                            ]
                        },
                        {
                            "Id": "HLIA",
                            "Name": "Hollister",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.4016, 36.852454",
                            "IataCode": "HLI",
                            "Airports": [
                                {
                                    "Id": "HLI",
                                    "Name": "Hollister",
                                    "CityId": "HLIA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.4094, 36.891154"
                                }
                            ]
                        },
                        {
                            "Id": "HLMA",
                            "Name": "Holland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.10893, 42.787523",
                            "IataCode": "HLM",
                            "Airports": [
                                {
                                    "Id": "HLM",
                                    "Name": "Holland",
                                    "CityId": "HLMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.16199, 42.79586"
                                }
                            ]
                        },
                        {
                            "Id": "HLNA",
                            "Name": "Helena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-112.03611, 46.592712",
                            "IataCode": "HLN",
                            "Airports": [
                                {
                                    "Id": "HLN",
                                    "Name": "Helena",
                                    "CityId": "HLNA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-111.98124, 46.605582"
                                }
                            ]
                        },
                        {
                            "Id": "HMTA",
                            "Name": "Hemet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.97197, 33.74752",
                            "IataCode": "HMT",
                            "Airports": [
                                {
                                    "Id": "HMT",
                                    "Name": "Hemet",
                                    "CityId": "HMTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.025, 33.733333"
                                }
                            ]
                        },
                        {
                            "Id": "HNHA",
                            "Name": "Hoonah",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.44361, 58.11",
                            "IataCode": "HNH",
                            "Airports": [
                                {
                                    "Id": "HNH",
                                    "Name": "Hoonah",
                                    "CityId": "HNHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.40333, 58.098056"
                                }
                            ]
                        },
                        {
                            "Id": "HNMA",
                            "Name": "Hana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.99028, 20.758056",
                            "IataCode": "HNM",
                            "Airports": [
                                {
                                    "Id": "HNM",
                                    "Name": "Hana",
                                    "CityId": "HNMA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.01889, 20.795833"
                                }
                            ]
                        },
                        {
                            "Id": "HNSA",
                            "Name": "Haines",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.445, 59.235833",
                            "IataCode": "HNS",
                            "Airports": [
                                {
                                    "Id": "HNS",
                                    "Name": "Haines",
                                    "CityId": "HNSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.51889, 59.245833"
                                }
                            ]
                        },
                        {
                            "Id": "HOMA",
                            "Name": "Homer",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.54833, 59.6425",
                            "IataCode": "HOM",
                            "Airports": [
                                {
                                    "Id": "HOM",
                                    "Name": "Homer",
                                    "CityId": "HOMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.47639, 59.645278"
                                }
                            ]
                        },
                        {
                            "Id": "HONA",
                            "Name": "Huron",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-98.214257, 44.363317",
                            "IataCode": "HON",
                            "Airports": [
                                {
                                    "Id": "HON",
                                    "Name": "Huron",
                                    "CityId": "HONA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-98.226389, 44.383611"
                                }
                            ]
                        },
                        {
                            "Id": "HOTB",
                            "Name": "Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-93.055179, 34.5037",
                            "IataCode": "HOT",
                            "Airports": [
                                {
                                    "Id": "HOT",
                                    "Name": "Hot Springs",
                                    "CityId": "HOTB",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-93.096112, 34.478889"
                                }
                            ]
                        },
                        {
                            "Id": "HPBA",
                            "Name": "Hooper Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.09667, 61.531111",
                            "IataCode": "HPB",
                            "Airports": [
                                {
                                    "Id": "HPB",
                                    "Name": "Hooper Bay",
                                    "CityId": "HPBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.14528, 61.525"
                                }
                            ]
                        },
                        {
                            "Id": "HPYA",
                            "Name": "Baytown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.977427, 29.735505",
                            "IataCode": "HPY",
                            "Airports": [
                                {
                                    "Id": "HPY",
                                    "Name": "Baytown",
                                    "CityId": "HPYA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.966667, 29.733333"
                                }
                            ]
                        },
                        {
                            "Id": "HRLA",
                            "Name": "Harlingen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.696103, 26.190631",
                            "IataCode": "HRL",
                            "Airports": [
                                {
                                    "Id": "HRL",
                                    "Name": "Harlingen",
                                    "CityId": "HRLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.65, 26.233333"
                                }
                            ]
                        },
                        {
                            "Id": "HROA",
                            "Name": "Harrison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-93.107676, 36.229794",
                            "IataCode": "HRO",
                            "Airports": [
                                {
                                    "Id": "HRO",
                                    "Name": "Harrison",
                                    "CityId": "HROA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-93.155556, 36.261667"
                                }
                            ]
                        },
                        {
                            "Id": "OKCA",
                            "Name": "Oklahoma City",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.516428, 35.46756",
                            "IataCode": "OKC",
                            "Airports": [
                                {
                                    "Id": "PWA",
                                    "Name": "Oklahoma City Wiley Post",
                                    "CityId": "OKCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.64726, 35.53422"
                                },
                                {
                                    "Id": "TIK",
                                    "Name": "Oklahoma City Tinker AFB",
                                    "CityId": "OKCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.383333, 35.416667"
                                },
                                {
                                    "Id": "OKC",
                                    "Name": "Oklahoma City Will Rogers World",
                                    "CityId": "OKCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.601667, 35.392222"
                                }
                            ]
                        },
                        {
                            "Id": "LASA",
                            "Name": "Las Vegas",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.2309, 36.168448",
                            "IataCode": "LAS",
                            "Airports": [
                                {
                                    "Id": "HSH",
                                    "Name": "Las Vegas Henderson Sky Harbor",
                                    "CityId": "LASA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.11917, 35.968333"
                                },
                                {
                                    "Id": "LSV",
                                    "Name": "Las Vegas Nellis AFB",
                                    "CityId": "LASA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.8, 36.3"
                                },
                                {
                                    "Id": "VGT",
                                    "Name": "Las Vegas North Air Terminal",
                                    "CityId": "LASA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.16889, 36.096667"
                                },
                                {
                                    "Id": "LAS",
                                    "Name": "Las Vegas Mccarran",
                                    "CityId": "LASA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.15225, 36.080056"
                                }
                            ]
                        },
                        {
                            "Id": "HSLA",
                            "Name": "Huslia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.39972, 65.698611",
                            "IataCode": "HSL",
                            "Airports": [
                                {
                                    "Id": "HSL",
                                    "Name": "Huslia",
                                    "CityId": "HSLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.375, 65.7025"
                                }
                            ]
                        },
                        {
                            "Id": "HSTA",
                            "Name": "Homestead",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.477557, 25.468722",
                            "IataCode": "HST",
                            "Airports": [
                                {
                                    "Id": "HST",
                                    "Name": "Homestead",
                                    "CityId": "HSTA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.483333, 25.483333"
                                }
                            ]
                        },
                        {
                            "Id": "HSVA",
                            "Name": "Huntsville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.586104, 34.730369",
                            "IataCode": "HSV",
                            "Airports": [
                                {
                                    "Id": "HUA",
                                    "Name": "Huntsville Redstone AAF",
                                    "CityId": "HSVA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.68333, 34.68342"
                                },
                                {
                                    "Id": "HSV",
                                    "Name": "Huntsville International",
                                    "CityId": "HSVA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.775556, 34.642778"
                                }
                            ]
                        },
                        {
                            "Id": "HTHA",
                            "Name": "Hawthorne",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-118.62458, 38.524644",
                            "IataCode": "HTH",
                            "Airports": [
                                {
                                    "Id": "HTH",
                                    "Name": "Hawthorne",
                                    "CityId": "HTHA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-118.63333, 38.533333"
                                }
                            ]
                        },
                        {
                            "Id": "HTOA",
                            "Name": "East Hampton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-72.184801, 40.963433",
                            "IataCode": "HTO",
                            "Airports": [
                                {
                                    "Id": "HTO",
                                    "Name": "East Hampton",
                                    "CityId": "HTOA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-72.251667, 40.959444"
                                }
                            ]
                        },
                        {
                            "Id": "HTSA",
                            "Name": "Huntington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-82.445154, 38.41925",
                            "IataCode": "HTS",
                            "Airports": [
                                {
                                    "Id": "HTS",
                                    "Name": "Tri-State",
                                    "CityId": "HTSA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-82.556111, 38.366944"
                                }
                            ]
                        },
                        {
                            "Id": "HTVA",
                            "Name": "Huntsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.550777, 30.723526",
                            "IataCode": "HTV",
                            "Airports": [
                                {
                                    "Id": "HTV",
                                    "Name": "Huntsville",
                                    "CityId": "HTVA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.55, 30.716667"
                                }
                            ]
                        },
                        {
                            "Id": "HUDA",
                            "Name": "Humboldt",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.21524, 42.720805",
                            "IataCode": "HUD",
                            "Airports": [
                                {
                                    "Id": "HUD",
                                    "Name": "Humboldt",
                                    "CityId": "HUDA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.24524, 42.73608"
                                }
                            ]
                        },
                        {
                            "Id": "HUFA",
                            "Name": "Terre Haute",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.413909, 39.466703",
                            "IataCode": "HUF",
                            "Airports": [
                                {
                                    "Id": "HUF",
                                    "Name": "Terre Haute",
                                    "CityId": "HUFA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.3075, 39.454444"
                                }
                            ]
                        },
                        {
                            "Id": "HUJA",
                            "Name": "Hugo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.509682, 34.01066",
                            "IataCode": "HUJ",
                            "Airports": [
                                {
                                    "Id": "HUJ",
                                    "Name": "Hugo",
                                    "CityId": "HUJA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.54191, 34.03483"
                                }
                            ]
                        },
                        {
                            "Id": "HULA",
                            "Name": "Houlton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-67.840296, 46.126164",
                            "IataCode": "HUL",
                            "Airports": [
                                {
                                    "Id": "HUL",
                                    "Name": "Houlton",
                                    "CityId": "HULA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-67.8, 46.133333"
                                }
                            ]
                        },
                        {
                            "Id": "HUMA",
                            "Name": "Houma",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-90.719535, 29.59577",
                            "IataCode": "HUM",
                            "Airports": [
                                {
                                    "Id": "HUM",
                                    "Name": "Houma",
                                    "CityId": "HUMA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-90.660833, 29.566944"
                                }
                            ]
                        },
                        {
                            "Id": "HUSB",
                            "Name": "Hughes",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.25556, 66.048889",
                            "IataCode": "HUS",
                            "Airports": [
                                {
                                    "Id": "HUS",
                                    "Name": "Hughes Municipal",
                                    "CityId": "HUSB",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.26333, 66.040556"
                                }
                            ]
                        },
                        {
                            "Id": "HUTA",
                            "Name": "Hutchinson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.929774, 38.060844",
                            "IataCode": "HUT",
                            "Airports": [
                                {
                                    "Id": "HUT",
                                    "Name": "Hutchinson",
                                    "CityId": "HUTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.861111, 38.068056"
                                }
                            ]
                        },
                        {
                            "Id": "HVEA",
                            "Name": "Hanksville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-110.71404, 38.373038",
                            "IataCode": "HVE",
                            "Airports": [
                                {
                                    "Id": "HVE",
                                    "Name": "Hanksville",
                                    "CityId": "HVEA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-110.71636, 38.360318"
                                }
                            ]
                        },
                        {
                            "Id": "HVNA",
                            "Name": "New Haven",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-72.928158, 41.308153",
                            "IataCode": "HVN",
                            "Airports": [
                                {
                                    "Id": "HVN",
                                    "Name": "New Haven",
                                    "CityId": "HVNA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.888333, 41.265278"
                                }
                            ]
                        },
                        {
                            "Id": "HVRA",
                            "Name": "Havre",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-109.68409, 48.549999",
                            "IataCode": "HVR",
                            "Airports": [
                                {
                                    "Id": "HVR",
                                    "Name": "Havre",
                                    "CityId": "HVRA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-109.76131, 48.542296"
                                }
                            ]
                        },
                        {
                            "Id": "HVSA",
                            "Name": "Hartsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.073401, 34.374043",
                            "IataCode": "HVS",
                            "Airports": [
                                {
                                    "Id": "HVS",
                                    "Name": "Hartsville",
                                    "CityId": "HVSA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.066667, 34.383333"
                                }
                            ]
                        },
                        {
                            "Id": "HWDA",
                            "Name": "Hayward",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.0808, 37.66882",
                            "IataCode": "HWD",
                            "Airports": [
                                {
                                    "Id": "HWD",
                                    "Name": "Hayward",
                                    "CityId": "HWDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.12394, 37.659974"
                                }
                            ]
                        },
                        {
                            "Id": "HWOA",
                            "Name": "Hollywood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.14949, 26.011201",
                            "IataCode": "HWO",
                            "Airports": [
                                {
                                    "Id": "HWO",
                                    "Name": "Hollywood",
                                    "CityId": "HWOA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.15, 26"
                                }
                            ]
                        },
                        {
                            "Id": "HYAA",
                            "Name": "Hyannis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.282799, 41.652889",
                            "IataCode": "HYA",
                            "Airports": [
                                {
                                    "Id": "HYA",
                                    "Name": "Hyannis",
                                    "CityId": "HYAA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.280556, 41.665833"
                                }
                            ]
                        },
                        {
                            "Id": "HYGA",
                            "Name": "Hydaburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.82667, 55.208056",
                            "IataCode": "HYG",
                            "Airports": [
                                {
                                    "Id": "HYG",
                                    "Name": "Hydaburg",
                                    "CityId": "HYGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.82667, 55.206667"
                                }
                            ]
                        },
                        {
                            "Id": "HYLA",
                            "Name": "Hollis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.63639, 55.556667",
                            "IataCode": "HYL",
                            "Airports": [
                                {
                                    "Id": "HYL",
                                    "Name": "Hollis",
                                    "CityId": "HYLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.64626, 55.48182"
                                }
                            ]
                        },
                        {
                            "Id": "EXIA",
                            "Name": "Excursion Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.42667, 58.418889",
                            "IataCode": "EXI",
                            "Airports": [
                                {
                                    "Id": "EXI",
                                    "Name": "Excursion Inlet",
                                    "CityId": "EXIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.44722, 58.420833"
                                }
                            ]
                        },
                        {
                            "Id": "HYRA",
                            "Name": "Hayward",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-91.484621, 46.013006",
                            "IataCode": "HYR",
                            "Airports": [
                                {
                                    "Id": "HYR",
                                    "Name": "Hayward",
                                    "CityId": "HYRA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-91.444444, 46.024444"
                                }
                            ]
                        },
                        {
                            "Id": "HYSA",
                            "Name": "Hays",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-99.32677, 38.879178",
                            "IataCode": "HYS",
                            "Airports": [
                                {
                                    "Id": "HYS",
                                    "Name": "Hays",
                                    "CityId": "HYSA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-99.274167, 38.846111"
                                }
                            ]
                        },
                        {
                            "Id": "HZLA",
                            "Name": "Hazleton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.974647, 40.958418",
                            "IataCode": "HZL",
                            "Airports": [
                                {
                                    "Id": "HZL",
                                    "Name": "Hazleton",
                                    "CityId": "HZLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.991111, 40.986389"
                                }
                            ]
                        },
                        {
                            "Id": "IAGA",
                            "Name": "Niagara Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-79.04131, 43.100988",
                            "IataCode": "IAG",
                            "Airports": [
                                {
                                    "Id": "IAG",
                                    "Name": "Niagara Falls",
                                    "CityId": "IAGA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-78.95, 43.1"
                                }
                            ]
                        },
                        {
                            "Id": "IANA",
                            "Name": "Kiana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.42278, 66.975",
                            "IataCode": "IAN",
                            "Airports": [
                                {
                                    "Id": "IAN",
                                    "Name": "Kiana",
                                    "CityId": "IANA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.43056, 66.975556"
                                }
                            ]
                        },
                        {
                            "Id": "ICLA",
                            "Name": "Clarinda",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.038313, 40.741935",
                            "IataCode": "ICL",
                            "Airports": [
                                {
                                    "Id": "ICL",
                                    "Name": "Clarinda",
                                    "CityId": "ICLA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.033333, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "IDAA",
                            "Name": "Idaho Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-112.03414, 43.466581",
                            "IataCode": "IDA",
                            "Airports": [
                                {
                                    "Id": "IDA",
                                    "Name": "Idaho Falls",
                                    "CityId": "IDAA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-112.07119, 43.515693"
                                }
                            ]
                        },
                        {
                            "Id": "IDGA",
                            "Name": "Ida Grove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.471667, 42.344987",
                            "IataCode": "IDG",
                            "Airports": [
                                {
                                    "Id": "IDG",
                                    "Name": "Ida Grove",
                                    "CityId": "IDGA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.466667, 42.35"
                                }
                            ]
                        },
                        {
                            "Id": "IDIA",
                            "Name": "Indiana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.152535, 40.621455",
                            "IataCode": "IDI",
                            "Airports": [
                                {
                                    "Id": "IDI",
                                    "Name": "Indiana",
                                    "CityId": "IDIA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.15, 40.616667"
                                }
                            ]
                        },
                        {
                            "Id": "IDPA",
                            "Name": "Independence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.708313, 37.224236",
                            "IataCode": "IDP",
                            "Airports": [
                                {
                                    "Id": "IDP",
                                    "Name": "Independence",
                                    "CityId": "IDPA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.773573, 37.154707"
                                }
                            ]
                        },
                        {
                            "Id": "IFAA",
                            "Name": "Iowa Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.251314, 42.52248",
                            "IataCode": "IFA",
                            "Airports": [
                                {
                                    "Id": "IFA",
                                    "Name": "Iowa Falls",
                                    "CityId": "IFAA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.266667, 42.516667"
                                }
                            ]
                        },
                        {
                            "Id": "IFPA",
                            "Name": "Bullhead City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-114.5683, 35.147777",
                            "IataCode": "IFP",
                            "Airports": [
                                {
                                    "Id": "IFP",
                                    "Name": "Bullhead City",
                                    "CityId": "IFPA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-114.55944, 35.156111"
                                }
                            ]
                        },
                        {
                            "Id": "IGGA",
                            "Name": "Igiugig",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.89472, 59.327778",
                            "IataCode": "IGG",
                            "Airports": [
                                {
                                    "Id": "IGG",
                                    "Name": "Igiugig",
                                    "CityId": "IGGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.9, 59.316667"
                                }
                            ]
                        },
                        {
                            "Id": "IGMA",
                            "Name": "Kingman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-114.05301, 35.189443",
                            "IataCode": "IGM",
                            "Airports": [
                                {
                                    "Id": "IGM",
                                    "Name": "Kingman",
                                    "CityId": "IGMA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-113.93806, 35.259444"
                                }
                            ]
                        },
                        {
                            "Id": "IJXA",
                            "Name": "Jacksonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.22901, 39.733936",
                            "IataCode": "IJX",
                            "Airports": [
                                {
                                    "Id": "IJX",
                                    "Name": "Jacksonville",
                                    "CityId": "IJXA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.237222, 39.774167"
                                }
                            ]
                        },
                        {
                            "Id": "IKKA",
                            "Name": "Kankakee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.861153, 41.120032",
                            "IataCode": "IKK",
                            "Airports": [
                                {
                                    "Id": "IKK",
                                    "Name": "Greater Kankakee",
                                    "CityId": "IKKA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.84831, 41.066734"
                                }
                            ]
                        },
                        {
                            "Id": "IKOA",
                            "Name": "Nikolski",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-168.86778, 52.938056",
                            "IataCode": "IKO",
                            "Airports": [
                                {
                                    "Id": "IKO",
                                    "Name": "Nikolski",
                                    "CityId": "IKOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-168.85, 52.941667"
                                }
                            ]
                        },
                        {
                            "Id": "ILEA",
                            "Name": "Killeen",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.727796, 31.117119",
                            "IataCode": "ILE",
                            "Airports": [
                                {
                                    "Id": "ILE",
                                    "Name": "Killeen Municipal",
                                    "CityId": "ILEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.686667, 31.086389"
                                },
                                {
                                    "Id": "HLR",
                                    "Name": "Killeen Fort Hood AAF",
                                    "CityId": "ILEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.733333, 31.116667"
                                },
                                {
                                    "Id": "GRK",
                                    "Name": "Killeen Gray AAF",
                                    "CityId": "ILEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.822952, 31.059846"
                                }
                            ]
                        },
                        {
                            "Id": "ILGA",
                            "Name": "Wilmington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "DELAW",
                            "Location": "-75.546589, 39.745947",
                            "IataCode": "ILG",
                            "Airports": [
                                {
                                    "Id": "ILG",
                                    "Name": "Greater Wilmington",
                                    "CityId": "ILGA",
                                    "CountryId": "US",
                                    "RegionId": "DELAW",
                                    "Location": "-75.6075, 39.678333"
                                }
                            ]
                        },
                        {
                            "Id": "ILIA",
                            "Name": "Iliamna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.87327, 59.79487",
                            "IataCode": "ILI",
                            "Airports": [
                                {
                                    "Id": "ILI",
                                    "Name": "Iliamna",
                                    "CityId": "ILIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.90833, 59.752778"
                                }
                            ]
                        },
                        {
                            "Id": "ILMA",
                            "Name": "Wilmington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.94471, 34.225726",
                            "IataCode": "ILM",
                            "Airports": [
                                {
                                    "Id": "ILM",
                                    "Name": "Wilmington",
                                    "CityId": "ILMA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.903889, 34.266944"
                                }
                            ]
                        },
                        {
                            "Id": "ILNA",
                            "Name": "Wilmington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-83.828537, 39.445339",
                            "IataCode": "ILN",
                            "Airports": [
                                {
                                    "Id": "ILN",
                                    "Name": "Wilmington",
                                    "CityId": "ILNA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.740833, 39.493056"
                                }
                            ]
                        },
                        {
                            "Id": "KCCA",
                            "Name": "Coffman Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.82778, 56.013889",
                            "IataCode": "KCC",
                            "Airports": [
                                {
                                    "Id": "KCC",
                                    "Name": "Coffman Cove",
                                    "CityId": "KCCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.83333, 55.983333"
                                }
                            ]
                        },
                        {
                            "Id": "KCLA",
                            "Name": "Chignik",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.40222, 56.295278",
                            "IataCode": "KCL",
                            "Airports": [
                                {
                                    "Id": "KCL",
                                    "Name": "Chignik Lagoon",
                                    "CityId": "KCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.53658, 56.311395"
                                },
                                {
                                    "Id": "KCQ",
                                    "Name": "Chignik Lake",
                                    "CityId": "KCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.7757, 56.255462"
                                },
                                {
                                    "Id": "KCG",
                                    "Name": "Chignik Fisheries",
                                    "CityId": "KCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.37361, 56.310833"
                                },
                                {
                                    "Id": "KBW",
                                    "Name": "Chignik Bay",
                                    "CityId": "KCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.38333, 56.283333"
                                }
                            ]
                        },
                        {
                            "Id": "IMMA",
                            "Name": "Immokalee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.417299, 26.41869",
                            "IataCode": "IMM",
                            "Airports": [
                                {
                                    "Id": "IMM",
                                    "Name": "Immokalee",
                                    "CityId": "IMMA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.416667, 26.416667"
                                }
                            ]
                        },
                        {
                            "Id": "INDA",
                            "Name": "Indianapolis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.158042, 39.768376",
                            "IataCode": "IND",
                            "Airports": [
                                {
                                    "Id": "IND",
                                    "Name": "Indianapolis",
                                    "CityId": "INDA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.281667, 39.728889"
                                }
                            ]
                        },
                        {
                            "Id": "INLA",
                            "Name": "International Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.410982, 48.601049",
                            "IataCode": "INL",
                            "Airports": [
                                {
                                    "Id": "INL",
                                    "Name": "International Falls",
                                    "CityId": "INLA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.404167, 48.563056"
                                }
                            ]
                        },
                        {
                            "Id": "INTB",
                            "Name": "Winston-Salem",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-80.244216, 36.09986",
                            "IataCode": "INT",
                            "Airports": [
                                {
                                    "Id": "INT",
                                    "Name": "Winston-Salem",
                                    "CityId": "INTB",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-80.2229, 36.1343"
                                }
                            ]
                        },
                        {
                            "Id": "INWA",
                            "Name": "Winslow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.69736, 35.024187",
                            "IataCode": "INW",
                            "Airports": [
                                {
                                    "Id": "INW",
                                    "Name": "Winslow",
                                    "CityId": "INWA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.7225, 35.021944"
                                }
                            ]
                        },
                        {
                            "Id": "IOWA",
                            "Name": "Iowa City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.530169, 41.661128",
                            "IataCode": "IOW",
                            "Airports": [
                                {
                                    "Id": "IOW",
                                    "Name": "Iowa City",
                                    "CityId": "IOWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.54628, 41.63918"
                                }
                            ]
                        },
                        {
                            "Id": "IPLA",
                            "Name": "Imperial",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.56944, 32.847553",
                            "IataCode": "IPL",
                            "Airports": [
                                {
                                    "Id": "NJK",
                                    "Name": "Imperial NAF",
                                    "CityId": "IPLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.57472, 32.837222"
                                },
                                {
                                    "Id": "IPL",
                                    "Name": "Imperial County",
                                    "CityId": "IPLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.57472, 32.837222"
                                }
                            ]
                        },
                        {
                            "Id": "IPTA",
                            "Name": "Williamsport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.001079, 41.24119",
                            "IataCode": "IPT",
                            "Airports": [
                                {
                                    "Id": "IPT",
                                    "Name": "Williamsport",
                                    "CityId": "IPTA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.920556, 41.242222"
                                }
                            ]
                        },
                        {
                            "Id": "IRCA",
                            "Name": "Circle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-144.06056, 65.825556",
                            "IataCode": "IRC",
                            "Airports": [
                                {
                                    "Id": "IRC",
                                    "Name": "Circle",
                                    "CityId": "IRCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-144.06389, 65.829167"
                                }
                            ]
                        },
                        {
                            "Id": "IRKA",
                            "Name": "Kirksville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.58325, 40.194754",
                            "IataCode": "IRK",
                            "Airports": [
                                {
                                    "Id": "IRK",
                                    "Name": "Kirksville",
                                    "CityId": "IRKA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.543889, 40.0925"
                                }
                            ]
                        },
                        {
                            "Id": "ORLB",
                            "Name": "Orlando",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.374026, 28.570841",
                            "IataCode": "ORL",
                            "Airports": [
                                {
                                    "Id": "MCO",
                                    "Name": "Orlando International",
                                    "CityId": "ORLB",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.324921, 28.432166"
                                },
                                {
                                    "Id": "SFB",
                                    "Name": "Orlando Sanford",
                                    "CityId": "ORLB",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.2375, 28.777778"
                                },
                                {
                                    "Id": "ORL",
                                    "Name": "Orlando Executive",
                                    "CityId": "ORLB",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.335557, 28.544167"
                                },
                                {
                                    "Id": "DWS",
                                    "Name": "Orlando Walt Disney World",
                                    "CityId": "ORLB",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.55, 28.383611"
                                }
                            ]
                        },
                        {
                            "Id": "ISNA",
                            "Name": "Williston",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-103.61797, 48.146968",
                            "IataCode": "ISN",
                            "Airports": [
                                {
                                    "Id": "ISN",
                                    "Name": "Williston",
                                    "CityId": "ISNA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-103.63611, 48.176111"
                                },
                                {
                                    "Id": "XWA",
                                    "Name": "Basin International",
                                    "CityId": "ISNA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-103.75056, 48.259722"
                                }
                            ]
                        },
                        {
                            "Id": "ISOA",
                            "Name": "Kinston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.581635, 35.262663",
                            "IataCode": "ISO",
                            "Airports": [
                                {
                                    "Id": "ISO",
                                    "Name": "Kinston",
                                    "CityId": "ISOA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.616667, 35.326667"
                                }
                            ]
                        },
                        {
                            "Id": "ISSA",
                            "Name": "Wiscasset",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.665602, 44.002856",
                            "IataCode": "ISS",
                            "Airports": [
                                {
                                    "Id": "ISS",
                                    "Name": "Wiscasset",
                                    "CityId": "ISSA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.666667, 44"
                                }
                            ]
                        },
                        {
                            "Id": "ISWA",
                            "Name": "Wisconsin Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.817346, 44.383576",
                            "IataCode": "ISW",
                            "Airports": [
                                {
                                    "Id": "ISW",
                                    "Name": "Wisconsin Rapids",
                                    "CityId": "ISWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.838889, 44.360278"
                                }
                            ]
                        },
                        {
                            "Id": "ITHA",
                            "Name": "Ithaca",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-76.496607, 42.440628",
                            "IataCode": "ITH",
                            "Airports": [
                                {
                                    "Id": "ITH",
                                    "Name": "Ithaca",
                                    "CityId": "ITHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-76.458056, 42.490278"
                                }
                            ]
                        },
                        {
                            "Id": "ITOA",
                            "Name": "Hilo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.09, 19.729722",
                            "IataCode": "ITO",
                            "Airports": [
                                {
                                    "Id": "ITO",
                                    "Name": "Hilo",
                                    "CityId": "ITOA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-155.05806, 19.723611"
                                }
                            ]
                        },
                        {
                            "Id": "IWDA",
                            "Name": "Ironwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-90.171008, 46.45467",
                            "IataCode": "IWD",
                            "Airports": [
                                {
                                    "Id": "IWD",
                                    "Name": "Ironwood",
                                    "CityId": "IWDA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-90.131667, 46.525556"
                                }
                            ]
                        },
                        {
                            "Id": "IYKA",
                            "Name": "Inyokern",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.81257, 35.646899",
                            "IataCode": "IYK",
                            "Airports": [
                                {
                                    "Id": "IYK",
                                    "Name": "Inyokern",
                                    "CityId": "IYKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.82917, 35.656667"
                                }
                            ]
                        },
                        {
                            "Id": "JACA",
                            "Name": "Jackson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.76243, 43.479929",
                            "IataCode": "JAC",
                            "Airports": [
                                {
                                    "Id": "JAC",
                                    "Name": "Jackson Hole",
                                    "CityId": "JACA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.73772, 43.606996"
                                }
                            ]
                        },
                        {
                            "Id": "JASA",
                            "Name": "Jasper",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-93.996576, 30.920199",
                            "IataCode": "JAS",
                            "Airports": [
                                {
                                    "Id": "JAS",
                                    "Name": "Jasper",
                                    "CityId": "JASA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.016667, 30.933333"
                                }
                            ]
                        },
                        {
                            "Id": "JBRA",
                            "Name": "Jonesboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.704279, 35.842297",
                            "IataCode": "JBR",
                            "Airports": [
                                {
                                    "Id": "JBR",
                                    "Name": "Jonesboro",
                                    "CityId": "JBRA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.648333, 35.83"
                                }
                            ]
                        },
                        {
                            "Id": "JDAA",
                            "Name": "John Day",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-118.95301, 44.415988",
                            "IataCode": "JDA",
                            "Airports": [
                                {
                                    "Id": "JDA",
                                    "Name": "John Day",
                                    "CityId": "JDAA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-118.96391, 44.40377"
                                }
                            ]
                        },
                        {
                            "Id": "JDYA",
                            "Name": "Downey",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.13257, 33.940014",
                            "IataCode": "JDY",
                            "Airports": [
                                {
                                    "Id": "JDY",
                                    "Name": "Downey",
                                    "CityId": "JDYA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.11667, 33.933333"
                                }
                            ]
                        },
                        {
                            "Id": "JEFA",
                            "Name": "Jefferson City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.173516, 38.576702",
                            "IataCode": "JEF",
                            "Airports": [
                                {
                                    "Id": "JEF",
                                    "Name": "Jefferson City",
                                    "CityId": "JEFA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.156389, 38.592222"
                                }
                            ]
                        },
                        {
                            "Id": "JHWA",
                            "Name": "Jamestown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-79.235326, 42.097002",
                            "IataCode": "JHW",
                            "Airports": [
                                {
                                    "Id": "JHW",
                                    "Name": "Jamestown",
                                    "CityId": "JHWA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-79.258056, 42.150556"
                                }
                            ]
                        },
                        {
                            "Id": "JKVA",
                            "Name": "Jacksonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.270504, 31.963778",
                            "IataCode": "JKV",
                            "Airports": [
                                {
                                    "Id": "JKV",
                                    "Name": "Jacksonville",
                                    "CityId": "JKVA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.21745, 31.86934"
                                }
                            ]
                        },
                        {
                            "Id": "JLNA",
                            "Name": "Joplin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.513281, 37.084227",
                            "IataCode": "JLN",
                            "Airports": [
                                {
                                    "Id": "JLN",
                                    "Name": "Joplin",
                                    "CityId": "JLNA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.497778, 37.149722"
                                }
                            ]
                        },
                        {
                            "Id": "JMCA",
                            "Name": "Sausalito",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.48525, 37.859094",
                            "IataCode": "JMC",
                            "Airports": [
                                {
                                    "Id": "JMC",
                                    "Name": "Sausalito",
                                    "CityId": "JMCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.51442, 37.87882"
                                }
                            ]
                        },
                        {
                            "Id": "JMSA",
                            "Name": "Jamestown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-98.708436, 46.910544",
                            "IataCode": "JMS",
                            "Airports": [
                                {
                                    "Id": "JMS",
                                    "Name": "Jamestown",
                                    "CityId": "JMSA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-98.678333, 46.93"
                                }
                            ]
                        },
                        {
                            "Id": "JNUA",
                            "Name": "Juneau",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.41972, 58.301944",
                            "IataCode": "JNU",
                            "Airports": [
                                {
                                    "Id": "JNU",
                                    "Name": "Juneau",
                                    "CityId": "JNUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.57472, 58.355556"
                                }
                            ]
                        },
                        {
                            "Id": "JOTA",
                            "Name": "Joliet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.081725, 41.525032",
                            "IataCode": "JOT",
                            "Airports": [
                                {
                                    "Id": "JOT",
                                    "Name": "Joliet",
                                    "CityId": "JOTA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.083333, 41.533333"
                                }
                            ]
                        },
                        {
                            "Id": "JSTA",
                            "Name": "Johnstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.92197, 40.326741",
                            "IataCode": "JST",
                            "Airports": [
                                {
                                    "Id": "JST",
                                    "Name": "Johnstown",
                                    "CityId": "JSTA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.834444, 40.316667"
                                }
                            ]
                        },
                        {
                            "Id": "KMOA",
                            "Name": "Manokotak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.05833, 58.981389",
                            "IataCode": "KMO",
                            "Airports": [
                                {
                                    "Id": "KMO",
                                    "Name": "Manokotak",
                                    "CityId": "KMOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.03583, 58.984444"
                                }
                            ]
                        },
                        {
                            "Id": "JVLA",
                            "Name": "Janesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.018722, 42.682788",
                            "IataCode": "JVL",
                            "Airports": [
                                {
                                    "Id": "JVL",
                                    "Name": "Janesville",
                                    "CityId": "JVLA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.016667, 42.683333"
                                }
                            ]
                        },
                        {
                            "Id": "JXNA",
                            "Name": "Jackson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.401346, 42.245869",
                            "IataCode": "JXN",
                            "Airports": [
                                {
                                    "Id": "JXN",
                                    "Name": "Jackson",
                                    "CityId": "JXNA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.461389, 42.258889"
                                }
                            ]
                        },
                        {
                            "Id": "KAEA",
                            "Name": "Kake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.94722, 56.975833",
                            "IataCode": "KAE",
                            "Airports": [
                                {
                                    "Id": "KAE",
                                    "Name": "Kake",
                                    "CityId": "KAEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.91733, 56.963977"
                                }
                            ]
                        },
                        {
                            "Id": "KNBA",
                            "Name": "Kanab",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.52631, 37.047485",
                            "IataCode": "KNB",
                            "Airports": [
                                {
                                    "Id": "KNB",
                                    "Name": "Kanab",
                                    "CityId": "KNBA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.53333, 37.05"
                                }
                            ]
                        },
                        {
                            "Id": "KALB",
                            "Name": "Kaltag",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.72194, 64.327222",
                            "IataCode": "KAL",
                            "Airports": [
                                {
                                    "Id": "KAL",
                                    "Name": "Kaltag",
                                    "CityId": "KALB",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.73611, 64.321944"
                                }
                            ]
                        },
                        {
                            "Id": "KNTA",
                            "Name": "Kennett",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-90.055649, 36.236176",
                            "IataCode": "KNT",
                            "Airports": [
                                {
                                    "Id": "KNT",
                                    "Name": "Kennett",
                                    "CityId": "KNTA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.034722, 36.230556"
                                }
                            ]
                        },
                        {
                            "Id": "KNWA",
                            "Name": "New Stuyahok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.31194, 59.452778",
                            "IataCode": "KNW",
                            "Airports": [
                                {
                                    "Id": "KNW",
                                    "Name": "New Stuyahok",
                                    "CityId": "KNWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.32972, 59.452222"
                                }
                            ]
                        },
                        {
                            "Id": "KOTA",
                            "Name": "Kotlik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.55333, 63.034167",
                            "IataCode": "KOT",
                            "Airports": [
                                {
                                    "Id": "KOT",
                                    "Name": "Kotlik",
                                    "CityId": "KOTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.56028, 63.029722"
                                }
                            ]
                        },
                        {
                            "Id": "KOZB",
                            "Name": "Ouzinkie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.50222, 57.923611",
                            "IataCode": "KOZ",
                            "Airports": [
                                {
                                    "Id": "KOZ",
                                    "Name": "Ouzinkie SPB",
                                    "CityId": "KOZB",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.50706, 57.921777"
                                }
                            ]
                        },
                        {
                            "Id": "KPBA",
                            "Name": "Point Baker",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.62111, 56.352778",
                            "IataCode": "KPB",
                            "Airports": [
                                {
                                    "Id": "KPB",
                                    "Name": "Point Baker",
                                    "CityId": "KPBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.58333, 56.333333"
                                }
                            ]
                        },
                        {
                            "Id": "KPCA",
                            "Name": "Port Clarence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.84583, 65.262222",
                            "IataCode": "KPC",
                            "Airports": [
                                {
                                    "Id": "KPC",
                                    "Name": "Port Clarence",
                                    "CityId": "KPCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.85583, 65.254444"
                                }
                            ]
                        },
                        {
                            "Id": "KPNA",
                            "Name": "Kipnuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.04139, 59.938889",
                            "IataCode": "KPN",
                            "Airports": [
                                {
                                    "Id": "KPN",
                                    "Name": "Kipnuk",
                                    "CityId": "KPNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.05, 59.933333"
                                }
                            ]
                        },
                        {
                            "Id": "KPTA",
                            "Name": "Jackpot",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-114.67476, 41.983244",
                            "IataCode": "KPT",
                            "Airports": [
                                {
                                    "Id": "KPT",
                                    "Name": "Jackpot",
                                    "CityId": "KPTA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-114.66667, 41.983333"
                                }
                            ]
                        },
                        {
                            "Id": "KQAA",
                            "Name": "Akutan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.77306, 54.135556",
                            "IataCode": "KQA",
                            "Airports": [
                                {
                                    "Id": "KQA",
                                    "Name": "Akutan",
                                    "CityId": "KQAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.78333, 54.133333"
                                }
                            ]
                        },
                        {
                            "Id": "KEBA",
                            "Name": "Nanwalek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.91173, 59.33058",
                            "IataCode": "KEB",
                            "Airports": [
                                {
                                    "Id": "KEB",
                                    "Name": "Nanwalek",
                                    "CityId": "KEBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.92222, 59.352778"
                                }
                            ]
                        },
                        {
                            "Id": "KEHA",
                            "Name": "Kenmore Air Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.24401, 47.75732",
                            "IataCode": "KEH",
                            "Airports": [
                                {
                                    "Id": "KEH",
                                    "Name": "Kenmore Air Harbor",
                                    "CityId": "KEHA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.25998, 47.75655"
                                }
                            ]
                        },
                        {
                            "Id": "KEKA",
                            "Name": "Ekwok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.47528, 59.349722",
                            "IataCode": "KEK",
                            "Airports": [
                                {
                                    "Id": "KEK",
                                    "Name": "Ekwok",
                                    "CityId": "KEKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.4775, 59.355278"
                                }
                            ]
                        },
                        {
                            "Id": "KFPA",
                            "Name": "False Pass",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.415, 54.850833",
                            "IataCode": "KFP",
                            "Airports": [
                                {
                                    "Id": "KFP",
                                    "Name": "False Pass",
                                    "CityId": "KFPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.40917, 54.849167"
                                }
                            ]
                        },
                        {
                            "Id": "KGKA",
                            "Name": "New Koliganek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.28444, 59.728611",
                            "IataCode": "KGK",
                            "Airports": [
                                {
                                    "Id": "KGK",
                                    "Name": "New Koliganek",
                                    "CityId": "KGKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.23333, 59.8"
                                }
                            ]
                        },
                        {
                            "Id": "KGXA",
                            "Name": "Grayling",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.06472, 62.903611",
                            "IataCode": "KGX",
                            "Airports": [
                                {
                                    "Id": "KGX",
                                    "Name": "Grayling",
                                    "CityId": "KGXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.0625, 62.895278"
                                }
                            ]
                        },
                        {
                            "Id": "KIBA",
                            "Name": "Ivanof Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.48333, 55.9",
                            "IataCode": "KIB",
                            "Airports": [
                                {
                                    "Id": "KIB",
                                    "Name": "Ivanof Bay",
                                    "CityId": "KIBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.5675, 55.893611"
                                }
                            ]
                        },
                        {
                            "Id": "KICA",
                            "Name": "King City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.12603, 36.212744",
                            "IataCode": "KIC",
                            "Airports": [
                                {
                                    "Id": "KIC",
                                    "Name": "King City",
                                    "CityId": "KICA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.11976, 36.227693"
                                }
                            ]
                        },
                        {
                            "Id": "SPSA",
                            "Name": "Wichita Falls",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.493387, 33.913708",
                            "IataCode": "SPS",
                            "Airports": [
                                {
                                    "Id": "SPS",
                                    "Name": "Wichita Falls Sheppard AFB",
                                    "CityId": "SPSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.491944, 33.988333"
                                },
                                {
                                    "Id": "KIP",
                                    "Name": "Wichita Falls Kickapoo",
                                    "CityId": "SPSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.49061, 33.86121"
                                }
                            ]
                        },
                        {
                            "Id": "KKAA",
                            "Name": "Koyuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.15694, 64.931944",
                            "IataCode": "KKA",
                            "Airports": [
                                {
                                    "Id": "KKA",
                                    "Name": "Koyuk",
                                    "CityId": "KKAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.15, 64.939444"
                                }
                            ]
                        },
                        {
                            "Id": "KKHA",
                            "Name": "Kongiganak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.88172, 59.95656",
                            "IataCode": "KKH",
                            "Airports": [
                                {
                                    "Id": "KKH",
                                    "Name": "Kongiganak",
                                    "CityId": "KKHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.75, 59.966667"
                                }
                            ]
                        },
                        {
                            "Id": "KKIA",
                            "Name": "Akiachak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.43139, 60.909444",
                            "IataCode": "KKI",
                            "Airports": [
                                {
                                    "Id": "KKI",
                                    "Name": "Akiachak",
                                    "CityId": "KKIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.42222, 60.906944"
                                }
                            ]
                        },
                        {
                            "Id": "KLGA",
                            "Name": "Kalskag",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.30528, 61.537222",
                            "IataCode": "KLG",
                            "Airports": [
                                {
                                    "Id": "KLG",
                                    "Name": "Kalskag",
                                    "CityId": "KLGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.32444, 61.538333"
                                }
                            ]
                        },
                        {
                            "Id": "KLLA",
                            "Name": "Levelock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.85889, 59.114167",
                            "IataCode": "KLL",
                            "Airports": [
                                {
                                    "Id": "KLL",
                                    "Name": "Levelock",
                                    "CityId": "KLLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.86333, 59.118611"
                                }
                            ]
                        },
                        {
                            "Id": "KLNA",
                            "Name": "Larsen Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.97861, 57.54",
                            "IataCode": "KLN",
                            "Airports": [
                                {
                                    "Id": "KLN",
                                    "Name": "Larsen Bay",
                                    "CityId": "KLNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154, 57.533333"
                                }
                            ]
                        },
                        {
                            "Id": "KLSA",
                            "Name": "Kelso",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.90844, 46.146779",
                            "IataCode": "KLS",
                            "Airports": [
                                {
                                    "Id": "KLS",
                                    "Name": "Kelso",
                                    "CityId": "KLSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.89483, 46.1165"
                                }
                            ]
                        },
                        {
                            "Id": "KLWA",
                            "Name": "Klawock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.09583, 55.552222",
                            "IataCode": "KLW",
                            "Airports": [
                                {
                                    "Id": "KLW",
                                    "Name": "Klawock",
                                    "CityId": "KLWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.10155, 55.554612"
                                }
                            ]
                        },
                        {
                            "Id": "KTSA",
                            "Name": "Teller Mission",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.48917, 65.334722",
                            "IataCode": "KTS",
                            "Airports": [
                                {
                                    "Id": "KTS",
                                    "Name": "Teller Mission",
                                    "CityId": "KTSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.47167, 65.329444"
                                }
                            ]
                        },
                        {
                            "Id": "KUKA",
                            "Name": "Kasigluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.51806, 60.895556",
                            "IataCode": "KUK",
                            "Airports": [
                                {
                                    "Id": "KUK",
                                    "Name": "Kasigluk",
                                    "CityId": "KUKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.52472, 60.87194"
                                }
                            ]
                        },
                        {
                            "Id": "KVCA",
                            "Name": "King Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.31028, 55.061667",
                            "IataCode": "KVC",
                            "Airports": [
                                {
                                    "Id": "KVC",
                                    "Name": "King Cove",
                                    "CityId": "KVCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.31333, 55.055"
                                }
                            ]
                        },
                        {
                            "Id": "KVLA",
                            "Name": "Kivalina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.53333, 67.726944",
                            "IataCode": "KVL",
                            "Airports": [
                                {
                                    "Id": "KVL",
                                    "Name": "Kivalina",
                                    "CityId": "KVLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.54722, 67.731667"
                                }
                            ]
                        },
                        {
                            "Id": "KWNA",
                            "Name": "Quinhagak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.91583, 59.748889",
                            "IataCode": "KWN",
                            "Airports": [
                                {
                                    "Id": "KWN",
                                    "Name": "Quinhagak",
                                    "CityId": "KWNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.83333, 59.761111"
                                }
                            ]
                        },
                        {
                            "Id": "KWTA",
                            "Name": "Kwethluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.43583, 60.812222",
                            "IataCode": "KWT",
                            "Airports": [
                                {
                                    "Id": "KWT",
                                    "Name": "Kwethluk",
                                    "CityId": "KWTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.4375, 60.808889"
                                }
                            ]
                        },
                        {
                            "Id": "KYKA",
                            "Name": "Karluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.45556, 57.571944",
                            "IataCode": "KYK",
                            "Airports": [
                                {
                                    "Id": "KYK",
                                    "Name": "Karluk",
                                    "CityId": "KYKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.45, 57.566111"
                                }
                            ]
                        },
                        {
                            "Id": "KYUA",
                            "Name": "Koyukuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.70083, 64.880278",
                            "IataCode": "KYU",
                            "Airports": [
                                {
                                    "Id": "KYU",
                                    "Name": "Koyukuk",
                                    "CityId": "KYUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.71306, 64.878056"
                                }
                            ]
                        },
                        {
                            "Id": "LAAA",
                            "Name": "Lamar",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-102.62075, 38.087231",
                            "IataCode": "LAA",
                            "Airports": [
                                {
                                    "Id": "LAA",
                                    "Name": "Lamar",
                                    "CityId": "LAAA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-102.69028, 38.068611"
                                }
                            ]
                        },
                        {
                            "Id": "LAFA",
                            "Name": "Lafayette",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.875287, 40.416702",
                            "IataCode": "LAF",
                            "Airports": [
                                {
                                    "Id": "LAF",
                                    "Name": "Lafayette",
                                    "CityId": "LAFA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.933611, 40.411944"
                                }
                            ]
                        },
                        {
                            "Id": "LALA",
                            "Name": "Lakeland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.949804, 28.039465",
                            "IataCode": "LAL",
                            "Airports": [
                                {
                                    "Id": "LAL",
                                    "Name": "Lakeland",
                                    "CityId": "LALA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.014722, 27.988889"
                                }
                            ]
                        },
                        {
                            "Id": "LAMA",
                            "Name": "Los Alamos",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.30697, 35.88808",
                            "IataCode": "LAM",
                            "Airports": [
                                {
                                    "Id": "LAM",
                                    "Name": "Los Alamos",
                                    "CityId": "LAMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.31667, 35.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LANA",
                            "Name": "Lansing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.555535, 42.732535",
                            "IataCode": "LAN",
                            "Airports": [
                                {
                                    "Id": "LAN",
                                    "Name": "Lansing",
                                    "CityId": "LANA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.588333, 42.779167"
                                }
                            ]
                        },
                        {
                            "Id": "LARA",
                            "Name": "Laramie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-105.5911, 41.311367",
                            "IataCode": "LAR",
                            "Airports": [
                                {
                                    "Id": "LAR",
                                    "Name": "Laramie",
                                    "CityId": "LARA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-105.67062, 41.31495"
                                }
                            ]
                        },
                        {
                            "Id": "LAWA",
                            "Name": "Lawton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-98.390331, 34.608685",
                            "IataCode": "LAW",
                            "Airports": [
                                {
                                    "Id": "LAW",
                                    "Name": "Lawton",
                                    "CityId": "LAWA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-98.416111, 34.569722"
                                }
                            ]
                        },
                        {
                            "Id": "LBBA",
                            "Name": "Lubbock",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.85517, 33.577863",
                            "IataCode": "LBB",
                            "Airports": [
                                {
                                    "Id": "REE",
                                    "Name": "Lubbock Reese AFB",
                                    "CityId": "LBBA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.85, 33.583333"
                                },
                                {
                                    "Id": "LBB",
                                    "Name": "Lubbock International",
                                    "CityId": "LBBA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.82278, 33.662222"
                                }
                            ]
                        },
                        {
                            "Id": "LBEA",
                            "Name": "Latrobe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.379481, 40.321181",
                            "IataCode": "LBE",
                            "Airports": [
                                {
                                    "Id": "LBE",
                                    "Name": "Latrobe",
                                    "CityId": "LBEA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.402778, 40.276389"
                                }
                            ]
                        },
                        {
                            "Id": "LBFA",
                            "Name": "North Platte",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-100.76542, 41.123887",
                            "IataCode": "LBF",
                            "Airports": [
                                {
                                    "Id": "LBF",
                                    "Name": "North Platte",
                                    "CityId": "LBFA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-100.69667, 41.128333"
                                }
                            ]
                        },
                        {
                            "Id": "LBLA",
                            "Name": "Liberal",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-100.921, 37.043081",
                            "IataCode": "LBL",
                            "Airports": [
                                {
                                    "Id": "LBL",
                                    "Name": "Liberal",
                                    "CityId": "LBLA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-100.96056, 37.044444"
                                }
                            ]
                        },
                        {
                            "Id": "LBTA",
                            "Name": "Lumberton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.008643, 34.61822",
                            "IataCode": "LBT",
                            "Airports": [
                                {
                                    "Id": "LBT",
                                    "Name": "Lumberton",
                                    "CityId": "LBTA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.06642, 34.60016"
                                }
                            ]
                        },
                        {
                            "Id": "LCIA",
                            "Name": "Laconia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.470351, 43.527855",
                            "IataCode": "LCI",
                            "Airports": [
                                {
                                    "Id": "LCI",
                                    "Name": "Laconia",
                                    "CityId": "LCIA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.421111, 43.571389"
                                }
                            ]
                        },
                        {
                            "Id": "LDJA",
                            "Name": "Linden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.24459, 40.622048",
                            "IataCode": "LDJ",
                            "Airports": [
                                {
                                    "Id": "LDJ",
                                    "Name": "Linden",
                                    "CityId": "LDJA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.25, 40.616667"
                                }
                            ]
                        },
                        {
                            "Id": "LDMA",
                            "Name": "Ludington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.452583, 43.955283",
                            "IataCode": "LDM",
                            "Airports": [
                                {
                                    "Id": "LDM",
                                    "Name": "Ludington",
                                    "CityId": "LDMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.40786, 43.96251"
                                }
                            ]
                        },
                        {
                            "Id": "LEBA",
                            "Name": "Lebanon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-72.251757, 43.642293",
                            "IataCode": "LEB",
                            "Airports": [
                                {
                                    "Id": "LEB",
                                    "Name": "Lebanon",
                                    "CityId": "LEBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-72.305833, 43.628056"
                                }
                            ]
                        },
                        {
                            "Id": "LEEA",
                            "Name": "Leesburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.877858, 28.810823",
                            "IataCode": "LEE",
                            "Airports": [
                                {
                                    "Id": "LEE",
                                    "Name": "Leesburg",
                                    "CityId": "LEEA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.816667, 28.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LEMB",
                            "Name": "Lemmon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-102.15932, 45.940834",
                            "IataCode": "LEM",
                            "Airports": [
                                {
                                    "Id": "LEM",
                                    "Name": "Lemmon",
                                    "CityId": "LEMB",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-102.1506, 45.9339"
                                }
                            ]
                        },
                        {
                            "Id": "LEXA",
                            "Name": "Lexington",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-84.477716, 37.98869",
                            "IataCode": "LEX",
                            "Airports": [
                                {
                                    "Id": "LSD",
                                    "Name": "Lexington Creech AAF",
                                    "CityId": "LEXA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-84.325562, 38.079176"
                                },
                                {
                                    "Id": "LEX",
                                    "Name": "Lexington Blue Grass",
                                    "CityId": "LEXA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-84.602222, 38.039722"
                                }
                            ]
                        },
                        {
                            "Id": "LFNA",
                            "Name": "Louisburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-78.301106, 36.099039",
                            "IataCode": "LFN",
                            "Airports": [
                                {
                                    "Id": "LFN",
                                    "Name": "Louisburg",
                                    "CityId": "LFNA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-78.33028, 36.02333"
                                }
                            ]
                        },
                        {
                            "Id": "LFTA",
                            "Name": "Lafayette",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.019843, 30.22409",
                            "IataCode": "LFT",
                            "Airports": [
                                {
                                    "Id": "LFT",
                                    "Name": "Lafayette",
                                    "CityId": "LFTA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-91.9875, 30.204722"
                                }
                            ]
                        },
                        {
                            "Id": "LGBA",
                            "Name": "Long Beach",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.18923, 33.766962",
                            "IataCode": "LGB",
                            "Airports": [
                                {
                                    "Id": "JLB",
                                    "Name": "Long Beach Heliport",
                                    "CityId": "LGBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.15111, 33.8175"
                                },
                                {
                                    "Id": "LGB",
                                    "Name": "Long Beach Municipal",
                                    "CityId": "LGBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.15111, 33.8175"
                                }
                            ]
                        },
                        {
                            "Id": "LGCA",
                            "Name": "La Grange",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-85.031327, 33.039291",
                            "IataCode": "LGC",
                            "Airports": [
                                {
                                    "Id": "LGC",
                                    "Name": "La Grange",
                                    "CityId": "LGCA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-85.070833, 33.0075"
                                }
                            ]
                        },
                        {
                            "Id": "LGDA",
                            "Name": "La Grande",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-118.08772, 45.324577",
                            "IataCode": "LGD",
                            "Airports": [
                                {
                                    "Id": "LGD",
                                    "Name": "La Grande",
                                    "CityId": "LGDA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-118.005, 45.289722"
                                }
                            ]
                        },
                        {
                            "Id": "LGUA",
                            "Name": "Logan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.83439, 41.735486",
                            "IataCode": "LGU",
                            "Airports": [
                                {
                                    "Id": "LGU",
                                    "Name": "Logan",
                                    "CityId": "LGUA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.855, 41.7875"
                                }
                            ]
                        },
                        {
                            "Id": "LHVA",
                            "Name": "Lock Haven",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.446926, 41.137013",
                            "IataCode": "LHV",
                            "Airports": [
                                {
                                    "Id": "LHV",
                                    "Name": "Lock Haven",
                                    "CityId": "LHVA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.45, 41.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LITA",
                            "Name": "Little Rock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.289595, 34.746481",
                            "IataCode": "LIT",
                            "Airports": [
                                {
                                    "Id": "LIT",
                                    "Name": "Little Rock",
                                    "CityId": "LITA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.234444, 34.729167"
                                }
                            ]
                        },
                        {
                            "Id": "LKPA",
                            "Name": "Lake Placid",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.979871, 44.279491",
                            "IataCode": "LKP",
                            "Airports": [
                                {
                                    "Id": "LKP",
                                    "Name": "Lake Placid",
                                    "CityId": "LKPA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.983333, 44.283333"
                                }
                            ]
                        },
                        {
                            "Id": "LKVA",
                            "Name": "Lakeview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-120.34579, 42.188772",
                            "IataCode": "LKV",
                            "Airports": [
                                {
                                    "Id": "LKV",
                                    "Name": "Lakeview",
                                    "CityId": "LKVA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-120.4, 42.166667"
                                }
                            ]
                        },
                        {
                            "Id": "LLXA",
                            "Name": "Lyndonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.003151, 44.533667",
                            "IataCode": "LLX",
                            "Airports": [
                                {
                                    "Id": "LLX",
                                    "Name": "Lyndonville",
                                    "CityId": "LLXA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.01611, 44.57201"
                                }
                            ]
                        },
                        {
                            "Id": "LLYA",
                            "Name": "Mount Holly",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.787662, 39.99289",
                            "IataCode": "LLY",
                            "Airports": [
                                {
                                    "Id": "LLY",
                                    "Name": "Mount Holly",
                                    "CityId": "LLYA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.75, 39.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LMTA",
                            "Name": "Klamath Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.78167, 42.224867",
                            "IataCode": "LMT",
                            "Airports": [
                                {
                                    "Id": "LMT",
                                    "Name": "Klamath Falls",
                                    "CityId": "LMTA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.73583, 42.163056"
                                }
                            ]
                        },
                        {
                            "Id": "PBIA",
                            "Name": "West Palm Beach",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.053375, 26.715342",
                            "IataCode": "PBI",
                            "Airports": [
                                {
                                    "Id": "LNA",
                                    "Name": "West Palm Beach Palm Beach County",
                                    "CityId": "PBIA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.08504, 26.59285"
                                },
                                {
                                    "Id": "PBI",
                                    "Name": "West Palm Beach International",
                                    "CityId": "PBIA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.094167, 26.682222"
                                }
                            ]
                        },
                        {
                            "Id": "LNKA",
                            "Name": "Lincoln",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-96.666961, 40.800001",
                            "IataCode": "LNK",
                            "Airports": [
                                {
                                    "Id": "LNK",
                                    "Name": "Lincoln",
                                    "CityId": "LNKA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.761389, 40.845833"
                                }
                            ]
                        },
                        {
                            "Id": "LNNA",
                            "Name": "Willoughby",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.406501, 41.63977",
                            "IataCode": "LNN",
                            "Airports": [
                                {
                                    "Id": "LNN",
                                    "Name": "Willoughby",
                                    "CityId": "LNNA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.416667, 41.633333"
                                }
                            ]
                        },
                        {
                            "Id": "LNPA",
                            "Name": "Wise",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-82.575711, 36.975934",
                            "IataCode": "LNP",
                            "Airports": [
                                {
                                    "Id": "LNP",
                                    "Name": "Wise",
                                    "CityId": "LNPA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-82.531443, 36.986991"
                                }
                            ]
                        },
                        {
                            "Id": "LNRA",
                            "Name": "Lone Rock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.197902, 43.183323",
                            "IataCode": "LNR",
                            "Airports": [
                                {
                                    "Id": "LNR",
                                    "Name": "Lone Rock",
                                    "CityId": "LNRA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.18179, 43.21138"
                                }
                            ]
                        },
                        {
                            "Id": "LNSA",
                            "Name": "Lancaster",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-76.305514, 40.037875",
                            "IataCode": "LNS",
                            "Airports": [
                                {
                                    "Id": "LNS",
                                    "Name": "Lancaster",
                                    "CityId": "LNSA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.299722, 40.121111"
                                }
                            ]
                        },
                        {
                            "Id": "LOLA",
                            "Name": "Lovelock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-118.47348, 40.179354",
                            "IataCode": "LOL",
                            "Airports": [
                                {
                                    "Id": "LOL",
                                    "Name": "Lovelock",
                                    "CityId": "LOLA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-118.56667, 40.058333"
                                }
                            ]
                        },
                        {
                            "Id": "SDFA",
                            "Name": "Louisville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-85.759407, 38.254239",
                            "IataCode": "SDF",
                            "Airports": [
                                {
                                    "Id": "LOU",
                                    "Name": "Louisville Bowman Field",
                                    "CityId": "SDFA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.66302, 38.22813"
                                },
                                {
                                    "Id": "LJC",
                                    "Name": "Louisville Intercontinental",
                                    "CityId": "SDFA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.75, 36.266667"
                                },
                                {
                                    "Id": "SDF",
                                    "Name": "Louisville Standiford Field",
                                    "CityId": "SDFA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-85.738611, 38.179722"
                                }
                            ]
                        },
                        {
                            "Id": "LOWA",
                            "Name": "Louisa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.004165, 38.025139",
                            "IataCode": "LOW",
                            "Airports": [
                                {
                                    "Id": "LOW",
                                    "Name": "Louisa",
                                    "CityId": "LOWA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.967653, 38.010163"
                                }
                            ]
                        },
                        {
                            "Id": "LOZA",
                            "Name": "London",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-84.083265, 37.128977",
                            "IataCode": "LOZ",
                            "Airports": [
                                {
                                    "Id": "LOZ",
                                    "Name": "Corbin-London",
                                    "CityId": "LOZA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-84.076944, 37.087222"
                                }
                            ]
                        },
                        {
                            "Id": "LPCA",
                            "Name": "Lompoc",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.45794, 34.63915",
                            "IataCode": "LPC",
                            "Airports": [
                                {
                                    "Id": "VBG",
                                    "Name": "Lompoc Vandenberg AFB",
                                    "CityId": "LPCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.58431, 34.73733"
                                },
                                {
                                    "Id": "LPC",
                                    "Name": "Lompoc",
                                    "CityId": "LPCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.46766, 34.66554"
                                }
                            ]
                        },
                        {
                            "Id": "LQKA",
                            "Name": "Pickens",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-82.707357, 34.883449",
                            "IataCode": "LQK",
                            "Airports": [
                                {
                                    "Id": "LQK",
                                    "Name": "Pickens",
                                    "CityId": "LQKA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-82.7, 34.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LRFA",
                            "Name": "Jacksonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.110146, 34.8662",
                            "IataCode": "LRF",
                            "Airports": [
                                {
                                    "Id": "LRF",
                                    "Name": "Jacksonville",
                                    "CityId": "LRFA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.15, 34.916667"
                                }
                            ]
                        },
                        {
                            "Id": "LRJA",
                            "Name": "Lemars",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-96.165578, 42.794157",
                            "IataCode": "LRJ",
                            "Airports": [
                                {
                                    "Id": "LRJ",
                                    "Name": "Lemars",
                                    "CityId": "LRJA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-96.166667, 42.8"
                                }
                            ]
                        },
                        {
                            "Id": "LRUA",
                            "Name": "Las Cruces",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.77834, 32.312316",
                            "IataCode": "LRU",
                            "Airports": [
                                {
                                    "Id": "LRU",
                                    "Name": "Las Cruces",
                                    "CityId": "LRUA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.92167, 32.290833"
                                }
                            ]
                        },
                        {
                            "Id": "LSEA",
                            "Name": "La Crosse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-91.239581, 43.801356",
                            "IataCode": "LSE",
                            "Airports": [
                                {
                                    "Id": "LSE",
                                    "Name": "La Crosse",
                                    "CityId": "LSEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-91.256389, 43.877222"
                                }
                            ]
                        },
                        {
                            "Id": "LTSA",
                            "Name": "Altus",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-99.333975, 34.638125",
                            "IataCode": "LTS",
                            "Airports": [
                                {
                                    "Id": "AXS",
                                    "Name": "Altus Municipal",
                                    "CityId": "LTSA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.338056, 34.696667"
                                },
                                {
                                    "Id": "LTS",
                                    "Name": "Altus AFB",
                                    "CityId": "LTSA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-99.27425, 34.66396"
                                }
                            ]
                        },
                        {
                            "Id": "LTWA",
                            "Name": "Leonardtown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.635794, 38.291238",
                            "IataCode": "LTW",
                            "Airports": [
                                {
                                    "Id": "LTW",
                                    "Name": "Leonardtown",
                                    "CityId": "LTWA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.666667, 38.25"
                                }
                            ]
                        },
                        {
                            "Id": "CVGA",
                            "Name": "Cincinnati",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.456887, 39.162004",
                            "IataCode": "CVG",
                            "Airports": [
                                {
                                    "Id": "LUK",
                                    "Name": "Cincinnati Municipal",
                                    "CityId": "CVGA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.666667, 39.033333"
                                },
                                {
                                    "Id": "CVG",
                                    "Name": "Cincinnati Northern Kentucky",
                                    "CityId": "CVGA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.668611, 39.047778"
                                }
                            ]
                        },
                        {
                            "Id": "LULA",
                            "Name": "Laurel",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.130612, 31.694051",
                            "IataCode": "LUL",
                            "Airports": [
                                {
                                    "Id": "LUL",
                                    "Name": "Laurel Hesler-Noble Field",
                                    "CityId": "LULA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.166667, 31.666667"
                                },
                                {
                                    "Id": "PIB",
                                    "Name": "Hattiesburg-Laurel",
                                    "CityId": "LULA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.3367, 31.4675"
                                }
                            ]
                        },
                        {
                            "Id": "LUPA",
                            "Name": "Kalaupapa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-156.98611, 21.1925",
                            "IataCode": "LUP",
                            "Airports": [
                                {
                                    "Id": "LUP",
                                    "Name": "Kalaupapa",
                                    "CityId": "LUPA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.97444, 21.211111"
                                }
                            ]
                        },
                        {
                            "Id": "LVKA",
                            "Name": "Livermore",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.76801, 37.681874",
                            "IataCode": "LVK",
                            "Airports": [
                                {
                                    "Id": "LVK",
                                    "Name": "Livermore",
                                    "CityId": "LVKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.81722, 37.693889"
                                }
                            ]
                        },
                        {
                            "Id": "LVSA",
                            "Name": "Las Vegas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.2239, 35.593932",
                            "IataCode": "LVS",
                            "Airports": [
                                {
                                    "Id": "LVS",
                                    "Name": "Las Vegas",
                                    "CityId": "LVSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.21667, 35.6"
                                }
                            ]
                        },
                        {
                            "Id": "LWBA",
                            "Name": "Lewisburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-80.44563, 37.801788",
                            "IataCode": "LWB",
                            "Airports": [
                                {
                                    "Id": "LWB",
                                    "Name": "Lewisburg",
                                    "CityId": "LWBA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-80.398611, 37.859722"
                                }
                            ]
                        },
                        {
                            "Id": "LWCA",
                            "Name": "Lawrence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.23525, 38.971669",
                            "IataCode": "LWC",
                            "Airports": [
                                {
                                    "Id": "LWC",
                                    "Name": "Lawrence",
                                    "CityId": "LWCA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.2175, 39.009167"
                                }
                            ]
                        },
                        {
                            "Id": "LWLA",
                            "Name": "Wells",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-114.96449, 41.111589",
                            "IataCode": "LWL",
                            "Airports": [
                                {
                                    "Id": "LWL",
                                    "Name": "Wells",
                                    "CityId": "LWLA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-114.96667, 41.116667"
                                }
                            ]
                        },
                        {
                            "Id": "LWMA",
                            "Name": "Lawrence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.163113, 42.707035",
                            "IataCode": "LWM",
                            "Airports": [
                                {
                                    "Id": "LWM",
                                    "Name": "Lawrence",
                                    "CityId": "LWMA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.1225, 42.7175"
                                }
                            ]
                        },
                        {
                            "Id": "LWSA",
                            "Name": "Lewiston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-117.01766, 46.416551",
                            "IataCode": "LWS",
                            "Airports": [
                                {
                                    "Id": "LWS",
                                    "Name": "Lewiston-Nez Perce County",
                                    "CityId": "LWSA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-117.0168, 46.3728"
                                }
                            ]
                        },
                        {
                            "Id": "LWTA",
                            "Name": "Lewistown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-109.42824, 47.062473",
                            "IataCode": "LWT",
                            "Airports": [
                                {
                                    "Id": "LWT",
                                    "Name": "Lewistown",
                                    "CityId": "LWTA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-109.46571, 47.052581"
                                }
                            ]
                        },
                        {
                            "Id": "LWVA",
                            "Name": "Lawrenceville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.681693, 38.729214",
                            "IataCode": "LWV",
                            "Airports": [
                                {
                                    "Id": "LWV",
                                    "Name": "Lawrenceville",
                                    "CityId": "LWVA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.6053, 38.76421"
                                }
                            ]
                        },
                        {
                            "Id": "LXNA",
                            "Name": "Lexington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.741504, 40.78084",
                            "IataCode": "LXN",
                            "Airports": [
                                {
                                    "Id": "LXN",
                                    "Name": "Lexington",
                                    "CityId": "LXNA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.772511, 40.78899"
                                }
                            ]
                        },
                        {
                            "Id": "LXVA",
                            "Name": "Leadville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.29252, 39.250823",
                            "IataCode": "LXV",
                            "Airports": [
                                {
                                    "Id": "LXV",
                                    "Name": "Leadville",
                                    "CityId": "LXVA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.31611, 39.220278"
                                }
                            ]
                        },
                        {
                            "Id": "LYHA",
                            "Name": "Lynchburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-79.142247, 37.413754",
                            "IataCode": "LYH",
                            "Airports": [
                                {
                                    "Id": "LYH",
                                    "Name": "Lynchburg",
                                    "CityId": "LYHA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-79.201111, 37.326944"
                                }
                            ]
                        },
                        {
                            "Id": "MCNA",
                            "Name": "Macon",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.632402, 32.840695",
                            "IataCode": "MCN",
                            "Airports": [
                                {
                                    "Id": "MCN",
                                    "Name": "Macon Lewis B Wilson",
                                    "CityId": "MCNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.647778, 32.695556"
                                },
                                {
                                    "Id": "MAC",
                                    "Name": "Macon Smart",
                                    "CityId": "MCNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.633333, 32.833333"
                                },
                                {
                                    "Id": "WRB",
                                    "Name": "Macon Robins AFB",
                                    "CityId": "MCNA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.59185, 32.64014"
                                }
                            ]
                        },
                        {
                            "Id": "MAEA",
                            "Name": "Madera",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.06072, 36.961336",
                            "IataCode": "MAE",
                            "Airports": [
                                {
                                    "Id": "MAE",
                                    "Name": "Madera",
                                    "CityId": "MAEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.11099, 36.98754"
                                }
                            ]
                        },
                        {
                            "Id": "MAFA",
                            "Name": "Midland",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-102.07792, 31.997346",
                            "IataCode": "MAF",
                            "Airports": [
                                {
                                    "Id": "MDD",
                                    "Name": "Midland Airpark",
                                    "CityId": "MAFA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.10236, 32.03568"
                                },
                                {
                                    "Id": "MAF",
                                    "Name": "Midland Odessa Regional",
                                    "CityId": "MAFA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.20139, 31.940833"
                                }
                            ]
                        },
                        {
                            "Id": "MAWA",
                            "Name": "Malden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-89.96648, 36.557003",
                            "IataCode": "MAW",
                            "Airports": [
                                {
                                    "Id": "MAW",
                                    "Name": "Malden",
                                    "CityId": "MAWA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-89.988889, 36.605556"
                                }
                            ]
                        },
                        {
                            "Id": "MBLA",
                            "Name": "Manistee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.324253, 44.244447",
                            "IataCode": "MBL",
                            "Airports": [
                                {
                                    "Id": "MBL",
                                    "Name": "Manistee",
                                    "CityId": "MBLA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.253333, 44.273889"
                                }
                            ]
                        },
                        {
                            "Id": "MBYA",
                            "Name": "Moberly",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.438237, 39.418369",
                            "IataCode": "MBY",
                            "Airports": [
                                {
                                    "Id": "MBY",
                                    "Name": "Moberly",
                                    "CityId": "MBYA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.426667, 39.463889"
                                }
                            ]
                        },
                        {
                            "Id": "SACA",
                            "Name": "Sacramento",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.4944, 38.581572",
                            "IataCode": "SAC",
                            "Airports": [
                                {
                                    "Id": "SCK",
                                    "Name": "Sacramento Stockton",
                                    "CityId": "SACA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.23917, 37.894444"
                                },
                                {
                                    "Id": "MCC",
                                    "Name": "Sacramento Mcclellan AFB",
                                    "CityId": "SACA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.40594, 38.676222"
                                },
                                {
                                    "Id": "MHR",
                                    "Name": "Sacramento Mather AFB",
                                    "CityId": "SACA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.2975, 38.553889"
                                },
                                {
                                    "Id": "SMF",
                                    "Name": "Sacramento International",
                                    "CityId": "SACA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.5983, 38.6956"
                                },
                                {
                                    "Id": "SAC",
                                    "Name": "Sacramento Executive",
                                    "CityId": "SACA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.49888, 38.513486"
                                }
                            ]
                        },
                        {
                            "Id": "MCDA",
                            "Name": "Mackinac Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.618934, 45.84918",
                            "IataCode": "MCD",
                            "Airports": [
                                {
                                    "Id": "MCD",
                                    "Name": "Mackinac Island",
                                    "CityId": "MCDA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.633333, 45.866667"
                                }
                            ]
                        },
                        {
                            "Id": "MCEA",
                            "Name": "Merced",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.48297, 37.302163",
                            "IataCode": "MCE",
                            "Airports": [
                                {
                                    "Id": "MER",
                                    "Name": "Merced Castle AFB",
                                    "CityId": "MCEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.56663, 37.379024"
                                },
                                {
                                    "Id": "MCE",
                                    "Name": "Merced Municipal Arpt",
                                    "CityId": "MCEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.51278, 37.284722"
                                }
                            ]
                        },
                        {
                            "Id": "TPAA",
                            "Name": "Tampa",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.458428, 27.947522",
                            "IataCode": "TPA",
                            "Airports": [
                                {
                                    "Id": "SPG",
                                    "Name": "St Petersburg",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.625278, 27.766944"
                                },
                                {
                                    "Id": "MCF",
                                    "Name": "Tampa Mac Dill AFB",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.52121, 27.84934"
                                },
                                {
                                    "Id": "KYO",
                                    "Name": "Topp Of Tampa",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.366667, 28.216667"
                                },
                                {
                                    "Id": "CLW",
                                    "Name": "Clearwater Executive",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.758611, 27.976667"
                                },
                                {
                                    "Id": "TPF",
                                    "Name": "Tampa Peter O'Knight",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.44917, 27.91556"
                                },
                                {
                                    "Id": "TPA",
                                    "Name": "Tampa International",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.535278, 27.973056"
                                },
                                {
                                    "Id": "PIE",
                                    "Name": "St. Pete–Clearwater International",
                                    "CityId": "TPAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.683333, 27.916667"
                                }
                            ]
                        },
                        {
                            "Id": "MCGA",
                            "Name": "Mcgrath",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.59583, 62.956389",
                            "IataCode": "MCG",
                            "Airports": [
                                {
                                    "Id": "MCG",
                                    "Name": "Mcgrath",
                                    "CityId": "MCGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.65764, 62.97875"
                                }
                            ]
                        },
                        {
                            "Id": "MCWA",
                            "Name": "Mason City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.201037, 43.153573",
                            "IataCode": "MCW",
                            "Airports": [
                                {
                                    "Id": "MCW",
                                    "Name": "Mason City",
                                    "CityId": "MCWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.329444, 43.156944"
                                }
                            ]
                        },
                        {
                            "Id": "MDFA",
                            "Name": "Medford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.340141, 45.13858",
                            "IataCode": "MDF",
                            "Airports": [
                                {
                                    "Id": "MDF",
                                    "Name": "Medford",
                                    "CityId": "MDFA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.3057, 45.09941"
                                }
                            ]
                        },
                        {
                            "Id": "MDNA",
                            "Name": "Madison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.379958, 38.735893",
                            "IataCode": "MDN",
                            "Airports": [
                                {
                                    "Id": "MDN",
                                    "Name": "Madison",
                                    "CityId": "MDNA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.46135, 38.76061"
                                }
                            ]
                        },
                        {
                            "Id": "CHIA",
                            "Name": "Chicago",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.685713, 41.838063",
                            "IataCode": "CHI",
                            "Airports": [
                                {
                                    "Id": "PWK",
                                    "Name": "Chicago Pal-Waukee",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.908889, 41.980833"
                                },
                                {
                                    "Id": "NOH",
                                    "Name": "Chicago NAS",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.616667, 41.880556"
                                },
                                {
                                    "Id": "DPA",
                                    "Name": "Chicago Dupage County",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.246389, 41.914444"
                                },
                                {
                                    "Id": "CGX",
                                    "Name": "Chicago Merrill C Meigs",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.608333, 41.858889"
                                },
                                {
                                    "Id": "MDW",
                                    "Name": "Chicago Midway",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.751944, 41.785"
                                },
                                {
                                    "Id": "ORD",
                                    "Name": "Chicago O'Hare International",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.906111, 41.978056"
                                },
                                {
                                    "Id": "RFD",
                                    "Name": "Chicago Rockford",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.097222, 42.195278"
                                },
                                {
                                    "Id": "GYY",
                                    "Name": "Chicago Gary Regional",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.416667, 41.616667"
                                },
                                {
                                    "Id": "CHI",
                                    "Name": "Chicago FSS",
                                    "CityId": "CHIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.608331, 41.87389"
                                }
                            ]
                        },
                        {
                            "Id": "MEIA",
                            "Name": "Meridian",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.703656, 32.36431",
                            "IataCode": "MEI",
                            "Airports": [
                                {
                                    "Id": "MEI",
                                    "Name": "Meridian",
                                    "CityId": "MEIA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.749167, 32.337222"
                                }
                            ]
                        },
                        {
                            "Id": "MEJA",
                            "Name": "Meadville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-80.151448, 41.641444",
                            "IataCode": "MEJ",
                            "Airports": [
                                {
                                    "Id": "MEJ",
                                    "Name": "Meadville",
                                    "CityId": "MEJA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-80.166667, 41.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MEMA",
                            "Name": "Memphis",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-90.048981, 35.149536",
                            "IataCode": "MEM",
                            "Airports": [
                                {
                                    "Id": "NQA",
                                    "Name": "Memphis NAS",
                                    "CityId": "MEMA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-90.05, 35.133333"
                                },
                                {
                                    "Id": "MEM",
                                    "Name": "Memphis International",
                                    "CityId": "MEMA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-89.978889, 35.049722"
                                }
                            ]
                        },
                        {
                            "Id": "MEOA",
                            "Name": "Manteo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-75.67573, 35.908226",
                            "IataCode": "MEO",
                            "Airports": [
                                {
                                    "Id": "MEO",
                                    "Name": "Manteo",
                                    "CityId": "MEOA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-75.695278, 35.919167"
                                }
                            ]
                        },
                        {
                            "Id": "MEVA",
                            "Name": "Minden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-119.76573, 38.954074",
                            "IataCode": "MEV",
                            "Airports": [
                                {
                                    "Id": "MEV",
                                    "Name": "Minden",
                                    "CityId": "MEVA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-119.75, 39"
                                }
                            ]
                        },
                        {
                            "Id": "MFDA",
                            "Name": "Mansfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.515447, 40.75839",
                            "IataCode": "MFD",
                            "Airports": [
                                {
                                    "Id": "MFD",
                                    "Name": "Mansfield",
                                    "CityId": "MFDA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.512778, 40.82"
                                }
                            ]
                        },
                        {
                            "Id": "MFIA",
                            "Name": "Marshfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.171799, 44.668852",
                            "IataCode": "MFI",
                            "Airports": [
                                {
                                    "Id": "MFI",
                                    "Name": "Marshfield",
                                    "CityId": "MFIA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.186667, 44.633889"
                                }
                            ]
                        },
                        {
                            "Id": "MFRA",
                            "Name": "Medford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-122.87559, 42.326515",
                            "IataCode": "MFR",
                            "Airports": [
                                {
                                    "Id": "MFR",
                                    "Name": "Medford",
                                    "CityId": "MFRA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-122.87222, 42.373611"
                                }
                            ]
                        },
                        {
                            "Id": "MFVA",
                            "Name": "Melfa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-75.741318, 37.649299",
                            "IataCode": "MFV",
                            "Airports": [
                                {
                                    "Id": "MFV",
                                    "Name": "Melfa",
                                    "CityId": "MFVA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-75.760946, 37.647879"
                                }
                            ]
                        },
                        {
                            "Id": "MGCA",
                            "Name": "Michigan City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.89503, 41.707539",
                            "IataCode": "MGC",
                            "Airports": [
                                {
                                    "Id": "MGC",
                                    "Name": "Michigan City",
                                    "CityId": "MGCA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.82375, 41.704319"
                                }
                            ]
                        },
                        {
                            "Id": "MGJA",
                            "Name": "Montgomery",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-74.236816, 41.527594",
                            "IataCode": "MGJ",
                            "Airports": [
                                {
                                    "Id": "MGJ",
                                    "Name": "Montgomery",
                                    "CityId": "MGJA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.266667, 41.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MGMA",
                            "Name": "Montgomery",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.299969, 32.366805",
                            "IataCode": "MGM",
                            "Airports": [
                                {
                                    "Id": "MGM",
                                    "Name": "Montgomery Dannelly Fld",
                                    "CityId": "MGMA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.391111, 32.3"
                                },
                                {
                                    "Id": "MXF",
                                    "Name": "Montgomery Maxwell AFB",
                                    "CityId": "MGMA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.316667, 32.383333"
                                },
                                {
                                    "Id": "GUN",
                                    "Name": "Montgomery Gunter AFB",
                                    "CityId": "MGMA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.316667, 32.383333"
                                }
                            ]
                        },
                        {
                            "Id": "MGRA",
                            "Name": "Moultrie",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.789063, 31.179908",
                            "IataCode": "MGR",
                            "Airports": [
                                {
                                    "Id": "MGR",
                                    "Name": "Moultrie Thomasville",
                                    "CityId": "MGRA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.80643, 31.08631"
                                },
                                {
                                    "Id": "MUL",
                                    "Name": "Moultrie Spence",
                                    "CityId": "MGRA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.70884, 31.14044"
                                }
                            ]
                        },
                        {
                            "Id": "MGWA",
                            "Name": "Morgantown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-79.955897, 39.629526",
                            "IataCode": "MGW",
                            "Airports": [
                                {
                                    "Id": "MGW",
                                    "Name": "Morgantown",
                                    "CityId": "MGWA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-79.915833, 39.642778"
                                }
                            ]
                        },
                        {
                            "Id": "MHEA",
                            "Name": "Mitchell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-98.029799, 43.709428",
                            "IataCode": "MHE",
                            "Airports": [
                                {
                                    "Id": "MHE",
                                    "Name": "Mitchell",
                                    "CityId": "MHEA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-98.038056, 43.775278"
                                }
                            ]
                        },
                        {
                            "Id": "MHKA",
                            "Name": "Manhattan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-96.57167, 39.183608",
                            "IataCode": "MHK",
                            "Airports": [
                                {
                                    "Id": "MHK",
                                    "Name": "Manhattan",
                                    "CityId": "MHKA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-96.668889, 39.142222"
                                }
                            ]
                        },
                        {
                            "Id": "MHTA",
                            "Name": "Manchester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.454789, 42.995641",
                            "IataCode": "MHT",
                            "Airports": [
                                {
                                    "Id": "MHT",
                                    "Name": "Manchester",
                                    "CityId": "MHTA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.438889, 42.938333"
                                }
                            ]
                        },
                        {
                            "Id": "MHVA",
                            "Name": "Mojave",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.17396, 35.05247",
                            "IataCode": "MHV",
                            "Airports": [
                                {
                                    "Id": "MHV",
                                    "Name": "Mojave",
                                    "CityId": "MHVA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.15, 35.066667"
                                }
                            ]
                        },
                        {
                            "Id": "MOTA",
                            "Name": "Minot",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-101.29627, 48.232509",
                            "IataCode": "MOT",
                            "Airports": [
                                {
                                    "Id": "MOT",
                                    "Name": "Minot International",
                                    "CityId": "MOTA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-101.28083, 48.259722"
                                },
                                {
                                    "Id": "MIB",
                                    "Name": "Minot AFB",
                                    "CityId": "MOTA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-101.35766, 48.41557"
                                }
                            ]
                        },
                        {
                            "Id": "MIEA",
                            "Name": "Muncie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.38636, 40.193377",
                            "IataCode": "MIE",
                            "Airports": [
                                {
                                    "Id": "MIE",
                                    "Name": "Muncie",
                                    "CityId": "MIEA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.394444, 40.239722"
                                }
                            ]
                        },
                        {
                            "Id": "MIOA",
                            "Name": "Miami",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-94.877455, 36.874515",
                            "IataCode": "MIO",
                            "Airports": [
                                {
                                    "Id": "MIO",
                                    "Name": "Miami",
                                    "CityId": "MIOA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-94.887671, 36.908605"
                                }
                            ]
                        },
                        {
                            "Id": "OMAA",
                            "Name": "Omaha",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-95.937792, 41.258611",
                            "IataCode": "OMA",
                            "Airports": [
                                {
                                    "Id": "OMA",
                                    "Name": "Omaha Eppley Airfield",
                                    "CityId": "OMAA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-95.894167, 41.3025"
                                },
                                {
                                    "Id": "MIQ",
                                    "Name": "Omaha Millard",
                                    "CityId": "OMAA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.11372, 41.199194"
                                }
                            ]
                        },
                        {
                            "Id": "MITA",
                            "Name": "Shafter",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.27178, 35.500514",
                            "IataCode": "MIT",
                            "Airports": [
                                {
                                    "Id": "MIT",
                                    "Name": "Shafter",
                                    "CityId": "MITA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.26667, 35.5"
                                }
                            ]
                        },
                        {
                            "Id": "MIVA",
                            "Name": "Millville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-75.039344, 39.40206",
                            "IataCode": "MIV",
                            "Airports": [
                                {
                                    "Id": "MIV",
                                    "Name": "Millville",
                                    "CityId": "MIVA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-75.033333, 39.4"
                                }
                            ]
                        },
                        {
                            "Id": "MJXA",
                            "Name": "Toms River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.197919, 39.953729",
                            "IataCode": "MJX",
                            "Airports": [
                                {
                                    "Id": "MJX",
                                    "Name": "Toms River",
                                    "CityId": "MJXA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.2, 39.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MKEA",
                            "Name": "Milwaukee",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.906473, 43.038902",
                            "IataCode": "MKE",
                            "Airports": [
                                {
                                    "Id": "MWC",
                                    "Name": "Milwaukee Lawrence J Timmerman",
                                    "CityId": "MKEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.03426, 43.11084"
                                },
                                {
                                    "Id": "MKE",
                                    "Name": "Milwaukee General Mitchell",
                                    "CityId": "MKEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.897222, 42.950278"
                                }
                            ]
                        },
                        {
                            "Id": "MKGA",
                            "Name": "Muskegon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-86.248392, 43.234181",
                            "IataCode": "MKG",
                            "Airports": [
                                {
                                    "Id": "MKG",
                                    "Name": "Muskegon",
                                    "CityId": "MKGA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-86.2375, 43.170556"
                                }
                            ]
                        },
                        {
                            "Id": "MKLA",
                            "Name": "Jackson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-88.813947, 35.614517",
                            "IataCode": "MKL",
                            "Airports": [
                                {
                                    "Id": "MKL",
                                    "Name": "Jackson",
                                    "CityId": "MKLA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-88.915278, 35.601389"
                                }
                            ]
                        },
                        {
                            "Id": "MKTA",
                            "Name": "Mankato",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.9994, 44.163577",
                            "IataCode": "MKT",
                            "Airports": [
                                {
                                    "Id": "JMN",
                                    "Name": "Mankato Municipal Heliport",
                                    "CityId": "MKTA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.016667, 44.166667"
                                },
                                {
                                    "Id": "MKT",
                                    "Name": "Mankato Municipal",
                                    "CityId": "MKTA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.918333, 44.223611"
                                }
                            ]
                        },
                        {
                            "Id": "MLBA",
                            "Name": "Melbourne",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.608109, 28.083627",
                            "IataCode": "MLB",
                            "Airports": [
                                {
                                    "Id": "MLB",
                                    "Name": "Melbourne",
                                    "CityId": "MLBA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.630278, 28.1025"
                                }
                            ]
                        },
                        {
                            "Id": "MLFA",
                            "Name": "Milford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-113.01079, 38.396911",
                            "IataCode": "MLF",
                            "Airports": [
                                {
                                    "Id": "MLF",
                                    "Name": "Milford",
                                    "CityId": "MLFA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-113.01667, 38.433333"
                                }
                            ]
                        },
                        {
                            "Id": "MLIA",
                            "Name": "Moline",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.515134, 41.5067",
                            "IataCode": "MLI",
                            "Airports": [
                                {
                                    "Id": "MLI",
                                    "Name": "Moline",
                                    "CityId": "MLIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.505, 41.448889"
                                }
                            ]
                        },
                        {
                            "Id": "MLSA",
                            "Name": "Miles City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-105.84056, 46.408336",
                            "IataCode": "MLS",
                            "Airports": [
                                {
                                    "Id": "MLS",
                                    "Name": "Miles City",
                                    "CityId": "MLSA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-105.88451, 46.428865"
                                }
                            ]
                        },
                        {
                            "Id": "MLTA",
                            "Name": "Millinocket",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.709758, 45.657272",
                            "IataCode": "MLT",
                            "Airports": [
                                {
                                    "Id": "MLT",
                                    "Name": "Millinocket",
                                    "CityId": "MLTA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.716667, 45.65"
                                }
                            ]
                        },
                        {
                            "Id": "MLUA",
                            "Name": "Monroe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.119301, 32.509311",
                            "IataCode": "MLU",
                            "Airports": [
                                {
                                    "Id": "MLU",
                                    "Name": "Monroe",
                                    "CityId": "MLUA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.038889, 32.511111"
                                }
                            ]
                        },
                        {
                            "Id": "MLYA",
                            "Name": "Manley Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.63389, 65.001111",
                            "IataCode": "MLY",
                            "Airports": [
                                {
                                    "Id": "MLY",
                                    "Name": "Manley Hot Springs",
                                    "CityId": "MLYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.64306, 64.996667"
                                }
                            ]
                        },
                        {
                            "Id": "MMHA",
                            "Name": "Mammoth Lakes",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.97208, 37.648546",
                            "IataCode": "MMH",
                            "Airports": [
                                {
                                    "Id": "MMH",
                                    "Name": "Mammoth Lakes",
                                    "CityId": "MMHA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.84529, 37.630003"
                                }
                            ]
                        },
                        {
                            "Id": "MMIA",
                            "Name": "Athens",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-84.59299, 35.442851",
                            "IataCode": "MMI",
                            "Airports": [
                                {
                                    "Id": "MMI",
                                    "Name": "Athens",
                                    "CityId": "MMIA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-84.675694, 35.523028"
                                }
                            ]
                        },
                        {
                            "Id": "MMLA",
                            "Name": "Marshall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.788354, 44.446903",
                            "IataCode": "MML",
                            "Airports": [
                                {
                                    "Id": "MML",
                                    "Name": "Marshall",
                                    "CityId": "MMLA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.8175, 44.447222"
                                }
                            ]
                        },
                        {
                            "Id": "MMUA",
                            "Name": "Morristown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.481544, 40.796767",
                            "IataCode": "MMU",
                            "Airports": [
                                {
                                    "Id": "MMU",
                                    "Name": "Morristown",
                                    "CityId": "MMUA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.415833, 40.799167"
                                }
                            ]
                        },
                        {
                            "Id": "MNZA",
                            "Name": "Manassas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.475267, 38.750949",
                            "IataCode": "MNZ",
                            "Airports": [
                                {
                                    "Id": "MNZ",
                                    "Name": "Manassas",
                                    "CityId": "MNZA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.518056, 38.721944"
                                }
                            ]
                        },
                        {
                            "Id": "MODA",
                            "Name": "Modesto",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.99688, 37.639097",
                            "IataCode": "MOD",
                            "Airports": [
                                {
                                    "Id": "MOD",
                                    "Name": "Modesto",
                                    "CityId": "MODA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.95528, 37.626944"
                                }
                            ]
                        },
                        {
                            "Id": "MOPA",
                            "Name": "Mount Pleasant",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.767514, 43.597807",
                            "IataCode": "MOP",
                            "Airports": [
                                {
                                    "Id": "MOP",
                                    "Name": "Mount Pleasant",
                                    "CityId": "MOPA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.733333, 43.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MORB",
                            "Name": "Morristown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-83.294892, 36.213981",
                            "IataCode": "MOR",
                            "Airports": [
                                {
                                    "Id": "MOR",
                                    "Name": "Morristown",
                                    "CityId": "MORB",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-83.374815, 36.181324"
                                }
                            ]
                        },
                        {
                            "Id": "MOUA",
                            "Name": "Mountain Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.72944, 62.085556",
                            "IataCode": "MOU",
                            "Airports": [
                                {
                                    "Id": "MOU",
                                    "Name": "Mountain Village",
                                    "CityId": "MOUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.71667, 62.089444"
                                }
                            ]
                        },
                        {
                            "Id": "MOXA",
                            "Name": "Morris",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.91394, 45.586072",
                            "IataCode": "MOX",
                            "Airports": [
                                {
                                    "Id": "MOX",
                                    "Name": "Morris",
                                    "CityId": "MOXA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.916667, 45.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MPZA",
                            "Name": "Mt Pleasant",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.557938, 40.96364",
                            "IataCode": "MPZ",
                            "Airports": [
                                {
                                    "Id": "MPZ",
                                    "Name": "Mt Pleasant",
                                    "CityId": "MPZA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.510969, 40.946434"
                                }
                            ]
                        },
                        {
                            "Id": "MQBA",
                            "Name": "Macomb",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.671797, 40.459208",
                            "IataCode": "MQB",
                            "Airports": [
                                {
                                    "Id": "MQB",
                                    "Name": "Macomb",
                                    "CityId": "MQBA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.621389, 40.4525"
                                }
                            ]
                        },
                        {
                            "Id": "MQTA",
                            "Name": "Marquette",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-87.395417, 46.543544",
                            "IataCode": "MQT",
                            "Airports": [
                                {
                                    "Id": "MQT",
                                    "Name": "Marquette",
                                    "CityId": "MQTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-87.558333, 46.534167"
                                }
                            ]
                        },
                        {
                            "Id": "MQYA",
                            "Name": "Smyrna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-86.518605, 35.982841",
                            "IataCode": "MQY",
                            "Airports": [
                                {
                                    "Id": "MQY",
                                    "Name": "Smyrna",
                                    "CityId": "MQYA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.51999, 36.00895"
                                }
                            ]
                        },
                        {
                            "Id": "MRBA",
                            "Name": "Martinsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-77.963887, 39.45621",
                            "IataCode": "MRB",
                            "Airports": [
                                {
                                    "Id": "MRB",
                                    "Name": "Martinsburg",
                                    "CityId": "MRBA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-77.985, 39.401667"
                                }
                            ]
                        },
                        {
                            "Id": "MRFA",
                            "Name": "Marfa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-104.01907, 30.307938",
                            "IataCode": "MRF",
                            "Airports": [
                                {
                                    "Id": "MRF",
                                    "Name": "Marfa",
                                    "CityId": "MRFA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-104.01667, 30.3"
                                }
                            ]
                        },
                        {
                            "Id": "MRKA",
                            "Name": "Marco Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.71842, 25.94121",
                            "IataCode": "MRK",
                            "Airports": [
                                {
                                    "Id": "MRK",
                                    "Name": "Marco Island",
                                    "CityId": "MRKA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.723889, 25.944444"
                                }
                            ]
                        },
                        {
                            "Id": "MRNA",
                            "Name": "Morganton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-81.684819, 35.745407",
                            "IataCode": "MRN",
                            "Airports": [
                                {
                                    "Id": "MRN",
                                    "Name": "Morganton",
                                    "CityId": "MRNA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-81.683333, 35.75"
                                }
                            ]
                        },
                        {
                            "Id": "MSLA",
                            "Name": "Muscle Shoals",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.667529, 34.744811",
                            "IataCode": "MSL",
                            "Airports": [
                                {
                                    "Id": "MSL",
                                    "Name": "Muscle Shoals",
                                    "CityId": "MSLA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.616111, 34.748611"
                                }
                            ]
                        },
                        {
                            "Id": "MSNA",
                            "Name": "Madison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.40123, 43.073053",
                            "IataCode": "MSN",
                            "Airports": [
                                {
                                    "Id": "MSN",
                                    "Name": "Madison",
                                    "CityId": "MSNA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.336615, 43.140327"
                                }
                            ]
                        },
                        {
                            "Id": "MSOA",
                            "Name": "Missoula",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-113.994, 46.872146",
                            "IataCode": "MSO",
                            "Airports": [
                                {
                                    "Id": "MSO",
                                    "Name": "Missoula",
                                    "CityId": "MSOA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-114.09061, 46.916972"
                                }
                            ]
                        },
                        {
                            "Id": "MSSA",
                            "Name": "Massena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-74.891865, 44.928105",
                            "IataCode": "MSS",
                            "Airports": [
                                {
                                    "Id": "MSS",
                                    "Name": "Massena",
                                    "CityId": "MSSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.846667, 44.937778"
                                }
                            ]
                        },
                        {
                            "Id": "MSVA",
                            "Name": "Monticello",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-74.689328, 41.655646",
                            "IataCode": "MSV",
                            "Airports": [
                                {
                                    "Id": "MSV",
                                    "Name": "Monticello",
                                    "CityId": "MSVA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.795, 41.701389"
                                }
                            ]
                        },
                        {
                            "Id": "MTJA",
                            "Name": "Montrose",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.87617, 38.47832",
                            "IataCode": "MTJ",
                            "Airports": [
                                {
                                    "Id": "MTJ",
                                    "Name": "Montrose",
                                    "CityId": "MTJA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.89295, 38.508147"
                                }
                            ]
                        },
                        {
                            "Id": "MTMA",
                            "Name": "Metlakatla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.57222, 55.129167",
                            "IataCode": "MTM",
                            "Airports": [
                                {
                                    "Id": "MTM",
                                    "Name": "Metlakatla",
                                    "CityId": "MTMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.58333, 55.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MTPA",
                            "Name": "Montauk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-71.954515, 41.035935",
                            "IataCode": "MTP",
                            "Airports": [
                                {
                                    "Id": "MTP",
                                    "Name": "Montauk",
                                    "CityId": "MTPA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-71.923611, 41.074444"
                                }
                            ]
                        },
                        {
                            "Id": "MTWA",
                            "Name": "Manitowoc",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.657584, 44.088606",
                            "IataCode": "MTW",
                            "Airports": [
                                {
                                    "Id": "MTW",
                                    "Name": "Manitowoc",
                                    "CityId": "MTWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.682222, 44.129722"
                                }
                            ]
                        },
                        {
                            "Id": "MUOA",
                            "Name": "Mountain Home",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-115.6912, 43.13295",
                            "IataCode": "MUO",
                            "Airports": [
                                {
                                    "Id": "MUO",
                                    "Name": "Mountain Home",
                                    "CityId": "MUOA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-115.68333, 43.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MUTA",
                            "Name": "Muscatine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.043205, 41.424473",
                            "IataCode": "MUT",
                            "Airports": [
                                {
                                    "Id": "MUT",
                                    "Name": "Muscatine",
                                    "CityId": "MUTA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.145, 41.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MVCA",
                            "Name": "Monroeville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.324708, 31.52794",
                            "IataCode": "MVC",
                            "Airports": [
                                {
                                    "Id": "MVC",
                                    "Name": "Monroeville",
                                    "CityId": "MVCA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.333333, 31.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MVNA",
                            "Name": "Mt Vernon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.90312, 38.317271",
                            "IataCode": "MVN",
                            "Airports": [
                                {
                                    "Id": "MVN",
                                    "Name": "Mt Vernon-Outland",
                                    "CityId": "MVNA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.858611, 38.323056"
                                }
                            ]
                        },
                        {
                            "Id": "MWAA",
                            "Name": "Marion",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.933126, 37.730605",
                            "IataCode": "MWA",
                            "Airports": [
                                {
                                    "Id": "MWA",
                                    "Name": "Marion",
                                    "CityId": "MWAA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.016389, 37.751944"
                                }
                            ]
                        },
                        {
                            "Id": "MWHA",
                            "Name": "Moses Lake",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-119.27808, 47.130142",
                            "IataCode": "MWH",
                            "Airports": [
                                {
                                    "Id": "MWH",
                                    "Name": "Moses Lake Grant County",
                                    "CityId": "MWHA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.31556, 47.205"
                                },
                                {
                                    "Id": "LRN",
                                    "Name": "Moses Lake Larson AFB",
                                    "CityId": "MWHA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.28333, 47.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MWLA",
                            "Name": "Mineral Wells",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.112822, 32.80846",
                            "IataCode": "MWL",
                            "Airports": [
                                {
                                    "Id": "CWO",
                                    "Name": "Mineral Wells Ft Wolter AAF",
                                    "CityId": "MWLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.116667, 32.8"
                                },
                                {
                                    "Id": "MWL",
                                    "Name": "Mineral Wells Arpt",
                                    "CityId": "MWLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.066667, 32.783333"
                                }
                            ]
                        },
                        {
                            "Id": "MWOA",
                            "Name": "Middletown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.398277, 39.515058",
                            "IataCode": "MWO",
                            "Airports": [
                                {
                                    "Id": "MWO",
                                    "Name": "Middletown",
                                    "CityId": "MWOA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.4, 39.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MXEA",
                            "Name": "Maxton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.348932, 34.735161",
                            "IataCode": "MXE",
                            "Airports": [
                                {
                                    "Id": "MXE",
                                    "Name": "Maxton",
                                    "CityId": "MXEA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.36365, 34.78766"
                                }
                            ]
                        },
                        {
                            "Id": "MXOA",
                            "Name": "Monticello",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-91.187094, 42.238335",
                            "IataCode": "MXO",
                            "Airports": [
                                {
                                    "Id": "MXO",
                                    "Name": "Monticello",
                                    "CityId": "MXOA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-91.2, 42.25"
                                }
                            ]
                        },
                        {
                            "Id": "SANA",
                            "Name": "San Diego",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.13322, 32.817197",
                            "IataCode": "SAN",
                            "Airports": [
                                {
                                    "Id": "CLD",
                                    "Name": "McClellan-Palomar",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.27819, 33.128136"
                                },
                                {
                                    "Id": "NZY",
                                    "Name": "San Diego North Island NAS",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.2, 32.7"
                                },
                                {
                                    "Id": "SDM",
                                    "Name": "San Diego Brown Field Municipal",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.98, 32.572222"
                                },
                                {
                                    "Id": "SEE",
                                    "Name": "San Diego Gillespie Field",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.97222, 32.826111"
                                },
                                {
                                    "Id": "MYF",
                                    "Name": "San Diego Montgomery Field",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.14114, 32.81588"
                                },
                                {
                                    "Id": "NKX",
                                    "Name": "San Diego Miramar NAS",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.11667, 32.866667"
                                },
                                {
                                    "Id": "SAN",
                                    "Name": "San Diego International",
                                    "CityId": "SANA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.18722, 32.732778"
                                }
                            ]
                        },
                        {
                            "Id": "MYRA",
                            "Name": "Myrtle Beach",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-78.886694, 33.68906",
                            "IataCode": "MYR",
                            "Airports": [
                                {
                                    "Id": "CRE",
                                    "Name": "Myrtle Beach Grand Strand",
                                    "CityId": "MYRA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-78.724444, 33.811389"
                                },
                                {
                                    "Id": "MYR",
                                    "Name": "Myrtle Beach International Airport",
                                    "CityId": "MYRA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-78.929722, 33.679167"
                                }
                            ]
                        },
                        {
                            "Id": "MYUA",
                            "Name": "Mekoryuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.185, 60.388056",
                            "IataCode": "MYU",
                            "Airports": [
                                {
                                    "Id": "MYU",
                                    "Name": "Mekoryuk",
                                    "CityId": "MYUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.26806, 60.372778"
                                }
                            ]
                        },
                        {
                            "Id": "BABB",
                            "Name": "Marysville",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.59135, 39.145725",
                            "IataCode": "MYV",
                            "Airports": [
                                {
                                    "Id": "BAB",
                                    "Name": "Marysville Beale AFB",
                                    "CityId": "BABB",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.5833, 39.15"
                                },
                                {
                                    "Id": "MYV",
                                    "Name": "Marysville Yuba County",
                                    "CityId": "BABB",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.56972, 39.098056"
                                }
                            ]
                        },
                        {
                            "Id": "MZJA",
                            "Name": "Marana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.22538, 32.436738",
                            "IataCode": "MZJ",
                            "Airports": [
                                {
                                    "Id": "MZJ",
                                    "Name": "Marana",
                                    "CityId": "MZJA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.33333, 32.516667"
                                }
                            ]
                        },
                        {
                            "Id": "JRFA",
                            "Name": "Kapolei",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-158.095, 21.333",
                            "IataCode": "JRF",
                            "Airports": [
                                {
                                    "Id": "JRF",
                                    "Name": "Kalaeloa",
                                    "CityId": "JRFA",
                                    "CountryId": "US",
                                    "Location": "-158.07175, 21.309702"
                                }
                            ]
                        },
                        {
                            "Id": "NELA",
                            "Name": "Lakehurst",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.311257, 40.014561",
                            "IataCode": "NEL",
                            "Airports": [
                                {
                                    "Id": "NEL",
                                    "Name": "Lakehurst",
                                    "CityId": "NELA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.316667, 40.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ORFA",
                            "Name": "Norfolk",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-76.285218, 36.846815",
                            "IataCode": "ORF",
                            "Airports": [
                                {
                                    "Id": "NGU",
                                    "Name": "Norfolk NAS Chambers",
                                    "CityId": "ORFA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.29115, 36.93012"
                                },
                                {
                                    "Id": "ORF",
                                    "Name": "Norfolk International",
                                    "CityId": "ORFA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.201389, 36.895833"
                                }
                            ]
                        },
                        {
                            "Id": "NHXA",
                            "Name": "Foley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.683597, 30.406587",
                            "IataCode": "NHX",
                            "Airports": [
                                {
                                    "Id": "NHX",
                                    "Name": "Foley",
                                    "CityId": "NHXA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.683333, 30.4"
                                }
                            ]
                        },
                        {
                            "Id": "NHZA",
                            "Name": "Brunswick",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.965328, 43.914524",
                            "IataCode": "NHZ",
                            "Airports": [
                                {
                                    "Id": "NHZ",
                                    "Name": "Brunswick",
                                    "CityId": "NHZA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.93861, 43.89222"
                                }
                            ]
                        },
                        {
                            "Id": "NIBA",
                            "Name": "Nikolai",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.375, 63.013333",
                            "IataCode": "NIB",
                            "Airports": [
                                {
                                    "Id": "NIB",
                                    "Name": "Nikolai",
                                    "CityId": "NIBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.38333, 63.016667"
                                }
                            ]
                        },
                        {
                            "Id": "NLCA",
                            "Name": "Lemoore",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.78291, 36.300783",
                            "IataCode": "NLC",
                            "Airports": [
                                {
                                    "Id": "NLC",
                                    "Name": "Lemoore",
                                    "CityId": "NLCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.82597, 36.33301"
                                }
                            ]
                        },
                        {
                            "Id": "NLGA",
                            "Name": "Nelson Lagoon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.43682, 55.90749",
                            "IataCode": "NLG",
                            "Airports": [
                                {
                                    "Id": "NLG",
                                    "Name": "Nelson Lagoon",
                                    "CityId": "NLGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.1525, 56.001389"
                                }
                            ]
                        },
                        {
                            "Id": "NMEA",
                            "Name": "Nightmute",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.72389, 60.479444",
                            "IataCode": "NME",
                            "Airports": [
                                {
                                    "Id": "NME",
                                    "Name": "Nightmute",
                                    "CityId": "NMEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.68556, 60.47028"
                                }
                            ]
                        },
                        {
                            "Id": "NNKA",
                            "Name": "Naknek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.01389, 58.728333",
                            "IataCode": "NNK",
                            "Airports": [
                                {
                                    "Id": "NNK",
                                    "Name": "Naknek",
                                    "CityId": "NNKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.01694, 58.733333"
                                }
                            ]
                        },
                        {
                            "Id": "NNLA",
                            "Name": "Nondalton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.84583, 59.973611",
                            "IataCode": "NNL",
                            "Airports": [
                                {
                                    "Id": "NNL",
                                    "Name": "Nondalton",
                                    "CityId": "NNLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.81667, 60"
                                }
                            ]
                        },
                        {
                            "Id": "POFA",
                            "Name": "Poplar Bluff",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-90.392888, 36.756999",
                            "IataCode": "POF",
                            "Airports": [
                                {
                                    "Id": "POF",
                                    "Name": "Poplar Bluff",
                                    "CityId": "POFA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.313889, 36.711111"
                                }
                            ]
                        },
                        {
                            "Id": "POHA",
                            "Name": "Pocahontas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.669147, 42.73553",
                            "IataCode": "POH",
                            "Airports": [
                                {
                                    "Id": "POH",
                                    "Name": "Pocahontas",
                                    "CityId": "POHA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.644167, 42.742222"
                                }
                            ]
                        },
                        {
                            "Id": "NOTA",
                            "Name": "Novato",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.5697, 38.10742",
                            "IataCode": "NOT",
                            "Airports": [
                                {
                                    "Id": "NOT",
                                    "Name": "Novato",
                                    "CityId": "NOTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.55583, 38.143333"
                                }
                            ]
                        },
                        {
                            "Id": "PNSA",
                            "Name": "Pensacola",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-87.216915, 30.421309",
                            "IataCode": "PNS",
                            "Airports": [
                                {
                                    "Id": "PNS",
                                    "Name": "Pensacola",
                                    "CityId": "PNSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-87.188333, 30.4725"
                                },
                                {
                                    "Id": "NDP",
                                    "Name": "Pensacola Ellyson NAS",
                                    "CityId": "PNSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-87.188333, 30.4725"
                                },
                                {
                                    "Id": "NPA",
                                    "Name": "Pensacola NAS",
                                    "CityId": "PNSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-87.31865, 30.35266"
                                },
                                {
                                    "Id": "NUN",
                                    "Name": "Pensacola Saufley NAS",
                                    "CityId": "PNSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-87.188333, 30.4725"
                                }
                            ]
                        },
                        {
                            "Id": "NPTA",
                            "Name": "Newport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "RHODE",
                            "Location": "-71.312828, 41.490102",
                            "IataCode": "NPT",
                            "Airports": [
                                {
                                    "Id": "NPT",
                                    "Name": "Newport",
                                    "CityId": "NPTA",
                                    "CountryId": "US",
                                    "RegionId": "RHODE",
                                    "Location": "-71.2825, 41.530833"
                                }
                            ]
                        },
                        {
                            "Id": "NQIA",
                            "Name": "Kingsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.856109, 27.515869",
                            "IataCode": "NQI",
                            "Airports": [
                                {
                                    "Id": "NQI",
                                    "Name": "Kingsville",
                                    "CityId": "NQIA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.80972, 27.50722"
                                }
                            ]
                        },
                        {
                            "Id": "NRBA",
                            "Name": "Mayport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.430642, 30.393296",
                            "IataCode": "NRB",
                            "Airports": [
                                {
                                    "Id": "NRB",
                                    "Name": "Mayport",
                                    "CityId": "NRBA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.65, 30.333333"
                                }
                            ]
                        },
                        {
                            "Id": "NRCA",
                            "Name": "Crows Landing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.0716, 37.393824",
                            "IataCode": "NRC",
                            "Airports": [
                                {
                                    "Id": "NRC",
                                    "Name": "Crows Landing",
                                    "CityId": "NRCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.1089, 37.407324"
                                }
                            ]
                        },
                        {
                            "Id": "NSEA",
                            "Name": "Milton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-87.039688, 30.632415",
                            "IataCode": "NSE",
                            "Airports": [
                                {
                                    "Id": "NSE",
                                    "Name": "Milton",
                                    "CityId": "NSEA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-87.05, 30.633333"
                                }
                            ]
                        },
                        {
                            "Id": "NTUA",
                            "Name": "Oceana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-75.977985, 36.852926",
                            "IataCode": "NTU",
                            "Airports": [
                                {
                                    "Id": "NTU",
                                    "Name": "Oceana",
                                    "CityId": "NTUA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.033333, 36.816667"
                                }
                            ]
                        },
                        {
                            "Id": "NUIA",
                            "Name": "Nuiqsut",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.97639, 70.2175",
                            "IataCode": "NUI",
                            "Airports": [
                                {
                                    "Id": "NUI",
                                    "Name": "Nuiqsut",
                                    "CityId": "NUIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.00556, 70.209722"
                                }
                            ]
                        },
                        {
                            "Id": "NULA",
                            "Name": "Nulato",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.10306, 64.719444",
                            "IataCode": "NUL",
                            "Airports": [
                                {
                                    "Id": "NUL",
                                    "Name": "Nulato",
                                    "CityId": "NULA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.07667, 64.727778"
                                }
                            ]
                        },
                        {
                            "Id": "NUQA",
                            "Name": "Mountain View",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.08385, 37.386052",
                            "IataCode": "NUQ",
                            "Airports": [
                                {
                                    "Id": "NUQ",
                                    "Name": "Mountain View",
                                    "CityId": "NUQA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.97329, 37.38522"
                                }
                            ]
                        },
                        {
                            "Id": "ODWA",
                            "Name": "Oak Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.64322, 48.293156",
                            "IataCode": "ODW",
                            "Airports": [
                                {
                                    "Id": "ODW",
                                    "Name": "Oak Harbor",
                                    "CityId": "ODWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.68611, 48.251389"
                                }
                            ]
                        },
                        {
                            "Id": "NXXA",
                            "Name": "Willow Grove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.115729, 40.143998",
                            "IataCode": "NXX",
                            "Airports": [
                                {
                                    "Id": "NXX",
                                    "Name": "Willow Grove",
                                    "CityId": "NXXA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.133333, 40.2"
                                }
                            ]
                        },
                        {
                            "Id": "NYGA",
                            "Name": "Quantico",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.293593, 38.522343",
                            "IataCode": "NYG",
                            "Airports": [
                                {
                                    "Id": "NYG",
                                    "Name": "Quantico",
                                    "CityId": "NYGA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.304869, 38.50452"
                                }
                            ]
                        },
                        {
                            "Id": "OAJA",
                            "Name": "Jacksonville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.430241, 34.754052",
                            "IataCode": "OAJ",
                            "Airports": [
                                {
                                    "Id": "OAJ",
                                    "Name": "Jacksonville",
                                    "CityId": "OAJA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.611667, 34.830278"
                                }
                            ]
                        },
                        {
                            "Id": "OBEA",
                            "Name": "Okeechobee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.829783, 27.243935",
                            "IataCode": "OBE",
                            "Airports": [
                                {
                                    "Id": "OBE",
                                    "Name": "Okeechobee",
                                    "CityId": "OBEA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.84951, 27.2631"
                                }
                            ]
                        },
                        {
                            "Id": "OBUA",
                            "Name": "Kobuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.88111, 66.907222",
                            "IataCode": "OBU",
                            "Airports": [
                                {
                                    "Id": "OBU",
                                    "Name": "Kobuk / Wien",
                                    "CityId": "OBUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.88333, 66.910556"
                                }
                            ]
                        },
                        {
                            "Id": "OCEA",
                            "Name": "Ocean City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-75.084906, 38.336503",
                            "IataCode": "OCE",
                            "Airports": [
                                {
                                    "Id": "OCE",
                                    "Name": "Ocean City",
                                    "CityId": "OCEA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-75.12407, 38.31206"
                                }
                            ]
                        },
                        {
                            "Id": "OCFA",
                            "Name": "Ocala",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.140092, 29.187199",
                            "IataCode": "OCF",
                            "Airports": [
                                {
                                    "Id": "OCF",
                                    "Name": "Ocala",
                                    "CityId": "OCFA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.226944, 29.175556"
                                }
                            ]
                        },
                        {
                            "Id": "OCHA",
                            "Name": "Nacogdoches",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.655487, 31.603513",
                            "IataCode": "OCH",
                            "Airports": [
                                {
                                    "Id": "OCH",
                                    "Name": "Nacogdoches AL Mangham Jr",
                                    "CityId": "OCHA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.7, 31.583333"
                                },
                                {
                                    "Id": "LFK",
                                    "Name": "Nacogdoches Lufkin Angelina County",
                                    "CityId": "OCHA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.750278, 31.234167"
                                }
                            ]
                        },
                        {
                            "Id": "OCNA",
                            "Name": "Oceanside",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.37948, 33.19587",
                            "IataCode": "OCN",
                            "Airports": [
                                {
                                    "Id": "OCN",
                                    "Name": "Oceanside",
                                    "CityId": "OCNA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.35333, 33.218611"
                                }
                            ]
                        },
                        {
                            "Id": "OEAA",
                            "Name": "Vincennes",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.528632, 38.677269",
                            "IataCode": "OEA",
                            "Airports": [
                                {
                                    "Id": "OEA",
                                    "Name": "Vincennes",
                                    "CityId": "OEAA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.533333, 38.683333"
                                }
                            ]
                        },
                        {
                            "Id": "OEOA",
                            "Name": "Osceola",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-92.70493, 45.32052",
                            "IataCode": "OEO",
                            "Airports": [
                                {
                                    "Id": "OEO",
                                    "Name": "Osceola",
                                    "CityId": "OEOA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-92.7, 45.3"
                                }
                            ]
                        },
                        {
                            "Id": "OFKA",
                            "Name": "Norfolk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-97.416996, 42.028338",
                            "IataCode": "OFK",
                            "Airports": [
                                {
                                    "Id": "OFK",
                                    "Name": "Norfolk",
                                    "CityId": "OFKA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-97.434167, 41.984167"
                                }
                            ]
                        },
                        {
                            "Id": "OGBA",
                            "Name": "Orangeburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.855648, 33.49182",
                            "IataCode": "OGB",
                            "Airports": [
                                {
                                    "Id": "OGB",
                                    "Name": "Orangeburg",
                                    "CityId": "OGBA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.85787, 33.46182"
                                }
                            ]
                        },
                        {
                            "Id": "OGGA",
                            "Name": "Kahului",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-156.47, 20.894722",
                            "IataCode": "OGG",
                            "Airports": [
                                {
                                    "Id": "OGG",
                                    "Name": "Maui Kahului",
                                    "CityId": "OGGA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.43361, 20.900556"
                                }
                            ]
                        },
                        {
                            "Id": "OGSA",
                            "Name": "Ogdensburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.486336, 44.694229",
                            "IataCode": "OGS",
                            "Airports": [
                                {
                                    "Id": "OGS",
                                    "Name": "Ogdensburg",
                                    "CityId": "OGSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.465833, 44.681667"
                                }
                            ]
                        },
                        {
                            "Id": "OICA",
                            "Name": "Norwich",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.523515, 42.531184",
                            "IataCode": "OIC",
                            "Airports": [
                                {
                                    "Id": "OIC",
                                    "Name": "Norwich",
                                    "CityId": "OICA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.533333, 42.533333"
                                }
                            ]
                        },
                        {
                            "Id": "OKKA",
                            "Name": "Kokomo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.133603, 40.486427",
                            "IataCode": "OKK",
                            "Airports": [
                                {
                                    "Id": "OKK",
                                    "Name": "Kokomo",
                                    "CityId": "OKKA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.058056, 40.529722"
                                }
                            ]
                        },
                        {
                            "Id": "OKMA",
                            "Name": "Okmulgee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.96055, 35.623437",
                            "IataCode": "OKM",
                            "Airports": [
                                {
                                    "Id": "OKM",
                                    "Name": "Okmulgee",
                                    "CityId": "OKMA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.966667, 35.616667"
                                }
                            ]
                        },
                        {
                            "Id": "OLDA",
                            "Name": "Old Town",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.645309, 44.934235",
                            "IataCode": "OLD",
                            "Airports": [
                                {
                                    "Id": "OLD",
                                    "Name": "Old Town",
                                    "CityId": "OLDA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.67433, 44.95275"
                                }
                            ]
                        },
                        {
                            "Id": "OLFA",
                            "Name": "Wolf Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-105.64056, 48.090574",
                            "IataCode": "OLF",
                            "Airports": [
                                {
                                    "Id": "OLF",
                                    "Name": "Wolf Point",
                                    "CityId": "OLFA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-105.57587, 48.094592"
                                }
                            ]
                        },
                        {
                            "Id": "OLMA",
                            "Name": "Olympia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.9007, 47.037874",
                            "IataCode": "OLM",
                            "Airports": [
                                {
                                    "Id": "OLM",
                                    "Name": "Olympia",
                                    "CityId": "OLMA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.90333, 46.973611"
                                }
                            ]
                        },
                        {
                            "Id": "OLSA",
                            "Name": "Nogales",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.93425, 31.340377",
                            "IataCode": "OLS",
                            "Airports": [
                                {
                                    "Id": "OLS",
                                    "Name": "Nogales",
                                    "CityId": "OLSA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.85, 31.416667"
                                }
                            ]
                        },
                        {
                            "Id": "OLUA",
                            "Name": "Columbus",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-97.368375, 41.429734",
                            "IataCode": "OLU",
                            "Airports": [
                                {
                                    "Id": "OLU",
                                    "Name": "Columbus",
                                    "CityId": "OLUA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-97.341667, 41.447222"
                                }
                            ]
                        },
                        {
                            "Id": "OLVA",
                            "Name": "Olive Branch",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.829531, 34.96176",
                            "IataCode": "OLV",
                            "Airports": [
                                {
                                    "Id": "OLV",
                                    "Name": "Olive Branch",
                                    "CityId": "OLVA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.833333, 34.966667"
                                }
                            ]
                        },
                        {
                            "Id": "OMEA",
                            "Name": "Nome",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.40639, 64.501111",
                            "IataCode": "OME",
                            "Airports": [
                                {
                                    "Id": "OME",
                                    "Name": "Nome",
                                    "CityId": "OMEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.44167, 64.511389"
                                }
                            ]
                        },
                        {
                            "Id": "OMKA",
                            "Name": "Omak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-119.52755, 48.410985",
                            "IataCode": "OMK",
                            "Airports": [
                                {
                                    "Id": "OMK",
                                    "Name": "Omak",
                                    "CityId": "OMKA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.51838, 48.46432"
                                }
                            ]
                        },
                        {
                            "Id": "ONAA",
                            "Name": "Winona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-91.639315, 44.049963",
                            "IataCode": "ONA",
                            "Airports": [
                                {
                                    "Id": "ONA",
                                    "Name": "Winona",
                                    "CityId": "ONAA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-91.706111, 44.076667"
                                }
                            ]
                        },
                        {
                            "Id": "ONLA",
                            "Name": "Oneill",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-98.647587, 42.457781",
                            "IataCode": "ONL",
                            "Airports": [
                                {
                                    "Id": "ONL",
                                    "Name": "Oneill",
                                    "CityId": "ONLA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-98.65, 42.45"
                                }
                            ]
                        },
                        {
                            "Id": "ONOA",
                            "Name": "Ontario",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-116.96294, 44.026552",
                            "IataCode": "ONO",
                            "Airports": [
                                {
                                    "Id": "ONO",
                                    "Name": "Ontario",
                                    "CityId": "ONOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-117.01389, 44.022778"
                                }
                            ]
                        },
                        {
                            "Id": "ONPA",
                            "Name": "Newport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-124.05345, 44.636784",
                            "IataCode": "ONP",
                            "Airports": [
                                {
                                    "Id": "ONP",
                                    "Name": "Newport",
                                    "CityId": "ONPA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-124.05817, 44.57984"
                                }
                            ]
                        },
                        {
                            "Id": "ONTA",
                            "Name": "Ontario",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.65089, 34.063344",
                            "IataCode": "ONT",
                            "Airports": [
                                {
                                    "Id": "ONT",
                                    "Name": "Ontario International",
                                    "CityId": "ONTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.60806, 34.057222"
                                }
                            ]
                        },
                        {
                            "Id": "OOKA",
                            "Name": "Toksook Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.1025, 60.530278",
                            "IataCode": "OOK",
                            "Airports": [
                                {
                                    "Id": "OOK",
                                    "Name": "Toksook Bay",
                                    "CityId": "OOKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.10833, 60.531944"
                                }
                            ]
                        },
                        {
                            "Id": "OPLA",
                            "Name": "Opelousas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.081509, 30.53353",
                            "IataCode": "OPL",
                            "Airports": [
                                {
                                    "Id": "OPL",
                                    "Name": "Opelousas",
                                    "CityId": "OPLA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.083333, 30.533333"
                                }
                            ]
                        },
                        {
                            "Id": "ORHA",
                            "Name": "Worcester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.802293, 42.262593",
                            "IataCode": "ORH",
                            "Airports": [
                                {
                                    "Id": "ORH",
                                    "Name": "Worcester",
                                    "CityId": "ORHA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.874444, 42.269167"
                                }
                            ]
                        },
                        {
                            "Id": "ORTA",
                            "Name": "Northway",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-141.93722, 62.961667",
                            "IataCode": "ORT",
                            "Airports": [
                                {
                                    "Id": "ORT",
                                    "Name": "Northway",
                                    "CityId": "ORTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-141.92389, 62.961944"
                                }
                            ]
                        },
                        {
                            "Id": "OSCA",
                            "Name": "Oscoda",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.330801, 44.420293",
                            "IataCode": "OSC",
                            "Airports": [
                                {
                                    "Id": "OSC",
                                    "Name": "Oscoda",
                                    "CityId": "OSCA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.38025, 44.45252"
                                }
                            ]
                        },
                        {
                            "Id": "OSHA",
                            "Name": "Oshkosh",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.542613, 44.024706",
                            "IataCode": "OSH",
                            "Airports": [
                                {
                                    "Id": "OSH",
                                    "Name": "Oshkosh",
                                    "CityId": "OSHA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.556944, 43.983889"
                                }
                            ]
                        },
                        {
                            "Id": "OTHA",
                            "Name": "North Bend",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-124.22428, 43.406501",
                            "IataCode": "OTH",
                            "Airports": [
                                {
                                    "Id": "OTH",
                                    "Name": "North Bend",
                                    "CityId": "OTHA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-124.24, 43.418889"
                                }
                            ]
                        },
                        {
                            "Id": "OTMA",
                            "Name": "Ottumwa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-92.411296, 41.020014",
                            "IataCode": "OTM",
                            "Airports": [
                                {
                                    "Id": "OTM",
                                    "Name": "Ottumwa",
                                    "CityId": "OTMA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-92.449167, 41.106389"
                                }
                            ]
                        },
                        {
                            "Id": "OTZA",
                            "Name": "Kotzebue",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.59667, 66.898333",
                            "IataCode": "OTZ",
                            "Airports": [
                                {
                                    "Id": "OTZ",
                                    "Name": "Kotzebue",
                                    "CityId": "OTZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.60583, 66.888056"
                                }
                            ]
                        },
                        {
                            "Id": "OUNA",
                            "Name": "Norman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.439478, 35.222567",
                            "IataCode": "OUN",
                            "Airports": [
                                {
                                    "Id": "OUN",
                                    "Name": "Norman",
                                    "CityId": "OUNA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.44003, 35.22923"
                                }
                            ]
                        },
                        {
                            "Id": "OVEA",
                            "Name": "Oroville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.55636, 39.513775",
                            "IataCode": "OVE",
                            "Airports": [
                                {
                                    "Id": "OVE",
                                    "Name": "Oroville",
                                    "CityId": "OVEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.61667, 39.5"
                                }
                            ]
                        },
                        {
                            "Id": "OWAA",
                            "Name": "Owatonna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-93.226044, 44.083852",
                            "IataCode": "OWA",
                            "Airports": [
                                {
                                    "Id": "OWA",
                                    "Name": "Owatonna",
                                    "CityId": "OWAA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-93.166667, 44.1"
                                }
                            ]
                        },
                        {
                            "Id": "OWBA",
                            "Name": "Owensboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-87.11333, 37.774215",
                            "IataCode": "OWB",
                            "Airports": [
                                {
                                    "Id": "OWB",
                                    "Name": "Owensboro",
                                    "CityId": "OWBA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-87.165833, 37.740833"
                                }
                            ]
                        },
                        {
                            "Id": "OWDA",
                            "Name": "Norwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.199497, 42.194543",
                            "IataCode": "OWD",
                            "Airports": [
                                {
                                    "Id": "OWD",
                                    "Name": "Norwood",
                                    "CityId": "OWDA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.17255, 42.19065"
                                }
                            ]
                        },
                        {
                            "Id": "OWKA",
                            "Name": "Norridgewock",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.790608, 44.713118",
                            "IataCode": "OWK",
                            "Airports": [
                                {
                                    "Id": "OWK",
                                    "Name": "Norridgewock",
                                    "CityId": "OWKA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.86218, 44.71721"
                                }
                            ]
                        },
                        {
                            "Id": "OXCA",
                            "Name": "Oxford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-73.116777, 41.433985",
                            "IataCode": "OXC",
                            "Airports": [
                                {
                                    "Id": "OXC",
                                    "Name": "Waterbury-Oxford",
                                    "CityId": "OXCA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-73.136389, 41.479167"
                                }
                            ]
                        },
                        {
                            "Id": "OXDA",
                            "Name": "Oxford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-84.745231, 39.506997",
                            "IataCode": "OXD",
                            "Airports": [
                                {
                                    "Id": "OXD",
                                    "Name": "Oxford",
                                    "CityId": "OXDA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-84.761111, 39.860278"
                                }
                            ]
                        },
                        {
                            "Id": "OXRA",
                            "Name": "Oxnard",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.17705, 34.197505",
                            "IataCode": "OXR",
                            "Airports": [
                                {
                                    "Id": "OXR",
                                    "Name": "Oxnard",
                                    "CityId": "OXRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.20639, 34.201111"
                                }
                            ]
                        },
                        {
                            "Id": "PAEA",
                            "Name": "Everett",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.20208, 47.978985",
                            "IataCode": "PAE",
                            "Airports": [
                                {
                                    "Id": "PAE",
                                    "Name": "Everett",
                                    "CityId": "PAEA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.28444, 47.909722"
                                }
                            ]
                        },
                        {
                            "Id": "PAHA",
                            "Name": "Paducah",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-88.600048, 37.083389",
                            "IataCode": "PAH",
                            "Airports": [
                                {
                                    "Id": "PAH",
                                    "Name": "Paducah",
                                    "CityId": "PAHA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-88.7725, 37.061111"
                                }
                            ]
                        },
                        {
                            "Id": "PAKA",
                            "Name": "Hanapepe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-159.59528, 21.911944",
                            "IataCode": "PAK",
                            "Airports": [
                                {
                                    "Id": "PAK",
                                    "Name": "Hanapepe",
                                    "CityId": "PAKA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-159.60306, 21.89667"
                                }
                            ]
                        },
                        {
                            "Id": "PAOA",
                            "Name": "Palo Alto",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.14302, 37.441883",
                            "IataCode": "PAO",
                            "Airports": [
                                {
                                    "Id": "PAO",
                                    "Name": "Palo Alto",
                                    "CityId": "PAOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.11389, 37.460833"
                                }
                            ]
                        },
                        {
                            "Id": "PAQA",
                            "Name": "Palmer",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.11278, 61.599722",
                            "IataCode": "PAQ",
                            "Airports": [
                                {
                                    "Id": "PAQ",
                                    "Name": "Palmer",
                                    "CityId": "PAQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.11667, 61.6"
                                }
                            ]
                        },
                        {
                            "Id": "PBFA",
                            "Name": "Pine Bluff",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.003196, 34.228431",
                            "IataCode": "PBF",
                            "Airports": [
                                {
                                    "Id": "PBF",
                                    "Name": "Pine Bluff",
                                    "CityId": "PBFA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.935556, 34.175556"
                                }
                            ]
                        },
                        {
                            "Id": "PLBA",
                            "Name": "Plattsburgh",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.452912, 44.699487",
                            "IataCode": "PLB",
                            "Airports": [
                                {
                                    "Id": "PLB",
                                    "Name": "Plattsburgh Clinton County",
                                    "CityId": "PLBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.521111, 44.688333"
                                },
                                {
                                    "Id": "PBG",
                                    "Name": "Plattsburgh AFB",
                                    "CityId": "PLBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.468056, 44.650833"
                                }
                            ]
                        },
                        {
                            "Id": "PDBA",
                            "Name": "Pedro Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.10611, 59.787222",
                            "IataCode": "PDB",
                            "Airports": [
                                {
                                    "Id": "PDB",
                                    "Name": "Pedro Bay",
                                    "CityId": "PDBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.11667, 59.783333"
                                }
                            ]
                        },
                        {
                            "Id": "PDTA",
                            "Name": "Pendleton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-118.7886, 45.672075",
                            "IataCode": "PDT",
                            "Airports": [
                                {
                                    "Id": "PDT",
                                    "Name": "Pendleton",
                                    "CityId": "PDTA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-118.83611, 45.698333"
                                }
                            ]
                        },
                        {
                            "Id": "PFCA",
                            "Name": "Pacific City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.96289, 45.202326",
                            "IataCode": "PFC",
                            "Airports": [
                                {
                                    "Id": "PFC",
                                    "Name": "Pacific City",
                                    "CityId": "PFCA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.95, 45.233333"
                                }
                            ]
                        },
                        {
                            "Id": "PGAA",
                            "Name": "Page",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.45583, 36.914722",
                            "IataCode": "PGA",
                            "Airports": [
                                {
                                    "Id": "PGA",
                                    "Name": "Page",
                                    "CityId": "PGAA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.44722, 36.926389"
                                }
                            ]
                        },
                        {
                            "Id": "PGDA",
                            "Name": "Punta Gorda",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.045366, 26.929784",
                            "IataCode": "PGD",
                            "Airports": [
                                {
                                    "Id": "PGD",
                                    "Name": "Punta Gorda",
                                    "CityId": "PGDA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.991389, 26.919167"
                                }
                            ]
                        },
                        {
                            "Id": "PGLA",
                            "Name": "Pascagoula",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.556127, 30.365755",
                            "IataCode": "PGL",
                            "Airports": [
                                {
                                    "Id": "PGL",
                                    "Name": "Pascagoula",
                                    "CityId": "PGLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.516667, 30.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PGOA",
                            "Name": "Pagosa Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.00976, 37.26945",
                            "IataCode": "PGO",
                            "Airports": [
                                {
                                    "Id": "PGO",
                                    "Name": "Pagosa Springs",
                                    "CityId": "PGOA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.05083, 37.268611"
                                }
                            ]
                        },
                        {
                            "Id": "PGVA",
                            "Name": "Greenville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.366353, 35.612661",
                            "IataCode": "PGV",
                            "Airports": [
                                {
                                    "Id": "PGV",
                                    "Name": "Pitt-Greenville",
                                    "CityId": "PGVA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.385278, 35.633333"
                                }
                            ]
                        },
                        {
                            "Id": "PHFA",
                            "Name": "Newport News",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-76.428003, 36.978759",
                            "IataCode": "PHF",
                            "Airports": [
                                {
                                    "Id": "LFI",
                                    "Name": "Newport News Langley AFB",
                                    "CityId": "PHFA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.36055, 37.08288"
                                },
                                {
                                    "Id": "PHF",
                                    "Name": "Newport News / Williamsb",
                                    "CityId": "PHFA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.493889, 37.130833"
                                }
                            ]
                        },
                        {
                            "Id": "PHKA",
                            "Name": "Pahokee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.665335, 26.820061",
                            "IataCode": "PHK",
                            "Airports": [
                                {
                                    "Id": "PHK",
                                    "Name": "Pahokee",
                                    "CityId": "PHKA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.69311, 26.78617"
                                }
                            ]
                        },
                        {
                            "Id": "PHLA",
                            "Name": "Philadelphia",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.163789, 39.952339",
                            "IataCode": "PHL",
                            "Airports": [
                                {
                                    "Id": "PNE",
                                    "Name": "North Philadelphia",
                                    "CityId": "PHLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.0125, 40.080278"
                                },
                                {
                                    "Id": "MUV",
                                    "Name": "Philadelphia Mustin Alf",
                                    "CityId": "PHLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.248611, 39.868056"
                                },
                                {
                                    "Id": "PSQ",
                                    "Name": "Philadelphia SPB",
                                    "CityId": "PHLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.248611, 39.868056"
                                },
                                {
                                    "Id": "PHL",
                                    "Name": "Philadelphia International",
                                    "CityId": "PHLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.242222, 39.871667"
                                },
                                {
                                    "Id": "TTN",
                                    "Name": "Philadelphia Trenton-Mercer",
                                    "CityId": "PHLA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-74.8125, 40.278056"
                                }
                            ]
                        },
                        {
                            "Id": "PHNA",
                            "Name": "Port Huron",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-82.424914, 42.970863",
                            "IataCode": "PHN",
                            "Airports": [
                                {
                                    "Id": "PHN",
                                    "Name": "Port Huron",
                                    "CityId": "PHNA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-82.526111, 42.913056"
                                }
                            ]
                        },
                        {
                            "Id": "PHOA",
                            "Name": "Point Hope",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.80806, 68.347778",
                            "IataCode": "PHO",
                            "Airports": [
                                {
                                    "Id": "PHO",
                                    "Name": "Point Hope",
                                    "CityId": "PHOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.8, 68.35"
                                }
                            ]
                        },
                        {
                            "Id": "PHTA",
                            "Name": "Paris",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-88.326711, 36.302002",
                            "IataCode": "PHT",
                            "Airports": [
                                {
                                    "Id": "PHT",
                                    "Name": "Paris",
                                    "CityId": "PHTA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-88.383333, 36.333611"
                                }
                            ]
                        },
                        {
                            "Id": "PIAA",
                            "Name": "Peoria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.588986, 40.69365",
                            "IataCode": "PIA",
                            "Airports": [
                                {
                                    "Id": "PIA",
                                    "Name": "Greater Peoria",
                                    "CityId": "PIAA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.687222, 40.661389"
                                }
                            ]
                        },
                        {
                            "Id": "PIHA",
                            "Name": "Pocatello",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-112.44553, 42.871303",
                            "IataCode": "PIH",
                            "Airports": [
                                {
                                    "Id": "PIH",
                                    "Name": "Pocatello",
                                    "CityId": "PIHA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-112.59029, 42.909669"
                                }
                            ]
                        },
                        {
                            "Id": "PIMA",
                            "Name": "Pine Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.854098, 32.864852",
                            "IataCode": "PIM",
                            "Airports": [
                                {
                                    "Id": "PIM",
                                    "Name": "Pine Mountain",
                                    "CityId": "PIMA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.833333, 32.866667"
                                }
                            ]
                        },
                        {
                            "Id": "PIRA",
                            "Name": "Pierre",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-100.35097, 44.368316",
                            "IataCode": "PIR",
                            "Airports": [
                                {
                                    "Id": "PIR",
                                    "Name": "Pierre",
                                    "CityId": "PIRA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-100.28417, 44.383611"
                                }
                            ]
                        },
                        {
                            "Id": "PIZA",
                            "Name": "Point Lay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.05111, 69.7575",
                            "IataCode": "PIZ",
                            "Airports": [
                                {
                                    "Id": "PIZ",
                                    "Name": "Point Lay",
                                    "CityId": "PIZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.00861, 69.741023"
                                }
                            ]
                        },
                        {
                            "Id": "PJBA",
                            "Name": "Payson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.32514, 34.230868",
                            "IataCode": "PJB",
                            "Airports": [
                                {
                                    "Id": "PJB",
                                    "Name": "Payson",
                                    "CityId": "PJBA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.34167, 34.261111"
                                }
                            ]
                        },
                        {
                            "Id": "PKAA",
                            "Name": "Napaskiak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.76611, 60.708056",
                            "IataCode": "PKA",
                            "Airports": [
                                {
                                    "Id": "PKA",
                                    "Name": "Napaskiak",
                                    "CityId": "PKAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.75806, 60.710833"
                                }
                            ]
                        },
                        {
                            "Id": "PKBA",
                            "Name": "Parkersburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-81.561513, 39.266742",
                            "IataCode": "PKB",
                            "Airports": [
                                {
                                    "Id": "PKB",
                                    "Name": "Parkersburg",
                                    "CityId": "PKBA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-81.438889, 39.345556"
                                }
                            ]
                        },
                        {
                            "Id": "PKDA",
                            "Name": "Park Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.058632, 46.922181",
                            "IataCode": "PKD",
                            "Airports": [
                                {
                                    "Id": "PKD",
                                    "Name": "Park Rapids",
                                    "CityId": "PKDA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.071667, 46.898333"
                                }
                            ]
                        },
                        {
                            "Id": "PLNA",
                            "Name": "Pellston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.783936, 45.552789",
                            "IataCode": "PLN",
                            "Airports": [
                                {
                                    "Id": "PLN",
                                    "Name": "Pellston",
                                    "CityId": "PLNA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.792778, 45.570833"
                                }
                            ]
                        },
                        {
                            "Id": "PLRA",
                            "Name": "Pell City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.286089, 33.586215",
                            "IataCode": "PLR",
                            "Airports": [
                                {
                                    "Id": "PLR",
                                    "Name": "Pell City",
                                    "CityId": "PLRA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.283333, 33.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PMDA",
                            "Name": "Palmdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.11646, 34.579434",
                            "IataCode": "PMD",
                            "Airports": [
                                {
                                    "Id": "PMD",
                                    "Name": "Palmdale",
                                    "CityId": "PMDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.08333, 34.6275"
                                }
                            ]
                        },
                        {
                            "Id": "PMHA",
                            "Name": "Portsmouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.997674, 38.731743",
                            "IataCode": "PMH",
                            "Airports": [
                                {
                                    "Id": "PMH",
                                    "Name": "Portsmouth",
                                    "CityId": "PMHA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.983333, 38.75"
                                }
                            ]
                        },
                        {
                            "Id": "PNCA",
                            "Name": "Ponca City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.085595, 36.706981",
                            "IataCode": "PNC",
                            "Airports": [
                                {
                                    "Id": "PNC",
                                    "Name": "Ponca City",
                                    "CityId": "PNCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.099444, 36.728056"
                                }
                            ]
                        },
                        {
                            "Id": "PNNA",
                            "Name": "Princeton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-67.572221, 45.223406",
                            "IataCode": "PNN",
                            "Airports": [
                                {
                                    "Id": "PNN",
                                    "Name": "Princeton",
                                    "CityId": "PNNA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-67.56351, 45.20073"
                                }
                            ]
                        },
                        {
                            "Id": "POEA",
                            "Name": "Fort Polk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-93.20544, 31.046578",
                            "IataCode": "POE",
                            "Airports": [
                                {
                                    "Id": "POE",
                                    "Name": "Fort Polk",
                                    "CityId": "POEA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.192222, 31.045833"
                                }
                            ]
                        },
                        {
                            "Id": "POUA",
                            "Name": "Poughkeepsie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.92097, 41.700371",
                            "IataCode": "POU",
                            "Airports": [
                                {
                                    "Id": "POU",
                                    "Name": "Poughkeepsie",
                                    "CityId": "POUA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.882778, 41.628056"
                                }
                            ]
                        },
                        {
                            "Id": "PPFA",
                            "Name": "Parsons",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.261084, 37.340338",
                            "IataCode": "PPF",
                            "Airports": [
                                {
                                    "Id": "PPF",
                                    "Name": "Parsons",
                                    "CityId": "PPFA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.508611, 37.332222"
                                }
                            ]
                        },
                        {
                            "Id": "PPMA",
                            "Name": "Pompano Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.124767, 26.23786",
                            "IataCode": "PPM",
                            "Airports": [
                                {
                                    "Id": "PPM",
                                    "Name": "Pompano Beach",
                                    "CityId": "PPMA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.116667, 26.25"
                                }
                            ]
                        },
                        {
                            "Id": "PQIA",
                            "Name": "Presque Isle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-68.015862, 46.681153",
                            "IataCode": "PQI",
                            "Airports": [
                                {
                                    "Id": "PQI",
                                    "Name": "Presque Isle",
                                    "CityId": "PQIA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-68.0475, 46.685"
                                }
                            ]
                        },
                        {
                            "Id": "PRBA",
                            "Name": "Paso Robles",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.691, 35.626637",
                            "IataCode": "PRB",
                            "Airports": [
                                {
                                    "Id": "PRB",
                                    "Name": "Paso Robles",
                                    "CityId": "PRBA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.62583, 35.670556"
                                }
                            ]
                        },
                        {
                            "Id": "PRCA",
                            "Name": "Prescott",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.4685, 34.540024",
                            "IataCode": "PRC",
                            "Airports": [
                                {
                                    "Id": "PRC",
                                    "Name": "Prescott",
                                    "CityId": "PRCA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.42481, 34.649308"
                                }
                            ]
                        },
                        {
                            "Id": "PROA",
                            "Name": "Perry",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.10718, 41.838596",
                            "IataCode": "PRO",
                            "Airports": [
                                {
                                    "Id": "PRO",
                                    "Name": "Perry",
                                    "CityId": "PROA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.15968, 41.82776"
                                }
                            ]
                        },
                        {
                            "Id": "PRXA",
                            "Name": "Paris",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.555513, 33.660939",
                            "IataCode": "PRX",
                            "Airports": [
                                {
                                    "Id": "PRX",
                                    "Name": "Paris",
                                    "CityId": "PRXA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.450278, 33.636667"
                                }
                            ]
                        },
                        {
                            "Id": "PSBA",
                            "Name": "Philipsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.220569, 40.896446",
                            "IataCode": "PSB",
                            "Airports": [
                                {
                                    "Id": "PSB",
                                    "Name": "Philipsburg",
                                    "CityId": "PSBA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.085833, 40.883333"
                                }
                            ]
                        },
                        {
                            "Id": "PSCA",
                            "Name": "Pasco",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-119.10057, 46.239579",
                            "IataCode": "PSC",
                            "Airports": [
                                {
                                    "Id": "PSC",
                                    "Name": "Pasco",
                                    "CityId": "PSCA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.11472, 46.263889"
                                }
                            ]
                        },
                        {
                            "Id": "PSFA",
                            "Name": "Pittsfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-73.245383, 42.450085",
                            "IataCode": "PSF",
                            "Airports": [
                                {
                                    "Id": "PSF",
                                    "Name": "Pittsfield",
                                    "CityId": "PSFA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-73.291667, 42.426667"
                                }
                            ]
                        },
                        {
                            "Id": "PSGA",
                            "Name": "Petersburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.95556, 56.8125",
                            "IataCode": "PSG",
                            "Airports": [
                                {
                                    "Id": "PSG",
                                    "Name": "Petersburg",
                                    "CityId": "PSGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.9425, 56.804167"
                                }
                            ]
                        },
                        {
                            "Id": "PSKA",
                            "Name": "Dublin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-80.685343, 37.105678",
                            "IataCode": "PSK",
                            "Airports": [
                                {
                                    "Id": "PSK",
                                    "Name": "Dublin",
                                    "CityId": "PSKA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-80.678611, 37.137222"
                                }
                            ]
                        },
                        {
                            "Id": "PSMA",
                            "Name": "Portsmouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-70.762553, 43.071755",
                            "IataCode": "PSM",
                            "Airports": [
                                {
                                    "Id": "PSM",
                                    "Name": "Portsmouth",
                                    "CityId": "PSMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-70.8217, 43.07601"
                                }
                            ]
                        },
                        {
                            "Id": "PSPA",
                            "Name": "Palm Springs",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.54529, 33.830296",
                            "IataCode": "PSP",
                            "Airports": [
                                {
                                    "Id": "UDD",
                                    "Name": "Palm Springs Bermuda Dunes",
                                    "CityId": "PSPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.26722, 33.735833"
                                },
                                {
                                    "Id": "PSP",
                                    "Name": "Palm Springs International",
                                    "CityId": "PSPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.505, 33.826667"
                                }
                            ]
                        },
                        {
                            "Id": "PSXA",
                            "Name": "Palacios",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.217467, 28.708046",
                            "IataCode": "PSX",
                            "Airports": [
                                {
                                    "Id": "PSX",
                                    "Name": "Palacios",
                                    "CityId": "PSXA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.25108, 28.72777"
                                }
                            ]
                        },
                        {
                            "Id": "PTDA",
                            "Name": "Port Alexander",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.64444, 56.249722",
                            "IataCode": "PTD",
                            "Airports": [
                                {
                                    "Id": "PTD",
                                    "Name": "Port Alexander",
                                    "CityId": "PTDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.65, 56.25"
                                }
                            ]
                        },
                        {
                            "Id": "PTHA",
                            "Name": "Port Heiden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.62694, 56.949167",
                            "IataCode": "PTH",
                            "Airports": [
                                {
                                    "Id": "PTH",
                                    "Name": "Port Heiden",
                                    "CityId": "PTHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.6375, 56.956667"
                                }
                            ]
                        },
                        {
                            "Id": "PTKA",
                            "Name": "Pontiac",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.291046, 42.638922",
                            "IataCode": "PTK",
                            "Airports": [
                                {
                                    "Id": "PTK",
                                    "Name": "Pontiac",
                                    "CityId": "PTKA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.411389, 42.664722"
                                }
                            ]
                        },
                        {
                            "Id": "PTNA",
                            "Name": "Patterson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-91.30205, 29.693264",
                            "IataCode": "PTN",
                            "Airports": [
                                {
                                    "Id": "PTN",
                                    "Name": "Patterson",
                                    "CityId": "PTNA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-91.3375, 29.708333"
                                }
                            ]
                        },
                        {
                            "Id": "PTSA",
                            "Name": "Pittsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-94.70496, 37.410884",
                            "IataCode": "PTS",
                            "Airports": [
                                {
                                    "Id": "PTS",
                                    "Name": "Pittsburg",
                                    "CityId": "PTSA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-94.730556, 37.446667"
                                }
                            ]
                        },
                        {
                            "Id": "PTTA",
                            "Name": "Pratt",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-98.737591, 37.643907",
                            "IataCode": "PTT",
                            "Airports": [
                                {
                                    "Id": "PTT",
                                    "Name": "Pratt",
                                    "CityId": "PTTA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-98.75, 37.716667"
                                }
                            ]
                        },
                        {
                            "Id": "PTUA",
                            "Name": "Platinum",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.81639, 59.013056",
                            "IataCode": "PTU",
                            "Airports": [
                                {
                                    "Id": "PTU",
                                    "Name": "Platinum",
                                    "CityId": "PTUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.81694, 59.011389"
                                }
                            ]
                        },
                        {
                            "Id": "PTVA",
                            "Name": "Porterville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.01677, 36.06523",
                            "IataCode": "PTV",
                            "Airports": [
                                {
                                    "Id": "PTV",
                                    "Name": "Porterville",
                                    "CityId": "PTVA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.06344, 36.0294"
                                }
                            ]
                        },
                        {
                            "Id": "PTWA",
                            "Name": "Pottstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.64963, 40.245374",
                            "IataCode": "PTW",
                            "Airports": [
                                {
                                    "Id": "PTW",
                                    "Name": "Pottstown / Limerick",
                                    "CityId": "PTWA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.633333, 40.25"
                                }
                            ]
                        },
                        {
                            "Id": "PUBA",
                            "Name": "Pueblo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-104.60914, 38.254447",
                            "IataCode": "PUB",
                            "Airports": [
                                {
                                    "Id": "PUB",
                                    "Name": "Pueblo",
                                    "CityId": "PUBA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.50401, 38.28805"
                                }
                            ]
                        },
                        {
                            "Id": "PUCA",
                            "Name": "Price",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-110.81071, 39.599409",
                            "IataCode": "PUC",
                            "Airports": [
                                {
                                    "Id": "PUC",
                                    "Name": "Price",
                                    "CityId": "PUCA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-110.75278, 39.609722"
                                }
                            ]
                        },
                        {
                            "Id": "PVCA",
                            "Name": "Provincetown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.178637, 42.058436",
                            "IataCode": "PVC",
                            "Airports": [
                                {
                                    "Id": "PVC",
                                    "Name": "Provincetown",
                                    "CityId": "PVCA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.221667, 42.071944"
                                }
                            ]
                        },
                        {
                            "Id": "PVDA",
                            "Name": "Providence",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "RHODE",
                            "Location": "-71.412834, 41.823989",
                            "IataCode": "PVD",
                            "Airports": [
                                {
                                    "Id": "PVD",
                                    "Name": "Providence",
                                    "CityId": "PVDA",
                                    "CountryId": "US",
                                    "RegionId": "RHODE",
                                    "Location": "-71.426111, 41.728333"
                                }
                            ]
                        },
                        {
                            "Id": "PVFA",
                            "Name": "Placerville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.79855, 38.729625",
                            "IataCode": "PVF",
                            "Airports": [
                                {
                                    "Id": "PVF",
                                    "Name": "Placerville",
                                    "CityId": "PVFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.7541, 38.72407"
                                }
                            ]
                        },
                        {
                            "Id": "PVUA",
                            "Name": "Provo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.65853, 40.233844",
                            "IataCode": "PVU",
                            "Airports": [
                                {
                                    "Id": "PVU",
                                    "Name": "Provo",
                                    "CityId": "PVUA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.72222, 40.218056"
                                }
                            ]
                        },
                        {
                            "Id": "PVWA",
                            "Name": "Plainview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.70684, 34.184794",
                            "IataCode": "PVW",
                            "Airports": [
                                {
                                    "Id": "PVW",
                                    "Name": "Plainview",
                                    "CityId": "PVWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.71712, 34.16841"
                                }
                            ]
                        },
                        {
                            "Id": "PWMA",
                            "Name": "Portland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-70.255326, 43.661471",
                            "IataCode": "PWM",
                            "Airports": [
                                {
                                    "Id": "PWM",
                                    "Name": "Portland",
                                    "CityId": "PWMA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-70.303611, 43.644444"
                                }
                            ]
                        },
                        {
                            "Id": "PWTA",
                            "Name": "Bremerton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.63264, 47.567318",
                            "IataCode": "PWT",
                            "Airports": [
                                {
                                    "Id": "PWT",
                                    "Name": "Bremerton",
                                    "CityId": "PWTA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.76333, 47.485"
                                }
                            ]
                        },
                        {
                            "Id": "RACA",
                            "Name": "Racine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.782852, 42.726131",
                            "IataCode": "RAC",
                            "Airports": [
                                {
                                    "Id": "RAC",
                                    "Name": "Racine",
                                    "CityId": "RACA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.81369, 42.76085"
                                }
                            ]
                        },
                        {
                            "Id": "RAPA",
                            "Name": "Rapid City",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-103.23102, 44.080543",
                            "IataCode": "RAP",
                            "Airports": [
                                {
                                    "Id": "RCA",
                                    "Name": "Rapid City Ellsworth AFB",
                                    "CityId": "RAPA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-103.10357, 44.14504"
                                },
                                {
                                    "Id": "RAP",
                                    "Name": "Rapid City Regional",
                                    "CityId": "RAPA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-103.05622, 44.040466"
                                }
                            ]
                        },
                        {
                            "Id": "RBGA",
                            "Name": "Roseburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.34174, 43.216505",
                            "IataCode": "RBG",
                            "Airports": [
                                {
                                    "Id": "RBG",
                                    "Name": "Roseburg",
                                    "CityId": "RBGA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.35972, 43.238889"
                                }
                            ]
                        },
                        {
                            "Id": "RBLA",
                            "Name": "Red Bluff",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.23583, 40.178489",
                            "IataCode": "RBL",
                            "Airports": [
                                {
                                    "Id": "RBL",
                                    "Name": "Red Bluff",
                                    "CityId": "RBLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.27292, 40.156556"
                                }
                            ]
                        },
                        {
                            "Id": "RBWA",
                            "Name": "Walterboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.666769, 32.90517",
                            "IataCode": "RBW",
                            "Airports": [
                                {
                                    "Id": "RBW",
                                    "Name": "Walterboro",
                                    "CityId": "RBWA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.666667, 32.9"
                                }
                            ]
                        },
                        {
                            "Id": "RBYA",
                            "Name": "Ruby",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.48694, 64.739444",
                            "IataCode": "RBY",
                            "Airports": [
                                {
                                    "Id": "RBY",
                                    "Name": "Ruby",
                                    "CityId": "RBYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.46083, 64.73"
                                }
                            ]
                        },
                        {
                            "Id": "RCKA",
                            "Name": "Rockdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.001374, 30.655476",
                            "IataCode": "RCK",
                            "Airports": [
                                {
                                    "Id": "RCK",
                                    "Name": "Rockdale",
                                    "CityId": "RCKA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97, 30.65"
                                }
                            ]
                        },
                        {
                            "Id": "RCRA",
                            "Name": "Rochester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.215833, 41.064764",
                            "IataCode": "RCR",
                            "Airports": [
                                {
                                    "Id": "RCR",
                                    "Name": "Rochester",
                                    "CityId": "RCRA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.183796, 41.063433"
                                }
                            ]
                        },
                        {
                            "Id": "RCTA",
                            "Name": "Reed City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.51005, 43.875017",
                            "IataCode": "RCT",
                            "Airports": [
                                {
                                    "Id": "RCT",
                                    "Name": "Reed City",
                                    "CityId": "RCTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.516667, 43.883333"
                                }
                            ]
                        },
                        {
                            "Id": "RDDA",
                            "Name": "Redding",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.39168, 40.58654",
                            "IataCode": "RDD",
                            "Airports": [
                                {
                                    "Id": "RDD",
                                    "Name": "Redding",
                                    "CityId": "RDDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.29222, 40.508333"
                                }
                            ]
                        },
                        {
                            "Id": "RDGA",
                            "Name": "Reading",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.926875, 40.335648",
                            "IataCode": "RDG",
                            "Airports": [
                                {
                                    "Id": "RDG",
                                    "Name": "Reading",
                                    "CityId": "RDGA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.963889, 40.378611"
                                }
                            ]
                        },
                        {
                            "Id": "RDMA",
                            "Name": "Redmond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.17392, 44.27262",
                            "IataCode": "RDM",
                            "Airports": [
                                {
                                    "Id": "RDM",
                                    "Name": "Redmond",
                                    "CityId": "RDMA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.15139, 44.254444"
                                }
                            ]
                        },
                        {
                            "Id": "REDA",
                            "Name": "Reedsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.595828, 40.663959",
                            "IataCode": "RED",
                            "Airports": [
                                {
                                    "Id": "RED",
                                    "Name": "Reedsville",
                                    "CityId": "REDA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.616667, 40.683333"
                                }
                            ]
                        },
                        {
                            "Id": "REOA",
                            "Name": "Rome",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-117.62821, 42.839045",
                            "IataCode": "REO",
                            "Airports": [
                                {
                                    "Id": "REO",
                                    "Name": "Rome",
                                    "CityId": "REOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-117.88432, 42.58322"
                                }
                            ]
                        },
                        {
                            "Id": "RHIA",
                            "Name": "Rhinelander",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.412075, 45.636623",
                            "IataCode": "RHI",
                            "Airports": [
                                {
                                    "Id": "RHI",
                                    "Name": "Rhinelander",
                                    "CityId": "RHIA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.4575, 45.631389"
                                }
                            ]
                        },
                        {
                            "Id": "SJCA",
                            "Name": "San Jose",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.89496, 37.339387",
                            "IataCode": "SJC",
                            "Airports": [
                                {
                                    "Id": "RHV",
                                    "Name": "San Jose Reid-Hillview",
                                    "CityId": "SJCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.81838, 37.333723"
                                },
                                {
                                    "Id": "SJC",
                                    "Name": "San Jose International",
                                    "CityId": "SJCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.92894, 37.363947"
                                }
                            ]
                        },
                        {
                            "Id": "RICA",
                            "Name": "Richmond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.460261, 37.553757",
                            "IataCode": "RIC",
                            "Airports": [
                                {
                                    "Id": "RIC",
                                    "Name": "Richmond",
                                    "CityId": "RICA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.3225, 37.506111"
                                }
                            ]
                        },
                        {
                            "Id": "RIEA",
                            "Name": "Rice Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-91.738225, 45.506068",
                            "IataCode": "RIE",
                            "Airports": [
                                {
                                    "Id": "RIE",
                                    "Name": "Rice Lake",
                                    "CityId": "RIEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-91.77361, 45.41806"
                                }
                            ]
                        },
                        {
                            "Id": "RILA",
                            "Name": "Rifle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.78312, 39.534702",
                            "IataCode": "RIL",
                            "Airports": [
                                {
                                    "Id": "RIL",
                                    "Name": "Rifle",
                                    "CityId": "RILA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.78333, 39.533333"
                                }
                            ]
                        },
                        {
                            "Id": "RIWA",
                            "Name": "Riverton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-108.3801, 43.024959",
                            "IataCode": "RIW",
                            "Airports": [
                                {
                                    "Id": "RIW",
                                    "Name": "Riverton",
                                    "CityId": "RIWA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-108.45394, 43.06337"
                                }
                            ]
                        },
                        {
                            "Id": "SIYA",
                            "Name": "Montague",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.5278, 41.728198",
                            "IataCode": "SIY",
                            "Airports": [
                                {
                                    "Id": "SIY",
                                    "Name": "Montague",
                                    "CityId": "SIYA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.46919, 41.78125"
                                }
                            ]
                        },
                        {
                            "Id": "RKDA",
                            "Name": "Rockland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.108929, 44.103691",
                            "IataCode": "RKD",
                            "Airports": [
                                {
                                    "Id": "RKD",
                                    "Name": "Rockland",
                                    "CityId": "RKDA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.098056, 44.059167"
                                }
                            ]
                        },
                        {
                            "Id": "RKPA",
                            "Name": "Rockport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.054434, 28.020573",
                            "IataCode": "RKP",
                            "Airports": [
                                {
                                    "Id": "RKP",
                                    "Name": "Rockport",
                                    "CityId": "RKPA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.05, 28.033333"
                                }
                            ]
                        },
                        {
                            "Id": "RKRA",
                            "Name": "Poteau",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-94.623558, 35.053709",
                            "IataCode": "RKR",
                            "Airports": [
                                {
                                    "Id": "RKR",
                                    "Name": "Poteau",
                                    "CityId": "RKRA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-94.616667, 35.05"
                                }
                            ]
                        },
                        {
                            "Id": "RKWA",
                            "Name": "Rockwood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-84.68494, 35.865628",
                            "IataCode": "RKW",
                            "Airports": [
                                {
                                    "Id": "RKW",
                                    "Name": "Rockwood",
                                    "CityId": "RKWA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-84.68966, 35.9223"
                                }
                            ]
                        },
                        {
                            "Id": "RLDA",
                            "Name": "Richland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-119.28446, 46.285691",
                            "IataCode": "RLD",
                            "Airports": [
                                {
                                    "Id": "RLD",
                                    "Name": "Richland",
                                    "CityId": "RLDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-119.30556, 46.308333"
                                }
                            ]
                        },
                        {
                            "Id": "RMEA",
                            "Name": "Rome",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.45573, 43.212847",
                            "IataCode": "RME",
                            "Airports": [
                                {
                                    "Id": "RME",
                                    "Name": "Rome",
                                    "CityId": "RMEA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.45, 43.216667"
                                }
                            ]
                        },
                        {
                            "Id": "RMGA",
                            "Name": "Rome",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-85.164673, 34.257038",
                            "IataCode": "RMG",
                            "Airports": [
                                {
                                    "Id": "RMG",
                                    "Name": "Rome",
                                    "CityId": "RMGA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-85.158611, 34.351389"
                                }
                            ]
                        },
                        {
                            "Id": "RNHA",
                            "Name": "New Richmond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-92.536587, 45.123021",
                            "IataCode": "RNH",
                            "Airports": [
                                {
                                    "Id": "RNH",
                                    "Name": "New Richmond",
                                    "CityId": "RNHA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-92.55, 45.133333"
                                }
                            ]
                        },
                        {
                            "Id": "RNOA",
                            "Name": "Reno",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-119.8138, 39.529633",
                            "IataCode": "RNO",
                            "Airports": [
                                {
                                    "Id": "RNO",
                                    "Name": "Reno / Tahoe",
                                    "CityId": "RNOA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-119.7675, 39.497778"
                                }
                            ]
                        },
                        {
                            "Id": "RNTA",
                            "Name": "Renton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.21707, 47.482878",
                            "IataCode": "RNT",
                            "Airports": [
                                {
                                    "Id": "RNT",
                                    "Name": "Renton",
                                    "CityId": "RNTA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.21667, 47.5"
                                }
                            ]
                        },
                        {
                            "Id": "RNZA",
                            "Name": "Rensselaer",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.150856, 40.936704",
                            "IataCode": "RNZ",
                            "Airports": [
                                {
                                    "Id": "RNZ",
                                    "Name": "Rensselaer",
                                    "CityId": "RNZA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.15, 40.95"
                                }
                            ]
                        },
                        {
                            "Id": "ROAA",
                            "Name": "Roanoke",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-79.941427, 37.27097",
                            "IataCode": "ROA",
                            "Airports": [
                                {
                                    "Id": "ROA",
                                    "Name": "Roanoke",
                                    "CityId": "ROAA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-79.976389, 37.324722"
                                }
                            ]
                        },
                        {
                            "Id": "ROCA",
                            "Name": "Rochester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-77.615557, 43.154786",
                            "IataCode": "ROC",
                            "Airports": [
                                {
                                    "Id": "ROC",
                                    "Name": "Rochester",
                                    "CityId": "ROCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-77.670833, 43.121389"
                                }
                            ]
                        },
                        {
                            "Id": "ROGA",
                            "Name": "Rogers",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.118537, 36.33202",
                            "IataCode": "ROG",
                            "Airports": [
                                {
                                    "Id": "ROG",
                                    "Name": "Rogers",
                                    "CityId": "ROGA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.106667, 36.372222"
                                }
                            ]
                        },
                        {
                            "Id": "ROWA",
                            "Name": "Roswell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-104.52302, 33.394265",
                            "IataCode": "ROW",
                            "Airports": [
                                {
                                    "Id": "ROW",
                                    "Name": "Roswell",
                                    "CityId": "ROWA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-104.52393, 33.297247"
                                }
                            ]
                        },
                        {
                            "Id": "ROXA",
                            "Name": "Roseau",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.762766, 48.846093",
                            "IataCode": "ROX",
                            "Airports": [
                                {
                                    "Id": "ROX",
                                    "Name": "Roseau",
                                    "CityId": "ROXA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.766667, 48.85"
                                }
                            ]
                        },
                        {
                            "Id": "RPXA",
                            "Name": "Roundup",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-108.5418, 46.445242",
                            "IataCode": "RPX",
                            "Airports": [
                                {
                                    "Id": "RPX",
                                    "Name": "Roundup",
                                    "CityId": "RPXA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-108.53333, 46.433333"
                                }
                            ]
                        },
                        {
                            "Id": "RRTA",
                            "Name": "Warroad",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.314404, 48.905266",
                            "IataCode": "RRT",
                            "Airports": [
                                {
                                    "Id": "RRT",
                                    "Name": "Warroad",
                                    "CityId": "RRTA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.34274, 48.93638"
                                }
                            ]
                        },
                        {
                            "Id": "RSHA",
                            "Name": "Russian Mission",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.32028, 61.785",
                            "IataCode": "RSH",
                            "Airports": [
                                {
                                    "Id": "RSH",
                                    "Name": "Russian Mission",
                                    "CityId": "RSHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.31917, 61.783056"
                                }
                            ]
                        },
                        {
                            "Id": "RSNA",
                            "Name": "Ruston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-92.637927, 32.523205",
                            "IataCode": "RSN",
                            "Airports": [
                                {
                                    "Id": "RSN",
                                    "Name": "Ruston",
                                    "CityId": "RSNA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-92.633333, 32.516667"
                                }
                            ]
                        },
                        {
                            "Id": "RSTA",
                            "Name": "Rochester",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-92.469899, 44.021631",
                            "IataCode": "RST",
                            "Airports": [
                                {
                                    "Id": "JRC",
                                    "Name": "Rochester Municipal Heliport",
                                    "CityId": "RSTA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.466667, 44.016667"
                                },
                                {
                                    "Id": "RST",
                                    "Name": "Rochester Municipal",
                                    "CityId": "RSTA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.496389, 43.910278"
                                }
                            ]
                        },
                        {
                            "Id": "RUTA",
                            "Name": "Rutland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.972607, 43.610624",
                            "IataCode": "RUT",
                            "Airports": [
                                {
                                    "Id": "RUT",
                                    "Name": "Rutland",
                                    "CityId": "RUTA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.948333, 43.529444"
                                }
                            ]
                        },
                        {
                            "Id": "TULB",
                            "Name": "Tulsa",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.992775, 36.153982",
                            "IataCode": "TUL",
                            "Airports": [
                                {
                                    "Id": "RVS",
                                    "Name": "Tulsa R.Lloyd Jones",
                                    "CityId": "TULB",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.984722, 36.039722"
                                },
                                {
                                    "Id": "ZFF",
                                    "Name": "Tulsa Off-line Point (Fedex)",
                                    "CityId": "TULB",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.966327, 36.134348"
                                },
                                {
                                    "Id": "TUL",
                                    "Name": "Tulsa International",
                                    "CityId": "TULB",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.889722, 36.197778"
                                }
                            ]
                        },
                        {
                            "Id": "RWIA",
                            "Name": "Rocky Mount",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-77.790534, 35.93821",
                            "IataCode": "RWI",
                            "Airports": [
                                {
                                    "Id": "RWI",
                                    "Name": "Rocky Mount-Wilson",
                                    "CityId": "RWIA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-77.890556, 35.854444"
                                }
                            ]
                        },
                        {
                            "Id": "RWLA",
                            "Name": "Rawlins",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-107.23866, 41.79107",
                            "IataCode": "RWL",
                            "Airports": [
                                {
                                    "Id": "RWL",
                                    "Name": "Rawlins",
                                    "CityId": "RWLA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-107.20139, 41.804444"
                                }
                            ]
                        },
                        {
                            "Id": "SAAA",
                            "Name": "Saratoga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-106.80643, 41.454962",
                            "IataCode": "SAA",
                            "Airports": [
                                {
                                    "Id": "SAA",
                                    "Name": "Saratoga",
                                    "CityId": "SAAA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-106.81, 41.445"
                                }
                            ]
                        },
                        {
                            "Id": "SADA",
                            "Name": "Safford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.70758, 32.833955",
                            "IataCode": "SAD",
                            "Airports": [
                                {
                                    "Id": "SAD",
                                    "Name": "Safford",
                                    "CityId": "SADA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.63591, 32.85479"
                                }
                            ]
                        },
                        {
                            "Id": "ZSHA",
                            "Name": "Santa Fe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.9378, 35.686975",
                            "IataCode": "ZSH",
                            "Airports": [
                                {
                                    "Id": "SAF",
                                    "Name": "Santa Fe",
                                    "CityId": "ZSHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.09016, 35.616837"
                                }
                            ]
                        },
                        {
                            "Id": "SPRT",
                            "Name": "Sparta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.701766, 38.123105",
                            "IataCode": "SAR",
                            "Airports": [
                                {
                                    "Id": "SAR",
                                    "Name": "Sparta Community",
                                    "CityId": "SPRT",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.69985, 38.146708"
                                }
                            ]
                        },
                        {
                            "Id": "SASA",
                            "Name": "Salton City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.95611, 33.29865",
                            "IataCode": "SAS",
                            "Airports": [
                                {
                                    "Id": "SAS",
                                    "Name": "Salton City",
                                    "CityId": "SASA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.95333, 33.24143"
                                }
                            ]
                        },
                        {
                            "Id": "SATA",
                            "Name": "San Antonio",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.493628, 29.424124",
                            "IataCode": "SAT",
                            "Airports": [
                                {
                                    "Id": "SSF",
                                    "Name": "San Antonio Stinson Municipal",
                                    "CityId": "SATA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.4714, 29.33718"
                                },
                                {
                                    "Id": "SKF",
                                    "Name": "San Antonio Kelly AFB",
                                    "CityId": "SATA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.5, 29.416667"
                                },
                                {
                                    "Id": "RND",
                                    "Name": "San Antonio Randolph AFB",
                                    "CityId": "SATA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.5, 29.416667"
                                },
                                {
                                    "Id": "MDA",
                                    "Name": "San Antonio Martindale AAF",
                                    "CityId": "SATA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.5, 29.416667"
                                },
                                {
                                    "Id": "SAT",
                                    "Name": "San Antonio International",
                                    "CityId": "SATA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.471944, 29.538333"
                                }
                            ]
                        },
                        {
                            "Id": "SAVA",
                            "Name": "Savannah",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.099834, 32.083541",
                            "IataCode": "SAV",
                            "Airports": [
                                {
                                    "Id": "SVN",
                                    "Name": "Savannah Hunter AAF",
                                    "CityId": "SAVA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.14568, 32.01"
                                },
                                {
                                    "Id": "SAV",
                                    "Name": "Savannah / Hilton Head",
                                    "CityId": "SAVA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.199444, 32.127222"
                                }
                            ]
                        },
                        {
                            "Id": "SBAA",
                            "Name": "Santa Barbara",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.69819, 34.42083",
                            "IataCode": "SBA",
                            "Airports": [
                                {
                                    "Id": "SZN",
                                    "Name": "Santa Barbara Santa Cruz Island",
                                    "CityId": "SBAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.915, 34.060556"
                                },
                                {
                                    "Id": "SBA",
                                    "Name": "Santa Barbara Municipal",
                                    "CityId": "SBAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.83944, 34.427778"
                                }
                            ]
                        },
                        {
                            "Id": "SBTA",
                            "Name": "San Bernardino",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.28977, 34.108345",
                            "IataCode": "SBD",
                            "Airports": [
                                {
                                    "Id": "SBD",
                                    "Name": "San Bernardino Norton AFB",
                                    "CityId": "SBTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.23333, 34.116667"
                                }
                            ]
                        },
                        {
                            "Id": "SBMA",
                            "Name": "Sheboygan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.71453, 43.750828",
                            "IataCode": "SBM",
                            "Airports": [
                                {
                                    "Id": "SBM",
                                    "Name": "Sheboygan",
                                    "CityId": "SBMA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.852222, 43.771667"
                                }
                            ]
                        },
                        {
                            "Id": "SBNA",
                            "Name": "South Bend",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.250007, 41.683381",
                            "IataCode": "SBN",
                            "Airports": [
                                {
                                    "Id": "SBN",
                                    "Name": "South Bend",
                                    "CityId": "SBNA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.3125, 41.704444"
                                }
                            ]
                        },
                        {
                            "Id": "SBOA",
                            "Name": "Salina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.85992, 38.957741",
                            "IataCode": "SBO",
                            "Airports": [
                                {
                                    "Id": "SBO",
                                    "Name": "Salina",
                                    "CityId": "SBOA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.8375, 39.029167"
                                }
                            ]
                        },
                        {
                            "Id": "CSLA",
                            "Name": "San Luis Obispo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.65962, 35.282752",
                            "IataCode": "SBP",
                            "Airports": [
                                {
                                    "Id": "SBP",
                                    "Name": "San Luis Obispo County",
                                    "CityId": "CSLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.63917, 35.236667"
                                }
                            ]
                        },
                        {
                            "Id": "SBSA",
                            "Name": "Steamboat Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.83172, 40.484977",
                            "IataCode": "SBS",
                            "Airports": [
                                {
                                    "Id": "SBS",
                                    "Name": "Steamboat Springs",
                                    "CityId": "SBSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.8625, 40.516667"
                                }
                            ]
                        },
                        {
                            "Id": "SCBA",
                            "Name": "Scribner",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-96.665862, 41.666666",
                            "IataCode": "SCB",
                            "Airports": [
                                {
                                    "Id": "SCB",
                                    "Name": "Scribner",
                                    "CityId": "SCBA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-96.666667, 41.65"
                                }
                            ]
                        },
                        {
                            "Id": "SCCA",
                            "Name": "Deadhorse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.51167, 70.205556",
                            "IataCode": "SCC",
                            "Airports": [
                                {
                                    "Id": "SCC",
                                    "Name": "Deadhorse (Prudhoe Bay)",
                                    "CityId": "SCCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.45413, 70.194178"
                                }
                            ]
                        },
                        {
                            "Id": "SCEA",
                            "Name": "State College",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.860001, 40.793395",
                            "IataCode": "SCE",
                            "Airports": [
                                {
                                    "Id": "SCE",
                                    "Name": "State College",
                                    "CityId": "SCEA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.848611, 40.849167"
                                }
                            ]
                        },
                        {
                            "Id": "STEA",
                            "Name": "Stevens Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.574563, 44.523579",
                            "IataCode": "STE",
                            "Airports": [
                                {
                                    "Id": "STE",
                                    "Name": "Stevens Point",
                                    "CityId": "STEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.530833, 44.545278"
                                }
                            ]
                        },
                        {
                            "Id": "SCHA",
                            "Name": "Schenectady",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.939569, 42.814243",
                            "IataCode": "SCH",
                            "Airports": [
                                {
                                    "Id": "SCH",
                                    "Name": "Schenectady",
                                    "CityId": "SCHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.933333, 42.85"
                                }
                            ]
                        },
                        {
                            "Id": "SCMA",
                            "Name": "Scammon Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.58167, 61.842778",
                            "IataCode": "SCM",
                            "Airports": [
                                {
                                    "Id": "SCM",
                                    "Name": "Scammon Bay",
                                    "CityId": "SCMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.59389, 61.846389"
                                }
                            ]
                        },
                        {
                            "Id": "STJA",
                            "Name": "St Joseph",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.846632, 39.768605",
                            "IataCode": "STJ",
                            "Airports": [
                                {
                                    "Id": "STJ",
                                    "Name": "St Joseph",
                                    "CityId": "STJA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.925, 39.960556"
                                }
                            ]
                        },
                        {
                            "Id": "STLA",
                            "Name": "St Louis",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-90.197889, 38.627273",
                            "IataCode": "STL",
                            "Airports": [
                                {
                                    "Id": "SUS",
                                    "Name": "Spirit Of St Louis",
                                    "CityId": "STLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.649567, 38.659778"
                                },
                                {
                                    "Id": "STL",
                                    "Name": "St Louis",
                                    "CityId": "STLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.3644, 38.7472"
                                },
                                {
                                    "Id": "CPS",
                                    "Name": "St Louis Bi-State Parks",
                                    "CityId": "STLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-90.157585, 38.572327"
                                }
                            ]
                        },
                        {
                            "Id": "STSA",
                            "Name": "Santa Rosa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.71443, 38.440467",
                            "IataCode": "STS",
                            "Airports": [
                                {
                                    "Id": "STS",
                                    "Name": "Santa Rosa",
                                    "CityId": "STSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.81167, 38.508889"
                                }
                            ]
                        },
                        {
                            "Id": "SDPA",
                            "Name": "Sand Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.4988, 55.336553",
                            "IataCode": "SDP",
                            "Airports": [
                                {
                                    "Id": "SDP",
                                    "Name": "Sand Point",
                                    "CityId": "SDPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.51389, 55.316667"
                                }
                            ]
                        },
                        {
                            "Id": "SDXA",
                            "Name": "Sedona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.76099, 34.869739",
                            "IataCode": "SDX",
                            "Airports": [
                                {
                                    "Id": "SDX",
                                    "Name": "Sedona",
                                    "CityId": "SDXA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.76667, 34.866667"
                                }
                            ]
                        },
                        {
                            "Id": "SDYA",
                            "Name": "Sidney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-104.15633, 47.716684",
                            "IataCode": "SDY",
                            "Airports": [
                                {
                                    "Id": "SDY",
                                    "Name": "Sidney",
                                    "CityId": "SDYA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-104.19537, 47.706527"
                                }
                            ]
                        },
                        {
                            "Id": "SEFA",
                            "Name": "Sebring",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.440907, 27.495592",
                            "IataCode": "SEF",
                            "Airports": [
                                {
                                    "Id": "SEF",
                                    "Name": "Sebring",
                                    "CityId": "SEFA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.34202, 27.4567"
                                }
                            ]
                        },
                        {
                            "Id": "SUAA",
                            "Name": "Stuart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.252826, 27.197548",
                            "IataCode": "SUA",
                            "Airports": [
                                {
                                    "Id": "SUA",
                                    "Name": "Stuart",
                                    "CityId": "SUAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.22171, 27.18116"
                                }
                            ]
                        },
                        {
                            "Id": "SERA",
                            "Name": "Seymour",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-85.890255, 38.95922",
                            "IataCode": "SER",
                            "Airports": [
                                {
                                    "Id": "SER",
                                    "Name": "Seymour",
                                    "CityId": "SERA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-85.883333, 38.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SUEA",
                            "Name": "Sturgeon Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.377042, 44.834164",
                            "IataCode": "SUE",
                            "Airports": [
                                {
                                    "Id": "SUE",
                                    "Name": "Sturgeon Bay",
                                    "CityId": "SUEA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.421389, 44.843889"
                                }
                            ]
                        },
                        {
                            "Id": "SSCA",
                            "Name": "Sumter",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.341469, 33.920435",
                            "IataCode": "SSC",
                            "Airports": [
                                {
                                    "Id": "SUM",
                                    "Name": "Sumter Municipal",
                                    "CityId": "SSCA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.116667, 33.9"
                                },
                                {
                                    "Id": "SSC",
                                    "Name": "Sumter Shaw AFB",
                                    "CityId": "SSCA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.47056, 33.97272"
                                }
                            ]
                        },
                        {
                            "Id": "SUNA",
                            "Name": "Hailey",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-114.31532, 43.519629",
                            "IataCode": "SUN",
                            "Airports": [
                                {
                                    "Id": "SUN",
                                    "Name": "Hailey",
                                    "CityId": "SUNA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-114.2962, 43.5044"
                                }
                            ]
                        },
                        {
                            "Id": "SFMA",
                            "Name": "Sanford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-70.774224, 43.439249",
                            "IataCode": "SFM",
                            "Airports": [
                                {
                                    "Id": "SFM",
                                    "Name": "Sanford",
                                    "CityId": "SFMA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-70.766667, 43.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SFOA",
                            "Name": "San Francisco",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.44045, 37.754432",
                            "IataCode": "SFO",
                            "Airports": [
                                {
                                    "Id": "EMB",
                                    "Name": "San Francisco Embarkadero",
                                    "CityId": "SFOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.375, 37.625278"
                                },
                                {
                                    "Id": "SFO",
                                    "Name": "San Francisco International",
                                    "CityId": "SFOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.37489, 37.618972"
                                },
                                {
                                    "Id": "JCC",
                                    "Name": "San Francisco China Basin Heliport",
                                    "CityId": "SFOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.41667, 37.75"
                                }
                            ]
                        },
                        {
                            "Id": "SGFA",
                            "Name": "Springfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.298244, 37.215326",
                            "IataCode": "SGF",
                            "Airports": [
                                {
                                    "Id": "SGF",
                                    "Name": "Springfield-Branson Rg",
                                    "CityId": "SGFA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.385, 37.24"
                                }
                            ]
                        },
                        {
                            "Id": "SGHA",
                            "Name": "Springfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-83.808817, 39.924227",
                            "IataCode": "SGH",
                            "Airports": [
                                {
                                    "Id": "SGH",
                                    "Name": "Springfield",
                                    "CityId": "SGHA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.8, 39.916667"
                                }
                            ]
                        },
                        {
                            "Id": "SGTA",
                            "Name": "Stuttgart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-91.552628, 34.500375",
                            "IataCode": "SGT",
                            "Airports": [
                                {
                                    "Id": "SGT",
                                    "Name": "Stuttgart",
                                    "CityId": "SGTA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.57457, 34.60037"
                                }
                            ]
                        },
                        {
                            "Id": "SUUA",
                            "Name": "Fairfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.03997, 38.249358",
                            "IataCode": "SUU",
                            "Airports": [
                                {
                                    "Id": "SUU",
                                    "Name": "Fairfield",
                                    "CityId": "SUUA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.92694, 38.2625"
                                }
                            ]
                        },
                        {
                            "Id": "SGYA",
                            "Name": "Skagway",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.31389, 59.458333",
                            "IataCode": "SGY",
                            "Airports": [
                                {
                                    "Id": "SGY",
                                    "Name": "Skagway",
                                    "CityId": "SGYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.31556, 59.460556"
                                }
                            ]
                        },
                        {
                            "Id": "SUWA",
                            "Name": "Superior",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-92.10408, 46.720774",
                            "IataCode": "SUW",
                            "Airports": [
                                {
                                    "Id": "SUW",
                                    "Name": "Superior",
                                    "CityId": "SUWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-92.103333, 46.691111"
                                }
                            ]
                        },
                        {
                            "Id": "SHGA",
                            "Name": "Shungnak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.13639, 66.888056",
                            "IataCode": "SHG",
                            "Airports": [
                                {
                                    "Id": "SHG",
                                    "Name": "Shungnak",
                                    "CityId": "SHGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.15778, 66.889722"
                                }
                            ]
                        },
                        {
                            "Id": "SHHA",
                            "Name": "Shishmaref",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.07194, 66.256667",
                            "IataCode": "SHH",
                            "Airports": [
                                {
                                    "Id": "SHH",
                                    "Name": "Shishmaref",
                                    "CityId": "SHHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.05833, 66.256944"
                                }
                            ]
                        },
                        {
                            "Id": "SHNA",
                            "Name": "Shelton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.10071, 47.215094",
                            "IataCode": "SHN",
                            "Airports": [
                                {
                                    "Id": "SHN",
                                    "Name": "Shelton",
                                    "CityId": "SHNA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.13889, 47.233889"
                                }
                            ]
                        },
                        {
                            "Id": "SHRA",
                            "Name": "Sheridan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-106.95618, 44.797194",
                            "IataCode": "SHR",
                            "Airports": [
                                {
                                    "Id": "SHR",
                                    "Name": "Sheridan",
                                    "CityId": "SHRA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-106.97765, 44.771599"
                                }
                            ]
                        },
                        {
                            "Id": "SHXA",
                            "Name": "Shageluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.56194, 62.682222",
                            "IataCode": "SHX",
                            "Airports": [
                                {
                                    "Id": "SHX",
                                    "Name": "Shageluk",
                                    "CityId": "SHXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.56667, 62.695833"
                                }
                            ]
                        },
                        {
                            "Id": "SIKA",
                            "Name": "Sikeston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-89.587858, 36.876719",
                            "IataCode": "SIK",
                            "Airports": [
                                {
                                    "Id": "SIK",
                                    "Name": "Sikeston",
                                    "CityId": "SIKA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-89.563208, 36.88985"
                                }
                            ]
                        },
                        {
                            "Id": "SITA",
                            "Name": "Sitka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.33, 57.053056",
                            "IataCode": "SIT",
                            "Airports": [
                                {
                                    "Id": "SIT",
                                    "Name": "Sitka",
                                    "CityId": "SITA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.36405, 57.048551"
                                }
                            ]
                        },
                        {
                            "Id": "SIVB",
                            "Name": "Sullivan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.405852, 39.09532",
                            "IataCode": "SIV",
                            "Airports": [
                                {
                                    "Id": "SIV",
                                    "Name": "Sullivan County",
                                    "CityId": "SIVB",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.453574, 39.109817"
                                }
                            ]
                        },
                        {
                            "Id": "SJTA",
                            "Name": "San Angelo",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.43704, 31.463772",
                            "IataCode": "SJT",
                            "Airports": [
                                {
                                    "Id": "SJT",
                                    "Name": "San Angelo Mathis Fld",
                                    "CityId": "SJTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.49444, 31.359722"
                                },
                                {
                                    "Id": "GOF",
                                    "Name": "San Angelo Goodfellow AFB",
                                    "CityId": "SJTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.43333, 31.466667"
                                }
                            ]
                        },
                        {
                            "Id": "SKKA",
                            "Name": "Shaktoolik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.15389, 64.333889",
                            "IataCode": "SKK",
                            "Airports": [
                                {
                                    "Id": "SKK",
                                    "Name": "Shaktoolik",
                                    "CityId": "SKKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.13889, 64.324167"
                                }
                            ]
                        },
                        {
                            "Id": "SKWA",
                            "Name": "Skwentna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.39778, 61.990278",
                            "IataCode": "SKW",
                            "Airports": [
                                {
                                    "Id": "SKW",
                                    "Name": "Skwentna",
                                    "CityId": "SKWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.18861, 61.965833"
                                }
                            ]
                        },
                        {
                            "Id": "SKYA",
                            "Name": "Sandusky",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.70796, 41.44894",
                            "IataCode": "SKY",
                            "Airports": [
                                {
                                    "Id": "SKY",
                                    "Name": "Griffing Sandusky",
                                    "CityId": "SKYA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.65212, 41.43311"
                                }
                            ]
                        },
                        {
                            "Id": "SUXA",
                            "Name": "Sioux City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-96.400307, 42.499994",
                            "IataCode": "SUX",
                            "Airports": [
                                {
                                    "Id": "SUX",
                                    "Name": "Sioux City",
                                    "CityId": "SUXA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-96.384167, 42.401944"
                                }
                            ]
                        },
                        {
                            "Id": "SLCA",
                            "Name": "Salt Lake City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.89105, 40.760779",
                            "IataCode": "SLC",
                            "Airports": [
                                {
                                    "Id": "SLC",
                                    "Name": "Salt Lake City",
                                    "CityId": "SLCA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.97952, 40.797437"
                                }
                            ]
                        },
                        {
                            "Id": "SVAA",
                            "Name": "Savoonga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-170.47889, 63.694167",
                            "IataCode": "SVA",
                            "Airports": [
                                {
                                    "Id": "SVA",
                                    "Name": "Savoonga",
                                    "CityId": "SVAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-170.45944, 63.693333"
                                }
                            ]
                        },
                        {
                            "Id": "SLEA",
                            "Name": "Salem",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.0351, 44.942899",
                            "IataCode": "SLE",
                            "Airports": [
                                {
                                    "Id": "SLE",
                                    "Name": "Salem",
                                    "CityId": "SLEA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.00915, 44.911772"
                                }
                            ]
                        },
                        {
                            "Id": "SLGA",
                            "Name": "Siloam Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.540496, 36.188136",
                            "IataCode": "SLG",
                            "Airports": [
                                {
                                    "Id": "SLG",
                                    "Name": "Siloam Springs",
                                    "CityId": "SLGA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.49077, 36.19036"
                                }
                            ]
                        },
                        {
                            "Id": "SVCA",
                            "Name": "Silver City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-108.28033, 32.770075",
                            "IataCode": "SVC",
                            "Airports": [
                                {
                                    "Id": "SVC",
                                    "Name": "Silver City",
                                    "CityId": "SVCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-108.15422, 32.632147"
                                }
                            ]
                        },
                        {
                            "Id": "SLKA",
                            "Name": "Saranac Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-74.131266, 44.329496",
                            "IataCode": "SLK",
                            "Airports": [
                                {
                                    "Id": "SLK",
                                    "Name": "Saranac Lake",
                                    "CityId": "SLKA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.206667, 44.382778"
                                }
                            ]
                        },
                        {
                            "Id": "SVEA",
                            "Name": "Susanville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.65301, 40.416284",
                            "IataCode": "SVE",
                            "Airports": [
                                {
                                    "Id": "SVE",
                                    "Name": "Susanville",
                                    "CityId": "SVEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.65, 40.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SLNA",
                            "Name": "Salina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.611424, 38.84028",
                            "IataCode": "SLN",
                            "Airports": [
                                {
                                    "Id": "SLN",
                                    "Name": "Salina",
                                    "CityId": "SLNA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.6525, 38.790833"
                                }
                            ]
                        },
                        {
                            "Id": "SLQA",
                            "Name": "Sleetmute",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.16972, 61.7025",
                            "IataCode": "SLQ",
                            "Airports": [
                                {
                                    "Id": "SLQ",
                                    "Name": "Sleetmute",
                                    "CityId": "SLQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.16667, 61.705"
                                }
                            ]
                        },
                        {
                            "Id": "SLRA",
                            "Name": "Sulphur Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.601067, 33.138448",
                            "IataCode": "SLR",
                            "Airports": [
                                {
                                    "Id": "SLR",
                                    "Name": "Sulphur Springs",
                                    "CityId": "SLRA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.6, 33.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SVHA",
                            "Name": "Statesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-80.887296, 35.782636",
                            "IataCode": "SVH",
                            "Airports": [
                                {
                                    "Id": "SVH",
                                    "Name": "Statesville",
                                    "CityId": "SVHA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-80.883333, 35.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SLTA",
                            "Name": "Salida",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.9989, 38.534719",
                            "IataCode": "SLT",
                            "Airports": [
                                {
                                    "Id": "SLT",
                                    "Name": "Salida",
                                    "CityId": "SLTA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.0497, 38.53798"
                                }
                            ]
                        },
                        {
                            "Id": "SMEA",
                            "Name": "Somerset",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-84.604108, 37.092022",
                            "IataCode": "SME",
                            "Airports": [
                                {
                                    "Id": "SME",
                                    "Name": "Somerset",
                                    "CityId": "SMEA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-84.61411, 37.0548"
                                }
                            ]
                        },
                        {
                            "Id": "SVSA",
                            "Name": "Stevens Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.09083, 66.006389",
                            "IataCode": "SVS",
                            "Airports": [
                                {
                                    "Id": "SVS",
                                    "Name": "Stevens Village",
                                    "CityId": "SVSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.09417, 66.009444"
                                }
                            ]
                        },
                        {
                            "Id": "SMNA",
                            "Name": "Salmon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-113.8959, 45.175755",
                            "IataCode": "SMN",
                            "Airports": [
                                {
                                    "Id": "SMN",
                                    "Name": "Salmon",
                                    "CityId": "SMNA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-113.91586, 45.183005"
                                }
                            ]
                        },
                        {
                            "Id": "SMOA",
                            "Name": "Santa Monica",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.49119, 34.019454",
                            "IataCode": "SMO",
                            "Airports": [
                                {
                                    "Id": "SMO",
                                    "Name": "Santa Monica",
                                    "CityId": "SMOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.5, 34.016667"
                                }
                            ]
                        },
                        {
                            "Id": "SWDA",
                            "Name": "Seward",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.44222, 60.104167",
                            "IataCode": "SWD",
                            "Airports": [
                                {
                                    "Id": "SWD",
                                    "Name": "Seward",
                                    "CityId": "SWDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.41806, 60.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SMXA",
                            "Name": "Santa Maria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.43572, 34.953034",
                            "IataCode": "SMX",
                            "Airports": [
                                {
                                    "Id": "SMX",
                                    "Name": "Santa Maria",
                                    "CityId": "SMXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.45778, 34.905"
                                }
                            ]
                        },
                        {
                            "Id": "SNAA",
                            "Name": "Santa Ana",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.86783, 33.745573",
                            "IataCode": "SNA",
                            "Airports": [
                                {
                                    "Id": "JOC",
                                    "Name": "Santa Ana Centerport Heliport",
                                    "CityId": "SNAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.86944, 33.666667"
                                },
                                {
                                    "Id": "DNT",
                                    "Name": "Santa Ana Downtown Heliport",
                                    "CityId": "SNAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.86667, 33.766667"
                                },
                                {
                                    "Id": "SNA",
                                    "Name": "Santa Ana John Wayne",
                                    "CityId": "SNAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.86694, 33.675556"
                                }
                            ]
                        },
                        {
                            "Id": "SWOA",
                            "Name": "Stillwater",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-97.058368, 36.115607",
                            "IataCode": "SWO",
                            "Airports": [
                                {
                                    "Id": "SWO",
                                    "Name": "Stillwater",
                                    "CityId": "SWOA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-97.085556, 36.158889"
                                }
                            ]
                        },
                        {
                            "Id": "SNKA",
                            "Name": "Snyder",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.91762, 32.717886",
                            "IataCode": "SNK",
                            "Airports": [
                                {
                                    "Id": "SNK",
                                    "Name": "Snyder",
                                    "CityId": "SNKA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.9, 32.716667"
                                }
                            ]
                        },
                        {
                            "Id": "SNLA",
                            "Name": "Shawnee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-96.9253, 35.327293",
                            "IataCode": "SNL",
                            "Airports": [
                                {
                                    "Id": "SNL",
                                    "Name": "Shawnee",
                                    "CityId": "SNLA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-96.916667, 35.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SWWA",
                            "Name": "Sweetwater",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-100.40594, 32.470952",
                            "IataCode": "SWW",
                            "Airports": [
                                {
                                    "Id": "SWW",
                                    "Name": "Sweetwater",
                                    "CityId": "SWWA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-100.4, 32.466667"
                                }
                            ]
                        },
                        {
                            "Id": "SNSA",
                            "Name": "Salinas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.6555, 36.677739",
                            "IataCode": "SNS",
                            "Airports": [
                                {
                                    "Id": "SNS",
                                    "Name": "Salinas",
                                    "CityId": "SNSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.60717, 36.66302"
                                }
                            ]
                        },
                        {
                            "Id": "SNYA",
                            "Name": "Sidney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-102.97798, 41.142762",
                            "IataCode": "SNY",
                            "Airports": [
                                {
                                    "Id": "SNY",
                                    "Name": "Sidney",
                                    "CityId": "SNYA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-102.9825, 41.099444"
                                }
                            ]
                        },
                        {
                            "Id": "SOVA",
                            "Name": "Seldovia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.71139, 59.438056",
                            "IataCode": "SOV",
                            "Airports": [
                                {
                                    "Id": "SOV",
                                    "Name": "Seldovia",
                                    "CityId": "SOVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.71667, 59.45"
                                }
                            ]
                        },
                        {
                            "Id": "SOWA",
                            "Name": "Show Low",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.02983, 34.254208",
                            "IataCode": "SOW",
                            "Airports": [
                                {
                                    "Id": "SOW",
                                    "Name": "Show Low",
                                    "CityId": "SOWA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.00301, 34.266296"
                                }
                            ]
                        },
                        {
                            "Id": "SPAA",
                            "Name": "Spartanburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-81.932048, 34.949567",
                            "IataCode": "SPA",
                            "Airports": [
                                {
                                    "Id": "SPA",
                                    "Name": "Spartanburg",
                                    "CityId": "SPAA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.95733, 34.91623"
                                }
                            ]
                        },
                        {
                            "Id": "SPFA",
                            "Name": "Spearfish",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-103.85937, 44.490817",
                            "IataCode": "SPF",
                            "Airports": [
                                {
                                    "Id": "SPF",
                                    "Name": "Spearfish",
                                    "CityId": "SPFA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-103.86667, 44.5"
                                }
                            ]
                        },
                        {
                            "Id": "SPIA",
                            "Name": "Springfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.64371, 39.801717",
                            "IataCode": "SPI",
                            "Airports": [
                                {
                                    "Id": "SPI",
                                    "Name": "Springfield",
                                    "CityId": "SPIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.678889, 39.844167"
                                }
                            ]
                        },
                        {
                            "Id": "SPWA",
                            "Name": "Spencer",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-95.144439, 43.141358",
                            "IataCode": "SPW",
                            "Airports": [
                                {
                                    "Id": "SPW",
                                    "Name": "Spencer",
                                    "CityId": "SPWA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-95.200833, 43.163889"
                                }
                            ]
                        },
                        {
                            "Id": "SPZA",
                            "Name": "Springdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.128814, 36.186744",
                            "IataCode": "SPZ",
                            "Airports": [
                                {
                                    "Id": "SPZ",
                                    "Name": "Springdale",
                                    "CityId": "SPZA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-94.122019, 36.174015"
                                }
                            ]
                        },
                        {
                            "Id": "SQLA",
                            "Name": "San Carlos",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.26052, 37.507159",
                            "IataCode": "SQL",
                            "Airports": [
                                {
                                    "Id": "SQL",
                                    "Name": "San Carlos",
                                    "CityId": "SQLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.25131, 37.512585"
                                }
                            ]
                        },
                        {
                            "Id": "SRWA",
                            "Name": "Salisbury",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-80.474226, 35.670973",
                            "IataCode": "SRW",
                            "Airports": [
                                {
                                    "Id": "SRW",
                                    "Name": "Salisbury",
                                    "CityId": "SRWA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-80.52173, 35.64181"
                                }
                            ]
                        },
                        {
                            "Id": "SXQA",
                            "Name": "Soldotna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.05833, 60.487778",
                            "IataCode": "SXQ",
                            "Airports": [
                                {
                                    "Id": "SXQ",
                                    "Name": "Soldotna",
                                    "CityId": "SXQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.03639, 60.475556"
                                }
                            ]
                        },
                        {
                            "Id": "SYIA",
                            "Name": "Shelbyville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-86.460272, 35.483406",
                            "IataCode": "SYI",
                            "Airports": [
                                {
                                    "Id": "SYI",
                                    "Name": "Shelbyville",
                                    "CityId": "SYIA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.4425, 35.560833"
                                }
                            ]
                        },
                        {
                            "Id": "SYRA",
                            "Name": "Syracuse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-76.147424, 43.048124",
                            "IataCode": "SYR",
                            "Airports": [
                                {
                                    "Id": "SYR",
                                    "Name": "Syracuse",
                                    "CityId": "SYRA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-76.1025, 43.110556"
                                }
                            ]
                        },
                        {
                            "Id": "SZPA",
                            "Name": "Santa Paula",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.05927, 34.354166",
                            "IataCode": "SZP",
                            "Airports": [
                                {
                                    "Id": "SZP",
                                    "Name": "Santa Paula",
                                    "CityId": "SZPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.06667, 34.35"
                                }
                            ]
                        },
                        {
                            "Id": "TADA",
                            "Name": "Trinidad",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-104.50054, 37.169463",
                            "IataCode": "TAD",
                            "Airports": [
                                {
                                    "Id": "TAD",
                                    "Name": "Trinidad",
                                    "CityId": "TADA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.325, 37.305556"
                                }
                            ]
                        },
                        {
                            "Id": "TALA",
                            "Name": "Tanana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.07889, 65.171944",
                            "IataCode": "TAL",
                            "Airports": [
                                {
                                    "Id": "TAL",
                                    "Name": "Tanana",
                                    "CityId": "TALA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.10806, 65.175"
                                }
                            ]
                        },
                        {
                            "Id": "TBNA",
                            "Name": "Fort Leonard Wood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.15717, 37.70573",
                            "IataCode": "TBN",
                            "Airports": [
                                {
                                    "Id": "TBN",
                                    "Name": "Fort Leonard Wood",
                                    "CityId": "TBNA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.140556, 37.741389"
                                }
                            ]
                        },
                        {
                            "Id": "TBRA",
                            "Name": "Statesboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.783167, 32.448788",
                            "IataCode": "TBR",
                            "Airports": [
                                {
                                    "Id": "TBR",
                                    "Name": "Statesboro",
                                    "CityId": "TBRA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.735556, 32.490278"
                                }
                            ]
                        },
                        {
                            "Id": "TCCA",
                            "Name": "Tucumcari",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-103.72497, 35.171723",
                            "IataCode": "TCC",
                            "Airports": [
                                {
                                    "Id": "TCC",
                                    "Name": "Tucumcari",
                                    "CityId": "TCCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-103.73333, 35.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TCLA",
                            "Name": "Tuscaloosa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.569173, 33.209841",
                            "IataCode": "TCL",
                            "Airports": [
                                {
                                    "Id": "TCL",
                                    "Name": "Tuscaloosa",
                                    "CityId": "TCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.610833, 33.221111"
                                }
                            ]
                        },
                        {
                            "Id": "TIWA",
                            "Name": "Tacoma",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.44429, 47.252877",
                            "IataCode": "TIW",
                            "Airports": [
                                {
                                    "Id": "GRF",
                                    "Name": "Tacoma Gray AAF",
                                    "CityId": "TIWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.57917, 47.083333"
                                },
                                {
                                    "Id": "TIW",
                                    "Name": "Tacoma Industrial",
                                    "CityId": "TIWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.57306, 47.270556"
                                },
                                {
                                    "Id": "TCM",
                                    "Name": "Tacoma Mc Chord AFB",
                                    "CityId": "TIWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.56461, 47.176667"
                                }
                            ]
                        },
                        {
                            "Id": "TCSA",
                            "Name": "Truth Or Consequences",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-107.25281, 33.128405",
                            "IataCode": "TCS",
                            "Airports": [
                                {
                                    "Id": "TCS",
                                    "Name": "Truth Or Consequences",
                                    "CityId": "TCSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-107.2692, 33.23535"
                                }
                            ]
                        },
                        {
                            "Id": "TDOA",
                            "Name": "Toledo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.84678, 46.43983",
                            "IataCode": "TDO",
                            "Airports": [
                                {
                                    "Id": "TDO",
                                    "Name": "Toledo",
                                    "CityId": "TDOA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.80706, 46.47733"
                                }
                            ]
                        },
                        {
                            "Id": "TOLA",
                            "Name": "Toledo",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-83.555212, 41.663938",
                            "IataCode": "TOL",
                            "Airports": [
                                {
                                    "Id": "TDZ",
                                    "Name": "Toledo Metcalf Field",
                                    "CityId": "TOLA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.481674, 41.564601"
                                },
                                {
                                    "Id": "TOL",
                                    "Name": "Toledo Express",
                                    "CityId": "TOLA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-83.800833, 41.590833"
                                }
                            ]
                        },
                        {
                            "Id": "TEBA",
                            "Name": "Teterboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.059308, 40.859822",
                            "IataCode": "TEB",
                            "Airports": [
                                {
                                    "Id": "TEB",
                                    "Name": "Teterboro",
                                    "CityId": "TEBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.062222, 40.849722"
                                }
                            ]
                        },
                        {
                            "Id": "TEKA",
                            "Name": "Tatitlek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.67861, 60.864722",
                            "IataCode": "TEK",
                            "Airports": [
                                {
                                    "Id": "TEK",
                                    "Name": "Tatitlek",
                                    "CityId": "TEKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.68333, 60.866667"
                                }
                            ]
                        },
                        {
                            "Id": "TEXA",
                            "Name": "Telluride",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.81229, 37.937494",
                            "IataCode": "TEX",
                            "Airports": [
                                {
                                    "Id": "TEX",
                                    "Name": "Telluride",
                                    "CityId": "TEXA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.90909, 37.953132"
                                }
                            ]
                        },
                        {
                            "Id": "THAA",
                            "Name": "Tullahoma",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-86.209434, 35.362023",
                            "IataCode": "THA",
                            "Airports": [
                                {
                                    "Id": "THA",
                                    "Name": "Tullahoma Northern",
                                    "CityId": "THAA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.2425, 35.384"
                                },
                                {
                                    "Id": "TUH",
                                    "Name": "Tullahoma Arnold AFS",
                                    "CityId": "THAA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-86.216667, 35.366667"
                                }
                            ]
                        },
                        {
                            "Id": "THPA",
                            "Name": "Thermopolis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-108.21204, 43.646067",
                            "IataCode": "THP",
                            "Airports": [
                                {
                                    "Id": "THP",
                                    "Name": "Thermopolis",
                                    "CityId": "THPA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-108.21667, 43.65"
                                }
                            ]
                        },
                        {
                            "Id": "THVA",
                            "Name": "York",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-76.727745, 39.962598",
                            "IataCode": "THV",
                            "Airports": [
                                {
                                    "Id": "THV",
                                    "Name": "York",
                                    "CityId": "THVA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.716667, 39.95"
                                }
                            ]
                        },
                        {
                            "Id": "TIXA",
                            "Name": "Titusville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.807554, 28.612219",
                            "IataCode": "TIX",
                            "Airports": [
                                {
                                    "Id": "TIX",
                                    "Name": "Titusville",
                                    "CityId": "TIXA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.799444, 28.512222"
                                }
                            ]
                        },
                        {
                            "Id": "TKAA",
                            "Name": "Talkeetna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.10944, 62.323889",
                            "IataCode": "TKA",
                            "Airports": [
                                {
                                    "Id": "TKA",
                                    "Name": "Talkeetna",
                                    "CityId": "TKAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.09, 62.322222"
                                }
                            ]
                        },
                        {
                            "Id": "TKFA",
                            "Name": "Truckee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.18325, 39.327962",
                            "IataCode": "TKF",
                            "Airports": [
                                {
                                    "Id": "TKF",
                                    "Name": "Truckee",
                                    "CityId": "TKFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.14167, 39.325"
                                }
                            ]
                        },
                        {
                            "Id": "TLAA",
                            "Name": "Teller",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.36083, 65.263611",
                            "IataCode": "TLA",
                            "Airports": [
                                {
                                    "Id": "TLA",
                                    "Name": "Teller",
                                    "CityId": "TLAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.33103, 65.240607"
                                }
                            ]
                        },
                        {
                            "Id": "TLHA",
                            "Name": "Tallahassee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-84.280733, 30.438256",
                            "IataCode": "TLH",
                            "Airports": [
                                {
                                    "Id": "TLH",
                                    "Name": "Tallahassee",
                                    "CityId": "TLHA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-84.351944, 30.399722"
                                }
                            ]
                        },
                        {
                            "Id": "TLRA",
                            "Name": "Tulare",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.34734, 36.207729",
                            "IataCode": "TLR",
                            "Airports": [
                                {
                                    "Id": "TLR",
                                    "Name": "Tulare",
                                    "CityId": "TLRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.32744, 36.156519"
                                }
                            ]
                        },
                        {
                            "Id": "TMAA",
                            "Name": "Tifton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.508497, 31.450463",
                            "IataCode": "TMA",
                            "Airports": [
                                {
                                    "Id": "TMA",
                                    "Name": "Tifton",
                                    "CityId": "TMAA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.483333, 31.433333"
                                }
                            ]
                        },
                        {
                            "Id": "TNCA",
                            "Name": "Tin City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-167.94806, 65.558611",
                            "IataCode": "TNC",
                            "Airports": [
                                {
                                    "Id": "TNC",
                                    "Name": "Tin City",
                                    "CityId": "TNCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-167.91889, 65.563889"
                                }
                            ]
                        },
                        {
                            "Id": "TNPA",
                            "Name": "Twentynine Palms",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.05417, 34.135558",
                            "IataCode": "TNP",
                            "Airports": [
                                {
                                    "Id": "TNP",
                                    "Name": "Twentynine Palms",
                                    "CityId": "TNPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.935, 34.118889"
                                }
                            ]
                        },
                        {
                            "Id": "TNUA",
                            "Name": "Newton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-93.047976, 41.699713",
                            "IataCode": "TNU",
                            "Airports": [
                                {
                                    "Id": "TNU",
                                    "Name": "Newton",
                                    "CityId": "TNUA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-93.02159, 41.67416"
                                }
                            ]
                        },
                        {
                            "Id": "TOAA",
                            "Name": "Torrance",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.34063, 33.83585",
                            "IataCode": "TOA",
                            "Airports": [
                                {
                                    "Id": "TOA",
                                    "Name": "Torrance",
                                    "CityId": "TOAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.34035, 33.80335"
                                }
                            ]
                        },
                        {
                            "Id": "TOCA",
                            "Name": "Toccoa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.332385, 34.577321",
                            "IataCode": "TOC",
                            "Airports": [
                                {
                                    "Id": "TOC",
                                    "Name": "Toccoa",
                                    "CityId": "TOCA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.29627, 34.59371"
                                }
                            ]
                        },
                        {
                            "Id": "TOIA",
                            "Name": "Troy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.969951, 31.808768",
                            "IataCode": "TOI",
                            "Airports": [
                                {
                                    "Id": "TOI",
                                    "Name": "Troy",
                                    "CityId": "TOIA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.01218, 31.86043"
                                }
                            ]
                        },
                        {
                            "Id": "TPLA",
                            "Name": "Temple",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.342782, 31.098234",
                            "IataCode": "TPL",
                            "Airports": [
                                {
                                    "Id": "TPL",
                                    "Name": "Temple",
                                    "CityId": "TPLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.409167, 31.150278"
                                }
                            ]
                        },
                        {
                            "Id": "TRHA",
                            "Name": "Trona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.37283, 35.762732",
                            "IataCode": "TRH",
                            "Airports": [
                                {
                                    "Id": "TRH",
                                    "Name": "Trona",
                                    "CityId": "TRHA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.32583, 35.812778"
                                }
                            ]
                        },
                        {
                            "Id": "TRLA",
                            "Name": "Terrell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.275257, 32.735963",
                            "IataCode": "TRL",
                            "Airports": [
                                {
                                    "Id": "TRL",
                                    "Name": "Terrell",
                                    "CityId": "TRLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.275, 32.735833"
                                }
                            ]
                        },
                        {
                            "Id": "TRXA",
                            "Name": "Trenton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.616607, 40.078902",
                            "IataCode": "TRX",
                            "Airports": [
                                {
                                    "Id": "TRX",
                                    "Name": "Trenton",
                                    "CityId": "TRXA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.616667, 40.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TSMA",
                            "Name": "Taos",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.57307, 36.407248",
                            "IataCode": "TSM",
                            "Airports": [
                                {
                                    "Id": "TSM",
                                    "Name": "Taos",
                                    "CityId": "TSMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.67528, 36.459167"
                                }
                            ]
                        },
                        {
                            "Id": "TSPA",
                            "Name": "Tehachapi",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.44897, 35.132188",
                            "IataCode": "TSP",
                            "Airports": [
                                {
                                    "Id": "TSP",
                                    "Name": "Tehachapi",
                                    "CityId": "TSPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.44167, 35.1375"
                                }
                            ]
                        },
                        {
                            "Id": "TTOA",
                            "Name": "Britton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-97.750937, 45.791619",
                            "IataCode": "TTO",
                            "Airports": [
                                {
                                    "Id": "TTO",
                                    "Name": "Britton",
                                    "CityId": "TTOA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-97.74483, 45.81662"
                                }
                            ]
                        },
                        {
                            "Id": "TUPA",
                            "Name": "Tupelo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-88.703386, 34.257607",
                            "IataCode": "TUP",
                            "Airports": [
                                {
                                    "Id": "TUP",
                                    "Name": "Tupelo",
                                    "CityId": "TUPA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-88.767222, 34.258889"
                                }
                            ]
                        },
                        {
                            "Id": "TVCA",
                            "Name": "Traverse City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.620632, 44.763057",
                            "IataCode": "TVC",
                            "Airports": [
                                {
                                    "Id": "TVC",
                                    "Name": "Traverse City",
                                    "CityId": "TVCA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.585833, 44.74"
                                }
                            ]
                        },
                        {
                            "Id": "TVFA",
                            "Name": "Thief River Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-96.181147, 48.119135",
                            "IataCode": "TVF",
                            "Airports": [
                                {
                                    "Id": "TVF",
                                    "Name": "Thief River Falls",
                                    "CityId": "TVFA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-96.185, 48.066111"
                                }
                            ]
                        },
                        {
                            "Id": "TVIA",
                            "Name": "Thomasville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.978781, 30.836581",
                            "IataCode": "TVI",
                            "Airports": [
                                {
                                    "Id": "TVI",
                                    "Name": "Thomasville",
                                    "CityId": "TVIA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.880556, 30.9025"
                                }
                            ]
                        },
                        {
                            "Id": "TVLA",
                            "Name": "South Lake Tahoe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.98435, 38.933241",
                            "IataCode": "TVL",
                            "Airports": [
                                {
                                    "Id": "TVL",
                                    "Name": "South Lake Tahoe",
                                    "CityId": "TVLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.99444, 38.894167"
                                }
                            ]
                        },
                        {
                            "Id": "TWFA",
                            "Name": "Twin Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-114.46087, 42.562967",
                            "IataCode": "TWF",
                            "Airports": [
                                {
                                    "Id": "TWF",
                                    "Name": "Twin Falls",
                                    "CityId": "TWFA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-114.48667, 42.481944"
                                }
                            ]
                        },
                        {
                            "Id": "TXKA",
                            "Name": "Texarkana",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-94.037688, 33.441791",
                            "IataCode": "TXK",
                            "Airports": [
                                {
                                    "Id": "TXK",
                                    "Name": "Texarkana",
                                    "CityId": "TXKA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-93.989167, 33.456389"
                                }
                            ]
                        },
                        {
                            "Id": "TYEA",
                            "Name": "Tyonek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.13694, 61.068056",
                            "IataCode": "TYE",
                            "Airports": [
                                {
                                    "Id": "TYE",
                                    "Name": "Tyonek",
                                    "CityId": "TYEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-151.33333, 61.016667"
                                }
                            ]
                        },
                        {
                            "Id": "TYRA",
                            "Name": "Tyler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.301062, 32.35126",
                            "IataCode": "TYR",
                            "Airports": [
                                {
                                    "Id": "TYR",
                                    "Name": "Tyler",
                                    "CityId": "TYRA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.398889, 32.354722"
                                }
                            ]
                        },
                        {
                            "Id": "TYSA",
                            "Name": "Knoxville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-83.920739, 35.960638",
                            "IataCode": "TYS",
                            "Airports": [
                                {
                                    "Id": "TYS",
                                    "Name": "Knoxville",
                                    "CityId": "TYSA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-83.9925, 35.812222"
                                }
                            ]
                        },
                        {
                            "Id": "TYZA",
                            "Name": "Taylor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.09123, 34.465037",
                            "IataCode": "TYZ",
                            "Airports": [
                                {
                                    "Id": "TYZ",
                                    "Name": "Taylor",
                                    "CityId": "TYZA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.11123, 34.45532"
                                }
                            ]
                        },
                        {
                            "Id": "UCYA",
                            "Name": "Union City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-89.05701, 36.42423",
                            "IataCode": "UCY",
                            "Airports": [
                                {
                                    "Id": "UCY",
                                    "Name": "Union City",
                                    "CityId": "UCYA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-89.05, 36.433333"
                                }
                            ]
                        },
                        {
                            "Id": "UESA",
                            "Name": "Waukesha",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.231481, 43.011678",
                            "IataCode": "UES",
                            "Airports": [
                                {
                                    "Id": "UES",
                                    "Name": "Waukesha",
                                    "CityId": "UESA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.23, 43.038333"
                                }
                            ]
                        },
                        {
                            "Id": "UILA",
                            "Name": "Quillayute",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-124.54243, 47.94313",
                            "IataCode": "UIL",
                            "Airports": [
                                {
                                    "Id": "UIL",
                                    "Name": "Quillayute",
                                    "CityId": "UILA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-124.63333, 47.9"
                                }
                            ]
                        },
                        {
                            "Id": "UINA",
                            "Name": "Quincy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-91.409873, 39.935602",
                            "IataCode": "UIN",
                            "Airports": [
                                {
                                    "Id": "UIN",
                                    "Name": "Quincy",
                                    "CityId": "UINA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-91.197222, 39.944167"
                                }
                            ]
                        },
                        {
                            "Id": "UKIA",
                            "Name": "Ukiah",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-123.20778, 39.150171",
                            "IataCode": "UKI",
                            "Airports": [
                                {
                                    "Id": "UKI",
                                    "Name": "Ukiah",
                                    "CityId": "UKIA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-123.2, 39.126667"
                                }
                            ]
                        },
                        {
                            "Id": "UKTA",
                            "Name": "Quakertown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.341567, 40.441768",
                            "IataCode": "UKT",
                            "Airports": [
                                {
                                    "Id": "UKT",
                                    "Name": "Quakertown",
                                    "CityId": "UKTA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.35, 40.433333"
                                }
                            ]
                        },
                        {
                            "Id": "ULMA",
                            "Name": "New Ulm",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.460529, 44.312463",
                            "IataCode": "ULM",
                            "Airports": [
                                {
                                    "Id": "ULM",
                                    "Name": "New Ulm",
                                    "CityId": "ULMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.500556, 44.321389"
                                }
                            ]
                        },
                        {
                            "Id": "UMMA",
                            "Name": "Summit",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.12222, 63.331389",
                            "IataCode": "UMM",
                            "Airports": [
                                {
                                    "Id": "UMM",
                                    "Name": "Summit",
                                    "CityId": "UMMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.31667, 63.316667"
                                }
                            ]
                        },
                        {
                            "Id": "UNKA",
                            "Name": "Unalakleet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.78806, 63.873056",
                            "IataCode": "UNK",
                            "Airports": [
                                {
                                    "Id": "UNK",
                                    "Name": "Unalakleet",
                                    "CityId": "UNKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.79389, 63.884722"
                                }
                            ]
                        },
                        {
                            "Id": "UOSA",
                            "Name": "Sewanee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-85.92109, 35.203137",
                            "IataCode": "UOS",
                            "Airports": [
                                {
                                    "Id": "UOS",
                                    "Name": "Sewanee",
                                    "CityId": "UOSA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-85.89859, 35.20397"
                                }
                            ]
                        },
                        {
                            "Id": "UOXA",
                            "Name": "Oxford",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.519248, 34.366495",
                            "IataCode": "UOX",
                            "Airports": [
                                {
                                    "Id": "UOX",
                                    "Name": "University-Oxford",
                                    "CityId": "UOXA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.535, 34.384444"
                                }
                            ]
                        },
                        {
                            "Id": "USTB",
                            "Name": "St Augustine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.314517, 29.894691",
                            "IataCode": "UST",
                            "Airports": [
                                {
                                    "Id": "UST",
                                    "Name": "St Augustine",
                                    "CityId": "USTB",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.339169, 29.959248"
                                }
                            ]
                        },
                        {
                            "Id": "TUNA",
                            "Name": "Tunica",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.382877, 34.684545",
                            "IataCode": "UTM",
                            "Airports": [
                                {
                                    "Id": "UTM",
                                    "Name": "Tunica Municipal",
                                    "CityId": "TUNA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.34639, 34.68083"
                                }
                            ]
                        },
                        {
                            "Id": "UVAA",
                            "Name": "Uvalde",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.786168, 29.209684",
                            "IataCode": "UVA",
                            "Airports": [
                                {
                                    "Id": "UVA",
                                    "Name": "Uvalde",
                                    "CityId": "UVAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.735, 29.201944"
                                }
                            ]
                        },
                        {
                            "Id": "VLDA",
                            "Name": "Valdosta",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-83.278485, 30.832702",
                            "IataCode": "VLD",
                            "Airports": [
                                {
                                    "Id": "VLD",
                                    "Name": "Valdosta Regional",
                                    "CityId": "VLDA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.277222, 30.783889"
                                },
                                {
                                    "Id": "VAD",
                                    "Name": "Valdosta Moody AFB",
                                    "CityId": "VLDA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.283333, 30.833333"
                                }
                            ]
                        },
                        {
                            "Id": "VAKA",
                            "Name": "Chevak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.58639, 61.527778",
                            "IataCode": "VAK",
                            "Airports": [
                                {
                                    "Id": "VAK",
                                    "Name": "Chevak",
                                    "CityId": "VAKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.59028, 61.528611"
                                }
                            ]
                        },
                        {
                            "Id": "VCTA",
                            "Name": "Victoria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.003598, 28.805267",
                            "IataCode": "VCT",
                            "Airports": [
                                {
                                    "Id": "VCT",
                                    "Name": "Victoria",
                                    "CityId": "VCTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.914444, 28.851111"
                                }
                            ]
                        },
                        {
                            "Id": "VCVA",
                            "Name": "Victorville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.29116, 34.536107",
                            "IataCode": "VCV",
                            "Airports": [
                                {
                                    "Id": "VCV",
                                    "Name": "Victorville",
                                    "CityId": "VCVA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.37444, 34.58696"
                                }
                            ]
                        },
                        {
                            "Id": "VDIA",
                            "Name": "Vidalia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-82.413461, 32.217685",
                            "IataCode": "VDI",
                            "Airports": [
                                {
                                    "Id": "VDI",
                                    "Name": "Vidalia",
                                    "CityId": "VDIA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-82.416667, 32.216667"
                                }
                            ]
                        },
                        {
                            "Id": "VDZA",
                            "Name": "Valdez",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.34833, 61.130833",
                            "IataCode": "VDZ",
                            "Airports": [
                                {
                                    "Id": "VDZ",
                                    "Name": "Valdez",
                                    "CityId": "VDZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.23889, 61.1325"
                                }
                            ]
                        },
                        {
                            "Id": "VEEA",
                            "Name": "Venetie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.41861, 67.013889",
                            "IataCode": "VEE",
                            "Airports": [
                                {
                                    "Id": "VEE",
                                    "Name": "Venetie",
                                    "CityId": "VEEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.41111, 67.023056"
                                }
                            ]
                        },
                        {
                            "Id": "VELA",
                            "Name": "Vernal",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-109.52875, 40.455516",
                            "IataCode": "VEL",
                            "Airports": [
                                {
                                    "Id": "VEL",
                                    "Name": "Vernal",
                                    "CityId": "VELA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-109.51103, 40.438847"
                                }
                            ]
                        },
                        {
                            "Id": "VHNA",
                            "Name": "Van Horn",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-104.83077, 31.039856",
                            "IataCode": "VHN",
                            "Airports": [
                                {
                                    "Id": "VHN",
                                    "Name": "Van Horn",
                                    "CityId": "VHNA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-104.83333, 31.05"
                                }
                            ]
                        },
                        {
                            "Id": "VISA",
                            "Name": "Visalia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.29206, 36.330228",
                            "IataCode": "VIS",
                            "Airports": [
                                {
                                    "Id": "VIS",
                                    "Name": "Visalia",
                                    "CityId": "VISA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.39389, 36.319444"
                                }
                            ]
                        },
                        {
                            "Id": "VJIA",
                            "Name": "Abingdon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-81.977348, 36.709833",
                            "IataCode": "VJI",
                            "Airports": [
                                {
                                    "Id": "VJI",
                                    "Name": "Abingdon",
                                    "CityId": "VJIA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-82.033333, 36.686111"
                                }
                            ]
                        },
                        {
                            "Id": "VNCA",
                            "Name": "Venice",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.454263, 27.099777",
                            "IataCode": "VNC",
                            "Airports": [
                                {
                                    "Id": "VNC",
                                    "Name": "Venice",
                                    "CityId": "VNCA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.433333, 27.066667"
                                }
                            ]
                        },
                        {
                            "Id": "VOKA",
                            "Name": "Camp Douglas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.271519, 43.922467",
                            "IataCode": "VOK",
                            "Airports": [
                                {
                                    "Id": "VOK",
                                    "Name": "Camp Douglas",
                                    "CityId": "VOKA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.283333, 43.933333"
                                }
                            ]
                        },
                        {
                            "Id": "VPSA",
                            "Name": "Valparaiso",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-86.502728, 30.508531",
                            "IataCode": "VPS",
                            "Airports": [
                                {
                                    "Id": "VPS",
                                    "Name": "Valparaiso Ft. Walton Beach",
                                    "CityId": "VPSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-86.527222, 30.489444"
                                },
                                {
                                    "Id": "EGI",
                                    "Name": "Valparaiso Duke Field",
                                    "CityId": "VPSA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-86.5, 30.483333"
                                }
                            ]
                        },
                        {
                            "Id": "VPZA",
                            "Name": "Valparaiso",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-87.061141, 41.473095",
                            "IataCode": "VPZ",
                            "Airports": [
                                {
                                    "Id": "VPZ",
                                    "Name": "Valparaiso",
                                    "CityId": "VPZA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-87.005278, 41.4525"
                                }
                            ]
                        },
                        {
                            "Id": "VRBA",
                            "Name": "Vero Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.397274, 27.638643",
                            "IataCode": "VRB",
                            "Airports": [
                                {
                                    "Id": "VRB",
                                    "Name": "Vero Beach",
                                    "CityId": "VRBA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.413611, 27.6525"
                                }
                            ]
                        },
                        {
                            "Id": "VSFA",
                            "Name": "Springfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.482312, 43.298408",
                            "IataCode": "VSF",
                            "Airports": [
                                {
                                    "Id": "VSF",
                                    "Name": "Springfield",
                                    "CityId": "VSFA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.52157, 43.34307"
                                }
                            ]
                        },
                        {
                            "Id": "VTNA",
                            "Name": "Valentine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-100.55097, 42.872783",
                            "IataCode": "VTN",
                            "Airports": [
                                {
                                    "Id": "VTN",
                                    "Name": "Valentine",
                                    "CityId": "VTNA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-100.55, 42.866667"
                                }
                            ]
                        },
                        {
                            "Id": "AVPA",
                            "Name": "Wilkes-Barre",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.881307, 41.245915",
                            "IataCode": "AVP",
                            "Airports": [
                                {
                                    "Id": "WBW",
                                    "Name": "Wilkes-Barre Wyoming Valle",
                                    "CityId": "AVPA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.853987, 41.298638"
                                },
                                {
                                    "Id": "AVP",
                                    "Name": "Wilkes-Barre International",
                                    "CityId": "AVPA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.725833, 41.339167"
                                }
                            ]
                        },
                        {
                            "Id": "VYSA",
                            "Name": "Peru",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.128973, 41.327535",
                            "IataCode": "VYS",
                            "Airports": [
                                {
                                    "Id": "VYS",
                                    "Name": "Peru",
                                    "CityId": "VYSA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.15298, 41.351073"
                                }
                            ]
                        },
                        {
                            "Id": "WAAA",
                            "Name": "Wales",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-168.0875, 65.609167",
                            "IataCode": "WAA",
                            "Airports": [
                                {
                                    "Id": "WAA",
                                    "Name": "Wales",
                                    "CityId": "WAAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-168.09083, 65.617222"
                                }
                            ]
                        },
                        {
                            "Id": "WAHA",
                            "Name": "Wahpeton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_DAK",
                            "Location": "-96.605907, 46.265237",
                            "IataCode": "WAH",
                            "Airports": [
                                {
                                    "Id": "WAH",
                                    "Name": "Wahpeton",
                                    "CityId": "WAHA",
                                    "CountryId": "US",
                                    "RegionId": "N_DAK",
                                    "Location": "-96.6, 46.266667"
                                }
                            ]
                        },
                        {
                            "Id": "WBBA",
                            "Name": "Stebbins",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.28806, 63.522222",
                            "IataCode": "WBB",
                            "Airports": [
                                {
                                    "Id": "WBB",
                                    "Name": "Stebbins",
                                    "CityId": "WBBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.27889, 63.516667"
                                }
                            ]
                        },
                        {
                            "Id": "WBQA",
                            "Name": "Beaver",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.39639, 66.359444",
                            "IataCode": "WBQ",
                            "Airports": [
                                {
                                    "Id": "WBQ",
                                    "Name": "Beaver",
                                    "CityId": "WBQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.40833, 66.362222"
                                }
                            ]
                        },
                        {
                            "Id": "WBRA",
                            "Name": "Big Rapids",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.483656, 43.698078",
                            "IataCode": "WBR",
                            "Airports": [
                                {
                                    "Id": "WBR",
                                    "Name": "Big Rapids",
                                    "CityId": "WBRA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.50393, 43.72252"
                                }
                            ]
                        },
                        {
                            "Id": "WBUA",
                            "Name": "Boulder",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.27055, 40.014986",
                            "IataCode": "WBU",
                            "Airports": [
                                {
                                    "Id": "WBU",
                                    "Name": "Boulder Municipal",
                                    "CityId": "WBUA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.22556, 40.039167"
                                },
                                {
                                    "Id": "WBI",
                                    "Name": "Boulder Broker Inn",
                                    "CityId": "WBUA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.28333, 40.016667"
                                },
                                {
                                    "Id": "WHH",
                                    "Name": "Boulder Hiltons Har H",
                                    "CityId": "WBUA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.23472, 40.030556"
                                }
                            ]
                        },
                        {
                            "Id": "AHFA",
                            "Name": "Arapahoe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.900402, 40.304174",
                            "IataCode": "AHF",
                            "Airports": [
                                {
                                    "Id": "AHF",
                                    "Name": "Arapahoe",
                                    "CityId": "AHFA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.9, 40.3"
                                }
                            ]
                        },
                        {
                            "Id": "ALEA",
                            "Name": "Alpine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-103.66101, 30.358492",
                            "IataCode": "ALE",
                            "Airports": [
                                {
                                    "Id": "ALE",
                                    "Name": "Alpine",
                                    "CityId": "ALEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-103.68351, 30.38405"
                                }
                            ]
                        },
                        {
                            "Id": "ARGA",
                            "Name": "Walnut Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.955953, 36.068403",
                            "IataCode": "ARG",
                            "Airports": [
                                {
                                    "Id": "ARG",
                                    "Name": "Walnut Ridge",
                                    "CityId": "ARGA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.92456, 36.12535"
                                }
                            ]
                        },
                        {
                            "Id": "ARTA",
                            "Name": "Watertown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.910756, 43.974784",
                            "IataCode": "ART",
                            "Airports": [
                                {
                                    "Id": "ART",
                                    "Name": "Watertown",
                                    "CityId": "ARTA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-76.021389, 43.990833"
                                }
                            ]
                        },
                        {
                            "Id": "ASEA",
                            "Name": "Aspen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.81754, 39.191098",
                            "IataCode": "ASE",
                            "Airports": [
                                {
                                    "Id": "ASE",
                                    "Name": "Aspen",
                                    "CityId": "ASEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.86985, 39.224275"
                                }
                            ]
                        },
                        {
                            "Id": "ASHA",
                            "Name": "Nashua",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_H",
                            "Location": "-71.467566, 42.765366",
                            "IataCode": "ASH",
                            "Airports": [
                                {
                                    "Id": "ASH",
                                    "Name": "Nashua",
                                    "CityId": "ASHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_H",
                                    "Location": "-71.509444, 42.778889"
                                }
                            ]
                        },
                        {
                            "Id": "ASLA",
                            "Name": "Marshall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.367418, 32.544871",
                            "IataCode": "ASL",
                            "Airports": [
                                {
                                    "Id": "ASL",
                                    "Name": "Marshall",
                                    "CityId": "ASLA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.30825, 32.52182"
                                }
                            ]
                        },
                        {
                            "Id": "ASNA",
                            "Name": "Talladega",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-86.105805, 33.435942",
                            "IataCode": "ASN",
                            "Airports": [
                                {
                                    "Id": "ASN",
                                    "Name": "Talladega",
                                    "CityId": "ASNA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-86.1, 33.433333"
                                }
                            ]
                        },
                        {
                            "Id": "ASQA",
                            "Name": "Austin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-117.06953, 39.493259",
                            "IataCode": "ASQ",
                            "Airports": [
                                {
                                    "Id": "ASQ",
                                    "Name": "Austin",
                                    "CityId": "ASQA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-117.16667, 39.466667"
                                }
                            ]
                        },
                        {
                            "Id": "ASTA",
                            "Name": "Astoria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-123.83125, 46.187884",
                            "IataCode": "AST",
                            "Airports": [
                                {
                                    "Id": "AST",
                                    "Name": "Astoria",
                                    "CityId": "ASTA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-123.88, 46.159722"
                                }
                            ]
                        },
                        {
                            "Id": "ASXA",
                            "Name": "Ashland (Wisconsin)",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-90.883798, 46.592443",
                            "IataCode": "ASX",
                            "Airports": [
                                {
                                    "Id": "ASX",
                                    "Name": "Ashland (Wisconsin)",
                                    "CityId": "ASXA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-90.916667, 46.55"
                                }
                            ]
                        },
                        {
                            "Id": "ATEA",
                            "Name": "Antlers",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.620248, 34.231212",
                            "IataCode": "ATE",
                            "Airports": [
                                {
                                    "Id": "ATE",
                                    "Name": "Antlers",
                                    "CityId": "ATEA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.616667, 34.233333"
                                }
                            ]
                        },
                        {
                            "Id": "ATKA",
                            "Name": "Atqasuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.39944, 70.469167",
                            "IataCode": "ATK",
                            "Airports": [
                                {
                                    "Id": "ATK",
                                    "Name": "Atqasuk",
                                    "CityId": "ATKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.33333, 70.5"
                                }
                            ]
                        },
                        {
                            "Id": "ATSA",
                            "Name": "Artesia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-104.4033, 32.842334",
                            "IataCode": "ATS",
                            "Airports": [
                                {
                                    "Id": "ATS",
                                    "Name": "Artesia",
                                    "CityId": "ATSA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-104.46694, 32.848333"
                                }
                            ]
                        },
                        {
                            "Id": "ATTA",
                            "Name": "Atmautluak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.27306, 60.866944",
                            "IataCode": "ATT",
                            "Airports": [
                                {
                                    "Id": "ATT",
                                    "Name": "Atmautluak",
                                    "CityId": "ATTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.2675, 60.866944"
                                }
                            ]
                        },
                        {
                            "Id": "ATWA",
                            "Name": "Appleton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-88.415384, 44.261931",
                            "IataCode": "ATW",
                            "Airports": [
                                {
                                    "Id": "ATW",
                                    "Name": "Appleton",
                                    "CityId": "ATWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-88.521111, 44.258889"
                                }
                            ]
                        },
                        {
                            "Id": "ATYA",
                            "Name": "Watertown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_DAK",
                            "Location": "-97.115073, 44.899409",
                            "IataCode": "ATY",
                            "Airports": [
                                {
                                    "Id": "ATY",
                                    "Name": "Watertown",
                                    "CityId": "ATYA",
                                    "CountryId": "US",
                                    "RegionId": "S_DAK",
                                    "Location": "-97.154167, 44.908889"
                                }
                            ]
                        },
                        {
                            "Id": "AUGA",
                            "Name": "Augusta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-69.77949, 44.310624",
                            "IataCode": "AUG",
                            "Airports": [
                                {
                                    "Id": "AUG",
                                    "Name": "Augusta",
                                    "CityId": "AUGA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-69.796667, 44.318056"
                                }
                            ]
                        },
                        {
                            "Id": "AUKA",
                            "Name": "Alakanuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.61528, 62.688889",
                            "IataCode": "AUK",
                            "Airports": [
                                {
                                    "Id": "AUK",
                                    "Name": "Alakanuk",
                                    "CityId": "AUKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.61083, 62.689167"
                                }
                            ]
                        },
                        {
                            "Id": "AUMA",
                            "Name": "Austin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-92.974637, 43.66663",
                            "IataCode": "AUM",
                            "Airports": [
                                {
                                    "Id": "AUM",
                                    "Name": "Austin",
                                    "CityId": "AUMA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-92.93352, 43.66469"
                                }
                            ]
                        },
                        {
                            "Id": "AUNA",
                            "Name": "Auburn",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.07689, 38.896565",
                            "IataCode": "AUN",
                            "Airports": [
                                {
                                    "Id": "AUN",
                                    "Name": "Auburn",
                                    "CityId": "AUNA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.06667, 38.9"
                                }
                            ]
                        },
                        {
                            "Id": "AUOA",
                            "Name": "Auburn",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.480783, 32.609857",
                            "IataCode": "AUO",
                            "Airports": [
                                {
                                    "Id": "AUO",
                                    "Name": "Auburn-Opelika",
                                    "CityId": "AUOA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.5, 32.633333"
                                }
                            ]
                        },
                        {
                            "Id": "AUWA",
                            "Name": "Wausau",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.630122, 44.959135",
                            "IataCode": "AUW",
                            "Airports": [
                                {
                                    "Id": "AUW",
                                    "Name": "Wausau Municipal",
                                    "CityId": "AUWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.623056, 44.923333"
                                },
                                {
                                    "Id": "CWA",
                                    "Name": "Wausau Central Wisconsin",
                                    "CityId": "AUWA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.659167, 44.782222"
                                }
                            ]
                        },
                        {
                            "Id": "AVLA",
                            "Name": "Asheville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-82.554015, 35.600945",
                            "IataCode": "AVL",
                            "Airports": [
                                {
                                    "Id": "AVL",
                                    "Name": "Asheville",
                                    "CityId": "AVLA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-82.541111, 35.435"
                                }
                            ]
                        },
                        {
                            "Id": "AVOA",
                            "Name": "Avon Park",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.506186, 27.595867",
                            "IataCode": "AVO",
                            "Airports": [
                                {
                                    "Id": "AVO",
                                    "Name": "Avon Park",
                                    "CityId": "AVOA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.516667, 27.6"
                                }
                            ]
                        },
                        {
                            "Id": "AWMA",
                            "Name": "West Memphis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.184539, 35.14648",
                            "IataCode": "AWM",
                            "Airports": [
                                {
                                    "Id": "AWM",
                                    "Name": "West Memphis",
                                    "CityId": "AWMA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.23426, 35.1362"
                                }
                            ]
                        },
                        {
                            "Id": "AXBA",
                            "Name": "Alexandria Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-75.917731, 44.335884",
                            "IataCode": "AXB",
                            "Airports": [
                                {
                                    "Id": "AXB",
                                    "Name": "Alexandria Bay",
                                    "CityId": "AXBA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-75.901389, 44.2525"
                                }
                            ]
                        },
                        {
                            "Id": "AXGA",
                            "Name": "Algona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-94.233019, 43.069966",
                            "IataCode": "AXG",
                            "Airports": [
                                {
                                    "Id": "AXG",
                                    "Name": "Algona",
                                    "CityId": "AXGA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-94.27191, 43.07774"
                                }
                            ]
                        },
                        {
                            "Id": "AXNA",
                            "Name": "Alexandria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.377538, 45.88524",
                            "IataCode": "AXN",
                            "Airports": [
                                {
                                    "Id": "AXN",
                                    "Name": "Alexandria",
                                    "CityId": "AXNA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.394167, 45.867222"
                                }
                            ]
                        },
                        {
                            "Id": "AYSA",
                            "Name": "Waycross",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-82.354018, 31.213551",
                            "IataCode": "AYS",
                            "Airports": [
                                {
                                    "Id": "AYS",
                                    "Name": "Waycross",
                                    "CityId": "AYSA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-82.396667, 31.248611"
                                }
                            ]
                        },
                        {
                            "Id": "AZOA",
                            "Name": "Kalamazoo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.587229, 42.291707",
                            "IataCode": "AZO",
                            "Airports": [
                                {
                                    "Id": "AZO",
                                    "Name": "Kalamazoo",
                                    "CityId": "AZOA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.553611, 42.236389"
                                }
                            ]
                        },
                        {
                            "Id": "BAMA",
                            "Name": "Battle Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-116.93427, 40.642133",
                            "IataCode": "BAM",
                            "Airports": [
                                {
                                    "Id": "BAM",
                                    "Name": "Battle Mountain",
                                    "CityId": "BAMA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-116.93333, 40.633333"
                                }
                            ]
                        },
                        {
                            "Id": "BBBA",
                            "Name": "Benson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.600035, 45.31496",
                            "IataCode": "BBB",
                            "Airports": [
                                {
                                    "Id": "BBB",
                                    "Name": "Benson",
                                    "CityId": "BBBA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.65087, 45.33163"
                                }
                            ]
                        },
                        {
                            "Id": "BBCA",
                            "Name": "Bay City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.969402, 28.982756",
                            "IataCode": "BBC",
                            "Airports": [
                                {
                                    "Id": "BBC",
                                    "Name": "Bay City",
                                    "CityId": "BBCA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.966667, 28.983333"
                                }
                            ]
                        },
                        {
                            "Id": "BBDA",
                            "Name": "Brady",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.335055, 31.135168",
                            "IataCode": "BBD",
                            "Airports": [
                                {
                                    "Id": "BBD",
                                    "Name": "Brady",
                                    "CityId": "BBDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.32339, 31.17878"
                                }
                            ]
                        },
                        {
                            "Id": "BBWA",
                            "Name": "Broken Bow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-99.639279, 41.401951",
                            "IataCode": "BBW",
                            "Airports": [
                                {
                                    "Id": "BBW",
                                    "Name": "Broken Bow",
                                    "CityId": "BBWA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-99.642241, 41.436775"
                                }
                            ]
                        },
                        {
                            "Id": "BCBA",
                            "Name": "Blacksburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-80.413939, 37.229573",
                            "IataCode": "BCB",
                            "Airports": [
                                {
                                    "Id": "BCB",
                                    "Name": "Blacksburg",
                                    "CityId": "BCBA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-80.40782, 37.206064"
                                }
                            ]
                        },
                        {
                            "Id": "BCSA",
                            "Name": "Belle Chasse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-89.990625, 29.854929",
                            "IataCode": "BCS",
                            "Airports": [
                                {
                                    "Id": "BCS",
                                    "Name": "Belle Chasse",
                                    "CityId": "BCSA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-90, 29.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MMNA",
                            "Name": "Stow",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.50562, 42.437037",
                            "IataCode": "MMN",
                            "Airports": [
                                {
                                    "Id": "MMN",
                                    "Name": "Stow",
                                    "CityId": "MMNA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.5, 42.433333"
                                }
                            ]
                        },
                        {
                            "Id": "MIFA",
                            "Name": "Monahans",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-102.89265, 31.594299",
                            "IataCode": "MIF",
                            "Airports": [
                                {
                                    "Id": "MIF",
                                    "Name": "Monahans",
                                    "CityId": "MIFA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-102.9, 31.6"
                                }
                            ]
                        },
                        {
                            "Id": "MPEA",
                            "Name": "Madison",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-72.598426, 41.279543",
                            "IataCode": "MPE",
                            "Airports": [
                                {
                                    "Id": "MPE",
                                    "Name": "Madison",
                                    "CityId": "MPEA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-72.6, 41.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MSCA",
                            "Name": "Mesa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.82264, 33.422268",
                            "IataCode": "MSC",
                            "Airports": [
                                {
                                    "Id": "MSC",
                                    "Name": "Mesa",
                                    "CityId": "MSCA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.72875, 33.46088"
                                }
                            ]
                        },
                        {
                            "Id": "MXYA",
                            "Name": "Mccarthy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.92167, 61.433333",
                            "IataCode": "MXY",
                            "Airports": [
                                {
                                    "Id": "MXY",
                                    "Name": "Mccarthy",
                                    "CityId": "MXYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.93736, 61.470611"
                                }
                            ]
                        },
                        {
                            "Id": "PECA",
                            "Name": "Pelican",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-136.2275, 57.960833",
                            "IataCode": "PEC",
                            "Airports": [
                                {
                                    "Id": "PEC",
                                    "Name": "Pelican",
                                    "CityId": "PECA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-136.23333, 57.95"
                                }
                            ]
                        },
                        {
                            "Id": "PMXA",
                            "Name": "Palmer",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.328693, 42.158427",
                            "IataCode": "PMX",
                            "Airports": [
                                {
                                    "Id": "PMX",
                                    "Name": "Palmer",
                                    "CityId": "PMXA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.316667, 42.15"
                                }
                            ]
                        },
                        {
                            "Id": "RGRA",
                            "Name": "Ranger",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.678948, 32.469852",
                            "IataCode": "RGR",
                            "Airports": [
                                {
                                    "Id": "RGR",
                                    "Name": "Ranger",
                                    "CityId": "RGRA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.683333, 32.466667"
                                }
                            ]
                        },
                        {
                            "Id": "WSHA",
                            "Name": "Shirley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-72.867603, 40.801488",
                            "IataCode": "WSH",
                            "Airports": [
                                {
                                    "Id": "WSH",
                                    "Name": "Shirley",
                                    "CityId": "WSHA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-72.863388, 40.813225"
                                }
                            ]
                        },
                        {
                            "Id": "RKHA",
                            "Name": "Rock Hill",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-81.025078, 34.924867",
                            "IataCode": "RKH",
                            "Airports": [
                                {
                                    "Id": "RKH",
                                    "Name": "Rock Hill",
                                    "CityId": "RKHA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-81.016667, 34.916667"
                                }
                            ]
                        },
                        {
                            "Id": "RSXA",
                            "Name": "Rouses Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.364856, 44.993928",
                            "IataCode": "RSX",
                            "Airports": [
                                {
                                    "Id": "RSX",
                                    "Name": "Rouses Point",
                                    "CityId": "RSXA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.352222, 44.984722"
                                }
                            ]
                        },
                        {
                            "Id": "RUIA",
                            "Name": "Ruidoso",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-105.67304, 33.331749",
                            "IataCode": "RUI",
                            "Airports": [
                                {
                                    "Id": "RUI",
                                    "Name": "Ruidoso",
                                    "CityId": "RUIA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.6625, 33.360833"
                                }
                            ]
                        },
                        {
                            "Id": "RVRA",
                            "Name": "Green River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-110.1618, 38.99525",
                            "IataCode": "RVR",
                            "Airports": [
                                {
                                    "Id": "RVR",
                                    "Name": "Green River",
                                    "CityId": "RVRA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-110.25, 38.966667"
                                }
                            ]
                        },
                        {
                            "Id": "TKEA",
                            "Name": "Tenakee Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.21889, 57.780833",
                            "IataCode": "TKE",
                            "Airports": [
                                {
                                    "Id": "TKE",
                                    "Name": "Tenakee Springs",
                                    "CityId": "TKEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.21667, 57.783333"
                                }
                            ]
                        },
                        {
                            "Id": "KPDA",
                            "Name": "King Of Prussia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.396021, 40.089275",
                            "IataCode": "KPD",
                            "Airports": [
                                {
                                    "Id": "KPD",
                                    "Name": "King Of Prussia",
                                    "CityId": "KPDA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.4, 40.083333"
                                }
                            ]
                        },
                        {
                            "Id": "CODA",
                            "Name": "Cody / Yellowstone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-109.05653, 44.526342",
                            "IataCode": "COD",
                            "Airports": [
                                {
                                    "Id": "COD",
                                    "Name": "Cody / Yellowstone",
                                    "CityId": "CODA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-109.02592, 44.519031"
                                }
                            ]
                        },
                        {
                            "Id": "TWAA",
                            "Name": "Twin Hills",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.275, 59.079167",
                            "IataCode": "TWA",
                            "Airports": [
                                {
                                    "Id": "TWA",
                                    "Name": "Twin Hills",
                                    "CityId": "TWAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.27278, 59.07556"
                                }
                            ]
                        },
                        {
                            "Id": "MLKA",
                            "Name": "Malta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-107.87428, 48.35972",
                            "IataCode": "MLK",
                            "Airports": [
                                {
                                    "Id": "MLK",
                                    "Name": "Malta",
                                    "CityId": "MLKA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-107.9095, 48.494417"
                                }
                            ]
                        },
                        {
                            "Id": "MQIA",
                            "Name": "Quincy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.00227, 42.252877",
                            "IataCode": "MQI",
                            "Airports": [
                                {
                                    "Id": "MQI",
                                    "Name": "Quincy",
                                    "CityId": "MQIA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71, 42.25"
                                }
                            ]
                        },
                        {
                            "Id": "MSDA",
                            "Name": "Mt Pleasant",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-111.45547, 39.546907",
                            "IataCode": "MSD",
                            "Airports": [
                                {
                                    "Id": "MSD",
                                    "Name": "Mt Pleasant",
                                    "CityId": "MSDA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-111.47444, 39.524722"
                                }
                            ]
                        },
                        {
                            "Id": "MXCA",
                            "Name": "Monticello",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-109.34289, 37.871383",
                            "IataCode": "MXC",
                            "Airports": [
                                {
                                    "Id": "MXC",
                                    "Name": "Monticello",
                                    "CityId": "MXCA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-109.36667, 37.883333"
                                }
                            ]
                        },
                        {
                            "Id": "TKJA",
                            "Name": "Tok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.98556, 63.336667",
                            "IataCode": "TKJ",
                            "Airports": [
                                {
                                    "Id": "TKJ",
                                    "Name": "Tok",
                                    "CityId": "TKJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-143, 63.3"
                                }
                            ]
                        },
                        {
                            "Id": "VLEA",
                            "Name": "Valle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.1385, 35.653607",
                            "IataCode": "VLE",
                            "Airports": [
                                {
                                    "Id": "VLE",
                                    "Name": "Valle",
                                    "CityId": "VLEA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-112.18333, 35.65"
                                }
                            ]
                        },
                        {
                            "Id": "RNGA",
                            "Name": "Rangely",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-108.80483, 40.087476",
                            "IataCode": "RNG",
                            "Airports": [
                                {
                                    "Id": "RNG",
                                    "Name": "Rangely",
                                    "CityId": "RNGA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-108.76056, 40.094722"
                                }
                            ]
                        },
                        {
                            "Id": "TBCA",
                            "Name": "Tuba City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.23986, 36.134993",
                            "IataCode": "TBC",
                            "Airports": [
                                {
                                    "Id": "TBC",
                                    "Name": "Tuba City",
                                    "CityId": "TBCA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.37837, 36.085523"
                                }
                            ]
                        },
                        {
                            "Id": "POCA",
                            "Name": "La Verne",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.76784, 34.100843",
                            "IataCode": "POC",
                            "Airports": [
                                {
                                    "Id": "POC",
                                    "Name": "La Verne",
                                    "CityId": "POCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.78256, 34.0914"
                                }
                            ]
                        },
                        {
                            "Id": "NVDA",
                            "Name": "Nevada",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.354672, 37.839205",
                            "IataCode": "NVD",
                            "Airports": [
                                {
                                    "Id": "NVD",
                                    "Name": "Nevada",
                                    "CityId": "NVDA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.303889, 37.851389"
                                }
                            ]
                        },
                        {
                            "Id": "ANPA",
                            "Name": "Annapolis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.492183, 38.978445",
                            "IataCode": "ANP",
                            "Airports": [
                                {
                                    "Id": "ANP",
                                    "Name": "Annapolis",
                                    "CityId": "ANPA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.5, 38.983333"
                                }
                            ]
                        },
                        {
                            "Id": "PHDA",
                            "Name": "New Philadelphia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.445671, 40.489787",
                            "IataCode": "PHD",
                            "Airports": [
                                {
                                    "Id": "PHD",
                                    "Name": "New Philadelphia",
                                    "CityId": "PHDA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.45, 40.5"
                                }
                            ]
                        },
                        {
                            "Id": "LYUA",
                            "Name": "Ely",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-91.867087, 47.903237",
                            "IataCode": "LYU",
                            "Airports": [
                                {
                                    "Id": "LYU",
                                    "Name": "Ely",
                                    "CityId": "LYUA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-91.829167, 47.823889"
                                }
                            ]
                        },
                        {
                            "Id": "PCDA",
                            "Name": "Prairie Du Chien",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-91.14124, 43.05165",
                            "IataCode": "PCD",
                            "Airports": [
                                {
                                    "Id": "PCD",
                                    "Name": "Prairie Du Chien",
                                    "CityId": "PCDA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-91.116667, 43.016667"
                                }
                            ]
                        },
                        {
                            "Id": "BDXA",
                            "Name": "Broadus",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-105.40749, 45.443881",
                            "IataCode": "BDX",
                            "Airports": [
                                {
                                    "Id": "BDX",
                                    "Name": "Broadus",
                                    "CityId": "BDXA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-105.36667, 45.466667"
                                }
                            ]
                        },
                        {
                            "Id": "ISMA",
                            "Name": "Kissimmee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.417266, 28.304675",
                            "IataCode": "ISM",
                            "Airports": [
                                {
                                    "Id": "ISM",
                                    "Name": "Kissimmee",
                                    "CityId": "ISMA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.40563, 28.31557"
                                }
                            ]
                        },
                        {
                            "Id": "MSYA",
                            "Name": "New Orleans",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-89.929976, 30.068836",
                            "IataCode": "MSY",
                            "Airports": [
                                {
                                    "Id": "NEW",
                                    "Name": "New Orleans Lakefront",
                                    "CityId": "MSYA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-90.026667, 30.039444"
                                },
                                {
                                    "Id": "NBG",
                                    "Name": "New Orleans NAS / Alvin Callendar",
                                    "CityId": "MSYA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-90.033333, 29.833333"
                                },
                                {
                                    "Id": "MSY",
                                    "Name": "New Orleans Louis Armstrong",
                                    "CityId": "MSYA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-90.251389, 29.991111"
                                }
                            ]
                        },
                        {
                            "Id": "SRQA",
                            "Name": "Sarasota",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.530653, 27.336435",
                            "IataCode": "SRQ",
                            "Airports": [
                                {
                                    "Id": "SRQ",
                                    "Name": "Sarasota",
                                    "CityId": "SRQA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.554167, 27.393889"
                                }
                            ]
                        },
                        {
                            "Id": "EYWA",
                            "Name": "Key West",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.776804, 24.564051",
                            "IataCode": "EYW",
                            "Airports": [
                                {
                                    "Id": "EYW",
                                    "Name": "Key West International",
                                    "CityId": "EYWA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.759722, 24.556111"
                                },
                                {
                                    "Id": "NQX",
                                    "Name": "Key West NAS",
                                    "CityId": "EYWA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.8, 24.55"
                                }
                            ]
                        },
                        {
                            "Id": "CGSA",
                            "Name": "College Park",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.449373, 33.653443",
                            "IataCode": "CGS",
                            "Airports": [
                                {
                                    "Id": "CGS",
                                    "Name": "College Park",
                                    "CityId": "CGSA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-76.92247, 38.98067"
                                }
                            ]
                        },
                        {
                            "Id": "JHYA",
                            "Name": "Cambridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.119935, 42.37689",
                            "IataCode": "JHY",
                            "Airports": [
                                {
                                    "Id": "JHY",
                                    "Name": "Cambridge",
                                    "CityId": "JHYA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.102778, 42.366667"
                                }
                            ]
                        },
                        {
                            "Id": "KOAA",
                            "Name": "Kona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.99556, 19.640556",
                            "IataCode": "KOA",
                            "Airports": [
                                {
                                    "Id": "KOA",
                                    "Name": "Kona",
                                    "CityId": "KOAA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.05, 19.733333"
                                }
                            ]
                        },
                        {
                            "Id": "SFYA",
                            "Name": "Springfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.589811, 42.101483",
                            "IataCode": "SFY",
                            "Airports": [
                                {
                                    "Id": "ZSF",
                                    "Name": "Springfield",
                                    "CityId": "SFYA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.6172, 42.1506"
                                }
                            ]
                        },
                        {
                            "Id": "RDUA",
                            "Name": "Durham",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-78.898619, 35.994033",
                            "IataCode": "RDU",
                            "Airports": [
                                {
                                    "Id": "RDU",
                                    "Name": "Raleigh / Durham",
                                    "CityId": "RDUA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-78.781944, 35.875278"
                                }
                            ]
                        },
                        {
                            "Id": "JMHA",
                            "Name": "Schaumburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.083406, 42.033361",
                            "IataCode": "JMH",
                            "Airports": [
                                {
                                    "Id": "JMH",
                                    "Name": "Schaumburg",
                                    "CityId": "JMHA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.083333, 42.033333"
                                }
                            ]
                        },
                        {
                            "Id": "GKTA",
                            "Name": "Gatlinburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-83.510164, 35.714259",
                            "IataCode": "GKT",
                            "Airports": [
                                {
                                    "Id": "GKT",
                                    "Name": "Gatlinburg",
                                    "CityId": "GKTA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-83.518611, 35.851389"
                                }
                            ]
                        },
                        {
                            "Id": "QWPA",
                            "Name": "Winter Park",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.76306, 39.891654",
                            "IataCode": "QWP",
                            "Airports": [
                                {
                                    "Id": "QWP",
                                    "Name": "Winter Park",
                                    "CityId": "QWPA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.75, 39.83333"
                                }
                            ]
                        },
                        {
                            "Id": "JLHA",
                            "Name": "Arlington Heights",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.980626, 42.08836",
                            "IataCode": "JLH",
                            "Airports": [
                                {
                                    "Id": "JLH",
                                    "Name": "Arlington Heights",
                                    "CityId": "JLHA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.983333, 42.083333"
                                }
                            ]
                        },
                        {
                            "Id": "JBKA",
                            "Name": "Berkeley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.27275, 37.871593",
                            "IataCode": "JBK",
                            "Airports": [
                                {
                                    "Id": "JBK",
                                    "Name": "Berkeley",
                                    "CityId": "JBKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.3, 37.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LNYA",
                            "Name": "Lanai City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-156.92222, 20.830556",
                            "IataCode": "LNY",
                            "Airports": [
                                {
                                    "Id": "LNY",
                                    "Name": "Lanai City",
                                    "CityId": "LNYA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.94944, 20.784722"
                                }
                            ]
                        },
                        {
                            "Id": "WKLA",
                            "Name": "Waikoloa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.79028, 19.943056",
                            "IataCode": "WKL",
                            "Airports": [
                                {
                                    "Id": "WKL",
                                    "Name": "Waikoloa",
                                    "CityId": "WKLA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.33333, 19.85"
                                }
                            ]
                        },
                        {
                            "Id": "SRUA",
                            "Name": "Santa Cruz",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.0308, 36.974117",
                            "IataCode": "SRU",
                            "Airports": [
                                {
                                    "Id": "SRU",
                                    "Name": "Santa Cruz",
                                    "CityId": "SRUA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.01667, 36.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SHDA",
                            "Name": "Staunton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-79.071696, 38.149576",
                            "IataCode": "SHD",
                            "Airports": [
                                {
                                    "Id": "SHD",
                                    "Name": "Staunton",
                                    "CityId": "SHDA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-78.896667, 38.263889"
                                }
                            ]
                        },
                        {
                            "Id": "SESA",
                            "Name": "Selma",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-87.021101, 32.407359",
                            "IataCode": "SES",
                            "Airports": [
                                {
                                    "Id": "SES",
                                    "Name": "Selma Selfield",
                                    "CityId": "SESA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.087222, 32.726111"
                                },
                                {
                                    "Id": "SEM",
                                    "Name": "Selma Craig AFB",
                                    "CityId": "SESA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-87.087222, 32.226111"
                                }
                            ]
                        },
                        {
                            "Id": "CMIA",
                            "Name": "Champaign",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.243383, 40.11642",
                            "IataCode": "CMI",
                            "Airports": [
                                {
                                    "Id": "CMI",
                                    "Name": "Champaign",
                                    "CityId": "CMIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.279722, 40.039722"
                                }
                            ]
                        },
                        {
                            "Id": "JBSA",
                            "Name": "Pleasanton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.87468, 37.662431",
                            "IataCode": "JBS",
                            "Airports": [
                                {
                                    "Id": "JBS",
                                    "Name": "Pleasanton",
                                    "CityId": "JBSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.9, 37.699722"
                                }
                            ]
                        },
                        {
                            "Id": "MFEA",
                            "Name": "Mcallen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.230013, 26.203407",
                            "IataCode": "MFE",
                            "Airports": [
                                {
                                    "Id": "MFE",
                                    "Name": "Mcallen",
                                    "CityId": "MFEA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.238611, 26.175833"
                                }
                            ]
                        },
                        {
                            "Id": "QKBA",
                            "Name": "Breckenridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.03835, 39.481654",
                            "IataCode": "QKB",
                            "Airports": [
                                {
                                    "Id": "QKB",
                                    "Name": "Breckenridge",
                                    "CityId": "QKBA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.05, 39.48333"
                                }
                            ]
                        },
                        {
                            "Id": "AUZA",
                            "Name": "Aurora",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.320071, 41.760585",
                            "IataCode": "AUZ",
                            "Airports": [
                                {
                                    "Id": "AUZ",
                                    "Name": "Aurora",
                                    "CityId": "AUZA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.316667, 41.75"
                                }
                            ]
                        },
                        {
                            "Id": "FOPA",
                            "Name": "Forest Park",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.369092, 33.622054",
                            "IataCode": "FOP",
                            "Airports": [
                                {
                                    "Id": "FOP",
                                    "Name": "Forest Park",
                                    "CityId": "FOPA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.366667, 33.616667"
                                }
                            ]
                        },
                        {
                            "Id": "SFZA",
                            "Name": "Smithfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "RHODE",
                            "Location": "-71.549507, 41.922043",
                            "IataCode": "SFZ",
                            "Airports": [
                                {
                                    "Id": "SFZ",
                                    "Name": "Smithfield",
                                    "CityId": "SFZA",
                                    "CountryId": "US",
                                    "RegionId": "RHODE",
                                    "Location": "-71.49117, 41.92093"
                                }
                            ]
                        },
                        {
                            "Id": "SGUA",
                            "Name": "Saint George",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-113.58412, 37.104148",
                            "IataCode": "SGU",
                            "Airports": [
                                {
                                    "Id": "SGU",
                                    "Name": "Saint George",
                                    "CityId": "SGUA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-113.59314, 37.092705"
                                }
                            ]
                        },
                        {
                            "Id": "BROA",
                            "Name": "Brownsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.497484, 25.901747",
                            "IataCode": "BRO",
                            "Airports": [
                                {
                                    "Id": "BRO",
                                    "Name": "Brownsville",
                                    "CityId": "BROA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.423333, 25.908889"
                                }
                            ]
                        },
                        {
                            "Id": "MCKA",
                            "Name": "Mccook",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-100.62571, 40.201948",
                            "IataCode": "MCK",
                            "Airports": [
                                {
                                    "Id": "MCK",
                                    "Name": "Mccook",
                                    "CityId": "MCKA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-100.59288, 40.210081"
                                }
                            ]
                        },
                        {
                            "Id": "STCB",
                            "Name": "St Cloud",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-94.162489, 45.560799",
                            "IataCode": "STC",
                            "Airports": [
                                {
                                    "Id": "STC",
                                    "Name": "St Cloud",
                                    "CityId": "STCB",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-94.060822, 45.546515"
                                }
                            ]
                        },
                        {
                            "Id": "CJNA",
                            "Name": "El Cajon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.96253, 32.794773",
                            "IataCode": "CJN",
                            "Airports": [
                                {
                                    "Id": "CJN",
                                    "Name": "El Cajon",
                                    "CityId": "CJNA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.96667, 32.8"
                                }
                            ]
                        },
                        {
                            "Id": "NGZA",
                            "Name": "Alameda",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.24164, 37.765206",
                            "IataCode": "NGZ",
                            "Airports": [
                                {
                                    "Id": "NGZ",
                                    "Name": "Alameda",
                                    "CityId": "NGZA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.25, 37.766667"
                                }
                            ]
                        },
                        {
                            "Id": "EGEA",
                            "Name": "Vail",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.3742, 39.640264",
                            "IataCode": "EGE",
                            "Airports": [
                                {
                                    "Id": "EGE",
                                    "Name": "Vail Eagle County",
                                    "CityId": "EGEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.91997, 39.642446"
                                },
                                {
                                    "Id": "ZBV",
                                    "Name": "Vail Beaver Creek Van Svce.",
                                    "CityId": "EGEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.9667, 38.3667"
                                },
                                {
                                    "Id": "QBF",
                                    "Name": "Vail Eagle Vail Van Service",
                                    "CityId": "EGEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-104.8833, 39.7667"
                                }
                            ]
                        },
                        {
                            "Id": "SOPA",
                            "Name": "Southern Pines",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.392254, 35.174047",
                            "IataCode": "SOP",
                            "Airports": [
                                {
                                    "Id": "SOP",
                                    "Name": "Southern Pines",
                                    "CityId": "SOPA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.575833, 35.18"
                                }
                            ]
                        },
                        {
                            "Id": "EENA",
                            "Name": "Brattleboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.557868, 42.850915",
                            "IataCode": "EEN",
                            "Airports": [
                                {
                                    "Id": "EEN",
                                    "Name": "Brattleboro",
                                    "CityId": "EENA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.270833, 42.900833"
                                }
                            ]
                        },
                        {
                            "Id": "HTWA",
                            "Name": "Chesapeake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-76.27494, 36.819037",
                            "IataCode": "HTW",
                            "Airports": [
                                {
                                    "Id": "HTW",
                                    "Name": "Chesapeake",
                                    "CityId": "HTWA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-82.49394, 38.42039"
                                }
                            ]
                        },
                        {
                            "Id": "CEFA",
                            "Name": "Chicopee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.59491, 42.172564",
                            "IataCode": "CEF",
                            "Airports": [
                                {
                                    "Id": "CEF",
                                    "Name": "Chicopee",
                                    "CityId": "CEFA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.53871, 42.19903"
                                }
                            ]
                        },
                        {
                            "Id": "CXOA",
                            "Name": "Conroe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.456051, 30.311877",
                            "IataCode": "CXO",
                            "Airports": [
                                {
                                    "Id": "CXO",
                                    "Name": "Conroe",
                                    "CityId": "CXOA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.41466, 30.35188"
                                }
                            ]
                        },
                        {
                            "Id": "DEOA",
                            "Name": "Dearborn",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.176314, 42.32226",
                            "IataCode": "DEO",
                            "Airports": [
                                {
                                    "Id": "DEO",
                                    "Name": "Dearborn",
                                    "CityId": "DEOA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.166667, 42.3"
                                }
                            ]
                        },
                        {
                            "Id": "JEMA",
                            "Name": "Emeryville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.28525, 37.831316",
                            "IataCode": "JEM",
                            "Airports": [
                                {
                                    "Id": "JEM",
                                    "Name": "Emeryville",
                                    "CityId": "JEMA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.3, 37.833333"
                                }
                            ]
                        },
                        {
                            "Id": "FNLA",
                            "Name": "Fort Collins",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.08442, 40.58526",
                            "IataCode": "FNL",
                            "Airports": [
                                {
                                    "Id": "FNL",
                                    "Name": "Fort Collins Loveland Municipal",
                                    "CityId": "FNLA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.01139, 40.451944"
                                },
                                {
                                    "Id": "QWF",
                                    "Name": "Fort Collins Bus Service",
                                    "CityId": "FNLA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.0839, 40.5839"
                                }
                            ]
                        },
                        {
                            "Id": "JGXA",
                            "Name": "Glendale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.25507, 34.142508",
                            "IataCode": "JGX",
                            "Airports": [
                                {
                                    "Id": "JGX",
                                    "Name": "Glendale",
                                    "CityId": "JGXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.28333, 34.166667"
                                }
                            ]
                        },
                        {
                            "Id": "HIOA",
                            "Name": "Hillsboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-122.98983, 45.522894",
                            "IataCode": "HIO",
                            "Airports": [
                                {
                                    "Id": "HIO",
                                    "Name": "Hillsboro",
                                    "CityId": "HIOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-122.94983, 45.54039"
                                }
                            ]
                        },
                        {
                            "Id": "QKSA",
                            "Name": "Keystone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.98724, 39.599431",
                            "IataCode": "QKS",
                            "Airports": [
                                {
                                    "Id": "QKS",
                                    "Name": "Keystone",
                                    "CityId": "QKSA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.11667, 39.5"
                                }
                            ]
                        },
                        {
                            "Id": "TRIA",
                            "Name": "Kingsport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-82.561819, 36.548434",
                            "IataCode": "TRI",
                            "Airports": [
                                {
                                    "Id": "TRI",
                                    "Name": "Tri-Cities",
                                    "CityId": "TRIA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-82.405701, 36.478448"
                                }
                            ]
                        },
                        {
                            "Id": "LJNA",
                            "Name": "Lake Jackson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.434386, 29.033857",
                            "IataCode": "LJN",
                            "Airports": [
                                {
                                    "Id": "LJN",
                                    "Name": "Lake Jackson",
                                    "CityId": "LJNA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.46216, 29.11024"
                                }
                            ]
                        },
                        {
                            "Id": "MXGA",
                            "Name": "Marlborough",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.545458, 42.349093",
                            "IataCode": "MXG",
                            "Airports": [
                                {
                                    "Id": "MXG",
                                    "Name": "Marlborough",
                                    "CityId": "MXGA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.55, 42.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MLCA",
                            "Name": "Mc Alester",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-95.769713, 34.93343",
                            "IataCode": "MLC",
                            "Airports": [
                                {
                                    "Id": "MLC",
                                    "Name": "Mc Alester",
                                    "CityId": "MLCA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-95.785, 34.880278"
                                }
                            ]
                        },
                        {
                            "Id": "MYLA",
                            "Name": "Mccall",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-116.09874, 44.911006",
                            "IataCode": "MYL",
                            "Airports": [
                                {
                                    "Id": "MYL",
                                    "Name": "Mccall",
                                    "CityId": "MYLA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-116.10214, 44.888472"
                                }
                            ]
                        },
                        {
                            "Id": "MPRA",
                            "Name": "Mcpherson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-97.664209, 38.370841",
                            "IataCode": "MPR",
                            "Airports": [
                                {
                                    "Id": "MPR",
                                    "Name": "Mcpherson",
                                    "CityId": "MPRA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.688799, 38.355186"
                                }
                            ]
                        },
                        {
                            "Id": "MTOA",
                            "Name": "Mattoon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.372825, 39.48309",
                            "IataCode": "MTO",
                            "Airports": [
                                {
                                    "Id": "MTO",
                                    "Name": "Mattoon",
                                    "CityId": "MTOA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.278333, 39.478611"
                                }
                            ]
                        },
                        {
                            "Id": "MCBA",
                            "Name": "Mccomb",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.453154, 31.243787",
                            "IataCode": "MCB",
                            "Airports": [
                                {
                                    "Id": "MCB",
                                    "Name": "Mccomb",
                                    "CityId": "MCBA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.469444, 31.175556"
                                }
                            ]
                        },
                        {
                            "Id": "MPSA",
                            "Name": "Mount Pleasant",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-94.968269, 33.156786",
                            "IataCode": "MPS",
                            "Airports": [
                                {
                                    "Id": "MPS",
                                    "Name": "Mount Pleasant",
                                    "CityId": "MPSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-94.983333, 33.166667"
                                }
                            ]
                        },
                        {
                            "Id": "ACKA",
                            "Name": "Nantucket",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.09946, 41.283456",
                            "IataCode": "ACK",
                            "Airports": [
                                {
                                    "Id": "ACK",
                                    "Name": "Nantucket",
                                    "CityId": "ACKA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.059722, 41.256667"
                                }
                            ]
                        },
                        {
                            "Id": "JNPA",
                            "Name": "Newport Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.92895, 33.61891",
                            "IataCode": "JNP",
                            "Airports": [
                                {
                                    "Id": "JNP",
                                    "Name": "Newport Beach",
                                    "CityId": "JNPA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.93333, 33.616667"
                                }
                            ]
                        },
                        {
                            "Id": "OBKA",
                            "Name": "Northbrook",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.828955, 42.127527",
                            "IataCode": "OBK",
                            "Airports": [
                                {
                                    "Id": "OBK",
                                    "Name": "Northbrook",
                                    "CityId": "OBKA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.816667, 42.116667"
                                }
                            ]
                        },
                        {
                            "Id": "OZRA",
                            "Name": "Ozark",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALABA",
                            "Location": "-85.640493, 31.459058",
                            "IataCode": "OZR",
                            "Airports": [
                                {
                                    "Id": "LOR",
                                    "Name": "Ozark Lowe Army Heliport",
                                    "CityId": "OZRA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.433333, 31.5"
                                },
                                {
                                    "Id": "OZR",
                                    "Name": "Ozark Cairns AAF",
                                    "CityId": "OZRA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-85.666667, 31.45"
                                },
                                {
                                    "Id": "HEY",
                                    "Name": "Ozark Hanchey Army Heliport",
                                    "CityId": "OZRA",
                                    "CountryId": "US",
                                    "RegionId": "ALABA",
                                    "Location": "-84.533333, 31.416667"
                                }
                            ]
                        },
                        {
                            "Id": "REHA",
                            "Name": "Rehoboth Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "DELAW",
                            "Location": "-75.076014, 38.720945",
                            "IataCode": "REH",
                            "Airports": [
                                {
                                    "Id": "REH",
                                    "Name": "Rehoboth Beach",
                                    "CityId": "REHA",
                                    "CountryId": "US",
                                    "RegionId": "DELAW",
                                    "Location": "-75.1, 38.7"
                                }
                            ]
                        },
                        {
                            "Id": "SRFA",
                            "Name": "San Rafael",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.53109, 37.973535",
                            "IataCode": "SRF",
                            "Airports": [
                                {
                                    "Id": "SRF",
                                    "Name": "San Rafael Hamilton Field",
                                    "CityId": "SRFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.52222, 38.015278"
                                },
                                {
                                    "Id": "JSG",
                                    "Name": "San Rafael H / P",
                                    "CityId": "SRFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.48725, 37.946668"
                                }
                            ]
                        },
                        {
                            "Id": "MVLA",
                            "Name": "Stowe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.684562, 44.465332",
                            "IataCode": "MVL",
                            "Airports": [
                                {
                                    "Id": "MVL",
                                    "Name": "Morrisville-Stowe",
                                    "CityId": "MVLA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.614, 44.53502"
                                }
                            ]
                        },
                        {
                            "Id": "JTOA",
                            "Name": "Thousand Oaks",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.83759, 34.170561",
                            "IataCode": "JTO",
                            "Airports": [
                                {
                                    "Id": "JTO",
                                    "Name": "Thousand Oaks",
                                    "CityId": "JTOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.83333, 34.166667"
                                }
                            ]
                        },
                        {
                            "Id": "UIZA",
                            "Name": "Utica",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.033538, 42.626145",
                            "IataCode": "UIZ",
                            "Airports": [
                                {
                                    "Id": "UIZ",
                                    "Name": "Utica",
                                    "CityId": "UIZA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.05, 42.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ALWA",
                            "Name": "Walla Walla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-118.34302, 46.064581",
                            "IataCode": "ALW",
                            "Airports": [
                                {
                                    "Id": "ALW",
                                    "Name": "Walla Walla",
                                    "CityId": "ALWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-118.29111, 46.094722"
                                }
                            ]
                        },
                        {
                            "Id": "UGNA",
                            "Name": "Waukegan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.844794, 42.363633",
                            "IataCode": "UGN",
                            "Airports": [
                                {
                                    "Id": "UGN",
                                    "Name": "Waukegan",
                                    "CityId": "UGNA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.86785, 42.42197"
                                }
                            ]
                        },
                        {
                            "Id": "WBNA",
                            "Name": "Woburn",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.152276, 42.479262",
                            "IataCode": "WBN",
                            "Airports": [
                                {
                                    "Id": "WBN",
                                    "Name": "Woburn",
                                    "CityId": "WBNA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.15, 42.466667"
                                }
                            ]
                        },
                        {
                            "Id": "APGA",
                            "Name": "Aberdeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.16412, 39.509556",
                            "IataCode": "APG",
                            "Airports": [
                                {
                                    "Id": "APG",
                                    "Name": "Aberdeen",
                                    "CityId": "APGA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.166667, 39.5"
                                }
                            ]
                        },
                        {
                            "Id": "HQMA",
                            "Name": "Aberdeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.81572, 46.975371",
                            "IataCode": "HQM",
                            "Airports": [
                                {
                                    "Id": "HQM",
                                    "Name": "Aberdeen",
                                    "CityId": "HQMA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.93556, 46.971111"
                                }
                            ]
                        },
                        {
                            "Id": "ALNA",
                            "Name": "Alton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.184276, 38.890604",
                            "IataCode": "ALN",
                            "Airports": [
                                {
                                    "Id": "ALN",
                                    "Name": "Alton",
                                    "CityId": "ALNA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.048333, 38.891667"
                                }
                            ]
                        },
                        {
                            "Id": "ACVA",
                            "Name": "Arcata",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-124.08284, 40.866517",
                            "IataCode": "ACV",
                            "Airports": [
                                {
                                    "Id": "ACV",
                                    "Name": "Arcata",
                                    "CityId": "ACVA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-124.1075, 40.979167"
                                }
                            ]
                        },
                        {
                            "Id": "AHMA",
                            "Name": "Ashland (Oregon)",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-122.70948, 42.194576",
                            "IataCode": "AHM",
                            "Airports": [
                                {
                                    "Id": "AHM",
                                    "Name": "Ashland (Oregon)",
                                    "CityId": "AHMA",
                                    "CountryId": "US",
                                    "Location": "-122.65686, 42.189404"
                                }
                            ]
                        },
                        {
                            "Id": "ATOA",
                            "Name": "Athens",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-82.101255, 39.32924",
                            "IataCode": "ATO",
                            "Airports": [
                                {
                                    "Id": "ATO",
                                    "Name": "Athens",
                                    "CityId": "ATOA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-82.1, 39.3333"
                                }
                            ]
                        },
                        {
                            "Id": "YNGA",
                            "Name": "Youngstown",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-80.649519, 41.09978",
                            "IataCode": "YNG",
                            "Airports": [
                                {
                                    "Id": "YNG",
                                    "Name": "Youngstown",
                                    "CityId": "YNGA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-80.669722, 41.256111"
                                }
                            ]
                        },
                        {
                            "Id": "NIRA",
                            "Name": "Beeville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-97.748331, 28.400832",
                            "IataCode": "NIR",
                            "Airports": [
                                {
                                    "Id": "NIR",
                                    "Name": "Beeville",
                                    "CityId": "NIRA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-97.75, 28.4"
                                }
                            ]
                        },
                        {
                            "Id": "MPVA",
                            "Name": "Montpelier",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VERMO",
                            "Location": "-72.575387, 44.260059",
                            "IataCode": "MPV",
                            "Airports": [
                                {
                                    "Id": "MPV",
                                    "Name": "Montpelier",
                                    "CityId": "MPVA",
                                    "CountryId": "US",
                                    "RegionId": "VERMO",
                                    "Location": "-72.566667, 44.203333"
                                }
                            ]
                        },
                        {
                            "Id": "BPAA",
                            "Name": "Bethpage",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.482069, 40.744266",
                            "IataCode": "BPA",
                            "Airports": [
                                {
                                    "Id": "BPA",
                                    "Name": "Bethpage",
                                    "CityId": "BPAA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.483333, 40.733333"
                                }
                            ]
                        },
                        {
                            "Id": "HCAA",
                            "Name": "Big Spring",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.47874, 32.250398",
                            "IataCode": "HCA",
                            "Airports": [
                                {
                                    "Id": "HCA",
                                    "Name": "Big Spring Howard County",
                                    "CityId": "HCAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.43389, 32.303611"
                                },
                                {
                                    "Id": "BGS",
                                    "Name": "Big Spring Webb AFB",
                                    "CityId": "HCAA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.48333, 32.385556"
                                }
                            ]
                        },
                        {
                            "Id": "BWSA",
                            "Name": "Blaine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.74712, 48.993723",
                            "IataCode": "BWS",
                            "Airports": [
                                {
                                    "Id": "BWS",
                                    "Name": "Blaine",
                                    "CityId": "BWSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.83333, 48.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BSIB",
                            "Name": "Blairsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.260869, 40.43118",
                            "IataCode": "BSI",
                            "Airports": [
                                {
                                    "Id": "BSI",
                                    "Name": "Blairsville",
                                    "CityId": "BSIB",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.283333, 40.45"
                                }
                            ]
                        },
                        {
                            "Id": "BBXA",
                            "Name": "Blue Bell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-75.266289, 40.152331",
                            "IataCode": "BBX",
                            "Airports": [
                                {
                                    "Id": "BBX",
                                    "Name": "Blue Bell",
                                    "CityId": "BBXA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-75.2675, 40.136667"
                                }
                            ]
                        },
                        {
                            "Id": "BJCA",
                            "Name": "Broomfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.08665, 39.920541",
                            "IataCode": "BJC",
                            "Airports": [
                                {
                                    "Id": "BJC",
                                    "Name": "Broomfield",
                                    "CityId": "BJCA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-105.06667, 39.933333"
                                }
                            ]
                        },
                        {
                            "Id": "BBFA",
                            "Name": "Burlington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.195611, 42.504817",
                            "IataCode": "BBF",
                            "Airports": [
                                {
                                    "Id": "BBF",
                                    "Name": "Burlington",
                                    "CityId": "BBFA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.183333, 42.5"
                                }
                            ]
                        },
                        {
                            "Id": "MDHA",
                            "Name": "Carbondale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.21675, 37.727273",
                            "IataCode": "MDH",
                            "Airports": [
                                {
                                    "Id": "MDH",
                                    "Name": "Carbondale",
                                    "CityId": "MDHA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.246944, 37.781389"
                                }
                            ]
                        },
                        {
                            "Id": "MRYA",
                            "Name": "Carmel",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.73245, 36.479684",
                            "IataCode": "MRY",
                            "Airports": [
                                {
                                    "Id": "OAR",
                                    "Name": "Carmel Fritzsche AAF",
                                    "CityId": "MRYA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.76273, 36.68107"
                                },
                                {
                                    "Id": "MRY",
                                    "Name": "Carmel Monterey Peninsula",
                                    "CityId": "MRYA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.84806, 36.588056"
                                }
                            ]
                        },
                        {
                            "Id": "WALA",
                            "Name": "Chincoteague",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-75.378809, 37.933179",
                            "IataCode": "WAL",
                            "Airports": [
                                {
                                    "Id": "WAL",
                                    "Name": "Chincoteague",
                                    "CityId": "WALA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-75.383333, 37.933333"
                                }
                            ]
                        },
                        {
                            "Id": "CKEA",
                            "Name": "Clear Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.62637, 38.958231",
                            "IataCode": "CKE",
                            "Airports": [
                                {
                                    "Id": "CKE",
                                    "Name": "Clear Lake",
                                    "CityId": "CKEA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.62222, 38.934722"
                                }
                            ]
                        },
                        {
                            "Id": "COIA",
                            "Name": "Cocoa",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.741998, 28.386116",
                            "IataCode": "COI",
                            "Airports": [
                                {
                                    "Id": "COI",
                                    "Name": "Cocoa Merritt Island",
                                    "CityId": "COIA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.68505, 28.34167"
                                },
                                {
                                    "Id": "COF",
                                    "Name": "Cocoa Patrick AFB",
                                    "CityId": "COIA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.68505, 28.34167"
                                }
                            ]
                        },
                        {
                            "Id": "MRCA",
                            "Name": "Columbia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-87.035283, 35.615072",
                            "IataCode": "MRC",
                            "Airports": [
                                {
                                    "Id": "MRC",
                                    "Name": "Columbia",
                                    "CityId": "MRCA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-87.033333, 35.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CYEA",
                            "Name": "Crystal Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.316196, 42.241134",
                            "IataCode": "CYE",
                            "Airports": [
                                {
                                    "Id": "CYE",
                                    "Name": "Crystal Lake",
                                    "CityId": "CYEA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.316667, 42.233333"
                                }
                            ]
                        },
                        {
                            "Id": "QCEA",
                            "Name": "Copper Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-106.14224, 39.506653",
                            "IataCode": "QCE",
                            "Airports": [
                                {
                                    "Id": "QCE",
                                    "Name": "Copper Mountain",
                                    "CityId": "QCEA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106.08333, 39.5"
                                }
                            ]
                        },
                        {
                            "Id": "DRIA",
                            "Name": "De Ridder",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "LOUIS",
                            "Location": "-93.289053, 30.846305",
                            "IataCode": "DRI",
                            "Airports": [
                                {
                                    "Id": "DRI",
                                    "Name": "De Ridder",
                                    "CityId": "DRIA",
                                    "CountryId": "US",
                                    "RegionId": "LOUIS",
                                    "Location": "-93.283333, 30.85"
                                }
                            ]
                        },
                        {
                            "Id": "DUJA",
                            "Name": "Dubois",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.76003, 41.119228",
                            "IataCode": "DUJ",
                            "Airports": [
                                {
                                    "Id": "DUJ",
                                    "Name": "Dubois",
                                    "CityId": "DUJA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.898889, 41.178333"
                                }
                            ]
                        },
                        {
                            "Id": "DUFA",
                            "Name": "Duck",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-75.755185, 36.169608",
                            "IataCode": "DUF",
                            "Airports": [
                                {
                                    "Id": "DUF",
                                    "Name": "Duck",
                                    "CityId": "DUFA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-75.788889, 36.258333"
                                }
                            ]
                        },
                        {
                            "Id": "EDGA",
                            "Name": "Edgewood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.294402, 39.418719",
                            "IataCode": "EDG",
                            "Airports": [
                                {
                                    "Id": "EDG",
                                    "Name": "Edgewood",
                                    "CityId": "EDGA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.360917, 39.465667"
                                }
                            ]
                        },
                        {
                            "Id": "ELMA",
                            "Name": "Elmira",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-76.807734, 42.089796",
                            "IataCode": "ELM",
                            "Airports": [
                                {
                                    "Id": "ELM",
                                    "Name": "Elmira",
                                    "CityId": "ELMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-76.895278, 42.160833"
                                }
                            ]
                        },
                        {
                            "Id": "ESOA",
                            "Name": "Espanola",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.0578, 36.002801",
                            "IataCode": "ESO",
                            "Airports": [
                                {
                                    "Id": "ESO",
                                    "Name": "Espanola",
                                    "CityId": "ESOA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-106.03333, 36.1"
                                }
                            ]
                        },
                        {
                            "Id": "FRDA",
                            "Name": "Friday Harbor",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.01712, 48.534266",
                            "IataCode": "FRD",
                            "Airports": [
                                {
                                    "Id": "FBS",
                                    "Name": "Friday Harbor SPB",
                                    "CityId": "FRDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.00611, 48.543611"
                                },
                                {
                                    "Id": "FRD",
                                    "Name": "Friday Harbor",
                                    "CityId": "FRDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.02389, 48.526389"
                                }
                            ]
                        },
                        {
                            "Id": "JHCA",
                            "Name": "Garden City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.634296, 40.726768",
                            "IataCode": "JHC",
                            "Airports": [
                                {
                                    "Id": "JHC",
                                    "Name": "Garden City",
                                    "CityId": "JHCA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-74.1, 40.783333"
                                }
                            ]
                        },
                        {
                            "Id": "GWVA",
                            "Name": "Glendale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-87.935642, 43.135288",
                            "IataCode": "GWV",
                            "Airports": [
                                {
                                    "Id": "GWV",
                                    "Name": "Glendale",
                                    "CityId": "GWVA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-87.95, 43.116667"
                                }
                            ]
                        },
                        {
                            "Id": "GVWA",
                            "Name": "Grandview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.53301, 38.885841",
                            "IataCode": "GVW",
                            "Airports": [
                                {
                                    "Id": "GVW",
                                    "Name": "Grandview",
                                    "CityId": "GVWA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.533333, 38.883333"
                                }
                            ]
                        },
                        {
                            "Id": "GTPA",
                            "Name": "Grants Pass",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-123.32839, 42.439007",
                            "IataCode": "GTP",
                            "Airports": [
                                {
                                    "Id": "GTP",
                                    "Name": "Grants Pass",
                                    "CityId": "GTPA",
                                    "CountryId": "US",
                                    "Location": "-123.388, 42.510111"
                                }
                            ]
                        },
                        {
                            "Id": "HNCA",
                            "Name": "Hatteras",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-75.690161, 35.219342",
                            "IataCode": "HNC",
                            "Airports": [
                                {
                                    "Id": "HNC",
                                    "Name": "Hatteras",
                                    "CityId": "HNCA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-75.7, 35.216667"
                                }
                            ]
                        },
                        {
                            "Id": "LIYA",
                            "Name": "Hinesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.595945, 31.846877",
                            "IataCode": "LIY",
                            "Airports": [
                                {
                                    "Id": "LIY",
                                    "Name": "Hinesville",
                                    "CityId": "LIYA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.6, 31.85"
                                }
                            ]
                        },
                        {
                            "Id": "HOPA",
                            "Name": "Hopkinsville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-87.488619, 36.865601",
                            "IataCode": "HOP",
                            "Airports": [
                                {
                                    "Id": "HOP",
                                    "Name": "Hopkinsville",
                                    "CityId": "HOPA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-87.495461, 36.673238"
                                }
                            ]
                        },
                        {
                            "Id": "IMTA",
                            "Name": "Iron Mountain",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-88.06596, 45.820233",
                            "IataCode": "IMT",
                            "Airports": [
                                {
                                    "Id": "IMT",
                                    "Name": "Iron Mountain",
                                    "CityId": "IMTA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-88.114167, 45.815556"
                                }
                            ]
                        },
                        {
                            "Id": "JHMA",
                            "Name": "Kapalua",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-156.65806, 20.999722",
                            "IataCode": "JHM",
                            "Airports": [
                                {
                                    "Id": "JHM",
                                    "Name": "Kapalua",
                                    "CityId": "JHMA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-156.67667, 20.964722"
                                }
                            ]
                        },
                        {
                            "Id": "LPOA",
                            "Name": "Laporte",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.722523, 41.610598",
                            "IataCode": "LPO",
                            "Airports": [
                                {
                                    "Id": "LPO",
                                    "Name": "Laporte",
                                    "CityId": "LPOA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.73391, 41.57254"
                                }
                            ]
                        },
                        {
                            "Id": "LZUA",
                            "Name": "Lawrenceville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-83.987962, 33.956215",
                            "IataCode": "LZU",
                            "Airports": [
                                {
                                    "Id": "LZU",
                                    "Name": "Lawrenceville",
                                    "CityId": "LZUA",
                                    "CountryId": "US",
                                    "Location": "-83.962343, 33.978011"
                                }
                            ]
                        },
                        {
                            "Id": "LEWA",
                            "Name": "Lewiston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MAINE",
                            "Location": "-70.214776, 44.100351",
                            "IataCode": "LEW",
                            "Airports": [
                                {
                                    "Id": "LEW",
                                    "Name": "Auburn-Lewiston Municipal",
                                    "CityId": "LEWA",
                                    "CountryId": "US",
                                    "RegionId": "MAINE",
                                    "Location": "-70.28763, 44.04823"
                                }
                            ]
                        },
                        {
                            "Id": "LSNA",
                            "Name": "Los Banos",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.84992, 37.058279",
                            "IataCode": "LSN",
                            "Airports": [
                                {
                                    "Id": "LSN",
                                    "Name": "Los Banos",
                                    "CityId": "LSNA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.86836, 37.061718"
                                }
                            ]
                        },
                        {
                            "Id": "LOGA",
                            "Name": "Longview",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.93817, 46.138168",
                            "IataCode": "LOG",
                            "Airports": [
                                {
                                    "Id": "LOG",
                                    "Name": "Longview",
                                    "CityId": "LOGA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.88333, 46.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MTHA",
                            "Name": "Marathon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.090351, 24.713752",
                            "IataCode": "MTH",
                            "Airports": [
                                {
                                    "Id": "MTH",
                                    "Name": "Marathon",
                                    "CityId": "MTHA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.051389, 24.719444"
                                }
                            ]
                        },
                        {
                            "Id": "RNCA",
                            "Name": "Mcminnville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TENNE",
                            "Location": "-85.769982, 35.683404",
                            "IataCode": "RNC",
                            "Airports": [
                                {
                                    "Id": "RNC",
                                    "Name": "Mcminnville",
                                    "CityId": "RNCA",
                                    "CountryId": "US",
                                    "RegionId": "TENNE",
                                    "Location": "-85.938167, 35.837083"
                                }
                            ]
                        },
                        {
                            "Id": "MFHA",
                            "Name": "Mesquite",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-114.06719, 36.805531",
                            "IataCode": "MFH",
                            "Airports": [
                                {
                                    "Id": "MFH",
                                    "Name": "Mesquite",
                                    "CityId": "MFHA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-114.05861, 36.836111"
                                }
                            ]
                        },
                        {
                            "Id": "MBSA",
                            "Name": "Midland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.247212, 43.615582",
                            "IataCode": "MBS",
                            "Airports": [
                                {
                                    "Id": "MBS",
                                    "Name": "Midland",
                                    "CityId": "MBSA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.083333, 43.533333"
                                }
                            ]
                        },
                        {
                            "Id": "ARVA",
                            "Name": "Minocqua",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WISCO",
                            "Location": "-89.710981, 45.871342",
                            "IataCode": "ARV",
                            "Airports": [
                                {
                                    "Id": "ARV",
                                    "Name": "Minocqua",
                                    "CityId": "ARVA",
                                    "CountryId": "US",
                                    "RegionId": "WISCO",
                                    "Location": "-89.732222, 45.924167"
                                }
                            ]
                        },
                        {
                            "Id": "MVWA",
                            "Name": "Mount Vernon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.33405, 48.421216",
                            "IataCode": "MVW",
                            "Airports": [
                                {
                                    "Id": "MVW",
                                    "Name": "Mount Vernon",
                                    "CityId": "MVWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.33333, 48.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MTCA",
                            "Name": "Mt Clemens",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-82.877975, 42.597256",
                            "IataCode": "MTC",
                            "Airports": [
                                {
                                    "Id": "NFB",
                                    "Name": "Mt Clemens Detroit NAF",
                                    "CityId": "MTCA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-82.966667, 42.966667"
                                },
                                {
                                    "Id": "MTC",
                                    "Name": "Mt Clemens Selfridge ANGB",
                                    "CityId": "MTCA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-82.883333, 42.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MHSA",
                            "Name": "Mount Shasta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.31057, 41.309875",
                            "IataCode": "MHS",
                            "Airports": [
                                {
                                    "Id": "MHS",
                                    "Name": "Mount Shasta",
                                    "CityId": "MHSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.31667, 41.316667"
                                }
                            ]
                        },
                        {
                            "Id": "ORQA",
                            "Name": "Norwalk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CONNE",
                            "Location": "-73.407897, 41.117597",
                            "IataCode": "ORQ",
                            "Airports": [
                                {
                                    "Id": "ORQ",
                                    "Name": "Norwalk",
                                    "CityId": "ORQA",
                                    "CountryId": "US",
                                    "RegionId": "CONNE",
                                    "Location": "-73.366667, 41.116667"
                                }
                            ]
                        },
                        {
                            "Id": "OILA",
                            "Name": "Oil City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-79.706441, 41.433948",
                            "IataCode": "OIL",
                            "Airports": [
                                {
                                    "Id": "OIL",
                                    "Name": "Oil City",
                                    "CityId": "OILA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-79.7, 41.433333"
                                }
                            ]
                        },
                        {
                            "Id": "OLYA",
                            "Name": "Olney",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.085315, 38.730881",
                            "IataCode": "OLY",
                            "Airports": [
                                {
                                    "Id": "OLY",
                                    "Name": "Olney-Noble",
                                    "CityId": "OLYA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.083333, 38.716667"
                                }
                            ]
                        },
                        {
                            "Id": "PVZA",
                            "Name": "Painesville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-81.245657, 41.724488",
                            "IataCode": "PVZ",
                            "Airports": [
                                {
                                    "Id": "PVZ",
                                    "Name": "Painesville",
                                    "CityId": "PVZA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-81.25, 41.716667"
                                }
                            ]
                        },
                        {
                            "Id": "JPDA",
                            "Name": "Pasadena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.14452, 34.147786",
                            "IataCode": "JPD",
                            "Airports": [
                                {
                                    "Id": "JPD",
                                    "Name": "Pasadena",
                                    "CityId": "JPDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.15, 34.15"
                                }
                            ]
                        },
                        {
                            "Id": "PCUA",
                            "Name": "Picayune",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-89.679508, 30.525469",
                            "IataCode": "PCU",
                            "Airports": [
                                {
                                    "Id": "PCU",
                                    "Name": "Picayune",
                                    "CityId": "PCUA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-89.655476, 30.490693"
                                }
                            ]
                        },
                        {
                            "Id": "NTDA",
                            "Name": "Port Hueneme",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.19511, 34.147783",
                            "IataCode": "NTD",
                            "Airports": [
                                {
                                    "Id": "NTD",
                                    "Name": "Port Hueneme",
                                    "CityId": "NTDA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.2, 34.15"
                                }
                            ]
                        },
                        {
                            "Id": "PUWA",
                            "Name": "Pullman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-117.17962, 46.731274",
                            "IataCode": "PUW",
                            "Airports": [
                                {
                                    "Id": "PUW",
                                    "Name": "Pullman",
                                    "CityId": "PUWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-117.105, 46.744722"
                                }
                            ]
                        },
                        {
                            "Id": "RTNA",
                            "Name": "Raton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-104.43915, 36.903358",
                            "IataCode": "RTN",
                            "Airports": [
                                {
                                    "Id": "RTN",
                                    "Name": "Raton",
                                    "CityId": "RTNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-104.50111, 36.741389"
                                }
                            ]
                        },
                        {
                            "Id": "ROLA",
                            "Name": "Roosevelt",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-109.98876, 40.299403",
                            "IataCode": "ROL",
                            "Airports": [
                                {
                                    "Id": "ROL",
                                    "Name": "Roosevelt",
                                    "CityId": "ROLA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-109.98333, 40.3"
                                }
                            ]
                        },
                        {
                            "Id": "STQA",
                            "Name": "St Marys",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.560856, 41.427839",
                            "IataCode": "STQ",
                            "Airports": [
                                {
                                    "Id": "STQ",
                                    "Name": "St Marys",
                                    "CityId": "STQA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.566667, 41.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SUCA",
                            "Name": "Sundance",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.37578, 44.406375",
                            "IataCode": "SUC",
                            "Airports": [
                                {
                                    "Id": "SUC",
                                    "Name": "Sundance",
                                    "CityId": "SUCA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.38333, 44.4"
                                }
                            ]
                        },
                        {
                            "Id": "TRTA",
                            "Name": "Tremonton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.16551, 41.711873",
                            "IataCode": "TRT",
                            "Airports": [
                                {
                                    "Id": "TRT",
                                    "Name": "Tremonton",
                                    "CityId": "TRTA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.16667, 41.716667"
                                }
                            ]
                        },
                        {
                            "Id": "TTDA",
                            "Name": "Troutdale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-122.38731, 45.539286",
                            "IataCode": "TTD",
                            "Airports": [
                                {
                                    "Id": "TTD",
                                    "Name": "Troutdale",
                                    "CityId": "TTDA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-122.39815, 45.54929"
                                }
                            ]
                        },
                        {
                            "Id": "VLOA",
                            "Name": "Vallejo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.25664, 38.104086",
                            "IataCode": "VLO",
                            "Airports": [
                                {
                                    "Id": "VLO",
                                    "Name": "Vallejo",
                                    "CityId": "VLOA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.23333, 38.116667"
                                }
                            ]
                        },
                        {
                            "Id": "SZLA",
                            "Name": "Warrensburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-93.73605, 38.762789",
                            "IataCode": "SZL",
                            "Airports": [
                                {
                                    "Id": "SZL",
                                    "Name": "Warrensburg",
                                    "CityId": "SZLA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-93.54786, 38.73031"
                                }
                            ]
                        },
                        {
                            "Id": "EANA",
                            "Name": "Wheatland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.95275, 42.054414",
                            "IataCode": "EAN",
                            "Airports": [
                                {
                                    "Id": "EAN",
                                    "Name": "Wheatland",
                                    "CityId": "EANA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.96667, 42.05"
                                }
                            ]
                        },
                        {
                            "Id": "ILLA",
                            "Name": "Willmar",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-95.043342, 45.121907",
                            "IataCode": "ILL",
                            "Airports": [
                                {
                                    "Id": "ILL",
                                    "Name": "Willmar",
                                    "CityId": "ILLA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-95.085278, 45.115278"
                                }
                            ]
                        },
                        {
                            "Id": "BAFA",
                            "Name": "Westfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-72.749538, 42.125093",
                            "IataCode": "BAF",
                            "Airports": [
                                {
                                    "Id": "BAF",
                                    "Name": "Westfield",
                                    "CityId": "BAFA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-72.75, 42.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SSUA",
                            "Name": "White Sulphur Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WEST_",
                            "Location": "-80.29757, 37.796511",
                            "IataCode": "SSU",
                            "Airports": [
                                {
                                    "Id": "SSU",
                                    "Name": "White Sulphur Springs",
                                    "CityId": "SSUA",
                                    "CountryId": "US",
                                    "RegionId": "WEST_",
                                    "Location": "-80.3, 37.8"
                                }
                            ]
                        },
                        {
                            "Id": "IKBA",
                            "Name": "Wilkesboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-81.16064, 36.145965",
                            "IataCode": "IKB",
                            "Airports": [
                                {
                                    "Id": "IKB",
                                    "Name": "Wilkesboro",
                                    "CityId": "IKBA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-81.191486, 36.150126"
                                }
                            ]
                        },
                        {
                            "Id": "WLDA",
                            "Name": "Winfield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-96.995592, 37.239749",
                            "IataCode": "WLD",
                            "Airports": [
                                {
                                    "Id": "WLD",
                                    "Name": "Winfield",
                                    "CityId": "WLDA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-97.050278, 37.166944"
                                }
                            ]
                        },
                        {
                            "Id": "RKCA",
                            "Name": "Yreka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.63447, 41.735419",
                            "IataCode": "RKC",
                            "Airports": [
                                {
                                    "Id": "RKC",
                                    "Name": "Yreka",
                                    "CityId": "RKCA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.54669, 41.73014"
                                }
                            ]
                        },
                        {
                            "Id": "EWBA",
                            "Name": "Fall River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.155045, 41.701491",
                            "IataCode": "EWB",
                            "Airports": [
                                {
                                    "Id": "EWB",
                                    "Name": "Fall River",
                                    "CityId": "EWBA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.959167, 41.676944"
                                }
                            ]
                        },
                        {
                            "Id": "AQYA",
                            "Name": "Alyeska",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.11083, 60.960833",
                            "IataCode": "AQY",
                            "Airports": [
                                {
                                    "Id": "AQY",
                                    "Name": "Alyeska",
                                    "CityId": "AQYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.11667, 60.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BRGA",
                            "Name": "Whitesburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KENTU",
                            "Location": "-82.826826, 37.118432",
                            "IataCode": "BRG",
                            "Airports": [
                                {
                                    "Id": "BRG",
                                    "Name": "Whitesburg",
                                    "CityId": "BRGA",
                                    "CountryId": "US",
                                    "RegionId": "KENTU",
                                    "Location": "-82.833333, 37.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MQWA",
                            "Name": "Mc Rae",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-82.900699, 32.067954",
                            "IataCode": "MQW",
                            "Airports": [
                                {
                                    "Id": "MQW",
                                    "Name": "Mc Rae",
                                    "CityId": "MQWA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-83.000278, 32.207778"
                                }
                            ]
                        },
                        {
                            "Id": "OBTA",
                            "Name": "Oakland",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-79.406712, 39.407875",
                            "IataCode": "OBT",
                            "Airports": [
                                {
                                    "Id": "OBT",
                                    "Name": "Oakland / Coliseum Stat",
                                    "CityId": "OBTA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-79.416667, 39.4"
                                }
                            ]
                        },
                        {
                            "Id": "SJNA",
                            "Name": "St Johns",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.36093, 34.50587",
                            "IataCode": "SJN",
                            "Airports": [
                                {
                                    "Id": "SJN",
                                    "Name": "St Johns",
                                    "CityId": "SJNA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.35, 34.5"
                                }
                            ]
                        },
                        {
                            "Id": "ARXA",
                            "Name": "Asbury Park",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.012082, 40.220391",
                            "Airports": []
                        },
                        {
                            "Id": "ISPA",
                            "Name": "Islip",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.210393, 40.729821",
                            "IataCode": "ISP",
                            "Airports": [
                                {
                                    "Id": "ISP",
                                    "Name": "Islip",
                                    "CityId": "ISPA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.100278, 40.795278"
                                }
                            ]
                        },
                        {
                            "Id": "ESDA",
                            "Name": "Eastsound",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.90546, 48.696771",
                            "IataCode": "ESD",
                            "Airports": [
                                {
                                    "Id": "ESD",
                                    "Name": "Eastsound",
                                    "CityId": "ESDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.9125, 48.708333"
                                },
                                {
                                    "Id": "DHB",
                                    "Name": "Deer Harbor Seaplane",
                                    "CityId": "ESDA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.00786, 48.618343"
                                }
                            ]
                        },
                        {
                            "Id": "WSXA",
                            "Name": "Westsound",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.95629, 48.631214",
                            "IataCode": "WSX",
                            "Airports": [
                                {
                                    "Id": "WSX",
                                    "Name": "Westsound",
                                    "CityId": "WSXA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.95289, 48.617364"
                                }
                            ]
                        },
                        {
                            "Id": "APHA",
                            "Name": "Bowling Green",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.346647, 38.049579",
                            "IataCode": "APH",
                            "Airports": [
                                {
                                    "Id": "APH",
                                    "Name": "Bowling Green",
                                    "CityId": "APHA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-91.218817, 39.366952"
                                }
                            ]
                        },
                        {
                            "Id": "GRMA",
                            "Name": "Grand Marais",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-85.985165, 46.670813",
                            "IataCode": "GRM",
                            "Airports": [
                                {
                                    "Id": "GRM",
                                    "Name": "Grand Marais",
                                    "CityId": "GRMA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-85.983333, 46.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SYNA",
                            "Name": "Stanton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.78846, 32.129289",
                            "IataCode": "SYN",
                            "Airports": [
                                {
                                    "Id": "SYN",
                                    "Name": "Stanton",
                                    "CityId": "SYNA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-93.01632, 44.47524"
                                }
                            ]
                        },
                        {
                            "Id": "SYLA",
                            "Name": "San Miguel",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.69628, 35.752468",
                            "IataCode": "SYL",
                            "Airports": [
                                {
                                    "Id": "SYL",
                                    "Name": "San Miguel",
                                    "CityId": "SYLA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.7, 35.75"
                                }
                            ]
                        },
                        {
                            "Id": "BLMA",
                            "Name": "Belmar",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.021804, 40.178447",
                            "IataCode": "BLM",
                            "Airports": [
                                {
                                    "Id": "BLM",
                                    "Name": "Belmar",
                                    "CityId": "BLMA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.016667, 40.183333"
                                }
                            ]
                        },
                        {
                            "Id": "OSBA",
                            "Name": "Osage Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-95.727717, 38.653281",
                            "IataCode": "OSB",
                            "Airports": [
                                {
                                    "Id": "OSB",
                                    "Name": "Osage Beach",
                                    "CityId": "OSBA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-95.80224, 38.635277"
                                }
                            ]
                        },
                        {
                            "Id": "XSMA",
                            "Name": "St Marys",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.592336, 38.282643",
                            "IataCode": "XSM",
                            "Airports": [
                                {
                                    "Id": "XSM",
                                    "Name": "St Marys",
                                    "CityId": "XSMA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.549988, 38.314454"
                                }
                            ]
                        },
                        {
                            "Id": "CWIA",
                            "Name": "Clinton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IOWA_",
                            "Location": "-90.533463, 41.898286",
                            "IataCode": "CWI",
                            "Airports": [
                                {
                                    "Id": "CWI",
                                    "Name": "Clinton",
                                    "CityId": "CWIA",
                                    "CountryId": "US",
                                    "RegionId": "IOWA_",
                                    "Location": "-90.331944, 41.829444"
                                }
                            ]
                        },
                        {
                            "Id": "PYMA",
                            "Name": "Plymouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.800069, 41.95112",
                            "IataCode": "PYM",
                            "Airports": [
                                {
                                    "Id": "PYM",
                                    "Name": "Plymouth",
                                    "CityId": "PYMA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.683333, 41.966667"
                                }
                            ]
                        },
                        {
                            "Id": "HPNA",
                            "Name": "Westchester County",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.756518, 41.159588",
                            "IataCode": "HPN",
                            "Airports": [
                                {
                                    "Id": "HPN",
                                    "Name": "Westchester County",
                                    "CityId": "HPNA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.707778, 41.068889"
                                }
                            ]
                        },
                        {
                            "Id": "BKEA",
                            "Name": "Baker",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-117.67346, 44.709897",
                            "IataCode": "BKE",
                            "Airports": [
                                {
                                    "Id": "BKE",
                                    "Name": "Baker",
                                    "CityId": "BKEA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-117.81, 44.838333"
                                }
                            ]
                        },
                        {
                            "Id": "LRDA",
                            "Name": "Laredo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.5, 27.533333",
                            "IataCode": "LRD",
                            "Airports": [
                                {
                                    "Id": "LRD",
                                    "Name": "Laredo",
                                    "CityId": "LRDA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.461111, 27.544722"
                                }
                            ]
                        },
                        {
                            "Id": "AHTA",
                            "Name": "Amchitka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "178.8775, 51.567222",
                            "IataCode": "AHT",
                            "Airports": [
                                {
                                    "Id": "AHT",
                                    "Name": "Amchitka",
                                    "CityId": "AHTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "179, 51.5"
                                }
                            ]
                        },
                        {
                            "Id": "HEEA",
                            "Name": "Helena",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-90.591772, 34.529547",
                            "IataCode": "HEE",
                            "Airports": [
                                {
                                    "Id": "HEE",
                                    "Name": "Helena",
                                    "CityId": "HEEA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-90.67566, 34.57649"
                                }
                            ]
                        },
                        {
                            "Id": "NDVA",
                            "Name": "Anacostia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "DISTR",
                            "Location": "-76.983864, 38.866501",
                            "IataCode": "NDV",
                            "Airports": [
                                {
                                    "Id": "NDV",
                                    "Name": "Anacostia",
                                    "CityId": "NDVA",
                                    "CountryId": "US",
                                    "RegionId": "DISTR",
                                    "Location": "-77, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "RBNA",
                            "Name": "Fort Jefferson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.872898, 24.62793",
                            "IataCode": "RBN",
                            "Airports": [
                                {
                                    "Id": "RBN",
                                    "Name": "Fort Jefferson",
                                    "CityId": "RBNA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.866667, 24.616667"
                                }
                            ]
                        },
                        {
                            "Id": "NENA",
                            "Name": "Whitehouse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-81.846215, 30.31635",
                            "IataCode": "NEN",
                            "Airports": [
                                {
                                    "Id": "NEN",
                                    "Name": "Whitehouse",
                                    "CityId": "NENA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-81.85, 30.316667"
                                }
                            ]
                        },
                        {
                            "Id": "NEAA",
                            "Name": "Glynco",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-81.510102, 31.222451",
                            "IataCode": "NEA",
                            "Airports": [
                                {
                                    "Id": "NEA",
                                    "Name": "Glynco",
                                    "CityId": "NEAA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-81.466667, 31.25"
                                }
                            ]
                        },
                        {
                            "Id": "DGNA",
                            "Name": "Dahlgren",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.684502, 38.198104",
                            "IataCode": "DGN",
                            "Airports": [
                                {
                                    "Id": "DGN",
                                    "Name": "Dahlgren",
                                    "CityId": "DGNA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.683333, 38.2"
                                }
                            ]
                        },
                        {
                            "Id": "WODA",
                            "Name": "Wood River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-90.097607, 38.861159",
                            "IataCode": "WOD",
                            "Airports": [
                                {
                                    "Id": "WOD",
                                    "Name": "Wood River",
                                    "CityId": "WODA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-90.083333, 38.866667"
                                }
                            ]
                        },
                        {
                            "Id": "LROA",
                            "Name": "Lathrop",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.329946, 39.548335",
                            "IataCode": "LRO",
                            "Airports": [
                                {
                                    "Id": "LRO",
                                    "Name": "Lathrop",
                                    "CityId": "LROA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.333333, 39.55"
                                }
                            ]
                        },
                        {
                            "Id": "VIHA",
                            "Name": "Vichy",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-91.760439, 38.111432",
                            "IataCode": "VIH",
                            "Airports": [
                                {
                                    "Id": "VIH",
                                    "Name": "Vichy",
                                    "CityId": "VIHA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-91.766667, 38.133333"
                                }
                            ]
                        },
                        {
                            "Id": "RFKA",
                            "Name": "Anguilla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSI",
                            "Location": "-90.824544, 32.974015",
                            "IataCode": "RFK",
                            "Airports": [
                                {
                                    "Id": "RFK",
                                    "Name": "Anguilla",
                                    "CityId": "RFKA",
                                    "CountryId": "US",
                                    "RegionId": "MISSI",
                                    "Location": "-90.816667, 32.966667"
                                }
                            ]
                        },
                        {
                            "Id": "HFFA",
                            "Name": "Hoffman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.547544, 35.032378",
                            "IataCode": "HFF",
                            "Airports": [
                                {
                                    "Id": "HFF",
                                    "Name": "Hoffman",
                                    "CityId": "HFFA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.566667, 35.033333"
                                }
                            ]
                        },
                        {
                            "Id": "USAA",
                            "Name": "Concord",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-80.620623, 35.434307",
                            "IataCode": "USA",
                            "Airports": [
                                {
                                    "Id": "USA",
                                    "Name": "Concord Regional",
                                    "CityId": "USAA",
                                    "CountryId": "US",
                                    "Location": "-80.709167, 35.387778"
                                }
                            ]
                        },
                        {
                            "Id": "HHHA",
                            "Name": "Hilton Head",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "S_CAR",
                            "Location": "-80.713441, 32.255758",
                            "IataCode": "HHH",
                            "Airports": [
                                {
                                    "Id": "HHH",
                                    "Name": "Hilton Head",
                                    "CityId": "HHHA",
                                    "CountryId": "US",
                                    "RegionId": "S_CAR",
                                    "Location": "-80.683333, 32.216667"
                                }
                            ]
                        },
                        {
                            "Id": "BZTA",
                            "Name": "Brazoria",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.569113, 29.044415",
                            "IataCode": "BZT",
                            "Airports": [
                                {
                                    "Id": "BZT",
                                    "Name": "Brazoria",
                                    "CityId": "BZTA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.566667, 29.05"
                                }
                            ]
                        },
                        {
                            "Id": "CTOA",
                            "Name": "Calverton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-77.670546, 38.632065",
                            "IataCode": "CTO",
                            "Airports": [
                                {
                                    "Id": "CTO",
                                    "Name": "Calverton",
                                    "CityId": "CTOA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.683333, 38.633333"
                                }
                            ]
                        },
                        {
                            "Id": "LOTA",
                            "Name": "Lockport",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.057837, 41.589475",
                            "IataCode": "LOT",
                            "Airports": [
                                {
                                    "Id": "LOT",
                                    "Name": "Lewis Lockport",
                                    "CityId": "LOTA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.05, 41.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BXCA",
                            "Name": "Boxborough",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.527287, 42.491759",
                            "IataCode": "BXC",
                            "Airports": [
                                {
                                    "Id": "BXC",
                                    "Name": "Boxborough",
                                    "CityId": "BXCA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.533333, 42.483333"
                                }
                            ]
                        },
                        {
                            "Id": "NZWA",
                            "Name": "South Weymouth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.94949, 42.1751",
                            "IataCode": "NZW",
                            "Airports": [
                                {
                                    "Id": "NZW",
                                    "Name": "South Weymouth",
                                    "CityId": "NZWA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.933333, 42.166667"
                                }
                            ]
                        },
                        {
                            "Id": "HBOA",
                            "Name": "Humboldt",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEBRA",
                            "Location": "-95.944998, 40.163889",
                            "IataCode": "HBO",
                            "Airports": [
                                {
                                    "Id": "HBO",
                                    "Name": "Humboldt",
                                    "CityId": "HBOA",
                                    "CountryId": "US",
                                    "RegionId": "NEBRA",
                                    "Location": "-95.95, 40.166667"
                                }
                            ]
                        },
                        {
                            "Id": "WRIA",
                            "Name": "Fort Dix",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_J",
                            "Location": "-74.61849, 40.029835",
                            "IataCode": "WRI",
                            "Airports": [
                                {
                                    "Id": "WRI",
                                    "Name": "Fort Dix",
                                    "CityId": "WRIA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_J",
                                    "Location": "-74.650167, 40.155306"
                                }
                            ]
                        },
                        {
                            "Id": "AYZA",
                            "Name": "Amityville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.417067, 40.678989",
                            "IataCode": "AYZ",
                            "Airports": [
                                {
                                    "Id": "AYZ",
                                    "Name": "Amityville",
                                    "CityId": "AYZA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.416667, 40.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DPKA",
                            "Name": "Deer Park",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-73.329286, 40.761765",
                            "IataCode": "DPK",
                            "Airports": [
                                {
                                    "Id": "DPK",
                                    "Name": "Deer Park",
                                    "CityId": "DPKA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-73.533333, 41"
                                }
                            ]
                        },
                        {
                            "Id": "LIZA",
                            "Name": "Limestone",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-78.629472, 42.027286",
                            "IataCode": "LIZ",
                            "Airports": [
                                {
                                    "Id": "LIZ",
                                    "Name": "Limestone",
                                    "CityId": "LIZA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-78.633333, 42.033333"
                                }
                            ]
                        },
                        {
                            "Id": "FOKA",
                            "Name": "Westhampton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_Y",
                            "Location": "-72.666208, 40.824544",
                            "IataCode": "FOK",
                            "Airports": [
                                {
                                    "Id": "FOK",
                                    "Name": "Westhampton",
                                    "CityId": "FOKA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_Y",
                                    "Location": "-72.641773, 40.838619"
                                }
                            ]
                        },
                        {
                            "Id": "JFNA",
                            "Name": "Jefferson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OHIO_",
                            "Location": "-80.769807, 41.738666",
                            "IataCode": "JFN",
                            "Airports": [
                                {
                                    "Id": "JFN",
                                    "Name": "Jefferson",
                                    "CityId": "JFNA",
                                    "CountryId": "US",
                                    "RegionId": "OHIO_",
                                    "Location": "-80.69536, 41.77783"
                                }
                            ]
                        },
                        {
                            "Id": "AOOA",
                            "Name": "Martinsburg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-78.32418, 40.311187",
                            "IataCode": "AOO",
                            "Airports": [
                                {
                                    "Id": "AOO",
                                    "Name": "Martinsburg",
                                    "CityId": "AOOA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-78.32, 40.297222"
                                }
                            ]
                        },
                        {
                            "Id": "MUUA",
                            "Name": "Mount Union",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.88222, 40.384522",
                            "IataCode": "MUU",
                            "Airports": [
                                {
                                    "Id": "MUU",
                                    "Name": "Mount Union",
                                    "CityId": "MUUA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.883333, 40.383333"
                                }
                            ]
                        },
                        {
                            "Id": "VEXA",
                            "Name": "Tioga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.133027, 41.908685",
                            "IataCode": "VEX",
                            "Airports": [
                                {
                                    "Id": "VEX",
                                    "Name": "Tioga",
                                    "CityId": "VEXA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.133333, 41.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CFTA",
                            "Name": "Clifton",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.29618, 33.050897",
                            "IataCode": "CFT",
                            "Airports": [
                                {
                                    "Id": "CFT",
                                    "Name": "Clifton",
                                    "CityId": "CFTA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.21111, 32.956944"
                                }
                            ]
                        },
                        {
                            "Id": "MYHA",
                            "Name": "Marble Canyon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-111.63766, 36.815543",
                            "IataCode": "MYH",
                            "Airports": [
                                {
                                    "Id": "MYH",
                                    "Name": "Marble Canyon",
                                    "CityId": "MYHA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-111.64583, 36.8125"
                                }
                            ]
                        },
                        {
                            "Id": "BLUA",
                            "Name": "Blue Canyon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-120.71105, 39.257122",
                            "IataCode": "BLU",
                            "Airports": [
                                {
                                    "Id": "BLU",
                                    "Name": "Blue Canyon",
                                    "CityId": "BLUA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.71667, 39.25"
                                }
                            ]
                        },
                        {
                            "Id": "BYSA",
                            "Name": "Fort Irwin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.68475, 35.262753",
                            "IataCode": "BYS",
                            "Airports": [
                                {
                                    "Id": "BYS",
                                    "Name": "Fort Irwin",
                                    "CityId": "BYSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.56667, 35.266667"
                                }
                            ]
                        },
                        {
                            "Id": "HGTA",
                            "Name": "Jolon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-121.17603, 35.9708",
                            "IataCode": "HGT",
                            "Airports": [
                                {
                                    "Id": "HGT",
                                    "Name": "Jolon",
                                    "CityId": "HGTA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-121.15, 35.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MWSA",
                            "Name": "Mount Wilson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.06618, 34.226393",
                            "IataCode": "MWS",
                            "Airports": [
                                {
                                    "Id": "MWS",
                                    "Name": "Mount Wilson",
                                    "CityId": "MWSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.06667, 34.216667"
                                }
                            ]
                        },
                        {
                            "Id": "TRMA",
                            "Name": "Thermal",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.13945, 33.640301",
                            "IataCode": "TRM",
                            "Airports": [
                                {
                                    "Id": "TRM",
                                    "Name": "Thermal",
                                    "CityId": "TRMA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.16056, 33.6278"
                                }
                            ]
                        },
                        {
                            "Id": "CZZA",
                            "Name": "Campo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-102.57964, 37.105019",
                            "IataCode": "CZZ",
                            "Airports": [
                                {
                                    "Id": "CZZ",
                                    "Name": "Campo",
                                    "CityId": "CZZA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-102.58333, 37.1"
                                }
                            ]
                        },
                        {
                            "Id": "OTOA",
                            "Name": "Otto",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEW_M",
                            "Location": "-106.00891, 35.073104",
                            "IataCode": "OTO",
                            "Airports": [
                                {
                                    "Id": "OTO",
                                    "Name": "Otto",
                                    "CityId": "OTOA",
                                    "CountryId": "US",
                                    "RegionId": "NEW_M",
                                    "Location": "-105.93333, 35.066667"
                                }
                            ]
                        },
                        {
                            "Id": "INSA",
                            "Name": "Indian Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.67058, 36.569683",
                            "IataCode": "INS",
                            "Airports": [
                                {
                                    "Id": "INS",
                                    "Name": "Indian Springs",
                                    "CityId": "INSA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-115.66667, 36.583333"
                                }
                            ]
                        },
                        {
                            "Id": "EYRA",
                            "Name": "Yerington",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-119.16293, 38.985751",
                            "IataCode": "EYR",
                            "Airports": [
                                {
                                    "Id": "EYR",
                                    "Name": "Yerington",
                                    "CityId": "EYRA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-119.25, 39"
                                }
                            ]
                        },
                        {
                            "Id": "BGQA",
                            "Name": "Big Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-101.46039, 31.191544",
                            "IataCode": "BGQ",
                            "Airports": [
                                {
                                    "Id": "BGQ",
                                    "Name": "Big Lake",
                                    "CityId": "BGQA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-101.46667, 31.2"
                                }
                            ]
                        },
                        {
                            "Id": "WHDA",
                            "Name": "Hyder",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-130.02472, 55.916944",
                            "IataCode": "WHD",
                            "Airports": [
                                {
                                    "Id": "WHD",
                                    "Name": "Hyder",
                                    "CityId": "WHDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-130.08333, 55.916667"
                                }
                            ]
                        },
                        {
                            "Id": "WLBA",
                            "Name": "Labouchere Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.63222, 56.296389",
                            "IataCode": "WLB",
                            "Airports": [
                                {
                                    "Id": "WLB",
                                    "Name": "Labouchere Bay",
                                    "CityId": "WLBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.65833, 56.291667"
                                }
                            ]
                        },
                        {
                            "Id": "POQA",
                            "Name": "Polk Inlet",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.54583, 55.349722",
                            "IataCode": "POQ",
                            "Airports": [
                                {
                                    "Id": "POQ",
                                    "Name": "Polk Inlet",
                                    "CityId": "POQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.5, 55.35"
                                }
                            ]
                        },
                        {
                            "Id": "PTLA",
                            "Name": "Port Armstrong",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.66167, 56.296944",
                            "IataCode": "PTL",
                            "Airports": [
                                {
                                    "Id": "PTL",
                                    "Name": "Port Armstrong",
                                    "CityId": "PTLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.6625, 56.297222"
                                }
                            ]
                        },
                        {
                            "Id": "RWBA",
                            "Name": "Rowan Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.25111, 56.653889",
                            "IataCode": "RWB",
                            "Airports": [
                                {
                                    "Id": "RWB",
                                    "Name": "Rowan Bay",
                                    "CityId": "RWBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.13333, 56.75"
                                }
                            ]
                        },
                        {
                            "Id": "WWPA",
                            "Name": "Whale Pass",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.12083, 56.115278",
                            "IataCode": "WWP",
                            "Airports": [
                                {
                                    "Id": "WWP",
                                    "Name": "Whale Pass",
                                    "CityId": "WWPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.83333, 56.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RTLA",
                            "Name": "Spirit Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "IDAHO",
                            "Location": "-116.86853, 47.966292",
                            "IataCode": "RTL",
                            "Airports": [
                                {
                                    "Id": "RTL",
                                    "Name": "Spirit Lake",
                                    "CityId": "RTLA",
                                    "CountryId": "US",
                                    "RegionId": "IDAHO",
                                    "Location": "-116.86667, 47.95"
                                }
                            ]
                        },
                        {
                            "Id": "DPGA",
                            "Name": "Dugway",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.74441, 40.221053",
                            "IataCode": "DPG",
                            "Airports": [
                                {
                                    "Id": "DPG",
                                    "Name": "Dugway",
                                    "CityId": "DPGA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.75, 40.233333"
                                }
                            ]
                        },
                        {
                            "Id": "RCEA",
                            "Name": "Roche Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.1488, 48.609822",
                            "IataCode": "RCE",
                            "Airports": [
                                {
                                    "Id": "RCE",
                                    "Name": "Roche Harbor",
                                    "CityId": "RCEA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.16667, 48.583333"
                                }
                            ]
                        },
                        {
                            "Id": "RSJA",
                            "Name": "Rosario",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.87602, 48.648715",
                            "IataCode": "RSJ",
                            "Airports": [
                                {
                                    "Id": "RSJ",
                                    "Name": "Rosario",
                                    "CityId": "RSJA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.86843, 48.64601"
                                }
                            ]
                        },
                        {
                            "Id": "HNXA",
                            "Name": "Hanna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-106.56197, 41.869962",
                            "IataCode": "HNX",
                            "Airports": [
                                {
                                    "Id": "HNX",
                                    "Name": "Hanna",
                                    "CityId": "HNXA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-106.5, 41.833333"
                                }
                            ]
                        },
                        {
                            "Id": "JVIA",
                            "Name": "Manville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-104.61774, 42.779134",
                            "IataCode": "JVI",
                            "Airports": [
                                {
                                    "Id": "JVI",
                                    "Name": "Manville",
                                    "CityId": "JVIA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-104.61667, 42.783333"
                                }
                            ]
                        },
                        {
                            "Id": "CUWA",
                            "Name": "Cube Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.71111, 57.935",
                            "IataCode": "CUW",
                            "Airports": [
                                {
                                    "Id": "CUW",
                                    "Name": "Cube Cove",
                                    "CityId": "CUWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.75, 57.941667"
                                }
                            ]
                        },
                        {
                            "Id": "DOFA",
                            "Name": "Dora Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.29528, 55.188889",
                            "IataCode": "DOF",
                            "Airports": [
                                {
                                    "Id": "DOF",
                                    "Name": "Dora Bay",
                                    "CityId": "DOFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.26667, 55.2125"
                                }
                            ]
                        },
                        {
                            "Id": "HBHA",
                            "Name": "Hobart Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.35333, 57.465833",
                            "IataCode": "HBH",
                            "Airports": [
                                {
                                    "Id": "HBH",
                                    "Name": "Hobart Bay",
                                    "CityId": "HBHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.46667, 57.408333"
                                }
                            ]
                        },
                        {
                            "Id": "BLWA",
                            "Name": "Waimanalo",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-157.72083, 21.35",
                            "IataCode": "BLW",
                            "Airports": [
                                {
                                    "Id": "BLW",
                                    "Name": "Waimanalo",
                                    "CityId": "BLWA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-157.7, 21.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MKKA",
                            "Name": "Hoolehua",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-157.07139, 21.171111",
                            "IataCode": "MKK",
                            "Airports": [
                                {
                                    "Id": "MKK",
                                    "Name": "Hoolehua",
                                    "CityId": "MKKA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-157.1, 21.152778"
                                }
                            ]
                        },
                        {
                            "Id": "RLUA",
                            "Name": "Bornite",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.94278, 67.059167",
                            "IataCode": "RLU",
                            "Airports": [
                                {
                                    "Id": "RLU",
                                    "Name": "Bornite",
                                    "CityId": "RLUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156, 67.75"
                                }
                            ]
                        },
                        {
                            "Id": "WCRA",
                            "Name": "Chandalar",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.49361, 67.505278",
                            "IataCode": "WCR",
                            "Airports": [
                                {
                                    "Id": "WCR",
                                    "Name": "Chandalar",
                                    "CityId": "WCRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.5, 67.505556"
                                }
                            ]
                        },
                        {
                            "Id": "KCNA",
                            "Name": "Chernofski",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-167.55, 53.408333",
                            "IataCode": "KCN",
                            "Airports": [
                                {
                                    "Id": "KCN",
                                    "Name": "Chernofski",
                                    "CityId": "KCNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-167.55, 53.4"
                                }
                            ]
                        },
                        {
                            "Id": "DRFA",
                            "Name": "Drift River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.16111, 60.591667",
                            "IataCode": "DRF",
                            "Airports": [
                                {
                                    "Id": "DRF",
                                    "Name": "Drift River",
                                    "CityId": "DRFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.15, 60.583333"
                                }
                            ]
                        },
                        {
                            "Id": "DUTA",
                            "Name": "Dutch Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.5422, 53.8898",
                            "IataCode": "DUT",
                            "Airports": [
                                {
                                    "Id": "DUT",
                                    "Name": "Dutch Harbor",
                                    "CityId": "DUTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.53583, 53.901111"
                                }
                            ]
                        },
                        {
                            "Id": "GAKA",
                            "Name": "Gakona",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.30194, 62.301944",
                            "IataCode": "GAK",
                            "Airports": [
                                {
                                    "Id": "GAK",
                                    "Name": "Gakona",
                                    "CityId": "GAKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.31667, 62.333333"
                                }
                            ]
                        },
                        {
                            "Id": "GEKA",
                            "Name": "Ganes Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.43056, 63.089722",
                            "IataCode": "GEK",
                            "Airports": [
                                {
                                    "Id": "GEK",
                                    "Name": "Ganes Creek",
                                    "CityId": "GEKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.43333, 63.1"
                                }
                            ]
                        },
                        {
                            "Id": "GLQA",
                            "Name": "Glennallen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.54639, 62.109167",
                            "IataCode": "GLQ",
                            "Airports": [
                                {
                                    "Id": "GLQ",
                                    "Name": "Glennallen",
                                    "CityId": "GLQA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.55, 62.116667"
                                }
                            ]
                        },
                        {
                            "Id": "GNUA",
                            "Name": "Goodnews Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.5875, 59.118889",
                            "IataCode": "GNU",
                            "Airports": [
                                {
                                    "Id": "GNU",
                                    "Name": "Goodnews Bay",
                                    "CityId": "GNUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.58056, 59.1125"
                                }
                            ]
                        },
                        {
                            "Id": "MOSA",
                            "Name": "Moses Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.03333, 64.7",
                            "IataCode": "MOS",
                            "Airports": [
                                {
                                    "Id": "MOS",
                                    "Name": "Moses Point",
                                    "CityId": "MOSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.04333, 64.695556"
                                }
                            ]
                        },
                        {
                            "Id": "MNTA",
                            "Name": "Minto",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.17472, 64.887222",
                            "IataCode": "MNT",
                            "Airports": [
                                {
                                    "Id": "MNT",
                                    "Name": "Minto",
                                    "CityId": "MNTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.37029, 65.145393"
                                }
                            ]
                        },
                        {
                            "Id": "PMUA",
                            "Name": "Paimiut",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.23194, 61.964167",
                            "IataCode": "PMU",
                            "Airports": [
                                {
                                    "Id": "PMU",
                                    "Name": "Paimiut",
                                    "CityId": "PMUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.33333, 61.983333"
                                }
                            ]
                        },
                        {
                            "Id": "PMLA",
                            "Name": "Port Moller",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.57694, 55.988611",
                            "IataCode": "PML",
                            "Airports": [
                                {
                                    "Id": "PML",
                                    "Name": "Port Moller",
                                    "CityId": "PMLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.26555, 56.03676"
                                }
                            ]
                        },
                        {
                            "Id": "PJSA",
                            "Name": "Port San Juan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.0675, 60.050833",
                            "IataCode": "PJS",
                            "Airports": [
                                {
                                    "Id": "PJS",
                                    "Name": "Port San Juan",
                                    "CityId": "PJSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.06389, 60.048611"
                                }
                            ]
                        },
                        {
                            "Id": "KPRA",
                            "Name": "Port Williams",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.58278, 58.492222",
                            "IataCode": "KPR",
                            "Airports": [
                                {
                                    "Id": "KPR",
                                    "Name": "Port Williams",
                                    "CityId": "KPRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.58333, 58.433333"
                                }
                            ]
                        },
                        {
                            "Id": "PUOA",
                            "Name": "Prudhoe Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.66944, 70.292222",
                            "IataCode": "PUO",
                            "Airports": [
                                {
                                    "Id": "PUO",
                                    "Name": "Prudhoe Bay",
                                    "CityId": "PUOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.33694, 70.251389"
                                }
                            ]
                        },
                        {
                            "Id": "STGA",
                            "Name": "St George Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-169.54167, 56.6",
                            "IataCode": "STG",
                            "Airports": [
                                {
                                    "Id": "STG",
                                    "Name": "St George Island",
                                    "CityId": "STGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-169.66568, 56.577688"
                                }
                            ]
                        },
                        {
                            "Id": "SMKA",
                            "Name": "St Michael",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.03917, 63.478056",
                            "IataCode": "SMK",
                            "Airports": [
                                {
                                    "Id": "SMK",
                                    "Name": "St Michael",
                                    "CityId": "SMKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.02778, 63.484167"
                                }
                            ]
                        },
                        {
                            "Id": "KSMA",
                            "Name": "Saint Marys",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-163.16583, 62.053056",
                            "IataCode": "KSM",
                            "Airports": [
                                {
                                    "Id": "KSM",
                                    "Name": "Saint Marys",
                                    "CityId": "KSMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.29806, 62.061667"
                                }
                            ]
                        },
                        {
                            "Id": "SNPA",
                            "Name": "Saint Paul Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-170.275, 57.122222",
                            "IataCode": "SNP",
                            "Airports": [
                                {
                                    "Id": "SNP",
                                    "Name": "Saint Paul Island",
                                    "CityId": "SNPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-170.21722, 57.152222"
                                }
                            ]
                        },
                        {
                            "Id": "SOLA",
                            "Name": "Solomon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.43917, 64.560833",
                            "IataCode": "SOL",
                            "Airports": [
                                {
                                    "Id": "SOL",
                                    "Name": "Solomon",
                                    "CityId": "SOLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.44028, 64.560833"
                                }
                            ]
                        },
                        {
                            "Id": "TOGA",
                            "Name": "Togiak Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.37639, 59.061944",
                            "IataCode": "TOG",
                            "Airports": [
                                {
                                    "Id": "TOG",
                                    "Name": "Togiak Village",
                                    "CityId": "TOGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.38222, 59.056667"
                                }
                            ]
                        },
                        {
                            "Id": "AEDA",
                            "Name": "Aleneva",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.90972, 58.06",
                            "IataCode": "AED",
                            "Airports": [
                                {
                                    "Id": "AED",
                                    "Name": "Aleneva",
                                    "CityId": "AEDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.9, 58.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BIGA",
                            "Name": "Big Delta",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.84222, 64.1525",
                            "IataCode": "BIG",
                            "Airports": [
                                {
                                    "Id": "BIG",
                                    "Name": "Big Delta",
                                    "CityId": "BIGA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.72028, 63.99444"
                                }
                            ]
                        },
                        {
                            "Id": "RTDA",
                            "Name": "Rotunda",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-82.29482, 26.85784",
                            "IataCode": "RTD",
                            "Airports": [
                                {
                                    "Id": "RTD",
                                    "Name": "Rotunda",
                                    "CityId": "RTDA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-82.266667, 26.866667"
                                }
                            ]
                        },
                        {
                            "Id": "WNCA",
                            "Name": "Tuxekan Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.24333, 55.88833",
                            "IataCode": "WNC",
                            "Airports": [
                                {
                                    "Id": "WNC",
                                    "Name": "Tuxekan Island",
                                    "CityId": "WNCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.225, 55.854167"
                                }
                            ]
                        },
                        {
                            "Id": "KNKA",
                            "Name": "Kakhonak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.75583, 59.44194",
                            "IataCode": "KNK",
                            "Airports": [
                                {
                                    "Id": "KNK",
                                    "Name": "Kakhonak",
                                    "CityId": "KNKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.85, 59.433333"
                                }
                            ]
                        },
                        {
                            "Id": "TEHA",
                            "Name": "Tetlin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.50158, 63.14862",
                            "IataCode": "TEH",
                            "Airports": [
                                {
                                    "Id": "TEH",
                                    "Name": "Tetlin",
                                    "CityId": "TEHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.51667, 63.133333"
                                }
                            ]
                        },
                        {
                            "Id": "UGIA",
                            "Name": "Uganik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.4, 57.76667",
                            "IataCode": "UGI",
                            "Airports": [
                                {
                                    "Id": "UGI",
                                    "Name": "Uganik",
                                    "CityId": "UGIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.5, 57.883333"
                                }
                            ]
                        },
                        {
                            "Id": "AYEA",
                            "Name": "Fort Devens",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-71.61479, 42.54364",
                            "IataCode": "AYE",
                            "Airports": [
                                {
                                    "Id": "AYE",
                                    "Name": "Fort Devens",
                                    "CityId": "AYEA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71.6, 42.55"
                                }
                            ]
                        },
                        {
                            "Id": "BYWA",
                            "Name": "Blakely Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.80243, 48.56025",
                            "IataCode": "BYW",
                            "Airports": [
                                {
                                    "Id": "BYW",
                                    "Name": "Blakely Island",
                                    "CityId": "BYWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123, 48.5"
                                }
                            ]
                        },
                        {
                            "Id": "CWSA",
                            "Name": "Center Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.8309, 48.49",
                            "IataCode": "CWS",
                            "Airports": [
                                {
                                    "Id": "CWS",
                                    "Name": "Center Island",
                                    "CityId": "CWSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.83139, 47.485"
                                }
                            ]
                        },
                        {
                            "Id": "CKRA",
                            "Name": "Crane Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.99826, 48.597117",
                            "IataCode": "CKR",
                            "Airports": [
                                {
                                    "Id": "CKR",
                                    "Name": "Crane Island",
                                    "CityId": "CKRA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123, 48.6"
                                }
                            ]
                        },
                        {
                            "Id": "DTRA",
                            "Name": "Decatur Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.81093, 48.510236",
                            "IataCode": "DTR",
                            "Airports": [
                                {
                                    "Id": "DTR",
                                    "Name": "Decatur Island",
                                    "CityId": "DTRA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.83333, 48.516667"
                                }
                            ]
                        },
                        {
                            "Id": "LPSA",
                            "Name": "Lopez Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.89194, 48.47889",
                            "IataCode": "LPS",
                            "Airports": [
                                {
                                    "Id": "LPS",
                                    "Name": "Lopez Island",
                                    "CityId": "LPSA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.93556, 48.482778"
                                }
                            ]
                        },
                        {
                            "Id": "WDNA",
                            "Name": "Waldron Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-123.03556, 48.68778",
                            "IataCode": "WDN",
                            "Airports": [
                                {
                                    "Id": "WDN",
                                    "Name": "Waldron Island",
                                    "CityId": "WDNA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-123.03333, 48.716667"
                                }
                            ]
                        },
                        {
                            "Id": "MVYA",
                            "Name": "Martha's Vineyard",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.54625, 41.388056",
                            "IataCode": "MVY",
                            "Airports": [
                                {
                                    "Id": "MVY",
                                    "Name": "Martha's Vineyard",
                                    "CityId": "MVYA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-70.616667, 41.392778"
                                }
                            ]
                        },
                        {
                            "Id": "UMBA",
                            "Name": "Umnak Island",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-168.43194, 53.22389",
                            "IataCode": "UMB",
                            "Airports": [
                                {
                                    "Id": "UNS",
                                    "Name": "Umnak Island Umnak",
                                    "CityId": "UMBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-168.33333, 53.25"
                                },
                                {
                                    "Id": "UMB",
                                    "Name": "Umnak Island North Shore",
                                    "CityId": "UMBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-167.8875, 53.376667"
                                }
                            ]
                        },
                        {
                            "Id": "ADKA",
                            "Name": "Adak Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-176.64861, 51.876964",
                            "IataCode": "ADK",
                            "Airports": [
                                {
                                    "Id": "ADK",
                                    "Name": "Adak Island",
                                    "CityId": "ADKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-176.64472, 51.882778"
                                }
                            ]
                        },
                        {
                            "Id": "ATUA",
                            "Name": "Attu Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "172.90944, 52.9025",
                            "IataCode": "ATU",
                            "Airports": [
                                {
                                    "Id": "ATU",
                                    "Name": "Attu Island",
                                    "CityId": "ATUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "173.17278, 52.825833"
                                }
                            ]
                        },
                        {
                            "Id": "ANNA",
                            "Name": "Annette Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.45583, 55.135",
                            "IataCode": "ANN",
                            "Airports": [
                                {
                                    "Id": "ANN",
                                    "Name": "Annette Island",
                                    "CityId": "ANNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.57056, 55.036944"
                                }
                            ]
                        },
                        {
                            "Id": "LIJA",
                            "Name": "Long Island",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.71861, 54.87111",
                            "IataCode": "LIJ",
                            "Airports": [
                                {
                                    "Id": "LIJ",
                                    "Name": "Long Island",
                                    "CityId": "LIJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.80833, 54.929167"
                                }
                            ]
                        },
                        {
                            "Id": "AVXA",
                            "Name": "Catalina Island",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.41989, 33.374917",
                            "IataCode": "AVX",
                            "Airports": [
                                {
                                    "Id": "TWH",
                                    "Name": "Catalina Island Two Harbors",
                                    "CityId": "AVXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.50833, 33.425"
                                },
                                {
                                    "Id": "AVX",
                                    "Name": "Catalina Island Avalon Bay",
                                    "CityId": "AVXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.41674, 33.40475"
                                },
                                {
                                    "Id": "CIB",
                                    "Name": "Catalina Island Ap In The Sky",
                                    "CityId": "AVXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.41389, 33.406111"
                                },
                                {
                                    "Id": "SXC",
                                    "Name": "Catalina Island Avalo Vor / WP",
                                    "CityId": "AVXA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.41667, 33.5"
                                }
                            ]
                        },
                        {
                            "Id": "HDHA",
                            "Name": "Oahu",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-157.94821, 21.454672",
                            "IataCode": "HDH",
                            "Airports": [
                                {
                                    "Id": "HDH",
                                    "Name": "Oahu",
                                    "CityId": "HDHA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-157.96667, 21.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LIHA",
                            "Name": "Kauai Island",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-159.53035, 22.061455",
                            "IataCode": "LIH",
                            "Airports": [
                                {
                                    "Id": "LIH",
                                    "Name": "Kauai Island Lihue",
                                    "CityId": "LIHA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-159.34583, 21.979167"
                                },
                                {
                                    "Id": "HPV",
                                    "Name": "Kauai Island Princeville",
                                    "CityId": "LIHA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-159.44667, 22.2125"
                                }
                            ]
                        },
                        {
                            "Id": "DTHA",
                            "Name": "Death Valley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.86513, 36.456911",
                            "IataCode": "DTH",
                            "Airports": [
                                {
                                    "Id": "DTH",
                                    "Name": "Death Valley",
                                    "CityId": "DTHA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117, 36.5"
                                }
                            ]
                        },
                        {
                            "Id": "MUEA",
                            "Name": "Kamuela",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.82101, 20.009369",
                            "IataCode": "MUE",
                            "Airports": [
                                {
                                    "Id": "MUE",
                                    "Name": "Kamuela",
                                    "CityId": "MUEA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-155.67333, 20.0125"
                                }
                            ]
                        },
                        {
                            "Id": "FBGA",
                            "Name": "Fort Bragg",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "N_CAR",
                            "Location": "-79.005757, 35.139303",
                            "IataCode": "FBG",
                            "Airports": [
                                {
                                    "Id": "FBG",
                                    "Name": "Fort Bragg",
                                    "CityId": "FBGA",
                                    "CountryId": "US",
                                    "RegionId": "N_CAR",
                                    "Location": "-79.016667, 35.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SUOA",
                            "Name": "Sunriver",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OREGO",
                            "Location": "-121.43864, 43.884007",
                            "IataCode": "SUO",
                            "Airports": [
                                {
                                    "Id": "SUO",
                                    "Name": "Sun River",
                                    "CityId": "SUOA",
                                    "CountryId": "US",
                                    "RegionId": "OREGO",
                                    "Location": "-121.5, 43.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CZCB",
                            "Name": "Copper Center",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.30528, 61.955",
                            "IataCode": "CZC",
                            "Airports": [
                                {
                                    "Id": "CZC",
                                    "Name": "Copper Centre",
                                    "CityId": "CZCB",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.3, 61.966667"
                                }
                            ]
                        },
                        {
                            "Id": "JLAA",
                            "Name": "Cooper Landing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.83417, 60.49",
                            "IataCode": "JLA",
                            "Airports": [
                                {
                                    "Id": "JLA",
                                    "Name": "Cooper Lodge",
                                    "CityId": "JLAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.85, 60.483333"
                                }
                            ]
                        },
                        {
                            "Id": "JCYA",
                            "Name": "Johnson City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.411968, 30.276868",
                            "IataCode": "JCY",
                            "Airports": [
                                {
                                    "Id": "JCY",
                                    "Name": "Johnson",
                                    "CityId": "JCYA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.416667, 30.283333"
                                }
                            ]
                        },
                        {
                            "Id": "IVHA",
                            "Name": "Sagwon",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.70056, 69.373056",
                            "IataCode": "IVH",
                            "Airports": [
                                {
                                    "Id": "IVH",
                                    "Name": "Ivishak",
                                    "CityId": "IVHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.25, 69.4"
                                }
                            ]
                        },
                        {
                            "Id": "WTRA",
                            "Name": "Whiteriver",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-109.96427, 33.836994",
                            "IataCode": "WTR",
                            "Airports": [
                                {
                                    "Id": "WTR",
                                    "Name": "White River",
                                    "CityId": "WTRA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-109.96667, 33.85"
                                }
                            ]
                        },
                        {
                            "Id": "BTIA",
                            "Name": "Kaktovik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-143.57833, 70.134722",
                            "IataCode": "BTI",
                            "Airports": [
                                {
                                    "Id": "BTI",
                                    "Name": "Barter Island",
                                    "CityId": "BTIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-143.57833, 70.134722"
                                }
                            ]
                        },
                        {
                            "Id": "LHBA",
                            "Name": "Unalaska",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.53667, 53.873611",
                            "IataCode": "LHB",
                            "Airports": [
                                {
                                    "Id": "LHB",
                                    "Name": "Lost Harbor",
                                    "CityId": "LHBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-165.6, 54.233333"
                                }
                            ]
                        },
                        {
                            "Id": "HEDA",
                            "Name": "Herendeen Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.68583, 55.768056",
                            "IataCode": "HED",
                            "Airports": [
                                {
                                    "Id": "HED",
                                    "Name": "Herendeen",
                                    "CityId": "HEDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.83333, 55.833333"
                                }
                            ]
                        },
                        {
                            "Id": "HAFA",
                            "Name": "Half Moon Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-122.42859, 37.463552",
                            "IataCode": "HAF",
                            "Airports": [
                                {
                                    "Id": "HAF",
                                    "Name": "Half Moon",
                                    "CityId": "HAFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-122.50192, 37.51355"
                                }
                            ]
                        },
                        {
                            "Id": "BMXA",
                            "Name": "Kokhanok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.77611, 59.439444",
                            "IataCode": "BMX",
                            "Airports": [
                                {
                                    "Id": "BMX",
                                    "Name": "Big Mountain",
                                    "CityId": "BMXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.25667, 59.361944"
                                }
                            ]
                        },
                        {
                            "Id": "MUIA",
                            "Name": "Fort Indiantown Gap",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-76.598299, 40.439811",
                            "IataCode": "MUI",
                            "Airports": [
                                {
                                    "Id": "MUI",
                                    "Name": "Fort Indiantown",
                                    "CityId": "MUIA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-76.596611, 40.447583"
                                }
                            ]
                        },
                        {
                            "Id": "PRLA",
                            "Name": "Chenega",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.01716, 60.07821",
                            "IataCode": "PRL",
                            "Airports": [
                                {
                                    "Id": "PRL",
                                    "Name": "Port Oceanic",
                                    "CityId": "PRLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.81944, 60.208333"
                                }
                            ]
                        },
                        {
                            "Id": "PEQA",
                            "Name": "Pecos",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-103.49323, 31.422912",
                            "IataCode": "PEQ",
                            "Airports": [
                                {
                                    "Id": "PEQ",
                                    "Name": "Pecos City",
                                    "CityId": "PEQA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-103.5, 31.433333"
                                }
                            ]
                        },
                        {
                            "Id": "TPOA",
                            "Name": "Port Alsworth",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.31278, 60.2025",
                            "IataCode": "TPO",
                            "Airports": [
                                {
                                    "Id": "TPO",
                                    "Name": "Tanalian Point",
                                    "CityId": "TPOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.33333, 60.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SXPA",
                            "Name": "Nunam Iqua",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-164.83333, 62.533333",
                            "IataCode": "SXP",
                            "Airports": [
                                {
                                    "Id": "SXP",
                                    "Name": "Sheldon Point",
                                    "CityId": "SXPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-164.83333, 62.533333"
                                }
                            ]
                        },
                        {
                            "Id": "SYAA",
                            "Name": "Attu",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "173.2375, 52.9375",
                            "IataCode": "SYA",
                            "Airports": [
                                {
                                    "Id": "SYA",
                                    "Name": "Shemya",
                                    "CityId": "SYAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "174.08944, 52.7175"
                                }
                            ]
                        },
                        {
                            "Id": "RBFA",
                            "Name": "Big Bear City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-116.84503, 34.261118",
                            "IataCode": "RBF",
                            "Airports": [
                                {
                                    "Id": "RBF",
                                    "Name": "Big Bear",
                                    "CityId": "RBFA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-116.85, 34.266667"
                                }
                            ]
                        },
                        {
                            "Id": "PNXA",
                            "Name": "Sherman",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.608881, 33.635662",
                            "IataCode": "PNX",
                            "Airports": [
                                {
                                    "Id": "PNX",
                                    "Name": "Sherman-Denison",
                                    "CityId": "PNXA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.611111, 33.638889"
                                }
                            ]
                        },
                        {
                            "Id": "BCEA",
                            "Name": "Bryce Canyon City",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-112.15694, 37.67389",
                            "IataCode": "BCE",
                            "Airports": [
                                {
                                    "Id": "BCE",
                                    "Name": "Bryce",
                                    "CityId": "BCEA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-112.15282, 37.702294"
                                }
                            ]
                        },
                        {
                            "Id": "FSNA",
                            "Name": "Buffalo Grove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-87.959794, 42.151415",
                            "IataCode": "FSN",
                            "Airports": [
                                {
                                    "Id": "FSN",
                                    "Name": "Fort Sheridan",
                                    "CityId": "FSNA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-87.966667, 42.15"
                                }
                            ]
                        },
                        {
                            "Id": "WDBA",
                            "Name": "Clover Pass",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.79167, 55.472222",
                            "IataCode": "WDB",
                            "Airports": [
                                {
                                    "Id": "WDB",
                                    "Name": "Deep Bay",
                                    "CityId": "WDBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.75, 55.583333"
                                }
                            ]
                        },
                        {
                            "Id": "NHKA",
                            "Name": "Solomons",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.454119, 38.318458",
                            "IataCode": "NHK",
                            "Airports": [
                                {
                                    "Id": "NHK",
                                    "Name": "Patuxent River",
                                    "CityId": "NHKA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-76.433333, 38.3"
                                }
                            ]
                        },
                        {
                            "Id": "GMVA",
                            "Name": "Goulding",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.20291, 37.009445",
                            "IataCode": "GMV",
                            "Airports": [
                                {
                                    "Id": "GMV",
                                    "Name": "Monument Valley Gldngs",
                                    "CityId": "GMVA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.20137, 37.017631"
                                }
                            ]
                        },
                        {
                            "Id": "WAYA",
                            "Name": "Waynesboro",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "PENNS",
                            "Location": "-77.577769, 39.755927",
                            "IataCode": "WAY",
                            "Airports": [
                                {
                                    "Id": "WAY",
                                    "Name": "Waynesburg",
                                    "CityId": "WAYA",
                                    "CountryId": "US",
                                    "RegionId": "PENNS",
                                    "Location": "-77.583333, 39.75"
                                }
                            ]
                        },
                        {
                            "Id": "LTHA",
                            "Name": "Amargosa Valley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-116.40033, 36.643842",
                            "IataCode": "LTH",
                            "Airports": [
                                {
                                    "Id": "LTH",
                                    "Name": "Lathrop Wells",
                                    "CityId": "LTHA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-116.38333, 36.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CKKA",
                            "Name": "Moko",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-91.839871, 36.467564",
                            "IataCode": "CKK",
                            "Airports": [
                                {
                                    "Id": "CKK",
                                    "Name": "Cherokee",
                                    "CityId": "CKKA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-91.866667, 36.5"
                                }
                            ]
                        },
                        {
                            "Id": "PYLA",
                            "Name": "Perry",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARKAN",
                            "Location": "-92.794056, 35.047587",
                            "IataCode": "PYL",
                            "Airports": [
                                {
                                    "Id": "PYL",
                                    "Name": "Perry Island",
                                    "CityId": "PYLA",
                                    "CountryId": "US",
                                    "RegionId": "ARKAN",
                                    "Location": "-92.816667, 35.033333"
                                }
                            ]
                        },
                        {
                            "Id": "OCAA",
                            "Name": "Grayvik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "FLORI",
                            "Location": "-80.28672, 25.31706",
                            "IataCode": "OCA",
                            "Airports": [
                                {
                                    "Id": "OCA",
                                    "Name": "Ocean Reef",
                                    "CityId": "OCAA",
                                    "CountryId": "US",
                                    "RegionId": "FLORI",
                                    "Location": "-80.274722, 25.325278"
                                }
                            ]
                        },
                        {
                            "Id": "CWGA",
                            "Name": "Champion Crossroad",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "GEORG",
                            "Location": "-84.87021, 32.851241",
                            "IataCode": "CWG",
                            "Airports": [
                                {
                                    "Id": "CWG",
                                    "Name": "Callaway Gardens",
                                    "CityId": "CWGA",
                                    "CountryId": "US",
                                    "RegionId": "GEORG",
                                    "Location": "-84.8825, 32.840278"
                                }
                            ]
                        },
                        {
                            "Id": "FMEA",
                            "Name": "Geraghty Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-76.734414, 39.099831",
                            "IataCode": "FME",
                            "Airports": [
                                {
                                    "Id": "FME",
                                    "Name": "Fort Meade",
                                    "CityId": "FMEA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-77, 39"
                                }
                            ]
                        },
                        {
                            "Id": "SBYA",
                            "Name": "Quail Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MARYL",
                            "Location": "-75.503254, 38.349284",
                            "IataCode": "SBY",
                            "Airports": [
                                {
                                    "Id": "SBY",
                                    "Name": "Salisbury-Ocean City",
                                    "CityId": "SBYA",
                                    "CountryId": "US",
                                    "RegionId": "MARYL",
                                    "Location": "-75.51, 38.338611"
                                }
                            ]
                        },
                        {
                            "Id": "AIZA",
                            "Name": "Kaiser",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-92.589906, 38.133645",
                            "IataCode": "AIZ",
                            "Airports": [
                                {
                                    "Id": "AIZ",
                                    "Name": "Kaiser / Lake Ozark",
                                    "CityId": "AIZA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-92.547222, 38.098333"
                                }
                            ]
                        },
                        {
                            "Id": "THMA",
                            "Name": "Thompson",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-91.982952, 39.188652",
                            "IataCode": "THM",
                            "Airports": [
                                {
                                    "Id": "THM",
                                    "Name": "Thompsonfield",
                                    "CityId": "THMA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-91.983333, 39.183333"
                                }
                            ]
                        },
                        {
                            "Id": "FLVA",
                            "Name": "Weston",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MISSO",
                            "Location": "-94.90163, 39.411109",
                            "IataCode": "FLV",
                            "Airports": [
                                {
                                    "Id": "FLV",
                                    "Name": "Fort Leavenworth",
                                    "CityId": "FLVA",
                                    "CountryId": "US",
                                    "RegionId": "MISSO",
                                    "Location": "-94.916667, 39.366667"
                                }
                            ]
                        },
                        {
                            "Id": "FSIA",
                            "Name": "Artillery Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-98.413943, 34.638962",
                            "IataCode": "FSI",
                            "Airports": [
                                {
                                    "Id": "FSI",
                                    "Name": "Fort Sill",
                                    "CityId": "FSIA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-98.419972, 34.650722"
                                }
                            ]
                        },
                        {
                            "Id": "NRIA",
                            "Name": "Dennis",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "OKLAH",
                            "Location": "-94.884123, 36.539246",
                            "IataCode": "NRI",
                            "Airports": [
                                {
                                    "Id": "NRI",
                                    "Name": "Shangri-la",
                                    "CityId": "NRIA",
                                    "CountryId": "US",
                                    "RegionId": "OKLAH",
                                    "Location": "-94.969, 36.675778"
                                }
                            ]
                        },
                        {
                            "Id": "FALA",
                            "Name": "H Cuellar Estates Colonia",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-99.126422, 26.561444",
                            "IataCode": "FAL",
                            "Airports": [
                                {
                                    "Id": "FAL",
                                    "Name": "Roma",
                                    "CityId": "FALA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-99.133333, 26.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MGIA",
                            "Name": "Port O'Connor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-96.405807, 28.448335",
                            "IataCode": "MGI",
                            "Airports": [
                                {
                                    "Id": "MGI",
                                    "Name": "Matagorda Island",
                                    "CityId": "MGIA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-96.5, 28.25"
                                }
                            ]
                        },
                        {
                            "Id": "LKSA",
                            "Name": "Spicewood",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-98.156407, 30.475472",
                            "IataCode": "LKS",
                            "Airports": [
                                {
                                    "Id": "LKS",
                                    "Name": "Lakeside",
                                    "CityId": "LKSA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-98.146944, 30.500833"
                                }
                            ]
                        },
                        {
                            "Id": "DAAA",
                            "Name": "Sliders",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-78.684731, 37.457089",
                            "IataCode": "DAA",
                            "Airports": [
                                {
                                    "Id": "DAA",
                                    "Name": "Fort Belvoir",
                                    "CityId": "DAAA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-77.0772, 38.74039"
                                }
                            ]
                        },
                        {
                            "Id": "BMIA",
                            "Name": "Colonial Meadows",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-88.9275, 40.481944",
                            "IataCode": "BMI",
                            "Airports": [
                                {
                                    "Id": "BMI",
                                    "Name": "Bloomington-Normal",
                                    "CityId": "BMIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-88.9275, 40.481944"
                                }
                            ]
                        },
                        {
                            "Id": "SQIA",
                            "Name": "Yeoward Addition",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ILLIN",
                            "Location": "-89.660662, 41.758087",
                            "IataCode": "SQI",
                            "Airports": [
                                {
                                    "Id": "SQI",
                                    "Name": "Sterling Rockfalls",
                                    "CityId": "SQIA",
                                    "CountryId": "US",
                                    "RegionId": "ILLIN",
                                    "Location": "-89.680833, 41.74"
                                }
                            ]
                        },
                        {
                            "Id": "CVRA",
                            "Name": "Culver",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "INDIA",
                            "Location": "-86.423063, 41.218931",
                            "IataCode": "CVR",
                            "Airports": [
                                {
                                    "Id": "CVR",
                                    "Name": "Culver City",
                                    "CityId": "CVRA",
                                    "CountryId": "US",
                                    "RegionId": "INDIA",
                                    "Location": "-86.416667, 41.216667"
                                }
                            ]
                        },
                        {
                            "Id": "NCOA",
                            "Name": "Squibnocket",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MASSA",
                            "Location": "-70.774473, 41.307058",
                            "IataCode": "NCO",
                            "Airports": [
                                {
                                    "Id": "NCO",
                                    "Name": "Quonset Point",
                                    "CityId": "NCOA",
                                    "CountryId": "US",
                                    "RegionId": "MASSA",
                                    "Location": "-71, 41"
                                }
                            ]
                        },
                        {
                            "Id": "DREA",
                            "Name": "Drummond",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.731114, 46.020021",
                            "IataCode": "DRE",
                            "Airports": [
                                {
                                    "Id": "DRE",
                                    "Name": "Drummond Island",
                                    "CityId": "DREA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.75, 46.016667"
                                }
                            ]
                        },
                        {
                            "Id": "HTLA",
                            "Name": "Lyon Manor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-84.743918, 44.461405",
                            "IataCode": "HTL",
                            "Airports": [
                                {
                                    "Id": "HTL",
                                    "Name": "Houghton",
                                    "CityId": "HTLA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-84.673347, 44.359574"
                                }
                            ]
                        },
                        {
                            "Id": "SGWA",
                            "Name": "Thomas",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MICHI",
                            "Location": "-83.54802, 43.700574",
                            "IataCode": "SGW",
                            "Airports": [
                                {
                                    "Id": "SGW",
                                    "Name": "Saginaw Bay",
                                    "CityId": "SGWA",
                                    "CountryId": "US",
                                    "RegionId": "MICHI",
                                    "Location": "-83.666667, 43.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RDRA",
                            "Name": "Finkle",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MINNE",
                            "Location": "-96.747303, 46.813852",
                            "IataCode": "RDR",
                            "Airports": [
                                {
                                    "Id": "RDR",
                                    "Name": "Red River",
                                    "CityId": "RDRA",
                                    "CountryId": "US",
                                    "RegionId": "MINNE",
                                    "Location": "-96.75, 46.833333"
                                }
                            ]
                        },
                        {
                            "Id": "NRSA",
                            "Name": "Wilsie",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-115.60305, 32.800056",
                            "IataCode": "NRS",
                            "Airports": [
                                {
                                    "Id": "NRS",
                                    "Name": "Imperial Beach",
                                    "CityId": "NRSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-115.57472, 32.837222"
                                }
                            ]
                        },
                        {
                            "Id": "FHUA",
                            "Name": "Miles Manor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-110.3398, 31.558428",
                            "IataCode": "FHU",
                            "Airports": [
                                {
                                    "Id": "FHU",
                                    "Name": "Fort Huachuca",
                                    "CityId": "FHUA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-110.34444, 31.588333"
                                }
                            ]
                        },
                        {
                            "Id": "HAEA",
                            "Name": "Supai",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ARIZO",
                            "Location": "-112.68908, 36.236927",
                            "IataCode": "HAE",
                            "Airports": [
                                {
                                    "Id": "HAE",
                                    "Name": "Havasupai",
                                    "CityId": "HAEA",
                                    "CountryId": "US",
                                    "RegionId": "ARIZO",
                                    "Location": "-91.443611, 39.724445"
                                }
                            ]
                        },
                        {
                            "Id": "RBKA",
                            "Name": "Dutch Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-117.10975, 33.596692",
                            "IataCode": "RBK",
                            "Airports": [
                                {
                                    "Id": "RBK",
                                    "Name": "Rancho",
                                    "CityId": "RBKA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-117.11667, 33.566667"
                                }
                            ]
                        },
                        {
                            "Id": "SFRA",
                            "Name": "El Merrie Dell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.37869, 34.295279",
                            "IataCode": "SFR",
                            "Airports": [
                                {
                                    "Id": "SFR",
                                    "Name": "San Fernando",
                                    "CityId": "SFRA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.43333, 34.266667"
                                }
                            ]
                        },
                        {
                            "Id": "SPQA",
                            "Name": "Harbor Hills",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.31479, 33.780295",
                            "IataCode": "SPQ",
                            "Airports": [
                                {
                                    "Id": "SPQ",
                                    "Name": "San Pedro",
                                    "CityId": "SPQA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-118.27278, 33.750556"
                                }
                            ]
                        },
                        {
                            "Id": "SQAA",
                            "Name": "Topanga Beach",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-118.57953, 34.040008",
                            "IataCode": "SQA",
                            "Airports": [
                                {
                                    "Id": "SQA",
                                    "Name": "Santa Ynez",
                                    "CityId": "SQAA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-120.07653, 34.60665"
                                }
                            ]
                        },
                        {
                            "Id": "OYSA",
                            "Name": "White Wolf",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "CALIF",
                            "Location": "-119.65017, 37.869367",
                            "IataCode": "OYS",
                            "Airports": [
                                {
                                    "Id": "OYS",
                                    "Name": "Yosemite Ntl Park",
                                    "CityId": "OYSA",
                                    "CountryId": "US",
                                    "RegionId": "CALIF",
                                    "Location": "-119.55, 37.85"
                                }
                            ]
                        },
                        {
                            "Id": "BCJA",
                            "Name": "Haver",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-105.92751, 38.964994",
                            "IataCode": "BCJ",
                            "Airports": [
                                {
                                    "Id": "BCJ",
                                    "Name": "Baca Grande",
                                    "CityId": "BCJA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-106, 39"
                                }
                            ]
                        },
                        {
                            "Id": "CDSA",
                            "Name": "Fowler",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-100.1957, 37.385579",
                            "IataCode": "CDS",
                            "Airports": [
                                {
                                    "Id": "CDS",
                                    "Name": "Childress",
                                    "CityId": "CDSA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-100.28432, 34.433142"
                                }
                            ]
                        },
                        {
                            "Id": "WSBA",
                            "Name": "Steamboat",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-119.7438, 39.377133",
                            "IataCode": "WSB",
                            "Airports": [
                                {
                                    "Id": "WSB",
                                    "Name": "Steamboat Bay",
                                    "CityId": "WSBA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-119.73333, 39.366667"
                                }
                            ]
                        },
                        {
                            "Id": "UCCA",
                            "Name": "Sugar Bunker",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "NEVAD",
                            "Location": "-115.96114, 36.839399",
                            "IataCode": "UCC",
                            "Airports": [
                                {
                                    "Id": "UCC",
                                    "Name": "Yucca Flat",
                                    "CityId": "UCCA",
                                    "CountryId": "US",
                                    "RegionId": "NEVAD",
                                    "Location": "-116.05, 37"
                                }
                            ]
                        },
                        {
                            "Id": "PRTA",
                            "Name": "Mendenhaven",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.56694, 58.391944",
                            "IataCode": "PRT",
                            "Airports": [
                                {
                                    "Id": "PRT",
                                    "Name": "Point Retreat",
                                    "CityId": "PRTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.55, 58.35"
                                }
                            ]
                        },
                        {
                            "Id": "FNRA",
                            "Name": "Mendenhall Valley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.56667, 58.391667",
                            "IataCode": "FNR",
                            "Airports": [
                                {
                                    "Id": "FNR",
                                    "Name": "Funter Bay",
                                    "CityId": "FNRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.58333, 58.333333"
                                }
                            ]
                        },
                        {
                            "Id": "HBCA",
                            "Name": "Mount Edgecombe",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.35444, 57.051111",
                            "IataCode": "HBC",
                            "Airports": [
                                {
                                    "Id": "HBC",
                                    "Name": "Hanus Bay",
                                    "CityId": "HBCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.91667, 57.5"
                                }
                            ]
                        },
                        {
                            "Id": "CIVA",
                            "Name": "Mud Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.76806, 55.420833",
                            "IataCode": "CIV",
                            "Airports": [
                                {
                                    "Id": "CIV",
                                    "Name": "Chomley",
                                    "CityId": "CIVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.21667, 55.216667"
                                }
                            ]
                        },
                        {
                            "Id": "NKIA",
                            "Name": "Naukati Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.21667, 55.366667",
                            "IataCode": "NKI",
                            "Airports": [
                                {
                                    "Id": "NKI",
                                    "Name": "Naukiti",
                                    "CityId": "NKIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.21667, 55.366667"
                                }
                            ]
                        },
                        {
                            "Id": "PRFA",
                            "Name": "Peninsula Point",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.73333, 55.383333",
                            "IataCode": "PRF",
                            "Airports": [
                                {
                                    "Id": "PRF",
                                    "Name": "Port Johnson",
                                    "CityId": "PRFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132, 55.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DLOA",
                            "Name": "Port Higgins",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.81667, 55.45",
                            "IataCode": "DLO",
                            "Airports": [
                                {
                                    "Id": "DLO",
                                    "Name": "Dolomi",
                                    "CityId": "DLOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.05, 55.122222"
                                }
                            ]
                        },
                        {
                            "Id": "KBEA",
                            "Name": "Refuge Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.74167, 55.406944",
                            "IataCode": "KBE",
                            "Airports": [
                                {
                                    "Id": "KBE",
                                    "Name": "Bell Island",
                                    "CityId": "KBEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.57, 55.929444"
                                }
                            ]
                        },
                        {
                            "Id": "SCJA",
                            "Name": "Salt Chuck",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.55361, 55.626389",
                            "IataCode": "SCJ",
                            "Airports": [
                                {
                                    "Id": "SCJ",
                                    "Name": "Smith Cove",
                                    "CityId": "SCJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.33333, 55.416667"
                                }
                            ]
                        },
                        {
                            "Id": "NIEA",
                            "Name": "Shoreline Drive",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.725, 55.375",
                            "IataCode": "NIE",
                            "Airports": [
                                {
                                    "Id": "NIE",
                                    "Name": "Niblack",
                                    "CityId": "NIEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.15, 55.066667"
                                }
                            ]
                        },
                        {
                            "Id": "LSRA",
                            "Name": "Situk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-139.55472, 59.435278",
                            "IataCode": "LSR",
                            "Airports": [
                                {
                                    "Id": "LSR",
                                    "Name": "Lost River",
                                    "CityId": "LSRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-139.73333, 59.55"
                                }
                            ]
                        },
                        {
                            "Id": "UNUA",
                            "Name": "Starr Hill",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.40417, 58.304167",
                            "IataCode": "UNU",
                            "Airports": [
                                {
                                    "Id": "UNU",
                                    "Name": "Juneau",
                                    "CityId": "UNUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.41667, 58.3"
                                }
                            ]
                        },
                        {
                            "Id": "KBKA",
                            "Name": "Sunnyside",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-136.25222, 57.982778",
                            "IataCode": "KBK",
                            "Airports": [
                                {
                                    "Id": "KBK",
                                    "Name": "Klag Bay",
                                    "CityId": "KBKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-136.16667, 57.666667"
                                }
                            ]
                        },
                        {
                            "Id": "AIBA",
                            "Name": "Thoms Place",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.185, 56.201944",
                            "IataCode": "AIB",
                            "Airports": [
                                {
                                    "Id": "AIB",
                                    "Name": "Anita Bay",
                                    "CityId": "AIBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.38333, 56.233333"
                                }
                            ]
                        },
                        {
                            "Id": "FAKA",
                            "Name": "Todd",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.03972, 57.461111",
                            "IataCode": "FAK",
                            "Airports": [
                                {
                                    "Id": "FAK",
                                    "Name": "False Island",
                                    "CityId": "FAKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.21667, 57.530556"
                                }
                            ]
                        },
                        {
                            "Id": "WLNA",
                            "Name": "Tokeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.46389, 55.994444",
                            "IataCode": "WLN",
                            "Airports": [
                                {
                                    "Id": "WLN",
                                    "Name": "Little Naukati",
                                    "CityId": "WLNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.21667, 55.866667"
                                }
                            ]
                        },
                        {
                            "Id": "PWRA",
                            "Name": "Tyee",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.54333, 57.040556",
                            "IataCode": "PWR",
                            "Airports": [
                                {
                                    "Id": "PWR",
                                    "Name": "Port Walter",
                                    "CityId": "PWRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.65, 56.383333"
                                }
                            ]
                        },
                        {
                            "Id": "RTEA",
                            "Name": "Ward Cove",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.72278, 55.411944",
                            "IataCode": "RTE",
                            "Airports": [
                                {
                                    "Id": "RTE",
                                    "Name": "Marguerite Bay",
                                    "CityId": "RTEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.63333, 55.7"
                                }
                            ]
                        },
                        {
                            "Id": "PBKA",
                            "Name": "West Juneau",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.42944, 58.295",
                            "IataCode": "PBK",
                            "Airports": [
                                {
                                    "Id": "PBK",
                                    "Name": "Pack Creek",
                                    "CityId": "PBKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.7, 57.8"
                                }
                            ]
                        },
                        {
                            "Id": "EFBA",
                            "Name": "Whitestone Logging Camp",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.42972, 58.071111",
                            "IataCode": "EFB",
                            "Airports": [
                                {
                                    "Id": "EFB",
                                    "Name": "Eight Fathom Bight",
                                    "CityId": "EFBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.73333, 58"
                                }
                            ]
                        },
                        {
                            "Id": "BCCA",
                            "Name": "Bearcreek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "MONTA",
                            "Location": "-109.15709, 45.160779",
                            "IataCode": "BCC",
                            "Airports": [
                                {
                                    "Id": "BCC",
                                    "Name": "Bear Creek",
                                    "CityId": "BCCA",
                                    "CountryId": "US",
                                    "RegionId": "MONTA",
                                    "Location": "-109.1, 45.183333"
                                }
                            ]
                        },
                        {
                            "Id": "NUWA",
                            "Name": "Silvana Terraces",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-122.28348, 48.202599",
                            "IataCode": "NUW",
                            "Airports": [
                                {
                                    "Id": "NUW",
                                    "Name": "Whidbey Island",
                                    "CityId": "NUWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-122.28333, 48.2"
                                }
                            ]
                        },
                        {
                            "Id": "SSWA",
                            "Name": "Stuart",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WASHI",
                            "Location": "-121.94845, 47.688434",
                            "IataCode": "SSW",
                            "Airports": [
                                {
                                    "Id": "SSW",
                                    "Name": "Stuart Island",
                                    "CityId": "SSWA",
                                    "CountryId": "US",
                                    "RegionId": "WASHI",
                                    "Location": "-121.95, 47.683333"
                                }
                            ]
                        },
                        {
                            "Id": "EMMA",
                            "Name": "Diamondville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "WYOMI",
                            "Location": "-110.5385, 41.779392",
                            "IataCode": "EMM",
                            "Airports": [
                                {
                                    "Id": "EMM",
                                    "Name": "Kemerer",
                                    "CityId": "EMMA",
                                    "CountryId": "US",
                                    "RegionId": "WYOMI",
                                    "Location": "-110.55961, 41.82467"
                                }
                            ]
                        },
                        {
                            "Id": "HCBA",
                            "Name": "Beaver Falls",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.46667, 55.383333",
                            "IataCode": "HCB",
                            "Airports": [
                                {
                                    "Id": "HCB",
                                    "Name": "Shoal Cove",
                                    "CityId": "HCBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.28333, 55.45"
                                }
                            ]
                        },
                        {
                            "Id": "FICA",
                            "Name": "Bell Island Hot Springs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.56528, 55.931667",
                            "IataCode": "FIC",
                            "Airports": [
                                {
                                    "Id": "FIC",
                                    "Name": "Fire Cove",
                                    "CityId": "FICA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.5375, 55.777778"
                                }
                            ]
                        },
                        {
                            "Id": "CBAA",
                            "Name": "Cannery",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.09639, 57.785278",
                            "IataCode": "CBA",
                            "Airports": [
                                {
                                    "Id": "CBA",
                                    "Name": "Corner Bay",
                                    "CityId": "CBAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.2, 57.716667"
                                }
                            ]
                        },
                        {
                            "Id": "FRPA",
                            "Name": "Freshwater Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.07944, 57.891667",
                            "IataCode": "FRP",
                            "Airports": [
                                {
                                    "Id": "FRP",
                                    "Name": "Fresh Water Bay",
                                    "CityId": "FRPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.025, 57.845833"
                                }
                            ]
                        },
                        {
                            "Id": "PFDA",
                            "Name": "Game Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.50778, 58.056667",
                            "IataCode": "PFD",
                            "Airports": [
                                {
                                    "Id": "PFD",
                                    "Name": "Port Frederick",
                                    "CityId": "PFDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.65, 57.95"
                                }
                            ]
                        },
                        {
                            "Id": "HMSA",
                            "Name": "Funter",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.89583, 58.256389",
                            "IataCode": "HMS",
                            "Airports": [
                                {
                                    "Id": "HMS",
                                    "Name": "Homeshore",
                                    "CityId": "HMSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-135.34722, 58.294444"
                                }
                            ]
                        },
                        {
                            "Id": "LPWA",
                            "Name": "Goddard",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-135.37278, 56.835278",
                            "IataCode": "LPW",
                            "Airports": [
                                {
                                    "Id": "LPW",
                                    "Name": "Little Port Walter",
                                    "CityId": "LPWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.83333, 56.383333"
                                }
                            ]
                        },
                        {
                            "Id": "CBZA",
                            "Name": "Hadley",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.28667, 55.534722",
                            "IataCode": "CBZ",
                            "Airports": [
                                {
                                    "Id": "CBZ",
                                    "Name": "Cabin Creek",
                                    "CityId": "CBZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-132.41667, 55.383333"
                                }
                            ]
                        },
                        {
                            "Id": "KLPA",
                            "Name": "Hood Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.39861, 57.3825",
                            "IataCode": "KLP",
                            "Airports": [
                                {
                                    "Id": "KLP",
                                    "Name": "Kelp Bay",
                                    "CityId": "KLPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.86667, 57.55"
                                }
                            ]
                        },
                        {
                            "Id": "BSFA",
                            "Name": "Pōhākupu",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-157.75417, 21.384722",
                            "IataCode": "BSF",
                            "Airports": [
                                {
                                    "Id": "BSF",
                                    "Name": "Pohakuloa",
                                    "CityId": "BSFA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-157.75, 21.383333"
                                }
                            ]
                        },
                        {
                            "Id": "NAXA",
                            "Name": "Waimanalo Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-158.0925, 21.332778",
                            "IataCode": "NAX",
                            "Airports": [
                                {
                                    "Id": "NAX",
                                    "Name": "Barbers Point",
                                    "CityId": "NAXA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-158.11667, 21.3"
                                }
                            ]
                        },
                        {
                            "Id": "UPPA",
                            "Name": "Hikapoloa",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "HAWAI",
                            "Location": "-155.85944, 20.242222",
                            "IataCode": "UPP",
                            "Airports": [
                                {
                                    "Id": "UPP",
                                    "Name": "Upolu Point",
                                    "CityId": "UPPA",
                                    "CountryId": "US",
                                    "RegionId": "HAWAI",
                                    "Location": "-155.8625, 20.268056"
                                }
                            ]
                        },
                        {
                            "Id": "UTOA",
                            "Name": "Birches",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.55667, 65.135",
                            "IataCode": "UTO",
                            "Airports": [
                                {
                                    "Id": "UTO",
                                    "Name": "Utopia Creek",
                                    "CityId": "UTOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.70167, 65.993333"
                                }
                            ]
                        },
                        {
                            "Id": "FLJA",
                            "Name": "Chenega Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-148.01139, 60.063333",
                            "IataCode": "FLJ",
                            "Airports": [
                                {
                                    "Id": "FLJ",
                                    "Name": "Falls Bay",
                                    "CityId": "FLJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148.00833, 60.0325"
                                }
                            ]
                        },
                        {
                            "Id": "BFBA",
                            "Name": "Chiniak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.1825, 57.631944",
                            "IataCode": "BFB",
                            "Airports": [
                                {
                                    "Id": "BFB",
                                    "Name": "Blue Fox Bay",
                                    "CityId": "BFBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.13333, 58.183333"
                                }
                            ]
                        },
                        {
                            "Id": "GMTA",
                            "Name": "Dime Landing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.15972, 65.12",
                            "IataCode": "GMT",
                            "Airports": [
                                {
                                    "Id": "GMT",
                                    "Name": "Granite Mountain",
                                    "CityId": "GMTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-161.2775, 65.405556"
                                }
                            ]
                        },
                        {
                            "Id": "DIOA",
                            "Name": "Diomede",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-168.94167, 65.736944",
                            "IataCode": "DIO",
                            "Airports": [
                                {
                                    "Id": "DIO",
                                    "Name": "Diomede Island",
                                    "CityId": "DIOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-168.95, 65.75"
                                }
                            ]
                        },
                        {
                            "Id": "SMUA",
                            "Name": "Eureka Roadhouse",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.16806, 61.938611",
                            "IataCode": "SMU",
                            "Airports": [
                                {
                                    "Id": "SMU",
                                    "Name": "Sheep Mountain",
                                    "CityId": "SMUA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.50667, 61.81194"
                                }
                            ]
                        },
                        {
                            "Id": "PPCA",
                            "Name": "Evansville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-151.50806, 66.922778",
                            "IataCode": "PPC",
                            "Airports": [
                                {
                                    "Id": "PPC",
                                    "Name": "Prospect Creek",
                                    "CityId": "PPCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.7, 66.796667"
                                }
                            ]
                        },
                        {
                            "Id": "KCRA",
                            "Name": "Folger",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "Location": "-156.08333, 63.561667",
                            "IataCode": "KCR",
                            "Airports": [
                                {
                                    "Id": "KCR",
                                    "Name": "Colorado Creek",
                                    "CityId": "KCRA",
                                    "CountryId": "US",
                                    "Location": "-156.00313, 63.590612"
                                }
                            ]
                        },
                        {
                            "Id": "NCNA",
                            "Name": "Golden",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.98611, 60.97",
                            "IataCode": "NCN",
                            "Airports": [
                                {
                                    "Id": "NCN",
                                    "Name": "New Chenega",
                                    "CityId": "NCNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-148, 60.669444"
                                }
                            ]
                        },
                        {
                            "Id": "EHMA",
                            "Name": "Goodnews Mining Camp",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-161.73, 58.926111",
                            "IataCode": "EHM",
                            "Airports": [
                                {
                                    "Id": "EHM",
                                    "Name": "Cape Newenham",
                                    "CityId": "EHMA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.06056, 58.647222"
                                }
                            ]
                        },
                        {
                            "Id": "FOXA",
                            "Name": "Harding Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.85083, 64.421667",
                            "IataCode": "FOX",
                            "Airports": [
                                {
                                    "Id": "FOX",
                                    "Name": "Fox",
                                    "CityId": "FOXA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147, 64"
                                }
                            ]
                        },
                        {
                            "Id": "HOLA",
                            "Name": "Holikachuk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.5175, 62.909722",
                            "IataCode": "HOL",
                            "Airports": [
                                {
                                    "Id": "HOL",
                                    "Name": "Holikachu",
                                    "CityId": "HOLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-159.65, 62.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CZFA",
                            "Name": "Igiak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-165.93361, 61.6175",
                            "IataCode": "CZF",
                            "Airports": [
                                {
                                    "Id": "CZF",
                                    "Name": "Cape Romanzof",
                                    "CityId": "CZFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.03639, 61.781111"
                                }
                            ]
                        },
                        {
                            "Id": "DCKA",
                            "Name": "Kalla",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.64389, 66.900278",
                            "IataCode": "DCK",
                            "Airports": [
                                {
                                    "Id": "DCK",
                                    "Name": "Dahl Creek",
                                    "CityId": "DCKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.9, 66.95"
                                }
                            ]
                        },
                        {
                            "Id": "RBHA",
                            "Name": "Kanatak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.03722, 57.569444",
                            "IataCode": "RBH",
                            "Airports": [
                                {
                                    "Id": "RBH",
                                    "Name": "Brooks Lodge",
                                    "CityId": "RBHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.77722, 58.55472"
                                }
                            ]
                        },
                        {
                            "Id": "BKFA",
                            "Name": "Kaskanak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.18333, 59.283333",
                            "IataCode": "BKF",
                            "Airports": [
                                {
                                    "Id": "BKF",
                                    "Name": "Brooks Lake",
                                    "CityId": "BKFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.23333, 58.6"
                                }
                            ]
                        },
                        {
                            "Id": "KGZA",
                            "Name": "Kennicott",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.88639, 61.486389",
                            "IataCode": "KGZ",
                            "Airports": [
                                {
                                    "Id": "KGZ",
                                    "Name": "Glacier Creek",
                                    "CityId": "KGZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.38333, 61.45"
                                }
                            ]
                        },
                        {
                            "Id": "NOLA",
                            "Name": "Klery Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.40306, 67.179444",
                            "IataCode": "NOL",
                            "Airports": [
                                {
                                    "Id": "NOL",
                                    "Name": "Nakolik River",
                                    "CityId": "NOLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.71667, 67.9"
                                }
                            ]
                        },
                        {
                            "Id": "BSZA",
                            "Name": "Koggiung",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.0075, 58.866944",
                            "IataCode": "BSZ",
                            "Airports": [
                                {
                                    "Id": "BSZ",
                                    "Name": "Bartletts",
                                    "CityId": "BSZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.35028, 58.233611"
                                }
                            ]
                        },
                        {
                            "Id": "OCIA",
                            "Name": "Latouche",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.89889, 60.051944",
                            "IataCode": "OCI",
                            "Airports": [
                                {
                                    "Id": "OCI",
                                    "Name": "Oceanic",
                                    "CityId": "OCIA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.86667, 60.25"
                                }
                            ]
                        },
                        {
                            "Id": "PNFA",
                            "Name": "Libbyville",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.05306, 58.777778",
                            "IataCode": "PNF",
                            "Airports": [
                                {
                                    "Id": "PNF",
                                    "Name": "Peterson's Point",
                                    "CityId": "PNFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.83333, 58.683333"
                                }
                            ]
                        },
                        {
                            "Id": "MCLA",
                            "Name": "Lignite",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-149.01833, 63.909444",
                            "IataCode": "MCL",
                            "Airports": [
                                {
                                    "Id": "MCL",
                                    "Name": "Mt Mckinley",
                                    "CityId": "MCLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-149.00639, 63.875972"
                                }
                            ]
                        },
                        {
                            "Id": "AOSA",
                            "Name": "McCord",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.20111, 57.139444",
                            "IataCode": "AOS",
                            "Airports": [
                                {
                                    "Id": "AOS",
                                    "Name": "Amook",
                                    "CityId": "AOSA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.83333, 57.466667"
                                }
                            ]
                        },
                        {
                            "Id": "FWLA",
                            "Name": "Medfra",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.71417, 63.106667",
                            "IataCode": "FWL",
                            "Airports": [
                                {
                                    "Id": "FWL",
                                    "Name": "Farewell",
                                    "CityId": "FWLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.88944, 62.509722"
                                }
                            ]
                        },
                        {
                            "Id": "RCPA",
                            "Name": "Meshik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.68194, 56.9125",
                            "IataCode": "RCP",
                            "Airports": [
                                {
                                    "Id": "RCP",
                                    "Name": "Cinder River",
                                    "CityId": "RCPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.80556, 57.083056"
                                }
                            ]
                        },
                        {
                            "Id": "CFAA",
                            "Name": "Nakeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.03861, 58.933889",
                            "IataCode": "CFA",
                            "Airports": [
                                {
                                    "Id": "CFA",
                                    "Name": "Coffee Point",
                                    "CityId": "CFAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.5, 58.216667"
                                }
                            ]
                        },
                        {
                            "Id": "PCKA",
                            "Name": "Nolan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-150.22972, 67.476667",
                            "IataCode": "PCK",
                            "Airports": [
                                {
                                    "Id": "PCK",
                                    "Name": "Porcupine Creek",
                                    "CityId": "PCKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-150.28333, 67.228889"
                                }
                            ]
                        },
                        {
                            "Id": "MDOA",
                            "Name": "Nuchek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-146.65528, 60.333333",
                            "IataCode": "MDO",
                            "Airports": [
                                {
                                    "Id": "MDO",
                                    "Name": "Middleton Island",
                                    "CityId": "MDOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-146.3, 59.453056"
                                }
                            ]
                        },
                        {
                            "Id": "UQEA",
                            "Name": "Nushagak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.49222, 58.948056",
                            "IataCode": "UQE",
                            "Airports": [
                                {
                                    "Id": "UQE",
                                    "Name": "Queen",
                                    "CityId": "UQEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.43167, 58.876667"
                                }
                            ]
                        },
                        {
                            "Id": "ISLB",
                            "Name": "Paxson",
                            "SingleAirportCity": false,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.61306, 63.089722",
                            "Airports": []
                        },
                        {
                            "Id": "KZBA",
                            "Name": "Port Hobron",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.15639, 57.160556",
                            "IataCode": "KZB",
                            "Airports": [
                                {
                                    "Id": "KZB",
                                    "Name": "Zachar Bay",
                                    "CityId": "KZBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.75, 57.55"
                                }
                            ]
                        },
                        {
                            "Id": "WSJA",
                            "Name": "Port O'Brien",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.31667, 57.731944",
                            "IataCode": "WSJ",
                            "Airports": [
                                {
                                    "Id": "WSJ",
                                    "Name": "San Juan",
                                    "CityId": "WSJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.31833, 57.731111"
                                }
                            ]
                        },
                        {
                            "Id": "KPYA",
                            "Name": "Port Wakefield",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.0525, 58.049167",
                            "IataCode": "KPY",
                            "Airports": [
                                {
                                    "Id": "KPY",
                                    "Name": "Port Bailey",
                                    "CityId": "KPYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.03333, 57.933333"
                                }
                            ]
                        },
                        {
                            "Id": "WRHA",
                            "Name": "Red Dog Mine",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.85611, 68.075556",
                            "IataCode": "WRH",
                            "Airports": [
                                {
                                    "Id": "WRH",
                                    "Name": "Wrench Creek",
                                    "CityId": "WRHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.28333, 68.25"
                                }
                            ]
                        },
                        {
                            "Id": "KPHA",
                            "Name": "Sanak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.8175, 54.478056",
                            "IataCode": "KPH",
                            "Airports": [
                                {
                                    "Id": "KPH",
                                    "Name": "Pauloff Harbor",
                                    "CityId": "KPHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.68333, 54.45"
                                }
                            ]
                        },
                        {
                            "Id": "PFAA",
                            "Name": "Savonoski",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.86389, 58.717222",
                            "IataCode": "PFA",
                            "Airports": [
                                {
                                    "Id": "PFA",
                                    "Name": "Paf Warren",
                                    "CityId": "PFAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.83333, 58.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KKFA",
                            "Name": "Sheshalik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.82806, 66.994167",
                            "IataCode": "KKF",
                            "Airports": [
                                {
                                    "Id": "KKF",
                                    "Name": "Kagvik Creek",
                                    "CityId": "KKFA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-163.5, 67.6"
                                }
                            ]
                        },
                        {
                            "Id": "FTLA",
                            "Name": "Takshak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-162.17694, 61.957778",
                            "IataCode": "FTL",
                            "Airports": [
                                {
                                    "Id": "FTL",
                                    "Name": "Fortuna Ledge",
                                    "CityId": "FTLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-162.08333, 61.883333"
                                }
                            ]
                        },
                        {
                            "Id": "GFBA",
                            "Name": "Ungalikthluk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-160.14528, 58.935278",
                            "IataCode": "GFB",
                            "Airports": [
                                {
                                    "Id": "GFB",
                                    "Name": "Togiak Fish",
                                    "CityId": "GFBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-160.05, 59.016667"
                                }
                            ]
                        },
                        {
                            "Id": "KMYA",
                            "Name": "Uyak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.99944, 57.637778",
                            "IataCode": "KMY",
                            "Airports": [
                                {
                                    "Id": "KMY",
                                    "Name": "Moser Bay",
                                    "CityId": "KMYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.14583, 57.02556"
                                }
                            ]
                        },
                        {
                            "Id": "KBCA",
                            "Name": "Venetie Landing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.8, 66.266667",
                            "IataCode": "KBC",
                            "Airports": [
                                {
                                    "Id": "KBC",
                                    "Name": "Birch Creek",
                                    "CityId": "KBCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-145.8, 66.266667"
                                }
                            ]
                        },
                        {
                            "Id": "LURA",
                            "Name": "Wevok",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.10694, 68.875833",
                            "IataCode": "LUR",
                            "Airports": [
                                {
                                    "Id": "LUR",
                                    "Name": "Cape Lisburne",
                                    "CityId": "LURA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.10694, 68.875833"
                                }
                            ]
                        },
                        {
                            "Id": "SVWA",
                            "Name": "Whitefish Lake",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.025, 60.933333",
                            "IataCode": "SVW",
                            "Airports": [
                                {
                                    "Id": "SVW",
                                    "Name": "Sparrevohn",
                                    "CityId": "SVWA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.57222, 61.0975"
                                }
                            ]
                        },
                        {
                            "Id": "PTRA",
                            "Name": "Womens Bay",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.66861, 57.682222",
                            "IataCode": "PTR",
                            "Airports": [
                                {
                                    "Id": "PTR",
                                    "Name": "Pleasant Harbour",
                                    "CityId": "PTRA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.83333, 57.75"
                                }
                            ]
                        },
                        {
                            "Id": "KTHA",
                            "Name": "Wood River",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.44111, 59.069167",
                            "IataCode": "KTH",
                            "Airports": [
                                {
                                    "Id": "KTH",
                                    "Name": "Tikchik",
                                    "CityId": "KTHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.33333, 59.966667"
                                }
                            ]
                        },
                        {
                            "Id": "KKKA",
                            "Name": "Yistletaw",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-157.3725, 64.8225",
                            "IataCode": "KKK",
                            "Airports": [
                                {
                                    "Id": "KKK",
                                    "Name": "Kalakaket",
                                    "CityId": "KKKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-156.84111, 64.425"
                                }
                            ]
                        },
                        {
                            "Id": "BICA",
                            "Name": "Kvichak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-156.93056, 58.969444",
                            "IataCode": "BIC",
                            "Airports": [
                                {
                                    "Id": "BIC",
                                    "Name": "Big Creek",
                                    "CityId": "BICA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-157.51667, 58.3"
                                }
                            ]
                        },
                        {
                            "Id": "ONNA",
                            "Name": "Afognak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.76806, 58.007778",
                            "IataCode": "ONN",
                            "Airports": [
                                {
                                    "Id": "ONN",
                                    "Name": "Onion Bay",
                                    "CityId": "ONNA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.83333, 58.233333"
                                }
                            ]
                        },
                        {
                            "Id": "PTAA",
                            "Name": "Alaganik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-145.21583, 60.444167",
                            "IataCode": "PTA",
                            "Airports": [
                                {
                                    "Id": "PTA",
                                    "Name": "Port Alsworth",
                                    "CityId": "PTAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.31861, 60.20417"
                                }
                            ]
                        },
                        {
                            "Id": "DGBA",
                            "Name": "Aleut Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.7655, 58.0178",
                            "IataCode": "DGB",
                            "Airports": [
                                {
                                    "Id": "DGB",
                                    "Name": "Danger Bay",
                                    "CityId": "DGBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.75, 58.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SYBA",
                            "Name": "Aleksashkina",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.35528, 57.78",
                            "IataCode": "SYB",
                            "Airports": [
                                {
                                    "Id": "SYB",
                                    "Name": "Seal Bay",
                                    "CityId": "SYBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.5, 58.166667"
                                }
                            ]
                        },
                        {
                            "Id": "BVDA",
                            "Name": "Biorka",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-166.20806, 53.826944",
                            "IataCode": "BVD",
                            "Airports": [
                                {
                                    "Id": "BVD",
                                    "Name": "Beaver Inlet",
                                    "CityId": "BVDA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-166.31667, 53.733333"
                                }
                            ]
                        },
                        {
                            "Id": "FAFA",
                            "Name": "Bradmere",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "VIRGI",
                            "Location": "-76.569444, 37.169722",
                            "IataCode": "FAF",
                            "Airports": [
                                {
                                    "Id": "FAF",
                                    "Name": "Fort Eustis",
                                    "CityId": "FAFA",
                                    "CountryId": "US",
                                    "RegionId": "VIRGI",
                                    "Location": "-76.51217, 37.1432"
                                }
                            ]
                        },
                        {
                            "Id": "VCBA",
                            "Name": "Howkan",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-132.80333, 54.87111",
                            "IataCode": "VCB",
                            "Airports": [
                                {
                                    "Id": "VCB",
                                    "Name": "View Cove",
                                    "CityId": "VCBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.01667, 55.083333"
                                }
                            ]
                        },
                        {
                            "Id": "HDAA",
                            "Name": "Neltushkin",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.60583, 57.25861",
                            "IataCode": "HDA",
                            "Airports": [
                                {
                                    "Id": "HDA",
                                    "Name": "Hidden Falls",
                                    "CityId": "HDAA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134.85, 57.2"
                                }
                            ]
                        },
                        {
                            "Id": "NKVA",
                            "Name": "New Tokeen",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.32417, 55.93639",
                            "IataCode": "NKV",
                            "Airports": [
                                {
                                    "Id": "NKV",
                                    "Name": "Nichen Cove",
                                    "CityId": "NKVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.21667, 55.85"
                                }
                            ]
                        },
                        {
                            "Id": "TKLA",
                            "Name": "Taku Harbor",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-134.00972, 58.06972",
                            "IataCode": "TKL",
                            "Airports": [
                                {
                                    "Id": "TKL",
                                    "Name": "Taku Lodge",
                                    "CityId": "TKLA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-134, 58"
                                }
                            ]
                        },
                        {
                            "Id": "FIVA",
                            "Name": "Cape Fanshaw",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-133.50194, 57.21278",
                            "IataCode": "FIV",
                            "Airports": [
                                {
                                    "Id": "FIV",
                                    "Name": "Five Finger",
                                    "CityId": "FIVA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-133.63333, 57.266667"
                                }
                            ]
                        },
                        {
                            "Id": "WYBA",
                            "Name": "Carlanna",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-131.68333, 55.35833",
                            "IataCode": "WYB",
                            "Airports": [
                                {
                                    "Id": "WYB",
                                    "Name": "Yes Bay",
                                    "CityId": "WYBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-131.83333, 55.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CYTA",
                            "Name": "Cape Yakataga",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-142.43083, 60.06639",
                            "IataCode": "CYT",
                            "Airports": [
                                {
                                    "Id": "CYT",
                                    "Name": "Yakataga",
                                    "CityId": "CYTA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-142.49444, 60.08"
                                }
                            ]
                        },
                        {
                            "Id": "KKBA",
                            "Name": "Duck Village",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.5, 58.166667",
                            "IataCode": "KKB",
                            "Airports": [
                                {
                                    "Id": "KKB",
                                    "Name": "Kitoi Bay",
                                    "CityId": "KKBA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-152.35, 58.183333"
                                }
                            ]
                        },
                        {
                            "Id": "ALZA",
                            "Name": "Kaguyak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-153.79552, 56.86893",
                            "IataCode": "ALZ",
                            "Airports": [
                                {
                                    "Id": "ALZ",
                                    "Name": "Alitak",
                                    "CityId": "ALZA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.24583, 56.895833"
                                }
                            ]
                        },
                        {
                            "Id": "RSPA",
                            "Name": "Kukak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.1875, 58.31778",
                            "IataCode": "RSP",
                            "Airports": [
                                {
                                    "Id": "RSP",
                                    "Name": "Raspberry Strait",
                                    "CityId": "RSPA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.83333, 58.166667"
                                }
                            ]
                        },
                        {
                            "Id": "OHCA",
                            "Name": "Sooghmeghat",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-169.40639, 63.35111",
                            "IataCode": "OHC",
                            "Airports": [
                                {
                                    "Id": "OHC",
                                    "Name": "Northeast Cape",
                                    "CityId": "OHCA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-168.96889, 63.326389"
                                }
                            ]
                        },
                        {
                            "Id": "TLJA",
                            "Name": "Vinasale",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-155.71028, 62.63722",
                            "IataCode": "TLJ",
                            "Airports": [
                                {
                                    "Id": "TLJ",
                                    "Name": "Tatalina",
                                    "CityId": "TLJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-155.96806, 62.885278"
                                }
                            ]
                        },
                        {
                            "Id": "WOOA",
                            "Name": "Woodchopper Creek",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-143.41194, 65.29389",
                            "IataCode": "WOO",
                            "Airports": [
                                {
                                    "Id": "WOO",
                                    "Name": "Woodchopper",
                                    "CityId": "WOOA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-143.53333, 65.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LKKA",
                            "Name": "Yacherk",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-159.21833, 59.04472",
                            "IataCode": "LKK",
                            "Airports": [
                                {
                                    "Id": "LKK",
                                    "Name": "Kulik Lake",
                                    "CityId": "LKKA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.83333, 59.816667"
                                }
                            ]
                        },
                        {
                            "Id": "SKJA",
                            "Name": "Aiaktalik",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.115, 56.70694",
                            "IataCode": "SKJ",
                            "Airports": [
                                {
                                    "Id": "SKJ",
                                    "Name": "Sitkinak Island",
                                    "CityId": "SKJA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.11389, 56.532778"
                                }
                            ]
                        },
                        {
                            "Id": "GDHA",
                            "Name": "Anugnak",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-158.5, 59.13333",
                            "IataCode": "GDH",
                            "Airports": [
                                {
                                    "Id": "GDH",
                                    "Name": "Golden Horn",
                                    "CityId": "GDHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-158.16667, 59.716667"
                                }
                            ]
                        },
                        {
                            "Id": "CLCA",
                            "Name": "Camino South",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "TEXAS",
                            "Location": "-95.1305, 29.5573",
                            "IataCode": "CLC",
                            "Airports": [
                                {
                                    "Id": "CLC",
                                    "Name": "Clearlake",
                                    "CityId": "CLCA",
                                    "CountryId": "US",
                                    "RegionId": "TEXAS",
                                    "Location": "-95.1375, 29.556944"
                                }
                            ]
                        },
                        {
                            "Id": "FRIA",
                            "Name": "Fort Riley-Camp Whiteside",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "KANSA",
                            "Location": "-96.77336, 39.08383",
                            "IataCode": "FRI",
                            "Airports": [
                                {
                                    "Id": "FRI",
                                    "Name": "Fort Riley",
                                    "CityId": "FRIA",
                                    "CountryId": "US",
                                    "RegionId": "KANSA",
                                    "Location": "-96.783333, 39.083333"
                                }
                            ]
                        },
                        {
                            "Id": "HNEA",
                            "Name": "Glacier View",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-147.50484, 61.90748",
                            "IataCode": "HNE",
                            "Airports": [
                                {
                                    "Id": "HNE",
                                    "Name": "Tahneta Pass Lodge",
                                    "CityId": "HNEA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-147.33333, 61.883333"
                                }
                            ]
                        },
                        {
                            "Id": "KZHA",
                            "Name": "Kodiak Station",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-152.60004, 57.76587",
                            "IataCode": "KZH",
                            "Airports": [
                                {
                                    "Id": "KZH",
                                    "Name": "Kizhuyak",
                                    "CityId": "KZHA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-153.15, 57.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PVYA",
                            "Name": "Pope-Vannoy Landing",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "ALASK",
                            "Location": "-154.49109, 59.55848",
                            "IataCode": "PVY",
                            "Airports": [
                                {
                                    "Id": "PVY",
                                    "Name": "Pope Vanoy",
                                    "CityId": "PVYA",
                                    "CountryId": "US",
                                    "RegionId": "ALASK",
                                    "Location": "-154.91667, 59.316667"
                                }
                            ]
                        },
                        {
                            "Id": "NBUA",
                            "Name": "Glenwood Spgs",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "COLOR",
                            "Location": "-107.32903, 39.55232",
                            "IataCode": "NBU",
                            "Airports": [
                                {
                                    "Id": "NBU",
                                    "Name": "Glenview",
                                    "CityId": "NBUA",
                                    "CountryId": "US",
                                    "RegionId": "COLOR",
                                    "Location": "-107.31667, 39.55"
                                }
                            ]
                        },
                        {
                            "Id": "BFGA",
                            "Name": "Lake Powell",
                            "SingleAirportCity": true,
                            "CountryId": "US",
                            "RegionId": "UTAH_",
                            "Location": "-110.72883, 37.51861",
                            "IataCode": "BFG",
                            "Airports": [
                                {
                                    "Id": "BFG",
                                    "Name": "Bullfrog Basin",
                                    "CityId": "BFGA",
                                    "CountryId": "US",
                                    "RegionId": "UTAH_",
                                    "Location": "-110.71333, 37.545833"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PM",
                    "Name": "St. Pierre and Miquelon",
                    "CurrencyId": "EUR",
                    "LanguageId": "FR",
                    "Cities": [
                        {
                            "Id": "MQCA",
                            "Name": "Miquelon",
                            "SingleAirportCity": true,
                            "CountryId": "PM",
                            "Location": "-56.381389, 47.0975",
                            "IataCode": "MQC",
                            "Airports": [
                                {
                                    "Id": "MQC",
                                    "Name": "Miquelon",
                                    "CityId": "MQCA",
                                    "CountryId": "PM",
                                    "Location": "-56.333333, 47.05"
                                }
                            ]
                        },
                        {
                            "Id": "FSPA",
                            "Name": "St Pierre",
                            "SingleAirportCity": true,
                            "CountryId": "PM",
                            "Location": "-56.183333, 46.766667",
                            "IataCode": "FSP",
                            "Airports": [
                                {
                                    "Id": "FSP",
                                    "Name": "St Pierre",
                                    "CityId": "FSPA",
                                    "CountryId": "PM",
                                    "Location": "-56.200562, 46.786897"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "BL",
                    "Name": "Saint Barthelemy",
                    "CurrencyId": "EUR",
                    "LanguageId": "fr",
                    "Cities": [
                        {
                            "Id": "SBHA",
                            "Name": "St Barthelemy",
                            "SingleAirportCity": true,
                            "CountryId": "BL",
                            "Location": "-62.833333, 17.9",
                            "IataCode": "SBH",
                            "Airports": [
                                {
                                    "Id": "SBH",
                                    "Name": "St Barthelemy",
                                    "CityId": "SBHA",
                                    "CountryId": "BL",
                                    "Location": "-62.85, 17.9"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "Id": "O",
            "Name": "Oceania",
            "Countries": [
                {
                    "Id": "TL",
                    "Name": "Timor-Leste",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "DILA",
                            "Name": "Dili",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "125.57361, -8.5586111",
                            "IataCode": "DIL",
                            "Airports": [
                                {
                                    "Id": "DIL",
                                    "Name": "Dili",
                                    "CityId": "DILA",
                                    "CountryId": "TL",
                                    "Location": "125.52472, -8.546667"
                                }
                            ]
                        },
                        {
                            "Id": "VIQA",
                            "Name": "Viqueque",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "126.36472, -8.8575",
                            "IataCode": "VIQ",
                            "Airports": [
                                {
                                    "Id": "VIQ",
                                    "Name": "Viqueque",
                                    "CityId": "VIQA",
                                    "CountryId": "TL",
                                    "Location": "126.36667, -8.883333"
                                }
                            ]
                        },
                        {
                            "Id": "BCHA",
                            "Name": "Baucau",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "126.45833, -8.4711111",
                            "IataCode": "BCH",
                            "Airports": [
                                {
                                    "Id": "BCH",
                                    "Name": "Baucau",
                                    "CityId": "BCHA",
                                    "CountryId": "TL",
                                    "Location": "126.39972, -8.491667"
                                }
                            ]
                        },
                        {
                            "Id": "UAIA",
                            "Name": "Suai",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "125.25556, -9.3125",
                            "IataCode": "UAI",
                            "Airports": [
                                {
                                    "Id": "UAI",
                                    "Name": "Suai",
                                    "CityId": "UAIA",
                                    "CountryId": "TL",
                                    "Location": "125.28333, -9.3"
                                }
                            ]
                        },
                        {
                            "Id": "MPTA",
                            "Name": "Maliana",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "125.21972, -8.9916667",
                            "IataCode": "MPT",
                            "Airports": [
                                {
                                    "Id": "MPT",
                                    "Name": "Maliana",
                                    "CityId": "MPTA",
                                    "CountryId": "TL",
                                    "Location": "125.25, -8.066667"
                                }
                            ]
                        },
                        {
                            "Id": "OECA",
                            "Name": "Ocussi",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "124.28718, -9.3151578",
                            "IataCode": "OEC",
                            "Airports": [
                                {
                                    "Id": "OEC",
                                    "Name": "Ocussi",
                                    "CityId": "OECA",
                                    "CountryId": "TL",
                                    "Location": "124.34167, -9.205"
                                }
                            ]
                        },
                        {
                            "Id": "KCII",
                            "Name": "Kon",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "127.05, -8.35",
                            "IataCode": "KCI",
                            "Airports": [
                                {
                                    "Id": "KCI",
                                    "Name": "Kon",
                                    "CityId": "KCII",
                                    "CountryId": "TL",
                                    "Location": "127.05, -8.35"
                                }
                            ]
                        },
                        {
                            "Id": "AUTI",
                            "Name": "Atauro",
                            "SingleAirportCity": true,
                            "CountryId": "TL",
                            "Location": "125.60139, -8.2666667",
                            "IataCode": "AUT",
                            "Airports": [
                                {
                                    "Id": "AUT",
                                    "Name": "Atauro",
                                    "CityId": "AUTI",
                                    "CountryId": "TL",
                                    "Location": "125.58333, -8.216667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PF",
                    "Name": "French Polynesia",
                    "CurrencyId": "XPF",
                    "Cities": [
                        {
                            "Id": "PPTP",
                            "Name": "Papeete",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-149.56667, -17.533333",
                            "IataCode": "PPT",
                            "Airports": [
                                {
                                    "Id": "PPT",
                                    "Name": "Papeete",
                                    "CityId": "PPTP",
                                    "CountryId": "PF",
                                    "Location": "-149.60724, -17.55375"
                                }
                            ]
                        },
                        {
                            "Id": "AUQP",
                            "Name": "Atuona",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-139.03333, -9.8",
                            "IataCode": "AUQ",
                            "Airports": [
                                {
                                    "Id": "AUQ",
                                    "Name": "Atuona",
                                    "CityId": "AUQP",
                                    "CountryId": "PF",
                                    "Location": "-139.01667, -9.766667"
                                }
                            ]
                        },
                        {
                            "Id": "RVVP",
                            "Name": "Rairua",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-147.68333, -23.866667",
                            "IataCode": "RVV",
                            "Airports": [
                                {
                                    "Id": "RVV",
                                    "Name": "Rairua",
                                    "CityId": "RVVP",
                                    "CountryId": "PF",
                                    "Location": "-147.65, -25.883333"
                                }
                            ]
                        },
                        {
                            "Id": "TTIP",
                            "Name": "Tetiaroa Is",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-149.55911, -17.012879",
                            "IataCode": "TTI",
                            "Airports": [
                                {
                                    "Id": "TTI",
                                    "Name": "Tetiaroa Is",
                                    "CityId": "TTIP",
                                    "CountryId": "PF",
                                    "Location": "-149.5, -17.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MOZP",
                            "Name": "Moorea",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-149.83165, -17.523125",
                            "IataCode": "MOZ",
                            "Airports": [
                                {
                                    "Id": "MOZ",
                                    "Name": "Moorea",
                                    "CityId": "MOZP",
                                    "CountryId": "PF",
                                    "Location": "-149.76667, -17.483333"
                                }
                            ]
                        },
                        {
                            "Id": "HUHP",
                            "Name": "Huahine",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-151.00169, -16.755475",
                            "IataCode": "HUH",
                            "Airports": [
                                {
                                    "Id": "HUH",
                                    "Name": "Huahine",
                                    "CityId": "HUHP",
                                    "CountryId": "PF",
                                    "Location": "-151.02167, -16.687222"
                                }
                            ]
                        },
                        {
                            "Id": "RFPP",
                            "Name": "Raiatea",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-151.42826, -16.824322",
                            "IataCode": "RFP",
                            "Airports": [
                                {
                                    "Id": "RFP",
                                    "Name": "Raiatea",
                                    "CityId": "RFPP",
                                    "CountryId": "PF",
                                    "Location": "-151.46667, -16.716667"
                                }
                            ]
                        },
                        {
                            "Id": "BOBP",
                            "Name": "Bora Bora",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-151.74534, -16.501511",
                            "IataCode": "BOB",
                            "Airports": [
                                {
                                    "Id": "BOB",
                                    "Name": "Bora Bora",
                                    "CityId": "BOBP",
                                    "CountryId": "PF",
                                    "Location": "-151.74833, -16.443056"
                                }
                            ]
                        },
                        {
                            "Id": "TPXP",
                            "Name": "Tupai",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-151.81737, -16.266517",
                            "IataCode": "TPX",
                            "Airports": [
                                {
                                    "Id": "TPX",
                                    "Name": "Tupai",
                                    "CityId": "TPXP",
                                    "CountryId": "PF",
                                    "Location": "-151.83333, -16.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MAUP",
                            "Name": "Maupiti",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-152.26284, -16.450525",
                            "IataCode": "MAU",
                            "Airports": [
                                {
                                    "Id": "MAU",
                                    "Name": "Maupiti",
                                    "CityId": "MAUP",
                                    "CountryId": "PF",
                                    "Location": "-152.25, -16.433333"
                                }
                            ]
                        },
                        {
                            "Id": "AHEP",
                            "Name": "Ahe",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.317, -14.483",
                            "IataCode": "AHE",
                            "Airports": [
                                {
                                    "Id": "AHE",
                                    "Name": "Ahe",
                                    "CityId": "AHEP",
                                    "CountryId": "PF",
                                    "Location": "-146.25694, -14.428056"
                                }
                            ]
                        },
                        {
                            "Id": "XMHA",
                            "Name": "Manihi",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.072, -14.442",
                            "IataCode": "XMH",
                            "Airports": [
                                {
                                    "Id": "XMH",
                                    "Name": "Manihi",
                                    "CityId": "XMHA",
                                    "CountryId": "PF",
                                    "Location": "-146.06667, -14.433333"
                                }
                            ]
                        },
                        {
                            "Id": "TKXP",
                            "Name": "Takaroa",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-144.983, -14.45",
                            "IataCode": "TKX",
                            "Airports": [
                                {
                                    "Id": "TKX",
                                    "Name": "Takaroa",
                                    "CityId": "TKXP",
                                    "CountryId": "PF",
                                    "Location": "-144.96667, -14.5"
                                }
                            ]
                        },
                        {
                            "Id": "TKPP",
                            "Name": "Takapoto",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.2, -14.617",
                            "IataCode": "TKP",
                            "Airports": [
                                {
                                    "Id": "TKP",
                                    "Name": "Takapoto",
                                    "CityId": "TKPP",
                                    "CountryId": "PF",
                                    "Location": "-145.246, -14.7095"
                                }
                            ]
                        },
                        {
                            "Id": "FAVP",
                            "Name": "Fakarava",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.65847, -16.051483",
                            "IataCode": "FAV",
                            "Airports": [
                                {
                                    "Id": "FAV",
                                    "Name": "Fakarava",
                                    "CityId": "FAVP",
                                    "CountryId": "PF",
                                    "Location": "-145.657, -16.0541"
                                }
                            ]
                        },
                        {
                            "Id": "NIUA",
                            "Name": "Niau",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.35567, -16.15417",
                            "IataCode": "NIU",
                            "Airports": [
                                {
                                    "Id": "NIU",
                                    "Name": "Niau",
                                    "CityId": "NIUA",
                                    "CountryId": "PF",
                                    "Location": "-146.36673, -16.13422"
                                }
                            ]
                        },
                        {
                            "Id": "AXRP",
                            "Name": "Arutua",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.61209, -15.245236",
                            "IataCode": "AXR",
                            "Airports": [
                                {
                                    "Id": "AXR",
                                    "Name": "Arutua",
                                    "CityId": "AXRP",
                                    "CountryId": "PF",
                                    "Location": "-146.75, -15.25"
                                }
                            ]
                        },
                        {
                            "Id": "APKP",
                            "Name": "Apataki",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.317, -15.45",
                            "IataCode": "APK",
                            "Airports": [
                                {
                                    "Id": "APK",
                                    "Name": "Apataki",
                                    "CityId": "APKP",
                                    "CountryId": "PF",
                                    "Location": "-146.41499, -15.5736"
                                }
                            ]
                        },
                        {
                            "Id": "KKRP",
                            "Name": "Kaukura Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-146.883, -15.65",
                            "IataCode": "KKR",
                            "Airports": [
                                {
                                    "Id": "KKR",
                                    "Name": "Kaukura Atoll",
                                    "CityId": "KKRP",
                                    "CountryId": "PF",
                                    "Location": "-146.88499, -15.6633"
                                }
                            ]
                        },
                        {
                            "Id": "MVTP",
                            "Name": "Mataiva",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-148.717, -14.883",
                            "IataCode": "MVT",
                            "Airports": [
                                {
                                    "Id": "MVT",
                                    "Name": "Mataiva",
                                    "CityId": "MVTP",
                                    "CountryId": "PF",
                                    "Location": "-148.717, -14.8681"
                                }
                            ]
                        },
                        {
                            "Id": "TIHP",
                            "Name": "Tikehau Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-148.217, -15.117",
                            "IataCode": "TIH",
                            "Airports": [
                                {
                                    "Id": "TIH",
                                    "Name": "Tikehau Atoll",
                                    "CityId": "TIHP",
                                    "CountryId": "PF",
                                    "Location": "-147.75, -15"
                                }
                            ]
                        },
                        {
                            "Id": "RGIP",
                            "Name": "Rangiroa",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-147.64528, -15.12528",
                            "IataCode": "RGI",
                            "Airports": [
                                {
                                    "Id": "RGI",
                                    "Name": "Rangiroa",
                                    "CityId": "RGIP",
                                    "CountryId": "PF",
                                    "Location": "-147.6608, -14.95428"
                                }
                            ]
                        },
                        {
                            "Id": "AAAP",
                            "Name": "Anaa",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.5, -17.333",
                            "IataCode": "AAA",
                            "Airports": [
                                {
                                    "Id": "AAA",
                                    "Name": "Anaa",
                                    "CityId": "AAAP",
                                    "CountryId": "PF",
                                    "Location": "-145.50996, -17.352606"
                                }
                            ]
                        },
                        {
                            "Id": "FACP",
                            "Name": "Faaite",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.317, -16.717",
                            "IataCode": "FAC",
                            "Airports": [
                                {
                                    "Id": "FAC",
                                    "Name": "Faaite",
                                    "CityId": "FACP",
                                    "CountryId": "PF",
                                    "Location": "-145.33333, -16.683333"
                                }
                            ]
                        },
                        {
                            "Id": "TJNP",
                            "Name": "Takume",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-142.2, -15.867",
                            "IataCode": "TJN",
                            "Airports": [
                                {
                                    "Id": "TJN",
                                    "Name": "Takume",
                                    "CityId": "TJNP",
                                    "CountryId": "PF",
                                    "Location": "-142.26694, -15.850556"
                                }
                            ]
                        },
                        {
                            "Id": "RRRA",
                            "Name": "Raroia",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-142.433, -16.017",
                            "IataCode": "RRR",
                            "Airports": [
                                {
                                    "Id": "RRR",
                                    "Name": "Raroia",
                                    "CityId": "RRRA",
                                    "CountryId": "PF",
                                    "Location": "-142.48581, -16.06297"
                                }
                            ]
                        },
                        {
                            "Id": "MKPP",
                            "Name": "Makemo",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-143.66126, -16.598525",
                            "IataCode": "MKP",
                            "Airports": [
                                {
                                    "Id": "MKP",
                                    "Name": "Makemo",
                                    "CityId": "MKPP",
                                    "CountryId": "PF",
                                    "Location": "-143.66667, -16.45"
                                }
                            ]
                        },
                        {
                            "Id": "RKAA",
                            "Name": "Aratika",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.5, -15.483",
                            "IataCode": "RKA",
                            "Airports": [
                                {
                                    "Id": "RKA",
                                    "Name": "Aratika",
                                    "CityId": "RKAA",
                                    "CountryId": "PF",
                                    "Location": "-145.46667, -15.4833"
                                }
                            ]
                        },
                        {
                            "Id": "KHZP",
                            "Name": "Kauehi",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-145.133, -15.867",
                            "IataCode": "KHZ",
                            "Airports": [
                                {
                                    "Id": "KHZ",
                                    "Name": "Kauehi",
                                    "CityId": "KHZP",
                                    "CountryId": "PF",
                                    "Location": "-145.11667, -15.766667"
                                }
                            ]
                        },
                        {
                            "Id": "NKPP",
                            "Name": "Nukutepipi",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-143.067, -20.683",
                            "IataCode": "NKP",
                            "Airports": [
                                {
                                    "Id": "NKP",
                                    "Name": "Nukutepipi",
                                    "CityId": "NKPP",
                                    "CountryId": "PF",
                                    "Location": "-143.04484, -20.7006"
                                }
                            ]
                        },
                        {
                            "Id": "HOIP",
                            "Name": "Hao Island",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-140.94528, -18.075278",
                            "IataCode": "HOI",
                            "Airports": [
                                {
                                    "Id": "HOI",
                                    "Name": "Hao Island",
                                    "CityId": "HOIP",
                                    "CountryId": "PF",
                                    "Location": "-140.95, -18.15"
                                }
                            ]
                        },
                        {
                            "Id": "HHZP",
                            "Name": "Hikueru",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-142.61304, -17.544819",
                            "IataCode": "HHZ",
                            "Airports": [
                                {
                                    "Id": "HHZ",
                                    "Name": "Hikueru",
                                    "CityId": "HHZP",
                                    "CountryId": "PF",
                                    "Location": "-142.53333, -17.533333"
                                }
                            ]
                        },
                        {
                            "Id": "FGUP",
                            "Name": "Fangatau",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-140.88715, -15.819928",
                            "IataCode": "FGU",
                            "Airports": [
                                {
                                    "Id": "FGU",
                                    "Name": "Fangatau",
                                    "CityId": "FGUP",
                                    "CountryId": "PF",
                                    "Location": "-140.88699, -15.8199"
                                }
                            ]
                        },
                        {
                            "Id": "FHZP",
                            "Name": "Fakahina",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-140.183, -15.983",
                            "IataCode": "FHZ",
                            "Airports": [
                                {
                                    "Id": "FHZ",
                                    "Name": "Fakahina",
                                    "CityId": "FHZP",
                                    "CountryId": "PF",
                                    "Location": "-140.08333, -16"
                                }
                            ]
                        },
                        {
                            "Id": "TKVP",
                            "Name": "Tatakoto",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.40131, -17.35175",
                            "IataCode": "TKV",
                            "Airports": [
                                {
                                    "Id": "TKV",
                                    "Name": "Tatakoto",
                                    "CityId": "TKVP",
                                    "CountryId": "PF",
                                    "Location": "-138.33333, -17.283333"
                                }
                            ]
                        },
                        {
                            "Id": "VHZP",
                            "Name": "Vahitahi",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.833, -18.783",
                            "IataCode": "VHZ",
                            "Airports": [
                                {
                                    "Id": "VHZ",
                                    "Name": "Vahitahi",
                                    "CityId": "VHZP",
                                    "CountryId": "PF",
                                    "Location": "-138.853, -18.78"
                                }
                            ]
                        },
                        {
                            "Id": "NUKP",
                            "Name": "Nukutavake",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.767, -19.267",
                            "IataCode": "NUK",
                            "Airports": [
                                {
                                    "Id": "NUK",
                                    "Name": "Nukutavake",
                                    "CityId": "NUKP",
                                    "CountryId": "PF",
                                    "Location": "-138.7, -19.183333"
                                }
                            ]
                        },
                        {
                            "Id": "REAP",
                            "Name": "Reao",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-136.44258, -18.469711",
                            "IataCode": "REA",
                            "Airports": [
                                {
                                    "Id": "REA",
                                    "Name": "Reao",
                                    "CityId": "REAP",
                                    "CountryId": "PF",
                                    "Location": "-136.4, -18.5"
                                }
                            ]
                        },
                        {
                            "Id": "PUKP",
                            "Name": "Pukarua",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-137.017, -18.317",
                            "IataCode": "PUK",
                            "Airports": [
                                {
                                    "Id": "PUK",
                                    "Name": "Pukarua",
                                    "CityId": "PUKP",
                                    "CountryId": "PF",
                                    "Location": "-137, -18.266667"
                                }
                            ]
                        },
                        {
                            "Id": "ZTAA",
                            "Name": "Tureia",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.51122, -20.876183",
                            "IataCode": "ZTA",
                            "Airports": [
                                {
                                    "Id": "ZTA",
                                    "Name": "Tureia",
                                    "CityId": "ZTAA",
                                    "CountryId": "PF",
                                    "Location": "-138.50464, -20.816457"
                                }
                            ]
                        },
                        {
                            "Id": "UOAA",
                            "Name": "Moruroa Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.917, -21.833",
                            "IataCode": "UOA",
                            "Airports": [
                                {
                                    "Id": "UOA",
                                    "Name": "Moruroa Atoll",
                                    "CityId": "UOAA",
                                    "CountryId": "PF",
                                    "Location": "-138.81305, -21.809443"
                                }
                            ]
                        },
                        {
                            "Id": "GMRP",
                            "Name": "Gambier Is",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-134.85639, -23.177",
                            "IataCode": "GMR",
                            "Airports": [
                                {
                                    "Id": "GMR",
                                    "Name": "Gambier Is",
                                    "CityId": "GMRP",
                                    "CountryId": "PF",
                                    "Location": "-135, -23.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TUBP",
                            "Name": "Austral Islands",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-148.70561, -24.449368",
                            "IataCode": "TUB",
                            "Airports": [
                                {
                                    "Id": "TUB",
                                    "Name": "Tubuai",
                                    "CityId": "TUBP",
                                    "CountryId": "PF",
                                    "Location": "-149.46667, -23.35"
                                }
                            ]
                        },
                        {
                            "Id": "RMTA",
                            "Name": "Rimatara",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-152.82, -22.65",
                            "IataCode": "RMT",
                            "Airports": [
                                {
                                    "Id": "RMT",
                                    "Name": "Rimatara",
                                    "CityId": "RMTA",
                                    "CountryId": "PF",
                                    "Location": "-152.85416, -22.63556"
                                }
                            ]
                        },
                        {
                            "Id": "RURP",
                            "Name": "Rurutu",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-151.34028, -22.452778",
                            "IataCode": "RUR",
                            "Airports": [
                                {
                                    "Id": "RUR",
                                    "Name": "Rurutu",
                                    "CityId": "RURP",
                                    "CountryId": "PF",
                                    "Location": "-151.33333, -22.433333"
                                }
                            ]
                        },
                        {
                            "Id": "NHVP",
                            "Name": "Nuku Hiva",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-140.1, -8.867",
                            "IataCode": "NHV",
                            "Airports": [
                                {
                                    "Id": "NHV",
                                    "Name": "Nuku Hiva",
                                    "CityId": "NHVP",
                                    "CountryId": "PF",
                                    "Location": "-140.15, -8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "UAHP",
                            "Name": "Ua Huka",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-139.55, -8.9",
                            "IataCode": "UAH",
                            "Airports": [
                                {
                                    "Id": "UAH",
                                    "Name": "Ua Huka",
                                    "CityId": "UAHP",
                                    "CountryId": "PF",
                                    "Location": "-139.55, -8.933333"
                                }
                            ]
                        },
                        {
                            "Id": "UAPP",
                            "Name": "Ua Pou",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-140.0667, -9.4",
                            "IataCode": "UAP",
                            "Airports": [
                                {
                                    "Id": "UAP",
                                    "Name": "Ua Pou",
                                    "CityId": "UAPP",
                                    "CountryId": "PF",
                                    "Location": "-140.078, -9.35167"
                                }
                            ]
                        },
                        {
                            "Id": "HIXP",
                            "Name": "Hiva Oa",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-139, -9.75",
                            "IataCode": "HIX",
                            "Airports": [
                                {
                                    "Id": "HIX",
                                    "Name": "Hiva Oa",
                                    "CityId": "HIXP",
                                    "CountryId": "PF",
                                    "Location": "-139.00949, -9.766243"
                                }
                            ]
                        },
                        {
                            "Id": "PKPP",
                            "Name": "Teonemahina",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-138.96667, -14.75",
                            "IataCode": "PKP",
                            "Airports": [
                                {
                                    "Id": "PKP",
                                    "Name": "Puka Puka",
                                    "CityId": "PKPP",
                                    "CountryId": "PF",
                                    "Location": "-138.96667, -14.75"
                                }
                            ]
                        },
                        {
                            "Id": "KXUP",
                            "Name": "Toini",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-144.35, -16.366667",
                            "IataCode": "KXU",
                            "Airports": [
                                {
                                    "Id": "KXU",
                                    "Name": "Katiu",
                                    "CityId": "KXUP",
                                    "CountryId": "PF",
                                    "Location": "-144.40306, -16.339444"
                                }
                            ]
                        },
                        {
                            "Id": "NAUP",
                            "Name": "Tepukamaruia",
                            "SingleAirportCity": true,
                            "CountryId": "PF",
                            "Location": "-141.28333, -14.166667",
                            "IataCode": "NAU",
                            "Airports": [
                                {
                                    "Id": "NAU",
                                    "Name": "Napuka Island",
                                    "CityId": "NAUP",
                                    "CountryId": "PF",
                                    "Location": "-141.267, -14.1768"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "CK",
                    "Name": "Cook Islands",
                    "CurrencyId": "NZD",
                    "Cities": [
                        {
                            "Id": "AIUC",
                            "Name": "Atiu Island",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-158.117, -19.983",
                            "IataCode": "AIU",
                            "Airports": [
                                {
                                    "Id": "AIU",
                                    "Name": "Atiu Island",
                                    "CityId": "AIUC",
                                    "CountryId": "CK",
                                    "Location": "-158.1, -20.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MOIC",
                            "Name": "Mitiaro Island",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-157.7, -19.867",
                            "IataCode": "MOI",
                            "Airports": [
                                {
                                    "Id": "MOI",
                                    "Name": "Mitiaro Island",
                                    "CityId": "MOIC",
                                    "CountryId": "CK",
                                    "Location": "-157.703, -19.8425"
                                }
                            ]
                        },
                        {
                            "Id": "AITC",
                            "Name": "Aitutaki",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-159.79, -18.85",
                            "IataCode": "AIT",
                            "Airports": [
                                {
                                    "Id": "AIT",
                                    "Name": "Aitutaki",
                                    "CityId": "AITC",
                                    "CountryId": "CK",
                                    "Location": "-159.75, -18.85"
                                }
                            ]
                        },
                        {
                            "Id": "MGSC",
                            "Name": "Mangaia Island",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-157.92306, -21.92139",
                            "IataCode": "MGS",
                            "Airports": [
                                {
                                    "Id": "MGS",
                                    "Name": "Mangaia Island",
                                    "CityId": "MGSC",
                                    "CountryId": "CK",
                                    "Location": "-157.93333, -21.933333"
                                }
                            ]
                        },
                        {
                            "Id": "RARC",
                            "Name": "Rarotonga",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-159.783, -21.233",
                            "IataCode": "RAR",
                            "Airports": [
                                {
                                    "Id": "RAR",
                                    "Name": "Rarotonga",
                                    "CityId": "RARC",
                                    "CountryId": "CK",
                                    "Location": "-159.8, -21.200278"
                                }
                            ]
                        },
                        {
                            "Id": "MHXC",
                            "Name": "Manihiki Island",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-161, -10.4",
                            "IataCode": "MHX",
                            "Airports": [
                                {
                                    "Id": "MHX",
                                    "Name": "Manihiki Island",
                                    "CityId": "MHXC",
                                    "CountryId": "CK",
                                    "Location": "-161, -10.366667"
                                }
                            ]
                        },
                        {
                            "Id": "PYEC",
                            "Name": "Penrhyn Island",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-157.96944, -9.00556",
                            "IataCode": "PYE",
                            "Airports": [
                                {
                                    "Id": "PYE",
                                    "Name": "Penrhyn Island",
                                    "CityId": "PYEC",
                                    "CountryId": "CK",
                                    "Location": "-158.03333, -9.016667"
                                }
                            ]
                        },
                        {
                            "Id": "PZKC",
                            "Name": "Puka Puka Is / Attol",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-165.85, -10.883",
                            "IataCode": "PZK",
                            "Airports": [
                                {
                                    "Id": "PZK",
                                    "Name": "Puka Puka Is / Attol",
                                    "CityId": "PZKC",
                                    "CountryId": "CK",
                                    "Location": "-165.81667, -10.883333"
                                }
                            ]
                        },
                        {
                            "Id": "MUKC",
                            "Name": "Te Enui",
                            "SingleAirportCity": true,
                            "CountryId": "CK",
                            "Location": "-158.12917, -19.986944",
                            "IataCode": "MUK",
                            "Airports": [
                                {
                                    "Id": "MUK",
                                    "Name": "Mauke Island",
                                    "CityId": "MUKC",
                                    "CountryId": "CK",
                                    "Location": "-157.345, -20.1361"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "SB",
                    "Name": "Solomon Islands",
                    "CurrencyId": "SBD",
                    "Cities": [
                        {
                            "Id": "OTVA",
                            "Name": "Ontong Java",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.52278, -5.513889",
                            "IataCode": "OTV",
                            "Airports": [
                                {
                                    "Id": "OTV",
                                    "Name": "Ontong Java",
                                    "CityId": "OTVA",
                                    "CountryId": "SB",
                                    "Location": "159.52278, -5.513889"
                                }
                            ]
                        },
                        {
                            "Id": "ATDA",
                            "Name": "Atoifi",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.01667, -8.85",
                            "IataCode": "ATD",
                            "Airports": [
                                {
                                    "Id": "ATD",
                                    "Name": "Atoifi",
                                    "CityId": "ATDA",
                                    "CountryId": "SB",
                                    "Location": "161.03333, -8.866667"
                                }
                            ]
                        },
                        {
                            "Id": "AKSA",
                            "Name": "Auki",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.7, -8.7666667",
                            "IataCode": "AKS",
                            "Airports": [
                                {
                                    "Id": "AKS",
                                    "Name": "Auki",
                                    "CityId": "AKSA",
                                    "CountryId": "SB",
                                    "Location": "160.68083, -8.697778"
                                }
                            ]
                        },
                        {
                            "Id": "BPFA",
                            "Name": "Batuna",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "158.11667, -8.55",
                            "IataCode": "BPF",
                            "Airports": [
                                {
                                    "Id": "BPF",
                                    "Name": "Batuna",
                                    "CityId": "BPFA",
                                    "CountryId": "SB",
                                    "Location": "158.11667, -8.55"
                                }
                            ]
                        },
                        {
                            "Id": "GZOA",
                            "Name": "Gizo",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "156.85, -8.1",
                            "IataCode": "GZO",
                            "Airports": [
                                {
                                    "Id": "GZO",
                                    "Name": "Gizo",
                                    "CityId": "GZOA",
                                    "CountryId": "SB",
                                    "Location": "156.83333, -8.116667"
                                }
                            ]
                        },
                        {
                            "Id": "HIRA",
                            "Name": "Honiara",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.95, -9.4333333",
                            "IataCode": "HIR",
                            "Airports": [
                                {
                                    "Id": "HIR",
                                    "Name": "Honiara",
                                    "CityId": "HIRA",
                                    "CountryId": "SB",
                                    "Location": "160.04556, -9.425278"
                                }
                            ]
                        },
                        {
                            "Id": "IRAA",
                            "Name": "Kirakira",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.91667, -10.45",
                            "IataCode": "IRA",
                            "Airports": [
                                {
                                    "Id": "IRA",
                                    "Name": "Kirakira",
                                    "CityId": "IRAA",
                                    "CountryId": "SB",
                                    "Location": "161.83333, -10.5"
                                }
                            ]
                        },
                        {
                            "Id": "KUEA",
                            "Name": "Kukundu",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "156.95, -8.0166667",
                            "IataCode": "KUE",
                            "Airports": [
                                {
                                    "Id": "KUE",
                                    "Name": "Kukundu",
                                    "CityId": "KUEA",
                                    "CountryId": "SB",
                                    "Location": "156, -8.016667"
                                }
                            ]
                        },
                        {
                            "Id": "AVUA",
                            "Name": "Avu Avu",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.38333, -9.8333333",
                            "IataCode": "AVU",
                            "Airports": [
                                {
                                    "Id": "AVU",
                                    "Name": "Avu Avu",
                                    "CityId": "AVUA",
                                    "CountryId": "SB",
                                    "Location": "160.38333, -9.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MBUA",
                            "Name": "Mbambanakira",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.85, -9.75",
                            "IataCode": "MBU",
                            "Airports": [
                                {
                                    "Id": "MBU",
                                    "Name": "Mbambanakira",
                                    "CityId": "MBUA",
                                    "CountryId": "SB",
                                    "Location": "159.83976, -9.74706"
                                }
                            ]
                        },
                        {
                            "Id": "MUAA",
                            "Name": "Munda",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.25, -8.3166667",
                            "IataCode": "MUA",
                            "Airports": [
                                {
                                    "Id": "MUA",
                                    "Name": "Munda",
                                    "CityId": "MUAA",
                                    "CountryId": "SB",
                                    "Location": "157.26667, -8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "ONEA",
                            "Name": "Onepusu",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.01667, -9.2833333",
                            "IataCode": "ONE",
                            "Airports": [
                                {
                                    "Id": "ONE",
                                    "Name": "Onepusu",
                                    "CityId": "ONEA",
                                    "CountryId": "SB",
                                    "Location": "161.06667, -9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PRSA",
                            "Name": "Parasi",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.43333, -9.6666667",
                            "IataCode": "PRS",
                            "Airports": [
                                {
                                    "Id": "PRS",
                                    "Name": "Parasi",
                                    "CityId": "PRSA",
                                    "CountryId": "SB",
                                    "Location": "158, -8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TAAA",
                            "Name": "Tarapaina",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.38333, -9.4333333",
                            "IataCode": "TAA",
                            "Airports": [
                                {
                                    "Id": "TAA",
                                    "Name": "Tarapaina",
                                    "CityId": "TAAA",
                                    "CountryId": "SB",
                                    "Location": "161.35, -9.4"
                                }
                            ]
                        },
                        {
                            "Id": "VEVA",
                            "Name": "Barakoma",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "156.68333, -7.9",
                            "IataCode": "VEV",
                            "Airports": [
                                {
                                    "Id": "VEV",
                                    "Name": "Barakoma",
                                    "CityId": "VEVA",
                                    "CountryId": "SB",
                                    "Location": "156.70599, -7.91278"
                                }
                            ]
                        },
                        {
                            "Id": "XYAA",
                            "Name": "Yandina",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.21667, -9.1166667",
                            "IataCode": "XYA",
                            "Airports": [
                                {
                                    "Id": "XYA",
                                    "Name": "Yandina",
                                    "CityId": "XYAA",
                                    "CountryId": "SB",
                                    "Location": "159.21667, -9.116667"
                                }
                            ]
                        },
                        {
                            "Id": "TLGA",
                            "Name": "Tulagi Island",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.15, -9.1",
                            "IataCode": "TLG",
                            "Airports": [
                                {
                                    "Id": "TLG",
                                    "Name": "Tulagi Island",
                                    "CityId": "TLGA",
                                    "CountryId": "SB",
                                    "Location": "160.15, -9.1"
                                }
                            ]
                        },
                        {
                            "Id": "VAOA",
                            "Name": "Suavanao",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "158.73333, -7.5666667",
                            "IataCode": "VAO",
                            "Airports": [
                                {
                                    "Id": "VAO",
                                    "Name": "Suavanao",
                                    "CityId": "VAOA",
                                    "CountryId": "SB",
                                    "Location": "158.66667, -7.566667"
                                }
                            ]
                        },
                        {
                            "Id": "EGMA",
                            "Name": "Sege",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.88333, -8.5666667",
                            "IataCode": "EGM",
                            "Airports": [
                                {
                                    "Id": "EGM",
                                    "Name": "Sege",
                                    "CityId": "EGMA",
                                    "CountryId": "SB",
                                    "Location": "157.85, -8.55"
                                }
                            ]
                        },
                        {
                            "Id": "RNAA",
                            "Name": "Arona",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161.96667, -9.8333333",
                            "IataCode": "RNA",
                            "Airports": [
                                {
                                    "Id": "RNA",
                                    "Name": "Arona",
                                    "CityId": "RNAA",
                                    "CountryId": "SB",
                                    "Location": "161.98306, -9.864444"
                                }
                            ]
                        },
                        {
                            "Id": "BASA",
                            "Name": "Balalae",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "155.8815, -6.989",
                            "IataCode": "BAS",
                            "Airports": [
                                {
                                    "Id": "BAS",
                                    "Name": "Balalae",
                                    "CityId": "BASA",
                                    "CountryId": "SB",
                                    "Location": "155.88333, -6.983333"
                                }
                            ]
                        },
                        {
                            "Id": "GSIA",
                            "Name": "Guadalcanal",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.183, -9.617",
                            "IataCode": "GSI",
                            "Airports": [
                                {
                                    "Id": "GSI",
                                    "Name": "Guadalcanal",
                                    "CityId": "GSIA",
                                    "CountryId": "SB",
                                    "Location": "160.2, -9.533333"
                                }
                            ]
                        },
                        {
                            "Id": "NNBA",
                            "Name": "Santa Ana",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "162.46361, -10.82833",
                            "IataCode": "NNB",
                            "Airports": [
                                {
                                    "Id": "NNB",
                                    "Name": "Santa Ana",
                                    "CityId": "NNBA",
                                    "CountryId": "SB",
                                    "Location": "162.5, -10.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RNLA",
                            "Name": "Rennell",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.167, -11.667",
                            "IataCode": "RNL",
                            "Airports": [
                                {
                                    "Id": "RNL",
                                    "Name": "Rennell",
                                    "CityId": "RNLA",
                                    "CountryId": "SB",
                                    "Location": "160.3, -11.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BNYA",
                            "Name": "Bellona",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.167, -11.667",
                            "IataCode": "BNY",
                            "Airports": [
                                {
                                    "Id": "BNY",
                                    "Name": "Bellona",
                                    "CityId": "BNYA",
                                    "CountryId": "SB",
                                    "Location": "159.81667, -11.3"
                                }
                            ]
                        },
                        {
                            "Id": "SCZA",
                            "Name": "Santa Cruz Is",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "166.4894, -11.2652",
                            "IataCode": "SCZ",
                            "Airports": [
                                {
                                    "Id": "SCZ",
                                    "Name": "Santa Cruz Is",
                                    "CityId": "SCZA",
                                    "CountryId": "SB",
                                    "Location": "166.85, -11.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CHYA",
                            "Name": "Choiseul Bay",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.04014, -7.0901311",
                            "IataCode": "CHY",
                            "Airports": [
                                {
                                    "Id": "CHY",
                                    "Name": "Choiseul Bay",
                                    "CityId": "CHYA",
                                    "CountryId": "SB",
                                    "Location": "156.66667, -7"
                                }
                            ]
                        },
                        {
                            "Id": "KWSA",
                            "Name": "Ukuuku",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.78333, -8.3666667",
                            "IataCode": "KWS",
                            "Airports": [
                                {
                                    "Id": "KWS",
                                    "Name": "Kwailabesi",
                                    "CityId": "KWSA",
                                    "CountryId": "SB",
                                    "Location": "160.77513, -8.360508"
                                }
                            ]
                        },
                        {
                            "Id": "VIUA",
                            "Name": "Tombe",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.73333, -8.5",
                            "IataCode": "VIU",
                            "Airports": [
                                {
                                    "Id": "VIU",
                                    "Name": "Viru",
                                    "CityId": "VIUA",
                                    "CountryId": "SB",
                                    "Location": "157.66667, -8.5"
                                }
                            ]
                        },
                        {
                            "Id": "SVYA",
                            "Name": "Tanggonggora",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.78333, -9.1333333",
                            "IataCode": "SVY",
                            "Airports": [
                                {
                                    "Id": "SVY",
                                    "Name": "Savo",
                                    "CityId": "SVYA",
                                    "CountryId": "SB",
                                    "Location": "159.8, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "GTAA",
                            "Name": "Sombiro",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "158.2, -8.7333333",
                            "IataCode": "GTA",
                            "Airports": [
                                {
                                    "Id": "GTA",
                                    "Name": "Gatokae",
                                    "CityId": "GTAA",
                                    "CountryId": "SB",
                                    "Location": "158.18333, -8.766667"
                                }
                            ]
                        },
                        {
                            "Id": "RINA",
                            "Name": "Rarumana",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.01667, -8.2",
                            "IataCode": "RIN",
                            "Airports": [
                                {
                                    "Id": "RIN",
                                    "Name": "Ringi Cove",
                                    "CityId": "RINA",
                                    "CountryId": "SB",
                                    "Location": "157.03333, -8.2"
                                }
                            ]
                        },
                        {
                            "Id": "RRIA",
                            "Name": "Naruo",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "158.35, -7.6333333",
                            "IataCode": "RRI",
                            "Airports": [
                                {
                                    "Id": "RRI",
                                    "Name": "Barora",
                                    "CityId": "RRIA",
                                    "CountryId": "SB",
                                    "Location": "158.26667, -7.5"
                                }
                            ]
                        },
                        {
                            "Id": "FREA",
                            "Name": "Mogarege",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.55, -8.1",
                            "IataCode": "FRE",
                            "Airports": [
                                {
                                    "Id": "FRE",
                                    "Name": "Fera Island",
                                    "CityId": "FREA",
                                    "CountryId": "SB",
                                    "Location": "159.58333, -8.1"
                                }
                            ]
                        },
                        {
                            "Id": "MNYA",
                            "Name": "Maloaini",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "155.6, -7.3333333",
                            "IataCode": "MNY",
                            "Airports": [
                                {
                                    "Id": "MNY",
                                    "Name": "Mono",
                                    "CityId": "MNYA",
                                    "CountryId": "SB",
                                    "Location": "155.56667, -7.35"
                                }
                            ]
                        },
                        {
                            "Id": "GEFA",
                            "Name": "Liangai",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "156.58333, -7.5833333",
                            "IataCode": "GEF",
                            "Airports": [
                                {
                                    "Id": "GEF",
                                    "Name": "Geva",
                                    "CityId": "GEFA",
                                    "CountryId": "SB",
                                    "Location": "156.59778, -7.578333"
                                }
                            ]
                        },
                        {
                            "Id": "JJAA",
                            "Name": "Kilokaka",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "159.31667, -8.2333333",
                            "IataCode": "JJA",
                            "Airports": [
                                {
                                    "Id": "JJA",
                                    "Name": "Jajao",
                                    "CityId": "JJAA",
                                    "CountryId": "SB",
                                    "Location": "159.27051, -8.216587"
                                }
                            ]
                        },
                        {
                            "Id": "KGEA",
                            "Name": "Keru",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.66667, -8.1833333",
                            "IataCode": "KGE",
                            "Airports": [
                                {
                                    "Id": "KGE",
                                    "Name": "Kagau",
                                    "CityId": "KGEA",
                                    "CountryId": "SB",
                                    "Location": "157.5866, -7.331055"
                                }
                            ]
                        },
                        {
                            "Id": "AFTA",
                            "Name": "Igwa",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.88333, -9.1666667",
                            "IataCode": "AFT",
                            "Airports": [
                                {
                                    "Id": "AFT",
                                    "Name": "Afutara",
                                    "CityId": "AFTA",
                                    "CountryId": "SB",
                                    "Location": "160.85, -9.2"
                                }
                            ]
                        },
                        {
                            "Id": "RBVA",
                            "Name": "Hovoro",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "157.6, -8.0833333",
                            "IataCode": "RBV",
                            "Airports": [
                                {
                                    "Id": "RBV",
                                    "Name": "Ramata",
                                    "CityId": "RBVA",
                                    "CountryId": "SB",
                                    "Location": "157.63944, -8.165556"
                                }
                            ]
                        },
                        {
                            "Id": "ANHA",
                            "Name": "Ghole",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.38333, -9.1166667",
                            "IataCode": "ANH",
                            "Airports": [
                                {
                                    "Id": "ANH",
                                    "Name": "Anuha Island Resort",
                                    "CityId": "ANHA",
                                    "CountryId": "SB",
                                    "Location": "160.5, -8.983333"
                                }
                            ]
                        },
                        {
                            "Id": "RUSA",
                            "Name": "Faunikari",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "161, -8.6166667",
                            "IataCode": "RUS",
                            "Airports": [
                                {
                                    "Id": "RUS",
                                    "Name": "Marau Sound",
                                    "CityId": "RUSA",
                                    "CountryId": "SB",
                                    "Location": "160.825, -9.86167"
                                }
                            ]
                        },
                        {
                            "Id": "KWRA",
                            "Name": "Anakelo",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "160.73333, -8.5666667",
                            "IataCode": "KWR",
                            "Airports": [
                                {
                                    "Id": "KWR",
                                    "Name": "Kwai Harbour",
                                    "CityId": "KWRA",
                                    "CountryId": "SB",
                                    "Location": "160.73333, -8.566667"
                                }
                            ]
                        },
                        {
                            "Id": "NAZA",
                            "Name": "Naana",
                            "SingleAirportCity": true,
                            "CountryId": "SB",
                            "Location": "162.2, -10.65",
                            "IataCode": "NAZ",
                            "Airports": [
                                {
                                    "Id": "NAZ",
                                    "Name": "Star Harbour",
                                    "CityId": "NAZA",
                                    "CountryId": "SB",
                                    "Location": "162.2058, -10.6556"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NC",
                    "Name": "New Caledonia",
                    "CurrencyId": "XPF",
                    "Cities": [
                        {
                            "Id": "NOUN",
                            "Name": "Noumea",
                            "SingleAirportCity": false,
                            "CountryId": "NC",
                            "Location": "166.45, -22.266667",
                            "IataCode": "NOU",
                            "Airports": [
                                {
                                    "Id": "GEA",
                                    "Name": "Noumea Magenta",
                                    "CityId": "NOUN",
                                    "CountryId": "NC",
                                    "Location": "166.47083, -22.259722"
                                },
                                {
                                    "Id": "NOU",
                                    "Name": "Noumea Tontouta",
                                    "CityId": "NOUN",
                                    "CountryId": "NC",
                                    "Location": "166.21083, -22.016944"
                                }
                            ]
                        },
                        {
                            "Id": "TOUN",
                            "Name": "Touho",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "165.23333, -20.783333",
                            "IataCode": "TOU",
                            "Airports": [
                                {
                                    "Id": "TOU",
                                    "Name": "Touho",
                                    "CityId": "TOUN",
                                    "CountryId": "NC",
                                    "Location": "165.259, -20.79"
                                }
                            ]
                        },
                        {
                            "Id": "PDCN",
                            "Name": "Mueo",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "165.01667, -21.3",
                            "IataCode": "PDC",
                            "Airports": [
                                {
                                    "Id": "PDC",
                                    "Name": "Mueo",
                                    "CityId": "PDCN",
                                    "CountryId": "NC",
                                    "Location": "165, -21.316667"
                                }
                            ]
                        },
                        {
                            "Id": "PUVN",
                            "Name": "Poum",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "164.01667, -20.233333",
                            "IataCode": "PUV",
                            "Airports": [
                                {
                                    "Id": "PUV",
                                    "Name": "Poum",
                                    "CityId": "PUVN",
                                    "CountryId": "NC",
                                    "Location": "164.10139, -20.294444"
                                }
                            ]
                        },
                        {
                            "Id": "KOCN",
                            "Name": "Koumac",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "164.28333, -20.566667",
                            "IataCode": "KOC",
                            "Airports": [
                                {
                                    "Id": "KOC",
                                    "Name": "Koumac",
                                    "CityId": "KOCN",
                                    "CountryId": "NC",
                                    "Location": "164.41667, -20.5"
                                }
                            ]
                        },
                        {
                            "Id": "KNQN",
                            "Name": "Kone",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "164.86667, -21.066667",
                            "IataCode": "KNQ",
                            "Airports": [
                                {
                                    "Id": "KNQ",
                                    "Name": "Kone",
                                    "CityId": "KNQN",
                                    "CountryId": "NC",
                                    "Location": "164.83701, -21.0543"
                                }
                            ]
                        },
                        {
                            "Id": "HLUN",
                            "Name": "Houailou",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "165.61667, -21.283333",
                            "IataCode": "HLU",
                            "Airports": [
                                {
                                    "Id": "HLU",
                                    "Name": "Houailou",
                                    "CityId": "HLUN",
                                    "CountryId": "NC",
                                    "Location": "166.06667, -21.033333"
                                }
                            ]
                        },
                        {
                            "Id": "HNGN",
                            "Name": "Hienghene",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "164.93333, -20.683333",
                            "IataCode": "HNG",
                            "Airports": [
                                {
                                    "Id": "HNG",
                                    "Name": "Hienghene",
                                    "CityId": "HNGN",
                                    "CountryId": "NC",
                                    "Location": "164.9, -20.666667"
                                }
                            ]
                        },
                        {
                            "Id": "BMYN",
                            "Name": "Belep Island",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "163.66208, -19.72778",
                            "IataCode": "BMY",
                            "Airports": [
                                {
                                    "Id": "BMY",
                                    "Name": "Belep Island",
                                    "CityId": "BMYN",
                                    "CountryId": "NC",
                                    "Location": "163.66667, -19.75"
                                }
                            ]
                        },
                        {
                            "Id": "LIFN",
                            "Name": "Lifou",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "167.2, -20.9",
                            "IataCode": "LIF",
                            "Airports": [
                                {
                                    "Id": "LIF",
                                    "Name": "Lifou",
                                    "CityId": "LIFN",
                                    "CountryId": "NC",
                                    "Location": "167.25, -20.766667"
                                }
                            ]
                        },
                        {
                            "Id": "MEEN",
                            "Name": "Mare",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "167.982, -21.5375",
                            "IataCode": "MEE",
                            "Airports": [
                                {
                                    "Id": "MEE",
                                    "Name": "Mare",
                                    "CityId": "MEEN",
                                    "CountryId": "NC",
                                    "Location": "168, -21.566667"
                                }
                            ]
                        },
                        {
                            "Id": "TGJN",
                            "Name": "Tiga",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "167.81472, -21.10694",
                            "IataCode": "TGJ",
                            "Airports": [
                                {
                                    "Id": "TGJ",
                                    "Name": "Tiga",
                                    "CityId": "TGJN",
                                    "CountryId": "NC",
                                    "Location": "167.8, -21"
                                }
                            ]
                        },
                        {
                            "Id": "UVEN",
                            "Name": "Ouvea",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "166.56194, -20.65222",
                            "IataCode": "UVE",
                            "Airports": [
                                {
                                    "Id": "UVE",
                                    "Name": "Ouvea",
                                    "CityId": "UVEN",
                                    "CountryId": "NC",
                                    "Location": "166.57286, -20.641991"
                                }
                            ]
                        },
                        {
                            "Id": "ILPN",
                            "Name": "Ile Des Pins",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "167.483, -22.617",
                            "IataCode": "ILP",
                            "Airports": [
                                {
                                    "Id": "ILP",
                                    "Name": "Ile Des Pins",
                                    "CityId": "ILPN",
                                    "CountryId": "NC",
                                    "Location": "167.4475, -22.589722"
                                }
                            ]
                        },
                        {
                            "Id": "IOUN",
                            "Name": "Ndji",
                            "SingleAirportCity": true,
                            "CountryId": "NC",
                            "Location": "166.78333, -22.466667",
                            "IataCode": "IOU",
                            "Airports": [
                                {
                                    "Id": "IOU",
                                    "Name": "Ile Ouen",
                                    "CityId": "IOUN",
                                    "CountryId": "NC",
                                    "Location": "166.78306, -22.463889"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NF",
                    "Name": "Norfolk Island",
                    "CurrencyId": "AUD",
                    "Cities": []
                },
                {
                    "Id": "GU",
                    "Name": "Guam",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "GUMA",
                            "Name": "Barrigada",
                            "SingleAirportCity": false,
                            "CountryId": "GU",
                            "Location": "144.79889, 13.468333",
                            "IataCode": "GUM",
                            "Airports": [
                                {
                                    "Id": "UAM",
                                    "Name": "Guam Anderson AFB",
                                    "CityId": "GUMA",
                                    "CountryId": "GU",
                                    "Location": "144.76667, 13.466667"
                                },
                                {
                                    "Id": "NGM",
                                    "Name": "Guam Agana NAS",
                                    "CityId": "GUMA",
                                    "CountryId": "GU",
                                    "Location": "144.75, 13.45"
                                },
                                {
                                    "Id": "NRV",
                                    "Name": "Guam Uscg Shore St",
                                    "CityId": "GUMA",
                                    "CountryId": "GU",
                                    "Location": "144.76389, 13.463889"
                                },
                                {
                                    "Id": "GUM",
                                    "Name": "Guam A.B. Won Pat",
                                    "CityId": "GUMA",
                                    "CountryId": "GU",
                                    "Location": "144.79111, 13.48"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NU",
                    "Name": "Niue Island",
                    "CurrencyId": "NZD",
                    "Cities": [
                        {
                            "Id": "IUEA",
                            "Name": "Avatele",
                            "SingleAirportCity": true,
                            "CountryId": "NU",
                            "Location": "-169.91667, -19.1",
                            "IataCode": "IUE",
                            "Airports": [
                                {
                                    "Id": "IUE",
                                    "Name": "Niue Island",
                                    "CityId": "IUEA",
                                    "CountryId": "NU",
                                    "Location": "-169.93333, -19.083333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "NZ",
                    "Name": "New Zealand",
                    "CurrencyId": "NZD",
                    "Cities": [
                        {
                            "Id": "WHKN",
                            "Name": "Whakatane",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "177, -37.983333",
                            "IataCode": "WHK",
                            "Airports": [
                                {
                                    "Id": "WHK",
                                    "Name": "Whakatane",
                                    "CityId": "WHKN",
                                    "CountryId": "NZ",
                                    "Location": "176.91389, -37.922222"
                                }
                            ]
                        },
                        {
                            "Id": "WKAN",
                            "Name": "Wanaka",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "169.15, -44.7",
                            "IataCode": "WKA",
                            "Airports": [
                                {
                                    "Id": "WKA",
                                    "Name": "Wanaka",
                                    "CityId": "WKAN",
                                    "CountryId": "NZ",
                                    "Location": "169.25, -44.733333"
                                }
                            ]
                        },
                        {
                            "Id": "WLGN",
                            "Name": "Wellington",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.77664, -41.278536",
                            "IataCode": "WLG",
                            "Airports": [
                                {
                                    "Id": "WLG",
                                    "Name": "Wellington",
                                    "CityId": "WLGN",
                                    "CountryId": "NZ",
                                    "Location": "174.80139, -41.323889"
                                }
                            ]
                        },
                        {
                            "Id": "WREN",
                            "Name": "Whangarei",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.32391, -35.731673",
                            "IataCode": "WRE",
                            "Airports": [
                                {
                                    "Id": "WRE",
                                    "Name": "Whangarei",
                                    "CityId": "WREN",
                                    "CountryId": "NZ",
                                    "Location": "174.36111, -35.772222"
                                }
                            ]
                        },
                        {
                            "Id": "WSZN",
                            "Name": "Westport",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "171.6037, -41.752617",
                            "IataCode": "WSZ",
                            "Airports": [
                                {
                                    "Id": "WSZ",
                                    "Name": "Westport",
                                    "CityId": "WSZN",
                                    "CountryId": "NZ",
                                    "Location": "171.58194, -41.739722"
                                }
                            ]
                        },
                        {
                            "Id": "AKLN",
                            "Name": "Auckland",
                            "SingleAirportCity": false,
                            "CountryId": "NZ",
                            "Location": "174.76318, -36.852095",
                            "IataCode": "AKL",
                            "Airports": [
                                {
                                    "Id": "MHB",
                                    "Name": "Auckland Mechanics Bay",
                                    "CityId": "AKLN",
                                    "CountryId": "NZ",
                                    "Location": "174.78472, -36.845"
                                },
                                {
                                    "Id": "AKL",
                                    "Name": "Auckland International",
                                    "CityId": "AKLN",
                                    "CountryId": "NZ",
                                    "Location": "174.78626, -37.008916"
                                }
                            ]
                        },
                        {
                            "Id": "ALRN",
                            "Name": "Alexandra",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "169.37008, -45.248372",
                            "IataCode": "ALR",
                            "Airports": [
                                {
                                    "Id": "ALR",
                                    "Name": "Alexandra",
                                    "CityId": "ALRN",
                                    "CountryId": "NZ",
                                    "Location": "169.36944, -45.213889"
                                }
                            ]
                        },
                        {
                            "Id": "AMZN",
                            "Name": "Ardmore",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175, -37.033333",
                            "IataCode": "AMZ",
                            "Airports": [
                                {
                                    "Id": "AMZ",
                                    "Name": "Ardmore",
                                    "CityId": "AMZN",
                                    "CountryId": "NZ",
                                    "Location": "174.96667, -37.033333"
                                }
                            ]
                        },
                        {
                            "Id": "ZQNN",
                            "Name": "Queenstown",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "168.66271, -45.030226",
                            "IataCode": "ZQN",
                            "Airports": [
                                {
                                    "Id": "ZQN",
                                    "Name": "Queenstown",
                                    "CityId": "ZQNN",
                                    "CountryId": "NZ",
                                    "Location": "168.73889, -45.021389"
                                }
                            ]
                        },
                        {
                            "Id": "DGRN",
                            "Name": "Dargaville",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.88333, -35.933333",
                            "IataCode": "DGR",
                            "Airports": [
                                {
                                    "Id": "DGR",
                                    "Name": "Dargaville",
                                    "CityId": "DGRN",
                                    "CountryId": "NZ",
                                    "Location": "173.88333, -35.933333"
                                }
                            ]
                        },
                        {
                            "Id": "KKON",
                            "Name": "Kaikohe",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.80018, -35.39818",
                            "IataCode": "KKO",
                            "Airports": [
                                {
                                    "Id": "KKO",
                                    "Name": "Kaikohe",
                                    "CityId": "KKON",
                                    "CountryId": "NZ",
                                    "Location": "173.81667, -35.452778"
                                }
                            ]
                        },
                        {
                            "Id": "KTFN",
                            "Name": "Takaka",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "172.8, -40.85",
                            "IataCode": "KTF",
                            "Airports": [
                                {
                                    "Id": "KTF",
                                    "Name": "Takaka",
                                    "CityId": "KTFN",
                                    "CountryId": "NZ",
                                    "Location": "172.8, -40.85"
                                }
                            ]
                        },
                        {
                            "Id": "BHEN",
                            "Name": "Blenheim",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.9528, -41.516033",
                            "IataCode": "BHE",
                            "Airports": [
                                {
                                    "Id": "BHE",
                                    "Name": "Blenheim",
                                    "CityId": "BHEN",
                                    "CountryId": "NZ",
                                    "Location": "173.87222, -41.516667"
                                }
                            ]
                        },
                        {
                            "Id": "CHCN",
                            "Name": "Christchurch",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "172.63333, -43.533333",
                            "IataCode": "CHC",
                            "Airports": [
                                {
                                    "Id": "CHC",
                                    "Name": "Christchurch",
                                    "CityId": "CHCN",
                                    "CountryId": "NZ",
                                    "Location": "172.53333, -43.488333"
                                }
                            ]
                        },
                        {
                            "Id": "CMVN",
                            "Name": "Coromandel",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.5, -36.766667",
                            "IataCode": "CMV",
                            "Airports": [
                                {
                                    "Id": "CMV",
                                    "Name": "Coromandel",
                                    "CityId": "CMVN",
                                    "CountryId": "NZ",
                                    "Location": "175.509, -36.7917"
                                }
                            ]
                        },
                        {
                            "Id": "DUDN",
                            "Name": "Dunedin",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "170.50361, -45.874159",
                            "IataCode": "DUD",
                            "Airports": [
                                {
                                    "Id": "DUD",
                                    "Name": "Dunedin",
                                    "CityId": "DUDN",
                                    "CountryId": "NZ",
                                    "Location": "170.2, -45.925"
                                }
                            ]
                        },
                        {
                            "Id": "FGLN",
                            "Name": "Fox Glacier",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "170.01798, -43.46513",
                            "IataCode": "FGL",
                            "Airports": [
                                {
                                    "Id": "FGL",
                                    "Name": "Fox Glacier",
                                    "CityId": "FGLN",
                                    "CountryId": "NZ",
                                    "Location": "170.01667, -43.466667"
                                }
                            ]
                        },
                        {
                            "Id": "GISN",
                            "Name": "Gisborne",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "178.00417, -38.653333",
                            "IataCode": "GIS",
                            "Airports": [
                                {
                                    "Id": "GIS",
                                    "Name": "Gisborne",
                                    "CityId": "GISN",
                                    "CountryId": "NZ",
                                    "Location": "177.97222, -38.659722"
                                }
                            ]
                        },
                        {
                            "Id": "GMNN",
                            "Name": "Greymouth",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "171.2, -42.466667",
                            "IataCode": "GMN",
                            "Airports": [
                                {
                                    "Id": "GMN",
                                    "Name": "Greymouth",
                                    "CityId": "GMNN",
                                    "CountryId": "NZ",
                                    "Location": "171.2, -42.45"
                                }
                            ]
                        },
                        {
                            "Id": "HKKN",
                            "Name": "Hokitika",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "170.96667, -42.716667",
                            "IataCode": "HKK",
                            "Airports": [
                                {
                                    "Id": "HKK",
                                    "Name": "Hokitika",
                                    "CityId": "HKKN",
                                    "CountryId": "NZ",
                                    "Location": "170.98667, -42.716111"
                                }
                            ]
                        },
                        {
                            "Id": "HLZN",
                            "Name": "Hamilton",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.28333, -37.783333",
                            "IataCode": "HLZ",
                            "Airports": [
                                {
                                    "Id": "HLZ",
                                    "Name": "Hamilton",
                                    "CityId": "HLZN",
                                    "CountryId": "NZ",
                                    "Location": "175.33333, -37.861111"
                                }
                            ]
                        },
                        {
                            "Id": "IVCN",
                            "Name": "Invercargill",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "168.35, -46.4",
                            "IataCode": "IVC",
                            "Airports": [
                                {
                                    "Id": "IVC",
                                    "Name": "Invercargill",
                                    "CityId": "IVCN",
                                    "CountryId": "NZ",
                                    "Location": "168.32083, -46.4125"
                                }
                            ]
                        },
                        {
                            "Id": "KATN",
                            "Name": "Kaitaia",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.24018, -35.09818",
                            "IataCode": "KAT",
                            "Airports": [
                                {
                                    "Id": "KAT",
                                    "Name": "Kaitaia",
                                    "CityId": "KATN",
                                    "CountryId": "NZ",
                                    "Location": "173.28333, -35.069444"
                                }
                            ]
                        },
                        {
                            "Id": "KBZN",
                            "Name": "Kaikoura",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.68333, -42.416667",
                            "IataCode": "KBZ",
                            "Airports": [
                                {
                                    "Id": "KBZ",
                                    "Name": "Kaikoura",
                                    "CityId": "KBZN",
                                    "CountryId": "NZ",
                                    "Location": "173.68333, -42.416667"
                                }
                            ]
                        },
                        {
                            "Id": "KUIN",
                            "Name": "Kawau Island",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.85, -36.416667",
                            "IataCode": "KUI",
                            "Airports": [
                                {
                                    "Id": "KUI",
                                    "Name": "Kawau Island",
                                    "CityId": "KUIN",
                                    "CountryId": "NZ",
                                    "Location": "174.83333, -36.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MFNN",
                            "Name": "Milford Sound",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "167.92005, -44.668356",
                            "IataCode": "MFN",
                            "Airports": [
                                {
                                    "Id": "MFN",
                                    "Name": "Milford Sound",
                                    "CityId": "MFNN",
                                    "CountryId": "NZ",
                                    "Location": "167.91111, -44.669444"
                                }
                            ]
                        },
                        {
                            "Id": "MRON",
                            "Name": "Masterton",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.6575, -40.959722",
                            "IataCode": "MRO",
                            "Airports": [
                                {
                                    "Id": "MRO",
                                    "Name": "Masterton",
                                    "CityId": "MRON",
                                    "CountryId": "NZ",
                                    "Location": "175.65, -40.95"
                                }
                            ]
                        },
                        {
                            "Id": "NPLN",
                            "Name": "New Plymouth",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.08333, -39.066667",
                            "IataCode": "NPL",
                            "Airports": [
                                {
                                    "Id": "NPL",
                                    "Name": "New Plymouth",
                                    "CityId": "NPLN",
                                    "CountryId": "NZ",
                                    "Location": "174.17639, -39.01"
                                }
                            ]
                        },
                        {
                            "Id": "NSNN",
                            "Name": "Nelson",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.28333, -41.283333",
                            "IataCode": "NSN",
                            "Airports": [
                                {
                                    "Id": "NSN",
                                    "Name": "Nelson",
                                    "CityId": "NSNN",
                                    "CountryId": "NZ",
                                    "Location": "173.21944, -41.3"
                                }
                            ]
                        },
                        {
                            "Id": "OAMN",
                            "Name": "Oamaru",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "170.98009, -45.078362",
                            "IataCode": "OAM",
                            "Airports": [
                                {
                                    "Id": "OAM",
                                    "Name": "Oamaru",
                                    "CityId": "OAMN",
                                    "CountryId": "NZ",
                                    "Location": "171.08333, -44.969444"
                                }
                            ]
                        },
                        {
                            "Id": "PMRN",
                            "Name": "Palmerston North",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.61667, -40.35",
                            "IataCode": "PMR",
                            "Airports": [
                                {
                                    "Id": "PMR",
                                    "Name": "Palmerston North",
                                    "CityId": "PMRN",
                                    "CountryId": "NZ",
                                    "Location": "175.61667, -40.322222"
                                }
                            ]
                        },
                        {
                            "Id": "PPQN",
                            "Name": "Paraparaumu",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.01667, -40.916667",
                            "IataCode": "PPQ",
                            "Airports": [
                                {
                                    "Id": "PPQ",
                                    "Name": "Paraparaumu",
                                    "CityId": "PPQN",
                                    "CountryId": "NZ",
                                    "Location": "174.98333, -40.9"
                                }
                            ]
                        },
                        {
                            "Id": "ROTN",
                            "Name": "Rotorua",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "176.24516, -38.138742",
                            "IataCode": "ROT",
                            "Airports": [
                                {
                                    "Id": "ROT",
                                    "Name": "Rotorua",
                                    "CityId": "ROTN",
                                    "CountryId": "NZ",
                                    "Location": "176.31667, -38.111111"
                                }
                            ]
                        },
                        {
                            "Id": "TIUN",
                            "Name": "Timaru",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "171.25, -44.4",
                            "IataCode": "TIU",
                            "Airports": [
                                {
                                    "Id": "TIU",
                                    "Name": "Timaru",
                                    "CityId": "TIUN",
                                    "CountryId": "NZ",
                                    "Location": "171.225, -44.302778"
                                }
                            ]
                        },
                        {
                            "Id": "TMZN",
                            "Name": "Thames",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.53333, -37.133333",
                            "IataCode": "TMZ",
                            "Airports": [
                                {
                                    "Id": "TMZ",
                                    "Name": "Thames",
                                    "CityId": "TMZN",
                                    "CountryId": "NZ",
                                    "Location": "175.56667, -37.116667"
                                }
                            ]
                        },
                        {
                            "Id": "TRGN",
                            "Name": "Tauranga",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "176.16667, -37.686111",
                            "IataCode": "TRG",
                            "Airports": [
                                {
                                    "Id": "TRG",
                                    "Name": "Tauranga",
                                    "CityId": "TRGN",
                                    "CountryId": "NZ",
                                    "Location": "176.19444, -37.670833"
                                }
                            ]
                        },
                        {
                            "Id": "TUON",
                            "Name": "Taupo",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "176.08333, -38.683333",
                            "IataCode": "TUO",
                            "Airports": [
                                {
                                    "Id": "TUO",
                                    "Name": "Taupo",
                                    "CityId": "TUON",
                                    "CountryId": "NZ",
                                    "Location": "176.08389, -38.742222"
                                }
                            ]
                        },
                        {
                            "Id": "WAGN",
                            "Name": "Whanganui",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.05, -39.933333",
                            "IataCode": "WAG",
                            "Airports": [
                                {
                                    "Id": "WAG",
                                    "Name": "Whanganui",
                                    "CityId": "WAGN",
                                    "CountryId": "NZ",
                                    "Location": "175.02417, -39.966667"
                                }
                            ]
                        },
                        {
                            "Id": "ASGN",
                            "Name": "Ashburton",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "171.73011, -43.898335",
                            "IataCode": "ASG",
                            "Airports": [
                                {
                                    "Id": "ASG",
                                    "Name": "Ashburton",
                                    "CityId": "ASGN",
                                    "CountryId": "NZ",
                                    "Location": "171.78333, -43.9"
                                }
                            ]
                        },
                        {
                            "Id": "TEUN",
                            "Name": "Te Anau",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "167.71667, -45.416667",
                            "IataCode": "TEU",
                            "Airports": [
                                {
                                    "Id": "TEU",
                                    "Name": "Te Anau",
                                    "CityId": "TEUN",
                                    "CountryId": "NZ",
                                    "Location": "167.72639, -45.419444"
                                }
                            ]
                        },
                        {
                            "Id": "MONN",
                            "Name": "Mount Cook",
                            "SingleAirportCity": false,
                            "CountryId": "NZ",
                            "Location": "170.09926, -43.737089",
                            "IataCode": "MON",
                            "Airports": [
                                {
                                    "Id": "TWZ",
                                    "Name": "Mount Cook Pukaki / Twizel",
                                    "CityId": "MONN",
                                    "CountryId": "NZ",
                                    "Location": "170.11722, -44.234167"
                                },
                                {
                                    "Id": "GTN",
                                    "Name": "Mount Cook Glentanner",
                                    "CityId": "MONN",
                                    "CountryId": "NZ",
                                    "Location": "170.13611, -43.766667"
                                },
                                {
                                    "Id": "MON",
                                    "Name": "Mount Cook",
                                    "CityId": "MONN",
                                    "CountryId": "NZ",
                                    "Location": "170.13611, -43.766667"
                                }
                            ]
                        },
                        {
                            "Id": "MTAN",
                            "Name": "Matamata",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.77021, -37.798216",
                            "IataCode": "MTA",
                            "Airports": [
                                {
                                    "Id": "MTA",
                                    "Name": "Matamata",
                                    "CityId": "MTAN",
                                    "CountryId": "NZ",
                                    "Location": "175.76667, -37.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MZPN",
                            "Name": "Motueka",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.01667, -41.133333",
                            "IataCode": "MZP",
                            "Airports": [
                                {
                                    "Id": "MZP",
                                    "Name": "Motueka",
                                    "CityId": "MZPN",
                                    "CountryId": "NZ",
                                    "Location": "173, -41.116667"
                                }
                            ]
                        },
                        {
                            "Id": "OHAN",
                            "Name": "Ohakea",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.4, -40.2",
                            "IataCode": "OHA",
                            "Airports": [
                                {
                                    "Id": "OHA",
                                    "Name": "Ohakea",
                                    "CityId": "OHAN",
                                    "CountryId": "NZ",
                                    "Location": "175.38667, -40.208333"
                                }
                            ]
                        },
                        {
                            "Id": "PCNN",
                            "Name": "Picton",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.01018, -41.288269",
                            "IataCode": "PCN",
                            "Airports": [
                                {
                                    "Id": "PCN",
                                    "Name": "Picton",
                                    "CityId": "PCNN",
                                    "CountryId": "NZ",
                                    "Location": "174.03333, -41.35"
                                }
                            ]
                        },
                        {
                            "Id": "RAGN",
                            "Name": "Raglan",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.88333, -37.8",
                            "IataCode": "RAG",
                            "Airports": [
                                {
                                    "Id": "RAG",
                                    "Name": "Raglan",
                                    "CityId": "RAGN",
                                    "CountryId": "NZ",
                                    "Location": "174.86667, -37.833333"
                                }
                            ]
                        },
                        {
                            "Id": "TKZN",
                            "Name": "Tokoroa",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.86667, -38.233333",
                            "IataCode": "TKZ",
                            "Airports": [
                                {
                                    "Id": "TKZ",
                                    "Name": "Tokoroa",
                                    "CityId": "TKZN",
                                    "CountryId": "NZ",
                                    "Location": "175.83333, -38.216667"
                                }
                            ]
                        },
                        {
                            "Id": "WHON",
                            "Name": "Franz Josef",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "170.18189, -43.389051",
                            "IataCode": "WHO",
                            "Airports": [
                                {
                                    "Id": "WHO",
                                    "Name": "Franz Josef",
                                    "CityId": "WHON",
                                    "CountryId": "NZ",
                                    "Location": "170.134, -43.3631"
                                }
                            ]
                        },
                        {
                            "Id": "WIKN",
                            "Name": "Surfdale",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.02568, -36.791141",
                            "IataCode": "WIK",
                            "Airports": [
                                {
                                    "Id": "WIK",
                                    "Name": "Surfdale",
                                    "CityId": "WIKN",
                                    "CountryId": "NZ",
                                    "Location": "175.08333, -36.833333"
                                }
                            ]
                        },
                        {
                            "Id": "WTZN",
                            "Name": "Whitianga",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.7, -36.833333",
                            "IataCode": "WTZ",
                            "Airports": [
                                {
                                    "Id": "WTZ",
                                    "Name": "Whitianga",
                                    "CityId": "WTZN",
                                    "CountryId": "NZ",
                                    "Location": "175.675, -36.832"
                                }
                            ]
                        },
                        {
                            "Id": "NPEN",
                            "Name": "Napier-Hastings",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "176.91667, -39.483333",
                            "IataCode": "NPE",
                            "Airports": [
                                {
                                    "Id": "NPE",
                                    "Name": "Napier-Hastings",
                                    "CityId": "NPEN",
                                    "CountryId": "NZ",
                                    "Location": "176.86528, -39.468056"
                                }
                            ]
                        },
                        {
                            "Id": "KKEN",
                            "Name": "Kerikeri",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "173.96667, -35.216667",
                            "IataCode": "KKE",
                            "Airports": [
                                {
                                    "Id": "KKE",
                                    "Name": "Kerikeri",
                                    "CityId": "KKEN",
                                    "CountryId": "NZ",
                                    "Location": "173.91254, -35.26202"
                                }
                            ]
                        },
                        {
                            "Id": "CHTN",
                            "Name": "Chatham Island",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "-176.52783, -43.918229",
                            "IataCode": "CHT",
                            "Airports": [
                                {
                                    "Id": "CHT",
                                    "Name": "Chatham Island",
                                    "CityId": "CHTN",
                                    "CountryId": "NZ",
                                    "Location": "-176.35, -43.8"
                                }
                            ]
                        },
                        {
                            "Id": "GBSN",
                            "Name": "Port Fitzroy",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.35, -36.166667",
                            "IataCode": "GBS",
                            "Airports": [
                                {
                                    "Id": "GBS",
                                    "Name": "Port Fitzroy",
                                    "CityId": "GBSN",
                                    "CountryId": "NZ",
                                    "Location": "175.33333, -36.133333"
                                }
                            ]
                        },
                        {
                            "Id": "NZKR",
                            "Name": "Karamea",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "172.11667, -41.25",
                            "IataCode": "NZK",
                            "Airports": [
                                {
                                    "Id": "NZK",
                                    "Name": "Karamea",
                                    "CityId": "NZKR",
                                    "CountryId": "NZ",
                                    "Location": "172.10468, -41.236769"
                                }
                            ]
                        },
                        {
                            "Id": "WIRN",
                            "Name": "Wairoa",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "177.36667, -39.033333",
                            "IataCode": "WIR",
                            "Airports": [
                                {
                                    "Id": "WIR",
                                    "Name": "Wairoa",
                                    "CityId": "WIRN",
                                    "CountryId": "NZ",
                                    "Location": "177.41667, -39.033333"
                                }
                            ]
                        },
                        {
                            "Id": "GBZN",
                            "Name": "Great Barrier Island",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "175.49432, -36.295",
                            "IataCode": "GBZ",
                            "Airports": [
                                {
                                    "Id": "GBZ",
                                    "Name": "Great Barrier Island",
                                    "CityId": "GBZN",
                                    "CountryId": "NZ",
                                    "Location": "175.4702, -36.24188"
                                }
                            ]
                        },
                        {
                            "Id": "PKLN",
                            "Name": "Te Mata",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.86667, -37.883333",
                            "IataCode": "PKL",
                            "Airports": [
                                {
                                    "Id": "PKL",
                                    "Name": "Pakatoa Island",
                                    "CityId": "PKLN",
                                    "CountryId": "NZ",
                                    "Location": "174.85333, -37.806944"
                                }
                            ]
                        },
                        {
                            "Id": "THHN",
                            "Name": "Marokopa",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "174.73333, -38.3",
                            "IataCode": "THH",
                            "Airports": [
                                {
                                    "Id": "THH",
                                    "Name": "Taharoa",
                                    "CityId": "THHN",
                                    "CountryId": "NZ",
                                    "Location": "174.71667, -38.2"
                                }
                            ]
                        },
                        {
                            "Id": "SZSN",
                            "Name": "Halfmoon Bay (Oban)",
                            "SingleAirportCity": true,
                            "CountryId": "NZ",
                            "Location": "168.10009, -46.888432",
                            "IataCode": "SZS",
                            "Airports": [
                                {
                                    "Id": "SZS",
                                    "Name": "Stewart Island",
                                    "CityId": "SZSN",
                                    "CountryId": "NZ",
                                    "Location": "167.86667, -47"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "FJ",
                    "Name": "Fiji",
                    "CurrencyId": "FJD",
                    "Cities": [
                        {
                            "Id": "AQSF",
                            "Name": "Saqani",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "170.9, -17",
                            "IataCode": "AQS",
                            "Airports": [
                                {
                                    "Id": "AQS",
                                    "Name": "Saqani",
                                    "CityId": "AQSF",
                                    "CountryId": "FJ",
                                    "Location": "170.9, -17"
                                }
                            ]
                        },
                        {
                            "Id": "RBIF",
                            "Name": "Rabi",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "-179, 15",
                            "IataCode": "RBI",
                            "Airports": [
                                {
                                    "Id": "RBI",
                                    "Name": "Rabi",
                                    "CityId": "RBIF",
                                    "CountryId": "FJ",
                                    "Location": "-179, 15"
                                }
                            ]
                        },
                        {
                            "Id": "NANF",
                            "Name": "Nadi",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.41667, -17.8",
                            "IataCode": "NAN",
                            "Airports": [
                                {
                                    "Id": "NAN",
                                    "Name": "Nadi",
                                    "CityId": "NANF",
                                    "CountryId": "FJ",
                                    "Location": "177.44778, -17.753056"
                                }
                            ]
                        },
                        {
                            "Id": "SUVF",
                            "Name": "Suva",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.41667, -18.133333",
                            "IataCode": "SUV",
                            "Airports": [
                                {
                                    "Id": "SUV",
                                    "Name": "Suva",
                                    "CityId": "SUVF",
                                    "CountryId": "FJ",
                                    "Location": "178.5596, -18.044993"
                                }
                            ]
                        },
                        {
                            "Id": "KVUF",
                            "Name": "Korolevu",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.88333, -17.866667",
                            "IataCode": "KVU",
                            "Airports": [
                                {
                                    "Id": "KVU",
                                    "Name": "Korolevu",
                                    "CityId": "KVUF",
                                    "CountryId": "FJ",
                                    "Location": "178, -17.766667"
                                }
                            ]
                        },
                        {
                            "Id": "SVUF",
                            "Name": "Savusavu",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.33333, -16.783333",
                            "IataCode": "SVU",
                            "Airports": [
                                {
                                    "Id": "SVU",
                                    "Name": "Savusavu",
                                    "CityId": "SVUF",
                                    "CountryId": "FJ",
                                    "Location": "179.34444, -16.802778"
                                }
                            ]
                        },
                        {
                            "Id": "VAUF",
                            "Name": "Vatukoula",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.85, -17.516667",
                            "IataCode": "VAU",
                            "Airports": [
                                {
                                    "Id": "VAU",
                                    "Name": "Vatukoula",
                                    "CityId": "VAUF",
                                    "CountryId": "FJ",
                                    "Location": "177.86667, -17.5"
                                }
                            ]
                        },
                        {
                            "Id": "RTAF",
                            "Name": "Rotuma Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.04873, -12.496314",
                            "IataCode": "RTA",
                            "Airports": [
                                {
                                    "Id": "RTA",
                                    "Name": "Rotuma Island",
                                    "CityId": "RTAF",
                                    "CountryId": "FJ",
                                    "Location": "177.05, -12.5"
                                }
                            ]
                        },
                        {
                            "Id": "KXFF",
                            "Name": "Koro Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.41667, -17.366667",
                            "IataCode": "KXF",
                            "Airports": [
                                {
                                    "Id": "KXF",
                                    "Name": "Koro Island",
                                    "CityId": "KXFF",
                                    "CountryId": "FJ",
                                    "Location": "179.83333, -17.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LUCF",
                            "Name": "Laucala Is",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.51667, -18.15",
                            "IataCode": "LUC",
                            "Airports": [
                                {
                                    "Id": "LUC",
                                    "Name": "Laucala Is",
                                    "CityId": "LUCF",
                                    "CountryId": "FJ",
                                    "Location": "-179.66701, -16.7481"
                                }
                            ]
                        },
                        {
                            "Id": "LBSF",
                            "Name": "Labasa",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.38333, -16.416667",
                            "IataCode": "LBS",
                            "Airports": [
                                {
                                    "Id": "LBS",
                                    "Name": "Labasa",
                                    "CityId": "LBSF",
                                    "CountryId": "FJ",
                                    "Location": "179.34056, -16.464722"
                                }
                            ]
                        },
                        {
                            "Id": "KDVF",
                            "Name": "Kandavu",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.38333, -19.033333",
                            "IataCode": "KDV",
                            "Airports": [
                                {
                                    "Id": "KDV",
                                    "Name": "Kandavu",
                                    "CityId": "KDVF",
                                    "CountryId": "FJ",
                                    "Location": "178.16667, -19.05"
                                }
                            ]
                        },
                        {
                            "Id": "LKBF",
                            "Name": "Lakeba",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "120.66139, -18.652659",
                            "IataCode": "LKB",
                            "Airports": [
                                {
                                    "Id": "LKB",
                                    "Name": "Lakeba",
                                    "CityId": "LKBF",
                                    "CountryId": "FJ",
                                    "Location": "-178.8, -18.23333"
                                }
                            ]
                        },
                        {
                            "Id": "MFJF",
                            "Name": "Moala",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "171.79331, -18.763993",
                            "IataCode": "MFJ",
                            "Airports": [
                                {
                                    "Id": "MFJ",
                                    "Name": "Moala",
                                    "CityId": "MFJF",
                                    "CountryId": "FJ",
                                    "Location": "179.93333, -18.566667"
                                }
                            ]
                        },
                        {
                            "Id": "ICIF",
                            "Name": "Cicia",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "-179.3, -17.75",
                            "IataCode": "ICI",
                            "Airports": [
                                {
                                    "Id": "ICI",
                                    "Name": "Cicia",
                                    "CityId": "ICIF",
                                    "CountryId": "FJ",
                                    "Location": "-179.33333, -17.75"
                                }
                            ]
                        },
                        {
                            "Id": "VBVF",
                            "Name": "Vanuabalavu",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "-178.9494, -17.2202",
                            "IataCode": "VBV",
                            "Airports": [
                                {
                                    "Id": "VBV",
                                    "Name": "Vanuabalavu",
                                    "CityId": "VBVF",
                                    "CountryId": "FJ",
                                    "Location": "-178.95, -17.233333"
                                }
                            ]
                        },
                        {
                            "Id": "NGIF",
                            "Name": "Ngau Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.30096, -18.015868",
                            "IataCode": "NGI",
                            "Airports": [
                                {
                                    "Id": "NGI",
                                    "Name": "Ngau Island",
                                    "CityId": "NGIF",
                                    "CountryId": "FJ",
                                    "Location": "179.33333, -18"
                                }
                            ]
                        },
                        {
                            "Id": "KAYF",
                            "Name": "Wakaya Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.01238, -17.628369",
                            "IataCode": "KAY",
                            "Airports": [
                                {
                                    "Id": "KAY",
                                    "Name": "Wakaya Island",
                                    "CityId": "KAYF",
                                    "CountryId": "FJ",
                                    "Location": "178.56611, -17.041111"
                                }
                            ]
                        },
                        {
                            "Id": "MNFF",
                            "Name": "Mana Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.1, -17.683",
                            "IataCode": "MNF",
                            "Airports": [
                                {
                                    "Id": "MNF",
                                    "Name": "Mana Island",
                                    "CityId": "MNFF",
                                    "CountryId": "FJ",
                                    "Location": "177.11667, -17.633333"
                                }
                            ]
                        },
                        {
                            "Id": "TVUF",
                            "Name": "Taveuni",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.95453, -16.92688",
                            "IataCode": "TVU",
                            "Airports": [
                                {
                                    "Id": "TVU",
                                    "Name": "Taveuni",
                                    "CityId": "TVUF",
                                    "CountryId": "FJ",
                                    "Location": "-179.87683, -16.69079"
                                }
                            ]
                        },
                        {
                            "Id": "VTFF",
                            "Name": "Vatulele",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.05, -18.833",
                            "IataCode": "VTF",
                            "Airports": [
                                {
                                    "Id": "VTF",
                                    "Name": "Vatulele",
                                    "CityId": "VTFF",
                                    "CountryId": "FJ",
                                    "Location": "177.61667, -18.55"
                                }
                            ]
                        },
                        {
                            "Id": "YASF",
                            "Name": "Yasawa Island",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.37719, -14.850618",
                            "IataCode": "YAS",
                            "Airports": [
                                {
                                    "Id": "YAS",
                                    "Name": "Yasawa Island",
                                    "CityId": "YASF",
                                    "CountryId": "FJ",
                                    "Location": "177.51984, -16.782519"
                                }
                            ]
                        },
                        {
                            "Id": "PTFF",
                            "Name": "Solevu",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.18333, -17.766667",
                            "IataCode": "PTF",
                            "Airports": [
                                {
                                    "Id": "PTF",
                                    "Name": "Malololailai",
                                    "CityId": "PTFF",
                                    "CountryId": "FJ",
                                    "Location": "177.21667, -17.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BVFF",
                            "Name": "Wairiki",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.66667, -16.933333",
                            "IataCode": "BVF",
                            "Airports": [
                                {
                                    "Id": "BVF",
                                    "Name": "Bua",
                                    "CityId": "BVFF",
                                    "CountryId": "FJ",
                                    "Location": "178.5, -17"
                                }
                            ]
                        },
                        {
                            "Id": "NTAF",
                            "Name": "Vusama",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.31667, -18.083333",
                            "IataCode": "NTA",
                            "Airports": [
                                {
                                    "Id": "NTA",
                                    "Name": "Natadola",
                                    "CityId": "NTAF",
                                    "CountryId": "FJ",
                                    "Location": "177.31667, -18.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LEVF",
                            "Name": "Vangandathe",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.83333, -17.683333",
                            "IataCode": "LEV",
                            "Airports": [
                                {
                                    "Id": "LEV",
                                    "Name": "Bureta",
                                    "CityId": "LEVF",
                                    "CountryId": "FJ",
                                    "Location": "178.83333, -17.683333"
                                }
                            ]
                        },
                        {
                            "Id": "BXLF",
                            "Name": "Ndawara",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.01667, -16.8",
                            "IataCode": "BXL",
                            "Airports": [
                                {
                                    "Id": "BXL",
                                    "Name": "Blue Lagoon",
                                    "CityId": "BXLF",
                                    "CountryId": "FJ",
                                    "Location": "179, -16.75"
                                }
                            ]
                        },
                        {
                            "Id": "CSTF",
                            "Name": "Naviyango",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "177.5, -17.583333",
                            "IataCode": "CST",
                            "Airports": [
                                {
                                    "Id": "CST",
                                    "Name": "Castaway",
                                    "CityId": "CSTF",
                                    "CountryId": "FJ",
                                    "Location": "177.5, -17.5"
                                }
                            ]
                        },
                        {
                            "Id": "ONUF",
                            "Name": "Mathuata-i-Wai",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.08333, -16.416667",
                            "IataCode": "ONU",
                            "Airports": [
                                {
                                    "Id": "ONU",
                                    "Name": "Ono I Lau",
                                    "CityId": "ONUF",
                                    "CountryId": "FJ",
                                    "Location": "179, -16.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TTLF",
                            "Name": "Korovou",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "179.03333, -16.833333",
                            "IataCode": "TTL",
                            "Airports": [
                                {
                                    "Id": "TTL",
                                    "Name": "Turtle Island",
                                    "CityId": "TTLF",
                                    "CountryId": "FJ",
                                    "Location": "179, -16.833333"
                                }
                            ]
                        },
                        {
                            "Id": "PHRF",
                            "Name": "Pacific Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "FJ",
                            "Location": "178.07435, -18.24705",
                            "IataCode": "PHR",
                            "Airports": [
                                {
                                    "Id": "PHR",
                                    "Name": "Pacific Harbor",
                                    "CityId": "PHRF",
                                    "CountryId": "FJ",
                                    "Location": "178.05, -18.2"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PW",
                    "Name": "Palau",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "RORA",
                            "Name": "Koror",
                            "SingleAirportCity": true,
                            "CountryId": "PW",
                            "Location": "134.47083, 7.3405556",
                            "IataCode": "ROR",
                            "Airports": [
                                {
                                    "Id": "ROR",
                                    "Name": "Koror",
                                    "CityId": "RORA",
                                    "CountryId": "PW",
                                    "Location": "134.55306, 7.364444"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "FM",
                    "Name": "Micronesia",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "KSAA",
                            "Name": "Kosrae",
                            "SingleAirportCity": true,
                            "CountryId": "FM",
                            "Location": "162.97539, 5.3106038",
                            "IataCode": "KSA",
                            "Airports": [
                                {
                                    "Id": "KSA",
                                    "Name": "Kosrae",
                                    "CityId": "KSAA",
                                    "CountryId": "FM",
                                    "Location": "162.95832, 5.358392"
                                }
                            ]
                        },
                        {
                            "Id": "YAPA",
                            "Name": "Yap",
                            "SingleAirportCity": true,
                            "CountryId": "FM",
                            "Location": "138.12917, 9.5144444",
                            "IataCode": "YAP",
                            "Airports": [
                                {
                                    "Id": "YAP",
                                    "Name": "Yap",
                                    "CityId": "YAPA",
                                    "CountryId": "FM",
                                    "Location": "138.08578, 9.500874"
                                }
                            ]
                        },
                        {
                            "Id": "PNIA",
                            "Name": "Pohnpei",
                            "SingleAirportCity": true,
                            "CountryId": "FM",
                            "Location": "158.21667, 6.85",
                            "IataCode": "PNI",
                            "Airports": [
                                {
                                    "Id": "PNI",
                                    "Name": "Pohnpei",
                                    "CityId": "PNIA",
                                    "CountryId": "FM",
                                    "Location": "158.20889, 6.985"
                                }
                            ]
                        },
                        {
                            "Id": "TKKA",
                            "Name": "Truk",
                            "SingleAirportCity": true,
                            "CountryId": "FM",
                            "Location": "151.78333, 7.416667",
                            "IataCode": "TKK",
                            "Airports": [
                                {
                                    "Id": "TKK",
                                    "Name": "Truk",
                                    "CityId": "TKKA",
                                    "CountryId": "FM",
                                    "Location": "151.84101, 7.459582"
                                }
                            ]
                        },
                        {
                            "Id": "ULIA",
                            "Name": "Falalop Village",
                            "SingleAirportCity": true,
                            "CountryId": "FM",
                            "Location": "139.78735, 10.0162",
                            "IataCode": "ULI",
                            "Airports": [
                                {
                                    "Id": "ULI",
                                    "Name": "Ulithi",
                                    "CityId": "ULIA",
                                    "CountryId": "FM",
                                    "Location": "139.78927, 10.01994"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "UM",
                    "Name": "US Minor Outlying Islands",
                    "CurrencyId": "USD",
                    "Cities": []
                },
                {
                    "Id": "WS",
                    "Name": "Samoa",
                    "CurrencyId": "WST",
                    "Cities": [
                        {
                            "Id": "APWW",
                            "Name": "Apia",
                            "SingleAirportCity": false,
                            "CountryId": "WS",
                            "Location": "-171.73333, -13.833333",
                            "IataCode": "APW",
                            "Airports": [
                                {
                                    "Id": "APW",
                                    "Name": "Apia Faleolo",
                                    "CityId": "APWW",
                                    "CountryId": "WS",
                                    "Location": "-172, -13.833333"
                                },
                                {
                                    "Id": "FGI",
                                    "Name": "Apia Fagali I",
                                    "CityId": "APWW",
                                    "CountryId": "WS",
                                    "Location": "-171.73333, -13.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LAVW",
                            "Name": "Lalomalava",
                            "SingleAirportCity": true,
                            "CountryId": "WS",
                            "Location": "-172.13333, -13.7",
                            "IataCode": "LAV",
                            "Airports": [
                                {
                                    "Id": "LAV",
                                    "Name": "Lalomalava",
                                    "CityId": "LAVW",
                                    "CountryId": "WS",
                                    "Location": "-171.73333, -14.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MXSW",
                            "Name": "Maota Savaii Is",
                            "SingleAirportCity": true,
                            "CountryId": "WS",
                            "Location": "-172.417, -13.583",
                            "IataCode": "MXS",
                            "Airports": [
                                {
                                    "Id": "MXS",
                                    "Name": "Maota Savaii Is",
                                    "CityId": "MXSW",
                                    "CountryId": "WS",
                                    "Location": "-172.23333, -13.716667"
                                }
                            ]
                        },
                        {
                            "Id": "AAUW",
                            "Name": "Auala",
                            "SingleAirportCity": true,
                            "CountryId": "WS",
                            "Location": "-172.6, -13.466667",
                            "IataCode": "AAU",
                            "Airports": [
                                {
                                    "Id": "AAU",
                                    "Name": "Asau",
                                    "CityId": "AAUW",
                                    "CountryId": "WS",
                                    "Location": "-172.6, -13.458333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "WF",
                    "Name": "Wallis and Futuna Islands",
                    "CurrencyId": "XPF",
                    "Cities": [
                        {
                            "Id": "WLSA",
                            "Name": "Wallis Island",
                            "SingleAirportCity": true,
                            "CountryId": "WF",
                            "Location": "-176.2, -13.267",
                            "IataCode": "WLS",
                            "Airports": [
                                {
                                    "Id": "WLS",
                                    "Name": "Wallis Island",
                                    "CityId": "WLSA",
                                    "CountryId": "WF",
                                    "Location": "-176.19615, -13.24051"
                                }
                            ]
                        },
                        {
                            "Id": "FUTA",
                            "Name": "Futuna Island",
                            "SingleAirportCity": true,
                            "CountryId": "WF",
                            "Location": "-178.09248, -14.315771",
                            "IataCode": "FUT",
                            "Airports": [
                                {
                                    "Id": "FUT",
                                    "Name": "Futuna Island",
                                    "CityId": "FUTA",
                                    "CountryId": "WF",
                                    "Location": "-178.16803, -14.268376"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PN",
                    "Name": "Pitcairn",
                    "CurrencyId": "NZD",
                    "Cities": []
                },
                {
                    "Id": "NR",
                    "Name": "Nauru",
                    "CurrencyId": "AUD",
                    "Cities": [
                        {
                            "Id": "INUA",
                            "Name": "Yaren District",
                            "SingleAirportCity": true,
                            "CountryId": "NR",
                            "Location": "166.92428, -0.54708226",
                            "IataCode": "INU",
                            "Airports": [
                                {
                                    "Id": "INU",
                                    "Name": "Nauru Island",
                                    "CityId": "INUA",
                                    "CountryId": "NR",
                                    "Location": "166.91639, -0.545145"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "PG",
                    "Name": "Papua New Guinea",
                    "CurrencyId": "PGK",
                    "Cities": [
                        {
                            "Id": "HNIP",
                            "Name": "Heiweni",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "159.44694, 22.213056",
                            "IataCode": "HNI",
                            "Airports": [
                                {
                                    "Id": "HNI",
                                    "Name": "Heiweni",
                                    "CityId": "HNIP",
                                    "CountryId": "PG",
                                    "Location": "159.44694, 22.213056"
                                }
                            ]
                        },
                        {
                            "Id": "MNPP",
                            "Name": "Maron",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.0324, -1.537506",
                            "IataCode": "MNP",
                            "Airports": [
                                {
                                    "Id": "MNP",
                                    "Name": "Maron",
                                    "CityId": "MNPP",
                                    "CountryId": "PG",
                                    "Location": "145.0324, -1.537506"
                                }
                            ]
                        },
                        {
                            "Id": "BIZP",
                            "Name": "Bimin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.11667, -5.3666667",
                            "IataCode": "BIZ",
                            "Airports": [
                                {
                                    "Id": "BIZ",
                                    "Name": "Bimin",
                                    "CityId": "BIZP",
                                    "CountryId": "PG",
                                    "Location": "142.01667, -5.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ESAP",
                            "Name": "Esa'Ala",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.81667, -9.7333333",
                            "IataCode": "ESA",
                            "Airports": [
                                {
                                    "Id": "ESA",
                                    "Name": "Esa'Ala",
                                    "CityId": "ESAP",
                                    "CountryId": "PG",
                                    "Location": "150.81667, -9.733333"
                                }
                            ]
                        },
                        {
                            "Id": "GVIP",
                            "Name": "Green River",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.18333, -3.9",
                            "IataCode": "GVI",
                            "Airports": [
                                {
                                    "Id": "GVI",
                                    "Name": "Green River",
                                    "CityId": "GVIP",
                                    "CountryId": "PG",
                                    "Location": "141.15, -3.966667"
                                }
                            ]
                        },
                        {
                            "Id": "JOPP",
                            "Name": "Josephstaal",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.01667, -4.7333333",
                            "IataCode": "JOP",
                            "Airports": [
                                {
                                    "Id": "JOP",
                                    "Name": "Josephstaal",
                                    "CityId": "JOPP",
                                    "CountryId": "PG",
                                    "Location": "145.01667, -4.75"
                                }
                            ]
                        },
                        {
                            "Id": "BNMP",
                            "Name": "Bodinumu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.6675, -9.1102778",
                            "IataCode": "BNM",
                            "Airports": [
                                {
                                    "Id": "BNM",
                                    "Name": "Bodinumu",
                                    "CityId": "BNMP",
                                    "CountryId": "PG",
                                    "Location": "147.61667, -9.116667"
                                }
                            ]
                        },
                        {
                            "Id": "BNTP",
                            "Name": "Bundi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.23333, -5.7333333",
                            "IataCode": "BNT",
                            "Airports": [
                                {
                                    "Id": "BNT",
                                    "Name": "Bundi",
                                    "CityId": "BNTP",
                                    "CountryId": "PG",
                                    "Location": "145.45, -5.75"
                                }
                            ]
                        },
                        {
                            "Id": "BNVP",
                            "Name": "Boana",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.81667, -6.4333333",
                            "IataCode": "BNV",
                            "Airports": [
                                {
                                    "Id": "BNV",
                                    "Name": "Boana",
                                    "CityId": "BNVP",
                                    "CountryId": "PG",
                                    "Location": "146.81667, -6.425"
                                }
                            ]
                        },
                        {
                            "Id": "BNZP",
                            "Name": "Banz",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.61667, -5.7833333",
                            "IataCode": "BNZ",
                            "Airports": [
                                {
                                    "Id": "BNZ",
                                    "Name": "Banz",
                                    "CityId": "BNZP",
                                    "CountryId": "PG",
                                    "Location": "144.61139, -5.808333"
                                }
                            ]
                        },
                        {
                            "Id": "BOTP",
                            "Name": "Boset",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.08333, -7.2333333",
                            "IataCode": "BOT",
                            "Airports": [
                                {
                                    "Id": "BOT",
                                    "Name": "Boset",
                                    "CityId": "BOTP",
                                    "CountryId": "PG",
                                    "Location": "141.08333, -7.233333"
                                }
                            ]
                        },
                        {
                            "Id": "BPBP",
                            "Name": "Boridi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.63333, -9.0833333",
                            "IataCode": "BPB",
                            "Airports": [
                                {
                                    "Id": "BPB",
                                    "Name": "Boridi",
                                    "CityId": "BPBP",
                                    "CountryId": "PG",
                                    "Location": "147.53333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "BPDP",
                            "Name": "Bapi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.98333, -7.7166667",
                            "IataCode": "BPD",
                            "Airports": [
                                {
                                    "Id": "BPD",
                                    "Name": "Bapi",
                                    "CityId": "BPDP",
                                    "CountryId": "PG",
                                    "Location": "147.01667, -7.75"
                                }
                            ]
                        },
                        {
                            "Id": "BRPP",
                            "Name": "Biaru",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.26667, -8.3333333",
                            "IataCode": "BRP",
                            "Airports": [
                                {
                                    "Id": "BRP",
                                    "Name": "Biaru",
                                    "CityId": "BRPP",
                                    "CountryId": "PG",
                                    "Location": "146.7, -7.533333"
                                }
                            ]
                        },
                        {
                            "Id": "BULP",
                            "Name": "Bulolo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.65, -7.2",
                            "IataCode": "BUL",
                            "Airports": [
                                {
                                    "Id": "BUL",
                                    "Name": "Bulolo",
                                    "CityId": "BULP",
                                    "CountryId": "PG",
                                    "Location": "146.64778, -7.189722"
                                }
                            ]
                        },
                        {
                            "Id": "BWPP",
                            "Name": "Bewani",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.16667, -3.0333333",
                            "IataCode": "BWP",
                            "Airports": [
                                {
                                    "Id": "BWP",
                                    "Name": "Bewani",
                                    "CityId": "BWPP",
                                    "CountryId": "PG",
                                    "Location": "141.15, -3.016667"
                                }
                            ]
                        },
                        {
                            "Id": "CMUP",
                            "Name": "Kundiawa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.96667, -6.0166667",
                            "IataCode": "CMU",
                            "Airports": [
                                {
                                    "Id": "CMU",
                                    "Name": "Kundiawa",
                                    "CityId": "CMUP",
                                    "CountryId": "PG",
                                    "Location": "144.97099, -6.02429"
                                }
                            ]
                        },
                        {
                            "Id": "DAFP",
                            "Name": "Daup",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.95, -4.75",
                            "IataCode": "DAF",
                            "Airports": [
                                {
                                    "Id": "DAF",
                                    "Name": "Daup",
                                    "CityId": "DAFP",
                                    "CountryId": "PG",
                                    "Location": "144.95, -4.733333"
                                }
                            ]
                        },
                        {
                            "Id": "DAUP",
                            "Name": "Daru",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.2, -9.0833333",
                            "IataCode": "DAU",
                            "Airports": [
                                {
                                    "Id": "DAU",
                                    "Name": "Daru",
                                    "CityId": "DAUP",
                                    "CountryId": "PG",
                                    "Location": "143.19333, -9.087222"
                                }
                            ]
                        },
                        {
                            "Id": "DERP",
                            "Name": "Derim",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.11667, -6.15",
                            "IataCode": "DER",
                            "Airports": [
                                {
                                    "Id": "DER",
                                    "Name": "Derim",
                                    "CityId": "DERP",
                                    "CountryId": "PG",
                                    "Location": "147.08333, -6.1"
                                }
                            ]
                        },
                        {
                            "Id": "EFGP",
                            "Name": "Efogi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.66361, -9.1541667",
                            "IataCode": "EFG",
                            "Airports": [
                                {
                                    "Id": "EFG",
                                    "Name": "Efogi",
                                    "CityId": "EFGP",
                                    "CountryId": "PG",
                                    "Location": "147.66667, -9.15"
                                }
                            ]
                        },
                        {
                            "Id": "EREP",
                            "Name": "Erave",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.88333, -6.65",
                            "IataCode": "ERE",
                            "Airports": [
                                {
                                    "Id": "ERE",
                                    "Name": "Erave",
                                    "CityId": "EREP",
                                    "CountryId": "PG",
                                    "Location": "143.9, -6.633333"
                                }
                            ]
                        },
                        {
                            "Id": "FINP",
                            "Name": "Finschhafen",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.85, -6.6",
                            "IataCode": "FIN",
                            "Airports": [
                                {
                                    "Id": "FIN",
                                    "Name": "Finschhafen",
                                    "CityId": "FINP",
                                    "CountryId": "PG",
                                    "Location": "147.85405, -6.62175"
                                }
                            ]
                        },
                        {
                            "Id": "FRQP",
                            "Name": "Feramin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.66667, -5.1666667",
                            "IataCode": "FRQ",
                            "Airports": [
                                {
                                    "Id": "FRQ",
                                    "Name": "Feramin",
                                    "CityId": "FRQP",
                                    "CountryId": "PG",
                                    "Location": "141.7, -5.416667"
                                }
                            ]
                        },
                        {
                            "Id": "FUBP",
                            "Name": "Fulleborn",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.63333, -6.1333333",
                            "IataCode": "FUB",
                            "Airports": [
                                {
                                    "Id": "FUB",
                                    "Name": "Fulleborn",
                                    "CityId": "FUBP",
                                    "CountryId": "PG",
                                    "Location": "150.65, -6.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GARP",
                            "Name": "Garaina",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.13333, -7.8833333",
                            "IataCode": "GAR",
                            "Airports": [
                                {
                                    "Id": "GAR",
                                    "Name": "Garaina",
                                    "CityId": "GARP",
                                    "CountryId": "PG",
                                    "Location": "147.1375, -7.873333"
                                }
                            ]
                        },
                        {
                            "Id": "GAZP",
                            "Name": "Guasopa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.95, -9.2166667",
                            "IataCode": "GAZ",
                            "Airports": [
                                {
                                    "Id": "GAZ",
                                    "Name": "Guasopa",
                                    "CityId": "GAZP",
                                    "CountryId": "PG",
                                    "Location": "152.93333, -9.2"
                                }
                            ]
                        },
                        {
                            "Id": "GEWP",
                            "Name": "Gewoia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.48333, -9.2",
                            "IataCode": "GEW",
                            "Airports": [
                                {
                                    "Id": "GEW",
                                    "Name": "Gewoia",
                                    "CityId": "GEWP",
                                    "CountryId": "PG",
                                    "Location": "148.46667, -9.3"
                                }
                            ]
                        },
                        {
                            "Id": "GKAP",
                            "Name": "Goroka",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.38333, -6.0833333",
                            "IataCode": "GKA",
                            "Airports": [
                                {
                                    "Id": "GKA",
                                    "Name": "Goroka",
                                    "CityId": "GKAP",
                                    "CountryId": "PG",
                                    "Location": "145.39778, -6.079167"
                                }
                            ]
                        },
                        {
                            "Id": "GUGP",
                            "Name": "Guari",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.86667, -8.05",
                            "IataCode": "GUG",
                            "Airports": [
                                {
                                    "Id": "GUG",
                                    "Name": "Guari",
                                    "CityId": "GUGP",
                                    "CountryId": "PG",
                                    "Location": "146.9, -8.516667"
                                }
                            ]
                        },
                        {
                            "Id": "GURP",
                            "Name": "Alotau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.41667, -10.3",
                            "IataCode": "GUR",
                            "Airports": [
                                {
                                    "Id": "GUR",
                                    "Name": "Alotau",
                                    "CityId": "GURP",
                                    "CountryId": "PG",
                                    "Location": "150.334, -10.3115"
                                }
                            ]
                        },
                        {
                            "Id": "HAZP",
                            "Name": "Hatzfeldthaven",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.21667, -4.4",
                            "IataCode": "HAZ",
                            "Airports": [
                                {
                                    "Id": "HAZ",
                                    "Name": "Hatzfeldthaven",
                                    "CityId": "HAZP",
                                    "CountryId": "PG",
                                    "Location": "145.18333, -4.433333"
                                }
                            ]
                        },
                        {
                            "Id": "HGUP",
                            "Name": "Mount Hagen",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.21667, -5.8666667",
                            "IataCode": "HGU",
                            "Airports": [
                                {
                                    "Id": "HGU",
                                    "Name": "Mount Hagen",
                                    "CityId": "HGUP",
                                    "CountryId": "PG",
                                    "Location": "144.295, -5.827222"
                                }
                            ]
                        },
                        {
                            "Id": "HKNP",
                            "Name": "Hoskins",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.4, -5.45",
                            "IataCode": "HKN",
                            "Airports": [
                                {
                                    "Id": "HKN",
                                    "Name": "Hoskins",
                                    "CityId": "HKNP",
                                    "CountryId": "PG",
                                    "Location": "150.41667, -5.475278"
                                }
                            ]
                        },
                        {
                            "Id": "HOCP",
                            "Name": "Komako",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.88333, -7.4",
                            "IataCode": "HOC",
                            "Airports": [
                                {
                                    "Id": "HOC",
                                    "Name": "Komako",
                                    "CityId": "HOCP",
                                    "CountryId": "PG",
                                    "Location": "145.88333, -7.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IDNP",
                            "Name": "Indagen",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.25, -6.2333333",
                            "IataCode": "IDN",
                            "Airports": [
                                {
                                    "Id": "IDN",
                                    "Name": "Indagen",
                                    "CityId": "IDNP",
                                    "CountryId": "PG",
                                    "Location": "147.25, -6.2"
                                }
                            ]
                        },
                        {
                            "Id": "IHUP",
                            "Name": "Ihu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.4, -7.9166667",
                            "IataCode": "IHU",
                            "Airports": [
                                {
                                    "Id": "IHU",
                                    "Name": "Ihu",
                                    "CityId": "IHUP",
                                    "CountryId": "PG",
                                    "Location": "145.38333, -7.9"
                                }
                            ]
                        },
                        {
                            "Id": "IMDP",
                            "Name": "Imonda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.16667, -3.3333333",
                            "IataCode": "IMD",
                            "Airports": [
                                {
                                    "Id": "IMD",
                                    "Name": "Imonda",
                                    "CityId": "IMDP",
                                    "CountryId": "PG",
                                    "Location": "141.2, -3.433333"
                                }
                            ]
                        },
                        {
                            "Id": "IOKP",
                            "Name": "Iokea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.26667, -8.4166667",
                            "IataCode": "IOK",
                            "Airports": [
                                {
                                    "Id": "IOK",
                                    "Name": "Iokea",
                                    "CityId": "IOKP",
                                    "CountryId": "PG",
                                    "Location": "146.26667, -8.4"
                                }
                            ]
                        },
                        {
                            "Id": "KMRP",
                            "Name": "Karimui",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.85, -6.5",
                            "IataCode": "KMR",
                            "Airports": [
                                {
                                    "Id": "KMR",
                                    "Name": "Karimui",
                                    "CityId": "KMRP",
                                    "CountryId": "PG",
                                    "Location": "144.86667, -6.5"
                                }
                            ]
                        },
                        {
                            "Id": "KDEP",
                            "Name": "Koroba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.75, -5.7",
                            "IataCode": "KDE",
                            "Airports": [
                                {
                                    "Id": "KDE",
                                    "Name": "Koroba",
                                    "CityId": "KDEP",
                                    "CountryId": "PG",
                                    "Location": "142.73333, -5.683333"
                                }
                            ]
                        },
                        {
                            "Id": "KPMP",
                            "Name": "Kompiam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.95, -5.3666667",
                            "IataCode": "KPM",
                            "Airports": [
                                {
                                    "Id": "KPM",
                                    "Name": "Kompiam",
                                    "CityId": "KPMP",
                                    "CountryId": "PG",
                                    "Location": "143.93333, -5.383333"
                                }
                            ]
                        },
                        {
                            "Id": "KDPP",
                            "Name": "Kandep",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.51667, -5.85",
                            "IataCode": "KDP",
                            "Airports": [
                                {
                                    "Id": "KDP",
                                    "Name": "Kandep",
                                    "CityId": "KDPP",
                                    "CountryId": "PG",
                                    "Location": "143.5, -5.866667"
                                }
                            ]
                        },
                        {
                            "Id": "KQLP",
                            "Name": "Kol",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.83333, -5.7",
                            "IataCode": "KQL",
                            "Airports": [
                                {
                                    "Id": "KQL",
                                    "Name": "Kol",
                                    "CityId": "KQLP",
                                    "CountryId": "PG",
                                    "Location": "144.86667, -5.65"
                                }
                            ]
                        },
                        {
                            "Id": "KRIP",
                            "Name": "Kikori",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.25, -7.4166667",
                            "IataCode": "KRI",
                            "Airports": [
                                {
                                    "Id": "KRI",
                                    "Name": "Kikori",
                                    "CityId": "KRIP",
                                    "CountryId": "PG",
                                    "Location": "144.26667, -7.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KEGP",
                            "Name": "Keglsugl",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.1, -5.8333333",
                            "IataCode": "KEG",
                            "Airports": [
                                {
                                    "Id": "KEG",
                                    "Name": "Keglsugl",
                                    "CityId": "KEGP",
                                    "CountryId": "PG",
                                    "Location": "145.08333, -5.8"
                                }
                            ]
                        },
                        {
                            "Id": "KSBP",
                            "Name": "Kasanombe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.01667, -6.3666667",
                            "IataCode": "KSB",
                            "Airports": [
                                {
                                    "Id": "KSB",
                                    "Name": "Kasanombe",
                                    "CityId": "KSBP",
                                    "CountryId": "PG",
                                    "Location": "147.01667, -6.383333"
                                }
                            ]
                        },
                        {
                            "Id": "KEXP",
                            "Name": "Kanabea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.9, -7.5333333",
                            "IataCode": "KEX",
                            "Airports": [
                                {
                                    "Id": "KEX",
                                    "Name": "Kanabea",
                                    "CityId": "KEXP",
                                    "CountryId": "PG",
                                    "Location": "145.91667, -7.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KGWP",
                            "Name": "Kagi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.67278, -9.1313889",
                            "IataCode": "KGW",
                            "Airports": [
                                {
                                    "Id": "KGW",
                                    "Name": "Kagi",
                                    "CityId": "KGWP",
                                    "CountryId": "PG",
                                    "Location": "146.83333, -9.05"
                                }
                            ]
                        },
                        {
                            "Id": "KIAP",
                            "Name": "Kaiapit",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.26667, -6.2666667",
                            "IataCode": "KIA",
                            "Airports": [
                                {
                                    "Id": "KIA",
                                    "Name": "Kaiapit",
                                    "CityId": "KIAP",
                                    "CountryId": "PG",
                                    "Location": "146.25, -6.266667"
                                }
                            ]
                        },
                        {
                            "Id": "KIQP",
                            "Name": "Kira",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.3, -8.05",
                            "IataCode": "KIQ",
                            "Airports": [
                                {
                                    "Id": "KIQ",
                                    "Name": "Kira",
                                    "CityId": "KIQP",
                                    "CountryId": "PG",
                                    "Location": "147.35, -8.116667"
                                }
                            ]
                        },
                        {
                            "Id": "KIZP",
                            "Name": "Kikinonda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.93333, -8.5333333",
                            "IataCode": "KIZ",
                            "Airports": [
                                {
                                    "Id": "KIZ",
                                    "Name": "Kikinonda",
                                    "CityId": "KIZP",
                                    "CountryId": "PG",
                                    "Location": "147.91667, -8.65"
                                }
                            ]
                        },
                        {
                            "Id": "KMAP",
                            "Name": "Kerema",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.76667, -7.9666667",
                            "IataCode": "KMA",
                            "Airports": [
                                {
                                    "Id": "KMA",
                                    "Name": "Kerema",
                                    "CityId": "KMAP",
                                    "CountryId": "PG",
                                    "Location": "145.771, -7.96361"
                                }
                            ]
                        },
                        {
                            "Id": "KMBP",
                            "Name": "Koinambe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.61667, -5.5166667",
                            "IataCode": "KMB",
                            "Airports": [
                                {
                                    "Id": "KMB",
                                    "Name": "Koinambe",
                                    "CityId": "KMBP",
                                    "CountryId": "PG",
                                    "Location": "144.63333, -5.5"
                                }
                            ]
                        },
                        {
                            "Id": "KUPP",
                            "Name": "Kupiano",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.18333, -10.083333",
                            "IataCode": "KUP",
                            "Airports": [
                                {
                                    "Id": "KUP",
                                    "Name": "Kupiano",
                                    "CityId": "KUPP",
                                    "CountryId": "PG",
                                    "Location": "148.16667, -10.091667"
                                }
                            ]
                        },
                        {
                            "Id": "KVGP",
                            "Name": "Kavieng",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.8, -2.5666667",
                            "IataCode": "KVG",
                            "Airports": [
                                {
                                    "Id": "KVG",
                                    "Name": "Kavieng",
                                    "CityId": "KVGP",
                                    "CountryId": "PG",
                                    "Location": "150.81556, -2.577778"
                                }
                            ]
                        },
                        {
                            "Id": "LAEP",
                            "Name": "Lae",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147, -6.7333333",
                            "IataCode": "LAE",
                            "Airports": [
                                {
                                    "Id": "LAE",
                                    "Name": "Lae",
                                    "CityId": "LAEP",
                                    "CountryId": "PG",
                                    "Location": "146.72611, -6.569722"
                                }
                            ]
                        },
                        {
                            "Id": "KZFP",
                            "Name": "Kaintiba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.03333, -7.5",
                            "IataCode": "KZF",
                            "Airports": [
                                {
                                    "Id": "KZF",
                                    "Name": "Kaintiba",
                                    "CityId": "KZFP",
                                    "CountryId": "PG",
                                    "Location": "146, -7.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LGMP",
                            "Name": "Laiagam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.76667, -5.2833333",
                            "IataCode": "LGM",
                            "Airports": [
                                {
                                    "Id": "LGM",
                                    "Name": "Laiagam",
                                    "CityId": "LGMP",
                                    "CountryId": "PG",
                                    "Location": "145.41667, -4.333333"
                                }
                            ]
                        },
                        {
                            "Id": "LMIP",
                            "Name": "Lumi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.03333, -3.4833333",
                            "IataCode": "LMI",
                            "Airports": [
                                {
                                    "Id": "LMI",
                                    "Name": "Lumi",
                                    "CityId": "LMIP",
                                    "CountryId": "PG",
                                    "Location": "142.03333, -3.483333"
                                }
                            ]
                        },
                        {
                            "Id": "MXHP",
                            "Name": "Moro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.16667, -6.3166667",
                            "IataCode": "MXH",
                            "Airports": [
                                {
                                    "Id": "MXH",
                                    "Name": "Moro",
                                    "CityId": "MXHP",
                                    "CountryId": "PG",
                                    "Location": "143.25056, -6.3525"
                                }
                            ]
                        },
                        {
                            "Id": "POMP",
                            "Name": "Port Moresby",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.1925, -9.4647222",
                            "IataCode": "POM",
                            "Airports": [
                                {
                                    "Id": "POM",
                                    "Name": "Port Moresby",
                                    "CityId": "POMP",
                                    "CountryId": "PG",
                                    "Location": "147.21671, -9.442693"
                                }
                            ]
                        },
                        {
                            "Id": "PMPP",
                            "Name": "Pimaga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.51667, -6.5",
                            "IataCode": "PMP",
                            "Airports": [
                                {
                                    "Id": "PMP",
                                    "Name": "Pimaga",
                                    "CityId": "PMPP",
                                    "CountryId": "PG",
                                    "Location": "143.41667, -6.666667"
                                }
                            ]
                        },
                        {
                            "Id": "RABP",
                            "Name": "Rabaul",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.18333, -4.2",
                            "IataCode": "RAB",
                            "Airports": [
                                {
                                    "Id": "RAB",
                                    "Name": "Rabaul",
                                    "CityId": "RABP",
                                    "CountryId": "PG",
                                    "Location": "152.36667, -4.333333"
                                }
                            ]
                        },
                        {
                            "Id": "RMNP",
                            "Name": "Rumginae",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.26667, -5.9",
                            "IataCode": "RMN",
                            "Airports": [
                                {
                                    "Id": "RMN",
                                    "Name": "Rumginae",
                                    "CityId": "RMNP",
                                    "CountryId": "PG",
                                    "Location": "141.16667, -6.166667"
                                }
                            ]
                        },
                        {
                            "Id": "AOAP",
                            "Name": "Aroa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.8, -9.05",
                            "IataCode": "AOA",
                            "Airports": [
                                {
                                    "Id": "AOA",
                                    "Name": "Aroa",
                                    "CityId": "AOAP",
                                    "CountryId": "PG",
                                    "Location": "146.8, -9.05"
                                }
                            ]
                        },
                        {
                            "Id": "VIVP",
                            "Name": "Vivigani",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.33333, -9.2833333",
                            "IataCode": "VIV",
                            "Airports": [
                                {
                                    "Id": "VIV",
                                    "Name": "Vivigani",
                                    "CityId": "VIVP",
                                    "CountryId": "PG",
                                    "Location": "150.34167, -9.330556"
                                }
                            ]
                        },
                        {
                            "Id": "ABPP",
                            "Name": "Atkamba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.1, -6.0833333",
                            "IataCode": "ABP",
                            "Airports": [
                                {
                                    "Id": "ABP",
                                    "Name": "Atkamba",
                                    "CityId": "ABPP",
                                    "CountryId": "PG",
                                    "Location": "141.1, -6.066667"
                                }
                            ]
                        },
                        {
                            "Id": "ABWP",
                            "Name": "Abau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.7, -10.183333",
                            "IataCode": "ABW",
                            "Airports": [
                                {
                                    "Id": "ABW",
                                    "Name": "Abau",
                                    "CityId": "ABWP",
                                    "CountryId": "PG",
                                    "Location": "148.7, -10.183333"
                                }
                            ]
                        },
                        {
                            "Id": "AFRP",
                            "Name": "Afore",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.38333, -9.15",
                            "IataCode": "AFR",
                            "Airports": [
                                {
                                    "Id": "AFR",
                                    "Name": "Afore",
                                    "CityId": "AFRP",
                                    "CountryId": "PG",
                                    "Location": "148.38333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "AGGP",
                            "Name": "Angoram",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.06667, -4.0666667",
                            "IataCode": "AGG",
                            "Airports": [
                                {
                                    "Id": "AGG",
                                    "Name": "Angoram",
                                    "CityId": "AGGP",
                                    "CountryId": "PG",
                                    "Location": "144.07389, -4.168611"
                                }
                            ]
                        },
                        {
                            "Id": "AGKP",
                            "Name": "Kagua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.8, -6.4333333",
                            "IataCode": "AGK",
                            "Airports": [
                                {
                                    "Id": "AGK",
                                    "Name": "Kagua",
                                    "CityId": "AGKP",
                                    "CountryId": "PG",
                                    "Location": "143.84667, -6.403333"
                                }
                            ]
                        },
                        {
                            "Id": "AIEP",
                            "Name": "Aiome",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.73333, -5.1333333",
                            "IataCode": "AIE",
                            "Airports": [
                                {
                                    "Id": "AIE",
                                    "Name": "Aiome",
                                    "CityId": "AIEP",
                                    "CountryId": "PG",
                                    "Location": "144.66667, -5.166667"
                                }
                            ]
                        },
                        {
                            "Id": "AKGP",
                            "Name": "Anguganak",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.21667, -3.5833333",
                            "IataCode": "AKG",
                            "Airports": [
                                {
                                    "Id": "AKG",
                                    "Name": "Anguganak",
                                    "CityId": "AKGP",
                                    "CountryId": "PG",
                                    "Location": "142.01667, -3.633333"
                                }
                            ]
                        },
                        {
                            "Id": "AMGP",
                            "Name": "Amboin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.48333, -4.6166667",
                            "IataCode": "AMG",
                            "Airports": [
                                {
                                    "Id": "AMG",
                                    "Name": "Amboin",
                                    "CityId": "AMGP",
                                    "CountryId": "PG",
                                    "Location": "143.7, -4.616667"
                                }
                            ]
                        },
                        {
                            "Id": "AMUP",
                            "Name": "Amanab",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.21667, -3.5833333",
                            "IataCode": "AMU",
                            "Airports": [
                                {
                                    "Id": "AMU",
                                    "Name": "Amanab",
                                    "CityId": "AMUP",
                                    "CountryId": "PG",
                                    "Location": "141.15, -3.516667"
                                }
                            ]
                        },
                        {
                            "Id": "AOBP",
                            "Name": "Annanberg",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.63333, -4.9",
                            "IataCode": "AOB",
                            "Airports": [
                                {
                                    "Id": "AOB",
                                    "Name": "Annanberg",
                                    "CityId": "AOBP",
                                    "CountryId": "PG",
                                    "Location": "144.65, -4.916667"
                                }
                            ]
                        },
                        {
                            "Id": "AONP",
                            "Name": "Arona",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.03333, -6.3",
                            "IataCode": "AON",
                            "Airports": [
                                {
                                    "Id": "AON",
                                    "Name": "Arona",
                                    "CityId": "AONP",
                                    "CountryId": "PG",
                                    "Location": "146, -6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "APPP",
                            "Name": "Asapa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.1, -8.9833333",
                            "IataCode": "APP",
                            "Airports": [
                                {
                                    "Id": "APP",
                                    "Name": "Asapa",
                                    "CityId": "APPP",
                                    "CountryId": "PG",
                                    "Location": "148.13333, -8.983333"
                                }
                            ]
                        },
                        {
                            "Id": "ATNP",
                            "Name": "Namatanai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.43333, -3.6666667",
                            "IataCode": "ATN",
                            "Airports": [
                                {
                                    "Id": "ATN",
                                    "Name": "Namatanai",
                                    "CityId": "ATNP",
                                    "CountryId": "PG",
                                    "Location": "152.46667, -3.666667"
                                }
                            ]
                        },
                        {
                            "Id": "ATPP",
                            "Name": "Aitape",
                            "SingleAirportCity": false,
                            "CountryId": "PG",
                            "Location": "142.35, -3.1333333",
                            "IataCode": "ATP",
                            "Airports": [
                                {
                                    "Id": "TAJ",
                                    "Name": "Aitape Tadji",
                                    "CityId": "ATPP",
                                    "CountryId": "PG",
                                    "Location": "142.41667, -3.183333"
                                },
                                {
                                    "Id": "ATP",
                                    "Name": "Aitape Airstrip",
                                    "CityId": "ATPP",
                                    "CountryId": "PG",
                                    "Location": "142.42957, -3.18985"
                                }
                            ]
                        },
                        {
                            "Id": "AUJP",
                            "Name": "Ambunti",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.83333, -4.2333333",
                            "IataCode": "AUJ",
                            "Airports": [
                                {
                                    "Id": "AUJ",
                                    "Name": "Ambunti",
                                    "CityId": "AUJP",
                                    "CountryId": "PG",
                                    "Location": "142.85, -4.25"
                                }
                            ]
                        },
                        {
                            "Id": "AWRP",
                            "Name": "Awar",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.85, -4.15",
                            "IataCode": "AWR",
                            "Airports": [
                                {
                                    "Id": "AWR",
                                    "Name": "Awar",
                                    "CityId": "AWRP",
                                    "CountryId": "PG",
                                    "Location": "144.87028, -4.124167"
                                }
                            ]
                        },
                        {
                            "Id": "AYUP",
                            "Name": "Aiyura",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.9, -6.3333333",
                            "IataCode": "AYU",
                            "Airports": [
                                {
                                    "Id": "AYU",
                                    "Name": "Aiyura",
                                    "CityId": "AYUP",
                                    "CountryId": "PG",
                                    "Location": "145.9, -6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BCPP",
                            "Name": "Bambu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.5, -5.8666667",
                            "IataCode": "BCP",
                            "Airports": [
                                {
                                    "Id": "BCP",
                                    "Name": "Bambu",
                                    "CityId": "BCPP",
                                    "CountryId": "PG",
                                    "Location": "146.48333, -5.85"
                                }
                            ]
                        },
                        {
                            "Id": "SXWP",
                            "Name": "Sauren",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.85, -5.9666667",
                            "IataCode": "SXW",
                            "Airports": [
                                {
                                    "Id": "SXW",
                                    "Name": "Sauren",
                                    "CityId": "SXWP",
                                    "CountryId": "PG",
                                    "Location": "148.95306, -5.971389"
                                }
                            ]
                        },
                        {
                            "Id": "PNPP",
                            "Name": "Popondetta",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.23333, -8.7666667",
                            "IataCode": "PNP",
                            "Airports": [
                                {
                                    "Id": "PNP",
                                    "Name": "Popondetta",
                                    "CityId": "PNPP",
                                    "CountryId": "PG",
                                    "Location": "148.30901, -8.80454"
                                }
                            ]
                        },
                        {
                            "Id": "SXAP",
                            "Name": "Sialum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.6, -6.0833333",
                            "IataCode": "SXA",
                            "Airports": [
                                {
                                    "Id": "SXA",
                                    "Name": "Sialum",
                                    "CityId": "SXAP",
                                    "CountryId": "PG",
                                    "Location": "147.61667, -6.033333"
                                }
                            ]
                        },
                        {
                            "Id": "EIAP",
                            "Name": "Eia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.81667, -7.9333333",
                            "IataCode": "EIA",
                            "Airports": [
                                {
                                    "Id": "EIA",
                                    "Name": "Eia",
                                    "CityId": "EIAP",
                                    "CountryId": "PG",
                                    "Location": "147.63333, -8.116667"
                                }
                            ]
                        },
                        {
                            "Id": "KSPP",
                            "Name": "Kosipe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.21667, -8.45",
                            "IataCode": "KSP",
                            "Airports": [
                                {
                                    "Id": "KSP",
                                    "Name": "Kosipe",
                                    "CityId": "KSPP",
                                    "CountryId": "PG",
                                    "Location": "147.46667, -8.55"
                                }
                            ]
                        },
                        {
                            "Id": "PUIP",
                            "Name": "Pureni",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.83333, -5.8666667",
                            "IataCode": "PUI",
                            "Airports": [
                                {
                                    "Id": "PUI",
                                    "Name": "Pureni",
                                    "CityId": "PUIP",
                                    "CountryId": "PG",
                                    "Location": "142.83333, -5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "IAUP",
                            "Name": "Iaura",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.11667, -9.7666667",
                            "IataCode": "IAU",
                            "Airports": [
                                {
                                    "Id": "IAU",
                                    "Name": "Iaura",
                                    "CityId": "IAUP",
                                    "CountryId": "PG",
                                    "Location": "148.1, -9.783333"
                                }
                            ]
                        },
                        {
                            "Id": "KBMP",
                            "Name": "Kabwum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.18333, -6.15",
                            "IataCode": "KBM",
                            "Airports": [
                                {
                                    "Id": "KBM",
                                    "Name": "Kabwum",
                                    "CityId": "KBMP",
                                    "CountryId": "PG",
                                    "Location": "147.16667, -6.166667"
                                }
                            ]
                        },
                        {
                            "Id": "XBNA",
                            "Name": "Biniguni",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.26667, -9.6666667",
                            "IataCode": "XBN",
                            "Airports": [
                                {
                                    "Id": "XBN",
                                    "Name": "Biniguni",
                                    "CityId": "XBNA",
                                    "CountryId": "PG",
                                    "Location": "149.3, -9.633333"
                                }
                            ]
                        },
                        {
                            "Id": "BEAP",
                            "Name": "Bereina",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.5, -8.6333333",
                            "IataCode": "BEA",
                            "Airports": [
                                {
                                    "Id": "BEA",
                                    "Name": "Bereina",
                                    "CityId": "BEAP",
                                    "CountryId": "PG",
                                    "Location": "146.48333, -8.65"
                                }
                            ]
                        },
                        {
                            "Id": "DPUP",
                            "Name": "Dumpu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.73333, -5.8833333",
                            "IataCode": "DPU",
                            "Airports": [
                                {
                                    "Id": "DPU",
                                    "Name": "Dumpu",
                                    "CityId": "DPUP",
                                    "CountryId": "PG",
                                    "Location": "145.75, -5.883333"
                                }
                            ]
                        },
                        {
                            "Id": "EMOP",
                            "Name": "Emo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.05, -9.1166667",
                            "IataCode": "EMO",
                            "Airports": [
                                {
                                    "Id": "EMO",
                                    "Name": "Emo",
                                    "CityId": "EMOP",
                                    "CountryId": "PG",
                                    "Location": "147.95, -9.15"
                                }
                            ]
                        },
                        {
                            "Id": "EMSP",
                            "Name": "Embessa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.76667, -9.45",
                            "IataCode": "EMS",
                            "Airports": [
                                {
                                    "Id": "EMS",
                                    "Name": "Embessa",
                                    "CityId": "EMSP",
                                    "CountryId": "PG",
                                    "Location": "148.86667, -9.45"
                                }
                            ]
                        },
                        {
                            "Id": "GAPP",
                            "Name": "Gusap",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.08333, -5.9833333",
                            "IataCode": "GAP",
                            "Airports": [
                                {
                                    "Id": "GAP",
                                    "Name": "Gusap",
                                    "CityId": "GAPP",
                                    "CountryId": "PG",
                                    "Location": "146.33333, -6.5"
                                }
                            ]
                        },
                        {
                            "Id": "GUEP",
                            "Name": "Guriaso",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.58333, -3.6",
                            "IataCode": "GUE",
                            "Airports": [
                                {
                                    "Id": "GUE",
                                    "Name": "Guriaso",
                                    "CityId": "GUEP",
                                    "CountryId": "PG",
                                    "Location": "141.58333, -3.583333"
                                }
                            ]
                        },
                        {
                            "Id": "GUVP",
                            "Name": "Mougulu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.41667, -6.2833333",
                            "IataCode": "GUV",
                            "Airports": [
                                {
                                    "Id": "GUV",
                                    "Name": "Mougulu",
                                    "CityId": "GUVP",
                                    "CountryId": "PG",
                                    "Location": "142.41667, -6.283333"
                                }
                            ]
                        },
                        {
                            "Id": "HNNP",
                            "Name": "Honinabi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.18333, -6.25",
                            "IataCode": "HNN",
                            "Airports": [
                                {
                                    "Id": "HNN",
                                    "Name": "Honinabi",
                                    "CityId": "HNNP",
                                    "CountryId": "PG",
                                    "Location": "142.23333, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "IOPP",
                            "Name": "Ioma",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.83333, -8.3666667",
                            "IataCode": "IOP",
                            "Airports": [
                                {
                                    "Id": "IOP",
                                    "Name": "Ioma",
                                    "CityId": "IOPP",
                                    "CountryId": "PG",
                                    "Location": "147.75, -8.35"
                                }
                            ]
                        },
                        {
                            "Id": "KNLP",
                            "Name": "Kelanoa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.5, -6.0166667",
                            "IataCode": "KNL",
                            "Airports": [
                                {
                                    "Id": "KNL",
                                    "Name": "Kelanoa",
                                    "CityId": "KNLP",
                                    "CountryId": "PG",
                                    "Location": "147.5, -6"
                                }
                            ]
                        },
                        {
                            "Id": "KRUP",
                            "Name": "Kerau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.06667, -8.25",
                            "IataCode": "KRU",
                            "Airports": [
                                {
                                    "Id": "KRU",
                                    "Name": "Kerau",
                                    "CityId": "KRUP",
                                    "CountryId": "PG",
                                    "Location": "147.08333, -8.3"
                                }
                            ]
                        },
                        {
                            "Id": "KKDP",
                            "Name": "Kokoda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.73333, -8.8833333",
                            "IataCode": "KKD",
                            "Airports": [
                                {
                                    "Id": "KKD",
                                    "Name": "Kokoda",
                                    "CityId": "KKDP",
                                    "CountryId": "PG",
                                    "Location": "147.73333, -8.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LMGP",
                            "Name": "Lamassa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.76667, -4.6833333",
                            "IataCode": "LMG",
                            "Airports": [
                                {
                                    "Id": "LMG",
                                    "Name": "Lamassa",
                                    "CityId": "LMGP",
                                    "CountryId": "PG",
                                    "Location": "152.75, -4.7"
                                }
                            ]
                        },
                        {
                            "Id": "LMYP",
                            "Name": "Lake Murray",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.43333, -6.8",
                            "IataCode": "LMY",
                            "Airports": [
                                {
                                    "Id": "LMY",
                                    "Name": "Lake Murray",
                                    "CityId": "LMYP",
                                    "CountryId": "PG",
                                    "Location": "141.494, -7.00992"
                                }
                            ]
                        },
                        {
                            "Id": "LNQP",
                            "Name": "Loani",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.61667, -10.583333",
                            "IataCode": "LNQ",
                            "Airports": [
                                {
                                    "Id": "LNQ",
                                    "Name": "Loani",
                                    "CityId": "LNQP",
                                    "CountryId": "PG",
                                    "Location": "150.15, -10.433333"
                                }
                            ]
                        },
                        {
                            "Id": "LTFP",
                            "Name": "Leitre",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.63333, -2.8333333",
                            "IataCode": "LTF",
                            "Airports": [
                                {
                                    "Id": "LTF",
                                    "Name": "Leitre",
                                    "CityId": "LTFP",
                                    "CountryId": "PG",
                                    "Location": "141.61667, -2.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MAPP",
                            "Name": "Mamai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.53333, -10.3",
                            "IataCode": "MAP",
                            "Airports": [
                                {
                                    "Id": "MAP",
                                    "Name": "Mamai",
                                    "CityId": "MAPP",
                                    "CountryId": "PG",
                                    "Location": "149.5, -10.333333"
                                }
                            ]
                        },
                        {
                            "Id": "UUUP",
                            "Name": "Manumu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.57528, -9.0666667",
                            "IataCode": "UUU",
                            "Airports": [
                                {
                                    "Id": "UUU",
                                    "Name": "Manumu",
                                    "CityId": "UUUP",
                                    "CountryId": "PG",
                                    "Location": "147.46667, -8.999722"
                                }
                            ]
                        },
                        {
                            "Id": "VMUP",
                            "Name": "Baimuru",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.81667, -7.5",
                            "IataCode": "VMU",
                            "Airports": [
                                {
                                    "Id": "VMU",
                                    "Name": "Baimuru",
                                    "CityId": "VMUP",
                                    "CountryId": "PG",
                                    "Location": "144.8, -7.5"
                                }
                            ]
                        },
                        {
                            "Id": "AEKP",
                            "Name": "Aseki",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.2, -7.35",
                            "IataCode": "AEK",
                            "Airports": [
                                {
                                    "Id": "AEK",
                                    "Name": "Aseki",
                                    "CityId": "AEKP",
                                    "CountryId": "PG",
                                    "Location": "146.28333, -7.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MAGP",
                            "Name": "Madang",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.8, -5.2166667",
                            "IataCode": "MAG",
                            "Airports": [
                                {
                                    "Id": "MAG",
                                    "Name": "Madang",
                                    "CityId": "MAGP",
                                    "CountryId": "PG",
                                    "Location": "145.78167, -5.209167"
                                }
                            ]
                        },
                        {
                            "Id": "MBVP",
                            "Name": "Masa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.63333, -6.35",
                            "IataCode": "MBV",
                            "Airports": [
                                {
                                    "Id": "MBV",
                                    "Name": "Masa",
                                    "CityId": "MBVP",
                                    "CountryId": "PG",
                                    "Location": "147.63333, -6.35"
                                }
                            ]
                        },
                        {
                            "Id": "MDUP",
                            "Name": "Mendi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.65, -6.15",
                            "IataCode": "MDU",
                            "Airports": [
                                {
                                    "Id": "MDU",
                                    "Name": "Mendi",
                                    "CityId": "MDUP",
                                    "CountryId": "PG",
                                    "Location": "143.65, -6.158611"
                                }
                            ]
                        },
                        {
                            "Id": "MGGP",
                            "Name": "Margarima",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.36667, -5.9833333",
                            "IataCode": "MGG",
                            "Airports": [
                                {
                                    "Id": "MGG",
                                    "Name": "Margarima",
                                    "CityId": "MGGP",
                                    "CountryId": "PG",
                                    "Location": "143.36667, -6.033333"
                                }
                            ]
                        },
                        {
                            "Id": "MHYP",
                            "Name": "Morehead",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.65, -8.55",
                            "IataCode": "MHY",
                            "Airports": [
                                {
                                    "Id": "MHY",
                                    "Name": "Morehead",
                                    "CityId": "MHYP",
                                    "CountryId": "PG",
                                    "Location": "141.63333, -8.7"
                                }
                            ]
                        },
                        {
                            "Id": "MLQP",
                            "Name": "Malalaua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.16667, -8.0666667",
                            "IataCode": "MLQ",
                            "Airports": [
                                {
                                    "Id": "MLQ",
                                    "Name": "Malalaua",
                                    "CityId": "MLQP",
                                    "CountryId": "PG",
                                    "Location": "146.085, -7.991667"
                                }
                            ]
                        },
                        {
                            "Id": "MWGP",
                            "Name": "Marawaka",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.86667, -6.9833333",
                            "IataCode": "MWG",
                            "Airports": [
                                {
                                    "Id": "MWG",
                                    "Name": "Marawaka",
                                    "CityId": "MWGP",
                                    "CountryId": "PG",
                                    "Location": "146.13333, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MXKP",
                            "Name": "Mindik",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.43333, -6.4666667",
                            "IataCode": "MXK",
                            "Airports": [
                                {
                                    "Id": "MXK",
                                    "Name": "Mindik",
                                    "CityId": "MXKP",
                                    "CountryId": "PG",
                                    "Location": "147.61667, -6.383333"
                                }
                            ]
                        },
                        {
                            "Id": "MYXP",
                            "Name": "Menyamya",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.01667, -7.2333333",
                            "IataCode": "MYX",
                            "Airports": [
                                {
                                    "Id": "MYX",
                                    "Name": "Menyamya",
                                    "CityId": "MYXP",
                                    "CountryId": "PG",
                                    "Location": "146.01667, -7.2"
                                }
                            ]
                        },
                        {
                            "Id": "MZNP",
                            "Name": "Minj",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.68333, -5.9",
                            "IataCode": "MZN",
                            "Airports": [
                                {
                                    "Id": "MZN",
                                    "Name": "Minj",
                                    "CityId": "MZNP",
                                    "CountryId": "PG",
                                    "Location": "144.67667, -5.905556"
                                }
                            ]
                        },
                        {
                            "Id": "NBAP",
                            "Name": "Nambaiyufa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.25, -6.2333333",
                            "IataCode": "NBA",
                            "Airports": [
                                {
                                    "Id": "NBA",
                                    "Name": "Nambaiyufa",
                                    "CityId": "NBAP",
                                    "CountryId": "PG",
                                    "Location": "145.23333, -6.25"
                                }
                            ]
                        },
                        {
                            "Id": "NDIP",
                            "Name": "Namudi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.33333, -9.4833333",
                            "IataCode": "NDI",
                            "Airports": [
                                {
                                    "Id": "NDI",
                                    "Name": "Namudi",
                                    "CityId": "NDIP",
                                    "CountryId": "PG",
                                    "Location": "148.26667, -9.433333"
                                }
                            ]
                        },
                        {
                            "Id": "NGRP",
                            "Name": "Ningerum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.13333, -5.6833333",
                            "IataCode": "NGR",
                            "Airports": [
                                {
                                    "Id": "NGR",
                                    "Name": "Ningerum",
                                    "CityId": "NGRP",
                                    "CountryId": "PG",
                                    "Location": "141, -6.5"
                                }
                            ]
                        },
                        {
                            "Id": "NMNP",
                            "Name": "Nomane",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.08333, -6.3166667",
                            "IataCode": "NMN",
                            "Airports": [
                                {
                                    "Id": "NMN",
                                    "Name": "Nomane",
                                    "CityId": "NMNP",
                                    "CountryId": "PG",
                                    "Location": "145.06667, -16.316667"
                                }
                            ]
                        },
                        {
                            "Id": "NOOP",
                            "Name": "Naoro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.62278, -9.2561111",
                            "IataCode": "NOO",
                            "Airports": [
                                {
                                    "Id": "NOO",
                                    "Name": "Naoro",
                                    "CityId": "NOOP",
                                    "CountryId": "PG",
                                    "Location": "147.53333, -9.2"
                                }
                            ]
                        },
                        {
                            "Id": "NPGP",
                            "Name": "Nipa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.45, -6.15",
                            "IataCode": "NPG",
                            "Airports": [
                                {
                                    "Id": "NPG",
                                    "Name": "Nipa",
                                    "CityId": "NPGP",
                                    "CountryId": "PG",
                                    "Location": "143.36667, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "OBMP",
                            "Name": "Morobe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.61667, -7.75",
                            "IataCode": "OBM",
                            "Airports": [
                                {
                                    "Id": "OBM",
                                    "Name": "Morobe",
                                    "CityId": "OBMP",
                                    "CountryId": "PG",
                                    "Location": "147.6, -7.75"
                                }
                            ]
                        },
                        {
                            "Id": "OGEP",
                            "Name": "Ogeranang",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.36667, -6.4666667",
                            "IataCode": "OGE",
                            "Airports": [
                                {
                                    "Id": "OGE",
                                    "Name": "Ogeranang",
                                    "CityId": "OGEP",
                                    "CountryId": "PG",
                                    "Location": "147.46667, -6.466667"
                                }
                            ]
                        },
                        {
                            "Id": "OLQP",
                            "Name": "Olsobip",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.53333, -5.3833333",
                            "IataCode": "OLQ",
                            "Airports": [
                                {
                                    "Id": "OLQ",
                                    "Name": "Olsobip",
                                    "CityId": "OLQP",
                                    "CountryId": "PG",
                                    "Location": "141.5, -5.466667"
                                }
                            ]
                        },
                        {
                            "Id": "OMLP",
                            "Name": "Omkalai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.95, -6.1833333",
                            "IataCode": "OML",
                            "Airports": [
                                {
                                    "Id": "OML",
                                    "Name": "Omkalai",
                                    "CityId": "OMLP",
                                    "CountryId": "PG",
                                    "Location": "144.95833, -6.185556"
                                }
                            ]
                        },
                        {
                            "Id": "ONBP",
                            "Name": "Ononge",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.25, -8.6666667",
                            "IataCode": "ONB",
                            "Airports": [
                                {
                                    "Id": "ONB",
                                    "Name": "Ononge",
                                    "CityId": "ONBP",
                                    "CountryId": "PG",
                                    "Location": "147.2, -8.583333"
                                }
                            ]
                        },
                        {
                            "Id": "OPUP",
                            "Name": "Balimo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.95, -8.05",
                            "IataCode": "OPU",
                            "Airports": [
                                {
                                    "Id": "OPU",
                                    "Name": "Balimo",
                                    "CityId": "OPUP",
                                    "CountryId": "PG",
                                    "Location": "142.93994, -8.052006"
                                }
                            ]
                        },
                        {
                            "Id": "OSGP",
                            "Name": "Ossima",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.3, -2.9",
                            "IataCode": "OSG",
                            "Airports": [
                                {
                                    "Id": "OSG",
                                    "Name": "Ossima",
                                    "CityId": "OSGP",
                                    "CountryId": "PG",
                                    "Location": "141.33333, -2.966667"
                                }
                            ]
                        },
                        {
                            "Id": "PDIP",
                            "Name": "Pindiu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.51667, -6.45",
                            "IataCode": "PDI",
                            "Airports": [
                                {
                                    "Id": "PDI",
                                    "Name": "Pindiu",
                                    "CityId": "PDIP",
                                    "CountryId": "PG",
                                    "Location": "147.58333, -6.5"
                                }
                            ]
                        },
                        {
                            "Id": "PGBP",
                            "Name": "Pangoa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.61667, -7",
                            "IataCode": "PGB",
                            "Airports": [
                                {
                                    "Id": "PGB",
                                    "Name": "Pangoa",
                                    "CityId": "PGBP",
                                    "CountryId": "PG",
                                    "Location": "141.55, -7.016667"
                                }
                            ]
                        },
                        {
                            "Id": "PGNP",
                            "Name": "Pangia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.11667, -6.35",
                            "IataCode": "PGN",
                            "Airports": [
                                {
                                    "Id": "PGN",
                                    "Name": "Pangia",
                                    "CityId": "PGNP",
                                    "CountryId": "PG",
                                    "Location": "144.2, -6.3"
                                }
                            ]
                        },
                        {
                            "Id": "PUAP",
                            "Name": "Puas",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.23333, -2.3833333",
                            "IataCode": "PUA",
                            "Airports": [
                                {
                                    "Id": "PUA",
                                    "Name": "Puas",
                                    "CityId": "PUAP",
                                    "CountryId": "PG",
                                    "Location": "150.23333, -2.4"
                                }
                            ]
                        },
                        {
                            "Id": "RAAP",
                            "Name": "Rakanda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.43333, -4.2166667",
                            "IataCode": "RAA",
                            "Airports": [
                                {
                                    "Id": "RAA",
                                    "Name": "Rakanda",
                                    "CityId": "RAAP",
                                    "CountryId": "PG",
                                    "Location": "152.16667, -4.5"
                                }
                            ]
                        },
                        {
                            "Id": "RGEP",
                            "Name": "Porgera",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.2, -5.4666667",
                            "IataCode": "RGE",
                            "Airports": [
                                {
                                    "Id": "RGE",
                                    "Name": "Porgera",
                                    "CityId": "RGEP",
                                    "CountryId": "PG",
                                    "Location": "143.15, -5.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SAMP",
                            "Name": "Salamo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.8, -9.6666667",
                            "IataCode": "SAM",
                            "Airports": [
                                {
                                    "Id": "SAM",
                                    "Name": "Salamo",
                                    "CityId": "SAMP",
                                    "CountryId": "PG",
                                    "Location": "150.80833, -9.65"
                                }
                            ]
                        },
                        {
                            "Id": "SDIP",
                            "Name": "Saidor",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.46667, -5.6333333",
                            "IataCode": "SDI",
                            "Airports": [
                                {
                                    "Id": "SDI",
                                    "Name": "Saidor",
                                    "CityId": "SDIP",
                                    "CountryId": "PG",
                                    "Location": "146.5, -5.55"
                                }
                            ]
                        },
                        {
                            "Id": "SFUP",
                            "Name": "Safia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.63333, -9.5833333",
                            "IataCode": "SFU",
                            "Airports": [
                                {
                                    "Id": "SFU",
                                    "Name": "Safia",
                                    "CityId": "SFUP",
                                    "CountryId": "PG",
                                    "Location": "148.66667, -9.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SGBP",
                            "Name": "Singaua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.11667, -6.7",
                            "IataCode": "SGB",
                            "Airports": [
                                {
                                    "Id": "SGB",
                                    "Name": "Singaua",
                                    "CityId": "SGBP",
                                    "CountryId": "PG",
                                    "Location": "147.13333, -6.7"
                                }
                            ]
                        },
                        {
                            "Id": "SIMP",
                            "Name": "Simbai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.51667, -5.2833333",
                            "IataCode": "SIM",
                            "Airports": [
                                {
                                    "Id": "SIM",
                                    "Name": "Simbai",
                                    "CityId": "SIMP",
                                    "CountryId": "PG",
                                    "Location": "144.53333, -5.25"
                                }
                            ]
                        },
                        {
                            "Id": "SIZP",
                            "Name": "Sissano",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.05, -3",
                            "IataCode": "SIZ",
                            "Airports": [
                                {
                                    "Id": "SIZ",
                                    "Name": "Sissano",
                                    "CityId": "SIZP",
                                    "CountryId": "PG",
                                    "Location": "142.05, -3"
                                }
                            ]
                        },
                        {
                            "Id": "SKCP",
                            "Name": "Suki",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.71667, -8.05",
                            "IataCode": "SKC",
                            "Airports": [
                                {
                                    "Id": "SKC",
                                    "Name": "Suki",
                                    "CityId": "SKCP",
                                    "CountryId": "PG",
                                    "Location": "141.8, -8.083333"
                                }
                            ]
                        },
                        {
                            "Id": "SWEP",
                            "Name": "Siwea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.6, -6.3",
                            "IataCode": "SWE",
                            "Airports": [
                                {
                                    "Id": "SWE",
                                    "Name": "Siwea",
                                    "CityId": "SWEP",
                                    "CountryId": "PG",
                                    "Location": "147.55, -6.283333"
                                }
                            ]
                        },
                        {
                            "Id": "SWGP",
                            "Name": "Satwag",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.28333, -6.15",
                            "IataCode": "SWG",
                            "Airports": [
                                {
                                    "Id": "SWG",
                                    "Name": "Satwag",
                                    "CityId": "SWGP",
                                    "CountryId": "PG",
                                    "Location": "147.3, -6.166667"
                                }
                            ]
                        },
                        {
                            "Id": "SXHP",
                            "Name": "Sehulea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.16667, -9.9666667",
                            "IataCode": "SXH",
                            "Airports": [
                                {
                                    "Id": "SXH",
                                    "Name": "Sehulea",
                                    "CityId": "SXHP",
                                    "CountryId": "PG",
                                    "Location": "151.18333, -9.983333"
                                }
                            ]
                        },
                        {
                            "Id": "TBAP",
                            "Name": "Tabibuga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.68333, -5.6",
                            "IataCode": "TBA",
                            "Airports": [
                                {
                                    "Id": "TBA",
                                    "Name": "Tabibuga",
                                    "CityId": "TBAP",
                                    "CountryId": "PG",
                                    "Location": "144.63333, -5.55"
                                }
                            ]
                        },
                        {
                            "Id": "TBEP",
                            "Name": "Timbunke",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.51667, -4.1833333",
                            "IataCode": "TBE",
                            "Airports": [
                                {
                                    "Id": "TBE",
                                    "Name": "Timbunke",
                                    "CityId": "TBEP",
                                    "CountryId": "PG",
                                    "Location": "143.51667, -4.2"
                                }
                            ]
                        },
                        {
                            "Id": "TEPP",
                            "Name": "Teptep",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.56667, -5.95",
                            "IataCode": "TEP",
                            "Airports": [
                                {
                                    "Id": "TEP",
                                    "Name": "Teptep",
                                    "CityId": "TEPP",
                                    "CountryId": "PG",
                                    "Location": "146.5, -5.85"
                                }
                            ]
                        },
                        {
                            "Id": "TFIP",
                            "Name": "Tufi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.31667, -9.0833333",
                            "IataCode": "TFI",
                            "Airports": [
                                {
                                    "Id": "TFI",
                                    "Name": "Tufi",
                                    "CityId": "TFIP",
                                    "CountryId": "PG",
                                    "Location": "149.31984, -9.07595"
                                }
                            ]
                        },
                        {
                            "Id": "TFMP",
                            "Name": "Telefomin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.58333, -5.1333333",
                            "IataCode": "TFM",
                            "Airports": [
                                {
                                    "Id": "TFM",
                                    "Name": "Telefomin",
                                    "CityId": "TFMP",
                                    "CountryId": "PG",
                                    "Location": "141.63333, -5.183333"
                                }
                            ]
                        },
                        {
                            "Id": "TIZP",
                            "Name": "Tari",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.95, -5.7",
                            "IataCode": "TIZ",
                            "Airports": [
                                {
                                    "Id": "TIZ",
                                    "Name": "Tari",
                                    "CityId": "TIZP",
                                    "CountryId": "PG",
                                    "Location": "142.94056, -5.860556"
                                }
                            ]
                        },
                        {
                            "Id": "TKBP",
                            "Name": "Tekadu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.56667, -7.6333333",
                            "IataCode": "TKB",
                            "Airports": [
                                {
                                    "Id": "TKB",
                                    "Name": "Tekadu",
                                    "CityId": "TKBP",
                                    "CountryId": "PG",
                                    "Location": "146.55, -7.666667"
                                }
                            ]
                        },
                        {
                            "Id": "TLWP",
                            "Name": "Talasea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.03333, -5.2833333",
                            "IataCode": "TLW",
                            "Airports": [
                                {
                                    "Id": "TLW",
                                    "Name": "Talasea",
                                    "CityId": "TLWP",
                                    "CountryId": "PG",
                                    "Location": "150.01667, -5.366667"
                                }
                            ]
                        },
                        {
                            "Id": "TPIP",
                            "Name": "Tapini",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.98333, -8.3666667",
                            "IataCode": "TPI",
                            "Airports": [
                                {
                                    "Id": "TPI",
                                    "Name": "Tapini",
                                    "CityId": "TPIP",
                                    "CountryId": "PG",
                                    "Location": "146.98333, -8.366667"
                                }
                            ]
                        },
                        {
                            "Id": "TRJP",
                            "Name": "Tarakbits",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.03333, -5.6333333",
                            "IataCode": "TRJ",
                            "Airports": [
                                {
                                    "Id": "TRJ",
                                    "Name": "Tarakbits",
                                    "CityId": "TRJP",
                                    "CountryId": "PG",
                                    "Location": "141.05, -5.616667"
                                }
                            ]
                        },
                        {
                            "Id": "TUTP",
                            "Name": "Tauta",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.91667, -5.85",
                            "IataCode": "TUT",
                            "Airports": [
                                {
                                    "Id": "TUT",
                                    "Name": "Tauta",
                                    "CityId": "TUTP",
                                    "CountryId": "PG",
                                    "Location": "145.56667, -5.5"
                                }
                            ]
                        },
                        {
                            "Id": "UKUP",
                            "Name": "Nuku",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.46667, -3.68333",
                            "IataCode": "UKU",
                            "Airports": [
                                {
                                    "Id": "UKU",
                                    "Name": "Nuku",
                                    "CityId": "UKUP",
                                    "CountryId": "PG",
                                    "Location": "142.46667, -3.683333"
                                }
                            ]
                        },
                        {
                            "Id": "ULEP",
                            "Name": "Sule",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.3, -4.9666667",
                            "IataCode": "ULE",
                            "Airports": [
                                {
                                    "Id": "ULE",
                                    "Name": "Sule",
                                    "CityId": "ULEP",
                                    "CountryId": "PG",
                                    "Location": "151.26667, -4.933333"
                                }
                            ]
                        },
                        {
                            "Id": "UNGP",
                            "Name": "Kiunga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.3, -6.1166667",
                            "IataCode": "UNG",
                            "Airports": [
                                {
                                    "Id": "UNG",
                                    "Name": "Kiunga",
                                    "CityId": "UNGP",
                                    "CountryId": "PG",
                                    "Location": "141.29167, -6.116667"
                                }
                            ]
                        },
                        {
                            "Id": "UPRP",
                            "Name": "Upiara",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.65, -8.55",
                            "IataCode": "UPR",
                            "Airports": [
                                {
                                    "Id": "UPR",
                                    "Name": "Upiara",
                                    "CityId": "UPRP",
                                    "CountryId": "PG",
                                    "Location": "142.63333, -8.533333"
                                }
                            ]
                        },
                        {
                            "Id": "WWKP",
                            "Name": "Wewak",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.63333, -3.55",
                            "IataCode": "WWK",
                            "Airports": [
                                {
                                    "Id": "WWK",
                                    "Name": "Wewak",
                                    "CityId": "WWKP",
                                    "CountryId": "PG",
                                    "Location": "143.67333, -3.585833"
                                }
                            ]
                        },
                        {
                            "Id": "USOP",
                            "Name": "Usino",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.38333, -5.5333333",
                            "IataCode": "USO",
                            "Airports": [
                                {
                                    "Id": "USO",
                                    "Name": "Usino",
                                    "CityId": "USOP",
                                    "CountryId": "PG",
                                    "Location": "145.43333, -5.5"
                                }
                            ]
                        },
                        {
                            "Id": "UVOP",
                            "Name": "Uvol",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151, -6.0166667",
                            "IataCode": "UVO",
                            "Airports": [
                                {
                                    "Id": "UVO",
                                    "Name": "Uvol",
                                    "CityId": "UVOP",
                                    "CountryId": "PG",
                                    "Location": "150.96667, -5.975"
                                }
                            ]
                        },
                        {
                            "Id": "VAIP",
                            "Name": "Vanimo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.3, -2.6833333",
                            "IataCode": "VAI",
                            "Airports": [
                                {
                                    "Id": "VAI",
                                    "Name": "Vanimo",
                                    "CityId": "VAIP",
                                    "CountryId": "PG",
                                    "Location": "141.30194, -2.694722"
                                }
                            ]
                        },
                        {
                            "Id": "WBMP",
                            "Name": "Wapenamanda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.91667, -5.6333333",
                            "IataCode": "WBM",
                            "Airports": [
                                {
                                    "Id": "WBM",
                                    "Name": "Wapenamanda",
                                    "CityId": "WBMP",
                                    "CountryId": "PG",
                                    "Location": "143.895, -5.6433"
                                }
                            ]
                        },
                        {
                            "Id": "WEDP",
                            "Name": "Wedau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.08333, -10.1",
                            "IataCode": "WED",
                            "Airports": [
                                {
                                    "Id": "WED",
                                    "Name": "Wedau",
                                    "CityId": "WEDP",
                                    "CountryId": "PG",
                                    "Location": "150.08333, -10.1"
                                }
                            ]
                        },
                        {
                            "Id": "WNUP",
                            "Name": "Wanuma",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.31667, -4.9",
                            "IataCode": "WNU",
                            "Airports": [
                                {
                                    "Id": "WNU",
                                    "Name": "Wanuma",
                                    "CityId": "WNUP",
                                    "CountryId": "PG",
                                    "Location": "145.31667, -4.9"
                                }
                            ]
                        },
                        {
                            "Id": "WOAP",
                            "Name": "Wonenara",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.88333, -6.8",
                            "IataCode": "WOA",
                            "Airports": [
                                {
                                    "Id": "WOA",
                                    "Name": "Wonenara",
                                    "CityId": "WOAP",
                                    "CountryId": "PG",
                                    "Location": "145.88333, -6.8"
                                }
                            ]
                        },
                        {
                            "Id": "WPMP",
                            "Name": "Wipim",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.86667, -8.7833333",
                            "IataCode": "WPM",
                            "Airports": [
                                {
                                    "Id": "WPM",
                                    "Name": "Wipim",
                                    "CityId": "WPMP",
                                    "CountryId": "PG",
                                    "Location": "143.03333, -8.816667"
                                }
                            ]
                        },
                        {
                            "Id": "WSAP",
                            "Name": "Wasua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.86667, -8.3",
                            "IataCode": "WSA",
                            "Airports": [
                                {
                                    "Id": "WSA",
                                    "Name": "Wasua",
                                    "CityId": "WSAP",
                                    "CountryId": "PG",
                                    "Location": "142.86667, -8.283333"
                                }
                            ]
                        },
                        {
                            "Id": "WSUP",
                            "Name": "Wasu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.2, -5.9666667",
                            "IataCode": "WSU",
                            "Airports": [
                                {
                                    "Id": "WSU",
                                    "Name": "Wasu",
                                    "CityId": "WSUP",
                                    "CountryId": "PG",
                                    "Location": "147.23333, -5.95"
                                }
                            ]
                        },
                        {
                            "Id": "ZENA",
                            "Name": "Zenag",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.58333, -6.95",
                            "IataCode": "ZEN",
                            "Airports": [
                                {
                                    "Id": "ZEN",
                                    "Name": "Zenag",
                                    "CityId": "ZENA",
                                    "CountryId": "PG",
                                    "Location": "146.61667, -6.95"
                                }
                            ]
                        },
                        {
                            "Id": "WABP",
                            "Name": "Wabag",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.7, -5.4833333",
                            "IataCode": "WAB",
                            "Airports": [
                                {
                                    "Id": "WAB",
                                    "Name": "Wabag",
                                    "CityId": "WABP",
                                    "CountryId": "PG",
                                    "Location": "143.73333, -5.483333"
                                }
                            ]
                        },
                        {
                            "Id": "LNCP",
                            "Name": "Lengbati",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.36667, -6.3833333",
                            "IataCode": "LNC",
                            "Airports": [
                                {
                                    "Id": "LNC",
                                    "Name": "Lengbati",
                                    "CityId": "LNCP",
                                    "CountryId": "PG",
                                    "Location": "147.36667, -6.366667"
                                }
                            ]
                        },
                        {
                            "Id": "AGLP",
                            "Name": "Wanigela",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.16667, -9.3333333",
                            "IataCode": "AGL",
                            "Airports": [
                                {
                                    "Id": "AGL",
                                    "Name": "Wanigela",
                                    "CityId": "AGLP",
                                    "CountryId": "PG",
                                    "Location": "149.15528, -9.340278"
                                }
                            ]
                        },
                        {
                            "Id": "WTPP",
                            "Name": "Woitape",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.25, -8.55",
                            "IataCode": "WTP",
                            "Airports": [
                                {
                                    "Id": "WTP",
                                    "Name": "Woitape",
                                    "CityId": "WTPP",
                                    "CountryId": "PG",
                                    "Location": "147.25, -8.55"
                                }
                            ]
                        },
                        {
                            "Id": "WTTP",
                            "Name": "Wantoat",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.48333, -6.1333333",
                            "IataCode": "WTT",
                            "Airports": [
                                {
                                    "Id": "WTT",
                                    "Name": "Wantoat",
                                    "CityId": "WTTP",
                                    "CountryId": "PG",
                                    "Location": "146.4, -6.05"
                                }
                            ]
                        },
                        {
                            "Id": "WUGP",
                            "Name": "Wau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.71667, -7.3333333",
                            "IataCode": "WUG",
                            "Airports": [
                                {
                                    "Id": "WUG",
                                    "Name": "Wau",
                                    "CityId": "WUGP",
                                    "CountryId": "PG",
                                    "Location": "146.71833, -7.3375"
                                }
                            ]
                        },
                        {
                            "Id": "WUMP",
                            "Name": "Wasum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.35, -6.05",
                            "IataCode": "WUM",
                            "Airports": [
                                {
                                    "Id": "WUM",
                                    "Name": "Wasum",
                                    "CityId": "WUMP",
                                    "CountryId": "PG",
                                    "Location": "149.33333, -6.05"
                                }
                            ]
                        },
                        {
                            "Id": "WKNP",
                            "Name": "Wakunai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.21667, -5.8666667",
                            "IataCode": "WKN",
                            "Airports": [
                                {
                                    "Id": "WKN",
                                    "Name": "Wakunai",
                                    "CityId": "WKNP",
                                    "CountryId": "PG",
                                    "Location": "155.21667, -5.866667"
                                }
                            ]
                        },
                        {
                            "Id": "UMCP",
                            "Name": "Umba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.96667, -7.0333333",
                            "IataCode": "UMC",
                            "Airports": [
                                {
                                    "Id": "UMC",
                                    "Name": "Umba",
                                    "CityId": "UMCP",
                                    "CountryId": "PG",
                                    "Location": "145.96667, -7.016667"
                                }
                            ]
                        },
                        {
                            "Id": "TSIP",
                            "Name": "Tsili Tsili",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.36667, -6.85",
                            "IataCode": "TSI",
                            "Airports": [
                                {
                                    "Id": "TSI",
                                    "Name": "Tsili Tsili",
                                    "CityId": "TSIP",
                                    "CountryId": "PG",
                                    "Location": "146.35, -6.85"
                                }
                            ]
                        },
                        {
                            "Id": "TOKP",
                            "Name": "Torokina",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.05, -6.2333333",
                            "IataCode": "TOK",
                            "Airports": [
                                {
                                    "Id": "TOK",
                                    "Name": "Torokina",
                                    "CityId": "TOKP",
                                    "CountryId": "PG",
                                    "Location": "155.33333, -6.233333"
                                }
                            ]
                        },
                        {
                            "Id": "TCJP",
                            "Name": "Torembi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.13333, -4.0166667",
                            "IataCode": "TCJ",
                            "Airports": [
                                {
                                    "Id": "TCJ",
                                    "Name": "Torembi",
                                    "CityId": "TCJP",
                                    "CountryId": "PG",
                                    "Location": "143.13333, -4.016667"
                                }
                            ]
                        },
                        {
                            "Id": "TIGP",
                            "Name": "Tingwon",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.71667, -2.6166667",
                            "IataCode": "TIG",
                            "Airports": [
                                {
                                    "Id": "TIG",
                                    "Name": "Tingwon",
                                    "CityId": "TIGP",
                                    "CountryId": "PG",
                                    "Location": "149.78333, -2.6"
                                }
                            ]
                        },
                        {
                            "Id": "TCKP",
                            "Name": "Tinboli",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.38333, -4.1",
                            "IataCode": "TCK",
                            "Airports": [
                                {
                                    "Id": "TCK",
                                    "Name": "Tinboli",
                                    "CityId": "TCKP",
                                    "CountryId": "PG",
                                    "Location": "143.38333, -4.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TFBP",
                            "Name": "Tifalmin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.46667, -5.1",
                            "IataCode": "TFB",
                            "Airports": [
                                {
                                    "Id": "TFB",
                                    "Name": "Tifalmin",
                                    "CityId": "TFBP",
                                    "CountryId": "PG",
                                    "Location": "141.41778, -5.118333"
                                }
                            ]
                        },
                        {
                            "Id": "TSKP",
                            "Name": "Taskul",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.45, -2.55",
                            "IataCode": "TSK",
                            "Airports": [
                                {
                                    "Id": "TSK",
                                    "Name": "Taskul",
                                    "CityId": "TSKP",
                                    "CountryId": "PG",
                                    "Location": "150.41667, -2.566667"
                                }
                            ]
                        },
                        {
                            "Id": "TBQP",
                            "Name": "Tarabo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.53333, -6.4666667",
                            "IataCode": "TBQ",
                            "Airports": [
                                {
                                    "Id": "TBQ",
                                    "Name": "Tarabo",
                                    "CityId": "TBQP",
                                    "CountryId": "PG",
                                    "Location": "145.53222, -6.466667"
                                }
                            ]
                        },
                        {
                            "Id": "SPHP",
                            "Name": "Sopu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.2, -8.6",
                            "IataCode": "SPH",
                            "Airports": [
                                {
                                    "Id": "SPH",
                                    "Name": "Sopu",
                                    "CityId": "SPHP",
                                    "CountryId": "PG",
                                    "Location": "147.76667, -9"
                                }
                            ]
                        },
                        {
                            "Id": "NISP",
                            "Name": "Simberi Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.95, -2.6666667",
                            "IataCode": "NIS",
                            "Airports": [
                                {
                                    "Id": "NIS",
                                    "Name": "Simberi Island",
                                    "CityId": "NISP",
                                    "CountryId": "PG",
                                    "Location": "152, -2.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KDQP",
                            "Name": "Kamberatoro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.06667, -3.6166667",
                            "IataCode": "KDQ",
                            "Airports": [
                                {
                                    "Id": "KDQ",
                                    "Name": "Kamberatoro",
                                    "CityId": "KDQP",
                                    "CountryId": "PG",
                                    "Location": "141.05, -3.605556"
                                }
                            ]
                        },
                        {
                            "Id": "MKNP",
                            "Name": "Malekolon",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "153.65, -4.0166667",
                            "IataCode": "MKN",
                            "Airports": [
                                {
                                    "Id": "MKN",
                                    "Name": "Malekolon",
                                    "CityId": "MKNP",
                                    "CountryId": "PG",
                                    "Location": "153.675, -4.016667"
                                }
                            ]
                        },
                        {
                            "Id": "MQOP",
                            "Name": "Malam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.65, -8.7166667",
                            "IataCode": "MQO",
                            "Airports": [
                                {
                                    "Id": "MQO",
                                    "Name": "Malam",
                                    "CityId": "MQOP",
                                    "CountryId": "PG",
                                    "Location": "142.65111, -8.703889"
                                }
                            ]
                        },
                        {
                            "Id": "MPUP",
                            "Name": "Mapua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152, -2.8166667",
                            "IataCode": "MPU",
                            "Airports": [
                                {
                                    "Id": "MPU",
                                    "Name": "Mapua",
                                    "CityId": "MPUP",
                                    "CountryId": "PG",
                                    "Location": "151.4, -2.9"
                                }
                            ]
                        },
                        {
                            "Id": "LSAP",
                            "Name": "Losuia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.06667, -8.5333333",
                            "IataCode": "LSA",
                            "Airports": [
                                {
                                    "Id": "LSA",
                                    "Name": "Losuia",
                                    "CityId": "LSAP",
                                    "CountryId": "PG",
                                    "Location": "151.08099, -8.50582"
                                }
                            ]
                        },
                        {
                            "Id": "KTKP",
                            "Name": "Kanua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.71667, -5.7666667",
                            "IataCode": "KTK",
                            "Airports": [
                                {
                                    "Id": "KTK",
                                    "Name": "Kanua",
                                    "CityId": "KTKP",
                                    "CountryId": "PG",
                                    "Location": "154.75, -5.338889"
                                }
                            ]
                        },
                        {
                            "Id": "KCJP",
                            "Name": "Komaio",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.6, -7.2166667",
                            "IataCode": "KCJ",
                            "Airports": [
                                {
                                    "Id": "KCJ",
                                    "Name": "Komaio",
                                    "CityId": "KCJP",
                                    "CountryId": "PG",
                                    "Location": "143.6, -7.266667"
                                }
                            ]
                        },
                        {
                            "Id": "KIEP",
                            "Name": "Kieta",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.63333, -6.2166667",
                            "IataCode": "KIE",
                            "Airports": [
                                {
                                    "Id": "KIE",
                                    "Name": "Kieta",
                                    "CityId": "KIEP",
                                    "CountryId": "PG",
                                    "Location": "155.72575, -6.304386"
                                }
                            ]
                        },
                        {
                            "Id": "KIIP",
                            "Name": "Kibuli",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.71667, -9.0166667",
                            "IataCode": "KII",
                            "Airports": [
                                {
                                    "Id": "KII",
                                    "Name": "Kibuli",
                                    "CityId": "KIIP",
                                    "CountryId": "PG",
                                    "Location": "142.69528, -9.016389"
                                }
                            ]
                        },
                        {
                            "Id": "KPLP",
                            "Name": "Kapal",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.71667, -8.65",
                            "IataCode": "KPL",
                            "Airports": [
                                {
                                    "Id": "KPL",
                                    "Name": "Kapal",
                                    "CityId": "KPLP",
                                    "CountryId": "PG",
                                    "Location": "142.82444, -8.633056"
                                }
                            ]
                        },
                        {
                            "Id": "ITKP",
                            "Name": "Itokama",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.26667, -9.2",
                            "IataCode": "ITK",
                            "Airports": [
                                {
                                    "Id": "ITK",
                                    "Name": "Itokama",
                                    "CityId": "ITKP",
                                    "CountryId": "PG",
                                    "Location": "148.2, -9.3"
                                }
                            ]
                        },
                        {
                            "Id": "GOCP",
                            "Name": "Gora",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.6, -9.1666667",
                            "IataCode": "GOC",
                            "Airports": [
                                {
                                    "Id": "GOC",
                                    "Name": "Gora",
                                    "CityId": "GOCP",
                                    "CountryId": "PG",
                                    "Location": "148.81667, -9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GMIP",
                            "Name": "Gasmata Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.33333, -6.2833333",
                            "IataCode": "GMI",
                            "Airports": [
                                {
                                    "Id": "GMI",
                                    "Name": "Gasmata Island",
                                    "CityId": "GMIP",
                                    "CountryId": "PG",
                                    "Location": "150.33333, -6.3"
                                }
                            ]
                        },
                        {
                            "Id": "FNEP",
                            "Name": "Fane",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.13333, -8.2833333",
                            "IataCode": "FNE",
                            "Airports": [
                                {
                                    "Id": "FNE",
                                    "Name": "Fane",
                                    "CityId": "FNEP",
                                    "CountryId": "PG",
                                    "Location": "147.23333, -8.6"
                                }
                            ]
                        },
                        {
                            "Id": "DOSP",
                            "Name": "Dios",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.96667, -5.55",
                            "IataCode": "DOS",
                            "Airports": [
                                {
                                    "Id": "DOS",
                                    "Name": "Dios",
                                    "CityId": "DOSP",
                                    "CountryId": "PG",
                                    "Location": "154.83333, -5.533333"
                                }
                            ]
                        },
                        {
                            "Id": "UBIP",
                            "Name": "Buin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.73333, -6.8333333",
                            "IataCode": "UBI",
                            "Airports": [
                                {
                                    "Id": "UBI",
                                    "Name": "Buin",
                                    "CityId": "UBIP",
                                    "CountryId": "PG",
                                    "Location": "155.7, -6.716667"
                                }
                            ]
                        },
                        {
                            "Id": "BOQP",
                            "Name": "Boku",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.35, -6.5666667",
                            "IataCode": "BOQ",
                            "Airports": [
                                {
                                    "Id": "BOQ",
                                    "Name": "Boku",
                                    "CityId": "BOQP",
                                    "CountryId": "PG",
                                    "Location": "155.35, -6.533333"
                                }
                            ]
                        },
                        {
                            "Id": "AUVP",
                            "Name": "Aumo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.5, -5.7333333",
                            "IataCode": "AUV",
                            "Airports": [
                                {
                                    "Id": "AUV",
                                    "Name": "Aumo",
                                    "CityId": "AUVP",
                                    "CountryId": "PG",
                                    "Location": "148.43333, -5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "RAWP",
                            "Name": "Arawa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.55, -6.2166667",
                            "IataCode": "RAW",
                            "Airports": [
                                {
                                    "Id": "RAW",
                                    "Name": "Arawa",
                                    "CityId": "RAWP",
                                    "CountryId": "PG",
                                    "Location": "155.58333, -6.25"
                                }
                            ]
                        },
                        {
                            "Id": "ADCP",
                            "Name": "Andakombe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.73333, -7.1333333",
                            "IataCode": "ADC",
                            "Airports": [
                                {
                                    "Id": "ADC",
                                    "Name": "Andakombe",
                                    "CityId": "ADCP",
                                    "CountryId": "PG",
                                    "Location": "145.73333, -7.133333"
                                }
                            ]
                        },
                        {
                            "Id": "AIHP",
                            "Name": "Aiambak",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.26667, -7.35",
                            "IataCode": "AIH",
                            "Airports": [
                                {
                                    "Id": "AIH",
                                    "Name": "Aiambak",
                                    "CityId": "AIHP",
                                    "CountryId": "PG",
                                    "Location": "141.26639, -7.348611"
                                }
                            ]
                        },
                        {
                            "Id": "MVIP",
                            "Name": "Manetai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.38611, -6.1166667",
                            "IataCode": "MVI",
                            "Airports": [
                                {
                                    "Id": "MVI",
                                    "Name": "Manetai",
                                    "CityId": "MVIP",
                                    "CountryId": "PG",
                                    "Location": "155.33333, -6"
                                }
                            ]
                        },
                        {
                            "Id": "TONP",
                            "Name": "Tonu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.425, -6.65",
                            "IataCode": "TON",
                            "Airports": [
                                {
                                    "Id": "TON",
                                    "Name": "Tonu",
                                    "CityId": "TONP",
                                    "CountryId": "PG",
                                    "Location": "155.59167, -6.35"
                                }
                            ]
                        },
                        {
                            "Id": "SQTP",
                            "Name": "Samarai Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.66667, -10.616667",
                            "IataCode": "SQT",
                            "Airports": [
                                {
                                    "Id": "SQT",
                                    "Name": "Samarai Island",
                                    "CityId": "SQTP",
                                    "CountryId": "PG",
                                    "Location": "150.68333, -10.55"
                                }
                            ]
                        },
                        {
                            "Id": "AUIP",
                            "Name": "Aua Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.067, -1.45",
                            "IataCode": "AUI",
                            "Airports": [
                                {
                                    "Id": "AUI",
                                    "Name": "Aua Island",
                                    "CityId": "AUIP",
                                    "CountryId": "PG",
                                    "Location": "143.075, -1.458333"
                                }
                            ]
                        },
                        {
                            "Id": "BUAP",
                            "Name": "Buka",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.633, -5.25",
                            "IataCode": "BUA",
                            "Airports": [
                                {
                                    "Id": "BUA",
                                    "Name": "Buka",
                                    "CityId": "BUAP",
                                    "CountryId": "PG",
                                    "Location": "154.67394, -5.421125"
                                }
                            ]
                        },
                        {
                            "Id": "GEIP",
                            "Name": "Green Islands",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.167, -4.5",
                            "IataCode": "GEI",
                            "Airports": [
                                {
                                    "Id": "GEI",
                                    "Name": "Green Islands",
                                    "CityId": "GEIP",
                                    "CountryId": "PG",
                                    "Location": "154.16667, -4.5"
                                }
                            ]
                        },
                        {
                            "Id": "KRXP",
                            "Name": "Kar Kar",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.967, -4.65",
                            "IataCode": "KRX",
                            "Airports": [
                                {
                                    "Id": "KRX",
                                    "Name": "Kar Kar",
                                    "CityId": "KRXP",
                                    "CountryId": "PG",
                                    "Location": "145.96667, -4.5"
                                }
                            ]
                        },
                        {
                            "Id": "KWXP",
                            "Name": "Kiwai Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.41667, -8.55",
                            "IataCode": "KWX",
                            "Airports": [
                                {
                                    "Id": "KWX",
                                    "Name": "Kiwai Island",
                                    "CityId": "KWXP",
                                    "CountryId": "PG",
                                    "Location": "143.41667, -8.583333"
                                }
                            ]
                        },
                        {
                            "Id": "LNVP",
                            "Name": "Lihir Group",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.6125, -3.0625",
                            "IataCode": "LNV",
                            "Airports": [
                                {
                                    "Id": "LNV",
                                    "Name": "Lihir Island",
                                    "CityId": "LNVP",
                                    "CountryId": "PG",
                                    "Location": "152.61667, -3.066667"
                                }
                            ]
                        },
                        {
                            "Id": "LSJP",
                            "Name": "Long Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.117, -5.35",
                            "IataCode": "LSJ",
                            "Airports": [
                                {
                                    "Id": "LSJ",
                                    "Name": "Long Island",
                                    "CityId": "LSJP",
                                    "CountryId": "PG",
                                    "Location": "147.08333, -5.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MASP",
                            "Name": "Manus Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147, -2.083333",
                            "IataCode": "MAS",
                            "Airports": [
                                {
                                    "Id": "MAS",
                                    "Name": "Manus Island",
                                    "CityId": "MASP",
                                    "CountryId": "PG",
                                    "Location": "147.42444, -2.056667"
                                }
                            ]
                        },
                        {
                            "Id": "MISP",
                            "Name": "Misima Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.75, -10.667",
                            "IataCode": "MIS",
                            "Airports": [
                                {
                                    "Id": "MIS",
                                    "Name": "Misima Island",
                                    "CityId": "MISP",
                                    "CountryId": "PG",
                                    "Location": "152.838, -10.6892"
                                }
                            ]
                        },
                        {
                            "Id": "MWUP",
                            "Name": "Mussau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.63333, -1.416667",
                            "IataCode": "MWU",
                            "Airports": [
                                {
                                    "Id": "MWU",
                                    "Name": "Mussau",
                                    "CityId": "MWUP",
                                    "CountryId": "PG",
                                    "Location": "149.66667, -1.5"
                                }
                            ]
                        },
                        {
                            "Id": "IISP",
                            "Name": "Nissan Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.217, -4.5",
                            "IataCode": "IIS",
                            "Airports": [
                                {
                                    "Id": "IIS",
                                    "Name": "Nissan Island",
                                    "CityId": "IISP",
                                    "CountryId": "PG",
                                    "Location": "154.34167, -4.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TGLP",
                            "Name": "Tagula",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "153.433, -11.5",
                            "IataCode": "TGL",
                            "Airports": [
                                {
                                    "Id": "TGL",
                                    "Name": "Tagula",
                                    "CityId": "TGLP",
                                    "CountryId": "PG",
                                    "Location": "153.5, -11.5"
                                }
                            ]
                        },
                        {
                            "Id": "WUVP",
                            "Name": "Wuvulu Is",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.83333, -1.716667",
                            "IataCode": "WUV",
                            "Airports": [
                                {
                                    "Id": "WUV",
                                    "Name": "Wuvulu Is",
                                    "CityId": "WUVP",
                                    "CountryId": "PG",
                                    "Location": "142.83333, -1.75"
                                }
                            ]
                        },
                        {
                            "Id": "RKUP",
                            "Name": "Yule Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.533, -8.817",
                            "IataCode": "RKU",
                            "Airports": [
                                {
                                    "Id": "RKU",
                                    "Name": "Yule Island",
                                    "CityId": "RKUP",
                                    "CountryId": "PG",
                                    "Location": "146.51667, -8.816667"
                                }
                            ]
                        },
                        {
                            "Id": "EMIP",
                            "Name": "Emirau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.975, -1.6417",
                            "IataCode": "EMI",
                            "Airports": [
                                {
                                    "Id": "EMI",
                                    "Name": "Emirau",
                                    "CityId": "EMIP",
                                    "CountryId": "PG",
                                    "Location": "149.98333, -1.65"
                                }
                            ]
                        },
                        {
                            "Id": "KAKP",
                            "Name": "Yukyuk",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.01667, -4.7",
                            "IataCode": "KAK",
                            "Airports": [
                                {
                                    "Id": "KAK",
                                    "Name": "Kar",
                                    "CityId": "KAKP",
                                    "CountryId": "PG",
                                    "Location": "146, -4.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SBCP",
                            "Name": "Yengimup",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.68333, -5.1833333",
                            "IataCode": "SBC",
                            "Airports": [
                                {
                                    "Id": "SBC",
                                    "Name": "Selbang",
                                    "CityId": "SBCP",
                                    "CountryId": "PG",
                                    "Location": "141.75, -5.316667"
                                }
                            ]
                        },
                        {
                            "Id": "PGEP",
                            "Name": "Yagepa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.15, -7.1166667",
                            "IataCode": "PGE",
                            "Airports": [
                                {
                                    "Id": "PGE",
                                    "Name": "Yegepa",
                                    "CityId": "PGEP",
                                    "CountryId": "PG",
                                    "Location": "146.15, -7.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LWIP",
                            "Name": "Wununga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.6, -6.35",
                            "IataCode": "LWI",
                            "Airports": [
                                {
                                    "Id": "LWI",
                                    "Name": "Lowai",
                                    "CityId": "LWIP",
                                    "CountryId": "PG",
                                    "Location": "146.58333, -6.316667"
                                }
                            ]
                        },
                        {
                            "Id": "BSPP",
                            "Name": "Wereave",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.08333, -8.333333",
                            "IataCode": "BSP",
                            "Airports": [
                                {
                                    "Id": "BSP",
                                    "Name": "Bensbach",
                                    "CityId": "BSPP",
                                    "CountryId": "PG",
                                    "Location": "141.08333, -8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "MGPP",
                            "Name": "Warangansau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.95, -4.0166667",
                            "IataCode": "MGP",
                            "Airports": [
                                {
                                    "Id": "MGP",
                                    "Name": "Manga",
                                    "CityId": "MGPP",
                                    "CountryId": "PG",
                                    "Location": "152.96667, -4.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PMNP",
                            "Name": "Wapon",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.4, -9.7",
                            "IataCode": "PMN",
                            "Airports": [
                                {
                                    "Id": "PMN",
                                    "Name": "Pumani",
                                    "CityId": "PMNP",
                                    "CountryId": "PG",
                                    "Location": "149.4, -9.716667"
                                }
                            ]
                        },
                        {
                            "Id": "AMFP",
                            "Name": "Waniap",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.73333, -4.2166667",
                            "IataCode": "AMF",
                            "Airports": [
                                {
                                    "Id": "AMF",
                                    "Name": "Ama",
                                    "CityId": "AMFP",
                                    "CountryId": "PG",
                                    "Location": "141.66667, -4.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TBGP",
                            "Name": "Wangbin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.226, -5.27861",
                            "IataCode": "TBG",
                            "Airports": [
                                {
                                    "Id": "TBG",
                                    "Name": "Tabubil",
                                    "CityId": "TBGP",
                                    "CountryId": "PG",
                                    "Location": "141.226, -5.27861"
                                }
                            ]
                        },
                        {
                            "Id": "MPFP",
                            "Name": "Waia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.2, -7.95",
                            "IataCode": "MPF",
                            "Airports": [
                                {
                                    "Id": "MPF",
                                    "Name": "Mapoda",
                                    "CityId": "MPFP",
                                    "CountryId": "PG",
                                    "Location": "143.16667, -7.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CVLP",
                            "Name": "Wagegia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.48333, -9.4833333",
                            "IataCode": "CVL",
                            "Airports": [
                                {
                                    "Id": "CVL",
                                    "Name": "Cape Vogel",
                                    "CityId": "CVLP",
                                    "CountryId": "PG",
                                    "Location": "150.5, -9.466667"
                                }
                            ]
                        },
                        {
                            "Id": "RUUP",
                            "Name": "Vomunk",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.33333, -5.2333333",
                            "IataCode": "RUU",
                            "Airports": [
                                {
                                    "Id": "RUU",
                                    "Name": "Ruti",
                                    "CityId": "RUUP",
                                    "CountryId": "PG",
                                    "Location": "144.15, -5.183333"
                                }
                            ]
                        },
                        {
                            "Id": "SUZP",
                            "Name": "Venuai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.6, -9.0833333",
                            "IataCode": "SUZ",
                            "Airports": [
                                {
                                    "Id": "SUZ",
                                    "Name": "Suria",
                                    "CityId": "SUZP",
                                    "CountryId": "PG",
                                    "Location": "147.6, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DDMP",
                            "Name": "Vailala East",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.41667, -7.95",
                            "IataCode": "DDM",
                            "Airports": [
                                {
                                    "Id": "DDM",
                                    "Name": "Dodoima",
                                    "CityId": "DDMP",
                                    "CountryId": "PG",
                                    "Location": "145.16667, -8.483333"
                                }
                            ]
                        },
                        {
                            "Id": "MRHP",
                            "Name": "Vailala",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.45, -7.95",
                            "IataCode": "MRH",
                            "Airports": [
                                {
                                    "Id": "MRH",
                                    "Name": "May River",
                                    "CityId": "MRHP",
                                    "CountryId": "PG",
                                    "Location": "145.33333, -8.466667"
                                }
                            ]
                        },
                        {
                            "Id": "YVDP",
                            "Name": "Uyeiwata",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.16667, -7.5666667",
                            "IataCode": "YVD",
                            "Airports": [
                                {
                                    "Id": "YVD",
                                    "Name": "Yeva",
                                    "CityId": "YVDP",
                                    "CountryId": "PG",
                                    "Location": "146.18889, -7.55"
                                }
                            ]
                        },
                        {
                            "Id": "RAXP",
                            "Name": "Ununom",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.03333, -9.6833333",
                            "IataCode": "RAX",
                            "Airports": [
                                {
                                    "Id": "RAX",
                                    "Name": "Oram",
                                    "CityId": "RAXP",
                                    "CountryId": "PG",
                                    "Location": "148.03333, -9.672222"
                                }
                            ]
                        },
                        {
                            "Id": "OTYP",
                            "Name": "Ububakogu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.76667, -6.6",
                            "IataCode": "OTY",
                            "Airports": [
                                {
                                    "Id": "OTY",
                                    "Name": "Oria",
                                    "CityId": "OTYP",
                                    "CountryId": "PG",
                                    "Location": "155.76667, -6.6"
                                }
                            ]
                        },
                        {
                            "Id": "KSXP",
                            "Name": "Tumbuna",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.15, -6.5833333",
                            "IataCode": "KSX",
                            "Airports": [
                                {
                                    "Id": "KSX",
                                    "Name": "Yasuru",
                                    "CityId": "KSXP",
                                    "CountryId": "PG",
                                    "Location": "146.18333, -6.6"
                                }
                            ]
                        },
                        {
                            "Id": "SGKP",
                            "Name": "Tsowak",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.78333, -5.05",
                            "IataCode": "SGK",
                            "Airports": [
                                {
                                    "Id": "SGK",
                                    "Name": "Sangapi",
                                    "CityId": "SGKP",
                                    "CountryId": "PG",
                                    "Location": "144.76667, -5.083333"
                                }
                            ]
                        },
                        {
                            "Id": "GBFP",
                            "Name": "Tivi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.7, -6.5166667",
                            "IataCode": "GBF",
                            "Airports": [
                                {
                                    "Id": "GBF",
                                    "Name": "Negarbo",
                                    "CityId": "GBFP",
                                    "CountryId": "PG",
                                    "Location": "144.66667, -6.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SBVP",
                            "Name": "Tendu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.9, -5.5333333",
                            "IataCode": "SBV",
                            "Airports": [
                                {
                                    "Id": "SBV",
                                    "Name": "Sabah",
                                    "CityId": "SBVP",
                                    "CountryId": "PG",
                                    "Location": "155.01667, -5.333333"
                                }
                            ]
                        },
                        {
                            "Id": "BOVP",
                            "Name": "Taubie",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "153.33333, -3.3833333",
                            "IataCode": "BOV",
                            "Airports": [
                                {
                                    "Id": "BOV",
                                    "Name": "Boang",
                                    "CityId": "BOVP",
                                    "CountryId": "PG",
                                    "Location": "153.33333, -3.366667"
                                }
                            ]
                        },
                        {
                            "Id": "AUPP",
                            "Name": "Taubadi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.61667, -9.9833333",
                            "IataCode": "AUP",
                            "Airports": [
                                {
                                    "Id": "AUP",
                                    "Name": "Agaun",
                                    "CityId": "AUPP",
                                    "CountryId": "PG",
                                    "Location": "149.58333, -9.983333"
                                }
                            ]
                        },
                        {
                            "Id": "SILP",
                            "Name": "Tanipai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.4, -8.4333333",
                            "IataCode": "SIL",
                            "Airports": [
                                {
                                    "Id": "SIL",
                                    "Name": "Sila",
                                    "CityId": "SILP",
                                    "CountryId": "PG",
                                    "Location": "147.5, -8.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TWYP",
                            "Name": "Tamoi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.08333, -7.2833333",
                            "IataCode": "TWY",
                            "Airports": [
                                {
                                    "Id": "TWY",
                                    "Name": "Tawa",
                                    "CityId": "TWYP",
                                    "CountryId": "PG",
                                    "Location": "146.06667, -7.266667"
                                }
                            ]
                        },
                        {
                            "Id": "KPEP",
                            "Name": "Suriap",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.18333, -4.0666667",
                            "IataCode": "KPE",
                            "Airports": [
                                {
                                    "Id": "KPE",
                                    "Name": "Yapsiei",
                                    "CityId": "KPEP",
                                    "CountryId": "PG",
                                    "Location": "141.16667, -4.05"
                                }
                            ]
                        },
                        {
                            "Id": "HBDP",
                            "Name": "Sulububi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.48333, -6.3333333",
                            "IataCode": "HBD",
                            "Airports": [
                                {
                                    "Id": "HBD",
                                    "Name": "Habi",
                                    "CityId": "HBDP",
                                    "CountryId": "PG",
                                    "Location": "142.49778, -6.333056"
                                }
                            ]
                        },
                        {
                            "Id": "SMHP",
                            "Name": "Sugan",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.78333, -6.1",
                            "IataCode": "SMH",
                            "Airports": [
                                {
                                    "Id": "SMH",
                                    "Name": "Sapmanga",
                                    "CityId": "SMHP",
                                    "CountryId": "PG",
                                    "Location": "146.75, -6.083333"
                                }
                            ]
                        },
                        {
                            "Id": "NOMP",
                            "Name": "Sokabi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.23333, -6.3",
                            "IataCode": "NOM",
                            "Airports": [
                                {
                                    "Id": "NOM",
                                    "Name": "Nomad River",
                                    "CityId": "NOMP",
                                    "CountryId": "PG",
                                    "Location": "142.21667, -6.3"
                                }
                            ]
                        },
                        {
                            "Id": "FUMP",
                            "Name": "Soia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.43333, -6.3666667",
                            "IataCode": "FUM",
                            "Airports": [
                                {
                                    "Id": "FUM",
                                    "Name": "Fuma",
                                    "CityId": "FUMP",
                                    "CountryId": "PG",
                                    "Location": "142.43333, -6.383333"
                                }
                            ]
                        },
                        {
                            "Id": "SBEP",
                            "Name": "Soabi Number 2",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.26667, -6.0833333",
                            "IataCode": "SBE",
                            "Airports": [
                                {
                                    "Id": "SBE",
                                    "Name": "Suabi",
                                    "CityId": "SBEP",
                                    "CountryId": "PG",
                                    "Location": "142.28333, -6.1"
                                }
                            ]
                        },
                        {
                            "Id": "TDSP",
                            "Name": "Sipoi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.98333, -7.6",
                            "IataCode": "TDS",
                            "Airports": [
                                {
                                    "Id": "TDS",
                                    "Name": "Sasereme",
                                    "CityId": "TDSP",
                                    "CountryId": "PG",
                                    "Location": "142.86667, -7.616667"
                                }
                            ]
                        },
                        {
                            "Id": "TLPP",
                            "Name": "Sinimsigin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.01667, -4.7833333",
                            "IataCode": "TLP",
                            "Airports": [
                                {
                                    "Id": "TLP",
                                    "Name": "Tumolbil",
                                    "CityId": "TLPP",
                                    "CountryId": "PG",
                                    "Location": "141.01667, -4.783333"
                                }
                            ]
                        },
                        {
                            "Id": "GBCP",
                            "Name": "Simmindure",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.73333, -6.1333333",
                            "IataCode": "GBC",
                            "Airports": [
                                {
                                    "Id": "GBC",
                                    "Name": "Gasuke",
                                    "CityId": "GBCP",
                                    "CountryId": "PG",
                                    "Location": "141.73333, -6.1"
                                }
                            ]
                        },
                        {
                            "Id": "TKWP",
                            "Name": "Sembati",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.2, -5.3166667",
                            "IataCode": "TKW",
                            "Airports": [
                                {
                                    "Id": "TKW",
                                    "Name": "Tekin",
                                    "CityId": "TKWP",
                                    "CountryId": "PG",
                                    "Location": "142.15, -5.25"
                                }
                            ]
                        },
                        {
                            "Id": "BPKP",
                            "Name": "Seltamin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.85, -5.4166667",
                            "IataCode": "BPK",
                            "Airports": [
                                {
                                    "Id": "BPK",
                                    "Name": "Biangabip",
                                    "CityId": "BPKP",
                                    "CountryId": "PG",
                                    "Location": "141.74972, -5.529722"
                                }
                            ]
                        },
                        {
                            "Id": "DNUP",
                            "Name": "Sakam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.68333, -6.15",
                            "IataCode": "DNU",
                            "Airports": [
                                {
                                    "Id": "DNU",
                                    "Name": "Dinangat",
                                    "CityId": "DNUP",
                                    "CountryId": "PG",
                                    "Location": "146.66667, -6.15"
                                }
                            ]
                        },
                        {
                            "Id": "GOEP",
                            "Name": "Ram",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.76667, -5.4333333",
                            "IataCode": "GOE",
                            "Airports": [
                                {
                                    "Id": "GOE",
                                    "Name": "Gonalia",
                                    "CityId": "GOEP",
                                    "CountryId": "PG",
                                    "Location": "151.85, -5.366667"
                                }
                            ]
                        },
                        {
                            "Id": "RBPP",
                            "Name": "Raba Raba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.83333, -9.9666667",
                            "IataCode": "RBP",
                            "Airports": [
                                {
                                    "Id": "RBP",
                                    "Name": "Rabaraba",
                                    "CityId": "RBPP",
                                    "CountryId": "PG",
                                    "Location": "149.83333, -9.998611"
                                }
                            ]
                        },
                        {
                            "Id": "KOMP",
                            "Name": "Puins",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.63333, -6.1166667",
                            "IataCode": "KOM",
                            "Airports": [
                                {
                                    "Id": "KOM",
                                    "Name": "Komo-Manda",
                                    "CityId": "KOMP",
                                    "CountryId": "PG",
                                    "Location": "143.63333, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "NUTP",
                            "Name": "Pora",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.43333, -5.6333333",
                            "IataCode": "NUT",
                            "Airports": [
                                {
                                    "Id": "NUT",
                                    "Name": "Nutuve",
                                    "CityId": "NUTP",
                                    "CountryId": "PG",
                                    "Location": "151.38333, -5.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MJJP",
                            "Name": "Pisingam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.21667, -5.7333333",
                            "IataCode": "MJJ",
                            "Airports": [
                                {
                                    "Id": "MJJ",
                                    "Name": "Moki",
                                    "CityId": "MJJP",
                                    "CountryId": "PG",
                                    "Location": "145.25, -5.7"
                                }
                            ]
                        },
                        {
                            "Id": "KWOP",
                            "Name": "Pisi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.75, -7.9833333",
                            "IataCode": "KWO",
                            "Airports": [
                                {
                                    "Id": "KWO",
                                    "Name": "Kawito",
                                    "CityId": "KWOP",
                                    "CountryId": "PG",
                                    "Location": "142.81667, -7.983333"
                                }
                            ]
                        },
                        {
                            "Id": "WAJP",
                            "Name": "Pirikwai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.26667, -7.8166667",
                            "IataCode": "WAJ",
                            "Airports": [
                                {
                                    "Id": "WAJ",
                                    "Name": "Wawoi Falls",
                                    "CityId": "WAJP",
                                    "CountryId": "PG",
                                    "Location": "143.26667, -7.8"
                                }
                            ]
                        },
                        {
                            "Id": "IUSP",
                            "Name": "Pipipaia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.13333, -5.9",
                            "IataCode": "IUS",
                            "Airports": [
                                {
                                    "Id": "IUS",
                                    "Name": "Inus",
                                    "CityId": "IUSP",
                                    "CountryId": "PG",
                                    "Location": "155.16667, -5.816667"
                                }
                            ]
                        },
                        {
                            "Id": "GRLP",
                            "Name": "Peila",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.18333, -7.95",
                            "IataCode": "GRL",
                            "Airports": [
                                {
                                    "Id": "GRL",
                                    "Name": "Garasa",
                                    "CityId": "GRLP",
                                    "CountryId": "PG",
                                    "Location": "147.18333, -7.95"
                                }
                            ]
                        },
                        {
                            "Id": "PLEP",
                            "Name": "Orei",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.05, -5.3833333",
                            "IataCode": "PLE",
                            "Airports": [
                                {
                                    "Id": "PLE",
                                    "Name": "Paiela",
                                    "CityId": "PLEP",
                                    "CountryId": "PG",
                                    "Location": "142.98333, -5.383333"
                                }
                            ]
                        },
                        {
                            "Id": "TDBP",
                            "Name": "Ordeno",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.45, -8.9666667",
                            "IataCode": "TDB",
                            "Airports": [
                                {
                                    "Id": "TDB",
                                    "Name": "Tetabedi",
                                    "CityId": "TDBP",
                                    "CountryId": "PG",
                                    "Location": "148.43333, -8.997222"
                                }
                            ]
                        },
                        {
                            "Id": "BXZP",
                            "Name": "Opai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.88333, -5.7",
                            "IataCode": "BXZ",
                            "Airports": [
                                {
                                    "Id": "BXZ",
                                    "Name": "Bunsil",
                                    "CityId": "BXZP",
                                    "CountryId": "PG",
                                    "Location": "147.86667, -5.716667"
                                }
                            ]
                        },
                        {
                            "Id": "IMNP",
                            "Name": "Onuk",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.15, -6.7",
                            "IataCode": "IMN",
                            "Airports": [
                                {
                                    "Id": "IMN",
                                    "Name": "Imane",
                                    "CityId": "IMNP",
                                    "CountryId": "PG",
                                    "Location": "146.08333, -6.7"
                                }
                            ]
                        },
                        {
                            "Id": "KVEP",
                            "Name": "Obulaku",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.13333, -8.6",
                            "IataCode": "KVE",
                            "Airports": [
                                {
                                    "Id": "KVE",
                                    "Name": "Kitava",
                                    "CityId": "KVEP",
                                    "CountryId": "PG",
                                    "Location": "151.33333, -8.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KGBP",
                            "Name": "Nuzen",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.63333, -6.1333333",
                            "IataCode": "KGB",
                            "Airports": [
                                {
                                    "Id": "KGB",
                                    "Name": "Konge",
                                    "CityId": "KGBP",
                                    "CountryId": "PG",
                                    "Location": "147.65, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "HITP",
                            "Name": "Nauma",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.11667, -6.9333333",
                            "IataCode": "HIT",
                            "Airports": [
                                {
                                    "Id": "HIT",
                                    "Name": "Hivaro",
                                    "CityId": "HITP",
                                    "CountryId": "PG",
                                    "Location": "143.06667, -6.933333"
                                }
                            ]
                        },
                        {
                            "Id": "GWNP",
                            "Name": "Narowaine",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.21667, -6.55",
                            "IataCode": "GWN",
                            "Airports": [
                                {
                                    "Id": "GWN",
                                    "Name": "Gnarowein",
                                    "CityId": "GWNP",
                                    "CountryId": "PG",
                                    "Location": "146.25, -6.566667"
                                }
                            ]
                        },
                        {
                            "Id": "IMAP",
                            "Name": "Naieta",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.55, -9.4833333",
                            "IataCode": "IMA",
                            "Airports": [
                                {
                                    "Id": "IMA",
                                    "Name": "Iamalele",
                                    "CityId": "IMAP",
                                    "CountryId": "PG",
                                    "Location": "150.5, -9.466667"
                                }
                            ]
                        },
                        {
                            "Id": "URUP",
                            "Name": "Moro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.55, -9.5166667",
                            "IataCode": "URU",
                            "Airports": [
                                {
                                    "Id": "URU",
                                    "Name": "Uroubi",
                                    "CityId": "URUP",
                                    "CountryId": "PG",
                                    "Location": "148.56667, -9.5"
                                }
                            ]
                        },
                        {
                            "Id": "MPGP",
                            "Name": "Moreng",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.7, -6.5333333",
                            "IataCode": "MPG",
                            "Airports": [
                                {
                                    "Id": "MPG",
                                    "Name": "Makini",
                                    "CityId": "MPGP",
                                    "CountryId": "PG",
                                    "Location": "147.71667, -6.533333"
                                }
                            ]
                        },
                        {
                            "Id": "MFZP",
                            "Name": "Meselia",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.11667, -6.1333333",
                            "IataCode": "MFZ",
                            "Airports": [
                                {
                                    "Id": "MFZ",
                                    "Name": "Mesalia",
                                    "CityId": "MFZP",
                                    "CountryId": "PG",
                                    "Location": "149.11667, -6.133333"
                                }
                            ]
                        },
                        {
                            "Id": "KMFP",
                            "Name": "Menwan",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.96667, -7.6333333",
                            "IataCode": "KMF",
                            "Airports": [
                                {
                                    "Id": "KMF",
                                    "Name": "Kamina",
                                    "CityId": "KMFP",
                                    "CountryId": "PG",
                                    "Location": "145.99583, -7.616667"
                                }
                            ]
                        },
                        {
                            "Id": "OPBP",
                            "Name": "Matanakunai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.68333, -4.8666667",
                            "IataCode": "OPB",
                            "Airports": [
                                {
                                    "Id": "OPB",
                                    "Name": "Open Bay",
                                    "CityId": "OPBP",
                                    "CountryId": "PG",
                                    "Location": "151.66667, -4.933333"
                                }
                            ]
                        },
                        {
                            "Id": "LABP",
                            "Name": "Marli",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.05, -5.6",
                            "IataCode": "LAB",
                            "Airports": [
                                {
                                    "Id": "LAB",
                                    "Name": "Lablab",
                                    "CityId": "LABP",
                                    "CountryId": "PG",
                                    "Location": "148.06667, -5.633333"
                                }
                            ]
                        },
                        {
                            "Id": "LNGP",
                            "Name": "Marekea",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.1, -8.1666667",
                            "IataCode": "LNG",
                            "Airports": [
                                {
                                    "Id": "LNG",
                                    "Name": "Lese",
                                    "CityId": "LNGP",
                                    "CountryId": "PG",
                                    "Location": "146.11667, -8.166667"
                                }
                            ]
                        },
                        {
                            "Id": "CGCP",
                            "Name": "Mapua",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.51667, -5.5166667",
                            "IataCode": "CGC",
                            "Airports": [
                                {
                                    "Id": "CGC",
                                    "Name": "Cape Gloucester",
                                    "CityId": "CGCP",
                                    "CountryId": "PG",
                                    "Location": "148.51583, -5.459167"
                                }
                            ]
                        },
                        {
                            "Id": "HYFP",
                            "Name": "Maprik",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.05, -3.6333333",
                            "IataCode": "HYF",
                            "Airports": [
                                {
                                    "Id": "HYF",
                                    "Name": "Hayfields",
                                    "CityId": "HYFP",
                                    "CountryId": "PG",
                                    "Location": "143.05, -3.633333"
                                }
                            ]
                        },
                        {
                            "Id": "WAOP",
                            "Name": "Mapaio",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.05, -7.65",
                            "IataCode": "WAO",
                            "Airports": [
                                {
                                    "Id": "WAO",
                                    "Name": "Wabo",
                                    "CityId": "WAOP",
                                    "CountryId": "PG",
                                    "Location": "145, -7.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LPNP",
                            "Name": "Maniag",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.31667, -6.3666667",
                            "IataCode": "LPN",
                            "Airports": [
                                {
                                    "Id": "LPN",
                                    "Name": "Leron Plains",
                                    "CityId": "LPNP",
                                    "CountryId": "PG",
                                    "Location": "146.3434, -6.3917"
                                }
                            ]
                        },
                        {
                            "Id": "JAQP",
                            "Name": "Malmal",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.46667, -5.6333333",
                            "IataCode": "JAQ",
                            "Airports": [
                                {
                                    "Id": "JAQ",
                                    "Name": "Jacquinot Bay",
                                    "CityId": "JAQP",
                                    "CountryId": "PG",
                                    "Location": "151.507, -5.6525"
                                }
                            ]
                        },
                        {
                            "Id": "SMPP",
                            "Name": "Malasait",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.88333, -4.4666667",
                            "IataCode": "SMP",
                            "Airports": [
                                {
                                    "Id": "SMP",
                                    "Name": "Stockholm",
                                    "CityId": "SMPP",
                                    "CountryId": "PG",
                                    "Location": "151.96667, -4.566667"
                                }
                            ]
                        },
                        {
                            "Id": "BAJP",
                            "Name": "Makiri",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.16667, -4.8666667",
                            "IataCode": "BAJ",
                            "Airports": [
                                {
                                    "Id": "BAJ",
                                    "Name": "Bali",
                                    "CityId": "BAJP",
                                    "CountryId": "PG",
                                    "Location": "149.13333, -4.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KAFP",
                            "Name": "Mainuki",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.35, -6.2333333",
                            "IataCode": "KAF",
                            "Airports": [
                                {
                                    "Id": "KAF",
                                    "Name": "Karato",
                                    "CityId": "KAFP",
                                    "CountryId": "PG",
                                    "Location": "155.35, -6.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ERUP",
                            "Name": "Lotuava",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.08333, -8.4166667",
                            "IataCode": "ERU",
                            "Airports": [
                                {
                                    "Id": "ERU",
                                    "Name": "Erume",
                                    "CityId": "ERUP",
                                    "CountryId": "PG",
                                    "Location": "147.08333, -8.416667"
                                }
                            ]
                        },
                        {
                            "Id": "TEOP",
                            "Name": "Lelefiru",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.05, -8.0833333",
                            "IataCode": "TEO",
                            "Airports": [
                                {
                                    "Id": "TEO",
                                    "Name": "Terapo",
                                    "CityId": "TEOP",
                                    "CountryId": "PG",
                                    "Location": "146.06667, -8.083333"
                                }
                            ]
                        },
                        {
                            "Id": "IBIP",
                            "Name": "Lauvore",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.18333, -5.55",
                            "IataCode": "IBI",
                            "Airports": [
                                {
                                    "Id": "IBI",
                                    "Name": "Iboki",
                                    "CityId": "IBIP",
                                    "CountryId": "PG",
                                    "Location": "149.19028, -5.553333"
                                }
                            ]
                        },
                        {
                            "Id": "NDNP",
                            "Name": "Launumu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.68333, -9.1333333",
                            "IataCode": "NDN",
                            "Airports": [
                                {
                                    "Id": "NDN",
                                    "Name": "Nadunumu",
                                    "CityId": "NDNP",
                                    "CountryId": "PG",
                                    "Location": "147.68333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "NUGP",
                            "Name": "Lapai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.95, -2.9666667",
                            "IataCode": "NUG",
                            "Airports": [
                                {
                                    "Id": "NUG",
                                    "Name": "Nuguria",
                                    "CityId": "NUGP",
                                    "CountryId": "PG",
                                    "Location": "150.81667, -3.466667"
                                }
                            ]
                        },
                        {
                            "Id": "WIUP",
                            "Name": "Lambe",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.5, -4.65",
                            "IataCode": "WIU",
                            "Airports": [
                                {
                                    "Id": "WIU",
                                    "Name": "Witu",
                                    "CityId": "WIUP",
                                    "CountryId": "PG",
                                    "Location": "149.5, -4.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KJUP",
                            "Name": "Lakuramau",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.25, -2.8833333",
                            "IataCode": "KJU",
                            "Airports": [
                                {
                                    "Id": "KJU",
                                    "Name": "Kamiraba",
                                    "CityId": "KJUP",
                                    "CountryId": "PG",
                                    "Location": "151.25, -2.883333"
                                }
                            ]
                        },
                        {
                            "Id": "KPAP",
                            "Name": "Lake Kopiago",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.51667, -5.3833333",
                            "IataCode": "KPA",
                            "Airports": [
                                {
                                    "Id": "KPA",
                                    "Name": "Kopiago",
                                    "CityId": "KPAP",
                                    "CountryId": "PG",
                                    "Location": "142.55, -5.383333"
                                }
                            ]
                        },
                        {
                            "Id": "BIJP",
                            "Name": "Kwil",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.2, -5.5666667",
                            "IataCode": "BIJ",
                            "Airports": [
                                {
                                    "Id": "BIJ",
                                    "Name": "Biliau",
                                    "CityId": "BIJP",
                                    "CountryId": "PG",
                                    "Location": "146.2, -5.583333"
                                }
                            ]
                        },
                        {
                            "Id": "EGAP",
                            "Name": "Kwasimerga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.11667, -7.05",
                            "IataCode": "EGA",
                            "Airports": [
                                {
                                    "Id": "EGA",
                                    "Name": "Engati",
                                    "CityId": "EGAP",
                                    "CountryId": "PG",
                                    "Location": "146.1, -6.916667"
                                }
                            ]
                        },
                        {
                            "Id": "WBCP",
                            "Name": "Kwaio Kwaio",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.35, -9.3166667",
                            "IataCode": "WBC",
                            "Airports": [
                                {
                                    "Id": "WBC",
                                    "Name": "Wapolu",
                                    "CityId": "WBCP",
                                    "CountryId": "PG",
                                    "Location": "150.35, -9.316667"
                                }
                            ]
                        },
                        {
                            "Id": "DAOP",
                            "Name": "Kurereda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.83333, -8.4333333",
                            "IataCode": "DAO",
                            "Airports": [
                                {
                                    "Id": "DAO",
                                    "Name": "Dabo",
                                    "CityId": "DAOP",
                                    "CountryId": "PG",
                                    "Location": "147.84944, -8.433056"
                                }
                            ]
                        },
                        {
                            "Id": "KGMP",
                            "Name": "Kungim",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.03333, -5.6833333",
                            "IataCode": "KGM",
                            "Airports": [
                                {
                                    "Id": "KGM",
                                    "Name": "Kungum",
                                    "CityId": "KGMP",
                                    "CountryId": "PG",
                                    "Location": "141.03, -5.671"
                                }
                            ]
                        },
                        {
                            "Id": "KRJP",
                            "Name": "Kundiman",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.51667, -4.6",
                            "IataCode": "KRJ",
                            "Airports": [
                                {
                                    "Id": "KRJ",
                                    "Name": "Karawari",
                                    "CityId": "KRJP",
                                    "CountryId": "PG",
                                    "Location": "143.51667, -4.616667"
                                }
                            ]
                        },
                        {
                            "Id": "DBPP",
                            "Name": "Kuda",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.9, -6.2666667",
                            "IataCode": "DBP",
                            "Airports": [
                                {
                                    "Id": "DBP",
                                    "Name": "Debepare",
                                    "CityId": "DBPP",
                                    "CountryId": "PG",
                                    "Location": "141.9, -6.283333"
                                }
                            ]
                        },
                        {
                            "Id": "CVBP",
                            "Name": "Korap",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.71667, -4.8",
                            "IataCode": "CVB",
                            "Airports": [
                                {
                                    "Id": "CVB",
                                    "Name": "Chungribu",
                                    "CityId": "CVBP",
                                    "CountryId": "PG",
                                    "Location": "144.7, -4.8"
                                }
                            ]
                        },
                        {
                            "Id": "SPVP",
                            "Name": "Kopar",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.53333, -3.8666667",
                            "IataCode": "SPV",
                            "Airports": [
                                {
                                    "Id": "SPV",
                                    "Name": "Sepik Plains",
                                    "CityId": "SPVP",
                                    "CountryId": "PG",
                                    "Location": "144.5, -3.816667"
                                }
                            ]
                        },
                        {
                            "Id": "KPFP",
                            "Name": "Kondobal",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.51667, -8.5333333",
                            "IataCode": "KPF",
                            "Airports": [
                                {
                                    "Id": "KPF",
                                    "Name": "Kondubol",
                                    "CityId": "KPFP",
                                    "CountryId": "PG",
                                    "Location": "142.51194, -8.533056"
                                }
                            ]
                        },
                        {
                            "Id": "KUYP",
                            "Name": "Komewu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.11667, -7.4166667",
                            "IataCode": "KUY",
                            "Airports": [
                                {
                                    "Id": "KUY",
                                    "Name": "Kamusi",
                                    "CityId": "KUYP",
                                    "CountryId": "PG",
                                    "Location": "143.12389, -7.4275"
                                }
                            ]
                        },
                        {
                            "Id": "KSGP",
                            "Name": "Kisengam",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.71667, -6.35",
                            "IataCode": "KSG",
                            "Airports": [
                                {
                                    "Id": "KSG",
                                    "Name": "Kisengan",
                                    "CityId": "KSGP",
                                    "CountryId": "PG",
                                    "Location": "146.71667, -6.35"
                                }
                            ]
                        },
                        {
                            "Id": "LHPP",
                            "Name": "Kekemona",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.7, -6.5166667",
                            "IataCode": "LHP",
                            "Airports": [
                                {
                                    "Id": "LHP",
                                    "Name": "Lehu",
                                    "CityId": "LHPP",
                                    "CountryId": "PG",
                                    "Location": "155.7, -6.5"
                                }
                            ]
                        },
                        {
                            "Id": "OBXP",
                            "Name": "Kaviananga",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.3, -7.6",
                            "IataCode": "OBX",
                            "Airports": [
                                {
                                    "Id": "OBX",
                                    "Name": "Obo",
                                    "CityId": "OBXP",
                                    "CountryId": "PG",
                                    "Location": "141.31667, -7.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BRHP",
                            "Name": "Kausi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.36667, -5.7166667",
                            "IataCode": "BRH",
                            "Airports": [
                                {
                                    "Id": "BRH",
                                    "Name": "Brahman",
                                    "CityId": "BRHP",
                                    "CountryId": "PG",
                                    "Location": "145.36667, -5.7"
                                }
                            ]
                        },
                        {
                            "Id": "TLOP",
                            "Name": "Kaubademki",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.96667, -4.9333333",
                            "IataCode": "TLO",
                            "Airports": [
                                {
                                    "Id": "TLO",
                                    "Name": "Tol",
                                    "CityId": "TLOP",
                                    "CountryId": "PG",
                                    "Location": "152.03333, -4.983333"
                                }
                            ]
                        },
                        {
                            "Id": "OSEP",
                            "Name": "Kapiso",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.1, -7.85",
                            "IataCode": "OSE",
                            "Airports": [
                                {
                                    "Id": "OSE",
                                    "Name": "Omora",
                                    "CityId": "OSEP",
                                    "CountryId": "PG",
                                    "Location": "147.08333, -7.833333"
                                }
                            ]
                        },
                        {
                            "Id": "ILXP",
                            "Name": "Kaliku",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.8, -5.5",
                            "IataCode": "ILX",
                            "Airports": [
                                {
                                    "Id": "ILX",
                                    "Name": "Ileg",
                                    "CityId": "ILXP",
                                    "CountryId": "PG",
                                    "Location": "145.8022, -5.4917"
                                }
                            ]
                        },
                        {
                            "Id": "KORP",
                            "Name": "Kakoro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.51667, -7.85",
                            "IataCode": "KOR",
                            "Airports": [
                                {
                                    "Id": "KOR",
                                    "Name": "Kokoro",
                                    "CityId": "KORP",
                                    "CountryId": "PG",
                                    "Location": "146.55, -7.808333"
                                }
                            ]
                        },
                        {
                            "Id": "NKNP",
                            "Name": "Iumbi Island",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.46667, -5.8166667",
                            "IataCode": "NKN",
                            "Airports": [
                                {
                                    "Id": "NKN",
                                    "Name": "Nankina",
                                    "CityId": "NKNP",
                                    "CountryId": "PG",
                                    "Location": "143.45, -5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LNFP",
                            "Name": "Iualbit",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.21667, -4.8666667",
                            "IataCode": "LNF",
                            "Airports": [
                                {
                                    "Id": "LNF",
                                    "Name": "Munbil",
                                    "CityId": "LNFP",
                                    "CountryId": "PG",
                                    "Location": "141.21667, -4.866667"
                                }
                            ]
                        },
                        {
                            "Id": "ASZP",
                            "Name": "Iok",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.26667, -5.8333333",
                            "IataCode": "ASZ",
                            "Airports": [
                                {
                                    "Id": "ASZ",
                                    "Name": "Asirim",
                                    "CityId": "ASZP",
                                    "CountryId": "PG",
                                    "Location": "150.36667, -5.866667"
                                }
                            ]
                        },
                        {
                            "Id": "HWAP",
                            "Name": "Iemepango",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.98333, -7.4333333",
                            "IataCode": "HWA",
                            "Airports": [
                                {
                                    "Id": "HWA",
                                    "Name": "Hawabango",
                                    "CityId": "HWAP",
                                    "CountryId": "PG",
                                    "Location": "145.96667, -7.433333"
                                }
                            ]
                        },
                        {
                            "Id": "OKVP",
                            "Name": "Hukim",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.03333, -5.5666667",
                            "IataCode": "OKV",
                            "Airports": [
                                {
                                    "Id": "OKV",
                                    "Name": "Okao",
                                    "CityId": "OKVP",
                                    "CountryId": "PG",
                                    "Location": "141.03667, -5.561111"
                                }
                            ]
                        },
                        {
                            "Id": "KUQP",
                            "Name": "Haivaro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.25, -7.2",
                            "IataCode": "KUQ",
                            "Airports": [
                                {
                                    "Id": "KUQ",
                                    "Name": "Kuri",
                                    "CityId": "KUQP",
                                    "CountryId": "PG",
                                    "Location": "143.26667, -7.133333"
                                }
                            ]
                        },
                        {
                            "Id": "HEOP",
                            "Name": "Hailogo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.6, -9.1333333",
                            "IataCode": "HEO",
                            "Airports": [
                                {
                                    "Id": "HEO",
                                    "Name": "Haelogo",
                                    "CityId": "HEOP",
                                    "CountryId": "PG",
                                    "Location": "147.58333, -9.133333"
                                }
                            ]
                        },
                        {
                            "Id": "KYXP",
                            "Name": "Gomando",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.05, -6.1666667",
                            "IataCode": "KYX",
                            "Airports": [
                                {
                                    "Id": "KYX",
                                    "Name": "Yalumet",
                                    "CityId": "KYXP",
                                    "CountryId": "PG",
                                    "Location": "147.01667, -6.183333"
                                }
                            ]
                        },
                        {
                            "Id": "GLPP",
                            "Name": "Golgubip",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.51667, -5.2833333",
                            "IataCode": "GLP",
                            "Airports": [
                                {
                                    "Id": "GLP",
                                    "Name": "Gulgubip",
                                    "CityId": "GLPP",
                                    "CountryId": "PG",
                                    "Location": "141.51667, -5.283333"
                                }
                            ]
                        },
                        {
                            "Id": "KAQP",
                            "Name": "Girebi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.8, -8.1333333",
                            "IataCode": "KAQ",
                            "Airports": [
                                {
                                    "Id": "KAQ",
                                    "Name": "Kamulai",
                                    "CityId": "KAQP",
                                    "CountryId": "PG",
                                    "Location": "146.81667, -8.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SMJP",
                            "Name": "Gerepo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.96667, -7.7",
                            "IataCode": "SMJ",
                            "Airports": [
                                {
                                    "Id": "SMJ",
                                    "Name": "Sim",
                                    "CityId": "SMJP",
                                    "CountryId": "PG",
                                    "Location": "146.98333, -7.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KGHP",
                            "Name": "Gahom",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.75, -4.6333333",
                            "IataCode": "KGH",
                            "Airports": [
                                {
                                    "Id": "KGH",
                                    "Name": "Yongai",
                                    "CityId": "KGHP",
                                    "CountryId": "PG",
                                    "Location": "142.66667, -4.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BMZP",
                            "Name": "Gagoro",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.25, -7.8333333",
                            "IataCode": "BMZ",
                            "Airports": [
                                {
                                    "Id": "BMZ",
                                    "Name": "Bamu",
                                    "CityId": "BMZP",
                                    "CountryId": "PG",
                                    "Location": "143.23333, -7.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KXRP",
                            "Name": "Gagan",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.61667, -5.2333333",
                            "IataCode": "KXR",
                            "Airports": [
                                {
                                    "Id": "KXR",
                                    "Name": "Karoola",
                                    "CityId": "KXRP",
                                    "CountryId": "PG",
                                    "Location": "154.599, -5.27714"
                                }
                            ]
                        },
                        {
                            "Id": "BAAP",
                            "Name": "Ewasse",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.01667, -5.3166667",
                            "IataCode": "BAA",
                            "Airports": [
                                {
                                    "Id": "BAA",
                                    "Name": "Bialla",
                                    "CityId": "BAAP",
                                    "CountryId": "PG",
                                    "Location": "151.03333, -5.316667"
                                }
                            ]
                        },
                        {
                            "Id": "YEQP",
                            "Name": "Eram",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.93333, -4.8833333",
                            "IataCode": "YEQ",
                            "Airports": [
                                {
                                    "Id": "YEQ",
                                    "Name": "Yenkis",
                                    "CityId": "YEQP",
                                    "CountryId": "PG",
                                    "Location": "143.91667, -5.1"
                                }
                            ]
                        },
                        {
                            "Id": "BVPP",
                            "Name": "Enkaiakmin",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.7, -5.35",
                            "IataCode": "BVP",
                            "Airports": [
                                {
                                    "Id": "BVP",
                                    "Name": "Bolovip",
                                    "CityId": "BVPP",
                                    "CountryId": "PG",
                                    "Location": "141.7, -5.383333"
                                }
                            ]
                        },
                        {
                            "Id": "MRMP",
                            "Name": "Enivilogo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.62222, -9.1533333",
                            "IataCode": "MRM",
                            "Airports": [
                                {
                                    "Id": "MRM",
                                    "Name": "Manare",
                                    "CityId": "MRMP",
                                    "CountryId": "PG",
                                    "Location": "147.6, -9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "XYRA",
                            "Name": "Edwaki",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.78333, -3.9",
                            "IataCode": "XYR",
                            "Airports": [
                                {
                                    "Id": "XYR",
                                    "Name": "Yellow River",
                                    "CityId": "XYRA",
                                    "CountryId": "PG",
                                    "Location": "141.78333, -3.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CPIP",
                            "Name": "East Baien",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "152.08333, -5.4333333",
                            "IataCode": "CPI",
                            "Airports": [
                                {
                                    "Id": "CPI",
                                    "Name": "Cape Orford",
                                    "CityId": "CPIP",
                                    "CountryId": "PG",
                                    "Location": "152.08333, -5.433333"
                                }
                            ]
                        },
                        {
                            "Id": "WGUP",
                            "Name": "Buwai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.81667, -6.8166667",
                            "IataCode": "WGU",
                            "Airports": [
                                {
                                    "Id": "WGU",
                                    "Name": "Wagau",
                                    "CityId": "WGUP",
                                    "CountryId": "PG",
                                    "Location": "146.8, -6.8"
                                }
                            ]
                        },
                        {
                            "Id": "MFOP",
                            "Name": "Buka",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "151.78333, -5.5666667",
                            "IataCode": "MFO",
                            "Airports": [
                                {
                                    "Id": "MFO",
                                    "Name": "Manguna",
                                    "CityId": "MFOP",
                                    "CountryId": "PG",
                                    "Location": "151.78333, -5.566667"
                                }
                            ]
                        },
                        {
                            "Id": "KUXP",
                            "Name": "Bolivip",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.6, -5.3333333",
                            "IataCode": "KUX",
                            "Airports": [
                                {
                                    "Id": "KUX",
                                    "Name": "Kuyol",
                                    "CityId": "KUXP",
                                    "CountryId": "PG",
                                    "Location": "141.63056, -5.378333"
                                }
                            ]
                        },
                        {
                            "Id": "MWIP",
                            "Name": "Biaka",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.45, -5.1333333",
                            "IataCode": "MWI",
                            "Airports": [
                                {
                                    "Id": "MWI",
                                    "Name": "Maramuni",
                                    "CityId": "MWIP",
                                    "CountryId": "PG",
                                    "Location": "143.48333, -5.15"
                                }
                            ]
                        },
                        {
                            "Id": "NWTP",
                            "Name": "Berema",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.73333, -9.9833333",
                            "IataCode": "NWT",
                            "Airports": [
                                {
                                    "Id": "NWT",
                                    "Name": "Nowata",
                                    "CityId": "NWTP",
                                    "CountryId": "PG",
                                    "Location": "149.73333, -9.983333"
                                }
                            ]
                        },
                        {
                            "Id": "LNMP",
                            "Name": "Benula",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.23333, -7.2333333",
                            "IataCode": "LNM",
                            "Airports": [
                                {
                                    "Id": "LNM",
                                    "Name": "Langimar",
                                    "CityId": "LNMP",
                                    "CountryId": "PG",
                                    "Location": "146.21667, -7.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SSSP",
                            "Name": "Barang",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.83333, -5.6166667",
                            "IataCode": "SSS",
                            "Airports": [
                                {
                                    "Id": "SSS",
                                    "Name": "Siassi",
                                    "CityId": "SSSP",
                                    "CountryId": "PG",
                                    "Location": "147.81667, -5.616667"
                                }
                            ]
                        },
                        {
                            "Id": "BWJP",
                            "Name": "Bangdap",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.91667, -6.4166667",
                            "IataCode": "BWJ",
                            "Airports": [
                                {
                                    "Id": "BWJ",
                                    "Name": "Bawan",
                                    "CityId": "BWJP",
                                    "CountryId": "PG",
                                    "Location": "146.9, -6.4"
                                }
                            ]
                        },
                        {
                            "Id": "SWRP",
                            "Name": "Bakum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "153.06667, -4.5333333",
                            "IataCode": "SWR",
                            "Airports": [
                                {
                                    "Id": "SWR",
                                    "Name": "Silur",
                                    "CityId": "SWRP",
                                    "CountryId": "PG",
                                    "Location": "153.05, -4.533333"
                                }
                            ]
                        },
                        {
                            "Id": "BDZP",
                            "Name": "Aven",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.96667, -6.3333333",
                            "IataCode": "BDZ",
                            "Airports": [
                                {
                                    "Id": "BDZ",
                                    "Name": "Baindoung",
                                    "CityId": "BDZP",
                                    "CountryId": "PG",
                                    "Location": "146.95, -6.333333"
                                }
                            ]
                        },
                        {
                            "Id": "KNEP",
                            "Name": "Avei",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.66667, -5.2833333",
                            "IataCode": "KNE",
                            "Airports": [
                                {
                                    "Id": "KNE",
                                    "Name": "Kanainj",
                                    "CityId": "KNEP",
                                    "CountryId": "PG",
                                    "Location": "144.7, -5.283333"
                                }
                            ]
                        },
                        {
                            "Id": "BMHP",
                            "Name": "Auwihu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.6, -6.3666667",
                            "IataCode": "BMH",
                            "Airports": [
                                {
                                    "Id": "BMH",
                                    "Name": "Bomai",
                                    "CityId": "BMHP",
                                    "CountryId": "PG",
                                    "Location": "144.58333, -6.416667"
                                }
                            ]
                        },
                        {
                            "Id": "DOOP",
                            "Name": "Auwaiabaiwa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.53333, -9.3166667",
                            "IataCode": "DOO",
                            "Airports": [
                                {
                                    "Id": "DOO",
                                    "Name": "Dorobisoro",
                                    "CityId": "DOOP",
                                    "CountryId": "PG",
                                    "Location": "147.55, -9.266667"
                                }
                            ]
                        },
                        {
                            "Id": "FAQP",
                            "Name": "Auom",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.91667, -4.4166667",
                            "IataCode": "FAQ",
                            "Airports": [
                                {
                                    "Id": "FAQ",
                                    "Name": "Freida River",
                                    "CityId": "FAQP",
                                    "CountryId": "PG",
                                    "Location": "141.95833, -4.616667"
                                }
                            ]
                        },
                        {
                            "Id": "LGNP",
                            "Name": "Augitni",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.36667, -5.75",
                            "IataCode": "LGN",
                            "Airports": [
                                {
                                    "Id": "LGN",
                                    "Name": "Linga Linga",
                                    "CityId": "LGNP",
                                    "CountryId": "PG",
                                    "Location": "149.41667, -5.683333"
                                }
                            ]
                        },
                        {
                            "Id": "OKPP",
                            "Name": "Atbine",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.01667, -5.25",
                            "IataCode": "OKP",
                            "Airports": [
                                {
                                    "Id": "OKP",
                                    "Name": "Oksapmin",
                                    "CityId": "OKPP",
                                    "CountryId": "PG",
                                    "Location": "142.08333, -5.25"
                                }
                            ]
                        },
                        {
                            "Id": "KWVP",
                            "Name": "Atamo",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "155.33333, -6.1833333",
                            "IataCode": "KWV",
                            "Airports": [
                                {
                                    "Id": "KWV",
                                    "Name": "Kurwina",
                                    "CityId": "KWVP",
                                    "CountryId": "PG",
                                    "Location": "155.33333, -6.183333"
                                }
                            ]
                        },
                        {
                            "Id": "DLBP",
                            "Name": "Atalum",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.1, -5.1166667",
                            "IataCode": "DLB",
                            "Airports": [
                                {
                                    "Id": "DLB",
                                    "Name": "Dalbertis",
                                    "CityId": "DLBP",
                                    "CountryId": "PG",
                                    "Location": "142.07992, -4.998673"
                                }
                            ]
                        },
                        {
                            "Id": "ARPP",
                            "Name": "Aragipa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.46667, -9.9",
                            "IataCode": "ARP",
                            "Airports": [
                                {
                                    "Id": "ARP",
                                    "Name": "Aragip",
                                    "CityId": "ARPP",
                                    "CountryId": "PG",
                                    "Location": "149.5, -9.8"
                                }
                            ]
                        },
                        {
                            "Id": "APRP",
                            "Name": "Apiope",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "145.16667, -7.85",
                            "IataCode": "APR",
                            "Airports": [
                                {
                                    "Id": "APR",
                                    "Name": "April River",
                                    "CityId": "APRP",
                                    "CountryId": "PG",
                                    "Location": "145, -8.5"
                                }
                            ]
                        },
                        {
                            "Id": "UAEP",
                            "Name": "Anjibal",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "144.65, -6.2166667",
                            "IataCode": "UAE",
                            "Airports": [
                                {
                                    "Id": "UAE",
                                    "Name": "Mount Aue",
                                    "CityId": "UAEP",
                                    "CountryId": "PG",
                                    "Location": "144.65, -6.233333"
                                }
                            ]
                        },
                        {
                            "Id": "MDMP",
                            "Name": "Amongabi",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "143.45, -4.6333333",
                            "IataCode": "MDM",
                            "Airports": [
                                {
                                    "Id": "MDM",
                                    "Name": "Munduku",
                                    "CityId": "MDMP",
                                    "CountryId": "PG",
                                    "Location": "143.45, -4.633333"
                                }
                            ]
                        },
                        {
                            "Id": "AWBP",
                            "Name": "Aketa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "142.73333, -8.0166667",
                            "IataCode": "AWB",
                            "Airports": [
                                {
                                    "Id": "AWB",
                                    "Name": "Awaba",
                                    "CityId": "AWBP",
                                    "CountryId": "PG",
                                    "Location": "142.75, -8.016667"
                                }
                            ]
                        },
                        {
                            "Id": "TSWP",
                            "Name": "Aiwamba",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "146.08333, -7.05",
                            "IataCode": "TSW",
                            "Airports": [
                                {
                                    "Id": "TSW",
                                    "Name": "Tsewi",
                                    "CityId": "TSWP",
                                    "CountryId": "PG",
                                    "Location": "146.03333, -6.966667"
                                }
                            ]
                        },
                        {
                            "Id": "MPXP",
                            "Name": "Aikamun",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.55, -4.9166667",
                            "IataCode": "MPX",
                            "Airports": [
                                {
                                    "Id": "MPX",
                                    "Name": "Miyanmin",
                                    "CityId": "MPXP",
                                    "CountryId": "PG",
                                    "Location": "141.61667, -4.9"
                                }
                            ]
                        },
                        {
                            "Id": "EPTP",
                            "Name": "Abunkamun",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "141.68333, -5.05",
                            "IataCode": "EPT",
                            "Airports": [
                                {
                                    "Id": "EPT",
                                    "Name": "Eliptamin",
                                    "CityId": "EPTP",
                                    "CountryId": "PG",
                                    "Location": "141.66667, -5.033333"
                                }
                            ]
                        },
                        {
                            "Id": "SGJP",
                            "Name": "Siasiada",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.15, -10.466667",
                            "IataCode": "SGJ",
                            "Airports": [
                                {
                                    "Id": "SGJ",
                                    "Name": "Sagarai",
                                    "CityId": "SGJP",
                                    "CountryId": "PG",
                                    "Location": "150.15, -10.466667"
                                }
                            ]
                        },
                        {
                            "Id": "AZBP",
                            "Name": "Segili",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.8, -10.133333",
                            "IataCode": "AZB",
                            "Airports": [
                                {
                                    "Id": "AZB",
                                    "Name": "Amazon Bay",
                                    "CityId": "AZBP",
                                    "CountryId": "PG",
                                    "Location": "148.81667, -10.15"
                                }
                            ]
                        },
                        {
                            "Id": "CPNP",
                            "Name": "Lalaura",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.35, -10.183333",
                            "IataCode": "CPN",
                            "Airports": [
                                {
                                    "Id": "CPN",
                                    "Name": "Cape Rodney",
                                    "CityId": "CPNP",
                                    "CountryId": "PG",
                                    "Location": "148.36667, -10.183333"
                                }
                            ]
                        },
                        {
                            "Id": "DOIP",
                            "Name": "Kwalosai",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.73333, -10.633333",
                            "IataCode": "DOI",
                            "Airports": [
                                {
                                    "Id": "DOI",
                                    "Name": "Doini",
                                    "CityId": "DOIP",
                                    "CountryId": "PG",
                                    "Location": "150.71667, -10.716667"
                                }
                            ]
                        },
                        {
                            "Id": "RNRP",
                            "Name": "Irupara",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "147.71667, -10.083333",
                            "IataCode": "RNR",
                            "Airports": [
                                {
                                    "Id": "RNR",
                                    "Name": "Robinson River",
                                    "CityId": "RNRP",
                                    "CountryId": "PG",
                                    "Location": "147.75, -10.133333"
                                }
                            ]
                        },
                        {
                            "Id": "WEPP",
                            "Name": "Iopara",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "148.18333, -10.066667",
                            "IataCode": "WEP",
                            "Airports": [
                                {
                                    "Id": "WEP",
                                    "Name": "Weam",
                                    "CityId": "WEPP",
                                    "CountryId": "PG",
                                    "Location": "148.16667, -10.066667"
                                }
                            ]
                        },
                        {
                            "Id": "GRHP",
                            "Name": "Ginewa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "150.55, -10.216667",
                            "IataCode": "GRH",
                            "Airports": [
                                {
                                    "Id": "GRH",
                                    "Name": "Garuahi",
                                    "CityId": "GRHP",
                                    "CountryId": "PG",
                                    "Location": "150.55, -10.225"
                                }
                            ]
                        },
                        {
                            "Id": "PPXP",
                            "Name": "Gatu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.61667, -10",
                            "IataCode": "PPX",
                            "Airports": [
                                {
                                    "Id": "PPX",
                                    "Name": "Param",
                                    "CityId": "PPXP",
                                    "CountryId": "PG",
                                    "Location": "149.59167, -9.995833"
                                }
                            ]
                        },
                        {
                            "Id": "BAPP",
                            "Name": "Gadaisu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "149.76667, -10.366667",
                            "IataCode": "BAP",
                            "Airports": [
                                {
                                    "Id": "BAP",
                                    "Name": "Baibara",
                                    "CityId": "BAPP",
                                    "CountryId": "PG",
                                    "Location": "149.75, -10.333333"
                                }
                            ]
                        },
                        {
                            "Id": "PAWP",
                            "Name": "Dominu",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "154.03333, -11.366667",
                            "IataCode": "PAW",
                            "Airports": [
                                {
                                    "Id": "PAW",
                                    "Name": "Pambwa",
                                    "CityId": "PAWP",
                                    "CountryId": "PG",
                                    "Location": "154.03333, -11.416667"
                                }
                            ]
                        },
                        {
                            "Id": "MMVP",
                            "Name": "Chemawa",
                            "SingleAirportCity": true,
                            "CountryId": "PG",
                            "Location": "-122.9926, 45.001508",
                            "IataCode": "MMV",
                            "Airports": [
                                {
                                    "Id": "MMV",
                                    "Name": "Mal",
                                    "CityId": "MMVP",
                                    "CountryId": "PG",
                                    "Location": "-123.13594, 45.19444"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "KI",
                    "Name": "Kiribati",
                    "CurrencyId": "AUD",
                    "Cities": [
                        {
                            "Id": "MTKA",
                            "Name": "Makin Island",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "174, 3",
                            "IataCode": "MTK",
                            "Airports": [
                                {
                                    "Id": "MTK",
                                    "Name": "Makin Island",
                                    "CityId": "MTKA",
                                    "CountryId": "KI",
                                    "Location": "172.99358, 3.37501"
                                }
                            ]
                        },
                        {
                            "Id": "BBGA",
                            "Name": "Butaritari",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "172.81667, 3.0833333",
                            "IataCode": "BBG",
                            "Airports": [
                                {
                                    "Id": "BBG",
                                    "Name": "Butaritari",
                                    "CityId": "BBGA",
                                    "CountryId": "KI",
                                    "Location": "172.80857, 3.0853"
                                }
                            ]
                        },
                        {
                            "Id": "MZKA",
                            "Name": "Marakei",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173.283, 2",
                            "IataCode": "MZK",
                            "Airports": [
                                {
                                    "Id": "MZK",
                                    "Name": "Marakei",
                                    "CityId": "MZKA",
                                    "CountryId": "KI",
                                    "Location": "173.2715, 2.05874"
                                }
                            ]
                        },
                        {
                            "Id": "ABFA",
                            "Name": "Abaiang",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "172.95, 1.833",
                            "IataCode": "ABF",
                            "Airports": [
                                {
                                    "Id": "ABF",
                                    "Name": "Abaiang",
                                    "CityId": "ABFA",
                                    "CountryId": "KI",
                                    "Location": "173.30417, 1.670833"
                                }
                            ]
                        },
                        {
                            "Id": "TRWA",
                            "Name": "Tarawa",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173, 1.433",
                            "IataCode": "TRW",
                            "Airports": [
                                {
                                    "Id": "TRW",
                                    "Name": "Tarawa",
                                    "CityId": "TRWA",
                                    "CountryId": "KI",
                                    "Location": "173.14659, 1.383113"
                                }
                            ]
                        },
                        {
                            "Id": "MNKA",
                            "Name": "Maiana",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173, 0.917",
                            "IataCode": "MNK",
                            "Airports": [
                                {
                                    "Id": "MNK",
                                    "Name": "Maiana",
                                    "CityId": "MNKA",
                                    "CountryId": "KI",
                                    "Location": "173, 1"
                                }
                            ]
                        },
                        {
                            "Id": "AEAA",
                            "Name": "Abemama Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173.833, 0.4",
                            "IataCode": "AEA",
                            "Airports": [
                                {
                                    "Id": "AEA",
                                    "Name": "Abemama Atoll",
                                    "CityId": "AEAA",
                                    "CountryId": "KI",
                                    "Location": "173.85, 0.483333"
                                }
                            ]
                        },
                        {
                            "Id": "KUCA",
                            "Name": "Kuria",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173.4, 0.217",
                            "IataCode": "KUC",
                            "Airports": [
                                {
                                    "Id": "KUC",
                                    "Name": "Kuria",
                                    "CityId": "KUCA",
                                    "CountryId": "KI",
                                    "Location": "173.38333, 0.233333"
                                }
                            ]
                        },
                        {
                            "Id": "AAKA",
                            "Name": "Aranuka",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "173.583, 0.15",
                            "IataCode": "AAK",
                            "Airports": [
                                {
                                    "Id": "AAK",
                                    "Name": "Aranuka",
                                    "CityId": "AAKA",
                                    "CountryId": "KI",
                                    "Location": "173.58333, 0.166667"
                                }
                            ]
                        },
                        {
                            "Id": "NONA",
                            "Name": "Nonouti",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "174.333, -0.667",
                            "IataCode": "NON",
                            "Airports": [
                                {
                                    "Id": "NON",
                                    "Name": "Nonouti",
                                    "CityId": "NONA",
                                    "CountryId": "KI",
                                    "Location": "174.35, -0.680556"
                                }
                            ]
                        },
                        {
                            "Id": "BEZA",
                            "Name": "Beru",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "175.983, -1.333",
                            "IataCode": "BEZ",
                            "Airports": [
                                {
                                    "Id": "BEZ",
                                    "Name": "Beru",
                                    "CityId": "BEZA",
                                    "CountryId": "KI",
                                    "Location": "176.0047, -1.354034"
                                }
                            ]
                        },
                        {
                            "Id": "NIGA",
                            "Name": "Nikunau",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "176.467, -1.35",
                            "IataCode": "NIG",
                            "Airports": [
                                {
                                    "Id": "NIG",
                                    "Name": "Nikunau",
                                    "CityId": "NIGA",
                                    "CountryId": "KI",
                                    "Location": "176.40968, -1.314841"
                                }
                            ]
                        },
                        {
                            "Id": "OOTA",
                            "Name": "Onotoa",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "175.55, -1.867",
                            "IataCode": "OOT",
                            "Airports": [
                                {
                                    "Id": "OOT",
                                    "Name": "Onotoa",
                                    "CityId": "OOTA",
                                    "CountryId": "KI",
                                    "Location": "175.56667, -1.916667"
                                }
                            ]
                        },
                        {
                            "Id": "TMNA",
                            "Name": "Tamana Island",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "175.967, -2.5",
                            "IataCode": "TMN",
                            "Airports": [
                                {
                                    "Id": "TMN",
                                    "Name": "Tamana Island",
                                    "CityId": "TMNA",
                                    "CountryId": "KI",
                                    "Location": "175.98333, -2.483333"
                                }
                            ]
                        },
                        {
                            "Id": "AISA",
                            "Name": "Arorae Island",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "176.817, -2.633",
                            "IataCode": "AIS",
                            "Airports": [
                                {
                                    "Id": "AIS",
                                    "Name": "Arorae Island",
                                    "CityId": "AISA",
                                    "CountryId": "KI",
                                    "Location": "176.83333, -2.65"
                                }
                            ]
                        },
                        {
                            "Id": "TNQA",
                            "Name": "Teraina",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "-160.4, 4.717",
                            "IataCode": "TNQ",
                            "Airports": [
                                {
                                    "Id": "TNQ",
                                    "Name": "Teraina",
                                    "CityId": "TNQA",
                                    "CountryId": "KI",
                                    "Location": "-160.33333, 4.7"
                                }
                            ]
                        },
                        {
                            "Id": "TNVA",
                            "Name": "Tabuaeran",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "-159.367, 3.867",
                            "IataCode": "TNV",
                            "Airports": [
                                {
                                    "Id": "TNV",
                                    "Name": "Tabuaeran",
                                    "CityId": "TNVA",
                                    "CountryId": "KI",
                                    "Location": "-159.35, 3.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CXIA",
                            "Name": "Christmas Island",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "-157.4, 1.883",
                            "IataCode": "CXI",
                            "Airports": [
                                {
                                    "Id": "CXI",
                                    "Name": "Christmas Island",
                                    "CityId": "CXIA",
                                    "CountryId": "KI",
                                    "Location": "-157.34972, 1.986111"
                                }
                            ]
                        },
                        {
                            "Id": "CISA",
                            "Name": "Canton Island",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "-171.717, -2.833",
                            "IataCode": "CIS",
                            "Airports": [
                                {
                                    "Id": "CIS",
                                    "Name": "Canton Island",
                                    "CityId": "CISA",
                                    "CountryId": "KI",
                                    "Location": "-171.71577, -2.76705"
                                }
                            ]
                        },
                        {
                            "Id": "TBFA",
                            "Name": "Utiroa",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "174.75, -1.2166667",
                            "IataCode": "TBF",
                            "Airports": [
                                {
                                    "Id": "TBF",
                                    "Name": "Tabiteuea North",
                                    "CityId": "TBFA",
                                    "CountryId": "KI",
                                    "Location": "174.78, -1.231944"
                                }
                            ]
                        },
                        {
                            "Id": "TSUA",
                            "Name": "Buariki",
                            "SingleAirportCity": true,
                            "CountryId": "KI",
                            "Location": "175.06667, -1.4833333",
                            "IataCode": "TSU",
                            "Airports": [
                                {
                                    "Id": "TSU",
                                    "Name": "Tabiteuea South",
                                    "CityId": "TSUA",
                                    "CountryId": "KI",
                                    "Location": "174.83333, -1.416667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TV",
                    "Name": "Tuvalu",
                    "CurrencyId": "AUD",
                    "Cities": [
                        {
                            "Id": "FUNA",
                            "Name": "Funafuti",
                            "SingleAirportCity": true,
                            "CountryId": "TV",
                            "Location": "179.21667, -8.5166667",
                            "IataCode": "FUN",
                            "Airports": [
                                {
                                    "Id": "FUN",
                                    "Name": "Funafuti",
                                    "CityId": "FUNA",
                                    "CountryId": "TV",
                                    "Location": "179.20833, -8.516667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MH",
                    "Name": "Marshall Islands",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "JEJA",
                            "Name": "Jeh",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169, 7.5",
                            "IataCode": "JEJ",
                            "Airports": [
                                {
                                    "Id": "JEJ",
                                    "Name": "Jeh",
                                    "CityId": "JEJA",
                                    "CountryId": "MH",
                                    "Location": "169, 7.5"
                                }
                            ]
                        },
                        {
                            "Id": "KBTA",
                            "Name": "Kaben",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "170.845, 8.893333",
                            "IataCode": "KBT",
                            "Airports": [
                                {
                                    "Id": "KBT",
                                    "Name": "Kaben",
                                    "CityId": "KBTA",
                                    "CountryId": "MH",
                                    "Location": "170.91667, 9.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MJEA",
                            "Name": "Majkin",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "170, 9",
                            "IataCode": "MJE",
                            "Airports": [
                                {
                                    "Id": "MJE",
                                    "Name": "Majkin",
                                    "CityId": "MJEA",
                                    "CountryId": "MH",
                                    "Location": "170, 9"
                                }
                            ]
                        },
                        {
                            "Id": "WJAA",
                            "Name": "Woja",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "170, 8.833333",
                            "IataCode": "WJA",
                            "Airports": [
                                {
                                    "Id": "WJA",
                                    "Name": "Woja",
                                    "CityId": "WJAA",
                                    "CountryId": "MH",
                                    "Location": "168.55131, 7.4522"
                                }
                            ]
                        },
                        {
                            "Id": "JATA",
                            "Name": "Jabot",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.96667, 7.75",
                            "IataCode": "JAT",
                            "Airports": [
                                {
                                    "Id": "JAT",
                                    "Name": "Jabot",
                                    "CityId": "JATA",
                                    "CountryId": "MH",
                                    "Location": "168.96667, 7.75"
                                }
                            ]
                        },
                        {
                            "Id": "EBNA",
                            "Name": "Ebadon",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "166.8, 9.333333",
                            "IataCode": "EBN",
                            "Airports": [
                                {
                                    "Id": "EBN",
                                    "Name": "Ebadon",
                                    "CityId": "EBNA",
                                    "CountryId": "MH",
                                    "Location": "166.81917, 9.33055"
                                }
                            ]
                        },
                        {
                            "Id": "LOFA",
                            "Name": "Loen",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.23333, 7.75",
                            "IataCode": "LOF",
                            "Airports": [
                                {
                                    "Id": "LOF",
                                    "Name": "Loen",
                                    "CityId": "LOFA",
                                    "CountryId": "MH",
                                    "Location": "169.91667, 7.916667"
                                }
                            ]
                        },
                        {
                            "Id": "TBVA",
                            "Name": "Tabal",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.94722, 10.038889",
                            "IataCode": "TBV",
                            "Airports": [
                                {
                                    "Id": "TBV",
                                    "Name": "Tabal",
                                    "CityId": "TBVA",
                                    "CountryId": "MH",
                                    "Location": "169.94722, 10.038889"
                                }
                            ]
                        },
                        {
                            "Id": "AICA",
                            "Name": "Airok",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.83333, 7.26667",
                            "IataCode": "AIC",
                            "Airports": [
                                {
                                    "Id": "AIC",
                                    "Name": "Ailinglapalap",
                                    "CityId": "AICA",
                                    "CountryId": "MH",
                                    "Location": "168.8255, 7.278821"
                                }
                            ]
                        },
                        {
                            "Id": "KWAA",
                            "Name": "Kwajalein",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "167.78333, 8.71667",
                            "IataCode": "KWA",
                            "Airports": [
                                {
                                    "Id": "KWA",
                                    "Name": "Kwajalein",
                                    "CityId": "KWAA",
                                    "CountryId": "MH",
                                    "Location": "167.73333, 8.716667"
                                }
                            ]
                        },
                        {
                            "Id": "ENTA",
                            "Name": "Enewetak Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "162.333, 11.5",
                            "IataCode": "ENT",
                            "Airports": [
                                {
                                    "Id": "ENT",
                                    "Name": "Enewetak Island",
                                    "CityId": "ENTA",
                                    "CountryId": "MH",
                                    "Location": "162.25, 11.5"
                                }
                            ]
                        },
                        {
                            "Id": "BIIA",
                            "Name": "Bikini Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "165.383, 11.583",
                            "IataCode": "BII",
                            "Airports": [
                                {
                                    "Id": "BII",
                                    "Name": "Bikini Atoll",
                                    "CityId": "BIIA",
                                    "CountryId": "MH",
                                    "Location": "166, 12"
                                }
                            ]
                        },
                        {
                            "Id": "RNPA",
                            "Name": "Rongelap Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "166.783, 11.317",
                            "IataCode": "RNP",
                            "Airports": [
                                {
                                    "Id": "RNP",
                                    "Name": "Rongelap Island",
                                    "CityId": "RNPA",
                                    "CountryId": "MH",
                                    "Location": "166.88333, 11.15"
                                }
                            ]
                        },
                        {
                            "Id": "WTOA",
                            "Name": "Wotho Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "165.83333, 10.083333",
                            "IataCode": "WTO",
                            "Airports": [
                                {
                                    "Id": "WTO",
                                    "Name": "Wotho Island",
                                    "CityId": "WTOA",
                                    "CountryId": "MH",
                                    "Location": "166.03333, 10.166667"
                                }
                            ]
                        },
                        {
                            "Id": "UJEA",
                            "Name": "Ujae Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "165.65, 9.05",
                            "IataCode": "UJE",
                            "Airports": [
                                {
                                    "Id": "UJE",
                                    "Name": "Ujae Island",
                                    "CityId": "UJEA",
                                    "CountryId": "MH",
                                    "Location": "165.66667, 9.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LMLA",
                            "Name": "Lae Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "166.2375, 8.93611",
                            "IataCode": "LML",
                            "Airports": [
                                {
                                    "Id": "LML",
                                    "Name": "Lae Island",
                                    "CityId": "LMLA",
                                    "CountryId": "MH",
                                    "Location": "166.25, 8.916667"
                                }
                            ]
                        },
                        {
                            "Id": "NMUA",
                            "Name": "Namu",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.17361, 7.983333",
                            "IataCode": "NMU",
                            "Airports": [
                                {
                                    "Id": "NMU",
                                    "Name": "Namu",
                                    "CityId": "NMUA",
                                    "CountryId": "MH",
                                    "Location": "168.13333, 8"
                                }
                            ]
                        },
                        {
                            "Id": "UITA",
                            "Name": "Jaluit Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.6425, 5.92167",
                            "IataCode": "UIT",
                            "Airports": [
                                {
                                    "Id": "UIT",
                                    "Name": "Jaluit Island",
                                    "CityId": "UITA",
                                    "CountryId": "MH",
                                    "Location": "169.61667, 5.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KIOA",
                            "Name": "Kili Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.12833, 5.64611",
                            "IataCode": "KIO",
                            "Airports": [
                                {
                                    "Id": "KIO",
                                    "Name": "Kili Island",
                                    "CityId": "KIOA",
                                    "CountryId": "MH",
                                    "Location": "169.2, 5.65"
                                }
                            ]
                        },
                        {
                            "Id": "NDKA",
                            "Name": "Namdrik Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.11667, 5.616667",
                            "IataCode": "NDK",
                            "Airports": [
                                {
                                    "Id": "NDK",
                                    "Name": "Namdrik Island",
                                    "CityId": "NDKA",
                                    "CountryId": "MH",
                                    "Location": "168.11667, 5.633333"
                                }
                            ]
                        },
                        {
                            "Id": "EBOA",
                            "Name": "Ebon",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "168.71667, 4.633333",
                            "IataCode": "EBO",
                            "Airports": [
                                {
                                    "Id": "EBO",
                                    "Name": "Ebon",
                                    "CityId": "EBOA",
                                    "CountryId": "MH",
                                    "Location": "168.75, 4.6"
                                }
                            ]
                        },
                        {
                            "Id": "UTKA",
                            "Name": "Utirik Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.8, 11.25389",
                            "IataCode": "UTK",
                            "Airports": [
                                {
                                    "Id": "UTK",
                                    "Name": "Utirik Island",
                                    "CityId": "UTKA",
                                    "CountryId": "MH",
                                    "Location": "169.86667, 11.233333"
                                }
                            ]
                        },
                        {
                            "Id": "MJBA",
                            "Name": "Mejit Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "170.867, 10.283",
                            "IataCode": "MJB",
                            "Airports": [
                                {
                                    "Id": "MJB",
                                    "Name": "Mejit Island",
                                    "CityId": "MJBA",
                                    "CountryId": "MH",
                                    "Location": "170.88333, 10.283333"
                                }
                            ]
                        },
                        {
                            "Id": "AIMA",
                            "Name": "Ailuk Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.93333, 10.32778",
                            "IataCode": "AIM",
                            "Airports": [
                                {
                                    "Id": "AIM",
                                    "Name": "Ailuk Island",
                                    "CityId": "AIMA",
                                    "CountryId": "MH",
                                    "Location": "169.98333, 10.2"
                                }
                            ]
                        },
                        {
                            "Id": "LIKA",
                            "Name": "Likiep Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "169.13333, 9.9",
                            "IataCode": "LIK",
                            "Airports": [
                                {
                                    "Id": "LIK",
                                    "Name": "Likiep Island",
                                    "CityId": "LIKA",
                                    "CountryId": "MH",
                                    "Location": "169.31667, 9.816667"
                                }
                            ]
                        },
                        {
                            "Id": "WTEA",
                            "Name": "Wotje Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "170.01667, 9.44167",
                            "IataCode": "WTE",
                            "Airports": [
                                {
                                    "Id": "WTE",
                                    "Name": "Wotje Island",
                                    "CityId": "WTEA",
                                    "CountryId": "MH",
                                    "Location": "170.26667, 9.45"
                                }
                            ]
                        },
                        {
                            "Id": "MAVA",
                            "Name": "Maloelap Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.06667, 8.75",
                            "IataCode": "MAV",
                            "Airports": [
                                {
                                    "Id": "MAV",
                                    "Name": "Maloelap Island",
                                    "CityId": "MAVA",
                                    "CountryId": "MH",
                                    "Location": "171.1, 8.75"
                                }
                            ]
                        },
                        {
                            "Id": "AULA",
                            "Name": "Aur Island",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.1, 8.2",
                            "IataCode": "AUL",
                            "Airports": [
                                {
                                    "Id": "AUL",
                                    "Name": "Aur Island",
                                    "CityId": "AULA",
                                    "CountryId": "MH",
                                    "Location": "171.16667, 8.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MAJA",
                            "Name": "Majuro",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.267, 7.067",
                            "IataCode": "MAJ",
                            "Airports": [
                                {
                                    "Id": "MAJ",
                                    "Name": "Majuro",
                                    "CityId": "MAJA",
                                    "CountryId": "MH",
                                    "Location": "171.27028, 7.063611"
                                }
                            ]
                        },
                        {
                            "Id": "AMRA",
                            "Name": "Arno",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.7, 7.083",
                            "IataCode": "AMR",
                            "Airports": [
                                {
                                    "Id": "AMR",
                                    "Name": "Arno",
                                    "CityId": "AMRA",
                                    "CountryId": "MH",
                                    "Location": "171.23333, 7.033333"
                                }
                            ]
                        },
                        {
                            "Id": "IMIA",
                            "Name": "Ine",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.7, 6.9833333",
                            "IataCode": "IMI",
                            "Airports": [
                                {
                                    "Id": "IMI",
                                    "Name": "Ine Island",
                                    "CityId": "IMIA",
                                    "CountryId": "MH",
                                    "Location": "171.61667, 6.983333"
                                }
                            ]
                        },
                        {
                            "Id": "EJTA",
                            "Name": "Anejet",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.93333, 6.03333",
                            "IataCode": "EJT",
                            "Airports": [
                                {
                                    "Id": "EJT",
                                    "Name": "Mili Atoll",
                                    "CityId": "EJTA",
                                    "CountryId": "MH",
                                    "Location": "171.95, 6.083333"
                                }
                            ]
                        },
                        {
                            "Id": "TICA",
                            "Name": "Anekirea",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.75, 6.96667",
                            "IataCode": "TIC",
                            "Airports": [
                                {
                                    "Id": "TIC",
                                    "Name": "Tinak Island",
                                    "CityId": "TICA",
                                    "CountryId": "MH",
                                    "Location": "171.78333, 7.05"
                                }
                            ]
                        },
                        {
                            "Id": "EALA",
                            "Name": "Kwajalein Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "167.733, 8.717",
                            "IataCode": "EAL",
                            "Airports": [
                                {
                                    "Id": "EAL",
                                    "Name": "Kwajalein Atoll",
                                    "CityId": "EALA",
                                    "CountryId": "MH",
                                    "Location": "166.84997, 9.31865"
                                }
                            ]
                        },
                        {
                            "Id": "MIJA",
                            "Name": "Mili Atoll",
                            "SingleAirportCity": true,
                            "CountryId": "MH",
                            "Location": "171.91667, 6.133333",
                            "IataCode": "MIJ",
                            "Airports": [
                                {
                                    "Id": "MIJ",
                                    "Name": "Mili Island",
                                    "CityId": "MIJA",
                                    "CountryId": "MH",
                                    "Location": "171.75, 6.083333"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AS",
                    "Name": "American Samoa",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "FTIA",
                            "Name": "Fitiuta",
                            "SingleAirportCity": true,
                            "CountryId": "AS",
                            "Location": "-169.42361, -14.222222",
                            "IataCode": "FTI",
                            "Airports": [
                                {
                                    "Id": "FTI",
                                    "Name": "Fitiuta",
                                    "CityId": "FTIA",
                                    "CountryId": "AS",
                                    "Location": "-169.42442, -14.215549"
                                }
                            ]
                        },
                        {
                            "Id": "PPGA",
                            "Name": "Pago Pago",
                            "SingleAirportCity": true,
                            "CountryId": "AS",
                            "Location": "-170.7025, -14.27806",
                            "IataCode": "PPG",
                            "Airports": [
                                {
                                    "Id": "PPG",
                                    "Name": "Pago Pago",
                                    "CityId": "PPGA",
                                    "CountryId": "AS",
                                    "Location": "-170.69639, -14.326389"
                                }
                            ]
                        },
                        {
                            "Id": "TAVA",
                            "Name": "Tau",
                            "SingleAirportCity": true,
                            "CountryId": "AS",
                            "Location": "-169.51444, -14.233611",
                            "IataCode": "TAV",
                            "Airports": [
                                {
                                    "Id": "TAV",
                                    "Name": "Tau",
                                    "CityId": "TAVA",
                                    "CountryId": "AS",
                                    "Location": "-169.51111, -14.22917"
                                }
                            ]
                        },
                        {
                            "Id": "OFUA",
                            "Name": "Ofu",
                            "SingleAirportCity": true,
                            "CountryId": "AS",
                            "Location": "-169.6775, -14.17528",
                            "IataCode": "OFU",
                            "Airports": [
                                {
                                    "Id": "OFU",
                                    "Name": "Ofu",
                                    "CityId": "OFUA",
                                    "CountryId": "AS",
                                    "Location": "-169.7, -14.166667"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TO",
                    "Name": "Tonga",
                    "CurrencyId": "TOP",
                    "Cities": [
                        {
                            "Id": "NTTA",
                            "Name": "Niuatoputapu",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-173.744, -15.956",
                            "IataCode": "NTT",
                            "Airports": [
                                {
                                    "Id": "NTT",
                                    "Name": "Niuatoputapu",
                                    "CityId": "NTTA",
                                    "CountryId": "TO",
                                    "Location": "-174, -15.5"
                                }
                            ]
                        },
                        {
                            "Id": "EUAA",
                            "Name": "Eua",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-174.93389, -21.3875",
                            "IataCode": "EUA",
                            "Airports": [
                                {
                                    "Id": "EUA",
                                    "Name": "Eua",
                                    "CityId": "EUAA",
                                    "CountryId": "TO",
                                    "Location": "-174.95917, -21.375833"
                                }
                            ]
                        },
                        {
                            "Id": "VAVA",
                            "Name": "Vava'u",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-174, -18.6",
                            "IataCode": "VAV",
                            "Airports": [
                                {
                                    "Id": "VAV",
                                    "Name": "Vava'u",
                                    "CityId": "VAVA",
                                    "CountryId": "TO",
                                    "Location": "-173.96667, -18.583333"
                                }
                            ]
                        },
                        {
                            "Id": "HPAA",
                            "Name": "Ha'Apai",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-174.59973, -19.931929",
                            "IataCode": "HPA",
                            "Airports": [
                                {
                                    "Id": "HPA",
                                    "Name": "Ha'Apai",
                                    "CityId": "HPAA",
                                    "CountryId": "TO",
                                    "Location": "-174.33333, -19.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TONG",
                            "Name": "Tongatapu",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-175.17936, -21.156128",
                            "IataCode": "TBU",
                            "Airports": [
                                {
                                    "Id": "TBU",
                                    "Name": "Tongatapu",
                                    "CityId": "TONG",
                                    "CountryId": "TO",
                                    "Location": "-175.15, -21.25"
                                }
                            ]
                        },
                        {
                            "Id": "NFOA",
                            "Name": "Mata`aho",
                            "SingleAirportCity": true,
                            "CountryId": "TO",
                            "Location": "-175.65, -15.6",
                            "IataCode": "NFO",
                            "Airports": [
                                {
                                    "Id": "NFO",
                                    "Name": "Niuafo'ou",
                                    "CityId": "NFOA",
                                    "CountryId": "TO",
                                    "Location": "-175.65, -15.6"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "MP",
                    "Name": "Northern Mariana Islands",
                    "CurrencyId": "USD",
                    "Cities": [
                        {
                            "Id": "SPNA",
                            "Name": "Saipan",
                            "SingleAirportCity": true,
                            "CountryId": "MP",
                            "Location": "145.75988, 15.187125",
                            "IataCode": "SPN",
                            "Airports": [
                                {
                                    "Id": "SPN",
                                    "Name": "Saipan",
                                    "CityId": "SPNA",
                                    "CountryId": "MP",
                                    "Location": "145.71167, 15.122778"
                                }
                            ]
                        },
                        {
                            "Id": "ROPA",
                            "Name": "Rota",
                            "SingleAirportCity": true,
                            "CountryId": "MP",
                            "Location": "145.20912, 14.154263",
                            "IataCode": "ROP",
                            "Airports": [
                                {
                                    "Id": "ROP",
                                    "Name": "Rota",
                                    "CityId": "ROPA",
                                    "CountryId": "MP",
                                    "Location": "145.24944, 14.168333"
                                }
                            ]
                        },
                        {
                            "Id": "TIQA",
                            "Name": "Tinian",
                            "SingleAirportCity": true,
                            "CountryId": "MP",
                            "Location": "145.6278, 15.001221",
                            "IataCode": "TIQ",
                            "Airports": [
                                {
                                    "Id": "TIQ",
                                    "Name": "Tinian",
                                    "CityId": "TIQA",
                                    "CountryId": "MP",
                                    "Location": "145.625, 15.001944"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "TK",
                    "Name": "Tokelau",
                    "CurrencyId": "NZD",
                    "Cities": []
                },
                {
                    "Id": "VU",
                    "Name": "Vanuatu",
                    "CurrencyId": "VUV",
                    "Cities": [
                        {
                            "Id": "WLHV",
                            "Name": "Walaha",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.7, -15.416667",
                            "IataCode": "WLH",
                            "Airports": [
                                {
                                    "Id": "WLH",
                                    "Name": "Walaha",
                                    "CityId": "WLHV",
                                    "CountryId": "VU",
                                    "Location": "167.7, -15.416667"
                                }
                            ]
                        },
                        {
                            "Id": "LNEV",
                            "Name": "Lonorore",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.16667, -15.85",
                            "IataCode": "LNE",
                            "Airports": [
                                {
                                    "Id": "LNE",
                                    "Name": "Lonorore",
                                    "CityId": "LNEV",
                                    "CountryId": "VU",
                                    "Location": "168.172, -15.8656"
                                }
                            ]
                        },
                        {
                            "Id": "LPMV",
                            "Name": "Lamap",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.8, -16.433333",
                            "IataCode": "LPM",
                            "Airports": [
                                {
                                    "Id": "LPM",
                                    "Name": "Lamap",
                                    "CityId": "LPMV",
                                    "CountryId": "VU",
                                    "Location": "167.81667, -16.466667"
                                }
                            ]
                        },
                        {
                            "Id": "NUSV",
                            "Name": "Norsup",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.38333, -16.066667",
                            "IataCode": "NUS",
                            "Airports": [
                                {
                                    "Id": "NUS",
                                    "Name": "Norsup",
                                    "CityId": "NUSV",
                                    "CountryId": "VU",
                                    "Location": "167.4, -16.058333"
                                }
                            ]
                        },
                        {
                            "Id": "SLHV",
                            "Name": "Sola",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.55, -13.883333",
                            "IataCode": "SLH",
                            "Airports": [
                                {
                                    "Id": "SLH",
                                    "Name": "Sola",
                                    "CityId": "SLHV",
                                    "CountryId": "VU",
                                    "Location": "167.55, -13.883333"
                                }
                            ]
                        },
                        {
                            "Id": "VLIV",
                            "Name": "Port Vila",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.31667, -17.733333",
                            "IataCode": "VLI",
                            "Airports": [
                                {
                                    "Id": "VLI",
                                    "Name": "Port Vila",
                                    "CityId": "VLIV",
                                    "CountryId": "VU",
                                    "Location": "168.31972, -17.699444"
                                }
                            ]
                        },
                        {
                            "Id": "OLJV",
                            "Name": "Olpoi",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "166.55, -14.866667",
                            "IataCode": "OLJ",
                            "Airports": [
                                {
                                    "Id": "OLJ",
                                    "Name": "Olpoi",
                                    "CityId": "OLJV",
                                    "CountryId": "VU",
                                    "Location": "166.5, -15"
                                }
                            ]
                        },
                        {
                            "Id": "VLSV",
                            "Name": "Valesdir",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.16667, -16.783333",
                            "IataCode": "VLS",
                            "Airports": [
                                {
                                    "Id": "VLS",
                                    "Name": "Valesdir",
                                    "CityId": "VLSV",
                                    "CountryId": "VU",
                                    "Location": "168.2, -16.8"
                                }
                            ]
                        },
                        {
                            "Id": "TOHV",
                            "Name": "Torres",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "166.60919, -13.244264",
                            "IataCode": "TOH",
                            "Airports": [
                                {
                                    "Id": "TOH",
                                    "Name": "Torres",
                                    "CityId": "TOHV",
                                    "CountryId": "VU",
                                    "Location": "166.638, -13.328"
                                }
                            ]
                        },
                        {
                            "Id": "ZGUA",
                            "Name": "Gaua",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.52, -14.265",
                            "IataCode": "ZGU",
                            "Airports": [
                                {
                                    "Id": "ZGU",
                                    "Name": "Gaua",
                                    "CityId": "ZGUA",
                                    "CountryId": "VU",
                                    "Location": "167.5, -14.25"
                                }
                            ]
                        },
                        {
                            "Id": "MTVV",
                            "Name": "Mota Lava",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.64, -13.708055",
                            "IataCode": "MTV",
                            "Airports": [
                                {
                                    "Id": "MTV",
                                    "Name": "Mota Lava",
                                    "CityId": "MTVV",
                                    "CountryId": "VU",
                                    "Location": "167.66667, -13.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SONV",
                            "Name": "Espiritu Santo",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.01633, -15.537667",
                            "IataCode": "SON",
                            "Airports": [
                                {
                                    "Id": "SON",
                                    "Name": "Espiritu Santo",
                                    "CityId": "SONV",
                                    "CountryId": "VU",
                                    "Location": "167.21667, -15.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MWFV",
                            "Name": "Maewo",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.167, -15.167",
                            "IataCode": "MWF",
                            "Airports": [
                                {
                                    "Id": "MWF",
                                    "Name": "Maewo",
                                    "CityId": "MWFV",
                                    "CountryId": "VU",
                                    "Location": "168.16667, -15.166667"
                                }
                            ]
                        },
                        {
                            "Id": "PBJV",
                            "Name": "Paama",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.217, -16.467",
                            "IataCode": "PBJ",
                            "Airports": [
                                {
                                    "Id": "PBJ",
                                    "Name": "Paama",
                                    "CityId": "PBJV",
                                    "CountryId": "VU",
                                    "Location": "168.21667, -16.433333"
                                }
                            ]
                        },
                        {
                            "Id": "EPIV",
                            "Name": "Epi",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "173.6915, -12.745",
                            "IataCode": "EPI",
                            "Airports": [
                                {
                                    "Id": "EPI",
                                    "Name": "Epi",
                                    "CityId": "EPIV",
                                    "CountryId": "VU",
                                    "Location": "168.25, -16.716667"
                                }
                            ]
                        },
                        {
                            "Id": "EAEV",
                            "Name": "Emae",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.4, -17.067",
                            "IataCode": "EAE",
                            "Airports": [
                                {
                                    "Id": "EAE",
                                    "Name": "Emae",
                                    "CityId": "EAEV",
                                    "CountryId": "VU",
                                    "Location": "168.41667, -17.166667"
                                }
                            ]
                        },
                        {
                            "Id": "TAHV",
                            "Name": "Tanna",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "169.333, -19.5",
                            "IataCode": "TAH",
                            "Airports": [
                                {
                                    "Id": "TAH",
                                    "Name": "Tanna",
                                    "CityId": "TAHV",
                                    "CountryId": "VU",
                                    "Location": "169.25, -19.333333"
                                }
                            ]
                        },
                        {
                            "Id": "AWDV",
                            "Name": "Aniwa",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "169.6, -19.254",
                            "IataCode": "AWD",
                            "Airports": [
                                {
                                    "Id": "AWD",
                                    "Name": "Aniwa",
                                    "CityId": "AWDV",
                                    "CountryId": "VU",
                                    "Location": "169.5, -19.25"
                                }
                            ]
                        },
                        {
                            "Id": "FTAV",
                            "Name": "Futuna Island",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "170.217, -19.533",
                            "IataCode": "FTA",
                            "Airports": [
                                {
                                    "Id": "FTA",
                                    "Name": "Futuna Island",
                                    "CityId": "FTAV",
                                    "CountryId": "VU",
                                    "Location": "170.23199, -19.5164"
                                }
                            ]
                        },
                        {
                            "Id": "AUYV",
                            "Name": "Aneityum",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "169.817, -20.2",
                            "IataCode": "AUY",
                            "Airports": [
                                {
                                    "Id": "AUY",
                                    "Name": "Aneityum",
                                    "CityId": "AUYV",
                                    "CountryId": "VU",
                                    "Location": "169.66667, -20.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TGHV",
                            "Name": "Worafiou",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.54667, -16.903611",
                            "IataCode": "TGH",
                            "Airports": [
                                {
                                    "Id": "TGH",
                                    "Name": "Tongoa",
                                    "CityId": "TGHV",
                                    "CountryId": "VU",
                                    "Location": "168.54667, -16.903611"
                                }
                            ]
                        },
                        {
                            "Id": "UIQV",
                            "Name": "Vovo",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.18333, -16.816667",
                            "IataCode": "UIQ",
                            "Airports": [
                                {
                                    "Id": "UIQ",
                                    "Name": "Quine Hill",
                                    "CityId": "UIQV",
                                    "CountryId": "VU",
                                    "Location": "168.16667, -17"
                                }
                            ]
                        },
                        {
                            "Id": "GBAV",
                            "Name": "Tombaesar",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "166.01667, -15.4",
                            "IataCode": "GBA",
                            "Airports": [
                                {
                                    "Id": "GBA",
                                    "Name": "Big Bay",
                                    "CityId": "GBAV",
                                    "CountryId": "VU",
                                    "Location": "166, -15"
                                }
                            ]
                        },
                        {
                            "Id": "ULBV",
                            "Name": "Tématou",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.31667, -16.483333",
                            "IataCode": "ULB",
                            "Airports": [
                                {
                                    "Id": "ULB",
                                    "Name": "Ulei",
                                    "CityId": "ULBV",
                                    "CountryId": "VU",
                                    "Location": "168.33333, -16.416667"
                                }
                            ]
                        },
                        {
                            "Id": "IPAV",
                            "Name": "Potnarvin",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "169.18333, -18.75",
                            "IataCode": "IPA",
                            "Airports": [
                                {
                                    "Id": "IPA",
                                    "Name": "Ipota",
                                    "CityId": "IPAV",
                                    "CountryId": "VU",
                                    "Location": "169.18333, -18.75"
                                }
                            ]
                        },
                        {
                            "Id": "DLYV",
                            "Name": "Pontasipsip",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "169.15, -18.666667",
                            "IataCode": "DLY",
                            "Airports": [
                                {
                                    "Id": "DLY",
                                    "Name": "Dillons Bay",
                                    "CityId": "DLYV",
                                    "CountryId": "VU",
                                    "Location": "169.00101, -18.7694"
                                }
                            ]
                        },
                        {
                            "Id": "LODV",
                            "Name": "Navonda",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.96667, -15.316667",
                            "IataCode": "LOD",
                            "Airports": [
                                {
                                    "Id": "LOD",
                                    "Name": "Longana",
                                    "CityId": "LODV",
                                    "CountryId": "VU",
                                    "Location": "167.96667, -15.316667"
                                }
                            ]
                        },
                        {
                            "Id": "CCVV",
                            "Name": "Linarar",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.46667, -16.183333",
                            "IataCode": "CCV",
                            "Airports": [
                                {
                                    "Id": "CCV",
                                    "Name": "Craig Cove",
                                    "CityId": "CCVV",
                                    "CountryId": "VU",
                                    "Location": "167.5, -16.2"
                                }
                            ]
                        },
                        {
                            "Id": "LNBV",
                            "Name": "Lamarou",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.16667, -16.583333",
                            "IataCode": "LNB",
                            "Airports": [
                                {
                                    "Id": "LNB",
                                    "Name": "Lamen Bay",
                                    "CityId": "LNBV",
                                    "CountryId": "VU",
                                    "Location": "168.18333, -16.583333"
                                }
                            ]
                        },
                        {
                            "Id": "SWJV",
                            "Name": "Labo",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "167.43333, -16.466667",
                            "IataCode": "SWJ",
                            "Airports": [
                                {
                                    "Id": "SWJ",
                                    "Name": "South West Bay",
                                    "CityId": "SWJV",
                                    "CountryId": "VU",
                                    "Location": "167.438, -16.495"
                                }
                            ]
                        },
                        {
                            "Id": "SSRV",
                            "Name": "Alélé",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.16667, -15.516667",
                            "IataCode": "SSR",
                            "Airports": [
                                {
                                    "Id": "SSR",
                                    "Name": "Sara",
                                    "CityId": "SSRV",
                                    "CountryId": "VU",
                                    "Location": "168.3, -15.5"
                                }
                            ]
                        },
                        {
                            "Id": "RCLV",
                            "Name": "Orati",
                            "SingleAirportCity": true,
                            "CountryId": "VU",
                            "Location": "168.58333, -17.733333",
                            "IataCode": "RCL",
                            "Airports": [
                                {
                                    "Id": "RCL",
                                    "Name": "Redcliffe",
                                    "CityId": "RCLV",
                                    "CountryId": "VU",
                                    "Location": "167.83501, -15.472"
                                }
                            ]
                        }
                    ]
                },
                {
                    "Id": "AU",
                    "Name": "Australia",
                    "CurrencyId": "AUD",
                    "LanguageId": "EN",
                    "Regions": [
                        {
                            "Id": "ACTER",
                            "Name": "Australian Capital Territory",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "NEW_S",
                            "Name": "New South Wales",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "QUEEN",
                            "Name": "Queensland",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "SOU_A",
                            "Name": "South Australia",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "TASMA",
                            "Name": "Tasmania",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "VICTO",
                            "Name": "Victoria",
                            "CountryId": "AU"
                        },
                        {
                            "Id": "WESTE",
                            "Name": "Western Australia",
                            "CountryId": "AU"
                        }
                    ],
                    "Cities": [
                        {
                            "Id": "AUUA",
                            "Name": "Aurukun Mission",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.25, -13.166667",
                            "IataCode": "AUU",
                            "Airports": [
                                {
                                    "Id": "AUU",
                                    "Name": "Aurukun Mission",
                                    "CityId": "AUUA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.71986, -13.354882"
                                }
                            ]
                        },
                        {
                            "Id": "DMDA",
                            "Name": "Doomadgee",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.83333, -18",
                            "IataCode": "DMD",
                            "Airports": [
                                {
                                    "Id": "DMD",
                                    "Name": "Doomadgee",
                                    "CityId": "DMDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.83333, -18"
                                }
                            ]
                        },
                        {
                            "Id": "EDRA",
                            "Name": "Edward River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.61667, -14.9",
                            "IataCode": "EDR",
                            "Airports": [
                                {
                                    "Id": "EDR",
                                    "Name": "Edward River",
                                    "CityId": "EDRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.61667, -14.9"
                                }
                            ]
                        },
                        {
                            "Id": "ELCA",
                            "Name": "Elcho Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.56667, -12.016667",
                            "IataCode": "ELC",
                            "Airports": [
                                {
                                    "Id": "ELC",
                                    "Name": "Elcho Island",
                                    "CityId": "ELCA",
                                    "CountryId": "AU",
                                    "Location": "135.56667, -12.016667"
                                }
                            ]
                        },
                        {
                            "Id": "IRGA",
                            "Name": "Lockhart River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.34317, -12.786273",
                            "IataCode": "IRG",
                            "Airports": [
                                {
                                    "Id": "IRG",
                                    "Name": "Lockhart River",
                                    "CityId": "IRGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.3125, -12.788889"
                                }
                            ]
                        },
                        {
                            "Id": "LELA",
                            "Name": "Lake Evella",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.8, -12.508333",
                            "IataCode": "LEL",
                            "Airports": [
                                {
                                    "Id": "LEL",
                                    "Name": "Lake Evella",
                                    "CityId": "LELA",
                                    "CountryId": "AU",
                                    "Location": "135.8, -12.508333"
                                }
                            ]
                        },
                        {
                            "Id": "MCVA",
                            "Name": "Mcarthur River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.095, -16.466667",
                            "IataCode": "MCV",
                            "Airports": [
                                {
                                    "Id": "MCV",
                                    "Name": "Mcarthur River",
                                    "CityId": "MCVA",
                                    "CountryId": "AU",
                                    "Location": "136.095, -16.466667"
                                }
                            ]
                        },
                        {
                            "Id": "MGTA",
                            "Name": "Milingimbi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.89028, -12.096667",
                            "IataCode": "MGT",
                            "Airports": [
                                {
                                    "Id": "MGT",
                                    "Name": "Milingimbi",
                                    "CityId": "MGTA",
                                    "CountryId": "AU",
                                    "Location": "134.89028, -12.096667"
                                }
                            ]
                        },
                        {
                            "Id": "NIFA",
                            "Name": "Nifty",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.58611, -21.675",
                            "IataCode": "NIF",
                            "Airports": [
                                {
                                    "Id": "NIF",
                                    "Name": "Nifty",
                                    "CityId": "NIFA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.58611, -21.675"
                                }
                            ]
                        },
                        {
                            "Id": "ONGA",
                            "Name": "Mornington",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.3, -16.583333",
                            "IataCode": "ONG",
                            "Airports": [
                                {
                                    "Id": "ONG",
                                    "Name": "Mornington",
                                    "CityId": "ONGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.3, -16.583333"
                                }
                            ]
                        },
                        {
                            "Id": "PBOA",
                            "Name": "Paraburdoo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.75, -23.166667",
                            "IataCode": "PBO",
                            "Airports": [
                                {
                                    "Id": "PBO",
                                    "Name": "Paraburdoo",
                                    "CityId": "PBOA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.75, -23.166667"
                                }
                            ]
                        },
                        {
                            "Id": "RAMA",
                            "Name": "Ramingining",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.81667, -12",
                            "IataCode": "RAM",
                            "Airports": [
                                {
                                    "Id": "RAM",
                                    "Name": "Ramingining",
                                    "CityId": "RAMA",
                                    "CountryId": "AU",
                                    "Location": "134.81667, -12"
                                }
                            ]
                        },
                        {
                            "Id": "WLPA",
                            "Name": "West Angleas",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "118.69045, -23.131641",
                            "IataCode": "WLP",
                            "Airports": [
                                {
                                    "Id": "WLP",
                                    "Name": "West Angleas",
                                    "CityId": "WLPA",
                                    "CountryId": "AU",
                                    "Location": "118.70383, -23.136665"
                                }
                            ]
                        },
                        {
                            "Id": "CJFA",
                            "Name": "Coondewanna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "118.81333, -22.966666",
                            "IataCode": "CJF",
                            "Airports": [
                                {
                                    "Id": "CJF",
                                    "Name": "Coondewanna",
                                    "CityId": "CJFA",
                                    "CountryId": "AU",
                                    "Location": "118.81333, -22.966666"
                                }
                            ]
                        },
                        {
                            "Id": "CXQA",
                            "Name": "Christmas Creek",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.84959, -22.379427",
                            "IataCode": "CXQ",
                            "Airports": [
                                {
                                    "Id": "CXQ",
                                    "Name": "Christmas Creek",
                                    "CityId": "CXQA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.91667, -18.866667"
                                },
                                {
                                    "Id": "CKW",
                                    "Name": "Christmas Creek Mine Airport",
                                    "CityId": "CXQA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.65222, -22.355556"
                                }
                            ]
                        },
                        {
                            "Id": "HUBA",
                            "Name": "Humbert River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.75, -16.5",
                            "IataCode": "HUB",
                            "Airports": [
                                {
                                    "Id": "HUB",
                                    "Name": "Humbert River",
                                    "CityId": "HUBA",
                                    "CountryId": "AU",
                                    "Location": "130.75, -16.5"
                                }
                            ]
                        },
                        {
                            "Id": "MIZA",
                            "Name": "Mainoru",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.1, -14",
                            "IataCode": "MIZ",
                            "Airports": [
                                {
                                    "Id": "MIZ",
                                    "Name": "Mainoru",
                                    "CityId": "MIZA",
                                    "CountryId": "AU",
                                    "Location": "134.1, -14"
                                }
                            ]
                        },
                        {
                            "Id": "KOHA",
                            "Name": "Koolatah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.41667, -15.966667",
                            "IataCode": "KOH",
                            "Airports": [
                                {
                                    "Id": "KOH",
                                    "Name": "Koolatah",
                                    "CityId": "KOHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.41667, -15.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BWBA",
                            "Name": "Barrow Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.41667, -20.783333",
                            "IataCode": "BWB",
                            "Airports": [
                                {
                                    "Id": "BWB",
                                    "Name": "Barrow Island",
                                    "CityId": "BWBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.41667, -20.783333"
                                }
                            ]
                        },
                        {
                            "Id": "SSKA",
                            "Name": "Sturt Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.13333, -19.2",
                            "IataCode": "SSK",
                            "Airports": [
                                {
                                    "Id": "SSK",
                                    "Name": "Sturt Creek",
                                    "CityId": "SSKA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.13333, -19.2"
                                }
                            ]
                        },
                        {
                            "Id": "AMTA",
                            "Name": "Amata",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "132.03333, -26.766667",
                            "IataCode": "AMT",
                            "Airports": [
                                {
                                    "Id": "AMT",
                                    "Name": "Amata",
                                    "CityId": "AMTA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "132.03333, -26.766667"
                                }
                            ]
                        },
                        {
                            "Id": "IFFA",
                            "Name": "Iffley",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.21667, -18.9",
                            "IataCode": "IFF",
                            "Airports": [
                                {
                                    "Id": "IFF",
                                    "Name": "Iffley",
                                    "CityId": "IFFA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.21667, -18.9"
                                }
                            ]
                        },
                        {
                            "Id": "AXLA",
                            "Name": "Alexandria",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.66667, -19.083333",
                            "IataCode": "AXL",
                            "Airports": [
                                {
                                    "Id": "AXL",
                                    "Name": "Alexandria",
                                    "CityId": "AXLA",
                                    "CountryId": "AU",
                                    "Location": "136.66667, -19.083333"
                                }
                            ]
                        },
                        {
                            "Id": "VCDA",
                            "Name": "Victoria River Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131, -16.4",
                            "IataCode": "VCD",
                            "Airports": [
                                {
                                    "Id": "VCD",
                                    "Name": "Victoria River Downs",
                                    "CityId": "VCDA",
                                    "CountryId": "AU",
                                    "Location": "131, -16.4"
                                }
                            ]
                        },
                        {
                            "Id": "GLGA",
                            "Name": "Glengyle",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.6, -24.816667",
                            "IataCode": "GLG",
                            "Airports": [
                                {
                                    "Id": "GLG",
                                    "Name": "Glengyle",
                                    "CityId": "GLGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.6, -24.816667"
                                }
                            ]
                        },
                        {
                            "Id": "AGWA",
                            "Name": "Agnew",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.15, -12.15",
                            "IataCode": "AGW",
                            "Airports": [
                                {
                                    "Id": "AGW",
                                    "Name": "Agnew",
                                    "CityId": "AGWA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.15, -12.15"
                                }
                            ]
                        },
                        {
                            "Id": "AMXA",
                            "Name": "Ammaroo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.24, -21.74",
                            "IataCode": "AMX",
                            "Airports": [
                                {
                                    "Id": "AMX",
                                    "Name": "Ammaroo",
                                    "CityId": "AMXA",
                                    "CountryId": "AU",
                                    "Location": "135.24, -21.74"
                                }
                            ]
                        },
                        {
                            "Id": "AUDA",
                            "Name": "Augustus Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.88167, -18.506944",
                            "IataCode": "AUD",
                            "Airports": [
                                {
                                    "Id": "AUD",
                                    "Name": "Augustus Downs",
                                    "CityId": "AUDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.88167, -18.506944"
                                }
                            ]
                        },
                        {
                            "Id": "AYDA",
                            "Name": "Alroy Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.95, -19.3",
                            "IataCode": "AYD",
                            "Airports": [
                                {
                                    "Id": "AYD",
                                    "Name": "Alroy Downs",
                                    "CityId": "AYDA",
                                    "CountryId": "AU",
                                    "Location": "135.95, -19.3"
                                }
                            ]
                        },
                        {
                            "Id": "AYLA",
                            "Name": "Anthony Lagoon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.53333, -18.033333",
                            "IataCode": "AYL",
                            "Airports": [
                                {
                                    "Id": "AYL",
                                    "Name": "Anthony Lagoon",
                                    "CityId": "AYLA",
                                    "CountryId": "AU",
                                    "Location": "135.53333, -18.033333"
                                }
                            ]
                        },
                        {
                            "Id": "AWNA",
                            "Name": "Alton Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.66667, -26.25",
                            "IataCode": "AWN",
                            "Airports": [
                                {
                                    "Id": "AWN",
                                    "Name": "Alton Downs",
                                    "CityId": "AWNA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.66667, -26.25"
                                }
                            ]
                        },
                        {
                            "Id": "BCZA",
                            "Name": "Bickerton Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "137.8, -13.783333",
                            "IataCode": "BCZ",
                            "Airports": [
                                {
                                    "Id": "BCZ",
                                    "Name": "Bickerton Island",
                                    "CityId": "BCZA",
                                    "CountryId": "AU",
                                    "Location": "137.8, -13.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BDWA",
                            "Name": "Bedford Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.46667, -17.3",
                            "IataCode": "BDW",
                            "Airports": [
                                {
                                    "Id": "BDW",
                                    "Name": "Bedford Downs",
                                    "CityId": "BDWA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.46667, -17.3"
                                }
                            ]
                        },
                        {
                            "Id": "AVGA",
                            "Name": "Auvergne",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.01667, -15.65",
                            "IataCode": "AVG",
                            "Airports": [
                                {
                                    "Id": "AVG",
                                    "Name": "Auvergne",
                                    "CityId": "AVGA",
                                    "CountryId": "AU",
                                    "Location": "130.01667, -15.65"
                                }
                            ]
                        },
                        {
                            "Id": "BEEA",
                            "Name": "Beagle Bay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "122.66667, -16.966667",
                            "IataCode": "BEE",
                            "Airports": [
                                {
                                    "Id": "BEE",
                                    "Name": "Beagle Bay",
                                    "CityId": "BEEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "122.66667, -16.966667"
                                }
                            ]
                        },
                        {
                            "Id": "BIWA",
                            "Name": "Billiluna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.16667, -19.566667",
                            "IataCode": "BIW",
                            "Airports": [
                                {
                                    "Id": "BIW",
                                    "Name": "Billiluna",
                                    "CityId": "BIWA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.16667, -19.566667"
                                }
                            ]
                        },
                        {
                            "Id": "BKPA",
                            "Name": "Barkly Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.5, -20.5",
                            "IataCode": "BKP",
                            "Airports": [
                                {
                                    "Id": "BKP",
                                    "Name": "Barkly Downs",
                                    "CityId": "BKPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.5, -20.5"
                                }
                            ]
                        },
                        {
                            "Id": "BVZA",
                            "Name": "Beverley Springs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "124, -15",
                            "IataCode": "BVZ",
                            "Airports": [
                                {
                                    "Id": "BVZ",
                                    "Name": "Beverley Springs",
                                    "CityId": "BVZA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "124, -15"
                                }
                            ]
                        },
                        {
                            "Id": "BXFA",
                            "Name": "Belburn",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.30778, -17.545556",
                            "IataCode": "BXF",
                            "Airports": [
                                {
                                    "Id": "BXF",
                                    "Name": "Belburn",
                                    "CityId": "BXFA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.30778, -17.545556"
                                }
                            ]
                        },
                        {
                            "Id": "CBCA",
                            "Name": "Cherrabun",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "125.55, -18.916667",
                            "IataCode": "CBC",
                            "Airports": [
                                {
                                    "Id": "CBC",
                                    "Name": "Cherrabun",
                                    "CityId": "CBCA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.55, -18.916667"
                                }
                            ]
                        },
                        {
                            "Id": "CBYA",
                            "Name": "Canobie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.92611, -19.478611",
                            "IataCode": "CBY",
                            "Airports": [
                                {
                                    "Id": "CBY",
                                    "Name": "Canobie",
                                    "CityId": "CBYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.92611, -19.478611"
                                }
                            ]
                        },
                        {
                            "Id": "CFIA",
                            "Name": "Camfield",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.28333, -17.033333",
                            "IataCode": "CFI",
                            "Airports": [
                                {
                                    "Id": "CFI",
                                    "Name": "Camfield",
                                    "CityId": "CFIA",
                                    "CountryId": "AU",
                                    "Location": "131.28333, -17.033333"
                                }
                            ]
                        },
                        {
                            "Id": "CFHA",
                            "Name": "Clifton Hills",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "139, -27",
                            "IataCode": "CFH",
                            "Airports": [
                                {
                                    "Id": "CFH",
                                    "Name": "Clifton Hills",
                                    "CityId": "CFHA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "139, -27"
                                }
                            ]
                        },
                        {
                            "Id": "COBA",
                            "Name": "Coolibah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.93333, -15.55",
                            "IataCode": "COB",
                            "Airports": [
                                {
                                    "Id": "COB",
                                    "Name": "Coolibah",
                                    "CityId": "COBA",
                                    "CountryId": "AU",
                                    "Location": "130.93333, -15.55"
                                }
                            ]
                        },
                        {
                            "Id": "CQPA",
                            "Name": "Cape Flattery",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.5, -18.2",
                            "IataCode": "CQP",
                            "Airports": [
                                {
                                    "Id": "CQP",
                                    "Name": "Cape Flattery",
                                    "CityId": "CQPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.5, -18.2"
                                }
                            ]
                        },
                        {
                            "Id": "CSDA",
                            "Name": "Cresswell Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.91667, -17.966667",
                            "IataCode": "CSD",
                            "Airports": [
                                {
                                    "Id": "CSD",
                                    "Name": "Cresswell Downs",
                                    "CityId": "CSDA",
                                    "CountryId": "AU",
                                    "Location": "135.91667, -17.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CRYA",
                            "Name": "Carlton Hill",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.61667, -15.466667",
                            "IataCode": "CRY",
                            "Airports": [
                                {
                                    "Id": "CRY",
                                    "Name": "Carlton Hill",
                                    "CityId": "CRYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.61667, -15.466667"
                                }
                            ]
                        },
                        {
                            "Id": "CTRA",
                            "Name": "Cattle Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131, -17.583333",
                            "IataCode": "CTR",
                            "Airports": [
                                {
                                    "Id": "CTR",
                                    "Name": "Cattle Creek",
                                    "CityId": "CTRA",
                                    "CountryId": "AU",
                                    "Location": "131, -17.583333"
                                }
                            ]
                        },
                        {
                            "Id": "DDNA",
                            "Name": "Delta Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.3, -16.916667",
                            "IataCode": "DDN",
                            "Airports": [
                                {
                                    "Id": "DDN",
                                    "Name": "Delta Downs",
                                    "CityId": "DDNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.3, -16.916667"
                                }
                            ]
                        },
                        {
                            "Id": "DFPA",
                            "Name": "Drumduff",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.33333, -16",
                            "IataCode": "DFP",
                            "Airports": [
                                {
                                    "Id": "DFP",
                                    "Name": "Drumduff",
                                    "CityId": "DFPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.33333, -16"
                                }
                            ]
                        },
                        {
                            "Id": "CZYA",
                            "Name": "Cluny",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.53333, -24.516667",
                            "IataCode": "CZY",
                            "Airports": [
                                {
                                    "Id": "CZY",
                                    "Name": "Cluny",
                                    "CityId": "CZYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.53333, -24.516667"
                                }
                            ]
                        },
                        {
                            "Id": "DKVA",
                            "Name": "Docker River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.08333, -24.866667",
                            "IataCode": "DKV",
                            "Airports": [
                                {
                                    "Id": "DKV",
                                    "Name": "Docker River",
                                    "CityId": "DKVA",
                                    "CountryId": "AU",
                                    "Location": "129.08333, -24.866667"
                                }
                            ]
                        },
                        {
                            "Id": "DNBA",
                            "Name": "Dunbar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.39056, -16.010833",
                            "IataCode": "DNB",
                            "Airports": [
                                {
                                    "Id": "DNB",
                                    "Name": "Dunbar",
                                    "CityId": "DNBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.39999, -16.05"
                                }
                            ]
                        },
                        {
                            "Id": "DRDA",
                            "Name": "Dorunda Station",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.33333, -16.5",
                            "IataCode": "DRD",
                            "Airports": [
                                {
                                    "Id": "DRD",
                                    "Name": "Dorunda Station",
                                    "CityId": "DRDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.33333, -16.5"
                                }
                            ]
                        },
                        {
                            "Id": "DRYA",
                            "Name": "Drysdale River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.41667, -15.666667",
                            "IataCode": "DRY",
                            "Airports": [
                                {
                                    "Id": "DRY",
                                    "Name": "Drysdale River",
                                    "CityId": "DRYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.41667, -15.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DNGA",
                            "Name": "Doongan",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.3, -15.383333",
                            "IataCode": "DNG",
                            "Airports": [
                                {
                                    "Id": "DNG",
                                    "Name": "Doongan",
                                    "CityId": "DNGA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.3, -15.383333"
                                }
                            ]
                        },
                        {
                            "Id": "DVPA",
                            "Name": "Davenport Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.11667, -24.133333",
                            "IataCode": "DVP",
                            "Airports": [
                                {
                                    "Id": "DVP",
                                    "Name": "Davenport Downs",
                                    "CityId": "DVPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.11667, -24.133333"
                                }
                            ]
                        },
                        {
                            "Id": "DYMA",
                            "Name": "Diamantina Lakes",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "139.16667, -26.75",
                            "IataCode": "DYM",
                            "Airports": [
                                {
                                    "Id": "DYM",
                                    "Name": "Diamantina Lakes",
                                    "CityId": "DYMA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "139.16667, -26.75"
                                }
                            ]
                        },
                        {
                            "Id": "DRRA",
                            "Name": "Durrie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.21667, -25.616667",
                            "IataCode": "DRR",
                            "Airports": [
                                {
                                    "Id": "DRR",
                                    "Name": "Durrie",
                                    "CityId": "DRRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.21667, -25.616667"
                                }
                            ]
                        },
                        {
                            "Id": "ERBA",
                            "Name": "Ernabella",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "132.11667, -26.283333",
                            "IataCode": "ERB",
                            "Airports": [
                                {
                                    "Id": "ERB",
                                    "Name": "Ernabella",
                                    "CityId": "ERBA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "132.11667, -26.283333"
                                }
                            ]
                        },
                        {
                            "Id": "EVDA",
                            "Name": "Eva Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.86667, -18.016667",
                            "IataCode": "EVD",
                            "Airports": [
                                {
                                    "Id": "EVD",
                                    "Name": "Eva Downs",
                                    "CityId": "EVDA",
                                    "CountryId": "AU",
                                    "Location": "134.86667, -18.016667"
                                }
                            ]
                        },
                        {
                            "Id": "FSLA",
                            "Name": "Fossil Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "125.8, -18.2",
                            "IataCode": "FSL",
                            "Airports": [
                                {
                                    "Id": "FSL",
                                    "Name": "Fossil Downs",
                                    "CityId": "FSLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.8, -18.2"
                                }
                            ]
                        },
                        {
                            "Id": "FVLA",
                            "Name": "Flora Valley",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.41667, -18.283333",
                            "IataCode": "FVL",
                            "Airports": [
                                {
                                    "Id": "FVL",
                                    "Name": "Flora Valley",
                                    "CityId": "FVLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.41667, -18.283333"
                                }
                            ]
                        },
                        {
                            "Id": "FVRA",
                            "Name": "Forrest River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.83333, -15.253056",
                            "IataCode": "FVR",
                            "Airports": [
                                {
                                    "Id": "FVR",
                                    "Name": "Forrest River",
                                    "CityId": "FVRA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.83333, -15.253056"
                                }
                            ]
                        },
                        {
                            "Id": "GBPA",
                            "Name": "Gamboola",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.66667, -16.55",
                            "IataCode": "GBP",
                            "Airports": [
                                {
                                    "Id": "GBP",
                                    "Name": "Gamboola",
                                    "CityId": "GBPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.66667, -16.55"
                                }
                            ]
                        },
                        {
                            "Id": "GBVA",
                            "Name": "Gibb River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.63333, -15.65",
                            "IataCode": "GBV",
                            "Airports": [
                                {
                                    "Id": "GBV",
                                    "Name": "Gibb River",
                                    "CityId": "GBVA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.63333, -15.65"
                                }
                            ]
                        },
                        {
                            "Id": "GDDA",
                            "Name": "Gordon Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.55, -18.716667",
                            "IataCode": "GDD",
                            "Airports": [
                                {
                                    "Id": "GDD",
                                    "Name": "Gordon Downs",
                                    "CityId": "GDDA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.55, -18.716667"
                                }
                            ]
                        },
                        {
                            "Id": "GLMA",
                            "Name": "Glenormiston",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.83333, -22.916667",
                            "IataCode": "GLM",
                            "Airports": [
                                {
                                    "Id": "GLM",
                                    "Name": "Glenormiston",
                                    "CityId": "GLMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.83333, -22.916667"
                                }
                            ]
                        },
                        {
                            "Id": "HIGA",
                            "Name": "Highbury",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.15, -16.416667",
                            "IataCode": "HIG",
                            "Airports": [
                                {
                                    "Id": "HIG",
                                    "Name": "Highbury",
                                    "CityId": "HIGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.15, -16.416667"
                                }
                            ]
                        },
                        {
                            "Id": "HOKA",
                            "Name": "Hooker Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.6325, -18.335",
                            "IataCode": "HOK",
                            "Airports": [
                                {
                                    "Id": "HOK",
                                    "Name": "Hooker Creek",
                                    "CityId": "HOKA",
                                    "CountryId": "AU",
                                    "Location": "130.6325, -18.335"
                                }
                            ]
                        },
                        {
                            "Id": "HPEA",
                            "Name": "Hope Vale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.91667, -14.666667",
                            "IataCode": "HPE",
                            "Airports": [
                                {
                                    "Id": "HPE",
                                    "Name": "Hope Vale",
                                    "CityId": "HPEA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.91667, -14.666667"
                                }
                            ]
                        },
                        {
                            "Id": "IDKA",
                            "Name": "Indulkana",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "133.325, -26.966667",
                            "IataCode": "IDK",
                            "Airports": [
                                {
                                    "Id": "IDK",
                                    "Name": "Indulkana",
                                    "CityId": "IDKA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "133.325, -26.966667"
                                }
                            ]
                        },
                        {
                            "Id": "IVWA",
                            "Name": "Inverway",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.63333, -17.833333",
                            "IataCode": "IVW",
                            "Airports": [
                                {
                                    "Id": "IVW",
                                    "Name": "Inverway",
                                    "CityId": "IVWA",
                                    "CountryId": "AU",
                                    "Location": "129.63333, -17.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KBBA",
                            "Name": "Kirkimbie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.21667, -17.766667",
                            "IataCode": "KBB",
                            "Airports": [
                                {
                                    "Id": "KBB",
                                    "Name": "Kirkimbie",
                                    "CityId": "KBBA",
                                    "CountryId": "AU",
                                    "Location": "129.21667, -17.766667"
                                }
                            ]
                        },
                        {
                            "Id": "KDSA",
                            "Name": "Kamaran Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.16667, -24.333333",
                            "IataCode": "KDS",
                            "Airports": [
                                {
                                    "Id": "KDS",
                                    "Name": "Kamaran Downs",
                                    "CityId": "KDSA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.16667, -24.333333"
                                }
                            ]
                        },
                        {
                            "Id": "KFGA",
                            "Name": "Kalkurung",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.81667, -17.433333",
                            "IataCode": "KFG",
                            "Airports": [
                                {
                                    "Id": "KFG",
                                    "Name": "Kalkurung",
                                    "CityId": "KFGA",
                                    "CountryId": "AU",
                                    "Location": "130.81667, -17.433333"
                                }
                            ]
                        },
                        {
                            "Id": "KGRA",
                            "Name": "Kulgera",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.03333, -25.833333",
                            "IataCode": "KGR",
                            "Airports": [
                                {
                                    "Id": "KGR",
                                    "Name": "Kulgera",
                                    "CityId": "KGRA",
                                    "CountryId": "AU",
                                    "Location": "133.03333, -25.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KKPA",
                            "Name": "Koolburra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.95, -15.583333",
                            "IataCode": "KKP",
                            "Airports": [
                                {
                                    "Id": "KKP",
                                    "Name": "Koolburra",
                                    "CityId": "KKPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.95, -15.583333"
                                }
                            ]
                        },
                        {
                            "Id": "KRDA",
                            "Name": "Kurundi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.66667, -20.516667",
                            "IataCode": "KRD",
                            "Airports": [
                                {
                                    "Id": "KRD",
                                    "Name": "Kurundi",
                                    "CityId": "KRDA",
                                    "CountryId": "AU",
                                    "Location": "134.66667, -20.516667"
                                }
                            ]
                        },
                        {
                            "Id": "KSVA",
                            "Name": "Springvale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.68333, -23.55",
                            "IataCode": "KSV",
                            "Airports": [
                                {
                                    "Id": "KSV",
                                    "Name": "Springvale",
                                    "CityId": "KSVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.68333, -23.55"
                                }
                            ]
                        },
                        {
                            "Id": "LGEA",
                            "Name": "Lake Gregory",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.56667, -20.133333",
                            "IataCode": "LGE",
                            "Airports": [
                                {
                                    "Id": "LGE",
                                    "Name": "Lake Gregory",
                                    "CityId": "LGEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.56667, -20.133333"
                                }
                            ]
                        },
                        {
                            "Id": "LDWA",
                            "Name": "Lansdowne",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.65, -17.883333",
                            "IataCode": "LDW",
                            "Airports": [
                                {
                                    "Id": "LDW",
                                    "Name": "Lansdowne",
                                    "CityId": "LDWA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.65, -17.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LIBA",
                            "Name": "Limbunya",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.8, -17.2",
                            "IataCode": "LIB",
                            "Airports": [
                                {
                                    "Id": "LIB",
                                    "Name": "Limbunya",
                                    "CityId": "LIBA",
                                    "CountryId": "AU",
                                    "Location": "129.8, -17.2"
                                }
                            ]
                        },
                        {
                            "Id": "LLLA",
                            "Name": "Lissadell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.63333, -16.566667",
                            "IataCode": "LLL",
                            "Airports": [
                                {
                                    "Id": "LLL",
                                    "Name": "Lissadell",
                                    "CityId": "LLLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.63333, -16.566667"
                                }
                            ]
                        },
                        {
                            "Id": "LLPA",
                            "Name": "Linda Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.7, -23.166667",
                            "IataCode": "LLP",
                            "Airports": [
                                {
                                    "Id": "LLP",
                                    "Name": "Linda Downs",
                                    "CityId": "LLPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.7, -23.166667"
                                }
                            ]
                        },
                        {
                            "Id": "LNHA",
                            "Name": "Lake Nash",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138, -20.95",
                            "IataCode": "LNH",
                            "Airports": [
                                {
                                    "Id": "LNH",
                                    "Name": "Lake Nash",
                                    "CityId": "LNHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138, -20.95"
                                }
                            ]
                        },
                        {
                            "Id": "LTVA",
                            "Name": "Lotusvale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.38333, -17.05",
                            "IataCode": "LTV",
                            "Airports": [
                                {
                                    "Id": "LTV",
                                    "Name": "Lotusvale",
                                    "CityId": "LTVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.38333, -17.05"
                                }
                            ]
                        },
                        {
                            "Id": "MBNA",
                            "Name": "Mt Barnett",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "125, -15",
                            "IataCode": "MBN",
                            "Airports": [
                                {
                                    "Id": "MBN",
                                    "Name": "Mt Barnett",
                                    "CityId": "MBNA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125, -15"
                                }
                            ]
                        },
                        {
                            "Id": "LWHA",
                            "Name": "Lawn Hill",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.58333, -18.583333",
                            "IataCode": "LWH",
                            "Airports": [
                                {
                                    "Id": "LWH",
                                    "Name": "Lawn Hill",
                                    "CityId": "LWHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.58333, -18.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MFPA",
                            "Name": "Manners Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "137.98333, -22.1",
                            "IataCode": "MFP",
                            "Airports": [
                                {
                                    "Id": "MFP",
                                    "Name": "Manners Creek",
                                    "CityId": "MFPA",
                                    "CountryId": "AU",
                                    "Location": "137.98333, -22.1"
                                }
                            ]
                        },
                        {
                            "Id": "MHOA",
                            "Name": "Mount House",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "125.76667, -17.133333",
                            "IataCode": "MHO",
                            "Airports": [
                                {
                                    "Id": "MHO",
                                    "Name": "Mount House",
                                    "CityId": "MHOA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.76667, -17.133333"
                                }
                            ]
                        },
                        {
                            "Id": "MIYA",
                            "Name": "Mittiebah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.267, -18.5",
                            "IataCode": "MIY",
                            "Airports": [
                                {
                                    "Id": "MIY",
                                    "Name": "Mittiebah",
                                    "CityId": "MIYA",
                                    "CountryId": "AU",
                                    "Location": "136.267, -18.5"
                                }
                            ]
                        },
                        {
                            "Id": "MGVA",
                            "Name": "Margaret River Station",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.86667, -18.616667",
                            "IataCode": "MGV",
                            "Airports": [
                                {
                                    "Id": "MGV",
                                    "Name": "Margaret River Station",
                                    "CityId": "MGVA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.86667, -18.616667"
                                }
                            ]
                        },
                        {
                            "Id": "MKVA",
                            "Name": "Mt Cavenagh",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.25, -25.966667",
                            "IataCode": "MKV",
                            "Airports": [
                                {
                                    "Id": "MKV",
                                    "Name": "Mt Cavenagh",
                                    "CityId": "MKVA",
                                    "CountryId": "AU",
                                    "Location": "133.25, -25.966667"
                                }
                            ]
                        },
                        {
                            "Id": "META",
                            "Name": "Moreton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.76667, -12.75",
                            "IataCode": "MET",
                            "Airports": [
                                {
                                    "Id": "MET",
                                    "Name": "Moreton",
                                    "CityId": "META",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.638, -12.4442"
                                }
                            ]
                        },
                        {
                            "Id": "MNWA",
                            "Name": "Macdonald Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.21667, -22.45",
                            "IataCode": "MNW",
                            "Airports": [
                                {
                                    "Id": "MNW",
                                    "Name": "Macdonald Downs",
                                    "CityId": "MNWA",
                                    "CountryId": "AU",
                                    "Location": "135.21667, -22.45"
                                }
                            ]
                        },
                        {
                            "Id": "MQEA",
                            "Name": "Marqua",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "137.35, -22.816667",
                            "IataCode": "MQE",
                            "Airports": [
                                {
                                    "Id": "MQE",
                                    "Name": "Marqua",
                                    "CityId": "MQEA",
                                    "CountryId": "AU",
                                    "Location": "137.35, -22.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MNVA",
                            "Name": "Mountain Valley",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.83333, -14.116667",
                            "IataCode": "MNV",
                            "Airports": [
                                {
                                    "Id": "MNV",
                                    "Name": "Mountain Valley",
                                    "CityId": "MNVA",
                                    "CountryId": "AU",
                                    "Location": "133.83333, -14.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MTDA",
                            "Name": "Mt Sandford",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.56667, -17",
                            "IataCode": "MTD",
                            "Airports": [
                                {
                                    "Id": "MTD",
                                    "Name": "Mt Sandford",
                                    "CityId": "MTDA",
                                    "CountryId": "AU",
                                    "Location": "130.56667, -17"
                                }
                            ]
                        },
                        {
                            "Id": "MRPA",
                            "Name": "Marla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135, -27",
                            "IataCode": "MRP",
                            "Airports": [
                                {
                                    "Id": "MRP",
                                    "Name": "Marla",
                                    "CityId": "MRPA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "135, -27"
                                }
                            ]
                        },
                        {
                            "Id": "MWYA",
                            "Name": "Miranda Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.51667, -25.783333",
                            "IataCode": "MWY",
                            "Airports": [
                                {
                                    "Id": "MWY",
                                    "Name": "Miranda Downs",
                                    "CityId": "MWYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.51667, -25.783333"
                                }
                            ]
                        },
                        {
                            "Id": "MXDA",
                            "Name": "Marion Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.6, -23.333333",
                            "IataCode": "MXD",
                            "Airports": [
                                {
                                    "Id": "MXD",
                                    "Name": "Marion Downs",
                                    "CityId": "MXDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.6, -23.333333"
                                }
                            ]
                        },
                        {
                            "Id": "NKBA",
                            "Name": "Noonkanbah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "124.85, -18.55",
                            "IataCode": "NKB",
                            "Airports": [
                                {
                                    "Id": "NKB",
                                    "Name": "Noonkanbah",
                                    "CityId": "NKBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "124.85, -18.55"
                                }
                            ]
                        },
                        {
                            "Id": "NLSA",
                            "Name": "Nicholson",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.89583, -18.050833",
                            "IataCode": "NLS",
                            "Airports": [
                                {
                                    "Id": "NLS",
                                    "Name": "Nicholson",
                                    "CityId": "NLSA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.89583, -18.050833"
                                }
                            ]
                        },
                        {
                            "Id": "NRYA",
                            "Name": "Newry",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "129.23333, -16.016667",
                            "IataCode": "NRY",
                            "Airports": [
                                {
                                    "Id": "NRY",
                                    "Name": "Newry",
                                    "CityId": "NRYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "129.23333, -16.016667"
                                }
                            ]
                        },
                        {
                            "Id": "ODLA",
                            "Name": "Cordillo Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.62637, -26.708813",
                            "IataCode": "ODL",
                            "Airports": [
                                {
                                    "Id": "ODL",
                                    "Name": "Cordillo Downs",
                                    "CityId": "ODLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.63934, -26.748954"
                                }
                            ]
                        },
                        {
                            "Id": "ONRA",
                            "Name": "Monkira",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.56667, -24.816667",
                            "IataCode": "ONR",
                            "Airports": [
                                {
                                    "Id": "ONR",
                                    "Name": "Monkira",
                                    "CityId": "ONRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.56667, -24.816667"
                                }
                            ]
                        },
                        {
                            "Id": "OORA",
                            "Name": "Mooraberree",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.23333, -25.05",
                            "IataCode": "OOR",
                            "Airports": [
                                {
                                    "Id": "OOR",
                                    "Name": "Mooraberree",
                                    "CityId": "OORA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.23333, -25.05"
                                }
                            ]
                        },
                        {
                            "Id": "OPIA",
                            "Name": "Oenpelli",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.06667, -12.333333",
                            "IataCode": "OPI",
                            "Airports": [
                                {
                                    "Id": "OPI",
                                    "Name": "Oenpelli",
                                    "CityId": "OPIA",
                                    "CountryId": "AU",
                                    "Location": "133.06667, -12.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OXOA",
                            "Name": "Orientos",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.33333, -27.666667",
                            "IataCode": "OXO",
                            "Airports": [
                                {
                                    "Id": "OXO",
                                    "Name": "Orientos",
                                    "CityId": "OXOA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.33333, -27.666667"
                                }
                            ]
                        },
                        {
                            "Id": "OXYA",
                            "Name": "Morney",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.36667, -25.35",
                            "IataCode": "OXY",
                            "Airports": [
                                {
                                    "Id": "OXY",
                                    "Name": "Morney",
                                    "CityId": "OXYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.36667, -25.35"
                                }
                            ]
                        },
                        {
                            "Id": "PDEA",
                            "Name": "Pandie Pandie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.66667, -26",
                            "IataCode": "PDE",
                            "Airports": [
                                {
                                    "Id": "PDE",
                                    "Name": "Pandie Pandie",
                                    "CityId": "PDEA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.66667, -26"
                                }
                            ]
                        },
                        {
                            "Id": "PEPA",
                            "Name": "Peppimenarti",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.1, -14.15",
                            "IataCode": "PEP",
                            "Airports": [
                                {
                                    "Id": "PEP",
                                    "Name": "Peppimenarti",
                                    "CityId": "PEPA",
                                    "CountryId": "AU",
                                    "Location": "130.1, -14.15"
                                }
                            ]
                        },
                        {
                            "Id": "RDAA",
                            "Name": "Rockhampton Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.16667, -18.95",
                            "IataCode": "RDA",
                            "Airports": [
                                {
                                    "Id": "RDA",
                                    "Name": "Rockhampton Downs",
                                    "CityId": "RDAA",
                                    "CountryId": "AU",
                                    "Location": "135.16667, -18.95"
                                }
                            ]
                        },
                        {
                            "Id": "RKYA",
                            "Name": "Rokeby",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.43333, -13.166667",
                            "IataCode": "RKY",
                            "Airports": [
                                {
                                    "Id": "RKY",
                                    "Name": "Rokeby",
                                    "CityId": "RKYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.43333, -13.166667"
                                }
                            ]
                        },
                        {
                            "Id": "RPMA",
                            "Name": "Ngukurr",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134, -14.933333",
                            "IataCode": "RPM",
                            "Airports": [
                                {
                                    "Id": "RPM",
                                    "Name": "Ngukurr",
                                    "CityId": "RPMA",
                                    "CountryId": "AU",
                                    "Location": "134, -14.933333"
                                }
                            ]
                        },
                        {
                            "Id": "RPVA",
                            "Name": "Roper Valley",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.03333, -14.966667",
                            "IataCode": "RPV",
                            "Airports": [
                                {
                                    "Id": "RPV",
                                    "Name": "Roper Valley",
                                    "CityId": "RPVA",
                                    "CountryId": "AU",
                                    "Location": "134.03333, -14.966667"
                                }
                            ]
                        },
                        {
                            "Id": "RRVA",
                            "Name": "Robinson River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.96667, -16.75",
                            "IataCode": "RRV",
                            "Airports": [
                                {
                                    "Id": "RRV",
                                    "Name": "Robinson River",
                                    "CityId": "RRVA",
                                    "CountryId": "AU",
                                    "Location": "136.96667, -16.75"
                                }
                            ]
                        },
                        {
                            "Id": "RSBA",
                            "Name": "Roseberth",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.63333, -25.833333",
                            "IataCode": "RSB",
                            "Airports": [
                                {
                                    "Id": "RSB",
                                    "Name": "Roseberth",
                                    "CityId": "RSBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.63333, -25.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RTPA",
                            "Name": "Rutland Plains",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.83333, -15.6",
                            "IataCode": "RTP",
                            "Airports": [
                                {
                                    "Id": "RTP",
                                    "Name": "Rutland Plains",
                                    "CityId": "RTPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.83333, -15.6"
                                }
                            ]
                        },
                        {
                            "Id": "SHUA",
                            "Name": "Smith Point",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.13333, -11.133333",
                            "IataCode": "SHU",
                            "Airports": [
                                {
                                    "Id": "SHU",
                                    "Name": "Smith Point",
                                    "CityId": "SHUA",
                                    "CountryId": "AU",
                                    "Location": "132.13333, -11.133333"
                                }
                            ]
                        },
                        {
                            "Id": "SRMA",
                            "Name": "Sandringham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.06667, -24.05",
                            "IataCode": "SRM",
                            "Airports": [
                                {
                                    "Id": "SRM",
                                    "Name": "Sandringham",
                                    "CityId": "SRMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.06667, -24.05"
                                }
                            ]
                        },
                        {
                            "Id": "TBKA",
                            "Name": "Timber Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.48333, -15.65",
                            "IataCode": "TBK",
                            "Airports": [
                                {
                                    "Id": "TBK",
                                    "Name": "Timber Creek",
                                    "CityId": "TBKA",
                                    "CountryId": "AU",
                                    "Location": "130.48333, -15.65"
                                }
                            ]
                        },
                        {
                            "Id": "TBLA",
                            "Name": "Tableland",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.83333, -17.3",
                            "IataCode": "TBL",
                            "Airports": [
                                {
                                    "Id": "TBL",
                                    "Name": "Tableland",
                                    "CityId": "TBLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.83333, -17.3"
                                }
                            ]
                        },
                        {
                            "Id": "TEFA",
                            "Name": "Telfer",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "122.21222, -21.713056",
                            "IataCode": "TEF",
                            "Airports": [
                                {
                                    "Id": "TEF",
                                    "Name": "Telfer",
                                    "CityId": "TEFA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "122.21222, -21.713056"
                                }
                            ]
                        },
                        {
                            "Id": "TYPA",
                            "Name": "Tobermorey",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "137.96667, -22.283333",
                            "IataCode": "TYP",
                            "Airports": [
                                {
                                    "Id": "TYP",
                                    "Name": "Tobermorey",
                                    "CityId": "TYPA",
                                    "CountryId": "AU",
                                    "Location": "137.96667, -22.283333"
                                }
                            ]
                        },
                        {
                            "Id": "UBUA",
                            "Name": "Kalumburu",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126.65, -14.3",
                            "IataCode": "UBU",
                            "Airports": [
                                {
                                    "Id": "UBU",
                                    "Name": "Kalumburu",
                                    "CityId": "UBUA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "126.65, -14.3"
                                }
                            ]
                        },
                        {
                            "Id": "TXRA",
                            "Name": "Tanbar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.91667, -25.85",
                            "IataCode": "TXR",
                            "Airports": [
                                {
                                    "Id": "TXR",
                                    "Name": "Tanbar",
                                    "CityId": "TXRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.91667, -25.85"
                                }
                            ]
                        },
                        {
                            "Id": "USLA",
                            "Name": "Useless Loop",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "113.11667, -25.966667",
                            "IataCode": "USL",
                            "Airports": [
                                {
                                    "Id": "USL",
                                    "Name": "Useless Loop",
                                    "CityId": "USLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "113.11667, -25.966667"
                                }
                            ]
                        },
                        {
                            "Id": "VNRA",
                            "Name": "Vanrook",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.91667, -16.866667",
                            "IataCode": "VNR",
                            "Airports": [
                                {
                                    "Id": "VNR",
                                    "Name": "Vanrook",
                                    "CityId": "VNRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.91667, -16.866667"
                                }
                            ]
                        },
                        {
                            "Id": "WAVA",
                            "Name": "Wave Hill",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.95, -17.483333",
                            "IataCode": "WAV",
                            "Airports": [
                                {
                                    "Id": "WAV",
                                    "Name": "Wave Hill",
                                    "CityId": "WAVA",
                                    "CountryId": "AU",
                                    "Location": "130.95, -17.483333"
                                }
                            ]
                        },
                        {
                            "Id": "WONA",
                            "Name": "Wondoola",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.9, -18.583333",
                            "IataCode": "WON",
                            "Airports": [
                                {
                                    "Id": "WON",
                                    "Name": "Wondoola",
                                    "CityId": "WONA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.9, -18.583333"
                                }
                            ]
                        },
                        {
                            "Id": "WLAA",
                            "Name": "Wallal",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.66667, -19.783333",
                            "IataCode": "WLA",
                            "Airports": [
                                {
                                    "Id": "WLA",
                                    "Name": "Wallal",
                                    "CityId": "WLAA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.66667, -19.783333"
                                }
                            ]
                        },
                        {
                            "Id": "WLLA",
                            "Name": "Wollogorang",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "137.91667, -17.616667",
                            "IataCode": "WLL",
                            "Airports": [
                                {
                                    "Id": "WLL",
                                    "Name": "Wollogorang",
                                    "CityId": "WLLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "137.91667, -17.616667"
                                }
                            ]
                        },
                        {
                            "Id": "WLOA",
                            "Name": "Waterloo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.31667, -16.633333",
                            "IataCode": "WLO",
                            "Airports": [
                                {
                                    "Id": "WLO",
                                    "Name": "Waterloo",
                                    "CityId": "WLOA",
                                    "CountryId": "AU",
                                    "Location": "129.31667, -16.633333"
                                }
                            ]
                        },
                        {
                            "Id": "AABA",
                            "Name": "Arrabury",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.04167, -26.7",
                            "IataCode": "AAB",
                            "Airports": [
                                {
                                    "Id": "AAB",
                                    "Name": "Arrabury",
                                    "CityId": "AABA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.04167, -26.7"
                                }
                            ]
                        },
                        {
                            "Id": "LUTA",
                            "Name": "Laura Station",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.44507, -15.560082",
                            "IataCode": "LUT",
                            "Airports": [
                                {
                                    "Id": "LUT",
                                    "Name": "Laura Station",
                                    "CityId": "LUTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.81667, -15.433333"
                                }
                            ]
                        },
                        {
                            "Id": "YNNA",
                            "Name": "Yandicoogina",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.23333, -22.766667",
                            "IataCode": "YNN",
                            "Airports": [
                                {
                                    "Id": "YNN",
                                    "Name": "Yandicoogina",
                                    "CityId": "YNNA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.23333, -22.766667"
                                }
                            ]
                        },
                        {
                            "Id": "BRJA",
                            "Name": "Bright",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "146.96667, -36.75",
                            "IataCode": "BRJ",
                            "Airports": [
                                {
                                    "Id": "BRJ",
                                    "Name": "Bright",
                                    "CityId": "BRJA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "146.93333, -36.7"
                                }
                            ]
                        },
                        {
                            "Id": "LTBA",
                            "Name": "Latrobe",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "146.41667, -41.233333",
                            "IataCode": "LTB",
                            "Airports": [
                                {
                                    "Id": "LTB",
                                    "Name": "Latrobe",
                                    "CityId": "LTBA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "146.47, -38.2072"
                                }
                            ]
                        },
                        {
                            "Id": "WEIA",
                            "Name": "Weipa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.86667, -12.666667",
                            "IataCode": "WEI",
                            "Airports": [
                                {
                                    "Id": "WEI",
                                    "Name": "Weipa",
                                    "CityId": "WEIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.88333, -12.683611"
                                }
                            ]
                        },
                        {
                            "Id": "WGAA",
                            "Name": "Wagga Wagga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.36667, -35.116667",
                            "IataCode": "WGA",
                            "Airports": [
                                {
                                    "Id": "WGA",
                                    "Name": "Wagga Wagga",
                                    "CityId": "WGAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "147.46444, -35.166667"
                                }
                            ]
                        },
                        {
                            "Id": "WOLA",
                            "Name": "Wollongong",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.88333, -34.433335",
                            "IataCode": "WOL",
                            "Airports": [
                                {
                                    "Id": "WOL",
                                    "Name": "Wollongong",
                                    "CityId": "WOLA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.78333, -34.566667"
                                }
                            ]
                        },
                        {
                            "Id": "WWYA",
                            "Name": "West Wyalong",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.21667, -33.916667",
                            "IataCode": "WWY",
                            "Airports": [
                                {
                                    "Id": "WWY",
                                    "Name": "West Wyalong",
                                    "CityId": "WWYA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "147.25, -33.933333"
                                }
                            ]
                        },
                        {
                            "Id": "XTGA",
                            "Name": "Thargomindah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.81667, -28",
                            "IataCode": "XTG",
                            "Airports": [
                                {
                                    "Id": "XTG",
                                    "Name": "Thargomindah",
                                    "CityId": "XTGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.76667, -27.966667"
                                }
                            ]
                        },
                        {
                            "Id": "XTRA",
                            "Name": "Tara",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.46667, -27.283333",
                            "IataCode": "XTR",
                            "Airports": [
                                {
                                    "Id": "XTR",
                                    "Name": "Tara",
                                    "CityId": "XTRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.46667, -27.283333"
                                }
                            ]
                        },
                        {
                            "Id": "ABHA",
                            "Name": "Alpha",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.63333, -23.65",
                            "IataCode": "ABH",
                            "Airports": [
                                {
                                    "Id": "ABH",
                                    "Name": "Alpha",
                                    "CityId": "ABHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.63333, -23.65"
                                }
                            ]
                        },
                        {
                            "Id": "ABMA",
                            "Name": "Bamaga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.38934, -10.891974",
                            "IataCode": "ABM",
                            "Airports": [
                                {
                                    "Id": "ABM",
                                    "Name": "Bamaga",
                                    "CityId": "ABMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.4, -10.866667"
                                }
                            ]
                        },
                        {
                            "Id": "ABXA",
                            "Name": "Albury",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "146.91667, -36.083333",
                            "IataCode": "ABX",
                            "Airports": [
                                {
                                    "Id": "ABX",
                                    "Name": "Albury",
                                    "CityId": "ABXA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "146.97667, -36.068333"
                                }
                            ]
                        },
                        {
                            "Id": "ADLA",
                            "Name": "Adelaide",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.6, -34.933335",
                            "IataCode": "ADL",
                            "Airports": [
                                {
                                    "Id": "ADL",
                                    "Name": "Adelaide",
                                    "CityId": "ADLA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.53472, -34.948333"
                                }
                            ]
                        },
                        {
                            "Id": "ALHA",
                            "Name": "Albany",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.89167, -35.016944",
                            "IataCode": "ALH",
                            "Airports": [
                                {
                                    "Id": "ALH",
                                    "Name": "Albany",
                                    "CityId": "ALHA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.80583, -34.945"
                                }
                            ]
                        },
                        {
                            "Id": "ZNEA",
                            "Name": "Newman",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.73333, -23.366667",
                            "IataCode": "ZNE",
                            "Airports": [
                                {
                                    "Id": "ZNE",
                                    "Name": "Newman",
                                    "CityId": "ZNEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.8, -23.416667"
                                }
                            ]
                        },
                        {
                            "Id": "ADOA",
                            "Name": "Andamooka",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.16564, -30.44808",
                            "IataCode": "ADO",
                            "Airports": [
                                {
                                    "Id": "ADO",
                                    "Name": "Andamooka",
                                    "CityId": "ADOA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.15, -31.016667"
                                }
                            ]
                        },
                        {
                            "Id": "BLSA",
                            "Name": "Bollon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.48333, -28.033333",
                            "IataCode": "BLS",
                            "Airports": [
                                {
                                    "Id": "BLS",
                                    "Name": "Bollon",
                                    "CityId": "BLSA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.48333, -28.05"
                                }
                            ]
                        },
                        {
                            "Id": "BOXA",
                            "Name": "Borroloola",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.28333, -16.066667",
                            "IataCode": "BOX",
                            "Airports": [
                                {
                                    "Id": "BOX",
                                    "Name": "Borroloola",
                                    "CityId": "BOXA",
                                    "CountryId": "AU",
                                    "Location": "136.28333, -16.066667"
                                }
                            ]
                        },
                        {
                            "Id": "BTXA",
                            "Name": "Betoota",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.73333, -25.7",
                            "IataCode": "BTX",
                            "Airports": [
                                {
                                    "Id": "BTX",
                                    "Name": "Betoota",
                                    "CityId": "BTXA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.73333, -25.7"
                                }
                            ]
                        },
                        {
                            "Id": "BQWA",
                            "Name": "Balgo Hills",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.96667, -20.15",
                            "IataCode": "BQW",
                            "Airports": [
                                {
                                    "Id": "BQW",
                                    "Name": "Balgo Hills",
                                    "CityId": "BQWA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.8, -20.183333"
                                }
                            ]
                        },
                        {
                            "Id": "CBXA",
                            "Name": "Condobolin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.15, -33.083333",
                            "IataCode": "CBX",
                            "Airports": [
                                {
                                    "Id": "CBX",
                                    "Name": "Condobolin",
                                    "CityId": "CBXA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.25, -33.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CCWA",
                            "Name": "Cowell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "136.91667, -33.683333",
                            "IataCode": "CCW",
                            "Airports": [
                                {
                                    "Id": "CCW",
                                    "Name": "Cowell",
                                    "CityId": "CCWA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "136.83333, -33.633333"
                                }
                            ]
                        },
                        {
                            "Id": "CLHA",
                            "Name": "Coolah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.7, -31.833333",
                            "IataCode": "CLH",
                            "Airports": [
                                {
                                    "Id": "CLH",
                                    "Name": "Coolah",
                                    "CityId": "CLHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.7, -31.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CMQA",
                            "Name": "Clermont",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.63333, -22.816667",
                            "IataCode": "CMQ",
                            "Airports": [
                                {
                                    "Id": "CMQ",
                                    "Name": "Clermont",
                                    "CityId": "CMQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.5, -22.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CSIA",
                            "Name": "Casino",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153.05, -28.866667",
                            "IataCode": "CSI",
                            "Airports": [
                                {
                                    "Id": "CSI",
                                    "Name": "Casino",
                                    "CityId": "CSIA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.05833, -28.883333"
                                }
                            ]
                        },
                        {
                            "Id": "CRBA",
                            "Name": "Collarenebri",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.58333, -29.55",
                            "IataCode": "CRB",
                            "Airports": [
                                {
                                    "Id": "CRB",
                                    "Name": "Collarenebri",
                                    "CityId": "CRBA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.58333, -29.55"
                                }
                            ]
                        },
                        {
                            "Id": "CUDA",
                            "Name": "Caloundra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.13333, -26.8",
                            "IataCode": "CUD",
                            "Airports": [
                                {
                                    "Id": "CUD",
                                    "Name": "Caloundra",
                                    "CityId": "CUDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.15, -26.8"
                                }
                            ]
                        },
                        {
                            "Id": "DRNA",
                            "Name": "Dirranbandi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.23333, -28.583333",
                            "IataCode": "DRN",
                            "Airports": [
                                {
                                    "Id": "DRN",
                                    "Name": "Dirranbandi",
                                    "CityId": "DRNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.21528, -28.586667"
                                }
                            ]
                        },
                        {
                            "Id": "EUCA",
                            "Name": "Eucla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.86667, -31.716667",
                            "IataCode": "EUC",
                            "Airports": [
                                {
                                    "Id": "EUC",
                                    "Name": "Eucla",
                                    "CityId": "EUCA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.86667, -31.716667"
                                }
                            ]
                        },
                        {
                            "Id": "FIKA",
                            "Name": "Finke",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.58333, -25.566667",
                            "IataCode": "FIK",
                            "Airports": [
                                {
                                    "Id": "FIK",
                                    "Name": "Finke",
                                    "CityId": "FIKA",
                                    "CountryId": "AU",
                                    "Location": "134.58333, -25.566667"
                                }
                            ]
                        },
                        {
                            "Id": "GAHA",
                            "Name": "Gayndah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.6, -25.633333",
                            "IataCode": "GAH",
                            "Airports": [
                                {
                                    "Id": "GAH",
                                    "Name": "Gayndah",
                                    "CityId": "GAHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.48333, -25.433333"
                                }
                            ]
                        },
                        {
                            "Id": "GTTA",
                            "Name": "Georgetown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.53333, -18.3",
                            "IataCode": "GTT",
                            "Airports": [
                                {
                                    "Id": "GTT",
                                    "Name": "Georgetown",
                                    "CityId": "GTTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.78333, -18.416667"
                                }
                            ]
                        },
                        {
                            "Id": "ISIA",
                            "Name": "Isisford",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.43333, -24.266667",
                            "IataCode": "ISI",
                            "Airports": [
                                {
                                    "Id": "ISI",
                                    "Name": "Isisford",
                                    "CityId": "ISIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.42333, -24.263333"
                                }
                            ]
                        },
                        {
                            "Id": "KNIA",
                            "Name": "Katanning",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.55, -33.7",
                            "IataCode": "KNI",
                            "Airports": [
                                {
                                    "Id": "KNI",
                                    "Name": "Katanning",
                                    "CityId": "KNIA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.55, -33.7"
                                }
                            ]
                        },
                        {
                            "Id": "KPPA",
                            "Name": "Kalpowar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.3, -24.7",
                            "IataCode": "KPP",
                            "Airports": [
                                {
                                    "Id": "KPP",
                                    "Name": "Kalpowar",
                                    "CityId": "KPPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.93333, -19.966667"
                                }
                            ]
                        },
                        {
                            "Id": "KPSA",
                            "Name": "Kempsey",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.83333, -31.083333",
                            "IataCode": "KPS",
                            "Airports": [
                                {
                                    "Id": "KPS",
                                    "Name": "Kempsey",
                                    "CityId": "KPSA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.76667, -31.083333"
                                }
                            ]
                        },
                        {
                            "Id": "FIZA",
                            "Name": "Fitzroy Crossing",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "125.58333, -18.183333",
                            "IataCode": "FIZ",
                            "Airports": [
                                {
                                    "Id": "FIZ",
                                    "Name": "Fitzroy Crossing",
                                    "CityId": "FIZA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.55417, -18.178333"
                                }
                            ]
                        },
                        {
                            "Id": "GOOA",
                            "Name": "Goondiwindi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.31667, -28.55",
                            "IataCode": "GOO",
                            "Airports": [
                                {
                                    "Id": "GOO",
                                    "Name": "Goondiwindi",
                                    "CityId": "GOOA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.31944, -28.524444"
                                }
                            ]
                        },
                        {
                            "Id": "LLGA",
                            "Name": "Chillagoe",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.53333, -17.15",
                            "IataCode": "LLG",
                            "Airports": [
                                {
                                    "Id": "LLG",
                                    "Name": "Chillagoe",
                                    "CityId": "LLGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.53333, -17.133333"
                                }
                            ]
                        },
                        {
                            "Id": "YUEA",
                            "Name": "Yuendumu",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.81667, -22.266667",
                            "IataCode": "YUE",
                            "Airports": [
                                {
                                    "Id": "YUE",
                                    "Name": "Yuendumu",
                                    "CityId": "YUEA",
                                    "CountryId": "AU",
                                    "Location": "131.81667, -22.266667"
                                }
                            ]
                        },
                        {
                            "Id": "LTPA",
                            "Name": "Lyndhurst",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.35, -30.283333",
                            "IataCode": "LTP",
                            "Airports": [
                                {
                                    "Id": "LTP",
                                    "Name": "Lyndhurst",
                                    "CityId": "LTPA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.35, -30.283333"
                                }
                            ]
                        },
                        {
                            "Id": "LUUA",
                            "Name": "Laura",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.46667, -15.566667",
                            "IataCode": "LUU",
                            "Airports": [
                                {
                                    "Id": "LUU",
                                    "Name": "Laura",
                                    "CityId": "LUUA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.16667, -15.666667"
                                }
                            ]
                        },
                        {
                            "Id": "SNHA",
                            "Name": "Stanthorpe",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.95, -28.666667",
                            "IataCode": "SNH",
                            "Airports": [
                                {
                                    "Id": "SNH",
                                    "Name": "Stanthorpe",
                                    "CityId": "SNHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.95, -28.65"
                                }
                            ]
                        },
                        {
                            "Id": "TANA",
                            "Name": "Tangalooma",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.37429, -27.179447",
                            "IataCode": "TAN",
                            "Airports": [
                                {
                                    "Id": "TAN",
                                    "Name": "Tangalooma",
                                    "CityId": "TANA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.25, -27.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LOCA",
                            "Name": "Lock",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.76667, -33.566667",
                            "IataCode": "LOC",
                            "Airports": [
                                {
                                    "Id": "LOC",
                                    "Name": "Lock",
                                    "CityId": "LOCA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "135.75, -33.55"
                                }
                            ]
                        },
                        {
                            "Id": "XCOA",
                            "Name": "Colac",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "143.58333, -38.333333",
                            "IataCode": "XCO",
                            "Airports": [
                                {
                                    "Id": "XCO",
                                    "Name": "Colac",
                                    "CityId": "XCOA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "143.58333, -38.35"
                                }
                            ]
                        },
                        {
                            "Id": "XMLA",
                            "Name": "Minlaton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.6, -34.766667",
                            "IataCode": "XML",
                            "Airports": [
                                {
                                    "Id": "XML",
                                    "Name": "Minlaton",
                                    "CityId": "XMLA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.533, -34.75"
                                }
                            ]
                        },
                        {
                            "Id": "XTOA",
                            "Name": "Taroom",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "149.8, -25.65",
                            "IataCode": "XTO",
                            "Airports": [
                                {
                                    "Id": "XTO",
                                    "Name": "Taroom",
                                    "CityId": "XTOA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149.8, -25.433333"
                                }
                            ]
                        },
                        {
                            "Id": "BDBA",
                            "Name": "Bundaberg",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.35, -24.85",
                            "IataCode": "BDB",
                            "Airports": [
                                {
                                    "Id": "BDB",
                                    "Name": "Bundaberg",
                                    "CityId": "BDBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.32083, -24.905833"
                                }
                            ]
                        },
                        {
                            "Id": "NTLA",
                            "Name": "Newcastle",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.77647, -32.927148",
                            "IataCode": "NTL",
                            "Airports": [
                                {
                                    "Id": "NTL",
                                    "Name": "Newcastle Williamtown",
                                    "CityId": "NTLA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.83333, -32.795833"
                                },
                                {
                                    "Id": "BEO",
                                    "Name": "Newcastle Belmont",
                                    "CityId": "NTLA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.66667, -33.033333"
                                }
                            ]
                        },
                        {
                            "Id": "BEUA",
                            "Name": "Bedourie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.46667, -24.35",
                            "IataCode": "BEU",
                            "Airports": [
                                {
                                    "Id": "BEU",
                                    "Name": "Bedourie",
                                    "CityId": "BEUA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.46001, -24.3461"
                                }
                            ]
                        },
                        {
                            "Id": "BFCA",
                            "Name": "Bloomfield",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.33333, -15.933333",
                            "IataCode": "BFC",
                            "Airports": [
                                {
                                    "Id": "BFC",
                                    "Name": "Bloomfield",
                                    "CityId": "BFCA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.33333, -15.9"
                                }
                            ]
                        },
                        {
                            "Id": "BHQA",
                            "Name": "Broken Hill",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "141.43333, -31.95",
                            "IataCode": "BHQ",
                            "Airports": [
                                {
                                    "Id": "BHQ",
                                    "Name": "Broken Hill",
                                    "CityId": "BHQA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "141.46667, -32.001667"
                                }
                            ]
                        },
                        {
                            "Id": "BHSA",
                            "Name": "Bathurst",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.56667, -33.416667",
                            "IataCode": "BHS",
                            "Airports": [
                                {
                                    "Id": "BHS",
                                    "Name": "Bathurst",
                                    "CityId": "BHSA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.65333, -33.409167"
                                }
                            ]
                        },
                        {
                            "Id": "BKQA",
                            "Name": "Blackall",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.46667, -24.416667",
                            "IataCode": "BKQ",
                            "Airports": [
                                {
                                    "Id": "BKQ",
                                    "Name": "Blackall",
                                    "CityId": "BKQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.42833, -24.434167"
                                }
                            ]
                        },
                        {
                            "Id": "BLNA",
                            "Name": "Benalla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "145.98333, -36.55",
                            "IataCode": "BLN",
                            "Airports": [
                                {
                                    "Id": "BLN",
                                    "Name": "Benalla",
                                    "CityId": "BLNA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "145.98333, -36.55"
                                }
                            ]
                        },
                        {
                            "Id": "BLTA",
                            "Name": "Blackwater",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.88333, -23.583333",
                            "IataCode": "BLT",
                            "Airports": [
                                {
                                    "Id": "BLT",
                                    "Name": "Blackwater",
                                    "CityId": "BLTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.88333, -23.583333"
                                }
                            ]
                        },
                        {
                            "Id": "BMEA",
                            "Name": "Broome",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "122.23333, -17.966667",
                            "IataCode": "BME",
                            "Airports": [
                                {
                                    "Id": "BME",
                                    "Name": "Broome",
                                    "CityId": "BMEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "122.23, -17.95"
                                }
                            ]
                        },
                        {
                            "Id": "BNEA",
                            "Name": "Brisbane",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.0235, -27.468968",
                            "IataCode": "BNE",
                            "Airports": [
                                {
                                    "Id": "BNE",
                                    "Name": "Brisbane",
                                    "CityId": "BNEA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.12181, -27.394233"
                                }
                            ]
                        },
                        {
                            "Id": "BNKA",
                            "Name": "Ballina",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153.56667, -28.866667",
                            "IataCode": "BNK",
                            "Airports": [
                                {
                                    "Id": "BNK",
                                    "Name": "Ballina",
                                    "CityId": "BNKA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.55, -28.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BQLA",
                            "Name": "Boulia",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.9, -22.9",
                            "IataCode": "BQL",
                            "Airports": [
                                {
                                    "Id": "BQL",
                                    "Name": "Boulia",
                                    "CityId": "BQLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.9, -22.9"
                                }
                            ]
                        },
                        {
                            "Id": "BRKA",
                            "Name": "Bourke",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "145.93333, -30.1",
                            "IataCode": "BRK",
                            "Airports": [
                                {
                                    "Id": "BRK",
                                    "Name": "Bourke",
                                    "CityId": "BRKA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "145.93333, -30.083333"
                                }
                            ]
                        },
                        {
                            "Id": "BSJA",
                            "Name": "Bairnsdale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "147.61667, -37.833333",
                            "IataCode": "BSJ",
                            "Airports": [
                                {
                                    "Id": "BSJ",
                                    "Name": "Bairnsdale",
                                    "CityId": "BSJA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "147.63333, -37.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BUCA",
                            "Name": "Burketown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.56667, -17.716667",
                            "IataCode": "BUC",
                            "Airports": [
                                {
                                    "Id": "BUC",
                                    "Name": "Burketown",
                                    "CityId": "BUCA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.53333, -17.75"
                                }
                            ]
                        },
                        {
                            "Id": "BUYA",
                            "Name": "Bunbury",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.63333, -33.333333",
                            "IataCode": "BUY",
                            "Airports": [
                                {
                                    "Id": "BUY",
                                    "Name": "Bunbury",
                                    "CityId": "BUYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.63333, -33.316667"
                                }
                            ]
                        },
                        {
                            "Id": "BVIA",
                            "Name": "Birdsville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.36667, -25.9",
                            "IataCode": "BVI",
                            "Airports": [
                                {
                                    "Id": "BVI",
                                    "Name": "Birdsville",
                                    "CityId": "BVIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.34333, -25.898333"
                                }
                            ]
                        },
                        {
                            "Id": "BWQA",
                            "Name": "Brewarrina",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "146.86667, -29.95",
                            "IataCode": "BWQ",
                            "Airports": [
                                {
                                    "Id": "BWQ",
                                    "Name": "Brewarrina",
                                    "CityId": "BWQA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "146.86667, -29.95"
                                }
                            ]
                        },
                        {
                            "Id": "BWTA",
                            "Name": "Burnie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "145.91667, -41.066667",
                            "IataCode": "BWT",
                            "Airports": [
                                {
                                    "Id": "BWT",
                                    "Name": "Burnie",
                                    "CityId": "BWTA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "145.72833, -40.995833"
                                }
                            ]
                        },
                        {
                            "Id": "BWUA",
                            "Name": "Bankstown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.03333, -33.916667",
                            "IataCode": "BWU",
                            "Airports": [
                                {
                                    "Id": "BWU",
                                    "Name": "Bankstown",
                                    "CityId": "BWUA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.98833, -33.92444"
                                }
                            ]
                        },
                        {
                            "Id": "BZDA",
                            "Name": "Balranald",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "143.56667, -34.633333",
                            "IataCode": "BZD",
                            "Airports": [
                                {
                                    "Id": "BZD",
                                    "Name": "Balranald",
                                    "CityId": "BZDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "143.61667, -34.616667"
                                }
                            ]
                        },
                        {
                            "Id": "CAZA",
                            "Name": "Cobar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "145.81667, -31.5",
                            "IataCode": "CAZ",
                            "Airports": [
                                {
                                    "Id": "CAZ",
                                    "Name": "Cobar",
                                    "CityId": "CAZA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "145.79333, -31.535"
                                }
                            ]
                        },
                        {
                            "Id": "CBIA",
                            "Name": "Cape Barren Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "148.01667, -40.383333",
                            "IataCode": "CBI",
                            "Airports": [
                                {
                                    "Id": "CBI",
                                    "Name": "Cape Barren Island",
                                    "CityId": "CBIA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "148.16667, -40.333333"
                                }
                            ]
                        },
                        {
                            "Id": "CBRA",
                            "Name": "Canberra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "ACTER",
                            "Location": "149.12807, -35.283462",
                            "IataCode": "CBR",
                            "Airports": [
                                {
                                    "Id": "CBR",
                                    "Name": "Canberra",
                                    "CityId": "CBRA",
                                    "CountryId": "AU",
                                    "RegionId": "ACTER",
                                    "Location": "149.195, -35.308889"
                                }
                            ]
                        },
                        {
                            "Id": "CCLA",
                            "Name": "Chinchilla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.63333, -26.75",
                            "IataCode": "CCL",
                            "Airports": [
                                {
                                    "Id": "CCL",
                                    "Name": "Chinchilla",
                                    "CityId": "CCLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.63333, -26.75"
                                }
                            ]
                        },
                        {
                            "Id": "CDQA",
                            "Name": "Croydon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.23333, -18.216667",
                            "IataCode": "CDQ",
                            "Airports": [
                                {
                                    "Id": "CDQ",
                                    "Name": "Croydon",
                                    "CityId": "CDQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.25, -18.3"
                                }
                            ]
                        },
                        {
                            "Id": "CDUA",
                            "Name": "Camden",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.7, -34.05",
                            "IataCode": "CDU",
                            "Airports": [
                                {
                                    "Id": "CDU",
                                    "Name": "Camden",
                                    "CityId": "CDUA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.68722, -34.040278"
                                }
                            ]
                        },
                        {
                            "Id": "CEDA",
                            "Name": "Ceduna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "133.66667, -32.116667",
                            "IataCode": "CED",
                            "Airports": [
                                {
                                    "Id": "CED",
                                    "Name": "Ceduna",
                                    "CityId": "CEDA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "133.70584, -32.131667"
                                }
                            ]
                        },
                        {
                            "Id": "CESA",
                            "Name": "Cessnock",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.35, -32.833333",
                            "IataCode": "CES",
                            "Airports": [
                                {
                                    "Id": "CES",
                                    "Name": "Cessnock",
                                    "CityId": "CESA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.35, -32.833333"
                                }
                            ]
                        },
                        {
                            "Id": "CFSA",
                            "Name": "Coffs Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153.13333, -30.3",
                            "IataCode": "CFS",
                            "Airports": [
                                {
                                    "Id": "CFS",
                                    "Name": "Coffs Harbour",
                                    "CityId": "CFSA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.12083, -30.321667"
                                }
                            ]
                        },
                        {
                            "Id": "CIEA",
                            "Name": "Collie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "116.15, -33.366667",
                            "IataCode": "CIE",
                            "Airports": [
                                {
                                    "Id": "CIE",
                                    "Name": "Collie",
                                    "CityId": "CIEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "116.13333, -33.366667"
                                }
                            ]
                        },
                        {
                            "Id": "CMAA",
                            "Name": "Cunnamulla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.68333, -28.066667",
                            "IataCode": "CMA",
                            "Airports": [
                                {
                                    "Id": "CMA",
                                    "Name": "Cunnamulla",
                                    "CityId": "CMAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.61611, -28.031389"
                                }
                            ]
                        },
                        {
                            "Id": "CMDA",
                            "Name": "Cootamundra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.03333, -34.65",
                            "IataCode": "CMD",
                            "Airports": [
                                {
                                    "Id": "CMD",
                                    "Name": "Cootamundra",
                                    "CityId": "CMDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.04333, -34.628333"
                                }
                            ]
                        },
                        {
                            "Id": "CNBA",
                            "Name": "Coonamble",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.4, -30.95",
                            "IataCode": "CNB",
                            "Airports": [
                                {
                                    "Id": "CNB",
                                    "Name": "Coonamble",
                                    "CityId": "CNBA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.37833, -30.980833"
                                }
                            ]
                        },
                        {
                            "Id": "CNJA",
                            "Name": "Cloncurry",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.5, -20.7",
                            "IataCode": "CNJ",
                            "Airports": [
                                {
                                    "Id": "CNJ",
                                    "Name": "Cloncurry",
                                    "CityId": "CNJA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.5, -20.666667"
                                }
                            ]
                        },
                        {
                            "Id": "CNSA",
                            "Name": "Cairns",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.76667, -16.916667",
                            "IataCode": "CNS",
                            "Airports": [
                                {
                                    "Id": "CNS",
                                    "Name": "Cairns",
                                    "CityId": "CNSA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.75167, -16.883333"
                                }
                            ]
                        },
                        {
                            "Id": "COJA",
                            "Name": "Coonabarabran",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.28333, -31.266667",
                            "IataCode": "COJ",
                            "Airports": [
                                {
                                    "Id": "COJ",
                                    "Name": "Coonabarabran",
                                    "CityId": "COJA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.27194, -31.337222"
                                }
                            ]
                        },
                        {
                            "Id": "CPDA",
                            "Name": "Coober Pedy",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "134.71667, -29.016667",
                            "IataCode": "CPD",
                            "Airports": [
                                {
                                    "Id": "CPD",
                                    "Name": "Coober Pedy",
                                    "CityId": "CPDA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "134.75278, -29.044444"
                                }
                            ]
                        },
                        {
                            "Id": "CTLA",
                            "Name": "Charleville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.25, -26.4",
                            "IataCode": "CTL",
                            "Airports": [
                                {
                                    "Id": "CTL",
                                    "Name": "Charleville",
                                    "CityId": "CTLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.26, -26.413333"
                                }
                            ]
                        },
                        {
                            "Id": "CTNA",
                            "Name": "Cooktown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.25, -15.466667",
                            "IataCode": "CTN",
                            "Airports": [
                                {
                                    "Id": "CTN",
                                    "Name": "Cooktown",
                                    "CityId": "CTNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.18333, -15.443333"
                                }
                            ]
                        },
                        {
                            "Id": "AYRA",
                            "Name": "Ayr",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.4, -19.583333",
                            "IataCode": "AYR",
                            "Airports": [
                                {
                                    "Id": "AYR",
                                    "Name": "Ayr",
                                    "CityId": "AYRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.32444, -19.596667"
                                }
                            ]
                        },
                        {
                            "Id": "CUQA",
                            "Name": "Coen",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.2, -13.933333",
                            "IataCode": "CUQ",
                            "Airports": [
                                {
                                    "Id": "CUQ",
                                    "Name": "Coen",
                                    "CityId": "CUQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.11333, -13.763889"
                                }
                            ]
                        },
                        {
                            "Id": "CUYA",
                            "Name": "Cue",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.9, -27.416667",
                            "IataCode": "CUY",
                            "Airports": [
                                {
                                    "Id": "CUY",
                                    "Name": "Cue",
                                    "CityId": "CUYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.91667, -27.45"
                                }
                            ]
                        },
                        {
                            "Id": "CVCA",
                            "Name": "Cleve",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "136.5, -33.7",
                            "IataCode": "CVC",
                            "Airports": [
                                {
                                    "Id": "CVC",
                                    "Name": "Cleve",
                                    "CityId": "CVCA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "136.5, -33.7"
                                }
                            ]
                        },
                        {
                            "Id": "CVQA",
                            "Name": "Carnarvon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "113.65901, -24.884033",
                            "IataCode": "CVQ",
                            "Airports": [
                                {
                                    "Id": "CVQ",
                                    "Name": "Carnarvon",
                                    "CityId": "CVQA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "113.66667, -24.88"
                                }
                            ]
                        },
                        {
                            "Id": "CWTA",
                            "Name": "Cowra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.68333, -33.833333",
                            "IataCode": "CWT",
                            "Airports": [
                                {
                                    "Id": "CWT",
                                    "Name": "Cowra",
                                    "CityId": "CWTA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.64667, -33.855"
                                }
                            ]
                        },
                        {
                            "Id": "CWWA",
                            "Name": "Corowa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "146.38333, -36.033333",
                            "IataCode": "CWW",
                            "Airports": [
                                {
                                    "Id": "CWW",
                                    "Name": "Corowa",
                                    "CityId": "CWWA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "146.35, -35.966667"
                                }
                            ]
                        },
                        {
                            "Id": "CXTA",
                            "Name": "Charters Towers",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.26667, -20.1",
                            "IataCode": "CXT",
                            "Airports": [
                                {
                                    "Id": "CXT",
                                    "Name": "Charters Towers",
                                    "CityId": "CXTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.26917, -20.046667"
                                }
                            ]
                        },
                        {
                            "Id": "CYGA",
                            "Name": "Corryong",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "147.88333, -36.2",
                            "IataCode": "CYG",
                            "Airports": [
                                {
                                    "Id": "CYG",
                                    "Name": "Corryong",
                                    "CityId": "CYGA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "147.9, -36.25"
                                }
                            ]
                        },
                        {
                            "Id": "DBOA",
                            "Name": "Dubbo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.61667, -32.25",
                            "IataCode": "DBO",
                            "Airports": [
                                {
                                    "Id": "DBO",
                                    "Name": "Dubbo",
                                    "CityId": "DBOA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.5775, -32.219167"
                                }
                            ]
                        },
                        {
                            "Id": "DBYA",
                            "Name": "Dalby",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.26667, -27.183333",
                            "IataCode": "DBY",
                            "Airports": [
                                {
                                    "Id": "DBY",
                                    "Name": "Dalby",
                                    "CityId": "DBYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.26667, -27.15"
                                }
                            ]
                        },
                        {
                            "Id": "DGEA",
                            "Name": "Mudgee",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.58333, -32.433333",
                            "IataCode": "DGE",
                            "Airports": [
                                {
                                    "Id": "DGE",
                                    "Name": "Mudgee",
                                    "CityId": "DGEA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.61667, -32.566667"
                                }
                            ]
                        },
                        {
                            "Id": "CMLA",
                            "Name": "Camooweal",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.11667, -19.916667",
                            "IataCode": "CML",
                            "Airports": [
                                {
                                    "Id": "CML",
                                    "Name": "Camooweal",
                                    "CityId": "CMLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.11667, -19.933333"
                                }
                            ]
                        },
                        {
                            "Id": "CRJA",
                            "Name": "Coorabie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "132.3, -31.9",
                            "IataCode": "CRJ",
                            "Airports": [
                                {
                                    "Id": "CRJ",
                                    "Name": "Coorabie",
                                    "CityId": "CRJA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "132.3, -31.9"
                                }
                            ]
                        },
                        {
                            "Id": "DNMA",
                            "Name": "Denham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "113.54222, -25.933333",
                            "IataCode": "DNM",
                            "Airports": [
                                {
                                    "Id": "DNM",
                                    "Name": "Denham",
                                    "CityId": "DNMA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "113.53333, -25.916667"
                                }
                            ]
                        },
                        {
                            "Id": "DNQA",
                            "Name": "Deniliquin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "144.96667, -35.533333",
                            "IataCode": "DNQ",
                            "Airports": [
                                {
                                    "Id": "DNQ",
                                    "Name": "Deniliquin",
                                    "CityId": "DNQA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "144.95167, -35.56"
                                }
                            ]
                        },
                        {
                            "Id": "DPOA",
                            "Name": "Devonport",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "146.35, -41.166667",
                            "IataCode": "DPO",
                            "Airports": [
                                {
                                    "Id": "DPO",
                                    "Name": "Devonport",
                                    "CityId": "DPOA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "146.425, -41.170833"
                                }
                            ]
                        },
                        {
                            "Id": "DRWA",
                            "Name": "Darwin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.84185, -12.461134",
                            "IataCode": "DRW",
                            "Airports": [
                                {
                                    "Id": "DRW",
                                    "Name": "Darwin",
                                    "CityId": "DRWA",
                                    "CountryId": "AU",
                                    "Location": "130.87222, -12.416667"
                                }
                            ]
                        },
                        {
                            "Id": "DYWA",
                            "Name": "Daly Waters",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.36667, -16.25",
                            "IataCode": "DYW",
                            "Airports": [
                                {
                                    "Id": "DYW",
                                    "Name": "Daly Waters",
                                    "CityId": "DYWA",
                                    "CountryId": "AU",
                                    "Location": "133.4, -16.25"
                                }
                            ]
                        },
                        {
                            "Id": "ECHA",
                            "Name": "Echuca",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "144.75, -36.133333",
                            "IataCode": "ECH",
                            "Airports": [
                                {
                                    "Id": "ECH",
                                    "Name": "Echuca",
                                    "CityId": "ECHA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.75, -36.15"
                                }
                            ]
                        },
                        {
                            "Id": "DOXA",
                            "Name": "Dongara",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.93333, -29.25",
                            "IataCode": "DOX",
                            "Airports": [
                                {
                                    "Id": "DOX",
                                    "Name": "Dongara",
                                    "CityId": "DOXA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.93333, -29.25"
                                }
                            ]
                        },
                        {
                            "Id": "DRBA",
                            "Name": "Derby",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "123.96667, -17.3",
                            "IataCode": "DRB",
                            "Airports": [
                                {
                                    "Id": "DCN",
                                    "Name": "Derby Curtin",
                                    "CityId": "DRBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "123.8172, -17.5672"
                                },
                                {
                                    "Id": "DRB",
                                    "Name": "Derby",
                                    "CityId": "DRBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "123.6525, -17.373333"
                                }
                            ]
                        },
                        {
                            "Id": "EIHA",
                            "Name": "Einasleigh",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.1, -18.516667",
                            "IataCode": "EIH",
                            "Airports": [
                                {
                                    "Id": "EIH",
                                    "Name": "Einasleigh",
                                    "CityId": "EIHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.1, -18.5"
                                }
                            ]
                        },
                        {
                            "Id": "EPRA",
                            "Name": "Esperance",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.9, -33.866667",
                            "IataCode": "EPR",
                            "Airports": [
                                {
                                    "Id": "EPR",
                                    "Name": "Esperance",
                                    "CityId": "EPRA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.82417, -33.685"
                                }
                            ]
                        },
                        {
                            "Id": "EMDA",
                            "Name": "Emerald",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.16667, -23.533333",
                            "IataCode": "EMD",
                            "Airports": [
                                {
                                    "Id": "EMD",
                                    "Name": "Emerald",
                                    "CityId": "EMDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.17833, -23.57"
                                }
                            ]
                        },
                        {
                            "Id": "EVHA",
                            "Name": "Evans Head",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153.43333, -29.116667",
                            "IataCode": "EVH",
                            "Airports": [
                                {
                                    "Id": "EVH",
                                    "Name": "Evans Head",
                                    "CityId": "EVHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.41667, -29.1"
                                }
                            ]
                        },
                        {
                            "Id": "FLYA",
                            "Name": "Finley",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "145.58333, -35.633333",
                            "IataCode": "FLY",
                            "Airports": [
                                {
                                    "Id": "FLY",
                                    "Name": "Finley",
                                    "CityId": "FLYA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "145.58333, -35.65"
                                }
                            ]
                        },
                        {
                            "Id": "FLCA",
                            "Name": "Falls Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.6, -34.966667",
                            "IataCode": "FLC",
                            "Airports": [
                                {
                                    "Id": "FLC",
                                    "Name": "Falls Creek",
                                    "CityId": "FLCA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "143.28333, -36.833333"
                                }
                            ]
                        },
                        {
                            "Id": "GBLA",
                            "Name": "Goulburn Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.38833, -11.65",
                            "IataCode": "GBL",
                            "Airports": [
                                {
                                    "Id": "GBL",
                                    "Name": "Goulburn Island",
                                    "CityId": "GBLA",
                                    "CountryId": "AU",
                                    "Location": "133.43333, -11.55"
                                }
                            ]
                        },
                        {
                            "Id": "FOSA",
                            "Name": "Forrest",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.1, -30.85",
                            "IataCode": "FOS",
                            "Airports": [
                                {
                                    "Id": "FOS",
                                    "Name": "Forrest",
                                    "CityId": "FOSA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.05, -30.816667"
                                }
                            ]
                        },
                        {
                            "Id": "FOTA",
                            "Name": "Forster",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.51667, -32.166667",
                            "IataCode": "FOT",
                            "Airports": [
                                {
                                    "Id": "FOT",
                                    "Name": "Forster",
                                    "CityId": "FOTA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.51667, -32.183333"
                                }
                            ]
                        },
                        {
                            "Id": "FRBA",
                            "Name": "Forbes",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.01667, -33.383333",
                            "IataCode": "FRB",
                            "Airports": [
                                {
                                    "Id": "FRB",
                                    "Name": "Forbes",
                                    "CityId": "FRBA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.05, -33.4"
                                }
                            ]
                        },
                        {
                            "Id": "GSCA",
                            "Name": "Gascoyne Junction",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.21667, -25.05",
                            "IataCode": "GSC",
                            "Airports": [
                                {
                                    "Id": "GSC",
                                    "Name": "Gascoyne Junction",
                                    "CityId": "GSCA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.25, -25.166667"
                                }
                            ]
                        },
                        {
                            "Id": "GEEA",
                            "Name": "George Town",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.83333, -41.1",
                            "IataCode": "GEE",
                            "Airports": [
                                {
                                    "Id": "GEE",
                                    "Name": "George Town",
                                    "CityId": "GEEA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.84, -41.08"
                                }
                            ]
                        },
                        {
                            "Id": "GETA",
                            "Name": "Geraldton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.6, -28.766667",
                            "IataCode": "GET",
                            "Airports": [
                                {
                                    "Id": "GET",
                                    "Name": "Geraldton",
                                    "CityId": "GETA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.70139, -28.79722"
                                }
                            ]
                        },
                        {
                            "Id": "GEXA",
                            "Name": "Geelong",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "144.3607, -38.147113",
                            "IataCode": "GEX",
                            "Airports": [
                                {
                                    "Id": "GEX",
                                    "Name": "Geelong",
                                    "CityId": "GEXA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.33299, -38.225"
                                }
                            ]
                        },
                        {
                            "Id": "GFFA",
                            "Name": "Griffith",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "146.03333, -34.283333",
                            "IataCode": "GFF",
                            "Airports": [
                                {
                                    "Id": "GFF",
                                    "Name": "Griffith",
                                    "CityId": "GFFA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "146.06583, -34.250833"
                                }
                            ]
                        },
                        {
                            "Id": "GFNA",
                            "Name": "Grafton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.93333, -29.683333",
                            "IataCode": "GFN",
                            "Airports": [
                                {
                                    "Id": "GFN",
                                    "Name": "Grafton",
                                    "CityId": "GFNA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.03389, -29.755833"
                                }
                            ]
                        },
                        {
                            "Id": "GLIA",
                            "Name": "Glen Innes",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.73333, -29.733333",
                            "IataCode": "GLI",
                            "Airports": [
                                {
                                    "Id": "GLI",
                                    "Name": "Glen Innes",
                                    "CityId": "GLIA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.69083, -29.676667"
                                }
                            ]
                        },
                        {
                            "Id": "GLTA",
                            "Name": "Gladstone",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.25, -23.85",
                            "IataCode": "GLT",
                            "Airports": [
                                {
                                    "Id": "GLT",
                                    "Name": "Gladstone",
                                    "CityId": "GLTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.25417, -23.833333"
                                }
                            ]
                        },
                        {
                            "Id": "GOVA",
                            "Name": "Gove",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.76667, -12.233333",
                            "IataCode": "GOV",
                            "Airports": [
                                {
                                    "Id": "GOV",
                                    "Name": "Gove",
                                    "CityId": "GOVA",
                                    "CountryId": "AU",
                                    "Location": "136.82167, -12.281667"
                                }
                            ]
                        },
                        {
                            "Id": "GOSA",
                            "Name": "Gosford",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.35, -33.433333",
                            "IataCode": "GOS",
                            "Airports": [
                                {
                                    "Id": "GOS",
                                    "Name": "Gosford",
                                    "CityId": "GOSA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.35, -33.433333"
                                }
                            ]
                        },
                        {
                            "Id": "GUHA",
                            "Name": "Gunnedah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.25, -30.983333",
                            "IataCode": "GUH",
                            "Airports": [
                                {
                                    "Id": "GUH",
                                    "Name": "Gunnedah",
                                    "CityId": "GUHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.23333, -30.95"
                                }
                            ]
                        },
                        {
                            "Id": "GULA",
                            "Name": "Goulburn",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.71667, -34.75",
                            "IataCode": "GUL",
                            "Airports": [
                                {
                                    "Id": "GUL",
                                    "Name": "Goulburn",
                                    "CityId": "GULA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.71667, -34.75"
                                }
                            ]
                        },
                        {
                            "Id": "GYPA",
                            "Name": "Gympie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.66667, -26.183333",
                            "IataCode": "GYP",
                            "Airports": [
                                {
                                    "Id": "GYP",
                                    "Name": "Gympie",
                                    "CityId": "GYPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.63333, -26.183333"
                                }
                            ]
                        },
                        {
                            "Id": "HBAA",
                            "Name": "Hobart",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "147.33333, -42.916667",
                            "IataCode": "HBA",
                            "Airports": [
                                {
                                    "Id": "HBA",
                                    "Name": "Hobart",
                                    "CityId": "HBAA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "147.48333, -42.833333"
                                }
                            ]
                        },
                        {
                            "Id": "HCQA",
                            "Name": "Halls Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "127.76667, -18.266667",
                            "IataCode": "HCQ",
                            "Airports": [
                                {
                                    "Id": "HCQ",
                                    "Name": "Halls Creek",
                                    "CityId": "HCQA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "127.66667, -18.233333"
                                }
                            ]
                        },
                        {
                            "Id": "HGDA",
                            "Name": "Hughenden",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.2, -20.85",
                            "IataCode": "HGD",
                            "Airports": [
                                {
                                    "Id": "HGD",
                                    "Name": "Hughenden",
                                    "CityId": "HGDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.22444, -20.818889"
                                }
                            ]
                        },
                        {
                            "Id": "HLTA",
                            "Name": "Hamilton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.03333, -37.75",
                            "IataCode": "HLT",
                            "Airports": [
                                {
                                    "Id": "HLT",
                                    "Name": "Hamilton",
                                    "CityId": "HLTA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.06056, -37.65"
                                }
                            ]
                        },
                        {
                            "Id": "HLVA",
                            "Name": "Helenvale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.23333, -15.716667",
                            "IataCode": "HLV",
                            "Airports": [
                                {
                                    "Id": "HLV",
                                    "Name": "Helenvale",
                                    "CityId": "HLVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.2, -15.683333"
                                }
                            ]
                        },
                        {
                            "Id": "HSMA",
                            "Name": "Horsham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.2, -36.716667",
                            "IataCode": "HSM",
                            "Airports": [
                                {
                                    "Id": "HSM",
                                    "Name": "Horsham",
                                    "CityId": "HSMA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.16667, -36.666667"
                                }
                            ]
                        },
                        {
                            "Id": "HVBA",
                            "Name": "Hervey Bay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.8333, -25.2833",
                            "IataCode": "HVB",
                            "Airports": [
                                {
                                    "Id": "HVB",
                                    "Name": "Hervey Bay",
                                    "CityId": "HVBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.88028, -25.31889"
                                }
                            ]
                        },
                        {
                            "Id": "HWKA",
                            "Name": "Hawker",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.41667, -31.883333",
                            "IataCode": "HWK",
                            "Airports": [
                                {
                                    "Id": "HWK",
                                    "Name": "Hawker",
                                    "CityId": "HWKA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.41667, -31.716667"
                                }
                            ]
                        },
                        {
                            "Id": "HXXA",
                            "Name": "Hay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "144.85, -34.5",
                            "IataCode": "HXX",
                            "Airports": [
                                {
                                    "Id": "HXX",
                                    "Name": "Hay",
                                    "CityId": "HXXA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "144.83333, -34.516667"
                                }
                            ]
                        },
                        {
                            "Id": "IFLA",
                            "Name": "Innisfail",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.03333, -17.533333",
                            "IataCode": "IFL",
                            "Airports": [
                                {
                                    "Id": "IFL",
                                    "Name": "Innisfail",
                                    "CityId": "IFLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.01667, -17.55"
                                }
                            ]
                        },
                        {
                            "Id": "IGHA",
                            "Name": "Ingham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.16667, -18.65",
                            "IataCode": "IGH",
                            "Airports": [
                                {
                                    "Id": "IGH",
                                    "Name": "Ingham",
                                    "CityId": "IGHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.16667, -18.716667"
                                }
                            ]
                        },
                        {
                            "Id": "INMA",
                            "Name": "Innamincka",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.73333, -27.75",
                            "IataCode": "INM",
                            "Airports": [
                                {
                                    "Id": "INM",
                                    "Name": "Innamincka",
                                    "CityId": "INMA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "140.73333, -27.75"
                                }
                            ]
                        },
                        {
                            "Id": "ISAA",
                            "Name": "Mount Isa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.5, -20.733333",
                            "IataCode": "ISA",
                            "Airports": [
                                {
                                    "Id": "ISA",
                                    "Name": "Mount Isa",
                                    "CityId": "ISAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.47917, -20.655833"
                                }
                            ]
                        },
                        {
                            "Id": "IVRA",
                            "Name": "Inverell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.11667, -29.783333",
                            "IataCode": "IVR",
                            "Airports": [
                                {
                                    "Id": "IVR",
                                    "Name": "Inverell",
                                    "CityId": "IVRA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.145, -29.89"
                                }
                            ]
                        },
                        {
                            "Id": "JABA",
                            "Name": "Jabiru",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.83604, -12.67049",
                            "IataCode": "JAB",
                            "Airports": [
                                {
                                    "Id": "JAB",
                                    "Name": "Jabiru",
                                    "CityId": "JABA",
                                    "CountryId": "AU",
                                    "Location": "132.89194, -12.659722"
                                }
                            ]
                        },
                        {
                            "Id": "JCKA",
                            "Name": "Julia Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.73333, -20.65",
                            "IataCode": "JCK",
                            "Airports": [
                                {
                                    "Id": "JCK",
                                    "Name": "Julia Creek",
                                    "CityId": "JCKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.7, -20.583333"
                                }
                            ]
                        },
                        {
                            "Id": "JUNA",
                            "Name": "Jundah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.06667, -24.833333",
                            "IataCode": "JUN",
                            "Airports": [
                                {
                                    "Id": "JUN",
                                    "Name": "Jundah",
                                    "CityId": "JUNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.06667, -24.833333"
                                }
                            ]
                        },
                        {
                            "Id": "KAXA",
                            "Name": "Kalbarri",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.16667, -27.666667",
                            "IataCode": "KAX",
                            "Airports": [
                                {
                                    "Id": "KAX",
                                    "Name": "Kalbarri",
                                    "CityId": "KAXA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.13333, -27.866667"
                                }
                            ]
                        },
                        {
                            "Id": "KNXA",
                            "Name": "Kununurra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.73333, -15.766667",
                            "IataCode": "KNX",
                            "Airports": [
                                {
                                    "Id": "KNX",
                                    "Name": "Kununurra",
                                    "CityId": "KNXA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.70833, -15.785"
                                }
                            ]
                        },
                        {
                            "Id": "KBYA",
                            "Name": "Streaky Bay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "134.21667, -32.8",
                            "IataCode": "KBY",
                            "Airports": [
                                {
                                    "Id": "KBY",
                                    "Name": "Streaky Bay",
                                    "CityId": "KBYA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "134, -33"
                                }
                            ]
                        },
                        {
                            "Id": "KCEA",
                            "Name": "Collinsville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.85, -20.55",
                            "IataCode": "KCE",
                            "Airports": [
                                {
                                    "Id": "KCE",
                                    "Name": "Collinsville",
                                    "CityId": "KCEA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.86667, -20.55"
                                }
                            ]
                        },
                        {
                            "Id": "KDBA",
                            "Name": "Kambalda",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.66667, -31.2",
                            "IataCode": "KDB",
                            "Airports": [
                                {
                                    "Id": "KDB",
                                    "Name": "Kambalda",
                                    "CityId": "KDBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120, -30"
                                }
                            ]
                        },
                        {
                            "Id": "KQBA",
                            "Name": "Koonibba",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "133.45, -31.966667",
                            "IataCode": "KQB",
                            "Airports": [
                                {
                                    "Id": "KQB",
                                    "Name": "Koonibba",
                                    "CityId": "KQBA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "133.41667, -31.9"
                                }
                            ]
                        },
                        {
                            "Id": "KRAA",
                            "Name": "Kerang",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "143.91667, -35.733333",
                            "IataCode": "KRA",
                            "Airports": [
                                {
                                    "Id": "KRA",
                                    "Name": "Kerang",
                                    "CityId": "KRAA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "143.91667, -35.666667"
                                }
                            ]
                        },
                        {
                            "Id": "KRBA",
                            "Name": "Karumba",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.83333, -17.483333",
                            "IataCode": "KRB",
                            "Airports": [
                                {
                                    "Id": "KRB",
                                    "Name": "Karumba",
                                    "CityId": "KRBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.83194, -17.456667"
                                }
                            ]
                        },
                        {
                            "Id": "KGCA",
                            "Name": "Kingscote",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.63333, -35.666667",
                            "IataCode": "KGC",
                            "Airports": [
                                {
                                    "Id": "KGC",
                                    "Name": "Kingscote",
                                    "CityId": "KGCA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.63535, -35.655622"
                                }
                            ]
                        },
                        {
                            "Id": "KGIA",
                            "Name": "Kalgoorlie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.46667, -30.75",
                            "IataCode": "KGI",
                            "Airports": [
                                {
                                    "Id": "KGI",
                                    "Name": "Kalgoorlie",
                                    "CityId": "KGIA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.46667, -30.766667"
                                }
                            ]
                        },
                        {
                            "Id": "KGYA",
                            "Name": "Kingaroy",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.83333, -26.533333",
                            "IataCode": "KGY",
                            "Airports": [
                                {
                                    "Id": "KGY",
                                    "Name": "Kingaroy",
                                    "CityId": "KGYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.83333, -26.55"
                                }
                            ]
                        },
                        {
                            "Id": "KTRA",
                            "Name": "Katherine",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.26667, -14.466667",
                            "IataCode": "KTR",
                            "Airports": [
                                {
                                    "Id": "KTR",
                                    "Name": "Katherine",
                                    "CityId": "KTRA",
                                    "CountryId": "AU",
                                    "Location": "132.27139, -14.4575"
                                }
                            ]
                        },
                        {
                            "Id": "KWMA",
                            "Name": "Kowanyama",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.6, -14.916667",
                            "IataCode": "KWM",
                            "Airports": [
                                {
                                    "Id": "KWM",
                                    "Name": "Kowanyama",
                                    "CityId": "KWMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.74833, -15.4875"
                                }
                            ]
                        },
                        {
                            "Id": "BQBA",
                            "Name": "Bussellton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.33333, -33.65",
                            "IataCode": "BQB",
                            "Airports": [
                                {
                                    "Id": "BQB",
                                    "Name": "Busselton",
                                    "CityId": "BQBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.25, -33.716667"
                                }
                            ]
                        },
                        {
                            "Id": "LEAA",
                            "Name": "Learmonth",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.08333, -22.25",
                            "IataCode": "LEA",
                            "Airports": [
                                {
                                    "Id": "LEA",
                                    "Name": "Learmonth",
                                    "CityId": "LEAA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.08167, -22.235"
                                }
                            ]
                        },
                        {
                            "Id": "LGHA",
                            "Name": "Leigh Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.41667, -30.483333",
                            "IataCode": "LGH",
                            "Airports": [
                                {
                                    "Id": "LGH",
                                    "Name": "Leigh Creek",
                                    "CityId": "LGHA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.41667, -30.466667"
                                }
                            ]
                        },
                        {
                            "Id": "LHGA",
                            "Name": "Lightning Ridge",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.98333, -29.433333",
                            "IataCode": "LHG",
                            "Airports": [
                                {
                                    "Id": "LHG",
                                    "Name": "Lightning Ridge",
                                    "CityId": "LHGA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "147.98333, -29.458333"
                                }
                            ]
                        },
                        {
                            "Id": "LNOA",
                            "Name": "Leonora",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.31667, -28.883333",
                            "IataCode": "LNO",
                            "Airports": [
                                {
                                    "Id": "LNO",
                                    "Name": "Leonora",
                                    "CityId": "LNOA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.3, -28.883333"
                                }
                            ]
                        },
                        {
                            "Id": "LREA",
                            "Name": "Longreach",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.25, -23.45",
                            "IataCode": "LRE",
                            "Airports": [
                                {
                                    "Id": "LRE",
                                    "Name": "Longreach",
                                    "CityId": "LREA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.27556, -23.437222"
                                }
                            ]
                        },
                        {
                            "Id": "LSYA",
                            "Name": "Lismore",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153.2773, -28.813544",
                            "IataCode": "LSY",
                            "Airports": [
                                {
                                    "Id": "LSY",
                                    "Name": "Lismore",
                                    "CityId": "LSYA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "153.26667, -28.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LVOA",
                            "Name": "Laverton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "122.41667, -28.633333",
                            "IataCode": "LVO",
                            "Airports": [
                                {
                                    "Id": "LVO",
                                    "Name": "Laverton",
                                    "CityId": "LVOA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "122.36667, -28.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MELA",
                            "Name": "Melbourne",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "144.96332, -37.814006",
                            "IataCode": "MEL",
                            "Airports": [
                                {
                                    "Id": "AVV",
                                    "Name": "Melbourne Avalon",
                                    "CityId": "MELA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.46944, -38.03944"
                                },
                                {
                                    "Id": "MEL",
                                    "Name": "Melbourne Tullamarine",
                                    "CityId": "MELA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.84333, -37.67333"
                                },
                                {
                                    "Id": "MEB",
                                    "Name": "Melbourne Essendon",
                                    "CityId": "MELA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.90194, -37.72806"
                                }
                            ]
                        },
                        {
                            "Id": "MGBA",
                            "Name": "Mount Gambier",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.76667, -37.833333",
                            "IataCode": "MGB",
                            "Airports": [
                                {
                                    "Id": "MGB",
                                    "Name": "Mount Gambier",
                                    "CityId": "MGBA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "140.78056, -37.754167"
                                }
                            ]
                        },
                        {
                            "Id": "MKYA",
                            "Name": "Mackay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "149.2, -21.15",
                            "IataCode": "MKY",
                            "Airports": [
                                {
                                    "Id": "MKY",
                                    "Name": "Mackay",
                                    "CityId": "MKYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149.18139, -21.172778"
                                }
                            ]
                        },
                        {
                            "Id": "MQLA",
                            "Name": "Mildura",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.15, -34.2",
                            "IataCode": "MQL",
                            "Airports": [
                                {
                                    "Id": "MQL",
                                    "Name": "Mildura",
                                    "CityId": "MQLA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.085, -34.2275"
                                }
                            ]
                        },
                        {
                            "Id": "MYAA",
                            "Name": "Moruya",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.08333, -35.916667",
                            "IataCode": "MYA",
                            "Airports": [
                                {
                                    "Id": "MYA",
                                    "Name": "Moruya",
                                    "CityId": "MYAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.14667, -35.901667"
                                }
                            ]
                        },
                        {
                            "Id": "ORRA",
                            "Name": "Yorketown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.6, -35.033333",
                            "IataCode": "ORR",
                            "Airports": [
                                {
                                    "Id": "ORR",
                                    "Name": "Yorketown",
                                    "CityId": "ORRA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.58333, -35.033333"
                                }
                            ]
                        },
                        {
                            "Id": "PERA",
                            "Name": "Perth",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.83333, -31.933336",
                            "IataCode": "PER",
                            "Airports": [
                                {
                                    "Id": "PER",
                                    "Name": "Perth",
                                    "CityId": "PERA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.96724, -31.938575"
                                }
                            ]
                        },
                        {
                            "Id": "PHEA",
                            "Name": "Port Hedland",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "118.56667, -20.316667",
                            "IataCode": "PHE",
                            "Airports": [
                                {
                                    "Id": "PHE",
                                    "Name": "Port Hedland",
                                    "CityId": "PHEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "118.63333, -20.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PKTA",
                            "Name": "Port Keats",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "129.55, -14.25",
                            "IataCode": "PKT",
                            "Airports": [
                                {
                                    "Id": "PKT",
                                    "Name": "Port Keats",
                                    "CityId": "PKTA",
                                    "CountryId": "AU",
                                    "Location": "129.53333, -14.25"
                                }
                            ]
                        },
                        {
                            "Id": "PPIA",
                            "Name": "Port Pirie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.01667, -33.183333",
                            "IataCode": "PPI",
                            "Airports": [
                                {
                                    "Id": "PPI",
                                    "Name": "Port Pirie",
                                    "CityId": "PPIA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138, -33.233333"
                                }
                            ]
                        },
                        {
                            "Id": "PPPA",
                            "Name": "Proserpine",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.58333, -20.416667",
                            "IataCode": "PPP",
                            "Airports": [
                                {
                                    "Id": "PPP",
                                    "Name": "Whitsunday Coast (Proserpine)",
                                    "CityId": "PPPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.55722, -20.496111"
                                }
                            ]
                        },
                        {
                            "Id": "PQQA",
                            "Name": "Port Macquarie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.91667, -31.433333",
                            "IataCode": "PQQ",
                            "Airports": [
                                {
                                    "Id": "PQQ",
                                    "Name": "Port Macquarie",
                                    "CityId": "PQQA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.86667, -31.433333"
                                }
                            ]
                        },
                        {
                            "Id": "PTJA",
                            "Name": "Portland",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "141.6, -38.333333",
                            "IataCode": "PTJ",
                            "Airports": [
                                {
                                    "Id": "PTJ",
                                    "Name": "Portland",
                                    "CityId": "PTJA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "141.625, -38.384167"
                                }
                            ]
                        },
                        {
                            "Id": "RCMA",
                            "Name": "Richmond",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.13333, -20.716667",
                            "IataCode": "RCM",
                            "Airports": [
                                {
                                    "Id": "RCM",
                                    "Name": "Richmond",
                                    "CityId": "RCMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.11667, -20.703889"
                                }
                            ]
                        },
                        {
                            "Id": "RMKA",
                            "Name": "Renmark",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.74697, -34.177015",
                            "IataCode": "RMK",
                            "Airports": [
                                {
                                    "Id": "RMK",
                                    "Name": "Renmark",
                                    "CityId": "RMKA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "140.68333, -34.2"
                                }
                            ]
                        },
                        {
                            "Id": "ROKA",
                            "Name": "Rockhampton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.5, -23.383333",
                            "IataCode": "ROK",
                            "Airports": [
                                {
                                    "Id": "ROK",
                                    "Name": "Rockhampton",
                                    "CityId": "ROKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.475, -23.377222"
                                }
                            ]
                        },
                        {
                            "Id": "SIOA",
                            "Name": "Smithton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "145.11667, -40.833333",
                            "IataCode": "SIO",
                            "Airports": [
                                {
                                    "Id": "SIO",
                                    "Name": "Smithton",
                                    "CityId": "SIOA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "145.08167, -40.835833"
                                }
                            ]
                        },
                        {
                            "Id": "SYDA",
                            "Name": "Sydney",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.21143, -33.875004",
                            "IataCode": "SYD",
                            "Airports": [
                                {
                                    "Id": "SYD",
                                    "Name": "Sydney",
                                    "CityId": "SYDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.17583, -33.944167"
                                },
                                {
                                    "Id": "SWZ",
                                    "Name": "Sydney West",
                                    "CityId": "SYDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.72167, -33.888056"
                                },
                                {
                                    "Id": "LBH",
                                    "Name": "Sydney Palm Beach SPB",
                                    "CityId": "SYDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.32389, -33.5875"
                                },
                                {
                                    "Id": "RSE",
                                    "Name": "Sydney Au-Rose Bay",
                                    "CityId": "SYDA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.21667, -33.8"
                                }
                            ]
                        },
                        {
                            "Id": "TMWA",
                            "Name": "Tamworth",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.93333, -31.1",
                            "IataCode": "TMW",
                            "Airports": [
                                {
                                    "Id": "TMW",
                                    "Name": "Tamworth",
                                    "CityId": "TMWA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.84556, -31.081944"
                                }
                            ]
                        },
                        {
                            "Id": "TSVA",
                            "Name": "Townsville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.8, -19.25",
                            "IataCode": "TSV",
                            "Airports": [
                                {
                                    "Id": "TSV",
                                    "Name": "Townsville",
                                    "CityId": "TSVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.76667, -19.25"
                                }
                            ]
                        },
                        {
                            "Id": "ARMA",
                            "Name": "Armidale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.65, -30.516667",
                            "IataCode": "ARM",
                            "Airports": [
                                {
                                    "Id": "ARM",
                                    "Name": "Armidale",
                                    "CityId": "ARMA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.62333, -30.528333"
                                }
                            ]
                        },
                        {
                            "Id": "ARYA",
                            "Name": "Ararat",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.93333, -37.283333",
                            "IataCode": "ARY",
                            "Airports": [
                                {
                                    "Id": "ARY",
                                    "Name": "Ararat",
                                    "CityId": "ARYA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "143, -37.316667"
                                }
                            ]
                        },
                        {
                            "Id": "ASPA",
                            "Name": "Alice Springs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.88333, -23.7",
                            "IataCode": "ASP",
                            "Airports": [
                                {
                                    "Id": "ASP",
                                    "Name": "Alice Springs",
                                    "CityId": "ASPA",
                                    "CountryId": "AU",
                                    "Location": "133.88417, -23.803333"
                                }
                            ]
                        },
                        {
                            "Id": "AXCA",
                            "Name": "Aramac",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.25, -22.966667",
                            "IataCode": "AXC",
                            "Airports": [
                                {
                                    "Id": "AXC",
                                    "Name": "Aramac",
                                    "CityId": "AXCA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.2425, -22.958056"
                                }
                            ]
                        },
                        {
                            "Id": "BCIA",
                            "Name": "Barcaldine",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.28333, -23.566667",
                            "IataCode": "BCI",
                            "Airports": [
                                {
                                    "Id": "BCI",
                                    "Name": "Barcaldine",
                                    "CityId": "BCIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.30194, -23.559167"
                                }
                            ]
                        },
                        {
                            "Id": "MJKA",
                            "Name": "Monkey Mia",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "113.71667, -25.8",
                            "IataCode": "MJK",
                            "Airports": [
                                {
                                    "Id": "MJK",
                                    "Name": "Monkey Mia",
                                    "CityId": "MJKA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "113.57722, -25.896389"
                                }
                            ]
                        },
                        {
                            "Id": "NSVA",
                            "Name": "Noosaville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.06667, -26.4",
                            "IataCode": "NSV",
                            "Airports": [
                                {
                                    "Id": "NSV",
                                    "Name": "Noosaville",
                                    "CityId": "NSVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.03333, -26.416667"
                                }
                            ]
                        },
                        {
                            "Id": "OAGA",
                            "Name": "Orange",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.1, -33.283333",
                            "IataCode": "OAG",
                            "Airports": [
                                {
                                    "Id": "OAG",
                                    "Name": "Orange",
                                    "CityId": "OAGA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.12333, -33.379167"
                                }
                            ]
                        },
                        {
                            "Id": "TGNA",
                            "Name": "Traralgon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "146.53333, -38.183333",
                            "IataCode": "TGN",
                            "Airports": [
                                {
                                    "Id": "TGN",
                                    "Name": "Traralgon",
                                    "CityId": "TGNA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "146.56667, -38.2"
                                }
                            ]
                        },
                        {
                            "Id": "WSYA",
                            "Name": "Airlie Beach",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.7169, -20.2675",
                            "IataCode": "WSY",
                            "Airports": [
                                {
                                    "Id": "WSY",
                                    "Name": "Airlie Beach",
                                    "CityId": "WSYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.757, -20.2783"
                                }
                            ]
                        },
                        {
                            "Id": "GLYA",
                            "Name": "Goldsworthy",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.53333, -20.35",
                            "IataCode": "GLY",
                            "Airports": [
                                {
                                    "Id": "GLY",
                                    "Name": "Goldsworthy",
                                    "CityId": "GLYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.66667, -20.083333"
                                }
                            ]
                        },
                        {
                            "Id": "JFMA",
                            "Name": "Fremantle",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.76667, -32.05",
                            "IataCode": "JFM",
                            "Airports": [
                                {
                                    "Id": "JFM",
                                    "Name": "Fremantle",
                                    "CityId": "JFMA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.75, -32.05"
                                }
                            ]
                        },
                        {
                            "Id": "PTIA",
                            "Name": "Port Douglas",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.46667, -16.483333",
                            "IataCode": "PTI",
                            "Airports": [
                                {
                                    "Id": "PTI",
                                    "Name": "Port Douglas",
                                    "CityId": "PTIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.46389, -16.486111"
                                }
                            ]
                        },
                        {
                            "Id": "TPRA",
                            "Name": "Tom Price",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.7931, -22.6939",
                            "IataCode": "TPR",
                            "Airports": [
                                {
                                    "Id": "TPR",
                                    "Name": "Tom Price",
                                    "CityId": "TPRA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.66667, -22.75"
                                }
                            ]
                        },
                        {
                            "Id": "MBBA",
                            "Name": "Marble Bar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.73333, -21.183333",
                            "IataCode": "MBB",
                            "Airports": [
                                {
                                    "Id": "MBB",
                                    "Name": "Marble Bar",
                                    "CityId": "MBBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.58333, -21.25"
                                }
                            ]
                        },
                        {
                            "Id": "MBHA",
                            "Name": "Maryborough",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.7, -25.533333",
                            "IataCode": "MBH",
                            "Airports": [
                                {
                                    "Id": "MBH",
                                    "Name": "Maryborough",
                                    "CityId": "MBHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.71333, -25.516667"
                                }
                            ]
                        },
                        {
                            "Id": "MBWA",
                            "Name": "Moorabbin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "145.03417, -37.936667",
                            "IataCode": "MBW",
                            "Airports": [
                                {
                                    "Id": "MBW",
                                    "Name": "Moorabbin",
                                    "CityId": "MBWA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "145.0975, -37.98"
                                }
                            ]
                        },
                        {
                            "Id": "MQZA",
                            "Name": "Margaret River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.06667, -33.95",
                            "IataCode": "MQZ",
                            "Airports": [
                                {
                                    "Id": "MQZ",
                                    "Name": "Margaret River",
                                    "CityId": "MQZA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.1, -33.933333"
                                }
                            ]
                        },
                        {
                            "Id": "MIMA",
                            "Name": "Merimbula",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.9, -36.9",
                            "IataCode": "MIM",
                            "Airports": [
                                {
                                    "Id": "MIM",
                                    "Name": "Merimbula",
                                    "CityId": "MIMA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.92, -36.908333"
                                }
                            ]
                        },
                        {
                            "Id": "MINA",
                            "Name": "Minnipa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.15, -32.85",
                            "IataCode": "MIN",
                            "Airports": [
                                {
                                    "Id": "MIN",
                                    "Name": "Minnipa",
                                    "CityId": "MINA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "134.83333, -32.833333"
                                }
                            ]
                        },
                        {
                            "Id": "MJPA",
                            "Name": "Manjimup",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "116.15, -34.233333",
                            "IataCode": "MJP",
                            "Airports": [
                                {
                                    "Id": "MJP",
                                    "Name": "Manjimup",
                                    "CityId": "MJPA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "116.1, -34.233333"
                                }
                            ]
                        },
                        {
                            "Id": "MKRA",
                            "Name": "Meekatharra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "118.48333, -26.6",
                            "IataCode": "MKR",
                            "Airports": [
                                {
                                    "Id": "MKR",
                                    "Name": "Meekatharra",
                                    "CityId": "MKRA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "118.55, -26.6"
                                }
                            ]
                        },
                        {
                            "Id": "MLRA",
                            "Name": "Millicent",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.35, -37.6",
                            "IataCode": "MLR",
                            "Airports": [
                                {
                                    "Id": "MLR",
                                    "Name": "Millicent",
                                    "CityId": "MLRA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "140.01667, -37.483333"
                                }
                            ]
                        },
                        {
                            "Id": "MMGA",
                            "Name": "Mount Magnet",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.81667, -28.066667",
                            "IataCode": "MMG",
                            "Airports": [
                                {
                                    "Id": "MMG",
                                    "Name": "Mount Magnet",
                                    "CityId": "MMGA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.85, -28.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MMMA",
                            "Name": "Middlemount",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.69926, -22.812264",
                            "IataCode": "MMM",
                            "Airports": [
                                {
                                    "Id": "MMM",
                                    "Name": "Middlemount",
                                    "CityId": "MMMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.70472, -22.8025"
                                }
                            ]
                        },
                        {
                            "Id": "MNGA",
                            "Name": "Maningrida",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.26667, -12.066667",
                            "IataCode": "MNG",
                            "Airports": [
                                {
                                    "Id": "MNG",
                                    "Name": "Maningrida",
                                    "CityId": "MNGA",
                                    "CountryId": "AU",
                                    "Location": "134.23333, -12.066667"
                                }
                            ]
                        },
                        {
                            "Id": "MNQA",
                            "Name": "Monto",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.11667, -24.866667",
                            "IataCode": "MNQ",
                            "Airports": [
                                {
                                    "Id": "MNQ",
                                    "Name": "Monto",
                                    "CityId": "MNQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.1, -24.883333"
                                }
                            ]
                        },
                        {
                            "Id": "MOVA",
                            "Name": "Moranbah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.04661, -22.001628",
                            "IataCode": "MOV",
                            "Airports": [
                                {
                                    "Id": "MOV",
                                    "Name": "Moranbah",
                                    "CityId": "MOVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.077, -22.0578"
                                }
                            ]
                        },
                        {
                            "Id": "MRGA",
                            "Name": "Mareeba",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.43333, -17",
                            "IataCode": "MRG",
                            "Airports": [
                                {
                                    "Id": "MRG",
                                    "Name": "Mareeba",
                                    "CityId": "MRGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.46667, -17"
                                }
                            ]
                        },
                        {
                            "Id": "MRZA",
                            "Name": "Moree",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.85, -29.466667",
                            "IataCode": "MRZ",
                            "Airports": [
                                {
                                    "Id": "MRZ",
                                    "Name": "Moree",
                                    "CityId": "MRZA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.84556, -29.498333"
                                }
                            ]
                        },
                        {
                            "Id": "MTLA",
                            "Name": "Maitland",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.55, -32.733333",
                            "IataCode": "MTL",
                            "Airports": [
                                {
                                    "Id": "MTL",
                                    "Name": "Maitland",
                                    "CityId": "MTLA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.55, -32.733333"
                                }
                            ]
                        },
                        {
                            "Id": "MTQA",
                            "Name": "Mitchell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.96667, -26.483333",
                            "IataCode": "MTQ",
                            "Airports": [
                                {
                                    "Id": "MTQ",
                                    "Name": "Mitchell",
                                    "CityId": "MTQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147.96667, -26.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MVHA",
                            "Name": "Macksville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.9, -30.716667",
                            "IataCode": "MVH",
                            "Airports": [
                                {
                                    "Id": "MVH",
                                    "Name": "Macksville",
                                    "CityId": "MVHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.9, -30.75"
                                }
                            ]
                        },
                        {
                            "Id": "MWBA",
                            "Name": "Morawa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "116, -29.216667",
                            "IataCode": "MWB",
                            "Airports": [
                                {
                                    "Id": "MWB",
                                    "Name": "Morawa",
                                    "CityId": "MWBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "116.0225, -29.204167"
                                }
                            ]
                        },
                        {
                            "Id": "MXUA",
                            "Name": "Mullewa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.51667, -28.533333",
                            "IataCode": "MXU",
                            "Airports": [
                                {
                                    "Id": "MXU",
                                    "Name": "Mullewa",
                                    "CityId": "MXUA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.51667, -28.55"
                                }
                            ]
                        },
                        {
                            "Id": "NAAA",
                            "Name": "Narrabri",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.78333, -30.316667",
                            "IataCode": "NAA",
                            "Airports": [
                                {
                                    "Id": "NAA",
                                    "Name": "Narrabri",
                                    "CityId": "NAAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.83333, -30.3"
                                }
                            ]
                        },
                        {
                            "Id": "NACA",
                            "Name": "Naracoorte",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.7383, -36.957848",
                            "IataCode": "NAC",
                            "Airports": [
                                {
                                    "Id": "NAC",
                                    "Name": "Naracoorte",
                                    "CityId": "NACA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "140.83333, -36.95"
                                }
                            ]
                        },
                        {
                            "Id": "NBHA",
                            "Name": "Nambucca Heads",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "153, -30.65",
                            "IataCode": "NBH",
                            "Airports": [
                                {
                                    "Id": "NBH",
                                    "Name": "Nambucca Heads",
                                    "CityId": "NBHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.98333, -30.633333"
                                }
                            ]
                        },
                        {
                            "Id": "NDSA",
                            "Name": "Sandstone",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.28333, -27.983333",
                            "IataCode": "NDS",
                            "Airports": [
                                {
                                    "Id": "NDS",
                                    "Name": "Sandstone",
                                    "CityId": "NDSA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.3, -27.983333"
                                }
                            ]
                        },
                        {
                            "Id": "NGAA",
                            "Name": "Young",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.3, -34.316667",
                            "IataCode": "NGA",
                            "Airports": [
                                {
                                    "Id": "NGA",
                                    "Name": "Young",
                                    "CityId": "NGAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.25, -34.25"
                                }
                            ]
                        },
                        {
                            "Id": "NLLA",
                            "Name": "Nullagine",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.1, -21.883333",
                            "IataCode": "NLL",
                            "Airports": [
                                {
                                    "Id": "NLL",
                                    "Name": "Nullagine",
                                    "CityId": "NLLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.2, -21.75"
                                }
                            ]
                        },
                        {
                            "Id": "NOAA",
                            "Name": "Nowra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.6, -34.883333",
                            "IataCode": "NOA",
                            "Airports": [
                                {
                                    "Id": "NOA",
                                    "Name": "Nowra",
                                    "CityId": "NOAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.5375, -34.950556"
                                }
                            ]
                        },
                        {
                            "Id": "NPPA",
                            "Name": "Napperby",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.11667, -33.166667",
                            "IataCode": "NPP",
                            "Airports": [
                                {
                                    "Id": "NPP",
                                    "Name": "Napperby",
                                    "CityId": "NPPA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "132.68333, -22.5"
                                }
                            ]
                        },
                        {
                            "Id": "NRAA",
                            "Name": "Narrandera",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "146.55, -34.75",
                            "IataCode": "NRA",
                            "Airports": [
                                {
                                    "Id": "NRA",
                                    "Name": "Narrandera",
                                    "CityId": "NRAA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "146.525, -34.701667"
                                }
                            ]
                        },
                        {
                            "Id": "NRGA",
                            "Name": "Narrogin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.16667, -32.933333",
                            "IataCode": "NRG",
                            "Airports": [
                                {
                                    "Id": "NRG",
                                    "Name": "Narrogin",
                                    "CityId": "NRGA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.16667, -32.933333"
                                }
                            ]
                        },
                        {
                            "Id": "NSAA",
                            "Name": "Noosa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.15, -26.383333",
                            "IataCode": "NSA",
                            "Airports": [
                                {
                                    "Id": "NSA",
                                    "Name": "Noosa",
                                    "CityId": "NSAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.05, -26.416667"
                                }
                            ]
                        },
                        {
                            "Id": "NSMA",
                            "Name": "Norseman",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.76667, -32.2",
                            "IataCode": "NSM",
                            "Airports": [
                                {
                                    "Id": "NSM",
                                    "Name": "Norseman",
                                    "CityId": "NSMA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.75, -32.2"
                                }
                            ]
                        },
                        {
                            "Id": "NSOA",
                            "Name": "Scone",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.85, -32.083333",
                            "IataCode": "NSO",
                            "Airports": [
                                {
                                    "Id": "NSO",
                                    "Name": "Scone",
                                    "CityId": "NSOA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.8325, -32.036111"
                                }
                            ]
                        },
                        {
                            "Id": "NTNA",
                            "Name": "Normanton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.08333, -17.666667",
                            "IataCode": "NTN",
                            "Airports": [
                                {
                                    "Id": "NTN",
                                    "Name": "Normanton",
                                    "CityId": "NTNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.06972, -17.688333"
                                }
                            ]
                        },
                        {
                            "Id": "NUBA",
                            "Name": "Numbulwar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.73722, -14.276111",
                            "IataCode": "NUB",
                            "Airports": [
                                {
                                    "Id": "NUB",
                                    "Name": "Numbulwar",
                                    "CityId": "NUBA",
                                    "CountryId": "AU",
                                    "Location": "135.71639, -14.271667"
                                }
                            ]
                        },
                        {
                            "Id": "NYNA",
                            "Name": "Nyngan",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.18333, -31.566667",
                            "IataCode": "NYN",
                            "Airports": [
                                {
                                    "Id": "NYN",
                                    "Name": "Nyngan",
                                    "CityId": "NYNA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "147.18333, -31.566667"
                                }
                            ]
                        },
                        {
                            "Id": "ODDA",
                            "Name": "Oodnadatta",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.46667, -27.55",
                            "IataCode": "ODD",
                            "Airports": [
                                {
                                    "Id": "ODD",
                                    "Name": "Oodnadatta",
                                    "CityId": "ODDA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "135.45, -27.550278"
                                }
                            ]
                        },
                        {
                            "Id": "OKYA",
                            "Name": "Oakey",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.71667, -27.433333",
                            "IataCode": "OKY",
                            "Airports": [
                                {
                                    "Id": "OKY",
                                    "Name": "Oakey",
                                    "CityId": "OKYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.735, -27.406667"
                                }
                            ]
                        },
                        {
                            "Id": "ONSA",
                            "Name": "Onslow",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.1, -21.65",
                            "IataCode": "ONS",
                            "Airports": [
                                {
                                    "Id": "ONS",
                                    "Name": "Onslow",
                                    "CityId": "ONSA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.1, -21.666667"
                                }
                            ]
                        },
                        {
                            "Id": "OOMA",
                            "Name": "Cooma",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.13333, -36.233333",
                            "IataCode": "OOM",
                            "Airports": [
                                {
                                    "Id": "OOM",
                                    "Name": "Cooma",
                                    "CityId": "OOMA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.97333, -36.308333"
                                }
                            ]
                        },
                        {
                            "Id": "OYNA",
                            "Name": "Ouyen",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.31667, -35.066667",
                            "IataCode": "OYN",
                            "Airports": [
                                {
                                    "Id": "OYN",
                                    "Name": "Ouyen",
                                    "CityId": "OYNA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.33333, -35.066667"
                                }
                            ]
                        },
                        {
                            "Id": "PEAA",
                            "Name": "Penneshaw",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.93333, -35.733333",
                            "IataCode": "PEA",
                            "Airports": [
                                {
                                    "Id": "PEA",
                                    "Name": "Penneshaw",
                                    "CityId": "PEAA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.93333, -35.733333"
                                }
                            ]
                        },
                        {
                            "Id": "PEYA",
                            "Name": "Penong",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "133.01667, -31.916667",
                            "IataCode": "PEY",
                            "Airports": [
                                {
                                    "Id": "PEY",
                                    "Name": "Penong",
                                    "CityId": "PEYA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "133.01667, -31.916667"
                                }
                            ]
                        },
                        {
                            "Id": "PKEA",
                            "Name": "Parkes",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.18333, -33.133333",
                            "IataCode": "PKE",
                            "Airports": [
                                {
                                    "Id": "PKE",
                                    "Name": "Parkes",
                                    "CityId": "PKEA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.2425, -33.130833"
                                }
                            ]
                        },
                        {
                            "Id": "PLOA",
                            "Name": "Port Lincoln",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.86667, -34.733333",
                            "IataCode": "PLO",
                            "Airports": [
                                {
                                    "Id": "PLO",
                                    "Name": "Port Lincoln",
                                    "CityId": "PLOA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "135.88056, -34.606111"
                                }
                            ]
                        },
                        {
                            "Id": "PTEA",
                            "Name": "Port Stephens",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.06348, -32.6966",
                            "IataCode": "PTE",
                            "Airports": [
                                {
                                    "Id": "PTE",
                                    "Name": "Port Stephens",
                                    "CityId": "PTEA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.75, -32.783333"
                                }
                            ]
                        },
                        {
                            "Id": "PUGA",
                            "Name": "Port Augusta",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.76667, -32.5",
                            "IataCode": "PUG",
                            "Airports": [
                                {
                                    "Id": "PUG",
                                    "Name": "Port Augusta",
                                    "CityId": "PUGA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.7671, -32.499049"
                                }
                            ]
                        },
                        {
                            "Id": "RBSA",
                            "Name": "Orbost",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "148.45, -37.7",
                            "IataCode": "RBS",
                            "Airports": [
                                {
                                    "Id": "RBS",
                                    "Name": "Orbost",
                                    "CityId": "RBSA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "148.5, -37.7"
                                }
                            ]
                        },
                        {
                            "Id": "RBUA",
                            "Name": "Roebourne",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.13333, -20.783333",
                            "IataCode": "RBU",
                            "Airports": [
                                {
                                    "Id": "RBU",
                                    "Name": "Roebourne",
                                    "CityId": "RBUA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.16667, -20.8"
                                }
                            ]
                        },
                        {
                            "Id": "RCNA",
                            "Name": "American River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.76667, -35.783333",
                            "IataCode": "RCN",
                            "Airports": [
                                {
                                    "Id": "RCN",
                                    "Name": "American River",
                                    "CityId": "RCNA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138, -35.833333"
                                }
                            ]
                        },
                        {
                            "Id": "RMAA",
                            "Name": "Roma",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.78333, -26.583333",
                            "IataCode": "RMA",
                            "Airports": [
                                {
                                    "Id": "RMA",
                                    "Name": "Roma",
                                    "CityId": "RMAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.77306, -26.547222"
                                }
                            ]
                        },
                        {
                            "Id": "RREA",
                            "Name": "Marree",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.06667, -29.65",
                            "IataCode": "RRE",
                            "Airports": [
                                {
                                    "Id": "RRE",
                                    "Name": "Marree",
                                    "CityId": "RREA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.06667, -29.65"
                                }
                            ]
                        },
                        {
                            "Id": "WYAA",
                            "Name": "Whyalla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.58333, -33.033333",
                            "IataCode": "WYA",
                            "Airports": [
                                {
                                    "Id": "WYA",
                                    "Name": "Whyalla",
                                    "CityId": "WYAA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.51862, -33.059608"
                                }
                            ]
                        },
                        {
                            "Id": "SFPA",
                            "Name": "Surfers Paradise",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.41667, -28",
                            "IataCode": "SFP",
                            "Airports": [
                                {
                                    "Id": "SFP",
                                    "Name": "Surfers Paradise",
                                    "CityId": "SFPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153, -28"
                                }
                            ]
                        },
                        {
                            "Id": "SHQA",
                            "Name": "Southport",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.41831, -27.973794",
                            "IataCode": "SHQ",
                            "Airports": [
                                {
                                    "Id": "SHQ",
                                    "Name": "Southport",
                                    "CityId": "SHQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.42778, -27.951944"
                                }
                            ]
                        },
                        {
                            "Id": "SHTA",
                            "Name": "Shepparton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "145.4, -36.383333",
                            "IataCode": "SHT",
                            "Airports": [
                                {
                                    "Id": "SHT",
                                    "Name": "Shepparton",
                                    "CityId": "SHTA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "145.43333, -36.383333"
                                }
                            ]
                        },
                        {
                            "Id": "SIXA",
                            "Name": "Singleton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.18333, -32.566667",
                            "IataCode": "SIX",
                            "Airports": [
                                {
                                    "Id": "SIX",
                                    "Name": "Singleton",
                                    "CityId": "SIXA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.16667, -32.566667"
                                }
                            ]
                        },
                        {
                            "Id": "WYNA",
                            "Name": "Wyndham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.1, -15.466667",
                            "IataCode": "WYN",
                            "Airports": [
                                {
                                    "Id": "WYN",
                                    "Name": "Wyndham",
                                    "CityId": "WYNA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.1375, -15.507222"
                                }
                            ]
                        },
                        {
                            "Id": "SQCA",
                            "Name": "Southern Cross",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.31667, -31.216667",
                            "IataCode": "SQC",
                            "Airports": [
                                {
                                    "Id": "SQC",
                                    "Name": "Southern Cross",
                                    "CityId": "SQCA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.35, -31.25"
                                }
                            ]
                        },
                        {
                            "Id": "SRNA",
                            "Name": "Strahan",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "145.33333, -42.15",
                            "IataCode": "SRN",
                            "Airports": [
                                {
                                    "Id": "SRN",
                                    "Name": "Strahan",
                                    "CityId": "SRNA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "145.31667, -42.15"
                                }
                            ]
                        },
                        {
                            "Id": "SWCA",
                            "Name": "Stawell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.76667, -37.05",
                            "IataCode": "SWC",
                            "Airports": [
                                {
                                    "Id": "SWC",
                                    "Name": "Stawell",
                                    "CityId": "SWCA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.76667, -37.066667"
                                }
                            ]
                        },
                        {
                            "Id": "SWHA",
                            "Name": "Swan Hill",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "143.5544, -35.337814",
                            "IataCode": "SWH",
                            "Airports": [
                                {
                                    "Id": "SWH",
                                    "Name": "Swan Hill",
                                    "CityId": "SWHA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "143.61667, -35.383333"
                                }
                            ]
                        },
                        {
                            "Id": "TAQA",
                            "Name": "Tarcoola",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "134.55, -30.683333",
                            "IataCode": "TAQ",
                            "Airports": [
                                {
                                    "Id": "TAQ",
                                    "Name": "Tarcoola",
                                    "CityId": "TAQA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "134.55, -30.683333"
                                }
                            ]
                        },
                        {
                            "Id": "TCAA",
                            "Name": "Tennant Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.2, -19.65",
                            "IataCode": "TCA",
                            "Airports": [
                                {
                                    "Id": "TCA",
                                    "Name": "Tennant Creek",
                                    "CityId": "TCAA",
                                    "CountryId": "AU",
                                    "Location": "134.18083, -19.638056"
                                }
                            ]
                        },
                        {
                            "Id": "TCWA",
                            "Name": "Tocumwal",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "145.56667, -35.816667",
                            "IataCode": "TCW",
                            "Airports": [
                                {
                                    "Id": "TCW",
                                    "Name": "Tocumwal",
                                    "CityId": "TCWA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "145.6, -35.816667"
                                }
                            ]
                        },
                        {
                            "Id": "TDRA",
                            "Name": "Theodore",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.08333, -24.95",
                            "IataCode": "TDR",
                            "Airports": [
                                {
                                    "Id": "TDR",
                                    "Name": "Theodore",
                                    "CityId": "TDRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.08333, -24.983333"
                                }
                            ]
                        },
                        {
                            "Id": "TEMA",
                            "Name": "Temora",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "147.53333, -34.433333",
                            "IataCode": "TEM",
                            "Airports": [
                                {
                                    "Id": "TEM",
                                    "Name": "Temora",
                                    "CityId": "TEMA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "147.5, -34.416667"
                                }
                            ]
                        },
                        {
                            "Id": "THGA",
                            "Name": "Thangool",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.58333, -24.483333",
                            "IataCode": "THG",
                            "Airports": [
                                {
                                    "Id": "THG",
                                    "Name": "Thangool",
                                    "CityId": "THGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.56667, -24.5"
                                }
                            ]
                        },
                        {
                            "Id": "TKYA",
                            "Name": "Turkey Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.2, -17.033333",
                            "IataCode": "TKY",
                            "Airports": [
                                {
                                    "Id": "TKY",
                                    "Name": "Turkey Creek",
                                    "CityId": "TKYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.25, -17.066667"
                                }
                            ]
                        },
                        {
                            "Id": "TROA",
                            "Name": "Taree",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.46667, -31.9",
                            "IataCode": "TRO",
                            "Airports": [
                                {
                                    "Id": "TRO",
                                    "Name": "Taree",
                                    "CityId": "TROA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.65, -31.816667"
                                }
                            ]
                        },
                        {
                            "Id": "WUNA",
                            "Name": "Wiluna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.21667, -26.6",
                            "IataCode": "WUN",
                            "Airports": [
                                {
                                    "Id": "WUN",
                                    "Name": "Wiluna",
                                    "CityId": "WUNA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.21667, -26.633333"
                                }
                            ]
                        },
                        {
                            "Id": "TUMA",
                            "Name": "Tumut",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.21667, -35.3",
                            "IataCode": "TUM",
                            "Airports": [
                                {
                                    "Id": "TUM",
                                    "Name": "Tumut",
                                    "CityId": "TUMA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.21667, -35.3"
                                }
                            ]
                        },
                        {
                            "Id": "TWBA",
                            "Name": "Toowoomba",
                            "SingleAirportCity": false,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.96667, -27.55",
                            "IataCode": "TWB",
                            "Airports": [
                                {
                                    "Id": "WTB",
                                    "Name": "Brisbane West Wellcamp",
                                    "CityId": "TWBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.79333, -27.558333"
                                },
                                {
                                    "Id": "TWB",
                                    "Name": "Toowoomba",
                                    "CityId": "TWBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.91389, -27.542222"
                                }
                            ]
                        },
                        {
                            "Id": "TWNA",
                            "Name": "Tewantin",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.03333, -26.383333",
                            "IataCode": "TWN",
                            "Airports": [
                                {
                                    "Id": "TWN",
                                    "Name": "Tewantin",
                                    "CityId": "TWNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.05, -26.4"
                                }
                            ]
                        },
                        {
                            "Id": "TYBA",
                            "Name": "Tibooburra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "142.01667, -29.433333",
                            "IataCode": "TYB",
                            "Airports": [
                                {
                                    "Id": "TYB",
                                    "Name": "Tibooburra",
                                    "CityId": "TYBA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "142.06222, -29.456944"
                                }
                            ]
                        },
                        {
                            "Id": "UEEA",
                            "Name": "Queenstown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "145.55, -42.083333",
                            "IataCode": "UEE",
                            "Airports": [
                                {
                                    "Id": "UEE",
                                    "Name": "Queenstown",
                                    "CityId": "UEEA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "145.55, -42.083333"
                                }
                            ]
                        },
                        {
                            "Id": "UIRA",
                            "Name": "Quirindi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.68333, -31.516667",
                            "IataCode": "UIR",
                            "Airports": [
                                {
                                    "Id": "UIR",
                                    "Name": "Quirindi",
                                    "CityId": "UIRA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.51667, -31.5"
                                }
                            ]
                        },
                        {
                            "Id": "ULPA",
                            "Name": "Quilpie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.25, -26.616667",
                            "IataCode": "ULP",
                            "Airports": [
                                {
                                    "Id": "ULP",
                                    "Name": "Quilpie",
                                    "CityId": "ULPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.3, -26.633333"
                                }
                            ]
                        },
                        {
                            "Id": "UMRA",
                            "Name": "Woomera",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "136.8, -31.15",
                            "IataCode": "UMR",
                            "Airports": [
                                {
                                    "Id": "UMR",
                                    "Name": "Woomera",
                                    "CityId": "UMRA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "136.81667, -31.15"
                                }
                            ]
                        },
                        {
                            "Id": "UTBA",
                            "Name": "Muttaburra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.55, -22.6",
                            "IataCode": "UTB",
                            "Airports": [
                                {
                                    "Id": "UTB",
                                    "Name": "Muttaburra",
                                    "CityId": "UTBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.525, -22.5875"
                                }
                            ]
                        },
                        {
                            "Id": "WAUA",
                            "Name": "Wauchope",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "152.73333, -31.45",
                            "IataCode": "WAU",
                            "Airports": [
                                {
                                    "Id": "WAU",
                                    "Name": "Wauchope",
                                    "CityId": "WAUA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "152.75, -31.466667"
                                }
                            ]
                        },
                        {
                            "Id": "WAZA",
                            "Name": "Warwick",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.01667, -28.233333",
                            "IataCode": "WAZ",
                            "Airports": [
                                {
                                    "Id": "WAZ",
                                    "Name": "Warwick",
                                    "CityId": "WAZA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.95, -28.15"
                                }
                            ]
                        },
                        {
                            "Id": "WDIA",
                            "Name": "Wondai",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.86667, -26.316667",
                            "IataCode": "WDI",
                            "Airports": [
                                {
                                    "Id": "WDI",
                                    "Name": "Wondai",
                                    "CityId": "WDIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.81667, -26.333333"
                                }
                            ]
                        },
                        {
                            "Id": "WEWA",
                            "Name": "Wee Waa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "149.43333, -30.233333",
                            "IataCode": "WEW",
                            "Airports": [
                                {
                                    "Id": "WEW",
                                    "Name": "Wee Waa",
                                    "CityId": "WEWA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "149.43333, -30.233333"
                                }
                            ]
                        },
                        {
                            "Id": "WGEA",
                            "Name": "Walgett",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.11667, -30.016667",
                            "IataCode": "WGE",
                            "Airports": [
                                {
                                    "Id": "WGE",
                                    "Name": "Walgett",
                                    "CityId": "WGEA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.13333, -30.033333"
                                }
                            ]
                        },
                        {
                            "Id": "WGTA",
                            "Name": "Wangaratta",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "146.33333, -36.366667",
                            "IataCode": "WGT",
                            "Airports": [
                                {
                                    "Id": "WGT",
                                    "Name": "Wangaratta",
                                    "CityId": "WGTA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "146.33333, -36.366667"
                                }
                            ]
                        },
                        {
                            "Id": "WHLA",
                            "Name": "Welshpool",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "146.43333, -38.666667",
                            "IataCode": "WHL",
                            "Airports": [
                                {
                                    "Id": "WHL",
                                    "Name": "Welshpool",
                                    "CityId": "WHLA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "146.45, -38.688889"
                                }
                            ]
                        },
                        {
                            "Id": "WINA",
                            "Name": "Winton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.03333, -22.4",
                            "IataCode": "WIN",
                            "Airports": [
                                {
                                    "Id": "WIN",
                                    "Name": "Winton",
                                    "CityId": "WINA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.06667, -22.35"
                                }
                            ]
                        },
                        {
                            "Id": "WIOA",
                            "Name": "Wilcannia",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "143.38333, -31.566667",
                            "IataCode": "WIO",
                            "Airports": [
                                {
                                    "Id": "WIO",
                                    "Name": "Wilcannia",
                                    "CityId": "WIOA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "143.55, -31.083333"
                                }
                            ]
                        },
                        {
                            "Id": "WKBA",
                            "Name": "Warracknabeal",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.41667, -36.25",
                            "IataCode": "WKB",
                            "Airports": [
                                {
                                    "Id": "WKB",
                                    "Name": "Warracknabeal",
                                    "CityId": "WKBA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.43333, -36.15"
                                }
                            ]
                        },
                        {
                            "Id": "WLCA",
                            "Name": "Walcha",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.6, -30.983333",
                            "IataCode": "WLC",
                            "Airports": [
                                {
                                    "Id": "WLC",
                                    "Name": "Walcha",
                                    "CityId": "WLCA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.6, -30.983333"
                                }
                            ]
                        },
                        {
                            "Id": "WMBA",
                            "Name": "Warrnambool",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.48333, -38.383333",
                            "IataCode": "WMB",
                            "Airports": [
                                {
                                    "Id": "WMB",
                                    "Name": "Warrnambool",
                                    "CityId": "WMBA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.44833, -38.295"
                                }
                            ]
                        },
                        {
                            "Id": "XMCA",
                            "Name": "Mallacoota",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "149.75, -37.55",
                            "IataCode": "XMC",
                            "Airports": [
                                {
                                    "Id": "XMC",
                                    "Name": "Mallacoota",
                                    "CityId": "XMCA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "149.71667, -37.6"
                                }
                            ]
                        },
                        {
                            "Id": "INJA",
                            "Name": "Injune",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.56667, -25.85",
                            "IataCode": "INJ",
                            "Airports": [
                                {
                                    "Id": "INJ",
                                    "Name": "Injune",
                                    "CityId": "INJA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.53333, -25.883333"
                                }
                            ]
                        },
                        {
                            "Id": "NBRA",
                            "Name": "Nambour",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.95, -26.633333",
                            "IataCode": "NBR",
                            "Airports": [
                                {
                                    "Id": "NBR",
                                    "Name": "Nambour",
                                    "CityId": "NBRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.96667, -26.533333"
                                }
                            ]
                        },
                        {
                            "Id": "WUDA",
                            "Name": "Wudinna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.46667, -33.05",
                            "IataCode": "WUD",
                            "Airports": [
                                {
                                    "Id": "WUD",
                                    "Name": "Wudinna",
                                    "CityId": "WUDA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "135.61944, -33.016667"
                                }
                            ]
                        },
                        {
                            "Id": "LSTA",
                            "Name": "Launceston",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "147.14435, -41.450491",
                            "IataCode": "LST",
                            "Airports": [
                                {
                                    "Id": "LST",
                                    "Name": "Launceston",
                                    "CityId": "LSTA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "147.21, -41.545833"
                                }
                            ]
                        },
                        {
                            "Id": "BXGA",
                            "Name": "Bendigo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "144.28333, -36.766667",
                            "IataCode": "BXG",
                            "Airports": [
                                {
                                    "Id": "BXG",
                                    "Name": "Bendigo",
                                    "CityId": "BXGA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "144.30194, -36.734722"
                                }
                            ]
                        },
                        {
                            "Id": "HLSA",
                            "Name": "St Helens",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "148.23889, -41.320278",
                            "IataCode": "HLS",
                            "Airports": [
                                {
                                    "Id": "HLS",
                                    "Name": "St Helens",
                                    "CityId": "HLSA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "148.25, -41.266667"
                                }
                            ]
                        },
                        {
                            "Id": "OOLA",
                            "Name": "Gold Coast",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.43333, -28",
                            "IataCode": "OOL",
                            "Airports": [
                                {
                                    "Id": "OOL",
                                    "Name": "Gold Coast",
                                    "CityId": "OOLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.48333, -28.183333"
                                }
                            ]
                        },
                        {
                            "Id": "KTAA",
                            "Name": "Karratha",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "116.85471, -20.729144",
                            "IataCode": "KTA",
                            "Airports": [
                                {
                                    "Id": "KTA",
                                    "Name": "Karratha",
                                    "CityId": "KTAA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "116.76667, -20.71"
                                }
                            ]
                        },
                        {
                            "Id": "HISA",
                            "Name": "Hayman Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.88569, -20.056415",
                            "IataCode": "HIS",
                            "Airports": [
                                {
                                    "Id": "HIS",
                                    "Name": "Hayman Island",
                                    "CityId": "HISA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.88075, -20.060285"
                                }
                            ]
                        },
                        {
                            "Id": "XRHA",
                            "Name": "Richmond",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "150.76667, -33.6",
                            "IataCode": "XRH",
                            "Airports": [
                                {
                                    "Id": "XRH",
                                    "Name": "Richmond",
                                    "CityId": "XRHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "150.76889, -33.6"
                                }
                            ]
                        },
                        {
                            "Id": "SXEA",
                            "Name": "Sale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "147.06667, -38.1",
                            "IataCode": "SXE",
                            "Airports": [
                                {
                                    "Id": "SXE",
                                    "Name": "Sale",
                                    "CityId": "SXEA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "147, -38.166667"
                                }
                            ]
                        },
                        {
                            "Id": "LDHA",
                            "Name": "Lord Howe Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "159.08581, -31.55938",
                            "IataCode": "LDH",
                            "Airports": [
                                {
                                    "Id": "LDH",
                                    "Name": "Lord Howe Island",
                                    "CityId": "LDHA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "159.07972, -31.5375"
                                }
                            ]
                        },
                        {
                            "Id": "HRNA",
                            "Name": "Heron Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "151.91285, -23.441868",
                            "IataCode": "HRN",
                            "Airports": [
                                {
                                    "Id": "HRN",
                                    "Name": "Heron Island",
                                    "CityId": "HRNA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "151.91667, -23.433333"
                                }
                            ]
                        },
                        {
                            "Id": "RVTA",
                            "Name": "Ravensthorpe",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.03333, -33.583333",
                            "IataCode": "RVT",
                            "Airports": [
                                {
                                    "Id": "RVT",
                                    "Name": "Ravensthorpe",
                                    "CityId": "RVTA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.11667, -33.6"
                                }
                            ]
                        },
                        {
                            "Id": "GYZA",
                            "Name": "Cosmo Newbery",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "122.9, -28",
                            "IataCode": "GYZ",
                            "Airports": [
                                {
                                    "Id": "GYZ",
                                    "Name": "Gruyere Mine Airport",
                                    "CityId": "GYZA",
                                    "CountryId": "AU",
                                    "Location": "123.21405, -28.033991"
                                }
                            ]
                        },
                        {
                            "Id": "JURA",
                            "Name": "Jurien Bay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.0375, -30.304167",
                            "IataCode": "JUR",
                            "Airports": [
                                {
                                    "Id": "JUR",
                                    "Name": "Jurien Bay",
                                    "CityId": "JURA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.05, -30.266667"
                                }
                            ]
                        },
                        {
                            "Id": "DAJA",
                            "Name": "Dauan Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.51667, -9.4166667",
                            "IataCode": "DAJ",
                            "Airports": [
                                {
                                    "Id": "DAJ",
                                    "Name": "Dauan Island",
                                    "CityId": "DAJA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.53333, -9.433333"
                                }
                            ]
                        },
                        {
                            "Id": "WNRA",
                            "Name": "Windorah",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.65, -25.433333",
                            "IataCode": "WNR",
                            "Airports": [
                                {
                                    "Id": "WNR",
                                    "Name": "Windorah",
                                    "CityId": "WNRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.66667, -25.416667"
                                }
                            ]
                        },
                        {
                            "Id": "SGOA",
                            "Name": "St George",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.58333, -28.033333",
                            "IataCode": "SGO",
                            "Airports": [
                                {
                                    "Id": "SGO",
                                    "Name": "St George",
                                    "CityId": "SGOA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.59389, -28.053333"
                                }
                            ]
                        },
                        {
                            "Id": "WLEA",
                            "Name": "Miles",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "150.18333, -26.666667",
                            "IataCode": "WLE",
                            "Airports": [
                                {
                                    "Id": "WLE",
                                    "Name": "Miles Aerodrome",
                                    "CityId": "WLEA",
                                    "CountryId": "AU",
                                    "Location": "150.1675, -26.808055"
                                }
                            ]
                        },
                        {
                            "Id": "HTUA",
                            "Name": "Hopetoun",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.36667, -35.733333",
                            "IataCode": "HTU",
                            "Airports": [
                                {
                                    "Id": "HTU",
                                    "Name": "Hopetoun",
                                    "CityId": "HTUA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.36667, -35.7"
                                }
                            ]
                        },
                        {
                            "Id": "GFEA",
                            "Name": "Grenfell",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "148.16667, -33.9",
                            "IataCode": "GFE",
                            "Airports": [
                                {
                                    "Id": "GFE",
                                    "Name": "Grenfell",
                                    "CityId": "GFEA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "148.18333, -33.9"
                                }
                            ]
                        },
                        {
                            "Id": "ZBLA",
                            "Name": "Biloela",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.5, -24.416667",
                            "IataCode": "ZBL",
                            "Airports": [
                                {
                                    "Id": "ZBL",
                                    "Name": "Biloela",
                                    "CityId": "ZBLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.5242, -24.401745"
                                }
                            ]
                        },
                        {
                            "Id": "KNSA",
                            "Name": "King Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "143.98333, -39.866667",
                            "IataCode": "KNS",
                            "Airports": [
                                {
                                    "Id": "KNS",
                                    "Name": "King Island",
                                    "CityId": "KNSA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "143.88333, -39.883333"
                                }
                            ]
                        },
                        {
                            "Id": "FLSA",
                            "Name": "Flinders Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "TASMA",
                            "Location": "148.117, -40",
                            "IataCode": "FLS",
                            "Airports": [
                                {
                                    "Id": "FLS",
                                    "Name": "Flinders Island",
                                    "CityId": "FLSA",
                                    "CountryId": "AU",
                                    "RegionId": "TASMA",
                                    "Location": "147.99667, -40.095"
                                }
                            ]
                        },
                        {
                            "Id": "UBBA",
                            "Name": "Mabuiag Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.181, -9.954",
                            "IataCode": "UBB",
                            "Airports": [
                                {
                                    "Id": "UBB",
                                    "Name": "Mabuiag Island",
                                    "CityId": "UBBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.18333, -9.95"
                                }
                            ]
                        },
                        {
                            "Id": "GICA",
                            "Name": "Boigu Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.13, -9.16",
                            "IataCode": "GIC",
                            "Airports": [
                                {
                                    "Id": "GIC",
                                    "Name": "Boigu Island",
                                    "CityId": "GICA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.21667, -9.233333"
                                }
                            ]
                        },
                        {
                            "Id": "XMYA",
                            "Name": "Yam Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.767, -9.9",
                            "IataCode": "XMY",
                            "Airports": [
                                {
                                    "Id": "XMY",
                                    "Name": "Yam Island",
                                    "CityId": "XMYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.45, -10.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SYUA",
                            "Name": "Sue Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.825, -10.207",
                            "IataCode": "SYU",
                            "Airports": [
                                {
                                    "Id": "SYU",
                                    "Name": "Sue Island",
                                    "CityId": "SYUA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.81667, -10.208333"
                                }
                            ]
                        },
                        {
                            "Id": "OKRA",
                            "Name": "Yorke Islands",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.225, -9.84175",
                            "IataCode": "OKR",
                            "Airports": [
                                {
                                    "Id": "OKR",
                                    "Name": "Yorke Island",
                                    "CityId": "OKRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.46667, -9.766667"
                                }
                            ]
                        },
                        {
                            "Id": "BDDA",
                            "Name": "Badu Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.15, -10.117",
                            "IataCode": "BDD",
                            "Airports": [
                                {
                                    "Id": "BDD",
                                    "Name": "Badu Island",
                                    "CityId": "BDDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.18333, -10.15"
                                }
                            ]
                        },
                        {
                            "Id": "CNCA",
                            "Name": "Coconut Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.068, -10.049",
                            "IataCode": "CNC",
                            "Airports": [
                                {
                                    "Id": "CNC",
                                    "Name": "Coconut Island",
                                    "CityId": "CNCA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.06667, -10.05"
                                }
                            ]
                        },
                        {
                            "Id": "NLFA",
                            "Name": "Darnley Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.771, -9.587",
                            "IataCode": "NLF",
                            "Airports": [
                                {
                                    "Id": "NLF",
                                    "Name": "Darnley Island",
                                    "CityId": "NLFA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.78333, -9.583333"
                                }
                            ]
                        },
                        {
                            "Id": "HIDA",
                            "Name": "Horn Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.118, -10.191",
                            "IataCode": "HID",
                            "Airports": [
                                {
                                    "Id": "HID",
                                    "Name": "Horn Island",
                                    "CityId": "HIDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.28333, -10.583333"
                                }
                            ]
                        },
                        {
                            "Id": "MYIA",
                            "Name": "Murray Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.842, -9.933",
                            "IataCode": "MYI",
                            "Airports": [
                                {
                                    "Id": "MYI",
                                    "Name": "Murray Island",
                                    "CityId": "MYIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.05, -9.908333"
                                }
                            ]
                        },
                        {
                            "Id": "KUGA",
                            "Name": "Kubin Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.37, -10.713",
                            "IataCode": "KUG",
                            "Airports": [
                                {
                                    "Id": "KUG",
                                    "Name": "Kubin Island",
                                    "CityId": "KUGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.3, -10.233333"
                                }
                            ]
                        },
                        {
                            "Id": "SBRA",
                            "Name": "Saibai Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.62, -9.39",
                            "IataCode": "SBR",
                            "Airports": [
                                {
                                    "Id": "SBR",
                                    "Name": "Saibai Island",
                                    "CityId": "SBRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.63333, -9.383333"
                                }
                            ]
                        },
                        {
                            "Id": "STFA",
                            "Name": "Stephen Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.545, -9.507",
                            "IataCode": "STF",
                            "Airports": [
                                {
                                    "Id": "STF",
                                    "Name": "Stephen Island",
                                    "CityId": "STFA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.56667, -9.5"
                                }
                            ]
                        },
                        {
                            "Id": "TISA",
                            "Name": "Thursday Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.22, -10.579",
                            "IataCode": "TIS",
                            "Airports": [
                                {
                                    "Id": "TIS",
                                    "Name": "Thursday Island",
                                    "CityId": "TISA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.05, -10.5"
                                }
                            ]
                        },
                        {
                            "Id": "HIHA",
                            "Name": "Hook Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.92326, -20.116504",
                            "IataCode": "HIH",
                            "Airports": [
                                {
                                    "Id": "HIH",
                                    "Name": "Hook Island",
                                    "CityId": "HIHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.95, -20.166667"
                                }
                            ]
                        },
                        {
                            "Id": "HAPA",
                            "Name": "Long Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.85889, -20.358715",
                            "IataCode": "HAP",
                            "Airports": [
                                {
                                    "Id": "HAP",
                                    "Name": "Long Island",
                                    "CityId": "HAPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.75, -20.5"
                                }
                            ]
                        },
                        {
                            "Id": "DDIA",
                            "Name": "Daydream Is",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.81407, -20.255509",
                            "IataCode": "DDI",
                            "Airports": [
                                {
                                    "Id": "DDI",
                                    "Name": "Daydream Is",
                                    "CityId": "DDIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.81667, -20.266667"
                                }
                            ]
                        },
                        {
                            "Id": "LZRA",
                            "Name": "Lizard Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.46429, -14.663437",
                            "IataCode": "LZR",
                            "Airports": [
                                {
                                    "Id": "LZR",
                                    "Name": "Lizard Island",
                                    "CityId": "LZRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.46667, -14.666667"
                                }
                            ]
                        },
                        {
                            "Id": "LDCA",
                            "Name": "Lindeman Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.72884, -20.611982",
                            "IataCode": "LDC",
                            "Airports": [
                                {
                                    "Id": "LDC",
                                    "Name": "Lindeman Island",
                                    "CityId": "LDCA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149, -21"
                                }
                            ]
                        },
                        {
                            "Id": "SOIA",
                            "Name": "South Molle Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.83754, -20.268338",
                            "IataCode": "SOI",
                            "Airports": [
                                {
                                    "Id": "SOI",
                                    "Name": "South Molle Island",
                                    "CityId": "SOIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.58333, -20.75"
                                }
                            ]
                        },
                        {
                            "Id": "DKIA",
                            "Name": "Dunk Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.14524, -17.936908",
                            "IataCode": "DKI",
                            "Airports": [
                                {
                                    "Id": "DKI",
                                    "Name": "Dunk Island",
                                    "CityId": "DKIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.13667, -17.941667"
                                }
                            ]
                        },
                        {
                            "Id": "MCYA",
                            "Name": "Sunshine Coast",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.07013, -26.39557",
                            "IataCode": "MCY",
                            "Airports": [
                                {
                                    "Id": "MCY",
                                    "Name": "Sunshine Coast",
                                    "CityId": "MCYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.09, -26.604167"
                                }
                            ]
                        },
                        {
                            "Id": "HTIA",
                            "Name": "Hamilton Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.94957, -20.33325",
                            "IataCode": "HTI",
                            "Airports": [
                                {
                                    "Id": "HTI",
                                    "Name": "Hamilton Island",
                                    "CityId": "HTIA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.952, -20.3581"
                                }
                            ]
                        },
                        {
                            "Id": "GYLA",
                            "Name": "Argyle",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.65196, -15.804477",
                            "IataCode": "GYL",
                            "Airports": [
                                {
                                    "Id": "GYL",
                                    "Name": "Argyle",
                                    "CityId": "GYLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.45369, -16.640139"
                                }
                            ]
                        },
                        {
                            "Id": "PDNA",
                            "Name": "Parndana",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.25949, -35.79018",
                            "IataCode": "PDN",
                            "Airports": [
                                {
                                    "Id": "PDN",
                                    "Name": "Parndana",
                                    "CityId": "PDNA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.08333, -35.916667"
                                }
                            ]
                        },
                        {
                            "Id": "DXDA",
                            "Name": "Dixie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.25725, -15.6258",
                            "IataCode": "DXD",
                            "Airports": [
                                {
                                    "Id": "DXD",
                                    "Name": "Dixie",
                                    "CityId": "DXDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.66667, -15.166667"
                                }
                            ]
                        },
                        {
                            "Id": "RTSA",
                            "Name": "Rottnest Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.53945, -31.99512",
                            "IataCode": "RTS",
                            "Airports": [
                                {
                                    "Id": "RTS",
                                    "Name": "Rottnest Island",
                                    "CityId": "RTSA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.53667, -32.006944"
                                }
                            ]
                        },
                        {
                            "Id": "BMPA",
                            "Name": "Brampton Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "149.27399, -20.8142",
                            "IataCode": "BMP",
                            "Airports": [
                                {
                                    "Id": "BMP",
                                    "Name": "Brampton Island",
                                    "CityId": "BMPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149.23333, -20.783333"
                                }
                            ]
                        },
                        {
                            "Id": "ODRA",
                            "Name": "Ord River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "128.06997, -17.266382",
                            "IataCode": "ODR",
                            "Airports": [
                                {
                                    "Id": "ODR",
                                    "Name": "Ord River",
                                    "CityId": "ODRA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "128.8, -17.5"
                                }
                            ]
                        },
                        {
                            "Id": "PMKA",
                            "Name": "Palm Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.49582, -18.59597",
                            "IataCode": "PMK",
                            "Airports": [
                                {
                                    "Id": "PMK",
                                    "Name": "Palm Island",
                                    "CityId": "PMKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.66667, -18.333333"
                                }
                            ]
                        },
                        {
                            "Id": "TYGA",
                            "Name": "Eromanga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.26667, -26.666667",
                            "IataCode": "TYG",
                            "Airports": [
                                {
                                    "Id": "TYG",
                                    "Name": "Thylungra",
                                    "CityId": "TYGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.45556, -26.098611"
                                }
                            ]
                        },
                        {
                            "Id": "KYFA",
                            "Name": "Agnew",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.5, -28.083333",
                            "IataCode": "KYF",
                            "Airports": [
                                {
                                    "Id": "KYF",
                                    "Name": "Yeelirrie",
                                    "CityId": "KYFA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.075, -27.277778"
                                }
                            ]
                        },
                        {
                            "Id": "EXMA",
                            "Name": "Exmouth",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.13333, -21.933333",
                            "IataCode": "EXM",
                            "Airports": [
                                {
                                    "Id": "EXM",
                                    "Name": "Exmouth Gulf",
                                    "CityId": "EXMA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.25, -22.25"
                                }
                            ]
                        },
                        {
                            "Id": "HMGA",
                            "Name": "Glen Helen",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.66667, -23.71667",
                            "IataCode": "HMG",
                            "Airports": [
                                {
                                    "Id": "HMG",
                                    "Name": "Hermannsburg",
                                    "CityId": "HMGA",
                                    "CountryId": "AU",
                                    "Location": "132.75, -23.95"
                                }
                            ]
                        },
                        {
                            "Id": "GTEA",
                            "Name": "Alyangula",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.45333, -13.972222",
                            "IataCode": "GTE",
                            "Airports": [
                                {
                                    "Id": "GTE",
                                    "Name": "Groote Eylandt",
                                    "CityId": "GTEA",
                                    "CountryId": "AU",
                                    "Location": "136.45333, -13.972222"
                                }
                            ]
                        },
                        {
                            "Id": "MRTA",
                            "Name": "Mataranka",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.11667, -14.933333",
                            "IataCode": "MRT",
                            "Airports": [
                                {
                                    "Id": "MRT",
                                    "Name": "Moroak",
                                    "CityId": "MRTA",
                                    "CountryId": "AU",
                                    "Location": "133.41667, -14.483333"
                                }
                            ]
                        },
                        {
                            "Id": "OCMA",
                            "Name": "Chencuokeng",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "116.78118, 23.213795",
                            "IataCode": "OCM",
                            "Airports": [
                                {
                                    "Id": "OCM",
                                    "Name": "Boolgeeda",
                                    "CityId": "OCMA",
                                    "CountryId": "AU",
                                    "Location": "117.27333, -22.539721"
                                }
                            ]
                        },
                        {
                            "Id": "HLLA",
                            "Name": "Western Shaw",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.2, -21.816667",
                            "IataCode": "HLL",
                            "Airports": [
                                {
                                    "Id": "HLL",
                                    "Name": "Hillside",
                                    "CityId": "HLLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.33333, -21.783333"
                                }
                            ]
                        },
                        {
                            "Id": "LOAA",
                            "Name": "Waggabundi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.4, -19.5",
                            "IataCode": "LOA",
                            "Airports": [
                                {
                                    "Id": "LOA",
                                    "Name": "Lorraine",
                                    "CityId": "LOAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.9, -19"
                                }
                            ]
                        },
                        {
                            "Id": "RPBA",
                            "Name": "Urapunga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.53333, -14.733333",
                            "IataCode": "RPB",
                            "Airports": [
                                {
                                    "Id": "RPB",
                                    "Name": "Roper Bar",
                                    "CityId": "RPBA",
                                    "CountryId": "AU",
                                    "Location": "134.73333, -14.733333"
                                }
                            ]
                        },
                        {
                            "Id": "HIPA",
                            "Name": "Urandangi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.3, -21.6",
                            "IataCode": "HIP",
                            "Airports": [
                                {
                                    "Id": "HIP",
                                    "Name": "Headingly",
                                    "CityId": "HIPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "138.28333, -21.316667"
                                }
                            ]
                        },
                        {
                            "Id": "MYOA",
                            "Name": "Udialla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "123.75, -17.966667",
                            "IataCode": "MYO",
                            "Airports": [
                                {
                                    "Id": "MYO",
                                    "Name": "Myroodah",
                                    "CityId": "MYOA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "124.26667, -18.116667"
                                }
                            ]
                        },
                        {
                            "Id": "WRWA",
                            "Name": "Twenty Ounce Gully",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.3, -21.2",
                            "IataCode": "WRW",
                            "Airports": [
                                {
                                    "Id": "WRW",
                                    "Name": "Warrawagine",
                                    "CityId": "WRWA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.71667, -20.866667"
                                }
                            ]
                        },
                        {
                            "Id": "CGVA",
                            "Name": "Thundulda",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "126, -32.266667",
                            "IataCode": "CGV",
                            "Airports": [
                                {
                                    "Id": "CGV",
                                    "Name": "Caiguna",
                                    "CityId": "CGVA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "125.46667, -32.266667"
                                }
                            ]
                        },
                        {
                            "Id": "DLVA",
                            "Name": "The Palms",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.71667, -12.566667",
                            "IataCode": "DLV",
                            "Airports": [
                                {
                                    "Id": "DLV",
                                    "Name": "Delissaville",
                                    "CityId": "DLVA",
                                    "CountryId": "AU",
                                    "Location": "130.63333, -12.566667"
                                }
                            ]
                        },
                        {
                            "Id": "MUQA",
                            "Name": "Talga Talga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.81667, -21.016667",
                            "IataCode": "MUQ",
                            "Airports": [
                                {
                                    "Id": "MUQ",
                                    "Name": "Muccan",
                                    "CityId": "MUQA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120, -20.666667"
                                }
                            ]
                        },
                        {
                            "Id": "COYA",
                            "Name": "Station Peak",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "118.18333, -21.166667",
                            "IataCode": "COY",
                            "Airports": [
                                {
                                    "Id": "COY",
                                    "Name": "Coolawanyah",
                                    "CityId": "COYA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.75, -21.85"
                                }
                            ]
                        },
                        {
                            "Id": "PRDA",
                            "Name": "Shellborough",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.36667, -20.016667",
                            "IataCode": "PRD",
                            "Airports": [
                                {
                                    "Id": "PRD",
                                    "Name": "Pardoo",
                                    "CityId": "PRDA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.11667, -20.1"
                                }
                            ]
                        },
                        {
                            "Id": "YLGA",
                            "Name": "Paroo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.55, -26.533333",
                            "IataCode": "YLG",
                            "Airports": [
                                {
                                    "Id": "YLG",
                                    "Name": "Yalgoo",
                                    "CityId": "YLGA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "116.66667, -28.35"
                                }
                            ]
                        },
                        {
                            "Id": "KBDA",
                            "Name": "Old Napier Downs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "124.73333, -17.25",
                            "IataCode": "KBD",
                            "Airports": [
                                {
                                    "Id": "KBD",
                                    "Name": "Kimberley Downs",
                                    "CityId": "KBDA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "124.35, -17.333333"
                                }
                            ]
                        },
                        {
                            "Id": "SWBA",
                            "Name": "North Shaw",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "119.36667, -21.333333",
                            "IataCode": "SWB",
                            "Airports": [
                                {
                                    "Id": "SWB",
                                    "Name": "Shaw River",
                                    "CityId": "SWBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "119.36667, -21.516667"
                                }
                            ]
                        },
                        {
                            "Id": "WNDA",
                            "Name": "Mount Morgans",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "122.06667, -28.766667",
                            "IataCode": "WND",
                            "Airports": [
                                {
                                    "Id": "WND",
                                    "Name": "Windarra",
                                    "CityId": "WNDA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.83333, -28.266667"
                                }
                            ]
                        },
                        {
                            "Id": "GBWA",
                            "Name": "Middle Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "120.26667, -21.883333",
                            "IataCode": "GBW",
                            "Airports": [
                                {
                                    "Id": "GBW",
                                    "Name": "Ginbata",
                                    "CityId": "GBWA",
                                    "CountryId": "AU",
                                    "Location": "119.95847, -22.62491"
                                }
                            ]
                        },
                        {
                            "Id": "GGDA",
                            "Name": "Lilydale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "138.7, -19.016667",
                            "IataCode": "GGD",
                            "Airports": [
                                {
                                    "Id": "GGD",
                                    "Name": "Gregory Downs",
                                    "CityId": "GGDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139.23333, -18.633333"
                                }
                            ]
                        },
                        {
                            "Id": "LERA",
                            "Name": "Lawlers",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.51667, -28.083333",
                            "IataCode": "LER",
                            "Airports": [
                                {
                                    "Id": "LER",
                                    "Name": "Leinster",
                                    "CityId": "LERA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.61667, -27.833333"
                                }
                            ]
                        },
                        {
                            "Id": "OLPA",
                            "Name": "Kingoonya",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "135.3, -30.9",
                            "IataCode": "OLP",
                            "Airports": [
                                {
                                    "Id": "OLP",
                                    "Name": "Olympic Dam",
                                    "CityId": "OLPA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "136.877, -30.485"
                                }
                            ]
                        },
                        {
                            "Id": "RHLA",
                            "Name": "Jimblebar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.18333, -23.466667",
                            "IataCode": "RHL",
                            "Airports": [
                                {
                                    "Id": "RHL",
                                    "Name": "Roy Hill",
                                    "CityId": "RHLA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120, -22.75"
                                }
                            ]
                        },
                        {
                            "Id": "WMEA",
                            "Name": "Ives",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.76667, -26.916667",
                            "IataCode": "WME",
                            "Airports": [
                                {
                                    "Id": "WME",
                                    "Name": "Mount Keith",
                                    "CityId": "WMEA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.54944, -27.287222"
                                }
                            ]
                        },
                        {
                            "Id": "EKDA",
                            "Name": "Hatches Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "135.2, -20.933333",
                            "IataCode": "EKD",
                            "Airports": [
                                {
                                    "Id": "EKD",
                                    "Name": "Elkedra",
                                    "CityId": "EKDA",
                                    "CountryId": "AU",
                                    "Location": "135.55, -21.15"
                                }
                            ]
                        },
                        {
                            "Id": "SNBA",
                            "Name": "Gribbles Settlement",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.66667, -11.416667",
                            "IataCode": "SNB",
                            "Airports": [
                                {
                                    "Id": "SNB",
                                    "Name": "Snake Bay",
                                    "CityId": "SNBA",
                                    "CountryId": "AU",
                                    "Location": "130.66667, -11.416667"
                                }
                            ]
                        },
                        {
                            "Id": "GSNA",
                            "Name": "Gordon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.26667, -32.116667",
                            "IataCode": "GSN",
                            "Airports": [
                                {
                                    "Id": "GSN",
                                    "Name": "Mount Gunson",
                                    "CityId": "GSNA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "137.76667, -31.666667"
                                }
                            ]
                        },
                        {
                            "Id": "WWIA",
                            "Name": "Eastern Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.61667, -21.683333",
                            "IataCode": "WWI",
                            "Airports": [
                                {
                                    "Id": "WWI",
                                    "Name": "Woodie Woodie",
                                    "CityId": "WWIA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "121.23333, -21.633333"
                                }
                            ]
                        },
                        {
                            "Id": "BYPA",
                            "Name": "Devans Find",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "119.4, -22.9",
                            "IataCode": "BYP",
                            "Airports": [
                                {
                                    "Id": "BYP",
                                    "Name": "Barimunya",
                                    "CityId": "BYPA",
                                    "CountryId": "AU",
                                    "Location": "119.16611, -22.673888"
                                }
                            ]
                        },
                        {
                            "Id": "NURA",
                            "Name": "Cook",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "130.41667, -30.616667",
                            "IataCode": "NUR",
                            "Airports": [
                                {
                                    "Id": "NUR",
                                    "Name": "Nullarbor",
                                    "CityId": "NURA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "130.91667, -31.433333"
                                }
                            ]
                        },
                        {
                            "Id": "DGDA",
                            "Name": "Boogardie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "117.78333, -28.033333",
                            "IataCode": "DGD",
                            "Airports": [
                                {
                                    "Id": "DGD",
                                    "Name": "Dalgaranga",
                                    "CityId": "DGDA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "117.30139, -27.818056"
                                }
                            ]
                        },
                        {
                            "Id": "LCNA",
                            "Name": "Blinman",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.66667, -31.1",
                            "IataCode": "LCN",
                            "Airports": [
                                {
                                    "Id": "LCN",
                                    "Name": "Balcanoona",
                                    "CityId": "LCNA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "139.35, -30.516667"
                                }
                            ]
                        },
                        {
                            "Id": "UTDA",
                            "Name": "Birdum",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.21667, -15.65",
                            "IataCode": "UTD",
                            "Airports": [
                                {
                                    "Id": "UTD",
                                    "Name": "Nutwood Downs",
                                    "CityId": "UTDA",
                                    "CountryId": "AU",
                                    "Location": "134.16667, -15.816667"
                                }
                            ]
                        },
                        {
                            "Id": "WOGA",
                            "Name": "Barrow Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.88333, -21.55",
                            "IataCode": "WOG",
                            "Airports": [
                                {
                                    "Id": "WOG",
                                    "Name": "Woodgreen",
                                    "CityId": "WOGA",
                                    "CountryId": "AU",
                                    "Location": "134.2, -22.433333"
                                }
                            ]
                        },
                        {
                            "Id": "SGPA",
                            "Name": "Bamboo Creek",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "120.21667, -20.933333",
                            "IataCode": "SGP",
                            "Airports": [
                                {
                                    "Id": "SGP",
                                    "Name": "Shay Gap",
                                    "CityId": "SGPA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.23333, -20.816667"
                                }
                            ]
                        },
                        {
                            "Id": "MSFA",
                            "Name": "Arltunga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134.68333, -23.433333",
                            "IataCode": "MSF",
                            "Airports": [
                                {
                                    "Id": "MSF",
                                    "Name": "Mount Swan",
                                    "CityId": "MSFA",
                                    "CountryId": "AU",
                                    "Location": "135, -22.516667"
                                }
                            ]
                        },
                        {
                            "Id": "BTDA",
                            "Name": "Allingham",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "136.3, -18.716667",
                            "IataCode": "BTD",
                            "Airports": [
                                {
                                    "Id": "BTD",
                                    "Name": "Brunette Downs",
                                    "CityId": "BTDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "135.91667, -18.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DVRA",
                            "Name": "Adelaide River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.1056, -13.237857",
                            "IataCode": "DVR",
                            "Airports": [
                                {
                                    "Id": "DVR",
                                    "Name": "Daly River",
                                    "CityId": "DVRA",
                                    "CountryId": "AU",
                                    "Location": "130.68333, -13.75"
                                }
                            ]
                        },
                        {
                            "Id": "CKIA",
                            "Name": "Murgenella Settlement",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.925, -11.552778",
                            "IataCode": "CKI",
                            "Airports": [
                                {
                                    "Id": "CKI",
                                    "Name": "Croker Island",
                                    "CityId": "CKIA",
                                    "CountryId": "AU",
                                    "Location": "132.48194, -11.16444"
                                }
                            ]
                        },
                        {
                            "Id": "ENBA",
                            "Name": "Green Head",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "114.96722, -30.071667",
                            "IataCode": "ENB",
                            "Airports": [
                                {
                                    "Id": "ENB",
                                    "Name": "Eneabba West",
                                    "CityId": "ENBA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "114.98333, -30.083333"
                                }
                            ]
                        },
                        {
                            "Id": "LFPA",
                            "Name": "Yarraden",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.21667, -14.466667",
                            "IataCode": "LFP",
                            "Airports": [
                                {
                                    "Id": "LFP",
                                    "Name": "Lakefield",
                                    "CityId": "LFPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.00556, -15.05"
                                }
                            ]
                        },
                        {
                            "Id": "NMPA",
                            "Name": "Woolgar",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.28333, -19.8",
                            "IataCode": "NMP",
                            "Airports": [
                                {
                                    "Id": "NMP",
                                    "Name": "New Moon",
                                    "CityId": "NMPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.96667, -20.166667"
                                }
                            ]
                        },
                        {
                            "Id": "MIHA",
                            "Name": "Womalilla",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.83333, -26.5",
                            "IataCode": "MIH",
                            "Airports": [
                                {
                                    "Id": "MIH",
                                    "Name": "Mitchell Plateau",
                                    "CityId": "MIHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "125.82917, -14.79167"
                                }
                            ]
                        },
                        {
                            "Id": "MLVA",
                            "Name": "Wenlock",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.96667, -13.1",
                            "IataCode": "MLV",
                            "Airports": [
                                {
                                    "Id": "MLV",
                                    "Name": "Merluna",
                                    "CityId": "MLVA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.98333, -13.016667"
                                }
                            ]
                        },
                        {
                            "Id": "WPKA",
                            "Name": "Walsh",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.9, -16.65",
                            "IataCode": "WPK",
                            "Airports": [
                                {
                                    "Id": "WPK",
                                    "Name": "Wrotham Park",
                                    "CityId": "WPKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144, -16.633333"
                                }
                            ]
                        },
                        {
                            "Id": "MUPA",
                            "Name": "Torrowangee",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "141.48333, -31.466667",
                            "IataCode": "MUP",
                            "Airports": [
                                {
                                    "Id": "MUP",
                                    "Name": "Mulga Park",
                                    "CityId": "MUPA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "141.05, -31.133333"
                                }
                            ]
                        },
                        {
                            "Id": "STHA",
                            "Name": "Templeton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.46667, -18.433333",
                            "IataCode": "STH",
                            "Airports": [
                                {
                                    "Id": "STH",
                                    "Name": "Strathmore",
                                    "CityId": "STHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.58333, -17.833333"
                                }
                            ]
                        },
                        {
                            "Id": "LKDA",
                            "Name": "Shiptons Flat",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.25, -15.8",
                            "IataCode": "LKD",
                            "Airports": [
                                {
                                    "Id": "LKD",
                                    "Name": "Lakeland Downs",
                                    "CityId": "LKDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.95, -15.816667"
                                }
                            ]
                        },
                        {
                            "Id": "OSOA",
                            "Name": "Selwyn",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "140.5, -21.533333",
                            "IataCode": "OSO",
                            "Airports": [
                                {
                                    "Id": "OSO",
                                    "Name": "Osborne Mine",
                                    "CityId": "OSOA",
                                    "CountryId": "AU",
                                    "Location": "140.555, -22.081666"
                                }
                            ]
                        },
                        {
                            "Id": "MHUA",
                            "Name": "Rundells",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "147.28333, -37.033333",
                            "IataCode": "MHU",
                            "Airports": [
                                {
                                    "Id": "MHU",
                                    "Name": "Mount Hotham",
                                    "CityId": "MHUA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "147.18333, -36.966667"
                                }
                            ]
                        },
                        {
                            "Id": "SQPA",
                            "Name": "Rossville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.26667, -15.666667",
                            "IataCode": "SQP",
                            "Airports": [
                                {
                                    "Id": "SQP",
                                    "Name": "Starcke",
                                    "CityId": "SQPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.96667, -14.833333"
                                }
                            ]
                        },
                        {
                            "Id": "BIPA",
                            "Name": "Palmerville",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.08333, -15.983333",
                            "IataCode": "BIP",
                            "Airports": [
                                {
                                    "Id": "BIP",
                                    "Name": "Bulimba",
                                    "CityId": "BIPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.75, -16.15"
                                }
                            ]
                        },
                        {
                            "Id": "NMRA",
                            "Name": "Oontoo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.01667, -27.65",
                            "IataCode": "NMR",
                            "Airports": [
                                {
                                    "Id": "NMR",
                                    "Name": "Nappa Merry",
                                    "CityId": "NMRA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.11667, -27.6"
                                }
                            ]
                        },
                        {
                            "Id": "BCKA",
                            "Name": "Mungana",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.4, -17.116667",
                            "IataCode": "BCK",
                            "Airports": [
                                {
                                    "Id": "BCK",
                                    "Name": "Bolwarra",
                                    "CityId": "BCKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.16667, -17.4"
                                }
                            ]
                        },
                        {
                            "Id": "ROHA",
                            "Name": "Mount Surprise",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.31667, -18.15",
                            "IataCode": "ROH",
                            "Airports": [
                                {
                                    "Id": "ROH",
                                    "Name": "Robinhood",
                                    "CityId": "ROHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144, -18.25"
                                }
                            ]
                        },
                        {
                            "Id": "RLPA",
                            "Name": "Mount Garnet",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.11667, -17.683333",
                            "IataCode": "RLP",
                            "Airports": [
                                {
                                    "Id": "RLP",
                                    "Name": "Rosella Plains",
                                    "CityId": "RLPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145, -18"
                                }
                            ]
                        },
                        {
                            "Id": "JHQA",
                            "Name": "Mount Coolon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.33333, -21.383333",
                            "IataCode": "JHQ",
                            "Airports": [
                                {
                                    "Id": "JHQ",
                                    "Name": "Shute Harbour",
                                    "CityId": "JHQA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "147, -22"
                                }
                            ]
                        },
                        {
                            "Id": "MVUA",
                            "Name": "Moojeeba",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.65, -14.083333",
                            "IataCode": "MVU",
                            "Airports": [
                                {
                                    "Id": "MVU",
                                    "Name": "Musgrave",
                                    "CityId": "MVUA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.68333, -14.166667"
                                }
                            ]
                        },
                        {
                            "Id": "DHDA",
                            "Name": "Mooga",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.93333, -26.266667",
                            "IataCode": "DHD",
                            "Airports": [
                                {
                                    "Id": "DHD",
                                    "Name": "Durham Downs",
                                    "CityId": "DHDA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149.08333, -26.1"
                                }
                            ]
                        },
                        {
                            "Id": "SCGA",
                            "Name": "Minhamite",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.35, -37.983333",
                            "IataCode": "SCG",
                            "Airports": [
                                {
                                    "Id": "SCG",
                                    "Name": "Spring Creek",
                                    "CityId": "SCGA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.41667, -38.016667"
                                }
                            ]
                        },
                        {
                            "Id": "BHTA",
                            "Name": "Middleton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.53333, -22.366667",
                            "IataCode": "BHT",
                            "Airports": [
                                {
                                    "Id": "BHT",
                                    "Name": "Brighton Downs",
                                    "CityId": "BHTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.56667, -23.366667"
                                }
                            ]
                        },
                        {
                            "Id": "IKPA",
                            "Name": "Mentana",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.41667, -16.25",
                            "IataCode": "IKP",
                            "Airports": [
                                {
                                    "Id": "IKP",
                                    "Name": "Inkerman",
                                    "CityId": "IKPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.5, -16.25"
                                }
                            ]
                        },
                        {
                            "Id": "MOOA",
                            "Name": "Meandarra",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "149.88333, -27.333333",
                            "IataCode": "MOO",
                            "Airports": [
                                {
                                    "Id": "MOO",
                                    "Name": "Moomba",
                                    "CityId": "MOOA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "149.83333, -27.5"
                                }
                            ]
                        },
                        {
                            "Id": "GVPA",
                            "Name": "Maytown",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.28333, -16.05",
                            "IataCode": "GVP",
                            "Airports": [
                                {
                                    "Id": "GVP",
                                    "Name": "Greenvale",
                                    "CityId": "GVPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.61667, -15.783333"
                                }
                            ]
                        },
                        {
                            "Id": "UDAA",
                            "Name": "Lyndbrook",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.43333, -17.85",
                            "IataCode": "UDA",
                            "Airports": [
                                {
                                    "Id": "UDA",
                                    "Name": "Undarra",
                                    "CityId": "UDAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.6, -18.183333"
                                }
                            ]
                        },
                        {
                            "Id": "HNKA",
                            "Name": "Lucinda",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.33333, -18.516667",
                            "IataCode": "HNK",
                            "Airports": [
                                {
                                    "Id": "HNK",
                                    "Name": "Hinchinbrook Island",
                                    "CityId": "HNKA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.28333, -18.383333"
                                }
                            ]
                        },
                        {
                            "Id": "NLKN",
                            "Name": "Longridge Station",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "167.93333, -29.05",
                            "IataCode": "NLK",
                            "Airports": [
                                {
                                    "Id": "NLK",
                                    "Name": "Norfolk Island",
                                    "CityId": "NLKN",
                                    "CountryId": "AU",
                                    "Location": "167.93944, -29.0425"
                                }
                            ]
                        },
                        {
                            "Id": "CFPA",
                            "Name": "Kidston",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.16667, -18.866667",
                            "IataCode": "CFP",
                            "Airports": [
                                {
                                    "Id": "CFP",
                                    "Name": "Carpentaria Downs",
                                    "CityId": "CFPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.35, -18.783333"
                                }
                            ]
                        },
                        {
                            "Id": "BVWA",
                            "Name": "Iron Range",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.3, -12.7",
                            "IataCode": "BVW",
                            "Airports": [
                                {
                                    "Id": "BVW",
                                    "Name": "Batavia Downs",
                                    "CityId": "BVWA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.21667, -12.65"
                                }
                            ]
                        },
                        {
                            "Id": "CRHA",
                            "Name": "Inverramsay",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.21667, -28.016667",
                            "IataCode": "CRH",
                            "Airports": [
                                {
                                    "Id": "CRH",
                                    "Name": "Cherribah",
                                    "CityId": "CRHA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.16667, -28"
                                }
                            ]
                        },
                        {
                            "Id": "MRLA",
                            "Name": "Homestead",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.66667, -20.366667",
                            "IataCode": "MRL",
                            "Airports": [
                                {
                                    "Id": "MRL",
                                    "Name": "Miners Lake",
                                    "CityId": "MRLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "145.58333, -20.066667"
                                }
                            ]
                        },
                        {
                            "Id": "CUGA",
                            "Name": "Gundy",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151, -32.016667",
                            "IataCode": "CUG",
                            "Airports": [
                                {
                                    "Id": "CUG",
                                    "Name": "Cudal",
                                    "CityId": "CUGA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151, -32"
                                }
                            ]
                        },
                        {
                            "Id": "ABGA",
                            "Name": "Gilbert River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.86667, -18.15",
                            "IataCode": "ABG",
                            "Airports": [
                                {
                                    "Id": "ABG",
                                    "Name": "Abingdon",
                                    "CityId": "ABGA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.2, -17.666667"
                                }
                            ]
                        },
                        {
                            "Id": "TWPA",
                            "Name": "Fossilbrook",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "144.38333, -17.816667",
                            "IataCode": "TWP",
                            "Airports": [
                                {
                                    "Id": "TWP",
                                    "Name": "Torwood",
                                    "CityId": "TWPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.75, -17.366667"
                                }
                            ]
                        },
                        {
                            "Id": "BZPA",
                            "Name": "Endeavour",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.08333, -15.416667",
                            "IataCode": "BZP",
                            "Airports": [
                                {
                                    "Id": "BZP",
                                    "Name": "Bizant",
                                    "CityId": "BZPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.63333, -15.216667"
                                }
                            ]
                        },
                        {
                            "Id": "SSPA",
                            "Name": "Ebagoola",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "143.26667, -14.316667",
                            "IataCode": "SSP",
                            "Airports": [
                                {
                                    "Id": "SSP",
                                    "Name": "Silver Plains",
                                    "CityId": "SSPA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "143.55, -13.983333"
                                }
                            ]
                        },
                        {
                            "Id": "HAAA",
                            "Name": "Donaldson",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140.36667, -19.866667",
                            "IataCode": "HAT",
                            "Airports": [
                                {
                                    "Id": "HAT",
                                    "Name": "Heathlands",
                                    "CityId": "HAAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.58333, -19.720833"
                                }
                            ]
                        },
                        {
                            "Id": "KMLA",
                            "Name": "Dobbyn",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "140, -19.8",
                            "IataCode": "KML",
                            "Airports": [
                                {
                                    "Id": "KML",
                                    "Name": "Kamileroi",
                                    "CityId": "KMLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "140.05, -19.366667"
                                }
                            ]
                        },
                        {
                            "Id": "MFLA",
                            "Name": "Clarke River",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "145.45, -19.216667",
                            "IataCode": "MFL",
                            "Airports": [
                                {
                                    "Id": "MFL",
                                    "Name": "Mount Full Stop",
                                    "CityId": "MFLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "144.9, -19.666667"
                                }
                            ]
                        },
                        {
                            "Id": "ORSA",
                            "Name": "Cassidy",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "146.33333, -18.633333",
                            "IataCode": "ORS",
                            "Airports": [
                                {
                                    "Id": "ORS",
                                    "Name": "Orpheus Island Resort",
                                    "CityId": "ORSA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "146.48333, -18.633333"
                                }
                            ]
                        },
                        {
                            "Id": "WANA",
                            "Name": "Canterbury",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "141.88333, -25.383333",
                            "IataCode": "WAN",
                            "Airports": [
                                {
                                    "Id": "WAN",
                                    "Name": "Waverney",
                                    "CityId": "WANA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "141.93333, -25.366667"
                                }
                            ]
                        },
                        {
                            "Id": "LYTA",
                            "Name": "Burnett Heads",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "152.4, -24.766667",
                            "IataCode": "LYT",
                            "Airports": [
                                {
                                    "Id": "LYT",
                                    "Name": "Lady Elliot Island",
                                    "CityId": "LYTA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "152.7, -24.116667"
                                }
                            ]
                        },
                        {
                            "Id": "MBFA",
                            "Name": "Brookside",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "146.88333, -36.733333",
                            "IataCode": "MBF",
                            "Airports": [
                                {
                                    "Id": "MBF",
                                    "Name": "Mount Buffalo",
                                    "CityId": "MBFA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "146.75, -36.75"
                                }
                            ]
                        },
                        {
                            "Id": "RBCA",
                            "Name": "Bannerton",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "VICTO",
                            "Location": "142.78333, -34.7",
                            "IataCode": "RBC",
                            "Airports": [
                                {
                                    "Id": "RBC",
                                    "Name": "Robinvale",
                                    "CityId": "RBCA",
                                    "CountryId": "AU",
                                    "RegionId": "VICTO",
                                    "Location": "142.75, -34.666667"
                                }
                            ]
                        },
                        {
                            "Id": "DYAA",
                            "Name": "Anakie",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "147.75, -23.55",
                            "IataCode": "DYA",
                            "Airports": [
                                {
                                    "Id": "DYA",
                                    "Name": "Dysart",
                                    "CityId": "DYAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148, -23.5"
                                }
                            ]
                        },
                        {
                            "Id": "SVMA",
                            "Name": "Wanetta",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "142.16028, -10.566944",
                            "IataCode": "SVM",
                            "Airports": [
                                {
                                    "Id": "SVM",
                                    "Name": "St Paul's Mission",
                                    "CityId": "SVMA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "142.11667, -10.366667"
                                }
                            ]
                        },
                        {
                            "Id": "AWPA",
                            "Name": "Wonardo",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "137.8, -19.96667",
                            "IataCode": "AWP",
                            "Airports": [
                                {
                                    "Id": "AWP",
                                    "Name": "Austral Downs",
                                    "CityId": "AWPA",
                                    "CountryId": "AU",
                                    "Location": "137.75, -20.5"
                                }
                            ]
                        },
                        {
                            "Id": "MVKA",
                            "Name": "Poonarunna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.55, -27.83333",
                            "IataCode": "MVK",
                            "Airports": [
                                {
                                    "Id": "MVK",
                                    "Name": "Mulka",
                                    "CityId": "MVKA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138, -27.25"
                                }
                            ]
                        },
                        {
                            "Id": "CWRA",
                            "Name": "Old Berlino",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.4, -27.8",
                            "IataCode": "CWR",
                            "Airports": [
                                {
                                    "Id": "CWR",
                                    "Name": "Cowarie",
                                    "CityId": "CWRA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.33333, -27.716667"
                                }
                            ]
                        },
                        {
                            "Id": "MWTA",
                            "Name": "Murrapatirinna",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.53333, -28.45",
                            "IataCode": "MWT",
                            "Airports": [
                                {
                                    "Id": "MWT",
                                    "Name": "Moolawatana",
                                    "CityId": "MWTA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138, -28.5"
                                }
                            ]
                        },
                        {
                            "Id": "GTSA",
                            "Name": "Lambina",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "134.2, -26.93333",
                            "IataCode": "GTS",
                            "Airports": [
                                {
                                    "Id": "GTS",
                                    "Name": "Granites",
                                    "CityId": "GTSA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "133.61667, -26.966667"
                                }
                            ]
                        },
                        {
                            "Id": "RTYA",
                            "Name": "Lake Hope",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "139.25, -28.4",
                            "IataCode": "RTY",
                            "Airports": [
                                {
                                    "Id": "RTY",
                                    "Name": "Merty",
                                    "CityId": "RTYA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "139, -28.25"
                                }
                            ]
                        },
                        {
                            "Id": "ETDA",
                            "Name": "Kalamurina",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "137.98333, -27.91667",
                            "IataCode": "ETD",
                            "Airports": [
                                {
                                    "Id": "ETD",
                                    "Name": "Etadunna",
                                    "CityId": "ETDA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138, -27.5"
                                }
                            ]
                        },
                        {
                            "Id": "BRTA",
                            "Name": "Fort Dundas",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.43333, -11.41667",
                            "IataCode": "BRT",
                            "Airports": [
                                {
                                    "Id": "BRT",
                                    "Name": "Bathurst Island",
                                    "CityId": "BRTA",
                                    "CountryId": "AU",
                                    "Location": "130.63333, -11.766667"
                                }
                            ]
                        },
                        {
                            "Id": "ANZA",
                            "Name": "Curtin Springs",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.75, -25.33333",
                            "IataCode": "ANZ",
                            "Airports": [
                                {
                                    "Id": "ANZ",
                                    "Name": "Angus Downs",
                                    "CityId": "ANZA",
                                    "CountryId": "AU",
                                    "Location": "132.16667, -25.083333"
                                }
                            ]
                        },
                        {
                            "Id": "MNEA",
                            "Name": "Berlino",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "138.58333, -27.6",
                            "IataCode": "MNE",
                            "Airports": [
                                {
                                    "Id": "MNE",
                                    "Name": "Mungeranie",
                                    "CityId": "MNEA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "138.6, -28"
                                }
                            ]
                        },
                        {
                            "Id": "DLKA",
                            "Name": "Tinga Tingana",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "140.16667, -28.8",
                            "IataCode": "DLK",
                            "Airports": [
                                {
                                    "Id": "DLK",
                                    "Name": "Dulkaninna",
                                    "CityId": "DLKA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "139.46667, -29.016667"
                                }
                            ]
                        },
                        {
                            "Id": "GPNA",
                            "Name": "Pularumpi",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "130.42289, -11.40595",
                            "IataCode": "GPN",
                            "Airports": [
                                {
                                    "Id": "GPN",
                                    "Name": "Garden Point",
                                    "CityId": "GPNA",
                                    "CountryId": "AU",
                                    "Location": "130.01667, -11.766667"
                                }
                            ]
                        },
                        {
                            "Id": "JADA",
                            "Name": "Canning Vale",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "115.91814, -32.05798",
                            "IataCode": "JAD",
                            "Airports": [
                                {
                                    "Id": "JAD",
                                    "Name": "Jandakot",
                                    "CityId": "JADA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "115.88333, -32.083333"
                                }
                            ]
                        },
                        {
                            "Id": "KYIA",
                            "Name": "Yalata",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "SOU_A",
                            "Location": "131.84242, -31.48023",
                            "IataCode": "KYI",
                            "Airports": [
                                {
                                    "Id": "KYI",
                                    "Name": "Yalata Mission",
                                    "CityId": "KYIA",
                                    "CountryId": "AU",
                                    "RegionId": "SOU_A",
                                    "Location": "131.86667, -31.483333"
                                }
                            ]
                        },
                        {
                            "Id": "SRRA",
                            "Name": "Alamorosa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "-105.99221, 33.005642",
                            "IataCode": "SRR",
                            "Airports": [
                                {
                                    "Id": "SRR",
                                    "Name": "Stradbroke Island",
                                    "CityId": "SRRA",
                                    "CountryId": "AU",
                                    "Location": "-106, 33"
                                }
                            ]
                        },
                        {
                            "Id": "PBYA",
                            "Name": "Shute Harbour",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "148.7613, -20.2856",
                            "IataCode": "PBY",
                            "Airports": [
                                {
                                    "Id": "PBY",
                                    "Name": "Peppers Palm Bay",
                                    "CityId": "PBYA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "148.83333, -20.283333"
                                }
                            ]
                        },
                        {
                            "Id": "MQAA",
                            "Name": "Eighty Mile Beach",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "121.08368, -19.7679",
                            "IataCode": "MQA",
                            "Airports": [
                                {
                                    "Id": "MQA",
                                    "Name": "Mandora",
                                    "CityId": "MQAA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "120.83333, -19.75"
                                }
                            ]
                        },
                        {
                            "Id": "CDAA",
                            "Name": "Kakadu",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "132.52191, -12.90539",
                            "IataCode": "CDA",
                            "Airports": [
                                {
                                    "Id": "CDA",
                                    "Name": "Cooinda",
                                    "CityId": "CDAA",
                                    "CountryId": "AU",
                                    "Location": "132.5225, -12.905278"
                                }
                            ]
                        },
                        {
                            "Id": "KCSA",
                            "Name": "Petermann",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.5881, -24.252119",
                            "IataCode": "KCS",
                            "Airports": [
                                {
                                    "Id": "KCS",
                                    "Name": "Kings Creek Station",
                                    "CityId": "KCSA",
                                    "CountryId": "AU",
                                    "Location": "131.68333, -24.383333"
                                }
                            ]
                        },
                        {
                            "Id": "PHJA",
                            "Name": "Newcastle West",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "NEW_S",
                            "Location": "151.766, -32.927",
                            "IataCode": "PHJ",
                            "Airports": [
                                {
                                    "Id": "PHJ",
                                    "Name": "Port Hunter",
                                    "CityId": "PHJA",
                                    "CountryId": "AU",
                                    "RegionId": "NEW_S",
                                    "Location": "151.76667, -32.916667"
                                }
                            ]
                        },
                        {
                            "Id": "OKBA",
                            "Name": "Fraser Island",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "153.03112, -25.39246",
                            "IataCode": "OKB",
                            "Airports": [
                                {
                                    "Id": "OKB",
                                    "Name": "Orchid Beach",
                                    "CityId": "OKBA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "153.16667, -25.25"
                                }
                            ]
                        },
                        {
                            "Id": "EDDA",
                            "Name": "Ghan",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "133.77514, -25.274398",
                            "IataCode": "EDD",
                            "Airports": [
                                {
                                    "Id": "EDD",
                                    "Name": "Erldunda",
                                    "CityId": "EDDA",
                                    "CountryId": "AU",
                                    "Location": "133.2, -25.25"
                                }
                            ]
                        },
                        {
                            "Id": "HRYA",
                            "Name": "Hugh",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "134, -25",
                            "IataCode": "HRY",
                            "Airports": [
                                {
                                    "Id": "HRY",
                                    "Name": "Henbury",
                                    "CityId": "HRYA",
                                    "CountryId": "AU",
                                    "Location": "133.25, -24.583333"
                                }
                            ]
                        },
                        {
                            "Id": "WITA",
                            "Name": "Karijini",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "WESTE",
                            "Location": "118.25841, -22.38661",
                            "IataCode": "WIT",
                            "Airports": [
                                {
                                    "Id": "WIT",
                                    "Name": "Wittenoom",
                                    "CityId": "WITA",
                                    "CountryId": "AU",
                                    "RegionId": "WESTE",
                                    "Location": "118.33333, -22.266667"
                                }
                            ]
                        },
                        {
                            "Id": "AYQA",
                            "Name": "Mutitjulu",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.05, -25.333333",
                            "IataCode": "AYQ",
                            "Airports": [
                                {
                                    "Id": "AYQ",
                                    "Name": "Connellan",
                                    "CityId": "AYQA",
                                    "CountryId": "AU",
                                    "Location": "131.05, -25.333333"
                                }
                            ]
                        },
                        {
                            "Id": "OBAA",
                            "Name": "Town View",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "139.50784, -20.72615",
                            "IataCode": "OBA",
                            "Airports": [
                                {
                                    "Id": "OBA",
                                    "Name": "Oban",
                                    "CityId": "OBAA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "139, -21"
                                }
                            ]
                        },
                        {
                            "Id": "GKLA",
                            "Name": "The Keppels",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "RegionId": "QUEEN",
                            "Location": "150.9437, -23.19316",
                            "IataCode": "GKL",
                            "Airports": [
                                {
                                    "Id": "GKL",
                                    "Name": "Great Keppel Island",
                                    "CityId": "GKLA",
                                    "CountryId": "AU",
                                    "RegionId": "QUEEN",
                                    "Location": "150.94167, -23.183333"
                                }
                            ]
                        },
                        {
                            "Id": "BYXA",
                            "Name": "Anindilyakwa",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "136.66667, -14",
                            "IataCode": "BYX",
                            "Airports": [
                                {
                                    "Id": "BYX",
                                    "Name": "Baniyala",
                                    "CityId": "BYXA",
                                    "CountryId": "AU",
                                    "Location": "136.23333, -13.2"
                                }
                            ]
                        },
                        {
                            "Id": "KBJA",
                            "Name": "Kings Canyon",
                            "SingleAirportCity": true,
                            "CountryId": "AU",
                            "Location": "131.49444, -24.25",
                            "IataCode": "KBJ",
                            "Airports": [
                                {
                                    "Id": "KBJ",
                                    "Name": "Kings Canyon",
                                    "CityId": "KBJA",
                                    "CountryId": "AU",
                                    "Location": "131.49444, -24.25"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}

  /******End*****/
  constructor(private accountService: AccountService,
    private modalService: NgbModal,
    private router: Router
      ) {
        // setInterval(() => {
        //   this.now = new Date();
        // }, 1);
       }

  ngOnInit(): void {
    this.getUserDetails();
    this.completeApplication();
    this.getCreditLimit();
    this.getUserLocation(); 
    //this.getDestinationData();
    this.accountService.getCurrentApplicationStatus().subscribe(response => {

      if ((response.currentStatus == "IN-PRINCIPAL_APPROVED") || (response.currentStatus == "PARTIAL_KYC_DOCS_UPLOADED")
        || (response.currentStatus == "REGISTERED") || (response.currentStatus == "IN_PROCESS")) {
        this.shwUploadedDocs = true;
      }
    })
  }

  getCurrentApplication() {
    this.accountService.getCurrentApplicationStatus().subscribe(response => {
      console.log(response.applicationCode);
      switch (response.currentStatus.toLowerCase()) {
        case ApplicationStatus.SIGNUP: {
         this.router.navigateByUrl('/application/appl/(application:personal)');
          break;
        }
        case ApplicationStatus.INCOMPLETE_REGISTRATION: {
         this.router.navigateByUrl('/application/appl/(application:professional)');
          break;
        }
        case ApplicationStatus.PARTIAL_KYC_DOCS_UPLOADED: 
        case ApplicationStatus.ALL_KYC_DOCS_UPLOADED: {
          this.router.navigateByUrl('/application/appl/(application:uploaddocs)');
          break;
        }
        case ApplicationStatus.LOAN_ELIGIBILITY_DENIED:
        case ApplicationStatus.DENIED:
        case ApplicationStatus.CREDIT_PULL_FAIL_DENIED:
        case ApplicationStatus.IN_PRINCIPAL_DENIED:
        case ApplicationStatus.DEMOGRAPHIC_DENIED:
        case ApplicationStatus.TRAVEL_LOAN_ELIGIBILITY_NO:
        case ApplicationStatus.SPLITFARE_ELIGIBILITY_NO:
        case ApplicationStatus.AUTO_ID_VERIFICATION_DENIED:
        case ApplicationStatus.FINAL_DENIED_MIHURU: {
          this.router.navigateByUrl('/sorry)');
          break;
        }
        case ApplicationStatus.IN_PRINCIPAL_APPROVED:
        case ApplicationStatus.SPLITFARE_ELIGIBILITY_YES:
        case ApplicationStatus.TRAVEL_LOAN_ELIGIBILITY_YES:
        case ApplicationStatus.FINAL_APPROVED_MIHURU:
        case ApplicationStatus.FINAL_APPROVED_LENDING_PARTNER: {
          this.router.navigateByUrl('/congratulations)');
          break;
        }
        case ApplicationStatus.CALCULATE_IN_PRINCIPAL_LIMIT: {
          this.router.navigateByUrl('/thankyou)');
          break;
        }
        case ApplicationStatus.CREDIT_PULL_FAIL:
        case ApplicationStatus.AUTO_BANK_STATEMENT_VERIFICATION_DENIED:
        case ApplicationStatus.IN_PROCESS:
        case ApplicationStatus.AUTO_BUSINESS_VERIFICATION_DENIED:
        case ApplicationStatus.REGISTER:
        case ApplicationStatus.BUREAU_DATA_MISMATCH: 
        case ApplicationStatus.KYC_VERIFIED:
        case ApplicationStatus.DEVIATION:
        case ApplicationStatus.L2_APPROVED:
        case ApplicationStatus.AUTO_DENIED:
        case ApplicationStatus.AUTO_ADDRESS_VERIFICATION_DENIED:
        case ApplicationStatus.CREDIT_RULE_ENGINE_II_DENIED:
        case ApplicationStatus.AUTO_EMPLOYMENT_VERIFICATION_DENIED:
        case ApplicationStatus.KYC_UNDER_VERIFICATION:
        case ApplicationStatus.AUTO_APPROVED:
        case ApplicationStatus.L2_DENIED:{
            this.router.navigateByUrl('/pleasewait)');
            break;
          }
        default:
          this.router.navigateByUrl('/dashboard');
      }
    }, error => {
      console.log(error);
    });
  }

getDestinationData(){
    this.accountService.getDestinationDataById().subscribe(res =>{
      this.destinationData = res["data"]["data"];
      this.getTempAndWeather(this.destinationData);
      this.cheapestFlight(this.destinationData);
    });
    
  }
    getUserLocation() {
        this.accountService.getCurrentLocation().subscribe(response => {
            this.location = response;
            this.accountService.getUserBookingFlightDetails().subscribe(response => {
                if (response["data"].destinations != "") {
                    this.flightPrice = response["data"].flightPrice;
                    let tenor = response["data"].tenor;
                    this.emiValue = this.getEmiValue(this.flightPrice, tenor);
                    let countryId, currencyCode;
                    let locationArr;
                    for (let i = 0; i < this.airportList.Continents.length; i++) {
                        for (let c = 0; c < this.airportList.Continents[i].Countries.length; c++) {
                            for (let ci = 0; ci < this.airportList.Continents[i].Countries[c].Cities.length; ci++) {
                                for (let a = 0; a < this.airportList.Continents[i].Countries[c].Cities[ci].Airports.length; a++) {
                                    if (this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].Id == response["data"].destinations) {
                                        countryId = this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].CountryId;
                                        locationArr = this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].Location.split(",");
                                        this.cityName = this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].Name;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 0; i < this.airportList.Continents.length; i++) {
                        for (let c = 0; c < this.airportList.Continents[i].Countries.length; c++) {
                            if (this.airportList.Continents[i].Countries[c].Id == countryId) {
                                currencyCode = this.airportList.Continents[i].Countries[c].CurrencyId;
                            }
                        }
                    }
                    let countryObj = {
                        city: this.cityName,
                        currencyCode: this.location.currency,
                        currencyCodeDestination: currencyCode,
                        countryCode: countryId,
                        latitude: locationArr[1],
                        longitude: locationArr[0]
                    }
                    this.getTempAndWeather(countryObj);
                }
                else {
                    this.cheapestFlight(this.location)
                }
            });
        });
    }
    FindMe(){
      geoFindMe();
    }

 /* getTempAndWeather(location) {
    this.accountService.getDestinationFacts({
      CityName: location.city,
      CurrencyCode: location.currency,
      CountryCode: location.country_code

    }).subscribe(response => {
      this.response = response;
      //this.rates = Math.round(this.response.data.data.rates);
      var rate =this.response.data.data.rates;
      for (var key in rate) {

        this.rates = Math.round(rate[key]);
      }
      this.temp = this.response.data.data.temp;

      this.convert(this.temp);

      
      });
  }*/
 
  getTempAndWeather(location) {
    this.accountService.getDestinationFacts({
      CityName: location.city,
      CurrencyCode: location.currencyCode,
      CountryCode: location.countryCode,
      CurrencyCodeDestination: location.currencyCodeDestination,
      Latitude: location.latitude,
      Longitude: location.longitude

    }).subscribe(response => {
      this.response = response;
      var rate =this.response.data.data.rates;
      let symbol,symbolDestination;
      var rateDestination =this.response.data.data.ratesDestination;
      for (var key in rate) {
        this.rates = rate[key].toFixed(2);
        symbol = key
      }
      for (var key in rateDestination) {
        this.ratesDestination =rateDestination[key].toFixed(2);
        symbolDestination = key
      }
      if(this.rates > this.ratesDestination){
        this.rates = (this.rates / this.ratesDestination).toFixed(2);
        this.rates = "1 " +  symbol +" = " + this.rates +" "+ symbolDestination;
      }
      else{
        this.rates = (this.rates / this.ratesDestination).toFixed(2);
        this.rates = "1 " + symbolDestination +" = " + this.rates + " " +symbol;
      }
      this.temp = this.response.data.data.temp;
      this.convert(this.temp);
      
      this.now = this.response.data.data.formatted +" GMT "+ this.response.data.data.gmt;
      
      });

  }

  convert(temp) {
    // this.tempMin = Math.round((temp.temp_min - 273.15));
    // this.tempMax = Math.round((temp.temp_max - 273.15));
    this.tempMin = Math.round((temp.temp_min));
    this.tempMax = Math.round((temp.temp_max));
  }

  knowMore(){
     this.knowMoreData = true;
    this.accountService.getSaticContent()
    .subscribe(res => {
      console.log("staticContent" , res);
      this.staticContent = res["data"]["data"];
      console.log("staticContent" , this.staticContent);
    })
  }

  cheapestFlight(data){
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();

    // var originDate =  yyyy + '-' + mm + '-' + dd ;

    var CheapestFlightRequest = {
      //CityName:data.city,
      // CityName:data.name,
      IP: data.ip,
      CurrencyCode:data.currency,
      //CountryCode:data.country_code,
      CountryCode:data.country_code,
      Locales:navigator.languages[0],
      //Origin: data.country_code + "-sky",
      CountryName: data.country_name,
      // OriginDate:originDate
    }
    //console.log(CheapestFlightRequest)
    this.accountService.getCheapestFlight(CheapestFlightRequest).subscribe(
      res=>{
        if (res["data"].status != "SUCCESS") {
          this.showMsg = true;
         // this.emiValue = "No flights available";
        }
        else {
          this.cityName = res["data"].data.cityName;
          this.flightPrice = res["data"].data.minPrice;
          let tenor = res["data"].data.tenor
          this.emiValue = this.getEmiValue(this.flightPrice,tenor);
          let countryId, currencyCode;
          let locationArr;
          for(let i=0;i<this.airportList.Continents.length;i++){
            for(let c=0;c<this.airportList.Continents[i].Countries.length;c++){
              for(let ci=0;ci<this.airportList.Continents[i].Countries[c].Cities.length;ci++){
                for(let a=0;a<this.airportList.Continents[i].Countries[c].Cities[ci].Airports.length;a++){
                  if(this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].Id == res["data"].data.countryCode ){
                    countryId = this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].CountryId;
                    locationArr = this.airportList.Continents[i].Countries[c].Cities[ci].Airports[a].Location.split(",");
                  }
                }
              }
            }
          }
          for(let i=0;i<this.airportList.Continents.length;i++){
            for(let c=0;c<this.airportList.Continents[i].Countries.length;c++){
              if(this.airportList.Continents[i].Countries[c].Id == countryId ){
                currencyCode = this.airportList.Continents[i].Countries[c].CurrencyId;
              }
            }
          }
          let countryObj = {
            city : res["data"].data.cityName,
            currencyCode: data.currency,
            currencyCodeDestination: currencyCode,
            countryCode: countryId,
            latitude:locationArr[1],
            longitude:locationArr[0]
          }
          this.getTempAndWeather(countryObj);
        }
      },
      error=>{
        console.log(error);
      });
  }

  getEmiValue(flightPrice: number,tenor:number){
   var flightDetails =
    {
    FlightPrice: flightPrice,
    Tenor: tenor
     }
    this.accountService.getEmiValue(flightDetails)
    .subscribe(res => {
      debugger;
      console.log(res["data"])
      this.emiValue = res["data"]["data"];
    })
  }

  getUserDetails() {
    this.accountService.getpersonalDetails()
      .subscribe(res => {
        console.log(res, "respone---");
        this.userPersonalDetail = res["data"].data;
        if (res["data"].data.emailVerifed == false){
          this.verified = true;
        }
        console.log("details", JSON.stringify(this.userPersonalDetail));
      });
  }

  completeApplication(){
    this.accountService.getCurrentApplicationStatus().subscribe(response => {
      console.log(response , "currentStatus");
      this.currentStatus = response.currentStatus;
      // REgister add
      if((this.currentStatus == "IN-PRINCIPAL_APPROVED") || (this.currentStatus ==  "PARTIAL_KYC_DOCS_UPLOADED") || (this.currentStatus == "ALL_KYC_DOCS_UPLOADED")
         || (this.currentStatus == "KYC_UNDER_VERIFICATION") || (this.currentStatus == "KYC_VERIFIED") || this.currentStatus == "ALL_KYC_DOCS_UPLOADED" || (this.currentStatus == "AUTO_APPROVED") || (this.currentStatus == "L2_Approved")){
      this.principalLoanAmount = true;
    }
    else if( (this.currentStatus == "FINAL_APPROVED_MIHURU") || (this.currentStatus =="FINAL_APPROVED_LENDING_PARTNER")){
       this.availableCreditLimit = true;
    }
    });

  }

  Popup() {
    this.modalService.open(this.Modal, { centered: true, keyboard: false })
  }

  verifyEmailOTP() {
    
    this.accountService.verifyEmailOTP({
      email: this.user.email,
      // mobileNumber: user.mobileNumber,
      otp: this.model.otp
    }).subscribe(response => {
      console.log('after otp verification:');
    },
      error => {
        console.log("error", error.error);
        if (error.status == 400)
          this.invalid = true;
      });
  }

  onResentEmailOTP() {
    //this.countdown.restart();
    this.accountService.resendEmailOTP({
      email: this.user.email,
      
    }).subscribe(response => {
      console.log('after otp verification:');
      console.log(response);
    });
  }

  onLeftTimeEvent($event) {
    this.timerLeftValue = $event.left;
    if (this.timerLeftValue == 0) {
      this.resetButtonEnable = true;
    }
  }

  getCreditLimit(){
    this.accountService.getCreditLimitAmount().subscribe(response =>{
      this.creditLimit = response['data']["sanctLimit"];
      this.availableCreditLimitAmount = response['data']["availableLimit"];
      this.availableCreditLimit  = true
      this.getOverlayStyle() ;
     });
  }
    onKeydown($event) {
    this.invalid = false;
  }
  getColor(){ 
    // this.current=(parseInt(this.creditLimit!=null?this.creditLimit:0)*100/this.availableCreditLimitAmount);
    this.current=((this.availableCreditLimitAmount!=null?this.availableCreditLimitAmount:0) *100/
    (this.creditLimit!=null?this.creditLimit:1));
    if( this.current>=0 && this.current<=10)
    this.color="#dc3545"  // Red 
    else if(this.current>10 && this.current<=50)
    this.color= "#ffcc03" // Yallow
    else 
    this.color= "#088127" // Green
    
  }
  getOverlayStyle() {
    // // const isSemi = this.semicircle;
    // this.current=(parseInt(this.creditLimit!=null?this.creditLimit:0)*100/100000);
   this.getColor();
    const isSemi = true;
    const transform = (isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

    return {
      top: isSemi ? 'auto' : '50%',
      bottom: isSemi ? '5%' : 'auto',
      left: '50%',
      transform,
      'font-size': this.radius / 3.5 + 'px',
    };
  }
  onFileChanged(event) {

    this.selectedFile = event.target.files[0];

    this.tempflag = true;
    if (this.selectedFile.type == 'image/jpeg' || this.selectedFile.type == 'image/png') {
    //   const reader = new FileReader();

      if (event.target.files && event.target.files.length) {
        // const [file] = event.target.files;
        // reader.readAsDataURL(file);
        let _formData = new FormData();
        _formData.append('Files', this.selectedFile);
    
  
        // let obj = {
        //   formData: _formData,
        // };
        this.accountService.updateProfilePics(_formData).subscribe(response => {
            if (response["data"].status == "success") {
                this.getUserDetails();
               // this.emiValue = "No flights available";
              }
        });
        // reader.onload = () => {
        //   this.imgUrl = reader.result as string;
        // };
      }
    }
    else{
        Swal.fire("Only png and jpeg file type can be uploaded.")
    }
  }
}
