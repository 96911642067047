import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'durationTimeFormat'})
export class DurationTimeFormatPipe implements PipeTransform {
  transform(value: any, typeOfValue): string {

    if(typeOfValue !== undefined && typeOfValue !== null){
      try{
        if(typeOfValue == "valueInMinutes"){
          let mins = +value;
          value = mins / 60;
        }
      }
      catch(e){}
    }

    let totalHours: number = +value;
    let totalStrHours = parseFloat( (Math.round(totalHours * 100) / 100).toString() ).toFixed(2);
    let parts = totalStrHours.split(".");

    let totalMinutes = parseFloat("0." + parts[1]) * 60;
    let totalStrMinutes = parseFloat( (Math.round(totalMinutes * 100) / 100).toString() ).toFixed(2);
    let minParts = totalStrMinutes.split(".");

    if(minParts[0] == "0")
      return parts[0] + " h";
    
    return parts[0] + " h " + minParts[0] + " m" ;
  }
}