import { Component, OnInit } from '@angular/core';
import { ProgressbarService } from '../../services/progressbar.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {
  percentage: number = 0;
  percentageVal$: Observable<number>;

  constructor(
    private progressbarService: ProgressbarService
    ) {

    }

  ngOnInit(): void {
    console.log(' In Progress bar init component:')
    this.progressbarService.setPercentageVal(0);
    this.progressbarService.percentageVal$.subscribe(val => {
      console.log('progress bar value changed:', val);
      this.percentage = val;
    });

  }

  

}
