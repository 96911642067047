<div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-sm-10 rightSection">
        <div class="privacyText">
            <h3>Terms of Use <br><small>Date: 10 December 2020</small></h3>
            <ol>
                <li>General <p>The below mentioned terms of use are applicable on any user/visitor (the “User”) using Mihuru Platforms
                        as offered Company as defined here in below. </p>
                    <p>Before you use Mihuru, you must read and accept all of the terms and conditions in, and linked to, this Terms of
                        Use and the Privacy Policy. We strongly recommend that, as you read this Terms of Use, you also access and read
                        the linked information, since it is incorporated into and, hereby, made part of this Terms of Use. As a user,
                        these Terms of Use govern your access to and use of the services, websites, and applications offered by Mihuru.
                        Your access to and use of the Service offered by the Company is conditioned on your acceptance of and compliance
                        with the Terms of Use and conditions mentioned below. Use of any functionality of Mihuru or any other website and
                        applications offered by the Company constitutes acceptance of this Terms of Use. If this Terms of Use conflicts
                        with any other documents, the Terms of Use will control for the purpose of usage of Mihuru. If you do not agree to
                        be bound by this Terms of Use and the Privacy Policy, you cannot use Mihuru in any way. Ensure that you return to
                        this page periodically to review the updated Terms of Use. The Company reserves the right, at any time and at
                        their sole discretion, to change or otherwise modify the Terms of Use without prior notice.</p>
                </li>
                <li>Definitions <p>"Application or App." shall mean Mihuru, the mobile and/or website application created, developed
                        and designed by the Company for providing the Services.</p>
                    <p>"Lender" shall mean non-banking financial company or banks with whom the Company has tied up for loan sanction,
                        which would sanction, process and grant the Loan to the User(s), through the Platform.</p>
                    <p>"Company" or "we" or “Mihuru” shall mean iTraveleo Private Limited, a company incorporated under the Companies
                        Act, 2013 having its registered office at 3A, Harmony, Dr. E Moses Road, Worli Naka, Worli, Mumbai, Maharashtra
                        400018. </p>
                    <p>"Customer(s)" or "you" or " Users" shall mean any natural or legal person who accesses, downloads, uses, views
                        the Mihuru Platforms and the Services.</p>
                    <p>"Loan" shall mean the loan that you may apply for through Mihuru Platforms and which is sanctioned and granted by
                        Lender, subject to the applicable terms and conditions of the Loan Agreement.</p>
                    <p>"Loan Agreement" shall mean the loan agreement to be executed between Lender and the User(s) for granting the
                        Loan whether in physical or electronic form as may be applicable from time-to-time.</p>
                    <p>"Online Stores" shall mean Windows Store, Android Google Play, iOS App store or any other online store or portal
                        where the App will be made available by the Company to the Users, from time to time.</p>
                    <p>"Outstanding Amount(s)" shall mean the Loan, interests and charges due and payable by you to Lender, on
                        respective due date(s).</p>
                    <p>"Mihuru Platforms" shall mean the App and the Website and other platforms collectively.</p>
                    <p>"Services" shall mean the services of granting, sanctioning, lending of short term loans, through the Mihuru
                        Platforms by Lender.</p>
                    <p>"Third Party Platforms" shall mean social networking platforms, such as Facebook, LinkedIn and other similar
                        platforms.</p>
                    <p>"User Data" shall mean any data, information, documents or materials submitted with the Company prior to or
                        during the use of the Services.</p>
                    <p>"Website" shall mean www.mihuru.com, which inclusive of its all its affiliated domains and various other
                        sub-domains and country sites, managed and operated by the Company for the provision of Services.</p>
                </li>
                <li>Services <p> Through the Services, you may apply for the Loan, subject to the fulfilment of the eligibility
                        criteria laid down in the App. You understand that the Company has been appointed by Lender to collect,
                        authenticate, track your location, verify and confirm the User Data, documents and details as may be required by
                        Lender to sanction the Loan. Lender authorizes the Company to collect and store the User Data through the
                        application form ("Application Form") available on Mihuru Platforms. In order to avail the Services, you are
                        required to register with the Company by logging in using your email address and mobile number and through your
                        Third Party Platforms (“User Account”). During the Application process, you shall be required to share and upload
                        the User Data on the Application Form. User Data shall include personal information including but not limited to
                        your name, e-mail address, gender, date of birth, mobile number, passwords, photograph, mobile phone information
                        including contact numbers, SMS and browsing history, data and login-in credentials of Third Party Platforms,
                        financial information such as bank documents, salary slips, bank statements, PAN card, bank account no., data from
                        Credit Information Companies, data required for Know Your Customer compliances, requirement and other relevant
                        details ("Personal Information"). You agree that the Personal Information shall always be accurate, correct and
                        complete. As part of the Services, you authorize us to import your details and Personal Information dispersed over
                        Third Party Platforms. You understand and acknowledge that we may periodically request for updates on such
                        Personal Information and we may receive such updated information from Third Party Platforms.</p>
                    <p> All transactions undertaken on your behalf by the Company will be on the basis of your express
                        instructions/consent and will be strictly on a non-discretionary basis. You also authorise the Company to get your
                        credit information report from one or more Credit Information Companies as decided by the Company from time to
                        time.Once you verify and upload the User Data and/or other documents and details in the Application Form, the
                        Company shall process the same. Upon the completion of the document verification by the Company, the Loan may be
                        sanctioned by Lender to you, subject to fitting eligibility criteria and other conditions set forth by Lender for
                        sanctioning the Loan. Thereafter, you are required to fill and upload the ECS/NACH mandate form/Cheque or any
                        other document as may be required by Lender . The Company may collect the physical documents including signatures
                        on those documents required for sanctioning and processing the Loan. Upon the collection of documents by the
                        Company, Lender shall disburse the Loan for your chosen product to Mihuru and/or Mihuru’s partners subject to the
                        terms and conditions of the Loan Agreement.</p>
                    <p>The sanctioned Loan shall be disbursed as per the mode provided in the Application Form. You are required to
                        repay the Outstanding Amount(s) to Lender, on the respective due date(s) mentioned in the Application Form.</p>
                    <p>You understand and acknowledge that the Company reserves the right to track your location ("Track") during the
                        provision of Services, and also in the event that you stop, cease, discontinue to use or avail the Services,
                        through deletion or uninstallation of Mobile App or otherwise, till the event that your obligations to pay the
                        Outstanding Amount(s) to Lender exist. Deletion, uninstallation, discontinuation of our Services, shall not
                        release you from the responsibility, obligation and liability to repay the Outstanding Amount(s).</p>
                    <p>You understand and acknowledge that you shall be solely responsible for all the activities that occur under your
                        User Account while availing the Services. You undertake that the Company shall not be responsible and liable for
                        any claims, damages, disputes arising out of use or misuse of the Services. By usage of the Services, you shall be
                        solely responsible for maintaining the confidentiality of the User Account and for all other related activities
                        under your User Account. The Company reserves the right to accept or reject your registration for the Services
                        without obligation of explanation.</p>
                    <p> You understand and acknowledge that, you are solely responsible for the capability of the electronic devices and
                        the internet connection, you chose to run Mihuru Platforms. Mihuru Platform’s operation or the Services on your
                        electronic device is subject to availability of hardware, software specifications, internet connection and other
                        features and specifications, required from time to time.</p>
                    <p>The User Data provided during the registration is stored by the Company for your convenience. You are not
                        required to log-in to your User Account, every time, to use or access the Platform. You understand and acknowledge
                        that by accepting these Terms, you authorize us to Track, fetch and use the User Data, including but not limited
                        to your Personal Information, for the purpose of authentication and any updates with regards to your credentials.
                    </p>
                </li>
                <li>Use of Service <p> You may only use Mihuru and Mihuru Platforms, for legitimate purposes in compliance these Terms
                        of Use and all applicable local, state, national, and international laws, rules and regulations. In consideration
                        of you agreeing to abide by these Terms of Use, we grant you a non-transferable, non-exclusive license to
                        download, access and use Mihuru and/or Mihuru Platforms for your own personal, non-commercial purposes and for no
                        other purpose. In particular, we grant such license to you subject to you agreeing that you shall not: </p>
                    <ol>
                        <li> use Mihuru and/or Mihuru Platforms for any purpose that is improper, unlawful, or to post, share or transmit
                            any material that (i) is defamatory, offensive, obscene or otherwise objectionable; (ii) is in breach of
                            confidence or privacy or of any third party’s rights including copyright, trade mark or other intellectual
                            property rights; (iii) is posted, shared or transmitted for the purpose of advertising or promoting yourself or
                            any third party; or (iv) is misleading or misrepresentative as to your identity or which in any way suggests
                            that you are sponsored, affiliated or connected with Mihuru;</li>
                        <li>use Mihuru and/or Mihuru Platforms for any commercial purpose or in any manner which may cause damage to
                            Mihuru or bring Mihuru into disrepute;</li>
                        <li>disassemble, reverse engineer or otherwise decompile any software, applications, updates or hardware contained
                            in or available via Mihuru and/or Mihuru Platforms, except as permitted by law;</li>
                        <li> copy, distribute, communicate to the public, sell, rent, lend or otherwise use Mihuru and/or Mihuru
                            Platforms, or seek to violate or circumvent any security measures employed to prevent or limit your access to or
                            use of Mihuru and/or Mihuru Platforms;</li>
                        <li>use or interfere with Mihuru and/or Mihuru Platforms in a way that could damage, disable, overburden, impair
                            or compromise our systems or security or interfere with other users;</li>
                        <li>introduce onto, or transmit via, Mihuru and/or Mihuru Platforms any disruptive computer program code, virus,
                            ‘denial of service’ or ‘spam’ attack, worm, Trojan horse, authorization key, license control utility or software
                            lock;</li>
                        <li>remove, alter or replace any notices of authorship, trade marks, business names, logos or other designations
                            of origin on Mihuru and/or Mihuru Platforms or pass off or attempt to pass off Mihuru and/or Mihuru Platforms as
                            the product of anyone other than Mihuru;</li>
                        <li>use any automated computer program or application to scan, copy, index, sort or otherwise exploit Mihuru
                            and/or Mihuru Platforms or any part thereof.</li>
                    </ol>
                    <p>When you sign up to any feature of, or use, Mihuru and/or Mihuru Platforms that requires the use of a log-in
                        account or password, you must provide us accurate information, including your real name. Also, you agree that you
                        shall be responsible for keeping those password and log-in details confidential and secure. We encourage you to
                        use “strong” passwords (that use a combination of upper and lower case letters, numbers and symbols) with your
                        account. If you become aware, or suspect for any reason, that the security of your log-in details has been
                        compromised, please let us know as soon as you can. Mihuru will not be liable for any loss or damage arising from
                        your failure to comply with this instruction. </p>
                    <p>Mihuru and Mihuru Platforms are not intended for those under 18 years of age, and no one under the age of 18 may
                        provide any information to, on or via Mihuru and/or Mihuru Platforms. We do not knowingly collect personal
                        information from those under the age of 18, and if we learn that we have collected or received personal
                        information from someone under the age of 18, we will delete that information in accordance with our Privacy
                        Policy.</p>
                    <p>To the extent that any element of Mihuru Services and/or Mihuru Platforms is hosted on a third-party website (for
                        example, Facebook or another social media website) and there are separate terms and conditions of use relating to
                        that third-party website, you agree to fully comply with such terms and conditions.</p>
                    <p>Mihuru is free to change the Service, stop the Service or any feature of the Service, create usage limits for the
                        Service at any given point in time, to you or to users at large without giving any prior notice/notification.</p>
                    <p>Violation of any of the Terms of Use or the Privacy Policy or any other terms used to avail of the Services
                        listed on this Site shall provide the right to the Company to delete the User’s account or information and
                        prohibit and ban him/her from using or accessing the Site or any of the services listed on the Site, without any
                        sufficient cause or notice.</p>
                </li>
                <li>Products <p> Mihuru facilitates access to credit from its lending partners to enable Users to purchase travel
                        education courses through Mihuru’s partners or other identified third parties. </p>
                    <p><span class="font-weight-bold">Mihuru Splitfare:</span><br /> Users make an upfront payment toward the cost of
                        the flight ticket, holiday package or hotel and then the pay our lending partner the remaining balance in EMIs
                        prior to taking their trip. Once the payments are made in full, Mihuru will provide you with the airline e-ticket
                        or travel booking vouchers. By confirming your booking through Mihuru Splitfare, you instruct us to book flight
                        tickets, hotels or holiday package on your behalf and you hereby authorize Mihuru to do so. </p>
                    <p><span class="font-weight-bold">Travel Loans:</span><br /> Users make an upfront payment toward the cost of the
                        flight ticket, holiday package or hotel and then the pay our lending partner the remaining balance in EMIs. Once
                        any EMIs due prior to your travel date are paid in full, Mihuru will provide you with the airline e-ticket or
                        travel booking vouchers. By confirming your booking through Mihuru Travel Loans, you instruct us to book flight
                        tickets, hotels or holiday package on your behalf and you hereby authorize Mihuru to do so. </p>
                    <p>The Company is not a travel agent and is not responsible for providing, or for setting or controlling the prices
                        applicable to, any of the travel options or products (such as flights, hotels or holiday packages) which you may
                        search for, find on and/or book via the Site or Mihuru Platforms (“Third Party Travel Products”). All such
                        Third-Party Travel Products are provided by independent travel agents, airlines, hotels, tour operators or other
                        third parties and are subject to the terms and conditions imposed by those third parties.</p>
                    <ol class="list-unstyled">
                        <li>
                            <p class="font-weight-normal">5.1 Booking:</p>
                            <p>By paying a fraction of the cost upfront, you can be rest assured that your travel has been booked. Your
                                flight ticket, holiday package and/or hotel voucher (as the case may be) will be released to you after you
                                have paid all instalments on or before the due date specified in your EMI plan.</p>
                            <p>Failure to pay all EMIs before the date of departure will result in your travel booking being cancelled. In
                                the event of failure to make pay all EMI, the processing fee paid will not be refunded or transferred to a new
                                booking under any circumstance. Any payments made towards the EMIs (after deducting the airline’s cancellation
                                charges and booking agent cancellation charges, if any) will be added into your Mihuru wallet which can be
                                redeemed against travel bookings via loans from Mihuru’s lending partners in future. The User may opt to
                                request a refund of the amount instead of a credit if he so desires and should request for such credit on an
                                email sent to <a href="mailto: support@mihuru.com" target="_blank" class="interLink">support&#64;mihuru.com</a>. If the travel provider’s cancellation charges exceed the payments made, the
                                User will have to pay for the excess amount.</p>
                        </li>
                        <li>
                            <p class="font-weight-normal">5.2 Changes and Cancellation:</p>
                            <p>Once the upfront cost has been paid, any change to your travel itinerary (including travel dates, routes,
                                cancellations) will incur change or cancellation penalties as outlined by the travel agent’s policy and
                                booking agent’s policy, if applicable. The processing fees paid is non-refundable and may not be transferred
                                to a new booking under any circumstance. Any payments made towards the instalments (after deducting the travel
                                provider’s cancellation charges and booking agent cancellation charges, if any) will be added into your Mihuru
                                wallet which can be redeemed against travel bookings via loans from Mihuru’s lending partners in future. The
                                user may opt to request a refund of the amount instead of a credit if he so desires and should request for
                                such credit on an email sent to <a href="mailto: support@mihuru.com" target="_blank" class="interLink">support&#64;mihuru.com</a>. If the airline’s cancellation charges exceed the payments
                                made, the user will have to pay for the excess amount.</p>
                        </li>
                        <li>
                            <p class="font-weight-normal">5.3 Payment:</p>
                            <p>You must pay all the instalments outlined in your EMI plan on or before their due date. Until we receive all
                                the instalments due prior to your date of travel, we are not obliged to give you your flight tickets or
                                holiday package and/or hotel voucher. We send you payment reminders days before the scheduled dates.</p>
                        </li>
                        <li>
                            <p class="font-weight-normal">5.4 Completion of Services:</p>
                            <p>After you have paid all EMIs as per your payment plans, our Services to you would be considered to be
                                completed. In case your travel is not yet completed, for any changes or cancellations thereafter, you will
                                have to get directly get in touch with the airline and/or the booking agent, as applicable.</p>
                        </li>
                        <li>
                            <p class="font-weight-normal">5.5 Air Ticket / Travel Voucher Delivery:</p>
                            <p>We will deliver your air tickets, holiday package and/or hotel vouchers once all EMIs due before your travel
                                date have been paid as per your EMI plan, to the email address provided. We are not liable for faulty delivery
                                or non-delivery of your air tickets, holiday package and/or hotel vouchers if caused by circumstances on your
                                part such as entering a wrong email address or your email settings. In case of any changes in your contact
                                information that may affect our ability to deliver ordered tickets / vouchers to you, you must notify us. We
                                do not deliver air tickets or vouchers in paper form.</p>
                        </li>
                        <li>
                            <p class="font-weight-normal">5.6 Extra Charges:</p>
                            <p>The airline you’ve booked your tickets with may apply extra charges for accessory services such as preferred
                                seating, refreshments, or entertainment. These services are optional, and not included in the flight price
                                displayed on our website. If you wish to avail these services offered by the airline, you will have to inform
                                us prior to completing your booking and any additional charge for these services will have to be borne by you.
                                If you wish to avail any of these services offered by the airline after you have completed your booking or
                                fully paid your installments, you will have to deal directly with the selected airline.</p>
                        </li>
                        <li>
                            <p><span class="font-weight-normal">5.6 Offered Flights:</span><br /> We display a range of flights on our
                                website that you may book using our visual discovery tool. If the conditions of carriage are changed by the
                                selected airline, during the provision of our Services to you, we will notify you accordingly and make an
                                effort to offer you alternative flight options. </p>
                        </li>
                    </ol>
                </li>
                <li>Education Loans <p> We faciliate loans for education and upskilling courses offered by our education partners.
                        These loans are extended by our lending partners and are paid directly to our education partner on your behalf as
                        provided hereinabove. </p>
                </li>
                <li>Sharing information with us <p> We take your privacy seriously and operate at all times in accordance with
                        applicable data protection laws. You acknowledge that, any personally identifiable information, which you submit
                        to or via the Site and/or Mihuru Platforms may be used by us in accordance with our Privacy Policy. You agree to
                        ensure that all personally identifiable information you provide to us is accurate and up to date and that you have
                        obtained all consents, licenses or approvals necessary to enable us to use that information in accordance with
                        these Terms of Use and with our <a [routerLink]="['/privacy','privacy']" class="interLink">Privacy Policy</a>. </p>
                    <p>To the extent that Mihuru and/or Mihuru Platforms allow you to post, upload, transmit or otherwise make available
                        any information, images, video, or other data with Mihuru or other Mihuru users (“User Content”), you agree that:
                    </p>
                    <ol class="list-unstyled">
                        <li>
                            <p>7.1 You are solely responsible for the User Content that you upload and you represent and agree that you will
                                not share anything which you do not have the permission or right to share and which you cannot grant the
                                license referred to in paragraph (b) below in respect of;</p>
                        </li>
                        <li>
                            <p>7.2 although all intellectual property rights subsisting in any User Content will be owned by you or your
                                licensors, you hereby grant the Company and its group companies a non-exclusive, perpetual, royalty-free,
                                worldwide, transferrable and sub-licensable right to use, reproduce both electronically and otherwise,
                                display, distribute, modify, adapt, publish, translate, and create derivative works from any and all such User
                                Content (excluding personally identifiable information) including for the purposes of advertising and
                                marketing Mihuru and/or Mihuru Platforms; </p>
                        </li>
                        <li>
                            <p>7.3 we are under no obligation to store, retain, publish or make available any User Content uploaded by you
                                and that you shall be responsible for creating backups of any such User Content if necessary.</p>
                            <p>To the extent that you provide us with any suggestions, comments, improvements, ideas or other feedback
                                (“Feedback”), you hereby assign ownership of all intellectual property rights subsisting in that feedback to
                                us and acknowledge that we can use and share such Feedback for any purpose at our discretion.</p>
                        </li>
                    </ol>
                </li>
                <li>Data Collection and Use <p> We record information relating to your use of Mihuru and Mihuru Platforms such as the
                        searches you undertake, the pages you view, your browser type, IP address, location, requested URL, referring URL,
                        and timestamp information. We use this type of information to administer Mihuru and provide the highest possible
                        level of service to you. We also use this information in the aggregate to perform statistical analyses of user
                        behavior and characteristics in order to measure interest in and use of the various areas of Mihuru. You cannot be
                        identified from this aggregate information.</p>
                    <p>The Site automatically collects non-personally identifiable information using the Sites' Cookies to monitor
                        visitors’ usage patterns and information. The only information the Site may collect from a normal visit is the IP
                        address, domain name, browser and type of machine used, referring websites, downloaded files, requested pages and
                        time and date of those requests to alert the User of compatibility issues or for improving the web design and
                        functionality of the Site as well as to monitor how many new visitors used the Site. The Site does not recognize
                        the identity of a User based on the numerical identity. The Cookies do not contain personal information about the
                        visitors and none of the cookies are used to record the personal information about the Users.</p>
                    <p>For the sake of clarity "Cookies" are alphanumeric identifiers that a web server transfers to the visitors local
                        computer, and then later their browser presents this information to the web server.</p>
                    <p>By using this website, you shall grant unconditional access to us for obtaining all information stored on your
                        device including but not restricted to mobile text data, applications, contact information, call durations, etc.
                    </p>
                </li>
                <li>Intellectual Property <p> Except as set out elsewhere in these Terms of Use, all intellectual property rights
                        including, but not limited to, copyright (including copyright in computer software), patents, trade marks or
                        business names, design rights, database rights, know-how, trade secrets and rights of confidence in Mihuru and
                        Mihuru Platforms (together, the "Intellectual Property Rights") are owned by or licensed to the Company. You
                        acknowledge that by using Mihuru and/or Mihuru Platforms, you will not acquire any right, title or interest in or
                        to Mihuru and/or Mihuru Platforms or in or to the Intellectual Property Rights except for the limited license to
                        use Mihuru and Mihuru Platforms granted to you pursuant to these Terms. You further acknowledge that you have no
                        right to have access to any aspect of Mihuru and/or Mihuru Platforms in source-code form.</p>
                    <p>You are granted a limited, non-exclusive right to create a text hyperlink to the Site for noncommercial purposes,
                        provided such link does not portray Mihuru or Mihuru Platforms or any of its products or services in a false,
                        misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not contain
                        any adult or illegal material or any material that is offensive, harassing or otherwise objectionable. You may not
                        use Mihuru logo or other proprietary graphic of Mihuru without our express written permission. Further, you may
                        not use, frame or utilize framing techniques to enclose any of our trademark, logo or other proprietary
                        information, including the images found at the Site, the content of any text or the layout/design of any page or
                        form contained on a page on the Site without our express written consent. Except as noted above, you are not
                        conveyed any right or license by implication, estoppel or otherwise in or under any patent, trademark, copyright
                        or proprietary right of Mihuru or any third party. Nothing contained on the Site may be construed as granting, by
                        implication, estoppel or otherwise, any license or right to use any patent, trademark, copyright or other
                        proprietary right of Mihuru or any third party without the prior written permission of Mihuru or such other party
                        that may own such patent, trademark, copyright or other proprietary right(s). </p>
                </li>
                <li>Copyright Policy <p>We respect the intellectual property rights of others. If you have reason to believe that your
                        copyright is being infringed by any content on Mihuru and/or Mihuru Platforms, please send a written notification
                        of such infringement to the email address in the Contact Us section on our Site. Please provide us with the
                        following information in accordance with the Copyright and Information Technology Acts of India: (i) a physical or
                        electronic signature of the copyright owner or a person authorized to act on their behalf; (ii) identification of
                        the copyrighted work claimed to have been infringed; (iii) identification of the material that is claimed to be
                        infringing or to be the subject of infringing activity and that is to be removed or access to which is to be
                        disabled, and information reasonably sufficient to permit us to locate the material; (iv) your contact
                        information, including your address, telephone number, and an email address; (v) a statement by you that you have
                        a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner,
                        its agent, or the law; and (vi) a statement that the information in the notification is accurate, and, under
                        penalty of perjury, that you are authorized to act on behalf of the copyright owner. We will respond to notices of
                        alleged copyright infringement that comply with applicable law and are properly provided to us.</p>
                </li>
                <li>Limitations and Exclusions of Liability <p>Mihuru is not a travel agent and is not responsible for providing any
                        travel options displayed via Mihuru and/or Mihuru Platforms or for setting or controlling the prices we display.
                        Any Third Party Travel Products, which you find and/or book via Mihuru and/or Mihuru Platforms are provided by
                        independent travel agents, airlines, hotels, travel operators or other third parties (“Travel Providers”). Mihuru
                        facilitates your bookings with Travel Providers but is not responsible and has no liability to you in respect of
                        such bookings. The terms and conditions applicable to your booking with them (“Travel Provider Terms”) will be
                        notified to you at the time of booking and you should ensure that you read and fully understand them before
                        completing your booking. The Travel Provider Terms will set out what rights you have against the Travel Provider
                        and will explain their liability to you in the event of anything going wrong. You agree to comply in full with all
                        applicable Travel Provider Terms. You acknowledge that breaching Travel Provider Terms could result in the
                        cancellation of tickets or reservations, revocation of frequent flier miles and other benefits, and additional
                        charges.
                    <p>Mihuru has no responsibility whatsoever for any arrangements you make with any third party as a result of your
                        use of Mihuru and/or Mihuru Platforms (including, without limitation, any Travel Provider). In the event that you
                        encounter any problems with any booking you make or attempt to make via Mihuru, you acknowledge that you should
                        resolve that issue with the relevant Travel Provider and that your sole remedy in such circumstances, including
                        any refund, lies with the relevant Travel Provider and not with Mihuru. Where Mihuru and/or Mihuru Platforms
                        contain links to other sites and resources provided by third parties, these links are provided for your
                        information only. We have no control over the contents of those sites or resources and accept no responsibility
                        for them or for any loss or damage that may arise from your use of them.</p>
                    <p>To the maximum extent permitted by law, we (together with our officers, directors, employees, representatives,
                        affiliates, providers and third parties) do not accept any liability for (a) any inaccuracies or omissions in the
                        content displayed on or via Mihuru and/or Mihuru Platforms; or (b) any act of god, accident, delay or any special,
                        exemplary, punitive, indirect, incidental or consequential loss or damage of any kind (including, without
                        limitation, lost profits or lost savings), whether based in contract, tort (including negligence), strict
                        liability or otherwise, incurred by you arising out of or in connection with your access to, use of, or inability
                        to access or use, Mihuru and/or Mihuru Platforms or any content contained provided therein.</p>
                    <p>Subject to these Terms of Use and to the extent permitted by law, our maximum aggregate liability to you arising
                        out of or in connection with these Terms of Use or your access to, use of, or inability to access or use, Mihuru
                        and/or Mihuru Platforms shall be limited to the sum of &#8377; 5,000 (Five Thousand Indian Rupees).</p>
                </li>
                <li>Your liability to us <p>Subject to these Terms of Use, you will be responsible and liable to us for all actions,
                        claims, proceedings, costs, damages, losses and expenses (including, without limitation, legal fees) incurred by
                        us, and each of our officers, directors, employees and agents, arising out of, or in any way connected with your
                        use of Mihuru and/or Mihuru Platforms or breach of these Terms of Use. </p>
                </li>
                <li>Indemnity <p>You access and use the Service or any Content available on Mihuru at your own risk. You understand
                        and agree that the Service is provided to you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the
                        foregoing, the Company, Mihuru and its partners disclaim any warranties, expressed or implied, of merchantability,
                        fitness for a particular purpose or non-infringement.</p>
                    <p>The User agrees to indemnify and hold the Company, its directors, employees, agents, officers, promoters,
                        shareholders, predecessors, successors-in-interest, affiliates and assigns, harmless from any demands, losses,
                        liabilities, damages, expenses and claims made against the Company by any third party arising out of or in
                        connection but not limited to breach of any warranties, representations or undertakings or in relation to the
                        non-fulfillment of any of Users obligations under this Terms of Use or out of violation of any applicable laws,
                        regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim
                        of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and
                        infringement claims or breach of other rights. This provision of this clause shall survive the expiry or earlier
                        termination of this Terms of Use whichever is earlier.</p>
                </li>
                <li>Disclaimer <p>The information contained on the Service is for general information purposes only. Even if the
                        Company takes every precaution to insure that the content of the Service is both current and accurate, errors can
                        occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or
                        inaccuracies in the information contained on the Service.</p>
                    <p>The Company assumes no responsibility for any errors or omissions, or for the results obtained from the use of
                        this information.</p>
                    <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages
                        or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in
                        connection with the use of the Service or the contents of the Service. The Company reserves the right to make
                        additions, deletions, or modifications to the contents on the Service at any time without prior notice. </p>
                    <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
                    <p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of
                        the results obtained from the use of the Service, and without warranty of any kind, express or implied, including,
                        but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
                    <p>The Company will not be liable to You or anyone else for any decision made or action taken by usage of Service or
                        for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
                </li>
                <li>Acceptance of Terms of Use <p>As a user of this Site, it is implicit that by logging on it you have read,
                        understood and consented to abide by the terms of the disclaimer, Privacy Policy and the Terms of Use. The Terms
                        of Use read with the Privacy Policy govern the usage of the Site and the platform. By using the Site, you signify
                        that you have read, understood and are agreeing to these Terms of Use, regardless of whether you are a registered
                        member of our services. Your continued access or use of this Site signifies your acceptance of the updated or
                        modified Terms of Use. If you do not wish to agree to these Terms of Use, please refrain from using this Site.</p>
                    <p>By accessing the Site you agree that these Terms of Use constitute the entire agreement between us and you with
                        respect to the Services provided by the Company and will supersede all other prior or contemporaneous
                        communications and proposals between yourself and us. A printed version of these Terms of Use and of any notice
                        given in electronic form shall be valid and admissible in judicial or administrative proceedings based upon or
                        relating to these Terms of Use to the same extent and subject to the same conditions as any other documents and
                        records originally generated and maintained in printed form.</p>
                </li>
                <li>Modification of Terms <p>The Company reserves the right, at all times, to discontinue or modify any of the Terms
                        of Use as may be deemed necessary or desirable without prior notification. Such changes may include without
                        limitation, the adding of certain fees or charges. Therefore, it is important that the user re-reads this
                        important notice containing the Terms of Use, from time to time, in order to be informed as to such changes
                        undertaken by the Company. Any changes to the Terms of Use and your continued use of our Site, is a deemed
                        agreement to the Terms of Use and the Privacy Policy expressed thereto. Any such deletions or modifications shall
                        be effective immediately upon its posting thereof. Use of the Site by the User after such modified notice shall be
                        deemed to constitute acceptance by User, of such modified terms.</p>
                    <p>In the event there are significant changes in the Service, or in the Terms of Use itself, we may display a notice
                        on the Website or send you an email informing you of such changes, so that you may review the changed terms prior
                        to continuing to use the Site and our Services.</p>
                    <p>Using the Company’s Services or accessing the Site after a notice of changes has been sent to you or published on
                        the Site will constitute your consent to the changed terms.</p>
                </li>
                <li>Termination of Agreement <p>Mihuru may in its absolute discretion immediately terminate any agreement with you
                        under these Terms of Use at any time upon notice to you and/or, if you have a log-in account, by cancelling your
                        membership and your access to your account. We may suspend your use of Mihuru and/or Mihuru Platforms without
                        notice at any time, without incurring any liability to you whatsoever.</p>
                </li>
                <li>Applicable Laws and Jurisdiction <p>Usage of the Site and Mihuru Platforms, any the Terms of Use along with the
                        Privacy Policy shall be governed by the applicable laws of India and shall be subjected to the exclusive
                        jurisdiction of the Courts in Mumbai.</p>
                </li>
                <li>Credit Information <ol class="list-unstyled">
                        <li>
                            <p>19.1 All transactions undertaken on your behalf by Mihuru will be on the basis of your express
                                instructions/prior approval and will be strictly on a non-discretionary basis.</p>
                            <p>19.2 The Service provided to you is based on your credit information report and credit rating maintained by
                                various authorized Credit Information Companies (“CICs) in India and any other financial information that you
                                may chose to provide to Mihuru or authorise / give consent to obtain from such CIC. If the information and
                                details provided to Mihuru (by the concerned CICs or you) are incorrect or incomplete, it will adversely
                                affect the quality of the Service. In addition, there are several unknown factors beyond the control of Mihuru
                                that may impact the outcome of the Service. For instance, credit scores are calculated differently by the
                                various credit information companies; banks and other lending institutions may have their own internal
                                policies subject to which they accept settlement of delayed dues or offer financial assistance and therefore,
                                it is impossible to predict how any one specific action that we may recommend will affect your credit rating
                                or your ability to obtain financial assistance. Therefore, you hereby understand and acknowledge that the
                                reports and analysis provided to you by Mihuru is based on Mihuru's commercially reasonable efforts and our
                                past knowledge and experience, and Mihuru does not guarantee any outcome vis-à-vis your credit report or
                                credit rating maintained by CICs nor do we guarantee that you will receive financial assistance from any bank
                                or other credit institution. Individual results may vary based on several factors which are beyond the control
                                of Mihuru. While we make commercially reasonable efforts to ensure that our analysis are comprehensive and
                                will provide you with options to improve your credit rating and credit worthiness, it is not meant to be
                                exhaustive or conclusive and errors may occur.</p>
                            <p>19.2 The Service provided to you is based on your credit information report and credit rating maintained by
                                various authorized Credit Information Companies (“CICs) in India and any other financial information that you
                                may chose to provide to Mihuru or authorise / give consent to obtain from such CIC. If the information and
                                details provided to Mihuru (by the concerned CICs or you) are incorrect or incomplete, it will adversely
                                affect the quality of the Service. In addition, there are several unknown factors beyond the control of Mihuru
                                that may impact the outcome of the Service. For instance, credit scores are calculated differently by the
                                various credit information companies; banks and other lending institutions may have their own internal
                                policies subject to which they accept settlement of delayed dues or offer financial assistance and therefore,
                                it is impossible to predict how any one specific action that we may recommend will affect your credit rating
                                or your ability to obtain financial assistance. Therefore, you hereby understand and acknowledge that the
                                reports and analysis provided to you by Mihuru is based on Mihuru's commercially reasonable efforts and our
                                past knowledge and experience, and Mihuru does not guarantee any outcome vis-à-vis your credit report or
                                credit rating maintained by CICs nor do we guarantee that you will receive financial assistance from any bank
                                or other credit institution. Individual results may vary based on several factors which are beyond the control
                                of Mihuru. While we make commercially reasonable efforts to ensure that our analysis are comprehensive and
                                will provide you with options to improve your credit rating and credit worthiness, it is not meant to be
                                exhaustive or conclusive and errors may occur.</p>
                            <p>19.4 For as long as your User ID remains registered with us, Mihuru may, from time to time, recommend our own
                                products and services or the products and/or services of third party banks and other credit institutions to
                                you. You hereby consent that based upon your request, Mihuru may forward your contact details to third party
                                banks and credit institutions, and you authorize them to contact you for Service related calls, even if you
                                are registered with a Do Not Call (DNC) status with your telecom provider. If you have asked us, or if you do
                                ask us in future not to send you any marketing material, you agree that this restriction will not apply to
                                these recommendations / referrals. The provision of referrals related to the products and/or services of third
                                party banks and other credit institutions is purely a referral service and is not an offer made to you by
                                Mihuru for any financial product and/or financial services and should not be construed as such. Such third
                                party referral is provided strictly on a non-risk participation basis. The ultimate decision regarding the
                                appropriateness of any product / service that is provided to you by Mihuru is exclusively yours and you should
                                exercise due caution and/or seek independent advice before entering into any investment or financial
                                obligation based on these referrals. You should consult your appropriate advisors and independently consider
                                the merits and risks of subscribing or purchasing any recommended products / services, including engaging with
                                any third party and / or purchasing their products / services. The third party/parties will be entitled to
                                determine, in their sole discretion, whether to offer any of their products and/or services to you and any
                                such offer will be subject to such terms and conditions as may be agreed between you and the concerned third
                                party. Mihuru shall not be a party to and shall not be responsible or liable for any transactions,
                                interactions, conduct or communications between you and our third party partners, on or through the Service or
                                otherwise. Mihuru may receive a referral / facilitation fee from these third party banks / other credit
                                institutions for the referral and you hereby confirm that you have no objection to the same.</p>
                        </li>
                    </ol>
                </li>
                <li>Force Majeure <p>Without limiting the foregoing, under no circumstances shall the Company be held liable for any
                        damage, loss, loss of services of Mihuru Platforms, due to deficiency in provision of the Services resulting
                        directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without
                        limitation, internet failures, computer equipment failures, telecommunication equipment failures, change in
                        applicable regulations, including Reserve Bank of India regulations, or any other government regulations, floods,
                        storms, electrical failure, civil disturbances, riots.</p>
                </li>
            </ol>
        </div>
    </div>
</div>
<div class="row mt-4 justify-content-center">
    <div class="col-12 col-lg-10 col-sm-10">
        <div class="form-group check-note">
            <div class="form-check">
                <input type="checkbox" id="allInfoAreValid" class="ab-checkbox" />
                <label class="form-check-label" for="allInfoAreValid">
                    I accept that I have read and understood Term & conditions
                </label>
            </div>
        </div>
    </div>
</div>