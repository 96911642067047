<div class="main-banner d-flex align-items-center">
    <div class="row justify-content-center w-100">
        <div class="col-sm-11 col-12">
            <h1 title="Mihuru + best brands = unlimited happiness"><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/pd-mihuruLogo.png"
                    class="logo" /> + best brands = unlimited
                happiness</h1>
            <p>For a seamless experience, <span>we partner with brands you love, </span><br />
                to give you the freedom to chase your dreams!
            </p>
        </div>
    </div>
</div>
<div class="section3">
    <div class="container-fluid leftcontent">
        <div class="row textDiv">
            <div class="col-12 p-0">
                <div class="row justify-content-center m-0">
                    <div class="col-lg-7 col-xl-5 col-11">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a [class]="navTravel" id="Travel-tab" (click)="tabChanged('travel')" data-toggle="tab"
                                    href="#Travel" role="tab" aria-controls="Travel"
                                    [attr.aria-selected]="navTravel == 'nav-link active' ?true:false">Travel</a>
                            </li>
                            <li class="nav-item">
                                <a [class]="navEducation" (click)="tabChanged('edTech')" id="Education-tab"
                                    data-toggle="tab" href="#Education" role="tab" aria-controls="Education"
                                    [attr.aria-selected]="navEducation == 'nav-link active'? true :false">Education</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div [class]="travelClass" id="Travel" role="tabpanel" aria-labelledby="Travel-tab">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-12">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-4 col-sm-4 col-6" *ngFor="let bgUrl of TravelData">
                                        <div id="curve" class="card justify-content-center">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="{{bgUrl.backgroundImage}}" />
                                            <div class="PD-logo"><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src={{bgUrl.logoImage}} />
                                                <div *ngIf="bgUrl.emiOffered" class="badge">0 cost EMI available</div>
                                            </div>
                                            <div class="card-blur"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class]="educationClass" id="Education" role="tabpanel" aria-labelledby="Education-tab">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-12">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-4 col-sm-4 col-6" *ngFor="let bgUrl of edTechData">
                                        <div id="curve" class="card justify-content-center">
                                            <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="{{bgUrl.backgroundImage}}" />
                                            <div class="PD-logo"><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="{{bgUrl.logoImage}}" />
                                                <!-- <div class="textdiv">{{bgUrl.partnerName}}</div> -->
                                                <div *ngIf="bgUrl.emiOffered" class="badge">0 cost EMI available</div>
                                            </div>
                                            <div class="card-blur"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-steps-freedom></app-steps-freedom>