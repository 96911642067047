<div class="section1">
    <div class="row justify-content-center m-0">
        <div class="col-lg-6 col-md-8 col-sm-8 col-11">
            <h2 title="Financing Your Dreams">Financing your dreams</h2>
            <p>Our transparent financial solutions offers you different EMI options to fund your travel and education goals.
            </p>
        </div>
    </div>
</div>
<div class="section2">
    <div class="container-fluid">
        <div class="leftcontent">
            <div class="textDiv1">
                <div class="row">
                    <div class="col-12">
                        <h2>Travel on EMI</h2>
                    </div>
                </div>
                <div class="row yellowBg">
                    <div class="col-sm-4 col-8 order-sm-8 img1"><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/Man-woman-standing-mountain-sunrise.png"
                            alt="Man and woman standing on mountain watching sunrise | Book Now Pay Later | Travel on EMI | Mihuru" class="img-fluid img-rounded"></div>
                    <div class="col-sm-8 col-12 textDiv text1">
                        <p>Financially planning your travel has never been easier</p>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span> 
                                Instant credit line up to &#8377;  2,00,000 for your travel booking</li>
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span>
                                Repay in instalments upto 12 months</li>
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span>With Book Now Pay Later you can save up to 40% on total travel costs</li>
                        </ul>
                        <!-- <button class="btn btn-warning" routerLink="/rules-for-traveling-the-world">Know more</button> -->
                        <button class="btn btn-warning" (click)="moEngageTravelKnowMore()">Know more</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="leftcontent">
            <div class="textDiv2">
                <div class="row">
                    <div class="col offset-sm-4 offset-md-4 offset-lg-5">
                        <h2>Education Loan</h2>
                    </div>
                </div>
                <div class="row yellowBg">
                    <div class="col-sm-4 col-8 img2"><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/Books-table-library.png"
                            alt="Books on the table in library | Education loans | Mihuru" class="img-fluid img-rounded"></div>
                    <div class="col-sm-8 col-12 textDiv text2">
                        <p>Further your professional learning goals</p>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span> Further your educational and professional learning goals</li>
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span>Pay course fees in convenient monthly instalments</li>
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span> Choose the course you wish to enroll in, we will help with the financing</li>
                            <li><span class="fa-li"><i class="fab fa-telegram-plane"></i></span> Education Loans upto &#8377; 2,50,000
                            </li>
                        </ul>
                        <!-- <button class="btn btn-warning" routerLink="/educationloan">Know more</button> -->
                        <button class="btn btn-warning" (click)="moEngageEducationKnowMore()">Know more</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>