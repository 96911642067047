<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
      <div class="row justify-content-center mt-4">
        <div class="col-8 blue_box p-4 text-center">
          <h3 class="heading">Type of Profession</h3>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col-11 white_box p-4">
          <form [formGroup]="model" novalidate autocomplete="off" (ngSubmit)="submit()">
            <div class="row justify-content-center">
              <div class="col-sm-10 col-12">
                <div class="form-group">
                  <select formControlName="professionType" class="form-select" (change)="onProfessionTypeChange()">
                    <option value="">Profession Type</option>
                    <option *ngFor="let professionType of professionTypes" [value]="professionType">
                      {{ professionType }}
                    </option>
                  </select>
                  <div class="error" *ngIf="model.get('professionType')?.invalid && model.get('professionType')?.touched">
                    Profession Type is mandatory
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-10 col-12">
                <ng-container *ngIf="isSelfEmployed">
                  <div class="row">
                    <div class="col-11 col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Company Name</label>
                        <input formControlName="companyName" class="form-control" placeholder="Please enter a Company Name" />
                        <span *ngIf="model.get('companyName')?.invalid && model.get('companyName')?.touched" class="alert alert-danger mt-2">
                          Company Name is mandatory
                        </span>
                      </div>
                    </div>
                    <div class="col-11 col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Company Type</label>
                        <select formControlName="companyType" class="form-select" style="margin-top: .3rem;">
                          <option value="">Please select company type</option>
                          <option *ngFor="let companyType of companyTypes" [value]="companyType">{{ companyType }}</option>
                        </select>
                        <span *ngIf="model.get('companyType')?.invalid && model.get('companyType')?.touched" class="alert alert-danger mt-2">
                        Company Type is mandatory
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row" *ngIf="isSelfEmployed">
                <div class="col-11 col-sm-6">
                  <div class="form-group">
                    <label class="control-label">GST</label>
                    <input formControlName="gst" class="form-control" placeholder="Please enter a GST" pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" (blur)="comparePan()" [attr.disabled] = "((model.get('skipGst')?.value) || gstInactive) ? true : null" [class.disabledCss] = "(model.get('skipGst')?.value) || gstInactive" />
                    <div *ngIf="model.get('gst').invalid && model.get('gst').touched" class="error">Invalid GST format</div>
                  </div>
                </div>
                <div class="col-11 col-sm-6" *ngIf="model.get('skipGst')?.value">
                    <div class="form-group">
                        <label class="control-label">Business Proof Document</label>
                        <select formControlName="businessDocument" class="form-select" style="margin-top: .3rem;">
                          <option value="">Please select business proof</option>
                          <option *ngFor="let doc of businessDocuments" [value]="doc">{{ doc }}</option>
                        </select>
                        <span *ngIf="model.get('businessDocument')?.invalid && model.get('businessDocument')?.touched" class="alert alert-danger mt-2">
                          Business Proof Document is mandatory
                        </span>
                      </div>   
                </div>
              </div>
              <div class="row"  *ngIf="isSelfEmployed">
                <div class="col-11 col-sm-6">
                    <div class="form-group">
                        <div class="form-check">
                          <input type="checkbox" formControlName="skipGst" id="skipGst" class="form-check-input" [attr.disabled] = "gstInactive" />
                          <label class="form-check-label" for="skipGst">Skip GST</label>
                        </div>
                      </div>
                </div>
              </div>
              <div class="row justify-content-center" *ngIf="model.get('skipGst')?.value">
                <div class="col-12">
                  <div class="file-upload">
                    <div class="image-upload-wrap">
                      <button type="button" class="remove-btn" (click)="removeFile()">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                      <div #filedrop ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (click)="file.click()" (onFileSelected)="updatePreview()" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="upload-drop-zone">
                        <div class="drag-text" *ngIf="uploader.queue.length == 0">
                          <h3><i class="fas fa-upload"></i><br />
                            Drag & drop or select file<br />
                            <div class="choose_file"> Choose file </div>
                          </h3>
                        </div>
                        <object *ngIf="image" id="myimage" width="100%" [data]='image' class="img_preview"></object>
                        <input #file style="display: none;" type="file" (onFileSelected)="updatePreview()" ng2FileSelect [uploader]="uploader" multiple />
                      </div>
                    </div>
                  </div>
                  <p class="uploaddocfooter text-success mt-0"> Please upload the documents only in PDF format</p>
                </div>
              </div>
              <label *ngIf="isBankStatementUpload">
                <p>{{bankStatementRequired}}</p>
              </label>
            </div>
          </div>
          <div class="row justify-content-center mb-2 mt-2 buttonDiv">
            <div class="col-6 text-center">
              <div *ngIf="!isSalarized">
                <button type="submit" class="btn btn-warning" [attr.disabled] = "(((uploader.queue.length === 0 || !businessDocuments.includes(model.get('businessDocument')?.value )) && model.get('skipGst')?.value  ) || (!model.get('skipGst')?.value && (!companyTypes.includes(model.get('companyType')?.value) || !model.get('gst')?.value || model.get('gst').invalid))) ? true : null" *ngIf="(isSalarized || (isSelfEmployed && model.get('companyName')?.value && model.get('companyType')?.value))">
                Submit
                </button>
              </div>
              <div *ngIf="isSalarized">
                <button type="submit" class="btn btn-warning">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-11">
    <app-assist-left></app-assist-left>
  </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>