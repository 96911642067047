import { Component, OnInit, Input } from '@angular/core';
import { Plan } from '../../../core/models/personalInfo';

@Component({
  selector: 'app-assist-emi-table',
  templateUrl: './assist-emi-table.component.html',
  styleUrls: ['./assist-emi-table.component.scss'],
})
export class AssistEmiTableComponent implements OnInit {
  @Input() Downpayment!: number;
  @Input() EmiAmount!: number;
  @Input() Loanamount!: number;
  @Input() NumberOfTotalEMI!: number;
  @Input() ProcessingFee!: number;
  @Input() PayToday!: number;
  @Input() FirstEMIDueDate!: string;
  constructor() {}

  ngOnInit(): void {}
}
