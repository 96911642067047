import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var Moengage; 

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  moEngageTravelKnowMore(){
    
    //Moengage.track_event("Education Know More");
    //Moengage.track_event("App Content Refreshed", {"HomePage": "Travel Know More", "moe_non_interactive": 1})
    Moengage.track_event("HomePage - Travel Know More", {"HomePage": "Travel Know More", "moe_non_interactive": 1})
    
    this.router.navigateByUrl('/rules-for-traveling-the-world');
    // routerLink="/rules-for-traveling-the-world"
  }

  moEngageEducationKnowMore(){
    
    //Moengage.track_event("Education Know More");
    //Moengage.track_event("App Content Refreshed", {"HomePage": "Education Know More", "moe_non_interactive": 1})
    Moengage.track_event("HomePage - Education Know More", {"HomePage": "Education Know More", "moe_non_interactive": 1})
    
    this.router.navigateByUrl('/educationloan');
    // routerLink="/rules-for-traveling-the-world"
  }

}
