
export enum ApplicationStatus {
  SIGNUP = "sign_up",
  INCOMPLETE_REGISTRATION = "incomplete_registration",
  REGISTER = "registered",
  IN_PROCESS = "in_process",
  APPROVED = "approved",
  DENIED = "denied",
  EXPIRED = "expired",
  AUTO_BANK_STATEMENT_VERIFICATION_DENIED = "auto_bank_statement_verification_denied",
  AUTO_BANK_STATEMENT_VERIFICATION_DENIED_FRAUD="auto_bank_statement_verification_denied_fraud",
  PARTIAL_KYC_DOCS_UPLOADED = "partial_kyc_docs_uploaded",
  AUTO_ID_VERIFICATION_DENIED = "auto_id_verification_denied",
  ALL_KYC_DOCS_UPLOADED = "all_kyc_docs_uploaded",
  AUTO_BUSINESS_VERIFICATION_DENIED = "auto_business_verification_denied",
  LOAN_ELIGIBILITY_DENIED = "loan_eligibility_denied",
  SPLITFARE_ELIGIBILITY_NO = "splitfare_eligibility_no",
  TRAVEL_LOAN_ELIGIBILITY_NO = "travel_loan_eligibility_no",
  IN_PRINCIPAL_APPROVED = "in_principal_approved",
  SPLITFARE_ELIGIBILITY_YES = "splitfare_eligibility_yes",
  TRAVEL_LOAN_ELIGIBILITY_YES = "travel_loan_eligibility_yes",
  CALCULATE_IN_PRINCIPAL_LIMIT = "calculate_in_principal_limit",
  INITIATE_FLIGHTBOOKING = "initiate_flightbooking",
  BUREAU_DATA_MISMATCH  = "bureau_data_mismatch",
  CREDIT_PULL_FAIL = "credit_pull_fail",
  DEMOGRAPHIC_DENIED = "demographic_denied",
  IN_PRINCIPAL_DENIED = "in_principal_denied",
  CREDIT_PULL_FAIL_DENIED = "credit_pull_fail_denied",
  KYC_VERIFIED = "kyc_verified",
  L2_APPROVED ="l2_approved",
  AUTO_DENIED ="auto_denied",
  FINAL_DENIED_LENDING_PARTNER ="final_denied_lending_partner",
  FINAL_APPROVED_MIHURU = "final_approved_mihuru",
  FINAL_APPROVED_LENDING_PARTNER ="final_approved_lending_partner",
  DEVIATION ="deviation",
  AUTO_ADDRESS_VERIFICATION_DENIED = "auto_address_verification_denied",
  CREDIT_RULE_ENGINE_II_DENIED = "credit_rule_engine_ii_denied",
  AUTO_EMPLOYMENT_VERIFICATION_DENIED = "auto_employment_verification_denied",
  FINAL_DENIED_MIHURU = "final_denied_mihuru",
  KYC_UNDER_VERIFICATION = "kyc_under_verification",
  AUTO_APPROVED = "auto_approved",
  FINAL_APPROVED = "final_approved",
  L2_DENIED = "l2_denied",
  BOUNCED = "bounced",
  SUSPENDED = 'suspended',
  NEW_APPLICATION = "new_application",
  BANK_STATEMENT_UPLOADED = "bank_statement_uploaded",
  EMPLOYMENT_PROOF_UPLOADED = "employment_proof_uploaded" 
}

  