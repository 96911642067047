import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-its-work',
  templateUrl: './how-its-work.component.html',
  styleUrls: ['./how-its-work.component.scss']
})
export class HowItsWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
