import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var Moengage ;
@Component({
  selector: 'app-travel-loan',
  templateUrl: './travel-loan.component.html',
  styleUrls: ['./travel-loan.component.scss']
})
export class TravelLoanComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
  }
  
  moEngageTravelLoanApplyNow(){
    //Moengage.track_event("App Content Refreshed", {"Travel Loan": "Sign Up Initiated", "moe_non_interactive": 1})
    Moengage.track_event("Travel Loan - Sign Up Initiated", {"Travel Loan": "Sign Up Initiated", "moe_non_interactive": 1})
    this.router.navigateByUrl('/signup');
  }

}
