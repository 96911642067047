<div class="container policyDiv">
  <div class="row m-0">
    <div class="col-xs-12">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="terms-tab" data-toggle="tab" href="#terms" role="tab"
            aria-controls="terms" aria-selected="true">Terms of Use</a>
          <a class="nav-item nav-link" id="privacy-tab" data-toggle="tab" href="#privacy" role="tab"
            aria-controls="privacy" aria-selected="false">Privacy Policy</a>
        </div>
      </nav>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="terms" role="tabpanel" aria-labelledby="terms-tab">
          <app-terms></app-terms>
        </div>
        <div class="tab-pane fade" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">
          <app-privacy></app-privacy>
        </div>
      </div>
    </div>
  </div>
</div> 
