<div class="container-fluid applicationFormDiv">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-11 col-sm-11 col-11">
            <div class="row mt-5 mb-5 d-flex shadow-sm subPagesDiv">
                <div class="col-12 col-lg-8 col-md-8 col-sm-12 leftSection">
                    <div class="row h-100">
                        <div class="col-sm-12 my-auto" *ngIf="!finalApprovedMihuruOrLendingPartner">
                            <h1>Congratulations</h1> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/app-congrats.png" />
                            <p>You have been approved for an in-principle credit line of &#8377;
                                {{inPrincipleCreditLimit}}</p>
                            <div class="row justify-content-center text-center buttonDiv">
                                <div class="col-lg-5 col-sm-4 col-md-6 col-8">
                                    <button type="submit" class="btn btn-warning btn-block"
                                        (click)="uploadDocs()">Upload
                                        Documents</button>
                                </div>
                            </div>
                            <p class="note">The above credit line can be used on successful verification of your
                                documents.</p>
                        </div>
                        <div class="col-sm-12 my-auto" *ngIf="finalApprovedMihuruOrLendingPartner">
                            <h1>Congratulations</h1> <img src="../../../assets/images/app-congrats.png" />
                            <p>You have been approved for a credit line of &#8377;
                                {{inPrincipleCreditLimit}}</p>
                            <div class="row justify-content-center text-center buttonDiv">
                                <div class="col-lg-5 col-sm-4 col-md-6 col-8">
                                    <button type="submit" class="btn btn-warning btn-block"
                                    routerLink="/flights-search">Plan your travel now</button>
                                </div>
                            </div>
                            <!-- <p class="note">The above credit line can be used on successful verification of your
                                documents.</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 col-md-4 col-sm-12 rightSection">
                    <div class="row justify-content-center h-100 referUsDiv">
                        <div class="col-lg-8 col-10 buttonDiv my-auto">
                            <h2>Do your friends love travelling as much as you?</h2>
                            <div class="row justify-content-center">
                                <div class="col-lg-9 col-6 col-md-8">
                                    <button type="submit" class="btn btn-warning btn-block">Refer us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>