import { Pipe, PipeTransform, Component} from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'currencyConvert' })
export class CurrencyConvertPipe implements PipeTransform {
  // constructor(public testService: TestService) {

  // }
    constructor(private cPipe: CurrencyPipe) {
    }

  transform(value: any): string {
    let numericValue = 0;

    if (value !== null) {
      try {
        numericValue = Number(value);
      }
      catch (e) { }
    }

    // let lsVariables = new MemoryStorageServiceVariables();
    // let currencySymbol = lsVariables.getCurrentCurrency();
     var codecurrency = "";//lsVariables.getCurrentCurrency();
    // //Convert code from known currency symbols
    // // hex code from https://www.toptal.com/designers/htmlarrows/currency/ 
    // switch (currencySymbol) {
    //   case "INR": currencySymbol = "&#x20b9;"; break;
    //   case "USD": currencySymbol = "&#x24;"; break;
    //   case "GBP": currencySymbol = "&#xa3;"; break;
    //   case "EUR": currencySymbol = "&#x20AC;"; break;
    //   case "JPY": currencySymbol = "&#xa5;"; break;
    //   case "CNY": currencySymbol = "&#x5143;"; break;
    //   case "RUB": currencySymbol = "&#x20BD;"; break;
    //   default: currencySymbol = "&#x24;"; break;
    // }

    let conversionExchange = 1;

    //currency conversion service
    let amount = numericValue * conversionExchange;
    let amountCurrency = "0.00"

    if (amount > 0)
      amountCurrency = parseFloat((Math.round(amount * 100) / 100).toString()).toFixed(2);

    //add commas - https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    var parts = amountCurrency.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    amountCurrency = parts[0]; //.join(".");
    var amountnew = Math.ceil(numericValue);
    
    var currency = this.cPipe.transform(amountnew, codecurrency, true,"1.0-0");

    return currency ;
    //return currencySymbol + amountCurrency;
  }
}