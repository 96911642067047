<circle-progress
[backgroundColor]="'#fff'"
[backgroundPadding]="-18"
[percent]="percentage"
[radius]="80"
[outerStrokeWidth]="10"
[innerStrokeWidth]="10"
[outerStrokeColor]="'#ffbb44'"
[innerStrokeColor]="'#ffbb44'"
[animation]="true"
[animationDuration]="300"
[startFromZero]="false"
[showSubtitle]="false"
[unitsColor]="'#002d3a'"
[titleColor]="'#002d3a'"
[titleFontSize]="40"
[titleFontWeight]="500"
></circle-progress>