import { Component, OnInit } from '@angular/core';
import { TermsPrivacyService } from 'src/app/core/services/terms-privacy.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  termsText:string = "";
  constructor(private termsPrivacyService:TermsPrivacyService) { }

  ngOnInit(): void {
    let obj = {
      type:"termsConditions"
    }
    this.termsPrivacyService.getTermsPrivacy(obj).subscribe(response=>{
      if (response["data"] != null) {
        this.termsText = response["data"].data
      }
    })
  }

}
