import { Component, OnInit } from '@angular/core';

import { QuotesService } from '../../../core/services/quotes.service';
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  quote: string = "";
  artist: string = "";
  
  constructor(private quotesService: QuotesService) { }

  ngOnInit(): void {
    this.setCurrentQuote();
  }

  setCurrentQuote() {
    this.quotesService.currentQuote.subscribe(quote => {
      console.log(quote);
      this.quote = quote.message;
      this.artist = quote.artist;
    })
  }

}
