import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { Plan } from 'src/app/core/models/personalInfo';
import { AccountService } from 'src/app/core/services/account.service';
import { PaymentGatewayPopComponent } from '../payment-gateway-pop/payment-gateway-pop.component';
import { interval, Subscription } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';
//import { User } from 'src/app/core/models/user';
declare var Razorpay: any;

export interface DownPaymentStatusRequest {
  type: string,
  merchantTxnId: string,
  status: string
}

export interface GenerateDownPaymentLinkRequest {
  amount: string,
  message: string,
  expiry_date: string,
  name: string
}

export class RazorpaySuccessResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

export class RazorpayFailureResponse {
  code: string;
  description: string;
  source: string;
  step: string;
  reason: string;
  orderId: string;
  paymentId: string;
}

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss'],
})
export class PaymentGatewayComponent implements OnInit {
  @ViewChild(PaymentGatewayPopComponent) paymentPopup: PaymentGatewayPopComponent;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private accountService: AccountService,
    private router: Router,
    private sessionTimerService :SessionTimerService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    this.selectedPlan = JSON.parse(MemoryStorageService.getItem('finalplan')!);
    this.user = JSON.parse(MemoryStorageService.getItem('user')!);
    this.secretKey =environment.RazorPaySecretKey;
    this.RazorPayKeyId =environment.RazorPayKeyID;
  }
  failureResponse: RazorpayFailureResponse;
  successResponse: RazorpaySuccessResponse;
  secretKey: string = '';
  RazorPayKeyId ='';
  order_id: string;
  selectedPlan: any;
  user: any;
  baseUrl!: string;

  downPaymentStatusRequest: DownPaymentStatusRequest = {
    type: '',
    merchantTxnId: '',
    status: ''
  }

  generateDownPaymentLinkRequest: GenerateDownPaymentLinkRequest = {
    amount: '',
    message: '',
    expiry_date: '',
    name: ''
  }

  paymentStatusSubscription: Subscription;
  merchantTxnId: string = '';
  paymentGatewayURL: string;
  isUPIAutoPay: boolean =  false;


  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
    let status = '';
    this.route.queryParamMap.subscribe(params => {
      status = params.get('status');

      /****  This Success/Failure we are using for Only updating the status if came here from digio nach. for PuPay Nach or AutoPay status already updated before comming here ***/
      if(status === 'failure'){
        // TODO update failed status on Table
        this.http.post(environment.apiUrl + 'ENachAPI/enachWebHookUpdate', { status: status }).subscribe(
        (res) => {
          console.log(res); 
        });
        let mandateId = params.get('digio_doc_id');
        this.accountService.updateNACHApplicationStatus(mandateId, 1);
      }
      else if(status === 'success'){
        let mandateId = params.get('digio_doc_id');
        this.accountService.updateNACHApplicationStatus(mandateId, 2);
        //TODO update success status on Table
      }
      /*****************************************/

      /****** check if came from UPI Autopay (<15000), to redirect next on loan agreement page after downpayment, if case from NACH then on Success Downpayment redirect to PartnerUrl or Success page - usual flow *****/
      const source = params.get('source');
      if(source && source === 'upi')
        this.isUPIAutoPay = true;
      /*****************************************/
    });

    if(status === 'failure')
    {
      this.router.navigateByUrl('/assist-journey/nach-mandate');
      return;
    }

    /********** for showing Easebuzz Payment Gateway ***********/
    if(environment.showeasebuzz)
    {
      this.downPaymentStatusRequest.type = "Check";

      this.accountService.checkUpdateDownpayment(this.downPaymentStatusRequest).subscribe((response: any) => {
        if (response['data']) {
          const data = response['data'];
          if (data.response_msg === 'Downpayment Successfull') {
            try{
              this.PaymentUpdateEaseBuzz('paid', this.merchantTxnId).then(() => {
                console.log('Payment Update Complete');
              }).catch(error => {
                console.error('Payment Update Error:', error);
              });
            }catch(err){
              // ignore any error as status reason must have been updated already
            }
            if(this.isUPIAutoPay){
              this.router.navigateByUrl('/assist-journey/loan-agreement?status=success&source=api');
            }
            else{
              this.router.navigateByUrl('/assist-journey/assist-success');
            }
          } 
          else if (data.response_msg === 'Already Link Present') {
            this.paymentGatewayURL = data.url;
            this.merchantTxnId = data.merchantTxnId;
            this.openPopup(this.paymentGatewayURL, this.merchantTxnId);
          }
          else {
            let amount: number = parseFloat(this.selectedPlan['downpayment'] + this.selectedPlan['gst'] + this.selectedPlan['processing_fee']);
            this.generateDownPaymentLinkRequest.amount =  amount.toFixed(2);
            MemoryStorageService.setItem('DownPaymentAmount', this.generateDownPaymentLinkRequest.amount);
            this.generateDownPaymentLinkRequest.expiry_date = '0';
            this.generateDownPaymentLinkRequest.message = 'TNPL';
            this.generateDownPaymentLinkRequest.name = this.user['fullname'];
            this.accountService.GenerateDownpaymentLink(this.generateDownPaymentLinkRequest).subscribe((response: any) => {
              console.log(response);
              if (response['data'] != null && response['data']) {
                  const res = JSON.parse(response['data']);
                  if(res.data.hasOwnProperty('payment_url') && res.data.payment_url != null){
                    this.paymentGatewayURL = res.data.payment_url;
                    this.merchantTxnId = res.data.merchant_txn;
                    MemoryStorageService.setItem('MihuruTransactionId', this.merchantTxnId );
                    this.openPopup(res.data.payment_url, res.data.merchant_txn);
                  }
              }
            });
          }
        }
        else if(response['error'])
        {
          swal.fire('Something went wrong!');
        }
      });
    }
    /*************************************************************************/
    /********** for showing RazorPay Payment Gateway (Not using now) *********/
    else
    {
      let customerToken = MemoryStorageService.getItem('customerToken');
      //call generate order id
      let req: any = {
        amount:
          this.selectedPlan['downpayment'] +
          this.selectedPlan['gst'] +
          this.selectedPlan['processing_fee'],
        receipt: 'r1',
        notes: { notes_key_1: MemoryStorageService.getItem('user')['fullname'] },
      };
      this.accountService.generatePaymentOrder(req, customerToken).subscribe(
        (res) => {
          console.log(res);
          this.loadRazorpayScript(
            res['id'],
            this.selectedPlan['downpayment'] +
              this.selectedPlan['gst'] +
              this.selectedPlan['processing_fee'],
            MemoryStorageService.getItem('user')['fullname'],
            MemoryStorageService.getItem('user')['email'],
            MemoryStorageService.getItem('user')['mobileNumber']
          );
        },
        (err) => {
          console.log(err);
        });
      }
    /*************************************************************** */
    // this.ProceedToPayment();
  }

  GeneratePayNow(plan: Plan) {
    return plan.processing_fee! + plan.gst! + plan.downpayment!;
  }
  loadRazorpayScript(
    order_id: string,
    amount: number,
    customerName: string,
    customerEmail: string,
    customerMobile: string
  ): void {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () =>
      this.initRazorpay(
        order_id,
        amount,
        customerName,
        customerEmail,
        customerMobile
      );
    document.head.appendChild(script);
  }

  initRazorpay(
    orderId: string,
    amount: number,
    customerName: string,
    customerEmail: string,
    customerMobile: string
  ): void {
    const options = {
      key: this.RazorPayKeyId, // Enter your Razorpay key ID
      amount: amount, // Amount is in currency subunits (e.g., paise). For 500 INR, use 50000.
      currency: 'INR',
      name: 'Mihuru',
      description: '',
      image: 'https://partnersplus.mihuru.com/favicon.png',
      order_id: orderId,
      handler: (response) => {
        this.successResponse = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        this.checkRazorpayRes(this.successResponse);
      },
      prefill: {
        name: customerName,
        email: customerEmail,
        contact: customerMobile,
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#044b5f',
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on('payment.failed', (response) => {
      this.failureResponse = {
        code: response.error.code,
        description: response.error.description,
        source: response.error.source,
        step: response.error.step,
        reason: response.error.reason,
        orderId: response.error.metadata.order_id,
        paymentId: response.error.metadata.payment_id,
      };
    });
    // document.getElementById('rzp-button1').onclick = function(e) {
    rzp1.open();
    //   e.preventDefault();
    // };
  }


  // checkRazorpayRes(successResponse: RazorpaySuccessResponse): void {
  //   const data =
  //     successResponse.razorpay_order_id +
  //     '|' +
  //     successResponse.razorpay_payment_id;
  //   const generatedSignature = CryptoJS.HmacSHA256(
  //     data,
  //     this.secretKey
  //   ).toString(CryptoJS.enc.Hex);
  //   if (generatedSignature === successResponse.razorpay_signature) {
  //     console.log('Signature verified successfully');
  //     // swal.fire('Payment Successfull')
  //     this.PaymentUpdate('paid'); 
       
          
  //   } else {
  //     this.PaymentUpdate('fail');
  //     console.error('Signature verification failed');
      
  //   }
  // }

  checkRazorpayRes(successResponse: RazorpaySuccessResponse): void {
    const data =
      successResponse.razorpay_order_id +
      '|' +
      successResponse.razorpay_payment_id;
    const generatedSignature = CryptoJS.HmacSHA256(
      data,
      this.secretKey
    ).toString(CryptoJS.enc.Hex);
    
    if (generatedSignature === successResponse.razorpay_signature) {
      console.log('Signature verified successfully');
      this.PaymentUpdate('paid').then(() => {
        console.log('Payment Update Complete');
      }).catch(error => {
        console.error('Payment Update Error:', error);
      });
    } else {
      this.PaymentUpdate('fail').then(() => {
        console.error('Payment failed');
      }).catch(error => {
        console.error('Payment Update Error:', error);
      });
      console.error('Signature verification failed');
    }
  }
  

  conditionAccept: boolean = true;
  ProceedToPayment() {
    if (this.conditionAccept) {
      let request = {
        amount: this.GeneratePayNow(this.selectedPlan),
        currency: 'INR',
        notes: { note1: 'Monedo' },
      };

      //var currentUser!: User;
      // if (this.accountService.currentUser$ != null) {
      //   this.accountService.currentUser$.subscribe(user => {
      //     currentUser = (user != null ? user : null)!;
      //   });
      // }
      // let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + currentUser.token);
      return this.http
        .post(
          environment.apiUrl + 'PaymentGateway/Razorpay/GenerateOrder',
          request //, {headers: headers1,}
        )
        .subscribe({
          next: (response: any) => {
            console.log(response); 
            this.order_id = JSON.parse(response['data'])['id'];
            this.loadRazorpayScript(
              this.order_id,
              this.GeneratePayNow(this.selectedPlan),
              this.user.fullname,
              this.user.email,
              this.user.mobileNumber
            );
          },
        });
    }
  }

  // async PaymentUpdate(payment_status :string):Promise<void>
  // {
     
  //   this.route.queryParams.subscribe((params: any) => {
  //     this.baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
  //     let obj = {
  //       'ResponseId': this.successResponse.razorpay_order_id,
  //       'payment_id': this.successResponse.razorpay_payment_id,
  //       'payment_link_status': payment_status,
  //       'signature': this.successResponse.razorpay_signature
  //     }
  //     let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + MemoryStorageService.getItem('customerToken'));
  //     return this.http.post(this.baseUrl, obj,{ headers: headers1 }).subscribe(
  //        response=> {
  //         if (response['data']!=null) {
  //           if(response['data'].partnerCallbackUrl !=null)
  //           {
  //           let queryParams = '?api=paymentgateway&payment_id=' + this.successResponse.razorpay_payment_id + '&payment_reference_id=' + this.successResponse.razorpay_order_id + '&payment_status='+payment_status+'&partnerTransactionId='+response['data'].partnerTransactionId +'&amount='+response['data'].Amount;
  //           window.location.replace("https://"+response['data'].partnerCallbackUrl + queryParams);
  //           }
  //           else
  //           {
  //             this.router.navigateByUrl('/assist-journey/assist-success');
  //           }
              
  //         }
  //       },
  //     );
  //   });
  // }


async PaymentUpdate(payment_status: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    this.route.queryParams.subscribe((params: any) => {
      this.baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
      let obj = {
        'ResponseId': this.successResponse.razorpay_order_id,
        'payment_id': this.successResponse.razorpay_payment_id,
        'payment_link_status': payment_status,
        'signature': this.successResponse.razorpay_signature
      };
      //let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + MemoryStorageService.getItem('customerToken'));
      //this.http.post(this.baseUrl, obj, { headers: headers1 }).subscribe(
        this.http.post(this.baseUrl, obj).subscribe(
        response => {
          if (response['data'] != null) {
            if (response['data'].partnerCallbackUrl != null && response['data'].partnerCallbackUrl !== 'undefined' && response['data'].partnerCallbackUrl != '') {
              // let queryParams = '?api=paymentgateway&payment_id=' + this.successResponse.razorpay_payment_id + '&payment_reference_id=' + this.successResponse.razorpay_order_id + '&payment_status=' + payment_status + '&partnerTransactionId=' + response['data'].partnerTransactionId + '&amount=' + response['data'].Amount;
              // window.location.replace("https://" + response['data'].partnerCallbackUrl + queryParams);
              window.location.replace("https://" + response['data'].partnerCallbackUrl);
            } else {
              this.router.navigateByUrl('/assist-journey/assist-success').then(() => resolve());
            }
          }
        },
        error => {
          reject(error);
        }
      );
    });
  });
}

async PaymentUpdateEaseBuzz(payment_status: string, MerchantId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    this.route.queryParams.subscribe((params: any) => {
      this.baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
      let obj = {
        'ResponseId': MerchantId,
        'payment_id': MerchantId,
        'payment_link_status': payment_status,
        'signature': "OK"
      };

      MemoryStorageService.setItem('TransactionId', MerchantId);
      //let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + MemoryStorageService.getItem('customerToken'));
      //this.http.post(this.baseUrl, obj, { headers: headers1 }).subscribe(
        this.http.post(this.baseUrl, obj).subscribe(
        response => {
          if (response['data'] != null) {
            if (response['data'].partnerCallbackUrl != null && response['data'].partnerCallbackUrl !== 'undefined' && response['data'].partnerCallbackUrl != '') {
              // let queryParams = '?api=paymentgateway&payment_id=' + this.successResponse.razorpay_payment_id + '&payment_reference_id=' + this.successResponse.razorpay_order_id + '&payment_status=' + payment_status + '&partnerTransactionId=' + response['data'].partnerTransactionId + '&amount=' + response['data'].Amount;
              // window.location.replace("https://" + response['data'].partnerCallbackUrl + queryParams);
              if(this.isUPIAutoPay){

                /*** Redirect if DownPayment Payment Failed for Sterling Holidays ***/
                if(payment_status === 'unpaid'){
                  if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                    {
                      const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentFailed" };
                      this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                      return;
                    }
                }
                /*******************/

                this.router.navigateByUrl('/assist-journey/loan-agreement?status=success&source=api').then(() => resolve());
              }
              else{
                
                /*** Redirect if DownPayment Payment Failed/Success for Sterling Holidays ***/
                if(payment_status === 'paid'){
                  if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                  {
                    const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentCompleted", mihuruTransactionId: MerchantId , amount: MemoryStorageService.getItem('DownPaymentAmount') };
                    this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                    return;
                  }
                }
                else{
                  if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                    {
                      const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentFailed" };
                      this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                      return;
                    }
                }
                /*******************/
                
                window.location.replace("https://" + response['data'].partnerCallbackUrl);
              }
              
            } else {
              if(this.isUPIAutoPay){
                this.router.navigateByUrl('/assist-journey/loan-agreement?status=success&source=api').then(() => resolve());
              }
              else{
                this.router.navigateByUrl('/assist-journey/assist-success').then(() => resolve());
              }
              
            }
          }
        },
        error => {
          reject(error);
        }
      );
    });
  });
}

openPopup(url: string, merchantTxnId: string): void {
  this.startPaymentStatusCheck();
  document.getElementById('popup-payment')!.style.display = 'block';
  document.getElementById('overlay-payment')!.style.display = 'block';
  this.paymentPopup.openWindow(url, merchantTxnId, this.isUPIAutoPay);
}


startPaymentStatusCheck(): void {
  if (this.paymentStatusSubscription) {
    this.paymentStatusSubscription.unsubscribe();
  }

  this.paymentStatusSubscription = interval(10000).subscribe(async () => {
    const response = await this.accountService.FetchDownPaymentStatus(this.merchantTxnId).toPromise();
    if (response['data']) {
      const result = JSON.parse(response['data']);
      if (result.hasOwnProperty('data') && result.data[0].hasOwnProperty('status') && result.data[0].status === 'Paid') {
        // Update Status to Paid
        if (this.paymentStatusSubscription) {
          this.paymentStatusSubscription.unsubscribe();
        }
        
        // this.downPaymentStatusRequest.type = 'Update';
        // this.downPaymentStatusRequest.merchantTxnId = this.merchantTxnId;
        // this.downPaymentStatusRequest.status = 'paid';
        //this.accountService.checkUpdateDownpayment(this.downPaymentStatusRequest).subscribe((response) => {});
        this.paymentPopup.CloseWindow();

        this.PaymentUpdateEaseBuzz('paid', this.merchantTxnId).then(() => {
          console.log('Payment Update Complete');
        }).catch(error => {
          console.error('Payment Update Error:', error);
        });
      }
    }
  });
}

}
