import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-agent',
  templateUrl: './travel-agent.component.html',
  styleUrls: ['./travel-agent.component.scss']
})
export class TravelAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
