import { Injectable } from '@angular/core';

import { Observable, of, ReplaySubject } from 'rxjs';
import { Quote } from '../models/quote';

@Injectable({
providedIn: 'root'
})
export class QuotesService {

    private currentQuoteSource = new ReplaySubject<Quote>(1);
    currentQuote = this.currentQuoteSource.asObservable();

    constructor() {
    }

    setCurrentQuote(quote: Quote) {
        this.currentQuoteSource.next(quote);
    }
}