import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener  } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { IMenuItem, SharedDataService } from  'src/app/core/services/shared-data.service';
import { User } from './../../core/models/user';

export interface Country {
  Code?: string;
  Name?: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {
  
  // @ViewChild('stickyMenu') menuElement: ElementRef;

  @ViewChild('menu1') menuElement1: ElementRef;

  sticky: boolean = false;
  elementPosition: any;

  currentUser$: Observable<User>;
  menuItems$: Observable<IMenuItem[]>;
  isMenuCollapsed: boolean = true;
  isCollapsed: boolean = true;
  isLoggedIn: boolean = false;
  IsMobile: boolean = false;
  navbarOpen: boolean = false;
  selectedCountry: boolean = true;
  navWhiteBg: boolean = false;
  showFlag: Country = {
    Code: "India",
    Name: "India"
  }

  showAboutUs: boolean = true;
  showProducts: boolean = true;
  showBook: boolean = true;
  showPartnerDirectory: boolean = true;
  showPartners: boolean = true;
  showExplore: boolean = true;
  showContactDetails: boolean = true;
  showApplication: boolean = true;

  showOnMobile: boolean = false;
  scrollOnMobile: boolean = false;
  isParterLogo :boolean =false;
  partnerLogourl :string ='';

  constructor(
    private router: Router,
    private accountService: AccountService,
    private sharedDataService : SharedDataService,
    private el: ElementRef,
    private route: ActivatedRoute,
  ) {
    this.checkForMobilBrowser();
    this.menuItems$ = this.sharedDataService.menuItemList$;
    this.sharedDataService.menuItemList$.subscribe(items =>{
      this.setMenuItemsVisibility(items);
    })
  }

  checkForMobilBrowser() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.showOnMobile = true;
    } else {
      this.showOnMobile = false;
    }
  }

  setMenuItemsVisibility(menuItems: IMenuItem[]) {
    menuItems.forEach(item => {
      switch(item.Name){
        case 'AboutUs' :
          this.showAboutUs = item.Visible;
          break;
        case 'Products' :
          this.showProducts = item.Visible;
          break;
        case 'Book' :
          this.showBook = item.Visible;
          break;
        case 'PartnerDirectory' :
          this.showPartnerDirectory = item.Visible;
          break;
        case 'Partners' :
          this.showPartners = item.Visible;
          break;
        case 'Explore' :
          this.showExplore = item.Visible;
          break;
        case 'ContactDetails' :
          this.showContactDetails = item.Visible;
          break;
      }
    });
  }

  ngOnInit(): void {
    this.currentUser$ = this.accountService.currentUser$;
    this.checkForMobilBrowser();
    this.sharedDataService.applicationMenuDisplay.subscribe(value => {
      this.showApplication = value;
    })
   this.getPartnerLogo();
  }

  ngAfterViewInit(){
    this.elementPosition = this.menuElement1?.nativeElement?.offsetTop;
  }

  // @HostListener('window:scroll', ['$event'])
  //   handleScroll(){
  //     const windowScroll = window.pageYOffset;
  //     if(windowScroll >= this.elementPosition){
  //       this.sticky = true;
  //     } else {
  //       this.sticky = false;
  //     }
  //   }


  @HostListener('window:scroll', ['$event'])
    handleScroll(){
      this.scrollOnMobile = true;
      const windowScroll = window.pageYOffset;
      if(windowScroll <= 1) {
        this.scrollOnMobile = false;
        this.navWhiteBg = false;
      }
      else if(windowScroll > 1){
        this.navWhiteBg = true;
      }
      
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkForMobilBrowser();
  }

  getCurrentUser() {
    this.accountService.currentUser$.subscribe(user => {
      this.isLoggedIn = !!user; 
    }, error => {
    })
  }

  loadAndShowCountryDialog() {

  }

  toggleNavbar() {

  }

  GoToSignout() {
    this.accountService.logout();
    this.router.navigateByUrl('/home');
    this.sharedDataService.setDefaultMenuVisibility();
  }

  changeApplication(val) {
    if (val === "personal") {
      this.router.navigateByUrl('/application/appl/(application:personal)')
    } else if(val == "professional") {
      this.router.navigateByUrl('/application/appl/(application:professional)')
    } else if(val == "uploaddocs") {
      this.router.navigateByUrl('/application/appl/(application:uploaddocs)')
    }
  }

  goToHome() {
    let routeUrl: string = this.router.url;
    if (!routeUrl.includes('application')) {
      this.router.navigateByUrl('/');
    }
  }
//check partnerLogo
getPartnerLogo()
{
this.accountService.partnerLogo.subscribe(newUrl => {
  this.partnerLogourl = newUrl;
  if(this.partnerLogourl !='')
    {
    this.isParterLogo =true;
    }
});
}
}
