import { HttpClient } from '@angular/common/http';
import {
Component,
EventEmitter,
OnInit,
Output,
Sanitizer,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-identity-proof',
  templateUrl: './identity-proof.component.html',
  styleUrls: ['./identity-proof.component.scss'],
})
export class IdentityProofComponent implements OnInit {

  baseUrl!: string;
  constructor(private router: Router, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService) {
    this.baseUrl = environment.apiUrl + 'assistmodule/v1/createUserProfile';
  }
  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
  }

  ProceedToDocUpload(response: any) {
    this.spinner.show();
    console.log(response);
    if (response['error']==null) {
      //swal.fire(response['data']['response_msg']);
      swal.fire({
        text: ''+response['data']['response_msg'],
        //icon: 'info',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigateByUrl('/assist-journey/address-proof');
        }
      });

    } else {
      swal.fire(response['error']['response_msg']);
      // if(response['error']['response_msg'] == "PAN details fetched from OCR doesn't match with details confirmed by user")
      // {
      //   this.router.navigateByUrl('/sorry');
      // }else
      // {
        this.router.navigateByUrl('/assist-journey/identity-proof');
      // }
    }
    this.spinner.hide();
  }
}
