<div class="container-fluid rightSection">
    <div class="row m-0 justify-content-center">
        <div class="col-sm-9 col-lg-6 p-0">
            <div class="row d-flex formDiv">
                <div class="col-12 col-sm-12 rightSection">
                    <div class="row justify-content-center personalDetails">
                        <div class="col-12">
                            <div class="signUp">
                                <div class="applicationForm">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h3>Pay now</h3>
                                        </div>
                                    </div>
                                    <div class="rōw justify-content-center mt-4">
                                        <div class="col-12">
                                            <div class="border-box">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group"> <label class="control-label d-none d-sm-block">UPI</label>
                                                            <input name="bankname" class="form-control" placeholder="abc@okaxis">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12">
                                                        <p>Please continue on your UPI app to complete the transaction</p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-6">
                                                        <div class="progress">
                                                            <div class="progress-bar bg-info progress-bar-striped" style="width: 90%">5:00 min left</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center text-center buttonDiv mb-5">
                                                <div class="col-sm-3 col-md-4 col-8">
                                                    <button class="btn btn-warning btn-block">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>