import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Plan } from '../../../core/models/personalInfo';
import swal from 'sweetalert2';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';

@Component({
  selector: 'app-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss'],
})
export class EsignComponent implements OnInit {
  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.baseUrl = environment.apiUrl + 'assistmodule/v1/createUserProfile';
  }

  conditionAccept: boolean = false;
  digio_doc_id!: string;
  message!: string;
  npci_txn_id!: string;
  status!: string;
  baseUrl!: string;
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.digio_doc_id = params['digio_doc_id'];
      this.message = params['message'];
      this.npci_txn_id =
        params['npci_txn_id'] == null ? '' : params['npci_txn_id'];
      this.status = params['status'];
      // swal.fire(this.digio_doc_id+' '+this.message+' '+this.npci_txn_id+' '+this.status);
    });

    let finalplan: Plan = JSON.parse(MemoryStorageService.getItem('finalplan')!);

    let body = {
      requestCode: '5015',
      emiAmount: finalplan.emi_amount,
      emiPercent: finalplan.roi,
      firstEmiDueDate: finalplan.emi_date,
      processingFee: finalplan.processing_fee,
      loanAmount: finalplan.loan_amount,
      totalNumberOfEmi: '',
      ipAddress: '', //take it from request header in api request backend
    };

    // return this.http.post(this.baseUrl, body).subscribe((response:any) => {
    //   console.log(response);
    // });
  }
}
