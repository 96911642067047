<div class="section2">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <h2 class="text-center">Easy Instalment plans</h2>
                <div class="row leftcontent">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-6 col-md-4 mb-4 border-right">
                                <div id="benifits-box">
                                    <div class="face">
                                        <img src="../../../../assets/images/installment-plan-01.png" />
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Single instalment</h3>
                                        <p>Pay in a single instalment within 45 days of making your booking</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 mb-4 border-right">
                                <div id="benifits-box">
                                    <div class="face">
                                        <img src="../../../../assets/images/installment-plan-02.png" />
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Fortnightly instalment</h3>
                                        <p>Experience the freedom to pay in 2-6 convenient instalments
                                            every 15 days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 mb-4">
                                <div id="benifits-box">
                                    <div class="face">
                                        <img src="../../../../assets/images/installment-plan-03.png" />
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Monthly instalment</h3>
                                        <p>Simple. Easy. Convenient. Regular monthly payment up to 12 months</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>