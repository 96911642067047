<app-menu *ngIf="!hideHeader"></app-menu>
<div class="container-fluid applicationFormDiv justify-content-center">
    <div class="row d-flex formDiv justify-content-center">
        <div class="col-10 rightSection">
            <div class="row justify-content-center">
                <div class="col-12"><app-assist-header-banner></app-assist-header-banner>
                </div>
            </div>
            <div class="row justify-content-center mt-3 pb-5 d-flex flex-lg-row-reverse">
                <div class="col-12">
                    <app-assist-journey></app-assist-journey>
                </div>
            </div>
            <!-- <div class="row justify-content-center mt-3 pb-5">
                <div class="col-12">
                    <app-steps-freedom></app-steps-freedom>
                </div>
            </div> -->
        </div>
    </div>
</div>
<app-footer *ngIf="!hideFooter"></app-footer>