<div class="section2">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <h2 class="text-center">Your benefits with Mihuru</h2>
                <div class="row leftcontent">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-6 col-md-4 mb-4">
                                <div id="benifits-box">
                                    <div class="face">
                                        30<span>%</span>
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Increase in business and conversions!</h3>
                                        <p>Eg: A partner travel agent saw his monthly sales grow from
                                            &#8377; 4,00,000 to &#8377; 5,20,000</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 mb-4">
                                <div id="benifits-box">
                                    <div class="face">
                                        40<span>%</span>
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Increase in booking value as customer affordability rises</h3>
                                        <p>Travel agents have witnessed their customers going on longer vacations and
                                            sometimes even upgrading to international bookings</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 mb-4">
                                <div id="benifits-box">
                                    <div class="face">
                                        25<span>%</span>
                                    </div>
                                    <div class="shadow scale"></div>
                                    <div class="message">
                                        <h3>Boost in customer acquisition</h3>
                                        <p>Acquire more customers without spending additional expenses on marketing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row benefitsDiv">
                            <div class="col-sm-12 mb-2">
                                <h3><span>Additional Business </span> <br class="d-block d-sm-none"> Opportunity to
                                    acquire customers on credit</h3>
                                <p>
                                    With credit lines to customers at any time, fund their travel with ease - Growing
                                    business by tapping these additional customers that wish to make bookings on credit
                                </p>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <h3><span>Simple process </span><br class="d-block d-sm-none"> Simple yet quick credit
                                    to customers</h3>
                                <p>
                                    Customers, just have to fill up a simple application form and provide minimum
                                    documents
                                </p>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <h3><span>Travel industry expertise </span> <br class="d-block d-sm-none">Customized
                                    credit line for customers</h3>
                                <p>Our financing solution is designed exclusively for each customer. Our travel credit
                                    is crafted to each customers financial profile
                                </p>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <h3><span>Flexible and transparent </span> <br class="d-block d-sm-none">Choice of
                                    repayment modes. No hidden charges
                                </h3>
                                <p>What a customer sees is what they pay. Our repayment modes provided are tailored to
                                    individual requirements and profile
                                </p>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <h3><span>World class support</span><br class="d-block d-sm-none"> Our dedicated support
                                    team is here to help</h3>
                                <p>Our staff is ready to clear any blockages or doubts you may have with the application
                                    process and related to customers travel finance
                                </p>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <h3><span>100% free</span><br class="d-block d-sm-none"> No business growth commission
                                    paid</h3>
                                <p>No commission is to be paid for the resultant rise in travel agent business i.e. all
                                    the benefits at no cost!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>