<div class="row justify-content-center mt-3  d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="mt-4">
            <div class="row user_details">
                <div class="col-sm-11 col-12 mb-4">
                    <p><span>Hi Prajakta! </span>You are just a step away from financing your trip! Please confirm the following details to proceed.</p>
                </div>
            </div>
            <div class="row content_div mb-3">
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-origin.png" alt="Origin" />
                        <p>
                            Origin
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-destination.png" alt="Destination" />
                        <p>
                            Destination
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-traveldate.png" alt="Travel date" />
                        <p>
                            Travel date
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-numberofpassengers.png" alt="Number of passengers" />
                        <p>
                            Number of passengers
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-traveltype.png" alt="Travel type" />
                        <p>
                            Travel type
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-travelcost.png" alt="Travel cost" />
                        <p>
                            FTravel cost
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="table"><img src="../../assets/images/assist-travelagent.png" class="Travel agent" />
                        <p>
                            Travel agent
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="col-12">
                    <div class="boxes">
                        <input type="checkbox" id="box1" class="form-check-input" name="checkbox" required>
                        <label for="box1">I have verified my travel details and associated fare rules of the booking agent and I shall not hold Mihuru responsible for any changes in rules by the booking agent. I will pay for the instalments in the Payment Plan in such manner as prescribed by Mihuru as and when the instalments become due. I
                            authorize Mihuru to cancel the purchased package on my behalf in the event I fail to pay the instalments on time.</label>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center text-center buttonDiv mt-3">
                <div class="col-6 text-lg-right">
                    <button type="submit" class="btn btn-default mt-3"> Cancel </button>
                </div>
                <div class="col-6 text-lg-left">
                    <button type="submit" class="btn btn-warning mt-3"> Proceed </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-12">
        <app-assist-emi-table></app-assist-emi-table>
    </div>
</div>