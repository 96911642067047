import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { MobileNumberPatternDirective } from './directives/mobile-number-pattern.directive';
import { CustomvalidationService } from './services/customvalidation.service';

import { ProgressbarService } from './services/progressbar.service';
import { QuotesComponent } from './components/quotes/quotes.component';
import { CurrencyConvertPipe } from './pipes/CurrencyConvertPipe';
import { AirlineCodeConvertPipe } from './pipes/AirlineCodeConvertPipe';
import { DurationTimeFormatPipe } from './pipes/DurationTimeFormatPipe';
import { MyTimePipe } from './pipes/minutestohours';
import { decimalToHHMM } from './pipes/decimaltohhmm';
import { TimerCounter } from './pipes/timerService';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { EncryptPhoneEmailPipe } from './pipes/encrypt-phone-email.pipe';

@NgModule({
    declarations: [
        ProgressbarComponent,
        MobileNumberPatternDirective,
        QuotesComponent,
        MyTimePipe,
        decimalToHHMM,
        CurrencyConvertPipe,   
        AirlineCodeConvertPipe,
        DurationTimeFormatPipe,
        ClickOutsideDirective,
        AutocompleteOffDirective,
        NumbersOnlyDirective,
        EncryptPhoneEmailPipe
    ],
    imports: [
      CommonModule,
      NgCircleProgressModule
    ],
    exports: [
        ProgressbarComponent,
        QuotesComponent,
        ClickOutsideDirective,
        AutocompleteOffDirective,
        NumbersOnlyDirective,
        MobileNumberPatternDirective,
        EncryptPhoneEmailPipe
    ],
  providers: [MyTimePipe,CurrencyPipe,decimalToHHMM,TimerCounter]
  })
  export class SharedModule {
    static forRoot() {
      return {
        ngModule: SharedModule,
        providers: [ProgressbarService]
      };
    }
  }