import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStatus } from 'src/app/core/Enums/ApplicationStatus';
import { AccountService } from 'src/app/core/services/account.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsComponent implements OnInit {


  finalApprovedMihuruOrLendingPartner : boolean = false;

  
  inPrincipleCreditLimit: string = "______";
  constructor(
    private router: Router,
    private accountService: AccountService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.sharedDataService.inPrincipleCreditLimit.subscribe(value => {
      this.inPrincipleCreditLimit = value;
    })
    this.getCurrentApplication();
  }

  uploadDocs() {
    this.router.navigateByUrl('/application/appl/(application:uploaddocs)')
  }

  getCurrentApplication() {
    this.accountService.getCurrentApplicationStatus().subscribe(response => {
      this.inPrincipleCreditLimit = response.inPrincipleLimit.toString();
      switch (response.currentStatus.toLowerCase()) {
        case ApplicationStatus.FINAL_APPROVED_MIHURU:
        case ApplicationStatus.FINAL_APPROVED_LENDING_PARTNER: {
          this.finalApprovedMihuruOrLendingPartner = true;
          break;
        }

        default: 
        this.finalApprovedMihuruOrLendingPartner = false;
      }
    }, error => {
      console.log(error);
    });
  }

}
