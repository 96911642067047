import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  //percentageval: number;

  private percentageValSource = new ReplaySubject<number>(1);
  private personalProgressValSource = new ReplaySubject<number>(1);
  private professionalProgressValSource = new ReplaySubject<number>(1);

  percentageVal$ = this.percentageValSource.asObservable();
  personalProgressVal$ = this.personalProgressValSource.asObservable();
  professionalProgressVal$ = this.professionalProgressValSource.asObservable();
  
  constructor() { 
    this.setPercentageVal(0);
    this.setPersonalProgressVal(0);
    this.setProfessionalProgressVal(0);
  }

  setPercentageVal(val: number) {
    console.log('In progressbar service');
    console.log('val:', val);
    this.percentageValSource.next(val);
  }

  setPersonalProgressVal(val: number) {
    console.log('In progressbar service personal Progress:');
    console.log('val:', val);
    this.personalProgressValSource.next(val);
  }

  setProfessionalProgressVal(val: number) {
    console.log('In progressbar service personal Progress:');
    console.log('val:', val);
    this.professionalProgressValSource.next(val);
  }

  
}
