<div class="section2">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <h2 class="text-center">How It Works</h2>
                <div class="row leftcontent">
                    <div class="col-12">
                        <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="assets/images/product-travel-howitswork.jpg" alt="product-travel-how its work"
                            class="float-left">
                        <h3>Step 1 - Choose your trip</h3>
                        <p>First, find your <a href="https://www.mihuru.com/BookFlights" target="_blank"
                                class="interLink">flight</a>, <a href="https://www.mihuru.com/Discover" target="_blank"
                                class="interLink">holiday package</a> or <a href="https://www.mihuru.com/Hotels"
                                target="_blank" class="interLink">hotel</a> on Mihuru.</p>
                        <h3>Step 2 - Apply and book</h3>
                        <p>Apply for a travel loan by providing us with your basic information and uploading your proof
                            documents.
                            We will then tell you how much you can borrow and for how long.
                            Then you can go ahead and initiate your booking or you can connect with Mihuru support.</p>
                        <h3>Step 3 - Repay your way </h3>
                        <p>You’ll repay the amount due and interest amount in upto 12 equal monthly instalments (EMIs).
                            This payment will either be in instalments before you travel or afterwards - depending on
                            what type
                            of travel loan we can offer you based on your financial profile.
                            You can manage every aspect of your account online.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>