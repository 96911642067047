<div class="container-fluid main-banner-text">
    <div class="row justify-content-end h-100">
        <div class="col-9 col-sm-6 col-md-6 col-lg-5 textDiv d-flex align-items-center h-100">
            <div class="row">
                <div class="col-12">
                    <h1>Financing Freedom.<br>
                        <span>Dreams. You </span>
                    </h1>
                    <p>Mihuru is the financial freedom you have been aspiring for. Our innovative travel finance
                        solutions like Book Now Pay Later empower you to travel the world. Our education loans help you achieve your learning goals. <br><a href="/about-us" class="interLink">Know more</a></p>
                    <div class="clear"></div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-sm-12 col-md-10 col-lg-6">
                            <button class="btn btn-warning btn-block" (click)="moEngageSignUpInMinutes()">Sign up in minutes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="carouselExampleSlidesOnly" class="carousel slide carousel-fade" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="travel w-100 h-100">
                &nbsp;
            </div>
        </div>
        <div class="carousel-item">
            <div class="education w-100 h-100">
                &nbsp;
            </div>
        </div>
    </div>
</div>