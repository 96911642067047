import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

export interface DownPaymentStatusRequest {
  type: string,
  merchantTxnId: string,
  status: string
}

@Component({
  selector: 'app-payment-gateway-pop',
  templateUrl: './payment-gateway-pop.component.html',
  styleUrls: ['./payment-gateway-pop.component.scss']
})
export class PaymentGatewayPopComponent implements OnInit {
  @Input() timerSub!: Subscription;
  private paymentGatewayURL: string;
  private PaymentWindow: Window | null = null;

  downPaymentStatusRequest: DownPaymentStatusRequest = {
    type: '',
    merchantTxnId: '',
    status: ''
  }

  baseUrl!: string;

  constructor(private router: Router, private accountService: AccountService, private userService: UserService, private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
  }

  async openWindow(url: string, merchantTxnId: string, isUPI: boolean) {

    this.paymentGatewayURL = url;
    this.PaymentWindow = window.open(url, '_blank');
    
    await this.waitForWindowToClose();
  
    try {
      const response = await this.accountService.FetchDownPaymentStatus(merchantTxnId).toPromise();
      if (response['data']) {
        const result = JSON.parse(response['data']);
        if (result.hasOwnProperty('data') && result.data[0].hasOwnProperty('status') && result.data[0].status === 'Paid') {

          // this.downPaymentStatusRequest.type = 'Update';
          // this.downPaymentStatusRequest.merchantTxnId = merchantTxnId;
          // this.downPaymentStatusRequest.status = 'Paid';
          // this.accountService.checkUpdateDownpayment(this.downPaymentStatusRequest).subscribe((response) => {});

          // this.router.navigateByUrl('/assist-journey/assist-success');
          if(this.timerSub){
            this.timerSub.unsubscribe();
          }
          this.PaymentUpdateEaseBuzz('paid', merchantTxnId, isUPI).then(() => {
            console.log('Payment Update Complete');
          }).catch(error => {
            console.error('Payment Update Error:', error);
          });

        }
        else {
          // what to do if still not paid
          // for Testing just refreshing...
          // dont know should try 3 times then update status desc "Down Payment Failed"
          // should write the code for failed in API
          // this.PaymentUpdateEaseBuzz('failed', merchantTxnId, isUPI).then(() => {
          //   console.log('Payment Update Complete');
          // }).catch(error => {
          //   console.error('Payment Update Error:', error);
          // });
          if(this.timerSub){
            this.timerSub.unsubscribe();
          }

          
          this.PaymentUpdateEaseBuzz('unpaid', merchantTxnId, isUPI).then(() => {
            console.log('Payment Update Complete');
          }).catch(error => {
            console.error('Payment Update Error:', error);
          });
          swal.fire("DownPayment Failed");
          return this.router.navigateByUrl('/assist-journey/assist-underwrite');
        }
      }
    } catch (error) {
      console.error('Error fetching NACH status:', error);
      //swal.fire("An error occurred while checking the Downpayment status.");
    }
  }
  
  // Function to check if the window is closed
  private waitForWindowToClose(): Promise<void> {
    return new Promise((resolve) => {
      const intervalId = window.setInterval(() => {
        if (this.PaymentWindow?.closed) {
          clearInterval(intervalId);
          document.getElementById('popup-payment')!.style.display = 'none';
          document.getElementById('overlay-payment')!.style.display = 'none';
          resolve();
        }
      }, 1000);
    });
  }
  
  OpenWindowByUser(){
    if (!this.PaymentWindow){
      this.PaymentWindow = window.open(this.paymentGatewayURL, '_blank');
    }
  }

  CloseWindow(){
    if (this.PaymentWindow){
      this.PaymentWindow.close();
    }
  }

  async PaymentUpdateEaseBuzz(payment_status: string, MerchantId: string, isUPI: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.route.queryParams.subscribe((params: any) => {
        this.baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
        let obj = {
          'ResponseId': MerchantId,
          'payment_id': MerchantId,
          'payment_link_status': payment_status,
          'signature': "OK"
        };
        //let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + MemoryStorageService.getItem('customerToken'));
        //this.http.post(this.baseUrl, obj, { headers: headers1 }).subscribe(
          this.http.post(this.baseUrl, obj).subscribe(
          response => {
            if (response['data'] != null) {
              if (response['data'].partnerCallbackUrl != null && response['data'].partnerCallbackUrl !== 'undefined' && response['data'].partnerCallbackUrl != '') {
                // let queryParams = '?api=paymentgateway&payment_id=' + this.successResponse.razorpay_payment_id + '&payment_reference_id=' + this.successResponse.razorpay_order_id + '&payment_status=' + payment_status + '&partnerTransactionId=' + response['data'].partnerTransactionId + '&amount=' + response['data'].Amount;
                // window.location.replace("https://" + response['data'].partnerCallbackUrl + queryParams);
                if(isUPI){

                  /*** Redirect if DownPayment Payment Failed for Sterling Holidays ***/
                  if(payment_status === 'unpaid'){
                    if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                      {
                        const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentFailed" };
                        this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                        return;
                      }
                  }
                  /*******************/

                  this.router.navigateByUrl('/assist-journey/loan-agreement?status=success&source=api').then(() => resolve());
                }
                else{

                  /*** Redirect if DownPayment Payment Failed/Success for Sterling Holidays ***/
                  if(payment_status === 'paid'){
                    if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                    {
                      const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentCompleted", mihuruTransactionId: MerchantId , amount: MemoryStorageService.getItem('DownPaymentAmount') };
                      this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                      return;
                    }
                  }
                  else{
                    if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
                      {
                        const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentFailed" };
                        this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                        return;
                      }
                  }
                  /*******************/

                  window.location.replace("https://" + response['data'].partnerCallbackUrl);
                }
                
              } else {
                if(isUPI){
                  this.router.navigateByUrl('/assist-journey/loan-agreement?status=success&source=api').then(() => resolve());
                }
                else{

                  this.router.navigateByUrl('/assist-journey/assist-success').then(() => resolve());
                }
                
              }
            }
          },
          error => {
            reject(error);
          }
        );
      });
    });
  }

}
