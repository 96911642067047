<div class="container-lg coloredBg">
	<div class="row m-0">
		<div class="col-12">
			<h2 class="text-center">FAQ’s </h2>
		</div>
	</div>
	<div class="row justify-content-center m-0">
		<div class="col-11 col-lg-12 text-left">
			<div class="accordion" id="accordionExample">
				<div class="card">
					<div class="card-header" id="headingOne">
						<h2 class="clearfix mb-0">
							<a class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
								aria-expanded="true" aria-controls="collapseOne"><i
									class="fa fa-chevron-circle-down"></i> What is Mihuru Pay in EMI before you Fly/Book
								Now Pay Later?</a>
						</h2>
					</div>
					<div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
						data-parent="#accordionExample">
						<div class="card-body">
							<p>It enables customers to book their travel in advance by paying for it in
								simple EMIs either before or after travel. The number of EMIs depends on the customer’s
								credit profile and depending on his travel date customer can choose to pay in 1 EMI or
								fortnightly up to 3 months or once a month till the loan is repaid.</p>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="headingTwo">
						<h2 class="mb-0">
							<a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
								aria-expanded="false" aria-controls="collapseTwo"><i
									class="fa fa-chevron-circle-down"></i>How much time does it take to get credit limit
								for customer?</a>
						</h2>
					</div>
					<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
						<div class="card-body"><p>The customer will get the credit limit within one working day, once we
							receive all the required documents.</p></div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="headingThree">
						<h2 class="mb-0">
							<a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
								aria-expanded="false" aria-controls="collapseThree"><i
									class="fa fa-chevron-circle-down"></i>When the payment will be processed? How much
								payment will be disbursed?</a>
						</h2>
					</div>
					<div id="collapseThree" class="collapse" aria-labelledby="headingThree"
						data-parent="#accordionExample">
						<div class="card-body"><p>The full amount basis booking value will be disbursed within 3-7 working days to the travel supplier post receipt of successful booking invoice and confirmation
							from customer.</p></div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="headingFour">
						<h2 class="mb-0">
							<a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour"
								aria-expanded="false" aria-controls="collapseFour"><i
									class="fa fa-chevron-circle-down"></i>What if the customer cancels his trip after
								booking?</a>
						</h2>
					</div>
					<div id="collapseFour" class="collapse" aria-labelledby="headingFour"
						data-parent="#accordionExample">
						<div class="card-body"><p>Travel agents need to inform Mihuru of the cancellation and refund the
							amount to Mihuru as per cancellation/refund policies</p></div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="headingFive">
						<h2 class="mb-0">
							<a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive"
								aria-expanded="false" aria-controls="collapseFive"><i
									class="fa fa-chevron-circle-down"></i>What if the customer doesn't pay back his
								loan?</a>
						</h2>
					</div>
					<div id="collapseFive" class="collapse" aria-labelledby="headingFive"
						data-parent="#accordionExample">
						<div class="card-body"><p>The Mihuru team will handle all collections/defaults. In the case of
							multiple defaults from customers, Mihuru will re-evaluate partnership with the concerned
							travel agent</p></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>