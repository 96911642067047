import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { AccountService } from 'src/app/core/services/account.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../sessiontimeout/session-timer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

enum ProfessionType {
  Salaried = 'Salaried',
  SelfEmployed = 'Self Employed',
}

enum CompanyType {
  Proprietorship = 'Proprietorship',
  Partnership = 'Partnership',
  LimitedLiabilityPartnership = 'Limited Liability Partnership',
  PrivateLimitedCompany = 'Private Limited Company',
}

enum BusinessDocument {
  UdyogAadhaar = 'Udyog Aadhaar',
  ShopEstablishmentCertificate = 'Shop and Establishment Certificate',
  GumastaLicense = 'Gumasta license',
}

@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrls: ['./professional-details.component.scss']
})
export class ProfessionalDetailsComponent implements OnInit {
  public professionTypes = [ProfessionType.Salaried, ProfessionType.SelfEmployed];
  public companyTypes = [CompanyType.Proprietorship, CompanyType.Partnership, CompanyType.LimitedLiabilityPartnership, CompanyType.PrivateLimitedCompany];
  public businessDocuments = [BusinessDocument.GumastaLicense, BusinessDocument.ShopEstablishmentCertificate, BusinessDocument.UdyogAadhaar];
  
  public model: FormGroup;
  public image!: any;
  public maxFileCount: number = 6;
  public hasBaseDropZoneOver: boolean;
  public pan: string = '';
  public bankStatementRequired: string = '';
  public isBankStatementUpload: boolean = false;
  public gstInactive: boolean = false;

  uploaderOptions: FileUploaderOptions = {
    url: 'https://mihuru.com',
    queueLimit: this.maxFileCount,
    disableMultipart: true,
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item: FileItem) => {
      if (item.file.type === 'application/pdf' || item.file.type.startsWith('image/')) {
        return {
          name: item.file.name,
          length: item.file.size,
          contentType: item.file.type,
          date: new Date(),
        };
      } 
    },
    filters: [
      {
        name: 'imageAndPdfFilter',
        fn: (item: FileLikeObject) => {
          return item.type === 'application/pdf' || item.type.startsWith('image/');
        }
      }
    ]
  };

  uploader = new FileUploader(this.uploaderOptions);

  constructor(private fb: FormBuilder,
              private accountService: AccountService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private sessionTimerService: SessionTimerService,
              private spinner: NgxSpinnerService) {
    this.model = this.fb.group({
      professionType: ['', Validators.required],
      companyName: [''],
      companyType: [''],
      businessDocument: [''],
      gst: ['', [Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')]],
      skipGst: [false]
    });

    this.model.get('gst')?.valueChanges.subscribe((value) => {
      if (value) {
        this.model.get('gst')?.setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

    const res = this.accountService.getLatestDocument('identity proof');
    this.pan = res['DocumentNumber'];
    this.hasBaseDropZoneOver = false;
  }

  ngOnInit(): void {
    this.spinner.hide();
    let user = JSON.parse(MemoryStorageService.getItem('user'));
    this.isBankStatementUpload = !!user['docs'][0]['bankstatement'];
  }

  updatePreview() {
    const files = this.uploader.queue;
    if (files.length == 0) {
      swal.fire('Only image files and PDFs are allowed');
    }
    this.image = files.length ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(files[0]._file)) : null;
  }

  removeFile() {
    this.uploader.clearQueue();
    this.image = false;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  get isSelfEmployed() {
    return this.model.get('professionType')?.value === ProfessionType.SelfEmployed;
  }

  get isSalarized() {
    return this.model.get('professionType')?.value === ProfessionType.Salaried;
  }

  onProfessionTypeChange() {

    this.gstInactive = false;
    this.model.get('skipGst').reset();
    this.model.get('companyName').reset();
    this.model.get('companyType').reset();
    this.model.get('businessDocument').reset();
    this.model.get('gst').reset();

    // let user = JSON.parse(MemoryStorageService.getItem('user'));
    // if (this.isSalarized && user['docs'][0]['bankstatement']) {
    //   this.router.navigateByUrl('/assist-journey/bank-statement');
    // } 
    // else {
    //   environment.production ? this.router.navigateByUrl('/assist-journey/team-callback') : this.router.navigateByUrl('/assist-journey/nach-mandate');
    // }
  }

  submit() {
    if (this.model.valid) {
      this.spinner.show();

      //public professionTypes = [ProfessionType.Salaried, ProfessionType.SelfEmployed];
      //public companyTypes = [CompanyType.Proprietorship, CompanyType.Partnership, CompanyType.LimitedLiabilityPartnership, CompanyType.PrivateLimitedCompany];
      //public businessDocuments = [BusinessDocument.GumastaLicense, BusinessDocument.ShopEstablishmentCertificate, BusinessDocument.UdyogAadhaar];



      this.uploadBusinessDetails();
    }
  }

  async uploadBusinessDetails() {
    let isGST: boolean = this.model.get('gst')?.value !== '';
    const files = this.uploader.queue;
    const fileBase64 = await Promise.all(files.map((file) => toBase64(file._file)));

    let obj = {
      "occupationType": this.model.get('professionType')?.value,
      "companyName": this.model.get('companyName')?.value,
      "companyType": this.model.get('companyType')?.value,
      "professionIdType": isGST ? 'GST Number' : (!this.isSelfEmployed ? 'Salary slip' : this.model.get('businessDocument')?.value  ),
      "professionId": this.model.get('gst')?.value,
      "isProprietor": this.isSelfEmployed && this.model.get('companyType')?.value === CompanyType.Proprietorship,
      "isGST": !this.model.get('skipGst')?.value,
      "files": fileBase64,
      "docType": this.model.get('businessDocument')?.value,
      "fileType": this.uploader.queue.length > 0 ? files[0]._file.type: '',
    };

    await this.accountService.uploadBusinessDetails(obj).subscribe(res => {
      
      if(this.isSelfEmployed && this.model.get('companyType')?.value === CompanyType.Proprietorship && !this.model.get('skipGst')?.value && res && (typeof res !== 'string') && res.hasOwnProperty('data') && res['data'].hasOwnProperty('response_msg') && (res['data']['response_msg']).toLowerCase() != 'active' ){
        this.spinner.hide();
        swal.fire({
          text: "GST Number is In-Active, Kindly upload the document!",
          //icon: 'info',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.hasDeactiveGst();
            return;
          }
        });
      }  
      else if (res && (res['errors'] || res['error'])) {
        this.spinner.hide();
        swal.fire({
          text: res['error'].hasOwnProperty('errors') ? (res['error'].errors + ', Kindly upload the document!') : (res['error'].hasOwnProperty('response_msg') ? 'GST Validation failed, Kindly upload the document!' : "Business Proof validation failed, Kindly upload the document!"),
          //icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.hasDeactiveGst();
            return;
          }
        });   
      }
      this.spinner.hide();
      this.nextPageRouting();
    });
  }

  nextPageRouting() {
    if (this.isSelfEmployed || this.isSalarized) {
      let user = JSON.parse(MemoryStorageService.getItem('user'));
      if (user['docs'][0]['bankstatement']) {
        return this.router.navigateByUrl('/assist-journey/bank-statement');
      } 
      // else {
      //   environment.production ? this.router.navigateByUrl('/assist-journey/team-callback') : this.router.navigateByUrl('/assist-journey/nach-mandate');
      // }
    } else {
      this.router.navigateByUrl('/assist-journey/process-offline');
    }
  }

  hasDeactiveGst(){
    this.model.get('gst').setValue('');
    this.model.get('skipGst').setValue(true);
    this.gstInactive = true;
  }

  

  comparePan() {
    // Your comparison logic goes here
  }
  
}
