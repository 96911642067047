import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';

export interface UpdateStatusData {
  mandateId: string;
  status: string;
}

@Component({
  selector: 'app-nach-pop',
  templateUrl: './nach-pop.component.html',
  styleUrls: ['./nach-pop.component.scss']
})
export class NachPopComponent implements OnInit {
  @Input() timerSub!: Subscription;
  private NACHUrl: string;
  private NachWindow: Window | null = null;
  updateStatusData: UpdateStatusData =
  {
     mandateId:  '',
     status: ''
  }
  constructor(private router: Router, private accountService: AccountService, private userService: UserService) {}
  ngOnInit(): void {
    
  }

  async openWindow(url: string, mandateId: string) {
    this.updateStatusData.mandateId = mandateId;
  
    this.NACHUrl = url;
    this.NachWindow = window.open(url, '_blank');
    
    await this.waitForWindowToClose();
  
    try {
      const response = await this.accountService.GetNachStatus(mandateId).toPromise();
      if (response['data']) {
        const data = response['data'];
        if (data.customer.accptd === 'accepted') {
          if(this.timerSub){
            this.timerSub.unsubscribe();
          }
          this.updateStatusData.status = 'accepted';
          this.accountService.updateNACHApplicationStatus(mandateId, 2).subscribe((response) => {
              this.accountService.UpdateMandateStatus(this.updateStatusData).subscribe((response) => {
              this.router.navigateByUrl('/assist-journey/loan-agreement?status=success');
            });
          });
        } else {
          if(this.timerSub){
            this.timerSub.unsubscribe();
          }
          this.updateStatusData.status = 'rejected';
          this.accountService.updateNACHApplicationStatus(mandateId, 0).subscribe((response) => {
            this.accountService.UpdateMandateStatus(this.updateStatusData).subscribe((response) => {
              swal.fire("NACH Mandate Failed");
              
              /*** Redirect if Nach Failed for Sterling Holidays ***/
              if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
              {
                const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "NachFailed" };
                this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                return;
              }
              /*******************/
              
              return this.router.navigateByUrl('/assist-journey/assist-underwrite');
            });
          });
        }
      }
    } catch (error) {
      console.error('Error fetching NACH status:', error);
      swal.fire("An error occurred while checking the NACH mandate status.");
    }
  }
  
  // Function to check if the window is closed
  private waitForWindowToClose(): Promise<void> {
    return new Promise((resolve) => {
      const intervalId = window.setInterval(() => {
        if (this.NachWindow?.closed) {
          clearInterval(intervalId);
          document.getElementById('popup-nach')!.style.display = 'none';
          document.getElementById('overlay-nach')!.style.display = 'none';
          resolve();
        }
      }, 1000);
    });
  }
  
  OpenWindowByUser(){
    if (!this.NachWindow){
      this.NachWindow = window.open(this.NACHUrl, '_blank');
    }
  }

  CloseWindow(){
    if (this.NachWindow){
      this.NachWindow.close();
    }
  }

  // closePopup(): void {
  //   document.getElementById('popup')!.style.display = 'none';
  //   document.getElementById('overlay')!.style.display = 'none';
    
  //   timer(0, 60000).pipe(
  //     switchMap(() => this.accountService.GetNachStatus(this.NachUniqueId))
  //   ).subscribe((response) => {
  //     if (response['data'] == null) {
  //        const data = JSON.parse(response['data']);
  //        if (data.customer.accptd === 'accepted') {
  //          this.router.navigateByUrl('/assist-journey/pleasewait');
  //        }
  //     }
  //   });

  //  swal.fire("NACH Mandate Failed");

  //}
}
