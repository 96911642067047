<section id="media" class="pb-5">
    <div class="container-fluid">
        <h2>Mihuru In The Media</h2>
        <div class="row justify-content-center">
            <!-- 01 -->
            <div *ngFor="let m of mediaArr" class="col-6 col-sm-4 col-lg-2">
                <div class="image-flip">
                    <div class="mainflip flip-0">
                        <div class="frontside">
                            <div class="card">
                                <div class="card-body align-items-center">
                                    <div class="d-flex h-100 justify-content-center">
                                        <div class="row justify-content-center align-self-center">
                                            <img class=" img-fluid"
                                                [src]="m.image" alt="card image">
                                            <h3>&nbsp;</h3>
                                            <p><img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../../assets/images/flip-icon.svg" class="flip-icon" /></p>
                                            <!-- BW disrupt -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="backside">
                            <div class="card">
                                <div class="card-body text-center mt-4">
                                    <div class="d-flex h-100 justify-content-center">
                                        <div class="row justify-content-center align-self-center">
                                            <!-- <img class=" img-fluid"
                                                src="../../../../assets/images/media-Disrupt-logo.png" alt="card image"> -->
                                            <a [href]="m.url"
                                                target="_blank">{{m.text}}</a>
                                            <p><a [href]="m.url"
                                                    class="interLink">Show more <i
                                                        class="fas fa-external-link-alt"></i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>